import { addYieldDAL, editYieldDAL, getExportBom, getExportYield} from '../api/yieldApi';

class yieldServices {

  constructor() {
  }

  editYield (id, data) {
    return editYieldDAL(id, data);
  }

  addYield (cutTicketId, data) {
    return addYieldDAL(cutTicketId, data);
  }

  getExportBom (styleId) {
      return getExportBom(styleId);
  }

  getExportYield (orderId) {
      return getExportYield(orderId);
  }

  sendNotify(id, data) {
    return editYieldDAL(id, data);
  }
}

export default new yieldServices();
