
const C = 'CUSTOMER_PO.CURRENT.EDIT_CUT_TICKET_IMAGES';

export default {
  SHOW_MODAL: `${C}.SHOW_MODAL`,
  HIDE_MODAL: `${C}.HIDE_MODAL`,

  SUBMIT: `${C}.SUBMIT`,
  CANCEL_SUBMIT: `${C}.CANCEL_SUBMIT`,

  ADD_IMAGE: `${C}.ADD_IMAGE`,
  REMOVE_IMAGE: `${C}.REMOVE_IMAGE`,

  RESET_DATA: `${C}.RESET_DATA`,
}
