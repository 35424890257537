import React from 'react';
import PropTypes from 'prop-types';
import I18n from "i18n-js";
import {PRODUCTION_SCREEN} from "../../../../constants/constants";
import _ from 'lodash';
import TableList from "../../../common/datatables/TableList";
import cutTicketActions from "../../../../actions/cutTicketActions";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import appActions from "../../../../actions/appActions";
import {toastr} from 'react-redux-toastr'
import {setUpdateProgressData, setBodyEditCutTicketColorProgress, setBodyUpdateCutTicketColorProgress} from "../../../../functions/utils";

class UpdateProgress extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      headers: [],
      tableData: []
    };
    this.onChange = this.onChange.bind(this);
  }

  componentWillMount(){
    const {activeScreen, data} = this.props;
    const progressItem = _.get(data, "selectedDate.colorsByDate", {});
    const {newData, headers} = setUpdateProgressData({...data}, activeScreen, this.onChange);
    this.setState({tableData: (activeScreen === PRODUCTION_SCREEN.UPDATE_PROGRESS) ? newData : progressItem, headers})
  }

  onChange(item, sizeName, value){
    let newItem = _.cloneDeep(item);
    _.set(newItem, sizeName, parseFloat(value));
    this.setDataChanged({...newItem}, sizeName, value, this.props.activeScreen);
  }

  setDataChanged(item){
    let data = [...this.state.tableData];
    let newData = [];
    data.forEach(it => {
      let newIt = it;
      if(it.name === item.name) {
        newIt = item;
      }
      newData = [...newData, newIt];
    });
    this.setState({tableData: newData});
  }

  save(){
    const cutTicketId = _.get(this.props.data, "_id", "");
    if((this.props.activeScreen === PRODUCTION_SCREEN.UPDATE_PROGRESS) && !this.validateInput(this.state.tableData, this.props.activeScreen)){
      toastr.info('Note!', "Please enter quantity");
      return;
    }
    const body = (this.props.activeScreen === PRODUCTION_SCREEN.EDIT_PROGRESS) ?
      setBodyEditCutTicketColorProgress([...this.state.tableData]) :
      setBodyUpdateCutTicketColorProgress([...this.state.tableData], this.props.activeScreen);
      this.props.cutTicketActions.updateCutTicketColorProgress(cutTicketId, body).then(res =>{
        if(res && res.success) {
          let pageList = _.cloneDeep(this.props.appState.pageList);
          pageList.pop();
          this.props.appActions.addPage(pageList);
        }
      });

  }

  validateInput(tableData) {
    let isEnterInputs = false;
    for(let color of tableData) {
      _.forIn(_.get(color, "sizes",{}), (co, key) => {
        if(_.get(co, "updateValue",0)) {
          isEnterInputs = true;
          return isEnterInputs;
        }
      })
    }
    return isEnterInputs;
  }

  render() {
    const {headers, tableData} = this.state;
    const {data} = this.props;
    return (
      <div id="content">

        <ul className="content-header-title">
          <li>
            <p>{I18n.t("PageProduction.CutTicket")}
              <span className="color-orange medium mg-left-50 size-16">{_.get(data,"cutTicket","")}</span>
            </p>
          </li>

          <li className="float-right add-filter-top" onClick={()=>{this.save()}}>
            <span className="cursor-pointer global-add" data-toggle="modal" data-target="#modal-add-save">
              <i className="fa fa-floppy-o" aria-hidden="true"></i>{I18n.t("PageProduction.Save")}</span>
          </li>
        </ul>

        <div className="wrap-global-table">
          <TableList items={tableData||[]} columns={headers||[]}
                     options={{clsTbl: 'global-table box-shadow-none'}}/>
        </div>
      </div>
    );
  }
}

UpdateProgress.propTypes = {
  appActions: PropTypes.object,
  cutTicketActions: PropTypes.object,
  data: PropTypes.object,
  styleId: PropTypes.string,
  activeScreen: PropTypes.string,
};

function mapStateToProps (state, ownProps) {
  return {
    appState: state.appState,
    authState: state.authState,
    cutTicketState: state.cutTicketState,
  };
}

function mapDispatchToProps (dispatch) {
  return {
    appActions: bindActionCreators(appActions, dispatch),
    cutTicketActions: bindActionCreators(cutTicketActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateProgress);

