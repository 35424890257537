
import { combineReducers } from 'redux';
import _ from 'lodash';
import * as R from 'ramda';
import C from '../../../constants/customer-po/current/editCutTicketImages';
import { pathImage } from '../../../functions/helpers';


const rawData = (state = null, action) => {
  const { type, payload } = action;
  switch (type) {
    case C.SHOW_MODAL:
      return payload;
    default:
      return state;
  }
}

const images = (state = [], action) => {
  const { type, payload } = action;
  switch (type) {
    case C.ADD_IMAGE: {
      const stateClone = _.cloneDeep(state);
      stateClone.push(payload);
      return stateClone;
    }
    case C.REMOVE_IMAGE:
      return state.filter(img => img.id !== payload);
    case C.SHOW_MODAL: {
      const items = R.path(['images'], payload);
      return _.map(items, img => ({
        id: img,
        url: pathImage(img),
      }));
    }
    default:
      return state;
  }
}


const submiting = (state = false, action) => {
  const { type } = action;
  switch (type) {
    case C.SUBMIT:
      return true;
    case C.CANCEL_SUBMIT:
      return false;
    default:
      return state;
  }
}

const combined = combineReducers({
  rawData,
  images,
  submiting,
});


export default (state, action) => {
  const { type } = action;
  if (type === C.RESET_DATA) {
    return {};
  }
  return combined(state, action);
};
