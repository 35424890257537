import {MATERIAL} from '../constants/actionTypes';
import initial from './initialState';

export default function (state = initial.materialState, action) {
  switch (action.type) {
    case MATERIAL.GET_LIST_SUCCESS:
      return {...state, items: action.data.items || [], count: action.data.total || 0};
    case MATERIAL.RELOAD:
      return {...state, reload: !state.reload};
    case MATERIAL.GET_DETAIL_SUCCESS:
      return {...state, detail: action.data};
    case MATERIAL.RESET_DETAIL:
      return {...state, detail: {}};
    default:
      return state;
  }
}
