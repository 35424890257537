import Base from './base';

class User extends Base {
  constructor() {
    super();

    this.signInDAL = this.signInDAL.bind(this);
    // this.signOutDAL = this.signOutDAL.bind(this);
    this.signUpDAL = this.signUpDAL.bind(this);
    this.getCurrentUserDAL = this.getCurrentUserDAL.bind(this);
    this.forgotPasswordDAL = this.forgotPasswordDAL.bind(this);
    this.resetPasswordDAL = this.resetPasswordDAL.bind(this);
    this.resetPasswordByTokenDAL = this.resetPasswordByTokenDAL.bind(this);
    this.changePasswordDAL = this.changePasswordDAL.bind(this);
    this.getListUserDAL = this.getListUserDAL.bind(this);
    this.profileEditDAL = this.profileEditDAL.bind(this);
    this.editUserDAL = this.editUserDAL.bind(this);
    this.updateLockUserDAL = this.updateLockUserDAL.bind(this);
    this.addUserDAL = this.addUserDAL.bind(this);
    this.deleteUserDAL = this.deleteUserDAL.bind(this);
    this.forgotPasswordConfirmDAL = this.forgotPasswordConfirmDAL.bind(this);
  }

  signUpDAL(body) {
    return super.execute(this.Urls.user.signUp, this.ApiMethod.post, body).then(response => {
      return user;
    });
  }

  signInDAL(body) {
    return super.execute(this.Urls.user.login, this.ApiMethod.post, body);
  }

  // signOutDAL() {
  //   return super.execute(this.Urls.user.signOut, this.ApiMethod.put, {});
  // }

  getCurrentUserDAL() {
    return super.execute(this.Urls.user.getCurrentUser, this.ApiMethod.get, null);
  }

  profileEditDAL (id, body, token) {
    return super.execute(this.Urls.user.update + '/' + id, this.ApiMethod.put, body, token);
  }

  forgotPasswordDAL(email) {
    return super.execute(this.Urls.user.forgot + '?email=' + email, this.ApiMethod.post, null, null);
  }

  forgotPasswordConfirmDAL(body) {
    return super.execute(this.Urls.user.forgotConfirm, this.ApiMethod.post, body, null);
  }

  resetPasswordDAL(id) {
    let url = this.Urls.user.passwordReset + id + this.Urls.user.passwordReset2;
    return super.execute(url, this.ApiMethod.get, null);
  }

  resetPasswordByTokenDAL(tokenPass, pass) {
    let body = {
      token: tokenPass,
      newPassword: pass,
      newPassword2: pass
    };
    return super.execute(this.Urls.user.reset, this.ApiMethod.post, body, null);
  }

  changePasswordDAL(body) {
    return super.execute(this.Urls.user.changePassword, this.ApiMethod.put, body);
  }

  getListUserDAL(options) {
    return super.execute(this.Urls.user.getList, this.ApiMethod.get, options);
  }

  editUserDAL(id, body) {
    return super.execute(this.Urls.user.update + '/' + id, this.ApiMethod.put, body);
  }

  updateLockUserDAL(id) {
      return super.execute(this.Urls.user.updateLock + '/' + id, this.ApiMethod.put, null);
  }


  addUserDAL(body){
    return super.execute(this.Urls.user.create, this.ApiMethod.post, body);
  }

  deleteUserDAL (id){
    return super.execute(this.Urls.user.delete + '/' + id, this.ApiMethod.delete, null);
  }
}

export default new User();
