import * as types from '../constants/actionTypes';
import {showLoading, hideLoading} from './commonUseActions';
import orderServices from '../services/orderServices';
import styleServices from '../services/styleServices';
import {SEARCH_KEY} from "../constants/constants";
import I18n from 'i18n-js';
import { PAGINATION } from '../constants/constants';

const getListOrder = (options = {}) => dispatch => {
    // showLoading(types, dispatch);
    dispatch({ type: types.ORDER.LOAD_MORE });
    dispatch({ type: types.ORDER.LOADING });
    orderServices.getListOrder(options).then((res) => {
      // hideLoading(types, dispatch);
      if (res.success) {
        dispatch({
          type: types.ORDER.GET_LIST_SUCCESS,
          data: res.data || []
        });
        if (res.data.items.length == 0 || res.data.items.length < PAGINATION.PAGE_SIZE) {
          dispatch({ type: types.ORDER.CANCEL_LOAD_MORE });
        }
      }
      dispatch({ type: types.ORDER.CANCEL_LOADING });
    }, (error) => {
      hideLoading(types, dispatch);
      dispatch({ type: types.ORDER.CANCEL_LOAD_MORE });
      dispatch({ type: types.ORDER.CANCEL_LOADING });
    });
}

const getListOrderMore = (options = {}) => dispatch => {
  // showLoading(types, dispatch);
  dispatch({ type: types.ORDER.LOAD_MORE });
  dispatch({ type: types.ORDER.LOADING });
  orderServices.getListOrder(options).then((res) => {
    // hideLoading(types, dispatch);
    if (res.success) {
      dispatch({
        type: types.ORDER.GET_LIST_MORE_SUCCESS,
        data: res.data || []
      });
      if (res.data.items.length == 0 || res.data.items.length < PAGINATION.PAGE_SIZE) {
        dispatch({ type: types.ORDER.CANCEL_LOAD_MORE });
      }
    }
    dispatch({ type: types.ORDER.CANCEL_LOADING });
  }, (error) => {
    hideLoading(types, dispatch);
    dispatch({ type: types.ORDER.CANCEL_LOAD_MORE });
    dispatch({ type: types.ORDER.CANCEL_LOADING });
  });
}

const getListOrderForReport = (options = {}) => dispatch => {
  orderServices.getListOrderForReport(options).then((res) => {
    if (res.success) {
      dispatch({
        type: types.ORDER.GET_LIST_SUCCESS,
        data: res.data || []
      });
    }
  }, (error) => {
    hideLoading(types, dispatch);
  });
}

function getDetail (id, reset = true, options = {}) {
  return (dispatch) => {
    showLoading(types, dispatch);
    reset && dispatch({
      type: types.ORDER.RESET_DETAIL,
      data: {}
    });

    orderServices.getDetail(id, options).then((res) => {
      hideLoading(types, dispatch);
      if (res) {
        dispatch({
          type: types.ORDER.GET_DETAIL_SUCCESS,
          data: res.data || {}
        });
      }
    }, (error) => {
      hideLoading(types, dispatch);
    });
  };
}

function sendNotify(id) {
  return (dispatch) => {
    showLoading(types, dispatch);
    return orderServices.sendNotify(id).then((res) => {
      hideLoading(types, dispatch);
      return {success: true}
    }, (error) => {
      hideLoading(types, dispatch);
      return {success: false, error}
    });
  };
}

function deleteOrder (id) {
  return (dispatch) => {
    showLoading(types, dispatch);

    orderServices.deleteOrder(id).then((res) => {
      hideLoading(types, dispatch);
      if (res) {
        dispatch({
          type: types.ORDER.RELOAD,
          data: true
        });
      }
    }, (error) => {
      hideLoading(types, dispatch, error);
    });
  };
}

function editOrder(id, data) {
  return (dispatch) => {
    showLoading(types, dispatch)

    return orderServices.editOrder(id, data).then((res) => {
      hideLoading(types, dispatch);
      if (res) {
        dispatch({
          type: types.ORDER.EDIT_ORDER_SUCCESS,
          data: res.data
        });
      }
      return res;
    }, (error) => {
      const message = error.statusCode == 500 ? I18n.t('ErrorMessages.OrderHaveAlreadyExist') : error.message
      hideLoading(types, dispatch, message);
    });
  };
}

function addOrder (data) {
  return (dispatch) => {
    showLoading(types, dispatch)

    return orderServices.addOrder(data).then((res) => {
      hideLoading(types, dispatch, res.success ? res.error : undefined);
      if (res && res.success) {
        dispatch({
          type: types.ORDER.ADD_ORDER_SUCCESS,
          data: res.data
        });
      }
      return res;
    }, (error) => {
      const message = error.statusCode == 500 ? I18n.t('ErrorMessages.OrderHaveAlreadyExist') : error.message
      hideLoading(types, dispatch, message);
    });
  };
}

function updateSelectedOrder(data){
  return  (dispatch) => {
    dispatch({
      type: types.ORDER.GET_DETAIL_SUCCESS,
      data: data
    });
  }
}

function searchInto(options, func,  name, field, bool = true) {
  return new Promise(resolve => {
    if(bool) return resolve(options)
    func.then(rs => {
      const qr = {[name]: _.get(rs, 'data.items', []).reduce((ls, vl) => ls.concat([vl[field]]), [])}
      options.query = JSON.stringify({...options.query, ...qr})
      return resolve(options)
    })
  })
}

function search ({search, options}) {
  return (dispatch) => {
    let func,  name, field, bool = true

    switch(search.key) {
      case SEARCH_KEY.STYLE: {
        func = styleServices.getAllStyles({projection: 'orderId', query: JSON.stringify({style: {$regex: '.*' + search.value + '.*', $options: 'i'}})})
        name = '_id'
        field = 'orderId'
        bool = false
      }
    }

    let op = {}

    return searchInto(options, func,  name, field, bool).then(data => {
      op = data
      return getListOrder(op)(dispatch)
    }).then(res => {
      return res;
    })
  };
}


function searchForReport ({search, options}) {
    return (dispatch) => {
        let func,  name, field, bool = true
        let op = {}

        return searchInto(options, func,  name, field, bool).then(data => {
            op = data
            return getListOrderForReport(op)(dispatch)
        }).then(res => {
            return res;
        })
    };
}

function getListCustomer (options = {}) {
  return (dispatch) => {
    showLoading(types, dispatch);

    return orderServices.getListCustomer(options).then((res) => {
      hideLoading(types, dispatch);
      if (res) {
        dispatch({
          type: types.ORDER.GET_LIST_CUSTOMER,
          data: res.data.items || []
        });
        return res
      }
    }, (error) => {
      hideLoading(types, dispatch);
      return error
    });
  };
}

function canceledOrder (id) {
  return (dispatch) => {
    showLoading(types, dispatch);

    return orderServices.editOrder(id, { isCancel: true }).then((res) => {
      hideLoading(types, dispatch);
      if (res) {
        if (res) {
          dispatch({
            type: types.ORDER.RELOAD,
            data: true
          });
        }
        return res;
      }
    }, (error) => {
      hideLoading(types, dispatch, error);
    });
  };
}

function changePageNumber(number) {
  return (dispatch) => {
    dispatch({ type: types.ORDER.CHANGE_PAGE_NUMBER, data: number });
  }
}

function cancelChangePageNumber() {
  return (dispatch) => {
    dispatch({ type: types.ORDER.RESET_CHANGE_PAGE_NUMBER });
  }
}

function changeFilterText(text) {
  return (dispatch) => {
    dispatch({ type: types.ORDER.CHANGE_FILTER_TEXT, data: text });
  }
}

function changeSearch() {
  return (dispatch) => {
    dispatch({ type: types.ORDER.IS_SEARCH});
  }
}

function cancelChangeSearch() {
  return (dispatch) => {
    dispatch({ type: types.ORDER.CANCEL_IS_SEARCH });
  }
}

export default {
  getListOrder,
  deleteOrder,
  editOrder,
  addOrder,
  getDetail,
  updateSelectedOrder,
  sendNotify,
  search,
  getListCustomer,
  canceledOrder,
  getListOrderForReport,
  searchForReport,
  changePageNumber,
  cancelChangePageNumber,
  changeFilterText,
  changeSearch,
  cancelChangeSearch,
  getListOrderMore
};
