import { combineReducers } from 'redux';
import C from '../../constants/production/addPacking';
import E from '../../constants/production/editPacking';
import _ from 'lodash';

const customers = (state = null, action) => {
  switch (action.type) {
    case C.GET_LIST_CUSTOMER:
      return action.payload;
    case C.CHANGE_EMPTY_DATA:
      return action.payload;
    default:
      return state;
  }
};

const customer = (state = null, action) => {
  switch (action.type) {
    case C.CHANGE_CUSTOMER:
      return action.payload;
    case C.CHANGE_EMPTY_DATA:
      return action.payload;
    default:
      return state;
  }
};

const customerPOs = (state = null, action) => {
  switch (action.type) {
    case C.CHANGE_LIST_CUSTOMERPO:
      return action.payload;
    case C.CHANGE_EMPTY_DATA:
      return action.payload;
    default:
      return state;
  }
};

const customerPO = (state = null, action) => {
  switch (action.type) {
    case C.CHANGE_CUSTOMERPO:
      return action.payload;
    case C.CHANGE_EMPTY_DATA:
      return action.payload;
    default:
      return state;
  }
};

const contractors = (state = null, action) => {
  switch (action.type) {
    case C.CHANGE_LIST_CONTRACTOR:
      return action.payload;
    case C.CHANGE_EMPTY_DATA:
      return action.payload;
    default:
      return state;
  }
};

const contractor = (state = null, action) => {
  switch (action.type) {
    case C.CHANGE_CONTRACTOR:
      return action.payload;
    case C.CHANGE_EMPTY_DATA:
      return action.payload;
    default:
      return state;
  }
};

const packingType = (state = null, action) => {
  switch (action.type) {
    case C.CHANGE_PACKING_TYPE:
      return action.payload;
    case C.CHANGE_EMPTY_DATA:
      return action.payload;
    default:
      return state;
  }
};

const cartonType = (state = null, action) => {
  switch (action.type) {
    case C.CHANGE_CARTON_TYPE:
      return action.payload;
    case C.CHANGE_EMPTY_DATA:
      return action.payload;
    default:
      return state;
  }
};

const packingByOrder = (state = null, action) => {
  switch (action.type) {
    case C.GET_PACKING_BY_ORDER:
      return action.payload;
    case C.CHANGE_EMPTY_DATA:
      return action.payload;
    case E.CHANGE_PACKED_QUANTITY:
      let rawData = _.cloneDeep(state);
      const { styleId, cutId, colorId, sizeName, value } = action.payload;
      rawData.map(
        style =>
          style._id === styleId &&
          style.cutTickets.map(
            cut =>
              cut._id === cutId &&
              cut.colors.map(
                color =>
                  color._id === colorId &&
                  color.sizes.map(size =>
                    size.name === sizeName ? (size.packedQuantity = size.packedQuantity - value) : null
                  )
              )
          )
      );
      return rawData;
    default:
      return state;
  }
};

const packing = (state = null, action) => {
  let packing, total;
  let data, styleId, cutId, colorId, sizeName, cutTicketId, no;
  let payload = action.payload;
  // console.log(action);
  switch (action.type) {
    case C.EMPTY_PACKING:
      return action.payload;
    case C.CREATE_PACKING:
      return action.payload;
    case C.EDIT_PACKING:
      return action.payload;
    case C.CREATE_CARTON_V2:
      return action.payload;
    case C.CHANGE_PACKING_NUMBER:
      if (true) {
        let data = payload.data;
        let styleId = payload.styleId;
        let cutId = payload.cutId;
        let colorId = payload.colorId;
        let sizeName = payload.sizeName;
        packing = _.cloneDeep(state);
        packing.styles.map(
          style =>
            style.style === styleId &&
            style.cutTickets.map(
              cut =>
                cut.cutTicket === cutId &&
                cut.colors.map(
                  color =>
                    color.color === colorId &&
                    color.packings[0].items.map(item =>
                      item.name === sizeName ? (item.planQuantity = _.toInteger(data)) : 0
                    )
                )
            )
        );
        return packing;
      }
      break;
    case C.CHANGE_PACKING_NOTE:
      if (true) {
        let { data, styleId, cutId, colorId } = action.payload;
        packing = _.cloneDeep(state);
        packing.styles.map(
          style =>
            style.style === styleId &&
            style.cutTickets.map(
              cut =>
                cut.cutTicket === cutId &&
                cut.colors.map(color => {
                  if (color.color === colorId) {
                    color.note = data;
                  }
                })
            )
        );
        return packing;
      }
      break;
    case C.CHANGE_ACTUAL_PACKING_NUMBER:
      if (true) {
        let { data, styleId, cutId, colorId, sizeName } = action.payload;
        packing = _.cloneDeep(state);
        packing.styles.map(
          style =>
            style.style === styleId &&
            style.cutTickets.map(
              cut =>
                cut.cutTicket === cutId &&
                cut.colors.map(
                  color =>
                    color.color === colorId &&
                    color.packings[0].items.map(item => {
                      if (item.name === sizeName) {
                        item.actualQuantity = _.toInteger(data);
                      }
                    })
                )
            )
        );
        return packing;
      }
      break;
    case C.CHANGE_ACTUAL_PACKING_CARTON_NUMBER:
      if (true) {
        let { data, styleId, cutId, colorId, sizeName, no } = action.payload;
        packing = _.cloneDeep(state);
        packing.styles.map(
          style =>
            style.style === styleId &&
            style.cutTickets.map(
              cut =>
                cut.cutTicket === cutId &&
                cut.colors.map(
                  color =>
                    color.color === colorId &&
                    color.packings.map(
                      packing =>
                        packing.props &&
                        packing.props.no == no &&
                        packing.items.map(item =>
                          item.name === sizeName ? (item.actualQuantity = _.toInteger(data)) : 0
                        )
                    )
                )
            )
        );
        return packing;
      }
      break;
    case C.CHANGE_GWKGSNWKGS_PACKING_CARTON_NUMBER:
      if (true) {
        let { data, styleId, cutId, colorId, sizeName, no } = action.payload;
        packing = _.cloneDeep(state);
        packing.styles.map(
          style =>
            style.style === styleId &&
            style.cutTickets.map(
              cut =>
                cut.cutTicket === cutId &&
                cut.colors.map(
                  color =>
                    color.color === colorId &&
                    color.packings.map(
                      packing =>
                        packing.props &&
                        packing.props.no == no &&
                        packing.items.map(item => (item.name === sizeName ? (item.value = _.toInteger(data)) : 0))
                    )
                )
            )
        );
        return packing;
      }
      break;
    case C.CHANGE_CHECKED_STYLE:
      let item = action.payload;
      packing = _.cloneDeep(state);
      packing.styles.map(style => (style.style === item.id ? (style.active = item.checked) : style.active));
      return packing;
    case C.CHANGE_EMPTY_DATA:
      return action.payload;
    case C.CHANGE_PROPS_CARTON:
      let { name, value } = action.payload;
      styleId = action.payload.styleId;
      cutId = action.payload.cutId;
      colorId = action.payload.colorId;
      packing = _.cloneDeep(state);

      packing.styles.map(
        style =>
          style.style === styleId &&
          style.cutTickets.map(
            cut =>
              cut.cutTicket === cutId &&
              cut.colors.map(color => {
                if (color.color === colorId) {
                  if (name === 'noEnd' || name === 'noBegin') {
                    if (name == 'noEnd' && _.toInteger(value) - (color.packings[0].props.noBegin || 0) >= 0) {
                      color.packings[0].props[name] = _.toInteger(value);
                    }
                    if (name == 'noBegin' && (color.packings[0].props.noEnd || 0) - _.toInteger(value) >= 0) {
                      color.packings[0].props[name] = _.toInteger(value);
                    }
                  } else {
                    color.packings[0].props[name] = Number(value);
                  }
                  const k = color.packings[0].props.noEnd - color.packings[0].props.noBegin + 1;
                  const props = color.packings[0].props;
                  color.packings[0].props.group = k === 1 ? `${props.noEnd}` : `${props.noBegin}-${props.noEnd}`;
                  // color.packings[0].props.tempData = true;
                }
              })
          )
      );
      return packing;
    case C.SET_REMAINING_CARTON:
      packing = _.cloneDeep(state);
      total = action.payload.total;
      styleId = action.payload.styleId;
      cutId = action.payload.cutId;
      colorId = action.payload.colorId;
      sizeName = action.payload.sizeName;
      packing.styles.map(
        style =>
          style.style === styleId &&
          style.cutTickets.map(
            cut =>
              cut.cutTicket === cutId &&
              cut.colors.map(
                color =>
                  color.color === colorId &&
                  color.packings[0].items.map(item =>
                    item.name === sizeName ? (item.remaining = _.toInteger(total)) : null
                  )
              )
          )
      );
      return packing;
    case C.CREATE_CARTON:
      styleId = action.payload.styleId;
      cutId = action.payload.cutTicketId;
      colorId = action.payload.colorId;
      packing = _.cloneDeep(state);
      packing.styles.map(
        style =>
          style.style === styleId &&
          style.cutTickets.map(
            cut =>
              cut.cutTicket === cutId &&
              cut.colors.map(color => {
                if (color.color === colorId) {
                  const k = color.packings[0].props.noEnd - color.packings[0].props.noBegin + 1;
                  const props = color.packings[0].props;
                  color.packings[0].props.group = k === 1 ? `${props.noEnd}` : `${props.noBegin}-${props.noEnd}`;
                  let bool = true;
                  let sum = 0;
                  color.packings[0].items.map(item => {
                    sum += item.planQuantity;
                    if (item.planQuantity * k > item.remaining) {
                      bool = false;
                    }
                  });
                  if (props.nwKgs == 0 || props.gwKgs == 0 || props.l == 0 || props.w == 0 || props.h == 0)
                    bool = false;
                  if (bool && sum > 0) {
                    let packed = color.packings[0];

                    for (let i = color.packings[0].props.noBegin; i <= color.packings[0].props.noEnd; i++) {
                      packed.props.no = i;
                      color.packings.push({
                        props: _.omit(_.cloneDeep(packed.props), ['tempData']),
                        items: _.cloneDeep(packed.items),
                      });
                    }
                    color.packings[0].items.map(item => (item.planQuantity = 0));
                    // color.packings[0].props.noEnd ++ ;
                    // color.packings[0].props.noBegin = color.packings[0].props.noEnd;
                  }
                }
              })
          )
      );
      packing.totalNW = packing.styles.reduce(
        (s, style) =>
          s +
          style.cutTickets.reduce(
            (c, cut) =>
              c +
              cut.colors.reduce(
                (cl, color) =>
                  cl +
                  color.packings.reduce((p, packing) => p + packing.props.nwKgs || 0, 0) -
                  color.packings[0].props.nwKgs,
                0
              ),
            0
          ),
        0
      );

      packing.totalGW = packing.styles.reduce(
        (s, style) =>
          s +
          style.cutTickets.reduce(
            (c, cut) =>
              c +
              cut.colors.reduce(
                (cl, color) =>
                  cl +
                  color.packings.reduce((p, packing) => p + packing.props.gwKgs || 0, 0) -
                  color.packings[0].props.gwKgs,
                0
              ),
            0
          ),
        0
      );

      return packing;
    case C.DELETE_CARTON:
      packing = _.cloneDeep(state);
      styleId = action.payload.styleId;
      cutId = action.payload.cutId;
      colorId = action.payload.colorId;
      let group = action.payload.no;
      packing.styles.map(
        style =>
          style.style === styleId &&
          style.cutTickets.map(
            cut =>
              cut.cutTicket === cutId &&
              cut.colors.map(color => {
                if (color.color === colorId) {
                  const tempPacking = color.packings.find(
                    (packing, index) => packing && packing.props.group == group && index > 0
                  );
                  color.packings = color.packings.filter(
                    (packing, index) => (packing && packing.props.group != group) || index == 0
                  );
                  if (color.packings[0].length == 1) {
                    color.packings[0] = { ...tempPacking, props: { ...tempPacking.props, noBegin: 1, noEnd: 1 } };
                  }
                }
              })
          )
      );
      return packing;
    case C.CHANGE_TOTAL:
      packing = _.cloneDeep(state);
      name = action.payload.name;
      value = action.payload.value;
      packing[name] = Number(value);
      return packing;
    case E.GET_PACKING:
      let temp = _.cloneDeep(action.payload);
      const packingStyles = _.cloneDeep(action.temp);
      if (temp.type === 'Carton') {
        packingStyles &&
          _.map(temp.styles, (style, sIdx) =>
            _.map(style.cutTickets, (cut, cIdx) =>
              _.map(cut.colors, (color, clIdx) => {
                const props = !_.isEmpty(color.packings) ? color.packings[color.packings.length - 1].props || {} : {};
                const packing = {
                  props: {
                    no: props.no || 0,
                    group: '',
                    noEnd: props.no || 1,
                    noBegin: props.no || 1,
                    nwKgs: 0,
                    gwKgs: 0,
                    l: 0,
                    w: 0,
                    h: 0,
                  },
                  items:
                    packingStyles[sIdx] &&
                    packingStyles[sIdx].cutTickets[cIdx] &&
                    packingStyles[sIdx].cutTickets[cIdx].colors[clIdx]
                      ? _.map(packingStyles[sIdx].cutTickets[cIdx].colors[clIdx].sizes, size => {
                          return { name: size.name, planQuantity: 0 };
                        })
                      : [],
                };
                color.packings.splice(0, 0, packing);
              })
            )
          );
      }
      return temp;
    case E.CHANGE_PACKING_ACTUAL_NUMBER:
      packing = _.cloneDeep(state);
      value = action.payload.value;
      styleId = action.payload.styleId;
      cutTicketId = action.payload.cutTicketId;
      colorId = action.payload.colorId;
      sizeName = action.payload.sizeName;
      no = action.payload.no;
      packing.styles.map(
        style =>
          style.style === styleId &&
          style.cutTickets.map(
            cut =>
              cut.cutTicket === cutTicketId &&
              cut.colors.map(
                color =>
                  color.color === colorId &&
                  color.packings.map(
                    packing =>
                      packing.props &&
                      packing.props.no == no &&
                      packing.props.group != '' &&
                      packing.items.map(item =>
                        item.name === action.payload.sizeName ? (item.actualQuantity = _.toInteger(value)) : 0
                      )
                  )
              )
          )
      );
      return packing;
    case E.CHANGE_GWKGSNWKGS_ACTUAL_NUMBER:
      packing = _.cloneDeep(state);
      value = action.payload.value;
      styleId = action.payload.styleId;
      cutTicketId = action.payload.cutTicketId;
      colorId = action.payload.colorId;
      sizeName = action.payload.sizeName;
      no = action.payload.no;
      packing.styles.map(
        style =>
          style.style === styleId &&
          style.cutTickets.map(
            cut =>
              cut.cutTicket === cutTicketId &&
              cut.colors.map(
                color =>
                  color.color === colorId &&
                  color.packings.map(
                    packing =>
                      packing.props &&
                      packing.props.no == no &&
                      packing.props.group != '' &&
                      packing.items.map(item => (item.name === action.payload.sizeName ? (item.value = value) : 0))
                  )
              )
          )
      );
      return packing;
    default:
      return state;
  }
};

const success = (state = null, action) => {
  switch (action.type) {
    case C.SAVE:
      return action.payload;
    case C.CHANGE_EMPTY_DATA:
      return action.payload;
    default:
      return state;
  }
};

export const addPacking = combineReducers({
  success,
  customers,
  customer,
  customerPOs,
  customerPO,
  contractors,
  contractor,
  packingType,
  cartonType,
  packingByOrder,
  packing,
});
