import React from "react";
import PropTypes from 'prop-types';
import DateTimeField from 'react-datetime';
import moment from 'moment';

const SubVATCard = props => (
  <div className="bg-white wrap-input-global pd-15-0 margin-small">
    <div className="col-md-6 col-xs-12 input">
      <ul>
        <li>
          <span>VAT No</span>
        </li>
        <li>
          <input
            type="text"
            className="form-control"
            value={props.vatNo}
            onChange={e => props.changeVATNo(e.target.value)}
          />
        </li>
      </ul>
    </div>
    <div className="col-md-6 col-xs-12 input">
      <ul>
        <li>
          <span>Total Amount</span>
        </li>
        <li>
          <span className="color-number bold">
            {props.isChangeTotalAmount ?
              <input
                type="number"
                className="form-control"
                value={props.totalAmount}
                onChange={e => props.changeVATTotalAmount(e.target.value)}
              /> : props.totalAmount
            }
          </span>
        </li>
      </ul>
    </div>
    <div className="col-md-6 col-xs-12 input">
      <ul>
        <li>
          <span>VAT Date</span>
        </li>
        <li>
          <DateTimeField
            timeFormat={false}
            closeOnSelect dateFormat="MMM DD, YYYY"
            value={moment(props.vatDate)}
            onChange={date => {
              props.changeVATDate(date.format('YYYY-MM-DD'));
            }}
          />
        </li>
      </ul>
    </div>
    <div className="col-md-6 col-xs-12 input">
      <ul>
        <li>
          <span>VAT Amount</span>
        </li>
        <li>
          <span className="color-number bold">
            {props.vatAmount}
          </span>
        </li>
      </ul>
    </div>
    <div className="col-md-6 col-xs-12 input">
      <ul>
        <li>
          <span>Tax Rate</span>
        </li>
        <li>
          <input 
            type="number"
            min = "0"
            step = "0.01" 
            className="form-control" 
            value={props.taxRate}
            onChange={e => props.changeTaxRate(e.target.value)}
          />
        </li>
      </ul>
    </div>
    <div className="col-md-6 col-xs-12 input">
      <ul>
        <li>
          <span>Grand Total VAT</span>
        </li>
        <li>
          <span className="color-number bold">{props.grandTotal}</span>
        </li>
      </ul>
    </div>

    <div className="col-xs-12 input h-auto">
      <ul>
        <li>
          <span>Note</span>
        </li>
        <li className="textarea">
          <textarea 
            rows="4" 
            cols="50" 
            value={props.note}
            onChange={e => props.changeNote(e.target.value)}
          />
        </li>
      </ul>
    </div>
  </div>
);

SubVATCard.propTypes = {
  vatNo: PropTypes.string.isRequired,
  totalAmount: PropTypes.string.isRequired,
  vatDate: PropTypes.string.isRequired,
  vatAmount: PropTypes.string.isRequired,
  taxRate: PropTypes.string.isRequired,
  grandTotal: PropTypes.string.isRequired,
  note: PropTypes.string.isRequired,
  isChangeTotalAmount: PropTypes.bool,

  changeVATTotalAmount: PropTypes.func,
  changeVATNo: PropTypes.func,
  changeVATDate: PropTypes.func,
  changeTaxRate: PropTypes.func,
  changeNote: PropTypes.func,
}

SubVATCard.defaultProps = {

  changeVATNo: f => f,
  changeVATDate: f => f,
  changeTaxRate: f => f,
  changeNote: f => f,
}

export default SubVATCard;
