import Base from './base';

class CutTicket extends Base {
  constructor () {
    super();

    this.getListCutTicketDAL = this.getListCutTicketDAL.bind(this);
    this.addCutTicketDAL = this.addCutTicketDAL.bind(this);
    this.editCutTicketDAL = this.editCutTicketDAL.bind(this);
    this.editColorCutTicketDAL = this.editColorCutTicketDAL.bind(this);
    this.getCutTicketDAL = this.getCutTicketDAL.bind(this);
    this.deleteCutTicketDAL = this.deleteCutTicketDAL.bind(this);
    this.updateCutTicketColorProgress = this.updateCutTicketColorProgress.bind(this);
    this.updateCutTicketColorQAProgress = this.updateCutTicketColorQAProgress.bind(this);
    this.deleteCutTicketColorProgress = this.deleteCutTicketColorProgress.bind(this);
    this.deleteCutTicketColorQAProgress = this.deleteCutTicketColorQAProgress.bind(this);
  }

  getListCutTicketDAL (styleId, query) {
    return super.execute(this.Urls.cutTicket.list.replace('{id}', styleId), this.ApiMethod.get, query)
      ;
  }

  addCutTicketDAL (styleId, body) {
    return super.execute(this.Urls.cutTicket.create.replace('{id}', styleId), this.ApiMethod.post, body)
        ;
  }

  editCutTicketDAL (id, body) {
    return super.execute(this.Urls.cutTicket.update + '/' + id, this.ApiMethod.put, body)
        ;
  }

  editColorCutTicketDAL (id, body) {
    return super.execute(this.Urls.cutTicket.update + '/' + id, this.ApiMethod.put, body);
  }

  updateCutTicketColorProgress (id, body) {
    return super.execute(this.Urls.cutTicket.cutTicketColorProgress.replace('{id}', id), this.ApiMethod.put, body);
  }

  updateCutTicketColorQAProgress (id, body) {
    return super.execute(this.Urls.cutTicket.cutTicketColorQAProgress.replace('{id}', id), this.ApiMethod.put, body);
  }

  deleteCutTicketColorProgress (id, body) {
    return super.execute(this.Urls.cutTicket.cutTicketColorProgress.replace('{id}', id), this.ApiMethod.delete, body);
  }

  deleteCutTicketColorQAProgress (id, body) {
    return super.execute(this.Urls.cutTicket.cutTicketColorQAProgress.replace('{id}', id), this.ApiMethod.delete, body);
  }

  getCutTicketDAL (id, options) {
    return super.execute(this.Urls.cutTicket.detail + '/' + id, this.ApiMethod.get, options)
        ;
  }

  deleteCutTicketDAL (id) {
    return super.execute(this.Urls.cutTicket.delete + '/' + id, this.ApiMethod.delete, null)
        ;
  }

}

export default new CutTicket();
