import Base from './base';

class StockOut extends Base {
  constructor () {
    super();

    this.getListItemDAL = this.getListItemDAL.bind(this);
    this.addItemDAL = this.addItemDAL.bind(this);
    this.editItemDAL = this.editItemDAL.bind(this);
    this.getItemDAL = this.getItemDAL.bind(this);
    this.deleteItemDAL = this.deleteItemDAL.bind(this);
    this.getMaterialsDAL = this.getMaterialsDAL.bind(this);
  }

  getListItemDAL (query) {
    return super.execute(this.Urls.stockOut.list, this.ApiMethod.get, query);
  }

  addItemDAL (body) {
    return super.execute(this.Urls.stockOut.create, this.ApiMethod.post, body);
  }

  editItemDAL (id, body) {
    return super.execute(this.Urls.stockOut.update + '/' + id, this.ApiMethod.put, body);
  }

  getItemDAL (id, options) {
    return super.execute(this.Urls.stockOut.detail + '/' + id, this.ApiMethod.get, options);
  }

  deleteItemDAL (id) {
    return super.execute(this.Urls.stockOut.delete + '/' + id, this.ApiMethod.delete, null);
  }

  getMaterialsDAL (styleId, options) {
    return super.execute(this.Urls.stockOut.getMaterials.replace('{id}', styleId), this.ApiMethod.get, options);
  }
}

export default new StockOut();
