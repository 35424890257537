import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { toastr } from 'react-redux-toastr';
import I18n from 'i18n-js';
import _ from 'lodash';

import * as PATH from '../../../../constants/routeConstants';
import * as actions from '../../../../actions/accounting/receivable/editInvoiceNormal';
import * as selectors from '../../../../selectors/accounting/receivable/editInvoiceNormal';

import AccountsEditReceivableInvoiceView from '../../../components/accounting/receivable/EditNormalReceivableInvoice';

class AccountsEditReceivableInvoice extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillMount() {
    this.props.loadData();
  }

  componentWillReceiveProps(nextProps) {
    let { saveSuccess } = nextProps;
    if (saveSuccess) {
      nextProps.reset();
      toastr.success(I18n.t('Notify.Success'), 'Save Success');
      nextProps.history.push(PATH.ACCOUNTING_RECEIVABLE_INVOICE_URL);
    }
  }

  handleChangeView(value) {
    //
    // if (value == "view-supplier") {
    //     this.props.history.push(PATH.ACCOUNTING_PAYABLE_PLAN_SUPPLIER_URL);
    // }
    // else if (value == "view-contractor") {
    //     this.props.history.push(PATH.ACCOUNTING_PAYABLE_PLAN_CONTRACTOR_URL);
    // }
    // else {
    //     this.props.history.push(PATH.ACCOUNTING_PAYABLE_PLAN_IMPORTEXPORT_URL);
    // }
  }
  render() {
    return <AccountsEditReceivableInvoiceView {...this.props} />;
  }
}

AccountsEditReceivableInvoice.propTypes = {};

AccountsEditReceivableInvoice.defaultProps = {};

const mapStateToProps = state => ({
  invoice: selectors.getInvoiceDetail(state),
  selectableCustomers: selectors.getSelectableCustomers(state),
  selectedCustomer: selectors.getSelectedCustomer(state),
  selectableCreditDebits: selectors.getSelectableCreditDebits(state),
  selectedCreditDebit: selectors.getSelectedCreditDebit(state),
  isVATPerCustomer: selectors.isVATPerCustomer(state),
  invoiceNo: selectors.getInvoiceNo(state),
  creditForSeaFreight: selectors.getCreditForSeaFreight(state),
  selectablePriceTypes: selectors.getSelectablePriceTypes(state),
  priceType: selectors.getSelectedPriceType(state),
  issuedDate: selectors.getIssuedDate(state),
  dueDate: selectors.getDueDate(state),
  selectableConvertCurrencies: selectors.getSelectableConvertCurrencies(state),
  selectableShippingMode: selectors.getSelectableShippingModes(state),
  selectedShippingMode: selectors.getSelectedShippingMode(state),
  convertType: selectors.getSelectedConvertCurrency(state),
  convertRate: selectors.getConvertRate(state),
  convertAmount: selectors.getConvertAmount(state),
  mainVATAmount: selectors.getMainVATAmount(state),
  mainVATDate: selectors.getMainVATDate(state),
  mainVATTaxRate: selectors.getMainVATTaxRate(state),
  mainVATNo: selectors.getMainVATNo(state),
  mainVATGrandTotal: selectors.getMainVATGrandTotal(state),
  mainVATTotalAmount: selectors.getMainVATTotalAmount(state),
  fetchingSelectableCustomer: selectors.isFetchingSelectableCustomer(state),
  checkableBills: selectors.getCheckableBills(state),
  checkedBills: selectors.getCheckedBills(state),
  dataRequestShipOut: selectors.getDataRequestShipOutByCustomer(state),
  dataApi: selectors.getDataApi(state),
  amountInv: selectors.getAmountInv(state),
  totalAirCost: selectors.getTotalAirCost(state),
  totalAmountInv: selectors.getTotalAmountInv(state),
  totalCreditForSeaFreight: selectors.getTotalCreditForSeaFreight(state),
  saveSuccess: selectors.getSuccess(state),

  receiveType: selectors.getReceiveType(state),
  receiveNote: selectors.getReceiveNote(state),
  receiveAmount: selectors.getReceiveAmount(state),
  receiveFiles: selectors.getReceiveFiles(state),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  loadData() {
    dispatch(actions.listCustomer());
  },
  changeSelectedCustomer(id) {
    dispatch(actions.resetData());
    dispatch(actions.changeSelectedCustomer(id));
    if (id && id.customer && id.customer.value) {
      dispatch(actions.listCreditDebitNote(id.customer.value));
    }
  },
  changeSelectedCreditDebit(data) {
    dispatch(actions.changeSelectedCreditDebit(data));
  },
  changeVATPerCustomer(checked) {
    dispatch(actions.changeVATPerCustomer(checked));
  },
  changeInvoiceNo(value) {
    dispatch(actions.changeInvoiceNo(value));
  },
  changeCreditForSeaFreight(value) {
    dispatch(actions.changeCreditForSeaFreight(value));
  },
  changePriceType(value) {
    dispatch(actions.changePriceType(value));
  },
  changeIssuedDate(value) {
    dispatch(actions.changeIssuedDate(value));
  },
  changeDueDate(value) {
    dispatch(actions.changeDueDate(value));
  },
  changeShippingMode(value) {
    dispatch(actions.changeShippingMode(value));
  },
  changeConvertType(value) {
    dispatch(actions.changeConvertType(value));
  },
  changeConvertRate(value, totalAmount) {
    dispatch(actions.changeConvertRate(value));
    totalAmount = totalAmount || 0;
    value = value || 0;
    let totalConvert = parseFloat(totalAmount * value).toFixed(2);
    dispatch(actions.changeConvertAmount(totalConvert == 0 ? null : totalConvert));
  },
  changeCheckedBill(data) {
    dispatch(actions.changeCheckedBill(data));
  },
  changeSearchText(value) {
    dispatch(actions.changeSearchText(data));
  },
  changeUnitPrice(billNo, customerPO, index, value) {
    dispatch(actions.changeUnitPrice({ billNo, customerPO, index, value }));
  },
  changeAirCost(billNo, customerPO, value) {
    dispatch(actions.changeAirCost({ billNo, customerPO, value }));
  },
  changeNoteBill(billNo, customerPO, value) {
    dispatch(actions.changeNoteBill({ billNo, customerPO, value }));
  },
  //--------- main VAT ---------//
  changeMainVATNo(value) {
    dispatch(actions.changeMainVATNo(value));
  },

  changeMainVATTotalAmount(value) {
    dispatch(actions.changeMainVATTotalAmount(value));
  },

  changeMainVATDate(value) {
    dispatch(actions.changeMainVATDate(value));
  },

  changeMainVATTaxRate(value) {
    dispatch(actions.changeMainVATTaxRate(value));
  },

  changeReceiveType(value) {
    if (value === 'Cash') {
      dispatch(actions.changeReceiveFile([]));
    }
    dispatch(actions.changeReceiveType(value));
  },

  changeReceiveNote(value) {
    dispatch(actions.changeReceiveNote(value));
  },

  changeReceiveAmount(value) {
    dispatch(actions.changeReceiveAmount(value));
  },

  changeReceiveFile(files, receiveFiles) {
    let file = files[0];
    var FileSize = file.size / 1024 / 1024; // in MB
    if (FileSize > 2) {
      toastr.error('Error', I18n.t('ErrorMessages.FileSizeExceeds2MB'));
      return;
    }
    let payload = receiveFiles || [];
    if (_.findIndex(payload, f => f.fileName == file.name) > -1) {
      toastr.error('Error', 'Duplicated file name');
      return;
    }
    const readerBase64 = new FileReader();
    readerBase64.onload = eventBase64 => {
      const url = eventBase64.target.result;
      const filename = file.name;

      payload.push({ id: filename, file, filename, url });
      dispatch(actions.changeReceiveFile(payload));
    };
    readerBase64.readAsDataURL(file);
  },

  removeReceiveFile(file, files) {
    let payload = [];
    let receiveFiles = files || [];
    _.forEach(receiveFiles, f => {
      if (f.fileName != file.fileName) {
        payload.push(f);
      }
    });
    dispatch(actions.changeReceiveFile(payload));
  },
  //--------- sub VAT ---------//
  changeSubVATTotalAmount(billNo, value) {
    dispatch(actions.changeSubVATTotalAmount({ billNo, value }));
  },
  changeSubVATNo(billNo, value) {
    dispatch(actions.changeSubVATNo({ billNo, value }));
  },
  changeSubVATDate(billNo, value) {
    dispatch(actions.changeSubVATDate({ billNo, value }));
  },
  changeSubVATTaxRate(billNo, value) {
    dispatch(actions.changeSubVATTaxRate({ billNo, value }));
  },
  changeSubVATNote(billNo, value) {
    dispatch(actions.changeSubVATNote({ billNo, value }));
  },
  getSubVATAmount(totalAmount, taxRate) {
    return ((parseFloat(totalAmount || 0) * parseFloat(taxRate || 0)) / 100).toFixed(2);
  },
  getSubVATGrandTotal(totalAmount, taxRate) {
    return ((parseFloat(totalAmount || 0) * parseFloat(taxRate || 0)) / 100 + parseFloat(totalAmount || 0)).toFixed(2);
  },
  save(data) {
    dispatch(actions.saveInvoice(data));
  },
  reset() {
    dispatch(actions.resetData());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AccountsEditReceivableInvoice));
