import React, { Component } from 'react';
import RevenueReportView from '../../components/reportAnalytic/revenueReport';
class RevenueReport extends Component {
    render() {
        return (
            <div>
                <RevenueReportView />
            </div>
        );
    }
}

export default RevenueReport;