import React from 'react';
import PropTypes from 'prop-types';
import { Link, Switch, Route, Redirect } from 'react-router-dom';
import { withRouter } from "react-router";
import { connect } from 'react-redux';
import BaseComponent from '../base/BaseComponent';
import I18n from 'i18n-js';
import AccountsPayablePlanRoutes from '../../ui/containers/accounting/payable/AccountsPayablePlanRoutes';

import AccountsInvoiceList from '../../ui/containers/accounting/PayableInvoiceList/AccountsListInvoice';
import AccountsPayableHistory from '../../ui/containers/accounting/payableHistoryList/ShowHistoryList';

import * as PATH from '../../constants/routeConstants';

class ScreenAccountsPayable extends BaseComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  render() {
    const pathname = this.props.router.route.location.pathname || '';
    let {roles} = this.props;
    return (
      <div className='show'>
        <div className="global-table-tab">
          {!(pathname == PATH.ACCOUNTING_PAYABLE_PLAN_SUPPLIER_DOMESTIC_PREVIEW_URL || 
          pathname == PATH.ACCOUNTING_PAYABLE_PLAN_SUPPLIER_OVERSEA_PREVIEW_URL || 
          pathname == PATH.ACCOUNTING_PAYABLE_PLAN_CONTRACTOR_PREVIEW_URL ||
          pathname == PATH.ACCOUNTING_PAYABLE_PLAN_IMPORTEXPORT_CONTAINER_PREVIEW_URL ||
          pathname == PATH.ACCOUNTING_PAYABLE_PLAN_IMPORTEXPORT_DECLARATION_PREVIEW_URL ||
          pathname == PATH.ACCOUNTING_PAYABLE_PLAN_IMPORTEXPORT_US_DELIVERY_PREVIEW_URL
          )
          &&
            <ul className={`nav nav-tabs wrap-tab ${!pathname.startsWith(PATH.ACCOUNTING_PAYABLE_PLAN_URL) && 'accounting-tabs'}`}>
              <li
                className={pathname.startsWith(PATH.ACCOUNTING_PAYABLE_PLAN_URL) ? "active" : ''}
              >
                <Link to={PATH.ACCOUNTING_PAYABLE_PLAN_URL}
                  aria-controls="tab-plan" role="tab"
                >
                  {I18n.t('ScreenAccountsPayable.Plan')}
                </Link>
              </li>

              <li
                className={pathname.startsWith(PATH.ACCOUNTING_PAYABLE_INVOICE_URL) ? "active" : ''}
              >
                <Link to={PATH.ACCOUNTING_PAYABLE_INVOICE_URL}
                  aria-controls="tab-invoice-list" role="tab"
                >
                  {I18n.t('ScreenAccountsPayable.InvoiceList')}
                </Link>
              </li>

              <li
                className={pathname.startsWith(PATH.ACCOUNTING_PAYABLE_HISTORY_URL) ? "active" : ""}
              >
                <Link to={PATH.ACCOUNTING_PAYABLE_HISTORY_URL}
                  aria-controls="History" role="tab"
                >
                  {I18n.t('ScreenAccountsPayable.History')}
                </Link>
              </li>

            </ul>
          }

          <div className="tab-content bg-none">
            <Switch>
              <Route path={PATH.ACCOUNTING_PAYABLE_URL} exact render={() => <Redirect to={PATH.ACCOUNTING_PAYABLE_PLAN_URL} />} />

              <Route path={PATH.ACCOUNTING_PAYABLE_PLAN_URL} render={() => <AccountsPayablePlanRoutes pathname={pathname} roles={roles}/>} />

              <Route path={PATH.ACCOUNTING_PAYABLE_INVOICE_URL} render={() => (
                <div role="tabpanel" className="tab-pane active position-relative" id="tab-invoice-list">
                  <AccountsInvoiceList router={this.props.router} roles={roles}/>
                </div>
              )} />

              <Route path={PATH.ACCOUNTING_PAYABLE_HISTORY_URL} render={() => (
                <AccountsPayableHistory router={this.props.router} roles={roles}/>
              )} />

            </Switch>
          </div>
        </div>

        <div className="modal fade" id="modal-add-accounting-invoice" tabIndex="-1" role="dialog">
          <div className="modal-dialog" role="document">
            <div className="modal-content">

              <div className="modal-header">
                <a className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></a>
                <h4 className="modal-title" id="myModalLabel">{I18n.t('ScreenAccountsPayable.ADDINVOICE')}</h4>
              </div>
              <div className="modal-body">

                <div>
                  <ul className="nav nav-tabs col-xs-12 wrap-list-radio" role="tablist">
                    <li role="presentation" className="col-sm-6 col-xs-12 active">
                      <a href="#tab-invoice-contractor" aria-controls="tab-invoice-contractor" role="tab"
                        data-toggle="tab">
                        <input type="radio" id="invoice-contractor" name="invoice-contractor" value="0" />
                        <label>{I18n.t('ScreenAccountsPayable.Contractor')}</label>
                        <div className="check">
                          <div className="inside" />
                        </div>
                      </a>
                    </li>
                    <li role="presentation" className="col-sm-6 col-xs-12">
                      <a href="#tab-invoice-container-transporter" aria-controls="tab-invoice-container-transporter"
                        role="tab" data-toggle="tab">
                        <input type="radio" id="invoice-container-transporter" name="invoice-container-transporter"
                          value="0" />
                        <label>{I18n.t('ScreenAccountsPayable.ContainerTransporter')}</label>
                        <div className="check">
                          <div className="inside" />
                        </div>
                      </a>
                    </li>
                    <li role="presentation" className="col-sm-6 col-xs-12">
                      <a href="#tab-invoice-supplier" aria-controls="tab-invoice-supplier" role="tab" data-toggle="tab">
                        <input type="radio" id="invoice-supplier" name="invoice-supplier" value="0" />
                        <label>{I18n.t('ScreenAccountsPayable.Supplier')}</label>
                        <div className="check">
                          <div className="inside" />
                        </div>
                      </a>
                    </li>
                    <li role="presentation" className="col-sm-6 col-xs-12">
                      <a href="#tab-invoice-import-export" aria-controls="tab-invoice-import-export" role="tab"
                        data-toggle="tab">
                        <input type="radio" id="invoice-import-export" name="invoice-import-export" value="0" />
                        <label>{I18n.t('ScreenAccountsPayable.ImportExport')}</label>
                        <div className="check">
                          <div className="inside" />
                        </div>
                      </a>
                    </li>
                  </ul>

                  <div className="tab-content">
                    <div role="tabpanel" className="tab-pane active" id="tab-invoice-contractor">
                      <div className="form-group">
                        <div className="col-md-4 col-xs-12 text">
                          <p>
                            {I18n.t('ScreenAccountsPayable.InvoiceNo')}
                          </p>
                        </div>
                        <div className="col-md-8 col-xs-12 value">
                          <input type="text" placeholder="Invoice No" className="form-control" />
                        </div>
                      </div>

                      <div className="form-group">
                        <div className="col-md-4 col-xs-12 text">
                          <p>
                            {I18n.t('ScreenAccountsPayable.IssuedDate')}
                          </p>
                        </div>
                        <div className="col-md-8 col-xs-12 value">
                          <input type="text" placeholder="Issued Date" className="form-control" />
                        </div>
                      </div>

                      <div className="form-group">
                        <div className="col-md-4 col-xs-12 text">
                          <p>
                            {I18n.t('ScreenAccountsPayable.DueDate')}
                          </p>
                        </div>
                        <div className="col-md-8 col-xs-12 value">
                          <input type="text" placeholder="Due Date" className="form-control" />
                        </div>
                      </div>

                      <div className="form-group">
                        <div className="col-md-4 col-xs-12 text">
                          <p>
                            {I18n.t('ScreenAccountsPayable.Contractor')}
                          </p>
                        </div>

                        <div className="col-md-8 col-xs-12 value">
                          <select className="form-control">
                            <option value="">Lang May 1</option>
                            <option value="">Lang May 2</option>
                          </select>
                        </div>

                      </div>


                    </div>

                    <div role="tabpanel" className="tab-pane" id="tab-invoice-container-transporter">

                      <div className="form-group">
                        <div className="col-md-4 col-xs-12 text">
                          <p>
                            {I18n.t('ScreenAccountsPayable.Name')}
                          </p>
                        </div>
                        <div className="col-md-8 col-xs-12 value">
                          <input type="text" placeholder="Name" className="form-control" />
                        </div>
                      </div>

                      <div className="form-group">
                        <div className="col-md-4 col-xs-12 text">
                          <p>
                            {I18n.t('ScreenAccountsPayable.IssuedDate')}
                          </p>
                        </div>
                        <div className="col-md-8 col-xs-12 value">
                          <input type="text" placeholder="Issued Date" className="form-control" />
                        </div>
                      </div>

                      <div className="form-group">
                        <div className="col-md-4 col-xs-12 text">
                          <p>
                            {I18n.t('ScreenAccountsPayable.DueDate')}
                          </p>
                        </div>
                        <div className="col-md-8 col-xs-12 value">
                          <input type="text" placeholder="Due Date" className="form-control" />
                        </div>
                      </div>

                    </div>

                    <div role="tabpanel" className="tab-pane" id="tab-invoice-supplier">

                      <div className="form-group">
                        <div className="col-md-4 col-xs-12 text">
                          <p>
                            {I18n.t('ScreenAccountsPayable.InvoiceNo')}
                          </p>
                        </div>
                        <div className="col-md-8 col-xs-12 value">
                          <input type="text" placeholder="Invoice No" className="form-control" />
                        </div>
                      </div>

                      <div className="form-group">
                        <div className="col-md-4 col-xs-12 text">
                          <p>
                            {I18n.t('ScreenAccountsPayable.IssuedDate')}
                          </p>
                        </div>
                        <div className="col-md-8 col-xs-12 value">
                          <input type="text" placeholder="Issued Date" className="form-control" />
                        </div>
                      </div>

                      <div className="form-group">
                        <div className="col-md-4 col-xs-12 text">
                          <p>
                            {I18n.t('ScreenAccountsPayable.DueDate')}
                          </p>
                        </div>
                        <div className="col-md-8 col-xs-12 value">
                          <input type="text" placeholder="Due Date" className="form-control" />
                        </div>
                      </div>

                      <div className="form-group">
                        <div className="col-md-4 col-xs-12 text">
                          <p>
                            {I18n.t('ScreenAccountsPayable.Supplier')}
                          </p>
                        </div>

                        <div className="col-md-8 col-xs-12 value">
                          <select className="form-control">
                            <option value="">Emma Greene</option>
                            <option value="">Emma Greene</option>
                          </select>
                        </div>

                      </div>


                    </div>

                    <div role="tabpanel" className="tab-pane" id="tab-invoice-import-export">

                      <div className="form-group">
                        <div className="col-md-4 col-xs-12 text">
                          <p>
                            {I18n.t('ScreenAccountsPayable.From')}
                          </p>
                        </div>
                        <div className="col-md-8 col-xs-12 value">
                          <input type="text" placeholder="From" className="form-control" />
                        </div>
                      </div>

                      <div className="form-group">
                        <div className="col-md-4 col-xs-12 text">
                          <p>
                            {I18n.t('ScreenAccountsPayable.To')}
                          </p>
                        </div>
                        <div className="col-md-8 col-xs-12 value">
                          <input type="text" placeholder="To" className="form-control" />
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

              </div>
              <div className="modal-footer">
                <button type="button" className="bt-black mg-right-15" data-dismiss="modal">{I18n.t('Btn.CANCEL')}</button>
                <button type="button" className="bt-orange">{I18n.t('Btn.NEXT')}</button>
              </div>

            </div>
          </div>
        </div>
      </div>
    );
  }
}

ScreenAccountsPayable.propTypes = {
  authState: PropTypes.object.isRequired
};


const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ScreenAccountsPayable));

