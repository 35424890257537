const C = "ADD_SUPPLIER";

export default {
    CHANGE_NAME: `${C}_CHANGE_NAME`,
    CHANGE_SHORT_NAME: `${C}_CHANGE_SHORT_NAME`,
    CHANGE_SHORT_NAME: `${C}_CHANGE_SHORT_NAME`,
    CHANGE_BANK_ACCOUNT_NO: `${C}_CHANGE_BANK_ACCOUNT_NO`,
    CHANGE_BANK_BRANCH: `${C}_CHANGE_BANK_BRANCH`,
    CHANGE_COMPANY_NAME: `${C}_CHANGE_COMPANY_NAME`,
    CHANGE_COMPANY_ADDRESS: `${C}_CHANGE_COMPANY_ADDRESS`,
    CHANGE_DELIVERY_DEVIATION: `${C}_CHANGE_DELIVERY_DEVIATION`,
    CHANGE_PHONE_NUMBER: `${C}_CHANGE_PHONE_NUMBER`,
    CHANGE_LIST_PAYMENT_TERM: `${C}_CHANGE_LIST_PAYMENT_TERM`,
    CHANGE_PAYMENT_TERM: `${C}_CHANGE_PAYMENT_TERM`,
    CHANGE_FAX: `${C}_CHANGE_FAX`,
    CHANGE_LIST_CURRENCY: `${C}_CHANGE_LIST_CURRENCY`,
    CHANGE_CURRENCY: `${C}_CHANGE_CURRENCY`,
    CHANGE_EMAIL: `${C}_CHANGE_EMAIL`,
    CHANGE_LIST_COLOR: `${C}_CHANGE_LIST_COLOR`,
    CHANGE_TYPE: `${C}_CHANGE_TYPE`,
    CHANGE_LIST_SIZE: `${C}_CHANGE_LIST_SIZE`,
    CHANGE_SIZE: `${C}_CHANGE_SIZE`,
    CHANGE_PRICE: `${C}_CHANGE_PRICE`,
    GET_LIST_MATERIAL: `${C}_GET_LIST_MATERIAL`,
    CHANGE_MATERIAL: `${C}_CHANGE_MATERIAL`,
    CHANGE_PROPERTY_MATERIAL: `${C}_CHANGE_PROPERTY_MATERIAL`,
    ADD_MATERIAL: `${C}_ADD_MATERIAL`,
    REMOVE_MATERIAL: `${C}_REMOVE_MATERIAL`,
    CHANGE_SUPPLIER: `${C}_CHANGE_EMPTY_DATA`,
    SAVE: `${C}_SAVE`,
    CHANGE_EMPTY_DATA: `${C}_CHANGE_EMPTY_DATA`
}