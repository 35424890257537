import C from '../../constants/ship-out/showHistory';
import shipOutApi from '../../api/shipOutApi';

export const getList = (options) => dispatch => {
    shipOutApi.getListHistory(options).then(res => {
        if(res.success) {
            dispatch({type: C.CHANGE_DISPLAY_LIST, payload: res.data});
            dispatch({type: C.CHANGE_PAGING_TOTAL, payload: res.meta.pages})
            dispatch({type: C.CHANGE_PAGING_CURRENT, payload: res.meta.page})
        }
    })
}

export const changeSelectedFilter = (value) => dispatch => {
    dispatch({type: C.CHANGE_SELECTED_FILTER, payload: value})
}

export const changeSearchValue = (value, pos) => dispatch => {
    dispatch({type: C.CHANGE_SEARCH_VALUE, payload: {value, pos}})
}

// export const search = (selectedFilter, searchValue) => dispatch => {
//     if(selectedFilter && searchValue) {
//         let op = {history: true};
//         let key = selectedFilter;
//         if(selectedFilter == 'date' || selectedFilter == 'declarationDate' || selectedFilter == 'etd' || selectedFilter == 'eta') {
//             let begin = moment(searchValue.from).format('YYYY-MM-DD');
//             let end = moment(searchValue.to).format('YYYY-MM-DD');
//             let date = `${begin}:${end}`;
//             op[key] = date
//             shipOutApi.getListHistory(op).then(res => {
//                 if(res.success)
//                     dispatch({type: C.CHANGE_DISPLAY_LIST, payload: res.data})
//             })
//         } else {
//             op[key] = searchValue.value
//             shipOutApi.getListHistory(op).then(res => {
//                 if(res.success)
//                     dispatch({type: C.CHANGE_DISPLAY_LIST, payload: res.data})
//             })
//         }
//     }
// }

export const changeSearchStatus = (value) => dispatch => {
    dispatch({type: C.CHANGE_SEARCH_STATUS, payload: value})
}