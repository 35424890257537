import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import FormGroup from '../forms/FormGroup';
import Input from '../forms/Input';

const ConvertCurrencyCard = props => {
  return (
    <div className="box-shadow">
      <div className="wrap-input-global bg-white float-left">
        <div className="col-md-4 col-xs-12 input" style={{ paddingLeft: 0 }}>
          <FormGroup
            label="Convert to"
            type="select"
            inputComponent={
              <select
                className="col-md-12"
                disabled={props.disableChangeValueCurrency}
                value={props.selectedCurrency}
                onChange={e => {
                  const { value } = e.target;
                  const o = _.find(props.selectableCurrencies, it => it.value === value);
                  props.changeSelectedCurrency(o);
                }}>
                <option key="empty">Select...</option>
                {_.map(props.selectableCurrencies, item => (
                  <option key={item.value} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
            }
          />
        </div>

        <div className="col-md-4 col-xs-12 input">
          <FormGroup
            type="input"
            label="Rate"
            inputComponent={
              <Input
                type="number"
                min="0"
                disabled={props.disableChangeValue}
                value={props.rate}
                onChange={e => props.changeRate(e.target.value)}
              />
            }
          />
        </div>

        <div className="col-md-4 col-xs-12 text bd-dashed-left">
          <p>
            Amount Convert <span className="mg-left-15 color-number bold size-13">{props.amount}</span>
          </p>
          <p>
            Total Amount Convert 3 <span className="mg-left-15 color-number bold size-13">{props.totalAmount}</span>
          </p>
        </div>
      </div>
    </div>
  );
};

ConvertCurrencyCard.propTypes = {
  selectableCurrencies: PropTypes.arrayOf(PropTypes.any).isRequired,

  // String, indicate the value provided above
  selectedCurrency: PropTypes.string.isRequired,

  rate: PropTypes.string.isRequired,
  amount: PropTypes.string.isRequired,
  totalAmount: PropTypes.string.isRequired,

  changeSelectedCurrency: PropTypes.func,
  changeRate: PropTypes.func,
};

ConvertCurrencyCard.defaultProps = {
  changeSelectedCurrency: f => f,
  changeRate: f => f,
};

export default ConvertCurrencyCard;
