import React, { Component } from 'react';

export default class TextClick extends Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	render() {
		const { value, options } = this.props;
		return (
			<td onClick={() => { options.click(value[1]); }}>
				{value[0]}
			</td>
		);
	}
}