import React, { Component } from 'react';
import { Store } from '../../../../CssHandleModule/functions'
import * as _ from 'lodash';
import I18n from 'i18n-js';
import ColorTable from '../../../../components/shipping/subViews/colorTableAddView';
import { connect } from 'react-redux';
import * as selector from '../../../../../selectors/ship-out/createPklShip';


const store = new Store();
store.setStyles({
    orderNumbers: [1, 2],
    aligns:[
        {order: 1, margin: {left: '10px'}},
        {order: 2, margin: {right: '10px'}}
    ]
});

class cutTicketContainer extends Component {

    state = {
        colors: []
    }

    //This function use for get colors in a cut ticket
    componentWillReceiveProps(nextProps) {
        const cutTicket = { ...nextProps.cutTicket };
        if (!_.isEqual(cutTicket.colors, this.state.colors)) {
            this.setState({ colors: cutTicket.colors });
        }
    }

    //This function use for handle color item and send the end result forward to color component
    setCutTicketComponents = (colors, orderId, styleId, cutId, packingType) => {
        const colorCoponents = colors && colors.map((color, index) => {
            return <ColorTable key={index} data={color} packingType = {packingType} orderId={orderId} styleId={styleId} cutId={cutId}/>
        })
        return colorCoponents;
    }

    render() {
        const { cutTicket, colors, styleId, packingType, orderId} = this.props;
        const colorComponents = cutTicket && this.setCutTicketComponents(colors, orderId, styleId, cutTicket._id, packingType);
        return (
            <div>
                <div className="d-flex justify-content-between" style={{
                    height: 40,
                    paddingTop: 10,
                    fontSize: 15
                }}>
                    <div className="d-flex">
                        <p className="size-12">{I18n.t('PageShiping.CutTicket')}</p>
                        <span className="color-orange bold mg-left-50 size-14">{cutTicket.cutTicket}</span>
                    </div>

                    <div className="d-flex justify-content-end">
                        <div className="d-flex mg-right-30">
                            <p className="size-12 mg-right-15">{I18n.t('PageShiping.Lable')}</p>
                            <span className="size-12 bold">{cutTicket.mainLabel || ''}</span>
                        </div>
                        <div className="d-flex mg-right-30">
                            <p className="size-12 mg-right-15">{I18n.t('PageShiping.Store')}</p>
                            <span className="size-12 bold">{cutTicket.store && cutTicket.store.name || ''}</span>
                        </div>
                        <div className="size-12 color-link"><a>{cutTicket.store && cutTicket.store.packingStandard && cutTicket.store.packingStandard.name || ''}</a></div>
                    </div>
                </div>
                {colorComponents}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    packingType: selector.getPackingType(state)
})

export default connect(mapStateToProps, null) (cutTicketContainer);