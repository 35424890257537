import React, { Component } from 'react';
import { Switch, Route } from "react-router-dom";
import { withRouter } from "react-router";
import * as R from 'ramda';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import authActions from '../actions/authActions';
import appActions from '../actions/appActions';
import { getAccessToken } from '../functions/helpers';
import Header from './common/layout/Header';
import Sidebar from './common/layout/Sidebar';
import AlertSuccess from './common/alerts/AlertSuccess';
import * as utils from "../functions/utils";

import {
  LOGIN_URL,
  ACCOUNTING_URL,
  CRM_URL,
  CUSTOMER_PO_HISTORY_VIEW_URL,
  CUSTOMER_PO_URL, DASHBOARD_URL, ORGANIZATION_URL, PACKING_STANDARD_ADD_URL, PRODUCTION_URL,
  PURCHASE_HISTORY_VIEW_URL, PURCHASE_ORDER_URL,
  PURCHASE_PREVIEW_URL,
  PURCHASE_URL, REPORT_ANALYTIC_URL, SAMPLE_APPROVAL_URL, SHIPPING_URL, STANDARD_URL, SYSTEM_SETTING_URL, WAREHOUSE_URL,
  YIELD_URL,
  FORGOT_PASSWORD_URL,
  STANDARD_PACKINGS_EDIT_URL,
} from "../constants/routeConstants";

const ForgotPassword = React.lazy(() => import('./auth/ForgotPassword'));
const PageDashboard = React.lazy(() => import('./dashboard/PageDashboard'));
const PageOrder = React.lazy(() => import('./order/PageOrder'));
const OrderHistoryView = React.lazy(() => import('./order/history/OrderHistoryView'));
const PurchaseHistoryView = React.lazy(() => import('./purchase/history/PurchaseHistoryView'));
const PagePurchase = React.lazy(() => import('./purchase/PagePurchase'));
const PagePurchaseOrder = React.lazy(() => import('./purchase/PagePurchaseOrder'));
const PurchasePreview = React.lazy(() => import('./purchase/purchase/PurchasePreview'));
const PageYield = React.lazy(() => import('./yield/PageYield'));
const PageSampleApproval = React.lazy(() => import('./sampleApproval/PageSampleApproval'));
const PageWareHouse = React.lazy(() => import('./warehouse/PageWareHouse'));
const PageProduction = React.lazy(() => import('./production/PageProduction'));
const PageShipping = React.lazy(() => import('./shipping/PageShipping'));
const PageAccounting = React.lazy(() => import('./accounting/PageAccounting'));
const PageReportAnalytic = React.lazy(() => import('./reportAnalytic/PageReportAnalytic'));
const PageStandard = React.lazy(() => import('./standard/PageStandard'));
const PageOrganization = React.lazy(() => import('./organization/PageOrganization'));
const PackingStandardAdd = React.lazy(() => import('./standard/packingStandard/PackingStandardAdd'));
const PageCrm = React.lazy(() => import('./crm/PageCrm'));
const PageSystemSettings = React.lazy(() => import('./systemSettings/PageSystemSettings'));
const EditPackingStandardContainer = React.lazy(() => import('../ui/containers/standard/packing/EditPackingStandard'));

import loadingIcon from '../stylesheets/images/loading.svg';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpenModal: false,
      menus: [
        { prefix: 'dashboard', url: '/' }
      ],
      isLoadSetting: false
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentWillMount() {
    // let userId = getCookie('userId-ad');
    let token = getAccessToken();
    !token ? this.props.routeTo(LOGIN_URL) : !this.props.authState.auth ? this.props.authPropsActions.getUser() : null;
  }

  componentWillReceiveProps(nextProps) {
    const location = R.path(['router', 'route', 'location'], nextProps);

    if (nextProps.authState.error) {
      this.props.routeTo(LOGIN_URL);
    } else if (location && location.pathname) {
      if (location.pathname === "/") {
        let firstUrl = this.getFirstUrl(nextProps.authState);
        firstUrl && this.props.routeTo(firstUrl);
      }
    }
  }

  componentDidUpdate() {
    // $("[data-toggle='tooltip']").tooltip();
  }

  getFirstUrl(authState) {
    let url = '';
    if (authState && authState.auth && authState.auth.role) {
      const role = authState.auth.role;
      const menus = utils.getMenuByRole(role, authState.auth.isSupperAdmin);
      if (menus && menus.length && menus[0].url) {
        url = menus[0].url
      }
    }
    return url;
  }

  openModal(modal) {
    $("body").addClass('modal-open');
    this.setState({
      modal: modal,
      isOpenModal: true
    });
  }

  closeModal() {
    $("body").removeClass('modal-open');
    this.setState({
      modal: null,
      isOpenModal: false
    });
  }

  renderChildren() {
    const { authState } = this.props;
    return React.Children.map(this.props.children, child => {
      return authState.auth ? React.cloneElement(child, {
        modal: { openModal: this.openModal, closeModal: this.closeModal }
      }) : null;
    });
  }

  render() {
    let loading;
    if (this.props.appState.loading) {
      loading = (
        <div className="modalApp wrap-icon-loading">
          <img src={loadingIcon} />
        </div>
      );
    } else {
      loading = null;
    }

    let modal = this.state.modal;
    let isOpenModal = this.state.isOpenModal;

    if (this.props.appState.alertSuccess) {
      isOpenModal = true;
      modal = <AlertSuccess func={this.props.appState.func} closeModal={this.closeModal} data={this.props.appState.alertSuccess} hideAlert={this.props.appPropsActions.hideAlert} />;
    }

    // if (this.props.appState.alertError) {
    //   isOpenModal = true;
    //   modal = <AlertError func={this.props.appState.func} closeModal={this.closeModal} data={this.props.appState.alertError} hideAlert={this.props.appPropsActions.hideAlert} />;
    // }

    let backdrop;
    if (isOpenModal || this.props.appState.loading) {
      backdrop = (<div className="modalBackdrop" />);
    } else {
      backdrop = null;
    }
    return (
      <div className="main-wrapper" >
        {isOpenModal && (
          <div className={'modalApp' + (this.props.appState.loading ? ' deActive' : '')}>
            {modal}
          </div>
        )}

        {loading}

        {backdrop}
        <div id="app" className={'app' + (this.state.isOpenModal || this.props.appState.loading ? ' deActive' : '')}>
          <div>
            <Header {...this.props} modal={{ openModal: this.openModal, closeModal: this.closeModal }} />
            <Sidebar {...this.props} />
            <div className="sidebar-overlay" id="sidebar-overlay" />
            <Switch>
              <Route path={DASHBOARD_URL} component={PageDashboard} />
              <Route
                path={CUSTOMER_PO_URL}
                render={(props) => (<PageOrder {...props} modal={{ openModal: this.openModal, closeModal: this.closeModal }} />)}
              />

              <Route
                path={CUSTOMER_PO_HISTORY_VIEW_URL}
                render={(props) => <OrderHistoryView {...props} modal={{ openModal: this.openModal, closeModal: this.closeModal }} />}
              />

              <Route
                path={PURCHASE_URL}
                render={(props) => <PagePurchase {...props} modal={{ openModal: this.openModal, closeModal: this.closeModal }} />}
              />

              <Route
                path={PURCHASE_PREVIEW_URL}
                render={(props) => <PurchasePreview {...props} modal={{ openModal: this.openModal, closeModal: this.closeModal }} />}
              />

              <Route
                path={PURCHASE_ORDER_URL}
                render={(props) => <PagePurchaseOrder {...props} modal={{ openModal: this.openModal, closeModal: this.closeModal }} />}
              />

              <Route
                path={PURCHASE_HISTORY_VIEW_URL}
                render={(props) => <PurchaseHistoryView {...props} modal={{ openModal: this.openModal, closeModal: this.closeModal }} />}
              />

              <Route
                path={YIELD_URL}
                render={(props) => <PageYield {...props} modal={{ openModal: this.openModal, closeModal: this.closeModal }} />}
              />

              <Route
                path={SAMPLE_APPROVAL_URL}
                render={(props) => <PageSampleApproval {...props} modal={{ openModal: this.openModal, closeModal: this.closeModal }} />}
              />

              <Route
                path={WAREHOUSE_URL}
                render={(props) => <PageWareHouse {...props} modal={{ openModal: this.openModal, closeModal: this.closeModal }} />}
              />

              <Route
                path={REPORT_ANALYTIC_URL}
                render={(props) => <PageReportAnalytic {...props} modal={{ openModal: this.openModal, closeModal: this.closeModal }} />}
              />

              <Route
                path={PRODUCTION_URL}
                render={(props) => <PageProduction {...props} modal={{ openModal: this.openModal, closeModal: this.closeModal }} />}
              />

              <Route path={`${STANDARD_PACKINGS_EDIT_URL}/:id`} component={EditPackingStandardContainer} />

              <Route
                path={STANDARD_URL}
                render={(props) => <PageStandard {...props} modal={{ openModal: this.openModal, closeModal: this.closeModal }} />}
              />

              <Route
                path={PACKING_STANDARD_ADD_URL}
                render={(props) => <PackingStandardAdd {...props} modal={{ openModal: this.openModal, closeModal: this.closeModal }} />}
              />

              <Route
                path={CRM_URL}
                render={(props) => <PageCrm {...props} modal={{ openModal: this.openModal, closeModal: this.closeModal }} />}
              />

              <Route
                path={ORGANIZATION_URL}
                render={(props) => <PageOrganization {...props} modal={{ openModal: this.openModal, closeModal: this.closeModal }} />}
              />

              <Route
                path={SYSTEM_SETTING_URL}
                render={(props) => <PageSystemSettings {...props} modal={{ openModal: this.openModal, closeModal: this.closeModal }} />}
              />

              <Route
                path={ACCOUNTING_URL}
                render={(props) => <PageAccounting {...props} modal={{ openModal: this.openModal, closeModal: this.closeModal }} />}
              />

              <Route
                path={FORGOT_PASSWORD_URL}
                render={(props) => <ForgotPassword {...props} modal={{ openModal: this.openModal, closeModal: this.closeModal }} />}
              />

              {/* <Route path={SHIP_OUT.URL} component={ShipOutContainer} />
              <Route path={SHIP_OUT.PKL_PRODUCTION.VIEW} component={CustomerContaier} />
              <Route path={SHIP_OUT.PKL_PRODUCTION.PREVIEW} component={PreviewContainer} />
              <Route path={SHIP_OUT.PKL_PRODUCTION.CREATE} component={TotalQTYContainer} />
              <Route path={SHIP_OUT.PKL_SHIP.CREATE_INV} component={CreateINVContainer}/>
              <Route path={SHIP_OUT.PKL_SHIP.EDIT} component={EditPKLShipContainer}/>
              <Route path={SHIP_OUT.SHIP.EDIT} component={EditShipOutContainer}/>
              <Route path={SHIP_OUT.HISTORY.VIEW} component={ViewHistoryContainer}/> */}

              <Route
                path={SHIPPING_URL}
                render={(props => <PageShipping {...props} modal={{ openModal: this.openModal, closeModal: this.closeModal }} />)} />
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}

App.PropTypes = {
  children: PropTypes.object.isRequired
};

function mapStateToProps(state, ownProps) {
  return {
    authState: state.authState,
    appState: state.appState
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    authPropsActions: bindActionCreators(authActions, dispatch),
    appPropsActions: bindActionCreators(appActions, dispatch),
    routeTo: function (url) {
      ownProps.history.push(url);
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
