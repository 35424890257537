import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import I18n from 'i18n-js';
import _ from 'lodash';

import BaseComponent from '../base/BaseComponent';
import * as dashboardSelectors from '../../selectors/dashboard';
import * as dashboardActions from '../../actions/dashboardActions';

import icXIcon from '../../stylesheets/images/ic-x.svg';
import icPpIcon from '../../stylesheets/images/ic-pp.svg';
import icDashboardOnTrackIcon from '../../stylesheets/images/ic-dashboard-on-track.svg';
import icDashboardNewIcon from '../../stylesheets/images/ic-dashboard-new.svg';
import icDashboardWarningIcon from '../../stylesheets/images/ic-dashboard-warning.svg';
import icDashboardBehindScheduleIcon from '../../stylesheets/images/ic-dashboard-behind-schedule.svg';
import icReadyIcon from '../../stylesheets/images/ic-ready.svg';
import ic7daysIcon from '../../stylesheets/images/ic-7days.svg';
import icLateIcon from '../../stylesheets/images/ic-late.svg';
import loadingIcon from '../../stylesheets/images/loading.svg';
const COLOR_LABEL = {
  RED: 2,
  YELLOW: 1,
  GREEN: 0,
}

class PageDashboard extends BaseComponent {
  constructor(props, context) {
    super(props, context);

    this.state = {
      index: 0,
      height: window.innerHeight,
      pageNumber: 0,
      isTab: null,
    };

    this.increaseIndex = this.increaseIndex.bind(this);
    this.activeTab = this.activeTab.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
  }
  handleScroll() {
    const { pageNumber, isTab } = this.state
    const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
    const body = document.body;
    const html = document.documentElement;
    const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
    const windowBottom = windowHeight + window.pageYOffset + 10;
    if (this.props.total > this.props.orders.length) {
      if (windowBottom >= docHeight) {
        if (isTab) {
          document.getElementById(isTab).style.background = "#fff";
        }
        this.setState({
          pageNumber: pageNumber+1
        });
        this.fetchData({time: pageNumber+1});
      }
    }
}

componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
}

componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
}
  increaseIndex() {
    this.setState((prev) => {
      return { index: prev.index + 1 };
    })
  }

  activeTab(tab) {
    this.setState({isTab: tab})
  }

  fetchData(options) {
    this.props.getDashboard(options);
  }

  componentWillMount() {
    const { pageNumber } = this.state
    this.fetchData({time: pageNumber});
    // this.fetchData({time: 2});
  }

  getColorClass = label => {
    switch(label) {
      case COLOR_LABEL.RED:
        return 'progress-bar bg-red';
      case COLOR_LABEL.YELLOW:
        return 'progress-bar bg-yellow color-black';
      case COLOR_LABEL.GREEN:
        return 'progress-bar progress-bar-success';
      default:
        return 'progress-bar bg-gray';
    }
  }

  renderTech = order => {
    const failedGarment = _.map(_.get(order, 'failedGarment', []), (fg, k) => ({
      img: fg.status == 'REJECTED' ? (<img key={k} src={icXIcon} />) : (<img src={icPpIcon} />),
      name: fg.type.toUpperCase(),
      styleName: fg.style
    }));

    return (
      <div className="box col1">
        <div className="wrap-box">
          <div className="bg-line-colum-left" />
          <div className="bg-line-colum-right" />
          <div className="box box-shadow">
            <h3 className="bd-solid-bottom-2 center">{I18n.t('Dashboard.TECH')}</h3>
            <ul className="list-items">
              {_.map(_.get(order, 'styles', []), (style, idx) => (
                <li key={idx}>
                  <div className="w-50-percent">
                    <span>{I18n.t('Dashboard.Style')} {style.style}</span>
                  </div>
                  <div className="w-50-percent mg-top-2">
                    <div className="progress">
                      <div className={this.getColorClass(_.get(style, 'techLabel', ''))} role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style={{ width: '100%' }}></div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
            <ul className="list-items-bottom">
              {_.map(failedGarment, (fg, idx) => {
                return (
                  <li key={idx}>
                    <span className="w-40 inline-block">{fg.name}</span>
                    <span className="mg-right-15">{fg.img}</span>
                    <span className="float-right color-red size-10">{fg.styleName}</span>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      </div>
    )
  }

  renderMD = order => {
    const failedMaterials = _.map(_.get(order, 'failedMaterials', []), fm => ({
      img: fm.status == 'REJECTED' ? (<img src={icXIcon} />) : (<img src={icPpIcon} />),
      name: fm.type.toUpperCase(),
      styleName: fm.style
    }));

    const notBuyMaterials = _.get(order, 'notBuyMaterials', []);
    return (
      <div className="box col2">
        <div className="wrap-box">
          <div className="bg-line-colum-left" />
          <div className="bg-line-colum-right" />
          <div className="box box-shadow">
            <h3 className="bd-solid-bottom-2 center">{I18n.t('Dashboard.MD')}</h3>
            <ul className="list-items">
              <li key="1">
                <div className="w-50-percent">
                  <span>{I18n.t('Dashboard.Fabric')}</span>
                </div>
                <div className="w-50-percent mg-top-2">
                  <div className="progress">
                    <div className={this.getColorClass(_.get(order, 'fabricLabel', ''))} role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style={{ width: '100%' }}></div>
                  </div>
                </div>
              </li>
              <li key="0">
                <div className="w-50-percent">
                  <span>{I18n.t('Dashboard.Trim')}</span>
                </div>
                <div className="w-50-percent mg-top-2">
                  <div className="progress">
                    <div className={this.getColorClass(_.get(order, 'trimLabel', ''))} role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style={{ width: '100%' }}></div>
                  </div>
                </div>
              </li>
              <li key="-1" className="list-red">
                {_.map(_.get(order, 'materials', []), (mt, idx) => (
                  <div key={idx} className="color-red pd-10-15-10-25">
                    <span>{mt.name}</span>
                  </div>
                ))}
              </li>
            </ul>
            <ul className="list-items-bottom" >
              {_.map(notBuyMaterials, (m, i) => (<li key={i} style = {{borderTop: 'none', color: 'red'}}>{m}</li>))};
            </ul>
            <ul className="list-items-bottom failMD">
              {_.map(failedMaterials, (fm, idx) => {
                return (
                  <li key={idx}>
                    <span className="name">{fm.name}</span>
                    <span className="img">{fm.img}</span>
                    <span className="color-red size-10 text">{fm.styleName}</span>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      </div>
    )
  }

  renderProduction = order => {
    return (
      <div className="box col3">
        <div className="wrap-box">
          <div className="bg-line-colum-left" />
          <div className="bg-line-colum-right" />
          <div className="box box-shadow">
            <h3 className="bd-solid-bottom-2 center">{I18n.t('Dashboard.PRODUCTION')}</h3>
            <ul className="list-items">
              {_.map(_.get(order, 'styles', []), (style, idx) => (
                <li key={idx}>
                  <div className="w-50-percent">
                    <span>{I18n.t('Dashboard.Style')} {style.style}</span>
                  </div>
                  <div className="w-50-percent mg-top-2">
                    <div className="progress">
                      <div className={this.getColorClass(_.get(style, 'productionLabel', ''))} role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style={{ width: '100%' }}></div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    )
  }

  renderQa = order => {

    return (
      <div className="box col4">
        <div className="wrap-box">
          <div className="bg-line-colum-left" />
          <div className="bg-line-colum-right" />
          <div className="box box-shadow">
            <h3 className="bd-solid-bottom-2 center">{I18n.t('Dashboard.QA')}</h3>
            <ul className="list-items">
              {_.map(_.get(order, 'styles', []), (style, idx) => {
                let elRed = '', elGreen = ''
              
                const failPercent = _.round(_.toInteger(style.progress.qaPercent.fail));
                elRed = (<div className={this.getColorClass(2)} role="progressbar" aria-valuenow={failPercent} aria-valuemin="0" aria-valuemax="100" style={{ width: `${failPercent}%` }}>{failPercent}%</div>)

                const passPercent = _.round(_.toInteger(style.progress.qaPercent.pass));
                elGreen = (<div className={this.getColorClass(0)} role="progressbar" aria-valuenow={passPercent} aria-valuemin="0" aria-valuemax="100" style={{ width: `${passPercent}%` }}>{passPercent}%</div>)

                return (
                  <li key={idx}>
                    <div className="w-50-percent">
                      <span>{I18n.t('Dashboard.Style')} {style.style}</span>
                    </div>
                    <div className="w-50-percent mg-top-2">
                      <div className="progress">
                        {elGreen != '' && elGreen}
                        {elRed != '' && elRed}
                      </div>
                    </div>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      </div>
    )
  }

  renderShipping = order => {

    return (
      <div className="box col5">
        <div className="wrap-box">
          <div className="bg-line-colum-left" />
          <div className="bg-line-colum-right" />
          <div className="box box-shadow">
            <h3 className="bd-solid-bottom-2 center">{I18n.t('Dashboard.SHIP')}</h3>
            <ul className="list-items">
              {_.map(_.get(order, 'styles', []), (style, idx) => (
                <li key={idx}>
                  <div className="w-50-percent">
                    <span>{I18n.t('Dashboard.Style')} {style.style}</span>
                  </div>
                  <div className="w-50-percent mg-top-2">
                    <div className="progress">
                      <div className={this.getColorClass(_.get(style, 'shipLabel', ''))} role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style={{ width: '100%' }}></div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    )
  }

  renderOrder = (order, key) => {
    return (
      <div key={_.get(order, 'tech._id') || key} className="wrap-customer-po">
        <div className="pd-0-5">
          <div className="title bd-solid-bottom-2 box-shadow">
            <span className="mg-right-30">{I18n.t('Dashboard.CustomerPO')}</span>
            <span className="bold size-16 color-orange">{_.get(order, '_id.order.customerPO')}</span>
          </div>
        </div>
        <div className="wrap-col wrap-col-5">
          <div className="bg-line-colum zero" />
          <div className="bg-line-colum one" />
          <div className="bg-line-colum two" />
          <div className="bg-line-colum three" />
          <div className="bg-line-colum foure" />
          <div className="bg-line-colum five" />
          <div className="bg-line-colum six" />
          <div className="wrap-col wrap-col-4">
            <div className="wrap-col wrap-col-3">
              <div className="wrap-col wrap-col-2">
                <div className="wrap-col wrap-col-1">
                  {this.renderTech(order)}
                  {this.renderMD(order)}
                  {this.renderProduction(order)}
                  {this.renderQa(order)}
                  {this.renderShipping(order)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render = () => {
    const page = 20;
    const { overview, orders, prods } = this.props;
    let index = _.parseInt(orders.length / page);
    if (orders.length % page > 0) {
      index++;
    }
    return (
      <div id="content">
        <div id="dashboard">
          <div className="wrap-info w-full mg-top-5 inline-block">
            <div className="box">
              <div id="isNew" className="info-box" onClick={() => {this.activeTab('isNew'); this.props.filterdDashboard(orders, 'isNew', 'isNew')}} style={{cursor: 'pointer'}}>
                <span className="info-box-icon bg-aqua">
                  <img src={icDashboardNewIcon} alt="" />
                </span>
                <div className="info-box-content">
                  <span className="info-box-number">{overview.isNew}</span><br />
                  <span className="info-box-text">{I18n.t('Dashboard.New')}</span>
                </div>
              </div>
            </div>

            <div className="box">
              <div id="onTrack" className="info-box" onClick={() => {this.activeTab('onTrack'); this.props.filterdDashboard(orders, 'onTrack', 'onTrack')}} style={{cursor: 'pointer'}}>
                <span className="info-box-icon bg-aqua">
                  <img src={icDashboardOnTrackIcon} alt="" />
                </span>
                <div className="info-box-content">
                  <span className="info-box-number">{overview.onTrack}</span><br />
                  <span className="info-box-text">{I18n.t('Dashboard.OnTrack')}</span>
                </div>
              </div>
            </div>

            <div className="box">
              <div id="warning" className="info-box" onClick={() => {this.activeTab('warning'); this.props.filterdDashboard(orders, 'warning', 'warning')}} style={{cursor: 'pointer'}}>
                <span className="info-box-icon bg-aqua">
                  <img src={icDashboardWarningIcon} alt="" />
                </span>
                <div className="info-box-content">
                  <span className="info-box-number">{overview.warning}</span><br />
                  <span className="info-box-text">{I18n.t('Dashboard.Warning')}</span>
                </div>
              </div>
            </div>

            <div className="box">
              <div id="behindSchedule" className="info-box" onClick={() => {this.activeTab('behindSchedule'); this.props.filterdDashboard(orders, 'behindSchedule', 'behindSchedule')}} style={{cursor: 'pointer'}}>
                <span className="info-box-icon bg-aqua">
                  <img src={icDashboardBehindScheduleIcon} alt="" />
                </span>
                <div className="info-box-content">
                  <span className="info-box-number">{overview.behindSchedule}</span><br />
                  <span className="info-box-text">{I18n.t('Dashboard.Behind')}</span>
                </div>
              </div>
            </div>
          </div>
          {/* Line 2 */}
          <div className="wrap-info w-full mg-top-5 inline-block">
            <div className="box" style={{width: '33.333%'}}>
              <div id="readyProduction" className="info-box" onClick={() => {this.activeTab('readyProduction'); this.props.filterLine2Dashboard(orders, prods.readyProductions || [],'readyProduction')}} style={{cursor: 'pointer'}}>
                <span className="info-box-icon bg-aqua">
                  <img src={icReadyIcon} alt="" style={{width: 65}} />
                </span>
                <div className="info-box-content">
                  <span className="info-box-number">{prods.readyProductions && prods.readyProductions.length}</span><br />
                  <span className="info-box-text">{I18n.t('Dashboard.ReadyForProduction')}</span>
                </div>
              </div>
            </div>

            <div className="box" style={{width: '33.333%'}}>
              <div id="weeklyProduction" className="info-box" onClick={() => {this.activeTab('weeklyProduction'); this.props.filterLine2Dashboard(orders, prods.weeklyProductions || [], 'weeklyProduction')}} style={{cursor: 'pointer'}}>
                <span className="info-box-icon bg-aqua">
                  <img src={ic7daysIcon} alt="" style={{width: 65}} />
                </span>
                <div className="info-box-content">
                  <span className="info-box-number">{prods.weeklyProductions && prods.weeklyProductions.length}</span><br />
                  <span className="info-box-text">{I18n.t('Dashboard.7DaysForProduction')}</span>
                </div>
              </div>
            </div>

            <div className="box" style={{width: '33.333%'}}>
              <div id="latedProduction" className="info-box" onClick={() => {this.activeTab('latedProduction'); this.props.filterLine2Dashboard(orders, prods.latedProductions || [],'latedProduction')}} style={{cursor: 'pointer'}}>
                <span className="info-box-icon bg-aqua">
                  <img src={icLateIcon} alt="" style={{width: 65}} />
                </span>
                <div className="info-box-content">
                  <span className="info-box-number">{prods.latedProductions && prods.latedProductions.length}</span><br />
                  <span className="info-box-text">{I18n.t('Dashboard.LateForProduction')}</span>
                </div>
              </div>
            </div>
          </div>
          <div>
            {this.props.filters.map((order, idx) => 
              this.renderOrder(order, idx))
            }
            {(this.props.isLoading == true && this.props.total > this.props.orders.length) &&
              <li className="loading-icon"><img src={loadingIcon} /></li>
            }
          </div>

        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  overview: dashboardSelectors.getOverview(state),
  orders: dashboardSelectors.getOrders(state),
  filters: dashboardSelectors.getFilteredOrders(state),
  prods: dashboardSelectors.getProds(state),
  isLoading: dashboardSelectors.isLoading(state),
  total: dashboardSelectors.total(state),
});

const mapDispatchToProps = dispatch => ({
  getDashboard(options) {
    return dispatch(dashboardActions.getDashboard(options))
  },

  filterdDashboard(orders, filter, idCss) {
    let arrCss = ['isNew','onTrack','warning','behindSchedule','readyProduction','weeklyProduction','latedProduction'];
      _.map(arrCss, (item, k) =>{
        if(item == idCss){
            document.getElementById(idCss).style.background = "#CCC";
        }else{
            document.getElementById(item).style.background = "#fff";
        }
      })

    return dispatch(dashboardActions.filterDashboard(orders, filter));
  },

  filterLine2Dashboard(orders, prod, idCss) {
      let arrCss = ['isNew','onTrack','warning','behindSchedule','readyProduction','weeklyProduction','latedProduction'];
      _.map(arrCss, (item, k) =>{
          if(item == idCss){
              document.getElementById(idCss).style.background = "#CCC";
          }else{
              document.getElementById(item).style.background = "#fff";
          }
      })

    return dispatch(dashboardActions.filterLine2Dashboard(orders, prod));
  }
});

PageDashboard.propTypes = {
  authState: PropTypes.object
};

export default connect(mapStateToProps, mapDispatchToProps)(PageDashboard);
