import { combineReducers } from 'redux';
import moment from 'moment';
import * as R from 'ramda';
import _ from 'lodash';
import I18n from 'i18n-js';
import C from '../../../constants/customer-po/current/createOrder';

const showing = (state = false, action) => {
  const { type } = action;
  switch (type) {
    case C.SHOW_MODAL:
      return true;
    case C.HIDE_MODAL:
      return false;
    default:
      return state;
  }
};

const customerPO = (state = '', action) => {
  const { type, payload } = action;
  switch (type) {
    case C.CHANGE_CUSTOMER_PO:
      return _.trimStart(payload);
    default:
      return state;
  }
};

const selectableCustomers = (state = [], action) => {
  const { type, payload } = action;
  switch (type) {
    case C.CHANGE_SELECTABLE_CUSTOMERS:
      return payload;
    default:
      return state;
  }
};

const selectedCustomer = (state = null, action) => {
  const { type, payload } = action;
  switch (type) {
    case C.CHANGE_SELECTED_CUSTOMER:
      return payload;
    default:
      return state;
  }
};

const orderDate = (state = moment().format(), action) => {
  const { type, payload } = action;
  switch (type) {
    case C.CHANGE_ORDER_DATE:
      return payload;
    default:
      return state;
  }
};

const exFactory = (state = moment().format(), action) => {
  const { type, payload } = action;
  switch (type) {
    case C.CHANGE_EX_FACTORY:
      return payload;
    default:
      return state;
  }
};

const ihDate = (state = moment().format(), action) => {
  const { type, payload } = action;
  switch (type) {
    case C.CHANGE_IH_DATE:
      return payload;
    default:
      return state;
  }
}

const description = (state = '', action) => {
  const { type, payload } = action;
  switch (type) {
    case C.CHANGE_DESCRIPTION:
      return payload;
    default:
      return state;
  }
}

const errors = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case C.CHANGE_VALIDATE_ORDER_NAMES:
      return {...state, 'names': payload };
    case C.CHANGE_CUSTOMER_PO: {
      const names = R.pathOr([], ['names'], state);
      return {
        ...state, 
        'customerPO': !payload ?
          `Please input customerPO`:
          _.find(names, x => x.toLowerCase() === payload.toLowerCase()) ? I18n.t('ErrorMessages.OrderHaveAlreadyExist'): null,
      };
    }
    case C.CHANGE_SELECTED_CUSTOMER:
      return {...state, 'selectedCustomer': !payload ? 'Please select a customer': null };
    default:
      return state;
  }
}

const submiting = (state = false, action) => {
  const { type } = action;
  switch (type) {
    case C.SUBMIT_ORDER:
      return true;
    case C.CANCEL_SUBMIT_ORDER:
      return false;
    default:
      return state;
  }
}

const combined = combineReducers({
  showing,
  customerPO,
  selectableCustomers,
  selectedCustomer,
  orderDate,
  exFactory,
  ihDate,
  description,
  errors,
  submiting,
});

export default (state, action) => {
  const { type } = action;
  if (type === C.RESET_DATA) {
    return {};
  }
  return combined(state, action);
};
