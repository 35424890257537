import React, { Component } from 'react';
import { Line,  Area, XAxis, YAxis, CartesianGrid, Tooltip, ComposedChart } from 'recharts';

import icSearchIcon from '../../../stylesheets/images/ic-search.svg';
import icTotalIcon from '../../../stylesheets/images/ic-total.svg';
import reportDemo2Icon from '../../../stylesheets/images/report-demo-2.svg';

const revenueReport = props => {
    let style = {
        width: "100%"
    };
    let lh95 = {
        lineHeight: "95px"
    };
    const data = [
        { name: 'Page A', uv: 4000, pv: 9000 },
        { name: 'Page B', uv: 3000, pv: 7222 },
        { name: 'Page C', uv: 2000, pv: 6222 },
        { name: 'Page D', uv: 1223, pv: 5400 },
        { name: 'Page E', uv: 1890, pv: 3200 },
        { name: 'Page F', uv: 2390, pv: 2500 },
        { name: 'Page G', uv: 3490, pv: 1209 },
    ];
    return (
        <div role="tabpanel" className="tab-pane" id="report-revenue">
            <div className="wrap-info w-full mg-top-5 inline-block">
                <div className="box">
                    <div className="info-box">
                        <span
                            className="info-box-icon bg-aqua">
                            <img
                                src={icTotalIcon}
                                alt="" />
                        </span>
                        <div className="info-box-content"><span className="info-box-number">3</span><br /><span
                            className="info-box-text">Total</span></div>
                    </div>
                </div>
            </div>

            <div className="wrap-doughnut-charts w-full inline-block pd-10 ">
                <div className="bg-white box-shadow inline-block w-full">
                    <div className="wrap-filter-search pd-15">
                        <div className="wrap-search">
                            <p>
                                Date Range
                                            </p>
                            <input type="text" placeholder="Search" className="form-control w-180" />
                            <button type="submit" className="bt-orange" >VIEW</button>
                        </div>
                        <div className="wrap-filter">
                            <p>
                                To
                                            </p>
                            <select>
                                <option value="" />
                            </select>
                        </div>
                    </div>

                    <div className="doughnut-charts inline-block w-full">
                        <ComposedChart width={1200} height={500} data={data} syncId="anyId"
                            margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip />
                            <Line type='monotone' dataKey='pv' stroke='#8884d8' fill='#82ca9d' />
                            <Area type='monotone' dataKey='pv' stroke='#82ca9d' fill='#82ca9d' />
                        </ComposedChart>
                    </div>
                </div>
            </div>

            <div className="wrap-content-table inline-block w-full pd-10">
                <div className="row">

                    <div className="col-md-8 col-xs-12 left">
                        <div className="box-shadow bg-white">

                            <div className="wrap-filter-search pd-15">
                                <div className="wrap-search">
                                    <p>
                                        Search
                                                    </p>
                                    <input type="text" placeholder="Search" className="form-control" />
                                    <span className="icon-search"><img src={icSearchIcon}
                                        alt="" /></span>
                                    <button type="submit" className="bt-orange">Search</button>
                                </div>
                                <div className="wrap-filter">
                                    <p>
                                        Filter
                                                    </p>
                                    <select>
                                        <option value="" />
                                    </select>
                                </div>
                            </div>

                            <div className="wrap-global-table box-shadow-none pd-0 inline-block w-full">

                                <table style={style} className="global-table box-shadow-none bd-solid-top-2">
                                    <tbody>

                                        <tr className="bd-dashed-bottom">
                                            <th className="center">Customer PO</th>
                                            <th className="center">Style</th>
                                            <th className="center">Quantity</th>
                                            <th className="center">EX-Factory</th>
                                            <th className="center">IH Date</th>
                                            <th className="center">Age</th>
                                        </tr>
                                        <tr>
                                            <td className="center">15612</td>
                                            <td className="center">MTU7WF0BVS</td>
                                            <td className="center">1,2312</td>
                                            <td className="center">Feb 02, 2017</td>
                                            <td className="center">Feb 02, 2017</td>
                                            <td className="center">1</td>
                                        </tr>

                                        <tr>
                                            <td className="center">15612</td>
                                            <td className="center">MTU7WF0BVS</td>
                                            <td className="center">1,2312</td>
                                            <td className="center">Feb 02, 2017</td>
                                            <td className="center">Feb 02, 2017</td>
                                            <td className="center">1</td>
                                        </tr>

                                        <tr>
                                            <td className="center">15612</td>
                                            <td className="center">MTU7WF0BVS</td>
                                            <td className="center">1,2312</td>
                                            <td className="center">Feb 02, 2017</td>
                                            <td className="center">Feb 02, 2017</td>
                                            <td className="center">1</td>
                                        </tr>

                                    </tbody>

                                </table>
                                <div className="center">
                                    <ul className="list-button">
                                        <li>
                                            <button className="bt-orange text-uppercase" onClick={() => this.btGetExport()} >EXPORT WIP</button>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="col-md-4 col-xs-12 right doughnut-wrap pd-left-0">
                        <div className="box-shadow bg-white">
                            <ul>
                                <li>
                                    <span className="bg-green box" />
                                    <span className="text">On Track</span>
                                </li>
                                <li>
                                    <span className="bg-yellow box" />
                                    <span className="text">Warning</span>
                                </li>
                                <li>
                                    <span className="bg-red box" />
                                    <span className="text">Behind Schedule</span>
                                </li>
                            </ul>
                            <div className="doughnut-charts inline-block w-full">
                                <p className="center h-95 size-12" style={lh95}>
                                    <img src={reportDemo2Icon} alt="" />
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default revenueReport;