import {createSelector} from 'reselect';
import _ from 'lodash';

export const getInvoiceDetail = ({historyPreviewContainer}) => historyPreviewContainer.invoiceDetail || {};

export const genDataForPreview = createSelector (
    [
        getInvoiceDetail
    ], data => {
        let temp = []
        _.cloneDeep((data.invoiceDetail || {}).containers || []).map(item => {
            temp.push(_.assign(item, {
            contNo: item.name,
          }))
        })
        return temp;
    }
)

export const getName = createSelector (
    [getInvoiceDetail], data => {
        if(data.invoiceDetail) {
            return data.invoiceDetail.name;
        }
        return '';
    }
)

export const getInvoiceNo = createSelector (
    [getInvoiceDetail], data => {
        return data.invNo || '';
    }
)

export const getIssuedDate = createSelector (
    [getInvoiceDetail], data => {
        return data.issuedDate || '';
    }
)

export const getCreditDebit = createSelector (
    [getInvoiceDetail], data => {
        return 'empty';
    }
)

export const getAmount = createSelector (
    [getInvoiceDetail], data => {
        return data.amount || 0;
    }
)

export const getVatAmount = createSelector (
    [getInvoiceDetail], data => {
        if(data.invoiceDetail) {
            const amount = (data.invoiceDetail.vat.totalAmount || 0) * (data.invoiceDetail.vat.taxRate || 0);
            return amount;
        }
        return 0;
    }
)

export const getTotalAmount = createSelector (
    [
        getAmount,
        getVatAmount,
    ], (amount, vatAmount) => {
        return amount + vatAmount;
    }
)

export const getActiveScreenStatus = ({historyPreviewContainer}) => historyPreviewContainer.activeScreen;