import * as types from '../constants/actionTypes';
import {showLoading, hideLoading} from './commonUseActions';
import roleServices from '../services/roleServices';

function getListRole (options = {}) {
  return (dispatch) => {
    showLoading(types, dispatch);

    return roleServices.getListRole(options).then((res) => {
      dispatch({
        type: types.ROLE.GET_LIST_SUCCESS,
        data: res.data || []
      });
      hideLoading(types, dispatch);
      return res;
    }, (error) => {
      hideLoading(types, dispatch);
    });
  };
}

function editRole (id, data) {
  return (dispatch) => {
    showLoading(types, dispatch);

    roleServices.editRole(id, data).then((res) => {
      hideLoading(types, dispatch);
      if (res) {
        dispatch({
          type: types.ROLE.RELOAD,
          data: true
        });
      }
    }, (error) => {
      hideLoading(types, dispatch, error);
    });
  };
}

function addRole (data) {
  return (dispatch) => {
    showLoading(types, dispatch);

    roleServices.addRole(data).then((res) => {
      hideLoading(types, dispatch);
      if (res) {
        dispatch({
          type: types.ROLE.RELOAD,
          data: true
        });
      }
    }, (error) => {
      hideLoading(types, dispatch, error);
    });
  };
}

function deleteRole (id) {
  return (dispatch) => {
    showLoading(types, dispatch);

    roleServices.deleteRole(id).then((res) => {
      hideLoading(types, dispatch);
      if (res) {
        dispatch({
          type: types.ROLE.RELOAD,
          data: true
        });
      }
    }, (error) => {
      hideLoading(types, dispatch, error);
    });
  };
}

function changeToRoleEditPage(roleDetail, currentPage){
  return (dispatch) => {
    dispatch({
      type: types.ROLE.SET_DETAIL,
      data: roleDetail
    });
    dispatch({
      type: types.APP.SET_PAGE_CURRENT,
      data: currentPage
    });
  };
}

function getListModule(){
  return (dispatch) => {
    showLoading(types, dispatch);

    roleServices.getListModules().then((res) => {
      hideLoading(types, dispatch);
      if (res) {
        dispatch({
          type: types.ROLE.GET_MODULES_SUCCESS,
          data: res.data
        });
      }
    }, (error) => {
      hideLoading(types, dispatch, error);
    });
  };
}

function getRoleDetail(roleId) {
  return (dispatch) => {
    showLoading(types, dispatch);

    roleServices.getRoleDetail(roleId).then((res) => {
      hideLoading(types, dispatch);
      if (res) {
        dispatch({
          type: types.ROLE.SET_DETAIL,
          data: res.data
        });
      }
    }, (error) => {
      hideLoading(types, dispatch, error);
    });
  };
}

export default {
  getListRole,
  getRoleDetail,
  editRole,
  addRole,
  deleteRole,
  changeToRoleEditPage,
  getListModule
};
