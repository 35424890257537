import C from '../../../constants/customer-po/current/cancelOrder';
import { listCustomerOrders, removeListCustomerOrders } from '../../../actions/customer-po/current/showListOrders';
import orderServices from '../../../services/orderServices';
import customerServices from '../../../services/customerServices';
import * as R from 'ramda';

export const showModal = (order) => dispatch => {
  dispatch({ type: C.CHANGE_ORDER_DETAIL, payload: order });
  dispatch({ type: C.SHOW_MODAL });
}

export const hideModal = () => dispatch => {
  dispatch({ type: C.RESET_DATA });
}

export const cancelOrder = id => dispatch => {
  dispatch({ type: C.SUBMIT });
  orderServices.editOrder(id, { isCancel: true }).then(res => {
    dispatch({ type: C.CANCEL_SUBMIT });
    dispatch({ type: C.RESET_DATA });
    // dispatch(listCustomerOrders());
    dispatch(removeListCustomerOrders(id));
  }).catch(error => {
    dispatch({ type: C.CANCEL_SUBMIT });
    console.error(error);
  })
}
