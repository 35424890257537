import React, { Component } from 'react';
import { connect } from "react-redux";
import { toastr } from 'react-redux-toastr';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import { withRouter, Switch, Route } from "react-router-dom";
import * as PATH from '../../../../constants/routeConstants';
import EditModalView from '../../../components/accounting/IEContainerItemEditModal';
import ModalEditContainerDetail from '../../../../components/common/modals/ModalEditContainerDetail';
import CreatePlanIEContainer from '../../../components/accounting/payable/CreatePlanIEContainer';
import PreviewIEContainer from './PreviewIEContainer';

import {
    resetData, getListForwarder, changeInvoiceInfo,
    changeChecked, changeVATValues, changeSearchText,
    changeContainerDetailValue, copyDataToTemp,
    saveChangeContainerDetailValue, createInvoice,
    activeScreenPreview
} from '../../../../actions/accounting/payable/createInvoiceImpExpContainer';
import * as selector from '../../../../selectors/accounting/payable/createInvoiceImpExpContainers';
class AccountsPayablePlanImportExportContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            openModal: false
        };
    }

    componentWillMount() {
        this.getListForwarder()
    }

    componentWillReceiveProps(nextProps) {
        const { success } = nextProps;
        if (success) {
            nextProps.resetData()
            nextProps.router.history.push(PATH.ACCOUNTING_PAYABLE_INVOICE_URL)
            toastr.success(I18n.t('Notify.Success'), 'Create INV successfully')
        }
    }

    componentWillUnmount() {
        this.props.resetData()
    }

    getListForwarder = () => {
        this.props.getListForwarder()
    }

    onChangeItem = (key, value) => {
        this.props.changeInvoiceInfo(key, value)
    }

    changeChecked = (item) => {
        this.props.changeChecked(item)
    }

    changeVATValues = (key, value) => {
        this.props.changeVATValues(key, value)
    }

    openChangeModal = (id) => {
        this.props.copyDataToTemp(this.props.getContainerDetail(id))
        this.setState({ openModal: true });
        // let { openModal, closeModal } = this.props.modal;
        // let modal = (<ModalEditContainerDetail closeModal={closeModal}  />);
        // openModal(modal);
    }

    closeModal = () => {
        this.setState({ openModal: false });
    }

    ok = (data) => {
        this.props.saveChangeContainerDetailValue(data);
    }

    resetDateRaw = () => {
        // this.
    }
    changeSearchText = (value) => {
        this.props.changeSearchText(value);
    }

    save = () => {
        const { dataApi } = this.props;
        const { invNo } = dataApi;
        if (_.isEmpty(invNo)) {
            toastr.error(I18n.t('Notify.Error'), 'Missing Invoice No')
        } else {
            this.props.createInvoice(dataApi);
        }
    }

    render() {
        const { canPreview, pathname, tempData, listForwarder, invoiceInfo, listContainer, listContainerDetail, 
            mainVAT, totalAmount, totalAmountINV, amountINV, vatAmount, grandTotalAmount, searchText, roles } = this.props;
        const { openModal } = this.state;
        // console.log(this.props.dataApi)
        return (
            <Switch>
                <Route path={PATH.ACCOUNTING_PAYABLE_PLAN_IMPORTEXPORT_CONTAINER_PREVIEW_URL}
                    render={() => <PreviewIEContainer />}
                />
                <Route path={PATH.ACCOUNTING_PAYABLE_PLAN_IMPORTEXPORT_CONTAINER_URL}
                    render={() =>
                        <div>
                            <CreatePlanIEContainer
                                listForwarder={listForwarder}
                                invoiceInfo={invoiceInfo}
                                listContainer={listContainer}
                                listContainerDetail={listContainerDetail}
                                onChangeItem={this.onChangeItem}
                                changeChecked={this.changeChecked}
                                changeVATValues={this.changeVATValues}
                                mainVAT={mainVAT}
                                totalAmount={totalAmount}
                                totalAmountINV={totalAmountINV}
                                amountINV={amountINV}
                                vatAmount={vatAmount}
                                grandTotalAmount={grandTotalAmount}
                                changeContainerDetail={this.openChangeModal}
                                searchText={searchText}
                                changeSearchText={this.changeSearchText}
                                save={this.save}
                                pathname={pathname}
                                canPreview={canPreview}
                                onPreviewScreen={this.props.onPreviewScreen}
                                roles={roles}
                            />
                            <EditModalView openModal={openModal}
                                ok={data => this.ok(data)}
                                close={this.closeModal}
                                data={tempData}
                                resetDateRaw={this.resetDateRaw}
                                changeContainerDetailValueOfNumber={(key, value) => this.props.changeContainerDetailValueOfNumber(key, value)}
                                changeContainerDetailValueOfText={(key, value) => this.props.changeContainerDetailValueOfText(key, value)}
                            />
                        </div>
                    }
                />
            </Switch>
        )
    }
}

AccountsPayablePlanImportExportContainer.propTypes = {

};

AccountsPayablePlanImportExportContainer.defaultProps = {

};

const mapStateToProps = state => ({
    listForwarder: selector.getSelectableImpExpForwarders(state),
    invoiceInfo: selector.getSelectedImpExpInfo(state),
    listContainer: selector.getSelectableContainers(state),
    listContainerDetail: selector.getDetailedContainers(state),
    mainVAT: selector.getMainVAT(state),
    totalAmount: selector.getMainTotalAmount(state),
    vatAmount: selector.getMainVATAmount(state),
    grandTotalAmount: selector.getGrandTotalVAT(state),
    amountINV: selector.getAmount(state),
    totalAmountINV: selector.getTotalAmount(state),
    getContainerDetail: selector.fnGetEditingContainer(state),
    searchText: selector.getSearchText(state),
    tempData: selector.getTempData(state),
    dataApi: selector.fnGenerateRequestBody(state),
    success: selector.getSuccess(state),
    canPreview: selector.getCanPreview(state),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    getListForwarder() {
        dispatch(getListForwarder())
    },
    changeInvoiceInfo(key, value) {
        dispatch(changeInvoiceInfo(key, value))
    },
    changeChecked(item) {
        dispatch(changeChecked(item))
    },
    changeVATValues(key, value) {
        dispatch(changeVATValues(key, value))
    },
    changeSearchText(value) {
        dispatch(changeSearchText(value))
    },
    changeContainerDetailValueOfNumber(key, value) {
        dispatch(changeContainerDetailValue(key, Number(value)))
    },
    changeContainerDetailValueOfText(key, value) {
        dispatch(changeContainerDetailValue(key, (value)))
    },
    copyDataToTemp(data) {
        dispatch(copyDataToTemp(data))
    },
    saveChangeContainerDetailValue(data) {
        dispatch(saveChangeContainerDetailValue(data))
    },
    createInvoice(data) {
        dispatch(createInvoice(data))
    },
    resetData() {
        dispatch(resetData())
    },
    onPreviewScreen() {
        dispatch(activeScreenPreview())
        ownProps.router.history.push(PATH.ACCOUNTING_PAYABLE_PLAN_IMPORTEXPORT_CONTAINER_PREVIEW_URL)
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountsPayablePlanImportExportContainer);
