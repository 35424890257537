
import * as R from 'ramda';
import C from '../../constants/purchase/showPurchaseDetail';
import purchaseSrv from '../../services/purchaseOrderServices';

export const fetchPurchaseOrder = id => dispatch => {
  dispatch({ type: C.FETCH_PURCHASE_ORDER });
  purchaseSrv.retrieve(id).then(res => {
    dispatch({ type: C.CANCEL_FETCH_PURCHASE_ORDER });
    dispatch({ type: C.CHANGE_PURCHASE_ORDER, payload: R.path(['data'], res) });
  }).catch(error => {
    dispatch({ type: C.CANCEL_FETCH_PURCHASE_ORDER });
    console.error(error);
  });
}

export const insertPurchaseOrder = purchase => dispatch => {
  dispatch({ type: C.CANCEL_FETCH_PURCHASE_ORDER });
  dispatch({ type: C.CHANGE_PURCHASE_ORDER, payload: purchase });
}

export const resetData = () => dispatch => {
  dispatch({ type: C.RESET_DATA });
}
