import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BaseComponent from '../../base/BaseComponent';
import purchaseActions from '../../../actions/purchaseActions';
import appActions from '../../../actions/appActions';
import ListAndActions from '../../common/datatables/ListAndActions';
import I18n from 'i18n-js';
import ModalConfirm from '../../common/modals/ModalConfirm';
import {PURCHASE_LIST_SEARCH_KEY, SEARCH_BAR_EVENT, PURCHASE_STATUS, SEARCH_LIST} from "../../../constants/constants";
import * as utils from "../../../functions/utils";
import _ from 'lodash';
import SearchBar from '../../common/layout/SearchBar';
import formatCurrency from 'format-currency';
import moment from 'moment';

class ScreenFollowUp extends BaseComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {
      pageSize: 10,
      pageNumber: 1,
      search: {key: PURCHASE_LIST_SEARCH_KEY.CUSTOMER_PO, value: ''},
      headers: [
        // { name: I18n.t('PagePurchase.No'), type: 'text', dataType: 'text', value: 'id' },
        { name: I18n.t('PagePurchase.OrderDate'), type: 'date', dataType: 'MMM DD, YYYY', value: 'createdAt' },
        { name: I18n.t('PagePurchase.CustomerPO'), type: 'text', dataType: 'text', value: '{{data}}.style.order.customerPO' },
        { name: I18n.t('PagePurchase.Style'), type: 'text', dataType: 'text', value: '{{data}}.style.style' },
        { name: I18n.t('PagePurchase.PurchaseOrderNo'), type: 'text', dataType: 'text', value: 'purchaseNo' },
        { name: I18n.t('PagePurchase.Supplier'), type: 'text', dataType: 'text', value: '{{data}}.supplier.name' },
        { name: I18n.t('PagePurchase.ExpectedReceivedDate'), type: 'date', dataType: 'MMM DD, YYYY', value: 'exReceivedDate' },
        { name: I18n.t('PagePurchase.Amount'), type: 'text', dataType: 'text', value: 'gTotal' },
        { name: I18n.t('PagePurchase.Type'), type: 'text', dataType: 'text', value: 'type' },
        { name: I18n.t('PagePurchase.Status'), type: 'text', dataType: 'text', value: 'status' }
      ]
    };
    this.showDeleteConfirm = this.showDeleteConfirm.bind(this);
    this.onDeletePurchase = this.onDeletePurchase.bind(this);
    this.setPage = this.setPage.bind(this);
  }

  componentWillMount() {
    let options = { pageSize: this.state.pageSize, pageNumber: this.state.pageNumber, populate: 'styleId|orderId,supplierId,materials.material' };
    this.props.purchaseActions.getListFollowUp(options);
  }

  componentWillReceiveProps = (nextProps) => {
    if(!_.isEqual(_.get(this, 'props.purchaseState.reload'), _.get(nextProps, 'purchaseState.reload'))) {
      let options = { pageSize: this.state.pageSize, pageNumber: this.state.pageNumber, populate: 'styleId|orderId,supplierId,materials.material' };
      this.props.purchaseActions.getListFollowUp(options);
    }
  }

  showDeleteConfirm(item) {
    let {openModal, closeModal} = this.props.modal;
    let modal = (<ModalConfirm
      title={I18n.t('PagePurchase.DELETEPURCHASE')}
      confirmMessage={I18n.t('PagePurchase.ConfirmDeletePurchase')}
      messageOk={I18n.t('Btn.YES')}
      messageCancel={I18n.t('Btn.CANCEL')}
      handleClose={closeModal}
      handleOk={this.onDeletePurchase.bind(null, item)}/>);
    openModal(modal);
  }

  onDeletePurchase(item) {
    item._id && this.props.purchaseActions.deletePurchase(item._id);
  }

  getListItem = (query = {}, pageSize, pageNumber) => {
    let options = { pageSize: pageSize, pageNumber: pageNumber, populate: 'styleId|orderId,supplierId,materials.material' };
    this.props.purchaseActions.getListFollowUp(options);
  }

  setPage = (page) => {
    this.setState({pageNumber: page});
    const {search, pageSize} = this.state;
    this.search(search, page, pageSize);
  }

  onSearch() {
    const {search, pageNumber, pageSize} = this.state;
    this.search(search, pageNumber, pageSize);
  }

  onChangeSearch = (property, value) => {
    switch(property) {
      case SEARCH_BAR_EVENT.FILTER: {
        this.setState({search: {key: value, value: ""}});
        break;
      }
      case SEARCH_BAR_EVENT.SEARCH: {
        this.setState({search: {...this.state.search, value: value}});
        break;
      }
    }
  }

  onKeyUpSearch(evt) {
    if (evt && evt.keyCode && evt.keyCode === 13) {
      const {search, pageNumber, pageSize} = this.state;
      this.search(search, pageNumber, pageSize);
    }
  }

  search(search, pageNumber, pageSize) {
    const type = "follow_up";
    const populate = 'styleId|orderId,supplierId,materials.material';
    if(search.key && search.value) {
      let options = {};
      switch (search.key) {
        case PURCHASE_LIST_SEARCH_KEY.CUSTOMER_PO:{
          options = {
            projection: "styleOrders",
            query: JSON.stringify({customerPO: {$regex: '.*' + search.value + '.*', $options: 'i'}})
          };
          this.props.purchaseActions.searchByCustomerPO(options, pageNumber, pageSize, type).then(res => {
            if(res && res.pageNumber && res.pageNumber == 1) {
              this.setState({pageNumber: 1});
            }
          });
          break;
        }
        case PURCHASE_LIST_SEARCH_KEY.SUPPLIER:{
          options = {
            projection: "_id",
            query: {name: {$regex: '.*' + search.value + '.*', $options: 'i'}}
          };
          this.props.purchaseActions.searchBySupplier(options, pageNumber, pageSize, type).then(res => {
            if(res && res.pageNumber && res.pageNumber == 1) {
              this.setState({pageNumber: 1});
            }
          });
          break;
        }
        case PURCHASE_LIST_SEARCH_KEY.PURCHASE_ORDER_NO:{
          options = {
            pageNumber,
            pageSize,
            populate: 'styleId|orderId,supplierId,materials.material',
            query: JSON.stringify({purchaseNo: {$regex: '.*' + search.value + '.*', $options: 'i'}})
          };
          this.props.purchaseActions.getListFollowUp(options).then(res => {
            if(res && res.success && res.data && res.data.items && !res.data.items.length && pageNumber > 1) {
              options = {
                pageNumber:1,
                pageSize,
                sort: {updatedAt: -1},
                populate: populate,
                query: JSON.stringify({purchaseNo: {$regex: '.*' + search.value + '.*', $options: 'i'}})
              };
              this.setState({pageNumber: 1});
              this.props.purchaseActions.getListFollowUp(options);
            }
          });
          break;
        }
        case PURCHASE_LIST_SEARCH_KEY.STYLE:{
          options = {
            projection: "_id",
            query: JSON.stringify({style: {$regex: '.*' + search.value + '.*', $options: 'i'}})
          };
          this.props.purchaseActions.searchByStyle(options, pageNumber, pageSize, type).then(res => {
            if(res && res.pageNumber && res.pageNumber == 1) {
              this.setState({pageNumber: 1});
            }
          });
          break;
        }
        case PURCHASE_LIST_SEARCH_KEY.TYPE:{
          options = {
            pageNumber,
            pageSize,
            populate: 'styleId|orderId,supplierId,materials.material',
            query: JSON.stringify({type: {$regex: '.*' + search.value + '.*', $options: 'i'}})
          };
          this.props.purchaseActions.getListFollowUp(options).then(res => {
            if(res && res.success && res.data && res.data.items && !res.data.items.length && pageNumber > 1) {
              options = {
                pageNumber:1,
                pageSize,
                sort: {updatedAt: -1},
                populate: populate,
                query: JSON.stringify({type: {$regex: '.*' + search.value + '.*', $options: 'i'}})
              };
              this.setState({pageNumber: 1});
              this.props.purchaseActions.getListFollowUp(options);
            }
          });
          break;
        }
        case PURCHASE_LIST_SEARCH_KEY.STATUS:{
          options = {
            pageNumber,
            pageSize,
            populate: 'styleId|orderId,supplierId,materials.material',
            query: JSON.stringify({status: {$regex: '.*' + search.value + '.*', $options: 'i'}})
          };
          this.props.purchaseActions.getListFollowUp(options).then(res => {
            if(res && res.success && res.data && res.data.items && !res.data.items.length && pageNumber > 1) {
              options = {
                pageNumber:1,
                pageSize,
                sort: {updatedAt: -1},
                populate: populate,
                query: JSON.stringify({status: {$regex: '.*' + search.value + '.*', $options: 'i'}})
              };
              this.setState({pageNumber: 1});
              this.props.purchaseActions.getListFollowUp(options);
            }
          });
          break;
        }
      }
    }
    if(!search.value) {
      let options = {
        pageNumber,
        pageSize,
        populate: populate,
      };
      this.props.purchaseActions.getListFollowUp(options, pageNumber, pageSize).then(res => {
        if(res && res.success && res.data && res.data.items && !res.data.items.length && pageNumber > 1) {
          options = {
            pageNumber:1,
            pageSize,
            sort: {updatedAt: -1},
            populate: populate,
          };
          this.setState({pageNumber: 1});
          this.props.purchaseActions.getListFollowUp(options);
        }
      });
    }
  }

  editOrder(data) {
    const {purchaseState} = this.props;
    const purChaseId = _.get(data, "_id") || "";
    let selectedPurChaseOrder = purchaseState.items.find(order => order && order._id === purChaseId);
    let orderDetail = _.get(selectedPurChaseOrder, "style.order") || {};

    const placeOrderData = {
      ...data,
      orderDetail: orderDetail || {},
    };
    this.props.editOrder(placeOrderData);


    let screen = utils.setScreenByPurchaseType(data.type);
    let pageList = [...this.props.appState.pageList];
    pageList.push({screen: screen, data: placeOrderData});
    this.props.appActions.addPage(pageList);
    // const pageData = _.assign(_.cloneDeep(this.props.appState.page), {isEdit: true, tab: "follow-up"});
    const pageData = _.assign(_.cloneDeep(this.props.appState.page), {isEdit: true});
    this.props.appActions.setPageCurrent(pageData);
  }

  genCurrencyForAmount = (amount, currency) => {
    if(currency == 'VND') {
      return formatCurrency(amount) + ' VND';
    }
    if(currency == 'USD') {
      return '$' + formatCurrency(amount);
    }
    return formatCurrency(amount)
  }

  setData(data) {
    data = data.map(item => {
      if(item.materials && item.materials.length > 0) {
        let temp = item.materials.sort((a,b) => new Date(a.exReceivedDate) - new Date(b.exReceivedDate))
        item = {...item, exReceivedDate: temp[0].exReceivedDate, gTotal: this.genCurrencyForAmount(item.gTotal, (item.supplier || {}).currency)}
      }
      return item
    });
    data = data.sort((a,b) => new Date(a.exReceivedDate) - new Date(b.exReceivedDate));
    return data;
  }

  onApplyStyleOnRow = (item) => {
    const oldestETA = (item || {}).materials.reduce((resultValue, currentValue) => {
      const eta = _.get(currentValue, 'ETA', undefined);
      if (!resultValue) {
        resultValue = eta;
      }
      if (eta > resultValue) {
        resultValue = eta;
      }
      return resultValue;
    }, undefined);
    const today = moment();
    if (today.diff(moment(oldestETA), 'days') >= 3) {
      return "color-orange";
    }
    return "";
  }

  render() {
    const {roles : {showCreate, showEdit, showDelete, showView}} = this.props;
    const actions = [
      (data, user) => {
        return showEdit ? {
          type: 'edit',
          func: (data) => {
            this.editOrder(data)
          },
          isActive: true,
          toolTip: 'Edit'
        } : {};
      },
      (data, user) => {
        return showDelete ? {
          type: 'delete',
          func: (data) => {
            this.showDeleteConfirm(data);
          },
          isActive: true,
          toolTip: 'Delete'
        } : {};
      }
    ];
    const { headers, pageNumber, search } = this.state;
    let data = this.props.purchaseState && this.props.purchaseState.items ? this.props.purchaseState.items : [];
    data = this.setData(data);
    let statusNotViewEdit = [] // [PURCHASE_STATUS.IN_TRANSIT, PURCHASE_STATUS.IN_PORT, PURCHASE_STATUS.WAITING_FOR_SHIPPING, PURCHASE_STATUS.IN_WAREHOUSE];
    let statusNotViewDelete = [PURCHASE_STATUS.IN_TRANSIT, PURCHASE_STATUS.IN_PORT, PURCHASE_STATUS.WAITING_FOR_SHIPPING, PURCHASE_STATUS.IN_WAREHOUSE];
    // console.log(this.props)
    return (
      <div id="content">
        <SearchBar 
          fields={SEARCH_LIST.PURCHASE_LIST_SEARCH_LIST} 
          onChangeSearch={(property, value) => this.onChangeSearch(property, value)} 
          onSearch={() => this.onSearch()}
        />

        <ListAndActions {...this.props} options={{
          statusNotViewEdit : statusNotViewEdit,
          statusNotViewDelete : statusNotViewDelete,
          textTotal: 'purchases',
          setPage: this.setPage,
          totalPage: this.props.purchaseState.count,
          pageNumber: pageNumber,
          perPage: 10,
          headers,
          actions,
          data,
          setSortPage: ()=>{},
          statusList: this.state.statusList,
          onApplyStyleOnRow: (item) => this.onApplyStyleOnRow(item),
          className: 'center'
        }} />
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    ...ownProps,
    appState: state.appState,
    authState: state.authState,
    purchaseState: state.purchaseState
  };
}

function mapDispatchToProps(dispatch) {
  return {
    appActions: bindActionCreators(appActions, dispatch),
    purchaseActions: bindActionCreators(purchaseActions, dispatch),
  };
}

ScreenFollowUp.propTypes = {
  authState: PropTypes.object.isRequired,
  appState: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ScreenFollowUp);
