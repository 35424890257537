import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
const moment = require('moment');
import BaseComponent from '../../base/BaseComponent';
import I18n from 'i18n-js';

class PurchaseHistoryView extends BaseComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {

    };
  }
  
  getDate = (day) => {
    const offset = new Date().getTimezoneOffset();
    let date = ''
    if(day) {
      date = new moment(day).utcOffset(-1 * offset)
      date = date.format('MMM DD, YYYY')
    }
    return date
  }

  render = () => {
    const { order } = this.props
    const currency = _.get(order, 'supplier.currency', "")

    let total = 0

    return (
      <div id="content">
        <ul className="content-header-title h-auto">
            <li className="title mg-right-60">
              <p> {I18n.t('PagePurchase.CustomerPO')}
                <span className="color-orange medium size-14 mg-left-15"> {_.get(order, 'style.order.customerPO', '')}</span>
              </p>
            </li>
            <li className="title mg-right-60">
              <p> {I18n.t('PagePurchase.Style')}
                <span className="color-orange medium size-14 mg-left-15"> {_.get(order, 'style.style', '')}</span>
              </p>
            </li>
            {!!order.purchaseNo && (<li className="title mg-right-60">
              <p> {I18n.t('PagePurchase.PurchaseOrderNo')}
                <span className="color-orange medium size-14 mg-left-15"> {_.get(order, 'purchaseNo', '')}</span>
              </p>
            </li>)}
            {!!order.supplier && (<li className="title mg-right-60">
              <p> {I18n.t('PagePurchase.Supplier')}
                <span className="color-orange medium size-14 mg-left-15"> {_.get(order, 'supplier.name', '')}</span>
              </p>
            </li>)}
            {!!_.get(order, 'createdBy.fullName', '') && (<li className="title mg-right-60">
                <p> {I18n.t('PagePurchase.Creator')}
                  <span className="color-orange medium size-14 mg-left-15"> {_.get(order, 'createdBy.fullName', '')}</span>
                </p>
              </li>)}
              {!!_.get(order, 'sender.fullName', '') && (<li className="title">
                <p> {I18n.t('PagePurchase.Sender')}
                  <span className="color-orange medium size-14 mg-left-15"> {_.get(order, 'sender.fullName', '')}</span>
                </p>
              </li>)}
        </ul>

        <div className="wrap-global-table">
          <table style={{width: '100%'}} className="global-table">
            <tbody>

              <tr>
                <th>{I18n.t('PagePurchase.Material')}</th>
                <th className="center">{I18n.t('PagePurchase.Content')}</th>
                <th className="center">{I18n.t('PagePurchase.Color')}</th>
                <th className="center">{I18n.t('PagePurchase.Size')}</th>
                <th className="center">{I18n.t('PagePurchase.Replacement')}<br/>P.O #</th>
                <th className="center">{I18n.t('PagePurchase.Unit')}</th>
                <th className="center">{I18n.t('PagePurchase.XMIL')}</th>
                <th className="center">{I18n.t('PagePurchase.ETD')}</th>
                <th className="center">{I18n.t('PagePurchase.ETA')}</th>
                <th className="center">{I18n.t('PagePurchase.Expected')}<br/>{I18n.t('PagePurchase.ReceivedDate')}</th>
                <th className="center">{I18n.t('PagePurchase.Payer')}</th>
                <th className="center">{I18n.t('PagePurchase.Quantity')}</th>
                <th className="center">{I18n.t('PagePurchase.Convert_Quantity')}</th>
                <th className="center">{I18n.t('PagePurchase.Price')}</th>
                <th className="center">{I18n.t('PagePurchase.Total')}<br/>{I18n.t('PagePurchase.Price')}</th>
              </tr>
              {order.materials.map((material, idx) => {
                const price = material.price > 0 ? (currency == 'USD' ? `$ ${material.price}` : currency ? `${material.price} ${currency}` : `${material.price}`) : 0;
                
                const qtConverted = this.convertUnit(material.needToBuy, material.material)
                let amount = material.price * material.needToBuy + (material.surcharge || 0) - (material.discount || 0);
                // if (material.payer == 'Customer' || material.payer == 'Subcontractor') amount = '-'
                // else {
                  total += amount
                  amount = amount > 0 ? (currency == 'USD' ? `$ ${amount}` : currency ? `${amount} ${currency}` : `${amount}`) : 0;
                // }
                  let covertQuantity = '-';
                  if(qtConverted != 0)  covertQuantity = `${qtConverted} ${this.getUnit(material, true)}`;
                return (
                  <tr key={idx}>
                    <td><span>{_.get(material, 'material.name', '')}</span></td>
                    <td className="center">{_.get(material, 'material.data.content', '')}</td>
                    <td className="center">{material.color || ''}</td>
                    <td className="center">{material.size || ''}</td>
                    <td className="center">{material.replacementPO}</td>
                    <td className="center">{_.get(material, 'material.data.unit', '')}</td>
                    <td className="center">{this.getDate(material.XMIL)}</td>
                    <td className="center">{this.getDate(material.ETD)}</td>
                    <td className="center">{this.getDate(material.ETA)}</td>
                    <td className="center">{this.getDate(material.exReceivedDate)}</td>
                    <td className="center">{material.payer || ''}</td>
                    <td className="center">{material.needToBuy} {this.getUnit(material)}</td>
                    <td className="center">{covertQuantity}</td>
                    <td className="center">{price}</td>
                    <td className="color-number center">{amount}</td>
                  </tr>
                )
              })}
              <tr className="g-total bg-white">
                <td colSpan="15" className="pd-top-20 pd-bottom-20">
                  <ul>
                    <li>
                    {I18n.t('PagePurchase.G-Total')}
                    </li>
                    <li className="color-number">
                      {total = total > 0 ? (currency == 'USD' ? `$ ${total}` : currency ? `${total} ${currency}` : `${total}`) : 0}
                    </li>
                  </ul>
                </td>
              </tr>
              {order.remark && <tr className="bg-white">
                <td className="pd-bottom-20" colSpan="15">
                  <span className="bold mg-bottom-10 text-decoration block">{I18n.t('PagePurchase.Remark')}</span>
                  <div>{order.remark}</div>
                </td>


              </tr>}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {};
}

PurchaseHistoryView.propTypes = {
  authState: PropTypes.object.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseHistoryView);

