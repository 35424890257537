import { resetPasswordDAL, getListUserDAL, editUserDAL, addUserDAL, deleteUserDAL, updateLockUserDAL } from '../api/userApi';
import {getShortName} from '../functions/helpers';

class userServices {

  constructor() {
  }


  resetPassword(id) {
    return resetPasswordDAL(id);
  }

  getListUser(options = {}) {
    options.sort = options.sort || {createdAt: -1};
    _.set(options, 'sort', JSON.stringify(options.sort));

    return getListUserDAL(options).then(res => {
      if (res.success && res.data && res.data.items){
        res.data.items.forEach(item => {
          item.fullName = `${item.firstName} ${item.lastName}`;
          item.shortName = getShortName(item.firstName, item.lastName);
        })
      }
      return res;
    });
  }

  editUser (id, data) {
    return editUserDAL(id, data);
  }

  updateLockUser (id) {
      return updateLockUserDAL(id);
  }

  addUser (data) {
    return addUserDAL(data);
  }

  deleteUser (id) {
    return deleteUserDAL(id);
  }
}

export default new userServices();
