import Base from './base';

class Production extends Base {
  constructor () {
    super();

    this.getListProgress = this.getListProgress.bind(this);
    this.getListProductionOrders = this.getListProductionOrders.bind(this);
    this.getListQAOrders = this.getListQAOrders.bind(this);
    this.getListPackingOrders = this.getListPackingOrders.bind(this);
    this.getListActualYield = this.getListActualYield.bind(this);
    this.updateActualYield = this.updateActualYield.bind(this);
  }

  getListProductionOrders (query ) {
    return super.execute(this.Urls.production.productionOrder, this.ApiMethod.get, query);
  }
  getListQAOrders (query ) {
    return super.execute(this.Urls.production.qaOrder, this.ApiMethod.get, query);
  }

  getListPackingOrders (query ) {
    return super.execute(this.Urls.production.packingOrder, this.ApiMethod.get, query);
  }

  getListProgress (id, query) {
    return super.execute(this.Urls.production.progress.replace('{id}', id), this.ApiMethod.get, query);
  }

  getListActualYield (id, query) {
    return super.execute(this.Urls.production.actualYield.replace('{id}', id), this.ApiMethod.get, query);
  }

  updateActualYield (id, data) {
    return super.execute(this.Urls.production.updateActualYield.replace('{id}', id), this.ApiMethod.put, data);
  }
}

export default new Production();
