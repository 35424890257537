import * as types from '../constants/actionTypes';
import {showLoading, hideLoading} from './commonUseActions';
import stockInServices from '../services/stockInServices';
import orderServices from "../services/orderServices";
import styleServices from "../services/styleServices";
import purchaseServices from "../services/purchaseServices";
import supplierServices from "../services/supplierServices";
import contractorServices from "../services/contractorServices";
import _ from 'lodash';

function getListItem (type, options = {}) {
  return (dispatch) => {
    showLoading(types, dispatch);

    return stockInServices.getListItem(type, options).then((res) => {
      hideLoading(types, dispatch);

      dispatch({
        type: types.STOCK_IN.GET_LIST_SUCCESS,
        data: res.data || [],
        typeStockIn: type
      });
      return res;
    }, (error) => {
      hideLoading(types, dispatch);
    });
  };
}

function getDetail (type, id, options = {}, reset = true) {
  return (dispatch) => {
    showLoading(types, dispatch);

    stockInServices.getDetail(type, id, options).then((res) => {
      hideLoading(types, dispatch);
      if (res) {
        dispatch({
          type: types.STOCK_IN.GET_DETAIL_SUCCESS,
          data: res.data || {},
          typeStockIn: type
        });
      }
    }, (error) => {
      hideLoading(types, dispatch);
    });
  };
}

function deleteItem (type, id, body) {
  return (dispatch) => {
    showLoading(types, dispatch);

    stockInServices.deleteItem(type, id, body).then((res) => {
      hideLoading(types, dispatch);

      if (res) {
        dispatch({
          type: types.STOCK_IN.RELOAD,
          data: true,
          typeStockIn: type
        });
      }
    }, (error) => {
      hideLoading(types, dispatch, error);
    });
  };
}

const  editItem =  (type, id, data) => dispatch => {
    showLoading(types, dispatch);
    stockInServices.editItem(type, id, data).then((res) => {
      hideLoading(types, dispatch);
      if (res.success) {
        dispatch({
          type: types.STOCK_IN.RELOAD,
          data: true,
          typeStockIn: type
        });
        return true;
      }
    }, (error) => {
      hideLoading(types, dispatch, error);
    });
    return false
}

function addItem (type, data) {
  return (dispatch) => {
    showLoading(types, dispatch);

    stockInServices.addItem(type, data).then((res) => {
      hideLoading(types, dispatch);

      if (res.success) {
        dispatch({
          type: types.STOCK_IN.RELOAD,
          data: true,
          typeStockIn: type
        });
      }
    }, (error) => {
      hideLoading(types, dispatch, error);
    });
  };
}

function setDetail (type, item) {
  return (dispatch) => {
    dispatch({
      type: types.STOCK_IN.SET_DETAIL,
      data: item,
      typeStockIn: type
    });
  };
}

function searchByCustomerPO(type, options, pageNumber, pageSize, populate) {
  return(dispatch) =>{
    showLoading(types, dispatch);
    return orderServices.getListOrder(options).then((res) => {
      hideLoading(types, dispatch);
      if(res && res.success) {
        let options = {
          pageSize: pageSize,
          pageNumber: pageNumber,
          sort: {updatedAt: -1},
          populate: populate,
          query: {
            orderId: {
              $in: res.data.items.reduce((arr, item) => arr.concat(item._id || []), [])
            }
          }
        };
        return getListItem(type, {...options})(dispatch).then(res => {
          if(res && res.success && res.data && res.data.items && !res.data.items.length && pageNumber > 1) {
            _.set(options, "pageNumber", 1);
            getListItem(type, options)(dispatch);
            pageNumber = 1;
          }
          return (_.set(res, "pageNumber", pageNumber));
        });
      }
    }, (error) => {
      hideLoading(types, dispatch, error);
    })
  }
}

function searchByStyle(type, options, pageNumber, pageSize, populate) {
  return(dispatch) =>{
    showLoading(types, dispatch);
    return styleServices.getAllStyles(options).then((res) => {
      hideLoading(types, dispatch);
      if(res && res.success) {
        let options = {
          pageSize: pageSize,
          pageNumber: pageNumber,
          sort: {updatedAt: -1},
          populate: populate,
          query: {
            styleId: {
              $in: res.data.items.reduce((arr, item) => arr.concat(item._id || []), [])
            }
          }
        };
        return getListItem(type, {...options})(dispatch).then(res => {
          if(res && res.success && res.data && res.data.items && !res.data.items.length && pageNumber > 1) {
            _.set(options, "pageNumber", 1);
            getListItem(type, options)(dispatch);
            pageNumber = 1;
          }
          return (_.set(res, "pageNumber", pageNumber));
        });
      }
    }, (error) => {
      hideLoading(types, dispatch, error);
    })
  }
}

function searchByPurchaseOrderNo(type, options, pageNumber, pageSize, populate) {
  return(dispatch) =>{
    showLoading(types, dispatch);
    return purchaseServices.getListPurchase(options).then((res) => {
      hideLoading(types, dispatch);
      if(res && res.success) {
        let options = {
          pageSize: pageSize,
          pageNumber: pageNumber,
          sort: {updatedAt: -1},
          populate: populate,
          query: {
            purchaseId: {
              $in: res.data.items.reduce((arr, item) => arr.concat(item._id || []), [])
            }
          }
        };
        return getListItem(type, {...options})(dispatch).then(res => {
          if(res && res.success && res.data && res.data.items && !res.data.items.length && pageNumber > 1) {
            _.set(options, "pageNumber", 1);
            getListItem(type, options)(dispatch);
            pageNumber = 1;
          }
          return (_.set(res, "pageNumber", pageNumber));
        });
      }
    }, (error) => {
      hideLoading(types, dispatch, error);
    })
  }
}

function searchBySupplier(type, options, pageNumber, pageSize, populate) {
  return(dispatch) =>{
    showLoading(types, dispatch);
    return supplierServices.getListSupplier(options).then(supRes => {
      hideLoading(types, dispatch);
      if(supRes && supRes.success) {
        let purOptions = {
          projection: "_id",
          query: JSON.stringify({
            supplierId: {
              $in: supRes.data.items.reduce((arr, item) => arr.concat(item._id || []), [])
            }
          })
        };
        return purchaseServices.getListPurchase(purOptions).then((res) => {
          hideLoading(types, dispatch);
          if(res && res.success) {
            let options = {
              pageSize: pageSize,
              pageNumber: pageNumber,
              sort: {updatedAt: -1},
              populate: populate,
              query: {
                purchaseId: {
                  $in: res.data.items.reduce((arr, item) => arr.concat(item._id || []), [])
                }
              }
            };
            return getListItem(type, {...options})(dispatch).then(res => {
              if(res && res.success && res.data && res.data.items && !res.data.items.length && pageNumber > 1) {
                _.set(options, "pageNumber", 1);
                getListItem(type, options)(dispatch);
                pageNumber = 1;
              }
              return (_.set(res, "pageNumber", pageNumber));
            });
          }
        }, (error) => {
          hideLoading(types, dispatch, error);
        })
      }
    }, (error) => {
      hideLoading(types, dispatch, error);
    })
  }
}

function searchByContractor(type, options, pageNumber, pageSize, populate) {
  return(dispatch) =>{
    showLoading(types, dispatch);
    return contractorServices.getListItem(options).then(res => {
      hideLoading(types, dispatch);
      if(res && res.success) {
        let options = {
          pageSize: pageSize,
          pageNumber: pageNumber,
          sort: {updatedAt: -1},
          populate: populate,
          query: {
            contractorId: {
              $in: res.data.items.reduce((arr, item) => arr.concat(item._id || []), [])
            }
          }
        };
        return getListItem(type, {...options})(dispatch).then(res => {
          if(res && res.success && res.data && res.data.items && !res.data.items.length && pageNumber > 1) {
            _.set(options, "pageNumber", 1);
            getListItem(type, options)(dispatch);
            pageNumber = 1;
          }
          return (_.set(res, "pageNumber", pageNumber));
        });
      }
    }, (error) => {
      hideLoading(types, dispatch, error);
    })
  }
}

export default {
  getListItem,
  deleteItem,
  editItem,
  addItem,
  getDetail,
  setDetail,
  searchByCustomerPO,
  searchByStyle,
  searchByPurchaseOrderNo,
  searchBySupplier,
  searchByContractor
};
