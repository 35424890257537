
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import _ from 'lodash';

import PreviewPurchaseOrder from '../../components/purchase/PreviewPurchaseOrder';

import * as selectors from '../../../selectors/purchase/showPurchaseDetail';
import * as actions from '../../../actions/purchase/showPurchaseDetail';

class View extends Component {

  componentWillMount() {
    const lastPage = _.last(this.props.appState.pageList);
    // console.log('===IN IS PREVIEW ORDER', lastPage);
    if (lastPage && lastPage.data && lastPage.data._id) {
      this.props.loadData(lastPage.data._id);
    } else {
      this.props.insertData((lastPage || {}).data);
    }
  }

  componentWillUnmount() {
    //this.props.resetData();
  }

  render() {
    return (
      <PreviewPurchaseOrder
        {...this.props}
      />
    )
  }
}

const mapStateToProps = state => ({
  appState: state.appState,
  sender: selectors.getSenderDetail(state),
  customerPO: selectors.getCustomerPO(state),
  style: selectors.getStyle(state),
  purchaseNo: selectors.getPurchaseNo(state),
  gTotal: selectors.getGTotal(state),
  items: selectors.getItems(state),
  supplierData: selectors.getSupplierData(state),
  remark: selectors.getRemark(state),
  order: selectors.getOrder(state),
  createdAt: selectors.getCreatedAt(state),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  loadData(id) {
    if (id) {
      dispatch(actions.fetchPurchaseOrder(id));
    }
  },

  insertData(data) {
    if (data) {
      dispatch(actions.insertPurchaseOrder(data));
    }
  },

  resetData() {
    dispatch(actions.resetData());
  },

});

export default connect(mapStateToProps, mapDispatchToProps) (withRouter(View));
