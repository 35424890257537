import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { garmentStatus } from '../../../constants/EnumTypes';
import _ from 'lodash';
import I18n from 'i18n-js';

class DamagedGarmentEdit extends Component {
  constructor(props, context) {
    super(props, context)

    this.state = {
      item: props.item || {}
    }
  }

  onChange = (idx, value) => {
    let { item } = this.state;
    item.sizes[idx].statusN = value
    this.setState({ item: _.set(item, 'status', value) });
  }

  save = () => {
    const { save } = this.props
    const { item } = this.state;

    this.props.save(item);
  }

  render = () => {
    const { item } = this.state;
    const { closeModal, isView } = this.props

    const offset = new Date().getTimezoneOffset();
    let date = ''
    if (item.date) {
      date = new moment(item.date).utcOffset(-1 * offset)
      date = date.format('MMM DD, YYYY')
    }
    return (
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <form>
            <div className="modal-header">
              <a className="close" onClick={closeModal}><span aria-hidden="true">&times;</span></a>
              <h4 className="modal-title" id="myModalLabel">{I18n.t('PageWareHouse.EDITSTATUS')}</h4>
            </div>

            <div className="modal-body">
              <div className="form-group">
                <div>
                  <div className="col-md-4 col-xs-12 text"><p>{I18n.t('PageWareHouse.Date')}</p></div>
                  <div className="col-md-8 col-xs-12 text"><p>{date}</p></div>
                </div>
                <div>
                  <div className="col-md-4 col-xs-12 text"><p>{I18n.t('PageWareHouse.CustomerPO')}</p></div>
                  <div className="col-md-8 col-xs-12 text"><p>{item.customerPO || ''}</p></div>
                </div>
                <div>
                  <div className="col-md-4 col-xs-12 text"><p>{I18n.t('PageWareHouse.Style')}</p></div>
                  <div className="col-md-8 col-xs-12 text"><p>{item.style || ''}</p></div>
                </div>
                <div>
                  <div className="col-md-4 col-xs-12 text"><p>{I18n.t('PageWareHouse.CutTicket')}</p></div>
                  <div className="col-md-8 col-xs-12 text"><p>{item.cutTicket || ''}</p></div>
                </div>
                <div>
                  <div className="col-md-4 col-xs-12 text"><p>{I18n.t('PageWareHouse.Contractor')}</p></div>
                  <div className="col-md-8 col-xs-12 text"><p>{item.contractor || ''}</p></div>
                </div>
                <div>
                  <div className="col-md-4 col-xs-12 text"><p>{I18n.t('PageWareHouse.Color')}</p></div>
                  <div className="col-md-8 col-xs-12 text"><p>{item.color || ''}</p></div>
                </div>
                <div className="pd-0-15 inline-block w-full">
                  <center className="bd-solid-top">
                    <table style={{ width: '100%', marginTop: '15px' }} className="damaged-garments-edit">
                      <thead>
                        <tr>
                          <th>{I18n.t('PageWareHouse.Size')}</th>
                          <th>{I18n.t('PageWareHouse.Fail')}</th>
                          <th>{I18n.t('PageWareHouse.Status')}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {(item.sizes || []).map((size, idx) => (
                          <tr key={idx}>
                            <td>{size.name || ''}</td>
                            <td>{size.fail || ''}</td>
                            <td>
                              {size.status == garmentStatus[1] ? size.status : (
                                <select className="form-control" value={size.statusN || garmentStatus[0] || ''} onChange={evt => this.onChange(idx, evt.target.value)} >
                                  {garmentStatus.map((k, idx) => (<option key={idx} value={k}>{k}</option>))}
                                </select>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </center>
                </div>
              </div>
            </div>
            {isView && 
              <div className="modal-footer">
                <button type="button" className="bt-orange mg-l-5" onClick={closeModal}>{I18n.t('Btn.OK')}</button>
              </div>
            }
            {!isView &&
              <div className="modal-footer">
                <button type="button" className="bt-black" onClick={closeModal}>{I18n.t('Btn.CANCEL')}</button>
                <button type="button" className="bt-orange mg-l-5" onClick={this.save}>{I18n.t('Btn.SAVE')}</button>
              </div>
            }
          </form>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {

  };
}

function mapDispatchToProps(dispatch) {
  return {
  };
}

DamagedGarmentEdit.propTypes = {
  isView: PropTypes.bool
};

DamagedGarmentEdit.defaultProps = {
  isView: false
};

export default connect(mapStateToProps, mapDispatchToProps)(DamagedGarmentEdit);
