import {getListItemDAL, editItemDAL, getItemDAL} from '../api/damagedGarmentApi';
import _ from 'lodash';

class damagedGarmentServices {

  constructor() {
  }

  getListItem (options = {}) {
    options.query && _.set(options, 'query', JSON.stringify(options['query']));
    options.sort && _.set(options, 'sort', JSON.stringify(options['sort']));
    return getListItemDAL(options);
  }
  
  editItem (id, data) {
    return editItemDAL(id, data);
  }
}

export default new damagedGarmentServices();
