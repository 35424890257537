import React from 'react';
import PropTypes from 'prop-types';

const SelectValidate = ({options, value, label, placeholder, property, error, onChange, wrapClass, isRequired = false}) => {
  wrapClass = wrapClass ? wrapClass : 'col-md-6 col-xs-12'
  return (
    <div className={wrapClass}>
      <div className="form-group">
        <div className="col-md-4 col-xs-12 text no-padding-left">
          <p>{label}</p> {isRequired && <span className = "color-red"> *</span>}
        </div>
        <div className="col-md-8 col-xs-12 value no-padding-right">
          <select className="form-control" value={value} placeholder={placeholder}
                  onChange={evt => onChange(property, evt.target.value)}>
            <option value="" />
            {options.map((term, idx1) => (<option key={idx1} value={term.value}>{term.label}</option>))}
          </select>
          {error && <div className="alert-warning">{error}</div>}
        </div>
      </div>
    </div>
  );
}

SelectValidate.propTypes = {
  options: PropTypes.array.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  property: PropTypes.string.isRequired,
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  wrapClass: PropTypes.string
};

export default SelectValidate;