import Base from './base';

class Dashboard extends Base {
  constructor () {
    super();

    this.getDashboard = this.getDashboard.bind(this);
  }

  getDashboard (options = {}) {
    return super.execute(this.Urls.dashboard, this.ApiMethod.get, options);
  }
}

export default new Dashboard();
