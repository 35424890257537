/**
 * @author hienhc <hienhc@nexlesoft.com>
 * @create date 2018-09-06 11:48:02
*/

const C = "EDIT_PKL_SHIP";

export default {
    /* LOAD RAW DATA TO DISPLAY IN THE EDITOR ACTIONS */
    FETCH_PKL_SHIP_DETAIL: `${C}_FETCH_PKL_SHIP_DETAIL`,
    CANCEL_FETCH_PKL_SHIP_DETAIL: `${C}_CANCEL_FETCH_PKL_SHIP_DETAIL`,
    CHANGE_PKL_SHIP_DETAIL: `${C}_CHANGE_PKL_SHIP_DETAIL`,

    /**
     * Actions that will affect change in data
     */
    CHANGE_ACTIVE_ORDER: `${C}_CHANGE_ACTIVE_ORDER`,
    CHANGE_ACTIVE_STYLE: `${C}_CHANGE_ACTIVE_STYLE`,
    REMOVE_STYLE: `${C}_REMOVE_STYLE`,
    
    SHOW_BAR_ACTUAL_QUANTITIES_EDITOR: `${C}_SHOW_BAR_ACTUAL_QUANTITIES_EDITOR`,
    CANCEL_BAR_ACTUAL_QUANTITIES_EDITOR: `${C}_DISMISS_BAR_ACTUAL_QUANTITIES_EDITOR`,

    CHANGE_BAR_ACTUAL_QUANTITY_INPUT_VALUE: `${C}_CHANGE_BAR_ACTUAL_QUANTITY_INPUT_VALUE`,
    PERFORM_CHANGE_BAR_ACTUAL_QUANTITIES: `${C}_PERFORM_CHANGE_BAR_ACTUAL_QUANTITIES`,

    SHOW_CARTON_DETAIL: `${C}_SHOW_CARTON_DETAIL`,
    CHANGE_CHECKED_PACKING: `${C}_CHANGE_CHECKED_PACKING`,
    CHANGE_PACKED_NWKGS: `${C}_CHANGE_PACKED_NWKGS`,
    CHANGE_PACKED_GWKGS: `${C}_CHANGE_PACKED_GWKGS`,
    CHANGE_PACKING_NUMBER: `${C}_CHANGE_PACKING_NUMBER`,
    /**
     * Actions to clear all data in specific store
     */
    RESET_DATA: `${C}_RESET_DATA`,
    RESET_BAR_NUMBER: `${C}_RESET_BAR_NUMBER`,

    /**
     * Actions to update successful
     */

     UPDATE_SUCCESS: `${C}_UPDATE_SUCCESS`,

     // Active Screen view
     CHANGE_VIEW: `${C}_CHANGE_VIEW`,
     ACTIVE_SCREEN_EDIT_PKL_SHIP: `${C}_ACTIVE_SCREEN_EDIT_PKL_SHIP`,
    CHANGE_COMMODITY: `${C}_CHANGE_COMMODITY`,
}