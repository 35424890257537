import C from '../../../constants/accounting/payable/historyPreviewContainer';
import accountingServices from "../../../services/accountingServices";

export const activeScreenPreviewContainer = () => dispatch => {
    dispatch({type: C.ACTIVE_SCREEN_STATUS, payload: true})
}

export const getInvoiceIEContainerView = (id) => dispatch => {
    accountingServices.getInvoiceDetail(id).then(res => {
        if(res.success) {
            dispatch({type: C.CHANGE_INVOICE_DETAIL, payload: res.data})
        }
    })
}

export const resetData = () => dispatch => {
    dispatch({type: C.RESET_DATA})
}