import {getListFabricDAL, addFabricDAL, editFabricDAL, getFabricDAL, getFabricByKeyDAL, deleteFabricDAL} from '../api/fabricApi';
import _ from 'lodash';

class fabricServices {

  constructor() {
  }

  getListFabric (options = {}) {
    options.populate = (options.populate ? `${options.populate}` : '');
    options.sort = options.sort || {createdAt: -1};
    options.query = '{"type":"FABRIC"}';
    _.set(options, 'sort', JSON.stringify(options.sort));

    return getListFabricDAL(options);
  }

  getDetail (id) {
    return getFabricDAL(id, {});
  }

  getDetailByKey (key) {
    return getFabricByKeyDAL(key, {});
  }

  deleteFabric (id) {
    return deleteFabricDAL(id);
  }

  editFabric (id, data) {
    return editFabricDAL(id, data);
  }

  addFabric (data) {
    data.type = 'FABRIC';
    return addFabricDAL(data);
  }
}

export default new fabricServices();
