import React from "react";
import PropTypes from "prop-types";
import ReactExport from "react-data-export";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

import * as utils from "../../../../functions/utils";
import TableList from "../../../../components/common/datatables/TableList";
import _ from "lodash";
const formatCurrency = require('format-currency');



const PreviewDeclaration = props => {
    let labelName = 'Name :';
    let name = {value: props.name || '', style: {font: {patternType: "solid", color: {rgb: "FFFF0000"}}}};
    let labelTotalAmount = {value: 'Total Amount : '};
    let totalAmount = {
        value: formatCurrency(props.totalAmount) + ' VND',
        style: {font: {patternType: "solid", color: {rgb: "FFFF0000"}}}
    };
    let labelTotalAdvance = 'Total Advance : ';
    let totalAdvance = {
        value: formatCurrency(props.totalAdvance) + ' VND',
        style: {font: {patternType: "solid", color: {rgb: "FFFF0000"}}}
    };
    let labelTotalRemaining = 'Total Remaining : ';
    let totalRemaining = {
        value: formatCurrency(props.totalRemaining) + ' VND',
        style: {font: {patternType: "solid", color: {rgb: "FFFF0000"}}}
    };
    let header = [labelName, name, labelTotalAmount, totalAmount, labelTotalAdvance, totalAdvance, labelTotalRemaining, totalRemaining];
    let putData = [];

    _.map(props.listDeclaration, (el) => {
        let keys = [
            {key: 'string', value: 'declarationNo', color: '00000000'},
            {key: 'date', value: 'declarationDate', color: '00000000'},
            {key: 'string', value: 'borderGate', color: '00000000'},
            {key: 'string', value: 'qtyPKG', color: '00000000'},
            {key: 'number', value: 'crsPaperWork', color: '00000000'},
            {key: 'number', value: 'truckingIn', color: '00000000'},
            {key: 'number', value: 'expense', color: '00000000'},
            {key: 'number', value: 'localCharge', color: '00000000'},
            {key: 'number', value: 'advance', color: 'FFFF0000'},
            {key: 'number', value: 'remaining', color: 'FF00FF00'}
            ];

        putData.push(utils.mapDataForCell(el, keys));
    });
    let headerTable = ['Declaration No', 'Declaration Date', 'Border Gate', 'QTY PKG', 'Import/Export Fees',
        'Transportation Fess', 'Expense', 'Local Charge', 'Advance', 'Remaining'];
    let dataHeaderTable = [];
    for(let i = 0; i < headerTable.length ; i++){
        dataHeaderTable.push({
            value: headerTable[i],
            style: utils.genStyleHeaderForCell('00000000')
        });
    }
    putData.unshift(dataHeaderTable);
    const multiDataSet = [
        {
            columns: [
                {title: '', width: {wch: 20}},//pixels width
                {title: '', width: {wch: 40}},//char width
                {title: '', width: {wch: 20}},
                {title: '', width: {wch: 40}},
                {title: '', width: {wch: 20}},//pixels width
                {title: '', width: {wch: 40}},//char width
                {title: '', width: {wch: 20}},
                {title: '', width: {wch: 40}},
            ],
            data: [header]
        },

        {
            xSteps: 0, // Will start putting cell with 1 empty cell on left most
            ySteps: 0, //will put space of 5 rows,
            columns: [],
            data: putData
        }
    ];
    let nameFile = '';
    let d = new Date();
    if(props.invoiceNo){
        nameFile = 'INV_' + props.invoiceNo + '_' + (Number(d.getMonth()) + 1 < 10 ? "0" + (Number(d.getMonth()) + 1) : Number(d.getMonth()) + 1)  + '_' + d.getFullYear();
    }else{
        nameFile = 'INV_' +  (Number(d.getMonth()) + 1 < 10 ? "0" + (Number(d.getMonth()) + 1) : Number(d.getMonth()) + 1)  + '_' + d.getFullYear();
    }

    return (
        <div>
            <ul className="content-header-title float-none pd-0-5 justify-content-space-between display-flex">
                <li>
                    <p>Name
                        <span className="color-orange medium mg-left-25 size-16">{props.name}</span>
                    </p>
                </li>
                <li>
                    <p>Total Amount
                        <span
                            className="color-number medium mg-left-25 size-16">{formatCurrency(props.totalAmountPreview)} VND</span>
                    </p>
                </li>
                <li>
                    <p>Total Advance
                        <span
                            className="color-number medium mg-left-25 size-16">{formatCurrency(props.totalAdvance)} VND</span>
                    </p>
                </li>

                {props.type != 'historyPreview' &&
                <li>
                    <p>Total Remaining
                        <span
                            className="color-number medium mg-left-25 size-16">{formatCurrency(props.totalAmountPreview - props.totalAdvance)} VND</span>
                    </p>
                </li>}
                <li className="add-filter-top">
                    <ExcelFile filename={nameFile}
                        element={
                            <span className="cursor-pointer">
                                <i className="fa fa-floppy-o" aria-hidden="true"></i>{"Export"}
                            </span>
                        }>
                        <ExcelSheet dataSet={multiDataSet}  name={nameFile}/>
                    </ExcelFile>
                </li>
            </ul>
            <div className="pd-5">
                <TableList items={props.listDeclaration || []} columns={props.columns || []}
                           options={{clsTbl: 'global-table'}}/>
            </div>
        </div>
    )
}
PreviewDeclaration.propTypes = {
    items: PropTypes.array,
    columns: PropTypes.array,
    listDeclaration: PropTypes.array,
    name: PropTypes.string,
    totalAmountPreview: PropTypes.number,
    totalAdvance: PropTypes.number,
    totalRemaining: PropTypes.number
}

PreviewDeclaration.defaultProps = {}
export default PreviewDeclaration