import _ from 'lodash';

export const getPackingDetail = ({ viewPklOfProdDetail }) =>viewPklOfProdDetail.packingDetail && viewPklOfProdDetail.packingDetail[0];

export const getSizes = ({ viewPklOfProdDetail}) => (styleId, cutId) => {
    let sizes = [];
    viewPklOfProdDetail.packingDetail && _.map(viewPklOfProdDetail.packingDetail[0].styles, style => style._id === styleId && _.map(style.cutTickets, cutTicket => {
        if(cutTicket._id === cutId) {
            _.map(cutTicket.colors, color => _.map(color.color.sizes, size => {
                if( _.findIndex(sizes, item => item === size.name) === -1) {
                    sizes.push(size.name)
                }
            }))
        }
    }))
    return sizes;
}

export const getActiveStyle = ({ viewPklOfProdDetail }) => styleId => {
    let data = viewPklOfProdDetail.packingDetail[0].styles.find(style => style._id == styleId && style)
    return data.active;
}

export const getPackingType = ({ viewPklOfProdDetail }) => viewPklOfProdDetail.packingDetail && viewPklOfProdDetail.packingDetail[0].type;

export const getCarton = ({ viewPklOfProdDetail }) => (styleId, cutId, colorId) => {
        if(!viewPklOfProdDetail || !viewPklOfProdDetail.packingDetail[0] || viewPklOfProdDetail.packingDetail[0].type == 'GOHH') return [];
        
        let cartons = [];
        _.map(viewPklOfProdDetail.packingDetail[0].styles, style => style._id == styleId &&
                        _.map(style.cutTickets, cut => cut._id == cutId && 
                            _.map(cut.colors, color => {
                                if(color._id == colorId) {
                                    _.map(color.packings, (packing, index) => {
                                        if(_.findIndex(cartons, carton => carton.props.group == packing.props.group) == -1 && packing.props.no != 0) {
                                            cartons.push({props: _.cloneDeep(packing.props), items: _.cloneDeep(packing.items)}) 
                                        }
                                    })
                                    
                                }
                            })))
        
        _.map(cartons || [], carton => {
            const temp = carton.props.group && carton.props.group.split('-');
            carton.noBegin = temp[0] || '';
            carton.noEnd = temp[1] || '';
            carton.quantity = temp ? temp[1] ?  temp[1] * 1 - temp[0] * 1 + 1 : 1 : 0;
            carton.quantityCTN = _.sum(carton.items.map(item => item.planQuantity || 0))
            carton.total = (carton.quantity * carton.quantityCTN) || 0
        })
        return cartons || []
}

export const getPackingTotal = ({viewPklOfProdDetail}) => (styleId, cutId, colorId, sizeId) => {
    if(!viewPklOfProdDetail.packingDetail) return 0;
    let packingTotal = (viewPklOfProdDetail.packingDetail[0].styles || []).map((style) => {
        return {
            id: style._id,
            childs: style.cutTickets.map(cut => ({
                 id: cut._id,
                 childs: cut.colors.map(color => ({
                     id: color._id,
                     childs: _.map(_.groupBy(_.concat(...(color.packings || []).map(packing => packing.items)), (it) => it.name), (v, k) => ({
                        name: k,
                        items: v,
                        calTotal: function() {
                            return _.sum(v.map(item => item.planQuantity || 0))
                        }
                     })),
                     calTotal: function() {
                        if(this.childs === undefined || this.childs === null) return 0;
                        const sum = _.sum(this.childs.map(c => c.calTotal()))
                        return sum;
                     },
                     getSize: function(_sizeName) {
                        if (this.childs)
                        return _.find(this.childs, item => item.name === _sizeName);
                    },
                 })),
                calTotal: function() {
                    if(this.childs === undefined) return 0;
                    
                    const sum = _.sum(this.childs.map(c => c.calTotal()))
                    return sum;
                },
                getColor: function(_colorId) {  
                    if (this.childs)
                    return _.find(this.childs, item => item.id === _colorId);
                    },
            })),
            calTotal: function() {
                if(this.childs === undefined) return 0;
                const sum = _.sum(this.childs.map(c => c.calTotal()))
                return sum;
            },
            getCutTicket: function(_cutId) {
                if (this.childs)
                    return _.find(this.childs, item => item.id === _cutId);
            },
        }
    } )
    
    if(!packingTotal) return 0;
    if(styleId) {
        let style = _.filter(packingTotal, sty => sty.id == styleId)[0];
        if(cutId) {
            let cut = style.getCutTicket(cutId);
            if(colorId) {
                let color = cut.getColor(colorId);
                if(sizeId) {
                    let size = color.getSize(sizeId);
                    return size ?  size.calTotal() : 0;
                }
                return color.calTotal();
            }
            return cut.calTotal();
        }
        return style.calTotal();
    } else {
        return _.sum(packingTotal.map(it => it.calTotal()))
    }

}