import { createSelector } from 'reselect';
import _ from 'lodash';
import formatCurrency from 'format-currency';

export const getInvoiceDetail = ({ historyPreviewSupplierDomestic }) =>
  historyPreviewSupplierDomestic.invoiceDetail || {};

export const getCurrency = createSelector([getInvoiceDetail], data => {
  return data.invoiceDetail && data.invoiceDetail.supplier.currency;
});

export const getCurrencyTypeUSD = createSelector([getCurrency], currency => {
  return currency && currency === 'USD' ? '$' : '';
});

export const getCurrencyTypeVND = createSelector([getCurrency], currency => {
  return currency && currency === 'VND' ? ' VND' : '';
});

export const getListItems = createSelector(
  [state => state, getCurrencyTypeUSD, getCurrencyTypeVND],
  (state, currencyTypeUSD, currencyTypeVND) => {
    const historyPreviewSupplierDomestic = state.historyPreviewSupplierDomestic;
    if (historyPreviewSupplierDomestic.invoiceDetail && historyPreviewSupplierDomestic.invoiceDetail.invoiceDetail) {
      const { purchaseOrders } = historyPreviewSupplierDomestic.invoiceDetail.invoiceDetail;
      let results = [];
      purchaseOrders.map(item =>
        (item.purchaseOrder.materials || []).map(m => {
          results.push({
            ...m,
            purchaseNo: (item.purchaseOrder || {}).purchaseNo,
            material: (m.material || {}).name,
            unit: ((m.material || {}).data || {}).unit,
            priceText: currencyTypeUSD + (m.price || 0) + currencyTypeVND,
            amount: currencyTypeUSD + formatCurrency(_.round((m.price || 0) * (m.quantity || 0), 2)) + currencyTypeVND,
            amountNumber: (m.price || 0) * (m.quantity || 0),
          });
        })
      );
      return results;
    }
    return [];
  }
);

export const getAmount = createSelector([getListItems], data => {
  return _.sum(data.map(item => item.amountNumber || 0));
});

export const getMainVATAmount = createSelector([getInvoiceDetail], data => {
  if (data.invoiceDetail) {
    const totalAmount = (data.invoiceDetail.vat || {}).totalAmount || 0;
    const taxRate = (data.invoiceDetail.vat || {}).taxRate || 0;
    return totalAmount * (taxRate / 100);
  }
});

export const getSumVatAmount = createSelector([getInvoiceDetail], data => {
  if (data.invoiceDetail) {
    return _.sum(
      data.invoiceDetail.purchaseOrders.map(s => (s.vat ? (s.vat.totalAmount || 0) * ((s.vat.taxRate || 0) / 100) : 0))
    );
  }
});

export const getVatAmount = createSelector([getMainVATAmount, getSumVatAmount], (mainVatAmount, sumVatAmount) => {
  return _.round((mainVatAmount || 0) + (sumVatAmount || 0), 2);
});

export const getTotalAmount = createSelector([getAmount, getVatAmount], (amount, vatAmount) => {
  return _.round((amount || 0) + (vatAmount || 0), 2);
});

export const getInvoiceNo = createSelector([getInvoiceDetail], data => {
  return data.invNo;
});

export const getIssuedDate = createSelector([getInvoiceDetail], data => {
  return data.issuedDate; //moment(data.issuedDate).format("MMM DD, YYYY");
});

export const getSupplier = createSelector([getInvoiceDetail], data => {
  return data.invoiceDetail && data.invoiceDetail.supplier.name;
});

export const getActiveScreenStatus = ({ historyPreviewSupplierDomestic }) =>
  historyPreviewSupplierDomestic.activeScreen;
