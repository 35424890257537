import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import * as R from 'ramda';
import I18n from 'i18n-js';

import EditCutTicketModal from '../../../components/customer-po/EditCutTicketModal';

import * as selectors from '../../../../selectors/customer-po/current/createCutTicket';
import * as actions from '../../../../actions/customer-po/current/createCutTicket';

class CreateCutTicketModal extends Component {
  componentWillMount() {
    this.props.loadData();
  }

  render() {
    return (
      <EditCutTicketModal
        title={I18n.t('PageOrder.ADDCUTTICKET')}
        {...this.props}
        onSubmit={() => this.props.save(this.props.styleId, this.props.fnBody())}
      />
    );
  }
}

const mapStateToProps = state => ({
  isOpen: selectors.isOpen(state),
  cutTicket: selectors.getCutTicketName(state),
  selectedWarehouse: selectors.getSelectedWarehouse(state),
  selectedStore: selectors.getSelectedStore(state),
  selectableStores: selectors.getSelectableStores(state),
  selectedShipMode: selectors.getSelectedShipMode(state),
  selectableShipModes: selectors.getSelectableShipModes(state),
  mainLabel: selectors.getMainLabel(state),
  ihDate: selectors.getIhDate(state),
  note: selectors.getNote(state),
  errors: selectors.getErrors(state),
  canSave: selectors.canSave(state),
  isSubmiting: selectors.isSubmiting(state),
  fnBody: selectors.fnGenerateBody(state),
  styleId: selectors.getStyleId(state),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  loadData() {
    dispatch(actions.listSelectableShipModes());
    dispatch(actions.listSelectableStores());
  },

  onClose() {
    dispatch(actions.hideModal());
  },

  onChangeSelectedWarehouse(item) {
    dispatch(actions.changeSelectedWarehouse(R.path(['raw'], item)));
  },

  onChangeCutTicket(value) {
    dispatch(actions.changeCutTicketName(value));
  },

  onChangeSelectedStore(item) {
    dispatch(actions.changeSelectedStore(R.path(['raw'], item)));
  },

  onChangeSelectedShipMode(item) {
    dispatch(actions.changeSelectedShipMode(R.path(['raw'], item)));
  },

  onChangeMainLabel(value) {
    dispatch(actions.changeMainLabel(value));
  },

  onChangeIhDate(value) {
    dispatch(actions.changeIhDate(value));
  },

  onChangeNote(value) {
    dispatch(actions.changeNote(value));
  },

  save(styleId, body) {
    dispatch(actions.submit(styleId, body));
  }

});

export default connect(mapStateToProps, mapDispatchToProps) (withRouter(CreateCutTicketModal));
