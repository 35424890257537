import C from '../../../constants/accounting/payable/editInvoiceSupplierOversea';
import accountingApi from '../../../api/accountingApi';
import accountingServices from '../../../services/accountingServices';
import {toastr} from 'react-redux-toastr';
import I18n from 'i18n-js';

export const activeScreenEditSupplierOversea = () => dispatch => {
    dispatch({type: C.ACTIVE_SCREEN_STATUS, payload: true});
}

export const getInvoiceSupplierOverseaDetail = (id) => dispatch => {
    accountingServices.getInvoiceSupplierOversea(id).then(res => {
        if(res.success) {
            dispatch({type: C.CHANGE_INVOICE_DETAIL, payload: res.data})
            dispatch({type: C.CHANGE_INVOICE_TYPE, payload: res.data.typeInvoice});
            dispatch({type: C.CHANGE_LIST_PURCHASE_DETAIL, payload: res.data.invoiceDetail.purchaseOrders});
            if(res.data.invoiceDetail) {
                if(res.data.invoiceDetail.supplier)
                    dispatch(getListCreditDebit({supplier: res.data.invoiceDetail.supplier._id}));

            }
        }
    })
}

export const getListCreditDebit = params => dispatch => {
    accountingServices.getListCreditDebit(params).then(
        res => {
            dispatch({ type: C.CHANGE_LIST_CREDIT_DEBIT, payload: res.data || [] });
        },
        error => {
            console.log(JSON.stringify(error));
        }
    );
};

export const changePaymentType = (value) => dispatch => {
    dispatch({ type: C.CHANGE_PAYMENT_TYPE, payload: value})
}

export const changeSupplier = (value) => dispatch => {
    dispatch({type: C.CHANGE_SUPPLIER_VALUE, payload: value})
}

export const changeFactor = (value) => dispatch => {
    dispatch({type: C.CHANGE_FACTOR_VALUE, payload: value})
}

export const changeInvoiceNo = (value) => dispatch => {
    dispatch({type: C.CHANGE_INVOICE_NO_VALUE, payload: value})
}

export const changeIssuedDate = (value) => dispatch => {
    dispatch({type: C.CHANGE_ISSUED_DATE_VALUE, payload: value})
}

export const changeDueDate = (value) => dispatch => {
    dispatch({type: C.CHANGE_DUE_DATE_VALUE, payload: value})
}

export const changePayAmount = (value, remaining, paid) => dispatch => {
    if((value * 1) > remaining) {
        toastr.error('Error', 'Pay Amount can not be more than Remaining');
        return;
    } 
    if(paid >= remaining) {
        toastr.warning('Warning', 'Completly payment');
        return;
    }
    if((value * 1) <= remaining) {
        dispatch({type: C.CHANGE_PAY_AMOUNT_VALUE, payload: value})
    }
}

export const changeCreditDebit = (value) => dispatch => {
    dispatch({type: C.CHANGE_CREDIT_DEBIT_VALUE, payload: value})
}

export const changeTransactionNo = (value) => dispatch => {
    dispatch({type: C.CHANGE_TRANSACTION_NO_VALUE, payload: value})
}

export const changePaymentMethod = (value) => dispatch => {
    dispatch({type: C.CHANGE_PAYMENT_METHOD_VALUE, payload: value})
}

export const changeCheckedPurchaseOrder = (_id, checked) => dispatch => {
    dispatch({type: C.CHANGE_CHECKED_PURCHASE_ORDER, payload: {_id, checked}})
}

export const toggleActivePurchaseOrder = (_id, active) => dispatch => {
    dispatch({type: C.CHANGE_TOGGLE_ACTIVE, payload: {_id, active}})
}

export const changePurchaseInvoiceNo = (purchaseId, materialId, index, value) => dispatch => {
    dispatch({type: C.CHANGE_PURCHASE_INVOICE_NO, payload: {purchaseId, materialId, index, value}})
}

export const addFileTransfer = (file) => dispatch => {
    var FileSize = file.size / 1024 / 1024; // in MB
  if (FileSize > 2) {
    toastr.error('Error', I18n.t('ErrorMessages.FileSizeExceeds2MB'));
    return;
  } 
  const readerBase64 = new FileReader();
  readerBase64.onload = (eventBase64) => {
    const url = eventBase64.target.result;
    const filename = file.name;

    const payload = { id: filename, file, filename, url, }
    dispatch({ type: C.ADD_TRANSFER_FILE, payload });
  };
  readerBase64.readAsDataURL(file);
}

export const onRemoveFile = (id) => dispatch => {
    dispatch({ type: C.REMOVE_TRANSFER_FILE, payload: id });
}

export const save = (paymentType, data, totalAmount) => dispatch => {
    if(data.payment){
        if(totalAmount <= (data.paid || 0 + _.toInteger(data.payment.paid))){
            data.paidDone = true;
        }
    }

    accountingServices.updateInvoice(data._id, paymentType, data).then(res => {
        if(res.success) {
            dispatch({type: C.SAVE_SUCCESS, payload: true})
        }
    })
}

export const resetSuccessStatus = () => dispatch => {
    dispatch({type: C.RESET_DATA_ALL})
}

export const copyFileToRawData = (files) => dispatch => {
    dispatch({ type: C.COPY_FILES_TO_RAW_DATA, payload: files });
}

export const emptyFilesInRawData = () => dispatch => {
    dispatch({ type: C.EMPTY_FILES_IN_RAW_DATA });
}

export const onSubmitFiles = (files) => dispatch => {
    dispatch({ type: C.SUBMIT_FILES, payload: files });
}

export const createReceipt = (value) => dispatch => {
    dispatch({ type: C.CREATE_RECEIPT, payload: value });
}

export const changeReceiptValue = (key, value) => dispatch => {
    dispatch({ type: C.CHANGE_RECEIPT_VALUE, payload: {key, value} });
}

export const onSubmitReceipt = (data) => dispatch => {
    dispatch({ type: C.SUBMIT_RECEIPT, payload: data });
}

export const changeEmptyReceipt = () => dispatch => {
    dispatch({ type: C.EMPTY_RECEIPT});
}