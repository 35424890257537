import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {
  connect
} from 'react-redux';
import { Link, Switch, Route, Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import BaseComponent from '../base/BaseComponent';
import ScreenShipIn from './shipIn/ScreenShipIn';
import ScreenShipOut from '../../ui/containers/shipping/ship-out/ShipOutContainer';
import _ from 'lodash';
import {
  getListFollowUp,
  getShipInList,
  getHistoryList,

} from '../../actions/shippingActions'
import {
  SHIPPING_SCREEN
} from '../../constants/constants'
import {SHIPPING_SHIP_IN_URL, SHIPPING_SHIP_OUT_URL, SHIP_OUT, SHIPPING_URL} from '../../constants/routeConstants';
import * as utils from "../../functions/utils";
import ScreenCreateShipment from './shipIn/ScreenCreateShipment'
import ScreenHistory from './shipIn/ScreenHistory';
class PageShipping extends BaseComponent {
  constructor (props, context) {
    super(props, context);
    this.state = {
      tabCurrent: '',
      tabChild: '',
      tabActive: 1
    };
  }

  componentWillReceiveProps (newProps) {
    let {tabCurrent, tabChild} = this.state;
    // !(tabCurrent === _.get(newProps.appState, 'page.tab', '')) && this.setState({tabCurrent: _.get(newProps.appState, 'page.tab', '')});
    // !(tabChild === _.get(newProps.appState, 'page.tabChild', '')) && this.setState({tabChild: _.get(newProps.appState, 'page.tabChild', '')});
  }

  getEditComponent (pageCurrent, tabSub = '') {
    let EditComponent = null;
    
    switch(pageCurrent.screen)
    {
      case 'createShipment':
        EditComponent = ScreenCreateShipment;
        break;
      case 'editShipin':
        EditComponent = ScreenCreateShipment;
        break;
      case 'viewHistory':
        EditComponent = ScreenHistory;
    }

    return EditComponent;
  }
  render = () => {
    if(!this.props.appState.tabActive){
        this.props.appState.tabActive = 1
    }
    let roles = utils.getRoleByAuthState(this.props.authState, 'shipping');
    const pageCurrent = this.props.appState.page || {};
    let {tabCurrent, tabChild, tabActive} = this.state;
    let EditComponent = this.getEditComponent(pageCurrent, _.get(pageCurrent, 'context.tabSub', ''));
    let show = (pageCurrent.isEdit || pageCurrent.isAdd) && EditComponent;
    return (
      <div>
        { show && (<EditComponent {...this.props} roles={roles}/>)}
        <div  id="content">
          <Switch>
              <Route path={SHIPPING_SHIP_IN_URL} extact render= {() =>  !show && <ScreenShipIn {...this.props} tabActive={tabActive} tabCurrent={tabCurrent} tabChild={tabChild} roles={roles} />}/>
              <Route path={SHIPPING_SHIP_OUT_URL} render= {() =>  <ScreenShipOut {...this.props} roles={roles}/>}/>
              <Route path={SHIPPING_URL} exact render={() => <Redirect to={SHIPPING_SHIP_IN_URL} />} />
          </Switch>
          </div>
      </div>
    );
  }
}

function mapStateToProps (state, ownProps) {
  return {
    appState: state.appState,
    authState: state.authState,
  };
}

function mapDispatchToProps (dispatch) {
  return {
    getListFollowUp: bindActionCreators(getListFollowUp, dispatch),
    getShipInList: bindActionCreators(getShipInList, dispatch),
    getHistoryList: bindActionCreators(getHistoryList, dispatch),
  };
}

PageShipping.propTypes = {
  authState: PropTypes.object.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(PageShipping);
