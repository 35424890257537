import Base from './base';
import moment from 'moment';

class Packing extends Base {
  constructor () {
    super();

    this.getListDAL = this.getListDAL.bind(this);
    this.deletePackingDAL = this.deletePackingDAL.bind(this);
    this.addPackingDAL = this.addPackingDAL.bind(this);
    this.editPackingDAL = this.editPackingDAL.bind(this);
    this.notify = this.notify.bind(this);
    this.search = this.search.bind(this);
    this.getPackingByOrderAndContractor = this.getPackingByOrderAndContractor.bind(this);
    this.stockPackingAPI = this.stockPackingAPI.bind(this);
    this.reshipPackingAPI = this.reshipPackingAPI.bind(this);
  }

  getListDAL (query) {
    return super.execute(this.Urls.packing.list, this.ApiMethod.get, query);
  }

  deletePackingDAL (id) {
    return super.execute(this.Urls.packing.detail + '/' + id, this.ApiMethod.delete, null);
  }

  addPackingDAL (body) {
    return super.execute(this.Urls.packing.detail, this.ApiMethod.post, body);
  }

  editPackingDAL (id, data) {
    return super.execute(this.Urls.packing.detail + '/' + id, this.ApiMethod.put, data);
  }

  notify (body) {
    return super.execute(this.Urls.packing.notify, this.ApiMethod.post, body);
  }

  search (options, search) {
      let op = {};
      if(search.key == 'createdAt'){
        let begin = moment(search.beginDate).format('YYYY-MM-DD');
        let end = moment(search.endDate).format('YYYY-MM-DD');
        let date = `${begin}:${end}`;
        op = {...options, [search.key] : date}
      } else op ={...options, [search.key] : search.value };
      return super.execute(this.Urls.packing.list , this.ApiMethod.get, op);
  }

  getPackingByOrder = (id, options) => {
    return super.execute(`${this.Urls.packing.packingByOrder}/${id}/packed-style-quantities` , this.ApiMethod.get, options);
  }

  getPackingByOrderAndContractor (oId, cId) {
    return super.execute(this.Urls.packing.packingByOrderAndContractor.replace('{oId}', oId).replace('{cId}', cId), this.ApiMethod.get);
  }

  getPacking = (id) => {
    return super.execute(this.Urls.packing.detail + '/' + id, this.ApiMethod.get);
  }

  stockPackingAPI (id) {
    return super.execute(this.Urls.packing.stock + '/' + id, this.ApiMethod.put);
  }

  reshipPackingAPI(id, data) {
    return super.execute(this.Urls.packing.reship + '/' + id, this.ApiMethod.put, data);
  }
}

export default new Packing();
