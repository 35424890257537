import React from "react";
import PropTypes from "prop-types";
import TableList from "../../../../components/common/datatables/TableList";
import moment from "moment";
import ReactExport from "react-data-export";
import * as utils from "../../../../functions/utils";
import formatCurrency from 'format-currency';
import _ from "lodash";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;


const PreviewContractor = props => {
    const { contractor, items, columns, currencyTypeUSD, currencyTypeVND } = props;
    let creditDebitAmount = 0;
    if (items && items.length > 0) {
        creditDebitAmount = _.get(items[0], 'creditDebitAmount', 0);
    }

    let labeContractor = 'Contractor :';
    let valuecontractor = {value: contractor || '', style: {font: {patternType: "solid", color: {rgb: "FFF86B00"}}}};

    let labelINVNo = {value: 'INV No : '};
    let totalINVNo = {
        value: (props.invoiceNo) || '',
        style: {font: {patternType: "solid", color: {rgb: "FFFF0000"}}}
    };
    let labelCreditDebit = 'Credit/ Debit : ';
    let totalCreditDebit = {
        value: `${currencyTypeUSD} ${formatCurrency(creditDebitAmount)} ${currencyTypeVND}`,
        style: {font: {patternType: "solid", color: {rgb: "FFFF0000"}}}
    };

    let labelAmountINV = 'Amount INV : ';
    let totalAmountINV = {
        value: `${currencyTypeUSD} ${formatCurrency(props.amount)} ${currencyTypeVND}`,
        style: {font: {patternType: "solid", color: {rgb: "FFFF0000"}}}
    };

    let labelTotalQTY = 'Total QTY : ';
    let totalTotalQTY = {
        value: props.totalQTY,
        style: {font: {patternType: "solid", color: {rgb: "FFFF0000"}}}
    };

    let labelIssuedDate = 'Issued Date : ';
    let totalIssuedDate = {
        value: moment(props.issuedDate).format("MMM DD, YYYY"),
        style: {font: {patternType: "solid", color: {rgb: "FFFF0000"}}}
    };

    let labelVATAmount = 'VAT Amount : ';
    let totalVATAmount = {
        value: `${currencyTypeUSD} ${formatCurrency(props.vatAmount)} ${currencyTypeVND}`,
        style: {font: {patternType: "solid", color: {rgb: "FFFF0000"}}}
    };

    let labelTotalAmountINV = 'Total Amount INV : ';
    let totalTotalAmountINV = {
        value: `${currencyTypeUSD} ${formatCurrency(props.totalAmount)} ${currencyTypeVND}`,
        style: {font: {patternType: "solid", color: {rgb: "FFFF0000"}}}
    };

    let header1 = [labeContractor, valuecontractor, labelINVNo, totalINVNo, labelCreditDebit, totalCreditDebit, labelAmountINV, totalAmountINV]
    let header2 = [labelTotalQTY, totalTotalQTY, labelIssuedDate, totalIssuedDate, labelVATAmount, totalVATAmount, labelTotalAmountINV, totalTotalAmountINV];
    let putData = [];

    _.map(items, (el) => {
        let keys = [
            {key: 'date', value: 'shipDate', color: '00000000'},
            {key: 'string', value: 'shipMode', color: '00000000'},
            {key: 'string', value: 'customerPO', color: '00000000'},
            {key: 'string', value: 'style', color: '00000000'},
            {key: 'number', value: 'cutTicket', color: '00000000'},
            {key: 'number', value: 'name', color: '00000000'},
            {key: 'number', value: 'qtyRec', color: '00000000'},
            {key: 'number', value: 'price', color: '00000000'},
            {key: 'number', value: 'amount', color: 'FFFF0000'}
        ];

        putData.push(utils.mapDataForCell(el, keys));
    });
    let headerTable = ['Ship Date', 'Ship Mode', 'Customer PO', 'Style', 'Cut Ticket',
        'Color', 'QTY REC', 'Price', 'Amount'];
    let dataHeaderTable = [];
    for(let i = 0; i < headerTable.length ; i++){
        dataHeaderTable.push({
            value: headerTable[i],
            style: utils.genStyleHeaderForCell('00000000')
        });
    }
    putData.unshift(dataHeaderTable);

    const multiDataSet = [
        {
            columns: [
                {title: '', width: {wch: 20}},//pixels width
                {title: '', width: {wch: 40}},//char width
                {title: '', width: {wch: 20}},
                {title: '', width: {wch: 40}},
                {title: '', width: {wch: 20}},//pixels width
                {title: '', width: {wch: 50}},//char width
            ],
            data: [header1]
        },
        {
            columns: [
                {title: '', width: {wch: 20}},//pixels width
                {title: '', width: {wch: 40}},//char width
                {title: '', width: {wch: 20}},
                {title: '', width: {wch: 40}},
                {title: '', width: {wch: 20}},//pixels width
                {title: '', width: {wch: 50}},//char width
            ],
            data: [header2]
        },
        {
            xSteps: 0, // Will start putting cell with 1 empty cell on left most
            ySteps: 0, //will put space of 5 rows,
            columns: [],
            data: putData
        }
    ];
    let nameFile = '';
    let d = new Date();
    if(props.invoiceNo){
        nameFile = 'INV_' + props.invoiceNo + '_' + (Number(d.getMonth()) + 1 < 10 ? "0" + (Number(d.getMonth()) + 1) : Number(d.getMonth()) + 1)  + '_' + d.getFullYear();
    }else{
        nameFile = 'INV_' +  (Number(d.getMonth()) + 1 < 10 ? "0" + (Number(d.getMonth()) + 1) : Number(d.getMonth()) + 1)  + '_' + d.getFullYear();
    }


    return (
        <div>
            <div className="mg-left-15 mg-right-15">
                <div className="row ">
                    <ul className="content-header-title float-none pd-0-5 justify-content-space-between display-flex">
                        <li>
                            <p>Contractor
                                <span className="color-orange medium mg-left-25 size-16">{contractor}</span>
                            </p>
                        </li>
                        <li>
                            <p>INV No
                                <span className="color-number medium mg-left-25 size-16">{(props.invoiceNo)}</span>
                            </p>
                        </li>
                        <li>
                            <p>Credit/ Debit
                                <span className="color-number medium mg-left-25 size-16">{currencyTypeUSD}{formatCurrency(creditDebitAmount)} {currencyTypeVND}</span>
                            </p>
                        </li>
                        <li>
                            <p>Amount INV
                                <span className="color-number medium mg-left-25 size-16">{currencyTypeUSD}{formatCurrency(props.amount)} {currencyTypeVND}</span>
                            </p>
                        </li>
                    </ul>
                </div>

                <div className="row">
                    <ul className="content-header-title float-none pd-0-5 justify-content-space-between display-flex">
                        <li>
                            <p>Total QTY
                                <span className="color-number medium mg-left-25 size-16">{(props.totalQTY)}</span>
                            </p>
                        </li>
                        <li>
                            <p>Issued Date
                                <span className="color-number medium mg-left-25 size-16">{moment(props.issuedDate).format("MMM DD, YYYY")}</span>
                            </p>
                        </li>
                        <li>
                            <p>VAT Amount
                                <span className="color-number medium mg-left-25 size-16">{currencyTypeUSD}{formatCurrency(props.vatAmount)} {currencyTypeVND}</span>
                            </p>
                        </li>
                        <li>
                            <p>Total Amount INV
                                <span className="color-number medium mg-left-25 size-16">{currencyTypeUSD}{formatCurrency(props.totalAmount)} {currencyTypeVND}</span>
                            </p>
                        </li>
                        <li className="add-filter-top">
                            <ExcelFile filename={nameFile}
                                       element={
                                           <span className="cursor-pointer">
                                <i className="fa fa-floppy-o" aria-hidden="true"></i>{"Export"}
                            </span>
                                       }>
                                <ExcelSheet dataSet={multiDataSet}  name={nameFile}/>
                            </ExcelFile>

                        </li>
                    </ul>
                </div>
            </div>
            <div className="pd-5">
                <TableList items={items || []} columns={columns || []} options={{ clsTbl: 'global-table' }} />
            </div>
        </div>
    )
}
PreviewContractor.propTypes = {
    items: PropTypes.array,
    columns: PropTypes.array,
    listDeclaration: PropTypes.array,
    name: PropTypes.string,
    totalAmount: PropTypes.number,
    totalAdvance: PropTypes.number,
    totalRemaining: PropTypes.number
}

PreviewContractor.defaultProps = {

}
export default PreviewContractor