import {WAREHOUSE} from '../constants/actionTypes';
import initial from './initialState';


export default function (state = initial.warehouseState, action) {
  switch (action.type) {
    case WAREHOUSE.GET_LIST_SUCCESS: {
      return {...state, items: Object.assign([], [...(action.data.items || [])]), count: action.data.count || 0};
    }
    case WAREHOUSE.GET_LIST_RESERVE: {
      return {...state, reserves: action.data.items || [], count: action.data.count || 0};
    }
    default:
      return state;
  }
}
