import {SAMPLE_APPROVAL} from '../constants/actionTypes';
import initial from './initialState';

export default function (state = initial.sampleApprovalState, action) {
  switch (action.type) {
    case SAMPLE_APPROVAL.RELOAD:
      return {...state, reload: !state.reload};
    case SAMPLE_APPROVAL.GET_DETAIL_SUCCESS:
      return {...state, detail: action.data};
    case SAMPLE_APPROVAL.RESET_DETAIL:
      return {...state, detail: {}};
    case SAMPLE_APPROVAL.UPDATE_STATUS:
      return {...state, item: action.item};
    case SAMPLE_APPROVAL.GET_LIST:
      return {...state, items: action.data.items, total: action.data.total}
    default:
      return state;
  }
}
