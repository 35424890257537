import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import customerActions from '../../../actions/customerActions';
import TextInputValidate from '../../common/fields/TextInputValidate';
import I18n from 'i18n-js';
import { isStringEmpty, validateEmail } from '../../../functions/helpers';
import ReactTelephoneInput from 'react-telephone-input/lib/withStyles'
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import { PaymentTermsOfCustomer, Currencies } from '../../../constants/constants';
import SelectValidate from '../../common/fields/SelectValidate';

import flagsIcon from '../../../stylesheets/images/flags.png';
import icAddIcon from '../../../stylesheets/images/ic-add.svg';
import icXIcon from '../../../stylesheets/images/ic-x.svg';

class CustomerEdit extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      customerDetail: [],
      errors: {},
      phoneNumber: {},
      country: '',
    }
    this.setUser = this.setUser.bind(this)
  }

  componentWillMount() {
    const data = _.get(this.props, 'customerDetail', {});
    if (data && Object.keys(data).length === 0) {
      data.warehouses = [];
      data.warehouses.push({});
      
    }
    if(!_.isEmpty(data)) {
      this.setState({ country: data.country })
    }
    this.setState({ customerDetail: _.cloneDeep(data) });
  }

  onChange = (property, value) => {
    this.setState(state => {
      return { customerDetail: _.set(state.customerDetail, property, value)}
    });
    this.validateBeforeChange(property, value);
  }

  close = () => {
    this.props.closeModal();
  }

  handleRemoveWareHouse = (index) => {
    let { customerDetail } = this.state;
    if (customerDetail.warehouses && index >= 0 && index < customerDetail.warehouses.length) {
      customerDetail.warehouses.splice(index, 1);
      this.setState({ customerDetail });
    }
  }

  setUser(field, value) {
    var customerDetail = this.state.customerDetail
    customerDetail[field] = value
    var errors = this.state.errors
    errors[field] = null
    this.setState({ customerDetail: customerDetail, errors })
  }

  handleAddWareHouse = () => {
    let { customerDetail } = this.state;
    if (!customerDetail.warehouses)
      customerDetail.warehouses = [];

    customerDetail.warehouses.push({});
    this.setState({ customerDetail });
  }

  onWarehouseChange = (index, property, value) => {
    let { customerDetail } = this.state;

    if (index >= 0 && index < customerDetail.warehouses.length) {
      customerDetail.warehouses[index][property] = value;
      this.setState({ customerDetail });
    }
  }

  validateBeforeChange = (property, value) => {
    let { errors } = this.state;
    errors = {};

    switch(property) {
      case 'name': {
        if (isStringEmpty(value)) {
          errors['name'] = I18n.t('ErrorMessages.CustomerNameIsRequired');
        } else if ((value || '').length > 30) {
          errors['name'] = I18n.t('ErrorMessages.CustomerNameTooLong');
        }
        if(_.isEmpty(value)) errors['name'] = '';
        this.setState({ errors });
        break;
      }
      case 'shortName': {
        if (isStringEmpty(value)) {
          errors['shortName'] = I18n.t('ErrorMessages.CustomerShortNameIsRequired');
        } else if((value || '').length > 10) {
          errors['shortName'] = I18n.t('ErrorMessages.CustomerShortNameTooLong');
        }
        if(_.isEmpty(value)) errors['shortName'] = '';
        this.setState({ errors });
        break;
      }
      case 'paymentTerm': {
        if (isStringEmpty(value)) {
          errors['paymentTerm'] = I18n.t('ErrorMessages.CustomerPaymentTermIsRequired');
        }
        if(_.isEmpty(value)) errors['paymentTerm'] = '';
        this.setState({ errors });
        break;
      }
      case 'phoneNumber': {
        if (isStringEmpty(value)) {
          errors['phoneNumber'] = I18n.t('ErrorMessages.CustomerPhoneNumberIsRequired');
        }
        if(_.isEmpty(value)) errors['phoneNumber'] = '';
        this.setState({ errors });
        break;
      }
      case 'currency': {
        if (isStringEmpty(value)) {
          errors['currency'] = I18n.t('ErrorMessages.CustomerCurrencyIsRequired');
        }
        if(_.isEmpty(value)) errors['currency'] = '';
        this.setState({ errors });
        break;
      }
      case 'email': {
        if ((value || '').length == 0) {
            errors['email'] = I18n.t('ErrorMessages.CustomerEmailIsRequired');
            break;
        }
        if (!validateEmail(value)) {
            errors['email'] = I18n.t('ErrorMessages.CustomerEmailWrongFormat');
            break;
        }
        if (_.isEmpty(value)) errors['email'] = '';
        this.setState({errors});
        break;
      }
      case 'warehouses': {
        if (!value || (value || '').length < 1) {
          errors['warehouse'] = I18n.t('ErrorMessages.CustomerWareHouseIsRequired');
        }
        let invalidWareHouseIndex = (value || []).findIndex(item => isStringEmpty(item.name));
        if (invalidWareHouseIndex >= 0) {
          errors['warehouseName' + invalidWareHouseIndex] = I18n.t('ErrorMessages.NameIsRequired');
        }
        if(_.isEmpty(value)) errors['warehouseName'] = '';
        this.setState({ errors });
        break;
      }
      default:
    }
  }

  validateBeforeSave = () => {
    let { customerDetail, errors } = this.state;
    errors = {};
    if (!customerDetail) {
      errors['name'] = I18n.t('ErrorMessages.CustomerInfoIsRequired');
      this.setState({ errors });
      return false;
    }

    if (isStringEmpty(customerDetail.name)) {
      errors['name'] = I18n.t('ErrorMessages.CustomerNameIsRequired');
      this.setState({ errors });
      return false;
    } else if(_.get(customerDetail, 'name', '').length > 30){
      errors['name'] = I18n.t('ErrorMessages.CustomerNameTooLong');
      this.setState({ errors });
      return false;
    }

    if (isStringEmpty(customerDetail.shortName)) {
      errors['shortName'] = I18n.t('ErrorMessages.CustomerShortNameIsRequired');
      this.setState({ errors });
      return false;
    } else if(_.get(customerDetail, 'shortName', '').length > 10) {
      errors['shortName'] = I18n.t('ErrorMessages.CustomerShortNameTooLong');
      this.setState({ errors });
      return false;
    }

    if (isStringEmpty(customerDetail.paymentTerm)) {
      errors['paymentTerm'] = I18n.t('ErrorMessages.CustomerPaymentTermIsRequired');
      this.setState({ errors });
      return false;
    }

    if (isStringEmpty(customerDetail.phoneNumber)) {
      errors['phoneNumber'] = I18n.t('ErrorMessages.CustomerPhoneNumberIsRequired');
      this.setState({ errors });
      return false;
    }
    
    if (isStringEmpty(customerDetail.currency)) {
      errors['currency'] = I18n.t('ErrorMessages.CustomerCurrencyIsRequired');
      this.setState({ errors });
      return false;
    }

    // if (isStringEmpty(customerDetail.email)) {
    //   errors['email'] = I18n.t('ErrorMessages.CustomerEmailIsRequired');
    //   this.setState({ errors });
    //   return false;
    // }
    if ((customerDetail.email || '').length == 0) {
        errors['email'] = I18n.t('ErrorMessages.CustomerEmailIsRequired');
        this.setState({ errors });
        return false;
    }

    if (!validateEmail(customerDetail.email)) {
      errors['email'] = I18n.t('ErrorMessages.CustomerEmailWrongFormat');
      this.setState({ errors });
      return false;
    }

    if (!customerDetail.warehouses || customerDetail.warehouses.length < 1) {
      errors['warehouse'] = I18n.t('ErrorMessages.CustomerWareHouseIsRequired');
      this.setState({ errors });
      return false;
    }

    let invalidWareHouseIndex = customerDetail.warehouses.findIndex(item => isStringEmpty(item.name));
    if (invalidWareHouseIndex >= 0) {
      errors['warehouseName' + invalidWareHouseIndex] = I18n.t('ErrorMessages.NameIsRequired');
      this.setState({ errors });
      return false;
    }

    this.setState({ errors });
    return true;
  }

  selectCountry(property, val) {
    let { customerDetail } = this.state;
    this.setState({ customerDetail: _.set(customerDetail, property, val) });
    this.setState({ country: val });
  }

  save = () => {
    if (!this.validateBeforeSave())
      return;

    let data = this.state.customerDetail;
    if (data._id) {
      this.props.customerPropsActions.editCustomer(this.state.customerDetail._id, this.state.customerDetail);
    } else {
      this.props.customerPropsActions.addCustomer(this.state.customerDetail);
    }
  }

  render() {
    let { customerDetail, errors, country, region } = this.state;
    return (
      <div className='modal-dialog lg' id="modal-popup-customer">
        <div className='modal-content'>
          <form>
            <div className='modal-header'>
              <a className='close' onClick={() => this.close()}><span
                aria-hidden='true'>&times;</span></a>
              <h4 className='modal-title'>{I18n.t('PageCrm.ADDCUSTOMER')}</h4>
            </div>

            <div className='modal-body pd-0 inline-block'>
              <h4 className="title">{I18n.t('PageCrm.INFORMATION')}</h4>
              <TextInputValidate value={customerDetail.name || ''}
                label={I18n.t('PageCrm.Name')}
                isRequired = {true}
                property={'name'}
                placeholder={I18n.t('PageCrm.Name')}
                error={errors['name']}
                onChange={this.onChange} />

              <div className="col-md-6 col-xs-12">
                <div className="form-group">
                  <div className="col-md-4 col-xs-12 text no-padding-left">
                    <p>{I18n.t('PageCrm.PhoneNumber')}</p> <p className="color-red"> *</p>
                  </div>
                  <div className="col-md-8 col-xs-12 value no-padding-right">
                    <ReactTelephoneInput className='w-full'
                      defaultCountry='us'
                      autoFormat={false}
                      flagsImagePath={flagsIcon}
                      initialValue={(customerDetail.phoneNumber ? customerDetail.phoneNumber : '')}
                      onChange={(phoneNumber) => this.setUser('phoneNumber', phoneNumber)}
                    />
                    {errors['phoneNumber'] && <div className="alert-warning">{errors['phoneNumber']}</div>}
                  </div>
                </div>
              </div>

              <TextInputValidate value={customerDetail.shortName || ''}
                label={I18n.t('PageCrm.ShortName')}
                property={'shortName'}
                isRequired = {true}
                placeholder={I18n.t('PageCrm.ShortName')}
                error={errors['shortName']}
                onChange={this.onChange} />

              <TextInputValidate value={customerDetail.fax || ''}
                label={I18n.t('PageCrm.Fax')}
                property={'fax'}
                placeholder={I18n.t('PageCrm.Fax')}
                error={errors['fax']}
                onChange={this.onChange} />

              <TextInputValidate value={(customerDetail.company && customerDetail.company.name) || ''}
                label={I18n.t('PageCrm.CompanyName')}
                property={'company.name'}
                placeholder={I18n.t('PageCrm.CompanyName')}
                error={errors['companyName']}
                onChange={this.onChange} />

              {/* <TextInputValidate value={(customerDetail.country) || ''}
                label={I18n.t('PageCrm.Country')}
                property={'country'}
                placeholder={I18n.t('PageCrm.Country')}
                error={errors['country']}
                onChange={this.onChange} /> */}

              <div className="col-md-6 col-xs-12">
                <div className="form-group">
                  <div className="col-md-4 col-xs-12 text no-padding-left">
                    <p>{I18n.t('PageCrm.Country')}</p>
                  </div>
                  <div className="col-md-8 col-xs-12 value no-padding-right value">
                    <CountryDropdown
                      value={country}
                      classes={"form-control"}
                      onChange={(val) => this.selectCountry('country', val)} />
                  </div>
                </div>
              </div>

              <TextInputValidate value={(customerDetail.company && customerDetail.company.address) || ''}
                label={I18n.t('PageCrm.CompanyAddress')}
                property={'company.address'}
                placeholder={I18n.t('PageCrm.CompanyAddress')}
                error={errors['companyAddress']}
                onChange={this.onChange} />

              <SelectValidate options={Currencies}
                value={customerDetail.currency || ''}
                label={I18n.t('PageCrm.Currency')}
                property={'currency'}
                isRequired = {true}
                placeholder={I18n.t('PageCrm.Currency')}
                error={errors['currency']}
                onChange={this.onChange} />

              <SelectValidate options={PaymentTermsOfCustomer}
                value={customerDetail.paymentTerm || ''}
                label={I18n.t('PageCrm.PaymentTerm')}
                property={'paymentTerm'}
                isRequired = {true}
                placeholder={I18n.t('PageCrm.PaymentTerm')}
                error={errors['paymentTerm']}
                onChange={this.onChange} />

              <TextInputValidate value={(customerDetail.email) || ''}
                label={I18n.t('PageCrm.Email')}
                property={'email'}
                isRequired = {true}
                placeholder={I18n.t('PageCrm.Email')}
                error={errors['email']}
                onChange={this.onChange} />

              <TextInputValidate value={(customerDetail.bankAccountNo) || ''}
                label={I18n.t('PageCrm.BankAccountNo')}
                property={'bankAccountNo'}
                placeholder={I18n.t('PageCrm.BankAccountNo')}
                error={errors['bankAccountNo']}
                onChange={this.onChange} />

              <h4 className="title mg-top-15 relative add-icon">
                {I18n.t('PageCrm.WAREHOUSE')}
                <span style={{ float: 'right' }}>
                  <span className="cursor-pointer"
                    onClick={evt => this.handleAddWareHouse()}>
                    {<img src={icAddIcon} alt="" />}
                  </span>
                </span>
              </h4>
              {errors['warehouse'] && <div className="alert-warning" style={{ left: 15 }}>{errors['warehouse']}</div>}
              {customerDetail.warehouses && customerDetail.warehouses.map((warehouse, index) => (
                <div key={index}>
                  <div className="col-md-5 col-xs-12 box">
                    <div className='form-group'>
                      <div className='col-md-4 col-xs-12 text'>
                        <p>{I18n.t('PageCrm.Name')}</p> <p className="color-red"> *</p>
                      </div>
                      <div className='col-md-8 col-xs-12 value'>
                        <input type="text" className="form-control"
                          value={warehouse.name || ''}
                          onChange={evt => this.onWarehouseChange(index, 'name', evt.target.value)}
                          placeholder={I18n.t('PageCrm.Name')} />
                        {errors['warehouseName' + index] && <div className="alert-warning">{errors['warehouseName' + index]}</div>}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-5 col-xs-12 box">
                    <div className='form-group'>
                      <div className='col-md-4 col-xs-12 text'>
                        <p>{I18n.t('PageCrm.Address')}</p>
                      </div>
                      <div className='col-md-8 col-xs-12 value'>
                        <input type="text" className="form-control"
                          value={warehouse.address || ''}
                          onChange={evt => this.onWarehouseChange(index, 'address', evt.target.value)}
                          placeholder={I18n.t('PageCrm.Address')} />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2 col-xs-12 box">
                    <div className='form-group'>
                      <div className='col-md-12 text icon-delete right'>
                        <span className="cursor-pointer" onClick={evt => this.handleRemoveWareHouse(index)}>
                          <img src={icXIcon} alt="" />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className='modal-footer'>
              <button type='button' className='bt-black' onClick={() => this.close()}>{I18n.t('Btn.CANCEL')}</button>
              <button type='button' className='bt-orange mg-l-5'
                onClick={() => this.save()}>{I18n.t('Btn.SAVE')}</button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    appState: state.appState,
    authState: state.authState,
    customerState: state.customerState
  };
}

function mapDispatchToProps(dispatch) {
  return {
    customerPropsActions: bindActionCreators(customerActions, dispatch)
  };
}

CustomerEdit.propTypes = {
  authState: PropTypes.object.isRequired,
  customerDetail: PropTypes.object
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerEdit);
