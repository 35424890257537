import React, { Component } from 'react';
import PreviewSupplierOverseaView from '../../../components/accounting/payable/PreviewSupplierOversea';
import I18n from 'i18n-js';
import { connect } from 'react-redux';
import * as selector from '../../../../selectors/accounting/payable/historyPreviewSupplierOversea';
import * as actions from '../../../../actions/accounting/payable/historyPreviewSupplierOversea';
import moment from 'moment';
import {withRouter} from "react-router";
class PreviewSupplierOversea extends Component {

    constructor (props) {
        super(props)
        this.state = {
            listIds: this.props.listIds,
            items: [],
            pageNumber: 1,
            pageSize: 10,
            columns: [
                { name: 'Purchase Order No.', value: 'purchaseNo', type: 'text' },
                { name: 'Material', value: 'material', type: 'text' },
                { name: 'Color', value: 'color', type: 'text' },
                { name: 'Payer', value: 'payer', type: 'text' },
                { name: 'ETD', value: 'eta', type: 'date', formatType: 'MMM DD, YYYY'  },
                { name: 'Received Quantity', value: 'receiveQuantity', type: 'number' },
                { name: 'INV No', value: 'invNo', type: 'text' },
                { name: 'Price', value: 'priceText', type: 'text' },
                { name: 'Amount', value: 'amount', type: 'text' },
            ],
            actions: []
        }
    }
    
    componentDidMount() {
        // this.props.resetData()
    }

    componentWillReceiveProps(nextProps) {
        const {activeScreen} = nextProps;
        if(!activeScreen) {
            nextProps.router.history.goBack();
        }
    }

    render() {
        const { columns } = this.state;
        return (
            <PreviewSupplierOverseaView columns = {columns} {...this.props} type = "historyPreview"/>
        );
    }
}

const mapStateToProps = state => ({
    invoiceDetail: selector.getInvoiceDetail(state),
    items: selector.getListItems(state),
    amount: selector.getAmount(state),
    invoiceNo: selector.getInvoiceNo(state),
    issuedDate: selector.getIssuedDate(state),
    supplier: selector.getSupplier(state),
    // amountINV: selector.getAmount(state),
    paid: selector.getPaid(state),
    activeScreen: selector.getActiveScreenStatus(state),
    currencyTypeUSD: selector.getCurrencyTypeUSD(state),
    currencyTypeVND: selector.getCurrencyTypeVND(state),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    // resetData() {
    //     dispatch(actions.resetData())
    // }
})

export default connect(mapStateToProps, mapDispatchToProps) (withRouter(PreviewSupplierOversea));