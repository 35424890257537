import React, { Component } from 'react';
import SRC from '../../../../constants/assets';
class SideBar extends Component {
  constructor(props) {
    super(props);
  }

  // changeChecked = (item) => {
  //     //console.log(item)
  //     this.props.changeChecked(item)
  // }
  render() {
    const { items, searchText, placeholder } = this.props;
    return (
      <div className="content-sidebar top-initial left-0">
        <div className="md content-sidebar-search">
          <input
            type="text"
            placeholder={placeholder || 'Search'}
            value={searchText}
            onChange={e => this.props.changeSearchText(e.target.value)}
          />
          <span>
            <i className="fa fa-search" aria-hidden="true" />
          </span>
        </div>
        <div className="md content-sidebar-menu">
          <ul className="overflow-y-scroll h-full">
            {items.map((item, index) => {
              return (
                <li key={index} className="style-checkbox">
                  <input
                    type="checkbox"
                    checked={item.checked}
                    id={item._id}
                    onChange={() => this.props.changeChecked(_.assign(_.cloneDeep(item), { checked: !item.checked }))}
                  />
                  <label htmlFor={item._id}>
                    <span className="mg-right-10">
                      <span />
                    </span>
                  </label>
                  <span>{item.name}</span>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    );
  }
}

export default SideBar;
