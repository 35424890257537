import React, { Component } from 'react';
import SRC from '../../../../../constants/assets';
import moment from 'moment';
const formatCurrency = require('format-currency');

class ContainerDetail extends Component {
    constructor(props) {
        super(props);
        
    }
    
    render() {
        const {data} = this.props;
        return (
            <div>
                <ul className="bg-white content-header-title pd-left-15 bd-solid-bottom-3">
                    <li>
                        <p className="bold text-transform">{data.cont}{data.contType ? ` - ${data.contType}` : ''}</p>
                    </li>
                    <li className="float-right">
                        <a onClick = {() => this.props.changeContainerDetail(data._id)}><img src={SRC.IC_EDIT_X} className="mg-right-10" /></a>
                    </li>
                </ul>
                <div className="colum-2 layout plan-container mg-0 bd-solid-bottom-3">
                    <div className="container-colum">
                        <div className="container-colum-2">
                            <div className="top bd-dashed-bottom inline-block w-full">

                                <div className="col-md-6 col-xs-12 mg-bottom-15">
                                    <p>
                                        <span className="text">Cost</span> <span className="color-number bold size-14 value">{formatCurrency(data.cost)}</span>
                                    </p>
                                </div>
                                <div className="col-md-6 col-xs-12 mg-bottom-15">
                                    <p>
                                        <span className="text">Charges Incurred</span> <span className="bold value">{formatCurrency(data.chargesIncurred)}</span>
                                    </p>
                                </div>
                                <div className="col-md-6 col-xs-12 mg-bottom-5">
                                    <p>
                                        <span className="text">Lift On Fee</span> <span className="color-green bold value">{formatCurrency(data.liftOnFee)}</span>
                                    </p>
                                </div>
                                <div className="col-md-6 col-xs-12 mg-bottom-5">
                                    <p>
                                        <span className="text">Lift On INV</span> <span className="bold value">{data.liftOnInv}</span>
                                    </p>
                                </div>
                                <div className="col-md-6 col-xs-12 mg-bottom-5">
                                    <p>
                                        <span className="text">Lift Off Fee</span> <span className="color-green bold value">{formatCurrency(data.liftOffFee)}</span>
                                    </p>
                                </div>
                                <div className="col-md-6 col-xs-12 mg-bottom-5">
                                    <p>
                                        <span className="text">Lift Off INV</span> <span className="bold value">{data.liftOffInv}</span>
                                    </p>
                                </div>

                            </div>
                            <div className="bottom">
                                <p>
                                    <span className="text w-auto mg-right-15">Note</span> <span className="bold value">{data.note}</span>
                                </p>
                            </div>
                        </div>
                        <div className="container-colum-2 position-relative top-30">
                            <div className="wrap">

                                <div className="col-md-6 col-xs-12">
                                    <p>
                                        <span className="text">Quantity</span> <span className="bold value">{data.qty ? data.qty: data.qtyPkg}</span>
                                    </p>
                                </div>
                                <div className="col-md-6 col-xs-12">
                                    <p>
                                        <span className="text">Warehouse Addr</span> <span className="bold text-transform value">{data.warehouseAddress}</span>
                                    </p>
                                </div>
                                <div className="col-md-6 col-xs-12">
                                    <p>
                                        <span className="text">Type</span> <span className="bold value">{data.type}</span>
                                    </p>
                                </div>
                                <div className="col-md-6 col-xs-12">
                                    <p>
                                        <span className="text">Truck No</span> <span className="bold">{data.truckNo}</span>
                                    </p>
                                </div>
                                <div className="col-md-6 col-xs-12">
                                    <p>
                                        <span className="text">Route</span> <span className="bold value">{data.route}</span>
                                    </p>
                                </div>
                                <div className="col-md-6 col-xs-12">
                                    {data.pickupCont ?
                                        <p>
                                            <span className="text">Pickup Cont</span> <span className="bold value">{data.pickupCont}</span>
                                        </p>
                                        :
                                        <p>
                                            <span className="text">Pickup Cont</span> <span className="bold value">{data.pickUpCont}</span>
                                        </p>}

                                </div>
                                <div className="col-md-6 col-xs-12">
                                    <p>
                                        <span className="text">Date</span> <span className="bold value">{moment(data.date).format('MMM DD, YYYY')}</span>
                                    </p>
                                </div>
                                <div className="col-md-6 col-xs-12">
                                    <p>
                                        <span className="text">Drop Off Cont</span> <span className="bold value">{data.dropOffCont}</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="bg-line-colum" />
                    </div>
                </div>
            </div>
        );
    }
}

export default ContainerDetail;