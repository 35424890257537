import Base from './base';

class StockIn extends Base {
  constructor () {
    super();

    this.getListItemDAL = this.getListItemDAL.bind(this);
    this.addItemDAL = this.addItemDAL.bind(this);
    this.editItemDAL = this.editItemDAL.bind(this);
    this.getItemDAL = this.getItemDAL.bind(this);
    this.deleteItemDAL = this.deleteItemDAL.bind(this);
  }

  getListItemDAL (type, query) {
    return super.execute(this.Urls.stockIn[type].list, this.ApiMethod.get, query);
  }

  addItemDAL (type, body) {
    return super.execute(this.Urls.stockIn[type].create, this.ApiMethod.post, body);
  }

  editItemDAL (type, id, body) {
    return super.execute(this.Urls.stockIn[type].update + '/' + id, this.ApiMethod.put, body);
  }

  getItemDAL (type, id, options) {
    return super.execute(this.Urls.stockIn[type].detail + '/' + id, this.ApiMethod.get, options);
  }

  deleteItemDAL (type, id, body) {
    return super.execute(this.Urls.stockIn[type].delete + '/' + id, this.ApiMethod.delete, body);
  }
}

export default new StockIn();
