import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import BaseComponent from '../base/BaseComponent';
import PanelCutTicket from './PanelCutTicket';
import styleActions from '../../actions/styleActions';
import yieldActions from '../../actions/yieldActions';
import _ from 'lodash';
import I18n from 'i18n-js';
var FileSaver = require('file-saver');

import icExportIcon from '../../stylesheets/images/ic-export.svg';
import icVIcon from '../../stylesheets/images/ic-v-w.svg';

class PanelYieldContent extends BaseComponent {
  constructor(props, context) {
    super(props, context);

    this.state = {
      items: [],
      selected: {},
      cutTickets: []
    };
  }

  componentWillReceiveProps(newProps) {
    let {items} = this.state;
    let data = _.get(newProps, 'order.styleOrders', []);
    !_.isEqual(items, _.get(newProps, 'order.styleOrders', [])) && this.setState({
      items: _.cloneDeep(data),
      selected: data.length ? data[0] : '',
      cutTickets: []
    });
  }

  btGetExportBom() {
      let {selected} = this.state;
      let url = this.props.getExportBom(selected._id);
      FileSaver.saveAs(url, "Report_ShipOut_Bom.xlsx");
  }

  btGetExportYield() {
      let {selected} = this.state;
      let url = this.props.getExportYield(selected._id);
      FileSaver.saveAs(url, "Report_ShipOut_Yield.xlsx");
  }

  onChange(styleId) {
    let {items, selected} = this.state;
    let itemSelect = items.find(i => i._id === styleId) || {};

    !_.isEqual(selected, itemSelect) && this.props.selectStyle(_.cloneDeep(itemSelect));
    this.setState({selected: itemSelect, cutTickets: []});
  }

  getCutTickets(items) {
    this.setState({cutTickets: items || []});
  }

  render() {
    const {order} = this.props;
    let {items, selected, cutTickets} = this.state;
    let chunks = _.chunk(cutTickets, 3);
    return (
      <div>
        <div className="md content-main-process mg-top-10 bg-white">
          <div className="content-main-header">
            <ul className="content-main-header-filter-style mg-top-15">
              <li className="title">{I18n.t('PageYield.Style')}</li>
              <li className="select">
                <select className="form-control" value={selected._id || ''}
                        onChange={evt => this.onChange(evt.target.value)}>
                  {/* <option value=""/> */}
                  {items.map((i, idx) => (<option key={idx} value={i._id}>{i.style}</option>))}
                </select>
              </li>
            </ul>
            <ul className="list-export">
              <li className="cursor-pointer" onClick={() => this.btGetExportYield()}>
                {I18n.t('PageYield.ExportYield')}
                <img src={icExportIcon} alt=""/>
              </li>
              <li className="cursor-pointer" onClick={() => this.btGetExportBom()}>
                {I18n.t('PageYield.ExportBOM')}
                <img src={icExportIcon} alt=""/>
              </li>
            </ul>
          </div>
          <div className="process bd-dashed-top center">
            {
              chunks.map((c, index) => {
                return (
                  <div key={index}>
                  <ul>
                    {
                      (c || [])
                        .reduce((acc, i, idx) => {
                          if (idx > 0) acc.push('###');
                          acc.push(i);
                          return acc;
                        }, [])
                        .map((i, idx) => {
                          return i === '###' ? (
                            <li key={idx} className="line">
                              <span/>
                            </li>
                          ) : (
                            <li key={idx}>
                              <div className="text">CT {i.cutTicket}</div>
                              <div className={i.yield && !_.isEmpty(i.yield) ? 'active background border bd-none' : 'border bd-none'}>
                                <span><img src={icVIcon} alt=""/></span></div>
                            </li>
                          );
                        })
                    }
                  </ul>
                  <br/>
                  </div>
                )
              })
            }
          </div>
        </div>

        <PanelCutTicket
          {...this.props}
          order={order || {}} style={selected || {}}
          showCreate={this.props.showCreate}
          showEdit={this.props.showEdit}
          showDelete={this.props.showDelete}
          isSuperAdmin={this.props.isSuperAdmin}
          getCutTickets={(items) => this.getCutTickets(items)}/>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    authState: state.authState,
    styleState: state.styleState
  };
}

function mapDispatchToProps(dispatch) {
  return {
    stylePropsActions: bindActionCreators(styleActions, dispatch),
      getExportBom(styleId) {
          return yieldActions.getExportBom(styleId)
      },
      getExportYield(orderId) {
          return yieldActions.getExportYield(orderId)
      }

  };
}

PanelYieldContent.propTypes = {
  authState: PropTypes.object.isRequired,
  showCreate : PropTypes.bool,
  showEdit : PropTypes.bool,
  showDelete : PropTypes.bool,
  isSuperAdmin: PropTypes.bool
};

export default connect(mapStateToProps, mapDispatchToProps)(PanelYieldContent);
