import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BaseComponent from '../../base/BaseComponent';
import appActions from '../../../actions/appActions';
import stockInActions from '../../../actions/stockInActions';
const DateTimeField = require('react-datetime');
import _ from 'lodash';
import moment from 'moment';
import I18n from 'i18n-js';
import {validateActualAndUsableOfMaterial} from "../../../functions/helpers";
import {toastr} from "react-redux-toastr";
import ModalConfirm from "../../common/modals/ModalConfirm";
import * as permissions from "../../../selectors/permissions";

import { withRouter } from 'react-router-dom';
import * as PATH from '../../../constants/routeConstants';

import icXIcon from '../../../stylesheets/images/ic-x.svg';
import * as utils from "../../../functions/utils";

class StockInSupplierEdit extends BaseComponent {
  constructor (props, context) {
    super(props, context);

    this.state = {
      item: {},
      materials: [],
      save: true,
      isCanUpdate: false,
    };
  }

  componentDidMount () {
    let stockIn = _.get(this.props.stockInState, 'supplier.detail', {});
    this.setState({
      item: _.cloneDeep(stockIn),
      saved: !stockIn.isReadyToPay,
      materials: _.cloneDeep(stockIn.materials || []),
      isCanUpdate: !stockIn.isReadyToPay
    });
    stockIn._id && this.props.stockInPropsActions.getDetail('supplier', stockIn._id, {populate: 'order,style,purchase,materials.material'});
  }

  componentWillReceiveProps (newProps) {
    // let {item} = this.state;
    let stockIn = _.get(newProps.stockInState, 'supplier.detail', {});
    if(_.isEmpty(stockIn)) {
      this.props.history.push(PATH.WAREHOUSE_INBOUND_SUPPLIER_URL)
    }
    const oldStockIn = _.get(this.props.stockInState, 'supplier.detail', {});
    if (stockIn !== oldStockIn) {
      this.setState({
        item: _.cloneDeep(stockIn),
        saved: !stockIn.isReadyToPay,
        materials: _.cloneDeep(stockIn.materials || []),
        isCanUpdate: !stockIn.isReadyToPay
      });
    }
    // let isCanUpdate = !item.isReadyToPay;
    // this.setState({item, isCanUpdate});
  }

  componentWillUpdate (nextProps) {
    if (_.get(this.props.stockInState, 'supplier.reload') != _.get(nextProps.stockInState, 'supplier.reload')) {
      this.props.appPropsActions.setDataPageCurrent({context: {}, isEdit: false});
    }
  }

  onChangeMaterials (id, property, value) {
    let {materials} = this.state;
    materials.forEach(m => {
      if (m._id === id) {
        switch (property) {
          case 'actual':
          case 'usable': {
            m[property] = value;
            m['unusable'] = (m.actual - m.usable) ? (m.actual - m.usable) : 0;
            break;
          }
          default:
            m[property] = value;
        }
      }
    });
    this.setState({materials});
  }

  onChangeStatus (check) {
    let {item} = this.state;
    item.status = check ? 'Approved' :'Unreserved'
    this.setState({item});
  }

  deleteMaterial (id) {
    let {materials} = this.state;
    materials = materials.filter(i => i._id != id);
    this.setState({materials});
  }

  save (materials) {
    let {item} = this.state;
    let data = _.map(_.cloneDeep(materials), m => _.assign(m, {material: _.has(m, "material._id") ? m.material._id : m.material}));

    const error = validateActualAndUsableOfMaterial(data || []);
    if(error) {
      toastr.info(I18n.t('Notify.Info'), error.msg);
      return;
    }

    const forMail = {
      purchaseType: _.get(item, 'purchase.type', ''), 
      supplierId: _.get(item, 'purchase.supplierId', ''),  
      customerPO: _.get(item, 'order.customerPO', ''), 
      style: _.get(item, 'style.style', ''),
      purchaseNo: _.get(item, 'purchase.purchaseNo', '')
    }
    item._id && this.props.stockInPropsActions.editItem('supplier', item._id, {...item, ...forMail, materials: data});
    this.props.history.push('/warehouse/inbound/suppiler');
  }

  showDeleteConfirm() {
      let {item, isCanUpdate, materials} = this.state;
      if(isCanUpdate && item.isReadyToPay){
          let { openModal, closeModal } = this.props.modal;
          let modal = (<ModalConfirm
              title={I18n.t('PageWareHouse.Save')}
              confirmMessage={I18n.t('PageWareHouse.ConfirmSaveOkToPay')}
              messageOk={I18n.t('Btn.YES')}
              messageCancel={I18n.t('Btn.CANCEL')}
              handleClose={closeModal}
              handleCancel={() => this.handleCancel()}
              data = {materials}
              handleOk={(data) => this.save(data)} />);
          openModal(modal);
      } else {
          this.save(materials);
      }
  }

  changeOkToPay (value) {
    let { item } = this.state;
    item.isReadyToPay = value;
    item.status = value ? 'Approved' :'Unreserved';
    this.setState({ item });
  }

  render () {
    let {item, materials, saved, isCanUpdate} = this.state;
    let { showEdit } = utils.getRoleByAuthState(this.props.authState, 'warehouse');
    let w100 = {
      width: '100%'
    };
    if (!showEdit) { isCanUpdate = false; saved = false }
    return (
      <div id="content">
        <div className="wrap-filter-title w-full pd-5 inline-block ">
          <div className="bg-white w-full inline-block pd-10">

            {!!item.order && (
              <div className="float-left h-40 line-h-40 mg-right-50" >
                <p className="size-12 normal">{I18n.t('PageWareHouse.CustomerPO')} <span className="color-orange medium size-16">{_.get(item, 'order.customerPO', '')}</span></p>
              </div>
            )}
            {!!item.order && (
              <div className="float-left h-40 line-h-40 mg-right-50">
                <p className="size-12 normal">{I18n.t('PageWareHouse.Style')} <span className="color-orange medium size-16">{_.get(item, 'style.style', '')}</span></p>
              </div>
            )}
            <div className="float-left h-40 line-h-40">
              <p className="size-12 normal">{I18n.t('PageWareHouse.PurchaseOrderNo')} <span className="color-orange medium size-16">{_.get(item, 'purchase.purchaseNo')}</span></p>
            </div>
            {saved && (
              <div className="float-right add-filter-top">
                <span className="cursor-pointer global-add" onClick={() => this.showDeleteConfirm()}><i className="fa fa-floppy-o" aria-hidden="true" /> Save</span>
              </div>
            )}
            {this.props.canOKToPay && (
              <div className="style-checkbox ok-to-pay inline-block pd-10-0 float-right">
                <input 
                  type="checkbox" 
                  id="stock_reserved" 
                  disabled = {!isCanUpdate} 
                  checked={!!item.isReadyToPay} 
                  onChange={evt => this.changeOkToPay(evt.target.checked)} 
                  value 
                />
                <label htmlFor="stock_reserved"><span><span /></span> Ok To Pay</label>
              </div>
            )}
          </div>
        </div>

        <div className="wrap-global-table">
          <table style={w100} className="global-table data-time-table warehouse">
            <tbody className="body-data-time">
              <tr>
                <th className="center w-265">{I18n.t('PageWareHouse.ReceivedDate')}</th>
                <th className="center">{I18n.t('PageWareHouse.Material')}</th>
                <th className="center">{I18n.t('PageWareHouse.Content')}</th>
                <th className="center">{I18n.t('PageWareHouse.Color')}</th>
                <th className="center">{I18n.t('PageWareHouse.Size')}</th>
                <th className="center">{I18n.t('PageWareHouse.Needed')}</th>
                <th className="center">{I18n.t('PageWareHouse.Actual')}</th>
                <th className="center">{I18n.t('PageWareHouse.Usable')}</th>
                <th className="center">{I18n.t('PageWareHouse.Damaged')}</th>
                <th className="center">{I18n.t('PageWareHouse.Unit')}</th>
                <th className="center">{I18n.t('PageWareHouse.Roll')}</th>
                {isCanUpdate && <th className="center">{I18n.t('PageWareHouse.Actions')}</th>}
              </tr>

              {_.map(materials, (i, idx) => (
                <tr key={idx}>
                  <td className="center date-pick date-time-td">
                    {!isCanUpdate ? 
                      <input type = "text" value={moment(i.receivedDate || {}).format('MMM DD, YYYY')} disabled/> 
                    : i.receivedDate ? 
                      <DateTimeField 
                        timeFormat={false} closeOnSelect dateFormat="MMM DD, YYYY"
                        value={moment(i.receivedDate || {})}
                        onChange={date => this.onChangeMaterials(i._id, 'receivedDate', date.format())} 
                      />
                    : 
                      <DateTimeField 
                        timeFormat={false} closeOnSelect dateFormat="MMM DD, YYYY"
                        onChange={date => this.onChangeMaterials(i._id, 'receivedDate', date.format())} 
                      />
                    }
                  </td>
                  <td className="center">{_.get(i, 'material.name', '')}</td>
                  <td className="center">{_.get(i, 'material.data.content')}</td>
                  <td className="center">{i.color}</td>
                  <td className="center">{i.size}</td>
                  <td className="center">{i.quantity}</td>
                  <td className="center w-100">
                    <input type="text" disabled = {!isCanUpdate} value={i.actual || ''} onChange={evt => this.onChangeMaterials(i._id, 'actual', evt.target.value)} className="form-control center" />
                  </td>
                  <td className="center w-100">
                    <input type="text" disabled = {!isCanUpdate} value={i.usable || ''} onChange={evt => this.onChangeMaterials(i._id, 'usable', evt.target.value)} className="form-control center" />
                  </td>
                  <td className="center">{(i.actual - i.usable) ? (i.actual - i.usable).toFixed(2):0}</td>
                  <td className="center">{_.get(i, 'material.data.unit')}</td>
                  <td className="center w-100">
                    <input type="text" disabled = {!isCanUpdate} value={i.roll || ''} onChange={evt => this.onChangeMaterials(i._id, 'roll', evt.target.value)} className="form-control center" />
                  </td>
                  {isCanUpdate &&
                    <td className="actions">
                        <span key={idx} className="cursor-pointer" style={{float: 'none'}} onClick={() => this.deleteMaterial(i._id)}><img src={icXIcon} alt="" /></span>
                    </td>
                  }
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

function mapStateToProps (state) {
  return {
    authState: state.authState,
    stockInState: state.stockInState,
    canOKToPay: permissions.isSpecial({moduleKey: "warehouse", ...state})
  };
}

function mapDispatchToProps (dispatch) {
  return {
    appPropsActions: bindActionCreators(appActions, dispatch),
    stockInPropsActions: bindActionCreators(stockInActions, dispatch)
  };
}

StockInSupplierEdit.propTypes = {
  authState: PropTypes.object.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(StockInSupplierEdit));
