import React, { Component } from "react";
import * as R from 'ramda';
import _ from 'lodash';
import { Switch, Route, Link, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import BaseComponent from "../base/BaseComponent";
import TabProperty from "./materials/TabProperty";
import TabFabric from "./materials/TabFabric";
import TabTrim from "./materials/TabTrim";
import PackingStandard from "./packingStandard/PackingStandard";
import Shippings from "./Shippings";
import GarmentSize from "./GarmentSizes";
import ContainerType from "./ContainerTypes";
import I18n from "i18n-js";
import PackingStandardAdd from "./packingStandard/PackingStandardAdd";
import * as PATH from "../../constants/routeConstants";
import { bindActionCreators } from 'redux';
import appActions from '../../actions/appActions';
import * as utils from "../../functions/utils";

class PageStandard extends BaseComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {
      searchFields: {},
      searchValue: {},
      tabActive: "materials",
      tabCurrent: "",
      tabChild: ""
    };
  }

  componentWillMount() {
    this.props.appPropsActions.setDataPageCurrent({isEdit: false, isAdd: false});
  }

  componentDidUpdate() {
    this.scrollBar();
  }

  componentWillReceiveProps(newProps) {
    let { tabCurrent, tabChild } = this.state;
    !(tabCurrent === _.get(newProps.appState, "page.tab", "")) &&
      this.setState({ tabCurrent: _.get(newProps.appState, "page.tab", "") });
    !(tabChild === _.get(newProps.appState, "page.tabChild", "")) &&
      this.setState({
        tabChild: _.get(newProps.appState, "page.tabChild", "")
      });
  }

  scrollBar() {
    if (
      $("#page-standard").height() < $("#page-standard > .wrap-page").height()
    ) {
      $("#page-standard").addClass("scrollbar");
    } else {
      $("#page-standard").removeClass("scrollbar");
    }
  }
  
  getStandardEditComponent(pageCurrent, tabSub = "") {
    let StandardComponent = null;

    switch (pageCurrent.screen) {
      case "createStandard":
        StandardComponent = PackingStandardAdd;
        break;
      case "editStandard":
        StandardComponent = PackingStandardAdd;
        break;
    }

    return StandardComponent;
  }

  render() {
    // const { tabCurrent } = this.props;
    let roles = utils.getRoleByAuthState(this.props.authState, 'standard');
    const pageCurrent = this.props.appState.page || {};
    let StandardComponent = this.getStandardEditComponent(
      pageCurrent,
      _.get(pageCurrent, "context.tabSub", "")
    );
    let show =
      !!pageCurrent.isEdit || (!!pageCurrent.isAdd && StandardComponent);

    const pathname = R.path(['location', 'pathname'], this.props);

    return (
      <div>
        {show && <StandardComponent {...this.props} />}
        <div className={`${show ? "hide" : ""}`} id="page-standard">
          <div className="global-table-tab pd-10 pd-top-15 wrap-page">
            <ul className="nav nav-tabs wrap-tab" role="tablist">
              <li
                role="presentation"
                className={pathname.startsWith(PATH.STANDARD_MATERIALS_URL) ? 'active' : ''}
              >
                <Link
                  to={PATH.STANDARD_MATERIALS_URL}
                  aria-controls="Materials"
                >
                  {I18n.t("PageStandard.Materials")}
                </Link>
                <span className="line" />
              </li>

              <li
                className={pathname.startsWith(PATH.STANDARD_PACKINGS_URL) ? 'active' : ''}
                role="presentation"
              >
                <Link
                  to={PATH.STANDARD_PACKINGS_URL}
                  aria-controls="Packing Standard"
                >
                  {I18n.t("PageStandard.PackingStandard")}
                </Link>
                <span className="line" />
              </li>

              <li
                role="presentation"
                className={pathname.startsWith(PATH.STANDARD_SHIPPINGS_URL) ? 'active' : ''}
              >
                <Link
                  to={PATH.STANDARD_SHIPPINGS_URL}
                  aria-controls="Shippings"
                  role="tab"
                >
                  {I18n.t("PageStandard.Shippings")}
                </Link>
                <span className="line" />
              </li>

              <li
                role="presentation"
                className={pathname.startsWith(PATH.STANDARD_GARMENT_SIZES_URL) ? 'active' : ''}
              >
                <Link
                  to={PATH.STANDARD_GARMENT_SIZES_URL}
                  aria-controls="Garment Sizes"
                >
                  {I18n.t("PageStandard.GarmentSizes")}
                </Link>
                <span className="line" />
              </li>

              <li
                role="presentation"
                className={pathname.startsWith(PATH.STANDARD_CONTAINER_TYPES_URL) ? 'active' : ''}
              >
                <Link
                  to={PATH.STANDARD_CONTAINER_TYPES_URL}
                  aria-controls="Container Types"
                  role="tab"
                >
                  {I18n.t("PageStandard.ContainerTypes")}
                </Link>
              </li>
            </ul>

            <div className="tab-content" id="standard">
              <Switch>
                <Route exact path={PATH.STANDARD_URL} render={() => <Redirect to={PATH.STANDARD_MATERIALS_URL} />} />
                <Route
                  path={PATH.STANDARD_MATERIALS_URL}
                  render={() => (
                    <div
                      role="tabpanel"
                      className="tab-pane active"
                      id="materials"
                    >
                      <ul className="nav nav-tabs wrap-tab" role="tablist">
                        <li role="presentation" className="active">
                          <a
                            className="box-shadow-none bold text-transform"
                            href="#standard-materials-property"
                            aria-controls="standard-materials-property"
                            role="tab"
                            data-toggle="tab"
                          >
                            {I18n.t("PageStandard.Property")}
                          </a>
                          <span className="line inline-block z-index-2" />
                        </li>
                        <li role="presentation">
                          <a
                            className="box-shadow-none bold text-transform"
                            href="#standard-materials-fabric"
                            aria-controls="standard-materials-fabric"
                            role="tab"
                            data-toggle="tab"
                          >
                            {I18n.t("PageStandard.Fabric")}
                          </a>
                          <span className="line inline-block z-index-2" />
                        </li>
                        <li role="presentation">
                          <a
                            className="box-shadow-none bold text-transform"
                            href="#standard-materials-trim"
                            aria-controls="standard-materials-trim"
                            role="tab"
                            data-toggle="tab"
                          >
                            {I18n.t("PageStandard.Trim")}
                          </a>
                        </li>
                      </ul>

                      <div
                        className="tab-content bd-dashed-top z-index-2"
                        id="tab-materials"
                      >
                        <TabProperty {...this.props} roles={roles} />
                        <TabFabric {...this.props} roles={roles} />
                        <TabTrim {...this.props} roles={roles} />
                      </div>
                    </div>
                  )}
                />

                <Route
                  path={PATH.STANDARD_PACKINGS_URL}
                  render={() => (
                    <div
                      role="tabpanel"
                      id="packing-standard"
                    >
                      <PackingStandard {...this.props} roles={roles} />
                    </div>
                  )}
                />

                <Route
                  path={PATH.STANDARD_SHIPPINGS_URL}
                  render={() => (
                    <div role="tabpanel" id="shippings">
                      <Shippings {...this.props} roles={roles} />
                    </div>
                  )}
                />

                <Route
                  path={PATH.STANDARD_GARMENT_SIZES_URL}
                  render={() => (
                    <div
                      role="tabpanel"
                      id="garment-sizes"
                    >
                      <GarmentSize {...this.props} roles={roles} />
                    </div>
                  )}
                />

                <Route
                  path={PATH.STANDARD_CONTAINER_TYPES_URL}
                  render={() => (
                    <div
                      role="tabpanel"
                      id="container-types"
                    >
                      <ContainerType {...this.props} roles={roles} />
                    </div>
                  )}
                />
              </Switch>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    appState: state.appState,
    authState: state.authState
  };
}

function mapDispatchToProps(dispatch) {
  return {
    appPropsActions: bindActionCreators(appActions, dispatch)
  };
}

PageStandard.propTypes = {
  authState: PropTypes.object.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PageStandard);
