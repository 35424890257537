
import React from 'react';
import PropTypes from 'prop-types';
import CheckBox from "../../fields/CheckBox";

export default class CheckList extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const {items, title} = this.props;
    return (
      <div className="content-sidebar" id="check-list">
        <div className="md title-main bg-white">
          <h3 className="medium center size-12">{title}</h3>
        </div>
        <div className="md content-sidebar-menu">
          <ul>
            {(items || []).map(item =>
              <li key={item._id}>
                <CheckBox checked={!!item.active} id={item._id} onChange={(evt)=>this.props.onChange(item._id, evt.target.checked)}/>
                <span>{item.name}</span>
              </li>
            )}
          </ul>
        </div>
      </div>
    );
  }
}

CheckList.propTypes = {
  items: PropTypes.array,
  onChange: PropTypes.func,
};
