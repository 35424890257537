/**
 * @author hienhc <hienhc@nexlesoft.com>
 * @create date 2018-09-04 02:16:10
 */
import _ from 'lodash';

export const getTotal = ({ createPklShip }) => {
  if (createPklShip.pklProds[0] && createPklShip.pklProds[0].type === 'GOHH') {
    const { packingInputs } = createPklShip;
    return _.sum(
      _.map(packingInputs, (v, k) => {
        const total = _.sum(
          _.map(v.dataset, it => {
            return _.sum(_.map(it.packings, p => p.value));
          })
        );
        return total;
      })
    );
  } else {
    const { packedQuantities } = createPklShip;
    const totalPacking = _.sum(
      _.map(packedQuantities, (v, k) =>
        _.sum(
          _.map(v, item => {
            const [firstPack] = item.raw;
            const quantity = item.checked ? item.quantity : 0;
            const total = _.sum(_.map(firstPack.items, it => it.planQuantity)) * quantity;
            return total;
          })
        )
      )
    );
    return totalPacking;
  }
};

export const getOrders = ({ createPklShip }) => {
  const { activeOrders, pklProds } = createPklShip;
  const orders = [];
  pklProds.map(it => {
    if (it) {
      const _id = it._id;
      const { customerPO } = it.order;
      const result = { _id, name: customerPO, active: activeOrders.findIndex(item => item == _id) >= 0 };
      return orders.push(result);
    }
  });
  return orders;
};

export const getStyles = ({ createPklShip }) => id => {
  const { activeStyles, pklProds } = createPklShip;
  const styles = [];
  pklProds.map(it => {
    if (it._id === id) {
      it.styles.map(s => {
        const { _id, style } = s.style;
        const result = { _id, name: style, active: activeStyles[id] == _id };
        styles.push(result);
      });
    }
  });
  return styles;
};

export const fnGetOrderDetail = ({ createPklShip }) => id => {
  const { pklProds } = createPklShip;
  const detail = _.find(pklProds, it => it._id === id);
  if (!detail) return null;
  const result = _.cloneDeep(detail);
  result.self = detail.order.self || []; // TODO;
  result.description = detail.order.description || '';
  return result;
};

export const getCommondity = ({ createPklShip }) => orderId => {
  const { pklProds } = createPklShip;
  const detail = _.find(pklProds, it => it._id === orderId);
  if (!detail) return null;

  const result = detail.commodity;
  return result || '';
};

export const fnGetCutTickets = ({ createPklShip }) => (orderId, styleId) => {
  const { pklProds, packingInputs } = createPklShip;
  const detail = _.find(pklProds, it => it._id === orderId);
  if (!detail) return [];

  const style = _.find(detail.styles, it => it.style._id === styleId);
  if (!style) return [];

  const results = _.map(style.cutTickets, item => {
    return item;
  });

  return results;
};

export const fnGetPacking = ({ createPklShip }) => (orderId, styleId) => {
  const { pklProds, packingInputs } = createPklShip;
  const detail = _.find(pklProds, it => it._id === orderId);
  if (!detail) return [];

  const style = _.find(detail.styles, it => it.style._id === styleId);
  if (!style) return [];

  const results = _.map(style.cutTickets, item => {
    return item;
  });

  return results;
};

export const fnGenApiData = ({ createPklShip, showPklProdList }) => () => {
  if (createPklShip.pklProds[0] && createPklShip.pklProds[0].type === 'GOHH') {
    const { packingInputs } = createPklShip;
    const pklProds = _.map(createPklShip.pklProds, it => ({
      self: it.order.self,
      packing: it._id,
      commodity: it.commodity,
      type: it.type,
      customer: it.customer._id,
      contractor: it.contractor,
      order: it.order._id,
      notifyDate: it.notifyDate,
      styles: _.map(it.styles, st => ({
        style: st.style._id,
        active: true,
        cutTickets: _.map(st.cutTickets, ct => ({
          cutTicket: ct.cutTicket._id,
          colors: _.map(ct.colors, color => ({
            color: color.color._id,
            packings: _.map(
              packingInputs[`${it._id}:${st.style._id}:${ct.cutTicket._id}:${color.color._id}`].dataset,
              record => ({
                props: {
                  no: record.bar,
                  actual: true,
                },
                items: _.map(record.packings, pk => ({
                  name: pk.name,
                  planQuantity: pk.value,
                  actualQuantity: pk.value,
                })),
              })
            ),
          })),
        })),
      })),
    }));

    return { pklProds, id: showPklProdList.checkEdit };
  } else {
    const { packedQuantities } = createPklShip;
    const pklProds = _.map(createPklShip.pklProds, it => ({
      self: it.order.self,
      carton: it.carton,
      packing: it._id,
      commodity: it.commodity,
      type: it.type,
      customer: it.customer._id,
      contractor: it.contractor,
      order: it.order._id,
      notifyDate: it.notifyDate,
      styles: _.map(it.styles, st => ({
        style: st.style._id,
        active: true,
        packings: st.packings,
        cutTickets: _.map(st.cutTickets, ct => ({
          cutTicket: ct.cutTicket._id,
          colors: _.map(ct.colors, color => ({
            color: color.color._id,
            packings: _.concat(
              ..._.map(
                packedQuantities[`${it._id}:${st.style._id}:${ct.cutTicket._id}:${color.color._id}`],
                record => record
              ).map(item => (item.checked ? _.slice(item.raw, 0, item.quantity) : []))
            ).map(it => {
              return {
                props: { ...it.props, actual: true },
                items: it.items.map(i => {
                  return {
                    name: i.name,
                    planQuantity: i.planQuantity,
                    actualQuantity: i.planQuantity,
                  };
                }),
              };
            }),
          })),
        })),
      })),
    }));
    return { pklProds, id: showPklProdList.checkEdit };
  }
};

export const getIdOfPackings = ({ showPklProdList, editPklShip }) => {
  // console.log('showPklProdList: ', showPklProdList);
  // console.log('editPklShip: ', editPklShip);
  let ids = [];
  if (!showPklProdList.checkEdit) {
    showPklProdList.checkedList.map(item => ids.push(item._id));
  }

  return ids;
};

export const getActiveStyleIds = ({ createPklShip }) => createPklShip.activeStyles;

export const getPackingType = ({ createPklShip }) => {
  if (_.isEmpty(createPklShip.pklProds)) return null;
  return createPklShip.pklProds[0].type;
};

export const getSizes = ({ createPklShip }) => (orderId, styleId, cutId, colorId) => {
  let sizes = [];
  createPklShip.pklProds.map(
    order =>
      order._id === orderId &&
      order.styles.map(
        style =>
          style.style._id === styleId &&
          style.cutTickets.map(
            cut =>
              cut.cutTicket._id === cutId &&
              cut.colors.map(
                color =>
                  color.color._id == colorId &&
                  color.color.sizes.map(size => {
                    if (_.findIndex(sizes, item => item === size.name) === -1) {
                      sizes.push(size.name);
                    }
                  })
              )
          )
      )
  );
  return sizes;
};

export const getSizeQtyGOHH = ({ createPklShip }) => (orderId, styleId, cutId, colorId, sizeName) => {
  const key = `${orderId}:${styleId}:${cutId}:${colorId}`;
  const qty = createPklShip.packingInputs[key]
    ? createPklShip.packingInputs[key].addableQuantities.find(size => size.name == sizeName)
    : {};
  return qty.value || 0;
};

export const getQtyColorConst = ({ createPklShip }) => (orderId, styleId, cutId, colorId, sizeName) => {
  if (sizeName) {
    const key = `${orderId}:${styleId}:${cutId}:${colorId}`;
    return _.sum(
      _.map(createPklShip.quantitiesConst[key], item => {
        const [firstPack] = item.raw;
        const quantity = item.checked ? item.quantity : 0;
        const total = _.sum(_.map(firstPack.items, it => it.name === sizeName && it.planQuantity)) * quantity;
        return total;
      })
    );
  } else {
    const key = `${orderId}:${styleId}:${cutId}:${colorId}`;
    return _.sum(
      _.map(createPklShip.quantitiesConst[key], item => {
        const [firstPack] = item.raw;
        const quantity = item.checked ? item.quantity : 0;
        const total = _.sum(_.map(firstPack.items, it => it.planQuantity)) * quantity;
        return total;
      })
    );
  }
};

export const getPacking = ({ createPklShip }) => (orderId, styleId, cutId, colorId, sizeName) => {
  const key = `${orderId}:${styleId}:${cutId}:${colorId}`;
  const packing = createPklShip.packingInputs[key]
    ? createPklShip.packingInputs[key].sizeInputs.find(size => size.name == sizeName)
    : {};
  return packing.value || 0;
};

export const getBarNumber = ({ createPklShip }) => (orderId, styleId, cutId, colorId) => {
  const key = `${orderId}:${styleId}:${cutId}:${colorId}`;
  return (createPklShip.packingInputs[key] && createPklShip.packingInputs[key].barInput) || 0;
};

export const getListBarDetail = ({ createPklShip }) => (orderId, styleId, cutId, colorId) => {
  const key = `${orderId}:${styleId}:${cutId}:${colorId}`;
  return (createPklShip.packingInputs[key] && createPklShip.packingInputs[key].dataset) || [];
};

export const getBarTotal = ({ createPklShip }) => (orderId, styleId, cutId, colorId, value) => {
  const key = `${orderId}:${styleId}:${cutId}:${colorId}`;
  const data = createPklShip.packingInputs[key] && createPklShip.packingInputs[key].dataset;
  const sum = _.sum(data.find(bar => bar.bar === value).packings.map(packing => packing.value));
  return sum;
};

export const getRemaining = ({ createPklShip }) => (orderId, styleId, cutId, colorId, sizeName) => {
  const key = `${orderId}:${styleId}:${cutId}:${colorId}`;
  const data = createPklShip.packingInputs[key] && createPklShip.packingInputs[key].dataset;
  const addableQuantities = createPklShip.packingInputs[key] && createPklShip.packingInputs[key].addableQuantities;
  const sumSizePacking = _.sum(data.map(bar => bar.packings.find(packing => packing.name == sizeName).value));
  const remaining = addableQuantities.find(item => item.name === sizeName).value - sumSizePacking;
  return remaining;
};

export const getSuccess = ({ createPklShip }) => createPklShip.success;

export const getCarton = ({ createPklShip }) => (orderId, styleId, cutTicketId, colorId) => {
  const key = `${orderId}:${styleId}:${cutTicketId}:${colorId}`;
  return createPklShip.packedQuantities[key] || [];
};

export const getEmptyCommondity = ({ createPklShip }) => {
  if (createPklShip.pklProds) {
    const temp = createPklShip.pklProds.filter(item => item && item.commodity);
    return temp.length < createPklShip.pklProds.length ? true : false;
  }
};
export const checkEdit = ({ showPklProdList }) => showPklProdList.checkEdit;

export const getPklShips = ({ createPklShip }) => createPklShip.pklShips;
export const getActions = ({ createPklShip }) => createPklShip.actions;
