import React from "react";
import PropTypes from "prop-types";
import I18n from "i18n-js";
import _ from "lodash";
import * as R from "ramda";
import Modal from "../forms/Modal";

import IMG_DEFAULT from "../../../stylesheets/images/grag-photo.png";

import icXHoverIcon from '../../../stylesheets/images/ic-x-hover.svg';

const EditStyleModal = props => (
  <Modal isOpen={props.isOpen}>
    <div className="modal-dialog" role="document">
      <div className="modal-content">
        <form>
          <div className="modal-header">
            <a className="close" onClick={() => props.onClose()}>
              <span aria-hidden="true">&times;</span>
            </a>
            <h4 className="modal-title" id="myModalLabel">
              {props.title}
            </h4>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <div className="col-xs-12 value dragphotos">
                <ul>
                  {_.map(props.images || [], image => (
                    <li key={image.url}>
                      <img
                        src={image.url}
                        alt="Image"
                        className="dnd-item"
                        style={{ maxWidth: "200px" }}
                      />
                      <span className="close-photo">
                        <img
                          style={{ cursor: "pointer" }}
                          src={icXHoverIcon}
                          onClick={e => {
                            e.stopPropagation();
                            props.onRemoveImage(image)
                          }}
                        />
                      </span>
                    </li>
                  ))}

                  <li className="default center">
                    <img
                      onDragOver={evt => evt.preventDefault()}
                      onDrop={e => {
                        e.preventDefault();
                        let file = e.dataTransfer
                          ? e.dataTransfer.files[0]
                          : e.target.files[0];
                        if (!file) return;

                        props.onAddImage(file);
                      }}
                      onDrag={evt => evt.preventDefault()}
                      onClick={() => {
                        $("#imgStyle").click();
                      }}
                      style={{ cursor: "pointer" }}
                      src={IMG_DEFAULT}
                    />
                  </li>
                </ul>
                <input
                  onChange={e => {
                    e.preventDefault();
                    let file = e.dataTransfer
                      ? e.dataTransfer.files[0]
                      : e.target.files[0];
                    if (!file) return;

                    props.onAddImage(file);
                  }}
                  style={{ display: "none" }}
                  id="imgStyle"
                  type="file"
                  name="imgStyle"
                />
              </div>
            </div>

            <div className="form-group">
              <div className="col-md-4 col-xs-12 text">
                <p>{I18n.t("PageOrder.Style")}</p> <span className="color-red"> *</span>
              </div>
              <div className="col-md-8 col-xs-12 value">
                <input
                  type="text"
                  className="form-control"
                  value={props.style || ""}
                  onChange={evt => props.onChangeStyle(evt.target.value)}
                />

                {R.path(["style"], props.errors) && (
                  <div className="alert-warning">{R.path(["style"], props.errors)}</div>
                )}
              </div>
            </div>

            <div className="form-group">
              <div className="col-md-4 col-xs-12 text">
                <p>{I18n.t("PageOrder.Page")}</p> {/* <span className="color-red"> *</span> */}
              </div>
              <div className="col-md-8 col-xs-12 value">
                <input
                  type="text"
                  className="form-control"
                  value={props.page || ""}
                  onChange={evt => props.onChangePage(evt.target.value)}
                />
                {R.path(["page"], props.errors) && (
                  <div className="alert-warning">{R.path(["page"], props.errors)}</div>
                )}
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="bt-black"
              onClick={() => props.onClose()}
            >
              {I18n.t("Btn.CANCEL")}
            </button>
            <button
              type="button"
              className={`${!props.canSave ? 'btn btn-default': ''} bt-orange mg-l-5`}
              disabled={!props.canSave}
              onClick={() => props.onSubmit()}
            >
              {props.isSubmiting ? I18n.t("Btn.SAVING") : I18n.t("Btn.SAVE")}
            </button>
          </div>
        </form>
      </div>
    </div>
  </Modal>
);

EditStyleModal.propTypes = {
  title: PropTypes.string.isRequired,
  images: PropTypes.arrayOf(PropTypes.any).isRequired,
  style: PropTypes.string.isRequired,
  page: PropTypes.string.isRequired,
  canSave: PropTypes.bool,
  isSubmiting: PropTypes.bool,
  errors: PropTypes.arrayOf(PropTypes.any).isRequired,

  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  onAddImage: PropTypes.func,
  onRemoveImage: PropTypes.func,
  onChangeStyle: PropTypes.func,
  onChangePage: PropTypes.func,
};

EditStyleModal.defaultProps = {
  canSave: true,
  isSubmiting: false, 

  onClose: f => f,
  onSubmit: f => f,
  onAddImage: f => f,
  onRemoveImage: f => f,
  onChangeStyle: f => f,
  onChangePage: f => f,
};

export default EditStyleModal;
