import React,{Component} from 'react';
import {connect} from 'react-redux';
import writtenNumber from 'written-number';
import {Store} from '../../../CssHandleModule/functions';
import CommondityComponent from './commondityComponent';
import {toastr} from 'react-redux-toastr'
import I18n from 'i18n-js';
import moment from 'moment';
import {SHIP_OUT} from '../../../../constants/routeConstants';
import * as selector from '../../../../selectors/ship-out/createINV';
import {changeCustomerINV, changeInvDate, save, resetData, update} from '../../../../actions/ship-out/createINVActions';
import DateTime from 'react-datetime';
const store = new Store();

store.setStyles({
    orderNumbers: [1, 2, 3],
    aligns: [
        {order: 1, margin: {top: 30}},
        {order: 2, margin: {right: 20}}
    ],
    forms: [
        {order: 1, size: '40%'}
    ],
    sizes: [
        {order: 1, width: '30%'}
    ],
    fonts: [
        {order: 1, size: 15},
        {order: 2, color: 'red'},
        {order: 3, weight: 'bold'}
    ]
});

class CreateINV extends Component{

    constructor(props) {
        super(props);
    }

    componentWillReceiveProps(nextProps) {
        const {success, activeScreenCreateStatus, activeScreenEditStatus} = nextProps;
        if(success) {
            nextProps.router.history.push(SHIP_OUT.PKL_SHIP.URL)
            toastr.success(I18n.t('Notify.Success'), I18n.t('Notify.SaveSuccess'))
        }
        if(!activeScreenCreateStatus && !activeScreenEditStatus) {
            nextProps.router.history.push(SHIP_OUT.PKL_SHIP.URL)
        }
    }

    componentDidMount() {
        this.props.resetData()
    }

    changeCustomerINV = (value) => {
        this.props.changeCustomerINV(value)
    }

    changeInvDate = (value) => {
        this.props.changeInvDate(value)
    }

    save = (dataApi, emptyPrice, emptyHSCode, emptyCatNo, customerINV) => {
        let param;
        if(emptyPrice) {
            param = 'emptyPrice';
        }
        // if(emptyHSCode) {
        //     param = 'emptyHSCode';
        // }
        // if(emptyCatNo) {
        //     param = 'emptyCatNo';
        // }
        if(_.isEmpty(customerINV)) {
            param = 'customerINV';
        }

        switch(param) {
            case 'emptyPrice':
                toastr.error(I18n.t('Notify.Error'), "Price mustn't equal 0");
                break;
            // case 'emptyHSCode':
            //     toastr.error(I18n.t('Notify.Error'), "HS Code mustn't empty");
            //     break;
            // case 'emptyCatNo':
            //     toastr.error(I18n.t('Notify.Error'), "Cat# mustn't empty");
            //     break;
            case 'customerINV':
                toastr.error(I18n.t('Notify.Error'), "Customer INV mustn't empty");
                break;
            default: 
                if(dataApi._id) {
                    this.props.update(dataApi)
                } else {
                    this.props.save(dataApi)
                }
                break;
        }
    }

    setCommondityComponent = (listCommodity) => {
        const commondityComponent = listCommodity.map((item, index) => {
            return <CommondityComponent key={index} data = {item}/>
        });
        return commondityComponent;
    }

    render() {
        const { 
            total, customerINV, invDate , listCommodity, gTotal, sayText,
            dataApi, emptyPrice, emptyHSCode, emptyCatNo
        } = this.props
        const commondityComponent = this.setCommondityComponent(listCommodity)
        // console.log(listCommodity)
        return(
            <div className="bg-white inline-block w-full" id="create_inv">
                <div className="inline-block w-full">
                    <div className="col-md-5 col-xs-12 bd-dashed-right-1">
                        <div className='d-flex mg-15-0'>
                            <span className="size-12 w-100" style={{lineHeight: '34px'}}>{I18n.t('PageShiping.CustomerINV')} <span className="color-red"> *</span></span>
                            <input type='text' className='form-control' style={store.setStyleType('form_1')}
                                value = {customerINV || ''}
                                onChange = {(e) => this.changeCustomerINV(e.target.value)}
                            />
                        </div>
                        <div className='d-flex mg-15-0'>
                            <span className="size-12 w-100" style={{lineHeight: '34px'}}>{I18n.t('PageShiping.Date')}</span>
                            <div style={store.setStyleType('form_1')}>
                                <DateTime
                                    defaultValue={moment().format("MMM DD, YYYY")}
                                    dateFormat="MMM DD, YYYY"
                                    timeFormat={false}
                                    value={moment(invDate)}
                                    onChange={date =>
                                        this.changeInvDate(date.format())
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-7 col-xs-12">
                        <div className="wrap-input">
                            <div className="col-md-4 col-sm-6 col-xs-12 input">
                                <ul>
                                    <li>
                                        <span>{I18n.t('PageShiping.TotalQTY')}</span>
                                    </li>
                                    <li>
                                        <span className="bold size-16">{total || 0}</span>
                                    </li>
                                </ul>
                            </div>

                            <div className="col-md-4 col-sm-6 col-xs-12 input">
                                <ul>
                                    <li>
                                        <span>{I18n.t('PageShiping.GTotal')}</span>
                                    </li>
                                    <li>
                                    <   span className="bold size-18 color-number">{'$'+_.round(gTotal, 5) || 0}</span>
                                    </li>
                                </ul>
                            </div>

                            <div className="col-md-4 col-sm-6 col-xs-12 style-icon-save text-right">
                                <span className="color-hv-green mg-top-15 inline-block cursor-pointer"
                                    onClick = {() => this.save(dataApi, emptyPrice, emptyHSCode, emptyCatNo, customerINV)}
                                >
                                    <i className="fa fa-floppy-o" aria-hidden="true"></i> SAVE
                                </span>
                            </div>

                            <div className="col-xs-12 input">
                                <ul>
                                    <li>
                                        <span>{I18n.t('PageShiping.Say')}</span>
                                    </li>
                                    <li>
                                        <span className="bold">{sayText(gTotal)}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                {commondityComponent}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    total: selector.getTotal(state),
    gTotal: selector.getGTotal(state),
    customerINV: selector.getcustomerInv(state),
    invDate: selector.getInvDate(state),
    listCommodity: selector.getListCommodity(state),
    sayText: selector.genSayText,
    dataApi: selector.genDataApi(state),
    success: selector.getSuccess(state),
    emptyPrice: selector.getEmptyPrice(state),
    emptyHSCode: selector.getEmptyHSCode(state),
    emptyCatNo: selector.getEmptyCatNo(state),
    activeScreenEditStatus: selector.getActiveScreenEditStatus(state),
    activeScreenCreateStatus: selector.getActiveScreenCreateStatus(state),
})

const mapDispatchToProps = dispatch => ({
    changeCustomerINV(value) {
        dispatch(changeCustomerINV(value))
    },
    changeInvDate(value) {
        dispatch(changeInvDate(value))
    },
    save(data) {
        dispatch(save(data))
    },
    update(data) {
        dispatch(update(data))
    },
    resetData() {
        dispatch(resetData())
    }
})
export default connect(mapStateToProps, mapDispatchToProps) (CreateINV);