import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import BaseComponent from '../base/BaseComponent';
import I18n from 'i18n-js';

import placeOrderIcon from '../../stylesheets/images/place_order.svg';

class PagePurchaseOrder extends BaseComponent {
    constructor(props, context) {
        super(props, context);
        this.state = {};
    }

    componentWillReceiveProps(newProps) {
        this.props.tabCurrent !== newProps.tabCurrent;
    }

    render() {
        let style = {
            width: "100%"
        };
        const {tabCurrent} = this.props;
        return (


            <div className={tabCurrent === '' ? 'show' : 'hide'} id="content">
                <div className="md content-main-header pd-5">
                    <ul className="content-main-header-top">
                        <li className="content-main-date">
                            <span>Aug 13</span>
                            <span className="line"><span/></span>
                            <span>2017</span>
                        </li>
                        <li className="content-main-title bold">
                            JEMMA
                        </li>
                        <li className="content-main-po">
                            <div className="content-title-po">
                                <span className="title">Custommer PO</span>
                                <span className="bold color-black-darken">:15620</span>
                            </div>
                            <div className="content-title-po">
                                <span className="title">Internal PO</span>
                                <span className="bold color-black-darken">:15620</span>
                            </div>
                        </li>
                        <li className="content-main-total">
                            <span className="text">G-Total</span>
                            <span className="color-number size-25">2,760</span>
                        </li>
                        <li className="content-main-factory">
                            <span className="text">Ex-Factory</span>
                            <span className="date">August 20,2017</span>
                        </li>
                        <li className="content-main-factory">
                            <span className="text">IH Date</span>
                            <span className="date">August 20,2017</span>
                        </li>

                    </ul>
                </div>
                <ul className="content-header-title">
                    <li>
                        <p>Style <span className="color-orange medium mg-left-50 size-16">MTUD12312S</span></p>
                    </li>
                </ul>
                <ul className="wrap-list-table grid">
                    <li className="grid-item mg-bottom-5">
                        <div className="wrap-item">
                            <div className="box-shadow">
                                <div className="header-place-order">
                                    <ul>
                                        <li>
                                            <h3>CHARLES GARCIA</h3>
                                        </li>
                                        <li>
                                            <span><img src={placeOrderIcon}
                                                       alt=""/> PLACE ORDER</span>
                                        </li>
                                    </ul>
                                </div>

                                <div className="wrap-global-table pd-0">
                                    <table style={style} className="global-table">
                                        <tbody>

                                        <tr className="bg-white bd-dashed-bottom">
                                            <th>Material</th>
                                            <th>Content</th>
                                            <th className="center">Color</th>
                                            <th className="center">Size</th>
                                            <th className="center">Quantity</th>
                                            <th className="center">Expected<br/>Received Date</th>
                                            <th className="center">Amount</th>
                                        </tr>
                                        <tr>
                                            <td>Slub Hacci</td>
                                            <td>100% Cotton</td>
                                            <td className="center">Yellow</td>
                                            <td className="center">-</td>
                                            <td className="center">100</td>
                                            <td className="center">Aug 13, 2017</td>
                                            <td className="number center color-number">$800</td>
                                        </tr>
                                        <tr>
                                            <td>Slub Hacci</td>
                                            <td>100% Cotton</td>
                                            <td className="center">Yellow</td>
                                            <td className="center">-</td>
                                            <td className="center">100</td>
                                            <td className="center">Aug 13, 2017</td>
                                            <td className="number center color-number">$800</td>
                                        </tr>
                                        <tr>
                                            <td>Slub Hacci</td>
                                            <td>100% Cotton</td>
                                            <td className="center">Yellow</td>
                                            <td className="center">-</td>
                                            <td className="center">100</td>
                                            <td className="center">Aug 13, 2017</td>
                                            <td className="number center color-number">$800</td>
                                        </tr>
                                        <tr>
                                            <td>Slub Hacci</td>
                                            <td>100% Cotton</td>
                                            <td className="center">Yellow</td>
                                            <td className="center">-</td>
                                            <td className="center">100</td>
                                            <td className="center">Aug 13, 2017</td>
                                            <td className="number center color-number">$800</td>
                                        </tr>
                                        <tr>
                                            <td>Slub Hacci</td>
                                            <td>100% Cotton</td>
                                            <td className="center">Yellow</td>
                                            <td className="center">-</td>
                                            <td className="center">100</td>
                                            <td className="center">Aug 13, 2017</td>
                                            <td className="number center color-number">$800</td>
                                        </tr>
                                        <tr className="bg-white bd-dashed-top">
                                            <td colSpan="2" className="medium">G-Total</td>
                                            <td colSpan="6" className="color-number right size-16">$800</td>
                                        </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        appState: state.appState,
        authState: state.authState
    };
}

function mapDispatchToProps(dispatch) {
    return {};
}

PagePurchaseOrder.propTypes = {
    authState: PropTypes.object.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(PagePurchaseOrder);

