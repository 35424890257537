/**
 * @author hienhc <hienhc@nexlesoft.com>
 * @create date 2018-09-06 09:54:16
 */
import { createSelector } from 'reselect';
import _ from 'lodash';
import moment from 'moment';

export const getCustomer = ({ editPklShip }) =>
  editPklShip.pklShipDetail && editPklShip.pklShipDetail.pklProds[0].customer.name;

export const getPklShipId = ({ editPklShip }) => editPklShip.pklShipDetail && editPklShip.pklShipDetail._id;

export const getTotal = ({ editPklShip }) => {
  if (editPklShip.pklShipDetail)
    if (editPklShip.pklShipDetail.pklProds[0].type == 'GOHH') {
      return _.sum(
        editPklShip.pklShipDetail.pklProds.map(it =>
          _.sum(
            it.styles.map(style =>
              _.sum(
                style.cutTickets.map(cut =>
                  _.sum(
                    cut.colors.map(color =>
                      _.sum(color.packings.map(packing => _.sum(packing.items.map(item => item.actualQuantity || 0))))
                    )
                  )
                )
              )
            )
          )
        )
      );
    } else {
      const { packedQuantities } = editPklShip;
      // console.log('packedQuantities----------', packedQuantities);
      return _.sum(
        _.map(packedQuantities, (v, k) =>
          _.sum(
            _.map(
              v,
              carton => carton.canExcute && carton.props.actual && _.sum(carton.items.map(item => item.planQuantity))
              // const total = _.sum(item.raw.map(bar => bar.checked && _.sum(bar.items.map(it => it.actualQuantity))));
              // return total;
            )
          )
        )
      );
    }
  else return 0;
};

export const getOrders = ({ editPklShip }) => {
  const { activeOrders, pklShipDetail } = editPklShip;
  const orders =
    pklShipDetail &&
    pklShipDetail.pklProds.map(it => {
      const _id = it._id;
      const { customerPO } = it.order;
      const result = { _id, name: customerPO, active: activeOrders.findIndex(item => item == _id) >= 0 };
      return result;
    });
  return orders;
};

export const getShipOut = ({ editPklShip }) => {
  const { pklShipDetail } = editPklShip;
  if (pklShipDetail) {
    return pklShipDetail.shipOut;
  }
  return null;
};

export const getStyles = ({ editPklShip }) => id => {
  const { activeStyles, pklShipDetail } = editPklShip;
  const styles = [];
  pklShipDetail &&
    pklShipDetail.pklProds.map(it => {
      if (it._id === id) {
        it.styles.map(s => {
          const { _id, style } = s.style;
          const result = { _id, name: style, active: activeStyles[id] == _id };
          styles.push(result);
        });
      }
    });
  return styles;
};

export const fnGetOrderDetail = ({ editPklShip }) => id => {
  const { pklProds } = editPklShip.pklShipDetail;
  const detail = _.find(pklProds, it => it._id === id);
  if (!detail) return null;
  const result = _.cloneDeep(detail);
  result.self = detail.order.self || []; // TODO;
  result.description = detail.order.description || '';
  return result;
};

export const pklProds = ({ editPklShip }) => editPklShip.pklShipDetail && editPklShip.pklShipDetail.pklProds;

export const getCommondity = ({ editPklShip }) => orderId => {
  const { pklProds } = editPklShip.pklShipDetail;
  const detail = _.find(pklProds, it => it._id === orderId);
  if (!detail) return null;

  const result = detail.commodity;
  return result || '';
};

export const fnGetCutTickets = ({ editPklShip }) => (orderId, styleId) => {
  const { pklShipDetail } = editPklShip;
  const detail = _.find(pklShipDetail.pklProds, it => it._id === orderId);
  if (!detail) return [];

  const style = _.find(detail.styles, it => it.style._id === styleId);
  if (!style) return [];

  const results = _.map(style.cutTickets, item => {
    return item;
  });

  return results;
};

export const fnGetBarPlanQuantities = ({ editPklShip }) => (orderId, styleId, cutTicketId, colorId, barNo) => {
  // TODO.
  throw 'Not Implemented Exception';
};

export const fnGetBarActualQuantities = ({ editPklShip }) => (orderId, styleId, cutTicketId, colorId, barNo) => {
  // TODO.
  throw 'Not Implemented Exception';
};

export const fnGetBarMissingQuantities = ({ editPklShip }) => (orderId, styleId, cutTicketId, colorId, barNo) => {
  // TODO.
  throw 'Not Implemented Exception';
};

// export const fnGenApiData = ({ editPklShip }) => editPklShip.pklShipDetail && {id: editPklShip.pklShipDetail._id, pklProds: editPklShip.pklShipDetail.pklProds};

export const fnGenApiData = ({ editPklShip }) => {
  if (editPklShip.pklShipDetail) {
    if (editPklShip.pklShipDetail.pklProds[0].type == 'GOHH') {
      const pklProds = _.map(editPklShip.pklShipDetail.pklProds, it => ({
        _id: it._id,
        packing: it.packing ? it.packing._id : null,
        commodity: it.commodity,
        type: it.type,
        customer: it.customer._id,
        contractor: it.contractor,
        notifyDate: it.notifyDate,
        order: it.order._id,
        styles: _.map(it.styles, st => ({
          style: st.style._id,
          active: true,
          cutTickets: _.map(st.cutTickets, ct => ({
            cutTicket: ct.cutTicket._id,
            colors: _.map(ct.colors, color => ({
              color: color.color._id,
              packings: _.map(color.packings, record => ({
                props: {
                  no: record.props.no,
                  actual: record.props.actual,
                },
                items: _.map(record.items, pk => ({
                  name: pk.name,
                  planQuantity: pk.planQuantity,
                  actualQuantity: pk.actualQuantity,
                })),
              })),
            })),
          })),
        })),
      }));
      return { pklProds: pklProds, id: editPklShip.pklShipDetail._id };
    } else {
      const pklProds = _.map(editPklShip.pklShipDetail.pklProds, it => ({
        _id: it._id,
        packing: it.packing ? it.packing._id : null,
        commodity: it.commodity,
        type: it.type,
        customer: it.customer._id,
        contractor: it.contractor,
        notifyDate: it.notifyDate,
        order: it.order._id,
        styles: _.map(it.styles, st => ({
          style: st.style._id,
          active: true,
          packings: st.packings,
          cutTickets: _.map(st.cutTickets, ct => ({
            cutTicket: ct.cutTicket._id,
            colors: _.map(ct.colors, color => ({
              color: color.color._id,

              packings: _.filter(
                editPklShip.packedQuantities[`${it._id}:${st.style._id}:${ct.cutTicket._id}:${color.color._id}`],
                record => record.canExcute
                // {
                // if(record.checked) {
                //     return {
                //         props: record.props,
                //         items: record.items,
                //     }
                // }
                // }
              ),
            })),
          })),
        })),
      }));
      return { pklProds: pklProds, id: editPklShip.pklShipDetail._id };
    }
  }
};

export const getActiveStyleIds = ({ editPklShip }) => editPklShip.activeStyles;

export const getPackingType = ({ editPklShip }) =>
  editPklShip.pklShipDetail && editPklShip.pklShipDetail.pklProds[0].type;

export const getSizes = ({ editPklShip }) => (orderId, styleId, cutId, colorId) => {
  let sizes = [];
  editPklShip.pklShipDetail &&
    editPklShip.pklShipDetail.pklProds.map(
      order =>
        order._id === orderId &&
        order.styles.map(
          style =>
            style.style._id === styleId &&
            style.cutTickets.map(
              cut =>
                cut.cutTicket._id === cutId &&
                cut.colors.map(
                  color =>
                    color.color._id == colorId &&
                    color.color.sizes.map(size => {
                      if (_.findIndex(sizes, item => item === size.name) === -1) {
                        sizes.push(size.name);
                      }
                    })
                )
            )
        )
    );
  return sizes;
};

export const getBars = ({ editPklShip }) => (orderId, styleId, cutId, colorId) => {
  let bars = [];

  editPklShip.pklShipDetail &&
    editPklShip.pklShipDetail.pklProds.map(
      order =>
        order._id === orderId &&
        order.styles.map(
          style =>
            style.style._id === styleId &&
            style.cutTickets.map(
              cut =>
                cut.cutTicket._id === cutId &&
                cut.colors.map(color => (color.color._id == colorId ? (bars = color.packings) : []))
            )
        )
    );
  return bars;
};

export const getBarTotal = bar => {
  return bar ? _.sum((bar.items || []).map(item => item.actualQuantity || 0)) : 0;
};

export const getBarDetail = ({ editPklShip }) => data => {
  const { barInputs } = editPklShip;
  const bars = barInputs[`${data.orderId}:${data.styleId}:${data.cutTicketId}:${data.colorId}`];
  return bars.find(bar => bar.bar == data.barNo);
};

export const getColorTotal = createSelector([getBars], fnGetBars => (orderId, styleId, cutTicketId, colorId) => {
  return _.sum(
    fnGetBars(orderId, styleId, cutTicketId, colorId).map(bar => _.sum(bar.items.map(item => item.actualQuantity || 0)))
  );
});

export const getBarOfPklShip = ({ editPklShip }) => (orderId, styleId, cutTicketId, colorId, barNo) => {
  let bar = {};

  editPklShip.pklShipDetail &&
    editPklShip.pklShipDetail.pklProds.map(
      order =>
        order._id === orderId &&
        order.styles.map(
          style =>
            style.style._id === styleId &&
            style.cutTickets.map(
              cut =>
                cut.cutTicket._id === cutTicketId &&
                cut.colors.map(color =>
                  color.color._id == colorId ? (bar = color.packings.find(packing => packing.props.no == barNo)) : {}
                )
            )
        )
    );

  return bar;
};

export const getSuccess = ({ editPklShip }) => editPklShip.success;

export const getQtyColorConst = ({ editPklShip }) => (orderId, styleId, cutId, colorId, sizeName) => {
  const key = `${orderId}:${styleId}:${cutId}:${colorId}`;
  const packedQuantities = _.cloneDeep(editPklShip.packedQuantities);
  // console.log('------------packedQuantities[key]', packedQuantities[key]);
  if (sizeName) {
    return !_.isEmpty(packedQuantities[key])
      ? _.sum(
          _.map(packedQuantities[key], v => {
            if (v.canExcute && v.checked) {
              return _.sum(v.items.map(item => (item.name == sizeName ? item.planQuantity : 0)));
            } else {
              return 0;
            }
          })
        )
      : 0;
  } else {
    return !_.isEmpty(packedQuantities[key])
      ? _.sum(
          _.map(packedQuantities[key], v => {
            if (v.canExcute && v.checked) {
              return _.sum(v.items.map(item => item.planQuantity));
            } else {
              return 0;
            }
          })
        )
      : 0;
  }
};

export const getCartons = ({ editPklShip }) => (orderId, styleId, cutId, colorId) => {
  const key = `${orderId}:${styleId}:${cutId}:${colorId}`;
  const { packedQuantities } = editPklShip;
  let cartons = packedQuantities[key];
  return cartons;
};

export const getView = ({ editPklShip }) => editPklShip.view;

export const getInvoice = ({ editPklShip }) => {
  if (editPklShip.pklShipDetail) {
    return editPklShip.pklShipDetail.invoice;
  }
};

export const getActiveScreenEditPklShip = ({ editPklShip }) => editPklShip.activeScreenEditPklShip;
export const getActions = ({ createPklShip }) => createPklShip.actions;
