import { combineReducers } from 'redux'
import C from '../../constants/ship-out/viewPklOfProdDetail';

const packingDetail = (state = null, action) => {
    const {type, payload} = action
    switch(type) {
        case C.CHANGE_PACKING_DETAIL:
            return payload;
        case C.CHANGE_STYLE_ACTIVE:
            let packingDetail = _.cloneDeep(state);
            packingDetail[0].styles && packingDetail[0].styles.map(style => {
                if(style._id == payload) {
                    style.active = !style.active
                }
            })
            return packingDetail
        case C.CHANGE_EMPTY_PACKING:
            return payload;
        default:
            return state;
    }
}


export const viewPklOfProdDetail = combineReducers({
    packingDetail,
});