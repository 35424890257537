
import _ from 'lodash';
import moment from 'moment';

export function isCheckable(checkedList, pklProd) {
    if (checkedList.length <= 0) {
        return true;
    } else {
        const { customer, packingType, notifyDate } = pklProd;
        const [item] = checkedList;
        const dateFormat = 'YYYY-MM-DD';
        return _.isEqualWith(
            [customer, packingType, moment(notifyDate).format(dateFormat)], 
            [item.customer, item.packingType, moment(item.notifyDate).format(dateFormat)]
        );
    }
}
