import React from 'react';

const ModalConfirm = props =>  {
  const {title
      , confirmMessage
      , messageOk
      , messageCancel
      , handleClose
      , handleOk
      , data} = props
  return (
    <div className="modal-dialog" role="document">
      <div className="modal-content">
        <form>
          <div className="modal-header">
            <h4 className="modal-title" id="myModalLabel">{title}</h4>
          </div>
          <div className="modal-body">
            <div className="form-group confirmMessage">{confirmMessage}</div>
          </div>
          <div className="modal-footer">
            <button onClick={() => handleClose()} type="button" className="bt-black" data-dismiss="modal">{messageCancel}</button>
            <button onClick={() => { handleOk(data); handleClose(); }} type="button" className="bt-orange mg-l-5" data-dismiss="modal">{messageOk}</button>
          </div>
        </form>
      </div>
    </div>
  );
}

ModalConfirm.defaultProps = {};

ModalConfirm.propTypes = {

};

export default ModalConfirm;