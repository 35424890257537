/* eslint-disable react/self-closing-comp */

import React from 'react';
import _ from 'lodash';
import I18n from 'i18n-js';
import moment from 'moment';
import PropTypes from 'prop-types';
import TableList from '../../../components/common/datatables/TableList';
import { PDFExport } from '@progress/kendo-react-pdf';

import { logoDataImage } from '../../../stylesheets/js/base64resources';
import PDFIcon from '../../../stylesheets/images/PDF-icon.png';
class PreviewPurchaseOrder extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        { name: I18n.t('PageYield.Material'), value: 'material', width: 100 },
        { name: I18n.t('PageYield.Content'), value: 'content', width: 120 },
        { name: I18n.t('PageYield.Color'), value: 'color', width: 60 },
        { name: I18n.t('PageYield.Size'), value: 'size', width: 60 },
        { name: I18n.t('PageYield.ReplacementPO'), value: 'replacementPO', width: 90 },
        { name: I18n.t('PageShiping.Unit'), value: 'unit', width: 50 },
        { name: I18n.t('PageShiping.XMIL'), value: 'XMIL', type: 'date', formatType: 'MMM DD, YYYY', width: 90 },
        { name: I18n.t('PageShiping.ETD'), value: 'ETD', type: 'date', formatType: 'MMM DD, YYYY', width: 90 },
        { name: I18n.t('PageShiping.ETA'), value: 'ETA', type: 'date', formatType: 'MMM DD, YYYY', width: 90 },
        {
          name: I18n.t('PagePurchase.ExpectedReceivedDate'),
          value: 'exReceivedDate',
          type: 'date',
          formatType: 'MMM DD, YYYY',
          width: 120,
        },
        { name: I18n.t('PagePurchase.Payer'), value: 'payer', width: 80 },
        { name: I18n.t('PagePurchase.Totalqly'), value: 'quantity', width: 80 },
        { name: I18n.t('PageShiping.Price'), value: 'price', options: { clsTd: 'word-break-word' }, width: 100 },
        { name: I18n.t('PageShiping.TotalPrice'), value: 'amount', options: { clsTd: 'red-price' }, width: 130 },
      ],
    };
  }

  exportPDFWithComponent() {
    this.pdfExportComponent.save();
  }

  render() {
    const { supplierData, items, sender } = this.props;
    const { columns } = this.state;
    const payer = _.get(items[0], ['payer'], '');
    const billToIsShowed = payer === 'FI';
    const orderDate = moment(_.get(this.props, ['order', 'createdAt'], ''));
    const purchaseNo = _.get(this.props, 'purchaseNo', '');
    const customerPO = _.get(this.props, ['order', 'customerPO'], null);
    // -- get the min ExReceivedDate of material exReceivedDate items -->
    const revDateI = items.reduce(
      (minExReceivedDate, { exReceivedDate }) =>
        exReceivedDate < minExReceivedDate ? exReceivedDate : minExReceivedDate,
      null
    );
    const revDateIFormatted = moment(revDateI);
    return (
      <div id="PreviewPurchaseOrder" className="container-fluid">
        <span
          className="export cursor-pointer"
          onClick={this.exportPDFWithComponent.bind(this)}
          style={{
            display: 'inline-block',
            fontSize: 14,
            position: 'absolute',
            top: '20px',
            right: '150px',
            cursor: 'pointer',
          }}>
          Export <img src={PDFIcon} alt="" style={{ position: 'relative', top: 5, width: 21 }} />
        </span>
        <PDFExport
          ref={component => (this.pdfExportComponent = component)}
          paperSize="A4"
          fileName={`PO ${purchaseNo} ${customerPO || ''}.pdf`}
          landscape
          pageTemplate={({ pageNum, totalPages }) => {
            return <div className="page-number">{`${pageNum} / ${totalPages}`}</div>;
          }}
          margin={0}
          scale={0.7}>
          <div className="preview-content">
            <div className="header-wrapper">
              <div className="header-block block-logo">
                <div>
                  <img src={logoDataImage} alt="logo" />
                </div>
                <p>8851 GARDEN GROVE BLVD, STE 103 GARDEN GROVE, CA 92844</p>
                <p>Tel: 657-263-4401</p>
              </div>
              <div className="header-block block-pri_info">
                <div className="header-row_info">
                  <div className="row-label">P/I No.</div>
                  <div className="row-value">{purchaseNo}</div>
                </div>
                <div className="header-row_info">
                  <div className="row-label">P/O No.</div>
                  <div className="row-value">{customerPO}</div>
                </div>
                <div className="header-row_info">
                  <div className="row-label">Date:</div>
                  <div className="row-value">{orderDate.isValid() ? orderDate.format('ll') : '-'}</div>
                </div>
                <div className="header-row_info">
                  <div className="row-label">Rev Date I</div>
                  <div className="row-value text-red">
                    {revDateIFormatted.isValid() ? revDateIFormatted.format('ll') : '-'}
                  </div>
                </div>
                <div className="header-row_info">
                  <div className="row-label">Rev Date II</div>
                  <div className="row-value text-red"></div>
                </div>
                <div className="header-row_info">
                  <div className="row-label">Currency:</div>
                  <div className="row-value">{_.get(supplierData, 'currency', '-')}</div>
                </div>
              </div>
            </div>
            <div className="header-title">purchase order</div>
            <div className="contact-wrapper">
              <div className={billToIsShowed ? 'contact-block' : 'hide'}>
                <h3 className="contact-title">BILL TO</h3>
                <div className="contact-block-content" style={{ height: '220px' }}>
                  <p className="contact-field">
                    <span className="field-name">Name:</span>Kristine Bui
                  </p>
                  <p className="contact-field">
                    <span className="field-name">Company:</span>FASHION IMPORT, INC
                  </p>
                  <p className="contact-field">
                    <span className="field-name">Address:</span> 8851 GARDEN GROVE BLVD, STE 103 GARDEN GROVE, CA 92844
                  </p>
                  <p className="contact-field">
                    <span className="field-name">Tel:</span>657-263-4401
                  </p>
                  <p className="contact-field">
                    <span className="field-name">Fax:</span>
                  </p>
                  <p className="contact-field">
                    <span className="field-name">Email:</span>
                    {`kbui@fashionimports.us, vu@fashionimports.vn`}
                  </p>
                </div>
              </div>

              <div className="contact-block">
                <h3 className="contact-title">PURCHASE FROM</h3>
                <div className="contact-block-content" style={{ height: '220px' }}>
                  <p className="contact-field">
                    <span className="field-name">Name:</span>
                    <span>{_.get(supplierData, 'name', '')}</span>
                  </p>
                  <p className="contact-field">
                    <span className="field-name">Company:</span>
                    <span>{_.get(supplierData, ['company', 'name'], '')}</span>
                  </p>
                  <p className="contact-field">
                    <span className="field-name">Address:</span>
                    <span>{_.get(supplierData, ['company', 'address'], '')}</span>
                  </p>
                  <p className="contact-field">
                    <span className="field-name">Tel:</span>
                    <span>{_.get(supplierData, ['phoneNumber'], '')}</span>
                  </p>
                  <p className="contact-field">
                    <span className="field-name">Fax:</span>
                    <span>{_.get(supplierData, ['fax'], '')}</span>
                  </p>
                  <p className="contact-field">
                    <span className="field-name">Email:</span>
                    <span>{_.get(supplierData, ['email'], '')}</span>
                  </p>
                </div>
              </div>

              <div className="contact-block">
                <h3 className="contact-title">SHIP TO</h3>
                <div className="contact-block-content" style={{ height: '220px' }}>
                  <p className="contact-field">
                    <span className="field-name">Name:</span>NGUYEN THI HAI YEN
                  </p>
                  <p className="contact-field">
                    <span className="field-name">Company:</span>GIA BAO F.E CO., LTD
                  </p>
                  <p className="contact-field">
                    <span className="field-name">Address:</span>1042 Lac Long Quan St., Ward 8 Tan Binh District, Ho Chi
                    Minh City, VN
                  </p>
                  <p className="contact-field">
                    <span className="field-name">Tel:</span>(84) 8-23754-0905
                  </p>
                  <p className="contact-field">
                    <span className="field-name">Fax:</span>(84) 8-23754-2404
                  </p>
                  <p className="contact-field">
                    <span className="field-name">Email:</span>
                    mila@fashionimports.vn
                  </p>
                </div>
              </div>
            </div>
            <div className="list-wrapper">
              <TableList {...this.props} items={items || []} columns={columns || []} tId="purchase-table" />
            </div>
            <div className="list-footer">
              <div className="gtotal">
                Remark: <span>{this.props.remark}</span>
              </div>
              <div className="gtotal">
                G-Total: <span>{this.props.gTotal}</span>
              </div>
            </div>
          </div>
        </PDFExport>
      </div>
    );
  }
}

PreviewPurchaseOrder.propTypes = {
  customerPO: PropTypes.string,
  style: PropTypes.string,
  purchaseNo: PropTypes.string,
  gTotal: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.any),
  supplierData: PropTypes.objectOf(PropTypes.any),
  order: PropTypes.objectOf(PropTypes.any),
  remark: PropTypes.string,
  createdAt: PropTypes.string,
};

PreviewPurchaseOrder.defaultProps = {};

export default PreviewPurchaseOrder;
