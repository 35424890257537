import {createSelector} from 'reselect';
import moment from 'moment';
import _ from 'lodash';
import * as R from 'ramda';
import {genDollarToText} from '../../../functions/helpers';
import {getInvoice, getTotalAmount} from "./editInvoiceSupplierDomestic";
import editInvoiceSupplierOversea from "../../../constants/accounting/payable/editInvoiceSupplierOversea";

export const getListPaymentMethod = () => {
    return (
        [
            {
                value: 'ACH PAYMENT',
                label: 'ACH PAYMENT'
            },
            {
                value: 'WIRE',
                label: 'WIRE'
            },
            {
                value: 'CREDIT CARD',
                label: 'CREDIT CARD'
            },
            {
                value: 'OTHER',
                label: 'OTHER'
            },
        ]
    )
}


export const getInvoiceDetail = ({editInvoiceSupplierOversea}) => editInvoiceSupplierOversea.invoiceDetail || {};

export const getInvoiceType = ({editInvoiceSupplierOversea}) => editInvoiceSupplierOversea.typeInvoice;

export const getConstSupplierName = ({editInvoiceSupplierOversea}) => editInvoiceSupplierOversea.constSupplierName;

export const getListSupplier = ({editInvoiceSupplierOversea}) => editInvoiceSupplierOversea.listSupplier;

export const getSupplier = createSelector (
    [
        getInvoiceDetail
    ], (data) => {
        return data.invoiceDetail && data.invoiceDetail.supplier.name
    }
)

export const getIssuedDate = createSelector (
    [
        getInvoiceDetail
    ], (data) => {
        return data.issuedDate
    }
)

export const getFactor = createSelector (
    [
        getInvoiceDetail
    ], data => {
        return data.invoiceDetail && data.invoiceDetail.factor
    }
)

export const getInvoiceNo = createSelector (
    [
        getInvoiceDetail
    ], (data) => {
        return data.invNo
    }
)

export const getDueDate = createSelector (
    [
        getInvoiceDetail
    ], data => {
        return data.dueDate
    }
)

export const getCurrency = createSelector (
    [
        getInvoiceDetail
    ], data => {
        return data.invoiceDetail && data.invoiceDetail.supplier.currency;
    }
)

export const getCurrencyTypeUSDOfSupplier = createSelector(
    [
        getCurrency
    ], (currency) => {
        return currency && currency === "USD" ? '$' : '';
    }
)

export const getCurrencyTypeVNDOfSupplier = createSelector(
    [
        getCurrency
    ], (currency) => {
        return currency && currency === "VND" ? ' VND' : '';
    }
)

export const getPaymentTerm = createSelector(
    [getIssuedDate, getDueDate],
    (issuedDate, dueDate) => {
      let oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
      let firstDate = new Date(issuedDate);
      let secondDate = new Date(dueDate);
      return `${Math.round(
        Math.abs((firstDate.getTime() - secondDate.getTime()) / oneDay)
      )} days`;
    }
  );

export const getCreditDebit = createSelector ( 
    [
        getInvoiceDetail
    ], data => {
        return data.invoiceDetail && data.invoiceDetail.creditDebit && data.invoiceDetail.creditDebit || ''
    }
);

export const getTransactionNo = ({editInvoiceSupplierOversea}) => editInvoiceSupplierOversea.transactionNo;

export const getPaymentMethod = ({editInvoiceSupplierOversea}) => editInvoiceSupplierOversea.paymentMethod;

export const getPaymentType = ({editInvoiceSupplierOversea}) => editInvoiceSupplierOversea.paymentType;

// export const getListCreditDebit = ({editInvoiceSupplierOversea}) => editInvoiceSupplierOversea.listCreditDebit;

export const getListCreditDebit = ({ editInvoiceSupplierOversea }) => {
    const { listCreditDebit } = editInvoiceSupplierOversea;
    return _.map(listCreditDebit, item => ({
        label: R.pathOr('', ['code'], item),
        value: R.pathOr('', ['_id'], item),
        raw: item,
    }));
}

export const getAmount = createSelector (
    [
        getInvoiceDetail, getCreditDebit
    ], (data, creditDebit) => {
        if (creditDebit && creditDebit.amount) {
            if (creditDebit.type == "Debit") {
                return (data.amount || 0)  - creditDebit.amount
            } else {
                return (data.amount || 0)  + creditDebit.amount
            }
        }
        return data.amount || 0;
    }
)

export const getPaid = createSelector (
    [
        getInvoiceDetail
    ], (data) => {
        return data.paid || 0
    }
)

export const getRemaining = createSelector(
    [
        getAmount,
        getPaid
    ], (amount, paid) => {
        return amount - paid
    }
)




export const getListCheckablePurchaseOrder = ({editInvoiceSupplierOversea}) => {
    if(editInvoiceSupplierOversea.listPurchaseOrderDetail) {
        const purchaseOrders = editInvoiceSupplierOversea.listPurchaseOrderDetail;
        return purchaseOrders.map(item => ({_id: item._id, name: (item.purchaseOrder || {}).purchaseNo, checked: item.checked}))
    }
}

const generateModelPurchaseOrder = (model) => {
        if (!model) return {};
        return  new Proxy(model, {
            get(obj, prop) {
                switch (prop) {
                    case 'raw':
                        return obj;
                    default:
                        return obj[prop];
                }
            },
        });
      };

export const getListPurchaseOrderDetail = ({editInvoiceSupplierOversea}) => {
    if(editInvoiceSupplierOversea.listPurchaseOrderDetail) {
        const purchaseOrders = editInvoiceSupplierOversea.listPurchaseOrderDetail;
        purchaseOrders.map(item => item.materials.map( m => m.amount = _.round(((m.orderQuantity || 0) * (m.price || 0)), 2)));
        purchaseOrders.map(item => item.totalPurchase = _.round(_.sum(item.materials.map(m => (m.amount || 0))), 2))
        return purchaseOrders.filter(item => item.checked === true)
    }
}

export const getPayAmount = ({editInvoiceSupplierOversea}) => editInvoiceSupplierOversea.payAmount;

export const getFilesSaved = createSelector(
    [
        getInvoiceDetail
    ], data => {
        return data.files || [];
    }
)

export const getTransferFiles = ({ editInvoiceSupplierOversea }) => {
    const { transferFiles } = editInvoiceSupplierOversea;
    return _.map(transferFiles, file => ({
      id: R.path(['id'], file),
      url: R.path(['url'], file),
      raw: file,
      type: _.split(R.path(['id'], file), '.')[1],
    }));
}

export const fnGenerateRequestData = createSelector(
    [
        getInvoiceDetail, getAmount
    ], (editInvoiceSupplierOversea, totalAmount) => {
        const {paymentType, payAmount, listPurchaseOrderDetail = []} = _.cloneDeep(editInvoiceSupplierOversea);
        let { invoiceDetail} = _.cloneDeep(editInvoiceSupplierOversea);
        if(_.isEmpty(invoiceDetail)) return {};
        let data = _.omit(invoiceDetail, ['createdAt', 'createdBy', 'updatedAt', 'updatedBy', 'paymentHistory']);
        data._id = editInvoiceSupplierOversea._id;
        let receipt;
        if(paymentType === 'transfer') {
            data = _.omit(data, ['receipt']);
        } else {
            receipt = data.receipt
            data = _.omit(data, ['files', 'receipt']);
        }
        invoiceDetail.totalAmount = totalAmount;
        const purchaseOrders = listPurchaseOrderDetail.map( item => ({
            purchaseOrder: (item.purchaseOrder || {})._id,
            materials: item.materials.map( m => ({
                ...m,
                material:m._id,
                receiveQuantity: m.orderQuantity,
            }))
        }))
        // (data ||{}).invoiceDetail.purchaseOrders = purchaseOrders;
        _.assign((invoiceDetail || {}).purchaseOrders, purchaseOrders)
        let detailOfInvoice = _.omit(invoiceDetail, ['createdAt', 'createdBy', 'updatedAt', 'updatedBy'])
        return ({...data, invoiceDetail: detailOfInvoice, payment: {receipt, paymentType: _.startCase(paymentType), paid: (payAmount * 1)}})
    }
)

export const getReceiptFromInvoice = createSelector (
    [
        getInvoiceDetail,
        getPayAmount,
        getSupplier,
    ], (data, payAmount, address) => {
        return ({...data.receipt, total: payAmount, date: moment().format(), address})
    }
)

export const getReceiptDataFromRawData = ({editInvoiceSupplierOversea}) => ({...editInvoiceSupplierOversea.receipt, say: genDollarToText(editInvoiceSupplierOversea.receipt.total)})

export const getEmptyReceipt = createSelector(
    [
        getReceiptFromInvoice
    ], data => {
        return data ? !data.isEdit : false;
    }
)

export const getSuccess = ({editInvoiceSupplierOversea}) => editInvoiceSupplierOversea.success;

export const getDisableChangeValue = createSelector (
    [
      getPaid
    ], paid => {
      if(paid > 0) return true;
      return false;
    }
)

export const getActiveScreenStatus = ({editInvoiceSupplierOversea}) => editInvoiceSupplierOversea.activeScreen;