import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import BaseComponent from '../../base/BaseComponent';
import I18n from 'i18n-js';
import List from "../../common/datatables/List";
import CanceledView from '../canceled/CanceledView';
import {bindActionCreators} from "redux";
import appActions from "../../../actions/appActions";
import orderServices from "../../../services/orderServices";
import orderActions from "../../../actions/orderActions";
import SearchBar from '../../common/layout/SearchBar';
import {CUSTOMER_PO_KEY, SEARCH_LIST, SEARCH_BAR_EVENT, PURCHASE_STATUS} from "../../../constants/constants";

class ScreenCanceledOrders extends BaseComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {
      pageNumber: 1,
      pageSize: 10,
      search: {key: CUSTOMER_PO_KEY.CUSTOMER_PO, value: ''},
      headers: [
        {name: I18n.t('PageOrder.Canceled'), type: 'date', dataType: 'MMM DD, YYYY', value: 'updatedAt', format: 'MMM DD, YYYY'},
        {name: I18n.t('PageOrder.CustomerPO'), type: 'text', dataType: 'text', value: 'customerPO'},
        {name: I18n.t('PageOrder.SaleOrder'), type: 'text', dataType: 'text', value: 'internalPO'},
        {name: I18n.t('PageOrder.Customer'), type: 'text', dataType: 'text', value: '{{data}}.customer.name'},
        {name: I18n.t('PageOrder.G-Total'), type: 'text', dataType: 'text', value: 'gTotal', options: {clsTd: 'color-number center' }},
        {name: I18n.t('PageOrder.OrderDate'), type: 'date', dataType: 'MMM DD, YYYY', value: 'orderDate', format: 'MMM DD, YYYY'},
        {name: I18n.t('PageOrder.Ex-Factory'), type: 'date', dataType: 'MMM DD, YYYY', value: 'exFactory', format: 'MMM DD, YYYY'},
        {name: I18n.t('PageOrder.IHDate'), type: 'date', dataType: 'MMM DD, YYYY', value: 'ihDate', format: 'MMM DD, YYYY'},
      ],
      orders: []
    };
    this.setPage = this.setPage.bind(this)
  }

  componentWillMount() {
    let {orderState} = {...this.props};
    orderState = orderState || {}
    this.getCompletedOrders(orderState.items);
    const size = this.state.pageSize;
    const number = this.state.pageNumber;
    this.getList(size, number);
  }

  componentWillReceiveProps(nextProps) {
    const {orderState} = this.props;
    if(!_.isEqual(orderState, nextProps.orderState)) {
      this.getCompletedOrders(nextProps.orderState.items);
    }
  }
 
  view(order) {
    this.setState({ selectedOrder: order });
    this.props.appActions.setDataPageCurrent({context: {tabSub: 'view'}, isEdit: true});
  }

  setPage(page){
    this.setState({pageNumber: page});
  }

  getOrderByPageNumber(orders, pageNumber) {
    const min = 10 * (pageNumber - 1);
    const max = 10 * pageNumber - 1;
    const pageOrder = (orders || []).filter((item, index) => index >= min && index <=max  )
    return pageOrder;
  }

  handlePagination(number) {
    const size = this.state.pageSize;
    this.getList(size, number)
  }

  getList(size, number) {
    const query = { pageSize: size, pageNumber: number, isCancel: true, sort: {updatedAt: -1}};
    this.props.loadData(_.assign(query));
  }
  
  onSearch = () => {
    const {search} = this.state;
    const option = { isCancel: true, sort: {updatedAt: -1}, filter: true };
    option[search.key] = search.value;
    orderServices.getListOrder(option).then(res => {
      this.setState({ orders: res.data.items });
    })
  }

  onChangeSearch = (property, value) => {
    switch(property) {
      case SEARCH_BAR_EVENT.FILTER: {
        this.setState({search: {key: value, value: ""}});
        break;
      }
      case SEARCH_BAR_EVENT.SEARCH: {
        this.setState({search: {...this.state.search, value: value}});
        break;
      }
    }
  }

  getCompletedOrders(orders) {
    this.setState({orders});
  }

  render() {
    const { appState, orderState } = this.props;
    const pageCurrent = appState.page || {};

    let show = !!pageCurrent.isEdit;

    let style = {
      width: "100%"
    };

    const fields = SEARCH_LIST.CUSTOMER_PO_SEARCH_LIST

    const {orders, search, headers, pageNumber, pageSize} = this.state;
    const actions = [
      (data, user) => {
        return {
          type: 'view',
          func: (data) => this.view(data),
          isActive: true,
          toolTip: 'view'
        };
      },
    ];
    const pageOrder = this.getOrderByPageNumber(orders, pageNumber);

    return show ? (
      <CanceledView order={this.state.selectedOrder} />
    ) : (
      <div>
        <div className="wrap-customer-history">
          <div className="wrap-global-table">
            <SearchBar 
              fields={fields} 
              searchText = {search.value}
              onChangeSearch={(property, value) => this.onChangeSearch(property, value)} 
              onSearch={() => this.onSearch()}
            />
            <List {...this.props} options={{
              textTotal: 'purchases',
              setPage: this.setPage,
              totalPage: orderState.count,
              pageNumber: pageNumber,
              perPage: pageSize,
              headers,
              data: orders,
              actions,
              setSortPage: ()=>{},
              className: 'center'
            }}
            handlePagination={(e) => this.handlePagination(e)}/>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    appState: state.appState,
    authState: state.authState,
    orderState: state.orderState
  };
}

function mapDispatchToProps(dispatch) {
  return {
    appActions: bindActionCreators(appActions, dispatch),
    loadData(options) {
      dispatch(orderActions.getListOrder(options));
    }
  };
}

ScreenCanceledOrders.propTypes = {
  authState: PropTypes.object.isRequired,
  orderState: PropTypes.object,
  appActions: PropTypes.object,
  viewOrder: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(ScreenCanceledOrders);

