/**
 * @author hienhc <hienhc@nexlesoft.com>
 * @create date 2018-08-31 01:35:30
 */
import { combineReducers } from 'redux';
import _ from 'lodash';
import { refineQuantity } from '../../functions/utils';
import C from '../../constants/ship-out/createPklShip';
import C2 from '../../constants/ship-out/editPklShip';

const fetching = (state = false, action) => {
  const { type } = action;
  switch (type) {
    case C.FETCH_PKL_PROD_LIST_DETAIL:
      return true;
    case C.CANCEL_FETCH_PKL_PROD_LIST_DETAIL:
      return false;
    default:
      return state;
  }
};

/**
 * Raw data
 */
const pklProds = (state = [], action) => {
  const { type, payload } = action;
  switch (type) {
    case C.CHANGE_PKL_PROD_LIST_DETAIL:
      return payload;
    case C2.CHANGE_PKL_SHIP_DETAIL: {
      return payload.pklProds.map(item => {
        const packing = item.packing;
        if (packing) {
          packing.commodity = item.commodity;
          packing.order.self = item.self;
        }
        return packing;
      });
    }
    case C.REMOVE_STYLE: {
      const { orderId, styleId } = payload;
      const rawCopy = _.cloneDeep(state);
      const orderItem = _.find(rawCopy, it => it._id === orderId);
      if (orderItem) {
        orderItem.styles = orderItem.styles.filter(it => it.style._id !== styleId);
      }
      return rawCopy;
    }
    case C.CHANGE_COMMODITY: {
      const { orderId, value } = payload;
      const rawCopy = _.cloneDeep(state);
      const orderItem = _.find(rawCopy, it => it._id === orderId);
      if (orderItem) {
        orderItem.commodity = value;
      }
      return rawCopy;
    }
    case C.RESET_DATA:
      return [];
    default:
      return state;
  }
};

/**
 * Raw data
 */
const actions = (state = [], action) => {
  const { type, payload } = action;
  switch (type) {
    case C.CHANGE_PKL_PROD_LIST_DETAIL:
      return payload.pklProds
        ? payload.pklProds.reduce((x, pklProd) => {
            pklProd.packing.styles.forEach(s => {
              const style = pklProd.styles.find(s2 => s.style._id == s2.style._id);
              s.packings.forEach(pk => {
                const pkFilter = style.packings.filter(pk2 => pk2.props.group == pk.props.group);
                const key = `${pklProd.packing._id}-${s.style._id}-${pk.props.group}`;
                if (pkFilter.length == 0 && !x[key]) {
                  x[key] = { check: false };
                } else if (pkFilter.length > 0) {
                  const pkFind = pkFilter.find(pk2 => pk2.props.no == pk.props.no);
                  if (pkFind) {
                    const action = x[key];
                    if (action) {
                      action.package += 1;
                      action.nos.push(pk.props.no);
                    } else {
                      x[key] = { package: 1, nos: [pk.props.no] };
                    }
                  }
                }
              });
            });
            return x;
          }, {})
        : {};
    case C2.CHANGE_PKL_SHIP_DETAIL:
      return payload.pklProds
        ? payload.pklProds.reduce((x, pklProd) => {
            pklProd.packing.styles.forEach(s => {
              const style = pklProd.styles.find(s2 => s.style._id == s2.style._id);
              s.packings.forEach(pk => {
                const pkFilter = style.packings.filter(pk2 => pk2.props.group == pk.props.group);
                const key = `${pklProd.packing._id}-${s.style._id}-${pk.props.group}`;
                if (pkFilter.length == 0 && !x[key]) {
                  x[key] = { check: false };
                } else if (pkFilter.length > 0) {
                  const pkFind = pkFilter.find(pk2 => pk2.props.no == pk.props.no);
                  if (pkFind) {
                    const action = x[key];
                    if (action) {
                      action.package += 1;
                      action.nos.push(pk.props.no);
                    } else {
                      x[key] = { package: 1, nos: [pk.props.no] };
                    }
                  }
                }
              });
            });
            return x;
          }, {})
        : {};
    default:
      return {};
  }
};

/**
 * Raw data
 */
const pklShips = (state = [], action) => {
  const { type, payload } = action;
  switch (type) {
    case C.CHANGE_PKL_PROD_LIST_DETAIL:
      return payload;
    case C2.CHANGE_PKL_SHIP_DETAIL:
      return payload;
    default:
      return state;
  }
};

/**
 * Track active orders
 */
const activeOrders = (state = [], action) => {
  const { type, payload } = action;
  switch (type) {
    case C.CHANGE_PKL_PROD_LIST_DETAIL: {
      // Active first order after reload data
      // console.log(payload);
      const [item] = payload;
      return item ? [item._id] : [];
    }
    case C2.CHANGE_PKL_SHIP_DETAIL: {
      // Active first order after reload data
      // console.log(payload);
      const [item] = payload.pklProds;
      return item && item.packing ? [item.packing._id] : [];
    }
    case C.CHANGE_ACTIVE_ORDER: {
      const { orderId, value } = payload;
      let filtered = _.cloneDeep(state).filter(it => it !== orderId);
      if (value) filtered = [...filtered, orderId];
      return filtered;
    }
    case C.RESET_DATA:
      return [];
    default:
      return state;
  }
};

/**
 * Track active style of each order
 */
const activeStyles = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case C.CHANGE_PKL_PROD_LIST_DETAIL: {
      const dic = {};
      payload.map(it => {
        // Active one style of each order if found one
        const [style] = it.styles;
        dic[it._id] = style ? style.style._id : null;
      });
      return dic;
    }
    case C2.CHANGE_PKL_SHIP_DETAIL: {
      const dic = {};
      payload.pklProds.map(it => {
        // Active one style of each order if found one
        const [style] = it.packing ? it.packing.styles : [];
        if (it.packing) dic[it.packing._id] = style ? style.style._id : null;
      });
      return dic;
    }
    case C.CHANGE_ACTIVE_STYLE: {
      const { orderId, styleId } = payload;
      const dic = _.cloneDeep(state);
      dic[orderId] = styleId;
      return dic;
    }
    case C.REMOVE_STYLE: {
      const { orderId, styleId } = payload;
      const dic = _.cloneDeep(state);
      if (dic[orderId] === styleId) dic[orderId] = null;
      return dic;
    }
    case C.RESET_DATA:
      return {};
    default:
      return state;
  }
};

/**
 * Track temporary packing size and bar number
 */
const packingInputs = (state = {}, action) => {
  const { type, payload } = action;
  let dic = {};
  let orderId, styleId, cutTicketId, colorId, sizeName, value;
  let inputGroup = null;
  let key = '';
  switch (type) {
    case C.CHANGE_PKL_PROD_LIST_DETAIL: {
      if (payload[0] && payload[0].type === 'Carton') return {};
      dic = {};
      payload.map(item => {
        item.styles.map(style => {
          style.cutTickets.map(cutTicket => {
            cutTicket.colors.map(color => {
              const key = `${item._id}:${style.style._id}:${cutTicket.cutTicket._id}:${color.color._id}`;
              const [packing] = color.packings;
              const planPackings = packing ? packing.items : color.color.sizes;
              dic[key] = {
                sizeInputs: _.map(color.color.sizes, size => ({
                  name: size.name,
                  value: 0,
                })),
                barInput: 0,
                dataset: [],
                addableQuantities: _.map(planPackings, size => ({
                  name: size.name,
                  value: size.planQuantity || 0,
                })),
              };
            });
          });
        });
      });
      return dic;
    }
    case C2.CHANGE_PKL_SHIP_DETAIL: {
      if (payload.pklProds[0] && payload.pklProds[0].type === 'Carton') return {};
      dic = {};
      payload.pklProds.map(item => {
        item.packing
          ? item.packing.styles.map(style => {
              const styleData = item.styles.find(s => s.style._id == style.style._id);
              style.cutTickets.map(cutTicket => {
                const cutTicketData = styleData
                  ? styleData.cutTickets.find(c => c.cutTicket._id == cutTicket.cutTicket._id)
                  : null;
                cutTicket.colors.map(color => {
                  const colorData = cutTicketData
                    ? cutTicketData.colors.find(c => c.color._id == color.color._id)
                    : null;
                  const key = `${item.packing._id}:${style.style._id}:${cutTicket.cutTicket._id}:${color.color._id}`;
                  const [packing] = color.packings;
                  const planPackings = packing ? packing.items : color.color.sizes;
                  dic[key] = {
                    sizeInputs: _.map(color.color.sizes, size => ({
                      name: size.name,
                      value: 0,
                    })),
                    barInput: 0,
                    dataset: colorData
                      ? colorData.packings.map(p => ({
                          bar: p.props.no,
                          packings: _.map(color.color.sizes, size => ({
                            name: size.name,
                            value: _.get(
                              p.items.find(i => i.name == size.name),
                              'planQuantity',
                              0
                            ),
                          })),
                          // p.items.map(i => ({name: i.name, value: i.planQuantity}))
                          error: false,
                        }))
                      : [],
                    addableQuantities: _.map(planPackings, size => ({
                      name: size.name,
                      value: size.planQuantity || 0,
                    })),
                  };
                });
              });
            })
          : item;
      });
      return dic;
    }

    case C.CHANGE_SIZE_PACKING: {
      orderId = payload.orderId;
      styleId = payload.styleId;
      cutTicketId = payload.cutTicketId;
      colorId = payload.colorId;
      sizeName = payload.sizeName;
      value = payload.value;
      dic = _.cloneDeep(state);
      key = `${orderId}:${styleId}:${cutTicketId}:${colorId}`;
      const { sizeInputs, addableQuantities, dataset } = dic[key] || {};
      const size = _.find(sizeInputs, it => it.name === sizeName);
      const addableQuantity = _.find(addableQuantities, q => q.name === sizeName);
      const remainingQty =
        addableQuantity.value - _.sum(_.map(dataset, it => _.find(it.packings, p => p.name === sizeName).value));
      size.value = _.min([refineQuantity(value), remainingQty]);
      return dic;
    }

    case C.CHANGE_BAR_NUMBER: {
      orderId = payload.orderId;
      styleId = payload.styleId;
      cutTicketId = payload.cutTicketId;
      colorId = payload.colorId;
      value = payload.value;
      dic = _.cloneDeep(state);
      key = `${orderId}:${styleId}:${cutTicketId}:${colorId}`;
      let inputGroup = dic[key];
      inputGroup.barInput = refineQuantity(value);
      return dic;
    }
    case C.ADD_BAR: {
      orderId = payload.orderId;
      styleId = payload.styleId;
      cutTicketId = payload.cutTicketId;
      colorId = payload.colorId;
      value = payload.value;
      key = `${orderId}:${styleId}:${cutTicketId}:${colorId}`;
      inputGroup = state[key];

      // Add bar valid when at least one size have value greater than 0, and bar number is not exist valid number
      const validSizes = !!_.find(inputGroup.sizeInputs, it => it.value > 0);
      const validBar = inputGroup.barInput > 0;
      if (!(validBar && validSizes)) return state;

      // Everything valid, then clone state to handle for the rest
      const dic = _.cloneDeep(state);
      inputGroup = dic[key];

      // Reset all error status
      _.map(inputGroup.dataset, item => {
        item.error = false;
      });

      // Check whether record with same bar number exist, if yes set error status
      const existItem = _.find(inputGroup.dataset, item => item.bar === inputGroup.barInput);
      if (existItem) {
        existItem.error = true;
        return dic;
      }

      // Otherwise add one record to dataset
      const { barInput, sizeInputs } = inputGroup;
      inputGroup.dataset = [...inputGroup.dataset, { bar: barInput, packings: _.cloneDeep(sizeInputs), error: false }];

      // and reset all related inputs
      inputGroup.barInput = 0;
      inputGroup.sizeInputs = _.map(inputGroup.sizeInputs, size => ({ name: size.name, value: 0 }));

      return dic;
    }
    case C.REMOVE_BAR:
      orderId = payload.orderId;
      styleId = payload.styleId;
      cutTicketId = payload.cutTicketId;
      colorId = payload.colorId;
      value = payload.value;
      key = `${orderId}:${styleId}:${cutTicketId}:${colorId}`;
      dic = _.cloneDeep(state);
      inputGroup = dic[key];
      _.remove(inputGroup.dataset, item => item.bar === value);
      return dic;
    case C.RESET_DATA:
      return {};
    default:
      return state;
  }
};

const success = (state = null, action) => {
  const { type, payload } = action;
  switch (type) {
    case C.RESET_DATA:
      return false;
    case C.CREATE_SUCCES:
      return payload;
    default:
      return state;
  }
};

const packedQuantities = (state = {}, action) => {
  const { type, payload } = action;
  let dic = {};
  switch (type) {
    case C.CHANGE_PKL_PROD_LIST_DETAIL: {
      if (payload[0] && payload[0].type === 'GOHH') return {};
      payload.map(item => {
        item.styles.map(style => {
          style.cutTickets.map(cutTicket => {
            cutTicket.colors.map(color => {
              const groups = _.groupBy(color.packings, pk => pk.props.group);
              const key = `${item._id}:${style.style._id}:${cutTicket.cutTicket._id}:${color.color._id}`;
              dic[key] = _.map(groups, (v, k) => {
                return {
                  checked: true,
                  quantity: v.length,
                  qtyCtn: _.sum(_.cloneDeep(v)[0].items.map(item => item.planQuantity)),
                  raw: _.cloneDeep(v),
                  group: k,
                  quantityData: v.length,
                };
              });
              //{checked: true, quantity: v.length, raw: v};
              // _.map(groups, (v, k) => {
              //     const key = `${item._id}:${style.style._id}:${cutTicket.cutTicket._id}:${color.color._id}:${k}`;
              //     dic[key] = {checked: true, quantity: v.length, raw: v};
              // });
            });
          });
        });
      });
      return dic;
    }
    case C2.CHANGE_PKL_SHIP_DETAIL: {
      if (payload.pklProds[0] && payload.pklProds[0].type === 'GOHH') return {};
      payload.pklProds.map(item => {
        item.packing
          ? item.packing.styles.map(style => {
              const styleData = item.styles.find(s => s.style._id == style.style._id);
              style.cutTickets.map(cutTicket => {
                const cutTicketData = styleData
                  ? styleData.cutTickets.find(c => c.cutTicket._id == cutTicket.cutTicket._id)
                  : null;
                cutTicket.colors.map(color => {
                  const colorData = cutTicketData
                    ? cutTicketData.colors.find(c => c.color._id == color.color._id)
                    : null;
                  const groupsData = colorData ? _.groupBy(colorData.packings, pk => pk.props.group) : null;
                  const groups = _.groupBy(color.packings, pk => pk.props.group);
                  const key = `${item.packing._id}:${style.style._id}:${cutTicket.cutTicket._id}:${color.color._id}`;
                  dic[key] = _.map(groups, (v, k) => {
                    const data = groupsData ? groupsData[k] : null;
                    return {
                      checked: data ? true : false,
                      quantity: data ? data.length : 0,
                      qtyCtn: _.sum(_.cloneDeep(v)[0].items.map(item => item.planQuantity)),
                      raw: _.cloneDeep(v),
                      group: k,
                      quantityData: v.length,
                    };
                  });
                  //{checked: true, quantity: v.length, raw: v};
                  // _.map(groups, (v, k) => {
                  //     const key = `${item._id}:${style.style._id}:${cutTicket.cutTicket._id}:${color.color._id}:${k}`;
                  //     dic[key] = {checked: true, quantity: v.length, raw: v};
                  // });
                });
              });
            })
          : item;
      });
      return dic;
    }
    case C.CHANGE_PACKED_QUANTITY: {
      const { orderId, styleId, cutTicketId, colorId, group, value } = payload;
      const key = `${orderId}:${styleId}:${cutTicketId}:${colorId}`;
      dic = _.cloneDeep(state);
      if (key in dic) {
        const dk = dic[key].find(item => item.group === group);
        dk.quantity = dk.raw.length >= value ? _.toInteger(value) : dk.quantity;
      }
      return dic;
    }
    case C.CHANGE_PACKED_CHECKED: {
      const { orderId, styleId, cutTicketId, colorId, group, value } = payload;
      const key = `${orderId}:${styleId}:${cutTicketId}:${colorId}`;
      dic = _.cloneDeep(state);
      if (key in dic) {
        dic[key].find(item => item.group === group).checked = value;
        dic[key].find(item => item.group === group).quantity = value
          ? dic[key].find(item => item.group === group).raw.length
          : 0;
      }
      return dic;
    }
    case C.RESET_DATA:
      return {};
    default:
      return state;
  }
};

const quantitiesConst = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case C.CHANGE_PKL_PROD_LIST_DETAIL:
      if (payload[0] && payload[0].type === 'GOHH') return {};
      const dic1 = {};
      payload.map(item => {
        item.styles.map(style => {
          style.cutTickets.map(cutTicket => {
            cutTicket.colors.map(color => {
              const groups = _.groupBy(color.packings, pk => pk.props.group);
              const key = `${item._id}:${style.style._id}:${cutTicket.cutTicket._id}:${color.color._id}`;
              dic1[key] = _.map(groups, (v, k) => {
                return {
                  checked: true,
                  quantity: v.length,
                  raw: v,
                  group: k,
                };
              });
              //{checked: true, quantity: v.length, raw: v};
              // _.map(groups, (v, k) => {
              //     const key = `${item._id}:${style.style._id}:${cutTicket.cutTicket._id}:${color.color._id}:${k}`;
              //     dic[key] = {checked: true, quantity: v.length, raw: v};
              // });
            });
          });
        });
      });
      return dic1;
    case C2.CHANGE_PKL_SHIP_DETAIL:
      if (payload.pklProds[0] && payload.pklProds[0].type === 'GOHH') return {};
      const dic2 = {};
      payload.pklProds.map(item => {
        item.packing
          ? item.packing.styles.map(style => {
              style.cutTickets.map(cutTicket => {
                cutTicket.colors.map(color => {
                  const groups = _.groupBy(color.packings, pk => pk.props.group);
                  const key = `${item.packing._id}:${style.style._id}:${cutTicket.cutTicket._id}:${color.color._id}`;
                  dic2[key] = _.map(groups, (v, k) => {
                    return {
                      checked: true,
                      quantity: v.length,
                      raw: v,
                      group: k,
                    };
                  });
                  //{checked: true, quantity: v.length, raw: v};
                  // _.map(groups, (v, k) => {
                  //     const key = `${item._id}:${style.style._id}:${cutTicket.cutTicket._id}:${color.color._id}:${k}`;
                  //     dic[key] = {checked: true, quantity: v.length, raw: v};
                  // });
                });
              });
            })
          : item;
      });
      return dic2;
    case C.RESET_DATA:
      return {};
    default:
      return state;
  }
};

export const createPklShip = combineReducers({
  success,
  fetching,
  activeOrders,
  activeStyles,
  pklProds,
  packingInputs,
  packedQuantities,
  quantitiesConst,
  pklShips,
  actions,
});
