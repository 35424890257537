import {
  getListDAL,
  deletePackingDAL,
  addPackingDAL,
  editPackingDAL,
  getPackingByOrder,
  getPacking,
  getPackingByOrderAndContractor,
  stockPackingAPI,
  reshipPackingAPI
} from '../api/packingApi';
import _ from 'lodash';

class packingServices {

  constructor() {
  }

  getList (options = {}) {
    options.populate = (options.populate ? `${options.populate}` : '');
    options.sort = options.sort || {createdAt: -1};
    _.set(options, 'sort', JSON.stringify(options.sort));

   return getListDAL(options);
  }

  deletePacking (id) {
    return deletePackingDAL(id);
  }

  addPacking (data) {
    return addPackingDAL(data);
  }

  editPacking (id, data) {
    return editPackingDAL(id, data);
  }

  getPackingByOrder = (id, options) => {
    return getPackingByOrder(id, options);
  }

  getPackingByOrderAndContractor = (oId, cId) => {
    return getPackingByOrderAndContractor(oId, cId);
  }

  getPacking = (id) => {
    return getPacking(id);
  }

  stockPacking = (id) => {
    return stockPackingAPI(id);
  }

  reshipPacking = (id) => {
    return reshipPackingAPI(id);
  }

}

export default new packingServices();
