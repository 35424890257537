import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { withRouter } from "react-router";

import * as PATH from "../../../../constants/routeConstants";

import CreatePlanSupplierDometic from "./CreatePlanSupplierDometic";
import CreatePlanSupplierOversea from "./CreatePlanSupplierOversea";

import CreatePlanIEContainer from './CreatePlanIEContainer';
import CreatePlanIEDeclaration from './CreatePlanIEDeclaration';
import CreatePlanIEUSDelivery from './CreatePlanIEUSDelivery';

import CreatePlanContractor from "./CreatePlanContractor";

const AccountsPayablePlan = (props) => (
  <Switch>
    <Route
      path={PATH.ACCOUNTING_PAYABLE_PLAN_URL}
      exact
      render={() => <Redirect to={PATH.ACCOUNTING_PAYABLE_PLAN_SUPPLIER_URL} />}
    />
    <Route
      path={PATH.ACCOUNTING_PAYABLE_PLAN_SUPPLIER_URL}
      exact
      render={() => (
        <Redirect to={PATH.ACCOUNTING_PAYABLE_PLAN_SUPPLIER_DOMESTIC_URL} />
      )}
    />
    <Route
      path={PATH.ACCOUNTING_PAYABLE_PLAN_SUPPLIER_DOMESTIC_URL}
      render={() => (<CreatePlanSupplierDometic {...props} />)}
    />
    <Route
      path={PATH.ACCOUNTING_PAYABLE_PLAN_SUPPLIER_OVERSEA_URL}
      render={() => (<CreatePlanSupplierOversea {...props} />)}
    />
    <Route
      path={PATH.ACCOUNTING_PAYABLE_PLAN_CONTRACTOR_URL}
      render={() => (<CreatePlanContractor {...props} />)}
    />
    <Route
      path={PATH.ACCOUNTING_PAYABLE_PLAN_IMPORTEXPORT_URL}
      render={() => (
        <Switch>
          <Route
            path={PATH.ACCOUNTING_PAYABLE_PLAN_IMPORTEXPORT_URL}
            exact
            render={() => (
              <Redirect
                to={PATH.ACCOUNTING_PAYABLE_PLAN_IMPORTEXPORT_CONTAINER_URL}
              />
            )}
          />
          <Route
            path={PATH.ACCOUNTING_PAYABLE_PLAN_IMPORTEXPORT_CONTAINER_URL}
            render={() => <CreatePlanIEContainer {...props} />}
          />
          <Route
            path={PATH.ACCOUNTING_PAYABLE_PLAN_IMPORTEXPORT_DECLARATION_URL}
            render={() => <CreatePlanIEDeclaration {...props} />}
          />
          <Route
            path={PATH.ACCOUNTING_PAYABLE_PLAN_IMPORTEXPORT_US_DELIVERY_URL}
            render={() => (<CreatePlanIEUSDelivery {...props} />)}
          />
        </Switch>
      )}
    />
  </Switch>
);

export default withRouter(AccountsPayablePlan);
