import {ROLE} from '../constants/actionTypes';
import initial from './initialState';

export default function (state = initial.roleState, action) {
  switch (action.type) {
    case ROLE.GET_LIST_SUCCESS:
      return {...state, items: action.data.items || [], count: action.data.count || 0};
    case ROLE.RELOAD:
      return {...state, reload: !state.reload};
    case ROLE.SET_DETAIL:
      return {...state, detail: action.data};
    case ROLE.GET_MODULES_SUCCESS:
      return {...state, modules: action.data};
    default:
      return state;
  }
}
