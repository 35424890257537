import {
    getListSupplierDAL, 
    getListCreditDebitDAL, 
    getListPurchaseOrderDAL , 
    getListInvoice , 
    getAmountInvoice , 
    getListInvoiceHistory , 
    getAmountInvoiceHistory,
    getPaidDetailInvoiceHistory, 
    getPaidDetailInvoice, 
    createInvoice, 
    deleteInvoice,
    getListContractorDAL,
    getListCustomerPODAL, 
    searchInvoiceList,
    getInvoiceDetail,
    uploadFile,
    updateInvoice,
} from '../api/accountingApi';
import _ from 'lodash';
class accountingServices {

    constructor() {
    }

    getListSupplier(params) {
        return getListSupplierDAL(params);
    }

    getListPurchaseOrder(params) {
        return getListPurchaseOrderDAL(params);
    }

    getListCreditDebit(params) {
        return getListCreditDebitDAL(params);
    }

    getListInvoice(params) {
        return getListInvoice(params);
    }

    getAmountInvoice(params) {
        return getAmountInvoice(params);
    }

    getListInvoiceHistory(params) {
        return getListInvoiceHistory(params);
    }

    getAmountInvoiceHistory(params) {
        return getAmountInvoiceHistory(params);
    }

    getPaidDetailInvoiceHistory(params) {
        return getPaidDetailInvoiceHistory(params);
    }

    getPaidDetailInvoice(params) {
        return getPaidDetailInvoice(params);
    }

    createInvoice(data) {
        return createInvoice(data)
    }

    deleteInvoice(param) {
        return deleteInvoice(param)
    }

    searchInvoiceList(query) {
        return searchInvoiceList(query)
    }

    getInvoiceDetail(id) {
        const op = {populate: "invoiceDetail"}
        return getInvoiceDetail(id, op)
    }

    getInvoiceContractorDetail(id) {
        const op = {populate: "invoiceDetail, invoiceDetail | contractor"}
        return getInvoiceDetail(id, op)
    }

    getInvoiceSupplierOversea(id) {
        const op = {populate: "invoiceDetail, invoiceDetail | supplier,invoiceDetail | purchaseOrders.purchaseOrder, invoiceDetail | purchaseOrders.materials.material"}
        return getInvoiceDetail(id, op)
    }

    getInvoiceSupplierDomestic(id) {
        const op = {populate: "invoiceDetail, invoiceDetail | supplier, invoiceDetail | creditDebit, invoiceDetail | purchaseOrders.purchaseOrder | materials.material, invoiceDetail | purchaseOrders.materials.material"}
        return getInvoiceDetail(id, op)
    }

    getListContractor(params) {
        return getListContractorDAL(params);
    }

    getListCustomerPO(params) {
        return getListCustomerPODAL(params);
    }

    updateInvoice(id, paymentType, data) {
        if(paymentType === 'transfer') {
            let promises = (data.files || []).map(i => {
                return i.raw.file
                  ? uploadFile(i.raw.file).then(res => _.get(res || {}, "data.filePath", ""))
                  : Promise.resolve(i);
            });
          
            return Promise.all(promises)
            .then(files => {
                data.payment.attachs = (files || []).filter(i => !!i);
                const temp = _.omit(data, ['files', '_id']);
                return updateInvoice(id, temp);
            })
            .catch(error => {
                throw error;
            });
        } else {
            return updateInvoice(id, data);
        }
        
    }
}

export default new accountingServices();
