import React from "react";
import moment from "moment";
import * as utils from "../../../../functions/utils";
import ReactExport from "react-data-export";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const CreditDebitViewDetail = props => {
    const { items, payInfo, summary } = props;
    let labeNo= 'No :';
    let valueNo = {value: payInfo.code || '', style: {font: {patternType: "solid", color: {rgb: "FFF86B00"}}}};

    let labeChargeTo= 'Charge To :';
    let valueChargeTo = {value: payInfo.customer ? payInfo.customer.name : '', style: {font: {patternType: "solid", color: {rgb: "FFF86B00"}}}};

    let labeAmount= 'Amount :';
    let valueAmount = {value: payInfo.amount + ' ' + payInfo.currency, style: {font: {patternType: "solid", color: {rgb: "FFF86B00"}}}};

    let labeDate= 'Date:';
    let valueDate = {value: moment(payInfo.createdAt).format('MMM DD YYYY'), style: {font: {patternType: "solid", color: {rgb: "FFF86B00"}}}};

    let labeIssuedBy= 'Issued by :';
    let valueIssuedBy = {value: payInfo.createdBy ? payInfo.createdBy.fullName : '', style: {font: {patternType: "solid", color: {rgb: "FFF86B00"}}}};

    let header = [labeNo, valueNo, labeChargeTo, valueChargeTo, labeAmount, valueAmount, labeDate, valueDate, labeIssuedBy, valueIssuedBy];
    
    let totalString = {value: "Total", style: {font: {patternType: "solid", color: {rgb: "FFF86B00"}}}};
    let cbm = {value: summary.cbm || 0, style: {font: {patternType: "solid", color: {rgb: "FFF86B00"}}}};
    let ctns = {value: summary.ctns || 0, style: {font: {patternType: "solid", color: {rgb: "FFF86B00"}}}};
    let quantity = {value: summary.quantity || 0, style: {font: {patternType: "solid", color: {rgb: "FFF86B00"}}}};
    let chargeableWeight = {value: summary.chargeableWeight || 0, style: {font: {patternType: "solid", color: {rgb: "FFF86B00"}}}};
    let airFee = {value: summary.airFee || 0, style: {font: {patternType: "solid", color: {rgb: "FFF86B00"}}}};
    let debitAmount = {value: summary.debitAmount || 0, style: {font: {patternType: "solid", color: {rgb: "FFF86B00"}}}};
    let total = ["", "", "", "", totalString, cbm, ctns, quantity, chargeableWeight, airFee, debitAmount]
    let putData = [];

    _.map(items, (el) => {
        let keys = [
            {key: 'string', value: 'customerPO', color: '00000000'},
            {key: 'string', value: 'style', color: '00000000'},
            {key: 'string', value: 'cutTicket', color: '00000000'},
            {key: 'string', value: 'billNo', color: '00000000'},
            {key: 'number', value: 'rate', color: '00000000'},
            {key: 'number', value: 'cbm', color: '00000000'},
            {key: 'number', value: 'ctns', color: '00000000'},
            {key: 'number', value: 'quantity', color: '00000000'},
            {key: 'number', value: 'chargeableWeight', color: '00000000'},
            {key: 'number', value: 'airFee', color: '00000000'},
            {key: 'number', value: 'debitAmount', color: '00000000'}
        ];

        putData.push(utils.mapDataForCell(el, keys));
    });
    let headerTable = ['Customer PO', 'Style', 'Cut Ticket', 'Bill #', 'Rate (USD)',
        'Volume (CBM)', 'Ctns', 'Quantity', 'Chargeable Weight', 'Air Fee', 'Debit Amount'];
    let dataHeaderTable = [];
    for(let i = 0; i < headerTable.length ; i++){
        dataHeaderTable.push({
            value: headerTable[i],
            style: utils.genStyleHeaderForCell('00000000')
        });
    }
    putData.unshift(dataHeaderTable);

    const multiDataSet = [
        {
            columns: [
                {title: '', width: {wch: 20}},//pixels width
                {title: '', width: {wch: 20}},//char width
                {title: '', width: {wch: 20}},
                {title: '', width: {wch: 20}},
                {title: '', width: {wch: 20}},//pixels width
                {title: '', width: {wch: 20}},//char width
            ],
            data: [header]
        },
       
        {
            xSteps: 0, // Will start putting cell with 1 empty cell on left most
            ySteps: 0, //will put space of 5 rows,
            columns: [],
            data: putData
        },
        {
            columns: [
                {title: '', width: {wch: 20}},//pixels width
                {title: '', width: {wch: 20}},//char width
                {title: '', width: {wch: 20}},
                {title: '', width: {wch: 20}},
                {title: '', width: {wch: 20}},//pixels width
                {title: '', width: {wch: 20}},//char width
            ],
            data: [total]
        },
    ];
    let nameFile = '';
    let d = new Date();
    let monStr = Number(d.getMonth()) + 1 < 10 ? '0' + Number(d.getMonth() + 1) : Number(d.getMonth() + 1)
    monStr =  monStr + '-' + d.getFullYear();
    if(payInfo.code){
        nameFile =  payInfo.type.toUpperCase() + '_' + payInfo.code + '_' + monStr 
    }else{
        nameFile = payInfo.type + '_' + monStr;
    }

    return (
        <div>
            <div className="mg-left-15 mg-right-15">
                <div className="row ">
                    <ul className="content-header-title float-none pd-0-5">
                        <li style={{ width: '33.333%', textAlign: 'left' }}>
                            <p>No
                                <span className="color-orange medium mg-left-25 size-16">{payInfo.code}</span>
                            </p>
                        </li>
                        <li style={{ width: '33.333%', textAlign: 'left' }}>
                            <p>Charge To
                                <span className="color-number medium mg-left-25 size-16">{payInfo.customer ? payInfo.customer.name : ''}</span>
                            </p>
                        </li>
                        <li style={{ width: '33.333%', textAlign: 'left' }}>
                            <p>Amount
                                <span className="color-number medium mg-left-25 size-16">{payInfo.amount} {payInfo.currency}</span>
                            </p>
                        </li>
                    </ul>
                </div>

                <div className="row">
                    <ul className="content-header-title float-none pd-0-5">
                        <li style={{ width: '33.333%', textAlign: 'left', height: 'auto' }}>
                            <p>Date
                                <span className="color-number medium mg-left-25 size-16">{moment(payInfo.createdAt).format('MMM DD YYYY')}</span>
                            </p>
                        </li>
                        <li style={{ width: '33.333%', textAlign: 'left', height: 'auto' }}>
                            <p>Issued By
                                <span className="color-number medium mg-left-25 size-16">{payInfo.createdBy ? payInfo.createdBy.fullName : ''}</span>
                            </p>
                        </li>
                        <li style={{ width: '23.333%', textAlign: 'left', height: 'auto' }}>

                        </li>
                        <li className="add-filter-top" style={{ width: '10%', textAlign: 'right', height: 'auto' }}>
                            {/* <span className="cursor-pointer" >
                                <i className="fa fa-floppy-o" aria-hidden="true"></i>{"Export"}
                            </span> */}
                             <ExcelFile filename={nameFile}
                                       element={
                                           <span className="cursor-pointer" style={{padding: 0}}>
                                <i className="fa fa-floppy-o" aria-hidden="true"></i>{"Export"}
                            </span>
                                       }>
                                <ExcelSheet dataSet={multiDataSet}  name={nameFile}/>
                            </ExcelFile>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="pd-5">
                <table className="global-table" style={{ width: '100%' }}>
                    <tbody>
                        <tr>
                            <th className="center">Customer PO</th>
                            <th className="center">Style</th>
                            <th className="center">Cut Ticket</th>
                            <th className="center">Bill #</th>
                            <th className="center">Rate (USD)</th>
                            <th className="center">Volume (CBM)</th>
                            <th className="center">Ctns</th>
                            <th className="center">Quantity</th>
                            <th className="center">Chargeable Weight</th>
                            <th className="center">Air Fee</th>
                            <th className="center w-100">Debit Amount</th>
                        </tr>
                        {_.map(items, (elem, elemKey) => (
                            <tr key={elemKey}>
                                <td className="center">{elem.customerPO}</td>
                                <td className="center">{elem.style}</td>
                                <td className="center">{elem.cutTicket}</td>
                                <td className="center">{elem.billNo}</td>
                                <td className="center">{elem.rate}</td>
                                <td className="center">{parseFloat(elem.cbm).toFixed(2)}</td>
                                <td className="center">{elem.ctns}</td>
                                <td className="center">{elem.quantity}</td>
                                <td className="center">{elem.chargeableWeight}</td>
                                <td className="center">{elem.airFee}</td>
                                <td className="center">{elem.debitAmount}</td>
                            </tr>
                        ))}
                        <tr>
                            <td className="center"></td>
                            <td className="center"></td>
                            <td className="center"></td>
                            <td className="center"></td>
                            <td className="center" style={{ color: 'red' }}>Total:</td>
                            <td className="center">{parseFloat(summary.cbm).toFixed(2)}</td>
                            <td className="center">{summary.ctns}</td>
                            <td className="center">{summary.quantity}</td>
                            <td className="center">{summary.chargeableWeight}</td>
                            <td className="center">{summary.airFee}</td>
                            <td className="center">{summary.debitAmount}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}
// CreditDebitViewDetail.propTypes = {
//     items: PropTypes.array,
//     columns: PropTypes.array,
//     listDeclaration: PropTypes.array,
//     name: PropTypes.string,
//     totalAmount: PropTypes.number,
//     totalAdvance: PropTypes.number,
//     totalRemaining: PropTypes.number,
//     paid: PropTypes.number
// }

CreditDebitViewDetail.defaultProps = {

}
export default CreditDebitViewDetail