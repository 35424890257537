import React, { Component } from 'react';

import icEyeIcon from '../../../stylesheets/images/ic-eye.svg';
import icEditXIcon from '../../../stylesheets/images/ic-edit-x.svg';
import icXIcon from '../../../stylesheets/images/ic-x.svg';
import icDisableIcon from '../../../stylesheets/images/ic-disable.png';
import icEnableIcon from '../../../stylesheets/images/ic-enable.png';
import icLockIcon from '../../../stylesheets/images/ic-lock.png';
import icCopyIcon from '../../../stylesheets/images/ic-copy.png';
import icPermissionIcon from '../../../stylesheets/images/ic-permission.png';

export default class Action extends Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	render() {
		const { type, func, data, isActive, toolTip } = this.props.options;
		let image = "";
		switch (type) {
			case 'delete':
				image = icXIcon;
				break;
			case 'disable':
				image = icDisableIcon;
				break;
			case 'enable':
				image = icEnableIcon;
				break;
			case 'edit':
				image = icEditXIcon;
				break;
			case 'view':
				image = icEyeIcon;
				break;
			case 'resetPassword':
				image = icLockIcon;
				break;
			case 'copy':
				image = icCopyIcon;
				break;
			case 'permission':
				image = icPermissionIcon;
				break;
		}
		let styleDisable = {opacity: '0.3', cursor: 'default'};
		return (
			<span className = "mg-right-5">
				<a  style={isActive? {}: styleDisable}  data-placement="bottom" data-toggle="tooltip" title={(toolTip && isActive)? toolTip: ''} onClick={() => {if(isActive){ func(data); }}} ><img src={image} data-placement="bottom" /></a>
			</span>
		);
	}
}
