import * as types from '../constants/actionTypes';
import {showLoading, hideLoading} from './commonUseActions';
import purchaseServices from '../services/purchaseServices';
import orderServices from "../services/orderServices";
import supplierServices from "../services/supplierServices";
import styleServices from "../services/styleServices";
import _ from 'lodash';
import {PURCHASE_STATUS, PURCHASE_LIST_SEARCH_KEY} from "../constants/constants";
import {toastr} from "react-redux-toastr";
import I18n from "i18n-js";

function getListPurchase (options = {}) {
  options.query = JSON.stringify(
    {...(JSON.parse(options.query || JSON.stringify({})) || {}), 
      status: {
        ...(JSON.parse(options.query || JSON.stringify({})) || {}).status || {},
        $ne: PURCHASE_STATUS.COMPLETED
      }
    }
  );
  return (dispatch) => {
    showLoading(types, dispatch);

    return purchaseServices.getListPurchase(options).then((res) => {
      hideLoading(types, dispatch);

      if (res) {
        dispatch({
          type: types.PURCHASE.GET_LIST_SUCCESS,
          data: res.data || []
        });
      }
      return res;
    }, (error) => {
      hideLoading(types, dispatch);
    });
  };
}

function getPurchases (options = {}) {
  return (dispatch) => {
    showLoading(types, dispatch);

    return purchaseServices.getListPurchase(options).then((res) => {
      hideLoading(types, dispatch);

      if (res) {
        dispatch({
          type: types.PURCHASE.GET_LIST_SUCCESS,
          data: res.data || []
        });
      }
      return res;
    }, (error) => {
      hideLoading(types, dispatch);
      return error;
    });
  };
}

function getPurchasesShipping (options = {}) {
    return (dispatch) => {
        showLoading(types, dispatch);

        return purchaseServices.getListPurchaseShipping(options).then((res) => {
            hideLoading(types, dispatch);

            if (res) {
                dispatch({
                    type: types.PURCHASE.GET_LIST_SUCCESS,
                    data: res.data || []
                });
            }
            return res;
        }, (error) => {
            hideLoading(types, dispatch);
            return error;
        });
    };
}


function getListFollowUp (options = {}) {
  return (dispatch) => {
    showLoading(types, dispatch);

    return purchaseServices.getListFollowUp(options).then((res) => {
      hideLoading(types, dispatch);

      if (res) {
        dispatch({
          type: types.PURCHASE.GET_LIST_SUCCESS,
          data: res.data || []
        });
      }
      return res;
    }, (error) => {
      hideLoading(types, dispatch);
    });
  };
}

function getListHistory (options = {}) {
  options.query = JSON.stringify({...(JSON.parse(options.query || JSON.stringify({})) || {}), status: PURCHASE_STATUS.COMPLETED});
  return (dispatch) => {
    showLoading(types, dispatch);

    return purchaseServices.getListPurchase(options).then((res) => {
      hideLoading(types, dispatch);

      if (res) {
        dispatch({
          type: types.PURCHASE.GET_LIST_SUCCESS,
          data: res.data || []
        });
      }
      return res;
    }, (error) => {
      hideLoading(types, dispatch);
    });
  };
}

function getDetail (id, reset = true) {
  return (dispatch) => {
    showLoading(types, dispatch);

    reset && dispatch({
      type: types.ORDER.RESET_DETAIL,
      data: {}
    });

    purchaseServices.getDetail(id, {}).then((res) => {
      hideLoading(types, dispatch);

      if (res) {
        dispatch({
          type: types.PURCHASE.GET_DETAIL_SUCCESS,
          data: res.data || {}
        });
      }
    }, (error) => {
      hideLoading(types, dispatch);
    });
  };
}
function deletePurchase (id) {
  return (dispatch) => {
    showLoading(types, dispatch);

    purchaseServices.deletePurchase(id).then((res) => {
      hideLoading(types, dispatch);

      if (res) {
        dispatch({
          type: types.PURCHASE.RELOAD,
          data: true
        });
      }
    }, (error) => {
      hideLoading(types, dispatch, error);
    });
  };
}

function editPurchase (id, data) {
  return (dispatch) => {
    showLoading(types, dispatch);

    purchaseServices.editPurchase(id, data).then((res) => {
      hideLoading(types, dispatch);

      if (res) {
        dispatch({
          type: types.PURCHASE.RELOAD,
          data: true
        });
      }
    }, (error) => {
      hideLoading(types, dispatch, error);
    });
  };
}

function restorePurchase (id, option) {
    return (dispatch) => {
        showLoading(types, dispatch);

        purchaseServices.restorePurchase(id).then((res) => {
            hideLoading(types, dispatch);

            if (res) {
                toastr.success(I18n.t('Success'), I18n.t('PagePurchase.RestoreSuccess'));
                dispatch({
                    type: types.PURCHASE.RELOAD,
                    data: true
                });
            }
        }, (error) => {
            hideLoading(types, dispatch, error);
        });
    };
}

function addPurchase (data) {
  return (dispatch) => {
    showLoading(types, dispatch);

    purchaseServices.addPurchase(data).then((res) => {
      hideLoading(types, dispatch, (res && !res.success) ? res.error : undefined);

      if (res && res.success) {
        dispatch({
          type: types.PURCHASE.RELOAD,
          data: true
        });
      }
    }, (error) => {
      hideLoading(types, dispatch, error);
    });
  };
}


function searchByCustomerPO(options, pageNumber, pageSize, type) {
  return(dispatch) =>{
    showLoading(types, dispatch);
    return orderServices.getListOrder(options).then((res) => {
      hideLoading(types, dispatch);
      if(res && res.success) {
        let options = {
          pageSize: pageSize,
          pageNumber: pageNumber,
          populate: 'materials.material,styleId|orderId,supplierId',
          sort: {updatedAt: -1},
          query: JSON.stringify({
            styleId: {
              $in: res.data.items.reduce((arr, item) => arr.concat(item.styleOrders || []), [])
            }
          })
        }
        if(type === "follow_up") {
          return getListFollowUp({...options})(dispatch).then(res => {
            if(res && res.success && res.data && res.data.items && !res.data.items.length && pageNumber > 1) {
              _.set(options, "pageNumber", 1);
              getListPurchase(options)(dispatch);
              pageNumber = 1;
            }
            return (_.set(res, "pageNumber", pageNumber));
          });
        }else if(type === 'history') {
          return getListHistory({...options})(dispatch).then(res => {
            if(res && res.success && res.data && res.data.items && !res.data.items.length && pageNumber > 1) {
              _.set(options, "pageNumber", 1);
              getListHistory(options)(dispatch);
              pageNumber = 1;
            }
            return (_.set(res, "pageNumber", pageNumber));
          });
        }else {
          return getListPurchase({...options})(dispatch).then(res => {
            if(res && res.success && res.data && res.data.items && !res.data.items.length && pageNumber > 1) {
              _.set(options, "pageNumber", 1);
              getListPurchase(options)(dispatch);
              pageNumber = 1;
            }
            return (_.set(res, "pageNumber", pageNumber));
          });
        }
      }
    }, (error) => {
      hideLoading(types, dispatch, error);
    })
  }
}

function searchBySupplier(options, pageNumber, pageSize, type) {
  return(dispatch) =>{
    showLoading(types, dispatch);
    return supplierServices.getListSupplier(options).then((res) => {
      hideLoading(types, dispatch);
      if(res && res.success) {
        let options = {
          pageSize: pageSize,
          pageNumber: pageNumber,
          populate: 'materials.material,styleId|orderId,supplierId',
          sort: {updatedAt: -1},
          query: JSON.stringify({
            supplierId: res.data.items.reduce((arr, item) => arr.concat([item._id]), [])
          })
        };
        if(type === "follow_up") {
          return getListFollowUp({...options})(dispatch).then(res => {
            if(res && res.success && res.data && res.data.items && !res.data.items.length && pageNumber > 1) {
              _.set(options, "pageNumber", 1);
              getListFollowUp(options)(dispatch);
              pageNumber = 1;
            }
            return (_.set(res, "pageNumber", pageNumber))
          });
        }else if(type === 'history') {
          return getListHistory({...options})(dispatch).then(res => {
            if(res && res.success && res.data && res.data.items && !res.data.items.length && pageNumber > 1) {
              _.set(options, "pageNumber", 1);
              getListHistory(options)(dispatch);
              pageNumber = 1;
            }
            return (_.set(res, "pageNumber", pageNumber));
          });
        }else {
          return getListPurchase({...options})(dispatch).then(res => {
            if(res && res.success && res.data && res.data.items && !res.data.items.length && pageNumber > 1) {
              _.set(options, "pageNumber", 1);
              getListPurchase(options)(dispatch);
              pageNumber = 1;
            }
            return (_.set(res, "pageNumber", pageNumber))
          });
        }
      }
    }, (error) => {
      hideLoading(types, dispatch, error);
    })
  }
}

function searchByStyle(options, pageNumber, pageSize, type) {
  return(dispatch) =>{
    showLoading(types, dispatch);
    return styleServices.getAllStyles(options).then((res) => {
      hideLoading(types, dispatch);
      if(res && res.success) {
        let options = {
          pageSize: pageSize,
          pageNumber: pageNumber,
          populate: 'materials.material,styleId|orderId,supplierId',
          sort: {updatedAt: -1},
          query: JSON.stringify({
            styleId: {
              $in: res.data.items.reduce((arr, item) => arr.concat(item._id || []), [])
            }
          })
        };
        if(type === "follow_up") {
          return getListFollowUp({...options})(dispatch).then(res => {
            if(res && res.success && res.data && res.data.items && !res.data.items.length && pageNumber > 1) {
              _.set(options, "pageNumber", 1);
              getListFollowUp(options)(dispatch);
              pageNumber = 1;
            }
            return (_.set(res, "pageNumber", pageNumber))
          });
        }else if(type === 'history') {
          return getListHistory({...options})(dispatch).then(res => {
            if(res && res.success && res.data && res.data.items && !res.data.items.length && pageNumber > 1) {
              _.set(options, "pageNumber", 1);
              getListHistory(options)(dispatch);
              pageNumber = 1;
            }
            return (_.set(res, "pageNumber", pageNumber));
          });
        }else {
          return getListPurchase({...options})(dispatch).then(res => {
            if(res && res.success && res.data && res.data.items && !res.data.items.length && pageNumber > 1) {
              _.set(options, "pageNumber", 1);
              getListPurchase(options)(dispatch);
              pageNumber = 1;
            }
            return (_.set(res, "pageNumber", pageNumber))
          });
        }
      }
    }, (error) => {
      hideLoading(types, dispatch, error);
    })
  }
}

function searchInto(options, func,  name, field, bool = true) {
  return new Promise(resolve => {
    if(bool) {
      options.query = JSON.stringify(options.query || {})
      return resolve(options)
    } 
    func.then(rs => {
      const qr = {[name]: _.get(rs, 'data.items', []).reduce((ls, vl) => _.isArray(vl[field]) ? [...ls, ...vl[field]] : [...ls, vl[field]], [])}
      options.query = JSON.stringify({...options.query, ...qr})
      return resolve(options)
    })
  })
}

function search ({search, options}) {
  return (dispatch) => {
    let func,  name, field, bool = true
    if(search.value != '') {
      switch(search.key) {
        case PURCHASE_LIST_SEARCH_KEY.CUSTOMER_PO: {
          func = orderServices.getListOrder({projection: 'styleOrders', query: JSON.stringify({customerPO: {$regex: '.*' + search.value + '.*', $options: 'i'}}), isGetAll: true})
          name = 'styleId'
          field = 'styleOrders'
          bool = false
          break;
        }
        case PURCHASE_LIST_SEARCH_KEY.SUPPLIER: {
          func = supplierServices.getListSupplier({projection: '_id', query: {name: {$regex: '.*' + search.value + '.*', $options: 'i'}}})
          name = 'supplierId'
          field = '_id'
          bool = false
          break;
        }
        case PURCHASE_LIST_SEARCH_KEY.STYLE: {
          func = styleServices.getAllStyles({projection: '_id', query: JSON.stringify({style: {$regex: '.*' + search.value + '.*', $options: 'i'}})})
          name = 'styleId'
          field = '_id'
          bool = false
          break;
        }
      }
    }

    let op = {}

    return searchInto(options, func,  name, field, bool).then(data => {
      op = data
      return getPurchases(_.clone(op))(dispatch)
    }).then(res => {
      if(res && res.success && res.data && res.data.items && !res.data.items.length && op.pageNumber > 1) {
        _.set(op, "pageNumber", 1);
        getPurchases(op)(dispatch);
        op.pageNumber = 1;
      }
      return (_.set(res, "pageNumber", op.pageNumber))
    })
  };
}

export default {
  getPurchases,
  getListPurchase,
  getListFollowUp,
  deletePurchase,
  editPurchase,
  restorePurchase,
  addPurchase,
  getDetail,
  searchBySupplier,
  searchByCustomerPO,
  searchByStyle,
  getListHistory,
  search
};
