import React, { Component } from 'react';
import PreviewSupplierDomesticView from '../../../components/accounting/payable/PreviewSupplierDomestic';
import I18n from 'i18n-js';
import { connect } from 'react-redux';
import * as selector from '../../../../selectors/accounting/payable/historyPreviewSupplierDomestic';
import * as actions from '../../../../actions/accounting/payable/historyPreviewSupplierDomestic';
import moment from 'moment';
import {withRouter} from "react-router";
class PreviewSupplierDomestic extends Component {

    constructor (props) {
        super(props)
        this.state = {
            listIds: this.props.listIds,
            items: [],
            pageNumber: 1,
            pageSize: 10,
            columns: [
                { name: 'Customner PO', value: '', type: 'text' },
                { name: 'Style', value: '', type: 'text' },
                { name: 'Purchase Order No.', value: 'purchaseNo', type: 'text' },
                { name: 'Material', value: 'material', type: 'text' },
                { name: 'Order Quantity', value: 'quantity', type: 'number' },
                { name: 'Received Quantity', value: 'receiveQuantity', type: 'number' },
                { name: 'Received Date', value: 'exReceivedDate', type: 'date', formatType: 'MMM DD, YYYY' },
                { name: 'Unit', value: 'unit', type: 'text' },
                { name: 'Price', value: 'priceText', type: 'text' },
                { name: 'Amount', value: 'amount', type: 'text' },
            ],
            actions: []
        }
    }

    componentDidMount() {
        this.props.resetData()
    }

    componentWillReceiveProps(nextProps) {
        const {activeScreen} = nextProps;
        if(!activeScreen) {
            nextProps.router.history.goBack();
        }
    }

    render() {
        const { columns } = this.state;
        return (
            <div>
                <PreviewSupplierDomesticView columns = {columns} {...this.props} />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    invoiceDetail: selector.getInvoiceDetail(state),
    items: selector.getListItems(state),
    amount: selector.getAmount(state),
    invoiceNo: selector.getInvoiceNo(state),
    issuedDate: selector.getIssuedDate(state),
    supplier: selector.getSupplier(state),
    // amountINV: selector.getAmount(state),
    totalAmountINV: selector.getTotalAmount(state),
    activeScreen: selector.getActiveScreenStatus(state),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    resetData() {
        dispatch(actions.resetData())
    }
})

export default connect(mapStateToProps, mapDispatchToProps) (withRouter(PreviewSupplierDomestic));