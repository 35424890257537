import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { withRouter } from 'react-router';
import I18n from 'i18n-js';
import { SHIP_OUT } from '../../../../../constants/routeConstants';
import { Store } from '../../../../CssHandleModule/functions';
import CutTicketContainer from './CutTicketContainerEdit';
import BarTitle from '../../../../components/shipping/subViews/barTitle';
import ChooseStyle from '../../../../components/shipping/subViews/chooseStyle';
import * as selector from '../../../../../selectors/ship-out/editPklShip';
import CutTicketContainersPrepack from '../../subViews/nested-containers/CutTicketContainersPrepack';
import {
  resetData,
  changeOrderActive,
  changeStyleActive,
  save,
} from '../../../../../actions/ship-out/editPklShipActions';
import CustomerPOEditShip from '../../../../components/shipping/subViews/customerPOEditShip';
const store = new Store();
store.setStyles({
  orderNumbers: [1],
  aligns: [{ order: 1, margin: { right: '10px' } }],
  fonts: [{ order: 1, color: '#F36F21' }],
  showDetail: [],
  actions: {},
});

class EditPKLShipContainer extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    actions: {},
  };
  componentWillReceiveProps(nextProps) {
    const { success, activeScreen, actions } = nextProps;
    console.log('componentWillReceiveProps nextProps:', nextProps);
    console.log('his.state.actions:', this.state.actions);
    if (!_.isEmpty(actions)) {
      this.setState({ actions });
    }
    if (success) {
      nextProps.resetData();
      nextProps.router.history.push(SHIP_OUT.PKL_SHIP.URL);
      toastr.success(I18n.t('Notify.Success'), 'Update Success');
    }
    if (!activeScreen) {
      nextProps.router.history.push(SHIP_OUT.PKL_SHIP.URL);
    }
  }

  componentDidMount() {
    this.props.resetData();
  }

  changeOrderActive = (value, orderId) => {
    this.props.changeOrderActive(value, orderId);
  };

  changeStyleActive = (orderId, styleId) => {
    this.props.changeStyleActive(orderId, styleId);
  };

  save = () => {
    const dataApi = this.getApiData();
    this.props.save(dataApi.id, dataApi);
  };

  setStateFuc = data => {
    this.setState(data);
  };

  getApiData = () => {
    const { actions } = this.state;
    const { dataApi } = this.props;
    dataApi.pklProds.forEach((order, oIdx) => {
      order.styles.forEach((s, sIdx) => {
        const pkNew = [];
        s.packings &&
          s.packings.forEach((pk, pkIdx) => {
            const key = `${order.packing}-${s.style}-${pk.props.group}`;
            let action = _.cloneDeep(actions[key]);
            if (!action || action.check != false) {
              if (action && action.package) {
                if (action.nos.includes(pk.props.no)) {
                  pkNew.push(pk);
                }
              } else {
                pkNew.push(pk);
              }
            }
          });
        s.packings = pkNew;
      });
    });
    return dataApi;
  };

  setCutTicketContainers = (id, shipOut) => {
    const { listActiveStyleIds, packingType } = this.props;
    const styleId = listActiveStyleIds[id];
    const cutTickets = this.props.getCutickets(id, styleId);
    return cutTickets.map((cutTicket, index) => {
      return (
        <CutTicketContainer
          {...this.props}
          key={index}
          shipOut={shipOut}
          packingType={packingType}
          cutTicket={cutTicket.cutTicket}
          colors={cutTicket.colors}
          styleId={styleId}
          orderId={id}
        />
      );
    });
  };

  setOrderContainers = (orders, shipOut) => {
    return orders.map((order, index) => {
      const { actions, showDetail } = this.state;
      const { getOrderDetail, getStyles, pklProds } = this.props;
      let orderDetail = getOrderDetail(order._id) || {};
      let styles = getStyles(order._id) || [];
      const cutTicketContainers =
        orderDetail.carton != 'Pre-pack' ? (
          this.setCutTicketContainers(order._id)
        ) : (
          <CutTicketContainersPrepack
            actions={_.isEmpty(actions) ? this.props.actions : actions}
            showDetail={showDetail}
            setState={this.setStateFuc}
            order={orderDetail.packing}
          />
        );
      return (
        <div key={index} className="pd-5 mg-bottom-10">
          <BarTitle
            id={order._id}
            type="customer_po"
            name={order.name}
            active={order.active}
            changeOrderActive={this.changeOrderActive}
          />
          {order.active ? (
            <div>
              {!shipOut && <CustomerPOEditShip pklProds={pklProds} orderDetail={orderDetail} orderId={order._id} />}
              <ChooseStyle
                removeIcon={false}
                orderId={order._id}
                styles={styles}
                changeStyleActive={this.changeStyleActive}
              />
              {cutTicketContainers}
            </div>
          ) : null}
        </div>
      );
    });
  };

  render() {
    const { total, listOrders, view, pklShipId, invoice, customerName, shipOut } = this.props;
    const orderContainers = listOrders && this.setOrderContainers(listOrders, shipOut);
    const customerInv = view && invoice.customerInv;
    return (
      <div>
        {/* { view ? 
                    <HeaderForSubView type='View' turnBackURL={SHIP_OUT.PKL_SHIP.CREATE} {...this.props}/>
                    : 
                    <HeaderForSubView type='Edit' turnBackURL={SHIP_OUT.PKL_SHIP.URL} {...this.props} />} */}
        <ul className="content-header-title float-none pd-0-10 justify-content-space-between display-flex">
          {view && (
            <li>
              <p>
                {I18n.t('PageShiping.CustomerINV')}
                <span className="color-orange medium mg-left-25 size-16">{customerInv || ''}</span>
              </p>
            </li>
          )}
          <li>
            <p>
              {I18n.t('PageShiping.Customer')}
              <span className="color-orange medium mg-left-25 size-16">{customerName || ''}</span>
            </p>
          </li>
          {!view && (
            <li>
              <p>
                {I18n.t('PageShiping.TotalQTY')}
                <span className="color-number medium mg-left-25 size-16">{total || 0}</span>
              </p>
            </li>
          )}
          {!view && (
            <li className="add-filter-top">
              <span className="cursor-pointer" onClick={() => this.save()}>
                <i className="fa fa-floppy-o" aria-hidden="true"></i> {I18n.t('PageShiping.SAVE')}
              </span>
            </li>
          )}
        </ul>
        {orderContainers}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  actions: selector.getActions(state),
  customerName: selector.getCustomer(state),
  total: selector.getTotal(state),
  listOrders: selector.getOrders(state),
  getStyles: selector.getStyles(state),
  listActiveStyleIds: selector.getActiveStyleIds(state),
  packingType: selector.getPackingType(state),
  getCutickets: selector.fnGetCutTickets(state),
  dataApi: selector.fnGenApiData(state),
  success: selector.getSuccess(state),
  view: selector.getView(state),
  pklShipId: selector.getPklShipId(state),
  invoice: selector.getInvoice(state),
  activeScreen: selector.getActiveScreenEditPklShip(state),
  getOrderDetail: selector.fnGetOrderDetail(state),
  pklProds: selector.pklProds(state),
  shipOut: selector.getShipOut(state),
});

const mapDispatchToProps = dispatch => ({
  resetData() {
    dispatch(resetData());
  },
  changeOrderActive(value, orderId) {
    dispatch(changeOrderActive(value, orderId));
  },
  changeStyleActive(orderId, styleId) {
    dispatch(changeStyleActive(orderId, styleId));
  },
  save(id, data) {
    dispatch(save(id, data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EditPKLShipContainer));
