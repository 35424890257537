import C from "../../../constants/accounting/payable/editInvoiceContractor";
import accountingServices from '../../../services/accountingServices';
import { toastr } from 'react-redux-toastr';
import I18n from 'i18n-js';

export const activeScreenEditContractor = () => dispatch => {
    dispatch({type: C.ACTIVE_SCREEN_STATUS, payload: true})
} 

export const getInvoiceContractor = (id) => dispatch => {
    accountingServices.getInvoiceContractorDetail(id).then(res => {
        if (res.success) {
            dispatch({ type: C.CHANGE_INVOICE_DETAIL, payload: res.data })
            dispatch({ type: C.CHANGE_CONST_CONTRACTOR_VALUE, payload: res.data.invoiceDetail.contractor.name });
            dispatch({ type: C.CHANGE_LIST_CUSTOMER_PO, payload: res.data.invoiceDetail.customerPOs });
            dispatch(getListCreditDebit({ contractor: res.data.invoiceDetail.contractor._id }));
        }
    })
}

export const getListCreditDebit = params => dispatch => {
    accountingServices.getListCreditDebit(params).then(
      res => {
        dispatch({ type: C.CHANGE_SELECTABLE_CREDIT_DEBITS, payload: _.get(res, 'data', []) });
      },
      error => {
        console.log(JSON.stringify(error));
      }
    );
  };

export const changeCreditedDebit = (value, totalAmount) => dispatch => {
    dispatch({ type: C.CHANGE_SELECTED_CREDIT_DEBIT_VALUE, payload: value});

    if (value && value.amount) {
        totalAmount += value.amount;
        dispatch({ type: C.CHANGE_TOTAL_AMOUNT_VALUE, payload: totalAmount});
    }
}

export const changeContractor = (value) => dispatch => {
    dispatch({ type: C.CHANGE_CONTRACTOR_VALUE, payload: value })
}

export const changeInvoiceNo = (value) => dispatch => {
    dispatch({ type: C.CHANGE_INVOICE_NO_VALUE, payload: value })
}

export const changeIssuedDate = (value) => dispatch => {
    dispatch({ type: C.CHANGE_ISSUED_DATE_VALUE, payload: value })
}
export const changeDueDate = (value) => dispatch => {
    dispatch({ type: C.CHANGE_DUE_DATE_VALUE, payload: value })
}
export const changePayAmount = (value, remaining, paid) => dispatch => {
    if (value > remaining) {
        toastr.error('Error', 'Pay Amount can not be more than Remaining');
        return;
    }
    if (paid >= remaining) {
        toastr.warning('Warning', 'Completly payment');
        return;
    }
    if (value <= remaining) {
        dispatch({ type: C.CHANGE_PAY_AMOUNT_VALUE, payload: value })
    }
}

export const changePaymentType = (value) => dispatch => {
    dispatch({ type: C.CHANGE_PAYMENT_TYPE, payload: value })
}

export const addFileTransfer = (file) => dispatch => {
    var FileSize = file.size / 1024 / 1024; // in MB
    if (FileSize > 2) {
        toastr.error('Error', I18n.t('ErrorMessages.FileSizeExceeds2MB'));
        return;
    }
    const readerBase64 = new FileReader();
    readerBase64.onload = (eventBase64) => {
        const url = eventBase64.target.result;
        const filename = file.name;

        const payload = { id: filename, file, filename, url, }
        dispatch({ type: C.ADD_TRANSFER_FILE, payload });
    };
    readerBase64.readAsDataURL(file);
}

export const onRemoveFile = (id) => dispatch => {
    dispatch({ type: C.REMOVE_TRANSFER_FILE, payload: id });
}

export const save = (paymentType, data) => dispatch => {
    accountingServices.updateInvoice(data._id, paymentType, data).then(res => {
        if (res.success) {
            dispatch({ type: C.SAVE_SUCCESS, payload: true })
        }
    })
}

export const resetSuccessStatus = () => dispatch => {
    dispatch({ type: C.RESET_DATA_ALL })
}

export const copyFileToRawData = (files) => dispatch => {
    dispatch({ type: C.COPY_FILES_TO_RAW_DATA, payload: files });
}

export const emptyFilesInRawData = () => dispatch => {
    dispatch({ type: C.EMPTY_FILES_IN_RAW_DATA });
}

export const onSubmitFiles = (files) => dispatch => {
    dispatch({ type: C.SUBMIT_FILES, payload: files });
}

export const createReceipt = (value) => dispatch => {
    dispatch({ type: C.CREATE_RECEIPT, payload: value });
}

export const changeReceiptValue = (key, value) => dispatch => {
    dispatch({ type: C.CHANGE_RECEIPT_VALUE, payload: { key, value } });
}

export const onSubmitReceipt = (data) => dispatch => {
    dispatch({ type: C.SUBMIT_RECEIPT, payload: data });
}

export const changeEmptyReceipt = () => dispatch => {
    dispatch({ type: C.EMPTY_RECEIPT });
}

export const changeSelectedCustomerPOs = (_id, checked) => dispatch => {
    dispatch({ type: C.CHANGE_CHECKED_CUSTOMER_PO, payload: { _id, checked } })
}

export const toggleActiveCustomerPO = (_id, active) => dispatch => {
    dispatch({ type: C.CHANGE_TOGGLE_ACTIVE_CUSTOMER_PO, payload: { _id, active } })
}

export const changeCustomerPOPrice = (id, value) => dispatch => {
    dispatch({ type: C.CHANGE_CUSTOMER_PO_PRICE, payload: { id, value } })
}

export const changeStylePrice = (cusId, styleId, value) => dispatch => {
    dispatch({ type: C.CHANGE_STYLE_PRICE, payload: { cusId, styleId, value } })
}

export const changeColorPrice = (cusId, styleId, cutId, colorId, value) => dispatch => {
    dispatch({ type: C.CHANGE_COLOR_PRICE, payload: { cusId, styleId, cutId, colorId, value } })
}

export const changeSelectedConvertCurrency = value => dispatch => {
    dispatch({ type: C.CHANGE_SELECTED_CURRENCY, payload: value });
};

export const changeConvertRate = value => dispatch => {
    dispatch({ type: C.CHANGE_CONVERT_RATE, payload: value });
}

export const resetData = () => dispatch => {
    dispatch({ type: C.RESET_DATA_ALL })
}

export const changeMainVATNo = (value) => dispatch => {
    dispatch({ type: C.CHANGE_MAIN_VAT_NO, payload: value });
}

export const changeMainVATTotalAmount = (value) => dispatch => {
    dispatch({ type: C.CHANGE_MAIN_TOTAL_AMOUNT, payload: value });
}

export const changeMainVATDate = (value) => dispatch => {
    dispatch({ type: C.CHANGE_MAIN_VAT_DATE, payload: value });
}

export const changeMainVATTaxRate = (value) => dispatch => {
    dispatch({ type: C.CHANGE_MAIN_TAX_RATE, payload: value });
}

export const changeSubVATNo = (_id, value) => dispatch => {
    dispatch({ type: C.CHANGE_SUB_VAT_NO, payload: { _id, value } });
};

export const changeSubVATDate = (_id, value) => dispatch => {
    dispatch({ type: C.CHANGE_SUB_VAT_DATE, payload: { _id, value } });
};

export const changeSubTaxRate = (_id, value) => dispatch => {
    dispatch({ type: C.CHANGE_SUB_TAX_RATE, payload: { _id, value } });
};

export const changeSubVATNote = (_id, value) => dispatch => {
    dispatch({ type: C.CHANGE_SUB_VAT_NOTE, payload: { _id, value } });
};
export const changeSubVATTotalAmount = (_id, value) => dispatch => {
    dispatch({ type: C.CHANGE_SUB_TOTAL_AMOUNT, payload: { _id, value } });
};