import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import * as R from 'ramda';

import SidebarItemList from '../../../components/customer-po/SidebarItemList';

import CreateOrderModal from '../../../containers/customer-po/current/CreateOrderModal';
import EditOrderModal from '../../../containers/customer-po/current/EditOrderModal';
import DeleteOrderModal from '../../../containers/customer-po/current/DeleteOrderModal';

import * as actions from '../../../../actions/customer-po/current/showListOrders';
import {
  showModal as showCreateOrderModal,
} from '../../../../actions/customer-po/current/createOrder';

import { showModal as showEditOrderModal } from '../../../../actions/customer-po/current/editOrder';
import { showModal as showDeleteOrderModal } from '../../../../actions/customer-po/current/cancelOrder';

import { isOpen as isOpenCreateOrderModal } from '../../../../selectors/customer-po/current/createOrder';
import { isOpen as isOpenEditOrderModal } from '../../../../selectors/customer-po/current/editOrder';
import { isOpen as isOpenDeleteOrderModal } from '../../../../selectors/customer-po/current/cancelOrder';

import * as selectors from '../../../../selectors/customer-po/current/showListOrders';
import * as permissions from '../../../../selectors/permissions';
import OrderDetail from './OrderDetail';
import { PAGINATION } from '../../../../constants/constants';
import _ from 'lodash';

class MainView extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      pageSize: PAGINATION.PAGE_SIZE,
      pageNumber: PAGINATION.PAGE_NUMBER,
    };
    this.onSubmitSearch = this.onSubmitSearch.bind(this)
  }

  componentWillMount() {
    const size = this.state.pageSize;
    const number = this.state.pageNumber;
    this.getListFirstOrder(size, number);
    this.props.onChangePageNumber(PAGINATION.PAGE_NUMBER);
    this.props.onChangeSearchText();
  }

  componentDidUpdate() {
    this.scrollBar();
  }

  getListOrder(size, number) {
    const query = { pageSize: size, pageNumber: number, isCompleted: false, isCancel: false };
    this.props.loadData(_.assign(query));
  }

  getListOrderSearch(size, number) {
    const { searchText } = this.props
    const query = { pageNumber: number, pageSize: size, isCompleted: false, isCancel: false, searchKey: searchText, filter: true };
    if (searchText) {
      this.props.loadData(_.assign(query));
    }
  }

  getListFirstOrder(size, number) {
    const query = { pageSize: size, pageNumber: number, isCompleted: false, isCancel: false };
    this.props.loadFirstData(_.assign(query));
  }

  handleLoadMore(number) {
    const { isSearch, searchText } = this.props
    const {pageSize, pageNumber } = this.state;
    if (isSearch && searchText == '') { // Search not value 
      this.props.cancelChangeSearch();
      this.props.cancelChangePageNumber();
      this.getListFirstOrder(pageSize, pageNumber);
    } else if (isSearch && searchText) { // Search value
      this.props.onChangePageNumber(number)
      this.getListOrderSearch(pageSize, number)
    } else { // Not search
      this.props.onChangePageNumber(number)
      this.props.cancelChangeSearch()
      this.getListOrder(pageSize, number)
    }

  }

  scrollBar() {
    if ($('.content-sidebar-menu').height() < $('.content-sidebar-menu > ul').height()) {
      $('.content-sidebar-menu').addClass('scrollbar');
    } else {
      $('.content-sidebar-menu').removeClass('scrollbar');
    }
  }

  onSubmitSearch() {
    const { searchText } = this.props
    const size = this.state.pageSize;
    const number = this.state.pageNumber;
    const querySearch = { pageNumber: number, pageSize: size, isCompleted: false, isCancel: false, searchKey: searchText, filter: true };
    const querySearchNull = { pageSize: size, pageNumber: number, isCompleted: false, isCancel: false };
    this.props.cancelChangePageNumber();
    if (searchText) {
      this.props.changeSearch()
      this.props.loadFirstData(_.assign(querySearch));
    } else {
      this.props.cancelChangeSearch()
      this.props.loadFirstData(_.assign(querySearchNull));
    } 
  }

  render() {
    const { numberPage } = this.props
    return ( 
      <div id="content">
        <SidebarItemList
          {...this.props}
          handleLoadMore={(numberPage) => this.handleLoadMore(numberPage)}
          onSubmitSearch={this.onSubmitSearch}
        />

        {!!this.props.selectedOrder &&
          <OrderDetail />
        }

        {this.props.isOpenCreateOrderModal &&
          <CreateOrderModal />
        }

        {this.props.isOpenEditOrderModal &&
          <EditOrderModal />
        }

        {this.props.isOpenDeleteOrderModal &&
          <DeleteOrderModal />
        }

      </div>
    )
  }
}

const mapStateToProps = state => ({
  searchText: selectors.getFilterText(state),
  items: selectors.getOrders(state),
  isLoadMore: selectors.isOpenLoadMore(state),
  isSearch: selectors.isSearch(state),
  numberPage: selectors.getNumberPage(state),
  isLoading: selectors.isLoading(state),
  selectedOrder: selectors.getSelectedOrder(state),
  canAddOrder: selectors.canAddOrder(state),
  canComplete: permissions.isSpecial({ moduleKey: "customerPO", ...state }),
  showEditItemBtn: selectors.canEditOrder(state),
  showDeleteItemBtn: selectors.canDeleteOrder(state),
  isOpenCreateOrderModal: isOpenCreateOrderModal(state),
  isOpenEditOrderModal: isOpenEditOrderModal(state),
  isOpenDeleteOrderModal: isOpenDeleteOrderModal(state),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  loadData(options) {
    dispatch(actions.listCustomerOrders(options));
  },

  onItemClick(item) {
    dispatch(actions.changeSelectedOrder(item ? item.raw : null));
  },

  onChangeSearchText(text) {
    dispatch(actions.changeFilterText(text ? text : ''));
  },

  onAddOrderClick() {
    dispatch(showCreateOrderModal());
  },

  onEditItemClick(item) {
    dispatch(showEditOrderModal(R.path(['raw', '_id'], item)));
  },

  onDeleteItemClick(item) {
    dispatch(showDeleteOrderModal(R.path(['raw'], item)));
  },

  onChangePageNumber(number) {
    dispatch(actions.changePageNumber(number));
  },

  loadFirstData(options) {
    dispatch(actions.listFirstOrders(options));
  },

  cancelChangeSearch() {
    dispatch(actions.cancelChangeSearch());
  },

  changeSearch() {
    dispatch(actions.changeSearch());
  },

  cancelChangePageNumber() {
    dispatch(actions.cancelChangePageNumber());
  },

});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MainView));
