import { combineReducers } from 'redux';
import C from '../../../constants/customer-po/current/showOrderDetail';

const order = (state = null, action) => {
  const { type, payload } = action;
  switch (type) {
    case C.CHANGE_ORDER_DETAIL:
      return payload;
    default:
      return state;
  }
};

const styles = (state = [], action) => {
  const { type, payload } = action;
  switch (type) {
    case C.CHANGE_STYLES:
      return payload;
    default:
      return state;
  }
};

const selectedStyle = (state = null, action) => {
  const { type, payload } = action;
  switch (type) {
    case C.CHANGE_SELECTED_STYLE:
      return payload || [];
    case C.CHANGE_STYLES:
      return null;
    default:
      return state;
  }
};

const cutTickets = (state = [], action) => {
  const { type, payload } = action;
  switch (type) {
    case C.CHANGE_CUT_TICKETS:
      return payload;
    case C.CHANGE_CUT_TICKET:
      return _.map(state, it => it._id !== payload._id ? it : payload);
    case C.CHANGE_STYLES:
    case C.CHANGE_SELECTED_STYLE:
      return [];
    default:
      return state;
  }
}

const notifying = (state = false, action) => {
  const { type } = action;
  switch (type) {
    case C.SEND_NOTIFY:
      return true;
    case C.CANCEL_SEND_NOTIFY:
      return false;
    default:
      return state;
  }
}

const combined = combineReducers({
  order,
  styles,
  selectedStyle,
  cutTickets,
  notifying,
});


export default (state, action) => {
  const { type } = action;
  if (type === C.RESET_DATA) {
    return {};
  }
  return combined(state, action);
};