import React, { Component, Suspense } from 'react';
import { Link, Switch, Route } from 'react-router-dom';
import { withRouter } from 'react-router';
import * as R from 'ramda';
import I18n from 'i18n-js';

import { SHIP_OUT } from '../../../../constants/routeConstants';
import PKLShipContainer from '../../../containers/shipping/ship-out/PKLShipContainer';
import PKLProductionContainer from '../../../containers/shipping/ship-out/PKLProductionContainer';
import SubShipOutContainer from '../../../containers/shipping/ship-out/TabShipOutContainer';
import HistoryContainer from '../../../containers/shipping/ship-out/HistoryContainer';
//Pkl Production
import CreatePklShipContainer from '../subViews/CreatePKLShipContainer';
import PreviewPackingContainer from '../subViews/PreviewContainer';
import EditPackingContainer from '../subViews/ViewinTabPKLProduction';
//Pkl Ship
import CreateShipmentContainer from './pklShip/CreateShipmentContainer';
import ViewPklShipDetailContainer from './pklShip/EditinTabPKLShipContainer';
import EditinTabPKLShipContainer from './pklShip/EditinTabPKLShipContainer';
import CreateInvoice from '../../../components/shipping/subViews/createINV';
//Ship Out
import EditShipmentContainer from './shipOut/EditShipmentContainer';

class ShipOutContainer extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        const pathname = R.path(['router', 'route', 'location', 'pathname'], this.props);
        const { roles } = this.props;
        const Loading = () => <div>loading...</div>;

        return (
            <Suspense fallback={<Loading />}>
                <div>
                    <Switch>
                        {/* Pkl Production */}
                        <Route path={SHIP_OUT.PKL_PRODUCTION.CREATE_CARTON} render={() => <CreatePklShipContainer {...this.props} />} />
                        <Route path={SHIP_OUT.PKL_PRODUCTION.CREATE_GOHH} render={() => <CreatePklShipContainer {...this.props} />} />
                        <Route path={SHIP_OUT.PKL_PRODUCTION.PREVIEW} component={PreviewPackingContainer} />
                        <Route path={SHIP_OUT.PKL_PRODUCTION.VIEW} component={EditPackingContainer} />
                        {/* Pkl Ship */}
                        {/*<Route path = {SHIP_OUT.PKL_SHIP.CREATE_DETAIL} component = {ViewPklShipDetailContainer}/>*/}
                        <Route path={SHIP_OUT.PKL_SHIP.CREATE} render={() => <CreateShipmentContainer {...this.props} disabled={false} saveButton noDateDelivered />} />
                        <Route path={SHIP_OUT.PKL_SHIP.EDIT} render={() => <EditinTabPKLShipContainer {...this.props} />} />
                        <Route path={SHIP_OUT.PKL_SHIP.EDIT_REVIEW} render={() => <CreatePklShipContainer {...this.props} />} />
                        <Route path={SHIP_OUT.PKL_SHIP.CREATE_INV} render={() => <div className="pd-5"><CreateInvoice {...this.props} /></div>} />
                        <Route path={SHIP_OUT.PKL_SHIP.EDIT_INV} render={() => <div className="pd-5"><CreateInvoice {...this.props} /></div>} />
                        {/* Ship Out */}
                        <Route path={SHIP_OUT.SHIP.VIEW_DETAIL} component={ViewPklShipDetailContainer} />
                        <Route path={SHIP_OUT.SHIP.EDIT} exact render={() => <EditShipmentContainer screen="editShipment" {...this.props} disabled={false} saveButton noDateDelivered={false} />} />
                        {/* History */}
                        <Route path={SHIP_OUT.HISTORY.VIEW_DETAIL} component={ViewPklShipDetailContainer} />
                        <Route path={SHIP_OUT.HISTORY.VIEW} exact render={() => <EditShipmentContainer screen="viewShipment" {...this.props} disabled saveButton={false} noDateDelivered={false} />} />
                        <Route path={SHIP_OUT.URL} render={() =>
                            <div className="global-table-tab">
                                <ul className="nav nav-tabs wrap-tab" role="tablist" style={{ height: 48 }}>
                                    <li role="presentation" className={pathname === SHIP_OUT.URL ? 'active' : ''}>
                                        <Link to={SHIP_OUT.URL} aria-controls="ship-out" role="tab">
                                            {I18n.t('PageShiping.PKLProduction')}
                                        </Link>
                                        <span className="line" />
                                    </li>

                                    <li role="presentation" className={pathname === SHIP_OUT.PKL_SHIP.URL ? 'active' : ''}>
                                        <Link to={SHIP_OUT.PKL_SHIP.URL} aria-controls="ship-out" role="tab">
                                            {I18n.t('PageShiping.PKLShip')}
                                        </Link>
                                        <span className="line" />
                                    </li>

                                    <li role="presentation" className={pathname === SHIP_OUT.SHIP.URL ? 'active' : ''}>
                                        <Link to={SHIP_OUT.SHIP.URL} aria-controls="ship-out" role="tab">
                                            {I18n.t('PageShiping.Ship')}
                                        </Link>
                                        <span className="line" />
                                    </li>

                                    <li role="presentation" className={pathname === SHIP_OUT.HISTORY.URL ? 'active' : ''}>
                                        <Link to={SHIP_OUT.HISTORY.URL} aria-controls="ship-out" role="tab">
                                            {I18n.t('PageShiping.History')}
                                        </Link>
                                    </li>
                                </ul>
                                <Switch>
                                    <Route path={SHIP_OUT.URL} exact render={() => <PKLProductionContainer {...this.props} />} roles={roles} />
                                    <Route path={SHIP_OUT.PKL_SHIP.URL} exact render={() => <PKLShipContainer {...this.props} />} roles={roles} />
                                    <Route path={SHIP_OUT.SHIP.URL} exact render={() => <SubShipOutContainer {...this.props} />} roles={roles} />
                                    <Route path={SHIP_OUT.HISTORY.URL} exact component={HistoryContainer} roles={roles} />
                                </Switch>
                            </div>
                        } />
                    </Switch>
                </div>
            </Suspense>
        );
    }
}

ShipOutContainer.propTypes = {

};

ShipOutContainer.defaultProps = {

};

export default withRouter(ShipOutContainer);