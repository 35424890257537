import Base from './base';

class Yield extends Base {
  constructor() {
    super();

    this.addYieldDAL = this.addYieldDAL.bind(this);
    this.editYieldDAL = this.editYieldDAL.bind(this);
    this.getExportBom = this.getExportBom.bind(this);
    this.getExportYield = this.getExportYield.bind(this);
  }

  addYieldDAL(cutTicketId, body) {
    return super.execute(this.Urls.yield.create.replace('{id}', cutTicketId), this.ApiMethod.post, body);
  }

  editYieldDAL(id, body) {
    return super.execute(this.Urls.yield.update + '/' + id, this.ApiMethod.put, body);
  }

  getExportBom(styleId) {
      return this.Urls.yield.exportBom +`/${styleId}`
  }

  getExportYield(orderId) {
      return this.Urls.yield.exportYield +`/${orderId}`
  }
}

export default new Yield();
