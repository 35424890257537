import { combineReducers } from 'redux';
import C from '../../../constants/accounting/payable/createInvoiceImpExpDeclaration';
import _ from 'lodash';
import moment from 'moment';
/**
 * Store name of import/export declaration
 */
const name = (state = '', action) => {
  const { payload, type } = action;
  switch (type) {
    case C.CHANGE_NAME_FIELD:
      return payload;
    case C.RESET_DATA:
      return ''
    default:
      return state;
  }
}

/**
 * Store input information for invoice no field
 */
const invoiceNo = (state = '', action) => {
  const { payload, type } = action;
  switch (type) {
    case C.CHANGE_INVOICE_NO:
      return payload;
    case C.RESET_DATA:
      return ''
    default:
      return state;
  }
}

/**
 * Store input information for issued date field
 */
const invoiceDate = (state, action) => {
  const { payload, type } = action;
  switch (type) {
    case C.CHANGE_INVOICE_DATE:
      return payload;
    case C.RESET_DATA:
      return ''
    default:
      return moment().format();
  }
}

/**
 * Store name of import/export declaration
 */
const otherFees = (state = 0, action) => {
  const { payload, type } = action;
  switch (type) {
    case C.CHANGE_ORDER_FEES:
      return payload
    case C.RESET_DATA:
      return ''
    default:
      return state;
  }
}

/**
 * Store name of import/export declaration
 */
const note = (state = '', action) => {
  const { payload, type } = action;
  switch (type) {
    case C.CHANGE_NOTE:
      return payload;
    case C.RESET_DATA:
      return '';
    default:
      return state;
  }
}

/**
 * Store helper information to check whether list of declaration numbers is fetching from api
 */
const fetchingDeclarations = (state = false, action) => {
  const { payload, type } = action;
  switch (type) {
    // TODO. handle related actions
    default:
      return state;
  }
}

/**
 * Store a list of declaration numbers that will be used to check in side menu
 */
const declarations = (state = [], action) => {
  const { payload, type } = action;
  switch (type) {
    case C.CHANGE_LIST_DECLARATION:
      return payload;
    case C.RESET_DATA:
      return []
    default:
      return state;
  }
}

/**
 * Store a list of checked declaration No
 */
const checkedDeclarations = (state = [], action) => {
  const { payload, type } = action;
  switch (type) {
    case C.CHANGE_CHECKED_LIST:
      const { _id, checked } = payload;
      let filtered = _.cloneDeep(state).filter(it => it !== _id);
      if (checked) filtered = [...filtered, _id];
      return filtered;
    case C.RESET_DATA:
      return []
    default:
      return state;
  }
}

/**
 * Store a list of active declaration No
 */
const activeDeclarations = (state = [], action) => {
  const { payload, type } = action;
  switch (type) {
    // TODO. handle related actions
    default:
      return state;
  }
}

/**
 * Store a helper information to check whether informations is post to api for saving...
 */
const saving = (state = false, action) => {
  const { payload, type } = action;
  switch (type) {
    // TODO. handle related actions
    default:
      return state;
  }
}

const searchText = (state = '', action) => {
  const { type, payload } = action;
  switch (type) {
    case C.CHANGE_SEARCH_DECLARATION_VALUE:
      return payload;
    case C.RESET_DATA:
      return ''
    default:
      return state;
  }
}

const success = (state = false, action) => {
  const { type, payload } = action;
  switch (type) {
    case C.CREATE_INVOICE_SUCCESS:
      return payload;
    case C.RESET_DATA:
      return false
    default:
      return state
  }
}

const activeScreenPreview = (state = false, action) => {
  const {type, payload} = action;
  switch(type) {
    case C.ACTIVE_SCREEN_STATUS:
      return payload;
    default:
      return state;
  }
}

export const createPayableInvoiceImpExpDeclarations = combineReducers({
  name,
  invoiceNo,
  invoiceDate,
  otherFees,
  note,

  fetchingDeclarations,
  declarations,
  checkedDeclarations,
  activeDeclarations,
  searchText,
  saving,
  success,
  activeScreenPreview,
});
