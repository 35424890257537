import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {toastr} from 'react-redux-toastr'
import { bindActionCreators } from 'redux'
import BaseComponent from '../../base/BaseComponent'
import I18n from 'i18n-js'
import TableList from "../../common/datatables/TableList"
import {Pagination} from "react-bootstrap"
import SearchBar from '../../common/layout/SearchBar';
import {ACTION_TYPE, COMPONENT_TYPE, PRODUCTION_SCREEN, FOLLOWUP_SEARCH_KEY} from "../../../constants/constants"
import {SEARCH_LIST, SEARCH_BAR_EVENT, SHIPPING_SCREEN} from "../../../constants/constants";
import appActions from '../../../actions/appActions';
import ModalNotifyPacking from "../../common/modals/ModalNotifyPacking";
import * as types from '../../../constants/actionTypes'
import {calculateTotal} from "../../../functions/utils";
import purchaseApi from '../../../api/purchaseApi';
import {PURCHASE_STATUS} from "../../../constants/constants";
import ModalShipOutINVForm from "../../common/modals/ModalShipOutINVForm";
import ModalCustomerForm from "../../common/modals/ModalCustomerForm";
import CustomPagination from '../../common/datatables/CustomPagination'

class TabFollowUp extends BaseComponent {
  constructor(props, context) {
    super(props, context)

    this.state = {
      notifies: [],
      items: [],
      total: 0,
      pageSize: 10,
      search: {key: FOLLOWUP_SEARCH_KEY.CUSTOMER_PO, value: '', beginDate: '', endDate: ''},
      pageNumber: 1,
      columns: [
        {name: "", type: 'checkbox', value: 'check', options: {event: this.onCheck}},
        {name: I18n.t("PageShiping.No"), value: 'no'},
        {name: I18n.t("PageShiping.PurchaseDate"),  type: 'date', formatType: 'MMM DD, YYYY', value: 'createdAt'},
        {name: I18n.t("PageShiping.CustomerPO"), value: 'style.order.customerPO'},
        {name: I18n.t("PageShiping.Style"), value: 'style.style'},
        // {name: I18n.t("PageShiping.PurchaseDate"),  type: 'date', formatType: 'MMM DD, YYYY', value: 'createdAt'},
        {name: I18n.t("PageShiping.PurchaseOrderNo"), value: 'purchaseNo'},
        {name: I18n.t("PageShiping.Supplier"), value: 'supplier.name'},
        {name: I18n.t("PageShiping.ExDate"), type: 'date', formatType: 'MMM DD, YYYY', value: 'exReceivedDate'},
        {name: I18n.t("PageShiping.Amount"), value: 'amount', options: {clsTd: 'color-red'}},
        {name: I18n.t("PageShiping.Type"), value: 'type'},
      ]
      ,actions: [],
    };
  }

  onCheck = (item, name, value) => {
    const {items, notifies} = this.state
    let bool = false
    for (const vl of items) {
      if(vl._id == item._id) {
        item[name] = value
        const idx = _.findIndex(notifies, n => n._id == vl._id)
        if(value) {
          idx < 0 && notifies.push(vl)
        } else {
          idx >= 0 && notifies.splice(idx, 1)
        }
        bool = true
        break
      }
    }
    bool && this.setState({items, notifies});
  }

  notify = () => {
    const {items, notifies} = this.state

    const screen = SHIPPING_SCREEN.CREATE_SHIPMENT
    const data = _.sortBy(notifies, 'style.style')
    const pageData = _.assign(_.cloneDeep(this.props.appState.page), 
      {isAdd: true}, 
      {screen, data}
    );

    this.props.appPropsActions.setPageCurrent(pageData);
  }

  editItem(item) {
      const { openModal, closeModal } = this.props.modal;
      let modal = (<ModalShipOutINVForm closeModal={closeModal} shipOutInvDetail={item}/>);
      openModal(modal);
  }

  // resetItems = items => {
  //   const {pageSize, pageNumber, notifies} = this.state;
    
  //   return items.map((item, idx) => {
  //     const idxN = _.findIndex(notifies, n => n._id == item._id)
      
  //     let amount = 0, exReceivedDate = ''
  //     if (item.materials && item.materials.length > 0) {
  //       let temp = item.materials.sort((a, b) => new Date(a.exReceivedDate) - new Date(b.exReceivedDate));
  //       amount = this.setCurrency(calculateTotal(item).toFixed(2), (item.supplier || {}).currency);
  //       exReceivedDate = temp[0].exReceivedDate
  //     }
  //     return {...item, check: idxN >= 0 ? true : false,  amount, no: (pageSize * (pageNumber - 1)) + idx + 1, exReceivedDate}
  //   })
  // }

  // setCurrency = (amount, currency) => {
  //   if(currency == 'VND') {
  //     return amount + ' VND';
  //   }
  //   if(currency == 'USD') {
  //     return '$' + amount;
  //   }
  //   return amount
  // }

  // getBaseOption = () => {
  //   const {pageSize, pageNumber} = this.state;

  //   let options = {
  //     query: JSON.stringify({}),
  //     sort: JSON.stringify({createdAt:-1}), 
  //     populate: 'materials.material,styleId|orderId|customer,supplierId',
  //     pageSize, 
  //     pageNumber,
  //   };
  //   return options
  // }

  // onChangeSearch = (property, value, pos) => {
  //   const { search } = this.state;
  //   switch(property) {
  //     case SEARCH_BAR_EVENT.FILTER: {
  //       this.setState({search: {key: value, value: ""}});
  //       break;
  //     }
  //     case SEARCH_BAR_EVENT.SEARCH: {
  //       if( this.state.search.key ==='orderDate'){
  //         if( pos === 'from'){
  //           search.beginDate = value
  //           this.setState({ search })      
  //         }
  //         if( pos === 'to'){
  //           search.endDate = value
  //           this.setState({ search })
  //         }
  //       }else{
  //         this.setState({search: {...this.state.search,value : value }});
  //       }
  //       break;
  //     }
  //   }
  // }

  // onSearch = (page = null) => {
  //   const {search} = this.state;
  //   const options = this.getBaseOption()
  //   options.query = JSON.parse(options.query)
  //   options.pageNumber = page != null ? page : options.pageNumber
  //   // if(search && search.key && search.value) {
  //   //   let qr = {}
  //   //   switch(search.key) {
  //   //     case FOLLOWUP_SEARCH_KEY.TYPE: {
  //   //       qr = {type: {$regex: '.*' + search.value + '.*', $options: 'i'}}
  //   //       options.query = qr
  //   //       break;
  //   //     }
  //   //     case FOLLOWUP_SEARCH_KEY.PURCHASE_ORDER_NO: {
  //   //       qr = {purchaseNo: {$regex: '.*' + search.value + '.*', $options: 'i'}}
  //   //       options.query = qr
  //   //       break;
  //   //     }
  //   //   }
  //   // }

  //   this.props.dispatch({type: types.APP.SHOW_LOADING})
  //   // const options = {}
  //   purchaseApi.search(search, options, FOLLOWUP_SEARCH_KEY).then(rs => {
  //     this.props.dispatch({type: types.APP.HIDE_LOADING})
      
  //     if(rs.success) {
  //       this.setState({
  //         items: this.resetItems(_.get(rs, 'data.items', {})),
  //         total: _.get(rs, 'data.count', 0)
  //       })
  //     } else toastr.error(I18n.t('Notify.Error'), rs.error)
  //   },
  //   err => {
  //     this.props.dispatch({type: types.APP.HIDE_LOADING})
  //     toastr.error(I18n.t('Notify.Error'), err.error)
  //   })
  // }
  

  // getList = options => {
  //   this.props.dispatch({type: types.APP.SHOW_LOADING})
  //   // const options = {}
  //   purchaseApi.search({}, options, null).then(rs => {
  //     this.props.dispatch({type: types.APP.HIDE_LOADING})
      
  //     if(rs.success) {
  //       this.setState({
  //         items: this.resetItems(_.get(rs, 'data.items', {})),
  //         total: _.get(rs, 'data.count', 0)
  //       })
  //     } else toastr.error(I18n.t('Notify.Error'), rs.error)
  //   },
  //   err => {
  //     this.props.dispatch({type: types.APP.HIDE_LOADING})
  //     toastr.error(I18n.t('Notify.Error'), err.error)
  //   })
  // }

  // selectPage = page => {
  //   this.setState({pageNumber: page})
  //   // const options = {
  //   //   ...this.getBaseOption(),
  //   //   pageNumber: page
  //   // }
  //   this.onSearch(page)
  // }

  // componentWillMount = () => {
    // this.getList(this.getBaseOption())
  // }
  selectPage = page => {
    const { onSearch } = this.props;
    const { search } = this.state;
    this.setState({pageNumber: page})
    onSearch(page, search).then(value => {
      this.setState(value);
    })
  }

  changeSearch = (property, value, pos) => {
    const { onChangeSearch } = this.props;
    const { search } = this.state;
    const newSearch = onChangeSearch(search, property, value, pos);
    this.setState({search: newSearch})
  }

  search = () => {
    const { onSearch } = this.props;
    const { pageNumber, search } = this.state;
    onSearch(pageNumber, search).then(value => {
      this.setState(value);
    });
  }
  componentWillMount = async () => {
    const { onSearch } = this.props;
    const { pageNumber } = this.state;
    onSearch(pageNumber, {}).then(value => {
      this.setState(value);
    })
  }

  render = () => {
    let {columns, actions, pageNumber, pageSize, items, total} = this.state;
    let { roles: {showView, showEdit} } = this.props;
    // const {items, total} = purchase;
    const fields = SEARCH_LIST.FOLLOWUP_SEARCH_LIST
    let totalPage = _.ceil(total / pageSize);
    let tabPanelClass = this.props.active ? "tab-pane active" : "tab-pane";
    actions= [];
    if(showView){
      actions.push(() => {
        return { type: 'view', click: (item) => this.editItem(item) };
      })
    };
    return (
        <div className={tabPanelClass} id="shipping-follow-up">
        <div className="wrap-search-position">
          <SearchBar fields={fields} onChangeSearch={this.changeSearch} onSearch={this.search}/>
        </div>
        <div>
          <TableList {...this.props} items={items || []} columns={columns || []} actions={actions || []} options={{clsTbl: 'global-table'}}/>
          
          <CustomPagination items={totalPage} activePage={pageNumber} onSelect={this.selectPage} />

          { showEdit && <div className="center mg-top-15 clearBoth">
            <button onClick={this.notify} className="bt-orange text-uppercase" disabled={(items.length) && !items.reduce((acc, curr) => 
              {
                Object.keys(curr).map(key => {
                  if (key == 'check') {
                    acc[key] = acc[key] || curr[key];
                  }
                });
                return acc;
              }, {}
            ).check}>{I18n.t('PageShiping.CreateShipment')}</button>
          </div>}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    appState: state.appState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    appPropsActions: bindActionCreators(appActions, dispatch),    
    dispatch
  };
}

TabFollowUp.propTypes = {
};

export default connect(mapStateToProps, mapDispatchToProps)(TabFollowUp);