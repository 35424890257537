import _ from 'lodash';
import * as R from 'ramda';
import { createSelector } from 'reselect';


export const getPageNumber = ({ orderState }) => orderState.pageNumber;
export const getFilterText = ({ orderState }) => orderState.filterText;
export const isOpenLoadMore = ({ orderState }) => orderState.isLoadMore;
export const isSearch = ({ orderState }) => orderState.isSearch;
export const getOrders = ({ orderState }) => orderState.items;
export const isLoading = ({ orderState }) => orderState.loading;
