import _ from 'lodash';
import * as R from 'ramda';
import { createSelector } from 'reselect';

const isSuperAdmin = ({ authState, moduleKey }) => {
    const { auth } = authState;
    const isSupperAdmin = R.path(['isSupperAdmin'], auth);
    return isSupperAdmin;
  }
  
  const getRoleModule = ({ authState, moduleKey }) => {
    const { auth } = authState;
    const modules = R.pathOr([], ['role', 'modules'], auth);
    const mod = modules.find(it => R.path(['moduleId', 'key'], it) === moduleKey);
    return mod;
  }
  
  export const isCreate = createSelector(
    [isSuperAdmin, getRoleModule],
    (admin, mod) => {
      if (admin) return true;
      if (!mod) return false;
      const addPerm = R.path(['actions', 'create'], mod);
      return addPerm || false;
    }
  );
  export const isSpecial = createSelector(
    [isSuperAdmin, getRoleModule],
    (admin, mod) => {
      if (admin) return true;
      if (!mod) return false;
      const addPerm = R.path(['actions', 'special'], mod);
      return addPerm || false;
    }
  );
  export const isView = createSelector(
    [isSuperAdmin, getRoleModule],
    (admin, mod) => {
      if (admin) return true;
      if (!mod) return false;
      const addPerm = R.path(['actions', 'view'], mod);
      return addPerm || false;
    }
  );

  export const isRevise = createSelector(
    [isSuperAdmin, getRoleModule],
    (admin, mod) => {
      if (admin) return true;
      if (!mod) return false;
      const addPerm = R.path(['actions', 'revise'], mod);
      return addPerm || false;
    }
  );

  export const isDelete = createSelector(
    [isSuperAdmin, getRoleModule],
    (admin, mod) => {
      if (admin) return true;
      if (!mod) return false;
      const addPerm = R.path(['actions', 'delete'], mod);
      return addPerm || false;
    }
  );