import { createSelector } from 'reselect';
import _ from 'lodash';
import formatCurrency from 'format-currency';

export const getInvoiceDetail = ({ historyPreviewSupplierOversea }) => historyPreviewSupplierOversea.invoiceDetail || {};

export const getCurrency = createSelector(
    [
        getInvoiceDetail
    ], data => {
        return data.invoiceDetail && data.invoiceDetail.supplier.currency;
    }
)

export const getCurrencyTypeUSD = createSelector(
    [
        getCurrency
    ], (currency) => {
        return currency && currency === "USD" ? '$' : '';
    }
)

export const getCurrencyTypeVND = createSelector(
    [
        getCurrency
    ], (currency) => {
        return currency && currency === "VND" ? ' VND' : '';
    }
)

export const getListItems = createSelector(
    [
        ({ historyPreviewSupplierOversea }) => historyPreviewSupplierOversea,
        getCurrencyTypeUSD,
        getCurrencyTypeVND
    ], (historyPreviewSupplierOversea, currencyTypeUSD, currencyTypeVND) => {
        if (historyPreviewSupplierOversea.invoiceDetail) {
            const { purchaseOrders } = historyPreviewSupplierOversea.invoiceDetail.invoiceDetail;
            let results = [];
            purchaseOrders.map(item => item.materials.map(m => {
                results.push({
                    ...m,
                    priceText: currencyTypeUSD + formatCurrency(_.round((m.price || 0), 2)) + currencyTypeVND,
                    amount: currencyTypeUSD + formatCurrency(_.round((m.price || 0) * (m.receiveQuantity || 0), 2)) + currencyTypeVND,
                    amountNumber: (m.price || 0) * (m.receiveQuantity || 0),
                    purchaseNo: _.get(item, "purchaseOrder.purchaseNo", ""),
                    material: m.material.name || ''
                })
            }))
            return results;
        }
        return []
    }
)

export const getAmount = createSelector(
    [
        getListItems
    ], data => {
        return _.sum(data.map(item => item.amountNumber || 0))
    }
)

export const getInvoiceNo = createSelector(
    [
        getInvoiceDetail
    ], (data) => {
        return data.invNo
    }
)

export const getIssuedDate = createSelector(
    [
        getInvoiceDetail
    ], (data) => {
        return data.issuedDate //moment(data.issuedDate).format("MMM DD, YYYY");
    }
)

export const getSupplier = createSelector(
    [
        getInvoiceDetail
    ], (data) => {
        return data.invoiceDetail && data.invoiceDetail.supplier.name
    }
)

export const getPaid = createSelector(
    [
        getInvoiceDetail
    ], (data) => {
        return data.invoiceDetail && data.paid
    }
)

export const getActiveScreenStatus = ({historyPreviewSupplierOversea}) => historyPreviewSupplierOversea.activeScreen;