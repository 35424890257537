import React from "react";
import PropTypes from "prop-types";
import * as R from 'ramda';
import _ from "lodash";
import SRC from "../../../constants/assets";


const SidebarItemList = props => (
  <div className="content-sidebar top-initial left-0" style={{height: 'auto'}}>
    <div className="md content-sidebar-search">
      <input
        type="text" 
        value={props.searchText}
        onChange={e => props.changeSearchText(e.target.value)}
        placeholder={props.placeholder}
      />
      <span>
        <i className="fa fa-search" aria-hidden="true" />
      </span>
    </div>

    <div className="md content-sidebar-menu" style={{height: 'auto'}}>
      <ul className="overflow-y-scroll m-h-full">
        {_.map(props.items, (item, index) => (
          <li className="style-checkbox" key = {index}>
            <input
              type="checkbox"
              disabled = {props.disableChangeValue}
              id={R.path(['_id'], item)}
              className="display-none"
              checked={R.path(['checked'], item)}
              onChange={e => props.changeItemChecked(item, e.target.checked)}
            />
            <label htmlFor={R.path(['_id'], item)}>
              <span className="mg-right-10">
                <span />
              </span>
            </label>
            <span>{R.path(['name'], item)}</span>

            {R.path(['warning'], item) && 
              <img src={SRC.IC_ALERT} className="icon-alert"/>
            }
          </li>
        ))}
      </ul>
    </div>
  </div>
);

SidebarItemList.propTypes = {
  searchText: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.any),
  placeholder: PropTypes.string,
  changeSearchText: PropTypes.func,
  changeItemChecked: PropTypes.func,
}

SidebarItemList.defaultProps = {
  searchText: '',
  items: [],
  placeholder: 'Search',
  changeSearchText: f => f,
  changeItemChecked: (i, v) => v,
}

export default SidebarItemList;
