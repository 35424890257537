import { createSelector } from 'reselect';
import _ from 'lodash';

export const getSupplier = ({addSupplier}) => addSupplier.supplier;

export const getName = ({addSupplier}) => addSupplier.name;

export const getShortName = ({addSupplier}) => addSupplier.shortName;

export const getBankAccountNo = ({addSupplier}) => addSupplier.bankAccountNo;

export const getBankBranch = ({addSupplier}) => addSupplier.bankBranch;

export const getCompanyName = ({addSupplier}) => addSupplier.companyName;

export const getType = ({addSupplier}) => addSupplier.type;

export const getCompanyAddress = ({addSupplier}) => addSupplier.companyAddress;

export const getDeliveryDeviation = ({addSupplier}) => addSupplier.deliveryDeviation;

export const getPhoneNumber = ({addSupplier}) => addSupplier.phoneNumber;

export const getPaymentTerm = ({addSupplier}) => addSupplier.paymentTerm;

export const getFax = ({addSupplier}) => addSupplier.fax;

export const getCurrency = ({addSupplier}) => addSupplier.currency;

export const getEmail = ({addSupplier}) => addSupplier.email;

export const getMaterials = ({addSupplier}) => addSupplier.materials;

export const getSelectedMaterials = ({addSupplier}) => addSupplier.selectedMaterials;

export const getSizes = createSelector(
    [
        getMaterials,
        getSelectedMaterials
    ],
    (materials, selectedMaterials) => (index) => {
        if(!materials.length) return [];
        let id = selectedMaterials[index].material;
        if(!id) return [];
        let i = _.findIndex(materials, el => el.id == id || el._id == id);
        if(i < 0) return [];
        const sizes = materials[i].data.size || [];
        const sizesObject = sizes.map(s => {
            return { label: s, value: s };
        });
        return sizesObject;
    }
)

export const getColors = createSelector(
    [
        getMaterials,
        getSelectedMaterials
    ],
    (materials, selectedMaterials) => (index) => {
        if(!materials.length) return [];
        let id = selectedMaterials[index].material;
        if(!id) return [];
        let i = _.findIndex(materials, el => el.id == id || el._id == id);
        if(i < 0) return [];
        const colors = materials[i].data.color || [];
        const colorsObject = colors.map(c => {
            return { label: c, value: c };
        });
        return colorsObject;
    }
)