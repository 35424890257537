import React from 'react';
import PropTypes from "prop-types";
import moment from 'moment';
import FormGroup from '../../forms/FormGroup';
import Input from '../../forms/Input';
import SRC from '../../../../constants/assets';
import _ from 'lodash';
const formatCurrency = require('format-currency');
const DateTimeField = require('react-datetime');

const EditIEContainer = props => {
    const {dataApi, dueDate, issuedDate, paymentType, amount, paid, remaining, emptyReceipt, receiptFromInvoice} = props
    // console.log(receiptFromInvoice);
    return (
        <div>
            <ul className="content-header-title float-none pd-0-5 justify-content-space-between display-flex">
                <li>
                    <p>Company
                        <span className="color-orange medium mg-left-25 size-16">{props.company}</span>
                    </p> 
                </li>
                <li className="add-filter-top">
                    <span className="cursor-pointer" onClick = {() => props.save(paymentType, dataApi)}>
                        <i className="fa fa-floppy-o" aria-hidden="true"></i>{"SAVE"}
                    </span>
                </li>
            </ul>
            <div className="wrap-input-global pd-15-0 margin-small bg-white page-edit-invoice-list box-shadow">
                <div className="pd-0 col-xs-9 left">
                    <div className="col-md-6 col-xs-12 input">
                        <FormGroup
                            label="Name"
                            type="input"
                            inputComponent={
                                <Input
                                    type="text"
                                    value={props.name}
                                    onChange={e =>
                                        props.changeName(e.target.value)
                                    }
                                    placeholder=""
                                />
                            }
                        />
                    </div>
                    <div className="col-md-6 col-xs-12 input">
                        <FormGroup
                            type="input"
                            label="Issued Date"
                            inputComponent={
                                <DateTimeField
                                    timeFormat={false} closeOnSelect dateFormat="MMM DD, YYYY"
                                    value={moment(issuedDate)}
                                    onChange={date =>
                                        props.changeIssuedDate(date.format())
                                    }
                                />
                            }
                        />
                    </div>
                    <div className="col-md-6 col-xs-12 input">
                        <FormGroup
                            type="input"
                            label="Invoice No."
                            inputComponent={
                                <Input
                                    type="text"
                                    value={props.invoiceNo}
                                    onChange={e =>
                                        props.changeInvoiceNo(e.target.value)
                                    }
                                    placeholder=""
                                />
                            }
                        />
                    </div>
                    <div className="col-md-6 col-xs-12 input">
                        <FormGroup
                            type="input"
                            label="Due Date"
                            inputComponent={
                                <DateTimeField
                                    timeFormat={false} closeOnSelect dateFormat="MMM DD, YYYY"
                                    value={moment(dueDate)}
                                    onChange={date =>
                                        props.changeDueDate(date.format())
                                    }
                                />
                            }
                        />
                    </div>
                    <div className="col-md-6 col-xs-12 input">
                        <FormGroup
                            type="input"
                            label="Pay Amount"
                            inputComponent={
                                <Input
                                    type="number"
                                    min = "0"
                                    value={props.payAmount}
                                    onChange={e =>
                                        props.changePayAmount(_.toInteger(e.target.value), remaining, paid)
                                    }
                                    placeholder=""
                                />
                            }
                        />
                    </div>
                </div>

                <div className="pd-0 col-xs-3 right">
                    <ul className="type-supplier">
                        <li onClick = {() => props.changePaymentType('cash')} className={paymentType === "cash" ? "active" : "cursor-pointer"}>
                            <span className="cursor-poniter">
                                <span className="icon">
                                <span />
                                </span>
                                <span className="text">Cash</span>
                            </span>
                        </li>

                        <li onClick = {() => props.changePaymentType('transfer')} className={paymentType === "transfer" ? "active" : "cursor-pointer"}>
                            <span
                                className="cursor-poniter"
                            >
                                <span className="icon">
                                <span />
                                </span>
                                <span className="text">Transfer</span>
                            </span>
                        </li>
                    </ul>
                    {paymentType === "transfer" && 
                    <span>
                        <div className = "mg-right-15">
                            <span className="color-link text-decoration cursor-pointer"
                                onClick = {() => props.openTransferModal()}
                            >
                                Upload File
                            </span>
                        </div>
                        <p className="text-align-left mg-bottom-15">
                            Files: {(dataApi.files || []).length}
                        </p>
                    </span>
                    }
                    {paymentType === "cash" &&  
                        <span>
                            { emptyReceipt && <div className = "mg-right-15">
                                <span className="color-link text-decoration cursor-pointer"
                                    onClick = {() => props.openReceiptModal()}
                                >
                                    Add Receipt
                                </span>
                            </div>}
                            {!emptyReceipt && <p className="text-align-left mg-bottom-15">
                            <span href="" className="color-link text-decoration size-12 cursor-pointer"
                                onClick = {() => props.openReceiptModal()}
                            >{receiptFromInvoice.no}</span>
                            </p>}
                        </span>
                    }
                    <div className="text-align-left mg-bottom-5">
                        <p>
                        <span className="mg-right-15 size-12">Total Amount INV</span>
                        <span className="bold color-number size-14">{formatCurrency(amount)} VND</span>
                        </p>
                    </div>

                    <div className="text-align-left mg-bottom-5">
                        <p>
                        <span className="mg-right-15 size-12">Paid</span>
                        <span className="bold size-14">{formatCurrency(paid)} VND</span>
                        </p>
                    </div>  

                    <div className="text-align-left mg-bottom-5">
                        <p>
                        <span className="mg-right-15 size-12">Remaining</span>
                        <span className="bold size-14 color-green">{formatCurrency(remaining)} VND</span>
                        </p>
                    </div>   
                </div>
            </div>
            {props.containers.map((item, index) => {
                return (
                    <div key = {index}>
                        <ul className="bg-white content-header-title pd-left-15 bd-solid-bottom-3">
                            <li>
                                <p className="bold text-transform">{item.name}</p>
                            </li>
                            <li className="float-right">
                                <a onClick = {() => {props.changeContainerDetail(item), props.onOpenModal()}}><img src={SRC.IC_EDIT_X} className="mg-right-10" /></a>
                            </li>
                        </ul>
                        <div className="colum-2 layout plan-container mg-0 bd-solid-bottom-3">
                            {/*<div className="container-colum">*/}
                                {/*<div className="container-colum-2">*/}
                                    {/*<div className="top bd-dashed-bottom inline-block w-full">*/}

                                        {/*<div className="col-md-6 col-xs-12 mg-bottom-15">*/}
                                            {/*<p>*/}
                                                {/*<span className="text">Cost</span> <span className="color-number bold size-14 value">{formatCurrency(item.cost)}</span>*/}
                                            {/*</p>*/}
                                        {/*</div>*/}
                                        {/*<div className="col-md-6 col-xs-12 mg-bottom-15">*/}
                                            {/*<p>*/}
                                                {/*<span className="text">Charges Incurred</span> <span className="bold value">{formatCurrency(item.chargesIncurred)}</span>*/}
                                            {/*</p>*/}
                                        {/*</div>*/}
                                        {/*<div className="col-md-6 col-xs-12 mg-bottom-5">*/}
                                            {/*<p>*/}
                                                {/*<span className="text">Lift On Fee</span> <span className="color-green bold value">{formatCurrency(item.liftOnFee)}</span>*/}
                                            {/*</p>*/}
                                        {/*</div>*/}
                                        {/*<div className="col-md-6 col-xs-12 mg-bottom-5">*/}
                                            {/*<p>*/}
                                                {/*<span className="text">Lift On INV</span> <span className="bold value">{item.liftOnInv}</span>*/}
                                            {/*</p>*/}
                                        {/*</div>*/}
                                        {/*<div className="col-md-6 col-xs-12 mg-bottom-5">*/}
                                            {/*<p>*/}
                                                {/*<span className="text">Lift Off Fee</span> <span className="color-green bold value">{formatCurrency(item.liftOffFee)}</span>*/}
                                            {/*</p>*/}
                                        {/*</div>*/}
                                        {/*<div className="col-md-6 col-xs-12 mg-bottom-5">*/}
                                            {/*<p>*/}
                                                {/*<span className="text">Lift Off INV</span> <span className="bold value">{item.liftOffInv}</span>*/}
                                            {/*</p>*/}
                                        {/*</div>*/}

                                    {/*</div>*/}
                                    {/*<div className="bottom">*/}
                                        {/*<p>*/}
                                            {/*<span className="text w-auto mg-right-15">Note</span> <span className="bold value">{item.note}</span>*/}
                                        {/*</p>*/}
                                    {/*</div>*/}
                                {/*</div>*/}
                                {/*<div className="container-colum-2 position-relative top-30">*/}
                                    {/*<div className="wrap">*/}

                                        {/*<div className="col-md-6 col-xs-12">*/}
                                            {/*<p>*/}
                                                {/*<span className="text">Quantity</span> <span className="bold value">{item.quantity}</span>*/}
                                            {/*</p>*/}
                                        {/*</div>*/}
                                        {/*<div className="col-md-6 col-xs-12">*/}
                                            {/*<p>*/}
                                                {/*<span className="text">Warehouse Addr</span> <span className="bold text-transform value">{item.warehouseAddress}</span>*/}
                                            {/*</p>*/}
                                        {/*</div>*/}
                                        {/*<div className="col-md-6 col-xs-12">*/}
                                            {/*<p>*/}
                                                {/*<span className="text">Type</span> <span className="bold value">{item.kind}</span>*/}
                                            {/*</p>*/}
                                        {/*</div>*/}
                                        {/*<div className="col-md-6 col-xs-12">*/}
                                            {/*<p>*/}
                                                {/*<span className="text">Truck No</span> <span className="bold">{item.truckNo}</span>*/}
                                            {/*</p>*/}
                                        {/*</div>*/}
                                        {/*<div className="col-md-6 col-xs-12">*/}
                                            {/*<p>*/}
                                                {/*<span className="text">Route</span> <span className="bold value">{item.route}</span>*/}
                                            {/*</p>*/}
                                        {/*</div>*/}
                                        {/*<div className="col-md-6 col-xs-12">*/}
                                            {/*<p>*/}
                                                {/*<span className="text">Pickup Cont</span> <span className="bold value">{item.pickupCont}</span>*/}
                                            {/*</p>*/}
                                        {/*</div>*/}
                                        {/*<div className="col-md-6 col-xs-12">*/}
                                            {/*<p>*/}
                                                {/*<span className="text">Date</span> <span className="bold value">{moment(item).format('MMM DD, YYYY')}</span>*/}
                                            {/*</p>*/}
                                        {/*</div>*/}
                                        {/*<div className="col-md-6 col-xs-12">*/}
                                            {/*<p>*/}
                                                {/*<span className="text">Drop Off Cont</span> <span className="bold value">{item.dropOffCont}</span>*/}
                                            {/*</p>*/}
                                        {/*</div>*/}
                                    {/*</div>*/}
                                {/*</div>*/}
                                {/*<div className="bg-line-colum" />*/}
                            {/*</div>*/}
                        </div>
                    </div>
                )
            })}
            
    </div>
    )
}

EditIEContainer.propTypes = {
    dataApi: PropTypes.object,
    company: PropTypes.string,
    name: PropTypes.string,
    invoiceNo: PropTypes.string,
    issuedDate: PropTypes.string,
    dueDate: PropTypes.string,
    payAmount: PropTypes.number,
    paymentType: PropTypes.string,
    containers: PropTypes.array,
    amount: PropTypes.number,
    paid: PropTypes.number,
    remaining: PropTypes.number,
    emptyReceipt: PropTypes.bool,
    receiptFromInvoice: PropTypes.object,
    //func
    changeName: PropTypes.func,
    changeIssuedDate: PropTypes.func,
    changePayAmount: PropTypes.func,
    changeDueDate: PropTypes.func,
    changeInvoiceNo: PropTypes.func,
    changePaymentType: PropTypes.func,
    changeContainerDetail: PropTypes.func,
    addFile: PropTypes.func,
    openReceiptModal: PropTypes.func,
}

EditIEContainer.defaultProps = {
    containers: [],
    payAmount: 0,
    amount: 0,
    dataApi: {},
    paymentType: 'cash',
    emptyReceipt: false,
    receiptFromInvoice: {},
    changeName: f => f,
    changeIssuedDate: f => f,
    changePayAmount: f => f,
    changeDueDate: f => f,
    changeInvoiceNo: f => f,
    changePaymentType: f => f,
    changeContainerDetail: f => f,
    addFile: f => f,
    openReceiptModal: f => f,
}

export default EditIEContainer;