import React, { Component } from 'react';
import PreviewIEContainerView from '../../../components/accounting/payable/PreviewIEContainer';
import I18n from 'i18n-js';
import { connect } from 'react-redux';
import * as selector from '../../../../selectors/accounting/payable/historyPreviewContainer';
import * as actions from '../../../../actions/accounting/payable/historyPreviewContainer';
import moment from 'moment';
import {withRouter} from "react-router";
class PreviewIEContainer extends Component {

    constructor (props) {
        super(props)
        this.state = {
            listIds: this.props.listIds,
            items: [],
            pageNumber: 1,
            pageSize: 10,
            columns: [
                { name: 'Cont No', value: 'contNo', type: 'text' },
                { name: 'Route', value: 'route', type: 'text' },
                { name: 'Warehouse Addr', value: 'warehouseAddress', type: 'text' },
                { name: 'Pickup Cont', value: 'pickupCont', type: 'text' },
                { name: 'Drop Off Cont', value: 'dropOffCont', type: 'number' },
                { name: 'Truck No', value: 'truckNo', type: 'number' },
                { name: 'Cost', value: 'cost', type: 'number' },
                { name: 'Charges Incurred', value: 'chargesIncurred', type: 'number' },
                { name: 'Lift On Fee', value: 'liftOnFee', type: 'number' },
                { name: 'Lift On INV', value: 'liftOnInv', type: 'text' },
                { name: 'Lift Off Fee', value: 'liftOffFee', type: 'number' },
                { name: 'Lift Off INV', value: 'liftOffInv', type: 'text' },
               
            ],
            actions: []
        }
    }
    
    componentDidMount() {
        this.props.resetData()
    }

    componentWillReceiveProps(nextProps) {
        const {activeScreen} = nextProps;
        if(!activeScreen) {
            nextProps.router.history.goBack();
        }
    }

    render() {
        const { columns } = this.state;
        // console.log(this.props.invoiceDetail)
        return (
            <PreviewIEContainerView columns = {columns} {...this.props} type = "historyPreview"/>
        );
    }
}

const mapStateToProps = state => ({
    invoiceDetail: selector.getInvoiceDetail(state),
    listContainer: selector.genDataForPreview(state),
    name: selector.getName(state),
    invNo: selector.getInvoiceNo(state),
    issuedDate: selector.getIssuedDate(state),
    vatAmount: selector.getVatAmount(state),
    amountINV: selector.getAmount(state),
    totalAmountINV: selector.getTotalAmount(state),
    activeScreen: selector.getActiveScreenStatus(state),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    resetData() {
        dispatch(actions.resetData())
    }
})

export default connect(mapStateToProps, mapDispatchToProps) (withRouter(PreviewIEContainer));