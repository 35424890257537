
import _ from 'lodash';
import * as R from 'ramda';

export const isShowing = ({ editCutTicketImages }) => !!editCutTicketImages.rawData;

export const getId = ({ editCutTicketImages }) => R.path(['_id'], editCutTicketImages.rawData);

export const isSubmiting = ({ editCutTicketImages }) => editCutTicketImages.submiting;

export const canSave = ({ editCutTicketImages }) => !editCutTicketImages.submiting;

export const getImages = ({ editCutTicketImages }) => {
  const { images } = editCutTicketImages;
  return _.map(images, img => ({
    id: R.path(['id'], img),
    url: R.path(['url'], img),
    raw: img,
  }));
}

export const fnGenerateBody = ({ editCutTicketImages }) => () => {
  const { images } = editCutTicketImages;
  return {
    images: _.map(images, img => (img.file ? { url: img.url, file: img.file } : img.url )),
  };
};
