
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import * as R from 'ramda';
import I18n from 'i18n-js';
import _ from 'lodash';

import EditCutTicketColorModal from '../../../components/customer-po/EditCutTicketColorModal';

import { getOrderId } from '../../../../selectors/customer-po/current/showOrderDetail';
import * as selectors from '../../../../selectors/customer-po/current/editCutTicketColors';
import * as actions from '../../../../actions/customer-po/current/editCutTicketColors';


class EditModal extends Component {
  componentWillMount() {
    this.props.loadData();
  }

  render() {
    return (
      <EditCutTicketColorModal
        title={I18n.t('PageOrder.ADDCOLOR')}
        {...this.props}
        onSubmit={() => this.props.save(this.props.id, this.props.fnBody(), this.props.orderId)}
      />
    )
  }
}

const mapStateToProps = state => ({
  id: selectors.getId(state),
  orderId: getOrderId(state),
  isOpen: selectors.isOpen(state),
  colors: selectors.getColors(state),
  selectableColors: selectors.getSuggestions(state),
  canSave: selectors.canSave(state),
  isSubmiting: selectors.isSubmiting(state),
  fnBody: selectors.fnGenerateRequestBody(state),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  loadData() {
    dispatch(actions.listSuggestions());
    dispatch(actions.listSelectableSizes());
  },

  onClose() {
    dispatch(actions.hideModal());
  },

  addColor() {
    dispatch(actions.addColor());
  },

  onChangeColorName(color, value) {
    dispatch(actions.changeColorName(R.path(['id'], color), value));
  },

  onRemoveColor(color) {
    dispatch(actions.removeColor(R.path(['id'], color)));
  },

  changeCheckedSize(color, size, checked) {
    const colorId = R.path(['id'], color);
    const sizeName = R.path(['value'], size);
    dispatch(checked ? actions.addSize(colorId, sizeName) : actions.removeSize(colorId, sizeName));
  },

  onChangeSize(color, size, value) {
    const colorId = R.path(['id'], color);
    const sizeName = R.path(['name'], size);
    const sizeValue = _.parseInt(value) || 0;
    dispatch(actions.changeSizeQuantity(colorId, sizeName, sizeValue));
  },

  save(id, body, orderId) {
    dispatch(actions.save(id, body, orderId));
  },

});

export default connect(mapStateToProps, mapDispatchToProps) (withRouter(EditModal));
