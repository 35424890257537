import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import contractorActions from '../../actions/contractorActions';
import ContractorsEdit from './modal/ContractorsEdit';
import ModalConfirm from '../common/modals/ModalConfirm';
import ContractorsAdd from './modal/ContractorsAdd';
import SearchBar from '../common/layout/SearchBar';
import BaseComponent from '../base/BaseComponent';
import TableList from '../common/datatables/TableList';
import _ from 'lodash';
import I18n from 'i18n-js';
import { SEARCH_BAR_EVENT, SEARCH_KEY, SEARCH_LIST } from "../../constants/constants";
import './style.scss';
import CustomPagination from '../common/datatables/CustomPagination';

class TabContractors extends BaseComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {
      tabActive: '',
      pageSize: 10,
      pageNumber: 1,
      columns: [
        { name: I18n.t('PageCrm.Name'), value: 'name' },
        { name: I18n.t('PageCrm.Address'), value: 'address' },
        { name: I18n.t('PageCrm.Currency'), value: 'currency' },
        { name: I18n.t('PageCrm.Director'), value: 'director' },

      ],
      items: [],
      total: 0,
      searchFields: [
        { name: I18n.t('PageCrm.Name'), field: 'name' },
        { name: I18n.t('PageCrm.Address'), field: 'address' },
        { name: I18n.t('PageCrm.Currency'), field: 'currency' }
      ],
      searchValue: {},
      search: { key: SEARCH_KEY.NAME, value: "" },
    };
    this.selectPage = this.selectPage.bind(this)
    this.onDeleteContractor = this.onDeleteContractor.bind(this);
  }

  componentWillMount() {
    this.getListItem();
  }

  componentWillReceiveProps(newProps) {
    let { items } = this.state;
    !_.isEqual(items, newProps.contractorState.items) && this.setState({
      items: _.cloneDeep(newProps.contractorState.items) || [],
      total: newProps.contractorState.count || 0
    });
    this.props.tabCurrent !== newProps.tabCurrent && this.closeFormAdd();
  }

  componentWillUpdate(nextProps, nextState) {
    if (_.get(this.props.contractorState, 'reload') != _.get(nextProps.contractorState, 'reload')) {
      this.search(nextState.search, nextState.pageNumber, nextState.pageSize);
    }
    _.get(this.props.contractorState, 'reload') != _.get(nextProps.contractorState, 'reload') && this.props.modal.closeModal();
  }

  getListItem(query = {}) {
    let { pageSize, pageNumber } = this.state;
    let _query = _.assign({ pageSize: pageSize, pageNumber: pageNumber }, query);
    this.props.contractorsActions.getListItem(_.set(_query, 'sort', { createdAt: -1 }));
  }

  addContractors(item) {
    let { openModal, closeModal } = this.props.modal;
    let modal = (<ContractorsEdit closeModal={closeModal} options={{ data: item }} />);
    openModal(modal);
  }

  editContractors(item) {
    let { openModal, closeModal } = this.props.modal;
    let modal = (<ContractorsEdit closeModal={closeModal} options={{ data: item }} />);
    openModal(modal);
  }

  showDeleteConfirm(item) {
    let { openModal, closeModal } = this.props.modal;
    let modal = (<ModalConfirm
      title={I18n.t('PageCrm.DELETECONTRACTOR')}
      confirmMessage={I18n.t('PageCrm.ConfirmDeleteContractor')}
      messageOk={I18n.t('Btn.YES')}
      messageCancel={I18n.t('Btn.CANCEL')}
      handleClose={closeModal}
      handleOk={this.onDeleteContractor.bind(null, item)} />);
    openModal(modal);
  }

  onDeleteContractor(item) {
    item._id && this.props.contractorsActions.deleteItem(item._id);
  }

  addItem(item) {
    let { openModal, closeModal } = this.props.modal;
    let modal = (<ContractorsAdd closeModal={closeModal} />);
    openModal(modal);
  }

  selectPage = (page) => {
    this.setState({ pageNumber: page });
    this.search(this.state.search, page, this.state.pageSize);
  }

  onChangeSearch(property, value) {
    switch (property) {
      case SEARCH_BAR_EVENT.FILTER: {
        this.setState({ search: { key: value, value: "" } });
        break;
      }
      case SEARCH_BAR_EVENT.SEARCH: {
        this.setState({ search: { ...this.state.search, value: value } });
        break;
      }
    }
  }

  onSearch() {
    const { search, pageNumber, pageSize } = this.state;
    this.search(search, pageNumber, pageSize);
  }
  onAction(type, data) {
    
  }
  search(search, pageNumber, pageSize) {
    let options = {};
    const populate = '';
    if (search && search.key && search.value) {
      switch (search.key) {
        case SEARCH_KEY.NAME: {
          options = {
            pageNumber,
            pageSize,
            sort: { updatedAt: -1 },
            populate: populate,
            query: { name: { $regex: '.*' + search.value + '.*', $options: 'i' } }
          };
          this.props.contractorsActions.getListItem(options).then(res => {
            if (res && res.success && res.data && res.data.items && !res.data.items.length && pageNumber > 1) {
              options = {
                pageNumber: 1,
                pageSize,
                sort: { updatedAt: -1 },
                populate: populate,
                query: { name: { $regex: '.*' + search.value + '.*', $options: 'i' } }
              };
              this.setState({ pageNumber: 1 });
              this.props.contractorsActions.getListItem(options)
            }
          });
          break;
        }
        case SEARCH_KEY.ADDRESS: {
          options = {
            pageNumber,
            pageSize,
            sort: { updatedAt: -1 },
            populate: populate,
            query: { address: { $regex: '.*' + search.value + '.*', $options: 'i' } }
          };
          this.props.contractorsActions.getListItem(options).then(res => {
            if (res && res.success && res.data && res.data.items && !res.data.items.length && pageNumber > 1) {
              options = {
                pageNumber: 1,
                pageSize,
                sort: { updatedAt: -1 },
                populate: populate,
                query: { address: { $regex: '.*' + search.value + '.*', $options: 'i' } }
              };
              this.setState({ pageNumber: 1 });
              this.props.contractorsActions.getListItem(options)
            }
          });
          break;
        }
        case SEARCH_KEY.CURRENCY: {
          options = {
            pageNumber,
            pageSize,
            sort: { updatedAt: -1 },
            populate: populate,
            query: { currency: { $regex: '.*' + search.value + '.*', $options: 'i' } }
          };
          this.props.contractorsActions.getListItem(options).then(res => {
            if (res && res.success && res.data && res.data.items && !res.data.items.length && pageNumber > 1) {
              options = {
                pageNumber: 1,
                pageSize,
                sort: { updatedAt: -1 },
                populate: populate,
                query: { currency: { $regex: '.*' + search.value + '.*', $options: 'i' } }
              };
              this.setState({ pageNumber: 1 });
              this.props.contractorsActions.getListItem(options)
            }
          });
          break;
        }
      }
    }
    if (!search.value) {
      let options = {
        pageNumber,
        pageSize,
        sort: { updatedAt: -1 },
        populate: populate,
      };
      this.props.contractorsActions.getListItem(options).then(res => {
        if (res && res.success && res.data && res.data.items && !res.data.items.length && pageNumber > 1) {
          options = {
            pageNumber: 1,
            pageSize,
            sort: { updatedAt: -1 },
            populate: populate,
          };
          this.setState({ pageNumber: 1 });
          this.props.contractorsActions.getListItem(options);
        }
      });
    }
  }

  render() {
    const { tabCurrent, roles: {showCreate, showEdit, showDelete} } = this.props;
    let { tabActive } = this.state;
    let { items, total, columns, pageNumber, pageSize, searchFields } = this.state;
    let actions = [
      (item) => {
        return showEdit ? { type: 'edit', click: (item) => this.editContractors(item) } : {};
      },
      (item) => {
        return showDelete ? { type: 'delete', click: (item) => this.showDeleteConfirm(item) } : {};
      }
    ];  
    let totalPage = _.ceil(total / pageSize);
    return (
      <div role="tabpanel" className="tab-pane" id="crm-contractors">
        <div className="wrap-search-position">
          <SearchBar
            name={I18n.t('PageCrm.AddNew')}
            addItem={showCreate ? () => this.addItem() : null}
            fields={SEARCH_LIST.CRM_CONTRACTOR}
            onChangeSearch={(type, data) => this.onChangeSearch(type, data)}
            onSearch={() => this.onSearch()}
          />
        </div>
        <TableList {...this.props} items={items || []} columns={columns || []} actions={actions || []} onAction={this.onAction}
          options={{ clsTbl: 'global-table box-shadow-none' }} widthAction="w-70" />
        <CustomPagination items={totalPage} activePage={pageNumber} onSelect={this.selectPage} />
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    appState: state.appState,
    authState: state.authState,
    contractorState: state.contractorState
  };
}

function mapDispatchToProps(dispatch) {
  return {
    contractorsActions: bindActionCreators(contractorActions, dispatch)
  };
}

TabContractors.propTypes = {
  authState: PropTypes.object.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(TabContractors);
