import { createSelector } from 'reselect';
import _ from 'lodash';

import { genVietNamDong, genDollarToText } from '../../../functions/helpers';
import formatCurrency from 'format-currency';

/**
 * Get status of fetching selectable contractors
 */
export const isFetchingSelectableContractors = ({ createPayableInvoiceContractor }) => {
  return createPayableInvoiceContractor.fetchingSelectableContractors;
};

/**
 * Get selectable contractors from reducer
 */
export const getSelectableContractors = ({ createPayableInvoiceContractor }) => {
  // return createPayableInvoiceContractor.selectableContractors;
  return _.map(createPayableInvoiceContractor.selectableContractors, s => ({
    value: s._id,
    label: s.name,
    raw: s,
  }));
};

/**
 * Get selected contractor
 */
export const getSelectedContractor = ({ createPayableInvoiceContractor }) => {
  return createPayableInvoiceContractor.selectedContractor;
};

/**
 * Get Invoice No
 */
export const getInvoiceNo = ({ createPayableInvoiceContractor }) => {
  return createPayableInvoiceContractor.invoiceNo;
};

/**
 * Get issued date
 */
export const getIssuedDate = ({ createPayableInvoiceContractor }) => {
  return createPayableInvoiceContractor.issuedDate;
};

/**
 * Get due date
 */
export const getDueDate = ({ createPayableInvoiceContractor }) => {
  return createPayableInvoiceContractor.dueDate;
};

/**
 * Get status of fetching selectable credit debit nots
 */
export const isFetchingSelectableCreditDebits = ({ createPayableInvoiceContractor }) => {
  return createPayableInvoiceContractor.fetchingSelectableCreditDebits;
};

export const getSelectableCreditDebits = ({ createPayableInvoiceContractor }) => {
  return createPayableInvoiceContractor.selectableCreditDebits;
};

export const getSelectedCreditDebit = ({ createPayableInvoiceContractor }) => {
  return createPayableInvoiceContractor.selectedCreditDebit;
};

/**
 * Get is VAT per month
 */
export const isVATPerMonth = ({ createPayableInvoiceContractor }) => {
  return createPayableInvoiceContractor.isVATPerMonth;
};

export const getSelectableConvertCurrencies = ({ createPayableInvoiceContractor }) => {
  const { selectableConvertCurrencies } = createPayableInvoiceContractor;
  return _.map(selectableConvertCurrencies, el => ({ label: el, value: el }));
};

export const getSelectedConvertCurrency = ({ createPayableInvoiceContractor }) => {
  return createPayableInvoiceContractor.selectedConvertCurrency;
};

export const getConvertRate = ({ createPayableInvoiceContractor }) => {
  return createPayableInvoiceContractor.convertRate;
};

export const getConvertCurrency = ({ createPayableInvoiceContractor }) => {
  return createPayableInvoiceContractor.selectedConvertCurrency;
};

/**
 * handle customerPO for repeat
 */
export const getCustomerPOsToMap = ({ createPayableInvoiceContractor }) => {
  let customerPOs = _.filter(createPayableInvoiceContractor.customerPOs);
  if (!customerPOs || !customerPOs.length) return [];
  let rs = [];
  _.forEach(customerPOs, item => {
    let temp = {
      customerPO: {
        name: _.get(item, 'customerPO.customerPO', null),
        id: _.get(item, 'customerPO.orderId', null),
        price: _.get(item, 'customerPO.price', 0),
        quantity: 0,
        parentId: _.get(item, '_id'),
      },
      vat: {
        vatNo: _.get(item, 'vat.vatNo', ''),
        vatDate: _.get(item, 'vat.vatDate', new Date()),
        taxRate: _.get(item, 'vat.taxRate', 0),
        totalAmount: _.get(item, 'vat.totalAmount', 0),
        note: _.get(item, 'vat.note', ''),
      },
      data: [],
    };
    _.forEach(item.styleOrders, (styleOrder, indexStyle) => {
      _.forEach(styleOrder.cutTickets, (cutTicket, indexCutTicket) => {
        _.forEach(cutTicket.colors, (color, indexColor) => {
          let totalColor = _.reduce(
            color.sizes,
            (t, size) => {
              return t + size.quantity;
            },
            0
          );
          let tmp = {
            color: {
              id: color.id,
              name: color.name,
              quantity: totalColor,
              shipMode: cutTicket.shipMode,
              shipDate: cutTicket.ihDate,
              price: color.price || 0,
              amount: totalColor * (color.price || 0),
            },
            style: {
              id: styleOrder.style._id,
              name: styleOrder.style.style,
              label: indexColor == 0 && indexCutTicket == 0 ? _.get(styleOrder, 'style.style', null) : '',
              price: _.get(styleOrder, 'style.price', 0),
            },
            cutTicket: {
              id: cutTicket.id,
              name: cutTicket.cutTicket,
              label: indexColor == 0 ? cutTicket.cutTicket : '',
            },
          };
          temp.data.push(tmp);
        });
      });
    });
    temp.customerPO.quantity = _.reduce(
      temp.data,
      (sum, el) => {
        return sum + el.color.quantity;
      },
      0
    );
    temp.vat.totalAmount = !temp.vat.totalAmount
      ? _.reduce(
          temp.data,
          (total, el) => {
            return total + el.color.quantity * el.color.price;
          },
          0
        )
      : temp.vat.totalAmount;
    rs.push(temp);
  });
  return rs;
};

/**
 * Get amount convert using convertRate and selectedConvertCurrency to calculate
 */
// export const getAmountConvert = createSelector(
//   [getAmount, getSelectedConvertCurrency, getConvertRate],
//   (amount, selectedConvertCurrency, convertRate) => {
//     throw "NotImplementedException";
//   }
// )

/**
 * Get total amount converted using convert rate and selected convert currency
 */
// export const getTotalAmountConvert = createSelector(
//   [getTotalAmount, getSelectedConvertCurrency, getConvertRate],
//   (totalAmount, selectedConvertCurrency, convertRate) => {
//     throw "NotImplementedException";
//   }
// )

export const getSelectableCustomerPOs = ({ createPayableInvoiceContractor }) => {
  const { selectableCustomerPOs, checkedCustomerPOs } = createPayableInvoiceContractor;
  let arrData = [];
  const results = selectableCustomerPOs.map(item => {
    if (!item.hide) {
      arrData.push({
        _id: item._id,
        name: item.customerPO.customerPO,
        checked: checkedCustomerPOs.findIndex(c => c === item._id) > -1 ? true : false,
        raw: item,
      });
    }
  });
  return arrData;
};

export const getSelectedCustomerPOs = ({ createPayableInvoiceContractor }) => {
  return _.filter(createPayableInvoiceContractor.selectableCustomerPOs, el => el.checked);
};

//--------- main VAT ---------//
export const getMainVATNo = ({ createPayableInvoiceContractor }) => {
  return createPayableInvoiceContractor.mainVATNo;
};

export const getMainVATDate = ({ createPayableInvoiceContractor }) => {
  return createPayableInvoiceContractor.mainVATDate;
};

export const getMainVATTaxRate = ({ createPayableInvoiceContractor }) => {
  return createPayableInvoiceContractor.mainVATTaxRate;
};

export const getMainVATNote = ({ createPayableInvoiceContractor }) => {
  return createPayableInvoiceContractor.mainVATNote;
};

export const getMainVATTotalAmount = ({ createPayableInvoiceContractor }) => {
  return createPayableInvoiceContractor.mainVATTotalAmount;
};

export const getMainVATAmount = ({ createPayableInvoiceContractor }) => {
  // TODO.
  return !createPayableInvoiceContractor.mainVATTaxRate
    ? 0
    : Math.round(
        (createPayableInvoiceContractor.mainVATTotalAmount * createPayableInvoiceContractor.mainVATTaxRate) / 100
      );
  // throw "NotImplementedException";
};

export const getMainVATGrandTotal = ({ createPayableInvoiceContractor }) => {
  // TODO.
  return !createPayableInvoiceContractor.mainVATTaxRate
    ? 0
    : Math.round(
        (createPayableInvoiceContractor.mainVATTotalAmount * createPayableInvoiceContractor.mainVATTaxRate) / 100
      ) + parseInt(createPayableInvoiceContractor.mainVATTotalAmount);
  // throw "NotImplementedException";
};

/**
 * Get Invoice amount
 */
export const getAmount = createSelector([getCustomerPOsToMap], data => {
  return _.sum(data.map(item => _.sum(item.data.map(it => it.color.amount || 0))));
});

export const getCurrency = ({ createPayableInvoiceContractor }) => {
  return (
    createPayableInvoiceContractor.selectableContractors.find(
      item => item._id === createPayableInvoiceContractor.selectedContractor
    ) || {}
  ).currency;
};

export const getCurrencyTypeUSD = createSelector([getCurrency], currency => {
  return currency === 'USD' ? '$' : '';
});

export const getCurrencyTypeVND = createSelector([getCurrency], currency => {
  return currency === 'VND' ? ` VND` : '';
});
/**
 * Get total invoice amount include tax
 */

export const getSumVatAmountOfCustomerPO = ({ createPayableInvoiceContractor }) => {
  const { customerPOs } = createPayableInvoiceContractor;
  if (customerPOs) {
    return _.sum(customerPOs.map(s => s.vat && (s.vat.totalAmount || 0) * ((s.vat.taxRate || 0) / 100)));
  }
};

export const getTotalAmount = createSelector(
  [getAmount, getMainVATAmount, getSumVatAmountOfCustomerPO, getSelectedCreditDebit],
  (amount, vatAmount, sumVatAmount, card) => {
    let totalAmount = (amount || 0) + (vatAmount || 0) + (sumVatAmount || 0);
    if (card && card.amount) {
      if (card.type === 'Credit') {
        totalAmount = totalAmount - card.amount;
      } else if (card.type === 'Debit') {
        totalAmount = totalAmount + card.amount;
      }
    }
    return Number(totalAmount).toFixed(2);
  }
);
/**
 * Get total invoice amount in words
 */
export const getTotalAmountSay = createSelector([getTotalAmount, getCurrency], (totalAmount, currency) => {
  if (currency == 'USD') {
    return genDollarToText(totalAmount);
  }
  return genVietNamDong(totalAmount);
});

//Get Anount after convert
export const getAmountConvert = createSelector(
  [getAmount, getConvertRate, getConvertCurrency],
  (amount, convertRate, currency) => {
    if (currency == 'USD') {
      return '$ ' + formatCurrency(_.round(amount / convertRate, 2));
    } else {
      return formatCurrency(_.round(amount * convertRate, 2)) + ` ${currency}`;
    }
  }
);

// Get total amount after convert
export const getTotalAmountConvert = createSelector(
  [getTotalAmount, getConvertRate, getConvertCurrency],
  (totalAmount, convertRate, currency) => {
    if (currency == 'USD') {
      return '$ ' + formatCurrency(_.round(totalAmount / convertRate, 2));
    } else {
      return formatCurrency(_.round(totalAmount * convertRate, 2)) + ` ${currency}`;
    }
  }
);

/**
 * Get preview invoice when creating
 * The results must have these information
 * {
 *    _id, name, totalQty, invNo, issuedDate, creditDebit, VAT, amount, totalAmount
 *    items: [
 *      {customerPO, style, cutTicket, color, shipDate, shipMode, qtyRec, price, amount}
 *    ]
 * }
 */
// export const getPreviewInvoice = createSelector(
//   [getEditableCustomerPOs],
//   (customerPOs) => {
//     // TODO.
//     throw "NotImplementedException";
//   }
// );

/**
 * Return a function that will use to generate an api request body that will be used to post to api
 * The type of the body will depend on selected supplier is domestic or oversea
 */
export const fnGenerateRequestBody = createSelector(
  [
    ({ createPayableInvoiceContractor }) => createPayableInvoiceContractor,

    isVATPerMonth,

    getSelectedContractor,
    getInvoiceNo,
    getIssuedDate,
    getDueDate,
    getSelectedCreditDebit,

    getSelectedConvertCurrency,
    getConvertRate,

    getMainVATNo,
    getMainVATDate,
    getMainVATTaxRate,
    getMainVATAmount,
    getMainVATTotalAmount,
    getMainVATGrandTotal,
    getTotalAmount,
  ],
  (
    createPayableInvoiceContractor,

    setIsVATPerMonth,

    setSelectedContractor,
    setInvoiceNo,
    setIssuedDate,
    setDueDate,
    setSelectedCreditDebit,

    setSelectedConvertCurrency,
    setConvertRate,

    setMainVATNo,
    setMainVATDate,
    setMainVATTaxRate,
    setMainVATAmount,
    setMainVATTotalAmout,
    setMainVATGrandTotal,
    setTotalAmountINV
  ) => {
    let obj = { invoiceDetail: {} };
    obj.typeInvoice = 'contractorInvoice';
    obj.invNo = setInvoiceNo;
    obj.creditDebit = setSelectedCreditDebit;
    obj.issuedDate = setIssuedDate;
    obj.dueDate = setDueDate;
    obj.amount = setTotalAmountINV;

    obj.invoiceDetail.convertType = setSelectedConvertCurrency;
    obj.invoiceDetail.convertRate = setConvertRate;

    obj.invoiceDetail.vatPermonth = setIsVATPerMonth;
    obj.invoiceDetail.contractor = setSelectedContractor;
    obj.currency = (
      createPayableInvoiceContractor.selectableContractors.find(item => item._id === setSelectedContractor) || {}
    ).currency;
    if (setIsVATPerMonth) {
      let vat = {};
      vat.vatNo = setMainVATNo;
      vat.vatDate = setMainVATDate;
      vat.taxRate = Number(setMainVATTaxRate);
      // vat.amount = setMainVATAmount;
      vat.totalAmount = Number(setMainVATTotalAmout);
      obj.invoiceDetail.vat = vat;
    }
    let customerPOs = [];
    _.forEach(createPayableInvoiceContractor.customerPOs, item => {
      let tmpCusPO = {};
      tmpCusPO.no = _.get(item, 'customerPO.customerPO', null);
      tmpCusPO.price = _.get(item, 'customerPO.price', null);
      tmpCusPO.styles = [];
      // handle vat per month
      if (!setIsVATPerMonth) {
        tmpCusPO.vat = item.vat;
      }
      _.forEach(item.styleOrders, styleOrder => {
        if (styleOrder.style) {
          let tmpStyle = {};
          tmpStyle.name = _.get(styleOrder, 'style.style', null);
          tmpStyle.price = _.get(styleOrder, 'style.price', null);
          tmpStyle.cutTickets = [];
          _.forEach(styleOrder.cutTickets, cutTicket => {
            let tmpCutTicket = {};
            tmpCutTicket.name = cutTicket.cutTicket;
            tmpCutTicket.colors = [];
            _.forEach(cutTicket.colors, color => {
              let tmpColor = {};
              tmpColor.name = color.name;
              tmpColor.shipDate = cutTicket.ihDate;
              tmpColor.shipMode = cutTicket.shipMode;
              tmpColor.price = color.price;
              tmpColor.qtyRec = _.reduce(
                color.sizes,
                (t, size) => {
                  return t + size.quantity;
                },
                0
              );
              tmpCutTicket.colors.push(tmpColor);
            });
            tmpStyle.cutTickets.push(tmpCutTicket);
          });
          tmpCusPO.styles.push(tmpStyle);
        }
      });
      customerPOs.push(tmpCusPO);
    });
    obj.invoiceDetail.customerPOs = customerPOs;

    return obj;
  }
);

export const getCanPreview = ({ createPayableInvoiceContractor }) =>
  !_.isEmpty(createPayableInvoiceContractor.checkedCustomerPOs);

export const getDataForPreview = createSelector(
  [
    ({ createPayableInvoiceContractor }) => createPayableInvoiceContractor,
    getCurrencyTypeUSD,
    getCurrencyTypeVND,
    getSelectedCreditDebit,
  ],
  (data, currencyTypeUSD, currencyTypeVND, selectedCreditDebit) => {
    if (data.customerPOs) {
      let results = [];
      let creditDebit = _.get(selectedCreditDebit, 'amount', 0);
      let type = _.get(selectedCreditDebit, 'type', 'Debit');
      if (type == 'Credit') {
        creditDebit = -creditDebit;
      }

      _.cloneDeep(data.customerPOs).map(item =>
        item.styleOrders.map(
          s =>
            s.style &&
            s.cutTickets.map(c =>
              c.colors.map(cl => {
                results.push({
                  ...cl,
                  cutTicket: c.cutTicket,
                  style: s.style.style,
                  shipDate: c.ihDate,
                  shipMode: c.shipMode,
                  qtyRec: _.sum(cl.sizes.map(size => size.quantity)),
                  price: cl.price,
                  customerPO: (item.customerPO || {}).customerPO,
                  amount:
                    currencyTypeUSD +
                    formatCurrency((cl.price || 0) * (_.sum(cl.sizes.map(size => size.quantity)) || 0)) +
                    currencyTypeVND,
                  amountNumber: (cl.price || 0) * (_.sum(cl.sizes.map(size => size.quantity)) || 0),
                  creditDebitAmount: creditDebit,
                });
              })
            )
        )
      );
      return results;
    }
    return [];
  }
);

export const getVATAmountForPreview = createSelector(
  [getMainVATAmount, getSumVatAmountOfCustomerPO],
  (mainVATAmount, sumVatAmount) => {
    return (mainVATAmount || 0) + (sumVatAmount || 0);
  }
);

export const getTotalQTY = createSelector([getDataForPreview], data => {
  return _.sum(data.map(item => item.qtyRec || 0));
});

export const getContractor = createSelector(
  [({ createPayableInvoiceContractor }) => createPayableInvoiceContractor, getSelectedContractor],
  (data, setSelectedContractor) => {
    return (data.selectableContractors.find(item => item._id === setSelectedContractor) || {}).name;
  }
);

export const getSuccess = ({ createPayableInvoiceContractor }) => createPayableInvoiceContractor.success;

export const getActiveScreenStatus = ({ createPayableInvoiceContractor }) =>
  createPayableInvoiceContractor.activeScreenPreview;
