import * as types from '../constants/actionTypes';
import {showLoading, hideLoading} from './commonUseActions';
import trimServices from '../services/trimServices';

function getListTrim (options = {}) {
  return (dispatch) => {
    showLoading(types, dispatch);

    trimServices.getListTrim(options).then((res) => {
      dispatch({
        type: types.TRIM.GET_LIST_SUCCESS,
        data: res.data || []
      });
      hideLoading(types, dispatch);
    }, (error) => {
      hideLoading(types, dispatch);
    });
  };
}

function getDetail (id, reset = true) {
  return (dispatch) => {
    showLoading(types, dispatch);
    reset && dispatch({
      type: types.TRIM.RESET_DETAIL,
      data: {}
    });

    trimServices.getDetail(id, {}).then((res) => {
      hideLoading(types, dispatch);
      if (res) {
        dispatch({
          type: types.TRIM.GET_DETAIL_SUCCESS,
          data: res.data || {}
        });
      }
    }, (error) => {
      hideLoading(types, dispatch);
    });
  };
}

function getDetailByKey (key) {
  return (dispatch) => {
    showLoading(types, dispatch);

    trimServices.getDetailByKey(key, {}).then((res) => {
      hideLoading(types, dispatch);
      res && res.success && dispatch({
        type: types.TRIM.GET_KEY_SUCCESS,
        data: {key: key, values: _.get(res, 'data.values', [])}
      });
    }, (error) => {
      hideLoading(types, dispatch);
    });
  };
}

function deleteTrim (id) {
  return (dispatch) => {
    showLoading(types, dispatch);

    trimServices.deleteTrim(id).then((res) => {
      hideLoading(types, dispatch);
      if (res) {
        dispatch({
          type: types.TRIM.RELOAD,
          data: true
        });
      }
    }, (error) => {
      hideLoading(types, dispatch, error);
    });
  };
}

function editTrim (id, data) {
  return (dispatch) => {
    showLoading(types, dispatch);

    trimServices.editTrim(id, data).then((res) => {
      hideLoading(types, dispatch);
      if (res) {
        dispatch({
          type: types.TRIM.RELOAD,
          data: true
        });
      }
    }, (error) => {
      hideLoading(types, dispatch, error);
    });
  };
}

function addTrim (data) {

  return (dispatch) => {
    showLoading(types, dispatch);

    trimServices.addTrim(data).then((res) => {
      hideLoading(types, dispatch);
      if (res) {
        dispatch({
          type: types.TRIM.RELOAD,
          data: true
        });
      }
    }, (error) => {
      hideLoading(types, dispatch, error);
    });
  };
}

export default {
  getListTrim,
  deleteTrim,
  editTrim,
  addTrim,
  getDetail,
  getDetailByKey
};
