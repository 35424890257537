import React, { Component } from 'react';
import CustomerPOReportView from '../../components/reportAnalytic/customerPOReport';
import * as selector from '../../../selectors/reportAnalytic/customerPOReport';
import * as actions from '../../../actions/reportAnalytic/customerPOActions';
import { connect } from 'react-redux';

class CustomerPOReport extends Component {

    constructor(props) {
        super(props);

    }

    componentWillMount() {
        this.props.getDataForChart()
    }

    onPieEnter = () => {

    }

    render() {
        const COLORS = ['red', 'yellow', '#52B244'];
        const RADIAN = Math.PI / 180;
        const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
            const radius = innerRadius + (outerRadius - innerRadius) * 1.4;
            const x = cx + radius * Math.cos(-midAngle * RADIAN);
            const y = cy + radius * Math.sin(-midAngle * RADIAN);
            return (
                <text x={x} y={y} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                    {`${(percent * 100).toFixed(0)}%`}
                </text>
            );
        };
        const {overview, onSearch} = this.props;
        return (
            <div>
                <CustomerPOReportView
                    dataPieChart={overview || []}
                    COLORS={COLORS}
                    onPieEnter={this.onPieEnter}
                    renderCustomizedLabel={renderCustomizedLabel}
                    onSearch={onSearch}
                    {...this.props}
                />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    data: selector.getDataForChart(state),
    totalNew: selector.getTotalNew(state),
    totalCompleted: selector.getTotalCompleted(state),
    searchValue: selector.getSearchValue(state),
    overview: selector.getOverview(state),
})

const mapDispatchToProps = (dispatch) => ({
    getDataForChart() {
        dispatch(actions.getDataForChart())
    },
    changeSearchText(key, date) {
        if(date == 'Invalid date') {
            date = ''
        }
        dispatch(actions.changeSearchText(key, date))
    },
    onSearch(searchValue) {
        dispatch(actions.search(searchValue))
    }
})
export default connect(mapStateToProps, mapDispatchToProps)(CustomerPOReport);