import React from "react";
import PropTypes from 'prop-types';
import Modal from '../forms/Modal';
import _ from 'lodash';
import I18n from 'i18n-js';
import ImageGallery from 'react-image-gallery';


const ImageGalleryModal = props => (
  <Modal isOpen={props.isOpen}>
  <div className="modal-dialog lg">
    <div className="modal-content">
      <div className="modal-header">
        <a className="close" onClick={() => props.onClose()}>
          <span aria-hidden="true">&times;</span>
        </a>
      </div>
      <div className="modal-body">
        {!_.isEmpty(props.images) ? 
          <ImageGallery items={props.images} /> : 
          <div className="h-100 line-h-100 text-center">
            <p>{I18n.t("PageOrder.Pleaseinputimages")}</p>
          </div>
        }
      </div>
    </div>
  </div>
  </Modal>
);

ImageGalleryModal.propTypes = {
  images: PropTypes.arrayOf(PropTypes.any).isRequired,

  onClose: PropTypes.func,
}

ImageGalleryModal.defaultProps = {
  onClose: f => f,
}

export default ImageGalleryModal;
