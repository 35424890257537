import React, { Component } from 'react';
import { Store } from '../../../CssHandleModule/functions'
import I18n from 'i18n-js';
import { connect } from 'react-redux';
import * as selector from '../../../../selectors/ship-out/viewPklOfProdDetail';
import _ from "lodash";

const store = new Store();
store.setStyles({
    orderNumbers: [1, 2],
    fonts: [
        { order: 1, size: '15px' }
    ],
    aligns: [
        { order: 1, margin: { right: '10px' } },
        { order: 2, margin: { left: '10px' } }
    ]
});

const ColorTable = (props) => {

    const { data, sizes, packingType, getCarton, styleId, cutId, getPackingTotal } = props;
    let cartons = packingType == 'Carton' && getCarton(styleId, cutId, data._id)
    const colorPacking = packingType == 'Carton' && getPackingTotal(styleId, cutId, data._id);
    let sizeCarton = []
    cartons = (cartons && cartons.length > 0) ? cartons : [];   
    cartons.map((carton, idx) => {
        carton.items.map((item, idxCarton) => {
            if(item.name != 'gwKgs' && item.name != 'nwKgs'){
                let store = _.find(sizeCarton, o => o === item.name);
                if(!store){
                    sizeCarton.push(item.name)
                }
            }
        })
        })
    return (
        <div>
            {packingType == 'GOHH' &&
                <div className="bg-white mg-top-2">
                    <table className="global-table w-full">
                        <tbody>
                            <tr>
                                <th className='left'>{I18n.t('PageShiping.Color')}</th>
                                {sizes.map((size, idx) => <th key={idx} className='left'>{size}</th>)}
                                <th className='center'>{I18n.t('PageShiping.Remaining')}</th>
                                <th className='center'>{I18n.t('PageShiping.Total')}</th>
                                <th className='center'></th>
                            </tr>
                            {data.map((color, id) => {
                                return (
                                    <tr key={id}>
                                        <td className='left'>{color.color.name}</td>
                                        {sizes.map((size, sId) => {
                                            let temp = color.packings ? color.packings[0].items.find(item => size == item.name) : {};
                                            return <td key={sId} className='left' style={{ color: '#F36F21' }}>{temp ? temp.planQuantity || 0 : 0}</td>
                                        })}
                                        <td className='center' style={{ color: '#52B244 ' }} >{0}</td>
                                        <td className='center' style={{ color: '#D20020' }} >{getPackingTotal(styleId, cutId, color._id)}</td>
                                        <td className='center'></td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            }
            {packingType == 'Carton' &&
                <div className="bg-white mg-top-2">
                    <div style={{
                        height: 50,
                        backgroundColor: 'white',
                        paddingTop: 15,
                        paddingLeft: '1%',
                        paddingRight: '1%'
                    }}>
                        <div className="d-flex justify-content-between">
                            <div>
                                <span style={store.setStyleType('font_1')}>{I18n.t('PageShiping.Color')}</span>
                                <span style={{ marginLeft: 10 }}>{data.color.name}</span>
                            </div>
                            <div className="d-flex justify-content-between" style={{ width: '25%' }}>
                                <div>
                                    <span style={store.setStyleType('font_1', 'align_1')}>{I18n.t('PageShiping.Total')}</span>
                                    <span style={{ color: '#D20020' }} >{colorPacking}</span>
                                </div>
                                <div>
                                    <span style={store.setStyleType('font_1', 'align_1')}>{I18n.t('PageShiping.Remaining')}</span>
                                    <span className="mr-10" style={{ color: '#52B244 ' }} >{0}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bg-white mg-top-2">
                        <table className="global-table w-full">
                            <tbody>
                                <tr>
                                    <th className='left'>{I18n.t('PageShiping.CTNNo')}</th>
                                    <th className='left'>{I18n.t('PageShiping.NW')}</th>
                                    <th className='left'>{I18n.t('PageShiping.GW')}</th>
                                    <th className='left'>{I18n.t('PageShiping.MEANS')}</th>
                                    <th className='left'>{I18n.t('PageShiping.M3')}</th>
                                    {sizeCarton.map((size, idx) => <th key={idx} className='left'>{size}</th>)}
                                    <th className='center'>{I18n.t('PageShiping.QTY')}</th>
                                    <th className='center'>{I18n.t('PageShiping.QTY/CTN')}</th>
                                    <th className='center'>{I18n.t('PageShiping.Total')}</th>
                                    <th className='center'></th>
                                </tr>
                                {cartons.map((carton, idx) => {
                                    const qty = carton.quantity
                                    const qtyCTN = carton.quantityCTN
                                    const m3 = _.round(0.00001648 * carton.props.l * carton.props.w * carton.props.h, 5)
                                    const total = carton.total
                                    const be = _.isEmpty(carton.noEnd) ?
                                        (<td className="left">{carton.noBegin}</td>)
                                        :
                                        (<td className="left">{carton.noBegin}&nbsp;&nbsp;&rArr;&nbsp;&nbsp;{carton.noEnd}</td>)
                                    return (
                                        <tr key={idx}>
                                            {be}
                                            <td className="left">{carton.props.nwKgs || '-'}</td>
                                            <td className="left">{carton.props.gwKgs || '-'}</td>
                                            <td className="left">{`${carton.props.l || '-'}" x ${carton.props.w || '-'}" x ${carton.props.h || '-'}"`}</td>
                                            <td className="left">{m3}</td>
                                            {
                                                carton.items.map((item, idxCarton) => {
                                                    if(item.name != 'gwKgs' && item.name != 'nwKgs'){
                                                        return (<td className="color-orange left" key={idxCarton}>{item.planQuantity}</td>)
                                                    }
                                                })
                                               }
                                            <td className="color-green center">{qty}</td>
                                            <td className="color-green center">{qtyCTN}</td>
                                            <td className="color-red center">{total}</td>
                                            <td className="center"></td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            }
        </div>
    );
}


const mapStateToProps = state => ({
    getCarton: selector.getCarton(state),
    getPackingTotal: selector.getPackingTotal(state)
})
export default connect(mapStateToProps, null)(ColorTable);