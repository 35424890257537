import * as Configs from './configs';

export default {
    baseUrl: Configs.APP.serverUrl,
    upload: Configs.APP.serverUrl + '/erp/api/upload',

    user: {
        signUp: Configs.APP.serverUrl + '/user/register',
        // signOut: Configs.APP.serverUrl + '/logout',
        login: Configs.APP.serverUrl + '/login',
        get: Configs.APP.serverUrl + '/user',
        getCurrentUser: Configs.APP.serverUrl + '/erp/api/user-by-token',
        forgot: Configs.APP.serverUrl + '/erp/api/user/forgot-password',
        forgotConfirm: Configs.APP.serverUrl + '/erp/api/user/forgot-password-confirm',
        reset: Configs.APP.serverUrl + '/user/password-forgot',
        verify: Configs.APP.serverUrl + '/user/email-verify',
        getList: Configs.APP.serverUrl + '/erp/api/users',
        changePassword: Configs.APP.serverUrl + '/erp/api/user/change-password',
        delete: Configs.APP.serverUrl + '/erp/api/user',
        create: Configs.APP.serverUrl + '/erp/api/user',
        update: Configs.APP.serverUrl + '/erp/api/user',
        updateLock: Configs.APP.serverUrl + '/erp/api/user/is-lock',
        passwordReset: Configs.APP.serverUrl + '/user/',
        passwordReset2: Configs.APP.serverUrl + '/password-reset-request'
    },

    dashboard: Configs.APP.serverUrl + '/erp/api/dashboards',

    order: {
        list: Configs.APP.serverUrl + '/erp/api/orders',
        create: Configs.APP.serverUrl + '/erp/api/order',
        detail: Configs.APP.serverUrl + '/erp/api/order',
        update: Configs.APP.serverUrl + '/erp/api/order',
        delete: Configs.APP.serverUrl + '/erp/api/order',
        sendNotify: Configs.APP.serverUrl + '/erp/api/order/send-email',
        getListCustomer: Configs.APP.serverUrl + '/erp/api/orders-purchase',
        getListContractor: Configs.APP.serverUrl + '/erp/api/orders/',
        listOrderPurchase: Configs.APP.serverUrl + '/erp/api/orders/purchase',
        listOrderForReport: Configs.APP.serverUrl + '/erp/api/report-analytic-wip',
        updateCompletedStatusOrders: Configs.APP.serverUrl + '/erp/api/order/completed',
    },
    purchase: {
        list: Configs.APP.serverUrl + '/erp/api/purchase-orders',
        restore: Configs.APP.serverUrl + '/erp/api/purchase-order-restore',
        listShipping: Configs.APP.serverUrl + '/erp/api/purchase-shipping-orders',
        followUp: Configs.APP.serverUrl + '/erp/api/follow-up',
        create: Configs.APP.serverUrl + '/erp/api/supplier/',
        detail: Configs.APP.serverUrl + '/erp/api/purchase',
        update: Configs.APP.serverUrl + '/erp/api/purchase-order',
        delete: Configs.APP.serverUrl + '/erp/api/purchase-order'
    },

    purchasePlan: {
        getByStyleId: Configs.APP.serverUrl + '/erp/api/style/{id}/purchase-plans'
    },

    supplier: {
        list: Configs.APP.serverUrl + '/erp/api/suppliers',
        create: Configs.APP.serverUrl + '/erp/api/supplier',
        detail: Configs.APP.serverUrl + '/erp/api/supplier',
        update: Configs.APP.serverUrl + '/erp/api/supplier',
        delete: Configs.APP.serverUrl + '/erp/api/supplier'
    },

    style: {
        all: Configs.APP.serverUrl + '/erp/api/styles',
        list: Configs.APP.serverUrl + '/erp/api/order/{id}/styles',
        create: Configs.APP.serverUrl + '/erp/api/order/{id}/style',
        detail: Configs.APP.serverUrl + '/erp/api/order/style',
        update: Configs.APP.serverUrl + '/erp/api/order/style',
        delete: Configs.APP.serverUrl + '/erp/api/order/style',
        listStyleAssignment: Configs.APP.serverUrl + '/erp/api/style/assignment'
    },
    cutTicket: {
        list: Configs.APP.serverUrl + '/erp/api/order/style/{id}/cutTickets',
        create: Configs.APP.serverUrl + '/erp/api/order/style/{id}/cutTicket',
        detail: Configs.APP.serverUrl + '/erp/api/order/style/cutTicket',
        update: Configs.APP.serverUrl + '/erp/api/order/style/cutTicket',
        delete: Configs.APP.serverUrl + '/erp/api/order/style/cutTicket',
        sugestColors: Configs.APP.serverUrl + '/erp/api/order/style/suggestColors',
        cutTicketColorProgress: Configs.APP.serverUrl + '/erp/api/order/style/cutTicket/{id}/progress',
        cutTicketColorQAProgress: Configs.APP.serverUrl + '/erp/api/order/style/cutTicket/{id}/QAProgress',
    },
    yield: {
        create: Configs.APP.serverUrl + '/erp/api/order/style/cutTicket/{id}/yield',
        update: Configs.APP.serverUrl + '/erp/api/order/style/cutTicket/yield',
        exportBom: Configs.APP.serverUrl + '/erp/api/report/bom',
        exportYield: Configs.APP.serverUrl + '/erp/api/report/yield',
    },

    customer: {
        list: Configs.APP.serverUrl + '/erp/api/customers',
        listByQa: Configs.APP.serverUrl + '/erp/api/customers/qa',
        create: Configs.APP.serverUrl + '/erp/api/customer',
        detail: Configs.APP.serverUrl + '/erp/api/customer',
        update: Configs.APP.serverUrl + '/erp/api/customer',
        delete: Configs.APP.serverUrl + '/erp/api/customer'
    },
    store: {
        list: Configs.APP.serverUrl + '/erp/api/stores',
        create: Configs.APP.serverUrl + '/erp/api/store',
        detail: Configs.APP.serverUrl + '/erp/api/store',
        update: Configs.APP.serverUrl + '/erp/api/store',
        delete: Configs.APP.serverUrl + '/erp/api/store'
    },
    property: {
        list: Configs.APP.serverUrl + '/erp/api/properties',
        create: Configs.APP.serverUrl + '/erp/api/property',
        detail: Configs.APP.serverUrl + '/erp/api/property',
        update: Configs.APP.serverUrl + '/erp/api/property',
        delete: Configs.APP.serverUrl + '/erp/api/property'
    },
    production: {
        productionOrder: Configs.APP.serverUrl + '/erp/api/product/orders',
        qaOrder: Configs.APP.serverUrl + '/erp/api/qa/orders',
        packingOrder: Configs.APP.serverUrl + '/erp/api/packing/orders',
        progress: Configs.APP.serverUrl + '/erp/api/order/{id}/progress',
        actualYield: Configs.APP.serverUrl + '/erp/api/order/style/{id}/cutTickets',
        updateActualYield: Configs.APP.serverUrl + '/erp/api/order/style/cutTicket/yield/actual/{id}',
    },
    fabric: {
        list: Configs.APP.serverUrl + '/erp/api/materials',
        create: Configs.APP.serverUrl + '/erp/api/material',
        detail: Configs.APP.serverUrl + '/erp/api/material/{id}',
        update: Configs.APP.serverUrl + '/erp/api/material/{id}',
        delete: Configs.APP.serverUrl + '/erp/api/material/{id}'
    },
    trim: {
        list: Configs.APP.serverUrl + '/erp/api/materials',
        create: Configs.APP.serverUrl + '/erp/api/material',
        detail: Configs.APP.serverUrl + '/erp/api/material/{id}',
        update: Configs.APP.serverUrl + '/erp/api/material/{id}',
        delete: Configs.APP.serverUrl + '/erp/api/material/{id}'
    },
    packingStandard: {
        list: Configs.APP.serverUrl + '/erp/api/packing-standards',
        create: Configs.APP.serverUrl + '/erp/api/packing-standard',
        delete: Configs.APP.serverUrl + '/erp/api/packing-standard',
        update: Configs.APP.serverUrl + '/erp/api/packing-standard'
    },

    packing: {
        list: Configs.APP.serverUrl + '/erp/api/packings',
        detail: Configs.APP.serverUrl + '/erp/api/packings',
        notify: Configs.APP.serverUrl + '/erp/api/packings/notify',
        packingByOrder: Configs.APP.serverUrl + '/erp/api/orders',
        packingByOrderAndContractor: Configs.APP.serverUrl + '/erp/api//{oId}/contractorId/{cId}',
        stock: Configs.APP.serverUrl + '/erp/api/packings/stock',
        reship: Configs.APP.serverUrl + '/erp/api/packings/reship'
    },
    material: {
        list: Configs.APP.serverUrl + '/erp/api/materials',
        create: Configs.APP.serverUrl + '/erp/api/material',
        detail: Configs.APP.serverUrl + '/erp/api/material',
        update: Configs.APP.serverUrl + '/erp/api/material',
        delete: Configs.APP.serverUrl + '/erp/api/material'
    },
    sampleApproval: {
        get: Configs.APP.serverUrl + '/erp/api/style/{id}/sample-approval',
        update: Configs.APP.serverUrl + '/erp/api/style/{id}/sample-approval',
        updateStatus: Configs.APP.serverUrl + '/erp/api/update/completed/sample-approval/{id}',
        getList: Configs.APP.serverUrl + '/erp/api/sample-approvals',
        getListCustomer: Configs.APP.serverUrl + '/erp/api/sample-approval/orders',
        exportCurrent: Configs.APP.serverUrl + '/erp/api/report/sample',
    },
    warehouse: {
        list: Configs.APP.serverUrl + '/erp/api/warehouse/stocks',
        reserve: Configs.APP.serverUrl + '/erp/api/warehouse/reserves',
        returnStyleResources: Configs.APP.serverUrl + '/erp/api/warehouse/{id}/return/{styleId}',
        exportReserve: Configs.APP.serverUrl + '/erp/api/report/warehouse/reserve'
    },
    stockIn: {
        supplier: {
            list: Configs.APP.serverUrl + '/erp/api/stock-in/suppliers',
            create: Configs.APP.serverUrl + '/erp/api/stock-in/supplier',
            detail: Configs.APP.serverUrl + '/erp/api/stock-in/supplier',
            update: Configs.APP.serverUrl + '/erp/api/stock-in/supplier',
            delete: Configs.APP.serverUrl + '/erp/api/stock-in/supplier'
        },
        contractor: {
            list: Configs.APP.serverUrl + '/erp/api/stock-in/contractors',
            create: Configs.APP.serverUrl + '/erp/api/stock-in/contractor',
            detail: Configs.APP.serverUrl + '/erp/api/stock-in/contractor',
            update: Configs.APP.serverUrl + '/erp/api/stock-in/contractor',
            delete: Configs.APP.serverUrl + '/erp/api/stock-in/contractor'
        }
    },
    purchaseOrder: {
        list: Configs.APP.serverUrl + '/erp/api/purchase-orders',
        create: Configs.APP.serverUrl + '/erp/api/purchase-order',
        detail: Configs.APP.serverUrl + '/erp/api/purchase-order',
        update: Configs.APP.serverUrl + '/erp/api/purchase-order',
        sendRevised: Configs.APP.serverUrl + '/erp/api/send-revised',
        delete: Configs.APP.serverUrl + '/erp/api/purchase-order',
        listInbound: Configs.APP.serverUrl + '/erp/api/purchase-orders-inbound',
    },
    damagedGarment: {
        list: Configs.APP.serverUrl + '/erp/api/warehouse/damaged-garments',
        detail: Configs.APP.serverUrl + '/erp/api/warehouse/damaged-garment',
        update: Configs.APP.serverUrl + '/erp/api/warehouse/damaged-garment'
    },
    damagedMaterial: {
        list: Configs.APP.serverUrl + '/erp/api/warehouse/damaged-materials'
    },
    stockOut: {
        list: Configs.APP.serverUrl + '/erp/api/stock-outs',
        create: Configs.APP.serverUrl + '/erp/api/stock-out',
        detail: Configs.APP.serverUrl + '/erp/api/stock-out',
        update: Configs.APP.serverUrl + '/erp/api/stock-out',
        delete: Configs.APP.serverUrl + '/erp/api/stock-out',
        getMaterials: Configs.APP.serverUrl + '/erp/api/stock-out/style/{id}/materials'
    },
    contractor: {
        list: Configs.APP.serverUrl + '/erp/api/contractors',
        create: Configs.APP.serverUrl + '/erp/api/contractor',
        detail: Configs.APP.serverUrl + '/erp/api/contractor',
        update: Configs.APP.serverUrl + '/erp/api/contractor',
        delete: Configs.APP.serverUrl + '/erp/api/contractor'
    },
    assignment: {
        ordersC: Configs.APP.serverUrl + '/erp/api/assignment/contractor/{id}/order',
        listOrder: Configs.APP.serverUrl + '/erp/api/assignment/order',
        list: Configs.APP.serverUrl + '/erp/api/assignments',
        create: Configs.APP.serverUrl + '/erp/api/assignment',
        detail: Configs.APP.serverUrl + '/erp/api/assignment',
        update: Configs.APP.serverUrl + '/erp/api/assignment',
        delete: Configs.APP.serverUrl + '/erp/api/assignment',
        export: Configs.APP.serverUrl + '/erp/api/report/assignment'
    },
    setting: {
        list: Configs.APP.serverUrl + '/erp/api/setting',
        update: Configs.APP.serverUrl + '/erp/api/setting'
    },
    department: {
        list: Configs.APP.serverUrl + '/erp/api/departments',
        update: Configs.APP.serverUrl + '/erp/api/department',
        create: Configs.APP.serverUrl + '/erp/api/department',
        delete: Configs.APP.serverUrl + '/erp/api/department',
    },
    organization: {
        list: Configs.APP.serverUrl + '/erp/api/organizations',
        update: Configs.APP.serverUrl + '/erp/api/organization',
        create: Configs.APP.serverUrl + '/erp/api/organization',
        delete: Configs.APP.serverUrl + '/erp/api/organization',
    },
    role: {
        list: Configs.APP.serverUrl + '/erp/api/roles',
        update: Configs.APP.serverUrl + '/erp/api/role',
        create: Configs.APP.serverUrl + '/erp/api/role',
        delete: Configs.APP.serverUrl + '/erp/api/role',
        detail: Configs.APP.serverUrl + '/erp/api/role',
    },
    module: {
        list: Configs.APP.serverUrl + '/erp/api/modules'
    },
    standard: {
        list: Configs.APP.serverUrl + '/erp/api/standards',
        create: Configs.APP.serverUrl + '/erp/api/standard',
        detail: Configs.APP.serverUrl + '/erp/api/standard',
        update: Configs.APP.serverUrl + '/erp/api/standard',
        delete: Configs.APP.serverUrl + '/erp/api/standard'
    },
    shipping: {
        default: Configs.APP.serverUrl + '/erp/api/shippings',
        followUp: Configs.APP.serverUrl + '/erp/api/shipping/follow-up',
        create: Configs.APP.serverUrl + '/erp/api/shipping/create',
        update: Configs.APP.serverUrl + '/erp/api/shipping',
        delete: Configs.APP.serverUrl + '/erp/api/shipping',
        listPurchaseOrder: Configs.APP.serverUrl + '/erp/api/shipping/order/selected',
        listShipIn: Configs.APP.serverUrl + '/erp/api/shippings',
        listHistory: Configs.APP.serverUrl + '/erp/api/shipping/history',
        listForwarder: Configs.APP.serverUrl + '/erp/api/payable-invoice/forwarder',
        exportShipin: Configs.APP.serverUrl + '/erp/api/report/shipin'
    },
    accounting: {
        payable: {
            listForwarder: Configs.APP.serverUrl + '/erp/api/payable-invoice/forwarder',
            listSuppier: Configs.APP.serverUrl + '/erp/api/payable-invoice/supplier',
            listCreditDebit: Configs.APP.serverUrl + '/erp/api/pay-info',
            listPurchaseOrder: Configs.APP.serverUrl + '/erp/api/payable-invoice/purchase-order',
            listContainer: Configs.APP.serverUrl + '/erp/api/payable-invoice/container',
            listInvoice: Configs.APP.serverUrl + '/erp/api/payable-invoice',
            getAmountInvoiceList: Configs.APP.serverUrl + '/erp/api/payable-invoice/amount-list',
            createInvoice: Configs.APP.serverUrl + '/erp/api/payable-invoice',
            deleteInvoice: Configs.APP.serverUrl + '/erp/api/payable-invoice',
            listDeclaration: Configs.APP.serverUrl + '/erp/api/payable-invoice/declaration',
            listCompany: Configs.APP.serverUrl + '/erp/api/payable-invoice/company',
            listBill: Configs.APP.serverUrl + '/erp/api/payable-invoice/bill',
            listContractor: Configs.APP.serverUrl + '/erp/api/payable-invoice/contractor',
            listCustomerPO: Configs.APP.serverUrl + '/erp/api/payable-invoice/customer-po',
            listPaid: Configs.APP.serverUrl + '/erp/api/payable-invoice/payment-history/',
            getInvoiceDetail: Configs.APP.serverUrl + '/erp/api/payable-invoice/',
            createINVRequest: Configs.APP.serverUrl + '/erp/api/payable-invoice/invoice-request',
            updateInvoice: Configs.APP.serverUrl + '/erp/api/payable-invoice/',
            getAmountInvoiceHistory: Configs.APP.serverUrl + '/erp/api/payable-invoice/amount-history',
            exportINVRequest: Configs.APP.serverUrl + '/erp/api/report/accounting/payable/invoices',
        },
        payInfo: {
            default: Configs.APP.serverUrl + '/erp/api/pay-info',
            create: Configs.APP.serverUrl + '/erp/api/pay-info',
            listPayInfo: Configs.APP.serverUrl + '/erp/api/pay-info',
            delete: Configs.APP.serverUrl + '/erp/api/pay-info',
            export: Configs.APP.serverUrl + '/erp/api/report/accounting/creditDebit',
        },
        receiable: {
            exportINVRequest: Configs.APP.serverUrl + '/erp/api/report/accounting/receiable/invoices',
            exportINV: Configs.APP.serverUrl + '/erp/api/report/accounting/receiable/invoices/{invId}'
        }
    },
    shipOut: {
        pklOfProduction: {
            listPklOfProduction: Configs.APP.serverUrl + '/erp/api/shipping-out/production-pkls',
            previewSelectedPklOfProdList: Configs.APP.serverUrl + '/erp/api/shipping-out/production-pkls/preview',
            getPackingDetail: Configs.APP.serverUrl + '/erp/api/shipping-out/production-pkls/detail',
            createPklShip: Configs.APP.serverUrl + '/erp/api/shipping-out/ship-pkls',
        },
        pklShip: {
            listPklShip: Configs.APP.serverUrl + '/erp/api/shipping-out/ship-pkls',
            updatePklShip: Configs.APP.serverUrl + '/erp/api/shipping-out/ship-pkls',
            pklShipDetail: Configs.APP.serverUrl + '/erp/api/shipping-out/ship-pkls/detail',
            deletePklShip: Configs.APP.serverUrl + '/erp/api/shipping-out/ship-pkls',
            createINV: Configs.APP.serverUrl + '/erp/api/shipping-out/ship-inv',
            getShipInv: Configs.APP.serverUrl + '/erp/api/shipping-out/ship-inv',
            updateInv: Configs.APP.serverUrl + '/erp/api/shipping-out/ship-inv',
            createShipment: Configs.APP.serverUrl + '/erp/api/shipping-out/ship-outs',
            findBillNo: Configs.APP.serverUrl + '/erp/api/shipping-out/find-ship-outs-by-bill-no',
            listForwarder: Configs.APP.serverUrl + '/erp/api/payable-invoice/forwarder',
        },
        shipOut: {
            listShipOut: Configs.APP.serverUrl + '/erp/api/shipping-out/ship-outs',
            shipmentDetail: Configs.APP.serverUrl + '/erp/api/shipping-out/ship-outs/detail',
            deleteShipment: Configs.APP.serverUrl + '/erp/api/shipping-out/ship-outs',
            updateShipment: Configs.APP.serverUrl + '/erp/api/shipping-out/ship-outs',
        },
        history: {
            listHistory: Configs.APP.serverUrl + '/erp/api/shipping-out/ship-outs',

        },
        export: {
            exportShipOut: Configs.APP.serverUrl + '/erp/api/report/shipout-current',
            exportPKLOfProductPreview: Configs.APP.serverUrl + '/erp/api/report/shipout-preview',
            exportCustomINV: Configs.APP.serverUrl + '/erp/api/report/shipout-invoice/{shipoutId}',
            exportShipoutPKL: Configs.APP.serverUrl + '/erp/api/report/shipout-pkl/{pklId}',
            exportShipoutAPL: Configs.APP.serverUrl + '/erp/api/report/shipout-apl/{pklId}'
        }
    },
    reportAnalytic: {
        exportWip: Configs.APP.serverUrl + '/erp/api/report/wip',
        getDataForChart: Configs.APP.serverUrl + '/erp/api/report-analytic/order-chart',
    }
};
