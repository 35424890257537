import React, { Component } from 'react';
import I18n from 'i18n-js';
import {Store} from '../../CssHandleModule/functions';

import icEditYieldIcon from '../../../stylesheets/images/ic-edit-yield.svg';

const store = new Store();

store.setStyles({
    orderNumbers: [1],
    aligns: [
        {order: 1, margin: {right: `10px`}}
    ],
    fonts: [
        {order: 1, size: `15px`}
    ]
});

const HeaderforSubView = (props) => {

    return (
        <div style={store.setStyleType(`font_1`)}>
            <ul className="header-menu d-flex" style={{
                backgroundColor: `white`,
                position: `absolute`,
                width: `50%`,
                height: 56,
                paddingLeft: 20,
                paddingTop: 20,
                top: 0
            }}>
                <li style={{marginRight: 10}}
                    className={`cursor-pointer`}
                    onClick={()=>{props.router.history.push(props.turnBackURL)}}>
                    <img style={store.setStyleType(`align_1`)}
                        src={icEditYieldIcon} />
                </li>
                <li style={{marginLeft: 5, color: `#F47738`}}
                    onClick={()=>{props.router.history.push(props.turnBackURL)}}
                    className={`cursor-pointer active`}>
                    {I18n.t(`Menu.${props.type}`)}
                </li>
            </ul>
        </div>
    );
}

export default HeaderforSubView;