import C from '../../constants/ship-out/previewSelectedPklProdList';
import shipOutApi from '../../api/shipOutApi';

export const getListPackingDetail = (op) => dispatch => {
    if(op) {
        shipOutApi.getListPackingDetail(op).then(res => {
            if(res.success) {
                dispatch({type: C.CHANGE_ITEM_LIST, payload: res.data});
                dispatch({type: C.CHANGE_PAGING_TOTAL, payload: res.meta.pages});
                dispatch({type: C.CHANGE_PAGING_CURRENT, payload: res.meta.page});
            }
        })
    }
}

export const changeSelectedFilter = (data) => dispatch => {
    if(data) {
        dispatch({type: C.CHANGE_SELECTED_FILTER, payload: data})
    }
}

export const changeSearchValue = (value, pos) => dispatch => {
    if(pos) {
        dispatch({type: C.CHANGE_SEARCH_TEXT, payload: {value, pos}})
    }
}


export const getExportPKLOfProductPreview = (ids) => {
    return shipOutApi.getExportPKLOfProductPreview(ids);
}

// export const search = (listIds, selectedFilter, searchValue) => dispatch => {
//     if(selectedFilter && searchValue) {
//         let op = {data: listIds};
//         let key = selectedFilter;
//         if(selectedFilter == 'ih') {
//             let begin = moment(searchValue.from).format('YYYY-MM-DD');
//             let end = moment(searchValue.to).format('YYYY-MM-DD');
//             let date = `${begin}:${end}`;
//             op[key] = date
//             shipOutApi.searchPreviewPklProduction(op).then(res => {
//                 if(res.success)
//                     dispatch({type: C.CHANGE_ITEM_LIST, payload: res.data})
//                     dispatch({type: C.CHANGE_PAGING_TOTAL, payload: res.meta.pages});
//                     dispatch({type: C.CHANGE_PAGING_CURRENT, payload: res.meta.page});
//             })
//         } else {
//             op[key] = searchValue.value
//             shipOutApi.searchPreviewPklProduction(op).then(res => {
//                 if(res.success)
//                     dispatch({type: C.CHANGE_ITEM_LIST, payload: res.data})
//                     dispatch({type: C.CHANGE_PAGING_TOTAL, payload: res.meta.pages});
//                     dispatch({type: C.CHANGE_PAGING_CURRENT, payload: res.meta.page});
//             })
//         }
//     }
// }

export const changeSearchStatus = (value) => dispatch => {
    dispatch({type: C.CHANGE_SEARCH_STATUS, payload: value})
}