import React, { Component } from "react";
import { connect } from "react-redux";
import { toastr } from 'react-redux-toastr';
import I18n from 'i18n-js'
import { withRouter, Switch, Route } from "react-router-dom";
import * as PATH from '../../../../constants/routeConstants';
import * as R from 'ramda';
import CreatePlanContractorView from "../../../components/accounting/payable/CreatePlanContractor";
import PreivewContractor from './PreviewContractor';
import * as actions from "../../../../actions/accounting/payable/createInvoiceContractor"
import * as selector from "../../../../selectors/accounting/payable/createInvoiceContractor";
import * as utils from "../../../../functions/utils";

class AccountsPayablePlanContractor extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillMount() {
    this.props.loadData();
  }

  componentWillReceiveProps(nextProps) {
    const { success } = nextProps;
    if (success) {
      this.props.resetData();
      nextProps.router.history.push(PATH.ACCOUNTING_PAYABLE_INVOICE_URL);
      toastr.success(I18n.t('Notify.Success'), 'Create INV successfully')
    }
  }
  componentWillUnmount() {
    this.props.resetData();
  }

  changeSelectedCustomerPOs = (id, checked) => {
    const { selectableCustomerPOs } = this.props;
    const item = selectableCustomerPOs.find(item => item._id == id);
    this.props.changeChecked(item.raw, checked)
  }

  save = (data) => {
    const { invoiceNo } = this.props;
    if (_.isEmpty(invoiceNo)) {
      toastr.error(I18n.t('Notify.Error'), 'Missing Invoice No')
    } else {
      this.props.createContractorInvoice(data)
    }
  }

  render() {
    return (
      <Switch>
        <Route path={PATH.ACCOUNTING_PAYABLE_PLAN_CONTRACTOR_PREVIEW_URL}
          render={() => (
            <PreivewContractor />
          )}
        />
        <Route path={PATH.ACCOUNTING_PAYABLE_PLAN_CONTRACTOR_URL} exact
          render={() => (
            <CreatePlanContractorView
              {...this.props}
              save={data => this.save(data)}
              changeSelectedCustomerPOs={(id, checked) => this.changeSelectedCustomerPOs(id, checked)}
            />
          )}
        />
      </Switch>
    );
  }
}

AccountsPayablePlanContractor.propTypes = {};

AccountsPayablePlanContractor.defaultProps = {};

const mapStateToProps = state => ({
  isVATPerMonth: selector.isVATPerMonth(state),

  selectableContractors: selector.getSelectableContractors(state),
  selectedContractor: selector.getSelectedContractor(state),

  issuedDate: selector.getIssuedDate(state),
  invoiceNo: selector.getInvoiceNo(state),
  dueDate: selector.getDueDate(state),

  currencyTypeUSD: selector.getCurrencyTypeUSD(state),
  currencyTypeVND: selector.getCurrencyTypeVND(state),

  selectableCredits: selector.getSelectableCreditDebits(state),
  selectedCredit: selector.getSelectedCreditDebit(state),

  invAmount: selector.getAmount(state),
  invTotalAmount: selector.getTotalAmount(state),
  invTotalAmountSay: selector.getTotalAmountSay(state),

  selectableCustomerPOs: selector.getSelectableCustomerPOs(state),
  // selectedCustomerPOs: selector.getSelectedCustomerPOs(state),
  selectedCustomerPOs: selector.getCustomerPOsToMap(state),

  selectableConvertCurrencies: selector.getSelectableConvertCurrencies(state),
  selectedConvertCurrency: selector.getConvertCurrency(state),
  convertRate: selector.getConvertRate(state),
  convertAmount: selector.getAmountConvert(state),
  convertTotalAmount: selector.getTotalAmountConvert(state),

  mainVATNo: selector.getMainVATNo(state),
  mainVATDate: selector.getMainVATDate(state),
  mainVATNote: selector.getMainVATNote(state),
  mainVATTaxRate: selector.getMainVATTaxRate(state),
  mainVATTotalAmount: selector.getMainVATTotalAmount(state),
  mainVATAmount: selector.getMainVATAmount(state),
  mainVATGrandTotal: selector.getMainVATGrandTotal(state),
  dataApi: selector.fnGenerateRequestBody(state),
  success: selector.getSuccess(state),
  canPreview: selector.getCanPreview(state),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  loadData() {
    dispatch(actions.listContractor());
  },

  resetData() {
    dispatch(actions.resetData());
  },

  changeVATPerMonth(checked) {
    dispatch(actions.changeVATPerMonth(checked));
  },

  changeSelectedContractor(item) {
    dispatch(actions.changeSelectedContractor(item ? item.value : null));
  },

  changeIssuedDate(value) {
    dispatch(actions.changeIssuedDate(value));
  },

  changeInvoiceNo(value) {
    dispatch(actions.changeInvoiceNo(value));
  },

  changeDueDate(value) {
    dispatch(actions.changeDueDate(value));
  },

  changeSelectedCredit(item) {
    dispatch(actions.changeSelectedCredit(item ? item : null));
  },

  changeSelectedConvertCurrency(item) {
    dispatch(actions.changeSelectedConvertCurrency(item || null));
  },

  changeConvertRate(value) {
    dispatch(actions.changeConvertRate(value));
  },

  changeSearchTextCustomerPO(value) {
    dispatch(actions.changeSearchText(value));
  },

  changeCustomerPOPrice(cusId, value) {
    dispatch(actions.changeCustomerPOPrice({ _id: cusId, price: value }))
  },

  changeStylePrice(customerPO, styleId, price) {
    dispatch(actions.changeStylePrice({ customerPO, styleId, price }))
  },
  changeColorPrice(customerPO, styleId, cutTicketId, colorId, price) {
    dispatch(actions.changeColorPrice({ customerPO, styleId, cutTicketId, colorId, price }))
  },

  //--------- main VAT ---------//
  changeMainVATNo(value) {
    dispatch(actions.changeMainVATNo(value));
  },

  changeMainVATTotalAmount(value) {
    dispatch(actions.changeMainVATTotalAmount(value));
  },

  changeMainVATDate(value) {
    dispatch(actions.changeMainVATDate(value));
  },

  changeMainVATTaxRate(value) {
    dispatch(actions.changeMainVATTaxRate(value));
  },

  changeChecked(item, checked) {
    if (item) {
      dispatch(actions.changeChecked(item, checked));
    }
  },

  //   toggleActivePurchaseOrder(item) {
  //     if (item) {
  //       dispatch(actions.toggleActivePurchaseOrder(R.path(['_id'], item)));
  //     }
  //   },

  //--------- sub VAT ---------//
  changeSubVATNo(id, value) {
    if (id) {
      dispatch(actions.changeSubVATNo(id, value));
    }
  },

  changeSubVATDate(id, value) {
    if (id) {
      dispatch(actions.changeSubVATDate(id, value));
    }
  },

  changeSubVATTaxRate(id, value) {
    if (id) {
      dispatch(actions.changeSubTaxRate(id, value));
    }
  },

  changeSubVATNote(id, value) {
    if (id) {
      dispatch(actions.changeSubVATNote(id, value));
    }
  },

  changeSubVATTotalAmount(id, value) {
    if (id) {
      dispatch(actions.changeSubVATTotalAmount(id, value));
    }
  },

  onPreviewScreen() {
    dispatch(actions.activeScreenPreview())
    ownProps.router.history.push(PATH.ACCOUNTING_PAYABLE_PLAN_CONTRACTOR_PREVIEW_URL)
  },

  createContractorInvoice(data) {
    dispatch(actions.createContractorInvoice(data))
  }

});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AccountsPayablePlanContractor));
