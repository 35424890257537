import React from 'react';
import Modal from 'react-modal';
import I18n from 'i18n-js';
import PropTypes from 'prop-types';

const IEUSDeliveryItemEditDetail = props => {
    const style = {
        overlay: {
          position: 'fixed',
          backgroundColor: 'rgba(0, 0, 0, 0.75)',
          zIndex: '3'
        },
        content: {
          position: 'auto',
          top: '50%',
          left: '50%',
          backgroundColor: 'rgba(0,0,0,0)',
          border: '0px',
          borderRadius: '0px',
          outline: 'none',
        },
      };
      const {openModal, data} = props
    return (
        <Modal style = {style} isOpen = {openModal}>
            <div className="modal-dialog lg" role="document">
                    <div className="modal-content">
                        <form>
                        <div className="modal-header">
                            <h4 className="modal-title" id="myModalLabel">{'ADD ITEM'}</h4>
                        </div>
                        <div className="modal-body pd-0">
                            <h4 className="title">{data.name}{data.type ? ` - ${data.type}` : ''}</h4>
   
                            <div className="col-md-6 col-xs-12">
                                <div className="form-group">
                                    <div className="col-md-4 col-xs-12 text no-padding-left">
                                        <p>Quantity</p>
                                    </div>
                                    <div className="col-md-8 col-xs-12 value no-padding-right">
                                        <input type="text" className="form-control" placeholder="Quantity" disabled value={data.qty}/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-xs-12">
                                <div className="form-group">
                                    <div className="col-md-4 col-xs-12 text no-padding-left">
                                        <p>Warehouse</p>
                                    </div>
                                    <div className="col-md-8 col-xs-12 value no-padding-right">
                                        <input type="text" className="form-control" placeholder="Warehouse" 
                                            value= {data.warehouse}
                                            onChange = {e => props.changeContainerDetailTextValue('warehouse', (e.target.value))}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="mg-bottom-15"></div>

                            <h4 className="title mg-top-10">FEE</h4>
                            <div className="col-md-6 col-xs-12">
                                <div className="form-group">
                                    <div className="col-md-4 col-xs-12 text no-padding-left">
                                        <p>Cost</p>
                                    </div>
                                    <div className="col-md-8 col-xs-12 value no-padding-right">
                                        <input type="number" min = "0" className="form-control" placeholder="Cost"
                                            value= {data.cost}
                                            onChange = {e => props.changeContainerDetailValue('cost', ((e.target.value)))}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-xs-12">
                                <div className="form-group">
                                    <div className="col-md-4 col-xs-12 text no-padding-left">
                                        <p>Chassis Fee</p>
                                    </div>
                                    <div className="col-md-8 col-xs-12 value no-padding-right">
                                        <input type="number" min = "0" className="form-control" placeholder="Chassis Fee" 
                                            value= {data.chassisFee}
                                            onChange = {e => props.changeContainerDetailValue('chassisFee', ((e.target.value)))}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6 col-xs-12">
                                <div className="form-group">
                                    <div className="col-md-4 col-xs-12 text no-padding-left">
                                        <p>Trucking</p>
                                    </div>
                                    <div className="col-md-8 col-xs-12 value no-padding-right">
                                        <input type="number" min = "0" className="form-control" placeholder="Trucking"
                                            value= {data.trucking}
                                            onChange = {e => props.changeContainerDetailValue('trucking', (e.target.value))}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-xs-12">
                                <div className="form-group">
                                    <div className="col-md-4 col-xs-12 text no-padding-left">
                                        <p>Prepull</p>
                                    </div>
                                    <div className="col-md-8 col-xs-12 value no-padding-right">
                                        <input type="number" min = "0" className="form-control" placeholder="Prepull"
                                            value= {data.prepull}
                                            onChange = {e => props.changeContainerDetailValue('prepull', (e.target.value))}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-xs-12">
                                <div className="form-group">
                                    <div className="col-md-4 col-xs-12 text no-padding-left">
                                        <p>Pier Pass</p>
                                    </div>
                                    <div className="col-md-8 col-xs-12 value no-padding-right">
                                        <input type="number" min = "0" className="form-control" placeholder="Pier Pass"
                                            value= {data.pierPass}
                                            onChange = {e => props.changeContainerDetailValue('pierPass', ((e.target.value)))}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-xs-12">
                                <div className="form-group">
                                    <div className="col-md-4 col-xs-12 text no-padding-left">
                                        <p>Yard Storage</p>
                                    </div>
                                    <div className="col-md-8 col-xs-12 value no-padding-right">
                                        <input type="number" min = "0" className="form-control" placeholder="Yard Storage"
                                            value= {data.yardStorage}
                                            onChange = {e => props.changeContainerDetailValue('yardStorage', ((e.target.value)))}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-xs-12">
                                <div className="form-group">
                                    <div className="col-md-4 col-xs-12 text no-padding-left">
                                        <p>Waiting Time</p>
                                    </div>
                                    <div className="col-md-8 col-xs-12 value no-padding-right">
                                        <input type="number" min = "0" className="form-control" placeholder="Waiting Time"
                                            value= {data.waitingTime}
                                            onChange = {e => props.changeContainerDetailValue('waitingTime', ((e.target.value)))}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-xs-12">
                                <div className="form-group">
                                    <div className="col-md-4 col-xs-12 text no-padding-left">
                                        <p>Other Fees</p>
                                    </div>
                                    <div className="col-md-8 col-xs-12 value no-padding-right">
                                        <input type="number" min = "0" className="form-control" placeholder="Other Fees"
                                            value= {data.otherFees}
                                            onChange = {e => props.changeContainerDetailValue('otherFees', ((e.target.value)))}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-xs-12">
                                <div className="form-group">
                                    <div className="col-md-2 col-xs-12 text no-padding-left">
                                        <p>Note</p>
                                    </div>
                                    <div className="pd-0-10 col-md-10 col-xs-12 value no-padding-right">
                                        <textarea type="text" className="form-control" placeholder="Note"
                                            value= {data.note}
                                            onChange = {e => props.changeContainerDetailTextValue('note', e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>

                        
                        </div>
                        <div className="modal-footer">
                                <button type="button" className="bt-black" onClick={() => {props.close()}}>{I18n.t('Btn.CANCEL')}</button>
                                <button type="button" className="bt-orange" onClick={() => {props.ok(data), props.close()}}>{I18n.t('Btn.SUBMIT')}</button>
                        </div>
                        </form>
                    </div>
                </div>
        </Modal>
    )
}

IEUSDeliveryItemEditDetail.propTypes = {
    data: PropTypes.object,
    changeContainerDetailValue: PropTypes.func
}
IEUSDeliveryItemEditDetail.defaultProps = {
    data: {},
    changeContainerDetailValue: f => f
}

export default IEUSDeliveryItemEditDetail;