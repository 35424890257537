import {getListItemDAL, getListReserveDAL, returnStyleResources, exportReserve} from '../api/warehouseApi';

class warehouseServices {

  constructor() {
  }

  getListItem (options = {}) {
    return getListItemDAL(options);
  }

  getListReserve (options = {}) {
    return getListReserveDAL(options.query || options);
  }
  
  returnStyleResources (_id, styleId) {
    return returnStyleResources(_id, styleId);
  }

  exportReserve(search = {}) {
    return exportReserve(search);
  }
}

export default new warehouseServices();
