import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import BaseComponent from '../../base/BaseComponent';
import { bindActionCreators } from 'redux';
import I18n from 'i18n-js';
import CheckList from '../../common/layout/list/CheckList';
import _ from 'lodash';
import Panel from '../../common/layout/panel/Panel';
import * as packingActions from '../../../actions/production/packing';
import { toastr } from 'react-redux-toastr';
import * as selector from '../../../selectors/production/addPacking';
import ModalEditCartonPerPack from '../../common/modals/ModalEditCartonPerPack';

import icEditXIcon from '../../../stylesheets/images/ic-edit-x.svg';
import icXIcon from '../../../stylesheets/images/ic-x.svg';
import addNewIcon from '../../../stylesheets/images/add-new.svg';
import Select from 'react-select';
import 'react-select/dist/react-select.css';

class AddPacking extends BaseComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {
      isEdit: false,
      packedStyles: {},
      packed: {},
      styles: [],
      showDetail: [],
    };
  }

  componentWillMount = () => {
    const isEdit = _.get(this.props, 'appState.pageList[0].data.isEdit', '');
    const notifyDate = _.get(this.props, 'appState.pageList[0].data.packing.notifyDate', '');
    if (isEdit) {
      this.getPacking();
      if (notifyDate) {
        this.setState({ isEdit });
      }
    } else this.getPackingByOrderAndContractor();
  };

  componentWillReceiveProps(nextProps) {
    const {
      packedStyles,
      customer,
      customerPO,
      contractor,
      type,
      packing,
      success,
      canResetPackedQuantity,
      cartonType,
    } = nextProps;
    const isEdit = _.get(nextProps, 'appState.pageList[0].data.isEdit', '');
    packing === null && this.createPacking(packedStyles, customer, customerPO, contractor, type, cartonType);

    const { styles } = this.state;
    if (packing && styles.length == 0) {
      packing.styles.forEach(s => {
        const style = {
          items: [],
          props: {
            noBegin: (_.max(s.packings.map(pk => pk.props.no)) || 0) + 1,
            noEnd: (_.max(s.packings.map(pk => pk.props.no)) || 0) + 1,
            nwKgs: 0,
            gwKgs: 0,
            l: 0,
            w: 0,
            h: 0,
          },
          style: s.style,
        };
        styles.push(style);
      });
      this.setState({ styles });
    }

    if (success) {
      toastr.success(I18n.t('Notify.Success'), I18n.t('Notify.SaveSuccess'));
      this.props.packingActions.changeEmptyData();
    }

    if (canResetPackedQuantity && packing && isEdit) {
      this.props.packingActions.canResetPackedQuantity();
      let { getPackingTotal } = nextProps;
      _.map(packedStyles, style =>
        _.map(style.cutTickets, cut =>
          _.map(cut.colors, color =>
            _.map(color.sizes, size => {
              const total = this.getPackingTotal(style._id, cut._id, color._id, size.name, packing);
              nextProps.packingActions.resetPackedQuantity(style._id, cut._id, color._id, size.name, total);
            })
          )
        )
      );
    }
  }

  editCarton = (styleId, no) => {
    let { openModal, closeModal } = this.props.modal;
    let modal = <ModalEditCartonPerPack closeModal={closeModal} data={{ styleId, no }} ok={this.ok} />;
    openModal(modal);
  };

  ok = () => {};

  getPackingByOrderAndContractor = () => {
    const id = this.props.customerPO._id;
    const contractorId = _.get(this.props, 'contractor._id', undefined);
    let options = {};
    if (contractorId) {
      options = { contractorId };
    }
    this.props.packingActions.getPackingByOrder(id, options);
  };

  getPacking = () => {
    const id = this.props.packingInfo._id;
    this.props.packingActions.getPacking(id);
  };

  createPacking = (packedStyles, customer, customerPO, contractor, type, cartonType) => {
    this.props.packingActions.createPacking(packedStyles, customer, customerPO, contractor, type, cartonType);
  };

  onChangeStyle = (_id, checked) => {
    this.props.packingActions.changeCheckedStyle(_id, checked);
  };

  onChangePackingGoHH = (value, styleId, cutId, colorId, sizeName) => {
    const total = this.props.getTotal(styleId, cutId, colorId, sizeName);
    this.props.packingActions.changePackingNumber(value, styleId, cutId, colorId, sizeName, total);
  };

  getRemaining = (styleId, cutId, colorId, sizeName) => {
    const { packing, packedStyles } = this.props;
    let packedQuantity = 0;
    packedStyles.forEach(pks => {
      (!styleId || pks._id == styleId) &&
        pks.cutTickets.forEach(ct => {
          (!cutId || ct._id == cutId) &&
            ct.colors.forEach(c => {
              (!colorId || c._id == colorId) &&
                c.sizes.forEach(s => {
                  (!sizeName || s.name == sizeName) &&
                    (packedQuantity += Number(s.passQuantity || 0) - Number(s.packedQuantity || 0));
                });
            });
        });
    });
    let planQuantity = this.getPackingTotal(styleId, cutId, colorId, sizeName, packing);
    return packedQuantity - planQuantity;
  };

  getPackingTotal = (styleId, cutId, colorId, sizeName, packing) => {
    let planQuantity = 0;
    packing &&
      packing.styles.forEach(s => {
        (!styleId || s.style == styleId) &&
          s.packings &&
          s.packings.forEach(pk => {
            pk.items &&
              pk.items.forEach(item => {
                (!cutId || item.cutTicket == cutId) &&
                  (!colorId || item.color == colorId) &&
                  (!sizeName || item.name == sizeName) &&
                  (planQuantity += Number(item.planQuantity || 0));
              });
          });
      });
    return planQuantity;
  };

  onChangePackingItems = (value, styleId, cutId, colorId, sizeName) => {
    const { styles } = this.state;
    let style = styles.find(s => s.style == styleId);
    if (!style) {
      style = { items: [], props: {}, style: styleId };
      styles.push(style);
    }
    const remaining = this.getRemaining(styleId, cutId, colorId, sizeName);
    value = Number(value) < remaining ? Number(value) : remaining;
    value = value < 0 ? 0 : value;

    let items = style.items;
    let item = items.find(item => item.cutTicket == cutId && item.color == colorId && item.name == sizeName);
    if (item) item.planQuantity = value;
    else
      items.push({
        cutTicket: cutId,
        color: colorId,
        name: sizeName,
        planQuantity: value,
      });
    style.items = items;
    this.setState({ styles });
  };

  onChangePackingProps = (key, value, styleId) => {
    const { styles } = this.state;
    let style = styles.find(s => s.style == styleId);
    if (!style) {
      style = { items: [], props: {}, style: styleId };
      styles.push(style);
    }
    let props = style.props;
    props[key] = Number(value);
    style.props = props;
    this.setState({ styles });
  };

  onChangeActualPacking = (value, styleId, cutId, colorId, sizeName, total) => {
    this.props.packingActions.changeActualPackingNumber(value, styleId, cutId, colorId, sizeName, total);
  };

  onChangePackingNote = (value, styleId, cutId, colorId) => {
    this.props.packingActions.changePackingNote(value, styleId, cutId, colorId);
  };

  onChangeCarton = (name, value, styleId, cutId, colorId, isBlur = false) => {
    this.props.packingActions.changePropsCarton(name, value, styleId, cutId, colorId);
  };
  onChangeCartonFloat = (name, value, styleId, cutId, colorId, isBlur = false) => {
    this.props.packingActions.changePropsCartonFloat(name, value, styleId, cutId, colorId);
  };
  addCartonV2 = styleId => {
    const { styles } = this.state;
    let style = styles.find(s => s.style == styleId);
    let { props, items } = style;
    const { noBegin, noEnd } = props;
    const { packing, packedStyles } = this.props;
    const packings = [];
    if (!props.gwKgs || !props.nwKgs || !props.l || !props.h || !props.w) {
      toastr.error(I18n.t('Notify.Error'), 'Please change the information of the Carton');
      return;
    }
    const sumPacking = items.reduce((x, y) => x + y.planQuantity, 0);
    if (sumPacking == 0) {
      toastr.error(I18n.t('Notify.Error'), 'Please change packing number');
      return;
    }
    const maxNo = _.max(packing.styles.find(s => s.style == styleId).packings.map(pk => pk.props.no));
    let err = false;
    if (noEnd < noBegin) err = true;
    if (noEnd < 1 || noBegin < 1) err = true;
    if (!maxNo && noBegin != 1) err = true;
    if (maxNo && (noBegin <= maxNo || noBegin != maxNo + 1)) err = true;
    if (err) {
      toastr.error(I18n.t('Notify.Error'), 'Duplicate CTN No');
      return;
    }
    for (let index = noBegin; index <= noEnd; index++) {
      packings.push({
        items: _.cloneDeep(items),
        props: {
          no: index,
          group: noBegin == noEnd ? `${index}` : `${noBegin}-${noEnd}`,
          nwKgs: props.nwKgs,
          gwKgs: props.gwKgs,
          l: props.l,
          w: props.w,
          h: props.h,
        },
      });
    }
    style.items = [];
    props.noBegin = (_.max(packings.map(pk => pk.props.no)) || 0) + 1;
    props.noEnd = (_.max(packings.map(pk => pk.props.no)) || 0) + 1;
    this.setState({ styles });
    this.props.packingActions.createCartonV2(packing, styleId, packings);
  };
  deleteCartonV2 = (styleId, noBegin, noEnd) => {
    const { packing, packedStyles } = this.props;
    const { styles } = this.state;
    let style = styles.find(s => s.style == styleId);
    let { props, items } = style;
    const qttNo = noEnd - noBegin + 1;
    props.noBegin =
      (_.max(packing.styles.find(s => s.style == styleId).packings.map(pk => pk.props.no)) || 0) + 1 - qttNo;
    props.noEnd =
      (_.max(packing.styles.find(s => s.style == styleId).packings.map(pk => pk.props.no)) || 0) + 1 - qttNo;
    this.setState({ styles });
    this.props.packingActions.deleteCartonV2(packing, styleId, noBegin, noEnd);
  };

  onChangeTotal = (value, name) => {
    this.props.packingActions.changeTotal(value, name);
  };

  showCartonDetail = (styleId, checkShow, group) => {
    let { showDetail } = this.state;
    if (checkShow) {
      showDetail.push({
        style: styleId,
        group,
      });
    } else {
      _.remove(showDetail, elm => elm.style == styleId && elm.group == group);
    }
    this.setState({ showDetail });
  };

  save = () => {
    const { packing } = this.props;
    const { totalNW, totalGW, planPpTotal } = this.props.packing || 0;
    if (packing.missingStatus) {
      toastr.warning('This packing is resolved missing, you cannot edit');
      return;
    }
    let param = '';
    if (totalNW === 0) {
      param = 'emptyTotalNW';
    }
    if (totalGW === 0) {
      param = 'emptyTotalGW';
    }
    if (planPpTotal === 0) {
      param = 'emptyPacking';
    }
    switch (param) {
      case 'emptyPacking':
        toastr.error(I18n.t('Notify.Error'), 'Please change packing number');
        break;
      case 'emptyTotalNW':
        toastr.error(I18n.t('Notify.Error'), "Total N.W mustn't equal 0");
        break;
      case 'emptyTotalGW':
        toastr.error(I18n.t('Notify.Error'), "Total G.W mustn't equal 0");
        break;
      default: {
        if (packing._id) {
          this.props.packingActions.editPacking(packing._id, packing);
        } else {
          this.props.packingActions.savePacking(packing);
        }
        break;
      }
    }
  };

  getActualAndRemainingPacking = (styleId, colorId, cutId, sizeName) => {
    const { packing } = this.props;
    let actualQuantity = 0;
    let remaining = 0;
    packing.styles
      .find(s => s.style == styleId)
      .packings.forEach(pk => {
        pk.items.forEach(item => {
          if (item.name == sizeName && item.color == colorId && item.cutTicket == cutId) {
            actualQuantity += Number(item.actualQuantity || 0);
            remaining += Number(item.planQuantity || 0) - Number(item.actualQuantity || 0);
          }
        });
      });
    return { actualQuantity, remaining };
  };

  renderCustomer = (customer, customerPO, totalNW, totalGW) => {
    const {
      roles: { showCreate, showEdit },
      packing,
    } = this.props;
    const { planPpTotal, actualPpTotal, missingStatus, packingType, notifyDate } = packing || {
      planPpTotal: 0,
      actualPpTotal: 0,
    };
    const { isEdit } = this.state;
    const missing = planPpTotal - actualPpTotal;
    const remaining = packing && this.getRemaining();

    return (
      <ul className="content-header-title nav-package">
        <li className="mg-right-40 order-1 order-sm-1 width-200">
          <p>
            {I18n.t('PageProduction.Customer')}
            <span
              className="color-orange medium mg-left-25 size-16 text-truncate float-right"
              style={{ display: 'inline-block', maxWidth: 100 }}>
              {customer || ''}
            </span>
          </p>
        </li>
        <li className="mg-right-40 order-2 order-sm-4 width-200">
          <p>
            {I18n.t('PageProduction.CustomerPO')}
            <span
              className="color-orange medium mg-left-25 size-16 text-truncate float-right"
              style={{ display: 'inline-block', maxWidth: 100 }}>
              {customerPO || ''}
            </span>
          </p>
        </li>
        <li className="mg-right-40 order-3 order-sm-2">
          <p style={{ display: 'flex' }}>
            {I18n.t('PageProduction.TotalNW')}
            {packingType == 'Carton' ? (
              <strong className="medium mg-left-25 size-16">{totalNW || 0}</strong>
            ) : (
              <input
                type="number"
                min="0"
                value={totalNW || 0}
                onChange={evt => {
                  this.onChangeTotal(evt.target.value, 'totalNW');
                }}
                className="mg-left-25 form-control center w-70"
              />
            )}
          </p>
        </li>
        <li className="mg-right-40 order-4 order-sm-5">
          <p style={{ display: 'flex' }}>
            {I18n.t('PageProduction.TotalGW')}
            {packingType == 'Carton' ? (
              <strong className="medium mg-left-25 size-16">{totalGW || 0}</strong>
            ) : (
              <input
                type="number"
                min="0"
                value={totalGW || 0}
                onChange={evt => {
                  this.onChangeTotal(evt.target.value, 'totalGW');
                }}
                className="mg-left-25 form-control center w-70"
              />
            )}
          </p>
        </li>
        <li className="mg-right-40 order-5 order-sm-3">
          {/*  GOHH  PackingType */}
          {notifyDate ? (
            <p>
              {I18n.t('PageProduction.Missing')}
              <strong className="color-green medium mg-left-25 size-16">{missing}</strong>
            </p>
          ) : (
            <p>
              {I18n.t('PageProduction.Remaining')}
              <strong className="color-green medium mg-left-25 size-16">{remaining}</strong>
            </p>
          )}
        </li>
        <li className="mg-right-40 order-6 order-sm-6">
          {_.isEmpty(notifyDate) && (
            <p>
              {I18n.t('PageProduction.PackingQTY')}
              <strong className="color-red medium mg-left-25 size-16">{actualPpTotal}</strong>
            </p>
          )}
          {!_.isEmpty(notifyDate) && (
            <p>
              {I18n.t('PageProduction.TotalQTY')}
              <strong className="color-red medium mg-left-25 size-16">{actualPpTotal}</strong>
            </p>
          )}
        </li>
        {!missingStatus
          ? ((isEdit && showEdit) || showCreate) && (
              <li className="float-right add-filter-top order-7 order-sm-7" style={{ flex: '1 1 auto', width: 'auto' }}>
                <span
                  className="cursor-pointer"
                  onClick={this.save}
                  data-toggle="modal"
                  data-target="#modal-add-order"
                  style={{ float: 'right', width: 80 }}>
                  <i className="fa fa-floppy-o" aria-hidden="true"></i>
                  {I18n.t('PageProduction.SAVE')}
                </span>
              </li>
            )
          : ''}
      </ul>
    );
  };

  defineDataShow = style => {
    const { packedStyles, packing } = this.props;
    const cutTickets = _.cloneDeep(packedStyles.find(s => s._id == style.style).cutTickets);
    const colors = _.flattenDepth(cutTickets.map(ct => ct.colors));
    const sizeNames = _.uniq(_.flattenDeep(cutTickets.map(ct => ct.colors.map(c => c.sizes.map(s => s.name)))));
    let pkStyles = packedStyles
      .map(pkstyle => {
        if (pkstyle._id === style.style) {
          const cutTickets = _.map(pkstyle.cutTickets, cutTicket => {
            let newCutTicket = null;
            _.forEach(style.cutTickets, c => {
              if (c.cutTicket === cutTicket._id) {
                let colors = [];
                cutTicket.colors.forEach(c1 => {
                  if (_.find(c.colors, c2 => c1._id === c2.color, 0)) {
                    colors.push(c1);
                  }
                });
                newCutTicket = Object.assign({}, cutTicket);
                newCutTicket.colors = colors;
                return false;
              }
            });
            return newCutTicket;
          }).filter(i => i !== null);
          let newStyle = Object.assign({}, pkstyle);
          newStyle.cutTickets = cutTickets;
          return newStyle;
        }
        return null;
      })
      .filter(i => i !== null)[0];
    let packings = _.cloneDeep(packing.styles.find(s => s.style === style.style)).packings.map(pk => {
      const groupColor = _.groupBy(pk.items, item => item.color);
      pk.items = Object.values(groupColor)
        .map(cs => {
          const item = {};
          cs.forEach(c => {
            if (['gwKgs', 'nwKgs'].includes(c.name)) {
              pk.props[c.name] = c.value;
            }
            item[c.name] = c.actualQuantity || c.planQuantity;
            item.color = colors.find(c2 => c2._id == c.color);
          });
          return item;
        })
        .filter(item => item.color);
      return pk;
    });
    packings = _.sortBy(
      Object.values(_.groupBy(packings, pk => pk.props.group)).map(pks => {
        const [noBegin, noEnd] = pks[0].props.group.split('-');
        return pks.length == 1
          ? pks[0]
          : {
              items: [...pks[0].items],
              props: {
                ...pks[0].props,
                noBegin,
                noEnd,
              },
              childs: pks,
            };
      }),
      pk => pk.props.group
    );
    return { pkStyles, packings, sizeNames };
  };

  renderPanelContent = style => {
    const { getPackingTotal, packing } = this.props;
    const packingType = packing.type;
    const { isEdit, styles, showDetail } = this.state;
    const { props, items } = styles.find(s => s.style == style.style) || { props: {}, items: [] };
    let { pkStyles, packings, sizeNames } = this.defineDataShow(style);

    console.log('packings:', packings);
    return (
      <div className="packing-customerPO-content">
        {pkStyles &&
          (pkStyles.cutTickets || []).map((cut, idx) => {
            return (
              <div key={idx}>
                <ul className="content-header-title">
                  <li>
                    <div className="text">
                      <span className="left size-12 mg-right-5">{I18n.t('PageProduction.CutTicket')}</span>
                      <span className="color-orange bold mg-left-50 size-16">{_.get(cut, 'cutTicket', '')}</span>
                    </div>
                  </li>
                  <li className="float-right">
                    <div className="text">
                      <a className="text-decoration color-link mg-left-50">
                        {_.get(cut, 'store.packingStandard.name', '')}
                      </a>
                    </div>
                  </li>
                  <li className="float-right mg-right-50">
                    <div className="text">
                      <span className="left size-12 mg-right-15">{I18n.t('PageOrder.Store')}</span>
                      <span className="bold">{_.get(cut, 'store.name', '')}</span>
                    </div>
                  </li>
                  <li className="float-right mg-right-50">
                    <div className="text">
                      <span className="left size-12 mg-right-15">{I18n.t('PageOrder.MainLabel')}</span>
                      <span className="bold">{_.get(cut, 'mainLabel', '')}</span>
                    </div>
                  </li>
                </ul>
                {(cut.colors || []).map((color, cidx) => {
                  const sizes = _.get(color, 'sizes', []);
                  const colorPacking = getPackingTotal(pkStyles._id, cut._id, color._id);
                  const styleId = _.get(pkStyles, '_id', '');
                  const cutTicketId = _.get(cut, '_id', '');
                  const colorId = _.get(color, '_id', '');
                  const key = `${styleId}:${cutTicketId}:${colorId}`;
                  return (
                    <div key={cidx} className="clear-both mg-top-5">
                      <div className="display-flex bg-white">
                        <div className="pd-10 display-flex align-items-center pd-20 w-180">
                          <h3 className="center mg-auto size-12">
                            <span className="fw-normal">{I18n.t('PageProduction.Color')}</span>
                            <br />
                            <br />
                            <strong className="size-13">{color.name}</strong>
                            <br />
                            <br />
                            {_.isEmpty(packing.notifyDate) && <span className="bold color-red">{colorPacking}</span>}
                          </h3>
                        </div>
                        <div className="pd-10 bd-solid-left">
                          <table className="global-table tr-height-30 box-shadow-none">
                            {_.isEmpty(packing.notifyDate) && (
                              <tbody>
                                <tr style={{ borderBottom: 'unset' }} className="bg-none">
                                  <td className="fw-normal">{I18n.t('PageProduction.TotalOrder')}</td>
                                  {sizes.map((size, sidx) => {
                                    return (
                                      <td key={sidx}>
                                        <span className="medium">{size.name}</span> : {size.quantity}
                                      </td>
                                    );
                                  })}
                                </tr>
                                <tr className="bg-none">
                                  <td className="fw-normal">{I18n.t('PageProduction.TotalPassQA')}</td>
                                  {sizes.map((size, sidx) => {
                                    return (
                                      <td key={sidx}>
                                        <span className="medium">{size.name}</span> : {size.passQuantity}
                                      </td>
                                    );
                                  })}
                                </tr>

                                <tr className="bg-none">
                                  <td className="fw-normal">{I18n.t('PageProduction.Remaining')}</td>
                                  {sizes.map((size, sidx) => {
                                    const remaining = this.getRemaining(style.style, cut._id, color._id, size.name);
                                    return (
                                      <td className="center color-green" key={sidx}>
                                        {remaining || 0}
                                      </td>
                                    );
                                  })}
                                </tr>

                                <tr className="bg-none">
                                  <td className="fw-normal">{I18n.t('PageProduction.Packing')}</td>
                                  {sizes.map((size, sidx) => {
                                    const item = items.find(
                                      item =>
                                        item.name == size.name && item.color == color._id && item.cutTicket == cut._id
                                    );
                                    return (
                                      <td key={sidx} className="center w-100">
                                        <input
                                          type="number"
                                          min="0"
                                          value={item && item.planQuantity ? item.planQuantity : 0}
                                          onChange={evt => {
                                            this.onChangePackingItems(
                                              evt.target.value,
                                              style.style,
                                              cut._id,
                                              color._id,
                                              size.name
                                            );
                                          }}
                                          className="form-control center pd-right-0"
                                        />
                                      </td>
                                    );
                                  })}
                                </tr>
                              </tbody>
                            )}

                            {!_.isEmpty(packing.notifyDate) && (
                              <tbody>
                                <tr className="bg-none" style={{ borderBottom: 'unset' }}>
                                  <td className="fw-normal">{I18n.t('PageProduction.Actual')}</td>
                                  {sizes.map((size, sidx) => {
                                    const { actualQuantity } = this.getActualAndRemainingPacking(
                                      style.style,
                                      color._id,
                                      cut._id,
                                      size.name
                                    );
                                    return (
                                      <td key={sidx}>
                                        <span className="medium">{size.name}</span> : {actualQuantity || 0}
                                      </td>
                                    );
                                  })}
                                </tr>
                                <tr className="bg-none">
                                  <td className="fw-normal">{I18n.t('PageProduction.Missing')}</td>
                                  {sizes.map((size, sidx) => {
                                    const { remaining } = this.getActualAndRemainingPacking(
                                      style.style,
                                      color._id,
                                      cut._id,
                                      size.name
                                    );
                                    return (
                                      <td className="color-green" key={sidx}>
                                        <span className="medium">{size.name}</span> : {remaining || 0}
                                      </td>
                                    );
                                  })}
                                </tr>
                              </tbody>
                            )}
                          </table>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          })}
        {packingType == 'Carton' && (
          <div className="bg-white mg-top-2 pd-10">
            {_.isEmpty(packing.notifyDate) && (
              <ul className="display-table w-full">
                <li className="display-table-cell">
                  <div className="text">
                    <span className="left size-12 mg-right-5">{I18n.t('PageProduction.CTNNO')}</span>
                    <input
                      type="number"
                      min="1"
                      value={props && props.noBegin}
                      onChange={evt => {
                        this.onChangePackingProps('noBegin', evt.target.value, style.style);
                      }}
                      className="form-control inline-block center pd-right-0 w-50"
                    />
                    &nbsp;&nbsp;&rArr;&nbsp;&nbsp;
                    <input
                      type="number"
                      min="1"
                      value={props && props.noEnd}
                      onChange={evt => {
                        this.onChangePackingProps('noEnd', evt.target.value, style.style);
                      }}
                      className="form-control inline-block center pd-right-0 w-50"
                    />
                  </div>
                </li>
                <li className="display-table-cell">
                  <div className="text">
                    <span className="left size-12 mg-right-5">{I18n.t('PageProduction.NWKGS')}</span>
                    <input
                      type="number"
                      min="0"
                      value={(props && props.nwKgs) || 0}
                      onChange={evt => {
                        this.onChangePackingProps('nwKgs', evt.target.value, style.style);
                      }}
                      className="form-control inline-block center pd-right-0 w-50"
                    />
                  </div>
                </li>
                <li className="display-table-cell">
                  <div className="text">
                    <span className="left size-12 mg-right-5">{I18n.t('PageProduction.GWKGS')}</span>
                    <input
                      type="number"
                      min="0"
                      value={(props && props.gwKgs) || 0}
                      onChange={evt => {
                        this.onChangePackingProps('gwKgs', evt.target.value, style.style);
                      }}
                      className="form-control inline-block center pd-right-0 w-50"
                    />
                  </div>
                </li>
                <li className="display-table-cell">
                  <div className="text">
                    <span className="left size-12 mg-right-5">{I18n.t('PageProduction.MEANS')}</span>
                    <input
                      type="number"
                      min="0"
                      value={(props && props.l) || 0}
                      onChange={evt => {
                        this.onChangePackingProps('l', evt.target.value, style.style);
                      }}
                      className="form-control inline-block center pd-right-0 w-50"
                    />
                    &nbsp;&nbsp;&minus;&nbsp;&nbsp;
                    <input
                      type="number"
                      min="0"
                      value={(props && props.w) || 0}
                      onChange={evt => {
                        this.onChangePackingProps('w', evt.target.value, style.style);
                      }}
                      className="form-control inline-block center pd-right-0 w-50"
                    />
                    &nbsp;&nbsp;&minus;&nbsp;&nbsp;
                    <input
                      type="number"
                      min="0"
                      value={(props && props.h) || 0}
                      onChange={evt => {
                        this.onChangePackingProps('h', evt.target.value, style.style);
                      }}
                      className="form-control inline-block center pd-right-0 w-50"
                    />
                    <button
                      onClick={() => this.addCartonV2(style.style)}
                      className="cursor-pointer icon-add button vertical-align-middle">
                      <img src={addNewIcon} />
                    </button>
                  </div>
                </li>
              </ul>
            )}
          </div>
        )}
        {!isEdit && packingType == 'Carton' && packings.length > 0 && (
          <div className="bg-white mg-top-2">
            <table className="global-table w-full">
              <tbody>
                <tr>
                  <th className="center">{I18n.t('PageShiping.CTNNo')}</th>
                  <th className="center">{I18n.t('PageShiping.NW')}</th>
                  <th className="center">{I18n.t('PageShiping.GW')}</th>
                  <th className="center">{I18n.t('PageShiping.MEANS')}</th>
                  <th className="center">{I18n.t('PageShiping.M3')}</th>
                  <th className="center">Color</th>
                  {sizeNames.map((sizeName, idx) => (
                    <th key={idx} className="center">
                      {sizeName}
                    </th>
                  ))}
                  <th className="center">{I18n.t('PageShiping.QTY')}</th>
                  <th className="center">{I18n.t('PageShiping.QTY/CTN')}</th>
                  <th className="center">{I18n.t('PageShiping.Total')}</th>
                  <th className="center"></th>
                </tr>
                {packings.map((pk, idx) => {
                  const qty_ctn = pk.items.reduce(
                    (x, y) => x + Object.values(y).reduce((a, b) => a + (Number(b) || 0), 0),
                    0
                  );
                  return pk.items.map((item, itemIdx) => {
                    return (
                      <tr style={{ borderBottom: '1px solid #ccc' }}>
                        {itemIdx == 0 &&
                          (pk.childs ? (
                            <td className="center" rowspan={pk.items.length}>
                              {pk.props.noBegin}&nbsp;&nbsp;&rArr;&nbsp;&nbsp;{pk.props.noEnd}
                            </td>
                          ) : (
                            <td className="center" rowspan={pk.items.length}>
                              {pk.props.no}
                            </td>
                          ))}
                        {itemIdx == 0 && (
                          <td className="center" rowspan={pk.items.length}>
                            {pk.props.nwKgs}
                          </td>
                        )}
                        {itemIdx == 0 && (
                          <td className="center" rowspan={pk.items.length}>
                            {pk.props.gwKgs}
                          </td>
                        )}
                        {itemIdx == 0 && (
                          <td className="center" rowspan={pk.items.length}>
                            {pk.props.l}" x {pk.props.w}" x {pk.props.h}"
                          </td>
                        )}
                        {itemIdx == 0 && (
                          <td className="center" rowspan={pk.items.length}>
                            {pk.props.m3}
                          </td>
                        )}
                        <td className="center">{item.color.name}</td>
                        {sizeNames.map((sizeName, idx) => (
                          <td className="center color-orange">{item[sizeName] || 0}</td>
                        ))}
                        {itemIdx == 0 && (
                          <td className="center color-green" rowspan={pk.items.length}>
                            {pk.childs ? pk.childs.length : 1}
                          </td>
                        )}
                        {itemIdx == 0 && (
                          <td className="center color-green" rowspan={pk.items.length}>
                            {qty_ctn}
                          </td>
                        )}
                        {itemIdx == 0 && (
                          <td className="center color-red" rowspan={pk.items.length}>
                            {qty_ctn * (pk.childs ? pk.childs.length : 1)}
                          </td>
                        )}
                        {itemIdx == 0 && (
                          <td className="center" rowspan={pk.items.length}>
                            {_.isEmpty(packing.notifyDate) && (
                              <span
                                onClick={() =>
                                  this.deleteCartonV2(
                                    style.style,
                                    pk.props.noBegin || pk.props.no,
                                    pk.props.noEnd || pk.props.no
                                  )
                                }
                                className="cursor-pointer">
                                <img src={icXIcon} alt="" />
                              </span>
                            )}
                          </td>
                        )}
                      </tr>
                    );
                  });
                })}
              </tbody>
            </table>
          </div>
        )}
        {isEdit && packingType == 'Carton' && packings.length > 0 && (
          <div className="bg-white mg-top-2">
            <table className="global-table w-full">
              <tbody>
                <tr>
                  <th className="center"></th>
                  <th className="center">{I18n.t('PageShiping.CTNNo')}</th>
                  <th className="center">{I18n.t('PageShiping.NW')}</th>
                  <th className="center">{I18n.t('PageShiping.GW')}</th>
                  <th className="center">{I18n.t('PageShiping.MEANS')}</th>
                  <th className="center">{I18n.t('PageShiping.M3')}</th>
                  <th className="center">Color</th>
                  {sizeNames.map((sizeName, idx) => (
                    <th key={idx} className="center">
                      {sizeName}
                    </th>
                  ))}
                  <th className="center">{I18n.t('PageShiping.QTY')}</th>
                  <th className="center">{I18n.t('PageShiping.QTY/CTN')}</th>
                  <th className="center">{I18n.t('PageShiping.Total')}</th>
                  <th className="center"></th>
                </tr>
                {packings.map(pkRoot => {
                  const check = showDetail.find(sd => sd.style == style.style && sd.group == pkRoot.props.group);
                  const packings2 = !check ? [pkRoot] : [pkRoot, ...pkRoot.childs];
                  return packings2.map((pk, pkIdx) => {
                    const qty_ctn = pk.items.reduce(
                      (x, y) => x + Object.values(y).reduce((a, b) => a + (Number(b) || 0), 0),
                      0
                    );
                    return pk.items.map((item, itemIdx) => {
                      return (
                        <tr className={pkIdx !== 0 ? 'bg-gray' : ''} style={{ borderBottom: '1px solid #ccc' }}>
                          {itemIdx == 0 && (
                            <td className="center" rowspan={pk.items.length}>
                              {pk.childs && (
                                <span
                                  className="fa fa-caret-down cursor-pointer"
                                  style={{ marginLeft: 10 }}
                                  onClick={() => this.showCartonDetail(style.style, !check, pk.props.group)}></span>
                              )}
                            </td>
                          )}
                          {itemIdx == 0 &&
                            (pk.childs ? (
                              <td className="center" rowspan={pk.items.length}>
                                {pk.props.noBegin}&nbsp;&nbsp;&rArr;&nbsp;&nbsp;{pk.props.noEnd}
                              </td>
                            ) : (
                              <td className="center" rowspan={pk.items.length}>
                                {pk.props.no}
                              </td>
                            ))}
                          {itemIdx == 0 && (
                            <td className="center" rowspan={pk.items.length}>
                              {pk.props.nwKgs}
                            </td>
                          )}
                          {itemIdx == 0 && (
                            <td className="center" rowspan={pk.items.length}>
                              {pk.props.gwKgs}
                            </td>
                          )}
                          {itemIdx == 0 && (
                            <td className="center" rowspan={pk.items.length}>
                              {pk.props.l}" x {pk.props.w}" x {pk.props.h}"
                            </td>
                          )}
                          {itemIdx == 0 && (
                            <td className="center" rowspan={pk.items.length}>
                              {pk.props.m3 || ''}
                            </td>
                          )}
                          <td className="center">{(item.color || {}).name}</td>
                          {sizeNames.map((sizeName, idx) => (
                            <td className="center color-orange">{item[sizeName] || 0}</td>
                          ))}
                          {itemIdx == 0 && (
                            <td className="center color-green" rowspan={pk.items.length}>
                              {pk.childs ? pk.childs.length : 1}
                            </td>
                          )}
                          {itemIdx == 0 && (
                            <td className="center color-green" rowspan={pk.items.length}>
                              {qty_ctn}
                            </td>
                          )}
                          {itemIdx == 0 && (
                            <td className="center color-red" rowspan={pk.items.length}>
                              {qty_ctn * (pk.childs ? pk.childs.length : 1)}
                            </td>
                          )}
                          {itemIdx == 0 && (
                            <td className="center" rowspan={pk.items.length}>
                              {!_.isEmpty(packing.notifyDate) && !pk.childs && (
                                <span
                                  onClick={() => this.editCarton(style.style, pk.props.no)}
                                  className="cursor-pointer">
                                  <img src={icEditXIcon} alt="" />
                                </span>
                              )}
                            </td>
                          )}
                        </tr>
                      );
                    });
                  });
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
    );
  };

  renderPanelDetails = style => {
    const { packedStyles, packing } = this.props;
    const { actualPpTotal } = packing || { actualPpTotal: 0 };
    const remaining = packing && this.getRemaining(style.style);
    const tmp = _.find(packedStyles || [], packedStyle => packedStyle._id == style.style);
    return (
      <ul>
        <li>
          <div className="text">
            <span className="left">{I18n.t('PageProduction.Style')}</span>
            <span className="right size-15">
              <span className="medium">{(tmp && tmp.style) || ''}</span>
            </span>
          </div>
        </li>
        <li className="float-right ">
          <div className="text">
            {_.isEmpty(packing.notifyDate) && (
              <span className="left size-12 mg-right-5">{I18n.t('PageProduction.PackingQTY')}</span>
            )}
            {_.isEmpty(packing.notifyDate) && <span className="right size-15">{actualPpTotal}</span>}
          </div>
        </li>
        <li className="float-right mg-right-50">
          <div className="text">
            {_.isEmpty(packing.notifyDate) && (
              <span className="left size-12 mg-right-5">{I18n.t('PageProduction.Remaining')}</span>
            )}
            {_.isEmpty(packing.notifyDate) && <span className="right size-15 mg-left-15">{remaining}</span>}
          </div>
        </li>
      </ul>
    );
  };

  render = () => {
    const { packing, packedStyles, cartonType } = this.props;
    const name = _.get(this.props, 'customer.name', null);
    const { customer } = this.props.packingInfo || {};
    const getCustomerName = name || customer; // view || add

    const { customerPO } = this.props.customerPO || this.props.packingInfo || '';
    const totalNW = _.get(this.props, 'packing.totalNW', 0);
    const totalGW = _.get(this.props, 'packing.totalGW', 0);
    const styles =
      packing && packing.styles
        ? (packing.styles || []).map((item, idx) => ({
            name: packedStyles ? _.get(packedStyles[idx], 'style', '') : '',
            _id: _.get(item, 'style', ''),
            active: _.get(item, 'active', false),
          }))
        : [];

    return (
      <div id="content">
        <div id="view-packing">
          {this.renderCustomer(getCustomerName, customerPO, totalNW, totalGW)}
          <CheckList
            items={styles}
            title={I18n.t('PageProduction.Style')}
            onChange={(_id, checked) => this.onChangeStyle(_id, checked)}
          />
          <div className="panel-group wrap-scrollbar" id="panel-list">
            {packing && packing.styles
              ? (packing.styles || []).map((style, index) => {
                  if (!_.get(style, 'active', false)) return undefined;
                  return (
                    <Panel
                      key={_.get(style, 'style', index)}
                      id={_.get(style, 'style', index)}
                      renderContent={() => this.renderPanelContent(style)}
                      renderPanelDetails={() => cartonType == this.renderPanelDetails(style)}
                    />
                  );
                })
              : null}
          </div>
        </div>
      </div>
    );
  };
}

function mapStateToProps(state, ownProps) {
  return {
    customer: selector.getCustomer(state),
    customerPO: selector.getCustomerPO(state),
    contractor: selector.getContractor(state),
    type: selector.getPackingType(state),
    cartonType: selector.getCartonType(state),
    packedStyles: selector.getPackingByOrder(state),
    packing: selector.getPacking(state),
    getRemaining: selector.getRemaining(state),
    getRemainingCustomer: selector.getRemainingCustomer(state),
    getPackingTotal: selector.getPackingTotal(state),
    getPackingTotalCarton: selector.getPackingTotalCarton(state),
    getTotal: selector.getTotal(state),
    getCarton: selector.getCarton(state),
    success: selector.getSuccess(state),
    packingInfo: selector.getPackingInfo(state),
    canResetPackedQuantity: selector.resetPackedQuantity(state),
    listCartonsForEdit: selector.getListCartonsForEdit(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    packingActions: bindActionCreators(packingActions, dispatch),
  };
}

AddPacking.propTypes = {
  data: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddPacking);
