import Base from './base';

class Style extends Base {
  constructor () {
    super();

    this.getAllStyleDAL = this.getAllStyleDAL.bind(this);
    this.getListStyleDAL = this.getListStyleDAL.bind(this);
    this.addStyleDAL = this.addStyleDAL.bind(this);
    this.editStyleDAL = this.editStyleDAL.bind(this);
    this.getStyleDAL = this.getStyleDAL.bind(this);
    this.deleteStyleDAL = this.deleteStyleDAL.bind(this);
    this.getListStyleAssignmentDAL = this.getListStyleAssignmentDAL.bind(this);
  }

  getAllStyleDAL (query) {
    return super.execute(this.Urls.style.all, this.ApiMethod.get, query);
  }

  getListStyleDAL (orderId, query) {
    return super.execute(this.Urls.style.list.replace('{id}', orderId), this.ApiMethod.get, query);
  }

  addStyleDAL (orderId, body) {
    return super.execute(this.Urls.style.create.replace('{id}', orderId), this.ApiMethod.post, body);
  }

  editStyleDAL (id, body) {
    return super.execute(this.Urls.style.update + '/' + id, this.ApiMethod.put, body);
  }

  getStyleDAL (id, options) {
    return super.execute(this.Urls.style.detail + '/' + id, this.ApiMethod.get, options);
  }

  deleteStyleDAL (id) {
    return super.execute(this.Urls.style.delete + '/' + id, this.ApiMethod.delete, null);
  }

  getListStyleAssignmentDAL (options) {
    return super.execute(this.Urls.style.listStyleAssignment, this.ApiMethod.get, options);
  }
}

export default new Style();
