const C = "EDIT_SHIPMENT";

export default {
    CHANGE_SHIPMENT_DETAIL: `${C}_CHANGE_SHIPMENT_DETAIL`,
    CHANGE_SHIPMENT_INFO: `${C}_CHANGE_SHIPMENT_INFO`,
    CHANGE_STANDARD: `${C}_CHANGE_STANDARD`,
    CHANGE_ACTIVE_ORDER: `${C}_CHANGE_ACTIVE_ORDER`,
    CHANGE_ACTIVE_STYLE: `${C}_CHANGE_ACTIVE_STYLE`,
    CHANGE_UNIT_PRICE: `${C}_CHANGE_UNIT_PRICE`,
    UPDATE_SUCCESS: `${C}_UPDATE_SUCCESS`,
    RESET_DATA: `${C}_RESET_DATA`,
    CHANGE_LIST_FORWARDER: `${C}_CHANGE_LIST_FORWARDER`,
    ADD_FORWARDER_INTO_LIST: `${C}_ADD_FORWARDER_INTO_LIST`,
    ACTIVE_SCREEN_EDIT_SHIPMENT: `${C}_ACTIVE_SCREEN_EDIT_SHIPMENT`
}