import * as types from '../constants/actionTypes';
import {showLoading, hideLoading} from './commonUseActions';
import sampleApprovalServices from '../services/sampleApprovalServices';

const getDetail = (styleId, query = {}, reset = true) => dispatch => {

    // showLoading(types, dispatch);

    reset && dispatch({
      type: types.SAMPLE_APPROVAL.RESET_DETAIL,
      data: {}
    });
    sampleApprovalServices.getDetail(styleId, query).then((res) => {
      hideLoading(types, dispatch);
      if (res) {
        dispatch({
          type: types.SAMPLE_APPROVAL.GET_DETAIL_SUCCESS,
          data: res.data || {}
        });
      }
    }, (error) => {
      hideLoading(types, dispatch);
    });
}

function editSampleApproval (styleId, data) {
  return (dispatch) => {

    showLoading(types, dispatch);

    sampleApprovalServices.editSampleApproval(styleId, data).then((res) => {
      hideLoading(types, dispatch);

      if (res && res.success) {
        dispatch({
          type: types.SAMPLE_APPROVAL.RELOAD,
          data: true
        });
      }
    }, (error) => {
      hideLoading(types, dispatch, error);
    });
  };
}

function resetDetail () {
  return (dispatch) => {
    dispatch({
      type: types.SAMPLE_APPROVAL.RESET_DETAIL,
      data: {}
    });
  };
}

function updateSampleApproval (styleId, status){
  return (dispatch) => {

    showLoading(types, dispatch);

    return sampleApprovalServices.updateSampleApproval(styleId, status).then((res) => {
      hideLoading(types, dispatch);
      return res;
    }, (error) => {
      hideLoading(types, dispatch, error);
      return error;
    });
  };
}

function getListSampleApproval (options){
  return (dispatch) => {

    showLoading(types, dispatch);

    sampleApprovalServices.getListSampleApproval(options).then((res) => {

      hideLoading(types, dispatch);
      if (res) {
        dispatch({
          type: types.SAMPLE_APPROVAL.GET_LIST,
          data: {
            items: res.data.items,
            total: res.data.count
          }
        });
      }
    }, (error) => {
      hideLoading(types, dispatch);
    });
  };
}

function getListCustomerPO (query = {}, reset = true) {
    return (dispatch) => {

        showLoading(types, dispatch);

        reset && dispatch({
            type: types.SAMPLE_APPROVAL.RESET_DETAIL,
            data: {}
        });

        sampleApprovalServices.getListCustomerPo(query).then((res) => {
            hideLoading(types, dispatch);
            if (res) {
                dispatch({
                    type: types.SAMPLE_APPROVAL.GET_LIST_CUSTOMER_PO,
                    data: res || {}
                });
            }
        }, (error) => {
            hideLoading(types, dispatch);
        });
    };
}

function getExportCurrent(orderId){
    return sampleApprovalServices.getExportCurrent(orderId);
}

export default {
  getListCustomerPO,
  editSampleApproval,
  getDetail,
  resetDetail,
  updateSampleApproval,
  getListSampleApproval,
  getExportCurrent
};
