import * as types from '../constants/actionTypes';
import {
    showLoading,
    hideLoading
} from './commonUseActions';
import dashboardServices from '../services/dashboardServices';
import _ from 'lodash';


export const getDashboard = (options) => (dispatch) => {
    // showLoading(types, dispatch);
    dispatch({
        type: types.DASHBOARD.LOADING
    });
    return dashboardServices.getDashboard(options).then((res) => {
        dispatch({
            type: types.DASHBOARD.TOTAL,
            data: res.data.total
        });
        dispatch({
            type: types.DASHBOARD.GET_DASHBOARD,
            data: res.data || []
        });
        //init value for filter
        const newOrders = _.filter(_.get(res, 'data.orders', []), (o) => {
            const ele = _.get(o, 'overview.behindSchedule', '');
            return ele == 1;
        });
        dispatch({
            type: types.DASHBOARD.FILTERED_DASHBOARD,
            data: newOrders
        });
        dispatch({
            type: types.DASHBOARD.CANCEL_LOADING
        });
        //   hideLoading(types, dispatch);
        return res;
    }, (error) => {
        // hideLoading(types, dispatch);
        dispatch({
            type: types.DASHBOARD.CANCEL_LOADING
        });
    });

};

export const filterDashboard = (orders, filter = undefined) => (dispatch) => {
    if (!filter) {
        return orders;
    }
    const newOrders = _.filter(orders, (o) => {
        const ele = _.get(o, `overview.${filter}`, '');
        return ele == 1;
    });
    dispatch({
        type: types.DASHBOARD.FILTERED_DASHBOARD,
        data: newOrders
    });
    return newOrders;
}

export const filterLine2Dashboard = (orders, prod) => (dispatch) => {

    const newOrders = _.filter(orders, (o) => {
        const customPO = _.get(o, '_id.order.customerPO', '');
        let order = undefined;
        prod.forEach(p => {
            if (customPO == p.customerPO) {
                order = o;
            }
        });
        return order !== undefined;
    });
    dispatch({
        type: types.DASHBOARD.FILTERED_DASHBOARD,
        data: newOrders
    });
    return newOrders;
}