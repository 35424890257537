import { createSelector } from 'reselect';
import _ from 'lodash';
import { genVietNamDong, genDollarToText } from '../../../functions/helpers';
import formatCurrency from 'format-currency';

const genNumberWithCurrency = (currency, amount) => {
  if (currency == 'VND') {
    return formatCurrency(amount) + ' VND';
  }
  if (currency == 'USD') {
    return '$' + formatCurrency(amount);
  }
  return formatCurrency(amount);
};

export const getPaymentTermDays = code => {
  let days = 0;
  switch (code.toLowerCase()) {
    case 'net30':
      days = 30;
      break;
    case 'net45':
      days = 45;
      break;
    case 'net60':
      days = 60;
      break;
    case 't/t in advance':
    default:
      days = 0;
  }
  return days;
};

export const isFetchingSelectableCustomer = ({ createReceivableInvoiceNormal }) => {
  return createReceivableInvoiceNormal.fetchingSelectableCustomers;
};

export const getSelectableCustomers = ({ createReceivableInvoiceNormal }) => {
  // return createReceivableInvoiceNormal.selectableCustomers;
  return _.map(createReceivableInvoiceNormal.selectableCustomers, s => ({
    value: s.customerId,
    label: s.name,
    currency: s.currency,
    paymentTerm: s.paymentTerm,
  }));
};

export const getSelectedCustomer = ({ createReceivableInvoiceNormal }) => {
  return createReceivableInvoiceNormal.selectedCustomer;
};

export const getSelectedCustomerCurrency = ({ createReceivableInvoiceNormal }) => {
  return _.get(createReceivableInvoiceNormal, 'selectedCustomer.currency', '');
};

export const isVATPerCustomer = ({ createReceivableInvoiceNormal }) => {
  return createReceivableInvoiceNormal.vatPerCustomer;
};

export const getCreditForSeaFreight = ({ createReceivableInvoiceNormal }) => {
  return createReceivableInvoiceNormal.creditForSeaFreight;
};

export const getSelectableCreditDebits = ({ createReceivableInvoiceNormal }) => {
  // return createReceivableInvoiceNormal.selectableCreditDebits;
  return _.map(createReceivableInvoiceNormal.selectableCreditDebits, s => ({
    value: s._id,
    label: s.code,
    type: s.type,
    amount: s.amount || 0,
  }));
};

export const getSelectedCreditDebit = ({ createReceivableInvoiceNormal }) => {
  return createReceivableInvoiceNormal.selectedCreditDebit;
};

export const getSelectablePriceTypes = ({ createReceivableInvoiceNormal }) => {
  return _.map(createReceivableInvoiceNormal.selectablePriceTypes, el => {
    return { value: el, label: el };
  });
};

export const getSelectedPriceType = ({ createReceivableInvoiceNormal }) => {
  return createReceivableInvoiceNormal.selectedPriceType;
};

export const getIssuedDate = ({ createReceivableInvoiceNormal }) => {
  return createReceivableInvoiceNormal.issuedDate;
};

export const getDueDate = ({ createReceivableInvoiceNormal }) => {
  return createReceivableInvoiceNormal.dueDate;
};

export const getInvoiceNo = ({ createReceivableInvoiceNormal }) => {
  return createReceivableInvoiceNormal.invoiceNo;
};

export const getSelectableShippingModes = ({ createReceivableInvoiceNormal }) => {
  return _.map(createReceivableInvoiceNormal.selectableShippingModes, el => {
    return { label: el, value: el };
  });
};

export const getSelectedShippingMode = ({ createReceivableInvoiceNormal }) => {
  return createReceivableInvoiceNormal.selectedShippingMode;
};

export const getPaymentTerm = ({ createReceivableInvoiceNormal }) => {
  const code = _.get(createReceivableInvoiceNormal, 'selectedCustomer.paymentTerm', '');
  const days = getPaymentTermDays(code);
  return days;
};

export const getAmount = ({ createReceivableInvoiceNormal }) => {
  throw 'NotImplementedException';
};

// export const getTotalAirCost = ({ createReceivableInvoiceNormal }) => {
//   throw "NotImplementedException";
// }

// export const getTotalCreditForSeaFreight = ({ createReceivableInvoiceNormal }) => {
//   throw "NotImplementedException";
// }

export const getTotalAmount = ({ createReceivableInvoiceNormal }) => {
  throw 'NotImplementedException';
};

export const getSelectableConvertCurrencies = ({ createReceivableInvoiceNormal }) => {
  return _.map(createReceivableInvoiceNormal.selectableConvertCurrencies, el => {
    return { label: el, value: el };
  });
};

export const getSelectedConvertCurrency = ({ createReceivableInvoiceNormal }) => {
  return createReceivableInvoiceNormal.selectedConvertCurrency;
};

export const getConvertRate = ({ createReceivableInvoiceNormal }) => {
  return createReceivableInvoiceNormal.convertRate;
};

export const getCheckableBills = ({ createReceivableInvoiceNormal }) => {
  console.log(createReceivableInvoiceNormal);
  return createReceivableInvoiceNormal.checkableBillList;
};

export const getCheckedBills = ({ createReceivableInvoiceNormal }) => {
  // return createReceivableInvoiceNormal.checkedBillList;
  return _.map(createReceivableInvoiceNormal.checkedBillList, el => {
    el.customerPOs = _.map(el.customerPOs, item => {
      let totalQuantity = _.reduce(
        item.data,
        (total, el) => {
          return total + parseInt(el.qty);
        },
        0
      );
      item.totalQuantity = totalQuantity;
      return item;
    });
    return el;
  });
};

export const getDataRequestShipOutByCustomer = ({ createReceivableInvoiceNormal }) => {
  let obj = {};
  obj.chargeBack = false;
  obj.billNo = _.map(createReceivableInvoiceNormal.checkedBillList, el => el.billNo);
  obj.shipMode = _.get(createReceivableInvoiceNormal, 'selectedShippingMode.value', null);
  obj.customer = _.get(createReceivableInvoiceNormal, 'selectedCustomer.value', null);
  return obj;
};

export const getMainVATNo = ({ createReceivableInvoiceNormal }) => {
  return createReceivableInvoiceNormal.mainVATNo;
};

export const getMainVATDate = ({ createReceivableInvoiceNormal }) => {
  return createReceivableInvoiceNormal.mainVATDate;
};

export const getMainVATTaxRate = ({ createReceivableInvoiceNormal }) => {
  return createReceivableInvoiceNormal.mainVATTaxRate;
};

export const getMainVATTotalAmount = ({ createReceivableInvoiceNormal }) => {
  return createReceivableInvoiceNormal.mainVATTotalAmount;
};

// export const getMainVATAmount = ({ createReceivableInvoiceNormal }) => {
//   // TODO.
//   throw "NotImplementedException";
// }
export const getMainVATAmount = createSelector([getMainVATTotalAmount, getMainVATTaxRate], (totalAmount, rate) => {
  return ((totalAmount * rate) / 100).toFixed(2);
});

// export const getMainVATGrandTotal = ({ createReceivableInvoiceNormal }) => {
//   // TODO.
//   throw "NotImplementedException";
// }
export const getMainVATGrandTotal = createSelector(
  [getMainVATAmount, getMainVATTotalAmount],
  (amountVAT, totalAmount) => {
    return (parseFloat(amountVAT) + parseFloat(totalAmount || 0)).toFixed(2);
  }
);

/**
 * Bill | Cont
 */
export const getDisplayType = createSelector([getSelectedShippingMode], shippingMode => {
  // If mode is Sea then Bill, otherwise Cont
  throw 'NotImplementedException';
});

/**
 * Based on display type, if Bill then use checkableBillList else checkableContList
 */
export const getCheckableItemList = createSelector(
  [getDisplayType, ({ createReceivableInvoiceNormal }) => createReceivableInvoiceNormal],
  (displayType, state) => {
    throw 'NotImplementedException';
  }
);
export const getErrorMessages = ({ createReceivableInvoiceNormal }) => createReceivableInvoiceNormal.errorsMessage;

export const getEditableItemList = createSelector(
  [getDisplayType, ({ createReceivableInvoiceNormal }) => createReceivableInvoiceNormal],
  (displayType, state) => {
    throw 'NotImplementedException';
  }
);

/**
 * Return a function to generate request body which will be used to send to api
 */
export const fnGenerateRequestBody = ({ createReceivableInvoiceNormal }) => () => {
  throw 'NotImplementedException';
};

export const getAmountInv = ({ createReceivableInvoiceNormal }) => {
  return _.reduce(
    createReceivableInvoiceNormal.checkedBillList,
    (total, el) => {
      return (
        total +
        _.reduce(
          el.customerPOs,
          (t, customerPO) => {
            return t + parseFloat(customerPO.totalAmount || 0);
          },
          0
        )
      );
    },
    0
  );
};

export const getTotalAirCost = ({ createReceivableInvoiceNormal }) => {
  return _.reduce(
    createReceivableInvoiceNormal.checkedBillList,
    (total, el) => {
      return (
        total +
        _.reduce(
          el.customerPOs,
          (t, customerPO) => {
            return t + parseFloat(customerPO.airCost || 0);
          },
          0
        )
      );
    },
    0
  );
};

export const getTotalAmountInv = createSelector(
  [getAmountInv, getTotalAirCost, getMainVATAmount, getSelectedCreditDebit, isVATPerCustomer],
  (amount, airCost, mainVAT, selectedCreditDebit, isVat) => {
    if (!isVat) {
      mainVAT = 0;
    }
    let tempAmount = 0;
    if (selectedCreditDebit) {
      tempAmount = selectedCreditDebit.type == 'Debit' ? -1 * selectedCreditDebit.amount : selectedCreditDebit.amount;
    }
    return (parseFloat(amount) + parseFloat(airCost || 0) + parseFloat(mainVAT || 0) + parseFloat(tempAmount)).toFixed(
      2
    );
  }
);

export const getTotalAmountSay = createSelector(
  [({ createReceivableInvoiceNormal }) => createReceivableInvoiceNormal, getTotalAmountInv],
  (createReceivableInvoiceNormal, totalAmount) => {
    let say = '';
    const selectedCustomer = _.get(createReceivableInvoiceNormal, 'selectedCustomer', {});
    if ((selectedCustomer || {}).currency == 'VND') {
      say = genVietNamDong(_.round(totalAmount, 2));
    } else {
      say = genDollarToText(_.round(totalAmount, 2));
    }
    return say;
  }
);

/**
 * Get amount convert using convertRate and selectedConvertCurrency to calculate
 */
export const getAmountConvert = createSelector(
  [({ createReceivableInvoiceNormal }) => createReceivableInvoiceNormal, getAmountInv],
  (state, amountINV) => {
    const { selectedConvertCurrency, convertRate } = state;
    if (amountINV && convertRate) {
      return genNumberWithCurrency(
        selectedConvertCurrency.value || selectedConvertCurrency,
        Number(amountINV * convertRate).toFixed(2)
      );
    }
    return '';
  }
);

/**
 * Get total amount converted using convert rate and selected convert currency
 */
export const getTotalAmountConvert = createSelector(
  [({ createReceivableInvoiceNormal }) => createReceivableInvoiceNormal, getTotalAmountInv],
  (state, totalAmountINV) => {
    if (totalAmountINV && state.convertRate) {
      const { convertRate, selectedConvertCurrency } = state;
      return genNumberWithCurrency(
        selectedConvertCurrency.value || selectedConvertCurrency,
        Number(totalAmountINV * convertRate).toFixed(2)
      );
    }
    return '';
  }
);

export const getTotalCreditForSeaFreight = createSelector([getAmountInv, getCreditForSeaFreight], (total, credit) => {
  return ((parseFloat(total) * parseFloat(credit || 0)) / 100).toFixed(2);
});

export const getDataApi = createSelector(
  [getTotalAmountInv, ({ createReceivableInvoiceNormal }) => createReceivableInvoiceNormal],
  (totalAmountInv, createReceivableInvoiceNormal) => {
    let data = {};
    data.customer = _.get(createReceivableInvoiceNormal, 'selectedCustomer.value', null);
    data.invNo = createReceivableInvoiceNormal.invoiceNo;
    data.creditSea = createReceivableInvoiceNormal.creditForSeaFreight;
    data.priceType = _.get(createReceivableInvoiceNormal, 'selectedPriceType.value', null);
    data.issuedDate = createReceivableInvoiceNormal.issuedDate;
    data.paymentTerm = createReceivableInvoiceNormal.paymentTerm || '';
    data.dueDate = createReceivableInvoiceNormal.dueDate;
    data.shipMode = _.get(createReceivableInvoiceNormal, 'selectedShippingMode.value', null);
    data.amount = totalAmountInv || 0;
    data.vatPerMonth = createReceivableInvoiceNormal.vatPerCustomer;
    data.convertType = _.get(createReceivableInvoiceNormal, 'selectedConvertCurrency.value', null);
    data.convertRate = createReceivableInvoiceNormal.convertRate;
    if (data.vatPerMonth) {
      data.vatNo = createReceivableInvoiceNormal.mainVATNo;
      data.vatDate = createReceivableInvoiceNormal.mainVATDate || new Date();
      data.taxRate = createReceivableInvoiceNormal.mainVATTaxRate;
      data.totalAmount = createReceivableInvoiceNormal.mainVATTotalAmount;
    }
    data.billsContainers = [];
    _.each(createReceivableInvoiceNormal.checkedBillList, item => {
      let bill = {};
      bill.billNo = item.billNo;
      if (!data.vatPerMonth) {
        bill.vatNo = item.vatNo;
        bill.vatDate = item.vatDate || new Date();
        bill.taxRate = item.taxRate;
        bill.totalAmount = item.totalAmount;
      }
      let orders = [];
      _.each(item.customerPOs, elem => {
        let tmp = {};
        tmp.order = _.get(elem, 'data[0].orderId');
        tmp.airCost = elem.airCost;
        tmp.note = elem.note;
        tmp.items = [];
        _.each(elem.data, o => {
          tmp.items.push({
            ctns: o.cnts,
            style: o.style,
            pg: o.pg || null,
            cutTicket: o.cutTicket,
            contractor: o.contractor,
            store: o.store,
            label: o.label || null,
            color: o.color,
            qty: o.qty,
            unitPrice: o.unitPrice || null,
          });
        });

        orders.push(tmp);
      });
      bill.orders = orders;
      data.billsContainers.push(bill);
    });
    data.creditDebit = _.get(createReceivableInvoiceNormal, 'selectedCreditDebit.value', null);
    return data;
  }
);
