import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import sampleApprovalActions from '../../../actions/sampleApprovalActions';
import {sampleApprovalStatus} from '../../../constants/EnumTypes';
const DateTimeField = require('react-datetime');
import _ from 'lodash';
import moment from 'moment';
import I18n from 'i18n-js';

class GarmentStatusEdit extends Component {
  constructor (props, context) {
    super(props, context);
    this.state = {
      item: {},
      errors: {}
    };
  }

  componentWillMount () {
    const {item} = this.props;
    let data = _.cloneDeep(item);
    this.setState({item: data});
  }

  onChange (path, value) {
    let {item} = this.state;
    this.setState({item: _.set(item, path, value)});
  }

  validate () {
    let {item, errors} = this.state;
    let fields = ['fit', 'pp', 'top'];

    fields.forEach(f => {
      let status = _.get(item, `garment.${f}.status`);
      !_.isUndefined(status) && !status ? _.set(errors, f, `Please select ${_.upperCase(f)}`) : _.set(errors, f, '');
    });
    this.setState({errors});

    let flag = Object.keys(errors).every(k => !errors[k]);

    return flag;
  }

  save () {
    let {item} = this.state;
    let fields = ['fit', 'pp', 'top'];

    // let garment = _.cloneDeep(item.garment) || {}
    let garment = fields.reduce((acc, k) => {
      let field = _.cloneDeep(_.get(item, `garment.${k}`)) || {};
      !_.isUndefined(_.get(field, `status`)) && !_.get(field, 'date') && _.set(field, 'date', moment().format());
      return _.set(acc, k, field);
    }, {});

    if (this.validate()) {
      item.styleId && this.props.sampleApprovalPropsActions.editSampleApproval(item.styleId, {garment});
    }
  }

  close () {
    this.props.closeModal();
  }

  render () {
    let {item, errors} = this.state;
    let garment = item.garment || {};
    let fields = ['fit', 'pp', 'top'];
    return (
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <form>
            <div className="modal-header">
              <a className="close" onClick={() => this.close()}><span aria-hidden="true">&times;</span></a>
              <h4 className="modal-title">{I18n.t('PageSampleApproval.GARMENT')}</h4>
            </div>
            <div className="modal-body">
              {fields.map((k, idx) => (
                <div key={idx} className="form-group">
                  <div className="col-md-2 col-xs-12 text">
                    <p>{_.upperCase(k)}</p>
                  </div>
                  <div className="col-md-5 col-xs-12 value">
                    <select className="form-control" value={_.get(garment, `${k}.status`) || ''} onChange={evt => this.onChange(`garment.${k}.status`, evt.target.value)} >
                      <option value="" />
                      {Object.keys(sampleApprovalStatus).map((k, idx) => {
                        return (<option key={idx} value={k}>{_.capitalize(sampleApprovalStatus[k])}</option>);
                      })}
                    </select>
                    {errors[k] && <div className="alert-warning">{errors[k]}</div>}
                  </div>

                  <div className="col-md-5 col-xs-12 value">
                    <DateTimeField timeFormat={false} closeOnSelect dateFormat="MMM DD, YYYY"
                      value={moment(_.get(garment, `${k}.date`) || {})}
                      onChange={date => this.onChange(`garment.${k}.date`, date.format())} />
                  </div>
                  {[sampleApprovalStatus.REJECTED, sampleApprovalStatus.RESUBMITTED, sampleApprovalStatus.APPROVED].indexOf(_.get(garment, `${k}.status`)) !== -1 && (
                  <div className="col-md-10 col-md-offset-2 col-xs-12 wrap-textarea">
                    <textarea rows="4" cols="50" type="text" className="form-control" value={_.get(garment, `${k}.remark`) || ''} onChange={evt => this.onChange(`garment.${k}.remark`, evt.target.value)} placeholder="Remark" />
                  </div>
                  )}
                </div>
              ))}

            </div>
            <div className="modal-footer">
              <button type="button" className="bt-black" onClick={() => this.close()}>{I18n.t('Btn.CANCEL')}</button>
              <button type="button" className="bt-orange mg-l-5" onClick={() => this.save()}>{I18n.t('Btn.SAVE')}</button>
            </div>

          </form>
        </div>
      </div>
    );
  }
}

function mapStateToProps (state, ownProps) {
  return {
    authState: state.authState,
    sampleApprovalState: state.sampleApprovalState
  };
}

function mapDispatchToProps (dispatch) {
  return {
    sampleApprovalPropsActions: bindActionCreators(sampleApprovalActions, dispatch)
  };
}

GarmentStatusEdit.propTypes = {
  authState: PropTypes.object.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(GarmentStatusEdit);
