import { createSelector } from 'reselect';
import moment from 'moment';
import _ from 'lodash';
import * as R from "ramda";
/**
 * Get a list of filters that can be use to filter pkl ship
 */
export const getListFilters = () =>  [
    {field: 'SelectFilter', name: 'PageShiping.SelectFilter', type: 'text'},
    {field: 'packingType', name: 'PageShiping.PackingType', type: 'text'},
    {field: 'customer', name: 'PageShiping.Customer', type: 'text'},
    {field: 'customerPO', name: 'PageShiping.CustomerPO', type: 'text'},
    {field: 'exFactory', name: 'PageShiping.ExFactory', type: 'dateTo'},
    {field: 'invNo', name: 'PageShiping.INVNo', type: 'text'},
    {field: 'invDate', name: 'PageShiping.INVDate', type: 'dateTo'},
    
]

/**
 * Get selected filter
 */
export const getSelectedFilter = createSelector(
    [getListFilters, ({ showPklShipList }) => showPklShipList.filter],
    (filters, selectedFilter) => {
        if (!selectedFilter) return filters[0].field;
        return selectedFilter;
    }
)

export const getSearchValue = ({ showPklShipList }) => showPklShipList.searchValue;

const genDisplayPklShipViewModel = (isDelete, model, checked) => {
    if (!model) return {};
    return new Proxy(model, {
        get(obj, prop) {
            obj.isDelete = isDelete;
            const qty = obj['qty'] * 1;  // Convert to number
            switch (prop) {
                case 'checked':
                    return checked;
                case 'checkable':
                    return !!obj['shipOut'];
                case 'qty':
                    return (qty > 0) ? qty : '-';  // Convert to number
                case 'exFactory':
                    return moment(obj['exFactory']).format('MMM DD, YYYY');
                case 'notifyDate':
                    return obj['notifyDate'] ? moment(obj['notifyDate']).format('MMM DD, YYYY') : '-';
                case 'invDate':
                    return obj['invDate'] ? moment(obj['invDate']).format('MMM DD, YYYY') : '-';
                case 'customerPO':
                    return !_.isEmpty(obj['order']) ? obj['order'].length : '-';
                case 'raw':
                    return obj;
                default:
                    return obj[prop];
            }
        },
    });
};

export const getCheckedList = ({ showPklShipList }) => showPklShipList.checkedList;

const isSuperAdmin = ({ authState }) => {
    const { auth } = authState;
    const isSupperAdmin = R.path(['isSupperAdmin'], auth);
    return isSupperAdmin;
}

const getRoleModule = ({ authState }) => {
    const { auth } = authState;
    const modules = R.pathOr([], ['role', 'modules'], auth);
    const mod = modules.find(it => R.path(['moduleId', 'key'], it) === 'shipping');
    return mod;
}

export const canDeletePKL = createSelector(
    [isSuperAdmin, getRoleModule],
    (admin, mod) => {

        if (admin) return true;
        if (!mod) return false;
        const addPerm = R.path(['actions', 'delete'], mod);
        return addPerm || false;
    }
);

export const getDisplayList = createSelector([
    ({ showPklShipList }) => showPklShipList.displayList,
    getCheckedList,
    canDeletePKL,
], (displayList, checkedList, canDeletePKL) => {
    return displayList.map(it => genDisplayPklShipViewModel(canDeletePKL,it, checkedList.findIndex(item => item._id === it._id) >= 0))
});

export const getCurrentPage = ({ showPklShipList }) => showPklShipList.currentPage;

export const getTotalPages = ({ showPklShipList }) => showPklShipList.totalPages;

export const canChecked = ({ showPklShipList }) => id => {
    const item = showPklShipList.displayList && showPklShipList.displayList.find(item => item._id == id)
    if(item.invNo) 
        return true;
    return false
}

export const getCheckedListIds = ({ showPklShipList }) => {
    if(showPklShipList.checkedList) {
        return showPklShipList.checkedList.map(item => item._id)
    }
}

export const canCreateShipment = ({showPklShipList}) => _.isEmpty(showPklShipList.checkedList);

export const getSearched = ({showPklShipList}) => showPklShipList.searched;

export const getSearchQuery = (searched, option, selectedFilter, searchValue) => {
    if(!searched) {
        return option;
    }
    if(selectedFilter && searchValue) {
        let op = option;
        let key = selectedFilter;
        if(!searchValue.value) return op;
        if(selectedFilter == 'exFactory' || selectedFilter == 'invDate') {
            let begin = moment(searchValue.from).format('YYYY-MM-DD');
            let end = moment(searchValue.to).format('YYYY-MM-DD');
            let date = `${begin}:${end}`;
            op[key] = date
        } else {
            op[key] = searchValue.value
        }
        return op;
    }   
}