
export default {
  appState: {
    loading: false,
    page: {},
    pageList: []
  },
  assignmentState: {
    items: [],
    ordersC: [],
    listOrder: [],
    detail: {},
    reload: true
  },
  authState: {
  },
  contractorState: {
    items: [],
    detail: {},
    reload: true
  },
  customerState: {
    items: [],
    detail: {},
    reload: true
  },
  cutTicketState: {
    items: [],
    detail: {},
    reload: true,
    itemYield: {}
  },
  damagedGarmentState: {
    items: [],
    detail: {},
    reload: true
  },
  damagedMaterialState: {
    items: [],
    count: 0
  },
  dashboardState: {
    overview: {},
    orders: [],
    filters: [],
    prods: {},
    reload: true,
    loading: false,
    total: 0
  },
  departmentState: {
    items: [],
    reload: true
  },
  materialState: {
    items: [],
    detail: {},
    reload: true
  },
  orderState: {
    items: [],
    detail: {},
    reload: true,
    reloadDetail: true,
    filterText: '',
    isSearch: false,
    loading: false 
  },
  organizationState: {
    items: [],
    reload: true
  },
  roleState: {
    items: [],
    detail: {},
    modules: [],
    reload: true
  },
  packingStandardState: {
    items: [],
    detail: {},
    reload: true,
    properties: {}
  },
  packingState: {
    items: [],
    detail: {},
    reload: true
  },
  propertyState: {
    items: [],
    detail: {},
    reload: true,
    properties: {}
  },
  fabricState: {
    items: [],
    detail: {},
    reload: true,
    properties: {}
  },
  trimState: {
    items: [],
    detail: {},
    reload: true,
    properties: {}
  },
  purchaseOrderState: {
    items: [],
    detail: {},
    reload: true
  },
  purchasePlanState: {
    planByStyle: {},
    reload: true
  },
  purchaseState: {
    items: [],
    detail: {},
    reload: true
  },
  sampleApprovalState: {
    detail: {},
    reload: true,
    items: [],
    total: 0
  },
  standardState: {
    detail: {},
    reload: true
  },
  warehouseState: {
    items: [],
    count: 0,
    detail: {},
    reload: true
  },
  stockInState: {
    supplier: {
      items: [],
      detail: {},
      history: [],
      reload: true
    },
    contractor: {
      items: [],
      detail: {},
      reload: true
    }
  },
  acounting: {
    payInfo: {
      items: [],
      detail: {},
      reload: true
    }
  },
  stockOutState: {
    items: [],
    detail: {},
    reload: true,
    materials: [],
    count: 0
  },
  storeState: {
    items: [],
    detail: {},
    reload: true
  },
  styleState: {
    items: [],
    detail: {},
    reload: true
  },
  supplierState: {
    items: [],
    detail: {},
    reload: true
  },
  systemSettingsState: {
    items: {},
    notifications: {},
    reload: true,
  },
  userState: {
    items: [],
    detail: {},
    reload: true,
  },
  productionOrderState: {
    items: [],
    detail: {},
    reload: true,
    filterText: '',
    isSearch: false,
    loading: false
  },
  qaOrderState: {
    items: [],
    detail: {},
    reload: true,
    filterText: '',
    isSearch: false,
    loading: false
  },
  productionProgressState: {
    items: [],
    detail: {},
    reload: true,
  },
  productionActualYieldState: {
    items: [],
    detail: {},
    reload: true,
  },
  shipping: {
    items: [],
    detail: {},
    reload: true,
    str: '',
    count: 0,
    shipinItems: [],
    shipinCount: 0,
    historyItems: [],
    historyCount: 0,
  },
  ShipOutState:{
    items: []
  }
};
