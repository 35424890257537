import Base from './base';

class PackingStandard extends Base {
  constructor () {
    super();

    this.getListPackingStandardDAL = this.getListPackingStandardDAL.bind(this);
    this.deleteItemDAL = this.deleteItemDAL.bind(this);
    this.addItemDAL = this.addItemDAL.bind(this);
  }

  getListPackingStandardDAL (query) {

    return super.execute(this.Urls.packingStandard.list, this.ApiMethod.get, query);
  }

  deleteItemDAL (id) {

    return super.execute(this.Urls.packingStandard.delete + '/' + id, this.ApiMethod.delete, null);
  }

  addItemDAL (body) {

    return super.execute(this.Urls.packingStandard.create, this.ApiMethod.post, body);
  }

  updateItemDAL = (id, data) => {
    return super.execute(this.Urls.packingStandard.update + '/' + id, this.ApiMethod.put, data);
  }
}

export default new PackingStandard();
