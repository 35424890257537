import Base from './base';

class Department extends Base {
  constructor () {
    super();

    this.getListDepartmentDAL = this.getListDepartmentDAL.bind(this);
    this.editDepartmentDAL = this.editDepartmentDAL.bind(this);
    this.addDepartmentDAL = this.addDepartmentDAL.bind(this);
    this.deleteDepartmentDAL = this.deleteDepartmentDAL.bind(this);
  }

  getListDepartmentDAL (query) {
    return super.execute(this.Urls.department.list, this.ApiMethod.get, query)
        ;
  }

  editDepartmentDAL (id, body) {
    return super.execute(this.Urls.department.update + '/' + id, this.ApiMethod.put, body)
        ;
  }

  addDepartmentDAL (body) {
    return super.execute(this.Urls.department.create, this.ApiMethod.post, body)
        ;
  }

  deleteDepartmentDAL (id) {
    return super.execute(this.Urls.department.delete + '/' + id, this.ApiMethod.delete, null)
        ;
  }

}

export default new Department();
