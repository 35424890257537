import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import BaseComponent from '../base/BaseComponent';
import TabCustomers from './TabCustomers';
import TabStores from './TabStores';
import TabSuppliers from './TabSuppliers';
import TabContractors from './TabContractors';
import I18n from 'i18n-js';
import * as utils from "../../functions/utils";

class PageCrm extends BaseComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  render() {
    let roles = utils.getRoleByAuthState(this.props.authState, 'crm');
    return (
      <div>
        <div id="content" className="wrapper-crm">
          <div className="global-table-tab pd-5 wrap-position-filter">
            <ul className="nav nav-tabs wrap-tab" role="tablist">
              <li role="presentation" className="active">
                <a href="#crm-customers" aria-controls="crm-customers"
                   role="tab" data-toggle="tab">{I18n.t('PageCrm.Customers')}</a>
                <span className="line"/>
              </li>
              <li role="presentation">
                <a href="#crm-stores" aria-controls="crm-stores" role="tab" data-toggle="tab">
                  {I18n.t('PageCrm.Stores')}
                </a>
                <span className="line"/>
              </li>
              <li role="presentation"><a href="#crm-suppliers" aria-controls="crm-suppliers" role="tab"
                                         data-toggle="tab">{I18n.t('PageCrm.Suppliers')}</a><span className="line"/></li>
              <li role="presentation"><a href="#crm-contractors" aria-controls="crm-contractors" role="tab"
                                         data-toggle="tab">{I18n.t('PageCrm.Contractors')}</a></li>
            </ul>

            <div className="tab-content">
              <TabCustomers {...this.props} roles={roles}/>
              <TabStores {...this.props} roles={roles}/>
              <TabSuppliers {...this.props} roles={roles}/>
              <TabContractors {...this.props} roles={roles}/>
            </div>
          </div>
        </div>
      </div>


    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    appState: state.appState,
    authState: state.authState
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

PageCrm.propTypes = {
  authState: PropTypes.object.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(PageCrm);
