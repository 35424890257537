import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import BaseComponent from '../../base/BaseComponent';
import warehouseActions from '../../../actions/warehouseActions';
import TableList from '../../common/datatables/TableList';
import SearchBar from '../partial/SearchBar';
import Reserve from '../modal/reserve';
import OutboundList from '../modal/outbound';
import _ from 'lodash';
import I18n from 'i18n-js';
import {SEARCH_BAR_EVENT, SEARCH_KEY, SEARCH_LIST} from "../../../constants/constants";
var FileSaver = require('file-saver');

import icExportIcon from '../../../stylesheets/images/ic-export.svg';
import CustomPagination from '../../common/datatables/CustomPagination';

class TabStock extends BaseComponent {
  constructor (props, context) {
    super(props, context);

    this.state = {
      pageSize: 10,
      pageNumber: 1,
      columns: [
        {name: I18n.t('PageWareHouse.Material'), value: 'material.name'},
        {name: I18n.t('PageWareHouse.Content'), value: 'material.data.content'},
        {name: I18n.t('PageWareHouse.Color'), value: 'color'},
        {name: I18n.t('PageWareHouse.Size'), value: 'size'},
        {name: I18n.t('PageWareHouse.Quantity'), value: 'quantity', options: {clsTh: 'center', clsTd: 'center'}},
        {name: I18n.t('PageWareHouse.Available'), value: 'available', options: {clsTh: 'center', clsTd: 'center'}},
        {name: I18n.t('PageWareHouse.Reserve'), value: 'reserve', options: { style:{cursor:'pointer'}, onClick: this.showReserve, clsTh: 'center', clsTd: 'center'}},
        {name: I18n.t('PageWareHouse.Outbounded'), value: 'outbounded', options: { style:{cursor:'pointer'}, onClick: this.showOutbounded, clsTh: 'center', clsTd: 'center'}},
        {name: I18n.t('PageWareHouse.Unit'), value: 'material.data.unit', options: {clsTh: 'center', clsTd: 'center'}}
      ],
      actions: [],
      warehouses: [],
      total: 0,
      search: {key: SEARCH_KEY.MATERIAL, value: ""},
      tabCurrent: props.tabCurrent,
      boolReturn: false
    };
  }

  componentWillMount() {
    let {pageSize, pageNumber} = this.state;
    this.getListItem({}, pageSize, pageNumber);
  }

  componentWillReceiveProps(newProps) {
    let {warehouses, searchValue, tabCurrent, pageSize, pageNumber, boolReturn} = this.state;
    !boolReturn && !_.isEqual(warehouses, newProps.warehouseState.items) && this.setState({
      warehouses: _.cloneDeep(newProps.warehouseState.items) || [],
      total: newProps.warehouseState.count || 0
    });

    boolReturn && this.setState({
      boolReturn: false
    });
    if (!_.isEqual(searchValue, newProps.searchValue)) {
      this.setState({searchValue: _.cloneDeep(newProps.searchValue)});
    }
    tabCurrent != newProps.tabCurrent && this.setState({tabCurrent: newProps.tabCurrent})
    tabCurrent != newProps.tabCurrent && newProps.tabCurrent == '' && this.onSearch()
  }

  getListItem = (query = {}, pageSize, pageNumber) => {
    let _query = _.assign({pageSize: pageSize, pageNumber: pageNumber, populate: 'material'}, query);
    this.props.warehouseActions.getListItem(_query);
  }

  selectPage = (page) => {
    this.setState({pageNumber: page});
    this.search(this.state.search, page, this.state.pageSize);
  };

  onChangeSearch(property, value) {
    switch(property) {
      case SEARCH_BAR_EVENT.FILTER: {
        this.setState({search: {key: value, value: ""}});
        break;
      }
      case SEARCH_BAR_EVENT.SEARCH: {
        this.setState({search: {...this.state.search, value: value}});
        break;
      }
    }
  }

  pushReturn = (id, value) => {
    let {warehouses} = this.state;
    const idx = _.findIndex(warehouses, i => i._id == id)
    if(idx >= 0) {
      warehouses[idx].returns = [...(warehouses[idx].returns || []), value]
      this.setState({warehouses, boolReturn: true})
    }
  }

  showReserve = stock => {
    let { openModal, closeModal } = this.props.modal;
    let modal = (<Reserve closeModal={closeModal} stock={stock} pushReturn={this.pushReturn} />);

    openModal(modal);
  }

  showOutbounded = stock => {
    let { openModal, closeModal } = this.props.modal;
    let modal = (<OutboundList closeModal={closeModal} stock={stock} pushReturn={this.pushReturn} />);

    openModal(modal);
  }

  onSearch(){
    const {search, pageNumber, pageSize} = this.state;
    this.search(search, pageNumber, pageSize);
  }

  search(search, pageNumber, pageSize) {
    let options = {};
    if(search && search.key && search.value) {
      switch(search.key) {
        case SEARCH_KEY.MATERIAL: {
          options = {
            projection: "_id",
            query: JSON.stringify({name: {$regex: '.*' + search.value + '.*', $options: 'i'}})
          };
          this.props.warehouseActions.searchByMaterial(options, pageNumber, pageSize).then(res => {
            if(res && res.pageNumber && res.pageNumber == 1) {
              this.setState({pageNumber: 1});
            }
          });
          break;
        }
        case SEARCH_KEY.CONTENT: {
          options = {
            projection: "_id",
            query: encodeURIComponent(JSON.stringify({"data.content": {$regex: '.*' + search.value.replace(/\+/g, "\\+") + '.*', $options: 'i'}}))
          };
          this.props.warehouseActions.searchByContent(options, pageNumber, pageSize).then(res => {
            if(res && res.pageNumber && res.pageNumber == 1) {
              this.setState({pageNumber: 1});
            }
          });
          break;
        }
        case SEARCH_KEY.COLOR: {
          let options = {
            pageNumber,
            pageSize,
            populate: 'material',
            query: JSON.stringify({"color": {$regex: '.*' + search.value + '.*', $options: 'i'}})
          };
          this.props.warehouseActions.getListItem(options, pageNumber, pageSize).then(res => {
            if(res && res.success && res.data && res.data.items && !res.data.items.length && pageNumber > 1) {
              options = {
                pageNumber:1,
                pageSize,
                populate: 'material',
                query: JSON.stringify({color: {$regex: '.*' + search.value + '.*', $options: 'i'}})
              };
              this.setState({pageNumber: 1});
              this.props.warehouseActions.getListItem(options);
            }
          });
          break;
        }
        case SEARCH_KEY.SIZE: {
          let options = {
            pageNumber,
            pageSize,
            populate: 'material',
            query: JSON.stringify({"size": {$regex: '.*' + search.value + '.*', $options: 'i'}})
          };
          this.props.warehouseActions.getListItem(options).then(res => {
            if(res && res.success && res.data && res.data.items && !res.data.items.length && pageNumber > 1) {
              options = {
                pageNumber:1,
                pageSize,
                populate: 'material',
                query: JSON.stringify({size: {$regex: '.*' + search.value + '.*', $options: 'i'}})
              };
              this.setState({pageNumber: 1});
              this.props.warehouseActions.getListItem(options);
            }
          });
          break;
        }
        case SEARCH_KEY.CUSTOMER_PO: {
          let options = {
            pageNumber,
            pageSize,
            populate: 'material',
            query: JSON.stringify({"customerPO": {$regex: '.*' + search.value + '.*', $options: 'i'}})
          };
          this.props.warehouseActions.getListItem(options).then(res => {
            if(res && res.success && res.data && res.data.items && !res.data.items.length && pageNumber > 1) {
              options = {
                pageNumber:1,
                pageSize,
                populate: 'material',
                query: JSON.stringify({size: {$regex: '.*' + search.value + '.*', $options: 'i'}})
              };
              this.setState({pageNumber: 1});
              this.props.warehouseActions.getListItem(options);
            }
          });
          break;
        }
      }
    }
    if(!search.value) {
      let options = {
        pageNumber,
        pageSize,
        populate: 'material',
      };
      this.props.warehouseActions.getListItem(options).then(res => {
        if(res && res.success && res.data && res.data.items && !res.data.items.length && pageNumber > 1) {
          options = {
            pageNumber:1,
            pageSize,
            populate: 'material',
          };
          this.setState({pageNumber: 1});
          this.props.warehouseActions.getListItem(options);
        }
      });
    }
  }

  onExport = () => {
    const {search} = this.state;
    let exportLink = this.props.warehouseActions.exportReserve(search);
    FileSaver.saveAs(exportLink, "reserve.xlsx");
  }

  render () {
    let {warehouses, total, columns, actions, pageNumber, pageSize, search} = this.state;
    const items = warehouses.map(vl => ({...vl, reserve: vl.quantity - vl.available}))

    let totalPage = _.ceil(total / pageSize);
    return (
      <div role="tabpanel" className="tab-pane active" id="stock">
        <div className="search-wrapper">
          <SearchBar
            name="Stock In"
            fields={SEARCH_LIST.WAREHOUSE_STOCK}
            onChangeSearch={(type, data) => this.onChangeSearch(type, data)}
            onSearch={() => this.onSearch()}
          />
          <span className="export cursor-pointer" onClick={this.onExport} style={{display: 'inline-block', float: 'right', marginTop: 15, fontSize: 14}}>
            Export <img src={icExportIcon} alt="" style={{position: 'relative', top: 5}}/>
          </span>
          
        </div>
        <TableList {...this.props} items={items} columns={columns || []} actions={actions || []}
                   options={{clsTbl: 'global-table box-shadow-none'}} widthAction="w-70"/>
        <CustomPagination items={totalPage} activePage={pageNumber} onSelect={this.selectPage} />
      </div>
    );
  }
}

function mapStateToProps (state, ownProps) {
  return {
    authState: state.authState,
    warehouseState: state.warehouseState
  };
}

function mapDispatchToProps (dispatch) {
  return {
    warehouseActions: bindActionCreators(warehouseActions, dispatch)
  };
}

TabStock.propTypes = {
  authState: PropTypes.object.isRequired,
  warehouseActions: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(TabStock);
