import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import departmentActions from '../../../actions/departmentActions';
import organizationActions from '../../../actions/organizationActions';
import I18n from 'i18n-js';
import {toastr} from 'react-redux-toastr'
import organizationServices from "../../../services/organizationServices";

class DepartmentTabEdit extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      item: {},
      organization: [],
      errors: {}
    };

  }

  componentWillMount() {
    const data = _.get(this.props, 'options.data', {});
    this.setState({ item: _.cloneDeep(data) });
    this.getListOrganization();
  }

  getListOrganization() {
    organizationServices.getListOrganization({}).then(res => {
      let arrayOrganization = res.data.items || [];
      this.setState({
        organization: arrayOrganization || [],
        total: res.data.count || 0
      });
    });
  }

  componentWillReceiveProps(newProps) {
    let { organization } = this.state;
    !_.isEqual(organization, newProps.organizationState.items) && this.setState({
      organization: _.cloneDeep(newProps.organizationState.items) || [],
      total: newProps.organizationState.count || 0
    });
  }

  onChange(property, value) {
    let { item } = this.state;
    this.setState({ item: _.set(item, property, value) });
    this.validateAfterChange(property, value);
  }

  close() {
    this.props.closeModal();
  }

  validateAfterChange(property, value) {
    let { errors } = this.state;
    errors = {};
    switch (property) {
      case 'organizationId': {
        if (_.isEmpty(value)) {
          errors['organization'] = 'Please select organization'
        }
        this.setState({ errors });
        break;
      }
      case 'name': {
        if (_.isEmpty(value)) {
          errors['name'] = 'Please enter name'
        }
        this.setState({ errors });
        break;
      }
      default:
    }
  }

  validateBeforeSave() {
    let { item, errors } = this.state;
    errors = {};
    if (_.isEmpty(item.organizationId)) {
      errors['organization'] = 'Please select organization'
      this.setState({ errors });
      return false;
    }
    if (_.isEmpty(item.name)) {
      errors['name'] = 'Please enter name';
      this.setState({ errors });
      return false;
    }
    this.setState({ errors });
    return true;
  }

  save() {
    if (!this.validateBeforeSave())
      return;
    let { item } = this.state;
    item._id && this.props.departmentPropsActions.editDepartment(item._id, item).then(rs => {
      if(rs.statusCode == 500) {
        toastr.error(I18n.t('Notify.Error'), I18n.t('ErrorMessages.DepartmentExist'))
      } else if(!rs.success) {
        toastr.error(I18n.t('Notify.Error'), rs.message)
      }
    });
  }

  render() {
    let { item, organization, errors } = this.state;
    return (
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <form>
            <div className="modal-header">
              <a className="close" onClick={() => this.close()}><span aria-hidden="true">&times;</span></a>
              <h4 className="modal-title" id="myModalLabel">{I18n.t('PageOrganization.EDITDEPARTMENT')}</h4>
            </div>

            <div className="modal-body">
              <div className="form-group">
                <div className="col-md-4 col-xs-12 text">
                  <p>{I18n.t('PageOrganization.Organization')}</p> <span className="color-red"> *</span>
                </div>
                <div className="col-md-8 col-xs-12 value">
                  <select className="form-control" onChange={evt => this.onChange('organizationId', evt.target.value)} >
                    <option value="">{(item.organizationId.name ? item.organizationId.name : "-- Select Organization --")}</option>
                    {organization.map((i, idx) => (<option key={idx} value={i._id}>{i.name}</option> ))}
                  </select>
                  {errors['organization'] && <div className="alert-warning">{errors['organization']}</div>}
                </div>
              </div>

              <div className="form-group">
                <div className="col-md-4 col-xs-12 text">
                  <p>{I18n.t('PageOrganization.Name')}</p> <span className="color-red"> *</span>
                </div>
                <div className="col-md-8 col-xs-12 value">
                  <input type="text" value={item.name || ''}
                         onChange={evt => this.onChange('name', evt.target.value)} className="form-control" />
                  {errors['name'] && <div className="alert-warning">{errors['name']}</div>}
                </div>
              </div>

              <div className="form-group">
                <div className="col-md-4 col-xs-12 text">
                  <p>{I18n.t('PageOrganization.Description')}</p>
                </div>
                <div className="col-md-8 col-xs-12 value">
                  <textarea rows="4" cols="50" className="form-control"
                            value={item.description || ''}
                            onChange={evt => this.onChange('description', evt.target.value)} />
                </div>
              </div>

            </div>

            <div className="modal-footer">
              <button type="button" className="bt-black" onClick={() => this.close()}>{I18n.t('Btn.CANCEL')}</button>
              <button type="button" className="bt-orange mg-l-5" onClick={() => this.save()}>{I18n.t('Btn.SAVE')}</button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    appState: state.appState,
    authState: state.authState,
    departmentState: state.departmentState,
    organizationState: state.organizationState
  };
}

function mapDispatchToProps(dispatch) {
  return {
    departmentPropsActions: bindActionCreators(departmentActions, dispatch),
    organizationPropsActions: bindActionCreators(organizationActions, dispatch)
  };
}

DepartmentTabEdit.propTypes = {
  authState: PropTypes.object.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(DepartmentTabEdit);
