import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import I18n from 'i18n-js';
import BaseComponent from '../../base/BaseComponent';
import appActions from '../../../actions/appActions';
import trimActions from '../../../actions/trimActions';
import packingStandardActions from '../../../actions/packingStandardActions';
import {pathImage} from '../../../functions/helpers';
import {toastr} from 'react-redux-toastr';
import {PACKING_TYPE} from '../../../constants/constants'

import icXIcon from '../../../stylesheets/images/ic-x.svg';
import addNewIcon from '../../../stylesheets/images/add-new.svg';
import icXHoverIcon from '../../../stylesheets/images/ic-x-hover.svg';
import dragPhotoIcon from '../../../stylesheets/images/drag-photo.svg';

class PackingStandardAdd extends BaseComponent {
	constructor(props, context) {
		super(props, context);
		this.save = this.save.bind(this);
		this.state = {
			materials: [],
			packingStandard: this.props.appState.page.isEdit ?_.get(this.props, 'appState.page.data') : {
				name: '',
				trimDescription: '',
				trims: [{
			    trim: '',
			    color: '',
			    size: ''
			  }],
				foldingWay: [{
			    photo: '',
				note: '',
				file: {}
			  }],
			  hangtag: [{
			    photo: '',
				note: '',
				file: {}
			  }],
			  shippingMark: [{
			    photo: '',
				note:'',
				file: {}
			  }],
			  type: 'GOHH'
			},
			errors: {}
		};
	}

	componentWillMount() {
		this.props.trimPropsActions.getListTrim();
		this.getListItem();
	}

	componentWillReceiveProps(newProps) {
		let { items, materials } = _.cloneDeep(this.state);
		!_.isEqual(materials, newProps.trimState.items) && this.setState({ materials: _.cloneDeep(newProps.trimState.items) });
		!_.isEqual(items, newProps.packingStandardState.items) && this.setState({ items: _.cloneDeep(newProps.packingStandardState.items) });
		
		if(materials && materials.length && this.state.materials.length) {
			let { packingStandard } = _.cloneDeep(this.state);
			_.each(packingStandard.trims, (trim) => {
				let selectedMaterial = _.find(materials, item => item._id === trim.trim._id);
				if (selectedMaterial) {
					trim.selectedMaterial = selectedMaterial;
				}
			})
			this.setState({ packingStandard });
		}
	
	}

	addItem(type) {
		let { packingStandard } = _.cloneDeep(this.state);
		switch (type) {
			case 'trims':
				packingStandard[type].push({ trim: '', color: '', size: '' });
				break;
			case 'foldingWay':
			case 'hangtag':
			case 'shippingMark':
				packingStandard[type].push({ photo: '', note: '' });
				break;
		}
		this.setState({ packingStandard });
	}

	removeItem(type, index) {
		let { packingStandard } = _.cloneDeep(this.state);
		packingStandard[type].splice(index, 1);
		this.setState({ packingStandard });
	}

	onChangeMaterial = (index, value) => {
		let { packingStandard } = _.cloneDeep(this.state);
		let selectedMaterial = this.state.materials.find(item => item._id === value);
		if (packingStandard.trims[index] && selectedMaterial) {
			packingStandard.trims[index].trim = value;
			packingStandard.trims[index].color = '';
			packingStandard.trims[index].size = '';
			packingStandard.trims[index].selectedMaterial = selectedMaterial;
			this.setState({ packingStandard });
			this.validateChange('trim', packingStandard);
		}
  	}

  validate() {
	let { packingStandard, errors } = _.cloneDeep(this.state);
	errors = {};
  	if(!packingStandard.name) {
  		errors.name = I18n.t('ErrorMessages.PleaseEnterBillNo');
	  }
	_.each(packingStandard.trims, (el) => {
		if(!el.trim || el.trim === '') {
			errors.trim = I18n.t('ErrorMessages.PleaseChooseTrim');
			return;
		}
	})
	this.setState({ errors });
  	return _.keys(errors).length === 0;
  }

  validateChange(property, value) {
	let { packingStandard, errors } = _.cloneDeep(this.state);
	// console.log('----', property, value, packingStandard.trims);
	delete errors[property];
	switch (property) {
		case 'name': {
			if(!value) {
				errors.name = I18n.t('ErrorMessages.PleaseEnterBillNo');
			}
			break;
		}
		case 'trim': {
			_.each(value.trims, (el) => {
				if(!el.trim || el.trim === '') {
					errors.trim = I18n.t('ErrorMessages.PleaseChooseTrim');
					return;
				}
			})
			break;
		}
	}
	this.setState({ errors });
  }

  changeValueOfArr(index, propertyParent, propertyChild, value) {
  	let { packingStandard } = _.cloneDeep(this.state);
  	if(packingStandard[propertyParent][index]) {
  		packingStandard[propertyParent][index][propertyChild] = value;
  		this.setState({ packingStandard });
  	}
  }

  changeValue(property, value) {
  	let { packingStandard } = _.cloneDeep(this.state);
  	packingStandard[property] = value;
	this.setState({ packingStandard });
	this.validateChange(property, value);
  }

	getListItem(query = {}) {
		let { pageSize, pageNumber } = _.cloneDeep(this.state);
		let _query = _.assign({
			pageSize: pageSize, 
			pageNumber: pageNumber,
			populate: JSON.stringify([
			  {
				path: 'trims.trim',
				select: 'name'
			  },
			]),
		  }, query);
		this.props.packingStandardPropsActions.getListPackingStandard(_.set(_query, 'sort', { createdAt: -1 }));
	}

	save = (packingStandard) => {
		if(packingStandard._id || packingStandard.id) {
			// let data = this.handleSave(packingStandard);
			this.props.packingStandardPropsActions.updateItem(packingStandard._id, packingStandard).then(res => {
				if(res.success) {
					toastr.success(I18n.t('Notify.Success'), 'Update Success')
					const pageData = _.assign(
						{
							isEdit: false,
							isAdd: false,
							loadMore : true
						}
				  	);
					this.props.appPropsActions.setPageCurrent(pageData)
				}
			});
		} else if(this.validate()) {
			let data = this.handleSave(packingStandard);
			this.props.packingStandardPropsActions.addItem(data).then(res => {
				if(res.success) {
					toastr.success(I18n.t('Notify.Success'), 'Add Success')
					const pageData = _.assign(
						{
							isEdit: false,
							isAdd: false,
							loadMore : true
						}
				  	);
					this.props.appPropsActions.setPageCurrent(pageData)
				}
			})
		}
	}
	
	handleSave(data) {
		data.trims = _.filter(data.trims, (el) => el.trim);
		data.foldingWay = _.filter(data.foldingWay, (el) => el.photo || el.note || el.file);
		data.hangtag = _.filter(data.hangtag, (el) => el.photo || el.note || el.file);
		data.shippingMark = _.filter(data.shippingMark, (el) => el.photo || el.note || el.file);
		return data;
	}

	addImage = (e, property, index) => {
		e.preventDefault();
		const file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
		if (!file)
			return;

		var FileSize = file.size / 1024 / 1024; // in MB
		if (FileSize > 2) {
			toastr.error('Error', I18n.t('ErrorMessages.FileSizeExceeds2MB'))
			return;
		}

		let { packingStandard } = _.cloneDeep(this.state);
		const readerBase64 = new FileReader();
		readerBase64.onload = eventBase64 => {
			packingStandard[property][index].file = {file: file, url: eventBase64.target.result, filename: file.name, show: true};
			this.setState({packingStandard});
		};
		readerBase64.readAsDataURL(file);
	}
	
	removeImage = (i, property) => {
		let { packingStandard } = _.cloneDeep(this.state);
		packingStandard[property][i].photo = '';
		packingStandard[property][i].file = {};
		this.setState({packingStandard});
	}

	render() {
		let { errors, packingStandard, materials } = this.state;
		return (
			<div id="content">
				<div id="packing-standard-add" className="pd-5">
					<div className="wrap-title pd-0 bd-solid-bottom-2 float-left inline-block w-full">
						<div className="col-md-4 col-sm-6 col-xs-12 input" >
							<ul className="list-inline nav">
								<li>
									<span style={{marginLeft: '-10px'}}> {I18n.t('PageStandard.PackingName')}</span> <span className="color-red"> *</span>
								</li>
								<li>
									<input onChange={(evt) => { this.changeValue('name', evt.target.value); }} type="text" placeholder="" value={packingStandard.name} className="form-control" />
									{errors['name'] && <div className="alert-warning">{errors['name']}</div>}
								</li>
							</ul>
						</div>
						<div className="col-md-4 col-sm-6 col-xs-12" id="radio-group-inline">
							<ul className="nav nav-tabs wrap-list-radio mg-top-5" role="tablist">
								<li role="presentation" className={packingStandard.type === PACKING_TYPE.GOHH ? 'active' : ''}>
								<a role="tab">
									<input type="radio" id="stock" checked={packingStandard.type === PACKING_TYPE.GOHH} name="stock"
										onChange={() => this.changeValue('type', PACKING_TYPE.GOHH)} />
									<label>{I18n.t('PageProduction.GOHH')}</label>
									<div className="check">
									<div className="inside" />
									</div>
								</a>
								</li>
								<li role="presentation" className={packingStandard.type === PACKING_TYPE.CARTON ? 'active' : ''}>
								<a role="tab">
									<input checked={packingStandard.type === PACKING_TYPE.CARTON} type="radio" id={PACKING_TYPE.CARTON} name={PACKING_TYPE.CARTON}
										onChange={() => this.changeValue('type', PACKING_TYPE.CARTON)} />
									<label>{I18n.t('PageProduction.Carton')}</label>
									<div className="check">
									<div className="inside" />
									</div>
								</a>
								</li>
							</ul>
						</div>
						<div className="float-right cursor-pointer">
							<span onClick={() => this.save(packingStandard)} className="color-hv-green mg-top-15 mg-right-15 inline-block size-16 cursor-point"><i className="fa fa-floppy-o" aria-hidden="true" /> {I18n.t('Btn.SAVE')}</span>
						</div>
					</div>

					<div className="wrap-content">

						<div id="add-standard-2">
							<div id="add-standard-1">
								<div id="add-standard-col-1">
									<div className="bd-dashed-bottom inline-block pd-bottom-20 w-full">

										<table className="global-table box-shadow-none w-full">
											<tbody>
												<tr className="bg-none bd-none">
													<th className="center">{I18n.t('PageStandard.TrimName')} <span className="color-red"> *</span></th>
													<th className="center">{I18n.t('PageStandard.Color')}</th>
													<th className="center">{I18n.t('PageStandard.Size')}</th>
													<th className="center" />
												</tr>
												{packingStandard.trims.map((trim, index) => {
													return (
														<tr className="bg-none" key={`trim_${index}`}>
															<td className="select" style={{ position: 'relative' }}>
																<select className="form-control" value={trim.trim._id || trim.trim} onChange={evt => this.onChangeMaterial(index, evt.target.value)} >
																	<option value=''/>
																	{materials.map((i, idx) => (<option key={idx} value={i._id}>{i.name}</option>))}
																</select>
																{errors['trim'] && index == packingStandard.trims.length - 1 && <div className="alert-warning">{errors['trim']}</div>}
															</td>
															<td className="select">
																<select className="form-control" value={trim.color} onChange={evt => this.changeValueOfArr(index, 'trims', 'color', evt.target.value)}>
																	<option value="" />
																	{trim.selectedMaterial && trim.selectedMaterial.data
																		&& trim.selectedMaterial.data.color
																		&& trim.selectedMaterial.data.color.map((color, idx) => {
																		return (
																			<option key={idx} value={color} >
																				{color}
																			</option>
																		);
																	})
																	}
																</select>
															</td>
															<td className="select">
																<select className="form-control" value={trim.size} onChange={evt => this.changeValueOfArr(index, 'trims', 'size', evt.target.value)}>
																	<option value="" />
																	{trim.selectedMaterial && trim.selectedMaterial.data 
																		&& trim.selectedMaterial.data.size
																		&& trim.selectedMaterial.data.size.map((size, idx) => {
																		return (
																			<option key={idx} value={size} >
																				{size}
																			</option>
																		);
																	})
																	}
																</select>
															</td>
															{index === packingStandard.trims.length - 1 &&
																<td>
																	{packingStandard.trims.length > 1 && (
																		<div className="button button-close" onClick={() => { this.removeItem('trims', index); }} style={{display: 'inline-block', float: 'left', padding: '0px 15px', marginLeft: '-25px'}} >
																				<img src={icXIcon} alt="Close" title="Close" style={{marginTop: 15}} />
																		</div>
																	)}
																	<div className="button-add" style={{float: 'left'}}>
																		<span onClick={() => this.addItem('trims')} className="cursor-pointer icon-add">
																			<img className="avatar-dropdown img-responsive position-relative top-4" src={addNewIcon} />
																		</span>
																	</div>
																</td>
															}
														</tr>
													);
												})
												}
											</tbody>
										</table>
									</div>

									<div className="wrap_trim_description">
										<table className="global-table box-shadow-none bd-none w-full">
											<tbody>
												<tr className="bg-none bd-none">
												<th>
													{I18n.t('PageStandard.TrimDescription')}
												</th>
												</tr>
												<tr>
													<td>
														<textarea className="resize-vertical" rows="4" cols="50" value={packingStandard.trimDescription} onChange={(evt) => { this.changeValue('trimDescription', evt.target.value); }} placeholder="Trim Description"/>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
								<div id="add-standard-col-2">
									<table className="global-table bd-dashed-bottom box-shadow-none w-full">
										<tbody>
											<tr className="bg-none bd-none">
												<th className="pd-left-15">{I18n.t('PageStandard.HoldingWay')}</th>
												<th />
												<th />
											</tr>
											{packingStandard.foldingWay.map((item, i) => {
												return (
													<tr className="bg-none bd-none" key={i}>
														<td className="center">
															<div className="img">
																<img src={ item.file && item.file.url ? item.file.url || pathImage(item.file.path) : item.photo ? pathImage(item.photo)  : dragPhotoIcon} onDragOver={evt => evt.preventDefault()} onDrop={evt => this.addImage(evt, 'foldingWay', i)} onDrag={evt => evt.preventDefault()} onClick={() => { $("#imgfoldingWay" + i).click(); }} style={{cursor:"pointer"}} className="pd-10" />
																{(item.photo || (item.file && item.file.url)) && 
																	<span className="icon-x-hover" onClick={(evt) => { this.removeImage(i, 'foldingWay'); }} ><img src={icXHoverIcon} /></span>
																}
																<input onChange={evt => this.addImage(evt, 'foldingWay', i)} style={{display:"none"}} id={'imgfoldingWay' + i}  type="file" name="imgStyle"/>
															</div>
														</td>
														<td>
															<textarea className="resize-vertical" value={item.note} rows="4" cols="50" placeholder={I18n.t('PageStandard.NoteforFoldingway')} onChange={(evt) => { this.changeValueOfArr(i, 'foldingWay', 'note', evt.target.value); }} />
														</td>
														<td />
														<td style={{position: 'relative'}}>
															{i === packingStandard.foldingWay.length - 1 && packingStandard.foldingWay.length > 1 && (
																<div className="button button-close" onClick={() => { this.removeItem('foldingWay', i); }} style={{display: 'block', padding: '0px 15px', position: 'absolute', top: 33, left: '-35px'}} >
																		<img src={icXIcon} alt="Close" title="Close" style={{width: 15, border: 'none'}} />
																</div>
															)}
															{i === packingStandard.foldingWay.length - 1 && 
																<div className="button-add">
																	<span className="cursor-pointer icon-add"  onClick={(evt) => { this.addItem('foldingWay'); }}>
																		<img className="bd-none" src={addNewIcon} />
																	</span>
																</div>
															}
														</td>
													</tr>
												)
											})
											}
										</tbody>
									</table>

									<table className="global-table bd-dashed-bottom box-shadow-none w-full">
										<tbody>
											<tr className="bg-none bd-none">
												<th className="pd-left-15">{I18n.t('PageStandard.HangTag')}</th>
												<th />
												<th />
											</tr>
											{packingStandard.hangtag.map((item, i) => {
												return (
													<tr className="bg-none bd-none" key={i}>
														<td className="center">
														<div className="img">
															<img src={item.file && item.file.url ? item.file.url || pathImage(item.file.path) : item.photo ? pathImage(item.photo) : dragPhotoIcon} onDragOver={evt => evt.preventDefault()} onDrop={evt => this.addImage(evt, 'hangtag', i)} onDrag={evt => evt.preventDefault()} onClick={() => { $("#imgHangTag" + i).click(); }} style={{cursor:"pointer"}} className="pd-10" />
															{(item.photo || (item.file && item.file.url)) && 
																<span className="icon-x-hover" onClick={(evt) => { this.removeImage(i, 'hangtag')}} ><img src={icXHoverIcon} /></span>
															}
															<input onChange={evt => this.addImage(evt, 'hangtag', i)} style={{display:"none"}} id={'imgHangTag' + i}  type="file" name="imgStyle"/>
														</div>
														</td>
														<td>
															<textarea className="resize-vertical" value={item.note} rows="4" cols="50" placeholder={I18n.t('PageStandard.NoteforFoldingway')} onChange={(evt) => { this.changeValueOfArr(i, 'hangtag', 'note', evt.target.value); }} />
														</td>
														<td />
														<td style={{position: 'relative'}}>
															{i === packingStandard.hangtag.length - 1 && packingStandard.hangtag.length > 1 && (
																<div className="button button-close" onClick={() => { this.removeItem('hangtag', i); }} style={{display: 'block', padding: '0px 15px', position: 'absolute', top: 33, left: '-35px'}} >
																		<img src={icXIcon} alt="Close" title="Close" style={{width: 15, border: 'none'}} />
																</div>
															)}
															{i === packingStandard.hangtag.length - 1 && 
																<div className="button-add">
																	<span className="cursor-pointer icon-add"  onClick={(evt) => { this.addItem('hangtag'); }}>
																		<img className="bd-none" src={addNewIcon} />
																	</span>
																</div>
															}
														</td>
													</tr>
												)
											})
											}
										</tbody>
									</table>

									<table className="global-table bd-dashed-bottom box-shadow-none w-full">
										<tbody>
											<tr className="bg-none bd-none">
												<th className="pd-left-15">{I18n.t('PageStandard.ShippingMark')}</th>
												<th />
												<th />
											</tr>
											{packingStandard.shippingMark.map((item, i) => {
												return (
													<tr className="bg-none bd-none" key={i}>
														<td className="center">
														<div className="img">
															<img src={item.file && item.file.url ? item.file.url || pathImage(item.file.path) : item.photo ? pathImage(item.photo) : dragPhotoIcon} onDragOver={evt => evt.preventDefault()} onDrop={evt => this.addImage(evt, 'shippingMark', i)} onDrag={evt => evt.preventDefault()} onClick={() => { $("#imgShippingMark" + i).click(); }} style={{cursor:"pointer"}} className="pd-10" />
															{(item.photo || (item.file && item.file.url)) && 
																<span className="icon-x-hover" onClick={(evt) => { this.removeImage(i, 'shippingMark')}} ><img src={icXHoverIcon} /></span>
															}
															<input onChange={evt => this.addImage(evt, 'shippingMark', i)} style={{display:"none"}} id={'imgShippingMark' + i}  type="file" name="imgStyle"/>
														</div>
														</td>
														<td>
															<textarea className="resize-vertical" value={item.note} rows="4" cols="50" placeholder={I18n.t('PageStandard.NoteforFoldingway')} onChange={(evt) => { this.changeValueOfArr(i, 'shippingMark', 'note', evt.target.value); }} />
														</td>
														<td />
														<td style={{position: 'relative'}}>
															{i === packingStandard.shippingMark.length - 1 && packingStandard.shippingMark.length > 1 && (
																<div className="button button-close" onClick={() => { this.removeItem('shippingMark', i); }} style={{display: 'block', padding: '0px 15px', position: 'absolute', top: 33, left: '-35px'}} >
																		<img src={icXIcon} alt="Close" title="Close" style={{width: 15, border: 'none'}} />
																</div>
															)}
															{i === packingStandard.shippingMark.length - 1 && 
																<div className="button-add">
																	<span className="cursor-pointer icon-add"  onClick={(evt) => { this.addItem('shippingMark'); }}>
																		<img className="bd-none" src={addNewIcon} />
																	</span>
																</div>
															}
														</td>
													</tr>
												)
											})
											}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>

				</div>
			</div>

		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		...ownProps,
		authState: state.authState,
		trimState: state.trimState,
		packingStandardState: state.packingStandardState
	};
}

function mapDispatchToProps(dispatch) {
	return {
		trimPropsActions: bindActionCreators(trimActions, dispatch),
		packingStandardPropsActions: bindActionCreators(packingStandardActions, dispatch),
		appPropsActions: bindActionCreators(appActions, dispatch),
		dispatch
	};
}

PackingStandardAdd.propTypes = {
	authState: PropTypes.object.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(PackingStandardAdd);
