import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BaseComponent from '../../base/BaseComponent';
import stockInActions from '../../../actions/stockInActions';
import purchaseOrderActions from '../../../actions/purchaseOrderActions';
const DateTimeField = require('react-datetime');
import _ from 'lodash';
import moment from 'moment';
import I18n from 'i18n-js';
import TextNumberInput from "../../common/fields/TextNumberInput";
import {toastr} from "react-redux-toastr";

class StockInSupplierAdd extends BaseComponent {
  constructor (props, context) {
    super(props, context);

    this.state = {
      item: {},
      order: {},
      purchase: {},
      purchases: [],
      materials: [],
      checkAll: true
    };
  }

  componentWillMount () {
    const {data} = this.props;

    const item = _.pick(data, ['orderId', 'styleId', 'purchaseId']);
    const purchase = _.cloneDeep(data.purchase) || {};
    const order = _.cloneDeep(data.order) || {};
    const style = (order.styleOrders || []).find(i => i._id == item.styleId)

    this.setState({
      item: {...item, purchaseType: purchase.type || '', supplierId: purchase.supplierId,  customerPO: order.customerPO, purchaseNo: purchase.purchaseNo, style: _.get(style, 'style', '') },
      order: _.cloneDeep(data.order) || {},
      purchase: purchase,
      purchases: _.cloneDeep(data.purchases) || [],
      materials: this.getMaterials(purchase)
    });
  }

  componentWillReceiveProps (newProps) {
    let {purchases} = this.state;
    !_.isEqual(purchases, newProps.purchaseOrderState.items) && this.setState({purchases: _.cloneDeep(newProps.purchaseOrderState.items) || []});
  }

  componentWillUpdate (nextProps) {
    if (_.get(this.props.stockInState, 'supplier.reload') != _.get(nextProps.stockInState, 'supplier.reload')) {
      _.isFunction(this.props.closeFormAdd) && this.props.closeFormAdd('supplier');
    }
  }

  getMaterials (purchase) {
    let materials = _.cloneDeep(purchase.materials) || [];
    return materials.filter(m => m.needToBuy).map(m => _.assign(m, {
      checked: true,
      material: _.get(m, 'material', {})
    }));
  }

  filterAndConvertMaterials (materials) {
    let materialsChecked = _.filter(materials, i => i.checked);
    return _.map(materialsChecked, i => ({
        material: _.get(i, 'material'),
        content: i.material.data.content,
        color: i.color,
        size: i.size,
        quantity: i.needToBuy,
        actual: i.actual || 0,
        usable: i.usable || 0,
        unusable: i.actual - i.usable,
        unit: i.unit,
        roll: i.roll || 0,
        receivedDate: i.receivedDate || new Date(), 
        receivedDateFomat: new moment(i.receivedDate || new Date()).format("DD/MM/YYYY")
      }));
  }

  onChange (property, value) {
    let {item, purchases} = this.state;

    switch (property) {
      case 'styleId':{
        this.setState({
          item: _.set(_.pick(item, ['orderId', 'styleId']), property, value),
          purchases: [],
          purchase: {},
          materials: []
        });
        value && this.getListPurchase({styleId: value});
        break;
      }
      case 'purchaseId': {
        let purchase = purchases.find(i => i._id === value) || {};
        this.setState({
          item: _.set(_.pick(item, ['orderId', 'styleId']), property, value),
          purchase: purchase,
          materials: this.getMaterials(purchase)
        });
        break;
      }
      default:
        this.setState({item: _.set(item, property, value)});
    }
  }

  onCheckbox(index, value){
    let {materials, checkAll} = this.state;
    if(index != null){
      if(materials[index]) {
        materials[index].checked = value;
        const count = materials.filter(m => m.checked == true).length;
        checkAll = count == materials.length ? true: false;
        this.setState({materials, checkAll});
      }
    }else{
      materials.forEach((_, index) => materials[index].checked = value);
      this.setState({materials, checkAll: value});
    }
  }
  onChangeMaterials (index, property, value) {
    let {materials} = this.state;
    if(property && materials[index]) {
      materials[index][property] = value;
      this.setState({materials});
    }
  }

  getListPurchase (query = {}) {
    let options = {pageSize: 10, pageNumber: 1};
    this.props.purchaseOrderPropsActions.getListItem(_.assign({query, populate: 'materials.material', sort: {createdAt: -1}}, options));
  }

  save () {
    let {item, materials} = this.state;

    let data = _.cloneDeep(item);
    data.materials = this.filterAndConvertMaterials(materials);
    if(data && data.purchaseId) {
      const materials = _.get(data, "materials", []);
      if(materials.length === 0) {
        toastr.info(I18n.t('Notify.Info'), I18n.t('PagePurchase.YouMustChooseAtleastOneMaterial'));
        return;
      }
      // const error = validateActualAndUsableOfMaterial(materials);
      // if(error) {
      //   toastr.info(I18n.t('Notify.Info'), error.msg);
      //   return;
      // }
      this.props.stockInPropsActions.addItem('supplier', data);
    }


  }

  render () {
    let {item, order, purchase, materials, checkAll} = this.state;
    let styles = _.get(order, 'styleOrders', []);
    let style = _.find(styles, el => el._id === item.styleId) || {};
    let w100 = {
      width: '100%'
    };
    // console.log('-----', materials);
    return (
      <div id="content">
        <div className="wrap-filter-title w-full pd-5 inline-block ">
          <div className="bg-white w-full inline-block pd-10">

            {!_.isEmpty(order) && (
            <div className="float-left h-40 line-h-40">
              <p className="size-12 bold">{I18n.t('PageWareHouse.CustomerPO')} <span className="color-orange medium mg-left-50 size-16">{_.get(order, 'customerPO', '')}</span></p>
            </div>
            )}

            <div className="float-right list-filter">
              {!_.isEmpty(order) && (
              <div className="filter select">
                <p className="bold">{I18n.t('PageWareHouse.Style')}</p>
                <p>{style.style}</p>
                {/* <select value={item.styleId || ''} onChange={evt => this.onChange('styleId', evt.target.value)}>
                  {styles.map((i, idx) => (<option key={idx} value={i._id}>{i.style}</option>))}
                </select> */}
              </div>
              )}

              <div className="filter select">
                <p className="bold">{I18n.t('PageWareHouse.PurchaseOrderNo')}</p>
                <p>{purchase.purchaseNo}</p>
                {/* <select value={item.purchaseId || ''} onChange={evt => this.onChange('purchaseId', evt.target.value)}>
                  <option value="" />
                  {purchases.map((i, idx) => (<option key={idx} value={i._id}>{i.purchaseNo}</option>))}
                </select> */}
              </div>

              <div className="float-right add-filter-top ">
                <span className="cursor-pointer global-add" onClick={() => this.save()}><i className="fa fa-floppy-o" aria-hidden="true" /> Save</span>
              </div>
            </div>
          </div>
        </div>

        <div className="wrap-global-table">
          <table style={w100} className="global-table data-time-table warehouse">
            <tbody className="body-data-time">
              <tr>
                <th className="center style-checkbox">
                  <input type="checkbox" id="checkAll" checked={checkAll} onChange={evt => this.onCheckbox(null, !!evt.target.checked)} value />
                  <label className="position-relative top-s-10" htmlFor="checkAll"><span><span /></span></label>
                </th>
                <th className="center w-265">{I18n.t('PageWareHouse.ReceivedDate')}</th>
                <th className="center">{I18n.t('PageWareHouse.Material')}</th>
                <th className="center">{I18n.t('PageWareHouse.Content')}</th>
                <th className="center">{I18n.t('PageWareHouse.Color')}</th>
                <th className="center">{I18n.t('PageWareHouse.Size')}</th>
                <th className="center">{I18n.t('PageWareHouse.Needed')}</th>
                <th className="center">{I18n.t('PageWareHouse.Actual')}</th>
                <th className="center">{I18n.t('PageWareHouse.Usable')}</th>
                <th className="center">{I18n.t('PageWareHouse.Damaged')}</th>
                <th className="center">{I18n.t('PageWareHouse.Unit')}</th>
                <th className="center">{I18n.t('PageWareHouse.Roll')}</th>
              </tr>

              {(materials || []).map((i, idx) => (
                <tr key={idx}>
                  <td className="center style-checkbox">
                    <input type="checkbox" id={`check` + idx} checked={i.checked} onChange={evt => this.onCheckbox(idx, !!evt.target.checked)} value />
                    <label className="position-relative top-s-10" htmlFor={`check` + idx}><span><span /></span></label>
                  </td>
                  <td className="center date-pick date-time-td">
                      <DateTimeField timeFormat={false} closeOnSelect dateFormat="MMM DD, YYYY"
                      value={moment(i.receivedDate || (new Date()))}
                      onChange={date => this.onChangeMaterials(idx, 'receivedDate', date.format())} />
                  </td>
                  <td className="center">{_.get(i, 'material.name', '')}</td>
                  <td className="center">{_.get(i, 'material.data.content', '')}</td>
                  <td className="center">{i.color || ''}</td>
                  <td className="center">{i.size || ''}</td>
                  <td className="center">{i.needToBuy || 0}</td>
                  <td className="center w-100">
                    <TextNumberInput value={i.actual || ''} onChange={val => this.onChangeMaterials(idx, 'actual', val)} className="form-control center"/>
                  </td>
                  <td className="center w-100">
                    <TextNumberInput value={i.usable || ''} onChange={val => this.onChangeMaterials(idx, 'usable', val)} className="form-control center" />
                  </td>
                  <td className="center">{(i.actual - i.usable) ? (i.actual - i.usable).toFixed(2) : 0}</td>
                  <td className="center">{_.get(i, 'material.data.unit', '')}</td>
                  <td className="center w-100">
                    <TextNumberInput value={i.roll || ''} onChange={val => this.onChangeMaterials(idx, 'roll', val)} className="form-control center" />
                  </td>
                </tr>
              ))}

            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

function mapStateToProps (state, ownProps) {
  return {
    authState: state.authState,
    stockInState: state.stockInState,
    purchaseOrderState: state.purchaseOrderState
  };
}

function mapDispatchToProps (dispatch) {
  return {
    stockInPropsActions: bindActionCreators(stockInActions, dispatch),
    purchaseOrderPropsActions: bindActionCreators(purchaseOrderActions, dispatch)
  };
}

StockInSupplierAdd.propTypes = {
  authState: PropTypes.object.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(StockInSupplierAdd);
