export const font = {

    size(value) { return { fontSize: value }; },
    color(value) { return { color: value }; },
    style(value) {return {fontStyle: value}},
    family(value) {return {fontFamily: value}},
    weight(value) {return {fontWeight: value}},

    handleGlobal(fonts, globalStore) {
        if(fonts){
            fonts.map(font => {
                if (font.order) {
                    if (font.size) {
                        globalStore[font.order].font = { ...globalStore[font.order].font, ...this.size(font.size) };
                    }
                    if (font.color) {
                        globalStore[font.order].font = { ...globalStore[font.order].font, ...this.color(font.color) };
                    }
                    if(font.style){
                        globalStore[font.order].font = { ...globalStore[font.order].font, ...this.style(font.style) };
                    }
                    if(font.family){
                        globalStore[font.order].font = { ...globalStore[font.order].font, ...this.family(font.family) };
                    }
                    if(font.weight){
                        globalStore[font.order].font = { ...globalStore[font.order].font, ...this.weight(font.weight) };
                    }
                }
            });
        }
    },

    handleSingle (font, singleStore){
        if(font){
            if (font.size) {
                singleStore.font = { ...singleStore.font, ...this.size(font.size) }
            }
            if (font.color) {
                singleStore.font = { ...singleStore.font, ...this.color(font.color) }
            }
            if(font.style){
                singleStore.font = { ...singleStore.font, ...this.style(font.style) }

            }
            if(font.family){
                singleStore.font = { ...singleStore.font, ...this.family(font.family) }

            }
            if(font.weight){
                singleStore.font = { ...singleStore.font, ...this.weight(font.weight) }
            }
        }
    }
}

export const form = {
    size(value) { return { width: value }; },

    handleGlobal(forms, globalStore) {
        if(forms){
            forms.map(form => {
                if (form.order) {
                    if (form.size) {
                        globalStore[form.order].form = { ...globalStore[form.order].form, ...this.size(form.size) };
                    }
                }
            });
        }
    },

    handleSingle(form, singleStore){
        if(form){
            if(form.size){
                singleStore.form = { ...singleStore.form, ...this.size(form.size) }
            }
        }
    }
}

export const align = {

    margin: {
        left(value) { return { marginLeft: value }; },
        right(value) { return { marginRight: value }; },
        bottom(value) { return { marginBottom: value }; },
        top(value) { return { marginTop: value }; }
    },

    padding: {
        left(value) { return { paddingLeft: value }; },
        right(value) { return { paddingRight: value }; },
        bottom(value) { return { paddingBottom: value }; },
        top(value) { return { paddingTop: value }; }
    },

    handleGlobal(aligns, globalStore) {
        if (aligns) {
            aligns.map(align => {
                if (align.order) {
                    if (align.margin && align.margin.left) {
                        globalStore[align.order].align = { ...globalStore[align.order].align, ...this.margin.left(align.margin.left) };
                    }
                    if (align.margin && align.margin.right) {
                        globalStore[align.order].align = { ...globalStore[align.order].align, ...this.margin.right(align.margin.right) };
                    }
                    if (align.margin && align.margin.bottom) {
                        globalStore[align.order].align = { ...globalStore[align.order].align, ...this.margin.bottom(align.margin.bottom) };
                    }
                    if (align.margin && align.margin.top) {
                        globalStore[align.order].align = { ...globalStore[align.order].align, ...this.margin.top(align.margin.top) };
                    }
                    if (align.padding && align.padding.left) {
                        globalStore[align.order].align = { ...globalStore[align.order].align, ...this.padding.left(align.padding.left) };
                    }
                    if (align.padding && align.padding.right) {
                        globalStore[align.order].align = { ...globalStore[align.order].align, ...this.padding.right(align.padding.right) };
                    }
                    if (align.padding && align.padding.bottom) {
                        globalStore[align.order].align = { ...globalStore[align.order].align, ...this.padding.bottom(align.padding.bottom) };
                    }
                    if (align.padding && align.padding.top) {
                        globalStore[align.order].align = { ...globalStore[align.order].align, ...this.padding.top(align.padding.top) };
                    }
                }
            });
        }
    },

    handleSingle(align, singleStore){
        if(align){
            if (align.margin && align.margin.left) {
                singleStore.align = { ...singleStore.align, ...this.margin.left(align.margin.left) }
            }
            if (align.margin && align.margin.right) {
                singleStore.align = { ...singleStore.align, ...this.margin.right(align.margin.right) }
            }
            if (align.margin && align.margin.bottom) {
                singleStore.align = { ...singleStore.align, ...this.margin.bottom(align.margin.bottom) }
            }
            if (align.margin && align.margin.top) {
                singleStore.align = { ...singleStore.align, ...this.margin.top(align.margin.top) }
            }
            if (align.padding && align.padding.left) {
                singleStore.align = { ...singleStore.align, ...this.padding.left(align.padding.left) }
            }
            if (align.padding && align.padding.right) {
                singleStore.align = { ...singleStore.align, ...this.padding.right(align.padding.right) }
            }
            if (align.padding && align.padding.bottom) {
                singleStore.align = { ...singleStore.align, ...this.padding.bottom(align.padding.bottom) }
            }
            if (align.padding && align.padding.top) {
                singleStore.align = { ...singleStore.align, ...this.padding.top(align.padding.top) }
            }
        }
    }
}

export const size = {
    width(value) { return { width: value }; },
    height(value) { return { height: value }; },

    handleGlobal(sizes, globalStore) {
        if (sizes) {
            sizes.map(size => {
                if (size.order) {
                    if (size.width) {
                        globalStore[size.order].size = { ...globalStore[size.order].size, ...this.width(size.width) };
                    }
                    if (size.height) {
                        globalStore[size.order].size = { ...globalStore[size.order].size, ...this.height(size.height) };
                    }
                }
            });
        } 
    },

    handleSingle(size, singleStore){
        if(size){
            if (size.width) {
                singleStore.size = { ...singleStore.size, ...this.width(size.width) }
            }
            if (size.height) {
                singleStore.size = { ...singleStore.size, ...this.height(size.height) }
            }
        }
    }
}

export const border = {
    left(value) { return { borderLeft: value }; },
    right(value) { return { borderRight: value }; },
    bottom(value) { return { borderBottom: value }; },
    top(value) { return { borderTop: value }; },

    handleGlobal(borders, globalStore) {
        if (borders) {
            borders.map(border => {
                if (border.order) {
                    if (border.left) {
                        globalStore[border.order].border = { ...globalStore[border.order].border, ...this.left(border.left) }
                    }
                    if (border.right) {
                        globalStore[border.order].border = { ...globalStore[border.order].border, ...this.right(border.right) }
                    }
                    if (border.bottom) {
                        globalStore[border.order].border = { ...globalStore[border.order].border, ...this.bottom(border.bottom) }
                    }
                    if (border.top) {
                        globalStore[border.order].border = { ...globalStore[border.order].border, ...this.top(border.top) }
                    }
                }
            });
        } 
    },

    handleSingle(border, singleStore){
        if(border){
            if (border.left) {
                singleStore.border = { ...singleStore.border, ...this.left(border.left) }
            }
            if (border.right) {
                singleStore.border = { ...singleStore.border, ...this.right(border.right) }
            }
            if (border.bottom) {
                singleStore.border = { ...singleStore.border, ...this.bottom(border.bottom) }
            }
            if (border.top) {
                singleStore.border = { ...singleStore.border, ...this.top(border.top) }
            }
        }
    }
}

export const background = {
    color (value){ return {backgroundColor: value}},

    handleGlobal(backgrounds, globalStore){
        if(backgrounds){
            backgrounds.map((background)=>{
                if(background.color){
                    globalStore[background.order].background = { ...globalStore[background.order].background, ...this.color(background.color) };
                }
                
            });
        }
    },

    handleSingle(background, singleStore){
        if(background){
            if(background.color){
                singleStore.background = { ...singleStore.background, ...this.color(background.color) }
            }
        }
    }
};

export const  box ={
    shadow(value) {return {boxShadow: value}},

    handleGlobal(boxes, globalStore){
        if(boxes){
            boxes.map((box)=>{
                if(box.shadow){
                    globalStore[box.order].box = { ...globalStore[box.order].box, ...this.shadow(box.shadow) };
                }
            });
        }
    },

    handleSingle(box, singleStore){
        if(box){
            if(box.shadow){
                singleStore.box = { ...singleStore.box, ...this.shadow(box.shadow) }
            }
        }
    }
};