import { createSelector } from 'reselect';
import _ from 'lodash';

export const getListCustomer = ({ addPacking }) => addPacking.customers && addPacking.customers.items;

export const getCustomer = ({ addPacking }) => addPacking.customer && addPacking.customer.data;

export const getListCustomerPO = ({ addPacking }) => addPacking.customerPOs && addPacking.customerPOs.items;

export const getCustomerPO = ({ addPacking }) => addPacking.customerPO && addPacking.customerPO.data;

export const getListContractor = ({ addPacking }) => addPacking.contractors;

export const getContractor = ({ addPacking }) => addPacking.contractor && addPacking.contractor.data;

export const getPackingType = ({ addPacking }) => addPacking.packingType && addPacking.packingType.data;

export const getCartonType = ({ addPacking }) => addPacking.cartonType && addPacking.cartonType.data;

export const getPackingByOrder = ({ addPacking }) => _.cloneDeep(addPacking.packingByOrder);

export const getPacking = ({ addPacking }) => addPacking.packing;

export const getSuccess = ({ addPacking }) => addPacking.success;

export const getPackingTotal = ({ addPacking }) => (styleId, cutId, colorId, sizeId) => {
  if (!addPacking.packing) return 0;
  let packingTotal = (addPacking.packing.styles || []).map(style => {
    return {
      id: style.style,
      childs: style.cutTickets.map(cut => ({
        id: cut.cutTicket,
        childs: cut.colors.map(color => ({
          id: color.color,
          childs: _.map(
            _.groupBy(
              _.concat(...(color.packings || []).map(packing => packing && packing.items)),
              it => (it || {}).name
            ),
            (v, k) => ({
              name: k,
              items: v,
              calTotal: function () {
                if (addPacking.packing.type === 'Carton')
                  return _.sum(v.map((item, idx) => (idx != 0 ? item.planQuantity || 0 : 0)));
                return _.sum(v.map(item => item.planQuantity || 0));
              },
            })
          ),
          calTotal: function () {
            if (this.childs === undefined || this.childs === null) return 0;
            const sum = _.sum(this.childs.map(c => c.calTotal()));
            return sum;
          },
          getSize: function (_sizeName) {
            if (this.childs) return _.find(this.childs, item => item.name === _sizeName);
          },
        })),
        calTotal: function () {
          if (this.childs === undefined) return 0;

          const sum = _.sum(this.childs.map(c => c.calTotal()));
          return sum;
        },
        getColor: function (_colorId) {
          if (this.childs) return _.find(this.childs, item => item.id === _colorId);
        },
      })),
      calTotal: function () {
        if (this.childs === undefined) return 0;
        const sum = _.sum(this.childs.map(c => c.calTotal()));
        return sum;
      },
      getCutTicket: function (_cutId) {
        if (this.childs) return _.find(this.childs, item => item.id === _cutId);
      },
    };
  });

  if (!packingTotal) return 0;
  if (styleId) {
    let style = _.filter(packingTotal, sty => sty.id == styleId)[0];
    if (!style) return 0;
    if (cutId) {
      let cut = style.getCutTicket(cutId) || {};
      if (colorId) {
        let color = cut.getColor(colorId) || {};
        if (sizeId && !_.isEmpty(color)) {
          let size = color.getSize(sizeId);
          return size ? size.calTotal() : 0;
        }
        return !_.isEmpty(color) ? color.calTotal() : 0;
      }
      return cut.calTotal();
    }
    return style.calTotal();
  } else {
    return _.sum(packingTotal.map(it => it.calTotal()));
  }
};

export const getActualPacking = ({ addPacking }) => (styleId, cutId, colorId, sizeId) => {
  if (!addPacking.packing) return 0;
  let packingTotal = (addPacking.packing.styles || []).map(style => {
    if (addPacking.packing.type === 'Carton') {
      return {
        id: style.style,
        childs: style.cutTickets.map(cut => ({
          id: cut.cutTicket,
          childs: cut.colors.map(color => ({
            id: color.color,
            childs: _.map(
              _.groupBy(_.concat(...(color.packings || []).map((packing, index) => packing.items)), it => it.name),
              (v, k) => ({
                name: k,
                items: v,
                calTotal: function () {
                  return _.sum(v.map((item, idx) => (item.actualQuantity ? item.actualQuantity : 0)));
                },
              })
            ),
            calTotal: function () {
              if (this.childs === undefined || this.childs === null) return 0;
              const sum = _.sum(this.childs.map(c => c.calTotal()));
              return sum;
            },
            getSize: function (_sizeName) {
              if (this.childs) return _.find(this.childs, item => item.name === _sizeName);
            },
          })),
          calTotal: function () {
            if (this.childs === undefined) return 0;

            const sum = _.sum(this.childs.map(c => c.calTotal()));
            return sum;
          },
          getColor: function (_colorId) {
            if (this.childs) return _.find(this.childs, item => item.id === _colorId);
          },
        })),
        calTotal: function () {
          if (this.childs === undefined) return 0;
          const sum = _.sum(this.childs.map(c => c.calTotal()));
          return sum;
        },
        getCutTicket: function (_cutId) {
          if (this.childs) return _.find(this.childs, item => item.id === _cutId);
        },
      };
    } else {
      return {
        id: style.style,
        childs: style.cutTickets.map(cut => ({
          id: cut.cutTicket,
          childs: cut.colors.map(color => ({
            id: color.color,
            childs: _.map(
              _.groupBy(_.concat(...(color.packings || []).map((packing, index) => packing.items)), it => it.name),
              (v, k) => ({
                name: k,
                items: v,
                calTotal: function () {
                  return _.sum(v.map((item, idx) => (item.actualQuantity ? item.actualQuantity : 0)));
                },
              })
            ),
            calTotal: function () {
              if (this.childs === undefined || this.childs === null) return 0;
              const sum = _.sum(this.childs.map(c => c.calTotal()));
              return sum;
            },
            getSize: function (_sizeName) {
              if (this.childs) return _.find(this.childs, item => item.name === _sizeName);
            },
          })),
          calTotal: function () {
            if (this.childs === undefined) return 0;

            const sum = _.sum(this.childs.map(c => c.calTotal()));
            return sum;
          },
          getColor: function (_colorId) {
            if (this.childs) return _.find(this.childs, item => item.id === _colorId);
          },
        })),
        calTotal: function () {
          if (this.childs === undefined) return 0;
          const sum = _.sum(this.childs.map(c => c.calTotal()));
          return sum;
        },
        getCutTicket: function (_cutId) {
          if (this.childs) return _.find(this.childs, item => item.id === _cutId);
        },
      };
    }
  });
  if (!packingTotal) return 0;
  if (styleId) {
    let style = _.filter(packingTotal, sty => sty.id == styleId)[0];
    if (!style) return 0;
    if (cutId) {
      let cut = style.getCutTicket(cutId) || {};
      if (colorId) {
        let color = cut.getColor(colorId) || {};
        if (sizeId && !_.isEmpty(color)) {
          let size = color.getSize(sizeId);
          return size ? size.calTotal() : 0;
        }
        return !_.isEmpty(color) ? color.calTotal() : 0;
      }
      return cut.calTotal();
    }
    return style.calTotal();
  } else {
    let result = _.sum(packingTotal.map(it => it.calTotal()));
    return result;
  }
};

export const getMissingPacking = ({ addPacking }) => (styleId, cutId, colorId, sizeId) => {
  if (!addPacking.packing) return 0;
  let packingTotal = (addPacking.packing.styles || []).map(style => {
    return {
      id: style.style,
      childs: (style.cutTickets || []).map(cut => ({
        id: cut.cutTicket,
        childs: cut.colors.map(color => ({
          id: color.color,
          childs: _.map(
            _.groupBy(_.concat(...(color.packings || []).map((packing, index) => packing.items)), it => it.name),
            (v, k) => ({
              name: k,
              items: v,
              calTotal: function () {
                return _.sum(
                  v.map((item, idx) => {
                    if (
                      item.planQuantity !== undefined &&
                      item.actualQuantity !== undefined &&
                      item.planQuantity >= item.actualQuantity
                    ) {
                      return (item.planQuantity || 0) - (item.actualQuantity || 0);
                    } else {
                      return 0;
                    }
                  })
                );
              },
            })
          ),
          calTotal: function () {
            if (this.childs === undefined || this.childs === null) return 0;
            const sum = _.sum(this.childs.map(c => c.calTotal()));
            return sum;
          },
          getSize: function (_sizeName) {
            if (this.childs) return _.find(this.childs, item => item.name === _sizeName);
          },
        })),
        calTotal: function () {
          if (this.childs === undefined) return 0;

          const sum = _.sum(this.childs.map(c => c.calTotal()));
          return sum;
        },
        getColor: function (_colorId) {
          if (this.childs) return _.find(this.childs, item => item.id === _colorId);
        },
      })),
      calTotal: function () {
        if (this.childs === undefined) return 0;
        const sum = _.sum(this.childs.map(c => c.calTotal()));
        return sum;
      },
      getCutTicket: function (_cutId) {
        if (this.childs) return _.find(this.childs, item => item.id === _cutId);
      },
    };
  });

  if (!packingTotal) return 0;
  if (styleId) {
    let style = _.filter(packingTotal, sty => sty.id == styleId)[0] || {};
    if (cutId) {
      if (typeof style.getCutTicket === 'function') {
        let cut = style.getCutTicket(cutId) || {};
        if (colorId) {
          if (typeof cut.getColor === 'function') {
            let color = cut.getColor(colorId) || {};
            if (sizeId && !_.isEmpty(color)) {
              let size = color.getSize(sizeId);
              return size ? size.calTotal() : 0;
            }
            return !_.isEmpty(color) ? color.calTotal() : 0;
          }
        }
        if (typeof cut.calTotal === 'function') {
          return cut.calTotal();
        }
        return 0;
      }
    }
    if (typeof style.calTotal === 'function') {
      return style.calTotal();
    }
    return 0;
  } else {
    return _.sum(packingTotal.map(it => it.calTotal()));
  }
};

export const getTotal = ({ addPacking }) => (styleId, cutId, colorId, sizeId) => {
  if (!addPacking.packingByOrder) return 0;
  let packingTotal = (_.cloneDeep(addPacking.packingByOrder) || []).map((style, sIdx) => {
    return {
      id: style._id,
      childs: style.cutTickets.map((cut, cIdx) => ({
        id: cut._id,
        childs: cut.colors.map((color, clIdx) => ({
          id: color._id,
          childs: color.sizes.map((size, szIdx) => ({
            sizeName: size.name,
            calTotal: function () {
              // if(editPacking && editPacking.packingInfo)
              //     return (size.passQuantity) || 0 - (size.packedQuantity || 0) + (_.sum(editPacking.packing.styles[sIdx].cutTickets[cIdx].colors[clIdx].packings, packing => packing.items.map(item => item.name == size.name)))
              // else
              return (size.passQuantity || 0) - (size.packedQuantity || 0);
            },
          })),
          calTotal: function () {
            if (this.childs === undefined) return 0;
            const sum = _.sum(this.childs.map(c => c.calTotal()));
            return sum;
          },
          getSize: function (_sizeName) {
            if (this.childs) return _.find(this.childs, item => item.sizeName === _sizeName);
          },
        })),
        calTotal: function () {
          if (this.childs === undefined) return 0;

          const sum = _.sum(this.childs.map(c => c.calTotal()));
          return sum;
        },
        getColor: function (_colorId) {
          if (this.childs) return _.find(this.childs, item => item.id === _colorId);
        },
      })),
      calTotal: function () {
        if (this.childs === undefined) return 0;
        const sum = _.sum(this.childs.map(c => c.calTotal()));
        return sum;
      },
      getCutTicket: function (_cutId) {
        if (this.childs) return _.find(this.childs, item => item.id === _cutId);
      },
    };
  });

  if (!packingTotal) return 0;
  if (styleId) {
    let style = _.filter(packingTotal, sty => sty.id == styleId)[0];
    if (!style) return 0;
    if (cutId) {
      let cut = style.getCutTicket(cutId);
      if (colorId) {
        let color = cut.getColor(colorId);
        if (sizeId && color) {
          let size = color.getSize(sizeId);
          return size.calTotal();
        }
        return color.calTotal();
      }
      return cut.calTotal();
    }
    return style.calTotal();
  } else {
    return _.sum(packingTotal.map(it => it.calTotal()));
  }
};

export const getTotalConst = ({ editPacking }) => (styleId, cutId, colorId, sizeId) => {
  if (!editPacking.packing) return 0;
  let packingTotal = (editPacking.packing.styles || []).map(style => {
    return {
      id: style.style,
      childs: style.cutTickets.map(cut => ({
        id: cut.cutTicket,
        childs: cut.colors.map(color => ({
          id: color.color,
          childs: _.map(
            _.groupBy(_.concat(...(color.packings || []).map(packing => packing.items)), it => it.name),
            (v, k) => ({
              name: k,
              items: v,
              calTotal: function () {
                if (editPacking.packing.type === 'Carton' && !editPacking.packingInfo)
                  return _.sum(v.map((item, idx) => (idx != 0 ? item.planQuantity || 0 : 0)));
                return _.sum(v.map(item => item.planQuantity || 0));
              },
            })
          ),
          calTotal: function () {
            if (this.childs === undefined || this.childs === null) return 0;
            const sum = _.sum(this.childs.map(c => c.calTotal()));
            return sum;
          },
          getSize: function (_sizeName) {
            if (this.childs) return _.find(this.childs, item => item.name === _sizeName);
          },
        })),
        calTotal: function () {
          if (this.childs === undefined) return 0;

          const sum = _.sum(this.childs.map(c => c.calTotal()));
          return sum;
        },
        getColor: function (_colorId) {
          if (this.childs) return _.find(this.childs, item => item.id === _colorId);
        },
      })),
      calTotal: function () {
        if (this.childs === undefined) return 0;
        const sum = _.sum(this.childs.map(c => c.calTotal()));
        return sum;
      },
      getCutTicket: function (_cutId) {
        if (this.childs) return _.find(this.childs, item => item.id === _cutId);
      },
    };
  });

  if (!packingTotal) return 0;
  if (styleId) {
    let style = _.filter(packingTotal, sty => sty.id == styleId)[0];
    if (!style) return 0;
    if (cutId) {
      let cut = style.getCutTicket(cutId);
      if (colorId) {
        let color = cut.getColor(colorId);
        if (sizeId && color) {
          let size = color.getSize(sizeId);
          return size ? size.calTotal() : 0;
        }
        return color.calTotal();
      }
      return cut.calTotal();
    }
    return style.calTotal();
  } else {
    return _.sum(packingTotal.map(it => it.calTotal()));
  }
};

export const getPackingTotalCarton = ({ addPacking }) => (styleId, cutId, colorId, sizeName) => {
  if (!addPacking || !addPacking.packing) return 0;
  let k = 0;
  _.map(
    addPacking.packing.styles,
    style =>
      style.style == styleId &&
      _.map(
        style.cutTickets,
        cut =>
          cut.cutTicket == cutId &&
          _.map(
            cut.colors,
            color =>
              color.color == colorId &&
              !_.isEmpty(color.packings) &&
              _.map(color.packings[0].items, item => {
                if (item.name == sizeName) {
                  k = item.planQuantity;
                }
              })
          )
      )
  );
  return k;
};
export const getRemaining = createSelector(
  [getPackingTotal, getTotal, getTotalConst],
  (fnPackingTotal, fnTotal, fnTotalConst, test) => (styleId, cutId, colorId, sizeId) => {
    return fnTotal(styleId, cutId, colorId, sizeId) - (fnPackingTotal(styleId, cutId, colorId, sizeId) || 0);
    //+ (fnTotalConst(styleId, cutId, colorId, sizeId))) || 0
  }
);

export const getRemainingCustomer = createSelector(
  [getPackingByOrder, getMissingPacking],
  (data, fnGetMissingPacking) => {
    let count = 0;
    _.map(data, idata => {
      _.map(idata.cutTickets, icutTicket => {
        _.map(icutTicket.colors, icolor => {
          const sizes = _.get(icolor, 'sizes', []);
          _.map(sizes, item => {
            // console.log(`ahihi: `,fnGetMissingPacking(idata._id, icutTicket._id, icolor._id, item.name) || 0)
            count += fnGetMissingPacking(idata._id, icutTicket._id, icolor._id, item.name) || 0;
          });
        });
      });
    });
    return count;
  }
);

export const getCarton = ({ addPacking }) => (styleId, cutId, colorId) => {
  if (!addPacking || !addPacking.packing || addPacking.packing.type == 'GOHH') return [];
  let cartons = [];
  _.map(
    addPacking.packing.styles,
    style =>
      style.style == styleId &&
      _.map(
        style.cutTickets,
        cut =>
          cut.cutTicket == cutId &&
          _.map(cut.colors, color => {
            if (color.color == colorId) {
              _.map(color.packings, (packing, index) => {
                if (
                  _.findIndex(cartons, carton => carton.props.group == packing.props.group) == -1 &&
                  packing.props.no != 0 &&
                  index > 0
                ) {
                  cartons.push({ props: _.cloneDeep(packing.props), items: _.cloneDeep(packing.items) });
                }
              });
            }
          })
      )
  );
  _.remove(cartons, carton => carton.props.no == 0);

  _.map(cartons || [], carton => {
    const temp = carton.props.group && carton.props.group.split('-');
    carton.noBegin = temp[0] || '';
    carton.noEnd = temp[1] || '';
    carton.quantity = temp ? (temp[1] ? temp[1] * 1 - temp[0] * 1 + 1 : 1) : 0;
    carton.quantityCTN = _.sum(carton.items.map(item => item.planQuantity || 0));
    carton.total = carton.quantity * carton.quantityCTN || 0;
  });
  return cartons || [];
};

export const getPackingInfo = ({ editPacking }) => editPacking.packingInfo && editPacking.packingInfo.data;

export const resetPackedQuantity = ({ editPacking }) => editPacking.resetPackedQuantity;

export const getListCartonsForEdit = ({ editPacking }) => editPacking.listCartons;
