import React, { Component } from 'react';
import I18n from 'i18n-js';
import { connect } from 'react-redux';
import PreviewContractorView from '../../../components/accounting/payable/PreviewContractor';
import * as selector from '../../../../selectors/accounting/payable/historyPreviewContractor';
import * as actions from '../../../../actions/accounting/payable/historyPreviewContractor';
import {withRouter} from "react-router";

class PreviewContractor extends Component {

    constructor(props) {
        super(props)
        this.state = {
            listIds: this.props.listIds,
            items: [],
            pageNumber: 1,
            pageSize: 10,
            columns: [
                { name: I18n.t('PageAccounting.ShipDate'), value: 'shipDate', type: 'date', formatType: 'MMM DD, YYYY' },
                { name: I18n.t('PageAccounting.ShipMode'), value: 'shipMode', type: 'text' },
                { name: I18n.t('PageAccounting.CustomerPO'), value: 'customerPO', type: 'text' },
                { name: I18n.t('PageAccounting.Style'), value: 'style', type: 'text' },
                { name: I18n.t('PageAccounting.CutTicket'), value: 'cutTicket', type: 'number' },
                { name: I18n.t('PageAccounting.Color'), value: 'name', type: 'number' },
                { name: I18n.t('PageAccounting.QTYREC'), value: 'qtyRec', type: 'number' },
                { name: I18n.t('PageAccounting.Price'), value: 'price', type: 'number' },
                { name: I18n.t('PageAccounting.Amount'), value: 'amount', options: { clsTd: 'color-red' }, type: 'number' },
                // { name: I18n.t('PageAccounting.Remaining'), value: 'remaining', options: { clsTd: 'color-green' }, type: 'number' },

            ],
            actions: []
        }
    }

    componentWillReceiveProps(nextProps) {
        const {activeScreen} = nextProps;
        if(!activeScreen) {
            nextProps.router.history.goBack();
        }
    }

    render() {
        const { columns } = this.state;
        return (
            <div>
                <PreviewContractorView columns={columns}
                    {...this.props}
                />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    invoiceDetail: selector.getInvoiceDetail(state),
    contractor: selector.getContractor(state),
    items: selector.getListCustomerPOsDetail(state),
    totalQTY: selector.getTotalQTY(state),
    invoiceNo: selector.getInvoiceNo(state),
    issuedDate: selector.getIssuedDate(state),
    vatAmount: selector.getVatAmount(state),
    amount: selector.getAmount(state),
    totalAmount: selector.getTotalAmount(state),
    currencyTypeUSD: selector.getCurrencyTypeUSD(state),
    currencyTypeVND: selector.getCurrencyTypeVND(state),
    activeScreen: selector.getActiveScreenStatus(state),
})

const mapDispatchToProps = dispatch => ({

})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PreviewContractor));