import React from "react";
import PropTypes from 'prop-types';

const ViewInvoiceNo = (props) => {
    const { data } = props
    return (
        <div>
            <ul className="content-header-title bg-white bd-dashed-bottom float-none pd-0-10 justify-content-space-between display-flex">
                <li>
                    <p>Invoice No.
                            <span className="bold mg-left-25 size-12">A01-2018</span>
                    </p>
                </li>
                <li>
                    <p>Credit/Debit
                            <span className="bold mg-left-25 size-12">1</span>
                    </p>
                </li>
                <li>
                    <p>Issued Date
                            <span className="bold mg-left-25 size-12">August 15, 2017</span>
                    </p>
                </li>
                <li>
                    <p>Due Date
                            <span className="bold mg-left-25 size-12">August 15, 2017</span>
                    </p>
                </li>
            </ul>

            <ul className="content-header-title bg-white mg-bottom-10 float-none pd-0-10 justify-content-space-between display-flex">
                <li>
                    <p>Amount INV
                            <span className="bold color-number mg-left-25 size-12">$300</span>
                    </p>
                </li>
                <li>
                    <p>Total Amount INV
                            <span className="bold color-number mg-left-25 size-12">$302</span>
                    </p>
                </li>
                <li>
                    <p>Say
                            <span className="bold mg-left-25 size-12">Forty-ther</span>
                    </p>
                </li>

            </ul>

            <ul className="content-header-title box-shadow mg-bottom-10 bg-white float-none pd-0-10 justify-content-space-between display-flex">
                <li>
                    <p>Convert to
                            <span className="bold mg-left-25 size-12">VND</span>
                    </p>
                </li>
                <li className="bd-dashed-right-1 pd-right-30">
                    <p>Rate
                            <span className="bold mg-left-25 size-12">22.5</span>
                    </p>
                </li>
                <li>
                    <p>Amount Convert
                            <span className="bold color-number mg-left-25 size-12">9,000,000 VND</span>
                    </p>
                </li>

                <li>
                    <p>Amount Convert
                            <span className="bold color-number mg-left-25 size-12">9,000,000 VND</span>
                    </p>
                </li>

            </ul>

            <ul className="content-header-title box-shadow mg-bottom-10 bg-white float-none pd-0-10 justify-content-space-between display-flex">
                <li>
                    <p>VAT No
                            <span className="bold mg-left-25 size-12">01</span>
                    </p>
                </li>
                <li>
                    <p>VAT Date
                            <span className="bold mg-left-25 size-12">August 15,2017</span>
                    </p>
                </li>
                <li>
                    <p>Tax Rate
                            <span className="bold mg-left-25 size-12">10%</span>
                    </p>
                </li>

                <li>
                    <p>Total Amount
                            <span className="bold color-number mg-left-25 size-12">9,000,000 VND</span>
                    </p>
                </li>

                <li>
                    <p>VAT Amount
                            <span className="bold color-number mg-left-25 size-12">$40</span>
                    </p>
                </li>

                <li>
                    <p>Grand Total VAT
                            <span className="bold color-number mg-left-25 size-12">$330</span>
                    </p>
                </li>
            </ul>

            {/*View INV No */}
            <div className="mg-bottom-10">
                <div className="d-flex justify-content-between cursor-pointer pd-10-0 color-white bg-black">
                    <div className="d-flex">
                        <p className="size-12 mg-bottom-auto mg-top-auto"><span className="icon fa fa-caret-down cursor-pointer mg-0-15"></span> Purchase Order</p>
                        <span className="bold mg-left-50 size-14 mg-bottom-auto mg-top-auto">1709-9123</span>
                    </div>

                    <div className="d-flex justify-content-end">
                        <div className="d-flex mg-right-30">
                            <p className="size-12 mg-right-15 mg-bottom-auto mg-top-auto">IH Date</p>
                            <span className="bold mg-bottom-auto mg-top-auto size-12">
                                August 13,2012
                              </span>
                        </div>
                        {/* <div className="size-12 color-link"><a>{cutTicket.store && cutTicket.store.packingStandard && cutTicket.store.packingStandard.name || '-'}</a></div> */}
                    </div>
                </div>
                <div className="box-shadow-1 mg-bottom-5">
                    <ul className="content-header-title bg-white float-none pd-0-10 justify-content-space-between display-flex bd-dashed-bottom">
                        <li>
                            <p>VAT No
                                <span className="bold mg-left-25 size-12">01</span>
                            </p>
                        </li>
                        <li>
                            <p>VAT Date
                                <span className="bold mg-left-25 size-12">August 15,2017</span>
                            </p>
                        </li>
                        <li>
                            <p>Tax Rate
                                <span className="bold mg-left-25 size-12">10%</span>
                            </p>
                        </li>

                        <li>
                            <p>Total Amount
                                <span className="bold color-number mg-left-25 size-12">9,000,000 VND</span>
                            </p>
                        </li>

                        <li>
                            <p>VAT Amount
                                <span className="bold color-number mg-left-25 size-12">$40</span>
                            </p>
                        </li>

                        <li>
                            <p>Grand Total VAT
                                <span className="bold color-number mg-left-25 size-12">$330</span>
                            </p>
                        </li>
                    </ul>
                    <ul className="content-header-title bg-white float-none pd-0-10 justify-content-space-between display-flex">
                        <li>
                            <p>VAT No
                                <span className="bold mg-left-25 size-12">01</span>
                            </p>
                        </li>
                    </ul>
                </div>

                <table className="global-table w-full">
                    <tbody>
                        <tr>
                            <th>Material</th>
                            <th>Order Quantity</th>
                            <th>Received Quanity</th>
                            <th>Received Date</th>
                            <th>Unit</th>
                            <th>Price</th>
                            <th>Amount</th>
                        </tr>
                        <tr>
                            <td>Sulb Hacci</td>
                            <td>90</td>
                            <td>90</td>
                            <td>Aug 13, 2017</td>
                            <td>yard</td>
                            <td>$90</td>
                            <td className="color-number">$230, 30</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className="mg-bottom-10">
                <div className="d-flex justify-content-between cursor-pointer pd-10-0 color-white bg-black">
                    <div className="d-flex">
                        <p className="size-12 mg-bottom-auto mg-top-auto"><span className="icon fa fa-caret-down cursor-pointer mg-0-15"></span> Purchase Order</p>
                        <span className="bold mg-left-50 size-14 mg-bottom-auto mg-top-auto">1709-9123</span>
                    </div>

                    <div className="d-flex justify-content-end">
                        <div className="d-flex mg-right-30">
                            <p className="size-12 mg-right-15 mg-bottom-auto mg-top-auto">IH Date</p>
                            <span className="bold mg-bottom-auto mg-top-auto size-12">
                                August 13,2012
                              </span>
                        </div>
                        {/* <div className="size-12 color-link"><a>{cutTicket.store && cutTicket.store.packingStandard && cutTicket.store.packingStandard.name || '-'}</a></div> */}
                    </div>
                </div>
                <div className="box-shadow-1 mg-bottom-5">
                    <ul className="content-header-title bg-white float-none pd-0-10 justify-content-space-between display-flex bd-dashed-bottom">
                        <li>
                            <p>VAT No
                                <span className="bold mg-left-25 size-12">01</span>
                            </p>
                        </li>
                        <li>
                            <p>VAT Date
                                <span className="bold mg-left-25 size-12">August 15,2017</span>
                            </p>
                        </li>
                        <li>
                            <p>Tax Rate
                                <span className="bold mg-left-25 size-12">10%</span>
                            </p>
                        </li>

                        <li>
                            <p>Total Amount
                                <span className="bold color-number mg-left-25 size-12">9,000,000 VND</span>
                            </p>
                        </li>

                        <li>
                            <p>VAT Amount
                                <span className="bold color-number mg-left-25 size-12">$40</span>
                            </p>
                        </li>

                        <li>
                            <p>Grand Total VAT
                                <span className="bold color-number mg-left-25 size-12">$330</span>
                            </p>
                        </li>
                    </ul>
                    <ul className="content-header-title bg-white float-none pd-0-10 justify-content-space-between display-flex">
                        <li>
                            <p>VAT No
                                <span className="bold mg-left-25 size-12">01</span>
                            </p>
                        </li>
                    </ul>
                </div>

                <table className="global-table w-full">
                    <tbody>
                        <tr>
                            <th>Material</th>
                            <th>Order Quantity</th>
                            <th>Received Quanity</th>
                            <th>Received Date</th>
                            <th>Unit</th>
                            <th>Price</th>
                            <th>Amount</th>
                        </tr>
                        <tr>
                            <td>Sulb Hacci</td>
                            <td>90</td>
                            <td>90</td>
                            <td>Aug 13, 2017</td>
                            <td>yard</td>
                            <td>$90</td>
                            <td className="color-number">$230, 30</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

ViewInvoiceNo.propTypes = {
    data: PropTypes.object
}

ViewInvoiceNo.defaultProps = {
    data: {}

}

export default ViewInvoiceNo;
