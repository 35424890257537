import React, {Component}         from 'react';
import PropTypes                  from 'prop-types';
import { connect }                from 'react-redux';
import _                          from 'lodash';

import EstimateForm               from './EstimateForm';
import TrimForm                   from './TrimForm';
import {sourceTypes, materialTypes} from '../../../constants/EnumTypes';

import icAddIcon from '../../../stylesheets/images/ic-add.svg';

class YieldTrim extends Component {
  constructor (props, context) {
    super(props, context);

    this.state = {
      wrap: this.props.wrap,
      est: this.setEst(this.props.colors || [])
    };
    this.onChange = this.onChange.bind(this);
    this.onChangeTrimSize = this.onChangeTrimSize.bind(this);
  }

//   componentWillMount () {
//     const {_yield} = this.props;
//     this.setState({wrap: {data: _.cloneDeep(_.get(_yield || {}, 'optionalTrims', [{}]))}});
//   }

  addItem () {
    let {wrap} = this.state;
    let data = _.get(wrap, 'data', []);
    data.unshift({info: {type: sourceTypes.SOURCED}})
    this.setState({wrap: {data: data}});
  }

  onChange (idx1, idx2, est, value)  {
    let {wrap} = this.state;
    est[idx2].trimColor = value;
    let estimate = [];
    est.forEach(c => {
      estimate.push({trimColor: c.trimColor, color: c.color._id});
    });

    if(!_.isEmpty(wrap.data[idx1].estimate)) {
      while(wrap.data[idx1].estimate.length - 1 < idx2) {
        wrap.data[idx1].estimate.push({trimColor: '', color:''})
      } 
        (wrap.data[idx1].estimate[idx2] || {}).trimColor = value;
        (wrap.data[idx1].estimate[idx2] || {}).color = est[idx2].color._id;
    } else {
      wrap.data[idx1].estimate = estimate;
    }

    this.setState({wrap});

    this.updateToParent(_.cloneDeep(_.get(wrap, 'data', [{}])));
  }

  onChangeTrimSize (idx, idxArr, size, value) {
    const {sizes} = this.props;
    let {wrap} = this.state;

    let trimSize = _.get(wrap, `data[${idx}].trimSize`, []);
    Object.keys(sizes).forEach((k, idx) => {
      if (!trimSize[idx]) {
        trimSize[idx] = {size: k, value: ''};
      }
    });

    if (trimSize[idxArr]) {
      trimSize[idxArr] = {size: size, value: value};
      _.set(wrap, `data[${idx}].trimSize`, trimSize);
      this.setState({wrap});

      this.updateToParent(_.cloneDeep(_.get(wrap, 'data', [])));
    }
  }

  updateInfo (info, idx) {
    let {wrap} = this.state;
    wrap = _.set(wrap || {}, `data[${idx}].info`, info);
    this.setState({wrap});

    this.updateToParent(_.cloneDeep(_.get(wrap, 'data', [])));
  }

  updateToParent (data) {
    // update to parent
    const {setProperty} = this.props;
    setProperty && setProperty(data);
  }

  setEst(colors) {
    return colors.map(vl => ({
      color: vl,
      selectColor: ""
    }))
    // let data = _.groupBy(colors,"name");
    // return Object.keys(data).map(vl => {
    //   // let sizes = data[vl].reduce((acc, item) => {
    //   //   item.sizes.forEach(e => {
    //   //     acc[e.name] = (e.quantity || 0) +  (acc[e.name] || 0);
    //   //   });
    //   //   return acc;
    //   // }, {});
    //   return {
    //     name: vl,
    //     data: data[vl],
    //     trimColor: ""
    //   };
    // });
  }

  onDelete (idx) {
    let {wrap} = this.state;
    let newData = wrap ? [...wrap.data] : [];
    newData.splice(idx, 1);


    this.setState({wrap: {data: newData, estimate: wrap.estimate}});
    if(this.props.onDelete) {
      this.props.onDelete(idx);
    }

  }

  renderYieldTrim() {
    const {isBlocked, showCreate, showEdit, showDelete, isSuperAdmin} = this.props;
    if(!isSuperAdmin) {
      if(isBlocked) {
        if(!showDelete) {
          return null;
        }
      } else if(!showCreate && !showEdit && !showDelete) {
        return null;
      }
    }
    return (
      <span className="cursor-pointer" onClick={() => this.addItem()}>
        <img src={icAddIcon} /> Add Trim</span>
    );
  }

  render () {
    let {sizes, materials, colorsGlobal, sizesGlobal, name, errors} = this.props;
    let {wrap, est} = this.state;

    return (
      <div className="md content-main-info-edit-yield">
        <div className="md content-main-info-edit-yield-header">
          <h2>{name}</h2>
          {this.renderYieldTrim()}
        </div>

        {(wrap.data || []).map((data, idx) => {
          let sizesTrim = _.get((materials.find(vl => vl._id == _.get(data, 'info.material', '')) || {}), "data.size", []);
          let selectedColors = []
          if(data.info && data.info.material) {
            let mat = materials.find(vl => vl._id.toString() == data.info.material)
            if(mat)
            selectedColors = mat.data.color
          }
          const itemError = _.isArray(errors) ? errors[idx] : errors;
          return (
            <div key={idx} className="md content-main-info-edit-yield-wrap">
              <EstimateForm
                {...this.props}
                errors={itemError}
                onDelete={()=>this.onDelete(idx)}
                data={data.info || {}}
                updateInfo={(info) => this.updateInfo(info, idx)}
                materials={(materials || []).filter(m => m.type === materialTypes.TRIM)}
                type={materialTypes.TRIM} />

              <TrimForm {...{data, idx, sizes, sizesTrim, est, colorsGlobal, sizesGlobal, onChangeTrimSize: this.onChangeTrimSize, onChange: this.onChange, selectedColors}} errors={itemError} />
            </div>
          );
        })}
      </div>
    );
  }
}

function mapStateToProps (state, ownProps) {
  return {
    authState: state.authState
  };
}

function mapDispatchToProps (dispatch) {
  return {};
}

YieldTrim.propTypes = {
  authState: PropTypes.object.isRequired,
  isBlocked : PropTypes.bool,
  showCreate : PropTypes.bool,
  showEdit : PropTypes.bool,
  showDelete : PropTypes.bool,
  isSuperAdmin: PropTypes.bool,
  type: PropTypes.string
};

export default connect(mapStateToProps, mapDispatchToProps)(YieldTrim);
