export default class Material {
  constructor(){
    this._id = '';
    this.name = '';
    this.type = '';
    this.isConvert = false;
    this.convertTo = '';
    this.convertUnit = '';
    this.note = '';
    this.data = {};
  }

  init(object){
    this._id = object._id;
    this.name = object.name;
    this.type = object.type;
    this.isConvert = object.isConvert;
    this.convertTo = object.convertTo;
    this.convertUnit = object.convertUnit;
    this.note = object.note;
    this.data = object.data;
  }
}