import {getListStoreDAL, addStoreDAL, editStoreDAL, getStoreDAL, deleteStoreDAL} from '../api/storeApi';

class storeServices {

  constructor() {
  }

  getListStore (options = {}) {
    options.query && _.set(options, 'query', JSON.stringify(options['query']));
    options.sort && _.set(options, 'sort', JSON.stringify(options['sort']));
    return getListStoreDAL(options).then((res) => {
      return res;
    });
  }

  getDetail (id) {
    return getStoreDAL(id, {});
  }

  deleteStore (id) {
    return deleteStoreDAL(id);
  }

  editStore (id, data) {
    return editStoreDAL(id, data);
  }

  addStore (data) {
    return addStoreDAL(data);
  }
}

export default new storeServices();
