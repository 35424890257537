import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import authActions from '../../actions/authActions';
import Select from '../common/fields/Select'
import { showAvatar } from '../../functions/helpers';
import ReactTelephoneInput from 'react-telephone-input/lib/withStyles'
const uuidv4 = require('uuid/v4')
import { pathImage } from '../../functions/helpers';
import { toastr } from 'react-redux-toastr';

import avatarDefaultIcon from '../../stylesheets/images/avatar-default.svg';
import flagsIcon from '../../stylesheets/images/flags.png';

class EditProfile extends Component {
  constructor(props, context) {
    super(props, context);
    this.setUser = this.setUser.bind(this)
    this.state = {
      errors: {},
      user: {
        gender: 'Male',
        email: '',
        _id: '',
      },
      combine: {
        gender: 'Male',
      },
    };

  }

  componentDidMount() {
    this.setState({ combine: Object.assign({}, this.props.authState.auth) })
  }

  componentWillReceiveProps(newProps) {
    this.setState({ combine: Object.assign({}, newProps.authState.auth) })
  }

  onChange(property, value) {
    let { item } = this.state;
    this.setState({ item: _.set(item, property, value) });
  }

  addImage(e) {
    e.preventDefault();
    let file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    if (!file)
      return;

    var FileSize = file.size / 1024 / 1024; // in MB
    if (FileSize > 2) {
      toastr.error('Error', I18n.t('ErrorMessages.FileSizeExceeds2MB'));
      return;
    }
    let self = this;
    let { user } = self.state;


    let readerBase64 = new FileReader();
    readerBase64.onload = function (eventBase64) {
      user.file = { file: file, url: eventBase64.target.result, filename: file.name };
      self.setState({ user });
    };
    readerBase64.readAsDataURL(file);
  }

  setUser(field, value) {
    var user = this.state.user
    var combine = this.state.combine
    user[field] = value
    combine[field] = value
    var errors = this.state.errors
    errors[field] = null
    this.setState({ user: user, errors, combine: combine })
  }

  close() {
    this.props.closeModal();
  }

  save() {
    var newUser = this.state.user
    var user = this.props.authState.auth
    var errors = {}
    var flag = true
    for (var key in newUser) {
      switch (key) {
        case 'phone':
          if (newUser[key] == '+')
            delete newUser[key]
          break
        case 'email':
          if (newUser[key] == '')
            newUser[key] = user.email
          break
        case '_id':
          if (newUser[key] == '')
            newUser[key] = user.id
          break
      }
    }
    if(newUser.firstName == '') {
      toastr.error('Error', I18n.t('ErrorMessages.FirstNameIsRequired'));
      return;
    }
    if(newUser.lastName == '') {
      toastr.error('Error', I18n.t('ErrorMessages.LastNameIsRequired'));
      return;
    }
    if (flag) {
      this.props.authPropsActions.profileEdit(user.id, newUser)
    } else {
      this.setState({ errors: errors })
    }
    this.props.closeModal();
  }

  render = () => {
    let { newAvatar } = this.state;
    let user = this.state.user;
    let userNew = this.state.combine;
    let avatar = showAvatar(user, { classDefault: 'avatar-default' }, newAvatar);
    return (
      <div className="modal-dialog" role="document" id="popup-edit-profile">
        <div className="modal-content">
          <form>
            <div className="modal-header">
              <h4 className="modal-title" id="myModalLabel">{I18n.t('Profile.EditProfile')}</h4>
            </div>
            <div className="modal-body">
              <div className="col-md-3 col-xs-12 avatar">
                <img className="avatar-dropdown img-respone" src={user.file ? (
                  user.file.url
                ) : (
                    userNew.avatar ? (
                      pathImage(userNew.avatar)
                    ) : avatarDefaultIcon
                  )} />
                <div className="wrap-upload-photo">
                  <input type='file' className="upload-photo cursor-pointer" onChange={(evt) => {
                    this.addImage(evt);
                    evt.preventDefault();
                  }} />
                  <span>{I18n.t('Profile.UploadPhoto')}</span>
                </div>
              </div>
              <div className="col-md-9 col-xs-12 wrap-form pd-0">
                <div className="form-group">
                  <div className="col-md-4 col-xs-12 text">
                    <p>
                      {I18n.t('Profile.FirstName')}
                    </p>
                  </div>
                  <div className="col-md-8 col-xs-12 value">
                    <input value={userNew ? userNew.firstName : ''} onChange={(evt) => { this.setUser('firstName', evt.target.value) }} type='text' className='form-control' name='add-first-name' placeholder={userNew ? userNew.firstName : ''} />
                    {this.state.errors['firstName'] ? (<span className='alert-warning'>{this.state.errors['firstName']}</span>) : ''}
                  </div>
                </div>

                <div className="form-group">
                  <div className="col-md-4 col-xs-12 text">
                    <p>
                      {I18n.t('Profile.LastName')}
                    </p>
                  </div>
                  <div className="col-md-8 col-xs-12 value">
                    <input value={userNew ? userNew.lastName : ''} onChange={(evt) => { this.setUser('lastName', evt.target.value) }} type='text' className='form-control' name='add-last-name' placeholder={userNew ? userNew.lastName : ''} />
                    {this.state.errors['lastName'] ? (<span className='alert-warning'>{this.state.errors['lastName']}</span>) : ''}
                  </div>
                </div>

                <div className="form-group">
                  <div className="col-md-4 col-xs-12 text">
                    <p>
                      {I18n.t('Profile.Sex')}
                    </p>
                  </div>
                  <div className="col-md-8 col-xs-12 value">
                    <Select onChange={(select) => { this.setUser('gender', select) }} options={{ value: (userNew.gender ? userNew.gender : 'Female'), options: [{ value: 'Female', text: 'Female' }, { value: 'Male', text: 'Male' }] }} />
                  </div>
                </div>

                <div className="form-group">
                  <div className="col-md-4 col-xs-12 text">
                    <p>
                      {I18n.t('Profile.Phone')}
                    </p>
                  </div>
                  <div className="col-md-8 col-xs-12 value">
                    <ReactTelephoneInput className='w-full'
                      defaultCountry='us'
                      autoFormat={false}
                      flagsImagePath={flagsIcon}
                      value={userNew.phone ? userNew.phone : ''}
                      onChange={(phone) => this.setUser('phone', phone)}
                    />
                  </div>
                </div>

                <div className="form-group">
                  <div className="col-md-4 col-xs-12 text">
                    <p>
                      {I18n.t('Profile.Email')}
                    </p>
                  </div>
                  <div className="col-md-8 col-xs-12 value">
                    <input value={(userNew.email ? userNew.email : userNew.email)} onChange={(evt) => { this.setUser('email', evt.target.value) }} type='email' className='form-control' name='add-email' placeholder='Email' disabled />
                    {this.state.errors['email'] ? (<span className='alert-warning'>{this.state.errors['email']}</span>) : ''}
                  </div>
                </div>
              </div>

            </div>
            <div className="modal-footer inline-block w-full">
              <button onClick={() => this.close()} type="button" className="bt-black" data-dismiss="modal">{I18n.t('Btn.CANCEL')}</button>
              <button onClick={() => this.save()} type="button" className="bt-orange mg-l-5" data-dismiss="modal">{I18n.t('Btn.OK')}</button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    appState: state.appState,
    authState: state.authState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    authPropsActions: bindActionCreators(authActions, dispatch)
  };
}

EditProfile.propTypes = {
  authState: PropTypes.object.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);
