import {getListTrimDAL, addTrimDAL, editTrimDAL, getTrimDAL, getTrimByKeyDAL, deleteTrimDAL} from '../api/trimApi';
import _ from 'lodash';

class trimServices {

  constructor() {
  }

  getListTrim (options = {}) {
    options.populate = (options.populate ? `${options.populate}` : '');
    options.sort = options.sort || {createdAt: -1};
    options.query = '{"type":"TRIM"}';
    _.set(options, 'sort', JSON.stringify(options.sort));

    return getListTrimDAL(options);
  }

  getDetail (id) {
    return getTrimDAL(id, {});
  }

  getDetailByKey (key) {
    return getTrimByKeyDAL(key, {});
  }

  deleteTrim (id) {
    return deleteTrimDAL(id);
  }

  editTrim (id, data) {
    return editTrimDAL(id, data);
  }

  addTrim (data) {
    data.type = 'TRIM';
    return addTrimDAL(data);
  }
}

export default new trimServices();
