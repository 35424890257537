import Base from './base';

class ShipOut extends Base {
    constructor () {
        super();
    }

    // PKL of Production
    getListPklOfProduction = (options) => {
        return super.execute(this.Urls.shipOut.pklOfProduction.listPklOfProduction, this.ApiMethod.get, options)
    }

    search = (options) => {
        return super.execute(this.Urls.shipOut.pklOfProduction.listPklOfProduction, this.ApiMethod.get, options)
    }

    getListPackingDetail = (data) => {
        return super.execute(this.Urls.shipOut.pklOfProduction.previewSelectedPklOfProdList, this.ApiMethod.post, data)
    }

    searchPreviewPklProduction = (data) => {
        return super.execute(this.Urls.shipOut.pklOfProduction.previewSelectedPklOfProdList, this.ApiMethod.post, data)
    }

    getPackingDetail = (op) => {
        return super.execute(this.Urls.shipOut.pklOfProduction.getPackingDetail, this.ApiMethod.post, op)
    }

    createPklShip = (data) => {
        return super.execute(this.Urls.shipOut.pklOfProduction.createPklShip, this.ApiMethod.post, data)
    }

    // Pkl of Ship

    getListPklShip = (options) => {
        return super.execute(this.Urls.shipOut.pklShip.listPklShip, this.ApiMethod.get, options)
    }

    findBillNo = (billNo) => {
        return super.execute(this.Urls.shipOut.pklShip.findBillNo, this.ApiMethod.get, {billNo: billNo})
    }

    searchPklShip = (options) => {
        return super.execute(this.Urls.shipOut.pklShip.listPklShip, this.ApiMethod.get, options)
    }
    
    getPklShipDetail = (data) => {
        return super.execute(this.Urls.shipOut.pklShip.pklShipDetail, this.ApiMethod.post, data)
    }

    getListForwarder = () => {
        return super.execute(this.Urls.shipOut.pklShip.listForwarder, this.ApiMethod.get)
    }
    
    // Create Invoice

    createINV = (data) => {
        return super.execute(this.Urls.shipOut.pklShip.createINV, this.ApiMethod.post, data)
    }

    getShipInvDetail = (id) => {
        return super.execute(this.Urls.shipOut.pklShip.getShipInv + '/' + id, this.ApiMethod.get)
    }

    updateINV = (id, data) => {
        return super.execute(this.Urls.shipOut.pklShip.updateInv + '/' + id, this.ApiMethod.put, data)
    }

    // Edit Pkl Ship

    updatePklShip = (id, data) => {
        return super.execute(this.Urls.shipOut.pklShip.updatePklShip + '/' + id, this.ApiMethod.put, data)
    }

    //Create Shipment

    createShipment = (data) => {
        return super.execute(this.Urls.shipOut.pklShip.createShipment, this.ApiMethod.post, data)
    }

    deletePklShipOut = (id) => {
        return super.execute(this.Urls.shipOut.pklShip.deletePklShip+ '/' + id, this.ApiMethod.delete)
    }

    // Ship out

    getListShipOut = (options) => {
        return super.execute(this.Urls.shipOut.shipOut.listShipOut, this.ApiMethod.get, options)
    }

    searchShipOut = (options) => {
        return super.execute(this.Urls.shipOut.shipOut.listShipOut, this.ApiMethod.get, options)
    }

    getShipmentDetail = (options) => {
        return super.execute(this.Urls.shipOut.shipOut.shipmentDetail, this.ApiMethod.post, options)
    }

    deleteShipment = (id) => {
        return super.execute(this.Urls.shipOut.shipOut.deleteShipment + '/' + id, this.ApiMethod.delete)
    }

    updateShipment = (id, data) => {
        return super.execute(this.Urls.shipOut.shipOut.updateShipment + '/' + id, this.ApiMethod.put, data)
    }
    // History

    getListHistory = (options) => {
        return super.execute(this.Urls.shipOut.history.listHistory, this.ApiMethod.get, options)
    }

    getExportShipOut = (options) => {
        const params =_.map(options, (v, k) => `${k}=${v}`).join('&');
        return this.Urls.shipOut.export.exportShipOut+`?${params}`
    }

    getExportPKLOfProductPreview = (ids) => {
        const params =_.join(ids, ',');
        return this.Urls.shipOut.export.exportPKLOfProductPreview+`?pklShipIds=${params}`
    }

    getExportCustomINV = (id) => {
        return this.Urls.shipOut.export.exportCustomINV.replace('{shipoutId}', id);
    }

    getExportShipoutPKL = (id) => {
        return this.Urls.shipOut.export.exportShipoutPKL.replace('{pklId}', id);
    }

    getExportShipoutAPL = (id) => {
        return this.Urls.shipOut.export.exportShipoutAPL.replace('{pklId}', id);
    }
}

export default new ShipOut();
