import { createSelector } from 'reselect';
import _ from 'lodash';

export const getTotal = ({createShipment}) => {
    const sum = createShipment.listOrders ? _.sum(createShipment.listOrders.map(item => _.sum(item.styles.map(style => style.quantity || 0)))) : 0;
    return sum;
}

export const getShipmentInfo = ({ createShipment }) => createShipment.shipmentInfo && createShipment.shipmentInfo

export const getShipMode = ({ createShipment }) => {
    if(createShipment.standard) {
        let temp = [];
        _.map(createShipment.standard.shipping, (v, k) => {
            if(k === 'other') {
                v.map(item => temp.findIndex(it => it == item.shipMod) == -1 && temp.push(item.shipMod));
                return;
            }
            temp.findIndex(it => it == k) == -1 && temp.push(_.toUpper(k));
        });
        return temp;
    }
}

export const getContType = ({ createShipment }) => createShipment.standard && createShipment.standard.containerType

export const fnGetShipAgencies = ({ createShipment }) => shipMode => {
    if(createShipment.standard) {
        const key = _.toLower(shipMode);
        if(key == 'air' || key == 'sea') {
            return createShipment.standard.shipping[key];
        }
        else {
            const result = []
            _.map(createShipment.standard.shipping.other, item => (item.shipMod) == shipMode && result.push(item.shipAgency))
            return result;
        }
    }
}

export const getTransportFee = ({ createShipment }) => createShipment.transportFee && createShipment.transportFee;

export const getCustomerINV = ({ createShipment }) => {
    if(createShipment.pklShips) {
        return createShipment.pklShips.map(item => ({customerInv: item.invoice.customerInv, pklShipId: item._id}))
    }
}

export const fnGetPklShipDetail = ({ createShipment }) => id => {
    if(createShipment.pklShips) {
        const result = createShipment.pklShips.find(item => item._id === id)
        return result
    }
}

export const getOrders = ({ createShipment }) => {
    const { activeOrders, listOrders } = createShipment;
    const orders = listOrders && listOrders.map(it => {
        const _id = it._id
        const { customerPO } = it;
        const result = {_id, name: customerPO, active: activeOrders.findIndex(item => item == _id) >= 0, commodity: it.commodity};
        return result;
    });
    return orders;
}

export const fnGetStyles = ({createShipment}) => id => {
    const { activeStyles, listOrders } = createShipment;
    const styles = [];
    listOrders && listOrders.map(it => {
        if(it._id === id) {
            it.styles.map(s => {
                const { _id, style, quantity } = s;
                const result = {_id, name: style, active: activeStyles[id] == _id, quantity};
                styles.push(result);
            })
        }
    })
    return styles
}

export const getActiveStyleIds = ({ createShipment }) => createShipment.activeStyles;

export const fnGetListData = ({createShipment}) => (orderId, styleId) => {
    if(createShipment.dataInputs) {
        const data = createShipment.dataInputs[`${orderId}:${styleId}`];
        return data
    }
}

export const fnGenApiData = ({createShipment}) => {
    const {pklShips, dataInputs, shipmentInfo, transportFee} = createShipment;
    const temp = (pklShips || []).map(pklShip => {
        return {
            pklShip: pklShip._id,
            shipOutInvoices: _.map(pklShip.pklProds, item => {
                return {
                _id: item._id,
                styles: item.styles.map(style => {
                    return {
                        style: style.style._id,
                        cutTickets: style.cutTickets.map(cut => {
                            return {
                                cutTicket: cut.cutTicket._id,
                                colors: cut.colors.map(color => {
                                    const data = ((dataInputs[`${item._id}:${style.style._id}`] || []).find(it => it.cutTicketId == cut.cutTicket._id && it.colorId == color.color._id) || {})
                                    return {
                                        color: color.color._id,
                                        quantity: data.quantity,
                                        price: data.price,
                                        unitPrice: data.unitPrice,
                                    }
                                })
                            }
                        })
                    }})
                }})
            }
        }
        )
    const data = {
        pklShips: temp,
        ...shipmentInfo,
        ...transportFee
    }
    return data;
}

export const getSuccess = ({createShipment}) => createShipment.success;

export const getEmptyField = createSelector (
    [
        getShipmentInfo
    ], (data) => {
        if(data) {
            if(data.customInv == '') return 'customINV';
            if (data.shipMode == '') return 'shipMode';
            if(data.shipMode == 'SEA' && data.cont == '') return 'cont';
            if(data.shipMode == 'SEA' && data.seal == '') return 'seal';
            if(data.shipMode == 'SEA' && data.contType == '') return 'contType';
            if(data.declarationNo == '') return 'declarationNo';
            if(data.forwarder == '') return 'forwarder';
            if(data.billNo == '') return 'billNo';
        }
        return '';
    }
)

export const getListForwarder = ({createShipment}) => {
    if(createShipment.listForwarder) {
        return createShipment.listForwarder.map(item => ({value: item._id, label: item.forwarder}))
    }
}

export const getActiveScreenCreateShipment = ({createShipment}) => createShipment.activeScreenCreateShipment;