import * as types from '../constants/actionTypes';
import {showLoading, hideLoading} from './commonUseActions';
import packingStandardServices from '../services/packingStandardServices';


const getListPackingStandard = (options = {}) => dispatch => {
  showLoading(types, dispatch)
  packingStandardServices
    .getListPackingStandard(options)
    .then(res => dispatch(
      {
        type: types.PACKING_STANDARD.GET_LIST_SUCCESS,
        data: res.data || { count: 0, items: [] }
      }
    ))
    .finally(() => hideLoading(types, dispatch))
}

function deleteItem (id) {
  return (dispatch) => {
    showLoading(types, dispatch);
    return packingStandardServices.deleteItem(id).then((res) => {
      hideLoading(types, dispatch, (res && res.error ? res.error : undefined));
      if (res) {
        dispatch({
          type: types.PACKING_STANDARD.RELOAD,
          data: true,
        });
      }
      return res;
    }, (error) => {
      hideLoading(types, dispatch, error);
    });
  };
}

function addItem (data) {
  return (dispatch) => {
    showLoading(types, dispatch);
    return packingStandardServices.addItem(data).then((res) => {
      hideLoading(types, dispatch);
      if (res) {
        dispatch({
          type: types.PACKING_STANDARD.RELOAD,
          data: true,
        });
      }
      return res;
    }, (error) => {
      hideLoading(types, dispatch, error);
    });
  };
}
function updateItem (id, data) {
  return (dispatch) => {
    showLoading(types, dispatch);

    return packingStandardServices.updateItem(id, data).then((res) => {
      hideLoading(types, dispatch);
      if (res) {
        dispatch({
          type: types.PACKING_STANDARD.RELOAD,
          data: true,
        });
      }
      return res;
    }, (error) => {
      hideLoading(types, dispatch, error);
    });
  };
}


export default {
  getListPackingStandard,
  deleteItem,
  addItem,
  updateItem
};
