import React, { Component } from 'react';
import { Store } from '../../../../CssHandleModule/functions'
import * as _ from 'lodash';
import I18n from 'i18n-js';
import ColorTable from '../../../../components/shipping/ship-out/pklShip/ColorTableEdit';
import { connect } from 'react-redux';
import * as selector from "../../../../../selectors/ship-out/editPklShip";


const store = new Store();
store.setStyles({
    orderNumbers: [1, 2],
    aligns:[
        {order: 1, margin: {left: '10px'}},
        {order: 2, margin: {right: '10px'}}
    ]
});

class cutTicketContainer extends Component {

    constructor(props) {
        super(props);
        
    }
    
    //This function use for get colors in a cut ticket
    componentWillReceiveProps(nextProps) {
    }

    //This function use for handle color item and send the end result forward to color component
    setCutTicketComponents = (colors, orderId, styleId, cutId, packingType) => {

        const colorCoponents = colors && colors.map((color, index) => {
            return <ColorTable {...this.props} key={index} data={color} packingType = {packingType} orderId={orderId} styleId={styleId} cutId={cutId}/>
        })
        return colorCoponents;
    }

    render() {
        const { cutTicket, colors, styleId, packingType, orderId, view} = this.props;
        const colorComponents = cutTicket && this.setCutTicketComponents(colors, orderId, styleId, cutTicket._id, packingType, view);
        return (
            <div>
                <div className="d-flex justify-content-between pd-10">
                    <div className="d-flex">
                        <p className="size-12">{I18n.t('PageShiping.CutTicket')}</p>
                        <span className="color-orange bold mg-left-50 size-14">{cutTicket.cutTicket || ''}</span>
                    </div>

                    <div className="d-flex justify-content-end">
                        <div className="d-flex mg-right-30">
                            <p className="size-12 mg-right-15">{I18n.t('PageShiping.Lable')}</p>
                            <span className="size-12 bold">{cutTicket.mainLabel || ''}</span>
                        </div>
                        <div className="d-flex mg-right-30">
                            <p className="size-12 mg-right-15">{I18n.t('PageShiping.Store')}</p>
                            <span className="size-12 bold">{cutTicket.store && cutTicket.store.name || ''}</span>
                        </div>
                        <div className="size-12 color-link"><a>{cutTicket.store && cutTicket.store.packingStandard && cutTicket.store.packingStandard.name || '-'}</a></div>
                    </div>
                </div>

                {colorComponents}
            </div>
        );
    }
}

const mapStateToProps = state => ({
})
export default connect(mapStateToProps, null) (cutTicketContainer);