import React from "react";
import PropTypes from "prop-types";
import I18n from "i18n-js";
import _ from 'lodash';
import * as R from 'ramda';
import DateTimeField from 'react-datetime';
import moment from 'moment';
import Modal from "../forms/Modal";


const EditOrderModal = props => (
    <Modal {...props}>
        <div className="modal-dialog" role="document">
            <div className="modal-content">
                <form>
                    <div className="modal-header">
                        <a
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={() => props.onCloseClick()}
                        >
                            <span aria-hidden="true">&times;</span>
                        </a>
                        <h4 className="modal-title" id="myModalLabel">
                            {props.title}
                        </h4>
                    </div>
                    <div className="modal-body">
                        <div className="form-group">
                            <div className="col-md-4 col-xs-12 text">
                                <p>{I18n.t("PageOrder.CustomerPO")}</p> <span className="color-red"> *</span>
                            </div>
                            <div className="col-md-8 col-xs-12 value">
                                <input
                                    type="text"
                                    className="form-control"
                                    value={props.customerPO || ""}
                                    onChange={evt =>
                                        props.onChangeCustomerPO(evt.target.value)
                                    }
                                    placeholder={I18n.t("PageOrder.CustomerPO")}
                                />
                                {R.path(['customerPO'], props.errors) && (
                                    <div className="alert-warning">{R.path(['customerPO'], props.errors)}</div>
                                )}
                            </div>
                        </div>

                        <div className="form-group">
                            <div className="col-md-4 col-xs-12 text">
                                <p>{I18n.t("PageOrder.Customer")}</p> <span className="color-red"> *</span>
                            </div>
                            <div className="col-md-8 col-xs-12 value">
                                <select
                                    className="form-control"
                                    value={props.selectedCustomer || ""}
                                    onChange={evt => {
                                        const { value } = evt.target;
                                        const item = _.find(props.selectableCustomers, it => it.value === value);
                                        props.onChangeSelectedCustomer(item);
                                    }}
                                >
                                    <option value="" />
                                    {_.map(props.selectableCustomers || [], item => (
                                        <option key={R.path(['value'], item)} value={R.path(['value'], item)}>
                                            {R.path(['label'], item)}
                                        </option>
                                    ))}
                                </select>

                                {R.path(['selectedCustomer'], props.errors) && (
                                    <div className="alert-warning">{R.path(['selectedCustomer'], props.errors)}</div>
                                )}
                            </div>
                        </div>

                        <div className="form-group">
                            <div className="col-md-4 col-xs-12 text">
                                <p>{I18n.t("PageOrder.OrderDate")}</p>
                            </div>
                            <div className="col-md-8 col-xs-12 value">
                                <DateTimeField
                                    timeFormat={false}
                                    dateFormat="MMMM DD, YYYY"
                                    value={moment(props.orderDate)}
                                    inputProps={{readOnly: true}}
                                    onChange={date =>
                                        props.onChangeOrderDate(date.format())
                                    }
                                />
                            </div>
                        </div>

                        <div className="form-group">
                            <div className="col-md-4 col-xs-12 text">
                                <p>{I18n.t("PageOrder.Ex-Factory")}</p>
                            </div>
                            <div className="col-md-8 col-xs-12 value">
                                <DateTimeField
                                    timeFormat={false}
                                    dateFormat="MMMM DD, YYYY"
                                    value={moment(props.exFactory)}
                                    inputProps={{readOnly: true}}
                                    onChange={date =>
                                        props.onChangeExFactory(date.format())
                                    }
                                />
                            </div>
                        </div>

                        <div className="form-group">
                            <div className="col-md-4 col-xs-12 text">
                                <p>{I18n.t("PageOrder.IHDate")}</p>
                            </div>
                            <div className="col-md-8 col-xs-12 value">
                                <DateTimeField
                                    timeFormat={false}
                                    dateFormat="MMMM DD, YYYY"
                                    value={moment(props.ihDate)}
                                    inputProps={{readOnly: true}}
                                    onChange={date =>
                                        props.onChangeIhDate(date.format())
                                    }
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="col-md-4 col-xs-12 text">
                                <p>{I18n.t("PageOrder.Description")}</p>
                            </div>
                            <div className="col-md-8 col-xs-12 value">
                <textarea
                    type="text"
                    className="form-control"
                    value={props.description || ""}
                    onChange={evt =>
                        props.onChangeDescription(evt.target.value)
                    }
                />
                            </div>
                        </div>

                        {props.showAdvance && (
                            <div>
                                <div className="form-group">
                                    <div className="col-md-4 col-xs-12 text" />
                                    {props.canComplete && (
                                        <div className="col-md-8 col-xs-12 value">
                                            <div className="style-checkbox">
                                                <input
                                                    type="checkbox"
                                                    id="view_5982c1c3cabed098307c2078"
                                                    value="on"
                                                    checked={props.isCompleted}
                                                    onChange={evt =>
                                                        props.onChangeCompleteted(evt.target.checked)
                                                    }
                                                />
                                                <label htmlFor="view_5982c1c3cabed098307c2078">
                        <span className="">
                          <span className="mg-left-25 line-h-15 position-absolute top-0">
                            {I18n.t("PageOrder.Completed")}
                          </span>
                        </span>
                                                </label>
                                            </div>
                                        </div>
                                    )}
                                </div>

                                {props.isCompleted && (
                                    <div className="form-group mg-0">
                                        <div className="col-md-4 col-xs-12 text">
                                            <p>{I18n.t("PageOrder.Note")}</p>
                                        </div>
                                        <div className="col-md-8 col-xs-12 value">
                      <textarea
                          type="text"
                          className="form-control"
                          value={props.note || ""}
                          onChange={evt =>
                              props.onChangeNote(evt.target.value)
                          }
                      />
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="bt-black"
                            onClick={() => props.onCloseClick()}
                        >
                            {I18n.t("Btn.CANCEL")}
                        </button>
                        <button
                            type="button"
                            disabled={!props.canSave}
                            className={`${!props.canSave ? 'btn btn-default': ''} bt-orange mg-l-5`}
                            onClick={() => props.onSubmitClick()}
                        >
                            {props.submiting ? I18n.t("Btn.SAVING") : I18n.t("Btn.SAVE")}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </Modal>
);

EditOrderModal.propTypes = {
    title: PropTypes.string.isRequired,
    customerPO: PropTypes.string.isRequired,
    selectedCustomer: PropTypes.string.isRequired,
    selectableCustomers: PropTypes.arrayOf(PropTypes.any).isRequired,
    orderDate: PropTypes.string.isRequired,
    exFactory: PropTypes.string.isRequired,
    ihDate: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    isCompleted: PropTypes.bool.isRequired,
    note: PropTypes.string.isRequired,
    errors: PropTypes.objectOf(PropTypes.any).isRequired,
    canSave: PropTypes.bool,
    submiting: PropTypes.bool,
    showAdvance: PropTypes.bool,

    onCloseClick: PropTypes.func,
    onSubmitClick: PropTypes.func,
    onChangeCustomerPO: PropTypes.func,
    onChangeSelectedCustomer: PropTypes.func,
    onChangeOrderDate: PropTypes.func,
    onChangeExFactory: PropTypes.func,
    onChangeIhDate: PropTypes.func,
    onChangeDescription: PropTypes.func,
    onChangeCompleteted: PropTypes.func,
    onChangeNote: PropTypes.func,
};

EditOrderModal.defaultProps = {
    showAdvance: false,
    submiting: false,
    canSave: true,

    onCloseClick: f => f,
    onSubmitClick: f => f,
    onChangeCustomerPO: f => f,
    onChangeSelectedCustomer: f => f,
    onChangeOrderDate: f => f,
    onChangeExFactory: f => f,
    onChangeIhDate: f => f,
    onChangeDescription: f => f,
    onChangeCompleteted: f => f,
    onChangeNote: f => f,
};

export default EditOrderModal;
