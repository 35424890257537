import {
    getListFollowUp,
    createShipment,
    editShipment,
    getPurchaseOrderList,
    getShipInList,
    getHistoryList,

} from '../api/shippingApi';

class ShippingServices {

  constructor() {
  }

  getListFollowUp(options){
    return getListFollowUp(options).then((res) => {
        return res
    })
  }

  createShipment(body){
    return createShipment(body).then((res) => {
      return res
    })
  }

  editShipment(id, body){
    return editShipment(id, body).then((res) => {
      return res
    })
  }

  getPurchaseOrderList(body){
    return getPurchaseOrderList(body).then((res) => {
      return res
    })
  }

  getShipInList(body)
  {
    return getShipInList(body).then((res) => {
      return res
    })
  }

  getHistoryList()
  {
    return getHistoryList().then((res) => {
      return res
    })
  }

}

export default new ShippingServices();
