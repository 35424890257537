import {STOCK_IN} from '../constants/actionTypes';
import initial from './initialState';


export default function (state = initial.stockInState, action) {
  let update = null;
  let keys = Object.keys(initial.stockInState);

  switch (action.type) {
    case STOCK_IN.GET_LIST_SUCCESS: {
      update = keys.indexOf(action.typeStockIn) !== -1 ? {...state[action.typeStockIn], items: action.data.items || [], count: action.data.count || 0} : null;
      return update ? {...state, [action.typeStockIn]: update} : state;
    }
    case STOCK_IN.RELOAD: {
      update = keys.indexOf(action.typeStockIn) !== -1 ? {...state[action.typeStockIn], reload: !state[action.typeStockIn].reload} : null;
      return update ? {...state, [action.typeStockIn]: update} : state;
    }
    case STOCK_IN.GET_DETAIL_SUCCESS: {
      update = keys.indexOf(action.typeStockIn) !== -1 ? {...state[action.typeStockIn], detail: action.data} : null;
      return update ? {...state, [action.typeStockIn]: update} : state;
    }
    case STOCK_IN.SET_DETAIL: {
      update = keys.indexOf(action.typeStockIn) !== -1 ? {...state[action.typeStockIn], detail: action.data} : null;
      return update ? {...state, [action.typeStockIn]: update} : state;
    }
    case STOCK_IN.RESET_DETAIL: {
      update = keys.indexOf(action.typeStockIn) !== -1 ? {...state[action.typeStockIn], detail: {}} : null;
      return update ? {...state, [action.typeStockIn]: update} : state;
    }
    default:
      return state;
  }
}
