import _ from 'lodash';
import * as R from 'ramda';
import { createSelector } from 'reselect';


export const getPageNumber = ({ qaOrderState }) => qaOrderState.pageNumber;
export const getFilterText = ({ qaOrderState }) => qaOrderState.filterText;
export const isOpenLoadMore = ({ qaOrderState }) => qaOrderState.isLoadMore;
export const isSearch = ({ qaOrderState }) => qaOrderState.isSearch;
export const getOrders = ({ qaOrderState }) => qaOrderState.items;
export const isLoading = ({ qaOrderState }) => qaOrderState.loading;
