import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
const DateTimeField = require('react-datetime');
import _ from 'lodash';
import moment from 'moment';
import I18n from 'i18n-js';
import {SEARCH_BAR_EVENT} from "../../../constants/constants";

import icSearchIcon from '../../../stylesheets/images/ic-search.svg';
import icAddIcon from '../../../stylesheets/images/ic-add.svg';

class SearchBar extends Component {
  constructor (props, context) {
    super(props, context);

    this.state = {
      filter: '',
      search: this.props.searchText || '',
      fields: []
    };
  }

  onChange (property, value, pos = '') {
    property === SEARCH_BAR_EVENT.FILTER ? this.setState({filter: value, search: ''}) : this.setState({search: value});
    this.props.onChangeSearch && this.props.onChangeSearch(property, value, pos);
  }

  getSearchField (searchText) {
    let {filter, search} = this.state;
    const {fields} = this.props;
    let field = fields.find(i => i.field === filter) || {};

    switch (field.type) {
      case 'date':
        return (
          <div className="wrap-search">
            <p>{I18n.t('PageWareHouse.Search')}</p>
            <div className="date-pick-search">
              <DateTimeField timeFormat={false} closeOnSelect dateFormat="MMM DD, YYYY" onChange={date => this.onChange(SEARCH_BAR_EVENT.SEARCH, date.format())} />
            </div>
            <span className="icon-search"><img src={icSearchIcon} alt="" /></span>
            <button type="button" onClick={() => this.props.onSearch()} className="transform bt-orange">Search</button>
          </div>
        );
      case 'dateTo':
        return (
          <div className="wrap-search from-to" key={`datato_${field.name}`}>
            <div className="date-pick-search">
            <ul>
              <li>
                <div className="title-date">From:</div> 
                <div className="wrap-input-date"><DateTimeField timeFormat={false} closeOnSelect dateFormat="MMM DD, YYYY" onChange={date => this.onChange(SEARCH_BAR_EVENT.SEARCH, date.format(),'from')} /></div>
              </li>
              <li>
                <div className="title-date">To:</div> 
                <div className="wrap-input-date"><DateTimeField timeFormat={false} closeOnSelect dateFormat="MMM DD, YYYY" onChange={date => this.onChange(SEARCH_BAR_EVENT.SEARCH, date.format(),'to')} /></div></li>
            </ul>
            </div>
            <button type="button" onClick={() => this.props.onSearch()} className="transform bt-orange">Search</button>
          </div>
        );
      case 'enum':
        return (
          <div className="wrap-search">
            <p>{I18n.t('PageWareHouse.Search')}</p>
            <div className="select-pick-search">
              <select className="form-control font-s-13" value={search || ''} onChange={evt => this.onChange(SEARCH_BAR_EVENT.SEARCH, evt.target.value)}>
              {(field.values || []).map((i, idx) => (<option key={idx} value={i.key}>{i.value}</option>))}
              </select>
            </div>
            <span className="icon-search"><img src={icSearchIcon} alt="" /></span>
            <button type="button" onClick={() => this.props.onSearch()} className="transform bt-orange">Search</button>
          </div>
        );
      default:
        return (
          <div className="wrap-search">
            <p>{I18n.t('PageWareHouse.Search')}</p>
            <input type="text" onKeyPress={evt => evt.key === 'Enter' && this.props.onSearch()} placeholder="Search" value={searchText != undefined ? searchText : search || ''} onChange={evt => this.onChange(SEARCH_BAR_EVENT.SEARCH, evt.target.value)} className="form-control" />
            <span className="icon-search"><img src={icSearchIcon} alt="" /></span>
            <button type="button" onClick={() => this.props.onSearch()} className="transform bt-orange">Search</button>
          </div>
      );
    }
  }

  render () {
    const {name, addItem, fields, searchText, style} = this.props;
    let {filter} = this.state;
    return (
      <div className="wrap-filter-search" style={style}>
        {_.isFunction(addItem) && (
          <div className="wrap-stock-in">
            <span className="cursor-pointer" onClick={() => _.isFunction(addItem) && addItem()}><img src={icAddIcon} alt="" /> {name}</span>
          </div>
        )}
        {this.getSearchField(searchText)}
        <div className="wrap-filter">
          <p>{I18n.t('PageWareHouse.Filter')}</p>
          <select className="form-control" value={filter || ''} onChange={evt => this.onChange(SEARCH_BAR_EVENT.FILTER, evt.target.value)} >
            {
              fields.map((i, idx) => (<option key={idx} value={i.field}>{I18n.t(i.name)}</option>))
            }
          </select>
        </div>
      </div>
    );
  }
}

function mapStateToProps (state, ownProps) {
  return {
    authState: state.authState
  };
}

function mapDispatchToProps (dispatch) {
  return {};
}

SearchBar.propTypes = {
  authState: PropTypes.object.isRequired,
  onChangeSearch: PropTypes.func,
  onSearch: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar);
