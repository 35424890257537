import {create} from 'apisauce';
import Urls from '../constants/apiUrls';
import {getAccessToken} from '../functions/helpers';

const uploadFile = (file) => {
  let token = getAccessToken();
  const headers = {
    'Content-Type': 'application/json',
    'x-access-token': token
  };
  const timeout = 50000;

  let request = create({
    baseURL: Urls.baseUrl,
    headers: headers,
    timeout: timeout
  });

  let body = new FormData();
  body.append('file', file);

  return request.post(Urls.upload, body).then(res => {
    return res.data || {};
  });
}
export default uploadFile;
