import * as types from '../constants/actionTypes';

function hideAlert () {
  return (dispatch) => {
    dispatch({
      type: types.APP.HIDE_ALERT,
      data: null
    });
  };
}

function setDataPageCurrent (data) {
  return (dispatch) => {
    dispatch({
      type: types.APP.SET_DATA_PAGE_CURRENT,
      data: data
    });
  };
}

function setPageCurrent(data){
  return (dispatch) => {
    dispatch({
      type: types.APP.SET_PAGE_CURRENT,
      data: data
    });
  }
}

function addPage(data){
  return (dispatch) => {
    dispatch({
      type: types.APP.ADD_PAGE_CURRENT,
      data: data
    });
  }
}

export default {
  hideAlert,
  setDataPageCurrent,
  setPageCurrent,
  addPage,
};
