import React from "react";
import PropTypes from 'prop-types';
import * as R from 'ramda';
import _ from 'lodash';
import I18n from 'i18n-js';

import Autocomplete from 'react-autocomplete';
import Modal from "../forms/Modal";

import '../forms/autocomplete.css';

import icAddOrderIcon from '../../../stylesheets/images/ic-add-order.svg';
import icEditRIcon from '../../../stylesheets/images/ic-edit-r.svg';

const EditCutTicketColorModal = props => (
  <Modal isOpen={props.isOpen}>
    <div className="modal-dialog lg" role="document">
      <div className="modal-content">
          <div className="modal-header">
            <span
              className="icon-add-cut-ticket-color"
            >
              <span
                className="cursor-pointer"
                onClick={props.addColor}
              >
                <img
                  src={icAddOrderIcon}
                />{" "}
                {I18n.t("PageOrder.AddColor")}
              </span>
            </span>
            <h4 className="modal-title" id="myModalLabel">
              {props.title}
            </h4>
          </div>

          <div className="modal-body" id="wrap-modal-add-color">
            {_.map(props.colors, color => (
              <div className="wrap-add-color inline-block w-full" key={R.path(['_id'], color)}>
                <div className="items">
                  {props.canRemove && (
                    <div className="closes">
                      <span
                        className="cursor-pointer"
                        onClick={() => props.onRemoveColor(color)}
                      >
                        <img
                          src={icEditRIcon}
                        />
                      </span>
                    </div>
                  )}

                  <div className="select-color">
                    <span>{I18n.t("PageOrder.Color")}</span>
                    <Autocomplete
                      className="form-control"
                      renderInput={p => (
                        <input {...p}
                          className="dropdown-toggle form-control input-lg size-12 color-black medium"
                        />
                      )}
                      value={R.path(['name'], color)}
                      items={_.uniq(props.selectableColors) || []}
                      getItemValue={(item) => item}
                      onChange={(e, value) => props.onChangeColorName(color, value)}
                      shouldItemRender={(item, value) => !!item.toLowerCase().includes(value.toLowerCase())}
                      onSelect={value => props.onChangeColorName(color, value)}
                      renderMenu={children => (
                        <div className="autocomplete-menu size-12 m-width-150 hidden-menu">
                          {children}
                        </div>
                      )}
                      renderItem={(item, isHighlighted) => (
                        <div
                          className={`autocomplete-item ${isHighlighted ? 'autocomplete-item-highlighted' : ''}`}
                          key={item}
                        >{item}</div>
                      )}
                    />
                  </div>

                  <div className="number">
                    <span>
                      {R.path(['totalQuantity'], color)}
                    </span>
                  </div>
                  <div className="size">
                    <ul>
                      {_.map(R.pathOr([], ['sizes'], color), size => (
                        <li key={R.path(['name'], size)}>
                          <span className="text-size">{R.path(['name'], size)}</span>
                          <input
                            type="text"
                            value={R.path(['quantity'], size)}
                            onChange={evt => {
                              props.onChangeSize(color, size, evt.target.value);
                            }}
                          />
                        </li>
                      ))}

                      <li className="add-size">
                        <span>{I18n.t("PageOrder.Size")}</span>
                        <div className="dropdown">
                          <button
                            className="btn btn-primary dropdown-toggle"
                            type="button"
                            data-toggle="dropdown"
                          >
                            <i className="fa fa-plus" />
                          </button>
                          <ul className="dropdown-menu">
                            {_.map(R.pathOr([], ['checkableSizes'], color), it => (
                              <li key={R.path(['value'], it)} className="test">
                                <input
                                  type="checkbox"
                                  className="cursor-pointer"
                                  checked={R.path(['checked'], it)}
                                  onChange={evt =>
                                    props.changeCheckedSize(color, it, evt.target.checked)
                                  }
                                />
                                Size {R.path(['label'], it)}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="bt-black"
              onClick={() => props.onClose()}
            >
              {I18n.t("Btn.CANCEL")}
            </button>
            <button
              type="button"
              disabled={!props.canSave}
              className={`${!props.canSave ? 'btn btn-default' : ''} bt-orange mg-l-5`}
              onClick={() => props.onSubmit()}
            >
              {props.isSubmiting ? I18n.t("Btn.SAVING") : I18n.t("Btn.SAVE")}
            </button>
          </div>
      </div>
    </div>
  </Modal>
);

EditCutTicketColorModal.propTypes = {
  title: PropTypes.string.isRequired,
  colors: PropTypes.arrayOf(PropTypes.any).isRequired,
  checkableSizes: PropTypes.arrayOf(PropTypes.any).isRequired,
  selectableColors: PropTypes.arrayOf(PropTypes.any),

  canSave: PropTypes.bool,
  isSubmiting: PropTypes.bool,
  canRemove: PropTypes.bool.isRequired,

  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  addColor: PropTypes.func,
  onChangeColorName: PropTypes.func,
  onRemoveColor: PropTypes.func,
  onChangeSize: PropTypes.func,
  changeCheckedSize: PropTypes.func,
};

EditCutTicketColorModal.defaultProps = {
  selectableColors: [],

  canSave: true,
  isSubmiting: false,

  onRemoveColor: f => f,
  onClose: f => f,
  onSubmit: f => f,
  addColor: f => f,
  onChangeColorName: f => f,
  onChangeSize: f => f,
  changeCheckedSize: f => f,
};

export default EditCutTicketColorModal;
