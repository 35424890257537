import {getPurchasePlanDAL, updatePurchasePlanDAL} from '../api/purchasePlanApi';

class purchasePlanServices {

  constructor() {
  }


  getPurchasePlan(styleId, options = {}) {
    return getPurchasePlanDAL(styleId, options);
  }

  updatePurchasePlan(styleId, options = {}) {
    return updatePurchasePlanDAL(styleId, options);
  }
}

export default new purchasePlanServices();
