import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import authActions from '../../actions/authActions';
import I18n from 'i18n-js';
import { toastr } from 'react-redux-toastr';
import { isStringEmpty } from '../../functions/helpers';
import _ from 'lodash';

import logoIcon from '../../stylesheets/images/logo.svg';
import loadingIcon from '../../stylesheets/images/loading.svg';
import faEyeIcon from '../../stylesheets/images/fa-eye.svg';
import faEyeSlashIcon from '../../stylesheets/images/fa-eye-slash.svg';

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        newPassword: '',
        rePassword: '',
        token: ''
      },
      typeNewPass: true,
      typeRePass: true,
      errors: {}
    };
    this.onChange = this.onChange.bind(this);
    this.redirectLoginPage = this.redirectLoginPage.bind(this);
  }


  componentWillMount() {
    // get token
    let token = _.get(this.props, 'location.search');
    token = _.replace(token,'?token=', '');

    if (token !== '') {
      let { data } = this.state;
      data.token = token;
      this.setState({ data });
    }
  }

  componentWillReceiveProps(newProps) {
    if (newProps.authState.resetError) {
      this.setState({ error: I18n.t('ResetPassword.TheTokenHasExpired') });
    }
  }

  onChange(name, value) {
    let { data } = this.state;
    data[name] = value;
    this.setState({ data });
  }

  onReset(event) {
    event.preventDefault();
    let { data } = this.state;

    if (this.validate()) {
      if(isStringEmpty(data.token)) {
        toastr.error('Error', I18n.t('ResetPassword.TokenMissing'));
        return;
      }
      this.props.authPropsActions.forgotPasswordConfirm(data).then(res => {
        
        if(res.success) {
          toastr.success('Success', I18n.t('ResetPassword.ChangeSuccess'));
          this.redirectLoginPage();
        }
        if(res.error) {
          if(res.error.error === 'Invalid/Expired/Used token') {
            toastr.error('Error', I18n.t('ResetPassword.ExpiredToken'));
          } else {
            toastr.error('Error', res.error.error);
          }
        }
      }, (err) => {
        console.log(err);
      })
    }
  }

  validate() {
    let { data, errors } = this.state;
    errors = {};
    if (!data.newPassword.length) {
      errors.newPassword = I18n.t('ResetPassword.PleaseEnterNewPassword');
      this.setState({ errors });
      return false;
    }
    if (data.newPassword.length < 6) {
      errors.newPassword = I18n.t('ResetPassword.EmptyOrLength');
      this.setState({ errors });
      return false;
    }
    if (data.newPassword !== data.rePassword) {
      errors.rePassword = I18n.t('ResetPassword.ConfirmPasswordIncorrect');
      this.setState({ errors });
      return false;
    }
    this.setState({ errors });
    return true;
  }

  redirectLoginPage() {
    this.props.history.push('/login');
  }

  render() {
    const { authState } = this.props;
    let { data, errors } = this.state;
    let isSubmiting = _.get(authState, 'loading', false);
    return (
      <div id="login" className={`card card-container ${isSubmiting ? 'de-active' : ''}`}>
        {isSubmiting && (
          <div className="loadingPage">
            <img src={loadingIcon} />
          </div>
        )}
        <img id="profile-img" className="profile-img-card" src={logoIcon} />
        <form className="form-signin" onKeyPress={event => {
          if (event.key === 'Enter') {
            this.onLogin();
          }
        }} >
          <div className="form-group password">
            <label>{I18n.t('ForgotPassword.NewPassword')}</label>
            <input type={this.state.typeNewPass ? "password" : "text"} className="form-control" value={data.newPassword} onChange={evt => this.onChange('newPassword', evt.target.value)} placeholder="" />
            <span onClick={() => { this.setState({ typeNewPass: !this.state.typeNewPass }) }}>
              <img style={{cursor: 'pointer'}} src={this.state.typeNewPass ? faEyeIcon : faEyeSlashIcon} alt="" />
            </span>
            {errors['newPassword'] && <div className="alert-warning">{errors['newPassword']}</div>}
          </div>
          <div className="form-group password">
            <label>{I18n.t('ForgotPassword.ConfirmPassword')}</label>
            <input type={this.state.typeRePass ? "password" : "text"} className="form-control" value={data.rePassword} onChange={evt => this.onChange('rePassword', evt.target.value)} placeholder="" />
            <span onClick={() => { this.setState({ typeRePass: !this.state.typeRePass }) }}>
              <img style={{cursor: 'pointer'}} src={this.state.typeRePass ? faEyeIcon : faEyeSlashIcon} alt="" />
            </span>
            {errors['rePassword'] && <div className="alert-warning">{errors['rePassword']}</div>}
          </div>
          <div className="wrap-button">
            <button type="button" className="bt-black mg-right-5" onClick={this.redirectLoginPage} >{I18n.t('Btn.CANCEL')}</button>
            <button type="button" className="bt-orange" onClick={(evt) => this.onReset(evt)} >{I18n.t('Btn.RESET')}</button>
          </div>
        </form>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    authState: state.authState
  };
}

function mapDispatchToProps(dispatch) {
  return {
    authPropsActions: bindActionCreators(authActions, dispatch)
  };
}

ResetPassword.contextTypes = {
  router: PropTypes.object.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
