export const MODAL = {
  SHOW_CONFIRM: 'MODAL_SHOW_CONFIRM',
  SHOW_MODAL: 'SHOW_MODAL',
  HIDE_MODAL: 'HIDE_MODAL',
  SHOW_MODAL_SUCCESS: 'SHOW_MODAL_SUCCESS'
};

export const SESSION = {
  LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',
  LOGIN_FAILED: 'USER_LOGIN_FAILED',
  SIGNUP_FAILED: 'USER_SIGNUP_FAILED',
  LOGOUT: 'USER_LOGOUT',
  SET_CURRENT_USER: 'SESSION_SET_CURRENT_USER'
};

export const USERS = {
  GET_LIST_SUCCESS: 'GET_USER_ORDERS_SUCCESS',
  FETCH_SUCCESS: 'USERS_FETCH_SUCCESS',
  GET_USER_POST_SUCCESS: 'GET_USER_POST_SUCCESS',
  FETCH_DETAIL_SUCCESS: 'USERS_FETCH_DETAIL_SUCCESS',
  ADD_ERROR: 'ADD_ERROR',
  RELOAD: 'USERS_RELOAD'
};

export const ROLES = {
  FETCH_SUCCESS: 'ROLES_FETCH_SUCCESS'
};

export const SIGN_UP = {
  SIGN_UP_SUCCESS: 'SIGN_UP_SUCCESS',
  SIGN_UP_ERROR: 'SIGN_UP_ERROR'
};

export const REGISTER = {
  REGISTER_SUCCESS: 'REGISTER_SUCCESS',
  REGISTER_ERROR: 'REGISTER_ERROR'
};

export const SIGN_OUT = {
  SIGN_OUT_SUCCESS: 'SIGN_OUT_SUCCESS'
};

export const AUTH = {
  SET_USER: 'SET_USER',
  EDIT_USER: 'EDIT_USER',
  GET_USER_FAIL: 'GET_USER_FAIL',
  SET_LANGUAGE: 'SET_LANGUAGE',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  VERIFY_EMAIL_SUCCESS: 'VERIFY_EMAIL_SUCCESS',
  FORGOT_PASSWORD_ERROR: 'FORGOT_PASSWORD_ERROR',
  CHANGE_PASSWORD_ERROR: 'CHANGE_PASSWORD_ERROR',
  CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
  SHOW_LOADING: 'SHOW_LOADING_AUTH',
  HIDE_LOADING: 'HIDE_LOADING_AUTH',
  LOGIN_FAIL: 'LOGIN_FAIL_AUTH'
};

export const APP = {
  SHOW_LOADING: 'SHOW_LOADING',
  HIDE_LOADING: 'HIDE_LOADING',
  SHOW_SUCCESS_ALERT: 'SHOW_SUCCESS_ALERT',
  SHOW_ERROR_ALERT: 'SHOW_ERROR_ALERT',
  HIDE_ALERT: 'HIDE_ALERT',
  GET_STATISTIC_SUCCESS: 'GET_STATISTIC_SUCCESS',
  SET_PAGE_CURRENT: 'SET_PAGE_CURRENT',
  ADD_PAGE_CURRENT: 'ADD_PAGE_CURRENT',
  SET_DATA_PAGE_CURRENT: 'SET_DATA_PAGE_CURRENT'
};

export const ORDER = {
  GET_LIST_SUCCESS: 'GET_LIST_ORDER_SUCCESS',
  GET_LIST_MORE_SUCCESS: 'GET_LIST_MORE_SUCCESS',
  RELOAD: 'RELOAD_ORDER',
  EDIT_ORDER_SUCCESS: 'EDIT_ORDER_SUCCESS',
  ADD_ORDER_SUCCESS: 'ADD_ORDER_SUCCESS',
  RELOAD_DETAIL: 'RELOAD_DETAIL_ORDER',
  GET_DETAIL_SUCCESS: 'GET_DETAIL_ORDER_SUCCESS',
  SEND_NOTIFY_SUCCESS: 'SEND_NOTIFY_SUCCESS',
  RESET_DETAIL: 'RESET_DETAIL_ORDER',
  GET_LIST_CUSTOMER: `GET_LIST_CUSTOMER`,
  CHANGE_PAGE_NUMBER: 'CHANGE_PAGE_NUMBER',
  RESET_CHANGE_PAGE_NUMBER: 'RESET_CHANGE_PAGE_NUMBER',
  CHANGE_FILTER_TEXT: 'CHANGE_FILTER_TEXT',
  CANCEL_IS_SEARCH: 'CANCEL_IS_SEARCH',
  IS_SEARCH: 'IS_SEARCH',
  LOAD_MORE: 'LOAD_MORE',
  CANCEL_LOAD_MORE: 'CANCEL_LOAD_MORE',
  CANCEL_LOADING: 'CANCEL_LOADING',
  LOADING: 'LOADING'
};

export const PURCHASE = {
    GET_LIST_SUCCESS: 'GET_LIST_PURCHASE_SUCCESS',
    RELOAD: 'RELOAD_PURCHASE',
    RELOAD_HISTORY: 'RELOAD_PURCHASE_HISTORY'
};


export const PURCHASE_PLAN = {
  GET_BY_STYLE_SUCCESS: 'GET_BY_STYLE_SUCCESS',
  UPDATE_PLAN_SUCCESS: 'UPDATE_PLAN_SUCCESS',
  RELOAD: 'RELOAD_PURCHASE_PLAN'
};

export const SUPPLIER = {
  GET_LIST_SUCCESS: 'GET_LIST_SUPPLIER_SUCCESS',
  RELOAD: 'RELOAD_SUPPLIER'
};

export const STYLE = {
  GET_LIST_SUCCESS: 'GET_LIST_STYLE_SUCCESS',
  RELOAD: 'RELOAD_STYLE',
  ADD_STYLE_SUCCESS: 'ADD_STYLE_SUCCESS',
  GET_DETAIL_SUCCESS: 'GET_DETAIL_STYLE_SUCCESS',
  RESET_DETAIL: 'RESET_DETAIL_STYLE'
};

export const CUT_TICKET = {
  GET_LIST_SUCCESS: 'GET_LIST_CUT_TICKET_SUCCESS',
  RELOAD: 'RELOAD_CUT_TICKET',
  GET_DETAIL_SUCCESS: 'GET_DETAIL_CUT_TICKET_SUCCESS',
  RESET_DETAIL: 'RESET_DETAIL_CUT_TICKET',
  SET_ITEM_YIELD_DETAIL: 'SET_ITEM_YIELD_DETAIL',
  SEND_YIELD_NOTIFY: 'SEND_YIELD_NOTIFY'
};

export const CUSTOMER = {
  GET_LIST_SUCCESS: 'GET_LIST_CUSTOMER_SUCCESS',
  RELOAD: 'RELOAD_CUSTOMER',
  GET_DETAIL_SUCCESS: 'GET_DETAIL_CUSTOMER_SUCCESS',
  RESET_DETAIL: 'RESET_DETAIL_CUSTOMER',
  DELETE_SUCCESS: 'CRM_CUSTOMER_DELETE_SUCCESS',
};

export const STORE = {
  GET_LIST_SUCCESS: 'GET_LIST_STORE_SUCCESS',
  RELOAD: 'RELOAD_STORE',
  GET_DETAIL_SUCCESS: 'GET_DETAIL_STORE_SUCCESS',
  RESET_DETAIL: 'RESET_DETAIL_STORE'
};

export const PROPERTY = {
  GET_KEY_SUCCESS: 'GET_KEY_PROPERTY_SUCCESS',
  GET_LIST_SUCCESS: 'GET_LIST_PROPERTY_SUCCESS',
  RELOAD: 'RELOAD_PROPERTY',
  GET_DETAIL_SUCCESS: 'GET_DETAIL_PROPERTY_SUCCESS',
  RESET_DETAIL: 'RESET_DETAIL_PROPERTY'
};

export const PRODUCTION = {
  RELOAD_PRODUCTION: 'RELOAD_PRODUCTION',
  CANCEL_RELOAD_PRODUCTION: 'CANCEL_RELOAD_PRODUCTION',
  RELOAD_QA: 'RELOAD_QA',
  GET_PRODUCTION_ORDERS_SUCCESS: 'GET_PRODUCTION_ORDERS_SUCCESS',
  GET_QA_ORDERS_SUCCESS: 'GET_QA_ORDERS_SUCCESS',
  GET_QA_ORDERS_SUCCESS_MORE: 'GET_QA_ORDERS_SUCCESS_MORE', 
  GET_PROGRESS_SUCCESS: 'GET_PROGRESS_SUCCESS',
  GET_ACTUAL_YIELD_SUCCESS: 'GET_ACTUAL_YIELD_SUCCESS',
  GET_PRODUCTION_ORDERS_SUCCESS_MORE: 'GET_PRODUCTION_ORDERS_SUCCESS_MORE',
  CHANGE_PAGE_NUMBER: 'CHANGE_PAGE_NUMBER',
  RESET_CHANGE_PAGE_NUMBER: 'RESET_CHANGE_PAGE_NUMBER',
  CHANGE_FILTER_TEXT: 'CHANGE_FILTER_TEXT',
  CANCEL_IS_SEARCH: 'CANCEL_IS_SEARCH',
  IS_SEARCH: 'IS_SEARCH',
  LOAD_MORE: 'LOAD_MORE',
  CANCEL_LOAD_MORE: 'CANCEL_LOAD_MORE',
  LOADING: 'LOADING',
  CANCEL_LOADING: 'CANCEL_LOADING',
};

export const FABRIC = {
  GET_KEY_SUCCESS: 'GET_KEY_FABRIC_SUCCESS',
  GET_LIST_SUCCESS: 'GET_LIST_FABRIC_SUCCESS',
  RELOAD: 'RELOAD_FABRIC',
  GET_DETAIL_SUCCESS: 'GET_DETAIL_FABRIC_SUCCESS',
  RESET_DETAIL: 'RESET_DETAIL_FABRIC'
};

export const TRIM = {
  GET_KEY_SUCCESS: 'GET_KEY_TRIM_SUCCESS',
  GET_LIST_SUCCESS: 'GET_LIST_TRIM_SUCCESS',
  RELOAD: 'RELOAD_TRIM',
  GET_DETAIL_SUCCESS: 'GET_DETAIL_TRIM_SUCCESS',
  RESET_DETAIL: 'RESET_DETAIL_TRIM'
};

export const MATERIAL = {
  GET_LIST_SUCCESS: 'GET_LIST_MATERIAL_SUCCESS',
  RELOAD: 'RELOAD_MATERIAL',
  GET_DETAIL_SUCCESS: 'GET_DETAIL_MATERIAL_SUCCESS',
  RESET_DETAIL: 'RESET_DETAIL_MATERIAL'
};

export const SAMPLE_APPROVAL = {
  RELOAD: 'RELOAD_SAMPLE_APPROVAL',
  GET_DETAIL_SUCCESS: 'GET_DETAIL_SAMPLE_APPROVAL_SUCCESS',
  RESET_DETAIL: 'RESET_DETAIL_SAMPLE_APPROVAL',
  UPDATE_STATUS: 'UPDATE_STATUS_SAMPLE_APPROVAL',
  GET_LIST: "GET_LIST_SAMPLE_APPROVAL",
  GET_LIST_CUSTOMER_PO: "GET_LIST_CUSTOMER_PO_SAMPLE_APPROVAL"
};

export const WAREHOUSE = {
  GET_LIST_SUCCESS: 'GET_LIST_WAREHOUSE_SUCCESS',
  GET_LIST_RESERVE: 'GET_LIST_RESERVE_SUCCESS'
};
export const STOCK_IN = {
  GET_LIST_SUCCESS: 'GET_LIST_STOCK_IN_SUCCESS',
  RELOAD: 'RELOAD_STOCK_IN',
  GET_DETAIL_SUCCESS: 'GET_DETAIL_STOCK_IN_SUCCESS',
  RESET_DETAIL: 'RESET_DETAIL_STOCK_IN',
  SET_DETAIL: 'SET_DETAIL_STOCK_IN'
};

export const PURCHASE_ORDER = {
  GET_LIST_SUCCESS: 'GET_LIST_PURCHASE_ORDER_SUCCESS',
  RELOAD: 'RELOAD_PURCHASE_ORDER',
  GET_DETAIL_SUCCESS: 'GET_DETAIL_PURCHASE_ORDER_SUCCESS',
  RESET_DETAIL: 'RESET_DETAIL_PURCHASE_ORDER'
};

export const DAMAGED_GARMENT = {
  GET_LIST_SUCCESS: 'GET_LIST_DAMAGED_GARMENT_SUCCESS',
  RELOAD: 'RELOAD_DAMAGED_GARMENT',
  GET_DETAIL_SUCCESS: 'GET_DETAIL_DAMAGED_GARMENT_SUCCESS'
};

export const DAMAGED_MATERIAL = {
  GET_LIST_SUCCESS: 'GET_LIST_DAMAGED_MATERIAL_SUCCESS',
};

export const STOCK_OUT = {
  GET_LIST_SUCCESS: 'GET_LIST_STOCK_OUT_SUCCESS',
  RELOAD: 'RELOAD_STOCK_OUT',
  GET_DETAIL_SUCCESS: 'GET_DETAIL_STOCK_OUT_SUCCESS',
  RESET_DETAIL: 'RESET_DETAIL_STOCK_OUT',
  SET_MATERIALS: 'SET_MATERIALS_STOCK_OUT'
};

export const CONTRACTOR = {
  GET_LIST_SUCCESS: 'GET_LIST_CONTRACTOR_SUCCESS',
  RELOAD: 'RELOAD_CONTRACTOR',
  GET_DETAIL_SUCCESS: 'GET_DETAIL_CONTRACTOR_SUCCESS',
  RESET_DETAIL: 'RESET_DETAIL_CONTRACTOR'
};

export const ASSIGNMENT = {
  GET_LIST_SUCCESS: 'GET_LIST_ASSIGNMENT_SUCCESS',
  GET_ORDER_CONTRACT_SUCCESS: 'GET_ORDER_CONTRACT_SUCCESS',
  GET_LIST_ORDER_SUCCESS: 'GET_ASSIGNMENT_ORDERS_SUCCESS',
  RELOAD: 'RELOAD_ASSIGNMENT',
  GET_DETAIL_SUCCESS: 'GET_DETAIL_ASSIGNMENT_SUCCESS',
  RESET_DETAIL: 'RESET_DETAIL_ASSIGNMENT'
};

export const PACKING_STANDARD = {
  GET_KEY_SUCCESS: 'GET_KEY_PACKING_STANDARD_SUCCESS',
  GET_LIST_SUCCESS: 'GET_LIST_PACKING_STANDARD_SUCCESS',
  RELOAD: 'RELOAD_PACKING_STANDARD',
  GET_DETAIL_SUCCESS: 'GET_DETAIL_PACKING_STANDARD_SUCCESS',
  RESET_DETAIL: 'RESET_DETAIL_PACKING_STANDARD'
};

export const PACKING = {
  GET_LIST_SUCCESS: 'GET_LIST_PACKING_SUCCESS',
  RELOAD: 'RELOAD_PACKING',
  GET_DETAIL_SUCCESS: 'GET_DETAIL_PACKING_SUCCESS',
  RESET_DETAIL: 'RESET_DETAIL_PACKING'
};

export const SYSTEM_SETTINGS = {
  GET_LIST_SUCCESS: 'GET_LIST_SYSTEM_SETTINGS_SUCCESS',
  GET_LIST_NOTIFICATIONS_SUCCESS: 'GET_LIST_SYSTEM_SETTINGS_NOTIFICATIONS_SUCCESS',
  RELOAD: 'RELOAD_SYSTEM_SETTINGS'
};

export const DEPARTMENT = {
  GET_LIST_SUCCESS: 'GET_LIST_DEPARTMENT_SUCCESS',
  RELOAD: 'RELOAD_DEPARTMENT_SETTINGS'
};

export const ORGANIATION = {
  GET_LIST_SUCCESS: 'GET_LIST_ORGANIATION_SUCCESS',
  RELOAD: 'RELOAD_ORGANIATION_SETTINGS'
};
export const ROLE = {
  GET_LIST_SUCCESS: 'GET_LIST_ROLE_SUCCESS',
  RELOAD: 'RELOAD_ROLE',
  SET_DETAIL: 'SET_ROLE_DETAIL',
  GET_MODULES_SUCCESS: 'GET_ROLE_MODULES_SUCCESS'
};

export const STANDARD = {
  RELOAD: 'RELOAD_STANDARD',
  GET_DETAIL_SUCCESS: 'GET_DETAIL_STANDARD_SUCCESS',
  RESET_DETAIL: 'RESET_DETAIL_STANDARD'
};

export const SHIPPING = {
  GET_FOLLOW_UP_SUCCESS: 'GET_FOLLOW_UP_SHIPPING_SUCCESS',
  CREATE_SHIPMENT: 'CREATE_SHIPMENT',
  EDIT_SHIPMENT: 'EDIT_SHIPMENT',
  PURCHASE_ORDER_LIST: 'PURCHASE_ORDER_LIST', 
  SHIPIN_LIST: 'SHIPIN_LIST',
  HISTORY_LIST: 'HISTORY_LIST',
  SHIP_OUT:{
    GET_ITEMS_FROM_SEARCH: `GET_ITEMS_FROM_SEARCH`
  }
};

export const PAYINFO = {
  CREATE_CREDIT_DEBIT: "CREATE_CREDIT_DEBIT",
  GET_PAYINFO_SUCCESS: "GET_PAYINFO_SUCCESS"
};

export const SHIPOUT = {
  GET_PKL_OF_PRODUCTION_SUCCESS: 'GET_PKL_OF_PRODUCTION_SUCCESS',
}

export const DASHBOARD = {
  GET_DASHBOARD: "DASHBOARD.GET_DASHBOARD",
  RESET_DASHBOARD: "DASHBOARD.RESET_DASHBOARD",
  FILTERED_DASHBOARD: "FILTERED_DASHBOARD",
  LOADING: "LOADING",
  CANCEL_LOADING: "CANCEL_LOADING",
  TOTAL: "TOTAL"
}
