import Base from './base';

class DamagedMaterial extends Base {
  constructor () {
    super();

    this.getListItemDAL = this.getListItemDAL.bind(this);
  }

  getListItemDAL (query) {
    return super.execute(this.Urls.damagedMaterial.list, this.ApiMethod.get, query);
  }
}

export default new DamagedMaterial();
