import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import orderActions from '../../../actions/orderActions';
import customerActions from '../../../actions/customerActions';
const DateTimeField = require('react-datetime');
import _ from 'lodash';
import moment from 'moment';
import I18n from 'i18n-js';

class ModalCustomerPOForm extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      isAdd: true,
      item: {},
      customers: [],
      errors: {},
    };
  }

  componentWillMount() {
    const { orderId } = this.props;
    if (orderId) {
      this.setState({ isAdd: false });
      this.props.orderPropsActions.getDetail(orderId);
    }
    this.props.customerPropsActions.getListCustomer();
  }

  componentWillReceiveProps(newProps) {
    let { customers, item } = this.state;
    let { orderId, orderState, customerState } = newProps;
    if (orderId && !_.isEqual(item, orderState.detail)) {
      this.setState({ item: _.cloneDeep(orderState.detail) || {} });
    }

    if (!_.isEqual(customers, customerState.items)) {
      this.setState({ customers: _.cloneDeep(customerState.items) || [] });
    }
  }

  onChange(property, value) {
    let { item } = this.state;
    this.setState({ item: _.set(item, property, value) });
  }

  validate() {
    let { item } = this.state;
    let fields = ['customerPO', 'customer'];
    let errors = {};

    fields.forEach(f => {
      _.set(errors, f, !item[f] ? `Please enter ${f}` : '');
    });
    if (!errors.customerPO) {
      _.set(errors, 'customerPO', (this.props.validateExistOrder && this.props.validateExistOrder(item)) ? I18n.t('ErrorMessages.OrderHaveAlreadyExist') : '');
    }
    this.setState({ errors });

    let flag = Object.keys(errors).every(k => !errors[k]);

    return flag;
  }

  save() {
    let { item } = this.state;

    // default
    !item.orderDate && _.set(item, 'orderDate', moment().format());
    !item.ihDate && _.set(item, 'ihDate', moment().format());
    !item.exFactory && _.set(item, 'exFactory', moment().format());
    item._id && !item.actualIHDate && _.set(item, 'actualIHDate', moment().format());

    if (this.validate()) {
      let data = _.pick(item, ['customerPO', 'customer', 'orderDate', 'ihDate', 'exFactory', 'actualIHDate', 'description']);
      item.customerName = (this.state.customers.find(vl => vl._id == data.customer) || {}).name || '';
      if (!item._id) {
        this.props.orderPropsActions.addOrder(_.cloneDeep(item)).then(res => {
          if (res && res.success && this.props.onSave) {
            this.props.onSave(res.data);
          }
        });
      }
      else {
        this.props.orderPropsActions.editOrder(item._id, _.cloneDeep(item)).then(res => {
          if (res && res.success && this.props.onSave) {
            this.props.onSave(res.data);
          }
        });
      }
    }
  }

  close() {
    this.props.closeModal();
  }

  onChangeIsCompleted(evt) {
    if(evt && evt.target) {
      this.setState({isCompleted: evt.target.checked});
    }
  }

  render() {
    let { isAdd, item, customers, errors } = this.state;
    let { note } = this.props;
    return (
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <form>
            <div className="modal-header">
              <a className="close" data-dismiss="modal" aria-label="Close" onClick={() => this.close()}><span aria-hidden="true">&times;</span></a>
              <h4 className="modal-title" id="myModalLabel">{isAdd ? I18n.t('PageOrder.ADDORDER') : I18n.t('PageOrder.EDITORDER')}</h4>
            </div>
            <div className="modal-body">
              <div className="form-group">
                <div className="col-md-4 col-xs-12 text">
                  <p>{I18n.t('PageOrder.CustomerPO')}</p>
                </div>
                <div className="col-md-8 col-xs-12 value">
                  <input type="text" className="form-control" value={item.customerPO || ''}
                    onChange={evt => this.onChange('customerPO', evt.target.value)}
                    placeholder={I18n.t('PageOrder.CustomerPO')} />
                  {errors['customerPO'] && <div className="alert-warning">{errors['customerPO']}</div>}
                </div>
              </div>

              <div className="form-group">
                <div className="col-md-4 col-xs-12 text">
                  <p>{I18n.t('PageOrder.Customer')}</p>
                </div>
                <div className="col-md-8 col-xs-12 value">
                  <select className="form-control" value={item.customer || ''} onChange={evt => this.onChange('customer', evt.target.value)} >
                    <option value="" />
                    {customers.map((i, idx) => (<option key={idx} value={i._id}>{i.name}</option>))}
                  </select>
                  {errors['customer'] && <div className="alert-warning">{errors['customer']}</div>}
                </div>
              </div>
              <div className="form-group">
                <div className="col-md-4 col-xs-12 text">
                  <p>{I18n.t('PageOrder.OrderDate')}</p>
                </div>
                <div className="col-md-8 col-xs-12 value">
                  <DateTimeField
                    timeFormat={false}
                    dateFormat="MMMM DD, YYYY"
                    value={moment(item.orderDate)}
                    onChange={date => this.onChange('orderDate', date.format())}
                  />
                </div>
              </div>

              <div className="form-group">
                <div className="col-md-4 col-xs-12 text">
                  <p>{I18n.t('PageOrder.Ex-Factory')}</p>
                </div>
                <div className="col-md-8 col-xs-12 value">
                  <DateTimeField
                    timeFormat={false}
                    dateFormat="MMMM DD, YYYY"
                    value={moment(item.exFactory)}
                    onChange={date => this.onChange('exFactory', date.format())}
                  />
                </div>
              </div>

              <div className="form-group">
                <div className="col-md-4 col-xs-12 text">
                  <p>{I18n.t('PageOrder.IHDate')}</p>
                </div>
                <div className="col-md-8 col-xs-12 value">
                  <DateTimeField
                    timeFormat={false}
                    dateFormat="MMMM DD, YYYY"
                    value={moment(item.ihDate )}
                    onChange={date => this.onChange('ihDate', date.format())}
                  />
                </div>
              </div>
              <div className="form-group">
                <div className="col-md-4 col-xs-12 text">
                  <p>{I18n.t('PageOrder.Description')}</p>
                </div>
                <div className="col-md-8 col-xs-12 value">
                  <textarea type="text" className="form-control" value={item.description || ''}
                    onChange={evt => this.onChange('description', evt.target.value)} />
                </div>
              </div>
              { note == true && (
                <div>
                  <div className="form-group">
                    <div className="col-md-4 col-xs-12 text"></div>
                    <div className="col-md-8 col-xs-12 value">
                      <div className="style-checkbox">
                        <input type="checkbox" id="view_5982c1c3cabed098307c2078"
                               value="on" checked={item.isCompleted} onChange={(evt)=>this.onChange('isCompleted', evt.target.checked)}/>
                        <label htmlFor="view_5982c1c3cabed098307c2078">
                          <span className=""><span className="mg-left-25 line-h-15 position-absolute top-0">{I18n.t('PageOrder.Completed')}</span></span>
                        </label>
                      </div>
                    </div>
                  </div>
                  {item.isCompleted &&
                    <div className="form-group mg-0">
                      <div className="col-md-4 col-xs-12 text">
                        <p>{I18n.t('PageOrder.Note')}</p>
                      </div>
                      <div className="col-md-8 col-xs-12 value">
                        <textarea type="text" className="form-control" value={item.note || ''}
                                  onChange={evt => this.onChange('note', evt.target.value)} />
                      </div>
                    </div>
                  }
                </div>
              )}
            </div>
            <div className="modal-footer">
              <button type="button" className="bt-black" onClick={() => this.close()} >{I18n.t('Btn.CANCEL')}</button>
              <button type="button" className="bt-orange mg-l-5" onClick={() => this.save()} >{I18n.t('Btn.SAVE')}</button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    authState: state.authState,
    orderState: state.orderState,
    customerState: state.customerState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    orderPropsActions: bindActionCreators(orderActions, dispatch),
    customerPropsActions: bindActionCreators(customerActions, dispatch)
  };
}

ModalCustomerPOForm.propTypes = {
  authState: PropTypes.object.isRequired,
  orderId: PropTypes.string,
  onSave: PropTypes.func,
  validateExistOrder: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalCustomerPOForm);
