import React, { Component } from 'react';
import {Store} from '../../../../CssHandleModule/functions'
import I18n from 'i18n-js';
import _ from 'lodash';
import {connect} from 'react-redux';
import ModalEditbar from '../../../../../components/common/modals/ModalEditbar';
import * as selector from '../../../../../selectors/ship-out/editPklShip';
import {
    changeShowCartonDetail,
    changeCheckedPacking,
    changePackedNwKgs,
    changePackedGwKgs,
    changePackedNumber
} from '../../../../../actions/ship-out/editPklShipActions';
const store = new Store();
store.setStyles({
    orderNumbers: [1, 2],
    fonts: [
        {order: 1, size: '15px'}
    ],
    aligns: [
        {order: 1, margin: {right: '10px'}},
        {order: 2, margin: {left: '10px'}}
    ]
});

import icEditXIcon from '../../../../../stylesheets/images/ic-edit-x.svg';

class ColorTable extends Component{

    constructor(props) {
        super(props);
        
    }
    
    openModalEditBar = (orderId, styleId, cutTicketId, colorId, barNo, barTotal) => {
        let { openModal, closeModal } = this.props.modal;
        let modal = (<ModalEditbar closeModal={closeModal}  ok={this.ok} data ={{orderId, styleId, cutTicketId, colorId, barNo, barTotal}}/>);
        openModal(modal);
    }

    changeShowCartonDetail = (orderId, styleId, cutTicketId, colorId, group) => {
        this.props.changeShowCartonDetail(orderId, styleId, cutTicketId, colorId, group)
    }

    changeCheckedPacking = (orderId, styleId, cutTicketId, colorId, group, cartonNo, value) => {
        this.props.changeCheckedPacking(orderId, styleId, cutTicketId, colorId, group, cartonNo, value)
    }
    //Carton
    changePackedNwKgs = (orderId, styleId, cutId, colorId, group, ctnNo, value) => {
        this.props.changePackedNwKgs(orderId, styleId, cutId, colorId, group, ctnNo, value)
    }

    changePackedGwKgs = (orderId, styleId, cutId, colorId, group, ctnNo, value) => {
        this.props.changePackedGwKgs(orderId, styleId, cutId, colorId, group, ctnNo, value)
    }
    changePackedNumber = (orderId, styleId, cutId, colorId, group, ctnNo, value) => {
        this.props.changePackedNumber(orderId, styleId, cutId, colorId, group, ctnNo, value)
    }

    render() {
    const {data, packingType, getSizes, orderId, styleId, cutId, getBars, getBarTotal, getColorTotal, getQtyColorConst, getCartons, view, shipOut} = this.props;
    const sizes = getSizes(orderId, styleId, cutId, data.color._id);
    const bars = packingType == "GOHH" ? getBars(orderId, styleId, cutId, data.color._id) : [];
    const totalColor = packingType == "GOHH" ? getColorTotal(orderId, styleId, cutId, data.color._id) : getQtyColorConst(orderId, styleId, cutId, data.color._id);
    // console.log('----------totalColor', totalColor);
    const cartons = packingType == "Carton" ? getCartons(orderId, styleId, cutId, data.color._id) : [];
        // console.log('----------cartons', cartons);
    return (
        <div className="mg-bottom-5">
            {packingType == 'GOHH' &&
                <div className="bg-white mg-top-2">
                    <div className="pd-15 bg-white bd-solid-bottom-2">
                        <div className="d-flex justify-content-between">
                            <div>
                                <span className="size-12">{I18n.t('PageShiping.Color')}</span>
                                <span className="size-15 bold mg-left-15">{data.color.name || ''}</span>
                            </div>
                            {!view &&<div className="d-flex justify-content-between" style={{width: '10%'}}>
                                <div>
                                    <span className="size-12">{I18n.t('PageShiping.Total')}</span>
                                    <span className="color-number size-15 bold mg-left-15">{totalColor || 0}</span>
                                </div>
                            </div>}
                        </div>
                    </div>
                        
                    <div className="bg-white mg-top-2">
                        <table className="global-table w-full">
                            <tbody>
                                <tr>
                                    <th className='left'>{I18n.t('PageShiping.Bar')}</th>
                                    {_.map(sizes, (size, idx) => <th key={idx} className='left'>{size}</th>)}
                                    <th className='center'>{I18n.t('PageShiping.Total')}</th>
                                    {!view && <th className='center'></th>}
                                </tr>
                                {bars.map((bar, index) => {
                                    const total = getBarTotal(bar);
                                    return (
                                        <tr key = {index}>
                                            <td>{bar.props.no}</td>
                                            {_.map(sizes, (size, Idx) => {
                                                let item = _.find(bar.items, (el) => el.name === size);
                                                return (
                                                    <td key = {Idx} className = "left color-orange">{_.get(item, 'actualQuantity', 0)}</td>
                                                )
                                            })}
                                            <td className='center color-red'>{total}</td>
                                            {!view && <td className="center"><span className="cursor-pointer" onClick={() => this.openModalEditBar(orderId, styleId, cutId, data.color._id, bar.props.no, total)}><img src={icEditXIcon}/></span></td>}
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            }
            {packingType == 'Carton' && totalColor >= 0 &&
                <div className="bg-white mg-top-2">
                       { view && 
                       <div style={{
                        height: 50,
                        backgroundColor: 'white',
                        paddingTop: 15,
                        paddingLeft: '1%',
                        paddingRight: '1%'
                        }}>
                        <div className="d-flex justify-content-between">
                                <div>
                                    <span style={store.setStyleType('font_1')}>{I18n.t('PageShiping.Color')}</span>
                                    <span style={{marginLeft: 10}}>{data.color.name || ''}</span>
                                </div>
                            </div>
                        </div>
                    }
                    {!view && <div className="display-flex bg-white">
                        <div className="pd-10 display-flex align-items-center pd-20 w-180 bd-dashed-right-1">
                            <h3 className="center mg-auto size-12">
                                <span className="fw-normal">{I18n.t("PageShiping.Color")}</span><br /><br />
                                <strong className="size-13">{data.color.name || ''}</strong><br /><br />
                            </h3>
                        </div>
                        <div className="pd-10 border-left-dashed">
                            <table className="global-table tr-height-30 box-shadow-none">
                                <tbody>
                                    <tr style={{ borderBottom: 'unset' }} className="bg-none">
                                        <td className="center fw-normal w-110">
                                            <span className="fw-normal">{I18n.t("PageShiping.ActualTotal")}</span><br />
                                            <strong className="size-13 color-red">{totalColor || 0}</strong><br /><br />
                                        </td>
                                        {sizes.map((size, sId) => {
                                            let qty = getQtyColorConst(orderId, styleId, cutId, data.color._id, size)
                                            return <td key={sId} className='center'> {size} : {qty}</td>
                                        })}
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>}
                    <div className="bg-white mg-top-2">
                        <table className="global-table w-full">
                            <tbody>
                                <tr>
                                    { !view && <th className='center'></th> }
                                    <th className='center'>{I18n.t('PageShiping.CTNNo')}</th>
                                    <th className='center'>{I18n.t('PageShiping.NW')}</th>
                                    <th className='center'>{I18n.t('PageShiping.GW')}</th>
                                    <th className='center'>{I18n.t('PageShiping.MEANS')}</th>
                                    <th className='center'>{I18n.t('PageShiping.M3')}</th>
                                    {sizes.map((size, idx) => <th key={idx} className='center'>{size}</th>)}
                                    <th className='center'>{I18n.t('PageShiping.QTY')}</th>
                                    <th className='center'>{I18n.t('PageShiping.QTY/CTN')}</th>
                                    <th className='center'>{I18n.t('PageShiping.Total')}</th>
                                </tr>
                                {(cartons || []).map((carton, idx) =>  {
                                    if(!_.isEmpty(carton) && (carton.active == true || carton.active == null)) {
                                        const qty = carton.quantity || 0
                                        const qtyCtn = carton.qtyCtn || 0
                                        const m3 = _.round(0.00001648 * carton.props.l * carton.props.w * carton.props.h, 5)
                                        const total = (qty * qtyCtn) || 0
                                        return (
                                            <tr key={idx} className= {carton.active != null ? 'bg-gray' : ''}>
                                                { !view && ( (carton.active == null && carton.canExcute == false) ?
                                                    <td className='center'>
                                                        {carton.canExcute == false &&
                                                            <span  className="fa fa-caret-down cursor-pointer"
                                                                style={store.setStyleType('align_1', { align: { margin: { left: '1%' } } })}
                                                                onClick={() => this.changeShowCartonDetail(orderId, styleId, cutId, data.color._id, carton.group)}
                                                            >
                                                            </span>
                                                        }
                                                    </td>:
                                                    <td className=" center style-checkbox">
                                                        <input checked={carton.props.actual}
                                                                type="checkbox"
                                                                id={`${orderId}:${styleId}:${cutId}:${data.color._id}:${carton.group}:${carton.ctnNo}`}
                                                                onChange={() => this.changeCheckedPacking(orderId, styleId, cutId, data.color._id, carton.group, carton.ctnNo, !carton.props.actual)}
                                                        />
                                                        <label htmlFor={`${orderId}:${styleId}:${cutId}:${data.color._id}:${carton.group}:${carton.ctnNo}`}><span><span/></span></label>
                                                </td>)}
                                                <td className="center">{carton.ctnNo}</td>
                                                <td className="center">{carton.active == null ? carton.props.nwKgs || '-' : ''}</td>
                                                <td className="center">{carton.active == null ? carton.props.gwKgs || '-' : ''}</td>
                                                <td className="center">{`${carton.props.l || '-'}" x ${carton.props.w || '-'}" x ${carton.props.h || '-'}"`}</td>
                                                <td className="center">{carton.active == null ? m3 : ''}</td>
                                                {sizes.map((size, idx) => {
                                                    let element = (<td className="color-orange center" key={`size_${idx}`}>{"-"}</td>);
                                                    carton.items.forEach((item) => {
                                                        if (item.name == size) {
                                                            element = (<td className="color-orange center" key={`size_${idx}`}>{item.planQuantity}</td>);
                                                        }
                                                    });
                                                    return element;
                                                })}
                                                <td className="color-green center">{qty}</td>
                                                <td className="color-green center">{qtyCtn}</td>
                                                <td className="color-red center">{total}</td>
                                            </tr>
                                        )
                                    }
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
                }
            </div>
        );
    }
}

const mapStateToProps = state => ({
    getSizes: selector.getSizes(state),
    getBars: selector.getBars(state),
    getBarTotal: selector.getBarTotal,
    getColorTotal: selector.getColorTotal(state),
    getQtyColorConst: selector.getQtyColorConst(state),
    getCartons: selector.getCartons(state),
    view: selector.getView(state),
    shipOut:selector.getShipOut(state),
})

const mapDispatchToProps = dispatch => ({
    changeShowCartonDetail(orderId, styleId, cutTicketId, colorId, group) {
        dispatch(changeShowCartonDetail(orderId, styleId, cutTicketId, colorId, group))
    },
    changeCheckedPacking(orderId, styleId, cutTicketId, colorId, group, cartonNo, value) {
        dispatch(changeCheckedPacking(orderId, styleId, cutTicketId, colorId, group, cartonNo, value))
    },
    // carton
    changePackedNwKgs(orderId, styleId, cutId, colorId, group, ctnNo, value) {
        dispatch(changePackedNwKgs(orderId, styleId, cutId, colorId, group, ctnNo, value))
    },
    changePackedGwKgs(orderId, styleId, cutId, colorId, group, ctnNo, value) {
        dispatch(changePackedGwKgs(orderId, styleId, cutId, colorId, group, ctnNo, value))
    },
    changePackedNumber(orderId, styleId, cutId, colorId, group, ctnNo, value) {
        dispatch(changePackedNumber(orderId, styleId, cutId, colorId, group, ctnNo, value))
    },
})
export default connect(mapStateToProps, mapDispatchToProps) (ColorTable);