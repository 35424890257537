

import { combineReducers } from 'redux';
import * as R from 'ramda';
import _ from 'lodash';
import uuid from 'uuid/v4';
import { pathImage } from '../../../functions/helpers';
import C from '../../../constants/standard/packing/editPackingStandard';

const rawData = (state = null, action) => {
  const { type, payload } = action;
  switch (type) {
    case C.CHANGE_PACKING_STANDARD:
      return payload;
    default:
      return state;
  }
}

const name = (state = null, action) => {
  const { type, payload } = action;
  switch (type) {
    case C.CHANGE_PACKING_STANDARD:
      return R.path(['name'], payload);
    case C.CHANGE_PACKING_NAME:
      return payload;
    default:
      return state;
  }
}

const description = (state = null, action) => {
  const { type, payload } = action;
  switch (type) {
    case C.CHANGE_PACKING_STANDARD:
      return R.path(['trimDescription'], payload);
    case C.CHANGE_PACKING_DESCRIPTION:
      return payload;
    default:
      return state;
  }
}

const type = (state = 'GOHH', action) => {
  const { type, payload } = action;
  switch (type) {
    case C.CHANGE_PACKING_STANDARD:
      return R.path(['type'], payload);
    case C.CHANGE_PACKING_TYPE:
      return payload;
    default:
      return state;
  }
}

const selectablePackingTypes = (state = ['GOHH', 'Carton']) => {
  return state;
}

const selectableMaterials = (state = [], action) => {
  const { type, payload } = action;
  switch (type) {
    case C.CHANGE_SELECTABLE_MATERIALS:
      return payload;
    default:
      return state;
  }
}

const trims = (state = [], action) => {
  const { type, payload } = action;
  switch (type) {
    case C.CHANGE_PACKING_STANDARD: {
      return _.map(R.pathOr([], ['trims'], payload), trim => ({
        ...trim,
        key: uuid(),
      }));
    }
    case C.CHANGE_TRIM_NAME: {
      const stateClone = _.clone(state);
      const { key, value } = payload;
      const trim = _.find(stateClone, t => t.key === key);
      trim.trim = value;
      return stateClone;
    }

    case C.CHANGE_TRIM_COLOR: {
      const stateClone = _.clone(state);
      const { key, value } = payload;
      const trim = _.find(stateClone, t => t.key === key);
      trim.color = value;
      return stateClone;
    }

    case C.CHANGE_TRIM_SIZE: {
      const stateClone = _.clone(state);
      const { key, value } = payload;
      const trim = _.find(stateClone, t => t.key === key);
      trim.size = value;
      return stateClone;
    }

    case C.ADD_TRIM: {
      return [...state, { key: uuid() }]
    }

    case C.REMOVE_TRIM: {
      let stateClone = _.clone(state);
      const { key } = payload;
      stateClone = stateClone.filter(t => t.key !== key);
      return stateClone;
    }

    default:
      return state;
  }
}

const foldingWays = (state = [], action) => {
  const { type, payload } = action;
  switch (type) {
    case C.CHANGE_PACKING_STANDARD:
      return _.map(R.pathOr([], ['foldingWay'], payload), item => ({
        ...item,
        imageUrl: pathImage(R.path(['photo'], item)),
        key: uuid(),
      }));

    case C.CHANGE_FOLDING_PHOTO: {
      const { key, value } = payload;
      const stateClone = _.clone(state);
      const item = _.find(stateClone, it => it.key === key);
      if (!item) return state;

      item.file = value;
      item.imageUrl = R.path(['url'], value);
      if (!item.imageUrl) item.photo = "";
      return stateClone;
    }

    case C.CHANGE_FOLDING_DESCRIPTION: {
      const { key, value } = payload;
      const stateClone = _.clone(state);
      const item = _.find(stateClone, it => it.key === key);
      if (!item) return state;

      item.note = value;
      return stateClone;
    }

    case C.ADD_FOLDING_WAY:
      return [...state, {key: uuid()}];

    case C.REMOVE_FOLDING_WAY: {
      let stateClone = _.clone(state);
      const { key } = payload;
      stateClone = stateClone.filter(t => t.key !== key);
      return stateClone;
    }

    default:
      return state;
  }
}

const hangTags = (state = [], action) => {
  const { type, payload } = action;
  switch (type) {
    case C.CHANGE_PACKING_STANDARD:
      return _.map(R.pathOr([], ['hangtag'], payload), item => ({
        ...item,
        imageUrl: pathImage(R.path(['photo'], item)),
        key: uuid(),
      }));

    case C.CHANGE_HANDTAG_PHOTO: {
      const { key, value } = payload;
      const stateClone = _.clone(state);
      const item = _.find(stateClone, it => it.key === key);
      if (!item) return state;

      item.file = value;
      item.imageUrl = R.path(['url'], value);
      if (!item.imageUrl) item.photo = "";
      return stateClone;
    }

    case C.CHANGE_HANDTAG_DESCRIPTION: {
      const { key, value } = payload;
      const stateClone = _.clone(state);
      const item = _.find(stateClone, it => it.key === key);
      if (!item) return state;
      
      item.note = value;
      return stateClone;
    }

    case C.ADD_HANDTAG:
      return [...state, {key: uuid()}];

    case C.REMOVE_HANDTAG: {
      let stateClone = _.clone(state);
      const { key } = payload;
      stateClone = stateClone.filter(t => t.key !== key);
      return stateClone;
    }
    
    default:
      return state;
  }
}

const shippingMarks = (state = [], action) => {
  const { type, payload } = action;
  switch (type) {
    case C.CHANGE_PACKING_STANDARD:
      return _.map(R.pathOr([], ['shippingMark'], payload), item => ({
        ...item,
        imageUrl: pathImage(R.path(['photo'], item)),
        key: uuid(),
      }));

    case C.CHANGE_SHIPPING_MARK_PHOTO: {
      const { key, value } = payload;
      const stateClone = _.clone(state);
      const item = _.find(stateClone, it => it.key === key);
      if (!item) return state;

      item.file = value;
      item.imageUrl = R.path(['url'], value);
      if (!item.imageUrl) item.photo = "";

      return stateClone;
    }

    case C.CHANGE_SHIPPING_MARK_DESCRIPTION: {
      const { key, value } = payload;
      const stateClone = _.clone(state);
      const item = _.find(stateClone, it => it.key === key);
      if (!item) return state;
      
      item.note = value;
      return stateClone;
    }

    case C.ADD_SHIPPING_MARK:
      return [...state, {key: uuid()}];

    case C.REMOVE_SHIPPING_MARK: {
      let stateClone = _.clone(state);
      const { key } = payload;
      stateClone = stateClone.filter(t => t.key !== key);
      return stateClone;
    }

    default:
      return state;
  }
}

const submiting = (state = false, action) => {
  const { type } = action;
  switch (type) {
    case C.SUBMIT:
      return true;
    case C.CANCEL_SUBMIT:
      return false;
    default:
      return state;
  }
}

const combined = combineReducers({
  rawData,
  name,
  description,
  type,
  selectablePackingTypes,
  selectableMaterials,
  trims,
  foldingWays,
  hangTags,
  shippingMarks,
  submiting,
});

export default (state, action) => {
  const { type } = action;
  if (type === C.RESET_DATA) {
    return {};
  }
  return combined(state, action);
};