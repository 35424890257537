import C from '../../constants/production/addPacking';
import E from '../../constants/production/editPacking';
import * as types from '../../constants/actionTypes';
import { showLoading, hideLoading } from '../commonUseActions';
import productionServices from '../../services/productionServices';
import orderServices from '../../services/orderServices';
import customerApi from '../../api/customerApi';
import packingServices from '../../services/packingServices';
import _ from 'lodash';

export const getListCustomer = () => dispatch => {
  customerApi.getListCustomerByQaDAL().then(res => {
    if (res.success) {
      dispatch({ type: C.GET_LIST_CUSTOMER, payload: res.data });
      const [item] = res.data.items;

      dispatch(changeSelectedCustomer(item));
    }
  });
};

export const changeSelectedCustomer = data => dispatch => {
  if (data._id) {
    dispatch({ type: C.CHANGE_CUSTOMER, payload: { data } });
    let query = { customer: data._id };
    productionServices.getListPackingOrders({ query }).then(res => {
      if (res.success) {
        dispatch({ type: C.CHANGE_LIST_CUSTOMERPO, payload: res.data });
        const [item] = res.data.items;
        dispatch(changeSelectedCustomerPO(item));
      }
    });
  }
};

export const changeSelectedCustomerPO = data => dispatch => {
  if (data._id) {
    dispatch({ type: C.CHANGE_CUSTOMERPO, payload: { data } });
    orderServices.getListContractor(data._id).then(res => {
      if (res.success) {
        dispatch({ type: C.CHANGE_LIST_CONTRACTOR, payload: res.data });
        const [item] = res.data;
        dispatch(changeSelectedContractor(item));
      }
    });
  }
};

export const changeSelectedContractor = data => dispatch => {
  if (data._id) {
    dispatch({ type: C.CHANGE_CONTRACTOR, payload: { data } });
  }
};
export const changeSelectCartonType = data => dispatch => {
  if (data) {
    dispatch({ type: C.CHANGE_CARTON_TYPE, payload: { data } });
  }
};

export const changePackingType = data => dispatch => {
  if (data) {
    dispatch({ type: C.CHANGE_PACKING_TYPE, payload: { data } });
  }
};

export const getInfoPacking = data => dispatch => {
  dispatch({ type: E.INFO_PACKING, payload: { data } });
};

export const getPacking = id => dispatch => {
  if (id) {
    packingServices.getPacking(id).then(res => {
      if (res.success) {
        dispatch({ type: C.CHANGE_PACKING_TYPE, payload: res.data.type });
        packingServices.getPackingByOrder(res.data.order).then(r => {
          if (r.success) {
            dispatch({ type: C.GET_PACKING_BY_ORDER, payload: r.data });
            dispatch({ type: E.GET_PACKING, payload: res.data, temp: r.data });
          }
        });
      }
    });
  }
};

export const getPackingByOrder = (data, options = null) => dispatch => {
  if (data) {
    packingServices.getPackingByOrder(data, options).then(res => {
      if (res.success) {
        dispatch({ type: C.GET_PACKING_BY_ORDER, payload: res.data });
      }
    });
  }
};

export const emptyPacking = () => dispatch => {
  dispatch({ type: C.EMPTY_PACKING, payload: null });
};

export const changeCheckedStyle = (id, checked) => dispatch => {
  dispatch({ type: C.CHANGE_CHECKED_STYLE, payload: { id: id, checked: checked } });
};

export const createPacking = (packedStyles, customer, customerPO, contractor, packingType, cartonType) => dispatch => {
  if (packedStyles && customer && customerPO && contractor && packingType) {
    const packing = {
      type: packingType,
      carton: cartonType,
      totalNW: 0,
      totalGW: 0,
      customer: customer._id,
      order: customerPO._id,
      contractor: contractor._id,
      styles: _.map(packedStyles, packedStyle => {
        return {
          style: packedStyle._id,
          name: packedStyle.style,
          active: true,
          cutTickets: _.map(packedStyle.cutTickets, cutTicket => {
            return {
              cutTicket: cutTicket._id,
              colors: _.map(cutTicket.colors, color => {
                return {
                  color: color._id,
                  packings: [
                    {
                      props:
                        packingType === 'Carton'
                          ? {
                              no: 0,
                              group: '',
                              noBegin: 1,
                              noEnd: 1,
                              nwKgs: 0,
                              gwKgs: 0,
                              l: 0,
                              w: 0,
                              h: 0,
                            }
                          : {},
                      items: _.map(color.sizes, size => {
                        return {
                          name: size.name,
                          remaining: 0,
                          planQuantity: 0,
                        };
                      }),
                    },
                  ],
                };
              }), // 1 packing
            };
          }),
          packings: [],
        };
      }),
    };
    dispatch({ type: C.CREATE_PACKING, payload: packing });
  }
};

export const changePackingNumber = (data, styleId, cutId, colorId, sizeName, total) => dispatch => {
  if (data && data <= total) {
    dispatch({ type: C.CHANGE_PACKING_NUMBER, payload: { data, styleId, cutId, colorId, sizeName } });
  }
};

export const changeActualPackingNumber = (data, styleId, cutId, colorId, sizeName, total) => dispatch => {
  if (data && data <= total) {
    dispatch({ type: C.CHANGE_ACTUAL_PACKING_NUMBER, payload: { data, styleId, cutId, colorId, sizeName } });
  }
};

export const changeActualPackingCartonNumber = (data, styleId, cutId, colorId, sizeName, no, total) => dispatch => {
  if (data <= total) {
    dispatch({ type: C.CHANGE_ACTUAL_PACKING_CARTON_NUMBER, payload: { data, styleId, cutId, colorId, sizeName, no } });
  }
};

export const changeGwKgsNwKgsPackingCartonNumber = (data, styleId, cutId, colorId, sizeName, no, total) => dispatch => {
  if (data <= total) {
    dispatch({
      type: C.CHANGE_GWKGSNWKGS_PACKING_CARTON_NUMBER,
      payload: { data, styleId, cutId, colorId, sizeName, no },
    });
  }
};

export const changePackingNote = (data, styleId, cutId, colorId) => dispatch => {
  dispatch({ type: C.CHANGE_PACKING_NOTE, payload: { data, styleId, cutId, colorId } });
};

export const setRemainingTempCarton = (styleId, cutId, colorId, sizeName, total) => dispatch => {
  if (styleId && cutId && colorId && sizeName && total) {
    dispatch({
      type: C.SET_REMAINING_CARTON,
      payload: { styleId, cutId, colorId, sizeName, total },
    });
  }
};

export const changePropsCarton = (name, value, styleId, cutId, colorId) => dispatch => {
  if (name && value > 0) {
    dispatch({ type: C.CHANGE_PROPS_CARTON, payload: { name, value, styleId, cutId, colorId } });
  }
};
export const changePropsCartonFloat = (name, value, styleId, cutId, colorId) => dispatch => {
  if (name && Number(value) > 0) {
    dispatch({ type: C.CHANGE_PROPS_CARTON, payload: { name, value, styleId, cutId, colorId } });
  }
};

export const createCarton = (styleId, cutTicketId, colorId) => dispatch => {
  dispatch({ type: C.CREATE_CARTON, payload: { styleId, cutTicketId, colorId } });
};
export const createCartonV2 = (packing, styleId, packings) => dispatch => {
  packing.styles.map((style, index) => {
    if (style.style == styleId) {
      packing.styles[index].packings.push(...packings);
    }
  });
  packing.totalGW = _.sum(_.flattenDeep(packing.styles.map(s => s.packings.map(pk => pk.props.gwKgs || 0))));
  packing.totalNW = _.sum(_.flattenDeep(packing.styles.map(s => s.packings.map(pk => pk.props.nwKgs || 0))));
  packing.planPpTotal = _.sum(
    _.flattenDeep(packing.styles.map(s => s.packings.map(pk => pk.items.map(item => item.planQuantity || 0))))
  );
  packing.actualPpTotal = _.sum(
    _.flattenDeep(
      packing.styles.map(s =>
        s.packings.map(pk => pk.items.map(item => Number(item.actualQuantity || item.planQuantity || 0)))
      )
    )
  );
  console.log('packing-createCartonV2:', packing);
  dispatch({ type: C.CREATE_CARTON_V2, payload: packing });
};

export const deleteCartonV2 = (packing, styleId, noBegin, noEnd) => dispatch => {
  const qttNo = noEnd - noBegin + 1;
  packing.styles.map((style, index) => {
    if (style.style == styleId) {
      _.remove(packing.styles[index].packings, pk => pk.props.no >= noBegin && pk.props.no <= noEnd);
      packing.styles[index].packings.forEach(pk => {
        if (pk.props.no > noEnd) {
          const [noBegin, noEnd] = pk.props.group.split('-');
          pk.props.no = pk.props.no - qttNo;
          pk.props.group = `${Number(noBegin) - qttNo}${noEnd ? `-${Number(noEnd) - qttNo}` : ''}`;
        }
      });
    }
  });

  packing.totalGW = _.sum(_.flattenDeep(packing.styles.map(s => s.packings.map(pk => pk.props.gwKgs || 0))));
  packing.totalNW = _.sum(_.flattenDeep(packing.styles.map(s => s.packings.map(pk => pk.props.nwKgs || 0))));
  packing.planPpTotal = _.sum(
    _.flattenDeep(packing.styles.map(s => s.packings.map(pk => pk.items.map(item => item.planQuantity || 0))))
  );
  packing.actualPpTotal = _.sum(
    _.flattenDeep(
      packing.styles.map(s =>
        s.packings.map(pk => pk.items.map(item => Number(item.actualQuantity || item.planQuantity || 0)))
      )
    )
  );
  console.log('packing-deleteCartonV2:', packing);
  dispatch({ type: C.CREATE_CARTON_V2, payload: packing });
};

export const savePacking = data => dispatch => {
  let packing = _.cloneDeep(data);
  if (packing) {
    _.remove(packing.styles, style => style.active === false);
    if (packing.type == 'Carton') {
      _.map(packing.styles, style =>
        _.map(style.cutTickets, cut => _.map(cut.colors, color => _.remove(color.packings, color.packings[0])))
      );
    }
    packingServices.addPacking(packing).then(res => {
      if (res.success) {
        dispatch({ type: C.SAVE, payload: res.success });
        dispatch({ type: types.APP.ADD_PAGE_CURRENT, data: null });
      }
    });
  }
};

export const editPacking = (id, data) => dispatch => {
  let packing = _.cloneDeep(data);
  if (packing) {
    _.remove(packing.styles, style => style.active === false);
    if (packing.type == 'Carton') {
      _.map(packing.styles, style =>
        _.map(style.cutTickets, cut => _.map(cut.colors, color => _.remove(color.packings, color.packings[0])))
      );
    }
    packingServices.editPacking(id, packing).then(res => {
      if (res.success) {
        dispatch({ type: C.SAVE, payload: res.success });
        dispatch({ type: types.APP.ADD_PAGE_CURRENT, data: null });
      }
    });
  }
};
export const changeEmptyData = () => dispatch => {
  dispatch({ type: C.CHANGE_EMPTY_DATA, payload: null });
};

export const deleteCarton = (styleId, cutId, colorId, no) => dispatch => {
  dispatch({ type: C.DELETE_CARTON, payload: { styleId, cutId, colorId, no } });
};

export const changeTotal = (value, name) => dispatch => {
  dispatch({ type: C.CHANGE_TOTAL, payload: { value, name } });
};

export const resetPackedQuantity = (styleId, cutId, colorId, sizeName, value) => dispatch => {
  dispatch({ type: E.CHANGE_PACKED_QUANTITY, payload: { styleId, cutId, colorId, sizeName, value } });
};

export const canResetPackedQuantity = () => dispatch => {
  dispatch({ type: E.CAN_CHANGE_PACKED_QUANTITY });
};

export const getPackingByOrderAndContractor = (oId, cId) => dispatch => {
  if (!!oId && !!cId) {
    packingServices.getPackingByOrderAndContractor(oId, cId).then(res => {
      if (res.success) {
        dispatch({ type: C.GET_PACKING_BY_ORDER, payload: res.data });
      }
    });
  }
};

export const changeShowCartonDetail = (styleId, cutTicketId, colorId, group) => dispatch => {
  dispatch({ type: E.CHANGE_SHOW_CARTON_DETAIL, payload: { styleId, cutTicketId, colorId, group } });
};

export const changeCheckedPacking = (styleId, cutTicketId, colorId, group, cartonNo, value) => dispatch => {
  dispatch({ type: E.CHANGE_CHECKED_PACKING, payload: { styleId, cutTicketId, colorId, group, cartonNo, value } });
};

export const changeActualPackingCartonNumberForEdit = (
  styleId,
  cutTicketId,
  colorId,
  sizeName,
  no,
  value,
  total
) => dispatch => {
  if (value <= total) {
    dispatch({
      type: E.CHANGE_PACKING_ACTUAL_NUMBER,
      payload: { styleId, cutTicketId, colorId, sizeName, no, value, total },
    });
  }
};
export const changeGwKgsNwKgsPackingCartonNumberForEdit = (
  styleId,
  cutTicketId,
  colorId,
  sizeName,
  no,
  value,
  total
) => dispatch => {
  if (value <= total) {
    dispatch({
      type: E.CHANGE_GWKGSNWKGS_ACTUAL_NUMBER,
      payload: { styleId, cutTicketId, colorId, sizeName, no, value, total },
    });
  }
};

export const stock = id => {
  packingServices.stockPacking(id);
};

export const reship = id => {
  packingServices.reshipPacking(id);
};

export const editItemOfpacking = (styleId, packing, no, items) => dispatch => {
  let pk = packing.styles.find(s => s.style == styleId).packings.find(pk => pk.props.no == no);
  pk.items = items;
  packing.totalGW = _.sum(_.flattenDeep(packing.styles.map(s => s.packings.map(pk => pk.props.gwKgs || 0))));
  packing.totalNW = _.sum(_.flattenDeep(packing.styles.map(s => s.packings.map(pk => pk.props.nwKgs || 0))));
  packing.planPpTotal = _.sum(
    _.flattenDeep(packing.styles.map(s => s.packings.map(pk => pk.items.map(item => item.planQuantity || 0))))
  );
  packing.actualPpTotal = _.sum(
    _.flattenDeep(
      packing.styles.map(s =>
        s.packings.map(pk => pk.items.map(item => Number(item.actualQuantity || item.planQuantity || 0)))
      )
    )
  );
  dispatch({ type: C.EDIT_PACKING, payload: packing });
};
