import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { withRouter } from 'react-router';
import I18n from 'i18n-js';
import * as selector from '../../../../../selectors/ship-out/createPklShip';
import * as selectorEdit from '../../../../../selectors/ship-out/editPklShip';
class CutTicketContainersPrepack extends Component {
  showCartonDetail = (orderId, styleId, checkShow, group) => {
    let { showDetail } = this.props;
    if (checkShow) {
      showDetail.push({
        order: orderId,
        style: styleId,
        group,
      });
    } else {
      _.remove(showDetail, elm => elm.style == styleId && elm.order == orderId && elm.group == group);
    }
    setState({ showDetail });
  };
  changeCheckedPacking = (orderId, styleId, group, value) => {
    const { actions, setState } = this.props;
    const key = `${orderId}-${styleId}-${group}`;
    const action = actions[key];
    if (!action) {
      actions[key] = { key, check: value };
    } else {
      action.check = value;
    }
    setState({ actions });
  };

  changePackedNumber = (orderId, styleId, group, pks, value) => {
    const { actions, setState } = this.props;
    const key = `${orderId}-${styleId}-${group}`;
    const action = actions[key];
    value = value > pks.length ? pks.length : value < 1 ? 1 : value;
    const nos = [];
    for (let i = 0; i < value; i++) {
      nos.push(pks[i].props.no);
    }
    if (!action) {
      actions[key] = { key, check: value };
    } else {
      action.package = value;
      action.nos = nos;
    }
    setState({ actions });
  };

  getActualAndRemainingPacking = (style, cutId, colorId) => {
    let actualQuantity = 0;
    let remaining = 0;
    style.packings.forEach(pk => {
      pk.items.forEach(item => {
        if (item.color == colorId && item.cutTicket == cutId) {
          actualQuantity += Number(item.actualQuantity || 0);
          remaining += Number(item.planQuantity || 0) - Number(item.actualQuantity || 0);
        }
      });
    });
    return { actualQuantity, remaining };
  };

  defineDataShow = (style, orderId) => {
    if (!style || !orderId) return { packings: [], sizeNames: [] };
    const { actions } = this.props;
    const styleClone = _.cloneDeep(style);
    const colors = _.flattenDeep(styleClone.cutTickets.map(ct => ct.colors));
    const sizeNames = _.uniq(
      _.flattenDeep(styleClone.cutTickets.map(ct => ct.colors.map(c => c.color.sizes.map(s => s.name))))
    );

    let packings = _.cloneDeep(
      styleClone.packings.map(pk => {
        const groupColor = _.groupBy(pk.items, item => item.color);
        pk.items = Object.values(groupColor)
          .map(cs => {
            const item = {};
            cs.forEach(c => {
              if (['gwKgs', 'nwKgs'].includes(c.name)) {
                pk.props[c.name] = c.value;
              }
              item[c.name] = c.actualQuantity || c.planQuantity;
              item.color = (colors.find(c2 => c2.color._id == c.color) || {}).color;
            });
            return item;
          })
          .filter(item => item.color);
        return pk;
      })
    );
    packings = _.sortBy(
      Object.values(_.groupBy(packings, pk => pk.props.group)).map(pks => {
        const [noBegin, noEnd] = pks[0].props.group.split('-');
        const key = `${orderId}-${style.style._id}-${pks[0].props.group}`;
        let action = _.cloneDeep(actions[key]) || {};
        action.check = action.check == undefined ? true : action.check;
        action.package = action.package || pks.length;
        return pks.length == 1
          ? { ...pks[0], action }
          : {
              items: [...pks[0].items],
              props: {
                ...pks[0].props,
                noBegin,
                noEnd,
              },
              action,
              childs: pks,
            };
      }),
      pk => pk.props.group
    );
    return { packings, sizeNames };
  };

  render() {
    const { order, listActiveStyleIds, view, showDetail } = this.props;
    console.log('this.props:', this.props);
    const styleId = listActiveStyleIds[order._id];
    const style = order.styles.find(s => s.style._id == styleId);
    const { packings, sizeNames } = this.defineDataShow(style, order._id);
    return (
      <div>
        {style &&
          style.cutTickets.map((cutTicket, index) => {
            return (
              <div>
                <div
                  className="d-flex justify-content-between"
                  style={{
                    height: 40,
                    paddingTop: 10,
                    fontSize: 15,
                  }}>
                  <div className="d-flex">
                    <p className="size-12">{I18n.t('PageShiping.CutTicket')}</p>
                    <span className="color-orange bold mg-left-50 size-14">{cutTicket.cutTicket.cutTicket}</span>
                  </div>

                  <div className="d-flex justify-content-end">
                    <div className="d-flex mg-right-30">
                      <p className="size-12 mg-right-15">{I18n.t('PageShiping.Lable')}</p>
                      <span className="size-12 bold">{cutTicket.cutTicket.mainLabel || ''}</span>
                    </div>
                    <div className="d-flex mg-right-30">
                      <p className="size-12 mg-right-15">{I18n.t('PageShiping.Store')}</p>
                      <span className="size-12 bold">
                        {(cutTicket.cutTicket.store && cutTicket.cutTicket.store.name) || ''}
                      </span>
                    </div>
                    <div className="size-12 color-link">
                      <a>
                        {(cutTicket.cutTicket.store &&
                          cutTicket.cutTicket.store.packingStandard &&
                          cutTicket.cutTicket.store.packingStandard.name) ||
                          ''}
                      </a>
                    </div>
                  </div>
                </div>
                {/* {colorComponents} */}
                {cutTicket.colors &&
                  cutTicket.colors.map((color, index) => {
                    const { actualQuantity, remaining } = this.getActualAndRemainingPacking(
                      style,
                      cutTicket.cutTicket._id,
                      color.color._id
                    );
                    return (
                      <div>
                        <div className="clear-both mg-top-5">
                          <div className="display-flex bg-white">
                            <div className="pd-10 display-flex align-items-center pd-20 w-180">
                              <h3 className="center mg-auto size-12">
                                <span className="fw-normal">{I18n.t('PageShiping.Color')}</span>
                                <br />
                                <br />
                                <strong className="size-13">{color.color.name}</strong>
                                <br />
                                <br />
                              </h3>
                            </div>
                            <div className="pd-10 border-left-dashed">
                              <table className="global-table mg-auto tr-height-30 box-shadow-none">
                                <tbody>
                                  <tr style={{ borderBottom: 'unset' }} className="bg-none">
                                    <td className="fw-normal">
                                      <h3>
                                        {I18n.t('PageShiping.TotalQTY')} :{' '}
                                        <strong className="color-red">{actualQuantity}</strong>
                                      </h3>
                                    </td>
                                    {sizeNames.map((sizeName, sId) => {
                                      // let qty = getQtyColorConst(orderId, styleId, cutId, data.color._id, size);
                                      return (
                                        <td className="center">
                                          {' '}
                                          {sizeName} : {0}
                                        </td>
                                      );
                                    })}
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            );
          })}
        <div className="bg-white mg-top-2" style={{ marginTop: 5 }}>
          <table className="global-table w-full">
            <tbody>
              <tr>
                <th className="center"></th>
                <th className="center">{I18n.t('PageShiping.CTNNo')}</th>
                <th className="center">{I18n.t('PageShiping.Packed')}</th>
                <th className="center">{I18n.t('PageShiping.NW')}</th>
                <th className="center">{I18n.t('PageShiping.GW')}</th>
                <th className="center">{I18n.t('PageShiping.MEANS')}</th>
                <th className="center">{I18n.t('PageShiping.M3')}</th>
                <th className="center">Color</th>
                {sizeNames.map((sizeName, idx) => (
                  <th className="center">{sizeName}</th>
                ))}
                <th className="center">{I18n.t('PageShiping.QTY')}</th>
                <th className="center">{I18n.t('PageShiping.QTY/CTN')}</th>
                <th className="center">{I18n.t('PageShiping.Total')}</th>
              </tr>
              {packings.map((pkRoot, idx) => {
                const check = (showDetail || []).find(
                  sd => sd.style == styleId && sd.order == order._id && sd.group == pkRoot.props.group
                );
                const packings2 = !check ? [pkRoot] : [pkRoot, ...pkRoot.childs];
                return packings2.map((pk, pkIdx) => {
                  const qty_ctn = pk.items.reduce(
                    (x, y) => x + Object.values(y).reduce((a, b) => a + (Number(b) || 0), 0),
                    0
                  );
                  return pk.items.map((item, itemIdx) => {
                    return (
                      <tr className={pkIdx !== 0 ? 'bg-gray' : ''} style={{ borderBottom: '1px solid #ccc' }}>
                        {itemIdx == 0 &&
                          (view && pk.childs ? (
                            <td className="center" rowspan={pk.items.length}>
                              <span
                                className="fa fa-caret-down cursor-pointer"
                                style={{ marginLeft: 10 }}
                                onClick={() =>
                                  this.showCartonDetail(order._id, styleId, !check, pk.props.group)
                                }></span>
                            </td>
                          ) : (
                            <td className="center style-checkbox" rowspan={pk.items.length}>
                              <input
                                checked={pk.action.check}
                                type="checkbox"
                                id={`${order._id}:${styleId}:${pk.props.group}`}
                                onChange={() =>
                                  this.changeCheckedPacking(order._id, styleId, pk.props.group, !pk.action.check)
                                }
                              />
                              <label htmlFor={`${order._id}:${styleId}:${pk.props.group}`}>
                                <span>
                                  <span />
                                </span>
                              </label>
                            </td>
                          ))}

                        {itemIdx == 0 &&
                          (pk.childs ? (
                            <td className="center" rowspan={pk.items.length}>
                              {pk.props.noBegin}&nbsp;&nbsp;&rArr;&nbsp;&nbsp;{pk.props.noEnd}
                            </td>
                          ) : (
                            <td className="center" rowspan={pk.items.length}>
                              {pk.props.no}
                            </td>
                          ))}
                        {itemIdx == 0 && (
                          <td className="center" rowspan={pk.items.length}>
                            <input
                              type="number"
                              min="0"
                              className="form-control center pd-right-0 mr-10"
                              value={pk.action.check ? pk.action.package : 0}
                              onChange={e =>
                                this.changePackedNumber(
                                  order._id,
                                  styleId,
                                  pk.props.group,
                                  pk.childs || [pk],
                                  e.target.value
                                )
                              }
                            />
                          </td>
                        )}
                        {itemIdx == 0 && (
                          <td className="center" rowspan={pk.items.length}>
                            {pk.props.nwKgs}
                          </td>
                        )}
                        {itemIdx == 0 && (
                          <td className="center" rowspan={pk.items.length}>
                            {pk.props.gwKgs}
                          </td>
                        )}
                        {itemIdx == 0 && (
                          <td className="center" rowspan={pk.items.length}>
                            {pk.props.l}" x {pk.props.w}" x {pk.props.h}"
                          </td>
                        )}
                        {itemIdx == 0 && (
                          <td className="center" rowspan={pk.items.length}>
                            {pk.props.m3}
                          </td>
                        )}
                        <td className="center">{item.color.name}</td>
                        {sizeNames.map((sizeName, idx) => (
                          <td className="center color-orange">{item[sizeName] || 0}</td>
                        ))}
                        {itemIdx == 0 && (
                          <td className="center color-green" rowspan={pk.items.length}>
                            {pk.childs ? pk.childs.length : 1}
                          </td>
                        )}
                        {itemIdx == 0 && (
                          <td className="center color-green" rowspan={pk.items.length}>
                            {qty_ctn}
                          </td>
                        )}
                        {itemIdx == 0 && (
                          <td className="center color-red" rowspan={pk.items.length}>
                            {pk.action.check ? qty_ctn * (pk.childs ? pk.childs.length : 1) : 0}
                          </td>
                        )}
                      </tr>
                    );
                  });
                });
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  listActiveStyleIds: selector.getActiveStyleIds(state),
  view: selectorEdit.getView(state),
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CutTicketContainersPrepack));
