import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Select from 'react-select';
import appActions from '../../actions/appActions';
import userActions from '../../actions/userActions';
import systemSettingActions from '../../actions/systemSettingActions';
import BaseComponent from '../base/BaseComponent';
import Modal from 'react-modal';
import _ from 'lodash';
import I18n from 'i18n-js';
import { toastr } from 'react-redux-toastr';

import icXGrayIcon from '../../stylesheets/images/ic-x-gray.svg';

class PageSystemSettings extends BaseComponent {
  constructor(props, context) {
    super(props, context);

    this.state = {
      items: [],
      settings: {},
      combine: {},
      notifications: {},
      removeSelected: true,
      disabled: false,
      stayOpen: false,
      valueYield: [],
      valueCustomerPOAdded: [],
      valueWarehouseRevised: [],
      valueColorQuantityRevised: [],
      valuePurchaseOrderSubmitted: [],
      valuePurchased: [],
      valuePurchase: [],
      valueAdditional: [],
      valueApprovepurchase: [],
      valueStockIn: [],
      valueReceived: [],
      valueStockOut: [],
      valueApproveOutbound: [],
      valueGSample: [],
      valueMSample: [],
      valueSampleResubmitted: [],
      valueProduction: [],
      valueQAFailed: [],
      valueShipIn: [],
      valueShipOut: [],
      valueNotifyShipping: [],
      openRemoveEmailModal: false,
    };
  }

  componentWillMount = () => {
    this.props.userPropsActions.getListUser();
    this.props.systemSettingPropsActions.getListSystemSetting();
    this.getListItems();
  }

  componentWillUpdate = (nextProps, nextState) => {
    if (this.props.systemSettingState.reload != nextProps.systemSettingState.reload) {
      nextProps.systemSettingPropsActions.getListSystemSetting();
      this.getListItems();
    }
  }

  validateSampleApprove() {
    const { settings } = this.state;
    const resubmittedAlert = _.get(settings, 'sampleApproval.resubmittedAlert', undefined);
    if (resubmittedAlert) {
      if (resubmittedAlert <= 0) {
        toastr.error(I18n.t('Error'), I18n.t('ErrorMessages.Settings.PleaseInputNumberLargerThanZero'));
        return false;
      }
    } else {
      toastr.error(I18n.t('Error'), I18n.t('ErrorMessages.Settings.ResubmittedAlertNumberInvalid'));
      return false;
    }
    return true;
  }

  getListItems = (options = {}) => {
    let query = { populate: 'notifications.shipOut,notifications.approveOutbound,notifications.shipIn,notifications.QAFailed,' +
            'notifications.productionFinished,notifications.GSAprrovedOrRejected, notifications.MSAprrovedOrRejected, notifications.SampleApprovalResubmitted' +
            ',' +
            'notifications.stockOut,notifications.receivedQuantityDeviated,notifications.stockIn,notifications.additionalPurchase,' +
            'notifications.approvePurchase,notifications.POStock,notifications.PQExceed,notifications.yieldRevised,' +
            'notifications.colorQuantityRevised,notifications.warehouseRevised,notifications.customerPOAdded,' +
            'notifications.notifyShipping,notifications.customerPOAdded,notifications.warehouseRevised,notifications.colorQuantityRevised,notifications.purchaseOrderSubmitted' };
    this.props.systemSettingPropsActions.getListNotifications(query);
  }

  componentWillReceiveProps = newProps => {
    const { items, settings, notifications } = this.state;
    const newItems = _.cloneDeep(_.get(newProps.userState, 'items') || []);
    const newSettings = _.cloneDeep(_.get(newProps.systemSettingState, 'items') || {});
    const newNotifications = _.cloneDeep(_.get(newProps.systemSettingState, 'notifications') || {});
    !_.isEqual(items, newItems) && this.setState({ items: newItems });
    !_.isEqual(settings, newSettings) && this.setState({ settings: newSettings });
    !_.isEqual(notifications, newNotifications) && this.setState({ notifications: newNotifications });
  }


  handleSelectYield = valueYield => {
    this.setState({ valueYield });
  }

  handleSelectCustomerPOAdded = valueCustomerPOAdded => {
      this.setState({ valueCustomerPOAdded });
  }

  handleSelectWarehouseRevised = valueWarehouseRevised => {
      this.setState({ valueWarehouseRevised });
  }
  handleSelectColorQuantityRevised = valueColorQuantityRevised => {
      this.setState({ valueColorQuantityRevised });
  }
  handleSelectPurchaseOrderSubmitted = valuePurchaseOrderSubmitted => {
      this.setState({ valuePurchaseOrderSubmitted });
  }

  handleSelectPurchased = valuePurchased => {
    this.setState({ valuePurchased });
  }
  handleSelectPurchase = valuePurchase => {
    this.setState({ valuePurchase });
  }
  handleSelectAdditional = valueAdditional => {
    this.setState({ valueAdditional });
  }
  handleSelectApprovepurchase = valueApprovepurchase => {
    this.setState({ valueApprovepurchase });
  }
  handleSelectStockIn = valueStockIn => {
    this.setState({ valueStockIn });
  }
  handleSelectReceived = valueReceived => {
    this.setState({ valueReceived });
  }
  handleSelectStockOut = (valueStockOut) => {
    this.setState({ valueStockOut });
  }
  handleSelectApproveOutbound = (valueApproveOutbound) => {
    this.setState({ valueApproveOutbound });
  }
  handleSelectGSample = (valueGSample) => {
    this.setState({ valueGSample });
  }
  handleSelectMSample = (valueMSample) => {
    this.setState({ valueMSample });
  }
  handleSampleResubmitted = (valueSampleResubmitted) => {
    this.setState({ valueSampleResubmitted });
  }
  handleSelectProduction = (valueProduction) => {
    this.setState({ valueProduction });
  }
  handleSelectQAFailed = (valueQAFailed) => {
    this.setState({ valueQAFailed });
  }
  handleSelectShipIn = (valueShipIn) => {
    this.setState({ valueShipIn });
  }
  handleSelectShipOut = (valueShipOut) => {
    this.setState({ valueShipOut });
  }
  handleSelectNotifyShipping = (valueNotifyShipping) => {
    this.setState({ valueNotifyShipping });
  }

  setProduction = (field, value) => {
    let items = this.state.settings;
    items.production[field] = value;
    this.setState({ settings: items });
  }

  setSampleApproval = (field, value) => {
    let items = this.state.settings;
    items.sampleApproval[field] = value;
    this.setState({ settings: items });
  }

  setPurchaseDeviation = (field, value) => {
    let PurchaseDeviation = this.state.settings;
    PurchaseDeviation[field] = value;
    this.setState({ settings: PurchaseDeviation });
  }

  setOutboundDeviation = (field, value) => {
    let OutboundDeviation = this.state.settings;
    OutboundDeviation[field] = value;
    this.setState({ settings: OutboundDeviation });
  }


  setAgeDefinition = (field, value) => {
    let items = this.state.settings;
    items.ageDefinition[field] = value;
    this.setState({ settings: items });
  }

  SetEmailSetting = (field, value) => {
    let items = this.state.settings;
    items.notifications[field] = value;
    this.setState({ settings: items });
  }

  closeCFRemoveEmail = () => {
    this.setState({
        openRemoveEmailModal: false
    })
  }
  
  CFRemoveEmail = () => {
    const { fieldEmail, idEmail } = this.state;
    let idsettings = this.state.settings._id;
    let items = this.state.settings;
    let notifications = this.state.settings.notifications[fieldEmail];
    let newitems = [];
    newitems = _.without(notifications, idEmail, _.isEqual);
    items.notifications[fieldEmail] = newitems;
    this.props.systemSettingPropsActions.updateEmailSetting(idsettings, items);
    this.closeCFRemoveEmail();
  }
  
  remove = (field, id) => {
    this.setState({
      openRemoveEmailModal: true,
      fieldEmail: field,
      idEmail: id
    })
  }

  save = () => {
    let idsettings = this.state.settings._id;
    let { valueYield, valuePurchased,
      valuePurchase, valueAdditional, valueApprovepurchase, valueStockIn, valueReceived, valueStockOut, valueApproveOutbound,
        valueSampleResubmitted, valueMSample, valueGSample, valueProduction, valueQAFailed, valueShipIn, valueShipOut, valueNotifyShipping,
        valueCustomerPOAdded, valueWarehouseRevised, valueColorQuantityRevised, valuePurchaseOrderSubmitted} = this.state;

    let newYield = _.compact(_.map(valueYield, 'id'));
    let stringYield = _.isEmpty(valueYield) ? this.state.settings.notifications.yieldRevised : newYield.toString();
    let pushYield = this.state.settings.notifications.yieldRevised;
    _.isEmpty(valueYield) ? this.state.settings.notifications.yieldRevised : pushYield.push(stringYield);
    let dataYield = _.isEmpty(valueYield) ? this.state.settings.notifications.yieldRevised : _.union(_.split(_.compact(pushYield), ','));

    let newCustomerPOAdded = _.compact(_.map(valueCustomerPOAdded, 'id'));
    let stringCustomerPOAdded = _.isEmpty(valueCustomerPOAdded) ? this.state.settings.notifications.customerPOAdded : newCustomerPOAdded.toString();
    let pushCustomerPOAdded = this.state.settings.notifications.customerPOAdded;
    _.isEmpty(valueCustomerPOAdded) ? this.state.settings.notifications.customerPOAdded : pushCustomerPOAdded.push(stringCustomerPOAdded);
    let dataCustomerPOAdded = _.isEmpty(valueCustomerPOAdded) ? this.state.settings.notifications.customerPOAdded : _.union(_.split(_.compact(pushCustomerPOAdded), ','));

    let newWarehouseRevised = _.compact(_.map(valueWarehouseRevised, 'id'));
    let stringWarehouseRevised = _.isEmpty(valueWarehouseRevised) ? this.state.settings.notifications.warehouseRevised : newWarehouseRevised.toString();
    let pushWarehouseRevised = this.state.settings.notifications.warehouseRevised;
    _.isEmpty(valueWarehouseRevised) ? this.state.settings.notifications.warehouseRevised : pushWarehouseRevised.push(stringWarehouseRevised);
    let dataWarehouseRevised = _.isEmpty(valueWarehouseRevised) ? this.state.settings.notifications.warehouseRevised : _.union(_.split(_.compact(pushWarehouseRevised), ','));

    let newColorQuantityRevised = _.compact(_.map(valueColorQuantityRevised, 'id'));
    let stringColorQuantityRevised = _.isEmpty(valueColorQuantityRevised) ? this.state.settings.notifications.colorQuantityRevised : newColorQuantityRevised.toString();
    let pushColorQuantityRevised = this.state.settings.notifications.colorQuantityRevised;
    _.isEmpty(valueColorQuantityRevised) ? this.state.settings.notifications.colorQuantityRevised : pushColorQuantityRevised.push(stringColorQuantityRevised);
    let dataColorQuantityRevised = _.isEmpty(valueColorQuantityRevised) ? this.state.settings.notifications.colorQuantityRevised : _.union(_.split(_.compact(pushColorQuantityRevised), ','));

    let newPurchaseOrderSubmitted = _.compact(_.map(valuePurchaseOrderSubmitted, 'id'));
    let stringPurchaseOrderSubmitted = _.isEmpty(valuePurchaseOrderSubmitted) ? this.state.settings.notifications.purchaseOrderSubmitted : newPurchaseOrderSubmitted.toString();
    let pushPurchaseOrderSubmitted = this.state.settings.notifications.purchaseOrderSubmitted;
    _.isEmpty(valuePurchaseOrderSubmitted) ? this.state.settings.notifications.purchaseOrderSubmitted : pushPurchaseOrderSubmitted.push(stringPurchaseOrderSubmitted);
    let dataPurchaseOrderSubmitted = _.isEmpty(valuePurchaseOrderSubmitted) ? this.state.settings.notifications.purchaseOrderSubmitted : _.union(_.split(_.compact(pushPurchaseOrderSubmitted), ','));

    let newPurchased = _.compact(_.map(valuePurchased, 'id'));
    let stringPurchased = _.isEmpty(valuePurchased) ? this.state.settings.notifications.PQExceed : newPurchased.toString();
    let pushPurchased = this.state.settings.notifications.PQExceed;
    _.isEmpty(valuePurchased) ? this.state.settings.notifications.PQExceed : pushPurchased.push(stringPurchased);
    let dataPurchased = _.isEmpty(valuePurchased) ? this.state.settings.notifications.PQExceed : _.union(_.split(_.compact(pushPurchased), ','));

    let newPurchase = _.compact(_.map(valuePurchase, 'id'));
    let stringPurchase = _.isEmpty(valuePurchase) ? this.state.settings.notifications.POStock : newPurchase.toString();
    let pushPurchase = this.state.settings.notifications.POStock;
    _.isEmpty(valuePurchase) ? this.state.settings.notifications.POStock : pushPurchase.push(stringPurchase);
    let dataPurchase = _.isEmpty(valuePurchase) ? this.state.settings.notifications.POStock : _.union(_.split(_.compact(pushPurchase), ','));

    let newAdditional = _.compact(_.map(valueAdditional, 'id'));
    let stringAdditional = _.isEmpty(valueAdditional) ? this.state.settings.notifications.additionalPurchase : newAdditional.toString();
    let pushAdditional = this.state.settings.notifications.additionalPurchase;
    _.isEmpty(valueAdditional) ? this.state.settings.notifications.additionalPurchase : pushAdditional.push(stringAdditional);
    let dataAdditional = _.isEmpty(valueAdditional) ? this.state.settings.notifications.additionalPurchase : _.union(_.split(_.compact(pushAdditional), ','));

    let newApprovepurchase = _.compact(_.map(valueApprovepurchase, 'id'));
    let stringApprovepurchase = _.isEmpty(valueApprovepurchase) ? this.state.settings.notifications.approvePurchase : newApprovepurchase.toString();
    let pushApprovepurchase = this.state.settings.notifications.approvePurchase;
    _.isEmpty(valueApprovepurchase) ? this.state.settings.notifications.approvePurchase : pushApprovepurchase.push(stringApprovepurchase);
    let dataApprovepurchase = _.isEmpty(valueApprovepurchase) ? this.state.settings.notifications.approvePurchase : _.union(_.split(_.compact(pushApprovepurchase), ','));

    let newStockIn = _.compact(_.map(valueStockIn, 'id'));
    let stringStockIn = _.isEmpty(valueStockIn) ? this.state.settings.notifications.stockIn : newStockIn.toString();
    let pushStockIn = this.state.settings.notifications.stockIn;
    _.isEmpty(valueStockIn) ? this.state.settings.notifications.stockIn : pushStockIn.push(stringStockIn);
    let dataStockIn = _.isEmpty(valueStockIn) ? this.state.settings.notifications.stockIn : _.union(_.split(_.compact(pushStockIn), ','));

    let newReceived = _.compact(_.map(valueReceived, 'id'));
    let stringReceived = _.isEmpty(valueReceived) ? this.state.settings.notifications.receivedQuantityDeviated : newReceived.toString();
    let pushReceived = this.state.settings.notifications.receivedQuantityDeviated;
    _.isEmpty(valueReceived) ? this.state.settings.notifications.receivedQuantityDeviated : pushReceived.push(stringReceived);
    let dataReceived = _.isEmpty(valueReceived) ? this.state.settings.notifications.receivedQuantityDeviated : _.union(_.split(_.compact(pushReceived), ','));

    let newStockOut = _.compact(_.map(valueStockOut, 'id'));
    let stringStockOut = _.isEmpty(valueStockOut) ? this.state.settings.notifications.stockOut : newStockOut.toString();
    let pushStockOut = this.state.settings.notifications.stockOut;
    _.isEmpty(valueStockOut) ? this.state.settings.notifications.stockOut : pushStockOut.push(stringStockOut);
    let dataStockOut = _.isEmpty(valueStockOut) ? this.state.settings.notifications.stockOut : _.union(_.split(_.compact(pushStockOut), ','));

    let newApproveOutbound = _.compact(_.map(valueApproveOutbound, 'id'));
    let stringApproveOutbound = _.isEmpty(valueApproveOutbound) ? this.state.settings.notifications.approveOutbound : newApproveOutbound.toString();
    let pushApproveOutbound = this.state.settings.notifications.approveOutbound;
    _.isEmpty(valueApproveOutbound) ? this.state.settings.notifications.approveOutbound : pushApproveOutbound.push(stringApproveOutbound);
    let dataApproveOutbound = _.isEmpty(valueApproveOutbound) ? this.state.settings.notifications.approveOutbound : _.union(_.split(_.compact(pushApproveOutbound), ','));

    let newGSample = _.compact(_.map(valueGSample, 'id'));
    let stringGSample = _.isEmpty(valueGSample) ? this.state.settings.notifications.GSAprrovedOrRejected : newGSample.toString();
    let pushGSample = this.state.settings.notifications.GSAprrovedOrRejected;
    _.isEmpty(valueGSample) ? this.state.settings.notifications.GSAprrovedOrRejected : pushGSample.push(stringGSample);
    let dataGSample = _.isEmpty(valueGSample) ? this.state.settings.notifications.GSAprrovedOrRejected : _.union(_.split(_.compact(pushGSample), ','));

    let newMSample = _.compact(_.map(valueMSample, 'id'));
    let stringMSample = _.isEmpty(valueMSample) ? this.state.settings.notifications.MSAprrovedOrRejected : newMSample.toString();
    let pushMSample = this.state.settings.notifications.MSAprrovedOrRejected;
    _.isEmpty(valueMSample) ? this.state.settings.notifications.MSAprrovedOrRejected : pushMSample.push(stringMSample);
    let dataMSample = _.isEmpty(valueMSample) ? this.state.settings.notifications.MSAprrovedOrRejected : _.union(_.split(_.compact(pushMSample), ','));

    let newSampleResubmitted = _.compact(_.map(valueSampleResubmitted, 'id'));
    let stringSampleResubmitted = _.isEmpty(valueSampleResubmitted) ? this.state.settings.notifications.SampleApprovalResubmitted : newSampleResubmitted.toString();
    let pushSampleResubmitted = this.state.settings.notifications.SampleApprovalResubmitted;
    _.isEmpty(valueSampleResubmitted) ? this.state.settings.notifications.SampleApprovalResubmitted : pushSampleResubmitted.push(stringSampleResubmitted);
    let dataSampleResubmitted = _.isEmpty(valueSampleResubmitted) ? this.state.settings.notifications.SampleApprovalResubmitted : _.union(_.split(_.compact(pushSampleResubmitted), ','));

    let newProduction = _.compact(_.map(valueProduction, 'id'));
    let stringProduction = _.isEmpty(valueProduction) ? this.state.settings.notifications.productionFinished : newProduction.toString();
    let pushProduction = this.state.settings.notifications.productionFinished;
    _.isEmpty(valueProduction) ? this.state.settings.notifications.productionFinished : pushProduction.push(stringProduction);
    let dataProduction = _.isEmpty(valueProduction) ? this.state.settings.notifications.productionFinished : _.union(_.split(_.compact(pushProduction), ','));

    let newQAFailed = _.compact(_.map(valueQAFailed, 'id'));
    let stringQAFailed = _.isEmpty(valueQAFailed) ? this.state.settings.notifications.QAFailed : newQAFailed.toString();
    let pushQAFailed = this.state.settings.notifications.QAFailed;
    _.isEmpty(valueQAFailed) ? this.state.settings.notifications.QAFailed : pushQAFailed.push(stringQAFailed);
    let dataQAFailed = _.isEmpty(valueQAFailed) ? this.state.settings.notifications.QAFailed : _.union(_.split(_.compact(pushQAFailed), ','));

    let newShipIn = _.compact(_.map(valueShipIn, 'id'));
    let stringShipIn = _.isEmpty(valueShipIn) ? this.state.settings.notifications.shipIn : newShipIn.toString();
    let pushShipIn = this.state.settings.notifications.shipIn;
    _.isEmpty(valueShipIn) ? this.state.settings.notifications.shipIn : pushShipIn.push(stringShipIn);
    let dataShipIn = _.isEmpty(valueShipIn) ? this.state.settings.notifications.shipIn : _.union(_.split(_.compact(pushShipIn), ','));

    let newShipOut = _.compact(_.map(valueShipOut, 'id'));
    let stringShipOut = _.isEmpty(valueShipOut) ? this.state.settings.notifications.shipOut : newShipOut.toString();
    let pushShipOut = this.state.settings.notifications.shipOut;
    _.isEmpty(valueShipOut) ? this.state.settings.notifications.shipOut : pushShipOut.push(stringShipOut);
    let dataShipOut = _.isEmpty(valueShipOut) ? this.state.settings.notifications.shipOut : _.union(_.split(_.compact(pushShipOut), ','));

    let newNotifyShipping = _.compact(_.map(valueNotifyShipping, 'id'));
    let stringNotifyShipping = _.isEmpty(valueNotifyShipping) ? this.state.settings.notifications.notifyShipping : newNotifyShipping.toString();
    let pushNotifyShipping = this.state.settings.notifications.notifyShipping;
    _.isEmpty(valueNotifyShipping) ? this.state.settings.notifications.notifyShipping : pushNotifyShipping.push(stringNotifyShipping);
    let dataNotifyShipping = _.isEmpty(valueNotifyShipping) ? this.state.settings.notifications.notifyShipping : _.union(_.split(_.compact(pushNotifyShipping), ','));

    let data = {
      "notifications": {
        "yieldRevised": dataYield,
        "customerPOAdded": dataCustomerPOAdded,
        "warehouseRevised": dataWarehouseRevised,
        "colorQuantityRevised": dataColorQuantityRevised,
        "purchaseOrderSubmitted": dataPurchaseOrderSubmitted,
        "PQExceed": dataPurchased,
        "POStock": dataPurchase,
        "additionalPurchase": dataAdditional,
        "approvePurchase": dataApprovepurchase,
        "stockIn": dataStockIn,
        "receivedQuantityDeviated": dataReceived,
        "stockOut": dataStockOut,
        "approveOutbound": dataApproveOutbound,
        "GSAprrovedOrRejected": dataGSample,
        "MSAprrovedOrRejected": dataMSample,
        "SampleApprovalResubmitted": dataSampleResubmitted,
        "productionFinished": dataProduction,
        "QAFailed": dataQAFailed,
        "shipIn": dataShipIn,
        "shipOut": dataShipOut,
        "notifyShipping": dataNotifyShipping,
      }
    };

    this.props.systemSettingPropsActions.updateEmailSetting(idsettings, data);
    this.setState({
      valueYield: [],
      valueCustomerPOAdded: [],
      valueWarehouseRevised: [],
      valueColorQuantityRevised: [],
      valuePurchaseOrderSubmitted: [],
      valuePurchased: [],
      valuePurchase: [],
      valueAdditional: [],
      valueApprovepurchase: [],
      valueStockIn: [],
      valueReceived: [],
      valueStockOut: [],
      valueApproveOutbound: [],
      valueGSample: [],
      valueMSample: [],
      valueSampleResubmitted: [],
      valueProduction: [],
      valueQAFailed: [],
      valueShipIn: [],
      valueShipOut: [],
      valueNotifyShipping: [],
    });
  }
  SavePurchaseDeviation = () => {
    let idsettings = this.state.settings._id;
    let purchaseDeviation = this.state.settings.purchaseDeviation;
    let outboundDeviation = this.state.settings.outboundDeviation;

    let data = {
      "purchaseDeviation": purchaseDeviation,
      "outboundDeviation": outboundDeviation
    }
    this.props.systemSettingPropsActions.updatePurchaseDeviation(idsettings, data);
  }

  SaveProduction = () => {
    let idsettings = this.state.settings._id;
    let newProduction = this.state.settings.production;
    let data = {
      "production": {
        "warning": newProduction.warning,
        "redAlert": newProduction.redAlert
      }
    };
    this.props.systemSettingPropsActions.updateProduction(idsettings, data);
  }

  SaveSampleApproval = () => {
    if (this.validateSampleApprove()) {
      let idsettings = this.state.settings._id;
      const { resubmittedAlert } = this.state.settings.sampleApproval;
      let data = {
        "sampleApproval": {
          "resubmittedAlert": resubmittedAlert
        }
      };
      this.props.systemSettingPropsActions.updateSetting(idsettings, data); 
    }
  }

  SaveAgeDefinition = () => {
    let idsettings = this.state.settings._id;
    let newageDefinition = this.state.settings.ageDefinition;
    let data = {
      "ageDefinition": {
        "fabric": newageDefinition.fabric,
        "inTransit": newageDefinition.inTransit,
        "top": newageDefinition.top,
        "counterSample": newageDefinition.counterSample,
        "pp": newageDefinition.pp,
        "exFactory": newageDefinition.exFactory,
        "trim": newageDefinition.trim,
        "actualYield": newageDefinition.actualYield,
        "ETD": newageDefinition.ETD,
        "yield": newageDefinition.yield,
        "customerPOAdded": newageDefinition.customerPOAdded,
        "warehouseRevised": newageDefinition.warehouseRevised,
        "colorQuantityRevised": newageDefinition.colorQuantityRevised,
        "purchaseOrderSubmitted": newageDefinition.purchaseOrderSubmitted,
        "inPort": newageDefinition.inPort,
        "ETA": newageDefinition.ETA,
        "fit": newageDefinition.fit,
        "inWarehouse": newageDefinition.inWarehouse,
        "delivery": newageDefinition.delivery,
        "ss": newageDefinition.ss,
        "production": newageDefinition.production,
      }
    };
    this.props.systemSettingPropsActions.updateAgeDefinition(idsettings, data);
  }

  render = () => {
    const { tabCurrent } = this.props;
    const { items, disabled, stayOpen, settings, notifications } = this.state;
    const options = items;
    const production = this.state.settings.production || {};
    const sampleApproval = this.state.settings.sampleApproval || {};
    const ageDefinition = this.state.settings.ageDefinition || {};
    const { valueYield, valuePurchased, valuePurchase, valueAdditional, valueApprovepurchase, valueStockIn, valueReceived, valueStockOut,
        valueApproveOutbound, valueGSample, valueMSample, valueSampleResubmitted, valueProduction, valueQAFailed, valueShipIn, valueShipOut,
        valueNotifyShipping, valueCustomerPOAdded, valueWarehouseRevised, valueColorQuantityRevised, valuePurchaseOrderSubmitted} = this.state;

    const arrayshipOut = notifications.shipOut;
    let differenceWithshipOut = [];
    differenceWithshipOut = _.differenceWith(items, arrayshipOut, _.isEqual);
    const optionsshipOut = differenceWithshipOut;

    const arrayshipIn = notifications.shipIn;
    let differenceWithshipIn = [];
    differenceWithshipIn = _.differenceWith(items, arrayshipIn, _.isEqual);
    const optionsshipIn = differenceWithshipIn;

    const arrayQAFailed = notifications.QAFailed;
    let differenceWithQAFailed = [];
    differenceWithQAFailed = _.differenceWith(items, arrayQAFailed, _.isEqual);
    const optionsQAFailed = differenceWithQAFailed;

    const arrayproductionFinished = notifications.productionFinished;
    let differenceWithproductionFinished = [];
    differenceWithproductionFinished = _.differenceWith(items, arrayproductionFinished, _.isEqual);
    const optionsproductionFinished = differenceWithproductionFinished;

    const arrayGSAprrovedOrRejected = notifications.GSAprrovedOrRejected;
    let differenceWithGSAprrovedOrRejected = [];
    differenceWithGSAprrovedOrRejected = _.differenceWith(items, arrayGSAprrovedOrRejected, _.isEqual);
    const optionsGSAprrovedOrRejected = differenceWithGSAprrovedOrRejected;

    const arrayMSAprrovedOrRejected = notifications.MSAprrovedOrRejected;
    let differenceWithMSAprrovedOrRejected = [];
    differenceWithMSAprrovedOrRejected = _.differenceWith(items, arrayMSAprrovedOrRejected, _.isEqual);
    const optionsMSAprrovedOrRejected = differenceWithMSAprrovedOrRejected;

    const arraySampleResubmitted = notifications.SampleResubmitted;
    let differenceWithSampleResubmitted = [];
    differenceWithSampleResubmitted = _.differenceWith(items, arraySampleResubmitted, _.isEqual);
    const optionsSampleResubmitted = differenceWithSampleResubmitted;

    const arraystockOut = notifications.stockOut;
    let differenceWithstockOut = [];
    differenceWithstockOut = _.differenceWith(items, arraystockOut, _.isEqual);
    const optionsstockOut = differenceWithstockOut;

    const arrayapproveOutbound = notifications.approveOutbound;
    let differenceWithapproveOutbound = [];
    differenceWithapproveOutbound = _.differenceWith(items, arrayapproveOutbound, _.isEqual);
    const optionsapproveOutbound = differenceWithapproveOutbound;


    const arrayreceivedQuantityDeviated = notifications.receivedQuantityDeviated;
    let differenceWithreceivedQuantityDeviated = [];
    differenceWithreceivedQuantityDeviated = _.differenceWith(items, arrayreceivedQuantityDeviated, _.isEqual);
    const optionsreceivedQuantityDeviated = differenceWithreceivedQuantityDeviated;

    const arraystockIn = notifications.stockIn;
    let differenceWithstockIn = [];
    differenceWithstockIn = _.differenceWith(items, arraystockIn, _.isEqual);
    const optionsstockIn = differenceWithstockIn;

    const arrayadditionalPurchase = notifications.additionalPurchase;
    let differenceWithadditionalPurchase = [];
    differenceWithadditionalPurchase = _.differenceWith(items, arrayadditionalPurchase, _.isEqual);
    const optionsadditionalPurchase = differenceWithadditionalPurchase;

    const arrayapprovePurchase = notifications.approvePurchase;
    let differenceWithapprovePurchase = [];
    differenceWithapprovePurchase = _.differenceWith(items, arrayapprovePurchase, _.isEqual);
    const optionsapprovePurchase = differenceWithapprovePurchase;

    const arrayPOStock = notifications.POStock;
    let differenceWithPOStock = [];
    differenceWithPOStock = _.differenceWith(items, arrayPOStock, _.isEqual);
    const optionsPOStock = differenceWithPOStock;

    const arrayPQExceed = notifications.PQExceed;
    let differenceWithPQExceed = [];
    differenceWithPQExceed = _.differenceWith(items, arrayPQExceed, _.isEqual);
    const optionsPQExceed = differenceWithPQExceed;

    const arrayyieldRevised = notifications.yieldRevised;
    let differenceWithyieldRevised = [];
    differenceWithyieldRevised = _.differenceWith(items, arrayyieldRevised, _.isEqual);
    const optionsyieldRevised = differenceWithyieldRevised;

    const arrayCustomerPOAdded = notifications.customerPOAdded;
    let differenceWithCustomerPOAdded = [];
    differenceWithCustomerPOAdded = _.differenceWith(items, arrayCustomerPOAdded, _.isEqual);
    const optionsCustomerPOAdded = differenceWithCustomerPOAdded;

    const arrayWarehouseRevised = notifications.warehouseRevised;
    let differenceWithWarehouseRevised = [];
    differenceWithWarehouseRevised = _.differenceWith(items, arrayWarehouseRevised, _.isEqual);
    const optionsWarehouseRevised = differenceWithWarehouseRevised;

    const arrayColorQuantityRevised = notifications.colorQuantityRevised;
    let differenceWithColorQuantityRevised = [];
    differenceWithColorQuantityRevised = _.differenceWith(items, arrayColorQuantityRevised, _.isEqual);
    const optionsColorQuantityRevised = differenceWithColorQuantityRevised;

    const arrayPurchaseOrderSubmitted = notifications.purchaseOrderSubmitted;
    let differenceWithPurchaseOrderSubmitted = [];
    differenceWithPurchaseOrderSubmitted = _.differenceWith(items, arrayPurchaseOrderSubmitted, _.isEqual);
    const optionsPurchaseOrderSubmitted = differenceWithPurchaseOrderSubmitted;

    const arraynotifyShipping = notifications.notifyShipping;
    let differenceWithnotifyShipping = [];
    differenceWithnotifyShipping = _.differenceWith(items, arraynotifyShipping, _.isEqual);
    const optionsnotifyShipping = differenceWithnotifyShipping;

    const style = {
      overlay: {
          position: 'fixed',
          backgroundColor: 'rgba(0, 0, 0, 0.75)',
          zIndex: '3'
      },
      content: {
          position: 'auto',
          top: '50%',
          left: '50%',
          backgroundColor: 'rgba(0,0,0,0)',
          border: '0px',
          borderRadius: '0px',
          outline: 'none',
      },
  };
  
    return (
      <div className="wrap-page-settings">
        <div className="pd-0-5 wrap-page">
          <div className="global-table-tab">
            <ul className="nav nav-tabs wrap-tab" role="tablist">
              <li role="presentation" className="active"><a href="#system-notification" aria-controls="notification" role="tab" data-toggle="tab">{I18n.t('PageSystemSettings.Notification')}</a><span className="line" /></li>
              <li role="presentation"><a href="#system-definition" aria-controls="definition" role="tab" data-toggle="tab">{I18n.t('PageSystemSettings.AgeDefinition')}</a></li>
            </ul>

            <div className="tab-content">
              <div role="tabpanel" className="tab-pane active bg-gray" id="system-notification">
                <div className="wrap-title bd-dashed-bottom bg-white">
                  <h3>{I18n.t('PageSystemSettings.EMAIL')}</h3>
                  <div className="float-right bt-save">
                    <span className="cursor-pointer" onClick={() => this.save()}><i className="fa fa-floppy-o" aria-hidden="true" /> {I18n.t('Btn.SAVE')}</span>
                  </div>
                </div>
                <div className="wrap-table inline-block w-full box-shadow pd-10-0 bg-white">


                    <div className="list-item">
                      <div className="col-md-6 col-xs-12">
                          <div className="form-group">
                              <div className="text">
                                  {I18n.t('PageSystemSettings.CustomerPOAdded')}
                              </div>
                              <div className="value select">
                                  <Select
                                      closeOnSelect={!stayOpen}
                                      disabled={disabled}
                                      multi
                                      onChange={this.handleSelectCustomerPOAdded}
                                      labelKey="email"
                                      options={optionsCustomerPOAdded}
                                      placeholder="Choose Email"
                                      removeSelected={this.state.removeSelected}
                                      joinValues
                                      valueKey="id"
                                      value={valueCustomerPOAdded}
                                  />
                              </div>
                          </div>
                      </div>
                      <div className="col-md-6 col-xs-12">
                          <ul className="list">
                              {(notifications.customerPOAdded || []).map((i, idx) => (
                                  <li key={idx}>
                                      <span>{i.email} <img onClick={(e) => this.remove('customerPOAdded', i.id)} src={icXGrayIcon} /></span>
                                  </li>
                              ))}
                          </ul>
                      </div>
                    </div>

                    <div className="list-item">
                      <div className="col-md-6 col-xs-12">
                          <div className="form-group">
                              <div className="text">
                                  {I18n.t('PageSystemSettings.ColorQuantityRevised')}
                              </div>
                              <div className="value select">
                                  <Select
                                      closeOnSelect={!stayOpen}
                                      disabled={disabled}
                                      multi
                                      onChange={this.handleSelectColorQuantityRevised}
                                      labelKey="email"
                                      options={optionsColorQuantityRevised}
                                      placeholder="Choose Email"
                                      removeSelected={this.state.removeSelected}
                                      joinValues
                                      valueKey="id"
                                      value={valueColorQuantityRevised}
                                  />
                              </div>
                          </div>
                      </div>
                      <div className="col-md-6 col-xs-12">
                          <ul className="list">
                              {(notifications.colorQuantityRevised || []).map((i, idx) => (
                                  <li key={idx}>
                                      <span>{i.email} <img onClick={(e) => this.remove('colorQuantityRevised', i.id)} src={icXGrayIcon} /></span>
                                  </li>
                              ))}
                          </ul>
                      </div>
                    </div>
                    <div className="list-item">
                      <div className="col-md-6 col-xs-12">
                        <div className="form-group">
                          <div className="text">
                            {I18n.t('PageSystemSettings.YieldInputRevised')}
                          </div>
                          <div className="value select">
                            <Select
                              closeOnSelect={!stayOpen}
                              disabled={disabled}
                              multi
                              onChange={this.handleSelectYield}
                              labelKey="email"
                              options={optionsyieldRevised}
                              placeholder="Choose Email"
                              removeSelected={this.state.removeSelected}
                              joinValues
                              valueKey="id"
                              value={valueYield}
                            />
                          </div>
                        </div>
                      </div>
                        <div className="col-md-6 col-xs-12">
                            <ul className="list">
                                {(notifications.yieldRevised || []).map((i, idx) => (
                                    <li key={idx}>
                                        <span>{i.email} <img onClick={(e) => this.remove('yieldRevised', i.id)} src={icXGrayIcon} /></span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    {/*////*/}
                    <div className="list-item">
                      <div className="col-md-6 col-xs-12">
                        <div className="form-group">
                          <div className="text">
                            {I18n.t('PageSystemSettings.PurchasedQuantityExceed')}
                          </div>
                          <div className="value select">
                            <Select
                              closeOnSelect={!stayOpen}
                              disabled={disabled}
                              multi
                              onChange={this.handleSelectPurchased}
                              labelKey="email"
                              options={optionsPQExceed}
                              placeholder="Choose Email"
                              removeSelected={this.state.removeSelected}
                              joinValues
                              valueKey="id"
                              value={valuePurchased}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-xs-12">
                        <ul className="list">
                          {(notifications.PQExceed || []).map((i, idx) => (
                            <li key={idx}>
                              <span>{i.email} <img onClick={(e) => this.remove('PQExceed', i.id)} src={icXGrayIcon} /></span>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                    <div className="list-item">
                      <div className="col-md-6 col-xs-12">
                          <div className="form-group">
                              <div className="text">
                                  {I18n.t('PageSystemSettings.PurchaseOrderSubmitted')}
                              </div>
                              <div className="value select">
                                  <Select
                                      closeOnSelect={!stayOpen}
                                      disabled={disabled}
                                      multi
                                      onChange={this.handleSelectPurchaseOrderSubmitted}
                                      labelKey="email"
                                      options={optionsPurchaseOrderSubmitted}
                                      placeholder="Choose Email"
                                      removeSelected={this.state.removeSelected}
                                      joinValues
                                      valueKey="id"
                                      value={valuePurchaseOrderSubmitted}
                                  />
                              </div>
                          </div>
                      </div>
                      <div className="col-md-6 col-xs-12">
                          <ul className="list">
                              {(notifications.purchaseOrderSubmitted || []).map((i, idx) => (
                                  <li key={idx}>
                                      <span>{i.email} <img onClick={(e) => this.remove('purchaseOrderSubmitted', i.id)} src={icXGrayIcon} /></span>
                                  </li>
                              ))}
                          </ul>
                      </div>
                  </div>

                  <div className="list-item">
                    <div className="col-md-6 col-xs-12">
                      <div className="form-group">
                        <div className="text">
                          {I18n.t('PageSystemSettings.AdditionalPurchase')}
                        </div>
                        <div className="value select">
                          <Select
                            closeOnSelect={!stayOpen}
                            disabled={disabled}
                            multi
                            onChange={this.handleSelectAdditional}
                            labelKey="email"
                            options={optionsadditionalPurchase}
                            placeholder="Choose Email"
                            removeSelected={this.state.removeSelected}
                            joinValues
                            valueKey="id"
                            value={valueAdditional}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-xs-12">
                      <ul className="list">
                        {(notifications.additionalPurchase || []).map((i, idx) => (
                          <li key={idx}>
                            <span>{i.email} <img onClick={(e) => this.remove('additionalPurchase', i.id)} src={icXGrayIcon} /></span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>

                  <div className="list-item">
                    <div className="col-md-6 col-xs-12">
                      <div className="form-group">
                        <div className="text">
                          {I18n.t('PageSystemSettings.POStock')}
                        </div>
                        <div className="value select">
                          <Select
                            closeOnSelect={!stayOpen}
                            disabled={disabled}
                            multi
                            onChange={this.handleSelectPurchase}
                            labelKey="email"
                            options={optionsPOStock}
                            placeholder="Choose Email"
                            removeSelected={this.state.removeSelected}
                            joinValues
                            valueKey="id"
                            value={valuePurchase}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-xs-12">
                      <ul className="list">
                        {(notifications.POStock || []).map((i, idx) => (
                          <li key={idx}>
                            <span>{i.email} <img onClick={(e) => this.remove('POStock', i.id)} src={icXGrayIcon} /></span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>

                  <div className="list-item">
                    <div className="col-md-6 col-xs-12">
                      <div className="form-group">
                        <div className="text">
                          {I18n.t('PageSystemSettings.ApprovePurchase')}
                        </div>
                        <div className="value select">
                          <Select
                            closeOnSelect={!stayOpen}
                            disabled={disabled}
                            multi
                            onChange={this.handleSelectApprovepurchase}
                            labelKey="email"
                            options={optionsapprovePurchase}
                            placeholder="Choose Email"
                            removeSelected={this.state.removeSelected}
                            joinValues
                            valueKey="id"
                            value={valueApprovepurchase}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-xs-12">
                      <ul className="list">
                        {(notifications.approvePurchase || []).map((i, idx) => (
                          <li key={idx}>
                            <span>{i.email} <img onClick={(e) => this.remove('approvePurchase', i.id)} src={icXGrayIcon} /></span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>

                  <div className="list-item">
                    <div className="col-md-6 col-xs-12">
                      <div className="form-group">
                        <div className="text">
                          {I18n.t('PageSystemSettings.GarmentSampleApprovalApprovedRejected')}
                        </div>
                        <div className="value select">
                          <Select
                            closeOnSelect={!stayOpen}
                            disabled={disabled}
                            multi
                            onChange={this.handleSelectGSample}
                            labelKey="email"
                            options={optionsGSAprrovedOrRejected}
                            placeholder="Choose Email"
                            removeSelected={this.state.removeSelected}
                            joinValues
                            valueKey="id"
                            value={valueGSample}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-xs-12">
                      <ul className="list">
                        {(notifications.GSAprrovedOrRejected || []).map((i, idx) => (
                          <li key={idx}>
                            <span>{i.email} <img onClick={(e) => this.remove('GSAprrovedOrRejected', i.id)} src={icXGrayIcon} /></span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>

                  <div className="list-item">
                    <div className="col-md-6 col-xs-12">
                      <div className="form-group">
                        <div className="text">
                          {I18n.t('PageSystemSettings.MaterialSampleApprovalApprovedRejected')}
                        </div>
                        <div className="value select">
                          <Select
                            closeOnSelect={!stayOpen}
                            disabled={disabled}
                            multi
                            onChange={this.handleSelectMSample}
                            labelKey="email"
                            options={optionsMSAprrovedOrRejected}
                            placeholder="Choose Email"
                            removeSelected={this.state.removeSelected}
                            joinValues
                            valueKey="id"
                            value={valueMSample}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-xs-12">
                      <ul className="list">
                        {(notifications.MSAprrovedOrRejected || []).map((i, idx) => (
                          <li key={idx}>
                            <span>{i.email} <img onClick={(e) => this.remove('MSAprrovedOrRejected', i.id)} src={icXGrayIcon} /></span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>

                  <div className="list-item">
                      <div className="col-md-6 col-xs-12">
                          <div className="form-group">
                              <div className="text">
                                  {I18n.t('PageSystemSettings.SampleApprovalResubmitted')}
                              </div>
                              <div className="value select">
                                  <Select
                                      closeOnSelect={!stayOpen}
                                      disabled={disabled}
                                      multi
                                      onChange={this.handleSampleResubmitted}
                                      labelKey="email"
                                      options={optionsSampleResubmitted}
                                      placeholder="Choose Email"
                                      removeSelected={this.state.removeSelected}
                                      joinValues
                                      valueKey="id"
                                      value={valueSampleResubmitted}
                                  />
                              </div>
                          </div>
                      </div>
                      <div className="col-md-6 col-xs-12">
                          <ul className="list">
                              {(notifications.SampleApprovalResubmitted || []).map((i, idx) => (
                                  <li key={idx}>
                                      <span>{i.email} <img onClick={(e) => this.remove('SampleApprovalResubmitted', i.id)} src={icXGrayIcon} /></span>
                                  </li>
                              ))}
                          </ul>
                      </div>
                  </div>


                  <div className="list-item">
                    <div className="col-md-6 col-xs-12">
                      <div className="form-group">
                        <div className="text">
                          {I18n.t('PageSystemSettings.StockIn')}
                        </div>
                        <div className="value select">
                          <Select
                            closeOnSelect={!stayOpen}
                            disabled={disabled}
                            multi
                            onChange={this.handleSelectStockIn}
                            labelKey="email"
                            options={optionsstockIn}
                            placeholder="Choose Email"
                            removeSelected={this.state.removeSelected}
                            joinValues
                            valueKey="id"
                            value={valueStockIn}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-xs-12">
                      <ul className="list">
                        {(notifications.stockIn || []).map((i, idx) => (
                          <li key={idx}>
                            <span>{i.email} <img onClick={(e) => this.remove('stockIn', i.id)} src={icXGrayIcon} /></span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>

                  <div className="list-item">
                    <div className="col-md-6 col-xs-12">
                        <div className="form-group">
                            <div className="text">
                                {I18n.t('PageSystemSettings.WarehouseRevised')}
                            </div>
                            <div className="value select">
                                <Select
                                    closeOnSelect={!stayOpen}
                                    disabled={disabled}
                                    multi
                                    onChange={this.handleSelectWarehouseRevised}
                                    labelKey="email"
                                    options={optionsWarehouseRevised}
                                    placeholder="Choose Email"
                                    removeSelected={this.state.removeSelected}
                                    joinValues
                                    valueKey="id"
                                    value={valueWarehouseRevised}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-xs-12">
                        <ul className="list">
                            {(notifications.warehouseRevised || []).map((i, idx) => (
                                <li key={idx}>
                                    <span>{i.email} <img onClick={(e) => this.remove('warehouseRevised', i.id)} src={icXGrayIcon} /></span>
                                </li>
                            ))}
                        </ul>
                    </div>
                  </div>

                  <div className="list-item">
                    <div className="col-md-6 col-xs-12">
                      <div className="form-group">
                        <div className="text">
                          {I18n.t('PageSystemSettings.ReceivedQuantityDeviated')}
                        </div>
                        <div className="value select">
                          <Select
                            closeOnSelect={!stayOpen}
                            disabled={disabled}
                            multi
                            onChange={this.handleSelectReceived}
                            labelKey="email"
                            options={optionsreceivedQuantityDeviated}
                            placeholder="Choose Email"
                            removeSelected={this.state.removeSelected}
                            joinValues
                            valueKey="id"
                            value={valueReceived}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-xs-12">
                      <ul className="list">
                        {(notifications.receivedQuantityDeviated || []).map((i, idx) => (
                          <li key={idx}>
                            <span>{i.email} <img onClick={(e) => this.remove('receivedQuantityDeviated', i.id)} src={icXGrayIcon} /></span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>


                  <div className="list-item">
                    <div className="col-md-6 col-xs-12">
                      <div className="form-group">
                        <div className="text">
                          {I18n.t('PageSystemSettings.StockOut')}
                        </div>
                        <div className="value select">
                          <Select
                            closeOnSelect={!stayOpen}
                            disabled={disabled}
                            multi
                            onChange={this.handleSelectStockOut}
                            labelKey="email"
                            options={optionsstockOut}
                            placeholder="Choose Email"
                            removeSelected={this.state.removeSelected}
                            joinValues
                            valueKey="id"
                            value={valueStockOut}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-xs-12">
                      <ul className="list">
                        {(notifications.stockOut || []).map((i, idx) => (
                          <li key={idx}>
                            <span>{i.email} <img onClick={(e) => this.remove('stockOut', i.id)} src={icXGrayIcon} /></span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>


                  <div className="list-item">
                    <div className="col-md-6 col-xs-12">
                      <div className="form-group">
                        <div className="text">
                          {I18n.t('PageSystemSettings.ApproveOutbound')}
                        </div>
                        <div className="value select">
                          <Select
                            closeOnSelect={!stayOpen}
                            disabled={disabled}
                            multi
                            onChange={this.handleSelectApproveOutbound}
                            labelKey="email"
                            options={optionsapproveOutbound}
                            placeholder="Choose Email"
                            removeSelected={this.state.removeSelected}
                            joinValues
                            valueKey="id"
                            value={valueApproveOutbound}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-xs-12">
                      <ul className="list">
                        {(notifications.approveOutbound || []).map((i, idx) => (
                          <li key={idx}>
                            <span>{i.email} <img onClick={(e) => this.remove('approveOutbound', i.id)} src={icXGrayIcon} /></span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>


                  <div className="list-item">
                    <div className="col-md-6 col-xs-12">
                      <div className="form-group">
                        <div className="text">
                          {I18n.t('PageSystemSettings.ProductionFinished')}
                        </div>
                        <div className="value select">
                          <Select
                            closeOnSelect={!stayOpen}
                            disabled={disabled}
                            multi
                            onChange={this.handleSelectProduction}
                            labelKey="email"
                            options={optionsproductionFinished}
                            placeholder="Choose Email"
                            removeSelected={this.state.removeSelected}
                            joinValues
                            valueKey="id"
                            value={valueProduction}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-xs-12">
                      <ul className="list">
                        {(notifications.productionFinished || []).map((i, idx) => (
                          <li key={idx}>
                            <span>{i.email} <img onClick={(e) => this.remove('productionFinished', i.id)} src={icXGrayIcon} /></span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>

                  <div className="list-item">
                    <div className="col-md-6 col-xs-12">
                      <div className="form-group">
                        <div className="text">
                          {I18n.t('PageSystemSettings.QAFailed')}
                        </div>
                        <div className="value select">
                          <Select
                            closeOnSelect={!stayOpen}
                            disabled={disabled}
                            multi
                            onChange={this.handleSelectQAFailed}
                            labelKey="email"
                            options={optionsQAFailed}
                            placeholder="Choose Email"
                            removeSelected={this.state.removeSelected}
                            joinValues
                            valueKey="id"
                            value={valueQAFailed}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-xs-12">
                      <ul className="list">
                        {(notifications.QAFailed || []).map((i, idx) => (
                          <li key={idx}>
                            <span>{i.email} <img onClick={(e) => this.remove('QAFailed', i.id)} src={icXGrayIcon} /></span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>


                  <div className="list-item">
                    <div className="col-md-6 col-xs-12">
                      <div className="form-group">
                        <div className="text">
                          {I18n.t('PageSystemSettings.ShipIn')}
                        </div>
                        <div className="value select">
                          <Select
                            closeOnSelect={!stayOpen}
                            disabled={disabled}
                            multi
                            onChange={this.handleSelectShipIn}
                            labelKey="email"
                            options={optionsshipIn}
                            placeholder="Choose Email"
                            removeSelected={this.state.removeSelected}
                            joinValues
                            valueKey="id"
                            value={valueShipIn}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-xs-12">
                      <ul className="list">
                        {(notifications.shipIn || []).map((i, idx) => (
                          <li key={idx}>
                            <span>{i.email} <img onClick={(e) => this.remove('shipIn', i.id)} src={icXGrayIcon} /></span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>

                  <div className="list-item">
                    <div className="col-md-6 col-xs-12">
                      <div className="form-group">
                        <div className="text">
                          {I18n.t('PageSystemSettings.NotifyShipping')}
                        </div>
                        <div className="value select">
                          <Select
                            closeOnSelect={!stayOpen}
                            disabled={disabled}
                            multi
                            onChange={this.handleSelectNotifyShipping}
                            labelKey="email"
                            options={optionsnotifyShipping}
                            placeholder="Choose Email"
                            removeSelected={this.state.removeSelected}
                            joinValues
                            valueKey="id"
                            value={valueNotifyShipping}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-xs-12">
                      <ul className="list">
                        {(notifications.notifyShipping || []).map((i, idx) => (
                          <li key={idx}>
                            <span>{i.email} <img onClick={(e) => this.remove('notifyShipping', i.id)} src={icXGrayIcon} /></span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>

                  <div className="list-item">
                    <div className="col-md-6 col-xs-12">
                      <div className="form-group">
                        <div className="text">
                          {I18n.t('PageSystemSettings.ShipOut')}
                        </div>
                        <div className="value select">
                          <Select
                            closeOnSelect={!stayOpen}
                            disabled={disabled}
                            multi
                            onChange={this.handleSelectShipOut}
                            labelKey="email"
                            options={optionsshipOut}
                            placeholder="Choose Email"
                            removeSelected={this.state.removeSelected}
                            joinValues
                            valueKey="id"
                            value={valueShipOut}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-xs-12">
                      <ul className="list">
                        {(notifications.shipOut || []).map((i, idx) => (
                          <li key={idx}>
                            <span>{i.email} <img onClick={(e) => this.remove('shipOut', i.id)} src={icXGrayIcon} /></span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="wrap-table inline-block w-full box-shadow mg-top-15 bg-white sys-sample-approval" id="system-sample-approval">
                  <div className="wrap-title bd-dashed-bottom bg-white">
                    <h3>{I18n.t('PageSystemSettings.SAMPLEAPPROVAL')}</h3>
                    <div className="float-right bt-save" onClick={() => { this.SaveSampleApproval(); }}>
                      <span className="cursor-pointer"><i className="fa fa-floppy-o" aria-hidden="true" /> {I18n.t('PageSystemSettings.SAVE')}</span>
                    </div>
                  </div>
                  <div className="pd-top-15 inline-block w-full wrap">
                    <div className="col-md-6 col-xs-12 box pd-0">
                      <ul>
                        <li className="text-right">
                          {I18n.t('PageSystemSettings.ResubmittedAlert')}
                        </li>
                        <li className="number">
                          <div className="value input">
                            <input type="number" min="0" className="form-control bg-white center size-14" onChange={(evt) => { this.setSampleApproval('resubmittedAlert', evt.target.value); }} value={sampleApproval.resubmittedAlert} />
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="wrap-table inline-block w-full box-shadow pd-10-0 mg-top-15 bg-white" id="purchase-deviation">
                  <div className="list-item">
                    <div className="col-xs-6">
                      <div className="form-group">
                        <div className="text bold">{I18n.t('PageSystemSettings.PURCHASEDEVIATION')}</div>
                        <div className="value input w-auto inline-block">
                          <input type="number" className="form-control center size-14" onChange={(evt) => { this.setPurchaseDeviation('purchaseDeviation', evt.target.value); }} value={settings.purchaseDeviation} placeholder={settings.purchaseDeviation} />
                          <span>%</span>
                        </div>
                      </div>
                    </div>

                    <div className="col-xs-5">
                      <div className="form-group">
                        <div className="text bold">{I18n.t('PageSystemSettings.OUTBOUNDDEVIATION')}</div>
                        <div className="value input w-auto inline-block">
                          <input type="number" className="form-control center size-14" onChange={(evt) => { this.setOutboundDeviation('outboundDeviation', evt.target.value); }} value={settings.outboundDeviation} placeholder={settings.outboundDeviation} />
                          <span>%</span>
                        </div>
                      </div>
                    </div>

                    <div className="col-xs-1">
                      <div className="float-right bt-save pd-0" onClick={() => { this.SavePurchaseDeviation(); }} >
                        <span className="cursor-pointer"><i className="fa fa-floppy-o" /> {I18n.t('Btn.SAVE')}</span>
                      </div>
                    </div>

                  </div>
                </div>

                <div className="wrap-table inline-block w-full box-shadow mg-top-15 bg-white" id="system-production">
                  <div className="wrap-title bd-dashed-bottom bg-white">
                    <h3>{I18n.t('PageSystemSettings.PRODUCTION')}</h3>
                    <div className="float-right bt-save" onClick={() => { this.SaveProduction(); }}>
                      <span className="cursor-pointer"><i className="fa fa-floppy-o" aria-hidden="true" /> {I18n.t('PageSystemSettings.SAVE')}</span>
                    </div>
                  </div>
                  <div className="pd-top-15 inline-block w-full wrap">


                    <div className="col-md-6 col-xs-12 box pd-0">
                      <ul>
                        <li className="text-right">
                          {I18n.t('PageSystemSettings.RedAlert')}
                        </li>
                        <li className="number">
                          <div className="value input">
                            <input type="number" className="form-control bg-white center size-14" onChange={(evt) => { this.setProduction('redAlert', evt.target.value); }} value={production.redAlert} placeholder={production.redAlert} />
                            <span>%</span>
                          </div>
                          <div className="progress">
                            <div className="progress-bar progress-bar-danger" role="progressbar" aria-valuenow={production.redAlert} aria-valuemin="0" aria-valuemax="100" style={{ width: '100%' }}>
                              <span className="sr-only">{production.redAlert}% {I18n.t('PageSystemSettings.Complete')}</span>
                            </div>
                          </div>
                        </li>

                      </ul>
                    </div>

                    <div className="col-md-6 col-xs-12 box pd-0">
                      <ul>
                        <li className="text-right">
                          {I18n.t('PageSystemSettings.Warning')}
                        </li>
                        <li className="number">
                          <div className="value input">
                            <input type="number" className="form-control bg-white center size-14" onChange={(evt) => { this.setProduction('warning', evt.target.value); }} value={production.warning ? production.warning : ''} placeholder={production.warning ? production.warning : ''} />
                            <span>%</span>
                          </div>
                          <div className="progress">
                            <div className="progress-bar progress-bar-warning" role="progressbar" aria-valuenow={production.warning} aria-valuemin="0" aria-valuemax="100" style={{ width: '100%' }}>
                              <span className="sr-only">{production.warning}% {I18n.t('PageSystemSettings.Complete')}</span>
                            </div>
                          </div>
                        </li>



                      </ul>
                    </div>

                  </div>
                </div>
              </div>
              <div role="tabpanel" className="tab-pane" id="system-definition">
                <div className="wrap-title bd-dashed-bottom bg-white">
                  <h3>AGE</h3>
                  <div className="float-right bt-save" onClick={() => { this.SaveAgeDefinition(); }}>
                    <span className="cursor-pointer"><i className="fa fa-floppy-o" aria-hidden="true" /> {I18n.t('Btn.SAVE')}</span>
                  </div>
                </div>

                <div className="wrap-table inline-block w-full box-shadow pd-10-0 bg-white">
                  <div className="wrap-input">
                    <div className="col-md-4 col-sm-6 col-xs-12 input">
                      <ul>
                        <li>
                          <span> {I18n.t('PageSystemSettings.Fabric')}</span>
                        </li>
                        <li>
                          <input type="number" min="0" className="form-control center size-14" onChange={(evt) => { this.setAgeDefinition('fabric', evt.target.value); }} value={ageDefinition.fabric} placeholder={ageDefinition.fabric} />
                        </li>
                      </ul>
                    </div>

                    <div className="col-md-4 col-sm-6 col-xs-12 input">
                      <ul>
                        <li>
                          <span>{I18n.t('PageSystemSettings.InTransit')}</span>
                        </li>
                        <li>
                          <input type="number" min="0" className="form-control center size-14" onChange={(evt) => { this.setAgeDefinition('inTransit', evt.target.value); }} value={ageDefinition.inTransit} placeholder={ageDefinition.inTransit} />
                        </li>
                      </ul>
                    </div>

                    <div className="col-md-4 col-sm-6 col-xs-12 input">
                      <ul>
                        <li>
                          <span>{I18n.t('PageSystemSettings.Top')}</span>
                        </li>
                        <li>
                          <input type="number" min="0" className="form-control center size-14" onChange={(evt) => { this.setAgeDefinition('top', evt.target.value); }} value={ageDefinition.top} placeholder={ageDefinition.top} />
                        </li>
                      </ul>
                    </div>

                    <div className="col-md-4 col-sm-6 col-xs-12 input">
                      <ul>
                        <li>
                          <span>{I18n.t('PageSystemSettings.CounterSample')}</span>
                        </li>
                        <li>
                          <input type="number" min="0" className="form-control center size-14" onChange={(evt) => { this.setAgeDefinition('counterSample', evt.target.value); }} 
                          value={ageDefinition.counterSample >= 0 ? ageDefinition.counterSample : 0} 
                          placeholder={ageDefinition.counterSample} 
                          />

                        </li>
                      </ul>
                    </div>

                    <div className="col-md-4 col-sm-6 col-xs-12 input">
                      <ul>
                        <li>
                          <span>{I18n.t('PageSystemSettings.PP')}</span>
                        </li>
                        <li>
                          <input type="number" min="0" className="form-control center size-14" onChange={(evt) => { this.setAgeDefinition('pp', evt.target.value); }} value={ageDefinition.pp} placeholder={ageDefinition.pp} />
                        </li>
                      </ul>
                    </div>

                    <div className="col-md-4 col-sm-6 col-xs-12 input">
                      <ul>
                        <li>
                          <span>{I18n.t('PageSystemSettings.Ex-Factory')}</span>
                        </li>
                        <li>
                          <input type="number" min="0" className="form-control center size-14" onChange={(evt) => { this.setAgeDefinition('exFactory', evt.target.value); }} value={ageDefinition.exFactory} placeholder={ageDefinition.exFactory} />
                        </li>
                      </ul>
                    </div>

                    <div className="col-md-4 col-sm-6 col-xs-12 input">
                      <ul>
                        <li>
                          <span>{I18n.t('PageSystemSettings.Trim')}</span>
                        </li>
                        <li>
                          <input type="number" min="0" className="form-control center size-14" onChange={(evt) => { this.setAgeDefinition('trim', evt.target.value); }} value={ageDefinition.trim} placeholder={ageDefinition.trim} />
                        </li>
                      </ul>
                    </div>






                    <div className="col-md-4 col-sm-6 col-xs-12 input">
                      <ul>
                        <li>
                          <span>{I18n.t('PageSystemSettings.ActualYield')}</span>
                        </li>
                        <li>
                          <input type="number" min="0" className="form-control center size-14" onChange={(evt) => { this.setAgeDefinition('actualYield', evt.target.value); }} value={ageDefinition.actualYield} placeholder={ageDefinition.actualYield} />
                        </li>
                      </ul>
                    </div>

                    <div className="col-md-4 col-sm-6 col-xs-12 input">
                      <ul>
                        <li>
                          <span>{I18n.t('PageSystemSettings.ETD')}</span>
                        </li>
                        <li>
                          <input type="number" min="0" className="form-control center size-14" onChange={(evt) => { this.setAgeDefinition('ETD', evt.target.value); }} value={ageDefinition.ETD} placeholder={ageDefinition.ETD} />
                        </li>
                      </ul>
                    </div>

                    <div className="col-md-4 col-sm-6 col-xs-12 input">
                      <ul>
                        <li>
                          <span>{I18n.t('PageSystemSettings.Yield')}</span>
                        </li>
                        <li>
                          <input type="number" min="0" className="form-control center size-14" onChange={(evt) => { this.setAgeDefinition('yield', evt.target.value); }} value={ageDefinition.yield} placeholder={ageDefinition.yield} />
                        </li>
                      </ul>
                    </div>

                    <div className="col-md-4 col-sm-6 col-xs-12 input">
                      <ul>
                        <li>
                          <span>{I18n.t('PageSystemSettings.InPort')}</span>
                        </li>
                        <li>
                          <input type="number" min="0" className="form-control center size-14" onChange={(evt) => { this.setAgeDefinition('inPort', evt.target.value); }} value={ageDefinition.inPort} placeholder={ageDefinition.inPort} />
                        </li>
                      </ul>
                    </div>

                    <div className="col-md-4 col-sm-6 col-xs-12 input">
                      <ul>
                        <li>
                          <span>{I18n.t('PageSystemSettings.ETA')}</span>
                        </li>
                        <li>
                          <input type="number" min="0" className="form-control center size-14" onChange={(evt) => { this.setAgeDefinition('ETA', evt.target.value); }} value={ageDefinition.ETA} placeholder={ageDefinition.ETA} />
                        </li>
                      </ul>
                    </div>


                    <div className="col-md-4 col-sm-6 col-xs-12 input">
                      <ul>
                        <li>
                          <span>{I18n.t('PageSystemSettings.FITColorlapdip')}</span>
                        </li>
                        <li>
                          <input type="number" min="0" className="form-control center size-14" onChange={(evt) => { this.setAgeDefinition('fit', evt.target.value); }} value={ageDefinition.fit} placeholder={ageDefinition.fit} />
                        </li>
                      </ul>
                    </div>

                    <div className="col-md-4 col-sm-6 col-xs-12 input">
                      <ul>
                        <li>
                          <span>{I18n.t('PageSystemSettings.InWarehouse')}</span>
                        </li>
                        <li>
                          <input type="number" min="0" className="form-control center size-14" onChange={(evt) => { this.setAgeDefinition('inWarehouse', evt.target.value); }} value={ageDefinition.inWarehouse} placeholder={ageDefinition.inWarehouse} />
                        </li>
                      </ul>
                    </div>

                    <div className="col-md-4 col-sm-6 col-xs-12 input">
                      <ul>
                        <li>
                          <span>{I18n.t('PageSystemSettings.DeliveryIH')}</span>
                        </li>
                        <li>
                          <input type="number" min="0" className="form-control center size-14" onChange={(evt) => { this.setAgeDefinition('delivery', evt.target.value); }} value={ageDefinition.delivery} placeholder={ageDefinition.delivery} />
                        </li>
                      </ul>
                    </div>

                    <div className="col-md-4 col-sm-6 col-xs-12 input">
                      <ul>
                        <li>
                          <span>{I18n.t('PageSystemSettings.S-S')}</span>
                        </li>
                        <li>
                          <input type="number" min="0" className="form-control center size-14" onChange={(evt) => { this.setAgeDefinition('ss', evt.target.value); }} value={ageDefinition.ss} placeholder={ageDefinition.ss} />
                        </li>
                      </ul>
                    </div>

                    <div className="col-md-4 col-sm-6 col-xs-12 input">
                      <ul>
                        <li>
                          <span>{I18n.t('PageSystemSettings.Production')}</span>
                        </li>
                        <li>
                          <input type="number" min="0" className="form-control center size-14" onChange={(evt) => { this.setAgeDefinition('production', evt.target.value); }} value={ageDefinition.production} placeholder={ageDefinition.production} />
                        </li>
                      </ul>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal style={style} isOpen={this.state.openRemoveEmailModal}>
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <form>
                <div className="modal-header">
                  <a onClick={() => this.closeCFRemoveEmail()} className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></a>
                  <h4 className="modal-title" id="myModalLabel">Confirm</h4>
                </div>
                <div className="modal-body size-13 pd-15">
                  Do you want to delete this email?
                </div>
                <div className="modal-footer">
                  <button type="button" className="bt-orange" data-dismiss="modal" onClick={() => this.CFRemoveEmail()}>Confirm
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal>

      </div>
    );
  }
}


function mapStateToProps(state, ownProps) {
  return {
    authState: state.authState,
    userState: state.userState,
    systemSettingState: state.systemSettingState
  };
}

function mapDispatchToProps(dispatch) {
  return {
    appPropsActions: bindActionCreators(appActions, dispatch),
    userPropsActions: bindActionCreators(userActions, dispatch),
    systemSettingPropsActions: bindActionCreators(systemSettingActions, dispatch)
  };
}

PageSystemSettings.propTypes = {
  authState: PropTypes.object.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(PageSystemSettings);

