import React from 'react';
import PropTypes from 'prop-types';
import BaseComponent from '../../base/BaseComponent';
import I18n from 'i18n-js';
import CutTicket from "./CutTicket";
import _ from 'lodash';

export default class StyleCutTicket extends BaseComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  render() {
    const {style} = this.props;
    const cutTickets = _.get(style, "cutTickets", []);
    return (
      <div className="">
        <ul className="content-header-title">
          <li>
            <p>{I18n.t('PageOrder.Style')}
              <span className="color-orange medium mg-left-50 size-16">{_.get(style, "style", "")}</span>
            </p>
          </li>
          <li className="mg-left-50">
            <p>{I18n.t('PageOrder.Page')}
              <span className="medium mg-left-50 size-16">{_.get(style, "page", "")}</span>
            </p>
          </li>
        </ul>
        <div className="md content-main-info pd-0">
          {
            cutTickets.map(cutTicket => {
              return (
                <CutTicket key={cutTicket._id} cutTicket={cutTicket}/>
              );
            })
          }
        </div>
      </div>
    );
  }
}

StyleCutTicket.propTypes = {
  style: PropTypes.object
};


