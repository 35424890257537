const C = 'CUSTOMER_PO.CURRENT.EDIT_STYLE';

export default {
  SHOW_MODAL: `${C}.SHOW_MODAL`,
  HIDE_MODAL: `${C}.HIDE_MODAL`,

  SUBMIT: `${C}.SUBMIT`,
  CANCEL_SUBMIT: `${C}.CANCEL_SUBMIT`,

  ADD_IMAGE: `${C}.ADD_IMAGE`,
  REMOVE_IMAGE: `${C}.REMOVE_IMAGE`,

  CHANGE_STYLE: `${C}.CHANGE_STYLE`,
  CHANGE_PAGE: `${C}.CHANGE_PAGE`,

  RESET_DATA: `${C}.RESET_DATA`,
}
