import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import purchaseActions from '../../../actions/purchaseActions';
import supplierActions from '../../../actions/supplierActions';
import orderActions from '../../../actions/orderActions';
import appActions from '../../../actions/appActions';
import I18n from 'i18n-js';
import {PURCHASE_SCREEN} from "../../../constants/constants";
import SearchSelect from '../../../ui/components/forms/SearchSelect';

import icAddIcon from '../../../stylesheets/images/ic-add.svg';
import icXIcon from '../../../stylesheets/images/ic-x.svg';

class PurchaseAdd extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      type: 'stock',
      orderDetail: {},
      item: {},
      purchase: {},
      listOrder: [],
      suppliers: [],
      materials: [],
      realMaterials: [],
      selectedMaterials: [],
      selectedSupplier: {},
      errors: {}
    };
    this.changeType = this.changeType.bind(this);
    this.selectMaterial = this.selectMaterial.bind(this);
    this.next = this.next.bind(this);
  }

  componentWillMount() {
    const { options } = this.props;
    !_.get(options, 'isAdd', true) && _.get(options, 'data.id', '') && this.props.purchasePropsActions.getDetail(_.get(options, 'data.id', ''));
    this.props.supplierPropsActions.getListSupplier({ populate: 'materials.material' });
    // this.props.orderPropsActions.getListOrder({ populate:'styleOrders', forAdditional: true });
  }

  componentWillReceiveProps(newProps) {
    let { suppliers, item, purchase } = this.state;
    !_.get(this.props, 'options.isAdd', true) && !_.isEqual(item, newProps.purchaseState.detail) && this.setState({ item: _.cloneDeep(newProps.purchaseState.detail) || {} });
    !_.isEqual(suppliers, newProps.supplierState.items) && this.setState({
      suppliers: _.cloneDeep(newProps.supplierState.items) || [],
      selectedSupplier: newProps.supplierState.items[0],
      materials: newProps.supplierState.items[0].materials,
      realMaterials: newProps.supplierState.items[0].realMaterials,
    });
    !_.isEqual(suppliers, newProps.orderState.items) && this.setState({
      listOrder: _.get(newProps, 'orderState.items', []),
      orderDetail: _.get(newProps, 'orderState.items[0]', null),
      purchase: { ...purchase, customerPO: _.get(newProps, 'orderState.items[0]._id', null) }
    });
  }

  onChange(property, value) {
    let { purchase, suppliers, listOrder } = this.state;
    if (property === 'supplierId') {
      const supplier = suppliers.find(supplier => supplier._id === value);
      this.setState({
        selectedSupplier: supplier,
        materials: supplier.materials,
        realMaterials: supplier.realMaterials,
        selectedMaterials: []
      });
    } else if(property === 'customerPO') {
      const order = listOrder.find(order => order._id === value);
      this.setState({
        orderDetail: order
      });
    }
    this.setState({ purchase: { ...purchase, [property]: value } });
  }

  next() {
    let { selectedSupplier, selectedMaterials, type, orderDetail } = this.state;
    let pageList = [...this.props.appState.pageList];
    if(type === 'stock') {
      selectedMaterials = selectedMaterials.filter(mr => mr._id && mr.color && (_.get(mr, 'material.type', '') == 'FABRIC' || mr.size));
      const stockOrderData = { supplier: selectedSupplier, materials: selectedMaterials };
      this.props.stockOrder(stockOrderData);

      pageList.push({screen: PURCHASE_SCREEN.STOCK_ORDER, data: stockOrderData});

    } else if(type === 'additional') {
      this.props.additionalOrder(orderDetail);
      pageList.push({screen: PURCHASE_SCREEN.ADDITIONAL_ORDER, data: orderDetail});
    }

    this.props.appPropsActions.addPage(pageList);



    this.props.closeModal();
  }

  changeType(type, selected) {
    const { listOrder, purchase } = this.state;
    if (selected) {
      this.setState({ type: type });
    }
    if (type !== 'stock') {
      this.props.orderPropsActions.getListOrder({ populate:'styleOrders', forAdditional: true });
    }
    this.setState({ purchase: { ...purchase, customerPO: type === 'stock' ? null : listOrder[0]._id } });
  }

  selectMaterial(isSelect, material) {
    const { selectedMaterials } = this.state;
    if (isSelect) {
      this.setState({ selectedMaterials: [...selectedMaterials, material] });
    } else {
      this.setState({ selectedMaterials: selectedMaterials.filter(m => m.key !== material.key) });
    }
  }

  close() {
    this.props.closeModal();
  }

  handleAddMaterial = () => {
    let { selectedMaterials } = this.state;
    selectedMaterials.push({});
    this.setState({selectedMaterials});
  }

  handleRemoveMaterial = (index) => {
    let { selectedMaterials } = this.state;
    _.remove(selectedMaterials, (_, i) => i == index);
    this.setState({selectedMaterials});
  }

  onChangeMaterial = (index, data) => {
    let { selectedMaterials } = this.state;
    let material = {
      ...(data._id ? {} : selectedMaterials[index]),
      ...data
    }
    const check = selectedMaterials.filter(mr => mr._id == material._id && mr.color == material.color && mr.size == material.size);
    if (check.length == 0) {
      selectedMaterials[index] = material
      this.setState({selectedMaterials});
    }
  }

  render() {
    let { item, suppliers, errors, materials, realMaterials, purchase, type, selectedMaterials } = this.state;
    const materialsData = (realMaterials || []).map((item, idx1) => ({
      value: item.key,
      label: `${_.get(item, 'material.name', '')}${ _.get(item, 'material.data.content', false) ? (' - ' + _.get(item, 'material.data.content', '')) : ''}`,
      raw: item
    }));
    const listOrder = _.get(this.state, 'listOrder', []);
    return (
      <div className="modal-dialog purchase-add" role="document">
        <div className="modal-content">
          <form>
            <div className="modal-header">
              <a className="close" onClick={() => this.close()}><span>&times;</span></a>
              <h4 className="modal-title" id="myModalLabel">{I18n.t('PagePurchase.ADDPURCHASEORDER')}</h4>
            </div>
            <div className="modal-body">
              <div>
                <ul className="nav nav-tabs col-md-8 col-md-offset-4 col-xs-12 wrap-list-radio" role="tablist">
                  <li role="presentation" className={type === 'stock' ? 'active' : ''}>
                    <a role="tab">
                      <input type="radio" id="stock" checked={type === 'stock'} name="stock"
                        onChange={evt => this.changeType('stock', evt.target.checked)} />
                      <label>{I18n.t('PagePurchase.Stock')}</label>
                      <div className="check">
                        <div className="inside" />
                      </div>
                    </a>
                  </li>
                  <li role="presentation" className={type === 'additional' ? 'active' : ''}>
                    <a role="tab">
                      <input checked={type === 'additional'} type="radio" id="additional" name="additional"
                        onChange={evt => this.changeType('additional', evt.target.checked)} />
                      <label>{I18n.t('PagePurchase.Additional')}</label>
                      <div className="check">
                        <div className="inside" />
                      </div>
                    </a>
                  </li>
                </ul>
                <div className="tab-content">
                  <div role="tabpanel" className={type === 'stock' ? "tab-pane active" : "tab-pane"}>
                    <div className="form-group">
                      <div className="col-md-4 col-xs-12 text">
                        <p>
                          {I18n.t('PagePurchase.Supplier')}
                        </p>
                      </div>
                      <div className="col-md-8 col-xs-12 value">
                        <select className="form-control" onChange={evt => this.onChange('supplierId', evt.target.value)}
                          value={purchase.supplierId || '_'}>
                          {
                            suppliers.map((supplier, idx) => {
                              return (<option key={idx} value={supplier._id}>{supplier.name}</option>);
                            })
                          }
                        </select>
                      </div>
                    </div>
                    <div className="form-group">
                      <h4 className="title mg-top-15 relative">
                        {I18n.t('PageCrm.MATERIALLIST')}
                        <span style={{ float: 'right', position: 'absolute', right: '15px', top: '12px' }}>
                          <span className="cursor-pointer"
                            onClick={() => this.handleAddMaterial()}
                            >
                            {<img src={icAddIcon} alt="" />}
                          </span>
                        </span>
                      </h4>
                      <div className="col-md-12">
                        <table className="global-table box-shadow-none w-full">
                          <tbody>
                            <tr className="bg-white bd-dashed-bottom">
                              <th>{I18n.t('PageCrm.Material')} <span className = "color-red"> *</span></th>
                              <th>{I18n.t('PageCrm.Color')} <span className = "color-red"> *</span></th>
                              <th>{I18n.t('PageCrm.Size')}</th>
                              <th style={{ width: '100px' }}>{I18n.t('PageCrm.Price')} <span className = "color-red"> *</span></th>
                              <th>{I18n.t('PageCrm.Unit')}</th>
                              <th></th>
                            </tr>
                          {selectedMaterials && selectedMaterials.map((material, index) => {
                            return (
                              <tr key={index}>
                                <td>
                                  <SearchSelect
                                      className="fabric-combobox m-w-200 w-100-percent"
                                      options={materialsData}
                                      value={material._id || ""}
                                      onChange={evt => this.onChangeMaterial(index, 
                                        {
                                          _id: _.get(evt, 'value', ''), 
                                          price: _.get(evt, 'raw.price', ''), 
                                          unit: _.get(evt, 'raw.material.data.unit', ''),
                                          material: _.get(evt, 'raw.material', {})
                                        })}
                                  />
                                </td>
                                <td>
                                  <SearchSelect
                                      className="fabric-combobox m-w-100 w-100-percent"
                                      options={
                                        _.get(materialsData.find(m => m.value == material._id), 'raw.color', [])
                                        .map(c => ({value: c, label: c}))
                                      }
                                      value={material.color || ""}
                                      onChange={evt => this.onChangeMaterial(index, {color: _.get(evt, 'value', '')})}
                                  />
                                </td>
                                <td>
                                  {_.get(materialsData.find(m => m.value == material._id), 'raw.material.type', '') != 'FABRIC' ?
                                    <SearchSelect
                                      className="fabric-combobox m-w-100 w-100-percent"
                                      options={
                                        _.get(materialsData.find(m => m.value == material._id), 'raw.size', [])
                                        .map(s => ({value: s, label: s}))
                                      }
                                      value={material.size || ""}
                                      onChange={evt => this.onChangeMaterial(index, {size: _.get(evt, 'value', '')})}
                                    /> : ''
                                  }
                                </td>
                                <td>{<span>{material.price}</span>}</td>
                                <td>
                                  {<span className={'lineHeight40'}>{material.unit}</span>}
                                </td>
                                <td>
                                  <span className="cursor-pointer lineHeight40" 
                                    onClick={evt => this.handleRemoveMaterial(index)}>
                                    {<img src={icXIcon} alt="" />}
                                  </span>
                                </td>
                              </tr>
                            )
                          })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div role="tabpanel" className={type === 'additional' ? "tab-pane active" : "tab-pane"}>
                    <div className="form-group">
                      <div className="col-md-4 col-xs-12 text">
                        <p>
                          {I18n.t('PagePurchase.CustomerPO')}
                        </p>
                      </div>
                      <div className="col-md-8 col-xs-12 value">
                        <select className="form-control" value={purchase.customerPO} onChange={evt => this.onChange('customerPO', evt.target.value)}>
                          {
                            listOrder.map(order => {
                              return (<option key={order.customerPO} value={order._id}>{order.customerPO}</option>);
                            })
                          }
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="cancel bg-black" onClick={() => this.close()}>{I18n.t('Btn.CANCEL')}</button>
              <button type="button" className="bg-orange mg-l-5" onClick={() => this.next()}>{I18n.t('Btn.NEXT')}</button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    ...ownProps,
    authState: state.authState,
    appState: state.appState,
    purchaseState: state.purchaseState,
    supplierState: state.supplierState,
    orderState: state.orderState
  };
}

function mapDispatchToProps(dispatch) {
  return {
    supplierPropsActions: bindActionCreators(supplierActions, dispatch),
    purchasePropsActions: bindActionCreators(purchaseActions, dispatch),
    orderPropsActions: bindActionCreators(orderActions, dispatch),
    appPropsActions: bindActionCreators(appActions, dispatch)
  };
}

PurchaseAdd.propTypes = {
  authState: PropTypes.object.isRequired,
  appPropsActions: PropTypes.object
};

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseAdd);
