import * as types from '../constants/actionTypes';
import {showLoading, hideLoading} from './commonUseActions';
import materialServices from '../services/materialServices';

function getListMaterial (options = {}) {
  return (dispatch) => {
    showLoading(types, dispatch);

    materialServices.getListMaterial(options).then((res) => {
      hideLoading(types, dispatch);
      dispatch({
        type: types.MATERIAL.GET_LIST_SUCCESS,
        data: res.data || []
      });
    }, (error) => {
      hideLoading(types, dispatch);
    });
  };
}

function getDetail(id) {
  return (dispatch) => {
    showLoading(types, dispatch);

    dispatch({
      type: types.MATERIAL.RESET_DETAIL,
      data: {}
    });

    materialServices.getDetail(id, {}).then((res) => {
      hideLoading(types, dispatch);
      if (res) {
        dispatch({
          type: types.MATERIAL.GET_DETAIL_SUCCESS,
          data: res.data || {}
        });
      }
    }, (error) => {
      hideLoading(types, dispatch);
    });
  };
}

function deleteMaterial(id) {
  return (dispatch) => {
    showLoading(types, dispatch);

    materialServices.deleteMaterial(id).then((res) => {
      hideLoading(types, dispatch);
      if (res) {
        dispatch({
          type: types.MATERIAL.RELOAD,
          data: true
        });
      }
    }, (error) => {
      hideLoading(types, dispatch, error);
    });
  };
}

function editMaterial (id, data) {
  return (dispatch) => {
    showLoading(types, dispatch);

    materialServices.editMaterial(id, data).then((res) => {
      hideLoading(types, dispatch);
      if (res) {
        dispatch({
          type: types.MATERIAL.RELOAD,
          data: true
        });
      }
    }, (error) => {
      hideLoading(types, dispatch, error);
    });
  };
}

function addMaterial (data) {
  return (dispatch) => {
    showLoading(types, dispatch);

    materialServices.addMaterial(data).then((res) => {
      hideLoading(types, dispatch);
      if (res) {
        dispatch({
          type: types.MATERIAL.RELOAD,
          data: true
        });
      }
    }, (error) => {
      hideLoading(types, dispatch, error);
    });
  };
}

export default {
  getListMaterial,
  deleteMaterial,
  editMaterial,
  addMaterial,
  getDetail
};
