import _ from 'lodash';

export const checkedQuantity = (data) => {
    let boolValue = false;
    _.map(data.purchaseOrders, item => {
        if(item.materials.length == 0) {
            boolValue = true;
        } else {
            item.materials.map(m => {
                if((m.actual > 0) || (m.sample > 0)) {
                    // || ((m.actual == undefined) && (m.sample == undefined))
                    boolValue = false;
                } else {
                    boolValue = true;
                }
            })
        }
    })
    return boolValue
}