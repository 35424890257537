import _ from 'lodash';
import * as R from 'ramda';

export const isOpen = ({ editOrder }) => {
  const { showing } = editOrder;
  return showing;
}

export const getCustomerPO = ({ editOrder }) => editOrder.customerPO;

export const getSelectableCustomers = ({ editOrder }) => {
  const { selectableCustomers } = editOrder;
  return _.map(selectableCustomers, item => ({
    value: R.path(['_id'], item),
    label: R.path(['name'], item),
    raw: item,
  }));
}

export const getSelectedCustomer = ({ editOrder }) => {
  const { selectedCustomer } = editOrder;
  return R.path(['_id'], selectedCustomer);
}

export const getOrderDate = ({ editOrder }) => editOrder.orderDate;

export const getExFactory = ({ editOrder }) => editOrder.exFactory;

export const getIhDate = ({ editOrder }) => editOrder.ihDate;

export const getDescription = ({ editOrder }) => editOrder.description;

export const isCompleted = ({ editOrder }) => editOrder.completed;

export const getNote = ({ editOrder }) => editOrder.note;

export const getErrors = ({ editOrder }) => editOrder.errors;

export const canSave = ({ editOrder }) => {
  const { selectedCustomer, customerPO, errors, submiting } = editOrder;
  if (submiting) return false;

  return !!_.trim(customerPO) 
    && !!selectedCustomer 
    && !R.path(['customerPO'], errors)
    && !R.path(['selectedCustomer'], errors);
}

export const isSubmiting = ({ editOrder }) => {
  const { submiting } = editOrder;
  return submiting;
}

export const fnGenerateRequestBody = ({ editOrder }) => () => {
  const { rawOrder, completed, note } = editOrder;
  const { selectedCustomer, customerPO, orderDate, exFactory, ihDate, description } = editOrder;
  let body = {
    _id: R.path(['_id'], rawOrder),
    customer: selectedCustomer,
    customerPO,
    orderDate, 
    ihDate, 
    exFactory, 
    description,
  }

  if (completed) {
    body = { ...body, isCompleted: completed, note };
  }

  return body;
}
