import React from 'react';
import BaseComponent from '../base/BaseComponent';
import { Switch, Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import ScreenCustomerPO from '../../ui/containers/customer-po/current/MainView';
import ScreenOrderHistory from './history/ScreenOrderHistory';
import ScreenOrderCanceled from './canceled/ScreenOrdersCanceled';
import { connect } from 'react-redux';
import orderActions from "../../actions/orderActions";
import _ from 'lodash';
import {bindActionCreators} from "redux";
import * as PATH from "../../constants/routeConstants";

class PageOrder extends BaseComponent {
  // constructor (props, context) {
  //   super(props, context);

  //   this.state = {
  //     tabCurrent: '',
  //     selectedOrder: {},
  //   };
  // }

  // componentDidMount() {
  //   this.getListOrder();

  // }

  // componentWillReceiveProps (nextProps) {
  //   let {tabCurrent} = this.state;
  //   !(tabCurrent === _.get(nextProps.appState, 'page.tab', '')) && this.setState({tabCurrent: _.get(nextProps.appState, 'page.tab', '')});
  //   if (_.get(this.props.orderState, 'reload') != _.get(nextProps.orderState, 'reload')) {
  //     this.getListOrder();
  //   }
  // }

  // getListOrder() {
  //   const query = {pageSize: this.state.pageSize, pageNumber: this.state.pageNumber};
  //   const options = { populate: 'styleOrders' };
  //   this.props.orderActions.getListOrder(_.assign(query));
  // }

  render () {
    return (
      <div>
        <Switch>
          <Route path={PATH.CUSTOMER_PO_URL} exact render={() => <Redirect to={PATH.CUSTOMER_PO_CURRENT_URL} />} />
          <Route path={PATH.CUSTOMER_PO_CURRENT_URL} component={ScreenCustomerPO} />
          <Route path={PATH.CUSTOMER_PO_CANCELLED_URL} render={() => <ScreenOrderCanceled {...this.props} />} />
          <Route path={PATH.CUSTOMER_PO_HISTORY_URL} render={() => <ScreenOrderHistory {...this.props} />} />
        </Switch>
      </div>
    );
  }
}

function mapStateToProps (state) {
  return {
    authState: state.authState,
    appState: state.appState,
    orderState: state.orderState,
  };
}

function mapDispatchToProps (dispatch) {
  return {
    // orderActions: bindActionCreators(orderActions, dispatch)
  };
}

PageOrder.propTypes = {
  authState: PropTypes.object.isRequired,
  appState: PropTypes.object,
  orderState: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(PageOrder);
