/**
 * @author hienhc <hienhc@nexlesoft.com>
 * @create date 2018-08-30 01:35:30
*/
import { combineReducers } from 'redux'
import { isCheckable } from "../../core/ship-out/helpers";
import C from '../../constants/ship-out/showPklProdList';
import C2 from '../../constants/ship-out/editPklShip';

/* Store selected filter type */
const filter = (state = null, action) => {
    const { type, payload } = action;
    switch (type) {
        case C.CHANGE_SELECTED_FILTER:
            return payload;
        default:
            return state;
    }
}

/* Store search  */
const searchValue = (state = {}, action) => {
    const { type, payload } = action;
    switch (type) {
        case C.CHANGE_SEARCH_TEXT:
            return {...state, [payload.pos]: payload.data};
        default:
            return state;
    }
}

/* To know whether on searching or not */
const searching = (state = false, action) => {
    const { type } = action;
    switch (type) {
        case C.SEARCH_PKL_PROD_LIST:
            return true;
        case C.CANCEL_SEARCH_PKL_PROD_LIST:
            return false;
        default:
            return state;
    }
}

/* To know whether on fetching data from the api or not */
const fetching = (state = false, action) => {
    const { type } = action;
    switch (type) {
        case C.FETCH_PKL_PROD_LIST:
            return true;
        case C.CANCEL_FETCH_PKL_PROD_LIST:
            return false;
        default:
            return state;
    }
}

/** 
 * Store the display list that will show to the user 
 * =================================================
 **/
const displayList = (state = [], action) => {
    const { type, payload } = action;
    switch (type) {
        case C.CHANGE_PKL_PROD_LIST:
            return payload;
        default:
            return state;
    }
}

/** 
 * Store the selected list that will use to keep track for further actions 
 * =======================================================================
 **/
const checkedList = (state = [], action) => {
    const {type, payload} = action;
    switch (type) {
        case C.ADD_CHECKED_PKL_PROD: 
            return isCheckable(state, payload) ? [...state, payload] : state;
        case C.REMOVE_CHECKED_PKL_PROD:
            let temp = state.filter(it => it._id !== payload._id);
            return temp;
        case C.CLEAR_ALL_CHECKED_PKL_PRODS:
            return [];
        default:
            return state;
    }
}

/** 
 * Store the paging info
 * ======================
 **/
const currentPage = (state = 1, action) => {
    const { type, payload } = action;
    switch (type) {
        case C.CHANGE_PAGING_CURRENT:
            return payload;
        default:
            return state;
    }
}

const totalPages = (state = null, action) => {
    const { type, payload } = action;
    switch (type) {
        case C.CHANGE_PAGING_TOTAL:
            return payload;
        default:
            return state;
    }
}

const searched = (state = false, action) => {
    const {type, payload} = action;
    switch(type) {
        case C.CHANGE_SEARCH_STATUS:
            return payload;
        default:
            return state;
    }
}
const checkEdit = (state = false, action) => {
    const {type, payload} = action;
    switch(type) {
        case C.VIEW_CREATER:
            return payload;
        case C2.CHANGE_PKL_SHIP_DETAIL:
            return payload._id;
        default:
            return state;
    }
}

export const showPklProdList = combineReducers({
    checkEdit,
    filter,
    searchValue,
    searching,
    fetching,
    displayList,
    checkedList,
    currentPage,
    totalPages,
    searched,
});
