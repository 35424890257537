import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
//Constants
import I18n from 'i18n-js';
import { Store } from '../../../CssHandleModule/functions';
import Creatable from 'react-select/lib/Creatable';
const DateTimeField = require('react-datetime');

const store = new Store();
store.setStyles({
  orderNumbers: [1, 2],
  sizes: [
    { order: 1, width: '52%' },
    { order: 2, width: '35%' },
  ],
  aligns: [{ order: 1, margin: { top: 20 } }],
});

class CustomerINV extends Component {
  constructor(props) {
    super(props);
  }

  componentWillMount() {
    // this.getStandard()
  }

  changeShipmentInfo = (key, value) => {
    this.props.changeShipmentInfo(key, value);
  };

  dateFormat = date => {
    try {
      return date.format();
    } catch (error) {
      return null;
    }
  };

  render() {
    const {
      shipMode,
      contType,
      shipAgencies,
      shipmentInfo,
      noDateDelivered,
      disabled,
      listForwarder = [],
    } = this.props;

    return (
      <div>
        <div className="row m-0 pd-bottom-20" style={{ fontSize: 12, backgroundColor: 'white' }}>
          <div className="col">
            <div className="d-flex" style={store.setStyleType('align_1')}>
              <span style={store.setStyleType('size_2')}>
                {I18n.t('PageShiping.BillNo')}
                {!disabled && <span className="color-red"> *</span>}
              </span>
              <input
                disabled={disabled}
                style={store.setStyleType('size_1')}
                className="form-control"
                value={shipmentInfo.billNo || ''}
                onChange={e => this.changeShipmentInfo('billNo', e.target.value)}
              />
            </div>
            <div className="d-flex" style={store.setStyleType('align_1')}>
              <span style={store.setStyleType('size_2')}>
                {I18n.t('PageShiping.CustomsINV')}
                {!disabled && <span className="color-red"> *</span>}
              </span>
              <input
                disabled={disabled}
                type="text"
                style={store.setStyleType('size_1')}
                className="form-control"
                value={(shipmentInfo && shipmentInfo.customInv) || ''}
                onChange={e => this.changeShipmentInfo('customInv', e.target.value)}
              />
            </div>
            <div className="d-flex" style={store.setStyleType('align_1')}>
              <span style={store.setStyleType('size_2')}>{I18n.t('PageShiping.Date')}</span>
              <div style={store.setStyleType('size_1')} className={disabled ? 'dateptimepicker-disabled' : ''}>
                <DateTimeField
                  timeFormat={false}
                  closeOnSelect
                  dateFormat="MMM DD, YYYY"
                  size="md"
                  value={moment(shipmentInfo.date)}
                  onChange={date => this.changeShipmentInfo('date', date.format())}
                />
              </div>
            </div>
            <div className="d-flex" style={store.setStyleType('align_1')}>
              <span style={store.setStyleType('size_2')}>
                {I18n.t('PageShiping.Cont')}
                {!disabled && <span className="color-red"> *</span>}
              </span>
              <input
                disabled={disabled}
                style={store.setStyleType('size_1')}
                className="form-control"
                value={shipmentInfo.cont || ''}
                onChange={e => this.changeShipmentInfo('cont', e.target.value)}
              />
            </div>
            <div className="d-flex" style={store.setStyleType('align_1')}>
              <span style={store.setStyleType('size_2')}>{I18n.t('PageShiping.Seal')}</span>
              <input
                disabled={disabled}
                style={store.setStyleType('size_1')}
                className="form-control"
                value={shipmentInfo.seal || ''}
                onChange={e => this.changeShipmentInfo('seal', e.target.value)}
              />
            </div>
            <div className="d-flex" style={store.setStyleType('align_1')}>
              <span style={store.setStyleType('size_2')}>{I18n.t('PageShiping.ContType')}</span>
              <select
                disabled={disabled}
                style={store.setStyleType('size_1')}
                className="form-control"
                value={shipmentInfo.contType || ''}
                onChange={evt => {
                  this.changeShipmentInfo('contType', evt.target.value);
                }}>
                <option value="">{}</option>
                {(contType || []).map((item, index) => {
                  return (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="d-flex" style={store.setStyleType('align_1')}>
              <span style={store.setStyleType('size_2')}>{I18n.t('PageShiping.From')}</span>
              <input
                disabled={disabled}
                style={store.setStyleType('size_1')}
                className="form-control"
                value={shipmentInfo.from || ''}
                onChange={e => this.changeShipmentInfo('from', e.target.value)}
              />
            </div>
            <div className="d-flex" style={store.setStyleType('align_1')}>
              <span style={store.setStyleType('size_2')}>{I18n.t('PageShiping.To')}</span>
              <input
                disabled={disabled}
                style={store.setStyleType('size_1')}
                className="form-control"
                value={shipmentInfo.to || ''}
                onChange={e => this.changeShipmentInfo('to', e.target.value)}
              />
            </div>
            <div className="d-flex" style={store.setStyleType('align_1')}>
              <span style={store.setStyleType('size_2')}>
                {I18n.t('PageShiping.ShipMode')}
                {!disabled && <span className="color-red"> *</span>}
              </span>
              <select
                disabled={disabled}
                style={store.setStyleType('size_1')}
                className="form-control"
                value={shipmentInfo.shipMode || ''}
                onChange={evt => {
                  this.changeShipmentInfo('shipMode', evt.target.value);
                }}>
                <option value="">{}</option>
                {(shipMode || []).map((item, index) => {
                  return (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="d-flex" style={store.setStyleType('align_1')}>
              <span style={store.setStyleType('size_2')}>{I18n.t('PageShiping.ShipAgencies')}</span>
              <select
                disabled={disabled}
                style={store.setStyleType('size_1')}
                className="form-control"
                value={shipmentInfo.shipAgencies || ''}
                onChange={evt => {
                  this.changeShipmentInfo('shipAgencies', evt.target.value);
                }}>
                <option value="">{}</option>
                {(shipAgencies || []).map((item, index) => {
                  return (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="col">
            <div className="d-flex" style={store.setStyleType('align_1')}>
              <span style={store.setStyleType('size_2')}>{I18n.t('PageShiping.ETD')}</span>
              <div style={store.setStyleType('size_1')} className={disabled ? 'dateptimepicker-disabled' : ''}>
                <DateTimeField
                  timeFormat={false}
                  closeOnSelect
                  dateFormat="MMM DD, YYYY"
                  size="md"
                  value={moment(shipmentInfo.etd)}
                  onChange={date => this.changeShipmentInfo('etd', this.dateFormat(date))}
                />
              </div>
            </div>
            <div className="d-flex" style={store.setStyleType('align_1')}>
              <span style={store.setStyleType('size_2')}>{I18n.t('PageShiping.ETA')}</span>
              <div style={store.setStyleType('size_1')} className={disabled ? 'dateptimepicker-disabled' : ''}>
                <DateTimeField
                  timeFormat={false}
                  closeOnSelect
                  dateFormat="MMM DD, YYYY"
                  size="md"
                  value={moment(shipmentInfo.eta)}
                  onChange={date => this.changeShipmentInfo('eta', this.dateFormat(date))}
                />
              </div>
            </div>
            <div className="d-flex" style={store.setStyleType('align_1')}>
              <span style={store.setStyleType('size_2')}>
                {I18n.t('PageShiping.DateDelivered')} {!noDateDelivered && <span className="color-red"> *</span>}
              </span>
              <div style={store.setStyleType('size_1')}>
                <DateTimeField
                  timeFormat={false}
                  closeOnSelect
                  dateFormat="MMM DD, YYYY"
                  size="md"
                  value={shipmentInfo.dateDelivered ? moment(shipmentInfo.dateDelivered) : null}
                  onChange={date => this.changeShipmentInfo('dateDelivered', this.dateFormat(date))}
                />
              </div>
            </div>
            <div className="d-flex" style={store.setStyleType('align_1')}>
              <span style={store.setStyleType('size_2')}>
                {I18n.t('PageShiping.DeclarationNo')} {!disabled && <span className="color-red"> *</span>}
              </span>
              <input
                disabled={disabled}
                type="text"
                style={store.setStyleType('size_1')}
                className="form-control"
                value={shipmentInfo.declarationNo || ''}
                onChange={e => this.changeShipmentInfo('declarationNo', e.target.value)}
              />
            </div>
            <div className="d-flex" style={store.setStyleType('align_1')}>
              <span style={store.setStyleType('size_2')}>{I18n.t('PageShiping.DeclarationDate')}</span>
              <div style={store.setStyleType('size_1')} className={disabled ? 'dateptimepicker-disabled' : ''}>
                <DateTimeField
                  timeFormat={false}
                  closeOnSelect
                  dateFormat="MMM DD, YYYY"
                  size="md"
                  value={moment(shipmentInfo.declarationDate)}
                  onChange={date => this.changeShipmentInfo('declarationDate', this.dateFormat(date))}
                />
              </div>
            </div>
            <div className="d-flex" style={store.setStyleType('align_1')}>
              <span style={store.setStyleType('size_2')}>{I18n.t('PageShiping.QTYPKG')}</span>
              <input
                disabled={disabled}
                type="text"
                style={store.setStyleType('size_1')}
                className="form-control"
                value={shipmentInfo.qtyPkg || ''}
                onChange={e => this.changeShipmentInfo('qtyPkg', e.target.value)}
              />
            </div>
            <div className="d-flex" style={store.setStyleType('align_1')}>
              <span style={store.setStyleType('size_2')}>{I18n.t('PageShiping.DropOffCont')}</span>
              <input
                disabled={disabled}
                style={store.setStyleType('size_1')}
                className="form-control"
                value={shipmentInfo.dropOffCont || ''}
                onChange={e => this.changeShipmentInfo('dropOffCont', e.target.value)}
              />
            </div>
            <div className="d-flex" style={store.setStyleType('align_1')}>
              <span style={store.setStyleType('size_2')}>{I18n.t('PageShiping.WarehouseAddress')}</span>
              <input
                disabled={disabled}
                style={store.setStyleType('size_1')}
                className="form-control"
                value={shipmentInfo.warehouseAddress || ''}
                onChange={e => this.changeShipmentInfo('warehouseAddress', e.target.value)}
              />
            </div>
            <div className="d-flex" style={store.setStyleType('align_1')}>
              <span style={store.setStyleType('size_2')}>{I18n.t('PageShiping.PickupCont')}</span>
              <input
                disabled={disabled}
                style={store.setStyleType('size_1')}
                className="form-control"
                value={shipmentInfo.pickupCont || ''}
                onChange={e => this.changeShipmentInfo('pickupCont', e.target.value)}
              />
            </div>
            <div className="d-flex" style={store.setStyleType('align_1')}>
              <span style={store.setStyleType('size_2')}>{I18n.t('PageShiping.DeliveryTerm')}</span>
              <input
                disabled={disabled}
                style={store.setStyleType('size_1')}
                className="form-control"
                value={shipmentInfo.deliveryTerm || ''}
                onChange={e => this.changeShipmentInfo('deliveryTerm', e.target.value)}
              />
            </div>
          </div>
          <div className="col">
            <div className="d-flex" style={store.setStyleType('align_1')}>
              <span style={store.setStyleType('size_2')}>{I18n.t('PageShiping.GWeight')}</span>
              <input
                disabled={disabled}
                type="number"
                min="0"
                style={store.setStyleType('size_1')}
                className="form-control"
                value={shipmentInfo.gw || ''}
                onChange={e => this.changeShipmentInfo('gw', e.target.value)}
              />
            </div>
            <div className="d-flex" style={store.setStyleType('align_1')}>
              <span style={store.setStyleType('size_2')}>{I18n.t('PageShiping.CBM')}</span>
              <input
                disabled={disabled}
                style={store.setStyleType('size_1')}
                className="form-control"
                value={shipmentInfo.cbm || ''}
                onChange={e => this.changeShipmentInfo('cbm', e.target.value)}
              />
            </div>
            <div className="d-flex" style={store.setStyleType('align_1')}>
              <span style={store.setStyleType('size_2')}>
                {I18n.t('PageShiping.Forwarder')} {!disabled && <span className="color-red"> *</span>}
              </span>
              <div
                className={disabled ? 'dateptimepicker-disabled input' : 'input'}
                style={store.setStyleType('size_1')}>
                <Creatable
                  options={listForwarder}
                  value={shipmentInfo.forwarder}
                  onChange={data => {
                    this.changeShipmentInfo('forwarder', data.value);
                  }}
                  isClearable={false}
                  defaultValue={shipmentInfo.forwarder}
                  className={disabled ? 'dateptimepicker-disabled z-index-10' : 'z-index-10'}
                  isDisabled={disabled}
                />
              </div>
              {/* <input disabled={disabled} style={store.setStyleType('size_1')} className='form-control' value={shipmentInfo.forwarder || ''} onChange={(e) => this.changeShipmentInfo('forwarder', e.target.value)} /> */}
            </div>
            <div className="d-flex" style={store.setStyleType('align_1')}>
              <span style={store.setStyleType('size_2')}>Advance No.</span>
              <input
                disabled={disabled}
                style={store.setStyleType('size_1')}
                className="form-control col-md-5"
                value={shipmentInfo.advanceNo || ''}
                onChange={e => this.changeShipmentInfo('advanceNo', e.target.value)}
              />
            </div>
            <div className="d-flex" style={store.setStyleType('align_1')}>
              <span style={store.setStyleType('size_2')}>{I18n.t('PageShiping.Advance')}</span>
              <input
                disabled={disabled}
                type="number"
                min="0"
                style={store.setStyleType('size_1')}
                className="form-control col-md-5"
                value={shipmentInfo.advance || ''}
                onChange={e => this.changeShipmentInfo('advance', e.target.value)}
              />
              <span>VND</span>
            </div>
            <div className="d-flex" style={store.setStyleType('align_1')}>
              <span style={store.setStyleType('size_2')}>{I18n.t('PageShiping.AdvanceDate')}</span>
              <div style={store.setStyleType('size_1')} className={disabled ? 'dateptimepicker-disabled' : ''}>
                <DateTimeField
                  timeFormat={false}
                  closeOnSelect
                  dateFormat="MMM DD, YYYY"
                  size="md"
                  value={moment(shipmentInfo.advanceDate)}
                  disabled={disabled ? 'disabled' : undefined}
                  onChange={date => this.changeShipmentInfo('advanceDate', this.dateFormat(date))}
                />
              </div>
            </div>
            <div className="d-flex" style={store.setStyleType('align_1')}>
              <span style={store.setStyleType('size_2')}>{I18n.t('PageSystemSettings.FlightNoVoyageNo')}</span>
              <input
                disabled={disabled}
                style={store.setStyleType('size_1')}
                className="form-control"
                value={shipmentInfo.transportNo || ''}
                onChange={e => this.changeShipmentInfo('transportNo', e.target.value)}
              />
            </div>
            <div className="d-flex" style={store.setStyleType('align_1')}>
              <span style={store.setStyleType('size_2')}>{I18n.t('PageShiping.BorderGate')}</span>
              <input
                disabled={disabled}
                style={store.setStyleType('size_1')}
                className="form-control"
                value={shipmentInfo.borderGate || ''}
                onChange={e => this.changeShipmentInfo('borderGate', e.target.value)}
              />
            </div>
            <div className="d-flex" style={store.setStyleType('align_1')}>
              <span style={store.setStyleType('size_2')}>{I18n.t('PageShiping.TruckNo')}</span>
              <input
                disabled={disabled}
                style={store.setStyleType('size_1')}
                className="form-control"
                value={shipmentInfo.truckNo || ''}
                onChange={e => this.changeShipmentInfo('truckNo', e.target.value)}
              />
            </div>
            <div className="d-flex" style={store.setStyleType('align_1')}>
              <span style={store.setStyleType('size_2')}>{I18n.t('PageShiping.Route')}</span>
              <input
                disabled={disabled}
                style={store.setStyleType('size_1')}
                className="form-control"
                value={shipmentInfo.route || ''}
                onChange={e => this.changeShipmentInfo('route', e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  // shipMode: selector.getShipMode(state),
  // shipmentInfo: selector.getShipmentInfo(state),
  // contType: selector.getContType(state),
  // getShipAgencies: selector.fnGetShipAgencies(state),
});

const mapDispatchToProps = dispatch => ({
  // changeShipmentInfo(key, value) {
  //     dispatch(changeShipmentInfo(key, value))
  // }
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerINV);
