
import { combineReducers } from 'redux';
import C from '../../../constants/customer-po/current/showImagesGallery';

const images = (state = null, action) => {
  const { type, payload } = action;
  switch (type) {
    case C.SHOW_MODAL: {
      return payload;
    }
    default:
      return state;
  }
}

const combined = combineReducers({
  images,
});


export default (state, action) => {
  const { type } = action;
  if (type === C.RESET_DATA) {
    return {};
  }
  return combined(state, action);
};
