import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import FormGroup from '../../forms/FormGroup';
import Input from '../../forms/Input';
import SearchSelect from '../../../components/forms/SearchSelect';
import DateTime from 'react-datetime';
import MainVATCard from '../MainVATCard';
import _ from 'lodash';

class AccountsReceivablePlan extends React.PureComponent {
  render() {
    const { props } = this;
    return (
      <div id="accounting-receivable-plan">
        <div
          className="wrap-filter-search"
          style={{
            top: '-50px',
            right: '0px',
            position: 'absolute',
          }}>
          <ul className="inline-block">
            <li className="float-left mg-right-15">
              <div className="style-checkbox position-relative">
                <input
                  id="accounts-receivable-chargeback-123123"
                  type="checkbox"
                  checked={false}
                  onChange={() => props.changeTypeInvoice()}
                />
                <label htmlFor="accounts-receivable-chargeback-123123">
                  <span className="mg-right-10">
                    <span></span>
                  </span>
                  Chargeback
                </label>
              </div>
            </li>
            {props.roles.showCreate && (
              <li className="float-left style-icon-save" onClick={() => props.save(props.dataApi)}>
                <span className="cursor-pointer">
                  <i className="fa fa-floppy-o" aria-hidden="true"></i>
                  SAVE
                </span>
              </li>
            )}
          </ul>
        </div>

        <div id="header-accounting" className="inline-block w-full position-relative">
          <div className="left w-200" />
          <div className="right top-0 w-full pd-left-300">
            <div className="border-title-left" />
            <div className="title-left left wrap-input-global">
              <div className="input pd-0 position-relative">
                <FormGroup
                  label="Customer"
                  type="select pd-right-0"
                  error={props.errorMessages.customer}
                  inputComponent={
                    <SearchSelect
                      options={props.selectableCustomers || []}
                      value={props.selectedCustomer || ''}
                      onChange={o =>
                        props.changeSelectedCustomer({
                          customer: o,
                          shipMode: props.selectedShippingMode,
                          issuedDate: props.issuedDate,
                        })
                      }
                      isLoading={props.fetchingSelectableCustomer}
                      clearable
                    />
                  }
                />
              </div>

              <div className="style-checkbox mg-5-0 text-right">
                <input
                  type="checkbox"
                  className="mg-l-10"
                  id="createplantinvoicecb123"
                  value={props.isVATPerCustomer}
                  onChange={evt => props.changeVATPerCustomer(evt.target.checked)}
                />
                <label htmlFor="createplantinvoicecb123">
                  <span className="mg-right-10">
                    <span />
                  </span>
                  VAT per customer
                </label>
              </div>

              <div className="input pd-0 position-relative">
                <FormGroup
                  label="Invoice No."
                  type="select pd-right-0"
                  error={props.errorMessages.invNo}
                  inputComponent={
                    <Input
                      type="text"
                      value={props.invoiceNo}
                      onChange={e => props.changeInvoiceNo(e.target.value)}
                      placeholder=""
                    />
                  }
                />
              </div>
              <div className="input pd-0 position-relative">
                <FormGroup
                  label="Credit/Debit Note"
                  type="select pd-right-0"
                  inputComponent={
                    <SearchSelect
                      options={props.selectableCreditDebits || []}
                      value={props.selectedCreditDebit || ''}
                      onChange={o => props.changeSelectedCreditDebit(o)}
                      clearable
                    />
                  }
                />
              </div>

              {/* <div className="input pd-0 position-relative">
                                <FormGroup
                                    label="Credit For Sea Freight"
                                    type="select pd-right-0"
                                    inputComponent={
                                        <Input
                                            type="text"
                                            value={props.creditForSeaFreight}
                                            onChange={e => props.changeCreditForSeaFreight(e.target.value)}
                                            placeholder=""
                                        />
                                    }
                                />
                            </div> */}

              <div className="input pd-0 position-relative no-index">
                <FormGroup
                  label="Price Type"
                  type="select pd-right-0"
                  inputComponent={
                    <SearchSelect
                      options={props.selectablePriceTypes || []}
                      value={props.priceType || ''}
                      onChange={o => props.changePriceType(o)}
                      clearable
                    />
                  }
                />
              </div>
            </div>

            <div className="bd-dashed-bottom wrap-input-global pd-15-0 margin-small">
              <div className="col-md-6 col-xs-12 input">
                <FormGroup
                  label="Issued Date"
                  type="select"
                  inputComponent={
                    <DateTime
                      defaultValue={moment().format('MMM DD, YYYY')}
                      dateFormat="MMM DD, YYYY"
                      timeFormat={false}
                      value={moment(props.issuedDate)}
                      onChange={date => {
                        props.changeIssuedDate(date.format());
                      }}
                    />
                  }
                />
              </div>
              <div className="col-md-6 col-xs-12 input">
                <FormGroup
                  type="position-relative top-7 pd-left-160"
                  label="Amount INV"
                  inputComponent={props.amountInv}
                />
              </div>
              <div className="col-md-6 col-xs-12 input">
                <FormGroup
                  type="position-relative top-7 bold"
                  label="Payment Term"
                  inputComponent={`${props.paymentTerm} days`}
                />
              </div>
              <div className="col-md-6 col-xs-12 input">
                <FormGroup
                  type="position-relative top-7 pd-left-160"
                  label="Total Air Cost"
                  inputComponent={props.totalAirCost}
                />
              </div>
              <div className="col-md-6 col-xs-12 input">
                <FormGroup
                  type="input"
                  label="Due Date"
                  inputComponent={
                    <DateTime
                      defaultValue={moment().format('MMM DD, YYYY')}
                      dateFormat="MMM DD, YYYY"
                      timeFormat={false}
                      value={moment(props.dueDate)}
                      onChange={date => props.changeDueDate(date.format())}
                    />
                  }
                />
              </div>
              <div className="col-md-6 col-xs-12 input">
                {/* <FormGroup
                                    type="position-relative top-7 pd-left-160"
                                    label="Total Credit for Sea Freight"
                                    inputComponent={props.totalCreditForSeaFreight}
                                /> */}
                <FormGroup
                  type="position-relative top-7 color-number bold pd-left-160"
                  label="Total Amount INV"
                  inputComponent={`${props.totalAmountInv} ${props.customerCurrency !== 'VND' ? 'USD' : 'VND'}`}
                />
              </div>
              <div className="col-md-6 col-xs-12 input">
                <FormGroup
                  label="Ship Mode"
                  type="select"
                  error={props.errorMessages.shipMode}
                  inputComponent={
                    <SearchSelect
                      options={props.selectableShippingMode || []}
                      value={props.selectedShippingMode || ''}
                      onChange={o => props.changeShippingMode({ shipMode: o, customer: props.selectedCustomer })}
                      isLoading={props.fetchingSelectableCustomer}
                      clearable
                    />
                  }
                />
              </div>
              <div className="col-md-6 col-xs-12 input">
                {/* <FormGroup
                                    type="position-relative top-7 color-number bold pd-left-160"
                                    label="Total Amount INV"
                                    inputComponent={props.totalAmountInv}
                                /> */}
              </div>
            </div>

            <div className="bottom text-left size-12 pd-15-0 inline-block w-full">
              <div className="col-xs-12">
                <p>
                  <span className="mg-right-15">Say</span> <span className="bold">{props.totalAmountSay || '-'}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="content-main-accounting">
          <div className="content-sidebar top-initial left-0">
            <div className="md content-sidebar-search">
              <input type="text" placeholder="Search" onChange={e => props.changeSearchText(e.target.value)} />
              <span>
                <i className="fa fa-search" aria-hidden="true" />
              </span>
            </div>
            <div className="md content-sidebar-menu">
              <ul className="overflow-y-scroll h-full">
                {_.map(props.filterBills(), (bill, index) => (
                  <li className="style-checkbox" key={index}>
                    <input
                      type="checkbox"
                      // id="content-sidebar-menu-1"
                      className="display-none"
                      checked={bill.checked ? true : false}
                      value={bill.checked}
                      id={bill._id}
                      onChange={evt =>
                        props.changeCheckedBill({
                          ...bill,
                          checked: evt.target.checked,
                          dataRequest: props.dataRequestShipOut,
                        })
                      }
                    />
                    <label htmlFor={bill._id}>
                      <span className="mg-right-10">
                        <span />
                      </span>
                      {bill.billNo}
                    </label>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          {/* <!-- Component Header Convert --> */}
          <div id="header-plan-accounts-payable">
            <div className="wrap-input-global box-shadow bg-white float-left">
              <div className="col-md-4 col-xs-12 input">
                <FormGroup
                  label="Convert to"
                  type="select"
                  inputComponent={
                    <SearchSelect
                      options={props.selectableConvertCurrencies || []}
                      value={props.convertType || props.customerCurrency || ''}
                      onChange={o => props.changeConvertType(o)}
                      isLoading={props.fetchingSelectableCustomer}
                      clearable
                      disabled
                    />
                  }
                />
              </div>

              <div className="col-md-4 col-xs-12 input">
                <FormGroup
                  type="input"
                  label="Rate"
                  inputComponent={
                    <Input
                      type="text"
                      value={props.convertRate}
                      onChange={e => props.changeConvertRate(e.target.value)}
                      placeholder=""
                    />
                  }
                />
              </div>

              <div className="col-md-4 col-xs-12 text col-md-4 col-xs-12 text bd-dashed-left">
                <p>
                  Amount Convert <span className="mg-left-15 color-number bold size-13">{props.amountConvert}</span>
                </p>
                <p>
                  Total Amount Convert 2{' '}
                  <span className="mg-left-15 color-number bold size-13">{props.totalAmountConvert}</span>
                </p>
              </div>
            </div>
          </div>

          {/* <!-- End Component --> */}

          {/* <!-- Component --> */}
          {props.isVATPerCustomer && (
            <MainVATCard
              vatNo={props.mainVATNo}
              vatDate={props.mainVATDate}
              totalAmount={props.mainVATTotalAmount}
              vatAmount={props.mainVATAmount}
              grandTotal={props.mainVATGrandTotal}
              taxRate={props.mainVATTaxRate}
              changeVATNo={value => props.changeMainVATNo(value)}
              changeTotalAmount={value => props.changeMainVATTotalAmount(value)}
              changeVATDate={value => props.changeMainVATDate(value)}
              changeTaxRate={value => props.changeMainVATTaxRate(value)}
            />
          )}
          {/* <!-- End Component --> */}

          <div className="panel-group mg-0" id="panel-heading-main" role="tablist" aria-multiselectable="true">
            {_.map(props.checkedBills, (bill, billKey) => (
              <div className="panel panel-default bd-none radius-0" key={billKey}>
                {/* <!-- Component --> */}
                <div className="panel-heading component" role="tab" id="">
                  <div className="panel-title">
                    <a
                      role="button"
                      data-toggle="collapse"
                      data-parent="#panel-heading-main"
                      href={'#tab_' + billKey}
                      aria-expanded="true"
                      aria-controls="collapseOne">
                      <i className="fa fa-caret-down" aria-hidden="true" />
                    </a>
                    <ul>
                      <li className="float-left">
                        <div className="text">
                          <span className="left">Bill/Cont</span>
                          <span className="right size-15">
                            <span className="medium">{bill.billNo}</span>
                          </span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* <!-- End Component --> */}

                {/* <!-- Component --> */}
                {/* {!props.isVATPerCustomer &&
                                    <div className="bd-solid-bottom-3">
                                        <SubVATCard
                                            vatNo={bill.vatNo}
                                            totalAmount={bill.totalAmount}
                                            vatDate={bill.vatDate}
                                            vatAmount={props.getSubVATAmount(bill.totalAmount, bill.taxRate)}
                                            taxRate={bill.taxRate}
                                            grandTotal={props.getSubVATGrandTotal(bill.totalAmount, bill.taxRate)}
                                            note={bill.vatNote}
                                            isChangeTotalAmount={true}

                                            changeVATTotalAmount={value => props.changeSubVATTotalAmount(bill.billNo, value)}
                                            changeVATNo={value => props.changeSubVATNo(bill.billNo, value)}
                                            changeVATDate={value => props.changeSubVATDate(bill.billNo, value)}
                                            changeTaxRate={value => props.changeSubVATTaxRate(bill.billNo, value)}
                                            changeNote={value => props.changeSubVATNote(bill.billNo, value)}
                                        />
                                    </div>
                                } */}
                {/* <!-- End Component --> */}

                <div
                  id={'tab_' + billKey}
                  className="panel-collapse collapse in"
                  role="tabpanel"
                  aria-labelledby="panel-heading-1">
                  {_.map(bill.customerPOs, (item, cusKey) => (
                    <div className="panel-body pd-0 bg-gray" key={cusKey}>
                      <ul className="bg-white content-header-title pd-left-15 bd-solid-bottom-3">
                        <li style={{ paddingRight: 10 }}>
                          <input
                            type="checkbox"
                            checked={_.get(
                              this.props.checkedCPO,
                              `${bill.billNo}.${_.get(item, 'data[0].orderId')}`,
                              true
                            )}
                            onChange={evt =>
                              this.props.changeCheckedCPO({
                                billNo: bill.billNo,
                                orderId: _.get(item, 'data[0].orderId'),
                                checked: evt.target.checked,
                              })
                            }
                          />
                        </li>
                        <li>
                          <p>
                            Customer PO <span className="bold mg-left-25 size-14 color-green">{item.customerPO}</span>
                          </p>
                        </li>
                        <li className="float-right">
                          <div className="inline-block mg-right-30">
                            <span>{_.get(item, 'data[0].commodity', '')}</span>
                          </div>
                        </li>
                      </ul>
                      {/* <!-- Component --> */}
                      {_.get(this.props.checkedCPO, `${bill.billNo}.${_.get(item, 'data[0].orderId')}`, true) && (
                        <div className="bg-white wrap-input-global pd-15-0-0-0 margin-small">
                          <div className="col-md-4 col-xs-12 input">
                            <ul>
                              <li>
                                <span>Air Cost</span>
                              </li>
                              <li className="pd-left-60">
                                <input
                                  type="text"
                                  className="form-control"
                                  value={item.airCost || null}
                                  onChange={evt => props.changeAirCost(bill.billNo, item.customerPO, evt.target.value)}
                                />
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-4 col-xs-12 input">
                            <ul className="position-relative">
                              <li className="top-0">
                                <span>Total Quantity</span>
                              </li>
                              <li>
                                <span className="color-number bold">{item.totalQuantity || 0}</span>
                              </li>
                            </ul>
                            <ul className="position-relative">
                              <li className="top-0">
                                <span>Total Amount</span>
                              </li>
                              <li>
                                <span className="color-number bold">{item.totalAmount || 0}</span>
                              </li>
                            </ul>
                          </div>

                          <div className="col-md-4 col-xs-12 input h-auto">
                            <ul>
                              <li>
                                <span>Note</span>
                              </li>
                              <li className="textarea pd-left-40">
                                <textarea
                                  rows="4"
                                  cols="50"
                                  value={item.note || null}
                                  onChange={evt => props.changeNoteBill(bill.billNo, item.customerPO, evt.target.value)}
                                />
                              </li>
                            </ul>
                          </div>
                        </div>
                      )}

                      {/* <!-- End Component --> */}
                      {_.get(this.props.checkedCPO, `${bill.billNo}.${_.get(item, 'data[0].orderId')}`, true) && (
                        <div className="wrap-global-table pd-0 inline-block w-full float-left mg-bottom-0">
                          <table className="global-table box-shadow-none w-full">
                            <tbody>
                              <tr className="bg-white bd-solid-bottom">
                                <th>CTNS</th>
                                <th className="center">Style</th>
                                <th className="center">PG</th>
                                <th className="center">Cut Ticket</th>
                                <th className="center">Contractor</th>
                                <th className="center">Store</th>
                                {/* <th className="center">Lable</th> */}
                                <th className="center">Color</th>
                                <th className="center">QTY</th>
                                <th className="center w-100">Unit Price (LDP)</th>
                                <th className="center">Total</th>
                              </tr>
                              {_.map(item.data, (elem, elemKey) => (
                                <tr key={elemKey}>
                                  <td>{elem.cnts}</td>
                                  <td className="center">{elem.style}</td>
                                  <td className="center">{elem.pg || '-'}</td>
                                  <td className="center">{elem.cutTicket}</td>
                                  <td className="center">{elem.contractor}</td>
                                  <td className="center">{elem.store}</td>
                                  {/* <td className="center">yard</td> */}
                                  <td className="center">{elem.color}</td>
                                  <td className="center">{elem.qty}</td>
                                  <td className="center input">
                                    <input
                                      type="number"
                                      className="form-control center"
                                      value={elem.unitPrice || null}
                                      onChange={evt =>
                                        props.changeUnitPrice(bill.billNo, item.customerPO, elemKey, evt.target.value)
                                      }
                                    />
                                  </td>
                                  <td className="center color-number">{elem.qty * (elem.unitPrice || 0)}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

AccountsReceivablePlan.propTypes = {
  selectableCustomers: PropTypes.arrayOf(PropTypes.any).isRequired,
  selectableShippingMode: PropTypes.arrayOf(PropTypes.any).isRequired,
  isVATPerCustomer: PropTypes.bool,
  invoiceNo: PropTypes.string,
  creditForSeaFreight: PropTypes.string,
  priceType: PropTypes.string,
  issuedDate: PropTypes.string,
  dueDate: PropTypes.string,
  selectedShippingMode: PropTypes.string,
  convertType: PropTypes.string,
  convertRate: PropTypes.string,
  mainVATAmount: PropTypes.string,
  mainVATDate: PropTypes.string,
  mainVATTaxRate: PropTypes.string,
  mainVATNo: PropTypes.string,
  mainVATGrandTotal: PropTypes.string,
  mainVATTotalAmount: PropTypes.string,
  fetchingSelectableCustomer: PropTypes.bool,

  changeShippingMode: PropTypes.func,
  changeSelectedCustomer: PropTypes.func,
  changeVATPerCustomer: PropTypes.func,
  changeInvoiceNo: PropTypes.func,
  changeCreditForSeaFreight: PropTypes.func,
  changePriceType: PropTypes.func,
  changeIssuedDate: PropTypes.func,
  changeDueDate: PropTypes.func,
  changeConvertType: PropTypes.func,
  changeConvertRate: PropTypes.func,
};

AccountsReceivablePlan.defaultProps = {};

export default AccountsReceivablePlan;
