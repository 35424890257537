import React from 'react';
import I18n from 'i18n-js';
import PropTypes from 'prop-types';
import SearchBar from '../../../common/components/SearchBar';
import formatCurrency from "format-currency";

import icEyeIcon from '../../../../stylesheets/images/ic-eye.svg';
import CustomPagination from '../../../../components/common/datatables/CustomPagination';

const AccountsListHistoryInvoice = props => {
    let style = {
        width: "100%"
    };
    // console.log(props)
    return (
        <div className="view-accounts-payable-invoice-list">
            <div className="wrap-search-position top-s-70">
                <SearchBar
                    fields={props.listFilter}
                    selectedFilter={props.selectedFilter}
                    searchValue={props.searchValue || {}}
                    onChangeFilter={value => props.onChangeFilter(value)}
                    onChangeValue={(value, pos) => props.onChangeSearchValue(value, pos)}
                    onSearch={() => props.onSearch({...props.paramsRequest})}
                />
            </div>
            <ul className="content-header-title bg-white pd-0-15 box-shadow-1 mg-bottom-5">
                <li className="float-right">
                    <p>Amount
                        <span className="bold mg-left-25 size-12 mg-left-15 color-number">{props.totalAmount}</span>
                    </p>
                </li>
            </ul>
            <table style={style} className="box-shadow bg-white global-table">
                <tbody>
                    <tr>
                        <th className="center">{I18n.t('ScreenAccountsReceivable.InvRequest')}</th>
                        <th className="center">{I18n.t('ScreenAccountsReceivable.InvoiceRef')}</th>
                        <th className="center">{I18n.t('ScreenAccountsReceivable.InvoiceNo')}</th>
                        <th className="center">{I18n.t('ScreenAccountsReceivable.CustomerPO')}</th>
                        <th className="center m-width-105">{I18n.t('ScreenAccountsReceivable.DueDate')}</th>
                        <th className="center">{I18n.t('ScreenAccountsReceivable.Customer')}</th>
                        <th className="center">{I18n.t('ScreenAccountsReceivable.Amount')}</th>
                        <th className="center">{I18n.t('ScreenAccountsReceivable.Received')}</th>
                        <th className="center">{I18n.t('ScreenAccountsReceivable.Actions')}</th>
                    </tr>
                    {props.listInvoice.map((item, index) => {
                        return (
                            <tr key={index}>
                                <td className="center">{item.invRequest || "-"}</td>
                                <td className="center">{item.invRef || "-"}</td>
                                <td className="center">{item.invNo}
                                    {/* <span className="color-link text-decoration cursor-pointer"
                                        onClick={() => props.viewInvoiceNo(item._id)}
                                    >
                                        {item.invNo}
                                    </span> */}
                                </td>
                                <td className="center">
                                    <span className="color-link text-decoration">
                                        {_.map(item.customerPOs, (el, elKey) => (
                                            <div key={elKey}>{el}</div>
                                        ))}
                                    </span>
                                </td>
                                <td className="center">{item.dueDate || "-"}</td>
                                <td className="center">{item.customer || "-"}</td>
                                <td className="center">${formatCurrency(item.amount)}</td>
                                <td className="center color-link dropdown-table-style">
                                    <div className="dropdown">
                                        <button
                                            className="btn btn-secondary dropdown-toggle color-link text-decoration" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                            onClick={() => props.showPaidPopup(item._id)}
                                        >
                                            <span>${formatCurrency(item.receive || 0)}</span>
                                        </button>
                                        <div className="dropdown-menu box-shadow w-300">
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <th>Date</th>
                                                        <th>Paid</th>
                                                        <th>Attachment</th>
                                                    </tr>
                                                    {props.listPaid.map((paid, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>{paid.date}</td>
                                                                <td className="color-number">${paid.receive}</td>
                                                                <td><span className="color-link cursor-pointer" onClick={() => props.openReceiptModal()}>{paid.attachment}</span></td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                            <span className="button-ok center color-orange bold dropdown-toggle cursor-pointer">OK</span>
                                        </div>
                                    </div>
                                </td>
                                <td className="center">
                                    <span className="mg-right-5">
                                        <img
                                            src={icEyeIcon}
                                            alt="Edit"
                                            className="cursor-pointer"
                                            onClick={() => props.view(item._id)}
                                        />
                                    </span>
                                </td>
                            </tr>
                        )
                    })}
                    <tr>

                    </tr>
                </tbody>
            </table>
            <CustomPagination 
                items={props.totalPage} 
                activePage={props.currentPage} 
                onSelect={page => { props.currentPage !== page && props.changePage({...props.paramsRequest, pageNumber: page}) }}
            />
            <div className="center bg-gray">
                <ul className="list-button">
                    <li>
                        <a className="bt-black" onClick={() => props.export()}>EXPORT CB</a>
                    </li>
                </ul>
                <ul className="list-button">
                    <li>
                        <a className="bt-orange" onClick={() => props.openPreviewInvoiceModal(props.listCheckedInvoice)}>CREATE INV REQUEST</a>
                    </li>
                </ul>
            </div>
        </div>
    )
}

AccountsListHistoryInvoice.propTypes = {
    listInvoice: PropTypes.array,
    listFilter: PropTypes.array,
    // listPaid: PropTypes.array,
    // selectedFilter: PropTypes.string,
    // searchValue: PropTypes.object,
    currentPage: PropTypes.number,
    totalPage: PropTypes.number,
    // currency: PropTypes.string,

    // openReceiptModal: PropTypes.func,
    // openPreviewInvoiceModal: PropTypes.func,
    // edit: PropTypes.func,
    // deleteInvoice: PropTypes.func,
    // export: PropTypes.func,
    // showPaidPopup: PropTypes.func,
    // viewInvoiceNo: PropTypes.func,
    // changePage: PropTypes.func,
    // onChangeFilter: PropTypes.func,
    // onChangeSearchValue: PropTypes.func,
    // changeCurrency: PropTypes.func,
    // getListInvoice: PropTypes.func,
}
AccountsListHistoryInvoice.defaultProps = {
    listInvoice: [],
    listFilter: [],
    listPaid: [],
    // selectedFilter: '',
    // searchValue: '',
    // currentPage: 1,
    // totalPage: 1,
    // openReceiptModal: f => f,
    // openPreviewInvoiceModal: f => f,
    // edit: f => f,
    // deleteInvoice: f => f,
    // export: f => f,
    // showPaidPopup: f => f,
    // viewInvoiceNo: f => f,
    // changePage: f => f,
}

export default AccountsListHistoryInvoice;