import {ORDER} from '../constants/actionTypes';
import initial from './initialState';

export default function (state = initial.orderState, action) {
  switch (action.type) {
    case ORDER.GET_LIST_SUCCESS:
      return {...state, items: Object.assign([], [...(action.data.items || [])]), count: action.data.count || 0, overview: action.data.overview};
    case ORDER.GET_LIST_MORE_SUCCESS:
      return {...state, items: state.items.concat(...action.data.items)};
    case ORDER.RELOAD:
      return {...state, reload: !state.reload};
    case ORDER.ADD_ORDER_SUCCESS:
      return {...state, reload: !state.reload, detail: action.data};
    case ORDER.EDIT_ORDER_SUCCESS:{
      return {...state, reload: !state.reload, detail: action.data};
    }
    case ORDER.RELOAD_DETAIL:
      return {...state, reloadDetail: !state.reloadDetail};
    case ORDER.GET_DETAIL_SUCCESS:
      return {...state, detail: action.data};
    case ORDER.RESET_DETAIL:
      return {...state, detail: {}};
    case ORDER.GET_LIST_CUSTOMER:{
      return {...state, items: action.data}
    }
    case ORDER.CHANGE_PAGE_NUMBER:{
      return {...state, pageNumber: action.data}
    }
    case ORDER.RESET_CHANGE_PAGE_NUMBER:{
      return {...state, pageNumber: 1}
    }
    case ORDER.CHANGE_FILTER_TEXT:{
      return {...state, filterText: action.data ? action.data : ''}
    }   
    case ORDER.IS_SEARCH: {
      return {...state, isSearch: true}
    }
    case ORDER.CANCEL_IS_SEARCH: {
      return {...state, isSearch: false}
    }     
    case ORDER.LOAD_MORE: {
      return {...state, isLoadMore: true}
    }
    case ORDER.CANCEL_LOAD_MORE: {
      return {...state, isLoadMore: false}
    }  
    case ORDER.LOADING: {
      return {...state, loading: true}
    } 
    case ORDER.CANCEL_LOADING: {
      return {...state, loading: false}
    }  
    default:
      return state;
  }
}
