export const shipMode = {
  SEA: 'SEA',
  AIR: 'AIR'
};

export const materialTypes = {
  FABRIC: 'FABRIC',
  TRIM: 'TRIM'
};

export const sourceTypes = {
  NOMINATED: 'NOMINATED',
  SOURCED: 'SOURCED'
};

export const sampleApprovalStatus = {
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  RESUBMITTED: 'RESUBMITTED',
  SUBMITTED: 'SUBMITTED'
};

export const garmentStatus = [
  'In stock',
  'Out stock'
];

export const assignmentStatus = {
  NEW: 'NEW',
  IN_PROGRESS: 'IN_PROGRESS',
  DONE: 'DONE'
};
