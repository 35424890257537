import Base from './base';

class Fabric extends Base {
  constructor () {
    super();

    this.getListFabricDAL = this.getListFabricDAL.bind(this);
    this.addFabricDAL = this.addFabricDAL.bind(this);
    this.editFabricDAL = this.editFabricDAL.bind(this);
    this.getFabricDAL = this.getFabricDAL.bind(this);
    this.getFabricByKeyDAL = this.getFabricByKeyDAL.bind(this);
    this.deleteFabricDAL = this.deleteFabricDAL.bind(this);
  }

  getListFabricDAL (query) {

    return super.execute(this.Urls.fabric.list, this.ApiMethod.get, query);
  }

  addFabricDAL (body) {
    return super.execute(this.Urls.fabric.create, this.ApiMethod.post, body);
  }

  editFabricDAL (id, body) {
    let url = this.Urls.fabric.update.replace('{id}', id);
    return super.execute(url, this.ApiMethod.put, body);
  }

  getFabricDAL (id, options) {
    let url = this.Urls.fabric.detail.replace('{id}', id);
    return super.execute(url, this.ApiMethod.get, options);
  }

  getFabricByKeyDAL (key, options) {
    return super.execute(this.Urls.fabric.detail + '/key/' + key, this.ApiMethod.get, options);
  }

  deleteFabricDAL (id) {
    let url = this.Urls.fabric.delete.replace('{id}', id);
    return super.execute(url, this.ApiMethod.delete, null);
  }
}

export default new Fabric();
