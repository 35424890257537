import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import BaseComponent from '../../base/BaseComponent';
import TableList from "../../common/datatables/TableList"
import sampleApprovalActions from "../../../actions/sampleApprovalActions";
import MaterialStatusLog from '../modal/MaterialHistoryStatusLog';
import {Pagination} from "react-bootstrap"
import GarmentStatusLog from '../modal/GarmentHistoryStatusLog';
import TechPackStatusLog from "../modal/TechPackStatusLog";
import I18n from 'i18n-js';
import _ from 'lodash';
import SearchBar from "../../common/layout/SearchBar";
import {
    PURCHASE_LIST_SEARCH_KEY, PURCHASE_LIST_SEARCH_LIST, PURCHASE_STATUS, SEARCH_BAR_EVENT, SEARCH_KEY,
    SEARCH_LIST, PURCHASE_SCREEN, PRODUCTION_TAB
} from "../../../constants/constants";
import purchaseActions from "../../../actions/purchaseActions";
import CustomPagination from '../../common/datatables/CustomPagination';

var FileSaver = require('file-saver');

class ScreenSampleApprovalHistory extends BaseComponent {
    constructor(props, context) {
        super(props, context);
        this.state = {
            pageSize: 10,
            pageNumber: 1,
            items: [],
            search: {key: SEARCH_KEY.CUSTOMER_PO, value: ''},
            columns: [
                {
                    name: I18n.t('PageSampleApproval.CompleteDate'),
                    type: 'date',
                    formatType: 'MMM DD, YYYY',
                    value: 'completeDate'
                },
                {name: I18n.t('PageSampleApproval.CustomerPO'), value: 'styleId.order.customerPO'},
                {name: I18n.t('PageSampleApproval.Customer'), value: 'styleId.order.customer.name'},
                {name: I18n.t('PageSampleApproval.Style'), value: 'styleId.style'},
                {
                    name: I18n.t('PageSampleApproval.LogHistory'),
                    value: '',
                    type: `link`,
                    options: {clsTd: 'color-link'},
                    logs: [`Garment`, `Material`, `Tech-Pack`],
                    onClick: this.onClickLog
                },
            ],
            actions: [
                () => {
                    return {type: 'export', click: (data) => this.btGetExport(data)};
                },
            ],
        };
    }

    onClickLog = (item, name) => {

        const {openModal, closeModal} = this.props.modal;

        switch (name) {
            case `Garment`: {
                let modal = (<GarmentStatusLog closeModal={closeModal} item={item}/>);
                openModal(modal);
                break;
            }
            case `Material`: {
                let modal = (<MaterialStatusLog closeModal={closeModal} auditLog={item.auditLog}/>);
                openModal(modal);
                break;
            }
            case `Tech-Pack`: {
                let modal = (<TechPackStatusLog closeModal={closeModal} info={item.info}/>);
                openModal(modal);
                break;
            }
        }
    }

    getQueryWithDate = (dateTime, type) => {
        let db = new Date(dateTime)
        if (type == 'from') {
            db.setHours(0, 0, 0, 0)
        } else {
            db.setHours(23, 59, 59, 999)
        }
        return db
    }

    onSearch() {
        const {search, pageNumber, pageSize} = this.state;
        this.search(search, pageNumber, pageSize);
    }

    onChangeSearch(property, value, pos) {
        const {search} = this.state;
        switch (property) {
            case SEARCH_BAR_EVENT.FILTER: {
                this.setState({search: {key: value, value: ""}});
                break;
            }
            case SEARCH_BAR_EVENT.SEARCH: {
                if (pos) {
                    if (pos === 'from') {
                        search.from = value
                        this.setState({search})
                    }
                    if (pos === 'to') {
                        search.to = value
                        this.setState({search})
                    }
                } else {
                    this.setState({search: {...this.state.search, value: value}});
                    break;
                }

            }
        }
    }

    search(search, pageNumber, pageSize) {
        const options = {
            ...this.getBaseOption(),
            pageNumber: 1
        }

        if ((search.key && search.value) || (search.key == SEARCH_KEY.COMPLETEDATE && (search.from || search.to))) {
            switch (search.key) {
                case SEARCH_KEY.CUSTOMER_PO: {
                    options[SEARCH_KEY.CUSTOMER_PO] = SEARCH_KEY.CUSTOMER_PO;
                    options.searchValue = search.value;
                    break;
                }
                case SEARCH_KEY.STYLE: {
                    options[SEARCH_KEY.STYLE] = SEARCH_KEY.STYLE;
                    options.searchValue = search.value;
                    break;
                }
                case SEARCH_KEY.CUSTOMER: {
                    options[SEARCH_KEY.CUSTOMER] = SEARCH_KEY.CUSTOMER;
                    options.searchValue = search.value;
                    break;
                }
                case SEARCH_KEY.COMPLETEDATE: {
                    options[SEARCH_KEY.COMPLETEDATE] = SEARCH_KEY.COMPLETEDATE;
                    options.searchFrom = this.getQueryWithDate(search.from, 'from');
                    options.searchTo = this.getQueryWithDate(search.to, 'to');
                    break;
                }
            }
        }


        this.getList(options)
    }

    componentWillMount() {
        this.getList(this.getBaseOption());
    }

    componentWillReceiveProps(nextPorps) {
        const {items, total} = nextPorps.sampleApprovalState;
        if (!_.isEqual(items, this.state.items)) {
            this.setState({items, total});
        }
    }

    getList(options) {
        this.props.sampleApprovalActions.getListSampleApproval(options);
    }

    setPage(page) {
        this.setState({pageNumber: page});
    }

    selectPage = page => {
        this.setState({pageNumber: page}, () => {
            const options = {
                ...this.getBaseOption(),
                pageNumber: page
            }
            this.getList(options)
        });
    }

    getBaseOption = () => {
        const {pageSize, pageNumber} = this.state;

        let options = {
            pageNumber,
            pageSize,
            // populate: [
            //   { path: `styleId`, select: `style orderId`, populate: { path: `orderId`, select: `customerPO customer`, populate: { path: `customer`, select: `name` } } },
            //   { path: `auditLog.materials.material`, select: `name` }
            // ],
            sort: {createdAt: -1}
        };
        return options
    }

    btGetExport = (data) => {
        if (data.styleId && data.styleId.orderId) {
            let url = this.props.getExportSampleApproval(data.styleId.orderId);
            FileSaver.saveAs(url, "sample-approval.xlsx");
        }
    }

    render() {
        const {columns, items, total, pageNumber, pageSize, actions} = this.state;
        let totalPage = _.ceil(total / pageSize);
        return (
            <div id="content">
                <div>
                    <SearchBar fields={SEARCH_LIST.SAMPLE_APPROVAL_HISTORY}
                               onChangeSearch={(property, value, pos) => this.onChangeSearch(property, value, pos)}
                               onSearch={() => this.onSearch()}/>
                </div>

                <TableList {...this.props} items={items || []} columns={columns || []} actions={actions || []}
                           options={{clsTbl: 'global-table'}}/>
                           
                <CustomPagination items={totalPage} activePage={pageNumber} onSelect={this.selectPage} />
            </div>
        );
    }

}

function mapStateToProps(state, ownProps) {
    return {
        sampleApprovalState: state.sampleApprovalState
    };
}

function mapDispatchToProps(dispatch) {
    return {
        sampleApprovalActions: bindActionCreators(sampleApprovalActions, dispatch),
        purchaseActions: bindActionCreators(purchaseActions, dispatch),
        getExportSampleApproval(orderId) {
            return sampleApprovalActions.getExportCurrent(orderId)
        }
    };
}

ScreenSampleApprovalHistory.propTypes = {};

export default connect(mapStateToProps, mapDispatchToProps)(ScreenSampleApprovalHistory);
