import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import SearchSelect from '../../../ui/components/forms/SearchSelect';

import icAddIcon from '../../../stylesheets/images/ic-add.svg';
import icXIcon from '../../../stylesheets/images/ic-x.svg';

class UpdateMaterials extends Component {
    constructor(props, context) {
        super(props, context);
        const {stockMaterials, selectedMaterials } = this.props;
        this.state = {
            stockMaterials: _.clone(stockMaterials || []),
            selectedMaterials
        }
    }

    onChangeMaterial = (index, data) => {
        let { selectedMaterials } = this.state;
        let material = {
            ...(data._id ? {} : selectedMaterials[index]),
            ...data
        }
        const check = selectedMaterials.filter(mr => mr._id == material._id && mr.color == material.color && mr.size == material.size);
        if (check.length == 0) {
            selectedMaterials[index] = material
            this.setState({selectedMaterials});
        }
    }
    handleAddMaterial = () => {
        let { selectedMaterials } = this.state;
        selectedMaterials.push({});
        this.setState({selectedMaterials});
    }

    handleRemoveMaterial = (index) => {
        let { selectedMaterials } = this.state;
        _.remove(selectedMaterials, (_, i) => i == index);
        this.setState({selectedMaterials});
    }

    close() {
        this.props.closeModal();
    }

    next = () => {
        let { selectedMaterials, stockMaterials } = this.state;
        selectedMaterials = selectedMaterials.filter(mr => mr._id && mr.color && (_.get(mr, 'material.type', '') == 'FABRIC' || mr.size));
        const { next, closeModal, initMaterial } = this.props;
        next && next((selectedMaterials || []).map(m => {
            let result;
            result = initMaterial(m);
            let stockMaterial = stockMaterials.find(sm => sm.id == result.id && sm.color == result.color && sm.size == result.size)
            if(stockMaterial) result = _.clone(stockMaterial);
            return result;
        }));
        closeModal && closeModal();
    }

    render = () => {
        const {supplier} = this.props;
        const { selectedMaterials } = this.state;
        const materialsData = (supplier.realMaterials || []).map((item, idx1) => ({
            value: item.key,
            label: `${_.get(item, 'material.name', '')}${ _.get(item, 'material.data.content', false) ? (' - ' + _.get(item, 'material.data.content', '')) : ''}`,
            raw: item
          }));
        return (
            <div className="modal-dialog purchase-add" role="document">
                <div className="modal-content">
                <form>
                    <div className="modal-header">
                    <a className="close" onClick={() => this.close()}><span>&times;</span></a>
                    <h4 className="modal-title" id="myModalLabel">{I18n.t('PagePurchase.ADDPURCHASEORDER')}</h4>
                    </div>
                    <div className="modal-body">
                    <div>
                        <div className="tab-content">
                            <div role="tabpanel">
                                <div className="form-group">
                                <div className="col-md-4 col-xs-12 text">
                                    <p>
                                    {I18n.t('PagePurchase.Supplier') + ": "} <b style={{marginLeft: '10px'}}>{supplier.name}</b>
                                    </p>
                                </div>
                                </div>
                                <div className="form-group">
                                <h4 className="title mg-top-15 relative">
                                    {I18n.t('PageCrm.MATERIALLIST')}
                                    <span style={{ float: 'right', position: 'absolute', right: '15px', top: '12px' }}>
                                    <span className="cursor-pointer"
                                        onClick={() => this.handleAddMaterial()}
                                        >
                                        {<img src={icAddIcon} alt="" />}
                                    </span>
                                    </span>
                                </h4>
                                <div className="col-md-12">
                                    <table className="global-table box-shadow-none w-full">
                                    <tbody>
                                        <tr className="bg-white bd-dashed-bottom">
                                        <th>{I18n.t('PageCrm.Material')} <span className = "color-red"> *</span></th>
                                        <th>{I18n.t('PageCrm.Color')} <span className = "color-red"> *</span></th>
                                        <th>{I18n.t('PageCrm.Size')}</th>
                                        <th style={{ width: '100px' }}>{I18n.t('PageCrm.Price')} <span className = "color-red"> *</span></th>
                                        <th>{I18n.t('PageCrm.Unit')}</th>
                                        <th></th>
                                        </tr>
                                    {selectedMaterials && selectedMaterials.map((material, index) => {
                                        return (
                                        <tr key={index}>
                                            <td>
                                            <SearchSelect
                                                className="fabric-combobox m-w-200 w-100-percent"
                                                options={materialsData}
                                                value={material._id || ""}
                                                onChange={evt => this.onChangeMaterial(index, 
                                                    {
                                                    _id: _.get(evt, 'value', ''), 
                                                    price: _.get(evt, 'raw.price', ''), 
                                                    unit: _.get(evt, 'raw.material.data.unit', ''),
                                                    material: _.get(evt, 'raw.material', {})
                                                    })}
                                            />
                                            </td>
                                            <td>
                                            <SearchSelect
                                                className="fabric-combobox m-w-100 w-100-percent"
                                                options={
                                                    _.get(materialsData.find(m => m.value == material._id), 'raw.color', [])
                                                    .map(c => ({value: c, label: c}))
                                                }
                                                value={material.color || ""}
                                                onChange={evt => this.onChangeMaterial(index, {color: _.get(evt, 'value', '')})}
                                            />
                                            </td>
                                            <td>
                                            {_.get(materialsData.find(m => m.value == material._id), 'raw.material.type', '') != 'FABRIC' ?
                                                <SearchSelect
                                                className="fabric-combobox m-w-100 w-100-percent"
                                                options={
                                                    _.get(materialsData.find(m => m.value == material._id), 'raw.size', [])
                                                    .map(s => ({value: s, label: s}))
                                                }
                                                value={material.size || ""}
                                                onChange={evt => this.onChangeMaterial(index, {size: _.get(evt, 'value', '')})}
                                                /> : ''
                                            }
                                            </td>
                                            <td>{<span>{material.price}</span>}</td>
                                            <td>
                                            {<span className={'lineHeight40'}>{material.unit}</span>}
                                            </td>
                                            <td>
                                            <span className="cursor-pointer lineHeight40" 
                                                onClick={evt => this.handleRemoveMaterial(index)}>
                                                {<img src={icXIcon} alt="" />}
                                            </span>
                                            </td>
                                        </tr>
                                        )
                                    })}
                                    </tbody>
                                    </table>
                                </div>
                                </div>
                            </div>
                        
                        </div>
                    </div>
                    </div>
                    <div className="modal-footer">
                    <button type="button" className="cancel bg-black" onClick={() => this.close()}>{I18n.t('Btn.CANCEL')}</button>
                    <button type="button" className="bg-orange mg-l-5" onClick={() => this.next()}>{I18n.t('Btn.NEXT')}</button>
                    </div>
                </form>
                </div>
            </div>
        );
    }
}

UpdateMaterials.propTypes = {
    materials: PropTypes.array,
    stockMaterials: PropTypes.array,
    closeModal: PropTypes.func,
    initMaterial: PropTypes.func,
    next: PropTypes.func
};

export default UpdateMaterials;
