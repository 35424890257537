import { createSelector } from 'reselect';
import _ from 'lodash';

export const isFetchingSelectableCustomer = ({ editReceivableInvoiceNormal }) => {
  return editReceivableInvoiceNormal.fetchingSelectableCustomers;
};

export const getSelectableCustomers = ({ editReceivableInvoiceNormal }) => {
  // return editReceivableInvoiceNormal.selectableCustomers;
  return _.map(editReceivableInvoiceNormal.selectableCustomers, s => ({
    value: s.customerId,
    label: s.name,
  }));
};

export const getInvoiceDetail = ({ editReceivableInvoiceNormal }) => {
  return editReceivableInvoiceNormal.invoiceDetail;
};

export const getSelectedCustomer = ({ editReceivableInvoiceNormal }) => {
  return editReceivableInvoiceNormal.selectedCustomer;
};

export const isVATPerCustomer = ({ editReceivableInvoiceNormal }) => {
  return editReceivableInvoiceNormal.vatPerCustomer;
};

export const getCreditForSeaFreight = ({ editReceivableInvoiceNormal }) => {
  return editReceivableInvoiceNormal.creditForSeaFreight;
};

export const getSelectableCreditDebits = ({ editReceivableInvoiceNormal }) => {
  // return editReceivableInvoiceNormal.selectableCreditDebits;
  return _.map(editReceivableInvoiceNormal.selectableCreditDebits, s => ({
    value: s._id,
    label: s.code,
    type: s.type,
    amount: s.amount || 0,
  }));
};

export const getSelectedCreditDebit = ({ editReceivableInvoiceNormal }) => {
  if (!editReceivableInvoiceNormal.selectedCreditDebit || !editReceivableInvoiceNormal.selectedCreditDebit.amount) {
    editReceivableInvoiceNormal.selectedCreditDebit = _.get(editReceivableInvoiceNormal, 'invoiceDetail.creditDebit');
  }
  return editReceivableInvoiceNormal.selectedCreditDebit;
};

export const getSelectablePriceTypes = ({ editReceivableInvoiceNormal }) => {
  return _.map(editReceivableInvoiceNormal.selectablePriceTypes, el => {
    return { value: el, label: el };
  });
};

export const getSelectedPriceType = ({ editReceivableInvoiceNormal }) => {
  return editReceivableInvoiceNormal.selectedPriceType;
};

export const getIssuedDate = ({ editReceivableInvoiceNormal }) => {
  return editReceivableInvoiceNormal.issuedDate;
};

export const getDueDate = ({ editReceivableInvoiceNormal }) => {
  return editReceivableInvoiceNormal.dueDate;
};

export const getInvoiceNo = ({ editReceivableInvoiceNormal }) => {
  return editReceivableInvoiceNormal.invoiceNo;
};

export const getSelectableShippingModes = ({ editReceivableInvoiceNormal }) => {
  return _.map(editReceivableInvoiceNormal.selectableShippingModes, el => {
    return { label: el, value: el };
  });
};

export const getSelectedShippingMode = ({ editReceivableInvoiceNormal }) => {
  return editReceivableInvoiceNormal.selectedShippingMode;
};

export const getPaymentTerm = ({ editReceivableInvoiceNormal }) => {
  throw 'NotImplementedException';
};

export const getAmount = ({ editReceivableInvoiceNormal }) => {
  throw 'NotImplementedException';
};

// export const getTotalAirCost = ({ editReceivableInvoiceNormal }) => {
//   throw "NotImplementedException";
// }

// export const getTotalCreditForSeaFreight = ({ editReceivableInvoiceNormal }) => {
//   throw "NotImplementedException";
// }

export const getTotalAmount = ({ editReceivableInvoiceNormal }) => {
  throw 'NotImplementedException';
};

export const getTotalAmountSay = ({ editReceivableInvoiceNormal }) => {
  throw 'NotImplementedException';
};

export const getReceiveType = ({ editReceivableInvoiceNormal }) => {
  return editReceivableInvoiceNormal.receiveType;
};

export const getReceiveNote = ({ editReceivableInvoiceNormal }) => {
  return editReceivableInvoiceNormal.receiveNote;
};

export const getReceiveAmount = ({ editReceivableInvoiceNormal }) => {
  return editReceivableInvoiceNormal.receiveAmount;
};

export const getReceiveFiles = ({ editReceivableInvoiceNormal }) => {
  return editReceivableInvoiceNormal.receiveFiles;
};

export const getSelectableConvertCurrencies = ({ editReceivableInvoiceNormal }) => {
  return _.map(editReceivableInvoiceNormal.selectableConvertCurrencies, el => {
    return { label: el, value: el };
  });
};

export const getSelectedConvertCurrency = ({ editReceivableInvoiceNormal }) => {
  return { label: 'VND', value: 'VND' };
  //return editReceivableInvoiceNormal.selectedConvertCurrency;
};

export const getConvertRate = ({ editReceivableInvoiceNormal }) => {
  return editReceivableInvoiceNormal.convertRate;
};

export const getConvertAmount = ({ editReceivableInvoiceNormal }) => {
  return editReceivableInvoiceNormal.convertAmount;
};

export const getCheckableBills = ({ editReceivableInvoiceNormal }) => {
  console.log(editReceivableInvoiceNormal);
  return editReceivableInvoiceNormal.checkableBillList;
};

export const getCheckedBills = ({ editReceivableInvoiceNormal }) => {
  // return editReceivableInvoiceNormal.checkedBillList;
  return _.map(editReceivableInvoiceNormal.checkedBillList, el => {
    el.customerPOs = _.map(el.customerPOs, item => {
      let totalQuantity = _.reduce(
        item.data,
        (total, el) => {
          return total + parseInt(el.qty);
        },
        0
      );
      item.totalQuantity = totalQuantity;
      return item;
    });
    return el;
  });
};

export const getDataRequestShipOutByCustomer = ({ editReceivableInvoiceNormal }) => {
  let obj = {};
  obj.chargeBack = false;
  obj.billNo = _.map(editReceivableInvoiceNormal.checkedBillList, el => el.billNo);
  obj.shipMode = _.get(editReceivableInvoiceNormal, 'selectedShippingMode.value', null);
  obj.customer = _.get(editReceivableInvoiceNormal, 'selectedCustomer.value', null);
  return obj;
};

/**
 * Get amount convert using convertRate and selectedConvertCurrency to calculate
 */
export const getAmountConvert = createSelector(
  [getAmount, getSelectedConvertCurrency, getConvertRate],
  (amount, selectedConvertCurrency, convertRate) => {
    throw 'NotImplementedException';
  }
);

/**
 * Get total amount converted using convert rate and selected convert currency
 */
export const getTotalAmountConvert = createSelector(
  [getTotalAmount, getSelectedConvertCurrency, getConvertRate],
  (totalAmount, selectedConvertCurrency, convertRate) => {
    throw 'NotImplementedException';
  }
);

export const getMainVATNo = ({ editReceivableInvoiceNormal }) => {
  return editReceivableInvoiceNormal.mainVATNo;
};

export const getMainVATDate = ({ editReceivableInvoiceNormal }) => {
  return editReceivableInvoiceNormal.mainVATDate;
};

export const getMainVATTaxRate = ({ editReceivableInvoiceNormal }) => {
  return editReceivableInvoiceNormal.mainVATTaxRate;
};

export const getMainVATTotalAmount = ({ editReceivableInvoiceNormal }) => {
  return editReceivableInvoiceNormal.mainVATTotalAmount;
};

// export const getMainVATAmount = ({ editReceivableInvoiceNormal }) => {
//   // TODO.
//   throw "NotImplementedException";
// }
export const getMainVATAmount = createSelector([getMainVATTotalAmount, getMainVATTaxRate], (totalAmount, rate) => {
  return ((totalAmount * rate) / 100).toFixed(2);
});

// export const getMainVATGrandTotal = ({ editReceivableInvoiceNormal }) => {
//   // TODO.
//   throw "NotImplementedException";
// }
export const getMainVATGrandTotal = createSelector(
  [getMainVATAmount, getMainVATTotalAmount],
  (amountVAT, totalAmount) => {
    return (parseFloat(amountVAT) + parseFloat(totalAmount || 0)).toFixed(2);
  }
);

/**
 * Bill | Cont
 */
export const getDisplayType = createSelector([getSelectedShippingMode], shippingMode => {
  // If mode is Sea then Bill, otherwise Cont
  throw 'NotImplementedException';
});

/**
 * Based on display type, if Bill then use checkableBillList else checkableContList
 */
export const getCheckableItemList = createSelector(
  [getDisplayType, ({ editReceivableInvoiceNormal }) => editReceivableInvoiceNormal],
  (displayType, state) => {
    throw 'NotImplementedException';
  }
);

export const getEditableItemList = createSelector(
  [getDisplayType, ({ editReceivableInvoiceNormal }) => editReceivableInvoiceNormal],
  (displayType, state) => {
    throw 'NotImplementedException';
  }
);

/**
 * Return a function to generate request body which will be used to send to api
 */
export const fnGenerateRequestBody = ({ editReceivableInvoiceNormal }) => () => {
  throw 'NotImplementedException';
};

export const getAmountInv = ({ editReceivableInvoiceNormal }) => {
  return _.reduce(
    editReceivableInvoiceNormal.checkedBillList,
    (total, el) => {
      return (
        total +
        _.reduce(
          el.customerPOs,
          (t, customerPO) => {
            return t + parseFloat(customerPO.totalAmount || 0);
          },
          0
        )
      );
    },
    0
  );
};

export const getTotalAirCost = ({ editReceivableInvoiceNormal }) => {
  return _.reduce(
    editReceivableInvoiceNormal.checkedBillList,
    (total, el) => {
      return (
        total +
        _.reduce(
          el.customerPOs,
          (t, customerPO) => {
            return t + parseFloat(customerPO.airCost || 0);
          },
          0
        )
      );
    },
    0
  );
};

export const getTotalAmountInv = createSelector(
  [getAmountInv, getTotalAirCost, getMainVATAmount, getSelectedCreditDebit],
  (amount, airCost, mainVAT, selectedCreditDebit) => {
    let tempAmount = 0;
    if (selectedCreditDebit && selectedCreditDebit.amount) {
      tempAmount = selectedCreditDebit.type == 'Debit' ? -1 * selectedCreditDebit.amount : selectedCreditDebit.amount;
    }
    return (parseFloat(amount) + parseFloat(airCost || 0) + parseFloat(mainVAT || 0) + parseFloat(tempAmount)).toFixed(
      2
    );
  }
);

export const getTotalCreditForSeaFreight = createSelector([getAmountInv, getCreditForSeaFreight], (total, credit) => {
  return ((parseFloat(total) * parseFloat(credit || 0)) / 100).toFixed(2);
});

export const getDataApi = createSelector(
  [getTotalAmountInv, ({ editReceivableInvoiceNormal }) => editReceivableInvoiceNormal],
  (totalAmountInv, editReceivableInvoiceNormal) => {
    let data = {};
    data.id = _.get(editReceivableInvoiceNormal, 'invoiceDetail._id', null);
    data.customer = _.get(editReceivableInvoiceNormal, 'selectedCustomer.value', null);
    data.invNo = editReceivableInvoiceNormal.invoiceNo;
    data.creditSea = editReceivableInvoiceNormal.creditForSeaFreight;
    data.priceType = _.get(editReceivableInvoiceNormal, 'selectedPriceType.value', null);
    data.issuedDate = editReceivableInvoiceNormal.issuedDate;
    data.paymentTerm = editReceivableInvoiceNormal.paymentTerm || '';
    data.dueDate = editReceivableInvoiceNormal.dueDate;
    data.shipMode = _.get(editReceivableInvoiceNormal, 'selectedShippingMode.value', null);
    data.amount = totalAmountInv || 0;
    data.vatPerMonth = editReceivableInvoiceNormal.vatPerCustomer;
    data.convertType = _.get(editReceivableInvoiceNormal, 'selectedConvertCurrency.value', null);
    data.convertRate = editReceivableInvoiceNormal.convertRate;
    data.convertAmount = editReceivableInvoiceNormal.convertAmount;
    if (data.vatPerMonth) {
      data.vatNo = editReceivableInvoiceNormal.mainVATNo;
      data.vatDate = editReceivableInvoiceNormal.mainVATDate || new Date();
      data.taxRate = editReceivableInvoiceNormal.mainVATTaxRate;
      data.totalAmount = editReceivableInvoiceNormal.mainVATTotalAmount;
    }

    data.receiveType = editReceivableInvoiceNormal.receiveType;
    data.receiveAmount = editReceivableInvoiceNormal.receiveAmount;
    data.receiveNote = editReceivableInvoiceNormal.receiveNote;
    data.receiveFiles = editReceivableInvoiceNormal.receiveFiles;

    data.billsContainers = [];
    _.each(editReceivableInvoiceNormal.checkedBillList, item => {
      let bill = {};
      bill.billNo = item.billNo;
      if (!data.vatPerMonth) {
        bill.vatNo = item.vatNo;
        bill.vatDate = item.vatDate || new Date();
        bill.taxRate = item.taxRate;
        bill.totalAmount = item.totalAmount;
      }
      let orders = [];
      _.each(item.customerPOs, elem => {
        let tmp = {};
        tmp.order = elem.order || _.get(elem, 'data[0].orderId');
        tmp.airCost = elem.airCost;
        tmp.note = elem.note;
        tmp.items = [];
        _.each(elem.data, o => {
          tmp.items.push({
            ctns: o.cnts,
            style: o.style,
            pg: o.pg || null,
            cutTicket: o.cutTicket,
            contractor: o.contractor,
            store: o.store,
            label: o.label || null,
            color: o.color,
            qty: o.qty,
            unitPrice: o.unitPrice || null,
          });
        });

        orders.push(tmp);
      });
      bill.orders = orders;
      data.billsContainers.push(bill);
    });
    data.creditDebit = _.get(editReceivableInvoiceNormal, 'selectedCreditDebit.value', null);
    return data;
  }
);

export const getSuccess = ({ editReceivableInvoiceNormal }) => {
  return editReceivableInvoiceNormal.success;
};
