
import { combineReducers } from 'redux';
import _ from 'lodash';
import C from '../../../constants/customer-po/current/createStyle';

const orderId = (state = null, action) => {
  const { type, payload } = action;
  switch (type) {
    case C.SHOW_MODAL:
      return payload;
    default:
      return state;
  }
}

const images = (state = [], action) => {
  const { type, payload } = action;
  switch (type) {
    case C.ADD_IMAGE: {
      const stateClone = _.cloneDeep(state);
      stateClone.push(payload);
      return stateClone;
    }
    case C.REMOVE_IMAGE:
      return state.filter(img => img.id !== payload);
    default:
      return state;
  }
}

const style = (state = '', action) => {
  const { type, payload } = action;
  switch (type) {
    case C.CHANGE_STYLE:
      return payload;
    default:
      return state;
  }
}

const page = (state = '', action) => {
  const { type, payload } = action;
  switch (type) {
    case C.CHANGE_PAGE:
      return payload;
    default:
      return state;
  }
}

const errors = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    // case C.CHANGE_PAGE:
    //   return {...state, 'page': !payload ? 'Please input page' : null };
    case C.CHANGE_STYLE:
      return {...state, 'style': !payload ? 'Please input style': null };
    default:
      return state;
  }
}

const submiting = (state = false, action) => {
  const { type } = action;
  switch (type) {
    case C.SUBMIT:
      return true;
    case C.CANCEL_SUBMIT:
      return false;
    default:
      return state;
  }
}

const combined = combineReducers({
  orderId,
  images,
  style,
  page,
  errors,
  submiting,
});


export default (state, action) => {
  const { type } = action;
  if (type === C.RESET_DATA) {
    return {};
  }
  return combined(state, action);
};

