import Base from './base';
import Urls from '../constants/apiUrls';
const BASE_URL = `${Urls.baseUrl}/erp/api/accounting/receivable-invoice`;

class Accounting extends Base {

	constructor() {
		super();
		this.getListSupplierDAL = this.getListSupplierDAL.bind(this);
		this.getListCreditDebitDAL = this.getListCreditDebitDAL.bind(this);
	}

	// All type of Invoice

	createInvoice = (data) => {
		return super.execute(this.Urls.accounting.payable.createInvoice, this.ApiMethod.post, data)
	}

	getListForwarder = () => {
		return super.execute(this.Urls.accounting.payable.listForwarder, this.ApiMethod.get);
	}

	getListSupplierDAL = (params) => {
		return super.execute(this.Urls.accounting.payable.listSuppier, this.ApiMethod.get, params);
	}

	getListCreditDebitDAL = (params) => {
		return super.execute(this.Urls.accounting.payable.listCreditDebit, this.ApiMethod.get, params);
	}

	getListPurchaseOrderDAL = (query) => {
		return super.execute(this.Urls.accounting.payable.listPurchaseOrder, this.ApiMethod.get, query);
	}

	// Create Invoice Imp Emp Container

	getListForwarder = () => {
		return super.execute(this.Urls.accounting.payable.listForwarder, this.ApiMethod.get);
	}

	getListContainer = (query) => {
		return super.execute(this.Urls.accounting.payable.listContainer, this.ApiMethod.get, query)
	}

	// create invoice IE Declaration

	getListDeclaration = () => {
		return super.execute(this.Urls.accounting.payable.listDeclaration, this.ApiMethod.get);
	}

	//create invoce IE US Delivery

	getListCompany = () => {
		return super.execute(this.Urls.accounting.payable.listCompany, this.ApiMethod.get);
	}

	getListBill = (query) => {
		return super.execute(this.Urls.accounting.payable.listBill, this.ApiMethod.get, query);
	}
	// invoice list screen

    getListInvoice = (query) => {
        return super.execute(this.Urls.accounting.payable.listInvoice, this.ApiMethod.get, query)
	}
	
	deleteInvoice = (id) => {
		return super.execute(this.Urls.accounting.payable.deleteInvoice + '/' + id, this.ApiMethod.delete)
	}

	searchInvoiceList = (query) => {
		return super.execute(this.Urls.accounting.payable.listInvoice, this.ApiMethod.get, query)
	}

    getAmountInvoice = (query) => {
        return super.execute(this.Urls.accounting.payable.getAmountInvoiceList, this.ApiMethod.get, query)
    }

    getPaidDetailInvoice = (id) => {
        return super.execute(this.Urls.accounting.payable.listPaid + id , this.ApiMethod.get)
    }

    getListInvoiceHistory = (query) => {
        return super.execute(this.Urls.accounting.payable.listInvoice, this.ApiMethod.get, query)
    }

    getAmountInvoiceHistory = (query) => {
        return super.execute(this.Urls.accounting.payable.getAmountInvoiceHistory, this.ApiMethod.get, query)
    }

    getPaidDetailInvoiceHistory = (query) => {
        // return super.execute(this.Urls.accounting.payable.getAmountInvoice, this.ApiMethod.get, query)
	}

	getListContractorDAL = (params) => {
		return super.execute(this.Urls.accounting.payable.listContractor, this.ApiMethod.get, params);
	}

	getListCustomerPODAL = (params) => {
		return super.execute(this.Urls.accounting.payable.listCustomerPO, this.ApiMethod.get, params);
	}

    createINVRequest = (data) => {
        return super.execute(this.Urls.accounting.payable.createINVRequest, this.ApiMethod.post, data)
    }

	getInvoiceDetail = (id, op) => {
		return super.execute(this.Urls.accounting.payable.getInvoiceDetail + id, this.ApiMethod.get, op)
	}

	//Update Invoice
	updateInvoice = (id, data) => {
		return super.execute(this.Urls.accounting.payable.updateInvoice + id, this.ApiMethod.put, data)
	}

	getExportINVRequest = (ids) => {
		return `${this.Urls.accounting.payable.exportINVRequest}?ids=${ids}`;
	}

	getExportReceiableINVRequest = (ids) => {
		return `${this.Urls.accounting.receiable.exportINVRequest}?ids=${ids}`;
	}

	getExportReceiableINV = (id) => {
		return this.Urls.accounting.receiable.exportINV.replace('{invId}', id);
	}
	
	listBillsOrContsByCustomer(shipMode, customer, chargeBack = false) {
		const url = `${BASE_URL}/list-billsconts`;
		return super.execute(url, this.ApiMethod.post, { shipMode, customer, chargeBack });
	}
	getListShipOutByCustomer(body) {
		const url = `${BASE_URL}/list-shipouts`;
		return this.execute(url, this.ApiMethod.post, body);
	}
}

export default new Accounting();