import React from "react";
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as R from 'ramda';
import I18n from 'i18n-js';
import './style.scss';

import IMG_DEFAULT from "../../../stylesheets/images/style-no.svg";

import icEditXIcon from '../../../stylesheets/images/ic-edit-x.svg';
import icEditRIcon from '../../../stylesheets/images/ic-edit-r.svg';
import icAddOrderIcon from '../../../stylesheets/images/ic-add-order.svg';

const StyleItemList = props => (
  <div className="md content-main-info-sidebar">
    <div className="md content-style">
      <ul>
        <li className="text">{I18n.t("PageOrder.Style")}</li>
        {props.canAddStyle && (
          <li className="icon cursor-pointer" onClick={() => props.onAddStyleClick()}>
            <img
              src={icAddOrderIcon}
            />
          </li>
        )}
      </ul>
    </div>

    <div className="md content-main-info-sidebar-menu">
      <ul>
        {_.map(props.items || [], item => (
          <li
            className={`text ${R.path(['active'], item) ? "active" : ""}`}
            key={R.path(['value'], item)}
            onClick={() => props.onItemClick(item)}
            title={`${R.path(['style'], item)} - ${R.path(['page'], item)}`}
          >
            <span className="pictures cursor-pointer">
              <img
                onClick={() => props.openImageGallery(item)}
                src={R.path(['imageUrl'], item) || IMG_DEFAULT}
                alt=""
              />
            </span>
            <span className="left">{_.truncate(R.path(['style'], item), { length: 14 })}</span>
            <span className="right">{_.truncate(R.path(['page'], item), { length: 3 })}</span>

            {R.path(['active'], item) && (
              <i className="fa fa-caret-right" aria-hidden="true" />
            )}
            <span className="line-column">
              <span />
            </span>

            {(props.canEditStyle || props.canDeleteStyle) && (
              <div className="icon-edit-delete">
                {props.canEditStyle && (
                  <span
                    className="cursor-pointer"
                    onClick={() => props.onEditItem(item)}
                  >
                    <img
                      src={icEditXIcon}
                      alt=""
                    />
                  </span>
                )}

                {(props.canDeleteStyle && (_.get(item, "raw.deletable", "") === "YES" || _.get(item, "raw.deletable", "") === "NEED_CONFIRM")) && (
                  <span
                    className="cursor-pointer"
                    onClick={() => props.onDeleteItem(item)}
                  >
                    <img
                      src={icEditRIcon}
                      alt=""
                    />
                  </span>
                )}
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  </div>
);

StyleItemList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  canAddStyle: PropTypes.bool.isRequired,
  canEditStyle: PropTypes.bool.isRequired,
  canDeleteStyle: PropTypes.bool.isRequired,

  onAddStyleClick: PropTypes.func,
  openImageGallery: PropTypes.func,
  onItemClick: PropTypes.func,
  onEditItem: PropTypes.func,
  onDeleteItem: PropTypes.func,
};

StyleItemList.defaultProps = {
  onAddStyleClick: f => f,
  openImageGallery: f => f,
  onItemClick: f => f,
  onEditItem: f => f,
  onDeleteItem: f => f,
};

export default StyleItemList;
