import React from 'react';
import PropTypes from 'prop-types';


const Input = (props) => {
  const { isValid, ...rest } = props;
  let css = '';
  if (isValid !== undefined && isValid !== null) css = isValid ? 'sw-border-success' : 'sw-border-danger';
  return (
    <input
      className={`form-control ${css}`}
      {...rest}
    />
  );
};

Input.propTypes = {
  isValid: PropTypes.bool,
};

Input.defaultProps = {
  isValid: undefined,
};

export default Input;
