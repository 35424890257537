import C from "../../../constants/accounting/receivable/editInvoiceNormal";
import accountingServices from "../../../services/AccountReceivableService";
import payInfoServices from "../../../services/payInfoServices";
import _ from 'lodash';

export const getDetailNormalInvoice = (id) => dispatch => {
    accountingServices.getDetailInvoice(id).then(res => {
        dispatch(listCreditDebitNote(_.get(res, 'data.customer.id', null)));
        dispatch({type: C.CHANGE_INVOICE_DETAIL, payload: _.get(res, 'data', {})});
        dispatch({type: C.CHANGE_INVOICE_NO, payload: _.get(res, 'data.invNo', null)});
        dispatch({type: C.CHANGE_ISSUED_DATE, payload: _.get(res, 'data.issuedDate', null)});
        dispatch({type: C.CHANGE_DUE_DATE, payload: _.get(res, 'data.dueDate', null)});
        dispatch({type: C.CHANGE_SHIP_MODE, payload: { label: _.get(res, 'data.shipMode', null), value: _.get(res, 'data.shipMode', null) } });
        dispatch({type: C.CHANGE_CONVERT_RATE, payload: _.get(res, 'data.convertRate', null)});
        dispatch({type: C.CHANGE_CONVERT_AMOUNT, payload: _.get(res, 'data.convertAmount', null)});
        dispatch({type: C.CHANGE_CONVERT_TYPE, payload: { label: _.get(res, 'data.convertType', null), value: _.get(res, 'data.convertType', null) } });
        dispatch({type: C.CHANGE_SELECTED_CUSTOMER, payload: { value: _.get(res, 'data.customer.id', null), label: _.get(res, 'data.customer.name', null) } });
        dispatch({type: C.CHANGE_SELECTED_PRICE, payload: { value: _.get(res, 'data.priceType', null), label: _.get(res, 'data.priceType', null) }});

        dispatch({type: C.CHANGE_SELECTED_CREDIT_DEBIT, payload: { value: _.get(res, 'data.creditDebit.id', null), label: _.get(res, 'data.creditDebit.code', null) } });
        // change bill list
        let bills = _.map(_.get(res, 'data.billsContainers', []), (el) => { 
          return { _id: el._id, billNo: el.billNo, checked: true } 
        });
        dispatch({type: C.CHANGE_LIST_BILL, payload: bills });
        // change customerPO data of bill
        let data = _.map(_.get(res, 'data.billsContainers', []), (el) => { 
          el.customerPOs = _.map(el.orders, (order) => {
            order.data = order.items;
            order.totalQuantity = _.reduce(order.data, (sum, item) => { return sum + item.qty }, 0);
            order.totalAmount = _.reduce(order.data, (total, elem) => { return total + (elem.qty * (elem.unitPrice || 0)) }, 0);
            order = _.omit(order, ['items']);
            return order;
          });
          el = _.omit(el, ['orders']);
          return el;    
        });
        dispatch({type: C.CHANGE_LIST_SELECTED_BILL, payload: data});
    }, (error) => {
        console.log(JSON.stringify(error));
    })
}

export const listCustomer = () => dispatch => {
  accountingServices.listCustomers({}).then(
    res => {
      dispatch({ type: C.CHANGE_LIST_CUSTOMER, payload: res.data });
    },
    error => {
      console.log(JSON.stringify(error));
    }
  );
};

export const listBill = (params) => dispatch => {
  accountingServices.listBillsOrContsByCustomer(params.shipMode, params.customer).then(
    res => {
      dispatch({ type: C.CHANGE_LIST_BILL, payload: res.data });
    },
    error => {
      console.log(JSON.stringify(error));
    }
  );
};

export const listCreditDebitNote = (customerId) => dispatch => {
  payInfoServices.getListPayInfo({customer: customerId}).then(
    res => {
      dispatch({ type: C.CHANGE_LIST_CREDIT_DEBIT_NOTE, payload: res.data });
    },
    error => {
      console.log(JSON.stringify(error));
    }
  );
};

export const changeSelectedCreditDebit = data => dispatch => {
  dispatch({ type: C.CHANGE_SELECTED_CREDIT_DEBIT, payload: data });
};

export const changeVATPerCustomer = value => dispatch => {
  dispatch({ type: C.CHANGE_VAT_PER_CUSTOMER, payload: value });
};

export const changeSelectedCustomer = data => dispatch => {
  dispatch({ type: C.CHANGE_SELECTED_CUSTOMER, payload: data.customer });
  if(data.customer && data.shipMode) {
    dispatch(listBill({shipMode: data.shipMode.value, customer: data.customer.value}))
  }
};

export const changeInvoiceNo = value => dispatch => {
  dispatch({ type: C.CHANGE_INVOICE_NO, payload: value });
};

export const changeCreditForSeaFreight = value => dispatch => {
  dispatch({ type: C.CHANGE_CREADIT_FOR_SEA_FREIGHT, payload: value });
};

export const changePriceType = value => dispatch => {
  dispatch({ type: C.CHANGE_SELECTED_PRICE, payload: value });
};

export const changeIssuedDate = value => dispatch => {
  dispatch({ type: C.CHANGE_ISSUED_DATE, payload: value });
};

export const changeDueDate = value => dispatch => {
  dispatch({ type: C.CHANGE_DUE_DATE, payload: value });
};

export const changeShippingMode = data => dispatch => {
  dispatch({ type: C.CHANGE_SHIP_MODE, payload: data.shipMode });
  if(data.customer && data.shipMode) {
    dispatch(listBill({shipMode: data.shipMode.value, customer: data.customer.value}))
  }
};

export const changeConvertType = value => dispatch => {
  dispatch({ type: C.CHANGE_CONVERT_TYPE, payload: value });
};

export const changeConvertRate = value => dispatch => {
  dispatch({ type: C.CHANGE_CONVERT_RATE, payload: value });
};

export const changeConvertAmount = value => dispatch => {
  dispatch({ type: C.CHANGE_CONVERT_AMOUNT, payload: value });
}

export const changeSearchText = value => dispatch => {
  dispatch({ type: C.CHANGE_SEARCH_TEXT, payload: value });
};

export const resetData = () => dispatch => {
  dispatch({ type: C.RESET_DATA });
};

export const changeCheckedBill = (data) => dispatch => {
  dispatch({ type: C.CHANGE_SELECTED_BILL, payload: {id: data._id, billNo: data.billNo, checked: data.checked}});
  if(data && data.checked) {
    let p = data.dataRequest;
    p.billNo.push(data.billNo);
    if(p.shipMode == 'Sea') {
      p.cont = p.billNo;
    }
    // p._id = _.get(data, '_id', '');
    dispatch(getBillData(p));
  }
};

export const getBillData = (params) => dispatch => {
  accountingServices.getListShipOutByCustomer(params).then(
    res => {
      // to do something
      dispatch({ type: C.MAP_DATA_ON_BILL, payload: res.data })
    },
    error => {
      console.log(JSON.stringify(error));
    }
  );
};

export const changeUnitPrice = (data) => dispatch => {
  dispatch({ type: C.CHANGE_UNIT_PRICE , payload: data})
}

export const changeAirCost = (data) => dispatch => {
  dispatch({ type: C.CHANGE_AIR_COST , payload: data})
}

export const changeNoteBill = (data) => dispatch => {
  dispatch({ type: C.CHANGE_NOTE_BILL , payload: data})
}

export const changeReceiveType = (data) => dispatch => {
  dispatch({ type: C.CHANGE_RECEIVE_TYPE, payload: data})
}

export const changeReceiveNote = (data) => dispatch => {
  dispatch({ type: C.CHANGE_RECEIVE_NOTE, payload: data })
}

export const changeReceiveAmount = (data) => dispatch => {
    dispatch({type: C.CHANGE_RECEIVE_AMOUNT, payload: data})
}

export const changeReceiveFile = file => dispatch => {
  dispatch({ type: C.CHANGE_RECEIVE_FILE, payload: file})
}

//--------- main VAT ---------//
export const changeMainVATNo = value => dispatch => {
  dispatch({ type: C.CHANGE_MAIN_VAT_NO, payload: value });
};

export const changeMainVATTotalAmount = value => dispatch => {
  dispatch({ type: C.CHANGE_MAIN_VAT_TOTAL_AMOUNT, payload: value });
};

export const changeMainVATDate = value => dispatch => {
  dispatch({ type: C.CHANGE_MAIN_VAT_DATE, payload: value });
};

export const changeMainVATTaxRate = value => dispatch => {
  dispatch({ type: C.CHANGE_MAIN_VAT_TAX_RATE, payload: value });
};

export const changeMainVATNote = value => dispatch => {
  dispatch({ type: C.CHANGE_MAIN_VAT_NOTE, payload: value });
};
//--------- sub VAT ---------//
export const changeSubVATTotalAmount = value => dispatch => {
  dispatch({ type: C.CHANGE_SUB_VAT_TOTAL_AMOUNT, payload: value });
};
export const changeSubVATNo = value => dispatch => {
  dispatch({ type: C.CHANGE_SUB_VAT_NO, payload: value });
};
export const changeSubVATDate = value => dispatch => {
  dispatch({ type: C.CHANGE_SUB_VAT_DATE, payload: value });
};
export const changeSubVATTaxRate = value => dispatch => {
  dispatch({ type: C.CHANGE_SUB_VAT_TAX_RATE, payload: value });
};
export const changeSubVATNote = value => dispatch => {
  dispatch({ type: C.CHANGE_SUB_VAT_NOTE, payload: value });
};

export const saveInvoice = data => dispatch => {
  if(data && data.id) {
    accountingServices.updateInvoice(data.id, data).then(res => {
      if(res.success) {
          dispatch({type: C.SAVE_SUCCESS, payload: true});
      }
    })
  }
};
