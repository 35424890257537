import Base from './base';

class Role extends Base {
  constructor () {
    super();

    this.getListRoleDAL = this.getListRoleDAL.bind(this);
    this.editRoleDAL = this.editRoleDAL.bind(this);
    this.addRoleDAL = this.addRoleDAL.bind(this);
    this.deleteRoleDAL = this.deleteRoleDAL.bind(this);
    this.getListModuleDAL = this.getListModuleDAL.bind(this);
    this.getRoleDetailDAL = this.getRoleDetailDAL.bind(this);
  }

  getListRoleDAL (query) {
    return super.execute(this.Urls.role.list, this.ApiMethod.get, query);
  }

  editRoleDAL (id, body) {
    return super.execute(this.Urls.role.update + '/' + id, this.ApiMethod.put, body)
        ;
  }

  addRoleDAL (body) {
    return super.execute(this.Urls.role.create, this.ApiMethod.post, body)
        ;
  }

  deleteRoleDAL (id) {
    return super.execute(this.Urls.role.delete + '/' + id, this.ApiMethod.delete, null)
        ;
  }

  getListModuleDAL(){
    return super.execute(this.Urls.module.list, this.ApiMethod.get, null);
  }

  getRoleDetailDAL(roleId){
    return super.execute(this.Urls.role.detail + '/' + roleId + "?populate=modules.moduleId", this.ApiMethod.get, null);
  }

}

export default new Role();
