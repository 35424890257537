import * as types from '../constants/actionTypes';
import {showLoading, hideLoading} from './commonUseActions';
import organizationServices from '../services/organizationServices';
// import I18n from 'i18n-js';

function getListOrganization (options = {}) {
  return (dispatch) => {
    showLoading(types, dispatch);

    return organizationServices.getListOrganization(options).then((res) => {
      dispatch({
        type: types.ORGANIATION.GET_LIST_SUCCESS,
        data: res.data || []
      });
      hideLoading(types, dispatch);
      return res;
    }, (error) => {
      hideLoading(types, dispatch);
    });
  };
}


function editOrganization (id, data) {
  return (dispatch) => {
    showLoading(types, dispatch);

    return organizationServices.editOrganization(id, data).then((res) => {
      hideLoading(types, dispatch);
      if (res) {
        dispatch({
          type: types.ORGANIATION.RELOAD,
          data: true
        });
      }
      return {success: true}
    }, (error) => {
      hideLoading(types, dispatch);
      return error
    });
  };
}

function addOrganization (data) {
  return (dispatch) => {
    showLoading(types, dispatch);

    return organizationServices.addOrganization(data).then((res) => {
      hideLoading(types, dispatch);
      if (res) {
        dispatch({
          type: types.ORGANIATION.RELOAD,
          data: true
        });
      }
      return {success: true}
    }, (error) => {
      hideLoading(types, dispatch);
      return error
    });
  };
}


function deleteOrganization (id) {
  return (dispatch) => {
    showLoading(types, dispatch);

    organizationServices.deleteOrganization(id).then((res) => {
      hideLoading(types, dispatch);
      if (res) {
        dispatch({
          type: types.ORGANIATION.RELOAD,
          data: true
        });
      }
    }, (error) => {
      hideLoading(types, dispatch, error);
    });
  };
}

export default {
  getListOrganization,
  editOrganization,
  addOrganization,
  deleteOrganization
};
