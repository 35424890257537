import {AUTH} from '../constants/actionTypes';
import initial from './initialState';

export default function authState (state = initial.authState, action) {
  switch (action.type) {
    case AUTH.LOGIN_FAIL:
      return {...state, error: action.data};
    case AUTH.SET_USER:
      return {...state, auth: action.data, error: ''};
    case AUTH.EDIT_USER:{
      let newData = Object.assign({}, action.data);
      newData.role = state.auth.role;
      return {...state, auth: newData, error: ''};
    }
    case AUTH.GET_USER_FAIL:
      return {...state, error: action.data};
    case AUTH.SET_LANGUAGE:
      return {...state, language: action.data};
    case AUTH.FORGOT_PASSWORD:
      return {...state, forgot: true};
    case AUTH.SHOW_ALERT:
      return {...state, alert: action.data};
    case AUTH.HIDE_ALERT:
      return {...state, alert: null};
    case AUTH.FORGOT_PASSWORD_ERROR:
      return {...state, forgotError: action.data};
    case AUTH.RESET_PASSWORD_ERROR:
      return {...state, resetError: action.data};
    case AUTH.SHOW_LOADING:
      return {...state, loading: true};
    case AUTH.HIDE_LOADING:
      return {...state, loading: false};
    default:
      return state;
  }
}
