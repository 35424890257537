import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BaseComponent from '../base/BaseComponent';
import ScreenAssignment from './assignment/ScreenAssignment';
import ScreenProduction from './production/ScreenProduction';
import ScreenQA from './qa/ScreenQA';
import ScreenPacking from './packing/ScreenPacking';
import _ from 'lodash';
import { PRODUCTION_SCREEN } from '../../constants/constants';
import ViewProgressStyle from './production/progress/ViewProgressStyle';
import appActions from '../../actions/appActions';
import productionActions from '../../actions/productionActions';
import cutTicketActions from '../../actions/cutTicketActions';
import UpdateEditProgress from './production/progress/UpdateEditProgress';
import { getActiveScreen } from '../../functions/utils';
import UpdateActualYield from './production/actualYield/UpdateActualYield';
import ViewQAProgress from './qa/view/ViewQAProgress';
import UpdateQAProgress from './qa/view/UpdateQAProgress';
import AddPacking from './packing/AddPacking';
import AddPackingPrePack from './packing/AddPackingPrePack';
import { PRODUCTION_TAB } from '../../constants/constants';
import * as utils from '../../functions/utils';
class PageProduction extends BaseComponent {
  constructor(props, context) {
    super(props, context);

    this.state = {
      tabCurrent: '',
      productionData: {},
      qaData: {},
      updateData: [],
      selectedCutTicketYield: '',
    };
    this.handleChangeActual = this.handleChangeActual.bind(this);
    this.onUpdateActualYield = this.onUpdateActualYield.bind(this);
  }

  componentWillReceiveProps(newProps) {
    let { tabCurrent } = this.state;
    const pageTab = _.get(newProps.appState, 'page.tab', '');
    const nextTab = PRODUCTION_TAB[_.toUpper(pageTab)] || '';
    tabCurrent !== pageTab && this.setState({ tabCurrent: nextTab });
    if (pageTab !== '' && !PRODUCTION_TAB[_.toUpper(pageTab)]) {
      const pageData = _.assign(_.cloneDeep(this.props.appState.page), { tab: '' });
      this.props.appActions.setPageCurrent(pageData);
    }
  }

  getEditComponent(activeScreen) {
    let EditComponent = null;

    switch (activeScreen) {
      case PRODUCTION_SCREEN.VIEW_PROGRESS: {
        EditComponent = ViewProgressStyle;
        break;
      }
      case PRODUCTION_SCREEN.UPDATE_PROGRESS: {
        EditComponent = UpdateEditProgress;
        break;
      }
      case PRODUCTION_SCREEN.EDIT_PROGRESS: {
        EditComponent = UpdateEditProgress;
        break;
      }
      case PRODUCTION_SCREEN.EDIT_ACTUAL_YIELD: {
        EditComponent = UpdateActualYield;
        break;
      }
      case PRODUCTION_SCREEN.VIEW_QA_PROGRESS: {
        EditComponent = ViewQAProgress;
        break;
      }
      case PRODUCTION_SCREEN.EDIT_QA_PROGRESS:
      case PRODUCTION_SCREEN.UPDATE_QA_PROGRESS: {
        EditComponent = UpdateQAProgress;
        break;
      }
      case PRODUCTION_SCREEN.ADD_PACKING: {
        EditComponent = AddPacking;
        break;
      }
      case PRODUCTION_SCREEN.ADD_PACKING_PRE_PACK: {
        EditComponent = AddPackingPrePack;
        break;
      }
    }

    return EditComponent;
  }

  handleSelectCutTicket(id) {
    this.setState({ selectedCutTicketYield: id });
  }

  handleChangeActual(item, sizeName, value) {
    let newItem = _.cloneDeep(item);
    let info = _.get(newItem, 'info', {});
    _.set(newItem, sizeName, parseFloat(value));
    let updateData = _.cloneDeep(this.state.updateData);
    const idx = _.findIndex(updateData, x => x._id === info._id);
    if (idx >= 0) {
      updateData[idx] = { _id: info._id, actual: value };
    } else {
      updateData.push({ _id: info._id, actual: value });
    }

    this.setState({ updateData: updateData });
  }

  onUpdateActualYield() {
    this.state.selectedCutTicketYield &&
      this.props.productionActions
        .updateActualYield(this.state.selectedCutTicketYield, this.state.updateData)
        .then(res => {
          if (res && res.success) {
            let pageList = _.cloneDeep(this.props.appState.pageList);
            pageList.pop();
            this.props.appActions.addPage(pageList);
          }
        });
  }

  setProductionData(key, item) {
    let productionData = _.cloneDeep(this.state.productionData);
    productionData[key] = item;
    this.setState({ productionData });
  }

  setQAData(key, item) {
    let qaData = _.cloneDeep(this.state.qaData);
    qaData[key] = item;
    this.setState({ qaData });
  }

  render() {
    // const pageCurrent = this.props.appState.page || {};
    let { tabCurrent, productionData, qaData } = this.state;
    let { appState } = this.props;
    let roles = utils.getRoleByAuthState(this.props.authState, 'production');
    const { activeScreen, activeData } = getActiveScreen(appState);
    let EditComponent = this.getEditComponent(activeScreen);
    let show = activeScreen;
    return (
      <div>
        {show ? (
          <EditComponent
            {...this.props}
            data={activeData}
            activeScreen={activeScreen}
            onUpdateActualYield={this.onUpdateActualYield}
            roles={roles}
          />
        ) : (
          <div className={`${show ? 'hide' : ''}`} id="content">
            <ScreenAssignment {...this.props} tabCurrent={tabCurrent} roles={roles} />
            {tabCurrent == PRODUCTION_TAB.PRODUCTION && (
              <ScreenProduction
                {...this.props}
                tabCurrent={tabCurrent}
                productionData={productionData}
                onSelectCutTicked={id => this.handleSelectCutTicket(id)}
                setProductionData={(key, item) => this.setProductionData(key, item)}
                onChangeActual={this.handleChangeActual}
                roles={roles}
              />
            )}
            {/*<UIScreenQA tabCurrent={tabCurrent}/>*/}
            {tabCurrent == PRODUCTION_TAB.QA && (
              <ScreenQA
                {...this.props}
                tabCurrent={tabCurrent}
                setQAData={(key, item) => this.setQAData(key, item)}
                qaData={qaData}
                roles={roles}
              />
            )}
            {tabCurrent == PRODUCTION_TAB.PACKING && (
              <ScreenPacking {...this.props} tabCurrent={tabCurrent} roles={roles} />
            )}
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    appState: state.appState,
    authState: state.authState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    appActions: bindActionCreators(appActions, dispatch),
    productionActions: bindActionCreators(productionActions, dispatch),
    cutTicketActions: bindActionCreators(cutTicketActions, dispatch),
  };
}

PageProduction.propTypes = {
  authState: PropTypes.object.isRequired,
  appActions: PropTypes.object,
  appState: PropTypes.object,
  productionActions: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(PageProduction);
