import React from 'react';
import PropTypes from 'prop-types';
import I18n from "i18n-js";
import { PRODUCTION_SCREEN } from "../../../../constants/constants";
import _ from 'lodash';
import TableList from "../../../common/datatables/TableList";
import cutTicketActions from "../../../../actions/cutTicketActions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import appActions from "../../../../actions/appActions";
import {
  setDataUpdateQAProgress,
  setBodyUpdateCutTicketColorQAProgress,
  setDataEditQAProgress,
  setBodyEditCutTicketColorQAProgress
} from "../../../../functions/utils";
import { toastr } from 'react-redux-toastr'

class UpdateQAProgress extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      headers: [],
      tableData: []
    };
    this.onChange = this.onChange.bind(this);
  }

  componentWillMount() {
    const { data, activeScreen } = this.props;
    const { headers, tableData } = (activeScreen === PRODUCTION_SCREEN.UPDATE_QA_PROGRESS) ?
      setDataUpdateQAProgress(data, this.onChange) : setDataEditQAProgress(data, this.onChange);
    this.setState({ headers, tableData });
  }

  onChange(item, sizeName, value, index) {
    let newItem = _.cloneDeep(item);
    if (index != undefined) {
      _.set(newItem, sizeName + "." + index + ".updateValue", parseFloat(value));
    } else {
      _.set(newItem, sizeName + ".updateValue", value);
    }
    if (!this.validatePassFail(newItem)) {
      toastr.info("Note!", "Can't enter exceed number of produced");
      this.setDataChanged(item);
      return;
    }
    this.setDataChanged(newItem);
  }

  validatePassFail(item) {
    let res = true;
    const sizes = _.get(item, "sizes", {});
    _.forIn(sizes, si => {
      const updateValue = (si.value || []).reduce((s, i) => s + _.get(i, "updateValue", 0), 0);
      const defaultValue = (si.value || []).reduce((s, i) => s + _.get(i, "defaultValue", 0), 0);
      if (updateValue > defaultValue) {
        res = false;
      }
    });
    return res;
  }

  setDataChanged(item) {
    let data = _.cloneDeep(this.state.tableData);
    let newData = [];
    data.forEach(it => {
      let newIt = it;
      if (it.name === item.name && it._id.toString() === item._id.toString()) {
        newIt = item;
      }
      newData = [...newData, newIt];
    });
    this.setState({ tableData: newData });
  }

  save() {

    const { data, activeScreen } = this.props;
    const { tableData } = this.state;
    const cutTicketId = _.get(data, "_id", "");
    const updateTableData = _.cloneDeep(tableData);
    if (activeScreen === PRODUCTION_SCREEN.UPDATE_QA_PROGRESS && !this.validateInput(updateTableData, activeScreen)) {
      toastr.info('Note!', "Please enter Pass/Fail");
      return;
    }

    const body = activeScreen === PRODUCTION_SCREEN.UPDATE_QA_PROGRESS ?
      setBodyUpdateCutTicketColorQAProgress(updateTableData) : setBodyEditCutTicketColorQAProgress(updateTableData);
    this.props.cutTicketActions.updateCutTicketColorQAProgress(cutTicketId, body).then(res => {
      if (res && res.success) {
        let pageList = _.cloneDeep(this.props.appState.pageList);
        pageList.pop();
        this.props.appActions.addPage(pageList);
      }
    });

  }

  validateInput(tableData) {
    let isEnterInputs = false;
    for (let color of tableData) {
      _.forIn(_.get(color, "sizes", {}), (co, key) => {
        const value = _.get(co, "value", []);
        value.forEach(val => {
          if (val.updateValue) {
            isEnterInputs = true;
            return isEnterInputs;
          }
        })
      })
    }
    return isEnterInputs;
  }

  render() {
    const { headers, tableData } = this.state;
    const { data } = this.props;

    return (
      <div id="content">

        <ul className="content-header-title">
          <li>
            <p>{I18n.t("PageProduction.CutTicket")}
              <span className="color-orange medium mg-left-50 size-16">{_.get(data, "cutTicket", "")}</span>
            </p>
          </li>

          <li className="float-right add-filter-top" onClick={() => { this.save() }}>
            <span className="cursor-pointer global-add" data-toggle="modal" data-target="#modal-add-save">
              <i className="fa fa-floppy-o" aria-hidden="true"></i>{I18n.t("PageProduction.Save")}</span>
          </li>
        </ul>

        <div className="wrap-global-table" id="update-qa-progress">
          <TableList items={tableData || []} columns={headers || []}
            options={{ clsTbl: 'global-table box-shadow-none' }} />
        </div>
      </div>
    );
  }
}

UpdateQAProgress.propTypes = {
  appActions: PropTypes.object,
  cutTicketActions: PropTypes.object,
  data: PropTypes.object,
  styleId: PropTypes.string,
  activeScreen: PropTypes.string,
};

function mapStateToProps(state, ownProps) {
  return {
    appState: state.appState,
    authState: state.authState,
    cutTicketState: state.cutTicketState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    appActions: bindActionCreators(appActions, dispatch),
    cutTicketActions: bindActionCreators(cutTicketActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateQAProgress);

