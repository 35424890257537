import React, { Component } from 'react';
import PropTypes from 'prop-types';


const FormGroup = (props) => {
    const {
        label, required, inputComponent, type, error, className
    } = props;

    return (
        <ul className={`position-relative ${className || ''}`}>
            <li><span>{label} {required && <span className="color-red icon-request">*</span>}</span></li>
            <li className={`${type}`}>
                {inputComponent}
                {error && <div className="text-danger">{error}</div>}
            </li>
        </ul>
    );
};

FormGroup.propTypes = {
    label: PropTypes.string,
    required: PropTypes.bool,
    inputComponent: PropTypes.objectOf(PropTypes.instanceOf(Component)),
    type: PropTypes.string,
};

FormGroup.defaultProps = {
    label: null,
    required: false,
    inputComponent: null,
    type: 'input',
};

export default FormGroup;
