import React, { Component } from 'react';
import PreviewUSDeliveryView from '../../../components/accounting/payable/PreviewUSDelivery';
import I18n from 'i18n-js';
import { connect } from 'react-redux';
import * as selector from '../../../../selectors/accounting/payable/createInvoiceImpExpUSDelivery';
import * as PATH from '../../../../constants/routeConstants'; 
import { withRouter } from 'react-router';
class PreviewUSDelivery extends Component {

    constructor (props) {
        super(props)
        this.state = {
            listIds: this.props.listIds,
            items: [],
            pageNumber: 1,
            pageSize: 10,
            columns: [
                { name: I18n.t('PageAccounting.BillNo'), value: 'billNo', type: 'text'},
                { name: I18n.t('PageAccounting.ETD'), value: 'etd', type: 'text'},
                { name: I18n.t('PageAccounting.ETA'), value: 'eta', type: 'text'},
                { name: I18n.t('PageAccounting.DueDate'), value: 'dueDate', type: 'text'},
                { name: I18n.t('PageAccounting.TotalAmount'), value: 'amount', type: 'number', options: { clsTd: 'color-red' }},
            ],
            actions: []
        }
    }
    
    componentWillReceiveProps(newProps) {
        const {activeScreen} = newProps;
        if(!activeScreen) {
            newProps.router.history.push(PATH.ACCOUNTING_PAYABLE_PLAN_IMPORTEXPORT_US_DELIVERY_URL);
        }
    }

    render() {
        const { columns } = this.state
        return (
            <PreviewUSDeliveryView columns = {columns} {...this.props}/>
        );
    }
}

const mapStateToProps = state => ({
    items: selector.genDataForPreview(state),
    name: selector.getSelectedImpExpCompany(state),
    totalAmount: selector.getTotalAmount(state),
    // totalAdvance: selector.getTotalAdvance(state),
    // totalRemaining: selector.getTotalRemaining(state),
    activeScreen: selector.getActiveScreenStatus(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({

})

export default connect(mapStateToProps, mapDispatchToProps) (withRouter(PreviewUSDelivery));