import React, { Component } from 'react';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom'
import * as PATH from '../../../constants/routeConstants';
import { connect } from 'react-redux';
import _ from 'lodash';
import { toastr } from 'react-redux-toastr'
import I18n from 'i18n-js';
import PreviewPurchasePlanView from '../../components/purchase/PreviewPurchasePlan';
import PurchasePlanPlaceOrder from './PurchasePlanPlaceOrder';
import * as selector from '../../../selectors/purchase/purchasePlans';
import * as actions from '../../../actions/purchase/purchasePlans';

class PreviewPurchasePlan extends Component {

    constructor(props) {
        super(props);

    }

    componentWillReceiveProps(nextProps) {
        const {createSuccessStatus, items} = nextProps;
        // if(createSuccessStatus) {
        //     toastr.success(I18n.t('Notify.Success'), 'Create Purchase Order Successfully');
        //     nextProps.resetData();
        //     nextProps.history.push(PATH.PURCHASE_PLANS_URL);
        // }
        if(_.isEmpty(items)) {
            nextProps.history.push(PATH.PURCHASE_PLANS_URL);
        }
    }

    placeOrder = (materials) => {
        this.props.selectedListMaterials(materials)
        this.props.history.push(PATH.PURCHASE_PLANS_PREVIEW_PLACE_ORDER_URL)
    }

    useStock = (materials) => {
        const {genRequestDataForUseStock} = this.props;
        const data = genRequestDataForUseStock(materials);
        this.props.createUseStock(data).then((resp) => {
            if (resp.success) {
                this.props.resetData();
                this.props.history.push(PATH.PURCHASE_PLANS_URL);
                toastr.success(I18n.t('Notify.Success'), 'Create Purchase Order Successfully');
            }
        });
    }

    render() {
        // console.log(this.props.items)
        return (
            <PreviewPurchasePlanView
                placeOrder={(materials) => this.placeOrder(materials)}
                useStock = {(materials) => this.useStock(materials)}
                {...this.props}
            />
        );
    }
}

const mapStateToProps = state => ({
    orderDetail: selector.getSelectedOrder(state),
    selectedStyle: selector.getSelectedStyle(state),
    items: selector.getListItemsForPreview(state),
    listGTotal: selector.getListGTotal(state),
    listCanUseStock: selector.getListCanUseStock(state),
    genRequestDataForUseStock: selector.fnGenRequestDataForUseStock(state),
    createSuccessStatus: selector.getCreateSuccessStatus(state),
})

const mapDispatchToProps = dispatch => ({
    createUseStock(data) {
        return actions.createUseStock(data);
        // dispatch(actions.createUseStock(data));
    },
    resetData() {
        dispatch(actions.resetData())
    },
    selectedListMaterials(materials) {
        dispatch(actions.selectedListMaterials(materials))
    }
})


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PreviewPurchasePlan));