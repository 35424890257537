import React, { Component } from 'react';
import { connect } from "react-redux";
import { toastr } from 'react-redux-toastr';
import PropTypes from 'prop-types';
import I18n from 'i18n-js'
import { Switch, Route } from "react-router-dom";
import { withRouter } from "react-router";
import * as PATH from '../../../../constants/routeConstants'
import * as actions from '../../../../actions/accounting/payable/createInvoiceIEUSDelivery';
import * as selector from '../../../../selectors/accounting/payable/createInvoiceImpExpUSDelivery';
import CreatePlanIEUSDelivery from '../../../components/accounting/payable/CreatePlanIEUSDelivery';
import EditModalView from '../../../components/accounting/IEUSDeliveryItemEditDetail';
import PreviewUSDelivery from './PreviewUSDelivery';
import CreatePlanSupplierDomesticView from "../../../components/accounting/payable/CreatePlanSupplierDomestic";
class AccountsPayablePlanImportExportUSDelivery extends Component {

    constructor(props) {
        super(props);
        this.state = {
            openModal: false
        };
    }

    componentWillMount() {
        // console.log(this.props)
        this.getListCompany()
    }

    componentWillReceiveProps(nextProps) {
        const { success } = nextProps;
        if (success) {
            nextProps.resetData()
            nextProps.router.history.push(PATH.ACCOUNTING_PAYABLE_INVOICE_URL);
            toastr.success(I18n.t('Notify.Success'), 'Create INV successfully')
        }
    }

    componentWillUnmount() {
        this.props.resetData()
    }

    getListCompany = () => {
        this.props.getListCompany()
    }

    openChangeModal = (billId, containerIndex) => {
        const data = this.props.getDetailContainerOfBill(billId, containerIndex)
        this.props.copyDataToTemp(data)
        this.setState({ openModal: true });
        // let { openModal, closeModal } = this.props.modal;
        // let modal = (<ModalEditContainerDetail closeModal={closeModal}  />);
        // openModal(modal);
    }

    closeModal = () => {
        this.setState({ openModal: false })
    }

    changeSelectedDeliveryBills = (id, checked) => {
        const { checkablePurchaseOrders } = this.props;
        const item = checkablePurchaseOrders.find(item => item._id == id);
        if(item) this.props.changeCheckedDeliveryBill(item.raw, checked)
    }

    save = (data) => {
        const { invoiceNo } = this.props;
        if (_.isEmpty(invoiceNo)) {
            toastr.error(I18n.t('Notify.Error'), 'Missing Invoice No')
        } else {
            this.props.createInvoice(data)
        }
    }

    render() {
        const { openModal } = this.state;
        const { tempData } = this.props;
        return (
            <Switch>
                <Route path={PATH.ACCOUNTING_PAYABLE_PLAN_IMPORTEXPORT_US_DELIVERY_PREVIEW_URL}
                    render={() => <PreviewUSDelivery />}
                />
                <Route path={PATH.ACCOUNTING_PAYABLE_PLAN_IMPORTEXPORT_US_DELIVERY_URL}
                    render={() =>
                        <span>
                            <CreatePlanIEUSDelivery
                                {...this.props}
                                openViewContainerDetail={this.openChangeModal}
                                save={data => this.save(data)}
                                changeSelectedDeliveryBills={(id, checked) => this.changeSelectedDeliveryBills(id, checked)}
                            />
                            <EditModalView openModal={openModal}
                                ok={data => this.props.submit(data)}
                                close={this.closeModal}
                                data={tempData}
                                resetDateRaw={() => this.props.resetTempData()}
                                changeContainerDetailValue={(key, value) => this.props.changeContainerDetailValue(key, value)}
                                changeContainerDetailTextValue={(key, value) => this.props.changeContainerDetailTextValue(key, value)}
                            />
                        </span>
                    }
                />
            </Switch>
        )
    }
}

AccountsPayablePlanImportExportUSDelivery.propTypes = {

};

AccountsPayablePlanImportExportUSDelivery.defaultProps = {

};

const mapStateToProps = state => ({
    listCompany: selector.getSelectableImpExpCompanies(state),
    selectedCompany: selector.getSelectedImpExpCompany(state),
    invoiceNo: selector.getInvoiceNo(state),
    invoiceDate: selector.getInvoiceDate(state),
    note: selector.getNote(state),
    listBill: selector.getSelectableUSDeliveryBills(state),
    listBillDetail: selector.getDetailedUSDeliveryBills(state),
    getDetailContainerOfBill: selector.fnGetDetailContainerOfBill(state),
    tempData: selector.getTempData(state),
    amount: selector.getAmount(state),
    totalAmount: selector.getTotalAmount(state),
    say: selector.getTotalAmountSay(state),
    dataApi: selector.fnGenerateRequestBody(state),
    canPreview: selector.getCanPreview(state),
    success: selector.getSuccess(state),
    dueDate: selector.getDueDate(state),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    getListCompany() {
        dispatch(actions.getListCompany())
    },
    changeFilterDeliveryBillText(value) {
        dispatch(actions.changeSearchText(value));
    },
    changeSelectedCompany(value) {
        dispatch(actions.changeSelectedCompany(value.raw.company))
    },
    changeCheckedDeliveryBill(data, checked) {
        // console.log(data, checked)
        dispatch(actions.changeCheckedDeliveryBill(data, checked))
    },
    changeInvoiceNo(value) {
        dispatch(actions.changeInvoiceNo(value))
    },
    changeInvoiceDate(value) {
        dispatch(actions.changeInvoiceDate(value))
    },
    changeNote(value) {
        dispatch(actions.changeNote(value))
    },
    changeBillDetailValue(id, key, value) {
        dispatch(actions.changeBillDetailValue(id, key, Number(value)))
    },
    toggleActivePurchaseOrder(id, value) {
        dispatch(actions.toggleActivePurchaseOrder(id, value))
    },
    copyDataToTemp(data) {
        dispatch(actions.copyDataToTemp(data))
    },
    changeContainerDetailValue(key, value) {
        dispatch(actions.changeContainerDetailValue(key, Number(value)))
    },
    changeContainerDetailTextValue(key, value) {
        dispatch(actions.changeContainerDetailValue(key, value))
    },
    submit(data) {
        dispatch(actions.submit(data))
    },
    resetTempData() {
        dispatch(actions.resetTempData())
    },
    createInvoice(data) {
        // console.log(data)
        dispatch(actions.createInvoice(data))
    },
    onPreviewScreen() {
        ownProps.router.history.push(PATH.ACCOUNTING_PAYABLE_PLAN_IMPORTEXPORT_US_DELIVERY_PREVIEW_URL)
    },
    resetData() {
        dispatch(actions.resetData())
    },
    changeDueDate(date) {
        dispatch(actions.changeDueDate(date))
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AccountsPayablePlanImportExportUSDelivery));
