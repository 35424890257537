
import { combineReducers } from 'redux';
import _ from 'lodash';
import * as R from 'ramda';
import uuid from 'uuid/v4';
import C from '../../../constants/customer-po/current/editCutTicketColors';


const rawData = (state = null, action) => {
  const { type, payload } = action;
  switch (type) {
    case C.SHOW_MODAL:
      return payload;
    default:
      return state;
  }
}

const colors = (state = [], action) => {
  const { type, payload } = action;
  switch (type) {

    case C.SHOW_MODAL: {
      let { colors } = payload;
      colors = colors && colors.length > 0 ? colors : [{name: '', sizes: []}];

      return _.map(colors, item => ({
        id: uuid(),
        name: R.path(['name'], item),
        sizes: _.map(R.path(['sizes'], item), it => ({
          name: R.path(['name'], it),
          quantity: R.path(['quantity'], it),
        })),
      }))
    }

    case C.CHANGE_COLOR_NAME: {
      const { id, value } = payload;
      const stateClone = _.clone(state);
      const item = _.find(stateClone, it => it.id === id);
      item.name = value;
      return stateClone;
    }

    case C.ADD_COLOR: {
      return [...state, { id: uuid(), name: '', sizes: [] }];
    }

    case C.REMOVE_COLOR: {
      return _.filter(state, it => it.id !== payload);
    }

    case C.CHANGE_SIZE_QUANTITY: {
      const { colorId, sizeName, value } = payload;
      const stateClone = _.clone(state);
      const color = _.find(stateClone, it => it.id === colorId);
      if (!color) return state;

      const { sizes } = color;
      const size = _.find(sizes, it => it.name === sizeName);
      if (!size) return state;

      size.quantity = parseInt(value);
      return stateClone;
    }

    case C.ADD_SIZE: {
      const { colorId, value } = payload;
      const stateClone = _.clone(state);
      const color = _.find(stateClone, it => it.id === colorId);
      color.sizes = value.name !== 'Default' ? [..._.filter(color.sizes, it => it.name !== 'Default'), value] : [value];
      return stateClone;
    }

    case C.REMOVE_SIZE: {
      const { colorId, sizeName } = payload;
      const stateClone = _.clone(state);
      const color = _.find(stateClone, it => it.id === colorId);
      color.sizes = _.filter(color.sizes, it => it.name !== sizeName);
      return stateClone
    }

    default:
      return state;
  }
}

const suggestions = (state = [], action) => {
  const { type, payload } = action;
  switch (type) {
    case C.CHANGE_SUGGESTIONS:
      return payload;
    default:
      return state;
  }
}

const selectableSizes = (state = [], action) => {
  const { type, payload } = action;
  switch (type) {
    case C.CHANGE_SELECTABLE_SIZES:
      return payload;
    default:
      return state;
  }
}

const submiting = (state = false, action) => {
  const { type } = action;
  switch (type) {
    case C.SUBMIT:
      return true;
    case C.CANCEL_SUBMIT:
      return false;
    default:
      return state;
  }
}

const combined = combineReducers({
  rawData,
  colors,
  suggestions,
  selectableSizes,
  submiting,
});


export default (state, action) => {
  const { type } = action;
  if (type === C.RESET_DATA) {
    return {};
  }
  return combined(state, action);
};
