import {DAMAGED_GARMENT} from '../constants/actionTypes';
import initial from './initialState';

export default function (state = initial.damagedGarmentState, action) {
  switch (action.type) {
    case DAMAGED_GARMENT.GET_LIST_SUCCESS:
      return {...state, items: action.data.items || [], count: action.data.count || 0};
    case DAMAGED_GARMENT.RELOAD:
      return {...state, reload: !state.reload};
    case DAMAGED_GARMENT.GET_DETAIL_SUCCESS:
      return {...state, detail: action.data};
    default:
      return state;
  }
}
