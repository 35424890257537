import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import I18n from 'i18n-js';
import roleActions from '../../../actions/roleActions';
import CheckBox from '../../common/fields/CheckBox';
import BaseComponent from '../../base/BaseComponent';
import _ from 'lodash';
import {MENU_ITEM, PERMISSION_ROLE} from "../../../constants/constants";
import * as types from "../../../constants/actionTypes";

class RoleFormScreen extends BaseComponent {
  constructor(props, context) {
    super(props, context);

    this.state = {
      roleDetail: {},
      errors: {},
      modules: [],
      roleName: ""
    };

    this.onSave = this.onSave.bind(this);
    this.onChangeCheckBox = this.onChangeCheckBox.bind(this);
    this.onChangeRoleName = this.onChangeRoleName.bind(this);
  }

  componentWillMount() {
    if (this.props.roleActions) {
      if (this.props.isEditRole && this.props.roleId) {
        this.props.roleActions.getRoleDetail(this.props.roleId);
      }
      this.props.roleActions.getListModule({});
    }
  }

  componentWillReceiveProps(newProps) {
    let { roleState } = newProps;
    if (roleState && (roleState.detail !== this.props.roleState.detail || roleState.modules !== this.props.roleState.modules)) {
      let modules = [];
      let roleName = "";
      if (this.props.isEditRole && roleState.detail && newProps.roleId) {
        modules = this.createModulesByDetail(roleState.detail.modules, roleState.modules);
        roleName = roleState.detail.name;
      } else if (roleState.modules) {
        modules = this.createModules(roleState.modules);
      }

      this.setState({ roleName: roleName, modules: modules });
    }
  }

  createModulesByDetail(detailModules, modules) {
    let newModules = [];

    if (modules && modules.items) {
      modules.items.forEach(moddule => {
        let item = {
          moduleId: moddule._id,
          name: moddule.name || "",
          key: moddule.key || "",
          actions: {
            view: false,
            create: false,
            revise: false,
            delete: false
          }
        };


        detailModules.forEach(mod => {
          const moduleId = mod.moduleId || {};
          if(moduleId.key === item.key) {
            item = {
              moduleId: moduleId._id,
              name: moduleId.name || "",
              key: moduleId.key || "",
              actions: mod.actions || {}
            };
          }
        });

        newModules.push(item);
      });
    }
    return newModules;
  }

  createModules(modules) {
    let newModules = [];
    if (modules && modules.items) {
      modules.items.forEach(mod => {
        newModules.push({
          moduleId: mod._id,
          name: mod.name || "",
          key: mod.key || "",
          actions: {
            view: false,
            create: false,
            revise: false,
            delete: false
          }
        });
      });
    }
    return newModules;
  }

  onChangeRoleName(event) {
    if(event && event.target) {
      this.setState({errors: {}, roleName: event.target.value || ""});
    }
  }

  onChangeCheckBox(event) {
    if (event && event.target) {
      const { id, checked } = event.target;
      let moduleId = id ? id.split("_")[1] : "";
      let roleId = id ? id.split("_")[0] : "";
      let modules = _.cloneDeep(this.state.modules);
      let newModules = [];
      modules.forEach(mod => {
        let newMod = Object.assign({}, mod);
        if (newMod.moduleId === moduleId && newMod.actions) {
          newMod.actions[roleId] = checked;
          if(newMod.actions.create || newMod.actions.revise || newMod.actions.delete) {
            newMod.actions.view = true; // view is enable when other role enable
          }
        }
        newModules.push(newMod);
      });
      this.setState({ modules: newModules });
    }
  }

  onSave() {
    if(!this.state.roleName) {
      this.setState({errors : {name: I18n.t('PageOrganization.PleaseEnterRole')}});
      return;
    }
    const payloadData = {
      name: this.state.roleName,
      modules: _.cloneDeep(this.state.modules),
      enabled: true
    };
    if (this.props.roleId) {
      this.props.roleActions.editRole(this.props.roleId, payloadData);
    } else {
      this.props.roleActions.addRole(payloadData);
    }
    this.props.dispatch({
      type: types.APP.SET_PAGE_CURRENT,
      data: _.assign(_.cloneDeep(this.props.appState.page), { isEdit: false })
    });
  }

  render() {
    const tooltipSpecials = {
      customerPO: "Complete Customer PO",
      yield: "Can unblock yield",
      sampleApproval: "Complete Sample approval",
      purchase: "Approval Order",
      warehouse: "Approval outbound and OK to paid",
      production: "Start Production",
      shipping: "Complete Ship in"
    }
    
    const enableSpecials = {
      dashboard: false,
      customerPO: true,
      yield: true,
      sampleApproval: true,
      purchase: true,
      warehouse: true,
      production: true,
      shipping: true,
      accounting: false,
      reportAnalytic: false,
      standard: false,
      organization: false,
      crm: false,
      systemSettings: false
    }
    const {errors = {}, modules = []} = this.state;
    return (
      <div className="scroll-height-main">
        <div className="md content-header-edit-yield">
          <div className="wrap-filter-search w-full">
            <div className="wrap-stock-in">
              <span className="cursor-pointer" onClick={this.onSave}>
                <i className="fa fa-floppy-o" aria-hidden="true"></i>
                SAVE
              </span>
            </div>
            <div className="wrap-search float-left">
              <p>{I18n.t('PageOrganization.Role')}</p>
              <input type={'text'} className="form-control text-indent-0"
                placeholder={I18n.t('PageOrganization.Role')}
                value={this.state.roleName}
                onChange={this.onChangeRoleName} />
              {errors['name'] && <div className="alert-warning" style={{left: 39}}>{errors['name']}</div>}
            </div>
          </div>
          <div id="view-add-perrmission">
            <div className="global-table-tab">
              <table className="global-table" style={{ width: '100%' }}>
                <tbody>
                <tr>
                    <th>{I18n.t('PageOrganization.Module')}</th>
                    <th className="center">{I18n.t('PageOrganization.View')}</th>
                    <th className="center">{I18n.t('PageOrganization.Create')}</th>
                    <th className="center">{I18n.t('PageOrganization.Revise')}</th>
                    <th className="center">{I18n.t('PageOrganization.Delete')}</th>
                    <th className="center">{I18n.t('PageOrganization.Special')}</th>
                  </tr>
                  {modules.map((module) => {
                    const actions = module.actions || {};
                    return (
                      <tr key={module.moduleId}>
                        <td>{module.name}</td>
                        <td className="center">
                          <CheckBox
                            id={PERMISSION_ROLE.VIEW + module.moduleId}
                            checked={actions.view || false}
                            onChange={this.onChangeCheckBox}
                          />
                        </td>
                        <td className="center">
                          <CheckBox
                            id={PERMISSION_ROLE.CREATE + module.moduleId}
                            checked={actions.create || false}
                            onChange={this.onChangeCheckBox}
                          />
                        </td>
                        <td className="center">
                          <CheckBox
                            id={PERMISSION_ROLE.REVISE + module.moduleId}
                            checked={actions.revise || false}
                            onChange={this.onChangeCheckBox}
                          />
                        </td>
                        <td className="center">
                          <CheckBox
                            disabled={module.key === MENU_ITEM.sampleApproval.key}
                            id={PERMISSION_ROLE.DELETE + module.moduleId}
                            checked={module.key === MENU_ITEM.sampleApproval.key ? false : actions.delete}
                            onChange={this.onChangeCheckBox}
                          />
                        </td>
                        <td className="center">
                          <CheckBox
                            disabled={!enableSpecials[`${module.key}`]}
                            id={PERMISSION_ROLE.SPECIAL + module.moduleId}
                            checked={actions.special || false}
                            onChange={this.onChangeCheckBox}
                            label={enableSpecials[`${module.key}`] ? "?" : ""}
                            tooltip={tooltipSpecials[`${module.key}`]}
                          />
                        </td>
                      </tr>
                    )
                  }
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    appState: state.appState,
    roleState: state.roleState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    roleActions: bindActionCreators(roleActions, dispatch),
    dispatch,
  };
}

RoleFormScreen.propTypes = {
  roleActions: PropTypes.object,
  isEditRole: PropTypes.bool,
  roleId: PropTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps)(RoleFormScreen);
