import React, {Component} from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import _ from 'lodash';
import BaseComponent from '../base/BaseComponent';
import PropTypes from 'prop-types';
import ScreenPurchases from './purchase/ScreenPurchases';
import ScreenFollowUp from './followUp/ScreenFollowUp';
import ScreenPurchaseHistory from './history/ScreenPurchaseHistory';
import PurchaseHistoryView from './history/PurchaseHistoryView';
import PlaceOrder from './purchase/PlaceOrder';
import StockOrder from './purchase/StockOrder';
import ScreenPurchaseEdit from './purchase/ScreenPurchaseEdit';
import PlansPreview from './plans/PlansPreview';
import ScreenPlans from './plans/ScreenPlans';

import PurchaseOrderDetail from '../../ui/containers/purchase/PurchaseOrderDetail';
import PreviewPurchaseOrderForPurchasePlans from '../../ui/containers/purchase/PreviewPurchaseOrderForPurchasePlans';
import PreviewPurchaseOrderForPurchaseList from '../../ui/containers/purchase/PreviewPurchaseOrderForPurchaseList';
import PurchasePlans from '../../ui/containers/purchase/PurchasePlans';
import { connect } from 'react-redux';
import {PURCHASE_SCREEN, PURCHASE_TAB} from "../../constants/constants";
import * as PATH from '../../constants/routeConstants';
import * as utils from "../../functions/utils";
import systemSettingServices from "../../services/systemSettingServices";

class PagePurchase extends BaseComponent {
  constructor (props, context) {
    super(props, context);
    this.state = {
      activeTab: PURCHASE_TAB.PURCHASE_PLAN,
      activeScreen: '',
      order: {},
      purchaseOrder: {},
      orderSelectedPlan: {},
      purchaseDeviation: 0,
      plansData:{
        selectedOrder: null,
        selectedStyle: null
      }
    };
  }

  componentWillMount() {
    const options = {projection: 'purchaseDeviation'};
    systemSettingServices.getListSystemSetting(options).then(res => {
      const purchaseDeviation = _.get(res, "data.purchaseDeviation", 0);
      purchaseDeviation && this.setState({purchaseDeviation});
    });
  }

  placeOrder = (order) =>  {
    // console.log('====Place order', order);
    this.setState({order});
  }

  stockOrder = (order) =>  {
    this.setState({order});
  }

  additionalOrder = (order) =>  {
    this.setState({ order});
  }

  previewOrder = (order) => {
    // console.log('====Preview order', order);
    this.setState({order});
  }

  editOrder = (order) => {
    this.setState({order});
  }

  viewHistory = (order) => {
    this.setState({order});
  }

  setPlansData({selectedOrder, selectedStyle, selectedStylePurchasePlan}) {
    let plansData = _.cloneDeep(this.state.plansData);
    _.set(plansData, "selectedOrder", selectedOrder);
    _.set(plansData, "selectedStyle", selectedStyle);
    _.set(plansData, "selectedStylePurchasePlan", selectedStylePurchasePlan);
    this.setState({plansData});
  }

  renderPurchasePlans(activeScreen, activeData) {
    const {plansData} = this.state;
    const orderData = !_.isEmpty(activeData) ? activeData : this.state.order;

    if (activeScreen === PURCHASE_SCREEN.PREVIEW_ORDER) {
      return (<PlansPreview modal={this.props.modal} order={orderData} authState={this.props.authState} placeOrder={this.placeOrder}/>);
    }
    if (activeScreen === PURCHASE_SCREEN.PLACE_ORDER) {
      return (<PlaceOrder isEditQantity={false} purchaseDeviation={this.state.purchaseDeviation} modal={this.props.modal} order={this.state.order} previewOrder={this.previewOrder} authState={this.props.authState} {...this.props}/>);
    }
    if (activeScreen === PURCHASE_SCREEN.PREVIEW_PURCHASE_ORDER) {
      return (<PreviewPurchaseOrderForPurchasePlans />);
    }

    return (
      <ScreenPlans
        selectedOrder={plansData.selectedOrder}
        selectedStyle={plansData.selectedStyle}
        selectedStylePurchasePlan={plansData.selectedStylePurchasePlan}
        authState={this.props.authState}
        modal={this.props.modal}
        previewOrder={this.previewOrder}
        setPlansData={(data)=>this.setPlansData(data)}
      />
    );
  }

  renderPurchaseList(activeScreen, activeData, isEdit, roles) {
    const orderData = !_.isEmpty(activeData) ? activeData : this.state.order;

    switch (activeScreen) {
      case PURCHASE_SCREEN.PLACE_ORDER:
        return (<PlaceOrder purchaseDeviation={this.state.purchaseDeviation} modal={this.props.modal} isEdit={isEdit} order={orderData} previewOrder={this.previewOrder} authState={this.props.authState} {...this.props}/>);
      case PURCHASE_SCREEN.STOCK_ORDER:
        return (<StockOrder purchaseDeviation={this.state.purchaseDeviation} modal={this.props.modal} isEdit={isEdit} order={orderData} />);
      case PURCHASE_SCREEN.PREVIEW_ORDER:
        return (<PlansPreview modal={this.props.modal} order={orderData} authState={this.props.authState} placeOrder={this.placeOrder}/>);
      case PURCHASE_SCREEN.EDIT_ORDER:
        return (<ScreenPurchaseEdit modal={this.props.modal} order={this.state.order} previewOrder={this.previewOrder} authState={this.props.authState}/>);
      case PURCHASE_SCREEN.ADDITIONAL_ORDER:
        return (<ScreenPlans authState={this.props.authState} modal={this.props.modal} additionalOrder={orderData} previewOrder={this.previewOrder}/>);
      case PURCHASE_SCREEN.PREVIEW_PURCHASE_ORDER:
        return (<PreviewPurchaseOrderForPurchaseList />);  
      default:
        return (<ScreenPurchases authState={this.props.authState} modal={this.props.modal} stockOrder={this.stockOrder} additionalOrder={this.additionalOrder} editOrder={this.editOrder} isEdit={isEdit} roles={roles}/>);
    }
  }
  
  renderCancelPurchaseList(activeScreen, activeData, isEdit, roles) {
    const orderData = !_.isEmpty(activeData) ? activeData : this.state.order;
    switch (activeScreen) {
      case PURCHASE_SCREEN.PLACE_ORDER:
        return (<PlaceOrder purchaseDeviation={this.state.purchaseDeviation} isCancel modal={this.props.modal} isEdit={isEdit} order={orderData} previewOrder={this.previewOrder} authState={this.props.authState} {...this.props}/>);
      default:
        return (<ScreenPurchases isCancel={true} authState={this.props.authState} modal={this.props.modal}  editOrder={this.editOrder} roles={roles}/>);
    }
  }
  renderFollowUp(activeScreen, activeData, isEdit, roles) {
    const orderData = !_.isEmpty(activeData) ? activeData : this.state.order;
    switch (activeScreen) {
      case PURCHASE_SCREEN.PLACE_ORDER:
        return (<PlaceOrder modal={this.props.modal} isEdit={isEdit} order={orderData} previewOrder={this.previewOrder} authState={this.props.authState} {...this.props}/>);
      case PURCHASE_SCREEN.ADDITIONAL_ORDER:
        return (<ScreenPlans authState={this.props.authState} modal={this.props.modal} additionalOrder={orderData} previewOrder={this.previewOrder}/>);
      // case PURCHASE_SCREEN.FOLLOW_UP_PREVIEW_PURCHASE_ORDER:
      case PURCHASE_SCREEN.PREVIEW_PURCHASE_ORDER:
        return (<PreviewPurchaseOrderForPurchaseList />);  
      default:
        return (<ScreenFollowUp authState={this.props.authState} modal={this.props.modal} editOrder={this.editOrder} isEdit={isEdit} roles={roles}/>);
    }
  }

  renderHistory(activeScreen) {
    const orderData = this.state.order;
    switch (activeScreen) {
      case PURCHASE_SCREEN.VIEW_HISTORY:
        return (<PurchaseHistoryView modal={this.props.modal} order={orderData} authState={this.props.authState}/>);
      default:
        return (<ScreenPurchaseHistory authState={this.props.authState} modal={this.props.modal} viewHistory={this.viewHistory} />);
    }
  }

  render = ()  => {
    const {appState} = this.props;
    const {activeTab, activeScreen, activeData, isEdit} = utils.getActiveScreen(appState);
    const roles = utils.getRoleByAuthState(this.props.authState, 'purchase');
    return (
    <Switch>
      <Route exact path={PATH.PURCHASE_URL} render={() => <Redirect to={PATH.PURCHASE_PLANS_URL} />} />
      <Route path={PATH.PURCHASE_PLANS_URL} component = {PurchasePlans} />
      <Route path={PATH.PURCHASE_FOLLOW_UP_URL} render={() => this.renderFollowUp(activeScreen, activeData, isEdit, roles)} />
      <Route path={PATH.PURCHASE_LIST_URL} render={() => this.renderPurchaseList(activeScreen, activeData, isEdit, roles)} />
      <Route path={PATH.PURCHASE_CANCEL_LIST_URL} render={() => this.renderCancelPurchaseList(activeScreen, activeData, isEdit, roles)} />
      <Route path={PATH.PURCHASE_HISTORY_LIST_URL} render={() => this.renderHistory(activeScreen)} />
      <Route path={`${PATH.PURCHASE_SHOW_URL}/:id`} component={PurchaseOrderDetail} />
    </Switch>
    )
  }
}

const mapStateToProps = (state, ownProps)  => {
  return {
    authState: state.authState,
    appState: state.appState
  };
};

PagePurchase.propTypes = {
  authState: PropTypes.object.isRequired,
  appState: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(PagePurchase);
