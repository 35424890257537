import React, { Component } from 'react';
import { connect } from "react-redux";
import { toastr } from 'react-redux-toastr';
import I18n from 'i18n-js'
import { withRouter } from "react-router";
import { Switch, Route } from "react-router-dom";
import * as PATH from '../../../../constants/routeConstants';
import PropTypes from 'prop-types';
import EditModalView from '../../../components/accounting/IEContainerItemEditModal';
import ModalEditContainerDetail from '../../../../components/common/modals/ModalEditContainerDetail';
import CreatePlanIEDeclaration from '../../../components/accounting/payable/CreatePlanIEDeclaration';
import * as actions from '../../../../actions/accounting/payable/createInvoiceIEDeclaration';
import * as selector from '../../../../selectors/accounting/payable/createInvoiceImpExpDeclarations';
import PreviewDeclaration from './PreviewDeclaration';
class AccountsPayablePlanImportExportContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            openModal: false
        };
    }

    componentWillMount() {
        this.getListDeclaration()
    }

    componentWillReceiveProps(nextProps) {
        const { success } = nextProps;
        if (success) {
            nextProps.resetData()
            nextProps.router.history.push(PATH.ACCOUNTING_PAYABLE_INVOICE_URL);
            toastr.success(I18n.t('Notify.Success'), 'Create INV successfully')
        }
    }

    componentWillUnmount() {
        this.props.resetData()
    }

    getListDeclaration = () => {
        this.props.getListDeclaration()
    }

    resetDateRaw = () => {
        // this.
    }

    save = (data) => {
        const { invoiceNo } = this.props;
        if (_.isEmpty(invoiceNo)) {
            toastr.error(I18n.t('Notify.Error'), 'Missing Invoice No')
        } else {
            this.props.createInvoice(data)
        }
    }

    render() {
        return (
            <Switch>
                <Route path={PATH.ACCOUNTING_PAYABLE_PLAN_IMPORTEXPORT_DECLARATION_PREVIEW_URL}
                    render={() => <PreviewDeclaration />}
                />
                <Route path={PATH.ACCOUNTING_PAYABLE_PLAN_IMPORTEXPORT_DECLARATION_URL}
                    render={() =>
                        <CreatePlanIEDeclaration
                            {...this.props}
                            save={data => this.save(data)}
                        />
                    }
                />
            </Switch>
        )
    }
}

AccountsPayablePlanImportExportContainer.propTypes = {

};

AccountsPayablePlanImportExportContainer.defaultProps = {

};

const mapStateToProps = state => ({
    name: selector.getName(state),
    invoiceNo: selector.getInvoiceNo(state),
    invoiceDate: selector.getInvoiceDate(state),
    orderFees: selector.getOtherFees(state),
    note: selector.getNote(state),
    amount: selector.getAmount(state),
    totalAmount: selector.getTotalAmount(state),
    say: selector.getTotalAmountSay(state),
    listCheckableDeclaration: selector.getSelectableDeclarations(state),
    searchText: selector.getSearchText(state),
    listDeclarationDetail: selector.getDetailedDeclarations(state),
    canPreview: selector.getCanPreview(state),
    dataApi: selector.fnGenerateRequestBody(state),
    success: selector.getSuccess(state)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    changeName(value) {
        dispatch(actions.changeName(value))
    },
    changeInvoiceNo(value) {
        dispatch(actions.changeInvoiceNo(value))
    },
    changeInvoiceDate(value) {
        dispatch(actions.changeInvoiceDate(value))
    },
    changeOrderFees(value) {
        dispatch(actions.changeOrderFees(value))
    },
    changeNote(value) {
        dispatch(actions.changeNote(value))
    },
    getListDeclaration() {
        dispatch(actions.getListDeclaration())
    },
    changeSearchText(value) {
        dispatch(actions.changeSearchText(value))
    },
    changeCheckedDeclaration(item, checked) {
        dispatch(actions.changeCheckedDeclaration(item, checked))
    },
    onPreviewScreen() {
        ownProps.router.history.push(PATH.ACCOUNTING_PAYABLE_PLAN_IMPORTEXPORT_DECLARATION_PREVIEW_URL)
    },
    createInvoice(data) {
        dispatch(actions.createInvoice(data))
    },
    resetData() {
        dispatch(actions.resetData())
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AccountsPayablePlanImportExportContainer));
