import React, { Component } from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

export default class TextNumberInput extends Component {
  constructor (props) {
    super(props);
    this.onValueChange = this.onValueChange.bind(this);
    this.inputElement = null;
  }

  onValueChange(valObject) {
    if(this.props.onChange) {
      let valueTemps = valObject.formattedValue.split('.');
      let value = valObject.formattedValue;
      if(valueTemps && valueTemps[1] && valueTemps[1].length > 2) {
        value = valueTemps[0] + '.' + valueTemps[1].substring(0,2);
      }
      this.props.onChange(value);
    }
  }

  render () {
    const {className, value, format, placeholder, disabled} = this.props;
    if (this.inputElement && disabled) {
      this.inputElement.disabled = true
    }
    return (
      <NumberFormat
        placeholder={placeholder}
        allowNegative={false}
        className={className}
        value={value}
        onValueChange={this.onValueChange}
        format={format}
        getInputRef={element => this.inputElement = element}
      />
    );
  }
}

TextNumberInput.propTypes = {
  className: PropTypes.string,
  //placeholder: PropTypes.number,
  format: PropTypes.string,
  //value: PropTypes.number,
  onChange: PropTypes.func,
};
