import React, {Component} from "react";
import {convertDisplay, getValue} from "../../../functions/helpers";
import getField from "../fields/index";
import Action from "../fields/Action";
import _ from 'lodash';
import Pagination from "./Pagination";

export default class ListAndActions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sort: {
        name: '',
        asc: false
      }
    };
    this.changePage = this.changePage.bind(this);
  }

  setSort(header) {
    let {setSortPage} = this.props.options;
    let sort = this.state.sort;
    if (header === sort.name) {
      sort.asc = !sort.asc;
    } else {
      sort.name = header;
      sort.asc = false;
    }
    setSortPage(sort);
    this.setState({sort: sort});
  }

  changePage(page) {
    let {setPage} = this.props.options;
    setPage && setPage(page);
  }

  render() {
    let {headers, actions, data, statusList, onApplyStyleOnRow, perPage, totalPage, pageNumber, className, statusNotViewEdit, statusNotViewDelete, statusNotViewDeleteOkToPay} = this.props.options;
    let pages = Math.ceil(totalPage / perPage) || 0;
    let page = pageNumber;
    return (
      <div className="wrap-global-table">
        <table className="global-table" style={{width: '100%'}}>
          <tbody className={className}>
          <tr role="row">
            {
              headers.map((header, idx) => {
                let sort = '';
                if (this.state.sort.name === header.name) {
                  if (this.state.sort.asc === true) {
                    sort = 'sorting_asc';
                  } else {
                    sort = 'sorting_desc';
                  }
                } else {
                  sort = 'sorting';
                }
                const style = header.style ? header.style : ""
                return (
                  <th key={idx} className={`${sort} center ${style}`} rowSpan="1" colSpan="1" onClick={() => {
                    this.setSort(header.name);
                  }}>{header.name}</th>
                );
              })
            }
            {(actions || []).length > 0 && <th className="center" rowSpan="1" colSpan="1">Actions</th>}
          </tr>
          {data.map((item, idx) => {
            const rowStyle = onApplyStyleOnRow && onApplyStyleOnRow(item) || "";
            return (
            <tr key={idx} className={rowStyle}>
              {headers.map((header, idx) => {
                let value = getValue(header.value, item);
                let dat = convertDisplay(header.type, value, header.dataType);
                let suffix = '';
                if(header.suffix) {
                  suffix = _.get(item, header.suffix, '');
                }
                if(suffix) {
                  dat += ' ' + suffix;
                }
                let options = {index: idx, statusList: statusList, ...header};
                let rendered = getField(dat, options, header.type, header.name);
                return rendered;
              })}
              {(actions || []).length > 0 && (
                <td className="">
                  {actions.map((action, idx) => {
                      let actionR = action(item, this.props.authState.auth);
                      // console.log(actionR)
                      let options = {...actionR, data: item};
                      if(statusNotViewEdit || statusNotViewDelete){
                        if(actionR.type == 'edit'){
                            let find = _.find(statusNotViewEdit, o => o === item.status);
                            if(!find){return (<Action options={options} key={idx}/>);}
                            return null;
                        }
                        if(actionR.type == 'delete'){
                            let find = _.find(statusNotViewDelete, o => o === item.status);
                            if(!find){return (<Action options={options} key={idx}/>);}
                            return null;
                        }
                      } else if (statusNotViewDeleteOkToPay){
                          if(actionR.type == 'edit'){
                            return (<Action options={options} key={idx}/>);
                          }
                          if(actionR.type == 'delete'){
                              if(!item.isReadyToPay){return (<Action options={options} key={idx}/>);}
                              return null;
                          }
                      } else{
                          return (<Action options={options} key={idx}/>);
                      }
                  })}
                </td>
              )}
            </tr>
          )})}
          </tbody>
        </table>
        {this.props.options.totalPage > 0 && (
          <div className="footer-list">
            <div className="show-text">
              {'Show ' + ((page - 1) * perPage + 1) + ' to ' + (((page * perPage) > this.props.options.totalPage) ? this.props.options.totalPage : (page * perPage)) + ' of ' + this.props.options.totalPage + ' ' + this.props.options.textTotal}
            </div>
            <Pagination items={pages} activePage={pageNumber} onSelect={this.changePage} onPagination={this.props.handlePagination} />
          </div>
        )}
      </div>
    );
  }
}
