import Base from './base';

class Property extends Base {
  constructor () {
    super();

    this.getListPropertyDAL = this.getListPropertyDAL.bind(this);
    this.addPropertyDAL = this.addPropertyDAL.bind(this);
    this.editPropertyDAL = this.editPropertyDAL.bind(this);
    this.getPropertyDAL = this.getPropertyDAL.bind(this);
    this.getPropertyByKeyDAL = this.getPropertyByKeyDAL.bind(this);
    this.deletePropertyDAL = this.deletePropertyDAL.bind(this);
  }

  getListPropertyDAL (query) {

    return super.execute(this.Urls.property.list, this.ApiMethod.get, query);
  }

  addPropertyDAL (body) {
    return super.execute(this.Urls.property.create, this.ApiMethod.post, body);
  }

  editPropertyDAL (id, body) {
    return super.execute(this.Urls.property.update + '/' + id, this.ApiMethod.put, body);
  }

  getPropertyDAL (id, options) {
    return super.execute(this.Urls.property.detail + '/' + id, this.ApiMethod.get, options);
  }

  getPropertyByKeyDAL (key, options) {
    return super.execute(this.Urls.property.detail + '/key/' + key, this.ApiMethod.get, options);
  }

  deletePropertyDAL (id) {
    return super.execute(this.Urls.property.delete + '/' + id, this.ApiMethod.delete, null);
  }
}

export default new Property();
