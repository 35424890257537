import C from '../../constants/ship-out/editShipment';
import shipOutApi from '../../api/shipOutApi';
import standardApi from '../../api/standardApi';
import orderApi from '../../api/orderApi';
import { toastr } from 'react-redux-toastr';
import _ from 'lodash';

export const getShipmentDetail = (id) => dispatch => {
    if (id) {
        let query = { id: id }
        shipOutApi.getShipmentDetail(query).then(res => {
            if (res.success) {
                dispatch({ type: C.CHANGE_SHIPMENT_DETAIL, payload: res.data[0] })
            }
        })
    }
}

export const getStandard = () => dispatch => {
    standardApi.getListItemDAL().then(res => {
        if (res.success) {
            dispatch({ type: C.CHANGE_STANDARD, payload: res.data.items[0] })
        }
    })
}

export const changeShipmentInfo = (key, value) => dispatch => {
    dispatch({ type: C.CHANGE_SHIPMENT_INFO, payload: { key, value } })
}

export const changeOrderActive = (value, orderId) => dispatch => {
    dispatch({ type: C.CHANGE_ACTIVE_ORDER, payload: { value, orderId } })
}

export const changeStyleActive = (orderId, styleId) => dispatch => {
    dispatch({ type: C.CHANGE_ACTIVE_STYLE, payload: { orderId, styleId } })
}

export const changeUnitPrice = (orderId, styleId, cutTicketId, colorId, value) => dispatch => {
    dispatch({ type: C.CHANGE_UNIT_PRICE, payload: { orderId, styleId, cutTicketId, colorId, value } })
}

export const save = (id, data) => dispatch => {
    shipOutApi.updateShipment(id, data).then(res => {
        if (res.success) {
            const completedOrders = _.get(res, 'data.completedOrders', []);
            if (completedOrders.length > 0) {
                const toastrConfirmOptions = {
                    onOk: () => {
                        const ids = completedOrders.map(po => po._id);
                        orderApi.updateCompletedStatusOrders({ ids: ids }).then();
                    },
                    onCancel: () => console.log('CANCEL: clicked')
                };
                let customPOs = completedOrders.reduce((pos, poModal) => pos + `${_.get(poModal, 'customerPO', '')}, `, "");
                customPOs = customPOs.slice(0, -2);
                toastr.confirm(`Orders ${customPOs} are fully shipped and moved to history?`, toastrConfirmOptions);
            }
            dispatch({ type: C.UPDATE_SUCCESS, payload: true })
        }
    })
}

export const resetData = () => dispatch => {
    dispatch({ type: C.RESET_DATA })
}

export const getListForwarder = () => dispatch => {
    shipOutApi.getListForwarder().then(res => {
        if (res.success) {
            dispatch({ type: C.CHANGE_LIST_FORWARDER, payload: res.data.items })
        }
    })
}

export const addForwarderIntoList = (value) => dispatch => {
    dispatch({ type: C.ADD_FORWARDER_INTO_LIST, payload: value })
}

export const activeScreenEditShipment = () => dispatch => {
    dispatch({ type: C.ACTIVE_SCREEN_EDIT_SHIPMENT, payload: true })
}