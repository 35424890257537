import React from 'react';
import PropTypes from "prop-types";
import * as R from 'ramda';
import moment from 'moment';
import FormGroup from '../../forms/FormGroup';
import Input from '../../forms/Input';
import SidebarItemList from "../SidebarItemList";
import SearchSelect from "../../forms/SearchSelect";
import _ from 'lodash';
const formatCurrency = require('format-currency');
const DateTimeField = require('react-datetime');

const EditInvoiceSupplierOversea = props => {
    const { disableChangeValue, currencyTypeUSD, currencyTypeVND, dataApi, constSupplierName = '', issuedDate, dueDate, amount, paid, remaining, paymentType = 'cash', emptyReceipt, receiptFromInvoice } = props;
    let creditDebit = null;
    if (props.creditDebit) {
        creditDebit = {
            value: props.creditDebit._id,
            label: props.creditDebit.code,
            raw: props.creditDebit
        }
    }
    return (
        <div>
            <ul className="content-header-title float-none pd-0-5 justify-content-space-between display-flex">
                <li>
                    <p>Supplier
                        <span className="color-orange medium mg-left-25 size-16">{constSupplierName}</span>
                    </p>
                </li>
                <li className="add-filter-top">
                    <span className="cursor-pointer" onClick={() => props.save(paymentType, dataApi)}>
                        <i className="fa fa-floppy-o" aria-hidden="true"></i>{"SAVE"}
                    </span>
                </li>
            </ul>
            <div className="wrap-input-global pd-15-0 margin-small bg-white page-edit-invoice-list box-shadow">
                <div className="pd-0 col-xs-9 left">
                    <div className="col-md-6 col-xs-12 input">
                        <FormGroup
                            label="Supplier"
                            type="input"
                            inputComponent={
                                <Input
                                    type="text"
                                    value={props.supplier}
                                    onChange={e =>
                                        props.changeSupplier(e.target.value)
                                    }
                                    placeholder=""
                                    disabled={paid > 0 ? true : false}
                                />
                            }
                        />
                    </div>
                    <div className="col-md-6 col-xs-12 input">
                        <FormGroup
                            type="input"
                            label="Issued Date"
                            inputComponent={
                                <div className={paid > 0 ? 'dateptimepicker-disabled' : ''}>
                                <DateTimeField
                                    timeFormat={false} closeOnSelect dateFormat="MMM DD, YYYY"
                                    value={moment(issuedDate)}
                                    onChange={date =>
                                        props.changeIssuedDate(date.format())
                                    }
                                />
                                </div>
                            }
                        />
                    </div>
                    <div className="col-md-6 col-xs-12 input">
                        <FormGroup
                            type="input"
                            label="Factor"
                            inputComponent={
                                <Input
                                    type="text"
                                    value={props.factor}
                                    onChange={e =>
                                        props.changeFactor(e.target.value)
                                    }
                                    placeholder=""
                                    disabled={paid > 0 ? true : false}
                                />
                            }
                        />
                    </div>
                    <div className="col-md-6 col-xs-12 input">
                        <FormGroup
                            type="input"
                            label="Payment Term"
                            inputComponent={
                                <span>{props.paymentTerm}</span>
                            }
                        />
                    </div>
                    <div className="col-md-6 col-xs-12 input">
                        <FormGroup
                            type="input"
                            label="Invoice No."
                            inputComponent={
                                <Input
                                    type="text"
                                    value={props.invoiceNo}
                                    onChange={e =>
                                        props.changeInvoiceNo(e.target.value)
                                    }
                                    placeholder=""
                                    disabled={paid > 0 ? true : false}
                                />
                            }
                        />
                    </div>
                    <div className="col-md-6 col-xs-12 input">
                        <FormGroup
                            type="input"
                            label="Due Date"
                            inputComponent={
                                <div className={paid > 0 ? 'dateptimepicker-disabled' : ''}>
                                <DateTimeField
                                    timeFormat={false} closeOnSelect dateFormat="MMM DD, YYYY"
                                    value={moment(dueDate)}
                                    onChange={date =>
                                        props.changeDueDate(date.format())
                                    }
                                />
                                </div>
                            }
                        />
                    </div>
                    <div className="col-md-6 col-xs-12 input">
                        <FormGroup
                            type="input"
                            label="Credit/Debit"
                            inputComponent={
                                <SearchSelect
                                    options={props.listCreditDebit}
                                    value={creditDebit || ""}
                                    onChange={o => props.changeCreditDebit(o)}
                                    clearable
                                    disabled={paid > 0 ? true : false}
                                />
                            }
                        />
                    </div>
                    <div className="col-md-6 col-xs-12 input">
                        <FormGroup
                            type="input"
                            label="Transaction No"
                            inputComponent={
                                <Input
                                    type="text"
                                    value={props.transactionNo}
                                    onChange={e =>
                                        props.changeTransactionNo((e.target.value))
                                    }
                                    placeholder=""
                                    disabled={paid > 0 ? true : false}
                                />
                            }
                        />
                    </div>
                    <div className="col-md-6 col-xs-12 input">
                        <FormGroup
                            type="input"
                            label="Payment Method"
                            inputComponent={
                                <SearchSelect
                                    options={props.listPaymentMethod}
                                    value={props.paymentMethod || ""}
                                    onChange={o => props.changePaymentMethod(o)}
                                    clearable
                                    disabled={paid > 0 ? true : false}
                                />
                            }
                        />
                    </div>
                    <div className="col-md-6 col-xs-12 input">
                        <FormGroup
                            type="input"
                            label="Pay Amount"
                            inputComponent={
                                <Input
                                    type="number"
                                    min='0'
                                    value={props.payAmount}
                                    onChange={e =>
                                        props.changePayAmount((e.target.value), remaining, paid)
                                    }
                                />
                            }
                        />
                    </div>
                </div>
                <div className="pd-0 col-xs-3 right">
                    <ul className="type-supplier">
                        <li onClick={() => props.changePaymentType('cash')} className={paymentType === "cash" ? "active" : "cursor-pointer"}>
                            <span className="cursor-poniter">
                                <span className="icon">
                                    <span />
                                </span>
                                <span className="text">Cash</span>
                            </span>
                        </li>

                        <li onClick={() => props.changePaymentType('transfer')} className={paymentType === "transfer" ? "active" : "cursor-pointer"}>
                            <span
                                className="cursor-poniter"
                            >
                                <span className="icon">
                                    <span />
                                </span>
                                <span className="text">Transfer</span>
                            </span>
                        </li>
                    </ul>

                    {paymentType === "transfer" &&
                        <span>
                            <div className="mg-right-15">
                                <span className="color-link text-decoration cursor-pointer"
                                    onClick={() => props.openTransferModal()}
                                >
                                    Upload File
                            </span>
                            </div>
                            <p className="text-align-left mg-bottom-15">
                                Files: {(dataApi.files || []).length}
                            </p>
                        </span>
                    }
                    {paymentType === "cash" &&
                        <span>
                            {emptyReceipt && <div className="mg-right-15">
                                <span className="color-link text-decoration cursor-pointer"
                                    onClick={() => props.openReceiptModal()}
                                >
                                    Add Receipt
                                </span>
                            </div>}
                            {!emptyReceipt && <p className="text-align-left mg-bottom-15">
                                <span href="" className="color-link text-decoration size-12 cursor-pointer"
                                    onClick={() => props.openReceiptModal()}
                                >{receiptFromInvoice.no}</span>
                            </p>}
                        </span>
                    }

                    <div className="text-align-left mg-bottom-5">
                        <p>
                            <span className="mg-right-15 size-12">Total Amount INV</span>
                            <span className="bold color-number size-14">{currencyTypeUSD}{formatCurrency(amount)}{currencyTypeVND}</span>
                        </p>
                    </div>

                    <div className="text-align-left mg-bottom-5">
                        <p>
                            <span className="mg-right-15 size-12">Paid</span>
                            <span className="bold size-14">{currencyTypeUSD}{formatCurrency(paid)}{currencyTypeVND}</span>
                        </p>
                    </div>

                    <div className="text-align-left mg-bottom-5">
                        <p>
                            <span className="mg-right-15 size-12">Remaining</span>
                            <span className="bold size-14 color-green">{currencyTypeUSD}{formatCurrency(remaining)}{currencyTypeVND}</span>
                        </p>
                    </div>
                </div>
            </div>
            <div className="content-main-accounting">
                <SidebarItemList
                    searchText={''}
                    changeSearchText={text => props.changeFilterPOText(text)}
                    items={props.listCheckablePurchaseOrder}
                    changeItemChecked={(item, checked) =>
                        props.changeCheckedPurchaseOrder(item, checked)
                    }
                />
                <div
                    className="panel-group mg-0"
                    id="panel-heading-main"
                    role="tablist"
                    aria-multiselectable="true"
                >
                    {_.map(props.editablePurchaseOrders, (item, index) => (
                        <div className="panel panel-default bd-none radius-0" key={index}>
                            {/* <!-- Component --> */}

                            <div className="panel-heading component" role="tab" id="">
                                <div className="panel-title">
                                    <a
                                        role="button"
                                        onClick={() => props.toggleActivePurchaseOrder(item._id, !item.active)}
                                    >
                                        <i className="fa fa-caret-down" aria-hidden="true" />
                                    </a>
                                    <ul>
                                        <li>
                                            <div className="text">
                                                <span className="left">Purchase Order</span>
                                                <span className="right size-15">
                                                    <span className="medium">{R.path(['purchaseNo'], item.purchaseOrder)}</span>
                                                </span>
                                            </div>
                                        </li>
                                        <li className="center">
                                            <div className="text">
                                                <span className="left">Total Purchase</span>
                                                <span className="right size-15">
                                                    <span className="medium">{currencyTypeUSD}{formatCurrency(R.path(['totalPurchase'], item))} {currencyTypeVND}</span>
                                                </span>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="text mg-0">
                                                <span className="right">
                                                    <span className="medium">{R.path(['customer'], item)}</span>
                                                </span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            {R.path(['active'], item) &&
                                <div
                                    id={R.path(['_id'], item)}
                                    className="panel-collapse collapse in"
                                    role="tabpanel"
                                    aria-labelledby={R.path(['_id'], item)}
                                >
                                    <div className="panel-body pd-0 bg-gray">
                                        <div className="wrap-global-table pd-0 inline-block w-full float-left mg-bottom-0">
                                            <table className="global-table box-shadow-none w-full">
                                                <tbody>
                                                    <tr className="bg-white bd-solid-bottom">
                                                        <th>Material</th>
                                                        <th className="center">Color</th>
                                                        <th className="center">Payer</th>
                                                        <th className="center">ETA</th>
                                                        <th className="center">Order Quantity</th>
                                                        <th className="center">Received Quantity</th>
                                                        <th className="center">INV No</th>
                                                        <th className="center">Price</th>
                                                        <th className="center">Amount</th>
                                                    </tr>

                                                    {_.map(R.pathOr([], ['materials'], item), (m, idx) => (
                                                        <tr key={idx}>
                                                            <td>{R.pathOr('', ['name'], m.material)}</td>
                                                            <td className="center">{R.path(['color'], m)}</td>
                                                            <td className="center">{R.path(['payer'], m)}</td>
                                                            <td className="center">{moment(R.path(['eta'], m)).format("MMM DD, YYYY")}</td>
                                                            <td className="center">{R.path(['orderQuantity'], m)}</td>
                                                            <td className="center">{R.path(['orderQuantity'], m)}</td>
                                                            <td className="center">
                                                                <Input
                                                                    type="text"
                                                                    disabled={disableChangeValue}
                                                                    value={R.path(['invNo'], m)}
                                                                    onChange={e => props.changePurchaseInvoiceNo(R.path(['_id'], item), R.path(['_id'], m), idx, e.target.value)}
                                                                />
                                                            </td>
                                                            <td className="center">{currencyTypeUSD}{R.path(['price'], m)} {currencyTypeVND}</td>
                                                            <td className="center color-number">{currencyTypeUSD}{R.path(['amount'], m)} {currencyTypeVND}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            }

                        </div>
                    ))}

                </div>
            </div>
        </div>
    )
}

EditInvoiceSupplierOversea.propTypes = {
    constSupplierName: PropTypes.string,
    supplier: PropTypes.string,
    issuedDate: PropTypes.string,
    dueDate: PropTypes.string,
    amount: PropTypes.Number,
    paid: PropTypes.Number,
    remaining: PropTypes.Number,
    paymentTerm: PropTypes.string,
    factor: PropTypes.string,
    paymentAmount: PropTypes.number,
    paymentType: PropTypes.string,
    paymentMethod: PropTypes.string,
    listPaymentMethod: PropTypes.array,
    transactionNo: PropTypes.string,
    creditDebit: PropTypes.string,
    listCreditDebit: PropTypes.array,
    listCheckablePurchaseOrder: PropTypes.array,
    editablePurchaseOrders: PropTypes.array,
    emptyReceipt: PropTypes.object,
    receiptFromInvoice: PropTypes.object,
    save: PropTypes.func,
    changeSupplier: PropTypes.func,
    changeIssuedDate: PropTypes.func,
    changeFactor: PropTypes.func,
    changeInvoiceNo: PropTypes.func,
    changeDueDate: PropTypes.func,
    changeCreditDebit: PropTypes.func,
    changeTransactionNo: PropTypes.func,
    changePaymentMethod: PropTypes.func,
    changePaymentType: PropTypes.func,
    changePayAmount: PropTypes.func,
    addFile: PropTypes.func,
    openReceiptModal: PropTypes.func,
    changeFilterPOText: PropTypes.func,
    changeCheckedPurchaseOrder: PropTypes.func,
    toggleActivePurchaseOrder: PropTypes.func,
}

EditInvoiceSupplierOversea.defaultProps = {
    constSupplierName: '',
    supplier: '',
    issuedDate: '',
    dueDate: '',
    amount: 0,
    paid: 0,
    remaining: 0,
    paymentTerm: '',
    factor: '',
    paymentAmount: 0,
    listCheckablePurchaseOrder: [],
    editablePurchaseOrders: [],
    emptyReceipt: {},
    receiptFromInvoice: {},
    changeSupplier: f => f,
    changeIssuedDate: f => f,
    changeFactor: f => f,
    changeInvoiceNo: f => f,
    changeDueDate: f => f,
    changeCreditDebit: f => f,
    changeTransactionNo: f => f,
    changePaymentMethod: f => f,
    changePayAmount: f => f,
    changePaymentType: f => f,
    addFile: f => f,
    openReceiptModal: f => f,
    changeFilterPOText: f => f,
    changeCheckedPurchaseOrder: f => f,
    toggleActivePurchaseOrder: f => f,
}

export default EditInvoiceSupplierOversea;