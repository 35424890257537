import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import purchaseActions from '../../../actions/purchaseActions';
import supplierActions from '../../../actions/supplierActions';
import orderActions from '../../../actions/orderActions';
import appActions from '../../../actions/appActions';
import {Pagination} from "react-bootstrap";
import I18n from 'i18n-js';
import {PURCHASE_SCREEN} from "../../../constants/constants";
import TableList from "../../common/datatables/TableList";
import SearchBar from '../../common/layout/SearchBar';
import {ACTION_TYPE, COMPONENT_TYPE, PRODUCTION_SCREEN, FOLLOWUP_SEARCH_KEY} from "../../../constants/constants"
import {SEARCH_LIST, SEARCH_BAR_EVENT, SHIPPING_SCREEN} from "../../../constants/constants";
import CustomPagination from '../../common/datatables/CustomPagination';

class PurchaseAdd extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      notifies: [],
      items: [],
      total: 0,
      pageSize: 10,
      search: {key: FOLLOWUP_SEARCH_KEY.CUSTOMER_PO, value: '', beginDate: '', endDate: ''},
      pageNumber: 1,
      columns: [
        {name: "", type: 'checkbox', value: 'check', options: {event: this.onCheck}},
        // {name: I18n.t("PageShiping.No"), value: 'no'},
        // {name: I18n.t("PageShiping.PurchaseDate"),  type: 'date', formatType: 'MMM DD, YYYY', value: 'createdAt'},
        {name: I18n.t("PageShiping.CustomerPO"), value: 'style.order.customerPO'},
        {name: I18n.t("PageShiping.Style"), value: 'style.style'},
        // {name: I18n.t("PageShiping.PurchaseDate"),  type: 'date', formatType: 'MMM DD, YYYY', value: 'createdAt'},
        {name: I18n.t("PageShiping.PurchaseOrderNo"), value: 'purchaseNo'},
        {name: I18n.t("PageShiping.Supplier"), value: 'supplier.name'},
        // {name: I18n.t("PageShiping.ExDate"), type: 'date', formatType: 'MMM DD, YYYY', value: 'exReceivedDate'},
        {name: I18n.t("PageShiping.Amount"), value: 'amount', options: {clsTd: 'color-red'}},
        {name: I18n.t("PageShiping.Type"), value: 'type'},
      ]
    };
    // this.changeType = this.changeType.bind(this);
    // this.selectMaterial = this.selectMaterial.bind(this);
    // this.next = this.next.bind(this);
  }

  onCheck = (item, name, value) => {
    const {items, notifies} = this.state
    let bool = false
    for (const vl of items) {
      if(vl._id == item._id) {
        item[name] = value
        const idx = _.findIndex(notifies, n => n._id == vl._id)
        if(value) {
          idx < 0 && notifies.push(vl)
        } else {
          idx >= 0 && notifies.splice(idx, 1)
        }
        bool = true
        break
      }
    }
    bool && this.setState({items, notifies});
  }

  selectPage = page => {
    const { onSearch } = this.props;
    const { search } = this.state;
    this.setState({pageNumber: page})
    onSearch(page, search).then(value => {
      this.setState(value);
    })
  }

  changeSearch = (property, value, pos) => {
    const { onChangeSearch } = this.props;
    const { search } = this.state;
    const newSearch = onChangeSearch(search, property, value, pos);
    this.setState({search: newSearch})
  }

  search = () => {
    const { onSearch } = this.props;
    const { pageNumber, search } = this.state;
    onSearch(pageNumber, search).then(value => {
      this.setState(value);
    });
  }
  componentWillMount = () => {
    const { onSearch } = this.props;
    const { pageNumber } = this.state;
    onSearch(pageNumber, {}).then(value => {
      this.setState(value);
    })
  }
  handleNext = () => {
    const {notifies} = this.state;
    const {notify} = this.props;
    this.close();
    notify(notifies);
  }

  componentWillReceiveProps(newProps) {
    // let { suppliers, item, purchase } = this.state;
    // !_.get(this.props, 'options.isAdd', true) && !_.isEqual(item, newProps.purchaseState.detail) && this.setState({ item: _.cloneDeep(newProps.purchaseState.detail) || {} });
    // !_.isEqual(suppliers, newProps.supplierState.items) && this.setState({
    //   suppliers: _.cloneDeep(newProps.supplierState.items) || [],
    //   selectedSupplier: newProps.supplierState.items[0],
    //   materials: newProps.supplierState.items[0].materials
    // });
    // !_.isEqual(suppliers, newProps.orderState.items) && this.setState({
    //   listOrder: newProps.orderState.items,
    //   orderDetail: newProps.orderState.items[0],
    //   purchase: { ...purchase, customerPO: newProps.orderState.items[0]._id }
    // });
  }

  close() {
    this.props.closeModal();
  }

  render() {
    const {columns, actions, pageNumber, pageSize, items, total, notifies} = this.state;
    const fields = SEARCH_LIST.FOLLOWUP_SEARCH_LIST;
    let totalPage = _.ceil(total / pageSize);
    return (
      <div className="modal-dialog purchase-add" style={stype.popupPurchase} role="document">
        <div className="modal-content">
        <form>
          <div className="modal-header">
            <a className="close" onClick={() => this.close()}><span>&times;</span></a>
            <h4 className="modal-title" id="myModalLabel">{I18n.t('PagePurchase.ADDPURCHASEORDER')}</h4>
          </div>
          <div className="modal-body">
            <div className="col-md-12 col-xs-12 no-padding-left-right">
              <SearchBar fields={fields} onChangeSearch={this.changeSearch} onSearch={this.search}/>
              <TableList {...this.props} items={items || []} columns={columns || []} actions={actions || []} options={{clsTbl: 'global-table'}}/>
              <CustomPagination items={totalPage} activePage={pageNumber} onSelect={this.selectPage} />
              <div className="center mg-top-15 clearBoth">
              <button type="button" onClick={() => this.handleNext()} className="bt-orange text-uppercase"> Next</button>
            </div>
            </div>
          </div>
        </form>
        </div>
      </div>
    );
  }
}
const stype = {
  popupPurchase: {
    maxWidth: 'none',
    width: '900px'
  }
}

function mapStateToProps(state, ownProps) {
  return {
    ...ownProps,
    authState: state.authState,
    appState: state.appState,
    purchaseState: state.purchaseState,
    supplierState: state.supplierState,
    orderState: state.orderState
  };
}

function mapDispatchToProps(dispatch) {
  return {
    supplierPropsActions: bindActionCreators(supplierActions, dispatch),
    purchasePropsActions: bindActionCreators(purchaseActions, dispatch),
    orderPropsActions: bindActionCreators(orderActions, dispatch),
    appPropsActions: bindActionCreators(appActions, dispatch)
  };
}

PurchaseAdd.propTypes = {
  authState: PropTypes.object.isRequired,
  appPropsActions: PropTypes.object
};

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseAdd);
