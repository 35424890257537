import {getListCustomerDAL, getListCustomerByQaDAL, addCustomerDAL, editCustomerDAL, 
  getCustomerDAL, deleteCustomerDAL} from '../api/customerApi';
import _ from 'lodash';

class customerServices {

  constructor() {
  }

  getListCustomerByQa (options = {}) {
    options.query && _.set(options, 'query', JSON.stringify(options['query']));
    options.sort && _.set(options, 'sort', JSON.stringify(options['sort']));

    return getListCustomerByQaDAL(options).then((res) => {
      return res;
    });
  }

  getListCustomer (options = {}) {
    options.query && _.set(options, 'query', JSON.stringify(options['query']));
    options.sort && _.set(options, 'sort', JSON.stringify(options['sort']));

    return getListCustomerDAL(options).then((res) => {
      return res;
    });
  }

  getDetail (id) {
    return getCustomerDAL(id, {});
  }

  deleteCustomer (id) {
    return deleteCustomerDAL(id);
  }

  editCustomer (id, data) {
   return editCustomerDAL(id, data);
  }

  addCustomer (data) {
     return addCustomerDAL(data);
  }
}

export default new customerServices();
