import { combineReducers } from 'redux';
import C from '../../../constants/accounting/payable/createInvoiceContractor';
import _ from 'lodash';
/**
 * Store helper information to check whether selectable contractors list is fetching from api
 */
const fetchingSelectableContractors = (state = false, action) => {
  const { payload, type } = action;
  switch (type) {
    // TODO. handle related actions
    default:
      return state;
  }
}

/**
 * Store a list of selectable contractors
 */
const selectableContractors = (state = [], action) => {
  const { payload, type } = action;
  switch (type) {
    case C.CHANGE_LIST_CONTRACTORS:
      return payload;
    // TODO. handle related actions
    default:
      return state;
  }
}

/**
 * Store input information for selected contractor field
 */
const selectedContractor = (state = null, action) => {
  const { payload, type } = action;
  switch (type) {
    case C.CHANGE_SELECTED_CONTRACTOR:
      return payload;
    case C.RESET_DATA:
      return null;
    // TODO. handle related actions
    default:
      return state;
  }
}

/**
 * Store input information for VAT per month field
 */
const isVATPerMonth = (state = true, action) => {
  const { payload, type } = action;
  switch (type) {
    case C.CHANGE_VAT_PER_MOUNTH:
      return payload;
    // TODO. handle related actions
    case C.RESET_DATA:
      return true;
    default:
      return state;
  }
}

/**
 * Store input information for invoice no field
 */
const selectableCustomerPOs = (state = [], action) => {
  const { payload, type } = action;
  switch (type) {
    case C.CHANGE_LIST_CUSTOMER_PO:
      // const results = [];
      // payload.map(item => results.push({_id: item._id, customerPO: (item.customerPO || {}).customerPO}))
      return payload;
    case C.CHANGE_SEARCH_TEXT: {
        let copyState = _.cloneDeep(state);
        if (!payload) {
            copyState = _.map(copyState, (el) => { el.hide = false; return el });
            return copyState;
        }
        const rex = new RegExp(`.*${payload}.*`)
        copyState = _.map(copyState, (el) => {
            let rs = rex.test(_.lowerCase(_.get(el, 'customerPO.customerPO', '')));
            el.hide = !rs;
            return el;
        })
        return copyState;
    }
    // case C.CHANGE_SELECTED_CUSTOMER_PO:
    //   let i = _.findIndex(copyState, (el) => el._id === payload.id);
    //   if (i >= 0) {
    //     copyState[i].checked = payload.checked;
    //   }
    //   return copyState;
    default:
      return state;
  }
}

/**
 * Store input information for invoice no field
 */
const invoiceNo = (state = null, action) => {
  const { payload, type } = action;
  switch (type) {
    case C.CHANGE_INVOICE_NO:
      return payload;
    // TODO. handle related actions
    default:
      return state;
  }
}

/**
 * Store input information for issued date field
 */
const issuedDate = (state = new Date(), action) => {
  const { payload, type } = action;
  switch (type) {
    case C.CHANGE_ISSUED_DATE:
      return payload;
    // TODO. handle related actions
    default:
      return state;
  }
}

/**
 * Store input information for due date field
 */
const dueDate = (state = new Date(), action) => {
  const { payload, type } = action;
  switch (type) {
    case C.CHANGE_DUE_DATE:
      return payload;
    // TODO. handle related actions
    default:
      return state;
  }
}

/**
 * Store helper information to check whether selectable credit debits is fetching from api
 */
const fetchingSelectableCreditDebits = (state = false, action) => {
  const { payload, type } = action;
  switch (type) {
    // TODO. handle related actions
    default:
      return state;
  }
}

/**
 * Store a list of selectable credit debit notes
 */
const selectableCreditDebits = (state = [], action) => {
  const { payload, type } = action;
  switch (type) {
    // TODO. handle related actions
    case C.CHANGE_LIST_CREDIT_DEBITS:
      return payload;
    default:
      return state;
  }
}

/**
 * Store input information for selected credit debit note field
 */
const selectedCreditDebit = (state = null, action) => {
  const { payload, type } = action;
  switch (type) {
    case C.CHANGE_SELECTED_CREDIT_DEBIT:
      return payload || {};
    // TODO. handle related actions
    default:
      return state;
  }
}

/**
 * Store helper information to check whether customer pos is fetching from api
 */
const fetchingCustomerPOs = (state = false, action) => {
  const { payload, type } = action;
  switch (type) {
    // TODO. handle related actions
    default:
      return state;
  }
}

/**
 * Store a list of customer pos
 */
const customerPOs = (state = [], action) => {
  const { payload, type } = action;
  let copyState = _.cloneDeep(state);
  let price, cusId, styleId, cutId, colorId;
  switch (type) {
    case C.CHANGE_SELECTED_CUSTOMER_PO: {
      const { item, checked } = payload;
      let filtered = _.cloneDeep(state).filter(it => it._id !== item._id);
      if (checked) {
        filtered.push(item)
      }
      return filtered;
    }
    case C.CHANGE_SEARCH_TEXT: {
        let copyState = _.cloneDeep(state);
        if (!payload) {
            copyState = _.map(copyState, (el) => { el.hide = false; return el });
            return copyState;
        }
        const rex = new RegExp(`.*${payload}.*`)
        copyState = _.map(copyState, (el) => {
            let rs = rex.test(_.lowerCase(_.get(el, 'customerPO.customerPO', '')));
            el.hide = !rs;
            return el;
        })
        return copyState;
    }
    case C.CHANGE_CUSTOMER_PO_PRICE: {
      price = parseInt(_.get(payload, 'price', 0));
      _.forEach(copyState, (customerPO) => {
        if (!customerPO.customerPO) return false;
        if (customerPO._id == payload._id) {
          customerPO.customerPO.price = price;
          _.forEach(customerPO.styleOrders, styleOrder => {
            if (styleOrder.style) {
              styleOrder.style.price = price;
              _.forEach(styleOrder.cutTickets, cutTicket => {
                _.forEach(cutTicket.colors, (color) => {
                  color.price = price;
                })
              })
            }
          })
        }
      })
      return copyState;
    }
    case C.CHANGE_STYLE_PRICE:
      cusId = payload.customerPO;
      styleId = payload.styleId;
      price = Number(payload.price);
      copyState.map(c => {
        if (c.customerPO.orderId == cusId) {
          c.customerPO.price = 0;
          c.styleOrders.map(s => {
            if (s.style && s.style._id == styleId) {
              s.style.price = price
              s.cutTickets.map(cut => cut.colors.map(cl => cl.price = price))
            }
          })
        }

      })
      return copyState;

    // TODO. handle related actions
    case C.CHANGE_COLOR_PRICE:
      cusId = payload.customerPO;
      styleId = payload.styleId;
      cutId = payload.cutTicketId;
      colorId = payload.colorId;
      price = payload.price;
      copyState.map(c => {
        if (c.customerPO.orderId == cusId) {
          c.customerPO.price = 0;
          c.styleOrders.map(s => {
            if (s.style && s.style._id == styleId) {
              s.style.price = 0;
              s.cutTickets.map(cut => {
                  cut.colors.map(cl => {
                    if (cl.id == colorId) {
                        cl.price = price;
                    }
                  })
              })
            }
          })
        }
      })
      return copyState;
    case C.CHANGE_SUB_VAT_NO:
      _.forEach(copyState, (el) => {
        if (el.customerPO.orderId == payload._id) {
          if (el.vat) {
            el.vat.vatNo = payload.value;
          } else {
            el.vat = { vatNo: payload.value }
          }
        }
      })
      return copyState;
    case C.CHANGE_SUB_VAT_DATE:
      _.forEach(copyState, (el) => {
        if (el.customerPO.orderId == payload._id) {
          if (el.vat) {
            el.vat.vatDate = payload.value;
          } else {
            el.vat = { vatDate: payload.value }
          }
        }
      })
      return copyState;
    case C.CHANGE_SUB_VAT_TAX_RATE:
      _.forEach(copyState, (el) => {
        if (el.customerPO.orderId == payload._id) {
          if (el.vat) {
            el.vat.taxRate = payload.value;
          } else {
            el.vat = { taxRate: payload.value }
          }
        }
      })
      return copyState;
    case C.CHANGE_SUB_VAT_NOTE:
      _.forEach(copyState, (el) => {
        if (el.customerPO.orderId == payload._id) {
          if (el.vat) {
            el.vat.note = payload.value;
          } else {
            el.vat = { note: payload.value }
          }
        }
      })
      return copyState;
    case C.CHANGE_SUB_VAT_TOTAL_AMOUNT:
      _.forEach(copyState, (el) => {
        if (el.customerPO.orderId == payload._id) {
          if (el.vat) {
            el.vat.totalAmount = payload.value;
          } else {
            el.vat = { totalAmount: payload.value }
          }
        }
      })
      return copyState;
    default:
      return state;
  }
}

/**
 * Store a list of checked customer pi
 */
const checkedCustomerPOs = (state = [], action) => {
  const { payload, type } = action;
  switch (type) {
    case C.CHANGE_SELECTED_CUSTOMER_PO:
      const { item, checked } = payload;
      let filtered = _.cloneDeep(state).filter(it => it !== item._id);
      if (checked) filtered = [...filtered, item._id];
      return filtered;
    default:
      return state;
  }
}

/**
 * Store a list of selectable convert currencies
 */
const selectableConvertCurrencies = (state = ["USD", "VND"], action) => {
  const { payload, type } = action;
  switch (type) {
    case C.CHANGE_CONVERT_CURRENCY:
      return payload;
    // TODO. handle related actions
    default:
      return state;
  }
}

/**
 * Store input information for selected convert currency field
 */
const selectedConvertCurrency = (state = "VND", action) => {
  const { payload, type } = action;
  switch (type) {
    case C.CHANGE_SELECTED_CURRENCY:
      return payload.value;
    // TODO. handle related actions
    default:
      return state;
  }
}

/**
 * Store input information for convert rate field
 */
const convertRate = (state = 0, action) => {
  const { payload, type } = action;
  switch (type) {
    case C.CHANGE_CONVERT_RATE:
      return payload;
    // TODO. handle related actions
    default:
      return state;
  }
}

/**
 * Store input information for VAT at the main VAT card
 */
const mainVATNo = (state = null, action) => {
  const { payload, type } = action;
  switch (type) {
    case C.CHANGE_MAIN_VAT_NO:
      return payload;
    // TODO. handle related actions
    default:
      return state;
  }
}

const mainVATDate = (state = new Date(), action) => {
  const { payload, type } = action;
  switch (type) {
    case C.CHANGE_MAIN_VAT_DATE:
      return payload;
    // TODO. handle related actions
    default:
      return state;
  }
}

const mainVATNote = (state = null, action) => {
  const { payload, type } = action;
  switch (type) {
    case C.CHANGE_MAIN_VAT_NOTE:
      return payload;
    // TODO. handle related actions
    default:
      return state;
  }
}

const mainVATTaxRate = (state = null, action) => {
  const { payload, type } = action;
  switch (type) {
    case C.CHANGE_MAIN_VAT_TAX_RATE:
      return payload;
    // TODO. handle related actions
    default:
      return state;
  }
}

const mainVATTotalAmount = (state = 0, action) => {
  const { payload, type } = action;
  switch (type) {
    case C.CHANGE_MAIN_VAT_TOTAL_AMOUNT:
      return payload;
    // TODO. handle related actions
    default:
      return state;
  }
}

/**
 * Store input information for Sub VAT cards in each purchase orders
 * only use if isVATPerMonth is false
 * @param state {[key]: {vatNo: Any, vatDate: Date, taxRate: Number, note: Any}}
 * key will be indexing which is purchase order id as well
 */
const subVATInputData = (state = {}, action) => {
  const { payload, type } = action;
  switch (type) {
    // TODO. handle related actions
    default:
      return state;
  }
}

/**
 * Store input information for pricing fields
 * @param state {[poId]: {price, styles: {[styleId]: {price, cutTickets: {[cutId]: {[color]: {price}}}}}}}
 */
const pricingInputData = (state = {}, action) => {
  const { payload, type } = action;
  switch (type) {
    // TODO. handle related actions
    default:
      return state;
  }
}

/**
 * Store a helper information to check whether informations is post to api for saving...
 */
const success = (state = false, action) => {
  const { payload, type } = action;
  switch (type) {
    case C.SUCCESS:
      return payload;
    default:
      return state;
  }
}

const activeScreenPreview = (state = false, action) => {
  const {type, payload} = action;
  switch(type) {
    case C.ACTIVE_SCREEN_STATUS:
      return payload;
    default:
      return state;
  }
}

const createPayableInvoiceContractor = combineReducers({
  fetchingSelectableContractors,
  selectableContractors,
  selectedContractor,

  fetchingSelectableCreditDebits,
  selectableCreditDebits,
  selectedCreditDebit,

  selectableCustomerPOs,

  invoiceNo,
  issuedDate,
  dueDate,

  isVATPerMonth,

  fetchingCustomerPOs,
  customerPOs,
  checkedCustomerPOs,

  selectableConvertCurrencies,
  selectedConvertCurrency,
  convertRate,

  mainVATNo,
  mainVATDate,
  mainVATTaxRate,
  mainVATNote,
  mainVATTotalAmount,

  subVATInputData,
  pricingInputData,
  success,
  activeScreenPreview,
});

export default (state, action) => {
  const { type } = action;
  if (type === C.RESET_DATA) {
    return {};
  }
  return createPayableInvoiceContractor(state, action);
};