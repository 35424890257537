import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import * as R from 'ramda';
import { Link, Switch, Route, Redirect } from 'react-router-dom';
import * as PATH from '../../../../constants/routeConstants';
import SRC from '../../../../constants/assets';
import FormGroup from '../../forms/FormGroup';
import Input from '../../forms/Input';
import SideBar from '../PayablePlan/SideBar';
import SearchSelect from "../../forms/SearchSelect";
import AccountsPayablePlanImportExportRadioLists from '../../../containers/accounting/AccountsPayablePlanImportExportRadioLists';
import AccountsPayablePlanAmountTable from '../AccountsPayablePlanAmountTable';

const formatCurrency = require('format-currency');
const DateTimeField = require('react-datetime');

const AccountsPayablePlanImportExportUsDelivery = (props) => {
    // console.log(props.listBillDetail)
    return (
        <div>
            <div style={{
                float: 'right',
                top: '-50px',
                right: '10px',
                position: 'absolute'
            }}>
                {props.roles.showCreate && <ul className="inline-block">
                    <li className="float-left style-icon-save top-7 position-relative">
                        <span className="cursor-pointer"
                            onClick={() => props.save(props.dataApi)}
                        >
                            <i className="fa fa-floppy-o" aria-hidden="true"></i>
                            SAVE
                        </span>
                    </li>
                </ul>}
            </div>
            <form onSubmit={(e) => {
                e.preventDefault();
                props.onSubmit();
            }}>
                <div id="header-accounting" className="inline-block w-full position-relative">
                    <div className="left w-200" />
                    <div className="right top-0 w-full pd-left-230">
                        <div className="border-title-left" />
                        <div className="title-left left">
                            <div className="btn-group btn-supplier-contractor-importExport ">
                                <button className="btn btn-secondary btn-lg dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Import/Export</button>
                                <div className="dropdown-menu">
                                    <ul>
                                        <li><Link to={PATH.ACCOUNTING_PAYABLE_PLAN_CONTRACTOR_URL}>Contractor</Link></li>
                                        <li><Link to={PATH.ACCOUNTING_PAYABLE_PLAN_SUPPLIER_URL}>Supplier</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <AccountsPayablePlanImportExportRadioLists pathname={props.pathname} />
                        </div>

                        <div className="bd-dashed-bottom wrap-input-global pd-15-0 margin-small">
                            <div className="col-md-6 col-xs-12 input">
                                <FormGroup
                                    label="Company"
                                    type="select"
                                    className="companyOverlay"
                                    inputComponent={
                                        <SearchSelect
                                            options={props.listCompany || []}
                                            value={props.selectedCompany || ""}
                                            onChange={o => props.changeSelectedCompany(o)}
                                            // isLoading={props.fetchingSelectableSuppliers}
                                            clearable
                                        />
                                    }
                                />
                            </div>

                            <div className="col-md-6 col-xs-12 input">
                                <FormGroup
                                    type="input"
                                    label="Due Date"
                                    inputComponent={
                                        <DateTimeField
                                            timeFormat={false} closeOnSelect dateFormat="MMM DD, YYYY"
                                            value={moment(props.dueDate)}
                                            onChange={date =>
                                                props.changeDueDate(date.format())
                                            }
                                        />
                                    }
                                />
                            </div>

                            <div className="col-md-6 col-xs-12 input">
                                <FormGroup
                                    type="input"
                                    label="Invoice No."
                                    inputComponent={
                                        <Input
                                            type="text"
                                            value={props.invoiceNo}
                                            onChange={e =>
                                                props.changeInvoiceNo((e.target.value))
                                            }
                                            placeholder=""
                                        />
                                    }
                                />
                            </div>
                            <div className="col-md-6 col-xs-12 input import-export-us-delivery-note">
                                <FormGroup
                                    type="textarea"
                                    label="Note"
                                    inputComponent={
                                        <textarea
                                            value={props.note}
                                            type="text"
                                            maxLength={200}
                                            onChange={e =>
                                                props.changeNote((e.target.value))
                                            }
                                        />
                                    }
                                />
                            </div>
                            {/* <div className="col-md-6 col-xs-12 input"></div> */}
                            <div className="col-md-6 col-xs-12 input">
                                <FormGroup
                                    type="input"
                                    label="Invoice Date"
                                    inputComponent={
                                        <DateTimeField
                                            timeFormat={false} closeOnSelect dateFormat="MMM DD, YYYY"
                                            value={moment(props.invoiceDate)}
                                            onChange={date =>
                                                props.changeInvoiceDate(date.format())
                                            }
                                        />
                                    }
                                />
                            </div>

                        </div>
                        <AccountsPayablePlanAmountTable
                            AmountINV={formatCurrency(props.amount) + ' USD'}
                            TotalAmountINV={formatCurrency(props.totalAmount) + ' USD'}
                            Say={props.say}
                        />
                    </div>
                </div>
                <div className="content-main-accounting">

                    <SideBar
                        searchText={props.searchText}
                        placeholder="Bill"
                        items={props.listBill}
                        changeChecked={item => props.changeCheckedDeliveryBill(item._id, item.checked)}
                        changeSearchText={value => props.changeFilterDeliveryBillText(value)} />

                    <div className="panel-group mg-0" id="panel-heading-main" role="tablist" aria-multiselectable="true">
                        {props.listBillDetail.map((item, index) => {
                            return (
                                <div className="panel panel-default bd-none radius-0" key={index}>
                                    {/* <!-- Component --> */}
                                    <div className="panel-heading component" role="tab" id={R.path(['_id'], item)}>
                                        <div className="panel-title">
                                            <a
                                                role="button"
                                                onClick={() => props.toggleActivePurchaseOrder(item._id, !item.active)}
                                            >
                                                <i className="fa fa-caret-down" aria-hidden="true" />
                                            </a>
                                            <ul>
                                                <li>
                                                    <div className="text">
                                                        <span className="left">Bill No</span>
                                                        <span className="right size-15"><span className="medium">{R.path(['billNo'], item)}</span></span>
                                                    </div>
                                                </li>
                                                <li />
                                            </ul>
                                        </div>
                                    </div>
                                    {/* <!-- End Component --> */}
                                    {R.path(['active'], item) && 
                                    <div
                                        id={R.path(['_id'], item)}
                                        className="panel-collapse collapse in"
                                        role="tabpanel"
                                        aria-labelledby={R.path(['_id'], item)}
                                    >
                                        <div className="panel-body pd-0">
                                            <div className="accounting-us-delivery bg-gray float-left text-left size-12 pd-0-0-5-0 inline-block w-full">
                                                <div className="col-md-3 col-xs-12 input">
                                                    <ul>
                                                        <li><span>IFS Filling</span></li>
                                                        <li>
                                                            <input type="number" min="0"
                                                                className="form-control w-100"
                                                                value={R.pathOr(0, ['ifsFilling'], item)}
                                                                onChange={e => props.changeBillDetailValue(item._id, "ifsFilling", (e.target.value))}
                                                            />
                                                        </li>
                                                    </ul>
                                                </div>

                                                <div className="col-md-3 col-xs-12 input">
                                                    <ul>
                                                        <li><span>Custom Entry</span></li>
                                                        <li>
                                                            <input type="number" min="0"
                                                                className="form-control w-100"
                                                                value={R.pathOr(0, ['customerEntry'], item)}
                                                                onChange={e => props.changeBillDetailValue(item._id, 'customerEntry', (e.target.value))}
                                                            />
                                                        </li>
                                                    </ul>
                                                </div>

                                                <div className="col-md-3 col-xs-12 input">
                                                    <ul>
                                                        <li><span>Other Fees</span></li>
                                                        <li>
                                                            <input type="number" min="0"
                                                                className="form-control w-100"
                                                                value={R.pathOr(0, ['otherFees'], item)}
                                                                onChange={e => props.changeBillDetailValue(item._id, 'otherFees', (e.target.value))}
                                                            />
                                                        </li>
                                                    </ul>
                                                </div>

                                                <div className="col-md-3 col-xs-12 input">
                                                    <ul>
                                                        <li>
                                                            <span>Payment Term</span>
                                                        </li>
                                                        <li>
                                                            <input type="number" min="0"
                                                                className="form-control w-100"
                                                                value={R.pathOr(0, ['paymentTerm'], item)}
                                                                onChange={e => props.changeBillDetailValue(item._id, 'paymentTerm', (e.target.value))}
                                                            />
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            {(item.containers).map((it, idx) => {
                                                return (
                                                    <div key={idx} className="mg-bottom-5 inline-block w-full">
                                                        <div className="wrap-input-global bg-gray float-left text-left size-12 pd-0-0-5-0 inline-block w-full">
                                                            <div className="col-md-3 col-sm-6 col-xs-12 mg-bottom-10">
                                                                {/* <p><span className="mg-right-15">ETD</span> <span className="bold">{moment(R.path(['etd'], it)).format("MMM DD, YYYY")}</span></p> */}
                                                            </div>
                                                            {/* <div className="col-md-3 col-sm-6 col-xs-12 mg-bottom-10">
                                                                <p><span className="mg-right-15">ETA</span> <span className="bold">{moment(R.path(['eta'], it)).format("MMM DD, YYYY")}</span></p>
                                                            </div>
                                                            <div className="col-md-3 col-sm-6 col-xs-12 mg-bottom-10">
                                                                <p><span className="mg-right-15">Due Date</span> <span className="bold">{moment(R.path(['dueDate'], it)).format("MMM DD, YYYY")}</span></p>
                                                            </div> */}
                                                        </div>
                                                        <ul className="bg-white content-header-title pd-left-15 bd-solid-bottom-3">
                                                            <li style={{width: "20%"}}>
                                                                <p className="bold text-transform">{R.path(['name'], it)}</p>
                                                            </li>
                                                            <li style={{width: "15%"}}>
                                                                <p><span className="mg-right-15 bold">ETD:</span> <span className="bold">{moment(R.path(['etd'], it)).format("MMM DD, YYYY")}</span></p>
                                                            </li>
                                                            <li style={{width: "15%"}}>
                                                                <p><span className="mg-right-15 bold">ETA:</span> <span className="bold">{moment(R.path(['eta'], it)).format("MMM DD, YYYY")}</span></p>
                                                            </li>
                                                            <li>
                                                                <p><span className="mg-right-15 bold">Due Date:</span> <span className="bold">{moment(R.path(['dueDate'], it)).format("MMM DD, YYYY")}</span></p>
                                                            </li>
                                                            <li className="float-right">
                                                                <span className="cursor-pointer" onClick={() => props.openViewContainerDetail(item._id, idx)}>
                                                                    <img src={SRC.IC_EDIT_X} className="mg-right-10" />
                                                                </span>
                                                            </li>
                                                        </ul>

                                                        <div className="wrap-input-global bg-white float-left text-left size-12 pd-15-0-0-0 inline-block w-full">
                                                            <div className="col-md-3 col-sm-6 col-xs-12 mg-bottom-10">
                                                                <p><span className="mg-right-15">Cost</span> <span className="bold color-green">{formatCurrency(R.pathOr(0, ['cost'], it))} USD</span></p>
                                                            </div>

                                                            <div className="col-md-3 col-sm-6 col-xs-12 mg-bottom-10">
                                                                <p><span className="mg-right-15">Pier Pass</span> <span className="bold color-green">{formatCurrency(R.pathOr(0, ['pierPass'], it))} USD</span></p>
                                                            </div>

                                                            <div className="col-md-3 col-sm-6 col-xs-12 mg-bottom-10">
                                                                <p><span className="mg-right-15">Chassis Fee</span> <span className="bold color-green">{formatCurrency(R.pathOr(0, ['chassisFee'], it))} USD</span></p>
                                                            </div>

                                                            <div className="col-md-3 col-sm-6 col-xs-12 mg-bottom-10">
                                                                <p><span className="mg-right-15">Yard Storage</span> <span className="bold color-green">{formatCurrency(R.pathOr(0, ['yardStorage'], it))} USD</span></p>
                                                            </div>

                                                            <div className="col-md-3 col-sm-6 col-xs-12 mg-bottom-10">
                                                                <p><span className="mg-right-15">Trucking</span> <span className="bold color-green">{formatCurrency(R.pathOr(0, ['trucking'], it))} USD</span></p>
                                                            </div>

                                                            <div className="col-md-3 col-sm-6 col-xs-12 mg-bottom-10">
                                                                <p><span className="mg-right-15">Waiting Time</span> <span className="bold color-green">{formatCurrency(R.pathOr(0, ['waitingTime'], it))} USD</span></p>
                                                            </div>

                                                            <div className="col-md-3 col-sm-6 col-xs-12 mg-bottom-10">
                                                                <p><span className="mg-right-15">Prepull</span> <span className="bold color-green">{formatCurrency(R.pathOr(0, ['prepull'], it))} USD</span></p>
                                                            </div>

                                                            <div className="col-md-3 col-sm-6 col-xs-12 mg-bottom-10">
                                                                <p><span className="mg-right-15">Other Fees</span> <span className="bold color-green">{formatCurrency(R.pathOr(0, ['otherFees'], it))} USD</span></p>
                                                            </div>

                                                            <div className="col-xs-12 mg-bottom-10 bd-dashed-bottom">
                                                                <p className="pd-15-0"><span className="mg-right-15">Note</span> <span className="bold size-14">{R.path(['note'], it)}</span></p>
                                                            </div>
                                                            <div className="pd-5-0">
                                                                <div className="col-md-3 col-sm-6 col-xs-12 mg-bottom-10">
                                                                    <p><span className="mg-right-15">Warehouse</span> <span className="bold color-green">{R.path(['warehouse'], it)}</span></p>
                                                                </div>
                                                                <div className="col-md-3 col-sm-6 col-xs-12 mg-bottom-10">
                                                                    <p><span className="mg-right-15">Quantiy</span> <span className="bold">{R.path(['quantity'], it)}</span></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}

                                        </div>
                                    </div>
                                    }
                                </div>
                            )
                        })}

                    </div>
                </div>
            </form>
            <div className="center clearBoth mg-top-15">
                <button className={props.canPreview ? "bt-orange text-uppercase" : "cursor-text display-none text-uppercase"} style={{ marginRight: 10 }}
                    onClick={() => { props.onPreviewScreen() }}
                    disabled={!props.canPreview}
                >{'PREVIEW'}</button>
            </div>
        </div>
    );
};

AccountsPayablePlanImportExportUsDelivery.propTypes = {
    //para
    listCompany: PropTypes.array,
    selectedCompany: PropTypes.string,
    invoiceNo: PropTypes.string,
    invoiceDate: PropTypes.string,
    note: PropTypes.string,
    listCheckableDeclaration: PropTypes.array,
    listBill: PropTypes.array,
    listBillDetail: PropTypes.array,
    amount: PropTypes.number,
    totalAmount: PropTypes.number,
    say: PropTypes.string,
    dataApi: PropTypes.object,
    canPreview: PropTypes.bool,
    //function
    changeSelectedCompany: PropTypes.func,
    changeCheckedDeliveryBill: PropTypes.func,
    changeFilterDeliveryBillText: PropTypes.func,
    changeInvoiceNo: PropTypes.func,
    changeInvoiceDate: PropTypes.func,
    changeNote: PropTypes.func,
    changeBillDetailValue: PropTypes.func,
    openViewContainerDetail: PropTypes.func,
    onPreviewScreen: PropTypes.func,
    searchText: PropTypes.string.isRequired,
};

AccountsPayablePlanImportExportUsDelivery.defaultProps = {
    dataApi: {},
    changeSelectedCompany: f => f,
    changeCheckedDeliveryBill: f => f,
    changeInvoiceNo: f => f,
    changeInvoiceDate: f => f,
    changeNote: f => f,
    changeBillDetailValue: f => f,
    changeFilterDeliveryBillText: f => f,
    onPreviewScreen: f => f,
};

export default AccountsPayablePlanImportExportUsDelivery;
