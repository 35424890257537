import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import BaseComponent from '../base/BaseComponent';
import SearchBar from '../common/layout/SearchBar';
import TableList from '../common/datatables/TableList';
import StockOutModal from './modal/StockOut';
import ModalConfirm from '../common/modals/ModalConfirm';
import StockOutAdd from './stock-out/StockOutAdd';
import StockOutEdit from './stock-out/StockOutEdit';
import appActions from '../../actions/appActions';
import stockOutActions from '../../actions/stockOutActions';
import {Pagination} from 'react-bootstrap';
import _ from 'lodash';
import moment from 'moment';
import I18n from 'i18n-js';
import {SEARCH_BAR_EVENT, SEARCH_KEY, SEARCH_LIST} from "../../constants/constants";
import * as utils from "../../functions/utils";

import icAddIcon from '../../stylesheets/images/ic-add.svg';
import CustomPagination from '../common/datatables/CustomPagination';

class ScreenStockOut extends BaseComponent {
  constructor (props, context) {
    super(props, context);

    this.state = {
      openForm: false,
      dataForm: {},

      pageSize: 10,
      pageNumber: 1,
      search: {key: SEARCH_KEY.CUSTOMER_PO, value: ''},
      columns: [
        {name: I18n.t('PageWareHouse.Date'), value: 'createdAt', type: 'date', formatType: 'MMM DD, YYYY'},
        {name: I18n.t('PageWareHouse.CustomerPO'), value: 'order.customerPO'},
        {name: I18n.t('PageWareHouse.Style'), value: 'style.style'},
        {name: I18n.t('PageWareHouse.Contractor'), value: 'contractor.name'},
        {name: I18n.t('PageWareHouse.Status'), value: 'status'}
      ],
      actions: [],
      items: [],
      total: 0
    };
  }

  getBaseOption = () => {
    let {pageSize, pageNumber} = this.state;
    return {
      pageSize, 
      pageNumber, 
      sort: {createdAt: -1},
      populate: 'orderId,styleId,contractorId'
    }
  }

  getListItem = options => {
    this.props.stockOutPropsActions.getListItem(options);
  }

  componentWillMount = () => {
    this.getListItem(this.getBaseOption());
  }

  componentWillReceiveProps = newProps => {
    let {items} = this.state;
    !_.isEqual(items, newProps.stockOutState.items) && this.setState({
      items: _.cloneDeep(newProps.stockOutState.items) || [],
      total: newProps.stockOutState.count || 0
    });

    const isEdit = _.get(newProps, 'appState.page.isEdit')
    !_.isEqual(_.get(this.props, 'appState.page.isEdit'), isEdit) && isEdit == false && this.closeFormAdd()
    const isAdd = _.get(newProps, 'appState.page.isAdd')
    !_.isEqual(_.get(this.props, 'appState.page.isAdd'), isAdd) && isAdd == false && this.closeFormAdd()
    // this.props.tabCurrent !== newProps.tabCurrent && this.closeFormAdd();

    const {page} = newProps.appState;
    if(page.tab !== 'stock-out') {
      newProps.appPropsActions.setDataPageCurrent({...page, tab: 'stock-out'})
    }
  }

  componentWillUpdate = (nextProps, nextState) => {
    if (_.get(this.props.stockOutState, 'reload') != _.get(nextProps.stockOutState, 'reload')) { 
      this.search();
      this.props.modal.closeModal();
      this.props.appPropsActions.setDataPageCurrent({isEdit: false});
    }
  }

  selectPage = pageNumber => {
    this.setState({pageNumber});

    const options = {...this.getBaseOption(), pageNumber};
    // this.getListItem(options);
    this.search(options);
  }

  addItem = () => {
    const {openModal, closeModal} = this.props.modal;
    let modal = (<StockOutModal closeModal={closeModal} openFormAdd={this.openFormAdd} />);
    openModal(modal);
  }

  showDeleteConfirm = item => {
    let {openModal, closeModal} = this.props.modal;
    let modal = (<ModalConfirm
      title={I18n.t('PageWareHouse.DELETEOUTBOUND')}
      confirmMessage={I18n.t('PageWareHouse.ConfirmDeleteStockOut')}
      messageOk={I18n.t('Btn.YES')}
      messageCancel={I18n.t('Btn.CANCEL')}
      handleClose={closeModal}
      handleOk={() => this.onDeleteStockOut(item)}/>);
    openModal(modal);
  }

  onDeleteStockOut = item => {
    item._id && this.props.stockOutPropsActions.deleteItem(item._id);
  }

  editItem = item => {
    this.setState({openForm: true, dataForm: item});
    this.props.appPropsActions.setDataPageCurrent({isEdit: true});
  }

  openFormAdd = dataForm => {
    this.setState({openForm: true, dataForm});
    this.props.appPropsActions.setDataPageCurrent({isAdd: true});
  }

  closeFormAdd = () => {
    this.setState({openForm: false, dataForm: {}});
  }

  onChangeSearch = (property, value) => {
    switch(property) {
      case SEARCH_BAR_EVENT.FILTER: {
        this.setState({search: {key: value, value: ""}});
        break;
      }
      case SEARCH_BAR_EVENT.SEARCH: {
        this.setState({search: {...this.state.search, value: value}});
        break;
      }
    }
  }

  search = (option) => {
    const {search} = this.state;

    let options = option || this.getBaseOption();
    // options.pageNumber = 1;
    if(search && search.key && search.value !== '' && search.value !== null && search.value !== "Invalid date") {
        let qr = {}
        switch(search.key) {
          case SEARCH_KEY.DATE: {
            let db = new Date(search.value)
            db.setHours(0,0,0,0)

            let de = _.clone(db)
            de.setDate(de.getDate() + 1);
            const qr = {createdAt: {$gte: db, $lt: de}}
            options.query = {...options.query, ...qr}
            break;
          }
        }
    }
    this.props.stockOutPropsActions.search({search, options}).then(res => {
      if(res && res.pageNumber && res.pageNumber == 1) {
        this.setState({pageNumber: 1});
      }
    });
  }

  render () {
    const {tabCurrent} = this.props;
    let {openForm, dataForm, searchFields, searchValue} = this.state;
    let {items, total, columns, actions, pageNumber, pageSize} = this.state;
    let { showCreate, showEdit, showDelete, showView } = utils.getRoleByAuthState(this.props.authState, 'warehouse');
    let totalPage = _.ceil(total / pageSize);
    actions = []
    if(showEdit){
      actions.push((item) => {
        return {type: 'edit', click: (item) => this.editItem(item)};
      })
    }
    if(showDelete){
      actions.push((item) => {
        if (item && item.status != 'New') {
          return {type: 'delete', click: (item) => this.showDeleteConfirm(item)};
        } else {
          return {type: '', click: (item) => this.showDeleteConfirm(item)};
        }
      })
    }

    if (openForm) {
      return dataForm._id ? <StockOutEdit {...this.props} data={dataForm || {}} closeFormAdd={this.closeFormAdd}/> :
        <StockOutAdd {...this.props} data={dataForm || {}} closeFormAdd={this.closeFormAdd} />;
    }

    const fields = SEARCH_LIST.WAREHOUSE_OUTBOUND
    return (
      <div className='show'>
        <div>
          {showCreate && (<div className="wrap-stock-in">
            <span className="cursor-pointer" style={{ lineHeight: '65px' }} onClick={() => this.addItem()} ><img src={icAddIcon}/> {I18n.t('PageWareHouse.Outbound')}</span>
          </div>)}
          <SearchBar fields={fields} onChangeSearch={this.onChangeSearch} onSearch={this.search}/>
        </div>
        <div className="wrap-global-table">
          <TableList {...this.props} items={items || []} columns={columns || []} actions={actions || []} options={{clsTbl: 'global-table'}} widthAction="w-80"/>
          <CustomPagination items={totalPage} activePage={pageNumber} onSelect={this.selectPage} />
        </div>
      </div>
    );
  }
}

function mapStateToProps (state, ownProps) {
  return {
    appState: state.appState,
    authState: state.authState,
    stockOutState: state.stockOutState
  };
}

function mapDispatchToProps (dispatch) {
  return {
    appPropsActions: bindActionCreators(appActions, dispatch),
    stockOutPropsActions: bindActionCreators(stockOutActions, dispatch)
  };
}

ScreenStockOut.propTypes = {
  authState: PropTypes.object.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(ScreenStockOut);
