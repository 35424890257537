const C = 'PAYABLE_INVOICE_LIST';

export default {
    CHANGE_LIST_INV: `${C}_CHANGE_LIST_INV`,
    CHANGE_LIST_FILTER: `${C}_CHANGE_LIST_FILTER`,
    CHANGE_SELECT_FILTER: `${C}_CHANGE_SELECT_FILTER`,
    CHANGE_SEARCH_VALUE: `${C}_CHANGE_SEARCH_VALUE`,
    CHANGE_PAGING_TOTAL: `${C}_CHANGE_PAGING_TOTAL`,
    CHANGE_PAGING_CURRENT: `${C}_CHANGE_PAGING_CURRENT`,
    TOGLE_CHECK_DATA: `${C}_TOGLE_CHECK_DATA`,
    CHANGE_TOTAL_PAID: `${C}_CHANGE_TOTAL_PAID`,
    CHANGE_TOTAL_AMOUNT: `${C}_CHANGE_TOTAL_AMOUNT`,
    CHANGE_TOTAL_REMAINING: `${C}_CHANGE_TOTAL_REMAINING`,
    CHANGE_FROM_DATE: `${C}_CHANGE_FROM_DATE`,
    CHANGE_TO_DATE: `${C}_CHANGE_TO_DATE`,
    CHANGE_CURRENCY: `${C}_CHANGE_CURRENCY`,
    CHANGE_PAID_DETAIL: `${C}_CHANGE_PAID_DETAIL`,
    ADD_CHECKED_INV_LIST: `${C}_ADD_CHECKED_INV_LIST`,
    REMOVE_CHECKED_INV_LIST: `${C}_REMOVE_CHECKED_INV_LIST`,
    CLEAR_ALL_CHECKED_INV_LIST: `${C}_CLEAR_ALL_CHECKED_INV_LIST`,
    CHANGE_INVOICE_REQUEST: `${C}_CHANGE_INVOICE_REQUEST`,
    CHANGE_CHECKED_INVOICE: `${C}_CHANGE_CHECKED_INVOICE`,
    CREATE_INV_REQUEST_SUCCESS: `${C}_CREATE_INV_REQUEST_SUCCESS`,
    RESET_INVOICE_REQUEST: `${C}_RESET_INVOICE_REQUEST`,
    DELETE_SUCCESS: `${C}_DELETE_SUCCESS`,
    RESET_DELETE_STATUS: `${C}_RESET_DELETE_STATUS`,
    CHANGE_LOADING: `${C}_CHANGE_LOADING`,
    RESET_PAID_LIST: `${C}_RESET_PAID_LIST`,
    CHANGE_RECEIPT_MODAL_VALUE: `${C}_CHANGE_RECEIPT_MODAL_VALUE`,
}