import * as types from '../constants/actionTypes';
import {showLoading, hideLoading} from './commonUseActions';
import productionServices from "../services/productionServices";
import { PAGINATION } from '../constants/constants';
function getListProductionOrders (options = {}) {
  return (dispatch) => {
    // showLoading(types, dispatch);
    dispatch({type: types.PRODUCTION.LOAD_MORE });
    dispatch({type: types.PRODUCTION.RELOAD_PRODUCTION });
    dispatch({type: types.PRODUCTION.LOADING });
    productionServices.getListProductionOrders(options).then((res) => {
      hideLoading(types, dispatch);
      if (res.data.items.length > 0 ) {
        dispatch({
          type: types.PRODUCTION.GET_PRODUCTION_ORDERS_SUCCESS,
          data: res.data || []
        });
        if (res.data.items.length < PAGINATION.PAGE_SIZE) { 
          dispatch({type: types.PRODUCTION.CANCEL_LOAD_MORE });
        }
      } else {
        dispatch({type: types.PRODUCTION.CANCEL_LOAD_MORE });
        dispatch({type: types.PRODUCTION.GET_PRODUCTION_ORDERS_SUCCESS, data: res.data || [] });
      }
      dispatch({type: types.PRODUCTION.CANCEL_RELOAD_PRODUCTION });
      dispatch({type: types.PRODUCTION.CANCEL_LOADING });
    }, (error) => {
      dispatch({type: types.PRODUCTION.CANCEL_RELOAD_PRODUCTION });
      hideLoading(types, dispatch);
      dispatch({type: types.PRODUCTION.CANCEL_LOAD_MORE });
      dispatch({type: types.PRODUCTION.CANCEL_LOADING });
    });
  };
}

function getListOrderMore (options = {}) {
  return (dispatch) => {
    // showLoading(types, dispatch);
    dispatch({type: types.PRODUCTION.RELOAD_PRODUCTION });
    dispatch({type: types.PRODUCTION.LOAD_MORE });
    dispatch({type: types.PRODUCTION.LOADING });
    productionServices.getListProductionOrders(options).then((res) => {
      hideLoading(types, dispatch);
      if (res.data.items.length > 0) {
        dispatch({
          type: types.PRODUCTION.GET_PRODUCTION_ORDERS_SUCCESS_MORE,
          data: res.data || []
        });
        if (res.data.items.length < PAGINATION.PAGE_SIZE) { 
          dispatch({type: types.PRODUCTION.CANCEL_LOAD_MORE });
        }
      } else {
        dispatch({type: types.PRODUCTION.CANCEL_LOAD_MORE });
      }
      dispatch({type: types.PRODUCTION.CANCEL_LOADING });
    }, (error) => {
      hideLoading(types, dispatch);
      dispatch({type: types.PRODUCTION.CANCEL_LOAD_MORE });
      dispatch({type: types.PRODUCTION.CANCEL_LOADING });
    });
  };
}

function getListProgress (id, options = {}) {
  return (dispatch) => {
    showLoading(types, dispatch);
    productionServices.getListProgress(id, options).then((res) => {
      hideLoading(types, dispatch);
      dispatch({
        type: types.PRODUCTION.GET_PROGRESS_SUCCESS,
        data: res.data || []
      });
    }, (error) => {
      hideLoading(types, dispatch);
    });
  };
}

function getListActualYield (id, options = {}) {
  return (dispatch) => {
    showLoading(types, dispatch);
    productionServices.getListActualYield(id, options).then((res) => {
      hideLoading(types, dispatch);
      dispatch({
        type: types.PRODUCTION.GET_ACTUAL_YIELD_SUCCESS,
        data: res.data || []
      });
    }, (error) => {
      hideLoading(types, dispatch);
    });
  };
}

function updateActualYield(id, data) {
  return (dispatch) => {
    showLoading(types, dispatch);
    return productionServices.updateActualYield(id, data).then((res) => {
      hideLoading(types, dispatch);
      return res;
    }, (error) => {
      hideLoading(types, dispatch);
    });
  };
}
function getListQAOrders (options = {}) {
  return (dispatch) => {
    dispatch({type: types.PRODUCTION.RELOAD_PRODUCTION });
    dispatch({ type: types.PRODUCTION.LOAD_MORE });
    dispatch({type: types.PRODUCTION.LOADING });
    // showLoading(types, dispatch);
    productionServices.getListQAOrders(options).then((res) => {
      hideLoading(types, dispatch);
      if (res.data.items.length > 0 ) {
        dispatch({
          type: types.PRODUCTION.GET_QA_ORDERS_SUCCESS,
          data: res.data || []
        });
        if (res.data.items.length < PAGINATION.PAGE_SIZE) { 
          dispatch({type: types.PRODUCTION.CANCEL_LOAD_MORE });
        }
      } else {
        dispatch({type: types.PRODUCTION.CANCEL_LOAD_MORE });
        dispatch({
          type: types.PRODUCTION.GET_QA_ORDERS_SUCCESS,
          data: res.data || []
        });
      }
      dispatch({type: types.PRODUCTION.CANCEL_RELOAD_PRODUCTION });
      dispatch({type: types.PRODUCTION.CANCEL_LOADING });
    }, (error) => {
      dispatch({type: types.PRODUCTION.CANCEL_RELOAD_PRODUCTION });
      hideLoading(types, dispatch);
      dispatch({type: types.PRODUCTION.CANCEL_LOADING });
    });
  };
}

function getListQAOrdersMore (options = {}) {
  return (dispatch) => {
    dispatch({ type: types.PRODUCTION.LOAD_MORE });
    dispatch({type: types.PRODUCTION.LOADING });
    // showLoading(types, dispatch);
    productionServices.getListQAOrders(options).then((res) => {
      hideLoading(types, dispatch);
      if (res.data.items.length > 0 ) {
        dispatch({
          type: types.PRODUCTION.GET_QA_ORDERS_SUCCESS_MORE,
          data: res.data || []
        });
        if (res.data.items.length < PAGINATION.PAGE_SIZE) { 
          dispatch({type: types.PRODUCTION.CANCEL_LOAD_MORE });
        }
      } else {
        dispatch({type: types.PRODUCTION.CANCEL_LOAD_MORE });
      }
      dispatch({type: types.PRODUCTION.CANCEL_RELOAD_PRODUCTION });
      dispatch({type: types.PRODUCTION.CANCEL_LOADING });
    }, (error) => {
      dispatch({type: types.PRODUCTION.CANCEL_RELOAD_PRODUCTION });
      dispatch({type: types.PRODUCTION.CANCEL_LOAD_MORE });
      hideLoading(types, dispatch);
      dispatch({type: types.PRODUCTION.CANCEL_LOADING });
    });
  };
}

function changePageNumber(number) {
  return (dispatch) => {
    dispatch({ type: types.PRODUCTION.CHANGE_PAGE_NUMBER, data: number });
  }
}

function cancelChangePageNumber() {
  return (dispatch) => {
    dispatch({ type: types.PRODUCTION.RESET_CHANGE_PAGE_NUMBER });
  }
}

function changeFilterText(text) {
  return (dispatch) => {
    dispatch({ type: types.PRODUCTION.CHANGE_FILTER_TEXT, data: text });
  }
}

function changeSearch() {
  return (dispatch) => {
    dispatch({ type: types.PRODUCTION.IS_SEARCH});
  }
}

function cancelChangeSearch() {
  return (dispatch) => {
    dispatch({ type: types.PRODUCTION.CANCEL_IS_SEARCH });
  }
}


export default {
  getListProductionOrders,
  getListQAOrders,
  getListQAOrdersMore,
  getListProgress,
  getListActualYield,
  updateActualYield,
  getListOrderMore,
  changePageNumber,
  cancelChangePageNumber,
  changeFilterText,
  changeSearch,
  cancelChangeSearch
};
