
import _ from 'lodash';
import * as R from 'ramda';

export const getId = ({ editPackingStandard }) => R.path(['_id'], editPackingStandard.rawData);

export const getName = ({ editPackingStandard }) => editPackingStandard.name;

export const getDescription = ({ editPackingStandard }) => editPackingStandard.description;

export const getCheckableTypes = ({ editPackingStandard }) => {
  const { type, selectablePackingTypes } = editPackingStandard;
  return _.map(selectablePackingTypes, t => ({
    value: t,
    label: t,
    checked: t === type,
    raw: t,
  }));
}

export const getTrims = ({ editPackingStandard }) => {
  const { trims, selectableMaterials } = editPackingStandard;
  const materials = _.map(selectableMaterials, m => ({
    value: R.path(['_id'], m),
    label: R.path(['name'], m),
    raw: m,
  }));

  const results = _.map(trims, trim => ({
    key: R.path(['key'], trim),

    material: R.path(['trim'], trim),
    selectableMaterials: materials,

    color: R.path(['color'], trim),
    selectableColors: _.map(R.pathOr([], ['data', 'color'], _.find(selectableMaterials, m => m._id === R.path(['trim'], trim))), c => ({
      value: c,
      label: c,
      raw: c,
    })),

    size: R.path(['size'], trim),
    selectableSizes: _.map(R.pathOr([], ['data', 'size'], _.find(selectableMaterials, m => m._id === R.path(['trim'], trim))), s => ({
      value: s,
      label: s,
      raw: s,
    })),
  }));

  return results;
}

export const getFoldingWays = ({ editPackingStandard }) => {
  const { foldingWays } = editPackingStandard;
  return _.map(foldingWays, item => ({
    key: R.path(['key'], item),
    imageUrl: R.path(['imageUrl'], item),
    description: R.path(['note'], item),
    raw: item,
  }));
}

export const getHangTags = ({ editPackingStandard }) => {
  const { hangTags } = editPackingStandard;
  return _.map(hangTags, item => ({
    key: R.path(['key'], item),
    imageUrl: R.path(['imageUrl'], item),
    description: R.path(['note'], item),
    raw: item,
  }));
}

export const getShippingMarks = ({ editPackingStandard }) => {
  const { shippingMarks } = editPackingStandard;
  return _.map(shippingMarks, item => ({
    key: R.path(['key'], item),
    imageUrl: R.path(['imageUrl'], item),
    description: R.path(['note'], item),
    raw: item,
  }));
}

export const fnGenerateBody = ({ editPackingStandard }) => () => {
  const { 
    name, description, type, rawData, trims, 
    foldingWays, hangTags, shippingMarks,
  } = editPackingStandard;

  let body = { 
    ...rawData,
    name, 
    type,
    trimDescription: description,
    trims,
    foldingWay: _.map(foldingWays, it => _.pick(it, ['_id', 'photo', 'note', 'file'])),
    hangtag: _.map(hangTags, it => _.pick(it, ['_id', 'photo', 'note', 'file'])),
    shippingMark: _.map(shippingMarks, it => _.pick(it, ['_id', 'photo', 'note', 'file'])),
  };
  return body;
}
