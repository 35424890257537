import {align, form, font, size, border, background, box} from './styles';

export class Store {
    constructor (){
        this.global= {};
        this.single = {}
    };

    setStyles = (options, isSingle = 0) => {

        if(!isSingle){
            if (options.orderNumbers) {
                options.orderNumbers.map(order => {
                    this.global[order] = {};
                });
            }    
    
            align.handleGlobal(options.aligns, this.global);
            form.handleGlobal(options.forms, this.global);
            font.handleGlobal(options.fonts, this.global);
            size.handleGlobal(options.sizes, this.global);
            border.handleGlobal(options.borders, this.global);
            background.handleGlobal(options.backgrounds, this.global);
            box.handleGlobal(options.boxes, this.global);
    
        }else{
    
            align.handleSingle(options.align, this.single);
            form.handleSingle(options.form, this.single);
            font.handleSingle(options.font, this.single);
            size.handleSingle(options.size, this.single);
            border.handleSingle(options.border, this.single);
            background.handleSingle(options.backgrounds, this.single);
            box.handleSingle(options.box, this.single);
    
            return this.single;
        }
        
    };

    setStyleType = (...types) => {
        let styles = { ...this.global };
        let tagStyles = {},
            resultStyles = {};
    
        types.map((type) => {
            if (typeof type === `object`) {
                this.single = {};
                const singleStyles = this.setStyles(type, 1);
                if (singleStyles.form) {
                    tagStyles = { ...tagStyles, ...singleStyles.form };
                }
                if (singleStyles.align) {
                    tagStyles = { ...tagStyles, ...singleStyles.align };
                }
                if (singleStyles.font) {
                    tagStyles = { ...tagStyles, ...singleStyles.font };
                }
                if (singleStyles.size) {
                    tagStyles = { ...tagStyles, ...singleStyles.size };
                }
                if (singleStyles.border) {
                    tagStyles = { ...tagStyles, ...singleStyles.border };
                }
                if (singleStyles.background) {
                    tagStyles = { ...tagStyles, ...singleStyles.background };
                }
                if (singleStyles.box) {
                    tagStyles = { ...tagStyles, ...singleStyles.box };
                }
                resultStyles = {...resultStyles, ...tagStyles};
            }else if(typeof type === `string`){
                const [typeName, order] = type.split(`_`);
                resultStyles = { ...resultStyles, ...styles[order][typeName] };
            }
        });
        return resultStyles;
    }
}



