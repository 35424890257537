import React from "react";
import moment from 'moment';
import PropTypes from 'prop-types';
import DateTime from "react-datetime";
import TextNumberInput from "../../../components/common/fields/TextNumberInput";


const MainVATCard = props => (
  <div className="bg-white box-shadow wrap-input-global pd-15-0 margin-small main-vat-card">

    <div className="col-md-6 col-xs-12 input">
      <ul>
        <li>
          <span>VAT No</span>
        </li>
        <li>
          <input
            type="text"
            disabled = {props.disableChangeValue}
            className="form-control"
            value={props.vatNo || ""}
            onChange={e => props.changeVATNo(e.target.value)}
          />
        </li>
      </ul>
    </div>

    <div className="col-md-6 col-xs-12 input">
      <ul>
        <li>
          <span>Total Amount</span>
        </li>
        <li>
          <span className="color-number bold">
            <TextNumberInput
              disabled={props.disableChangeValue}
              className="form-control"
              value={props.totalAmount || ""}
              onChange={props.changeTotalAmount}
            />
          </span>
        </li>
      </ul>
    </div>

    <div className="col-md-6 col-xs-12 input">
      <ul>
        <li>
          <span>VAT Date</span>
        </li>
        <li className={props.disableChangeValue ? "dateptimepicker-disabled" : ""}>
            <DateTime
                defaultValue={moment().format("MMM DD, YYYY")}
                dateFormat="MMM DD, YYYY"
                timeFormat={false}
                value={moment(props.vatDate ? props.vatDate : new Date())}
                onChange={date => props.changeVATDate(date.format())}
            />

        </li>
      </ul>
    </div>
    <div className="col-md-6 col-xs-12 input">
      <ul>
        <li>
          <span>VAT Amount</span>
        </li>
        <li>
          <span className="color-number bold">
            {props.vatAmount}
          </span>
        </li>
      </ul>
    </div>
    <div className="col-md-6 col-xs-12 input">
      <ul>
        <li>
          <span>Tax Rate</span>
        </li>
        <li>
          <TextNumberInput
            disabled={props.disableChangeValue}
            className="form-control"
            value={props.taxRate}
            onChange={props.changeTaxRate}
          />
        </li>
      </ul>
    </div>
    <div className="col-md-6 col-xs-12 input">
      <ul>
        <li>
          <span>Grand Total VAT</span>
        </li>
        <li>
          <span className="color-number bold">
            {props.grandTotal}
          </span>
        </li>
      </ul>
    </div>
  </div>
);

MainVATCard.propTypes = {
  vatNo: PropTypes.string.isRequired,
  totalAmount: PropTypes.string.isRequired,
  vatDate: PropTypes.string.isRequired,
  vatAmount: PropTypes.string.isRequired,
  taxRate: PropTypes.string.isRequired,
  grandTotal: PropTypes.string.isRequired,

  changeVATNo: PropTypes.func,
  changeTotalAmount: PropTypes.func,
  changeVATDate: PropTypes.func,
  changeTaxRate: PropTypes.func,
}

MainVATCard.defaultProps = {
  changeVATNo: (v) => v,
  changeTotalAmount: f => f,
  changeVATDate: f => f,
  changeTaxRate: f => f,
}

export default MainVATCard;
