import React, { Component } from 'react';
import moment from 'moment';
const formatCurrency = require('format-currency');

const DateTimeField = require('react-datetime');

class VATValues extends Component {
    constructor(props) {
        super(props);
        
    }
    
    render() {
        const {data, totalAmount,  vatAmount, grandTotalAmount} = this.props;
        return (
            <div className="bg-white box-shadow wrap-input-global pd-15-0 margin-small">
                <div className="col-md-6 col-xs-12 input">
                    <ul>
                        <li><span>VAT No</span></li>
                        <li>
                            <input type="text" className="form-control" 
                                value = {data.vatNo || ''}
                                onChange = {e => this.props.changeVATValues('vatNo', e.target.value)}
                            />
                        </li>
                    </ul>
                </div>
                <div className="col-md-6 col-xs-12 input">
                    <ul>
                        <li><span>Total Amount</span></li>
                        <li><span className="color-number bold">{formatCurrency(totalAmount || 0)}</span></li>
                    </ul>
                </div>
                <div className="col-md-6 col-xs-12 input">
                    <ul>
                        <li><span>VAT Date</span></li>
                        <li><DateTimeField
                            timeFormat={false} closeOnSelect dateFormat="MMM DD, YYYY" 
                            value = {moment(data.vatDate)}
                            onChange = {date => this.props.changeVATValues('vatDate', date.format())}
                            />
                         </li>
                    </ul>
                </div>
                <div className="col-md-6 col-xs-12 input">
                    <ul>
                        <li><span>VAT Amount</span></li>
                        <li><span className="color-number bold">{formatCurrency(vatAmount || 0)}</span></li>
                    </ul>
                </div>
                <div className="col-md-6 col-xs-12 input">
                    <ul>
                        <li><span>Tax Rate</span></li>
                        <li><input type="number" className="form-control" step = "0.01"
                            value = {data.taxRate}
                            onChange = {e => this.props.changeVATValues('taxRate', e.target.value)}
                        /></li>
                    </ul>
                </div>
                <div className="col-md-6 col-xs-12 input">
                    <ul>
                        <li><span>Grand Total VAT</span></li>
                        <li><span className="color-number bold">{formatCurrency(grandTotalAmount || 0)}</span></li>
                    </ul>
                </div>

                <div className="col-xs-12 input h-auto">
                    <ul>
                        <li><span>Note</span></li>
                        <li className="textarea">
                            <textarea rows="4" cols="50" value = {data.note}
                                 onChange = {e => this.props.changeVATValues('note', e.target.value)}
                            />
                        </li>
                    </ul>
                </div>

            </div>
        );
    }
}

export default VATValues;