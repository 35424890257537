
import { combineReducers } from 'redux';
import C from '../../constants/purchase/showPurchaseDetail';

const fetching = (state = false, action) => {
  const { type, payload } = action;
  switch (type) {
    case C.FETCH_PURCHASE_ORDER:
      return true;
    case C.CANCEL_FETCH_PURCHASE_ORDER:
      return false;
    default:
      return state;
  }
}

const purchaseOrder = (state = null, action) => {
  const { type, payload } = action;
  switch (type) {
    case C.CHANGE_PURCHASE_ORDER:
      return payload;
    default:
      return state;
  }
}

const combined = combineReducers({
  fetching,
  purchaseOrder,
});

export default (state, action) => {
  const { type } = action;
  if (type === C.RESET_DATA) {
    return {};
  }
  return combined(state, action);
};