import C from '../../../constants/accounting/payable/createInvoiceImpExpDeclaration';
import accountingApi from '../../../api/accountingApi';

export const getListDeclaration = () => dispatch => {
    accountingApi.getListDeclaration().then(res => {
        if(res.success) {
            dispatch({type: C.CHANGE_LIST_DECLARATION, payload: res.data.items})
        }
    })
    // dispatch({type: C.CHANGE_LIST_DECLARATION, payload: jsonDeclarations.data.items})
}

export const changeName = (value) => dispatch => {
    dispatch({ type: C.CHANGE_NAME_FIELD, payload: value})
}
export const changeInvoiceNo = (value) => dispatch => {
    dispatch({ type: C.CHANGE_INVOICE_NO, payload: value})
}
export const changeInvoiceDate = (value) => dispatch => {
    dispatch({ type: C.CHANGE_INVOICE_DATE, payload: value})
}
export const changeOrderFees = (value) => dispatch => {
    dispatch({ type: C.CHANGE_ORDER_FEES, payload: value})
}
export const changeNote = (value) => dispatch => {
    dispatch({ type: C.CHANGE_NOTE, payload: value})
}

export const changeSearchText = (value) => dispatch => {
    dispatch({ type: C.CHANGE_SEARCH_DECLARATION_VALUE, payload: value})
}

export const changeCheckedDeclaration = (item, checked) => dispatch => {
    dispatch({ type: C.CHANGE_CHECKED_LIST, payload: {_id: item._id, checked}})
}

export const createInvoice  = (data) => dispatch => {
    accountingApi.createInvoice(data).then(res => {
        if(res.success) {
            dispatch({type: C.CREATE_INVOICE_SUCCESS, payload: true})
        }
    })
}

export const resetData = () => dispatch => {
    dispatch({type: C.RESET_DATA})
}

export const activeScreenPreview = () => dispatch => {
    dispatch({type: C.ACTIVE_SCREEN_STATUS, payload: true})
  }