import { createSelector } from 'reselect';
import _ from 'lodash';

const genMonthText = (value) => {
    const month = value.split('-')[1];
    switch(month) {
        case '01':
            return 'Jan';
        case '02':
            return 'Feb';
        case '03':
            return 'Mar';
        case '04':
            return 'Apr';
        case '05':
            return 'May';
        case '06':
            return 'Jun';
        case '07':
            return 'Jul';
        case '08':
            return 'Aug';
        case '09':
            return 'Sep';
        case '10':
            return 'Oct';
        case '11':
            return 'Nov';
        case '12':
            return 'Dec';
        default:
            return '';
    }
}

export const getDataForChart = ({customerPOReport}) => {
    if(customerPOReport.dataChart) {
        let {dataChart} = _.cloneDeep(customerPOReport);
        dataChart.map(item => {
            item.new = item.countNew;
            item.completed = item.countCompleted;
            item.name = genMonthText(item.month);
        })
        return dataChart
    }
    return [];
};

export const getTotalNew = createSelector(
    [
        getDataForChart
    ], data => {
        return _.sum(data.map(item => item.new || 0))
    }
)

export const getTotalCompleted = createSelector(
    [
        getDataForChart
    ], data => {
        return _.sum(data.map(item => item.completed || 0))
    }
)

export const getSearchValue = ({customerPOReport}) => customerPOReport.searchValue;

export const getOverview = ({orderState}) => orderState.overview;