const C = 'CUSTOMER_PO.CURRENT.CREATE_ORDER';

export default {
  SHOW_MODAL: `${C}.SHOW_MODAL`,
  HIDE_MODAL: `${C}.HIDE_MODAL`,

  CHANGE_VALIDATE_ORDER_NAMES: `${C}.CHANGE_VALIDATE_ORDER_NAMES`,  // For validate confict purpose
  
  CHANGE_CUSTOMER_PO: `${C}.CHANGE_CUSTOMER_PO`,
  CHANGE_ORDER_DATE: `${C}.CHANGE_ORDER_DATE`,
  CHANGE_EX_FACTORY: `${C}.CHANGE_EX_FACTORY`,
  CHANGE_IH_DATE: `${C}.CHANGE_IH_DATE`,
  CHANGE_DESCRIPTION: `${C}.CHANGE_DESCRIPTION`,

  FETCH_SELECTABLE_CUSTOMERS: `${C}.FETCH_SELECTABLE_CUSTOMERS`,
  CANCEL_FETCH_SELECTABLE_CUSTOMERS: `${C}.CANCEL_FETCH_SELECTABLE_CUSTOMERS`,
  CHANGE_SELECTABLE_CUSTOMERS: `${C}.CHANGE_SELECTABLE_CUSTOMERS`,
  CHANGE_SELECTED_CUSTOMER: `${C}.CHANGE_SELECTED_CUSTOMER`,

  SUBMIT_ORDER: `${C}.SUBMIT_ORDER`,
  CANCEL_SUBMIT_ORDER: `${C}.CANCEL_SUBMIT_ORDER`,

  RESET_DATA: `${C}.RESET_DATA`,
};
