import * as types from '../constants/actionTypes';
import {showLoading, hideLoading} from './commonUseActions';
import standardServices from '../services/standardServices';

function getStandard (options = {}) {
  return (dispatch) => {
    showLoading(types, dispatch);

    standardServices.getStandard(options).then((res) => {
      hideLoading(types, dispatch);

      dispatch({
        type: types.STANDARD.GET_DETAIL_SUCCESS,
        data: res.data.items && res.data.items.length > 0 ? res.data.items[0] : {}
      });
    }, (error) => {
      console.log('===Get Standard Error===', error);
      hideLoading(types, dispatch);
    });
  };
}

function getDetail (id, options = {}, reset = true) {
  return (dispatch) => {
    showLoading(types, dispatch);

    standardServices.getDetail(id, options).then((res) => {
      hideLoading(types, dispatch);

      if (res) {
        dispatch({
          type: types.STANDARD.GET_DETAIL_SUCCESS,
          data: res.data || {}
        });
      }
    }, (error) => {
      hideLoading(types, dispatch);
    });
  };
}

function deleteItem (id) {
  return (dispatch) => {
    showLoading(types, dispatch);

    standardServices.deleteItem(id).then((res) => {
      hideLoading(types, dispatch);

      if (res) {
        dispatch({
          type: types.STANDARD.RELOAD,
          data: true
        });
      }
    }, (error) => {
      hideLoading(types, dispatch, error);
    });
  };
}

function editItem (id, data) {
  return (dispatch) => {
    showLoading(types, dispatch);

    standardServices.editItem(id, data).then((res) => {
      hideLoading(types, dispatch);

      if (res) {
        dispatch({
          type: types.STANDARD.RELOAD,
          data: true
        });
      }
    }, (error) => {
      hideLoading(types, dispatch, error);
    });
  };
}

function addItem (data) {
  return (dispatch) => {
    showLoading(types, dispatch);

    standardServices.addItem(data).then((res) => {
      hideLoading(types, dispatch);

      if (res) {
        dispatch({
          type: types.STANDARD.RELOAD,
          data: true
        });
      }
    }, (error) => {
      hideLoading(types, dispatch, error);
    });
  };
}

function setDetail (item) {
  return (dispatch) => {
    dispatch({
      type: types.STANDARD.RESET_DETAIL,
      data: item
    });
  };
}

export default {
    getStandard,
    deleteItem,
    editItem,
    addItem,
    getDetail,
    setDetail
};
