
import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import propertyActions from '../../../actions/propertyActions';
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';
import I18n from 'i18n-js';
import {toastr} from 'react-redux-toastr';

import icpointerIcon from '../../../stylesheets/images/ic-pointer.svg';
import icAddIcon from '../../../stylesheets/images/ic-add.svg';

class PropertyEdit extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      item: {},
      value: null,
      color: [],
      errors: {},
      dataValue: []
    };
    this.removeItem = this.removeItem.bind(this)
  }

  componentWillMount() {
    const data = _.get(this.props, 'options.data', {});
    this.setState({ item: _.cloneDeep(data) });
    this.props.propertyPropsActions.getListProperty();
  }

  componentWillReceiveProps(newProps) {
    const { dataValue } = this.state;
    const newdataValue = _.cloneDeep(_.get(this.props, 'options.data.values', {}) || []);
    !_.isEqual(dataValue, newdataValue) && this.setState({ dataValue: newdataValue });
  }


  onChange(property, value) {
    let { item } = this.state;
    this.setState({ item: _.set(item, property, value) });
  }

  addColor() {
    let item = this.state.item;
    let { dataValue } = this.state
    let value = _.isUndefined(this.state.item.value) ? "" : this.state.item.value;
    item.values = dataValue
    let existValue = dataValue.findIndex(data => data.toLowerCase() === value.toLowerCase())
    if(existValue == -1){
      dataValue.push(value);
      item.values.push(value);
      dataValue = _.uniq(item.values)
      this.setState({ item, dataValue });
      this.refs.property.value = "";
    } else {
      toastr.error(I18n.t('Notify.Error'), 'Duplicate color');
      this.refs.property.value = "";
    }
  } 

  removeItem(id) {
    let item = this.state.item;
    let dataValue = this.state.dataValue
    item.values = _.uniq(dataValue);
    let data = _.pullAt(item.values, id);
    dataValue = item.values;
    this.setState({ item, dataValue });
  }
  onSortEnd({ oldIndex, newIndex }) {
    this.setState({
      dataValue: arrayMove(this.state.dataValue, oldIndex, newIndex),
    });
  }

  close() {
    this.props.closeModal();
  }

  validate() {
    let { item } = this.state;
    let fields = ['name'];
    let errors = {};
    fields.forEach(f => {
      _.set(errors, f, !item[f] ? `Please enter ${f}` : '');
    });
    if (!errors.name) {
      _.set(errors, 'name', (this.props.validateExistPropertyName && this.props.validateExistPropertyName(item)) ? I18n.t('ErrorMessages.PropertyNameAlreadyExist') : '');
    }
    this.setState({ errors });
    let flag = Object.keys(errors).every(k => !errors[k]);
    return flag;
  }

  save() {
    let { item, dataValue } = this.state;
    dataValue = _.uniq(dataValue)
    item.values = dataValue
    if (this.validate()) {
      item._id && this.props.propertyPropsActions.editProperty(item._id, item);
    }
  }

  render = () => {
    let { item, color, errors, dataValue } = this.state;
    
    const SortableItem = SortableElement(({ removeItem, value, i }) =>
      <li><img src={icpointerIcon} className="cursor-pointer"/> <span>{value} <button onClick={() => removeItem(i)}></button></span></li>
    );

    const SortableList = SortableContainer(({ dataValue, removeItem }) => {
      return (
        <ul className="SortableList item-property-value">
          {dataValue.map((value, index) =>
            <SortableItem key={`item-${index}`} index={index} i={index} value={value} removeItem={removeItem} />
          )}
        </ul>
      );
    });
    return (
      <div className="modal-dialog" role="document">
        <div className="modal-content">
  
            <div className="modal-header">
              <a className="close" onClick={() => this.close()}><span aria-hidden="true">&times;</span></a>
              <h4 className="modal-title" id="myModalLabel">{I18n.t('PageStandard.EDITPROPERTY')}</h4>
            </div>

            <div className="modal-body">

              <div className="form-group">
                <div className="col-md-4 col-xs-12 text">
                  <p>{I18n.t('PageStandard.Name')}</p>
                </div>
                <div className="col-md-8 col-xs-12 value">
                  <input type="text" value={item.name || ''} onChange={evt => this.onChange('name', evt.target.value)} className="form-control" disabled />
                  {errors['name'] && <div className="alert-warning">{errors['name']}</div>}
                </div>
              </div>

              <div className="form-group">
                <div className="col-md-4 col-xs-12 text">
                  <p>{I18n.t('PageStandard.PropertyValues')}</p>
                </div>
                <div className="col-md-8 col-xs-12 value relative">
                  <input type="text" value={this.state.value} ref="property" onChange={evt => this.onChange('value', evt.target.value)} className="form-control" onKeyPress={event => {
                    if (event.key === 'Enter') {
                      this.addColor();
                    }
                  }} />
                  <img src={icAddIcon} className="icon-add-property-value" onClick={() => { this.addColor(); }} />
                </div>
              </div>
              <SortableList
                dataValue={dataValue}
                axis={'xy'}
                onSortEnd={this.onSortEnd.bind(this)}
                removeItem={this.removeItem} />
            </div>

            <div className="modal-footer">
              <button type="button" className="bt-black" onClick={() => this.close()}>{I18n.t('Btn.CANCEL')}</button>
              <button type="button" className="bt-orange mg-l-5" onClick={() => this.save()}>{I18n.t('Btn.SAVE')}</button>
            </div>
      
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    appState: state.appState,
    authState: state.authState,
    propertyState: state.propertyState
  };
}

function mapDispatchToProps(dispatch) {
  return {
    propertyPropsActions: bindActionCreators(propertyActions, dispatch)
  };
}

PropertyEdit.propTypes = {
  authState: PropTypes.object.isRequired,
  validateExistPropertyName: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(PropertyEdit);
