import React from "react";
import PropTypes from "prop-types";
import I18n from "i18n-js";
import _ from "lodash";
import Modal from "../../forms/Modal";
import IMG_DEFAULT from "../../../../stylesheets/images/grag-photo.png";
import XLXS_DEFAULT from "../../../../stylesheets/images/excel.png";
import DOC_DEFAULT from "../../../../stylesheets/images/doc.png";
import icXIcon from '../../../../stylesheets/images/ic-x.svg';

const UploadTransferFileModal = props => {
    // console.log(props.transferFiles)
    return (
        <Modal isOpen={props.isOpen}>
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <form>
                        <div className="modal-header">
                            <a className="close" onClick={() => props.onClose()}>
                                <span aria-hidden="true">&times;</span>
                            </a>
                            <h4 className="modal-title" id="myModalLabel">
                                {props.title || "Upload"}
                            </h4>
                        </div>
                        <div className="modal-body">
                            <div className="form-group">
                                <div className="col-xs-12 right">
                                    <label htmlFor="file-upload" className="custom-file-upload">
                                        <ul className="list-button cursor-pointer" style={{margin: 0}}>
                                            <li>
                                                <a className="bt-orange">Upload</a>
                                            </li>
                                        </ul>
                                    </label>
                                    <input
                                        onChange={e => {
                                            e.preventDefault();
                                            let file = e.dataTransfer
                                                ? e.dataTransfer.files[0]
                                                : e.target.files[0];
                                            if (!file) return;
                                            props.onAddFile(file);
                                        }}
                                        type="file"
                                        id="file-upload"
                                        className="inputfile"
                                    />
                                </div>
                                <div className="col-xs-12">
                                    <ul>
                                        {_.map(props.transferFiles || [], file => (
                                            <li key={file.url} style={{ marginBottom: 30, marginTop: 5, borderTop: '1px dashed gray' }}>
                                                <div className="mg-top-5 mg-bottom-5">
                                                    <div className="col-xs-10">
                                                        <span className="size-12" style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '100%', display: 'block'}}>
                                                            {file.id}
                                                        </span>
                                                    </div>
                                                    <div className="col-xs-2 right">
                                                        <span
                                                            className="color-red right"
                                                            style={{ cursor: "pointer" }}
                                                            onClick={e => {
                                                                e.stopPropagation();
                                                                props.onRemoveFile(file.id)
                                                            }}
                                                        >
                                                            <img
                                                                src={icXIcon}
                                                                alt="Delete"
                                                                className="cursor-pointer"
                                                            />
                                                        </span>
                                                    </div>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="bt-black"
                                onClick={() => props.onClose()}
                            >
                                {I18n.t("Btn.CANCEL")}
                            </button>
                            <button
                                type="button"
                                className={`${!props.canSave ? 'btn btn-default' : ''} bt-orange mg-l-5`}
                                disabled={!props.canSave}
                                onClick={() => { props.onSubmit(props.transferFiles), props.onClose() }}
                            >
                                {props.isSubmiting ? I18n.t("Btn.SAVING") : I18n.t("Btn.SAVE")}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </Modal>
    );
}
UploadTransferFileModal.propTypes = {
    title: PropTypes.string.isRequired,
    images: PropTypes.arrayOf(PropTypes.any).isRequired,
    style: PropTypes.string.isRequired,
    page: PropTypes.string.isRequired,
    canSave: PropTypes.bool,
    isSubmiting: PropTypes.bool,
    errors: PropTypes.arrayOf(PropTypes.any).isRequired,

    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
    onAddImage: PropTypes.func,
    onRemoveImage: PropTypes.func,
    onChangeStyle: PropTypes.func,
    onChangePage: PropTypes.func,
};

UploadTransferFileModal.defaultProps = {
    canSave: true,
    isSubmiting: false,

    onClose: f => f,
    onSubmit: f => f,
    onAddImage: f => f,
    onRemoveImage: f => f,
    onChangeStyle: f => f,
    onChangePage: f => f,
};

export default UploadTransferFileModal;
