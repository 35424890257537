const C = "CREATE_INV_PKL_SHIP";

export default {
    CHANGE_PKL_SHIP_DETAIL: `${C}_CHANGE_PKL_SHIP_DETAIL`,
    RESET_DATA: `${C}_RESET_DATA`,
    CHANGE_CUSTOMER_INV: `${C}_CHANGE_CUSTOMER_INV`,
    CHANGE_INV_DATE: `${C}_CHANGE_INV_DATE`,
    CHANGE_PRICE: `${C}_CHANGE_PRICE`,
    CHANGE_HS_CODE: `${C}_CHANGE_HS_CODE`,
    CHANGE_CAT: `${C}_CHANGE_CAT`,
    CREATE_SUCCES: `${C}_CREATE_SUCCES`,
    UPDATE_SUCCES: `${C}_UPDATE_SUCCES`,
    ACTIVE_SCREEN_EDIT_INV: `${C}_ACTIVE_SCREEN_EDIT_INV`,
    ACTIVE_SCREEN_CREATE_INV: `${C}_ACTIVE_SCREEN_CREATE_INV`,
    CHANGE_DATASET_ALL_VALUE: `${C}_CHANGE_DATASET_ALL_VALUE`,
    CHANGE_DATASET_DETAIL: `${C}_CHANGE_DATASET_DETAIL`,
    CHANGE_DATASET_DETAIL_OF_CREATE: `${C}_CHANGE_DATASET_DETAIL_OF_CREATE`,
}