import {PURCHASE_PLAN} from '../constants/actionTypes';
import initial from './initialState';

export default function (state = initial.purchasePlanState, action) {
  switch (action.type) {
    case PURCHASE_PLAN.GET_BY_STYLE_SUCCESS:
      return {...state, planByStyle: Object.assign({}, action.data || {})};
    case PURCHASE_PLAN.UPDATE_PLAN_SUCCESS:
      return {...state, planByStyle: action.data || {}};
    case PURCHASE_PLAN.RELOAD:
      return {...state, reload: !state.reload};
    default:
      return state;
  }
}
