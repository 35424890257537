import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import moment from 'moment';
import _ from 'lodash';
import I18n from 'i18n-js';
import { toastr } from 'react-redux-toastr';

import warehouseActions from '../../../actions/warehouseActions';
import TableList from '../../common/datatables/TableList';
import BaseComponent from '../../base/BaseComponent';


class Reserve extends BaseComponent {
  constructor (props, context) {
    super(props, context);

    this.state = {
      reserves: [],
      values: _.cloneDeep(_.get(props, 'stock.returns', [])),
      value: 0,
      columns: [
        {name: I18n.t('PageWareHouse.CustomerPO'), value: 'customerPO'},
        {name: I18n.t('PageWareHouse.Style'), value: 'style'},
        {name: I18n.t('PageWareHouse.Amount'), value: 'total', options: {clsTh: 'center', clsTd: 'center'}},
      ],
      actions: [
        (item) => {
          return { type: 'delete', click: (item) => this.removeStyle(item) };
        }
      ],
    }
  }

  componentWillMount = () => {
    let {stock} = this.props;
    this.props.warehouseActions.getListReserve({query: {color: stock.color || '', size: stock.size || '', material: _.get(stock, 'material._id')}});
  }

  // componentWillReceiveProps = newProps => {
  //   this.setState({reseres: _.get(newProps, 'warehouseState.reserves', [])})
  // }

  removeStyle(item) {
    const { stock } = this.props;
    const toastrConfirmOptions = {
      onOk: () => {
        const { warehouseActions } = this.props;
        const styleId = _.get(item, 'styleId', undefined);
        const _id = _.get(item, '_id', undefined);
        if (styleId && _id) {
          warehouseActions.returnStyleResources(_id, styleId).then((resp) => {
            if (resp) {
              this.props.warehouseActions.getListReserve({query: {color: stock.color || '', size: stock.size || '', material: _.get(stock, 'material._id')}});
              toastr.success(I18n.t('Success'), I18n.t('PageWareHouse.ReturnSuccess'));
            }
          }, (error) => {
            console.log('error', error);
          });
        }
      },
      onCancel: () => console.log('CANCEL: Clicked !')
    };
    toastr.confirm(I18n.t('PageWareHouse.ConfirmReturnResources'), toastrConfirmOptions);
  }

  // returnValue = () => {
  //   const {value, values} = this.state;
  //   const {stock } = this.props;
    
  //   const item = {value, date: new Date()}
  //   values.push(item)
  //   this.props.pushReturn(stock._id, item)
  //   this.setState({value: 0, values})
  // }

  render = () => {
    let {closeModal, stock } = this.props;
    let {columns, value, values, actions} = this.state;
    const reserves = _.get(this, 'props.warehouseState.reserves', []);
    // console.log(reserves);
    return (
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <form>
              <div className="modal-header">
                <a className="close" onClick={closeModal}><span aria-hidden="true">&times;</span></a>
                <h4 className="modal-title" id="myModalLabel">{I18n.t('PageWareHouse.Reserve')}</h4>
              </div>

              <div className="modal-body">
                <h3 style={{margin:'10px', textAlign: 'center'}}>{`${_.get(stock, 'material.name', '')}: ${stock.reserve}`}</h3>
                <TableList items={reserves} columns={columns || []} actions={actions || []} options={{clsTbl: 'global-table box-shadow-none'}}/>
                <hr/>
                <div>
                  {values.map((item, idx) => {
                    return (
                      <div key={idx}>
                        <span>{moment(item.date).format("MMMM DD, YYYY")} </span>
                        <span>{item.value}</span>
                      </div>
                    )
                  })}
                </div>
                {/* <input type="number" min="0" value={value} onChange={(evt) => this.setState({value: evt.target.value}) } className="form-control center pd-right-0 w-70 display-table-cell" />
                <button type="button" className="bt-orange mg-left-10" onClick={this.returnValue}>{I18n.t('Btn.RETURN')}</button> */}
                
              </div>

              <div className="modal-footer">
                <button type="button" className="bt-black" onClick={closeModal}>{I18n.t('Btn.CLOSE')}</button>
              </div>
            </form>
          </div>
        </div>
      )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    authState: state.authState,
    warehouseState: state.warehouseState
  };
}

const mapDispatchToProps = dispatch => {
  return {
    warehouseActions: bindActionCreators(warehouseActions, dispatch)
  };
}

Reserve.propTypes = {
  authState: PropTypes.object.isRequired,
  warehouseActions: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(Reserve);
