import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import userActions from '../../actions/userActions';
import BaseComponent from '../base/BaseComponent';
import ModalConfirm from '../common/modals/ModalConfirm';
import ModalUserForm from '../common/modals/ModalUserForm';
import TableList from '../common/datatables/TableList';
import I18n from 'i18n-js';
import _ from 'lodash';
import './style.scss';
import SearchBar from "../common/layout/SearchBar";
import {SEARCH_BAR_EVENT, SEARCH_KEY, SEARCH_LIST} from "../../constants/constants";
import CustomPagination from '../common/datatables/CustomPagination';

class TabUsers extends BaseComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {
      pageSize: 10,
      pageNumber: 1,
      columns: [
        { name: '', value: 'avatar', type: 'avatar', default: 'shortName', dataType: 'text', options: {clsTd: 'avatar'} },
        { name: I18n.t('PageOrganization.Name'), value: 'fullName', type: 'text', dataType: 'text' },
        { name: I18n.t('PageOrganization.Phone'), getValue: (i) => {
          let phoneNumber = i.phone == '+' ? '-': i.phone;
          return phoneNumber;
        }, type: 'text', dataType: 'text' },
        { name: I18n.t('PageOrganization.Email'), value: 'email', type: 'text', dataType: 'text'},
        { name: I18n.t('PageOrganization.Organization'), value: 'organization.name', type: 'text', dataType: 'text'},
        { name: I18n.t('PageOrganization.Department'), value: 'department.name', type: 'text', dataType: 'text'},
        { name: I18n.t('PageAccounting.Status'),  getValue: (i) => {
                if(i.isLock)return 'Lock';
                else return ''
            }, type: 'text', dataType: 'text'},
        { name: I18n.t('PageOrganization.Role'), value: 'role.name', type: 'text', dataType: 'text'},
      ],
      actions: [
        (item) => {
          return { type: 'edit', click: (item) => this.editItem(item) };
        },
        (item) => {
          return { type: 'delete', click: (item) => this.showDeleteConfirm(item) };
        },

      ],
      users: [],
      total: 0,
      search: {key: SEARCH_KEY.NAME, value: ""}
    };

    this.onDeleteUser = this.onDeleteUser.bind(this);
    this.selectPage = this.selectPage.bind(this);
  }

  componentWillMount() {
    this.getListItem({}, this.state.pageSize, this.state.pageNumber);
  }

  componentWillReceiveProps(newProps) {
    let { users } = this.state;
    !_.isEqual(users, newProps.userState.items) && this.setState({
      users: _.cloneDeep(newProps.userState.items) || [],
      total: newProps.userState.count || 0
    });
  }

  componentWillUpdate(nextProps, nextState) {
    if (this.props.userState.reload != nextProps.userState.reload && this.props.modal) {
      this.props.modal.closeModal();
      this.getListItem({}, this.state.pageSize, this.state.pageNumber);
    }
  }

  getListItem = (query = {}, pageSize, pageNumber) => {
    let _query = _.assign({pageSize: pageSize, pageNumber: pageNumber, populate: 'department,organization,role'}, query);
    this.props.userActions.getListUser(_query);
  }

  addItem() {
    let { openModal, closeModal } = this.props.modal;
    let modal = (<ModalUserForm closeModal={closeModal} />);
    openModal(modal);
  }

  editItem(item) {
    let { openModal, closeModal } = this.props.modal;
    let modal = (<ModalUserForm closeModal={closeModal} propUserDetail={ item } />);
    openModal(modal);
  }

  selectPage (page) {
    this.setState({pageNumber: page});
    this.search(this.state.search, page, this.state.pageSize);
  }

  showDeleteConfirm(item) {
    let { openModal, closeModal } = this.props.modal;
    let modal = (<ModalConfirm
      title={I18n.t('PageOrganization.DELETEUSER')}
      confirmMessage={I18n.t('PageOrganization.ConfirmDeleteUser')}
      messageOk={I18n.t('Btn.YES')}
      messageCancel={I18n.t('Btn.CANCEL')}
      handleClose={closeModal}
      handleOk={this.onDeleteUser.bind(null, item)} />);
    openModal(modal);
  }

  onDeleteUser(item) {
    item._id && this.props.userActions.deleteUser(item._id);
  }

  onChangeSearch(property, value) {
    switch(property) {
      case SEARCH_BAR_EVENT.FILTER: {
        this.setState({search: {key: value, value: ""}});
        break;
      }
      case SEARCH_BAR_EVENT.SEARCH: {
        this.setState({search: {...this.state.search, value: value}});
        break;
      }
    }
  }

  onSearch(){
    const {search, pageNumber, pageSize} = this.state;
    this.search(search, pageNumber, pageSize);
  }

  search(search, pageNumber, pageSize) {
    let options = {};
    const populate= 'department,organization,role';
    if(search && search.key && search.value) {
      switch(search.key) {
        case SEARCH_KEY.NAME: {
          options = {
            pageNumber,
            pageSize,
            sort: {updatedAt: -1},
            populate: populate,
            query: JSON.stringify({$where:"/.*"+ search.value + ".*/i.test(this.firstName%2B' '%2Bthis.lastName)"})
          };
          this.props.userActions.getListUser(options).then(res => {
            if(res && res.success && res.data && res.data.items && !res.data.items.length && pageNumber > 1) {
              options = {
                pageNumber:1,
                pageSize,
                sort: {updatedAt: -1},
                populate: populate,
                query: JSON.stringify({$where:"/.*"+ search.value + ".*/i.test(this.firstName%2B' '%2Bthis.lastName)"})
              };
              this.setState({pageNumber: 1});
              this.props.userActions.getListUser({...options});
            }
          });
          break;
        }
        case SEARCH_KEY.PHONE: {

          options = {
            pageNumber,
            pageSize,
            sort: {updatedAt: -1},
            populate: populate,
            query: encodeURIComponent(JSON.stringify({phone: {$regex: '.*' + search.value.replace("+", "\\+") + '.*', $options: 'i'}}))
          };
          this.props.userActions.getListUser(options).then(res => {
            if(res && res.success && res.data && res.data.items && !res.data.items.length && pageNumber > 1) {
              options = {
                pageNumber:1,
                pageSize,
                sort: {updatedAt: -1},
                populate: populate,
                query: encodeURIComponent(JSON.stringify({phone: {$regex: '.*' + search.value.replace("+", "\\+") + '.*', $options: 'i'}}))
              };
              this.setState({pageNumber: 1});
              this.props.userActions.getListUser({...options});
            }
          });
          break;
        }
        case SEARCH_KEY.EMAIL: {
          options = {
            pageNumber,
            pageSize,
            sort: {updatedAt: -1},
            populate: populate,
            query: JSON.stringify({email: {$regex: '.*' + search.value + '.*', $options: 'i'}})
          };
          this.props.userActions.getListUser({...options}).then(res => {
            if(res && res.success && res.data && res.data.items && !res.data.items.length && pageNumber > 1) {
              options = {
                pageNumber:1,
                pageSize,
                sort: {updatedAt: -1},
                populate: populate,
                query: JSON.stringify({email: {$regex: '.*' + search.value + '.*', $options: 'i'}})
              };
              this.setState({pageNumber: 1});
              this.props.userActions.getListUser({...options});
            }
          });
          break;
        }
        case SEARCH_KEY.ORGANIZATION: {
          let query = {
            name: {
              $regex: ".*" + search.value + ".*",
              $options: "i"
            }
          }
          options = {
            projection: "_id",
            query: query
          };
          this.props.userActions.searchByOrganization(options, pageNumber, pageSize, populate).then(res => {
            if(res && res.pageNumber && res.pageNumber == 1) {
              this.setState({pageNumber: 1});
            }
          });
          break;
        }
        case SEARCH_KEY.DEPARTMENT: {
          let query = {
            name: {
              $regex: ".*" + search.value + ".*",
              $options: "i"
            }
          }
          options = {
            projection: "_id",
            query: query
          };
          this.props.userActions.searchByDepartment(options, pageNumber, pageSize, populate).then(res => {
            if(res && res.pageNumber && res.pageNumber == 1) {
              this.setState({pageNumber: 1});
            }
          });
          break;
        }
        case SEARCH_KEY.ROLE: {
          let query = {
            name: {
              $regex: ".*" + search.value + ".*",
              $options: "i"
            }
          }
          options = {
            projection: "_id",
            query: query
          };
          this.props.userActions.searchByRole(options, pageNumber, pageSize, populate).then(res => {
            if(res && res.pageNumber && res.pageNumber == 1) {
              this.setState({pageNumber: 1});
            }
          });
          break;
        }
      }
    }
    if(!search.value) {
      let options = {
        pageNumber,
        pageSize,
        sort: {updatedAt: -1},
        populate: populate,
      };
      this.props.userActions.getListUser(options).then(res => {
        if(res && res.success && res.data && res.data.items && !res.data.items.length && pageNumber > 1) {
          options = {
            pageNumber:1,
            pageSize,
            sort: {updatedAt: -1},
            populate: populate,
          };
          this.setState({pageNumber: 1});
          this.props.userActions.getListUser(options);
        }
      });
    }
  }
  render() {
    let style = {
      width: "100%"
    };

    let { users, total, columns, actions, pageNumber, pageSize } = this.state;
    let totalPage = _.ceil(total / pageSize);
    let tabPanelClass = this.props.active ? "tab-pane active" : "tab-pane";
    return (

      <div role="tabpanel" className={tabPanelClass} id="organization-users">
        <div className="wrap-search-position">
          <SearchBar
            name={I18n.t('PageOrganization.AddNew')}
            addItem={() => this.addItem()}
            fields={SEARCH_LIST.ORGANIZATION_USER}
            onChangeSearch={(type, data) => this.onChangeSearch(type, data)}
            onSearch={() => this.onSearch()}
          />
        </div>
        <TableList {...this.props} items={users || []} columns={columns || []} actions={actions || []}
                   options={{ clsTbl: 'global-table box-shadow-none hasAvatar' }} widthAction="w-70"/>
        <CustomPagination items={totalPage} activePage={pageNumber} onSelect={this.selectPage} />
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    userState: state.userState
  };
}

function mapDispatchToProps(dispatch) {
  return {
    userActions: bindActionCreators(userActions, dispatch)
  };
}

TabUsers.propTypes = {
  authState: PropTypes.object.isRequired,
  active: PropTypes.bool,
  onSelectPage: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(TabUsers);
