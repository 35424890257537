import {getListItemDAL} from '../api/damagedMaterialApi';
import _ from 'lodash';

class damagedMaterialServices {

  constructor() {
  }

  getListItem (options = {}) {
    options.query && _.set(options, 'query', JSON.stringify(options['query']));
    options.sort && _.set(options, 'sort', JSON.stringify(options['sort']));
    return getListItemDAL(options);
  }
}

export default new damagedMaterialServices();
