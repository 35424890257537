import * as types from '../constants/actionTypes';
import {showLoading, hideLoading} from './commonUseActions';
import propertyServices from '../services/propertyServices';

function getListProperty (options = {}) {
  return (dispatch) => {
    showLoading(types, dispatch);

    propertyServices.getListProperty(options).then((res) => {
      dispatch({
        type: types.PROPERTY.GET_LIST_SUCCESS,
        data: res.data || []
      });
      hideLoading(types, dispatch);
    }, (error) => {
      hideLoading(types, dispatch);
    });
  };
}

function getDetail (id, reset = true) {
  return (dispatch) => {
    showLoading(types, dispatch);
    reset && dispatch({
      type: types.PROPERTY.RESET_DETAIL,
      data: {}
    });

    propertyServices.getDetail(id, {}).then((res) => {
      hideLoading(types, dispatch);
      if (res) {
        dispatch({
          type: types.PROPERTY.GET_DETAIL_SUCCESS,
          data: res.data || {}
        });
      }
    }, (error) => {
      hideLoading(types, dispatch);
    });
  };
}

function getDetailByKey (key) {
  return (dispatch) => {
    showLoading(types, dispatch);

    propertyServices.getDetailByKey(key, {}).then((res) => {
      hideLoading(types, dispatch);
      res && res.success && dispatch({
        type: types.PROPERTY.GET_KEY_SUCCESS,
        data: {key: key, values: _.get(res, 'data.values', [])}
      });
    }, (error) => {
      hideLoading(types, dispatch);
    });
  };
}

function deleteProperty (id) {
  return (dispatch) => {
    showLoading(types, dispatch);

    propertyServices.deleteProperty(id).then((res) => {
      hideLoading(types, dispatch);
      if (res) {
        dispatch({
          type: types.PROPERTY.RELOAD,
          data: true
        });
      }
    }, (error) => {
      hideLoading(types, dispatch, error);
    });
  };
}

function editProperty (id, data) {
  return (dispatch) => {
    showLoading(types, dispatch);

    propertyServices.editProperty(id, data).then((res) => {
      hideLoading(types, dispatch);
      if (res) {
        dispatch({
          type: types.PROPERTY.RELOAD,
          data: true
        });
      }
    }, (error) => {
      hideLoading(types, dispatch, error);
    });
  };
}

function addProperty (data) {

  return (dispatch) => {
    showLoading(types, dispatch);

    propertyServices.addProperty(data).then((res) => {
      hideLoading(types, dispatch);
      if (res) {
        dispatch({
          type: types.PROPERTY.RELOAD,
          data: true
        });
      }
    }, (error) => {
      hideLoading(types, dispatch, error);
    });
  };
}

export default {
  getListProperty,
  deleteProperty,
  editProperty,
  addProperty,
  getDetail,
  getDetailByKey
};
