import * as types from '../constants/actionTypes';
import {showLoading, hideLoading} from './commonUseActions';
import damagedMaterialServices from '../services/damagedMaterialServices';
import materialServices from "../services/materialServices";
import orderServices from "../services/orderServices";
import purchaseServices from "../services/purchaseServices";
import styleServices from "../services/styleServices";
import contractorServices from "../services/contractorServices";
import supplierServices from "../services/supplierServices";
import _ from 'lodash';

function getListItem (options = {}) {
  return (dispatch) => {
    showLoading(types, dispatch);

    return damagedMaterialServices.getListItem(options).then((res) => {
      dispatch({
        type: types.DAMAGED_MATERIAL.GET_LIST_SUCCESS,
        data: res.data || []
      });
      hideLoading(types, dispatch);
      return res;
    }, (error) => {
      hideLoading(types, dispatch);
    });
  };
}

function searchByMaterial(options, pageNumber, pageSize) {
  return(dispatch) =>{
    showLoading(types, dispatch);
    return materialServices.getListMaterial(options).then((res) => {
      hideLoading(types, dispatch);
      if(res && res.success) {
        let options = {
          pageSize: pageSize,
          pageNumber: pageNumber,
          sort: {updatedAt: -1},
          populate: 'material,orderId,styleId,contractor,supplier,purchaseOrder',
          query: {
            material: {
              $in: res.data.items.reduce((arr, item) => arr.concat(item._id || []), [])
            }
          }
        };
        return getListItem({...options})(dispatch).then(res => {
          if(res && res.success && res.data && res.data.items && !res.data.items.length && pageNumber > 1) {
            _.set(options, "pageNumber", 1);
            getListItem(options)(dispatch);
            pageNumber = 1;
          }
          return (_.set(res, "pageNumber", pageNumber));
        });
      }
    }, (error) => {
      hideLoading(types, dispatch, error);
    })
  }
}

function searchByContent(options, pageNumber, pageSize) {
  return(dispatch) =>{
    showLoading(types, dispatch);
    return materialServices.getListMaterial(options).then((res) => {
      hideLoading(types, dispatch);
      if(res && res.success) {
        let options = {
          pageSize: pageSize,
          pageNumber: pageNumber,
          sort: {updatedAt: -1},
          populate: 'material,orderId,styleId,contractor,supplier,purchaseOrder',
          query: {
            material: {
              $in: res.data.items.reduce((arr, item) => arr.concat(item._id || []), [])
            }
          }
        };
        return getListItem({...options})(dispatch).then(res => {
          if(res && res.success && res.data && res.data.items && !res.data.items.length && pageNumber > 1) {
            _.set(options, "pageNumber", 1);
            getListItem(options)(dispatch);
            pageNumber = 1;
          }
          return (_.set(res, "pageNumber", pageNumber));
        });
      }
    }, (error) => {
      hideLoading(types, dispatch, error);
    })
  }
}

function searchByCustomerPO(options, pageNumber, pageSize) {
  return(dispatch) =>{
    showLoading(types, dispatch);
    return orderServices.getListOrder(options).then((res) => {
      hideLoading(types, dispatch);
      if(res && res.success) {
        let options = {
          pageSize: pageSize,
          pageNumber: pageNumber,
          sort: {updatedAt: -1},
          populate: 'material,orderId,styleId,contractor,supplier,purchaseOrder',
          query: {
            orderId: {
              $in: res.data.items.reduce((arr, item) => arr.concat(item._id || []), [])
            }
          }
        };
        return getListItem({...options})(dispatch).then(res => {
          if(res && res.success && res.data && res.data.items && !res.data.items.length && pageNumber > 1) {
            _.set(options, "pageNumber", 1);
            getListItem(options)(dispatch);
            pageNumber = 1;
          }
          return (_.set(res, "pageNumber", pageNumber));
        });
      }
    }, (error) => {
      hideLoading(types, dispatch, error);
    })
  }
}

function searchByStyle(options, pageNumber, pageSize) {
  return(dispatch) =>{
    showLoading(types, dispatch);
    return styleServices.getAllStyles(options).then((res) => {
      hideLoading(types, dispatch);
      if(res && res.success) {
        let options = {
          pageSize: pageSize,
          pageNumber: pageNumber,
          sort: {updatedAt: -1},
          populate: 'material,orderId,styleId,contractor,supplier,purchaseOrder',
          query: {
            styleId: {
              $in: res.data.items.reduce((arr, item) => arr.concat(item._id || []), [])
            }
          }
        };
        return getListItem({...options})(dispatch).then(res => {
          if(res && res.success && res.data && res.data.items && !res.data.items.length && pageNumber > 1) {
            _.set(options, "pageNumber", 1);
            getListItem(options)(dispatch);
            pageNumber = 1;
          }
          return (_.set(res, "pageNumber", pageNumber));
        });
      }
    }, (error) => {
      hideLoading(types, dispatch, error);
    })
  }
}

function searchByPurchaseOrderNo(options, pageNumber, pageSize) {
  return(dispatch) =>{
    showLoading(types, dispatch);
    return purchaseServices.getListPurchase(options).then((res) => {
      hideLoading(types, dispatch);
      if(res && res.success) {
        let options = {
          pageSize: pageSize,
          pageNumber: pageNumber,
          sort: {updatedAt: -1},
          populate: 'material,orderId,styleId,contractor,supplier,purchaseOrder',
          query: {
            purchaseOrder: {
              $in: res.data.items.reduce((arr, item) => arr.concat(item._id || []), [])
            }
          }
        };
        return getListItem({...options})(dispatch).then(res => {
          if(res && res.success && res.data && res.data.items && !res.data.items.length && pageNumber > 1) {
            _.set(options, "pageNumber", 1);
            getListItem(options)(dispatch);
            pageNumber = 1;
          }
          return (_.set(res, "pageNumber", pageNumber));
        });
      }
    }, (error) => {
      hideLoading(types, dispatch, error);
    })
  }
}

function searchBySource(options, pageNumber, pageSize) {
  return(dispatch) =>{
    showLoading(types, dispatch);
    return Promise.all([contractorServices.getListItem({...options}), supplierServices.getListSupplier({...options})]).then(arrayRes => {
      const contractorIds = _.get(arrayRes[0], "data.items", []).reduce((arr, item) => arr.concat(item._id || []), []);
      const supplierIds = _.get(arrayRes[1], "data.items", []).reduce((arr, item) => arr.concat(item._id || []), []);

      let options = {
        pageSize: pageSize,
        pageNumber: pageNumber,
        sort: {updatedAt: -1},
        populate: 'material,orderId,styleId,contractor,supplier,purchaseOrder',
        query: {
          $or: [{supplier: supplierIds}, {contractor: contractorIds}]
        }
      };
      return getListItem({...options})(dispatch).then(res => {
        if(res && res.success && res.data && res.data.items && !res.data.items.length && pageNumber > 1) {
          _.set(options, "pageNumber", 1);
          getListItem(options)(dispatch);
          pageNumber = 1;
        }
        return (_.set(res, "pageNumber", pageNumber));
      });
    }, (error) => {
      hideLoading(types, dispatch, error);
    })
  }
}

export default {
  getListItem,
  searchByMaterial,
  searchByContent,
  searchByCustomerPO,
  searchByStyle,
  searchByPurchaseOrderNo,
  searchBySource,
};
