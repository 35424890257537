import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { withRouter } from 'react-router';
import I18n from 'i18n-js';
import { toastr } from 'react-redux-toastr';
import CustomerPOCreateShip from '../../../components/shipping/subViews/customerPOCreateShip';
import ChooseStyle from '../../../components/shipping/subViews/chooseStyle';
import CutTicketContainer from './nested-containers/CutTicketContainerAddView';
import BarTitle from '../../../components/shipping/subViews/barTitle';
import { SHIP_OUT } from '../../../../constants/routeConstants';
import CutTicketContainersPrepack from './nested-containers/CutTicketContainersPrepack';

import {
  getListOrders,
  resetData,
  save,
  changeStyleActive,
  changeOrderActive,
} from '../../../../actions/ship-out/createPklShipActions';
import * as selector from '../../../../selectors/ship-out/createPklShip';
class TotalQTYContainer extends Component {
  state = {
    cutTickets: [1, 2],
    actions: {},
  };
  componentDidMount() {
    this.getListOrders();
  }

  componentWillUnmount() {
    this.props.resetData();
  }
  componentWillUpdate() {}

  componentWillReceiveProps(nextProps) {
    console.log('this.props.actions - componentWillReceiveProps: ', this.props.actions);
    console.log('nextProps - componentWillReceiveProps: ', nextProps);
    const { success, checkEdit, actions } = nextProps;
    if (!_.isEmpty(actions)) {
      this.setState({ actions });
    }
    if (success) {
      nextProps.router.history.push(checkEdit ? SHIP_OUT.PKL_SHIP.URL : SHIP_OUT.URL);
      toastr.success(I18n.t('Notify.Success'), I18n.t('Notify.SaveSuccess'));
    }
  }

  getListOrders = () => {
    const { listIds } = this.props;
    if (!_.isEmpty(listIds)) this.props.getListOrders(listIds);
  };

  changeOrderActive = (value, orderId) => {
    this.props.changeOrderActive(orderId, value);
  };

  setStateFuc = data => {
    this.setState(data);
  };

  setCutTicketContainers = id => {
    const { listActiveStyleIds } = this.props;
    const styleId = listActiveStyleIds[id];
    const cutTickets = this.props.getCutickets(id, styleId);
    return cutTickets.map((cutTicket, index) => {
      return (
        <CutTicketContainer
          key={index}
          cutTicket={cutTicket.cutTicket}
          colors={cutTicket.colors}
          styleId={styleId}
          orderId={id}
        />
      );
    });
  };

  getApiData = () => {
    const { actions } = this.state;
    const data = this.props.getApiData();
    data.pklProds.forEach((order, oIdx) => {
      order.styles.forEach((s, sIdx) => {
        const pkNew = [];
        s.packings &&
          s.packings.forEach((pk, pkIdx) => {
            const key = `${order.packing}-${s.style}-${pk.props.group}`;
            let action = _.cloneDeep(actions[key]);
            if (!action || action.check != false) {
              if (action && action.package) {
                if (action.nos.includes(pk.props.no)) {
                  pkNew.push(pk);
                }
              } else {
                pkNew.push(pk);
              }
            }
          });
        s.packings = pkNew;
      });
    });
    return data;
  };

  total = () => {
    const { actions } = this.state;
    let total = this.props.total;
    const data = this.props.getApiData();
    data.pklProds.forEach((order, oIdx) => {
      order.styles.forEach((s, sIdx) => {
        const pkNew = [];
        s.packings && s.packings.forEach((pk, pkIdx) => {
          const key = `${order.packing}-${s.style}-${pk.props.group}`;
          let action = _.cloneDeep(actions[key]);
          if (!action || action.check != false) {
            if (action && action.package) {
              if (action.nos.includes(pk.props.no)) {
                total += _.sum(pk.items.map(item => Number(item.actualQuantity || 0)));
              }
            } else {
              total += _.sum(pk.items.map(item => Number(item.actualQuantity || 0)));
            }
          }
        });
        s.packings = pkNew;
      });
    });
    return total;
  };

  changeStyleActive = (orderId, styleId) => {
    this.props.changeStyleActive(orderId, styleId);
  };

  setOrderContainers = orders => {
    return orders.map((order, index) => {
      const { actions } = this.state;
      const { getOrderDetail, getStyles } = this.props;
      let orderDetail = getOrderDetail(order._id) || {};
      let styles = getStyles(order._id) || [];

      const cutTicketContainers =
        orderDetail.carton != 'Pre-pack' ? (
          this.setCutTicketContainers(order._id)
        ) : (
          <CutTicketContainersPrepack
            actions={_.isEmpty(actions) ? this.props.actions : actions}
            setState={this.setStateFuc}
            order={orderDetail}
          />
        );
      return (
        <div className="pd-5 mg-bottom-10">
          <BarTitle
            id={order._id}
            type="customer_po"
            name={order.name}
            active={order.active}
            changeOrderActive={this.changeOrderActive}
          />
          {order.active ? (
            <div>
              <CustomerPOCreateShip orderDetail={orderDetail} orderId={order._id} />
              <ChooseStyle
                removeIcon={true}
                orderId={order._id}
                styles={styles}
                changeStyleActive={this.changeStyleActive}
              />
              {cutTicketContainers}
            </div>
          ) : null}
        </div>
      );
    });
  };

  save = () => {
    const { emptyCommodity } = this.props;
    const total = this.total;
    let param = '';
    if (emptyCommodity) {
      param = 'emptyCommondity';
    }
    if (total === 0) {
      param = 'emptyPacking';
    }
    switch (param) {
      case 'emptyCommondity':
        toastr.error(I18n.t('Notify.Error'), "Commondity field mustn't empty");
        break;
      case 'emptyPacking':
        toastr.error(I18n.t('Notify.Error'), 'Please add Bars or Cartons before Create');
        break;
      default: {
        const data = this.getApiData();
        console.log(data);
        this.props.save(data);
        break;
      }
    }
  };

  render() {
    const { listOrders } = this.props;
    const total = this.total();
    const orderContainers = this.setOrderContainers(listOrders);
    return (
      <div>
        <ul className="content-header-title float-none pd-0-5 justify-content-space-between display-flex">
          <li>
            <p>
              {I18n.t('PageShiping.TotalQTY')}
              <span className="color-number medium mg-left-25 size-16">{total}</span>
            </p>
          </li>
          <li className="add-filter-top">
            <span className="cursor-pointer" onClick={() => this.save()}>
              <i className="fa fa-floppy-o" aria-hidden="true"></i>
              {I18n.t('PageShiping.SAVE')}
            </span>
          </li>
        </ul>
        {orderContainers}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  actions: selector.getActions(state),
  pklShips: selector.getPklShips(state),
  checkEdit: selector.checkEdit(state),
  listIds: selector.getIdOfPackings(state),
  listOrders: selector.getOrders(state),
  getOrderDetail: selector.fnGetOrderDetail(state),
  getStyles: selector.getStyles(state),
  getCutickets: selector.fnGetCutTickets(state),
  listActiveStyleIds: selector.getActiveStyleIds(state),
  total: selector.getTotal(state),
  getApiData: selector.fnGenApiData(state),
  success: selector.getSuccess(state),
  emptyCommodity: selector.getEmptyCommondity(state),
});

const mapDispatchToProps = dispatch => ({
  getListOrders(listIds) {
    dispatch(getListOrders(listIds));
  },
  resetData() {
    dispatch(resetData());
  },
  save(data) {
    dispatch(save(data));
  },
  changeStyleActive(orderId, styleId) {
    dispatch(changeStyleActive(orderId, styleId));
  },
  changeOrderActive(orderId, value) {
    dispatch(changeOrderActive(orderId, value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TotalQTYContainer));
