import React from "react";
import PropTypes from 'prop-types';
import DateTimeField from "react-datetime";
import _ from "lodash";
import * as R from 'ramda';
import moment from "moment";
import I18n from "i18n-js";

import Modal from "../forms/Modal";


const EditCutTicketModal = props => (
  <Modal {...props}>
    <div className="modal-dialog" role="document">
      <div className="modal-content">
        <form>
          <div className="modal-header">
            <a className="close" onClick={() => props.onClose()}>
              <span>&times;</span>
            </a>
            <h4 className="modal-title" id="myModalLabel">
              {props.title}
            </h4>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <div className="col-md-4 col-xs-12 text">
                <p>{I18n.t("PageOrder.CutTicket")}</p> <span className="color-red"> *</span>
              </div>
              <div className="col-md-8 col-xs-12 value">
                <input
                  type="text"
                  className="form-control"
                  value={props.cutTicket || ""}
                  onChange={evt => props.onChangeCutTicket(evt.target.value)}
                />
                {R.path(["cutTicket"], props.errors) && (
                  <div className="alert-warning">{R.path(["cutTicket"], props.errors)}</div>
                )}
              </div>
            </div>
            <div className="form-group">
              <div className="col-md-4 col-xs-12 text">
                <p>{I18n.t("PageOrder.Store")}</p> <span className="color-red"> *</span>
              </div>

              <div className="col-md-8 col-xs-12 value">
                <select
                  className="form-control"
                  value={props.selectedStore || ""}
                  onChange={evt => {
                    const { value } = evt.target;
                    const store = _.find(props.selectableStores, o => o.value === value);
                    props.onChangeSelectedStore(store);
                  }}
                >
                  <option value="" />
                  {_.map(props.selectableStores || [], store => (
                    <option key={R.path(['value'], store)} value={R.path(['value'], store)}>
                      {R.path(['label'], store)}
                    </option>
                  ))}
                </select>

                {R.path(["selectedStore"], props.errors) && (
                  <div className="alert-warning">{R.path(["selectedStore"], props.errors)}</div>
                )}
              </div>
            </div>
            <div className="form-group">
              <div className="col-md-4 col-xs-12 text">
                <p>{I18n.t("PageOrder.Warehouse")}</p> <span className="color-red"> *</span>
              </div>
              <div className="col-md-8 col-xs-12 value">
                <select
                  className="form-control"
                  value={props.selectedWarehouse || ""}
                  onChange={evt => {
                    const { value } = evt.target;
                    const warehouse = _.find(props.selectableWarehouses, o => o.value === value);
                    props.onChangeSelectedWarehouse(warehouse);
                  }}
                >
                  <option value="" />
                  {_.map(props.selectableWarehouses, wh => (
                    <option key={R.path(['value'], wh)} value={R.path(['value'], wh)}>
                      {R.path(['label'], wh)}
                    </option>
                  ))}
                </select>

                {R.path(["selectedWarehouse"], props.errors) && (
                  <div className="alert-warning">{R.path(["selectedWarehouse"], props.errors)}</div>
                )}
              </div>
            </div>

            <div className="form-group">
              <div className="col-md-4 col-xs-12 text">
                <p>{I18n.t("PageOrder.ShipMode")}</p>
              </div>
              <div className="col-md-8 col-xs-12 value">
                <select
                  className="form-control"
                  value={props.selectedShipMode || ""}
                  onChange={evt => {
                    const { value } = evt.target;
                    const shipMode = _.find(props.selectableShipModes, o => o.value === value);
                    props.onChangeSelectedShipMode(shipMode);
                  }}
                >
                  <option value="" />
                  {_.map(props.selectableShipModes, shipMode => (
                    <option key={R.path(['value'], shipMode)} value={R.path(['value'], shipMode)}>
                      {R.path(['label'], shipMode)}
                    </option>
                  ))}
                </select>
                {R.path(["selectedShipMode"], props.errors) && (
                  <div className="alert-warning">{R.path(["selectedShipMode"], props.errors)}</div>
                )}
              </div>
            </div>

            <div className="form-group">
              <div className="col-md-4 col-xs-12 text">
                <p>{I18n.t("PageOrder.MainLabel")}</p>
              </div>
              <div className="col-md-8 col-xs-12 value">
                <input
                  type="text"
                  className="form-control"
                  value={props.mainLabel || ""}
                  onChange={evt => props.onChangeMainLabel(evt.target.value)}
                />
              </div>
            </div>

            <div className="form-group">
              <div className="col-md-4 col-xs-12 text">
                <p>{I18n.t("PageOrder.IHDate")}</p>
              </div>
              <div className="col-md-8 col-xs-12 value">
                <DateTimeField
                  timeFormat={false}
                  dateFormat="MMMM DD, YYYY"
                  value={moment(props.ihDate)}
                  onChange={date =>
                    props.onChangeIhDate(date.format())
                  }
                  isValidDate={(currentDate, selectedDate) => moment(selectedDate).isSameOrAfter(moment(props.exFactory))}
                  showDisabledMonthNavigation={()=> true}
                />
                {R.path(["ihDate"], props.errors) && (
                  <div className="alert-warning">{R.path(["ihDate"], props.errors)}</div>
                )}
              </div>
            </div>

            <div className="form-group">
              <div className="col-md-4 col-xs-12 text">
                <p>{I18n.t("PageOrder.Note")}</p>
              </div>
              <div className="col-md-8 col-xs-12 value">
                <input
                  type="text"
                  className="form-control"
                  value={props.note || ""}
                  onChange={evt => props.onChangeNote(evt.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="bt-black"
              onClick={() => props.onClose()}
            >
              {I18n.t("Btn.CANCEL")}
            </button>
            <button
              type="button"
              className={`${!props.canSave ? 'btn btn-default' : ''} bt-orange mg-l-5`}
              disabled={!props.canSave}
              onClick={() => props.onSubmit()}
            >
              {props.isSubmiting ? I18n.t("Btn.SAVING") : I18n.t("Btn.SAVE")}
            </button>
          </div>
        </form>
      </div>
    </div>
  </Modal>
);

EditCutTicketModal.propTypes = {
  title: PropTypes.string.isRequired,
  cutTicket: PropTypes.string.isRequired,

  selectedStore: PropTypes.string.isRequired,
  selectableStores: PropTypes.arrayOf(PropTypes.any).isRequired,

  selectedWarehouse: PropTypes.string.isRequired,
  selectableWarehouses: PropTypes.arrayOf(PropTypes.any).isRequired,

  selectedShipMode: PropTypes.string.isRequired,
  selectableShipModes: PropTypes.arrayOf(PropTypes.any).isRequired,

  ihDate: PropTypes.string.isRequired,
  exFactory: PropTypes.string.isRequired,
  note: PropTypes.string.isRequired,
  canSave: PropTypes.bool.isRequired,
  errors: PropTypes.arrayOf(PropTypes.any).isRequired,
  isSubmiting: PropTypes.bool,

  onClose: PropTypes.func,
  onChangeCutTicket: PropTypes.func,
  onChangeSelectedStore: PropTypes.func,
  onChangeSelectedWarehouse: PropTypes.func,
  onChangeSelectedShipMode: PropTypes.func,
  onChangeMainLabel: PropTypes.func,
  onChangeIhDate: PropTypes.func,
  onChangeNote: PropTypes.func,
  onSubmit: PropTypes.func,
}

EditCutTicketModal.defaultProps = {
  isSubmiting: false,

  onClose: f => f,
  onChangeCutTicket: f => f,
  onChangeSelectedStore: f => f,
  onChangeSelectedWarehouse: f => f,
  onChangeSelectedShipMode: f => f,
  onChangeMainLabel: f => f,
  onChangeIhDate: f => f,
  onChangeNote: f => f,
  onSubmit: f => f,
}

export default EditCutTicketModal;
