/**
 * @author hienhc <hienhc@nexlesoft.com>
 * @create date 2018-08-30 11:48:02
*/

const C = "SHOW_PKL_PROD_LIST";

export default {
    /* Actions that will affect displaying list */
    FETCH_PKL_PROD_LIST: `${C}_FETCH_PKL_PROD_LIST`,
    CANCEL_FETCH_PKL_PROD_LIST: `${C}_CANCEL_FETCH_PKL_PROD_LIST`,
    CHANGE_PKL_PROD_LIST: `${C}_CHANGE_PKL_PROD_LIST`,

    /* Actions that will affect checked list */
    ADD_CHECKED_PKL_PROD: `${C}_ADD_CHECKED_PKL_PROD`,
    REMOVE_CHECKED_PKL_PROD: `${C}_REMOVE_CHECKED_PKL_PROD`,
    CLEAR_ALL_CHECKED_PKL_PRODS: `${C}_CLEAR_ALL_CHECKED_PKL_PRODS`,

    /* Actions that will affect pagination view */
    CHANGE_PAGING_CURRENT: `${C}_CHANGE_PAGING_CURRENT`,
    CHANGE_PAGING_TOTAL: `${C}_CHANGE_PAGING_TOTAL`,

    /* Actions that will affect filter */
    CHANGE_SELECTED_FILTER: `${C}_CHANGE_SELECTED_FILTER`,
    CHANGE_SEARCH_TEXT: `${C}_CHANGE_SEARCH_TEXT`,
    SEARCH_PKL_PROD_LIST: `${C}_SEARCH_PKL_PROD_LIST`,
    CANCEL_SEARCH_PKL_PROD_LIST: `${C}_SEARCH_PKL_PROD_LIST`,
    CHANGE_SEARCH_STATUS: `${C}_CHANGE_SEARCH_STATUS`,
    VIEW_CREATER: `${C}_VIEW_CREATER`,
}