import React from "react";
import moment from "moment";
import I18n from 'i18n-js';
import _ from 'lodash';
import PropTypes from 'prop-types';

import IC_NOTIFY from "../../stylesheets/images/ic-notify.png";

const age = function(a, b) {
  a.setHours(0,0,0,0)
  b.setHours(0,0,0,0)
  const daysLeft = moment(b).diff(moment(a), 'days') + 1
  return daysLeft > 0 ? daysLeft : 0
}

const OrderInfoBar = props => (
  <div className="md content-main-header">
    <ul className="content-main-header-top">
      <li className="content-main-date">
        <span className="top">{moment(props.orderDate).format("MMM DD")}</span>
        <span className="line">
          <span />
        </span>
        <span className="bottom">{moment(props.orderDate).format("YYYY")}</span>
      </li>
      <li className="content-main-title bold">
        {props.customer}
      </li>
      <li className="content-main-po">
        <div className="content-title-po">
          <span className="title mg-top-0 mg-bottom-0">
            {I18n.t("PageOrder.CustomerPO")}
          </span>
          <span className="divide mg-top-0 mg-bottom-0" />
          <span className="name bold mg-top-0 mg-bottom-0 mg-right-5">
            <span className="ellipsisContent">
              {_.truncate(props.customerPO, {length: 14})}
            </span>
          </span>
        </div>
        <div className="content-title-po">
          <span className="title mg-top-0 mg-bottom-0">
            {I18n.t("PageOrder.SaleOrder")}
          </span>
          <span className="number mg-top-0 mg-bottom-0 mg-right-5">
            {" "}
            <span className="bold">{props.internalPO}</span>
          </span>
        </div>
      </li>
      <li className="content-main-total">
        <span className="text">{I18n.t("PageOrder.G-Total")}</span>
        <span className="number">{props.gTotal}</span>
      </li>

      {props.showShipped && (
        <li className="content-main-shipped">
          <span className="text">{I18n.t("PageOrder.Shipped")}</span>
          <span className="number color-green">{props.totalShipped}</span>
        </li>
      )}

      <li className="content-main-factory">
        <span className="text">{I18n.t("PageOrder.Ex-Factory")}</span>
        <span className="date">
          {props.exFactory
            ? moment(props.exFactory).format("MMMM DD, YYYY")
            : ""}
        </span>
      </li>

      <li className="content-main-factory">
        <span className="text">{I18n.t("PageOrder.IHDate")}</span>
        <span className="date">
          {props.ihDate ? moment(props.ihDate).format("MMMM DD, YYYY") : ""}
        </span>
      </li>

      {props.showStartProduction &&
        (!props.startDate ? (
          <li className="content-main-factory">
            <span className="text mg-0">
              {I18n.t("PageProduction.StartProduction")}
            </span>
            <span
              className="icon mg-0 size-40 color-orange"
              onClick={() => props.startProductionClick()}
            >
              <i className="fa fa-youtube-play" aria-hidden="true" />
            </span>
          </li>
        ) : (
          <li className="content-main-factory">
            <span className="text mg-0">
              {I18n.t("PageProduction.DaysLeft")}
            </span>
            <span className="icon mg-0 days-left">
              <span className="bold size-40 color-orange">
                {!props.exFactory
                  ? age(new Date(), new Date(props.exFactory))
                  : 0}
              </span>
            </span>
          </li>
        ))}

      {props.showNotify && (
        <li 
          className={`btn bd-solid-left position-relative w-100 ${props.isNotifying? 'disabled': ''}`}
          onClick={() => (!props.isNotifying) ? props.sendNotify() : null}
        >
          <div className="content-main-add-ticket">
            <span className="icon">
              <img
                src={IC_NOTIFY}
                alt=""
              />
            </span>
            <span className="text">{!props.isNotifying ? I18n.t("PageOrder.SendNotify") : I18n.t("PageOrder.SendingNotify")}</span>
          </div>
        </li>
      )}
    </ul>
  </div>
);

OrderInfoBar.propTypes = {
  orderDate: PropTypes.string.isRequired,
  customer: PropTypes.string.isRequired,
  customerPO: PropTypes.string.isRequired,
  internalPO: PropTypes.string.isRequired,
  gTotal: PropTypes.number.isRequired,
  exFactory: PropTypes.string.isRequired,
  ihDate: PropTypes.string.isRequired,
  totalShipped: PropTypes.number,

  isNotifying: PropTypes.bool,

  startDate: PropTypes.string,
  showStartProduction: PropTypes.bool,

  showNotify: PropTypes.bool,
  showShipped: PropTypes.bool,

  sendNotify: PropTypes.func,
  startProductionClick: PropTypes.func,
};

OrderInfoBar.defaultProps = {

  startDate: null,
  showStartProduction: false,
  totalShipped: '0',
  isNotifying: false,

  showNotify: false,
  showShipped: false,

  sendNotify: f => f,
  startProductionClick: f => f,
};

export default OrderInfoBar;
