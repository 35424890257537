import React, { Component } from 'react';
import CreditDebitViewDetail from '../../../components/accounting/CreditDebit/CreditDebitViewDetail';
import I18n from 'i18n-js';
import { connect } from 'react-redux';
import payInfoApi from '../../../../api/payInfoApi';
import accountingApi from '../../../../api/accountingApi';
import moment from 'moment';
import {withRouter} from "react-router";
class PreviewCreditDebit extends Component {

    constructor (props) {
        super(props)
        this.state = {
            items: [],
            payInfo: {},
            summary: {}
        }
    }
    
    componentWillMount() {
        // this.props.loadData(this.props.router.route.match.params.payInfoId);
        payInfoApi.getPayInfo(this.props.router.route.match.params.payInfoId + "?populate=customer,createdBy,chargeBackInvs.cutTicketId").then(res => {
            if (res.success) {
                var payInfo = _.get(res, "data", {})
                accountingApi.listBillsOrContsByCustomer('Air', _.get(res, "data.customer._id", ""), true).then(res1 => {
                    var checkableBills = _.get(res1, "data", [])
                    var checkedBills = [];
                    let dataRequest = {};
                    dataRequest.chargeBack = true;
                    dataRequest.billNo = [];
                    dataRequest.billIds = [];
                    checkableBills.forEach(bill => {
                        if(payInfo && payInfo.chargeBackInvs && payInfo.chargeBackInvs.find(item => item.shipOutId == bill._id)) {
                            bill.checked = true;
                            dataRequest.billNo.push(bill.billNo)
                            dataRequest.billIds.push(bill._id)
                            checkedBills.push(bill)
                        }
                    })
                    this.setState({checkableBills: checkableBills})

                    dataRequest.shipMode ='Air'
                    dataRequest.customer =_.get(res, "data.customer._id", "");

                    let p = dataRequest;
                    p.cont = p.billNo;
                    p.ids = dataRequest.billIds || [];
                    accountingApi.getListShipOutByCustomer(p).then(res2 => {
                        let payload = res2.data
                        _.map(checkedBills, el => {
                            let billProp = el.billNo;
                            if(payload[billProp]) {
                              el.customerPOs = [];
                              let keys = Object.keys(payload[billProp])
                                _.forEach(keys, (property) => {
                                    let data = payload[billProp][property]
                                    if(data) {
                                        data = data.filter(item => {
                                            if(payInfo.chargeBackInvs) {
                                                console.log(payInfo.chargeBackInvs)
                                                let chargeBackInvoice = payInfo.chargeBackInvs.find(chargeBackI => chargeBackI.cutTicketId._id == item.cutTicketId)
                                                if(chargeBackInvoice) {
                                                    item.chargeBackInvoice = chargeBackInvoice
                                                    return true;
                                                }
                                            }
                                            return false;
                                        })
                                    }
                                    if(data && data.length > 0) {
                                        el.customerPOs.push({
                                            customerPO: property,
                                            data: data || []
                                          })
                                    }
                                })
                            }
                          })
                        let items = [];
                        checkedBills.forEach(bill => {
                            if(bill.customerPOs) {
                                bill.customerPOs.forEach(customerPO => {
                                    if(customerPO.data) {
                                        customerPO.data.forEach(elem => {
                                            console.log("elem.cutTicket ", elem.cutTicket);
                                            items.push({
                                                customerPO: customerPO.customerPO,
                                                billNo: bill.billNo,
                                                style: elem.style,
                                                cutTicket: elem.cutTicket,
                                                rate: elem.chargeBackInvoice.rate,
                                                cbm: elem.cbm.cbm,
                                                airFee: this.getAirFee(elem),
                                                feeInUSA: elem.chargeBackInvoice.feeInUSA,
                                                deduct: elem.chargeBackInvoice.deduct,
                                                debitAmount: this.getDebitAmount(elem),
                                                rateLM: elem.chargeBackInvoice.rateLM,
                                                amountPay: this.getAmountPay(elem),
                                                ctns: elem.chargeBackInvoice.ctns,
                                                quantity: elem.chargeBackInvoice.qty,
                                                chargeableWeight: elem.chargeBackInvoice.chargableWeight

                                            })
                                        })
                                    }
                                })
                            }
                           
                        })
                        this.setState({items: items});
                        let summary = {
                            cbm: _.sumBy(items, 'cbm'),
                            ctns: _.sumBy(items, 'ctns'),
                            quantity: _.sumBy(items, 'quantity'),
                            chargeableWeight: _.sumBy(items, 'chargeableWeight'),
                            airFee: _.sumBy(items, 'airFee'),
                            debitAmount: _.sumBy(items, 'debitAmount')
                        };
                        this.setState({summary: summary});

                    })

                });
                this.setState({
                    payInfo: payInfo
                  })
            }
          })
    }

    getDebitAmount(item) {
        return (item.chargeBackInvoice.rate || 0) * item.cbm.cw + (item.chargeBackInvoice.feeInUSA || 0) - (item.chargeBackInvoice.deduct || 0);
    }
  
    getAirFee(elem) {
        return (elem.chargeBackInvoice.rate || 0) * elem.cbm.cw;
    }
  
    getAmountPay(item) {
        return item.cbm.cw * (item.chargeBackInvoice.rateLM || 0);
    }
    
    componentDidMount() {
        // this.props.resetData()
    }

    componentWillReceiveProps(nextProps) {
        const {activeScreen} = nextProps;
        if(!activeScreen) {
            nextProps.router.history.goBack();
        }
    }

    render() {
        const { items,  payInfo, summary} = this.state;
        return (
            <CreditDebitViewDetail payInfo = {payInfo}  items = {items} summary={summary} {...this.props} type = "historyPreview"/>
        );
    }
}

const mapStateToProps = state => ({
    // invoiceDetail: selector.getInvoiceDetail(state),
    // items: selector.getListItems(state),
    // amount: selector.getAmount(state),
    // invoiceNo: selector.getInvoiceNo(state),
    // issuedDate: selector.getIssuedDate(state),
    // supplier: selector.getSupplier(state),
    // // amountINV: selector.getAmount(state),
    // paid: selector.getPaid(state),
    // activeScreen: selector.getActiveScreenStatus(state),
    // currencyTypeUSD: selector.getCurrencyTypeUSD(state),
    // currencyTypeVND: selector.getCurrencyTypeVND(state),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    // resetData() {
    //     dispatch(actions.resetData())
    // }
})

export default connect(mapStateToProps, mapDispatchToProps) (withRouter(PreviewCreditDebit));