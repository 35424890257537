import {getListItemDAL, addItemDAL, editItemDAL, getItemDAL, deleteItemDAL} from '../api/stockInApi';
import _ from 'lodash';

class stockInServices {

  constructor() {
  }

  getListItem (type, options = {}) {
    options.query && _.set(options, 'query', JSON.stringify(options['query']));
    options.sort && _.set(options, 'sort', JSON.stringify(options['sort']));
    return getListItemDAL(type, options);
  }

  getDetail (type, id, options = {}) {
    return getItemDAL(type, id, options);
  }

  deleteItem (type, id, body) {
    return deleteItemDAL(type, id, body);
  }

  editItem (type, id, data) {
    return editItemDAL(type, id, data);
  }

  addItem (type, data) {
    return addItemDAL(type, data);
  }
}

export default new stockInServices();
