import React from 'react';
import I18n from 'i18n-js';
import PropTypes from 'prop-types';
import SearchBar from '../../../common/components/SearchBar';

import icEyeIcon from '../../../../stylesheets/images/ic-eye.svg';
import CustomPagination from '../../../../components/common/datatables/CustomPagination';

const ShowHistoryList = props => {
    let style = {
        width: "100%"
    };

    const {currencyTypeUSD, currencyTypeVND} = props;
    
    return (
        <div className="view-accounts-payable-invoice-list">
            <div className="wrap-search-position top-s-70">
                <SearchBar
                    fields={props.listFilter}
                    selectedFilter={props.selectedFilter}
                    searchValue={props.searchValue || {}}
                    onChangeFilter={value => props.onChangeFilter(value)}
                    onChangeValue={(value, pos) => props.onChangeSearchValue(value, pos)}
                    onSearch={() => props.onSearch({...props.paramsRequest})}
                />
            </div>
            <ul className="content-header-title bg-white pd-0-15 box-shadow-1 mg-bottom-5">
                <li className="float-right">
                    <p>Amount
                        <span className="bold mg-left-25 size-12 mg-left-15">{currencyTypeUSD}{props.totalAmount}{currencyTypeVND}</span>
                    </p>
                </li>

                <li className="type-invoice-list float-left">
                    <span
                        className={props.currency == "USD" ? "cursor-pointer wrap active" : "cursor-pointer wrap"}
                        onClick={() => props.changeCurrency('USD')}
                    >
                        <span className="icon">
                            <span />
                        </span>
                        <span className="text">USD</span>
                    </span>

                    <span
                        className={props.currency == "VND" ? "cursor-pointer wrap active" : "cursor-pointer wrap"}
                        onClick={() => props.changeCurrency('VND')}
                    >
                        <span className="icon">
                            <span />
                        </span>
                        <span className="text">VND</span>
                    </span>
                </li>
            </ul>
            <table style={style} className="box-shadow bg-white global-table">
                <tbody>
                    <tr>
                        <th className="center">INV Request</th>
                        <th className="center">{I18n.t('ScreenAccountsPayable.InvoiceRef')}</th>
                        <th className="center">{I18n.t('ScreenAccountsPayable.InvoiceNo')}</th>
                        <th className="center m-width-105">{I18n.t('ScreenAccountsPayable.IssuedDate')}</th>
                        <th className="center">{I18n.t('ScreenAccountsPayable.CustomerPO')}</th>
                        <th className="center m-width-105">{I18n.t('ScreenAccountsPayable.DueDate')}</th>
                        <th className="center">{I18n.t('ScreenAccountsPayable.FinishedDate')}</th>
                        <th className="center">{I18n.t('ScreenAccountsPayable.Payee')}</th>
                        <th className="center">{I18n.t('ScreenAccountsPayable.Type')}</th>
                        <th className="center">{I18n.t('ScreenAccountsPayable.Amount')}</th>
                        <th className="center">{I18n.t('ScreenAccountsPayable.Actions')}</th>
                    </tr>
                    {props.listInvoiceHistory.map((item, index) => {
                        return (
                            <tr key={index}>
                                <td className="center">{item.invRequest}</td>
                                <td className="center">{item.invRef}</td>
                                <td className="center">{item.invNo}</td>
                                <td className="center">{item.issuedDate}</td>
                                <td className="center">{item.customerPOs.length > 0 ? (
                                    <div className="dropdown">
                                    <span className="dropdown-toggle button-text color-link" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        {item.customerPOs.length}
                                    </span>
                                    <div className="dropdown-menu">
                                    <table className="dropdown-table">
                                        <tbody>
                                        {item.customerPOs.map((p, index) => (
                                            <tr key={`customerPO_${index}`}>
                                                <td>{p}</td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                    <span className="button-ok center color-orange bold cursor-pointer">OK</span>
                                    </div>
                                    </div>) : '-'
                                }</td>
                                <td className="center">{item.dueDate}</td>
                                <td className="center">{item.finishedDate}</td>
                                <td className="center">{item.payee}</td>
                                <td className="center">{item.type}</td>
                                <td className="center">{currencyTypeUSD}{item.amount}{currencyTypeVND}</td>
                                <td className="center">
                                    <span className="mg-right-5">
                                        <img
                                            src={icEyeIcon}
                                            alt="View"
                                            className="cursor-pointer"
                                            onClick={() => props.view(item._id, item.typeInvoice)}
                                        />
                                    </span>
                                </td>
                            </tr>
                        )
                    })}
                    <tr>
                    </tr>
                </tbody>
            </table>
            <CustomPagination 
                items={props.pageTotal} 
                activePage={props.pageCurrent} 
                onSelect={page => {props.pageCurrent !== page && props.changePage({...props.paramsRequest, pageNumber: page}) }}
            />
        </div>
    );
}

ShowHistoryList.propTypes = {
    listInvoice: PropTypes.array,
    listFilter: PropTypes.array,
    listPaid: PropTypes.array,
    selectedFilter: PropTypes.string,
    searchValue: PropTypes.object,
    currentPage: PropTypes.number,
    totalPage: PropTypes.number,
    currency: PropTypes.string,

    openReceiptModal: PropTypes.func,
    openPreviewInvoiceModal: PropTypes.func,
    edit: PropTypes.func,
    deleteInvoice: PropTypes.func,
    export: PropTypes.func,
    showPaidPopup: PropTypes.func,
    viewInvoiceNo: PropTypes.func,
    changePage: PropTypes.func,
    onChangeFilter: PropTypes.func,
    onChangeSearchValue: PropTypes.func,
    changeCurrency: PropTypes.func,
    getListInvoice: PropTypes.func,
}
ShowHistoryList.defaultProps = {
    listInvoice: [],
    listFilter: [],
    listPaid: [],
    selectedFilter: '',
    searchValue: '',
    currentPage: 1,
    totalPage: 1,
    openReceiptModal: f => f,
    openPreviewInvoiceModal: f => f,
    edit: f => f,
    deleteInvoice: f => f,
    export: f => f,
    showPaidPopup: f => f,
    viewInvoiceNo: f => f,
    changePage: f => f,
}

export default ShowHistoryList;