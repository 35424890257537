import {DASHBOARD} from '../constants/actionTypes';
import initial from './initialState';

export default function (state = initial.dashboardState, action) {
  switch (action.type) {
    case DASHBOARD.GET_DASHBOARD:
      return {...state, overview: action.data.overview || {}, orders: Object.assign([], [...(action.data.orders || [])]), prods: action.data.prods || []};
    case DASHBOARD.FILTERED_DASHBOARD:
      return {...state, filters: Object.assign([], [...(action.data || [])])}
    case DASHBOARD.RELOAD:
      return {...state, reload: !state.reload};
    case DASHBOARD.CANCEL_LOADING:
      return {...state, loading: false};
    case DASHBOARD.LOADING:
      return {...state, loading: true}; 
    case DASHBOARD.TOTAL:
      return {...state, total: action.data}; 
      
    default:
      return state;
  }
}
