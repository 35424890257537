
import C from '../../../constants/customer-po/current/editCutTicketImages';
import {toastr} from 'react-redux-toastr';
import * as R from 'ramda';
import I18n from 'i18n-js';
import cutTicketServices from '../../../services/cutTicketServices';
import { listCutTickets } from './showOrderDetail';


export const showModal = cutTicket => dispatch => {
  dispatch({ type: C.SHOW_MODAL, payload: cutTicket });
}

export const hideModal = () => dispatch => {
  dispatch({ type: C.RESET_DATA });
}

export const addImage = file => dispatch => {
  if(file.type.indexOf('image') < 0){
      toastr.error('Error', I18n.t('ErrorMessages.FileNotImage'));
      return;
  }
  var FileSize = file.size / 1024 / 1024; // in MB
  if (FileSize > 2) {
    toastr.error('Error', I18n.t('ErrorMessages.FileSizeExceeds2MB'));
    return;
  } 
  const readerBase64 = new FileReader();
  readerBase64.onload = (eventBase64) => {
    const url = eventBase64.target.result;
    const filename = file.name;

    const payload = { id: filename, file, filename, url, }
    dispatch({ type: C.ADD_IMAGE, payload });
  };
  readerBase64.readAsDataURL(file);
}

export const removeImage = image => dispatch => {
  dispatch({ type: C.REMOVE_IMAGE, payload: image });
}

export const save = (id, body) => dispatch => {
  dispatch({ type: C.SUBMIT });
  cutTicketServices.editCutTicket(id, body).then(res => {
    dispatch({ type: C.CANCEL_SUBMIT });
    dispatch(listCutTickets(R.path(['data', 'cutTicket', 'styleId'], res)));
    dispatch({ type: C.RESET_DATA });
  }).catch(error => {
    dispatch({ type: C.CANCEL_SUBMIT });
    console.error(error);
  })
}
