import _ from 'lodash';
import {PACKING_STANDARD} from '../constants/actionTypes';
import initial from './initialState';

export default function (state = initial.packingStandardState, action) {
  switch (action.type) {
    case PACKING_STANDARD.GET_LIST_SUCCESS:
      return {...state, items: action.data.items || [], count: action.data.count || 0};
    case PACKING_STANDARD.RELOAD:
      return {...state, reload: !state.reload};
    case PACKING_STANDARD.GET_DETAIL_SUCCESS:
      return {...state, detail: action.data};
    case PACKING_STANDARD.RESET_DETAIL:
      return {...state, detail: {}};
    case PACKING_STANDARD.GET_KEY_SUCCESS: {
      let properties = _.cloneDeep(state.properties);
      if (!_.isEmpty(action.data) && action.data.key) {
        _.set(properties, action.data.key, action.data.values || []);
      }
      return {...state, properties: properties};
    }
    default:
      return state;
  }
}
