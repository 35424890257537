import C from "../../../constants/accounting/payable/createInvoiceContractor";
import accountingServices from "../../../services/accountingServices";
import _ from 'lodash';

export const changeVATPerMonth = checked => dispatch => {
  dispatch({ type: C.CHANGE_VAT_PER_MOUNTH, payload: checked });
};

export const changeIssuedDate = value => dispatch => {
  dispatch({ type: C.CHANGE_ISSUED_DATE, payload: value });
};

export const changeInvoiceNo = value => dispatch => {
  dispatch({ type: C.CHANGE_INVOICE_NO, payload: value });
}

export const changeDueDate = value => dispatch => {
  dispatch({ type: C.CHANGE_DUE_DATE, payload: value });
};

/**
 *
 * @param {string} type DOMESTIC" || "OVERSEA"
 */
export const listContractor = type => dispatch => {
  accountingServices.getListContractor({}).then(
    res => {
      dispatch({ type: C.CHANGE_LIST_CONTRACTORS, payload: res.data.items });
      const [firstItem] = res.data.items;
      if (firstItem) {
        dispatch(changeSelectedContractor(firstItem._id));
        dispatch(getListCustomerPO({ contractorId: firstItem._id }))
        dispatch(getListCreditDebit({ contractor: firstItem._id }))
      }
    },
    error => {
      console.log(JSON.stringify(error));
    }
  );
};

export const changeChecked = (item, checked) => dispatch => {
  dispatch({type: C.CHANGE_SELECTED_CUSTOMER_PO, payload: {item, checked}});
};

export const getListCustomerPO = query => dispatch => {
  accountingServices.getListCustomerPO(query).then(
    res => {
      dispatch({ type: C.CHANGE_LIST_CUSTOMER_PO, payload: res.data.items });
    },
    error => {
      console.log(JSON.stringify(error));
    }
  );
};

export const getListCreditDebit = params => dispatch => {
  // debugger
  accountingServices.getListCreditDebit(params).then(
    res => {
      dispatch({ type: C.CHANGE_LIST_CREDIT_DEBITS, payload: _.get(res, 'data', []) });
    },
    error => {
      console.log(JSON.stringify(error));
    }
  );
};

export const changeSelectedContractor = data => dispatch => {
  dispatch({ type: C.CHANGE_SELECTED_CONTRACTOR, payload: data });
  
  if (data) {
    dispatch(getListCustomerPO({ contractorId: data }))
    dispatch(getListCreditDebit({ contractor: data }))
    dispatch(changeSelectedCredit(null))
  }
};

export const changeSelectedCredit = item => dispatch => {
  dispatch({ type: C.CHANGE_SELECTED_CREDIT_DEBIT, payload: item });
};

export const changeSelectedConvertCurrency = item => dispatch => {
  dispatch({ type: C.CHANGE_SELECTED_CURRENCY, payload: item });
};

export const changeConvertRate = value => dispatch => {
  dispatch({ type: C.CHANGE_CONVERT_RATE, payload: value });
};

export const changeSearchText = value => dispatch => {
  dispatch({ type: C.CHANGE_SEARCH_TEXT, payload: value });
};

export const changeCustomerPOPrice = value => dispatch => {
  dispatch({ type: C.CHANGE_CUSTOMER_PO_PRICE, payload: value });
};

export const changeStylePrice = value => dispatch => {
  dispatch({ type: C.CHANGE_STYLE_PRICE, payload: value });
};

export const changeColorPrice = value => dispatch => {
  dispatch({ type: C.CHANGE_COLOR_PRICE, payload: value });
};

//--------- main VAT ---------//
export const changeMainVATNo = value => dispatch => {
  dispatch({ type: C.CHANGE_MAIN_VAT_NO, payload: value });
};

export const changeMainVATTotalAmount = value => dispatch => {
  dispatch({ type: C.CHANGE_MAIN_VAT_TOTAL_AMOUNT, payload: value });
};

export const changeMainVATDate = value => dispatch => {
  dispatch({ type: C.CHANGE_MAIN_VAT_DATE, payload: value });
};

export const changeMainVATTaxRate = value => dispatch => {
  dispatch({ type: C.CHANGE_MAIN_VAT_TAX_RATE, payload: value });
};

export const changeMainVATNote = value => dispatch => {
  dispatch({ type: C.CHANGE_MAIN_VAT_NOTE, payload: value });
};

// export const changeCheckedPurchaseOrder = (id, checked) => dispatch => {
//   dispatch({ type: C.TOGLE_PURCHASE_ORDERS, payload: id });
// };

// export const toggleActivePurchaseOrder = id => dispatch => {
//   dispatch({ type: C.TOGLE_ACTIVE_PURCHASE_ORDERS, payload: id });
// };

//--------- sub VAT ---------//
export const changeSubVATNo = (_id, value) => dispatch => {
  dispatch({ type: C.CHANGE_SUB_VAT_NO, payload: {_id, value }});
};

export const changeSubVATDate = (_id, value) => dispatch => {
  dispatch({ type: C.CHANGE_SUB_VAT_DATE, payload: {_id, value}});
};

export const changeSubTaxRate = (_id, value) => dispatch => {
  dispatch({ type: C.CHANGE_SUB_VAT_TAX_RATE, payload: {_id, value}});
};

export const changeSubVATNote = (_id, value) => dispatch => {
  dispatch({ type: C.CHANGE_SUB_VAT_NOTE, payload: {_id, value}});
};
export const changeSubVATTotalAmount = (_id, value) => dispatch => {
  dispatch({ type: C.CHANGE_SUB_VAT_TOTAL_AMOUNT, payload: {_id, value}});
};

export const resetData = () => dispatch => {
  dispatch({ type: C.RESET_DATA });
};

// export const changeFactor = (value) => dispatch => {
//   dispatch({ type: C.CHANGE_FACTOR, payload: value})
// }

export const createContractorInvoice = (data) => dispatch => {
  accountingServices.createInvoice(data).then(res => {
    if(res.success) {
      dispatch({ type: 'CREATE_PAYABLE_INVOICE_CONTRACTOR_SUCCESS', payload: true})
    }
  }).catch(error => {
    console.error(error)
  })
}

export const activeScreenPreview = () => dispatch => {
  dispatch({type: C.ACTIVE_SCREEN_STATUS, payload: true})
}

// export const changeSupplierType = (value) => dispatch => {
//   dispatch({ type: C.CHANGE_TYPE_SUPPLIERS, payload: value})
// }