const C = "CREATE_INVOICE_IMPEMP_CONTAINER";

export default {
    CHANGE_LIST_FORWARDER: `${C}_CHANGE_LIST_FORWARDER`,
    CHANGE_INVOICE_INFO: `${C}_CHANGE_INVOICE_INFO`,
    CHANGE_LIST_CONTAINER: `${C}_CHANGE_LIST_CONTAINER`,
    CHANGE_CHECKED_LIST: `${C}_CHANGE_CHECKED_LIST`,
    CHANGE_VAT_VALUE: `${C}_CHANGE_VAT_VALUE`,
    SAVE_CHANGE_DETAIL_CONTAINER_VALUES: `${C}_SAVE_CHANGE_DETAIL_CONTAINER_VALUES`,
    RESET_CHECKED_LIST: `${C}_RESET_CHECKED_LIST`,
    CHANGE_EMPTY_VAT_VALUE: `${C}_CHANGE_EMPTY_VAT_VALUE`,
    CHANGE_SEARCH_CONTAINER_VALUE: `${C}_CHANGE_SEARCH_CONTAINER_VALUE`,
    CHANGE_TEMP_DATA: `${C}_CHANGE_TEMP_DATA`,
    CHANGE_DETAIL_CONTAINER_VALUE: `${C}_CHANGE_DETAIL_CONTAINER_VALUE`,
    CREATE_INVOICE_SUCCESS: `${C}_CREATE_INVOICE_SUCCESS`,
    RESET_DATA: `${C}_RESET_DATA`,
    ACTIVE_SCREEN_STATUS: `${C}_ACTIVE_SCREEN_STATUS`
}