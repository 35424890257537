import { SYSTEM_SETTINGS } from '../constants/actionTypes';
import initial from './initialState';

export default function (state = initial.systemSettingsState, action) {
  switch (action.type) {
    case SYSTEM_SETTINGS.GET_LIST_SUCCESS:
      return { ...state, items: action.data || {} };
    case SYSTEM_SETTINGS.GET_LIST_NOTIFICATIONS_SUCCESS:
      return { ...state, notifications: action.data.notifications || {} };
    case SYSTEM_SETTINGS.RELOAD:
      return { ...state, reload: !state.reload };
    default:
      return state;
  }
}
