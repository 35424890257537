import {combineReducers} from 'redux';
import C from '../../../constants/accounting/receivable/previewInvoice';

/**
 * Store helper information to check whether invoice list is fetching...
 */
const fetchingDetailPayableInvoices = (state = false, action) => {
    const { type } = action;
    switch (type) {
        // TODO. handle related actions
        default:
            return state;
    }
}

const invoiceDetail = (state = null, action) => {
    const { type, payload } = action;
    switch (type) {
        case C.CHANGE_INVOICE_DETAIL:
            return payload;
        // TODO. handle related actions
        default:
            return state;
    }
}

export const previewReceivableInvoice = combineReducers({
    fetchingDetailPayableInvoices,
    invoiceDetail
});
