import { getDashboard } from '../api/dashboardApi';


class dashboardServices {

  constructor() {
  }

  getDashboard(options = {}) {
    return getDashboard(options);
  }
  
  static attachUserToMessage(loginUser, peerUser, messages) {
    let processedData;
    // processedData = messages.map((value) => {
    //   let message = Object.assign(new chatMessage(), value);
    //   if (!message.user) {
    //     if (messageUtils.removePrefixAndSuffix(message.from) === loginUser.xmppId)
    //       message.user = loginUser;
    //     else if (messageUtils.removePrefixAndSuffix(message.to) === peerUser.xmppId)
    //       message.user = peerUser;
    //   }
    //   return message;
    // });
    return processedData;
  }
}

export default new dashboardServices();
