import React, { Component } from 'react';
import { connect } from 'react-redux';
//Constants
import { withRouter } from 'react-router'
import { Switch, Route} from "react-router-dom";
import * as PATH from "../../../../../constants/routeConstants";
import I18n from 'i18n-js';
import { toastr } from 'react-redux-toastr';
import { SHIP_OUT } from '../../../../../constants/routeConstants';
//Components
import CustomerINV from '../../../../components/shipping/subViews/customerINV';
import CutTickedContainer from './CutTickedContainerCreateShipment';
import TransportFee from '../../../../components/shipping/subViews/transportFee';
import ChooseStyle from '../../../../components/shipping/subViews/chooseStyle';
import BarTitle from '../../../../components/shipping/subViews/barTitle';
//Actions
import {
    getStandard, changeOrderActive,
    changeStyleActive, save, resetData,
    changeTransportFee, changeShipmentInfo,
    changeUnitPrice, getListForwarder, addForwarderIntoList
} from '../../../../../actions/ship-out/createShipmentActions';
import { getPklShipDetailView, activeScreenEditPklShip } from '../../../../../actions/ship-out/editPklShipActions';
import {resetCheckListAtPklShip} from '../../../../../actions/ship-out/showPklShipListActions';
//selectors
import * as selector from '../../../../../selectors/ship-out/createShipment';
import ViewPklShipDetailContainer from "./EditinTabPKLShipContainer";
import shipOutApi from "../../../../../api/shipOutApi";
import C from "../../../../../constants/ship-out/createShipment";

class CreateShipmentContainer extends Component {

    constructor(props) {
        super(props);

    }

    componentWillMount() {
        this.getStandard();
        this.props.getListForwarder();
    }

    componentWillReceiveProps(nextProps) {
        const { success, activeScreen } = nextProps;
        if (success) {
            nextProps.resetData()
            nextProps.resetCheckListAtPklShip()
            nextProps.router.history.push(SHIP_OUT.PKL_SHIP.URL)
            toastr.success(I18n.t('Notify.Success'), I18n.t('Notify.CreateShipmentSuccess'))
        }
        if(!activeScreen) {
            nextProps.router.history.push(SHIP_OUT.PKL_SHIP.URL)
        }
    }

    componentDidMount() {
        // this.props.resetData()
    }

    getStandard = () => {
        this.props.getStandard();
    }

    showDetailPklShip = (id) => {
        this.props.changePklShipDetail(id)
        this.props.activeScreenViewDetail()
        this.props.router.history.push(SHIP_OUT.PKL_SHIP.CREATE_DETAIL)
    }

    setCustomerINVLinks = (customerINVs) => {
        return (customerINVs || []).map((customerINV, index) => {
            if (customerINVs.length == index + 1) {
                return (<span key={index}><a onClick={() => this.showDetailPklShip(customerINV.pklShipId)} className="color-link">{customerINV.customerInv}</a></span>);
            }
            else {
                return (<span key={index}><a onClick={() => this.showDetailPklShip(customerINV.pklShipId)} className="color-link">{customerINV.customerInv}</a>,&nbsp;</span>);
            }
        });
    }

    // setCustomerPOContainers = (orders)=>{
    //     return (orders || []).map((order, index)=>{
    //         return (<CustomerPOContainer key={index} order={order}/>);
    //     });
    // }

    changeOrderActive = (value, orderId) => {
        this.props.changeOrderActive(value, orderId)
    }

    changeStyleActive = (orderId, styleId) => {
        this.props.changeStyleActive(orderId, styleId)
    }

    changeTransportFee = (key, value) => {
        this.props.changeTransportFee(key, value)
    }

    changeShipmentInfo = (key, value) => {
        this.props.changeShipmentInfo(key, value)
    }

    changeUnitPrice = (orderId, styleId, cutTicketId, colorId, value) => {
        this.props.changeUnitPrice(orderId, styleId, cutTicketId, colorId, value)
    }

    save = () => {
        const { dataApi, emptyField } = this.props;
        switch (emptyField) {
            case 'customINV':
                toastr.error(I18n.t('Notify.Error'), "Customs INV field mustn't empty");
                break;
            case 'shipMode':
                toastr.error(I18n.t('Notify.Error'), "Ship Mode field mustn't empty");
                break;
            case 'cont':
                toastr.error(I18n.t('Notify.Error'), "Cont field mustn't empty");
                break;
            case 'seal':
                toastr.error(I18n.t('Notify.Error'), "Seal field mustn't empty");
                break;
            case 'contType':
                toastr.error(I18n.t('Notify.Error'), "Cont field type mustn't empty");
                break;
            case 'declarationNo':
                toastr.error(I18n.t('Notify.Error'), "Declaration No field mustn't empty");
                break;
            case 'forwarder':
                toastr.error(I18n.t('Notify.Error'), "Forwarder field mustn't empty");
                break;
            case 'billNo':
                toastr.error(I18n.t('Notify.Error'), "Bill No mustn't empty");
                break;
            default:
                // shipOutApi.findBillNo(dataApi.billNo).then(res => {
                //     if(res.data.length > 0) {
                //         toastr.error(I18n.t('Notify.Error'), "Bill No already exists");
                //     }else{
                        this.props.save(dataApi);
                    // }
                // });
                break;
        }
    }
    setOrderContainers = (orders, transportFee) => {

        return (orders || []).map((order, index) => {
            const { getStyles, listActiveStyleIds, getListData, total } = this.props
            let styles = [];
            // const cutTicketContainers = this.setCutTicketContainers(order._id);
            if (order.active) {
                styles = getStyles(order._id)
            }
            const activeStyle = styles.find(item => item.active == true)
            return (
                <div key={index} className="mg-bottom-10">
                    <BarTitle classActive={order.active ? "rotate" : ""} id={order._id} type='customer_po' name={order.name} commodity={order.commodity} active={order.active} changeOrderActive={this.changeOrderActive} />
                    {order.active ?
                        <div>
                            <ChooseStyle removeIcon={false} orderId={order._id} styles={styles} changeStyleActive={this.changeStyleActive} />
                            <div>
                                <TransportFee type={'view'} total={total} styleQuantity={activeStyle ? activeStyle.quantity : 0} transportFee={transportFee} />
                            </div>
                            <CutTickedContainer orderId={order._id} styleId={listActiveStyleIds[order._id]} changeUnitPrice={this.changeUnitPrice} getListData={getListData} />
                        </div>
                        : null}

                </div>
            )
        })
    }

    render() {

        const { customerINVs, listOrders, total, transportFee, shipMode, contType, getShipAgencies, noDateDelivered, listForwarder } = this.props;
        const shipmentInfo = this.props.shipmentInfo || {};
        const shipAgencies = shipmentInfo && getShipAgencies(shipmentInfo.shipMode);
        const customerINVLinks = this.setCustomerINVLinks(customerINVs);
        const customerPOContainers = this.setOrderContainers(listOrders, transportFee);
        return (
            <Switch>
                <Route path = {PATH.SHIP_OUT.PKL_SHIP.CREATE_DETAIL} render = {() => (<ViewPklShipDetailContainer/>)}/>
                <Route path = {PATH.SHIP_OUT.PKL_SHIP.CREATE} render = {() => (
                    <div>
                        {/* <HeaderForSubView type='CreateShipment' turnBackURL={SHIP_OUT.PKL_SHIP.URL} {...this.props}/> */}
                        <div className="pd-0-5">
                            <ul className="content-header-title float-none pd-0-5 justify-content-space-between display-flex">
                                {customerINVLinks &&
                                <li>
                                    <p>{I18n.t('PageShiping.CustomerINV')}
                                        <span className="color-link medium mg-left-25 size-16">{customerINVLinks}</span>
                                    </p>
                                </li>
                                }
                                <li>
                                    <p>{I18n.t('PageShiping.Total')}
                                        <span className="color-number medium mg-left-25 size-16">{total}</span>
                                    </p>
                                </li>
                                <li className="add-filter-top">
                            <span className="cursor-pointer" onClick={() => this.save()}>
                                <i className="fa fa-floppy-o" aria-hidden="true"></i>{I18n.t("PageShiping.SAVE")}
                            </span>
                                </li>
                            </ul>
                            <CustomerINV
                                noDateDelivered={noDateDelivered}
                                shipMode={shipMode}
                                contType={contType}
                                shipmentInfo={shipmentInfo}
                                shipAgencies={shipAgencies}
                                changeShipmentInfo={this.changeShipmentInfo}
                                listForwarder = {listForwarder}
                            />
                            <TransportFee
                                type={'input'}
                                total={total}
                                transportFee={transportFee}
                                changeTransportFee={this.changeTransportFee}
                            />
                            {customerPOContainers}
                        </div>
                    </div>
                )} />
            </Switch>

        );
    }
}

const mapStateToProps = state => ({
    customerINVs: selector.getCustomerINV(state),
    getPklShipDetail: selector.fnGetPklShipDetail(state),
    listOrders: selector.getOrders(state),
    getStyles: selector.fnGetStyles(state),
    listActiveStyleIds: selector.getActiveStyleIds(state),
    total: selector.getTotal(state),
    dataApi: selector.fnGenApiData(state),
    success: selector.getSuccess(state),
    transportFee: selector.getTransportFee(state),
    shipMode: selector.getShipMode(state),
    shipmentInfo: selector.getShipmentInfo(state),
    contType: selector.getContType(state),
    getShipAgencies: selector.fnGetShipAgencies(state),
    getListData: selector.fnGetListData(state),
    emptyField: selector.getEmptyField(state),
    listForwarder: selector.getListForwarder(state),
    activeScreen: selector.getActiveScreenCreateShipment(state),
})

const mapDispatchToProps = dispatch => ({
    getStandard() {
        dispatch(getStandard())
    },
    changePklShipDetail(data) {
        dispatch(getPklShipDetailView(data))
    },
    changeOrderActive(value, orderId) {
        dispatch(changeOrderActive(value, orderId))
    },
    changeStyleActive(orderId, styleId) {
        dispatch(changeStyleActive(orderId, styleId))
    },
    save(data) {
        dispatch(save(data))
    },
    resetData() {
        dispatch(resetData())
    },
    changeTransportFee(key, value) {
        dispatch(changeTransportFee(key, value))
    },
    changeShipmentInfo(key, value) {
        if(key == 'forwarder') {
            dispatch(addForwarderIntoList(value))
        }
        dispatch(changeShipmentInfo(key, value))
    },
    changeUnitPrice(orderId, styleId, cutTicketId, colorId, value) {
        dispatch(changeUnitPrice(orderId, styleId, cutTicketId, colorId, value))
    },
    getListForwarder() {
        dispatch(getListForwarder())
    },
    activeScreenViewDetail() {
        dispatch(activeScreenEditPklShip())
    },
    resetCheckListAtPklShip() {
        dispatch(resetCheckListAtPklShip())
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CreateShipmentContainer));