import { combineReducers } from 'redux';
import C from '../../../constants/accounting/payable/editInvoiceContractor';

const invoiceDetail = (state = null, action) => {
    const { type, payload } = action;
    let dic = _.cloneDeep(state);
    switch (type) {
        case C.CHANGE_INVOICE_DETAIL:
            return { ...payload, receipt: { isEdit: false } };
        case C.CHANGE_CONTRACTOR_VALUE:
            dic.invoiceDetail.contractor.name = payload;
            return dic;
        case C.CHANGE_INVOICE_NO_VALUE:
            dic.invNo = payload;
            return dic;
        case C.CHANGE_ISSUED_DATE_VALUE:
            dic.issuedDate = payload;
            return dic;
        case C.CHANGE_DUE_DATE_VALUE:
            dic.dueDate = payload;
            return dic;
        case C.SUBMIT_FILES:
            dic.files = payload;
            return dic;
        case C.SUBMIT_RECEIPT:
            dic.receipt = { ...payload, isEdit: true };
            return dic;
        case C.CHANGE_SELECTED_CURRENCY:
            dic.invoiceDetail.convertType = payload;
            return dic;
        case C.CHANGE_CONVERT_RATE:
            dic.invoiceDetail.convertRate = payload;
            return dic;
        case C.CHANGE_MAIN_VAT_NO:
            dic.invoiceDetail.vat.vatNo = payload;
            return dic;
        case C.CHANGE_MAIN_VAT_DATE:
            dic.invoiceDetail.vat.vatDate = payload;
            return dic;
        case C.CHANGE_MAIN_TAX_RATE:
            dic.invoiceDetail.vat.taxRate = payload;
            return dic;
        case C.CHANGE_MAIN_TOTAL_AMOUNT:
            dic.invoiceDetail.vat.totalAmount = payload;
            return dic;
        case C.RESET_DATA_ALL:
            return null;
        default:
            return state;
    }
}
/**
 * Store input information for selected contractor field
 */
const contractor = (state = null, action) => {
    const { payload, type } = action;
    switch (type) {
        case C.CHANGE_CONST_CONTRACTOR_VALUE:
            return payload;
        default:
            return state;
    }
}

const selectableCreditDebits = (state = [], action) => {
    const { payload, type } = action;
    switch (type) {
      case C.CHANGE_SELECTABLE_CREDIT_DEBITS:
        return payload;
      default:
        return state;
    }
  }

/**
 * Store input information for selected credit debit note field
 */
const selectedCreditDebit = (state = null, action) => {
    const { payload, type } = action;
    switch (type) {
      case C.CHANGE_SELECTED_CREDIT_DEBIT_VALUE:
        return payload || {};
      // TODO. handle related actions
      default:
        return state;
    }
  }


/**
 * Store helper information to check whether selectable credit debits is fetching from api
 */
const fetchingSelectableCreditDebits = (state = false, action) => {
    const { payload, type } = action;
    switch (type) {
        // TODO. handle related actions
        default:
            return state;
    }
}

const customerPOs = (state = [], action) => {
    const { payload, type } = action;
    let dic = _.cloneDeep(state), _id, checked, active, value, cusId, styleId, cutId, colorId;
    switch (type) {
        case C.CHANGE_LIST_CUSTOMER_PO:
            return payload.map(item => ({ ...item, checked: true, active: true }));
        case C.CHANGE_CHECKED_CUSTOMER_PO:
            _id = payload._id;
            checked = payload.checked;
            dic.map(item => item._id === _id ? item.checked = checked : null)
            return dic;
        case C.CHANGE_TOGGLE_ACTIVE_CUSTOMER_PO:
            _id = payload._id;
            active = payload.active;
            dic.map(item => item._id === _id ? item.active = active : null)
            return dic;
        case C.CHANGE_CUSTOMER_PO_PRICE:
            cusId = payload.id;
            value = payload.value;
            dic.map(c => {
                if (c._id == cusId) {
                    c.price = value
                    c.styles.map(s => {
                        s.price = value
                        s.cutTickets.map(cut => cut.colors.map(cl => cl.price = value))
                    })
                }

            })
            return dic;
        case C.CHANGE_STYLE_PRICE:
            cusId = payload.cusId;
            styleId = payload.styleId;
            value = payload.value;
            dic.map(c => {
                if (c._id == cusId) {
                    c.price = 0;
                    c.styles.map(s => {
                        if (s._id == styleId) {
                            s.price = value
                            s.cutTickets.map(cut => cut.colors.map(cl => cl.price = value))
                        }
                    })
                }

            })
            return dic;
        case C.CHANGE_COLOR_PRICE:
            cusId = payload.cusId;
            styleId = payload.styleId;
            cutId = payload.cutId;
            colorId = payload.colorId;
            value = payload.value;
            dic.map(c => {
                if (c._id == cusId) {
                    c.price = 0;
                    c.styles.map(s => {
                        if (s._id == styleId) {
                            s.price = 0;
                            s.cutTickets.map(cut => {
                                if (cut._id == cutId)
                                    cut.colors.map(cl => {
                                        if (cl._id == colorId) cl.price = value;
                                    })
                            })
                        }
                    })
                }
            })
            return dic;
        case C.CHANGE_SUB_VAT_NO:
            _.forEach(dic, (el) => {
                if (el._id == payload._id) {
                    if (el.vat) {
                        el.vat.vatNo = payload.value;
                    } else {
                        el.vat = { vatNo: payload.value }
                    }
                }
            })
            return dic;
        case C.CHANGE_SUB_VAT_DATE:
            _.forEach(dic, (el) => {
                if (el._id == payload._id) {
                    if (el.vat) {
                        el.vat.vatDate = payload.value;
                    } else {
                        el.vat = { vatDate: payload.value }
                    }
                }
            })
            return dic;
        case C.CHANGE_SUB_TAX_RATE:
            _.forEach(dic, (el) => {
                if (el._id == payload._id) {
                    if (el.vat) {
                        el.vat.taxRate = payload.value;
                    } else {
                        el.vat = { taxRate: payload.value }
                    }
                }
            })
            return dic;
        case C.CHANGE_SUB_VAT_NOTE:
            _.forEach(dic, (el) => {
                if (el._id == payload._id) {
                    if (el.vat) {
                        el.vat.note = payload.value;
                    } else {
                        el.vat = { note: payload.value }
                    }
                }
            })
            return dic;
        case C.CHANGE_SUB_TOTAL_AMOUNT:
            _.forEach(dic, (el) => {
                if (el._id == payload._id) {
                    if (el.vat) {
                        el.vat.totalAmount = payload.value;
                    } else {
                        el.vat = { totalAmount: payload.value }
                    }
                }
            })
            return dic;
        default:
            return state;
    }
}

/**
 * Store a list of checked customer pi
 */
const checkedCustomerPOs = (state = [], action) => {
    const { payload, type } = action;
    switch (type) {
        // TODO. handle related actions
        default:
            return state;
    }
}

/**
 * Store a list of selectable convert currencies
 */
const selectableConvertCurrencies = (state = ["USD", "VND"], action) => {
    const { payload, type } = action;
    switch (type) {
        case C.CHANGE_CONVERT_CURRENCY:
            return payload;
        // TODO. handle related actions
        default:
            return state;
    }
}

/**
 * Store input information for selected convert currency field
 */
const selectedConvertCurrency = (state = null, action) => {
    const { payload, type } = action;
    switch (type) {
        // TODO. handle related actions
        default:
            return state;
    }
}

/**
 * Store input information for convert rate field
 */
const convertRate = (state = null, action) => {
    const { payload, type } = action;
    switch (type) {
        // TODO. handle related actions
        default:
            return state;
    }
}



/**
 * Store input information for pricing fields
 * @param state {[poId]: {price, styles: {[styleId]: {price, cutTickets: {[cutId]: {[color]: {price}}}}}}}
 */
const pricingInputData = (state = {}, action) => {
    const { payload, type } = action;
    switch (type) {
        // TODO. handle related actions
        default:
            return state;
    }
}

/**
 * Store a payment type information
 * @return state ['cash', 'transfer']
 */
const paymentType = (state = 'cash', action) => {
    const { payload, type } = action;
    switch (type) {
        case C.CHANGE_PAYMENT_TYPE:
            return payload;
        default:
            return state;
    }
}

const payAmount = (state = 0, action) => {
    const { type, payload } = action;
    switch (type) {
        case C.CHANGE_PAY_AMOUNT_VALUE:
            return payload;
        case C.RESET_DATA_ALL:
            return 0;
        default:
            return state;
    }
}

const transferFiles = (state = [], action) => {
    const { type, payload } = action;
    switch (type) {
        case C.COPY_FILES_TO_RAW_DATA:
            return payload;
        case C.ADD_TRANSFER_FILE: {
            const stateClone = _.cloneDeep(state);
            stateClone.push(payload);
            return stateClone;
        }
        case C.REMOVE_TRANSFER_FILE:
            return state.filter(file => file.id !== payload);
        case C.EMPTY_FILES_IN_RAW_DATA:
            return [];
        case C.RESET_DATA_ALL:
            return [];
        default:
            return state;
    }
}

const receipt = (state = {}, actions) => {
    const { type, payload } = actions;
    switch (type) {
        case C.CREATE_RECEIPT:
            return payload;
        case C.CHANGE_RECEIPT_VALUE:
            const { key, value } = payload;
            let dic = _.cloneDeep(state);
            dic[key] = value;
            return dic;
        case C.EMPTY_RECEIPT:
            return {}
        case C.RESET_DATA_ALL:
            return {};
        default:
            return state;
    }
}

const success = (state = false, action) => {
    const { type, payload } = action;
    switch (type) {
        case C.SAVE_SUCCESS:
            return payload;
        case C.RESET_DATA_ALL:
            return false;
        default:
            return state;
    }
}

const activeScreen = (state = false, action) => {
    const {type, payload} = action;
    switch(type) {
        case C.ACTIVE_SCREEN_STATUS:
            return payload;
        default:
            return state;
    }
}

const editInvoiceContractor = combineReducers({
    invoiceDetail,
    contractor,
    paymentType,
    payAmount,
    fetchingSelectableCreditDebits,
    selectableCreditDebits,
    selectedCreditDebit,

    customerPOs,
    checkedCustomerPOs,

    selectableConvertCurrencies,
    selectedConvertCurrency,
    convertRate,

    pricingInputData,
    transferFiles,
    receipt,
    success,
    activeScreen,
});

export default (state, action) => {
    const { type } = action;
    if (type === C.RESET_DATA_ALL) {
        return {};
    }
    return editInvoiceContractor(state, action);
};