import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import sampleApprovalActions from '../../../actions/sampleApprovalActions';
import {sampleApprovalStatus} from '../../../constants/EnumTypes';
import _ from 'lodash';
import moment from 'moment';
import I18n from 'i18n-js';

class MaterialStatusLog extends Component {
  constructor (props, context) {
    super(props, context);

    this.state = {
      materialLog: []
    };
  }

  componentWillMount () {
    const {item, type} = this.props;
    const typesMap = {
      counterSample: 'COUNTER-SAMPLE',
      colorLapdip: 'COLOR-LAPDIP',
      ss: 'S-S'
    };

    let materialLog = (_.cloneDeep(_.get(item, 'auditLog.materials')) || []).filter(i => i.type === typesMap[type]);
    materialLog = materialLog.reduce((acc, i) => {
      let date = moment(i.date).format('MMM DD, YYYY');
      _.isUndefined(acc[date]) ? _.set(acc, date, [i]) : _.set(acc, date, _.concat(_.get(acc, date), i));
      return acc;
    }, {});

    this.setState({materialLog});
  }

  close () {
    this.props.closeModal();
  }

  render () {
    let {materialLog} = this.state;
    return (
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <a className="close" onClick={() => this.close()}><span aria-hidden="true">&times;</span></a>
            <h4 className="modal-title" id="myModalLabel">{I18n.t('PageSampleApproval.AUDITLOG')}</h4>
          </div>
          <div className="modal-body pd-0">
            <ul className="list-log-history material-log-history">
              {Object.keys(materialLog).map((k, idx) => (
                <li key={idx}>
                  <h3>{k}</h3>
                  <div className="pd-15 bg-white">
                    <table style={{width: '100%'}} className="global-table">
                      <tbody>
                        {
                          materialLog[k].reduce((acc, i) => {
                            return (sampleApprovalStatus.REJECTED === _.get(i, 'data.status', '') || sampleApprovalStatus.APPROVED === _.get(i, 'data.status', '') || sampleApprovalStatus.RESUBMITTED === _.get(i, 'data.status', '')) &&  _.get(i, 'data.remark', '') != '' ? _.concat(acc, [i, _.set(_.cloneDeep(i), 'showRemark', true)]) : _.concat(acc, i);
                          }, [])
                          .map((i, idx) => {
                            let status = _.get(i, 'data.status', '');
                            let className = sampleApprovalStatus.APPROVED === status ? 'color-green'
                              : sampleApprovalStatus.REJECTED === status ? 'color-red'
                              : sampleApprovalStatus.RESUBMITTED === status ? 'color-yellow' : '';

                            return !i.showRemark ? (
                              <tr key={idx} className="bg-white">
                                <td className="color-gray">{moment(i.date).format('HH:mm A')}</td>
                                <td>{_.get(i, 'material.name', '')}</td>
                                <td className={className}>{_.capitalize(status)}</td>
                                <td>{moment(_.get(i, 'data.date') || {}).format('MMM DD, YYYY')}</td>
                              </tr>
                              ) : (
                              <tr key={idx} className="bg-white text">
                                <td />
                                <td colSpan="3">
                                  <div className="bg-gray radius-5 pd-10">{_.truncate(_.get(i, 'data.remark', ''), {length: 120, separator: ' '})}</div>
                                  <br/>
                                </td>
                              </tr>
                              );
                          })
                        }
                      </tbody>
                    </table>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps (state, ownProps) {
  return {
    authState: state.authState,
    sampleApprovalState: state.sampleApprovalState
  };
}

function mapDispatchToProps (dispatch) {
  return {
    sampleApprovalPropsActions: bindActionCreators(sampleApprovalActions, dispatch)
  };
}

MaterialStatusLog.propTypes = {
  authState: PropTypes.object.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(MaterialStatusLog);
