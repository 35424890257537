import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router'
//Constants
import I18n from 'i18n-js';
import { toastr } from 'react-redux-toastr'
import { SHIP_OUT } from '../../../../../constants/routeConstants';
//Components
import CustomerINV from '../../../../components/shipping/subViews/customerINV';
import CutTickedContainer from '../pklShip/CutTickedContainerCreateShipment';
import TransportFee from '../../../../components/shipping/subViews/transportFee';
import ChooseStyle from '../../../../components/shipping/subViews/chooseStyle';
import BarTitle from '../../../../components/shipping/subViews/barTitle';
import CheckBox from '../../../../../components/common/fields/CheckBox';
//Actions
import {
    getStandard, changeOrderActive,
    changeStyleActive, save, resetData,
    changeShipmentInfo, changeUnitPrice,
    getListForwarder, addForwarderIntoList
} from '../../../../../actions/ship-out/editShipmentActions';
import { getPklShipDetailView, activeScreenEditPklShip } from '../../../../../actions/ship-out/editPklShipActions';
//selectors
import * as selector from '../../../../../selectors/ship-out/editShipment';
import _ from "lodash";
import ModalConfirm from "../../../../../components/common/modals/ModalConfirm";

class CreateShipmentContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isCompleted: this.props.noDateDelivered || false,
        }
    }

    state = {
        customerINVs: [1],
        customerPOs: [1, 2, 3]
    };

    componentWillMount() {
        this.getStandard();
        this.props.getListForwarder()
    }

    componentWillReceiveProps(nextProps) {
        const { success, activeScreen } = nextProps;

        if (success) {
            nextProps.resetData()
            nextProps.router.history.push(SHIP_OUT.SHIP.URL)
            toastr.success(I18n.t('Notify.Success'), 'Update Success')
        }
        if (!activeScreen) {
            nextProps.router.history.goBack()
        }
    }

    getStandard = () => {
        this.props.getStandard();
    }

    showDetailPklShip = (id) => {
        const { screen } = this.props
        this.props.changePklShipDetail(id)
        this.props.activeScreenViewDetail()
        if (screen == "editShipment")
            this.props.router.history.push(SHIP_OUT.SHIP.VIEW_DETAIL)
        else {
            this.props.router.history.push(SHIP_OUT.HISTORY.VIEW_DETAIL)
        }
    }

    setCustomerINVLinks = (customerINVs) => {
        const totalItems = customerINVs && customerINVs.length - 1;
        return (customerINVs || []).map((customerINV, index) => {
            return (<span key={index}>
                <a onClick={() => this.showDetailPklShip(customerINV.pklShipId)} className="color-link">{customerINV.customerInv}</a>
                {!(index === totalItems) && (<span>,&nbsp;</span>)}
            </span>);
        });
    }

    changeOrderActive = (value, orderId) => {
        this.props.changeOrderActive(value, orderId)
    }

    changeStyleActive = (orderId, styleId) => {
        this.props.changeStyleActive(orderId, styleId)
    }

    validateField() {
        const { dataApi } = this.props;
        const { isCompleted } = this.state;

        const emptyField = selector.getEmptyField(dataApi);
        switch (emptyField) {
            case 'customINV':
                toastr.error(I18n.t('Notify.Error'), "Customs INV field mustn't empty");
                break;
            case 'shipMode':    
                toastr.error(I18n.t('Notify.Error'), "Ship mode field mustn't empty");
                break;
            case 'cont':
                toastr.error(I18n.t('Notify.Error'), "Cont field mustn't empty");
                break;
            case 'seal':
                toastr.error(I18n.t('Notify.Error'), "Seal field mustn't empty");
                break;
            case 'contType':
                toastr.error(I18n.t('Notify.Error'), "Cont field type mustn't empty");
                break;
            case 'declarationNo':
                toastr.error(I18n.t('Notify.Error'), "Declaration No field mustn't empty");
                break;
            case 'forwarder':
                toastr.error(I18n.t('Notify.Error'), "Forwarder field mustn't empty");
                break;
            case 'billNo':
                toastr.error(I18n.t('Notify.Error'), "Bill No mustn't empty");
                break;
            case 'dateDelivered': {
                if (isCompleted) {
                    toastr.error(I18n.t('Notify.Error'), "Date delivered invalid");
                }
                break;
            }
            default:
                return true;
        }
        return false;
    }
    renderConfirmSaveStatusCompleted () {
        let { openModal, closeModal } = this.props.modal;
        let modal = (<ModalConfirm
            title={I18n.t('PageShiping.ConfirmShiping')}
            confirmMessage={I18n.t('PageShiping.ConfirmComplete')}
            messageOk={I18n.t('Btn.YES')}
            messageCancel={I18n.t('Btn.CANCEL')}
            handleClose={closeModal}
            handleOk={this.checkedQuantityBeforeSave.bind(null)} />);
        openModal(modal);
    }
    checkSave = () => {
        const { isCompleted } = this.state;
        if(isCompleted){
            return this.renderConfirmSaveStatusCompleted();
        }
        return this.checkedQuantityBeforeSave();
    }


    checkedQuantityBeforeSave = () => {
        this.save();
    }


    save = () => {
        const { dataApi } = this.props;
        if (this.validateField()) {
            // shipOutApi.findBillNo(dataApi.billNo).then(res => {
            //     if(res.data.length > 0) {
            //         let i = _.findIndex(res.data, (el) => el._id !== dataApi._id);
            //         if (i >= 0) {
            //             return toastr.error(I18n.t('Notify.Error'), "Bill No already exists");
            //         }
            //     }
                    this.props.save(dataApi._id, dataApi);
            // });


        }
    }

    changeTransportFee = (key, value) => {
        this.props.changeShipmentInfo(key, value)
    }

    changeShipmentInfo = (key, value) => {
        this.props.changeShipmentInfo(key, value)
    }

    changeUnitPrice = (orderId, styleId, cutTicketId, colorId, value) => {
        value = _.round(value * 1, 2);
        this.props.changeUnitPrice(orderId, styleId, cutTicketId, colorId, value)
    }

    setOrderContainers = (orders, transportFee) => {

        return (orders || []).map((order, index) => {
            const { getStyles, listActiveStyleIds, getListData, disabled, total } = this.props
            let styles = [];
            // const cutTicketContainers = this.setCutTicketContainers(order._id);
            if (order.active) {
                styles = getStyles(order._id)
            }
            const activeStyle = styles.find(item => item.active == true)
            return (
                <div key={index} className="mg-bottom-10">
                    <BarTitle id={order._id} type='customer_po' name={order.name} commodity={order.commodity} active={order.active} changeOrderActive={this.changeOrderActive} />
                    {order.active ?
                        <div>
                            <ChooseStyle removeIcon={false} orderId={order._id} styles={styles} changeStyleActive={this.changeStyleActive} />
                            <div>
                                <TransportFee type={'view'} total={total} styleQuantity={activeStyle ? activeStyle.quantity : 0} transportFee={transportFee} />
                            </div>
                            <CutTickedContainer disabled={disabled} orderId={order._id} styleId={listActiveStyleIds[order._id]} getListData={getListData} changeUnitPrice={this.changeUnitPrice} />
                        </div>
                        : null}

                </div>
            )
        })
    }

    isCompletedChange = () => {
        this.setState((state) => {
            if (state.isCompleted) {
                // Empty dateDelivered when change state from completed to incompleted.
                this.props.changeShipmentInfo('isCompleted', false);
                // this.props.changeShipmentInfo('dateDelivered', null);
            } else {
                this.props.changeShipmentInfo('isCompleted', true);
                // this.props.changeShipmentInfo('dateDelivered', moment().format());
            }
            return { isCompleted: !state.isCompleted };
        });
    }

    render() {

        const { getStyles, disabled, listForwarder, customerINVs, listOrders, total, shipMode, getShipAgencies, contType, shipmentInfo, transportFee } = this.props;
        const shipAgencies = shipmentInfo && getShipAgencies(shipmentInfo.shipMode);
        const customerINVLinks = this.setCustomerINVLinks(customerINVs);
        const customerPOContainers = this.setOrderContainers(listOrders, transportFee);
        const { isCompleted } = this.state;

        return (
            <div className="pd-5">
                {/* <HeaderForSubView type='CreateShipment' turnBackURL={SHIP_OUT.PKL_SHIP.URL} {...this.props}/> */}
                <div>

                    <div className="d-flex justify-content-between pd-15 bg-white bd-dashed-bottom">
                        <div className="d-flex">
                            <p className="size-12">{I18n.t('PageShiping.CustomerINV')}</p>
                            <span className="color-link medium mg-left-25 size-14"> {customerINVLinks}</span>
                        </div>

                        <div className={`${!disabled ? "d-flex mg-right-30" : "d-flex"}`}>
                            <p className="size-12 mg-right-15">{I18n.t('PageShiping.Total')}</p>
                            <span className="size-14 bold color-number">{total}</span>
                        </div>

                        <div className="d-flex justify-content-end">
                            {!disabled && 
                                <div className="d-flex mg-right-30">
                                    <CheckBox
                                        id="editshiping_cb"
                                        label="Compeleted"
                                        checked={isCompleted}
                                        onChange={this.isCompletedChange}
                                    />
                                </div>
                            }
                            {!disabled &&
                                <div className="d-flex add-filter-top">
                                    <span className="cursor-pointer h-auto line-h-initial"
                                          onClick={this.checkSave}
                                    >
                                        <i className="fa fa-floppy-o" aria-hidden="true"></i>{I18n.t("PageShiping.SAVE")}
                                    </span>
                                </div>
                            }
                        </div>
                    </div>
                    <CustomerINV
                        disabled={disabled}
                        noDateDelivered={!isCompleted}
                        shipMode={shipMode}
                        contType={contType}
                        shipmentInfo={shipmentInfo}
                        shipAgencies={shipAgencies}
                        changeShipmentInfo={this.changeShipmentInfo}
                        listForwarder={listForwarder}
                    />
                    <TransportFee type={'input'} total={total} disabled={disabled} transportFee={transportFee} changeTransportFee={this.changeTransportFee} />
                    {customerPOContainers}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    customerINVs: selector.getCustomerINV(state),
    // getPklShipDetail: selector.fnGetPklShipDetail(state),
    listOrders: selector.getOrders(state),
    getStyles: selector.fnGetStyles(state),
    listActiveStyleIds: selector.getActiveStyleIds(state),
    total: selector.getTotal(state),
    dataApi: selector.fnGenApiData(state),
    success: selector.getSuccess(state),
    transportFee: selector.getTransportFee(state),
    shipMode: selector.getShipMode(state),
    shipmentInfo: selector.getShipmentInfo(state),
    contType: selector.getContType(state),
    getShipAgencies: selector.fnGetShipAgencies(state),
    getListData: selector.fnGetListData(state),
    listForwarder: selector.getListForwarder(state),
    activeScreen: selector.getActiveScreenEditShipment(state),
})

const mapDispatchToProps = dispatch => ({
    getStandard() {
        dispatch(getStandard())
    },
    changePklShipDetail(data) {
        dispatch(getPklShipDetailView(data))
    },
    changeOrderActive(value, orderId) {
        dispatch(changeOrderActive(value, orderId))
    },
    changeStyleActive(orderId, styleId) {
        dispatch(changeStyleActive(orderId, styleId))
    },
    save(id, data) {
        dispatch(save(id, data))
    },
    resetData() {
        dispatch(resetData())
    },
    changeShipmentInfo(key, value) {
        if (key == 'forwarder') {
            dispatch(addForwarderIntoList(value))
        }
        dispatch(changeShipmentInfo(key, value))
    },
    changeUnitPrice(orderId, styleId, cutTicketId, colorId, value) {
        dispatch(changeUnitPrice(orderId, styleId, cutTicketId, colorId, value))
    },
    getListForwarder() {
        dispatch(getListForwarder())
    },
    activeScreenViewDetail() {
        dispatch(activeScreenEditPklShip())
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CreateShipmentContainer));