import IC_DASHBOARD from '../stylesheets/images/ic-dashboard.svg';
import IC_DASHBOARD_NEW from '../stylesheets/images/ic-dashboard-new.svg';
import IC_DASHBOARD_ON_TRACK from '../stylesheets/images/ic-dashboard-on-track.svg';
import IC_DASHBOARD_WARNING from '../stylesheets/images/ic-dashboard-warning.svg';
import IC_DASHBOARD_BEHIND_SCHEDULE from '../stylesheets/images/ic-dashboard-behind-schedule.svg';
import IC_X from '../stylesheets/images/ic-x.svg';
import LOGO_WHITE from '../stylesheets/images/logo-white.svg';
import IC_CUSTOMER from '../stylesheets/images/ic-customer.svg';
import IC_YIELD from '../stylesheets/images/ic-yield.svg';
import IC_SAMPLE_APPROVAL from '../stylesheets/images/ic-sample-approval.svg';
import IC_PURCHASE from '../stylesheets/images/ic-purchase.svg';
import IC_WAREHOUSE from '../stylesheets/images/ic-warehouse-w.svg';
import IC_PRODUCTION_S from '../stylesheets/images/ic-production-s.svg';
import IC_SHIPPING from '../stylesheets/images/ic-shipping.svg';
import IC_ACCOUNTING_S from '../stylesheets/images/ic-accounting-s.svg';
import IC_REPORT from '../stylesheets/images/ic-report.svg';
import IC_STANDARD_S from '../stylesheets/images/ic-standard-s.svg';
import IC_ORGANIZATION from '../stylesheets/images/ic-organization.svg';
import IC_CRM_S from '../stylesheets/images/ic-crm-s.svg';
import IC_SYSTEM from '../stylesheets/images/ic-system.svg';
import IC_EDIT_C from '../stylesheets/images/ic-edit-c.svg';
import IC_EDIT_D from '../stylesheets/images/ic-edit-d.svg';
import IC_CANCEL from '../stylesheets/images/ic-cancel.svg';
import IC_CANCEL_HIGHTLIGHT from '../stylesheets/images/ic-cancel-highlight.svg';
import IC_HISTORY_D from '../stylesheets/images/ic-history-d.svg';
import IC_HISTORY_C from '../stylesheets/images/ic-history-c.svg';
import IC_ADD_ORDER from '../stylesheets/images/ic-add-order.svg';
import IC_NOTIFY from '../stylesheets/images/ic-notify.png';
import IC_EDIT_X from '../stylesheets/images/ic-edit-x.svg';
import IC_CURSOR_POINTER from '../stylesheets/images/ic-cursor-pointer.svg';
import IC_GRAG_PHOTO from '../stylesheets/images/grag-photo.svg';
import IC_EXPORT from '../stylesheets/images/ic-export.svg';
import IC_V_W from '../stylesheets/images/ic-v-w.svg';
import IC_EDIT_YIELD from '../stylesheets/images/ic-edit-yield.svg';
import IC_YIELD_C from '../stylesheets/images/ic-yield-c.svg';
import IC_SAMPLE_APPROVAL_C from '../stylesheets/images/ic-sample-approval-c.svg';
import IC_SAMPLE_APPROVAL_D from '../stylesheets/images/ic-sample-approval-d.svg';
import IC_EDIT_NOTEXT from '../stylesheets/images/ic-edit-notext.svg';
import IC_AUDIT_LOG from '../stylesheets/images/ic-auditLog.svg';
import IC_PLANS_C from '../stylesheets/images/ic-plans-c.svg';
import IC_PLANS_D from '../stylesheets/images/ic-plans-d.svg';
import IC_FOLLOW_UP_D from '../stylesheets/images/ic-follow-up-d.svg';
import IC_FOLLOW_UP_C from '../stylesheets/images/ic-follow-up-c.svg';
import IC_PURCHASE_LIST_D from '../stylesheets/images/ic-purchase-list-d.svg';
import IC_PURCHASE_LIST_C from '../stylesheets/images/ic-purchase-list-c.svg';
import IC_WAREHOUSE_C from '../stylesheets/images/ic-warehouse-c.svg';
import IC_STOCK_IN from '../stylesheets/images/ic-stock-in.svg';
import IC_EYE from '../stylesheets/images/ic-eye.svg';

import IC_SAVE from '../stylesheets/images/ic-save.svg';
import IC_X_GRAY from '../stylesheets/images/ic-x-gray.svg';
import IC_X_WHITE from '../stylesheets/images/ic-x-white.svg';
import IC_SEARCH from '../stylesheets/images/ic-search.svg';
import IC_ALERT from '../stylesheets/images/ic-alert.svg';

import IC_EMAIL from '../stylesheets/images/email.png';
import IC_HOME_PHONE from '../stylesheets/images/home.png';
import IC_STROKE from '../stylesheets/images/homephone.png';
import IC_HOME from '../stylesheets/images/stroke.png';

export default {
    IC_DASHBOARD,
    IC_DASHBOARD_NEW,
    IC_DASHBOARD_ON_TRACK,
    IC_DASHBOARD_WARNING,
    IC_DASHBOARD_BEHIND_SCHEDULE,
    IC_X,
    LOGO_WHITE,
    IC_CUSTOMER,
    IC_YIELD,
    IC_SAMPLE_APPROVAL,
    IC_PURCHASE,
    IC_WAREHOUSE,
    IC_PRODUCTION_S,
    IC_SHIPPING,
    IC_ACCOUNTING_S,
    IC_REPORT,
    IC_STANDARD_S,
    IC_ORGANIZATION,
    IC_CRM_S,
    IC_SYSTEM,
    IC_EDIT_C,
    IC_EDIT_D,
    IC_CANCEL,
    IC_CANCEL_HIGHTLIGHT,
    IC_HISTORY_D,
    IC_HISTORY_C,
    IC_ADD_ORDER,
    IC_NOTIFY,
    IC_EDIT_X,
    IC_CURSOR_POINTER,
    IC_GRAG_PHOTO,
    IC_EXPORT,
    IC_V_W,
    IC_EDIT_YIELD,
    IC_YIELD_C,
    IC_SAMPLE_APPROVAL_C,
    IC_SAMPLE_APPROVAL_D,
    IC_EDIT_NOTEXT,
    IC_AUDIT_LOG,
    IC_PLANS_C,
    IC_PLANS_D,
    IC_FOLLOW_UP_D,
    IC_FOLLOW_UP_C,
    IC_PURCHASE_LIST_D,
    IC_PURCHASE_LIST_C,
    IC_WAREHOUSE_C,
    IC_STOCK_IN,
    IC_EYE,
    IC_SAVE,
    IC_X_GRAY,
    IC_X_WHITE,
    IC_SEARCH,
    IC_ALERT,
    IC_EMAIL,
    IC_HOME_PHONE,
    IC_STROKE,
    IC_HOME
};
