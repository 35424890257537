import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import * as PATH from '../../../../constants/routeConstants';
import * as selector from '../../../../selectors/accounting/payable/showHistoryInvoiceList';
import * as actions from '../../../../actions/accounting/payable/invoiceHistory';
import { getInvoiceIEContainerView, activeScreenPreviewContainer } from '../../../../actions/accounting/payable/historyPreviewContainer';
import { getInvoiceIEDeclarationView, activeScreenPreviewDeclaration } from '../../../../actions/accounting/payable/historyPreviewDeclaration';
import { getInvoiceIEUSDeliveryView, activeScreenPreviewUSDelivery } from '../../../../actions/accounting/payable/historyPreviewUSDelivery';
import { getInvoiceContractorView, activeScreenPreviewContractor } from '../../../../actions/accounting/payable/historyPreviewContractor';
import { getInvoiceSupplierOverseaView, activeScreenPreviewOversea } from '../../../../actions/accounting/payable/historyPreviewSupplierOversea';
import { getInvoiceSupplierDomesticView, activeScreenPreviewDomestic } from '../../../../actions/accounting/payable/historyPreviewSupplierDomestic';
import ShowHistoryListComponent from '../../../components/accounting/payable/ShowHistoryList';
class ShowHistoryList extends Component {
    constructor(props) {
        super(props);

    }

    componentWillMount() {
        this.props.changeLoading(true);
    }

    componentDidMount() {
        // this.getList()
    }

    componentWillReceiveProps(nextProps) {
        const { paramsRequest, loading } = nextProps;
        // if (createInvoiceRequestStatus) {
        //     nextProps.resetInvoiceRequest();
        // }
        // if (deleteSuccess) {
        //     nextProps.resetDeleteStatus();
        //     this.getListInvoice(paramsRequest || {});
        //     toastr.success(I18n.t('Notify.Success'), 'Delete Success');
        // }
        if (loading && paramsRequest) {
            nextProps.changeLoading(false);
            this.getListInvoiceHistory(paramsRequest || {});
        }
    }

    getListInvoiceHistory(params) {
        this.props.getListInvoiceHistory(params)
    }

    getBaseOption(pageSize, pageNumber) {
        let options = {
            query: JSON.stringify({ isCancel: { $ne: true } }),
            sort: JSON.stringify({ createdAt: -1 }),
            isHistory:
                pageSize,
            pageNumber,
        };
        return options;
    }

    render() {
        return (
            <ShowHistoryListComponent {...this.props}/>
        );
    }
}

const mapStateToProps = state => ({
    paramsRequest: selector.getParamsRequest(state),
    listFilter: selector.getSelectableFilters(),
    selectedFilter: selector.getSelectedFilter(state),
    searchValue: selector.getSearchValue(state),
    loading: selector.getLoading(state),
    currency: selector.getCurrency(state),
    listInvoiceHistory: selector.getInvoicesHistory(state),
    pageTotal: selector.getPageTotal(state),
    pageCurrent: selector.getPageCurrent(state),
    totalAmount: selector.getTotalAmount(state),
    currencyTypeUSD: selector.getCurrencyTypeUSD(state),
    currencyTypeVND: selector.getCurrencyTypeVND(state),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    changeLoading(value) {
        dispatch(actions.changeLoading(value))
    },
    getListInvoiceHistory(params) {
        dispatch(actions.getListInvoiceHistory(params))
        dispatch(actions.getAmountInvoiceHistory({currency: params.currency}))
    },
    changeCurrency(value) {
        dispatch(actions.changeCurrency(value))
    },
    view(id, type) {
        console.log('------show detail history---', id, type);
        switch (type) {
            case 'containerInvoice':
                dispatch(activeScreenPreviewContainer());
                ownProps.router.history.push(PATH.ACCOUNTING_PAYABLE_HISTORY_CONTAINER_VIEW_URL);
                dispatch(getInvoiceIEContainerView(id));
                return;
            case 'declarationInvoice':
                dispatch(activeScreenPreviewDeclaration());
                ownProps.router.history.push(PATH.ACCOUNTING_PAYABLE_HISTORY_DECLARATION_VIEW_URL);
                dispatch(getInvoiceIEDeclarationView(id));
                return;
            case 'usDeliveryInvoice':
                dispatch(activeScreenPreviewUSDelivery());
                ownProps.router.history.push(PATH.ACCOUNTING_PAYABLE_HISTORY_US_DELIVERY_VIEW_URL);
                dispatch(getInvoiceIEUSDeliveryView(id));
                return;
            case 'contractorInvoice':
                dispatch(activeScreenPreviewContractor());
                ownProps.router.history.push(PATH.ACCOUNTING_PAYABLE_HISTORY_CONTRACTOR_VIEW_URL);
                dispatch(getInvoiceContractorView(id));
                return;
            case 'domesticInvoice':
                dispatch(activeScreenPreviewDomestic());
                ownProps.router.history.push(PATH.ACCOUNTING_PAYABLE_HISTORY_DOMESTIC_VIEW_URL);
                dispatch(getInvoiceSupplierDomesticView(id));
                return;
            case 'overseaInvoice':
                dispatch(activeScreenPreviewOversea());
                ownProps.router.history.push(PATH.ACCOUNTING_PAYABLE_HISTORY_OVERSEA_VIEW_URL);
                dispatch(getInvoiceSupplierOverseaView(id));
                return;
            default:
                return;
        }
    },
    changePage(params) {
        dispatch(actions.changePage(params.pageNumber))
        dispatch(actions.getListInvoiceHistory(params))
    },
    onChangeFilter(value) {
        dispatch(actions.changeSelectedFilter(value))
    },
    onChangeSearchValue(value, pos) {
        dispatch(actions.changeSearchValue(value, pos))
    },
    onSearch(params) {
        params.pageNumber = 1;
        dispatch(actions.changePage(params.pageNumber));
        dispatch(actions.getListInvoiceHistory(params))
        dispatch(actions.getAmountInvoiceHistory({currency: params.currency}))
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ShowHistoryList));