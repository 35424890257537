import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { withRouter } from 'react-router';
import I18n from 'i18n-js';
import * as PATH from '../../../../constants/routeConstants'; 
import EditInvoiceIEUSDeliveryView from '../../../components/accounting/PayableInvoiceList/EditInvoiceIEUSDelivery';
import ReceiptModal from '../../../components/accounting/PayableInvoiceList/ReceiptModal';
import * as actions from '../../../../actions/accounting/payable/editInvoiceIEUSDelivery';
import * as selector from '../../../../selectors/accounting/payable/editInvoiceIEUSDelivery';
import EditModalView from '../../../components/accounting/IEUSDeliveryItemEditDetail';
import UploadTransferFileModal from '../../../components/accounting/PayableInvoiceList/UploadTransferFileModal';
class EditInvoiceIEUSDelivery extends Component {

    constructor(props) {
        super(props);
        this.state = ({
            openContainerDetailModal: false,
            openReceiptModal: false,
            openTransferModal: false,
        })
    }

    componentWillReceiveProps(nextProps) {
        const {success, activeScreen} = nextProps;
        if(success) {
            nextProps.resetSuccessStatus();
            nextProps.router.history.push(PATH.ACCOUNTING_PAYABLE_INVOICE_URL)
            toastr.success(I18n.t('Notify.Success'), 'Update Invoice Success')
        }
        if(!activeScreen) {
            nextProps.router.history.goBack()
        }
    }

    openReceiptModal = () => {
        const {receiptFromInvoice} = this.props;
        this.props.createReceipt(receiptFromInvoice)
        this.setState({openReceiptModal: true})
    }

    closeReceiptModal = () => {
        this.props.changeEmptyReceipt()
        this.setState({openReceiptModal: false})
    }

    openTransferModal = () => {
        const {filesSaved} = this.props;
        this.props.copyFileToRawData(filesSaved);
        this.setState({openTransferModal: true})
    }

    closeTransferModal = () => {
        this.props.emptyFilesInRawData();
        this.setState({openTransferModal: false})
    }

    openViewContainerDetailModal = (billId, containerIndex, data) => {
        this.props.copyDataToTemp({...data, billId, contIndex: containerIndex})
        this.setState({openContainerDetailModal: true})
    }

    closeViewContainerDetailModal = () => {
        this.setState({openContainerDetailModal: false})
    }

    save = (paymentType, data) => {
        const {payAmount} = this.props;
        if(payAmount == 0) {
            delete data.payment;
            this.props.save(paymentType, data);
        } else {
            this.props.save(paymentType, data);
        }
    }

    render() {
        const {openReceiptModal, openContainerDetailModal, openTransferModal} = this.state;
        const {tempData, transferFiles, receiptData} = this.props;
        return (
            <div>
                <EditInvoiceIEUSDeliveryView 
                    {...this.props} 
                    save = {(paymentType, data) => this.save(paymentType, data)}
                    openReceiptModal = {() => this.openReceiptModal()}
                    openTransferModal = {() => this.openTransferModal()}
                    openViewContainerDetail = {(billId, containerIndex, item) => this.openViewContainerDetailModal(billId, containerIndex, item)}
                />
                <ReceiptModal 
                    openModal = {openReceiptModal} 
                    close = {this.closeReceiptModal}
                    receiptData = {receiptData}
                    changeReceiptValue = {(key, value) => this.props.changeReceiptValue(key, value)} 
                    onSubmitReceipt = {data => this.props.onSubmitReceipt(data)}
                />
                <EditModalView 
                    openModal = {openContainerDetailModal} 
                    close = {this.closeViewContainerDetailModal}
                    data = {tempData}
                    ok = {(item) => this.props.submit(item)}
                    resetDateRaw = {this.resetDataRaw} 
                    changeContainerDetailValue = {(key, value) => this.props.changeContainerDetailValue(key, value)}
                />
                <UploadTransferFileModal
                    isOpen = {openTransferModal}
                    transferFiles = {transferFiles}
                    onClose = {this.closeTransferModal}
                    onAddFile = {file => this.props.onAddFile(file)}
                    onRemoveFile = {id => this.props.onRemoveFile(id)}
                    onSubmit = {(files) => this.props.onSubmitFiles(files)}
                />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    company: selector.getCompany(state),
    paymentType: selector.getPaymentType(state),
    name: selector.getName(state),
    invoiceNo: selector.getInvoiceNo(state),
    invoiceDate: selector.getInvoiceDate(state),
    note: selector.getNote(state),
    payAmount: selector.getPayAmount(state),
    paymentType: selector.getPaymentType(state),
    amount: selector.getAmount(state),
    paid: selector.getPaid(state),
    remaining: selector.getRemaining(state),
    listBillDetail: selector.getListBillDetail(state),
    tempData: selector.getDataOfTemp(state),
    transferFiles: selector.getTransferFiles(state),
    dataApi: selector.fnGenerateRequestData(state),
    filesSaved: selector.getFilesSaved(state),
    receiptData: selector.getReceiptDataFromRawData(state),
    receiptFromInvoice: selector.getReceiptFromInvoice(state),
    emptyReceipt: selector.getEmptyReceipt(state),
    success: selector.getSuccess(state),
    disableChangeValue: selector.getDisableChangeValue(state),
    activeScreen: selector.getActiveScreenStatus(state),
    dueDate: selector.getDueDate(state),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    changePaymentType(value) {
        dispatch(actions.changePaymentType(value))
    },
    changeName(value) {
        dispatch(actions.changeName(value))
    },
    changeInvoiceDate(value) {
        dispatch(actions.changeInvoiceDate(value))
    },
    changePayAmount(value, remaining, paid) {
        dispatch(actions.changePayAmount(value, remaining, paid))
    },
    changeInvoiceNo(value) {
        dispatch(actions.changeInvoiceNo(value))
    },
    changeNote(value) {
        dispatch(actions.changeNote(value))
    },
    changeBillDetailValue(id, key, value) {
        dispatch(actions.changeBillDetailValue(id, key, value))
    },
    copyDataToTemp(data) {
        dispatch(actions.copyDataToTemp(data))
    },
    submit(data) {
        dispatch(actions.submit(data))
    },
    resetTempData() {
        dispatch(actions.resetTempData())
    },
    changeContainerDetailValue(key, value) {
        dispatch(actions.changeContainerDetailValue(key, value))
    },
    onAddFile(file) {
        dispatch(actions.addFileTransfer(file))
    },
    onRemoveFile(id) {
        dispatch(actions.onRemoveFile(id))
    },
    save(paymentType, data) {
        dispatch(actions.save(paymentType, data))
    },
    copyFileToRawData(files) {
        dispatch(actions.copyFileToRawData(files))
    },
    emptyFilesInRawData() {
        dispatch(actions.emptyFilesInRawData())
    },
    onSubmitFiles(files) {
        dispatch(actions.onSubmitFiles(files))
    },
    createReceipt(value) {
        dispatch(actions.createReceipt(value))
    },
    changeReceiptValue(key, value) {
        dispatch(actions.changeReceiptValue(key, value))
    },
    onSubmitReceipt(data) {
        dispatch(actions.onSubmitReceipt(data))
    },
    changeEmptyReceipt() {
        dispatch(actions.changeEmptyReceipt())
    },
    resetSuccessStatus() {
        dispatch(actions.resetSuccessStatus())
    },
    toggleActivePurchaseOrder(id, value) {
        dispatch(actions.toggleActivePurchaseOrder(id, value))
    },
    changeDueDate(date) {
        dispatch(actions.changeDueDate(date))
    }
})

export default connect(mapStateToProps, mapDispatchToProps) (withRouter(EditInvoiceIEUSDelivery));