import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import FormGroup from '../../forms/FormGroup';
import Input from '../../forms/Input';
import SearchSelect from '../../../components/forms/SearchSelect';
import DateTimeField from 'react-datetime';
// import SideBar from './SideBar';
import MainVATCard from '../MainVATCard';

import icXHoverIcon from '../../../../stylesheets/images/ic-x-hover.svg';

const AccountsEditReceivableInvoice = props => {
  return (
    <form>
      <div id="accounting-receivable-plan">
        <div
          className="wrap-filter-search"
          style={{
            right: '0px',
          }}>
          <ul className="inline-block">
            <li className="float-left style-icon-save" onClick={() => props.save(props.dataApi)}>
              <span className="cursor-pointer">
                <i className="fa fa-floppy-o" aria-hidden="true"></i>
                SAVE
              </span>
            </li>
          </ul>
        </div>

        <div
          id="header-accounting"
          className="wrap-input-global pd-15-0 margin-small bg-white page-edit-invoice-list box-shadow">
          <div className="pd-0 col-xs-9 left">
            <div className="col-md-6 col-xs-12 input">
              <FormGroup
                label="Customer"
                type="select pd-right-0"
                inputComponent={
                  <SearchSelect
                    options={props.selectableCustomers || []}
                    value={props.selectedCustomer || ''}
                    onChange={o => props.changeSelectedCustomer({ customer: o, shipMode: props.selectedShippingMode })}
                    isLoading={props.fetchingSelectableCustomer}
                    clearable
                    disabled
                  />
                }
              />
            </div>
            <div className="col-md-6 col-xs-12 input">
              <FormGroup
                label="Issued Date"
                type="select"
                inputComponent={
                  <DateTimeField
                    timeFormat={false}
                    closeOnSelect
                    dateFormat="MMM DD, YYYY"
                    value={moment(props.issuedDate)}
                    onChange={date => props.changeIssuedDate(date.format('YYYY-MM-DD HH:mm Z'))}
                  />
                }
              />
            </div>

            <div className="col-md-6 col-xs-12 input">
              <FormGroup
                label="Invoice No."
                type="select pd-right-0"
                inputComponent={
                  <Input
                    type="text"
                    value={props.invoiceNo}
                    onChange={e => props.changeInvoiceNo(e.target.value)}
                    placeholder=""
                    disabled
                  />
                }
              />
            </div>
            <div className="col-md-6 col-xs-12 input">
              <FormGroup type="position-relative top-7 bold" label="Payment Term" inputComponent={'60 days'} />
            </div>

            <div className="col-md-6 col-xs-12 input">
              <FormGroup
                label="Credit/Debit Note"
                type="select pd-right-0"
                inputComponent={
                  <SearchSelect
                    options={props.selectableCreditDebits || []}
                    value={props.selectedCreditDebit || ''}
                    onChange={o => props.changeSelectedCreditDebit(o)}
                    clearable
                  />
                }
              />
            </div>
            <div className="col-md-6 col-xs-12 input">
              <FormGroup
                type="input"
                label="Due Date"
                inputComponent={
                  <DateTimeField
                    timeFormat={false}
                    closeOnSelect
                    dateFormat="MMM DD, YYYY"
                    value={moment(props.dueDate)}
                    onChange={date => props.changeDueDate(date.format('YYYY-MM-DD HH:mm Z'))}
                  />
                }
              />
            </div>

            <div className="col-md-6 col-xs-12 input">
              <FormGroup
                label="Price Type"
                type="select pd-right-0"
                inputComponent={
                  <SearchSelect
                    options={props.selectablePriceTypes || []}
                    value={props.priceType || ''}
                    onChange={o => props.changePriceType(o)}
                    clearable
                  />
                }
              />
            </div>
            <div className="col-md-6 col-xs-12 input">
              <FormGroup
                type="input"
                label="Receive Amount"
                inputComponent={
                  <Input
                    type="number"
                    min="0"
                    value={props.receiveAmount}
                    onChange={e => props.changeReceiveAmount(e.target.value)}
                    placeholder=""
                  />
                }
              />
            </div>
          </div>

          <div className="pd-0 col-xs-3 right">
            <div>
              <ul className="type-supplier">
                <li
                  onClick={() => props.changeReceiveType('Cash')}
                  className={props.receiveType === 'Cash' ? 'active' : 'cursor-pointer'}>
                  <span className="cursor-poniter">
                    <span className="icon">
                      <span />
                    </span>
                    <span className="text">Cash</span>
                  </span>
                </li>
                <li
                  onClick={() => props.changeReceiveType('Transfer')}
                  className={props.receiveType === 'Transfer' ? 'active' : 'cursor-pointer'}>
                  <span className="cursor-poniter">
                    <span className="icon">
                      <span />
                    </span>
                    <span className="text">Transfer</span>
                  </span>
                </li>
              </ul>
              {props.receiveType === 'Transfer' && (
                <div>
                  <div className="mg-bottom-15">
                    <input type="file" onChange={e => props.changeReceiveFile(e.target.files, props.receiveFiles)} />
                  </div>
                  <div>
                    <table className="w-full font-s-12 left mg-bottom-15">
                      {_.map(props.receiveFiles || [], file => (
                        <tr key={file.filename}>
                          <td style={{ width: '90%' }}>{file.filename}</td>
                          <td style={{ width: '10%' }}>
                            <img
                              style={{ cursor: 'pointer', width: '15px', height: '15px' }}
                              src={icXHoverIcon}
                              onClick={e => {
                                e.stopPropagation();
                                props.removeReceiveFile(file, props.receiveFiles);
                              }}
                            />
                          </td>
                        </tr>
                      ))}
                    </table>
                  </div>
                </div>
              )}
              {props.receiveType === 'Cash' && <div></div>}
            </div>
            <div className="left textarea">
              <textarea
                rows="3"
                value={props.receiveNote || ''}
                onChange={evt => props.changeReceiveNote(evt.target.value)}
                placeholder="Note"
              />
            </div>
            <div className="text-align-left mg-top-10">
              <p>
                <span className="mg-right-15 size-12">Total Amount INV</span>
                <span className="bold color-number size-14">${props.totalAmountInv}</span>
              </p>
            </div>
            <div className="text-align-left mg-top-10">
              <p>
                <span className="mg-right-15 size-12">Received</span>
                <span className="bold size-14">${props.received}</span>
              </p>
            </div>
            <div className="text-align-left mg-top-10">
              <p>
                <span className="mg-right-15 size-12">Remaining</span>
                <span className="bold color-green size-14">${props.remaining}</span>
              </p>
            </div>
          </div>

          {/* <div className="bottom text-left size-12 pd-15-0 inline-block w-full">
                    <div className="col-xs-12">
                        <p><span className="mg-right-15">Say</span> <span className="bold">Forty-three thousand five hundred twenty-two dollars and twenty-two cents</span></p>
                    </div>
                </div> */}
        </div>
        <div className="content-main-accounting">
          <div className="content-sidebar top-initial left-0">
            <div className="md content-sidebar-search">
              <input type="text" placeholder="Search" />
              <span>
                <i className="fa fa-search" aria-hidden="true" />
              </span>
            </div>
            <div className="md content-sidebar-menu">
              <ul className="overflow-y-scroll h-full">
                {_.map(props.checkableBills, (bill, index) => (
                  <li className="style-checkbox" key={index}>
                    <input
                      type="checkbox"
                      // id="content-sidebar-menu-1"
                      className="display-none"
                      value={bill.checked}
                      checked={bill.checked || false}
                      id={bill._id}
                      onChange={evt =>
                        props.changeCheckedBill({
                          ...bill,
                          checked: evt.target.checked,
                          dataRequest: props.dataRequestShipOut,
                        })
                      }
                    />
                    <label htmlFor={bill._id}>
                      <span className="mg-right-10">
                        <span />
                      </span>
                      {bill.billNo}
                    </label>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          {/* <!-- Component Header Convert --> */}
          <div id="header-plan-accounts-payable">
            <div className="wrap-input-global box-shadow bg-white float-left overflow-hidden">
              <div className="col-md-4 col-xs-12 input">
                <FormGroup
                  label="Convert to"
                  type="select"
                  inputComponent={
                    <SearchSelect
                      options={props.selectableConvertCurrencies || []}
                      value={props.convertType || ''}
                      onChange={o => props.changeConvertType(o)}
                      isLoading={props.fetchingSelectableCustomer}
                      disabled
                    />
                  }
                />
              </div>

              <div className="col-md-4 col-xs-12 input">
                <FormGroup
                  type="input"
                  label="Rate"
                  inputComponent={
                    <Input
                      type="text"
                      value={props.convertRate}
                      onChange={e => props.changeConvertRate(e.target.value, props.totalAmountInv)}
                      placeholder=""
                    />
                  }
                />
              </div>

              <div className="col-md-4 col-xs-12 text col-md-4 col-xs-12 text bd-dashed-left">
                <p style={{ marginTop: '20px' }}>
                  Total Amount Convert 1{' '}
                  <span className="mg-left-15 color-number bold size-13">{props.convertAmount} VND</span>
                </p>
              </div>
            </div>
          </div>

          {/* <!-- End Component --> */}

          {/* <!-- Component --> */}
          {props.isVATPerCustomer && (
            <MainVATCard
              vatNo={props.mainVATNo}
              vatDate={props.mainVATDate}
              totalAmount={props.mainVATTotalAmount}
              vatAmount={props.mainVATAmount}
              grandTotal={props.mainVATGrandTotal}
              taxRate={props.mainVATTaxRate}
              changeVATNo={value => props.changeMainVATNo(value)}
              changeTotalAmount={value => props.changeMainVATTotalAmount(value)}
              changeVATDate={value => props.changeMainVATDate(value)}
              changeTaxRate={value => props.changeMainVATTaxRate(value)}
            />
          )}
          {/* <!-- End Component --> */}

          <div className="panel-group mg-0" id="panel-heading-main" role="tablist" aria-multiselectable="true">
            {_.map(props.checkedBills, (bill, billKey) => (
              <div className="panel panel-default bd-none radius-0" key={billKey}>
                {/* <!-- Component --> */}
                <div className="panel-heading component" role="tab" id="">
                  <div className="panel-title">
                    <a
                      role="button"
                      data-toggle="collapse"
                      data-parent="#panel-heading-main"
                      href="#panel-heading-1"
                      aria-expanded="true"
                      aria-controls="collapseOne">
                      <i className="fa fa-caret-down" aria-hidden="true" />
                    </a>
                    <ul>
                      <li className="float-left">
                        <div className="text">
                          <span className="left">Bill/Cont</span>
                          <span className="right size-15">
                            <span className="medium">{bill.billNo}</span>
                          </span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>

                <div
                  id="panel-heading-1"
                  className="panel-collapse collapse in"
                  role="tabpanel"
                  aria-labelledby="panel-heading-1">
                  {_.map(bill.customerPOs, (item, cusKey) => (
                    <div className="panel-body pd-0 bg-gray" key={cusKey}>
                      <ul className="bg-white content-header-title pd-left-15 bd-solid-bottom-3">
                        <li>
                          <p>
                            Customer PO <span className="bold mg-left-25 size-14 color-green">{item.customerPO}</span>
                          </p>
                        </li>
                        <li className="float-right">
                          <div className="inline-block mg-right-30">
                            <span>LADIES KNIT DRESS - 90</span>
                          </div>
                        </li>
                      </ul>
                      {/* <!-- Component --> */}
                      <div className="bg-white wrap-input-global pd-15-0-0-0 margin-small">
                        <div className="col-md-4 col-xs-12 input">
                          <ul>
                            <li>
                              <span>Air Cost</span>
                            </li>
                            <li className="pd-left-60">
                              <input
                                type="text"
                                className="form-control"
                                value={item.airCost || null}
                                onChange={evt => props.changeAirCost(bill.billNo, item.customerPO, evt.target.value)}
                              />
                            </li>
                          </ul>
                        </div>
                        <div className="col-md-4 col-xs-12 input">
                          <ul className="position-relative">
                            <li className="top-0">
                              <span>Total Quantity</span>
                            </li>
                            <li>
                              <span className="color-number bold">{item.totalQuantity || 0}</span>
                            </li>
                          </ul>
                          <ul className="position-relative">
                            <li className="top-0">
                              <span>Total Amount</span>
                            </li>
                            <li>
                              <span className="color-number bold">{item.totalAmount || 0}</span>
                            </li>
                          </ul>
                        </div>

                        <div className="col-md-4 col-xs-12 input h-auto">
                          <ul>
                            <li>
                              <span>Note</span>
                            </li>
                            <li className="textarea pd-left-40">
                              <textarea
                                rows="4"
                                cols="50"
                                value={item.note || null}
                                onChange={evt => props.changeNoteBill(bill.billNo, item.customerPO, evt.target.value)}
                              />
                            </li>
                          </ul>
                        </div>
                      </div>
                      {/* <!-- End Component --> */}

                      <div className="wrap-global-table pd-0 inline-block w-full float-left mg-bottom-0">
                        <table className="global-table box-shadow-none w-full">
                          <tbody>
                            <tr className="bg-white bd-solid-bottom">
                              <th>CTNS</th>
                              <th className="center">Style</th>
                              <th className="center">PG</th>
                              <th className="center">Cut Ticket</th>
                              <th className="center">Contractor</th>
                              <th className="center">Store</th>
                              {/* <th className="center">Lable</th> */}
                              <th className="center">Color</th>
                              <th className="center">QTY</th>
                              <th className="center w-100">Unit Price (LDP)</th>
                              <th className="center">Total</th>
                            </tr>
                            {_.map(item.data, (elem, elemKey) => (
                              <tr key={elemKey}>
                                <td>{elem.cnts}</td>
                                <td className="center">{elem.style}</td>
                                <td className="center">{elem.pg || '-'}</td>
                                <td className="center">{elem.cutTicket}</td>
                                <td className="center">{elem.contractor}</td>
                                <td className="center">{elem.store}</td>
                                {/* <td className="center">yard</td> */}
                                <td className="center">{elem.color}</td>
                                <td className="center">{elem.qty}</td>
                                <td className="center input">
                                  <input
                                    type="number"
                                    className="form-control center"
                                    value={elem.unitPrice || null}
                                    onChange={evt =>
                                      props.changeUnitPrice(bill.billNo, item.customerPO, elemKey, evt.target.value)
                                    }
                                  />
                                </td>
                                <td className="center color-number">{elem.qty * (elem.unitPrice || 0)}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </form>
  );
};

AccountsEditReceivableInvoice.propTypes = {
  selectableCustomers: PropTypes.arrayOf(PropTypes.any).isRequired,
  selectableShippingMode: PropTypes.arrayOf(PropTypes.any).isRequired,
  isVATPerCustomer: PropTypes.bool,
  invoiceNo: PropTypes.string,
  creditForSeaFreight: PropTypes.string,
  priceType: PropTypes.string,
  issuedDate: PropTypes.string,
  dueDate: PropTypes.string,
  selectedShippingMode: PropTypes.string,
  convertType: PropTypes.string,
  convertRate: PropTypes.string,
  convertAmount: PropTypes.string,
  mainVATAmount: PropTypes.string,
  mainVATDate: PropTypes.string,
  mainVATTaxRate: PropTypes.string,
  mainVATNo: PropTypes.string,
  mainVATGrandTotal: PropTypes.string,
  mainVATTotalAmount: PropTypes.string,
  fetchingSelectableCustomer: PropTypes.bool,

  receiveAmount: PropTypes.string,
  receiveNote: PropTypes.string,
  receiveAmount: PropTypes.string,
  receiveFiles: PropTypes.arrayOf(PropTypes.any).isRequired,

  changeShippingMode: PropTypes.func,
  changeSelectedCustomer: PropTypes.func,
  changeVATPerCustomer: PropTypes.func,
  changeInvoiceNo: PropTypes.func,
  changeCreditForSeaFreight: PropTypes.func,
  changePriceType: PropTypes.func,
  changeIssuedDate: PropTypes.func,
  changeDueDate: PropTypes.func,
  changeConvertType: PropTypes.func,
  changeConvertRate: PropTypes.func,

  changeReceiveAmount: PropTypes.func,
  changeReceiveNote: PropTypes.func,
  changeReceiveAmount: PropTypes.func,
  changeReceiveFile: PropTypes.func,
  removeReceiveFile: PropTypes.func,
};

AccountsEditReceivableInvoice.defaultProps = {};

export default AccountsEditReceivableInvoice;
