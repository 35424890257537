import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import standardActions from '../../actions/standardActions';
import BaseComponent from '../base/BaseComponent';
import I18n from 'i18n-js';
import {toastr} from 'react-redux-toastr';
import _ from 'lodash';
import Moment from 'moment';

import icXIcon from '../../stylesheets/images/ic-x.svg';
import addNewIcon from '../../stylesheets/images/add-new.svg';

class Shippings extends BaseComponent {
        constructor(props, context) {
                super(props, context);
                this.state = {
                        items: [],
                        addAir: [""],
                        addSea: [""],
                        addOther: [{
                                "shipMod": "",
                                "shipAgency": "",
                        }],
                        duplicate: [""],
                };
        }

        componentWillMount() {
                this.props.standardPropsActions.getStandard();
        }

        componentWillReceiveProps(newProps) {
                const { items } = this.state;
                const newItems = _.cloneDeep(_.get(newProps.standardState, 'detail') || []);
                !_.isEqual(items, newItems) && this.setState({ items: newItems });
        }

        componentWillUpdate(nextProps, nextState) {
                if (this.props.standardState.reload != nextProps.standardState.reload) {
                        nextProps.standardPropsActions.getStandard();
                }
        }

        addAir(idx) {
                let addAir = this.state.addAir;
                let items = this.state.items.shipping.air;
                items.splice(idx + 1, 0, "");
                this.setState({ addAir });
        }

        addNewAir() {
                let addAir = _.concat(this.state.addAir, "");
                this.setState({ addAir });
        }

        addSea(idx) {

                let addSea = this.state.addSea;
                let items = this.state.items.shipping.sea;
                items.splice(idx + 1, 0, "");
                this.setState({ addSea });
        }

        addNewSea() {
                let addSea = _.concat(this.state.addSea, "");
                this.setState({ addSea });
        }

        addOther(idx) {

                let addOther = this.state.addOther;
                let items = this.state.items.shipping.other;
                items.splice(idx + 1, 0, {
                        "shipMod": "",
                        "shipAgency": "",
                });
                this.setState({ addOther });
        }

        addNewOther() {
                let addOther = _.concat(this.state.addOther, {
                        "shipMod": "",
                        "shipAgency": "",
                });
                this.setState({ addOther });
        }

        deleteItemAir(idx) {
                let arr = this.state.items.shipping.air;
                let deleteAir = _.pullAt(arr, [idx]);
                this.setState({ arr });
        }

        deleteItemSea(idx) {
                let arr = this.state.items.shipping.sea;
                let deleteSea = _.pullAt(arr, [idx]);
                this.setState({ arr });
        }

        deleteItemOther(idx) {
                let arr = this.state.items.shipping.other;
                let deleteOther = _.pullAt(arr, [idx]);
                this.setState({ deleteOther });
        }

        deleteItemAddAir(id) {
                let arr = this.state.addAir;
                let addAir = _.pullAt(arr, [id]);
                this.setState({ arr });
        }

        deleteItemAddSea(id) {
                let arr = this.state.addSea;
                let addSea = _.pullAt(arr, [id]);
                this.setState({ arr });
        }

        deleteItemAddOther(id) {
                let arr = this.state.addOther;
                let addOther = _.pullAt(arr, [id]);
                this.setState({ arr });
        }

        setInputAir(idx, value) {

                let data = this.state.addAir;
                data[idx] = value;
                this.setState({ data });
        }

        setInputSea(idx, value) {
                let data = this.state.addSea;
                data[idx] = value;
                this.setState({ data });
        }

        setInputOther(idx, property, value) {
                let data = this.state.addOther[idx];
                this.setState({ data: _.set(data, property, value) });
        }

        onChangeAir(property, value) {
                let data = this.state.items.shipping.air;
		data[property] = value;
                this.setState({ data: _.set(data, property, value) });
        }

        onChangeSea(property, value) {
                let data = this.state.items.shipping.sea;
                data[property] = value;
                this.setState({ data: _.set(data, property, value) });
        }

        onChangeOther(idx, property, value) {
                let data = this.state.items.shipping.other[idx];
                this.setState({ data: _.set(data, property, value) });
        }
        existValue = (arr) => {
                let k = new Map([...new Set(arr)].map(
                        x => [x, arr.filter(y => y.toLowerCase() === x.toLowerCase()).length]
                    ));;
                let exist;
                k.forEach((val, key) => {
                        if(val > 1 ) exist = val;
                });
                return exist;
        }
        save(id) {
                const {air, sea, other} = this.state.items.shipping;
                const {addAir, addSea, addOther} = this.state;
                let existOther = -1;
                for (const o of other) {
                        if(existOther > -1) break;
                        existOther = other.findIndex(item => 
                                item._id === undefined &&
                                o._id !== undefined &&
                                item.shipAgency.toLowerCase() === o.shipAgency.toLowerCase() && 
                                item.shipMod.toLowerCase() === o.shipMod.toLowerCase()
                        )
                }

                if(existOther != -1 || this.existValue(air) > 1 || this.existValue(sea) > 1) {
                        toastr.error(I18n.t('Notify.Error'), 'Duplicate Value');
                } else {
                        let arrAir = _.union(_.compact(_.concat(air, addAir)));
                        let arrSea = _.union(_.compact(_.concat(sea, addSea)));
                        let arrOther = _.union(_.compact(_.concat(other, addOther)));
                        let arrOtherNull = _.filter(arrOther, _.matches({ "shipMod": "", "shipAgency": "" }));
                        _.pullAllBy(arrOther, arrOtherNull);
                        let data = {
                                "shipping": {
                                        "air": arrAir,
                                        "sea": arrSea,
                                        "other": arrOther,
                                }
                        };
                        this.props.standardPropsActions.editItem(id, data);
                }
        }

        render() {
                const { tabCurrent, roles: {showEdit} } = this.props;
                let { tabActive } = this.state;
                let { items, addAir, addSea, addOther } = this.state;
                return (
                        <div className="box-shadow">
                                {showEdit && <div className="title bd-dashed-bottom">
                                        <h2>{I18n.t('PageStandard.Shipping')}</h2>
                                        <span className="button-save cursor-pointer" onClick={() => { this.save(items._id); }}><i className="fa fa-floppy-o size-20" aria-hidden="true" /> {I18n.t('Btn.SAVE')}</span>
                                </div>}
                                <div className="wrap-content">
                                        <div className="box col-md-4 col-xs-12">
                                                <h4><a href="#" className="color-orange text-decoration">{I18n.t('PageStandard.AIRFREIGT')}</a></h4>
                                                <p className="title">{I18n.t('PageStandard.ShipAgencies')}</p>
                                                <ul className="list-ship-agencies">
                                                        {(items.shipping ? (items.shipping.air || []) : []).map((item, idx) => (

                                                                <li key={idx}>
                                                                        <div className="input">
                                                                                <input type="text" className="form-control" value={item} onChange={(evt) => { this.onChangeAir(idx, evt.target.value); }} />
                                                                        </div>
                                                                        <div className="button button-close" onClick={() => { this.deleteItemAir(idx); }}  >
                                                                                <img src={icXIcon} alt="Close" title="Close" />
                                                                        </div>
                                                                        <div className="button button-add" onClick={() => { this.addAir(idx); }}>
                                                                                <span className="cursor-pointer icon-add"><img src={addNewIcon} alt="Add New" title="Add New" /></span>
                                                                        </div>
                                                                </li>
                                                        ))}
                                                        {items.shipping && items.shipping.air && items.shipping.air.length === 0 && (
                                                                <div className="button button-add" onClick={() => { this.addAir(0); }}>
                                                                        <span className="cursor-pointer icon-add"><img src={addNewIcon} alt="Add New" title="Add New" /></span>
                                                                </div>
                                                        )}
                                                </ul>
                                        </div>
                                        <div className="box col-md-4 col-xs-12">
                                                <h4><a href="#" className="color-orange text-decoration">{I18n.t('PageStandard.SEAFREIGHT')}</a></h4>
                                                <p className="title">{I18n.t('PageStandard.ShipAgencies')}</p>
                                                <ul className="list-ship-agencies">
                                                        {(items.shipping ? (items.shipping.sea || []) : []).map((item, idx) => (
                                                                <li key={idx}>
                                                                        <div className="input">
                                                                                <input type="text" className="form-control" value={item} onChange={(evt) => { this.onChangeSea(idx, evt.target.value); }} />
                                                                        </div>
                                                                        <div className="button button-close" onClick={() => { this.deleteItemSea(idx); }}  >
                                                                                <img src={icXIcon} alt="Close" title="Close" />
                                                                        </div>
                                                                        <div className="button button-add" onClick={() => { this.addSea(idx); }}>
                                                                                <span className="cursor-pointer icon-add"><img src={addNewIcon} alt="Add New" title="Add New" /></span>
                                                                        </div>
                                                                </li>
                                                        ))}
                                                        {items.shipping && items.shipping.sea && items.shipping.sea.length === 0 && (
                                                                <div className="button button-add" onClick={() => { this.addSea(0); }}>
                                                                        <span className="cursor-pointer icon-add"><img src={addNewIcon} alt="Add New" title="Add New" /></span>
                                                                </div>
                                                        )}
                                                </ul>
                                        </div>
                                        <div className="box col-md-4 col-xs-12 wrap-list-other">
                                                <h4><a href="#" className="color-orange text-decoration">{I18n.t('PageStandard.OTHERFREIGHT')}</a></h4>
                                                <div className="inline-block w-full list relative float-left">
                                                        <div className="col-md-4 col-xs-12 shipmod pd-0">
                                                                <p className="title">{I18n.t('PageShiping.ShipMode')}</p>
                                                        </div>
                                                        <div className="col-md-8 col-xs-12">
                                                                <p className="title">{I18n.t('PageStandard.ShipAgencies')}</p>
                                                        </div>
                                                </div>
                                                {(items.shipping ? (items.shipping.other || []) : []).map((item, idx) => (
                                                        <div key={idx} className="inline-block w-full list relative float-left">
                                                                <div className="col-md-4 col-xs-12 shipmod pd-0">
                                                                        <div className="input">
                                                                                <input type="text" value={item.shipMod} className="form-control" onChange={(evt) => { this.onChangeOther(idx, "shipMod", evt.target.value); }} />
                                                                        </div>
                                                                </div>
                                                                <div className="col-md-8 col-xs-12">

                                                                        <ul className="list-ship-agencies">
                                                                                <li className="pd-0">
                                                                                        <div className="input">
                                                                                                <input type="text" value={item.shipAgency} onChange={(evt) => { this.onChangeOther(idx, "shipAgency", evt.target.value); }} className="form-control" />
                                                                                        </div>
                                                                                        <div className="button button-close" onClick={() => { this.deleteItemOther(idx); }}  >
                                                                                                <img src={icXIcon} alt="Close" title="Close" />
                                                                                        </div>
                                                                                        <div className="button button-add shipagency bt-add-other">
                                                                                                <span className="cursor-pointer icon-add" onClick={() => { this.addOther(idx); }}><img src={addNewIcon} alt="Add New" title="Add New" /></span>
                                                                                        </div>
                                                                                </li>
                                                                        </ul>
                                                                </div>
                                                        </div>
                                                ))}
                                                {items.shipping && items.shipping.other && items.shipping.other.length === 0 && (
                                                        <div className="button button-add shipagency bt-add-other">
                                                                <span className="cursor-pointer icon-add" onClick={() => { this.addOther(0); }}><img src={addNewIcon} alt="Add New" title="Add New" /></span>
                                                        </div>
                                                )}
                                        </div>
                                </div>
                        </div>
                );
        }
}

function mapStateToProps(state, ownProps) {
        return {
                ...ownProps,
                authState: state.authState,
                standardState: state.standardState
        };
}

function mapDispatchToProps(dispatch) {
        return {
                standardPropsActions: bindActionCreators(standardActions, dispatch)
        };
}

Shippings.propTypes = {
        authState: PropTypes.object.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(Shippings);
