import React from 'react';
import _ from 'lodash';
import * as R from 'ramda';
import I18n from 'i18n-js';
import PropTypes from 'prop-types';

const PurchaseOrderDetail = props => (
  <div id="content">
    <ul className="content-header-title h-auto">
      <li className="title mg-right-60">
        <p>
          {' '}
          {I18n.t('PagePurchase.CustomerPO')}
          <span className="color-orange medium size-14 mg-left-15"> {props.customerPO}</span>
        </p>
      </li>
      <li className="title mg-right-60">
        <p>
          {' '}
          {I18n.t('PagePurchase.Style')}
          <span className="color-orange medium size-14 mg-left-15"> {props.style}</span>
        </p>
      </li>

      {!!props.purchaseNo && (
        <li className="title mg-right-60">
          <p>
            {' '}
            {I18n.t('PagePurchase.PurchaseOrderNo')}
            <span className="color-orange medium size-14 mg-left-15"> {props.purchaseNo}</span>
          </p>
        </li>
      )}

      {!!props.supplier && (
        <li className="title mg-right-60">
          <p>
            {' '}
            {I18n.t('PagePurchase.Supplier')}
            <span className="color-orange medium size-14 mg-left-15"> {props.supplier}</span>
          </p>
        </li>
      )}
    </ul>

    <div className="wrap-global-table">
      <table style={{ width: '100%' }} className="global-table">
        <tbody>
          <tr>
            <th>{I18n.t('PagePurchase.Material')}</th>
            <th className="center">{I18n.t('PagePurchase.Content')}</th>
            <th className="center">{I18n.t('PagePurchase.Color')}</th>
            <th className="center">{I18n.t('PagePurchase.Size')}</th>
            <th className="center">
              {I18n.t('PagePurchase.Replacement')}
              <br />
              P.O #
            </th>
            <th className="center">{I18n.t('PagePurchase.Unit')}</th>
            <th className="center">{I18n.t('PagePurchase.XMIL')}</th>
            <th className="center">{I18n.t('PagePurchase.ETD')}</th>
            <th className="center">{I18n.t('PagePurchase.ETA')}</th>
            <th className="center">
              {I18n.t('PagePurchase.Expected')}
              <br />
              {I18n.t('PagePurchase.ReceivedDate')}
            </th>
            <th className="center">{I18n.t('PagePurchase.Payer')}</th>
            <th className="center">{I18n.t('PagePurchase.Quantity')}</th>
            <th className="center">{I18n.t('PagePurchase.Convert_Quantity')}</th>
            <th className="center">{I18n.t('PagePurchase.Price')}</th>
            <th className="center">
              {I18n.t('PagePurchase.Total')}
              <br />
              {I18n.t('PagePurchase.Price')}
            </th>
          </tr>

          {_.map(props.items, item => (
            <tr key={R.path(['key'], item)}>
              <td>
                <span>{R.path(['material'], item)}</span>
              </td>
              <td className="center">{R.path(['content'], item)}</td>
              <td className="center">{R.path(['color'], item)}</td>
              <td className="center">{R.path(['size'], item)}</td>
              <td className="center">{R.path(['replacementPO'], item)}</td>
              <td className="center">{R.path(['unit'], item)}</td>
              <td className="center">{R.path(['XMIL'], item)}</td>
              <td className="center">{R.path(['ETD'], item)}</td>
              <td className="center">{R.path(['ETA'], item)}</td>
              <td className="center">{R.path(['exReceivedDate'], item)}</td>
              <td className="center">{R.path(['payer'], item)}</td>
              <td className="center">{R.path(['quantity'], item)}</td>
              <td className="center">{R.path(['convertQuantity'], item)}</td>
              <td className="center word-break-word">{R.path(['price'], item)}</td>
              <td className="color-number center word-break-word">{R.path(['amount'], item)}</td>
            </tr>
          ))}

          <tr className="g-total bg-white">
            <td colSpan="15" className="pd-top-20 pd-bottom-20">
              <ul>
                <li>{I18n.t('PagePurchase.G-Total')}</li>
                <li className="color-number">{props.gTotal}</li>
              </ul>
            </td>
          </tr>
          {props.remark && (
            <tr className="bg-white">
              <td className="pd-bottom-20" colSpan="15">
                <span className="bold mg-bottom-10 text-decoration block">{I18n.t('PagePurchase.Remark')}</span>
                <div>{props.remark}</div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  </div>
);

PurchaseOrderDetail.propTypes = {
  customerPO: PropTypes.string.isRequired,
  style: PropTypes.string.isRequired,
  purchaseNo: PropTypes.string.isRequired,
  supplier: PropTypes.string.isRequired,
  creator: PropTypes.string.isRequired,
  sender: PropTypes.string.isRequired,
  gTotal: PropTypes.string.isRequired,
  remark: PropTypes.string.isRequired,

  items: PropTypes.arrayOf(PropTypes.any).isRequired,
};

PurchaseOrderDetail.defaultProps = {};

export default PurchaseOrderDetail;
