import {getListRoleDAL, editRoleDAL, addRoleDAL, deleteRoleDAL, getListModuleDAL, getRoleDetailDAL} from '../api/roleApi';

class roleServices {

  constructor() {
  }

  getListRole (options = {}) {
    options.query && _.set(options, 'query', JSON.stringify(options['query']));
    options.sort && _.set(options, 'sort', JSON.stringify(options['sort']));
    return getListRoleDAL(options).then(res => {
      if (res.success && res.data && res.data.items){
        res.data.items.forEach(item => {
          let permissionNames = null;
          if(item && item.modules && item.modules.length) {
            item.modules.forEach(module => {
              if(module.actions && (module.actions.view || module.actions.create || module.actions.revise || module.actions.delete)) {
                permissionNames = (permissionNames == null) ? module.moduleId.name : (permissionNames + ', ' + module.moduleId.name);
              }
            });
            item.permissionNames = permissionNames;
          }
        })
      }
      return res;
    });
  }

  editRole (id, data) {
    return editRoleDAL(id, data);
  }

  addRole (data) {
    return addRoleDAL(data);
  }

  deleteRole (id) {
   return deleteRoleDAL(id);
  }

  getListModules(){
    return getListModuleDAL();
  }

  getRoleDetail(roleId){
    return getRoleDetailDAL(roleId);
  }

}

export default new roleServices();
