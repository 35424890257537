import React from "react";
import PropTypes from "prop-types";
import TableList from "../../../../components/common/datatables/TableList";
import ReactExport from "react-data-export";
import _ from "lodash";
import * as utils from "../../../../functions/utils";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const PreviewUSDelivery = props => {
    let labelName = 'Name :';
    let name = {value: props.name || '', style: {font: {patternType: "solid", color: {rgb: "FFF86B00"}}}};


    let labelTotalAmount = 'Total Amount INV : ';
    let totalTotalAmount = {
        value: props.totalAmount,
        style: {font: {patternType: "solid", color: {rgb: "FFFF0000"}}}
    };

    let header = [labelName, name, labelTotalAmount, totalTotalAmount];
    let putData = [];

    _.map(props.items, (el) => {
        let keys = [
            {key: 'string', value: 'billNo', color: '00000000'},
            {key: 'string', value: 'etd', color: '00000000'},
            {key: 'string', value: 'eta', color: '00000000'},
            {key: 'string', value: 'dueDate', color: '00000000'},
            {key: 'string', value: 'amount', color: '00000000'},
        ];

        putData.push(utils.mapDataForCell(el, keys));
    });

    let headerTable = ['Bill No', 'ETD', 'ETA', 'Due Date', 'Total Amount'];
    let dataHeaderTable = [];
    for(let i = 0; i < headerTable.length ; i++){
        dataHeaderTable.push({
            value: headerTable[i],
            style: utils.genStyleHeaderForCell('00000000')
        });
    }
    putData.unshift(dataHeaderTable);

    const multiDataSet = [
        {
            columns: [
                {title: '', width: {wch: 15}},//pixels width
                {title: '', width: {wch: 25}},//char width
                {title: '', width: {wch: 15}},
                {title: '', width: {wch: 25}},
                {title: '', width: {wch: 15}},//pixels width
                {title: '', width: {wch: 25}},//char width
                {title: '', width: {wch: 15}},//pixels width
                {title: '', width: {wch: 25}},//char width
                {title: '', width: {wch: 15}},
                {title: '', width: {wch: 25}},
                {title: '', width: {wch: 15}},//pixels width
                {title: '', width: {wch: 25}},//char width
            ],
            data: [header]
        },

        {
            xSteps: 0, // Will start putting cell with 1 empty cell on left most
            ySteps: 0, //will put space of 5 rows,
            columns: [],
            data: putData
        }
    ];
    let nameFile = '';
    let d = new Date();
    if(props.invoiceNo){
        nameFile = 'INV_' + props.invoiceNo + '_' + (Number(d.getMonth()) + 1 < 10 ? "0" + (Number(d.getMonth()) + 1) : Number(d.getMonth()) + 1)  + '_' + d.getFullYear();
    }else{
        nameFile = 'INV_' +  (Number(d.getMonth()) + 1 < 10 ? "0" + (Number(d.getMonth()) + 1) : Number(d.getMonth()) + 1)  + '_' + d.getFullYear();
    }


    console.log(props.items)
    return (
        <div>
            <ul className="content-header-title float-none pd-0-5 justify-content-space-between display-flex">
                <li>
                    <p>Name
                        <span className="color-orange medium mg-left-25 size-16">{props.name}</span>
                    </p> 
                </li>
                <li className="float-right">
                    <p>Total Amount Invoice
                        <span className="color-number medium mg-left-25 size-16">{props.totalAmount}  USD</span>
                    </p>
                </li>
                <li className="add-filter-top">
                    <span className="cursor-pointer" onClick = {() => this.save()}>
                        <ul className="content-header-title float-left pd-0-5" style={{height: 'auto'}}>
                    <li className="add-filter-top">
                        <ExcelFile filename={nameFile}
                                   element={
                                       <span className="cursor-pointer">
                                <i className="fa fa-floppy-o" aria-hidden="true"></i>{"Export"}
                            </span>
                                   }>
                            <ExcelSheet dataSet={multiDataSet}  name={nameFile}/>
                        </ExcelFile>

                    </li>
                </ul>
                    </span>
                </li>
            </ul>

            <div className="pd-5">
                <TableList items={props.items || []} columns={props.columns || []} options={{ clsTbl: 'global-table' }} />
            </div>
        </div>
    )
}
PreviewUSDelivery.propTypes = {
    items: PropTypes.array,
    columns: PropTypes.array,
    name: PropTypes.string,
    totalAmount: PropTypes.number,
    totalAdvance: PropTypes.number,
    totalRemaining: PropTypes.number
}

PreviewUSDelivery.defaultProps = {
    items: [],
    columns: [],
    listDeclaration: [],
    name: '',
    totalAmount: 0,
    totalAdvance: 0,
    totalRemaining: 0
}
export default PreviewUSDelivery