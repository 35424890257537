import {combineReducers} from 'redux';
import C from "../../../constants/accounting/payable/historyPreviewSupplierOversea";

const invoiceDetail = (state = null, action) => {
    const {type, payload} = action;
    switch(type) {
        case C.CHANGE_INVOICE_DETAIL:
            return payload;
        case C.RESET_DATA:
            return {};
        default:
            return state;
    }
}

const activeScreen = (state = false, action) => {
    const { type, payload } = action;
    switch (type) {
        case C.ACTIVE_SCREEN_STATUS:
            return payload;
        default:
            return state;
    }
}

export const historyPreviewSupplierOversea = combineReducers ({
    invoiceDetail,
    activeScreen
})