import _ from 'lodash';
import * as R from 'ramda';

export const isOpen = ({ createCutTicket }) => !!createCutTicket.style;

export const getStyleId = ({ createCutTicket }) => R.path(['_id'], createCutTicket.style);

export const getCutTicketName = ({ createCutTicket }) => createCutTicket.cutTicketName;

export const getSelectableStores = ({ createCutTicket }) => {
  const { selectableStores } = createCutTicket;
  return _.map(selectableStores, item => ({
    value: R.path(['_id'], item),
    label: R.path(['name'], item),
    raw: item,
  }));
}

export const getSelectedStore = ({ createCutTicket }) => {
  const { selectedStore } = createCutTicket;
  return R.pathOr('', ['_id'], selectedStore);
}

export const getSelectedWarehouse = ({ createCutTicket }) => {
  const { selectedWarehouse } = createCutTicket;
  return R.pathOr('', ['_id'], selectedWarehouse);
}

export const getSelectableShipModes = ({ createCutTicket }) => {
  const { selectableShipModes } = createCutTicket;
  return _.map(selectableShipModes, item => ({
    value: item,
    label: item,
    raw: item,
  }));
};

export const getSelectedShipMode = ({ createCutTicket }) => {
  const { selectedShipMode } = createCutTicket;
  return selectedShipMode;
}

export const getMainLabel = ({ createCutTicket }) => createCutTicket.mainLabel;
export const getIhDate = ({ createCutTicket }) => createCutTicket.ihDate;
export const getNote = ({ createCutTicket }) => createCutTicket.note;

export const getErrors = ({ createCutTicket }) => createCutTicket.errors;

export const canSave = ({ createCutTicket }) => {
  const {
    errors, submiting, cutTicketName, mainLabel, ihDate, 
    selectedWarehouse, selectedShipMode,
  } = createCutTicket;

  if (submiting) return false;

  return !!_.trimEnd(cutTicketName) 
    && !!_.trimEnd(ihDate)
    && !!selectedWarehouse
    && !!selectedShipMode
    && !R.path(['cutTicket'], errors)
    && !R.path(['ihDate'], errors)
    && !R.path(['selectedWarehouse'], errors)
    && !R.path(['selectedShipMode'], errors);
};

export const isSubmiting = ({ createCutTicket }) => createCutTicket.submiting;

export const fnGenerateBody = ({ createCutTicket }) => () => {
  const { cutTicketName, selectedWarehouse, selectedStore, selectedShipMode, mainLabel, ihDate, note, style } = createCutTicket;
  const body = {
    cutTicket: cutTicketName,
    warehouse: selectedWarehouse,
    shipMode: selectedShipMode,
    store: selectedStore,
    styleName: R.path(['style'], style),
    mainLabel,
    ihDate,
    note,
  }
  return body;
}
