import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import storeActions from '../../../actions/storeActions';
import packingStandardActions from '../../../actions/packingStandardActions';
import I18n from 'i18n-js';
import { toastr } from 'react-redux-toastr';

class StoreAdd extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      item: {},
      packingStandard: [],
      errors: {},
    };
  }

  componentWillMount() {
    this.getListItem();
  }

  componentWillReceiveProps(newProps) {
    let { packingStandard } = this.state;
    !_.isEqual(packingStandard, newProps.packingStandardState.items) && this.setState({
      packingStandard: _.cloneDeep(newProps.packingStandardState.items) || [],
      total: newProps.packingStandardState.count || 0
    });
  }

  getListItem(query = {}) {
    let { pageSize, pageNumber } = this.state;
    let _query = _.assign({ pageSize: pageSize, pageNumber: pageNumber, projection: 'name' }, query);
    this.props.packingStandardPropsActions.getListPackingStandard(_.set(_query, 'sort', { createdAt: -1 }));
  }

  onChange(property, value) {
    let { item } = this.state;
    this.setState({ item: _.set(item, property, value) });
    this.validateBeforeChange(property, value);
  }

  close() {
    this.props.closeModal();
  }

  validateBeforeChange(property, value) {
    let { errors } = this.state;
    errors = {};
    switch (property) {
      case 'name': {
        if (_.isEmpty(value)) {
          errors['name'] = I18n.t('ErrorMessages.StoreNameIsRequired');
        }
        this.setState({errors});
        break;
      }
      case 'packingStandard': {
        if (_.isEmpty(value)) {
          errors['packingStandard'] = I18n.t('ErrorMessages.PackingStandardInfoIsRequired');
        }
        this.setState({errors});
        break;
      }
      default:
    }
  }

  validateBeforeSave() {
    let { item, errors } = this.state;
    errors = {};
    if (_.isEmpty(item.name) || _.isEmpty(item.packingStandard)) {
      if (_.isEmpty(item.name)) {
        errors['name'] = I18n.t('ErrorMessages.StoreNameIsRequired');
      }
      if (_.isEmpty(item.packingStandard)) {
        errors['packingStandard'] = I18n.t('ErrorMessages.PackingStandardInfoIsRequired');
      }
      this.setState({ errors });
      return false;
    }
    this.setState({ errors });
    return true;
  }

  save() {
    if (!this.validateBeforeSave())
      return;
    let { item } = this.state;
    this.props.storePropsActions.addStore(item).then(result => {
      if (result.statusCode == 500) {
        // toastr.error(I18n.t('Notify.Error'), I18n.t('ErrorMessages.StoreExist'))
        let { item, errors } = this.state;
        errors['name'] = I18n.t('ErrorMessages.StoreExist');
        this.setState({ errors });
      } else if (!result.success) {
        toastr.error(I18n.t('Notify.Error'), result.message)
      }
    });
  }

  render() {
    let { packingStandard, errors } = this.state;
    let { item } = this.state;
    return (
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <form>
            <div className="modal-header">
              <a className="close" onClick={() => this.close()}><span aria-hidden="true">&times;</span></a>
              <h4 className="modal-title" id="myModalLabel">{I18n.t('PageCrm.ADDSTORE')}</h4>
            </div>

            <div className="modal-body">
              <div className="form-group">
                <div className="col-md-4 col-xs-12 text">
                  <p>{I18n.t('PageCrm.Name')}</p> <p className="color-red"> *</p>
                </div>
                <div className="col-md-8 col-xs-12 value">
                  <input type="text" placeholder={item.name || ''}
                    onChange={evt => this.onChange('name', evt.target.value)} className="form-control" />
                  {errors['name'] && <div className="alert-warning">{errors['name']}</div>}
                </div>
              </div>

              <div className="form-group">
                <div className="col-md-4 col-xs-12 text">
                  <p>{I18n.t('PageCrm.Address')}</p>
                </div>
                <div className="col-md-8 col-xs-12 value">
                  <input type="text" placeholder={item.address || ''}
                    onChange={evt => this.onChange('address', evt.target.value)} className="form-control" />
                </div>
              </div>

              <div className="form-group">
                <div className="col-md-4 col-xs-12 text">
                  <p>{I18n.t('PageCrm.PackingStandard')}</p> <p className="color-red"> *</p>
                </div>
                <div className="col-md-8 col-xs-12 value">
                  <select className="form-control" onChange={evt => this.onChange('packingStandard', evt.target.value)}>
                    <option value="">-- Select Packing standard --</option>
                    {packingStandard.map((i, idx) => (<option key={idx} value={i._id}>{i.name}</option>))}
                  </select>
                  {errors['packingStandard'] && <div className="alert-warning">{errors['packingStandard']}</div>}
                </div>
              </div>

            </div>

            <div className="modal-footer">
              <button type="button" className="bt-black" onClick={() => this.close()}>{I18n.t('Btn.CANCEL')}</button>
              <button type="button" className="bt-orange mg-l-5"
                onClick={() => this.save()}>{I18n.t('Btn.SAVE')}</button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    appState: state.appState,
    authState: state.authState,
    storeState: state.storeState,
    packingStandardState: state.packingStandardState
  };
}

function mapDispatchToProps(dispatch) {
  return {
    storePropsActions: bindActionCreators(storeActions, dispatch),
    packingStandardPropsActions: bindActionCreators(packingStandardActions, dispatch)
  };
}

StoreAdd.propTypes = {
  authState: PropTypes.object.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(StoreAdd);
