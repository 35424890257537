import {updateEmailSettingDAL, updateProductionDAL, updateAgeDefinitionDAL, getListNotificationsDAL, getListSystemSettingDAL, updatePurchaseDeviationDAL, updateSetting } from '../api/systemSettingApi';

class systemSettingServices {

  constructor() {
  }


  updateEmailSetting (id, data) {
    return updateEmailSettingDAL(id, data);
  }

  getListSystemSetting(options = {}) {
    return getListSystemSettingDAL(options);
  }

  updateProduction (id, data) {
    return updateProductionDAL(id, data);
  }

  getListNotifications(options = {}) {
    options.populate = (options.populate ? `${options.populate},` : '');
    return getListNotificationsDAL(options);
  }


  updatePurchaseDeviation (id, data) {
    return updatePurchaseDeviationDAL(id, data);
  }

  updateAgeDefinition (id, data) {
    return updateAgeDefinitionDAL(id, data);
  }
  
  updateSetting (id, data) {
    return updateSetting(id, data);
  }
}

export default new systemSettingServices();
