import React, { Component } from 'react';
import { Store } from '../../../../CssHandleModule/functions'
import * as _ from 'lodash';
import I18n from 'i18n-js';
import ColorTable from '../../../../components/shipping/subViews/colorTable';
import { connect } from 'react-redux';
import * as selector from '../../../../../selectors/ship-out/viewPklOfProdDetail';


const store = new Store();
store.setStyles({
    orderNumbers: [1, 2],
    aligns:[
        {order: 1, margin: {left: '10px'}},
        {order: 2, margin: {right: '10px'}}
    ]
});

class cutTicketContainer extends Component {

    state = {
        colors: []
    }

    //This function use for get colors in a cut ticket
    componentWillReceiveProps(nextProps) {
        const cutTicket = { ...nextProps.cutTicket };
        if (!_.isEqual(cutTicket.colors, this.state.colors)) {
            this.setState({ colors: cutTicket.colors });
        }
    }

    //This function use for handle color item and send the end result forward to color component
    setCutTicketComponents = (colors, sizes, packingType, styleId, cutId) => {
        let colorCoponents = null;
        if(packingType === 'GOHH') {
            colorCoponents = colors && <ColorTable data={colors} sizes={sizes} packingType = {packingType} styleId={styleId} cutId={cutId}/>
        } else {
            colorCoponents = colors && colors.map((color, index) => {
                return <ColorTable key={index} data={color} sizes={sizes} packingType = {packingType} styleId={styleId} cutId={cutId}/>
            })
        }

        return colorCoponents;
    }

    render() {

        const { cutTicket, styleId, getSizes, packingType} = this.props;
        const sizes = getSizes(styleId, cutTicket._id);
        const colorComponents = cutTicket && this.setCutTicketComponents(cutTicket.colors, sizes, packingType ,styleId, cutTicket._id);

        return (
            <div>
                <div className="d-flex justify-content-between pd-10">
                    <div className="d-flex">
                        <p className="size-12">{I18n.t('PageShiping.CutTicket')}</p>
                        <span className="color-orange bold mg-left-50 size-14">{cutTicket.cutTicket.cutTicket || ''}</span>
                    </div>

                    <div className="d-flex justify-content-end">
                        <div className="d-flex mg-right-30">
                            <p className="size-12 mg-right-15">{I18n.t('PageShiping.Lable')}</p>
                            <span className="size-12 bold">{cutTicket.cutTicket.mainLabel || ''}</span>
                        </div>
                        <div className="d-flex mg-right-30">
                            <p className="size-12 mg-right-15">{I18n.t('PageShiping.Store')}</p>
                            <span className="size-12 bold">{cutTicket.cutTicket.store && cutTicket.cutTicket.store.name || ''}</span>
                        </div>
                        <div className="size-12 color-link"><a>{cutTicket.cutTicket.store && cutTicket.cutTicket.store.packingStandard && cutTicket.cutTicket.store.packingStandard.name || ''}</a></div>
                    </div>
                </div>
                {colorComponents}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    getSizes: selector.getSizes(state),
    packingType: selector.getPackingType(state)
})
export default connect(mapStateToProps, null) (cutTicketContainer);