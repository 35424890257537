import * as types from '../constants/actionTypes';
import {showLoading, hideLoading} from './commonUseActions';
import yieldServices from '../services/yieldServices';
import { toastr } from 'react-redux-toastr';

function editYield (id, data) {
  return (dispatch) => {
    showLoading(types, dispatch);

    yieldServices.editYield(id, data).then((res) => {
      hideLoading(types, dispatch);

      if (res && res.success) {
        dispatch({
          type: types.CUT_TICKET.SET_ITEM_YIELD_DETAIL,
          data: {}
        });

        dispatch({
          type: types.CUT_TICKET.RELOAD,
          data: true
        });
      }
    }, (error) => {
      hideLoading(types, dispatch, error);
    });
  };
}


function addYield (cutTicketId, data) {
  return (dispatch) => {
    showLoading(types, dispatch);

    yieldServices.addYield(cutTicketId, data).then((res) => {
      hideLoading(types, dispatch);

      if (res && res.success) {
        dispatch({
          type: types.CUT_TICKET.SET_ITEM_YIELD_DETAIL,
          data: {}
        });

        dispatch({
          type: types.CUT_TICKET.RELOAD,
          data: true
        });
      }
    }, (error) => {
      hideLoading(types, dispatch, error);
    });
  };
}
function getExportBom(styleId){
    return yieldServices.getExportBom(styleId);
}

function getExportYield(orderId){
    return yieldServices.getExportYield(orderId);
}

function sendNotify(id, y) {
  return (dispatch) => {
  hideLoading(types, dispatch);
  return yieldServices.sendNotify(id, y).then((res) => {
    toastr.info('Success', 'Send notify successfully');
    dispatch({
      type: types.CUT_TICKET.SET_ITEM_YIELD_DETAIL,
      data: {}
    });

    dispatch({
      type: types.CUT_TICKET.RELOAD,
      data: true
    });
  }, (error) => {
    toastr.warning('Error', 'Send notify failed');
    hideLoading(types, dispatch, error);
  });
  }
}

export default {
  editYield,
  addYield,
  getExportBom,
  getExportYield,
  sendNotify
};
