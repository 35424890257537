
import _ from 'lodash';
import * as R from 'ramda';

export const getId = ({ editCutTicketColors }) => R.path(['_id'], editCutTicketColors.rawData);

export const getColors = ({ editCutTicketColors }) => {
  const { colors, selectableSizes } = editCutTicketColors;

  return _.map(colors, color => ({
    ...color,
    checkableSizes: _.map(selectableSizes, sz => ({
      value: sz,
      label: sz,
      checked: !!_.find(color.sizes, it => it.name === sz)
    }))
  }));
}

export const isOpen = ({ editCutTicketColors }) => !!editCutTicketColors.rawData;

export const isSubmiting = ({ editCutTicketColors }) => editCutTicketColors.submiting;

export const canSave = ({ editCutTicketColors }) => !editCutTicketColors.submiting;

export const getSuggestions = ({ editCutTicketColors }) => editCutTicketColors.suggestions;

export const fnGenerateRequestBody = ({ editCutTicketColors }) => () => {
  const { rawData } = editCutTicketColors;
  let { colors } = editCutTicketColors;
  colors = _.filter(colors, color => !!color.name);
  colors = _.map(colors, color => {
    let i = _.findIndex(rawData.colors, (el) => el.name == color.name);
    let tmp;
    if(i >= 0) {
      tmp = rawData.colors[i];
      tmp.sizes = color.sizes;
    } else {
      tmp = color;
    }
    return tmp;
  })
  return {
    ...rawData,
    colors: colors
  };
}
