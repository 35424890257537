import React, { Component } from 'react';
import { Store } from '../../../CssHandleModule/functions';
import { changeActiveStyle, removeStyle } from '../../../../actions/ship-out/createPklShipActions'
import { connect } from 'react-redux';

import icXGrayIcon from '../../../../stylesheets/images/ic-x-gray.svg';
import icXWhiteIcon from '../../../../stylesheets/images/ic-x-white.svg';

const store = new Store();

store.setStyles({
    orderNumbers: [1, 2],
    sizes: [
        { order: 1, width: '100px', height: '28px' }
    ],
    aligns: [
        { order: 1, margin: { right: '10px' } },
        { order: 2, margin: { left: '2px' } }
    ]
});

class ChooseStyle extends Component {

    constructor(props) {
        super(props);

    }

    changeStyleActive = (orderId, styleId) => {
        this.props.changeStyleActive(orderId, styleId)
    }

    removeStyle = (orderId, styleId) => {
        this.props.removeStyle(orderId, styleId)
    }

    render() {
        const { orderId, styles, removeIcon } = this.props
        return (
            <div className='d-flex' style={{ padding: '17px 2% 17px 2%', backgroundColor: 'white' }}>
                <span className="mg-right-15 size-12">Style</span>
                <div className="inline-block">
                    {styles && styles.map((style, index) => {
                        return (
                            <div className="mg-0-20 float-left position-relative" key={index}>
                                <label className={style.active ? 'label-style bt-orange cursor-pointer pd-right-30' : 'label-style bt-default color-black bd-solid-1 cursor-pointer pd-right-30'}
                                    onClick={() => this.changeStyleActive(orderId, style._id)}
                                > {style.name}
                                </label>
                                {removeIcon ? <span className="cursor-pointer close-icon"
                                    onClick={() => this.removeStyle(orderId, style._id)}
                                >
                                    {style.active ? <img src={icXWhiteIcon} />
                                        : <img src={icXGrayIcon} />
                                    }
                                </span> : null}
                            </div>
                        )
                    })}
                </div>
            </div>
        );
    }

}

const mapDispatchToProps = dispatch => ({
    // changeActiveStyle(orderId, styleId) {
    //     dispatch(changeActiveStyle(orderId, styleId))
    // },
    removeStyle(orderId, styleId) {
        dispatch(removeStyle(orderId, styleId))
    }
})

export default connect(null, mapDispatchToProps)(ChooseStyle);