import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import PropTypes from 'prop-types';
import purchaseActions from '../../../actions/purchaseActions';
import supplierActions from '../../../actions/supplierActions';
import orderActions from '../../../actions/orderActions';
import I18n from 'i18n-js';

class AddMaterial extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      selectedMaterials: this.props.selectedMaterials,
      errors: {}
    };
    this.selectMaterial = this.selectMaterial.bind(this);
    this.ok = this.ok.bind(this);
  }

  componentWillMount() {

  }

  componentWillReceiveProps(newProps) {

  }

  selectMaterial(isSelect, material) {
    const {selectedMaterials} = this.state;
    if (isSelect) {
      this.setState({selectedMaterials: [...selectedMaterials, material]});
    } else {
      this.setState({selectedMaterials: selectedMaterials.filter(m => m._id !== material._id)});
    }
  }

  onChange(property, value) {

  }

  ok() {
    this.props.changeMaterial(this.state.selectedMaterials);
    this.props.closeModal();
  }

  close() {
    this.props.closeModal();
  }

  render() {
    let {materials} = this.props;
    let {selectedMaterials} = this.state;
    return (
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <form>
            <div className="modal-header">
              <a className="close" onClick={() => this.close()}><span>&times;</span></a>
              <h4 className="modal-title" id="myModalLabel">{I18n.t('PagePurchase.ADDMATERIAL')}</h4>
            </div>
            <div className="modal-body">
              <div>
                <div className="tab-content">
                  <div>
                    <div className="form-group">
                      <div className="col-md-4 col-xs-12 text">
                        <p>
                        {I18n.t('PagePurchase.Materials')}
                        </p>
                      </div>
                      <div className="col-md-8 col-xs-12 value">
                        <ul>
                          {
                            materials.map((material, idx) => {
                              return (
                                <li key={idx} className="style-checkbox">
                                  <input checked={(selectedMaterials.find(m => m._id === material._id)) !== undefined}
                                         type="checkbox" id={material._id} value={material._id}
                                         onChange={(evt) => this.selectMaterial(evt.target.checked, material)}/>
                                  <label htmlFor={material._id}><span><span/></span></label> {material.name}
                                </li>);
                            })
                          }
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="cancel bg-black" onClick={() => this.close()}>{I18n.t('Btn.CANCEL')}</button>
              <button type="button" className="bg-orange mg-l-5" onClick={() => this.ok()}>{I18n.t('Btn.SAVE')}</button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    ...ownProps,
    authState: state.authState,
    purchaseState: state.purchaseState,
    supplierState: state.supplierState,
    orderState: state.orderState
  };
}

function mapDispatchToProps(dispatch) {
  return {
    supplierPropsActions: bindActionCreators(supplierActions, dispatch),
    purchasePropsActions: bindActionCreators(purchaseActions, dispatch),
    orderPropsActions: bindActionCreators(orderActions, dispatch)
  };
}

AddMaterial.propTypes = {
  authState: PropTypes.object.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(AddMaterial);
