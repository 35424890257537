import {ApiMethod} from '../constants/constants';
import {isObjectUndefinedOrNull} from '../functions/helpers';
import request from 'request';
import { getAccessToken } from '../functions/helpers';
import { toastr } from 'react-redux-toastr';
import I18n from 'i18n-js';

export default class RestClient {
  constructor () {
    this.timeout = 5000;
    this.TAG = 'RestClient';
    this.headers = {
      'Content-Type': 'application/json'
    };
  }

  setToken (token) {
    this.headers['x-access-token'] = token;
  }

  setLanguage (laguage) {
    this.headers['accept-language'] = laguage;
  }

  setTimeout (timeout) {
    this.timeout = timeout;
  }

  /**
   * TODO. Refactor cheat code - Check error code
   * Description: Check error code and show toastr error messages suit to statusCode.
  */
  checkStatusCode(body) {
    let cloneBody = JSON.parse(body);
    switch(cloneBody.statusCode) {
      case 401: {
        toastr.error(I18n.t('Error'), I18n.t('ErrorMessages.StatusCode.401Unauthorized'));
        this.props.authPropsActions.signOut();
        this.props.router.history.push('/login');
        break;
      }
      default: {
        // toastr.error(I18n.t('Error'), message);
      }
    }
  }

  execute (url, method, body) {
    let token = getAccessToken();
    if (token){
      this.setToken(token)
    }
    // console.log(JSON.stringify(body), token);
    return new Promise((resolve, reject) => {
      switch (method) {
        case ApiMethod.get: {
          if (!isObjectUndefinedOrNull(body)) {
            let index = 0;
            let query = '';
            Object.keys(body).map((key) => {
              let bullet = index == 0 ? '?' : '&';
              query = query + bullet + key + '=' + body[key];
              index++;
            });

            url = url + query;
          }
          request.get({
            headers: this.headers,
            url: url
          }, (error, response, body) => {
            this.checkStatusCode(body);
            if (response.statusCode == 200 && body && !error) {
              body = JSON.parse(body);
              return body.success == true ? resolve(body) : reject(body);
            } else {
              body = JSON.parse(body);
              return reject(body);
            }
          });
          break;
        }
        case ApiMethod.post:
          request.post({
            headers: this.headers,
            url: url,
            body: JSON.stringify(body)
          }, (error, response, body) => {
            this.checkStatusCode(body);
            if (response && response.statusCode == 200 && body && !error) {
              body = JSON.parse(body);
              return body.success == true ? resolve(body) : reject(body);
            } else {
              body = body ? JSON.parse(body) : {};
              return reject(body);
            }
          });
          break;
        case ApiMethod.put: {
          request.put({
            headers: this.headers,
            url: url,
            body: JSON.stringify(body)
          }, (error, response, body) => {
            this.checkStatusCode(body);
            if (response.statusCode == 200 && body && !error) {
              body = JSON.parse(body);
              return body.success == true ? resolve(body) : reject(body);
            } else {
              body = JSON.parse(body);
              return reject(body);
            }
          });
          break;
        }
        case ApiMethod.delete: {
          request.delete({
            headers: this.headers,
            url: url,
            body: JSON.stringify(body)
          }, (error, response, body) => {
            this.checkStatusCode(body);
            if (response.statusCode == 200 && body && !error) {
              body = JSON.parse(body);
              return body.success == true ? resolve(body) : reject(body);
            } else {
              body = JSON.parse(body);
              return reject(body);
            }
          });
          break;
        }
      }
    });
  }
}
