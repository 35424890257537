import React from 'react';
import Moment from 'moment';
import _ from 'lodash';
import Urls from '../constants/apiUrls';
import buffer from 'buffer';
import I18n from "i18n-js";
import writtenNumber from 'written-number';
import {COMPONENT_TYPE} from "../constants/constants";
import ProgressBar from "../components/common/fields/ProgressBar";
import TextNumberInput from "../components/common/fields/TextNumberInput";

import avatarIcon from '../stylesheets/images/avatar.png';

function setCookie(name, value, expireDays) {
  let d = new Date();
  d.setTime(d.getTime() + (expireDays * 24 * 60 * 60 * 1000));
  let expires = 'expires=' + d.toUTCString();
  document.cookie = name + '=' + value + ';' + expires + ';path=/';
}

function getCookie(cookieName) {
  let name = cookieName + '=';
  let cookieArray = document.cookie.split(';');
  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i];
    while (cookie.charAt(0) == ' ') {
      cookie = cookie.substring(1);
    }
    if (cookie.indexOf(name) == 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }
  return null;
}

function getAccessToken() {
  return getCookie('x-access-token');
}

function getValue(field, data) {
  let value;
  if (field.indexOf('{{data}}') != -1) {
    try {
      field = field.replace(/{{data}}/g, 'data');
      value = eval(field);
    } catch (err) {

    }
  } else {
    if (typeof field === 'string') {
      value = data[field];
    } else {
      if (Object.prototype.toString.call(field) === '[object Array]') {
        value = [];
        field.forEach((i, idx) => {
          value.push(data[i]);
        });
      }
    }
  }
  return value;
}

function convertDisplay(type, data, format = '', params) {
  let value;
  var offset;
  var date;
  switch (type) {
    case 'date':
      offset = new Date().getTimezoneOffset();
      date = new Moment(data).utcOffset(-1 * offset);
      date = date.format(format || 'MM/DD/YYYY');
      value = data ? date : '';
      break;
    case 'time':
      offset = new Date().getTimezoneOffset();
      date = new Moment(data * 1000).utcOffset(-1 * offset);
      date = date.format('HH:mm A');
      value = data ? date : '';
      break;
    case 'age':
      if (data) {
        date = new Date();
        let start = new Date(data);
        value = date.getFullYear() - start.getFullYear();
      } else {
        value = '';
      }
      break;
    case 'dateTime':
      offset = new Date().getTimezoneOffset();
      date = new Moment(data).utcOffset(-1 * offset);
      date = date.format('MM/DD/YYYY HH:mm A');
      value = date;
      break;
    case 'price':
      if (data) {
        let formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 0 /* this might not be necessary */
        });
        value = formatter.format(parseInt(data));
      } else {
        value = '';
      }
      break;
    case 'item':
      value = data >= 0 ? `${data} ${data == 1 ? 'item' : 'items'}` : 'Unlimit';
      break;
    case 'checkbox':
        value = data;
       break;
    case 'boolean':
      value = data ? 'Yes' : 'No';
      break;
    case 'usd':
      value = '$' + data;
      break;
    case 'payment_method':
      value = _.split(_.lowerCase(data), ' ').map(w => _.upperFirst(w)).join(' ');
      break;
    case 'status':
      value = _.split(_.lowerCase(data), ' ').map(w => _.upperFirst(w)).join(' ');
      break;
    case 'groups':
      value = data.map(item => _.get(item, 'nameLowerCase', '')).join(', ');
      break;
    case 'truncate':
      value = _.truncate(data);
      break;
    case 'join':
      value = _.join(data, ', ');
      break;
    default:
      value = data;
  }
  return value;
}

function showAvatar (user, options, newAvatar) {
  if (!user) { return '' }

  let avatar = ''
  if (user.avatar) {
    avatar = (<img className={options.classAvatar || ''} src={newAvatar || user.avatar} alt='' onError={evt => evt.target.setAttribute('src', avatarIcon)} />)
  } else {
    let shortName = _.upperFirst(user.firstName ? user.firstName[0] : '') + _.upperFirst(user.lastName ? user.lastName[0] : '')
    avatar = <span className={options.classDefault || 'avatar-table-default'}>{shortName}</span>
  }

  return avatar
}

function pathImage(path) {
  if (!path) return '';
  path = path.replace('\\', '/')
  return path.indexOf(Urls.baseUrl) === -1 ? (Urls.baseUrl + path) : path;
}

function formatNumber(number) {
  return (new Intl.NumberFormat('en-US')).format(parseInt(number) || 0);
}

function getQuatity(n, y, per) {
  return (new Intl.NumberFormat('en-US')).format(_.round(n * y * (1 + per/100), 3) || 0);
}

function textTrim(text, limit) {
  if(!text)
    return '';
  if(text.length < limit || text.length == limit)
    return text;
  return `${text.substr(0, limit)}...`;
}

function isStringEmpty (text) {
  return text === undefined || text === null || text.trim() === '';
}

function isObjectUndefinedOrNull (obj) {
  return obj == undefined || obj == null;
}

function toBase64 (val) {
  if (this.isObjectUndefinedOrNull(val)) return '';
  return buffer.Buffer(val).toString('base64');
}

function validateEmail (email) {
  let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

function validatePass (pass) {
  let passw = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$/;
  return passw.test(pass);
}

function validateNumberPhone (number) {
  let c1 = number.substring(0, 4);
  if (c1 === '+855') {
    return true;
  } else {
    c1 = number.substring(0, 3);
    if (c1 === '+60' || c1 === '+84') {
      return true;
    } else {
      return false;
    }
  }
}

function getLocation (href) {
  let match = href.match(/^(https?\:)\/\/(([^:\/?#]*)(?:\:([0-9]+))?)([\/]{0,1}[^?#]*)(\?[^#]*|)(#.*|)$/);
  return match && {
    href: href,
    protocol: match[1],
    host: match[2],
    hostname: match[3],
    port: match[4],
    pathname: match[5],
    search: match[6],
    hash: match[7]
  };
}

function randomColorAvatar(str) {
  const AVATAR_COLOR_RANGE = [
    '#1ABC9C',
    '#2ECC71',
    '#9B59B6',
    '#34495E',
    '#F1C40F',
    '#E67E22',
    '#E74C3C',
    '#95A5A6'
  ];
  let charCode = 0;
  if (str && str.length)
    charCode = str.charCodeAt(0) + str.length;
  let index = charCode % AVATAR_COLOR_RANGE.length;
  return AVATAR_COLOR_RANGE[index < AVATAR_COLOR_RANGE.length ? index : 0];
}

function getShortName(firstName, lastName){
  return (firstName? firstName.substring(0, 1) : "G") + (lastName? lastName.substring(0, 1) : "G");
}

export function getMetaImage(url, callback) {
  let img = new Image();
  img.src = url;
  img.onload = function() { callback(url, true, this.width, this.height); }; // eslint-disable-line no-use-before-define
  img.onerror = function() { callback(false); }; // eslint-disable-line no-use-before-define
}

export function validateActualAndUsableOfMaterial(materials) {
  let error = null;
  let errorEmptyActual = true;
  for(let item of materials) {
    const actual = parseInt(_.get(item, "actual", 0));
    const usable = parseInt(_.get(item, "usable", 0));
    if(actual) errorEmptyActual = false;
    if(actual < usable) {
      error = {key: "greater", msg: I18n.t('PagePurchase.UsableNotGreaterQuantity')}
      break;
    }
  }
  if(errorEmptyActual) {
    error = {key: "actual", msg: I18n.t('PagePurchase.LeastAtOneActualMustBeRequired')}
  }
  return error;
}

export function validateQuantityAndUsableOfMaterial(materials) {
  let error = null;
  for(let item of materials) {
    const quantity = parseInt(_.get(item, "quantity", 0));
    const usable = parseInt(_.get(item, "usable", 0));
    if(!quantity) {
      error = {key: "actual", msg: I18n.t('PagePurchase.PleaseEnterQuantity')}
      break;
    }else if(!usable) {
      error = {key: "usable", msg: I18n.t('PagePurchase.PleaseEnterUsable')}
      break;
    }else if(quantity < usable) {
      error = {key: "greater", msg: I18n.t('PagePurchase.UsableNotGreaterQuantity')}
      break;
    }
  }
  return error;
}

export function getComponentByType(item, headerOb, type, value, defaultValue, idx, disabled) {
  let element = null;
  const customProps = {
    ...(!!value && { value }),
    ...(!!defaultValue && { placeholder: defaultValue })
  };
  switch (type) {
    case COMPONENT_TYPE.INPUT: {
      const isActualYield = _.get(headerOb, 'options.isActualYield', false);
      const isAlwaysEnabled = _.get(headerOb, 'options.isAlwaysEnabled', false);
      element = (
        <td key={idx} className={_.get(headerOb, 'options.clsTd', '')}>
          {(isActualYield || value !== "" || !!defaultValue || isAlwaysEnabled ) ?
            <TextNumberInput
              className={_.get(headerOb, 'options.clsIpt', '')}
              onChange={value => {
                _.get(headerOb, "options.event", ()=>{})(item, _.get(headerOb, "value",""), value)
              }}
              {...customProps}
            /> : "-"
          }
        </td>
      )
      break;
    }
    case COMPONENT_TYPE.PROGRESS_BAR:{
      element = (
        <td key={idx} className={_.get(headerOb, 'options.clsTd', '')} style={{width: '15%'}}>
          {value ?
            <ProgressBar value1={_.get(value, "value1","")} value2={_.get(value, "value2","")}/> : '-'
          }
        </td>
      )
      break;
    }
    case COMPONENT_TYPE.LIST:{
      element = (
        <td key={idx} className={_.get(headerOb, 'options.clsTd', '')}>
          {
            value ? (
              <ul>
                {
                  (value ||[]).map((item,index) => (
                    <li key={index} className={_.get(item, "options.liCls","")}>{_.get(item, "value","")}</li>
                  ))
                }
              </ul>
            ) : '-'
          }
        </td>
      )
      break;
    }
    case COMPONENT_TYPE.INPUT_LIST:{
      element = (
        <td key={idx} className={_.get(headerOb, 'options.clsTd', '')}>
          {
            value ? (
              <ul>
                {
                  (value ||[]).map((it,index) => (
                    <li key={index} className={_.get(item, "options.liCls","")}>
                      <TextNumberInput
                        placeholder={_.get(it, "defaultValue",0)}
                        value={_.get(it, "updateValue", "") === 0 ? "" : _.get(it, "updateValue", "")}
                        className="form-control"
                        onChange={(value)=>{
                          _.get(headerOb, "options.event", ()=>{})(item, _.get(headerOb, "value",""), value, index)
                        }}
                      />
                    </li>
                  ))
                }
              </ul>
            ) : '-'
          }
        </td>
      )
      break;
    }
    case COMPONENT_TYPE.TEXT_AREA: {
      element = (
        <td key={idx} className={_.get(headerOb, 'options.clsTd', '')}>
          <textarea
            name="" id="" cols="30" rows="10"
            value={value.updateValue}
            onChange={(evt)=>{
                      _.get(headerOb, "options.event", ()=>{})(item, _.get(headerOb, "value",""), evt.target.value)
                    }}/>
        </td>
      )
      break;
    }
    case COMPONENT_TYPE.CHECKBOX: {
      element = (
        <td key={idx} className={'style-checkbox ' + _.get(headerOb, 'options.clsTd', '')}>
          <input checked={value} disabled= {disabled}
                  className = {!disabled && 'cursor-pointer'}
                  type="checkbox" id={item._id}
                  onChange={(evt)=>{
                    _.get(headerOb, "options.event", ()=>{})(item, _.get(headerOb, "value",""), evt.target.checked)
                  }}/>
          <label htmlFor={item._id}>
            {disabled ? <span style={{backgroundColor: "#d4d0d0"}}><span/></span> : <span className="cursor-pointer"><span/></span>}
          </label>
        </td>
      )
      break;
    }
    case COMPONENT_TYPE.LINK:{
      element = (
        <td key={idx} className={'style-checkbox ' + _.get(headerOb, 'options.clsTd', '')}>
        {(headerOb.logs||[]).map((log, index)=>{
          if(index === 2){
            return (<span  key={index}><a onClick={()=>headerOb.onClick(item, log)}>{log}</a></span>);
          }
          return (
            <span  key={index}><a onClick={()=>headerOb.onClick(item, log)}>{log}</a> / </span>
          )
        })}
        </td>
      )
      break;
    }
    default: {
      const fnc = _.get(headerOb, "options.onClick", null)
      if(fnc) {
        const onClick = () => fnc(item)
        element = (
          <td style={_.get(headerOb, "options.style", {})} key={idx} className={_.get(headerOb, 'options.clsTd', '')} onClick={onClick}>
            <a style={{textDecoration: 'underline'}}>{value===""?'-':value}</a>
          </td>
        )
      } else {
        element = (
          <td style={_.get(headerOb, "options.style", {})} key={idx} className={_.get(headerOb, 'options.clsTd', '') + `${headerOb.name == 'Status' ? value.toLowerCase().replace(' ', '-') : ''}`}>
            {value===""?'-':value}
          </td>
        );
      }

      break;
    }
  }
  return element;
}

export function genVietNamDong(value) {
  const ChuSo = [' không ', ' một ', 'hai', 'ba', 'bốn', 'năm', 'sáu', 'bảy', 'tám', 'chín'];
  const Tien= [ "", " nghìn", " triệu", " tỷ", " nghìn tỷ", " triệu tỷ"];

  function DocSo3ChuSo(baso) {
    var tram;
    var chuc;
    var donvi;
    var KetQua="";
    tram=parseInt(baso/100);
    chuc=parseInt((baso%100)/10);
    donvi=baso%10;
    if(tram==0 && chuc==0 && donvi==0) return "";
    if(tram!=0)
    {
        KetQua += ChuSo[tram] + " trăm ";
        if ((chuc == 0) && (donvi != 0)) KetQua += " linh ";
    }
    if ((chuc != 0) && (chuc != 1))
    {
            KetQua += ChuSo[chuc] + " mươi ";
            if ((chuc == 0) && (donvi != 0)) KetQua = KetQua + " linh ";
    }
    if (chuc == 1) KetQua += " mười ";
    switch (donvi)
    {
        case 1:
            if ((chuc != 0) && (chuc != 1))
            {
                KetQua += " mốt ";
            }
            else
            {
                KetQua += ChuSo[donvi];
            }
            break;
        case 5:
            if (chuc == 0)
            {
                KetQua += ChuSo[donvi];
            }
            else
            {
                KetQua += " lăm ";
            }
            break;
        default:
            if (donvi != 0)
            {
                KetQua += ChuSo[donvi];
            }
            break;
        }
    return KetQua;
  }
  function DocTienBangChu(SoTien)
  {
      var lan=0;
      var i=0;
      var so=0;
      var KetQua="";
      var tmp="";
      var ViTri = new Array();
      if(SoTien<0) return "Số tiền âm !";
      if(SoTien==0) return "Không đồng !";
      if(SoTien>0)
      {
          so=SoTien;
      }
      else
      {
          so = -SoTien;
      }
      if (SoTien > 8999999999999999)
      {
          //SoTien = 0;
          return "Số quá lớn!";
      }
      ViTri[5] = Math.floor(so / 1000000000000000);
      if(isNaN(ViTri[5]))
          ViTri[5] = "0";
      so = so - parseFloat(ViTri[5].toString()) * 1000000000000000;
      ViTri[4] = Math.floor(so / 1000000000000);
       if(isNaN(ViTri[4]))
          ViTri[4] = "0";
      so = so - parseFloat(ViTri[4].toString()) * 1000000000000;
      ViTri[3] = Math.floor(so / 1000000000);
       if(isNaN(ViTri[3]))
          ViTri[3] = "0";
      so = so - parseFloat(ViTri[3].toString()) * 1000000000;
      ViTri[2] = parseInt(so / 1000000);
       if(isNaN(ViTri[2]))
          ViTri[2] = "0";
      ViTri[1] = parseInt((so % 1000000) / 1000);
       if(isNaN(ViTri[1]))
          ViTri[1] = "0";
      ViTri[0] = parseInt(so % 1000);
    if(isNaN(ViTri[0]))
          ViTri[0] = "0";
      if (ViTri[5] > 0)
      {
          lan = 5;
      }
      else if (ViTri[4] > 0)
      {
          lan = 4;
      }
      else if (ViTri[3] > 0)
      {
          lan = 3;
      }
      else if (ViTri[2] > 0)
      {
          lan = 2;
      }
      else if (ViTri[1] > 0)
      {
          lan = 1;
      }
      else
      {
          lan = 0;
      }
      for (i = lan; i >= 0; i--)
      {
         tmp = DocSo3ChuSo(ViTri[i]);
         KetQua += tmp;
         if (ViTri[i] > 0) KetQua += Tien[i];
         if ((i > 0) && (tmp.length > 0)) KetQua += ',';//&& (!string.IsNullOrEmpty(tmp))
      }
     if (KetQua.substring(KetQua.length - 1) == ',')
     {
          KetQua = KetQua.substring(0, KetQua.length - 1);
     }
     if(KetQua.substring(0, 1) == ' ') {
      KetQua = KetQua.substring(1, KetQua.length);
     }
     //KetQua = KetQua.substring(0, KetQua.length - 1);
     return KetQua.charAt(0).toUpperCase() + KetQua.slice(1) + ' đồng';//.substring(0, 1);//.toUpperCase();// + KetQua.substring(1);
  }
  return DocTienBangChu(value)
}

export function genDollarToText(value) {
  const str = `${value}`;
    const data = str.split('.')
    const begin = `${writtenNumber(_.toInteger(data[0]))}`
    const end = `${writtenNumber(_.toInteger(data[1]))}`
    const d = data[0] > 1 ? 'dollars' : 'dollar';
    const c = data[1] > 1 ? 'cents' : 'cent';
    const endText = data[1] > 0 ? ` and ${end} ${c}` : ''
    const text =  `${begin} ${d}${endText}`  ;
    return text.charAt(0).toUpperCase() + text.slice(1);
}

export function jsUcfirst(string) 
{
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function convertStringToCamelVariable(str) {
  const isMutilStr = str.split('.');
  let results = '';
  isMutilStr.forEach((item, index) => {
    if (index === 0) {
      results = `${item}`
    } else {
      results = `${results}${jsUcfirst(item)}`;
    }
  });
  return results;
}

export {
  getCookie,
  setCookie,
  getAccessToken,
  getValue,
  convertDisplay,
  showAvatar,
  pathImage,
  formatNumber,
  getQuatity,
  textTrim, isStringEmpty, isObjectUndefinedOrNull, toBase64
  , validateEmail, validatePass, validateNumberPhone, getLocation, randomColorAvatar, getShortName };
