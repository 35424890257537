import React, { Component } from 'react';

import * as _ from 'lodash';
import I18n from 'i18n-js';
import { toastr } from 'react-redux-toastr';
import SearchBar from '../../../common/components/SearchBar';
import { SHIP_OUT } from '../../../../constants/routeConstants';
import { ACTION_TYPE } from '../../../../constants/constants';
import { connect } from 'react-redux';
import * as selector from '../../../../selectors/ship-out/showShipOutList';
import {
  changeSearchStatus,
  getList,
  changeSelectedFilter,
  changeSearchValue,
  search,
  deleteShipment,
  getExportShipOut,
} from '../../../../actions/ship-out/showShipOutListActions';
import shipOutApi from '../../../../api/shipOutApi';
import { getShipmentDetail, activeScreenEditShipment } from '../../../../actions/ship-out/editShipmentActions';
import TableList from '../../../../components/common/datatables/TableList';
import ModalConfirm from '../../../../components/common/modals/ModalConfirm';
import moment from 'moment';
var FileSaver = require('file-saver');

import icExportIcon from '../../../../stylesheets/images/ic-export.svg';
import CustomPagination from '../../../../components/common/datatables/CustomPagination';

class SubShipOutContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        { name: I18n.t('PageShiping.Date'), value: 'date' },
        { name: I18n.t(`PageShiping.DeclarationNo`), value: 'declarationNo' },
        {
          name: I18n.t(`PageShiping.DeclarationDate`),
          type: 'declarationDate',
          formatType: 'MMM DD, YYYY',
          value: 'declarationDate',
        },
        { name: I18n.t(`PageShiping.ShipMode`), value: `shipMode` },
        { name: I18n.t(`PageShiping.BillNo`), value: `billNo` },
        { name: I18n.t(`PageShiping.ETD`), value: 'etd' },
        { name: I18n.t(`PageShiping.ETA`), value: 'eta' },
        { name: I18n.t(`PageShiping.TotalFee`), value: 'totalFee', options: { clsTd: 'color-red' } },
      ],
      actions: [],
      pageSize: 10,
      pageNumber: 1,
    };
  }

  componentWillMount() {
    this.getList(this.getBaseOption());
  }

  // This function use for get items from search feature
  componentWillReceiveProps(nextProps) {
    const { deleteSuccess } = nextProps;
    if (deleteSuccess) {
      toastr.success(I18n.t('Notify.Success'), I18n.t('Notify.DeleteShipmentSuccess'));
      this.getList(this.getBaseOption());
    }
  }

  componentDidMount() {}

  //This function use for setup query string for request api
  getBaseOption = () => {
    const { pageSize, pageNumber } = this.state;
    let options = {
      history: false,
      query: JSON.stringify({ isCancel: { $ne: true } }),
      sort: JSON.stringify({ createdAt: -1 }),
      pageSize,
      pageNumber,
    };
    return options;
  };

  getList(options) {
    ///
    this.props.getList(options);
  }

  // These 2 functions use for paging
  selectPage = page => {
    let options = this.getBaseOption();
    options.pageNumber = page;
    const { searched, getSearchQuery, selectedFilter, searchValue } = this.props;
    this.getList(getSearchQuery(searched, options, selectedFilter, searchValue));
  };

  displayEditView = item => {
    this.props.getShipmentDetail(item._id);
    this.props.activeScreenEditShipment();
    this.props.router.history.push(SHIP_OUT.SHIP.EDIT);
  };

  showDeleteConfirm = item => {
    let { openModal, closeModal } = this.props.modal;
    let modal = (
      <ModalConfirm
        title={I18n.t('PageProduction.DELETEPACKING')}
        confirmMessage={I18n.t('PageProduction.DeleteConfirm')}
        messageOk={I18n.t('Btn.YES')}
        messageCancel={I18n.t('Btn.CANCEL')}
        handleClose={closeModal}
        handleOk={() => this.deleteShipment(item._id)}
      />
    );
    openModal(modal);
  };

  deleteShipment = id => {
    this.props.deleteShipment(id);
  };

  onChangeFilter = value => {
    this.props.changeSelectedFilter(value);
    this.props.changeSearchStatus(false);
  };

  onChangeValue = (value, pos) => {
    this.props.changeSearchValue(value, pos);
    this.props.changeSearchStatus(false);
  };

  onSearch = () => {
    this.props.changeSearchStatus(true);
    const { searched, getSearchQuery, selectedFilter, searchValue } = this.props;
    this.getList(getSearchQuery(true, this.getBaseOption(), selectedFilter, searchValue));
  };

  btGetExport() {
    const { selectedFilter, searchValue } = this.props;
    let options = this.mapFilter(selectedFilter, searchValue);
    // console.log(options);
    let url = this.props.getExportBom(options);
    FileSaver.saveAs(url, 'Report_ShipOut.xlsx');
  }

  export = item => {
    const id = _.get(item, '_id', undefined);
    if (id) {
      const url = this.props.getExportCustomINV(id);
      FileSaver.saveAs(url, 'CustomINV.xlsx');
    } else {
      toastr.error('Error', 'unexpected error occurred');
    }
  };

  mapFilter(selectedFilter, searchValue) {
    let value = '';
    if (searchValue.from) value = moment(new Date(searchValue.from)).format('YYYY-MM-DD');
    if (searchValue.to && value == '') value += moment(new Date(searchValue.to)).format('YYYY-MM-DD');
    if (searchValue.to && value != '') value += ',' + moment(new Date(searchValue.to)).format('YYYY-MM-DD');
    switch (selectedFilter) {
      case 'billNo':
        return { filter: 'billNo', value: searchValue.value };
      case 'advance':
        return { filter: 'advance', value: searchValue.value };
      case 'advanceNo':
        return { filter: 'advanceNo', value: searchValue.value };
      case 'date':
        return { filter: 'date', value: value };
      case 'declarationNo':
        return { filter: 'decNo', value: searchValue.value };
      case 'declarationDate':
        return { filter: 'decDate', value: value };
      case 'shipMode':
        return { filter: 'shipMode', value: searchValue.value };
      case 'etd':
        return { filter: 'etd', value: value };
      case 'etd':
        return { filter: 'eta', value: value };
      default:
        return {};
    }
  }
  render() {
    let { columns, actions } = this.state;
    let {
      listFilters,
      selectedFilter,
      searchValue,
      listShipOut,
      totalPage,
      currentPage,
      roles: { showCreate, showEdit, showDelete, showView },
    } = this.props;
    actions = [() => ({ type: ACTION_TYPE.EXPORT, title: 'Export Custom INV', click: item => this.export(item) })];
    if (showEdit) {
      actions.push(() => ({ type: ACTION_TYPE.EDIT, click: item => this.displayEditView(item) }));
    }
    if (showDelete) {
      actions.push(() => ({ type: ACTION_TYPE.DELETE, click: item => this.showDeleteConfirm(item) }));
    }
    return (
      <div>
        <div className="wrap-search-position top-5 display-flex">
          <div>
            <SearchBar
              name="Shipping"
              fields={listFilters}
              searchValue={searchValue || {}}
              selectedFilter={selectedFilter}
              onChangeFilter={this.onChangeFilter}
              onChangeValue={this.onChangeValue}
              onSearch={this.onSearch}
            />
          </div>
          <div className="mg-auto-20 size-12 cursor-pointer" onClick={() => this.btGetExport()}>
            Export
            <img className="vertical-align-middle mg-left-10" src={icExportIcon} alt="Edit" />
          </div>
        </div>
        <TableList
          columns={columns || []}
          actions={actions || []}
          items={listShipOut || []}
          options={{ clsTbl: 'global-table' }}
        />
        <CustomPagination items={totalPage} activePage={currentPage} onSelect={this.selectPage} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  listFilters: selector.getListFilters(),
  selectedFilter: selector.getSelectedFilter(state),
  searchValue: selector.getSearchValue(state),
  listShipOut: selector.getDisplayList(state),
  totalPage: selector.getTotalPages(state),
  currentPage: selector.getCurrentPage(state),
  deleteSuccess: selector.getDeleteSuccess(state),
  searched: selector.getSearched(state),
  getSearchQuery: selector.getSearchQuery,
});

const mapDispatchToProps = dispatch => ({
  getList(options) {
    dispatch(getList(options));
  },
  changeSelectedFilter(value) {
    dispatch(changeSelectedFilter(value));
  },
  changeSearchValue(value, pos) {
    dispatch(changeSearchValue(value, pos));
  },
  search(selectedFilter, searchValue) {
    dispatch(search(selectedFilter, searchValue));
  },
  getShipmentDetail(id) {
    dispatch(getShipmentDetail(id));
  },
  deleteShipment(id) {
    dispatch(deleteShipment(id));
  },
  changeSearchStatus(value) {
    dispatch(changeSearchStatus(value));
  },
  getExportBom(data) {
    return getExportShipOut(data);
  },
  getExportCustomINV(id) {
    return shipOutApi.getExportCustomINV(id);
  },
  activeScreenEditShipment() {
    dispatch(activeScreenEditShipment());
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(SubShipOutContainer);
