import {PURCHASE} from '../constants/actionTypes';
import initial from './initialState';
import {calculateTotal} from "../functions/utils";

export default function (state = initial.purchaseState, action) {
  switch (action.type) {
    case PURCHASE.GET_LIST_SUCCESS:
      return {...state, items: action.data.items.map((item, index) => ({...item, id: index + 1, gTotal: calculateTotal(item)})) || [], count: action.data.count || 0};
    case PURCHASE.RELOAD:
      return {...state, reload: !state.reload};
    default:
      return state;
  }
}
