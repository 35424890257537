import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import BaseComponent from '../../base/BaseComponent';
import BarOrderInfo from '../../common/layout/BarOrderInfo';
import SidebarOrders from '../../common/layout/SidebarOrdersCommon';
import orderActions from '../../../actions/orderActions';
import appActions from '../../../actions/appActions';
import productionActions from '../../../actions/productionActions';
import _ from "lodash";
import TableList from "../../common/datatables/TableList";
import {setQAProgress} from "../../../functions/utils";
import {ACTION_TYPE, PRODUCTION_SCREEN} from "../../../constants/constants";
import { PAGINATION } from '../../../constants/constants';
import * as selectors from '../../../selectors/qaOrders';
class ScreenQA extends BaseComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {
      selectedOrder: {},
      headers: [],
      qaProgress: [],
      actions: [],
      pageSize: PAGINATION.PAGE_SIZE,
      pageNumber: PAGINATION.PAGE_NUMBER,
    };
    this.onSubmitSearch = this.onSubmitSearch.bind(this)
    this.handleSelectOrder = this.handleSelectOrder.bind(this);
  }

  componentWillMount(){
    const { pageSize, pageNumber } = this.state;
    const options = {pageSize: pageSize, pageNumber: pageNumber, production: true, filter: true };
    this.props.loadFirstData(options);
    this.props.onChangePageNumber(PAGINATION.PAGE_NUMBER);
    this.props.onChangeSearch();
    // this.props.productionActions.getListQAOrders();
    this.setData(this.props);
  }

  componentWillReceiveProps(nextProps){
    if(!_.isEqual(this.props.qaOrderState, nextProps.qaOrderState)) {
      this.setData(nextProps);
    }
    // if(!_.isEqual(this.props.qaOrderState.reload, nextProps.qaOrderState.reload)) {
    //   this.props.productionActions.getListQAOrders();
    // }
  }

  setData(props){
    const items = _.get(props.qaOrderState, "items", []);
    const {qaData} = props;
    let selectedOrder = _.get(qaData, "selectedOrder",null);
    if(selectedOrder){
      selectedOrder = items.find(x => x._id === selectedOrder._id)
      this.props.setQAData("selectedOrder", selectedOrder);
    }
    selectedOrder = selectedOrder || items[0];
    const {headers, qaProgressData} = setQAProgress(selectedOrder);
    this.setState({
      selectedOrder: selectedOrder || {},
      headers: headers,
      qaProgress: qaProgressData
    })
  }

  handleSelectOrder(item) {
    const {headers, qaProgressData} = setQAProgress(item);
    this.setState({
      selectedOrder: item,
      qaProgress: qaProgressData,
      headers: headers,
    });
    if (this.props.setQAData) {
      this.props.setQAData("selectedOrder", item);
    }
  }

  handleStartProduction(item) {
    this.props.orderActions.editOrder(item._id, {startDate: new Date()}).then(res => {
      res && res.success && this.props.productionActions.getListQAOrders();
    });
  }

  setTabActive(tab) {
    console.log('setTabActive', setTabActive)
    this.setState({tabActive: tab, searchValue: {}});
    this.props.appActions.setPageCurrent(_.assign(_.cloneDeep(this.props.appState.page), {context: {tabSub: tab}}));
    this.props.setQAData("activeTab", tab);
  }

  viewQAProgress(item){
    console.log('viewQAProgress', item)  
    const {qaData} = this.props;
    let {selectedOrder} = qaData;
    selectedOrder = selectedOrder || this.state.selectedOrder;
    let pageList = [...this.props.appState.pageList];
    pageList.push({screen: PRODUCTION_SCREEN.VIEW_QA_PROGRESS, data: {order: selectedOrder, style: item}});
    this.props.appActions.addPage(pageList);
  }


  handleLoadMore(number) {
    const { isSearch, searchText } = this.props
    const {pageSize, pageNumber } = this.state;
    if (isSearch && searchText == '') { // Search not value 
      this.props.cancelChangeSearch();
      this.props.cancelChangePageNumber();
      this.getListFirstOrder(pageSize, pageNumber);
    } else if (isSearch && searchText) { // Search value
      this.props.onChangePageNumber(number)
      this.getListOrderSearch(pageSize, number)
    } else { // Not search
      this.props.onChangePageNumber(number)
      this.props.cancelChangeSearch()
      this.getListOrderMore(pageSize, number)
    }
  }
  getListOrderMore(size, number) {
    const query = { pageSize: size, pageNumber: number, production: true, filter: true};
    this.props.getListOrderMore(_.assign(query));
  }
  
  getListFirstOrder(size, number) {
    const query = { pageSize: size, pageNumber: number, production: true, filter: true };
    this.props.loadFirstData(_.assign(query));
  }

  getListOrderSearch(size, number) {
    const { searchText } = this.props
    const query = { pageNumber: number, pageSize: size, production: true, filter: true, searchKey: searchText};
    if (searchText) {
      this.props.getListOrderMore(_.assign(query));
    }
  }

  onSubmitSearch() {
    const { searchText } = this.props
    const { pageSize, pageNumber } = this.state;
    const querySearch = { pageNumber: pageNumber, pageSize: pageSize, production: true, searchKey: searchText};
    const querySearchNull = { pageNumber: pageNumber, pageSize: pageSize, production: true};
    this.props.cancelChangePageNumber();
    if (searchText) {
      this.props.changeSearch()
      this.props.loadFirstData(_.assign(querySearch));
    } else {
      this.props.cancelChangeSearch()
      this.props.loadFirstData(_.assign(querySearchNull));
    } 
  }

  render() {
    const {tabCurrent, qaOrderState, qaData, roles: {showView}} = this.props;
    let {selectedOrder} = qaData;
    let {headers, qaProgress, actions} = this.state;
    selectedOrder = selectedOrder || this.state.selectedOrder;
    let order = _.cloneDeep(selectedOrder) || {};
    const activeTab = (_.get(qaData, "activeTab",""));
    actions = [];
    if(showView){
      actions.push(() => {
        return { type: ACTION_TYPE.VIEW, click: (item) => this.viewQAProgress(item) };
      })
    };
    return (
      <div className={tabCurrent === 'qa' ? 'show' : 'hide'}>
        <div>
          <SidebarOrders
            {...this.props}
            selectedOrder={selectedOrder || {}}
            orders={_.get(qaOrderState, "items", [])}
            modal={this.props.modal}
            handleSelectedOrder={this.handleSelectOrder}
            componentName='qa'
            handleLoadMore={(pageNumber) => this.handleLoadMore(pageNumber)}
            onSubmitSearch={this.onSubmitSearch}
          />

          <div className="content-main" id="qa-tab">
            <BarOrderInfo order={order} isStartDate onStartProduction={()=>{this.handleStartProduction(order)}}/>

            <TableList {...this.props} items={qaProgress||[]} columns={headers||[]} actions={actions||[]}
                       options={{clsTbl: 'global-table box-shadow-none'}} widthAction="w-70"/>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    appState: state.appState,
    orderState: state.orderState,
    authState: state.authState,
    qaOrderState: state.qaOrderState,

    pageNumber: selectors.getPageNumber(state),
    searchText: selectors.getFilterText(state),
    isLoadMore: selectors.isOpenLoadMore(state),
    isSearch: selectors.isSearch(state),
    items: selectors.getOrders(state),
    isLoading: selectors.isLoading(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    orderActions: bindActionCreators(orderActions, dispatch),
    appActions: bindActionCreators(appActions, dispatch),
    productionActions: bindActionCreators(productionActions, dispatch),


    loadFirstData(options) {
      dispatch(productionActions.getListQAOrders(options));
    },

    getListOrderMore(options) {
        dispatch(productionActions.getListQAOrdersMore(options));
    },

    cancelChangePageNumber() {
        dispatch(productionActions.cancelChangePageNumber());
    },

    onChangePageNumber(number) {
        dispatch(productionActions.changePageNumber(number));
    },

    onChangeSearch(text) {
        dispatch(productionActions.changeFilterText(text ? text : ''));
    },

    cancelChangeSearch() {
        dispatch(productionActions.cancelChangeSearch());
    },

    changeSearch() {
        dispatch(productionActions.changeSearch());
    },

  };
}

ScreenQA.propTypes = {
  authState: PropTypes.object.isRequired,
  appActions: PropTypes.object,
  productionActions: PropTypes.object,
  qaOrderState: PropTypes.object,
  qaData: PropTypes.object,
  setQAData: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(ScreenQA);
