
const C = 'CUSTOMER_PO.CURRENT.EDIT_CUT_TICKET_COLORS';

export default {
  SHOW_MODAL: `${C}.SHOW_MODAL`,
  HIDE_MODAL: `${C}.HIDE_MODAL`,

  SUBMIT: `${C}.SUBMIT`,
  CANCEL_SUBMIT: `${C}.CANCEL_SUBMIT`,

  FETCH_SUGGESTIONS: `${C}.FETCH_SUGGESTIONS`,
  CANCEL_FETCH_SUGGESTIONS: `${C}.CANCEL_FETCH_SUGGESTIONS`,
  CHANGE_SUGGESTIONS: `${C}.CHANGE_SUGGESTIONS`,

  ADD_COLOR: `${C}.ADD_COLOR`,
  REMOVE_COLOR: `${C}.REMOVE_COLOR`,
  CHANGE_COLOR_NAME: `${C}.CHANGE_COLOR_NAME`,

  ADD_SIZE: `${C}.ADD_SIZE`,
  REMOVE_SIZE: `${C}.REMOVE_SIZE`,
  CHANGE_SIZE_QUANTITY: `${C}.CHANGE_SIZE_QUANTITY`,

  FETCH_SELECTABLE_SIZES: `${C}.FETCH_SELECTABLE_SIZES`,
  CANCEL_FETCH_SELECTABLE_SIZES: `${C}.FETCH_SELECTABLE_SIZES`,
  CHANGE_SELECTABLE_SIZES: `${C}.CHANGE_SELECTABLE_SIZES`,

  RESET_DATA: `${C}.RESET_DATA`,
}
