import React, { Component } from 'react';

export default class TextInput extends Component {
  constructor (props) {
    super(props);
    this.state = {
      value: null
    };
  }

  changText (text) {
    let {pattern, validationFunc, onChange, name, outMessage} = this.props.options;
    let reg = new RegExp(pattern || '');
    let correct = !!reg.test(text);

    validationFunc && validationFunc(text);
    onChange(name, text, outMessage, correct);
    this.setState({value: text});
  }

  render () {
    let {value, className, placeholder} = this.props.options;
    value = this.state.value != null ? this.state.value : value;
    value = value || '';

    return (<input type="text" value={value} onChange={(evt) => { this.changText(evt.target.value); }} className={className} placeholder={placeholder} />);
  }
}
