import {
  COMPONENT_TYPE,
  MENU_ITEM,
  PRODUCTION_SCREEN,
  PURCHASE_EVENT,
  PURCHASE_SCREEN,
  PURCHASE_STATUS,
  PURCHASE_TAB,
  PURCHASE_TYPE,
} from '../constants/constants';
import I18n from 'i18n-js';
import moment from 'moment';
import _ from 'lodash';
import numeral from 'numeral';

function convertValueDate(value) {
  return moment(value).format('MMM DD, YYYY');
}

export function genStyleForCell(color) {
  let style = {
    alignment: { vertical: 'center' },
    font: { patternType: 'solid', color: { rgb: color } },
    border: {
      top: { style: 'thin', color: { rgb: '00000000' } },
      bottom: { style: 'thin', color: { rgb: '00000000' } },
      left: { style: 'thin', color: { rgb: '00000000' } },
      right: { style: 'thin', color: { rgb: '00000000' } },
    },
  };
  return style;
}

export function genStyleHeaderForCell(color) {
  let style = {
    alignment: { vertical: 'center' },
    font: { sz: '10.5', bold: true, patternType: 'solid', color: { rgb: color } },
    border: {
      top: { style: 'thin', color: { rgb: '00000000' } },
      bottom: { style: 'thin', color: { rgb: '00000000' } },
      left: { style: 'thin', color: { rgb: '00000000' } },
      right: { style: 'thin', color: { rgb: '00000000' } },
    },
  };
  return style;
}

export function mapDataForCell(data, keys) {
  let putData = [];
  _.map(keys, idata => {
    if (idata.key == 'date') {
      putData.push({
        value: convertValueDate(_.get(data, idata.value, '')),
        style: genStyleForCell(idata.color),
      });
    } else if (idata.key == 'index') {
      putData.push({
        value: idata.value,
        style: genStyleForCell(idata.color),
      });
    } else {
      putData.push({
        value: _.get(data, idata.value, ''),
        style: genStyleForCell(idata.color),
      });
    }
  });
  return putData;
}

export function getRoleByAuthState(authState, moduleKey) {
  let showCreate = false;
  let showEdit = false;
  let showDelete = false;
  let showView = false;
  if (authState && authState.auth && authState.auth.role) {
    const role = authState.auth.role;
    if (role && role.modules) {
      for (let mod of role.modules) {
        if (mod.moduleId && mod.moduleId.key === moduleKey) {
          showCreate = mod.actions && mod.actions.create;
          showEdit = mod.actions && mod.actions.revise;
          showDelete = mod.actions && mod.actions.delete;
          showView = mod.actions && mod.actions.view;
        }
      }
    }
  }
  return { showCreate, showEdit, showDelete, showView };
}

export function getMenuByRole(role, isSuperAdmin) {
  let menu = [];
  role &&
    role.modules &&
    role.modules.forEach(mod => {
      const actions = mod.actions || {};
      const moduleId = mod.moduleId || {};
      if (actions.view || actions.create || actions.revise || actions.delete || isSuperAdmin) {
        menu.push(setMenuItem({ key: moduleId.key, name: moduleId.name }));
      }
    });
  return menu;
}

export function setMenuItem({ prefix, key, name, role }) {
  prefix = prefix || 'dashboard';
  let url = MENU_ITEM[key].url || '/';
  let img = MENU_ITEM[key].img || '';
  return { prefix, name, url, text: name, img, role: role || [] };
}

export function getHeaderMenuNameByActiveScreen(activeScreen) {
  let headerMenuName = '';

  switch (activeScreen) {
    case PURCHASE_SCREEN.PREVIEW_ORDER: {
      headerMenuName = I18n.t('Menu.PreviewPurchase');
      break;
    }
    case PURCHASE_SCREEN.ADDITIONAL_ORDER: {
      headerMenuName = I18n.t('Menu.AddAdditional');
      break;
    }
    case PURCHASE_SCREEN.PLACE_ORDER: {
      headerMenuName = I18n.t('Menu.PlaceOrder');
      break;
    }
    case PURCHASE_SCREEN.EDIT_ORDER: {
      headerMenuName = I18n.t('Menu.EditPurchase');
      break;
    }
    case PURCHASE_SCREEN.STOCK_ORDER: {
      headerMenuName = I18n.t('Menu.PlaceOrder');
      break;
    }
    case PURCHASE_SCREEN.PREVIEW_PURCHASE_ORDER: {
      headerMenuName = I18n.t('Menu.PreviewPurchaseOrder');
      break;
    }
    case PURCHASE_SCREEN.VIEW_HISTORY:
    case PRODUCTION_SCREEN.ADD_PACKING:
    case PRODUCTION_SCREEN.ADD_PACKING_PRE_PACK:
    case PRODUCTION_SCREEN.VIEW_PROGRESS:
    case PRODUCTION_SCREEN.VIEW_QA_PROGRESS: {
      headerMenuName = I18n.t('Menu.View');
      break;
    }
    case PRODUCTION_SCREEN.UPDATE_PROGRESS:
    case PRODUCTION_SCREEN.UPDATE_QA_PROGRESS: {
      headerMenuName = I18n.t('Menu.UpdateProgress');
      break;
    }
    case PRODUCTION_SCREEN.EDIT_ACTUAL_YIELD:
    case PRODUCTION_SCREEN.EDIT_QA_PROGRESS:
    case PRODUCTION_SCREEN.EDIT_PROGRESS: {
      headerMenuName = I18n.t('Menu.Edit');
      break;
    }
  }
  return headerMenuName;
}

export function getActiveScreen(appState) {
  const activeTab = appState && appState.page && appState.page.tab ? appState.page.tab : PURCHASE_TAB.PURCHASE_PLAN;
  let activeScreen = '';
  let activeData = '';
  let isEdit = appState && appState.page && appState.page.isEdit ? appState.page.isEdit : false;
  const pageList = appState.pageList || [];

  if (pageList.length) {
    activeScreen = pageList[pageList.length - 1].screen || '';
    activeData = pageList[pageList.length - 1].data || {};
  }

  return { activeTab, activeScreen, activeData, isEdit };
}

export function getActiveTab(appState) {
  const activeTab = appState && appState.page && appState.page.tab ? appState.page.tab : '';
  let activeScreen = '';
  let activeData = '';
  let isEdit = appState && appState.page && appState.page.isEdit ? appState.page.isEdit : false;
  const pageList = appState.pageList || [];

  if (pageList.length) {
    activeScreen = pageList[pageList.length - 1].screen || '';
    activeData = pageList[pageList.length - 1].data || {};
  }

  return { activeTab, activeScreen, activeData, isEdit };
}

export function setParamsByOrder(order) {
  let showStatus = false;
  let status = '';
  let type = order.type || '';
  // if(!order.status){
  //     order.status = PURCHASE_STATUS.NEW;
  // }
  if (order && order.status) {
    status = order.status;
    switch (order.status) {
      case PURCHASE_STATUS.DRAFT:
      case PURCHASE_STATUS.WAITING_FOR_APPROVAL:
      case PURCHASE_STATUS.USE_STOCK:
        showStatus = false;
        break;
      default:
        showStatus = true;
    }
  }
  return { showStatus, status, type };
}

export function setScreenByPurchaseType(type) {
  switch (type) {
    case PURCHASE_TYPE.RESERVED:
    case PURCHASE_TYPE.USE_STOCK:
    case PURCHASE_TYPE.ADDITIONAL:
      return PURCHASE_SCREEN.PLACE_ORDER;
    case PURCHASE_TYPE.UNRESERVED:
      return PURCHASE_SCREEN.STOCK_ORDER;
  }
}

export function filterData(data, search) {
  if (search && search.key && search.value) {
    data = (data || []).filter(item => {
      const keyData = _.get(item, search.key, '');
      return _.includes(keyData.toString().toLowerCase(), search.value.toString().toLowerCase());
    });
  }
  return data;
}

export function calculateTotal(purchase) {
  if (purchase.materials.length > 0) {
    return purchase.materials.reduce((current, next) => {
      // if (next.payer == 'Customer' || next.payer == 'Subcontractor')
      //   return current;

      // const qt = _.isObject(next.material) && _.isFunction(convertUnit) ? convertUnit(next.needToBuy, next.material) : next.needToBuy
      //   console.log(2222, JSON.stringify(next))
      return current + (next.price * next.needToBuy + (next.surcharge || 0) - (next.discount || 0));
    }, 0);
  }
  return 0;
}

export function isChangeValue(order, purchase) {
  let isChange = false;
  if (order && order.materials && purchase && purchase.materials) {
    for (let i = 0; i < purchase.materials.length; i++) {
      const needToBuy = (_.get(purchase.materials[i], 'needToBuy', 0) || 0).toString();
      const surcharge = (_.get(purchase.materials[i], 'surcharge', 0) || 0).toString();
      const discount = (_.get(purchase.materials[i], 'discount', 0) || 0).toString();
      isChange =
        needToBuy !== (_.get(order.materials[i], 'needToBuy', 0) || 0).toString() ||
        surcharge !== (_.get(order.materials[i], 'surcharge', 0) || 0).toString() ||
        discount !== (_.get(order.materials[i], 'discount', 0) || 0).toString();
      if (isChange) {
        break;
      }
    }
  }
  return isChange;
}

export function isExceedValue(order, purchase, percent) {
  let isExceed = false;
  if (order && order.materials && purchase && purchase.materials) {
    for (let i = 0; i < purchase.materials.length; i++) {
      const needToBuyOrder = _.get(order.materials[i], 'needToBuy', 0);
      const needToBuy = _.get(purchase.materials[i], 'needToBuy', 0);
      isExceed = needToBuy > needToBuyOrder + (needToBuyOrder * percent) / 100;
      if (isExceed) {
        break;
      }
    }
  }
  return isExceed;
}

export function isExceed(value1, value2, percent) {
  return value2 > value1 + (value1 * percent) / 100;
}

export function setStatus(status, type, order, purchase, percent) {
  let newStatus = '';
  const isChange = isChangeValue(order, purchase);
  const isExceed = isExceedValue(order, purchase, percent);

  switch (status) {
    case PURCHASE_STATUS.DRAFT: {
      if (type == PURCHASE_EVENT.SEND) {
        newStatus = isExceed ? PURCHASE_STATUS.WAITING_FOR_APPROVAL : PURCHASE_STATUS.NEW;
      }
      break;
    }
    case PURCHASE_STATUS.NEW:
    case PURCHASE_STATUS.IN_PROGRESS: {
      if (type == PURCHASE_EVENT.SEND) {
        newStatus = isChange ? PURCHASE_STATUS.WAITING_FOR_APPROVAL : PURCHASE_STATUS.NEW;
      }
      break;
    }
    case PURCHASE_STATUS.APPROVED: {
      if (type == PURCHASE_EVENT.SEND) {
        newStatus = PURCHASE_STATUS.NEW;
      }
      break;
    }
  }

  return newStatus;
}

export function setStatusList(statusList, status, purchase_type) {
  switch (status) {
    case PURCHASE_STATUS.NEW: {
      if (purchase_type === PURCHASE_TYPE.USE_STOCK) {
        statusList = [
          { value: 'NEW', label: 'New' },
          { value: 'COMPLETED', label: 'Completed' },
        ];
      }
      break;
    }
    case PURCHASE_STATUS.IN_PROGRESS: {
      statusList = [
        { value: 'IN_PROGRESS', label: 'In progress' },
        { value: 'IN_TRANSIT', label: 'In transit' },
        { value: 'IN_PORT', label: 'In port' },
        { value: 'WAITING_FOR_SHIPPING', label: 'Waiting for shipping' },
        { value: 'IN_WAREHOUSE', label: 'In warehouse' },
        { value: 'COMPLETED', label: 'Completed' },
      ];
      break;
    }
    case PURCHASE_STATUS.IN_PORT: {
      statusList = [
        { value: 'IN_PORT', label: 'In port' },
        { value: 'WAITING_FOR_SHIPPING', label: 'Waiting for shipping' },
        { value: 'IN_WAREHOUSE', label: 'In warehouse' },
        { value: 'COMPLETED', label: 'Completed' },
      ];
      break;
    }
    case PURCHASE_STATUS.IN_TRANSIT: {
      statusList = [
        { value: 'IN_TRANSIT', label: 'In transit' },
        { value: 'IN_PORT', label: 'In port' },
        { value: 'WAITING_FOR_SHIPPING', label: 'Waiting for shipping' },
        { value: 'IN_WAREHOUSE', label: 'In warehouse' },
        { value: 'COMPLETED', label: 'Completed' },
      ];
      break;
    }
    case PURCHASE_STATUS.WAITING_FOR_SHIPPING: {
      statusList = [
        { value: 'WAITING_FOR_SHIPPING', label: 'Waiting for shipping' },
        { value: 'IN_WAREHOUSE', label: 'In warehouse' },
        { value: 'COMPLETED', label: 'Completed' },
      ];
      break;
    }
    case PURCHASE_STATUS.IN_WAREHOUSE: {
      statusList = [
        { value: 'IN_WAREHOUSE', label: 'In warehouse' },
        { value: 'COMPLETED', label: 'Completed' },
      ];
      break;
    }
    case PURCHASE_STATUS.COMPLETED: {
      statusList = [{ value: 'COMPLETED', label: 'Completed' }];
      break;
    }
  }
  return statusList;
}

export function setProductionProgress(progressData) {
  let newProgressData = (progressData || []).map(item => {
    let progress = _.get(item, 'progress', []);
    let sizeKey = _.uniqWith(
      progress.reduce((arr, it) => [...arr, ..._.get(it, 'sizes', []).map(si => si.name)], []),
      _.isEqual
    );
    let progressOb = {};
    if (!sizeKey.length) {
      const sizes = _.get(item, 'sizes', {});
      Object.keys(sizes).forEach(sk => {
        progressOb[sk] = 0;
      });
    } else {
      sizeKey.forEach(sk => {
        progressOb[sk] = Math.round(
          progress.reduce(
            (sum, pr) =>
              (sum += _.get(pr, 'sizes', []).reduce((sSum, sz) => (sSum += sz.name === sk ? sz.quantity : 0), 0)),
            0
          )
        );
      });
    }
    const totalCompleted = Object.keys(progressOb).reduce((rs, key) => (rs += progressOb[key]), 0);
    return {
      ...item,
      progressOb,
      totalCompleted: totalCompleted,
      percent: {
        value1: totalCompleted > item.totalOrder ? 100 : ((totalCompleted * 100) / item.totalOrder).toFixed(2),
      },
    };
  });
  return newProgressData;
}

export function getUnCompletedOrders(orders) {
  let unCompletedOrders = (orders || []).filter(item => !item.isCompleted); // Todo: and g-total < = shipped
  return unCompletedOrders;
}

export function setDataCutTicketProgress(data) {
  return data;
}

/*******************************************************
 Progress Items : {
  ...cutTicketItems,
  headers : all of sizes of CutTicketColors
  progressByDate: separate all of sizes of colors by Date
  {
    date: new moment(key).format("MMM DD, YYYY"),
    sizes,
    colorsByDate: a list of colors containing all of sizes by every date
  }
}
 *******************************************************/
export function setCutTicketProgressItems(order, cutTicketItems, styleId) {
  const cutTickets = _.get(
    _.get(order, 'styleOrders', []).find(x => _.get(x, 'style._id', '') === styleId),
    'cutTickets',
    []
  );
  let contractId = '';
  let isOnlyOneContractor = true;
  for (let x of cutTickets) {
    if (contractId && contractId !== x.contractorId) {
      isOnlyOneContractor = false;
      break;
    }
    contractId = x.contractorId || '';
  }
  let styleContractor = isOnlyOneContractor ? _.get(cutTickets, '0.contractor', '') : '';
  const items = (cutTicketItems || []).map(item => {
    const startHeader = [
      {
        name: I18n.t('PageProduction.UpdateDate'),
        value: 'date',
        type: 'date',
        formatType: 'MMM DD, YYYY',
      },
    ];
    const headers = getColorSizeHeadersByCutTicketColor(item, startHeader);
    const progressGroupByDate = _.groupBy(
      _.get(item, 'colors', []).reduce((arr, color) => [...arr, ..._.get(color, 'progress', [])], []),
      'date'
    );
    const colors = _.groupBy(_.get(item, 'colors', []), 'name');
    let clo = {};
    let colorSizes = {};
    let colorsByDates = [];
    _.forIn(colors, (cl, k) => {
      clo = _.groupBy(
        cl.reduce(
          (arr, c) => [
            ...arr,
            ..._.get(c, 'progress', []).map(prItem => ({
              ...prItem,
              colorId: c._id,
            })),
          ],
          []
        ),
        'date'
      );
      colorSizes = _.groupBy(
        cl.reduce(
          (arr, c) => [
            ...arr,
            ..._.get(c, 'sizes', []).map(prItem => ({
              ...prItem,
              colorId: c._id,
            })),
          ],
          []
        ),
        'name'
      );
      let progressAllSize = _.groupBy(
        cl
          .reduce((ar, cL) => [...ar, ..._.get(cL, 'progress', [])], [])
          .reduce((xrr, pr) => [...xrr, ..._.get(pr, 'sizes', [])], []),
        'name'
      );
      _.forIn(progressAllSize, (prAS, k) => {
        progressAllSize[k] = prAS.reduce((q, si) => q + si.quantity, 0);
      });
      const totalOrderColor = Object.keys(colorSizes).reduce(
        (tt, cs) => tt + colorSizes[cs].reduce((qa, cS) => qa + (cS.quantity || 0), 0),
        0
      );
      _.forIn(colorSizes, (vl, key) => {
        colorSizes[key] = {
          quantity: Math.round((vl || []).reduce((s, it) => s + _.get(it, 'quantity', 0), 0)),
        };
      });
      let dates = [];
      _.forIn(clo, (c, x) => {
        let sizes = _.groupBy(
          clo[x].reduce((ar, s) => [...ar, ..._.get(s, 'sizes', [])], []),
          'name'
        );

        _.forIn(sizes, (vl, key) => {
          let sizeOrder = _.get(colorSizes[key], 'quantity', 0);
          let updateValue = Math.round((vl || []).reduce((s, it) => s + _.get(it, 'quantity', 0), 0));
          let progressValue = progressAllSize[key];
          let remaining = Math.round(sizeOrder > progressValue ? sizeOrder - progressValue : 0);
          sizes[key] = {
            _id: _.get(vl, '0._id', ''),
            totalOrder: sizeOrder,
            remaining: remaining,
            updateValue: updateValue,
          };
        });
        dates.push({
          _progressId: (clo[x] || []).reduce((ar, i) => [...ar, { colorId: i.colorId, _id: i._id }], []),
          date: x,
          sizes: sizes,
        });
      });
      colorsByDates = [
        ...colorsByDates,
        {
          _ids: colors[k].reduce((idArr, c) => [...idArr, _.get(c, '_id', '')], []),
          name: k,
          dates: dates,
          totalOrder: totalOrderColor,
        },
      ];
    });
    let progressByDate = [];
    _.forIn(progressGroupByDate, (vl, key) => {
      let sizes = _.groupBy(
        vl.reduce((arr, v) => [...arr, ..._.get(v, 'sizes', [])], []),
        'name'
      );
      _.forIn(sizes, (vl, key) => {
        sizes[key] = Math.round((vl || []).reduce((s, it) => s + _.get(it, 'quantity', 0), 0));
      });
      let clrsByDate = colorsByDates.map(clr => {
        return {
          _ids: clr._ids || '',
          name: clr.name || '',
          sizes: (clr.dates || []).find(d => (d.date || '') === key),
          totalOrder: clr.totalOrder || 0,
        };
      });
      progressByDate = [
        ...progressByDate,
        {
          date: key,
          sizes,
          colorsByDate: clrsByDate,
        },
      ];
    });
    progressByDate.sort((a, b) => {
      return new Date(b.date) - new Date(a.date);
    });

    return {
      ...item,
      contractor: !isOnlyOneContractor
        ? _.get(
            cutTickets.find(x => x._id === item._id),
            'contractor',
            ''
          )
        : '',
      headers: headers,
      progressByDate: progressByDate,
    };
  });
  return { items, styleContractor };
}

export function getColorSizeHeadersByCutTicketColor(cutTicketColor, startHeader) {
  const headers = _.uniqWith(
    _.get(cutTicketColor, 'colors', []).reduce(
      (arr, color) => [...arr, ..._.get(color, 'sizes', []).reduce((ar, si) => [...ar, _.get(si, 'name', '')], [])],
      []
    ),
    _.isEqual
  );
  return [
    ...startHeader,
    ...headers.map(item => ({
      name: item,
      value: 'sizes.' + item,
      options: { clsTh: 'center', clsTd: 'center' },
    })),
  ];
}

export function setUpdateProgressData(data, activeScreen, onChange) {
  const startHeaders = [
    {
      name: I18n.t('PageProduction.QTY'),
      type: 'text',
      dataType: 'text',
      value: 'totalOrder',
      options: { clsTh: 'w-50', clsTd: 'color-red' },
    },
    {
      name: I18n.t('PageProduction.Color'),
      type: 'text',
      dataType: 'text',
      value: 'name',
      options: { clsTh: 'w-100', clsTd: 'bold' },
    },
  ];
  let headers = _.cloneDeep(_.get(Object.assign({}, data), 'headers', []));
  headers.shift();

  headers = [
    ...startHeaders,
    ...headers.map(header => ({
      name: header.name || '',
      type: COMPONENT_TYPE.INPUT,
      dataType: COMPONENT_TYPE.INPUT,
      default: (activeScreen === PRODUCTION_SCREEN.EDIT_PROGRESS ? 'sizes.' : '') + header.value + '.remaining',
      placeholder: (activeScreen === PRODUCTION_SCREEN.EDIT_PROGRESS ? 'sizes.' : '') + header.value + '.remaining',
      value: (activeScreen === PRODUCTION_SCREEN.EDIT_PROGRESS ? 'sizes.' : '') + header.value + '.updateValue',
      options: {
        clsTh: 'center w-50',
        clsTd: 'center w-50',
        clsIpt: 'input-color-sizes center',
        event: onChange,
        isAlwaysEnabled: true,
      },
    })),
  ];

  const colors = _.groupBy(_.get(data, 'colors', []), 'name');
  let colorsAndSizes = [];
  _.forIn(colors, (vl, key) => {
    let sizes = _.groupBy(
      vl.reduce((ar, cl) => [...ar, ..._.get(cl, 'sizes', [])], []),
      'name'
    );
    let progressAllSize = _.groupBy(
      vl
        .reduce((ar, cl) => [...ar, ..._.get(cl, 'progress', [])], [])
        .reduce((xrr, pr) => [...xrr, ..._.get(pr, 'sizes', [])], []),
      'name'
    );
    _.forIn(progressAllSize, (prAS, k) => {
      progressAllSize[k] = prAS.reduce((q, si) => q + si.quantity, 0);
    });
    _.forIn(sizes, (s, k) => {
      const quant = s.reduce((q, si) => q + si.quantity, 0);
      sizes[k] = {
        // updateValue will be map with value when render tablelist items
        // updateValue: Math.round((quant - progressAllSize[k]) < 0 ? 0 : (quant - (progressAllSize[k] || 0))),
        // remaining will be map with placeholder when render tablelist items
        remaining: Math.round(quant - progressAllSize[k] < 0 ? 0 : quant - (progressAllSize[k] || 0)),
        quantity: Math.round(quant),
      };
    });

    colorsAndSizes.push({
      _ids: vl.reduce((idArr, c) => [...idArr, c._id || ''], []),
      name: key || '',
      sizes,
      totalOrder: Math.round(Object.keys(sizes).reduce((total, ke) => total + _.get(sizes[ke], 'quantity', 0), 0)),
    });
  });

  return { newData: colorsAndSizes, headers };
}

export function setBodyUpdateCutTicketColorProgress(tableData) {
  const data = _.cloneDeep(tableData);
  let body = [];
  const newDate = new Date().toISOString();
  data.forEach(item => {
    let sizes = [];
    _.forIn(item.sizes || {}, (size, key) => {
      sizes.push({
        name: key,
        quantity: (size.updateValue || 0) / 1,
      });
    });

    item._ids.forEach(_id => {
      body.push({
        _id: _id || '',
        progress: {
          date: newDate,
          sizes: sizes,
        },
      });
    });
  });
  return body;
}

export function setBodyEditCutTicketColorProgress(tableData) {
  const data = [...tableData];
  let body = [];
  data.forEach(item => {
    let sizes = [];
    _.forIn(_.get(item, 'sizes.sizes', {}), (size, key) => {
      sizes.push({
        name: key,
        quantity: (size.updateValue || 0) / 1,
      });
    });

    item._ids.forEach(_id => {
      body.push({
        _id: _id || '',
        progress: {
          _id: _.get(
            _.get(item, 'sizes._progressId', []).find(x => x.colorId === _id),
            '_id',
            ''
          ),
          date: _.get(item, 'sizes.date', ''),
          sizes: sizes,
        },
      });
    });
  });
  return body;
}

export function getDataTableActualYield(actualYield, onChange) {
  const headers = [
    { name: I18n.t('PageProduction.Material'), value: 'info.material.name' },
    {
      name: I18n.t('PageProduction.Content'),
      value: 'info.material.data.content',
      options: { clsTh: 'center', clsTd: 'center' },
    },
    { name: I18n.t('PageProduction.%'), value: 'info.percent', options: { clsTh: 'center', clsTd: 'center' } },
    {
      name: I18n.t('PageProduction.CustomerYield'),
      value: 'info.yield',
      options: { clsTh: 'center', clsTd: 'center' },
    },
    { name: I18n.t('PageProduction.Actual'), value: 'info.actual', options: { clsTh: 'center', clsTd: 'center' } },
    {
      name: I18n.t('PageProduction.Unit'),
      value: 'info.material.data.unit',
      options: { clsTh: 'center', clsTd: 'center' },
    },
  ];

  const editHeaders = [
    { name: I18n.t('PageProduction.Material'), value: 'info.material.name' },
    {
      name: I18n.t('PageProduction.Content'),
      value: 'info.material.data.content',
      options: { clsTh: 'center', clsTd: 'center' },
    },
    { name: I18n.t('PageProduction.%'), value: 'info.percent', options: { clsTh: 'center', clsTd: 'center' } },
    {
      name: I18n.t('PageProduction.CustomerYield'),
      value: 'info.yield',
      options: { clsTh: 'center', clsTd: 'center' },
    },
    {
      name: I18n.t('PageProduction.Actual'),
      value: 'info.actual',
      type: COMPONENT_TYPE.INPUT,
      dataType: COMPONENT_TYPE.INPUT,
      options: {
        isActualYield: true,
        clsTh: 'center w-50',
        clsTd: 'center w-50',
        clsIpt: 'input-color-sizes center',
        event: onChange,
      },
    },
    {
      name: I18n.t('PageProduction.Unit'),
      value: 'info.material.data.unit',
      options: { clsTh: 'center', clsTd: 'center' },
    },
  ];
  const newData = actualYield.map(item => {
    const self = _.get(item, 'yield.self', []);
    const contrast = _.get(item, 'yield.contrast', []);
    const _70D = _.get(item, 'yield._70D', []);
    const optionalTrims = _.get(item, 'yield.optionalTrims', []);
    const specialTrims = _.get(item, 'yield.specialTrims', []);
    let materials = [self, ...contrast, ..._70D, ...optionalTrims, ...specialTrims];
    return {
      ...item,
      headers,
      editHeaders,
      materials,
    };
  });
  return newData;
}

export function setQAProgress(order) {
  let qaProgressData = [];
  let headers = [
    { name: I18n.t('PageProduction.TotalOrder'), value: 'totalOrder' },
    { name: I18n.t('PageProduction.TotalProduced'), value: 'totalProduced' },
    { name: I18n.t('PageProduction.Style'), value: 'style', options: { clsTh: 'center', clsTd: 'center' } },
  ];
  const styleOrders = _.get(order, 'styleOrders', []);

  const sizeKeys = _.uniqWith(
    styleOrders
      .reduce((ctAr, st) => [...ctAr, ...st.cutTickets], [])
      .reduce((clAr, ct) => [...clAr, ...ct.colors], [])
      .reduce((sAr, cl) => [...sAr, ..._.get(cl, 'sizes', []).map(si => si.name)], []),
    _.isEqual
  );
  headers = [
    ...headers,
    ...sizeKeys.map(sKey => ({
      name: sKey,
      value: 'sizes.' + sKey,
      type: COMPONENT_TYPE.LIST,
      options: { clsTh: 'center', clsTd: 'center' },
    })),
    {
      name: '',
      value: 'percent',
      options: { clsTh: 'center', clsTd: 'color-green center' },
      type: COMPONENT_TYPE.PROGRESS_BAR,
    },
    {
      name: I18n.t('PageProduction.TotalPass'),
      value: 'totalPass',
      options: { clsTh: 'center', clsTd: 'color-green center' },
    },
  ];
  qaProgressData = styleOrders.map(styleOrder => {
    const QAProgress = _.groupBy(
      (styleOrder.cutTickets || [])
        .reduce((clAr, ct) => [...clAr, ...ct.colors], [])
        .reduce(
          (prAr, cl) => [...prAr, ..._.get(cl, 'QAProgress', []).reduce((sAr, pr) => [...sAr, ...pr.sizes], [])],
          []
        ),
      'name'
    );
    const progress = (styleOrder.cutTickets || [])
      .reduce((clAr, ct) => [...clAr, ...ct.colors], [])
      .reduce((prAr, cl) => [...prAr, ..._.get(cl, 'progress', []).reduce((sAr, pr) => [...sAr, ...pr.sizes], [])], []);
    const totalOrder = (styleOrder.cutTickets || []).reduce((total, ct) => total + ct.gTotal, 0);
    const sizeKeyByStyle = _.uniqWith(
      (styleOrder.cutTickets || [])
        .reduce((clAr, ct) => [...clAr, ...ct.colors], [])
        .reduce((sAr, cl) => [...sAr, ..._.get(cl, 'sizes', []).map(si => si.name)], []),
      _.isEqual
    );
    let qaSizes = {};
    sizeKeyByStyle.forEach(sKey => {
      qaSizes[sKey] = [
        { value: (QAProgress[sKey] || []).reduce((s, q) => s + q.pass, 0), options: { liCls: 'color-green' } },
        { value: (QAProgress[sKey] || []).reduce((s, q) => s + q.fail, 0), options: { liCls: 'color-yellow' } },
      ];
    });
    const totalProduced = progress.reduce((s, pr) => s + _.get(pr, 'quantity', 0), 0);
    const totalPass = Object.keys(qaSizes).reduce((s, key) => s + _.get(qaSizes[key], '0.value'), 0);
    const totalFail = Object.keys(qaSizes).reduce((s, key) => s + _.get(qaSizes[key], '1.value'), 0);
    const cutTickets = _.get(styleOrder, 'cutTickets', []).map(cutTicket => {
      const headersByCut = [
        {
          name: I18n.t('PageProduction.UpdateDate'),
          value: 'date',
          type: 'date',
          dataType: 'date',
          formatType: 'MMM DD, YYYY',
        },
      ];
      const colorsByCut = _.get(cutTicket, 'colors', []).map(color => {
        let listQAProgressByDate = [];
        let qaProgressByDate = _.groupBy(_.get(color, 'QAProgress', []), 'date');
        let sizesColorUpdate = _.groupBy(_.get(color, 'sizes', []), 'name');

        _.forIn(sizesColorUpdate, (s, nKey) => {
          sizesColorUpdate[nKey] = {
            pass: {
              remaining: 0,
              updateValue: 0,
            },
            fail: {
              updateValue: 0,
            },
          };
        });
        _.forIn(qaProgressByDate, (qaP, date) => {
          let sizes = _.groupBy(
            qaP.reduce((arr, qa) => [...arr, ...qa.sizes], []),
            'name'
          );
          _.forIn(sizes, (si, name) => {
            sizes[name] = {
              pass: si.reduce((s, i) => s + i.pass, 0),
              fail: si.reduce((s, i) => s + i.fail, 0),
            };
          });
          listQAProgressByDate.push({
            date: date,
            sizes: sizes,
            _colorId: color._id,
            colorName: color.name,
          });
        });
        return {
          ...color,
          listQAProgressByDate,
          sizesColorUpdate,
        };
      });

      const colorsAndSizesByDate = _.groupBy(
        colorsByCut.reduce((arr, clBC) => [...arr, ...clBC.listQAProgressByDate], []),
        'date'
      );
      const sizesByCut = _.uniqWith(
        colorsByCut.reduce((arr, cl) => [...arr, ...cl.sizes.map(s => s.name)], []),
        _.isEqual
      );

      sizesByCut.forEach(sKey => {
        headersByCut.push({
          name: sKey,
          value: 'sizes.' + sKey + '.value',
          type: COMPONENT_TYPE.LIST,
          options: { clsTh: 'center', clsTd: 'center' },
        });
      });
      const cutQAProgressByDate = _.groupBy(
        colorsByCut.reduce((arr, clBC) => [...arr, ...clBC.QAProgress], []),
        'date'
      );
      let qaProgressListByDate = [];
      _.forIn(cutQAProgressByDate, (ctBD, date) => {
        let sizesByDate = _.groupBy(
          ctBD.reduce((arr, ct) => [...arr, ...ct.sizes], []),
          'name'
        );
        _.forIn(sizesByDate, (sBD, sKey) => {
          sizesByDate[sKey] = {
            value: [
              { value: sBD.reduce((s, sbd) => s + sbd.pass, 0), options: { liCls: 'color-green' } },
              { value: sBD.reduce((s, sbd) => s + sbd.fail, 0), options: { liCls: 'color-yellow' } },
            ],
            _ids: sBD.reduce((arr, sbd) => [...arr, sbd._id], []),
          };
        });
        qaProgressListByDate.push({
          date: date,
          sizes: sizesByDate,
          colorsAndSizesByDate: colorsAndSizesByDate[date],
        });
      });
      return {
        ...cutTicket,
        headers: headersByCut,
        qaProgressListByDate: qaProgressListByDate,
      };
    });
    return {
      _id: _.get(styleOrder, 'style._id', ''),
      cutTickets: cutTickets,
      style: _.get(styleOrder, 'style.style', ''),
      sizes: qaSizes,
      totalPass: totalPass,
      totalOrder: totalOrder,
      totalProduced: totalProduced,
      percent: {
        value1: ((totalPass * 100) / totalProduced).toFixed(2),
        value2: ((totalFail * 100) / totalProduced).toFixed(2),
      },
    };
  });

  return { headers, qaProgressData };
}

export function setDataUpdateQAProgress(data, onChange) {
  let headers = _.get(_.cloneDeep(data), 'headers', []);
  headers.splice(0, 1);
  headers = [
    { name: I18n.t('PageProduction.QTYOrder'), value: 'qtyOrder', options: { clsTd: 'center' } },
    {
      name: I18n.t('PageProduction.QTYProduced'),
      value: 'qtyProduced',
      options: { clsTh: 'center', clsTd: 'center' },
    },
    { name: I18n.t('PageProduction.Color'), value: 'name', options: { clsTh: 'center', clsTd: 'center' } },
    { name: '', value: 'passFail', type: COMPONENT_TYPE.LIST, options: { clsTh: 'center', clsTd: 'center' } },
    ...headers.map(header => {
      return {
        name: header.name,
        type: COMPONENT_TYPE.INPUT_LIST,
        value: header.value,
        options: { clsTh: 'center', clsTd: 'center', event: onChange },
      };
    }),
    { name: '', value: 'note', type: COMPONENT_TYPE.TEXT_AREA, options: { event: onChange } },
  ];
  let tableData = _.get(data, 'colors', []).map(color => {
    const qtyOrder = _.get(color, 'sizes', []).reduce((s, size) => s + size.quantity, 0);
    const qtyProduced = _.get(color, 'progress', [])
      .reduce((arr, pr) => [...arr, ...pr.sizes], [])
      .reduce((s, si) => s + si.quantity, 0);
    let progress = _.groupBy(
      _.get(color, 'progress', []).reduce((arr, pr) => [...arr, ...pr.sizes], []),
      'name'
    );
    let qaProgress = _.groupBy(
      _.get(color, 'QAProgress', []).reduce((arr, pr) => [...arr, ...pr.sizes], []),
      'name'
    );
    _.forIn(progress, (pr, sKey) => {
      progress[sKey] = pr.reduce((s, i) => s + i.quantity, 0);
    });

    _.forIn(qaProgress, (pr, sKey) => {
      qaProgress[sKey] = {
        pass: pr.reduce((s, i) => s + i.pass, 0),
        fail: pr.reduce((s, i) => s + i.fail, 0),
      };
    });
    let sizes = _.groupBy(_.get(color, 'sizes', []), 'name');

    _.forIn(sizes, (size, sKey) => {
      const passAndFail = _.get(qaProgress[sKey], 'pass', 0) + _.get(qaProgress[sKey], 'fail', 0);
      sizes[sKey] = {
        value: [
          {
            defaultValue: progress[sKey] > passAndFail ? progress[sKey] - passAndFail : 0,
            updateValue: 0,
          },
          {
            updateValue: 0,
          },
        ],
      };
    });
    return {
      _id: color._id,
      name: color.name,
      passFail: [
        { value: 'Pass', options: { liCls: 'color-green' } },
        { value: 'Fail', options: { liCls: 'color-yellow' } },
      ],
      sizes,
      qtyOrder: qtyOrder,
      qtyProduced: qtyProduced,
      note: '',
    };
  });

  return { headers, tableData };
}

export function setCutTicketQAProgressItems(order, cutTickets, styleId) {
  const orderCutTickets = _.get(
    _.get(order, 'styleOrders', []).find(x => _.get(x, 'style._id', '') === styleId),
    'cutTickets',
    []
  );
  let contractId = '';
  let isOnlyOneContractor = true;
  for (let x of orderCutTickets) {
    if (contractId && contractId !== x.contractorId) {
      isOnlyOneContractor = false;
      break;
    }
    contractId = x.contractorId || '';
  }
  let styleContractor = isOnlyOneContractor ? _.get(orderCutTickets, '0.contractor', '') : '';

  const QAProgress = _.groupBy(
    (cutTickets || [])
      .reduce((clAr, ct) => [...clAr, ...ct.colors], [])
      .reduce(
        (prAr, cl) => [...prAr, ..._.get(cl, 'QAProgress', []).reduce((sAr, pr) => [...sAr, ...pr.sizes], [])],
        []
      ),
    'name'
  );
  const progress = (cutTickets || [])
    .reduce((clAr, ct) => [...clAr, ...ct.colors], [])
    .reduce((prAr, cl) => [...prAr, ..._.get(cl, 'progress', []).reduce((sAr, pr) => [...sAr, ...pr.sizes], [])], []);
  const sizeKeyByStyle = _.uniqWith(
    (cutTickets || [])
      .reduce((clAr, ct) => [...clAr, ...ct.colors], [])
      .reduce((sAr, cl) => [...sAr, ..._.get(cl, 'sizes', []).map(si => si.name)], []),
    _.isEqual
  );
  let qaSizes = {};
  sizeKeyByStyle.forEach(sKey => {
    qaSizes[sKey] = [
      { value: (QAProgress[sKey] || []).reduce((s, q) => s + q.pass, 0), options: { liCls: 'color-green' } },
      { value: (QAProgress[sKey] || []).reduce((s, q) => s + q.fail, 0), options: { liCls: 'color-yellow' } },
    ];
  });
  const totalProduced = progress.reduce((s, pr) => s + _.get(pr, 'quantity', 0), 0);
  const totalPass = Object.keys(qaSizes).reduce((s, key) => s + _.get(qaSizes[key], '0.value'), 0);
  const totalFail = Object.keys(qaSizes).reduce((s, key) => s + _.get(qaSizes[key], '1.value'), 0);

  const percent = {
    value1: ((totalPass * 100) / totalProduced).toFixed(2),
    value2: ((totalFail * 100) / totalProduced).toFixed(2),
  };

  const items = cutTickets.map(cutTicket => {
    const headersByCut = [
      {
        name: I18n.t('PageProduction.UpdateDate'),
        value: 'date',
        type: 'date',
        dataType: 'date',
        formatType: 'MMM DD, YYYY',
      },
    ];
    const colorsByCut = _.get(cutTicket, 'colors', []).map(color => {
      let listQAProgressByDate = [];
      let qaProgressByDate = _.groupBy(_.get(color, 'QAProgress', []), 'date');
      let sizesColorUpdate = _.groupBy(_.get(color, 'sizes', []), 'name');

      _.forIn(sizesColorUpdate, (s, nKey) => {
        sizesColorUpdate[nKey] = {
          pass: {
            remaining: 0,
            updateValue: 0,
          },
          fail: {
            updateValue: 0,
          },
        };
      });
      _.forIn(qaProgressByDate, (qaP, date) => {
        let sizes = _.groupBy(
          qaP.reduce((arr, qa) => [...arr, ...qa.sizes], []),
          'name'
        );
        _.forIn(sizes, (si, name) => {
          sizes[name] = {
            pass: si.reduce((s, i) => s + i.pass, 0),
            fail: si.reduce((s, i) => s + i.fail, 0),
          };
        });
        listQAProgressByDate.push({
          _id: _.get(qaP[0], '_id', ''),
          note: _.get(qaP[0], 'note', ''),
          date: date,
          sizes: sizes,
          _colorId: color._id,
          colorName: color.name,
        });
      });
      return {
        ...color,
        listQAProgressByDate,
        sizesColorUpdate,
      };
    });

    const colorsAndSizesByDate = _.groupBy(
      colorsByCut.reduce((arr, clBC) => [...arr, ...clBC.listQAProgressByDate], []),
      'date'
    );
    const sizesByCut = _.uniqWith(
      colorsByCut.reduce((arr, cl) => [...arr, ...cl.sizes.map(s => s.name)], []),
      _.isEqual
    );

    sizesByCut.forEach(sKey => {
      headersByCut.push({
        name: sKey,
        value: 'sizes.' + sKey + '.value',
        type: COMPONENT_TYPE.LIST,
        options: { clsTh: 'center', clsTd: 'center' },
      });
    });
    const cutQAProgressByDate = _.groupBy(
      colorsByCut.reduce((arr, clBC) => [...arr, ...clBC.QAProgress], []),
      'date'
    );
    let qaProgressListByDate = [];
    _.forIn(cutQAProgressByDate, (ctBD, date) => {
      let sizesByDate = _.groupBy(
        ctBD.reduce((arr, ct) => [...arr, ...ct.sizes], []),
        'name'
      );
      _.forIn(sizesByDate, (sBD, sKey) => {
        sizesByDate[sKey] = {
          value: [
            { value: sBD.reduce((s, sbd) => s + sbd.pass, 0), options: { liCls: 'color-green' } },
            { value: sBD.reduce((s, sbd) => s + sbd.fail, 0), options: { liCls: 'color-yellow' } },
          ],
          _ids: sBD.reduce((arr, sbd) => [...arr, sbd._id], []),
        };
      });
      qaProgressListByDate.push({
        date: date,
        sizes: sizesByDate,
        colorsAndSizesByDate: colorsAndSizesByDate[date],
      });
    });
    qaProgressListByDate.sort((a, b) => {
      return new Date(b.date) - new Date(a.date);
    });
    return {
      ...cutTicket,
      contractor: !isOnlyOneContractor
        ? _.get(
            orderCutTickets.find(x => x._id === cutTicket._id),
            'contractor',
            ''
          )
        : '',
      headers: headersByCut,
      qaProgressListByDate: qaProgressListByDate,
    };
  });
  return { percent, items, styleContractor };
}

export function setBodyUpdateCutTicketColorQAProgress(tableData) {
  const data = [...tableData];
  let body = [];
  const newDate = new Date().toISOString();
  data.forEach(item => {
    let sizes = [];
    _.forIn(_.get(item, 'sizes', {}), (size, key) => {
      sizes.push({
        name: key,
        pass: _.get(size, 'value.0.updateValue', 0),
        fail: _.get(size, 'value.1.updateValue', 0),
      });
    });

    body.push({
      _id: item._id || '',
      QAProgress: {
        date: newDate,
        sizes: sizes,
        note: _.get(item, 'note.updateValue'),
      },
      name: _.get(item, 'name', ''),
    });
  });
  return body;
}

export function setBodyEditCutTicketColorQAProgress(tableData) {
  const data = [...tableData];
  let body = [];
  data.forEach(item => {
    let sizes = [];
    _.forIn(_.get(item, 'sizes', {}), (size, key) => {
      sizes.push({
        name: key,
        pass: _.get(size, 'value.0.updateValue', 0),
        fail: _.get(size, 'value.1.updateValue', 0),
      });
    });

    body.push({
      _id: item._colorId || '',
      QAProgress: {
        _id: item._id,
        date: item.date,
        sizes: sizes,
        note: _.get(item, 'note.updateValue'),
      },
      name: _.get(item, 'name', ''),
    });
  });
  return body;
}

export function setDataEditQAProgress(data, onChange) {
  let headers = _.get(_.cloneDeep(data), 'headers', []);
  headers.splice(0, 1);
  headers = [
    { name: I18n.t('PageProduction.QTYOrder'), value: 'qtyOrder', options: { clsTd: 'center' } },
    {
      name: I18n.t('PageProduction.QTYProduced'),
      value: 'qtyProduced',
      options: { clsTh: 'center', clsTd: 'center' },
    },
    { name: I18n.t('PageProduction.Color'), value: 'name', options: { clsTh: 'center', clsTd: 'center' } },
    { name: '', value: 'passFail', type: COMPONENT_TYPE.LIST, options: { clsTh: 'center', clsTd: 'center' } },
    ...headers.map(header => {
      return {
        name: header.name,
        type: COMPONENT_TYPE.INPUT_LIST,
        value: header.value,
        options: { clsTh: 'center', clsTd: 'center', event: onChange },
      };
    }),
    { name: '', value: 'note', type: COMPONENT_TYPE.TEXT_AREA, options: { event: onChange } },
  ];

  let tableData = _.get(_.cloneDeep(data), 'selectedDate.colorsAndSizesByDate', []).map(color => {
    const originColor = _.get(_.cloneDeep(data), 'colors', []).find(c => c._id === color._colorId);
    const qtyOrder = _.get(originColor, 'sizes', []).reduce((s, size) => s + size.quantity, 0);
    const qtyProduced = _.get(originColor, 'progress', [])
      .reduce((arr, pr) => [...arr, ...pr.sizes], [])
      .reduce((s, si) => s + si.quantity, 0);

    let progress = _.groupBy(
      _.get(originColor, 'progress', []).reduce((arr, pr) => [...arr, ...pr.sizes], []),
      'name'
    );
    let qaProgress = _.groupBy(
      _.get(originColor, 'QAProgress', []).reduce((arr, pr) => [...arr, ...pr.sizes], []),
      'name'
    );
    _.forIn(progress, (pr, sKey) => {
      progress[sKey] = pr.reduce((s, i) => s + i.quantity, 0);
    });

    _.forIn(qaProgress, (pr, sKey) => {
      qaProgress[sKey] = {
        pass: pr.reduce((s, i) => s + i.pass, 0),
        fail: pr.reduce((s, i) => s + i.fail, 0),
      };
    });
    let sizes = _.get(color, 'sizes', {});
    _.forIn(sizes, (size, sKey) => {
      const passAndFail = _.get(qaProgress[sKey], 'pass', 0) + _.get(qaProgress[sKey], 'fail', 0);

      sizes[sKey] = {
        value: [
          {
            defaultValue: progress[sKey] >= passAndFail ? progress[sKey] - passAndFail + size.pass + size.fail : 0,
            updateValue: size.pass,
          },
          {
            updateValue: size.fail,
          },
        ],
      };
    });
    return {
      _id: color._id,
      _colorId: color._colorId,
      name: color.colorName,
      note: {
        updateValue: color.note,
      },
      date: color.date,
      sizes: sizes,
      qtyOrder: qtyOrder,
      qtyProduced: qtyProduced,
      passFail: [
        { value: 'Pass', options: { liCls: 'color-green' } },
        { value: 'Fail', options: { liCls: 'color-yellow' } },
      ],
    };
  });
  return { headers, tableData: tableData };
}

export function buildCustomerPOToCheckList(customerPOs, selectedCustomerPOs) {
  return customerPOs.map(cus => {
    return {
      ...cus,
      multiStyles: _.get(cus, 'styleOrders', []).map(style => ({
        cutTickets: style.cutTickets || [],
        _id: _.get(style, 'style._id', ''),
        name: _.get(style, 'style.style', ''),
        active: true,
      })),
      name: cus.customerPO,
      active: selectedCustomerPOs.findIndex(seCus => seCus._id === cus._id) >= 0,
    };
  });
}

export function refineQuantity(value) {
  return (value || '').replace(/([^0-9]).*?/g, '') * 1;
}

export const convertCurrency = (money, currency) => {
  const formated = numeral(money).format('0,0.00').replace('+', '');
  return money > 0 ? (currency == 'USD' ? `$${formated}` : `${formated} ${currency}`) : 0;
};
