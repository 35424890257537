import React from "react";
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import _ from 'lodash';
import * as R from 'ramda';

import IC_ADD_ORDER from '../../../stylesheets/images/ic-add-order.svg';
import IC_EDIT from "../../../stylesheets/images/ic-edit-x.svg";
import IC_DELETE from "../../../stylesheets/images/ic-cursor-pointer.svg";
import loadingIcon from '../../../stylesheets/images/loading.svg';
const SidebarItemList = props => (

  <div className="content-sidebar clearfix">
    {props.canAddOrder && (
      <div className="md content-sidebar-add-order">
        <span className="cursor-pointer" onClick={() => props.onAddOrderClick()}>
          <img src={IC_ADD_ORDER} alt="Add Order" />{" "}
          {I18n.t("PageOrder.AddOrder")}
        </span>
      </div>
    )}
    <div className="md content-sidebar-search">
      <input
        type="text"
        value={props.searchText || ""}
        onChange={evt => props.onChangeSearchText(evt.target.value)}
        onKeyPress={evt => evt.key === "Enter" && props.onSubmitSearch()}
        placeholder="Search"
      />
      <span onClick={() => props.onSubmitSearch()}>
        <i className="fa fa-search cursor-pointer" aria-hidden="true" />
      </span>
    </div>

    <div className="md content-sidebar-menu">
      <ul>
        {_.map(props.items || [], item => (
          <li
            key={R.path(['value'], item)}
            className={R.pathOr(false, ['active'], item) ? "active" : ""}
            title={`${R.path(['label'], item)}`}
            onClick={() => props.onItemClick(item)}
          >
            {_.truncate(R.path(['label'], item), { length: 23 })}
            <span className="line-column">
              <span />
            </span>
            <div className="icon-edit-delete">
              {props.showEditItemBtn && (
                <span
                  className="cursor-pointer"
                  onClick={() => props.onEditItemClick(item)}
                >
                  <img
                    src={IC_EDIT}
                    alt="Edit"
                  />
                </span>
              )}
              {props.showDeleteItemBtn && (
                <span
                  className="cursor-pointer"
                  onClick={() => props.onDeleteItemClick(item)}
                >
                  <img
                    src={IC_DELETE}
                    alt=""
                  />
                </span>
              )}
            </div>
          </li>
        ))}
        {(props.isLoading == true) &&
          <li className="loading-icon"><img src={loadingIcon} /></li>
        }
        {(props.isLoadMore == true && props.isLoading == false)  &&
          <li className="button-load-more"><span onClick={() => props.handleLoadMore(props.numberPage + 1)}>Load more</span></li>
        }
      </ul>
    </div>
  </div>
);

SidebarItemList.propTypes = {
  canAddOrder: PropTypes.bool.isRequired,
  showEditItemBtn: PropTypes.bool.isRequired,
  showDeleteItemBtn: PropTypes.bool.isRequired,
  searchText: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  isLoadMore: PropTypes.bool,
  numberPage: PropTypes.number.isRequired,
  onAddOrderClick: PropTypes.func,
  onEditItemClick: PropTypes.func,
  onItemClick: PropTypes.func,
  onDeleteItemClick: PropTypes.func,
  onChangeSearchText: PropTypes.func,
  onSubmitSearch: PropTypes.func,
  handleLoadMore: PropTypes.func,
}

SidebarItemList.defaultProps = {
  onAddOrderClick: f => f,
  onEditItemClick: f => f,
  onItemClick: f => f,
  onDeleteItemClick: f => f,
  onChangeSearchText: f => f,
  onSubmitSearch: f => f,
  handleLoadMore: f => f,
}

export default SidebarItemList;
