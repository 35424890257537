import {Component} from 'react';
import _ from 'lodash';

export default class BaseComponent extends Component {
  constructor (props, context) {
    super(props, context);
  }

  checkPermission (user, actions, data) {
    let {component} = this.state;
    let hasRight = false;
    user.permissions && actions.forEach(action => {
      if (user.permissions[component + '_' + action] ||
        (data && data.creator === user.id && user.permissions[component + '_own'])) {
        hasRight = hasRight || true;
      }
    });
    if (user.role == 'SUPER_ADMIN') {
      hasRight = true;
    }
    return hasRight;
  }

  makeQueryWithPermission (query) {
    let {component} = this.state;
    let {authState} = this.props;
    let user = authState.auth || {};
    if (user.role === 'SUPER_ADMIN') { return query; }

    let permissions = user.permissions || {};
    let condition = !(permissions[component + '_read'] || permissions[component + '_write']) &&
      permissions[component + '_own'] ? {query: {creator: user.id}} : {};

    return _.merge(query || {}, condition);
  }

  convertUnit(n, {isConvert = false, convertTo = '1'}) {
    if(!isConvert) return 0
    const arr = convertTo.split('/')
    if(arr.length == 2) 
      return  _.ceil(n * _.toNumber(arr[0]) / _.toNumber(arr[1]))
    
    if(arr.length == 1) 
      return _.ceil(n * _.toNumber(arr[0]))

    return n
  }

  getUnit(material, cv = false) {
    if(!cv || !_.get(material, 'material.isConvert', false)) return _.get(material, 'material.data.unit', '')
    return _.get(material, 'material.convertUnit', '')
  }

  allowAdd () {
    let {component} = this.state;
    let {authState} = this.props;
    let user = authState.auth || {};
    if (user.role === 'SUPER_ADMIN') { return true; }

    let permissions = user.permissions || {};
    return permissions[component + '_write'] || permissions[component + '_own'];
  }
}
