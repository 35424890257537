import { createSelector } from 'reselect';
import _ from 'lodash';


/**
 * Selectors for overview information of invoice (first component)
 *
 */
export const getSelectableImpExpForwarders = ({ createPayableInvoiceImpExpContainer }) => {

  return createPayableInvoiceImpExpContainer.selectableImpExpForwarders.map(item => ({id: item._id, name: item.forwarder}));
}

export const getSelectedImpExpInfo = ({ createPayableInvoiceImpExpContainer }) => {
  return createPayableInvoiceImpExpContainer.selectedImpExpInfo;
}

export const getSelectableCreditDebits = ({ createPayableInvoiceImpExpContainer }) => {
  /**
   * Get selectable credit debit notes (filtered by import/export company) from reducer
   */
  return createPayableInvoiceImpExpContainer.selectableCreditDebits;
}

export const getSelectedCreditDebit = ({ createPayableInvoiceImpExpContainer }) => {
  return createPayableInvoiceImpExpContainer.selectableCreditDebits;
}

export const getAmount = ({ createPayableInvoiceImpExpContainer }) => {
  const {containersDetail} = createPayableInvoiceImpExpContainer;
  if(containersDetail) {
    return _.sum(containersDetail.map(item => ((item.cost || 0) + (item.chargesIncurred || 0) + (item.liftOnFee || 0) + (item.liftOffFee || 0))))
  }
}




/**
 * Get values in VAT section
 *
 */
export const getMainVAT = ({ createPayableInvoiceImpExpContainer }) => {
  return createPayableInvoiceImpExpContainer.mainVAT;
}

export const getMainTotalAmount = ({ createPayableInvoiceImpExpContainer }) => {
  let temp = createPayableInvoiceImpExpContainer.containersDetail
  if(temp) {
    return _.sum(temp.map(item => item.cost));
  }
}

export const getMainVATAmount = createSelector(
  [
  ({ createPayableInvoiceImpExpContainer }) => createPayableInvoiceImpExpContainer.mainVAT,
  getMainTotalAmount
  ],
  (mainVAT, total) => {
    return (total*parseFloat(mainVAT.taxRate))/100;
  }
)

export const getGrandTotalVAT = createSelector(
  [getMainTotalAmount, getMainVATAmount],
  (totalAmount, vatAmount) => {
    return totalAmount + vatAmount;
  }
)

const genDisplayContainerModel = (model, checked) => {
  if (!model) return {};
  return  _.assign( new Proxy(model, {
      get(obj, prop) {
          switch (prop) {
              case 'raw':
                  return obj;
              default:
                  return obj[prop];
          }
      },
  }), {checked: checked});
};

export const getCheckedList = ({ createPayableInvoiceImpExpContainer }) => createPayableInvoiceImpExpContainer.checkedContainers;

export const getSelectableContainers = createSelector(
  [
    ({ createPayableInvoiceImpExpContainer }) => createPayableInvoiceImpExpContainer.containers,
    ({ createPayableInvoiceImpExpContainer }) => createPayableInvoiceImpExpContainer.searchText,
    getCheckedList,
  ],
  (containers, query, checkedList) => {
    const data =  _.cloneDeep(containers).map(it => 
      {
        const checked = checkedList.findIndex(item => item === it._id) >= 0
        return genDisplayContainerModel(it, checked)
      }
    ).filter(item => !item.cont || item.cont.toLowerCase().indexOf(query.toLowerCase()) > -1)
      return data.map(item => ({...item, name: item.cont}))
  }
)


export const getDetailedContainers = ({ createPayableInvoiceImpExpContainer }) => {
  if(createPayableInvoiceImpExpContainer.containersDetail) {
    const data = createPayableInvoiceImpExpContainer.containersDetail 
    return data;
  }
}

export const fnGetEditingContainer = ({ createPayableInvoiceImpExpContainer }) => id => {
  const {containersDetail} = createPayableInvoiceImpExpContainer;
  if(containersDetail) {
    return containersDetail.find(item => item._id == id)
  }
};

// export const isEditingContainer = createSelector(
//   [getEditingContainer],
//   (editingCont) => {
//     if (editingCont) return true;
//     return false;
//   }
// );

/**
 * Get preview invoice when creating
 * The results must have these information
 * {
 *    _id, active?, name, issueDate, invNo, creditDebit, VAT,
 *    amountInv, totalAmountInv, items: [{contNo, truckNo,
 *    route, warehouse, pickUp, dropOff,
 *    cost, chargeIncurred, liftOnInv, liftOnFee,
 *    liftOffInv, liftOffFee, note }], 
 * }
 */
export const getPreviewInvoice = createSelector(
  [getSelectableContainers], 
  (containers) => {
    // TODO.
    throw "NotImplementedException";
  }
);

export const getTotalAmount = createSelector(
  [
    getMainVATAmount,
    getAmount,
  ], (vatTotal, total) => {
    return (total + vatTotal);
  }
)


export const getTotalAmountSay = createSelector(
  [getTotalAmount],
  (totalAmount) => {
    // TODO. Get total amount in words
    throw "NotImplementedException";
  }
);

export const getSearchText = ({createPayableInvoiceImpExpContainer}) => createPayableInvoiceImpExpContainer.searchText;

export const getTempData = ({createPayableInvoiceImpExpContainer}) => createPayableInvoiceImpExpContainer.tempData;

export const fnGenerateRequestBody = createSelector(
  [
    ({createPayableInvoiceImpExpContainer}) => createPayableInvoiceImpExpContainer,
    getAmount,
    getMainTotalAmount,
  ], (data, amount, totalAmount) => {
    const {selectedImpExpInfo, mainVAT, containersDetail} = data;
    let result = {};
    if(selectedImpExpInfo)
    {
        result = {
        typeInvoice: "containerInvoice",
        invNo: selectedImpExpInfo.invoiceNo,
        issuedDate: selectedImpExpInfo.issuedDate,
        dueDate: selectedImpExpInfo.dueDate,
        amount: amount,
        currency: 'VND',
        invoiceDetail: {
          name: selectedImpExpInfo.name,
          vat: { 
            vatNo: mainVAT.vatNo, 
            vatDate: mainVAT.vatDate, 
            taxRate: parseFloat(mainVAT.taxRate)/100, 
            totalAmount: totalAmount
          },
          containers: containersDetail.map(item => (_.omit({...item, name: (item.cont) + (item.contType ? ` - ${item.contType}` : ''), quantity: item.qty}, ["_id", "cont", "contType", "qty"])))
        }
      }
    }
    return result;
  }
)
// export const fnGenerateRequestBody = ({ createPayableInvoiceImpExpContainer }) => () => {
//   /**
//    * Return a function that will use to generate an api request body that will be used to post to api
//    * The type of the body will depend on selected import/export company
//    */
//   const {selectedImpExpInfo, mainVAT, containersDetail} = createPayableInvoiceImpExpContainer

  
// }

export const getSuccess = ({createPayableInvoiceImpExpContainer}) => createPayableInvoiceImpExpContainer.success;

export const getCanPreview = ({ createPayableInvoiceImpExpContainer }) => !_.isEmpty(createPayableInvoiceImpExpContainer.checkedContainers);

export const genDataForPreview = createSelector (
  [
    getDetailedContainers
  ], (listContainer) => {
    let data = [];
    _.cloneDeep(listContainer).map(item => {
      data.push(_.assign(item, {
        contNo: item.cont + (item.contType ? ` - ${item.contType}` : ''),
      }))
    })
    return data;
  }
)

export const getActiveScreenStatus = ({createPayableInvoiceImpExpContainer}) => createPayableInvoiceImpExpContainer.activeScreenPreview;