import Base from './base';

class SystemSetting extends Base {
  constructor() {
    super();
    this.getListSystemSettingDAL = this.getListSystemSettingDAL.bind(this);
    this.getListNotificationsDAL = this.getListNotificationsDAL.bind(this);
    this.updateEmailSettingDAL = this.updateEmailSettingDAL.bind(this);
    this.updateProductionDAL = this.updateProductionDAL.bind(this);
    this.updatePurchaseDeviationDAL = this.updatePurchaseDeviationDAL.bind(this);
    this.updateAgeDefinitionDAL = this.updateAgeDefinitionDAL.bind(this);
    this.updateSetting = this.updateSetting.bind(this);

  }

  updateEmailSettingDAL(id, body) {

    return super.execute(this.Urls.setting.update + '/' + id, this.ApiMethod.put, body);
  }

  updateProductionDAL(id, body) {
    return super.execute(this.Urls.setting.update + '/' + id, this.ApiMethod.put, body);
  }

  getListSystemSettingDAL(body) {
    return super.execute(this.Urls.setting.list, this.ApiMethod.get, body);
  }

  getListNotificationsDAL(query) {
    return super.execute(this.Urls.setting.list, this.ApiMethod.get, query);
  }

  updatePurchaseDeviationDAL(id, body) {
    return super.execute(this.Urls.setting.update + '/' + id, this.ApiMethod.put, body);
  }

  updateAgeDefinitionDAL(id, body) {
    return super.execute(this.Urls.setting.update + '/' + id, this.ApiMethod.put, body);
  }

  updateSetting(id, body) {
    return super.execute(this.Urls.setting.update + '/' + id, this.ApiMethod.put, body);
  }

}

export default new SystemSetting();
