import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { withRouter } from 'react-router';
import I18n from 'i18n-js';
import * as PATH from '../../../../constants/routeConstants'; 
import EditInvoiceSupplierDomesticView from '../../../components/accounting/PayableInvoiceList/EditInvoiceSuppilerDomestic';
import ReceiptModal from '../../../components/accounting/PayableInvoiceList/ReceiptModal';
import * as actions from '../../../../actions/accounting/payable/editInvoiceSupplierDomestic';
import * as selector from '../../../../selectors/accounting/payable/editInvoiceSupplierDomestic';
import UploadTransferFileModal from '../../../components/accounting/PayableInvoiceList/UploadTransferFileModal';

class EditInvoiceSupplierDomestic extends Component {

    constructor(props) {
        super(props);
        this.state = ({
            openReceiptModal: false,
            openTransferModal: false,
            convertCurrencies: [
                'USD',
                'VND',
            ]
        })
    }
    componentWillMount() {
        const {convertCurrencies} = this.state;
        const {supplierId} = this.props
        this.props.changeSelectableConvertCurrencies(convertCurrencies)
    }

    componentWillReceiveProps(nextProps) {
        const {success, activeScreen} = nextProps;
        if(success) {
            nextProps.resetSuccessStatus();
            nextProps.router.history.push(PATH.ACCOUNTING_PAYABLE_INVOICE_URL)
            toastr.success(I18n.t('Notify.Success'), 'Update Invoice Success')
        }
        if(!activeScreen) {
            nextProps.router.history.goBack()
        }
    }

    componentDidMount() {
        this.props.resetData()
    }

    openReceiptModal = () => {
        const {receiptFromInvoice} = this.props;
        this.props.createReceipt(receiptFromInvoice)
        this.setState({openReceiptModal: true})
    }

    closeReceiptModal = () => {
        this.props.changeEmptyReceipt()
        this.setState({openReceiptModal: false})
    }

    openTransferModal = () => {
        const {filesSaved} = this.props;
        this.props.copyFileToRawData(filesSaved);
        this.setState({openTransferModal: true})
    }

    closeTransferModal = () => {
        this.props.emptyFilesInRawData();
        this.setState({openTransferModal: false})
    }

    save = (paymentType, data) => {
        const {payAmount, totalAmount} = this.props;
        if(payAmount == 0) {
            delete data.payment;
            this.props.save(paymentType, data, totalAmount);
        } else {
            this.props.save(paymentType, data, totalAmount);
        }
    }

    render() {
        const {openReceiptModal, openTransferModal} = this.state;
        const { transferFiles, receiptData } = this.props;
        return (
            <div>
                <EditInvoiceSupplierDomesticView 
                    {...this.props}
                    save = {(paymentType, data) => this.save(paymentType, data)}
                    openReceiptModal = {(value) => this.openReceiptModal(value)}
                    openTransferModal = {() => this.openTransferModal()}
                />
                <ReceiptModal 
                    openModal = {openReceiptModal}
                    close = {this.closeReceiptModal}
                    receiptData = {receiptData}
                    changeReceiptValue = {(key, value) => this.props.changeReceiptValue(key, value)} 
                    onSubmitReceipt = {data => this.props.onSubmitReceipt(data)}
                />
                <UploadTransferFileModal
                    isOpen = {openTransferModal}
                    transferFiles = {transferFiles}
                    onClose = {this.closeTransferModal}
                    onAddFile = {file => this.props.onAddFile(file)}
                    onRemoveFile = {id => this.props.onRemoveFile(id)}
                    onSubmit = {(files) => this.props.onSubmitFiles(files)}
                />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    paymentType: selector.getPaymentType(state),
    supplier: selector.getSupplier(state),
    supplierId: selector.getSupplierId(state),
    issuedDate: selector.getIssuedDate(state),
    dueDate: selector.getDueDate(state),
    invoiceNo: selector.getInvoiceNo(state),
    payAmount: selector.getPayAmount(state),
    totalAmount: selector.getTotalAmount(state),
    paid: selector.getPaid(state),
    remaining: selector.getRemaining(state),
    constSupplierName: selector.getConstSupplierName(state),
    selectableConvertCurrencies: selector.getSelectableConvertCurrencies(state),
    selectedConvertCurrency: selector.getSelectCurrency(state),
    convertRate: selector.getConvertRate(state),
    vatValue: selector.getVATValue(state),
    listCheckablePurchaseOrder: selector.getListCheckablePurchaseOrder(state),
    editablePurchaseOrders: selector.getListPurchaseOrderDetail(state),
    currencyTypeUSD: selector.getCurrencyTypeUSDOfSupplier(state),
    currencyTypeVND: selector.getCurrencyTypeVNDOfSupplier(state),
    transferFiles: selector.getTransferFiles(state),
    dataApi: selector.fnGenerateRequestData(state),
    filesSaved: selector.getFilesSaved(state),
    receiptData: selector.getReceiptDataFromRawData(state),
    receiptFromInvoice: selector.getReceiptFromInvoice(state),
    emptyReceipt: selector.getEmptyReceipt(state),
    success: selector.getSuccess(state),
    disableChangeValue: selector.getDisableChangeValue(state),
    amountConvert: selector.getAmountConvert(state),
    totalAmountConvert: selector.getTotalAmountConvert(state),
    activeScreen: selector.getActiveScreenStatus(state),
    selectableCredits: selector.getListCreditDebit(state),
    selectedCredit: selector.getCreditDebit(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    changeSelectableConvertCurrencies(value) {
        dispatch(actions.changeSelectableConvertCurrencies(value))
    },
    changeSupplier(value) {
        dispatch(actions.changeSupplier(value))
    },
    changeIssuedDate(value) {
        dispatch(actions.changeIssuedDate(value))
    },
    changeInvoiceNo(value) {
        dispatch(actions.changeInvoiceNo(value))
    },
    changeDueDate(value) {
        dispatch(actions.changeDueDate(value))
    },
    changeCreditDebit(value) {
        dispatch(actions.changeCreditDebit(value))
    },
    changePaymentType(value) {
        dispatch(actions.changePaymentType(value))
    },
    changePayAmount(value, remaining, paid) {
        dispatch(actions.changePayAmount(value, remaining, paid))
    },
    toggleActivePurchaseOrder(id, active) {
        dispatch(actions.toggleActivePurchaseOrder(id, active))
    },
    changeCheckedPurchaseOrder(item, checked) {
        dispatch(actions.changeCheckedPurchaseOrder(item._id, checked))
    },
    changeSelectedConvertCurrency(value) {
        dispatch(actions.changeSelectedConvertCurrency(value))
    },
    changeConvertRate(value) {
        dispatch(actions.changeConvertRate(value))
    },
    changeSubVATNo(id, value) {
        dispatch(actions.changeVATNo(id, value))
    },
    changeSubVATDate(id, value) {
        dispatch(actions.changeVATDate(id, value))
    },
    changeSubVATTaxRate(id, value) {
        dispatch(actions.changeTaxRate(id, value))
    },
    changeSubVATNote(id, value) {
        dispatch(actions.changeNote(id, value))
    },
    onAddFile(file) {
        dispatch(actions.addFileTransfer(file))
    },
    onRemoveFile(id) {
        dispatch(actions.onRemoveFile(id))
    },
    save(paymentType, data, totalAmount) {
        dispatch(actions.save(paymentType, data, totalAmount))
    },
    copyFileToRawData(files) {
        dispatch(actions.copyFileToRawData(files))
    },
    emptyFilesInRawData() {
        dispatch(actions.emptyFilesInRawData())
    },
    onSubmitFiles(files) {
        dispatch(actions.onSubmitFiles(files))
    },
    createReceipt(value) {
        dispatch(actions.createReceipt(value))
    },
    changeReceiptValue(key, value) {
        dispatch(actions.changeReceiptValue(key, value))
    },
    onSubmitReceipt(data) {
        dispatch(actions.onSubmitReceipt(data))
    },
    changeEmptyReceipt() {
        dispatch(actions.changeEmptyReceipt())
    },
    resetSuccessStatus() {
        dispatch(actions.resetSuccessStatus())
    },
    changeMainVATNo(value) {
        dispatch(actions.changeMainVATNo(value))
    },
    changeMainVATTotalAmount(value) {
        dispatch(actions.changeMainVATTotalAmount(value))
    },
    changeMainVATDate(value) {
        dispatch(actions.changeMainVATDate(value))
    },
    changeMainVATTaxRate(value) {
        dispatch(actions.changeMainVATTaxRate(value))
    },
    resetData() {
        dispatch(actions.resetData())
    }
})
export default connect(mapStateToProps, mapDispatchToProps) (withRouter(EditInvoiceSupplierDomestic));