import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import * as PATH from '../../../../constants/routeConstants';
import moment from 'moment';

import FormGroup from '../../forms/FormGroup';
import Input from '../../forms/Input';
import SearchSelect from '../../forms/SearchSelect';
import DateTimeField from 'react-datetime';
import formatCurrency from 'format-currency';
import ConvertCurrencyCard from '../ConvertAmountCard';
import SideBar from '../PayablePlan/SideBar';
import SubVATCard from './SubVATCard';
import MainVATCard from '../MainVATCard';

// import AccountsPayablePlanContractorSelect from '../../../containers/accounting/AccountsPayablePlanContractorSelect';
import AccountsPayablePlanCreditDebitSelect from '../../../containers/accounting/AccountsPayablePlanCreditDebitSelect';
// import AccountsPayablePlanConvertSelect from '../../../containers/accounting/AccountsPayablePlanConvertSelect';
import _ from 'lodash';

const AccountsPayablePlanContractor = props => {
  const { currencyTypeUSD, currencyTypeVND, canPreview } = props;
  return (
    <div>
      <div
        style={{
          float: 'right',
          top: '-50px',
          right: '10px',
          position: 'absolute',
        }}>
        {props.roles.showCreate && (
          <ul className="inline-block">
            <li className="float-left style-icon-save top-7 position-relative">
              <span className="cursor-pointer" onClick={() => props.save(props.dataApi)}>
                <i className="fa fa-floppy-o" aria-hidden="true"></i>
                SAVE
              </span>
            </li>
          </ul>
        )}
      </div>
      <form
        onSubmit={e => {
          e.preventDefault();
          props.onSubmit();
        }}>
        <div id="header-accounting" className="inline-block w-full position-relative">
          <div className="left w-200" />
          <div className="right top-0 w-full pd-left-230">
            <div className="border-title-left" />
            <div className="title-left left">
              <div className="btn-group btn-supplier-contractor-importExport ">
                <button
                  className="btn btn-secondary btn-lg dropdown-toggle"
                  type="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false">
                  Contractor
                </button>
                <div className="dropdown-menu">
                  <ul>
                    <li>
                      <Link to={PATH.ACCOUNTING_PAYABLE_PLAN_SUPPLIER_URL}>Supplier</Link>
                    </li>
                    <li>
                      <Link to={PATH.ACCOUNTING_PAYABLE_PLAN_IMPORTEXPORT_CONTAINER_URL}>Import/Export</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="style-checkbox mg-top-15">
                <input
                  type="checkbox"
                  id="123"
                  className="display-none"
                  value="on"
                  checked={props.isVATPerMonth}
                  onChange={e => props.changeVATPerMonth(e.target.checked)}
                />
                <label htmlFor="123">
                  <span className="mg-right-10">
                    <span />
                  </span>
                  VAT per month
                </label>
              </div>
            </div>

            <div className="bd-dashed-bottom wrap-input-global pd-15-0 margin-small">
              <div className="col-md-6 col-xs-12 input">
                <FormGroup
                  label="Contractor"
                  type="select"
                  inputComponent={
                    // <AccountsPayablePlanContractorSelect
                    //     items={props.selectableContractors || []}
                    //     onChangeItem={elem => props.changeSelectedContractor(elem)}
                    //     value={props.selectedContractor || ""}
                    // isLoading={false}
                    // isValid={false}
                    // />
                    <SearchSelect
                      options={props.selectableContractors || []}
                      value={props.selectedContractor || ''}
                      onChange={o => props.changeSelectedContractor(o)}
                      isLoading={props.fetchingSelectableContractor}
                      clearable
                    />
                  }
                />
              </div>
              <div className="col-md-6 col-xs-12 input">
                <FormGroup
                  type="input"
                  label="Issued Date"
                  inputComponent={
                    <DateTimeField
                      timeFormat={false}
                      closeOnSelect
                      dateFormat="MMM DD, YYYY"
                      value={moment(props.issuedDate)}
                      onChange={date => props.changeIssuedDate(date.format('YYYY-MM-DD'))}
                    />
                  }
                />
              </div>
              <div className="col-md-6 col-xs-12 input">
                <FormGroup
                  type="input"
                  label="Invoice No."
                  inputComponent={
                    <Input
                      type="text"
                      value={props.invoiceNo}
                      onChange={e => props.changeInvoiceNo(e.target.value)}
                      placeholder=""
                    />
                  }
                />
              </div>
              <div className="col-md-6 col-xs-12 input">
                <FormGroup
                  type="input"
                  label="Due Date"
                  inputComponent={
                    <DateTimeField
                      timeFormat={false}
                      closeOnSelect
                      dateFormat="MMM DD, YYYY"
                      value={moment(props.dueDate)}
                      onChange={date => props.changeDueDate(date.format('YYYY-MM-DD'))}
                    />
                  }
                />
              </div>
              <div className="col-md-6 col-xs-12 input">
                <FormGroup
                  label="Credit/Debit"
                  type="select"
                  inputComponent={
                    <AccountsPayablePlanCreditDebitSelect
                      items={props.selectableCredits}
                      onChangeItem={item => props.changeSelectedCredit(item)}
                      value={_.get(props.selectedCredit, '_id', '')}
                      // onChangeItem={item =>
                      //     props.handleChange('accountsPayable', item)
                      // }
                    />
                  }
                />
              </div>
            </div>

            <div className="bottom text-left size-12 pd-15-0 inline-block w-full">
              <div className="col-md-4 col-sm-6 col-xs-12">
                <p className="size-17">
                  <span className="mg-right-15">Amount INV</span>{' '}
                  <span className="bold color-number">
                    {currencyTypeUSD}
                    {formatCurrency(props.invAmount)} {currencyTypeVND}
                  </span>
                </p>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12">
                <p className="size-17">
                  <span className="mg-right-15">Total Amount INV</span>{' '}
                  <span className="bold color-number">
                    {currencyTypeUSD}
                    {formatCurrency(props.invTotalAmount)}
                    {currencyTypeVND}
                  </span>
                </p>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12">
                <p className="size-15">
                  <span className="mg-right-15">Say</span> <span className="bold">{props.invTotalAmountSay}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="content-main-accounting">
          <SideBar
            searchText={props.filterPOText}
            placeholder="Search"
            items={props.selectableCustomerPOs}
            changeChecked={item => props.changeSelectedCustomerPOs(item._id, item.checked)}
            changeSearchText={value => props.changeSearchTextCustomerPO(value)}
          />

          {/* <!-- Component Header Convert --> */}
          <div id="header-plan-accounts-payable" className="box-shadow">
            <ConvertCurrencyCard
              selectableCurrencies={props.selectableConvertCurrencies}
              selectedCurrency={props.selectedConvertCurrency}
              rate={props.convertRate}
              amount={props.convertAmount}
              totalAmount={props.convertTotalAmount}
              changeSelectedCurrency={o => props.changeSelectedConvertCurrency(o)}
              changeRate={value => props.changeConvertRate(value)}
            />
          </div>

          {/* <!-- End Component --> */}

          {/* <!-- Component --> */}
          {props.isVATPerMonth && (
            <MainVATCard
              vatNo={props.mainVATNo}
              vatDate={props.mainVATDate}
              totalAmount={props.mainVATTotalAmount}
              vatAmount={currencyTypeUSD + props.mainVATAmount + currencyTypeVND}
              grandTotal={currencyTypeUSD + props.mainVATGrandTotal + currencyTypeVND}
              taxRate={props.mainVATTaxRate}
              changeVATNo={value => props.changeMainVATNo(value)}
              changeTotalAmount={value => props.changeMainVATTotalAmount(value)}
              changeVATDate={value => props.changeMainVATDate(value)}
              changeTaxRate={value => props.changeMainVATTaxRate(value)}
            />
          )}
          {/* <!-- End Component --> */}
          {_.map(props.selectedCustomerPOs, (item, q) => (
            <div
              key={q}
              className="panel-group mg-0"
              id="panel-heading-main"
              role="tablist"
              aria-multiselectable="true">
              <div className="panel panel-default">
                {/* <!-- Component --> */}
                <div className="panel-heading component" role="tab" id="">
                  <div className="panel-title">
                    <a
                      role="button"
                      data-toggle="collapse"
                      data-parent="#panel-heading-main"
                      href="#panel-heading-1"
                      aria-expanded="true"
                      aria-controls="collapseOne">
                      <i className="fa fa-caret-down" aria-hidden="true" />
                    </a>
                    <ul>
                      <li>
                        <div className="text">
                          <span className="left">Customer PO</span>
                          <span className="right size-15">
                            <span className="medium">{item.customerPO.name}</span>
                          </span>
                        </div>
                      </li>
                      <li>
                        <div className="text">
                          <span className="left">QTY</span>
                          <span className="right size-15">
                            <span className="medium">{item.customerPO.quantity}</span>
                          </span>
                        </div>
                        <div className="text mg-0">
                          <ul>
                            <li>
                              <span>Price</span>
                            </li>
                            <li className="position-relative top-s-5">
                              <input
                                type="number"
                                value={item.customerPO.price || 0}
                                onChange={event =>
                                  props.changeCustomerPOPrice(item.customerPO.parentId, event.target.value)
                                }
                                className="form-control center w-100"
                              />
                            </li>
                          </ul>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* <!-- End Component --> */}
                <div
                  id="panel-heading-1"
                  className="panel-collapse collapse in"
                  role="tabpanel"
                  aria-labelledby="panel-heading-1">
                  <div className="panel-body pd-0">
                    {!props.isVATPerMonth ? (
                      <SubVATCard
                        vatNo={item.vat.vatNo}
                        totalAmount={item.vat.totalAmount}
                        vatDate={item.vat.vatDate}
                        vatAmount={Math.round((item.vat.totalAmount * item.vat.taxRate) / 100)}
                        taxRate={item.vat.taxRate}
                        grandTotal={
                          Math.round((item.vat.totalAmount * item.vat.taxRate) / 100) + parseInt(item.vat.totalAmount)
                        }
                        note={item.vat.note}
                        isChangeTotalAmount={true}
                        changeVATTotalAmount={value => props.changeSubVATTotalAmount(item.customerPO.id, Number(value))}
                        changeVATNo={value => props.changeSubVATNo(item.customerPO.id, value)}
                        changeVATDate={value => props.changeSubVATDate(item.customerPO.id, value)}
                        changeTaxRate={value => props.changeSubVATTaxRate(item.customerPO.id, Number(value))}
                        changeNote={value => props.changeSubVATNote(item.customerPO.id, value)}
                      />
                    ) : (
                      ''
                    )}
                    <div className="wrap-global-table pd-0 inline-block w-full float-left mg-bottom-0">
                      <table className="global-table box-shadow-none w-full">
                        <tbody>
                          <tr className="bg-white bd-solid-bottom">
                            <th>Style</th>
                            <th className="center">Price</th>
                            <th className="center">Cut Ticket</th>
                            <th className="center">Color</th>
                            <th className="center">Ship Date</th>
                            <th className="center">Ship Mode</th>
                            <th className="center">QTY Rec</th>
                            <th className="center">Price</th>
                            <th className="center">Amount</th>
                          </tr>
                          {_.map(item.data, (row, k) => (
                            <tr key={k}>
                              <td>{row.style.label}</td>
                              <td className="center">
                                {row.style.label ? (
                                  <input
                                    type="number"
                                    min="0"
                                    value={row.style.price}
                                    onChange={evt =>
                                      props.changeStylePrice(item.customerPO.id, row.style.id, evt.target.value)
                                    }
                                    className="form-control center"></input>
                                ) : (
                                  ''
                                )}
                              </td>
                              <td className="center">{row.cutTicket.label}</td>
                              <td className="center">{row.color.name}</td>
                              <td className="center">{moment(row.color.shipDate).format('MMM DD, YYYY')}</td>
                              <td className="center">{row.color.shipMode}</td>
                              <td className="center">{row.color.quantity}</td>
                              <td className="center">
                                <input
                                  type="number"
                                  min="0"
                                  value={row.color.price}
                                  onChange={evt =>
                                    props.changeColorPrice(
                                      item.customerPO.id,
                                      row.style.id,
                                      row.cutTicket.id,
                                      row.color.id,
                                      evt.target.value
                                    )
                                  }
                                  className="form-control center"></input>
                              </td>
                              <td className="center number">
                                {currencyTypeUSD}
                                {row.color.amount || 0} {currencyTypeVND}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </form>
      <div className="center clearBoth w-full mg-top-15 inline-block">
        <button
          className={canPreview ? 'bt-orange text-uppercase' : 'cursor-text display-none text-uppercase'}
          style={{ marginRight: 10 }}
          onClick={() => props.onPreviewScreen()}
          disabled={!canPreview}>
          {'PREVIEW'}
        </button>
      </div>
    </div>
  );
};

AccountsPayablePlanContractor.propTypes = {
  selectableContractors: PropTypes.arrayOf(PropTypes.any).isRequired,
  isVATPerMonth: PropTypes.bool.isRequired,
  selectedContractor: PropTypes.string.isRequired,
  // fetchingSelectableContractors: PropTypes.bool.isRequired,

  issuedDate: PropTypes.string.isRequired,
  invoiceNo: PropTypes.string.isRequired,
  dueDate: PropTypes.string.isRequired,

  selectableCredits: PropTypes.arrayOf(PropTypes.arrayOf).isRequired,
  // fetchingSelectableCredits: PropTypes.bool.isRequired,
  selectedCredit: PropTypes.object.isRequired,

  // invAmount: PropTypes.string.isRequired,
  // invTotalAmount: PropTypes.string.isRequired,
  // invTotalAmountSay: PropTypes.string.isRequired,

  searchTextCustomerPO: PropTypes.string.isRequired,
  selectableCustomerPOs: PropTypes.arrayOf(PropTypes.any).isRequired,
  selectedCustomerPOs: PropTypes.arrayOf(PropTypes.any).isRequired,

  selectableConvertCurrencies: PropTypes.arrayOf(PropTypes.any).isRequired,
  selectedConvertCurrency: PropTypes.string.isRequired,
  convertRate: PropTypes.string.isRequired,
  // convertAmount: PropTypes.string.isRequired,
  // convertTotalAmount: PropTypes.string.isRequired,

  mainVATNo: PropTypes.string.isRequired,
  mainVATDate: PropTypes.string.isRequired,
  mainVATTotalAmount: PropTypes.string.isRequired,
  mainVATTaxRate: PropTypes.string.isRequired,
  mainVATAmount: PropTypes.string.isRequired,
  mainVATGrandTotal: PropTypes.string.isRequired,
  dataApi: PropTypes.object.isRequired,

  // onOverseaClick: PropTypes.func,
  // changeSelectedSupplier: PropTypes.func,
  changeVATPerMonth: PropTypes.func,
  changeIssuedDate: PropTypes.func,
  changeInvoiceNo: PropTypes.func,
  changeDueDate: PropTypes.func,
  changeSelectedCredit: PropTypes.func,
  changeSearchTextCustomerPO: PropTypes.func,
  changeSelectedCustomerPOs: PropTypes.func,

  changeSelectedConvertCurrency: PropTypes.func,
  changeConvertRate: PropTypes.func,
  changeCustomerPOPrice: PropTypes.func,
  changeStylePrice: PropTypes.func,
  changeColorPrice: PropTypes.func,
  //--------- main VAT ---------//
  changeMainVATNo: PropTypes.func,
  changeMainVATTotalAmount: PropTypes.func,
  changeMainVATDate: PropTypes.func,
  changeMainVATTaxRate: PropTypes.func,

  // toggleActivePurchaseOrder: PropTypes.func,
  //--------- sub VAT ---------//
  changeSubVATNo: PropTypes.func,
  changeSubVATDate: PropTypes.func,
  changeSubVATTaxRate: PropTypes.func,
  changeSubVATNote: PropTypes.func,
  changeSubVATTotalAmount: PropTypes.func,
  // onSubmit: PropTypes.func,
  // save: PropTypes.func
};

AccountsPayablePlanContractor.defaultProps = {};

const mapStateToProps = state => ({});

export default AccountsPayablePlanContractor;
