import C from '../../constants/ship-out/createShipment';
import standardApi from '../../api/standardApi';
import shipOutApi from '../../api/shipOutApi'

export const getStandard = () => dispatch => {
    standardApi.getListItemDAL().then(res => {
        if(res.success) {
            dispatch({type: C.CHANGE_SHIPMENT_INFOMATION, payload: res.data.items[0]})
        }
    })
}

export const changeShipmentInfo = (key, value) => dispatch => {
    dispatch({type: C.CHANGE_SHIPMENT_INFO_VALUE, payload: {key, value}})
}

export const changeTransportFee = (key, value) => dispatch => {
    dispatch({type: C.CHANGE_TRANSPORT_FEE_VALUE, payload: {key, value}})
}

export const changePklShipList = (data) => dispatch => {
    const query = {data: data}
    shipOutApi.getPklShipDetail(query).then(res => {
        if(res.success) {
            dispatch({type: C.CHANGE_PKL_SHIP_LIST, payload: res.data})
        }
    })
}

export const changePklShipDetail = (data) => dispatch => {
    dispatch({type: C.CHANGE_PKL_SHIP_DETAIL, payload: data})
}

export const changeOrderActive = (value, orderId) => dispatch => {
    dispatch({type: C.CHANGE_ACTIVE_ORDER, payload: {value, orderId}})
}

export const changeStyleActive = (orderId, styleId) => dispatch => {
    dispatch({type: C.CHANGE_ACTIVE_STYLE, payload: {orderId, styleId}})
}

export const changeUnitPrice = (orderId, styleId, cutTicketId, colorId, value) => dispatch => {
    dispatch({type: C.CHANGE_UNIT_PRICE, payload: {orderId, styleId, cutTicketId, colorId, value}})
}

export const save = (data, callback) => dispatch => {
    // shipOutApi.findBillNo(data.billNo).then(res => {
    //     if(res.data.length > 0) {
    //         callback(false);
    //     }else{
            shipOutApi.createShipment(data).then(res => {
                if(res.success) {
                    dispatch({type: C.CREATE_SUCCESS, payload: true});
                    callback(true)
                }
            // })
        // }
    })
}

export const resetData = () => dispatch => {
    dispatch({type: C.RESET_DATA})
}

export const getListForwarder = () => dispatch => {
    shipOutApi.getListForwarder().then(res => {
        if(res.success) {
            dispatch({type: C.CHANGE_LIST_FORWARDER, payload: res.data.items})
        }
    })
}

export const addForwarderIntoList = (value) => dispatch => {
    dispatch({type: C.ADD_FORWARDER_INTO_LIST, payload: value})
}

export const activeScreenCreateShipment = () => dispatch => {
    dispatch({type: C.ACTIVE_SCREEN_CREATE_SHIPMENT, payload: true})
}