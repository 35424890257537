import * as types from '../constants/actionTypes';
import {showLoading, hideLoading} from './commonUseActions';
import warehouseServices from '../services/warehouseServices';
import materialServices from "../services/materialServices";
import _ from 'lodash';

function getListItem (options = {}) {
  return (dispatch) => {
    // showLoading(types, dispatch);

    return warehouseServices.getListItem(options).then((res) => {
      hideLoading(types, dispatch, (res && res.error ? res.error : undefined));

      if(res && res.success){
        dispatch({
          type: types.WAREHOUSE.GET_LIST_SUCCESS,
          data: res.data || {},
        });
      }
      return res;
    }, (error) => {
      hideLoading(types, dispatch, error);
    });
  };
}

function getListReserve (options = {}) {
  return (dispatch) => {
    showLoading(types, dispatch);

    return warehouseServices.getListReserve(options).then((res) => {
      hideLoading(types, dispatch, (res && res.error ? res.error : undefined));

      if(res && res.success){
        dispatch({
          type: types.WAREHOUSE.GET_LIST_RESERVE,
          data: res.data || {},
        });
      }
      return res;
    }, (error) => {
      hideLoading(types, dispatch, error);
    });
  };
}

function searchByMaterial(options, pageNumber, pageSize) {
  return(dispatch) =>{
    showLoading(types, dispatch);
    return materialServices.getListMaterial(options).then((res) => {
      hideLoading(types, dispatch);
      if(res && res.success) {
        let options = {
          pageSize: pageSize,
          pageNumber: pageNumber,
          populate: 'material',
          query: JSON.stringify({
            material: {
              $in: res.data.items.reduce((arr, item) => arr.concat(item._id || []), [])
            }
          })
        };
        return getListItem({...options})(dispatch).then(res => {
          if(res && res.success && res.data && res.data.items && !res.data.items.length && pageNumber > 1) {
            _.set(options, "pageNumber", 1);
            getListItem(options)(dispatch);
            pageNumber = 1;
          }
          return (_.set(res, "pageNumber", pageNumber));
        });
      }
    }, (error) => {
      hideLoading(types, dispatch, error);
    })
  }
}

function searchByContent(options, pageNumber, pageSize) {
  return(dispatch) => {
    showLoading(types, dispatch);
    return materialServices.getListMaterial(options).then((res) => {
      hideLoading(types, dispatch);
      if(res && res.success) {
        let options = {
          pageSize: pageSize,
          pageNumber: pageNumber,
          populate: 'material',
          query: JSON.stringify({
            material: {
              $in: res.data.items.reduce((arr, item) => arr.concat(item._id || []), [])
            }
          })
        };
        return getListItem({...options})(dispatch).then(res => {
          if(res && res.success && res.data && res.data.items && !res.data.items.length && pageNumber > 1) {
            _.set(options, "pageNumber", 1);
            getListItem(options)(dispatch);
            pageNumber = 1;
          }
          return (_.set(res, "pageNumber", pageNumber));
        });
      }
    }, (error) => {
      hideLoading(types, dispatch, error);
    })
  }
}
  
function returnStyleResources(_id, styleId) {
  return (dispatch) => {
    showLoading(types, dispatch);
    return warehouseServices.returnStyleResources(_id, styleId).then((resp) => {
      hideLoading(types, dispatch);
      return resp;
    }, (error) => {
      console.log('Error', error);
      hideLoading(types, dispatch, error);
    });
  }
}

function exportReserve(search = {}) {
  return (dispatch) => {
    return warehouseServices.exportReserve(search);
  }
}


export default {
  getListItem,
  searchByMaterial,
  searchByContent,
  getListReserve,
  returnStyleResources,
  exportReserve
};
