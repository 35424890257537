import {createSelector} from 'reselect';
import _ from 'lodash';
import moment from "moment";
import formatCurrency from "format-currency";

/**
 * Get a list of invoices
 * The results will contains these information
 * [{
 *    _id, invRequest, invRef, invNo, customerPO, dueDate, payee, 
 *    type, amount, paid, remaining, checked, checkable, deletable,
 * }]
 */
export const getReceivableInvoices = ({showReceivableInvoiceListHistory}) => showReceivableInvoiceListHistory.receivableInvoices;
// export const getReceivableInvoices = createSelector([
//     ({showReceivableInvoiceListHistory}) => showReceivableInvoiceListHistory.receivableInvoices
// ], (receivableInvoices) => {
//   return receivableInvoices;
//     let arrData = [];
//     payableInvoices.map(it => {
//         let obj = {};
//         obj._id = it._id;
//         obj.invRequest = it.invRequest;
//         obj.invRef = it.invRef;
//         obj.invNo = it.invNo;
//         obj.customerPOs = it.invoiceDetail.customerPOs ? it.invoiceDetail.customerPOs : '-';
//         obj.customerPO = it.invoiceDetail.customerPOs ? it.invoiceDetail.customerPOs[0].customerPO : '-';
//         obj.dueDate = converData('dueDate', it);
//         obj.payee = it.invoiceDetail.supplier ? it.invoiceDetail.supplier.name : '-';
//         obj.type = it.typeInvoice;
//         obj.amount = converData('amount', it);
//         obj.paid = converData('paid', it);
//         obj.remaining = converData('remaining', it);
//         let findCheck = _.findIndex(checkedList, function (o) {
//             return o._id === it._id
//         });
//         obj.checked = findCheck >= 0 ? true : false;
//         obj.checkable = obj.checked ? false : true;
//         obj.deletable = obj.paid == '0.00' ? true : false;
//         arrData.push(obj);
//     });
//     return arrData;
// });
/**
 * Get selectable filters
 */
export const getSelectableFilters = () => {
    let arrData = [
        {field: 'invRequest', name: 'PageAccounting.INVRequest', type: 'text'},
        {field: 'invRef', name: 'PageAccounting.INVRef', type: 'text'},
        {field: 'invNo', name: 'PageAccounting.INVNo', type: 'text'},
        {field: 'customerPO', name: 'PageAccounting.CustomerPO', type: 'text'},
        {field: 'dueDate', name: 'PageAccounting.DueDate', type: 'dateTo'},
        {field: 'customer', name: 'PageAccounting.Customer', type: 'text'}
    ]
    return arrData;
}

// export const getCheckedList = ({showReceivableInvoiceListHistory}) => showReceivableInvoiceListHistory.checkedList;

// /**
//  * Get selected filter
//  */
export const getSelectedFilter = createSelector(
    [getSelectableFilters, ({showReceivableInvoiceListHistory}) => showReceivableInvoiceListHistory.selectedFilter],
    (filters, selectedFilter) => {
        if (!selectedFilter) return filters[0].field;
        return selectedFilter;
    }
)


// /**
//  * Get search text field value from reducer
//  */
export const getSearchValue = ({showReceivableInvoiceListHistory}) => showReceivableInvoiceListHistory.searchValue;

// /**
//  * Get status of whether invoices data is fetching from api
//  */
// export const isFetchingInvoices = ({showReceivableInvoiceListHistory}) => showReceivableInvoiceListHistory.fetchingPayableInvoices;





function converData(key, obj) {
    switch (key) {
        case 'amount':
            return formatCurrency(obj['amount']);
        case 'paid':
            return formatCurrency(obj['paid']);
        case 'remaining':
            return formatCurrency(obj['amount'] - (obj['paid'] || 0));  // Convert to number
        case 'dueDate':
            return obj['dueDate'] ? moment(obj['dueDate']).format('MMM DD, YYYY') : '-';
        default:
            return obj[key];
    }
}

/**
 * Get total amount
 * Format: $ ###,###.##
 */
export const getTotalAmount = ({showReceivableInvoiceListHistory}) => `$${formatCurrency(showReceivableInvoiceListHistory.totalAmount)}`;


// /**
//  * change currency
//  */
// export const getCurrency = ({showReceivableInvoiceListHistory}) => showReceivableInvoiceListHistory.changeCurrency;


// /**
//  * Get total paid
//  * Format: $ ###,###.##
//  */
export const getTotalPaid = ({showReceivableInvoiceListHistory}) => `$${formatCurrency(showReceivableInvoiceListHistory.totalPaid)}`;

// /**
//  * Get total remaining
//  * Format: $ ###,###.##
//  */
export const getTotalRemaining = ({showReceivableInvoiceListHistory}) => `$${formatCurrency(showReceivableInvoiceListHistory.totalRemaining)}`;


/**
 * Get paid detail
 * Format: $ ###,###.##
 */
// export const getPaidDetail = ({showReceivableInvoiceListHistory}) => {
//     let arr = [];
//     showReceivableInvoiceListHistory.paidDetail.map(it =>{
//         let obj = {};
//         obj.date = it.date;
//         obj.received = `$${it.paid}`;
//         obj.attachment = it.attachs;
//         arr.push(obj);
//     });
//     return arr;
// };

/**
 * Get total pages
 */
export const getTotalPages = ({showReceivableInvoiceListHistory}) => {
    return showReceivableInvoiceListHistory.totalPages;
}

// /**
//  * Get current page
//  */
export const getCurrentPage = ({showReceivableInvoiceListHistory}) => {
    return showReceivableInvoiceListHistory.currentPage;
}

// /**
//  * Return a function that will be use to generate request body which is use to
//  * send create invoice request from checked invoices
//  */
// export const fnGenerateRequestBody = createSelector([
//     getPayableInvoices,
// ], (payableInvoices) => {
//     let arrData = [];
//     payableInvoices.map(it => {
//         if (it.checked) arrData.push(it._id);
//     });
//     return arrData;
// });

export const getInvoiceRequest = ({showReceivableInvoiceListHistory}) => showReceivableInvoiceListHistory.invoiceRequest;

export const getCheckedInvoice = ({showReceivableInvoiceListHistory}) => {
    if(showReceivableInvoiceListHistory.checkedInvoices){
        return showReceivableInvoiceListHistory.checkedInvoices
    }
};

// export const getCreateInvoiceRequestStatus = ({showReceivableInvoiceListHistory}) => showReceivableInvoiceListHistory.createInvoiceRequestSuccess;

export const getDeleteSuccess = ({showReceivableInvoiceListHistory}) => showReceivableInvoiceListHistory.deleteSuccess;

export const getInvoiceWillBeDeleted = ({showReceivableInvoiceListHistory}) => showReceivableInvoiceListHistory.invoiceWillBeDeleted;

export const getParamsRequest = createSelector([
    getSelectedFilter,
    getSearchValue,
    // getCurrency,
    // getCurrentPage
], (type,
    value,
    // currency,
    // page
) => {
    let params = {};
    params.type = type;
    // params.value = JSON.stringify(value);
    if(params.type == 'dueDate') {
        params.from = moment(_.get(value, 'from', '')).format("YYYY-MM-DD");
        params.to = moment(_.get(value, 'to', '')).format("YYYY-MM-DD");
    } else {
        params.value = _.get(value, 'value', '')
    }
    // params.convertType = currency;
    // params.pageNumber = page ||  1;
    params.pageSize = 10;
    params.history = true;
    return params;
});

export const getLoading = ({showReceivableInvoiceListHistory}) => showReceivableInvoiceListHistory.loading;