import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
const DateTimeField = require('react-datetime');
import _ from 'lodash';
import moment from 'moment';
import I18n from 'i18n-js';
import {SEARCH_BAR_EVENT} from "../../../constants/constants";

import icAddIcon from '../../../stylesheets/images/ic-add.svg';
import icSearchIcon from '../../../stylesheets/images/ic-search.svg';

class SearchBar extends Component {
  constructor (props, context) {
    super(props, context);

    this.state = {
      filter: '',
      search: '',
      fields: []
    };
  }

  componentWillReceiveProps (newProps) {
    let {fields} = this.state;
    !_.isEqual(fields, newProps.fields || []) && this.setState({
      fields: _.cloneDeep(newProps.fields),
      filter: '',
      search: ''
    });
  }

  onChange (property, value) {
    property === SEARCH_BAR_EVENT.FILTER ? this.setState({filter: value, search: ''}) : this.setState({search: value});
    this.props.onChangeSearch && this.props.onChangeSearch(property, value);
  }

  getSearchField () {
    let {filter, search, fields} = this.state;
    let field = fields.find(i => i.field === filter) || {};

    switch (field.type) {
      case 'date':
        return (
          <div className="date-pick-search">
            <DateTimeField timeFormat={false} closeOnSelect dateFormat="MMM DD, YYYY" onChange={date => this.onChange(SEARCH_BAR_EVENT.SEARCH, date.format())} />
          </div>
        );
      case 'enum':
        return (
          <div className="select-pick-search">
            <select className="form-control font-s-13" value={search || ''} onChange={evt => this.onChange(SEARCH_BAR_EVENT.SEARCH, evt.target.value)}>
              {(field.values || []).map((i, idx) => (<option key={idx} value={i.key}>{i.value}</option>))}
            </select>
          </div>
        );
      default:
        return (<input type="text" onKeyPress={evt => evt.key === 'Enter' && this.props.onSearch()} placeholder="Search" value={search || ''} onChange={evt => this.onChange(SEARCH_BAR_EVENT.SEARCH, evt.target.value)} className="form-control" />);
    }
  }

  render () {
    const {name, addItem, showCreate} = this.props;
    let {filter, fields} = this.state;

    return (
      <div className={`wrap-filter-search ${this.props.className || ''}`}>
        {(_.isFunction(addItem) && (showCreate != false)) && (
        <div className="wrap-stock-in">
          <span className="cursor-pointer" onClick={() => _.isFunction(addItem) && addItem()}><img src={icAddIcon} alt="" /> {name}</span>
        </div>
        )}

        <div className="wrap-search">
          <p>{I18n.t('PageWareHouse.Search')}</p>
          {this.getSearchField()}
          <span className="icon-search"><img src={icSearchIcon} alt="" /></span>
          <button type="submit" onClick={() => this.props.onSearch()} className="transform bt-orange">Search</button>
        </div>
        <div className="wrap-filter">
          <p>{I18n.t('PageWareHouse.Filter')}</p>
          <select className="form-control" value={filter || ''} onChange={evt => this.onChange(SEARCH_BAR_EVENT.FILTER, evt.target.value)} >
            {
              fields.map((i, idx) => (<option key={idx} value={i.field}>{I18n.t(i.name)}</option>))
            }
          </select>
        </div>
      </div>
    );
  }
}

function mapStateToProps (state, ownProps) {
  return {
    authState: state.authState
  };
}

function mapDispatchToProps (dispatch) {
  return {};
}

SearchBar.propTypes = {
  authState: PropTypes.object.isRequired,
  onChangeSearch: PropTypes.func,
  onSearch: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar);
