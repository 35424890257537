import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import * as R from 'ramda';
import numeral from 'numeral';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BaseComponent from '../../base/BaseComponent';
import purchaseActions from '../../../actions/purchaseActions';
import appActions from '../../../actions/appActions';
import ListAndActions from '../../common/datatables/ListAndActions';
import SearchBar from '../../common/layout/SearchBar';
import I18n from 'i18n-js';
import {
    PURCHASE_LIST_SEARCH_KEY, PURCHASE_LIST_SEARCH_LIST, PURCHASE_STATUS, SEARCH_BAR_EVENT, SEARCH_KEY,
    SEARCH_LIST, PURCHASE_SCREEN, PRODUCTION_TAB, PURCHASE_EVENT
} from "../../../constants/constants";
import * as PATH from '../../../constants/routeConstants';
import TableList from "../../common/datatables/TableList";
import {Pagination} from "react-bootstrap";
import _ from "lodash";
import formatCurrency from 'format-currency';
import ModalConfirm from "../../common/modals/ModalConfirm";
import CustomPagination from '../../common/datatables/CustomPagination';

class ScreenPurchaseHistory extends BaseComponent {
  constructor (props, context) {
    super(props, context);
    this.state = {
      pageSize: 10,
      pageNumber: 1,
      search: {key: SEARCH_KEY.CUSTOMER_PO, value: ''},
      columns: [
        // {name: I18n.t('PagePurchase.No'), type: 'text', dataType: 'text', value: 'id'},
        {name: I18n.t('PagePurchase.CompletedDate'), type: 'date', formatType: 'MMM DD, YYYY', options: {clsTh: 'center', clsTd: 'center'}, value: 'completedAt'},
        {name: I18n.t('PagePurchase.OrderDate'), type: 'date', formatType: 'MMM DD, YYYY', options: {clsTh: 'center', clsTd: 'center'}, value: 'createdAt'},
        {name: I18n.t('PagePurchase.CustomerPO'), type: 'text', dataType: 'text', value: 'style.order.customerPO'},
        {name: I18n.t('PagePurchase.Style'), type: 'text', dataType: 'text', value: 'style.style'},
        {name: I18n.t('PagePurchase.PurchaseOrderNo'), type: 'text', dataType: 'text', value: 'purchaseNo'},
        {name: I18n.t('PagePurchase.Supplier'), type: 'text', dataType: 'text', value: 'supplier.name'},
        {name: I18n.t('PagePurchase.ExpectedReceivedDate'), type: 'date', formatType: 'MMM DD, YYYY', options: {clsTh: 'center', clsTd: 'center'}, value: 'exReceivedDate'},
        {name: I18n.t('PagePurchase.Amount'), type: 'text', dataType: 'text', value: 'gTotal'},
        {name: I18n.t('PagePurchase.Type'), type: 'text', dataType: 'text', value: 'type'}
      ],
        headers: [
            { name: I18n.t('PagePurchase.CompletedDate'), type: 'date', dataType: 'MMM DD, YYYY', value: 'completedAt' },
            { name: I18n.t('PagePurchase.OrderDate'), type: 'date', dataType: 'MMM DD, YYYY', value: 'createdAt' },
            { name: I18n.t('PagePurchase.CustomerPO'), type: 'text', dataType: 'text', value: '{{data}}.style.order.customerPO' },
            { name: I18n.t('PagePurchase.Style'), type: 'text', dataType: 'text', value: '{{data}}.style.style' },
            { name: I18n.t('PagePurchase.PurchaseOrderNo'), type: 'text', dataType: 'text', value: 'purchaseNo' },
            { name: I18n.t('PagePurchase.Supplier'), type: 'text', dataType: 'text', value: '{{data}}.supplier.name' },
            { name: I18n.t('PagePurchase.ExpectedReceivedDate'), type: 'date', dataType: 'MMM DD, YYYY', value: 'exReceivedDate' },
            { name: I18n.t('PagePurchase.Amount'), type: 'text', dataType: 'text', value: 'gTotal' },
            { name: I18n.t('PagePurchase.Type'), type: 'text', dataType: 'text', value: 'type' },
        ]
        ,

      actions: [
          (item) => {
              return { type: 'restore', click: (item) => this.renderConfirmRestore(item) };
          },
      ],
    };
      this.restoreData = this.restoreData.bind(this);
  }

  componentWillMount() {
    let options = {
      pageSize: this.state.pageSize,
      pageNumber: this.state.pageNumber,
      populate: 'materials.material,styleId|orderId,supplierId',
      sort: {completedAt: -1}
    };
    this.props.purchaseActions.getListHistory(options);
  }

    renderConfirmRestore (item) {
        let { openModal, closeModal } = this.props.modal;
        let modal = (<ModalConfirm
            title={I18n.t('PagePurchase.RestoredOrder')}
            confirmMessage={I18n.t('PagePurchase.ConfirmSaveStatusRestored')}
            messageOk={I18n.t('Btn.YES')}
            messageCancel={I18n.t('Btn.CANCEL')}
            handleClose={closeModal}
            handleOk={this.restoreData.bind(null, item)} />);
        openModal(modal);
    }

    restoreData = item => {
        item._id && this.props.purchaseActions.restorePurchase(item._id);

        let myVar = setTimeout(() => {
          this.onSearch();
          }, 1000);
        setTimeout(() => {
            clearInterval(myVar); //clear above interval after 5 seconds
        },1100);
  }

  onSearch() {
    const {search, pageNumber, pageSize} = this.state;
    this.search(search, pageNumber, pageSize);
  }

  onChangeSearch(property, value, pos) {
    switch(property) {
      case SEARCH_BAR_EVENT.FILTER: {
        this.setState({search: {key: value, value: ""}});
        break;
      }
      case SEARCH_BAR_EVENT.SEARCH: {
        this.setState({search: {...this.state.search, value: value}});
        break;
      }
    }
  }

  onKeyUpSearch(evt) {
    if (evt && evt.keyCode && evt.keyCode === 13) {
      const {search, pageNumber, pageSize} = this.state;
      this.search(search, pageNumber, pageSize);
    }
  }

  setPage(page) {
    this.setState({pageNumber: page});
    this.search(this.state.search, page, this.state.pageSize);
  }

  search(search, pageNumber, pageSize) {
    const populate = 'materials.material,styleId|orderId,supplierId';
    if(search.key && search.value) {
      let options = {};
      const type = 'history';
      switch (search.key) {
        case SEARCH_KEY.CUSTOMER_PO:{
          options = {
            projection: "styleOrders",
            query: JSON.stringify({customerPO: {$regex: '.*' + search.value + '.*', $options: 'i'}})
          };

          this.props.purchaseActions.searchByCustomerPO(options, pageNumber, pageSize, type).then(res => {
            if(res && res.pageNumber && res.pageNumber == 1) {
              this.setState({pageNumber: 1});
            }
          });
          break;
        }
        case SEARCH_KEY.SUPPLIER:{
          options = {
            projection: "_id",
            query: {name: {$regex: '.*' + search.value + '.*', $options: 'i'}}
          };
          this.props.purchaseActions.searchBySupplier(options, pageNumber, pageSize, type).then(res => {
            if(res && res.pageNumber && res.pageNumber == 1) {
              this.setState({pageNumber: 1});
            }
          });
          break;
        }
        case SEARCH_KEY.PURCHASE_ORDER_NO:{
          options = {
            pageNumber,
            pageSize,
            sort: {completedAt: -1},
            populate: populate,
            query: JSON.stringify({purchaseNo: {$regex: '.*' + search.value + '.*', $options: 'i'}})
          };
          this.props.purchaseActions.getListHistory(options).then(res => {
            if(res && res.success && res.data && res.data.items && !res.data.items.length && pageNumber > 1) {
              options = {
                pageNumber:1,
                pageSize,
                sort: {completedAt: -1},
                populate: populate,
                query: JSON.stringify({purchaseNo: {$regex: '.*' + search.value + '.*', $options: 'i'}})
              };
              this.setState({pageNumber: 1});
              this.props.purchaseActions.getListHistory(options);
            }
          });
          break;
        }
        case SEARCH_KEY.STYLE:{
          options = {
            projection: "_id",
            query: JSON.stringify({style: {$regex: '.*' + search.value + '.*', $options: 'i'}})
          };
          this.props.purchaseActions.searchByStyle(options, pageNumber, pageSize, type).then(res => {
            if(res && res.pageNumber && res.pageNumber == 1) {
              this.setState({pageNumber: 1});
            }
          });
          break;
        }
        case SEARCH_KEY.TYPE:{
          options = {
            pageNumber,
            pageSize,
            sort: {completedAt: -1},
            populate: populate,
            query: JSON.stringify({type: {$regex: '.*' + search.value + '.*', $options: 'i'}})
          };
          this.props.purchaseActions.getListHistory(options).then(res => {
            if(res && res.success && res.data && res.data.items && !res.data.items.length && pageNumber > 1) {
              options = {
                pageNumber:1,
                pageSize,
                sort: {updatedAt: -1},
                populate: populate,
                query: JSON.stringify({type: {$regex: '.*' + search.value + '.*', $options: 'i'}})
              };
              this.setState({pageNumber: 1});
              this.props.purchaseActions.getListHistory(options);
            }
          });
          break;
        }
      }
    }
    if(!search.value) {
      let options = {
        pageNumber,
        pageSize,
        populate: populate,
      };

      this.props.purchaseActions.getListHistory(options, pageNumber, pageSize).then(res => {
        if(res && res.success && res.data && res.data.items && !res.data.items.length && pageNumber > 1) {
          options = {
            pageNumber:1,
            pageSize,
            sort: {updatedAt: -1},
            populate: populate,
          };
          this.setState({pageNumber: 1});
          this.props.purchaseActions.getListHistory(options);
        }
      });
    }
  }

  selectPage = (page) => {
      const populate = 'materials.material,styleId|orderId,supplierId';
      this.setState({pageNumber: page});
      let options = { pageSize: this.state.pageSize, pageNumber: page, populate };
      this.props.purchaseActions.getListHistory(options);
  }

  genCurrencyForAmount = (amount, currency) => {
    if(currency == 'VND') {
      return formatCurrency(amount) + ' VND';
    }
    if(currency == 'USD') {
      return '$' + formatCurrency(amount);
    }
    return formatCurrency(amount)
  }

  render () {

    const {headers, pageNumber, search, actions, columns, pageSize} = this.state;
      let totalPage = _.ceil(this.props.purchaseState.count / pageSize);
    let data = this.props.purchaseState && this.props.purchaseState.items ? this.props.purchaseState.items : [];
    data = data.map(item => {
        item.gTotal = this.genCurrencyForAmount(item.gTotal, (item.supplier || {}).currency) //numeral(R.pathOr(0, ['gTotal'], item)).format('0,0.00');
      if(item.materials && item.materials.length > 0) {
        let temp = item.materials.sort((a,b) => new Date(a.exReceivedDate) - new Date(b.exReceivedDate));
        item = {...item, exReceivedDate: temp[0].exReceivedDate}
      }
      return item
    });
    data = data.sort((a,b) => -(new Date(a.createdAt) - new Date(b.createdAt)));
    return (
      <div id="content">

              <div>
                  <SearchBar fields={SEARCH_LIST.PURCHASE_LIST_HISTORY} onChangeSearch={(property, value, pos) => this.onChangeSearch(property, value, pos)} onSearch={() => this.onSearch()}/>
              </div>
              <div className="wrap-global-table">
                  <TableList items={data || []} columns={columns || []} actions={ actions} options={{clsTbl: 'global-table'}}/>
                  <CustomPagination items={totalPage} activePage={pageNumber} onSelect={this.selectPage} />
              </div>
      </div>
    );
  }
}

function mapStateToProps (state, ownProps) {
  return {
    ...ownProps,
    authState: state.authState,
    appState: state.appState,
    purchaseState: state.purchaseState
  };
}

function mapDispatchToProps (dispatch) {
  return {
    appPropsActions: bindActionCreators(appActions, dispatch),
    purchaseActions: bindActionCreators(purchaseActions, dispatch)
  };
}

ScreenPurchaseHistory.propTypes = {
  authState: PropTypes.object.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ScreenPurchaseHistory));
