import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import contractorActions from '../../../actions/contractorActions';
import I18n from 'i18n-js';
import { toastr } from 'react-redux-toastr';

class ContractorsEdit extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      item: {},
      errors: {}
    };

  }

  componentWillMount() {
    const data = _.get(this.props, 'options.data', {});
    this.setState({ item: _.cloneDeep(data) });
  }

  onChange(property, value) {
    let { item } = this.state;
    this.setState({ item: _.set(item, property, value) });
    this.validateChange(property, value);
  }

  close() {
    this.props.closeModal();
  }

  save() {
    if (!this.validate()) return;
    let { item } = this.state;
    item._id && this.props.contractorsPropsActions.editItem(item._id, item).then(result => {
      if (result.statusCode == 500) {
        toastr.error(I18n.t('Notify.Error'), I18n.t('ErrorMessages.ContractorExist'))
      } else if (!result.success) {
        toastr.error(I18n.t('Notify.Error'), result.message)
      }
    });
  }

  validateChange(property, value) {
    let { errors } = this.state;
    errors = {};
    switch (property) {
      case 'name': {
        if (_.isEmpty(value)) {
          errors['name'] = I18n.t('ErrorMessages.ContractorNameIsRequired');
        }
        this.setState({ errors });
        break;
      }
      default:
    }
  }

  validate() {
    let { item, errors } = this.state;
    errors = {};
    if (_.isEmpty(item.name)) {
      errors['name'] = I18n.t('ErrorMessages.ContractorNameIsRequired');
      this.setState({ errors });
      return false;
    }
    return true;
  }

  render() {
    let { item, errors } = this.state;
    return (
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <form>
            <div className="modal-header">
              <a className="close" onClick={() => this.close()}><span aria-hidden="true">&times;</span></a>
              <h4 className="modal-title" id="myModalLabel">{I18n.t('PageCrm.EDITCONTRACTOR')}</h4>
            </div>

            <div className="modal-body">
              <div className="form-group">
                <div className="col-md-4 col-xs-12 text">
                  <p>{I18n.t('PageCrm.Name')}</p> <span className="color-red"> *</span>
                </div>
                <div className="col-md-8 col-xs-12 value">
                  <input type="text" value={item.name || ''} onChange={evt => this.onChange('name', evt.target.value)} className="form-control" />
                  {errors['name'] && <div className="alert-warning">{errors['name']}</div>}
                </div>
              </div>
              <div className="form-group">
                <div className="col-md-4 col-xs-12 text">
                  <p>{I18n.t('PageCrm.Address')}</p>
                </div>
                <div className="col-md-8 col-xs-12 value">
                  <input type="text" value={item.address || ''} onChange={evt => this.onChange('address', evt.target.value)} className="form-control" />
                </div>
              </div>
              <div className="form-group">
                <div className="col-md-4 col-xs-12 text">
                  <p>{I18n.t('PageCrm.Currency')}</p> <span className="color-red"> *</span>
                </div>
                <div className="col-md-8 col-xs-12 value">
                  <select value={item.currency ? item.currency : '-'} onChange={evt => this.onChange('currency', evt.target.value)} className="form-control">
                    <option value="-"></option>
                    <option value="VND">{I18n.t('PageCrm.VND')}</option>
                    <option value="USD">{I18n.t('PageCrm.USD')}</option>
                  </select>
                </div>
              </div>
              <div className="form-group">
                <div className="col-md-4 col-xs-12 text">
                  <p>{I18n.t('PageCrm.Director')}</p>
                </div>
                <div className="col-md-8 col-xs-12 value">
                  <input type="text" value={item.director || ''} onChange={evt => this.onChange('director', evt.target.value)} className="form-control" />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="bt-black" onClick={() => this.close()}>{I18n.t('Btn.CANCEL')}</button>
              <button type="button" className="bt-orange mg-l-5" onClick={() => this.save()}>{I18n.t('Btn.SAVE')}</button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    appState: state.appState,
    authState: state.authState,
    contractorState: state.contractorState
  };
}

function mapDispatchToProps(dispatch) {
  return {
    contractorsPropsActions: bindActionCreators(contractorActions, dispatch)
  };
}

ContractorsEdit.propTypes = {
  authState: PropTypes.object.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(ContractorsEdit);
