import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import customerActions from '../../actions/customerActions';
import BaseComponent from '../base/BaseComponent';
import TableList from '../common/datatables/TableList';
import SearchBar from '../common/layout/SearchBar';
import ModalCustomerForm from '../common/modals/ModalCustomerForm';
import ModalConfirm from '../common/modals/ModalConfirm';
import _ from 'lodash';
import I18n from 'i18n-js';
import {toastr} from 'react-redux-toastr';
import {SEARCH_BAR_EVENT, SEARCH_KEY, SEARCH_LIST} from "../../constants/constants";
import './style.scss';
import CustomPagination from '../common/datatables/CustomPagination';

class TabCustomers extends BaseComponent {
  constructor(props, context) {
    super(props, context);
    
    this.state = {
      tabActive: '',
      pageSize: 10,
      pageNumber: 1,
      columns: [
        { name: I18n.t('PageCrm.Name'), value: 'name', options: { clsTh: 'pagecrmName'} },
        { name: I18n.t('PageCrm.ShortName'), value: 'shortName', },
        { name: I18n.t('PageCrm.Company'), value: 'company.name', options: { clsTh: 'pagecrmCompany'} },
        { name: I18n.t('PageCrm.Address'), value: 'company.address', options: { clsTh: 'pagecrmAddress'} },
        { name: I18n.t('PageCrm.Email'), value: 'email' },
        { name: I18n.t('PageCrm.Phone'), getValue: (i) => {
          let phoneNumber = i.phoneNumber == '+' ? '-': i.phoneNumber;
          return phoneNumber;
        }, options: { clsTh: 'center', clsTd: 'center' } },
        { name: I18n.t('PageCrm.Fax'), value: 'fax', options: { clsTh: 'center', clsTd: 'center' } },
        { name: I18n.t('PageCrm.Country'), value: 'country', options: { clsTh: 'center', clsTd: 'center' } },
        { name: I18n.t('PageCrm.Currency'), value: 'currency', options: { clsTh: 'center', clsTd: 'center' } },
        { name: I18n.t('PageCrm.BankAccNo'), value: 'bankAccountNo', options: { clsTh: 'center', clsTd: 'center' } },
        {
          name: I18n.t('PageCrm.Warehouse'),
          getValue: (i) => (i.warehouses || []).length,
          options: { clsTh: 'center', clsTd: 'center' }
        },
      ],
      
      items: [],
      total: 0,
      searchValue: {},
      search: {key: SEARCH_KEY.NAME, value: ""}
    }
    this.onDeleteCustomer = this.onDeleteCustomer.bind(this);
  }

  componentWillMount() {
    this.getListItem();
  }

  componentWillReceiveProps(newProps) {
    let { items } = this.state;
    !_.isEqual(items, newProps.customerState.items) && this.setState({
      items: _.cloneDeep(newProps.customerState.items) || [],
      total: newProps.customerState.count || 0
    });
    this.props.tabCurrent !== newProps.tabCurrent;
    const {deleteSuccessStatus} = newProps.customerState;
    if(deleteSuccessStatus) {
      newProps.customerActions.resetDeleteStatus()
      toastr.success(I18n.t('Notify.Success'), 'Delete Success');
      this.getListItem();
    }
  }

  componentWillUpdate(nextProps, nextState) {
    if (_.get(this.props.customerState, 'reload') != _.get(nextProps.customerState, 'reload')) {
      this.search(nextState.search, nextState.pageNumber, nextState.pageSize)
    }
    _.get(this.props.customerState, 'reload') != _.get(nextProps.customerState, 'reload') && this.props.modal.closeModal();
  }

  getListItem(query = {}) {
    let { pageSize, pageNumber } = this.state;
    let _query = _.assign({ pageSize: pageSize, pageNumber: pageNumber }, query);
    this.props.customerActions.getListCustomer(_.set(_query, 'sort', { createdAt: -1 }));
  }

  addItem() {
    const { openModal, closeModal } = this.props.modal;
    let modal = (<ModalCustomerForm closeModal={closeModal} items = {this.state.items}/>);
    openModal(modal);
  }

  editItem(item) {
    let { openModal, closeModal } = this.props.modal
    let modal = (<ModalCustomerForm closeModal={closeModal} customerDetail={item} />)
    openModal(modal)
  }

  showDeleteConfirm(item) {
    let { openModal, closeModal } = this.props.modal;
    let modal = (<ModalConfirm
      title={I18n.t('PageCrm.DELETECUSTOMER')}
      confirmMessage={I18n.t('PageCrm.ConfirmDeleteCustomer')}
      messageOk={I18n.t('Btn.YES')}
      messageCancel={I18n.t('Btn.CANCEL')}
      handleClose={closeModal}
      handleOk={this.onDeleteCustomer.bind(null, item)} />);
    openModal(modal);
  }

  onDeleteCustomer(item) {
    item._id && this.props.customerActions.deleteCustomer(item._id);
  }

  selectPage = (page) => {
    this.setState({pageNumber: page});
    this.search(this.state.search, page, this.state.pageSize);
  }

  onChangeSearch(property, value) {
    switch(property) {
      case SEARCH_BAR_EVENT.FILTER: {
        this.setState({search: {key: value, value: ""}});
        break;
      }
      case SEARCH_BAR_EVENT.SEARCH: {
        this.setState({search: {...this.state.search, value: value}});
        break;
      }
    }
  }

  onSearch(){
    const {search, pageNumber, pageSize} = this.state;
    this.search(search, pageNumber, pageSize);
  }

  search(search, pageNumber, pageSize) {
    let options = {};
    const populate= '';
    if(search && search.key && search.value) {
      switch(search.key) {
        case SEARCH_KEY.NAME: {
          options = {
            pageNumber,
            pageSize,
            sort: {updatedAt: -1},
            populate: populate,
            query: {name: {$regex: '.*' + search.value + '.*', $options: 'i'}}
          };
          this.props.customerActions.getListCustomer(options).then(res => {
            if(res && res.success && res.data && res.data.items && !res.data.items.length && pageNumber > 1) {
              options = {
                pageNumber:1,
                pageSize,
                sort: {updatedAt: -1},
                populate: populate,
                query: {name: {$regex: '.*' + search.value + '.*', $options: 'i'}}
              };
              this.setState({pageNumber: 1});
              this.props.customerActions.getListCustomer(options)
            }
          });
          break;
        }
        case SEARCH_KEY.SHORT_NAME: {
          options = {
            pageNumber,
            pageSize,
            sort: {updatedAt: -1},
            populate: populate,
            query: {shortName: {$regex: '.*' + search.value + '.*', $options: 'i'}}
          };
          this.props.customerActions.getListCustomer(options).then(res => {
            if(res && res.success && res.data && res.data.items && !res.data.items.length && pageNumber > 1) {
              options = {
                pageNumber:1,
                pageSize,
                sort: {updatedAt: -1},
                populate: populate,
                query: {shortName: {$regex: '.*' + search.value + '.*', $options: 'i'}}
              };
              this.setState({pageNumber: 1});
              this.props.customerActions.getListCustomer(options)
            }
          });
          break;
        }
        case SEARCH_KEY.COMPANY: {
          options = {
            pageNumber,
            pageSize,
            sort: {updatedAt: -1},
            populate: populate,
            query: {"company.name": {$regex: '.*' + search.value + '.*', $options: 'i'}}
          };
          this.props.customerActions.getListCustomer(options).then(res => {
            if(res && res.success && res.data && res.data.items && !res.data.items.length && pageNumber > 1) {
              options = {
                pageNumber:1,
                pageSize,
                sort: {updatedAt: -1},
                populate: populate,
                query: {"company.name": {$regex: '.*' + search.value + '.*', $options: 'i'}}
              };
              this.setState({pageNumber: 1});
              this.props.customerActions.getListCustomer(options)
            }
          });
          break;
        }
        case SEARCH_KEY.ADDRESS: {
          options = {
            pageNumber,
            pageSize,
            sort: {updatedAt: -1},
            populate: populate,
            query: {"company.address": {$regex: '.*' + search.value + '.*', $options: 'i'}}
          };
          this.props.customerActions.getListCustomer(options).then(res => {
            if(res && res.success && res.data && res.data.items && !res.data.items.length && pageNumber > 1) {
              options = {
                pageNumber:1,
                pageSize,
                sort: {updatedAt: -1},
                populate: populate,
                query: {"company.address": {$regex: '.*' + search.value + '.*', $options: 'i'}}
              };
              this.setState({pageNumber: 1});
              this.props.customerActions.getListCustomer(options)
            }
          });
          break;
        }
        case SEARCH_KEY.EMAIL: {
          options = {
            pageNumber,
            pageSize,
            sort: {updatedAt: -1},
            populate: populate,
            query: {email: {$regex: '.*' + search.value + '.*', $options: 'i'}}
          };
          this.props.customerActions.getListCustomer(options).then(res => {
            if(res && res.success && res.data && res.data.items && !res.data.items.length && pageNumber > 1) {
              options = {
                pageNumber:1,
                pageSize,
                sort: {updatedAt: -1},
                populate: populate,
                query: {email: {$regex: '.*' + search.value + '.*', $options: 'i'}}
              };
              this.setState({pageNumber: 1});
              this.props.customerActions.getListCustomer(options)
            }
          });
          break;
        }
        case SEARCH_KEY.COUNTRY: {
          options = {
            pageNumber,
            pageSize,
            sort: {updatedAt: -1},
            populate: populate,
            query: {country: {$regex: '.*' + search.value + '.*', $options: 'i'}}
          };
          this.props.customerActions.getListCustomer(options).then(res => {
            if(res && res.success && res.data && res.data.items && !res.data.items.length && pageNumber > 1) {
              options = {
                pageNumber:1,
                pageSize,
                sort: {updatedAt: -1},
                populate: populate,
                query: {country: {$regex: '.*' + search.value + '.*', $options: 'i'}}
              };
              this.setState({pageNumber: 1});
              this.props.customerActions.getListCustomer(options)
            }
          });
          break;
        }
      }
    }
    if(!search.value) {
      let options = {
        pageNumber,
        pageSize,
        sort: {updatedAt: -1},
        populate: populate,
      };
      this.props.customerActions.getListCustomer(options).then(res => {
        if(res && res.success && res.data && res.data.items && !res.data.items.length && pageNumber > 1) {
          options = {
            pageNumber:1,
            pageSize,
            sort: {updatedAt: -1},
            populate: populate,
          };
          this.setState({pageNumber: 1});
          this.props.customerActions.getListCustomer(options);
        }
      });
    }
  }

  render() {
    let { items, total, columns, pageNumber, pageSize } = this.state;
    let { roles: {showCreate, showEdit, showDelete} } = this.props;
    let actions = [
      () => {
        return showEdit ? { type: 'edit', click: (item) => this.editItem(item) } : {};
      },
      () => {
        return showDelete ? { type: 'delete', click: (item) => this.showDeleteConfirm(item) } : {};
      }
    ];
    let totalPage = _.ceil(total / pageSize);
    return (

      <div role="tabpanel" className="tab-pane active" id="crm-customers">
        <div className="wrap-search-position">
        <SearchBar
          name={I18n.t('PageCrm.AddNew')}
          addItem={showCreate ? (() => this.addItem()) : null}
          fields={SEARCH_LIST.CRM_CUSTOMER}
          onChangeSearch={(type, data) => this.onChangeSearch(type, data)}
          onSearch={() => this.onSearch()}
        />
        </div>
        <div className="scroller">
          <TableList {...this.props} items={items || []} columns={columns || []} actions={actions || []}
            options={{ clsTbl: 'global-table box-shadow-none' }} widthAction="w-70" />
        </div>
        <CustomPagination items={totalPage} activePage={pageNumber} onSelect={this.selectPage} />
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    appState: state.appState,
    authState: state.authState,
    customerState: state.customerState
  };
}

function mapDispatchToProps(dispatch) {
  return {
    customerActions: bindActionCreators(customerActions, dispatch)
  };
}

TabCustomers.propTypes = {
  authState: PropTypes.object.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(TabCustomers);
