import React from 'react';
import I18n from 'i18n-js';
import PropTypes from 'prop-types';
import SearchBar from '../../../common/components/SearchBar';
import FileSaver from 'file-saver';
import { pathImage } from '../../../../functions/helpers';
import _ from 'lodash';
import formatCurrency from "format-currency"

import icEditXIcon from '../../../../stylesheets/images/ic-edit-x.svg';
import icXIcon from '../../../../stylesheets/images/ic-x.svg';
import CustomPagination from '../../../../components/common/datatables/CustomPagination';

const AccountsListInvoice = props => {
    let style = {
        width: "100%"
    };
    // console.log(props)
    const {currencyTypeUSD, currencyTypeVND} = props;
    const displayMoney = value => `${props.currency === "USD" ? currencyTypeUSD : ''} ${formatCurrency(value, {
        maxFraction: props.currency === "USD" ? 2 : 0,
        minFraction: props.currency === "USD" ? 2 : 0,
    })} ${props.currency === "VND" ? currencyTypeVND : ''}`;
    return (
        <div className="view-accounts-payable-invoice-list">
            <div className="wrap-search-position top-s-70">
                <SearchBar
                    fields={props.listFilter}
                    selectedFilter={props.selectedFilter}
                    searchValue={props.searchValue || {}}
                    onChangeFilter={value => props.onChangeFilter(value)}
                    onChangeValue={(value, pos) => props.onChangeSearchValue(value, pos)}
                    onSearch={() => props.onSearch({...props.paramsRequest})}
                />
            </div>
            <ul className="content-header-title bg-white pd-0-15 box-shadow-1 mg-bottom-5">
                <li className="float-right mg-left-30">
                    <p>Remaining
                            <span className="bold mg-left-25 size-12 color-number mg-left-15">{displayMoney(props.remaining)}</span>
                    </p>
                </li>
                <li className="float-right mg-left-30">
                    <p>Paid
                            <span className="bold mg-left-25 size-12 mg-left-15">{displayMoney(props.paid)}</span>
                    </p>
                </li>
                <li className="float-right">
                    <p>Amount
                        <span className="bold mg-left-25 size-12 mg-left-15">{displayMoney(props.amount)}</span>
                    </p>
                </li>

                <li className="type-invoice-list float-left">
                    <span
                        className={props.currency == "USD" ? "cursor-pointer wrap active" : "cursor-pointer wrap"}
                        onClick={() => props.changeCurrency('USD')}
                    >
                        <span className="icon">
                            <span />
                        </span>
                        <span className="text">USD</span>
                    </span>

                    <span
                        className={props.currency == "VND" ? "cursor-pointer wrap active" : "cursor-pointer wrap"}
                        onClick={() => props.changeCurrency('VND')}
                    >
                        <span className="icon">
                            <span />
                        </span>
                        <span className="text">VND</span>
                    </span>
                </li>
            </ul>
            <table style={style} className="box-shadow bg-white global-table">
                <tbody>
                    <tr>
                        <th />
                        <th className="center">INV Request</th>
                        <th className="center">{I18n.t('ScreenAccountsPayable.InvoiceRef')}</th>
                        <th className="center">{I18n.t('ScreenAccountsPayable.InvoiceNo')}</th>
                        <th className="center">{I18n.t('ScreenAccountsPayable.CustomerPO')}</th>
                        <th className="center">{I18n.t('ScreenAccountsPayable.PurchaseOrder')}</th>
                        <th className="center m-width-105">{I18n.t('ScreenAccountsPayable.DueDate')}</th>
                        <th className="center">{I18n.t('ScreenAccountsPayable.Payee')}</th>
                        <th className="center">{I18n.t('ScreenAccountsPayable.Type')}</th>
                        <th className="center">{I18n.t('ScreenAccountsPayable.Amount')}</th>
                        <th className="center">{I18n.t('ScreenAccountsPayable.Paid')}</th>
                        {/* <th className="center">{I18n.t('ScreenAccountsPayable.Remaining')}</th> */}
                        <th className="center">{I18n.t('ScreenAccountsPayable.Actions')}</th>
                    </tr>
                    {props.listInvoice.map((item, index) => {
                        return (
                            <tr key={index}>
                                <td className="center style-checkbox">
                                    <input type="checkbox" id={item._id} checked = {item.checked} value="on" onClick={() => props.checkedInvoice(item._id, !item.checked)} />
                                    <label className = "position-absolute mg-left-25" htmlFor={item._id}>
                                        <span><span />
                                        </span>
                                    </label>
                                </td>
                                <td className="center">{item.invRequest}</td>
                                <td className="center">{item.invRef}</td>
                                <td className="center">
                                    {/* <span className="color-link text-decoration cursor-pointer"
                                        onClick={() => props.viewInvoiceNo(item._id)}
                                    >
                                    </span> */}
                                        {item.invNo}
                                </td>
                                <td className="center">{item.customerPOs.length > 0 ? (
                                    <div className="dropdown">
                                        <span className="dropdown-toggle button-text color-link" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            {item.customerPOs.length}
                                        </span>
                                        <div className="dropdown-menu">
                                        <table className="dropdown-table">
                                            <tbody>
                                            {item.customerPOs.map((p, index) => (
                                                <tr key={`customerPO_${index}`}>
                                                    <td>{p}</td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                        <span className="button-ok center color-orange bold cursor-pointer">OK</span>
                                        </div>
                                    </div>) : '-'
                                }</td>
                                <td className="center">{item.type === 'Supplier' ? (
                                    <div className="dropdown">
                                        <span className="dropdown-toggle button-text color-link" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            {item.purchaseOrders.length}
                                        </span>
                                        <div className="dropdown-menu">
                                        <table className="dropdown-table">
                                            <tbody>
                                            {item.purchaseOrders.map((p, index) => (
                                                <tr key={`droppurchase_${index}`}>
                                                    <td>{_.get(p, 'purchaseOrder.purchaseNo', '-')}</td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                        <span className="button-ok center color-orange bold cursor-pointer">OK</span>
                                        </div>
                                    </div>) : '-'
                                }</td>
                                <td className="center">{item.dueDate}</td>
                                <td className="center">{item.payee}</td>
                                <td className="center">{item.type}</td>
                                <td className="center">{displayMoney(item.totalAmount)}</td>
                                <td className="center color-link dropdown-table-style">
                                    <div className="dropdown">
                                        <button
                                            className="btn btn-secondary dropdown-toggle color-link text-decoration" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                            onClick={() => props.showPaidPopup(item._id)}
                                        >
                                            <span>{displayMoney(item.paid)}</span>
                                        </button>
                                        <div className="dropdown-menu box-shadow w-300" style={{left: -100}}>
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <th>Date</th>
                                                        <th>Paid</th>
                                                        <th>Attachment</th>
                                                    </tr>
                                                    {props.listPaid.map((paid, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td className = "w-100">{paid.date}</td>
                                                                <td className="color-number w-100">{displayMoney(paid.paid)}</td>
                                                                <td className = "w-100">
                                                                    {paid.type === 'Transfer' ? 
                                                                        <span className= "color-link cursor-pointer" style ={{wordBreak: 'break-all'}} onClick = {() =>{
                                                                            FileSaver.saveAs(pathImage(paid.raw.attachs[0]), paid.name)
                                                                            }}>{paid.name}</span>
                                                                    : 
                                                                    <span className= "color-link cursor-pointer" onClick={() => props.openReceiptModal(paid.raw.receipt)}>{paid.name}</span>
                                                                    }
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                            <span className="button-ok center color-orange bold cursor-pointer">OK</span>
                                        </div>
                                    </div>
                                </td>
                                <td className="center">
                                    {props.roles.showEdit && <span className="mg-right-5">
                                        <img
                                            src={icEditXIcon}
                                            alt="Edit"
                                            className="cursor-pointer"
                                            onClick={() => props.edit(item)}
                                        />
                                    </span>}
                                    {(item.paid == 0 && props.roles.showDelete) &&
                                    <span>
                                        <img
                                            src={icXIcon}
                                            alt="Delete"
                                            className="cursor-pointer"
                                            onClick={() => props.showInvoiceConfirmModal(item._id)}
                                        />
                                    </span>
                                    }
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            <CustomPagination 
                items={props.totalPage} 
                activePage={props.currentPage} 
                onSelect={page => { props.currentPage !== page && props.changePage({...props.paramsRequest, pageNumber: page}) }}
            />
            {props.roles.showCreate && <div className="center bg-gray">
                {/* <ul className="list-button">
                    <li>
                        <a className="bt-black" onClick={() => props.export()}>EXPORT CB</a>
                    </li>
                </ul> */}
                <ul className="list-button">
                    <li>
                        <a className="bt-orange" onClick={() => props.openPreviewInvoiceModal()}>CREATE INV REQUEST</a>
                    </li>
                </ul>
            </div>}
        </div>
    )
}

AccountsListInvoice.propTypes = {
    listInvoice: PropTypes.array,
    listFilter: PropTypes.array,
    listPaid: PropTypes.array,
    selectedFilter: PropTypes.string,
    searchValue: PropTypes.object,
    currentPage: PropTypes.number,
    totalPage: PropTypes.number,
    currency: PropTypes.string,

    openReceiptModal: PropTypes.func,
    openPreviewInvoiceModal: PropTypes.func,
    edit: PropTypes.func,
    deleteInvoice: PropTypes.func,
    export: PropTypes.func,
    showPaidPopup: PropTypes.func,
    viewInvoiceNo: PropTypes.func,
    changePage: PropTypes.func,
    onChangeFilter: PropTypes.func,
    onChangeSearchValue: PropTypes.func,
    changeCurrency: PropTypes.func,
    getListInvoice: PropTypes.func,
}
AccountsListInvoice.defaultProps = {
    listInvoice: [],
    listFilter: [],
    listPaid: [],
    selectedFilter: '',
    searchValue: '',
    currentPage: 1,
    totalPage: 1,
    openReceiptModal: f => f,
    openPreviewInvoiceModal: f => f,
    edit: f => f,
    deleteInvoice: f => f,
    export: f => f,
    showPaidPopup: f => f,
    viewInvoiceNo: f => f,
    changePage: f => f,
}

export default AccountsListInvoice;