import React from 'react';
import Modal from 'react-modal';
import I18n from 'i18n-js';
import PropTypes from 'prop-types';

const DeleteInvoiceModal =  props => {
    const style = {
        overlay: {
          position: 'fixed',
          backgroundColor: 'rgba(0, 0, 0, 0.75)',
          zIndex: '3'
        },
        content: {
          position: 'auto',
          top: '50%',
          left: '50%',
          backgroundColor: 'rgba(0,0,0,0)',
          border: '0px',
          borderRadius: '0px',
          outline: 'none',
        },
      };
      const {openModal, id} = props
    //   console.log(props)
        return (
            <Modal style = {style} isOpen = {openModal}>
                <div className="modal-dialog" role="document" id="accounts-payable-invoice-receipt">
                    <div className="modal-content">
                        <form>
                            <div className="modal-header">
                                <h4 className="modal-title" id="myModalLabel">{I18n.t('Modal.Title.DeleteInvoice')}</h4>
                            </div>
                            <div className="modal-body pd-0">
                                <div className="col-xs-12">
                                    <div className="form-group">
                                        <div className="col-md-12">
                                            {I18n.t('Modal.Content.DeleteInvoice')}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="bt-black" onClick = {() => props.close()}>{I18n.t('Button.Cancel')}</button>
                                <button type="button" className="bt-orange" onClick = {() => props.delete(id)}>{I18n.t('Button.Delete')}</button>
                            </div>
                            </form>
                        </div>
                    </div>
            </Modal>
    );
}

DeleteInvoiceModal.propTypes = {
    delete: PropTypes.func,
    id: PropTypes.string.isRequired
}
DeleteInvoiceModal.defaultProps = {
    delete: f => f
}

export default DeleteInvoiceModal;