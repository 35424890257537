import {getListMaterialDAL, addMaterialDAL, editMaterialDAL, getMaterialDAL, deleteMaterialDAL} from '../api/materialApi';

class materialServices {

  constructor() {
  }


  getListMaterial (options = {}) {
   return getListMaterialDAL(options);
  }

  getDetail (id) {
    return getMaterialDAL(id, {});
  }

  deleteMaterial (id) {
   return deleteMaterialDAL(id);
  }

  editMaterial (id, data) {
    return editMaterialDAL(id, data);
  }

  addMaterial (data) {
    return addMaterialDAL(data);
  }
}

export default new materialServices();
