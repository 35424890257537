import React, { Component } from 'react';
import { connect } from 'react-redux';

class Select extends Component {

    constructor(props){
        super(props);
        this.state = {
            select: undefined
        };
    }

    select(option){
        let {onChange} = this.props;
        onChange(option.value);
        this.setState({select: option.value});
    }

    render(){
        let {value, options} = this.props.options;

        if(this.state.select){
            value = this.state.select;
        }

        let select = options.find((option) => {return option.value == value;});
        options = options.filter((option) => {return option.value != value;});

        if(!select){
            select = {text: ''};
        }

        return (
            <div className="btn-group w-full">
                <button type="button" className="select form-control text-left"
                          data-toggle="dropdown"> {select.text}
                    <span className="caret" />
                </button>
                <ul className="dropdown-menu w-full" role="menu">
                    {
                        options.map((option, index) => {
                            return (
                                <li key={index}><a onClick={()=>{this.select(option);}}>{option.text}</a></li>
                            );
                        })
                    }
                </ul>
            </div>
        );
    }

}

export default connect()(Select);
