import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import BaseComponent from '../../base/BaseComponent';
import BarOrderInfo from '../../common/layout/BarOrderInfo';
import SidebarOrders from '../../common/layout/SidebarOrdersCommon';
import orderActions from '../../../actions/orderActions';
import appActions from '../../../actions/appActions';
import productionActions from '../../../actions/productionActions';
import I18n from 'i18n-js';
import {PRODUCTION_TAB} from "../../../constants/constants";
import TabProgress from "./progress/TabProgress";
import * as permissions from "../../../selectors/permissions";
import * as selectors from '../../../selectors/productionOrders';
import _ from "lodash";
import { PAGINATION } from '../../../constants/constants';
class ScreenProduction extends BaseComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {
      selectedOrder: {},
      pageSize: PAGINATION.PAGE_SIZE,
      pageNumber: PAGINATION.PAGE_NUMBER,
    };
    this.onSubmitSearch = this.onSubmitSearch.bind(this)
    this.handleSelectOrder = this.handleSelectOrder.bind(this);
  }

  componentWillMount(){
    const { pageSize, pageNumber } = this.state;
    const options = {pageSize: pageSize, pageNumber: pageNumber, production: true, filter: true };
    this.props.loadFirstData(options);
    this.props.onChangePageNumber(PAGINATION.PAGE_NUMBER);
    this.props.onChangeSearch();

    // this.props.productionActions.getListProductionOrders();
    // const items = _.get(this.props.productionOrderState, "items", []);
    this.setData(this.props);
  }

  componentWillReceiveProps(nextProps){
    if(!_.isEqual(this.props.productionOrderState, nextProps.productionOrderState)) {
      this.setData(nextProps);
    }
    // if(!_.isEqual(this.props.productionOrderState.reload, nextProps.productionOrderState.reload)) {
    //   this.props.productionActions.getListProductionOrders();
    // }
  }

  componentWillUpdate(nextProps) {
    this.props.orderState.reload != nextProps.orderState.reload && this.props.modal.closeModal();
    let {selectedOrder} = this.state;
    this.props.orderState.reloadDetail != nextProps.orderState.reloadDetail && selectedOrder._id && this.props.orderPropsActions.getDetail(selectedOrder._id, false);
  }

  setData(props){
    const items = _.get(props.productionOrderState, "items", []);
    const {productionData} = props;
    let selectedOrder = _.get(productionData, "selectedOrder",null);
    if(selectedOrder){
      selectedOrder = items.find(x => x._id === selectedOrder._id);
      this.props.setProductionData("selectedOrder", selectedOrder);
    }
    selectedOrder = selectedOrder || items[0];
    this.setState({selectedOrder: selectedOrder || {}})
  }

  handleSelectOrder(item) {
    this.setState({selectedOrder: item});
    if (this.props.setProductionData) {
      this.props.setProductionData("selectedOrder", item);
    }
  }

  handleStartProduction(item) {
    this.props.orderActions.editOrder(item._id, {startDate: new Date()}).then(res => {
      res && res.success && this.props.productionActions.getListProductionOrders();
    });
  }

  setTabActive(tab) {
    this.setState({tabActive: tab, searchValue: {}});
    this.props.appActions.setPageCurrent(_.assign(_.cloneDeep(this.props.appState.page), {context: {tabSub: tab}}));
    this.props.setProductionData("activeTab", tab);
  }


  handleLoadMore(number) {
    const { isSearch, searchText } = this.props
    const {pageSize, pageNumber } = this.state;
    if (isSearch && searchText == '') { // Search not value 
      this.props.cancelChangeSearch();
      this.props.cancelChangePageNumber();
      this.getListFirstOrder(pageSize, pageNumber);
    } else if (isSearch && searchText) { // Search value
      this.props.onChangePageNumber(number)
      this.getListOrderSearch(pageSize, number)
    } else { // Not search
      this.props.onChangePageNumber(number)
      this.props.cancelChangeSearch()
      this.getListOrderMore(pageSize, number)
    }
  }
  getListOrderMore(size, number) {
    const query = { pageSize: size, pageNumber: number, production: true, filter: true};
    this.props.getListOrderMore(_.assign(query));
  }
  
  getListFirstOrder(size, number) {
    const query = { pageSize: size, pageNumber: number, production: true, filter: true };
    this.props.loadFirstData(_.assign(query));
  }

  getListOrderSearch(size, number) {
    const { searchText } = this.props
    const query = { pageNumber: number, pageSize: size, production: true, filter: true, searchKey: searchText};
    if (searchText) {
      this.props.getListOrderMore(_.assign(query));
    }
  }

  onSubmitSearch() {
    const { searchText } = this.props
    const { pageSize, pageNumber } = this.state;
    const querySearch = { pageNumber: pageNumber, pageSize: pageSize, production: true, searchKey: searchText};
    const querySearchNull = { pageNumber: pageNumber, pageSize: pageSize, production: true};
    this.props.cancelChangePageNumber();
    if (searchText) {
      this.props.changeSearch()
      this.props.loadFirstData(_.assign(querySearch));
    } else {
      this.props.cancelChangeSearch()
      this.props.loadFirstData(_.assign(querySearchNull));
    } 
  }


  render() {
    const {tabCurrent, productionOrderState, productionData, canStartProduction, roles} = this.props;
    let {selectedOrder} = productionData;
    selectedOrder = selectedOrder || this.state.selectedOrder;
    let order = _.cloneDeep(selectedOrder) || {};
    const activeTab = (_.get(productionData, "activeTab",""));
    return (
      <div className={tabCurrent === 'production' ? 'show' : 'hide'}>
        <div>
          <SidebarOrders
            {...this.props}
            selectedOrder={selectedOrder || {}}
            orders={_.get(productionOrderState, "items", [])}
            modal={this.props.modal}
            handleSelectedOrder={this.handleSelectOrder}
            handleLoadMore={(pageNumber) => this.handleLoadMore(pageNumber)}
            onSubmitSearch={this.onSubmitSearch}

          />

          <div className="content-main" id="production-tab">
            <BarOrderInfo order={order} isStartDate canStartProduction={canStartProduction} onStartProduction={()=>{this.handleStartProduction(order)}}/>

            <div className="global-table-tab pd-10-0">
              <ul className="nav nav-tabs wrap-tab" role="tablist">
                <li role="presentation" className={(!activeTab || activeTab === PRODUCTION_TAB.PROGRESS) ? "active":""} onClick={() => this.setTabActive(PRODUCTION_TAB.PROGRESS)}>
                  <a href={"#progress"} aria-controls="progress" role="tab"
                     data-toggle="tab">{I18n.t('PageProduction.Progress')}</a>
                  <span className="line"/>
                </li>
                {/* <li role="presentation" className={(activeTab === PRODUCTION_TAB.ACTUAL_YIELD) ? "active":""} onClick={() => this.setTabActive(PRODUCTION_TAB.ACTUAL_YIELD)}>
                  <a href="#actual_yield" aria-controls="actual_yield" role="tab"
                     data-toggle="tab">{I18n.t('PageProduction.ActualYield')}</a>
                </li> */}
              </ul>
              {(!activeTab || activeTab === PRODUCTION_TAB.PROGRESS) &&
                <TabProgress order={order} roles={roles}/>
              }
              {/* {(activeTab === PRODUCTION_TAB.ACTUAL_YIELD) &&
                <TabActualYield
                  order={order}
                  onChangeActual={this.props.onChangeActual}
                  onSelectCutTicked={this.props.onSelectCutTicked}
                  setProductionData={this.props.setProductionData}
                  productionData={this.props.productionData}
                />
              } */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    appState: state.appState,
    orderState: state.orderState,
    authState: state.authState,
    productionOrderState: state.productionOrderState,
    canStartProduction: permissions.isSpecial({moduleKey: "production", ...state}),

    pageNumber: selectors.getPageNumber(state),
    searchText: selectors.getFilterText(state),
    isLoadMore: selectors.isOpenLoadMore(state),
    isSearch: selectors.isSearch(state),
    items: selectors.getOrders(state),
    isLoading: selectors.isLoading(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    orderActions: bindActionCreators(orderActions, dispatch),
    appActions: bindActionCreators(appActions, dispatch),
    productionActions: bindActionCreators(productionActions, dispatch),

    loadFirstData(options) {
      dispatch(productionActions.getListProductionOrders(options));
    },

    getListOrderMore(options) {
        dispatch(productionActions.getListOrderMore(options));
    },

    cancelChangePageNumber() {
        dispatch(productionActions.cancelChangePageNumber());
    },

    onChangePageNumber(number) {
        dispatch(productionActions.changePageNumber(number));
    },

    onChangeSearch(text) {
        dispatch(productionActions.changeFilterText(text ? text : ''));
    },

    cancelChangeSearch() {
        dispatch(productionActions.cancelChangeSearch());
    },

    changeSearch() {
        dispatch(productionActions.changeSearch());
    },
  };
}

ScreenProduction.propTypes = {
  authState: PropTypes.object.isRequired,
  appActions: PropTypes.object,
  productionActions: PropTypes.object,
  productionOrderState: PropTypes.object,
  productionData: PropTypes.object,
  onChangeActual: PropTypes.func,
  onSelectCutTicked: PropTypes.func,
  setProductionData: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(ScreenProduction);
