import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import DateTimeField from 'react-datetime';
import moment from 'moment';
import organizationActions from '../../../actions/organizationActions';
import departmentActions from '../../../actions/departmentActions';
import roleActions from '../../../actions/roleActions';
import userActions from '../../../actions/userActions';
import SelectValidate from '../../common/fields/SelectValidate';
import MultiSelectField from '../../common/fields/MultiSelectField';
import TextInputValidate from '../../common/fields/TextInputValidate';
// import DateTimePickerValidate from '../../common/fields/DateTimePickerValidate';
import I18n from 'i18n-js';
import { isStringEmpty, validateEmail } from '../../../functions/helpers';
import roleServices from "../../../services/roleServices";
import organizationServices from "../../../services/organizationServices";
import departmentServices from "../../../services/departmentServices";
import customerServices from "../../../services/customerServices";
import ReactTelephoneInput from 'react-telephone-input/lib/withStyles'
import {toastr} from "react-redux-toastr";

import flagsIcon from '../../../stylesheets/images/flags.png';

class ModalUserForm extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      userDetail: {},
      organizations: [],
      departments: [],
      customers: [],
      selectedCustomers: '',
      roles: [],
      genders: [{ value: 'Male', label: 'Male' }, { value: 'Female', label: 'Female' }],
      errors: {}
    };
    this.onChange = this.onChange.bind(this);
    this.customerSelectChange = this.customerSelectChange.bind(this);
    this.validateBeforeSave = this.validateBeforeSave.bind(this);
  }

  componentWillMount() {
    let { userDetail } = this.state;
    let { propUserDetail } = this.props;

    //this.props.organizationPropsActions.getListOrganization({});
    //this.props.departmentPropsActions.getListDepartment({});
    //this.props.roleActions.getListRole({});

    this.getListRole();
    this.getListDepartment();
    this.getListOrganization();

    this.getListCustomer();
    if (propUserDetail && userDetail._id !== propUserDetail._id) {
      userDetail = Object.assign({}, propUserDetail);

      userDetail.organization = _.get(userDetail, 'organization._id', '');
      userDetail.department = _.get(userDetail, 'department._id', '');
      userDetail.role = _.get(userDetail, 'role._id', '');

      if (userDetail.isTakeCareAllCustomer) {
        this.setState({ selectedCustomers: ["All"] });
      } else {
        if (userDetail.customers) {
          this.setState({ selectedCustomers: userDetail.customers });
        }
      }
      this.setState({ userDetail: userDetail });
    }
  }

  getListRole() {
    roleServices.getListRole().then(res => {
      let arrayRole = (res && res.data && res.data.items) ? res.data.items : [];
      this.setState({ roles: arrayRole.map(item => { return { value: item._id, label: item.name } }) });
    });
  }

  getListDepartment() {
    departmentServices.getListDepartment({}).then(res => {
      let arrayDepartment = res.data.items || [];
      this.setState({ departments: arrayDepartment.map(item => { return { value: item._id, label: item.name, organizationId: item.organizationId } }) });
    });
  }
  getListOrganization() {
    organizationServices.getListOrganization({}).then(res => {
      let arrayOrganization = res.data.items || [];
      this.setState({ organizations: arrayOrganization.map(item => { return { value: item._id, label: item.name }; }) });
    });
  }

  getListCustomer() {
    customerServices.getListCustomer({}).then(res => {
      let arrayCustomer = res.data.items || [];
      arrayCustomer.unshift({ _id: "All", name: "All" });
      this.setState({ customers: arrayCustomer.map(item => { return { value: item._id, label: item.name }; }) });
    });
  }

  onChange(property, value) {
    let { userDetail } = this.state;
    if (property === 'isConvert') {
      userDetail.convertTo = value === false ? '' : value;
    }
    else if (property === 'organization') {
      userDetail.department = '';
      userDetail.organization = value;
    }
    else {
      this.setState({ userDetail: _.set(userDetail, property, value) });
    }
    this.setState({ userDetail: userDetail });
    this.validateAfterChange(property, value);
  }

  customerSelectChange(value) {
    let { userDetail } = this.state;
    let selectedValues = value.split(",");
    if (selectedValues.includes("All")) {
      if (selectedValues[selectedValues.length - 1] == "All") {
        selectedValues = ["All"];
      } else {
        selectedValues.splice(selectedValues.indexOf("All"), 1)
      }
    }

    this.setState({ selectedCustomers: selectedValues.join() })
    this.setState({ userDetail: _.set(userDetail, "customers", selectedValues) });
    this.setState({ userDetail: userDetail })
  }

  close() {
    this.props.closeModal();
  }

  validateAfterChange(property, value) {
    let { errors } = this.state;
    delete errors[property];
    switch (property) {
      case 'firstName': {
        if (isStringEmpty(value)) {
          errors['firstName'] = I18n.t('ErrorMessages.UserFirstNameIsRequired');
        }
        this.setState({ errors });
        break;
      }
      case 'lastName': {
        if (isStringEmpty(value)) {
          errors['lastName'] = I18n.t('ErrorMessages.UserLastNameIsRequired');
        }
        this.setState({ errors });
        break; 
      }
      case 'email': {
        if (isStringEmpty(value)) {
          errors['email'] = I18n.t('ErrorMessages.UserEmailIsRequired');
        } else if (!validateEmail(value)) {
          errors['email'] = I18n.t('ErrorMessages.UserEmailWrongFormat');
        }
        this.setState({ errors });
        break; 
      }
      case 'organization': {
        if (isStringEmpty(value)) {
          errors['organization'] = I18n.t('ErrorMessages.PleaseChooseOrganization');
        }
        this.setState({ errors });
        break; 
      }
      case 'department': {
        if (isStringEmpty(value)) {
          errors['department'] = I18n.t('ErrorMessages.PleaseChooseDepartment');
        }
        this.setState({ errors });
        break; 
      }
      case 'role': {
        if (isStringEmpty(value)) {
          errors['role'] = I18n.t('ErrorMessages.PleaseChooseRole');
        }
        this.setState({ errors });
        break;
      }
      default:
    }
  }

  validateBeforeSave() {
    let { userDetail, errors } = this.state;
    let isNotError = true;
    errors = {};
    if (!userDetail) {
      errors['firstName'] = I18n.t('ErrorMessages.UserInfoIsRequired');
      this.setState({ errors });
      isNotError = false;
    }

    if (isStringEmpty(userDetail.firstName)) {
      errors['firstName'] = I18n.t('ErrorMessages.UserFirstNameIsRequired');
      this.setState({ errors });
      isNotError = false;
    }

    if (isStringEmpty(userDetail.lastName)) {
      errors['lastName'] = I18n.t('ErrorMessages.UserLastNameIsRequired');
      this.setState({ errors });
      isNotError = false;
    }

    if (isStringEmpty(userDetail.email)) {
      errors['email'] = I18n.t('ErrorMessages.UserEmailIsRequired');
      this.setState({ errors });
      isNotError = false;
    }

    if (!validateEmail(userDetail.email) && !errors['email']) {
      errors['email'] = I18n.t('ErrorMessages.UserEmailWrongFormat');
      this.setState({ errors });
      isNotError = false;
    }

    if (isStringEmpty(userDetail.organization)) {
      errors['organization'] = I18n.t('ErrorMessages.PleaseChooseOrganization');
      this.setState({ errors });
      isNotError = false;
    }

    if (isStringEmpty(userDetail.department)) {
      errors['department'] = I18n.t('ErrorMessages.PleaseChooseDepartment');
      this.setState({ errors });
      isNotError = false;
    }

    if (isStringEmpty(userDetail.role)) {
      errors['role'] = I18n.t('ErrorMessages.PleaseChooseRole');
      this.setState({ errors });
      isNotError = false;
    }
    this.setState({ errors });
    return isNotError;;
  }

  setUser(field, value) {
    var userDetail = this.state.userDetail
    userDetail[field] = value
    var errors = this.state.errors
    errors[field] = null
    this.setState({ userDetail: userDetail, errors })
  }

  updateLock(){
      let { userDetail } = this.state;
      this.props.userPropsActions.updateLock(userDetail._id).then(res => {
          if(res && !res.success) {
              const error = _.get(res, "error", "");
              if(error === 'Email already exist') {
                  let { errors } = this.state;
                  errors['email'] = I18n.t('ErrorMessages.EmailIsAlreadyExist');
                  this.setState({ errors });
              } else {
                  error && toastr.error('Error', error);
              }
          }
      });
  }
  save() {
    if (!this.validateBeforeSave())
      return;

    let { userDetail } = this.state;
    let customers = [];
    _.forEach(userDetail.customers, (c) => {
      if (c != '') {
        customers.push(c);
      }
    })
    userDetail.customers = customers;

    if (userDetail._id) {
      this.props.userPropsActions.editUser(userDetail._id, userDetail).then(res => {
        if(res && !res.success) {
          const error = _.get(res, "error", "");
          if(error === 'Email already exist') {
            let { errors } = this.state;
            errors['email'] = I18n.t('ErrorMessages.EmailIsAlreadyExist');
            this.setState({ errors });
          } else {
            error && toastr.error('Error', error);
          }
        }
      });
    } else {
      delete userDetail._id;
      this.props.userPropsActions.addUser(userDetail).then(res => {
        if(res && !res.success) {
          const error = _.get(res, "error", "");
          if(error === 'Email already exist') {
            let { errors } = this.state;
            errors['email'] = I18n.t('ErrorMessages.EmailIsAlreadyExist');
            this.setState({ errors });
          } else {
            error && toastr.error('Error', error);
          }
        }
      });
    }
  }

  render() {
    let { userDetail, genders, organizations, departments, roles, customers, selectedCustomers, errors } = this.state;
    const joinedDate = userDetail ? moment(userDetail.joinedDate) : moment();
    
    return (
      <div className="modal-dialog modal-add-user" role="document">
        <div className="modal-content">
          <form>
            <div className="modal-header">
              <a className="close" onClick={() => this.close()}><span aria-hidden="true">&times;</span></a>
              <h4 className="modal-title" id="myModalLabel">{userDetail && userDetail._id ? I18n.t('PageOrganization.EDITUSER') : I18n.t('PageOrganization.ADDUSER')}</h4>
            </div>

            <div className="modal-body">
              <TextInputValidate value={userDetail.firstName || ''}
                label={I18n.t('PageOrganization.FirstName')}
                property={'firstName'}
                isRequired = {true}
                placeholder={I18n.t('PageOrganization.FirstName')}
                error={errors['firstName']}
                onChange={this.onChange} wrapClass='col-md-12 col-xs-12 box' />
              <TextInputValidate value={userDetail.lastName || ''}
                label={I18n.t('PageOrganization.LastName')}
                property={'lastName'}
                isRequired = {true}
                placeholder={I18n.t('PageOrganization.LastName')}
                error={errors['lastName']}
                onChange={this.onChange}
                wrapClass='col-md-12 col-xs-12 box' />

              <SelectValidate options={genders || []}
                value={userDetail.gender || ''}
                label={I18n.t('PageOrganization.Gender')}
                property={'gender'}
                placeholder={I18n.t('PageOrganization.Gender')}
                error={errors['gender']}
                onChange={this.onChange}
                wrapClass='col-md-12 col-xs-12 box' />

              <div className="col-md-12 col-xs-12 box">
                <div className="form-group">
                  <div className="col-md-4 col-xs-12 text no-padding-left">
                    <p>{I18n.t('PageOrganization.Phone')}</p>
                  </div>
                  <div className="col-md-8 col-xs-12 value no-padding-right">
                    <ReactTelephoneInput className='w-full'
                      defaultCountry='us'
                      autoFormat={false}
                      flagsImagePath={flagsIcon}
                      initialValue={(userDetail.phone ? userDetail.phone : '')}
                      onChange={(phone) => this.setUser('phone', phone)}
                    />
                  </div>
                </div>
              </div>

              <TextInputValidate value={userDetail.email || ''}
                label={I18n.t('PageOrganization.Email')}
                property={'email'}
                isRequired = {true}
                placeholder={I18n.t('PageOrganization.Email')}
                error={errors['email']}
                onChange={this.onChange}
                wrapClass='col-md-12 col-xs-12 box' />
              <SelectValidate options={organizations || []}
                value={userDetail.organization || ''}
                label={I18n.t('PageOrganization.Organization')}
                property={'organization'}
                isRequired = {true}
                placeholder={I18n.t('PageOrganization.Organization')}
                error={errors['organization']}
                onChange={this.onChange}
                wrapClass='col-md-12 col-xs-12 box' />
              <SelectValidate options={(departments || []).filter(department => department.organizationId == userDetail.organization)}
                value={userDetail.department || ''}
                label={I18n.t('PageOrganization.Department')}
                property={'department'}
                isRequired = {true}
                placeholder={I18n.t('PageOrganization.Department')}
                error={errors['department']}
                onChange={this.onChange}
                wrapClass='col-md-12 col-xs-12 box' />
              <SelectValidate options={roles || []}
                value={userDetail.role || ''}
                label={I18n.t('PageOrganization.Role')}
                property={'role'}
                isRequired = {true}
                placeholder={I18n.t('PageOrganization.Role')}
                error={errors['role']}
                onChange={this.onChange}
                wrapClass='col-md-12 col-xs-12 box' />
              {/* <TextInputValidate value={userDetail.joinedDate || ''}
                label={I18n.t('PageOrganization.JoinedDate')}
                property={'joinedDate'}
                placeholder={I18n.t('PageOrganization.JoinedDate')}
                error={errors['joinedDate']}
                onChange={this.onChange}
                wrapClass='col-md-12 col-xs-12 box' /> */}
              {(userDetail && userDetail.joinedDate || !userDetail.id) &&
                <div className="col-md-12 col-xs-12 box">
                  <div className="form-group">
                    <div className="col-md-4 col-xs-12 text no-padding-left">
                      <p>{I18n.t('PageOrganization.JoinedDate')}</p>
                    </div>
                    <div className="col-md-8 col-xs-12 value no-padding-right">
                      <DateTimeField
                        timeFormat={false} closeOnSelect dateFormat="MMM DD, YYYY"
                        value={joinedDate}
                        onChange={date => this.onChange('joinedDate', date.format())}/>
                        {errors['joinedDate'] && <div className="alert-warning">{errors['joinedDate']}</div>}
                    </div>
                  </div>
                </div>
              }
              <MultiSelectField
                options={customers || []}
                value={selectedCustomers || ''}
                label={I18n.t('PageOrganization.Customer')}
                property={'role'}
                placeholder={I18n.t('PageOrganization.Customer')}
                error={errors['customer']}
                onChange={this.customerSelectChange}
                wrapClass='col-md-12 col-xs-12 box'
                stayOpen={false}
                removeSelected={true}
              />

              <div className={'clearBoth'}></div>
            </div>

            <div className="modal-footer">
              <button type="button" className="bt-black" onClick={() => this.close()}>{I18n.t('Btn.CANCEL')}</button>
                {userDetail.isLock &&
                <button type="button" className="bt-black" onClick={() => this.updateLock()}>{I18n.t('Btn.UNLOCK')}</button>
                }
                {!userDetail.isLock &&
                <button type="button" className="bt-black" onClick={() => this.updateLock()}>{I18n.t('Btn.LOCK')}</button>
                }


              <button type="button" className="bt-orange mg-l-5" onClick={() => this.save()}>{I18n.t('Btn.SAVE')}</button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    organizationState: state.organizationState,
    departmentState: state.departmentState,
    roleState: state.roleState,
    userState: state.userState
  };
}

function mapDispatchToProps(dispatch) {
  return {
    organizationPropsActions: bindActionCreators(organizationActions, dispatch),
    departmentPropsActions: bindActionCreators(departmentActions, dispatch),
    roleActions: bindActionCreators(roleActions, dispatch),
    userPropsActions: bindActionCreators(userActions, dispatch),
  };
}

ModalUserForm.propTypes = {
  userDetail: PropTypes.object
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalUserForm);
