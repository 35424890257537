import { combineReducers } from 'redux';
import C from '../../../constants/customer-po/current/cancelOrder';

const showing = (state = false, action) => {
  const { type } = action;
  switch (type) {
    case C.SHOW_MODAL:
      return true;
    case C.HIDE_MODAL:
      return false;
    default:
      return state;
  }
};

const order = (state = null, action) => {
  const { type, payload } = action;
  switch (type) {
    case C.CHANGE_ORDER_DETAIL:
      return payload;
    default:
      return state;
  }
}

const submiting = (state = false, action) => {
  const { type } = action;
  switch (type) {
    case C.SUBMIT:
      return true;
    case C.CANCEL_SUBMIT:
      return false;
    default:
      return state;
  }
}

const combined = combineReducers({
  showing,
  order,
  submiting,
});

export default (state, action) => {
  const { type } = action;
  if (type === C.RESET_DATA) {
    return {};
  }
  return combined(state, action);
};