import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import I18n from 'i18n-js';
import { toastr } from 'react-redux-toastr';
import _ from 'lodash';

import BaseComponent from '../base/BaseComponent';

import YieldFabric from './partial/YieldFabric';
import YieldTrim from './partial/YieldTrim';

import cutTicketActions from '../../actions/cutTicketActions';
import materialActions from '../../actions/materialActions';
import propertyActions from '../../actions/propertyActions';
import yieldActions from '../../actions/yieldActions';

import { sourceTypes } from "../../constants/EnumTypes";
import * as types from '../../constants/actionTypes';
import appActions from '../../actions/appActions';
import * as permissions from "../../selectors/permissions";

const YieldType = {
  self: 'self',
  contrast: 'contrast',
  lining: '_70D',
  specialTrims: 'specialTrims',
  optionalTrims: 'optionalTrims',
};

class ScreenYieldEdit extends BaseComponent {
  constructor (props, context) {
    super(props, context);

    this.state = {
      cutTicket: {},
      item: {},
      materials: [],
      colorsGlobal: [],
      sizesGlobal: [],
      errors: {},
    };
  }

  componentWillMount () {
    if (!_.isEmpty(this.props.cutTicket)) {
      let item = _.get(this.props.cutTicket || {}, 'yield', {}) || {};
      this.setState({
        cutTicket: _.cloneDeep(this.props.cutTicket),
        item: _.cloneDeep(this.convertOriginalItem(item))
      });

      this.getProperties(_.cloneDeep(this.props.cutTicket));
    }
    this.props.materialPropsActions.getListMaterial();
    this.props.propertyPropsActions.getListProperty();
  }

  componentWillReceiveProps (newProps) {
    let {cutTicket, materials} = this.state;

    if (!_.isEqual(cutTicket, newProps.cutTicket)) {
      let item = _.get(this.props.cutTicket || {}, 'yield', {}) || {};
      this.setState({
        cutTicket: _.cloneDeep(newProps.cutTicket),
        item: _.cloneDeep(this.convertOriginalItem(item))
      });

      this.getProperties(_.cloneDeep(newProps.cutTicket));
    }

    !_.isEqual(materials, newProps.materialState.items) && this.setState({materials: _.cloneDeep(newProps.materialState.items)});

    let { colorsGlobal ,sizesGlobal } = this.state;
    const properties = newProps.propertyState.items;
    let colors = _.filter(properties, (property) => property.key == 'color');
    let sizes = _.filter(properties, (property) => property.key == 'size');
    colors[0] && colors[0].values && !_.isEqual(colorsGlobal, colors[0].values) && this.setState({colorsGlobal: colors[0].values});
    sizes[0] && sizes[0].values && !_.isEqual(sizesGlobal, sizes[0].values) && this.setState({sizesGlobal: sizes[0].values});
    // properties['trim-size'] && !_.isEqual(sizesTrim, properties['trim-size']) && this.setState({sizesTrim: properties['trim-size']});
    // properties['color'] && !_.isEqual(colorsGlobal, properties['color']) && this.setState({colorsGlobal: properties['color']});
  }

  componentWillUnmount() {
    const { page } = this.props.appState;
    this.props.appPropsActions.setDataPageCurrent({...page, isEdit: false, isAdd: false})
  }

  convertOriginalItem (item) {
    if (item._id) {
      // self
      if (item.self && !_.isEmpty(item.self)) {
        let material = _.get(item, 'self.info.material', null);
        material && material._id && _.set(item, 'self.info.material', material._id);

        let estimate = _.get(item, 'self.estimate', []);
        estimate.forEach(i => {
          i.color && i.color._id && _.set(i, 'color', i.color._id);
        });
      }

      // constrast, 70D, specialTrims, optionalTrims
      [YieldType.contrast, YieldType.lining, YieldType.specialTrims, YieldType.optionalTrims].forEach(k => {
        if (item[k] && !_.isEmpty(item[k])) {
          item[k].forEach(i => {
            let material = _.get(i, 'info.material', null);
            material && material._id && _.set(i, 'info.material', material._id);

            let estimate = _.get(i, 'estimate', []);
            estimate.forEach(i2 => {
              i2.color && i2.color._id && _.set(i2, 'color', i2.color._id);
            });
          });
        }
      });
    }

    return item;
  }

  getProperties (cutTicket) {
    let colors = (cutTicket.colors || []).filter(c => !!c._id);
    let sizes = colors.reduce((acc, c) => {
      return (c.sizes || []).reduce((acc1, s) => {
        let q = _.get(acc1, `${s.name}.${c.name}`, 0);
        return _.set(acc1, `${s.name}.${c.name}`, q + (parseInt(s.quantity) || 0));
      }, acc);
    }, {});

    this.setState({colors, sizes});
  }

  onDelete(idx, type) {
    let item = Object.assign({},this.state.item);
    let newData = (type && item && item[type]) ? [...item[type]] : [];
    newData.splice(idx, 1);
    _.set(item, type, newData);
    this.setState({item});
  }

  getColorfieldName = (type) => {
    switch (type) {
      case YieldType.self: return "selfColor";
      case YieldType.contrast: return "contrastColor";
      case YieldType.lining: return "_70DColor";
      case YieldType.specialTrims: return "trimColor";
      case YieldType.optionalTrims: return "trimColor";
      default: return "";
    }
  }

  validate = (data, colors, type, sizes = []) => {
    
    let estimate = _.get(data, 'estimate', []);
    let info = _.get(data, 'info', {});
    let error = {};

    if(!colors || !colors.length) {
      toastr.error('Error', I18n.t('ErrorMessages.Yield.PleaseAddColorForThisCutTicket'));
      return false;
    }

    _.forEach(colors, (color, k) => {
      let i = _.findIndex(estimate, (elem) => elem.color === color._id && !_.isEmpty(elem[this.getColorfieldName(type)]));
      if(i !== -1) return;
      _.set(error, `color.${k}`, 'ErrorMessages.Yield.SelfColorIsRequired');
    })

    switch (type) {
      case YieldType.optionalTrims:
      case YieldType.specialTrims: {
        if (!info.material) {
          error.material = 'ErrorMessages.Yield.TrimIsRequired';    
        }

        let trimSize = _.get(data, 'trimSize', []);
        if (_.size(sizes) === trimSize.length) {
          _.each(trimSize, (t, index) => {
            if (t.value === "" || t.value === undefined || t.value === null) {
              _.set(error, `size.${index}`, 'ErrorMessages.Yield.TrimSizesIsRequired');
            }
          });
        } else {
          _.set(error, `size.${0}`, 'ErrorMessages.Yield.TrimSizesIsRequired');
        }
        break;
      }
      case YieldType.self:
      case YieldType.contrast:
      case YieldType.lining: {
        if (!info.material) {
          error.material = 'ErrorMessages.Yield.FabricIsRequired';    
        }
        if (!info.width && info.width !== 0) {
          error.width = 'ErrorMessages.Yield.WidthIsRequired';
        }
        if(!info.actualWidth && info.actualWidth !== 0) {
          error.actualWidth = 'ErrorMessages.Yield.WidthIsRequired';
        }
        break;
      }
    }

    // if(!info.percent) {
    //   errors.percent = 'ErrorMessages.Yield.PercentIsRequired';
    // }
    if(!info.yield && info.yield !== 0) {
      error.yield = 'ErrorMessages.Yield.CustomerYieldIsRequired';
    }

    if(!info.actualYield && info.actualYield !== 0) {
      error.actualYield = 'ErrorMessages.Yield.ActualYieldIsRequired';
    }

    if(!info.replacementPO && info.type === "NOMINATED") {
      error.replacementPO = 'ErrorMessages.Yield.ReplacementPOIsRequired';
    }

    return error;
  }

  validateData(item, colors, sizes) {
    let errors = {
      [YieldType.self]: {},
      [YieldType.contrast]: [],
      [YieldType.lining]: [],
      [YieldType.specialTrims]: [],
      [YieldType.optionalTrims]: [],
    };

    const selfError = this.validate(_.get(item, YieldType.self, {}), colors, YieldType.self);
    errors[YieldType.self] = selfError;
    const isSelfValid = _.isEmpty(selfError);

    let hadErrors = false;
    [YieldType.contrast, YieldType.lining, YieldType.specialTrims, YieldType.optionalTrims].forEach(i => {
      _.get(item, i, []).forEach((p, index) => {
        const error = this.validate(p, colors, i, sizes);
        errors[i][index] = error;
        if (!_.isEmpty(error)) {
          hadErrors = true;
        }
      });
    });

    this.setState({ errors });
    return isSelfValid && !hadErrors;
  }

  save () {
    let {item, cutTicket, colors, sizes} = this.state;
    let data = _.omit(item, ['auditLog']);

    if(this.validateData(data, colors, sizes)) {
      this.props.dispatch({
        type: types.APP.SET_PAGE_CURRENT,
        data: {
          isEdit: false,
        }
      });
      data.self.percent = data.self.percent || 0;
      data.self.yield = data.self.yield || 0;
      item._id ? this.props.yieldPropsActions.editYield(item._id, data)
        : this.props.yieldPropsActions.addYield(cutTicket._id, data)
    }
  }

  setProperty (property, info) {
    let isOK = true;
    if (property == 'isBlocked') {
      const {showDelete, canUnblock, isSuperAdmin} = this.props;
      if (info == false) {
        if (!isSuperAdmin && !canUnblock) {
          isOK = false;
          toastr.warning('Error', 'Only Admin and Special Role can unblock this yield');
        }
      } else {
        if (!isSuperAdmin && !showDelete) {
          isOK = false;
          toastr.warning('Error', 'Only Admin and Delete Role can block this yield');
        }
      }
    }
    if (isOK) {
      let {item} = this.state;
      this.setState({item: _.set(item || {}, property, info)});
    }
  }

  renderBlocked() {
    const {item} = this.state;
    const {showDelete, canUnblock, isSuperAdmin} = this.props;
    if(!showDelete && !isSuperAdmin && !canUnblock) {
      return null;
    }
    return (
      <li>
        <div className="style-checkbox position-relative">
        <span data-toggle="tooltip" data-placement="bottom" title="Block/Unblock this yield">
          <input type="checkbox" checked={!!item.isBlocked || false} value onChange={evt => this.setProperty('isBlocked', !!evt.target.checked)} />
          <label><span className="position-relative right-5"><span></span></span>{I18n.t('Btn.BLOCK')}</label>
        </span>
        </div>
      </li>
    );
  }

  renderSave() {
    const {item} = this.state;
    const {showCreate, showEdit, showDelete, isSuperAdmin} = this.props;
    if(!isSuperAdmin) {
      if(item.isBlocked) {
        if(!showDelete) {
          return null;
        }
      } else if(!showCreate && !showEdit && !showDelete) {
        return null;
      }
    }
    return(
      <li onClick={() => this.save()} className="cursor-pointer">
        <i className="fa fa-floppy-o cursor-pointer" aria-hidden="true" />
        {I18n.t('Btn.SAVE')}
      </li>
    );
  }

  render () {
    // console.log('=====++++====', this.state);
    let {cutTicket, item, materials, colorsGlobal, sizesGlobal, colors, sizes, errors} = this.state;
    let showEdit = this.props.showEdit
    return (
      <div className={`scroll-height-main content-main-edit-yield ${item.isBlocked && "gray-out"}`}>
        <div className="md content-header-edit-yield">
          <div className="title">
            <div className="text-left">
              <ul>
                <li><span className="text">{I18n.t('PageYield.CutTicket')}</span><span className="number">{cutTicket.cutTicket}</span></li>
                <li><span className="text">{I18n.t('PageYield.G-Total')}</span><span className="number">{cutTicket.gTotal}</span></li>
              </ul>
            </div>
            <div className="text-right">
              <ul>
                {this.renderBlocked()}
                {this.renderSave()}
              </ul>
            </div>
          </div>
          <div className="form">
            <div className="col-md-2 col-sm-3 col-xs-12 text">{I18n.t('PageYield.Note')}</div>
            <div className="col-md-10 col-sm-9 col-xs-12 textarea">
              <textarea rows="2" cols="50" value={item.note || ''} onChange={evt => this.setProperty('note', evt.target.value)} placeholder={I18n.t('PageYield.Typeyournote')} />
            </div>
          </div>
        </div>

        <YieldFabric {...this.props} name={"SELF"}
                      isBlocked={item.isBlocked}
                      wrap={{data: [item.self || {info: {type: sourceTypes.SOURCED}}]}}
                      setProperty={(info) => this.setProperty('self', info)}
                      onDelete={(idx)=>this.onDelete(idx, 'self')}
                      colors={colors || []}
                      materials={materials || []} 
                      colorsGlobal={colorsGlobal || []}
                      errors = {errors[YieldType.self]}
                      thName={"Self color"} 
                      fieldName={"selfColor"} 
                      isArray={false}/>

        <YieldFabric {...this.props} name={"CONTRAST"}
                      isBlocked={item.isBlocked}
                      wrap={{data: item.contrast}}
                      setProperty={(info) => this.setProperty('contrast', info)}
                      onDelete={(idx)=>this.onDelete(idx, 'contrast')}
                      colors={colors || []} 
                      materials={materials || []} 
                      colorsGlobal={colorsGlobal || []}
                      thName={"Contrast color"} 
                      fieldName={"contrastColor"} 
                      isArray={true}
                      errors = {errors[YieldType.contrast]} />

        <YieldFabric {...this.props} name={"LINING"}
                      isBlocked={item.isBlocked}
                      wrap={{data: item._70D}}
                      setProperty={(info) => this.setProperty('_70D', info)}
                      onDelete={(idx)=>this.onDelete(idx, '_70D')}
                      colors={colors || []} 
                      materials={materials || []} 
                      colorsGlobal={colorsGlobal || []}
                      thName={"70D color"} 
                      fieldName={"_70DColor"} 
                      isArray={true}
                      errors = {errors[YieldType.lining]} />

        <YieldTrim {...this.props} name={"SPECIAL TRIMS"}
                    isBlocked={item.isBlocked}
                    wrap={{data: item.specialTrims}}
                    setProperty={(info) => this.setProperty('specialTrims', info)}
                    onDelete={(idx)=>this.onDelete(idx, 'specialTrims')}
                    sizes={sizes || {}} 
                    colors={colors || []} 
                    materials={materials || []} 
                    colorsGlobal={colorsGlobal || []}
                    sizesGlobal={sizesGlobal || []}
                    errors = {errors[YieldType.specialTrims]}
                    isArray={true} />

        <YieldTrim {...this.props} name={"OPTIONAL TRIMS"}
                    isBlocked={item.isBlocked}
                    wrap={{data: item.optionalTrims}}
                    setProperty={(info) => this.setProperty('optionalTrims', info)}
                    onDelete={(idx)=>this.onDelete(idx, 'optionalTrims')}
                    sizes={sizes || {}} 
                    colors={colors || []} 
                    materials={materials || []} 
                    colorsGlobal={colorsGlobal || []}
                    sizesGlobal={sizesGlobal || []}
                    errors = {errors[YieldType.optionalTrims]}
                    isArray={true} />

      </div>
    );
  }
}

function mapStateToProps (state, ownProps) {
  return {
    authState: state.authState,
    cutTicketState: state.cutTicketState,
    materialState: state.materialState,
    propertyState: state.propertyState,
    appState: state.appState,
    canUnblock: permissions.isSpecial({moduleKey: "yield", ...state})
  };
}

function mapDispatchToProps (dispatch) {
  return {
    cutTicketPropsActions: bindActionCreators(cutTicketActions, dispatch),
    materialPropsActions: bindActionCreators(materialActions, dispatch),
    propertyPropsActions: bindActionCreators(propertyActions, dispatch),
    yieldPropsActions: bindActionCreators(yieldActions, dispatch),
    appPropsActions: bindActionCreators(appActions, dispatch),
    dispatch
  };
}

ScreenYieldEdit.propTypes = {
  authState: PropTypes.object.isRequired,
  showCreate : PropTypes.bool,
  showEdit : PropTypes.bool,
  showDelete : PropTypes.bool,
  isSuperAdmin: PropTypes.bool
};

export default connect(mapStateToProps, mapDispatchToProps)(ScreenYieldEdit);
