
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import * as R from 'ramda';
import I18n from 'i18n-js';

import EditStyleModal from '../../../components/customer-po/EditStyleModal';

import * as selectors from '../../../../selectors/customer-po/current/createStyle';
import * as actions from '../../../../actions/customer-po/current/createStyle';


class CreateStyleModal extends Component {
  render() {
    return (
      <EditStyleModal
        title={I18n.t('PageOrder.ADDSTYLE')}
        {...this.props}
        onSubmit={() => {
          const { orderId, fnReqBody } = this.props;
          this.props.saveStyle(orderId, fnReqBody());
        }}
      />
    )
  }
}

const mapStateToProps = state => ({
  orderId: selectors.getOrderId(state),
  isOpen: selectors.isShowing(state),
  style: selectors.getStyle(state),
  page: selectors.getPage(state),
  images: selectors.getImages(state),
  canSave: selectors.canSave(state),
  isSubmiting: selectors.isSubmiting(state),
  errors: selectors.getErrors(state),
  fnReqBody: selectors.fnGenerateBody(state),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onClose() {
    dispatch(actions.hideModal());
  },

  onChangeStyle(value) {
    dispatch(actions.changeStyle(value));
  },

  onChangePage(value) {
    dispatch(actions.changePage(value));
  },

  onAddImage(file) {
    dispatch(actions.addImage(file));
  },

  onRemoveImage(item) {
    dispatch(actions.removeImage(R.path(['raw', 'id'], item)));
  },

  saveStyle(orderId, style) {
    dispatch(actions.save(orderId, style));
  },
});

export default connect(mapStateToProps, mapDispatchToProps) (withRouter(CreateStyleModal));
