import C from '../../../constants/customer-po/current/createOrder';
import { listCustomerOrders } from '../../../actions/customer-po/current/showListOrders';
import orderServices from '../../../services/orderServices';
import customerServices from '../../../services/customerServices';
import { toastr } from 'react-redux-toastr';
import * as R from 'ramda';
import I18n from 'i18n-js';

export const showModal = () => dispatch => {
  dispatch({ type: C.SHOW_MODAL });
}

export const hideModal = () => dispatch => {
  dispatch({ type: C.HIDE_MODAL });
}

export const listValidateOrderNames = () => dispatch => {
  orderServices.getListOrder({}).then(res => {
    const payload = R.pathOr([], ['data', 'items'], res).map(it => it.customerPO);
    dispatch({ type: C.CHANGE_VALIDATE_ORDER_NAMES, payload });
  });
}

export const listSelectableCustomers = () => dispatch => {
  dispatch({ type: C.FETCH_SELECTABLE_CUSTOMERS });
  return customerServices.getListCustomer({}).then(res => {
    dispatch({ type: C.CANCEL_FETCH_SELECTABLE_CUSTOMERS });
    const payload = R.path(['data', 'items'], res);
    dispatch({ type: C.CHANGE_SELECTABLE_CUSTOMERS, payload });
  }).catch(error => {
    dispatch({ type: C.CANCEL_FETCH_SELECTABLE_CUSTOMERS });
    console.error(error);
  })
}

export const changeSelectedCustomer = customer => dispatch => {
  dispatch({ type: C.CHANGE_SELECTED_CUSTOMER, payload: customer });
};

export const changeCustomerPO = value => dispatch => {
  dispatch({ type: C.CHANGE_CUSTOMER_PO, payload: value });
};

export const changeOrderDate = value => dispatch => {
  dispatch({ type: C.CHANGE_ORDER_DATE, payload: value });
};

export const changeExFactory = value => dispatch => {
  dispatch({ type: C.CHANGE_EX_FACTORY, payload: value });
};

export const changeIhDate = value => dispatch => {
  dispatch({ type: C.CHANGE_IH_DATE, payload: value });
};

export const changeDescription = value => dispatch => {
  dispatch({ type: C.CHANGE_DESCRIPTION, payload: value });
};

export const submitOrder = order => dispatch => {
  dispatch({ type: C.SUBMIT_ORDER });
  orderServices.addOrder(order).then(res => {
    dispatch({ type: C.CANCEL_SUBMIT_ORDER });
    dispatch({ type: C.RESET_DATA });
    dispatch(listCustomerOrders());
    dispatch(hideModal());
  }).catch(error => {
    dispatch({ type: C.CANCEL_SUBMIT_ORDER });
    console.log('Error', error);
    toastr.error(I18n.t('Error'), error.error);
  });
};

