import Base from './base';

class Warehouse extends Base {
  constructor () {
    super();

    this.getListItemDAL = this.getListItemDAL.bind(this);
    this.getListReserveDAL = this.getListReserveDAL.bind(this);
    this.returnStyleResources = this.returnStyleResources.bind(this);
    this.exportReserve = this.exportReserve.bind(this);
  }

  getListItemDAL(query) {
    return super.execute(this.Urls.warehouse.list, this.ApiMethod.get, query);
  }

  getListReserveDAL(query) {
    return super.execute(this.Urls.warehouse.reserve, this.ApiMethod.post, query);
  }

  returnStyleResources(_id, styleId) {
    let url = this.Urls.warehouse.returnStyleResources.replace('{styleId}', styleId).replace('{id}', _id);
    return super.execute(url, this.ApiMethod.put);
  }

  exportReserve(search) {
    let filter = search.key || '';
    let value = search.value || '';
    let url = this.Urls.warehouse.exportReserve;
    url += '?filter=' + filter + '&value=' + value;
    return url;
  }
}

export default new Warehouse();
