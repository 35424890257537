import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
const moment = require('moment');
const DateTimeField = require('react-datetime');
import purchaseActions from '../../../actions/purchaseActions';
import purchasePlanActions from '../../../actions/purchasePlanActions';
import orderActions from '../../../actions/orderActions';
import supplierActions from '../../../actions/supplierActions';
import AddMaterial from '../modal/AddMaterial';
import _ from 'lodash';
import I18n from 'i18n-js';

import icAddIcon from '../../../stylesheets/images/ic-add.svg';

class ScreenPurchaseEdit extends Component {
  constructor(props, context) {
    super(props, context);
    // this.save = this.save.bind(this)
    // this.styleChange = this.styleChange.bind(this)
    // this.onSelect = this.onSelect.bind(this)
    // this.addMaterial = this.addMaterial.bind(this)
    // this.changeMaterial = this.changeMaterial.bind(this)
    // this.updatePlan = this.updatePlan.bind(this)
    this.state = {
      errors: {},
      // orderDetail: {},
      stylePurchasePlan: {},
      currentStyle: null,
      suppliers: [],
      materials: [],
      purchase: this.props.order,
    };
  }

  initMaterial = materials => {
    return materials.map(material => {
      if (!material.properties) {
        return material;
      }
      return {
        _id: material._id,
        content: material.properties[0].content,
        size: material.properties[0].size,
        color: material.properties[0].color,
        XMIL: new Date(),
        ETD: new Date(),
        ETA: new Date(),
        inStock: 0,
        needToBuy: 0,
        price: material.properties[0].price,
        amount: 0,
      };
    });
  };

  materialGrouping = materials => {
    let result = [];
    materials.forEach(material => {
      let find = result.find(e => e._id === material.material._id);
      if (find) {
        find.properties.push({
          size: material.size,
          color: material.color,
          price: material.price,
          content: material.content,
        });
      } else {
        result.push({
          _id: material.material._id,
          name: material.material.name,
          content: material.material.content,
          properties: [{ size: material.size, color: material.color, price: material.price, content: material.content }],
        });
      }
    });
    return result;
  };

  componentWillMount = () => {
    const { order } = this.props;
    this.props.supplierPropsActions.getListSupplier({ populate: 'materials.material', query: JSON.stringify({ _id: order.supplierId }) });
  };

  componentWillReceiveProps = newProps => {
    const { stylePurchasePlan, suppliers } = this.state;
    // !_.isEqual(newProps.purchasePlanState.planByStyle, stylePurchasePlan) && this.setState({stylePurchasePlan: newProps.purchasePlanState.planByStyle})
    // !_.isEqual(newProps.orderState.detail, orderDetail) && this.setState({orderDetail: _.cloneDeep(newProps.orderState.detail)})
    !_.isEqual(suppliers, newProps.supplierState.items) &&
      this.setState({
        suppliers: _.cloneDeep(newProps.supplierState.items) || [],
        selectedSupplier: _.cloneDeep(this.props.order.supplier),
        materials: this.materialGrouping(newProps.supplierState.items[0].materials),
      });
  };

  // styleChange = (value) => {
  //   if (value !== this.state.currentStyle && value !== '-') {
  //     this.props.purchasePlanPropsActions.getPurchasePlan(value, {type: 'ADDITIONAL', populate: 'materials.material'})
  //   }
  //   this.setState({currentStyle: value, stylePurchasePlan: {}})
  // }

  changeMaterial = selectedMaterials => {
    if (selectedMaterials) {
      const { purchase } = this.state;
      const filteredMaterials = purchase.materials.filter(material => selectedMaterials.find(m => m._id === material._id) !== undefined);
      const newMaterials = selectedMaterials.filter(material => purchase.materials.find(m => m._id === material._id) === undefined);
      this.setState({ purchase: { ...purchase, materials: this.initMaterial([...filteredMaterials, ...newMaterials]) } });
    }
  };

  addMaterial = () => {
    let { openModal, closeModal } = this.props.modal;
    let modal = (
      <AddMaterial
        closeModal={closeModal}
        options={{ isAdd: true, data: {} }}
        materials={this.state.materials}
        selectedMaterials={this.props.order.materials}
        changeMaterial={this.changeMaterial}
      />
    );
    openModal(modal);
  };

  onSelect = (name, id, value) => {
    const { stylePurchasePlan } = this.state;
    this.setState({
      stylePurchasePlan: {
        ...stylePurchasePlan,
        materials: stylePurchasePlan.materials.map(material => {
          if (material._id !== id) {
            return material;
          }
          if (name === 'supplier') {
            const selectedSupplier = material.availableSuppliers.find(supplier => supplier.supplierId === value);
            return { ...material, supplier: value, price: selectedSupplier ? selectedSupplier.price : 0 };
          }
          if (name === 'needToBuy') {
            return { ...material, needToBuy: value, amount: value * material.price };
          }
        }),
      },
    });
  };

  onChange = (id, property, value) => {
    const { purchase } = this.state;
    if (!id) {
      return this.setState({ purchase: { ...purchase, [property]: value } });
    }
    this.setState({
      purchase: {
        ...purchase,
        materials: purchase.materials.map(material => {
          if (material._id !== id) {
            return material;
          }
          if (property === 'needToBuy') {
            return { ...material, [property]: value, amount: material.price * value };
          }
          return { ...material, [property]: value };
        }),
      },
    });
  };

  close = () => {
    this.props.closeModal();
  };

  save = () => {
    const { purchase } = this.state;
    this.props.purchasePropsActions.editPurchase(purchase._id, purchase);
  };

  // updatePlan = () => {
  //   const {stylePurchasePlan, currentStyle} = this.state
  //   this.props.purchasePlanPropsActions.updatePurchasePlan(currentStyle, stylePurchasePlan)
  // }

  render = () => {
    // let {supplier} = this.props.order
    let { purchase, errors, materials } = this.state;
    return (
      <div id="content">
        <ul className="content-header-title">
          <li>
            <p>
              {I18n.t('PagePurchase.Supplier')} <span className="color-orange medium mg-left-50 size-16">{purchase.supplierName}</span>
            </p>
          </li>
          <li className="float-right">
            <span className="cursor-pointer global-add" onClick={() => this.addMaterial()}>
              <img src={icAddIcon} /> {I18n.t('PagePurchase.AddMaterial')}
            </span>
          </li>
        </ul>
        <div className="wrap-global-table materials-table">
          <table style={{ width: '100%' }} className="global-table">
            <tbody>
              <tr>
                <th>{I18n.t('PagePurchase.Material')}</th>
                <th className="center">{I18n.t('PagePurchase.Content')}</th>
                <th className="center">{I18n.t('PagePurchase.Color')}</th>
                <th className="center">{I18n.t('PagePurchase.Size')}</th>
                <th className="center">{I18n.t('PagePurchase.XMIL')}</th>
                <th className="center">{I18n.t('PagePurchase.ETD')}</th>
                <th className="center">{I18n.t('PagePurchase.ETA')}</th>
                <th className="center">
                  {I18n.t('PagePurchase.Quantity')}
                  <br />
                  {I18n.t('PagePurchase.InStock')}
                </th>
                <th className="center">
                  {I18n.t('PagePurchase.Quantity')}
                  <br />
                  {I18n.t('PagePurchase.ToBuy')}
                </th>
                <th className="center">{I18n.t('PagePurchase.Price')}</th>
                <th className="center">
                  {I18n.t('PagePurchase.Total')}
                  <br />
                  {I18n.t('PagePurchase.Price')}
                </th>
              </tr>
              {materials.length > 0 &&
                materials.map(material => {
                  const currentMaterial = purchase.materials.find(m => m.material === material._id);
                  const sizes = _.uniq(_.map(material.properties, 'size'));
                  const colors = _.uniq(_.map(material.properties, 'color'));
                  return (
                    currentMaterial !== undefined && (
                      <tr key={material._id}>
                        <td>{material.name}</td>
                        <td className="center select input">
                          <input
                            name="content"
                            type="text"
                            className="form-control center"
                            value={currentMaterial.content}
                            onChange={evt => this.onChange(material._id, 'content', evt.target.value)}
                          />
                        </td>
                        <td className="center select">
                          <select
                            className="w-full"
                            value={currentMaterial.size}
                            onChange={evt => this.onChange(material._id, 'color', evt.target.value)}>
                            {colors.map(color => {
                              return (
                                <option key={color} value={color}>
                                  {color}
                                </option>
                              );
                            })}
                          </select>
                        </td>
                        <td className="center select">
                          <select
                            className="w-full"
                            value={currentMaterial.color}
                            onChange={evt => this.onChange(material._id, 'size', evt.target.value)}>
                            {sizes.map(size => {
                              return (
                                <option key={size} value={size}>
                                  {size}
                                </option>
                              );
                            })}
                          </select>
                        </td>
                        <td className="center select">
                          <DateTimeField
                            timeFormat={false}
                            dateFormat="MMMM DD, YYYY"
                            value={moment(currentMaterial.XMIL)}
                            onChange={date => this.onChange(material._id, 'XMIL', date.format())}
                          />
                          {errors['XMIL'] && <div className="alert-warning">{errors['XMIL']}</div>}
                        </td>
                        <td className="center select">
                          <DateTimeField
                            timeFormat={false}
                            dateFormat="MMMM DD, YYYY"
                            value={moment(currentMaterial.ETD)}
                            onChange={date => this.onChange(material._id, 'ETD', date.format())}
                          />
                          {errors['ETD'] && <div className="alert-warning">{errors['ETD']}</div>}
                        </td>
                        <td className="center select">
                          <DateTimeField
                            timeFormat={false}
                            dateFormat="MMMM DD, YYYY"
                            value={moment(currentMaterial.ETA)}
                            onChange={date => this.onChange(material._id, 'ETA', date.format())}
                          />
                          {errors['ETA'] && <div className="alert-warning">{errors['ETA']}</div>}
                        </td>
                        <td className="center">100</td>
                        <td className="center w-90">
                          <input
                            type="text"
                            value={currentMaterial.needToBuy}
                            onChange={evt => this.onChange(currentMaterial._id, 'needToBuy', evt.target.value)}
                            placeholder=""
                            className="form-control center"
                          />
                        </td>
                        <td className="center">${currentMaterial.price}</td>
                        <td className="color-number center">${currentMaterial.amount}</td>
                      </tr>
                    )
                  );
                })}

              <tr className="g-total">
                <td colSpan="11">
                  <ul>
                    <li>{I18n.t('PagePurchase.G-Total')}</li>
                    <li className="color-number">
                      $
                      {purchase.materials.reduce((current, next) => {
                        return current + (next.amount ? next.amount : 0);
                      }, 0)}
                    </li>
                  </ul>
                </td>
              </tr>
              <tr className="bg-white">
                <td className="center vertical-align-inherit">{I18n.t('PagePurchase.Remark')}</td>
                <td colSpan="10">
                  <textarea
                    rows="10"
                    value={purchase.remark}
                    onChange={evt => this.onChange(null, 'remark', evt.target.value)}
                    cols="20"
                    className="mg-bottom-50"
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <div className="text-center">
            <ul className="list-button">
              <li>
                <a className="bg-black">{I18n.t('Btn.PreviewOrder')}</a>
              </li>
              <li>
                <a className="bg-orange" onClick={() => this.save()}>
                  {I18n.t('Btn.Save')}
                </a>
              </li>
              <li>
                <a className="bg-green">{I18n.t('Btn.Send')}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    authState: state.authState,
    supplierState: state.supplierState,
    // ,
    // orderState: state.orderState
    // ,
    // purchasePlanState: state.purchasePlanState
  };
};

const mapDispatchToProps = dispatch => {
  return {
    supplierPropsActions: bindActionCreators(supplierActions, dispatch),
    purchasePropsActions: bindActionCreators(purchaseActions, dispatch),
    // purchasePlanPropsActions: bindActionCreators(purchasePlanActions, dispatch),
    orderPropsActions: bindActionCreators(orderActions, dispatch),
  };
};

ScreenPurchaseEdit.propTypes = {
  authState: PropTypes.object.isRequired,
  activeTab: PropTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps)(ScreenPurchaseEdit);
