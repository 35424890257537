import C from '../../constants/ship-out/showShipOutList';
import {combineReducers} from 'redux';

const filter = (state = null, action) => {
    const {type, payload} = action;
    switch(type) {
        case C.CHANGE_SELECTED_FILTER:
            return payload;
        default:
            return state
    }
}

const searchValue = (state = {}, action) => {
    const { type, payload } = action;
    switch (type) {
        case C.CHANGE_SEARCH_VALUE:
            return {...state, [payload.pos]: payload.value};
        default:
            return state;
    }
}

const displayList = (state = null, action ) => {
    const {type, payload} = action;
    switch(type) {
        case C.CHANGE_LIST_SHIP_OUT:
            return payload;
        default:
            return state;
    }
}

const currentPage = (state = 1, action) => {
    const { type, payload } = action;
    switch (type) {
        case C.CHANGE_PAGING_CURRENT:
            return payload;
        default:
            return state;
    }
}

const totalPages = (state = null, action) => {
    const { type, payload } = action;
    switch (type) {
        case C.CHANGE_PAGING_TOTAL:
            return payload;
        default:
            return state;
    }
}

const deleteShipment = (state = false, action) => {
    const {type, payload} = action;
    switch(type) {
        case C.CHANGE_LIST_SHIP_OUT:
            return false;
        case C.DELETE_SUCCESS:
            return true;
        default:
            return state;
    }
}

const searched = (state = false, action) => {
    const {type, payload} = action;
    switch(type) {
        case C.CHANGE_SEARCH_STATUS:
            return payload;
        default:
            return state;
    }
}

export const showShipOutList = combineReducers({
    deleteShipment,
    filter,
    searchValue,
    currentPage,
    totalPages,
    displayList,
    searched,
})