import React from 'react';
import PropTypes from 'prop-types';
import Modal from './Modal';

const ConfirmModal = props => (
  <Modal isOpen={props.isOpen}>
    <div className="modal-dialog" role="document">
      <div className="modal-content">
        <form>
          <div className="modal-header">
            <h4 className="modal-title" id="myModalLabel">{props.title}</h4>
          </div>
          <div className="modal-body">
            <div className="form-group confirmMessage">{props.confirmMessage}</div>
          </div>
          <div className="modal-footer">
            <button onClick={() => props.onCloseClick()} type="button" className="bt-black" data-dismiss="modal">
              {props.cancelLabel}
            </button>
            <button 
              onClick={() => props.onConfirmClick()} 
              type="button" 
              disabled={!props.canSave}
              className={`${!props.canSave ? 'btn btn-default': ''} bt-orange mg-l-5`} 
              data-dismiss="modal"
            >
              {!props.isSubmiting ? props.okLabel : props.submitingLabel}
            </button>
          </div>
        </form>
      </div>
    </div>
  </Modal>
);

ConfirmModal.propTypes = {
  title: PropTypes.string.isRequired,
  confirmMessage: PropTypes.string.isRequired,
  canSave: PropTypes.bool,
  isSubmiting: PropTypes.bool,

  cancelLabel: PropTypes.string,
  okLabel: PropTypes.string,
  submitingLabel: PropTypes.string,

  onCloseClick: PropTypes.func,
  onConfirmClick: PropTypes.func,
};

ConfirmModal.defaultProps = {
  canSave: true,
  isSubmiting: false,
  cancelLabel: 'Cancel',
  okLabel: 'OK',
  submitingLabel: 'Submiting...',

  onCloseClick: f => f,
  onConfirmClick: f => f,
};

export default ConfirmModal;
