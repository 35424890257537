import React, { Component } from 'react';
import {connect} from 'react-redux';
import ViewInvoiceNoView from '../../../components/accounting/PayableInvoiceList/ViewInvoiceNo';

class ViewInvoiceNo extends Component {

    constructor(props) {
        super(props);
        this.state = {
         
        }
    }

    componentWillMount = () => {
       
    }
    
    render() {
        return (
            <ViewInvoiceNoView/>
        );
    }
}

const mapStateToProps = state => ({

})

const mapDispatchToProps = (dispatch, ownProps) => ({

})

export default connect(mapStateToProps, mapDispatchToProps) (ViewInvoiceNo);