import {DAMAGED_MATERIAL} from '../constants/actionTypes';
import initial from './initialState';

export default function (state = initial.damagedMaterialState, action) {
  switch (action.type) {
    case DAMAGED_MATERIAL.GET_LIST_SUCCESS:
      return {...state, items: action.data.items || [], count: action.data.count || 0};
    default:
      return state;
  }
}
