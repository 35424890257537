import {createSelector} from 'reselect';
import _ from 'lodash';
import formatCurrency from "format-currency";

/**
 * Get detail of invoices
 */
export const getInvoiceDetail = ({previewReceivableInvoice}) => previewReceivableInvoice.invoiceDetail;

export const getCustomer = ({previewReceivableInvoice}) => {
    return _.get(previewReceivableInvoice, 'invoiceDetail.customer', '')
};

export const getAmountCredit = ({previewReceivableInvoice}) => {
    return 0;
};

export const getAmount = ({previewReceivableInvoice}) => {
    return "$" + formatCurrency(_.get(previewReceivableInvoice, 'invoiceDetail.amount', 0));
};

export const getConvertType = ({previewReceivableInvoice}) => {
    return _.get(previewReceivableInvoice, 'invoiceDetail.convertType', '')
};

export const getConvertRate = ({previewReceivableInvoice}) => {
    return _.get(previewReceivableInvoice, 'invoiceDetail.convertRate', '')
};

export const getCustomerPOs = ({previewReceivableInvoice}) => {
    let customerPOs = _.get(previewReceivableInvoice, 'invoiceDetail.customerPOs', []);
    return _.map(customerPOs, (customerPO) => {
        customerPO.items = _.chain(customerPO.items).groupBy("style").map(function(v, i) {
            return {
              style: i,
              items: v
            }
        }).value();
        return customerPO;
    })
};