import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';


import AccountsReceivablePlanView from '../../components/accounting/AccountsReceivablePlan';
class AccountsReceivablePlan extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentWillMount() {

    }

    componentWillReceiveProps(nextProps) {

    }

    handleChangeView(value) {
        // 
        // if (value == "view-supplier") {
        //     this.props.history.push(PATH.ACCOUNTING_PAYABLE_PLAN_SUPPLIER_URL);
        // }
        // else if (value == "view-contractor") {
        //     this.props.history.push(PATH.ACCOUNTING_PAYABLE_PLAN_CONTRACTOR_URL);
        // }
        // else {
        //     this.props.history.push(PATH.ACCOUNTING_PAYABLE_PLAN_IMPORTEXPORT_URL);
        // }
    }
    render() {
        return (
            <AccountsReceivablePlanView/>
        )
    }
}

AccountsReceivablePlan.propTypes = {

};

AccountsReceivablePlan.defaultProps = {

};

const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(AccountsReceivablePlan);
