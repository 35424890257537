
import * as R from 'ramda';
import _ from 'lodash';
import numeral from 'numeral';
import moment from 'moment';
import uuid from 'uuid/v4';
import { createSelector } from "reselect";
import formatCurrency from "format-currency";

export const getCustomerPO = ({ purchase }) => {
  const { showPurchaseDetail } = purchase;
  if (showPurchaseDetail.purchaseOrder && showPurchaseDetail.purchaseOrder.customerPO) {
    return _.get(showPurchaseDetail, 'purchaseOrder.customerPO', '');
  }
  return R.path(['purchaseOrder', 'style', 'order', 'customerPO'], showPurchaseDetail);
}

export const getStyle = ({ purchase }) => {
  const { showPurchaseDetail } = purchase;
  return R.path(['purchaseOrder', 'style', 'style'], showPurchaseDetail);
}

export const getPurchaseNo = ({ purchase }) => {
  const { showPurchaseDetail } = purchase;
  return R.path(['purchaseOrder', 'purchaseNo'], showPurchaseDetail);
}

export const getSupplier = ({ purchase }) => {
  const { showPurchaseDetail } = purchase;
  return R.path(['purchaseOrder', 'supplier', 'name'], showPurchaseDetail);
}

export const getSupplierData = ({ purchase }) => {
  const { showPurchaseDetail } = purchase;
  return R.path(['purchaseOrder', 'supplier'], showPurchaseDetail);
}

export const getCreator = ({ purchase }) => {
  const { showPurchaseDetail } = purchase;
  return R.path(['purchaseOrder', 'createdBy', 'fullName'], showPurchaseDetail);
}

export const getSender = ({ purchase }) => {
  const { showPurchaseDetail } = purchase;
  return R.path(['purchaseOrder', 'sender', 'fullName'], showPurchaseDetail);
}
export const getSenderDetail = ({ purchase }) => {
  const { showPurchaseDetail } = purchase;
  return R.path(['purchaseOrder', 'sender'], showPurchaseDetail);
}

const getCurrencySymbol = ({ purchase }) => {
  const { showPurchaseDetail } = purchase;
  const currency = R.pathOr('USD', ['purchaseOrder', 'supplier', 'currency'], showPurchaseDetail).toUpperCase();
  return currency === 'VND' ? 'VND' : '$';
}

export const getGTotal = createSelector(
  [
    ({ purchase }) => purchase,
    getCurrencySymbol
  ],
  (purchase, symbol) => {
    const { showPurchaseDetail } = purchase;
    // const materials = _.filter(R.pathOr([], ['purchaseOrder', 'materials'], showPurchaseDetail), m => m.payer !== 'Customer' && m.payer !== 'Subcontractor');
    const materials = R.pathOr([], ['purchaseOrder', 'materials'], showPurchaseDetail);
    const total = _.sum(_.map(materials, m => parseFloat(m.price) * parseFloat(m.needToBuy) + parseFloat(m.surcharge || 0) - parseFloat(m.discount || 0)));
    return `${parseFloat(total).toFixed(2)} ${symbol}`;
  }
);

export const getRemark = ({ purchase }) => {
  const { showPurchaseDetail } = purchase;
  return R.path(['purchaseOrder', 'remark'], showPurchaseDetail);
}

export const getOrder = ({ purchase }) => {
  const { showPurchaseDetail } = purchase;
  return _.get(showPurchaseDetail, 'purchaseOrder.style.order', {});
}

export const getCreatedAt = ({ purchase }) => {
    const { showPurchaseDetail } = purchase;
    return R.path(['purchaseOrder', 'createdAt'], showPurchaseDetail);
}


export const getItems = createSelector(
  [
    ({ purchase }) => purchase,
    getCurrencySymbol
  ],
  (purchase, symbol) => {
    const { showPurchaseDetail } = purchase;
    const materials = R.pathOr([], ['purchaseOrder', 'materials'], showPurchaseDetail);
    const dateFormat = 'MMM DD, YYYY';
    const results = _.map(materials, m => {
      const unit = R.path(['material', 'data', 'unit'], m);
      const quantity = parseFloat(R.pathOr(0, ['needToBuy'], m));
      const isConvert = R.pathOr(false, ['material', 'isConvert'], m);
      const convertUnit = isConvert ? R.pathOr(unit, ['material', 'convertUnit'], m) : unit;
      const convertTo = parseFloat(eval(R.pathOr('1', ['material', 'convertTo'], m)));
      const convertQty = isConvert ? _.round(quantity * convertTo, 2) : quantity;
      const price = R.pathOr(0, ['price'], m);
      const surcharge = parseFloat(R.pathOr(0, ['surcharge'], m));
      const discount = parseFloat(R.pathOr(0, ['discount'], m));
      const totalPrice = quantity * price + surcharge - discount;
      const payer = R.path(['payer'], m);
      // const hasToPay = !_.find(['Customer', 'Subcontractor'], s => s === payer);

      return {
        key: uuid(),
        material: R.path(['material', 'name'], m),
        content: R.path(['material', 'data', 'content'], m),
        color: R.path(['color'], m),
        size: R.path(['size'], m),
        replacementPO: R.path(['replacementPO'], m),
        unit: unit,
        XMIL: moment(R.path(['XMIL'], m)).format(dateFormat),
        ETD: moment(R.path(['ETD'], m)).format(dateFormat),
        ETA: moment(R.path(['ETA'], m)).format(dateFormat),
        exReceivedDate: moment(R.path(['exReceivedDate'], m)).format(dateFormat),
        payer,
        quantity: `${numeral(quantity).format('0,0').replace('+','')} ${unit}`,
        convertQuantity: isConvert ? `${numeral(convertQty).format('0,0').replace('+','')} ${convertUnit}` : '-',
        price: `${formatCurrency(_.round(price || 0, 2))} ${symbol}`,
        // amount: hasToPay ? `${numeral(totalPrice).format('0,0').replace('+','')} ${symbol}` : '-',
        amount: `${formatCurrency(_.round(totalPrice || 0, 2))} ${symbol}`,
        raw: m,
      }
    })
    return results;
  }
);
