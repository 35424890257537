import { PAYINFO } from '../constants/actionTypes';
import initial from './initialState';

export default function (state = initial.acounting.payInfo, action) {
    switch (action.type) {
        // case STANDARD.RELOAD:
        //     return {...state, reload: !state.reload};
        case PAYINFO.CREATE_CREDIT_DEBIT:
            // alert('here at reducer: ' + JSON.stringify(action.data))
            return {
                ...state, 
                detail: action.data
            }
        case PAYINFO.GET_PAYINFO_SUCCESS:
            return {
                ...state,
                items: action.data.items,
                count: action.data.count
            }
        default:
            return state;
    }
}
