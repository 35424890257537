import {PRODUCTION} from '../constants/actionTypes';
import initial from './initialState';

export default function (state = initial.productionProgressState, action) {
  switch (action.type) {
    case PRODUCTION.GET_PROGRESS_SUCCESS:
      return {...state, items: action.data || [], count: action.data.count || 0};
    default:
      return state;
  }
}
