import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import BaseComponent from '../../base/BaseComponent';
import DamagedGarmentEdit from '../modal/DamagedGarmentEdit';
import damagedGarmentServices from '../../../services/damagedGarmentServices';
import SearchBar from '../../common/layout/SearchBar';
import TableList from '../../common/datatables/TableList';
import {garmentStatus} from '../../../constants/EnumTypes';
import {toastr} from 'react-redux-toastr'
import _ from 'lodash';
import I18n from 'i18n-js';
import * as types from '../../../constants/actionTypes';
import { SEARCH_KEY, SEARCH_LIST, SEARCH_BAR_EVENT } from "../../../constants/constants";
import CustomPagination from '../../common/datatables/CustomPagination';

class TabGarment extends BaseComponent {
  constructor(props, context) {
    super(props, context);

    this.state = {
      pageSize: 10,
      pageNumber: 1,
      totalPage: 0,
      columns: [
        { name: I18n.t('PageWareHouse.Date'), value: 'date', type: 'date', formatType: 'MMM DD, YYYY' },
        { name: I18n.t('PageWareHouse.CustomerPO'), value: 'customerPO' },
        { name: I18n.t('PageWareHouse.Style'), value: 'style' },
        { name: I18n.t('PageWareHouse.CutTicket'), value: 'cutTicket' },
        { name: I18n.t('PageWareHouse.Color'), value: 'color' },
        { name: I18n.t('PageWareHouse.Quantity'), value: 'quantity' },
        { name: I18n.t('PageWareHouse.Contractor'), value: 'contractor' },
        { name: I18n.t('PageWareHouse.Type'), value: 'type' },
        { name: I18n.t('PageWareHouse.Note'), value: 'note' }
      ],
      actions: [
        (item) => {
          const isView = (item.sizes || []).every(s => s.status === garmentStatus[1]);
          if (isView) {
            return { type: 'view', click: (item) => this.viewItem(item, isView) };
          }
          return { type: 'edit', click: (item) => this.viewItem(item) };
        }
      ],
      items: [],
      search: {key: SEARCH_KEY.CUSTOMER_PO, value: ''}
    };
  }

  getBaseOption = () => {
    const {pageSize, pageNumber} = this.state;
    let options = {
      pageSize,
      pageNumber
    };
    return options
  }

  componentWillMount = () => {
    this.getListItem();
  }

  save = item => {
    const { items } = this.state
    const idx = _.findIndex(items, i => i._id == item._id)
    if(idx >= 0) {
      for (const size of items[idx].sizes) {
        for (const s of item.sizes) {
          if(s.statusN == garmentStatus[1] && s._id == size._id) {
            size.status = s.statusN
          }
        }
      }
      this.props.dispatch({type: types.APP.SHOW_LOADING})
      damagedGarmentServices.editItem(((items[idx].colorId || {})._id || items[idx].colorId ), items[idx]).then(rs => {
        this.props.dispatch({type: types.APP.HIDE_LOADING})
        if(rs.success) {
          this.setState({items})
          this.props.modal.closeModal()
        } else toastr.error(I18n.t('Notify.Error'), rs.message)
      }, err => {
        this.props.dispatch({type: types.APP.HIDE_LOADING})
        toastr.error(I18n.t('Notify.Error'), err.message)
      })
    }
    
  }

  viewItem = (item, isView = false) => {
    let {openModal, closeModal} = this.props.modal;
    let modal = (<DamagedGarmentEdit {...{item, closeModal, save: this.save, isView}} />);
    openModal(modal);
  }

  getListItem = (options = {pageNumber: 1}) => {
    const {pageSize} = this.state;
    options = {
      ...options,
      pageSize
    }
    this.props.dispatch({type: types.APP.SHOW_LOADING})
    damagedGarmentServices.getListItem(options).then(rs => {
      this.props.dispatch({type: types.APP.HIDE_LOADING})
      if(rs.success) {
        this.setState({
          items: _.get(rs, 'data.items', []),
          totalPage: _.get(rs, 'meta.pages', 0)
        })
      } else toastr.error(I18n.t('Notify.Error'), rs.message)
    }, err => {
      this.props.dispatch({type: types.APP.HIDE_LOADING})
      toastr.error(I18n.t('Notify.Error'), err.message)
    })
  }

  onSearch = () => {
    const {search} = this.state;
    this.search(search, {});
  }

  setPage = (page) => {
    if(page !== this.state.pageNumber) {
      this.setState({pageNumber: page});
      const {search} = this.state;
      this.search(search, {pageNumber: page});
    }
  }


  onChangeSearch = (property, value) => {
    switch(property) {
      case SEARCH_BAR_EVENT.FILTER: {
        this.setState({search: {key: value, value: ""}});
        break;
      }
      case SEARCH_BAR_EVENT.SEARCH: {
        this.setState({search: {...this.state.search, value: value}});
        break;
      }
    }
  }

  search = (search, options) => {
    if(search && search.key && search.value) {
        let qr = {}
        !!options.query && (options.query = {})
        
        switch(search.key) {
            case SEARCH_KEY.CUSTOMER_PO: {
                qr = {customerPO: {$regex: '.*' + search.value + '.*', $options: 'i'}}
                options.query = {...options.query, ...qr}
                break;
            }
            case SEARCH_KEY.STYLE: {
                qr = {style: {$regex: '.*' + search.value + '.*', $options: 'i'}}
                options.query = {...options.query, ...qr}
                break;
            }
            case SEARCH_KEY.CUT_TICKET: {
                qr = {cutTicket: {$regex: '.*' + search.value + '.*', $options: 'i'}}
                options.query = {...options.query, ...qr}
                break;
            }
            case SEARCH_KEY.CONTRACTOR: {
                qr = {contractor: {$regex: '.*' + search.value + '.*', $options: 'i'}}
                options.query = {...options.query, ...qr}
                break;
            }
            case SEARCH_KEY.TYPE: {
              qr = {type: {$regex: '.*' + search.value + '.*', $options: 'i'}}
              options.query = {...options.query, ...qr}
              break;
            }
        }

    }
    this.getListItem(options)
  }


  render = () => {
    const { items, columns, actions, totalPage, pageNumber } = this.state

    return (
      <div role="tabpanel" className="tab-pane" id="damaged_garments">
        <SearchBar name="Search..." fields={SEARCH_LIST.WAREHOUSE_DAMAGED_GARMENTS} onChangeSearch={this.onChangeSearch} onSearch={this.onSearch}/>
        <TableList {...this.props} items={items} columns={columns || []} actions={actions || []}/>
        <CustomPagination items={totalPage} activePage={pageNumber} onSelect={this.setPage} />
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    authState: state.authState
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch    
  };
}

TabGarment.propTypes = {
  authState: PropTypes.object.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(TabGarment);
