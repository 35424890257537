import C from '../../../constants/accounting/payable/createInvoiceHistory';
import accountingServices from "../../../services/accountingServices";

export const getListInvoiceHistory = (params) => dispatch => {
    accountingServices.getListInvoiceHistory(params).then(res => {
        dispatch({type: C.CHANGE_LIST_HISTORY, payload: res.data});
        dispatch({type: C.CHANGE_PAGING_TOTAL, payload: res.meta.pages})
        dispatch({type: C.CHANGE_PAGING_CURRENT, payload: Number(res.meta.page)})
    }, (error) => {
        console.log(JSON.stringify(error));
    })
}

export const getAmountInvoiceHistory = (params) => dispatch => {
    accountingServices.getAmountInvoiceHistory(params).then(res => {
        dispatch({type: C.CHANGE_TOTAL_AMOUNT, payload: res.data.amount});
    }, (error) => {
        console.log(JSON.stringify(error));
    })
}

export const getPaidDetailInvoiceHistory = (params) => dispatch => {
    accountingServices.getPaidDetailInvoiceHistory(params).then(res => {
        dispatch({type: C.CHANGE_PAID_DETAIL, payload: res.data.items});
    }, (error) => {
        console.log(JSON.stringify(error));
    })
}

export const changeLoading = (value) => dispatch => {
    dispatch({type: C.CHANGE_LOADING, payload: value})
}

export const changeCurrency = (value) => dispatch => {
    dispatch({type: C.CHANGE_CURRENCY, payload: value})
    dispatch(changeLoading(true))
}

export const changePage = (value) => dispatch => {
    dispatch({type: C.CHANGE_PAGING_CURRENT, payload: value})
}

export const changeListFilter = value => dispatch => {
    dispatch({ type: C.CHANGE_LIST_FILTER, payload: value });
};

export const changeSelectedFilter = (value) => dispatch => {
    if(value) {
        dispatch({type: C.CHANGE_SELECT_FILTER, payload: value})
    }
}

export const changeSearchValue = (data, pos) => dispatch => {
    dispatch({type: C.CHANGE_SEARCH_VALUE, payload: {data, pos}})
}