import {combineReducers} from 'redux';
import C from "../../../constants/accounting/payable/createInvoiceHistory";

/**
 * Store helper information to check whether invoice list is fetching...
 */
const fetchingInvoiceList = (state = false, action) => {
    const {payload, type} = action;
    switch (type) {
        // TODO. handle related actions
        default:
            return state;
    }
}

/**
 * Store history invoices
 */
const historyInvoices = (state = [], action) => {
    const {payload, type} = action;
    switch (type) {
        case C.CHANGE_LIST_HISTORY:
            return payload;
        default:
            return state;
    }
}

/**
 * Store total amount information
 * Format: $ ###,###
 */
const totalAmount = (state = null, action) => {
    const {payload, type} = action;
    switch (type) {
        case C.CHANGE_TOTAL_AMOUNT:
            return payload;
        default:
            return state;
    }
}

/**
 * Store selectable filters list
 */
const selectableFilters = (state = [], action) => {
    const {payload, type} = action;
    switch (type) {
        case C.CHANGE_LIST_FILTER:
            return payload;
        default:
            return state;
    }
}

/**
 * Store selected filter
 */
const selectedFilter = (state = null, action) => {
    const {payload, type} = action;
    switch (type) {
        case C.CHANGE_SELECT_FILTER:
            return payload;
        default:
            return state;
    }
}

/**
 * Store search text information
 */
const searchValue = (state = {}, action) => {
    const {type, payload} = action;
    switch (type) {
        case C.CHANGE_SEARCH_VALUE:
            let dic = _.cloneDeep(state);
            dic[payload.pos] = payload.data;
            return dic;
        default:
            return state;
    }
}

/**
 * Store total pages information
 */
const totalPages = (state = null, action) => {
    const {payload, type} = action;
    switch (type) {
        case C.CHANGE_PAGING_TOTAL:
            return payload;
        default:
            return state;
    }
}

/**
 * Store current page information
 */
const currentPage = (state = null, action) => {
    const {payload, type} = action;
    switch (type) {
        case C.CHANGE_PAGING_CURRENT:
            return payload;
        default:
            return state;
    }
}

/**
 * Store input information for from date field
 */
const fromDate = (state = null, action) => {
    const {payload, type} = action;
    switch (type) {
        case C.CHANGE_FROM_DATE:
            return payload;
        default:
            return state;
    }
}

/**
 * Store input information for to date field
 */
const toDate = (state = null, action) => {
    const {payload, type} = action;
    switch (type) {
        case C.CHANGE_TO_DATE:
            return payload;
        default:
            return state;
    }
}

const changeCurrency = (state = 'USD', action) => {
    const {payload, type} = action;
    switch (type) {
        case C.CHANGE_CURRENCY:
            return payload;
        default:
            return state;
    }
}

/**
 * Store a list of payable invoices
 */
const paidDetail = (state = [], action) => {
    const {payload, type} = action;
    switch (type) {
        case C.CHANGE_PAID_DETAIL:
            return payload;
        default:
            return state;
    }
}

const checkedInvoice = (state = [], action) => {
    const {type, payload} = action;
    switch (type) {
        case C.ADD_CHECKED_INV_LIST:
            return [...state, payload];
        case C.REMOVE_CHECKED_INV_LIST:
            let temp = state.filter(it => it._id !== payload._id);
            return temp;
        case C.CLEAR_ALL_CHECKED_INV_LIST:
            return [];
        default:
            return state;
    }
}

const loading = (state = false, action) => {
    const {type, payload} = action;
    switch(type) {
        case C.CHANGE_LOADING:
            return payload;
        default:
            return state;
    }
}

const pageTotal = (state = null, action) => {
    const {type, payload} = action;
    switch(type) {
        case C.CHANGE_PAGING_TOTAL:
            return payload;
        default:
            return state;
    }
}

const pageCurrent = (state = null, action) => {
    const {type, payload} = action;
    switch(type) {
        case C.CHANGE_PAGING_CURRENT:
            return payload;
        default:
            return state;
    }
}

export const showPayableHistoryInvoiceList = combineReducers({
    fetchingInvoiceList,
    historyInvoices,
    totalAmount,
    changeCurrency,
    selectableFilters,
    selectedFilter,
    searchValue,
    fromDate,
    toDate,
    totalPages,
    currentPage,
    paidDetail,
    checkedInvoice,
    loading,
    pageTotal,
    pageCurrent
});
