import { createSelector } from 'reselect';
import _ from 'lodash';
import * as R from 'ramda';
import formatCurrency from 'format-currency';
import { genVietNamDong } from '../../../functions/helpers';
import { genDollarToText } from '../../../functions/helpers';

/**
 * Get selectable suppliers from reducer
 */
export const getSelectableSuppliers = ({ createPayableInvoiceSupplier }) => {
  const { selectableSuppliers } = createPayableInvoiceSupplier;
  return _.map(selectableSuppliers, s => ({
    value: s._id,
    label: s.name,
    raw: s,
  }));
};

export const getSave = ({ createPayableInvoiceSupplier }) => {
  return createPayableInvoiceSupplier.saving;
};

/**
 * Get selected supplier from reducer
 */
export const getSelectedSupplier = ({ createPayableInvoiceSupplier }) => {
  const { selectedSupplier } = createPayableInvoiceSupplier;
  return {
    value: R.pathOr('', ['_id'], selectedSupplier),
    deliveryDeviation: R.pathOr(1, ['deliveryDeviation'], selectedSupplier),
    raw: selectedSupplier,
  };
};

export const getCurrency = ({ createPayableInvoiceSupplier }) =>
  (createPayableInvoiceSupplier.selectedSupplier || {}).currency;

const genNumberWithCurrency = (currency, amount) => {
  if (currency == 'VND') {
    return formatCurrency(amount) + ' VND';
  }
  if (currency == 'USD') {
    return '$' + formatCurrency(amount);
  }
  return formatCurrency(amount);
};

/**
 * Get selected type supplier from reducer
 */
export const getSelectedTypeSupplier = ({ createPayableInvoiceSupplier }) => {
  return createPayableInvoiceSupplier.selectedTypeSupplier;
};

/**
 * Get invoiceNo field value from reducer
 */
export const getInvoiceNo = ({ createPayableInvoiceSupplier }) => {
  return createPayableInvoiceSupplier.invoiceNo;
};

/**
 * Get issuedDate field value from reducer
 */
export const getIssuedDate = ({ createPayableInvoiceSupplier }) => {
  return createPayableInvoiceSupplier.issuedDate;
};

/**
 * Get dueDate field value from reducer
 */
export const getDueDate = ({ createPayableInvoiceSupplier }) => {
  return createPayableInvoiceSupplier.dueDate;
};

/**
 * Get selectable credit debit notes from reducer
 */
export const getSelectableCreditDebits = ({ createPayableInvoiceSupplier }) => {
  const { selectableCreditDebits } = createPayableInvoiceSupplier;
  return _.map(selectableCreditDebits, item => ({
    label: R.pathOr('', ['code'], item),
    value: R.pathOr('', ['_id'], item),
    raw: item,
  }));
};

/**
 * Get selected credit debit note from reducer
 */
export const getSelectedCreditDebit = ({ createPayableInvoiceSupplier }) => {
  // debugger
  const { selectedCreditDebit } = createPayableInvoiceSupplier;
  return {
    value: R.pathOr('', ['_id'], selectedCreditDebit),
    raw: selectedCreditDebit,
  };
};

/**
 * Get selected credit debit amount from reducer
 */
export const getSelectedCreditDebitAmount = ({ createPayableInvoiceSupplier }) => {
  // debugger
  const { selectedCreditDebit } = createPayableInvoiceSupplier;
  return {
    value: genNumberWithCurrency(
      getCurrency({ createPayableInvoiceSupplier }),
      R.pathOr('', ['amount'], selectedCreditDebit)
    ),
  };
};

/**
 * Get is VAT per month value from reducer
 */
export const isVATPerMonthChecked = ({ createPayableInvoiceSupplier }) => {
  return createPayableInvoiceSupplier.isVATPerMonth;
};

export const getCheckedPurchaseOrders = ({ createPayableInvoiceSupplier }) => {
  return createPayableInvoiceSupplier.checkedPurchaseOrders;
};

/**
 * Get factor input value from reducer
 */
export const getFactor = ({ createPayableInvoiceSupplier }) => {
  return createPayableInvoiceSupplier.factor;
};

/**
 * Get payment term from raw data in reducer
 */
export const getPaymentTerm = ({ createPayableInvoiceSupplier }) => {
  if (createPayableInvoiceSupplier.selectedSupplier) {
    const { selectedSupplier } = createPayableInvoiceSupplier;
    switch (selectedSupplier.paymentTerm) {
      case 'T/T In Advance':
        return '0 day';
      case 'NET10':
        return '10 days';
      case 'NET30':
        return '30 days';
      case 'NET45':
        return '45 days';
      case 'NET60':
        return '60 days';
      default:
        return '0 day';
    }
  }
  return '0 day';
};

export const getPaymentTermNumber = ({ createPayableInvoiceSupplier }) => {
  if (createPayableInvoiceSupplier.selectedSupplier) {
    const { selectedSupplier } = createPayableInvoiceSupplier;
    switch (selectedSupplier.paymentTerm) {
      case 'T/T In Advance':
        return 0;
      case 'NET30':
        return 30;
      case 'NET10':
        return 10;
      case 'NET45':
        return 45;
      case 'NET60':
        return 60;
      default:
        return 0;
    }
  }
  return 0;
};

/**
 * Get Invoice amount from reducer
 */
export const getAmount = ({ createPayableInvoiceSupplier }) => {
  let countAmount = 0;
  _.forEach(createPayableInvoiceSupplier.checkedPurchaseOrders, value => {
    //111
    let findPurchaseOrders = _.find(createPayableInvoiceSupplier.purchaseOrders, { _id: value });
    if (findPurchaseOrders) {
      let totalAmount = findPurchaseOrders.materials.reduce((amount, material) => {
        return (amount += material.amount);
      }, 0);
      countAmount += totalAmount;
    }
  });
  return Number(countAmount).toFixed(2);
};

/**
 * Get Invoice total amount from reducer
 */
export const getTotalAmount = ({ createPayableInvoiceSupplier }) => {
  let countAmount = 0;
  _.forEach(createPayableInvoiceSupplier.checkedPurchaseOrders, value => {
    //111
    let findPurchaseOrders = _.find(createPayableInvoiceSupplier.purchaseOrders, { _id: value });
    if (findPurchaseOrders) {
      let totalAmount = findPurchaseOrders.materials.reduce((amount, material) => {
        return (amount += material.amount);
      }, 0);
      countAmount += totalAmount;
    }
  });

  let card = createPayableInvoiceSupplier.selectedCreditDebit;
  if (!card) return countAmount;

  if (card.type == 'Debit') {
    countAmount = countAmount + card.amount;
  } else {
    countAmount = countAmount - card.amount;
  }
  return Number(countAmount).toFixed(2);
};

/**
 * Get selectable convert currencies list
 */
export const getSelectableConvertCurrencies = ({ createPayableInvoiceSupplier }) => {
  const { selectableConvertCurrencies } = createPayableInvoiceSupplier;
  return _.map(selectableConvertCurrencies, item => ({
    label: item,
    value: item,
    raw: item,
  }));
};

/**
 * Get selected convert currency
 */
export const getSelectedConvertCurrency = ({ createPayableInvoiceSupplier }) => {
  const { selectedConvertCurrency } = createPayableInvoiceSupplier;
  return {
    value: selectedConvertCurrency,
  };
};

/**
 * Get convert rate
 */
export const getConvertRate = ({ createPayableInvoiceSupplier }) => {
  return createPayableInvoiceSupplier.convertRate;
};

export const getMainVATNo = ({ createPayableInvoiceSupplier }) => {
  return createPayableInvoiceSupplier.mainVATNo;
};

export const getMainVATDate = ({ createPayableInvoiceSupplier }) => {
  return createPayableInvoiceSupplier.mainVATDate;
};

export const getMainTaxRate = ({ createPayableInvoiceSupplier }) => {
  return createPayableInvoiceSupplier.mainTaxRate;
};

export const getMainTotalAmount = ({ createPayableInvoiceSupplier }) => {
  return createPayableInvoiceSupplier.mainTotalAmount;
};

export const getMainVATAmount = ({ createPayableInvoiceSupplier }) => {
  let vatAmount = 0;
  createPayableInvoiceSupplier.mainTaxRate
    ? (vatAmount =
        (Number(createPayableInvoiceSupplier.mainTotalAmount) * Number(createPayableInvoiceSupplier.mainTaxRate)) / 100)
    : 0;
  const { selectedSupplier } = createPayableInvoiceSupplier;
  return `${formatCurrency(Number(vatAmount).toFixed(2))} ${selectedSupplier ? selectedSupplier.currency : ''}`;
};

export const getMainGrandTotalVAT = ({ createPayableInvoiceSupplier }) => {
  let vatAmount = 0;
  let totalVatAmount = 0;
  createPayableInvoiceSupplier.mainTaxRate
    ? (vatAmount =
        (Number(createPayableInvoiceSupplier.mainTotalAmount) * Number(createPayableInvoiceSupplier.mainTaxRate)) / 100)
    : 0;
  createPayableInvoiceSupplier.mainTaxRate
    ? (totalVatAmount = Number(createPayableInvoiceSupplier.mainTotalAmount) + vatAmount)
    : 0;
  const { selectedSupplier } = createPayableInvoiceSupplier;
  return `${formatCurrency(Number(totalVatAmount))} ${selectedSupplier ? selectedSupplier.currency : ''}`;
};

const getMainGrandTotalVATSum = ({ createPayableInvoiceSupplier }) => {
  let vatAmount = 0;
  let totalVatAmount = 0;
  createPayableInvoiceSupplier.mainTaxRate
    ? (vatAmount = (createPayableInvoiceSupplier.mainTotalAmount * createPayableInvoiceSupplier.mainTaxRate) / 100)
    : 0;
  createPayableInvoiceSupplier.mainTaxRate
    ? (totalVatAmount = createPayableInvoiceSupplier.mainTotalAmount + vatAmount)
    : 0;
  return _.round(totalVatAmount * 1, 2);
};

export const getSubVATNo = ({ createPayableInvoiceSupplier }) => {
  return createPayableInvoiceSupplier.subVATNo;
};

export const getConvertCurrency = ({ createPayableInvoiceSupplier }) => {
  const { selectedSupplier } = createPayableInvoiceSupplier;
  if (selectedSupplier) {
    let [c] = _.filter(['VND', 'USD'], it => it !== selectedSupplier.currency);
    return selectedSupplier ? c : '';
  } else {
    return '';
  }
};

export const getSubVATDate = ({ createPayableInvoiceSupplier }) => {
  return createPayableInvoiceSupplier.subVATDate;
};

export const getSubNote = ({ createPayableInvoiceSupplier }) => {
  return createPayableInvoiceSupplier.subNote;
};

export const fnGetSubListData = ({ createPayableInvoiceSupplier }) => id => {
  //1111
  let findPurchaseOrders = _.find(createPayableInvoiceSupplier.purchaseOrders, { _id: id });
  if (findPurchaseOrders) {
    findPurchaseOrders.materials.map(imaterial => {
      imaterial.amount = imaterial.actual * imaterial.price;
    });
  }
  return findPurchaseOrders;
};

/**
 * Get  amount IVN
 */

export const fnGetSubTotalAmount = createSelector(
  [({ createPayableInvoiceSupplier }) => createPayableInvoiceSupplier, fnGetSubListData],
  (createPayableInvoiceSupplier, subListData) => id => {
    let getObjData = subListData(id);
    let totalAmount = getObjData.materials.reduce((amount, material) => {
      return (amount += material.amount);
    }, 0);
    const { selectedSupplier } = createPayableInvoiceSupplier;
    return `${Number(totalAmount).toFixed(2)} ${selectedSupplier ? selectedSupplier.currency : ''}`;
  }
);

const fnGetSubTotalAmountSum = createSelector([fnGetSubListData], subListData => id => {
  let getObjData = subListData(id);
  let totalAmount = getObjData.materials.reduce((amount, material) => {
    return (amount += material.amount);
  }, 0);
  return Number(totalAmount).toFixed(2);
});

export const getSubTaxRate = createSelector(
  [({ createPayableInvoiceSupplier }) => createPayableInvoiceSupplier],
  createPayableInvoiceSupplier => id => {
    let amountNumber = 0;
    _.forEach(createPayableInvoiceSupplier.purchaseOrders, po => {
      if (po._id === id) {
        _.forEach(po.materials, value => {
          let sumTotal = 0;
          let temp =
            value.needToBuy +
            value.needToBuy * (_.get(createPayableInvoiceSupplier, 'selectedSupplier.deliveryDeviation', 1) / 100);
          let tempRound = Math.round(temp);
          if (temp > tempRound) {
            temp = tempRound + 1;
          }
          if (tempRound >= value.usable) {
            sumTotal = value.usable * value.price;
          } else {
            sumTotal = temp * value.price;
          }
          const amount =
            createPayableInvoiceSupplier.selectedTypeSupplier === 'domesticInvoice'
              ? sumTotal
              : (value.usable || 0) * (value.price || 0);
          amountNumber += amount + (value.surcharge || 0) - (value.discount || 0);
        });
      }
    });
    let subNote = createPayableInvoiceSupplier.subNote[id];
    let subTaxRate = createPayableInvoiceSupplier.subTaxRate[id];
    !subTaxRate ? (subTaxRate = 0) : null;
    let getSubTotalAmount = amountNumber;
    let getSubVATAmount = Number(((getSubTotalAmount * Number(subTaxRate)) / 100).toFixed(2));
    let getSubTotalVAT = (Number(getSubTotalAmount) + getSubVATAmount).toFixed(2);
    return {
      taxRate: subTaxRate,
      totalAmount: getSubTotalAmount,
      VATAmount: getSubVATAmount,
      grandTotalVAT: getSubTotalVAT,
      note: subNote,
    };
  }
);

export const getSelectablePurchaseOrders = createSelector(
  [({ createPayableInvoiceSupplier }) => createPayableInvoiceSupplier],
  createPayableInvoiceSupplier => {
    let arrData = [];
    const { checkedPurchaseOrders } = createPayableInvoiceSupplier;
    _.forEach(createPayableInvoiceSupplier.purchaseOrders, value => {
      if (!value.hide) {
        let obj = {};
        obj._id = value._id;
        obj.name = value.purchaseNo;
        obj.checked = checkedPurchaseOrders.findIndex(item => item == value._id) > -1 ? true : false;
        let supplier = createPayableInvoiceSupplier.selectedSupplier;
        obj.customerPO = value.customerPO;
        obj.style = value.style;
        //'T/T In Advance', 'NET30', 'NET45', 'NET60'
        let oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
        let firstDate = new Date(value.etd);
        let numberOfDate = 0;
        if (supplier.paymentTerm == 'NET30') {
          numberOfDate = 30;
        } else if (supplier.paymentTerm == 'NET45') {
          numberOfDate = 45;
        } else if (supplier.paymentTerm == 'NET60') {
          numberOfDate = 60;
        } else numberOfDate = 0;

        let countDate = (firstDate.getTime() + oneDay * numberOfDate - new Date().getTime()) / oneDay;

        if (countDate <= 3) {
          obj.warning = true;
        } else {
          obj.warning = false;
        }

        arrData.push(obj);
      }
    });
    return arrData;
  }
);

export const getEditablePurchaseOrders = createSelector(
  [({ createPayableInvoiceSupplier }) => createPayableInvoiceSupplier, getSubTaxRate],
  (createPayableInvoiceSupplier, setSubTaxRate) => {
    let arrData = [];
    const { purchaseOrders } = createPayableInvoiceSupplier;
    const { activePurchaseOrders } = createPayableInvoiceSupplier;
    const { selectedSupplier } = createPayableInvoiceSupplier;
    const { checkedPurchaseOrders } = createPayableInvoiceSupplier;
    const deliveryDeviation = (selectedSupplier ? selectedSupplier.deliveryDeviation : 1) || 0;
    const currency = selectedSupplier ? selectedSupplier.currency : '';
    _.forEach(checkedPurchaseOrders, value => {
      //111
      let order = _.find(purchaseOrders, { _id: value });
      let obj = {};
      obj._id = order._id;
      obj.order = order.order;
      let subTotalVAT = setSubTaxRate(obj._id);
      obj.active = !!_.find(activePurchaseOrders, it => it === order._id);
      obj.name = order.purchaseNo;
      obj.purchaseId = order.purchaseId;
      obj.customerPO = order.customerPO;
      obj.style = order.style;
      // Order Quantity => quantity , Received Quantity => usable
      obj.iHDate = order.date;
      obj.customer = null;
      (obj.hasVAT = createPayableInvoiceSupplier.isVATPerMonth ? false : true),
        (obj.vatNo = createPayableInvoiceSupplier.subVATNo[obj._id]);
      obj.vatDate = !createPayableInvoiceSupplier.subVATDate[obj._id]
        ? new Date()
        : createPayableInvoiceSupplier.subVATDate[obj._id];
      obj.taxRate = subTotalVAT.taxRate;
      obj.note = createPayableInvoiceSupplier.subNote[obj._id];
      obj.totalAmount = `${formatCurrency(Number(subTotalVAT.totalAmount).toFixed(2))} ${
        selectedSupplier ? selectedSupplier.currency : ''
      }`;
      obj.vatAmount = `${formatCurrency(Number(subTotalVAT.VATAmount).toFixed(2))} ${
        selectedSupplier ? selectedSupplier.currency : ''
      }`;
      obj.grandTotalVAT = `${formatCurrency(Number(subTotalVAT.grandTotalVAT).toFixed(2))} ${
        selectedSupplier ? selectedSupplier.currency : ''
      }`;
      obj.items = [];
      obj.currency = selectedSupplier ? selectedSupplier.currency : '';
      _.forEach(order.materials, value => {
        let iOfItems = {};
        iOfItems._id = value._id;
        iOfItems.color = value.color;
        iOfItems.size = value.size;
        iOfItems.customerPO = order.customerPO;
        iOfItems.style = order.style;
        iOfItems.size = value.size;
        iOfItems.material = value.material;
        iOfItems.color = value.color;
        iOfItems.payer = value.payer;
        iOfItems.eta = value.ETA;
        iOfItems.invNo = value.invNo;
        iOfItems.orderQuantity = value.needToBuy;
        iOfItems.receivedQuantity = value.usable;
        iOfItems.receivedDate = value.receivedDate;
        iOfItems.surcharge = value.surcharge;
        iOfItems.discount = value.discount;
        iOfItems.surchargeText = genNumberWithCurrency(currency, value.surcharge);
        iOfItems.discountText = genNumberWithCurrency(currency, value.discount);
        iOfItems.unit = value.data ? value.data.unit : null;
        iOfItems.price = genNumberWithCurrency(currency, value.price || 0);
        iOfItems.priceNumber = value.price || 0;
        let sumTotal = 0;
        let temp = value.needToBuy + value.needToBuy * (deliveryDeviation / 100);
        let tempRound = Math.round(temp);
        if (temp > tempRound) {
          temp = tempRound + 1;
        }
        if (tempRound >= value.usable) {
          sumTotal = value.usable * value.price;
        } else {
          sumTotal = temp * value.price;
        }
        const amount =
          createPayableInvoiceSupplier.selectedTypeSupplier === 'domesticInvoice'
            ? sumTotal
            : (value.usable || 0) * (value.price || 0);
        iOfItems.amountNumber = amount + (value.surcharge || 0) - (value.discount || 0);
        iOfItems.amount = genNumberWithCurrency(
          currency,
          _.round(amount + (value.surcharge || 0) - (value.discount || 0), 2)
        );
        obj.items.push(iOfItems);
      });
      obj.totalPurchaseNumber = obj.items.reduce((amount, material) => {
        return (amount += material.amountNumber);
      }, 0);
      obj.totalPurchase = formatCurrency(_.round(obj.totalPurchaseNumber, 2));
      arrData.push(obj);
    });
    return arrData;
  }
);

export const getAmountINV = createSelector(
  [
    ({ createPayableInvoiceSupplier }) => createPayableInvoiceSupplier.selectedSupplier,
    getEditablePurchaseOrders,
    getCurrency,
  ],
  (selectedSupplier, purchaseOrders, currency) => {
    const sumAmount = _.sum((purchaseOrders || []).map(item => item.totalPurchaseNumber));
    return genNumberWithCurrency(currency, _.round(sumAmount, 2));
  }
);

const getAmountINVSum = createSelector([getEditablePurchaseOrders], purchaseOrders => {
  const sumAmount = _.sum(purchaseOrders.map(item => item.totalPurchaseNumber));
  return _.round(sumAmount, 2);
});

export const getTotalAmountINV = createSelector(
  [({ createPayableInvoiceSupplier }) => createPayableInvoiceSupplier, getSubTaxRate, getAmountINVSum, getCurrency],
  (createPayableInvoiceSupplier, setSubTaxRate, amountINV, currency) => {
    const { selectedSupplier } = createPayableInvoiceSupplier;
    let amount = 0;
    if (createPayableInvoiceSupplier.isVATPerMonth) {
      amount += amountINV;
      amount += createPayableInvoiceSupplier.mainTotalAmount * 1 * (createPayableInvoiceSupplier.mainTaxRate / 100);
    } else {
      _.forEach(createPayableInvoiceSupplier.checkedPurchaseOrders, value => {
        let subTotalVAT = setSubTaxRate(value);
        amount += subTotalVAT.VATAmount * 1 + subTotalVAT.totalAmount * 1;
      });
    }

    let card = createPayableInvoiceSupplier.selectedCreditDebit;
    if (!card) return genNumberWithCurrency(currency, Number(amount).toFixed(2));

    if (card.type == 'Debit') {
      amount = amount + card.amount;
    } else amount = amount - card.amount;
    return genNumberWithCurrency(currency, Number(amount).toFixed(2));
    // return `${formatCurrency(Number(amount).toFixed(2))} ${selectedSupplier ? selectedSupplier.currency : ''}`;
  }
);

export const getAmountConvert = createSelector(
  [({ createPayableInvoiceSupplier }) => createPayableInvoiceSupplier, getAmountINVSum, getConvertCurrency],
  (state, amountINV, currency) => {
    const { convertRate } = state;
    if (amountINV && convertRate) {
      if (currency == 'USD') {
        return genNumberWithCurrency(currency, Number(amountINV / convertRate).toFixed(2));
      } else {
        return genNumberWithCurrency(currency, Number(amountINV * convertRate).toFixed(2));
      }
    }
    return '';
  }
);

const getTotalAmountINVSum = createSelector(
  [({ createPayableInvoiceSupplier }) => createPayableInvoiceSupplier, getSubTaxRate, getAmountINVSum],
  (createPayableInvoiceSupplier, setSubTaxRate, amountINV) => {
    let amount = 0;
    if (createPayableInvoiceSupplier.isVATPerMonth) {
      amount += amountINV;
      amount += createPayableInvoiceSupplier.mainTotalAmount * 1 * (createPayableInvoiceSupplier.mainTaxRate / 100);
    } else {
      _.forEach(createPayableInvoiceSupplier.checkedPurchaseOrders, value => {
        let subTotalVAT = setSubTaxRate(value);
        amount += subTotalVAT.VATAmount * 1 + subTotalVAT.totalAmount * 1;
      });
    }

    let card = createPayableInvoiceSupplier.selectedCreditDebit;
    if (!card) return amount;

    if (card.type == 'Debit') {
      amount = amount + card.amount;
    } else amount = amount - card.amount;
    return Number(amount).toFixed(2);
  }
);

export const getTotalAmountSay = createSelector(
  [({ createPayableInvoiceSupplier }) => createPayableInvoiceSupplier, getTotalAmountINVSum],
  (createPayableInvoiceSupplier, totalAmount) => {
    const { selectedSupplier } = createPayableInvoiceSupplier;
    let say = '';
    if ((selectedSupplier || {}).currency == 'VND') {
      say = genVietNamDong(_.round(totalAmount, 2));
    }
    if ((selectedSupplier || {}).currency == 'USD') {
      say = genDollarToText(_.round(totalAmount, 2));
    }
    return say;
  }
);

export const getTotalAmountConvert = createSelector(
  [({ createPayableInvoiceSupplier }) => createPayableInvoiceSupplier, getTotalAmountINVSum, getConvertCurrency],
  (state, totalAmountINV, currency) => {
    if (totalAmountINV && state.convertRate) {
      const { convertRate } = state;
      if (currency == 'USD') {
        return genNumberWithCurrency(currency, Number(totalAmountINV / convertRate).toFixed(2));
      } else {
        return genNumberWithCurrency(currency, Number(totalAmountINV * convertRate).toFixed(2));
      }
    }
    return '';
  }
);

/**
 * Return a function that will use to generate an api request body that will be used to post to api
 * The type of the body will depend on selected supplier is domestic or oversea
 */

// export const

export const fnGenerateRequestBody = createSelector(
  [
    ({ createPayableInvoiceSupplier }) => createPayableInvoiceSupplier,
    getTotalAmountINVSum,
    getMainGrandTotalVATSum,
    getSubTaxRate,
    getInvoiceNo,
    getEditablePurchaseOrders,
    getAmountINVSum,
  ],
  (
    createPayableInvoiceSupplier,
    setTotalAmountINV,
    setMainGrandTotalVAT,
    setSubTaxRate,
    invNo,
    purchaseOrders,
    amountINV
  ) => {
    let obj = { invoiceDetail: {} };

    obj.typeInvoice = createPayableInvoiceSupplier.selectedTypeSupplier;
    obj.invNo = invNo;
    obj.issuedDate = createPayableInvoiceSupplier.issuedDate;
    obj.dueDate = createPayableInvoiceSupplier.dueDate;
    obj.currency = _.get(createPayableInvoiceSupplier, 'selectedSupplier.currency', null);

    obj.amount = amountINV;
    obj.totalAmount = setTotalAmountINV;

    obj.invoiceDetail.supplier = _.get(createPayableInvoiceSupplier, 'selectedSupplier._id');
    obj.invoiceDetail.creditDebit = (createPayableInvoiceSupplier.selectedCreditDebit || {})._id;

    obj.invoiceDetail.convertType = createPayableInvoiceSupplier.selectedConvertCurrency;
    obj.invoiceDetail.convertRate = createPayableInvoiceSupplier.convertRate;
    obj.invoiceDetail.typeInvoice = createPayableInvoiceSupplier.selectedTypeSupplier;

    if (createPayableInvoiceSupplier.selectedTypeSupplier === 'overseaInvoice') {
      obj.invoiceDetail.factor = createPayableInvoiceSupplier.factor;
      const purchaseOrdersDetail = purchaseOrders.map(item => {
        if (createPayableInvoiceSupplier.checkedPurchaseOrders.findIndex(it => it === item._id) > -1) {
          return {
            purchaseOrder: item.purchaseId,
            order: item.order,
            materials: item.items.map(i => ({ ...i, price: i.priceNumber })),
          };
        }
      });
      obj.invoiceDetail.purchaseOrders = purchaseOrdersDetail;
      return obj;
    } else {
      if (createPayableInvoiceSupplier.isVATPerMonth) {
        obj.invoiceDetail.vatPermonth = true;
        let vat = {};
        vat.vatNo = createPayableInvoiceSupplier.mainVATNo;
        vat.vatDate = createPayableInvoiceSupplier.mainVATDate;
        vat.taxRate = createPayableInvoiceSupplier.mainTaxRate;
        vat.totalAmount = Number(createPayableInvoiceSupplier.mainTotalAmount);
        obj.invoiceDetail.vat = vat;
        const purchaseOrdersDetail = _.map(createPayableInvoiceSupplier.checkedPurchaseOrders, item => {
          let purchase = _.find(createPayableInvoiceSupplier.purchaseOrders, el => el._id === item);
          return {
            purchaseOrder: purchase.purchaseId,
            order: purchase.order,
            materials: _.map(purchase.materials, elem => {
              return {
                material: _.get(elem, 'material._id'),
                orderQuantity: elem.needToBuy,
                receivedQuantity: elem.usable,
                receivedDate: elem.exReceivedDate,
                unit: _.get(elem, 'material.data.unit', ''),
                price: elem.priceNumber,
                surcharge: elem.surcharge,
                discount: elem.discount,
                amount:
                  _.get(elem, 'usable', 0) * _.get(elem, 'price', 0) +
                  _.get(elem, 'surcharge', 0) -
                  _.get(elem, 'discount', 0),
              };
            }),
          };
        });
        obj.invoiceDetail.purchaseOrders = purchaseOrdersDetail;
      } else {
        obj.invoiceDetail.vatPermonth = false;
        let arrData = [];
        _.forEach(createPayableInvoiceSupplier.checkedPurchaseOrders, value => {
          //111
          let findPurchaseOrders = _.find(createPayableInvoiceSupplier.purchaseOrders, { _id: value });
          let obj = { _id: null, vat: {} };
          if (findPurchaseOrders) {
            obj._id = findPurchaseOrders._id;
          }
          let subTotalVAT = setSubTaxRate(obj._id);
          obj.vat.vatNo = createPayableInvoiceSupplier.subVATNo[obj._id];
          obj.vat.vatDate = !createPayableInvoiceSupplier.subVATDate[obj._id]
            ? new Date()
            : createPayableInvoiceSupplier.subVATDate[obj._id];
          obj.vat.taxRate = subTotalVAT.taxRate;
          obj.vat.totalAmount = subTotalVAT.totalAmount;
          obj.vat.note = subTotalVAT.note;
          obj.purchaseOrder = findPurchaseOrders.purchaseId;
          arrData.push(obj);
        });
        obj.invoiceDetail.purchaseOrders = arrData;
      }
      return obj;
    }
  }
);

/**
 *INV no of purchase
 */

export const getInvNoOfPurchaseOrder = createSelector(
  [({ createPayableInvoiceSupplier }) => createPayableInvoiceSupplier],
  createPayableInvoiceSupplier => {
    let invNo = createPayableInvoiceSupplier.invNoOfPurchaseOrder;
    let copyPurchaseString = JSON.stringify(createPayableInvoiceSupplier.purchaseOrders);
    let copyPurchase = JSON.parse(copyPurchaseString);
    _.forEach(createPayableInvoiceSupplier.checkedPurchaseOrders, value => {
      let findPurchaseOrders = _.find(copyPurchase, { _id: value });
      if (findPurchaseOrders) {
        if (findPurchaseOrders._id == invNo.purchaseOrderId && invNo.materalId == value._id) {
          value.invNo = invNo.value;
        }
      }
    });
    createPayableInvoiceSupplier.purchaseOrders = copyPurchase;
    return createPayableInvoiceSupplier.invNoOfPurchaseOrder;
  }
);

/**
 * Get preview invoice when creating
 * The results must have these information
 * {
 *    _id, supplier, invNo,issueDate, creditDebit, VAT, amount, totalAmount,
 *    items: [{customerPO, style, poNumber, material, quantity, receivedQuantity,
 *    receivedDate, unit, price, amount}]
 * }
 */
export const getPreviewInvoice = createSelector(
  [
    ({ createPayableInvoiceSupplier }) => createPayableInvoiceSupplier,
    fnGenerateRequestBody,
    getEditablePurchaseOrders,
    getCurrency,
  ],
  (createPayableInvoiceSupplier, generateRequestBody, purchaseOrders, currency) => {
    let obj = {};
    obj._id = null;
    obj.supplier = generateRequestBody.invoiceDetail.supplier;
    obj.invNo = createPayableInvoiceSupplier.invoiceNo;
    obj.issueDate = createPayableInvoiceSupplier.issuedDate;
    obj.creditDebit = createPayableInvoiceSupplier.selectedCreditDebit;
    obj.VAT = 0;
    obj.items = [];
    if (createPayableInvoiceSupplier.isVATPerMonth) {
      obj.VAT = (createPayableInvoiceSupplier.mainTotalAmount * createPayableInvoiceSupplier.mainTaxRate) / 100;
    } else {
      _.forEach(generateRequestBody.invoiceDetail.purchaseOrders, value => {
        if (!createPayableInvoiceSupplier.isVATPerMonth && value.vat) {
          obj.VAT += ((value.vat.totalAmount || 0) * (value.vat.taxRate || 0)) / 100;
        }
      });
    }

    purchaseOrders.map(item =>
      item.items.map(it => {
        obj.items.push({
          ...it,
          purchaseNo: item.name,
          content: _.get(it, 'material.data.content', ''),
          material: (it.material || {}).name,
          unit: ((it.material || {}).data || {}).unit,
          priceText: genNumberWithCurrency(currency, it.price || 0),
          amount: genNumberWithCurrency(currency, it.amountNumber),
        });
      })
    );
    return obj;
  }
);

export const getCanPreview = ({ createPayableInvoiceSupplier }) =>
  !_.isEmpty(createPayableInvoiceSupplier.checkedPurchaseOrders);

export const getSuccess = ({ createPayableInvoiceSupplier }) => createPayableInvoiceSupplier.success;

export const getLoading = ({ createPayableInvoiceSupplier }) => createPayableInvoiceSupplier.loading;

export const getErrorMessages = ({ createPayableInvoiceSupplier }) => createPayableInvoiceSupplier.errorsMessage;

export const getActiveScreenPreviewStatus = ({ createPayableInvoiceSupplier }) =>
  createPayableInvoiceSupplier.activeScreenPreview;
