import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import BaseComponent from '../../../base/BaseComponent';
import productionActions from '../../../../actions/productionActions';
import appActions from '../../../../actions/appActions';
import TableList from '../../../common/datatables/TableList';
import _ from 'lodash';
import I18n from 'i18n-js';
import {
  ACTION_TYPE, COMPONENT_TYPE, PRODUCTION_SCREEN, PRODUCTION_TAB } from "../../../../constants/constants";
import * as utils from "../../../../functions/utils";

class TabProgress extends BaseComponent {
  constructor (props, context) {
    super(props, context);

    this.state = {
      columns: [],
      items: [],
      actions: []
    };
  }


  componentWillMount() {
    const {order, progress} = this.props;
    this.getListDataProgress(order);
    this.setData(progress||{});
  }

  componentWillReceiveProps(nextProps) {
    const {order, progress} = this.props;
    if(!_.isEqual(order, nextProps.order)) {
      this.getListDataProgress(nextProps.order);
    }

    if(!_.isEqual(progress, nextProps.progress)) {
      this.setData(nextProps.progress);
    }
  }

  setData(progress) {
    let startCols = [
      {name: I18n.t('PageProduction.TotalOrder'), value: 'totalOrder', },
      {name: I18n.t('PageProduction.Style'), value: 'style', options: {clsTh: 'center', clsTd: 'center'}},
    ];
    const endCols = [
      {name: "", value: 'percent', options: {clsTh: 'center', clsTd: 'center'}, type: COMPONENT_TYPE.PROGRESS_BAR},
      {name: I18n.t('PageProduction.TotalCompleted'), value: 'totalCompleted', options: {clsTh: 'center', clsTd: 'w-100 color-green center'}},
    ];
    const sizeColumns = _.uniqWith(_.get(progress, "items", []).reduce((arr, item) => [...arr, ...Object.keys(item.sizes)],[]), _.isEqual);
    const columns = [...startCols, ...sizeColumns.map(item => ({name: item, value: "progressOb." + item, options: {clsTh: 'center', clsTd: 'center'}})), ...endCols];
    const items = utils.setProductionProgress(_.get(progress, "items", []));
    this.setState({columns, items});
  }

  getListDataProgress(order) {
    if(order && order._id) {
      const options = {
        query: {
          ids: _.get(order, "styleOrders", []).reduce((arr, st) => [...arr, _.get(st, "style._id","")],[]),
          cIds: _.get(order, "styleOrders", []).reduce((arr, st) => [...arr, ...(_.get(st, "cutTickets",[]).reduce((cArr, c) => [...cArr, c._id],[]))],[])
        }
      };
      this.props.productionActions.getListProgress(_.get(order, "_id", ""), options);
    }
  }

  viewProgress(item) {
    const {order} = this.props;
    let pageList = [...this.props.appState.pageList];
    pageList.push({screen: PRODUCTION_SCREEN.VIEW_PROGRESS, data: {order, style: item}});
    this.props.appActions.addPage(pageList);
  }
  

  render () {
    let {total, items, columns, actions, pageNumber, pageSize} = this.state;
    const {order, roles: {showView}} = this.props;
    let totalPage = _.ceil(total / pageSize);
    actions = [];
    if(showView){
      actions.push(() => {
        return { type: ACTION_TYPE.VIEW, click: (item) => this.viewProgress(item) };
      })
    };
    return (
      <div role="tabpanel" className="tab-pane active" id={PRODUCTION_TAB.PROGRESS}>
        <TableList {...this.props} items={items ||[]} columns={columns || []} actions={order && order.startDate ? actions : []|| []}
                   options={{clsTbl: 'global-table box-shadow-none'}} widthAction="w-70"/>
      </div>
    );
  }
}

function mapStateToProps (state, ownProps) {
  return {
    authState: state.authState,
    appState: state.appState,
    progress: state.productionProgressState
  };
}

function mapDispatchToProps (dispatch) {
  return {
    productionActions: bindActionCreators(productionActions, dispatch),
    appActions: bindActionCreators(appActions, dispatch),
  };
}

TabProgress.propTypes = {
  authState: PropTypes.object.isRequired,
  appState: PropTypes.object,
  productionActions: PropTypes.object,
  appActions: PropTypes.object,
  progress: PropTypes.object,
  order: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(TabProgress);
