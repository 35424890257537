const C = "EDIT_INVOICE_CONTRACTOR";

export default {
    CHANGE_INVOICE_DETAIL: `${C}_CHANGE_INVOICE_DETAIL`,
    CHANGE_CONST_CONTRACTOR_VALUE: `${C}_CHANGE_CONST_CONTRACTOR_VALUE`,
    CHANGE_CONTRACTOR_VALUE: `${C}_CHANGE_CONTRACTOR_VALUE`,
    CHANGE_SELECTABLE_CREDIT_DEBITS: `${C}_CHANGE_LIST_CREDIT_DEBITS`,
    CHANGE_SELECTED_CREDIT_DEBIT_VALUE: `${C}_CHANGE_SELECTED_CREDIT_DEBIT_VALUE`,
    CHANGE_INVOICE_NO_VALUE: `${C}_CHANGE_INVOICE_NO_VALUE`,
    CHANGE_ISSUED_DATE_VALUE: `${C}_CHANGE_ISSUED_DATE_VALUE`,
    CHANGE_DUE_DATE_VALUE: `${C}_CHANGE_DUE_DATE_VALUE`,
    CHANGE_PAY_AMOUNT_VALUE: `${C}_CHANGE_PAY_AMOUNT_VALUE`,
    CHANGE_PAYMENT_TYPE: `${C}_CHANGE_PAYMENT_TYPE`,
    CHANGE_TOTAL_AMOUNT_VALUE: `${C}_CHANGE_TOTAL_AMOUNT_VALUE`,
    CHANGE_LIST_CUSTOMER_PO: `${C}_CHANGE_LIST_CUSTOMER_PO`,
    CHANGE_CHECKED_CUSTOMER_PO: `${C}_CHANGE_CHECKED_CUSTOMER_PO`,
    CHANGE_TOGGLE_ACTIVE_CUSTOMER_PO: `${C}_CHANGE_TOGGLE_ACTIVE_CUSTOMER_PO`,
    ADD_TRANSFER_FILE: `${C}_ADD_TRANSFER_FILE`,
    REMOVE_TRANSFER_FILE: `${C}_REMOVE_TRANSFER_FILE`,
    EMPTY_FILES_IN_RAW_DATA: `${C}_EMPTY_FILES_IN_RAW_DATA`,
    COPY_FILES_TO_RAW_DATA: `${C}_COPY_FILES_TO_RAW_DATA`,
    SUBMIT_FILES: `${C}_SUBMIT_FILES`,
    CREATE_RECEIPT: `${C}_CREATE_RECEIPT`,
    CHANGE_RECEIPT_VALUE: `${C}_CHANGE_RECEIPT_VALUE`,
    SUBMIT_RECEIPT: `${C}_SUBMIT_RECEIPT`,
    EMPTY_RECEIPT: `${C}_EMPTY_RECEIPT`,
    SAVE_SUCCESS: `${C}_SAVE_SUCCESS`,
    RESET_DATA_ALL: `${C}_RESET_DATA_ALL`,
    CHANGE_CUSTOMER_PO_PRICE: `${C}_CHANGE_CUSTOMER_PO_PRICE`,
    CHANGE_STYLE_PRICE: `${C}_CHANGE_STYLE_PRICE`,
    CHANGE_COLOR_PRICE: `${C}_CHANGE_COLOR_PRICE`,
    CHANGE_SELECTED_CURRENCY: `${C}_CHANGE_SELECTED_CURRENCY`,
    CHANGE_CONVERT_RATE: `${C}_CHANGE_CONVERT_RATE`,
    CHANGE_MAIN_VAT_NO: `$${C}_CHANGE_MAIN_VAT_NO`,
    CHANGE_MAIN_TOTAL_AMOUNT: `${C}_CHANGE_MAIN_TOTAL_AMOUNT`,
    CHANGE_MAIN_VAT_DATE: `${C}_CHANGE_MAIN_VAT_DATE`,
    CHANGE_MAIN_TAX_RATE: `${C}_CHANGE_MAIN_TAX_RATE`,
    CHANGE_SUB_VAT_NO: `$${C}_CHANGE_SUB_VAT_NO`,
    CHANGE_SUB_TOTAL_AMOUNT: `${C}_CHANGE_SUB_TOTAL_AMOUNT`,
    CHANGE_SUB_VAT_DATE: `${C}_CHANGE_SUB_VAT_DATE`,
    CHANGE_SUB_TAX_RATE: `${C}_CHANGE_SUB_TAX_RATE`,
    CHANGE_SUB_VAT_NOTE: `${C}_CHANGE_SUB_VAT_NOTE`,
    ACTIVE_SCREEN_STATUS: `${C}_ACTIVE_SCREEN_STATUS`,
}