/**
 * @author hienhc <hienhc@nexlesoft.com>
 * @create date 2018-08-30 01:35:30
*/
import { combineReducers } from 'redux'
import C from '../../constants/ship-out/previewSelectedPklProdList';

/* Store selected filter type */
const filter = (state = null, action) => {
    const { type, payload } = action;
    switch (type) {
        case C.CHANGE_SELECTED_FILTER:
            return payload;
        default:
            return state;
    }
}

/* Store search text */
const searchText = (state = {}, action) => {
    const { type, payload } = action;
    switch (type) {
        case C.CHANGE_SEARCH_TEXT:
            return {...state, [payload.pos]: payload.value};
        default:
            return state;
    }
}

/* To know whether on searching or not */
const searching = (state = false, action) => {
    const { type } = action;
    switch (type) {
        case C.SEARCH_PKL_PROD_LIST:
            return true;
        case C.CANCEL_SEARCH_PKL_PROD_LIST:
            return false;
        default:
            return state;
    }
}

/* To know whether on fetching data from the api or not */
const fetching = (state = false, action) => {
    const { type } = action;
    switch (type) {
        case C.FETCH_PKL_PROD_LIST:
            return true;
        case C.CANCEL_FETCH_PKL_PROD_LIST:
            return false;
        default:
            return state;
    }
}

const itemList = (state = [], action) => {
    const { type, payload } = action;
    switch (type) {
        case C.CHANGE_ITEM_LIST:
            return payload;
        default:
            return state;
    }
}

const exporting = (state = false, action) => {
    const { type } = action;
    switch (type) {
        case C.EXPORT_ITEM_LIST:
            return true;
        case C.CANCEL_EXPORTING:
            return false;
        default:
            return state;
    }
}

/** 
 * Store the paging info
 * ======================
 **/
const currentPage = (state = 1, action) => {
    const { type, payload } = action;
    switch (type) {
        case C.CHANGE_PAGING_CURRENT:
            return payload;
        default:
            return state;
    }
}

const totalPages = (state = null, action) => {
    const { type, payload } = action;
    switch (type) {
        case C.CHANGE_PAGING_TOTAL:
            return payload;
        default:
            return state;
    }
}

const searched = (state = false, action) => {
    const {type, payload} = action;
    switch(type) {
        case C.CHANGE_SEARCH_STATUS:
            return payload;
        default:
            return state;
    }
}

export const previewSelectedPklProdList = combineReducers({
    filter,
    searchText,
    searching,
    fetching,
    itemList,
    exporting,
    currentPage,
    totalPages,
    searched,
});
