import * as types from '../constants/actionTypes';
import {showLoading, hideLoading} from './commonUseActions';
import purchasePlanServices from '../services/purchasePlanServices';

function getPurchasePlan(styleId, options = {}) {
  return (dispatch) => {
    showLoading(types, dispatch);

    purchasePlanServices.getPurchasePlan(styleId, options).then((res) => {
      hideLoading(types, dispatch);
      if (res) {
        dispatch({
          type: types.PURCHASE_PLAN.GET_BY_STYLE_SUCCESS,
          data: res.data || []
        });
      }
    }, (error) => {
      dispatch({
        type: types.PURCHASE_PLAN.GET_BY_STYLE_SUCCESS,
        data: []
      });
      hideLoading(types, dispatch, error);
    });
  };
}

function updatePurchasePlan(styleId, options = {}) {
  return (dispatch) => {
    // showLoading(types, dispatch);

    purchasePlanServices.updatePurchasePlan(styleId, options).then((res) => {
      if (res) {
        dispatch(getPurchasePlan(styleId, {type: 'ADDITIONAL', populate: 'materials.material'}));
      }
    }, (error) => {
      // hideLoading(types, dispatch);
    });
  };
}

export default {
  getPurchasePlan,
  updatePurchasePlan
};
