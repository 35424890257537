import React, {Component} from 'react';
import numeral from "numeral";

export default class Amount extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {value, className} = this.props;
    return (
      <td className={className}>
        {numeral(value).format('0,0.00')}
      </td>
    );
  }
}
