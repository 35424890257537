import React from 'react';
import moment from 'moment';
import I18n from 'i18n-js';

export default (props)=>{

  return (
    <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <a className="close" onClick={() => {props.closeModal()}}><span aria-hidden="true">&times;</span></a>
            <h4 className="modal-title" id="myModalLabel">{I18n.t('PageSampleApproval.AUDITLOG')}</h4>
          </div>
          <div className="modal-body pd-0">
            <ul className="list-log-history material-log-history" style={{paddingBottom: 20}}>
                <div className="pd-15 bg-white">
                    <div className="row" style={{paddingBottom: 10, fontSize: 12}}>
                        <div className="col col-md-6"><div>{I18n.t('PageSampleApproval.TechPack')}</div></div>
                        <div className="col col-md-6 text-center"><div>{props.info === undefined ? <div></div> : moment(props.info.techPack).format('MMM DD, YYYY')}</div></div>
                    </div>
                    <div className="row" style={{paddingBottom: 10, fontSize: 12}}>
                        <div className="col col-md-6"><div>{I18n.t('PageSampleApproval.SewBy')}</div></div>
                        <div className="col col-md-6 text-center">{props.info === undefined ? <div></div> : moment(props.info.sewBy).format('MMM DD, YYYY')}</div>
                    </div>
                    <div className="row" style={{paddingBottom: 10, fontSize: 12}}>
                        <div className="col col-md-6"><div>{I18n.t('PageSampleApproval.Pattern')}</div></div>
                        <div className="col col-md-6 text-center">{props.info === undefined ? <div></div> : moment(props.info.pattern).format('MMM DD, YYYY')}</div>
                    </div>
                    <div className="row" style={{paddingBottom: 10, fontSize: 12}}>
                        <div className="col col-md-6"><div>{I18n.t('PageSampleApproval.PM')}</div></div>
                        <div className="col col-md-6 text-center">{props.info === undefined ? <div></div> : moment(props.info.productionMarker).format('MMM DD, YYYY')}</div>
                    </div>
                    <div className="row pb-5" style={{paddingBottom: 10, borderBottom: `1px dashed rgb(232, 234, 237)`, fontSize: 12}}>
                        <div className="col col-md-6"><div>{I18n.t('PageSampleApproval.RPM')}</div></div>
                        <div className="col col-md-6 text-center">{props.info === undefined ? <div></div> : moment(props.info.revisedProductionMarker).format('MMM DD, YYYY')}</div>
                    </div>
                    <div className="row" style={{paddingTop: 10, fontSize: 12}}>
                        <div className="col col-md-6"><div>{I18n.t('PageSampleApproval.ADSubmit')}:</div></div>
                        <div className="col col-md-6">{props.info === undefined ? <div></div> : props.info.adSubmit}</div>
                    </div>
                </div>
            </ul>
          </div>
        </div>
      </div>
  );
}