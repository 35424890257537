import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import assignmentActions from '../../../actions/assignmentActions';
import contractorActions from '../../../actions/contractorActions';
import _ from 'lodash';
import I18n from 'i18n-js';

class StockOut extends Component {
  constructor (props, context) {
    super(props, context);

    this.state = {
      item: {},
      orders: [],
      contractors: [],
      errors: {}
    };
  }

  componentWillMount () {
    // this.getListOrder();
    this.getListContractor();
  }

  componentWillReceiveProps (newProps) {
    let {orders, contractors} = this.state;
    !_.isEqual(orders, newProps.assignmentState.ordersC) && this.setState({orders: _.cloneDeep(newProps.assignmentState.ordersC) || []});
    !_.isEqual(contractors, newProps.contractorState.items) && this.setState({contractors: _.cloneDeep(newProps.contractorState.items) || []});
  }

  getListOrder (contractorId, options = {}) {
    this.props.assignmentPropsActions.getOrdersByContractor(contractorId, options);
  }

  getListContractor (query = {}) {
    this.props.contractorPropsActions.getListItem(_.assign({query, isAssigned: true, sort: {name: 1}}, {}));
  }

  onChange (property, value) {
    let {item, orders} = this.state;

    switch (property) {
      case 'contractorId':
        this.setState({item: _.set(_.pick(item, ['orderId', 'styleId', 'cutTickets']), property, value)});
        this.getListOrder(value, {})
        break;
      case 'styleId':
        let order = orders.find(i => _.get(i, 'order._id', '') == item.orderId) || {};
        let styles = order.styles || [];
        let cutTickets = styles.find(i => _.get(i, 'style._id', '') == value) || {};
        cutTickets = cutTickets.cutTickets || []
        item = {...item, cutTickets, [property]: value}
        this.setState({item});
        break;
      default:
        this.setState({item: _.set(_.pick(item, ['contractorId']), property, value)});
    }
  }

  close () {
    this.props.closeModal();
  }

  validate () {
    let {item, errors} = this.state;
    let fields = {orderId: 'Customer PO', styleId: 'Style', contractorId: 'Contractor', cutTickets: 'CutTickets'};

    Object.keys(fields).forEach(f => {
      _.set(errors, f, !item[f] ? `Please select ${fields[f]}` : '');
    });
    this.setState({errors});

    let flag = Object.keys(errors).every(k => !errors[k]);

    return flag;
  }

  save () {
    let {item, contractors, orders} = this.state;
    const {openFormAdd} = this.props;

    let contractor = contractors.find(i => i._id == item.contractorId);
    let order = orders.find(i => _.get(i, 'order._id', '') == item.orderId) || {};

    let data = _.cloneDeep(item);
    data.order = order.order;
    data.style = order.styles.find(vl => (vl.style || {})._id == item.styleId).style || {};
    data.contractor = contractor;

    if (this.validate()) {
      // open form add
      _.isFunction(openFormAdd) && openFormAdd(data);

      // close modal add
      this.props.closeModal();
    }
  }

  selectAllCutTicket = (cts, check) => {
    let {item} = this.state;    
    this.setState({item: {...item, cutTickets: check ? cts: []}});
  }

  selectCutTicket = (ct, check) => {
    let {item} = this.state;
    if(check) item.cutTickets.push(ct)
    else item.cutTickets = item.cutTickets.filter(vl => vl._id != ct._id)
    this.setState({item});
  }

  render () {
    let {orders, contractors, item, errors} = this.state;

    let orderSelected = (orders || []).find(i => _.get(i, 'order._id', '') == item.orderId) || {};
    let styles = orderSelected.styles || [];
    let cutTickets = styles.find(i => _.get(i, 'style._id', '') == item.styleId) || {};
    cutTickets = cutTickets.cutTickets || []

    return (
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <form>
            <div className="modal-header">
              <a className="close" onClick={() => this.close()}><span aria-hidden="true">&times;</span></a>
              <h4 className="modal-title" id="myModalLabel">{I18n.t('PageWareHouse.ADDOUTBOUND')}</h4>
            </div>

            <div className="modal-body">
              <div className="form-group">
                <div className="col-md-4 col-xs-12 text">
                  <p>{I18n.t('PageWareHouse.Contractor')}</p>
                </div>
                <div className="col-md-8 col-xs-12 value">
                  <select className="form-control" value={item.contractorId || ''} onChange={evt => this.onChange('contractorId', evt.target.value)} >
                    <option value="" />
                    {contractors.map((i, idx) => (<option key={idx} value={i._id}>{i.name}</option>))}
                  </select>
                  {errors['contractorId'] && <div className="alert-warning">{errors['contractorId']}</div>}
                </div>
              </div>
              <div className="form-group">
                <div className="col-md-4 col-xs-12 text">
                  <p>{I18n.t('PageWareHouse.CustomerPO')}</p>
                </div>
                <div className="col-md-8 col-xs-12 value">
                  <select className="form-control" value={item.orderId || ''} onChange={evt => this.onChange('orderId', evt.target.value)}>
                    <option value="" />
                    {orders.map((i, idx) => (<option key={idx} value={_.get(i, 'order._id', '')}>{_.get(i, 'order.customerPO')}</option>))}
                  </select>
                  {errors['orderId'] && <div className="alert-warning">{errors['orderId']}</div>}
                </div>
              </div>

              <div className="form-group">
                <div className="col-md-4 col-xs-12 text">
                  <p>{I18n.t('PageWareHouse.Style')}</p>
                </div>
                <div className="col-md-8 col-xs-12 value">
                  <select className="form-control" value={item.styleId || ''} onChange={evt => this.onChange('styleId', evt.target.value)} >
                    <option value="" />
                    {styles.map((i, idx) => (<option key={idx} value={_.get(i, 'style._id', '')}>{_.get(i, 'style.style', '')}</option>))}
                  </select>
                  {errors['styleId'] && <div className="alert-warning">{errors['styleId']}</div>}
                </div>
              </div>

              {cutTickets.length > 0 && (
                <div className="form-group">
                  <div className="col-md-4 col-xs-12 text">
                    <p>{I18n.t('PageWareHouse.CutTicket')}</p>
                  </div>
                  <div className="col-md-8 col-xs-12 value">
                    <div className="style-checkbox">
                      <input type="checkbox" id={`check_style_all_${item.styleId || '999'}`}
                             checked={cutTickets.length == (item.cutTickets || []).length}
                             onChange={evt => this.selectAllCutTicket(cutTickets, evt.target.checked)}
                      />
                      <label htmlFor={`check_style_all_${item.styleId || '999'}`}>
                        <span className="mg-right-15"></span>
                        {I18n.t('PageProduction.AllCutTicket')}
                      </label>
                    </div>
                    {cutTickets.map(ct=>(
                      <div key={ct._id} className="style-checkbox">
                        <input type="checkbox" id={`check_style_all_${ct._id || '999'}`}
                              checked={!!(item.cutTickets|| []).find(i => i._id === ct._id)}
                              onChange={evt => this.selectCutTicket(ct, evt.target.checked)}
                        />
                        <label htmlFor={`check_style_all_${ct._id || '999'}`}>
                          <span className="mg-right-15"></span>
                          {ct.cutTicket}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>

            <div className="modal-footer">
              <button type="button" className="bt-black" onClick={() => this.close()}>{I18n.t('Btn.CANCEL')}</button>
              <button type="button" className="bt-orange mg-l-5" onClick={() => this.save()}>{I18n.t('Btn.SAVE')}</button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

function mapStateToProps (state, ownProps) {
  return {
    assignmentState: state.assignmentState,
    contractorState: state.contractorState
  };
}

function mapDispatchToProps (dispatch) {
  return {
    assignmentPropsActions: bindActionCreators(assignmentActions, dispatch),
    contractorPropsActions: bindActionCreators(contractorActions, dispatch)
  };
}

StockOut.propTypes = {
};

export default connect(mapStateToProps, mapDispatchToProps)(StockOut);
