import React from "react";
import PropTypes from 'prop-types';

const ViewInvoiceNo = (props) => {
    return (
        <div>
            <div className="row mg-0 pd-top-20 pd-bottom-20">
                <span className ="size-15">Customer:</span>
                <span className ="color-orange size-20 bold mg-left-15">{props.customer}</span>
            </div>
            <div className="row mg-0 mg-bottom-10">
                <div className="col-md-6 pd-0-5-0-0">
                    <ul className="content-header-title box-shadow bg-white float-none justify-content-space-between display-flex h-full-none">
                        <li className="bd-dashed-right-1 w-half-part h-full-none pd-10">
                            <p>Credit/Debit
                                <span className="bold mg-left-25 size-12">1</span>
                            </p>
                        </li>
                        <li className="w-half-part h-full-none pd-10">
                            <p>Amount
                                <span className="bold color-number mg-left-25 size-12">{props.amountCredit}</span>
                            </p>
                        </li>
                    </ul>
                </div>
                <div className="col-md-6 pd-0-5-0-0">
                    <ul className="content-header-title box-shadow bg-white float-none justify-content-space-between display-flex h-full-none">
                        <li className="bd-dashed-right-1 w-half-part h-full-none pd-10 line-h-initial">
                            <div className="h-half-part">
                                <span>Convert to currency</span>
                                <span className="bold mg-left-25 size-12">{props.convertType}</span>
                            </div>
                            <div className="h-half-part">
                                <span>Rate</span>
                                <span className="bold mg-left-25 size-12">{props.convertRate}</span>
                            </div>
                        </li>
                        <li className="w-half-part h-full-none pd-10">
                            <p>Amount
                                <span className="bold color-number mg-left-25 size-12">{props.amount}</span>
                            </p>
                        </li>
                    </ul>
                </div>
            </div>
            {_.map(props.customerPOs, (elem, i) => (
                <div className="mg-bottom-10" key={i}>
                    <div className="d-flex justify-content-between cursor-pointer pd-10-0 color-white bg-black">
                        <div className="d-flex">
                            <p className="size-12 mg-bottom-auto mg-top-auto"><span className="icon fa fa-caret-down cursor-pointer mg-0-15"></span> Customer PO</p>
                            <span className="bold mg-left-50 size-14 mg-bottom-auto mg-top-auto">{elem.customerPO}</span>
                        </div>
                        {/* <div className="d-flex justify-content-end">
                            <div className="d-flex mg-right-30">
                                <p className="size-12 mg-right-15 mg-bottom-auto mg-top-auto">IH Date</p>
                                <span className="bold mg-bottom-auto mg-top-auto size-12">
                                    August 13,2012
                                </span>
                            </div>
                        </div> */}
                    </div>
                    {_.map(elem.items, (item, k) => (
                        <div key={k} className="mg-bottom-5">
                            <div className="box-shadow-1 mg-bottom-5">
                                <ul className="content-header-title bg-white float-none pd-0-10 justify-content-space-between display-flex bd-dashed-bottom">
                                    <li>
                                        <p>Style
                                            <span className="bold mg-left-25 size-12">{item.style}</span>
                                        </p>
                                    </li>
                                    <li>
                                        <p>Price Total
                                            <span className="bold color-number mg-left-25 size-12">$330</span>
                                        </p>
                                    </li>
                                </ul>
                                <ul className="content-header-title bg-white float-none pd-0-10 justify-content-space-between display-flex">
                                    <li>
                                        <p>LDP
                                            <span className="bold mg-left-25 size-12">01</span>
                                        </p>
                                    </li>
                                    <li>
                                        <p>Air Charge
                                            <span className="bold mg-left-25 size-12">01</span>
                                        </p>
                                    </li>
                                    <li>
                                        <p>Sample Charge
                                            <span className="bold mg-left-25 size-12">01</span>
                                        </p>
                                    </li>
                                    <li>
                                        <p>G-Total
                                            <span className="bold mg-left-25 size-12">01</span>
                                        </p>
                                    </li>
                                </ul>
                            </div>

                            <table className="global-table w-full">
                                <tbody>
                                    <tr>
                                        <th>Cut Ticket</th>
                                        <th>Color</th>
                                        <th>Size</th>
                                        <th>Quantity</th>
                                    </tr>
                                    {
                                        _.map(item.items, (row, j) => (
                                            <tr key={j}>
                                                <td>{row.cutTicket}</td>
                                                <td>{row.color}</td>
                                                <td>-</td>
                                                <td>{row.qty}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    ))}
                    
                </div>
            ))}
        </div>
    );
};

ViewInvoiceNo.propTypes = {
    data: PropTypes.object
}

ViewInvoiceNo.defaultProps = {
    data: {}

}

export default ViewInvoiceNo;
