
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import * as R from 'ramda';

import PurchaseOrderDetail from '../../components/purchase/PurchaseOrderDetail';

import * as selectors from '../../../selectors/purchase/showPurchaseDetail';
import * as actions from '../../../actions/purchase/showPurchaseDetail';

class View extends Component {
  componentWillMount() {
    const id = R.path(['match', 'params', 'id'], this.props);
    this.props.loadData(id);
  }

  componentWillUnmount() {
    this.props.resetData();
  }

  render() {
    return (
      <PurchaseOrderDetail
        {...this.props}
      />
    )
  }
}

const mapStateToProps = state => ({
  customerPO: selectors.getCustomerPO(state),
  style: selectors.getStyle(state),
  purchaseNo: selectors.getPurchaseNo(state),
  supplier: selectors.getSupplier(state),
  creator: selectors.getCreator(state),
  sender: selectors.getSender(state),
  gTotal: selectors.getGTotal(state),
  remark: selectors.getRemark(state),
  items: selectors.getItems(state),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  loadData(id) {
    if (id) {
      dispatch(actions.fetchPurchaseOrder(id));
    }
  },

  resetData() {
    dispatch(actions.resetData());
  },
});

export default connect(mapStateToProps, mapDispatchToProps) (withRouter(View));
