import Base from './base';

class Purchase extends Base {
  constructor () {
    super();
    this.getPurchasePlanDAL = this.getPurchasePlanDAL.bind(this);
    this.updatePurchasePlanDAL = this.updatePurchasePlanDAL.bind(this);
  }

  getPurchasePlanDAL (styleId, query) {
    return super.execute(this.Urls.purchasePlan.getByStyleId.replace('{id}', styleId), this.ApiMethod.get, query);
  }

  updatePurchasePlanDAL (styleId, data) {
    return super.execute(this.Urls.purchasePlan.getByStyleId.replace('{id}', styleId), this.ApiMethod.put, data);
  }

}

export default new Purchase();
