import React from 'react';
import { Switch, Route, Redirect } from "react-router-dom";
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import BaseComponent from '../base/BaseComponent';
import ScreenAccountsPayable from './ScreenAccountsPayable';
import ScreenAccountsReceivable from './ScreenAccountsReceivable';
import ScreenCreditDebitNote from './ScreenCreditDebitNote';
import _ from 'lodash';
import * as utils from "../../functions/utils";

import {
  ACCOUNTING_URL, ACCOUNTING_PAYABLE_URL, ACCOUNTING_RECEIVABLE_URL,
  ACCOUNTING_CREDIT_DEBIT_URL,
  ACCOUNTING_PAYABLE_INVOICE_CONTAINER_EDIT_URL,
  ACCOUNTING_PAYABLE_INVOICE_VIEW_URL,
  ACCOUNTING_PAYABLE_INVOICE_DECLARATION_EDIT_URL,
  ACCOUNTING_PAYABLE_INVOICE_US_DELIVERY_EDIT_URL,
  ACCOUNTING_PAYABLE_INVOICE_CONTRACTOR_EDIT_URL,
  ACCOUNTING_PAYABLE_INVOICE_DOMESTIC_EDIT_URL,
  ACCOUNTING_PAYABLE_INVOICE_OVERSEA_EDIT_URL,
  ACCOUNTING_PAYABLE_HISTORY_CONTAINER_VIEW_URL,
  ACCOUNTING_PAYABLE_HISTORY_DECLARATION_VIEW_URL,
  ACCOUNTING_PAYABLE_HISTORY_US_DELIVERY_VIEW_URL,

  ACCOUNTING_PAYABLE_HISTORY_CONTRACTOR_VIEW_URL,
  ACCOUNTING_PAYABLE_HISTORY_OVERSEA_VIEW_URL,
  ACCOUNTING_PAYABLE_HISTORY_DOMESTIC_VIEW_URL,

  ACCOUNTING_RECEIVABLE_INVOICE_VIEW_URL,
  ACCOUNTING_RECEIVABLE_INVOICE_EDIT_NORMAL_URL,
  ACCOUNTING_RECEIVABLE_PLAN_VIEW_CHARGE_BACK_URL
} from "../../constants/routeConstants";

//Invoice list and edit payable
import EditInvoiceIEContainer from '../../ui/containers/accounting/PayableInvoiceList/EditInvoiceIEContainer';
import EditInvoiceIEDeclaration from '../../ui/containers/accounting/PayableInvoiceList/EditInvoiceIEDeclaration';
import EditInvoiceIEUSDelivery from '../../ui/containers/accounting/PayableInvoiceList/EditInvoiceIEUSDelivery'
import EditInvoiceContractor from '../../ui/containers/accounting/PayableInvoiceList/EditInvoiceContractor';
import EditInvoiceSupplierDomestic from '../../ui/containers/accounting/PayableInvoiceList/EditInvoiceSupplierDomestic';
import EditInvoiceSupplierOversea from '../../ui/containers/accounting/PayableInvoiceList/EditInvoiceSupplierOversea';
//Invoice history and view payable
import HistoryInvoicePreviewIEContainer from '../../ui/containers/accounting/payableHistoryList/PreviewIEContainer';
import HistoryInvoicePreviewDeclaration from '../../ui/containers/accounting/payableHistoryList/PreviewDeclaration';
import HistoryInvoicePreviewUSDelivery from '../../ui/containers/accounting/payableHistoryList/PreviewUSDelivery';

import HistoryInvoicePreviewContractor from '../../ui/containers/accounting/payableHistoryList/PreviewContractor';
import HistoryInvoicePreviewSupplierOversea from '../../ui/containers/accounting/payableHistoryList/PreviewSupplierOversea';
import HistoryInvoicePreviewSupplierDomestic from '../../ui/containers/accounting/payableHistoryList/PreviewSupplierDomestic';

// Invoice history and view receivable
import HistoryReceivableInvoicePreview from '../../ui/containers/accounting/receivable/PreviewHistoryReceivableInvoice';
// Invoice list and edit receivable
import EditNormalReceivableInvoice from '../../ui/containers/accounting/receivable/EditNormalReceivableInvoice';
import PreviewCreditDebit from '../../ui/containers/accounting/creditDebit/PreviewCreditDebit';
import ViewInvoiceNo from '../../ui/containers/accounting/PayableInvoiceList/ViewInvoiceNo';
class PageAccounting extends BaseComponent {
  constructor(props, context) {
    super(props, context);

    this.state = {
      tabCurrent: ''
    };
  }

  componentWillReceiveProps(newProps) {
    let {tabCurrent} = this.state;
    !(tabCurrent === _.get(newProps.appState, 'page.tab', '')) && this.setState({tabCurrent: _.get(newProps.appState, 'page.tab', '')});
  }

  getEditComponent() {
    let EditComponent = null;

    return EditComponent;
  }

  render() {
    let roles = utils.getRoleByAuthState(this.props.authState, 'accounting');
    return (
      <div>
        <div id="content">
          <Switch>
            {/* History view invoice payable*/}
            <Route path = {ACCOUNTING_PAYABLE_HISTORY_CONTAINER_VIEW_URL} component = {HistoryInvoicePreviewIEContainer} />
            <Route path = {ACCOUNTING_PAYABLE_HISTORY_DECLARATION_VIEW_URL} component = {HistoryInvoicePreviewDeclaration} />
            <Route path = {ACCOUNTING_PAYABLE_HISTORY_US_DELIVERY_VIEW_URL} component = {HistoryInvoicePreviewUSDelivery} />
            <Route path = {ACCOUNTING_PAYABLE_HISTORY_CONTRACTOR_VIEW_URL} component = {HistoryInvoicePreviewContractor} />
            <Route path = {ACCOUNTING_PAYABLE_HISTORY_OVERSEA_VIEW_URL} component = {HistoryInvoicePreviewSupplierOversea} />
            <Route path = {ACCOUNTING_PAYABLE_HISTORY_DOMESTIC_VIEW_URL} component = {HistoryInvoicePreviewSupplierDomestic} />
            {/* preview invoice */}
            {/* <Route path = {ACCOUNTING_PAYABLE_PLAN_CONTRACTOR_PREVIEW_URL} component = {CreateInvoicePreviewContractor} /> */}
            {/* <Route path = {ACCOUNTING_PAYABLE_PLAN_SUPPLIER_DOMESTIC_PREVIEW_URL} component = {CreateInvoicePreviewSupplierDomestic} /> */}
            {/* <Route path = {ACCOUNTING_PAYABLE_PLAN_SUPPLIER_OVERSEA_PREVIEW_URL} component = {CreateInvoicePreviewSupplierOversea} /> */}
            {/* <Route path = {ACCOUNTING_PAYABLE_PLAN_IMPORTEXPORT_US_DELIVERY_PREVIEW_URL} component = {CreateInvoicePreviewUSDelivery} />
            <Route path = {ACCOUNTING_PAYABLE_PLAN_IMPORTEXPORT_DECLARATION_PREVIEW_URL} component = {CreateInvoicePreviewDeclaration} /> */}
            {/* <Route path = {ACCOUNTING_PAYABLE_PLAN_IMPORTEXPORT_CONTAINER_PREVIEW_URL} component = {CreateInvoicePreviewIEContainer} /> */}

            {/* History view invoice receivable*/}
            <Route path = {ACCOUNTING_RECEIVABLE_INVOICE_VIEW_URL} component = {HistoryReceivableInvoicePreview} />
            <Route path = {ACCOUNTING_RECEIVABLE_INVOICE_EDIT_NORMAL_URL} render = {() => <EditNormalReceivableInvoice {...this.props}/>} />
            
            {/* Edit invoice payable*/}

            <Route path = {ACCOUNTING_PAYABLE_INVOICE_CONTAINER_EDIT_URL} component = {EditInvoiceIEContainer} />
            <Route path = {ACCOUNTING_PAYABLE_INVOICE_DECLARATION_EDIT_URL} component = {EditInvoiceIEDeclaration} />
            <Route path = {ACCOUNTING_PAYABLE_INVOICE_US_DELIVERY_EDIT_URL} component = {EditInvoiceIEUSDelivery} />
            <Route path = {ACCOUNTING_PAYABLE_INVOICE_CONTRACTOR_EDIT_URL} component = {EditInvoiceContractor} />
            <Route path = {ACCOUNTING_PAYABLE_INVOICE_DOMESTIC_EDIT_URL} component = {EditInvoiceSupplierDomestic} />
            <Route path = {ACCOUNTING_PAYABLE_INVOICE_OVERSEA_EDIT_URL} component = {EditInvoiceSupplierOversea} />
            <Route path = {ACCOUNTING_PAYABLE_INVOICE_VIEW_URL} component = {ViewInvoiceNo} />

            <Route path={ACCOUNTING_URL} exact render={() => <Redirect to={ACCOUNTING_PAYABLE_URL} />} />
            <Route path={ACCOUNTING_PAYABLE_URL} render = {() => <ScreenAccountsPayable {...this.props} roles={roles}/>}/>
            <Route path={ACCOUNTING_RECEIVABLE_URL} render = {() => <ScreenAccountsReceivable {...this.props} roles={roles}/>}/>
            <Route path={ACCOUNTING_CREDIT_DEBIT_URL} exact render = {() => <ScreenCreditDebitNote {...this.props} roles={roles}/>} />

            <Route path={ACCOUNTING_RECEIVABLE_PLAN_VIEW_CHARGE_BACK_URL} exact render={() => <PreviewCreditDebit {...this.props}/>} />
          </Switch>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    appState: state.appState,
    authState: state.authState
  };
}

function mapDispatchToProps(dispatch) {
  return {
    
  };
}

PageAccounting.propTypes = {
  authState: PropTypes.object.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(PageAccounting);
