import {getListSupplierDAL, addSupplierDAL, editSupplierDAL, getSupplierDAL, deleteSupplierDAL} from '../api/supplierApi';
import _ from 'lodash';

class supplierServices {
  constructor() {
  }

  getListSupplier (options = {}) {
    options.query && _.set(options, 'query', JSON.stringify(options['query']));
    options.sort && _.set(options, 'sort', JSON.stringify(options['sort']));
    return getListSupplierDAL(options).then((res) => {
      return res;
    });
  }

  getDetail (id) {
    return getSupplierDAL(id, {});
  }

  deleteSupplier (id) {
    return deleteSupplierDAL(id);
  }

  editSupplier (id, data) {
    return editSupplierDAL(id, data);
  }

  addSupplier (data) {
    return addSupplierDAL(data);
  }
}

export default new supplierServices();
