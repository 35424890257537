import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BaseComponent from '../../base/BaseComponent';
import PropTypes from 'prop-types';
import TextInputValidate from '../.././common/fields/TextInputValidate';
import SelectValidate from '../.././common/fields/SelectValidate';
import supplierActions from '../../../actions/supplierActions';
import materialActions from '../../../actions/materialActions';
import propertyActions from '../../../actions/propertyActions';
import { PaymentTerms, Currencies, MaterialTypes } from '../../../constants/constants';
import I18n from 'i18n-js';
import { isStringEmpty, validateEmail } from '../../../functions/helpers';
import ReactTelephoneInput from 'react-telephone-input/lib/withStyles'
import {toastr} from "react-redux-toastr";
import SearchSelect from '../../../ui/components/forms/SearchSelect';
import DropDownMultiSelect from '../fields/DropDownMultiSelect';

import * as selector from '../../../selectors/crm/addSupplier';
import * as supplierActionsRft from '../../../actions/crm/supplierActions';

import flagsIcon from '../../../stylesheets/images/flags.png';
import icAddIcon from '../../../stylesheets/images/ic-add.svg';
import icXIcon from '../../../stylesheets/images/ic-x.svg';

class ModalSupplierForm extends BaseComponent {
  constructor(props, context) {
    super(props, context);

    this.state = {
      errors: {}
    }
    this.onChangeMaterialProperty = this.onChangeMaterialProperty.bind(this)
  }

  componentWillMount() {
    const data = _.cloneDeep(_.get(this.props, 'supplierDetail', {}));
    if (data && (data.id || data._id)) {
      this.props.supplierActions.changeSupplier(data);
      // const compressedMaterials = this.compressMaterials(data.realMaterials);
      this.props.supplierActions.changeMaterial(data.realMaterials);
    } else {
      this.props.supplierActions.changeSupplier({ company: {}, bank: {} });
      this.props.supplierActions.addSelectedMaterial({});
    }
    this.setState({ supplierDetail: data });
    this.props.materialPropsActions && this.props.materialPropsActions.getListMaterial({});
    this.props.propertyPropsActions && this.props.propertyPropsActions.getListProperty();

    this.props.supplierActions.getListMaterial({});
  }

  compressMaterials(materials) {
    let result = [];
    (materials || []).forEach(element => {
        const itemIndex = result.findIndex(i => i.material === element.material && i.price === element.price);
        if (itemIndex === -1) {
            const newItem = Object.assign({}, element);
            if (element.color && typeof element.color === 'string') {
                newItem.color = element.color.split(',');
            }
            if (element.size && typeof element.size === 'string') {
                newItem.size = element.size.split(',');
            }
            result.push(newItem);
        } else {
            if (element.color) {
                const newItem = result[itemIndex];
                if (!newItem.color) {
                    newItem.color = [];
                }
                if (newItem.color.findIndex(i => i === element.color) === -1) {
                    newItem.color.push(element.color);
                    result.slice(itemIndex, 1, newItem);
                }
            }
            if (element.size) {
                const newItem = result[itemIndex];
                if (!newItem.size) {
                    newItem.size = [];
                }
                if (newItem.size.findIndex(i => i === element.size) === -1) {
                    newItem.size.push(element.size);
                    result.slice(itemIndex, 1, newItem);
                }
            }
        }
    });
    return result;
  }

  // color must have value
  uncompressMaterials(materials) {
    let results = [];
    (materials || []).forEach(m => {
      const colors = _.get(m, 'color', []);
      const sizes = _.get(m, 'size', []);
      colors.forEach(c => {
        if (sizes.length > 0) {
          sizes.forEach(s => {
            const newMaterial = { material: _.get(m, 'material', ''), color: c, size: s || '', price: _.get(m, 'price', '')};
            results.push(newMaterial);
          });
        } else {
          const newMaterial = { material: _.get(m, 'material', ''), color: c, price: _.get(m, 'price', '')};
          results.push(newMaterial);
        }
      });
    });
    return results;
  }

  onChange = (property, value) => {
    switch(property) {
      case 'name':
        this.props.supplierActions.changeName(value);
        break;
      case 'shortName':
        this.props.supplierActions.changeShortName(value);
        break;
      case 'bankBranch':
        this.props.supplierActions.changeBankBranch(value);
        break;
      case 'bankAccountNo':
        this.props.supplierActions.changeBankAccountNo(value);
        break;
      case 'companyName':
        this.props.supplierActions.changeCompanyName(value);
        break;
      case 'companyAddress':
        this.props.supplierActions.changeCompanyAddress(value);
        break;
      case 'type':
        this.props.supplierActions.changeType(value);
        break;
      case 'phoneNumber':
        this.props.supplierActions.changePhoneNumber(value);
        break;
      case 'paymentTerm':
        this.props.supplierActions.changePaymentTerm(value);
        break;
      case 'fax':
        this.props.supplierActions.changeFax(value);
        break;
      case 'currency':
        this.props.supplierActions.changeCurrency(value);
        break;
      case 'email':
        this.props.supplierActions.changeEmail(value);
        break;
      case 'deliveryDeviation':
        this.props.supplierActions.changeDeliveryDeviation(value);
        break;
    }
    this.validateBeforeChange(property, value);
  }

  handleAddMaterial = () => {
    this.props.supplierActions.addSelectedMaterial({});
    const { selectedMaterials } = this.props;
    this.validateBeforeChange('materials', selectedMaterials.materials);
  }

  handleRemoveMaterial = (index) => {
    this.props.supplierActions.removeSelectedMaterial(index);
    const { selectedMaterials } = this.props;
    this.validateBeforeChange('materials', selectedMaterials);
  }

  onChangeMaterial = (index, value) => {
    this.props.supplierActions.changeSelectedMaterials({index, value, property: 'material' });
    this.onValidateMaterialChange(index, 'material', value);
  }

  onChangeMaterialProperty = (index, property, value) => {
    if (property === 'price') {
      value = _.round(value * 1, 3);
    }
    this.props.supplierActions.changeSelectedMaterials({index, property, value});
    this.onValidateMaterialChange(index, property, value);
  }

  onValidateMaterialChange(index, property, value) {
    let errors = {};
    if(property === 'material' && _.isEmpty(value)) {
      errors['material'+index] = I18n.t('ErrorMessages.SupplierMaterialIsRequired');
    } else if(property === 'color' && (value || '').length < 1) {
      errors['materialColor'+index] = I18n.t('ErrorMessages.SupplierMaterialColorIsRequired');
    } else if(property === 'price' && (value || '').length < 1) {
      errors['materialPrice'+index] = I18n.t('ErrorMessages.SupplierMaterialPriceIsRequired');
    } else if(property === 'price' && value < 0) {
      errors['materialPrice'+index] = I18n.t('ErrorMessages.SupplierMaterialPriceIsLargeMoreThan0');
    }
    this.setState({ errors });
  }


  close() {
    this.props.closeModal();
  }

  validateBeforeChange = (property, value) => {
    let { errors } = this.state;
    errors = {};
    switch (property) {
      case 'name': {
        if (isStringEmpty(value)) {
          errors['name'] = I18n.t('ErrorMessages.SupplierNameIsRequired');
        }
        if(_.isEmpty(value)) errors['name'] = '';
        this.setState({ errors });
        break;
      }
      case 'shortName': {
        if (isStringEmpty(value)) {
          errors['shortName'] = I18n.t('ErrorMessages.SupplierShortNameIsRequired');
        }
        if(_.isEmpty(value)) errors['shortName'] = '';
        this.setState({ errors });
        break;
      }
      case 'division': {
        if (isStringEmpty(value)) {
          errors['type'] = I18n.t('ErrorMessages.SupplierDivisionIsRequired');
        }
        if(_.isEmpty(value)) errors['division'] = '';
        this.setState({ errors });
        break;
      }
      case 'email': {
        if (isStringEmpty(value)) {
          errors['email'] = I18n.t('ErrorMessages.SupplierEmailIsRequired');
        } else if (!validateEmail(value)) {
          errors['email'] = I18n.t('ErrorMessages.SupplierEmailWrongFormat');
        }
        if(_.isEmpty(value)) errors['email'] = '';
        this.setState({ errors });
        break;
      }
      case 'deliveryDeviation': {
        if (isStringEmpty(value + '')) {
          errors['deliveryDeviation'] = I18n.t('ErrorMessages.SupplierDeliveryDeviationIsRequired');
        }
        if(_.isEmpty(value)) errors['deliveryDeviation'] = '';
        this.setState({ errors });
        break;
      }
      case 'currency': {
        if (isStringEmpty(value)) {
          errors['currency'] = I18n.t('ErrorMessages.SupplierCurrencyIsRequired');
        }
        if(_.isEmpty(value)) errors['currency'] = '';
        this.setState({ errors });
        break;
      }
      case 'materials': {
        console.log('-----', value);
        if (!value || (value || '').length < 1) {
          errors['material'] = I18n.t('ErrorMessages.SupplierMaterialIsRequired');
        } else {
          _.each(value, (material, index) => {
            if(!material || _.isEmpty(material)) {
              errors['material'+index] = I18n.t('ErrorMessages.SupplierMaterialIsRequired');
              return false;
            }
            if(!material.color) {
              errors['materialColor'+index] = I18n.t('ErrorMessages.SupplierMaterialColorIsRequired');
              return false;
            }
            if(!material.price) {
              errors['materialPrice'+index] = I18n.t('ErrorMessages.SupplierMaterialPriceIsRequired');
              return false;
            }
            if(material.price < 0) {
              errors['materialPrice'+index] = I18n.t('ErrorMessages.SupplierMaterialPriceIsLargeMoreThan0');
              return false;
            }
          })
        }
        this.setState({ errors });
        break;
      }
      default:
    }
  }

  validateBeforeSave = (data) => {
    let { errors } = this.state;
    errors = {};
    if (!data) {
      errors['name'] = I18n.t('ErrorMessages.SupplierInfoIsRequired');
      this.setState({ errors });
      return false;
    }

    if (isStringEmpty(data.name)) {
      errors['name'] = I18n.t('ErrorMessages.SupplierNameIsRequired');
      this.setState({ errors });
      return false;
    }

    if (isStringEmpty(data.shortName)) {
      errors['shortName'] = I18n.t('ErrorMessages.SupplierShortNameIsRequired');
      this.setState({ errors });
      return false;
    }

    if (isStringEmpty(data.division)) {
      errors['type'] = I18n.t('ErrorMessages.SupplierDivisionIsRequired');
      this.setState({ errors });
      return false;
    }

    if (isStringEmpty(data.email)) {
      errors['email'] = I18n.t('ErrorMessages.SupplierEmailIsRequired');
      this.setState({ errors });
      return false;
    }
    else if (!validateEmail(data.email)) {
      errors['email'] = I18n.t('ErrorMessages.SupplierEmailWrongFormat');
      this.setState({ errors });
      return false;
    }

    /*if (isStringEmpty(supplierDetail.phoneNumber)) {
      errors['phoneNumber'] = I18n.t('ErrorMessages.SupplierPhoneNumberIsRequired');
      this.setState({ errors });
      return false;
    }*/

    if (isStringEmpty(data.deliveryDeviation + '')) {
      errors['deliveryDeviation'] = I18n.t('ErrorMessages.SupplierDeliveryDeviationIsRequired');
      this.setState({ errors });
      return false;
    }

    if (isStringEmpty(data.currency)) {
      errors['currency'] = I18n.t('ErrorMessages.SupplierCurrencyIsRequired');
      this.setState({ errors });
      return false;
    }

    if (!data.materials || data.materials.length < 1) {
      errors['material'] = I18n.t('ErrorMessages.SupplierMaterialIsRequired');
      this.setState({ errors });
      return false;
    } else {
      _.each(data.materials, (material, index) => {
        if(!material || _.isEmpty(material)) {
          errors['material'+index] = I18n.t('ErrorMessages.SupplierMaterialIsRequired');
          return false;
        }
        if(!material.color) {
          errors['materialColor'+index] = I18n.t('ErrorMessages.SupplierMaterialColorIsRequired');
          return false;
        }
        // if(!material.size) {
        //   errors['materialSize'+index] = I18n.t('ErrorMessages.SupplierMaterialSizeIsRequired');
        //   return false;
        // }
        if(!material.price) {
          errors['materialPrice'+index] = I18n.t('ErrorMessages.SupplierMaterialPriceIsRequired');
          return false;
        }
        if(material.price < 0) {
          errors['materialPrice'+index] = I18n.t('ErrorMessages.SupplierMaterialPriceIsLargeMoreThan0');
          return false;
        }
      })
    }
    this.setState({ errors });
    return _.isEmpty(errors);
  }

  save() {
    const { supplier, selectedMaterials } = this.props;
    let data = {
      ...supplier,
      materials: selectedMaterials
    }

    if (!this.validateBeforeSave(data))
      return;
    let dataTest = _.map(_.cloneDeep(selectedMaterials), (s) => {s.price = null; s._id = null; s.type = null; s.unit = null; return s});
    let bool = _.isEqual(dataTest , _.uniqWith(dataTest, _.isEqual));
    if(!bool) {
      toastr.error(I18n.t('Notify.Error'), 'Duplicate Material');
      return;
    }

    // const uncompressedMaterials = selectedMaterials;
    data.materials = selectedMaterials;
    // console.log('===before commit===', data, uncompressedMaterials);
    if (data._id) {
      this.props.supplierActions.edit(data._id, data).then(res => {
        if(res.success) {
          toastr.success('Success', 'Edit success!');
          this.props.closeModal();
          this.props.supplierActions.getListMaterial({});
          this.props.supplierPropsActions.getListSupplier({pageSize: 10, pageNumber: 1, sort: {"createdAt":-1}});
        }
        if(!res.success) toastr.error('Error', res.error || 'Data error!');
      });
    } else {
      this.props.supplierActions.save(data).then(res => {
        if(res.success) {
          toastr.success('Success', 'Save success!');
          this.props.closeModal();
          this.props.supplierActions.getListMaterial({});
          this.props.supplierPropsActions.getListSupplier({pageSize: 10, pageNumber: 1, sort: {"createdAt":-1}});
        }
        if(!res.success) toastr.error('Error', res.error || 'Data error!');
      });
    }
  }

  render() {
    let { errors } = this.state;
    const { supplier,
      materials,
      getColors,
      getSizes,
      selectedMaterials } = this.props;
      const materialsData = (materials || []).map((item, idx1) => ({
        value: item._id,
        label: `${item.name}${(item.data && item.data.content) ? (' - ' + item.data.content) : ''}`,
        raw: item
      }));
    // console.log('+++++===+++++', this.state);
    return (
      <div className="modal-dialog lg" id="modal-popup-supplier">
        <div className="modal-content">

          <div className="modal-header">
            <a className="close" onClick={() => this.close()}><span aria-hidden="true">&times;</span></a>
            <h4 className="modal-title">{supplier._id ? I18n.t('PageCrm.EDITSUPPLIER') : I18n.t('PageCrm.ADDSUPPLIER')}</h4>
          </div>

          <div className="modal-body pd-0 inline-block">
            <h4 className="title" >{I18n.t('PageCrm.INFORMATION')}</h4>
            <TextInputValidate value={supplier.name || ''}
              label={I18n.t('PageCrm.Name')}
              property={'name'}
              isRequired = {true}
              placeholder={I18n.t('PageCrm.Name')}
              error={errors['name']}
              onChange={this.onChange} />

            <TextInputValidate value={_.get(supplier, "bank.accountNo", "")}
              label={I18n.t('PageCrm.BankAccountNo')}
              property={'bankAccountNo'}
              placeholder={I18n.t('PageCrm.BankAccountNo')}
              onChange={this.onChange} />

            <TextInputValidate value={supplier.shortName || ''}
              label={I18n.t('PageCrm.ShortName')}
              property={'shortName'}
              isRequired = {true}
              placeholder={I18n.t('PageCrm.ShortName')}
              error={errors['shortName']}
              onChange={this.onChange} />

            <TextInputValidate value={_.get(supplier, "bank.branch", "")}
              label={I18n.t('PageCrm.BankBranch')}
              property={'bankBranch'}
              placeholder={I18n.t('PageCrm.BankBranch')}
              onChange={this.onChange} />

            <TextInputValidate value={_.get(supplier, "company.name", "")}
              label={I18n.t('PageCrm.CompanyName')}
              property={'companyName'}
              placeholder={I18n.t('PageCrm.CompanyName')}
              onChange={this.onChange} />

            <div className="col-md-6 col-xs-12">
              <div className="form-group">
                <div className="col-md-1 col-xs-12 text no-padding-left">
                  &nbsp;
                </div>
                <div className="col-md-11 col-xs-12 value no-padding-right">
                  <ul className="wrap-list-radio-style">
                      <li role="presentation" className="active">
                      <input type="radio" name='location' id={supplier.division + '_domestic'} value='DOMESTIC' checked={supplier.division === 'DOMESTIC'}
                      onChange={evt => this.onChange('type', evt.target.value)} />
                          <label htmlFor={supplier.division + '_domestic'}>{I18n.t('PageCrm.Domestic')}</label>
                          <div className="check"><div className="inside"></div></div>
                      </li>
                      <li role="presentation" className="active">
                      <input type="radio" name='location' id={supplier.division + '_oversea'} value='OVERSEA' checked={supplier.division === 'OVERSEA'}
                        onChange={evt => this.onChange('type', evt.target.value)} />
                          <label htmlFor={supplier.division + '_oversea'}>{I18n.t('PageCrm.Oversea')}</label>
                          <div className="check"><div className="inside"></div></div>
                      </li>
                    </ul>
                  {errors['type'] && <div className="alert-warning">{errors['type']}</div>}
                </div>
              </div>
            </div>

            <TextInputValidate value={_.get(supplier, "company.address", "")}
              label={I18n.t('PageCrm.CompanyAddress')}
              property={'companyAddress'}
              placeholder={I18n.t('PageCrm.CompanyAddress')}
              onChange={this.onChange} />

            <TextInputValidate value={supplier.deliveryDeviation || ''}
              label={I18n.t('PageCrm.DeliveryDeviation')}
              inputType={'number'}
              property={'deliveryDeviation'}
              placeholder={I18n.t('PageCrm.DeliveryDeviation')}
              error={errors['deliveryDeviation']}
              onChange={this.onChange} />

              <div className="col-md-6 col-xs-12">
                <div className="form-group">
                  <div className="col-md-4 col-xs-12 text no-padding-left">
                    <p>{I18n.t('PageCrm.PhoneNumber')}</p>
                  </div>
                  <div className="col-md-8 col-xs-12 value no-padding-right">
                    <ReactTelephoneInput className='w-full'
                      defaultCountry='us'
                      autoFormat={false}
                      flagsImagePath={flagsIcon}
                      initialValue={supplier.phoneNumber || ''}
                      onChange={(phoneNumber) => this.onChange('phoneNumber', phoneNumber)}
                    />
                  </div>
                </div>
              </div>

            <SelectValidate options={PaymentTerms}
              value={supplier.paymentTerm || ''}
              label={I18n.t('PageCrm.PaymentTerm')}
              property={'paymentTerm'}
              placeholder={I18n.t('PageCrm.PaymentTerm')}
              onChange={this.onChange} />

            <TextInputValidate value={supplier.fax || ''}
              label={I18n.t('PageCrm.Fax')}
              property={'fax'}
              placeholder={I18n.t('PageCrm.Fax')}
              onChange={this.onChange} />

            <SelectValidate options={Currencies}
              value={supplier.currency || ''}
              label={I18n.t('PageCrm.Currency')}
              isRequired = {true}
              property={'currency'}
              placeholder={I18n.t('PageCrm.Currency')}
              error={errors['currency']}
              onChange={this.onChange} />
            
            <TextInputValidate value={supplier.email || ''}
              label={I18n.t('PageCrm.Email')}
              property={'email'}
              isRequired = {true}
              placeholder={I18n.t('PageCrm.Email')}
              error={errors['email']}
              onChange={this.onChange} />

            <h4 className="title mg-top-15 relative">
              {I18n.t('PageCrm.MATERIALLIST')}
              <span style={{ float: 'right', position: 'absolute', right: '15px', top: '12px' }}>
                <span className="cursor-pointer"
                  onClick={evt => this.handleAddMaterial()}>
                  {<img src={icAddIcon} alt="" />}
                </span>
              </span>
            </h4>

            <div className="col-md-12">
              <table className="global-table box-shadow-none w-full">
                <tbody>
                  <tr className="bg-white bd-dashed-bottom">
                    <th>{I18n.t('PageCrm.Material')} <span className = "color-red"> *</span></th>
                    <th>{I18n.t('PageCrm.Color')} <span className = "color-red"> *</span></th>
                    <th>{I18n.t('PageCrm.Size')}</th>
                    <th style={{ width: '100px' }}>{I18n.t('PageCrm.Price')} <span className = "color-red"> *</span></th>
                    <th>{I18n.t('PageCrm.Unit')}</th>
                    <th></th>
                  </tr>
                {/* </thead>
                <tbody> */}
                  {selectedMaterials && selectedMaterials.map((material, index) => {
                    let sizes = getSizes(index);
                    let colors = getColors(index);
                    const unit = (((materialsData.find(m => m.value === material.material) || {}).raw || {}).data || {}).unit || '';
                    const materialType = ((materialsData.find(m => m.value === material.material) || {}).raw || {}).type || '';

                    return (
                      <tr key={index}>
                        <td>
                          <SearchSelect
                              className="fabric-combobox m-w-250 w-100-percent"
                              options={materialsData}
                              value={material.material || ""}
                              onChange={evt => this.onChangeMaterial(index, (evt && evt.value) || '')}
                          />
                          {/* <select className="form-control" value={(material.material) || ''}
                            onChange={evt => this.onChangeMaterial(index, evt.target.value)}>
                            <option value="" />
                            {materials && materials.map((item, idx1) =>
                              (<option key={idx1}
                                value={item._id}>{item.name}{(item.data && item.data.content) ? (' - ' + item.data.content) : ''}</option>))}
                          </select> */}
                          {errors['material' + index] && <div className="alert-warning">{errors['material' + index]}</div>}
                        </td>
                        <td>
                          <DropDownMultiSelect key={`drop_color_${index}`} options={colors || []} selectedValues={material.color || []} property={'color'} onChange={(property, value) => { this.onChangeMaterialProperty(index, property, value); }}></DropDownMultiSelect>
                          {/* <select className="form-control" value={(material.color) || ''}
                            onChange={evt => this.onChangeMaterialProperty(index, 'color', evt.target.value)}>
                            <option value="" />
                            {colors && _.map(colors, (item, i) => (<option key={i} value={item}>{item}</option>))}
                          </select> */}
                          {errors['materialColor' + index] && <div className="alert-warning">{errors['materialColor' + index]}</div>}
                        </td>
                        <td>
                          <DropDownMultiSelect disabled={materialType == 'FABRIC'} key={`drop_size_${index}`} options={sizes || []} selectedValues={material.size || []} property={'size'} onChange={(property, value) => { this.onChangeMaterialProperty(index, property, value); }}></DropDownMultiSelect>
                          {/* {<select className="form-control" disabled={materialType == 'FABRIC'} value={(material.size) || ''}
                            onChange={evt => this.onChangeMaterialProperty(index, 'size', evt.target.value)}>
                            <option value="" />
                            {sizes && _.map(sizes, (item, i) => (<option key={i} value={item}>{item}</option>))}
                          </select>
                          } */}
                          {errors['materialSize' + index] && <div className="alert-warning">{errors['materialSize' + index]}</div>}
                        </td>
                        <td>
                          <input type="number" className="form-control"
                            placeholder={I18n.t('PageCrm.Price')}
                            // min = '0'
                            value={material.price || ''}
                            onChange={evt => this.onChangeMaterialProperty(index, 'price', evt.target.value)} />
                          {errors['materialPrice' + index] && <div className="alert-warning">{errors['materialPrice' + index]}</div>}
                        </td>
                        <td>
                          {/* {<span className={'lineHeight40'}>{material.tempSelected ? this.getUnit({material: material.tempSelected}, true) : material.unit}</span>} */}
                          {<span className={'lineHeight40'}>{unit}</span>}
                        </td>
                        <td>
                          <span className="cursor-pointer lineHeight40" onClick={evt => this.handleRemoveMaterial(index)}>{<img src={icXIcon} alt="" />}</span>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
              {errors['material'] && <div className="alert-warning">{errors['material']}</div>}
            </div>
          </div>

          <div className="modal-footer">
            <button type="button" className="bt-black" onClick={() => this.close()}>{I18n.t('Btn.CANCEL')}</button>
            <button type="button" className="bt-orange mg-l-5" onClick={() => this.save()}>{I18n.t('Btn.SAVE')}</button>
          </div>

        </div>

      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  // console.log('VKL state', state);
  return {
    supplier: selector.getSupplier(state),
    materials: selector.getMaterials(state),
    selectedMaterials: selector.getSelectedMaterials(state),
    getSizes: selector.getSizes(state),
    getColors: selector.getColors(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    supplierPropsActions: bindActionCreators(supplierActions, dispatch),
    materialPropsActions: bindActionCreators(materialActions, dispatch),
    propertyPropsActions: bindActionCreators(propertyActions, dispatch),
    supplierActions: bindActionCreators(supplierActionsRft, dispatch),
    dispatch
  };
}

ModalSupplierForm.propTypes = {
  authState: PropTypes.object,
  supplierDetail: PropTypes.object
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalSupplierForm);
