import C from "../../../constants/accounting/receivable/createInvoiceChargeback";
import accountingServices from "../../../services/AccountReceivableService";
import _ from 'lodash';

export const listCustomer = () => dispatch => {
  accountingServices.listCustomers({}).then(
    res => {
      dispatch({ type: C.CHANGE_LIST_CUSTOMER, payload: res.data });
    },
    error => {
      console.log(JSON.stringify(error));
    }
  );
};

export const listBill = (param) => dispatch => {
  accountingServices.listBillsOrContsByCustomer('Air', param.customer, param.chargeBack).then(
    res => {
      dispatch({ type: C.CHANGE_LIST_BILL, payload: res.data });
    },
    error => {
      console.log(JSON.stringify(error));
    }
  );
};

export const changeSelectedCustomer = data => dispatch => {
  dispatch({ type: C.CHANGE_SELECTED_CUSTOMER, payload: data });
  if(data) {
    dispatch({ type: C.RESET_SELECTED_BILL });
    dispatch(listBill({customer: data.value, chargeBack: data.chargeBack || false}))
  }
};

export const changeIssuedDate = value => dispatch => {
  dispatch({ type: C.CHANGE_ISSUED_DATE, payload: value });
};

export const changeSearchText = value => dispatch => {
  dispatch({ type: C.CHANGE_SEARCH_TEXT, payload: value });
};

export const resetData = () => dispatch => {
  dispatch({ type: C.RESET_DATA });
  dispatch({ type: C.RESET_DATA_ALL });
};

export const changeCheckedBill = (data) => dispatch => {
  dispatch({ type: C.CHANGE_SELECTED_BILL, payload: {id: data._id, billNo: data.billNo, checked: data.checked}});
  if(data && data.checked) {
    let p = data.dataRequest;
		p.billNo.push(data.billNo);
    p.cont = p.billNo;
    p.ids = _.get(data, 'dataRequest.billIds', []);
    if (_.indexOf(p.ids, data._id, 0) === -1) {
      data._id && p.ids.push(data._id);
    }
    dispatch(getBillData(p));
  }
};

export const getBillData = (params) => dispatch => {
  accountingServices.getListShipOutByCustomer(params).then(
    res => {
      // to do something
      dispatch({ type: C.MAP_DATA_ON_BILL, payload: res.data });
      dispatch({ type: C.CHANGE_COUNT_NO_ITEM, payload: res.meta.countNo });
    },
    error => {
      console.log(JSON.stringify(error));
    }
  );
};

export const changeNoteBill = (data) => dispatch => {
  dispatch({ type: C.CHANGE_NOTE_BILL , payload: data})
}
// export const changeInvoiceNo = (data) => dispatch => {
//   dispatch({ type: C.CHANGE_INVOICE_NO , payload: data})
// }
export const changeRateItem = (data) => dispatch => {
  dispatch({ type: C.CHANGE_RATE_ITEM , payload: data})
}
export const changeFeeILoginxInUSAItem = (data) => dispatch => {
  dispatch({ type: C.CHANGE_FEE_I_LOGICX_IN_USA_ITEM , payload: data})
}
export const changeDeductItem = (data) => dispatch => {
  dispatch({ type: C.CHANGE_DEDUCT_ITEM , payload: data})
}
export const changePayerItem = (data) => dispatch => {
  dispatch({ type: C.CHANGE_PAYER_ITEM , payload: data})
}
export const changeRateLMItem = (data) => dispatch => {
  dispatch({ type: C.CHANGE_RATE_LM_ITEM , payload: data})
}

//data (billNo, customerPO, itemKey, availableNoList, countNo) [find item (billNo, customerPO, itemKey) - create no(availableNoList, countNo)]
export const createNoItem = (data) => dispatch => {
  if(!data.value) {
    let now = new Date();
    data.value = "LM" + (Number(now.getMonth()) + 1 < 10 ? "0" + (Number(now.getMonth()) + 1) : Number(now.getMonth()) + 1) + now.getFullYear() + "AC-" + (data.countNo < 10 ? "0" + data.countNo : data.countNo);
    data.countNo += 1;
    data.availableNoList.push(data.value);
    dispatch({ type: C.CHANGE_AVAILABLE_NO_LIST , payload: data.availableNoList});
    dispatch({ type: C.CHANGE_COUNT_NO_ITEM , payload: data.countNo});
  }
  dispatch({ type: C.CREATE_NO_ITEM , payload: data});
}

export const changeReasonOrder = (data) => dispatch => {
  dispatch({ type: C.CHANGE_REASON_ORDER , payload: data})
}
export const changeNoteOrder = (data) => dispatch => {
  dispatch({ type: C.CHANGE_NOTE_ORDER , payload: data})
}

export const saveInvoice = data => dispatch => {
  accountingServices.createInvoice(data).then(res => {
    if(res.success) {
        dispatch({type: C.SAVE_SUCCESS, payload: true})
    }
})
};
