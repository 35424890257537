import React, { Component } from 'react';
import _ from 'lodash';

export default class ActionBtn extends Component {
  constructor (props) {
    super(props);
  }

  render () {
    let data = this.props.value;
    let options = this.props.options || {};

    let avatar = data[1] ? (<img src={data[1]} alt="" />)
        : (<span>{_.upperFirst(data[2] ? data[2][0] : '') + _.upperFirst(data[3] ? data[3][0] : '')}</span>);
    let {click} = options;

    return (
      <td className="sorting_1" onClick={() => { click && click(data[4]); }}>
        <ul className="name-images">
          <li className="avatar">
            {avatar}
          </li>
          <li className="name">
            {data[0]}
          </li>
        </ul>
      </td>
    );
  }
}
