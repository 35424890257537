const C = "SHIP_OUT";

export default {
    CHANGE_LIST_SHIP_OUT: `${C}_CHANGE_LIST_SHIP_OUT`,
    CHANGE_SELECTED_FILTER: `${C}_CHANGE_SELECTED_FILTER`,
    CHANGE_SEARCH_VALUE: `${C}_CHANGE_SEARCH_VALUE`,
    CHANGE_PAGING_TOTAL: `${C}_CHANGE_PAGING_TOTAL`,
    CHANGE_PAGING_CURRENT: `${C}_CHANGE_PAGING_CURRENT`,
    DELETE_SUCCESS: `${C}_DELETE_SUCCESS`,
    CHANGE_SEARCH_STATUS: `${C}_CHANGE_SEARCH_STATUS`,
}