import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, Switch, Route, Redirect } from 'react-router-dom';
import * as PATH from '../../../../constants/routeConstants';
import SRC from '../../../../constants/assets';
import FormGroup from '../../forms/FormGroup';
import Input from '../../forms/Input';
import InvoiceInfo from '../PayablePlan/ImpExpContainer/InvoiceInfo';
import SideBar from '../PayablePlan/SideBar';
import ContainerDetail from '../PayablePlan/ImpExpContainer/ContainerDetail';
import VATValuesComponent from '../PayablePlan/ImpExpContainer/VATValues';

class CreatePlanIEContainer extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      canPreview,
      pathname,
      searchText,
      listForwarder,
      invoiceInfo,
      listContainer,
      listContainerDetail,
      mainVAT,
      totalAmount,
      totalAmountINV,
      amountINV,
      vatAmount,
      grandTotalAmount,
      roles,
    } = this.props;
    if (listContainerDetail.length > 0) {
      // console.log(listContainerDetail);
    }
    return (
      <div>
        <div
          style={{
            float: 'right',
            top: '-50px',
            right: '10px',
            position: 'absolute',
          }}>
          {roles.showCreate && (
            <ul className="inline-block">
              <li className="float-left style-icon-save top-7 position-relative">
                <span className="cursor-pointer" onClick={() => this.props.save()}>
                  <i className="fa fa-floppy-o" aria-hidden="true"></i>
                  SAVE
                </span>
              </li>
            </ul>
          )}
        </div>
        <form
          onSubmit={e => {
            e.preventDefault();
            props.onSubmit();
          }}>
          <InvoiceInfo
            items={listForwarder || []}
            onChangeItem={(key, value) => this.props.onChangeItem(key, value)}
            data={invoiceInfo || {}}
            totalAmountINV={totalAmountINV}
            amountINV={amountINV}
            pathname={pathname}
          />
          <div className="content-main-accounting">
            <SideBar
              searchText={searchText}
              placeholder="Search"
              items={listContainer}
              changeChecked={item => this.props.changeChecked(item)}
              changeSearchText={value => this.props.changeSearchText(value)}
            />

            <VATValuesComponent
              data={mainVAT}
              totalAmount={totalAmount + 'VND'}
              vatAmount={vatAmount + 'VND'}
              grandTotalAmount={grandTotalAmount + 'VND'}
              changeVATValues={(key, value) => this.props.changeVATValues(key, value)}
            />
            {/* <!-- End Component --> */}
            {listContainerDetail.map((item, index) => {
              return (
                <ContainerDetail
                  data={item}
                  key={index}
                  changeContainerDetail={id => this.props.changeContainerDetail(id)}
                />
              );
            })}
            <div className="center clearBoth w-full mg-top-15 inline-block">
              <button
                className={canPreview ? 'bt-orange text-uppercase' : 'cursor-text display-none text-uppercase'}
                style={{ marginRight: 10 }}
                onClick={() => this.props.onPreviewScreen()}
                disabled={!canPreview}>
                {'PREVIEW'}
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

CreatePlanIEContainer.propTypes = {};

CreatePlanIEContainer.defaultProps = {};

export default CreatePlanIEContainer;
