const C = 'CUSTOMER_PO.CURRENT.DELETE_CUT_TICKET';

export default {
  SHOW_MODAL: `${C}.SHOW_MODAL`,
  HIDE_MODAL: `${C}.HIDE_MODAL`,

  SUBMIT: `${C}.SUBMIT`,
  CANCEL_SUBMIT: `${C}.CANCEL_SUBMIT`,

  RESET_DATA: `${C}.RESET_DATA`,
};
