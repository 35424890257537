import * as types from '../constants/actionTypes';
import {showLoading, hideLoading} from './commonUseActions';
import departmentServices from '../services/departmentServices';
import organizationServices from '../services/organizationServices';

function getListDepartment (options = {}) {
  return (dispatch) => {
    showLoading(types, dispatch);

    return departmentServices.getListDepartment(options).then((res) => {
      dispatch({
        type: types.DEPARTMENT.GET_LIST_SUCCESS,
        data: res.data || []
      });
      hideLoading(types, dispatch);
      return res;
    }, (error) => {
      hideLoading(types, dispatch);
    });
  };
}

function searchByContent(options, pageNumber, pageSize) {
  return(dispatch) =>{
    showLoading(types, dispatch);
    return organizationServices.getListOrganizationId(options).then((res) => {
      hideLoading(types, dispatch);
      if(res && res.success) {
        let options = {
          pageSize: pageSize,
          pageNumber: pageNumber,
          sort: {updatedAt: -1},
          populate: 'organizationId',
          query: {
            organizationId: {
              $in: res.data.items.reduce((arr, item) => arr.concat(item._id || []), [])
            }
          }
        };
        return getListDepartment({...options})(dispatch).then(res => {
          if(res && res.success && res.data && res.data.items && !res.data.items.length && pageNumber > 1) {
            _.set(options, "pageNumber", 1);
            getListDepartment(options)(dispatch);
            pageNumber = 1;
          }
          return (_.set(res, "pageNumber", pageNumber));
        });
      }
    }, (error) => {
      hideLoading(types, dispatch, error);
    })
  }
}


function editDepartment (id, data) {
  return (dispatch) => {
    showLoading(types, dispatch);

    return departmentServices.editDepartment(id, data).then((res) => {
      hideLoading(types, dispatch);
      if (res) {
        dispatch({
          type: types.DEPARTMENT.RELOAD,
          data: true
        });
      }
      return {success: true}
    }, (error) => {
      hideLoading(types, dispatch);
      return error
    });
  };
}

function addDepartment (data) {
  return (dispatch) => {
    showLoading(types, dispatch);

    return departmentServices.addDepartment(data).then((res) => {
      hideLoading(types, dispatch);
      if (res) {
        dispatch({
          type: types.DEPARTMENT.RELOAD,
          data: true
        });
      }
      return {success: true}
    }, (error) => {
      hideLoading(types, dispatch);
      return error
    });
  };
}


function deleteDepartment (id) {
  return (dispatch) => {
    showLoading(types, dispatch);

    departmentServices.deleteDepartment(id).then((res) => {
      hideLoading(types, dispatch);
      if (res) {
        dispatch({
          type: types.DEPARTMENT.RELOAD,
          data: true
        });
      }
    }, (error) => {
      hideLoading(types, dispatch, error);
    });
  };
}

export default {
  getListDepartment,
  searchByContent,
  editDepartment,
  addDepartment,
  deleteDepartment
};
