/**
 * @author hienhc <hienhc@nexlesoft.com>
 * @create date 2018-08-30 02:16:10
*/
import { createSelector } from 'reselect';

/**
 * Get a list of filters that can be use to filter pkl productions
 */
export const getListFilters = () =>  [
    // {feild: 'IH', name: 'ih',type: 'date'},
    {field: 'customerPO', name: 'PageShiping.CustomerPO',type: 'text'}, 
    {field: 'customer', name: 'PageShiping.Customer',type: 'text'},
    {field: 'contractor', name: 'PageShiping.Contractor',type: 'text'},
    {field: 'style', name: 'PageShiping.Style',type: 'text'}, 
    {field: 'cutTicket', name: 'PageShiping.CutTicket',type: 'text'},
    {field: 'store', name: 'PageShiping.Store',type: 'text'}, 
    {field: 'color', name: 'PageShiping.Color',type: 'text'},
]

/**
 * Get selected filter
 */
export const getSelectedFilter = createSelector(
    [getListFilters, ({ previewSelectedPklProdList }) => previewSelectedPklProdList.filter],
    (filters, selectedFilter) => {
        if (!selectedFilter) return filters[0].field;
        return selectedFilter;
    }
)

export const getSearchText = ({ previewSelectedPklProdList }) => previewSelectedPklProdList.searchText;

export const isSearching = ({ previewSelectedPklProdList }) => previewSelectedPklProdList.searching;

export const isFetching = ({ previewSelectedPklProdList }) => previewSelectedPklProdList.fetching;

export const getItemList = ({ previewSelectedPklProdList }) => previewSelectedPklProdList.itemList;

export const getCurrentPage = ({ previewSelectedPklProdList }) => previewSelectedPklProdList.currentPage;

export const getTotalPages = ({ previewSelectedPklProdList }) => previewSelectedPklProdList.totalPages;

export const isExporting = ({ previewSelectedPklProdList }) => previewSelectedPklProdList.exporting;

export const getIdOfPackings = ({ showPklProdList }) => {
    let ids = [];
    showPklProdList.checkedList.map(item => ids.push(item._id))
    return ids
}

export const getSearched = ({previewSelectedPklProdList}) => previewSelectedPklProdList.searched;

export const getSearchQuery = (searched, option, listIds, selectedFilter, searchValue) => {
    if(!searched) {
        return {...option, data: listIds};
    }
    if(selectedFilter && searchValue) {
        let op = {...option, data: listIds};
        let key = selectedFilter;
        if(selectedFilter == 'ih') {
            let begin = moment(searchValue.from).format('YYYY-MM-DD');
            let end = moment(searchValue.to).format('YYYY-MM-DD');
            let date = `${begin}:${end}`;
            op[key] = date
        } else {
            op[key] = searchValue.value
        }
        return op;
    }   
}