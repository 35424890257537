import React from 'react';
import Modal from 'react-modal';

const CustomModal = props => (
  <Modal {...props}>
    {props.children}
  </Modal>
);

CustomModal.defaultProps = {
  style: {
    overlay: {
      position: 'fixed',
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
      zIndex: '3'
    },
    content: {
      position: 'auto',
      top: '50%',
      left: '50%',
      backgroundColor: 'rgba(0,0,0,0)',
      border: '0px',
      borderRadius: '0px',
      outline: 'none',
    },
  }
}

export default CustomModal;
