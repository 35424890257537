import * as types from '../constants/actionTypes';
import {showLoading, hideLoading} from './commonUseActions';
import contractorServices from '../services/contractorServices';

function getListItem (options = {}) {
  return (dispatch) => {
    showLoading(types, dispatch);

    return contractorServices.getListItem(options).then((res) => {
      hideLoading(types, dispatch);
      if (res) {
        dispatch({
          type: types.CONTRACTOR.GET_LIST_SUCCESS,
          data: res.data || []
        });
      }
      return res;
    }, (error) => {
      hideLoading(types, dispatch);
    });
  };
}

function getDetail (id, reset = true) {
  return (dispatch) => {
    showLoading(types, dispatch);

    contractorServices.getDetail(id, {}).then((res) => {
      hideLoading(types, dispatch);
      if (res) {
        dispatch({
          type: types.CONTRACTOR.GET_DETAIL_SUCCESS,
          data: res.data || {}
        });
      }
    }, (error) => {
      hideLoading(types, dispatch);
    });
  };
}

function deleteItem (id) {
  return (dispatch) => {
    showLoading(types, dispatch);

    contractorServices.deleteItem(id).then((res) => {
      hideLoading(types, dispatch);
      if (res) {
        dispatch({
          type: types.CONTRACTOR.RELOAD,
          data: true
        });
      }
    }, (error) => {
      hideLoading(types, dispatch, error);
    });
  };
}

function editItem (id, data) {
  return (dispatch) => {
    showLoading(types, dispatch);

    return contractorServices.editItem(id, data).then((res) => {
      if (res) {
        hideLoading(types, dispatch);
        dispatch({
          type: types.CONTRACTOR.RELOAD,
          data: true
        });
      }
      return {success: true};
    }, (error) => {
      hideLoading(types, dispatch);
      return error;
    });
  };
}

function addItem (data) {
  return (dispatch) => {
    showLoading(types, dispatch);

    return contractorServices.addItem(data).then((res) => {
      if (res) {
        hideLoading(types, dispatch);
        dispatch({
          type: types.CONTRACTOR.RELOAD,
          data: true
        });
      }
      return {success: true};
    }, (error) => {
      hideLoading(types, dispatch);
      return error;
    });
  };
}

export default {
  getListItem,
  deleteItem,
  editItem,
  addItem,
  getDetail
};
