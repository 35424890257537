import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import BaseComponent from '../../base/BaseComponent';
import SearchBar from '../../common/layout/SearchBar';
import ModalAssignmentForm from '../../common/modals/ModalAssignmentForm';
import { exportCurContractor } from '../../../api/assignmentApi';
import ModalConfirm from '../../common/modals/ModalConfirm';
import assignmentActions from '../../../actions/assignmentActions';
import {Pagination} from 'react-bootstrap';
import TableList from '../../common/datatables/TableList';
import _ from 'lodash';
import moment from 'moment';
import I18n from 'i18n-js';
import {PRODUCTION_TAB, SEARCH_LIST, SEARCH_BAR_EVENT, SEARCH_KEY} from "../../../constants/constants";
var FileSaver = require('file-saver');

import icAddIcon from '../../../stylesheets/images/ic-add.svg';
import icExportIcon from '../../../stylesheets/images/ic-export.svg';
import CustomPagination from '../../common/datatables/CustomPagination';

class ScreenAssignment extends BaseComponent {
  constructor (props, context) {
    super(props, context);

    this.state = {
      openForm: false,
      dataForm: {},

      pageSize: 10,
      pageNumber: 1,
      columns: [
        {name: I18n.t('PageProduction.CustomerPO'), value: 'order.customerPO', options: {clsTh: 'center', clsTd: 'center'}},
        {name: I18n.t('PageProduction.Style'), getValue: (i) => (i.styles || []), type: 'dropdownstyle', options: {clsTh: 'center', clsTd: 'center color-green'}},
        {name: I18n.t('PageProduction.CutTicket'), getValue: (i) => (i.styles || []), type: 'dropdowncutticket', options: {clsTh: 'center', clsTd: 'center color-green'}},
        {name: I18n.t('PageProduction.Contractor'), value: 'contractor.name', options: {clsTh: 'center', clsTd: 'center'}},
        {name: I18n.t('PageProduction.AssignmentDate'), value: 'date', type: 'date', formatType: 'MMM DD, YYYY', options: {clsTh: 'center', clsTd: 'center'}},
        {name: I18n.t('PageProduction.DueDate'), value: 'deadline', type: 'date', formatType: 'MMM DD, YYYY', options: {clsTh: 'center', clsTd: 'center'}},
        {name: I18n.t('PageProduction.QTY'), getValue: (i) =>
          (i.styles || []).reduce((qty, style) => {
            return (style.cutTickets || []).reduce((qty, cutTicket) => {
              return qty += parseInt((cutTicket && cutTicket.gTotal) || 0);
            }, qty)}, 0)
        },
        {name: I18n.t('PageProduction.Status'), getValue: (i) => _.startCase(_.lowerCase(i.status))}
      ],
      actions: [],
      items: [],
      total: 0,
      searchFields: SEARCH_LIST.ASIGNMENTS,
      search: {key: SEARCH_LIST.ASIGNMENTS[0].field, value: ""}
    };

  }

  getBaseOption = () => {
    let {pageSize, pageNumber} = this.state;
    return {
      pageSize, 
      pageNumber, 
      sort: {createdAt: -1},
      populate: 'orderId,contractorId,styles.style,styles.cutTickets'
    }
  }

  componentWillMount = () => {
    this.getListItem(this.getBaseOption());
  }

  componentWillReceiveProps = newProps => {
    let {items} = this.state;
    !_.isEqual(items, newProps.assignmentState.items) && this.setState({
      items: _.cloneDeep(newProps.assignmentState.items) || [],
      total: newProps.assignmentState.count || 0
    });
    this.props.tabCurrent !== newProps.tabCurrent;
  }

  componentWillUpdate = (nextProps, nextState) => {
    if (_.get(this.props.assignmentState, 'reload') != _.get(nextProps.assignmentState, 'reload')) { 
      this.onSearch();
      this.props.modal.closeModal();
    }
  }

  getListItem = options => {
    this.props.assignmentPropsActions.getListItem(options);
  }

  selectPage = pageNumber => {
    const {search} = this.state;
    this.setState({pageNumber});
    let options = this.getBaseOption()

    this.search(search, {...options, pageNumber});
  }

  addItem = () => {
    const {openModal, closeModal} = this.props.modal;
    let modal = (<ModalAssignmentForm closeModal={closeModal}/>);
    openModal(modal);
  }

  showDeleteConfirm = item => {
    let {openModal, closeModal} = this.props.modal;
    let modal = (<ModalConfirm
      title={I18n.t('PageProduction.DELETEASSIGNMENT')}
      confirmMessage={I18n.t('PageProduction.ConfirmDeleteAssignment')}
      messageOk={I18n.t('Btn.YES')}
      messageCancel={I18n.t('Btn.CANCEL')}
      handleClose={closeModal}
      handleOk={() => this.onDeleteAssignment(item)}/>);
    openModal(modal);
  }

  onDeleteAssignment = item => {
    item._id && this.props.assignmentPropsActions.deleteItem(item._id);
  }


  editItem = item => {
    let {openModal, closeModal} = this.props.modal;
    let modal = (<ModalAssignmentForm closeModal={closeModal} assignmentDetail={item} />);
    openModal(modal);
  }

  onChangeSearch = (property, value) => {
    switch(property) {
      case SEARCH_BAR_EVENT.FILTER: {
        const search = _.get(this, 'state.search', '')
        if((value == SEARCH_KEY.DATE || value == SEARCH_KEY.STYLE || value == SEARCH_KEY.DATELINE) && (search.key == SEARCH_KEY.DATE || search.key == SEARCH_KEY.DATELINE) )
          this.setState({search: {...search, key: value}});
        else this.setState({search: {key: value, value: ""}});
        break;
      }
      case SEARCH_BAR_EVENT.SEARCH: {
        this.setState({search: {...this.state.search, value: value}});
        break;
      }
    }
  }

  onSearch = () => {
    const {search} = this.state;
    let options = this.getBaseOption();
    // options.pageNumber = 1;
    this.search(search, options);
  }

  search = (search, options) => {
    if(search && search.key && search.value !== '' && search.value !== null && search.value !== "Invalid date") {
        let qr = {}
        switch(search.key) {
          case SEARCH_KEY.DATE: {
              let db = new Date(search.value)
              db.setHours(0,0,0,0)

              let de = _.clone(db)
              de.setDate(de.getDate() + 1);
              const qr = {date: {$gte: db, $lt: de}}
              options.query = {...options.query, ...qr}
              break;
          }
          case SEARCH_KEY.DATELINE: {
            let db = new Date(search.value)
            db.setHours(0,0,0,0)

            let de = _.clone(db)
            de.setDate(de.getDate() + 1);
            const qr = {deadline: {$gte: db, $lt: de}}
            options.query = {...options.query, ...qr}
            break;
          }
          case SEARCH_KEY.STATUS: {
            qr = {status: {$regex: '.*' + search.value + '.*', $options: 'i'}}
            options.query = {...options.query, ...qr}
            break;
          }
          case SEARCH_KEY.STYLE: {
              qr = {'styles.styleName': {$regex: '.*' + search.value + '.*', $options: 'i'}}
              options.query = {...options.query, ...qr}
              break;
          }
        }

    }
    this.props.assignmentPropsActions.search({search, options}).then(res => {
      if(res && res.pageNumber && res.pageNumber == 1) {
        this.setState({pageNumber: 1});
      }
    });
  }

  onExport = () => {
    const exportLink = exportCurContractor();
    FileSaver.saveAs(exportLink, "Assignment_Contractor.xlsx");
  }

  render = () => {
    const {tabCurrent, roles: {showCreate, showEdit, showDelete, showView}} = this.props;
    let {openForm, dataForm, searchFields, searchValue} = this.state;
    let {items, total, columns, actions, pageNumber, pageSize} = this.state;
    let totalPage = _.ceil(total / pageSize);
    actions = [];
    if(showEdit){
      actions.push((item) => {
        return {type: 'edit', click: this.editItem};
      })
    }
    if(showDelete){
      actions.push((item) => {
        return {type: 'delete', click: this.showDeleteConfirm};
      })
    }
    
    let w100 = {
      width: '100%'
    };
    return (
      <div className={(!tabCurrent || tabCurrent === PRODUCTION_TAB.ASSIGNMENT) ? 'show' : 'hide'}>
        <div>
          <span className="export cursor-pointer" onClick={this.onExport} style={{display: 'inline-block', float: 'right', marginTop: 15, fontSize: 14, position: 'relative'}}>
              Export <img src={icExportIcon} alt="" style={{position: 'relative', top: 5}}/>
          </span>
          {showCreate && (<div className="wrap-stock-in">
            <span className="cursor-pointer" onClick={this.addItem} ><img src={icAddIcon}/> {I18n.t('PageProduction.ADDASSIGNMENT')}</span>
          </div>)}
          <SearchBar fields={searchFields} onChangeSearch={this.onChangeSearch} onSearch={this.onSearch}/>
        </div>
        <div className="wrap-global-table">
          <TableList items={items || []} columns={columns || []} actions={actions || []} options={{clsTbl: 'global-table'}}/>
          <CustomPagination items={totalPage} activePage={pageNumber} onSelect={this.selectPage} />

        </div>
      </div>
    );
  }
}

function mapStateToProps (state, ownProps) {
  return {
    appState: state.appState,
    authState: state.authState,
    assignmentState: state.assignmentState
  };
}

function mapDispatchToProps (dispatch) {
  return {
    assignmentPropsActions: bindActionCreators(assignmentActions, dispatch)
  };
}

ScreenAssignment.propTypes = {
  authState: PropTypes.object.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(ScreenAssignment);
