import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import I18n from 'i18n-js';
import CustomerPOForm from '../modals/ModalCustomerPOForm';
import ModalConfirm from '../modals/ModalConfirm';
import {textTrim} from '../../../functions/helpers';
import _ from 'lodash';

import icAddOrderIcon from '../../../stylesheets/images/ic-add-order.svg';
import icEditXIcon from '../../../stylesheets/images/ic-edit-x.svg';
import icCursorPointerIcon from '../../../stylesheets/images/ic-cursor-pointer.svg';
import loadingIcon from '../../../stylesheets/images/loading.svg';
class SidebarOrders extends Component {
  constructor (props) {
    super(props);
    this.state = {
      search: '',
      origin: [],
      orders: props.orders || [],
      allOrders: [],
    };
    this.selectItem = this.selectItem.bind(this);
    this.onCanceledOrder = this.onCanceledOrder.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if(!_.isEqual(this.props.orders, nextProps.orders)) {
      this.onSubmitSearch(nextProps);
    }
  }

  onChangeSearch = (search) => {
    this.setState({search});
    // this.props.onChangeSearchValue(search);
  }

  onSubmitSearch (props) {
    let orders = props ? [...props.orders] : [...this.props.orders];
    const  {search}  = this.state;
    if(search) {
      orders = (orders || []).filter(x => (
        _.get(x, 'customerPO', '').toLowerCase().includes(search.toLowerCase())
        || _.get(x, 'customer.name', '').toLowerCase().includes(search.toLowerCase())
        )
      );
    }
    this.setState({orders});
  }

  showUpdateForm(id) {
    let {openModal, closeModal} = this.props.modal;
    let modal = (<CustomerPOForm closeModal={closeModal}
                                 orderId={id}
                                 onSave={this.selectItem}
                                 note={true}
                                 validateExistOrder={(validateOrder)=>this.validateExistOrder(validateOrder)}/>);
    openModal(modal);
  }

  showAddForm() {
    let {openModal, closeModal} = this.props.modal;
    let modal = (<CustomerPOForm closeModal={closeModal}
                                 onSave={this.selectItem}
                                 note={false}
                                 validateExistOrder={(validateOrder)=>this.validateExistOrder(validateOrder)}/>);
    openModal(modal);
  }

  validateExistOrder(validateOrder){
    if (this.state.allOrders && this.state.allOrders.length){
      return this.state.allOrders.findIndex(order => (order.customerPO.toLowerCase() === validateOrder.customerPO.toLowerCase()) && order._id !== validateOrder._id) >= 0;
    }
    return false;
  }

  showDeleteConfirm(item) {
    let {openModal, closeModal} = this.props.modal;
    let modal = (<ModalConfirm
      title={I18n.t('PageOrder.CanceledStyle')}
      confirmMessage={I18n.t('PageOrder.ConfirmCanceled')}
      messageOk={I18n.t('Btn.YES')}
      messageCancel={I18n.t('Btn.CANCEL')}
      handleClose={closeModal}
      handleOk={this.onCanceledOrder.bind(null, item)}/>);
    openModal(modal);
  }

  onCanceledOrder(item) {
    item && item._id && this.props.canceledOrder(item._id);
    if (this.props.modal) {
      this.props.modal.closeModal();
    }
  }

  selectItem (item) {
    item = item || {};
    this.setState({selectedOrder: item});
    this.props.handleSelectedOrder(item);
  }

  componentDidUpdate() {
    this.scrollBar();
  }

  scrollBar() {
    let {componentName} = this.props;
    componentName = componentName ? `.${componentName}` : ''
    if ($(`.content-sidebar-menu${componentName}`).height() < $(`.content-sidebar-menu${componentName} > ul`).height()) {
      $(`.content-sidebar-menu${componentName}`).addClass('scrollbar');
    } else {
      $(`.content-sidebar-menu${componentName}`).removeClass('scrollbar');
    }
  }
  
  render () {
    let {search} = this.state;
    let {componentName} = this.props;
    componentName = componentName || '';
    let {orders} = _.cloneDeep(this.state);
    orders = search ? _.cloneDeep(orders).filter(item => {
      return item.customerPO.toLowerCase().search(search.toLowerCase()) > -1 || _.get(item, 'customer.name', '').toLowerCase().search(search.toLowerCase()) > -1 }) : orders;
    const {selectedOrder} = this.props;
    let {wrapClass, showCreate, showEdit, showDelete, isSuperAdmin} = this.props;
    wrapClass += ' content-sidebar clearfix';
    return (
      <div className={wrapClass}>
        {(showCreate || isSuperAdmin) &&
          <div className="md content-sidebar-add-order">
            <span className="cursor-pointer" onClick={() => this.showAddForm()} ><img src={icAddOrderIcon} /> {I18n.t('PageOrder.AddOrder')}</span>
          </div>
        }
        <div className="md content-sidebar-search" >
          <input type="text" value={this.props.searchText || ''}
            onChange={evt => this.props.onChangeSearch(evt.target.value)}
            onKeyPress={evt => evt.key === 'Enter' && this.props.onSubmitSearch()}
            placeholder="Search" />
          <span onClick={() => this.props.onSubmitSearch()}><i className="fa fa-search cursor-pointer" aria-hidden="true" /></span>
        </div>
        <div className={`md content-sidebar-menu ${componentName}`}>
          <ul>
            {(this.props.items || []).map((order, idx) => (
              <li key={idx}
                  className={order._id == selectedOrder._id ? 'active' : ''}
                  title={`${order.customerPO} - ${_.get(order, 'customer.name', '')}`}
                  onClick={() => this.selectItem(order)}>
                {textTrim(order.customerPO, 8)} &nbsp; - &nbsp; {textTrim(_.get(order, 'customer.name', ''), 6)}
                {/* {order._id == selectedOrder._id && (<i className="fa fa-caret-right" aria-hidden="true" />)} */}

                <span className="line-column"><span/></span>
                <div className="icon-edit-delete">
                  {(showEdit || isSuperAdmin)&&
                    <span className="cursor-pointer" onClick={() => this.showUpdateForm(order._id)}>
                      <img src={icEditXIcon} alt="" />
                    </span>
                  }
                  {(showDelete || isSuperAdmin) &&
                    <span className="cursor-pointer" onClick={() => this.showDeleteConfirm(order)}>
                      <img src={icCursorPointerIcon} alt="" />
                    </span>
                  }
                </div>
              </li>
            ))}
            {(this.props.isLoading == true) &&
              <li className="loading-icon"><img src={loadingIcon} /></li>
            }
            {(this.props.isLoadMore == true && this.props.isLoading == false)  &&
                <li className="button-load-more"><span onClick={() => this.props.handleLoadMore(this.props.pageNumber + 1)}>Load more</span></li>
            }
          </ul>
        </div>
      </div>
    );
  }
}

SidebarOrders.propTypes = {
  orderState: PropTypes.object,
  modal: PropTypes.object,
  orders: PropTypes.array,
  handleSelectedOrder: PropTypes.func.isRequired,
  showCreate: PropTypes.bool,
  showEdit: PropTypes.bool,
  showDelete: PropTypes.bool,
  isSuperAdmin: PropTypes.bool,
  wrapClass: PropTypes.string,
  selectedOrder: PropTypes.object,
  deleteOrder: PropTypes.func,
  pageNumber: PropTypes.number.isRequired,
};

SidebarOrders.defaultProps = {
  onSubmitSearch: f => f,
  handleLoadMore: f => f,
}

function mapStateToProps (state) {
  return {
    authState: state.authState
  };
}

function mapDispatchToProps (dispatch) {
  return {
    dispatch
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SidebarOrders);
