import _ from 'lodash';
import moment from 'moment';

export const getTotal = ({ editShipment }) => {
  const sum = editShipment.listOrders
    ? _.sum(editShipment.listOrders.map(item => _.sum(item.styles.map(style => style.quantity || 0))))
    : 0;
  return sum;
};

export const getShipMode = ({ editShipment }) => {
  if (editShipment.standard) {
    let temp = [];
    _.map(editShipment.standard.shipping, (v, k) => {
      if (k === 'other') {
        v.map(item => temp.findIndex(it => it == item.shipMod) == -1 && temp.push(item.shipMod));
        return;
      }
      temp.findIndex(it => it == k) == -1 && temp.push(_.toUpper(k));
    });
    return temp;
  }
};

export const getShipmentInfo = ({ editShipment }) => {
  if (editShipment.shipmentDetail) {
    const data = editShipment.shipmentDetail;
    return {
      isCompleted: data.isCompleted || false,
      customInv: data.customInv || '',
      date: data.date || '',
      cont: data.cont || '',
      seal: data.seal || '',
      contType: data.contType || '',
      from: data.from || '',
      to: data.to || '',
      shipMode: data.shipMode || '',
      shipAgencies: data.shipAgencies || '',
      transportNo: data.transportNo || 0,
      //col2
      deliveryTerm: data.deliveryTerm || '',
      etd: data.etd || '',
      eta: data.eta || '',
      dateDelivered: data.dateDelivered,
      declarationNo: data.declarationNo || 0,
      declarationDate: data.declarationDate || '',
      qtyPkg: data.qtyPkg || 0,
      dropOffCont: data.dropOffCont || '',
      warehouseAddress: data.warehouseAddress || '',
      pickupCont: data.pickupCont || '',
      //col3
      gw: data.gw || 0,
      kgs: data.kgs || 0,
      cbm: data.cbm || 0,
      forwarder: data.forwarder || '',
      advance: data.advance || 0,
      advanceNo: data.advanceNo || '',
      advancdDate: data.advancdDate || '',
      billNo: data.billNo || '',
      borderGate: data.borderGate || '',
      truckNo: data.truckNo || '',
      route: data.route || '',
    };
  } else return {};
};

export const getContType = ({ editShipment }) => editShipment.standard && editShipment.standard.containerType;

export const fnGetShipAgencies = ({ editShipment }) => shipMode => {
  if (editShipment.standard) {
    const key = _.toLower(shipMode);
    if (key == 'other') {
      const result = _.map(editShipment.standard.shipping[key], item => {
        return `${item.shipMod} - ${item.shipAgency}`;
      });
      return result;
    } else return editShipment.standard.shipping[key];
  }
};

export const getTransportFee = ({ editShipment }) => {
  if (editShipment.shipmentDetail) {
    const data = editShipment.shipmentDetail;
    return {
      crsPaperwork: data.crsPaperwork || 0,
      localCharge: data.localCharge || 0,
      trunkingOut: data.trunkingOut || 0,
      note: data.note || '',
    };
  }
};

export const getCustomerINV = ({ editShipment }) => {
  if (editShipment.shipmentDetail) {
    return editShipment.shipmentDetail.pklShips.map(item => ({
      customerInv: item.pklShip.invoice.customerInv,
      pklShipId: item.pklShip._id,
    }));
  }
};

export const fnGetPklShipDetail = ({ editShipment }) => id => {
  if (editShipment.pklShips) {
    const result = editShipment.pklShips.find(item => item._id === id);
    return result;
  }
};

export const getOrders = ({ editShipment }) => {
  const { activeOrders, listOrders } = editShipment;
  const orders =
    listOrders &&
    listOrders.map(it => {
      const _id = it._id;
      const { customerPO } = it;
      const result = {
        _id,
        name: customerPO,
        active: activeOrders.findIndex(item => item == _id) >= 0,
        commodity: it.commodity,
      };
      return result;
    });
  return orders;
};

export const fnGetStyles = ({ editShipment }) => id => {
  const { activeStyles, listOrders } = editShipment;
  const styles = [];
  listOrders &&
    listOrders.map(it => {
      if (it._id === id) {
        it.styles.map(s => {
          const { _id, style, quantity } = s;
          const result = { _id, name: style || '', active: activeStyles[id] == _id, quantity };
          styles.push(result);
        });
      }
    });
  return styles;
};

export const getActiveStyleIds = ({ editShipment }) => editShipment.activeStyles;

export const fnGetListData = ({ editShipment }) => (orderId, styleId) => {
  if (editShipment.dataInputs) {
    const data = editShipment.dataInputs[`${orderId}:${styleId}`];
    return data;
  }
};

export const fnGenApiData = ({ editShipment }) => {
  const { dataInputs, shipmentDetail } = editShipment;
  if (dataInputs && shipmentDetail) {
    const data = _.cloneDeep(shipmentDetail);
    data.pklShips.map(pklShip => {
      pklShip.pklShip = pklShip.pklShip._id;
      pklShip.shipOutInvoices.map(item => {
        item.styles.map(style => {
          style.cutTickets.map(cut => {
            cut.colors.map(color => {
              const data =
                (dataInputs[`${item._id}:${style.style._id}`] || []).find(
                  it => it.cutTicketId == cut.cutTicket._id && it.colorId == color.color._id
                ) || {};
              color.unitPrice = data.unitPrice;
            });
          });
        });
      });
    });
    return data;
  }
};

export const getSuccess = ({ editShipment }) => editShipment.success;

export const getListForwarder = ({ editShipment }) => {
  if (editShipment.listForwarder) {
    return editShipment.listForwarder.map(item => ({ value: item._id, label: item.forwarder }));
  }
};

export const getEmptyField = data => {
  if (data) {
    if (data.customInv == '') return 'customINV';
    if (data.shipMode == '') return 'shipMode';
    if (data.shipMode == 'SEA' && data.cont == '') return 'cont';
    if (data.shipMode == 'SEA' && data.seal == '') return 'seal';
    if (data.shipMode == 'SEA' && data.contType == '') return 'contType';
    if (data.declarationNo == '') return 'declarationNo';
    if (data.forwarder == '') return 'forwarder';
    if (data.billNo == '') return 'billNo';
    // if (!moment(data.dateDelivered).isValid()) return 'dateDelivered';
  }
  return '';
};

export const getActiveScreenEditShipment = ({ editShipment }) => editShipment.activeScreenEditShipment;
