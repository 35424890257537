/**
 * @author hienhc
 * @email hienhc@nexlesoft.com
 * @create date 2018-06-16 11:57:03
 * @modify date 2018-06-16 11:57:03
 * @desc [description]
*/

import React, { Component } from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import Select from 'react-select';
import 'react-select/dist/react-select.css';

class QuickSearchSelect extends Component {
  componentWillReceiveProps(nextProps) {
    const oldItems = (this.props.items || []).map(it => it.value);
    const newItems = (nextProps.items || []);
    const difItems = newItems.filter(it => !oldItems.includes(it.value));
    if (difItems.length === 1) {
      const [item] = difItems;
      nextProps.onChange(item.value);
    }
  }

  render() {
    const { items = [], value, disabled } = this.props;
    const { isLoading, isSearchBackend, isValid } = this.props;

    const customProps = (isSearchBackend) ?
      {
        filterOptions: options => options,
        onInputChange: f => this.props.onInputChange(f),
      } :
      {};
    let style = {};
    if (isValid !== null) {
      const color = isValid ? '#3c763d' : '#a94442';
      style = { borderColor: color };
    }

    return (
      <Select
        style={style}
        options={items}
        value={value}
        onChange={(o) => {
          this.props.onChange(R.path(['value'], o));
        }}
        {...customProps}
        isLoading={isLoading}
        disabled={disabled}
        clearable
      />
    );
  }
};

QuickSearchSelect.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any),
  value: PropTypes.string,
  isValid: PropTypes.bool,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  isSearchBackend: PropTypes.bool,

  onInputChange: PropTypes.func,
  onChange: PropTypes.func,
};

QuickSearchSelect.defaultProps = {
  items: [],
  value: null,
  disabled: false,
  isValid: null,
  isLoading: false,
  isSearchBackend: false,

  onInputChange: f => f,
  onChange: f => f,
};

export default QuickSearchSelect;
