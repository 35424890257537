import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import I18n from 'i18n-js';

import ModalConfirm from '../../../components/forms/ModalConfirm';

import * as selectors from '../../../../selectors/customer-po/current/deleteStyle';
import * as actions from '../../../../actions/customer-po/current/deleteStyle';

const DeleteStyleModal = props => (
  <ModalConfirm
    {...props}
    onConfirmClick={() => props.deleteStyle(props.style)}
  />
);

const mapStateToProps = state => ({
  isOpen: selectors.isOpen(state),
  title: I18n.t('PageOrder.DELETESTYLE'),
  confirmMessage: selectors.getDescriptionMessage(state),
  cancelLabel: I18n.t('Btn.CANCEL'),
  okLabel: selectors.getLabelButtonAccept(state),
  style: selectors.getStyle(state),
});

const mapDispatchToProps = (dispatch) => ({
  onCloseClick() {
    dispatch(actions.hideModal())
  },

  deleteStyle(style) {
    dispatch(actions.deleteStyle(style));
  },
});

export default connect(mapStateToProps, mapDispatchToProps) (withRouter(DeleteStyleModal));
