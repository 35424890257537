
import _ from 'lodash';
import * as R from 'ramda';

export const isShowing = ({ createStyle }) => !!createStyle.orderId;

export const getOrderId = ({ createStyle }) => createStyle.orderId;

export const isSubmiting = ({ createStyle }) => createStyle.submiting;

export const getImages = ({ createStyle }) => {
  const { images } = createStyle;
  return _.map(images, img => ({
    id: R.path(['id'], img),
    url: R.path(['url'], img),
    raw: img,
  }));
}

export const getStyle = ({ createStyle }) => createStyle.style;

export const getPage = ({ createStyle }) => createStyle.page;

export const getErrors = ({ createStyle }) => createStyle.errors;

export const canSave = ({ createStyle }) => {
  const { errors, style, submiting } = createStyle;
  if (submiting) return false;
  return !!_.trimEnd(style) && !R.path(['style'], errors);
    // && !!_.trimEnd(page)
    // && !R.path(['page'], errors);
};

export const fnGenerateBody = ({ createStyle }) => () => {
  const { style, page, images, orderId } = createStyle;
  return {
    style,
    page,
    images: _.map(images, img => ({ url: img.url, file: img.file })),
  };
};
