import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import authActions from '../../actions/authActions';
import { getAccessToken } from '../../functions/helpers';
import _ from 'lodash';
import I18n from 'i18n-js';
import { FORGOT_PASSWORD_URL } from '../../constants/routeConstants';
import logoIcon from '../../stylesheets/images/logo.svg';
import loadingIcon from '../../stylesheets/images/loading.svg';
import faEyeIcon from '../../stylesheets/images/fa-eye.svg';
import faEyeSlashIcon from '../../stylesheets/images/fa-eye-slash.svg';

class LoginPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      typeNewPass: true,
      data: {email: '', password: ''},
      rememberMe: false,
      errors: {}
    };
  }

  componentWillMount() {
    let token = getAccessToken();
    if (token && !this.props.authState.auth) {
      this.props.authPropsActions.getUser();
    }
  }

  validate() {
    let { data, errors } = this.state;
    let fields = ['email', 'password'];

    fields.forEach(f => {
      _.set(errors, f, !data[f] ? I18n.t('PleaseEnter') + I18n.t(f) : '');
    });
    this.setState({ errors });

    let flag = Object.keys(errors).every(k => !errors[k]);
    return flag;
  }

  onLogin() {
    let { data, rememberMe } = this.state;
    this.validate() && this.props.authPropsActions.signIn({ email: data.email, password: data.password, rememberMe });
  }

  onChange(property, value) {
    let { data, errors } = this.state;
    data[property] = value;
    let newErrors = Object.assign({}, errors);
    newErrors[property] = "";
    this.setState({ data: Object.assign({}, data), errors: newErrors });
  }

  handleRememberMe(value) {
    this.setState({rememberMe: !!value});
  }

  componentWillReceiveProps(newProps) {
    if (newProps.authState.auth) {
      this.props.router.history.push('/');
    }
  }

  forgotPassword() {
    this.props.router.history.push(FORGOT_PASSWORD_URL);
  }

  render() {
    const { authState } = this.props;
    let { data, rememberMe, errors } = this.state;
    let isSubmiting = _.get(authState, 'loading', false);

    return (
      <div id="login" className={`card card-container ${isSubmiting ? 'de-active' : ''}`}>
        {isSubmiting && (
          <div className="loadingPage">
            <img src={loadingIcon} />
          </div>
        )}
        <img id="profile-img" className="profile-img-card" src={logoIcon} />
        <form className="form-signin" onKeyPress={event => {
          if (event.key === 'Enter') {
            this.onLogin();
          }
        }} >
          <div className="form-group position-relative">
            <label>{I18n.t('LoginPage.Email')}</label>
            <input type="email" className="form-control" value = {data.email} onChange={evt => this.onChange('email', evt.target.value)} />
            {errors['email'] && <span className="alert-warning">{errors['email']}</span>}
          </div>
          <div className="form-group password">
            <label>{I18n.t('LoginPage.Password')}</label>
            <input type={this.state.typeNewPass ? "password" : "text"} value = {data.password} className="form-control" onChange={evt => this.onChange('password', evt.target.value)}/>
            <span onClick={() => { this.setState({ typeNewPass: !this.state.typeNewPass }) }}>
              <img style={{cursor: 'pointer'}} src={this.state.typeNewPass ? faEyeIcon : faEyeSlashIcon} alt="" />
            </span>
            {errors['password'] && <div className="alert-warning">{errors['password']}</div>}
          </div>
          <div id="remember" className="checkbox">
            <input id="option3" type="checkbox" name="field" checked={!!rememberMe} onChange={evt => this.handleRememberMe(evt.target.checked)} />
            <label htmlFor="option3"><span><span /></span>{I18n.t('LoginPage.Rememberme')}</label>
          </div>
          <div id="forgot" className="cursor-pointer" onClick={() => { this.forgotPassword(); }}><span>{I18n.t('LoginPage.forgotpassword')}</span></div>
          {authState.error && typeof authState.error === 'string' && (
            <div className="form-group authstate-error">
              <span className="alert-warning">{authState.error}</span>
            </div>
          )}
          <div className="wrap-button">
            <button type="button" className="bt-orange" onClick={() => this.onLogin()} >LOGIN</button>
          </div>
        </form>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    authState: state.authState
  };
}

function mapDispatchToProps(dispatch) {
  return {
    authPropsActions: bindActionCreators(authActions, dispatch)
  };
}

LoginPage.contextTypes = {
  router: PropTypes.object.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LoginPage));
