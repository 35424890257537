import Base from './base';

class Material extends Base {
  constructor () {
    super();

    this.getListMaterialDAL = this.getListMaterialDAL.bind(this);
    this.addMaterialDAL = this.addMaterialDAL.bind(this);
    this.editMaterialDAL = this.editMaterialDAL.bind(this);
    this.getMaterialDAL = this.getMaterialDAL.bind(this);
    this.deleteMaterialDAL = this.deleteMaterialDAL.bind(this);
  }

  getListMaterialDAL (query) {
    return super.execute(this.Urls.material.list, this.ApiMethod.get, query);
  }

  addMaterialDAL (body) {
    return super.execute(this.Urls.material.create, this.ApiMethod.post, body);
  }

  editMaterialDAL (id, body) {
    return super.execute(this.Urls.material.update + '/' + id, this.ApiMethod.put, body);
  }

  getMaterialDAL (id, options) {
    return super.execute(this.Urls.material.detail + '/' + id, this.ApiMethod.get, options);
  }

  deleteMaterialDAL (id) {
    return super.execute(this.Urls.material.delete + '/' + id, this.ApiMethod.delete, null);
  }
}

export default new Material();
