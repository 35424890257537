import * as types from '../constants/actionTypes';
import {showLoading, hideLoading} from './commonUseActions';
import userServices from '../services/userServices';
import organizationServices from "../services/organizationServices";
import departmentServices from "../services/departmentServices";
import roleServices from "../services/roleServices";
import _ from 'lodash';

function resetPassword(id) {
  return (dispatch) => {
    showLoading(types, dispatch);

    userServices.resetPassword(id).then((res) => {
      hideLoading(types, dispatch);

      dispatch({
        type: types.APP.SHOW_SUCCESS_ALERT,
        data: 'Reset password successful!'
      });
    }, (error) => {
      hideLoading(types, dispatch, error);
    });
  };
}

function getListUser(options) {
  return (dispatch) => {
    showLoading(types, dispatch);
    return userServices.getListUser(options).then((res) => {
      hideLoading(types, dispatch);

      dispatch({
        type: types.USERS.GET_LIST_SUCCESS,
        data: res.data || []
      });
      return res;
    }, (error) => {
      hideLoading(types, dispatch, error);
    });
  };
}



function updateLock (id) {
    return (dispatch) => {
        showLoading(types, dispatch);
        return userServices.updateLockUser(id).then((res) => {
            hideLoading(types, dispatch);
            if (res) {
                dispatch({
                    type: types.USERS.RELOAD,
                    data: true
                });
            }
            return res;
        }, (error) => {
            hideLoading(types, dispatch);
            return error;
        });
    };
}

function editUser (id, data) {
  return (dispatch) => {
    showLoading(types, dispatch);
    return userServices.editUser(id, data).then((res) => {
      hideLoading(types, dispatch);
      if (res) {
        dispatch({
          type: types.USERS.RELOAD,
          data: true
        });
      }
      return res;
    }, (error) => {
      hideLoading(types, dispatch);
      return error;
    });
  };
}

function addUser (data) {
  return (dispatch) => {
    showLoading(types, dispatch);

    return userServices.addUser(data).then((res) => {
      hideLoading(types, dispatch);
      if (res) {
        dispatch({
          type: types.USERS.RELOAD,
          data: true
        });
      }
      return res;
    }, (error) => {
      hideLoading(types, dispatch);
      return error;
    });
  };
}


function deleteUser (id) {
  return (dispatch) => {
    showLoading(types, dispatch);

    userServices.deleteUser(id).then((res) => {
      hideLoading(types, dispatch);
      if (res) {
        dispatch({
          type: types.USERS.RELOAD,
          data: true
        });
      }
    }, (error) => {
      hideLoading(types, dispatch, error);
    });
  };
}

function searchByOrganization(options, pageNumber, pageSize, populate) {
  return(dispatch) =>{
    showLoading(types, dispatch);
    return organizationServices.getListOrganization(options).then((res) => {
      hideLoading(types, dispatch);
      if(res && res.success) {
        let options = {
          pageSize: pageSize,
          pageNumber: pageNumber,
          populate: populate,
          query: JSON.stringify({
            organization: {
              $in: res.data.items.reduce((arr, item) => arr.concat(item._id || []), [])
            }
          })
        };
        return getListUser({...options})(dispatch).then(res => {
          if(res && res.success && res.data && res.data.items && !res.data.items.length && pageNumber > 1) {
            _.set(options, "pageNumber", 1);
            getListUser(options)(dispatch);
            pageNumber = 1;
          }
          return (_.set(res, "pageNumber", pageNumber));
        });
      }
    }, (error) => {
      hideLoading(types, dispatch, error);
    })
  }
}

function searchByDepartment(options, pageNumber, pageSize, populate) {
  return(dispatch) =>{
    showLoading(types, dispatch);
    return departmentServices.getListDepartment(options).then((res) => {
      hideLoading(types, dispatch);
      if(res && res.success) {
        let options = {
          pageSize: pageSize,
          pageNumber: pageNumber,
          populate: populate,
          query: JSON.stringify({
            department: {
              $in: res.data.items.reduce((arr, item) => arr.concat(item._id || []), [])
            }
          })
        };
        return getListUser({...options})(dispatch).then(res => {
          if(res && res.success && res.data && res.data.items && !res.data.items.length && pageNumber > 1) {
            _.set(options, "pageNumber", 1);
            getListUser(options)(dispatch);
            pageNumber = 1;
          }
          return (_.set(res, "pageNumber", pageNumber));
        });
      }
    }, (error) => {
      hideLoading(types, dispatch, error);
    })
  }
}

function searchByRole(options, pageNumber, pageSize, populate) {
  return(dispatch) =>{
    showLoading(types, dispatch);
    return roleServices.getListRole(options).then((res) => {
      hideLoading(types, dispatch);
      if(res && res.success) {
        let options = {
          pageSize: pageSize,
          pageNumber: pageNumber,
          populate: populate,
          query: JSON.stringify({
            role: {
              $in: res.data.items.reduce((arr, item) => arr.concat(item._id || []), [])
            }
          })
        };
        return getListUser({...options})(dispatch).then(res => {
          if(res && res.success && res.data && res.data.items && !res.data.items.length && pageNumber > 1) {
            _.set(options, "pageNumber", 1);
            getListUser(options)(dispatch);
            pageNumber = 1;
          }
          return (_.set(res, "pageNumber", pageNumber));
        });
      }
    }, (error) => {
      hideLoading(types, dispatch, error);
    })
  }
}


export default {
  resetPassword,
  getListUser,
  editUser,
  updateLock,
  addUser,
  deleteUser,
  searchByOrganization,
  searchByDepartment,
  searchByRole,
};
