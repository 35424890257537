import _ from 'lodash';
import * as R from 'ramda';

export const isOpen = ({ createOrder }) => {
  const { showing } = createOrder;
  return showing;
}

export const getCustomerPO = ({ createOrder }) => createOrder.customerPO;

export const getSelectableCustomers = ({ createOrder }) => {
  const { selectableCustomers } = createOrder;
  return _.map(selectableCustomers, item => ({
    value: R.path(['_id'], item),
    label: R.path(['name'], item),
    raw: item,
  }));
}

export const getSelectedCustomer = ({ createOrder }) => {
  const { selectedCustomer } = createOrder;
  return R.path(['_id'], selectedCustomer);
}

export const getOrderDate = ({ createOrder }) => createOrder.orderDate;

export const getExFactory = ({ createOrder }) => createOrder.exFactory;

export const getIhDate = ({ createOrder }) => createOrder.ihDate;

export const getDescription = ({ createOrder }) => createOrder.description;

export const getErrors = ({ createOrder }) => createOrder.errors;

export const canSave = ({ createOrder }) => {
  const { selectedCustomer, customerPO, errors, submiting } = createOrder;
  if (submiting) return false;

  return !!_.trim(customerPO) 
    && !!selectedCustomer 
    && !R.path(['customerPO'], errors)
    && !R.path(['selectedCustomer'], errors);
}

export const isSubmiting = ({ createOrder }) => {
  const { submiting } = createOrder;
  return submiting;
}

export const fnGenerateRequestBody = ({ createOrder }) => () => {
  const { selectedCustomer, customerPO, orderDate, exFactory, ihDate, description } = createOrder;
  return {
    customer: selectedCustomer,
    customerPO,
    orderDate, 
    ihDate, 
    exFactory, 
    description,
  }
}
