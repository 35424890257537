import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

export default class Panel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: true,
    };
  }

  onRotatePanelArrow = () => {
    this.setState({activeTab: !this.state.activeTab})
  }

  render = () => {
    const {id, renderContent, renderPanelDetails, removePurchase} = this.props;
    const {activeTab} = this.state;
    return (
      <div key={id} className="panel panel-default">
        <div className="panel-heading">
          {_.isFunction(removePurchase) ? <a className="close" onClick={() => removePurchase()}><span>&times;</span></a> : ''}
          <div className="panel-title">
            <a role="button"
               data-toggle="collapse"
               aria-expanded="true"
               aria-controls="collapseOne"
               onClick={this.onRotatePanelArrow}
            >
              <i id={"caret" + (id || "")} className={"fa " + (activeTab ? "fa-caret-down" : "fa-caret-right")} aria-hidden="true"></i>
            </a>
            {_.isFunction(renderPanelDetails) ? renderPanelDetails() : null}
          </div>
        </div>
        <div className={(activeTab ? "show" : "hide")}>
          <div className="panel-body pd-0">
            {_.isFunction(renderContent) ? renderContent() : null}
          </div>
        </div>
      </div>
    );
  }
}

Panel.propTypes = {
  item: PropTypes.object,
  renderPanelDetails: PropTypes.func,
  renderContent: PropTypes.func,
};

