import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { withRouter } from 'react-router';
import I18n from 'i18n-js';
import _ from 'lodash';
import * as PATH from '../../../../constants/routeConstants';
import AccountsListInvoiceView from '../../../components/accounting/PayableInvoiceList/AccountsListInvoice';
import ReceiptModal from '../../../components/accounting/PayableInvoiceList/ReceiptModal';
import CreateInvoiceRequestModal from '../../../components/accounting/PayableInvoiceList/CreateInvoiceRequestModal';
import * as actions from '../../../../actions/accounting/payable/invoiceList';
import * as selector from '../../../../selectors/accounting/payable/showInvoiceList';
import {
  getInvoiceIEContainerDetail,
  activeScreenEditContainer,
} from '../../../../actions/accounting/payable/editInvoiceIEContainer';
import {
  getInvoiceIEDeclarationDetail,
  activeScreenEditDeclaration,
} from '../../../../actions/accounting/payable/editInvoiceIEDeclaration';
import {
  getInvoiceIEUSDeliveryDetail,
  activeScreenEditUSDelivery,
} from '../../../../actions/accounting/payable/editInvoiceIEUSDelivery';
import {
  getInvoiceSupplierOverseaDetail,
  activeScreenEditSupplierOversea,
} from '../../../../actions/accounting/payable/editInvoiceSupplierOversea';
import {
  getInvoiceSupplierDomesticDetail,
  activeScreenEditSupplierDomestic,
} from '../../../../actions/accounting/payable/editInvoiceSupplierDomestic';
import {
  getInvoiceContractor,
  activeScreenEditContractor,
} from '../../../../actions/accounting/payable/editInvoiceContractor';
import ShowInvoiceConfirmModal from '../../../components/accounting/PayableInvoiceList/ConfirmModal';

class AccountsListInvoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openReceiptModal: false,
      openPreviewInvoiceModal: false,
      loading: true,
      openConfirmModal: false,
      invoiceDeleteId: '',
    };
  }

  componentWillMount() {
    this.props.changeLoading(true);
  }

  componentWillReceiveProps(nextProps) {
    const { createInvoiceRequestStatus, deleteSuccess, paramsRequest, loading } = nextProps;
    if (createInvoiceRequestStatus) {
      nextProps.resetInvoiceRequest();
      nextProps.resetCreateInvoiceRequestStatus();
      this.getListInvoice(paramsRequest || {});
    }
    if (deleteSuccess) {
      nextProps.resetDeleteStatus();
      this.getListInvoice(paramsRequest || {});
      toastr.success(I18n.t('Notify.Success'), 'Delete Success');
    }
    if (loading && paramsRequest) {
      this.getListInvoice(paramsRequest || {});
      nextProps.changeLoading(false);
    }
  }
  getListInvoice = params => {
    this.props.getListInvoice(params);
  };

  openReceiptModal = item => {
    this.props.changeReceiptModalData(item);
    this.setState({
      openReceiptModal: true,
    });
  };

  closeReceiptModal = () => {
    this.setState({
      openReceiptModal: false,
    });
  };

  openPreviewInvoiceModal = () => {
    this.setState({
      openPreviewInvoiceModal: true,
    });
  };

  closePreviewInvoiceModal = () => {
    this.setState({
      openPreviewInvoiceModal: false,
    });
    this.props.resetInvoiceRequest();
  };

  createINVRequest = (listInv, invRequest) => {
    const { listInvoice } = this.props;
    let isRequest = false;
    listInvoice.map(item =>
      item.invRequest && listInv.findIndex(inv => inv == item._id) > -1 ? (isRequest = true) : null
    );
    if (_.isEmpty(invRequest)) {
      toastr.error(I18n.t('Notify.Error'), 'Invoice request can not be blank.');
    } else {
      if (isRequest) {
        toastr.error(I18n.t('Notify.Error'), 'Invoice request already exists.');
      } else {
        this.setState({ openPreviewInvoiceModal: false });
        this.props.createINVRequest(listInv, invRequest, true);
      }
    }
  };

  showInvoiceConfirmModal = id => {
    this.setState({ openConfirmModal: true, invoiceDeleteId: id });
  };

  closeShowConfirmModal = () => {
    this.setState({ openConfirmModal: false });
  };

  render() {
    const { openReceiptModal, openPreviewInvoiceModal, openConfirmModal, invoiceDeleteId } = this.state;
    const { invoiceRequest, listCheckedInvoice, receiptData, roles } = this.props;
    return (
      <div>
        <AccountsListInvoiceView
          {...this.props}
          openPreviewInvoiceModal={this.openPreviewInvoiceModal}
          openReceiptModal={item => this.openReceiptModal(item)}
          showInvoiceConfirmModal={id => this.showInvoiceConfirmModal(id)}
        />
        <ReceiptModal
          type={'export'}
          openModal={openReceiptModal}
          close={this.closeReceiptModal}
          receiptData={receiptData}
          export={data => this.props.export(data)}
        />
        <CreateInvoiceRequestModal
          openModal={openPreviewInvoiceModal}
          invoiceRequest={invoiceRequest}
          listCheckedInvoice={listCheckedInvoice}
          createINVRequest={(listInv, invRequest) => this.createINVRequest(listInv, invRequest)}
          changeInvoiceRequest={value => this.props.changeInvoiceRequest(value)}
          close={this.closePreviewInvoiceModal}
        />
        <ShowInvoiceConfirmModal
          invoiceDeleteId={invoiceDeleteId}
          openModal={openConfirmModal}
          close={this.closeShowConfirmModal}
          deleteInvoice={id => this.props.deleteInvoice(invoiceDeleteId)}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  listInvoice: selector.getPayableInvoices(state),
  listFilter: selector.getSelectableFilters(state),
  selectedFilter: selector.getSelectedFilter(state),
  searchValue: selector.getSearchValue(state),
  invoiceRequest: selector.getInvoiceRequest(state),
  listPaid: selector.getPaidDetail(state),
  currentPage: selector.getCurrentPage(state),
  totalPage: selector.getTotalPages(state),
  listCheckedInvoice: selector.getCheckedInvoice(state),
  createInvoiceRequestStatus: selector.getCreateInvoiceRequestStatus(state),
  deleteSuccess: selector.getDeleteSuccess(state),
  currency: selector.getCurrency(state),
  paramsRequest: selector.getParamsRequest(state),
  loading: selector.getLoading(state),
  receiptData: selector.getReceipt(state),
  amount: selector.getTotalAmount(state),
  paid: selector.getTotalPaid(state),
  remaining: selector.getTotalRemaining(state),
  currencyTypeUSD: selector.getCurrencyTypeUSD(state),
  currencyTypeVND: selector.getCurrencyTypeVND(state),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getListInvoice(params) {
    dispatch(actions.getListInvoice(params));
    dispatch(actions.getAmountInvoice({ currency: params.currency }));
  },
  checkedInvoice(id, checked) {
    dispatch(actions.checkedInvoice(id, checked));
  },
  edit(item) {
    const { typeInvoice, _id } = item;
    switch (typeInvoice) {
      case 'containerInvoice':
        dispatch(activeScreenEditContainer());
        ownProps.router.history.push(PATH.ACCOUNTING_PAYABLE_INVOICE_CONTAINER_EDIT_URL);
        dispatch(getInvoiceIEContainerDetail(_id));
        return;
      case 'declarationInvoice':
        dispatch(activeScreenEditDeclaration());
        ownProps.router.history.push(PATH.ACCOUNTING_PAYABLE_INVOICE_DECLARATION_EDIT_URL);
        dispatch(getInvoiceIEDeclarationDetail(_id));
        return;
      case 'usDeliveryInvoice':
        dispatch(activeScreenEditUSDelivery());
        ownProps.router.history.push(PATH.ACCOUNTING_PAYABLE_INVOICE_US_DELIVERY_EDIT_URL);
        dispatch(getInvoiceIEUSDeliveryDetail(_id));
        return;
      case 'contractorInvoice':
        dispatch(activeScreenEditContractor());
        dispatch(getInvoiceContractor(_id));
        ownProps.router.history.push(PATH.ACCOUNTING_PAYABLE_INVOICE_CONTRACTOR_EDIT_URL);
        return;
      case 'domesticInvoice':
        dispatch(activeScreenEditSupplierDomestic());
        ownProps.router.history.push(PATH.ACCOUNTING_PAYABLE_INVOICE_DOMESTIC_EDIT_URL);
        dispatch(getInvoiceSupplierDomesticDetail(_id));
        return;
      case 'overseaInvoice':
        dispatch(activeScreenEditSupplierOversea());
        ownProps.router.history.push(PATH.ACCOUNTING_PAYABLE_INVOICE_OVERSEA_EDIT_URL);
        dispatch(getInvoiceSupplierOverseaDetail(_id));
        return;
      default:
        return;
    }
  },
  changeInvoiceRequest(value) {
    dispatch(actions.changeInvoiceRequest(value));
  },
  resetInvoiceRequest() {
    dispatch(actions.resetInvoiceRequest());
  },
  deleteInvoice(id) {
    dispatch(actions.deleteInvoice(id));
  },
  createINVRequest(listInv, invRequest, isExport = false) {
    dispatch(actions.createINVRequest(listInv, invRequest, isExport));
  },
  onChangeFilter(value) {
    dispatch(actions.changeSelectedFilter(value));
  },
  onChangeSearchValue(value, pos) {
    dispatch(actions.changeSearchValue(value, pos));
  },
  onSearch(params) {
    params.pageNumber = 1;
    dispatch(actions.changePage(params.pageNumber));
    dispatch(actions.getListInvoice(params));
  },
  showPaidPopup(id) {
    dispatch(actions.resetPaidList());
    dispatch(actions.getPaidDetailInvoice(id));
  },
  viewInvoiceNo(id) {
    ownProps.router.history.push(PATH.ACCOUNTING_PAYABLE_INVOICE_VIEW_URL);
  },
  changeCurrency(value) {
    dispatch(actions.changeCurrency(value));
  },
  resetDeleteStatus() {
    dispatch(actions.resetDeleleStatus());
  },
  changeLoading(value) {
    dispatch(actions.changeLoading(value));
  },
  changePage(params) {
    dispatch(actions.changePage(params.pageNumber));
    dispatch(actions.getListInvoice(params));
  },
  changeReceiptModalData(item) {
    dispatch(actions.changeReceiptModalData(item));
  },
  resetCreateInvoiceRequestStatus() {
    dispatch(actions.resetCreateInvoiceRequestStatus());
  },
  downloadAttachs(listAttachs) {},
  export(data) {
    // console.log(data)
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AccountsListInvoice));
