import { createSelector } from 'reselect';
import _ from 'lodash';
import moment from 'moment';
import * as R from 'ramda';
import {genDollarToText} from '../../../functions/helpers';

export const getInvoiceDetail = ({editInvoiceImpExpContainer}) => editInvoiceImpExpContainer.invoiceDetail || {};

export const getCompany = ({editInvoiceImpExpContainer}) => editInvoiceImpExpContainer.company;

export const getName = createSelector (
    [
        getInvoiceDetail
    ], (data) => {
        return data.invoiceDetail && data.invoiceDetail.name
    }
)

export const getPaid = createSelector (
    [
        getInvoiceDetail
    ], (data) => {
        return data.paid || 0
    }
)

export const getInvoiceNo = createSelector (
    [
        getInvoiceDetail
    ], (data) => {
        return data.invNo
    }
)

export const getIssuedDate = createSelector (
    [
        getInvoiceDetail
    ], (data) => {
        return data.issuedDate //moment(data.issuedDate).format("MMM DD, YYYY");
    }
)

export const getDueDate = createSelector(
    [
        getInvoiceDetail
    ], data => {
        return data.dueDate //moment(data.dueDate).format("MMM DD, YYYY");
    }
)

export const getPayAmount = ({editInvoiceImpExpContainer}) => editInvoiceImpExpContainer.payAmount;

export const getFilesSaved = createSelector(
    [
        getInvoiceDetail
    ], data => {
        return data.files || [];
    }
)

export const getRawDetailValue = ({editInvoiceImpExpContainer}) => editInvoiceImpExpContainer.rawData;

export const getPaymentType = ({editInvoiceImpExpContainer}) => editInvoiceImpExpContainer.paymentType;

export const getContainers = ({editInvoiceImpExpContainer}) => {
    if(editInvoiceImpExpContainer.invoiceDetail)
        return  editInvoiceImpExpContainer.invoiceDetail.invoiceDetail.containers
}

export const getAmount = createSelector (
    [
        getContainers
    ], (data) => {
        return _.sum((data || []).map(item => (item.cost || 0) + (item.chargesIncurred || 0) + (item.liftOffFee || 0) + (item.liftOnFee || 0)))
        // return data.amount || 0
    }
)

export const getRemaining = createSelector(
    [
        getAmount,
        getPaid
    ], (amount, paid) => {
        return amount - paid
    }
)

export const fnGetContainerById = createSelector(
    [
        getContainers
    ],(containers) => id => {
        return containers.find(item => item._id === id)
    }
)

export const getTransferFiles = ({ editInvoiceImpExpContainer }) => {
    const { transferFiles } = editInvoiceImpExpContainer;
    return _.map(transferFiles, file => ({
      id: R.path(['id'], file),
      url: R.path(['url'], file),
      raw: file,
      type: _.split(R.path(['id'], file), '.')[1],
    }));
}

export const fnGenerateRequestData = createSelector (
    [
        ({editInvoiceImpExpContainer}) => editInvoiceImpExpContainer,
        getAmount
    ], (editInvoiceImpExpContainer, amount) => {
        const {invoiceDetail = {}, paymentType, payAmount} = _.cloneDeep(editInvoiceImpExpContainer);
        let data = _.omit(invoiceDetail, ['createdAt', 'createdBy', 'updatedAt', 'updatedBy', 'paymentHistory']);
        let detailOfInvoice = _.omit(data.invoiceDetail, ['createdAt', 'createdBy', 'updatedAt', 'updatedBy'])
        let receipt;
        if(paymentType === 'transfer') {
            data = _.omit(data, ['receipt']);
        } else {
            receipt = data.receipt;
            data = _.omit(data, ['files', 'receipt']);
        }
        data = {...data, 
            payment: {
                receipt, 
                paymentType: _.startCase(paymentType), 
                paid: payAmount
            }, 
            invoiceDetail: detailOfInvoice, 
            currency: 'VND',
            amount: amount }
        return data;
    }
)

// export const fnGenerateRequestData = ({editInvoiceImpExpContainer}) => {
//     const {invoiceDetail = {}, paymentType, payAmount} = editInvoiceImpExpContainer;
//     let data = _.omit(invoiceDetail, ['createdAt', 'createdBy', 'updatedAt', 'updatedBy', 'paymentHistory']);
//     let detailOfInvoice = _.omit(data.invoiceDetail, ['createdAt', 'createdBy', 'updatedAt', 'updatedBy'])
//     let receipt;
//     if(paymentType === 'transfer') {
//         data = _.omit(data, ['receipt']);
//     } else {
//         receipt = data.receipt;
//         data = _.omit(data, ['files', 'receipt']);
//     }
//     data = {...data, payment: {receipt, paymentType: _.startCase(paymentType), paid: payAmount}}
//     return ({...data, invoiceDetail: detailOfInvoice, currency: 'VND'})
// }

export const getReceiptFromInvoice = createSelector (
    [
        getInvoiceDetail,
        getPayAmount,
        getName
    ], (data, payAmount, address) => {
        return ({...data.receipt, total: payAmount, date: moment().format(), address})
    }
)

export const getReceiptDataFromRawData = ({editInvoiceImpExpContainer}) => ({...editInvoiceImpExpContainer.receipt, say: genDollarToText(editInvoiceImpExpContainer.receipt.total)})

export const getEmptyReceipt = createSelector(
    [
        getReceiptFromInvoice
    ], data => {
        return !data.isEdit;
    }
)

export const getSuccess = ({editInvoiceImpExpContainer}) => editInvoiceImpExpContainer.success;

export const getDisableChangeValue = createSelector (
    [
      getPaid
    ], paid => {
      if(paid > 0) return true;
      return false;
    }
  )

  export const getActiveScreenStatus = ({editInvoiceImpExpContainer}) => editInvoiceImpExpContainer.activeScreen;