import React, { Component } from 'react';
import { connect } from 'react-redux';
import {withRouter} from 'react-router'

import * as _ from 'lodash';
import I18n from 'i18n-js';
import SearchBar from '../../../common/components/SearchBar';
import {ACTION_TYPE} from '../../../../constants/constants';
import { SHIP_OUT } from '../../../../constants/routeConstants';
import TableList from '../../../../components/common/datatables/TableList';
import {getList, changeSelectedFilter, changeSearchValue, search, changeSearchStatus} from '../../../../actions/ship-out/showHistoryActions';
import * as selector from '../../../../selectors/ship-out/showHistoryList';
import {getShipmentDetail, activeScreenEditShipment} from '../../../../actions/ship-out/editShipmentActions';
import CustomPagination from '../../../../components/common/datatables/CustomPagination';

class HistoryContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            columns: [
                { name: I18n.t("PageShiping.Date"), value: 'date' },
                { name: I18n.t(`PageShiping.DeclarationNo`), value: 'declarationNo' },
                { name: I18n.t(`PageShiping.DeclarationDate`), type: 'declarationDate', formatType: 'MMM DD, YYYY', value: 'declarationDate' },
                { name: I18n.t(`PageShiping.ShipMode`), value: `shipMode` },
                { name: I18n.t(`PageShiping.BillNo`), value: `billNo` },
                { name: I18n.t('PageShiping.Cont'), value: 'cont' },
                { name: I18n.t(`PageShiping.ETD`), value: 'etd' },
                { name: I18n.t(`PageShiping.ETA`), value: 'eta' },
                { name: I18n.t(`PageShiping.TotalFee`), value: 'totalFee', options: { clsTd: 'color-red' } }
            ],
            actions: [
                () => {
                    return { type: ACTION_TYPE.VIEW, click: (item) => this.displayView(item) };
                }
            ],
            pageNumber: 1,
            pageSize: 10,
        }
    }
    

    displayView = (item)=>{
        this.props.getShipmentDetail(item._id)
        this.props.activeScreenViewShipment();
        this.props.router.history.push(SHIP_OUT.HISTORY.VIEW);
    }

    componentWillMount() {
        this.getList(this.getBaseOption())
    }
    // This function use for get items from search feature
    componentWillReceiveProps(nextPorps) {
        const { items } = { ...nextPorps.ShipOutState };
        if (!_.isEqual(items, this.state.items)) {
            this.setState({ items });
        }
    }

    componentDidMount() {
    }


    //This function use for setup query string for request api
    getBaseOption = () => {
        const {pageSize, pageNumber} = this.state;
        let options = {
            history: true,
            query: JSON.stringify({isCancel: {$ne: true}}),
            sort: JSON.stringify({createdAt:-1}), 
            pageSize, 
            pageNumber,
        };
        return options;
    }

    getList(options) {
        this.props.getList(options);
    }

    onChangeFilter = (value) => {
        this.props.changeSelectedFilter(value);
        this.props.changeSearchStatus(true)
    }
    
    onChangeValue = (value, pos) => {
        this.props.changeSearchValue(value, pos);
        this.props.changeSearchStatus(true)
    }

    onSearch = () => {
        this.props.changeSearchStatus(true)
        const {searched, getSearchQuery, selectedFilter, searchValue} = this.props;
        this.getList(getSearchQuery(true, this.getBaseOption(), selectedFilter, searchValue))
    }

    selectPage = (page) => {
        let options = this.getBaseOption();
        options.pageNumber = page;
        const { searched, getSearchQuery, selectedFilter, searchValue } = this.props;    
        this.getList(getSearchQuery(searched, options, selectedFilter, searchValue))
    }

    render() {

        const { columns, items, actions, pageNumber, pageSize, total } = { ...this.state };
        const {listFilter, selectedFilter, listItems, searchValue, currentPage, totalPage} = this.props;

        return (
            <div>
                <div className="wrap-search-position top-5">
                    <SearchBar name="Shipping" fields={listFilter} selectedFilter={selectedFilter} searchValue={searchValue || {}} onChangeFilter={this.onChangeFilter} onChangeValue={this.onChangeValue} onSearch={this.onSearch}/>
                </div>
                <TableList columns = {columns || []} items = {listItems || []} actions = {actions || []} options={{clsTbl: 'global-table'}}/>
                <CustomPagination items={totalPage} activePage={currentPage} onSelect={this.selectPage} />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    listFilter: selector.getListFilters(),
    selectedFilter: selector.getSelectedFilter(state),
    listItems: selector.getDisplayList(state),
    searchValue: selector.getSearchValue(state),
    currentPage: selector.getCurrentPage(state),
    totalPage: selector.getTotalPages(state),
    searched: selector.getSearched(state),
    getSearchQuery: selector.getSearchQuery,
})

const mapDispatchToProps = dispatch => ({
    getList(options) {
        dispatch(getList(options))
    },
    changeSelectedFilter(value) {
        dispatch(changeSelectedFilter(value))
    },
    changeSearchValue(value, pos) {
        dispatch(changeSearchValue(value, pos))
    },
    search(selectedFilter, searchValue) {
        dispatch(search(selectedFilter, searchValue))
    },
    getShipmentDetail(id) {
        dispatch(getShipmentDetail(id))
    },
    changeSearchStatus(value) {
        dispatch(changeSearchStatus(value))
    },
    activeScreenViewShipment() {
        dispatch(activeScreenEditShipment())
    }
})

export default connect(mapStateToProps, mapDispatchToProps) (withRouter(HistoryContainer));
