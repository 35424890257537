import { STANDARD } from '../constants/actionTypes';
import initial from './initialState';

export default function (state = initial.standardState, action) {
    switch (action.type) {
        case STANDARD.RELOAD:
            return {...state, reload: !state.reload};
        case STANDARD.GET_DETAIL_SUCCESS:
            return {...state, detail: action.data};
        case STANDARD.RESET_DETAIL:
            return {...state, detail: {}};
        default:
            return state;
    }
}
