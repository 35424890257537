import Base from './base';

class PurchaseOrder extends Base {
  constructor () {
    super();

    this.getListItemDAL = this.getListItemDAL.bind(this);
    this.addItemDAL = this.addItemDAL.bind(this);
    this.editItemDAL = this.editItemDAL.bind(this);
    this.editAndSendRevisedDAL = this.editAndSendRevisedDAL.bind(this);
    this.getItemDAL = this.getItemDAL.bind(this);
    this.deleteItemDAL = this.deleteItemDAL.bind(this);
    this.getListPurchasesInboundDAL = this.getListPurchasesInboundDAL.bind(this);
  }

  getListItemDAL (query, token) {
    return super.execute(this.Urls.purchaseOrder.list, this.ApiMethod.get, query, token);
  }

  addItemDAL (body, token) {
    return super.execute(this.Urls.purchaseOrder.create, this.ApiMethod.post, body, token);
  }

  editItemDAL (id, body, token) {
    return super.execute(this.Urls.purchaseOrder.update + '/' + id, this.ApiMethod.put, body, token);
  }

  editAndSendRevisedDAL (id, body, token) {
      return super.execute(this.Urls.purchaseOrder.sendRevised + '/' + id, this.ApiMethod.put, body, token);
  }

  getItemDAL (id, options, token) {
    return super.execute(this.Urls.purchaseOrder.detail + '/' + id, this.ApiMethod.get, options, token);
  }

  deleteItemDAL (id, token) {
    return super.execute(this.Urls.purchaseOrder.delete + '/' + id, this.ApiMethod.delete, null, token);
  }

  getListPurchasesInboundDAL(query, token) {
    return super.execute(this.Urls.purchaseOrder.listInbound, this.ApiMethod.get, query, token);
  }
}

export default new PurchaseOrder();
