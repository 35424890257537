import React, { Component } from 'react';
import {connect} from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { withRouter } from 'react-router';
import I18n from 'i18n-js';
import * as PATH from '../../../../constants/routeConstants'; 
import EditIEContainerView from '../../../components/accounting/PayableInvoiceList/EditInvoiceIEContainer';
import EditModalView from '../../../components/accounting/IEContainerItemEditModal';
import ReceiptModal from '../../../components/accounting/PayableInvoiceList/ReceiptModal';
import UploadTransferFileModal from '../../../components/accounting/PayableInvoiceList/UploadTransferFileModal';
import * as actions from '../../../../actions/accounting/payable/editInvoiceIEContainer';
import * as selector from '../../../../selectors/accounting/payable/editInvoiceIEContainer';

class EditIEContainer extends Component {

    constructor(props) {
        super(props);
        this.state = ({
            openModal: false,
            openReceiptModal: false,
            openTransferModal: false,
        })
    }

    componentWillReceiveProps(nextProps) {
        const {success, activeScreen} = nextProps;
        if(success) {
            nextProps.resetSuccessStatus();
            nextProps.router.history.push(PATH.ACCOUNTING_PAYABLE_INVOICE_URL)
            toastr.success(I18n.t('Notify.Success'), 'Update Invoice Success')
        }
        if(!activeScreen) {
            nextProps.router.history.goBack()
        }
    }
    
    onOpenModal = () => {
        this.setState({openModal: true})
    }
    
    ok = (data) => {
        this.props.saveChangeContainerDetail(data)
    }

    closeModal = () => {
        this.setState({openModal: false})
    }

    resetDateRaw = () => {

    }

    openReceiptModal = () => {
        const {receiptFromInvoice} = this.props;
        this.props.createReceipt(receiptFromInvoice)
        this.setState({openReceiptModal: true})

    }

    closeReceiptModal = () => {
        this.props.changeEmptyReceipt()
        this.setState({openReceiptModal: false})
    }

    openTransferModal = () => {
        const {filesSaved} = this.props;
        this.props.copyFileToRawData(filesSaved);
        this.setState({openTransferModal: true})
    }

    closeTransferModal = () => {
        this.props.emptyFilesInRawData();
        this.setState({openTransferModal: false})
    }

    save = (paymentType, data) => {
        const {payAmount} = this.props;
        if(payAmount == 0) {
            delete data.payment;
            this.props.save(paymentType, data);
        } else {
            this.props.save(paymentType, data);
        }
    }

    render() {
        const {openModal, openReceiptModal, openTransferModal} = this.state;
        const {tempData, transferFiles, receiptData} = this.props
        return (
            <span>
                <EditIEContainerView 
                    {...this.props} 
                    onOpenModal = {this.onOpenModal}
                    save = {(paymentType, data) => this.save(paymentType, data)}
                    openReceiptModal = {() => this.openReceiptModal()} 
                    openTransferModal = {() => this.openTransferModal()}
                />
                <EditModalView openModal = {openModal} 
                    ok = {data => this.ok(data)}
                    close = {this.closeModal}
                    data = {tempData} 
                    resetDateRaw = {this.resetDateRaw} 
                    changeContainerDetailValue = {(key, value) => this.props.changeContainerDetailValue(key, value)} 
                />
                <ReceiptModal 
                    openModal = {openReceiptModal} 
                    close = {this.closeReceiptModal} 
                    changeReceiptValue = {(key, value) => this.props.changeReceiptValue(key, value)} 
                    receiptData = {receiptData}
                    onSubmitReceipt = {data => this.props.onSubmitReceipt(data)}
                />
                <UploadTransferFileModal 
                    onRemoveFile = {id => this.props.onRemoveFile(id)} 
                    transferFiles = {transferFiles} 
                    onAddFile = {file => this.props.onAddFile(file)} 
                    isOpen = {openTransferModal} 
                    onClose = {this.closeTransferModal}
                    onSubmit = {(files) => this.props.onSubmitFiles(files)}
                />
            </span>
        );
    }
}

const mapStateToProps = state => ({
    company: selector.getCompany(state),
    name: selector.getName(state),
    invoiceNo: selector.getInvoiceNo(state),
    issuedDate: selector.getIssuedDate(state),
    dueDate: selector.getDueDate(state),
    payAmount: selector.getPayAmount(state),
    paymentType: selector.getPaymentType(state),
    containers: selector.getContainers(state),
    getContainerById: selector.fnGetContainerById(state),
    amount: selector.getAmount(state),
    paid: selector.getPaid(state),
    remaining: selector.getRemaining(state),
    tempData: selector.getRawDetailValue(state),
    transferFiles: selector.getTransferFiles(state),
    dataApi: selector.fnGenerateRequestData(state),
    filesSaved: selector.getFilesSaved(state),
    receiptData: selector.getReceiptDataFromRawData(state),
    receiptFromInvoice: selector.getReceiptFromInvoice(state),
    emptyReceipt: selector.getEmptyReceipt(state),
    success: selector.getSuccess(state),
    activeScreen: selector.getActiveScreenStatus(state),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    changeName(value) {
        dispatch(actions.changeName(value))
    },
    changeIssuedDate(value) {
        dispatch(actions.changeIssuedDate(value))
    },
    changePayAmount(value, remaining, paid) {
        dispatch(actions.changePayAmount(value, remaining, paid))
    },
    changeDueDate(value) {
        dispatch(actions.changeDueDate(value))
    },
    changeInvoiceNo(value) {
        dispatch(actions.changeInvoiceNo(value))
    },
    changePaymentType(value) {
        dispatch(actions.changePaymenttype(value))
    },
    changeContainerDetail(data) {
        dispatch(actions.changeRawData(data))
    },
    changeContainerDetailValue(key, value) {
        dispatch(actions.changeContainerDetailValue(key, value))
    },
    saveChangeContainerDetail(data) {
        dispatch(actions.saveChangeContainerDetail(data))
    },
    onAddFile(file) {
        dispatch(actions.addFileTransfer(file))
    },
    onRemoveFile(id) {
        dispatch(actions.onRemoveFile(id))
    },
    save(paymentType, data) {
        dispatch(actions.save(paymentType, data))
    },
    copyFileToRawData(files) {
        dispatch(actions.copyFileToRawData(files))
    },
    emptyFilesInRawData() {
        dispatch(actions.emptyFilesInRawData())
    },
    onSubmitFiles(files) {
        dispatch(actions.onSubmitFiles(files))
    },
    createReceipt(value) {
        dispatch(actions.createReceipt(value))
    },
    changeReceiptValue(key, value) {
        dispatch(actions.changeReceiptValue(key, value))
    },
    onSubmitReceipt(data) {
        dispatch(actions.onSubmitReceipt(data))
    },
    changeEmptyReceipt() {
        dispatch(actions.changeEmptyReceipt())
    },
    resetSuccessStatus() {
        dispatch(actions.resetSuccessStatus())
    }
})
export default connect(mapStateToProps, mapDispatchToProps) (withRouter(EditIEContainer));