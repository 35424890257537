const C = 'ADD_PACKING';

export default {
  CHANGE_CUSTOMER: `${C}_CHANGE_CUSTOMER`,
  CHANGE_LIST_CUSTOMERPO: `${C}_CHANGE_LIST_CUSTOMERPO`,
  CHANGE_CUSTOMERPO: `${C}_CHANGE_CUSTOMERPO`,
  GET_LIST_CUSTOMER: `${C}_GET_LIST_CUSTOMER`,
  CHANGE_LIST_CONTRACTOR: `${C}_CHANGE_LIST_CONTRACTOR`,
  CHANGE_CONTRACTOR: `${C}_CHANGE_CONTRACTOR`,
  CHANGE_CARTON_TYPE: `${C}_CHANGE_CARTON_TYPE`,
  CHANGE_PACKING_TYPE: `${C}_CHANGE_PACKING_TYPE`,
  GET_PACKING_BY_ORDER: `${C}_GET_PACKING_BY_ORDER`,
  CREATE_PACKING: `${C}_CREATE_PACKING`,
  EDIT_PACKING: `${C}_EDIT_PACKING`,
  CHANGE_PACKING_NUMBER: `${C}_CHANGE_PACKING_NUMBER`,
  CHANGE_PACKING_NOTE: `${C}_CHANGE_PACKING_NOTE`,
  CHANGE_ACTUAL_PACKING_NUMBER: `${C}_CHANGE_ACTUAL_PACKING_NUMBER`,
  CHANGE_ACTUAL_PACKING_CARTON_NUMBER: `${C}CHANGE_ACTUAL_PACKING_CARTON_NUMBER`,
  CHANGE_GWKGSNWKGS_PACKING_CARTON_NUMBER: `${C}CHANGE_GWKGSNWKGS_PACKING_CARTON_NUMBER`,
  EMPTY_PACKING: `${C}_EMPTY_PACKING`,
  CHANGE_CHECKED_STYLE: `${C}_CHANGE_CHECKED_STYLE`,
  SAVE: `${C}_SAVE`,
  CHANGE_EMPTY_DATA: `${C}_CHANGE_EMPTY_DATA`,
  CHANGE_PROPS_CARTON: `${C}_CHANGE_PROPS_CARTON`,
  CREATE_CARTON: `${C}_CREATE_CARTON`,
  CREATE_CARTON_V2: `${C}_CREATE_CARTON_V2`,
  SET_REMAINING_CARTON: `${C}_SET_REMAINING_CARTON`,
  DELETE_CARTON: `${C}_DELETE_CARTON`,
  CHANGE_TOTAL: `${C}_CHANGE_TOTAL`,
};
