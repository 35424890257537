import * as types from '../constants/actionTypes';
import {
  showLoading,
  hideLoading
} from './commonUseActions';
import shippingServices from '../services/shippingServices';

function getListFollowUp(options) {
  return (dispatch) => {
    showLoading(types, dispatch);

    return shippingServices.getListFollowUp(options).then((res) => {
      hideLoading(types, dispatch);
      if (res.data) {
        dispatch({
          type: types.SHIPPING.GET_FOLLOW_UP_SUCCESS,
          data: res.data.items,
          count: res.data.count,
        });
      }
      return res;
    }, (error) => {
      hideLoading(types, dispatch);
    });

  };
}

function createShipment(body) {

  return (dispatch) => {
    showLoading(types, dispatch);

    return shippingServices.createShipment(body).then((res) => {
      hideLoading(types, dispatch);
      // alert('here in actions with data: ' + JSON.stringify(res.data.items))
      if (res.data) {
        dispatch({
          type: types.SHIPPING.CREATE_SHIPMENT,
          data: res.data.items
        });
      }
      return res;
    }, (error) => {
      hideLoading(types, dispatch);
    });

  };


}

function editShipment(id, body) {

  return (dispatch) => {
    showLoading(types, dispatch);

    return shippingServices.editShipment(id, body).then((res) => {
      hideLoading(types, dispatch);
      // alert('here in actions with data: ' + JSON.stringify(res.data.items))
      if (res.data) {
        dispatch({
          type: types.SHIPPING.EDIT_SHIPMENT,
          data: res.data.items
        });
      }
      return res;
    }, (error) => {
      hideLoading(types, dispatch);
    });

  };


}

function getPurchaseOrderList(body) {
  return (dispatch) => {
    showLoading(types, dispatch);

    return shippingServices.getPurchaseOrderList(body).then((res) => {
      hideLoading(types, dispatch);
      // alert('here in actions with data: ' + JSON.stringify(res.data))
      if (res.data) {
        dispatch({
          type: types.SHIPPING.PURCHASE_ORDER_LIST,
          data: res.data
        });
      }
      return res;
    }, (error) => {
      hideLoading(types, dispatch);
    });

  };
}

function getShipInList(body) {
  return (dispatch) => {
    showLoading(types, dispatch);

    return shippingServices.getShipInList(body).then((res) => {
      hideLoading(types, dispatch);
      // alert('here in actions with data: ' + JSON.stringify(res.data.items))
      if (res.data) {
        dispatch({
          type: types.SHIPPING.SHIPIN_LIST,
          data: res.data.items,
          count: res.data.count
        });
      }
      return res;
    }, (error) => {
      hideLoading(types, dispatch);
    });

  };
}

function getHistoryList() {

  return (dispatch) => {
    showLoading(types, dispatch);
    return shippingServices.getHistoryList().then((res) => {
      hideLoading(types, dispatch);
      // alert('here in actions with data....: ' + JSON.stringify(res.data.items))
      if (res.data) {
        dispatch({
          type: types.SHIPPING.HISTORY_LIST,
          data: res.data.items,
          count: res.data.count
        });
      }
      return res;
    }, (error) => {
      hideLoading(types, dispatch);
    });

  };
}


export default {
  getListFollowUp,
  createShipment,
  editShipment,
  getPurchaseOrderList,
  getShipInList,
  getHistoryList,
};