import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import I18n from 'i18n-js';
import BaseComponent from '../../base/BaseComponent';

import propertyActions from '../../../actions/propertyActions';

import PropertyAdd from '../../common/modals/ModalPropertyAdd';
import PropertyEdit from '../../common/modals/ModalPropertyEdit';
import ModalConfirm from '../../common/modals/ModalConfirm';
import TableList from '../../common/datatables/TableList';
import _ from 'lodash';
import CustomPagination from '../../common/datatables/CustomPagination';
class TabProperty extends BaseComponent {
  constructor(props, context) {
    super(props, context);
    let { roles: {showEdit, showDelete} } = this.props;
    this.state = {
      pageSize: 10,
      pageNumber: 1,
      columns: [
        { name: I18n.t('PageStandard.PropertyName'), type: 'text', dataType: 'text', value: 'name', options: { clsTh: 'w-120' } },
        { name: I18n.t('PageStandard.Value'), type: 'join', dataType: 'text', value: 'values' },
        { name: I18n.t('PageStandard.CreatedDate'), value: 'createdAt', type: 'date', formatType: 'MMM DD, YYYY', options: { clsTh: 'w-120' } },
      ],
      actions: [
        () => {
          return showEdit ? { type: 'edit', click: (item) => this.editItem(item) } : {};
        },
      ],
      items: []
    };
    this.onDeleteProperty = this.onDeleteProperty.bind(this);
    this.validateExistPropertyName = this.validateExistPropertyName.bind(this);
  }

  componentWillMount() {
    this.getListItem();
  }

  componentWillReceiveProps(newProps) {
    let { items } = this.state;
    !_.isEqual(items, newProps.propertyState.items) && this.setState({
      items: _.cloneDeep(newProps.propertyState.items) || [],
      total: newProps.propertyState.count || 0
    });
  }

  componentWillUpdate(nextProps, nextState) {
    if (this.props.propertyState.reload != nextProps.propertyState.reload && this.props.modal) {
      this.props.modal.closeModal();
    }

    if (this.props.propertyState.reload != nextProps.propertyState.reload || this.state.pageNumber != nextState.pageNumber) {
      let query = {};
      if (nextState.pageNumber) {
        query.pageSize = nextState.pageSize,
          query.pageNumber = nextState.pageNumber
      }
      this.getListItem(_.set(query, 'sort', { createdAt: -1 }));
    }

  }

  getListItem(query = {}) {
    let { pageSize, pageNumber } = this.state;
    let _query = _.assign({ pageSize: pageSize, pageNumber: pageNumber }, query);
    this.props.propertyPropsActions.getListProperty(_.set(_query, 'sort', { createdAt: -1 }));
  }


  addItem() {
    let { openModal, closeModal } = this.props.modal;
    let modal = (<PropertyAdd closeModal={closeModal} validateExistPropertyName={this.validateExistPropertyName} />);
    openModal(modal);
  }

  editItem(item) {
    let { openModal, closeModal } = this.props.modal;
    let modal = (<PropertyEdit closeModal={closeModal} validateExistPropertyName={this.validateExistPropertyName} options={{ data: item }} />);
    openModal(modal);
  }

  showDeleteConfirm(item) {
    let { openModal, closeModal } = this.props.modal;
    let modal = (<ModalConfirm
      title={I18n.t('PageStandard.DELETEPROPERTY')}
      confirmMessage={I18n.t('PageStandard.ConfirmDeletePackageStandard')}
      messageOk={I18n.t('Btn.YES')}
      messageCancel={I18n.t('Btn.CANCEL')}
      handleClose={closeModal}
      handleOk={this.onDeleteProperty.bind(null, item)} />);
    openModal(modal);
  }

  validateExistPropertyName(validatePropertyName) {
    if (this.state.items && this.state.items.length) {
      return this.state.items.findIndex(items => (items.name.toLowerCase() === validatePropertyName.name.toLowerCase()) && items._id !== validatePropertyName._id) >= 0;
    }
    return false;
  }

  onDeleteProperty(item) {
    item._id && this.props.propertyPropsActions.deleteProperty(item._id);
  }

  selectPage = (page) => {
    this.setState({ pageNumber: page });
  }

  render() {

    let { items, total, columns, actions, pageNumber, pageSize } = this.state;
    let totalPage = _.ceil(total / pageSize);
    return (
      <div role="tabpanel" className="tab-pane active" id="standard-materials-property">
        <TableList {...this.props} items={items || []} columns={columns || []} actions={actions || []}
          options={{ clsTbl: 'global-table box-shadow-none' }} widthAction="w-70" />
        <CustomPagination items={totalPage} activePage={pageNumber} onSelect={this.selectPage} />
      </div>

    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    ...ownProps,
    authState: state.authState,
    propertyState: state.propertyState
  };
}

function mapDispatchToProps(dispatch) {
  return {
    propertyPropsActions: bindActionCreators(propertyActions, dispatch)
  };
}

TabProperty.propTypes = {
  authState: PropTypes.object.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(TabProperty);
