import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import appActions from '../../actions/appActions';
import roleActions from '../../actions/roleActions';
import BaseComponent from '../base/BaseComponent';
import ModalConfirm from '../common/modals/ModalConfirm';
// import ModalRoleForm from '../common/modals/ModalRoleForm';
import TableList from '../common/datatables/TableList';
import I18n from 'i18n-js';
import _ from 'lodash';
import './style.scss';
import SearchBar from '../common/layout/SearchBar';
import { SEARCH_BAR_EVENT, SEARCH_KEY, SEARCH_LIST } from "../../constants/constants";

import icAddIcon from '../../stylesheets/images/ic-add.svg';
import CustomPagination from '../common/datatables/CustomPagination';

class TabPermissionRole extends BaseComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {
      pageSize: 10,
      pageNumber: 1,
      columns: [
        { name: I18n.t('PageOrganization.Role'), value: 'name', type: 'text', dataType: 'text' },
        { name: I18n.t('PageOrganization.Permission'), value: 'permissionNames', type: 'text', dataType: 'text' },
      ],
      actions: [
        () => {
          return { type: 'edit', click: (item) => this.handleAddEditRole(item) };
        },
        () => {
          return { type: 'delete', click: (item) => this.showDeleteConfirm(item) };
        },

      ],
      roles: [],
      total: 0,
      searchValue: {},
      search: { key: SEARCH_KEY.ROLE, value: "" }
    };

    this.handleAddEditRole = this.handleAddEditRole.bind(this);
    this.onDeleteRole = this.onDeleteRole.bind(this);
    this.getListItem = this.getListItem.bind(this);
    this.selectPage = this.selectPage.bind(this);
  }

  componentWillMount() {
    this.getListItem();
  }

  componentWillReceiveProps(newProps) {
    let { roles } = this.state;
    !_.isEqual(roles, newProps.roleState.items) && this.setState({
      roles: _.cloneDeep(newProps.roleState.items) || [],
      total: newProps.roleState.count || 0
    });
  }

  componentWillUpdate(nextProps, nextState) {
    if (this.props.roleState.reload != nextProps.roleState.reload && this.props.modal) {
      this.search(nextState.search, nextState.pageNumber, nextState.pageSize)
    }
    _.get(this.props.roleState, 'reload') != _.get(nextProps.roleState, 'reload') && this.props.modal.closeModal();
  }



  getListItem(query = {}) {
    let { pageSize, pageNumber } = this.state;
    let _query = _.assign({ pageSize: pageSize, pageNumber: pageNumber, populate: 'modules.moduleId' }, query);
    this.props.roleActions.getListRole(_.set(_query, 'sort', { createdAt: -1 }));
  }

  handleAddEditRole(role) {

    let { roleActions } = this.props;
    if (roleActions.changeToRoleEditPage) {
      roleActions.changeToRoleEditPage(role._id ? role : {}, _.assign(_.cloneDeep(this.props.appState.page), { isEdit: true }));
      this.props.handleAddEditRole({ roleId: role._id || "", isEditRole: true });
    }
  }

  showDeleteConfirm(item) {
    let { openModal, closeModal } = this.props.modal;
    let modal = (<ModalConfirm
      title={I18n.t('PageOrganization.DELETEROLE')}
      confirmMessage={I18n.t('PageOrganization.ConfirmDeleteRole')}
      messageOk={I18n.t('Btn.YES')}
      messageCancel={I18n.t('Btn.CANCEL')}
      handleClose={closeModal}
      handleOk={this.onDeleteRole.bind(null, item)} />);
    openModal(modal);
  }

  onDeleteRole(item) {
    if (item._id) {
      this.props.roleActions.deleteRole(item._id);
    }
  }

  selectPage = (page) => {
    this.setState({ pageNumber: page });
    this.search(this.state.search, page, this.state.pageSize);
  }

  onChangeSearch(property, value) {
    switch (property) {
      case SEARCH_BAR_EVENT.FILTER: {
        this.setState({ search: { key: value, value: "" } });
        break;
      }
      case SEARCH_BAR_EVENT.SEARCH: {
        this.setState({ search: { ...this.state.search, value: value } });
        break;
      }
    }
  }

  onSearch() {
    const { search, pageNumber, pageSize } = this.state;
    this.search(search, pageNumber, pageSize);
  }

  search(search, pageNumber, pageSize) {
    let options = {};
    const populate = 'modules.moduleId';
    if (search && search.key && search.value) {
      switch (search.key) {
        case SEARCH_KEY.ROLE: {
          options = {
            pageNumber,
            pageSize,
            sort: { updatedAt: -1 },
            populate: populate,
            query: { name: { $regex: '.*' + search.value + '.*', $options: 'i' } }
          };
          this.props.roleActions.getListRole(options).then(res => {
            if (res && res.success && res.data && res.data.items && !res.data.items.length && pageNumber > 1) {
              options = {
                pageNumber: 1,
                pageSize,
                sort: { updatedAt: -1 },
                populate: populate,
                query: { name: { $regex: '.*' + search.value + '.*', $options: 'i' } }
              };
              this.setState({ pageNumber: 1 });
              this.props.roleActions.getListRole(options)
            }
          });
          break;
        }
      }
    }
    if (!search.value) {
      let options = {
        pageNumber,
        pageSize,
        sort: { updatedAt: -1 },
        populate: populate,
      };
      this.props.roleActions.getListRole(options).then(res => {
        if (res && res.success && res.data && res.data.items && !res.data.items.length && pageNumber > 1) {
          options = {
            pageNumber: 1,
            pageSize,
            sort: { updatedAt: -1 },
            populate: populate,
          };
          this.setState({ pageNumber: 1 });
          this.props.roleActions.getListRole(options);
        }
      });
    }
  }

  render() {
    let { roles, total, columns, actions, pageSize, pageNumber } = this.state;

    let totalPage = _.ceil(total / pageSize);
    let tabPanelClass = this.props.active ? "tab-pane active" : "tab-pane";
    return (

      <div role="tabpanel" className={tabPanelClass} id="organization-permission-role">

        <div className="wrap-search-position">
          <SearchBar
            name={I18n.t('PagePermission.AddNew')}
            fields={SEARCH_LIST.ORGANIZATION_PERMISSION}
            onChangeSearch={(type, data) => this.onChangeSearch(type, data)}
            onSearch={() => this.onSearch()}
          />
          <div className="wrap-filter-search" style={{marginLeft: '-10px'}}>
            <div className="wrap-stock-in">
              <span className="cursor-pointer" onClick={this.handleAddEditRole}><img
                src={icAddIcon} alt="" /> {I18n.t('PageOrganization.AddNew')}</span>
            </div>
          </div>
        </div>

        <TableList {...this.props} items={roles || []} columns={columns || []} actions={actions || []}
          options={{ clsTbl: 'global-table box-shadow-none hasAvatar' }} widthAction="w-70" />
        <CustomPagination items={totalPage} activePage={pageNumber} onSelect={this.selectPage} />
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    roleState: state.roleState
  };
}

function mapDispatchToProps(dispatch) {
  return {
    roleActions: bindActionCreators(roleActions, dispatch),
    appPropsActions: bindActionCreators(appActions, dispatch),
  };
}

TabPermissionRole.propTypes = {
  roleState: PropTypes.object,
  authState: PropTypes.object,
  handleAddEditRole: PropTypes.func,
  onSelectPage: PropTypes.func,
  active: PropTypes.bool,
  pageSize: PropTypes.number,
  pageNumber: PropTypes.number,
};

export default connect(mapStateToProps, mapDispatchToProps)(TabPermissionRole);

