import Base from './base';

class reportAnalytic extends Base {
    constructor () {
        super();
    }

    getExportWip = (filter) => {
        return `${this.Urls.reportAnalytic.exportWip}${filter ? filter : ''}`;
    }

    getDataForChart = (op) => {
        return super.execute(this.Urls.reportAnalytic.getDataForChart, this.ApiMethod.get, op)
    }
}

export default new reportAnalytic();
