
import * as R from 'ramda';
import C from '../../../constants/customer-po/current/deleteCutTicket';
import cutTicketServices from '../../../services/cutTicketServices';
import { listCutTickets } from './showOrderDetail';

export const showModal = cutTicket => dispatch => {
  dispatch({ type: C.SHOW_MODAL, payload: cutTicket });
}

export const hideModal = () => dispatch => {
  dispatch({ type: C.RESET_DATA });
}

export const deleteCutTicket = cutTicket => dispatch => {
  dispatch({ type: C.SUBMIT });
  const id = R.path(['_id'], cutTicket);
  cutTicketServices.deleteCutTicket(id).then((res) => {
    dispatch({ type: C.CANCEL_SUBMIT });
    dispatch({ type: C.RESET_DATA });
    dispatch(listCutTickets(R.path(['styleId'], cutTicket)));
  }).catch(error => {
    dispatch({ type: C.CANCEL_SUBMIT });
    console.error(error);
  });
};
