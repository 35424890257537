import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import PropTypes from 'prop-types';
import materialActions from '../../../actions/materialActions';
import supplierActions from '../../../actions/supplierActions';
import propertyActions from '../../../actions/propertyActions';
import orderActions from '../../../actions/orderActions';
import I18n from 'i18n-js';
import TextNumberInput from "../../common/fields/TextNumberInput";

class AddMaterial extends Component {
    constructor (props, context) {
        super(props, context);
        this.state = {
          materials: [],
          selectedMaterial: {},
          errors: {},
          data: {
            sizeList:[],
            colorList: []
          },
          colors: [],
          sizes: []
        };
      }
    
      componentWillMount () {
        this.getListMaterials(this.props.data || {});
        this.props.propertyPropsActions.getListProperty();
      }
    
      componentWillReceiveProps (newProps) {
        let { materials, selectedMaterial, colors, sizes } = this.state;
        !_.isEqual(selectedMaterial, newProps.selectedMaterial) && this.setState({selectedMaterial: newProps.selectedMaterial || {} });
        !_.isEqual(materials, newProps.materialState.items) && this.setState({materials: _.cloneDeep(newProps.materialState.items) || []});
        let properties = newProps.propertyState.items;
        let colorsTmp = _.filter(properties, (property) => property.key == 'color');
        let sizeTmp = _.filter(properties, (property) => property.key == 'size');
        colorsTmp[0] && !_.isEqual(colors, colorsTmp[0].values || []) && this.setState({colors: colorsTmp[0].values || [] });
        sizeTmp[0] && !_.isEqual(sizes, sizeTmp[0].values || []) && this.setState({sizes: sizeTmp[0].values || [] });
      }

    getListMaterials (data) {
        this.props.materialActions.getListMaterial(data);
      }
    
      onChange (property, value) {
        let {data, materials, selectedMaterial} = this.state;
        if(property == "material") {
          let mat = materials.find(vl => vl._id.toString() == value);
          if(mat) {
            selectedMaterial = mat;
            this.setState({selectedMaterial});
            data.content = mat.data.content;
            data.unit = mat.data.unit;
            data.sizeList = mat.data.size || [];
            data.colorList = mat.data.color || [];
            data = _.set(data, 'color', '');
            data = _.set(data, 'size', '');
          }
        }
        
        data = _.set(data, property, value);
        this.setState({data});
    
        // update to parent
        let {updateInfo} = this.props;
        updateInfo && updateInfo(_.cloneDeep(data));
      }
    
      close () {
        this.props.closeModal();
      }
    
      validate () {
        let {data, errors} = this.state;
        let fields = {material: 'Material', color: 'Color', quantity: 'Quantity', usable: 'Usable'};
    
        Object.keys(fields).forEach(f => {
          _.set(errors, f, !data[f] ? `Please select ${fields[f]}` : '');
        });
        if(parseFloat(data.quantity) < parseFloat(data.usable)) {
          _.set(errors, 'usable', 'Usable must be not greater than Quantity');
        }
        this.setState({errors});
    
        let flag = Object.keys(errors).every(k => !errors[k]);
    
        return flag;
      }

      save () {
        let {data, selectedMaterial, colors, sizes} = this.state;
        if(selectedMaterial._id)
          data.material = selectedMaterial;
        data.unusable = parseFloat(data.quantity - data.usable).toFixed(2);
        if(this.validate()) {
          this.props.onSave(data);
          this.props.closeModal();
        }
      }
    
      render () {
        let {materials, errors, data, selectedMaterial, colors, sizes} = this.state;
    
        return (
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <form>
                <div className="modal-header">
                  <a className="close" onClick={() => this.close()}><span aria-hidden="true">&times;</span></a>
                  <h4 className="modal-title" id="myModalLabel">{I18n.t('PagePurchase.ADDMATERIAL')}</h4>
                </div>
    
                <div className="modal-body">
    
                  <div className="form-group">
                    <div className="col-md-3 col-xs-12 text">
                      <p>{I18n.t('PagePurchase.Material')}</p>
                    </div>
                    <div className="col-md-9 col-xs-12 value">
                      <select className="form-control" value={selectedMaterial._id || ''} onChange={evt => this.onChange('material', evt.target.value)}>
                        <option value="" />
                        {materials.map((i, idx) => (<option key={idx} value={i._id}>{i.name}{i.data && i.data.content? ' - '+ i.data.content: ''}</option>))}
                      </select>
                      {errors['material'] && <div className="alert-warning">{errors['material']}</div>}
                    </div>
                  </div>

                  <div className="form-group">
                    <div className="col-md-3 col-xs-12 text">
                      <p>{I18n.t('PageYield.Content')}</p>
                    </div>
                    <div className="col-md-9 col-xs-12 value">
                      <input type="text" className="form-control" value={data.content || ''} onChange={evt => this.onChange('content', evt.target.value)} disabled />
                    </div>
                  </div>

                  <div className="form-group">
                    <div className="col-md-3 col-xs-12 text">
                      <p>{I18n.t('PageYield.Color')}</p>
                    </div>
                    <div className="col-md-9 col-xs-12 value">
                    <select className="form-control" value={data.color || ''} onChange={evt => this.onChange('color', evt.target.value)}>
                        <option value="" />
                        {selectedMaterial._id && colors.map((i, idx) => (<option key={idx} value={i}>{i}</option>))}
                      </select>
                      {errors['color'] && <div className="alert-warning">{errors['color']}</div>}
                    </div>

                  </div>

                  <div className="form-group">
                    <div className="col-md-3 col-sm-6 col-xs-12 text">
                      <p>{I18n.t('PageYield.Unit')}</p>
                    </div>
                    <div className="col-md-3 col-sm-6 col-xs-12 value">
                      <input type="text" className="form-control center" value={data.unit || ''} onChange={evt => this.onChange('unit', evt.target.value)} disabled />
                    </div>

                    <div className="col-md-3 col-xs-12 text right">
                      <p>{I18n.t('PageYield.Roll')}</p>
                    </div>
                    <div className="col-md-3 col-sm-6 col-xs-12 value">
                      <input type="text" className="form-control center" value={data.roll || ''} onChange={evt => this.onChange('roll', evt.target.value)} placeholder='0' />
                    </div>
                  </div>

                  <div className="form-group">
                    <div className="col-md-3 col-sm-6 col-xs-12 text">
                      <p>{I18n.t('PageYield.Quantity')}</p>
                    </div>
                    <div className="col-md-3 col-sm-6 col-xs-12 value">
                      <TextNumberInput className="form-control center" value={data.quantity || ''} onChange={val => this.onChange('quantity', val)}  placeholder='0' />
                      {errors['quantity'] && <div className="wd-160 alert-warning">{errors['quantity']}</div>}
                    </div>

                    <div className="col-md-3 col-xs-12 text right">
                      <p>{I18n.t('PageYield.Size')}</p>
                    </div>
                    <div className="col-md-3 col-sm-6 col-xs-12 value">
                      <select className="form-control" disabled={selectedMaterial.type == 'FABRIC'} value={data.size || ''} onChange={evt => this.onChange('size', evt.target.value)}>
                        <option value="" />
                        {selectedMaterial._id && sizes.map((i, idx) => (<option key={idx} value={i}>{i}</option>))}
                      </select>
                    </div>
                    
                  </div>

                  <div className="form-group">
                    <div className="col-md-3 col-sm-6 col-xs-12 text">
                      <p>{I18n.t('PageYield.Usable')}</p>
                    </div>
                    <div className="col-md-3 col-sm-6 col-xs-12 value">
                      <TextNumberInput className="form-control center" value={data.usable || ''} onChange={val => this.onChange('usable', val)}  placeholder='0' />
                      {errors['usable'] && <div className="wd-160 alert-warning">{errors['usable']}</div>}
                    </div>

                    <div className="col-md-3 col-xs-12 text right">
                      <p>{I18n.t('PageYield.UnUsable')}</p>
                    </div>
                    <div className="col-md-3 col-sm-6 col-xs-12 value">
                      <input type="text" className="form-control center" value={(data.quantity - data.usable) ? (data.quantity - data.usable).toFixed(2) : 0}  placeholder='0' disabled/> 
                    </div>
                   
                  </div>
                </div>
    
                <div className="modal-footer">
                  <button type="button" className="bt-black" onClick={() => this.close()}>{I18n.t('Btn.CANCEL')}</button>
                  <button type="button" className="bt-orange mg-l-5" onClick={() => this.save()}>{I18n.t('Btn.SAVE')}</button>
                </div>
              </form>
            </div>
          </div>
        );
      }
}

function mapStateToProps(state, ownProps) {
  return {
    ...ownProps,
    authState: state.authState,
    materialState: state.materialState,
    supplierState: state.supplierState,
    orderState: state.orderState,
    propertyState: state.propertyState
  };
}

function mapDispatchToProps(dispatch) {
  return {
    supplierPropsActions: bindActionCreators(supplierActions, dispatch),
    materialActions: bindActionCreators(materialActions, dispatch),
    orderPropsActions: bindActionCreators(orderActions, dispatch),
    propertyPropsActions: bindActionCreators(propertyActions, dispatch)
  };
}

AddMaterial.propTypes = {
  authState: PropTypes.object.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(AddMaterial);
