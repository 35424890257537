import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { withRouter } from 'react-router';
import I18n from 'i18n-js';
import * as PATH from '../../../../constants/routeConstants';
import AccountsListInvoiceView from '../../../components/accounting/receivable/AccountsListHistoryReceivableInvoice';
import CreateInvoiceRequestModal from '../../../components/accounting/PayableInvoiceList/CreateInvoiceRequestModal';
import DeleteInvoiceModal from '../../../components/accounting/DeleteInvoiceModal';
import * as actions from '../../../../actions/accounting/receivable/showInvoiceListHistory';
import * as selector from '../../../../selectors/accounting/receivable/showInvoiceListHistory';

import * as actionsPreview from '../../../../actions/accounting/receivable/previewInvoice';

class AccountsListHistoryInvoice extends Component {

    constructor(props) {
        super(props);
        this.state = {
            openReceiptModal: false,
            openPreviewInvoiceModal: false,
            openDeleteInvoiceModal: false,
            loading: true
        }
    }

    componentWillMount() {
        this.props.changeLoading(true);
        this.props.loadData(this.props.paramsRequest);
    }

    componentWillReceiveProps(nextProps) {
        const { createInvoiceRequestStatus, deleteSuccess, paramsRequest, loading } = nextProps;
        if (createInvoiceRequestStatus) {
            nextProps.resetInvoiceRequest();
            toastr.success(I18n.t('Notify.Success'), 'Create Success');
        }
        if (deleteSuccess) {
            nextProps.resetDeleteStatus();
            toastr.success(I18n.t('Notify.Success'), 'Delete Success');
        }
        if (loading) {
            // this.getListInvoice(paramsRequest || {});
            nextProps.changeLoading(false);
        }
    }
    getListInvoice = (params) => {
        this.props.getListInvoice(params);
    }

    openReceiptModal = () => {
        this.setState({
            openReceiptModal: true
        })
    }

    closeReceiptModal = () => {
        this.setState({
            openReceiptModal: false
        })
    }

    openPreviewInvoiceModal = () => {
        this.setState({
            openPreviewInvoiceModal: true
        })
    }

    closePreviewInvoiceModal = () => {
        this.setState({
            openPreviewInvoiceModal: false,
            openDeleteInvoiceModal: false
        })
        this.props.resetInvoiceRequest()
    }

    createINVRequest = (listInv, invRequest) => {
        this.setState({ openPreviewInvoiceModal: false })
        this.props.createINVRequest(listInv, invRequest)
    }

    openDeleteInvoiceModal = (value) => {
        this.setState({
            openDeleteInvoiceModal: true
        });
        this.props.changeInvoiceWillBeDeleted(value)
    }

    deleteInvoice = (id, params) => {
        this.props.deleteInvoice(id, params);
        this.setState({
            openDeleteInvoiceModal: false
        });
    }

    render() {
        const { openReceiptModal, openPreviewInvoiceModal, openDeleteInvoiceModal } = this.state;
        const { invoiceRequest, listCheckedInvoice, invoiceWillBeDeleted, paramsRequest } = this.props;
        return (
            <div>
                <AccountsListInvoiceView 
                    {...this.props} 
                    openPreviewInvoiceModal={this.openPreviewInvoiceModal}
                    openDeleteInvoiceModal={this.openDeleteInvoiceModal}
                    openReceiptModal={this.openReceiptModal} 
                />
                {/* <ReceiptModal openModal={openReceiptModal} close={this.closeReceiptModal} /> */}
                <CreateInvoiceRequestModal
                    openModal={openPreviewInvoiceModal}
                    invoiceRequest={invoiceRequest}
                    listCheckedInvoice={listCheckedInvoice}
                    createINVRequest={(listInv, invRequest) => this.createINVRequest(listInv, invRequest)}
                    changeInvoiceRequest={(value) => this.props.changeInvoiceRequest(value)}
                    close={this.closePreviewInvoiceModal}
                />
                <DeleteInvoiceModal
                    openModal={openDeleteInvoiceModal}
                    id={invoiceWillBeDeleted}
                    delete={(value) => this.deleteInvoice(value, paramsRequest)}
                    close={this.closePreviewInvoiceModal}
                />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    listInvoice: selector.getReceivableInvoices(state),
    listFilter: selector.getSelectableFilters(state),
    selectedFilter: selector.getSelectedFilter(state),
    searchValue: selector.getSearchValue(state),
    invoiceRequest: selector.getInvoiceRequest(state),
    // listPaid: selector.getPaidDetail(state),
    currentPage: selector.getCurrentPage(state),
    totalPage: selector.getTotalPages(state),
    listCheckedInvoice: selector.getCheckedInvoice(state),
    // createInvoiceRequestStatus: selector.getCreateInvoiceRequestStatus(state),
    deleteSuccess: selector.getDeleteSuccess(state),
    // currency: selector.getCurrency(state),
    paramsRequest: selector.getParamsRequest(state),
    loading: selector.getLoading(state),
    totalAmount: selector.getTotalAmount(state),
    totalPaid: selector.getTotalPaid(state),
    totalRemaining: selector.getTotalRemaining(state),
    invoiceWillBeDeleted: selector.getInvoiceWillBeDeleted(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    loadData(params) {
        dispatch(actions.getListInvoice(params))
        dispatch(actions.getAmountInvoice(params))
    },
    getListInvoice(params) {
        dispatch(actions.getListInvoice(params))
    },
    changeLoading(value) {
        dispatch(actions.changeLoading(value))
    },
    checkedInvoice(id, checked) {
        dispatch(actions.checkedInvoice(id, checked))
    },
    edit(item) {
        
    },
    changeInvoiceRequest(value) {
        dispatch(actions.changeInvoiceRequest(value))
    },
    resetInvoiceRequest() {
        dispatch(actions.resetInvoiceRequest())
    },
    deleteInvoice(id, params) {
        dispatch(actions.deleteInvoice(id, params))
    },
    changeInvoiceWillBeDeleted(id) {
        dispatch(actions.changeInvoiceWillBeDeleted(id))
    },
    createINVRequest(listInv, invRequest) {
        dispatch(actions.createINVRequest(listInv, invRequest))
    },
    onChangeFilter(value) {
        dispatch(actions.changeSelectedFilter(value))
    },
    onChangeSearchValue(value, pos) {
        dispatch(actions.changeSearchValue(value, pos))
    },
    onSearch(params) {
        params.pageNumber = 1;
        dispatch(actions.changePage(params.pageNumber));
        dispatch(actions.getListInvoice(params));
        dispatch(actions.getAmountInvoice(params))
    },
    // showPaidPopup(id) {
    //     dispatch(actions.getPaidList(id))
    // },
    view(id) {
        dispatch(actionsPreview.getDetailInvoice(id));
        ownProps.history.push(PATH.ACCOUNTING_RECEIVABLE_INVOICE_VIEW_URL);
    },
  
    resetDeleteStatus() {
        dispatch(actions.resetDeleleStatus());
    },
    changePage(params) {
        dispatch(actions.changePage(params.pageNumber));
        dispatch(actions.getListInvoice(params));
    },
    export() {

    }
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AccountsListHistoryInvoice));