import C from "../../../constants/accounting/payable/editInvoiceIEContainer";
import accountingServices from '../../../services/accountingServices';
import {toastr} from 'react-redux-toastr';
import I18n from 'i18n-js';

export const activeScreenEditContainer = () => dispatch => {
    dispatch({type: C.ACTIVE_SCREEN_STATUS, payload: true})
} 

export const getInvoiceIEContainerDetail = (id) => dispatch => {
    accountingServices.getInvoiceDetail(id).then(res => {
        if(res.success) {
            dispatch({type: C.CHANGE_INVOICE_DETAIL, payload: res.data})
            dispatch({type: C.CHANGE_COMPANY_VALUE, payload: res.data.invoiceDetail.name});
        }
    })
}

export const changeName = (value) => dispatch => {
    dispatch({type: C.CHANGE_NAME_VALUE, payload: value})
}
export const changeInvoiceNo = (value) => dispatch => {
    dispatch({type: C.CHANGE_INVOICE_NO_VALUE, payload: value})
}
export const changeIssuedDate = (value) => dispatch => {
    dispatch({type: C.CHANGE_ISSUED_DATE_VALUE, payload: value})
}
export const changeDueDate = (value) => dispatch => {
    dispatch({type: C.CHANGE_DUE_DATE_VALUE, payload: value})
}
export const changePayAmount = (value, remaining, paid) => dispatch => {
    if(value > remaining) {
        toastr.error('Error', 'Pay Amount can not be more than Remaining');
        return;
    } 
    if(paid >= remaining) {
        toastr.warning('Warning', 'Completly payment');
        return;
    }
    if(value <= remaining) {
        dispatch({type: C.CHANGE_PAY_AMOUNT_VALUE, payload: value})
    }
}

export const changeRawData = (data) => dispatch => {
    dispatch({type: C.COPY_CONTAINER_DETAIL_TO_RAW_DATA, payload: data})
}

export const changePaymenttype = (value) => dispatch => {
    dispatch({type: C.CHANGE_PAYMENT_TYPE, payload: value})
}

export const changeContainerDetailValue = (key, value) => dispatch => {
    dispatch({type: C.CHANGE_RAW_DATA_VALUE, payload: {key, value}})
}

export const saveChangeContainerDetail = (data) => dispatch => {
    dispatch({type: C.SAVE_CHANGE_CONTAINER_DETAIL, payload: data})
}

export const addFileTransfer = (file) => dispatch => {
    var FileSize = file.size / 1024 / 1024; // in MB
  if (FileSize > 2) {
    toastr.error('Error', I18n.t('ErrorMessages.FileSizeExceeds2MB'));
    return;
  } 
  const readerBase64 = new FileReader();
  readerBase64.onload = (eventBase64) => {
    const url = eventBase64.target.result;
    const filename = file.name;

    const payload = { id: filename, file, filename, url, }
    dispatch({ type: C.ADD_TRANSFER_FILE, payload });
  };
  readerBase64.readAsDataURL(file);
}

export const onRemoveFile = (id) => dispatch => {
    dispatch({ type: C.REMOVE_TRANSFER_FILE, payload: id });
}

export const save = (paymentType, data) => dispatch => {
    accountingServices.updateInvoice(data._id, paymentType, data).then(res => {
        if(res.success) {
            dispatch({type: C.SAVE_SUCCESS, payload: true})
        }
    })
}

export const resetSuccessStatus = () => dispatch => {
    dispatch({type: C.RESET_DATA_ALL})
}

export const copyFileToRawData = (files) => dispatch => {
    dispatch({ type: C.COPY_FILES_TO_RAW_DATA, payload: files });
}

export const emptyFilesInRawData = () => dispatch => {
    dispatch({ type: C.EMPTY_FILES_IN_RAW_DATA });
}

export const onSubmitFiles = (files) => dispatch => {
    dispatch({ type: C.SUBMIT_FILES, payload: files });
}

export const createReceipt = (value) => dispatch => {
    dispatch({ type: C.CREATE_RECEIPT, payload: value });
}

export const changeReceiptValue = (key, value) => dispatch => {
    dispatch({ type: C.CHANGE_RECEIPT_VALUE, payload: {key, value} });
}

export const onSubmitReceipt = (data) => dispatch => {
    dispatch({ type: C.SUBMIT_RECEIPT, payload: data });
}

export const changeEmptyReceipt = () => dispatch => {
    dispatch({ type: C.EMPTY_RECEIPT});
}