import Base from './base';

class Organization extends Base {
  constructor () {
    super();

    this.getListOrganizationDAL = this.getListOrganizationDAL.bind(this);
    this.editOrganizationDAL = this.editOrganizationDAL.bind(this);
    this.addOrganizationDAL = this.addOrganizationDAL.bind(this);
    this.deleteOrganizationDAL = this.deleteOrganizationDAL.bind(this);
  }

  getListOrganizationDAL (query) {
    return super.execute(this.Urls.organization.list, this.ApiMethod.get, query);
  }

  editOrganizationDAL (id, body) {
    return super.execute(this.Urls.organization.update + '/' + id, this.ApiMethod.put, body)
        ;
  }

  addOrganizationDAL (body) {
    return super.execute(this.Urls.organization.create, this.ApiMethod.post, body)
        ;
  }

  deleteOrganizationDAL (id) {
    return super.execute(this.Urls.organization.delete + '/' + id, this.ApiMethod.delete, null)
        ;
  }

}

export default new Organization();
