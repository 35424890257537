import {combineReducers} from 'redux';
import C from '../../../constants/accounting/receivable/showInvoiceListHistory';
import _ from "lodash";

/**
 * Store helper information to check whether invoice list is fetching...
 */
const fetchingPayableInvoices = (state = false, action) => {
    const { type } = action;
    switch (type) {
        // TODO. handle related actions
        default:
            return state;
    }
}

// /**
//  * Store total amount
//  */
const totalAmount = (state = null, action) => {
    const {payload, type} = action;
    switch (type) {
        case C.CHANGE_TOTAL_AMOUNT:
            return payload;
        default:
            return state;
    }
}

// /**
//  * Store total remaining
//  */
const totalRemaining = (state = null, action) => {
    const {payload, type} = action;
    switch (type) {
        case C.CHANGE_TOTAL_REMAINING:
            return payload;
        default:
            return state;
    }
}

// /**
//  * Store total paid
//  */
const totalPaid = (state = null, action) => {
    const {payload, type} = action;
    switch (type) {
        case C.CHANGE_TOTAL_PAID:
            return payload;
        default:
            return state;
    }
}

/**
 * Store a list of payable invoices
 */
const receivableInvoices = (state = [], action) => {
    const {payload, type} = action;
    let copyState = _.clone(state);
    switch (type) {
        case C.CHANGE_LIST_INV:
            return payload;
        case C.CREATE_INV_REQUEST_SUCCESS:
            copyState = _.map(copyState, (el) => {
                let i = _.findIndex(payload.ids, (id) => el._id == id || el.id == id);
                if(i >= 0) {
                    el.invRequest = payload.invRequest;
                    el.checked = false;
                }
                return el;
            })
            return copyState;
        case C.CHANGE_CHECKED_INVOICE:
            let i = _.findIndex(copyState, (el) => el._id == payload._id);
            if(i >= 0) copyState[i].checked = payload.checked;
            return copyState;
        default:
            return state;
    }
}

// /**
//  * Store a list of payable invoices
//  */
// const paidDetail = (state = [], action) => {
//     const {payload, type} = action;
//     switch (type) {
//         case C.CHANGE_PAID_DETAIL:
//             return payload;
//         default:
//             return state;
//     }
// }

// /**
//  * Store a list of checked invoices
//  */
const checkedInvoices = (state = [], action) => {
    const {payload, type} = action;
    switch (type) {
        case C.CHANGE_CHECKED_INVOICE:
            const { _id, checked } = payload;
            let filtered = _.filter(_.cloneDeep(state), it => it !== _id);
            if (checked) filtered = [...filtered, _id];
            return filtered;
        case C.CREATE_INV_REQUEST_SUCCESS:
            return [];
        default:
            return state;
    }
}

// /**
//  * Store a list of selectable filters
//  */
const selectableFilters = (state = [], action) => {
    const {payload, type} = action;
    switch (type) {
        case C.CHANGE_LIST_FILTER:
            return payload;
        default:
            return state;
    }
}

// /**
//  * checked List of data
//  */
// const checkedList = (state = [], action) => {
//     const {payload, type} = action;
//     switch (type) {
//         case C.TOGLE_CHECK_DATA:
//             let find = _.filter(state, payload);
//             find.length == 0 ? state.push(payload) : _.remove(state, (n) => {
//                 return n == payload;
//             });
//             return state;
//         default:
//             return state;
//     }
// }

// /**
//  * Store selected filter
//  */
const selectedFilter = (state = null, action) => {
    const {payload, type} = action;
    switch (type) {
        case C.CHANGE_SELECT_FILTER:
            return payload;
        default:
            return state;
    }
}

// /**
//  * Store search value
//  */

const searchValue = (state = {}, action) => {
    const {type, payload} = action;
    switch (type) {
        case C.CHANGE_SEARCH_VALUE:
            return {...state, [payload.pos]: payload.data};
        default:
            return state;
    }
}

// /**
//  * change currency
//  */
// const changeCurrency = (state = 'VND', action) => {
//     const {payload, type} = action;
//     switch (type) {
//         case C.CHANGE_CURRENCY:
//             return payload;
//         default:
//             return state;
//     }
// }


// /**
//  * Store input information for issued date field
//  */
// const fromDate = (state = null, action) => {
//     const {payload, type} = action;
//     switch (type) {
//         case C.CHANGE_FROM_DATE:
//             return payload;
//         default:
//             return state;
//     }
// }

// /**
//  * Store input information for due date field
//  */
// const toDate = (state = null, action) => {
//     const {payload, type} = action;
//     switch (type) {
//         case C.CHANGE_TO_DATE:
//             return payload;
//         default:
//             return state;
//     }
// }

// /**
//  * Store paging information for total pages
//  */
const totalPages = (state = null, action) => {
    const {payload, type} = action;
    switch (type) {
        case C.CHANGE_PAGING_TOTAL:
            return payload;
        default:
            return state;
    }
}

// /**
//  * Store paging information for current page
//  */
const currentPage = (state = 1, action) => {
    const {payload, type} = action;
    switch (type) {
        case C.CHANGE_PAGING_CURRENT:
            return payload;
        default:
            return state;
    }
}

const invoiceRequest = (state = '', action) => {
    const {type, payload} = action;
    switch(type) {
        case C.CHANGE_INVOICE_REQUEST:
            return payload;
        case C.RESET_INVOICE_REQUEST:
            return '';
        default:
            return state
    }
}

const createInvoiceRequestSuccess = (state = false, action) => {
    const {type, payload} = action;
    switch(type) {
        case C.CREATE_INV_REQUEST_SUCCESS:
            return payload.success;
        case C.RESET_INVOICE_REQUEST:
            return false;
        default:
            return state;
    }
}

const deleteSuccess = (state = false, action) => {
    const {type, payload} = action;
    switch(type) {
        case C.DELETE_SUCCESS:
            return payload;
        case C.RESET_DELETE_STATUS:
            return false;
        default:
            return state;
    }
}

const loading = (state = true, action) => {
    const {type, payload} = action;
    switch(type) {
        case C.CHANGE_LOADING:
            return payload;
        default:
            return state;
    }
}

// /**
//  * Store selected filter
//  */
const invoiceWillBeDeleted = (state = null, action) => {
    const {payload, type} = action;
    switch (type) {
        case C.CHANGE_INVOICE_WILL_BE_DELETED:
            return payload;
        default:
            return state;
    }
}

export const showReceivableInvoiceListHistory = combineReducers({
    fetchingPayableInvoices,
    totalAmount,
    totalPaid,
    totalRemaining,
    receivableInvoices,
    checkedInvoices,
    // checkedList,
    selectableFilters,
    selectedFilter,
    searchValue,
    // fromDate,
    // toDate,
    // changeCurrency,
    totalPages,
    currentPage,
    // paidDetail,
    invoiceRequest,
    createInvoiceRequestSuccess,
    deleteSuccess,
    loading,
    invoiceWillBeDeleted
});
