import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {toastr} from 'react-redux-toastr'
import { bindActionCreators } from 'redux';
import BaseComponent from '../../base/BaseComponent';
import styleActions from '../../../actions/styleActions';
import purchasePlanActions from '../../../actions/purchasePlanActions';
import _ from 'lodash';
import I18n from 'i18n-js';

class PlansTable extends BaseComponent {
  constructor (props, context) {
    super(props, context);
    const {isAdditionalOrder} = props
    const items = isAdditionalOrder ? _.get(props, 'order.styleOrders', []) : []
    this.state = {
      isAdditionalOrder: isAdditionalOrder || false,
      items,
      stylePurchasePlan: {},
      currentStyle: (isAdditionalOrder && items && items[0]) ?  items[0]._id : {},
      chxTrim: true,
      chxFabric: true
    };

  }

  componentWillMount = () => {
    const {purchasePlanState} = this.props;
    const {isAdditionalOrder, currentStyle, items} = this.state;
    if(isAdditionalOrder) {
      this.props.purchasePlanPropsActions.getPurchasePlan(currentStyle, {type: 'ORIGINAL', populate: 'materials.material', additional: true});
    }
    isAdditionalOrder && this.props.selectStyle(items[0]);
    this.setState({stylePurchasePlan: _.get(purchasePlanState, "planByStyle", {})});
  }

  componentWillReceiveProps = (newProps) => {
    const {selectedStyle, selectedStylePurchasePlan} = newProps;
    let {items, stylePurchasePlan} = this.state;
    if(_.get(newProps, 'order.styleOrders', []).length > 0 && !_.isEqual(newProps.purchasePlanState.planByStyle, stylePurchasePlan)){
      this.setState({stylePurchasePlan: _.cloneDeep(newProps.purchasePlanState.planByStyle || selectedStylePurchasePlan)});
    }
    if(!_.isEqual(items, _.get(newProps, 'order.styleOrders', []))) {
      let currentStyle = (newProps.order.styleOrders && newProps.order.styleOrders.length > 0 ? newProps.order.styleOrders[0] : {});
      if(!currentStyle){
      currentStyle = (selectedStyle && selectedStyle._id) ? selectedStyle : currentStyle;
      }
      this.setState({
        items: _.cloneDeep(_.get(newProps, 'order.styleOrders', [])),
        selected: {},
      });
      if(this.state.stylePurchasePlan.styleId) currentStyle._id = this.state.stylePurchasePlan.styleId;
      this.styleChange(currentStyle._id, selectedStylePurchasePlan || newProps.purchasePlanState.planByStyle || this.props.purchasePlanState.planByStyle);
      newProps.selectStyle(currentStyle);
    }
  }

  styleChange = (value, selectedStylePurchasePlan) => {
    if(value) {
      const {items, currentStyle, isAdditionalOrder} = this.state;
      this.props.selectStyle(items.find(style => style._id === value || style._id === this.state.styleId));
      if (value !== this.state.currentStyle && value !== '-') {
        this.props.purchasePlanPropsActions.getPurchasePlan(value, {type: 'ORIGINAL', populate: 'materials.material', additional: isAdditionalOrder});
      }
    }
    this.setState({currentStyle: value, stylePurchasePlan: selectedStylePurchasePlan||{}});
    this.props.setStylePurchasePlan && this.props.setStylePurchasePlan();
  }

  onSelect = (name, idx, value) => {
    const {stylePurchasePlan} = this.state;
    const materials = _.get(stylePurchasePlan, 'materials', [])

    if (name === 'supplier') {
      const selectedSupplier = materials[idx].availableSuppliers.find(supplier => supplier.supplierId === value);
      const price = selectedSupplier?selectedSupplier.price:0
      const currency = selectedSupplier?selectedSupplier.currency:''
      materials[idx] = {...materials[idx], supplier: (value == '-' ? undefined : value), price, amount: this.convertUnit(materials[idx].needToBuy, materials[idx].material) * price, currency};
    }
    if (name === 'needToBuy') {
      value = parseInt(value == "" ? 0 : value) || 0
      let needToBuy = value < 0 ? 0 : value
      const supplier = needToBuy == 0 ? undefined : materials[idx].supplier
      materials[idx] = {...materials[idx], needToBuy, supplier, amount: this.convertUnit(needToBuy, materials[idx].material) * materials[idx].price};
    }
    if (name === 'inStock') {
      value = parseInt(value == "" ? 0 : value) || 0
      let inStock = value < 0 ? 0 : value
      inStock = inStock > materials[idx].inStockReal ? materials[idx].inStockReal : inStock
      const needToBuy = materials[idx].quantity - inStock
      const supplier = needToBuy == 0 ? undefined : materials[idx].supplier
      materials[idx] = {...materials[idx], inStock, needToBuy, supplier, amount: this.convertUnit(needToBuy, materials[idx].material) * materials[idx].price};
    }
    this.setState({stylePurchasePlan: {...stylePurchasePlan, materials: materials}});
    // this.props.setStylePurchasePlan && this.props.setStylePurchasePlan({...stylePurchasePlan, materials: materials});
  }

  onChange = (id, property, value) => {
    const {purchase} = this.state;
    if (!id) {
      return this.setState({purchase: {...purchase, [property]: value}});
    }
    this.setState({
      purchase: {
        ...purchase, materials: purchase.materials.map(material => {
          if (material._id !== id) {
            return material;
          }
          if (property === 'needToBuy') {
            return {...material, [property]: value, amount: material.price * value};
          }
          return {...material, [property]: value};
        })
      }
    });
  }

  updatePlan = () => {
    const {stylePurchasePlan, currentStyle} = this.state;
    this.props.purchasePlanPropsActions.updatePurchasePlan(currentStyle, stylePurchasePlan);
  }


  previewPlan = () => {
    const { stylePurchasePlan, currentStyle, items, chxTrim, chxFabric } = this.state;

    const param = {
      ...stylePurchasePlan,
      materials: (stylePurchasePlan.materials || []).filter(material => ((chxTrim && _.get(material, 'material.type', '') == 'TRIM' || (chxFabric && _.get(material, 'material.type', '') == 'FABRIC'))))
    }

    let boolUseStock = _.findIndex(param.materials, vl => !vl.supplier && vl.needToBuy == 0 && vl.inStock > 0) >= 0
    let boolPlaceOrder = _.findIndex(param.materials, vl => !!vl.supplier) >= 0
    let boolPlaceOrderN = _.findIndex(param.materials, vl => !!vl.supplier && vl.needToBuy > 0) >= 0

    if(!boolUseStock && !boolPlaceOrder) toastr.info(I18n.t('Notify.Info'), I18n.t('ErrorMessages.PleaseChooseSupplier'));
    else if(!boolUseStock && !boolPlaceOrderN) toastr.info(I18n.t('Notify.Info'), I18n.t('ErrorMessages.PleaseInputNeedToBuy'));
    else {
      this.props.previewOrder(param);
    }
  }

  checkMaterialType = (bool, field) => {
    this.setState({[field]: bool})
  }

  render = () => {
    let { currentStyle, stylePurchasePlan, chxFabric, chxTrim, isAdditionalOrder, items} = this.state;
    const idxStyleItem = _.findIndex(items || [], i => i._id == currentStyle)
    // console.log(this.state)
    return (
      <div>
        <div className="md content-main-process mg-top-10 bg-white">
          <div className="content-main-header">
            <ul className="content-main-header-filter-style mg-top-15 w-full">
              <li className="title">{I18n.t('PagePurchase.Style')}</li>
              <li className="select">
                <select className="form-control" value={currentStyle ? currentStyle : '-'} onChange={evt => this.styleChange(evt.target.value)}>
                  <option value="-">{I18n.t('PagePurchase.SelectAStyle')}</option>
                  {items.map((i, idx) => !i.closePurchase && (<option key={idx} value={i._id}>{i.style}</option>))}
                </select>
              </li>
              <li className="title fabric-trim">
                <div className="style-checkbox">
                  <input checked={chxFabric} type="checkbox" id="fabricChxId" onChange={(evt) => this.checkMaterialType(evt.target.checked, 'chxFabric')} />
                  <label style={{marginRight:'10px', cursor: 'pointer'}} htmlFor="fabricChxId"> <span><span className="text">Fabric</span></span></label>
                </div>
                <div className="style-checkbox">
                  <input checked={chxTrim} type="checkbox" id="trimChxId" onChange={(evt) => this.checkMaterialType(evt.target.checked, 'chxTrim')} />
                  <label style={{cursor: 'pointer'}} htmlFor="trimChxId"> <span><span className="text">Trim</span></span></label>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="md content-main-info table-purchase-plan">
          {
            idxStyleItem >= 0 && stylePurchasePlan.materials && stylePurchasePlan.materials.length > 0 && (
              <div className="wrap-global-table pd-0">
                <table style={{width: '100%'}} className="global-table">
                  <tbody>
                  <tr>
                    <th>{I18n.t('PagePurchase.Material')}</th>
                    <th>{I18n.t('PagePurchase.Content')}</th>
                    <th className="center">{I18n.t('PagePurchase.Replacement')}<br/>P.0 #</th>
                    <th className="center">{I18n.t('PagePurchase.Color')}</th>
                    <th className="center">{I18n.t('PagePurchase.Size')}</th>
                    {!isAdditionalOrder && (<th className="center">{I18n.t('PagePurchase.QTY')}</th>)}
                    <th className="center">{I18n.t('PagePurchase.Price')}</th>
                    {!isAdditionalOrder && (<th className="center">{I18n.t('PagePurchase.InStock')}</th>)}
                    <th className="center">{I18n.t('PagePurchase.NeedToBuy')}</th>
                    <th className="center w-140">{I18n.t('PagePurchase.Supplier')}</th>
                    <th className="center">{I18n.t('PagePurchase.Amount')}</th>
                  </tr>
                  {
                    stylePurchasePlan.materials.map((material, idx) => {
                      if(!chxTrim && _.get(material, 'material.type', '') == 'TRIM') return
                      if(!chxFabric && _.get(material, 'material.type', '') == 'FABRIC') return
                      const price = material.price > 0 ? (material.currency == 'USD' ? `$ ${material.price}` : `${material.price} ${material.currency}`) : 0
                      let amountSum = material.price * material.needToBuy;
                        amountSum =  parseFloat(amountSum).toFixed(2);
                      let amount = amountSum > 0 ? (material.currency == 'USD' ? `$ ${amountSum}` : `${amountSum } ${material.currency}`) : 0;

                      return (<tr key={idx}>
                        <td>{material.material.name}</td>
                        <td>{_.get(material, 'material.data.content', '')}</td>
                        <td className="center">{material.replacementPO}</td>
                        <td className="center">{material.color}</td>
                        <td className="center">{material.size}</td>
                        {!isAdditionalOrder && (<td className="center">{material.quantity}</td>)}
                        <td className="color-number center">{price}</td>
                        {!isAdditionalOrder && (<td className="center w-100">
                          <span>{material.inStock}</span>
                          {/* <input value={material.inStock} type="number" min="0" max={material.inStockReal} className="form-control center" onChange={evt => this.onSelect('inStock', idx, evt.target.value)} /> */}
                        </td>)}
                        <td className="center w-100">
                          <input value={material.needToBuy} type="number" min="0" className="form-control center" onChange={evt => this.onSelect('needToBuy', idx, evt.target.value)}/>
                        </td>
                        <td className="center select">
                          {material.needToBuy > 0 && (
                            <select className="w-full" value={material.supplier || '-'} onChange={evt => this.onSelect('supplier', idx, evt.target.value)}>
                              <option value="-">{I18n.t('PagePurchase.Select')}</option>
                              {
                                material.availableSuppliers.map(supplier => {
                                  return <option key={supplier.supplierId} value={supplier.supplierId}>{supplier.supplierName}</option>;
                                })
                              }
                            </select>
                          )}
                        </td>
                        <td className="color-number center">{amount}</td>
                      </tr>);
                    })
                  }
                  </tbody>
                </table>
                <div className="center">
                  <ul className="list-button">
                    <li>
                      {(chxTrim || chxFabric) && (<a onClick={this.previewPlan} className="bt-orange color-white">{I18n.t('PagePurchase.PreviewPurchase')}</a>)}
                    </li>
                  </ul>
                </div>
              </div>)
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    authState: state.authState,
    styleState: state.styleState,
    purchasePlanState: state.purchasePlanState
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    purchasePlanPropsActions: bindActionCreators(purchasePlanActions, dispatch),
  };
};

PlansTable.propTypes = {
  authState: PropTypes.object.isRequired,
  selectedStyle: PropTypes.object,
  stylePurchasePlan: PropTypes.object,
  setStylePurchasePlan: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(PlansTable);
