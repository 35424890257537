import React, { Component } from 'react';
import I18n from 'i18n-js';
import {connect} from 'react-redux';
import moment from 'moment';
import * as selector from '../../../selectors/accounting/payable/createInvoiceImpExpContainers';
const DateTimeField = require('react-datetime');

class ModalEditContainerDetail extends Component {

    constructor(props) {
        super(props);
        
    }
    
    ok = (data) => {
        this.props.closeModal();
        this.props.ok(data)
    }

    close = () => {
        this.props.closeModal();
        this.props.resetDataRaw();
    }

    render() {
        const {data} = this.props;
        return (
            <div className="modal-dialog lg" role="document">
                <div className="modal-content">
                    <form>
                    <div className="modal-header">
                        <h4 className="modal-title" id="myModalLabel">{'ADD ITEM'}</h4>
                    </div>
                    <div className="modal-body pd-0">
                        <h4 className="title">{data.cont}{data.contType ? ` - ${data.contType}` : ''}</h4>
                        
                        <div className="col-md-6 col-xs-12">
                            <div className="form-group">
                                <div className="col-md-4 col-xs-12 text no-padding-left">
                                    <p>Date</p>
                                </div>
                                <div className="col-md-8 col-xs-12 value no-padding-right">
                                    <DateTimeField className="form-control" 
                                        timeFormat={false} closeOnSelect dateFormat="MMM DD, YYYY"
                                        value= {moment(data.date)}
                                        onChange = {date => this.props.changeContainerDetailValue('date', date.format())}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 col-xs-12">
                            <div className="form-group">
                                <div className="col-md-4 col-xs-12 text no-padding-left">
                                    <p>Route</p>
                                </div>
                                <div className="col-md-8 col-xs-12 value no-padding-right">
                                    <input type="text" className="form-control" placeholder="Route" 
                                        value = {data.route}
                                        onChange = {e => this.props.changeContainerDetailValue('route', e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 col-xs-12">
                            <div className="form-group">
                                <div className="col-md-4 col-xs-12 text no-padding-left">
                                    <p>Quantity</p>
                                </div>
                                <div className="col-md-8 col-xs-12 value no-padding-right">
                                    <input type="text" className="form-control" placeholder="Quantity" value={data.qty}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-xs-12">
                            <div className="form-group">
                                <div className="col-md-4 col-xs-12 text no-padding-left">
                                    <p>Warehouse Address</p>
                                </div>
                                <div className="col-md-8 col-xs-12 value no-padding-right">
                                    <input type="text" className="form-control" placeholder="Warehouse Address" 
                                        value= {data.warehouseAddress}
                                        onChange = {e => this.props.changeContainerDetailValue('warehouseAddress', e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-xs-12">
                            <div className="form-group">
                                <div className="col-md-4 col-xs-12 text no-padding-left">
                                    <p>Type</p>
                                </div>
                                <div className="col-md-8 col-xs-12 value no-padding-right">
                                    <input type="text" className="form-control" placeholder="Type" 
                                        value= {data.type}
                                        onChange = {e => this.props.changeContainerDetailValue('type', e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-xs-12">
                            <div className="form-group">
                                <div className="col-md-4 col-xs-12 text no-padding-left">
                                    <p>Pickup Cont</p>
                                </div>
                                <div className="col-md-8 col-xs-12 value no-padding-right">
                                    <input type="text" className="form-control" placeholder="Pickup Cont"
                                        value= {data.pickupCont}
                                        onChange = {e => this.props.changeContainerDetailValue('pickupCont', e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-xs-12">
                            <div className="form-group">
                                <div className="col-md-4 col-xs-12 text no-padding-left">
                                    <p>Truck No</p>
                                </div>
                                <div className="col-md-8 col-xs-12 value no-padding-right">
                                    <input type="text" className="form-control" placeholder="Truck No"
                                        value= {data.truckNo}
                                        onChange = {e => this.props.changeContainerDetailValue('truckNo', e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-xs-12">
                            <div className="form-group">
                                <div className="col-md-4 col-xs-12 text no-padding-left">
                                    <p>Drop Off Cont</p>
                                </div>
                                <div className="col-md-8 col-xs-12 value no-padding-right">
                                    <input type="text" className="form-control" placeholder="Drop Off Cont"
                                        value= {data.dropOffCont}
                                        onChange = {e => this.props.changeContainerDetailValue('dropOffCont', e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="mg-bottom-15"></div>

                        <h4 className="title mg-top-10">FEE</h4>
                        <div className="col-md-6 col-xs-12">
                            <div className="form-group">
                                <div className="col-md-4 col-xs-12 text no-padding-left">
                                    <p>Cost</p>
                                </div>
                                <div className="col-md-8 col-xs-12 value no-padding-right">
                                    <input type="text" className="form-control" placeholder="Cost"
                                        value= {data.cost}
                                        onChange = {e => this.props.changeContainerDetailValue('cost', e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-xs-12">
                            <div className="form-group">
                                <div className="col-md-4 col-xs-12 text no-padding-left">
                                    <p>Charges Incurred</p>
                                </div>
                                <div className="col-md-8 col-xs-12 value no-padding-right">
                                    <input type="text" className="form-control" placeholder="Date" 
                                        value= {data.chargesIncurred}
                                        onChange = {e => this.props.changeContainerDetailValue('chargesIncurred', e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 col-xs-12">
                            <div className="form-group">
                                <div className="col-md-4 col-xs-12 text no-padding-left">
                                    <p>Lift On INV</p>
                                </div>
                                <div className="col-md-8 col-xs-12 value no-padding-right">
                                    <input type="text" className="form-control" placeholder="Lift On INV"
                                        value= {data.liftOnInv}
                                        onChange = {e => this.props.changeContainerDetailValue('liftOnInv', e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-xs-12">
                            <div className="form-group">
                                <div className="col-md-4 col-xs-12 text no-padding-left">
                                    <p>Lift Off INV</p>
                                </div>
                                <div className="col-md-8 col-xs-12 value no-padding-right">
                                    <input type="text" className="form-control" placeholder="Lift Off INV"
                                        value= {data.liftOffInv}
                                        onChange = {e => this.props.changeContainerDetailValue('liftOffInv', e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-xs-12">
                            <div className="form-group">
                                <div className="col-md-4 col-xs-12 text no-padding-left">
                                    <p>Lift On Fee</p>
                                </div>
                                <div className="col-md-8 col-xs-12 value no-padding-right">
                                    <input type="text" className="form-control" placeholder="Lift On Fee"
                                        value= {data.liftOnFee}
                                        onChange = {e => this.props.changeContainerDetailValue('liftOnFee', e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-xs-12">
                            <div className="form-group">
                                <div className="col-md-4 col-xs-12 text no-padding-left">
                                    <p>Lift Off Fee</p>
                                </div>
                                <div className="col-md-8 col-xs-12 value no-padding-right">
                                    <input type="text" className="form-control" placeholder="Lift Off Fee"
                                        value= {data.liftOffFee}
                                        onChange = {e => this.props.changeContainerDetailValue('liftOffFee', e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-xs-12">
                            <div className="form-group">
                                <div className="col-md-2 col-xs-12 text no-padding-left">
                                    <p>Note</p>
                                </div>
                                <div className="pd-0-10 col-md-10 col-xs-12 value no-padding-right">
                                    <textarea type="text" className="form-control" placeholder="Note"
                                        value= {data.note}
                                        onChange = {e => this.props.changeContainerDetailValue('note', e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>

                       
                    </div>
                    <div className="modal-footer">
                            <button type="button" className="bt-black" onClick={() => this.close()}>{I18n.t('Btn.CANCEL')}</button>
                            <button type="button" className="bt-orange" onClick={() => this.ok(data)}>{I18n.t('Btn.SUBMIT')}</button>
                    </div>
                    </form>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    data: selector.getTempData(state)
})

const mapDispatchToProps = dispatch => ({

})

export default connect(mapStateToProps, mapDispatchToProps) (ModalEditContainerDetail);