export const HOME_URL = "/";
export const LOGIN_URL = "/login";
export const RESET_PASSWORD_URL = "/resetPassword";
export const FORGOT_PASSWORD_URL = "/forgotPassword";
export const DASHBOARD_URL = "/dashboard";

export const CUSTOMER_PO_URL = "/customer-po";
export const CUSTOMER_PO_CURRENT_URL = `${CUSTOMER_PO_URL}/current`;
export const CUSTOMER_PO_CANCELLED_URL = `${CUSTOMER_PO_URL}/cancelled`;
export const CUSTOMER_PO_HISTORY_URL = `${CUSTOMER_PO_URL}/history`;
export const CUSTOMER_PO_HISTORY_VIEW_URL = `${CUSTOMER_PO_HISTORY_URL}/view`;

export const YIELD_URL = "/yield";
export const SAMPLE_APPROVAL_URL = "/sample-approval";

export const PURCHASE_URL = "/purchase";
export const PURCHASE_PLANS_URL = `${PURCHASE_URL}/plans`;
export const PURCHASE_PLANS_PREVIEW_URL = `${PURCHASE_PLANS_URL}/preview`;
export const PURCHASE_PLANS_PREVIEW_ORDER_URL = `${PURCHASE_PLANS_PREVIEW_URL}/previewOrder`;
export const PURCHASE_PLANS_PREVIEW_PLACE_ORDER_URL = `${PURCHASE_PLANS_PREVIEW_URL}/placeOrder`;
export const PURCHASE_FOLLOW_UP_URL = `${PURCHASE_URL}/follow-up`;
export const PURCHASE_LIST_URL = `${PURCHASE_URL}/list`;
export const PURCHASE_CANCEL_LIST_URL = `${PURCHASE_URL}/cancel-list`;
export const PURCHASE_HISTORY_LIST_URL = `${PURCHASE_URL}/history-list`;
export const PURCHASE_SHOW_URL = `${PURCHASE_URL}/show`;

export const PURCHASE_PREVIEW_URL = "/purchase/preview";
export const PURCHASE_ORDER_URL = "/purchase/order";
export const PURCHASE_HISTORY_VIEW_URL = "/purchase/history/view";
export const WAREHOUSE_URL = "/warehouse";
export const WAREHOUSE_STOCK_URL = `${WAREHOUSE_URL}/stock`;
export const WAREHOUSE_INBOUND_URL = `${WAREHOUSE_URL}/inbound`;
export const WAREHOUSE_INBOUND_SUPPLIER_URL = `${WAREHOUSE_INBOUND_URL}/suppiler`;
export const WAREHOUSE_INBOUND_CONTRACTOR_URL = `${WAREHOUSE_INBOUND_URL}/contractor`;
export const WAREHOUSE_OUTBOUND_URL = `${WAREHOUSE_URL}/outbound`;
export const WAREHOUSE_INBOUND_ADD_SUPPLIER_URL = `${WAREHOUSE_INBOUND_SUPPLIER_URL}/add`;
export const WAREHOUSE_INBOUND_EDIT_SUPPLIER_URL = `${WAREHOUSE_INBOUND_SUPPLIER_URL}/edit`;
export const WAREHOUSE_INBOUND_ADD_CONTRACTOR_URL = `${WAREHOUSE_INBOUND_CONTRACTOR_URL}/add`;
export const WAREHOUSE_INBOUND_EDIT_CONTRACTOR_URL = `${WAREHOUSE_INBOUND_CONTRACTOR_URL}/edit`;
export const PRODUCTION_URL = "/production";
export const SHIPPING_URL = "/shipping";
export const SHIPPING_SHIP_OUT_URL = `${SHIPPING_URL}/ship-out`;
export const SHIPPING_SHIP_IN_URL = `${SHIPPING_URL}/ship-in`;

export const ACCOUNTING_URL = "/accounting";
export const ACCOUNTING_PAYABLE_URL = `${ACCOUNTING_URL}/payable`;
export const ACCOUNTING_PAYABLE_PLAN_URL = `${ACCOUNTING_PAYABLE_URL}/plan`;
export const ACCOUNTING_PAYABLE_PLAN_SUPPLIER_URL = `${ACCOUNTING_PAYABLE_PLAN_URL}/supplier`;
export const ACCOUNTING_PAYABLE_PLAN_SUPPLIER_DOMESTIC_URL = `${ACCOUNTING_PAYABLE_PLAN_SUPPLIER_URL}/domestic`;
export const ACCOUNTING_PAYABLE_PLAN_SUPPLIER_DOMESTIC_PREVIEW_URL = `${ACCOUNTING_PAYABLE_PLAN_SUPPLIER_DOMESTIC_URL}/preview`;
export const ACCOUNTING_PAYABLE_PLAN_SUPPLIER_OVERSEA_URL = `${ACCOUNTING_PAYABLE_PLAN_SUPPLIER_URL}/oversea`;
export const ACCOUNTING_PAYABLE_PLAN_SUPPLIER_OVERSEA_PREVIEW_URL = `${ACCOUNTING_PAYABLE_PLAN_SUPPLIER_OVERSEA_URL}/preview`;
export const ACCOUNTING_PAYABLE_PLAN_CONTRACTOR_URL = `${ACCOUNTING_PAYABLE_PLAN_URL}/contractor`;
export const ACCOUNTING_PAYABLE_PLAN_CONTRACTOR_PREVIEW_URL = `${ACCOUNTING_PAYABLE_PLAN_CONTRACTOR_URL}/preview`;
export const ACCOUNTING_PAYABLE_PLAN_IMPORTEXPORT_URL = `${ACCOUNTING_PAYABLE_PLAN_URL}/import-export`;
export const ACCOUNTING_PAYABLE_PLAN_IMPORTEXPORT_CONTAINER_URL = `${ACCOUNTING_PAYABLE_PLAN_IMPORTEXPORT_URL}/container`;
export const ACCOUNTING_PAYABLE_PLAN_IMPORTEXPORT_CONTAINER_PREVIEW_URL = `${ACCOUNTING_PAYABLE_PLAN_IMPORTEXPORT_CONTAINER_URL}/preview`;
export const ACCOUNTING_PAYABLE_PLAN_IMPORTEXPORT_DECLARATION_URL = `${ACCOUNTING_PAYABLE_PLAN_IMPORTEXPORT_URL}/declaration`;
export const ACCOUNTING_PAYABLE_PLAN_IMPORTEXPORT_DECLARATION_PREVIEW_URL = `${ACCOUNTING_PAYABLE_PLAN_IMPORTEXPORT_DECLARATION_URL}/preview`;
export const ACCOUNTING_PAYABLE_PLAN_IMPORTEXPORT_US_DELIVERY_URL = `${ACCOUNTING_PAYABLE_PLAN_IMPORTEXPORT_URL}/us-delivery`;
export const ACCOUNTING_PAYABLE_PLAN_IMPORTEXPORT_US_DELIVERY_PREVIEW_URL = `${ACCOUNTING_PAYABLE_PLAN_IMPORTEXPORT_US_DELIVERY_URL}/preview`;
export const ACCOUNTING_PAYABLE_INVOICE_URL = `${ACCOUNTING_PAYABLE_URL}/invoice`;
export const ACCOUNTING_PAYABLE_INVOICE_VIEW_URL = `${ACCOUNTING_PAYABLE_INVOICE_URL}/view`;
export const ACCOUNTING_PAYABLE_INVOICE_CONTAINER_EDIT_URL = `${ACCOUNTING_PAYABLE_INVOICE_URL}/impexp-container-edit`;
export const ACCOUNTING_PAYABLE_INVOICE_DECLARATION_EDIT_URL = `${ACCOUNTING_PAYABLE_INVOICE_URL}/impexp-declaration-edit`;
export const ACCOUNTING_PAYABLE_INVOICE_US_DELIVERY_EDIT_URL = `${ACCOUNTING_PAYABLE_INVOICE_URL}/impexp-usdelivery-edit`;
export const ACCOUNTING_PAYABLE_INVOICE_CONTRACTOR_EDIT_URL = `${ACCOUNTING_PAYABLE_INVOICE_URL}/contractor-edit`;
export const ACCOUNTING_PAYABLE_INVOICE_DOMESTIC_EDIT_URL = `${ACCOUNTING_PAYABLE_INVOICE_URL}/supplier-domestic-edit`;
export const ACCOUNTING_PAYABLE_INVOICE_OVERSEA_EDIT_URL = `${ACCOUNTING_PAYABLE_INVOICE_URL}/supplier-oversea-edit`;
export const ACCOUNTING_PAYABLE_HISTORY_URL = `${ACCOUNTING_PAYABLE_URL}/history`;
export const ACCOUNTING_PAYABLE_HISTORY_CONTAINER_VIEW_URL = `${ACCOUNTING_PAYABLE_HISTORY_URL}/impexp-container-view`;
export const ACCOUNTING_PAYABLE_HISTORY_DECLARATION_VIEW_URL = `${ACCOUNTING_PAYABLE_HISTORY_URL}/impexp-declaration-view`;
export const ACCOUNTING_PAYABLE_HISTORY_US_DELIVERY_VIEW_URL = `${ACCOUNTING_PAYABLE_HISTORY_URL}/impexp-usdelivery-view`;
export const ACCOUNTING_PAYABLE_HISTORY_CONTRACTOR_VIEW_URL = `${ACCOUNTING_PAYABLE_HISTORY_URL}/contractor-view`;
export const ACCOUNTING_PAYABLE_HISTORY_DOMESTIC_VIEW_URL = `${ACCOUNTING_PAYABLE_HISTORY_URL}/supplier-domestic-view`;
export const ACCOUNTING_PAYABLE_HISTORY_OVERSEA_VIEW_URL = `${ACCOUNTING_PAYABLE_HISTORY_URL}/supplier-oversea-view`;
export const ACCOUNTING_CREDIT_DEBIT_URL = `${ACCOUNTING_URL}/credit-debit-notes`;

export const ACCOUNTING_RECEIVABLE_URL = `${ACCOUNTING_URL}/receivable`;
export const ACCOUNTING_RECEIVABLE_INVOICE_URL = `${ACCOUNTING_RECEIVABLE_URL}/invoice`;
export const ACCOUNTING_RECEIVABLE_HISTORY_URL = `${ACCOUNTING_RECEIVABLE_URL}/history`;
export const ACCOUNTING_RECEIVABLE_PLAN_URL = `${ACCOUNTING_RECEIVABLE_URL}/plan`;
export const ACCOUNTING_RECEIVABLE_PLAN_NORMAL_URL = `${ACCOUNTING_RECEIVABLE_PLAN_URL}/normal`;
export const ACCOUNTING_RECEIVABLE_PLAN_CHARGE_BACK_URL = `${ACCOUNTING_RECEIVABLE_PLAN_URL}/charge`;

export const ACCOUNTING_RECEIVABLE_PLAN_EDIT_CHARGE_BACK_URL = `${ACCOUNTING_RECEIVABLE_PLAN_CHARGE_BACK_URL}/edit/:payInfoId`;

export const ACCOUNTING_RECEIVABLE_PLAN_VIEW_CHARGE_BACK_URL = `${ACCOUNTING_CREDIT_DEBIT_URL}/view/:payInfoId`;

export const ACCOUNTING_RECEIVABLE_INVOICE_VIEW_URL = `${ACCOUNTING_RECEIVABLE_HISTORY_URL}/view`;
export const ACCOUNTING_RECEIVABLE_INVOICE_EDIT_NORMAL_URL = `${ACCOUNTING_RECEIVABLE_INVOICE_URL}/edit-normal`;
export const ACCOUNTING_RECEIVABLE_INVOICE_EDIT_CHARGEBACK_URL = `${ACCOUNTING_RECEIVABLE_INVOICE_URL}/edit-chargeback`;

export const REPORT_ANALYTIC_URL = "/report-analytic";
export const REPORT_ANALYTIC_CUSTOMER_PO_URL = `${REPORT_ANALYTIC_URL}/customerPO`;
export const REPORT_ANALYTIC_REVENUE_URL = `${REPORT_ANALYTIC_URL}/revenue`;

export const STANDARD_URL = "/standard";
export const STANDARD_MATERIALS_URL = `${STANDARD_URL}/materials`;
export const STANDARD_PACKINGS_URL = `${STANDARD_URL}/packings`;
export const STANDARD_PACKINGS_EDIT_URL = `${STANDARD_PACKINGS_URL}/edit`;
export const STANDARD_SHIPPINGS_URL = `${STANDARD_URL}/shippings`;
export const STANDARD_GARMENT_SIZES_URL = `${STANDARD_URL}/garment-sizes`;
export const STANDARD_CONTAINER_TYPES_URL = `${STANDARD_URL}/container-types`;

export const PACKING_STANDARD_ADD_URL = "/packing-standard/add";
export const ORGANIZATION_URL = "/organization";
export const CRM_URL = "/crm";
export const SYSTEM_SETTING_URL = "/system-settings";

const C = '/shipping/ship-out';
export const SHIP_OUT ={
    URL: C,
    PKL_PRODUCTION : {
        URL: `${C}/pkl-production`,
        VIEW: `${C}/pkl-production/view`,
        CREATE_CARTON: `${C}/pkl-production/create-carton`,
        CREATE_GOHH: `${C}/pkl-production/create-GOHH`,
        PREVIEW: `${C}/pkl-production/preview`
    },
    PKL_SHIP: {
        URL: `${C}/pkl-ship`,
        CREATE: `${C}/pkl-ship/create-shipment`,
        CREATE_INV: `${C}/pkl-ship/create-INV`,
        EDIT_INV: `${C}/pkl-ship/edit-INV`,
        EDIT: `${C}/pkl-ship/edit`,
        EDIT_REVIEW: `${C}/pkl-ship/edit-review`,
        CREATE_DETAIL: `${C}/pkl-ship/create-shipment/view`
    },
    SHIP: {
        URL: `${C}/ship`,
        EDIT: `${C}/ship/edit`,
        VIEW_DETAIL: `${C}/ship/edit/detail`
    },
    HISTORY: {
        URL: `${C}/history`,
        VIEW: `${C}/history/view`,
        VIEW_DETAIL: `${C}/history/view/detail`
    }

};