import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
const moment = require('moment');
import BaseComponent from '../../base/BaseComponent';
import DateTimeField from 'react-datetime';
import purchaseActions from '../../../actions/purchaseActions';
import purchasePlanActions from '../../../actions/purchasePlanActions';
import orderActions from '../../../actions/orderActions';
import purchaseOrderActions from '../../../actions/purchaseOrderActions';
import appActions from '../../../actions/appActions';
import AddMaterial from '../modal/AddMaterial';
import {
  MENU_ITEM,
  Payers,
  PERMISSION_ROLE,
  PURCHASE_STATUS,
  PURCHASE_STATUS_KEY,
  PURCHASE_TYPE,
  PURCHASES_STATUS_LIST,
  PURCHASE_SCREEN,
  PURCHASE_EVENT,
} from '../../../constants/constants';
import * as PATH from '../../../constants/routeConstants';
import * as utils from '../../../functions/utils';
import _ from 'lodash';
import I18n from 'i18n-js';
import CheckBox from '../../common/fields/CheckBox';
import { calculateTotal } from '../../../functions/utils';
import * as permissions from '../../../selectors/permissions';
import formatCurrency from 'format-currency';
import ModalConfirm from '../../common/modals/ModalConfirm';

class PlaceOrder extends BaseComponent {
  constructor(props, context) {
    super(props, context);
    const { order } = this.props;
    // console.log('PLACE ORDER', this.props);
    let { supplier, materials, style, isAdditionalOrder, orderDetail } = this.props.order;
    supplier = supplier || {};
    materials = materials || [];
    style = style || {};
    isAdditionalOrder = isAdditionalOrder || false;
    orderDetail = orderDetail || {};
    const statusOb = PURCHASES_STATUS_LIST.find(x => x.label === this.props.order.status);
    const status = statusOb && statusOb.value ? statusOb.value : '';
    this.state = {
      approve: false,
      isEditQantity: props.isEditQantity,
      status: status || _.get(this.props.order, 'status', ''),
      errors: {},
      orderDetail: {},
      stylePurchasePlan: {},
      currentStyle: null,
      currency: supplier.currency || '',
      purchase: {
        purchaseNo: order.purchaseNo || '',
        supplierId: supplier.supplierId || supplier._id,
        supplierName: supplier.supplierName || supplier.name || '',
        supplierEmail: supplier.email || '',
        styleId: style._id || '',
        styleName: style.style || '',
        customerPO: orderDetail.customerPO || '',
        customer: _.get(orderDetail, 'customer.name', ''),
        customerId: _.get(orderDetail, 'customer._id', ''),
        materials: this.initMaterial(materials),
        remark: order.remark || '',
        type: _.get(this.props.order, 'type', ''),
        gTotal: 0,
        isAdditionalOrder,
        currency: supplier.currency || '',
        sender: order.sender,
        createdBy: order.createdBy,
        createdAt: order.createdAt,
        _id: order._id,
        supplier: supplier,
      },
      isStateChange: false,
    };

    this.onChangeCheckboxApprove = this.onChangeCheckboxApprove.bind(this);
    this.edit = this.edit.bind(this);
  }

  initMaterial = materials => {
    materials = materials || [];
    return materials.map(material => {
      material = material || {};
      return {
        ...material,
        _id: material._id,
        discount: material.discount || 0,
        surcharge: material.surcharge || 0,
        payer: material.payer || Payers[0].value,
        XMIL: material.XMIL || new Date(),
        ETD: material.ETD || new Date(),
        ETA: material.ETA || new Date(),
        exReceivedDate: material.exReceivedDate || new Date(),
        exReceivedDateFormat: new moment(material.exReceivedDate || new Date()).format('DD/MM/YYYY'),
      };
    });
  };

  componentWillReceiveProps(newProps) {
    const { orderDetail, stylePurchasePlan, currentStyle } = this.state;
    const { style } = newProps;
    !_.isEqual(newProps.purchasePlanState.planByStyle, stylePurchasePlan) &&
      this.setState({ stylePurchasePlan: _.cloneDeep(newProps.purchasePlanState.planByStyle) });
  }

  componentDidUpdate(prevProps, prevSate) {
    if (this.state !== prevSate) {
      if (this.state.isStateChange === false) {
        this.setState({ isStateChange: true });
      }
    }
  }

  styleChange = value => {
    if (value !== this.state.currentStyle && value !== '-') {
      this.props.purchasePlanPropsActions.getPurchasePlan(value, {
        type: 'ADDITIONAL',
        populate: 'materials.material',
      });
    }
    this.setState({ currentStyle: value, stylePurchasePlan: {} });
  };

  changeMaterial = selectedMaterials => {
    if (selectedMaterials) {
      const { purchase } = this.state;
      const filteredMaterials = purchase.materials.filter(
        material => selectedMaterials.find(m => m._id === material._id) !== undefined
      );
      const newMaterials = selectedMaterials.filter(
        material => purchase.materials.find(m => m._id === material._id) === undefined
      );
      this.setState({
        purchase: { ...purchase, materials: this.initMaterial([...filteredMaterials, ...newMaterials]) },
      });
    }
  };

  addMaterial = () => {
    let { openModal, closeModal } = this.props.modal;
    let modal = (
      <AddMaterial
        closeModal={closeModal}
        options={{ isAdd: true, data: {} }}
        materials={this.props.order.allMaterials}
        selectedMaterials={this.state.purchase.materials}
        changeMaterial={this.changeMaterial}
      />
    );
    openModal(modal);
  };

  onSelect = (name, id, value) => {
    const { stylePurchasePlan } = this.state;
    this.setState({
      stylePurchasePlan: {
        ...stylePurchasePlan,
        materials: stylePurchasePlan.materials.map(material => {
          if (material._id !== id) {
            return material;
          }
          if (name === 'supplier') {
            const selectedSupplier = material.availableSuppliers.find(supplier => supplier.supplierId === value);
            return { ...material, supplier: value, price: selectedSupplier ? selectedSupplier.price : 0 };
          }
          if (name === 'needToBuy') {
            return { ...material, needToBuy: value, amount: value * material.price };
          }
        }),
      },
    });
  };

  onChange = (id, property, value) => {
    const { purchase } = this.state;
    if (!id) {
      return this.setState({ purchase: { ...purchase, [property]: value } });
    }
    this.setState({
      purchase: {
        ...purchase,
        materials: purchase.materials.map(material => {
          if (material._id !== id) {
            return material;
          }
          if (property === 'needToBuy') {
            return { ...material, [property]: value, amount: material.price * value };
          }
          return { ...material, [property]: value };
        }),
      },
    });
  };

  close = () => {
    this.props.closeModal();
  };

  updatePlan = () => {
    const { stylePurchasePlan, currentStyle } = this.state;
    this.props.purchasePlanPropsActions.updatePurchasePlan(currentStyle, stylePurchasePlan);
  };

  renderDate = (idx, fieldName, material, isEdit) => {
    const { errors } = this.state;
    const onChange = date => {
      let params = { [fieldName]: date.format() };
      if (fieldName === 'exReceivedDate') {
        _.set(params, 'exReceivedDateFormat', date.format('DD/MM/YYYY'));
      }

      this.onChangeDataRow(idx, params);
    };

    if (!isEdit) {
      return moment(material[fieldName] || {}).format('MMM DD, YYYY');
    }
    return (
      <div>
        <DateTimeField
          value={moment(material[fieldName])}
          dateFormat="MMM DD, YYYY"
          timeFormat={false}
          onChange={onChange}
        />
        {errors[fieldName] && <div className="alert-warning">{errors[fieldName]}</div>}
      </div>
    );
  };

  renderInput(material, field, idx, isEdit, type) {
    if (!isEdit) {
      return material[field] || '';
    }
    return (
      <input
        onChange={e => {
          this.onChangeNumber(e.target.value, idx, field);
        }}
        onBlur={e => {
          this.onBlurNumber(e.target.value, idx, field);
        }}
        className="form-control center"
        type={type}
        value={material[field] || ''}
      />
    );
  }

  renderRemark(value, status) {
    if (
      status !== PURCHASE_STATUS.IN_PORT &&
      status !== PURCHASE_STATUS.IN_TRANSIT &&
      status !== PURCHASE_STATUS.IN_WAREHOUSE &&
      status !== PURCHASE_STATUS.WAITING_FOR_SHIPPING &&
      status !== PURCHASE_STATUS.COMPLETED
    ) {
      return <textarea rows="6" value={value} onChange={this.onChangeRemark} cols="20" className="mg-bottom-50" />;
    }
    return <p className="text-left">{value || ''}</p>;
  }

  renderPayer = (idx, material, isEdit) => {
    const onChange = evt => {
      const { value } = evt.target;
      this.onChangeDataRow(idx, { payer: value });
    };
    if (!isEdit) {
      return material.payer || '';
    }
    return (
      <div>
        <select className="w-full" onChange={onChange} value={material.payer}>
          {Payers.map((payer, idx) => (
            <option key={idx} value={payer.value}>
              {payer.label}
            </option>
          ))}
        </select>
      </div>
    );
  };

  onChangeStatus(event) {
    const status = event && event.target && event.target.value ? event.target.value : PURCHASE_STATUS.NEW;
    this.setState({ selectStatus: status });
    this.setState({ status });
  }

  renderStatus() {
    let typePurchase = _.get(this.props.order, 'type', '');
    let statusList = [];

    let currentStatus = _.get(this.props.order, 'status', '');
    if (currentStatus == 'In progress') {
      statusList = PURCHASES_STATUS_LIST.filter(item => item.value != 'NEW');
    }
    if (
      currentStatus == 'In transit' ||
      currentStatus == 'In port' ||
      currentStatus == 'Waiting for shipping' ||
      currentStatus == 'In warehouse'
    ) {
      statusList = PURCHASES_STATUS_LIST.filter(item => item.value != 'NEW');
      statusList = statusList.filter(item => item.value != 'IN_PROGRESS');
    }
    if (typePurchase === PURCHASE_TYPE.USE_STOCK) {
      statusList = PURCHASES_STATUS_LIST.filter(item => item.value == 'NEW' || item.value == 'COMPLETED');
    } else if (currentStatus == 'New') {
      statusList = PURCHASES_STATUS_LIST;
    }
    if (currentStatus === PURCHASE_STATUS.APPROVED) {
      return <p className="text-left">{currentStatus}</p>;
    }
    return (
      <div className="center select date-time-td w-120">
        <select className="w-full" onChange={event => this.onChangeStatus(event)} value={this.state.status}>
          {/* <option></option> */}
          {statusList.map((status, idx) => (
            <option key={idx} value={status.value}>
              {status.label}
            </option>
          ))}
        </select>
      </div>
    );
  }

  renderGTotal({ total, orderDetail, showStatus, isCancel }) {
    return (
      <tr className="g-total">
        {showStatus && !isCancel && <td colSpan="1">{I18n.t('PagePurchase.Status')}</td>}
        {showStatus && !isCancel && (
          <td colSpan="2" className="pd-0-15">
            {this.renderStatus(orderDetail)}
          </td>
        )}
        <td colSpan={showStatus ? '13' : '14'}>
          <ul>
            <li>{I18n.t('PagePurchase.G-Total')}</li>
            <li className="color-number pd-0-5">{total}</li>
          </ul>
        </td>
      </tr>
    );
  }

  onChangeDataRow = (index, obj) => {
    let { purchase } = this.state;
    const materials = purchase.materials.map((item, idx) => {
      if (idx == index) {
        return { ...item, ...obj };
      } else {
        return item;
      }
    });
    this.setState({
      purchase: {
        ...purchase,
        materials: [...materials],
      },
    });
  };

  onChangeRemark = evt => this.onChange(null, 'remark', evt.target.value);

  onChangeNumber = (vl, idx, field) => {
    if (_.get(this.props.order, 'status', '') === PURCHASE_STATUS.APPROVED) {
      return;
    }
    const { purchase } = this.state;
    purchase.materials[idx][field] = vl;
    this.setState({ purchase });
  };

  onBlurNumber = (vl, idx, field) => {
    if (_.get(this.props.order, 'status', '') === PURCHASE_STATUS.APPROVED) {
      return;
    }
    const { purchase } = this.state;
    purchase.materials[idx][field] = parseFloat(vl) || '';
    this.setState({ purchase });
  };

  renderConfirmSaveStatusCompleted(status) {
    let { openModal, closeModal } = this.props.modal;
    let modal = (
      <ModalConfirm
        title={I18n.t('PagePurchase.CompletedOrder')}
        confirmMessage={I18n.t('PagePurchase.ConfirmSaveStatusCompleted')}
        messageOk={I18n.t('Btn.YES')}
        messageCancel={I18n.t('Btn.CANCEL')}
        handleClose={closeModal}
        handleOk={this.edit.bind(null, status, PURCHASE_EVENT.SAVE)}
      />
    );
    openModal(modal);
  }

  sendRevised = () => {
    return this.editAndSendRevised(status, PURCHASE_EVENT.SAVE);
  };

  editAndSendRevised = (status, type) => {
    const { stock } = this.state;
    let { purchase } = this.state;
    const { order, purchaseDeviation } = this.props;
    const purChaseId = order && order._id ? order._id : '';
    const gTotal = calculateTotal(purchase);
    status = utils.setStatus(_.get(this.props.order, 'status', ''), type, order, purchase, purchaseDeviation);
    status = status || (PURCHASE_STATUS[this.state.status] ? PURCHASE_STATUS[this.state.status] : this.state.status);
    if (status == 'Approved') {
      status = 'New';
    }
    this.props.purchaseOrderActions.editAndSendRevised(purChaseId, { ...stock, status, gTotal });
  };

  saveOrder() {
    if (this.props.isEdit) {
      let value = this.state.status;
      let status = PURCHASE_STATUS[value] || this.props.order.status;
      if (status == PURCHASE_STATUS.COMPLETED) {
        return this.renderConfirmSaveStatusCompleted(status);
      }
      return this.edit(status, 'SAVE');
    }
    return this.save(PURCHASE_STATUS.DRAFT, 'SAVE');
  }

  sendOrder() {
    if (this.props.isEdit) {
      return this.edit(PURCHASE_STATUS.NEW, 'SEND');
    }
    return this.save(PURCHASE_STATUS.NEW, 'SEND');
  }

  save(status, type) {
    let { purchase } = this.state;
    const gTotal = calculateTotal(purchase);
    purchase = { ...purchase, status, gTotal };
    this.props.purchaseOrderActions.addItem(purchase);
  }

  edit(status, type) {
    let { purchase } = this.state;
    const { order, purchaseDeviation } = this.props;
    const purChaseId = order && order._id ? order._id : '';
    const gTotal = calculateTotal(purchase);
    status = utils.setStatus(_.get(this.props.order, 'status', ''), type, order, purchase, purchaseDeviation);
    status = status || (PURCHASE_STATUS[this.state.status] ? PURCHASE_STATUS[this.state.status] : this.state.status);
    if (status == 'Approved') {
      status = 'New';
    }
    purchase = { ...purchase, status: status, gTotal };
    this.props.purchaseOrderActions.editItem(purChaseId, purchase).then(res => {
      if (res && res.success) {
        // let screen = '';
        // let pageList = [...this.props.appState.pageList];
        // pageList.push({screen: screen, data });
        // this.props.appActions.addPage(pageList);
        // const pageData = _.assign(_.cloneDeep(this.props.appState.page), {isEdit: false, isAdd: false});
        // this.props.appActions.setPageCurrent(pageData);
      }
    });
  }

  onChangeCheckboxApprove(event) {
    if (event && event.target && event.target.checked) {
      this.setState({ status: PURCHASE_STATUS.APPROVED });
      this.setState({ approve: true });
    } else {
      this.setState({ status: PURCHASE_STATUS_KEY.WAITING_FOR_APPROVAL });
      this.setState({ approve: false });
    }
  }

  openPreviewPurchaseOrder(data) {
    // if(this.props.appState.page.tab == "follow-up") {
    //     let screen = PURCHASE_SCREEN.FOLLOW_UP_PREVIEW_PURCHASE_ORDER;
    //     let pageList = [...this.props.appState.pageList];
    //     pageList.push({screen: screen, data });
    //     this.props.appActions.addPage(pageList);
    //     const pageData = _.assign(_.cloneDeep(this.props.appState.page)); //{isEdit: false, isAdd: false}
    //     this.props.appActions.setPageCurrent(pageData);
    // } else {
    if (data) {
      // this.props.history.push(PATH.PURCHASE_LIST_PREVIEW_ORDER_URL)
      let screen = PURCHASE_SCREEN.PREVIEW_PURCHASE_ORDER;
      let pageList = [...this.props.appState.pageList];

      const prevData = _.get(_.last(pageList), 'data', undefined);
      const prevRemark = _.get(prevData, 'remark', '');
      const remark = _.get(data, 'remark', '');
      if (prevData.materials !== data.materials || prevRemark !== remark) {
        const prevPage = pageList.pop();
        let newData = Object.assign({}, prevPage.data);
        newData.materials = data.materials;
        newData.remark = remark;
        pageList.push({ screen: prevPage.screen, data: newData });
      }

      pageList.push({ screen: screen, data });
      this.props.appActions.addPage(pageList);
      const pageData = _.assign(_.cloneDeep(this.props.appState.page)); //{isEdit: false, isAdd: false}
      this.props.appActions.setPageCurrent(pageData);
    }
    // }
  }

  renderCheckboxApprove() {
    const status = this.props.order && this.props.order.status ? this.props.order.status : '';
    let { approve } = this.state;
    if (status !== PURCHASE_STATUS.WAITING_FOR_APPROVAL) {
      return null;
    }
    return (
      <div className="checkbox-approve">
        <span>{I18n.t('PagePurchase.Approve')}</span>
        {/*<CheckBox onChange={(event) => this.onChangeCheckboxApprove(event)}/>*/}
        <CheckBox id={new Date().getTime()} checked={approve || false} onChange={this.onChangeCheckboxApprove} />
      </div>
    );
  }

  renderHeaderPlaceOrder(type, status) {
    return (
      <tr>
        <th className="center">{I18n.t('PagePurchase.Material')}</th>
        <th className="center">
          {I18n.t('PagePurchase.Replacement')}
          <br />
          P.0 #
        </th>
        <th className="center">{I18n.t('PagePurchase.XMIL')}</th>
        <th className="center">{I18n.t('PagePurchase.ETD')}</th>
        <th className="center">{I18n.t('PagePurchase.ETA')}</th>
        <th className="center">
          {I18n.t('PagePurchase.Expected')}
          <br />
          {I18n.t('PagePurchase.ReceivedDate')}
        </th>
        {/* {(type === PURCHASE_TYPE.ADDITIONAL && status === PURCHASE_STATUS.DRAFT) ? null :
          <th className='center'>{I18n.t('PagePurchase.Payer')}</th>
        } */}
        <th className="center">{I18n.t('PagePurchase.Payer')}</th>
        <th className="center w-80">{I18n.t('PagePurchase.Quantity')}</th>
        <th className="center">{I18n.t('PagePurchase.Convert_Quantity')}</th>
        <th className="center">{I18n.t('PagePurchase.Price')}</th>
        <th className="center w-80">{I18n.t('PagePurchase.Surcharge')}</th>
        <th className="center w-80">{I18n.t('PagePurchase.Discount')}</th>
        <th className="center">
          {I18n.t('PagePurchase.Total')}
          <br />
          {I18n.t('PagePurchase.Price')}
        </th>
      </tr>
    );
  }

  renderBodyPlaceOrder(purchase, currency, type, status, isEditQantity) {
    const isEdit =
      status !== PURCHASE_STATUS.APPROVED &&
      status !== PURCHASE_STATUS.IN_PORT &&
      status !== PURCHASE_STATUS.IN_TRANSIT &&
      status !== PURCHASE_STATUS.IN_WAREHOUSE &&
      status !== PURCHASE_STATUS.WAITING_FOR_SHIPPING &&
      status !== PURCHASE_STATUS.COMPLETED;

    return purchase.materials.map((material, idx) => {
      const qty = material.quantity;
      const inStock = material.inStock;

      const price =
        material.price > 0
          ? currency == 'USD'
            ? `$ ${formatCurrency(material.price)}`
            : currency
            ? `${formatCurrency(material.price)} ${currency}`
            : `${formatCurrency(material.price)}`
          : 0;
      let name = _.get(material, 'material.name', '');
      const content = _.get(material, 'material.data.content', '');
      content.length > 1 && (name += ' - ' + content);
      let color = material.color || '';
      let size = material.size || '';
      color = size == '' ? color : color + ' - ' + size;
      const unit = _.get(material, 'material.data.unit', '');
      // console.log(unit);
      //unit.length > 1 && (color += ' - ' + unit);
      const qtConverted = this.convertUnit(material.needToBuy, material.material);
      let amount = material.price * material.needToBuy + (material.surcharge || 0) - (material.discount || 0);
      amount =
        amount > 0
          ? currency == 'USD'
            ? `$ ${formatCurrency(amount)}`
            : currency
            ? `${formatCurrency(amount)} ${currency}`
            : `${formatCurrency(amount)}`
          : 0;
      // if (material.payer == 'Customer' || material.payer == 'Subcontractor') amount = '-';
      let covertQuantity = '-';
      if (qtConverted != 0) covertQuantity = `${qtConverted} ${this.getUnit(material, true)}`;

      return (
        <tr key={idx}>
          <td>
            {name} <br /> <span className="title-size">({color})</span>
          </td>
          <td className="center">{material.replacementPO}</td>
          <td className="center select date-time-td">{this.renderDate(idx, 'XMIL', material, isEdit)}</td>
          <td className="center select date-time-td">{this.renderDate(idx, 'ETD', material, isEdit)}</td>
          <td className="center select date-time-td">{this.renderDate(idx, 'ETA', material, isEdit)}</td>
          <td className="center select date-time-td">{this.renderDate(idx, 'exReceivedDate', material, isEdit)}</td>
          <td className="center select date-time-td">{this.renderPayer(idx, material, isEdit)}</td>
          {/* <td className="center">
              {isEditQantity == false ? `${material.needToBuy} ${this.getUnit(material)}` : this.renderInput(material, 'needToBuy', idx, isEdit)}
            </td> */}
          <td className="center">
            {material.needToBuy} {unit}
          </td>
          <td className="center">{covertQuantity}</td>
          <td className="color-number center">{price}</td>
          <td className="color-number center">{this.renderInput(material, 'surcharge', idx, isEdit, 'text')}</td>
          <td className="color-number center">{this.renderInput(material, 'discount', idx, isEdit, 'number')}</td>
          <td className="color-number center">{amount}</td>
        </tr>
      );
    });
  }

  render() {
    const {
      order: { supplier, materials, orderDetail },
      isCancel,
      canApprove,
    } = this.props;
    let { selectStatus } = this.state;
    const { purchase, currency, isEditQantity, isStateChange } = this.state;
    const propCurrentStyle = this.props.order.style;
    let total = calculateTotal(purchase);
    total =
      total > 0
        ? currency == 'USD'
          ? `$ ${formatCurrency(total)}`
          : currency
          ? `${formatCurrency(total)} ${currency}`
          : `${formatCurrency(total)}`
        : 0;
    let { showStatus, status, type } = utils.setParamsByOrder(this.props.order);
    if (purchase.status && !status) {
      status = purchase.status;
    }
    if (!selectStatus) {
      selectStatus = status;
    }
    return (
      <div id="content">
        {/* <BarOrderInfo order={orderDetail}/> */}
        <div className="md content-main-process" style={flexStyle}>
          <div className="style">
            <ul className="content-main-header-filter-style">
              <li className="title">
                <p>
                  {' '}
                  {I18n.t('PagePurchase.CustomerPO')}
                  <span className="color-orange medium size-16 mg-left-15">
                    {' '}
                    {_.get(orderDetail, 'customerPO', '')}
                  </span>
                </p>
              </li>
              <li className="title mg-left-15">
                <p>
                  {' '}
                  {I18n.t('PagePurchase.Style')}
                  <span className="color-orange medium size-16 mg-left-15">
                    {' '}
                    {_.get(propCurrentStyle, 'style', '')}
                  </span>
                </p>
              </li>
              {!!purchase.purchaseNo && (
                <li className="title mg-left-15">
                  <p>
                    {' '}
                    {I18n.t('PagePurchase.PurchaseOrderNo')}
                    <span className="color-orange medium size-16 mg-left-15"> {_.get(purchase, 'purchaseNo', '')}</span>
                  </p>
                </li>
              )}
              {!!purchase.supplierName && (
                <li className="title mg-left-15">
                  <p>
                    {' '}
                    {I18n.t('PagePurchase.Supplier')}
                    <span className="color-orange medium size-16 mg-left-15">
                      {' '}
                      {_.get(purchase, 'supplierName', '')}
                    </span>
                  </p>
                </li>
              )}
              {!!purchase.supplierName && (
                <li className="title mg-left-15">
                  <p>
                    {' '}
                    {I18n.t('PagePurchase.Sender')}
                    <span className="color-orange medium size-16 mg-left-15">
                      {' '}
                      {_.get(purchase, 'sender.fullName', '')}
                    </span>
                  </p>
                </li>
              )}
            </ul>
          </div>
          {canApprove && this.renderCheckboxApprove()}
        </div>
        {purchase.materials && purchase.materials.length > 0 && (
          <div className="wrap-global-table">
            <table style={{ width: '100%' }} className="global-table data-time-table">
              <tbody className="body-data-time edit-purchase">
                {this.renderHeaderPlaceOrder(type, status)}
                {this.renderBodyPlaceOrder(purchase, currency, type, status, isEditQantity)}
                {this.renderGTotal({ total, orderDetail, showStatus, isCancel })}

                <tr className="bg-white wrap-textarea">
                  <td colSpan="1" className="center vertical-align-inherit">
                    {I18n.t('PagePurchase.Remark')}
                  </td>
                  <td colSpan="13">{this.renderRemark(purchase.remark, status)}</td>
                </tr>
              </tbody>
            </table>
            <div className="text-center">
              <ul className="list-button">
                {isCancel != true && type !== PURCHASE_STATUS.USE_STOCK && (
                  <li>
                    <a
                      className="bg-black"
                      onClick={() => {
                        this.openPreviewPurchaseOrder(purchase);
                      }}>
                      {I18n.t('Btn.PreviewOrder')}
                    </a>
                  </li>
                )}
                {isCancel != true && status !== PURCHASE_STATUS.APPROVED && isStateChange && (
                  <li>
                    <a className="bg-orange" onClick={() => this.saveOrder()}>
                      {I18n.t('Btn.Save')}
                    </a>
                  </li>
                )}
                {isStateChange == false && (
                  <li>
                    <button type="button" className={`${'btn btn-default'} bt-orange mg-l-7`} disabled={true}>
                      {I18n.t('Btn.SAVE')}
                    </button>
                  </li>
                )}

                {isCancel != true &&
                  status !== PURCHASE_STATUS.NEW &&
                  status !== PURCHASE_STATUS.USE_STOCK &&
                  status !== PURCHASE_STATUS.IN_PROGRESS &&
                  status !== PURCHASE_STATUS.IN_PORT &&
                  status !== PURCHASE_STATUS.IN_TRANSIT &&
                  status !== PURCHASE_STATUS.IN_WAREHOUSE &&
                  status !== PURCHASE_STATUS.WAITING_FOR_SHIPPING &&
                  status !== PURCHASE_STATUS.COMPLETED &&
                  status !== PURCHASE_STATUS.WAITING_FOR_APPROVAL && (
                    <li>
                      <a className="bg-green" disabled={true} onClick={() => this.sendOrder()}>
                        {I18n.t('Btn.Send')}
                      </a>
                    </li>
                  )}

                {(selectStatus === PURCHASE_STATUS.NEW || selectStatus === PURCHASE_STATUS.IN_PROGRESS) && (
                  <li>
                    <a className="bg-orange" onClick={() => this.sendRevised()}>
                      {I18n.t('Btn.SendRevised')}
                    </a>
                  </li>
                )}
              </ul>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const flexStyle = {
  display: 'flex',
  alignItem: 'center',
  justifyContent: 'space-between',
};

function mapStateToProps(state, ownProps) {
  return {
    ...ownProps,
    authState: state.authState,
    appState: state.appState,
    orderState: state.orderState,
    purchasePlanState: state.purchasePlanState,
    canApprove: permissions.isSpecial({ moduleKey: 'purchase', ...state }),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    purchasePropsActions: bindActionCreators(purchaseActions, dispatch),
    purchasePlanPropsActions: bindActionCreators(purchasePlanActions, dispatch),
    orderPropsActions: bindActionCreators(orderActions, dispatch),
    purchaseOrderActions: bindActionCreators(purchaseOrderActions, dispatch),
    appActions: bindActionCreators(appActions, dispatch),
  };
}

PlaceOrder.propTypes = {
  authState: PropTypes.object.isRequired,
  activeTab: PropTypes.string,
  showStatus: PropTypes.bool,
  isEdit: PropTypes.bool,
  isCancel: PropTypes.bool,
  purchaseDeviation: PropTypes.number,
  order: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PlaceOrder));
