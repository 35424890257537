import C from '../../../constants/accounting/payable/editInvoiceSupplierOversea';
import { combineReducers } from "redux";
import _ from 'lodash';

const invoiceDetail = (state = {}, action) => {
    const {type, payload} = action;
    let dic = _.cloneDeep(state);
    switch(type) {
        case C.CHANGE_INVOICE_DETAIL:
            return {...payload, receipt: {isEdit: false}};
        case C.CHANGE_SUPPLIER_VALUE:
            dic.invoiceDetail.supplier.name = payload;
            return dic;
        case C.CHANGE_FACTOR_VALUE:
            dic.factor = payload;
            return dic;
        case C.CHANGE_INVOICE_NO_VALUE:
            dic.invNo = payload;
            return dic;
        case C.CHANGE_CREDIT_DEBIT_VALUE:
            dic.invoiceDetail.creditDebit = payload.raw;
            return dic;
        case C.CHANGE_ISSUED_DATE_VALUE:
            dic.issuedDate = payload;
            return dic;
        case C.CHANGE_DUE_DATE_VALUE:
            dic.dueDate = payload;
            return dic;
        case C.SUBMIT_FILES:
            dic = _.cloneDeep(state);
            dic.files = payload;
            return dic;
        case C.SUBMIT_RECEIPT:
            dic = _.cloneDeep(state);
            dic.receipt = {...payload, isEdit: true};
            return dic;
        case C.RESET_ALL_DATA:
            return {};
        default:
            return state;
    }
}

const constSupplierName = (state = '', action) => {
    const {type, payload} = action;
    switch(type) {
        case C.CHANGE_INVOICE_DETAIL:
            return payload.invoiceDetail.supplier.name;
        default:
            return state;
    }
}

const typeInvoice = (state = '', action) => {
    const {type, payload} = action;
    switch(type) {
        case C.CHANGE_INVOICE_TYPE:
            return payload;
        default:
            return state;
    }
}

const listSupplier = (state = [], action) => {
    const {type, payload} = action;
    switch(type) {
        case C.CHANGE_LIST_SUPPLIER:
            return payload;
        default:
            return state;
    }
}

const listCreditDebit = (state = [], action) => {
    const {type, payload} = action
    switch(type) {
        case C.CHANGE_LIST_CREDIT_DEBIT:
            return payload;
        default:
            return state;
    }
}

const listPurchaseOrderDetail = (state = [], action) => {
    const {type, payload} = action;
    let dic;
    let _id
    switch(type) {
        case C.CHANGE_LIST_PURCHASE_DETAIL:
            return payload.map(item => ({...item, checked: true, active: true}));
        case C.CHANGE_CHECKED_PURCHASE_ORDER:
            const {checked} = payload;
            _id = payload._id;
            dic = _.cloneDeep(state);
            dic.map(item => item._id === _id ? item.checked = checked : null);
            return dic;
        case C.CHANGE_TOGGLE_ACTIVE:
            const {active} = payload;
            _id = payload._id;
            dic = _.cloneDeep(state);
            dic.map(item => item._id === _id ? item.active = active : null);
            return dic;
        case C.CHANGE_PURCHASE_INVOICE_NO:
            const {purchaseId, materialId, index, value} = payload;
            dic = _.cloneDeep(state);
            dic.map(item => item._id === purchaseId && item.materials.map((m, idx) => (m._id === materialId && idx === index) ? m.invNo = value : null))
            return dic;
        default:
            return state;
    }
}

const transactionNo = (state = '', action) => {
    const {type, payload} = action;
    switch(type) {
        case C.CHANGE_TRANSACTION_NO_VALUE:
            return payload;
        default:
            return state
    }
}

const paymentMethod = (state = '', action) => {
    const {type, payload} = action;
    switch(type) {
        case C.CHANGE_PAYMENT_METHOD_VALUE:
            return payload;
        default:
            return state
    }
}

const paymentType = (state = 'cash', action) => {
    const {type, payload} = action;
    switch(type) {
        case C.CHANGE_PAYMENT_TYPE:
            return payload;
        default:
            return state
    }
}

const transferFiles = (state = [], action) => {
    const { type, payload } = action;
    switch (type) {
        case C.COPY_FILES_TO_RAW_DATA:
            return payload;
        case C.ADD_TRANSFER_FILE: {
            const stateClone = _.cloneDeep(state);
            stateClone.push(payload);
            return stateClone;
        }
        case C.REMOVE_TRANSFER_FILE:
            return state.filter(file => file.id !== payload);
        case C.EMPTY_FILES_IN_RAW_DATA:
            return [];
        case C.RESET_DATA_ALL:
            return [];
        default:
            return state;
    }
  }

const receipt = (state = {}, actions) => {
      const {type, payload} = actions;
      switch(type) {
            case C.CREATE_RECEIPT:
                return payload;
            case C.CHANGE_RECEIPT_VALUE:
                const {key, value} = payload;
                let dic = _.cloneDeep(state);
                dic[key] = value;
                return dic;
            case C.EMPTY_RECEIPT:
                return {}
            default:
                return state;
      }
  }

const payAmount = (state = 0, action) => {
      const {type, payload} = action;
      switch(type) {
        case C.CHANGE_PAY_AMOUNT_VALUE:
            return payload;
        case C.RESET_DATA_ALL:
            return 0;
        default:
            return state;
      }
  }

const success = (state = false, action) => {
    const {type, payload} = action;
    switch(type) {
          case C.SAVE_SUCCESS:
              return payload;
          case C.RESET_DATA_ALL:
              return false;
          default:
              return state;
    }
}

const activeScreen = (state = false, action) => {
    const {type, payload} = action;
    switch(type) {
        case C.ACTIVE_SCREEN_STATUS:
            return payload;
        default:
            return state;
    }
}

export const editInvoiceSupplierOversea = combineReducers({
    invoiceDetail,
    typeInvoice,
    listSupplier,
    listCreditDebit,
    listPurchaseOrderDetail,
    constSupplierName,
    transactionNo,
    paymentMethod,
    paymentType,
    transferFiles,
    payAmount,
    receipt,
    success,
    activeScreen
})