import * as R from 'ramda';
import _ from 'lodash';
import moment from 'moment';
import * as PATH from '../../../constants/routeConstants';
import { pathImage } from '../../../functions/helpers';


export const getOrderId = ({ showOrderDetail }) => {
  const { order } = showOrderDetail;
  return R.path(['_id'], order);
};

export const getOrderDate = ({ showOrderDetail }) => {
  const { order } = showOrderDetail;
  return R.path(['orderDate'], order);
};

export const getCustomer = ({ showOrderDetail }) => {
  const { order } = showOrderDetail;
  return R.path(['customer', 'name'], order);
};

export const getCustomerPO = ({ showOrderDetail }) => {
  const { order } = showOrderDetail;
  return R.path(['customerPO'], order);
};

export const getInternalPO = ({ showOrderDetail }) => {
  const { order } = showOrderDetail;
  return R.path(['internalPO'], order);
};

export const getGTotal = ({ showOrderDetail }) => {
  const { order } = showOrderDetail;
  return R.path(['gTotal'], order);
};

export const getExFactory = ({ showOrderDetail }) => {
  const { order } = showOrderDetail;
  return R.path(['exFactory'], order);
};

export const getIhDate = ({ showOrderDetail }) => {
  const { order } = showOrderDetail;
  return R.path(['ihDate'], order);
};

export const getShipped = ({ showOrderDetail }) => {
    const { order } = showOrderDetail;
    return R.path(['shipped'], order);
};

export const getStyles = ({ showOrderDetail }) => {
  const { styles, selectedStyle } = showOrderDetail;
  return _.map(styles, item => ({
    images: R.path(['images'], item),
    imageUrl: pathImage(_.head(R.path(['images'], item))),
    value: R.path(['_id'], item),
    style: R.path(['style'], item),
    page: R.path(['page'], item),
    active: R.path(['_id'], selectedStyle) === R.path(['_id'], item),
    raw: item,
  }));
}

export const getCutTickets = ({ showOrderDetail }) => {
  const { cutTickets } = showOrderDetail;
  return _.map(cutTickets, item => ({
    _id: R.path(['_id'], item),
    name: R.path(['cutTicket'], item),
    gTotal: R.path(['gTotal'], item),
    imageUrl: _.head(R.path(['images'], item)) ? pathImage(_.head(R.path(['images'], item))) : null,
    warehouse: R.path(['warehouse', 'name'], item),
    store: R.path(['store', 'name'], item),
    packingStandard: R.path(['store', 'packingStandard', 'name'], item),
    editPackingStandardUrl: `${PATH.STANDARD_PACKINGS_EDIT_URL}/${R.path(['store', 'packingStandard', '_id'], item)}`,
    shipMode: R.path(['shipMode'], item),
    mainLabel: R.path(['mainLabel'], item),
    ihDate: moment(R.path(['ihDate'], item)).format('MMM DD, YYYY'),
    note: R.path(['note'], item),
    colors: _.map(R.pathOr([], ['colors'], item), clr => ({
      name: R.path(['name'], clr),
      total: _.sum(_.map(R.pathOr([], ['sizes'], clr), sz => R.pathOr(0, ['quantity'], sz))),
      sizes: _.map(R.pathOr([], ['sizes'], clr), sz => ({
        name: R.path(['name'], sz),
        quantity: R.path(['quantity'], sz),
        raw: sz,
      })),
      raw: clr,
    })),
    raw: item,
  }));
}

export const getSelectableWarehouses = ({ showOrderDetail }) => {
  const { order } = showOrderDetail;
  const warehouses = R.pathOr([], ['customer', 'warehouses'], order);
  return _.map(warehouses, item => ({
    value: R.path(['_id'], item),
    label: R.path(['name'], item),
    raw: item,
  }));
}

export const getSelectedStyle = ({ showOrderDetail }) => showOrderDetail.selectedStyle;

export const isNotifying = ({ showOrderDetail }) => showOrderDetail.notifying;
