import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as PATH from '../../constants/routeConstants';
import BaseComponent from '../base/BaseComponent';
import StockInSupplier from './modal/StockInSupplier';
import StockInContractor from './modal/StockInContractor';
import TabSupplier from './stock-in/TabSupplier';
import TabContractor from './stock-in/TabContractor';
import StockInSupplierAdd from './stock-in/StockInSupplierAdd';
import StockInContractorAdd from './stock-in/StockInContractorAdd';
import stockInActions from '../../actions/stockInActions';
import appAction from '../../actions/appActions';
import _ from 'lodash';
import I18n from 'i18n-js';
import { Switch, Route, withRouter, Redirect } from 'react-router-dom';
class ScreenStockIn extends BaseComponent {
  constructor(props, context) {
    super(props, context);

    this.state = {
      typeAdd: '',
      addStockIn: false,
      dataForm: {},

      searchFields: {},
      searchValue: {},
      tabActive: 'supplier',
    };
  }

  componentWillReceiveProps(newProps) {
    this.props.tabCurrent !== newProps.tabCurrent && this.closeFormAdd();
    const { page } = newProps.appState;
    if (page.tab !== 'stock-in') {
      this.props.appPropsAction.setDataPageCurrent({ ...page, tab: 'stock-in' });
    }
  }

  addItem() {
    const { openModal, closeModal } = this.props.modal;
    let { tabActive } = this.state;
    const { pathname } = this.props.location;
    let modal =
      pathname == PATH.WAREHOUSE_INBOUND_SUPPLIER_URL ? (
        <StockInSupplier closeModal={closeModal} openFormAdd={(type, dataForm) => this.openFormAdd(type, dataForm)} />
      ) : (
        <StockInContractor closeModal={closeModal} openFormAdd={(type, dataForm) => this.openFormAdd(type, dataForm)} />
      );
    modal && openModal(modal);
  }

  openFormAdd(type, dataForm) {
    this.setState({ typeAdd: type, addStockIn: true, dataForm });
    if (type === 'supplier') {
      this.props.history.push(PATH.WAREHOUSE_INBOUND_ADD_SUPPLIER_URL);
    }
    if (type === 'contractor') {
      this.props.history.push(PATH.WAREHOUSE_INBOUND_ADD_CONTRACTOR_URL);
    }
  }

  closeFormAdd(tab = 'supplier') {
    this.setState({ typeAdd: '', addStockIn: false, dataForm: {} });
    this.setTabActive(tab);
    if (tab === 'supplier') {
      this.props.history.push(PATH.WAREHOUSE_INBOUND_SUPPLIER_URL);
    } else if (tab === 'contractor') {
      this.props.history.push(PATH.WAREHOUSE_INBOUND_CONTRACTOR_URL);
    }
  }

  setSearchFields(type, fields) {
    let { searchFields } = this.state;
    this.setState({ searchFields: _.set(searchFields, type, fields) });
  }

  setSearchValue(search) {
    let { searchValue, tabActive } = this.state;
    this.setState({ searchValue: _.set(searchValue, tabActive, search) });
  }

  setTabActive(tab) {
    this.setState({ tabActive: tab, searchValue: {} });
  }

  render() {
    const { tabCurrent } = this.props;
    let { typeAdd, addStockIn, dataForm, searchFields, searchValue, tabActive } = this.state;
    const { pathname } = this.props.location;
    return (
      <Switch>
        <Route
          path={PATH.WAREHOUSE_INBOUND_ADD_SUPPLIER_URL}
          render={() => (
            <StockInSupplierAdd {...this.props} data={dataForm || {}} closeFormAdd={tab => this.closeFormAdd(tab)} />
          )}
        />
        <Route
          path={PATH.WAREHOUSE_INBOUND_ADD_CONTRACTOR_URL}
          render={() => (
            <StockInContractorAdd
              {...this.props}
              data={dataForm || {}}
              backToListPage={tab => this.closeFormAdd(tab)}
            />
          )}
        />
        <Route
          path={PATH.WAREHOUSE_INBOUND_URL}
          render={() => (
            <div className="show">
              <div className="global-table-tab">
                <ul className="nav nav-tabs wrap-tab" role="tablist">
                  <li
                    role="presentation"
                    className={`cursor-pointer ${pathname == PATH.WAREHOUSE_INBOUND_SUPPLIER_URL && 'active'}`}
                    onClick={() => {
                      this.setTabActive('supplier');
                      this.props.history.push(PATH.WAREHOUSE_INBOUND_SUPPLIER_URL);
                    }}>
                    <a href="#from_supplier" aria-controls="From Supplier" role="tab" data-toggle="tab">
                      {I18n.t('PageWareHouse.FromSupplier')}
                    </a>
                  </li>
                  <li
                    role="presentation"
                    className={`cursor-pointer ${pathname == PATH.WAREHOUSE_INBOUND_CONTRACTOR_URL && 'active'}`}
                    onClick={() => {
                      this.setTabActive('contractor');
                      this.props.history.push(PATH.WAREHOUSE_INBOUND_CONTRACTOR_URL);
                    }}>
                    <a href="#from_contractor" aria-controls="From Contractor" role="tab" data-toggle="tab">
                      {I18n.t('PageWareHouse.FromContractor')}
                    </a>
                  </li>
                </ul>

                <div className="tab-content">
                  <Switch>
                    <Route
                      path={PATH.WAREHOUSE_INBOUND_URL}
                      exact
                      render={() => <Redirect to={PATH.WAREHOUSE_INBOUND_SUPPLIER_URL} />}
                    />
                    <Route
                      path={PATH.WAREHOUSE_INBOUND_SUPPLIER_URL}
                      render={() => (
                        <TabSupplier
                          {...this.props}
                          searchValue={_.get(searchValue, 'supplier') || {}}
                          addItem={() => this.addItem()}
                          setSearchFields={(type, fields) => this.setSearchFields(type, fields)}
                        />
                      )}
                    />
                    <Route
                      path={PATH.WAREHOUSE_INBOUND_CONTRACTOR_URL}
                      render={() => (
                        <TabContractor
                          {...this.props}
                          searchValue={_.get(searchValue, 'contractor') || {}}
                          addItem={() => this.addItem()}
                          setSearchFields={(type, fields) => this.setSearchFields(type, fields)}
                        />
                      )}
                    />
                  </Switch>
                </div>
              </div>
            </div>
          )}
        />
      </Switch>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    appState: state.appState,
    authState: state.authState,
    stockInState: state.stockInState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    stockInPropsActions: bindActionCreators(stockInActions, dispatch),
    appPropsAction: bindActionCreators(appAction, dispatch),
  };
}

ScreenStockIn.propTypes = {
  authState: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ScreenStockIn));
