import React from 'react';
import PropTypes from 'prop-types';
import PanelTable from "./PanelTable";
import { PANEL_TABLE_ID } from "../../../../constants/constants";
import _ from 'lodash';

export default class PanelTableList extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0
    };
  }

  render() {
    const {items, actions, onSaveIHDate, dataKey, renderPanelActions, isRenderIHDate, showEdit} = this.props;
    return (
      <div className="panel-group mg-0" id={PANEL_TABLE_ID.TAB_PRODUCTION_VIEW}>
        {
          (items||[]).map((item, index) => {
            return (
              <PanelTable
                key={_.get(item,"_id",index)}
                active={index === this.state.activeIndex}
                cutTicketData={item}
                headers={_.get(item, "headers", [])}
                data={_.get(item, (dataKey || "progressByDate"), [])}
                actions={actions}
                contractor={item.contractor}
                parrentId={PANEL_TABLE_ID.TAB_PRODUCTION_VIEW}
                tabId={PANEL_TABLE_ID.TAB_PRODUCTION_VIEW + index}
                contentId={PANEL_TABLE_ID.PRODUCTION_VIEW + index}
                //onUpdateProgress={()=>onUpdateProgress(item)}
                onSaveIHDate={(it)=>onSaveIHDate(it)}
                setActivePanel={()=>{
                    this.setState({activeIndex: (this.state.activeIndex === index) ? -1: index})
                  }
                }
                renderPanelActions={()=>renderPanelActions(item)}
                isRenderIHDate={isRenderIHDate}
                showEdit={showEdit}
              />
            );
          })
        }
      </div>
    );
  }
}

PanelTableList.propTypes = {
  onUpdateProgress: PropTypes.func,
  onSaveIHDate: PropTypes.func,
  renderPanelActions: PropTypes.func,
  id: PropTypes.string,
  dataKey: PropTypes.string,
  items: PropTypes.array,
  actions: PropTypes.array,
  isRenderIHDate: PropTypes.bool,
};
