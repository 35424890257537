import orderServices from '../../../services/orderServices';
import _ from 'lodash';
import C from '../../../constants/customer-po/current/showOrderList';
import { PAGINATION } from '../../../constants/constants';
import { changeOrderDetail } from './showOrderDetail';
import * as R from 'ramda';

export const listCustomerOrders = (options = {}) => dispatch => {
  dispatch({ type: C.FETCH_ORDER_LIST });
  dispatch({type: C.LOAD_MORE });
  dispatch({type: C.LOADING });
  return orderServices.getListOrder(options).then(res => {
    dispatch({ type: C.CANCEL_FETCH_ORDER_LIST });
    if (res.data.items.length > 0) {
      const payload = R.pathOr([], ['data', 'items'], res).filter(it => !it.isCompleted && !it.isCancel);
      if (payload.length == 0 || payload.length < PAGINATION.PAGE_SIZE) { 
        dispatch({type: C.CANCEL_LOAD_MORE });
      }
      dispatch({type: C.CHANGE_ORDER_LIST, payload });
      dispatch(changeSelectedOrder(_.head(payload)));
      
    } else {
      dispatch({type: C.CANCEL_LOAD_MORE });
      dispatch({type: C.CHANGE_ORDER_LIST, payload: [] });
    }
    dispatch({type: C.CANCEL_LOADING });
    return res;
  }).catch(error => {
    dispatch({ type: C.CANCEL_FETCH_ORDER_LIST });
    dispatch({ type: C.CANCEL_LOAD_MORE });
    dispatch({type: C.CANCEL_LOADING });
    console.error(error);
  }); 
};

export const listFirstOrders = (options = {}) => dispatch => { 
  dispatch({ type: C.FETCH_ORDER_LIST });
  dispatch({type: C.LOAD_MORE });
  dispatch({type: C.LOADING });
  return orderServices.getListOrder(options).then(res => {
    if (res.data.items.length > 0) {
      dispatch({ type: C.CANCEL_FETCH_ORDER_LIST });
      const payload = R.pathOr([], ['data', 'items'], res).filter(it => !it.isCompleted && !it.isCancel);
      if (payload.length == 0 || payload.length < PAGINATION.PAGE_SIZE) { 
        dispatch({type: C.CANCEL_LOAD_MORE });
      }
      dispatch({type: C.CHANGE_FIRST_ORDER_LIST, payload });
      dispatch(changeSelectedOrder(_.head(payload)));
    } else {
      dispatch({type: C.CANCEL_LOAD_MORE });
      dispatch({type: C.CHANGE_FIRST_ORDER_LIST, payload: [] });
    }
    dispatch({type: C.CANCEL_LOADING });
    return res;
  }).catch(error => {
    dispatch({ type: C.CANCEL_FETCH_ORDER_LIST });
    dispatch({ type: C.CANCEL_LOAD_MORE });
    dispatch({type: C.CANCEL_LOADING });
    console.error(error);
  }); 
};

export const changeSelectedOrder = order => dispatch => {
  dispatch(changeOrderDetail(order));
};

export const changeFilterText = text => dispatch => {
  dispatch({ type: C.CHANGE_FILTER_TEXT, payload: text});
};

export const changePageNumber = number => dispatch => {
  dispatch({ type: C.CHANGE_PAGE_NUMBER, payload: number });
};

export const cancelChangePageNumber = () => dispatch => {
  dispatch({ type: C.RESET_CHANGE_PAGE_NUMBER});
};

export const changeSearch = () => dispatch => {
  dispatch({ type: C.IS_SEARCH });
};

export const cancelChangeSearch = () => dispatch => {
  dispatch({ type: C.CANCEL_IS_SEARCH });
};

export const removeListCustomerOrders = id => dispatch => {
  dispatch({ type: C.REMOVE_ORDER_LIST, payload: id });
};

export const updateListCustomerOrders = order => dispatch => {
  dispatch({ type: C.UPDATE_ORDER_LIST, payload: order });
  dispatch(changeOrderDetail(order));
};