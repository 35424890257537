import {
  getListCutTicketDAL,
  addCutTicketDAL,
  editColorCutTicketDAL,
  editCutTicketDAL,
  getCutTicketDAL,
  deleteCutTicketDAL,
  uploadFile,
  updateCutTicketColorProgress,
  deleteCutTicketColorProgress,
  updateCutTicketColorQAProgress,
  deleteCutTicketColorQAProgress,
} from '../api/cutTicketApi';
import _ from 'lodash';

class cutTicketServices {

  constructor() {
  }


  getListCutTicket (styleId, options = {}) {
      // options.populate = 'colors,colors|store,colors|store|packingStandard' + (options.populate ? `,${options.populate}` : '');

      return getListCutTicketDAL(styleId, options);
  }

  getDetail (id, options) {
    return getCutTicketDAL(id, options);
  }

  deleteCutTicket (id) {
    return deleteCutTicketDAL(id);
  }

  editCutTicket (id, data) {
    let promises = (data.images || []).map(i => {
      return i.file ? uploadFile(i.file).then(res => _.get(res || {}, 'data.filePath', '')) : Promise.resolve(i);
    });

    return Promise.all(promises)
      .then(images => {
        data.images = (images || []).filter(i => !!i);

        return editCutTicketDAL(id, data);
      })
      .catch(error => {
        throw error;
      });
  }

  editColorCutTicket (id, data) {
    return editColorCutTicketDAL(id, data);
  }

  updateCutTicketColorProgress (id, data) {
    return updateCutTicketColorProgress(id, data);
  }

  updateCutTicketColorQAProgress (id, data) {
    return updateCutTicketColorQAProgress(id, data);
  }

  deleteCutTicketColorProgress (id, data) {
    return deleteCutTicketColorProgress(id, data);
  }

  deleteCutTicketColorQAProgress (id, data) {
    return deleteCutTicketColorQAProgress(id, data);
  }

  addCutTicket (orderId, data) {
    let promises = (data.images || []).map(i => {
      return i.file ? uploadFile(i.file).then(res => _.get(res || {}, 'data.filePath', '')) : Promise.resolve(i);
    });

    return Promise.all(promises)
      .then(images => {
        data.images = (images || []).filter(i => !!i);

       return addCutTicketDAL(orderId, data);
      })
      .catch(error => {
        throw error;
      });
  }

}

export default new cutTicketServices();
