import React, { Component } from 'react';
import { connect } from 'react-redux';
//constants
import { Store } from '../../../CssHandleModule/functions'
import I18n from 'i18n-js'
//selectors
import * as selector from '../../../../selectors/ship-out/createShipment';

const store = new Store();
store.setStyles({
    orderNumbers: [1, 2, 3, 4],
    aligns: [
        { order: 1, margin: { right: '10px' } },
        { order: 2, margin: { right: '50px' } },
        { order: 3, margin: { top: 27 } },
        { order: 4, margin: { top: -7 } }
    ],
    borders: [
        { order: 1, right: '1px dashed gray' }
    ],
    fonts: [
        { order: 1, color: 'red' }
    ],
    sizes: [
        { order: 1, width: 60 }
    ]
});

class TransportFee extends Component {

    constructor(props) {
        super(props)
    }

    changeTransportFee = (key, value) => {
        this.props.changeTransportFee(key, value)
    }

    render() {
        const { type, styleQuantity, disabled, total } = this.props
        const transportFee = this.props.transportFee || {}
        return (
            <div>
                {type == 'input' &&
                    <div className="mg-top-10">

                        <div className="wrap-transport-fees">
                            <div className="header-bottom bg-white">
                                <div className="col-md-2 col-sm-4 col-xs-12 title">
                                    <h3 className="color-number line-h-60">{I18n.t('PageShiping.TransportFees')}</h3>
                                </div>
                                <div className="col-md-10 col-sm-8 col-xs-12 list-input">
                                    <div className="col-md-4 col-xs-12 input">
                                        <ul>
                                            <li>
                                                <span>{I18n.t('PageShiping.CRS')}</span>
                                            </li>
                                            <span className="wrap-input inline-flex"  >
                                                <input disabled={disabled} type='number' min="0" className='form-control' value={transportFee ? transportFee.crsPaperwork : 0} onChange={(e) => this.changeTransportFee('crsPaperwork', e.target.value)} /><span className="mg-auto-10">VND</span>
                                            </span>
                                        </ul>
                                    </div>
                                    <div className="col-md-4 col-xs-12 input">
                                        <ul>
                                            <li>
                                                <span>{I18n.t('PageShiping.LocalCharge')}</span>
                                            </li>
                                            <span className="wrap-input inline-flex"  >
                                                <input disabled={disabled} type='number' min="0" className='form-control' value={transportFee ? transportFee.localCharge : 0} onChange={(e) => this.changeTransportFee('localCharge', e.target.value)} /><span className="mg-auto-10">VND</span>
                                           </span>
                                        </ul>
                                    </div>
                                    <div className="col-md-4 col-xs-12 input">
                                        <ul>
                                            <li>
                                                <span>{I18n.t('PageShiping.TruckingOut')}</span>
                                            </li>
                                            <span className="wrap-input inline-flex"  >
                                                <input disabled={disabled} type='number' min="0" className='form-control' value={transportFee ? transportFee.trunkingOut : 0} onChange={(e) => this.changeTransportFee('trunkingOut', e.target.value)} /><span className="mg-auto-10">VND</span>
                                           </span>
                                        </ul>
                                    </div>
                                    <div className="col-md-12 col-xs-12 input mg-top-15">
                                        <ul>
                                            <li>
                                                <span>{I18n.t('PageShiping.Note')}</span>
                                            </li>
                                            <li>
                                                <textarea disabled={disabled} type='text' className='form-control' value={transportFee ? transportFee.note : ''} onChange={(e) => this.changeTransportFee('note', e.target.value)} />
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                }
                {type == 'view' &&
                    <div className="wrap-transport-fees">
                        <div className="header-bottom bg-white bd-dashed-top">
                            <div className="col-md-2 col-sm-4 col-xs-12 title">
                                <h3 className="color-number">{I18n.t('PageShiping.TransportFees')}</h3>
                            </div>
                            <div className="col-md-10 col-sm-8 col-xs-12 list-input">
                                <div className="col-md-4 col-xs-12 input">
                                    <ul>
                                        <li>
                                            <span>{I18n.t('PageShiping.CRS')}</span>
                                        </li>
                                        <li>
                                            <span className="color-number">{_.round(transportFee.crsPaperwork / total * styleQuantity, 2)} VND</span>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-md-4 col-xs-12 input">
                                    <ul>
                                        <li>
                                            <span>{I18n.t('PageShiping.LocalCharge')}</span>
                                        </li>
                                        <li>
                                            <span className="color-number">{_.round(transportFee.localCharge / total * styleQuantity, 2)} VND</span>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-md-4 col-xs-12 input">
                                    <ul>
                                        <li>
                                            <span>{I18n.t('PageShiping.TruckingOut')}</span>
                                        </li>
                                        <li>
                                            <span className="color-number">{_.round(transportFee.trunkingOut / total * styleQuantity, 2)} VND</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>

        );
    }
}

const mapStateToProps = state => ({
    // transportFee: selector.getTransportFee(state),
})

const mapDispatchToProps = dispatch => ({
    // changeTransportFee(key, value) {
    //     dispatch(changeTransportFee(key, value))
    // }
})

export default connect(mapStateToProps, mapDispatchToProps)(TransportFee);

