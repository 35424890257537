import _ from 'lodash';
import * as R from 'ramda';
import { createSelector } from 'reselect';


export const getPageNumber = ({ productionOrderState }) => productionOrderState.pageNumber;
export const getFilterText = ({ productionOrderState }) => productionOrderState.filterText;
export const isOpenLoadMore = ({ productionOrderState }) => productionOrderState.isLoadMore;
export const isSearch = ({ productionOrderState }) => productionOrderState.isSearch;
export const getOrders = ({ productionOrderState }) => productionOrderState.items;
export const isLoading = ({ productionOrderState }) => productionOrderState.loading;

