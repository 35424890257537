import React, { Component } from 'react';
import { connect } from 'react-redux'
import I18n from 'i18n-js';
import _ from 'lodash';
import { Store } from '../../../CssHandleModule/functions'
import * as selector from '../../../../selectors/ship-out/createINV';
import { changePrice, changeHSCode, changeCat } from '../../../../actions/ship-out/createINVActions';
const store = new Store();
store.setStyles({
    orderNumbers: [1, 2],
    aligns: [
        { order: 1, margin: { left: '10px' } },
        { order: 2, margin: { right: '10px' } }
    ]
});

class commondityComponent extends Component {

    constructor(props) {
        super(props)

    }

    changePrice = (orderId, styleId, cutTicketId, colorId, value) => {
        this.props.changePrice(orderId, styleId, cutTicketId, colorId, _.toNumber(value))
    }

    changeHSCode = (orderId, value) => {
        this.props.changeHSCode(orderId, value)
    }

    changeCat = (orderId, value) => {
        this.props.changeCat(orderId, value)
    }

    render() {
        const { data, getDataOfCommodity } = this.props
        const listDataOfCommodity = getDataOfCommodity(data._id);

        return (
            <div className="inline-block w-full bg-gray">

                <div className="d-flex justify-content-between pd-10">
                    <div className="d-flex">
                        <p className="size-12 line-h-35">{I18n.t('PageShiping.Commodity')}</p>
                        <span className="color-orange bold mg-left-50 size-14 line-h-35">{data.commodity || ''}</span>
                    </div>

                    <div className="d-flex justify-content-end">
                        <div className="d-flex mg-right-30">
                            <p className="size-12 mg-right-15 line-h-35">{I18n.t('PageShiping.HSCode')} </p>
                            <span>
                                <input className="form-control center mr-10 w-100" type="text"
                                    value={data.hsCode || ''}
                                    onChange={(e) => this.changeHSCode(data._id, e.target.value)}
                                />
                            </span>
                        </div>
                        <div className="d-flex mg-right-30">
                            <p className="size-12 mg-right-15 line-h-35">{I18n.t('PageShiping.Cat')} </p>
                            <span>
                                <input className="form-control center mr-10 w-100" type="text"
                                    value={data.catNo || ''}
                                    onChange={(e) => this.changeCat(data._id, e.target.value)}
                                />
                            </span>
                        </div>
                        {/* <div className="size-12 color-link"><a>{cutTicket.store && cutTicket.store.packingStandard && cutTicket.store.packingStandard.name || '-'}</a></div> */}
                    </div>
                </div>
                <div className="bg-white">
                    <table className="global-table w-full">
                        <tbody>
                            <tr>
                                <th className='center'>{I18n.t('PageShiping.CustomerPO')}</th>
                                <th className='center'>{I18n.t('PageShiping.Style')}</th>
                                <th className='center'>{I18n.t('PageShiping.CutTicket')}</th>
                                <th className='center'>{I18n.t('PageShiping.Store')}</th>
                                <th className='center'>{I18n.t('PageShiping.Color')}</th>
                                <th className='center'>{I18n.t('PageShiping.Quantity')}</th>
                                <th className='center'>{I18n.t('PageShiping.Price')}</th>
                                <th className='center'>{I18n.t('PageShiping.Amount')}</th>
                            </tr>
                            {listDataOfCommodity.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td className='center'>{item.customerPO}</td>
                                        <td className='center'>{item.style}</td>
                                        <td className='center'>{item.cutTicket}</td>
                                        <td className='center'>{item.store}</td>
                                        <td className='center'>{item.color}</td>
                                        <td className='center'>{item.quantity}</td>
                                        <td className='center  w-100'><input className="form-control center pd-right-0 mr-10" type="number" min="0"
                                            value={item.price || 0}
                                            onChange={(e) => this.changePrice(item._id, item.styleId, item.cutTicketId, item.colorId, e.target.value)}
                                        />
                                        </td>
                                        <td className='center color-red'>{'$' + item.amount}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

commondityComponent.propTypes = {

};

const mapStateToProps = state => ({
    getDataOfCommodity: selector.getDataOfCommodity(state),
    // hsCode: selector.getHSCode(state),
    // cat: selector.getCat(state),
})

const mapDispatchToProps = dispatch => ({

    changePrice(orderId, styleId, cutTicketId, colorId, value) {
        value = _.round(value * 1, 2);
        dispatch(changePrice(orderId, styleId, cutTicketId, colorId, value))
    },
    changeHSCode(orderId, value) {
        dispatch(changeHSCode(orderId, value))
    },
    changeCat(orderId, value) {
        dispatch(changeCat(orderId, value))
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(commondityComponent);