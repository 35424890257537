/**
 * @author hienhc <hienhc@nexlesoft.com>
 * @create date 2018-08-30 02:16:10
*/
import { createSelector } from 'reselect';
import moment from 'moment';
import { isCheckable } from "../../core/ship-out/helpers";

/**
 * Get a list of filters that can be use to filter pkl productions
 */
export const getListFilters = () =>  [
    {field: 'SelectFilter', name: 'PageShiping.SelectFilter', type: 'text'},
    {field: 'customerPO', name: 'PageShiping.CustomerPO', type: 'text'}, 
    {field: 'customer', name: 'PageShiping.Customer', type: 'text'},
    {field: 'type', name: 'PageShiping.PackingType', type: 'text'},
    {field: 'exFactory', name: 'PageShiping.ExFactory', type: 'dateTo'}
]

/**
 * Get selected filter
 */
export const getSelectedFilter = createSelector(
    [getListFilters, ({ showPklProdList }) => showPklProdList.filter],
    (filters, selectedFilter) => {
        if (!selectedFilter) return filters[0].field;
        return selectedFilter;
    }
)

export const getSearchValue = ({ showPklProdList }) => showPklProdList.searchValue;

export const isSearching = ({ showPklProdList }) => showPklProdList.searching;

export const isFetching = ({ showPklProdList }) => showPklProdList.fetching;

const genDisplayPklProdViewModel = (model, checked, checkable) => {
    if (!model) return {};
    return new Proxy(model, {
        get(obj, prop) {
            const actualTotal = obj['actualTotal'] * 1;  // Convert to number
            const planTotal = obj['planTotal'] * 1;
            switch (prop) {
                case 'checked':
                    return checked;
                case 'checkable':
                    return checkable;
                case 'planTotal':
                    return planTotal;  // Convert to number
                case 'actualTotal':
                    return (actualTotal > 0) ? actualTotal : '-';
                case 'remaining':
                    return (actualTotal > 0) ? planTotal - actualTotal : '-';
                case 'exFactory':
                    return moment(obj['exFactory']).format('MMM DD, YYYY');
                case 'raw':
                    return obj;
                default:
                    return obj[prop];
            }
        },
    });
};

export const getCheckedList = ({ showPklProdList }) => showPklProdList.checkedList;

export const getCheckableList = createSelector(
    [
        ({ showPklProdList }) => showPklProdList.displayList, 
        getCheckedList
    ],
    (displayList, checkedList) => {
        const checkableDisplayList = displayList.filter(it => isCheckable(checkedList, it));
        return [...checkableDisplayList, ...checkedList];
    }
)


export const getDisplayList = createSelector([
    ({ showPklProdList }) => showPklProdList.displayList,
    getCheckedList,
    getCheckableList
], (displayList, checkedList, checkableList) => {
    return displayList.map(it => genDisplayPklProdViewModel(it, checkedList.findIndex(item => item._id === it._id) >= 0, it in checkableList))
});

export const getCurrentPage = ({ showPklProdList }) => showPklProdList.currentPage;

export const getTotalPages = ({ showPklProdList }) => showPklProdList.totalPages;

export const canPreview = createSelector(
    [getCheckedList],
    (checkedList) => {
        if (checkedList.length > 0) return true;
        return false;
    }
);

export const canCreatePklShip = createSelector(
    [canPreview],
    (previewable) => previewable
);

export const getPackingTypeOfCheckedList = ({showPklProdList}) => showPklProdList.checkedList[0] && showPklProdList.checkedList[0].packingType;

export const getSearched = ({showPklProdList}) => showPklProdList.searched;

export const getSearchQuery = (searched, option, selectedFilter, searchValue) => {
    if(!searched) {
        return option;
    }
    if(selectedFilter && searchValue) {
        let op = option;
        let key = selectedFilter;
        if(selectedFilter != 'exFactory' && !searchValue.value) return op;
        if(selectedFilter == 'exFactory') {
            let begin = moment(searchValue.from).format('YYYY-MM-DD');
            let end = moment(searchValue.to).format('YYYY-MM-DD');
            let date = `${begin}:${end}`;
            op[key] = date
        } else {
            op[key] = searchValue.value
        }
        return op;
    }   
}