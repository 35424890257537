import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import * as PATH from '../../../../../constants/routeConstants';
import FormGroup from '../../../forms/FormGroup';
import Input from '../../../forms/Input';
import {genVietNamDong} from '../../../../../functions/helpers';
import AccountsPayablePlanImportExportNameSelect from '../../../../containers/accounting/AccountsPayablePlanImportExportNameSelect';
import AccountsPayablePlanImportExportRadioLists from '../../../../containers/accounting/AccountsPayablePlanImportExportRadioLists';
const formatCurrency = require('format-currency');
const DateTimeField = require('react-datetime');

class InvoiceInfo extends Component{
    constructor(props) {
        super(props);
    }

    onChangeName = (item) => {
        // console.log(item)
        this.props.onChangeItem('name', item.name)
    }

    onChangeInvoiceNo = (value) => {
        this.props.onChangeItem('invoiceNo', value)
    }

    onChangeDate = (key, value) => {
        this.props.onChangeItem(key, value)
    }   

    render() {
        const {items, totalAmountINV, amountINV, data, pathname} = this.props;
        return (
            <div id="header-accounting" className="inline-block w-full position-relative">
                    <div className="left w-200" />
                    <div className="right top-0 w-full pd-left-230">
                        <div className="border-title-left" />
                        <div className="title-left left">
                            <div className="btn-group btn-supplier-contractor-importExport ">
                                <button className="btn btn-secondary btn-lg dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Import/Export</button>
                                <div className="dropdown-menu">
                                    <ul>
                                        <li><Link to={PATH.ACCOUNTING_PAYABLE_PLAN_CONTRACTOR_URL}>Contractor</Link></li>
                                        <li><Link to={PATH.ACCOUNTING_PAYABLE_PLAN_SUPPLIER_URL}>Supplier</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <AccountsPayablePlanImportExportRadioLists pathname = {pathname}/>
                        </div>

                        <div className="bd-dashed-bottom wrap-input-global pd-15-0 margin-small">

                            <div className="col-md-6 col-xs-12 input">
                            {/* <ul>
                                <li><span>{label} {required && <span className="color-red icon-request">*</span>}</span></li>
                                <li className={`${type}`}>
                                    {inputComponent}
                                </li>
                            </ul> */}
                                <FormGroup
                                    type="input"
                                    label="Name"
                                    inputComponent={
                                        <AccountsPayablePlanImportExportNameSelect
                                            items={items}
                                            value = {data.name}
                                            onChangeItem={this.onChangeName}
                                        />
                                    }
                                />
                            </div>
                            <div className="col-md-6 col-xs-12 input">
                                <FormGroup
                                    type="input"
                                    label="Issued Date"
                                    inputComponent={
                                        <DateTimeField
                                            timeFormat={false} closeOnSelect dateFormat="MMM DD, YYYY"
                                            value={moment(data.issuedDate)} 
                                            onChange={date => this.onChangeDate('issuedDate', date.format())}
                                        />
                                    }
                                />
                            </div>
                            <div className="col-md-6 col-xs-12 input">
                                <FormGroup
                                    type="input"
                                    label="Invoice No."
                                    inputComponent={
                                        <Input
                                            type="text"
                                            value={data.invoiceNo}
                                            onChange = {e => this.onChangeInvoiceNo(e.target.value)}
                                            placeholder=""
                                        />
                                    }
                                />
                            </div>
                            <div className="col-md-6 col-xs-12 input">
                                <FormGroup
                                    type="input"
                                    label="Due Date"
                                    inputComponent={
                                        <DateTimeField 
                                            timeFormat={false} closeOnSelect dateFormat="MMM DD, YYYY"
                                            value={moment(data.dueDate)} 
                                            onChange={date => this.onChangeDate('dueDate', date.format())}
                                        />
                                    }
                                />
                            </div>
                        </div>

                        <div className="bottom text-left size-12 pd-15-0 inline-block w-full">
                            <div className="col-md-4 col-sm-6 col-xs-15">
                                <p className = "size-17"><span className="mg-right-15">Amount INV</span> <span className="bold color-number">{formatCurrency(amountINV)} VND</span></p>
                            </div>
                            <div className="col-md-4 col-sm-6 col-xs-15">
                                <p className = "size-17"><span className="mg-right-15">Total Amount INV</span> <span className="bold color-number">{formatCurrency(totalAmountINV)} VND</span></p>
                            </div>
                            <div className="col-md-4 col-sm-6 col-xs-15">
                                <p className = "size-15"><span className="mg-right-15">Say</span> <span className="bold">{genVietNamDong(totalAmountINV)}</span></p>
                            </div>
                        </div>
                    </div>
                    
                </div>
        )
    }
}

export default InvoiceInfo;