import * as types from '../constants/actionTypes';
import {showLoading, hideLoading} from './commonUseActions';
import payInfoServices from "../services/payInfoServices";

function addPayInfo (data) {
  return (dispatch) => {
    showLoading(types, dispatch);

    payInfoServices.createPayInfo(data).then((res) => {
      hideLoading(types, dispatch, (res && !res.success) ? res.error : undefined);

      if (res && res.success) {
        dispatch({
          type: types.PAYINFO.CREATE_CREDIT_DEBIT,
          data: res.data.items
        });
      }
    }, (error) => {
      hideLoading(types, dispatch, error);
    });
  };
}

function getListPayInfo (options = {}) {
  return (dispatch) => {
    showLoading(types, dispatch);
    payInfoServices.getListPayInfo(options).then((res) => {
      hideLoading(types, dispatch);
      dispatch({
        type: types.PAYINFO.GET_PAYINFO_SUCCESS,
        data: res.data || []
      });
    }, (error) => {
      hideLoading(types, dispatch);
    });
  };
}

export default {
  addPayInfo,
  getListPayInfo
}