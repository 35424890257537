import { combineReducers } from 'redux'
import E from '../../constants/production/editPacking';
import C from '../../constants/production/addPacking';
import _ from 'lodash';

const packingInfo = (state = null, action) => {
    switch (action.type) {
        case E.INFO_PACKING:
            return action.payload;
        case C.CHANGE_EMPTY_DATA:
            return action.payload;
        default:
            return state;
    }
}

const packing = (state = null, action) => {
    switch (action.type) {
        case E.GET_PACKING:
            return action.payload;
        case C.CHANGE_EMPTY_DATA:
            return action.payload;
        default:
            return state;
    }
}

const listCartons = (state = [], action) => {
    const { type, payload } = action;
    let styleId, cutTicketId, colorId, group, cartonNo, value, key, dic, sizeName, no, total;
    let totalTam = 0;
    switch (action.type) {
        case E.GET_PACKING:
            if (action.payload && action.payload.type === 'GOHH') return {};
            dic = {};
            action.payload.styles.map(style => {
                style.cutTickets.map(cutTicket => {
                    cutTicket.colors.map(color => {
                        const groups = _.groupBy(color.packings, pk => pk != null && pk.props.group);
                        key = `${style.style}:${cutTicket.cutTicket}:${color.color}`;
                        const temp = !_.isEmpty(groups) && _.map(groups, (v, k) => {
                            return {
                                active: false,
                                raw: _.map(v, it => {
                                    if (it)
                                        return {
                                            checked: true,
                                            props: it.props,
                                            items: it.items
                                        }
                                }),
                                quantity: v.length,
                                qtyCtn: v[0] ? _.sum(_.cloneDeep(v)[0].items.map(item => item.planQuantity - (item.actualQuantity || 0))) : 0,
                                group: k
                            }
                        })
                        let cartons = temp ? temp.map(carton => {
                            if (carton)
                                return {
                                    active: null,
                                    canExcute: carton.quantity == 1,
                                    checked: carton.quantity == 1 ? true : false,
                                    group: carton.group,
                                    ctnNo: carton.quantity > 1 ? carton.group : carton.raw[0] && carton.raw[0].props.no,
                                    quantity: carton.quantity,
                                    qtyCtn: carton.qtyCtn,
                                    props: carton.raw[0] && carton.raw[0].props,
                                    items: carton.raw[0] && carton.raw[0].items,
                                }
                        }) : [];
                        cartons.sort((a, b) => {
                            if ((a.group.split('-')[0] * 1) > (b.group.split('-')[0] * 1)) return 1;
                            if ((a.group.split('-')[0] * 1) < (b.group.split('-')[0] * 1)) return -1;
                            return 0;
                        });
                        (temp || []).map((carton) => carton.quantity > 1 &&
                            carton.raw.map(item => {
                                const result = {
                                    active: false,
                                    canExcute: true,
                                    checked: item && item.checked,
                                    group: carton.group,
                                    ctnNo: item ? `${item.props.no}` : '',
                                    quantity: 1,
                                    qtyCtn: _.sum(item.items.map(i => i.planQuantity - (i.actualQuantity || 0))),
                                    props: item && item.props,
                                    items: item && item.items
                                }
                                const index = item && _.findLastIndex(cartons, carton => carton.group == item.props.group) + 1;
                                item && cartons.splice(index, 0, result)
                            })
                        )
                        // cartons.sort((a, b) => Number(a.ctnNo) && Number(b.ctnNo) && a.ctnNo < Number(b.ctnNo));
                        dic[key] = cartons
                        //{checked: true, quantity: v.length, raw: v};
                        // _.map(groups, (v, k) => {
                        //     const key = `${item._id}:${style.style._id}:${cutTicket.cutTicket._id}:${color.color._id}:${k}`;
                        //     dic[key] = {checked: true, quantity: v.length, raw: v};
                        // });
                    })
                })
            })
            return dic;
        case E.CHANGE_SHOW_CARTON_DETAIL:
            styleId = action.payload.styleId;
            cutTicketId = action.payload.cutTicketId;
            colorId = action.payload.colorId;
            group = action.payload.group;
            key = `${styleId}:${cutTicketId}:${colorId}`;
            dic = _.cloneDeep(state)
            dic[key].map(carton => (carton.group == group && carton.canExcute) ? typeof carton.active !== "undefined" ? carton.active= !carton.active : null : null)
            let totalTam2 = 0
            dic[key].map(carton => {
                let qtyCtn = 0
                carton.items.map((item) => {
                    let tam = item.actualQuantity == null ? item.planQuantity : item.actualQuantity;
                    qtyCtn += tam
                })
                if (carton.canExcute == true) {
                    carton.totalQtyCtn = qtyCtn
                    totalTam2 += carton.totalQtyCtn
                }
            });

            dic[key].map(carton => {
                if (carton.canExcute == false) {
                    carton.totalQtyCtn = totalTam2
                }
            });
            return dic;
        case E.CHANGE_CHECKED_PACKING:
            styleId = action.payload.styleId;
            cutTicketId = action.payload.cutTicketId;
            colorId = action.payload.colorId;
            group = action.payload.group;
            cartonNo = action.payload.cartonNo
            value = action.payload.value;
            key = `${styleId}:${cutTicketId}:${colorId}`;
            dic = _.cloneDeep(state)
            dic[key].map(carton => (carton.group == group && carton.canExcute && carton.ctnNo == cartonNo) ? carton.checked = value : null)
            return dic;
        case E.CHANGE_PACKING_ACTUAL_NUMBER:
            styleId = action.payload.styleId;
            cutTicketId = action.payload.cutTicketId;
            colorId = action.payload.colorId;
            sizeName = action.payload.sizeName;
            no = action.payload.no;
            value = action.payload.value;
            total = action.payload.total;
            key = `${styleId}:${cutTicketId}:${colorId}`;
            dic = _.cloneDeep(state);
            dic[key].map(carton => {
                if ((dic[key].length == 1 || ( dic[key].length > 1 )) && carton.ctnNo == no && carton.canExcute == true) {
                    carton.items.map(item => item.name == sizeName ? item.actualQuantity = value : null)
                    carton.qtyCtn = _.sum(carton.items.map(item => item.planQuantity - (item.actualQuantity || 0)))
                }
                let qtyCtn = 0
                carton.items.map((item) => {
                    let tam = item.actualQuantity == null ? item.planQuantity : item.actualQuantity;
                    qtyCtn += tam
                })
                if ( carton.canExcute == true) {
                    carton.totalQtyCtn = qtyCtn
                    totalTam += carton.totalQtyCtn
                }
            });

            dic[key].map(carton => {
                if (carton.active == null && carton.canExcute == false) {
                    carton.totalQtyCtn = totalTam
                }
            });
            return dic;
        case E.CHANGE_GWKGSNWKGS_ACTUAL_NUMBER:
            styleId = action.payload.styleId;
            cutTicketId = action.payload.cutTicketId;
            colorId = action.payload.colorId;
            sizeName = action.payload.sizeName;
            no = action.payload.no;
            value = action.payload.value;
            total = action.payload.total;
            key = `${styleId}:${cutTicketId}:${colorId}`;
            dic = _.cloneDeep(state);
            dic[key].map(carton => {
                if ((dic[key].length == 1 || ( dic[key].length > 1 )) && carton.ctnNo == no && carton.canExcute == true) {
                    carton.items.map(item => item.name == sizeName ? item.value = value : null)
                }
            });
            return dic;
        case C.DELETE_CARTON:
            const { type, payload } = action;
            styleId = action.payload.styleId
            cutTicketId = action.payload.cutId
            colorId = action.payload.colorId
            key = `${styleId}:${cutTicketId}:${colorId}`
            no = action.payload.no
            dic = _.cloneDeep(state);
            dic[key] = dic[key].filter((item, index) => index == 0 && item.props.no != no );
            return dic;
        default:
            return state;
    }
}

const resetPackedQuantity = (state = null, action) => {
    const { type } = action;
    switch (type) {
        case E.GET_PACKING:
            return true;
        case E.CAN_CHANGE_PACKED_QUANTITY:
            return false;
        default:
            return state;
    }
}
export const editPacking = combineReducers({
    packingInfo,
    packing,
    listCartons,
    resetPackedQuantity
})