import C from '../../../constants/accounting/receivable/showInvoiceList';
import accountingServices from "../../../services/AccountReceivableService";
import { getExportReceiableINVRequest } from '../../../api/accountingApi';
import { toastr } from 'react-redux-toastr';
import I18n from 'i18n-js';
var FileSaver = require('file-saver');
export const getListInvoice = (params) => dispatch => {
    accountingServices.listInvoices(params).then((res) => {
        dispatch({type: C.CHANGE_LIST_INV, payload: res.data});
        dispatch({type: C.CHANGE_PAGING_TOTAL, payload: res.meta.pages});
    }, (error) => {
        console.log(JSON.stringify(error));
    })
}

export const getAmountInvoice = (params) => dispatch => {
    accountingServices.getAmountInvoice(params).then(res => {
        dispatch({type: C.CHANGE_TOTAL_PAID, payload: _.get(res, 'data.paid', 0)});
        dispatch({type: C.CHANGE_TOTAL_AMOUNT, payload: _.get(res, 'data.amount', 0)});
        dispatch({type: C.CHANGE_TOTAL_REMAINING, payload: _.get(res, 'data.amount', 0) - _.get(res, 'data.paid', 0)});
    }, (error) => {
        console.log(JSON.stringify(error));
    })
}

// export const getPaidDetailInvoice = (params) => dispatch => {
//     accountingServices.getPaidDetailInvoice(params).then(res => {
//         dispatch({type: C.CHANGE_PAID_DETAIL, payload: res.data.items});
//     }, (error) => {
//         console.log(JSON.stringify(error));
//     })
// }

// export const changeCheckedList = (item, checked) => dispatch => {
//     if(item) {
//         if(checked)
//             dispatch({type: C.ADD_CHECKED_INV_LIST, payload : item})
//         else
//             dispatch({type: C.REMOVE_CHECKED_INV_LIST, payload: item})
//     }
// }

export const deleteInvoice = (id, params) => dispatch => {
    accountingServices.deleteInvoice(id).then(res => {
        if(res.success) {
            dispatch({type: C.DELETE_SUCCESS, payload: true});
            //getListInvoice(params)
            accountingServices.listInvoices(undefined).then((res) => {
                dispatch({type: C.CHANGE_LIST_INV, payload: res.data});
                dispatch({type: C.CHANGE_PAGING_TOTAL, payload: res.meta.pages});
            }, (error) => {
                console.log(JSON.stringify(error));
            })
        }
    })
}

// export const changeListFilter = value => dispatch => {
//     dispatch({ type: C.CHANGE_LIST_FILTER, payload: value });
// };

export const changeSelectedFilter = (value) => dispatch => {
    if(value) {
        dispatch({type: C.CHANGE_SELECT_FILTER, payload: value})
    }
}

export const changeSearchValue = (data, pos) => dispatch => {
    dispatch({type: C.CHANGE_SEARCH_VALUE, payload: {data, pos}})
}


export const changeInvoiceRequest = (value) => dispatch => {
    dispatch({type: C.CHANGE_INVOICE_REQUEST, payload: value})
}

export const createINVRequest = (ids, invRequest) => dispatch => {
    let params = {ids : ids , invRequest : invRequest};
    accountingServices.createINVRequest(params).then(res => {
        if(res.success) {
            // toastr.success(I18n.t('Notify.Success'), 'Create Success');
            dispatch({type: C.CREATE_INV_REQUEST_SUCCESS, payload: {success: true, ids, invRequest}})
            let str = '';
            ids.forEach((item, index) => {
                if (index === ids.length - 1) {
                    str += item;
                } else {
                    str += `${item},`;
                }
            });
            const exportUrl = getExportReceiableINVRequest(str);
            FileSaver.saveAs(exportUrl, "Export_Invoices.xlsx");
        }
    });
    // this.search(selectedFilter, searchValue , currency)
}

// export const search = (selectedFilter, searchValue , currency) => dispatch => {
//     if(selectedFilter && searchValue) {
//         let op = {};
//         let key = selectedFilter;
//         if(selectedFilter == 'dueDate') {
//             let begin = moment(searchValue.from).format('YYYY-MM-DD');
//             let end = moment(searchValue.to).format('YYYY-MM-DD');
//             let date = `${begin}:${end}`;
//             op[key] = date;
//             op[currency] = currency;
//             accountApi.searchInvoiceList(op).then(res => {
//                 if(res.success)
//                     dispatch({type: C.CHANGE_LIST_INV, payload: res.data})
//             })
//         } else {
//             op[key] = searchValue.value;
//             op[currency] = currency;
//             accountApi.searchInvoiceList(op).then(res => {
//                 if(res.success)
//                     dispatch({type: C.CHANGE_LIST_INV, payload: res.data})
//             })
//         }
//     }
// }

export const checkedInvoice = (_id, checked) => dispatch => {
    dispatch({type: C.CHANGE_CHECKED_INVOICE, payload: {_id, checked}})
}

export const resetInvoiceRequest = () => dispatch => {
    dispatch({type: C.CHANGE_INVOICE_REQUEST, payload: ''})
}

export const resetDeleleStatus = () => dispatch => {
    dispatch({type: C.RESET_DELETE_STATUS})
}

// export const changeCurrency = (value) => dispatch => {
//     dispatch({type: C.CHANGE_CURRENCY, payload: value})
// }

export const changeLoading = (value) => dispatch => {
    dispatch({type: C.CHANGE_LOADING, payload: value})
}

export const changePage = (value) => dispatch => {
    dispatch({type: C.CHANGE_PAGING_CURRENT, payload: value})
}

export const changeInvoiceWillBeDeleted = (value) => dispatch => {
    dispatch({type: C.CHANGE_INVOICE_WILL_BE_DELETED, payload: value})
}
