import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import I18n from 'i18n-js';
import { toastr } from 'react-redux-toastr';
import SearchBar from '../../../common/components/SearchBar';
import TableList from '../../../components/shipping/subViews/tableListForPklShip';
import { ACTION_TYPE } from '.././../../../constants/constants';
import { SHIP_OUT } from '../../../../constants/routeConstants';
import * as selector from '../../../../selectors/ship-out/showPklShipList';
import _ from 'lodash';
import {
  changeSearchStatus,
  changeSelectedFilter,
  changeSearchValue,
  getListPklShip,
  changeCheckedList,
  search,
  deletePklShip,
} from '../../../../actions/ship-out/showPklShipListActions';
import {
  getItemDetailToCreateINV,
  activeEditInvScreen,
  getShipInvDetail,
  activeScreenCreateInv,
} from '../../../../actions/ship-out/createINVActions';
import { getPklShipDetail, activeScreenEditPklShip } from '../../../../actions/ship-out/editPklShipActions';
import { changePklShipList, activeScreenCreateShipment } from '../../../../actions/ship-out/createShipmentActions';

import shipOutApi from '../../../../api/shipOutApi';
import ModalConfirm from '../../../../components/common/modals/ModalConfirm';
import CustomPagination from '../../../../components/common/datatables/CustomPagination';
var FileSaver = require('file-saver');
class PKLShipContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        { name: '', type: 'checkbox', value: 'checked', checkable: 'checkable', options: { event: this.onCheck } },
        { name: I18n.t('PageShiping.PackingType'), value: 'packingType' },
        { name: I18n.t(`PageShiping.Customer`), value: `customer` },
        { name: I18n.t(`PageShiping.CustomerPO`), value: `customerPO`, options: { clsTd: 'color-green' } },
        { name: I18n.t(`PageShiping.QTY`), value: `qty` },
        { name: I18n.t(`PageShiping.ExFactory`), value: `exFactory` },
        { name: I18n.t(`PageShiping.INVNo`), value: `invNo` },
        { name: I18n.t(`PageShiping.INVDate`), value: `invDate` },
      ],
      actions: [],
      pageSize: 10,
      pageNumber: 1,
    };
  }

  componentWillMount() {
    this.getList(this.getBaseOption());
  }

  // This function use for get items from search feature
  componentWillReceiveProps(nextPorps) {
    // const {searched, getSearchQuery, selectedFilter, searchValue} = nextPorps;
    // if(searched) {
    //     nextPorps.changeSearchStatus(false);
    //     this.getList(getSearchQuery(this.getBaseOption(), selectedFilter, searchValue))
    // }
  }

  componentDidMount() {}

  //This function use for setup query string for request api
  getBaseOption = () => {
    const { pageSize, pageNumber } = this.state;
    let options = {
      query: JSON.stringify({ isCancel: { $ne: true } }),
      sort: JSON.stringify({ createdAt: -1 }),
      pageSize,
      pageNumber,
    };
    return options;
  };

  getList = options => {
    this.props.getListPklShip(options);
  };

  // These's a function use for paging
  selectPage = page => {
    let options = this.getBaseOption();
    options.pageNumber = page;
    const { searched, getSearchQuery, selectedFilter, searchValue } = this.props;
    this.getList(getSearchQuery(searched, options, selectedFilter, searchValue));
  };

  onChangeFilter = value => {
    this.props.changeSelectedFilter(value);
    this.props.changeSearchStatus(false);
  };

  onChangeValue = (value, pos) => {
    this.props.changeSearchValue(value, pos);
    this.props.changeSearchStatus(false);
  };

  onSearch = () => {
    this.props.changeSearchStatus(true);
    const { searched, getSearchQuery, selectedFilter, searchValue } = this.props;
    this.getList(getSearchQuery(true, this.getBaseOption(), selectedFilter, searchValue));
  };

  displayCreateINV = item => {
    if (item.invId) {
      this.props.activeScreenEditInv();
      this.props.getItemDetailToCreateINV(item._id, true);
      this.props.router.history.push(SHIP_OUT.PKL_SHIP.EDIT_INV);
    } else {
      this.props.activeScreenCreateInv();
      this.props.getItemDetailToCreateINV(item._id, false);
      this.props.router.history.push(SHIP_OUT.PKL_SHIP.CREATE_INV);
    }
  };

  displayEdit = item => {
    this.props.getPklShipDetail(item._id);
    this.props.activeScreenEditPklShip();
    this.props.router.history.push(
      item.isDelete && !item.checkable && !item.invId ? SHIP_OUT.PKL_SHIP.EDIT_REVIEW : SHIP_OUT.PKL_SHIP.EDIT
    );
  };

  displayDelete = item => {
    this.showDeleteConfirm(item);
  };

  exportFirst = item => {
    const id = _.get(item, '_id', undefined);
    if (id) {
      const url = shipOutApi.getExportShipoutAPL(id);
      FileSaver.saveAs(url, 'ShipOutAPL.xlsx');
    } else {
      toastr.error('Error', 'unexpected error occurred');
    }
  };

  exportSecond = item => {
    const id = _.get(item, '_id', undefined);
    if (id) {
      const url = shipOutApi.getExportShipoutPKL(id);
      FileSaver.saveAs(url, 'ShipOutPKL.xlsx');
    } else {
      toastr.error('Error', 'unexpected error occurred');
    }
  };

  onCheck = (item, value) => {
    const { canChecked } = this.props;
    if (value) {
      if (canChecked(item.raw._id)) {
        this.props.changeCheckedList(item.raw, value);
      } else {
        toastr.error(I18n.t('Notify.Error'), 'Please create Invoice before checking !');
      }
    } else {
      this.props.changeCheckedList(item.raw, value);
    }
  };

  viewCreateShipmentScreen = () => {
    const { checkedList } = this.props;
    this.props.activeScreenCreateShipment();
    this.props.router.history.push(SHIP_OUT.PKL_SHIP.CREATE);
    this.props.changePklShipListToCreateShipment(checkedList);
  };

  onDeleteShipOut(id) {
    const { getSearchQuery, selectedFilter, searchValue } = this.props;
    this.props.changeSearchStatus(true);
    let optionSearch = getSearchQuery(true, this.getBaseOption(), selectedFilter, searchValue);
    this.props.deleteShipment(id, optionSearch);
  }

  showDeleteConfirm = item => {
    let { openModal, closeModal } = this.props.modal;
    let modal = (
      <ModalConfirm
        title={I18n.t('PageOrganization.DELETEDPKLSHIP')}
        confirmMessage={I18n.t('PageProduction.DeleteConfirm')}
        messageOk={I18n.t('Btn.YES')}
        messageCancel={I18n.t('Btn.CANCEL')}
        handleClose={closeModal}
        handleOk={() => this.onDeleteShipOut(item._id)}
      />
    );
    openModal(modal);
  };

  render() {
    let { canDeletePKL, roles } = this.props;
    if (canDeletePKL) {
      this.state.actions.push({ type: ACTION_TYPE.DELETE, click: item => this.displayDelete(item) });
    }
    let { columns, actions } = { ...this.state };
    const { listFilter, selectedFilter, searchValue, currentPage, totalPage, canCreateShipment } = this.props;
    let { listPklOfShip } = this.props;
    return (
      <div>
        <div className="wrap-search-position top-5">
          <SearchBar
            name="Shipping"
            fields={listFilter}
            selectedFilter={selectedFilter}
            searchValue={searchValue || {}}
            onChangeFilter={this.onChangeFilter}
            onChangeValue={this.onChangeValue}
            onSearch={this.onSearch}
          />
        </div>
        <TableList
          items={listPklOfShip || []}
          onCheck={(item, checked) => this.onCheck(item, checked)}
          createInvoice={item => this.displayCreateINV(item)}
          edit={item => this.displayEdit(item)}
          delete={item => this.displayDelete(item)}
          exportFirst={item => this.exportFirst(item)}
          exportSecond={item => this.exportSecond(item)}
          roles={roles}
        />
        <CustomPagination items={totalPage} activePage={currentPage} onSelect={this.selectPage} />
        <div className="center mg-top-15 clearBoth">
          <button
            className={canCreateShipment ? 'cursor-text bg-gray-bold text-uppercase' : 'bt-orange text-uppercase'}
            disabled={canCreateShipment}
            onClick={() => this.viewCreateShipmentScreen()}>
            {/* <Link to={SHIP_OUT.PKL_SHIP.CREATE}> */}
            {I18n.t(`PageShiping.CreateShipment`)}
            {/* </Link> */}
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  listFilter: selector.getListFilters(),
  selectedFilter: selector.getSelectedFilter(state),
  searchValue: selector.getSearchValue(state),
  listPklOfShip: selector.getDisplayList(state),
  currentPage: selector.getCurrentPage(state),
  totalPage: selector.getTotalPages(state),
  canChecked: selector.canChecked(state),
  checkedList: selector.getCheckedListIds(state),
  canCreateShipment: selector.canCreateShipment(state),
  searched: selector.getSearched(state),
  getSearchQuery: selector.getSearchQuery,
  canDeletePKL: selector.canDeletePKL(state),
});

const mapDispatchToProps = dispatch => ({
  getListPklShip(options) {
    dispatch(getListPklShip(options));
  },
  changeSelectedFilter(value) {
    dispatch(changeSelectedFilter(value));
  },
  changeSearchValue(value, pos) {
    dispatch(changeSearchValue(value, pos));
  },
  changeCheckedList(item, value) {
    dispatch(changeCheckedList(item, value));
  },
  getItemDetailToCreateINV(id, isEdit) {
    dispatch(getItemDetailToCreateINV(id, isEdit));
  },
  search(selectedFilter, searchValue) {
    dispatch(search(selectedFilter, searchValue));
  },
  getPklShipDetail(id) {
    dispatch(getPklShipDetail(id));
  },
  changePklShipListToCreateShipment(data) {
    dispatch(changePklShipList(data));
  },
  changeSearchStatus(value) {
    dispatch(changeSearchStatus(value));
  },
  activeScreenEditInv() {
    dispatch(activeEditInvScreen());
  },
  activeScreenCreateInv() {
    dispatch(activeScreenCreateInv());
  },
  activeScreenCreateShipment() {
    dispatch(activeScreenCreateShipment());
  },
  activeScreenEditPklShip() {
    dispatch(activeScreenEditPklShip());
  },
  deleteShipment(id, option) {
    dispatch(deletePklShip(id, option));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PKLShipContainer));
