import {
  getListPackingStandardDAL,
  deleteItemDAL,
  addItemDAL,
  updateItemDAL,
  uploadFile
} from "../api/packingStandardApi";

import Base from '../api/base';
import Urls from '../constants/apiUrls';
import * as R from 'ramda';
import _ from "lodash";

const BASE_URL = `${Urls.baseUrl}/erp/api/packing-standard`;

class packingStandardServices {
  constructor() {
    this.api = new Base();
  }

  executeApi(url, method, body) {
    return this.api.execute(url, method, body).then(res => {
      if (R.path(['error'], res)) throw R.path(['error'], res);
      return R.path(['data'], res);
    });
  }

  retrieve(id) {
    const url = `${BASE_URL}/${id}`;
    const query = {
      populate: JSON.stringify([{path: 'trims.trim', select: 'name'}]),
    }
    return this.executeApi(url, this.api.ApiMethod.get, query);
  }

  getListPackingStandard(options = {}) {
    options.populate = options.populate ? `${options.populate}` : "";
    options.sort = options.sort || { createdAt: -1 };
    _.set(options, "sort", JSON.stringify(options.sort));

    return getListPackingStandardDAL(options);
  }

  deleteItem(id) {
    return deleteItemDAL(id);
  }

  updateItem(id, data) {
    let types = ["foldingWay", "hangtag", "shippingMark"];
    let promises = [];
    _.each(types, type => {
      _.each(data[type], el => {
        if (el.file) {
          promises.push(uploadFile(el.file.file));
        }
      });
    });

    return Promise.all(promises)
      .then(images => {
        let count = 0;
        images = _.map(images, image =>
          _.get(image || {}, "data.filePath", "")
        );
        _.each(types, type => {
          _.each(data[type], el => {
            if (el.file) {
              el.photo = images[count];
              count++;
              delete el.file;
            }
          });
        });
        return updateItemDAL(id, data);
      })
      .catch(error => {
        throw error;
      });
  }

  addItem(data) {
    let types = ["foldingWay", "hangtag", "shippingMark"];
    let promises = [];
    _.each(types, type => {
      _.each(data[type], el => {
        if (el.file) {
          promises.push(uploadFile(el.file.file));
        }
        // else {
        //   promises.push(Promise.resolve(el.photo))
        // }
      });
    });

    return Promise.all(promises)
      .then(images => {
        let count = 0;
        images = _.map(images, image =>
          _.get(image || {}, "data.filePath", "")
        );
        _.each(types, type => {
          _.each(data[type], el => {
            if (el.file) {
              el.photo = images[count];
              count++;
              delete el.file;
            }
          });
        });
        return addItemDAL(data);
      })
      .catch(error => {
        throw error;
      });
  }
}

export default new packingStandardServices();
