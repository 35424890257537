import Base from './base';

class Store extends Base {
  constructor () {
    super();

    this.getListStoreDAL = this.getListStoreDAL.bind(this);
    this.addStoreDAL = this.addStoreDAL.bind(this);
    this.editStoreDAL = this.editStoreDAL.bind(this);
    this.getStoreDAL = this.getStoreDAL.bind(this);
    this.deleteStoreDAL = this.deleteStoreDAL.bind(this);
  }

  getListStoreDAL (query) {
    return super.execute(this.Urls.store.list, this.ApiMethod.get, query);
  }

  addStoreDAL (body) {

    return super.execute(this.Urls.store.create, this.ApiMethod.post, body);
  }

  editStoreDAL (id, body) {

    return super.execute(this.Urls.store.update + '/' + id, this.ApiMethod.put, body);
  }

  getStoreDAL (id, options) {
    return super.execute(this.Urls.store.detail + '/' + id, this.ApiMethod.get, options);
  }

  deleteStoreDAL (id) {
    return super.execute(this.Urls.store.delete + '/' + id, this.ApiMethod.delete, null);
  }
}

export default new Store();
