import React from 'react';
import PropTypes from "prop-types";
import * as R from 'ramda';
import moment from 'moment';
import FormGroup from '../../forms/FormGroup';
import Input from '../../forms/Input';
import SRC from '../../../../constants/assets';
const formatCurrency = require('format-currency');
const DateTimeField = require('react-datetime');

const EditInvoiceIEDeclaration = props => {
    const { disableChangeValue, dataApi, company = '', invoiceDate, paymentType, amount, paid, remaining, emptyReceipt, receiptFromInvoice } = props;
    return (
        <div>
            <ul className="content-header-title float-none pd-0-5 justify-content-space-between display-flex">
                <li>
                    <p>Name
                        <span className="color-orange medium mg-left-25 size-16">{company}</span>
                    </p>
                </li>
                <li className="add-filter-top">
                    <span className="cursor-pointer" onClick={() => props.save(paymentType, dataApi)}>
                        <i className="fa fa-floppy-o" aria-hidden="true"></i>{"SAVE"}
                    </span>
                </li>
            </ul>
            <div className="wrap-input-global pd-15-0 margin-small bg-white page-edit-invoice-list box-shadow">
                <div className="pd-0 col-xs-9 left">
                    <div className="col-md-6 col-xs-12 input">
                        <FormGroup
                            label="Name"
                            type="input"
                            inputComponent={
                                <Input
                                    type="text"
                                    value={props.name}
                                    onChange={e =>
                                        props.changeName(e.target.value)
                                    }
                                    placeholder=""
                                />
                            }
                        />
                    </div>
                    <div className="col-md-6 col-xs-12 input">
                        <FormGroup
                            type="input"
                            label="Invoice Date"
                            inputComponent={
                                <DateTimeField
                                    timeFormat={false} closeOnSelect dateFormat="MMM DD, YYYY"
                                    value={moment(invoiceDate)}
                                    onChange={date =>
                                        props.changeInvoiceDate(date.format())
                                    }
                                />
                            }
                        />
                    </div>
                    <div className="col-md-6 col-xs-12 input">
                        <FormGroup
                            type="input"
                            label="Invoice No."
                            inputComponent={
                                <Input
                                    type="text"
                                    value={props.invoiceNo}
                                    onChange={e =>
                                        props.changeInvoiceNo(e.target.value)
                                    }
                                    placeholder=""
                                />
                            }
                        />
                    </div>
                    <div className="col-md-6 col-xs-12 input">
                        <FormGroup
                            type="input"
                            label="Pay Amount"
                            inputComponent={
                                <Input
                                    type="number"
                                    min="0"
                                    value={props.payAmount}
                                    onChange={e =>
                                        props.changePayAmount(_.toInteger(e.target.value), remaining, paid)
                                    }
                                    placeholder=""
                                />
                            }
                        />
                    </div>
                    <div className="col-md-6 col-xs-12 input import-export-us-delivery-note">
                        <FormGroup
                            type="textarea"
                            label="Note"
                            inputComponent={
                                <textarea
                                    value={props.note}
                                    type="text"
                                    maxLength={200}
                                    onChange={e =>
                                        props.changeNote((e.target.value))
                                    }
                                />
                            }
                        />
                    </div>
                    <div className="col-md-6 col-xs-12 input">
                        <FormGroup
                            type="input"
                            label="Due Date"
                            inputComponent={
                                <DateTimeField
                                    timeFormat={false} closeOnSelect dateFormat="MMM DD, YYYY"
                                    value={moment(props.dueDate)}
                                    onChange={date =>
                                        props.changeDueDate(date.format())
                                    }
                                />
                            }
                        />
                    </div>
                </div>
                <div className="pd-0 col-xs-3 right">
                    <ul className="type-supplier">
                        <li onClick={() => props.changePaymentType('cash')} className={paymentType === "cash" ? "active" : "cursor-pointer"}>
                            <span className="cursor-poniter">
                                <span className="icon">
                                    <span />
                                </span>
                                <span className="text">Cash</span>
                            </span>
                        </li>

                        <li onClick={() => props.changePaymentType('transfer')} className={paymentType === "transfer" ? "active" : "cursor-pointer"}>
                            <span
                                className="cursor-poniter"
                            >
                                <span className="icon">
                                    <span />
                                </span>
                                <span className="text">Transfer</span>
                            </span>
                        </li>
                    </ul>

                    {paymentType === "transfer" &&
                        <span>
                            <div className="mg-right-15">
                                <span className="color-link text-decoration cursor-pointer"
                                    onClick={() => props.openTransferModal()}
                                >
                                    Upload File
                            </span>
                            </div>
                            <p className="text-align-left mg-bottom-15">
                                Files: {(dataApi.files || []).length}
                            </p>
                        </span>
                    }
                    {paymentType === "cash" &&
                        <span>
                            {emptyReceipt && <div className="mg-right-15">
                                <span className="color-link text-decoration cursor-pointer"
                                    onClick={() => props.openReceiptModal()}
                                >
                                    Add Receipt
                                </span>
                            </div>}
                            {!emptyReceipt && <p className="text-align-left mg-bottom-15">
                                <span href="" className="color-link text-decoration size-12 cursor-pointer"
                                    onClick={() => props.openReceiptModal()}
                                >{receiptFromInvoice.no}</span>
                            </p>}
                        </span>
                    }

                    <div className="text-align-left mg-bottom-5">
                        <p>
                            <span className="mg-right-15 size-12">Total Amount INV</span>
                            <span className="bold color-number size-14">{'$' + formatCurrency(amount)}</span>
                        </p>
                    </div>

                    <div className="text-align-left mg-bottom-5">
                        <p>
                            <span className="mg-right-15 size-12">Paid</span>
                            <span className="bold size-14">{'$' + formatCurrency(paid)}</span>
                        </p>
                    </div>

                    <div className="text-align-left mg-bottom-5">
                        <p>
                            <span className="mg-right-15 size-12">Remaining</span>
                            <span className="bold size-14 color-green">{'$' + formatCurrency(remaining)}</span>
                        </p>
                    </div>
                </div>
            </div>
            <div className="panel-group mg-0" id="panel-heading-main" role="tablist" aria-multiselectable="true">
                {props.listBillDetail.map((item, index) => {

                    return (
                        <div className="panel panel-default" key={index}>
                            {/* <!-- Component --> */}
                            <div className="panel-heading component" role="tab" id={R.path(['_id'], item)}>
                                <div className="panel-title">
                                    <a
                                        role="button"
                                        onClick={() => props.toggleActivePurchaseOrder(item._id, !item.active)}
                                    >
                                        <i className="fa fa-caret-down" aria-hidden="true" />
                                    </a>
                                    <ul>
                                        <li>
                                            <div className="text">
                                                <span className="left">Bill No</span>
                                                <span className="right size-15"><span className="medium">{R.path(['billNo'], item)}</span></span>
                                            </div>
                                        </li>
                                        <li />
                                    </ul>
                                </div>
                            </div>
                            {/* <!-- End Component --> */}
                            {R.path(['active'], item) &&
                                <div
                                    id={R.path(['_id'], item)}
                                    className="panel-collapse collapse in"
                                    role="tabpanel"
                                    aria-labelledby={R.path(['_id'], item)}
                                >
                                    <div className="panel-body pd-0">
                                        <div className="accounting-us-delivery bg-gray float-left text-left size-12 pd-0-0-5-0 inline-block w-full">
                                            <div className="col-md-3 col-xs-12 input">
                                                <ul>
                                                    <li><span>IFS Filling</span></li>
                                                    <li>
                                                        <input type="number" min="0"
                                                            disabled={disableChangeValue}
                                                            className="form-control w-100"
                                                            value={R.path(['ifsFilling'], item)}
                                                            onChange={e => props.changeBillDetailValue(item._id, "ifsFilling", _.toNumber(e.target.value))}
                                                        />
                                                    </li>
                                                </ul>
                                            </div>

                                            <div className="col-md-3 col-xs-12 input">
                                                <ul>
                                                    <li><span>Custom Entry</span></li>
                                                    <li>
                                                        <input type="number" min="0"
                                                            className="form-control w-100"
                                                            disabled={disableChangeValue}
                                                            value={R.path(['customerEntry'], item)}
                                                            onChange={e => props.changeBillDetailValue(item._id, 'customerEntry', _.toNumber(e.target.value))}
                                                        />
                                                    </li>
                                                </ul>
                                            </div>

                                            <div className="col-md-3 col-xs-12 input">
                                                <ul>
                                                    <li><span>Other Fees</span></li>
                                                    <li>
                                                        <input type="number" min="0"
                                                            className="form-control w-100"
                                                            disabled={disableChangeValue}
                                                            value={R.path(['otherFees'], item)}
                                                            onChange={e => props.changeBillDetailValue(item._id, 'otherFees', _.toNumber(e.target.value))}
                                                        />
                                                    </li>
                                                </ul>
                                            </div>

                                            <div className="col-md-3 col-xs-12 input">
                                                <ul>
                                                    <li>
                                                        <span>Payment Term</span>
                                                    </li>
                                                    <li>
                                                        <input type="number" min="0"
                                                            className="form-control w-100"
                                                            disabled={disableChangeValue}
                                                            value={R.path(['paymentTerm'], item)}
                                                            onChange={e => props.changeBillDetailValue(item._id, 'paymentTerm', _.toNumber(e.target.value))}
                                                        />
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        {(item.containers || []).map((it, idx) => {
                                            return (
                                                <div key={idx} className="box-shadow mg-bottom-5 inline-block w-full">
                                                    <div className="wrap-input-global bg-gray float-left text-left size-12 pd-0-0-5-0 inline-block w-full">
                                                        <div className="col-md-3 col-sm-6 col-xs-12 mg-bottom-10">
                                                            {/* <p><span className="mg-right-15">ETD</span> <span className="bold">{moment(R.path(['etd'], it)).format("MMM DD, YYYY")}</span></p> */}
                                                        </div>
                                                        {/* <div className="col-md-3 col-sm-6 col-xs-12 mg-bottom-10">
                                                            <p><span className="mg-right-15">ETA</span> <span className="bold">{moment(R.path(['eta'], it)).format("MMM DD, YYYY")}</span></p>
                                                        </div>
                                                        <div className="col-md-3 col-sm-6 col-xs-12 mg-bottom-10">
                                                            <p><span className="mg-right-15">Due Date</span> <span className="bold">{moment(R.path(['dueDate'], it)).format("MMM DD, YYYY")}</span></p>
                                                        </div> */}
                                                    </div>
                                                    <ul className="bg-white content-header-title pd-left-15 bd-solid-bottom-3">
                                                        <li style={{width: "20%"}}>
                                                            <p className="bold text-transform">{R.path(['name'], it)}{it.type ? ` - ${R.path(['type'], it)}` : ''}</p>
                                                        </li>
                                                        <li style={{width: "15%"}}>
                                                            <p><span className="mg-right-15 bold">ETD:</span> <span className="bold">{moment(R.path(['etd'], it)).format("MMM DD, YYYY")}</span></p>
                                                        </li>
                                                        <li style={{width: "15%"}}>
                                                            <p><span className="mg-right-15 bold">ETA:</span> <span className="bold">{moment(R.path(['eta'], it)).format("MMM DD, YYYY")}</span></p>
                                                        </li>
                                                        <li>
                                                            <p><span className="mg-right-15 bold">Due Date:</span> <span className="bold">{moment(R.path(['dueDate'], it)).format("MMM DD, YYYY")}</span></p>
                                                        </li>
                                                        <li className="float-right">
                                                            {!disableChangeValue ?
                                                                <span className="cursor-pointer"
                                                                    onClick={() => props.openViewContainerDetail(item._id, idx, it)}>
                                                                    <img src={SRC.IC_EDIT_X} className="mg-right-10" />
                                                                </span>
                                                                : null
                                                            }

                                                        </li>
                                                    </ul>

                                                    <div className="wrap-input-global bg-white float-left text-left size-12 pd-15-0-0-0 inline-block w-full">
                                                        <div className="col-md-3 col-sm-6 col-xs-12 mg-bottom-10">
                                                            <p><span className="mg-right-15">Cost</span> <span className="bold color-green">${R.path(['cost'], it)}</span></p>
                                                        </div>

                                                        <div className="col-md-3 col-sm-6 col-xs-12 mg-bottom-10">
                                                            <p><span className="mg-right-15">Pier Pass</span> <span className="bold color-green">${R.path(['pierPass'], it)}</span></p>
                                                        </div>

                                                        <div className="col-md-3 col-sm-6 col-xs-12 mg-bottom-10">
                                                            <p><span className="mg-right-15">Chassis Fee</span> <span className="bold color-green">${R.path(['chassisFee'], it)}</span></p>
                                                        </div>

                                                        <div className="col-md-3 col-sm-6 col-xs-12 mg-bottom-10">
                                                            <p><span className="mg-right-15">Yard Storage</span> <span className="bold color-green">${R.path(['yardStorage'], it)}</span></p>
                                                        </div>

                                                        <div className="col-md-3 col-sm-6 col-xs-12 mg-bottom-10">
                                                            <p><span className="mg-right-15">Trucking</span> <span className="bold color-green">${R.path(['trucking'], it)}</span></p>
                                                        </div>

                                                        <div className="col-md-3 col-sm-6 col-xs-12 mg-bottom-10">
                                                            <p><span className="mg-right-15">Waiting Time</span> <span className="bold color-green">${R.path(['waitingTime'], it)}</span></p>
                                                        </div>

                                                        <div className="col-md-3 col-sm-6 col-xs-12 mg-bottom-10">
                                                            <p><span className="mg-right-15">Prepull</span> <span className="bold color-green">${R.path(['prepull'], it)}</span></p>
                                                        </div>

                                                        <div className="col-md-3 col-sm-6 col-xs-12 mg-bottom-10">
                                                            <p><span className="mg-right-15">Other Fees</span> <span className="bold color-green">${R.path(['otherFees'], it)}</span></p>
                                                        </div>

                                                        <div className="col-xs-12 mg-bottom-10 bd-dashed-bottom">
                                                            <p className="pd-15-0"><span className="mg-right-15">Note</span> <span className="bold size-14">{R.path(['note'], it)}</span></p>
                                                        </div>
                                                        <div className="pd-5-0">
                                                            <div className="col-md-3 col-sm-6 col-xs-12 mg-bottom-10">
                                                                <p><span className="mg-right-15">Warehouse</span> <span className="bold color-green">{R.path(['warehouse'], it)}</span></p>
                                                            </div>
                                                            <div className="col-md-3 col-sm-6 col-xs-12 mg-bottom-10">
                                                                <p><span className="mg-right-15">Quantiy</span> <span className="bold">{R.path(['qty'], it)}</span></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            }
                        </div>
                    )
                })
                }
            </div>
        </div>
    )
}

EditInvoiceIEDeclaration.propTypes = {
    item: PropTypes.object,
    declarations: PropTypes.object,
    company: PropTypes.string,
    invoiceNo: PropTypes.string,
    issuedDate: PropTypes.string,
    dueDate: PropTypes.string,
    note: PropTypes.string,
    payAmount: PropTypes.number,
    amount: PropTypes.number,
    paid: PropTypes.number,
    remaining: PropTypes.number,
    paymentType: PropTypes.string,
    listBillDetail: PropTypes.array,
    openReceiptModal: PropTypes.func,
    openViewContainerDetail: PropTypes.func,
}

EditInvoiceIEDeclaration.defaultProps = {
    item: {},
    company: '',
    invoiceNo: '',
    issuedDate: '',
    dueDate: '',
    note: '',
    payAmount: 0,
    amount: 0,
    paid: 0,
    remaining: 0,
    paymentType: 'cash',
    listBillDetail: [],
    openReceiptModal: f => f,
    openViewContainerDetail: f => f,
}

export default EditInvoiceIEDeclaration