const C = 'CUSTOMER_PO.CURRENT.CANCEL_ORDER';

export default {
  SHOW_MODAL: `${C}.SHOW_MODAL`,
  HIDE_MODAL: `${C}.HIDE_MODAL`,

  CHANGE_ORDER_DETAIL: `${C}.CHANGE_ORDER_DETAIL`,

  SUBMIT: `${C}.SUBMIT`,
  CANCEL_SUBMIT: `${C}.CANCEL_SUBMIT`,

  RESET_DATA: `${C}.RESET_DATA`,
};
