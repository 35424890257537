import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import organizationActions from '../../actions/organizationActions';
import Add from './modal/OrganizationTabAdd';
import Edit from './modal/OrganizationTabEdit';
import ModalConfirm from '../common/modals/ModalConfirm';
import SearchBar from '../common/layout/SearchBar';
import BaseComponent from '../base/BaseComponent';
import TableList from '../common/datatables/TableList';
import _ from 'lodash';
import I18n from 'i18n-js';
import {SEARCH_BAR_EVENT, SEARCH_KEY, SEARCH_LIST} from "../../constants/constants";
import CustomPagination from '../common/datatables/CustomPagination';

class TabOrganization extends BaseComponent {

  constructor(props, context) {
    super(props, context);
    this.state = {
      pageSize: 10,
      pageNumber: 1,
      columns: [
        { name: I18n.t('PageOrganization.Name'), value: 'name' },
        { name: I18n.t('PageOrganization.Address'), value: 'address' },
        { name: I18n.t('PageOrganization.Phone'), getValue: (i) => {
          let phoneNumber = i.phone == '+' ? '-': i.phone;
          return phoneNumber;
        }},
      ],
      actions: [
        (item) => {
          return { type: 'edit', click: (item) => this.editItem(item) };
        },
        (item) => {
          return { type: 'delete', click: (item) => this.showDeleteConfirm(item) };

        },
      ],
      items: [],
      total: 0,
      searchValue: {},
      search: {key: SEARCH_KEY.NAME, value: ""}
    };

    this.onDeleteOrganization = this.onDeleteOrganization.bind(this);
  }

  componentWillMount() {
    this.getListItem();
  }

  componentWillReceiveProps(newProps) {
    let { items } = this.state;
    !_.isEqual(items, newProps.organizationState.items) && this.setState({
      items: _.cloneDeep(newProps.organizationState.items) || [],
      total: newProps.organizationState.count || 0
    });
  }

  componentWillUpdate(nextProps, nextState) {
    if (_.get(this.props.organizationState, 'reload') != _.get(nextProps.organizationState, 'reload')) {
      this.search(nextState.search, nextState.pageNumber, nextState.pageSize)
    }
    _.get(this.props.organizationState, 'reload') != _.get(nextProps.organizationState, 'reload') && this.props.modal.closeModal();
  }

  getListItem(query = {}) {
    let { pageSize, pageNumber } = this.state;
    let _query = _.assign({ pageSize: pageSize, pageNumber: pageNumber }, query);
    this.props.organizationActions.getListOrganization(_.set(_query, 'sort', { createdAt: -1 }));
  }

  addItem() {
    let { openModal, closeModal } = this.props.modal;
    let modal = (<Add closeModal={closeModal} />);
    openModal(modal);
  }

  editItem(item) {
    let { openModal, closeModal } = this.props.modal;
    let modal = (<Edit closeModal={closeModal} options={{ data: item }} />);
    openModal(modal);
  }

  showDeleteConfirm(item) {
    let { openModal, closeModal } = this.props.modal;
    let modal = (<ModalConfirm
      title={I18n.t('PageOrganization.DELETEORGANIZATION')}
      confirmMessage={I18n.t('PageOrganization.ConfirmDeleteOrganization')}
      messageOk={I18n.t('Btn.YES')}
      messageCancel={I18n.t('Btn.CANCEL')}
      handleClose={closeModal}
      handleOk={this.onDeleteOrganization.bind(null, item)} />);
    openModal(modal);
  }

  onDeleteOrganization(item) {
    item._id && this.props.organizationActions.deleteOrganization(item._id);
  }

  selectPage = (page) => {
    this.setState({ pageNumber: page });
    this.search(this.state.search, page, this.state.pageSize);
  }

  onChangeSearch(property, value) {
    switch(property) {
      case SEARCH_BAR_EVENT.FILTER: {
        this.setState({search: {key: value, value: ""}});
        break;
      }
      case SEARCH_BAR_EVENT.SEARCH: {
        this.setState({search: {...this.state.search, value: value}});
        break;
      }
    }
  }

  onSearch(){
    const {search, pageNumber, pageSize} = this.state;
    this.search(search, pageNumber, pageSize);
  }

  search(search, pageNumber, pageSize) {
    let options = {};
    const populate= '';
    if(search && search.key && search.value) {
      switch(search.key) {
        case SEARCH_KEY.NAME: {
          options = {
            pageNumber,
            pageSize,
            sort: {updatedAt: -1},
            populate: populate,
            query: {name: {$regex: '.*' + search.value + '.*', $options: 'i'}}
          };
          this.props.organizationActions.getListOrganization(options).then(res => {
            if(res && res.success && res.data && res.data.items && !res.data.items.length && pageNumber > 1) {
              options = {
                pageNumber:1,
                pageSize,
                sort: {updatedAt: -1},
                populate: populate,
                query: {name: {$regex: '.*' + search.value + '.*', $options: 'i'}}
              };
              this.setState({pageNumber: 1});
              this.props.organizationActions.getListOrganization(options)
            }
          });
          break;
        }
        case SEARCH_KEY.ADDRESS: {
          options = {
            pageNumber,
            pageSize,
            sort: {updatedAt: -1},
            populate: populate,
            query: {address: {$regex: '.*' + search.value + '.*', $options: 'i'}}
          };
          this.props.organizationActions.getListOrganization(options).then(res => {
            if(res && res.success && res.data && res.data.items && !res.data.items.length && pageNumber > 1) {
              options = {
                pageNumber:1,
                pageSize,
                sort: {updatedAt: -1},
                populate: populate,
                query: {address: {$regex: '.*' + search.value + '.*', $options: 'i'}}
              };
              this.setState({pageNumber: 1});
              this.props.organizationActions.getListOrganization(options)
            }
          });
          break;
        }
        case SEARCH_KEY.PHONE: {
          options = {
            pageNumber,
            pageSize,
            sort: {updatedAt: -1},
            populate: populate,
            query: {phone: {$regex: '.*' + search.value + '.*', $options: 'i'}}
          };
          this.props.organizationActions.getListOrganization(options).then(res => {
            if(res && res.success && res.data && res.data.items && !res.data.items.length && pageNumber > 1) {
              options = {
                pageNumber:1,
                pageSize,
                sort: {updatedAt: -1},
                populate: populate,
                query: {phone: {$regex: '.*' + search.value + '.*', $options: 'i'}}
              };
              this.setState({pageNumber: 1});
              this.props.organizationActions.getListOrganization(options)
            }
          });
          break;
        }
      }
    }
    if(!search.value) {
      let options = {
        pageNumber,
        pageSize,
        sort: {updatedAt: -1},
        populate: populate,
      };
      this.props.organizationActions.getListOrganization(options).then(res => {
        if(res && res.success && res.data && res.data.items && !res.data.items.length && pageNumber > 1) {
          options = {
            pageNumber:1,
            pageSize,
            sort: {updatedAt: -1},
            populate: populate,
          };
          this.setState({pageNumber: 1});
          this.props.organizationActions.getListOrganization(options);
        }
      });
    }
  }

  render() {
    let { items, total, columns, actions, pageNumber, pageSize } = this.state;
    let totalPage = _.ceil(total / pageSize);
    let tabPanelClass = this.props.active ? "tab-pane active" : "tab-pane";
    return (

      <div role="tabpanel" className={tabPanelClass} id="organization-organization">
        <div className="wrap-search-position">
          <SearchBar
            name={I18n.t('PageOrganization.AddNew')}
            addItem={() => this.addItem()}
            fields={SEARCH_LIST.ORGANIZATION_ORGANIZATION}
            onChangeSearch={(type, data) => this.onChangeSearch(type, data)}
            onSearch={() => this.onSearch()}
          />
        </div>
        <TableList {...this.props} items={items || []} columns={columns || []} actions={actions || []}
          options={{ clsTbl: 'global-table box-shadow-none' }} widthAction="w-70" />
        <CustomPagination items={totalPage} activePage={pageNumber} onSelect={this.selectPage} />
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    appState: state.appState,
    authState: state.authState,
    organizationState: state.organizationState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    organizationActions: bindActionCreators(organizationActions, dispatch)
  };
}

TabOrganization.propTypes = {
  authState: PropTypes.object.isRequired,
  active: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(TabOrganization);
