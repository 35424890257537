import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import QuickSearchSelect from '../../components/forms/QuickSearchSelect';
class AccountsPayablePlanImportExportNameSelect extends Component {
    constructor(props) {
        super(props);
    }

    componentWillMount() {

    }

    componentWillReceiveProps(nextProps) {

    }

    handleChange(value) {
        const { items } = this.props;
        const [item] = items.filter(it => it.id === value);
        this.props.onChangeItem(item || null);
    }

    render() {
        const { items = []} = this.props;
        const { value, isValid, isLoading } = this.props;
        // console.log(value)
        return (
            <QuickSearchSelect
                items={items.map(item => ({ value: item.id, label: item.name }))}
                value={value}
                isValid={isValid}
                onChange={id => this.handleChange(id)}
                isLoading={isLoading} 
            />
        );
    }
}

AccountsPayablePlanImportExportNameSelect.propTypes = {
    items: PropTypes.array,
    value: PropTypes.string,
    isValid: PropTypes.bool,
    isLoading: PropTypes.bool,
    onChangeItem: PropTypes.func,
};

AccountsPayablePlanImportExportNameSelect.defaultProps = {
    value: null,
    isValid: null,
    isLoading: false,
    onChangeItem: f => f,
};

const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(AccountsPayablePlanImportExportNameSelect);
