import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import I18n from 'i18n-js';
import BaseComponent from '../../base/BaseComponent';
import trimActions from '../../../actions/trimActions';
import TrimForm from '../../common/modals/ModalTrimForm';
import ModalConfirm from '../../common/modals/ModalConfirm';
import TableList from '../../common/datatables/TableList';
import _ from 'lodash';
import {FABRIC_SEARCH_KEY, SEARCH_BAR_EVENT, SEARCH_LIST} from "../../../constants/constants";
import {toastr} from "react-redux-toastr";
import SearchBar from "../../common/layout/SearchBar";
import trimApi from "../../../api/trimApi";

import icAddIcon from '../../../stylesheets/images/ic-add.svg';
import CustomPagination from '../../common/datatables/CustomPagination';

class TabTrim extends BaseComponent {
  constructor(props, context) {
    super(props, context);
    let { roles: {showEdit, showDelete} } = this.props;
    this.state = {
      pageSize: 10,
      pageNumber: 1,
      columns: [
        { name: I18n.t('PageStandard.Name'), value: 'name', type: 'text', dataType: 'text' },
        { name: I18n.t('PageStandard.Content'), value: 'data.content', type: 'text', dataType: 'text' },
        { name: I18n.t('PageStandard.Unit'), value: 'data.unit', type: 'text', dataType: 'text'},
        { name: I18n.t('PageStandard.Origin'), value: 'data.origin', type: 'text', dataType: 'text'},
        { name: I18n.t('PageStandard.Note'), value: 'note', type: 'text', dataType: 'text'},
      ],
      actions: [
        (item) => {
          return showEdit ? { type: 'edit', click: (item) => this.editItem(item) } : {};
        },
        (item) => {
          return showDelete ? { type: 'delete', click: (item) => this.showDeleteConfirm(item) } : {};
        },

      ],
      trims: []
    };

    this.onDeleteTrim = this.onDeleteTrim.bind(this);
  }

  componentWillMount() {
    this.setState({search: {key: FABRIC_SEARCH_KEY.FABRIC_NAME, value: ""}});
    let options = { pageSize: this.state.pageSize, pageNumber: this.state.pageNumber };
    this.props.trimPropsActions.getListTrim(options);
  }

  componentWillReceiveProps(newProps) {
    let { trims } = this.state;
    !_.isEqual(trims, newProps.trimState.items) && this.setState({
      trims: _.cloneDeep(newProps.trimState.items) || [],
      total: newProps.trimState.count || 0
    });
  }

  componentWillUpdate(nextProps, nextState) {
    if (this.props.trimState.reload != nextProps.trimState.reload && this.props.modal) {
      this.props.modal.closeModal();
      nextProps.trimPropsActions.getListTrim({ pageSize: this.state.pageSize, pageNumber: this.state.pageNumber });

    }

  }

  selectPage = (page) => {
    this.setState({pageNumber: page});
    let options = { pageSize: this.state.pageSize, pageNumber: page };
    this.props.trimPropsActions.getListTrim(options);
  }

  addItem() {
    let { openModal, closeModal } = this.props.modal;
    let modal = (<TrimForm closeModal={closeModal} />);
    openModal(modal);
  }

  editItem(item) {
    let { openModal, closeModal } = this.props.modal;
    let modal = (<TrimForm closeModal={closeModal} propTrimDetail={item} />);
    openModal(modal);
  }

  showDeleteConfirm(item) {
    let { openModal, closeModal } = this.props.modal;
    let modal = (<ModalConfirm
      title={I18n.t('PageStandard.DELETEPROPERTY')}
      confirmMessage={I18n.t('PageStandard.ConfirmDeletePackageStandard')}
      messageOk={I18n.t('Btn.YES')}
      messageCancel={I18n.t('Btn.CANCEL')}
      handleClose={closeModal}
      handleOk={this.onDeleteTrim.bind(null, item)} />);
    openModal(modal);
  }

  onDeleteTrim(item) {
    item._id && this.props.trimPropsActions.deleteTrim(item._id);
  }

    getBaseOption = () => {
        const {pageSize, pageNumber, search} = this.state;

        let objQuery = {};
        if(search && search.key && search.value) {
            let qr = {}
            switch(search.key) {
                case FABRIC_SEARCH_KEY.FABRIC_NAME: {
                    objQuery = {name: {$regex: '.*' + search.value + '.*', $options: 'i'}}
                    break;
                }
                case FABRIC_SEARCH_KEY.CONTENT: {
                    objQuery = {'data.content': {$regex: '.*' + search.value + '.*', $options: 'i'}}
                    break;
                }
                case FABRIC_SEARCH_KEY.UNIT: {
                    objQuery = {'data.unit': {$regex: '.*' + search.value + '.*', $options: 'i'}}
                    break;
                }
                case FABRIC_SEARCH_KEY.ORIGIN: {
                    objQuery = {'data.origin': {$regex: '.*' + search.value + '.*', $options: 'i'}}
                    break;
                }
            }

        }

        objQuery.type = "TRIM";

        let options = {
            query: JSON.stringify(objQuery),
            sort: JSON.stringify({createdAt:-1}),
            pageSize,
            pageNumber,
        };
        return options
    }

    onChangeSearch = (property, value) => {
        switch(property) {
            case SEARCH_BAR_EVENT.FILTER: {
                this.setState({search: {key: value, value: ""}});
                break;
            }
            case SEARCH_BAR_EVENT.SEARCH: {
                this.setState({search: {...this.state.search, value: value}});
                break;
            }
        }
    }

    onSearch = (page = null) => {

        const options = this.getBaseOption()
        options.pageNumber = page != null ? page : options.pageNumber

        // const options = {}
        trimApi.getListTrimDAL(options).then(rs => {
                if(rs.success) {
                    this.setState({
                        trims: _.get(rs, 'data.items', {}),
                        total: _.get(rs, 'data.count', 0)
                    })
                } else toastr.error(I18n.t('Notify.Error'), rs.error)
            },
            err => {
                toastr.error(I18n.t('Notify.Error'), err.error)
            })
    }


    render() {
    let { trims, total, columns, actions, pageNumber, pageSize } = this.state;
    let { roles: {showCreate} } = this.props;
    let totalPage = _.ceil(total / pageSize);
        const fields = SEARCH_LIST.FABRIC_SEARCH_LIST;
        return (
            <div role="tabpanel"  className="tab-pane" id="standard-materials-trim">
                <div className="wrap-search-position">

                </div>
                <SearchBar fields={fields} onChangeSearch={this.onChangeSearch} onSearch={this.onSearch}/>
                {showCreate && <span className="cursor-pointer add-standard-materials-fabric" onClick={() => { this.addItem(); }}>
                     <img src={icAddIcon} /> {I18n.t('PageStandard.AddNew')}
              </span>}
                <div>
                    <TableList {...this.props} items={trims || []} columns={columns || []} actions={actions || []}
                               options={{ clsTbl: 'global-table box-shadow-none' }} widthAction="w-70"/>
                    <CustomPagination items={totalPage} activePage={pageNumber} onSelect={this.selectPage} />

                </div>
            </div>
        );

  }
}

function mapStateToProps(state, ownProps) {
  return {
    ...ownProps,
    authState: state.authState,
    trimState: state.trimState
  };
}

function mapDispatchToProps(dispatch) {
  return {
    trimPropsActions: bindActionCreators(trimActions, dispatch)
  };
}

TabTrim.propTypes = {
  authState: PropTypes.object.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(TabTrim);
