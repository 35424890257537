import React, {Component} from 'react';
import {Store} from '../../../../CssHandleModule/functions';
import BarTitle from '../../../../components/shipping/subViews/barTitle';
import CutTicketContainer from './CutTicketContainer';
import I18n from 'i18n-js';
import { connect } from 'react-redux';
import * as selector from '../../../../../selectors/ship-out/viewPklOfProdDetail';
import {changeStyleActive} from '../../../../../actions/ship-out/viewPklOfProdDetailActions';

class StyleContainer extends Component {

    constructor(props) {
        super(props)
    }

    //Get new items from props
    componentWillReceiveProps(nextProps){
        const {cutTickets} = {...nextProps};
        if(cutTickets !== []){
            this.setState({cutTickets});
        }
    }

    changeStyleActive = (id) => {
        this.props.changeStyleActive(id)
    }

    setcutTicketContainers =  (cutTickets, id)=>{
        return cutTickets.map((cutTicket, index) => {
            return (
                <CutTicketContainer key={index} cutTicket={cutTicket} styleId = {id}/>
            );
        });
    } 

    render(){
        const {style, getActiveStyle} = this.props;
        const cutTicketContainers = style && this.setcutTicketContainers(style.cutTickets, style._id);
        const active = getActiveStyle(style._id);
        return(
            <div>
                <div className="mg-bottom-10">
                    <BarTitle classActive={active ? "rotate" : ""} type='style' name = {style.style.style} id = {style._id} changeStyleActive = {this.changeStyleActive}/>
                    {active == true ? cutTicketContainers : null}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    getActiveStyle: selector.getActiveStyle(state)
})

const mapDispatchToProps = dispatch => ({
    changeStyleActive(id) {
        dispatch(changeStyleActive(id))
    }
})

export default connect(mapStateToProps, mapDispatchToProps) (StyleContainer);