import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link, Switch, Route, Redirect } from 'react-router-dom';


import * as R from 'ramda';
import AccountsPayablePlanImportExportRadioListsView from '../../components/accounting/AccountsPayablePlanImportExportRadioLists';

class AccountsPayablePlanImportExportRadioLists extends Component {
    constructor(props, context) {
        super(props, context);
    }

    render() {
        const {pathname} = this.props;

        // console.log('asdfsdf', this.props)
        return (
            <AccountsPayablePlanImportExportRadioListsView
                pathname={pathname}
            />
        );
    }
}

AccountsPayablePlanImportExportRadioLists.propTypes = {
    
};

AccountsPayablePlanImportExportRadioLists.defaultProps = {

};

const mapStateToProps = state => ({
  
});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AccountsPayablePlanImportExportRadioLists));
