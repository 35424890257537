import React, { Component } from 'react';
import PreviewDeclarationView from '../../../components/accounting/payable/PreviewDeclaration';
import I18n from 'i18n-js';
import { connect } from 'react-redux';
import * as selector from '../../../../selectors/accounting/payable/historyPreviewDeclaration';
import {withRouter} from "react-router";
class PreviewDeclaration extends Component {

    constructor (props) {
        super(props)
        this.state = {
            listIds: this.props.listIds,
            items: [],
            pageNumber: 1,
            pageSize: 10,
            columns: [
                { name: I18n.t('PageAccounting.DeclarationNo'), value: 'declarationNo', type: 'text' },
                { name: I18n.t('PageAccounting.DeclarationDate'), value: 'declarationDate', type: 'text' },
                { name: I18n.t('PageAccounting.BorderGate'), value: 'borderGate', type: 'text' },
                { name: I18n.t('PageAccounting.QTYPKG'), value: 'qtyPKG', type: 'text' },
                { name: I18n.t('PageAccounting.ImportExportFees'), value: 'crsPaperWork', type: 'number' },
                { name: I18n.t('PageAccounting.TransportationFees'), value: 'truckingIn', type: 'number' },
                { name: I18n.t('PageAccounting.Expense'), value: 'expense', type: 'number' },
                { name: I18n.t('PageAccounting.LocalCharge'), value: 'localCharge', type: 'number' },
                { name: I18n.t('PageAccounting.Advance'), value: 'advance', options: { clsTd: 'color-red' }, type: 'number' },
                // { name: I18n.t('PageAccounting.Remaining'), value: 'remaining', options: { clsTd: 'color-green' }, type: 'number' },
               
            ],
            actions: []
        }
    }
    
    componentWillReceiveProps(nextProps) {
        const {activeScreen} = nextProps;
        if(!activeScreen) {
            nextProps.router.history.goBack();
        }
    }

    render() {
        const { columns } = this.state
        // console.log(this.props.invoiceDetail)
        return (
            <PreviewDeclarationView columns = {columns} {...this.props} type = 'historyPreview'/>
        );
    }
}

const mapStateToProps = state => ({
    invoiceDetail: selector.getInvoiceDetail(state),
    listDeclaration: selector.genDataForPreview(state),
    name: selector.getName(state),
    totalAmount: selector.getTotalAmount(state),
    totalAdvance: selector.getTotalAdvance(state),
    // totalRemaining: selector.getTotalRemaining(state),
    activeScreen: selector.getActiveScreenStatus(state),
})

const mapDispatchToProps = (dispatch, ownProps) => ({

})

export default connect(mapStateToProps, mapDispatchToProps) (withRouter(PreviewDeclaration));