import React, {Component} from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BaseComponent from '../../base/BaseComponent';
import purchaseActions from '../../../actions/purchaseActions';
import appActions from '../../../actions/appActions';
import ListAndActions from '../../common/datatables/ListAndActions';
import SearchBar from '../../common/layout/SearchBar';
import PurchaseAdd from '../modal/PurchaseAdd';
import ModalConfirm from '../../common/modals/ModalConfirm';
import * as utils from '../../../functions/utils';
import I18n from 'i18n-js';
import * as PATH from '../../../constants/routeConstants';
import {PURCHASE_LIST_SEARCH_KEY, SEARCH_LIST, SEARCH_BAR_EVENT, PURCHASE_STATUS} from "../../../constants/constants";
import _ from 'lodash';
import {calculateTotal} from "../../../functions/utils";
import formatCurrency from 'format-currency';

import icAddIcon from '../../../stylesheets/images/ic-add.svg';

class ScreenPurchases extends BaseComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {
      pageSize: 10,
      pageNumber: 1,
      search: {key: PURCHASE_LIST_SEARCH_KEY.CUSTOMER_PO, value: ''},
      headers: [
        // {name: I18n.t('PagePurchase.No'), type: 'text', dataType: 'text', value: 'id'},
        {name: I18n.t('PagePurchase.OrderDate'),type: 'date', dataType: 'MMM DD, YYYY', value: 'createdAt', style: 'm-width-105'},
        {
          name: I18n.t('PagePurchase.CustomerPO'),
          type: 'text',
          dataType: 'text',
          value: '{{data}}.style.order.customerPO'
        },
        {name: I18n.t('PagePurchase.Style'), type: 'text', dataType: 'text', value: '{{data}}.style.style'},
        {name: I18n.t('PagePurchase.PurchaseOrderNo'), type: 'text', dataType: 'text', value: 'purchaseNo'},
        {name: I18n.t('PagePurchase.Supplier'), type: 'text', dataType: 'text', value: '{{data}}.supplier.name'},
        {name: I18n.t('PagePurchase.ExpectedReceivedDate'), type: 'date', dataType: 'MMM DD, YYYY', value: 'exReceivedDate'},
        {name: I18n.t('PagePurchase.Amount'), type: 'text', dataType: 'text', value: 'gTotal'},
        {name: I18n.t('PagePurchase.Type'), type: 'text', dataType: 'text', value: 'type'},
        {name: I18n.t('PagePurchase.Status'), type: 'text', dataType: 'text', value: 'status'}
      ],
      selectedOrder: {},
    };
  }

  getBaseOption = () => {
    const {pageSize, pageNumber} = this.state;
    const {isCancel} = this.props

    let options = {
      pageSize,
      pageNumber,
      populate: 'materials.material,styleId|orderId|customer,supplierId',
      sort: {updatedAt: -1},
      query: { status: { $nin: ['Completed']}, isCancel: {$ne: true}}
    };
    isCancel == true && (options.query = { ...options.query, isCancel })
    return options
  }

  componentWillMount = () => {
    let options = this.getBaseOption()
    options.query = JSON.stringify(options.query)
    this.props.purchaseActions.getPurchases(options);
  }

  componentWillReceiveProps = (nextProps) => {
    const {isCancel} = this.props
    const isCancelN = nextProps.isCancel

    if(isCancel != isCancelN) {
      let options = this.getBaseOption()
      this.setState({search: {key: PURCHASE_LIST_SEARCH_KEY.CUSTOMER_PO, value: ''}})
      options.query = { ...options.query, isCancel: isCancelN ? isCancelN : {$ne: true} }
      options.query = JSON.stringify(options.query)
      this.props.purchaseActions.getPurchases(options);
    }

    if(!_.isEqual(_.get(this, 'props.purchaseState.reload'), _.get(nextProps, 'purchaseState.reload'))) {
      let options = this.getBaseOption();
      this.setState({search: {key: PURCHASE_LIST_SEARCH_KEY.CUSTOMER_PO, value: ''},})
      options.query = JSON.stringify(options.query)
      this.props.purchaseActions.getPurchases(options);
    }
  }

  addPurchase = () => {
    let {openModal, closeModal} = this.props.modal;
    let modal = (
      <PurchaseAdd closeModal={closeModal} options={{isAdd: true, data: {}}} stockOrder={this.props.stockOrder}
                   additionalOrder={this.props.additionalOrder}/>);
    openModal(modal);
  }

  showDeleteConfirm = (item) => {
    let {openModal, closeModal} = this.props.modal;
    let modal = (<ModalConfirm
      title={I18n.t('PagePurchase.DELETEPURCHASE')}
      confirmMessage={I18n.t('PagePurchase.ConfirmDeletePurchase')}
      messageOk={I18n.t('Btn.YES')}
      messageCancel={I18n.t('Btn.CANCEL')}
      handleClose={closeModal}
      handleOk={this.onDeletePurchase.bind(null, item)}/>);
    openModal(modal);
  }

  onDeletePurchase = (item) => {
    item._id && this.props.purchaseActions.deletePurchase(item._id);
  }

  onSearch = () => {
    const {search} = this.state;
    this.search(search, this.getBaseOption());
  }

  editOrder = (data) => {
    const {purchaseState} = this.props;
    const purChaseId = _.get(data, "_id") || "";
    let selectedPurChaseOrder = purchaseState.items.find(order => order && order._id === purChaseId);
    let orderDetail = _.get(selectedPurChaseOrder, "style.order") || {};

    const placeOrderData = {
      ...data,
      orderDetail: orderDetail || {},
    };
    this.props.editOrder(placeOrderData);


    let screen = utils.setScreenByPurchaseType(data.type);
    let pageList = [...this.props.appState.pageList];
    pageList.push({screen: screen, data: placeOrderData});

    this.props.appPropsActions.addPage(pageList);
    const pageData = _.assign(_.cloneDeep(this.props.appState.page), {isEdit: true});
    this.props.appPropsActions.setPageCurrent(pageData);
  }

  setPage = (pageNumber) => {
    const {search} = this.state;
    this.setState({pageNumber});

    let options = this.getBaseOption()

    this.search(search, {...options, pageNumber});
  }

  genCurrencyForAmount = (amount, currency) => {
    if(currency == 'VND') {
      return formatCurrency(amount) + ' VND';
    }
    if(currency == 'USD') {
      return '$' + formatCurrency(amount);
    }
    return formatCurrency(amount)
  }

  setData(data = []) {
    data = data.map( item => ({...item, gTotal: isNaN(item.gTotal) ? 0 : this.genCurrencyForAmount(item.gTotal, (item.supplier || {}).currency) }))
    data = data.map(item => {
      if (item.materials && item.materials.length > 0) {
        let temp = item.materials.sort((a, b) => new Date(a.exReceivedDate) - new Date(b.exReceivedDate));
        // let gTotal = calculateTotal(item);
        const canceled = item.isCancel == true ? 'Canceled' : ''
        item = {...item, exReceivedDate: temp[0].exReceivedDate, canceled};
      }
      return item
    });
    //data = utils.filterData(data, search);
    return data;
  }

  onChangeSearch = (property, value) => {
    switch(property) {
      case SEARCH_BAR_EVENT.FILTER: {
        this.setState({search: {key: value, value: ""}});
        break;
      }
      case SEARCH_BAR_EVENT.SEARCH: {
        this.setState({search: {...this.state.search, value: value}});
        break;
      }
    }
  }

  search = (search, options) => {

    if(search && search.key && search.value) {
        let qr = {}
        switch(search.key) {
            case PURCHASE_LIST_SEARCH_KEY.PURCHASE_ORDER_NO: {
                qr = {purchaseNo: {$regex: '.*' + search.value + '.*', $options: 'i'}}
                options.query = {...options.query, ...qr}
                break;
            }
            case PURCHASE_LIST_SEARCH_KEY.TYPE: {
                qr = {type: {$regex: '.*' + search.value + '.*', $options: 'i'}}
                options.query = {...options.query, ...qr}
                break;
            }
            case PURCHASE_LIST_SEARCH_KEY.STATUS: {
                qr = {status: {...options.query.status, $regex: '.*' + search.value + '.*', $options: 'i'}}
                options.query = {...options.query, ...qr}
                break;
            }
        }

    }
    this.props.purchaseActions.search({search, options}).then(res => {
      if(res && res.pageNumber && res.pageNumber == 1) {
        this.setState({pageNumber: 1});
      }
    });
  }

  setSortPage(sort) {

  }

  render = () => {
    const {headers, pageNumber, search} = this.state;
    const {isCancel, roles : {showCreate, showEdit, showDelete, showView}} = this.props;

    let actions = [
      (data, user) => {
        return {
          type: (isCancel || !showEdit) ? 'view' : 'edit',
          func: (data) => (isCancel || !showEdit) ? this.props.router.history.push(`${PATH.PURCHASE_SHOW_URL}/${R.path(['_id'], data)}`) : this.editOrder(data),
          isActive: true,
          toolTip: (isCancel || !showEdit) ? 'View' : 'Edit'
        };
      },
      (data, user) => {
        return showDelete ? {
          type: 'delete',
          func: (data) => {
            this.showDeleteConfirm(data);
          },
          isActive: true,
          toolTip: 'Delete'
        } : {};
      }
    ];

    
    const fields = SEARCH_LIST.PURCHASE_LIST_SEARCH_LIST

    const idxStatus = _.findIndex(headers, h => h.value == "status")
    if(isCancel == true) {
      actions.splice(1,1)
      fields.splice(5,1)
      idxStatus >= 0 && (
        headers.splice(headers.length - 1, 1)
      )
    } else{
      idxStatus < 0 && (
        headers.push({name: I18n.t('PagePurchase.Status'), type: 'text', dataType: 'text', value: 'status'})
      )
    }

    let data = this.props.purchaseState && this.props.purchaseState.items ? this.props.purchaseState.items : [];
    data = this.setData(data || [], search || {});
    let statusNotViewEdit = !isCancel ? [] : null;
    let statusNotViewDelete = !isCancel ? [PURCHASE_STATUS.IN_TRANSIT, PURCHASE_STATUS.IN_PORT, PURCHASE_STATUS.WAITING_FOR_SHIPPING, PURCHASE_STATUS.IN_WAREHOUSE] : null;
    // console.log(fields)
    return (
      <div id="content">
        <div>
          {(isCancel != true && showCreate) && (
            <div className="wrap-stock-in">
              <span className="cursor-pointer" onClick={() => this.addPurchase()} ><img src={icAddIcon}/> {I18n.t('PagePurchase.ADDPURCHASEORDER')}</span>
            </div>
          )}
          <SearchBar 
            fields={fields} 
            searchText = {search.value}
            onChangeSearch={(property, value) => this.onChangeSearch(property, value)} 
            onSearch={() => this.onSearch()}
          />
        </div>
        <ListAndActions {...this.props} options={{
          statusNotViewEdit : statusNotViewEdit,
          statusNotViewDelete : statusNotViewDelete,
          textTotal: 'purchases',
          setPage: this.setPage,
          totalPage: this.props.purchaseState.count,
          pageNumber: pageNumber,
          perPage: 10,
          headers,
          actions,
          data,
          setSortPage: this.setSortPage,
          statusList: this.state.statusList,
          className: 'center',
        }}/>
      </div>
    )
  }

}

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    authState: state.authState,
    appState: state.appState,
    purchaseState: state.purchaseState,
    orderState: state.orderState,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    purchaseActions: bindActionCreators(purchaseActions, dispatch),
    appPropsActions: bindActionCreators(appActions, dispatch),
  };
}

ScreenPurchases.propTypes = {
  authState: PropTypes.object.isRequired,
  appState: PropTypes.object,
  orderState: PropTypes.object,
  purchaseState: PropTypes.object,
  purchaseActions: PropTypes.object,
  appPropsActions: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ScreenPurchases));
