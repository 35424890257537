import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import I18n from 'i18n-js';

import BaseComponent from '../../base/BaseComponent';
import * as packingActions from '../../../actions/production/packing';

class Missing extends BaseComponent {
  constructor (props, context) {
    super(props, context);
  }

  stock = () => {
    let { closeModal, packing, stockPacking} = this.props;
    stockPacking(packing);
    closeModal();
  }

  reship = () => {
    let { closeModal, packing } = this.props;
    closeModal();
  }

  render = () => {
    let { closeModal } = this.props;
    return (
        <div className="modal-dialog modal-sm" role="document">
          <div className="modal-content">
            <form>
              <div className="modal-header">
                <a className="close" onClick={closeModal}><span aria-hidden="true">&times;</span></a>
                <h4 className="modal-title" id="myModalLabel">{I18n.t('PageProduction.Remaining')}</h4>
              </div>

              <div className="modal-body">
                <br/>
                <div style={{margin:'10px', textAlign: 'center'}}>What do you want to resolve this remaining?</div>
                <br/>
              </div>

              <div className="modal-footer">
                <button type="button" className="bt-black" onClick={this.stock}>{I18n.t('Btn.STOCK')}</button>
                <button type="button" className="bt-orange mg-l-5" onClick={this.reship}>{I18n.t('Btn.RESHIP')}</button>
              </div>
            </form>
          </div>
        </div>
      )
  }
}

const mapStateToProps = (state) => {
  return {
    appState: state.appState,
    authState: state.authState
  };
}

const mapDispatchToProps = dispatch => {
  return {
    packingActions: bindActionCreators(packingActions, dispatch),
  };
}

Missing.propTypes = {
};

export default connect(mapStateToProps, mapDispatchToProps)(Missing);
