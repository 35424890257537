
const C = "EDIT_PACKING_STANDARD";

export default {
  FETCH_PACKING_STANDARD: `${C}.FETCH_PACKING_STANDARD`,
  CANCEL_FETCH_PACKING_STANDARD: `${C}.CANCEL_FETCH_PACKING_STANDARD`,
  CHANGE_PACKING_STANDARD: `${C}.CHANGE_PACKING_STANDARD`,

  FETCH_SELECTABLE_MATERIALS: `${C}.FETCH_SELECTABLE_MATERIALS`,
  CANCEL_FETCH_SELECTABLE_MATERIALS: `${C}.FETCH_SELECTABLE_MATERIALS`,
  CHANGE_SELECTABLE_MATERIALS: `${C}.CHANGE_SELECTABLE_MATERIALS`,

  CHANGE_PACKING_NAME: `${C}.CHANGE_PACKING_NAME`,
  CHANGE_PACKING_TYPE: `${C}.CHANGE_PACKING_TYPE`,
  CHANGE_PACKING_DESCRIPTION: `${C}.CHANGE_PACKING_DESCRIPTION`,

  ADD_TRIM: `${C}.ADD_TRIM`,
  REMOVE_TRIM: `${C}.REMOVE_TRIM`,
  CHANGE_TRIM_NAME: `${C}.CHANGE_TRIM_NAME`,
  CHANGE_TRIM_COLOR: `${C}.CHANGE_TRIM_COLOR`,
  CHANGE_TRIM_SIZE: `${C}.CHANGE_TRIM_SIZE`,

  ADD_FOLDING_WAY: `${C}.ADD_FOLDING_WAY`,
  REMOVE_FOLDING_WAY: `${C}.REMOVE_FOLDING_WAY`,
  CHANGE_FOLDING_DESCRIPTION: `${C}.CHANGE_FOLDING_DESCRIPTION`,
  CHANGE_FOLDING_PHOTO: `${C}.CHANGE_FOLDING_PHOTO`,

  ADD_HANDTAG: `${C}.ADD_HANDTAG`,
  REMOVE_HANDTAG: `${C}.REMOVE_HANDTAG`,
  CHANGE_HANDTAG_DESCRIPTION: `${C}.CHANGE_HANDTAG_DESCRIPTION`,
  CHANGE_HANDTAG_PHOTO: `${C}.CHANGE_HANDTAG_PHOTO`,

  ADD_SHIPPING_MARK: `${C}.ADD_SHIPPING_MARK`,
  REMOVE_SHIPPING_MARK: `${C}.REMOVE_SHIPPING_MARK`,
  CHANGE_SHIPPING_MARK_DESCRIPTION: `${C}.CHANGE_SHIPPING_MARK_DESCRIPTION`,
  CHANGE_SHIPPING_MARK_PHOTO: `${C}.CHANGE_SHIPPING_MARK_PHOTO`,

  SUBMIT: `${C}.SUBMIT`,
  CANCEL_SUBMIT: `${C}.CANCEL_SUBMIT`,

  RESET_DATA: `${C}.RESET_DATA`,
}
