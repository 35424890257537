import { combineReducers } from 'redux';
import moment from 'moment';
import C from '../../../constants/accounting/receivable/createInvoiceNormal';
/**
 * Store status of feching selectable customers from api
 */

const errorsMessage = (state = {}, action) => {
  const { payload, type } = action;
  switch (type) {
    case C.CHANGE_ERROR_MESSAGE:
      return payload;
    case C.RESET_DATA:
      return {};
    default:
      return state;
  }
};

const fetchingSelectableCustomers = (state = false, action) => {
  const { payload, type } = action;
  switch (type) {
    // TODO. handle related actions
    default:
      return state;
  }
};

/**
 * Store a list of selectable customers
 */
const selectableCustomers = (state = [], action) => {
  const { payload, type } = action;
  switch (type) {
    case C.CHANGE_LIST_CUSTOMER:
      return payload;
    // TODO. handle related actions
    default:
      return state;
  }
};

/**
 * Store selected customer
 */
const selectedCustomer = (state = null, action) => {
  const { payload, type } = action;
  switch (type) {
    case C.CHANGE_SELECTED_CUSTOMER:
      return payload;
    // TODO. handle related actions
    default:
      return state;
  }
};

/**
 * Store status for checking vat per customer field
 */
const vatPerCustomer = (state = false, action) => {
  const { payload, type } = action;
  switch (type) {
    case C.CHANGE_VAT_PER_CUSTOMER:
      return payload;
    // TODO. handle related actions
    default:
      return state;
  }
};

/**
 * Store status for invoice no
 */
const invoiceNo = (state = null, action) => {
  const { payload, type } = action;
  switch (type) {
    case C.CHANGE_INVOICE_NO:
      return payload;
    // TODO. handle related actions
    default:
      return state;
  }
};

/**
 * Store status for invoice no
 */
const creditForSeaFreight = (state = null, action) => {
  const { payload, type } = action;
  switch (type) {
    case C.CHANGE_CREADIT_FOR_SEA_FREIGHT:
      return payload;
    // TODO. handle related actions
    default:
      return state;
  }
};

/**
 * Store list of selectable credit debits
 */
const selectableCreditDebits = (state = [], action) => {
  const { payload, type } = action;
  switch (type) {
    case C.CHANGE_LIST_CREDIT_DEBIT_NOTE:
      return payload;
    // TODO. handle related actions
    default:
      return state;
  }
};

/**
 * Store list of selected credit debit
 */
const selectedCreditDebit = (state = null, action) => {
  const { payload, type } = action;

  switch (type) {
    case C.CHANGE_SELECTED_CREDIT_DEBIT:
      return payload;
    // TODO. handle related actions
    default:
      return state;
  }
};

/**
 * Store selectable price types
 * TODO move hard coded state to configurable constants
 */
const selectablePriceTypes = (state = ['LDP', 'DDP', 'FOB', 'CMT'], action) => {
  const { type } = action;
  switch (type) {
    default:
      return state;
  }
};

/**
 * Store selected price type
 */
const selectedPriceType = (state = null, action) => {
  const { payload, type } = action;
  switch (type) {
    case C.CHANGE_SELECTED_PRICE:
      return payload;
    // TODO. handle related actions
    default:
      return state;
  }
};

/**
 * Store issued date, default is now
 */
const issuedDate = (state = moment(), action) => {
  const { payload, type } = action;
  switch (type) {
    // TODO. handle related actions
    case C.CHANGE_ISSUED_DATE:
      return payload;
    default:
      return state;
  }
};

/**
 * Store due date
 */
const dueDate = (state = moment(), action) => {
  const { payload, type } = action;
  switch (type) {
    case C.CHANGE_DUE_DATE:
      return payload;
    default:
      return state;
  }
};

/**
 * Store a list of selectable shipping modes
 * TODO Move hard coded state to configurable constants
 */
const selectableShippingModes = (state = ['Sea', 'Air'], action) => {
  const { type } = action;
  switch (type) {
    default:
      return state;
  }
};

/**
 * Store selected shipping mode
 */
const selectedShippingMode = (state = null, action) => {
  const { payload, type } = action;
  switch (type) {
    case C.CHANGE_SHIP_MODE:
      return payload;
    // TODO. handle related actions
    default:
      return state;
  }
};

/**
 * Store a list of checkable bill
 */
const checkableBillList = (state = [], action) => {
  const { payload, type } = action;
  const copyState = _.cloneDeep(state);
  switch (type) {
    case C.CHANGE_LIST_BILL:
      return payload;
    case C.CHANGE_SELECTED_BILL:
      let i = _.findIndex(copyState, el => el._id == payload.id);
      if (i >= 0) {
        copyState[i].checked = payload.checked;
      }
      return copyState;
    // TODO. handle related actions
    default:
      return state;
  }
};

/**
 * Store a list of checked bill
 */
const checkedBillList = (state = [], action) => {
  const { payload, type } = action;
  let copyState = _.cloneDeep(state);
  let index;
  switch (type) {
    case C.CHANGE_SELECTED_BILL:
      if (payload.checked) {
        copyState.push(payload);
      } else {
        _.remove(copyState, el => el.id == payload.id);
      }
      return copyState;
    case C.MAP_DATA_ON_BILL:
      _.map(copyState, el => {
        let billProp = el.billNo;
        if (payload[billProp]) {
          el.customerPOs = [];
          let keys = Object.keys(payload[billProp]);
          if (keys && keys.length) {
            _.forEach(keys, property => {
              el.customerPOs.push({
                customerPO: property,
                data: payload[billProp][property] || [],
              });
            });
          }
        }
      });
      return copyState;
    case C.CHANGE_UNIT_PRICE:
      _.forEach(copyState, el => {
        if (el.billNo == payload.billNo) {
          _.forEach(el.customerPOs, item => {
            if (item.customerPO == payload.customerPO) {
              let i = payload.index;
              item.data[i].unitPrice = parseFloat(payload.value);
              item.totalAmount = _.reduce(
                item.data,
                (total, elem) => {
                  return total + elem.qty * (elem.unitPrice || 0);
                },
                0
              );
            }
          });
        }
      });
      return copyState;
    case C.CHANGE_AIR_COST:
      index = _.findIndex(copyState, el => el.billNo == payload.billNo);
      if (index >= 0) {
        let i = _.findIndex(copyState[index].customerPOs, item => item.customerPO == payload.customerPO);
        if (i >= 0) copyState[index].customerPOs[i].airCost = payload.value;
      }
      return copyState;
    case C.CHANGE_NOTE_BILL:
      index = _.findIndex(copyState, el => el.billNo == payload.billNo);
      if (index >= 0) {
        let i = _.findIndex(copyState[index].customerPOs, item => item.customerPO == payload.customerPO);
        if (i >= 0) copyState[index].customerPOs[i].note = payload.value;
      }
      return copyState;
    case C.CHANGE_SUB_VAT_NO:
      index = _.findIndex(copyState, el => el.billNo == payload.billNo);
      if (index >= 0) copyState[index].vatNo = payload.value;
      return copyState;
    case C.CHANGE_SUB_VAT_DATE:
      index = _.findIndex(copyState, el => el.billNo == payload.billNo);
      if (index >= 0) copyState[index].vatDate = payload.value;
      return copyState;
    case C.CHANGE_SUB_VAT_TAX_RATE:
      index = _.findIndex(copyState, el => el.billNo == payload.billNo);
      if (index >= 0) copyState[index].taxRate = payload.value;
      return copyState;
    case C.CHANGE_SUB_VAT_TOTAL_AMOUNT:
      index = _.findIndex(copyState, el => el.billNo == payload.billNo);
      if (index >= 0) copyState[index].totalAmount = payload.value;
      return copyState;
    case C.CHANGE_SUB_VAT_NOTE:
      index = _.findIndex(copyState, el => el.billNo == payload.billNo);
      if (index >= 0) copyState[index].vatNote = payload.value;
      return copyState;
    case C.RESET_DATA:
      return [];
    // TODO. handle related actions
    default:
      return state;
  }
};

/**
 * Store a list of checkable cont list
 */
const checkableContList = (state = [], action) => {
  const { payload, type } = action;
  switch (type) {
    // TODO. handle related actions
    default:
      return state;
  }
};

/**
 * Store status of search text
 */
const searchText = (state = [], action) => {
  const { payload, type } = action;
  switch (type) {
    case C.CHANGE_SEARCH_TEXT:
      return payload;
    // TODO. handle related actions
    default:
      return state;
  }
};

/**
 * Store a list of checked cont
 */
const checkedContList = (state = [], action) => {
  const { payload, type } = action;
  switch (type) {
    // TODO. handle related actions
    default:
      return state;
  }
};

/**
 * Store a list of selectable convert currencies
 * TODO move hardcoded state to configurable constanst
 */
const selectableConvertCurrencies = (state = ['USD', 'VND'], action) => {
  const { type } = action;
  switch (type) {
    default:
      return state;
  }
};

/**
 * Store selected convert currency
 */
const selectedConvertCurrency = (state = null, action) => {
  const { payload, type } = action;
  switch (type) {
    case C.CHANGE_CONVERT_TYPE:
      return payload;
    // TODO. handle related actions
    default:
      return state;
  }
};

/**
 * Store convert rate
 */
const convertRate = (state = null, action) => {
  const { payload, type } = action;
  switch (type) {
    case C.CHANGE_CONVERT_RATE:
      return payload;
    // TODO. handle related actions
    default:
      return state;
  }
};

/**
 * Store input information for VAT number field at the main VAT card
 */
const mainVATNo = (state = null, action) => {
  const { payload, type } = action;
  switch (type) {
    case C.CHANGE_MAIN_VAT_NO:
      return payload;
    // TODO. handle related actions
    default:
      return state;
  }
};

/**
 * Store input information for VAT date field at the main VAT card
 */
const mainVATDate = (state = null, action) => {
  const { payload, type } = action;
  switch (type) {
    case C.CHANGE_MAIN_VAT_DATE:
      return payload;
    // TODO. handle related actions
    default:
      return state;
  }
};

/**
 * Store input information for TAX rate field at the main VAT card
 */
const mainVATTaxRate = (state = null, action) => {
  const { payload, type } = action;
  switch (type) {
    case C.CHANGE_MAIN_VAT_TAX_RATE:
      return payload;
    // TODO. handle related actions
    default:
      return state;
  }
};

/**
 * Store input information for Total amount field at the main VAT card
 */
const mainVATTotalAmount = (state = null, action) => {
  const { payload, type } = action;
  switch (type) {
    case C.CHANGE_MAIN_VAT_TOTAL_AMOUNT:
      return payload;
    // TODO. handle related actions
    default:
      return state;
  }
};

/**
 * Store detail input information for each checked bills
 * @param state {[billNo]: {[customerPO]: {airCost, note, items: {[key]: { unitPrice }}}}}
 */
const billInputDetails = (state = {}, action) => {
  const { payload, type } = action;
  switch (type) {
    // TODO. handle related actions
    default:
      return state;
  }
};

/**
 * Store detail input information for each checked conts
 * @param state {[contNo]: {[customerPO]: {airCost, note, items: {[key]: { unitPrice }}}}}
 */
const contInputDetails = (state = {}, action) => {
  const { payload, type } = action;
  switch (type) {
    // TODO. handle related actions
    default:
      return state;
  }
};

const success = (state = false, action) => {
  const { type, payload } = action;
  switch (type) {
    case C.SAVE_SUCCESS:
      return payload;
    case C.RESET_DATA_ALL:
      return false;
    default:
      return state;
  }
};

export const createReceivableInvoiceNormal = combineReducers({
  errorsMessage,
  fetchingSelectableCustomers,
  selectableCustomers,
  selectedCustomer,

  vatPerCustomer,

  selectableCreditDebits,
  selectedCreditDebit,

  selectablePriceTypes,
  selectedPriceType,

  issuedDate,
  dueDate,

  selectableShippingModes,
  selectedShippingMode,

  checkableBillList,
  checkedBillList,
  checkableContList,
  checkedContList,

  selectableConvertCurrencies,
  selectedConvertCurrency,
  convertRate,

  // Main VAT card, only use for domestic type
  mainVATNo,
  mainVATDate,
  mainVATTaxRate,
  mainVATTotalAmount,

  billInputDetails,
  contInputDetails,

  creditForSeaFreight,
  invoiceNo,
  searchText,
  success,
});
