import C from '../../constants/ship-out/showShipOutList'
import shipOutApi from '../../api/shipOutApi';
import moment from 'moment';

export const getList = (options) => dispatch => {
    shipOutApi.getListShipOut(options).then(res => {
        if(res.success) {
            dispatch({type : C.CHANGE_LIST_SHIP_OUT, payload: res.data});
            dispatch({type: C.CHANGE_PAGING_TOTAL, payload: res.meta.pages});
            dispatch({type: C.CHANGE_PAGING_CURRENT, payload: res.meta.page});
        }
    })
}

export const getExportShipOut = (options) => {
    return shipOutApi.getExportShipOut(options);
}

export const changeSelectedFilter = (value) => dispatch => {
    dispatch({type: C.CHANGE_SELECTED_FILTER, payload: value})
}

export const changeSearchValue = (value, pos) => dispatch => {
    dispatch({type: C.CHANGE_SEARCH_VALUE, payload: {value, pos}})
}

export const deleteShipment = (id) => dispatch => {
    shipOutApi.deleteShipment(id).then(res => {
        if(res.success) {
            dispatch({type: C.DELETE_SUCCESS})
        }
    })
}

export const changeSearchStatus = (value) => dispatch => {
    dispatch({type: C.CHANGE_SEARCH_STATUS, payload: value})
}