import { combineReducers } from 'redux'
import C from '../../constants/crm/addSupplier';
import _ from 'lodash';

const supplier = (state = { bank: {}, company: {} }, action) => {
    let supplier = _.cloneDeep(state);
    switch(action.type) {
        case C.CHANGE_SUPPLIER:
            supplier = action.payload;
            return supplier;
        case C.CHANGE_NAME:
            supplier.name = action.payload;
            return supplier;
        case C.CHANGE_SHORT_NAME:
            supplier.shortName = action.payload;
            return supplier;
        case C.CHANGE_BANK_ACCOUNT_NO:
            supplier.bank.accountNo = action.payload;
            return supplier;
        case C.CHANGE_BANK_BRANCH:
            supplier.bank.branch = action.payload;
            return supplier;
        case C.CHANGE_COMPANY_NAME:
            supplier.company.name = action.payload;
            return supplier;
        case C.CHANGE_COMPANY_ADDRESS:
            supplier.company.address = action.payload;
            return supplier;
        case C.CHANGE_TYPE:
            supplier.division = action.payload;
            return supplier;
        case C.CHANGE_DELIVERY_DEVIATION:
            supplier.deliveryDeviation = action.payload;
            return supplier;
        case C.CHANGE_PHONE_NUMBER:
            supplier.phoneNumber = action.payload;
            return supplier;
        case C.CHANGE_PAYMENT_TERM:
            supplier.paymentTerm = action.payload;
            return supplier;
        case C.CHANGE_FAX:
            supplier.fax = action.payload;
            return supplier;
        case C.CHANGE_CURRENCY:
            supplier.currency = action.payload;
            return supplier;
        case C.CHANGE_EMAIL:
            supplier.email = action.payload;
            return supplier;
        case C.CHANGE_EMPTY_DATA:
            return { bank: {}, company: {} };
        default:
            return state;
    }
}

const success = (state = null, action) => {
    switch(action.type) {
        case C.SAVE:
            return action.payload;
        case C.CHANGE_EMPTY_DATA:
            return action.payload;
        default:
            return state;
    }
}

const materials = (state = [], action) => {
    switch(action.type) {
        case C.GET_LIST_MATERIAL:
            return action.payload;
        default:
            return state;
    }
}

const selectedMaterials = (state = [], action) => {
    let list;
    switch(action.type) {
        case C.CHANGE_MATERIAL:
            return action.payload;
        case C.CHANGE_PROPERTY_MATERIAL: {
            let selectedMaterials = _.cloneDeep(state) || [];
            let data = action.payload;
            if(selectedMaterials && selectedMaterials.length) {
                _.each(selectedMaterials, (elem, i) => {
                    if(i == data.index) {
                        elem[data.property] = data.value;
                    }
                }) 
            } else {
                selectedMaterials.push({})
            }
            return selectedMaterials;
        }
        case C.ADD_MATERIAL: {
            list = _.cloneDeep(state) || [];
            list.push(action.payload);
            return list;
        }
        case C.REMOVE_MATERIAL: {
            list = _.cloneDeep(state) || [];
            list.splice(action.payload, 1);
            return list;
        }
        case C.CHANGE_EMPTY_DATA:
            return [];
        default:
            return state;
    }
}

export const addSupplier = combineReducers({
    supplier,
    materials,
    selectedMaterials,
    success

});