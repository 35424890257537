import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import sampleApprovalActions from '../../../actions/sampleApprovalActions';
import {sampleApprovalStatus} from '../../../constants/EnumTypes';
const DateTimeField = require('react-datetime');
import _ from 'lodash';
import moment from 'moment';
import I18n from 'i18n-js';

class MaterialStatusEdit extends Component {
  constructor (props, context) {
    super(props, context);

    this.state = {
      item: {},
      errors: {},
      errorDate: [],
    };
  }

  componentWillMount () {
    const {item} = this.props;
    let data = _.cloneDeep(item);
    this.setState({item: data});
  }

  onChange (path, value, idx) {
    let {item, errorDate} = this.state;
    if(value == "Invalid date") {
      errorDate[idx] = true;
      this.setState({item: _.set(item, path, value), errorDate})
    } else {
      errorDate[idx] = false;
      this.setState({item: _.set(item, path, value), errorDate});
    }
    // console.log(path, ' ' ,value)
    // this.setState({item: _.set(item, path, value)});
  }

  validate () {
    const {type} = this.props;
    let {item, errors} = this.state;

    let data = (item.materials || []).map(i => ({
      [type]: _.cloneDeep(_.get(i, type)) || {},
      material: _.get(i, 'material._id') || i.material
    }));

    data.forEach(i => {
      let status = _.get(i, `${type}.status`);
      !_.isUndefined(status) && !status ? _.set(errors, i.material, `Please select status`) : _.set(errors, i.material, '');
    });
    this.setState({errors});

    let flag = Object.keys(errors).every(k => !errors[k]);

    return flag;
  }

  save () {
    const {type} = this.props;
    let {item} = this.state;

    let data = (item.materials || []).map(i => {
      let field = _.cloneDeep(_.get(i, type)) || {};
      !_.isUndefined(_.get(field, `status`)) && !_.get(field, 'date') && _.set(field, 'date', moment().format());

      return {
        [type]: field,
        material: _.get(i, 'material._id') || i.material
      };
    });

    if (this.validate()) {
      item.styleId && this.props.sampleApprovalPropsActions.editSampleApproval(item.styleId, {materials: data});
    }
  }

  close () {
    this.props.closeModal();
  }

  render () {
    const {type} = this.props;
    let {item, errors, errorDate} = this.state;

    let materials = item.materials || [];

    return (
      <div className="modal-dialog lg" role="document">
        <div className="modal-content">
          <form>
            <div className="modal-header">
              <a className="close" onClick={() => this.close()}><span aria-hidden="true">&times;</span></a>
              <h4 className="modal-title">{I18n.t('PageSampleApproval.MATERIALSAMPLEAPPROVAL')}</h4>
            </div>
            <div className="modal-body">
              {materials.map((i, idx) => (
                <div key={idx} className="form-group">
                  <div className="col-md-4 col-xs-12 text">
                    <p>{_.get(i, 'material.name', '')}</p>
                  </div>
                  <div className="col-md-4 col-xs-12 value">
                    <select className="form-control" value={_.get(i, `${type}.status`) || ''} onChange={evt => this.onChange(`materials[${idx}].${type}.status`, evt.target.value)} >
                      <option value="" />
                      {Object.keys(sampleApprovalStatus).map((k, idx) => {
                        return (<option key={idx} value={k}>{_.capitalize(sampleApprovalStatus[k])}</option>);
                      })}
                    </select>
                    {errors[_.get(i, 'material._id')] && <div className="alert-warning">{errors[_.get(i, 'material._id')]}</div>}
                  </div>
                  <div className="col-md-4 col-xs-12 value">
                    <DateTimeField timeFormat={false} closeOnSelect dateFormat="MMM DD, YYYY"
                      value={moment(_.get(i, `${type}.date`) || {})}
                      onChange={date => this.onChange(`materials[${idx}].${type}.date`, date.format(), idx)} />
                  </div>
                  {errorDate[idx] && <p className="float-right text color-red" style = {{marginRight: '20px'}}>Please enter the date in the format "MMM DD, YYYY". E.g: {moment().format("MMM DD, YYYY")}</p>}
                  {[sampleApprovalStatus.REJECTED, sampleApprovalStatus.RESUBMITTED, sampleApprovalStatus.APPROVED].indexOf(_.get(i, `${type}.status`)) !== -1 && (
                  <div className="col-md-8 col-md-offset-4 col-xs-12 wrap-textarea">
                    <textarea rows="4" cols="50" type="text" className="form-control" value={_.get(i, `${type}.remark`) || ''} onChange={evt => this.onChange(`materials[${idx}].${type}.remark`, evt.target.value)} placeholder="Remark" />
                  </div>
                  )}
                </div>
              ))}

            </div>
            <div className="modal-footer">
              <button type="button" className="bt-black" onClick={() => this.close()} >{I18n.t('Btn.CANCEL')}</button>
              <button type="button" className="bt-orange mg-l-5" onClick={() => this.save()} >{I18n.t('Btn.SAVE')}</button>
            </div>

          </form>
        </div>
      </div>
    );
  }
}

function mapStateToProps (state, ownProps) {
  return {
    authState: state.authState,
    sampleApprovalState: state.sampleApprovalState
  };
}

function mapDispatchToProps (dispatch) {
  return {
    sampleApprovalPropsActions: bindActionCreators(sampleApprovalActions, dispatch)
  };
}

MaterialStatusEdit.propTypes = {
  authState: PropTypes.object.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(MaterialStatusEdit);
