import React, { Component } from 'react';
import { connect } from 'react-redux';
import I18n from 'i18n-js';
import StyleContainer from './nested-containers/StyleContainer';
import StyleContainerPrepack from './nested-containers/StyleContainerPrepack';
import HeaderforSubView from '../../../common/components/HeaderforSubView';
import { SHIP_OUT } from '../../../../constants/routeConstants';
import * as selector from '../../../../selectors/ship-out/viewPklOfProdDetail';
import { changeEmptyPacking } from '../../../../actions/ship-out/viewPklOfProdDetailActions';

import { Store } from '../../../CssHandleModule/functions';
const store = new Store();

store.setStyles({
  orderNumbers: [1],
  aligns: [{ order: 1, margin: { right: '10px' } }],
  fonts: [{ order: 1, color: '#F36F21' }],
});

class customerContainer extends Component {
  constructor(props) {
    super(props);
  }

  componentWillMount() {
    // const {packingDetail, orderInfo} = this.props;
    // !packingDetail && !orderInfo && this.props.router.history.push(SHIP_OUT.URL)
  }

  componentWillUnmount() {
    this.props.changeEmptyPacking();
  }

  componentDidMount() {
    //Get api in here
    const { styles } = { ...this.state };

    this.setState({ styles });
  }

  setStyleContainers = packingDetail => {
    packingDetail;
    const styleComponents =
      packingDetail.styles &&
      packingDetail.styles.map((style, index) => {
        return packingDetail.carton != 'Pre-pack' ? (
          <StyleContainer key={index} style={style} />
        ) : (
          <StyleContainerPrepack key={index} style={style} />
        );
      });

    return styleComponents;
  };

  render() {
    const { packingDetail, getPackingTotal } = this.props;
    const styleContainer = packingDetail && this.setStyleContainers(packingDetail || []);
    const packingTotal = getPackingTotal();
    return (
      <div className="pd-5">
        {/* <HeaderforSubView type='View' {...this.props} turnBackURL={SHIP_OUT.URL}/> */}
        <ul className="content-header-title float-none pd-0-10 justify-content-space-between display-flex">
          <li>
            <p>
              {I18n.t('PageShiping.Customer')}
              <span className="color-orange medium mg-left-25 size-16">
                {_.get(packingDetail, 'customer.name', '')}
              </span>
            </p>
          </li>
          <li>
            <p>
              {I18n.t('PageShiping.CustomerPO')}
              <span className="color-orange medium mg-left-25 size-16">
                {_.get(packingDetail, 'order.customerPO', '')}
              </span>
            </p>
          </li>
          <li>
            <p>
              {I18n.t('PageShiping.TotalNW')}
              <span className="color-number medium mg-left-25 size-16">
                {(packingDetail && packingDetail.totalNW) || 0}
              </span>
            </p>
          </li>
          <li>
            <p>
              {I18n.t('PageShiping.TotalGW')}
              <span className="color-number medium mg-left-25 size-16">
                {(packingDetail && packingDetail.totalGW) || 0}
              </span>
            </p>
          </li>
          <li>
            <p>
              {I18n.t('PageShiping.Remaining')}
              <span className="color-number medium mg-left-25 size-16">{0}</span>
            </p>
          </li>
          <li>
            <p>
              {I18n.t('PageShiping.TotalQTY')}
              <span className="color-number medium mg-left-25 size-16">{packingTotal}</span>
            </p>
          </li>
        </ul>
        {/* <div className="col-xs-12">
            <div className="d-flex justify-content-between ml-3 mr-3" style={{ height: 50, marginTop: 25 }}>
                <div className="d-flex">
                    <p style={store.setStyleType('align_1')}>{I18n.t('PageShiping.Customer')}</p><span style={store.setStyleType('font_1')}>{packingDetail && packingDetail.customer.name || ''}</span>
                </div>
                <div className="d-flex">
                    <p style={store.setStyleType('align_1')}>{I18n.t('PageShiping.CustomerPO')}</p><span style={store.setStyleType('font_1')}>{packingDetail && packingDetail.order.customerPO || ''}</span>
                </div>
                <div className="d-flex">
                    <p style={store.setStyleType('align_1')}>{I18n.t('PageShiping.TotalNW')}</p><span>{packingDetail && packingDetail.totalNW || 0}</span>
                </div>
                <div className="d-flex">
                    <p style={store.setStyleType('align_1')}>{I18n.t('PageShiping.TotalGW')}</p><span>{packingDetail && packingDetail.totalGW || 0}</span>
                </div>
                <div className="d-flex">
                    <p style={store.setStyleType('align_1')}>{I18n.t('PageShiping.Remaining')}</p><span style={{color: '#52B244 '}}>{0}</span>
                </div>
                <div className="d-flex">
                    <p style={store.setStyleType('align_1')}>{I18n.t('PageShiping.TotalQTY')}</p><span style={{color: '#D20020 '}}>{packingTotal}</span>
                </div>
            </div>
        </div> */}
        {styleContainer}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  packingDetail: selector.getPackingDetail(state),
  getPackingTotal: selector.getPackingTotal(state),
});

const mapDispatchToProps = dispatch => ({
  changeEmptyPacking() {
    dispatch(changeEmptyPacking());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(customerContainer);
