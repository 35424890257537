
import * as R from 'ramda';
import C from '../../../constants/standard/packing/editPackingStandard';
import servInstance from '../../../services/packingStandardServices';
import trimServ from '../../../services/trimServices';
import I18n from 'i18n-js';
import { toastr } from 'react-redux-toastr';
import { goBack } from 'react-router-redux';


export const resetData = () => dispatch => {
  dispatch({ type: C.RESET_DATA });
}

export const fetchPackingStandard = id => dispatch => {
  dispatch({ type: C.FETCH_PACKING_STANDARD });
  servInstance.retrieve(id).then(res => {
    dispatch({ type: C.CANCEL_FETCH_PACKING_STANDARD });
    dispatch({ type: C.CHANGE_PACKING_STANDARD, payload: res});
  }).catch(error => {
    dispatch({ type: C.CANCEL_FETCH_PACKING_STANDARD });
    console.error(error);
  });
}

export const fetchSelectableMaterials = () => dispatch => {
  dispatch({ type: C.FETCH_SELECTABLE_MATERIALS });
  trimServ.getListTrim().then(res => {
    dispatch({ type: C.CANCEL_FETCH_SELECTABLE_MATERIALS });
    dispatch({ type: C.CHANGE_SELECTABLE_MATERIALS, payload: R.path(['data', 'items'], res)});
  }).catch(error => {
    dispatch({ type: C.CANCEL_FETCH_SELECTABLE_MATERIALS });
    console.error(error);
  });
}

export const changeName = value => dispatch => {
  dispatch({ type: C.CHANGE_PACKING_NAME, payload: value });
}

export const changeDescription = value => dispatch => {
  dispatch({ type: C.CHANGE_PACKING_DESCRIPTION, payload: value });
}

export const changeType = value => dispatch => {
  dispatch({ type: C.CHANGE_PACKING_TYPE, payload: value });
}

export const changeTrimMaterial = (trimKey, value) => dispatch => {
  dispatch({ type: C.CHANGE_TRIM_NAME, payload: { key: trimKey, value } });
}

export const changeTrimColor = (trimKey, value) => dispatch => {
  dispatch({ type: C.CHANGE_TRIM_COLOR, payload: { key: trimKey, value } });
}

export const changeTrimSize = (trimKey, value) => dispatch => {
  dispatch({ type: C.CHANGE_TRIM_SIZE, payload: { key: trimKey, value }});
}

export const addTrim = () => dispatch => {
  dispatch({ type: C.ADD_TRIM });
}

export const removeTrim = (trimKey) => dispatch => {
  dispatch({ type: C.REMOVE_TRIM, payload: { key: trimKey } });
}

const loadPhotoFromFile = (file, callback) => {
  var FileSize = file.size / 1024 / 1024; // in MB
  if (FileSize > 2) {
    toastr.error('Error', I18n.t('ErrorMessages.FileSizeExceeds2MB'));
    return;
  } 
  const readerBase64 = new FileReader();
  readerBase64.onload = (eventBase64) => {
    const url = eventBase64.target.result;
    const filename = file.name;

    const payload = { file, filename, url, }
    callback(payload);
  };
  readerBase64.readAsDataURL(file);
}

export const changeFoldingWayDescription = (key, value) => dispatch => {
  dispatch({ type: C.CHANGE_FOLDING_DESCRIPTION, payload: {key, value} });
}

export const changeFoldingWayPhoto = (key, file) => dispatch => {
  loadPhotoFromFile(file, value => {
    dispatch({ type: C.CHANGE_FOLDING_PHOTO, payload: { key, value } });
  })
}

export const removeFoldingWayPhoto = (key) => dispatch => {
  dispatch({ type: C.CHANGE_FOLDING_PHOTO, payload: { key, value: null }});
}

export const addFoldingWay = () => dispatch => {
  dispatch({ type: C.ADD_FOLDING_WAY });
}

export const removeFoldingWay = (key) => dispatch => {
  dispatch({ type: C.REMOVE_FOLDING_WAY, payload: { key } });
}

export const changeHangTagDescription = (key, value) => dispatch => {
  dispatch({ type: C.CHANGE_HANDTAG_DESCRIPTION, payload: {key, value} });
}

export const changeHangTagPhoto = (key, file) => dispatch => {
  loadPhotoFromFile(file, value => {
    dispatch({ type: C.CHANGE_HANDTAG_PHOTO, payload: { key, value } });
  })
}

export const removeHangTagPhoto = (key) => dispatch => {
  dispatch({ type: C.CHANGE_HANDTAG_PHOTO, payload: { key, value: null }});
}

export const addHangTag = () => dispatch => {
  dispatch({ type: C.ADD_HANDTAG });
}

export const removeHangTag = (key) => dispatch => {
  dispatch({ type: C.REMOVE_HANDTAG, payload: { key } });
}

export const changeShippingMarkDescription = (key, value) => dispatch => {
  dispatch({ type: C.CHANGE_SHIPPING_MARK_DESCRIPTION, payload: { key, value}});
}

export const changeShippingMarkPhoto = (key, file) => dispatch => {
  loadPhotoFromFile(file, value => {
    dispatch({ type: C.CHANGE_SHIPPING_MARK_PHOTO, payload: { key, value } });
  })
}

export const removeShippingMarkPhoto = (key) => dispatch => {
  dispatch({ type: C.CHANGE_SHIPPING_MARK_PHOTO, payload: { key, value: null }});
}

export const addShippingMark = () => dispatch => {
  dispatch({ type: C.ADD_SHIPPING_MARK });
}

export const removeShippingMark = (key) => dispatch => {
  dispatch({ type: C.REMOVE_SHIPPING_MARK, payload: { key } });
}

export const submit = (id, body) => dispatch => {
  dispatch({ type: C.SUBMIT });
  servInstance.updateItem(id, body).then(res => {
    dispatch({ type: C.CANCEL_SUBMIT });
    toastr.success(I18n.t('Notify.Success'), 'Update Success');
    dispatch(goBack());
  }).catch(error => {
    dispatch({ type: C.CANCEL_SUBMIT });
    console.error(error);
  });
}
