import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BaseComponent from '../../base/BaseComponent';
import moment from 'moment';
import purchaseOrderActions from '../../../actions/purchaseOrderActions';
import appActions from '../../../actions/appActions';
import I18n from 'i18n-js';
import _ from 'lodash';
import {PURCHASE_SCREEN} from "../../../constants/constants";
import numeral from 'numeral';
import { convertCurrency } from "../../../functions/utils";

import placeOrderIcon from '../../../stylesheets/images/place_order.svg';

class PlansPreview extends BaseComponent {
  placeOrder = order => {
    const {style, orderDetail, isAdditionalOrder} = this.props.order;
    const purchase = {
      orderDetail,
      style,
      supplier: order.supplier,
      materials: order.materials,
      type: isAdditionalOrder ? 'Additional' : 'Reserved',
      isAdditionalOrder
    };
    this.props.placeOrder(purchase);
    let pageList = [...this.props.appState.pageList];
    pageList.push({screen: PURCHASE_SCREEN.PLACE_ORDER, data: purchase});
    this.props.appPropsActions.addPage(pageList);
  };

  useStock = order => {
    const {style} = this.props.order;
    const param = {
      styleId: style._id,
      materials: (order.materials || []).map(vl => {
        const rs = _.pick(vl,['replacementPO', 'color', 'size', 'inStock', 'needToBuy', 'quantity'])
        rs.material = _.get(vl, 'material._id', '')
        return rs
      }),
      status: 'New',
      type: 'Use stock'
    }
    this.props.purchaseOrderActions.addItem(param);
  }

  render = () => {
    const {orderDetail, purchasePlan, style} = this.props.order;
    let materialBySupplier = [];
    purchasePlan && purchasePlan.materials && purchasePlan.materials.forEach(material => {
      if (material.supplier) {
        let exist = materialBySupplier.find(m => m.supplier && m.supplier.supplierId === material.supplier);
        if (exist) {
          exist.materials.push(material);
        } else {
          const supplier = material.availableSuppliers.find(m => m.supplierId === material.supplier);
          materialBySupplier.push({supplier: supplier, materials: [material]});
        }
      } else if(material.needToBuy == 0 && material.inStock > 0){
        let exist = materialBySupplier.find(m => m.supplier == null);
        if (exist) {
          exist.materials.push(material);
        } else {
          materialBySupplier.push({supplier: null, materials: [material]});
        }
      }
    });
    return (
      <div id="content">
        <div className="md content-main-header pd-5">
          <ul className="content-main-header-top">
            <li className="content-main-date">
              <span>{moment(orderDetail.createdAt).format('MMM DD')}</span>
              <span className="line"><span/></span>
              <span>{moment(orderDetail.createdAt).format('YYYY')}</span>
            </li>
            <li className="content-main-title bold">
              {orderDetail.customer ? orderDetail.customer.name : ''}
            </li>
            <li className="content-main-po">
              <div className="content-title-po">
                <span className="title">{I18n.t('PagePurchase.CustomerPO')}</span>
                <span className="bold color-black-darken">{orderDetail.customerPO}</span>
              </div>
              <div className="content-title-po">
                <span className="title">{I18n.t('PagePurchase.SaleOrderPO')}</span>
                <span className="bold color-black-darken">{orderDetail.internalPO}</span>
              </div>
            </li>
            <li className="content-main-total">
              <span className="text">{I18n.t('PagePurchase.G-Total')}</span>
              <span className="color-number size-25">{numeral(orderDetail.gTotal).format("0").replace('+', '')}</span>
            </li>
            <li className="content-main-factory">
              <span className="text">{I18n.t('PagePurchase.Ex-Factory')}</span>
              <span className="date">{moment(orderDetail.exFactory).format('MMM DD, YYYY')}</span>
            </li>
            <li className="content-main-factory">
              <span className="text">{I18n.t('PagePurchase.IHDate')}</span>
              <span className="date">{moment(orderDetail.ihDate).format('MMM DD, YYYY')}</span>
            </li>
          </ul>
        </div>
        <ul className="content-header-title">
          <li>
            <p>{I18n.t('PagePurchase.Style')} <span className="color-orange medium mg-left-50 size-16">{style.style}</span></p>
          </li>
        </ul>


        <ul className="wrap-list-table grid">
          {
            materialBySupplier.map((supplier, idx) => {
              let total = supplier.materials.reduce((a,b) => a + (b.price * b.needToBuy || 0), 0)
              // total = total > 0 ? (supplier.supplier.currency == 'USD' ? `$ ${total}` : `${total} ${supplier.supplier.currency}`) : '0'
              return (
              <li key={idx} className="grid-item mg-bottom-5">
                <div className="wrap-item">
                  <div className="box-shadow">
                    <div className="header-place-order">
                      <ul style={{ minWidth: 593 }}>
                        <li>
                          <h3>{_.get(supplier, 'supplier.supplierName', '')}</h3>
                        </li>
                        <li>
                          {total != '0' && (<span style={{cursor:'pointer'}} onClick={() => this.placeOrder(supplier)}><img src={placeOrderIcon}/> {I18n.t('PagePurchase.PLACEORDER')}</span>)}
                          {total == '0' && (<span style={{cursor:'pointer'}} onClick={() => this.useStock(supplier)}><img src={placeOrderIcon}/> {I18n.t('PagePurchase.UseStock')}</span>)}
                        </li>
                      </ul>
                    </div>

                    <div className="wrap-global-table pd-0">
                      <table style={{width:'100%'}} className="global-table">
                        <tbody>
                        <tr className="bg-white bd-dashed-bottom">
                          <th>{I18n.t('PagePurchase.Material')}</th>
                          <th>{I18n.t('PagePurchase.Content')}</th>
                          <th>{I18n.t('PagePurchase.Replacement')}</th>
                          <th className="center">{I18n.t('PagePurchase.Color')}</th>
                          <th className="center">{I18n.t('PagePurchase.Size')}</th>
                          <th className="center">{I18n.t('PagePurchase.Quantity')}</th>
                          <th className="center">{I18n.t('PagePurchase.Convert_Quantity')}</th>
                          <th className="center">{I18n.t('PagePurchase.Amount')}</th>
                        </tr>
                        {
                          supplier.materials.map((material, idx) => {
                            const qtConverted = this.convertUnit(material.needToBuy, material.material)
                            let amountSum = material.price * material.needToBuy
                            // const amount = amountSum > 0 ? (material.currency == 'USD' ? `$ ${amountSum}` : `${amountSum } ${material.currency}`) : 0;
                            let covertQuantity = '-';
                            if(qtConverted != 0)  covertQuantity = `${qtConverted} ${this.getUnit(material, true)}`;
                            return (
                              <tr key={idx}>
                                <td>{material.material.name}</td>
                                <td>{_.get(material, 'material.data.content', '')}</td>
                                <td>{material.replacementPO}</td>
                                <td className="center">{material.color}</td>
                                <td className="center">{material.size}</td>
                                <td className="center">{material.needToBuy} {this.getUnit(material)}</td>
                                <td className="center">{covertQuantity}</td>
                                <td className="number center color-number">{convertCurrency(amountSum, material.currency)}</td>
                              </tr>
                            );
                          })
                        }
                        <tr className="bg-white bd-dashed-top">
                          <td colSpan="2" className="medium">{I18n.t('PagePurchase.G-Total')}</td>
                          <td colSpan="6" className="color-number right size-16">{convertCurrency(total, _.get(supplier, 'supplier.currency', ''))}</td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </li>);
            })
          }
        </ul>


      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    authState: state.authState,
    appState: state.appState,
    purchaseState: state.purchaseState
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    purchaseOrderActions: bindActionCreators(purchaseOrderActions, dispatch),
    appPropsActions: bindActionCreators(appActions, dispatch),
  };
}

PlansPreview.propTypes = {
  authState: PropTypes.object.isRequired,
  activeTab: PropTypes.string,
  placeOrder: PropTypes.func,
  appPropsActions: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(PlansPreview);
