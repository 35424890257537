import C from '../../../constants/accounting/receivable/createInvoiceNormal';
import accountingServices from '../../../services/AccountReceivableService';
import payInfoServices from '../../../services/payInfoServices';
import { getPaymentTermDays } from '../../../selectors/accounting/receivable/createInvoiceNormal';
import _ from 'lodash';
import moment from 'moment';
import { toastr } from 'react-redux-toastr';
import I18n from 'i18n-js';

export const listCustomer = () => dispatch => {
  accountingServices.listCustomers({}).then(
    res => {
      dispatch({ type: C.CHANGE_LIST_CUSTOMER, payload: res.data });
    },
    error => {
      console.log(JSON.stringify(error));
    }
  );
};

export const listCreditDebitNote = customerId => dispatch => {
  payInfoServices.getListPayInfo({ customer: customerId }).then(
    res => {
      dispatch({ type: C.CHANGE_LIST_CREDIT_DEBIT_NOTE, payload: res.data });
    },
    error => {
      console.log(JSON.stringify(error));
    }
  );
};

export const listBill = params => dispatch => {
  accountingServices.listBillsOrContsByCustomer(params.shipMode, params.customer).then(
    res => {
      dispatch({ type: C.CHANGE_LIST_BILL, payload: res.data });
    },
    error => {
      console.log(JSON.stringify(error));
    }
  );
};

export const changeVATPerCustomer = value => dispatch => {
  dispatch({ type: C.CHANGE_VAT_PER_CUSTOMER, payload: value });
};

export const changeSelectedCustomer = data => dispatch => {
  dispatch({ type: C.CHANGE_SELECTED_CUSTOMER, payload: data.customer });
  let currency = _.get(data, 'customer.currency', '');
  if (currency === 'VND') {
    currency = 'USD';
  } else {
    currency = 'VND';
  }
  dispatch({ type: C.CHANGE_CONVERT_TYPE, payload: currency });
  const code = _.get(data, 'customer.paymentTerm', '');
  const days = getPaymentTermDays(code);
  const dueDay = (moment(data.issuedDate, 'DD-MM-YYYY').add(days, 'days') || moment()).format('YYYY-MM-DD');
  dispatch({ type: C.CHANGE_DUE_DATE, payload: dueDay });
  if (data.customer && data.shipMode) {
    dispatch(listBill({ shipMode: data.shipMode.value, customer: data.customer.value }));
  }
};

export const changeSelectedCreditDebit = data => dispatch => {
  dispatch({ type: C.CHANGE_SELECTED_CREDIT_DEBIT, payload: data });
};

export const changeInvoiceNo = value => dispatch => {
  dispatch({ type: C.CHANGE_INVOICE_NO, payload: value });
};

export const changeCreditForSeaFreight = value => dispatch => {
  dispatch({ type: C.CHANGE_CREADIT_FOR_SEA_FREIGHT, payload: value });
};

export const changePriceType = value => dispatch => {
  dispatch({ type: C.CHANGE_SELECTED_PRICE, payload: value });
};

export const changeIssuedDate = value => dispatch => {
  dispatch({ type: C.CHANGE_ISSUED_DATE, payload: value });
};

export const changeDueDate = value => dispatch => {
  dispatch({ type: C.CHANGE_DUE_DATE, payload: value });
};

export const changeShippingMode = data => dispatch => {
  dispatch({ type: C.CHANGE_SHIP_MODE, payload: data.shipMode });
  if (data.customer && data.shipMode) {
    dispatch(listBill({ shipMode: data.shipMode.value, customer: data.customer.value }));
  }
};

export const changeConvertType = value => dispatch => {
  dispatch({ type: C.CHANGE_CONVERT_TYPE, payload: value });
};

export const changeConvertRate = value => dispatch => {
  dispatch({ type: C.CHANGE_CONVERT_RATE, payload: value });
};

export const changeSearchText = value => dispatch => {
  dispatch({ type: C.CHANGE_SEARCH_TEXT, payload: value });
};

export const resetData = () => dispatch => {
  dispatch({ type: C.RESET_DATA });
};

export const changeCheckedBill = data => dispatch => {
  dispatch({ type: C.CHANGE_SELECTED_BILL, payload: { id: data._id, billNo: data.billNo, checked: data.checked } });
  if (data && data.checked) {
    let p = data.dataRequest;
    p.billNo.push(data.billNo);
    if (p.shipMode == 'Sea') {
      p.cont = p.billNo;
    }
    // p._id = _.get(data, '_id', '');
    dispatch(getBillData(p));
  }
};

export const getBillData = params => dispatch => {
  accountingServices.getListShipOutByCustomer(params).then(
    res => {
      // to do something
      dispatch({ type: C.MAP_DATA_ON_BILL, payload: res.data });
    },
    error => {
      console.log(JSON.stringify(error));
    }
  );
};

export const changeUnitPrice = data => dispatch => {
  dispatch({ type: C.CHANGE_UNIT_PRICE, payload: data });
};

export const changeAirCost = data => dispatch => {
  dispatch({ type: C.CHANGE_AIR_COST, payload: data });
};

export const changeNoteBill = data => dispatch => {
  dispatch({ type: C.CHANGE_NOTE_BILL, payload: data });
};

//--------- main VAT ---------//
export const changeMainVATNo = value => dispatch => {
  dispatch({ type: C.CHANGE_MAIN_VAT_NO, payload: value });
};

export const changeMainVATTotalAmount = value => dispatch => {
  dispatch({ type: C.CHANGE_MAIN_VAT_TOTAL_AMOUNT, payload: value });
};

export const changeMainVATDate = value => dispatch => {
  dispatch({ type: C.CHANGE_MAIN_VAT_DATE, payload: value });
};

export const changeMainVATTaxRate = value => dispatch => {
  dispatch({ type: C.CHANGE_MAIN_VAT_TAX_RATE, payload: value });
};

export const changeMainVATNote = value => dispatch => {
  dispatch({ type: C.CHANGE_MAIN_VAT_NOTE, payload: value });
};
//--------- sub VAT ---------//
export const changeSubVATTotalAmount = value => dispatch => {
  dispatch({ type: C.CHANGE_SUB_VAT_TOTAL_AMOUNT, payload: value });
};
export const changeSubVATNo = value => dispatch => {
  dispatch({ type: C.CHANGE_SUB_VAT_NO, payload: value });
};
export const changeSubVATDate = value => dispatch => {
  dispatch({ type: C.CHANGE_SUB_VAT_DATE, payload: value });
};
export const changeSubVATTaxRate = value => dispatch => {
  dispatch({ type: C.CHANGE_SUB_VAT_TAX_RATE, payload: value });
};
export const changeSubVATNote = value => dispatch => {
  dispatch({ type: C.CHANGE_SUB_VAT_NOTE, payload: value });
};

export const changeErrorMessage = value => dispatch => {
  dispatch({ type: C.CHANGE_ERROR_MESSAGE, payload: value });
};

export const saveInvoice = data => dispatch => {
  accountingServices.createInvoice(data).then(res => {
    if (res.success) {
      toastr.success(I18n.t('Notify.Success'), 'Create Success');
      dispatch({ type: C.SAVE_SUCCESS, payload: true });
    } else {
      toastr.error(I18n.t('Notify.Error'), 'Create Fail');
    }
  });
};
