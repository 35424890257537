import {
  getListStyleDAL,
  addStyleDAL,
  editStyleDAL,
  getStyleDAL,
  deleteStyleDAL,
  uploadFile,
  getAllStyleDAL,
  getListStyleAssignmentDAL
} from "../api/styleApi";
import _ from "lodash";

class styleServices {
  constructor() {}

  getAllStyles(options = {}) {
    return getAllStyleDAL(options);
  }

  getAllStylesAssignment(options = {}) {
    return getListStyleAssignmentDAL(options);
  }

  getListStyle(orderId, options = {}) {
    return getListStyleDAL(orderId, options);
  }

  getDetail(id) {
    return getStyleDAL(id, {});
  }

  deleteStyle(id) {
    return deleteStyleDAL(id);
  }

  editStyle(id, data) {
    let promises = (data.images || []).map(i => {
      return i.file
        ? uploadFile(i.file).then(res => _.get(res || {}, "data.filePath", ""))
        : Promise.resolve(i);
    });

    return Promise.all(promises)
      .then(images => {
        data.images = (images || []).filter(i => !!i);

        return editStyleDAL(id, data);
      })
      .catch(error => {
        throw error;
      });
  }

  addStyle(orderId, data) {
    let promises = (data.images || []).map(i => {
      return i.file
        ? uploadFile(i.file).then(res => _.get(res || {}, "data.filePath", ""))
        : Promise.resolve(i);
    });

    return Promise.all(promises)
      .then(images => {
        data.images = (images || []).filter(i => !!i);

        return addStyleDAL(orderId, data);
      })
      .catch(error => {
        throw error;
      });
  }
}

export default new styleServices();
