/**
 * @author hienhc <hienhc@nexlesoft.com>
 * @create date 2018-08-30 11:48:02
*/

const C = "PREVIEW_SELECTED_PKL_PROD_LIST";

export default {
    /**
     * Actions that will affect display items
     */
    FETCH_ITEM_LIST: `${C}_FETCH_ITEM_LIST`,
    CANCEL_FETCH_ITEM_LIST: `${C}_CANCEL_FETCH_ITEM_LIST`,
    CHANGE_ITEM_LIST: `${C}_CHANGE_ITEM_LIST`,

    /**
     * Actions that will affect filter
     */
    CHANGE_SELECTED_FILTER: `${C}_CHANGE_SELECTED_FILTER`,
    CHANGE_SEARCH_TEXT: `${C}_CHANGE_SEARCH_TEXT`,
    SEARCH_ITEM_LIST: `${C}_SEARCH_ITEM_LIST`,
    CANCEL_SEARCH_ITEM_LIST: `${C}_SEARCH_ITEM_LIST`,

    /* Actions that will affect pagination view */
    CHANGE_PAGING_CURRENT: `${C}_CHANGE_PAGING_CURRENT`,
    CHANGE_PAGING_TOTAL: `${C}_CHANGE_PAGING_TOTAL`,

    /**
     * Actions that will affect export
     */
    EXPORT_ITEM_LIST: `${C}_EXPORT_ITEM_LIST`,
    CANCEL_EXPORTING: `${C}_CANCEL_EXPORTING`,
    CHANGE_SEARCH_STATUS: `${C}_CHANGE_SEARCH_STATUS`,
}