import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import BaseComponent from '../base/BaseComponent';
import ScreenWarehouse from './ScreenWarehouse';
// import ScreenReserve from './ScreenReserve';
import ScreenStockIn from './ScreenStockIn';
import ScreenStockOut from './ScreenStockOut';
import StockInSupplierEdit from './stock-in/StockInSupplierEdit';
import StockInContractorEdit from './stock-in/StockInContractorEdit';
import _ from 'lodash';
import { Switch, Route, Redirect } from 'react-router-dom';
import * as PATH from '../../constants/routeConstants';

class PageWareHouse extends BaseComponent {
  constructor(props, context) {
    super(props, context);

    this.state = {
      tabCurrent: ''
    };
  }

  componentWillReceiveProps(newProps) {
    let { tabCurrent } = this.state;
    !(tabCurrent === _.get(newProps.appState, 'page.tab', '')) && this.setState({ tabCurrent: _.get(newProps.appState, 'page.tab', '') });
  }

  getEditComponent(tab, tabSub = '') {
    let EditComponent = null;

    switch (tab) {
      case 'stock-in':
        EditComponent = tabSub == 'supplier' ? StockInSupplierEdit : StockInContractorEdit;
        break;
    }

    return EditComponent;
  }

  render() {
    const pageCurrent = this.props.appState.page || {};
    let { tabCurrent } = this.state;

    // let tabCurrent = 'stock-in'

    let EditComponent = this.getEditComponent(pageCurrent.tab, _.get(pageCurrent, 'context.tabSub', ''));
    let show = !!pageCurrent.isEdit && EditComponent;

    return (
      <div>
        {/* { show && (<EditComponent {...this.props} />)} */}
        <Switch>
          <Route path={PATH.WAREHOUSE_INBOUND_EDIT_SUPPLIER_URL} render={() => (<StockInSupplierEdit {...this.props} />)} />
          <Route path={PATH.WAREHOUSE_INBOUND_EDIT_CONTRACTOR_URL} render={() => (<StockInContractorEdit {...this.props} />)} />
          <Route path={PATH.WAREHOUSE_STOCK_URL} render={() => (
            <ScreenWarehouse {...this.props} tabCurrent={tabCurrent} />
          )} />
          <Route path={PATH.WAREHOUSE_INBOUND_URL} render={() => (
            <ScreenStockIn {...this.props} tabCurrent={tabCurrent} />
          )} />
          <Route path={PATH.WAREHOUSE_OUTBOUND_URL} render={() => (
            <ScreenStockOut {...this.props} tabCurrent={tabCurrent} />
          )} />
          <Route path={PATH.WAREHOUSE_URL} exact render={() => <Redirect to={PATH.WAREHOUSE_STOCK_URL} />} />
        </Switch>
        {/* {tabCurrent == 'reserves' && (<ScreenReserve {...this.props} tabCurrent={tabCurrent} />)} */}
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    appState: state.appState,
    authState: state.authState
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

PageWareHouse.propTypes = {
  authState: PropTypes.object.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(PageWareHouse);
