import C from '../../constants/reportAnalytic/customerPOReport';
import { combineReducers } from "redux";

const dataChart = (state = [], action) => {
    const {type, payload} = action;
    switch(type) {
        case C.CHANGE_DATA_CHART:
            return payload;
        default:
            return state;
    }
}

const searchValue = (state = {}, action) => {
    const {type, payload} = action;
    switch(type) {
        case C.CHANGE_SEARCH_VALUE:
            let dic = _.cloneDeep(state);
            const {key, date} = payload;
            dic[key] = date;
            return dic;
        default:
            return state;
    }
}

const customerPOReport = combineReducers({
    dataChart,
    searchValue,
})

export default (state, action) => {
    const { type } = action;
    if (type === C.RESET_DATA) {
      return {};
    }
    return customerPOReport(state, action);
  };