import { combineReducers } from 'redux';
import C from '../../constants/ship-out/editPklShip';
import _ from 'lodash';

const pklShipDetail = (state = null, action) => {
  const { type, payload } = action;
  // let orderId, styleId, cutTicketId, colorId, barNo, bar;
  switch (type) {
    case C.CHANGE_PKL_SHIP_DETAIL:
      return payload;
    case C.PERFORM_CHANGE_BAR_ACTUAL_QUANTITIES:
      const { orderId, styleId, cutTicketId, colorId, barNo, bar } = payload;
      let dic = _.cloneDeep(state);
      dic.pklProds.map(
        order =>
          order._id === orderId &&
          order.styles.map(
            style =>
              style.style._id === styleId &&
              style.cutTickets.map(
                cut =>
                  cut.cutTicket._id === cutTicketId &&
                  cut.colors.map(
                    color =>
                      color.color._id == colorId &&
                      color.packings.map(packing => {
                        if (packing.props.no == barNo) {
                          packing.items.map(
                            (item, index) => (item.actualQuantity = _.toInteger(bar.sizeInputs[index].value))
                          );
                        }
                      })
                  )
              )
          )
      );
      return dic;
    case C.CHANGE_COMMODITY: {
      const { pklProds, orderId, value } = payload;
      let dic = _.cloneDeep(state);
      const orderItem = _.find(dic.pklProds, it => it._id === orderId);
      if (orderItem) {
        orderItem.commodity = value;
      }
      return dic;
    }
    case C.RESET_DATA:
      return null;
    default:
      return state;
  }
};

const activeOrders = (state = [], action) => {
  const { type, payload } = action;
  switch (type) {
    case C.CHANGE_PKL_SHIP_DETAIL: {
      // Active first order after reload data
      const [item] = payload && payload.pklProds;
      return item ? [item._id] : [];
    }
    case C.CHANGE_ACTIVE_ORDER: {
      const { orderId, value } = payload;
      let filtered = _.cloneDeep(state).filter(it => it !== orderId);
      if (value) filtered = [...filtered, orderId];
      return filtered;
    }

    case C.RESET_DATA:
      return [];
    default:
      return state;
  }
};

const activeStyles = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case C.CHANGE_PKL_SHIP_DETAIL: {
      const dic = {};
      payload &&
        payload.pklProds.map(it => {
          // Active one style of each order if found one
          const [style] = it.styles;
          dic[it._id] = style ? style.style._id : null;
        });
      return dic;
    }

    case C.CHANGE_ACTIVE_STYLE: {
      const { orderId, styleId } = payload;
      const dic = _.cloneDeep(state);
      dic[orderId] = styleId;
      return dic;
    }
    case C.REMOVE_STYLE: {
      const { orderId, styleId } = payload;
      const dic = _.cloneDeep(state);
      if (dic[orderId] === styleId) dic[orderId] = null;
      return dic;
    }
    case C.RESET_DATA:
      return {};
    default:
      return state;
  }
};

const barInputs = (state = {}, action) => {
  const { type, payload } = action;
  let dic = {};
  let orderId, styleId, cutTicketId, colorId, barNo, sizeName, value, bar;
  let inputGroup = null;
  let key = '';
  switch (type) {
    case C.CHANGE_PKL_SHIP_DETAIL: {
      if (payload && payload.pklProds[0].type === 'Carton') return {};
      dic = {};
      payload.pklProds.map(item => {
        item.styles.map(style => {
          style.cutTickets.map(cutTicket => {
            cutTicket.colors.map(color => {
              key = `${item._id}:${style.style._id}:${cutTicket.cutTicket._id}:${color.color._id}`;
              // const [packing] = color.packings;
              // const planPackings = packing ? packing.items : color.color.sizes;
              dic[key] = color.packings.map(packing => {
                return {
                  bar: packing.props.no,
                  sizeInputs: _.map(packing.items, size => ({
                    name: size.name,
                    value: size.actualQuantity || 0,
                  })),
                  addableQuantities: _.map(packing.items, size => ({
                    name: size.name,
                    value: size.planQuantity || 0,
                  })),
                };
              });
            });
          });
        });
      });
      return dic;
    }
    case C.CHANGE_BAR_ACTUAL_QUANTITY_INPUT_VALUE:
      orderId = payload.orderId;
      styleId = payload.styleId;
      cutTicketId = payload.cutTicketId;
      colorId = payload.colorId;
      barNo = payload.barNo;
      sizeName = payload.sizeName;
      value = payload.value;
      key = `${orderId}:${styleId}:${cutTicketId}:${colorId}`;
      dic = _.cloneDeep(state);
      dic[key].map(bar => {
        if (bar.bar == barNo) {
          bar.addableQuantities.map((item, index) => {
            if (item.name == sizeName && value <= item.value) {
              bar.sizeInputs[index].value = value;
            }
          });
        }
      });
      return dic;
    case C.RESET_BAR_NUMBER:
      orderId = payload.orderId;
      styleId = payload.styleId;
      cutTicketId = payload.cutTicketId;
      colorId = payload.colorId;
      barNo = payload.barNo;
      bar = payload.bar;
      key = `${orderId}:${styleId}:${cutTicketId}:${colorId}`;
      dic = _.cloneDeep(state);
      dic[key].map(b => {
        if (b.bar == barNo) {
          b.sizeInputs.map((item, index) => {
            item.value = bar.items[index].actualQuantity;
          });
        }
      });
      return dic;
    case C.RESET_DATA:
      return null;
    default:
      return state;
  }
};

const packedQuantities = (state = {}, action) => {
  const { type, payload } = action;
  let orderId, styleId, cutTicketId, colorId, group, cartonNo, value, key;
  let dic = {};
  switch (type) {
    case C.CHANGE_PKL_SHIP_DETAIL:
      if (payload && payload.pklProds[0].type === 'GOHH') return {};
      dic = {};
      payload.pklProds.map(item => {
        item.styles.map(style => {
          style.cutTickets.map(cutTicket => {
            cutTicket.colors.map(color => {
              const groups = _.groupBy(color.packings, pk => pk != null && pk.props.group);
              key = `${item._id}:${style.style._id}:${cutTicket.cutTicket._id}:${color.color._id}`;
              const temp =
                !_.isEmpty(groups) &&
                _.map(groups, (v, k) => {
                  return {
                    active: false,
                    raw: _.map(v, it => {
                      if (it)
                        return {
                          checked: true,
                          props: it.props,
                          items: it.items,
                        };
                    }),
                    quantity: v.length,
                    qtyCtn: v[0] ? _.sum(_.cloneDeep(v)[0].items.map(item => item.planQuantity)) : 0,
                    group: k,
                  };
                });
              // console.log('------------packedQuantities', temp);
              let cartons = temp
                ? temp.map(carton => {
                    if (carton)
                      return {
                        active: null,
                        canExcute: carton.quantity == 1,
                        checked: carton.quantity == 1 ? true : false,
                        group: carton.group,
                        ctnNo: carton.quantity > 1 ? carton.group : carton.raw[0] && carton.raw[0].props.no,
                        quantity: carton.quantity,
                        qtyCtn: carton.qtyCtn,
                        props: carton.raw[0] && carton.raw[0].props,
                        items: carton.raw[0] && carton.raw[0].items,
                      };
                  })
                : [];
              cartons.sort((a, b) => a.group > b.group);
              (temp || []).map(
                carton =>
                  carton.quantity > 1 &&
                  carton.raw.map(item => {
                    const result = {
                      active: false,
                      canExcute: true,
                      checked: item && item.checked,
                      group: carton.group,
                      ctnNo: item ? `${item.props.no}` : '',
                      quantity: 1,
                      qtyCtn: carton.qtyCtn,
                      props: item && item.props,
                      items: item && item.items,
                    };
                    const index = item && _.findLastIndex(cartons, carton => carton.group == item.props.group) + 1;
                    item && cartons.splice(index, 0, result);
                  })
              );
              dic[key] = cartons;
              //{checked: true, quantity: v.length, raw: v};
              // _.map(groups, (v, k) => {
              //     const key = `${item._id}:${style.style._id}:${cutTicket.cutTicket._id}:${color.color._id}:${k}`;
              //     dic[key] = {checked: true, quantity: v.length, raw: v};
              // });
            });
          });
        });
      });
      return dic;
    case C.SHOW_CARTON_DETAIL:
      orderId = payload.orderId;
      styleId = payload.styleId;
      cutTicketId = payload.cutTicketId;
      colorId = payload.colorId;
      group = payload.group;
      key = `${orderId}:${styleId}:${cutTicketId}:${colorId}`;
      dic = _.cloneDeep(state);
      dic[key].map(carton => (carton.group == group && carton.canExcute ? (carton.active = !carton.active) : null));
      return dic;
    case C.CHANGE_CHECKED_PACKING:
      orderId = payload.orderId;
      styleId = payload.styleId;
      cutTicketId = payload.cutTicketId;
      colorId = payload.colorId;
      group = payload.group;
      cartonNo = payload.cartonNo;
      value = payload.value;
      key = `${orderId}:${styleId}:${cutTicketId}:${colorId}`;
      dic = _.cloneDeep(state);
      // console.log('---------dic CHANGE_CHECKED_PACKING', value);
      dic[key].map(carton => {
        if (carton.group == group && carton.canExcute && carton.ctnNo == cartonNo) {
          carton.props.actual = value;
          carton.checked = value;
        }
      });
      // console.log('---------dic ', dic[key]);
      return dic;
    case C.CHANGE_PACKED_NWKGS:
      orderId = payload.orderId;
      styleId = payload.styleId;
      cutTicketId = payload.cutTicketId;
      colorId = payload.colorId;
      group = payload.group;
      cartonNo = payload.cartonNo;
      value = payload.value;
      key = `${orderId}:${styleId}:${cutTicketId}:${colorId}`;
      dic = _.cloneDeep(state);
      // console.log('---------dic CHANGE_CHECKED_PACKING', value);
      dic[key].map(carton => {
        if (carton.group == group && carton.ctnNo == cartonNo) {
          carton.props.nwKgs = Number(value);
        }
      });
      // console.log('---------dic ', dic[key]);
      return dic;
    case C.CHANGE_PACKED_GWKGS:
      orderId = payload.orderId;
      styleId = payload.styleId;
      cutTicketId = payload.cutTicketId;
      colorId = payload.colorId;
      group = payload.group;
      cartonNo = payload.cartonNo;
      value = payload.value;
      key = `${orderId}:${styleId}:${cutTicketId}:${colorId}`;
      dic = _.cloneDeep(state);
      // console.log('---------dic CHANGE_CHECKED_PACKING', value);
      dic[key].map(carton => {
        if (carton.group == group && carton.ctnNo == cartonNo) {
          carton.props.gwKgs = Number(value);
        }
      });
      // console.log('---------dic ', dic[key]);
      return dic;
    case C.CHANGE_PACKING_NUMBER:
      orderId = payload.orderId;
      styleId = payload.styleId;
      cutTicketId = payload.cutTicketId;
      colorId = payload.colorId;
      group = payload.group;
      cartonNo = payload.cartonNo;
      value = payload.value;
      key = `${orderId}:${styleId}:${cutTicketId}:${colorId}`;
      dic = _.cloneDeep(state);
      if (key in dic)
        dic[key].find(item => item.group === cartonNo).quantity =
          dic[key].find(item => item.group === cartonNo).quantity >= _.toInteger(value)
            ? _.toInteger(value)
            : dic[key].find(item => item.group === cartonNo).quantity;
      return dic;
    case C.RESET_DATA:
      return {};
    default:
      return state;
  }
};

const success = (state = null, action) => {
  const { type, payload } = action;
  switch (type) {
    case C.RESET_DATA:
      return false;
    case C.UPDATE_SUCCESS:
      return payload;
    default:
      return state;
  }
};

const view = (state = false, action) => {
  const { type, payload } = action;
  switch (type) {
    case C.CHANGE_VIEW:
      return payload;
    case C.RESET_DATA:
      return false;
    default:
      return state;
  }
};

const activeScreenEditPklShip = (state = false, action) => {
  const { type, payload } = action;
  switch (type) {
    case C.ACTIVE_SCREEN_EDIT_PKL_SHIP:
      return payload;
    default:
      return state;
  }
};

/**
 * Raw data
 */
const actions = (state = [], action) => {
  const { type, payload } = action;
  switch (type) {
    case C.CHANGE_PKL_PROD_LIST_DETAIL:
      return payload.pklProds.reduce((x, pklProd) => {
        pklProd.packing.styles.forEach(s => {
          const style = pklProd.styles.find(s2 => s.style._id == s2.style._id);
          s.packings.forEach(pk => {
            const pkFilter = style.packings.filter(pk2 => pk2.props.group == pk.props.group);
            const key = `${pklProd.packing._id}-${s.style._id}-${pk.props.group}`;
            if (pkFilter.length == 0 && !x[key]) {
              x[key] = { check: false };
            } else if (pkFilter.length > 0) {
              const pkFind = pkFilter.find(pk2 => pk2.props.no == pk.props.no);
              if (pkFind) {
                const action = x[key];
                if (action) {
                  action.package += 1;
                  action.nos.push(pk.props.no);
                } else {
                  x[key] = { package: 1, nos: [pk.props.no] };
                }
              }
            }
          });
        });
        return x;
      }, {});
    case C.CHANGE_PKL_SHIP_DETAIL:
      return payload.pklProds.reduce((x, pklProd) => {
        pklProd.packing.styles.forEach(s => {
          const style = pklProd.styles.find(s2 => s.style._id == s2.style._id);
          s.packings.forEach(pk => {
            const pkFilter = style.packings.filter(pk2 => pk2.props.group == pk.props.group);
            const key = `${pklProd.packing._id}-${s.style._id}-${pk.props.group}`;
            if (pkFilter.length == 0 && !x[key]) {
              x[key] = { check: false };
            } else if (pkFilter.length > 0) {
              const pkFind = pkFilter.find(pk2 => pk2.props.no == pk.props.no);
              if (pkFind) {
                const action = x[key];
                if (action) {
                  action.package += 1;
                  action.nos.push(pk.props.no);
                } else {
                  x[key] = { package: 1, nos: [pk.props.no] };
                }
              }
            }
          });
        });
        return x;
      }, {});
    default:
      return state;
  }
};

// TODO
export const editPklShip = combineReducers({
  actions,
  success,
  view,
  pklShipDetail,
  barInputs,
  packedQuantities,
  activeOrders,
  activeStyles,
  activeScreenEditPklShip,
});
