/* eslint-disable import/no-unresolved */
import React from 'react';
import I18n from 'i18n-js';
import _ from 'lodash';
import SidebarOrders from "../../../components/common/layout/SidebarOrdersCommon";
import BarOrderInfo from "../../../components/common/layout/BarOrderInfo";
import SearchSelect from '../forms/SearchSelect';

const PurchasePlans = props => {
    const { orders, selectedOrder, listStyles, selectedStyle, materials, checkedFabric, checkedTrim } = props;
    // console.log(materials)
    return (
        <div id="content" >
            <SidebarOrders
                {...props}
                selectedOrder={selectedOrder || {}}
                orders={orders || []}
                handleSelectedOrder={(order) => props.handleSelectOrder(order)}
                handleLoadMore={(pageNumber) => props.handleLoadMore(pageNumber)}
                onSubmitSearch={()=>props.onSubmitSearch()}
            />
            <div className="content-main">
                <BarOrderInfo order={selectedOrder || {}} />
                <div>
                    <div className="md content-main-process mg-top-10 bg-white">
                        <div className="content-main-header">
                            <ul className="content-main-header-filter-style mg-top-15 w-full">
                                <li className="title">{I18n.t('PagePurchase.Style')}</li>
                                <div className="w-150" style={{ position: "absolute", left: 100, fontSize: "12px" }}>
                                    <SearchSelect
                                        options={listStyles || []}
                                        value={selectedStyle.value || ""}
                                        onChange={o => props.changeSelectedStyle(o)}
                                        isLoading={props.fetchingSelectableSuppliers}
                                        clearable = {false}
                                    />
                                </div>
                                <li className="title fabric-trim">
                                    <div className="style-checkbox">
                                        <input checked={checkedFabric} type="checkbox" id="fabricChxId"
                                            onChange={() => props.changeCheckedFabric(!checkedFabric)}
                                        />
                                        <label style={{ marginRight: '10px', cursor: 'pointer' }} htmlFor="fabricChxId"> <span><span className="text">Fabric</span></span></label>
                                    </div>
                                    <div className="style-checkbox">
                                        <input checked={checkedTrim} type="checkbox" id="trimChxId"
                                            onChange={() => props.changeCheckedTrim(!checkedTrim)}
                                        />
                                        <label style={{ cursor: 'pointer' }} htmlFor="trimChxId"> <span><span className="text">Trim</span></span></label>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    {!_.isEmpty(materials) &&
                        <div className="md content-main-info table-purchase-plan">
                            <div className="wrap-global-table pd-0">
                                <table style={{ width: '100%' }} className="global-table">
                                    <tbody>
                                        <tr>
                                            <th>{I18n.t('PagePurchase.Material')}</th>
                                            <th>{I18n.t('PagePurchase.Content')}</th>
                                            <th className="center">{I18n.t('PagePurchase.Replacement')}<br />P.0 #</th>
                                            <th className="center">{I18n.t('PagePurchase.Color')}</th>
                                            <th className="center">{I18n.t('PagePurchase.Size')}</th>
                                            <th className="center">{I18n.t('PagePurchase.QTY')}</th>
                                            <th className="center">{I18n.t('PagePurchase.Price')}</th>
                                            <th className="center">{I18n.t('PagePurchase.InStock')}</th>
                                            <th className="center">{I18n.t('PagePurchase.NeedToBuy')}</th>
                                            <th className="center w-140">{I18n.t('PagePurchase.Supplier')}</th>
                                            <th className="center">{I18n.t('PagePurchase.Amount')}</th>
                                        </tr>
                                        {materials.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{item.material}</td>
                                                    <td>{item.content}</td>
                                                    <td className="center">{item.replacementPO}</td>
                                                    <td className="center">{item.color}</td>
                                                    <td className="center">{item.size}</td>
                                                    <td className="center">{item.quantity}</td>
                                                    <td className="center">{item.price}</td>
                                                    <td className="center">{item.inStock}</td>
                                                    <td className="center w-100">
                                                        <input
                                                            value={item.needToBuy}
                                                            type="number" min="0"
                                                            className="form-control center"
                                                            onChange={e => props.changeMaterialValue(checkedFabric, checkedTrim, item._id, index, 'needToBuy', Number(e.target.value))}
                                                        />
                                                    </td>
                                                    <td className="center w-140">
                                                        {item.needToBuy > 0 &&
                                                            <SearchSelect
                                                                options={item.suppliers || []}
                                                                value={(item.supplier || {}).value || ""}
                                                                onChange={o => props.changeMaterialValue(checkedFabric, checkedTrim, item._id, index, 'supplier', o)}
                                                                // isLoading={props.fetchingSelectableSuppliers}
                                                                clearable
                                                            />}
                                                    </td>
                                                    <td className="center">{item.amount}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                                <div className="center">
                                    <ul className="list-button">
                                        <li>
                                            <a className="bt-orange color-white"
                                                onClick={() => props.previewPlan()}
                                            >{I18n.t('PagePurchase.PreviewPurchase')}</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>}

                </div>
            </div>
        </div>
    );
}

export default PurchasePlans;