import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr'
import _ from 'lodash';

import { bindActionCreators } from '../../../node_modules/redux';

import BaseComponent from '../base/BaseComponent';

import payInfoApi from './../../api/payInfoApi';
import * as types from "../../constants/actionTypes";
import { SEARCH_LIST } from "../../constants/constants";
import { ACCOUNTING_RECEIVABLE_PLAN_CHARGE_BACK_URL, ACCOUNTING_CREDIT_DEBIT_URL} from "../../constants/routeConstants";
import commonUseActions from './../../actions/commonUseActions';

import TableList from '../common/datatables/TableList';
import ModalAddCreditDebit from './../common/modals/ModalAddCreditDebit';
import ModalConfirm from '../common/modals/ModalConfirm';
import SearchBar from '../../ui/common/components/SearchBar';

import icExport from '../../stylesheets/images/ic-export.svg';
import icAdd from '../../stylesheets/images/ic-add.svg';
import CustomPagination from '../common/datatables/CustomPagination';

var FileSaver = require('file-saver');
class ScreenCreditDebitNote extends BaseComponent {
  constructor(props, context) {
    super(props, context);
    let { roles: {showEdit, showDelete} } = this.props;
    this.state = {
      items: [],
      pageNumber: 1,
      pageSize: 10,
      columns: [
        { name: I18n.t('PageAccounting.CreditDebitID'), value: 'code', options: { style: { minWidth: 130 } } },
        { name: I18n.t('PageAccounting.IssuedDate'), value: 'issuedDate', type: 'date', formatType: 'MMM DD, YYYY', options: { style: { minWidth: 105 } } },
        { name: I18n.t('PageAccounting.Type'), value: 'type' },
        { name: I18n.t('PageAccounting.ChargeTo'), value: 'chargeTo' },
        { name: I18n.t('PageAccounting.Amount'), value: 'amount' },
        { name: I18n.t('PageAccounting.Currency'), value: 'currency' },
        { name: I18n.t("PageAccounting.Note"), value: 'note' },
        { name: I18n.t("PageAccounting.Status"), value: 'status' },
      ],
      actions: [
        (row) => {
          if(row && !row.code.includes("AC-")) return {};
          return {type: 'export', click: (item) => this.exportItem(item)};
        },
        (row) => {
          if(row && row.code.includes("AC-") && row.status == "Used") {
            return {type: 'view', click: (item) =>  {
              this.props.history.push(ACCOUNTING_CREDIT_DEBIT_URL + "/view/" +  item._id);
              }
            };
          }

          return showEdit ? { type: 'edit', click: (data) => {
            if(data.code.includes("AC-") ) {
              if(data.status != "Used") {
                this.props.history.push(ACCOUNTING_RECEIVABLE_PLAN_CHARGE_BACK_URL + "/edit/" + data._id);
              }
            } else {
              this.editPayInfo(data) 
            }
          }
          } : {};
        },
        (row) => {
          if((row && row.code.includes("AC-") && row.status == "Used") || !showDelete) return {};
          return { type: 'delete', click: (item) =>  {
            if(item && item.code.includes("AC-") && item.status == "Used") {
              toastr.error(I18n.t('Notify.Error'), "This PayInfo can not delete.")
              return;
            }
            this.showDeleteConfirm(item) ;
          }
        } },
      ],
      selectableFilter: SEARCH_LIST.CREDIT_DEBIT_SEARCH_LIST,
      selectedFilter: _.get(SEARCH_LIST, 'CREDIT_DEBIT_SEARCH_LIST[0].field', ''),
      searchValue: {}
    };
  }
  componentWillMount() {
    let options = this.getBaseOption();
    this.getPayInfo(options);
  }

  editPayInfo = (data) => {
    let { openModal, closeModal } = this.props.modal;
    let modal = (<ModalAddCreditDebit closeModal={closeModal} ok = {data => this.getPayInfo(this.getBaseOption())}  payInfo={data}/>);
    openModal(modal);
  }

  showDeleteConfirm = item => {
    let { openModal, closeModal } = this.props.modal;
    let modal = (<ModalConfirm
      title={item.type == 'Debit' ? I18n.t('PageAccounting.DeleteDebit') : I18n.t('PageAccounting.DeleteCredit')}
      confirmMessage={I18n.t('PageAccounting.DeleteConfirm')}
      messageOk={I18n.t('Btn.YES')}
      messageCancel={I18n.t('Btn.CANCEL')}
      handleClose={closeModal}
      handleOk={() => this.deletePayInfo(item._id)} />);
    openModal(modal);
  }

  deletePayInfo = id => {
    this.props.dispatch({ type: types.APP.SHOW_LOADING })
    payInfoApi.deletePayInfo(id).then(rs => {
      this.props.dispatch({ type: types.APP.HIDE_LOADING })
      if (rs.success) {
        toastr.success(I18n.t('Notify.Success'), '')
        this.props.dispatch({
          type: types.APP.SET_PAGE_CURRENT,
          data: {
            isEdit: false,
            tab: 'accounting.credit',
            tabChild: ''
          }
        })
        let options = this.getBaseOption();
        this.getPayInfo(options);
      } else {
        toastr.error(I18n.t('Notify.Error'), rs.error)
      }
    },
      err => {
        this.props.dispatch({ type: types.APP.HIDE_LOADING })
        toastr.error(I18n.t('Notify.Error'), err.error)
      })
  }

  getBaseOption = (page) => {
    const { pageSize, pageNumber, selectedFilter, searchValue } = this.state;
    let options = {
      sort: JSON.stringify({ createdAt: -1 }),
      pageSize: pageSize,
      pageNumber: page || pageNumber,
    };
    if(selectedFilter && !_.isEmpty(searchValue)) {
      if(selectedFilter !== 'issuedDate' && searchValue.value) {
        options.searchKey = selectedFilter;
        options.searchValue = searchValue.value;
      } 
      if(selectedFilter === 'issuedDate' && (searchValue.from || searchValue.to)) {
        options.searchKey = selectedFilter;
        if(searchValue.from && searchValue.from !== "Invalid date") {
          options.searchFrom = moment(searchValue.from).format('YYYY-MM-DD');
        }
        if(searchValue.to && searchValue.to !== "Invalid date") {
          options.searchTo = moment(searchValue.to).format('YYYY-MM-DD');
        }
      }
    }
    return options;
  }

  addCreditDebit = () => {
    let { openModal, closeModal } = this.props.modal;
    let modal = (<ModalAddCreditDebit closeModal={closeModal} ok={this.ok} />);
    openModal(modal);
  }

  getPayInfo = (options) => {
    payInfoApi.getListPayInfo(options).then(res => {
      if (res.success) {
        this.setState({
          items: _.get(res, "data", []),
          pages: _.get(res, "meta.pages", 0),
        })
      }
    })

  }

  ok = (payInfo) => {
    let options = this.getBaseOption();
    this.getPayInfo(options);
  }
  
  onChangeFilter(value) {
    let selectedFilter = _.cloneDeep(this.state.selectedFilter);
    let searchValue = _.cloneDeep(this.state.searchValue);
    if(selectedFilter !== value) {
      selectedFilter = value;
      // reset search value if change search field
      searchValue = {};
      this.setState({selectedFilter, searchValue});
    }
  }

  onChangeSearchValue(value, pos) {
    let searchValue = _.cloneDeep(this.state.searchValue);
    searchValue[pos] = value;
    this.setState({searchValue});
  }

  changePage(page) {
    this.setState({pageNumber: page});
    let options = this.getBaseOption(page);
    this.getPayInfo(options);
  }

  onSearch() {
    this.setState({pageNumber: 1});
    let options = this.getBaseOption(1);
    this.getPayInfo(options);
  }

  onExport() {
    this.setState({pageNumber: 1});
    let options = this.getBaseOption(1);
    let url = payInfoApi.getExportCreditDebitUrl();
    if (options.searchKey && options.searchValue) {
      if (options.searchKey != 'issuedDate') {
        url += '?searchKey=' + options.searchKey + '&searchValue=' + options.searchValue;
      } else {
        url += '?searchKey=' + options.searchKey + '&searchFrom=' + options.searchFrom + '&searchTo=' + options.searchTo;
      }
    }
    FileSaver.saveAs(url, "Report_Credit_Debit_Note.xlsx");
  }

  exportItem = item => {
    let url = payInfoApi.getExportCreditDebitUrl() + '/' + item._id + '?reason=' + item.reason;
    let fileName = "Report_Credit_Debit_Reason.xlsx";
    if (item.reason && item.reason == "AC") {
      fileName = "Report_Debit_AC.xlsx";
    }
    FileSaver.saveAs(url, fileName);
  }

  render() {
    const { items, columns, pageNumber, actions, selectableFilter, selectedFilter, searchValue, pages } = this.state;
    let { roles: {showCreate} } = this.props;
    return (
      <div className='show'>
        <div>
          <span style={{display: 'inline-block', float: 'right', marginTop: 15, fontSize: 14, position: 'relative'}} className="export cursor-pointer" onClick={() =>this.onExport()}>
            Export <img src={icExport} alt="" style={{position: 'relative', top: 5}}/>
          </span>
          {showCreate && <div className="wrap-stock-in">
            <span className="cursor-pointer" onClick={this.addCreditDebit}><img src={icAdd} alt=""/> {I18n.t('ScreenCreditDebitNote.AddCreditDebit')}</span>
          </div>}
          <SearchBar fields={selectableFilter} selectedFilter={selectedFilter} searchValue={searchValue} onChangeFilter={value => this.onChangeFilter(value)} onChangeValue={(value, pos) => this.onChangeSearchValue(value, pos)} onSearch={() => this.onSearch()}/>
        </div>
        <div className="wrap-global-table">
          <TableList
            {...this.props}
            items={items || []}
            columns={columns || []}
            actions={actions || []}
            onAction={this.onAction}
            options={{ clsTbl: 'global-table box-shadow-none' }}
            widthAction="w-100"
          />
          <CustomPagination items={pages} activePage={pageNumber} onSelect={page => {pageNumber !== page && this.changePage(page) }} />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    appState: state.appState,
    authState: state.authState
  };
}

function mapDispatchToProps(dispatch) {
  return {
    commonUseActions: bindActionCreators(commonUseActions, dispatch),
    dispatch
  };
}

ScreenCreditDebitNote.propTypes = {
  authState: PropTypes.object.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(ScreenCreditDebitNote);

