import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link, Switch, Route, Redirect } from 'react-router-dom';
import * as R from "ramda";
import * as PATH from '../../../../constants/routeConstants';
import SRC from '../../../../constants/assets';
import FormGroup from '../../forms/FormGroup';
import Input from '../../forms/Input';
import SidebarItemList from "../SidebarItemList";
// import AccountsPayablePlanCreditDebitSelect from '../../../containers/accounting/AccountsPayablePlanCreditDebitSelect';
import AccountsPayablePlanImportExportRadioLists from '../../../containers/accounting/AccountsPayablePlanImportExportRadioLists';
import AccountsPayablePlanAmountTable from '../AccountsPayablePlanAmountTable'
const formatCurrency = require('format-currency');
const DateTimeField = require('react-datetime');

const AccountsPayablePlanImportExportDeclaration = (props) => {
    return (
        <div>
            <div style = {{ float: 'right',
                      top: '-50px',
                      right: '10px',
                      position: 'absolute'}}>
                {props.roles.showCreate && <ul className="inline-block">
                    <li className="float-left style-icon-save top-7 position-relative">
                        <span className="cursor-pointer"
                            onClick = {() => props.save(props.dataApi)}
                        >
                        <i className="fa fa-floppy-o" aria-hidden="true"></i>
                                SAVE
                        </span>
                    </li>
                </ul>}
            </div>
            <form onSubmit={(e) => {
                e.preventDefault();
                props.onSubmit();
            }}>
                <div id="header-accounting" className="inline-block w-full position-relative">
                    <div className="left w-200" />
                    <div className="right top-0 w-full pd-left-230">
                        <div className="border-title-left" />
                        <div className="title-left left">
                            <div className="btn-group btn-supplier-contractor-importExport ">
                                <button className="btn btn-secondary btn-lg dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Import/Export</button>
                                <div className="dropdown-menu">
                                    <ul>
                                        <li><Link to={PATH.ACCOUNTING_PAYABLE_PLAN_CONTRACTOR_URL}>Contractor</Link></li>
                                        <li><Link to={PATH.ACCOUNTING_PAYABLE_PLAN_SUPPLIER_URL}>Supplier</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <AccountsPayablePlanImportExportRadioLists pathname = {props.pathname}/>
                        </div>

                        <div className="bd-dashed-bottom wrap-input-global pd-15-0 margin-small">
                            <div className="col-md-6 col-xs-12 input">
                                <FormGroup
                                    type="input"
                                    label="Name"
                                    inputComponent={
                                        <Input
                                            type="text"
                                            value={props.name}
                                            onChange={e =>
                                                props.changeName(e.target.value)
                                            }
                                            placeholder=""
                                        />
                                    }
                                />
                            </div>
                            <div className="col-md-6 col-xs-12 input">
                                <FormGroup
                                    type="input"
                                    label="Other Fees"
                                    inputComponent={
                                        <Input
                                        type="number"
                                        value={props.orderFees}
                                        onChange={e =>
                                            props.changeOrderFees(_.toNumber(e.target.value))
                                        }
                                        placeholder=""
                                        />
                                    }
                                />
                            </div>
                            <div className="col-md-6 col-xs-12 input">
                                <FormGroup
                                    type="input"
                                    label="Invoice No."
                                    inputComponent={
                                        <Input
                                        type="text"
                                        value={props.invoiceNo}
                                        onChange={e =>
                                            props.changeInvoiceNo(e.target.value)
                                        }
                                        placeholder=""
                                        />
                                    }
                                />
                            </div>

                            <div className="col-md-6 col-xs-12 input">&nbsp;</div>
                            <div className="col-md-6 col-xs-12 input">
                                <FormGroup
                                    type="input"
                                    label="Invoice Date"
                                    inputComponent={
                                        <DateTimeField
                                            timeFormat={false} closeOnSelect dateFormat="MMM DD, YYYY"
                                            value={moment(props.invoiceDate)}
                                            onChange={date =>
                                                props.changeInvoiceDate(date.format())
                                            }
                                        />
                                    }
                                />
                            </div>
                            <div className="col-md-6 col-xs-12 input import-export-note">
                                <FormGroup
                                    type="textarea"
                                    label="Note"
                                    inputComponent={
                                        <textarea
                                            // style = {{width: '113%', marginLeft: '-19px'}}
                                            value={props.note}
                                            type="text"
                                            maxLength={200}
                                            onChange={e =>
                                                props.changeNote( e.target.value)
                                            }
                                        />
                                    }
                                />
                            </div>
                        </div>

                        <AccountsPayablePlanAmountTable
                            AmountINV={formatCurrency(props.amount) + 'VND'}
                            TotalAmountINV={formatCurrency(props.totalAmount) + 'VND'}
                            Say={props.say}
                        />
                    </div>
                </div>

                <div className="content-main-accounting">

                    <SidebarItemList
                        searchText={props.searchText}
                        placeholder="Declaration No"
                        changeSearchText={text => props.changeSearchText(text)}
                        items={props.listCheckableDeclaration}
                        changeItemChecked={(item, checked) =>
                        props.changeCheckedDeclaration(item, checked)
                        }
                    />

                    {props.listDeclarationDetail.map((item, index) => {
                        return(
                            <div className="box-shadow mg-bottom-5 inline-block" key = {index}>
                                <ul className="bg-white content-header-title pd-left-15 bd-solid-bottom-3">
                                    <li>
                                        <p>Declaration No <span className="bold mg-left-25 size-14">{R.path(['declarationNo'], item)}</span></p>
                                    </li>
                                    <li className="float-right">
                                        <div className="inline-block mg-right-30">
                                            <span className="bold">{moment(R.path(['declarationDate'], item)).format("MMM DD, YYYY")}</span>
                                        </div>
                                    </li>
                                </ul>

                                <ul className="bg-lightGray content-header-title pd-left-15">
                                    <li>
                                        <p>Border Gate <span className="bold mg-left-25 text-transform">{R.path(['borderGate'], item)}</span></p>
                                    </li>
                                    <li className="float-right mg-right-30">
                                        <p>Note <span className="bold mg-left-25 text-transform">{R.path(['note'], item)}</span></p>
                                    </li>
                                </ul>

                                <div className="bg-white float-left text-left size-12 pd-15-0 inline-block w-full">
                                    <div className="col-md-3 col-sm-6 col-xs-12 mg-bottom-10">
                                        <p><span className="mg-right-15">QTY PKG</span> <span className="bold color-number">{R.path(['qtyPKG'], item)}</span></p>
                                    </div>
                                    <div className="col-md-3 col-sm-6 col-xs-12 mg-bottom-10">
                                        <p><span className="mg-right-15">Import/Export Fees</span> <span className="bold color-number">{formatCurrency(R.path(['crsPaperWork'], item))}</span></p>
                                    </div>
                                    <div className="col-md-3 col-sm-6 col-xs-12 mg-bottom-10">
                                        <p><span className="mg-right-15">Transportation Fees</span> <span className="bold">{formatCurrency(R.path(['truckingIn'], item))}</span></p>
                                    </div>
                                    <div className="col-md-3 col-sm-6 col-xs-12 mg-bottom-10">
                                        <p><span className="mg-right-15">Expense</span> <span className="bold">{formatCurrency(R.path(['expense'], item))}</span></p>
                                    </div>
                                    <div className="col-md-3 col-sm-6 col-xs-12">
                                        <p><span className="mg-right-15">CBM</span> <span className="bold">{formatCurrency(R.path(['cbm'], item))}</span></p>
                                    </div>
                                    <div className="col-md-3 col-sm-6 col-xs-12">
                                        <p><span className="mg-right-15">Local Charge</span> <span className="bold">{formatCurrency(R.path(['localCharge'], item))}</span></p>
                                    </div>
                                    <div className="col-md-3 col-sm-6 col-xs-12">
                                        <p><span className="mg-right-15">Advance</span> <span className="bold">{formatCurrency(R.path(['advance'], item))}</span></p>
                                    </div>
                                    <div className="col-md-3 col-sm-6 col-xs-12">
                                        <p><span className="mg-right-15">Remaining</span> <span className="bold">{formatCurrency(R.pathOr(0 ,['remaining'], item))}</span></p>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                    
                </div>
            </form>
            <div className="center clearBoth mg-top-15">
                <button className={props.canPreview ? "bt-orange text-uppercase" : "cursor-text display-none text-uppercase"} style={{ marginRight: 10 }}
                    onClick={() => { props.onPreviewScreen() }}
                    disabled={!props.canPreview}
                >{'PREVIEW'}</button>
            </div>
        </div>
    );
};

AccountsPayablePlanImportExportDeclaration.propTypes = {
    //para
    name: PropTypes.string,
    invoiceNo: PropTypes.string,
    invoiceDate: PropTypes.string,
    orderFees: PropTypes.number,
    note: PropTypes.string,
    amount: PropTypes.number,
    totalAmount: PropTypes.number,
    say: PropTypes.string,
    searchText: PropTypes.string,
    listCheckableDeclaration: PropTypes.array,
    listDeclarationDetail: PropTypes.array,
    canPreview: PropTypes.bool,
    dataApi: PropTypes.object,
    //func
    changeName: PropTypes.func,
    changeInvoiceNo: PropTypes.func,
    changeInvoiceDate: PropTypes.func,
    changeOrderFees: PropTypes.func,
    changeNote: PropTypes.func,
    changeSearchText: PropTypes.func,
    changeCheckedDeclaration: PropTypes.func,
    onPreviewScreen: PropTypes.func,
    save: PropTypes.func
};

AccountsPayablePlanImportExportDeclaration.defaultProps = {
    name: '',
    invoiceNo: '',
    invoiceDate: moment().format(),
    orderFees: '',
    note: '',
    changeName: f => f,
    changeInvoiceNo: f => f,
    changeInvoiceDate: f => f,
    changeOrderFees: f => f,
    changeNote: f => f,
    changeSearchText: f => f,
    changeCheckedDeclaration: f => f,
    onPreviewScreen: f => f,
    save: f => f
};

export default AccountsPayablePlanImportExportDeclaration;
