import {ASSIGNMENT} from '../constants/actionTypes';
import  initial from './initialState';

export default function (state = initial.assignmentState, action) {
  switch (action.type) {
    case ASSIGNMENT.GET_ORDER_CONTRACT_SUCCESS:
      return {...state, ordersC: action.data.items || [], count: action.data.count || 0};
    case ASSIGNMENT.GET_LIST_ORDER_SUCCESS:
      return {...state, listOrder: action.data.items || [], count: action.data.count || 0};
    case ASSIGNMENT.GET_LIST_SUCCESS:
      return {...state, items: action.data.items || [], count: action.data.count || 0};
    case ASSIGNMENT.RELOAD:
      return {...state, reload: !state.reload};
    case ASSIGNMENT.GET_DETAIL_SUCCESS:
      return {...state, detail: action.data};
    case ASSIGNMENT.RESET_DETAIL:
      return {...state, detail: {}};
    default:
      return state;
  }
}
