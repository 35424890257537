import { combineReducers } from 'redux';
import C from '../../../constants/accounting/payable/editInvoiceSupplierDomestic';
import _ from 'lodash';

const invoiceDetail = (state = {}, action) => {
    const { type, payload } = action;
    let dic = _.cloneDeep(state);
    switch (type) {
        case C.CHANGE_INVOICE_DETAIL:
            return { ...payload, receipt: { isEdit: false } };
        case C.CHANGE_SUPPLIER_VALUE:
            dic.invoiceDetail.supplier.name = payload;
            return dic;
        case C.CHANGE_INVOICE_NO_VALUE:
            dic.invNo = payload;
            return dic;
        case C.CHANGE_CREDIT_DEBIT_VALUE:
            dic.invoiceDetail.creditDebit = payload.value;
            return dic;
        case C.CHANGE_ISSUED_DATE_VALUE:
            dic.issuedDate = payload;
            return dic;
        case C.CHANGE_DUE_DATE_VALUE:
            dic.dueDate = payload;
            return dic;
        case C.CHANGE_SELECT_CURRENCY:
            dic.invoiceDetail.convertType = payload.value;
            return dic;
        case C.CHANGE_CONVERT_RATE:
            dic.invoiceDetail.convertRate = payload;
            return dic;
        case C.SUBMIT_FILES:
            dic = _.cloneDeep(state);
            dic.files = payload;
            return dic;
        case C.SUBMIT_RECEIPT:
            dic = _.cloneDeep(state);
            dic.receipt = { ...payload, isEdit: true };
            return dic;
        case C.CHANGE_MAIN_VAT_NO:
            dic.invoiceDetail.vat.vatNo = payload;
            return dic;
        case C.CHANGE_MAIN_VAT_DATE:
            dic.invoiceDetail.vat.vatDate = payload;
            return dic;
        case C.CHANGE_MAIN_TAX_RATE:
            dic.invoiceDetail.vat.taxRate = payload;
            return dic;
        case C.CHANGE_MAIN_TOTAL_AMOUNT:
            dic.invoiceDetail.vat.totalAmount = payload;
            return dic;
        case C.RESET_ALL_DATA:
            return {};
        default:
            return state;
    }
}

/**
 * Store a selected supplier
 */
const supplier = (state = null, action) => {
    const { type, payload } = action;
    switch (type) {
        case C.CHANGE_INVOICE_DETAIL:
            return payload.invoiceDetail.supplier.name;
        default:
            return state;
    }
}

const typeInvoice = (state = '', action) => {
    const { type, payload } = action;
    switch (type) {
        case C.CHANGE_INVOICE_TYPE:
            return payload;
        default:
            return state;
    }
}



/**
 * Store a list of selectable credit debit notes
 */
const selectableCreditDebits = (state = [], action) => {
    const { type, payload } = action
    switch (type) {
        case C.CHANGE_LIST_CREDIT_DEBIT:
            return payload;
        default:
            return state;
    }
}

/**
 * Store a helper information to check whether credit debit notes data is fetching...
 */
const fetchingSelectableCreditDebitNotes = (state = false, action) => {
    const { payload, type } = action;
    switch (type) {
        // TODO. handle related actions
        default:
            return state;
    }
}

/**
 * Store helper information to check whether list of purchase orders is fetching from api
 */
const fetchingPurchaseOrders = (state = false, action) => {
    const { payload, type } = action;
    switch (type) {
        // TODO. handle related actions
        default:
            return state;
    }
}

/**
 * Store a list of purchase orders that will be used to check in side menu
 */
const listPurchaseOrderDetail = (state = [], action) => {
    const { type, payload } = action;
    let dic = _.cloneDeep(state);
    let _id, value;
    switch (type) {
        case C.CHANGE_LIST_PURCHASE_DETAIL:
            return payload.map(item => ({ ...item, checked: true, active: true }));
        case C.CHANGE_CHECKED_PURCHASE_ORDER:
            const { checked } = payload;
            _id = payload._id;
            dic.map(item => item._id === _id ? item.checked = checked : null);
            return dic;
        case C.CHANGE_TOGGLE_ACTIVE:
            const { active } = payload;
            _id = payload._id;
            dic.map(item => item._id === _id ? item.active = active : null);
            return dic;
        case C.CHANGE_PURCHASE_VAT_NO:
            _id = payload._id;
            value = payload.value;
            dic.map(item => item._id === _id ? item.vat.vatNo = value : null);
            return dic;
        case C.CHANGE_PURCHASE_VAT_DATE:
            _id = payload._id;
            value = payload.value;
            dic.map(item => item._id === _id ? item.vat.vatDate = value : null);
            return dic;
        case C.CHANGE_PURCHASE_TAX_RATE:
            _id = payload._id;
            value = payload.value;
            dic.map(item => item._id === _id ? item.vat.taxRate = value : null);
            return dic;
        case C.CHANGE_PURCHASE_NOTE:
            _id = payload._id;
            value = payload.value;
            dic.map(item => item._id === _id ? item.vat.note = value : null);
            return dic;
        case C.RESET_DATA_ALL:
            return [];
        default:
            return state;
    }
}


/**
 * Store a list of convertable currencies that used for select convert currency
 */
const selectableConvertCurrencies = (state = [], action) => {
    const { payload, type } = action;
    switch (type) {
        case C.CHANGE_LIST_CONVERT_CURRENCIES:
            return payload;
        default:
            return state;
    }
}

/**
 * Store selected convert currency
 */
const selectedConvertCurrency = (state = null, action) => {
    const { payload, type } = action;
    switch (type) {
        // TODO. handle related actions
        default:
            return state;
    }
}

/**
 * Store input information for convert rate field
 */
const convertRate = (state = null, action) => {
    const { payload, type } = action;
    switch (type) {
        // TODO. handle related actions
        default:
            return state;
    }
}

/**
 * Store input information for paid Amount of invoice
 */
const paidAmount = (state = {}, action) => {
    const { payload, type } = action;
    switch (type) {
        // TODO. handle related actions
        default:
            return state;
    }
}

const paymentType = (state = 'cash', action) => {
    const { type, payload } = action;
    switch (type) {
        case C.CHANGE_PAYMENT_TYPE:
            return payload;
        case C.RESET_DATA_ALL:
            return 'cash';
        default:
            return state;
    }
}

const transferFiles = (state = [], action) => {
    const { type, payload } = action;
    switch (type) {
        case C.COPY_FILES_TO_RAW_DATA:
            return payload;
        case C.ADD_TRANSFER_FILE: {
            const stateClone = _.cloneDeep(state);
            stateClone.push(payload);
            return stateClone;
        }
        case C.REMOVE_TRANSFER_FILE:
            return state.filter(file => file.id !== payload);
        case C.EMPTY_FILES_IN_RAW_DATA:
            return [];
        case C.RESET_DATA_ALL:
            return [];
        default:
            return state;
    }
}

const receipt = (state = {}, actions) => {
    const { type, payload } = actions;
    switch (type) {
        case C.CREATE_RECEIPT:
            return payload;
        case C.CHANGE_RECEIPT_VALUE:
            const { key, value } = payload;
            let dic = _.cloneDeep(state);
            dic[key] = value;
            return dic;
        case C.EMPTY_RECEIPT:
            return {}
        case C.RESET_DATA_ALL:
            return {};
        default:
            return state;
    }
}

const payAmount = (state = 0, action) => {
    const { type, payload } = action;
    switch (type) {
        case C.CHANGE_PAY_AMOUNT_VALUE:
            return payload;
        case C.RESET_DATA_ALL:
            return 0;
        default:
            return state;
    }
}

const success = (state = false, action) => {
    const { type, payload } = action;
    switch (type) {
        case C.SAVE_SUCCESS:
            return payload;
        case C.RESET_DATA_ALL:
            return false;
        default:
            return state;
    }
}

const activeScreen = (state = false, action) => {
    const {type, payload} = action;
    switch(type) {
        case C.ACTIVE_SCREEN_STATUS:
            return payload;
        default:
            return state;
    }
}

export const editInvoiceSupplierDomestic = combineReducers({
    invoiceDetail,
    typeInvoice,
    supplier,

    paymentType,

    fetchingSelectableCreditDebitNotes,
    selectableCreditDebits,

    fetchingPurchaseOrders,
    listPurchaseOrderDetail,

    // Converate card, only use for domestic type
    selectableConvertCurrencies,
    selectedConvertCurrency,
    convertRate,

    paidAmount,
    transferFiles,
    payAmount,
    receipt,
    success,
    activeScreen,
});