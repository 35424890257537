import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import stockInActions from '../../../actions/stockInActions';
import orderActions from '../../../actions/orderActions';
import purchaseOrderActions from '../../../actions/purchaseOrderActions';
import supplierActions from '../../../actions/supplierActions';

import _ from 'lodash';
import I18n from 'i18n-js';
import { PURCHASE_STATUS, PURCHASE_TYPE } from '../../../constants/constants';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
const {
  NEW,
  DRAFT,
  USE_STOCK,
  WAITING_FOR_APPROVAL,
  APPROVED,
  IN_PROGRESS,
  IN_TRANSIT,
  IN_PORT,
  WAITING_FOR_SHIPPING,
  IN_WAREHOUSE,
  COMPLETED,
} = PURCHASE_STATUS;

const { RESERVED, UNRESERVED } = PURCHASE_TYPE;

class StockInSupplier extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      reserved: true,
      item: {},
      orders: [],
      suppliers: [],
      purchases: [],
      errors: {},
    };
  }

  componentWillMount() {
    this.getListOrder();
    this.getListSupplier();
  }

  componentWillReceiveProps(newProps) {
    let { orders, purchases, suppliers } = this.state;
    !_.isEqual(orders, newProps.orderState.items) &&
      this.setState({ orders: _.cloneDeep(newProps.orderState.items) || [] });
    !_.isEqual(purchases, newProps.purchaseOrderState.items) &&
      this.setState({ purchases: _.cloneDeep(newProps.purchaseOrderState.items) || [] });
    !_.isEqual(suppliers, newProps.supplierState.items) &&
      this.setState({ suppliers: _.cloneDeep(newProps.supplierState.items) || [] });
  }

  getListOrder(options = {}) {
    let query = {};
    options.populate = 'styleOrders';

    this.props.orderPropsActions.getListCustomer(_.assign(query, options));
  }
  getListSupplier(options = {}) {
    this.props.supplierActions.getListSupplier(options);
  }

  getListPurchase(query = {}) {
    this.props.purchaseOrderPropsActions.getListPurchasesInbound(query);
  }

  onChange(property, value) {
    let { item } = this.state;

    switch (property) {
      case 'orderId':
        this.setState({ item: { orderId: value }, purchases: [] });
        break;
      case 'styleId':
        this.setState({ item: _.set(_.pick(item, ['orderId', 'styleId']), property, value), purchases: [] });
        value && this.getListPurchase({ styleId: value });
        break;
      case 'supplierId':
        this.setState({ item: { supplierId: value }, purchases: [] });
        value && this.getListPurchase({ supplierId: value });
        break;
      default:
        this.setState({ item: _.set(item, property, value) });
    }
    this.validateChange(property, value);
  }

  changeReverse(reserved) {
    this.setState({ reserved, suppliers: [], purchases: [], errors: {}, item: {} });
    !reserved && this.getListSupplier({});
  }

  close() {
    this.props.closeModal();
  }

  validateChange(property, value) {
    let { errors, reserved } = this.state;
    let fields = reserved
      ? { orderId: 'Customer PO', styleId: 'Style', purchaseId: 'Purchase Order No' }
      : { purchaseId: 'Purchase Order No' };
    delete errors[property];
    if (!value) {
      errors[property] = `Please select ${fields[property]}`;
    }
    this.setState({ errors });
  }

  validate() {
    let { item, errors, reserved } = this.state;
    let fields = reserved
      ? { orderId: 'Customer PO', styleId: 'Style', purchaseId: 'Purchase Order No' }
      : { purchaseId: 'Purchase Order No' };

    Object.keys(fields).forEach(f => {
      _.set(errors, f, !item[f] ? `Please select ${fields[f]}` : '');
    });
    this.setState({ errors });

    let flag = Object.keys(errors).every(k => !errors[k]);

    return flag;
  }

  save() {
    let { item, purchases, orders, reserved } = this.state;
    const { openFormAdd } = this.props;
    let purchase = purchases.find(i => i._id === item.purchaseId);

    let purchaseData = purchases.filter(item => {
      if (reserved) {
        return item.type === RESERVED && [NEW, IN_PROGRESS, IN_TRANSIT, IN_PORT].includes(item.status);
      } else {
        return item.type === UNRESERVED && [NEW, IN_PROGRESS, IN_TRANSIT, IN_PORT, IN_WAREHOUSE].includes(item.status);
      }
    });

    let order = orders.find(i => i._id === item.orderId) || {};

    let data = _.cloneDeep(item);
    data.order = order;
    data.purchase = purchase;
    data.purchases = purchaseData || [];

    if (this.validate()) {
      // open form add
      _.isFunction(openFormAdd) && openFormAdd('supplier', data);

      // close modal add
      this.props.closeModal();
    }
  }

  render() {
    let { orders, purchases, suppliers, reserved, item, errors } = this.state;

    let orderSelected = (orders || []).find(i => i._id === item.orderId) || {};
    let styles = orderSelected.styleOrders || [];

    return (
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <form>
            <div className="modal-header">
              <a className="close" onClick={() => this.close()}>
                <span aria-hidden="true">&times;</span>
              </a>
              <h4 className="modal-title" id="myModalLabel">
                {I18n.t('PageWareHouse.ADDINBOUND')}
              </h4>
            </div>

            {!!reserved && (
              <div className="modal-body">
                <div className="form-group">
                  <div className="col-md-4 col-xs-12" />
                  <div className="col-md-8 col-xs-12 style-checkbox">
                    <input
                      type="checkbox"
                      id="stock_reserved"
                      checked={!!reserved}
                      onChange={evt => this.changeReverse(evt.target.checked)}
                      value
                    />
                    <label htmlFor="stock_reserved">
                      <span>
                        <span />
                      </span>{' '}
                      Reserved
                    </label>
                  </div>
                </div>

                <div className="form-group">
                  <div className="col-md-4 col-xs-12 text">
                    <p>{I18n.t('PageWareHouse.CustomerPO')}</p> <span className="color-red"> *</span>
                  </div>
                  <div className="col-md-8 col-xs-12 value">
                    <Select
                      value={item.orderId}
                      options={orders.map(o => ({ value: o._id, label: o.customerPO }))}
                      onChange={evt => this.onChange('orderId', evt.value)}
                    />
                    {errors['orderId'] && <div className="alert-warning">{errors['orderId']}</div>}
                  </div>
                </div>

                <div className="form-group">
                  <div className="col-md-4 col-xs-12 text">
                    <p>{I18n.t('PageWareHouse.Style')}</p> <span className="color-red"> *</span>
                  </div>
                  <div className="col-md-8 col-xs-12 value">
                    <Select
                      value={item.styleId}
                      options={styles.map(s => ({ value: s._id, label: s.style }))}
                      onChange={evt => this.onChange('styleId', evt.value)}
                    />
                    {errors['styleId'] && <div className="alert-warning">{errors['styleId']}</div>}
                  </div>
                </div>

                <div className="form-group">
                  <div className="col-md-4 col-xs-12 text">
                    <p>{I18n.t('PageWareHouse.PurchaseOrderNo')}</p> <span className="color-red"> *</span>
                  </div>
                  <div className="col-md-8 col-xs-12 value">
                    <Select
                      value={item.purchaseId}
                      onChange={evt => this.onChange('purchaseId', evt.value)}
                      options={purchases.reduce((x, y) => {
                        if (
                          (y.type !== UNRESERVED && y.status == WAITING_FOR_SHIPPING) ||
                          (y.type !== UNRESERVED &&
                            ![WAITING_FOR_APPROVAL, DRAFT, APPROVED, COMPLETED, USE_STOCK].includes(y.status))
                        ) {
                          x.push({ value: y._id, label: y.purchaseNo });
                        }
                        return x;
                      }, [])}
                    />
                    {errors['purchaseId'] && <div className="alert-warning">{errors['purchaseId']}</div>}
                  </div>
                </div>
              </div>
            )}

            {!reserved && (
              <div className="modal-body">
                <div className="form-group">
                  <div className="col-md-4 col-xs-12" />
                  <div className="col-md-8 col-xs-12 style-checkbox pd-0">
                    <input
                      type="checkbox"
                      id="stock_reserved"
                      checked={!!reserved}
                      onChange={evt => this.changeReverse(evt.target.checked)}
                      value
                    />
                    <label htmlFor="stock_reserved">
                      <span>
                        <span />
                      </span>{' '}
                      Reserved
                    </label>
                  </div>
                </div>
                <div className="form-group">
                  <div className="col-md-4 col-xs-12 text">
                    <p>{I18n.t('PageWareHouse.Supplier')}</p> <span className="color-red"> *</span>
                  </div>
                  <div className="col-md-8 col-xs-12 value">
                    <Select
                      value={item.supplierId}
                      options={suppliers.map(s => ({ value: s._id, label: s.name }))}
                      onChange={evt => this.onChange('supplierId', evt.value)}
                    />
                    {errors['supplierId'] && <div className="alert-warning">{errors['supplierId']}</div>}
                  </div>
                </div>
                <div className="form-group">
                  <div className="col-md-4 col-xs-12 text">
                    <p>{I18n.t('PageWareHouse.PurchaseOrderNo')}</p>
                  </div>
                  <div className="col-md-8 col-xs-12 value">
                    <Select
                      value={item.purchaseId}
                      onChange={evt => this.onChange('purchaseId', evt.value)}
                      options={purchases.reduce((x, y) => {
                        if (
                          y.type === UNRESERVED &&
                          [NEW, IN_PROGRESS, IN_TRANSIT, IN_PORT, IN_WAREHOUSE, WAITING_FOR_SHIPPING].includes(y.status)
                        ) {
                          x.push({ value: y._id, label: y.purchaseNo });
                        }
                        return x;
                      }, [])}
                    />
                    {errors['purchaseId'] && <div className="alert-warning">{errors['purchaseId']}</div>}
                  </div>
                </div>
              </div>
            )}

            <div className="modal-footer">
              <button type="button" className="bt-black" onClick={() => this.close()}>
                {I18n.t('Btn.CANCEL')}
              </button>
              <button type="button" className="bt-orange mg-l-5" onClick={() => this.save()}>
                {I18n.t('Btn.SAVE')}
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    authState: state.authState,
    stockInState: state.stockInState,
    orderState: state.orderState,
    purchaseOrderState: state.purchaseOrderState,
    supplierState: state.supplierState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    stockInPropsActions: bindActionCreators(stockInActions, dispatch),
    orderPropsActions: bindActionCreators(orderActions, dispatch),
    purchaseOrderPropsActions: bindActionCreators(purchaseOrderActions, dispatch),
    supplierActions: bindActionCreators(supplierActions, dispatch),
  };
}

StockInSupplier.propTypes = {
  authState: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(StockInSupplier);
