import React, {Component} from 'react';
import I18n from 'i18n-js';

export default class AlertSuccess extends Component {
  render() {
    let data = this.props.data;
    let hideAlert = this.props.hideAlert;
    let func = this.props.func;
    func = func ? func : () => {
    };
    return (
      <div className="">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <form>
              <div className="modal-header">
                <h4 className="modal-title" id="myModalLabel">{I18n.t('Success')}</h4>
              </div>
              <div className="modal-body">
                {data}
              </div>
              <div className="modal-footer">
                <button onClick={() => {
                  func();
                  hideAlert();
                  this.props.closeModal();
                }} type="button" className="bt-black" data-dismiss="modal">CLOSE
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
