import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import * as R from 'ramda';

import OrderInfoBar from '../../../components/OrderInfoBar';
import StyleItemList from '../../../components/customer-po/StyleItemList';
import CutTicketItemList from '../../../components/customer-po/CutTicketItemList';

import CreateStyleModal from './CreateStyleModal';
import EditStyleModal from './EditStyleModal';
import DeleteStyleModal from './DeleteStyleModal';
import CreateCutTicketModal from './CreateCutTicketModal';
import EditCutTicketModal from './EditCutTicketModal';
import EditCutTicketImagesModal from './EditCutTicketImagesModal';
import DeleteCutTicketModal from './DeleteCutTicketModal';
import EditCutCutTicketColorsModal from './EditCutTicketColorModal';
import ImagesGalleryModal from './ImagesGalleryModal';

import { isShowing as isShowCreateStyleModal } from '../../../../selectors/customer-po/current/createStyle';
import { isShowing as isShowEditStyleModal } from '../../../../selectors/customer-po/current/editStyle';
import { isShowing as isShowEditCutTicketImagesModal } from '../../../../selectors/customer-po/current/editCutTicketImages';
import { isOpen as isShowDeleteStyleModal } from '../../../../selectors/customer-po/current/deleteStyle';
import { isOpen as isShowCreateCutTicketModal } from '../../../../selectors/customer-po/current/createCutTicket';
import { isOpen as isShowEditCutTicketModal } from '../../../../selectors/customer-po/current/editCutTicket';
import { isOpen as isShowDeleteCutTicketModal } from '../../../../selectors/customer-po/current/deleteCutTicket';
import { isOpen as isShowEditCutTicketColorsModal } from '../../../../selectors/customer-po/current/editCutTicketColors';
import { isOpen as isShowImagesGalleryModal } from '../../../../selectors/customer-po/current/showImagesGallery';

import { showModal as showCreateStyleModal } from '../../../../actions/customer-po/current/createStyle';
import { showModal as showEditStyleModal } from '../../../../actions/customer-po/current/editStyle';
import { showModal as showDeleteStyleModal } from '../../../../actions/customer-po/current/deleteStyle';
import { showModal as showCreateCutTicketModal } from '../../../../actions/customer-po/current/createCutTicket';
import { showModal as showEditCutTicketModal } from '../../../../actions/customer-po/current/editCutTicket';
import { showModal as showEditCutTicketImagesModal } from '../../../../actions/customer-po/current/editCutTicketImages';
import { showModal as showDeleteCutTicketModal } from '../../../../actions/customer-po/current/deleteCutTicket';
import { showModal as showEditCutTicketColorsModal } from '../../../../actions/customer-po/current/editCutTicketColors';
import { showModal as showImagesGalleryModal } from '../../../../actions/customer-po/current/showImagesGallery';

import * as actions from '../../../../actions/customer-po/current/showOrderDetail';
import * as selectors from '../../../../selectors/customer-po/current/showOrderDetail';
import * as orderSelectors from '../../../../selectors/customer-po/current/showListOrders';

class OrderDetail extends Component {
  render() {
    return (
      <div className="content-main">
        <OrderInfoBar
          showNotify
          showShipped
          {...this.props}
          sendNotify={() => this.props.sendEmailNotify(this.props.id)}
        />

        <div className="md content-main-info">
          <StyleItemList
            items={this.props.styles}
            canAddStyle={this.props.canAdd}
            canEditStyle={this.props.canEdit}
            canDeleteStyle={this.props.canDelete}
            onItemClick={(item) => this.props.changeSelectedStyle(item)}
            onAddStyleClick={() => this.props.showAddStyleModal(this.props.id)}
            onEditItem={(item) => this.props.showEditStyleModal(item)}
            onDeleteItem={(item) => this.props.showDeleteStyleModal(item)}
            openImageGallery={(item) => this.props.showImagesGallery(R.path(['raw', 'images'], item))}
          />

          <CutTicketItemList
            items={this.props.cutTickets}
            canAdd={this.props.canAdd}
            canEdit={this.props.canEdit}
            canDelete={this.props.canDelete}
            onAddCutTicket={() => this.props.showAddCutTicketModal(this.props.selectedStyle)}
            onEditItemClick={(item) => this.props.showEditCutTicketModal(item)}
            onEditItemImages={(item) => this.props.showEditCutTicketImagesModal(item)}
            onDeleteItemClick={(item) => this.props.showDeleteCutTicketModal(item)}
            onAddCutTicketColor={(item) => this.props.showEditCutTicketColorsModal(item)}
            onEditCutTicketColor={(item) => this.props.showEditCutTicketColorsModal(item)}
            openImageGallery={(item) => this.props.showImagesGallery(R.path(['raw', 'images'], item))}
          />
        </div>
        
        {this.props.isShowCreateStyleModal &&
          <CreateStyleModal />
        }

        {this.props.isShowEditStyleModal &&
          <EditStyleModal />
        }

        {this.props.isShowDeleteStyleModal &&
          <DeleteStyleModal />
        }

        {this.props.isShowCreateCutTicketModal &&
          <CreateCutTicketModal
            selectableWarehouses={this.props.selectableWarehouses}
          />
        }

        {this.props.isShowEditCutTicketModal && 
          <EditCutTicketModal />
        }

        {this.props.isShowEditCutTicketImagesModal &&
          <EditCutTicketImagesModal />
        }
        
        {this.props.isShowDeleteCutTicketModal && 
          <DeleteCutTicketModal />
        }

        {this.props.isShowEditCutTicketColorsModal &&
          <EditCutCutTicketColorsModal
            canRemove={this.props.canEdit}
          />
        }

        {this.props.isShowImagesGalleryModal &&
          <ImagesGalleryModal />
        }

      </div>
    )
  }
}

const mapStateToProps = state => ({
  id: selectors.getOrderId(state),
  orderDate: selectors.getOrderDate(state),
  customer: selectors.getCustomer(state),
  customerPO: selectors.getCustomerPO(state),
  internalPO: selectors.getInternalPO(state),
  exFactory: selectors.getExFactory(state),
  ihDate: selectors.getIhDate(state),
  gTotal: selectors.getGTotal(state),
  totalShipped: selectors.getShipped(state),

  isNotifying: selectors.isNotifying(state),

  selectedStyle: selectors.getSelectedStyle(state),
  selectableWarehouses: selectors.getSelectableWarehouses(state),

  styles: selectors.getStyles(state),
  cutTickets: selectors.getCutTickets(state),
  canEdit: orderSelectors.canEditOrder(state),
  canAdd: orderSelectors.canAddOrder(state),
  canDelete: orderSelectors.canDeleteOrder(state),

  isShowCreateStyleModal: isShowCreateStyleModal(state),
  isShowEditStyleModal: isShowEditStyleModal(state),
  isShowDeleteStyleModal: isShowDeleteStyleModal(state),
  isShowCreateCutTicketModal: isShowCreateCutTicketModal(state),
  isShowEditCutTicketModal: isShowEditCutTicketModal(state),
  isShowEditCutTicketImagesModal: isShowEditCutTicketImagesModal(state),
  isShowDeleteCutTicketModal: isShowDeleteCutTicketModal(state),
  isShowEditCutTicketColorsModal: isShowEditCutTicketColorsModal(state),
  isShowImagesGalleryModal: isShowImagesGalleryModal(state),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  changeSelectedStyle(item) {
    dispatch(actions.changeSelectedStyle(R.path(['raw'], item)));
  },

  showAddStyleModal(orderId) {
    dispatch(showCreateStyleModal(orderId));
  },

  showEditStyleModal(item) {
    dispatch(showEditStyleModal(R.path(['raw'], item)));
  },

  showDeleteStyleModal(item) {
    dispatch(showDeleteStyleModal(R.path(['raw'], item)));
  },

  showAddCutTicketModal(style, item) {
    // console.log(this)
    dispatch(showCreateCutTicketModal(style, this.ihDate));
  },

  showEditCutTicketModal(item) {
    dispatch(showEditCutTicketModal(R.path(['raw'], item)));
  },

  showEditCutTicketImagesModal(item) {
    dispatch(showEditCutTicketImagesModal(R.path(['raw'], item)))
  },

  showDeleteCutTicketModal(item) {
    dispatch(showDeleteCutTicketModal(R.path(['raw'], item)));
  },

  showEditCutTicketColorsModal(item) {
    dispatch(showEditCutTicketColorsModal(R.path(['raw'], item)));
  },

  showImagesGallery(images) {
    dispatch(showImagesGalleryModal(images));
  },

  sendEmailNotify(id) {
    dispatch(actions.sendNotify(id));
  },

});

export default connect(mapStateToProps, mapDispatchToProps) (withRouter(OrderDetail));
