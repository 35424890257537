import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import BaseComponent from '../../base/BaseComponent';
import TableList from '../../common/datatables/TableList';
import ModalConfirm from '../../common/modals/ModalConfirm';
import appActions from '../../../actions/appActions';
import stockInActions from '../../../actions/stockInActions';
import {Pagination} from 'react-bootstrap';
import SearchBar from '../partial/SearchBar';
import _ from 'lodash';
import moment from 'moment';
import I18n from 'i18n-js';
import {PURCHASE_STATUS, SEARCH_BAR_EVENT, SEARCH_KEY, SEARCH_LIST} from "../../../constants/constants";
import { Switch, Route, withRouter, Redirect } from 'react-router-dom';
import * as PATH from '../../../constants/routeConstants';
import ListAndActions from "../../common/datatables/ListAndActions";
import * as utils from "../../../functions/utils";
class TabSupplier extends BaseComponent {
    constructor (props, context) {
        super(props, context);

        this.state = {
            pageSize: 10,
            pageNumber: 1,
            headers: [
                {name: I18n.t('PageWareHouse.Date'), value: 'date', type: 'date', dataType: 'MMM DD, YYYY', format: 'MMM DD, YYYY'},
                {name: I18n.t('PageWareHouse.CustomerPO'), type: 'text', dataType: 'text', value: '{{data}}.order.customerPO'},
                {name: I18n.t('PageWareHouse.Style'), type: 'text', dataType: 'text', value: '{{data}}.style.style'},
                {name: I18n.t('PageWareHouse.PurchaseOrderNo'), type: 'text', dataType: 'text', value: '{{data}}.purchase.purchaseNo'},
                {name: I18n.t('PageWareHouse.Supplier'), type: 'text', dataType: 'text', value: '{{data}}.purchase.supplier.name'},
                {name: I18n.t('PageWareHouse.IsOkToPay'), type: 'checkbox', dataType: 'checkbox', value: 'isReadyToPay'},
            ],
            actions: [
                (item) => {
                    return {type: 'edit', check: 'status', value: 'Approved', click: (item) => this.editItem(item)};
                },
                (item) => {
                    return {type: 'delete', check: 'status', value: 'Approved', click: (item) => this.showDeleteConfirm(item)};
                }
            ],
            items: [],
            total: 0,
            searchValue: {},
            search: {key: SEARCH_KEY.CUSTOMER_PO, value: ""}
        };

        this.onDeleteStockIn = this.onDeleteStockIn.bind(this);
    }

    componentWillMount () {
        this.getListItem();
    }

    componentWillReceiveProps (newProps) {
        let {items, searchValue} = this.state;
        let newItems = _.get(newProps.stockInState, 'supplier.items') || [];
        let total = _.get(newProps.stockInState, 'supplier.count') || 0;

        !_.isEqual(items, newItems) && this.setState({items: newItems, total});
    }

    componentWillUpdate (nextProps, nextState) {
        if(_.get(this.props.stockInState, 'supplier.reload') != _.get(nextProps.stockInState, 'supplier.reload')) {
            this.props.modal.closeModal();
            this.getListItem()
        }
    }


    getListItem (query = {}) {
        let {pageSize, pageNumber} = this.state;
        let _query = _.assign({pageSize: pageSize, pageNumber: pageNumber, populate: 'orderId,styleId,purchaseId'}, query);
        this.props.stockInActions.getListItem('supplier', _.set(_query, 'sort', {createdAt: -1}));
    }

    parseSearchQuery (search) {
        let value = search.search;
        let query = {};

        if (!value || _.isEmpty(value)) { return {}; }

        switch (search.field) {
            case 'date':
                query = {date: {$gt: moment(value).startOf('day').toISOString(), $lt: moment(value).endOf('day').toISOString()}};
                break;
            case 'customerPO':
                query = {order: {customerPO: `@@${value}`}};
                break;
            case 'style':
                query = {style: {style: `@@${value}`}};
                break;
            case 'purchaseNo':
                query = {purchase: {purchaseNo: `@@${value}`}};
                break;
            case 'supplier':
                query = {'purchase.supplier': {name: `@@${value}`}};
                break;
            default:
                query = {$or: [
                        {'order.customerPO': `@@${value}`},
                        {'style.style': `@@${value}`},
                        {'purchase.purchaseNo': `@@${value}`},
                        {'purchase.supplier.name': `@@${value}`}
                    ]};
        }

        return query;
    }

    selectPage (page) {
        this.setState({pageNumber: page});
        this.search(this.state.search, page, this.state.pageSize);
    }

    showDeleteConfirm(item) {
        let {openModal, closeModal} = this.props.modal;
        let modal = (<ModalConfirm
            title={I18n.t('PageWareHouse.DELETEOUTBOUND-FROMSUPPLIER')}
            confirmMessage={I18n.t('PageWareHouse.ConfirmDeleteStockInSupplier')}
            messageOk={I18n.t('Btn.YES')}
            messageCancel={I18n.t('Btn.CANCEL')}
            handleClose={closeModal}
            handleOk={this.onDeleteStockIn.bind(null, item)}/>);
        openModal(modal);
    }

    onDeleteStockIn(item) {
        item._id && this.props.stockInActions.deleteItem('supplier', item._id, {purchaseType: _.get(item, 'purchase.type', '')});
    }

    editItem (item) {
        this.props.stockInActions.setDetail('supplier', item);
        this.props.appPropsActions.setDataPageCurrent({context: {tabSub: 'supplier'}});
        this.props.history.push(PATH.WAREHOUSE_INBOUND_EDIT_SUPPLIER_URL)
    }

    onChangeSearch(property, value) {
        switch(property) {
            case SEARCH_BAR_EVENT.FILTER: {
                this.setState({search: {key: value, value: ""}});
                break;
            }
            case SEARCH_BAR_EVENT.SEARCH: {
                this.setState({search: {...this.state.search, value: value}});
                break;
            }
        }
    }

    onSearch(){
        const {search, pageNumber, pageSize} = this.state;
        this.search(search, pageNumber, pageSize);
    }

    search(search, pageNumber, pageSize) {
        let options = {};
        const type = "supplier";
        const populate = 'orderId,styleId,purchaseId';
        if(search && search.key && search.value) {
            switch(search.key) {
                case SEARCH_KEY.CUSTOMER_PO: {
                    options = {
                        projection: "_id",
                        query: JSON.stringify({customerPO: {$regex: '.*' + search.value + '.*', $options: 'i'}})
                    };
                    this.props.stockInActions.searchByCustomerPO(type, options, pageNumber, pageSize, populate).then(res => {
                        if(res && res.pageNumber && res.pageNumber == 1) {
                            this.setState({pageNumber: 1});
                        }
                    });
                    break;
                }
                case SEARCH_KEY.STYLE: {
                    options = {
                        projection: "_id",
                        query: JSON.stringify({style: {$regex: '.*' + search.value + '.*', $options: 'i'}})
                    };
                    this.props.stockInActions.searchByStyle(type, options, pageNumber, pageSize, populate).then(res => {
                        if(res && res.pageNumber && res.pageNumber == 1) {
                            this.setState({pageNumber: 1});
                        }
                    });
                    break;
                }
                case SEARCH_KEY.PURCHASE_ORDER_NO: {
                    const options = {
                        projection: "_id",
                        query: JSON.stringify({purchaseNo: {$regex: '.*' + search.value + '.*', $options: 'i'}})
                    };
                    this.props.stockInActions.searchByPurchaseOrderNo(type, options, pageNumber, pageSize, populate).then(res => {
                        if(res && res.pageNumber && res.pageNumber == 1) {
                            this.setState({pageNumber: 1});
                        }
                    });
                    break;
                }
                case SEARCH_KEY.SUPPLIER: {
                    const options = {
                        projection: "_id",
                        query: {name: {$regex: '.*' + search.value + '.*', $options: 'i'}}
                    };
                    this.props.stockInActions.searchBySupplier(type, options, pageNumber, pageSize, populate).then(res => {
                        if(res && res.pageNumber && res.pageNumber == 1) {
                            this.setState({pageNumber: 1});
                        }
                    });
                    break;
                }
            }
        }
        if(!search.value) {
            let options = {
                pageNumber,
                pageSize,
                sort: {updatedAt: -1},
                populate: populate,
            };
            this.props.stockInActions.getListItem(type, options).then(res => {
                if(res && res.success && res.data && res.data.items && !res.data.items.length && pageNumber > 1) {
                    options = {
                        pageNumber:1,
                        pageSize,
                        sort: {updatedAt: -1},
                        populate: populate,
                    };
                    this.setState({pageNumber: 1});
                    this.props.stockInActions.getListItem(type, options);
                }
            });
        }
    }

    setPage = (pageNumber) => {
        const {search, pageSize} = this.state;
        this.setState({pageNumber});
        this.search(search, pageNumber, pageSize);
    }

    render () {
        let {items, total, headers, pageNumber, pageSize} = this.state;
        let { showCreate, showEdit, showDelete, showView } = utils.getRoleByAuthState(this.props.authState, 'warehouse');
        if(total != 0){
        }
        let totalPage = _.ceil(total / pageSize);
        let {active} = this.props;
        const actions = [];
        if(showEdit || showView){
            actions.push((data, user) => {
                return { type: 'edit', func: (item) => this.editItem(item), isActive: true, toolTip: 'edit' };
            })
        }
        if(showDelete){
            actions.push((data, user) => {
                return { type: 'delete', func: (item) => this.showDeleteConfirm(item), isActive: true, toolTip: 'delete' };
            })
        }
        return (
            <div role="tabpanel" className='tab-pane active' id="from_supplier">
                <SearchBar
                    name={I18n.t('PageWareHouse.Inbound')}
                    addItem={() => this.props.addItem()}
                    fields={SEARCH_LIST.WAREHOUSE_INBOUND_SUPPLIER}
                    onChangeSearch={(type, data) => this.onChangeSearch(type, data)}
                    onSearch={() => this.onSearch()}
                    className="warehouse-supplier"
                    showCreate={showCreate}
                />

                <ListAndActions {...this.props} options={{
                    statusNotViewDeleteOkToPay : true,
                    textTotal: 'inbound',
                    setPage: this.setPage,
                    totalPage: total,
                    pageNumber: pageNumber,
                    perPage: pageSize,
                    data: items,
                    headers,
                    actions,
                    setSortPage: ()=>{},
                    className: 'center'
                }}/>
            </div>
        );
    }
}

function mapStateToProps (state, ownProps) {
    return {
        authState: state.authState,
        stockInState: state.stockInState
    };
}

function mapDispatchToProps (dispatch) {
    return {
        appPropsActions: bindActionCreators(appActions, dispatch),
        stockInActions: bindActionCreators(stockInActions, dispatch),

    };
}

TabSupplier.propTypes = {
    authState: PropTypes.object.isRequired,
    addItem: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TabSupplier));

