import * as types from '../constants/actionTypes';
import {showLoading, hideLoading} from './commonUseActions';
import styleServices from '../services/styleServices';

function getListStyle (orderId, options = {}) {
  return (dispatch) => {
    showLoading(types, dispatch);

    styleServices.getListStyle(orderId, options).then((res) => {
      hideLoading(types, dispatch);

      res && res.success && dispatch({
        type: types.STYLE.GET_LIST_SUCCESS,
        data: _.get(res, 'data', {})
      });
    }, (error) => {
      hideLoading(types, dispatch);
    });
  };
}

function getListStyleAssignment (orderId, options = {}) {
  return (dispatch) => {
    showLoading(types, dispatch);

    styleServices.getAllStylesAssignment(orderId, options).then((res) => {
      hideLoading(types, dispatch);

      res && res.success && dispatch({
        type: types.STYLE.GET_LIST_SUCCESS,
        data: _.get(res, 'data', {})
      });
    }, (error) => {
      hideLoading(types, dispatch);
    });
  };
}

function getDetail (id, reset = true) {
  return (dispatch) => {
    showLoading(types, dispatch);

    reset && dispatch({
      type: types.STYLE.RESET_DETAIL,
      data: {}
    });

    styleServices.getDetail(id, {}).then((res) => {
      hideLoading(types, dispatch);

      if (res) {
        dispatch({
          type: types.STYLE.GET_DETAIL_SUCCESS,
          data: res.data || {}
        });
      }
    }, (error) => {
      hideLoading(types, dispatch);
    });
  };
}

function deleteStyle (id) {
  return (dispatch) => {
    showLoading(types, dispatch);

    styleServices.deleteStyle(id).then((res) => {
      hideLoading(types, dispatch);

      if (res) {
        dispatch({
          type: types.STYLE.RELOAD,
          data: true
        });
      }
    }, (error) => {
      hideLoading(types, dispatch, error);
    });
  };
}

function editStyle(id, data) {
  return (dispatch) => {
    showLoading(types, dispatch);

    styleServices.editStyle(id, data).then((res) => {
      hideLoading(types, dispatch);

      if (res) {
        dispatch({
          type: types.STYLE.RELOAD,
          data: true
        });
      }
    }, (error) => {
      hideLoading(types, dispatch, error);
    });
  };
}

function addStyle(orderId, data) {
  return (dispatch) => {
    showLoading(types, dispatch);

    styleServices.addStyle(orderId, data).then((res) => {
      hideLoading(types, dispatch);
      if (res) {
        dispatch({
          type: types.STYLE.ADD_STYLE_SUCCESS,
          data: res.data
        });
      }
    }, (error) => {
      hideLoading(types, dispatch, error);
    });
  };
}

export default {
  getListStyle,
  deleteStyle,
  editStyle,
  addStyle,
  getDetail
};
