/**
 * @author hienhc at nexlesoft.com
 * @create date 2018-10-29 09:36:46
 * @desc Edit packing standard view
 */

import React from "react";
import PropTypes from "prop-types";
import I18n from "i18n-js";
import * as R from "ramda";
import _ from "lodash";

import IC_ADD_TRIM from "../../../../stylesheets/images/add-new.svg";
import IC_REMOVE_IMG from "../../../../stylesheets/images/ic-x-hover.svg";
import IMG_DEFAULT from "../../../../stylesheets/images/drag-photo.svg";
import IMG_REMOVE from "../../../../stylesheets/images/ic-x.svg";

const EditPackingStandard = props => (
  <div id="content">
    <div id="packing-standard-add" className="pd-5">
      <div className="wrap-title pd-0 bd-solid-bottom-2 float-left inline-block w-full">
        <div className="col-md-4 col-sm-6 col-xs-12 input">
          <ul className="list-inline nav navbar-nav">
            <li>
              <span> {I18n.t("PageStandard.PackingName")}</span>
            </li>
            <li>
              <input
                onChange={evt => {
                  props.onChangeName(evt.target.value);
                }}
                type="text"
                placeholder=""
                value={props.name}
                className="form-control"
              />
              {R.path(["name"], props.errors) && (
                <div className="alert-warning">
                  {R.path(["name"], props.errors)}
                </div>
              )}
            </li>
          </ul>
        </div>

        <div className="col-md-4 col-sm-6 col-xs-12" id="radio-group-inline">
          <ul className="nav nav-tabs wrap-list-radio" role="tablist">
            {_.map(props.checkablePackingTypes, t => (
              <li
                role="presentation"
                className={t.checked ? "active" : ""}
                key={t.value}
              >
                <a role="tab">
                  <input
                    type="radio"
                    id="stock"
                    checked={t.checked}
                    name="stock"
                    onChange={() => props.onChangePackingType(t)}
                  />
                  <label>{t.label}</label>
                  <div className="check">
                    <div className="inside" />
                  </div>
                </a>
              </li>
            ))}
          </ul>
        </div>

        <div className="float-right cursor-pointer">
          <span
            onClick={() => props.onSubmit()}
            className={`btn color-hv-green mg-top-15 mg-right-15 inline-block size-16 ${
              !props.canSave ? "disabled" : ""
            }`}
          >
            <i className="fa fa-floppy-o" aria-hidden="true" />{" "}
            {props.isSubmiting ? I18n.t("Btn.SAVING") : I18n.t("Btn.SAVE")}
          </span>
        </div>
      </div>

      <div className="wrap-content">
        <div id="add-standard-2">
          <div id="add-standard-1">
            <div id="add-standard-col-1">
              <div className="bd-dashed-bottom inline-block pd-bottom-20 w-full">
                <table className="global-table box-shadow-none w-full">
                  <tbody>
                    <tr className="bg-none bd-none">
                      <th className="center">
                        {I18n.t("PageStandard.TrimName")}
                      </th>
                      <th className="center">{I18n.t("PageStandard.Color")}</th>
                      <th className="center">{I18n.t("PageStandard.Size")}</th>
                      <th className="center" />
                    </tr>

                    {_.map(props.trims, trim => {
                      const lastTrim = _.last(props.trims);
                      return (
                        <tr className="bg-none" key={trim.key}>
                          <td className="select">
                            <select
                              className="form-control"
                              value={trim.material}
                              onChange={evt => {
                                const { value } = evt.target;
                                const m = _.find(
                                  trim.selectableMaterials,
                                  it => it.value === value
                                );
                                props.onChangeTrimMaterial(trim, m);
                              }}
                            >
                              <option value="" />
                              {_.map(trim.selectableMaterials, m => (
                                <option key={m.value} value={m.value}>
                                  {m.label}
                                </option>
                              ))}
                            </select>
                          </td>

                          <td className="select">
                            <select
                              className="form-control"
                              value={trim.color}
                              onChange={evt => {
                                const { value } = evt.target;
                                const c = _.find(
                                  trim.selectableColors,
                                  it => it.value === value
                                );
                                props.onChangeTrimColor(trim, c);
                              }}
                            >
                              <option value="" />
                              {_.map(trim.selectableColors, c => (
                                <option key={c.value} value={c.value}>
                                  {c.label}
                                </option>
                              ))}
                            </select>
                          </td>

                          <td className="select">
                            <select
                              className="form-control"
                              value={trim.size}
                              onChange={evt => {
                                const { value } = evt.target;
                                const s = _.find(
                                  trim.selectableSizes,
                                  it => it.value === value
                                );
                                props.onChangeTrimSize(trim, s);
                              }}
                            >
                              <option value="" />
                              {_.map(trim.selectableSizes, size => (
                                <option key={size.value} value={size.value}>
                                  {size.label}
                                </option>
                              ))}
                            </select>
                          </td>

                          {trim === lastTrim && (
                            <td>
                              {props.trims.length > 1 &&
                                <div className="button button-close" onClick={() => { props.onRemoveTrim(trim); }} style={{display: 'inline-block', float: 'left', padding: '0px 15px', marginLeft: '-25px'}} >
                                  <img src={IMG_REMOVE} alt="Close" title="Close" style={{marginTop: 15}} />
                                </div>
                              }
                              <div className="button-add" style={{float: 'left'}}>
                                <span
                                  onClick={() => props.onAddTrim()}
                                  className="cursor-pointer icon-add"
                                >
                                  <img
                                    className="avatar-dropdown img-responsive position-relative top-4"
                                    src={IC_ADD_TRIM}
                                  />
                                </span>
                              </div>
                            </td>
                          )}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>

              <div className="wrap_trim_description">
                <table className="global-table box-shadow-none bd-none w-full">
                  <tbody>
                    <tr className="bg-none bd-none">
                      <th>{I18n.t("PageStandard.TrimDescription")}</th>
                    </tr>
                    <tr>
                      <td>
                        <textarea
                          className="resize-vertical"
                          rows="4"
                          cols="50"
                          value={props.description}
                          onChange={evt => {
                            const { value } = evt.target;
                            props.onChangeDescription(value);
                          }}
                          placeholder="Trim Description"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div id="add-standard-col-2">
              {_.map(
                [ 
                  { label: I18n.t('PageStandard.HoldingWay'), name: "FoldingWay", items: props.foldingWays }, 
                  { label: I18n.t('PageStandard.HangTag'), name: "HangTag", items: props.hangTags },
                  { label: I18n.t('PageStandard.ShippingMark'), name: "ShippingMark", items: props.shippingMarks }
                ], 
                group => (
                <table key={group.name} className="global-table bd-dashed-bottom box-shadow-none w-full">
                  <tbody>
                    <tr className="bg-none bd-none">
                      <th className="pd-left-15">
                        {R.path(['label'], group)}
                      </th>
                      <th />
                      <th />
                    </tr>

                    {_.map(group.items, item => {
                      return (
                        <tr className="bg-none bd-none" key={item.key}>
                          <td className="center">
                            <div className="img">
                              <img
                                src={item.imageUrl || IMG_DEFAULT}
                                onDragOver={evt => evt.preventDefault()}
                                onDrop={e => {
                                  e.preventDefault();
                                  const file = e.dataTransfer
                                    ? e.dataTransfer.files[0]
                                    : e.target.files[0];
                                  if (!file) return;

                                  props[`onAdd${group.name}Image`](item, file);
                                }}
                                onDrag={evt => evt.preventDefault()}
                                onClick={() => {
                                  $(`#img-${group.name}-${item.key}`).click();
                                }}
                                style={{ cursor: "pointer" }}
                                className="pd-10"
                              />
                              {item.imageUrl && (
                                <span
                                  className="icon-x-hover"
                                  onClick={() => {
                                    props[`onRemove${group.name}Image`](item);
                                  }}
                                >
                                  <img src={IC_REMOVE_IMG} />
                                </span>
                              )}
                              <input
                                onChange={e => {
                                  e.preventDefault();
                                  const file = e.dataTransfer
                                    ? e.dataTransfer.files[0]
                                    : e.target.files[0];
                                  if (!file) return;

                                  props[`onAdd${group.name}Image`](item, file);
                                }}
                                style={{ display: "none" }}
                                id={`img-${group.name}-${item.key}`}
                                type="file"
                                name="imgStyle"
                              />
                            </div>
                          </td>
                          <td>
                            <textarea
                              className="resize-vertical"
                              value={item.description}
                              rows="4"
                              cols="50"
                              placeholder={item.placeholder}
                              onChange={e => {
                                const { value } = e.target;
                                props[`onChange${group.name}Description`](item, value);
                              }}
                            />
                          </td>
                          <td />
                          <td style={{position: 'relative'}}>
                            {_.last(group.items) === item && (
                              <div>
                                {group.items.length > 1 && 
                                  <div className="button button-close" onClick={() => { props[`onRemove${group.name}`](item)}} style={{display: 'block', padding: '0px 15px', position: 'absolute', top: 33, left: '-35px'}} >
                                    <img src={IMG_REMOVE} alt="Close" title="Close" style={{width: 15, border: 'none'}} />
                                  </div>
                                }
                                <div className="button-add">
                                  <span
                                    className="cursor-pointer icon-add"
                                    onClick={() => {
                                      props[`onAdd${group.name}`]();
                                    }}
                                  >
                                    <img className="bd-none" src={IC_ADD_TRIM} />
                                  </span>
                                </div>
                              </div>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

EditPackingStandard.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  trims: PropTypes.arrayOf(PropTypes.any).isRequired,
  foldingWays: PropTypes.arrayOf(PropTypes.any).isRequired,
  hangTags: PropTypes.arrayOf(PropTypes.any).isRequired,
  shippingMarks: PropTypes.arrayOf(PropTypes.any).isRequired,
  errors: PropTypes.objectOf(PropTypes.any),
  checkablePackingTypes: PropTypes.arrayOf(PropTypes.any).isRequired,

  isSubmiting: PropTypes.bool,
  canSave: PropTypes.bool,

  onChangeName: PropTypes.func,
  onChangePackingType: PropTypes.func,
  onChangeTrimMaterial: PropTypes.func,
  onChangeTrimColor: PropTypes.func,
  onChangeTrimSize: PropTypes.func,
  onChangeDescription: PropTypes.func,
  onAddTrim: PropTypes.func,
  onRemoveTrim: PropTypes.func,

  onAddFoldingWayImage: PropTypes.func,
  onRemoveFoldingWayImage: PropTypes.func,
  onChangeFoldingWayDescription: PropTypes.func,
  onAddFoldingWay: PropTypes.func,
  onRemoveFoldingWay: PropTypes.func,

  onAddHangTagImage: PropTypes.func,
  onRemoveHangTagImage: PropTypes.func,
  onChangeHangTagDescription: PropTypes.func,
  onAddHangTag: PropTypes.func,
  onRemoveHangTag: PropTypes.func,

  onAddShippingMarkImage: PropTypes.func,
  onRemoveShippingMarkImage: PropTypes.func,
  onChangeShippingMarkDescription: PropTypes.func,
  onAddShippingMark: PropTypes.func,
  onRemoveShippingMark: PropTypes.func,

  onSubmit: PropTypes.func
};

EditPackingStandard.defaultProps = {
  errors: {},
  isSubmiting: false,
  canSave: true,

  onChangeName: f => f,
  onChangePackingType: f => f,
  onChangeTrimMaterial: (t, m) => m,
  onChangeTrimColor: (t, c) => c,
  onChangeTrimSize: (t, s) => s,
  onChangeDescription: f => f,

  onAddFoldingWayImage: (k, v) => v,
  onRemoveFoldingWayImage: (k, v) => v,
  onChangeFoldingWayDescription: (k, v) => v,
  onAddFoldingWay: (k, v) => v,
  onRemoveFoldingWay: k => k,

  onAddHangTagImage: (k, v) => v,
  onRemoveHangTagImage: (k, v) => v,
  onChangeHangTagDescription: (k, v) => v,
  onAddHangTag: (k, v) => v,
  onRemoveHangTag: k => k,

  onAddShippingMarkImage: (k, v) => v,
  onRemoveShippingMarkImage: (k, v) => v,
  onChangeShippingMarkDescription: (k, v) => v,
  onAddShippingMark: (k, v) => v,
  onRemoveShippingMark: k => k,

  onAddTrim: f => f,
  onRemoveTrim: (k) => k,
  onSubmit: f => f
};

export default EditPackingStandard;
