
import { combineReducers } from 'redux';
import C from '../../../constants/customer-po/current/deleteCutTicket';

const cutTicket = (state = null, action) => {
  const { type, payload } = action;
  switch (type) {
    case C.SHOW_MODAL:
      return payload;
    default:
      return state;
  }
}

const submiting = (state = false, action) => {
  const { type } = action;
  switch (type) {
    case C.SUBMIT:
      return true;
    case C.CANCEL_SUBMIT:
      return false;
    default:
      return state;
  }
}

const combined = combineReducers({
  cutTicket,
  submiting,
});

export default (state, action) => {
  const { type } = action;
  if (type === C.RESET_DATA) {
    return {};
  }
  return combined(state, action);
};