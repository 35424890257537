import React from 'react';
import _ from 'lodash';
import I18n from 'i18n-js';
import {textTrim} from '../../../functions/helpers';
import { formatNumber, getQuatity } from '../../../functions/helpers';

export default ({ item, est, fieldName, thName, style = {} }) => {
    const actualYield = _.get(item, 'info.actualYield', undefined);
    const customerYield = _.get(item, 'info.yield', undefined);
    return (
        <div className="content-ticket">
            <div className="info">
                <ul>
                    {/* <li>
                        <h4>{_.get(item, 'info.material.name', '')}</h4>
                        <span className="float-right text">% <span className="number">{_.get(item, 'info.percent', '')}</span></span>
                    </li> */}
                    <li>
                        <h4 style={style}>{_.get(item, 'info.material.name', '')}</h4><br/>
                        <p>{_.get(item, 'info.material.data.content', '')}</p>
                        <div style={{position: 'absolute', top: 0, right: 0, minWidth: 150}}>
                            {customerYield && <span className="float-right text">{I18n.t('PageYield.CustomerYield')} <span className="number">{`${customerYield}  %${_.get(item, 'info.percent', '')}`}</span></span>}<br/>
                            {actualYield && <span className="float-right text">{I18n.t('PageYield.ActualYield')} <span className="number">{`${actualYield}  %${_.get(item, 'info.actualPercent', '')}`}</span></span>}
                        </div>
                    </li>
                </ul>
            </div>

            <table style={{ width: '100%' }}>
                <thead>
                    <tr>
                        <th>{thName}</th>
                        <th>{I18n.t('PageYield.Color')}</th>
                        <th className="quantity">{I18n.t('PageYield.Quantity')} ({_.get(item, 'info.material.data.unit', '')})</th>
                    </tr>
                </thead>
                <tbody>
                    {est.map((i, idx) => {
                        let quantity = _.round((_.get(i, 'color.sizes') || []).reduce((s, item) => s + item.quantity || 0, 0),3);
                        return (
                            <tr key={idx}>
                                <td className="bold">{i[fieldName] || ''}</td>
                                <td className="bold">
                                    <span className="title pd-right-15">{textTrim(_.get(i, 'color.name', ''), 22)}</span>
                                    <span className="number">{formatNumber(quantity)}</span>
                                </td>
                                <td className="quantity">{getQuatity(quantity, _.get(item, 'info.yield', 0), _.get(item, 'info.percent', 0))}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
}