import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import I18n from 'i18n-js';
import BaseComponent from '../../base/BaseComponent';
import fabricActions from '../../../actions/fabricActions';
import ModalFabricForm from '../../common/modals/ModalFabricForm';
import ModalConfirm from '../../common/modals/ModalConfirm';
import TableList from '../../common/datatables/TableList';
import _ from 'lodash';
import SearchBar from "../../common/layout/SearchBar";
import {
    SEARCH_BAR_EVENT,
    SEARCH_LIST,
    FABRIC_SEARCH_KEY,
    FOLLOWUP_SEARCH_KEY,
    PURCHASE_LIST_SEARCH_KEY
} from "../../../constants/constants";
import fabricApi from "../../../api/fabricApi";
import {toastr} from "react-redux-toastr";

import icAddIcon from '../../../stylesheets/images/ic-add.svg';
import CustomPagination from '../../common/datatables/CustomPagination';

class TabFabric extends BaseComponent {
  constructor(props, context) {
    super(props, context);
    let { roles: {showEdit, showDelete} } = this.props;
    this.state = {
      pageSize: 10,
      pageNumber: 1,
      columns: [
        { name: I18n.t('PageStandard.Name'), value: 'name', type: 'text', dataType: 'text' },
        { name: I18n.t('PageStandard.Content'), value: 'data.content', type: 'text', dataType: 'text' },
        { name: I18n.t('PageStandard.Unit'), value: 'data.unit', type: 'text', dataType: 'text'},
        { name: I18n.t('PageStandard.Origin'), value: 'data.origin', type: 'text', dataType: 'text'},
        { name: I18n.t('PageStandard.Note'), value: 'note', type: 'text', dataType: 'text'},
      ],
      actions: [
        (item) => {
          return showEdit ? { type: 'edit', click: (item) => this.editItem(item) } : {};
        },
        (item) => {
          return showDelete ? { type: 'delete', click: (item) => this.showDeleteConfirm(item) } : {};
        },

      ],
      fabrics: [],
    };

    this.onDeleteFabric = this.onDeleteFabric.bind(this);
  }

  componentWillMount() {
    this.setState({search: {key: FABRIC_SEARCH_KEY.FABRIC_NAME, value: ""}});
    let options = { pageSize: this.state.pageSize, pageNumber: this.state.pageNumber };
    this.props.fabricPropsActions.getListFabric(options);
  }

  componentWillReceiveProps(newProps) {
    let { fabrics } = this.state;
    !_.isEqual(fabrics, newProps.fabricState.items) && this.setState({
      fabrics: _.cloneDeep(newProps.fabricState.items) || [],
      total: newProps.fabricState.count || 0
    });
  }

  componentWillUpdate(nextProps, nextState) {
    if (this.props.fabricState.reload != nextProps.fabricState.reload && this.props.modal) {
      this.props.modal.closeModal();
      nextProps.fabricPropsActions.getListFabric({ pageSize: this.state.pageSize, pageNumber: this.state.pageNumber });
    }
  }

  addItem() {
    let { openModal, closeModal } = this.props.modal;
    let modal = (<ModalFabricForm closeModal={closeModal} />);
    openModal(modal);
  }

  editItem(item) {
    let { openModal, closeModal } = this.props.modal;
    let modal = (<ModalFabricForm closeModal={closeModal} propFabricDetail={ item } />);
    openModal(modal);
  }

  selectPage = (page) => {
    this.setState({pageNumber: page});
    let options = { pageSize: this.state.pageSize, pageNumber: page };
    this.props.fabricPropsActions.getListFabric(options);
  }

  showDeleteConfirm(item) {
    let { openModal, closeModal } = this.props.modal;
    let modal = (<ModalConfirm
      title={I18n.t('PageStandard.DELETEFABRIC')}
      confirmMessage={I18n.t('PageStandard.ConfirmDeleteFabric')}
      messageOk={I18n.t('Btn.YES')}
      messageCancel={I18n.t('Btn.CANCEL')}
      handleClose={closeModal}
      handleOk={this.onDeleteFabric.bind(null, item)} />);
    openModal(modal);
  }

  onDeleteFabric(item) {
    item._id && this.props.fabricPropsActions.deleteFabric(item._id);
  }

  getBaseOption = () => {
      const {pageSize, pageNumber, search} = this.state;

      let objQuery = {};
      if(search && search.key && search.value) {
          let qr = {}
          switch(search.key) {
              case FABRIC_SEARCH_KEY.FABRIC_NAME: {
                  objQuery = {name: {$regex: '.*' + search.value + '.*', $options: 'i'}}
                  break;
              }
              case FABRIC_SEARCH_KEY.CONTENT: {
                  objQuery = {'data.content': {$regex: '.*' + search.value + '.*', $options: 'i'}}
                  break;
              }
              case FABRIC_SEARCH_KEY.UNIT: {
                  objQuery = {'data.unit': {$regex: '.*' + search.value + '.*', $options: 'i'}}
                  break;
              }
              case FABRIC_SEARCH_KEY.ORIGIN: {
                  objQuery = {'data.origin': {$regex: '.*' + search.value + '.*', $options: 'i'}}
                  break;
              }
          }

      }

      objQuery.type = "FABRIC";

      let options = {
          query: JSON.stringify(objQuery),
          sort: JSON.stringify({createdAt:-1}),
          pageSize,
          pageNumber,
      };
      return options
  }

    onChangeSearch = (property, value) => {
        switch(property) {
            case SEARCH_BAR_EVENT.FILTER: {
                this.setState({search: {key: value, value: ""}});
                break;
            }
            case SEARCH_BAR_EVENT.SEARCH: {
                this.setState({search: {...this.state.search, value: value}});
                break;
            }
        }
    }

  onSearch = (page = null) => {

      const options = this.getBaseOption()
      options.pageNumber = page != null ? page : options.pageNumber

      // const options = {}
      fabricApi.getListFabricDAL(options).then(rs => {
              if(rs.success) {
                  this.setState({
                      fabrics: _.get(rs, 'data.items', {}),
                      total: _.get(rs, 'data.count', 0)
                  })
              } else toastr.error(I18n.t('Notify.Error'), rs.error)
          },
          err => {
              toastr.error(I18n.t('Notify.Error'), err.error)
          })
  }

  render() {
    let { fabrics, total, columns, actions, pageNumber, pageSize } = this.state;
    let { roles: {showCreate} } = this.props;
    let totalPage = _.ceil(total / pageSize);
      const fields = SEARCH_LIST.FABRIC_SEARCH_LIST;
      return (
          <div role="tabpanel"  className="tab-pane" id="standard-materials-fabric">
              <div className="wrap-search-position">

              </div>
              <SearchBar fields={fields} onChangeSearch={this.onChangeSearch} onSearch={this.onSearch}/>
              {showCreate && <span className="cursor-pointer add-standard-materials-fabric" onClick={() => { this.addItem(); }}>
                     <img src={icAddIcon} /> {I18n.t('PageStandard.AddNew')}
              </span>}
              <div>
                  <TableList {...this.props} items={fabrics || []} columns={columns || []} actions={actions || []}
                             options={{ clsTbl: 'global-table box-shadow-none' }} widthAction="w-70"/>
                  <CustomPagination items={totalPage} activePage={pageNumber} onSelect={this.selectPage} />

              </div>
          </div>
      );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    fabricState: state.fabricState
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fabricPropsActions: bindActionCreators(fabricActions, dispatch)
  };
}

TabFabric.propTypes = {
};

export default connect(mapStateToProps, mapDispatchToProps)(TabFabric);
