import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import FormGroup from '../../forms/FormGroup';
import Input from '../../forms/Input';
import Sidebar from '../SidebarItemList';
import SearchSelect from '../../forms/SearchSelect';
import ConvertCurrencyCard from '../ConvertAmountCard';
import SubVATCard from '../payable/SubVATCard';
import MainVATCard from '../MainVATCard';
import _ from 'lodash';
const formatCurrency = require('format-currency');
const DateTimeField = require('react-datetime');

const EditInvoiceContractor = props => {
  const {
    currencyTypeUSD,
    currencyTypeVND,
    mainVatValue,
    disableChangeValue = false,
    dataApi,
    constContractorName = '',
    issuedDate,
    dueDate,
    totalAmount,
    paid,
    remaining,
    paymentType = 'cash',
    emptyReceipt,
    receiptFromInvoice,
  } = props;

  return (
    <div>
      <ul className="content-header-title float-none pd-0-5 justify-content-space-between display-flex">
        <li>
          <p>
            Contractor
            <span className="color-orange medium mg-left-25 size-16">{constContractorName}</span>
          </p>
        </li>
        <li className="add-filter-top">
          <span className="cursor-pointer" onClick={() => props.save(paymentType, dataApi)}>
            <i className="fa fa-floppy-o" aria-hidden="true"></i>
            {'SAVE'}
          </span>
        </li>
      </ul>
      <div className="wrap-input-global pd-15-0 margin-small bg-white page-edit-invoice-list box-shadow">
        <div className="pd-0 col-xs-9 left">
          <div className="col-md-6 col-xs-12 input">
            <FormGroup
              label="Contractor"
              type="input"
              inputComponent={
                <Input
                  type="text"
                  value={props.contractor}
                  onChange={e => props.changeSupplier(e.target.value)}
                  placeholder=""
                />
              }
            />
          </div>
          <div className="col-md-6 col-xs-12 input">
            <FormGroup
              type="input"
              label="Issued Date"
              inputComponent={
                <DateTimeField
                  timeFormat={false}
                  closeOnSelect
                  dateFormat="MMM DD, YYYY"
                  value={moment(issuedDate)}
                  onChange={date => props.changeIssuedDate(date.format())}
                />
              }
            />
          </div>
          <div className="col-md-6 col-xs-12 input">
            <FormGroup
              type="input"
              label="Invoice No."
              inputComponent={
                <Input
                  type="text"
                  value={props.invoiceNo}
                  onChange={e => props.changeInvoiceNo(e.target.value)}
                  placeholder=""
                />
              }
            />
          </div>
          <div className="col-md-6 col-xs-12 input">
            <FormGroup
              type="input"
              label="Due Date"
              inputComponent={
                <DateTimeField
                  timeFormat={false}
                  closeOnSelect
                  dateFormat="MMM DD, YYYY"
                  value={moment(dueDate)}
                  onChange={date => props.changeDueDate(date.format())}
                />
              }
            />
          </div>
          <div className="col-md-6 col-xs-12 input">
            <FormGroup
              type="input"
              label="Credit/Debit"
              inputComponent={
                <SearchSelect
                  options={props.selectableCredits || []}
                  value={props.selectedCredit || ''}
                  onChange={o => props.changeCreditDebit(o, totalAmount)}
                  clearable
                />
              }
            />
          </div>
          <div className="col-md-6 col-xs-12 input">
            <FormGroup
              type="input"
              label="Payment Amount"
              inputComponent={
                <Input
                  type="number"
                  min="0"
                  value={props.payAmount}
                  onChange={e => props.changePayAmount(_.toNumber(e.target.value), remaining, paid)}
                  placeholder=""
                />
              }
            />
          </div>
        </div>
        <div className="pd-0 col-xs-3 right">
          <ul className="type-supplier">
            <li
              onClick={() => props.changePaymentType('cash')}
              className={paymentType === 'cash' ? 'active' : 'cursor-pointer'}>
              <span className="cursor-poniter">
                <span className="icon">
                  <span />
                </span>
                <span className="text">Cash</span>
              </span>
            </li>

            <li
              onClick={() => props.changePaymentType('transfer')}
              className={paymentType === 'transfer' ? 'active' : 'cursor-pointer'}>
              <span className="cursor-poniter">
                <span className="icon">
                  <span />
                </span>
                <span className="text">Transfer</span>
              </span>
            </li>
          </ul>

          {paymentType === 'transfer' && (
            <span>
              <div className="mg-right-15">
                <span className="color-link text-decoration cursor-pointer" onClick={() => props.openTransferModal()}>
                  Upload File
                </span>
              </div>
              <p className="text-align-left mg-bottom-15">Files: {(dataApi.files || []).length}</p>
            </span>
          )}
          {paymentType === 'cash' && (
            <span>
              {emptyReceipt && (
                <div className="mg-right-15">
                  <span className="color-link text-decoration cursor-pointer" onClick={() => props.openReceiptModal()}>
                    Add Receipt
                  </span>
                </div>
              )}
              {!emptyReceipt && (
                <p className="text-align-left mg-bottom-15">
                  <span
                    href=""
                    className="color-link text-decoration size-12 cursor-pointer"
                    onClick={() => props.openReceiptModal()}>
                    {receiptFromInvoice.no}
                  </span>
                </p>
              )}
            </span>
          )}
          <div className="text-align-left mg-bottom-5">
            <p>
              <span className="mg-right-15 size-12">Total Amount INV</span>
              <span className="bold color-number size-14">
                {currencyTypeUSD}
                {formatCurrency(totalAmount)} {currencyTypeVND}
              </span>
            </p>
          </div>

          <div className="text-align-left mg-bottom-5">
            <p>
              <span className="mg-right-15 size-12">Paid</span>
              <span className="bold size-14">
                {currencyTypeUSD}
                {formatCurrency(paid)} {currencyTypeVND}
              </span>
            </p>
          </div>

          <div className="text-align-left mg-bottom-5">
            <p>
              <span className="mg-right-15 size-12">Remaining</span>
              <span className="bold size-14 color-green">
                {currencyTypeUSD}
                {formatCurrency(remaining)}
                {currencyTypeVND}
              </span>
            </p>
          </div>
        </div>
      </div>
      <div className="content-main-accounting">
        <Sidebar
          items={props.listCheckableCustomerPOs}
          disableChangeValue={disableChangeValue}
          changeItemChecked={(item, checked) => props.changeSelectedCustomerPOs(item._id, checked)}
          changeSearchText={value => props.changeSearchTextCustomerPO(value)}
        />
        <div id="header-plan-accounts-payable">
          <ConvertCurrencyCard
            selectableCurrencies={props.selectableConvertCurrencies}
            selectedCurrency={props.selectedConvertCurrency}
            rate={props.convertRate}
            disableChangeValue={disableChangeValue}
            amount={props.amountConvert}
            totalAmount={props.totalAmountConvert}
            changeSelectedCurrency={o => props.changeSelectedConvertCurrency(o)}
            changeRate={value => props.changeConvertRate(Number(value))}
          />
        </div>
        {mainVatValue && (
          <MainVATCard
            vatNo={mainVatValue.vatNo}
            vatDate={mainVatValue.vatDate}
            totalAmount={mainVatValue.totalAmount}
            vatAmount={currencyTypeUSD + mainVatValue.vatAmount + ' ' + currencyTypeVND}
            grandTotal={currencyTypeUSD + mainVatValue.grandTotalAmount + ' ' + currencyTypeVND}
            taxRate={mainVatValue.taxRate}
            changeVATNo={value => props.changeMainVATNo(value)}
            changeTotalAmount={value => props.changeMainVATTotalAmount(value)}
            changeVATDate={value => props.changeMainVATDate(value)}
            changeTaxRate={value => props.changeMainVATTaxRate(value)}
            disableChangeValue={disableChangeValue}
          />
        )}
        <div className="panel-group mg-0" id="panel-heading-main" role="tablist" aria-multiselectable="true">
          {_.map(props.editableCustomerPOsDetail, (item, q) => (
            <div
              key={q}
              className="panel-group mg-0"
              id="panel-heading-main"
              role="tablist"
              aria-multiselectable="true">
              <div className="panel panel-default">
                {/* <!-- Component --> */}
                <div className="panel-heading component" role="tab" id="">
                  <div className="panel-title">
                    <a
                      role="button"
                      data-toggle="collapse"
                      data-parent="#panel-heading-main"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                      onClick={() => props.toggleActiveCustomerPO(item.customerPO.id, !item.active)}>
                      <i className="fa fa-caret-down" aria-hidden="true" />
                    </a>
                    <ul>
                      <li>
                        <div className="text">
                          <span className="left">Customer PO</span>
                          <span className="right size-15">
                            <span className="medium">{item.customerPO.name}</span>
                          </span>
                        </div>
                      </li>
                      <li>
                        <div className="text">
                          <span className="left">QTY</span>
                          <span className="right size-15">
                            <span className="medium">{item.customerPO.quantity}</span>
                          </span>
                        </div>
                        <div className="text mg-0">
                          <ul>
                            <li>
                              <span>Price</span>
                            </li>
                            <li className="position-relative top-s-5">
                              <input
                                type="number"
                                className="form-control center w-100"
                                disabled={disableChangeValue}
                                value={item.customerPO.price || 0}
                                onChange={event => props.changeCustomerPOPrice(item.customerPO.id, event.target.value)}
                              />
                            </li>
                          </ul>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* <!-- End Component --> */}
                {item.active && (
                  <div
                    id={`#panel-heading-${q}`}
                    className="panel-collapse collapse in"
                    role="tabpanel"
                    aria-labelledby={`#panel-heading-${q}`}>
                    <div className="panel-body pd-0">
                      {item.vat ? (
                        <SubVATCard
                          vatNo={item.vat.vatNo}
                          totalAmount={item.vat.totalAmount}
                          vatDate={item.vat.vatDate}
                          vatAmount={item.vat.vatAmount}
                          taxRate={item.vat.taxRate}
                          grandTotal={item.vat.grandTotalAmount}
                          note={item.vat.note}
                          isChangeTotalAmount={true}
                          changeVATTotalAmount={value =>
                            props.changeSubVATTotalAmount(item.customerPO.id, Number(value))
                          }
                          changeVATNo={value => props.changeSubVATNo(item.customerPO.id, value)}
                          changeVATDate={value => props.changeSubVATDate(item.customerPO.id, value)}
                          changeTaxRate={value => props.changeSubVATTaxRate(item.customerPO.id, Number(value))}
                          changeNote={value => props.changeSubVATNote(item.customerPO.id, value)}
                        />
                      ) : (
                        ''
                      )}
                      <div className="wrap-global-table pd-0 inline-block w-full float-left mg-bottom-0">
                        <table className="global-table box-shadow-none w-full">
                          <tbody>
                            <tr className="bg-white bd-solid-bottom">
                              <th>Style</th>
                              <th className="center">Price</th>
                              <th className="center">Cut Ticket</th>
                              <th className="center">Color</th>
                              <th className="center">Ship Date</th>
                              <th className="center">Ship Mode</th>
                              <th className="center">QTY Rec</th>
                              <th className="center">Price</th>
                              <th className="center">Amount</th>
                            </tr>
                            {_.map(item.data || [], (row, k) => (
                              <tr key={k}>
                                <td>{row.style.label}</td>
                                <td className="center">
                                  {row.style.label ? (
                                    <input
                                      type="number"
                                      min="0"
                                      className="form-control center"
                                      disabled={disableChangeValue}
                                      value={row.style.price}
                                      onChange={evt =>
                                        props.changeStylePrice(item.customerPO.id, row.style.id, evt.target.value)
                                      }
                                    />
                                  ) : (
                                    ''
                                  )}
                                </td>
                                <td className="center">{row.cutTicket.label}</td>
                                <td className="center">{row.color.name}</td>
                                <td className="center">{moment(row.color.shipDate).format('MMM DD, YYYY')}</td>
                                <td className="center">{row.color.shipMode}</td>
                                <td className="center">{row.color.quantity}</td>
                                <td className="center">
                                  <input
                                    type="number"
                                    min="0"
                                    disabled={disableChangeValue}
                                    value={row.color.price}
                                    onChange={evt =>
                                      props.changeColorPrice(
                                        item.customerPO.id,
                                        row.style.id,
                                        row.cutTicket.id,
                                        row.color.id,
                                        evt.target.value
                                      )
                                    }
                                    className="form-control center"
                                  />
                                </td>
                                <td className="center number">
                                  {currencyTypeUSD}
                                  {row.color.amount || 0} {currencyTypeVND}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

EditInvoiceContractor.propTypes = {
  constSupplierName: PropTypes.string,

  supplier: PropTypes.string,
  issuedDate: PropTypes.string,
  dueDate: PropTypes.string,
  invoiceNo: PropTypes.string,
  paymentAmount: PropTypes.number,

  selectableCredits: PropTypes.arrayOf(PropTypes.arrayOf).isRequired,
  creditedDebit: PropTypes.object.isRequireds,

  listCurrencies: PropTypes.array,
  currency: PropTypes.string,
  amount: PropTypes.Number,
  paid: PropTypes.Number,
  remaining: PropTypes.Number,
  paymentType: PropTypes.string,

  selectableConvertCurrencies: PropTypes.array,
  selectedConvertCurrency: PropTypes.string,
  convertRate: PropTypes.string,
  convertAmount: PropTypes.string,
  convertTotalAmount: PropTypes.string,

  listCheckablePurchaseOrder: PropTypes.array,
  editablePurchaseOrders: PropTypes.array,

  dataApi: PropTypes.object,

  save: PropTypes.func,
  changeSupplier: PropTypes.func,
  changeIssuedDate: PropTypes.func,
  changeFactor: PropTypes.func,
  changeInvoiceNo: PropTypes.func,
  changeDueDate: PropTypes.func,
  changeCreditDebit: PropTypes.func,
  changeTransactionNo: PropTypes.func,
  changePaymentMethod: PropTypes.func,
  changePaymentType: PropTypes.func,
  changePaymentAmount: PropTypes.func,
  addFile: PropTypes.func,
  openReceiptModal: PropTypes.func,
  changeFilterPOText: PropTypes.func,
  changeCheckedPurchaseOrder: PropTypes.func,
  toggleActivePurchaseOrder: PropTypes.func,
};

EditInvoiceContractor.defaultProps = {
  constSupplierName: '',
  supplier: '',
  issuedDate: '',
  dueDate: '',
  amount: 0,
  paid: 0,
  remaining: 0,
  paymentAmount: 0,
  listCheckablePurchaseOrder: [],
  editablePurchaseOrders: [],
  listCurrencies: [],
  currency: '',
  dataApi: {},
  changeSupplier: f => f,
  changeIssuedDate: f => f,
  changeInvoiceNo: f => f,
  changeDueDate: f => f,
  changeCreditDebit: f => f,
  changePaymentAmount: f => f,
  changePaymentType: f => f,
  addFile: f => f,
  openReceiptModal: f => f,
  changeFilterPOText: f => f,
  changeCheckedPurchaseOrder: f => f,
  toggleActivePurchaseOrder: f => f,
};

export default EditInvoiceContractor;
