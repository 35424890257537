import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BaseComponent from '../../base/BaseComponent';
import stockInActions from '../../../actions/stockInActions';
import contractorActions from '../../../actions/contractorActions';
const DateTimeField = require('react-datetime');
import AddMaterial from '../modal/AddMaterial';
import _ from 'lodash';
import moment from 'moment';
import I18n from 'i18n-js';
import TextNumberInput from "../../common/fields/TextNumberInput";
import {validateQuantityAndUsableOfMaterial} from "../../../functions/helpers";
import {toastr} from "react-redux-toastr";

import icAddIcon from '../../../stylesheets/images/ic-add.svg';
import icXIcon from '../../../stylesheets/images/ic-x.svg';
import icLineDBIcon from '../../../stylesheets/images/ic-line-db.svg';
import icLineNewRightIcon from '../../../stylesheets/images/ic-line-new-right.png';

class StockInContractorAdd extends BaseComponent {
  constructor (props, context) {
    super(props, context);

    this.state = {
      item: {},
      order: {},
      contractor: {},
      contractors: [],
      style: {},
      materials: []
    };
  }


  componentWillMount () {
    const {data} = this.props;

    const item = _.pick(data, ['orderId', 'styleId', 'contractorId']);
    const contractor = _.cloneDeep(data.contractor) || {};
    const order = _.cloneDeep(data.order) || {}
    const style = _.cloneDeep(data.style) || {}

    this.setState({
      item: {...item, contractor: contractor.name, customerPO: order.customerPO, style: style.style },
      order,
      style,
      contractor,
      contractors: _.cloneDeep(data.contractors) || []
    });
  }

  componentWillReceiveProps (newProps) {
    let {contractors} = this.state;
    !_.isEqual(contractors, newProps.contractorState.items) && this.setState({contractors: _.cloneDeep(newProps.contractorState.items) || []});
  }

  componentWillUpdate (nextProps, nextState) {
    if (_.get(this.props.stockInState, 'contractor.reload') != _.get(nextProps.stockInState, 'contractor.reload')) {
      _.isFunction(this.props.backToListPage) && this.props.backToListPage('contractor');
    }
  }

  onChangeMaterials (id, property, value) {
    let {materials} = this.state;
    materials.forEach(m => {
      if (m._id === id) {
        m[property] = value;
      }
    });

    this.setState({materials});
  }

  onAddMaterial(materialData) {
    var d = new Date();
    materialData._id = d.getTime();
    materialData.receivedDate = d;
    this.setState({ materials: [...this.state.materials, materialData] });
  }

  deleteMaterial (id) {
    let {materials} = this.state;
    materials = materials.filter(i => i._id != id);
    this.setState({materials});
  }

  save () {
    let {item, materials} = this.state;

    const error = validateQuantityAndUsableOfMaterial(materials);
    if(error) {
      toastr.info(I18n.t('Notify.Info'), error.msg);
      return;
    }

    const verifiedItem = Object.assign(item, {});
    verifiedItem.materials = materials.map(i => {
      const item = Object.assign(i, {});
      item._id = undefined;
      item.receivedDateFomat = new moment(i.receivedDate || new Date()).format("DD/MM/YYYY");
      return item;
    });

    this.props.stockInPropsActions.addItem('contractor', verifiedItem);
  }

  addMaterial (contractorId, orderId, styleId) {
    const {openModal, closeModal} = this.props.modal;
    openModal((<AddMaterial closeModal={closeModal} data={{contractorId, orderId, styleId}} onSave={(dataForm) => this.onAddMaterial(dataForm)} />))
  }

  render () {
    let { order, contractor, materials, style } = this.state;
    let w100 = {
      width: '100%'
    };
    return (
      <div id="content">
        <div className="wrap-filter-title w-full pd-5 inline-block ">
          <div className="bg-white w-full inline-block pd-10">

            <div className="float-left h-40 line-h-40">
              <p className="size-12 normal">{I18n.t('PageWareHouse.Contractor')} <span className="color-orange medium mg-left-50 size-16">{contractor.name}</span></p>
            </div>

            <div className="float-right add-filter-top ">
                <span className="cursor-pointer global-add" onClick={() => this.save()}><i className="fa fa-floppy-o" aria-hidden="true" /> Save</span>
            </div>

            <div className="float-right list-filter">
              <div className="float-right add-filter-top ">
                <span className="cursor-pointer" onClick={() => this.addMaterial(contractor._id, order._id, style._id)}><img src={icAddIcon} alt="" />ADD MATERIAL</span>
              </div>
            </div>

          </div>
        </div>
      {(materials == null ||  materials.length == 0) && (
        <div className="there_is_no_data">
          <div className="center">
            <span className="line-click"><img src={icLineDBIcon} alt=""/></span>
            <span className="text">There is no data!</span>
          </div>
          <div className="right">
            <span className="line-click"><img src={icLineNewRightIcon} alt=""/></span>
          </div>
       </div>
      )}
      {(materials &&  materials.length > 0) && (
        <div className="wrap-global-table">
          <table style={w100} className="global-table data-time-table warehouse">
            <tbody className="body-data-time">
              <tr>
                {/* <th /> */}
                <th className="center w-265">{I18n.t('PageWareHouse.ReceivedDate')}</th>
                <th className="center">{I18n.t('PageWareHouse.Material')}</th>
                <th className="center">{I18n.t('PageWareHouse.Content')}</th>
                <th className="center">{I18n.t('PageWareHouse.Color')}</th>
                <th className="center">{I18n.t('PageWareHouse.Size')}</th>
                <th className="center">{I18n.t('PageWareHouse.Quantity')}</th>
                <th className="center">{I18n.t('PageWareHouse.Usable')}</th>
                <th className="center">{I18n.t('PageWareHouse.Damaged')}</th>
                <th className="center">{I18n.t('PageWareHouse.Unit')}</th>
                <th className="center">{I18n.t('PageWareHouse.Roll')}</th>
                <th className="center">{I18n.t('PageWareHouse.Actions')}</th>
              </tr>

              {(materials || []).map((i, idx) => (
                <tr key={idx}>
                  {/* <td className="center style-checkbox">
                    <input type="checkbox" id={`check` + idx} checked={!!i.checked} onChange={evt => this.onChangeMaterials(i._id, 'checked', !!evt.target.checked)} value /><label htmlFor={`check` + idx}><span><span /></span></label>
                  </td> */}
                  <td className="center date-pick date-time-td">
                    {i.receivedDate ? <DateTimeField timeFormat={false} closeOnSelect dateFormat="MMM DD, YYYY"
                      value={moment(i.receivedDate || {})}
                      onChange={date => this.onChangeMaterials(i._id, 'receivedDate', date.format())} />
                    : <DateTimeField timeFormat={false} closeOnSelect dateFormat="MMM DD, YYYY"
                      onChange={date => this.onChangeMaterials(i._id, 'receivedDate', date.format())} />
                  }
                  </td>
                  <td className="center">{_.get(i, 'material.name', '')}</td>
                  <td className="center">{_.get(i, 'material.data.content', '')}</td>
                  <td className="center">{i.color || ''}</td>
                  <td className="center">{i.size || ''}</td>
                  <td className="center w-100">
                    <TextNumberInput value={i.quantity || ''} onChange={val => this.onChangeMaterials(i._id, 'quantity', val)} className="form-control center" />
                  </td>
                  <td className="center w-100">
                    <TextNumberInput value={i.usable || ''} onChange={val => this.onChangeMaterials(i._id, 'usable', val)} className="form-control center" />
                  </td>
                  <td className="center">{(i.quantity - i.usable) ? (i.quantity - i.usable).toFixed(2) : 0}</td>
                  <td className="center">{_.get(i, 'material.data.unit', '')}</td>
                  <td className="center w-100">
                    <input type="text" value={i.roll || ''} onChange={evt => this.onChangeMaterials(i._id, 'roll', evt.target.value)} className="form-control center" />
                  </td>
                  <td className="actions center">
                    <span key={idx} className="cursor-pointer" onClick={() => this.deleteMaterial(i._id)}><img src={icXIcon} alt="" /></span>
                  </td>
                </tr>
              ))}

            </tbody>
          </table>
        </div>
      )}

      </div>
    );
  }
}

function mapStateToProps (state, ownProps) {
  return {
    authState: state.authState,
    stockInState: state.stockInState,
    contractorState: state.contractorState
  };
}

function mapDispatchToProps (dispatch) {
  return {
    stockInPropsActions: bindActionCreators(stockInActions, dispatch),
    contractorActions: bindActionCreators(contractorActions, dispatch)
  };
}

StockInContractorAdd.propTypes = {
  authState: PropTypes.object.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(StockInContractorAdd);
