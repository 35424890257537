import Base from './base';

class DamagedGarment extends Base {
  constructor () {
    super();

    this.getListItemDAL = this.getListItemDAL.bind(this);
    this.editItemDAL = this.editItemDAL.bind(this);
  }

  getListItemDAL (query) {
    return super.execute(this.Urls.damagedGarment.list, this.ApiMethod.get, query);
  }

  editItemDAL (id, body) {
    return super.execute(this.Urls.damagedGarment.update + '/' + id, this.ApiMethod.put, body);
  }
}

export default new DamagedGarment();
