import React, { Component } from 'react';
import { connect } from 'react-redux';
import SearchBar from '../../../common/components/SearchBar';
import TableList from '../../../../components/common/datatables/TableList';
import {withRouter} from 'react-router';
import I18n from 'i18n-js';
import { SHIP_OUT } from '../../../../constants/routeConstants';
import * as selector from '../../../../selectors/ship-out/previewSelectedPklProdList';
import * as previewSelectedPklProdListActions from '../../../../actions/ship-out/previewSelectedPklProdListActions';
import {getExportPKLOfProductPreview} from '../../../../actions/ship-out/previewSelectedPklProdListActions'
import CustomPagination from '../../../../components/common/datatables/CustomPagination';
var FileSaver = require('file-saver');

class PreviewContianer extends Component {

    constructor (props) {
        super(props)
        this.state = {
            listIds: this.props.listIds,
            items: [],
            pageNumber: 1,
            pageSize: 10,
            columns: [
                { name: I18n.t('PageShiping.IH'), value: 'ih', type: 'date',formatType: 'MMM DD, YYYY' },
                { name: I18n.t('PageShiping.Customer'), value: 'customer', type: 'text' },
                { name: I18n.t('PageShiping.Contractor'), value: 'contractor', type: 'text' },
                { name: I18n.t('PageShiping.CustomerPO'), value: 'customerPO', type: 'text' },
                { name: I18n.t('PageShiping.Style'), value: 'style', type: 'text' },
                { name: I18n.t('PageShiping.CutTicket'), value: 'cutTicket', options: { clsTd: 'color-orange' }, type: 'text' },
                { name: I18n.t('PageShiping.Store'), value: 'store', options: { clsTd: 'color-orange' }, type: 'text' },
                { name: I18n.t('PageShiping.Color'), value: 'color', options: { clsTd: 'color-orange' }, type: 'text' },
                { name: I18n.t('PageShiping.QTYPO'), value: 'qtyPO', options: { clsTd: 'color-orange' }, type: 'text' },
                { name: I18n.t('PageShiping.QTYShip'), value: 'qtyShip', options: { clsTd: 'color-green' }, type: 'text' },
                { name: I18n.t('PageShiping.CBM'), value: 'CBM', options: { clsTd: 'color-green' }, type: 'text' },
            ],
            actions: []
        }
    }
    
    componentWillMount() {
        const {listIds} = this.props;
        !_.isEmpty(listIds) ? this.props.getListPackingDetail({...this.getBaseOption(), data: listIds}) : this.props.router.history.push(SHIP_OUT.URL)
    }

    componentDidMount() {
        const { items } = { ...this.state };

        this.setState({ items });
    }

    getBaseOption = () => {
        const {pageSize, pageNumber} = this.state;
        let options = {
          query: JSON.stringify({isCancel: {$ne: true}}),
          sort: JSON.stringify({createdAt:-1}), 
          pageSize, 
          pageNumber,
        };
        return options;
    }

    onChangeFilter = (value) => {
        this.props.changeSelectedFilter(value);
        this.props.changeSearchStatus(true)
    }

    onChangeValue = (value, pos) => {
        this.props.changeSearchValue(value, pos);
        this.props.changeSearchStatus(true);
    }

    selectPage = (page) => {
       let options = this.getBaseOption();
        options.pageNumber = page;
        const { listIds, searched, getSearchQuery, selectedFilter, searchValue } = this.props;    
        this.props.getListPackingDetail(getSearchQuery(searched, options, listIds, selectedFilter, searchValue))
    }

    // onSearch = (selectedFilter, searchValue, listIds) => {
    //     this.props.search(listIds, selectedFilter, searchValue);
    // }

    onSearch = (listIds) => {
        this.props.changeSearchStatus(true)
        const {searched, getSearchQuery, selectedFilter, searchValue} = this.props;
        this.props.getListPackingDetail(getSearchQuery(true, this.getBaseOption(), listIds, selectedFilter, searchValue))
    }

    btGetExport() {
        const {listIds} = this.props
        let url = this.props.getExportPKLOfProductPreview(listIds);
        FileSaver.saveAs(url, "Report_PKL_Product.xlsx");
    }

    render() {
        const { items, columns, actions } = { ...this.state };
        const {itemList, listFilter, selectedFilter, searchValue, totalPages, currentPage, listIds} = this.props;
        // console.log(listIds)
        return (
            <div>
                {/* <HeaderforSubView type='View' turnBackURL={SHIP_OUT.URL} {...this.props} /> */}
                <SearchBar name="Shipping" fields={listFilter} selectedFilter={selectedFilter || {}} searchValue={searchValue || {}} onChangeFilter={this.onChangeFilter} onChangeValue={this.onChangeValue} onSearch={() => this.onSearch(listIds)}/>
                <div className="pd-5">
                    <TableList items={itemList || []} columns={columns || []} options={{ clsTbl: 'global-table' }} />
                </div>
                <CustomPagination items={totalPages} activePage={currentPage} onSelect={this.selectPage} />
                <div className="center clearBoth">
                    <button className="bt-orange text-uppercase" onClick={() => this.btGetExport()} >{I18n.t(`PageShiping.Export`)}</button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    listIds: selector.getIdOfPackings(state),
    itemList: selector.getItemList(state),
    listFilter: selector.getListFilters(),
    selectedFilter: selector.getSelectedFilter(state),
    searchValue: selector.getSearchText(state),
    totalPages: selector.getTotalPages(state),
    currentPage: selector.getCurrentPage(state),
    searched: selector.getSearched(state),
    getSearchQuery: selector.getSearchQuery,
});

const mapDispatchToProps = dispatch => ({
    getListPackingDetail(data) {
        dispatch(previewSelectedPklProdListActions.getListPackingDetail(data))
    },
    changeSelectedFilter(value) {
        dispatch(previewSelectedPklProdListActions.changeSelectedFilter(value))
    },
    changeSearchValue(value, pos) {
        dispatch(previewSelectedPklProdListActions.changeSearchValue(value, pos))
    },
    search(listIds, selectedFilter, searchValue) {
        dispatch(previewSelectedPklProdListActions.search(listIds, selectedFilter, searchValue))
    },
    changeSearchStatus(value) {
        dispatch(previewSelectedPklProdListActions.changeSearchStatus(value))
    },
    getExportPKLOfProductPreview(ids) {
        return getExportPKLOfProductPreview(ids)
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PreviewContianer));