import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import sampleApprovalActions from '../../../actions/sampleApprovalActions';
const DateTimeField = require('react-datetime');
import _ from 'lodash';
import moment from 'moment';
import I18n from 'i18n-js';
class GarmentInfoEdit extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      info: {},
      error: {},
    };
  }

  componentWillMount() {
    const { item } = this.props;
    this.setState({ info: _.cloneDeep(item.info) || {} });
  }

  onChange(path, value) {
    let { info, error } = this.state;
    if(value == "Invalid date") {
      error[path] = true;
      this.setState({error})
    } else {
      error[path] = false;
      this.setState({ info: _.set(info, path, value), error });
    }
  }

  close() {
    this.props.closeModal();
  }

  save() {
    const { item } = this.props;
    let { info } = this.state;
    item.styleId && this.props.sampleApprovalPropsActions.editSampleApproval(item.styleId, { info });
  }

  render() {
    let { info, error} = this.state;
    let fields = [
      { text: 'Tech-Pack', field: 'techPack' },
      { text: 'Sew-By', field: 'sewBy' },
      { text: 'Pattern', field: 'pattern' },
      { text: 'Production Marker', field: 'productionMarker' },
      { text: 'Revised Production Marker', field: 'revisedProductionMarker' },
    ];

    return (
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <form>
            <div className="modal-header">
              <a className="close" onClick={() => this.close()}><span aria-hidden="true">&times;</span></a>
              <h4 className="modal-title" id="myModalLabel">{I18n.t('PageSampleApproval.GARMENT')}</h4>
            </div>
            <div className="modal-body">
              {fields.map((i, idx) => (
                <div key={idx}>
                  <div className="form-group">
                    <div className="col-md-5 col-xs-12 text">
                      <p>{i.text}</p>
                    </div>
                    <div className="col-md-7 col-xs-12 value">
                      {_.get(info, i.field) ? <DateTimeField timeFormat={false} 
                        closeOnSelect dateFormat="MMM DD, YYYY"
                        value={moment(_.get(info, i.field) || {})}
                        valueFormat = "yyyy-MM-dd-HH-mm-ss"
                        onChange={date => this.onChange(i.field, date.format())} />
                        : <DateTimeField timeFormat={false} closeOnSelect dateFormat="MMM DD, YYYY"
                          onChange={date => this.onChange(i.field, date.format())} />
                      }
                    </div>
                  </div>
                  {error[i.field] && <p className="float-right text color-red" style = {{marginRight: '20px'}}>Please enter the date in the format "MMM DD, YYYY". E.g: {moment().format("MMM DD, YYYY")}</p>}
                </div>
              ))}

              <div className="form-group border-dashed">
                <div className="col-md-5 col-xs-12 text">
                  <p>{I18n.t('PageSampleApproval.ADSubmit')}</p>
                </div>
                <div className="col-md-7 col-xs-12 value">
                  <textarea rows="4" cols="50" type="text" className="form-control" value={info.adSubmit || ''} onChange={evt => this.onChange('adSubmit', evt.target.value)} />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="bt-black" onClick={() => this.close()}>{I18n.t('Btn.CANCEL')}</button>
              <button type="button" className="bt-orange mg-l-5" onClick={() => this.save()}>{I18n.t('Btn.SAVE')}</button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    authState: state.authState,
    sampleApprovalState: state.sampleApprovalState
  };
}

function mapDispatchToProps(dispatch) {
  return {
    sampleApprovalPropsActions: bindActionCreators(sampleApprovalActions, dispatch)
  };
}

GarmentInfoEdit.propTypes = {
  authState: PropTypes.object.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(GarmentInfoEdit);
