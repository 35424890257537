import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import BaseComponent from '../../base/BaseComponent';
import TableList from '../../common/datatables/TableList';
import appActions from '../../../actions/appActions';
import ModalConfirm from '../../common/modals/ModalConfirm';
import stockInActions from '../../../actions/stockInActions';
import {Pagination} from 'react-bootstrap';
import SearchBar from '../partial/SearchBar';
import _ from 'lodash';
import moment from 'moment';
import I18n from 'i18n-js';
import {SEARCH_BAR_EVENT, SEARCH_KEY, SEARCH_LIST} from "../../../constants/constants";
import { withRouter } from 'react-router-dom';
import * as PATH from '../../../constants/routeConstants';
import * as utils from "../../../functions/utils";
import CustomPagination from '../../common/datatables/CustomPagination';
class TabContractor extends BaseComponent {
  constructor (props, context) {
    super(props, context);

    this.state = {
      pageSize: 10,
      pageNumber: 1,
      columns: [
        {name: I18n.t('PageWareHouse.Date'), value: 'date', type: 'date', formatType: 'MMM DD, YYYY'},
        {name: I18n.t('PageWareHouse.CustomerPO'), value: 'order.customerPO'},
        {name: I18n.t('PageWareHouse.Style'), value: 'style.style'},
        {name: I18n.t('PageWareHouse.Contractor'), value: 'contractor.name'}
      ],
      actions: [],
      items: [],
      total: 0,
      searchFields: [
        {name: I18n.t('PageWareHouse.Date'), field: 'date', type: 'date'},
        {name: I18n.t('PageWareHouse.CustomerPO'), field: 'customerPO'},
        {name: I18n.t('PageWareHouse.Style'), field: 'style'},
        {name: I18n.t('PageWareHouse.Contractor'), field: 'contractor'}
      ],
      searchValue: {},
      search: {key: SEARCH_KEY.CUSTOMER_PO, value: ""}
    };

    this.onDeleteStockContractor = this.onDeleteStockContractor.bind(this);
  }

  componentWillMount () {
    let {searchFields} = this.state;
    const {setSearchFields} = this.props;
    _.isFunction(setSearchFields) && setSearchFields('contractor', searchFields);

    this.getListItem();
  }

  componentWillReceiveProps (newProps) {
    let {items, searchValue} = this.state;
    let newItems = _.get(newProps.stockInState, 'contractor.items') || [];
    let total = _.get(newProps.stockInState, 'contractor.count') || 0;

    !_.isEqual(items, newItems) && this.setState({items: newItems, total});

    if (!_.isEqual(searchValue, newProps.searchValue)) {
      this.setState({searchValue: _.cloneDeep(newProps.searchValue)});
    }
  }

  componentWillUpdate (nextProps, nextState) {
    /*let {searchValue} = this.state;
    if (_.get(this.props.stockInState, 'contractor.reload') != _.get(nextProps.stockInState, 'contractor.reload') ||
        this.state.pageNumber != nextState.pageNumber ||
        !_.isEqual(searchValue, nextState.searchValue)) {
      let condition = this.parseSearchQuery(nextState.searchValue);
      this.getListItem({pageSize: nextState.pageSize, pageNumber: nextState.pageNumber, query: condition, type: _.isEmpty(condition) ? '' : 'search'});
    }*/

    if(_.get(this.props.stockInState, 'contractor.reload') != _.get(nextProps.stockInState, 'contractor.reload')) {
      this.props.modal.closeModal();
      this.getListItem()
    }
  }

  getListItem (query = {}) {
    let {pageSize, pageNumber} = this.state;
    let _query = _.assign({pageSize: pageSize, pageNumber: pageNumber, populate: 'orderId,styleId,contractorId'}, query);
    this.props.stockInPropsActions.getListItem('contractor', _.set(_query, 'sort', {createdAt: -1}));
  }

  parseSearchQuery (search) {
    let value = search.search;
    let query = {};

    if (!value || _.isEmpty(value)) { return {}; }

    switch (search.field) {
      case 'date':
        query = {date: {$gt: moment(value).startOf('day').toISOString(), $lt: moment(value).endOf('day').toISOString()}};
        break;
      case 'customerPO':
        query = {order: {customerPO: `@@${value}`}};
        break;
      case 'style':
        query = {style: {style: `@@${value}`}};
        break;
      case 'contractor':
        query = {'contractor': {name: `@@${value}`}};
        break;
      default:
        query = {$or: [
          {'order.customerPO': `@@${value}`},
          {'style.style': `@@${value}`},
          {'contractor.name': `@@${value}`}
        ]};
    }

    return query;
  }

  selectPage (page) {
    this.setState({pageNumber: page});
    this.search(this.state.search, page, this.state.pageSize);
  }

  showDeleteConfirm(item) {
    let {openModal, closeModal} = this.props.modal;
    let modal = (<ModalConfirm
      title={I18n.t('PageWareHouse.DELETEINBOUND-FROMCONTRACTOR')}
      confirmMessage={I18n.t('PageWareHouse.ConfirmDeleteStockInSupplier')}
      messageOk={I18n.t('Btn.YES')}
      messageCancel={I18n.t('Btn.CANCEL')}
      handleClose={closeModal}
      handleOk={this.onDeleteStockContractor.bind(null, item)}/>);
    openModal(modal);
  }

  onDeleteStockContractor(item) {
    item._id && this.props.stockInPropsActions.deleteItem('contractor', item._id);
  }

  editItem (item) {
    this.props.stockInPropsActions.setDetail('contractor', item);
    this.props.appPropsActions.setDataPageCurrent({context: {tabSub: 'contractor'}});
    this.props.history.push(PATH.WAREHOUSE_INBOUND_EDIT_CONTRACTOR_URL)
  }

  onChangeSearch(property, value) {
    switch(property) {
      case SEARCH_BAR_EVENT.FILTER: {
        this.setState({search: {key: value, value: ""}});
        break;
      }
      case SEARCH_BAR_EVENT.SEARCH: {
        this.setState({search: {...this.state.search, value: value}});
        break;
      }
    }
  }

  onSearch(){
    const {search, pageNumber, pageSize} = this.state;
    this.search(search, pageNumber, pageSize);
  }

  search(search, pageNumber, pageSize) {
    let options = {};
    const type = "contractor";
    const populate = 'orderId,styleId,contractorId';
    if(search && search.key && search.value) {
      switch(search.key) {
        case SEARCH_KEY.CUSTOMER_PO: {
          options = {
            projection: "_id",
            query: JSON.stringify({customerPO: {$regex: '.*' + search.value + '.*', $options: 'i'}})
          };
          this.props.stockInActions.searchByCustomerPO(type, options, pageNumber, pageSize, populate).then(res => {
            if(res && res.pageNumber && res.pageNumber == 1) {
              this.setState({pageNumber: 1});
            }
          });
          break;
        }
        case SEARCH_KEY.STYLE: {
          options = {
            projection: "_id",
            query: JSON.stringify({style: {$regex: '.*' + search.value + '.*', $options: 'i'}})
          };
          this.props.stockInActions.searchByStyle(type, options, pageNumber, pageSize, populate).then(res => {
            if(res && res.pageNumber && res.pageNumber == 1) {
              this.setState({pageNumber: 1});
            }
          });
          break;
        }
        case SEARCH_KEY.CONTRACTOR: {
          const options = {
            projection: "_id",
            query: {name: {$regex: '.*' + search.value + '.*', $options: 'i'}}
          };
          this.props.stockInActions.searchByContractor(type, options, pageNumber, pageSize, populate).then(res => {
            if(res && res.pageNumber && res.pageNumber == 1) {
              this.setState({pageNumber: 1});
            }
          });
          break;
        }
      }
    }
    if(!search.value) {
      let options = {
        pageNumber,
        pageSize,
        sort: {updatedAt: -1},
        populate: populate,
      };
      this.props.stockInActions.getListItem(type, options).then(res => {
        if(res && res.success && res.data && res.data.items && !res.data.items.length && pageNumber > 1) {
          options = {
            pageNumber:1,
            pageSize,
            sort: {updatedAt: -1},
            populate: populate,
          };
          this.setState({pageNumber: 1});
          this.props.stockInActions.getListItem(options);
        }
      });
    }
  }

  render () {
    let {items, total, columns, actions, pageNumber, pageSize} = this.state;
    let { showCreate, showEdit, showDelete } = utils.getRoleByAuthState(this.props.authState, 'warehouse');
    let {active} = this.props;
    let totalPage = _.ceil(total / pageSize);
    actions = [];
    if(showEdit){
      actions.push((item) => {
        return {type: 'edit', check: 'status', value: 'Approved', click: (item) => this.editItem(item)};
      })
    }
    if(showDelete){
        actions.push((item) => {
          return {type: 'delete', check: 'status', value: 'Approved', click: (item) => this.showDeleteConfirm(item)};
        })
    }
    return (
      <div role="tabpanel" className='tab-pane active' id="from_contractor">
        <SearchBar
          name={I18n.t('PageWareHouse.Inbound')}
          addItem={() => this.props.addItem()}
          fields={SEARCH_LIST.WAREHOUSE_INBOUND_CONTRACTOR}
          onChangeSearch={(type, data) => this.onChangeSearch(type, data)}
          onSearch={() => this.onSearch()}
          showCreate={showCreate}
        />
        <TableList {...this.props} items={items || []} columns={columns || []} actions={actions || []} widthAction="w-70" />
        <CustomPagination items={totalPage} activePage={pageNumber} onSelect={this.selectPage} />
      </div>
    );
  }
}

function mapStateToProps (state, ownProps) {
  return {
    authState: state.authState,
    stockInState: state.stockInState
  };
}

function mapDispatchToProps (dispatch) {
  return {
    appPropsActions: bindActionCreators(appActions, dispatch),
    stockInActions: bindActionCreators(stockInActions, dispatch),
  };
}

TabContractor.propTypes = {
  authState: PropTypes.object.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TabContractor));
