import * as R from 'ramda';
import I18n from 'i18n-js';

export const isOpen = ({ deleteStyle }) => !!deleteStyle.style;
export const isSubmiting = ({ deleteStyle }) => deleteStyle.submiting;
export const getStyle = ({ deleteStyle }) => R.path(['style'], deleteStyle);
export const getDescriptionMessage = ({ deleteStyle }) => {
    let r = R.path(['style', 'deletable'], deleteStyle);
    if(r === 'YES') return I18n.t('PageOrder.ConfirmDeleteStyle');
    if(r === 'NEED_CONFIRM') return I18n.t('PageOrder.ConfirmDeleteStyleWithPurchase');
}
export const getLabelButtonAccept = ({ deleteStyle }) => {
    let r = R.path(['style', 'deletable'], deleteStyle);
    if(r === 'YES') return I18n.t('Btn.YES');
    if(r === 'NEED_CONFIRM') return I18n.t('Btn.OK');
}