import Base from './base';

class Assignment extends Base {
  constructor () {
    super();

    this.getOrdersByContractorDAL = this.getOrdersByContractorDAL.bind(this);
    this.getListOrderDAL = this.getListOrderDAL.bind(this);
    this.getListItemDAL = this.getListItemDAL.bind(this);
    this.addItemDAL = this.addItemDAL.bind(this);
    this.editItemDAL = this.editItemDAL.bind(this);
    this.getItemDAL = this.getItemDAL.bind(this);
    this.deleteItemDAL = this.deleteItemDAL.bind(this);
    this.exportCurContractor = this.exportCurContractor.bind(this);
  }

  getOrdersByContractorDAL(id, query) {
    return super.execute(this.Urls.assignment.ordersC.replace('{id}', id), this.ApiMethod.get, query);
  }

  getListOrderDAL(query) {
    return super.execute(this.Urls.assignment.listOrder, this.ApiMethod.get, query);
  }

  getListItemDAL (query) {
    return super.execute(this.Urls.assignment.list, this.ApiMethod.get, query);
  }

  addItemDAL (body) {
    return super.execute(this.Urls.assignment.create, this.ApiMethod.post, body);
  }

  editItemDAL (id, body) {
    return super.execute(this.Urls.assignment.update + '/' + id, this.ApiMethod.put, body);
  }

  getItemDAL (id, options) {
    return super.execute(this.Urls.assignment.detail + '/' + id, this.ApiMethod.get, options);
  }

  deleteItemDAL (id) {
    return super.execute(this.Urls.assignment.delete + '/' + id, this.ApiMethod.delete, null);
  }

  exportCurContractor() {
    return this.Urls.assignment.export;
  }
}

export default new Assignment();
