const C = 'CREATE_CHARGEBACK_RECEIVABLE_INVOICE';

export default {
    // CHANGE_INVOICE_NO: `${C}_CHANGE_INVOICE_NO`,
    CHANGE_LIST_CUSTOMER: `${C}_CHANGE_LIST_CUSTOMER`,
    CHANGE_SELECTED_CUSTOMER: `${C}_CHANGE_SELECTED_CUSTOMER`,

    CHANGE_ISSUED_DATE: `${C}_CHANGE_ISSUED_DATE`,

    CHANGE_LIST_BILL: `${C}_CHANGE_LIST_BILL`,
    CHANGE_SELECTED_BILL: `${C}_CHANGE_SELECTED_BILL`,
    MAP_DATA_ON_BILL: `${C}_MAP_DATA_ON_BILL`,
    RESET_SELECTED_BILL: `${C}_RESET_SELECTED_BILL`,
    CHANGE_COUNT_NO_ITEM: `${C}_CHANGE_COUNT_NO_ITEM`,
    CHANGE_AVAILABLE_NO_LIST: `${C}_CHANGE_AVAILABLE_NO_LIST`,

    CHANGE_REASON_ORDER: `${C}_CHANGE_CHANGE_REASON`,
    CHANGE_NOTE_ORDER: `${C}_CHANGE_NOTE_BILL`,

    CHANGE_RATE_ITEM: `${C}_CHANGE_RATE_ITEM`,
    CHANGE_FEE_I_LOGICX_IN_USA_ITEM: `${C}_CHANGE_FEE_I_LOGICX_IN_USA`,
    CHANGE_DEDUCT_ITEM: `${C}_CHANGE_DEDUCT_ITEM`,
    CHANGE_PAYER_ITEM: `${C}_CHANGE_PAYER`,
    CHANGE_RATE_LM_ITEM: `${C}_CHANGE_RATE_LM_ITEM`,
    CREATE_NO_ITEM: `${C}_CREATE_NO_ITEM`,

    CHANGE_SEARCH_TEXT: `${C}_CHANGE_SEARCH_TEXT`,
    
    RESET_DATA_ALL: `${C}_RESET_DATA_ALL`,
    SAVE_SUCCESS: `${C}_SAVE_SUCCESS`,
    RESET_DATA: `${C}_RESET_DATA`,
}