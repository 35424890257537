import {getListOrganizationDAL, editOrganizationDAL, addOrganizationDAL, deleteOrganizationDAL} from '../api/organizationApi';

class organizationServices {

  constructor() {
  }

  getListOrganization (options = {}) {
    options.query && _.set(options, 'query', JSON.stringify(options['query']));
    options.sort && _.set(options, 'sort', JSON.stringify(options['sort']));
    return getListOrganizationDAL(options).then((res) => {
      return res;
    });
  }

  getListOrganizationId (options = {}) {
    return getListOrganizationDAL(options);
  }

  editOrganization (id, data) {
    return editOrganizationDAL(id, data);
  }

  addOrganization (data) {
    return addOrganizationDAL(data);
  }

  deleteOrganization (id) {
   return deleteOrganizationDAL(id);
  }

}

export default new organizationServices();
