import React from 'react';
import {
  connect
} from 'react-redux';
import BaseComponent from '../../base/BaseComponent';
import I18n from 'i18n-js';
import TabShipIn from './TabShipIn';
import TabHistory from './TabHistory';
import TabFollowUp from './TabFollowUp';
import PropTypes from "prop-types";
import RoleFormScreen from "../../organization/TabPermissionRole/RoleFormScreen";
import {bindActionCreators} from "redux";
import appActions from "../../../actions/appActions";
import * as types from '../../../constants/actionTypes';
import purchaseApi from '../../../api/purchaseApi';
import {ACTION_TYPE, COMPONENT_TYPE, PRODUCTION_SCREEN, FOLLOWUP_SEARCH_KEY} from "../../../constants/constants";
import {SEARCH_LIST, SEARCH_BAR_EVENT, SHIPPING_SCREEN} from "../../../constants/constants";
import {toastr} from 'react-redux-toastr';
import _ from 'lodash';
import {calculateTotal} from "../../../functions/utils";

class ScreenShipIn extends BaseComponent {
  constructor(props, context) {
      super(props, context);
      this.state = {
        tabActive: 1,
        isEditRole: false,
        roleId: "",
        notifies: [],
        // purchase: {items: [], total: 0},
        // total: 0,
        pageSize: 10,
        search: {key: FOLLOWUP_SEARCH_KEY.CUSTOMER_PO, value: '', beginDate: '', endDate: ''},
        pageNumber: 1,
      };
      this.handleAddEditRole = this.handleAddEditRole.bind(this);
      this.handleClickTab = this.handleClickTab.bind(this);
  }

  handleAddEditRole({roleId, isEditRole}) {
      this.setState({roleId, isEditRole});
  }

  handleClickTab(tabActive) {
      this.setState({tabActive});
      this.props.appState.tabActive = tabActive;
  }

  getBaseOption = () => {
    const {pageSize, pageNumber} = this.state;

    let options = {
      query: JSON.stringify({}),
      sort: JSON.stringify({createdAt:-1}), 
      populate: 'materials.material,styleId|orderId|customer,supplierId',
      pageSize, 
      pageNumber,
    };
    return options
  }

  resetItems = items => {
    const {pageSize, pageNumber, notifies} = this.state;
    
    return items.map((item, idx) => {
      const idxN = _.findIndex(notifies, n => n._id == item._id)
      
      let amount = 0, exReceivedDate = ''
      if (item.materials && item.materials.length > 0) {
        let temp = item.materials.sort((a, b) => new Date(a.exReceivedDate) - new Date(b.exReceivedDate));
        amount = this.setCurrency(calculateTotal(item).toFixed(2), (item.supplier || {}).currency);
        exReceivedDate = temp[0].exReceivedDate
      }
      return {...item, check: idxN >= 0 ? true : false,  amount, no: (pageSize * (pageNumber - 1)) + idx + 1, exReceivedDate}
    })
  }
  setCurrency = (amount, currency) => {
    if(currency == 'VND') {
      return amount + ' VND';
    }
    if(currency == 'USD') {
      return '$' + amount;
    }
    return amount
  }

  onChangeSearch = (search, property, value, pos) => {
    // const { search } = this.state;
    let newSearch = {}
    switch(property) {
      case SEARCH_BAR_EVENT.FILTER: {
        newSearch = {key: value, value: ""}
        break;
      }
      case SEARCH_BAR_EVENT.SEARCH: {
        if( this.state.search.key ==='orderDate'){
          if( pos === 'from'){
            search.beginDate = value
            newSearch = {...search}
          }
          if( pos === 'to'){
            search.endDate = value
            newSearch = {...search}
          }
        }else{
          newSearch = {...search, value : value }  
        }
        break;
      }
    }
    return newSearch;
  }

  onSearch = (page = null, search = {}) => {
    // const {search} = this.state;
    const options = this.getBaseOption()
    options.query = JSON.parse(options.query)
    options.pageNumber = page != null ? page : options.pageNumber
    // if(search && search.key && search.value) {
    //   let qr = {}
    //   switch(search.key) {
    //     case FOLLOWUP_SEARCH_KEY.TYPE: {
    //       qr = {type: {$regex: '.*' + search.value + '.*', $options: 'i'}}
    //       options.query = qr
    //       break;
    //     }
    //     case FOLLOWUP_SEARCH_KEY.PURCHASE_ORDER_NO: {
    //       qr = {purchaseNo: {$regex: '.*' + search.value + '.*', $options: 'i'}}
    //       options.query = qr
    //       break;
    //     }
    //   }
    // }

    this.props.dispatch({type: types.APP.SHOW_LOADING})
    // const options = {}
    return new Promise((resolve, reject) => {
      purchaseApi.search(search, options, FOLLOWUP_SEARCH_KEY).then(rs => {
        this.props.dispatch({type: types.APP.HIDE_LOADING})
        
        if(rs.success) {
          resolve({
            items: this.resetItems(_.get(rs, 'data.items', {})),
            total: _.get(rs, 'data.count', 0)
          })
        } else {
          toastr.error(I18n.t('Notify.Error'), rs.error)
          reject(rs.error);
        }
      },
      err => {
        this.props.dispatch({type: types.APP.HIDE_LOADING})
        toastr.error(I18n.t('Notify.Error'), err.error)
        reject(err.error);
      })
    })
  }

  render() {
    const { tabCurrent, roles } = this.props;
    const modal = this.props.modal;
    let {isEditRole, roleId, purchase} = this.state;
    const pageCurrent = this.props.appState.page || {};
    let showEditRole = isEditRole && !!pageCurrent.isEdit;
    return (
        <div>
            <div id="content">
                {showEditRole &&
                <RoleFormScreen
                    handleAddEditRole={this.handleAddEditRole}
                    roleId={roleId}
                    isEditRole={isEditRole}
                />
                }
                {!showEditRole &&
      <div className={tabCurrent === '' ? 'show' : 'hide'}>
          <div className="global-table-tab wrap-position-filter">
              <ul className="nav nav-tabs wrap-tab" role="tablist">
                  <li role="presentation" className={this.props.appState.tabActive === 1 ? "active" : ""}>
                      <a onClick={()=>this.handleClickTab(1)}
                         aria-controls="shipping-follow-up" role="tab"
                         data-toggle="tab">{I18n.t('PageShiping.Follow-Up')}</a>
                      <span className="line"/>
                  </li>
                  <li role="presentation" className={this.props.appState.tabActive === 2 ? "active" : ""}>
                      <a onClick={()=>this.handleClickTab(2)}
                         aria-controls="shipping-ship-in"
                         role="tab" data-toggle="tab">{I18n.t('PageShiping.ShipIn')}</a>
                      <span className="line"/>
                  </li>
                  <li role="presentation" className={this.props.appState.tabActive === 3 ? "active" : ""}>
                      <a onClick={()=>this.handleClickTab(3)}
                         aria-controls="shipping-history" role="tab"
                         data-toggle="tab">{I18n.t('PageShiping.History')}</a>
                </li>
              </ul>

              <div className="tab-content">
            {/*<div role="tabpanel" className="tab-pane active" id="shipping-follow-up">*/}
              <TabFollowUp  {...this.props}
                onSearch={this.onSearch} 
                onChangeSearch={this.onChangeSearch} 
                active={this.props.appState.tabActive === 1}
                roles={roles}/>
            {/*</div>*/}
            {/*<div role="tabpanel" className="tab-pane" id="shipping-ship-in">*/}
              <TabShipIn modal={modal} {...this.props}
                onSearch={this.onSearch} 
                onChangeSearch={this.onChangeSearch} 
                active={this.props.appState.tabActive === 2}
                roles={roles}/>
            {/*</div>*/}
            {/*<div role="tabpanel" className="tab-pane" id="shipping-history">*/}
              <TabHistory  {...this.props}
                active={this.props.appState.tabActive === 3}
                roles={roles}/>
            {/*</div>*/}
          </div>


        </div>
      </div>
                }
            </div>
        </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
      appState: state.appState,
      authState: state.authState,
      roleState: state.roleState
  };
}

function mapDispatchToProps(dispatch) {
  return {
      appPropsActions: bindActionCreators(appActions, dispatch),
      dispatch
  };
}

ScreenShipIn.propTypes = {
    authState: PropTypes.object.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(ScreenShipIn);

