import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import BaseComponent from '../base/BaseComponent';
import { getExportWip } from '../../actions/reportAnalytic/revemueActions';
import I18n from "i18n-js";
import { withRouter } from "react-router";
import * as PATH from '../../constants/routeConstants';
import { Switch, Route, Redirect, Link } from "react-router-dom";
import CustomerPOContainer from '../../ui/containers/reportAnalytic/customerPOReport';
import RevenueContaier from '../../ui/containers/reportAnalytic/revenueReport';
let FileSaver = require('file-saver');

class PageReportAnalytic extends BaseComponent {
    constructor(props, context) {
        super(props, context);
        this.state = {};
    }

    btGetExport() {
        let url = this.props.getExportWip();
        FileSaver.saveAs(url, "Report_PKL_Product.xlsx");
    }

    render() {
        const pathname = this.props.router.route.location.pathname || '';
        return (
            <div>
                <div id="report-analytic">
                    {/* <ul className="nav nav-tabs wrap-tab pd-5 mg-top-5" role="tablist">
                        <li role="presentation"  
                            className={pathname.startsWith(PATH.REPORT_ANALYTIC_CUSTOMER_PO_URL) ? "active" : ''}
                        >
                            <Link to={PATH.REPORT_ANALYTIC_CUSTOMER_PO_URL}
                                aria-controls="tab-plan"
                                role="tab" data-toggle="tab"
                            >
                                CUSTOMER PO
                            </Link>
                            <span className="line">|</span>
                        </li>
                        <li role="presentation"
                             className={pathname.startsWith(PATH.REPORT_ANALYTIC_REVENUE_URL) ? "active" : ''}
                        >
                            <Link to={PATH.REPORT_ANALYTIC_REVENUE_URL}
                                aria-controls="tab-plan"
                                role="tab" data-toggle="tab"
                            >
                                REVENUE
                            </Link>
                            <span className="line" />
                        </li>
                    </ul> */}

                    <div className="tab-content bg-none" id="tab-report">
                        <Switch>
                            <Route path={PATH.REPORT_ANALYTIC_CUSTOMER_PO_URL} component={CustomerPOContainer} />
                            <Route path={PATH.REPORT_ANALYTIC_REVENUE_URL} component={RevenueContaier} />
                            <Route path={PATH.REPORT_ANALYTIC_URL}  render={() => <Redirect to = {PATH.REPORT_ANALYTIC_CUSTOMER_PO_URL} />} />
                        </Switch>
                    </div>
                </div>
            </div >
        );
    }
}

const mapStateToProps = state => ({
    appState: state.appState,
    authState: state.authState
});

const mapDispatchToProps = dispatch => ({
    getExportWip() {
        return getExportWip();
    }
});

PageReportAnalytic.propTypes = {
    authState: PropTypes.object.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PageReportAnalytic));

