import React, { Component } from 'react';
import I18n from 'i18n-js';

import xlxsIcon from '../../../../stylesheets/images/xlxs.svg';
import createINVIcon from '../../../../stylesheets/images/ic-createINV.svg';
import icXIcon from '../../../../stylesheets/images/ic-x.svg';
import icEditXIcon from '../../../../stylesheets/images/ic-edit-x.svg';

const style = {
    width: '100%'
}
const tableListForPklShip = props => {
    return (
        <table style={style} className="box-shadow bg-white global-table">
            <tbody>
                <tr>
                    <th />
                    <th className="center">{I18n.t('PageShiping.PackingType')}</th>
                    <th className="center">{I18n.t('PageShiping.Customer')}</th>
                    <th className="center">{I18n.t('PageShiping.CustomerPO')}</th>
                    <th className="center">{I18n.t('PageShiping.QTY')}</th>
                    <th className="center">{I18n.t('PageShiping.ExFactory')}</th>
                    <th className="center">{I18n.t('PageShiping.INVNo')}</th>
                    <th className="center">{I18n.t('PageShiping.INVDate')}</th>
                    <th className="center">{I18n.t('PageShiping.Actions')}</th>
                </tr>
                {props.items.map((item, index) => {
                    return (
                        <tr key={index}>
                            <td className="center style-checkbox">
                                <input type="checkbox"
                                    id={item._id}
                                    checked={item.checked}
                                    value="on"
                                    disabled = {item.checkable}
                                    onClick={() => props.onCheck(item, !item.checked)} />
                                <label htmlFor={item._id}>
                                {item.checkable ? <span style={{backgroundColor: "#d4d0d0"}}><span/></span> : <span className="cursor-pointer"><span/></span>}
                                </label>
                            </td>
                            <td className="center">{item.packingType}</td>
                            <td className="center">{item.customer}</td>
                            <td className="center color-link dropdown-table-style">
                                <div className="dropdown">
                                    <button
                                        className="btn btn-secondary dropdown-toggle color-link text-decoration" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                    >
                                        <span>{item.customerPO}</span>
                                    </button>
                                    <div className="dropdown-menu box-shadow w-300">
                                        {(item.order || []).map((it, idx) => {
                                            return (
                                                <div key={idx} className="center mg-top-10 mg-bottom-10"><span >{it.customerPO}</span></div>
                                            )
                                        })}
                                        <span className="button-ok center color-orange bold cursor-pointer">OK</span>
                                    </div>
                                </div>
                            </td>
                            <td className="center">{item.qty}</td>
                            <td className="center">{item.notifyDate}</td>
                            <td className="center">{item.invNo}</td>
                            <td className="center">{item.invDate}</td>
                            <td className="center">
                                <span className="mg-right-5" data-toggle="tooltip" data-placement="bottom" title="Export APL">
                                    <img
                                        src={xlxsIcon}
                                        alt="Edit"
                                        className="cursor-pointer"
                                        onClick={() => props.exportFirst(item)}
                                    />
                                </span>
                                <span className="mg-right-5" data-toggle="tooltip" data-placement="bottom" title={'Export PKL & INV'}>
                                    <img
                                        src={xlxsIcon}
                                        alt="Export"
                                        className="cursor-pointer"
                                        onClick={() => props.exportSecond(item)}
                                    />
                                </span>
                                {(props.roles.showCreate && props.roles.showEdit) && <span className="mg-right-5" data-toggle="tooltip" data-placement="bottom" title="Create INV">
                                    <img
                                        src={createINVIcon}
                                        alt="Create"
                                        className="cursor-pointer"
                                        onClick={() => props.createInvoice(item)}
                                    />
                                </span>}
                                {props.roles.showEdit && <span data-toggle="tooltip" data-placement="bottom" title="Edit APL">
                                    <img
                                        src={icEditXIcon}
                                        alt="Edit"
                                        className="cursor-pointer"
                                        onClick={() => props.edit(item)}
                                    />
                                </span>}
                                {(item.isDelete && !item.checkable && props.roles.showDelete) &&
                                    <span data-toggle="tooltip" data-placement="bottom" title="Delete APL">
                                    <img
                                    src={icXIcon}
                                    alt="Delete"
                                    className="cursor-pointer"
                                    onClick={() => props.delete(item)}
                                    />
                                    </span>
                                }
                            </td>
                        </tr>
                    )
                })}
                <tr>
                </tr>
            </tbody>
        </table>
    );

}

export default tableListForPklShip;