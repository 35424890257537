import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BaseComponent from '../../base/BaseComponent';
import stockOutActions from '../../../actions/stockOutActions';
const DateTimeField = require('react-datetime');
import _ from 'lodash';
import moment from 'moment';
import I18n from 'i18n-js';
import TextNumberInput from "../../common/fields/TextNumberInput";
import {toastr} from "react-redux-toastr";

class StockOutAdd extends BaseComponent {
  constructor (props, context) {
    super(props, context);
    this.state = {
      item: {},
      order: {},
      style: {},
      contractors: [],
      stockOut: {},
      checkAll: false
    };
  }

  getStockOut = mts => {
    return {
      ...this.state.item,
      materials: mts.map(vl => ({
        material: vl.material,
        color: vl.color,
        size: vl.size,
        quantity: vl.quantity,
        reserve: vl.reserve, 
        outbound: vl.outbound, 
        actual: 0,
        roll: 0,
        deliveryDate: new Date(),
        check: false
      }))
    }
  }

  componentWillMount = () => {
    const {data} = this.props;
    let item = _.pick(data, ['orderId', 'styleId', 'contractorId', 'cutTickets']);
    this.setState({
      item,
      order: _.cloneDeep(data.order) || {},
      style: _.cloneDeep(data.style) || {},
      contractor: _.cloneDeep(data.contractor) || {}
    });

    this.getListMaterial(item.styleId, {query: {cutTickets: item.cutTickets.reduce((arr,i) => [...arr, i._id], [])}});
  }

  componentWillReceiveProps = (newProps) => {
    let {stockOut} = this.state;

    let stockOutNew = this.getStockOut(_.get(newProps, 'stockOutState.materials', []));
    stockOutNew && (stockOutNew.materials || []).map(m => {
      if ((m.reserve + m.outbound) > m.quantity ) {
        m.actual = (m.quantity - m.outbound) > 0 ? (m.quantity - m.outbound) : 0;
      } else {
        m.actual = m.reserve;
      }
      return m;
    });
    !_.isEqual(stockOutNew, stockOut) && this.setState({stockOut: stockOutNew});
  }

  componentWillUpdate = (nextProps, nextState) => {
    if (_.get(this.props.stockOutState, 'reload') != _.get(nextProps.stockOutState, 'reload')) {
      _.isFunction(this.props.closeFormAdd) && this.props.closeFormAdd();
    }
  }

  getListMaterial = (styleId, options = {}) => {
    this.props.stockOutPropsActions.getMaterials(styleId, options);
  }

  onCheckbox = (idx, value) => {
    let {stockOut, checkAll} = this.state;
    if(idx != null){
      _.set(stockOut.materials[idx] || {}, 'check', value);
      const count = stockOut.materials.filter(m => m.check == true).length;
      checkAll = count == stockOut.materials.length ? true: false;
      this.setState({stockOut, checkAll});
    }else{
      stockOut.materials.forEach((_, index) => stockOut.materials[index].check = value);
      this.setState({stockOut, checkAll: value});
    }
    
  }

  onChangeMaterials = (idx, property, value) => {
    let {stockOut} = this.state;
    if(property == 'actual') {
      const v = parseFloat(value || 0) || 0
      const max = stockOut.materials[idx].reserve;
      if(v > max) value = max;
      if(v < 0) value = 0;
    }
    _.set(stockOut.materials[idx] || {}, property, value);
    this.setState({stockOut});
  }

  save () {
    let {stockOut, contractor, order, style} = this.state;
    const data = {...stockOut, materials: stockOut.materials.filter(vl => vl.check), contractor: contractor.name, customerPO: order.customerPO, style: style.style}
    for (const mt of data.materials) {
      mt.deliveryDateFomat = new moment(mt.deliveryDate).format("DD/MM/YYYY")
    }
    let bool = data.materials.length == 0
    if(bool) {
      toastr.info(I18n.t('Notify.Info'), I18n.t('ErrorMessages.PleaseCheck'))
      return
    }
    
    bool = !!data.materials.find(vl => vl.check && vl.actual == 0) 
    if(bool) {
      toastr.info(I18n.t('Notify.Info'), I18n.t('ErrorMessages.PleaseInputActual'))
      return
    }
    this.props.stockOutPropsActions.addItem(data).then(res => {
      if(res.statusCode == 555)
        toastr.error(I18n.t('Notify.Error'), I18n.t('ErrorMessages.ReserveActualServer'))
    })
  }

  renderDate = (idx, fieldName, item) => {
    const onChange = date => {
      const {stockOut} = this.state
      stockOut.materials[idx][fieldName] = date.format()
      
      this.setState({stockOut})
    }
    return (
      <div>
        <DateTimeField onChange={onChange} timeFormat={false}  dateFormat='MMM DD, YYYY' value={moment(item[fieldName])} />
      </div>
    )
  }

  render () {
    let {item, order, style, stockOut, contractor, checkAll} = this.state;
    let styles = _.get(order, 'styles') || [];
    let w100 = {
      width: '100%'
    };
    return (
      <div id="content">
        <div className="wrap-filter-title w-full pd-5 inline-block ">
          <div className="bg-white w-full inline-block pd-10">
            <div className="float-left list-filter">
              <div className="filter select">
                <p>{I18n.t('PageWareHouse.Contractor')}</p>
                <p className="color-orange">{contractor.name}</p>
              </div>
              <div className="filter select">
                <p>{I18n.t('PageWareHouse.CustomerPO')}</p>
                <p className="color-orange">{order.customerPO}</p>
              </div>
              <div className="filter select">
                <p>{I18n.t('PageWareHouse.Style')}</p>
                <p className="color-orange">{style.style}</p>
              </div>
            </div>
            <div className="float-right add-filter-top ">
              <span className="cursor-pointer global-add" onClick={() => this.save()}><i className="fa fa-floppy-o" aria-hidden="true" /> {I18n.t('Btn.Save')}</span>
            </div>
          </div>
        </div>

        <div className="wrap-global-table">
          <table style={w100} className="global-table data-time-table warehouse">
            <tbody className="body-data-time">
              <tr>
                <th className="center style-checkbox">
                  <input type="checkbox" id="checkAll" checked={checkAll} onChange={evt => this.onCheckbox(null, !!evt.target.checked)} value />
                  <label className="position-relative top-s-10" htmlFor="checkAll"><span><span /></span></label>
                </th>
                <th className="center">{I18n.t('PageWareHouse.DeliveryDate')}</th>
                <th className="center">{I18n.t('PageWareHouse.Material')}</th>
                <th className="center">{I18n.t('PageWareHouse.Content')}</th>
                <th className="center">{I18n.t('PageWareHouse.Color')}</th>
                <th className="center">{I18n.t('PageWareHouse.Size')}</th>
                <th className="center">{I18n.t('PageWareHouse.Needed')}</th>
                <th className="center">{I18n.t('PageWareHouse.Reserve')}</th>
                <th className="center">{I18n.t('PageWareHouse.Outbound')}</th>
                <th className="center">{I18n.t('PageWareHouse.Actual')}</th>
                <th className="center">{I18n.t('PageWareHouse.Unit')}</th>
                <th className="center">{I18n.t('PageWareHouse.Roll')}</th>
              </tr>

              {(stockOut.materials || []).map((i, idx) => (
                <tr key={idx}>
                  <td className="center style-checkbox">
                    <input type="checkbox" id={`check` + idx} checked={!!i.check} onChange={evt => this.onCheckbox(idx, !!evt.target.checked)} value />
                    <label className="position-relative top-s-10" htmlFor={`check` + idx}><span><span /></span></label>
                  </td>
                  <td className='center w-180 select date-time-td'>
                    {this.renderDate(idx, "deliveryDate", i)}
                  </td>
                  <td className="center">{_.get(i, 'material.name', '')}</td>
                  <td className="center">{_.get(i, 'material.data.content', '')}</td>
                  <td className="center">{i.color || ''}</td>
                  <td className="center">{i.size || ''}</td>
                  <td className="center">{i.quantity || 0}</td>
                  <td className="center">{i.reserve || 0}</td>
                  <td className="center">{i.outbound || 0}</td>
                  <td className="center w-110">
                    <TextNumberInput value={i.actual || 0} onChange={val => this.onChangeMaterials(idx, 'actual', val)} className="form-control center" />
                  </td>
                  <td className="center">{_.get(i, 'material.data.unit', '')}</td>
                  <td className="center w-90">
                    <input type="number" value={i.roll || ''} onChange={evt => this.onChangeMaterials(idx, 'roll', evt.target.value)} className="form-control center" />
                  </td>
                </tr>
              ))}

            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

function mapStateToProps (state, ownProps) {
  return {
    authState: state.authState,
    stockOutState: state.stockOutState,
  };
}

function mapDispatchToProps (dispatch) {
  return {
    stockOutPropsActions: bindActionCreators(stockOutActions, dispatch),
  };
}

StockOutAdd.propTypes = {
  authState: PropTypes.object.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(StockOutAdd);
