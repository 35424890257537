/* eslint-disable import/no-unresolved */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import DateTimeField from 'react-datetime';
import * as R from 'ramda';
import moment from 'moment';
import _ from 'lodash';

import * as PATH from '../../../../constants/routeConstants';

import FormGroup from '../../forms/FormGroup';
import Input from '../../forms/Input';
import SearchSelect from '../../forms/SearchSelect';
import SideBar from '../PayablePlan/SideBar';

const AccountsPayablePlanSupplierOversea = props => {
  // console.log(props)
  return (
    <div>
      <div
        style={{
          float: 'right',
          top: '-50px',
          right: '10px',
          position: 'absolute',
        }}>
        {props.roles.showCreate && (
          <ul className="inline-block">
            <li className="float-left style-icon-save top-7 position-relative">
              <span className="cursor-pointer" onClick={() => props.save(props.dataApi)}>
                <i className="fa fa-floppy-o" aria-hidden="true"></i>
                SAVE
              </span>
            </li>
          </ul>
        )}
      </div>
      <form
        onSubmit={e => {
          e.preventDefault();
          props.onSubmit();
        }}>
        <div id="header-accounting" className="inline-block w-full position-relative page-accounting-supplier">
          <div className="left w-200" />
          <div className="right top-0 w-full pd-left-230">
            <div className="border-title-left" />
            <div className="title-left left">
              <div className="btn-group btn-supplier-contractor-importExport">
                <button
                  className="btn btn-secondary btn-lg dropdown-toggle"
                  type="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false">
                  Supplier
                </button>
                <div className="dropdown-menu">
                  <ul>
                    <li>
                      <Link to={PATH.ACCOUNTING_PAYABLE_PLAN_CONTRACTOR_URL}>Contractor</Link>
                    </li>
                    <li>
                      <Link to={PATH.ACCOUNTING_PAYABLE_PLAN_IMPORTEXPORT_CONTAINER_URL}>Import/Export</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <ul className="type-supplier">
                <li className="cursor-pointer">
                  <span className="cursor-pointer" onClick={() => props.onDomesticClick()}>
                    <span className="icon">
                      <span />
                    </span>
                    <span className="text">DOMESTIC</span>
                  </span>
                </li>
                <li className="active">
                  <span>
                    <span className="icon">
                      <span />
                    </span>
                    <span className="text">OVERSEA</span>
                  </span>
                </li>
              </ul>
            </div>
            <div className="bd-dashed-bottom wrap-input-global pd-15-0 margin-small">
              <div className="col-md-6 col-xs-12 input">
                <FormGroup
                  label="Supplier"
                  type="select"
                  inputComponent={
                    <SearchSelect
                      options={props.selectableSuppliers || []}
                      value={props.selectedSupplier || ''}
                      onChange={o => props.changeSelectedSupplier(o)}
                      isLoading={props.fetchingSelectableSuppliers}
                      clearable
                    />
                  }
                />
              </div>

              <div className="col-md-6 col-xs-12 input">
                <FormGroup
                  type="input"
                  label="Issued Date"
                  inputComponent={
                    <DateTimeField
                      timeFormat={false}
                      closeOnSelect
                      dateFormat="MMM DD, YYYY"
                      value={moment(props.issuedDate)}
                      onChange={date => props.changeIssuedDate(date.format())}
                    />
                  }
                />
              </div>
              <div className="col-md-6 col-xs-12 input">
                <FormGroup
                  type="input"
                  label="Factor"
                  inputComponent={
                    <Input type="text" value={props.factor || ''} onChange={e => props.changeFactor(e.target.value)} />
                  }
                />
              </div>
              <div className="col-md-6 col-xs-12 input">
                <ul className="position-relative">
                  <li>
                    <span>Payment Term</span>
                  </li>
                  <li>
                    <span style={{ display: 'inline-block', marginTop: 7 }}>{props.paymentTerm}</span>
                  </li>
                </ul>
              </div>
              <div className="col-md-6 col-xs-12 input">
                <FormGroup
                  type="input"
                  label="Invoice No."
                  inputComponent={
                    <Input
                      type="text"
                      value={props.invoiceNo || ''}
                      onChange={e => props.changeInvoiceNo(e.target.value)}
                    />
                  }
                />
              </div>
              <div className="col-md-6 col-xs-12 input">
                <FormGroup
                  type="input"
                  label="Due Date"
                  inputComponent={
                    <DateTimeField
                      timeFormat={false}
                      closeOnSelect
                      dateFormat="MMM DD, YYYY"
                      value={moment(props.dueDate)}
                      onChange={dueDate =>
                        dueDate.valueOf() > moment(props.issuedDate).valueOf() &&
                        props.changeDueDate(dueDate.format(), props.issuedDate)
                      }
                    />
                  }
                />
              </div>
              <div className="col-md-6 col-xs-12 input">
                <FormGroup
                  label="Credit/Debit"
                  type="select"
                  inputComponent={
                    <SearchSelect
                      options={props.selectableCredits}
                      value={props.selectedCredit || ''}
                      onChange={o => props.changeSelectedCredit(o)}
                      isLoading={props.fetchingSelectableCredits}
                      clearable
                    />
                  }
                />
              </div>
            </div>

            <div className="bottom text-left size-12 pd-15-0 inline-block w-full">
              <div className="col-md-4 col-sm-6 col-xs-12">
                <p className="size-17">
                  <span className="mg-right-15">Amount INV</span>{' '}
                  <span className="bold color-number">{props.invAmount}</span>
                </p>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12">
                <p className="size-17">
                  <span className="mg-right-15">Total Amount INV</span>{' '}
                  <span className="bold color-number">{props.invTotalAmount}</span>
                </p>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12">
                <p className="size-15">
                  <span className="mg-right-15">Say</span> <span className="bold">{props.invTotalAmountSay}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="content-main-accounting">
          <SideBar
            searchText={props.filterPOText}
            placeholder="Search"
            items={props.checkablePurchaseOrders}
            changeChecked={item => props.changeCheckedPurchaseOrder(item)}
            changeSearchText={value => props.changeFilterPOText(value)}
          />
          <div className="panel-group mg-0" id="panel-heading-main" role="tablist" aria-multiselectable="true">
            {_.map(props.editablePurchaseOrders, (item, index) => (
              <div className="panel panel-default bd-none radius-0" key={index}>
                {/* <!-- Component --> */}

                <div className="panel-heading component" role="tab" id="">
                  <div className="panel-title">
                    <a role="button" onClick={() => props.toggleActivePurchaseOrder(item)}>
                      <i className="fa fa-caret-down" aria-hidden="true" />
                    </a>
                    <ul>
                      <li>
                        <div className="text">
                          <span className="left">Purchase Order</span>
                          <span className="right size-15">
                            <span className="medium">{R.path(['name'], item)}</span>
                          </span>
                        </div>
                      </li>
                      <li className="center">
                        <div className="text">
                          <span className="left">Total Purchase</span>
                          <span className="right size-15">
                            <span className="medium">
                              {R.path(['totalPurchase'], item)} {R.path(['currency'], item)}
                            </span>
                          </span>
                        </div>
                      </li>
                      <li>
                        <div className="text mg-0">
                          <span className="right">
                            <span className="medium">{R.path(['customer'], item)}</span>
                          </span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>

                {R.path(['active'], item) && (
                  <div
                    id={R.path(['_id'], item)}
                    className="panel-collapse collapse in"
                    role="tabpanel"
                    aria-labelledby={R.path(['_id'], item)}>
                    <div className="panel-body pd-0 bg-gray">
                      <div className="wrap-global-table pd-0 inline-block w-full float-left mg-bottom-0">
                        <table className="global-table box-shadow-none w-full">
                          <tbody>
                            <tr className="bg-white bd-solid-bottom">
                              <th>Material</th>
                              <th className="center">Color</th>
                              <th className="center">Payer</th>
                              <th className="center">ETA</th>
                              <th className="center">Order Quantity</th>
                              <th className="center">Received Quantity</th>
                              <th className="center">INV No</th>
                              <th className="center">Price</th>
                              <th className="center">Amount</th>
                            </tr>

                            {_.map(R.pathOr([], ['items'], item), (m, idx) => (
                              <tr key={idx}>
                                <td>{R.path(['name'], m.material)}</td>
                                <td className="center">{R.path(['color'], m)}</td>
                                <td className="center">{R.path(['payer'], m)}</td>
                                <td className="center">{moment(R.path(['eta'], m)).format('MMM DD, YYYY')}</td>
                                <td className="center">{R.path(['orderQuantity'], m)}</td>
                                <td className="center">{R.path(['receivedQuantity'], m)}</td>
                                <td className="center">
                                  <Input
                                    type="text"
                                    value={R.path(['invNo'], m)}
                                    onChange={e =>
                                      props.changePurchaseInvoiceNo(
                                        R.path(['_id'], item),
                                        R.path(['_id'], m),
                                        idx,
                                        e.target.value
                                      )
                                    }
                                  />
                                </td>
                                <td className="center">{R.path(['price'], m)}</td>
                                <td className="center color-number">
                                  {R.path(['amount'], m)} {R.path(['currency'], item)}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </form>
      <div className="center clearBoth mg-top-15">
        <button
          className={props.canPreview ? 'bt-orange text-uppercase' : 'cursor-text display-none text-uppercase'}
          style={{ marginRight: 10 }}
          onClick={() => {
            props.onPreviewScreen();
          }}
          disabled={!props.canPreview}>
          {'PREVIEW'}
        </button>
      </div>
    </div>
  );
};

AccountsPayablePlanSupplierOversea.propTypes = {
  isVATPerMonthChecked: PropTypes.bool.isRequired,
  selectableSuppliers: PropTypes.arrayOf(PropTypes.any).isRequired,
  fetchingSelectableSuppliers: PropTypes.bool.isRequired,
  selectedSupplier: PropTypes.string.isRequired,

  issuedDate: PropTypes.string.isRequired,
  invoiceNo: PropTypes.string.isRequired,
  factor: PropTypes.string.isRequired,
  dueDate: PropTypes.string.isRequired,
  paymentTerm: PropTypes.string.isRequired,

  selectableCredits: PropTypes.arrayOf(PropTypes.arrayOf).isRequired,
  fetchingSelectableCredits: PropTypes.bool.isRequired,
  selectedCredit: PropTypes.string.isRequired,

  invAmount: PropTypes.string.isRequired,
  invTotalAmount: PropTypes.string.isRequired,
  invTotalAmountSay: PropTypes.string.isRequired,

  filterPOText: PropTypes.string.isRequired,
  checkablePurchaseOrders: PropTypes.arrayOf(PropTypes.any).isRequired,
  editablePurchaseOrders: PropTypes.arrayOf(PropTypes.any).isRequired,

  selectableConvertCurrencies: PropTypes.arrayOf(PropTypes.any).isRequired,
  selectedConvertCurrency: PropTypes.string.isRequired,
  convertRate: PropTypes.string.isRequired,
  convertAmount: PropTypes.string.isRequired,
  convertTotalAmount: PropTypes.string.isRequired,

  mainVATNo: PropTypes.string.isRequired,
  mainVATDate: PropTypes.string.isRequired,
  mainVATTotalAmount: PropTypes.string.isRequired,
  mainVATAmount: PropTypes.string.isRequired,
  mainVATGrandTotal: PropTypes.string.isRequired,
  mainTaxRate: PropTypes.string.isRequired,

  onOverseaClick: PropTypes.func,
  changeSelectedSupplier: PropTypes.func,
  changeVATPerMonthChecked: PropTypes.func,
  changeIssuedDate: PropTypes.func,
  changeInvoiceNo: PropTypes.func,
  changeDueDate: PropTypes.func,
  changeSelectedCredit: PropTypes.func,
  changeFilterPOText: PropTypes.func,
  changeCheckedPurchaseOrder: PropTypes.func,
  changeSelectedConvertCurrency: PropTypes.func,
  changeConvertRate: PropTypes.func,

  changeMainVATNo: PropTypes.func,
  changeMainVATTotalAmount: PropTypes.func,
  changeMainVATDate: PropTypes.func,
  changeMainVATTaxRate: PropTypes.func,

  toggleActivePurchaseOrder: PropTypes.func,

  changeSubVATNo: PropTypes.func,
  changeSubVATDate: PropTypes.func,
  changeSubVATTaxRate: PropTypes.func,
  changeSubVATNote: PropTypes.func,
  changeFactor: PropTypes.func,
  changePurchaseInvoiceNo: PropTypes.func,
  save: PropTypes.func,
  dataApi: PropTypes.object,
  onSubmit: PropTypes.func,
};

AccountsPayablePlanSupplierOversea.defaultProps = {
  onOverseaClick: f => f,
  changeVATPerMonthChecked: f => f,
  changeSelectedSupplier: f => f,
  changeIssuedDate: f => f,
  changeInvoiceNo: f => f,
  changeDueDate: f => f,
  changeSelectedCredit: f => f,
  changeFilterPOText: f => f,
  changeCheckedPurchaseOrder: f => f,
  changeSelectedConvertCurrency: f => f,
  changeConvertRate: f => f,

  changeMainVATNo: f => f,
  changeMainVATTotalAmount: f => f,
  changeMainVATDate: f => f,
  changeMainVATTaxRate: f => f,

  toggleActivePurchaseOrder: f => f,

  changeSubVATNo: f => f,
  changeSubVATDate: f => f,
  changeSubVATTaxRate: f => f,
  changeSubVATNote: f => f,
  changeFactor: f => f,
  changePurchaseInvoiceNo: f => f,
  onSubmit: f => f,
  save: f => f,
};

export default AccountsPayablePlanSupplierOversea;
