import React, { Component } from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import _ from 'lodash';
import commonUseActions from '../../../actions/commonUseActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as packingActions from '../../../actions/production/packing';
import * as selector from '../../../selectors/production/addPacking';

class ModalEditCartonPerPack extends Component {
  constructor(props, context) {
    super(props, context);
    const { data, packing, packedStyles } = this.props;
    const { styleId, no } = data;
    const pakingCarton = (packing.styles.find(s => s.style == styleId) || {}).packings.find(pk => pk.props.no == no);
    let items = _.cloneDeep(pakingCarton.items);
    this.state = {
      items,
    };
  }

  componentWillMount = () => {};

  componentWillReceiveProps(nextProps) {}

  ok = () => {
    const { data, packing, packedStyles } = this.props;
    const { items } = this.state;
    const { styleId, no } = data;
    this.props.packingActions.editItemOfpacking(styleId, packing, no, items);
    this.props.ok();
    this.props.closeModal();
  };

  onChangeGwKgsNwKgsPacking = (value, item) => {
    let { items } = this.state;
    let item2 = items.find(i => i.name == item.name && i.color == item.color && i.cutTicket == item.cutTicket);
    value = value < 1 ? 1 : value;
    if (['gwKgs', 'nwKgs'].includes(item2.name)) {
      item2.value = value > item2.maxValue ? item2.maxValue : value;
    } else {
      item2.actualQuantity = value > item2.planQuantity ? item2.planQuantity : value;
    }
    this.setState({ items });
  };

  onChangeActualPacking = (value, styleId, cutId, colorId, sizeName, no, total) => {
    let { carton } = _.cloneDeep(this.state);
    if (Number(value) <= total) {
      carton.items.map(c => (c.name == sizeName ? (c.actualQuantity = Number(value)) : null));
    }
    this.setState({ carton });
  };

  render = () => {
    const { data, packing, packedStyles } = this.props;
    const { styleId, no } = data;
    let items = _.cloneDeep(this.state.items);
    const cutTickets = _.cloneDeep(packedStyles.find(s => s._id == styleId).cutTickets);
    const colors = _.flattenDepth(cutTickets.map(ct => ct.colors));
    items = _.reduce(
      _.groupBy(items, i => i.cutTicket),
      (result, value, key) => {
        result[key] = _.groupBy(value, i => i.color);
        return result;
      },
      {}
    );
    return (
      <div className="modal-dialog" role="document" id="modal-add-packing" style={{ maxWidth: 'unset', width: 750 }}>
        <div className="modal-content">
          <form>
            <div className="modal-header">
              <a className="close" data-dismiss="modal" aria-label="Close" onClick={this.props.closeModal}>
                <span aria-hidden="true">&times;</span>
              </a>
              <h4 className="modal-title" id="myModalLabel"></h4>
            </div>
            <div className="modal-body" s>
              {_.map(items, (itemColors, ct) => {
                const cutTicket = cutTickets.find(ctf => ctf._id == ct) || {};
                return (
                  <div style={{ margin: 5, marginLeft: 20 }}>
                    {cutTicket.cutTicket && (
                      <div class="text">
                        <span class="left size-12 mg-right-5">Cut Ticket</span>
                        <span class="color-orange bold mg-left-50 size-16">{cutTicket.cutTicket}</span>
                      </div>
                    )}
                    {_.map(itemColors, (itemSizes, c) => {
                      const color = colors.find(cf => cf._id == c) || {};
                      return (
                        <div className="display-flex bg-white">
                          <div className="display-flex align-items-center w-150">
                            <h3 className="center mg-auto size-12">
                              <span className="fw-normal">{color.name ? 'Color' : ''}</span>
                              <br />
                              <br />
                              <strong className="size-13">{color.name || ''}</strong>
                              <br />
                              <br />
                            </h3>
                          </div>
                          <div className="bd-solid-left">
                            <table className="global-table tr-height-30 box-shadow-none">
                              <tbody>
                                <tr className="bg-none" style={{ borderBottom: 'unset' }}>
                                  <td className="fw-normal">Plan</td>
                                  {itemSizes.map(item => {
                                    return (
                                      <td className="center">
                                        {item.name}: {item.value || item.planQuantity}
                                      </td>
                                    );
                                  })}
                                </tr>
                                <tr className="bg-none">
                                  <td className="fw-normal">Actual</td>
                                  {itemSizes.map(item => {
                                    return (
                                      <td className="center color-green">
                                        <input
                                          style={{ margin: 'auto' }}
                                          min="0"
                                          value={item.value || item.actualQuantity}
                                          onChange={evt => {
                                            this.onChangeGwKgsNwKgsPacking(evt.target.value, item);
                                          }}
                                          type="number"
                                          className="form-control center pd-right-0 mr-10 height-30 w-50"
                                        />
                                      </td>
                                    );
                                  })}
                                </tr>
                                <tr className="bg-none">
                                  <td className="fw-normal">Remaining</td>
                                  {itemSizes.map(item => {
                                    return (
                                      <td className="center color-green">
                                        {item.color
                                          ? (item.planQuantity || 0) - (item.actualQuantity || 0)
                                          : (item.maxValue || 0) - (item.value || 0)}
                                      </td>
                                    );
                                  })}
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
            <div className="modal-footer">
              <button type="button" className="bt-black" onClick={this.props.closeModal}>
                {I18n.t('Btn.CANCEL')}
              </button>
              <button type="button" className="bt-orange" onClick={() => this.ok()}>
                {I18n.t('Btn.OK')}
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  };
}

function mapStateToProps(state, ownProps) {
  return {
    appState: state.appState,
    authState: state.authState,
    getCarton: selector.getCarton(state),
    packedStyles: selector.getPackingByOrder(state),
    packing: selector.getPacking(state),
    getActualPacking: selector.getActualPacking(state),
    packingInfo: selector.getPackingInfo(state),
    listCartons: selector.getListCartonsForEdit(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    commonUseActions: bindActionCreators(commonUseActions, dispatch),
    packingActions: bindActionCreators(packingActions, dispatch),
    dispatch,
  };
}

ModalEditCartonPerPack.propTypes = {
  ok: PropTypes.func,
  commonUseActions: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalEditCartonPerPack);
