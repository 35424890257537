import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';

const ConfirmModal = (props) => {
    const style = {
        overlay: {
            position: 'fixed',
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
            zIndex: '3'
        },
        content: {
            position: 'auto',
            top: '50%',
            left: '50%',
            backgroundColor: 'rgba(0,0,0,0)',
            border: '0px',
            borderRadius: '0px',
            outline: 'none',
        },
    };
    const { openModal } = props

    return (
        <Modal style={style} isOpen={openModal}>

            <div className="modal-dialog" role="document" id="accounts-payable-invoice-receipt">
                <div className="modal-content">
                    <form>
                        <div className="modal-header">
                            <h4 className="modal-title" id="myModalLabel">Warning</h4>
                        </div>
                        <div className="modal-body pd-0">
                            <div className="col-xs-12">
                                <div className="col-xs-12 text no-padding-left">
                                    <span className='size-16'>Are you sure you want to delete this item?</span>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="bt-black" onClick={() => { props.close() }}>CANCEL</button>
                            <button type="button" className="bt-orange" onClick={() => { props.deleteInvoice(props.invoiceDeleteId), props.close() }}>OK</button>
                        </div>
                    </form>
                </div>
            </div>
        </Modal>
    );
}


ConfirmModal.propTypes = {
    openModal: PropTypes.bool,
    close: PropTypes.func,
    ok: PropTypes.func,
}
ConfirmModal.defaultProps = {
    openModal: false,
    close: f => f,
    ok: f => f
}

export default ConfirmModal;