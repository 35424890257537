import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import * as PATH from '../../../../constants/routeConstants';
import * as actions from '../../../../actions/accounting/receivable/createInvoiceNormal';
import * as selectors from '../../../../selectors/accounting/receivable/createInvoiceNormal';

import AccountsReceivablePlanView from '../../../components/accounting/receivable/CreatePlanNormalInvoice';
import _ from 'lodash';
import I18n from 'i18n-js';

class AccountsReceivablePlan extends Component {
  constructor(props) {
    super(props);
    this.state = { searchText: '', checkedCPO: {} };
  }

  componentWillMount() {
    this.props.loadData();
  }

  changeSearchText(value) {
    this.setState({
      searchText: value,
    });
  }
  filterBills() {
    const bills = this.props.checkableBills;
    const searchText = this.state.searchText;
    return bills.filter(b => (b.billNo || '').toLocaleLowerCase().includes((searchText || '').toLocaleLowerCase()));
  }

  changeCheckedCPO({ billNo, orderId, checked }) {
    const { checkedCPO } = this.state;
    const bill = checkedCPO[billNo] || {};
    bill[orderId] = checked;
    checkedCPO[billNo] = bill;
    this.setState({ checkedCPO });
  }

  save(data) {
    const { checkedCPO } = this.state;
    let errors = {};
    if (!data.invNo) {
      errors.invNo = I18n.t('Accounting.ErrorMessages.InvoiceNoIsRequired');
    }
    if (!data.customer) {
      errors.customer = I18n.t('Accounting.ErrorMessages.CustomerIsRequired');
    }
    if (!data.shipMode) {
      errors.shipMode = I18n.t('Accounting.ErrorMessages.ShipMode');
    }
    data.billsContainers.forEach(bill => {
      bill.orders = bill.orders.filter(od => _.get(checkedCPO, `${bill.billNo}.${od.order}`, true));
      if (!bill.orders || bill.orders.length == 0) {
        errors.order = I18n.t('Accounting.ErrorMessages.Order');
      }
    });

    if (_.isEmpty(errors)) {
      this.props.saveInvoice(data);
    } else {
      this.props.changeErrorMessage(errors);
    }
  }

  componentWillReceiveProps(nextProps) {
    let { saveSuccess } = nextProps;
    if (saveSuccess) {
      nextProps.reset();
      toastr.success(I18n.t('Notify.Success'), 'Save Success');
      nextProps.history.push(PATH.ACCOUNTING_RECEIVABLE_INVOICE_URL);
    }
  }

  handleChangeView(value) {
    //
    // if (value == "view-supplier") {
    //     this.props.history.push(PATH.ACCOUNTING_PAYABLE_PLAN_SUPPLIER_URL);
    // }
    // else if (value == "view-contractor") {
    //     this.props.history.push(PATH.ACCOUNTING_PAYABLE_PLAN_CONTRACTOR_URL);
    // }
    // else {
    //     this.props.history.push(PATH.ACCOUNTING_PAYABLE_PLAN_IMPORTEXPORT_URL);
    // }
  }
  render() {
    const { searchText, checkedCPO } = this.state;
    // console.log(searchText);
    return (
      <AccountsReceivablePlanView
        {...this.props}
        changeSearchText={value => this.changeSearchText(value)}
        filterBills={() => this.filterBills()}
        changeCheckedCPO={value => this.changeCheckedCPO(value)}
        checkedCPO={checkedCPO}
        save={data => this.save(data)}
      />
    );
  }
}

AccountsReceivablePlan.propTypes = {};

AccountsReceivablePlan.defaultProps = {};

const mapStateToProps = state => ({
  selectableCustomers: selectors.getSelectableCustomers(state),
  selectedCustomer: selectors.getSelectedCustomer(state),
  selectableCreditDebits: selectors.getSelectableCreditDebits(state),
  selectedCreditDebit: selectors.getSelectedCreditDebit(state),
  isVATPerCustomer: selectors.isVATPerCustomer(state),
  invoiceNo: selectors.getInvoiceNo(state),
  creditForSeaFreight: selectors.getCreditForSeaFreight(state),
  selectablePriceTypes: selectors.getSelectablePriceTypes(state),
  priceType: selectors.getSelectedPriceType(state),
  issuedDate: selectors.getIssuedDate(state),
  dueDate: selectors.getDueDate(state),
  selectableConvertCurrencies: selectors.getSelectableConvertCurrencies(state),
  selectableShippingMode: selectors.getSelectableShippingModes(state),
  selectedShippingMode: selectors.getSelectedShippingMode(state),
  convertType: selectors.getSelectedConvertCurrency(state),
  convertRate: selectors.getConvertRate(state),
  mainVATAmount: selectors.getMainVATAmount(state),
  mainVATDate: selectors.getMainVATDate(state),
  mainVATTaxRate: selectors.getMainVATTaxRate(state),
  mainVATNo: selectors.getMainVATNo(state),
  mainVATGrandTotal: selectors.getMainVATGrandTotal(state),
  mainVATTotalAmount: selectors.getMainVATTotalAmount(state),
  fetchingSelectableCustomer: selectors.isFetchingSelectableCustomer(state),
  checkableBills: selectors.getCheckableBills(state),
  checkedBills: selectors.getCheckedBills(state),
  dataRequestShipOut: selectors.getDataRequestShipOutByCustomer(state),
  dataApi: selectors.getDataApi(state),
  amountInv: selectors.getAmountInv(state),
  totalAirCost: selectors.getTotalAirCost(state),
  totalAmountInv: selectors.getTotalAmountInv(state),
  totalAmountSay: selectors.getTotalAmountSay(state),
  totalCreditForSeaFreight: selectors.getTotalCreditForSeaFreight(state),
  paymentTerm: selectors.getPaymentTerm(state),
  amountConvert: selectors.getAmountConvert(state),
  totalAmountConvert: selectors.getTotalAmountConvert(state),
  customerCurrency: selectors.getSelectedCustomerCurrency(state),
  errorMessages: selectors.getErrorMessages(state),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  loadData() {
    dispatch(actions.listCustomer());
  },
  changeSelectedCustomer(id) {
    dispatch(actions.resetData());
    dispatch(actions.changeSelectedCustomer(id));

    if (id && id.customer && id.customer.value) {
      dispatch(actions.listCreditDebitNote(id.customer.value));
    }
  },
  changeSelectedCreditDebit(data) {
    dispatch(actions.changeSelectedCreditDebit(data));
  },
  changeVATPerCustomer(checked) {
    dispatch(actions.changeVATPerCustomer(checked));
  },
  changeInvoiceNo(value) {
    dispatch(actions.changeInvoiceNo(value));
  },
  changeCreditForSeaFreight(value) {
    dispatch(actions.changeCreditForSeaFreight(value));
  },
  changePriceType(value) {
    dispatch(actions.changePriceType(value));
  },
  changeIssuedDate(value) {
    dispatch(actions.changeIssuedDate(value));
  },
  changeDueDate(value) {
    dispatch(actions.changeDueDate(value));
  },
  changeShippingMode(value) {
    dispatch(actions.resetData());
    dispatch(actions.changeShippingMode(value));
  },
  changeConvertType(value) {
    dispatch(actions.changeConvertType(value));
  },
  changeConvertRate(value) {
    dispatch(actions.changeConvertRate(value));
  },
  changeCheckedBill(data) {
    dispatch(actions.changeCheckedBill(data));
  },
  // changeSearchText(value) {
  //   dispatch(actions.changeSearchText(data));
  // },
  changeUnitPrice(billNo, customerPO, index, value) {
    dispatch(actions.changeUnitPrice({ billNo, customerPO, index, value }));
  },
  changeAirCost(billNo, customerPO, value) {
    dispatch(actions.changeAirCost({ billNo, customerPO, value }));
  },
  changeNoteBill(billNo, customerPO, value) {
    dispatch(actions.changeNoteBill({ billNo, customerPO, value }));
  },
  //--------- main VAT ---------//
  changeMainVATNo(value) {
    dispatch(actions.changeMainVATNo(value));
  },

  changeMainVATTotalAmount(value) {
    dispatch(actions.changeMainVATTotalAmount(value));
  },

  changeMainVATDate(value) {
    dispatch(actions.changeMainVATDate(value));
  },

  changeMainVATTaxRate(value) {
    dispatch(actions.changeMainVATTaxRate(value));
  },
  saveInvoice(data) {
    dispatch(actions.saveInvoice(data));
  },
  changeErrorMessage(errors) {
    dispatch(actions.changeErrorMessage(errors));
  },

  //--------- sub VAT ---------//
  changeSubVATTotalAmount(billNo, value) {
    dispatch(actions.changeSubVATTotalAmount({ billNo, value }));
  },
  changeSubVATNo(billNo, value) {
    dispatch(actions.changeSubVATNo({ billNo, value }));
  },
  changeSubVATDate(billNo, value) {
    dispatch(actions.changeSubVATDate({ billNo, value }));
  },
  changeSubVATTaxRate(billNo, value) {
    dispatch(actions.changeSubVATTaxRate({ billNo, value }));
  },
  changeSubVATNote(billNo, value) {
    dispatch(actions.changeSubVATNote({ billNo, value }));
  },
  getSubVATAmount(totalAmount, taxRate) {
    return ((parseFloat(totalAmount || 0) * parseFloat(taxRate || 0)) / 100).toFixed(2);
  },
  getSubVATGrandTotal(totalAmount, taxRate) {
    return ((parseFloat(totalAmount || 0) * parseFloat(taxRate || 0)) / 100 + parseFloat(totalAmount || 0)).toFixed(2);
  },
  getSubVATGrandTotal(totalAmount, taxRate) {
    return ((parseFloat(totalAmount || 0) * parseFloat(taxRate || 0)) / 100 + parseFloat(totalAmount || 0)).toFixed(2);
  },

  changeTypeInvoice() {
    ownProps.history.push(PATH.ACCOUNTING_RECEIVABLE_PLAN_CHARGE_BACK_URL);
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AccountsReceivablePlan));
