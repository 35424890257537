
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import * as R from 'ramda';

import ImagesGalleryModal from '../../../components/customer-po/ImageGalleryModal';

import * as selectors from '../../../../selectors/customer-po/current/showImagesGallery';
import * as actions from '../../../../actions/customer-po/current/showImagesGallery';


class ImagesGallery extends Component {
  render() {
    return (
      <ImagesGalleryModal
        {...this.props}
      />
    )
  }
}

const mapStateToProps = state => ({
  isOpen: selectors.isOpen(state),
  images: selectors.getImages(state),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onClose() {
    dispatch(actions.hideModal());
  },
});

export default connect(mapStateToProps, mapDispatchToProps) (withRouter(ImagesGallery));
