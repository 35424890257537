import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import authActions from '../../actions/authActions';
import { pathImage } from '../../functions/helpers';
import * as types from '../../constants/actionTypes';
import EditProfile from '../auth/EditProfile';
import ChangesPassword from '../auth/ChangePassword';
import _ from 'lodash';
import I18n from 'i18n-js';

import avatarDefaultIcon from '../../stylesheets/images/avatar-default.svg';

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  editProfile = item => {
    let { openModal, closeModal } = this.props.modal;
    let modal = <EditProfile closeModal={closeModal} />;
    openModal(modal);
  };

  changesPassword() {
    let { openModal, closeModal } = this.props.modal;
    let modal = <ChangesPassword closeModal={closeModal} />;
    openModal(modal);
  }

  signOut() {
    this.props.authPropsActions.signOut();
    this.props.router.history.push('/login');
  }

  render() {
    let user = this.props.authState.auth || {};
    let shortName =
      _.upperFirst(user.firstName ? user.firstName[0] : '') + _.upperFirst(user.lastName ? user.lastName[0] : '');
    return (
      <div className="header-block header-block-nav">
        <ul className="nav-profile">
          <li className="profile dropdown">
            <a
              className={user.avatar ? 'nav-link dropdown-toggle' : 'nav-link dropdown-toggle null'}
              data-toggle="dropdown"
              role="button"
              aria-haspopup="true"
              aria-expanded="false">
              <img className="avatar-dropdown" src={user.avatar ? pathImage(user.avatar) : avatarDefaultIcon} />
            </a>

            <div className="dropdown-menu profile-dropdown-menu" aria-labelledby="dropdownMenu1">
              <div className="info" onClick={() => this.editProfile()} style={{ cursor: 'pointer' }}>
                <ul>
                  <li className="cursor-pointer">
                    {user.avatar ? (
                      <img className="avatar-dropdown" src={user.avatar ? pathImage(user.avatar) : avatarDefaultIcon} />
                    ) : (
                      <span className="avatar-header-default">{shortName}</span>
                    )}
                  </li>
                  <li className="cursor-pointer">
                    <span className="">{user.fullName}</span>
                    <p style={{ wordBreak: 'break-all' }}>{user.email}</p>
                  </li>
                </ul>
              </div>
              <div className="control">
                <ul>
                  <li onClick={() => this.editProfile()} style={{ cursor: 'pointer' }}>
                    <span className="cursor-pointer">{I18n.t('Menu.UserProfile')}</span>
                  </li>
                  <li onClick={() => this.changesPassword()} style={{ cursor: 'pointer' }}>
                    <span className="cursor-pointer">{I18n.t('Menu.ChangePassword')}</span>
                  </li>
                </ul>
              </div>
              <div className="logout" onClick={() => this.signOut()} style={{ cursor: 'pointer' }}>
                <span className="cursor-pointer">{I18n.t('Menu.Logout')}</span>
              </div>
            </div>
          </li>
        </ul>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    appState: state.appState,
    authState: state.authState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    authPropsActions: bindActionCreators(authActions, dispatch),
    dispatch,
  };
}

Profile.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Profile));
