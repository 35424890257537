import {getSampleApprovalDAL, editSampleApprovalDAL, updateSampleApprovalDAL, getListSampleApprovalDAL, getListCustomerPODAL, getExportCurrent} from '../api/sampleApprovalApi';
import {getExportBom} from "../api/yieldApi";

class sampleApprovalServices {

  constructor() {
  }

  getDetail (styleId, query = {}, reset = true) {
    return getSampleApprovalDAL(styleId, query);
  }

  editSampleApproval (styleId, data) {
    return editSampleApprovalDAL(styleId, data);
  }

  updateSampleApproval (styleId, status){
    return updateSampleApprovalDAL(styleId, status);
  }

  getListSampleApproval (options){
    // options.query && _.set(options, 'query', JSON.stringify(options['query']));
    // options.sort && _.set(options, 'sort', JSON.stringify(options['sort']));
    // options.populate && _.set(options, 'populate', JSON.stringify(options['populate']));
    return getListSampleApprovalDAL(options);
  }

  getListCustomerPo (query = {}, reset = true) {
      return getListCustomerPODAL(query);
  }

  getExportCurrent (orderId) {
      return getExportCurrent(orderId);
  }
}

export default new sampleApprovalServices();
