import React  from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import BaseComponent from '../../base/BaseComponent';
import damagedMaterialActions from '../../../actions/damagedMaterialActions';
import TableList from '../../common/datatables/TableList';
import SearchBar from '../partial/SearchBar';
import _ from 'lodash';
import I18n from 'i18n-js';
import {SEARCH_BAR_EVENT, SEARCH_KEY, SEARCH_LIST} from "../../../constants/constants";
import CustomPagination from '../../common/datatables/CustomPagination';

class TabMaterial extends BaseComponent {
  constructor (props, context) {
    super(props, context);

    this.state = {
      pageSize: 10,
      pageNumber: 1,
      columns: [
        {name: I18n.t('PageWareHouse.Date'), value: 'updatedAt', type: 'date', formatType: 'MMM DD, YYYY'},
        {name: I18n.t('PageWareHouse.Material'), value: 'material.name'},
        {name: I18n.t('PageWareHouse.Content'), value: 'material.data.content'},
        {name: I18n.t('PageWareHouse.CustomerPO'), value: 'order.customerPO'},
        {name: I18n.t('PageWareHouse.Style'), value: 'style.style', options: {clsTh: 'center', clsTd: 'center'}},
        {name: I18n.t('PageWareHouse.PurchaseOrderNo'), value: 'purchaseOrder.purchaseNo', options: {clsTh: 'center', clsTd: 'center'}},
        {name: I18n.t('PageWareHouse.Source'), value: 'source', options: {clsTh: 'center', clsTd: 'center'}},
        {name: I18n.t('PageWareHouse.Color'), value: 'color', options: {clsTh: 'center', clsTd: 'center'}},
        {name: I18n.t('PageWareHouse.Size'), value: 'size', options: {clsTh: 'center', clsTd: 'center'}},
        {name: I18n.t('PageWareHouse.Quantity'), value: 'quantity', options: {clsTh: 'center', clsTd: 'center'}},
        {name: I18n.t('PageWareHouse.Unit'), value: 'material.data.unit', options: {clsTh: 'center', clsTd: 'center'}},
      ],
      actions: [],
      damagedMaterials: [],
      total: 0,
      search: {key: SEARCH_KEY.MATERIAL, value: ""}
    };

    this.selectPage = this.selectPage.bind(this);
  }

  componentWillMount() {
    let {pageSize, pageNumber} = this.state;
    this.getListItem({}, pageSize, pageNumber);
  }

  componentWillReceiveProps(newProps) {
    let {damagedMaterials, searchValue} = this.state;
    !_.isEqual(damagedMaterials, newProps.damagedMaterialState.items) && this.setState({
      damagedMaterials: _.cloneDeep(newProps.damagedMaterialState.items) || [],
      total: newProps.damagedMaterialState.count || 0
    });
    if (!_.isEqual(searchValue, newProps.searchValue)) {
      this.setState({searchValue: _.cloneDeep(newProps.searchValue)});
    }
  }

  getListItem (query = {}, pageSize, pageNumber) {
    let _query = _.assign({pageSize: pageSize, pageNumber: pageNumber, populate: 'material,orderId,styleId,contractor,supplier,purchaseOrder,', sort: {updatedAt: -1}}, query);
    this.props.damagedMaterialActions.getListItem(_query);
  }

  selectPage (page) {
    this.setState({pageNumber: page});
    this.search(this.state.search, page, this.state.pageSize);
  }

  onChangeSearch(property, value) {
    switch(property) {
      case SEARCH_BAR_EVENT.FILTER: {
        this.setState({search: {key: value, value: ""}});
        break;
      }
      case SEARCH_BAR_EVENT.SEARCH: {
        this.setState({search: {...this.state.search, value: value}});
        break;
      }
    }
  }

  onSearch(){
    const {search, pageNumber, pageSize} = this.state;
    this.search(search, pageNumber, pageSize);
  }

  search(search, pageNumber, pageSize) {
    let options = {};
    const populate = 'material,orderId,styleId,contractor,supplier,purchaseOrder';
    if(search && search.key && search.value) {
      switch(search.key) {
        case SEARCH_KEY.MATERIAL: {
          options = {
            projection: "_id",
            query: JSON.stringify({name: {$regex: '.*' + search.value + '.*', $options: 'i'}})
          };
          this.props.damagedMaterialActions.searchByMaterial(options, pageNumber, pageSize).then(res => {
            if(res && res.pageNumber && res.pageNumber == 1) {
              this.setState({pageNumber: 1});
            }
          });
          break;
        }
        case SEARCH_KEY.CONTENT: {
          options = {
            projection: "_id",
            query: JSON.stringify({"data.content": {$regex: '.*' + search.value + '.*', $options: 'i'}})
          };
          this.props.damagedMaterialActions.searchByContent(options, pageNumber, pageSize).then(res => {
            if(res && res.pageNumber && res.pageNumber == 1) {
              this.setState({pageNumber: 1});
            }
          });
          break;
        }
        case SEARCH_KEY.CUSTOMER_PO: {
          options = {
            projection: "_id",
            query: JSON.stringify({customerPO: {$regex: '.*' + search.value + '.*', $options: 'i'}})
          };
          this.props.damagedMaterialActions.searchByCustomerPO(options, pageNumber, pageSize).then(res => {
            if(res && res.pageNumber && res.pageNumber == 1) {
              this.setState({pageNumber: 1});
            }
          });
          break;
        }
        case SEARCH_KEY.STYLE: {
          options = {
            projection: "_id",
            query: JSON.stringify({style: {$regex: '.*' + search.value + '.*', $options: 'i'}})
          };
          this.props.damagedMaterialActions.searchByStyle(options, pageNumber, pageSize).then(res => {
            if(res && res.pageNumber && res.pageNumber == 1) {
              this.setState({pageNumber: 1});
            }
          });
          break;
        }
        case SEARCH_KEY.PURCHASE_ORDER_NO: {
          options = {
            projection: "_id",
            query: JSON.stringify({purchaseNo: {$regex: '.*' + search.value + '.*', $options: 'i'}})
          };
          this.props.damagedMaterialActions.searchByPurchaseOrderNo(options, pageNumber, pageSize).then(res => {
            if(res && res.pageNumber && res.pageNumber == 1) {
              this.setState({pageNumber: 1});
            }
          });
          break;
        }
        case SEARCH_KEY.SOURCE: {
          options = {
            projection: "_id",
            query: {name: {$regex: '.*' + search.value + '.*', $options: 'i'}}
          };
          this.props.damagedMaterialActions.searchBySource(options, pageNumber, pageSize).then(res => {
            if(res && res.pageNumber && res.pageNumber == 1) {
              this.setState({pageNumber: 1});
            }
          });
          break;
        }
        case SEARCH_KEY.COLOR: {
          options = {
            pageNumber,
            pageSize,
            sort: {updatedAt: -1},
            populate: 'material,orderId,styleId,contractor,supplier,purchaseOrder',
            query: {color: {$regex: '.*' + search.value + '.*', $options: 'i'}}
          };
          this.props.damagedMaterialActions.getListItem(options).then(res => {
            if(res && res.success && res.data && res.data.items && !res.data.items.length && pageNumber > 1) {
              options = {
                pageNumber:1,
                pageSize,
                sort: {updatedAt: -1},
                populate: populate,
                query: {color: {$regex: '.*' + search.value + '.*', $options: 'i'}}
              };
              this.setState({pageNumber: 1});
              this.props.damagedMaterialActions.getListItem(options);
            }
          });
          break;
        }
        case SEARCH_KEY.SIZE: {
          options = {
            pageNumber,
            pageSize,
            sort: {updatedAt: -1},
            populate: 'material,orderId,styleId,contractor,supplier,purchaseOrder',
            query: {size: {$regex: '.*' + search.value + '.*', $options: 'i'}}
          };
          this.props.damagedMaterialActions.getListItem(options).then(res => {
            if(res && res.success && res.data && res.data.items && !res.data.items.length && pageNumber > 1) {
              options = {
                pageNumber:1,
                pageSize,
                sort: {updatedAt: -1},
                populate: populate,
                query: {size: {$regex: '.*' + search.value + '.*', $options: 'i'}}
              };
              this.setState({pageNumber: 1});
              this.props.damagedMaterialActions.getListItem(options);
            }
          });
          break;
        }
      }
    }
    if(!search.value) {
      options = {
        pageNumber,
        pageSize,
        sort: {updatedAt: -1},
        populate: populate,
      };
      this.props.damagedMaterialActions.getListItem(options).then(res => {
        if(res && res.success && res.data && res.data.items && !res.data.items.length && pageNumber > 1) {
          options = {
            pageNumber:1,
            pageSize,
            sort: {updatedAt: -1},
            populate: populate,
          };
          this.setState({pageNumber: 1});
          this.props.damagedMaterialActions.getListItem(options);
        }
      });
    }
  }

  render () {
    let {damagedMaterials, total, columns, actions, pageNumber, pageSize} = this.state;
    let totalPage = _.ceil(total / pageSize);
    return (
      <div role="tabpanel" className="tab-pane" id="damaged_materials">
        <SearchBar
          name="Stock In"
          fields={SEARCH_LIST.WAREHOUSE_DAMAGED_MATERIALS}
          onChangeSearch={(type, data) => this.onChangeSearch(type, data)}
          onSearch={() => this.onSearch()}
        />
        <TableList {...this.props} items={damagedMaterials || []} columns={columns || []} actions={actions || []}
                   options={{clsTbl: 'global-table box-shadow-none'}} widthAction="w-70"/>
        <CustomPagination items={totalPage} activePage={pageNumber} onSelect={this.selectPage} />
      </div>
    );
  }
}

function mapStateToProps (state, ownProps) {
  return {
    authState: state.authState,
    damagedMaterialState: state.damagedMaterialState
  };
}

function mapDispatchToProps (dispatch) {
  return {
    damagedMaterialActions: bindActionCreators(damagedMaterialActions, dispatch)
  };
}

TabMaterial.propTypes = {
  authState: PropTypes.object.isRequired,
  damagedMaterialActions: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(TabMaterial);
