import React, {Component} from 'react';
import PropTypes from 'prop-types';
import I18n from "i18n-js";
import _ from 'lodash';
import moment from 'moment'
// import {buildCustomerPOToCheckList} from "../../../functions/utils";
import {connect} from "react-redux";
const DateTimeField = require('react-datetime');

class ModalNotifyPacking extends Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      date: new Date()
    }
  }

  ok = () => {
    const {date} = this.state
    
    this.props.notify(date)
    this.props.closeModal()
  }

  onChange = date => this.setState({ date: moment(date, 'x').format('') })

  render = () => {
    const {date} = this.state
    return (
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <form>
            <div className="modal-header">
              <a className="close" onClick={this.props.closeModal}><span aria-hidden="true">&times;</span></a>
              <h4 className="modal-title" id="myModalLabel">{I18n.t('PageProduction.NotifyShipping')}</h4>
            </div>
            <div className="modal-body">

              <div className="form-group">
                <div className="col-md-4 col-xs-12 text">
                  <p>
                    {I18n.t('PageProduction.Ex-Factory')}
                  </p>
                </div>
                <div className="col-md-8 col-xs-12 value">
                  <DateTimeField onChange={this.onChange} timeFormat={false} dateFormat='MMM DD, YYYY' value={date} />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="bt-black" onClick={this.props.closeModal}>{I18n.t('Btn.CANCEL')}</button>
              <button type="button" className="bt-orange" onClick={() => this.ok()}>{I18n.t('Btn.OK')}</button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
  };
}

function mapDispatchToProps(dispatch) {
  return {
  };
}

ModalNotifyPacking.propTypes = {
  notify: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalNotifyPacking);
