import React, { Component } from 'react';

export default class Image extends Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	render() {
		const { value } = this.props;
		return (
			<td className="thumbnails">
				<img src={value} alt="" className="img-responsive" />
			</td>
		);
	}
}