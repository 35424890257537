import _ from 'lodash';
import {APP} from '../constants/actionTypes';
import initial from './initialState';

export default function appState (state = initial.appState, action) {
  switch (action.type) {
    case APP.SHOW_LOADING:
      return {...state, loading: true};
    case APP.HIDE_LOADING:
      return {...state, loading: false};
    case APP.SHOW_SUCCESS_ALERT:
      return {...state, alertSuccess: action.data, func: action.func};
    case APP.SHOW_ERROR_ALERT:
      return {...state, alertError: action.data, func: action.func};
    case APP.HIDE_ALERT:
      return {...state, alertSuccess: null, alertError: null};
    case APP.SET_PAGE_CURRENT:
      return {...state, page: action.data || {}};
    case APP.ADD_PAGE_CURRENT:
      return {...state, pageList: action.data || []};
    case APP.SET_DATA_PAGE_CURRENT:{
      let page = _.assign(_.cloneDeep(state.page) || {}, action.data || {});
      return {...state, page: page};
    }
    default:
      return state;
  }
}
