import { createSelector } from 'reselect';
import _ from 'lodash';
import moment from 'moment';
import * as R from 'ramda';
import { genDollarToText } from '../../../functions/helpers';

export const getInvoiceDetail = ({ editInvoiceImpExpDeclaration }) => editInvoiceImpExpDeclaration.invoiceDetail || {};

export const getConstName = ({ editInvoiceImpExpDeclaration }) => editInvoiceImpExpDeclaration.constName;

const genDisplayDeclarationModel = (model) => {
    if (!model) return {};
    return new Proxy(model, {
        get(obj, prop) {
            switch (prop) {
                case 'importExportFees':
                    return (obj['importExportFees'] || 0)
                case 'transportationFees':
                    return (obj['transportationFees'] || 0)
                case 'localCharge':
                    return (obj['localCharge'] || 0)
                case 'advance':
                    return (obj['advance'] || 0)
                case 'declarationDate':
                    return obj['declarationDate'] ? moment(obj['declarationDate']).format("MMM DD, YYYY") : '';
                case 'expense':
                    return (obj['expense'] || 0);
                case 'remaining':
                    return (obj['remaining'] || 0);
                case 'raw':
                    return obj;
                default:
                    return obj[prop];
            }
        },
    });
};

export const getDeclarations = ({ editInvoiceImpExpDeclaration }) => {
    if (editInvoiceImpExpDeclaration.invoiceDetail)
        return editInvoiceImpExpDeclaration.invoiceDetail.invoiceDetail.declarations.map(item => genDisplayDeclarationModel(item))
}

export const getName = createSelector(
    [
        getInvoiceDetail
    ], (data) => {
        return data.invoiceDetail && data.invoiceDetail.name
    }
)

export const getOtherFees = createSelector(
    [getInvoiceDetail], data => {
        return data.invoiceDetail && data.invoiceDetail.otherFees || 0
    }
)

export const getAmount = createSelector(
    [
        getInvoiceDetail,
        getOtherFees
    ], (data, otherFees) => {
        if (data.invoiceDetail) {

            return _.sum(data.invoiceDetail.declarations.map(item => item.remaining || 0)) + (otherFees * 1)
        }
    }
)

export const getPaid = createSelector(
    [
        getInvoiceDetail
    ], (data) => {
        return data.paid || 0
    }
)

export const getRemaining = createSelector(
    [
        getAmount,
        getPaid
    ], (amount, paid) => {
        return amount - paid
    }
)

export const getInvoiceNo = createSelector(
    [
        getInvoiceDetail
    ], (data) => {
        return data.invNo
    }
)

export const getInvoiceDate = createSelector(
    [
        getInvoiceDetail
    ], (data) => {
        return data.invoiceDate //moment(data.issuedDate).format("MMM DD, YYYY");
    }
)

export const getDueDate = createSelector(
    [
        getInvoiceDetail
    ], data => {
        return data.dueDate //moment(data.dueDate).format("MMM DD, YYYY");
    }
)

export const getPaymentType = ({ editInvoiceImpExpDeclaration }) => editInvoiceImpExpDeclaration.paymentType;

export const getPayAmount = ({ editInvoiceImpExpDeclaration }) => editInvoiceImpExpDeclaration.payAmount;

export const getFilesSaved = createSelector(
    [
        getInvoiceDetail
    ], data => {
        return data.files || [];
    }
)

export const getTransferFiles = ({ editInvoiceImpExpDeclaration }) => {
    const { transferFiles } = editInvoiceImpExpDeclaration;
    return _.map(transferFiles, file => ({
        id: R.path(['id'], file),
        url: R.path(['url'], file),
        raw: file,
        type: _.split(R.path(['id'], file), '.')[1],
    }));
}

export const fnGenerateRequestData = createSelector(
    [
        ({ editInvoiceImpExpDeclaration }) => editInvoiceImpExpDeclaration,
        getAmount
    ], (editInvoiceImpExpDeclaration, amount) => {
        const { invoiceDetail = {}, paymentType, payAmount } = _.cloneDeep(editInvoiceImpExpDeclaration);
        let data = _.omit(invoiceDetail, ['createdAt', 'createdBy', 'updatedAt', 'updatedBy', 'paymentHistory']);
        let detailOfInvoice = _.omit(data.invoiceDetail, ['createdAt', 'createdBy', 'updatedAt', 'updatedBy'])
        let receipt;
        if (paymentType === 'transfer') {
            data = _.omit(data, ['receipt']);
        } else {
            receipt = data.receipt
            data = _.omit(data, ['files', 'receipt']);
        }
        data = { ...data, amount: amount, payment: { receipt, paymentType: _.startCase(paymentType), paid: payAmount } }
        return ({ ...data, invoiceDetail: detailOfInvoice, currency: 'VND' })
    }
)

export const getReceiptFromInvoice = createSelector(
    [
        getInvoiceDetail,
        getPayAmount,
        getName
    ], (data, payAmount, address) => {
        return ({ ...data.receipt, total: payAmount, date: moment().format(), address })
    }
)

export const getReceiptDataFromRawData = ({ editInvoiceImpExpDeclaration }) => ({ ...editInvoiceImpExpDeclaration.receipt, say: genDollarToText(editInvoiceImpExpDeclaration.receipt.total) })

export const getEmptyReceipt = createSelector(
    [
        getReceiptFromInvoice
    ], data => {
        return !data.isEdit;
    }
)

export const getSuccess = ({ editInvoiceImpExpDeclaration }) => editInvoiceImpExpDeclaration.success;

export const getDisableChangeValue = createSelector(
    [
        getPaid
    ], paid => {
        if (paid > 0) return true;
        return false;
    }
)

export const getActiveScreenStatus = ({editInvoiceImpExpDeclaration}) => editInvoiceImpExpDeclaration.activeScreen;