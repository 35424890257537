import React from 'react';
import BaseComponent from '../../base/BaseComponent';
import I18n from 'i18n-js';
import moment from 'moment';
import Panel from "../../common/layout/panel/Panel";

class ScreenHistory extends BaseComponent {

    constructor(props, context) {
        super(props, context);
    
        this.state = {
          shipping: {},
          ...this.getOrders(),
          standards: {}
        }
    }

    componentWillMount = () => {
        const shipping = _.get(this.props, 'appState.page.data', {})
        // shipping.purchaseOrders ? shipping.purchaseOrders = {} : shipping.purchaseOrders = {};
        this.setState({
          shipping : _.cloneDeep(shipping)
        })
    }

    getOrders = () => {
        const id = _.get(this.props, 'appState.page.data._id', '');
        const pos = id ? _.get(this.props, 'appState.page.data.purchaseOrders').map(po => {
            for (const mt of po.materials) {
              for (const material of po.purchaseOrder.materials) {
                if(mt.id = material._id){
                  material.checked = true;
                  material.xmil = mt.xmil;
                  material.eta = mt.eta;
                  material.etd = mt.etd;
                  material.actual = mt.actual;
                  material.sample = mt.sample;
                }
              }
            }
          return po.purchaseOrder
        }) : _.get(this.props, 'appState.page.data', [])
        const orders = pos.reduce((orders, po) => {
          const idx = _.findIndex(orders, o => o._id == _.get(po, 'style.order._id'))
          if(idx >= 0) {
            const idxS = _.findIndex(orders[idx].styles, st => st._id == _.get(po, 'style._id', ''))
            if(idxS < 0) {
              orders[idx].styles.push({
                _id: _.get(po, 'style._id', ''),
                style: _.get(po, 'style.style', ''),
                gTotal: _.get(po, 'style.gTotal', ''),
                active: false
              })
            }
          } else {
            orders.push({
              _id: _.get(po, 'style.order._id', ''),
              customerPO: _.get(po, 'style.order.customerPO', ''),
              gTotal: _.get(po, 'style.order.gTotal', 0),
              styles: [
                {
                  _id: _.get(po, 'style._id', ''),
                  style: _.get(po, 'style.style', ''),
                  gTotal: _.get(po, 'style.gTotal', ''),
                  active: true
                }
              ] 
            })
          }
          return orders
        }, [])
    
        const gTotal = orders.reduce((s, o) => s + o.gTotal, 0)
        return {orders, gTotal , pos : _.cloneDeep(pos)}
    }

    setTabStyle = (orderId, styleId) => {
        const { orders } = this.state
        for (const order of orders) {
          if(order._id == orderId) {
            for (const style of order.styles || []) {
              style.active = false
              if(style._id == styleId) {
                style.active = true
              }
            }
            break
          }
        }
        this.setState({ orders })
    }

    renderTitle() {
        const { shipping} = this.state
        // console.log(shipping)
        return (
                <div> 
                    <div className="wrap-title bd-dashed-bottom bg-white">
                        <div className="col-md-4 col-sm-6 col-xs-12 title">
                            <ul>
                                <li className="top-0"><span>{I18n.t('PageShiping.BillNo')}</span></li>
                                <li>
                                    <span className="bold">{shipping.billNo || '-'}</span>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="wrap-table inline-block w-full box-shadow pd-10-0 bg-white wrap-input li-top-0">
                        <div className="col-md-4 col-sm-6 col-xs-12 input">
                            <ul>
                                <li>
                                    <span>{I18n.t('PageSystemSettings.ETD')}</span>
                                </li>
                                <li>
                                    <span className="bold">{moment(shipping.etd).format('MMM DD, YYYY') || '-'}</span>
                                </li>
                            </ul>
                        </div>

                        <div className="col-md-4 col-sm-6 col-xs-12 input">
                            <ul>
                            <li> 
                                <span>{I18n.t('PageSystemSettings.Shipper')}</span>
                            </li>
                            <li>
                                
                                <span className="bold">{shipping.shipper || '-'}</span>
                            </li>
                            </ul>
                        </div>

                        <div className="col-md-4 col-sm-6 col-xs-12 input">
                            <ul>
                            <li>
                                <span>{I18n.t('PageSystemSettings.FlightNoVoyageNo')}</span>
                            </li>
                            <li>
                                <span className="bold">{shipping.flightNo || '-'}</span>
                            </li>
                            </ul>
                        </div>
                    {/* {-----------------} */}

                    <div className="col-md-4 col-sm-6 col-xs-12 input">
                        <ul>
                        <li>
                            <span>{I18n.t('PageSystemSettings.ETA')}</span>
                        </li>
                        <li>
                            <span className="bold">{moment(shipping.eta).format('MMM DD, YYYY') || '-'}</span>
                        </li>
                        </ul>
                    </div>

                    <div className="col-md-4 col-sm-6 col-xs-12 input">
                        <ul>
                            <li>
                                <span>{I18n.t('PageSystemSettings.ShipMode')}</span>
                            </li>
                            <li>
                                <span className="bold">{shipping.shipMode || '-'}</span>
                            </li>
                        </ul>
                    </div>

                    <div className="col-md-4 col-sm-6 col-xs-12 input">
                        <ul>
                        <li>
                            <span>{I18n.t('PageSystemSettings.CBM')}</span>
                        </li>
                        <li>
                            <span className="bold">{shipping.cbm || '-'}</span>
                        </li>
                        </ul>
                    </div>
                    {/* {-----------------} */}

                    <div className="col-md-4 col-sm-6 col-xs-12 input">
                        <ul>
                        <li>
                            <span>{I18n.t('PageSystemSettings.DocsReceived')}</span>
                        </li>
                        <li>
                            <span className="bold">{moment(shipping.docsReceived).format('MMM DD, YYYY') || '-'}</span>
                        </li>
                        </ul>
                    </div>

                    <div className="col-md-4 col-sm-6 col-xs-12 input">
                        <ul>
                        <li>
                            <span>{I18n.t('PageSystemSettings.QTYPKG')}</span>
                        </li>
                        <li>
                            <span className="bold">{shipping.qtyPKG || '-'}</span>
                        </li>
                        </ul>
                    </div>

                    <div className="col-md-4 col-sm-6 col-xs-12 input">
                        <ul>
                        <li>
                            <span>{I18n.t('PageSystemSettings.GW')}</span>
                        </li>
                        <li>
                            <span className="bold">{shipping.gw || '-'}</span>
                        </li>
                        </ul>
                    </div>
                    {/* {-----------------} */}

                    <div className="col-md-4 col-sm-6 col-xs-12 input">
                        <ul>
                        <li>
                            <span>{I18n.t('PageSystemSettings.ETAFactory')}</span>
                        </li>
                        <li>
                            <span className="bold">{moment(shipping.etaFactory).format('MMM DD, YYYY') || '-'}</span>
                        </li>
                        </ul>
                    </div>

                    <div className="col-md-4 col-sm-6 col-xs-12 input">
                        <ul>
                        <li>
                            <span>{I18n.t('PageSystemSettings.Forwarder')}</span>
                        </li>
                        <li>
                            <span className="bold">{shipping.forwarder || '-'}</span>
                        </li>
                        </ul>
                    </div>

                    <div className="col-md-4 col-sm-6 col-xs-12 input">
                        <ul>
                        <li>
                            <span>{I18n.t('PageSystemSettings.Qty')}</span>
                        </li>
                        <li>
                            <span className="bold">{shipping.qty || '-'}</span>
                        </li>
                        </ul>
                    </div>
                    {/* {-----------------} */}

                    <div className="col-md-4 col-sm-6 col-xs-12 input">
                        <ul>
                        <li>
                            <span>{I18n.t('PageSystemSettings.DeclarationNo')}</span>
                        </li>
                        <li>
                            <span className="bold">{shipping.declarationNo || '-'}</span>
                        </li>
                        </ul>
                    </div>

                    <div className="col-md-4 col-sm-6 col-xs-12 input">
                        <ul>
                        <li>
                            <span>{I18n.t('PageSystemSettings.Advance')}</span>
                        </li>
                        <li>
                            <span className="bold">{shipping.advance || '-'}</span>
                        </li>
                        </ul>
                    </div>

                    <div className="col-md-4 col-sm-6 col-xs-12 input">
                        <ul>
                        <li>
                            <span>{I18n.t('PageSystemSettings.BorderGate')}</span>
                        </li>
                        <li>
                            <span className="bold">{shipping.borderGate || '-'}</span>
                        </li>
                        </ul>
                    </div>
                    {/* {-----------------} */}

                    <div className="col-md-4 col-sm-6 col-xs-12 input">
                        <ul>
                        <li>
                            <span>{I18n.t('PageSystemSettings.DeclarationDate')}</span>
                        </li>
                        <li>
                            <span className="bold">{moment(shipping.declarationDate).format('MMM DD, YYYY') || '-'}</span>
                        </li>
                        </ul>
                    </div>

                    <div className="col-md-4 col-sm-6 col-xs-12 input">
                        <ul>
                        <li>
                            <span>{I18n.t('PageSystemSettings.AdvanceDate')}</span>
                        </li>
                        <li>
                            <span className="bold">{moment(shipping.advanceDate).format('MMM DD, YYYY') || '-'}</span>
                        </li>
                        </ul>
                    </div>

                    <div className="col-md-4 col-sm-6 col-xs-12 input">
                        <ul>
                        <li>
                            <span>{I18n.t('PageSystemSettings.DropOffCont')}</span>
                        </li>
                        <li>
                            <span className="bold">{shipping.dropOffCont || '-'}</span>
                        </li>
                        </ul>
                    </div>
                    {/* {-----------------} */}

                    <div className="col-md-4 col-sm-6 col-xs-12 input">
                        <ul>
                        <li>
                            <span>{I18n.t('PageSystemSettings.Cont')}</span>
                        </li>
                        <li>
                            <span className="bold">{shipping.cont || '-'}</span>
                        </li>
                        </ul>
                    </div>

                    <div className="col-md-4 col-sm-6 col-xs-12 input">
                        <ul>
                        <li>
                            <span>{I18n.t('PageSystemSettings.No')}</span>
                        </li>
                        <li>
                            <span className="bold">{shipping.no || '-'}</span>
                        </li>
                        </ul>
                    </div>

                    <div className="col-md-4 col-sm-6 col-xs-12 input note">
                        <ul>
                        <li>
                            <span>{I18n.t('PageSystemSettings.Note')}</span>
                        </li>
                        <li>
                            <span className="bold">{shipping.note || '-'}</span>

                        </li>
                        </ul>
                    </div>
                    {/* {-----------------} */}

                        <div className="col-md-4 col-sm-6 col-xs-12 input">
                        <ul>
                            <li>
                            <span>{I18n.t('PageSystemSettings.ContType')}</span>
                            </li>
                            <li>
                                <span className="bold">{shipping.contType || '-'}</span>
                            </li>
                        </ul>
                        </div>

                        <div className="col-md-4 col-sm-6 col-xs-12 input">
                        <ul>
                            <li>
                                <span>{I18n.t('PageSystemSettings.PickupCont')}</span>
                            </li>
                            <li>
                                <span className="bold">{shipping.pickUpCont || '-'}</span>
                            </li>
                        </ul>
                        </div>
                    {/* {-----------------} */}


                    </div>

                    <div className="header-transport mg-5-0 bd-none box-shadow">
                    <div className="wrap-border-right-transport"><span className="border-right-transport"></span></div>
                    <div className="wrap-title">
                        <h3 style={{margin : 10}}><span className="fw-normal">{I18n.t('PageShiping.TransportFees')}</span></h3>
                    </div>
                    <div className="wrap-value">
                        <ul>
                        <li>
                            <span className="wrap-text">{I18n.t('PageShiping.CRS')}</span>
                            <span className="wrap-text" style={{paddingLeft: 10, color: '#d0021b'}}>  
                                ${shipping.crsPaperWork || 0}
                            </span>
                        </li>
                        <li>
                            <span className="wrap-text">{I18n.t('PageShiping.LocalCharge')}</span>
                            <span className="wrap-text" style={{paddingLeft: 10, color: '#d0021b'}}>  
                                ${shipping.localCharge  || 0}
                            </span>
                        </li>
                        <li>
                            <span className="wrap-text">{I18n.t('PageShiping.TruckingIn')}</span>
                            <span className="wrap-text" style={{paddingLeft: 10, color: '#d0021b'}}>  
                                ${shipping.truckingIn || 0}
                            </span>
                        </li>
                        </ul>
                    </div>

                    </div>
            </div>
        )
    }

    renderPanelDetails = (order) => {
        return (
          <div className="text mg-left-30">
            <span className="left size-12">{I18n.t("PageShiping.CustomerPO")}</span>
            <span className="right size-15 medium mg-left-10 text-transform">{_.get(order, 'customerPO', '')}</span>
          </div>
        )
    }
    
    renderPanelContent = order => {
        const { gTotal, shipping, pos } = this.state
        const style = (order.styles || []).find(st => st.active) || {}
        return (
          <div>
            <div className="header-title pd-0 box-shadow">
              <div className="list-style pd-0-15 bg-white">
                <span className="mg-right-10 size-13">{I18n.t("PageShiping.Style")}</span>
                {(order.styles || []).map((style, idx) => (
                  <button key={idx} onClick={() => this.setTabStyle(order._id, style._id)} className={style.active ? 'bt-orange mg-10' : 'bt-white mg-10'}>{style.style || ''}</button>
                ))}
              </div>
            </div>
            
            <div className="header-transport pd-15 bd-none box-shadow">
              <div className="wrap-border-right-transport"><span className="border-right-transport"></span></div>
              <div className="wrap-title">
                <h3 style={{margin :10}}><span className="fw-normal">{I18n.t('PageShiping.TransportFees')}</span></h3>
              </div>
              <div className="wrap-value">
                <ul>
                  <li>
                    <span className="wrap-text">{I18n.t('PageShiping.CRS')}</span>
                    <span className="wrap-text" style ={{marginLeft: 10, color: 'red'}}>${_.ceil(_.toInteger(shipping.crsPaperWork) * style.gTotal / gTotal, 2)}</span>
                  </li>
                  <li>
                    <span className="wrap-text">{I18n.t('PageShiping.LocalCharge')}</span>
                    <span className="wrap-text" style ={{marginLeft: 10, color: 'red'}}>${_.ceil(_.toInteger(shipping.localCharge) * style.gTotal / gTotal, 2)}</span>
                  </li>
                  <li>
                    <span className="wrap-text">{I18n.t('PageShiping.TruckingIn')}</span>
                    <span className="wrap-text" style ={{marginLeft: 10, color: 'red'}}>${_.ceil(_.toInteger(shipping.truckingIn) * style.gTotal / gTotal, 2)}</span>
                  </li>
                </ul>
              </div>
            </div>
            {
              pos.filter(p => p.styleId == style._id).map((po, idx) => {
                const checkedAll = _.findIndex(po.materials || [], m => !(m.checked || false)) < 0
                return (<div key={idx}>
                  <div className="pd-10 size-13">
                    <span className="wrap-text mg-right-10">{I18n.t('PageShiping.PurchaseOrder')}</span>
                    <span className="wrap-text color-orange ">{po.purchaseNo}</span>
                  </div>
                  <div className="tab-content">
                    <table className="global-table w-full bg-white data-time-table">
                      <tbody>
                        <tr>
                          <th className="center style-checkbox">
                            <input checked={checkedAll} type="checkbox" id={po._id}/>
                            <label htmlFor={po._id}><span><span/></span></label>
                          </th>
                          <th className='center'>{I18n.t('PageShiping.Material')}</th>
                          <th className='center'>{I18n.t('PageShiping.Content')}</th>
                          <th className='center'>{I18n.t('PageShiping.Color')}</th>
                          <th className='center'>{I18n.t('PageShiping.Size')}</th>
                          <th className='center'>{I18n.t('PageShiping.Needed')}</th>
                          <th className='center'>{I18n.t('PageShiping.Sample')}</th>
                          <th className='center'>{I18n.t('PageShiping.Actual')}</th>
                          <th className='center'>{I18n.t('PageShiping.XMIL')}</th>
                          <th className='center'>{I18n.t('PageShiping.ETD')}</th>
                          <th className='center'>{I18n.t('PageShiping.ETA')}</th>
                        </tr>
                        {(po.materials || []).map((mt, idx) => {
                          return (
                            <tr key={idx}>
                              <td className="center style-checkbox">
                                <input checked={mt.checked||false} type="checkbox" id={mt._id}/>
                                <label htmlFor={mt._id}><span><span/></span></label>
                              </td>
                              <td>{_.get(mt, 'material.name', '')}</td>
                              <td className="center">{_.get(mt, 'material.data.content', '')}</td>
                              <td className="center">{_.get(mt, 'color', '')}</td>
                              <td className="center">{_.get(mt, 'size', '')}</td>
                              <td className="center">{_.get(mt, 'needToBuy', '')}</td>
                              <td className="center">
                                {mt.sample || 0} 
                              </td>
                              <td className="center">
                                {mt.actual || 0}
                              </td>
                              <td className="center select date-time-td">
                                {moment(_.get(mt, 'xmil')).format('MMM DD, YYYY')}
                              </td>
                              <td className="center select date-time-td">
                               {moment(_.get(mt, 'etd')).format('MMM DD, YYYY')}
                              </td>
                              <td className="center select date-time-td">
                               {moment(_.get(mt, 'eta')).format('MMM DD, YYYY')}
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>)
              })
            }
          </div>
        )
    }

    render = () => {
        const { orders } = this.state;
        return (
        <div id="screen-history-shipment" className="pd-10">
            {this.renderTitle()}
            <div className="panel-group" id="panel-list">
                {orders.map((order, idx) => (
                <Panel key={idx} id={_.get(order, '_id', idx)} renderContent={() => this.renderPanelContent(order)} renderPanelDetails={() => this.renderPanelDetails(order)}/>
                ))}
            </div>
        </div>
        )
    }
}
export default ScreenHistory;