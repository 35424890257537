import React from 'react';
import PropTypes from "prop-types";
import * as R from 'ramda';
import moment from 'moment';
import FormGroup from '../../forms/FormGroup';
import Input from '../../forms/Input';
const formatCurrency = require('format-currency');
const DateTimeField = require('react-datetime');

const EditInvoiceIEDeclaration = props => {
    const { disableChangeValue, dataApi, paymentType, constName, declarations, invoiceDate, amount, paid, remaining, emptyReceipt, receiptFromInvoice } = props;
    // console.log(disableChangeValue)
    return (
        <div>
            <ul className="content-header-title float-none pd-0-5 justify-content-space-between display-flex">
                <li>
                    <p>Name
                        <span className="color-orange medium mg-left-25 size-16">{constName}</span>
                    </p>
                </li>
                <li className="add-filter-top">
                    <span className="cursor-pointer" onClick={() => props.save(paymentType, dataApi)}>
                        <i className="fa fa-floppy-o" aria-hidden="true" />{"SAVE"}
                    </span>
                </li>
            </ul>
            <div className="wrap-input-global pd-15-0 margin-small bg-white page-edit-invoice-list box-shadow">
                <div className="pd-0 col-xs-9 left">
                    <div className="col-md-6 col-xs-12 input">
                        <FormGroup
                            label="Name"
                            type="input"
                            inputComponent={
                                <Input
                                    type="text"
                                    value={props.name}
                                    onChange={e =>
                                        props.changeName(e.target.value)
                                    }
                                    placeholder=""
                                />
                            }
                        />
                    </div>
                    <div className="col-md-6 col-xs-12 input">
                        <FormGroup
                            type="input"
                            label="Invoice Date"
                            inputComponent={
                                <DateTimeField
                                    timeFormat={false} closeOnSelect dateFormat="MMM DD, YYYY"
                                    value={moment(invoiceDate)}
                                    onChange={date =>
                                        props.changeIssuedDate(date.format())
                                    }
                                />
                            }
                        />
                    </div>
                    <div className="col-md-6 col-xs-12 input">
                        <FormGroup
                            type="input"
                            label="Invoice No."
                            inputComponent={
                                <Input
                                    type="text"
                                    value={props.invoiceNo}
                                    onChange={e =>
                                        props.changeInvoiceNo(e.target.value)
                                    }
                                    placeholder=""
                                />
                            }
                        />
                    </div>
                    <div className="col-md-6 col-xs-12 input">
                        <FormGroup
                            type="input"
                            label="Other Fees"
                            inputComponent={
                                <Input
                                    type="number"
                                    min="0"
                                    disabled={disableChangeValue}
                                    value={props.otherFees}
                                    onChange={e =>
                                        props.changeOtherFees(_.toInteger(e.target.value))
                                    }
                                    placeholder=""
                                />
                            }
                        />
                    </div>
                    <div className="col-md-6 col-xs-12 input">
                        <FormGroup
                            type="input"
                            label="Pay Amount"
                            inputComponent={
                                <Input
                                    type="number"
                                    min="0"
                                    value={props.payAmount}
                                    onChange={e =>
                                        props.changePayAmount(_.toInteger(e.target.value), remaining, paid)
                                    }
                                    placeholder=""
                                />
                            }
                        />
                    </div>
                </div>
                <div className="pd-0 col-xs-3 right">
                    <ul className="type-supplier">
                        <li onClick={() => props.changePaymentType('cash')} className={paymentType === "cash" ? "active" : "cursor-pointer"}>
                            <span className="cursor-poniter">
                                <span className="icon">
                                    <span />
                                </span>
                                <span className="text">Cash</span>
                            </span>
                        </li>

                        <li onClick={() => props.changePaymentType('transfer')} className={paymentType === "transfer" ? "active" : "cursor-pointer"}>
                            <span
                                className="cursor-poniter"
                            >
                                <span className="icon">
                                    <span />
                                </span>
                                <span className="text">Transfer</span>
                            </span>
                        </li>
                    </ul>

                    {paymentType === "transfer" &&
                        <span>
                            <div className="mg-right-15">
                                <span className="color-link text-decoration cursor-pointer"
                                    onClick={() => props.openTransferModal()}
                                >
                                    Upload File
                            </span>
                            </div>
                            <p className="text-align-left mg-bottom-15">
                                Files: {(dataApi.files || []).length}
                            </p>
                        </span>
                    }
                    {paymentType === "cash" &&
                        <span>
                            {emptyReceipt && <div className="mg-right-15">
                                <span className="color-link text-decoration cursor-pointer"
                                    onClick={() => props.openReceiptModal()}
                                >
                                    Add Receipt
                                </span>
                            </div>}
                            {!emptyReceipt && <p className="text-align-left mg-bottom-15">
                                <span href="" className="color-link text-decoration size-12 cursor-pointer"
                                    onClick={() => props.openReceiptModal()}
                                >{receiptFromInvoice.no}</span>
                            </p>}
                        </span>
                    }

                    <div className="text-align-left mg-bottom-5">
                        <p>
                            <span className="mg-right-15 size-12">Total Amount INV</span>
                            <span className="bold color-number size-14">{formatCurrency(amount)} VND</span>
                        </p>
                    </div>

                    <div className="text-align-left mg-bottom-5">
                        <p>
                            <span className="mg-right-15 size-12">Paid</span>
                            <span className="bold size-14">{formatCurrency(paid)} VND</span>
                        </p>
                    </div>

                    <div className="text-align-left mg-bottom-5">
                        <p>
                            <span className="mg-right-15 size-12">Remaining</span>
                            <span className="bold size-14 color-green">{formatCurrency(remaining)} VND</span>
                        </p>
                    </div>
                </div>
            </div>
            {declarations.map((item, index) => {
                return (
                    <div key={index}>
                        <div className="box-shadow mg-bottom-5 inline-block" style={{ width: "100%" }}>
                            <ul className="bg-white content-header-title pd-left-15 bd-solid-bottom-3">
                                <li>
                                    <p>Declaration No <span className="bold mg-left-25 size-14">{R.path(['declarationNo'], item)}</span></p>
                                </li>
                                <li className="float-right">
                                    <div className="inline-block mg-right-30">
                                        <span className="bold">{R.path(['declarationDate'], item)}</span>
                                    </div>
                                </li>
                            </ul>

                            <ul className="bg-lightGray content-header-title pd-left-15">
                                <li>
                                    <p>Border Gate <span className="bold mg-left-25 text-transform">{R.path(['borderGate'], item)}</span></p>
                                </li>
                                <li className="float-right mg-right-30">
                                    <p>Note <span className="bold mg-left-25 text-transform">{R.path(['note'], item)}</span></p>
                                </li>
                            </ul>

                            <div className="bg-white float-left text-left size-12 pd-15-0 inline-block w-full">
                                <div className="col-md-3 col-sm-6 col-xs-12 mg-bottom-10">
                                    <p><span className="mg-right-15">QTY PKG</span> <span className="bold color-number">{R.pathOr(0, ['qtyPKG'], item)}</span></p>
                                </div>
                                <div className="col-md-3 col-sm-6 col-xs-12 mg-bottom-10">
                                    <p><span className="mg-right-15">Import/Export Fees</span> <span className="bold color-number">{formatCurrency(R.path(['importExportFees'], item))}</span></p>
                                </div>
                                <div className="col-md-3 col-sm-6 col-xs-12 mg-bottom-10">
                                    <p><span className="mg-right-15">Transportation Fees</span> <span className="bold">{formatCurrency(R.path(['transportationFees'], item))}</span></p>
                                </div>
                                <div className="col-md-3 col-sm-6 col-xs-12 mg-bottom-10">
                                    <p><span className="mg-right-15">Expense</span> <span className="bold">{formatCurrency(R.path(['expense'], item))}</span></p>
                                </div>
                                <div className="col-md-3 col-sm-6 col-xs-12">
                                    <p><span className="mg-right-15">CBM</span> <span className="bold">{formatCurrency(R.path(['cbm'], item))}</span></p>
                                </div>
                                <div className="col-md-3 col-sm-6 col-xs-12">
                                    <p><span className="mg-right-15">Local Charge</span> <span className="bold">{formatCurrency(R.path(['localCharge'], item))}</span></p>
                                </div>
                                <div className="col-md-3 col-sm-6 col-xs-12">
                                    <p><span className="mg-right-15">Advance</span> <span className="bold">{formatCurrency(R.path(['advance'], item))}</span></p>
                                </div>
                                <div className="col-md-3 col-sm-6 col-xs-12">
                                    <p><span className="mg-right-15">Remaining</span> <span className="bold">{formatCurrency(R.pathOr(0, ['remaining'], item))}</span></p>
                                </div>
                            </div>
                        </div>
                        <div className="bg-line-colum" />
                    </div>
                )
            })}

        </div>
    )
}

EditInvoiceIEDeclaration.propTypes = {
    item: PropTypes.object,
    declarations: PropTypes.array,
    name: PropTypes.string,
    invoiceNo: PropTypes.string,
    issuedDate: PropTypes.string,
    dueDate: PropTypes.string,
    payAmount: PropTypes.number,
    paymentType: PropTypes.string,
    emptyReceipt: PropTypes.bool,
    receiptFromInvoice: PropTypes.object,
    openReceiptModal: PropTypes.func,
}

EditInvoiceIEDeclaration.defaultProps = {
    item: {},
    declarations: [],
    name: '',
    invoiceNo: '',
    issuedDate: '',
    dueDate: '',
    payAmount: 0,
    paymentType: 'cash',
    emptyReceipt: false,
    receiptFromInvoice: {},
    openReceiptModal: f => f,
}

export default EditInvoiceIEDeclaration;