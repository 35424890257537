import React, { Component } from 'react';
import PreviewIEContainerView from '../../../components/accounting/payable/PreviewIEContainer';
import I18n from 'i18n-js';
import { connect } from 'react-redux';
import * as selector from '../../../../selectors/accounting/payable/createInvoiceImpExpContainers';
import moment from 'moment';
import * as PATH from '../../../../constants/routeConstants'; 
import { withRouter } from 'react-router';
class PreviewIEContainer extends Component {

    constructor (props) {
        super(props)
        this.state = {
            listIds: this.props.listIds,
            items: [],
            pageNumber: 1,
            pageSize: 10,
            columns: [
                { name: 'Cont No', value: 'contNo', type: 'text' },
                { name: 'Route', value: 'route', type: 'text' },
                { name: 'Warehouse Addr', value: 'warehouseAddress', type: 'text' },
                { name: 'Pickup Cont', value: 'pickUpCont', type: 'text' },
                { name: 'Drop Off Cont', value: 'dropOffCont', type: 'number' },
                { name: 'Truck No', value: 'truckNo', type: 'number' },
                { name: 'Cost', value: 'cost', type: 'number' },
                { name: 'Charges Incurred', value: 'chargesIncurred', type: 'number' },
                { name: 'Lift On Fee', value: 'liftOnFee', type: 'number' },
                { name: 'Lift On INV', value: 'liftOnInv', type: 'text' },
                { name: 'Lift Off Fee', value: 'liftOffFee', type: 'number' },
                { name: 'Lift Off INV', value: 'liftOffInv', type: 'text' },
               
            ],
            actions: []
        }
    }
    
    componentWillReceiveProps(newProps) {
        const {activeScreen} = newProps;
        if(!activeScreen) {
            newProps.router.history.push(PATH.ACCOUNTING_PAYABLE_PLAN_IMPORTEXPORT_CONTAINER_URL);
        }
    }

    render() {
        const { columns } = this.state
        return (
            <PreviewIEContainerView columns = {columns} {...this.props}/>
        );
    }
}

const mapStateToProps = state => ({
    listContainer: selector.genDataForPreview(state),
    name: (selector.getSelectedImpExpInfo(state) || {}).name,
    invNo: (selector.getSelectedImpExpInfo(state) || {}).invoiceNo,
    issuedDate: moment((selector.getSelectedImpExpInfo(state) || {}).issuedDate).format("MMM DD, YYYY"),
    vatAmount: selector.getMainVATAmount(state),
    amountINV: selector.getAmount(state),
    totalAmountINV: selector.getTotalAmount(state),
    activeScreen: selector.getActiveScreenStatus(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({

})

export default connect(mapStateToProps, mapDispatchToProps) (withRouter(PreviewIEContainer));