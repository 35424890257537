import _ from 'lodash';

import * as types from '../constants/actionTypes';
import {showLoading, hideLoading} from './commonUseActions';
import {SEARCH_KEY} from "../constants/constants";
import assignmentServices from '../services/assignmentServices';
import orderServices from "../services/orderServices";
import contractorServices from "../services/contractorServices";


function getOrdersByContractor(id, options) {
  return (dispatch) => {

    showLoading(types, dispatch);
    assignmentServices.getOrdersByContractor(id, options).then((res) => {
      hideLoading(types, dispatch);
      if (res) {
        dispatch({
          type: types.ASSIGNMENT.GET_ORDER_CONTRACT_SUCCESS,
          data: res.data
        });
      }
    }, (error) => {
      hideLoading(types, dispatch);
    });
  };
}

function getListOrder (options = {}) {
  return (dispatch) => {

    showLoading(types, dispatch);
    assignmentServices.getListOrder(options).then((res) => {
      hideLoading(types, dispatch);
      if (res) {
        dispatch({
          type: types.ASSIGNMENT.GET_LIST_ORDER_SUCCESS,
          data: res.data
        });
      }
    }, (error) => {
      hideLoading(types, dispatch);
    });
  };
}

function getListItem (options = {}) {
  return (dispatch) => {

    showLoading(types, dispatch);
    return assignmentServices.getListItem(options).then((res) => {
      hideLoading(types, dispatch);
      if (res) {
        dispatch({
          type: types.ASSIGNMENT.GET_LIST_SUCCESS,
          data: res.data
        });
      }
      return res;
    }, (error) => {
      hideLoading(types, dispatch);
      return error
    });
  };
}

function getDetail (id, reset = true) {
  return (dispatch) => {
    showLoading(types, dispatch);
    assignmentServices.getDetail(id, {}).then((res) => {
      hideLoading(types, dispatch);
      if (res) {
        dispatch({
          type: types.ASSIGNMENT.GET_DETAIL_SUCCESS,
          data: res.data || {}
        });
      }
    }, (error) => {
      hideLoading(types, dispatch);
    });
  };
}

function deleteItem (id) {
  return (dispatch) => {

    showLoading(types, dispatch);
    assignmentServices.deleteItem(id).then((res) => {
      hideLoading(types, dispatch);
      if (res) {
        dispatch({
          type: types.ASSIGNMENT.RELOAD,
          data: true
        });
        dispatch({
          type: types.PRODUCTION.RELOAD_PRODUCTION
        });

        dispatch({
          type: types.PRODUCTION.RELOAD_QA
        });
      }
    }, (error) => {
      hideLoading(types, dispatch, error);
    });
  };
}

function editItem (id, data) {
  return (dispatch) => {

    showLoading(types, dispatch);
    assignmentServices.editItem(id, data).then((res) => {
      hideLoading(types, dispatch);
      if (res) {
        dispatch({
          type: types.ASSIGNMENT.RELOAD,
          data: true
        });
        dispatch({
          type: types.PRODUCTION.RELOAD_PRODUCTION
        });
        dispatch({
          type: types.PRODUCTION.RELOAD_QA
        });
      }
    }, (error) => {
      hideLoading(types, dispatch, error);
    });
  };
}

function addItem (data) {
  return (dispatch) => {
    showLoading(types, dispatch);

    assignmentServices.addItem(data).then((res) => {
      hideLoading(types, dispatch);
      if (res) {
        dispatch({
          type: types.ASSIGNMENT.RELOAD,
          data: true
        });
        dispatch({
          type: types.PRODUCTION.RELOAD_PRODUCTION
        });
      }
    }, (error) => {
      hideLoading(types, dispatch, error);
    });
  };
}

function searchInto(options, func,  name, field, bool = true) {
  return new Promise(resolve => {
    if(bool) {
      return resolve(options)
    } 
    func.then(rs => {
      const qr = {[name]: _.get(rs, 'data.items', []).reduce((ls, vl) => _.isArray(vl[field]) ? [...ls, ...vl[field]] : [...ls, vl[field]], [])}
      options.query = {...options.query, ...qr}
      return resolve(options)
    })
  })
}

function search ({search, options}) {
  return (dispatch) => {
    let func,  name, field, bool = true

    if(search.value != '') {
      switch(search.key) {
        case SEARCH_KEY.CUSTOMER_PO: {
          func = orderServices.getListOrder({projection: '_id', query: JSON.stringify({customerPO: {$regex: '.*' + search.value + '.*', $options: 'i'}})})
          name = 'orderId'
          field = '_id'
          bool = false
          break;
        }
        case SEARCH_KEY.CONTRACTOR: {
          func = contractorServices.getListItem({projection: '_id', query: {name: {$regex: '.*' + search.value + '.*', $options: 'i'}}})
          name = 'contractorId'
          field = '_id'
          bool = false
          break;
        }
      }
    }

    let op = {}

    return searchInto(options, func,  name, field, bool).then(data => {
      op = data
      return getListItem(_.clone(op))(dispatch)
    }).then(res => {
      if(res && res.success && res.data && res.data.items && !res.data.items.length && op.pageNumber > 1) {
        _.set(op, "pageNumber", 1);
        getPurchases(op)(dispatch);
        op.pageNumber = 1;
      }
      return (_.set(res, "pageNumber", op.pageNumber))
    })
  };
}

export default {
  getOrdersByContractor,
  getListOrder,
  getListItem,
  deleteItem,
  editItem,
  addItem,
  getDetail,
  search
};
