import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import standardActions from '../../actions/standardActions';
import BaseComponent from '../base/BaseComponent';
import I18n from 'i18n-js';
import {toastr} from 'react-redux-toastr';
import _ from 'lodash';
import Moment from 'moment';

class GarmentSizes extends BaseComponent {
        constructor(props, context) {
                super(props, context);
                this.state = {
                        items: [],
                        garmentSizes: [],
                        add: [""]
                };
                this.onChange = this.onChange.bind(this)
        }

        componentWillMount() {
                this.props.standardPropsActions.getStandard();
        }

        componentWillUpdate(nextProps, nextState) {
                if (this.props.standardState.reload != nextProps.standardState.reload) {
                        nextProps.standardPropsActions.getStandard();
                }
        }

        componentWillReceiveProps(newProps) {
                const { items } = this.state;
                const newItems = _.cloneDeep(_.get(newProps.standardState, 'detail') || []);
                !_.isEqual(items, newItems) && this.setState({ items: newItems });

        }

        deleteItemAdd(id) {
                var del = this.state.items.garmentSize;
                var items = _.pullAt(del, id);
                this.setState({ del });
        }

        addItem(idx) {
                let add = this.state.add;
                let items = this.state.items.garmentSize;
                items.splice(idx + 1, 0, "");
                this.setState({ add });
        }

        addNewItem() {
                let add = _.concat(this.state.add, "");
                this.setState({ add });
        }

        setInput(id, value) {
                let items = this.state.add;
                items[id] = value;
                this.setState({ add: items });
        }

        existValue = (arr) => {
                let k = new Map([...new Set(arr)].map(
                        x => [x, arr.filter(y => y.toLowerCase() === x.toLowerCase()).length]
                    ));;
                let exist;
                k.forEach((val, key) => {
                        if(val > 1 ) exist = val;
                });
                return exist;
        }

        onChange(property, value) {
                let data = this.state.items.garmentSize;
                data[property] = value;
                this.setState({ data: _.set(data, property, value) });
        }

        save(id) {
                let {garmentSize} = this.state.items;
                let add = this.state.add;
                if(this.existValue(garmentSize) > 1){
                        toastr.error(I18n.t('Notify.Error'), 'Duplicate Size');
                } else {
                        let arr = _.union(_.compact(_.concat(garmentSize, add)));
                        let data = {
                                "garmentSize": arr
                        };
                        this.props.standardPropsActions.editItem(id, data);
                }
        }

        render() {
                const { tabCurrent, roles: {showEdit} } = this.props;
                let { tabActive } = this.state;
                let { items, garmentSizes, add } = this.state;
                return (
                        <div className="box-shadow">
                                {showEdit && <div className="title bd-dashed-bottom">
                                        <h2>{I18n.t('PageStandard.SizeName')}</h2>
                                        <span className="button-save cursor-pointer" onClick={() => { this.save(items._id); }}><i className="fa fa-floppy-o size-20" aria-hidden="true" /> {I18n.t('Btn.SAVE')}</span>
                                </div>}
                                <div className="wrap-content">
                                        <ul className="list" style={{width: '100%'}}>
                                                {(items.garmentSize || []).map((item, idx) => (
                                                        <li className="box col-md-3 col-xs-12" key={idx}>
                                                                <div className="input">
                                                                        <input type="text" className="form-control" value={item} onChange={(evt) => { this.onChange(idx, evt.target.value); }} />
                                                                </div>
                                                                <button className="button button-close" onClick={() => { this.deleteItemAdd(idx); }}></button>
                                                                <button className="cursor-pointer icon-add button" onClick={() => { this.addItem(idx); }}></button>
                                                        </li>
                                                ))}
                                                {items.garmentSize && items.garmentSize.length === 0 && (
                                                        <li className="box col-md-3 col-xs-12" key={0}>
                                                                <button className="cursor-pointer icon-add button" style={{display: 'inline-block', position: 'relative', marginLeft: 15}} onClick={() => { this.addItem(0); }}></button>
                                                        </li>
                                                )}
                                        </ul>
                                </div>
                        </div>
                );
        }
}

function mapStateToProps(state, ownProps) {
        return {
                ...ownProps,
                authState: state.authState,
                standardState: state.standardState
        };
}

function mapDispatchToProps(dispatch) {
        return {
                standardPropsActions: bindActionCreators(standardActions, dispatch)
        };
}

GarmentSizes.propTypes = {
        authState: PropTypes.object.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(GarmentSizes);