import {STYLE} from '../constants/actionTypes';
import initial from './initialState';

export default function (state = initial.styleState, action) {
  switch (action.type) {
    case STYLE.GET_LIST_SUCCESS:
      return {...state, items: action.data.items || [], count: action.data.total || 0};
    case STYLE.RELOAD:
      return {...state, reload: !state.reload};
    case STYLE.ADD_STYLE_SUCCESS:
      return {...state, reload: !state.reload, detail: action.data};
    case STYLE.GET_DETAIL_SUCCESS:
      return {...state, detail: action.data};
    case STYLE.RESET_DETAIL:
      return {...state, detail: {}};
    default:
      return state;
  }
}
