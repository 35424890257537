import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import BaseComponent from '../base/BaseComponent';

import SidebarOrders from '../common/layout/SidebarOrdersCommon';
var FileSaver = require('file-saver');
import ScreenSampleApprovalHistory from "./history/ScreenSampleApprovalHistory";
import {SAMPLE_APPROVAL_TAB} from '../../constants/constants'

import GarmentStatusEdit from './modal/GarmentStatusEdit';
import GarmentStatusLog from './modal/GarmentStatusLog';
import GarmentInfoEdit from './modal/GarmentInfoEdit';
import MaterialStatusEdit from './modal/MaterialStatusEdit';
import MaterialStatusLog from './modal/MaterialStatusLog';
import sampleApprovalActions from '../../actions/sampleApprovalActions';
import orderActions from '../../actions/orderActions';
import {sampleApprovalStatus} from '../../constants/EnumTypes';
import I18n from 'i18n-js';
import _ from 'lodash';
import moment from 'moment';
import {MENU_ITEM} from "../../constants/constants";
import * as utils from "../../functions/utils";
import {getUnCompletedOrders} from "../../functions/utils";
import ModalConfirm from '../common/modals/ModalConfirm';
import * as selectors from '../../selectors/permissions';
import * as selectorsOrder from '../../selectors/order';

import icExportIcon from '../../stylesheets/images/ic-export.svg';
import icEditNotextIcon from '../../stylesheets/images/ic-edit-notext.svg';
import icAuditLogIcon from '../../stylesheets/images/ic-auditLog.svg';
import icVIcon from '../../stylesheets/images/ic-v.svg';
import icXIcon from '../../stylesheets/images/ic-x.svg';
import icPpIcon from '../../stylesheets/images/ic-pp.svg';
import gpsIcon from '../../stylesheets/images/gps.svg';
import { PAGINATION } from '../../constants/constants';
class PageSampleApproval extends BaseComponent {
  constructor(props, context) {
    super(props, context);

    this.state = {
      activeScreen: ``,
      styles: [],
      styleSelected: {},
      item: {},
      selectedOrder: {},
      completedOrders: [],
      order: {},
      pageSize: PAGINATION.PAGE_SIZE,
      pageNumber: PAGINATION.PAGE_NUMBER,
    };
    this.onSubmitSearch = this.onSubmitSearch.bind(this)
  }

  viewHistory = (order) => {
    this.setState({order});
  }

  renderHistory(activeScreen) {
    switch (activeScreen) {
      case 1:
      default:{
        return (<ScreenSampleApprovalHistory authState={this.props.authState} modal={this.props.modal} viewHistory={this.viewHistory}/>);
      }
    }
  }

  componentWillMount() {
    this.getOrders();
    this.props.onChangePageNumber(PAGINATION.PAGE_NUMBER);
    this.props.onChangeSearch();
  }

  componentWillReceiveProps(newProps) {
    let {item} = this.state;
    !_.isEqual(item, newProps.sampleApprovalState.detail) && this.setState({
      item: _.cloneDeep(newProps.sampleApprovalState.detail) || {}
    });
    if (this.props.sampleApprovalState.reload != newProps.sampleApprovalState.reload) {
      let {item} = this.state;
      item.styleId && this.getItem(item.styleId);
    }
    this.props.sampleApprovalState.reload != newProps.sampleApprovalState.reload && this.props.modal.closeModal();
     
    const boolState = !_.isEqual(_.get(this.props.orderState, "items",[]), _.get(newProps.orderState, "items", []))
    if(boolState || _.isEmpty(this.state.completedOrders)) {
      const orders = _.get(newProps.orderState, "items", [])
      const orderUnCompleted = orders.filter(item => !item.isCompleted);
      const orderItems = this.filterCompeletedOrders(orderUnCompleted);
      //
      this.getStyles(orderItems[0] || {});
      this.setState({completedOrders: orderItems, selectedOrder: orderItems[0]});
    }
  }

  componentWillUpdate(nextProps, nextState) {
    
  }

  getUnCompletedOrders = (orders) => {
    return (orders || []).filter(item => !item.isCompleted); // Todo: and g-total < = shipped
  }

  filterCompeletedOrders(orders) {
    return orders.filter(o => {
      const length = o.styleOrders.filter(s => s.isApproved === false).length;
      return length > 0;
    });
  }

  getOrders() {
    const query = {pageSize: this.state.pageSize, pageNumber: this.state.pageNumber, isApproved: false, isCompleted: false, isCancel: false};
    const options = {populate: 'styleOrders'};
    this.props.orderActions.getListOrder(_.assign(query, options)); 
  }

  getItem(styleId) {
    this.props.sampleApprovalPropsActions.getDetail(styleId, {populate: 'materials.material,auditLog.materials.material'});
  }

  getStyles(order) {
    let {styles} = this.state;

    let stylesUpdate = _.get(order || {}, 'styleOrders') || [];
    const getUnApproveStyles = (stylesUpdate || []).filter(i => i.isApproved === false);
    let first = _.head(getUnApproveStyles) || {};
    if (!_.isEqual(styles, getUnApproveStyles)) {
      this.setState({styles: _.cloneDeep(getUnApproveStyles), styleSelected: first});
      first._id ? this.getItem(first._id) : this.setState({item: {}});
    }
  }

  selectStyle(styleId) {
    let {styles} = this.state;

    let found = styles.find(i => i._id === styleId);
    this.setState(state => ({
      styleSelected: found || {},
      item: {...state.item, styleId}
    }));

    styleId && this.getItem(styleId);
  }

  editGarmentStatus() {
    const {openModal, closeModal} = this.props.modal;
    const {item} = this.state;
    let modal = (<GarmentStatusEdit closeModal={closeModal} item={item}/>);
    openModal(modal);
  }

  openGarmentStatusLog() {
    const {openModal, closeModal} = this.props.modal;
    const {item} = this.state;
    let modal = (<GarmentStatusLog closeModal={closeModal} item={item}/>);

    openModal(modal);
  }

  editGarmentInfo() {
    const {openModal, closeModal} = this.props.modal;
    const {item} = this.state;
    let modal = (<GarmentInfoEdit closeModal={closeModal} item={item}/>);

    openModal(modal);
  }

  editMaterialStatus(type) {
    const {openModal, closeModal} = this.props.modal;
    const {item} = this.state;
    let modal = (<MaterialStatusEdit closeModal={closeModal} item={item} type={type}/>);

    openModal(modal);
  }

  openMaterialStatusLog(type) {
    const {openModal, closeModal} = this.props.modal;
    const {item} = this.state;
    // console.log(item);
    let modal = (<MaterialStatusLog closeModal={closeModal} item={item} type={type}/>);

    openModal(modal);
  }

  handleSelectOrder(order) {
    this.setState({ selectedOrder: order || {} });
    this.getStyles(order);
  }

  onHandleFinishButton = () => {
    const id = _.get(this.state, "item._id", null);
    const styleId = _.get(this.state, "item.styleId", null);
    id !== null && this.props.sampleApprovalPropsActions.updateSampleApproval(id, {status: 1}).then(res => {
      if(_.get(res, "data.status", false)){
        let isRemove = false;
        const selectOrder = Object.assign(this.state.selectedOrder, {});
        this.setState(function(state) {
          const item = Object.assign(state.item, {});
          item.status = true;
          let completedOrders = Object.assign(this.state.completedOrders, []);
          completedOrders.map(order => {
            if (order._id === state.selectedOrder._id) {
              const tempOrder = Object.assign(order, {});
              const styles = _.get(tempOrder, "styleOrders");
              styles.map(s => {
                if (s._id === styleId) {
                  const newStyles = Object.assign(s, {});
                  newStyles.isApproved = true;
                  return newStyles;
                }
                return s;
              });
              tempOrder.styleOrders = styles;
              if (styles.filter(s => s.isApproved === false).length === 0) {
                isRemove = true;
                return null;
              }
              return tempOrder;
            }
            return order;
          });
          completedOrders = completedOrders.filter(item => item !== null);
          return {
            item,
            completedOrders
          };
        });
        if (isRemove) {
          this.getOrders();
        } else {
          this.getOrders();
          this.handleSelectOrder(selectOrder);
        }
      }
    });
  }

  displayFinishButton = (e)=>{
    let {openModal, closeModal} = this.props.modal;
    let modal = (<ModalConfirm
      title={I18n.t('PageSampleApproval.Title')}
      confirmMessage={I18n.t('PageSampleApproval.Message')}
      messageOk={I18n.t('Btn.YES')}
      messageCancel={I18n.t('Btn.CANCEL')}
      handleClose={closeModal}
      handleOk={this.onHandleFinishButton}/>);
    openModal(modal);
  }

    btGetExport() {
      let {selectedOrder} = this.state;
      let url = this.props.getExportSampleApproval(selectedOrder._id);
      FileSaver.saveAs(url, "sample-approval.xlsx");
    }

    handleLoadMore(number) {
      const { isSearch, searchText } = this.props
      const {pageSize, pageNumber } = this.state;
      if (isSearch && searchText == '') { // Search not value 
        this.props.cancelChangeSearch();
        this.props.cancelChangePageNumber();
        this.getListFirstOrder(pageSize, pageNumber);
      } else if (isSearch && searchText) { // Search value
        this.props.onChangePageNumber(number)
        this.getListOrderSearch(pageSize, number)
      } else { // Not search
        this.props.onChangePageNumber(number)
        this.props.cancelChangeSearch()
        this.getListOrderMore(pageSize, number)
      }
    }

    getListOrderMore(size, number) {
      const query = { pageSize: size, pageNumber: number, populate: 'styleOrders', isApproved: false, isCompleted: false, isCancel: false};
      this.props.getListOrderMore(_.assign(query));
    }

    getListFirstOrder(size, number) {
      const query = { pageSize: size, pageNumber: number, isApproved: false, isCompleted: false, isCancel: false, populate: 'styleOrders' };
      this.props.loadFirstData(_.assign(query));
    }
  
    getListOrderSearch(size, number) {
      const { searchText } = this.props
      const query = { pageNumber: number, pageSize: size, isApproved: false, isCompleted: false, isCancel: false, populate: 'styleOrders', searchKey: searchText, filter: true};
      if (searchText) {
        this.props.getListOrderMore(_.assign(query));
      }
    }
  
    onSubmitSearch() {
      const { searchText } = this.props
      // const size = this.state.pageSize;
      // const number = this.state.pageNumber;
      const { pageSize, pageNumber } = this.state;
      // pageSize: pageSize, pageNumber: pageNumber, populate: 'styleOrders'
      const querySearch = { pageNumber: pageNumber, pageSize: pageSize, isApproved: false, isCompleted: false, isCancel: false, populate: 'styleOrders', searchKey: searchText, filter: true};
      const querySearchNull = { pageNumber: pageNumber, pageSize: pageSize, isApproved: false, isCompleted: false, isCancel: false, populate: 'styleOrders'};
      this.props.cancelChangePageNumber();
      if (searchText) {
        this.props.changeSearch()
        this.props.loadFirstData(_.assign(querySearch));
      } else {
        this.props.cancelChangeSearch()
        this.props.loadFirstData(_.assign(querySearchNull));
      } 
    }

  render() {
    const {appState, canComplete} = this.props;
    const {activeTab, activeScreen} = utils.getActiveScreen(appState);


    let {styles, styleSelected, item} = this.state;
    let garment = item.garment || {};
    let materials = item.materials || [];
    let info = item.info || {};

    let fields = ['fit', 'pp', 'top'];
    let fieldsInfo = [
      {text: 'Tech-Pack', field: 'techPack'},
      {text: 'Sew-By', field: 'sewBy'},
      {text: 'Pattern', field: 'pattern'},
      {text: 'Production Marker', field: 'productionMarker'},
      {text: 'Revised Production Marker', field: 'revisedProductionMarker'}
    ];
    let fieldsMaterial = ['counterSample', 'colorLapdip', 'ss'];
    let fieldsTypeMaterial = ['COUNTER-SAMPLE', 'COLOR-LAPDIP', 'S-S']
    fields.forEach(key => {
      if(!garment[key]) {
        garment[key] = {}
      }
      garment[key].tracking = []
    })
    let ms = [];
    materials.forEach(m => {
      if (m.material) {
        fieldsMaterial.forEach(f => {
          if (!m[f]) {
            m[f] = {};
          }
          m[f].tracking = [];
        })   
        ms.push(m);
      }
    })
    materials = ms;
    // get tracking status garment
    if(item.auditLog != null && item.auditLog.garment != null && item.auditLog.garment.length > 0) {
      let logs = [...item.auditLog.garment].sort((a,b) => new Date(a.date) - new Date(b.date))
      // let logs = item.auditLog.garment.sort((a,b) => new Date(a.date) - new Date(b.date))
      fields.forEach(key => {
        let temp = logs.filter(item => item.type.toLowerCase() == key)
        temp.forEach((item, index) => {
          if(index > 0) {
            if(item.data.status != temp[index - 1].data.status) {
              garment[key].tracking.push(item.data.status)
            }
          } else {
            garment[key].tracking.push(item.data.status)
          }
        })
      })
    }

    // get tracking status of material
    if(item.auditLog != null && item.auditLog.materials != null && item.auditLog.materials.length > 0) {
      let logs = [...item.auditLog.materials].sort((a,b) => new Date(a.date) - new Date(b.date))
      materials.forEach((material, index) => {
        fieldsTypeMaterial.forEach((K,idx) => {
          let key = fieldsMaterial[idx]
          material[key].tracking = []
          let temp = logs.filter(item => item.material._id ==  _.get(material, 'material._id', '') && item.type == K)
          temp.forEach((item, index) => {
            if(index > 0) {
              if(item.data.status != temp[index - 1].data.status) {
                material[key].tracking.push(item.data.status)
              }
            } else {
              material[key].tracking.push(item.data.status)
            }
          })
        })
      })
    }

    const {showView, showCreate, showEdit, showDelete} = utils.getRoleByAuthState(this.props.authState, MENU_ITEM.sampleApproval.key);
    const isSuperAdmin = (this.props.authState && this.props.authState.auth && this.props.authState.auth.isSupperAdmin);
    const {completedOrders, selectedOrder} = this.state;
    // console.log(completedOrders)
    switch (activeTab){
      case SAMPLE_APPROVAL_TAB.SAMPLE_APPROVAL_HISTORY: {
        return this.renderHistory(activeScreen)
      }
      default: {
        return (
          <div id="content">
            <SidebarOrders
              selectedOrder={selectedOrder || {}}
              orders={completedOrders || []}
              modal={this.props.modal}
              handleSelectedOrder={(order)=>this.handleSelectOrder(order)}

              {...this.props}
              handleLoadMore={(pageNumber) => this.handleLoadMore(pageNumber)}
              onSubmitSearch={this.onSubmitSearch}
            />
    
            <div className="content-main sample-approval-scroll">
              <div className="md content-main-header">
                <ul className="content-main-header-filter-style">
                  <li className="title">{I18n.t('PageSampleApproval.Style')}</li>
                  <li className="select">
                    <select className="form-control" value={styleSelected._id || ''}
                            onChange={evt => this.selectStyle(evt.target.value)}>
                      {/* <option value=""/> */}
                      {styles.map((i, idx) => !i.isApproved && (<option key={idx} value={i._id}>{i.style}</option>))}
                    </select>
                  </li>
                </ul>
              </div>
    
              {!_.isEmpty(styleSelected) && (
                <div className="md content-main-info">
                  <div className="md content-main-info-garment">
                    <h2>{I18n.t('PageSampleApproval.Garment')}
                      <span className="export cursor-pointer" onClick={() => this.btGetExport()}>Export <img src={icExportIcon} alt=""/></span>
                    </h2>
                    <div className="md content-main-info-grament-wrap">
                      <div className="content-left">
                        <div className="md content-main-edit-auditlog">
                          {(showCreate || showEdit || isSuperAdmin) &&
                          <span className="cursor-pointer" onClick={() => this.editGarmentStatus()}><img
                            src={icEditNotextIcon} alt="Edit"/></span>
                          }
                          <span className="cursor-pointer" onClick={() => this.openGarmentStatusLog()}><img
                            src={icAuditLogIcon} alt="Audit Log"/></span>
                        </div>
                        <table className="table table-striped">
                          <thead>
                          <tr>
                            <th>{I18n.t('PageSampleApproval.FIT')}</th>
                            <th>{I18n.t('PageSampleApproval.PP')}</th>
                            <th>{I18n.t('PageSampleApproval.TOP')}</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr>
                            {fields.map((k, idx) => (
                              <td key={idx}>
                              {_.get(garment, `${k}.tracking`).map((element, index) => (
                                <span key={index} className="sample-approval-status">
                                    {(element === sampleApprovalStatus.APPROVED && (
                                      <img src={icVIcon} alt=""/>))}
                                    {(element === sampleApprovalStatus.REJECTED && (
                                      <img src={icXIcon} alt=""/>))}
                                    {(element === sampleApprovalStatus.RESUBMITTED && (
                                      <img src={icPpIcon} alt=""/>))}
                                    {(element === sampleApprovalStatus.SUBMITTED && (
                                      <img src={gpsIcon} alt="" height="17" width="17"/>))}
                                </span>
                              ))}
                              </td>
                            ))}
                          </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="content-right">
                        <div className="md content-main-edit-auditlog">
                          {(showCreate || showEdit || isSuperAdmin) &&
                          <span className="cursor-pointer" onClick={() => this.editGarmentInfo()}><img
                            src={icEditNotextIcon} alt="Edit"/></span>
                          }
                        </div>
                        <ul className="md table-content">
                          {fieldsInfo.map((i, idx) => (
                            <li key={idx}>
                              <span className="title">{i.text}</span>
                              {_.get(info, i.field) && (<span className="value"><span
                                className="medium">{moment(info[i.field]).format('MMMM')}</span> {moment(info[i.field]).format('DD, YYYY')}</span>)}
                              {!_.get(info, i.field) && (<span className="value">-</span>)}
                            </li>
                          ))}
                          <li>
                            {I18n.t('PageSampleApproval.ADSubmit')}: <span className="medium">{info.adSubmit || ''}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
    
                  <div className="md content-main-info-material">
                    <table className="table table-striped" id="table-sample-approval">
                      <thead>
                      <tr>
                        <th>{I18n.t('PageSampleApproval.Material')}</th>
                        <th className="th-edit-auditlog center">
                          <div className="wrap-th-garment">{I18n.t('PageSampleApproval.CounterSample')}
                            <div className="md content-main-edit-auditlog">
                              {(showCreate || showEdit || isSuperAdmin) &&
                              <span className="cursor-pointer" onClick={() => this.editMaterialStatus('counterSample')}><img
                                src={icEditNotextIcon} alt="Edit"/></span>
                              }
                              <span className="cursor-pointer"
                                    onClick={() => this.openMaterialStatusLog('counterSample')}><img
                                src={icAuditLogIcon} alt="Audit Log"/></span>
                            </div>
                          </div>
                        </th>
                        <th className="th-edit-auditlog center">
                          <div className="wrap-th-garment">{I18n.t('PageSampleApproval.ColorLapdip')}
                            <div className="md content-main-edit-auditlog">
                              <span className="cursor-pointer" onClick={() => this.editMaterialStatus('colorLapdip')}><img
                                src={icEditNotextIcon} alt="Edit"/></span>
                              <span className="cursor-pointer"
                                    onClick={() => this.openMaterialStatusLog('colorLapdip')}><img
                                src={icAuditLogIcon} alt="Audit Log"/></span>
                            </div>
                          </div>
                        </th>
                        <th className="th-edit-auditlog center">
                          <div className="wrap-th-garment">{I18n.t('PageSampleApproval.S-S')}
                            <div className="md content-main-edit-auditlog">
                              {(showCreate || showEdit) &&
                              <span className="cursor-pointer" onClick={() => this.editMaterialStatus('ss')}><img
                                src={icEditNotextIcon} alt="Edit"/></span>
                              }
                              <span className="cursor-pointer" onClick={() => this.openMaterialStatusLog('ss')}><img
                                src={icAuditLogIcon} alt="Audit Log"/></span>
                            </div>
                          </div>
                        </th>
                      </tr>
                      </thead>
                      <tbody>
                      {materials.map((i, idx) => (
                        <tr key={idx}>
                          <td>{`${_.get(i, 'material.name', '')}${_.get(i, 'material.data.content', undefined) ? ` -- ${_.get(i, 'material.data.content', '')}` : ''}`}</td>
                          {fieldsMaterial.map((k, idx) => (
                              <td key={idx} className="center material-status">
                              {_.map(_.get(i, `${k}.tracking`, []), (element, index) => (
                                <span key={index} className="sample-approval-status">
                                    {(element === sampleApprovalStatus.APPROVED && (
                                      <img src={icVIcon} alt=""/>))}
                                    {(element === sampleApprovalStatus.REJECTED && (
                                      <img src={icXIcon} alt=""/>))}
                                    {(element === sampleApprovalStatus.RESUBMITTED && (
                                      <img src={icPpIcon} alt=""/>))}
                                    {(element === sampleApprovalStatus.SUBMITTED && (
                                      <img src={gpsIcon} alt="" height="17" width="17"/>))}
                                </span>
                              ))}
                              </td>
                          ))}
                        </tr>
                      ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="left font-s-13 color-number">
                      {item.repeatOrder}
                  </div>
                  {canComplete && (
                    <div className="text-center">
                      <button className="bt-orange text-uppercase" onClick={this.displayFinishButton}>{I18n.t('PageSampleApproval.Complete')}</button>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        );
      }
      // default:{
        
      // }
    }
  }
}

function mapStateToProps(state, ownProps) {
  return {
    appState: state.appState,
    authState: state.authState,
    orderState: state.orderState,
    sampleApprovalState: state.sampleApprovalState,
    canComplete: selectors.isSpecial({moduleKey:"sampleApproval", ...state}),

    pageNumber: selectorsOrder.getPageNumber(state),
    searchText: selectorsOrder.getFilterText(state),
    isLoadMore: selectorsOrder.isOpenLoadMore(state),
    isSearch: selectorsOrder.isSearch(state),
    items: selectorsOrder.getOrders(state),
    isLoading: selectorsOrder.isLoading(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    orderActions: bindActionCreators(orderActions, dispatch),
    sampleApprovalPropsActions: bindActionCreators(sampleApprovalActions, dispatch),
    getExportSampleApproval(orderId) {
      return sampleApprovalActions.getExportCurrent(orderId)
    },

    loadFirstData(options) {
      dispatch(orderActions.getListOrder(options));
    },

    getListOrderMore(options) {
      dispatch(orderActions.getListOrderMore(options));
    },

    cancelChangePageNumber() {
      dispatch(orderActions.cancelChangePageNumber());
    },
  
    onChangePageNumber(number) {
      dispatch(orderActions.changePageNumber(number));
    },

    onChangeSearch(text) {
      dispatch(orderActions.changeFilterText(text));
    },

    cancelChangeSearch() {
      dispatch(orderActions.cancelChangeSearch());
    },
  
    changeSearch() {
      dispatch(orderActions.changeSearch());
    },
  };
}

PageSampleApproval.propTypes = {
  authState: PropTypes.object.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(PageSampleApproval);
