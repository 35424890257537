import {PRODUCTION} from '../constants/actionTypes';
import initial from './initialState';

export default function (state = initial.productionOrderState, action) {
  switch (action.type) {
    case PRODUCTION.GET_PRODUCTION_ORDERS_SUCCESS:
      return {...state, items: Object.assign([], [...(action.data.items || [])]), count: action.data.count || 0};
    case PRODUCTION.GET_PRODUCTION_ORDERS_SUCCESS_MORE:
      return {...state, items: state.items.concat(...action.data.items), count: action.data.count || 0};
    case PRODUCTION.RELOAD_PRODUCTION:
      return {...state, reload: true};
    case PRODUCTION.CANCEL_RELOAD_PRODUCTION:
      return {...state, reload: false};
    case PRODUCTION.CHANGE_PAGE_NUMBER:{
      return {...state, pageNumber: action.data}
    }
    case PRODUCTION.RESET_CHANGE_PAGE_NUMBER:{
      return {...state, pageNumber: 1}
    }
    case PRODUCTION.CHANGE_FILTER_TEXT:{
      return {...state, filterText: action.data ? action.data : ''}
    }   
    case PRODUCTION.IS_SEARCH: {
      return {...state, isSearch: true}
    }
    case PRODUCTION.CANCEL_IS_SEARCH: {
      return {...state, isSearch: false}
    }     
    case PRODUCTION.LOAD_MORE: {
      return {...state, isLoadMore: true}
    }
    case PRODUCTION.CANCEL_LOAD_MORE: {
      return {...state, isLoadMore: false}
    }  
    case PRODUCTION.LOADING: {
      return {...state, loading: true}
    }
    case PRODUCTION.CANCEL_LOADING: {
      return {...state, loading: false}
    }  
    default:
      return state;
  }
}
