import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import customerActions from '../../../actions/customerActions';
import TextInputValidate from '../../common/fields/TextInputValidate';
import I18n from 'i18n-js';
import { isStringEmpty, validateEmail } from '../../../functions/helpers';
import ReactTelephoneInput from 'react-telephone-input/lib/withStyles'
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import {PaymentTermsOfCustomer, Currencies, SEARCH_KEY} from '../../../constants/constants';
import SelectValidate from '../../common/fields/SelectValidate';
import TableList from "../datatables/TableList";
class ShipOutInvEdit extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            shipOutInvDetail: {},
            tabActive: '',
            pageSize: 10,
            pageNumber: 1,
            columns: [
                { name: I18n.t('PageProduction.Material'), value: 'material.name', options: { clsTh: 'pagecrmName'} },
                { name: I18n.t('PageProduction.Content'), value: 'material.data.content', },
                { name: I18n.t('PageProduction.ReplacementPO'), value: 'replacementPO', options: { clsTh: 'pagecrmCompany'} },
                { name: I18n.t('PageProduction.Color'), value: 'color', options: { clsTh: 'pagecrmAddress'} },
                { name: I18n.t('PageProduction.Size'), value: 'size' },
                { name: I18n.t('PageProduction.Needed'), value: 'needToBuy' },
                { name: I18n.t('PageProduction.XMIL'), value: 'XMIL', type: 'date',formatType: 'MMM DD, YYYY' },
                { name: I18n.t('PageProduction.ETD'), value: 'ETD', type: 'date',formatType: 'MMM DD, YYYY' },
                { name: I18n.t('PageProduction.ETA'), value: 'ETA', type: 'date',formatType: 'MMM DD, YYYY' },

            ],
            items: [],
            total: 0,
            searchValue: {},
        }
        this.setUser = this.setUser.bind(this)
    }
    componentWillMount() {
        const data = _.get(this.props, 'shipOutInvDetail', {});
        this.setState({ shipOutInvDetail: _.cloneDeep(data) });
    }

    setUser(field, value) {
        var shipOutInvDetail = this.state.shipOutInvDetail
        shipOutInvDetail[field] = value
        var errors = this.state.errors
        errors[field] = null
        this.setState({ shipOutInvDetail: shipOutInvDetail, errors })
    }

    close = () => {
        this.props.closeModal();
    }


    render() {
        const { columns, shipOutInvDetail } = this.state;
        return (
            <div className='modal-dialog lgShipOut' id="modal-popup-customer">
                <div className='modal-content'>
                    <form>
                        <div className='modal-header'>
                            <a className='close' onClick={() => this.close()}><span
                                aria-hidden='true'>&times;</span></a>
                            <h4 className='modal-title'>{I18n.t('PageProduction.PurchaseOrderDetails')}</h4>
                        </div>

                        <div className='modal-body pd-0 inline-block'>
                            <div className="col-md-12 col-xs-12 box">
                                <div className='form-group'>
                                    <TableList {...this.props} items={shipOutInvDetail.materials || []} columns={columns || []} tId="purchase-table" />
                                </div>
                            </div>

                        </div>

                        <div className='modal-footer'>
                            <button type='button' className='bt-black' onClick={() => this.close()}>{I18n.t('Btn.CLOSE')}</button>

                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        appState: state.appState,
        authState: state.authState,
        customerState: state.customerState
    };
}

function mapDispatchToProps(dispatch) {
    return {
        customerPropsActions: bindActionCreators(customerActions, dispatch)
    };
}

ShipOutInvEdit.propTypes = {
    authState: PropTypes.object.isRequired,
    shipOutInvDetail: PropTypes.object
};

export default connect(mapStateToProps, mapDispatchToProps)(ShipOutInvEdit);
