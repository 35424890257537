import {combineReducers} from 'redux';
import C from '../../constants/ship-out/createShipment';
import moment from 'moment'

const standard = (state = null, action) => {
    const {type, payload} = action;
    switch(type) {
        case C.CHANGE_SHIPMENT_INFOMATION:
            return payload;
        case C.RESET_DATA:
            return null;
        default:
            return state;
    }
}

const shipmentInfo = (state = {}, action) => {
    const {type, payload} = action;
    switch(type) {
        case C.CHANGE_SHIPMENT_INFOMATION:
            const result = {
                //col1
                customInv: '',
                isCompleted: false,
                date: moment().format(),
                cont: '',
                seal: '',
                contType: '',
                from: '',
                to: '',
                shipMode: '',
                shipAgencies: '',
                transportNo: '',
                //col2
                deliveryTerm: '',
                etd: moment().format(),
                eta: moment().format(),
                dateDelivered: moment().format(),// moment().format(),
                declarationNo: '',
                declarationDate: moment().format(),
                qtyPkg: 0,
                dropOffCont: '',
                warehouseAddress: '',
                pickupCont: '',
                //col3
                gw: 0,
                kgs: 0,
                cbm: 0,
                forwarder: '',
                advance: 0,
                advancdDate: moment().format(),
                billNo: '',
                borderGate: '',
                truckNo: '',
                route: '',
            }
            return result;
        case C.CHANGE_SHIPMENT_INFO_VALUE:
            const {key, value} = payload;
            let temp = _.cloneDeep(state);
            temp[key] = value;
            return temp;
        case C.RESET_DATA:
            return null;
        default:
            return state
    }
}

const transportFee = (state = null, action) => {
    const {type, payload} = action;
    switch(type) {
        case C.CHANGE_SHIPMENT_INFOMATION:
            const result = {
                crsPaperwork: 0,
                localCharge: 0,
                trunkingOut: 0,
                note: ''
            }
            return result;
        case C.CHANGE_TRANSPORT_FEE_VALUE:
            const {key, value} = payload;
            let temp = _.cloneDeep(state);
            temp[key] = value;
            return temp;
        case C.RESET_DATA:
            return null;
        default:
            return state;
    }
}

const pklShips = (state = null, action) => {
    const {type, payload} = action
    switch(type) {
        case C.CHANGE_PKL_SHIP_LIST:
            return payload;
        case C.RESET_DATA:
            return null;
        default:
            return state;
    }
}

const listOrders = (state = null, action) => {
    const {type, payload} = action
    switch(type) {
        case C.CHANGE_PKL_SHIP_LIST:
            let dic = []
            payload.map(item => item.invoice.items.map(it => {
                const result = {
                    pklShipId: item._id,
                    _id: it._id,
                    customerPO: it.customerPO,
                    commodity: it.commodity,
                    styles: it.styles.map(style => {
                        return {
                            _id: style.style._id,
                            style: style.style.style,
                            quantity: _.sum(style.cutTickets.map(cut => _.sum(cut.colors.map(color => color.quantity)))),
                        }
                    })
                }
                dic.push(result);
            }))
            return dic;
        case C.RESET_DATA:
            return null;
        default:
            return state;
    }
}

const activeOrders = (state = [], action) => {
    const { type, payload } = action;
    switch (type) {
        case C.CHANGE_PKL_SHIP_LIST: {
            // Active first order after reload data
            const [item] = payload && payload[0].pklProds;
            return item ? [item._id] : [];
        }
        case C.CHANGE_ACTIVE_ORDER: {
            const { orderId, value } = payload;
            let filtered = _.cloneDeep(state).filter(it => it !== orderId);
            if (value) filtered = [...filtered, orderId];
            return filtered;
        }
        case C.RESET_DATA:
            return [];
        default:
            return state;
    }
}

const activeStyles = (state = {}, action) => {
    const { type, payload } = action;
    switch(type) {
        case C.CHANGE_PKL_SHIP_LIST: {
            const dic = {};
            payload && payload.map(item => item.pklProds.map(it => {
                // Active one style of each order if found one
                const [style] = it.styles;
                dic[it._id] = style ? style.style._id : null;
            }))
            return dic;
        }
        case C.CHANGE_ACTIVE_STYLE: {
            const { orderId, styleId } = payload;
            const dic = _.cloneDeep(state);
            dic[orderId] = styleId;
            return dic;
        }
        case C.REMOVE_STYLE: {
            const { orderId, styleId } = payload;
            const dic = _.cloneDeep(state);
            if (dic[orderId] === styleId) dic[orderId] = null;
            return dic;
        }
        case C.RESET_DATA:
            return {};
        default:
            return state;
    }
}

const dataInputs = (state = null, action) => {
    const {type, payload} = action;
    let dic = {};
    let key;
    switch(type) {
        case C.CHANGE_PKL_SHIP_LIST:
            payload.map(pklShip => pklShip.invoice.items.map(item => item.styles.map(style => {
                key = `${item._id}:${style.style._id}`
                dic[key] = [];
                style.cutTickets.map(cut => cut.colors.map(color => {
                    // const key = `${item._id}:${style.style._id}:${cut.cutTicket._id}:${color.color._id}`
                    dic[key].push({
                        cutTicket: cut.cutTicket.cutTicket,
                        cutTicketId: cut.cutTicket._id,
                        store: cut.cutTicket.store && cut.cutTicket.store.name,
                        color: color.color.name,
                        colorId: color.color._id,
                        quantity: color.quantity,
                        price: color.price,
                        amount: color.price * color.quantity,
                        unitPrice: 0,
                        unitAmount: 0,
                    })
            }))})))
            return dic;
        case C.CHANGE_UNIT_PRICE:
            const {orderId, styleId, cutTicketId, colorId, value} = payload;
            dic = _.cloneDeep(state);
            key = `${orderId}:${styleId}`;
            dic[key].map(item => {
                if(item.cutTicketId == cutTicketId && item.colorId == colorId) {
                    item.unitPrice = value;
                    item.unitAmount = value * item.quantity
                }
            })
            return dic
        case C.RESET_DATA:
            return null;
        default:
            return state;
    }
}

const success = (state = false, action) => {
    const {type, payload} = action;
    switch(type) {
        case C.RESET_DATA:
            return null;
        case C.CREATE_SUCCESS:
            return payload;
        default:
            return state;
    }
}


const listForwarder = (state = [], action) => {
    const { type, payload } = action;
    switch(type) {
        case C.CHANGE_LIST_FORWARDER:
            return payload;
        case C.ADD_FORWARDER_INTO_LIST:
            let dic = _.cloneDeep(state);
            dic = dic.filter(item => item.forwarder != payload);
            dic.unshift({_id: payload, forwarder: payload});
            return dic;
        case C.RESET_DATA:
            return [];
        default:
            return state;
    }
}

const activeScreenCreateShipment = (state = false, action) => {
    const {type, payload} = action;
    switch(type) {
      case C.ACTIVE_SCREEN_CREATE_SHIPMENT:
        return payload;
      default:
        return state;
    }
  }

export const createShipment = combineReducers ({
    success,
    standard,
    shipmentInfo,
    transportFee,
    pklShips,
    listOrders,
    activeOrders,
    activeStyles,
    dataInputs,
    listForwarder,
    activeScreenCreateShipment,
})