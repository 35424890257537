import * as types from '../constants/actionTypes';
import {showLoading, hideLoading} from './commonUseActions';
import systemSettingServices from '../services/systemSettingServices';

function updateEmailSetting (id, data) {
  return (dispatch) => {
    showLoading(types, dispatch);

    systemSettingServices.updateEmailSetting(id, data).then((res) => {
      hideLoading(types, dispatch);

      dispatch({
        type: types.APP.SHOW_SUCCESS_ALERT,
      });
      dispatch({
        type: types.SYSTEM_SETTINGS.RELOAD,
        data: true
      });
    }, (error) => {
      hideLoading(types, dispatch, error);
    });
  };
}

function getListSystemSetting(options = {}) {
  return (dispatch) => {
    showLoading(types, dispatch);

    systemSettingServices.getListSystemSetting().then((res) => {
      hideLoading(types, dispatch);

      dispatch({
        type: types.SYSTEM_SETTINGS.GET_LIST_SUCCESS,
        data: res.data || []
      });
    }, (error) => {
      hideLoading(types, dispatch, error);
    });
  };
}

function updateProduction (id, data) {
  return (dispatch) => {
    showLoading(types, dispatch);

    systemSettingServices.updateProduction(id, data).then((res) => {
      hideLoading(types, dispatch);

      dispatch({
        type: types.APP.SHOW_SUCCESS_ALERT,
      });
      dispatch({
        type: types.SYSTEM_SETTINGS.RELOAD,
        data: true
      });
    }, (error) => {
      hideLoading(types, dispatch, error);
    });
  };
}

function getListNotifications(options = {}) {
  return (dispatch) => {
    showLoading(types, dispatch);

    systemSettingServices.getListNotifications(options).then((res) => {
      hideLoading(types, dispatch);

      dispatch({
        type: types.SYSTEM_SETTINGS.GET_LIST_NOTIFICATIONS_SUCCESS,
        data: res.data || []
      });
    }, (error) => {
      hideLoading(types, dispatch, error);
    });
  };
}


function updatePurchaseDeviation (id, data) {
  return (dispatch) => {
    showLoading(types, dispatch);

    systemSettingServices.updatePurchaseDeviation(id, data).then((res) => {
      hideLoading(types, dispatch);

      dispatch({
        type: types.APP.SHOW_SUCCESS_ALERT,
      });
      dispatch({
        type: types.SYSTEM_SETTINGS.RELOAD,
        data: true
      });
    }, (error) => {
      hideLoading(types, dispatch, error);
    });
  };
}

function updateAgeDefinition (id, data) {
    return (dispatch) => {
    showLoading(types, dispatch);

    systemSettingServices.updateAgeDefinition(id, data).then((res) => {
      hideLoading(types, dispatch);

      dispatch({
        type: types.APP.SHOW_SUCCESS_ALERT,
      });
      dispatch({
        type: types.SYSTEM_SETTINGS.RELOAD,
        data: true
      });
    }, (error) => {
      hideLoading(types, dispatch, error);
    });
  };
}

function updateSetting (id, data) {
  return (dispatch) => {
    showLoading(types, dispatch);

    systemSettingServices.updateSetting(id, data).then((res) => {
      hideLoading(types, dispatch);

      dispatch({
        type: types.APP.SHOW_SUCCESS_ALERT,
      });
      dispatch({
        type: types.SYSTEM_SETTINGS.RELOAD,
        data: true
      });
    }, (error) => {
      hideLoading(types, dispatch, error);
    });
  };
}


export default {
  updateEmailSetting,
  updateProduction,
  updateAgeDefinition,
  getListSystemSetting,
  getListNotifications,
  updatePurchaseDeviation,
  updateSetting
};
