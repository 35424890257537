import Base from './base';

class Supplier extends Base {
  constructor () {
    super();

    this.getListSupplierDAL = this.getListSupplierDAL.bind(this);
    this.addSupplierDAL = this.addSupplierDAL.bind(this);
    this.editSupplierDAL = this.editSupplierDAL.bind(this);
    this.deleteSupplierDAL = this.deleteSupplierDAL.bind(this);
  }

  getListSupplierDAL (query) {
    return super.execute(this.Urls.supplier.list, this.ApiMethod.get, query);
  }

  addSupplierDAL (body) {
    return super.execute(this.Urls.supplier.create, this.ApiMethod.post, body);
  }

  editSupplierDAL (id, body) {
    return super.execute(this.Urls.supplier.update + '/' + id, this.ApiMethod.put, body);
  }

  getSupplierDAL (id, options) {
    return super.execute(this.Urls.supplier.detail + '/' + id, this.ApiMethod.get, options);
  }

  deleteSupplierDAL (id) {
    return super.execute(this.Urls.supplier.delete + '/' + id, this.ApiMethod.delete, null);
  }
}

export default new Supplier();
