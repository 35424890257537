const C = 'PAYABLE_INVOICE_HISTORY';

export default {
    CHANGE_LIST_HISTORY: `${C}_CHANGE_LIST_HISTORY`,
    CHANGE_LIST_FILTER: `${C}_CHANGE_LIST_FILTER`,
    CHANGE_SELECT_FILTER: `${C}_CHANGE_SELECT_FILTER`,
    CHANGE_SEARCH_VALUE: `${C}_CHANGE_SEARCH_VALUE`,
    CHANGE_PAGING_TOTAL: `${C}_CHANGE_PAGING_TOTAL`,
    CHANGE_PAGING_CURRENT: `${C}_CHANGE_PAGING_CURRENT`,
    CHANGE_TOTAL_AMOUNT: `${C}_CHANGE_TOTAL_AMOUNT`,
    CHANGE_FROM_DATE: `${C}_CHANGE_FROM_DATE`,
    CHANGE_TO_DATE: `${C}_CHANGE_TO_DATE`,
    CHANGE_CURRENCY: `${C}_CHANGE_CURRENCY`,
    CHANGE_PAID_DETAIL: `${C}CHANGE_PAID_DETAIL`,
    CHANGE_LOADING: `${C}_CHANGE_LOADING`,
    CHANGE_PAGING_CURRENT: `${C}_CHANGE_PAGING_CURRENT`,
    CHANGE_LIST_FILTER: `${C}_CHANGE_LIST_FILTER`,
    CHANGE_SELECT_FILTER: `${C}_CHANGE_SELECT_FILTER`,
    CHANGE_SEARCH_VALUE: `${C}_CHANGE_SEARCH_VALUE`,
}