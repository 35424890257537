
import _ from 'lodash';
import { pathImage } from '../../../functions/helpers';

export const isOpen = ({ showImagesGallery }) => !!showImagesGallery.images && showImagesGallery.images.length > 0;

export const getImages = ({ showImagesGallery }) => {
  const { images } = showImagesGallery;
  return _.map(images, img => ({
    'original': pathImage(img),
    'thumbnail': pathImage(img),
  }))
}
