import React from "react";
import PropTypes from "prop-types";
import moment from 'moment';
import TableList from "../../../../components/common/datatables/TableList";
import * as utils from "../../../../functions/utils";
import ReactExport from "react-data-export";
import _ from "lodash";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const formatCurrency = require('format-currency');

const PreviewIEContainer = props => {
    let labelName = 'Name :';
    let name = {value: props.name || '', style: {font: {patternType: "solid", color: {rgb: "FFF86B00"}}}};

    let labelINVNo = 'INV No : ';
    let valueINVNo = {
        value: formatCurrency(props.invNo) + ' VND',
        style: {font: {patternType: "solid", color: {rgb: "FFFF0000"}}}
    };
    let labelIssuedDate = 'Issued Date : ';
    let valueIssuedDate = {
        value: moment(props.issuedDate).format("MMM DD, YYYY"),
        style: {font: {patternType: "solid", color: {rgb: "FFFF0000"}}}
    };

    let labelVATAmount = 'VAT Amount : ';
    let totalVATAmount = {
        value: formatCurrency(props.vatAmount) + ' VND',
        style: {font: {patternType: "solid", color: {rgb: "FFFF0000"}}}
    };
    let labelAmountINV = 'Amount INV : ';
    let totalAmountINV  = {
        value: formatCurrency(props.amountINV) + ' VND',
        style: {font: {patternType: "solid", color: {rgb: "FFFF0000"}}}
    };
    let labelTotalAmountINV = 'Total Amount INV : ';
    let totalTotalAmountINV = {
        value: formatCurrency(props.totalAmountINV) + ' VND',
        style: {font: {patternType: "solid", color: {rgb: "FFFF0000"}}}
    };

    let header = [labelName, name, labelINVNo, valueINVNo, labelIssuedDate, valueIssuedDate, labelVATAmount, totalVATAmount, labelAmountINV, totalAmountINV, labelTotalAmountINV, totalTotalAmountINV];
    let putData = [];

    _.map(props.listContainer, (el) => {
        let keys = [
            {key: 'string', value: 'contNo', color: '00000000'},
            {key: 'string', value: 'route', color: '00000000'},
            {key: 'string', value: 'warehouseAddress', color: '00000000'},
            {key: 'string', value: 'pickupCont', color: '00000000'},
            {key: 'number', value: 'dropOffCont', color: '00000000'},
            {key: 'number', value: 'truckingIn', color: '00000000'},
            {key: 'number', value: 'cost', color: '00000000'},
            {key: 'text', value: 'chargesIncurred', color: '00000000'},
            {key: 'number', value: 'liftOnFee', color: 'FFFF0000'},
            {key: 'text', value: 'liftOnINV', color: 'FF00FF00'},
            {key: 'number', value: 'liftOffFee', color: 'FFFF0000'},
            {key: 'text', value: 'liftOffINV', color: 'FF00FF00'}
        ];

        putData.push(utils.mapDataForCell(el, keys));
    });

    let headerTable = ['Cont No', 'Route', 'Warehouse Addr', 'Pickup Cont', 'Drop Off Cont',
        'Truck No', 'Cost', 'Charges Incurred', 'Lift On Fee', 'Lift On INV', 'Lift Off Fee', 'Lift Off INV'];
    let dataHeaderTable = [];
    for(let i = 0; i < headerTable.length ; i++){
        dataHeaderTable.push({
            value: headerTable[i],
            style: utils.genStyleHeaderForCell('00000000')
        });
    }
    putData.unshift(dataHeaderTable);

    const multiDataSet = [
        {
            columns: [
                {title: '', width: {wch: 15}},//pixels width
                {title: '', width: {wch: 25}},//char width
                {title: '', width: {wch: 15}},
                {title: '', width: {wch: 25}},
                {title: '', width: {wch: 15}},//pixels width
                {title: '', width: {wch: 25}},//char width
                {title: '', width: {wch: 15}},//pixels width
                {title: '', width: {wch: 25}},//char width
                {title: '', width: {wch: 15}},
                {title: '', width: {wch: 25}},
                {title: '', width: {wch: 15}},//pixels width
                {title: '', width: {wch: 25}},//char width
            ],
            data: [header]
        },

        {
            xSteps: 0, // Will start putting cell with 1 empty cell on left most
            ySteps: 0, //will put space of 5 rows,
            columns: [],
            data: putData
        }
    ];
    let nameFile = '';
    let d = new Date();
    if(props.invNo){
        nameFile = 'INV_' + props.invNo + '_' + (Number(d.getMonth()) + 1 < 10 ? "0" + (Number(d.getMonth()) + 1) : Number(d.getMonth()) + 1)  + '_' + d.getFullYear();
    }else{
        nameFile = 'INV_' +  (Number(d.getMonth()) + 1 < 10 ? "0" + (Number(d.getMonth()) + 1) : Number(d.getMonth()) + 1)  + '_' + d.getFullYear();
    }


    return (
        <div>
            <ul className="content-header-title float-none pd-0-5 justify-content-space-between display-flex preview-ie-container">
                <li>
                    <p>Name
                        <span className="color-orange medium mg-left-25 size-16">{props.name}</span>
                    </p> 
                </li>
                <li>
                    <p>INV No
                        <span className="color-number medium mg-left-25 size-16">{props.invNo}</span>
                    </p>
                </li>
                <li>
                    <p>Issued Date
                        <span className="color-number medium mg-left-25 size-16">{moment(props.issuedDate).format("MMM DD, YYYY")}</span>
                    </p>
                </li>

                {props.type === 'historyPreview' &&
                <li>
                    <p>Credit/Debit
                        <span className="color-number medium mg-left-25 size-16">{'empty'}</span>
                    </p>
                </li>}

                <li>
                    <p>VAT Amount
                        <span className="color-number medium mg-left-25 size-16">{formatCurrency(props.vatAmount)} VND</span>
                    </p>
                </li>
                <li>
                    <p>Amount INV
                        <span className="color-number medium mg-left-25 size-16">{formatCurrency(props.amountINV)} VND</span>
                    </p>
                </li>
                <li>
                    <p>Total Amount INV
                        <span className="color-number medium mg-left-25 size-16">{formatCurrency(props.totalAmountINV)} VND</span>
                    </p>
                </li>
                <li className="add-filter-top">
                    <ExcelFile filename={nameFile}
                               element={
                                   <span className="cursor-pointer">
                                <i className="fa fa-floppy-o" aria-hidden="true"></i>{"Export"}
                            </span>
                               }>
                        <ExcelSheet dataSet={multiDataSet}  name={nameFile}/>
                    </ExcelFile>

                </li>
            </ul>
            <div className="pd-5">
                <TableList items={props.listContainer || []} columns={props.columns || []} options={{ clsTbl: 'global-table' }} />
            </div>
        </div>
    )
}
PreviewIEContainer.propTypes = {
    listContainer: PropTypes.array,
    columns: PropTypes.array,
    name: PropTypes.string,
    invNo: PropTypes.string,
    issuedDate: PropTypes.string,
    vatAmount: PropTypes.number,
    amountINV: PropTypes.number,
    totalAmountINV: PropTypes.number,
}

PreviewIEContainer.defaultProps = {
    listContainer: [],
    columns: [],
    name: '',
    invNo: '',
    issuedDate: '',
    vatAmount: 0,
    amountINV: 0,
    totalAmountINV: 0,
}
export default PreviewIEContainer