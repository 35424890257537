import React from 'react';
import PropTypes from 'prop-types';

export default class CheckBox extends React.Component {
  render() {
    const { checked, id, disabled, label, tooltip} = this.props;
    const disabledSpan = disabled ? "disabledSpan" : "";
    return (
      <div className="style-checkbox position-relative">
        <input id={id} type="checkbox" checked={checked} onChange={this.props.onChange} disabled={disabled}/> 
        <label data-toggle="tooltip" title={tooltip} htmlFor={id}><span className={disabledSpan + " mg-right-10"}><span></span></span>{label}</label>
      </div>
    );
  }

}

CheckBox.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.string,
  tooltip: PropTypes.string,
  onChange: PropTypes.func,
};