import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import orderActions from '../../actions/orderActions';
import { getExportWip } from '../../actions/reportAnalytic/revemueActions';
import TableList from '../common/datatables/TableList';
import SearchBar from '../common/layout/SearchBar';
import {SEARCH_LIST, SEARCH_BAR_EVENT, SEARCH_KEY, SHIPPING_SEARCH_KEY} from "../../constants/constants";
import I18n from 'i18n-js';
import _ from 'lodash';
import moment from 'moment';
let FileSaver = require('file-saver');
import loadingIcon from '../../stylesheets/images/loading.svg';
class CustomerPOList extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            orders: [],
            columns: [
                { name: I18n.t('PageOrder.Customer'), value: 'customer', type: 'text', dataType: 'text', options: { clsTd: 'width15Percent', clsTh: 'width15Percent' } },
                { name: I18n.t('PageOrder.CustomerPO'), value: 'customerPO', type: 'text', dataType: 'text', options: { clsTd: 'width15Percent', clsTh: 'width15Percent' } },
                { name: I18n.t('PageOrder.Style'), value: 'style', type: 'text', dataType: 'text', options: { clsTd: 'width15Percent', clsTh: 'width15Percent' } },
                { name: I18n.t('PageOrder.Quantity'), value: 'quantity', type: 'text', dataType: 'text', options: { clsTd: 'width15Percent', clsTh: 'width15Percent' } },
                { name: I18n.t('PageOrder.Ex-Factory'), value: 'exFactory', type: 'date', formatType: "MMM DD, YYYY", options: { clsTd: 'width20Percent', clsTh: 'width20Percent' } },
                { name: I18n.t('PageOrder.IHDate'), value: 'ihDate', type: 'date', formatType: "MMM DD, YYYY", options: { clsTd: 'width20Percent', clsTh: 'width20Percent' } },
                // { name: 'Order date', value: 'orderDate', type: 'date', dataType: 'date', options: {clsTd: 'width20Percent', clsTh: 'width20Percent'}},
                { name: I18n.t('PageOrder.Age'), value: 'age', type: 'text', dataType: 'text', options: { clsTd: 'width15Percent', clsTh: 'width15Percent' } }
            ],
            total: 0,
            search: { key: SEARCH_LIST.CRM_ANALYTIC[0].field, value: "" },

            index: 0,
        };
        this.increaseIndex = this.increaseIndex.bind(this);
    }

    increaseIndex() {
      if (this.state.search.value) {
        let options = { populate: 'styleOrders', sort: { createdAt: -1 }, isCompleted: false, index: this.state.index + 1 };
        this.search(this.state.search, options);
      } else {
        this.getListItem({}, this.state.index + 1);
      }
      this.setState((prev) => {
        return { index: prev.index + 1 };
      })
    }

    componentWillMount = () => {
        let { index } = this.state;
        this.getListItem({}, index);
    }

    componentWillReceiveProps = (newProps) => {
        let { orders, search } = this.state;
        !_.isEqual(orders, newProps.orderState.items) && this.setState({
            orders: (newProps.orderState.items || []),
            total: newProps.orderState.count || 0
        });
    }

    age = (a, b) => {
        a.setHours(0, 0, 0, 0)
        b.setHours(0, 0, 0, 0)
        return moment(b).diff(moment(a), 'days') + 1
    }

    getListItem = (query = {}, index) => {
        query = { ...query, isCompleted: false };
        const options = { ...{ populate: 'styleOrders', sort: { createdAt: -1 } }, index, ...query };
        this.props.orderPropsActions.getListOrderForReport(options);
    }

    onChangeSearch = (property, value) => {
        switch (property) {
            case SEARCH_BAR_EVENT.FILTER: {
                this.setState({ search: { key: value, value: "" } });
                break;
            }
            case SEARCH_BAR_EVENT.SEARCH: {
                this.setState({ search: { ...this.state.search, value: value } });
                break;
            }
        }
    }


    onSearch = () => {
        const { search, index } = this.state;
        let options = { populate: 'styleOrders', sort: { createdAt: -1 }, isCompleted: false, index };

        this.search(search, options);
    }

    search = (search, options) => {

        if (search && search.key && search.value) {
            let qr = {}
            switch (search.key) {
                case SEARCH_KEY.CUSTOMER_PO: {
                    qr = { customerPO: { $regex: '.*' + search.value + '.*', $options: 'i' } }
                    options.query = JSON.stringify({ ...options.query, ...qr })
                    break;
                }
                case SEARCH_KEY.CUSTOMER: {
                    qr = { customer: search.value }
                    options.query = JSON.stringify({ ...options.query, ...qr })
                    break;
                }
                case SEARCH_KEY.STYLE: {
                    qr = { style: search.value }
                    options.query = JSON.stringify({ ...options.query, ...qr })
                    break;
                }
                case SEARCH_KEY.AGE: {
                    let db = new Date()
                    db.setHours(0, 0, 0, 0)

                    const diff = (parseInt(search.value) || 0) - 1
                    db.setDate(db.getDate() - diff)
                    let de = _.clone(db)
                    de.setDate(de.getDate() + 1);

                    const qr = { orderDate: { $gte: db, $lt: de } }
                    options.query = JSON.stringify({ ...options.query, ...qr })
                    break;
                }
                case SEARCH_KEY.IHDATE: {
                    let db = new Date(search.value)
                    db.setHours(0, 0, 0, 0)

                    let de = _.clone(db)
                    de.setDate(de.getDate() + 1);
                    const qr = { ihDate: { $gte: db, $lt: de } }
                    options.query = JSON.stringify({ ...options.query, ...qr })
                    break;
                }
            }

        }
        this.setState({options});
        this.props.orderPropsActions.searchForReport({ search, options }).then();
    }

    btGetExport(e) {
        e.preventDefault();
        const { search, index } = this.state;
        // TODO build filter params
        let filters = `?index=${index}`;
        switch (search.key) {
            case SEARCH_KEY.CUSTOMER_PO: {
                if (search.value) filters += `&filter=customerPO&value=${search.value}`;
                break;
            }
            case SEARCH_KEY.CUSTOMER: {
                if (search.value) filters += `&filter=customer&value=${search.value}`;
                break;
            }
            case SEARCH_KEY.STYLE: {
                if (search.value) filters += `&filter=style&value=${search.value}`;
                break;
            }
            case SEARCH_KEY.IHDATE: {
                let db = new Date(search.value)
                db.setHours(0, 0, 0, 0)

                let de = _.clone(db)
                de.setDate(de.getDate() + 1);
                const qr = { ihDate: { $gte: db, $lt: de } }

                if (search.value) filters += `&filter=ihDate&value=${JSON.stringify(qr)}`;
                break;
            }
            default:
        }
        let url = this.props.getExportWip(filters);
        FileSaver.saveAs(url, "WIP_data.xlsx");
    }

    paneDidMount = (node) => {    
        if(node) {      
          node.addEventListener("scroll", this.handleScroll.bind(this));      
        }
      }
    
      handleScroll = (event) => {    
        const node = event.target;
        const bottom = node.scrollHeight - node.scrollTop === node.clientHeight;
        if (bottom) {    
            this.increaseIndex()  
        }    
      }

    render = () => {
      const page = 20;
      let { total, orders, columns } = this.state;
      const that = this; 
      return (
        <div className="box-shadow bg-white">
          <SearchBar name="Add Assignment" fields={SEARCH_LIST.CRM_ANALYTIC}
            onChangeSearch={this.onChangeSearch}
            onSearch={this.onSearch} />
          <div className="wrap-global-table">
            <div ref={that.paneDidMount} className="wrap-global-table box-shadow-none pd-0 inline-block w-full table-analytic">
              <TableList {...this.props} items={orders.slice(0, this.state.index * page + page)} columns={columns || []} options={{ clsTbl: 'global-table box-shadow-none w-full' }} />
              {total > (page + this.state.index * page) && 
              <div className="loading-icon"><img src={loadingIcon} /></div>
              }
            </div>
          </div>
          <div className="center">
            <ul className="list-button mg-top-10 mg-bottom-10">
              {/* {total > (page + this.state.index * page) && <li><a className="bt-black" onClick={this.increaseIndex}>Load More..</a></li>} */}
              <li> <a className="bt-orange" onClick={e => this.btGetExport(e)}>EXPORT WIP</a> </li>
            </ul>
          </div>
        </div>
      )
    }

}

CustomerPOList.propTypes = {

};

const mapStateToProps = state => ({
    authState: state.authState,
    orderState: state.orderState
})

const mapDispatchToProps = dispatch => ({
    orderPropsActions: bindActionCreators(orderActions, dispatch),
    getExportWip(options) {
        return getExportWip(options);
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(CustomerPOList);
