import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import I18n from 'i18n-js';
import TableList from '../../../../components/common/datatables/TableList';
import SearchBar from '../../../common/components/SearchBar';
import { Pagination } from 'react-bootstrap';
import { SHIPOUT_SEARCH_KEY, SEARCH_LIST, SEARCH_BAR_EVENT } from '../../../../constants/constants';
import { SHIP_OUT } from '../../../../constants/routeConstants';
import * as shipOutActions from '../../../../actions/ship-out/showPklProdListActions';
import * as selector from '../../../../selectors/ship-out/showPklProdList';
import { getPackingDetail } from '../../../../actions/ship-out/viewPklOfProdDetailActions';
import CustomPagination from '../../../../components/common/datatables/CustomPagination';

class PklOfProductionContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNumber: 1,
      pageSize: 10,
      total: 0,
      columns: [
        { name: '', type: 'checkbox', value: 'checked', options: { event: this.onCheck }, checkable: 'checkable' },
        { name: I18n.t('PageShiping.CustomerPO'), value: 'customerPO' },
        { name: I18n.t('PageShiping.Customer'), value: 'customer' },
        { name: I18n.t('PageShiping.PackingType'), value: 'packingType' },
        {
          name: I18n.t('PageProduction.PlanTotal'),
          getValue: i => {
            let planTotal = i.planTotal == 0 ? '-' : i.planTotal;
            return planTotal;
          },
        },
        {
          name: I18n.t('PageProduction.ActualTotal'),
          getValue: i => {
            let actualTotal = i.actualTotal == 0 ? '-' : i.actualTotal;
            return actualTotal;
          },
        },
        {
          name: I18n.t('PageProduction.Remaining'),
          getValue: i => {
            let remaining = i.remaining == 0 || !i.remaining ? '-' : i.remaining;
            return remaining;
          },
          options: { clsTd: 'color-green' },
        },
        { name: I18n.t('PageShiping.ExFactory'), type: 'date', formatType: 'MMM DD, YYYY', value: 'notifyDate' },
      ],
      actions: [
        () => {
          return { type: 'view', click: data => this.view(data) };
        },
      ],
      search: { key: this.props.listFilter[0].field, value: '', beginDate: '', endDate: '' },
    };
  }

  componentWillMount() {
    this.getList(this.getBaseOption());
  }

  componentWillReceiveProps(nextProps) {}

  getBaseOption = () => {
    const { pageSize, pageNumber } = this.state;
    let options = {
      query: JSON.stringify({ isCancel: { $ne: true } }),
      sort: JSON.stringify({ createdAt: -1 }),
      pageSize,
      pageNumber,
    };
    return options;
  };

  getList(options) {
    this.props.shipOutActions.getPklProdList(options);
  }

  onChangeFilter = value => {
    this.props.shipOutActions.changeSelectedFilter(value);
    this.props.changeSearchStatus(false);
  };

  onChangeValue = (value, pos) => {
    this.props.shipOutActions.changeSearchValue(value, pos);
    this.props.changeSearchStatus(false);
  };

  onSearch = () => {
    this.props.changeSearchStatus(true);
    const { searched, getSearchQuery, selectedFilter, searchValue } = this.props;
    this.getList(getSearchQuery(true, this.getBaseOption(), selectedFilter, searchValue));
  };

  selectPage = page => {
    let options = this.getBaseOption();
    options.pageNumber = page;
    const { searched, getSearchQuery, selectedFilter, searchValue } = this.props;
    this.getList(getSearchQuery(searched, options, selectedFilter, searchValue));
  };

  onCheck = (item, name, value) => {
    this.props.changeCheckedList(item.raw, value);
    // console.log(item, name, value)
  };

  view = data => {
    this.props.router.history.push(SHIP_OUT.PKL_PRODUCTION.VIEW);
    this.props.getPackingDetail(data._id);
  };

  viewCreatePklShip = packingType => {
    this.props.viewCreate();
    if (packingType == 'GOHH') this.props.router.history.push(SHIP_OUT.PKL_PRODUCTION.CREATE_GOHH);
    else this.props.router.history.push(SHIP_OUT.PKL_PRODUCTION.CREATE_CARTON);
  };
  render() {
    const { columns, actions } = this.state;
    const {
      listPklOfProduction,
      listFilter,
      selectedFilter,
      searchValue,
      totalPages,
      currentPage,
      canCreatePKlShip,
      canPreview,
      packingType,
      roles: { showCreate },
    } = this.props;
    return (
      <div>
        <div className="wrap-search-position top-5">
          <SearchBar
            name="Shipping"
            fields={listFilter}
            selectedFilter={selectedFilter}
            searchValue={searchValue || {}}
            onChangeFilter={this.onChangeFilter}
            onChangeValue={this.onChangeValue}
            onSearch={this.onSearch}
          />
        </div>
        <TableList
          columns={columns || []}
          items={listPklOfProduction || []}
          actions={actions || []}
          options={{ clsTbl: 'global-table' }}
        />

        <CustomPagination items={totalPages} activePage={currentPage} onSelect={this.selectPage} />
        <div className="center mg-top-15 clearBoth">
          <button
            className={canPreview ? 'bt-black text-uppercase' : 'cursor-text bg-gray-bold text-uppercase'}
            style={{ marginRight: 10 }}
            onClick={() => {
              this.props.router.history.push(SHIP_OUT.PKL_PRODUCTION.PREVIEW);
            }}
            disabled={!canPreview}>
            {I18n.t(`PageShiping.Preview`)}
          </button>
          {showCreate && (
            <button
              className={canCreatePKlShip ? 'bt-orange text-uppercase' : 'cursor-text bg-gray-bold text-uppercase'}
              disabled={!canCreatePKlShip}
              onClick={() => this.viewCreatePklShip(packingType)}>
              {I18n.t(`PageShiping.CreatePKLProduction`)}
            </button>
          )}
        </div>
      </div>
    );
  }
}

PklOfProductionContainer.propTypes = {};

PklOfProductionContainer.defaultProps = {};

const mapStateToProps = state => ({
  shipOutState: state.shipOutState,
  listFilter: selector.getListFilters(),
  listPklOfProduction: selector.getDisplayList(state),
  selectedFilter: selector.getSelectedFilter(state),
  searchValue: selector.getSearchValue(state),
  totalPages: selector.getTotalPages(state),
  currentPage: selector.getCurrentPage(state),
  canCreatePKlShip: selector.canCreatePklShip(state),
  canPreview: selector.canPreview(state),
  packingType: selector.getPackingTypeOfCheckedList(state),
  searched: selector.getSearched(state),
  getSearchQuery: selector.getSearchQuery,
});

const mapDispatchToProps = dispatch => ({
  shipOutActions: bindActionCreators(shipOutActions, dispatch),
  changeCheckedList(item, value) {
    dispatch(shipOutActions.changeCheckedList(item, value));
  },
  getPackingDetail(id) {
    dispatch(getPackingDetail(id));
  },
  changeSearchStatus(value) {
    dispatch(shipOutActions.changeSearchStatus(value));
  },
  viewCreate(value) {
    dispatch(shipOutActions.viewCreate(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PklOfProductionContainer);
