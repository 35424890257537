import C from '../../../constants/customer-po/current/editCutTicket';
import * as R from 'ramda';
import _ from 'lodash';
import { shipMode } from '../../../constants/EnumTypes';
import storeServices from '../../../services/storeServices';
import standardServices from '../../../services/standardServices';
import cutTicketServices from '../../../services/cutTicketServices';
import { listCutTickets } from './showOrderDetail';
import {toastr} from 'react-redux-toastr';


export const showModal = cutTicket => dispatch => {
  dispatch({ type: C.SHOW_MODAL, payload: cutTicket });
};

export const hideModal = () => dispatch => {
  dispatch({ type: C.RESET_DATA });
};

export const changeSelectedWarehouse = warehouse => dispatch => {
  dispatch({ type: C.CHANGE_SELECTED_WAREHOUSE, payload: warehouse });
};

export const changeCutTicketName = value => dispatch => {
  dispatch({ type: C.CHANGE_CUT_TICKET_NAME, payload: value });
};

export const listSelectableStores = () => dispatch => {
  dispatch({ type: C.FETCH_SELECTABLE_STORES });
  storeServices.getListStore({}).then(res => {
    dispatch({ type: C.CANCEL_FETCH_SELECTABLE_STORES });
    const payload = R.path(['data', 'items'], res);
    dispatch({ type: C.CHANGE_SELECTABLE_STORES, payload });
  }).catch(error => {
    dispatch({ type: C.CANCEL_FETCH_SELECTABLE_STORES });
    console.error(error);
  });
};

export const changeSelectedStore = store => dispatch => {
  dispatch({ type: C.CHANGE_SELECTED_STORE, payload: store });
}

export const listSelectableShipModes = () => dispatch => {
  dispatch({ type: C.FETCH_SELECTABLE_SHIP_MODES });
  standardServices.getStandard({}).then(res => {
    dispatch({ type: C.CANCEL_FETCH_SELECTABLE_SHIP_MODES });
    const standard = _.head(R.pathOr([], ['data', 'items'], res));
    const others = _.map(R.pathOr([], ['shipping', 'other'], standard), item => item.shipMod );
    const payload = _.union([..._.map(shipMode, (v, k) => v), ...others]);
    dispatch({ type: C.CHANGE_SELECTABLE_SHIP_MODES, payload });
  }).catch(error => {
    dispatch({ type: C.CANCEL_FETCH_SELECTABLE_SHIP_MODES });
    console.error(error);
  });
};

export const changeSelectedShipMode = value => dispatch => {
  dispatch({ type: C.CHANGE_SELECTED_SHIP_MODE, payload: value });
}

export const changeMainLabel = value => dispatch => {
  dispatch({ type: C.CHANGE_MAIN_LABEL, payload: value });
}

export const changeIhDate = value => dispatch => {
  dispatch({ type: C.CHANGE_IH_DATE, payload: value });
}

export const changeNote = value => dispatch => {
  dispatch({ type: C.CHANGE_NOTE, payload: value });
}

export const submit = (cutId, body) => dispatch => {
  dispatch({ type: C.SUBMIT });
  cutTicketServices.editCutTicket(cutId, body).then(res => {
    dispatch({ type: C.CANCEL_SUBMIT });
    dispatch({ type: C.RESET_DATA });
    dispatch(listCutTickets(R.path(['data', 'cutTicket', 'styleId'], res)));
  }).catch(error => {
    toastr.error('Error', error.error);
    dispatch({ type: C.CANCEL_SUBMIT });
    console.error(error);
  });
}
