import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import contractorActions from '../../../actions/contractorActions';
import assignmentActions from '../../../actions/assignmentActions';
const DateTimeField = require('react-datetime');
import AssignmentCutTickets from '../../production/assignment/AssignmentCutTickets';
import _ from 'lodash';
import moment from 'moment';
import I18n from 'i18n-js';

class ModalAssignmentForm extends Component {
  constructor(props, context) {
    super(props, context);
    let assignmentDetail = _.get(this.props, 'assignmentDetail', {});
    _.set(assignmentDetail, "deadline", assignmentDetail.deadline || new Date());
    this.state = {
      orders: [],
      contractors: [],
      errors: {},
      assignmentDetail: assignmentDetail || {deadline: new Date()},
      selectedOrder: {},
      isChooseStyle: assignmentDetail ? (assignmentDetail.styles || []).length > 0 : false,
      isShowStyleDetail: !!assignmentDetail
    };
  }

  componentWillMount = () => {
    this.getListOrder();
    this.getListContractor();
  }

  componentWillReceiveProps = newProps => {
    let {orders, contractors, assignmentDetail} = this.state;
    if(newProps.assignmentState.listOrder && newProps.assignmentState.listOrder.length && !_.isEqual(orders, newProps.assignmentState.listOrder)) {
      this.setState({orders: _.cloneDeep(newProps.assignmentState.listOrder), selectedOrder: newProps.assignmentState.listOrder.find(order => order._id === assignmentDetail.orderId) || {} });
    }
    !_.isEqual(contractors, newProps.contractorState.items) && this.setState({contractors: newProps.contractorState.items || []});
  }

  getListOrder = (options = {}) => {
    const {assignmentDetail} = this.state
    if(assignmentDetail._id) {
      options.query = {...(options.query || {}), _id: {$ne: assignmentDetail._id}}
    }
    this.props.assignmentPropsActions.getListOrder(options);
  }

  getListContractor = (query = {}) => {
    let options = {};
    this.props.contractorPropsActions.getListItem(_.assign({query, sort: {name: 1}}, options));
  }

  onChange = (property, value) => {
    let {assignmentDetail, orders} = this.state;
    if (property === 'orderId') {
      let selectedOrder = orders.find(i => i._id === value);
      this.setState({
        assignmentDetail: _.set(assignmentDetail, property, value),
        selectedOrder: orders.find(i => i._id === value),
        isShowStyleDetail: false,
      });

      this.handleSelectAllStyle(true, selectedOrder);
    } else {
      this.setState({
        assignmentDetail: _.set(assignmentDetail, property, value),
      });
    }
    this.validateAfterChange(property, value);
  }

  handleChooseStyle = (value) => {
    this.setState({isChooseStyle: !!value});
  }

  handleSelectAllStyle = (isSelected, selectedOrder) => {
    let {assignmentDetail} = this.state;
    assignmentDetail.styles = [];
    if(isSelected){
      (selectedOrder.styleOrders || []).forEach(styleOrder => {
        assignmentDetail.styles.push(this.prepareSelectedStyle(styleOrder))
      });
    }
    this.setState({assignmentDetail});
  }

  handleClickOk = () => {
    this.setState({isShowStyleDetail: true});
  }

  handleSelectStyle = (styleOrder, isSelected) => {
    let {assignmentDetail} = this.state;
    assignmentDetail.styles = isSelected ? [...assignmentDetail.styles, this.prepareSelectedStyle(styleOrder)] : assignmentDetail.styles.filter(style => style.style._id !== styleOrder._id);
    this.setState({assignmentDetail});
  }

  prepareSelectedStyle = (style) => {
    return {
      style: _.cloneDeep(style),
      cutTickets: style.cutTickets || [],
      total: (style.cutTickets || []).reduce((total, cutTicket) => {return total += parseInt(cutTicket.gTotal)}, 0)
    }
  }

  handleSelectAllCutTicket = (selectingStyleId, cutTickets, isSelected) => {
    let {assignmentDetail} = this.state;
    let selectingStyle = assignmentDetail.styles.find(style => style.style._id === selectingStyleId);

    if (selectingStyle){
      selectingStyle.cutTickets = isSelected ? cutTickets : [];
      this.setState({assignmentDetail});
    }
  }

  handleSelectCutTicket = (selectingStyleId, cutTicket, isSelected) => {
    let {assignmentDetail} = this.state;
    (assignmentDetail.styles || []).forEach(style => {
      if(style.style._id === selectingStyleId) {
        let cutTickets = style.cutTickets || [];
        cutTickets = isSelected ? _.concat(cutTickets, cutTicket) : cutTickets.filter(c => c._id !== cutTicket._id);
        _.set(style, 'cutTickets', cutTickets);
      }
    })

    this.setState({assignmentDetail});
  }

  close = () => {
    this.props.closeModal();
  }

  validateAfterChange = (property, value) => {
    let { errors } = this.state;
    delete errors[property];
    switch (property) {
      case 'contractorId': {
        if (!value) {
          errors['contractorId'] = I18n.t('PageProduction.ContractorIsRequired');
        }
        this.setState({errors});
        break; 
      }
      case 'orderId': {
        if (!value) {
          errors['orderId'] = I18n.t('PageProduction.CustomerPOIsRequired');
        }
        this.setState({errors});
        break; 
      }
      default:
    }
  }

  validateBeforeSave = () => {
    let { assignmentDetail, errors } = this.state;
    errors = {};
    if (!assignmentDetail.contractorId) {
      errors['contractorId'] = I18n.t('PageProduction.ContractorIsRequired');
      this.setState({errors});
      return false;
    }
    if (!assignmentDetail.orderId) {
      errors['orderId'] = I18n.t('PageProduction.CustomerPOIsRequired');
      this.setState({errors});
      return false;
    }

    this.setState({errors});
    return true;
  }

  save = () => {
    if (!this.validateBeforeSave())
      return;

    let {assignmentDetail} = this.state;
      assignmentDetail.styles.map(item =>{
          var evens = _.remove(item.cutTickets, function(n) {
              return !n.disable;
          });
          item.styleName = item.style.style;
          item.cutTickets = evens;
      });
    if(assignmentDetail._id){
      this.props.assignmentPropsActions.editItem(assignmentDetail._id, Object.assign({}, assignmentDetail));
    } else {
      this.props.assignmentPropsActions.addItem(assignmentDetail);
    }
  }

  render() {
    let {assignmentDetail, orders, contractors, errors, isChooseStyle, selectedOrder, isShowStyleDetail} = this.state;
    let orderStyles = selectedOrder.styleOrders || [];
    return (
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <a className="close" onClick={() => this.close()}><span>&times;</span></a>
            <h4 className="modal-title" id="myModalLabel">{assignmentDetail._id ? I18n.t('PageProduction.EDITASSIGNMENT') : I18n.t('PageProduction.ADDASSIGNMENT')}</h4>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <div className="col-md-4 col-xs-12 text">
                <p>{I18n.t('PageProduction.Contractor')}</p> <span className="color-red"> *</span>
              </div>
              <div className="col-md-8 col-xs-12 value">
                <select className="form-control" value={assignmentDetail.contractorId || ''}
                        onChange={evt => this.onChange('contractorId', evt.target.value)}>
                  <option value=""/>
                  {contractors.map((i, idx) => (<option key={idx} value={i._id}>{i.name}</option>))}
                </select>
                {errors['contractorId'] && <div className="alert-warning">{errors['contractorId']}</div>}
              </div>
            </div>

            <div className="form-group">
              <div className="col-md-4 col-xs-12 text">
                <p>{I18n.t('PageProduction.Deadline')}</p>
              </div>
              <div className="col-md-8 col-xs-12 value">
                <DateTimeField timeFormat={false} closeOnSelect dateFormat="MMM DD, YYYY"
                               value={moment(assignmentDetail.deadline || {})}
                               onChange={date => this.onChange('deadline', date.format())}/>
              </div>
            </div>

            <div className="form-group">
              <div className="col-md-4 col-xs-12 text">
                <p>{I18n.t('PageProduction.CustomerPO')}</p> <span className="color-red"> *</span>
              </div>
              <div className="col-md-8 col-xs-12 value">
                <select className="form-control" value={assignmentDetail.orderId || ''}
                        onChange={evt => this.onChange('orderId', evt.target.value)}>
                  <option value=""/>
                  {orders.map((i, idx) => (<option key={idx} value={i._id}>{i.customerPO}</option>))}
                </select>
                {errors['orderId'] && <div className="alert-warning">{errors['orderId']}</div>}
              </div>
            </div>

            <div className="form-group">
              <div className="col-md-4 col-xs-12 text"/>
              <div className="col-md-8 col-xs-12 value">
                <div className="style-checkbox">
                  <input type="checkbox" id="chooseStyle" checked={!!isChooseStyle} value
                         onChange={evt => this.handleChooseStyle(evt.target.checked)}/><label htmlFor="chooseStyle"><span
                  className="mg-right-15"><span /></span>{I18n.t('PageProduction.ChooseStyle')}</label>
                </div>
              </div>
            </div>

            {
              isChooseStyle && (
              <div className="form-group bd-dashed-top bd-dashed-bottom pd-top-20 pd-bottom-20">
                <div className="col-md-4 col-xs-12 text">
                  <p>{I18n.t('PageProduction.Style')}</p>
                </div>
                <div className="col-md-8 col-xs-12 value">
                  <div className="dropdown input">
                    <input type="text" className="form-control btn btn-secondary dropdown-toggle"
                           placeholder="Choose Style" id="dropdownMenuButton" data-toggle="dropdown"
                           aria-haspopup="true" aria-expanded="false"/>
                    <ul className="dropdown-menu dropdown-menu-style pd-0 w-full">
                      <li className="border bd-dashed-bottom">
                        <input type="checkbox" id="check1"
                               checked={assignmentDetail.styles && assignmentDetail.styles.length === orderStyles.length}
                               onChange={evt => this.handleSelectAllStyle(evt.target.checked, selectedOrder)}/> {I18n.t('PageProduction.All')}
                      </li>
                      {(orderStyles || []).map((styleOrder, idx) => {
                        return (
                          <li key={styleOrder._id}>
                            <input type="checkbox" id={`check_pick_${styleOrder._id}`}
                                   checked={!!assignmentDetail.styles.find(style => style.style && style.style._id === styleOrder._id)}
                                   onChange={evt => this.handleSelectStyle(styleOrder, evt.target.checked)}/> {styleOrder.style}
                          </li>
                        );
                      })}
                      <li className="center bd-solid-top" onClick={()=>{this.handleClickOk()}}>
                        <span className="color-orange bold">{I18n.t('PageProduction.OK')}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            )}

            { isShowStyleDetail && isChooseStyle && assignmentDetail.styles
            && <AssignmentCutTickets styles={assignmentDetail.styles || []}
                                     orderStyles={orderStyles}
                                     handleSelectAllCutTicket={this.handleSelectAllCutTicket}
                                     handleSelectCutTicket={this.handleSelectCutTicket}/>
            }
          </div>
          <div className="modal-footer">
            <button type="button" className="bt-black" onClick={() => this.close()}>{I18n.t('Btn.CANCEL')}</button>
            <button type="button" className="bt-orange mg-l-5" onClick={() => this.save()}>{I18n.t('Btn.SAVE')}</button>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    contractorState: state.contractorState,
    assignmentState: state.assignmentState
  };
}

function mapDispatchToProps(dispatch) {
  return {
    contractorPropsActions: bindActionCreators(contractorActions, dispatch),
    assignmentPropsActions: bindActionCreators(assignmentActions, dispatch)
  };
}

ModalAssignmentForm.propTypes = {
  assignmentState: PropTypes.object
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalAssignmentForm);
