import React from 'react';
import PropTypes from 'prop-types';
import {  connect } from 'react-redux';
import {toastr} from 'react-redux-toastr'
import {bindActionCreators} from 'redux';
import BaseComponent from '../../base/BaseComponent';
import I18n from 'i18n-js';
import shippingActions from '../../../actions/shippingActions';
import { getExportShipin } from '../../../api/shippingApi';
import appActions from '../../../actions/appActions';
import {  SHIPPING_SCREEN } from "../../../constants/constants";
import _ from 'lodash'
import {Pagination} from "react-bootstrap";
import TableList from '../../common/datatables/TableList';
import SearchBar from '../../common/layout/SearchBar';
import * as types from '../../../constants/actionTypes'
import {SEARCH_KEY, SEARCH_LIST, SEARCH_BAR_EVENT,SHIPPING_SEARCH_KEY, FOLLOWUP_SEARCH_KEY} from "../../../constants/constants";
import shippingApi from '../../../api/shippingApi';
import ModalConfirm from '../../common/modals/ModalConfirm';
import moment from 'moment';
var FileSaver = require('file-saver');

import icExportIcon from '../../../stylesheets/images/ic-export.svg';
import CustomPagination from '../../common/datatables/CustomPagination';

class TabShipIn extends BaseComponent {
  constructor(props, context) {
    super(props, context);
    this.state = ({
      notifies: [],
      pageNumber: 1,
      pageSize: 10,
      total: 0,
      columns: [
        {name: I18n.t('PageShiping.Date'), type: 'date', formatType: 'MMM DD, YYYY', value: 'advanceDate'},
        {name: I18n.t('PageShiping.DeclarationNo'), value: 'declarationNo'},
        {name: I18n.t('PageShiping.DeclarationDate'), type: 'date', formatType: 'MMM DD, YYYY', value: 'declarationDate'},
        {name: I18n.t('PageShiping.ShipMode'), value: 'shipMode'},
        {name: I18n.t('PageShiping.BillNo'), value: 'billNo'},
        {name: I18n.t('PageShiping.ETD'), type: 'date', formatType: 'MMM DD, YYYY', value: 'etd'},
        {name: I18n.t('PageShiping.ETA'), type: 'date', formatType: 'MMM DD, YYYY', value: 'eta'},
        {name: I18n.t("PageShiping.TotalFee"), value: 'totalFee', options: {clsTd: 'color-red'}},
      ],
      actions: [],
      items: [],
      search: {key: SHIPPING_SEARCH_KEY.DECLARATION_NO, value: '', beginDate: '', endDate: ''}
    });

  }

  componentWillMount()
  {
    this.getList(this.getBaseOption());
    return true;
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.appState.page.loadMore){
      this.props.dispatch({
        type: types.APP.SET_PAGE_CURRENT,
        data: {
          isEdit: false,
          tab: '',
          tabChild: 'tabShipIn',
          loadMore: false,
        }
      })
      this.getList(this.getBaseOption())
    }
    //this.getList(this.getBaseOption());
    return true;
  }
  getBaseOption = () => {
    const {pageSize, pageNumber} = this.state;
    let options = {
      query: JSON.stringify({isCancel: {$ne: true}, isCompleted : false}),
      sort: JSON.stringify({createdAt:-1}), 
      populate:JSON.stringify([
        {
          path: 'purchaseOrders.purchaseOrder', 
          populate:[
            {
              path: 'styleId',
              select: 'orderId style gTotal',
              populate: {
                path: 'orderId',
                select: 'customerPO gTotal'
              }
            },
            {
              path: 'materials.material',
              select: 'name data.content'
            }
          ] 
        }
      ]),
      pageSize, 
      pageNumber,
    };
    return options
  }

  resetItems = items => {
    const {pageSize, pageNumber, notifies} = this.state;
    
    return items.map((item, idx) => {
      const idxN = _.findIndex(notifies, n => n._id == item._id)
      let totalFee = '$' + (item.crsPaperWork + item.localCharge + item.truckingIn);
      return {...item, totalFee, no: (pageSize * (pageNumber - 1)) + idx + 1}
    })
  }

  getList = options => {
    this.props.dispatch({type: types.APP.SHOW_LOADING});
    shippingApi.getShipInList(options).then(rs => {
      this.props.dispatch({type: types.APP.HIDE_LOADING})

      if(rs.success) {
        this.setState({
          items: this.resetItems(_.get(rs,'data.items',{})),
          total: _.get(rs,'data.count',0)
        })
      }
      else toastr.error(I18n.t('Notify.Error'), rs.error)
    },
    err => {
      this.props.dispatch({type: types.APP.HIDE_LOADING})
      toastr.error(I18n.t('Notify.Error'), err.error)
    })
  }

  selectPage = (page) => {
    this.setState({pageNumber: page});
    this.onSearch(page);
  };

  //- searching
  onChangeSearch = (property, value , pos) => {
    const { search } = this.state;
    this.setState({pageNumber: 1});

    switch(property) {
      case SEARCH_BAR_EVENT.FILTER: {
        this.setState({search: {key: value, value: ""}});
        break;
      }
      case SEARCH_BAR_EVENT.SEARCH: {
        if( this.state.search.key ==='date' || this.state.search.key ==='declarationDate' ||
            this.state.search.key ==='etd' || this.state.search.key ==='eta'){
              if( pos === 'from'){
                search.beginDate = value
                this.setState({ search })      
              }
              if( pos === 'to'){
                search.endDate = value
                this.setState({ search })
              }
        }else if(this.state.search.key ==='ETAFactory'){
            this.setState({search: {...this.state.search,value : value }});
        }else{
          this.setState({search: {...this.state.search,value : value }});
        }
        break;
      }
    }
    // console.log(this.state.search);
  }

  getQueryWithDate = (search) => {
    let qr = {}
    let db = new Date(search.beginDate)
    db.setHours(0,0,0,0)
    let de = new Date(search.endDate)
    de.setHours(23,59,59,999)
    qr = {$gte: db, $lt: de}
    return qr
  }

  onSearch = (page) =>
  {
    const { search } = this.state;
    const options = this.getBaseOption()
    options.query = JSON.parse(options.query)
    options.pageNumber = page != null ? page : options.pageNumber
    if(search && search.key && (search.value || (search.beginDate && search.endDate))) {
      let qr = {}
      let vl = {}
      switch(search.key) {
        case SHIPPING_SEARCH_KEY.DECLARATION_NO: {
          qr = {declarationNo: {$regex: '.*' + search.value + '.*', $options: 'i'}}
          // options.query = qr
          break;
        }
        case SHIPPING_SEARCH_KEY.SHIP_MODE: {
          qr = {shipMode: {$regex: '.*' + search.value + '.*', $options: 'i'}}
          // options.query = qr
          break;
        }
        case SHIPPING_SEARCH_KEY.BILL_NO: {
          qr = {billNo: {$regex: '.*' + search.value + '.*', $options: 'i'}}
          // options.query = qr
          break;
        }
        case SHIPPING_SEARCH_KEY.DECLARATION_DATE: {
          vl = this.getQueryWithDate(this.state.search)
          qr = {declarationDate: vl}
          // options.query = qr
          break;
        }
        case SHIPPING_SEARCH_KEY.ETD: {
          vl = this.getQueryWithDate(this.state.search)
          qr = {etd: vl}
          // options.query = qr
          break;
        }
        case SHIPPING_SEARCH_KEY.ETA: {
            vl = this.getQueryWithDate(this.state.search)
            qr = {eta: vl}
            // options.query = qr
            break;
        }
        case SHIPPING_SEARCH_KEY.ETA_FACTORY: {
            let dateSearch = {
              beginDate : this.state.search.value,
              endDate: this.state.search.value
            }
            vl = this.getQueryWithDate(dateSearch)
            qr = {etaFactory: vl}
            // options.query = qr
            break;
        }
        case SHIPPING_SEARCH_KEY.ADVANCE: {
            qr = {advance: Number(search.value) }
            // options.query = qr
            break;
        }
        default:
          break;
      }
      options.query = {...options.query, ...qr}
    }

    this.props.dispatch({type: types.APP.SHOW_LOADING})

    shippingApi.search(search, options, SHIPPING_SEARCH_KEY).then(rs => {
      this.props.dispatch({type: types.APP.HIDE_LOADING})
      if(rs.success) {
        this.setState({
          items: this.resetItems(_.get(rs,'data.items',{})),
          total: _.get(rs,'data.count',0)
        })
      }
    },
    err => {
      this.props.dispatch({type: types.APP.HIDE_LOADING})
      // toastr.error(I18n.t('Notify.Error'), err.error)
    })
  }

  editShipment = (data) => {
      const { onSearch, onChangeSearch } = this.props;
      const screen = SHIPPING_SCREEN.EDIT_SHIPIN
      const clonePage = {...this.props.appState.page};
      const pageData = _.assign(_.cloneDeep(clonePage), 
        {isEdit: true}, 
        {
          screen, 
          data,
          onSearch,
          onChangeSearch
        }
      );
      this.props.appPropsActions.setPageCurrent(pageData);
  }

  showDeleteConfirm = item => {
    let {openModal, closeModal} = this.props.modal;
    let modal = (<ModalConfirm
      title={I18n.t('PageShiping.DeleteShipment')}
      confirmMessage={I18n.t('PageShiping.DeleteConfirm')}
      messageOk={I18n.t('Btn.YES')}
      messageCancel={I18n.t('Btn.CANCEL')}
      handleClose={closeModal}
      handleOk={() => this.deleteShipment(item._id)}/>);
    openModal(modal);
  }

  deleteShipment = id => {
    this.props.dispatch({type: types.APP.SHOW_LOADING})
    // const options = {}
    shippingApi.deleteShipment(id).then(rs => {
      this.props.dispatch({type: types.APP.HIDE_LOADING})
        if(rs.success) {
          toastr.success(I18n.t('Notify.Success'), 'Delete Success')
          this.props.dispatch({
            type: types.APP.SET_PAGE_CURRENT,
            data: {
              isEdit: false,
              tab: '',
              tabChild: 'tabShipIn'
            }
          })
          this.getList(this.getBaseOption());
        } else {
          toastr.error(I18n.t('Notify.Error'), rs.error)
        }
      },
      err => {
        this.props.dispatch({type: types.APP.HIDE_LOADING})
        toastr.error(I18n.t('Notify.Error'), err.error)
      })
  }

  getListOrderById = (purchaseOrder) => {
    // this.props.dispatch({type: types.APP.SHOW_LOADING})
  }

  onExport() {
    const { search } = this.state;
    // TODO build filter params
    let filters = undefined;
    switch (search.key) {
      case SHIPPING_SEARCH_KEY.DECLARATION_NO: {
        if (search.value) filters = `?filter=decNo&value=${search.value}`;
        break;
      }
      case SHIPPING_SEARCH_KEY.BILL_NO: {
        if (search.value) filters = `?filter=billNo&value=${search.value}`;
        break;
      }
      case SHIPPING_SEARCH_KEY.SHIP_MODE: {
        if (search.value) filters = `?filter=shipMode&value=${search.value}`;
        break;
      }
      case SHIPPING_SEARCH_KEY.DECLARATION_DATE: {
        if (moment(search.beginDate).isValid() && moment(search.endDate).isValid()) {
          const dateValue = `${moment(search.beginDate).format('YYYY-MM-DD')},${moment(search.endDate).format('YYYY-MM-DD')}`;
          filters = `?filter=decDate&value=${dateValue}`;
        }
        break;
      }
      case SHIPPING_SEARCH_KEY.ETA: {
        if (moment(search.beginDate).isValid() && moment(search.endDate).isValid()) {
          const dateValue = `${moment(search.beginDate).format('YYYY-MM-DD')},${moment(search.endDate).format('YYYY-MM-DD')}`;
          filters = `?filter=eta&value=${dateValue}`;
        }
        break;
      }
      case SHIPPING_SEARCH_KEY.ETD: {
        if (moment(search.beginDate).isValid() && moment(search.endDate).isValid()) {
          const dateValue = `${moment(search.beginDate).format('YYYY-MM-DD')},${moment(search.endDate).format('YYYY-MM-DD')}`;
          filters = `?filter=etd&value=${dateValue}`;
        }
        break;
      }
      case SHIPPING_SEARCH_KEY.ETA_FACTORY: {
          let dateSearch = {
              beginDate : this.state.search.value,
              endDate: this.state.search.value
          }
          if (moment(dateSearch.beginDate).isValid() && moment(dateSearch.endDate).isValid()) {
              const dateValue = `${moment(dateSearch.beginDate).format('YYYY-MM-DD')},${moment(dateSearch.endDate).format('YYYY-MM-DD')}`;
              filters = `?filter=etaFactory&value=${dateValue}`;
          }
          break;
      }
      case SHIPPING_SEARCH_KEY.ADVANCE: {
          if (search.value) filters = `?filter=advance&value=${Number(search.value)}`;
          break;
      }
      default:
    }
    let url = this.props.getExportShipin(filters);
    FileSaver.saveAs(url, "Shipin.xlsx");
  }

  render() {
    let { items, total, columns, pageNumber , pageSize , actions } = this.state;
    let { roles: {showCreate, showEdit, showDelete, showView}} = this.props;
    const fields = SEARCH_LIST.SHIPIN_SEARCH_LIST
    let totaPage = _.ceil(total / pageSize);
    let tabPanelClass = this.props.active ? "tab-pane active" : "tab-pane";
    actions = []
    if(showView || showEdit){
      actions.push(() => {
        return { type: 'edit', click: (data) => this.editShipment(data) };
      })
    };
    if(showDelete){
      actions.push(() => {
        return { type: 'delete', click: (item) => this.showDeleteConfirm(item) };
      })
    };
    return (
        <div className={tabPanelClass} id="shipping-ship-in">
            {/* Ship-in list */}
            <div className="wrap-search-position">
              <SearchBar
                  name="Shipping"
                  fields={fields}
                  onChangeSearch={this.onChangeSearch}
                  onSearch={this.onSearch}
                  style={{paddingRight: '100px'}}
              />
              <span style={{display: 'inline-block', width: 80, position: 'absolute', right: 0, top: 15, fontSize: 12}} className="export cursor-pointer" onClick={() => this.onExport()}>Export <img src={icExportIcon} alt="" style={{paddingLeft: 10, position: 'relative', top: 4}}/></span>
            </div>
            <TableList
                {...this.props} 
                items={items}
                columns={columns || []} 
                actions={actions || []}
                onAction = {this.onAction}
                options={{clsTbl: 'global-table box-shadow-none'}} 
                widthAction="w-70"
            />
            <CustomPagination items={totaPage} activePage={pageNumber} onSelect={this.selectPage} />
        </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    appState: state.appState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    appPropsActions: bindActionCreators(appActions, dispatch),
    shippingActions: bindActionCreators(shippingActions, dispatch),
    getExportShipin(filter) {
      return getExportShipin(filter);
    },
    dispatch
  };
}

TabShipIn.propTypes = {
};

export default connect(mapStateToProps, mapDispatchToProps)(TabShipIn);

