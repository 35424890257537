
const C = 'CUSTOMER_PO.CURRENT.SHOW_ORDER_LIST';

export default {
  FETCH_ORDER_LIST: `${C}.FETCH_ORDER_LIST`,
  CANCEL_FETCH_ORDER_LIST: `${C}.CANCEL_FETCH_ORDER_LIST`,
  CHANGE_ORDER_LIST: `${C}.CHANGE_ORDER_LIST`,

  CHANGE_FIRST_ORDER_LIST: `${C}.CHANGE_FIRST_ORDER_LIST`,

  LOAD_MORE_ORDER_LIST: `${C}.LOAD_MORE_ORDER_LIST`,
  CANCEL_LOAD_MORE_ORDER_LIST: `${C}.CANCEL_LOAD_MORE_ORDER_LIST`,

  CANCEL_LOAD_MORE: `${C}.CANCEL_LOAD_MORE`,
  LOAD_MORE: `${C}.LOAD_MORE`,

  CHANGE_PAGE_NUMBER: `${C}.CHANGE_PAGE_NUMBER`,
  RESET_CHANGE_PAGE_NUMBER: `${C}.RESET_CHANGE_PAGE_NUMBER`,

  CHANGE_SELECTED_ORDER: `${C}.CHANGE_SELECTED_ORDER`,
  CHANGE_FILTER_TEXT: `${C}.CHANGE_FILTER_TEXT`,

  CANCEL_IS_SEARCH: `${C}.CANCEL_IS_SEARCH`,
  IS_SEARCH: `${C}.IS_SEARCH`,

  LOADING: `${C}.LOADING`,
  CANCEL_LOADING: `${C}.CANCEL_LOADING`,

  UPDATE_ORDER_LIST: `${C}.UPDATE_ORDER_LIST`,
  REMOVE_ORDER_LIST: `${C}.REMOVE_ORDER_LIST`,
};
