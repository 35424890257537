import C from '../../constants/ship-out/createINV';
import { combineReducers } from 'redux';
import moment from 'moment';

const pklShipDetail = (state = null, action) => {
  const { type, payload } = action;
  switch (type) {
    case C.CHANGE_PKL_SHIP_DETAIL:
      return payload[0];
    case C.RESET_DATA:
      return null;
    default:
      return state;
  }
};

const dataSet = (state = null, action) => {
  const { type, payload } = action;
  let dic = {};
  let orderId, styleId, cutTicketId, colorId, value;
  switch (type) {
    case C.CHANGE_DATASET_DETAIL_OF_CREATE:
      dic = {
        customerInv: '',
        invDate: moment(),
        items: payload[0].pklProds.map(item => {
          return {
            _id: item._id,
            commodity: item.commodity,
            hsCode: '',
            catNo: '',
            customer: item.customer._id,
            customerName: item.customer.name,
            customerPO: item.order.customerPO,
            styles: item.styles.map(style => {
              return {
                style: style.style._id,
                styleName: style.style.style,
                cutTickets: style.cutTickets.map(cut => {
                  return {
                    cutTicket: cut.cutTicket._id,
                    cutTicketName: cut.cutTicket.cutTicket,
                    store: cut.cutTicket.store ? cut.cutTicket.store.name || '' : '',
                    colors: cut.colors.map(color => {
                      return {
                        color: color.color._id,
                        colorName: color.color.name,
                        quantity:
                          _.sum(
                            color.packings.map(packing =>
                              _.sum(packing.items.map(item => item.actualQuantity || item.planQuantity || 0))
                            )
                          ) +
                          _.sum(
                            style.packings.map(packing =>
                              _.sum(
                                packing.items
                                  .filter(item => item.color == color.color._id && item.cutTicket == cut.cutTicket._id)
                                  .map(item => item.actualQuantity || item.planQuantity || 0)
                              )
                            )
                          ),
                        price: 0,
                      };
                    }),
                  };
                }),
              };
            }),
          };
        }),
      };
      return dic;
    case C.CHANGE_CUSTOMER_INV:
      dic = _.cloneDeep(state);
      dic.customerInv = payload;
      return dic;
    case C.CHANGE_INV_DATE:
      dic = _.cloneDeep(state);
      dic.invDate = payload;
      return dic;
    case C.CHANGE_PRICE:
      dic = _.cloneDeep(state);
      orderId = payload.orderId;
      styleId = payload.styleId;
      cutTicketId = payload.cutTicketId;
      colorId = payload.colorId;
      value = payload.value;

      _.map(
        dic.items,
        item =>
          item._id === orderId &&
          _.map(
            item.styles,
            style =>
              style.style === styleId &&
              _.map(
                style.cutTickets,
                cut =>
                  cut.cutTicket && _.map(cut.colors, color => (color.color === colorId ? (color.price = value) : null))
              )
          )
      );

      return dic;
    case C.CHANGE_HS_CODE:
      dic = _.cloneDeep(state);
      orderId = payload.orderId;
      value = payload.value;
      _.map(dic.items, item => {
        if (item._id == orderId) {
          item.hsCode = value;
        }
      });
      return dic;
    case C.CHANGE_CAT:
      dic = _.cloneDeep(state);
      orderId = payload.orderId;
      value = payload.value;
      _.map(dic.items, item => {
        if (item._id == orderId) {
          item.catNo = value;
        }
      });
      return dic;
    case C.CHANGE_DATASET_ALL_VALUE:
      return payload;
    case C.CHANGE_DATASET_DETAIL:
      dic = _.cloneDeep(payload[0].invoice);
      let pklProds = payload[0].pklProds;
      dic.items.map((item, index) =>
        item.styles.map((style, sId) => {
          let pStyle = pklProds[index].styles.find(s => s.style._id == style.style._id);
          style.styleName = pStyle.style.style;
          pStyle &&
            style.cutTickets.map((cut, cId) => {
              let pCut = pStyle.cutTickets.find(cutTicket => cutTicket.cutTicket._id == cut.cutTicket._id);
              cut.cutTicketName = (pCut.cutTicket || {}).cutTicket;
              cut.store = ((pCut.cutTicket || {}).store || {}).name || '';
              cut &&
                cut.colors.map((color, clId) => {
                  let pColor = pCut.colors.find(cl => cl.color._id == color.color._id);
                  color.colorName = pColor.color.name;
                });
            });
        })
      );
      return dic;
    case C.RESET_DATA:
      return null;
    default:
      return state;
  }
};

const success = (state = null, action) => {
  const { type, payload } = action;
  switch (type) {
    case C.RESET_DATA:
      return false;
    case C.CREATE_SUCCES:
      return payload;
    default:
      return state;
  }
};
// const updatesuccess = (state = null, action) => {
//     const { type, payload } = action;
//     switch (type) {
//         case C.RESET_DATA:
//             return false;
//         case C.CREATE_SUCCES:
//             return payload;
//         default:
//             return state;
//     }
// }

const activeScreenEditStatus = (state = false, action) => {
  const { type, payload } = action;
  switch (type) {
    case C.ACTIVE_SCREEN_EDIT_INV:
      return payload;
    default:
      return state;
  }
};
const activeScreenCreateStatus = (state = false, action) => {
  const { type, payload } = action;
  switch (type) {
    case C.ACTIVE_SCREEN_CREATE_INV:
      return payload;
    default:
      return state;
  }
};

export const createINV = combineReducers({
  success,
  pklShipDetail,
  dataSet,
  activeScreenEditStatus,
  activeScreenCreateStatus,
});
