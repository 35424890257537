import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import appActions from '../../actions/appActions';

import BaseComponent from '../base/BaseComponent';
import TabGarment from './warehouse/TabGarment';
import TabStock from './warehouse/TabStock';
import _ from 'lodash';
import I18n from 'i18n-js';
import TabMaterials from "./warehouse/TabMaterials";
import {SEARCH_KEY, WAREHOUSE_TAB} from "../../constants/constants";

class ScreenWarehouse extends BaseComponent {
  constructor(props, context) {
    super(props, context);

    this.state = {
      search: {key: SEARCH_KEY.MATERIAL, value: ""},
      tabActive: WAREHOUSE_TAB.STOCK_MANAGEMENT.STOCK,
    };
  }

  componentWillMount() {
    this.props.appPropsActions.setDataPageCurrent({isEdit: false});
  }

  componentWillReceiveProps(nextProps) {

  }

  onChangeSearch() {

  }

  setSearchValue(search) {
    let { searchValue, tabActive } = this.state;
    this.setState({ searchValue: _.set(searchValue, tabActive, search) });
  }

  setTabActive(tab) {
    this.setState({ tabActive: tab, searchValue: {} });
  }


  render() {
    const { tabCurrent } = this.props;
    let {searchValue } = this.state;
    return (
      <div className='show'>
        <div className="global-table-tab">
          <ul className="nav nav-tabs wrap-tab" role="tablist">
            <li role="presentation" className="active" onClick={() => this.setTabActive('stock')}><a href="#stock" aria-controls="stock" role="tab" data-toggle="tab">{I18n.t('PageWareHouse.Stock')}</a><span className="line" /></li>
            <li role="presentation" onClick={() => this.setTabActive('damaged_garments')}><a href="#damaged_garments" aria-controls="damaged_garments" role="tab" data-toggle="tab">{I18n.t('PageWareHouse.DamagedGarments')}</a><span className="line" /></li>
            <li role="presentation" onClick={() => this.setTabActive('damaged_materials')}><a href="#damaged_materials" aria-controls="damaged_materials" role="tab" data-toggle="tab">{I18n.t('PageWareHouse.DamagedMaterials')}</a></li>
          </ul>
          <div className="tab-content">
            <TabStock
              {...this.props}
              tabCurrent={tabCurrent}
              searchValue={_.get(searchValue, WAREHOUSE_TAB.STOCK_MANAGEMENT.STOCK) || {}}
            />
            <TabGarment
              {...this.props}
              searchValue={_.get(searchValue, WAREHOUSE_TAB.STOCK_MANAGEMENT.DAMAGED_GARMENTS) || {}}
            />
            <TabMaterials
              {...this.props}
              searchValue={_.get(searchValue, WAREHOUSE_TAB.STOCK_MANAGEMENT.DAMAGED_MATERIALS) || {}}
            />
          </div>
        </div>

      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    authState: state.authState
  };
}

function mapDispatchToProps(dispatch) {
  return {
    appPropsActions: bindActionCreators(appActions, dispatch)
  };
}

ScreenWarehouse.propTypes = {
  authState: PropTypes.object.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(ScreenWarehouse);
