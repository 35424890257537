import {createSelector} from 'reselect';
import _ from 'lodash';
import formatCurrency from 'format-currency';
export const getInvoiceDetail = ({historyPreviewContractor}) => historyPreviewContractor.invoiceDetail || {};

export const getContractor = createSelector (
    [
        getInvoiceDetail
    ], data => {
        return data.invoiceDetail && data.invoiceDetail.contractor.name;
    }
)

export const getCurrency = createSelector (
    [
        getInvoiceDetail
    ], data => {
        return data.invoiceDetail && data.invoiceDetail.contractor.currency;
    }
)

export const getCurrencyTypeUSD = createSelector(
    [
        getCurrency
    ], (currency) => {
        return currency && currency === "USD" ? '$' : '';
    }
)

export const getCurrencyTypeVND = createSelector(
    [
        getCurrency
    ], (currency) => {
        return currency && currency === "VND" ? ' VND' : '';
    }
)

export const getListCustomerPOsDetail = createSelector (
    [
        getInvoiceDetail,
        getCurrencyTypeUSD,
        getCurrencyTypeVND
    ], (data, currencyTypeUSD, currencyTypeVND) => {
        if(data.invoiceDetail) {
            let results = [];
            data.invoiceDetail.customerPOs.map(item => 
                item.styles.map(s => 
                    s.cutTickets.map(c => 
                        c.colors.map(cl => 
                            {
                                results.push({...cl, 
                                    cutTicket: c.name, 
                                    style: s.name, 
                                    customerPO: item.no, 
                                    amount: currencyTypeUSD + formatCurrency((cl.price || 0) * (cl.qtyRec || 0)) + currencyTypeVND,
                                    amountNumber: ((cl.price || 0) * (cl.qtyRec || 0))
                                })
                            }
            ))))
            return results;
        }
        return [];
    }
)

export const getTotalQTY = createSelector (
    [
        getListCustomerPOsDetail
    ], data => {
        return _.sum(data.map(item => item.qtyRec))
    }
)

export const getInvoiceNo = createSelector(
    [
      getInvoiceDetail
    ], (data) => {
      return data.invNo
    }
  )
  
  export const getIssuedDate = createSelector(
    [
      getInvoiceDetail
    ], (data) => {
      return data.issuedDate //moment(data.issuedDate).format("MMM DD, YYYY");
    }
  )

  export const getMainVATAmount = createSelector (
    [
      getInvoiceDetail
    ], data => {
      if(data.invoiceDetail) {
        const totalAmount = (data.invoiceDetail.vat || {}).totalAmount || 0;
        const taxRate = (data.invoiceDetail.vat || {}).taxRate || 0;
        return (totalAmount * (taxRate / 100))
      } 
    }
  )

  export const getSumVatAmountOfCustomerPO = createSelector (
    [
      getInvoiceDetail
    ], data => {
      if(data.invoiceDetail) {
        return _.sum(data.invoiceDetail.customerPOs.map(s => s.vat ? ((s.vat.totalAmount || 0) * ((s.vat.taxRate || 0) / 100)) : 0))
      } 
    }
  )

  export const getVatAmount = createSelector (
      [
          getMainVATAmount,
          getSumVatAmountOfCustomerPO,
      ], (mainVatAmount, sumVatAmountOfCustomerPO) => {
          return _.round((mainVatAmount || 0) +(sumVatAmountOfCustomerPO || 0), 2)
      }
  )
  
  export const getAmount = createSelector (
      [
          getListCustomerPOsDetail
      ], data => {
        return _.round(_.sum(data.map(item => item.amountNumber)), 2)
      }
  )

  export const getTotalAmount = createSelector (
      [
          getAmount,
          getVatAmount,
      ], (amount, vatAmount) => {
          return _.round((amount || 0) + (vatAmount || 0), 2)
      }
  )
// export const genDataForPreview = createSelector (
//     [
//         getInvoiceDetail
//     ], data => {
//         // let temp = []
//         // _.cloneDeep((data.invoiceDetail || {}).declarations || []).map(item => {
//         //     temp.push(_.assign(item, {
//         //     contNo: item.name,
//         //   }))
//         // })
//         return ((data.invoiceDetail || {}).declarations || []);
//     }
// )

// export const getName = createSelector (
//     [getInvoiceDetail], data => {
//         if(data.invoiceDetail) {
//             return data.invoiceDetail.name;
//         }
//         return '';
//     }
// )

// export const getTotalAmount = createSelector (
//     [genDataForPreview], data => {
//         return _.sum(data.map(item => (item.crsPaperWork || 0) + (item.truckingIn || 0) + (item.localCharge || 0) - (item.advance || 0)))
//     }
// )

// export const getTotalAdvance = createSelector (
//     [genDataForPreview], data => {
//         return _.sum(data.map(item => (item.advance || 0)))
//     }
// )

export const getActiveScreenStatus = ({historyPreviewContractor}) => historyPreviewContractor.activeScreen;