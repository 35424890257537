import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import BaseComponent from "../../base/BaseComponent";
import SidebarOrders from "../../common/layout/SidebarOrders";
import PlansTable from "./PlansTable";
import orderActions from "../../../actions/orderActions";
import appActions from "../../../actions/appActions";
import styleActions from "../../../actions/styleActions";
import BarOrderInfo from "../../common/layout/BarOrderInfo";
import _ from "lodash";
import * as types from "../../../constants/actionTypes";
import {PURCHASE_SCREEN, PURCHASE_TAB} from "../../../constants/constants";
import {getUnCompletedOrders} from "../../../functions/utils";

class ScreenPlans extends BaseComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {
      search: "",
      selectedOrder: props.additionalOrder || {},
      isAdditionalOrder: !_.isEmpty(props.additionalOrder || {}),
      selectedStyle: {},
      completedOrders: [],
      selectedStylePurchasePlan: null
    };
  }

  componentWillMount() {
    const {isAdditionalOrder} = this.state;
    if(!isAdditionalOrder) {
      const {orderState, selectedOrder, selectedStyle} = this.props;
      const options = {populate: 'styleOrders', closePurchase: false, isMaterial: 'yes'};
      this.props.orderActions.getListOrder(options);
      const orderItems = getUnCompletedOrders(_.get(orderState, "items", []));
      this.setState({
        completedOrders: orderItems,
        selectedOrder: selectedOrder || orderItems[0],
        selectedStyle: selectedStyle || {}
      });
    }
    
  }

  componentWillReceiveProps(nextProps) {

    if (!_.isEqual(_.get(this.props.orderState, "items", []), _.get(nextProps.orderState, "items", []))) {
      const orderItems = getUnCompletedOrders(_.get(nextProps.orderState, "items", []));
      this.setState({completedOrders: orderItems, selectedOrder: orderItems[0]});
    }
  }

  // checkEmptyStyleOfOrder = (orders) => {
  //   const results = orders.filter(item => item.styleOrders)
  // }

  handleSelectOrder(item) {
    this.setState({selectedOrder: item || {}});
    this.props.setPlansData && this.props.setPlansData({item, selectedStyle: item.styleOrders[0]._id });
  }

  selectStyle(style) {
    this.setState({selectedStyle: style || {}});
  }

  previewOrder = purchasePlan => {
    const {selectedOrder, selectedStyle, isAdditionalOrder, selectedStylePurchasePlan} = this.state;
    const previewOrderData = {
      orderDetail: selectedOrder,
      purchasePlan: purchasePlan,
      style: selectedStyle,
      activeTab: this.props.activeTab,
      isAdditionalOrder
    };
    this.props.previewOrder(previewOrderData);
    //const pageData = _.assign(_.cloneDeep(this.props.appState.pageList), {activeScreen: PURCHASE_SCREEN.PREVIEW_ORDER, activeData: previewOrderData});
    let pageList = [...this.props.appState.pageList];
    pageList.push({screen: PURCHASE_SCREEN.PREVIEW_ORDER, data: previewOrderData});
    this.props.appPropsActions.addPage(pageList);
    this.props.setPlansData && this.props.setPlansData({selectedOrder, selectedStyle, selectedStylePurchasePlan})
  }

  setStylePurchasePlan(selectedStylePurchasePlan) {
    const {selectedOrder, selectedStyle} = this.state;
    this.setState({selectedStylePurchasePlan});
    this.props.setPlansData && this.props.setPlansData({selectedOrder, selectedStyle, selectedStylePurchasePlan})
  }




  render() {
    let {orders, selectedOrder, search, isAdditionalOrder, completedOrders, selectedStyle} = this.state;
    let order = _.cloneDeep(selectedOrder) || {};
    console.log(this.state)
    return (
      <div id="content" className={isAdditionalOrder ? 'noSidebarOrders' : ''}>
        {
          !isAdditionalOrder && (
            <SidebarOrders
              selectedOrder={selectedOrder || {}}
              orders={completedOrders || []}
              modal={this.props.modal}
              handleSelectedOrder={(order) => this.handleSelectOrder(order)}
            />
          )
        }


        <div className="content-main">
          <BarOrderInfo order={order}/>
          <PlansTable
            {...this.props}
            order={order || {}}
            isAdditionalOrder={isAdditionalOrder}
            selectedStyle={selectedStyle}
            selectStyle={style => this.selectStyle(style)}
            previewOrder={purchasePlan => this.previewOrder(purchasePlan)}
            setStylePurchasePlan={(data) => this.setStylePurchasePlan(data)}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    appState: state.appState,
    authState: state.authState,
    orderState: state.orderState
  };
}

function mapDispatchToProps(dispatch) {
  return {
    orderActions: bindActionCreators(orderActions, dispatch),
    appPropsActions: bindActionCreators(appActions, dispatch),
    stylePropsActions: bindActionCreators(styleActions, dispatch),
  };
}

ScreenPlans.propTypes = {
  authState: PropTypes.object.isRequired,
  appState: PropTypes.object.isRequired,
  activeTab: PropTypes.string,
  appPropsActions: PropTypes.object,
  setPlansData: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(ScreenPlans);
