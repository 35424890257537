
import _ from 'lodash';
import * as R from 'ramda';

export const isShowing = ({ editStyle }) => !!editStyle.rawStyle;

export const getId = ({ editStyle }) => R.path(['_id'], editStyle.rawStyle);

export const isSubmiting = ({ editStyle }) => editStyle.submiting;

export const getImages = ({ editStyle }) => {
  const { images } = editStyle;
  return _.map(images, img => ({
    id: R.path(['id'], img),
    url: R.path(['url'], img),
    raw: img,
  }));
}

export const getStyle = ({ editStyle }) => editStyle.style;

export const getPage = ({ editStyle }) => editStyle.page;

export const getErrors = ({ editStyle }) => editStyle.errors;

export const canSave = ({ editStyle }) => {
  const { errors, style, submiting } = editStyle;
  if (submiting) return false;

  return !!_.trimEnd(style) && !R.path(['style'], errors);
    // && !!_.trimEnd(page) 
    // && !R.path(['page'], errors);
};

export const fnGenerateBody = ({ editStyle }) => () => {
  const { style, page, images } = editStyle;
  return {
    style,
    page,
    images: _.map(images, img => (img.file ? { url: img.url, file: img.file } : img.url )),
  };
};
