import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import I18n from 'i18n-js';
import authActions from '../../actions/authActions';
import { isStringEmpty, validateEmail } from '../../functions/helpers';
import { toastr } from 'react-redux-toastr';
import _ from 'lodash';

import logoIcon from '../../stylesheets/images/logo.svg';
import loadingIcon from '../../stylesheets/images/loading.svg';

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: '',
      data: { email: '' },
      errors: {}
    };
    this.onChange = this.onChange.bind(this);
    this.redirectLoginPage = this.redirectLoginPage.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  componentWillReceiveProps(newProps) {
    if (newProps.authState.forgotError) {
      this.setState({ errors: newProps.authState.forgotError });
    }
    if (newProps.authState.auth) {
      this.props.router.history.push('/');
    }
  }

  onChange(name, value) {
    let { data } = this.state;
    data[name] = value;
    this.setState({ data });
  }

  validate() {
    let { data, errors } = this.state;
    errors = {};
    if (isStringEmpty(data.email)) {
      errors.email = I18n.t('ErrorMessages.UserEmailIsRequired');
      this.setState({ errors })
      return false;
    }
    if (!validateEmail(data.email)) {
      errors.email = I18n.t('ErrorMessages.UserEmailWrongFormat');
      this.setState({ errors })
      return false;
    }
    this.setState({ errors });
    return true;
  }

  handleKeyPress(event) {
    if(event.key == 'Enter') {
      this.onForgot(event);
    }
  }

  onForgot(event) {
    event.preventDefault();
    if (this.validate()) {
      this.props.authPropsActions.forgotPassword(this.state.data.email).then(res => {
        if (res.error) {
          let err = _.get(res, 'error.error', '')
          if (err === 'Not found') {
            let { errors } = this.state;
            errors.email = I18n.t('ErrorMessages.EmailNotFound');
            this.setState({ errors });
          } else {
            if(err === 'User not found') {
              toastr.error('Error', I18n.t('ResetPassword.UserDoesNotExist'))
            } else {
              toastr.error('Error', I18n.t('ResetPassword.ExpiredToken'))
            }
            // toastr.error('Error', err)
          }
        } else {
          toastr.success('Success', I18n.t('ForgotPassword.NotifySuccess'))
        }
      })
    }
  }

  redirectLoginPage() {
    this.props.history.push('/login');
  }

  render() {
    const { authState } = this.props;
    let { data, errors } = this.state;
    let isSubmiting = _.get(authState, 'loading', false);
    return (
      <div id="login" className={`card card-container ${isSubmiting ? 'de-active' : ''}`}>
        {isSubmiting && (
          <div className="loadingPage">
            <img src={loadingIcon} />
          </div>
        )}
        <img id="profile-img" className="profile-img-card" src={logoIcon} />
        <form className="form-signin" onKeyPress={event => {
          if (event.key === 'Enter') {
            this.onLogin();
          }
        }} >
          <div className="form-group">
            <h3 className="text-center">{I18n.t('ForgotPassword.Description')}</h3>
            <input type="email" className="form-control" onKeyPress={this.handleKeyPress} value={data.email} onChange={evt => this.onChange('email', evt.target.value)} placeholder="" />
            {errors['email'] && <span className="alert-warning">{errors['email']}</span>}
          </div>
          <div className="wrap-button">
            <button type="button" className="bt-black mg-right-5" onClick={this.redirectLoginPage} >{I18n.t('Btn.CANCEL')}</button>
            <button type="button" className="bt-orange" onClick={(evt) => this.onForgot(evt)} >{I18n.t('Btn.SEND')}</button>
          </div>
        </form>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    authState: state.authState
  };
}

function mapDispatchToProps(dispatch) {
  return {
    authPropsActions: bindActionCreators(authActions, dispatch)
  };
}

ForgotPassword.contextTypes = {
  router: PropTypes.object.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
