import Base from './base';
import orderServices from "../services/orderServices";
import styleServices from '../services/styleServices';
import supplierServices from '../services/supplierServices';
class Purchase extends Base {
  constructor () {
    super();
    this.getListPurchaseDAL = this.getListPurchaseDAL.bind(this);
    this.addPurchaseDAL = this.addPurchaseDAL.bind(this);
    this.editPurchaseDAL = this.editPurchaseDAL.bind(this);
    this.deletePurchaseDAL = this.deletePurchaseDAL.bind(this);
    this.getListFollowUpDAL = this.getListFollowUpDAL.bind(this);
    this.search = this.search.bind(this);
    this.restorePurchaseDAL = this.restorePurchaseDAL.bind(this);
  }

  getListPurchaseDAL (query) {
    return super.execute(this.Urls.purchase.list, this.ApiMethod.get, query);
  }

  getListFollowUpDAL (query) {
    return super.execute(this.Urls.purchase.followUp, this.ApiMethod.get, query);
  }

  addPurchaseDAL (body) {
    return super.execute(this.Urls.purchase.create + `${body.supplierId}/purchase-order`, this.ApiMethod.post, body);
  }

  editPurchaseDAL (id, body) {
    return super.execute(this.Urls.purchase.update + '/' + id, this.ApiMethod.put, body);
  }

  restorePurchaseDAL (id) {
     return super.execute(this.Urls.purchase.restore + '/' + id, this.ApiMethod.put, null);
  }

  getPurchaseDAL (id, options) {
    return super.execute(this.Urls.purchase.detail + '/' + id, this.ApiMethod.get, options);
  }

  deletePurchaseDAL (id) {
    return super.execute(this.Urls.purchase.delete + '/' + id, this.ApiMethod.delete, null);
  }

  searchInto(options, func,  name, field, bool = true) {
    return new Promise(resolve => {
      if(bool) {
        options.query = JSON.stringify(options.query || {})
        return resolve(options)
      } 
      func.then(rs => {
        const qr = {[name]: _.get(rs, 'data.items', []).reduce((ls, vl) => _.isArray(vl[field]) ? [...ls, ...vl[field]] : [...ls, vl[field]], [])}
        options.query = JSON.stringify({...options.query, ...qr})
        return resolve(options)
      })
    })
  }


  search (search, options, searchKey) {
    // let func,  name, field, bool = true
    // if(search.value != '' || (search.beginDate != '' && search.endDate != '') ) {
    //     func = orderServices.getListOrder({query: JSON.stringify(options.query )})
    //     name = 'styleId'
    //     field = 'styleOrders'
    //     bool = false

      // switch(search.key) {
      //   case searchKey.CUSTOMER_PO: {
      //     func = orderServices.getListOrder({projection: 'styleOrders', query: JSON.stringify({customerPO: {$regex: '.*' + search.value + '.*', $options: 'i'}})})
      //     name = 'styleId'
      //     field = 'styleOrders'
      //     bool = false
      //     break;
      //   }
      //   case searchKey.STYLE: {
      //     func = styleServices.getAllStyles({projection: '_id', query: JSON.stringify({style: {$regex: '.*' + search.value + '.*', $options: 'i'}})})
      //     name = 'styleId'
      //     field = '_id'
      //     bool = false
      //     break;
      //   }
      //   case searchKey.SUPPLIER: {
      //     func = supplierServices.getListSupplier({projection: '_id', query: ({name: {$regex: '.*' + search.value + '.*', $options: 'i'}})})
      //     name = 'supplierId'
      //     field = '_id'
      //     bool = false
      //     break;
      //   }
      //   case searchKey.ORDER_DATE: {
      //     let db = new Date(search.beginDate)
      //     db.setHours(0,0,0,0)
      //     //db.setDate(db.getDate() - 1);
      //     let de = new Date(search.endDate)
      //     de.setHours(23,59,59,999)
      //     func = orderServices.getListOrder({projection: 'styleOrders', query: JSON.stringify({orderDate: {$gte: db, $lt: de}})})
      //     name = 'styleId'
      //     field = 'styleOrders'
      //     bool = false
      //     break;
      //   }
      // }
    // }

      let params = Object.assign({}, options);
      if(search.key == 'orderDate') {
          let db = new Date(search.beginDate)
          db.setHours(0,0,0,0)
          let de = new Date(search.endDate)
          de.setHours(23,59,59,999)
          if (db) {
            params.beginDate = db
          }
          if (de) {
            params.endDate = de
          }
      } else if (search.key) {
          params[search.key] = search.value;
      }
      // console.log('----setlogic--', params, search);
      return super.execute(this.Urls.purchase.listShipping, this.ApiMethod.get, params);
  }
}

export default new Purchase();
