import {createSelector} from 'reselect';
import _ from 'lodash';

export const getInvoiceDetail = ({historyPreviewDeclaration}) => historyPreviewDeclaration.invoiceDetail || {};

export const genDataForPreview = createSelector (
    [
        getInvoiceDetail
    ], data => {
        // let temp = []
        // _.cloneDeep((data.invoiceDetail || {}).declarations || []).map(item => {
        //     temp.push(_.assign(item, {
        //     contNo: item.name,
        //   }))
        // })
        return ((data.invoiceDetail || {}).declarations || []);
    }
)

export const getName = createSelector (
    [getInvoiceDetail], data => {
        if(data.invoiceDetail) {
            return data.invoiceDetail.name;
        }
        return '';
    }
)

export const getTotalAmount = createSelector (
    [genDataForPreview], data => {
        return _.sum(data.map(item => (item.crsPaperWork || 0) + (item.truckingIn || 0) + (item.localCharge || 0) - (item.advance || 0)))
    }
)

export const getTotalAdvance = createSelector (
    [genDataForPreview], data => {
        return _.sum(data.map(item => (item.advance || 0)))
    }
)

export const getActiveScreenStatus = ({historyPreviewDeclaration}) => historyPreviewDeclaration.activeScreen;