import React    from 'react';
import _        from 'lodash';
import I18n from 'i18n-js';
import SearchSelect from '../../../ui/components/forms/SearchSelect';

export default ({data, idx, est, colorsGlobal, onChange, thName, fieldName, selectedColors, errors}) => {

    const dataSelect = colorsGlobal.map((cg, idx3) => ({key: idx3, value: cg, label: cg}));
    const getRate = item => {
        let _cost = parseFloat(_.get(item, 'info.yield', 0)) || 0;
        let _percent = parseFloat(_.get(item, 'info.percent', 0)) || 0;

        return _cost * (1 + _percent / 100);
    }
    
    const getColor = (estimates, est) => {
        let estimate = estimates.find(e => e.color === est.color._id);
        est.selectColor = _.get(estimate, fieldName, '')
        return est.selectColor;
    }
    const error = _.isArray(errors) ? _.nth(errors, idx) : errors;

    return (
        <div className="content-right">
            <div className="md content-main-title-edit-yield-color">
                <table style={{width: '100%'}}>
                    <thead>
                        <tr className="border-dashed">
                            <th className="title-first">{I18n.t('PageYield.GarmentColor')}</th>
                            <th className="title-size">{I18n.t('PageYield.Size')}</th>
                            <th className="select">{thName}<span className="color-red" style={{display: 'inline'}}>*</span></th>
                            <th className="quantity">{I18n.t('PageYield.Quantity')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {(est || []).map((c, idx2) => {
                            let quantity = _.get(c, 'color.sizes', []).reduce((s, item) => s + item.quantity || 0, 0);
                            return (
                                <tr key={idx2}>
                                    <td className="title-first medium">{_.get(c, 'color.name','')}</td>
                                    <td className="title-size">
                                    { _.get(c, 'color.sizes', []).map((s, idx3) => (
                                        <div key={idx3} className="size-number">
                                            <span className="size">{s.name}</span>
                                            <span className="number"> : {s.quantity}</span>
                                        </div>
                                    ))}
                                    </td>
                                    <td className="select">
                                        <SearchSelect
                                            className="fabric-combobox m-w-75 w-100-percent"
                                            options={dataSelect}
                                            value={ getColor(data.estimate||[], c) }
                                            onChange={evt => onChange(idx, idx2, est , evt ? evt.value : '')}
                                        />
                                        {error && _.get(error, `color.${idx2}`, undefined) &&
                                            <div className="text-danger">
                                                {I18n.t(_.get(error, `color.${idx2}`, ''))}
                                            </div>
                                        }
                                    </td>
                                    <td className="quantity">{_.round((quantity * getRate(data)), 3)}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );
}