import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import { toastr } from 'react-redux-toastr';
import I18n from 'i18n-js';

import * as PATH from '../../../../constants/routeConstants';
import * as actions from "../../../../actions/accounting/receivable/createInvoiceChargeback";
import * as selectors from "../../../../selectors/accounting/receivable/createInvoiceChargeback";

import AccountsReceivablePlanView from '../../../components/accounting/receivable/CreatePlanChargeBackInvoice';
class AccountsReceivablePlan extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentWillMount() {
        this.props.loadData();
    }

    componentWillUnmount() {
        this.props.reset();
    }

    componentWillReceiveProps(nextProps) {
        let { saveSuccess } = nextProps;
        if(saveSuccess) {
            nextProps.reset();
            toastr.success(I18n.t('Notify.Success'), 'Create Success');
            nextProps.history.push(PATH.ACCOUNTING_CREDIT_DEBIT_URL);
        }
    }

    handleChangeView(value) {
        // 
        // if (value == "view-supplier") {
        //     this.props.history.push(PATH.ACCOUNTING_PAYABLE_PLAN_SUPPLIER_URL);
        // }
        // else if (value == "view-contractor") {
        //     this.props.history.push(PATH.ACCOUNTING_PAYABLE_PLAN_CONTRACTOR_URL);
        // }
        // else {
        //     this.props.history.push(PATH.ACCOUNTING_PAYABLE_PLAN_IMPORTEXPORT_URL);
        // }
    }
    render() {
        return (
            <AccountsReceivablePlanView {...this.props}/>
        )
    }
}

AccountsReceivablePlan.propTypes = {

};

AccountsReceivablePlan.defaultProps = {

};

const mapStateToProps = (state) => ({
    selectableCustomers: selectors.getSelectableCustomers(state),
    selectedCustomer: selectors.getSelectedCustomer(state),
    issuedDate: selectors.getIssuedDate(state),
    fetchingSelectableCustomer: selectors.isFetchingSelectableCustomer(state),
    checkableBills: selectors.getCheckableBills(state),
    checkedBills: selectors.getCheckedBills(state),
    dataRequestShipOut: selectors.getDataRequestShipOutByCustomer(state),
    dataApi: selectors.getDataApi(state),
    // invoiceNo: selectors.getInvoiceNo(state),
    totalAirFee: selectors.getTotalAirFee(state),
    totalDebitAmount: selectors.getTotalDebitAmount(state),
    noListAvailable: selectors.getListNoAvailable(state),
    countNo: selectors.getCountNo(state),
    availableNoList: selectors.getAvailableNoList(state),
    sayMoney: selectors.getSayMoney(state),
    saveSuccess: selectors.getSuccess(state)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    loadData() {
        dispatch(actions.listCustomer());
    },
    // changeInvoiceNo(data) {
    //     dispatch(actions.changeInvoiceNo(data));
    // },
    changeSelectedCustomer(data) {
        // dispatch(actions.resetData())
        data.chargeBack = true;
        dispatch(actions.changeSelectedCustomer(data));
    },
    changeRateItem(billNo, customerPO, itemKey, value) {
        dispatch(actions.changeRateItem({billNo, customerPO, itemKey, value}));
    },
    changeFeeILoginxInUSAItem(billNo, customerPO, itemKey, value) {
        dispatch(actions.changeFeeILoginxInUSAItem({billNo, customerPO, itemKey, value}));
    },
    changeDeductItem(billNo, customerPO, itemKey, value) {
        dispatch(actions.changeDeductItem({billNo, customerPO, itemKey, value}));
    },
    changePayerItem(billNo, customerPO, itemKey, value) {
        dispatch(actions.changePayerItem({billNo, customerPO, itemKey, value}));
    },
    changeRateLMItem(billNo, customerPO, itemKey, value) {
        dispatch(actions.changeRateLMItem({billNo, customerPO, itemKey, value}));
    },
    changeIssuedDate(value) {
        dispatch(actions.changeIssuedDate(value));
    },
    changeCheckedBill(data) {
        dispatch(actions.changeCheckedBill(data));
    },
    // changeSearchText(value) {
    //     dispatch(actions.changeSearchText(data));
    // },
    changeReasonOrder(billNo, customerPO, value) {
        dispatch(actions.changeReasonOrder({billNo, customerPO, value}))
    },
    changeNoteOrder(billNo, customerPO, value) {
        dispatch(actions.changeNoteOrder({billNo, customerPO, value}))
    },
    save(data) {
        dispatch(actions.saveInvoice(data));
    },
    changeTypeInvoice() {
        ownProps.history.push(PATH.ACCOUNTING_RECEIVABLE_PLAN_NORMAL_URL);
    },
    getDebitAmount(item) {
        let airFee = this.getAirFee(item);
        let feeInUsa = Number(item.feeInUsa) || 0;
        let deduct = item.deduct || 0;
        return airFee + feeInUsa - deduct;
    },
    getDebitAmountCustomerPO(items) {
        return _.reduce(items, (total, item) => {
            let airFee = this.getAirFee(item);
            let feeInUsa = Number(item.feeInUsa) || 0;
            let deduct = item.deduct || 0;
            return total + airFee + feeInUsa - deduct;
        }, 0)
    },
    getAirFee(elem) {
        return (elem.rate || 0) * elem.cbm.cw;
    },
    getAirFeeCustomerPO(items) {
        return _.reduce(items, (total, item) => {
            return total + (item.rate || 0) * item.cbm.cw;
        }, 0)
    },
    createNo(billNo, customerPO, itemKey, availableNoList, countNo, value) {
        dispatch(actions.createNoItem({billNo, customerPO, itemKey, availableNoList, countNo, value}));
    },
    getAmountPay(item) {
        return item.cbm.cw * (item.rateLM || 0);
    },
    reset() {
        dispatch(actions.resetData());
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AccountsReceivablePlan));
