import React from 'react';
import PropTypes from 'prop-types';


const ProgressBar = ({max, value1, value2}) => {
  const style = {width: value1+'%'};
  const subStyle = {width: value2+'%'};
  return (
    <div className="progress bg-white mg-0">
      <div className="progress-bar bg-green" role="progressbar"
           style={style} aria-valuenow={value1} aria-valuemin="0"
           aria-valuemax={max || 100}>{value1}%
      </div>
      <div className="progress-bar progress-bar-warning" style={subStyle}>
        {value2}%
      </div>
    </div>
  );
}

ProgressBar.propTypes = {
  max: PropTypes.number,
  value1: PropTypes.oneOfType(["number, string"]),
  value2: PropTypes.oneOfType(["number, string"]),
  style: PropTypes.string,
};

export default ProgressBar;
