const C = 'EDIT_PACKING';

export default {
    INFO_PACKING: `${C}_INFO_PACKING`,
    GET_PACKING: `${C}_GET_PACKING`,
    CHANGE_PACKED_QUANTITY: `${C}_CHANGE_PACKED_QUANTITY`,
    CAN_CHANGE_PACKED_QUANTITY: `${C}_CAN_CHANGE_PACKED_QUANTITY`,
    CHANGE_SHOW_CARTON_DETAIL: `${C}_CHANGE_SHOW_CARTON_DETAIL`,
    CHANGE_CHECKED_PACKING: `${C}_CHANGE_CHECKED_PACKING`,
    CHANGE_PACKING_ACTUAL_NUMBER: `${C}_CHANGE_PACKING_ACTUAL_NUMBER`,
    CHANGE_GWKGSNWKGS_ACTUAL_NUMBER: `${C}CHANGE_GWKGSNWKGS_ACTUAL_NUMBER`,
    STOCK: `${C}STOCK`,
    RESHIP: `${C}RESHIP`
}