import React from "react";
import Textbox from './Text';
import _ from 'lodash';

let component_map = {
  'text'                : require("./Text").default,
  'text-click'          : require("./TextClick").default,
  'image'               : require("./Image").default,
  'textInput'           : require("./TextInput").default,
  'select'              : require("./Select").default,
  'nameAvatar'          : require("./NameAvatar").default,
  'status'              : require("./Status").default,
  'amount'              : require("./Amount").default,
  'checkbox'            : require("./CheckBox").default,
};

export default function getField(value, options={}, type, name) {
  let MyComponent = component_map[type];
  const className = _.get(options, "options.clsTd", "");
  if(MyComponent){
    return (<MyComponent key={options.index} options={options} className={className} checked={value} value={value} center/>);
  }
  return  (<Textbox key={options.index} options={options} value={value} />);
}
