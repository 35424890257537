import {
  getListItemDAL,
  addItemDAL,
  editItemDAL,
  getItemDAL,
  getItemByKeyDAL,
  deleteItemDAL,
  getListPurchasesInboundDAL,
  editAndSendRevisedDAL,
} from '../api/purchaseOrderApi';
import _ from 'lodash';

import Base from '../api/base';
import Urls from '../constants/apiUrls';

const BASE_URL = `${Urls.baseUrl}/erp/api/purchase-order`;

class purchaseOrderServices {
  constructor() {
    this.api = new Base();
  }

  retrieve(id) {
    const url = `${BASE_URL}/${id}`;
    const query = {
      populate: ['createdBy', 'sender', 'supplierId', 'styleId|orderId', 'materials.material'].join(','),
    };
    return this.api.execute(url, this.api.ApiMethod.get, query);
  }

  getListItem(options = {}) {
    options.query && _.set(options, 'query', JSON.stringify(options['query']));
    options.sort = options.sort || { createdAt: -1 };
    options.sort && _.set(options, 'sort', JSON.stringify(options['sort']));
    return getListItemDAL(options);
  }

  getDetail(id, reset = true) {
    return getItemDAL(id, {});
  }

  getDetailByKey(key) {
    return getItemByKeyDAL(key, {});
  }

  deleteItem(id) {
    return deleteItemDAL(id);
  }

  editItem(id, data) {
    data.supplier = data.supplier ? { _id: data.supplier._id } : null;
    return editItemDAL(id, data);
  }

  editAndSendRevised(id, data) {
    return editAndSendRevisedDAL(id, data);
  }

  addItem(data) {
    data.supplier = data.supplier ? { _id: data.supplier._id } : null;
    return addItemDAL(data);
  }

  getListPurchasesInbound(query) {
    return getListPurchasesInboundDAL(query);
  }
}

export default new purchaseOrderServices();
