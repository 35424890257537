import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';
const moment = require('moment');
const DateTimeField = require('react-datetime');
import _ from 'lodash';
import I18n from 'i18n-js';
import appActions from '../../../actions/appActions';
import BaseComponent from '../../base/BaseComponent';
import UpdateMaterials from '../modal/UpdateMaterials';
import {
  PURCHASE_EVENT,
  PURCHASE_STATUS,
  PURCHASE_STATUS_KEY,
  PURCHASES_STATUS_LIST,
  PURCHASE_SCREEN,
} from '../../../constants/constants';
import purchaseOrderActions from '../../../actions/purchaseOrderActions';
import * as utils from '../../../functions/utils';
import CheckBox from '../../common/fields/CheckBox';
import formatCurrency from 'format-currency';
import ModalConfirm from '../../common/modals/ModalConfirm';

import icAddIcon from '../../../stylesheets/images/ic-add.svg';

class StockOrder extends BaseComponent {
  constructor(props, context) {
    super(props, context);
    const { materials, supplier, remark, style = {} } = this.props.order;
    const statusOb = PURCHASES_STATUS_LIST.find(x => x.label === this.props.order.status);
    const status = statusOb && statusOb.value ? statusOb.value : '';
    this.state = {
      status: status || _.get(this.props.order, 'status', ''),
      errors: {},
      stock: {
        remark: remark || '',
        materials: (materials || []).map(material => this.initMaterial(material)),
        selectedMaterials: materials || [],
        type: 'Unreserved',
        currency: supplier && supplier.currency ? supplier.currency : '',
        sender: this.props.order.sender,
        createdAt: this.props.order.createdAt,
        createdBy: this.props.order.createdBy,
        purchaseNo: this.props.order.purchaseNo,
        supplierId: supplier.supplierId || supplier._id,
        supplierName: supplier.supplierName || supplier.name || '',
        supplierEmail: supplier.email || '',
        supplier: supplier,
        _id: this.props.order._id,
        status: status || _.get(this.props.order, 'status', ''),
      },
      supplier,
      isStateChange: false,
    };
    this.edit = this.edit.bind(this);
  }

  initMaterial = material => {
    material = material || {};
    const mt = _.get(material, 'material', {});
    return {
      id: material._id,
      material: mt,
      materialName: mt.name || '',
      content: _.get(mt, 'data.content', ''),
      unit: _.get(mt, 'data.unit', ''),
      color: material.color,
      size: material.size,
      price: material.price,
      needToBuy: material.needToBuy,
      discount: material.discount || 0,
      surcharge: material.surcharge || 0,
      XMIL: material.XMIL || new Date(),
      ETD: material.ETD || new Date(),
      ETA: material.ETA || new Date(),
      exReceivedDate: material.exReceivedDate || new Date(),
      exReceivedDateFormat: new moment(material.exReceivedDate || new Date()).format('DD/MM/YYYY'),
    };
  };

  componentDidUpdate(prevProps, prevSate) {
    if (this.state !== prevSate) {
      if (this.state.isStateChange === false) {
        this.setState({ isStateChange: true });
      }
    }
  }

  onChangeRemark = evt => this.setState({ stock: { ...this.state.stock, remark: evt.target.value } });

  renderDate = (idx, fieldName, material, isEdit) => {
    const { errors } = this.state;
    const onChange = date => {
      const { stock } = this.state;
      stock.materials[idx][fieldName] = date.format();
      if (fieldName === 'exReceivedDate') {
        stock.materials[idx]['exReceivedDateFormat'] = date.format('MMM DD, YYYY');
      }
      this.setState({ stock });
    };

    return (
      <div>
        <DateTimeField
          value={moment(material[fieldName]).toDate()}
          dateFormat="MMM DD, YYYY"
          timeFormat={false}
          onChange={onChange}
        />
        {errors[fieldName] && <div className="alert-warning">{errors[fieldName]}</div>}
      </div>
    );
  };

  renderInput(item, field, idx, isEdit, type) {
    if (!isEdit) {
      return item[field] || '';
    }
    return (
      <input
        onChange={evt => {
          this.onChangeNumber(evt.target.value, idx, field);
        }}
        onBlur={evt => {
          this.onBlurNumber(evt.target.value, idx, field);
        }}
        className="form-control center"
        type={type}
        min="0"
        value={item[field] || ''}
      />
    );
  }

  onChangeNumber = (vl, idx, field) => {
    const { stock } = this.state;
    stock.materials[idx][field] = vl;
    this.setState({ stock });
  };
  onBlurNumber = (vl, idx, field) => {
    const { stock } = this.state;
    stock.materials[idx][field] = parseFloat(vl) || '';
    this.setState({ stock });
  };

  renderRemark(value, status) {
    if (
      status !== PURCHASE_STATUS.IN_PORT &&
      status !== PURCHASE_STATUS.IN_TRANSIT &&
      status !== PURCHASE_STATUS.IN_WAREHOUSE &&
      status !== PURCHASE_STATUS.COMPLETED
    ) {
      return <textarea rows="10" value={value} onChange={this.onChangeRemark} cols="20" className="mg-bottom-50" />;
    }
    return <p className="text-left">{value || ''}</p>;
  }

  showPopup = () => {
    const { openModal, closeModal } = this.props.modal;
    const { supplier, stock } = this.state;
    const selectedMaterials = stock.selectedMaterials;
    const stockMaterials = stock.materials;
    const next = materials => {
      const { stock } = this.state;
      stock.materials = materials;
      this.setState({ stock });
    };
    const modal = (
      <UpdateMaterials
        {...{ closeModal, supplier, next, selectedMaterials, stockMaterials, initMaterial: this.initMaterial }}
      />
    );
    openModal(modal);
  };

  calculateTotal = materials => {
    return (materials || []).reduce(
      (acc, vl) => acc + (vl.price || 0) * (vl.needToBuy || 0) + (vl.surcharge || 0) - (vl.discount || 0),
      0
    );
  };

  save = status => {
    const { stock } = this.state;
    const total = vl => (vl.price || 0) * (vl.needToBuy || 0) + (vl.surcharge || 0) - (vl.discount || 0);
    const found = (stock.materials || []).find(vl => (vl.needToBuy || 0) <= 0 || total(vl) <= 0);
    if (found) toastr.info(I18n.t('Notify.Info'), I18n.t('ErrorMessages.PleaseInputQuantityToBuy'));
    else {
      const gTotal = this.calculateTotal(stock.materials);
      this.props.purchaseOrderActions.addItem({ ...stock, status, gTotal });
    }
  };

  edit = (status, type) => {
    const { stock } = this.state;
    const { order, purchaseDeviation } = this.props;
    const total = vl => (vl.price || 0) * (vl.needToBuy || 0) + (vl.surcharge || 0) - (vl.discount || 0);
    const found = (stock.materials || []).find(vl => (vl.needToBuy || 0) <= 0 || total(vl) <= 0);
    const purChaseId = this.props.order && this.props.order._id ? this.props.order._id : '';
    if (found) alert('Please input correctly!');
    else {
      const gTotal = this.calculateTotal(stock.materials);
      status = utils.setStatus(_.get(this.props.order, 'status', ''), type, order, stock, purchaseDeviation);
      status = status || (PURCHASE_STATUS[this.state.status] ? PURCHASE_STATUS[this.state.status] : this.state.status);
      this.props.purchaseOrderActions.editItem(purChaseId, { ...stock, status, gTotal });
    }
  };

  editAndSendRevised = (status, type) => {
    const { stock } = this.state;
    const { order, purchaseDeviation } = this.props;
    const total = vl => (vl.price || 0) * (vl.needToBuy || 0) + (vl.surcharge || 0) - (vl.discount || 0);
    const found = (stock.materials || []).find(vl => (vl.needToBuy || 0) <= 0 || total(vl) <= 0);
    const purChaseId = this.props.order && this.props.order._id ? this.props.order._id : '';
    if (found) alert('Please input correctly!');
    else {
      const gTotal = this.calculateTotal(stock.materials);
      status = utils.setStatus(_.get(this.props.order, 'status', ''), type, order, stock, purchaseDeviation);
      status = status || (PURCHASE_STATUS[this.state.status] ? PURCHASE_STATUS[this.state.status] : this.state.status);
      this.props.purchaseOrderActions.editAndSendRevised(purChaseId, { ...stock, status, gTotal });
    }
  };

  renderConfirmSaveStatusCompleted(status) {
    let { openModal, closeModal } = this.props.modal;
    let modal = (
      <ModalConfirm
        title={I18n.t('PagePurchase.CompletedOrder')}
        confirmMessage={I18n.t('PagePurchase.ConfirmSaveStatusCompleted')}
        messageOk={I18n.t('Btn.YES')}
        messageCancel={I18n.t('Btn.CANCEL')}
        handleClose={closeModal}
        handleOk={this.edit.bind(null, status, PURCHASE_EVENT.SAVE)}
      />
    );
    openModal(modal);
  }

  saveOrder = () => {
    if (this.props.isEdit) {
      let value = this.state.status;
      let status = PURCHASE_STATUS[value];
      if (status == PURCHASE_STATUS.COMPLETED) {
        return this.renderConfirmSaveStatusCompleted(status);
      }
      return this.edit(status, PURCHASE_EVENT.SAVE);
    }
    return this.save(PURCHASE_STATUS.DRAFT, PURCHASE_EVENT.SAVE);
  };

  sendOrder = () => {
    if (this.props.isEdit) {
      return this.edit(PURCHASE_STATUS.NEW, PURCHASE_EVENT.SEND);
    }
    return this.save(PURCHASE_STATUS.NEW, PURCHASE_EVENT.SEND);
  };

  sendRevised = () => {
    return this.editAndSendRevised(status, PURCHASE_EVENT.SAVE);
  };

  openPreviewPurchaseOrder(data) {
    if (data) {
      // this.props.history.push(PATH.PURCHASE_LIST_PREVIEW_ORDER_URL)
      let screen = PURCHASE_SCREEN.PREVIEW_PURCHASE_ORDER;
      let pageList = [...this.props.appState.pageList];
      // Sync data after user change some attribute like Remark or Quantity...
      const prevData = _.get(_.last(pageList), 'data', undefined);
      if (prevData !== data) {
        const prevPage = pageList.pop();
        pageList.push({ screen: prevPage.screen, data });
      }
      pageList.push({ screen: screen, data });
      this.props.appActions.addPage(pageList);
      const pageData = _.assign(_.cloneDeep(this.props.appState.page)); //{isEdit: false, isAdd: false}
      this.props.appActions.setPageCurrent(pageData);
    }
  }

  onChangeStatus(event) {
    const status = event && event.target && event.target.value ? event.target.value : PURCHASE_STATUS.NEW;
    this.setState({ selectStatus: status });
    this.setState({ status });
  }

  onChangeCheckboxApprove(event) {
    if (event && event.target && event.target.checked) {
      this.setState({ status: PURCHASE_STATUS.APPROVED });
    } else {
      this.setState({ status: PURCHASE_STATUS_KEY.WAITING_FOR_APPROVAL });
    }
  }

  renderStatus(status) {
    if (status === PURCHASE_STATUS.APPROVED) {
      return <p className="text-left">{status}</p>;
    }
    return (
      <div className="center select date-time-td">
        <select className="w-full" onChange={event => this.onChangeStatus(event)} value={this.state.status}>
          {/* <option></option> */}
          {PURCHASES_STATUS_LIST.map((status, idx) => (
            <option key={idx} value={status.value}>
              {status.label}
            </option>
          ))}
        </select>
      </div>
    );
  }

  renderGTotal({ total, showStatus, status }) {
    return (
      <tr className="g-total">
        {showStatus && <td colSpan="1">{I18n.t('PagePurchase.Status')}</td>}
        {showStatus && (
          <td colSpan="2" className="pd-0-15">
            {this.renderStatus(status)}
          </td>
        )}
        <td colSpan={showStatus ? '12' : '15'} className="pd-right-10">
          <ul>
            <li>{I18n.t('PagePurchase.G-Total')}</li>
            <li className="color-number">{total}</li>
          </ul>
        </td>
      </tr>
    );
  }

  render = () => {
    const { stock, supplier, isStateChange } = this.state;
    let { selectStatus } = this.state;
    const currency = supplier.currency;

    let total = this.calculateTotal(stock.materials);
    total =
      total > 0
        ? currency == 'USD'
          ? `$ ${formatCurrency(_.round(total, 2))}`
          : `${formatCurrency(_.round(total, 2))} ${currency}`
        : 0;
    let { showStatus, status } = utils.setParamsByOrder(this.props.order);
    const isEdit =
      status !== PURCHASE_STATUS.APPROVED &&
      status !== PURCHASE_STATUS.IN_PORT &&
      status !== PURCHASE_STATUS.IN_TRANSIT &&
      status !== PURCHASE_STATUS.IN_WAREHOUSE &&
      status !== PURCHASE_STATUS.COMPLETED;
    if (stock.status && !status) {
      status = stock.status;
    }
    if (!selectStatus) {
      selectStatus = stock.status;
    }

    return (
      <div id="content">
        <div className="md content-main-process">
          <div style={{ width: '100%' }} className="style">
            <ul style={{ width: '100%' }} className="content-main-header-filter-style">
              <li className="title">
                <p>
                  {I18n.t('PagePurchase.Supplier')}{' '}
                  <span className="color-orange medium mg-left-60 size-16"> {(supplier && supplier.name) || ''}</span>
                </p>
              </li>
              <li className="title mg-left-60">
                <p>
                  {' '}
                  {I18n.t('PagePurchase.PurchaseOrderNo')}
                  <span className="color-orange medium size-16 mg-left-15"> {_.get(stock, 'purchaseNo', '')}</span>
                </p>
              </li>
              <li className="title" style={{ float: 'right' }}>
                {!this.props.isEdit && (
                  <p>
                    <span className="cursor-pointer global-add" onClick={() => this.showPopup()}>
                      <img src={icAddIcon} /> {I18n.t('PagePurchase.UpdateMaterials')}
                    </span>
                  </p>
                )}
                {status === PURCHASE_STATUS.WAITING_FOR_APPROVAL && (
                  <div className="checkbox-approve">
                    <span>{I18n.t('PagePurchase.Approve')}</span>
                    <CheckBox onChange={event => this.onChangeCheckboxApprove(event)} />
                  </div>
                )}
              </li>
            </ul>
          </div>
        </div>
        {stock.materials && stock.materials.length > 0 && (
          <div className="wrap-global-table">
            <table style={{ width: '100%' }} className="global-table data-time-table">
              <tbody className="body-data-time s">
                <tr>
                  <th>{I18n.t('PagePurchase.Material')}</th>
                  <th className="center">{I18n.t('PagePurchase.Content')}</th>
                  <th className="center">{I18n.t('PagePurchase.Color')}</th>
                  <th className="center">{I18n.t('PagePurchase.Size')}</th>
                  <th className="center">{I18n.t('PagePurchase.Unit')}</th>
                  <th className="center">{I18n.t('PagePurchase.XMIL')}</th>
                  <th className="center">{I18n.t('PagePurchase.ETD')}</th>
                  <th className="center">{I18n.t('PagePurchase.ETA')}</th>
                  <th className="center">
                    {I18n.t('PagePurchase.Expected')}
                    <br />
                    {I18n.t('PagePurchase.ReceivedDate')}
                  </th>
                  <th className="center">{I18n.t('PagePurchase.Quantity')}</th>
                  <th className="center">{I18n.t('PagePurchase.Convert_Quantity')}</th>
                  <th className="center">{I18n.t('PagePurchase.Price')}</th>
                  <th className="center">{I18n.t('PagePurchase.Surcharge')}</th>
                  <th className="center">{I18n.t('PagePurchase.Discount')}</th>
                  <th className="center">
                    {I18n.t('PagePurchase.Total')}
                    <br />
                    {I18n.t('PagePurchase.Price')}
                  </th>
                </tr>
                {stock.materials.map((item, idx) => {
                  const price =
                    item.price > 0
                      ? currency == 'USD'
                        ? `$ ${formatCurrency(_.round(item.price, 2))}`
                        : `${formatCurrency(_.round(item.price, 2))} ${currency}`
                      : 0;
                  const qtConverted = this.convertUnit(item.needToBuy || 0, item.material || {});
                  let amount = item.price * (item.needToBuy || 0) + (item.surcharge || 0) - (item.discount || 0);
                  amount =
                    amount > 0
                      ? currency == 'USD'
                        ? `$ ${formatCurrency(_.round(amount, 2))}`
                        : `${formatCurrency(_.round(amount, 2))} ${currency}`
                      : 0;
                  return (
                    <tr key={idx}>
                      <td>{item.materialName}</td>
                      <td>{item.content}</td>
                      <td className="center">{item.color}</td>
                      <td className="center">{item.size}</td>
                      <td className="center">{item.unit}</td>
                      <td className="center select date-time-td">{this.renderDate(idx, 'XMIL', item, isEdit)}</td>
                      <td className="center select date-time-td">{this.renderDate(idx, 'ETD', item, isEdit)}</td>
                      <td className="center select date-time-td">{this.renderDate(idx, 'ETA', item, isEdit)}</td>
                      <td className="center select date-time-td">
                        {this.renderDate(idx, 'exReceivedDate', item, isEdit)}
                      </td>
                      <td className="center">{this.renderInput(item, 'needToBuy', idx, isEdit)}</td>
                      <td className="center">
                        {qtConverted} {this.getUnit(item, true)}
                      </td>
                      <td className="color-number center">{price}</td>
                      <td className="color-number center">
                        {this.renderInput(item, 'surcharge', idx, isEdit, 'text')}
                      </td>
                      <td className="color-number center">
                        {this.renderInput(item, 'discount', idx, isEdit, 'number')}
                      </td>
                      <td className="color-number center">{amount}</td>
                    </tr>
                  );
                })}
                {this.renderGTotal({ total, showStatus, status })}
                <tr className="bg-white wrap-textarea">
                  <td colSpan="1" className="center vertical-align-inherit">
                    {I18n.t('PagePurchase.Remark')}
                  </td>
                  <td colSpan="14">{this.renderRemark(stock.remark, status)}</td>
                </tr>
              </tbody>
            </table>
            <div className="text-center">
              <ul className="list-button">
                <li>
                  <a className="bg-black" onClick={() => this.openPreviewPurchaseOrder(stock)}>
                    {I18n.t('Btn.PreviewOrder')}
                  </a>
                </li>

                {status !== PURCHASE_STATUS.APPROVED && isStateChange && (
                  <li>
                    <a className="bg-orange" disabled={true} onClick={() => this.saveOrder()}>
                      {I18n.t('Btn.Save')}
                    </a>
                  </li>
                )}
                {isStateChange == false && (
                  <li>
                    <button type="button" className={`${'btn btn-default'} bt-orange mg-l-5`} disabled={true}>
                      {I18n.t('Btn.SAVE')}
                    </button>
                  </li>
                )}
                {status !== PURCHASE_STATUS.WAITING_FOR_APPROVAL &&
                  status !== PURCHASE_STATUS.USE_STOCK &&
                  status !== PURCHASE_STATUS.IN_PROGRESS &&
                  status !== PURCHASE_STATUS.IN_PORT &&
                  status !== PURCHASE_STATUS.IN_TRANSIT &&
                  status !== PURCHASE_STATUS.IN_WAREHOUSE &&
                  status !== PURCHASE_STATUS.COMPLETED &&
                  status !== PURCHASE_STATUS.NEW && (
                    <li>
                      <a className="bg-green" onClick={() => this.sendOrder()}>
                        {I18n.t('Btn.Send')}
                      </a>
                    </li>
                  )}
                {(selectStatus === PURCHASE_STATUS_KEY.NEW || selectStatus === PURCHASE_STATUS_KEY.IN_PROGRESS) && (
                  <li>
                    <a className="bg-orange" onClick={() => this.sendRevised()}>
                      {I18n.t('Btn.SendRevised')}
                    </a>
                  </li>
                )}
              </ul>
            </div>
          </div>
        )}
      </div>
    );
  };
}

const mapStateToProps = (state, ownProps) => {
  return {
    authState: state.authState,
    appState: state.appState,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    purchaseOrderActions: bindActionCreators(purchaseOrderActions, dispatch),
    appActions: bindActionCreators(appActions, dispatch),
  };
};

StockOrder.propTypes = {
  authState: PropTypes.object.isRequired,
  isEdit: PropTypes.bool,
  purchaseDeviation: PropTypes.number,
};

export default connect(mapStateToProps, mapDispatchToProps)(StockOrder);
