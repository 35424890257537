import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import { toastr } from 'react-redux-toastr';
import I18n from 'i18n-js';

import * as PATH from '../../../../constants/routeConstants';
import * as actions from "../../../../actions/accounting/receivable/createInvoiceChargeback";
import * as selectors from "../../../../selectors/accounting/receivable/createInvoiceChargeback";
import payInfoApi from '../../../../api/payInfoApi';
import accountingApi from '../../../../api/accountingApi';
import EditPlanChargeBackInvoiceView from '../../../components/accounting/receivable/EditPlanChargeBackInvoice';
class EditPlanChargeBackInvoice extends Component {

    constructor(props) {
        super(props);
        this.state = {
            payInfo: {},
            checkableBills: []
        };
    }

    componentWillMount() {
        // this.props.loadData(this.props.router.route.match.params.payInfoId);
        payInfoApi.getPayInfo(this.props.router.route.match.params.payInfoId + "?populate=customer").then(res => {
            if (res.success) {
                var payInfo = _.get(res, "data", {})
                accountingApi.listBillsOrContsByCustomer('Air', _.get(res, "data.customer._id", ""), true).then(res1 => {
                    var checkableBills = _.get(res1, "data", [])
                    var checkedBills = [];
                    let dataRequest = {};
                    dataRequest.chargeBack = true;
                    dataRequest.billNo = [];
                    dataRequest.billIds = [];
                    checkableBills.forEach(bill => {
                        if(payInfo && payInfo.chargeBackInvs && payInfo.chargeBackInvs.find(item => item.shipOutId == bill._id)) {
                            bill.checked = true;
                            dataRequest.billNo.push(bill.billNo)
                            dataRequest.billIds.push(bill._id)
                            checkedBills.push(bill)
                        }
                    })
                    this.setState({checkableBills: checkableBills})

                    dataRequest.shipMode ='Air'
                    dataRequest.customer =_.get(res, "data.customer._id", "");

                    let p = dataRequest;
                    p.cont = p.billNo;
                    p.ids = dataRequest.billIds || [];
                    accountingApi.getListShipOutByCustomer(p).then(res2 => {
                        let payload = res2.data
                        _.map(checkedBills, el => {
                            let billProp = el.billNo;
                            if(payload[billProp]) {
                              el.customerPOs = [];
                              let keys = Object.keys(payload[billProp])
                                _.forEach(keys, (property) => {
                                    let data = payload[billProp][property]
                                    if(data) {
                                        data = data.filter(item => {
                                            if(payInfo.chargeBackInvs) {
                                                let chargeBackInvoice = payInfo.chargeBackInvs.find(chargeBackI => chargeBackI.cutTicketId == item.cutTicketId)
                                                if(chargeBackInvoice) {
                                                    item.chargeBackInvoice = chargeBackInvoice
                                                    return true;
                                                }
                                            }
                                            return false;
                                        })
                                    }
                                    if(data && data.length > 0) {
                                        let note = _.get(data[0], 'chargeBackInvoice.note', '');
                                        el.customerPOs.push({
                                            customerPO: property,
                                            note: note,
                                            data: data || []
                                          })
                                    }
                                })
                            }
                          })
                        this.setState({checkedBills: checkedBills})
                        this.getTotalAirFee(checkedBills);
                        this.getTotalDebitAmount(checkedBills);
                    })

                });
                this.setState({
                    payInfo: payInfo
                  })
            }
          })
    }

    getTotalAirFee(checkedBills) {
        let totalAirFee = 0;
        _.forEach(checkedBills, (bill) => {
            _.forEach(bill.customerPOs, (po) => {
                _.forEach(po.data, (el) => {
                let chargeBackInvoice = _.get(el, 'chargeBackInvoice', {});
                let rate = parseFloat(_.get(chargeBackInvoice, 'rate', 0));
                let cw = _.get(chargeBackInvoice, 'chargableWeight', 0);
                totalAirFee += _.round(rate * cw, 2);
                })
            })
        })
        this.setState({totalAirFee: totalAirFee})
    }

    getTotalDebitAmount(checkedBills) {
        let totalDebitAmount = 0;
        _.forEach(checkedBills, (bill) => {
            _.forEach(bill.customerPOs, (po) => {
                _.forEach(po.data, (el) => {
                    let chargeBackInvoice = _.get(el, 'chargeBackInvoice', {});
                    let rate = parseFloat(_.get(chargeBackInvoice, 'rate', 0));
                    let cw = _.get(chargeBackInvoice, 'chargableWeight', 0);
                    let us = _.get(chargeBackInvoice, 'feeInUSA', 0);
                    let de = _.get(chargeBackInvoice, 'deduct', 0);
                    totalDebitAmount += _.round(rate * cw + us - de, 2);
                })
            })
        })
        this.setState({totalDebitAmount: totalDebitAmount})
    }

    changeChargeBackInvs(field, cutTicketId, value) {
        let {payInfo} = this.state;
        if(payInfo.chargeBackInvs) {
            payInfo.chargeBackInvs.forEach(item => {
                if(item.cutTicketId == cutTicketId) {
                    item[`${field}`] = value;
                }
            })
        }
        this.setState({payInfo: payInfo})
    }

    updatePayinfo() {
        let {payInfo} = this.state;
        let amount = 0;
        _.forEach(payInfo.chargeBackInvs, (el) => {
            let rate = el.rate || 0;
            let usa = el.feeInUSA || 0;
            let ded = el.deduct || 0;
            let cbm = el.chargableWeight || 0;
            let temp = rate * cbm + usa - ded;
            amount = amount + temp;
        })
        payInfo.amount = amount;
        payInfoApi.updatePayInfo(payInfo._id, payInfo).then(res => {
            if (res.success) {
                toastr.success(I18n.t('Notify.Success'), 'Save Success');
                nextProps.history.push(PATH.ACCOUNTING_RECEIVABLE_PLAN_URL);
            }
        });
    }

    // componentWillUnmount() {
    //     this.props.reset();
    // }

    // componentWillReceiveProps(nextProps) {
    //     let { saveSuccess } = nextProps;
    //     if(saveSuccess) {
    //         nextProps.reset();
    //         toastr.success(I18n.t('Notify.Success'), 'Save Success');
    //         nextProps.history.push(PATH.ACCOUNTING_RECEIVABLE_INVOICE_URL);
    //     }
    // }

    render() {
        let {payInfo, checkableBills, checkedBills, totalDebitAmount, totalAirFee} = this.state;
        return (
            <EditPlanChargeBackInvoiceView 
            {...this.props}
            payInfo = {payInfo || {}}
            checkableBills= {checkableBills || []}
            checkedBills = {checkedBills || []}
            totalAirFee = {totalAirFee}
            totalDebitAmount = {totalDebitAmount}
            changeChargeBackInvs = {(field, cuticketId, value) => this.changeChargeBackInvs(field, cuticketId, value)}
            onSave = {() => this.updatePayinfo()}
            />
        )
    }
}

EditPlanChargeBackInvoice.propTypes = {

};

EditPlanChargeBackInvoice.defaultProps = {

};

const mapStateToProps = (state) => ({
    
});

const mapDispatchToProps = (dispatch, ownProps) => ({
   
    getDebitAmount(item) {
        return (item.chargeBackInvoice.rate || 0) * item.cbm.cw + (item.chargeBackInvoice.feeInUSA || 0) - (item.chargeBackInvoice.deduct || 0);
    },
    getDebitAmountCustomerPO(items) {
        return _.reduce(items, (total, item) => {
            return total + (item.chargeBackInvoice.rate || 0) * item.cbm.cw + (item.chargeBackInvoice.feeInUSA || 0) - (item.chargeBackInvoice.deduct || 0);
        }, 0)
    },
    getAirFee(elem) {
        return (elem.chargeBackInvoice.rate || 0) * elem.cbm.cw;
    },
    getAirFeeCustomerPO(items) {
        return _.reduce(items, (total, item) => {
            return total + (item.chargeBackInvoice.rate || 0) * item.cbm.cw;
        }, 0)
    },
  
    getAmountPay(item) {
        return item.cbm.cw * (item.chargeBackInvoice.rateLM || 0);
    },
  
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EditPlanChargeBackInvoice));
