import C from '../../constants/ship-out/viewPklOfProdDetail';
import shipOutApi from '../../api/shipOutApi';


export const getPackingDetail = (id) => dispatch => {
    if(id) {
        let array = [];
        array.push(id);
        let query = {data: array}
        shipOutApi.getPackingDetail(query).then(res => {
            if(res.success) {
                dispatch({type: C.CHANGE_PACKING_DETAIL, payload: res.data})
            }
        })
    }
}

export const changeStyleActive = (id) => dispatch => {
    if(id) {
        dispatch({type: C.CHANGE_STYLE_ACTIVE, payload: id})
    }
}

export const changeEmptyPacking = () => dispatch => {
    dispatch({type: C.CHANGE_EMPTY_PACKING, payload: null})
}