import { createSelector } from 'reselect';
import _ from 'lodash';
import moment from 'moment';
import * as R from 'ramda';
import { genDollarToText } from '../../../functions/helpers';
import formatCurrency from 'format-currency';
// Get all data in invoice detail
export const getInvoiceDetail = ({ editInvoiceContractor }) => editInvoiceContractor.invoiceDetail || {};

/**
 * Get selected contractor
 */
export const getConstContractor = ({ editInvoiceContractor }) => {
  return editInvoiceContractor.contractor;
};

export const getConvertCurrency = ({ editInvoiceContractor }) => {
  return editInvoiceContractor.invoiceDetail && editInvoiceContractor.invoiceDetail.invoiceDetail.convertType;
};

export const getContractor = createSelector([getInvoiceDetail], data => {
  return data.invoiceDetail && data.invoiceDetail.contractor.name;
});

export const getCurrency = createSelector([getInvoiceDetail], data => {
  return data.invoiceDetail && data.invoiceDetail.contractor.currency;
});

export const getCurrencyTypeUSDOfSupplier = createSelector([getCurrency], currency => {
  return currency === 'USD' ? '$' : '';
});

export const getCurrencyTypeVNDOfSupplier = createSelector([getCurrency], currency => {
  return currency === 'VND' ? ` VND` : '';
});

export const getMainVATValue = createSelector([getInvoiceDetail], data => {
  if (data.invoiceDetail && data.invoiceDetail.vat) {
    let { vat } = data.invoiceDetail;
    return {
      ...vat,
      vatAmount: formatCurrency(Number(vat.totalAmount || 0) * (Number(vat.taxRate || 0) / 100)),
      grandTotalAmount: formatCurrency(
        Number(vat.totalAmount || 0) + Number(vat.totalAmount || 0) * (Number(vat.taxRate || 0) / 100)
      ),
    };
  }
  return null;
});

export const getPaid = createSelector([getInvoiceDetail], data => {
  return data.paid || 0;
});

export const getInvoiceNo = createSelector([getInvoiceDetail], data => {
  return data.invNo;
});

export const getIssuedDate = createSelector([getInvoiceDetail], data => {
  return data.issuedDate; //moment(data.issuedDate).format("MMM DD, YYYY");
});

export const getDueDate = createSelector([getInvoiceDetail], data => {
  return data.dueDate; //moment(data.dueDate).format("MMM DD, YYYY");
});

/**
 * Get payment type: "cash" or "transfer"
 *
 */
export const getPaymentType = ({ editInvoiceContractor }) => editInvoiceContractor.paymentType;

export const getCheckableCustomerPOs = ({ editInvoiceContractor }) => {
  if (editInvoiceContractor.customerPOs) {
    const customerPOs = editInvoiceContractor.customerPOs;
    return customerPOs.map(item => ({ _id: item._id, name: item.no, checked: item.checked }));
  }
};

export const getListCustomerPOsDetail = ({ editInvoiceContractor }) => {
  let customerPOs = _.filter(editInvoiceContractor.customerPOs, el => el.checked);
  if (!customerPOs || !customerPOs.length) return [];
  let rs = [];
  _.forEach(customerPOs, item => {
    let temp = {
      customerPO: {
        name: item.no,
        id: item._id,
        price: item.price || 0,
        quantity: 0,
      },
      data: [],
      vat: item.vat
        ? {
            ...item.vat,
            vatAmount: (item.vat.totalAmount || 0) * ((item.vat.taxRate || 0) / 100),
            grandTotalAmount:
              (item.vat.totalAmount || 0) + (item.vat.totalAmount || 0) * ((item.vat.taxRate || 0) / 100),
          }
        : null,
      active: item.active,
    };
    _.forEach(item.styles, (styleOrder, indexStyle) => {
      _.forEach(styleOrder.cutTickets, (cutTicket, indexCutTicket) => {
        _.forEach(cutTicket.colors, (color, indexColor) => {
          let tmp = {
            color: {
              id: color._id,
              name: color.name,
              quantity: color.qtyRec || 0,
              shipMode: color.shipMode,
              shipDate: color.shipDate,
              price: color.price || 0,
              amount: (color.qtyRec || 0) * (color.price || 0),
            },
            style: {
              id: styleOrder._id,
              name: styleOrder.name,
              label: indexColor == 0 && indexCutTicket == 0 ? styleOrder.name : '',
              price: styleOrder.price || 0,
            },
            cutTicket: {
              id: cutTicket._id,
              name: cutTicket.name,
              label: indexColor == 0 ? cutTicket.name : '',
            },
          };
          temp.data.push(tmp);
        });
      });
    });
    temp.customerPO.quantity = _.reduce(
      temp.data,
      (sum, el) => {
        return sum + el.color.quantity;
      },
      0
    );
    // temp.vat.totalAmount  = !temp.vat.totalAmount ? _.reduce(temp.data, (total, el) => { return total + el.color.quantity * el.color.price }, 0) : temp.vat.totalAmount;
    rs.push(temp);
  });
  return rs;
};

export const getSelectableCreditDebits = ({ editInvoiceContractor }) => {
  return _.map(editInvoiceContractor.selectableCreditDebits, s => ({
    value: s._id,
    label: s.code,
    type: s.type,
    amount: s.amount || 0,
  }));
};

export const getSelectedCreditDebit = ({ editInvoiceContractor }) => {
  if (!editInvoiceContractor.selectedCreditDebit) {
    let credit = _.get(editInvoiceContractor, 'invoiceDetail.invoiceDetail.creditDebit', null);
    if (credit) {
      editInvoiceContractor.selectedCreditDebit = {
        value: credit._id,
        label: credit.code,
        type: credit.type,
        amount: credit.amount || 0,
      };
    }
  }
  return editInvoiceContractor.selectedCreditDebit;
};

export const getAmount = createSelector([getListCustomerPOsDetail], data => {
  return _.sum(data.map(item => _.sum(item.data.map(it => it.color.amount || 0))));
});

export const getMainVATAmount = createSelector([getInvoiceDetail], data => {
  if (data.invoiceDetail) {
    const totalAmount = (data.invoiceDetail.vat || {}).totalAmount || 0;
    const taxRate = (data.invoiceDetail.vat || {}).taxRate || 0;
    return totalAmount * (taxRate / 100);
  }
});

export const getSumVatAmountOfCustomerPO = ({ editInvoiceContractor }) => {
  const { customerPOs } = editInvoiceContractor;
  if (customerPOs) {
    return _.sum(customerPOs.map(s => s.checked && s.vat && (s.vat.totalAmount || 0) * ((s.vat.taxRate || 0) / 100)));
  }
};

export const getTotalAmount = createSelector(
  [getAmount, getMainVATAmount, getSumVatAmountOfCustomerPO, getSelectedCreditDebit],
  (amount, maiVATAmount, sumVatAmountOfCustomerPO, selectedCredit) => {
    let total = (amount || 0) + (maiVATAmount || 0) + (sumVatAmountOfCustomerPO || 0);
    if (selectedCredit && selectedCredit.amount) {
      if (selectedCredit.type == 'Debit') {
        total += selectedCredit.amount;
      } else {
        total -= selectedCredit.amount;
      }
      return total;
    }
  }
);

export const getRemaining = createSelector([getTotalAmount, getPaid], (amount, paid) => {
  return amount - paid;
});

export const getPayAmount = ({ editInvoiceContractor }) => editInvoiceContractor.payAmount;

export const getReceiptFromInvoice = createSelector(
  [getInvoiceDetail, getPayAmount, getContractor],
  (data, payAmount, address) => {
    return { ...data.receipt, total: payAmount, date: moment().format(), address };
  }
);

export const getReceiptDataFromRawData = ({ editInvoiceContractor }) => ({
  ...editInvoiceContractor.receipt,
  say: genDollarToText(editInvoiceContractor.receipt.total),
});

export const getEmptyReceipt = createSelector([getReceiptFromInvoice], data => {
  return !data.isEdit;
});

export const getFilesSaved = createSelector([getInvoiceDetail], data => {
  return data.files || [];
});
/**
 * Get status of fetching selectable credit debit nots
 */
export const isFetchingSelectableCreditDebits = ({ editInvoiceContractor }) => {
  return editInvoiceContractor.fetchingSelectableCreditDebits;
};

/**
 * Get download url of invoice file
 */
export const getDownloadUrlInvoiceFile = ({ editInvoiceContractor }) => {
  throw 'NotImplementedException';
};

export const getSelectableConvertCurrencies = ({ editInvoiceContractor }) => {
  const { selectableConvertCurrencies } = editInvoiceContractor;
  return _.map(selectableConvertCurrencies, el => ({ label: el, value: el }));
};

export const getSelectedConvertCurrency = createSelector([getInvoiceDetail], data => {
  return data.invoiceDetail && data.invoiceDetail.convertType;
});

export const getConvertRate = createSelector([getInvoiceDetail], data => {
  return data.invoiceDetail && data.invoiceDetail.convertRate;
});

/**
 * Get amount convert using convertRate and selectedConvertCurrency to calculate
 */
export const getAmountConvert = createSelector(
  [state => state, getAmount, getConvertRate],
  (state, amount, convertRate) => {
    console.log(state);
    const currency = getConvertCurrency(state);
    if (currency == 'USD') {
      return '$ ' + formatCurrency(_.round(amount / convertRate, 2));
    } else {
      return formatCurrency(_.round(amount * convertRate, 2)) + ` ${currency}`;
    }
  }
);

/**
 * Get total amount converted using convert rate and selected convert currency
 */
export const getTotalAmountConvert = createSelector(
  [state => state, getTotalAmount, getConvertRate],
  (state, totalAmount, convertRate) => {
    console.log(state);
    const currency = getConvertCurrency(state);
    if (currency == 'USD') {
      return '$ ' + formatCurrency(_.round(totalAmount / convertRate, 2));
    } else {
      return formatCurrency(_.round(totalAmount * convertRate, 2)) + ` ${currency}`;
    }
  }
);

// export const getSelectableCreditDebits = ({ editInvoiceContractor }) => {
//   return _.map(editInvoiceContractor.selectableCreditDebits, s => ({
//     value: s._id,
//     label: s.code,
//     type: s.type,
//     amount: s.amount || 0
//   }));
// }

// export const getSelectedCreditDebit = ({ editInvoiceContractor }) => {
//   return editInvoiceContractor.selectedCreditDebit;
// }

/**
 * Return a function that will use to generate an api request body that will be used to post to api
 * The type of the body will depend on selected supplier is domestic or oversea
 */
export const fnGenerateRequestBody = createSelector(
  [({ editInvoiceContractor }) => editInvoiceContractor, getTotalAmount],
  (editInvoiceContractor, totalAmount) => {
    const { invoiceDetail, paymentType, payAmount, customerPOs } = _.cloneDeep(editInvoiceContractor);
    if (!invoiceDetail) return {};
    let data = _.omit(invoiceDetail, ['createdAt', 'createdBy', 'updatedAt', 'updatedBy', 'paymentHistory']);
    let detailOfInvoice = _.omit(data.invoiceDetail, ['createdAt', 'createdBy', 'updatedAt', 'updatedBy']);
    let receipt;
    if (paymentType === 'transfer') {
      data = _.omit(data, ['receipt']);
    } else {
      receipt = data.receipt;
      data = _.omit(data, ['files', 'receipt']);
    }
    data.invoiceDetail.contractor = data.invoiceDetail.contractor._id;
    data = {
      ...data,
      amount: totalAmount,
      customerPOs,
      payment: { receipt, paymentType: _.startCase(paymentType), paid: payAmount },
    };
    return { ...data, invoiceDetail: detailOfInvoice };
  }
);

export const getTransferFiles = ({ editInvoiceContractor }) => {
  const { transferFiles } = editInvoiceContractor;
  return _.map(transferFiles, file => ({
    id: R.path(['id'], file),
    url: R.path(['url'], file),
    raw: file,
    type: _.split(R.path(['id'], file), '.')[1],
  }));
};

export const getSuccess = ({ editInvoiceContractor }) => editInvoiceContractor.success;

export const getDisableChangeValue = createSelector([getPaid], paid => {
  if (paid > 0) return true;
  return false;
});

export const getActiveScreenStatus = ({ editInvoiceContractor }) => editInvoiceContractor.activeScreen;
