import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BaseComponent from '../base/BaseComponent';
import ScreenYieldEdit from './ScreenYieldEdit';
import PanelYieldContent from './PanelYieldContent';
import orderActions from '../../actions/orderActions';
import BarOrderInfo from '../common/layout/BarOrderInfo';
import SidebarOrders from '../common/layout/SidebarOrdersCommon';
import _ from 'lodash';
import {MENU_ITEM} from "../../constants/constants";
import {getUnCompletedOrders, getRoleByAuthState} from "../../functions/utils";
import { PAGINATION } from '../../constants/constants';
import * as selectors from '../../selectors/order';
class PageYield extends BaseComponent {
  constructor(props, context) {
    super(props, context);

    this.state = {
      search: '',
      selectedOrder: {},
      styleSelected: {},
      cutTicketSelected: {},
      isEditYield: false,
      completedOrders: [],
      pageSize: PAGINATION.PAGE_SIZE,
      pageNumber: PAGINATION.PAGE_NUMBER,
    };
    this.onSubmitSearch = this.onSubmitSearch.bind(this)
    this.handleSelectOrder = this.handleSelectOrder.bind(this);
  }

  componentWillMount() {
    const {orderState} = this.props;
    const { pageSize, pageNumber } = this.state;
    const options = {pageSize: pageSize, pageNumber: pageNumber, populate: 'styleOrders', isCompleted:false, isCancel:false };
    this.props.orderActions.getListOrder(options);
    this.props.onChangePageNumber(PAGINATION.PAGE_NUMBER);
    const orderItems = getUnCompletedOrders(_.get(orderState, "items", []));
    this.setState({completedOrders: orderItems, selectedOrder: orderItems[0]});
    this.props.onChangeSearch();
  }

  componentWillReceiveProps(nextProps) {
    // edit yield
    let { cutTicketSelected } = this.state;
    if (!_.isEqual(cutTicketSelected, nextProps.cutTicketState.itemYield)) {
      let cutTicket = _.cloneDeep(nextProps.cutTicketState.itemYield) || {};
      this.setState({ cutTicketSelected: cutTicket, isEditYield: !_.isEmpty(cutTicket)});
    }

    if(!_.isEqual(_.get(this.props.orderState, "items",[]), _.get(nextProps.orderState, "items", []))) {
      const orderItems = getUnCompletedOrders(_.get(nextProps.orderState, "items", []));
      this.setState({completedOrders: orderItems, selectedOrder: orderItems[0]});
    }
  }

  getListOrderMore(size, number) {
    const query = { pageSize: size, pageNumber: number, populate: 'styleOrders', isCompleted:false, isCancel:false};
    this.props.getListOrderMore(_.assign(query));
  }

  handleSelectOrder(item) {
    this.setState({ selectedOrder: item || {} });
  }

  selectStyle(style) {
    this.setState({ styleSelected: style || {} });
  }

  handleLoadMore(number) {
    const { isSearch, searchText } = this.props
    const {pageSize, pageNumber } = this.state;
    if (isSearch && searchText == '') { // Search not value 
      this.props.cancelChangeSearch();
      this.props.cancelChangePageNumber();
      this.getListFirstOrder(pageSize, pageNumber);
    } else if (isSearch && searchText) { // Search value
      this.props.onChangePageNumber(number)
      this.getListOrderSearch(pageSize, number)
    } else { // Not search
      this.props.onChangePageNumber(number)
      this.props.cancelChangeSearch()
      this.getListOrderMore(pageSize, number)
    }
  }

  getListFirstOrder(size, number) {
    const query = { pageSize: size, pageNumber: number, populate: 'styleOrders', isCompleted:false, isCancel:false };
    this.props.loadFirstData(_.assign(query));
  }

  getListOrderSearch(size, number) {
    const { searchText } = this.props
    const query = { pageNumber: number, pageSize: size, populate: 'styleOrders',isCompleted:false, isCancel:false, searchKey: searchText, filter: true};
    if (searchText) {
      this.props.getListOrderMore(_.assign(query));
    }
  }

  onSubmitSearch() {
    const { searchText } = this.props
    // const size = this.state.pageSize;
    // const number = this.state.pageNumber;
    const { pageSize, pageNumber } = this.state;
    // pageSize: pageSize, pageNumber: pageNumber, populate: 'styleOrders'
    const querySearch = { pageNumber: pageNumber, pageSize: pageSize, populate: 'styleOrders', isCompleted:false, isCancel:false, searchKey: searchText, filter: true};
    const querySearchNull = { pageNumber: pageNumber, pageSize: pageSize, populate: 'styleOrders', isCompleted:false, isCancel:false};
    this.props.cancelChangePageNumber();
    if (searchText) {
      this.props.changeSearch()
      this.props.loadFirstData(_.assign(querySearch));
    } else {
      this.props.cancelChangeSearch()
      this.props.loadFirstData(_.assign(querySearchNull));
    } 
  }

  render() {
    const pageCurrent = this.props.appState.page || {};
    let {selectedOrder, cutTicketSelected, isEditYield, completedOrders} = this.state;
    // selected
    let showEditPage = isEditYield && !!pageCurrent.isEdit;
    const {showCreate, showEdit, showDelete} = getRoleByAuthState(this.props.authState, MENU_ITEM.yield.key);
    const isSuperAdmin = (this.props.authState && this.props.authState.auth && this.props.authState.auth.isSupperAdmin);
    const { pageNumber } = this.props
    return (
      <div id="content">
        {showEditPage &&
          <ScreenYieldEdit
            {...this.props}
            showCreate={showCreate}
            showEdit={showEdit}
            showDelete={showDelete}
            isSuperAdmin={isSuperAdmin}
            cutTicket={cutTicketSelected || {}}
          />
        }

        <SidebarOrders
          {...this.props}
          selectedOrder={selectedOrder || {}}
          orders={completedOrders || []}
          modal={this.props.modal}
          wrapClass={showEditPage ? 'hide' : ''}
          handleSelectedOrder={this.handleSelectOrder}
          handleLoadMore={(pageNumber) => this.handleLoadMore(pageNumber)}
          onSubmitSearch={this.onSubmitSearch}
        />

        <div className={`content-main ${showEditPage ? 'hide' : ''}`} id="yield">
          <BarOrderInfo order={selectedOrder || {}} />
          <PanelYieldContent
            {...this.props}
            order={selectedOrder || {}}
            showCreate={showCreate}
            showEdit={showEdit}
            showDelete={showDelete}
            isSuperAdmin={isSuperAdmin}
            selectStyle={(style) => this.selectStyle(style)} />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    appState: state.appState,
    authState: state.authState,
    orderState: state.orderState,
    cutTicketState: state.cutTicketState,
    pageNumber: selectors.getPageNumber(state),
    searchText: selectors.getFilterText(state),
    isLoadMore: selectors.isOpenLoadMore(state),
    isSearch: selectors.isSearch(state),
    items: selectors.getOrders(state),
    isLoading: selectors.isLoading(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    orderActions: bindActionCreators(orderActions, dispatch),
    loadFirstData(options) {
      dispatch(orderActions.getListOrder(options));
    },

    getListOrderMore(options) {
      dispatch(orderActions.getListOrderMore(options));
    },

    cancelChangePageNumber() {
      dispatch(orderActions.cancelChangePageNumber());
    },
  
    onChangePageNumber(number) {
      dispatch(orderActions.changePageNumber(number));
    },

    onChangeSearch(text) {
      dispatch(orderActions.changeFilterText(text));
    },

    cancelChangeSearch() {
      dispatch(orderActions.cancelChangeSearch());
    },
  
    changeSearch() {
      dispatch(orderActions.changeSearch());
    },
  };
}

PageYield.propTypes = {
  authState: PropTypes.object.isRequired,
  orderState: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(PageYield);
