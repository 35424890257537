import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import BaseComponent from '../../base/BaseComponent';
import BarOrderInfo from '../../common/layout/BarOrderInfo';
import I18n from 'i18n-js';
import {bindActionCreators} from "redux";
import orderActions from "../../../actions/orderActions";
import _ from 'lodash';
import StyleCutTicket from "../../common/layout/StyleCutTicket";

class View extends BaseComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  componentWillMount() {
    const options = {populate: "styleOrders|cutTickets|colors, styleOrders|cutTickets|store|packingStandard"};
    this.props.orderActions.getDetail(_.get(this.props, "order._id", ""), true, options);
  }

  render() {
    let style = {
      width: "100%"
    };
    const {orderState} = this.props;
    const styleOrders = _.get(orderState, "detail.styleOrders", []);
    return (

      <div id="content">
        <div className="content-main pd-5">
          <BarOrderInfo order={this.props.order}/>
          <div className="history-note">
            <span className="note-label">{I18n.t('PageOrder.Note')}</span>
            <span className="note-content">{_.get(this.props.order, "note", "")}</span>
          </div>
          <div className="order-history-view">
            {
              styleOrders.map(style => {
                return (
                  <StyleCutTicket key={style._id} style={style}/>
                );
              })
            }
          </div>

        </div>
      </div>

    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    appState: state.appState,
    authState: state.authState,
    orderState: state.orderState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    orderActions: bindActionCreators(orderActions, dispatch),
  };
}

View.propTypes = {
  authState: PropTypes.object.isRequired,
  orderActions: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(View);



