import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import BaseComponent from '../../base/BaseComponent';
import ModalConfirm from '../../common/modals/ModalConfirm';
import packingStandardActions from '../../../actions/packingStandardActions';
import TableList from '../../common/datatables/TableList';
import I18n from 'i18n-js';
import appActions from '../../../actions/appActions';
import * as types from '../../../constants/actionTypes'
import {toastr} from 'react-redux-toastr';
import _ from 'lodash';

import * as PATH from '../../../constants/routeConstants';
import { showModal as showImagesGalleryModal } from '../../../actions/customer-po/current/showImagesGallery';
import { isOpen as isShowImagesGalleryModal } from '../../../selectors/customer-po/current/showImagesGallery';
import ImagesGalleryModal from '../../../ui/containers/customer-po/current/ImagesGalleryModal';

import icAddIcon from '../../../stylesheets/images/ic-add.svg';
import CustomPagination from '../../common/datatables/CustomPagination';

class PackingStandard extends BaseComponent {
  constructor(props, context) {
    super(props, context);
    let { roles: {showEdit, showDelete} } = this.props;
    this.state = {
      pageSize: 10,
      pageNumber: 1,
      columns: [
        {name: 'Packing Name', value: 'name'},
        {name: 'Type', value: 'type', options: {clsTh: 'center', clsTd: 'center'}},
        {
          name: 'Folding Way', getValue: (i) => {
          let foldingWay = i.foldingWay || [];
          let photos = _.filter(foldingWay, (fold) => fold.photo).map(el => el.photo);
          return photos.length ? photos[photos.length - 1] : '';
        }, options: {clsTh: 'center', clsTd: 'center'}, type: 'gallery', onClick: (i) => {this.onGalleryClicked(i, 'foldingWay');}
        },
        {
          name: 'Hangtag', getValue: (i) => {
          let hangtag = i.hangtag || [];
          let photos = _.filter(hangtag, (ht) => ht.photo).map(el => el.photo);
          return photos.length ? photos[photos.length - 1] : '';
        }, options: {clsTh: 'center', clsTd: 'center'}, type: 'gallery', onClick: (i) => {this.onGalleryClicked(i, 'hangtag');}
        },
        {
          name: 'Shipping Mark', getValue: (i) => {
          let shippingMark = i.shippingMark || [];
          let photos = _.filter(shippingMark, (sm) => sm.photo).map(el => el.photo);
          return photos.length ? photos[photos.length - 1] : '';
        }, options: {clsTh: 'center', clsTd: 'center'}, type: 'gallery', onClick: (i) => {this.onGalleryClicked(i, 'shippingMark');}
        },

      ],
      actions: [
        () => {
          return showEdit ? {type: 'edit', click: (item) => this.editItem(item)} : {};
        },
        () => {
          return showDelete ? {type: 'delete', click: (item) => this.showDeleteConfirm(item)} : {};
        }
      ],
      items: [],
    };

    this.onDeletePackingStandard = this.onDeletePackingStandard.bind(this);
  }

  componentWillMount() { 
    const options = { pageSize: this.state.pageSize, pageNumber: this.state.pageNumber }
    this.getListItem(options)
  }

  componentWillReceiveProps(newProps) {
    if(newProps.appState.page.loadMore){
      this.props.dispatch({
        type: types.APP.SET_PAGE_CURRENT,
        data: {
          loadMore: false,
        }
      })
      this.getListItem()
    }

    let {items} = this.state;
    !_.isEqual(items, newProps.packingStandardState.items) && this.setState({
      items: _.cloneDeep(newProps.packingStandardState.items) || [],
      total: newProps.packingStandardState.count
    });
    this.props.tabCurrent !== newProps.tabCurrent && this.closeFormAdd();
  }

  showDeleteConfirm(item) {
    let {openModal, closeModal} = this.props.modal;
    let modal = (<ModalConfirm
      title={I18n.t('PageStandard.DELETEPACKINGSTANDARD')}
      confirmMessage={I18n.t('PageStandard.ConfirmDeletePackageStandard')}
      messageOk={I18n.t('Btn.YES')}
      messageCancel={I18n.t('Btn.CANCEL')}
      handleClose={closeModal}
      handleOk={this.onDeletePackingStandard.bind(null, item)}/>);
    openModal(modal);
  }

  onDeletePackingStandard(item) {
    item._id && this.props.packingStandardPropsActions.deleteItem(item._id).then(res => {
      if(res.success) {
        toastr.success(I18n.t('Notify.Success'), 'Delete Success')
        const pageData = _.assign(
          {
            isEdit: false,
            isAdd: false,
            loadMore : true
          }
          );
        this.props.appPropsActions.setPageCurrent(pageData)
      }
    });
  }

  getListItem(query = {}) {
    let {pageSize, pageNumber} = this.state
    let _query = _.assign({
      pageSize: pageSize, 
      pageNumber: pageNumber,
      populate: JSON.stringify([
        {
          path: 'trims.trim',
          select: 'name'
        },
      ]),
    }, query);
    this.props.packingStandardPropsActions.getListPackingStandard(_.set(_query, 'sort', {createdAt: -1}));
  }

  selectPage = (page) => {
    this.setState({pageNumber: page})
    this.props.packingStandardPropsActions.getListPackingStandard({pageSize: this.state.pageSize, pageNumber: page });
  }

  editItem(item) {
    this.props.history.push(`${PATH.STANDARD_PACKINGS_EDIT_URL}/${R.path(['_id'], item)}`);
    // const screen = 'editStandard'
    // const clonePage = {...this.props.appState.page};
    // const pageData = _.assign(_.cloneDeep(clonePage), 
    //   {isEdit: true}, 
    //   {
    //     screen, 
    //     data : item
    //   }
    // );
    // this.props.appPropsActions.setPageCurrent(pageData);
  }

  PackingStandardAdd() {
    const screen = 'createStandard'
      const clonePage = {...this.props.appState.page};
      const pageData = _.assign(_.cloneDeep(clonePage), 
        {isAdd: true}, 
        {
          screen, 
        }
      );
      this.props.appPropsActions.setPageCurrent(pageData);
  }

  onGalleryClicked(item, type) {
    const photos = _.filter(item[type] || [], (fold) => fold.photo).map(el => el.photo);
    this.props.showImagesGallery(photos);
  }

  onOpenGallery() {
    this.setState({isOpenGallery: true});
  }

  onCloseGallery() {
    this.setState({isCloseGallery: false});
  }

  render() {
    // const {tabCurrent} = this.props;
    // let {tabActive} = this.state;
    let { roles: {showCreate} } = this.props;
    let {items, total, columns, actions, pageNumber, pageSize} = this.state;
    let totalPage = _.ceil(total / pageSize);
    // console.log('=====items======', items);
    return (
      <div role="tabpanel" className="tab-pane active" id="standard-packing-property">
        {showCreate && <span onClick={() => {
          this.PackingStandardAdd();
        }} className="cursor-pointer add-standard-packing-property"><img
          src={icAddIcon}/> {I18n.t('PageStandard.AddNew')}</span>}

        <TableList 
          {...this.props} 
          items={items || []} 
          columns={columns || []} 
          actions={actions || []}
          options={{clsTbl: 'global-table box-shadow-none'}}
        />
        <CustomPagination items={totalPage} activePage={pageNumber} onSelect={this.selectPage} />
        {this.props.isShowImagesGalleryModal &&
          <ImagesGalleryModal />
        }
      </div>

    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    ...ownProps,
    authState: state.authState,
    packingStandardState: state.packingStandardState,
    isShowImagesGalleryModal: isShowImagesGalleryModal(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    packingStandardPropsActions: bindActionCreators(packingStandardActions, dispatch),
    appPropsActions: bindActionCreators(appActions, dispatch),
    dispatch,
    showImagesGallery(images) {
      dispatch(showImagesGalleryModal(images));
    },
  };
}

PackingStandard.propTypes = {
  authState: PropTypes.object.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(PackingStandard);
