import Base from './base';

class Trim extends Base {
  constructor () {
    super();

    this.getListTrimDAL = this.getListTrimDAL.bind(this);
    this.addTrimDAL = this.addTrimDAL.bind(this);
    this.editTrimDAL = this.editTrimDAL.bind(this);
    this.getTrimDAL = this.getTrimDAL.bind(this);
    this.getTrimByKeyDAL = this.getTrimByKeyDAL.bind(this);
    this.deleteTrimDAL = this.deleteTrimDAL.bind(this);
  }

  getListTrimDAL (query) {

    return super.execute(this.Urls.trim.list, this.ApiMethod.get, query);
  }

  addTrimDAL (body) {
    return super.execute(this.Urls.trim.create, this.ApiMethod.post, body);
  }

  editTrimDAL (id, body) {
    let url = this.Urls.trim.update.replace('{id}', id);
    return super.execute(url, this.ApiMethod.put, body);
  }

  getTrimDAL (id, options) {
    let url = this.Urls.trim.detail.replace('{id}', id);
    return super.execute(url, this.ApiMethod.get, options);
  }

  getTrimByKeyDAL (key, options) {
    return super.execute(this.Urls.trim.detail + '/key/' + key, this.ApiMethod.get, options);
  }

  deleteTrimDAL (id) {
    let url = this.Urls.trim.delete.replace('{id}', id);
    return super.execute(url, this.ApiMethod.delete, null);
  }
}

export default new Trim();
