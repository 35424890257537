import _ from 'lodash';
import * as R from 'ramda';
import { createSelector } from 'reselect';


export const getFilterText = ({ showListOrders }) => showListOrders.filterText;
export const getNumberPage = ({ showListOrders }) => showListOrders.numberPage;
export const isOpenLoadMore = ({ showListOrders }) => showListOrders.loadMore;
export const isSearch = ({ showListOrders }) => showListOrders.isSearch;
export const isLoading = ({ showListOrders }) => showListOrders.loading;

export const getOrders = ({ showListOrders, showOrderDetail }) => {
  let { orders } = showListOrders;
  const { order } = showOrderDetail;
  // const { filterText } = showListOrders;

  // if (filterText) {
  //   orders = orders.filter(x =>
  //     _.get(x, 'customerPO', '').toLowerCase().includes(filterText.toLowerCase()) || 
  //     _.get(x, 'customer.name', '').toLowerCase().includes(filterText.toLowerCase())
  //   );
  // }

  return _.map(orders, item => ({
    value: R.path(['_id'], item),
    // label: `${_.truncate(R.path(['customerPO'], item), {length: 8})}  -  ${_.truncate(R.path(['customer', 'name'], item), {length: 6})}`,
    label: `${R.path(['customerPO'], item)}  -  ${R.path(['customer', 'name'], item)}`,
    active: R.path(['_id'], order) === R.path(['_id'], item),
    raw: item,
  }));
};

export const getSelectedOrder = ({ showOrderDetail }) => showOrderDetail.order;

const isSuperAdmin = ({ authState }) => {
  const { auth } = authState;
  const isSupperAdmin = R.path(['isSupperAdmin'], auth);
  return isSupperAdmin;
}

const getRoleModule = ({ authState }) => {
  const { auth } = authState;
  const modules = R.pathOr([], ['role', 'modules'], auth);
  const mod = modules.find(it => R.path(['moduleId', 'key'], it) === 'customerPO');
  return mod;
}

export const canAddOrder = createSelector(
  [isSuperAdmin, getRoleModule], 
  (admin, mod) => {
    if (admin) return true;
    if (!mod) return false;
    const addPerm = R.path(['actions', 'create'], mod);
    return addPerm || false;
  }
);

export const canEditOrder = createSelector(
  [isSuperAdmin, getRoleModule], 
  (admin, mod) => {
    if (admin) return true;
    if (!mod) return false;
    const addPerm = R.path(['actions', 'revise'], mod);
    return addPerm || false;
  }
);

export const canDeleteOrder = createSelector(
  [isSuperAdmin, getRoleModule], 
  (admin, mod) => {
    if (admin) return true;
    if (!mod) return false;
    const addPerm = R.path(['actions', 'delete'], mod);
    return addPerm || false;
  }
);
