import React, {Component} from 'react';
import {Store} from '../../../../CssHandleModule/functions';
import I18n from 'i18n-js';
import { connect } from 'react-redux';
import _ from 'lodash';

import * as selector from '../../../../../selectors/ship-out/createShipment';
import {changeUnitPrice} from '../../../../../actions/ship-out/createShipmentActions';

const store = new Store();
store.setStyles({
    orderNumbers: [1, 2, 3, 4],
    aligns: [
        { order: 1, margin: { right: '10px' } },
        { order: 2, margin: { right: '50px' } },
        { order: 3, margin: { top: 27 } },
        { order: 4, margin: { top: -7 } }
    ],
    borders: [
        { order: 1, right: '1px dashed gray' }
    ],
    fonts: [
        { order: 1, color: 'red' }
    ],
    sizes: [
        { order: 1, width: 60 }
    ]
});

class CutTicketContainer extends Component {

    constructor(props) {
        super(props);
        
    }
    
    componentWillReceiveProps(nextProps){
        //Get new items from props
    }

    changeUnitPrice = (orderId, styleId, cutTicketId, colorId, value) => {
        value = _.round(value * 1, 2);
        this.props.changeUnitPrice(orderId, styleId, cutTicketId, colorId, value)
    }

    render() {
        const {orderId, styleId, getListData, disabled} = this.props;
        const data = getListData(orderId, styleId) || [];
        return(
            <div className="bg-white mg-top-10 inline-block w-full mg-top-5">
                <table className="global-table w-full">
                    <tbody>
                        <tr>
                            <th className='center'>{I18n.t('PageShiping.CutTicket')}</th>
                            <th className='center'>{I18n.t('PageShiping.Store')}</th>
                            <th className='center'>{I18n.t('PageShiping.Color')}</th>
                            <th className='center'>{I18n.t('PageShiping.Quantity')}</th>
                            <th className='center'>{I18n.t('PageShiping.Unit')}</th>
                            <th className='center'>{I18n.t('PageShiping.UnitPriceFabric')}</th>
                            <th className='center'>{I18n.t('PageShiping.Amount')}</th>
                            <th className='center'>{I18n.t('PageShiping.UnitPriceCMT')}</th>
                            <th className='center'>{I18n.t('PageShiping.Amount')}</th>
                        </tr>
                        {data.map((item, index) => {
                            return (
                                <tr key = {index}>
                                    <td className='center'>{item.cutTicket || '-'}</td>
                                    <td className='center'>{item.store || '-'}</td>
                                    <td className='center'>{item.color || '-'}</td>
                                    <td className='center'>{item.quantity || '0'}</td>
                                    <td className='center'>{'PCS'}</td>
                                    <td className='center'>{'$'+item.price}</td>
                                    <td className='center color-red'>{'$'+item.amount}</td>
                                    <td className='center w-100'><input disabled = {disabled} className = "form-control" type="number" min = "0"
                                        value = {item.unitPrice || 0}
                                        onChange = {(e) => this.changeUnitPrice(orderId, styleId, item.cutTicketId, item.colorId, e.target.value)}
                                    /></td>
                                    <td className='center color-red'>{'$'+item.unitAmount}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        );
    }
}

const mapStatetoProps = state => ({
    // getListData: selector.fnGetListData(state),
})

const mapDispatchToProps = dispatch => ({
    // changeUnitPrice(orderId, styleId, cutTicketId, colorId, value) {
    //     dispatch(changeUnitPrice(orderId, styleId, cutTicketId, colorId, value))
    // }
})
export default connect(mapStatetoProps, mapDispatchToProps) (CutTicketContainer);