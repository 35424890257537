/* eslint-disable react/no-multi-comp */
import React from 'react';
import PropTypes from 'prop-types';
import { Pagination } from 'react-bootstrap';

const PAGINATE_LENGTH = 8
const CustomPagination = props => {
    const { items, activePage, onSelect, style } = props;
    const prevPage = () => activePage > 1 && onSelect(activePage - 1);
    const nextPage = () => activePage < items && onSelect(activePage + 1);
    const renderItems = () => {
        const els = [];
        for(let i = 1; i <= items; i+=1) {
            const isActive = i === activePage;
            if (i < activePage - (activePage % PAGINATE_LENGTH) || i > activePage + PAGINATE_LENGTH - (activePage % PAGINATE_LENGTH)) { continue }
            els.push(
                <Pagination.Item key={i} onClick={() => onSelect(i)} active={isActive}>{i}</Pagination.Item>
            )
        }
        return els;
    };
    const defaultStyle = { backgroundColor: '#F0F0F0', display: 'flex', flexDirection: 'row-reverse' };
    const PrevElllipsis = () => {
        const isShowed = activePage - PAGINATE_LENGTH >= 1 && items > PAGINATE_LENGTH;
        return isShowed ? <Pagination.Ellipsis onClick={() => onSelect(activePage - PAGINATE_LENGTH)} /> : null;
    }
    const NextElllipsis = () => {
        const isShowed = activePage < items && items > PAGINATE_LENGTH && activePage + PAGINATE_LENGTH <= items;
        return isShowed ? <Pagination.Ellipsis onClick={() => onSelect(activePage + PAGINATE_LENGTH)} /> : null;
    }
    return (
        <div style={{...defaultStyle, ...style}}>
            <Pagination>
                <Pagination.Prev onClick={prevPage}>Previous</ Pagination.Prev>
                <PrevElllipsis />
                {renderItems()}
                <NextElllipsis />
                <Pagination.Next onClick={nextPage}>Next</Pagination.Next>
            </Pagination>
        </div>
    )
};

CustomPagination.propTypes = {
    items: PropTypes.number,
    activePage: PropTypes.number,
    onSelect: PropTypes.func,
    style: PropTypes.instanceOf(Object),
};

export default CustomPagination;