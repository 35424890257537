import React from "react";
import { Link } from 'react-router-dom';
import PropTypes from "prop-types";
import _ from "lodash";
import * as R from "ramda";
import I18n from "i18n-js";

import IMG_DEFAULT from "../../../stylesheets/images/grag-photo.svg";

import icEditXIcon from '../../../stylesheets/images/ic-edit-x.svg';
import icAddIcon from '../../../stylesheets/images/ic-add.svg';
import icEditRIcon from '../../../stylesheets/images/ic-edit-r.svg';
import icEditBgDIcon from '../../../stylesheets/images/ic-edit-bg-d.svg';
import icAddOrderIcon from '../../../stylesheets/images/ic-add-order.svg';

const CutTicketItemList = props => (
  <div>
    {props.canAdd && (
      <div
        style={{bottom: 'auto'}}
        className="wrap-button-add-cut-ticket cursor-pointer text-align-right"
      >
        <span className="icon inline-block w-auto" onClick={() => props.onAddCutTicket()}>
          <img src={icAddIcon} alt="" />
        </span>
        <span className="text inline-block w-auto" onClick={() => props.onAddCutTicket()}>
          {I18n.t("PageOrder.ADDCUTTICKET")}
        </span>
      </div>
    )}
    

    <div className="md content-main-info-number mg-top-45">
      {_.map(props.items || [], item => (
        <div
          key={R.path(["_id"], item)}
          className="box-shadow mg-bottom-5 w-full inline-block box-cut-ticket"
        >
          <ul className="top">
            <li>
              <div className="text">
                <span className="left">{I18n.t("PageOrder.CutTicket")}</span>
                <span className="right size-15 color-black">
                  <span className="medium">{R.path(["name"], item)}</span>
                </span>
              </div>
            </li>
            <li>
              <div className="text">
                <span className="left">{I18n.t("PageOrder.G-Total")}</span>
                <span className="right size-15 color-number">
                  <span className="medium">{R.path(["gTotal"], item)}</span>
                </span>
              </div>
            </li>

            {(props.canEdit || props.canDelete) && (
              <li className="icon">
                {props.canEdit && (
                  <span
                    className="cursor-pointer"
                    onClick={() => props.onEditItemClick(item)}
                  >
                    <img
                      src={icEditXIcon}
                      alt="Edit"
                    />
                  </span>
                )}

                {props.canDelete && (
                  <span
                    className="cursor-pointer"
                    onClick={() => props.onDeleteItemClick(item)}
                  >
                    <img
                      src={icEditRIcon}
                      alt="Delete"
                    />
                  </span>
                )}
              </li>
            )}
          </ul>

          <ul className="center bd-dashed-top bd-solid-bottom content-cut-ticket">
            <li className="img">
              <div className="images">
                <img
                  src={R.pathOr(IMG_DEFAULT, ["imageUrl"], item)}
                  className="img-responsive cursor-pointer"
                  onClick={() => R.path(['imageUrl'], item) ? props.openImageGallery(item) : props.onEditItemImages(item)}
                />
                <span
                  className="cursor-pointer"
                  onClick={() => props.onEditItemImages(item)}
                >
                  <img
                    src={icEditBgDIcon}
                    alt=""
                  />
                </span>
              </div>
            </li>

            <li className="padding-img size-12 list-info-style-order">
              <div className="col-md-6 col-xs-12">
                <div className="text">
                  <span className="left">{I18n.t("PageOrder.Warehouse")}</span>
                  <span className="right">{R.path(["warehouse"], item)}</span>
                </div>
                <div className="text">
                  <span className="left">{I18n.t("PageOrder.Store")}</span>
                  <span className="right">{R.path(["store"], item)}</span>
                </div>
                <div className="text">
                  <span className="left">
                    {I18n.t("PageCrm.PackingStandard")}
                  </span>
                  <span className="right">
                    {R.path(['packingStandard'], item) ? 
                      <Link to={R.path(['editPackingStandardUrl'], item)}>{R.path(["packingStandard"], item)}</Link> : 
                      <span />}
                  </span>
                </div>
                <div className="text">
                  <span className="left">{I18n.t("PageOrder.ShipMode")}</span>
                  <span className="right">{R.path(["shipMode"], item)}</span>
                </div>
              </div>
              <div className="col-md-6 col-xs-12">
                <div className="text">
                  <span className="left">{I18n.t("PageOrder.MainLabel")}</span>
                  <span className="right">{R.path(["mainLabel"], item)}</span>
                </div>
                <div className="text">
                  <span className="left">{I18n.t("PageOrder.IHDate")}</span>
                  <span className="right">{R.path(["ihDate"], item)}</span>
                </div>
                <div className="text">
                  <span className="left">{I18n.t("PageOrder.Note")}</span>
                  <span className="right">{R.path(["note"], item)}</span>
                </div>
              </div>
            </li>
          </ul>

          {R.pathOr([], ["colors"], item).length > 0 && (
            <div className="wrap-icon-edit">
              <ul className="edit">
                {(props.canEdit || props.canDelete) && (
                  <li className="float-right icon icon-edit-delete-cutticket">
                    <span
                      className="cursor-pointer"
                      onClick={() => props.onEditCutTicketColor(item)}
                    >
                      <img
                        src={icEditXIcon}
                        alt="Edit"
                      />
                    </span>
                  </li>
                )}
              </ul>
            </div>
          )}

          <div>
            <ul className="center">
              <li className="not-padding-img padding-img">
                <div className="wrap-table">
                  <table>
                    <tbody>
                      {_.map(R.pathOr([], ["colors"], item), color => (
                        <tr key={R.path(["name"], color)}>
                          <td>
                            <span className="color-number w-40">
                              {R.path(["total"], color)}
                            </span>
                            <span className="medium">
                              {_.truncate(R.path(["name"], color), {
                                length: 15
                              })}
                            </span>
                          </td>
                          <td>
                            {_.map(R.pathOr([], ["sizes"], color), size => (
                              <div
                                key={R.path(["name"], size)}
                                className="itemSize float-left w-70"
                              >
                                <span className="bold">
                                  {R.path(["name"], size)}
                                </span>{" "}
                                :{" "}
                                <span className="regular">
                                  {R.path(["quantity"], size)}
                                </span>
                              </div>
                            ))}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </li>
            </ul>
          </div>

          {R.pathOr([], ["colors"], item).length === 0 && (
            <ul className="grag-photo">
              <li>
                <span
                  onClick={() => props.onAddCutTicketColor(item)}
                  className="cursor-pointer"
                >
                  <img
                    src={icAddOrderIcon}
                    alt=""
                  />{" "}
                  {I18n.t("PageOrder.AddColor")}
                </span>
              </li>
            </ul>
          )}
        </div>
      ))}
    </div>
  </div>
);

CutTicketItemList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  canEdit: PropTypes.bool,
  canDelete: PropTypes.bool,

  onAddCutTicket: PropTypes.func,
  onEditItemClick: PropTypes.func,
  openImageGallery: PropTypes.func,
  onEditItemImages: PropTypes.func,
  onEditCutTicketColor: PropTypes.func,
  onAddCutTicketColor: PropTypes.func
};

CutTicketItemList.defaultProps = {
  canEdit: false,
  canDelete: false,

  onAddCutTicket: f => f,
  onEditItemClick: f => f,
  openImageGallery: f => f,
  onEditItemImages: f => f,
  onEditCutTicketColor: f => f,
  onAddCutTicketColor: f => f
};

export default CutTicketItemList;
