import { createSelector } from 'reselect';
import moment from 'moment';

/**
 * Get a list of filters that can be use to filter ship out
 */
export const getListFilters = () =>  [
    {field: 'SelectFilter', name: 'PageShiping.SelectFilter', type: 'text'},
    {field: 'billNo', name: 'PageShiping.BillNo', type: 'text'},
    {field: 'cont', name: 'PageShiping.Cont', type: 'text'},
    {field: 'date', name: 'PageShiping.Date', type: 'dateTo'},
    {field: 'declarationNo', name: 'PageShiping.DeclarationNo', type: 'text'},
    {field: 'declarationDate', name: 'PageShiping.DeclarationDate', type: 'dateTo'},
    {field: 'shipMode', name: 'PageShiping.ShipMode', type: 'text'},
    {field: 'etd', name: 'PageShiping.ETD', type: 'dateTo'},
    {field: 'eta', name: 'PageShiping.ETA', type: 'dateTo'},
]

export const getSelectedFilter = createSelector(
    [getListFilters, ({ showHistoryList }) => showHistoryList.filter],
    (filters, selectedFilter) => {
        if (!selectedFilter) return filters[0].field;
        return selectedFilter;
    }
)

const genDisplayShipOutViewModel = (model) => {
    if (!model) return {};
    return new Proxy(model, {
        get(obj, prop) {
            const totalFee = obj['totalFee'] * 1;  // Convert to number
            const declarationNo = obj['declarationNo'] * 1;  // Convert to number
            switch (prop) {
                case 'totalFee':
                    return (totalFee > 0) ? totalFee : '-';  // Convert to number
                case 'cont':
                    return obj['cont'];
                case 'declarationNo':
                    return (declarationNo > 0) ? declarationNo : '-';  // Convert to number
                case 'date':
                    return moment(obj['date']).format('MMM DD, YYYY');
                case 'declarationDate':
                    return obj['declarationDate'] ? moment(obj['declarationDate']).format('MMM DD, YYYY') : '-';
                case 'eta':
                    return obj['eta'] ? moment(obj['eta']).format('MMM DD, YYYY') : '-';
                case 'etd':
                    return obj['etd'] ? moment(obj['etd']).format('MMM DD, YYYY') : '-';
                case 'raw':
                    return obj;
                default:
                    return obj[prop];
            }
        },
    });
};

export const getDisplayList = createSelector([
    ({ showHistoryList }) => showHistoryList.displayList || []
], (displayList) => {
    return displayList.map(it => genDisplayShipOutViewModel(it))
});

export const getSearchValue = ({ showHistoryList }) => showHistoryList.searchValue;

export const getCurrentPage = ({ showHistoryList }) => showHistoryList.currentPage;

export const getTotalPages = ({ showHistoryList }) => showHistoryList.totalPages;

export const getSearched = ({showHistoryList}) => showHistoryList.searched;

export const getSearchQuery = (searched, option, selectedFilter, searchValue) => {
    if(!searched) {
        return option;
    }
    if(selectedFilter && searchValue) {
        let op = option;
        let key = selectedFilter;
        if(!searchValue.value) return op;
        if(selectedFilter == 'date' || selectedFilter == 'declarationDate' || selectedFilter == 'etd' || selectedFilter == 'eta') {
            let begin = moment(searchValue.from).format('YYYY-MM-DD');
            let end = moment(searchValue.to).format('YYYY-MM-DD');
            let date = `${begin}:${end}`;
            op[key] = date
        } else {
            op[key] = searchValue.value
        }
        return op;
    }   
}