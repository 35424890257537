import React from 'react';
import CustomerPOList from '../../../components/reportAnalytic/CustomerPOList';
import {
    BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip,
    PieChart, Pie, Cell, ResponsiveContainer
} from 'recharts';
import DateTimeField from 'react-datetime';
import moment from 'moment';

import icDashboardNewIcon from '../../../stylesheets/images/ic-dashboard-new.svg';
import icCompletedIcon from '../../../stylesheets/images/ic-completed.svg';

const customerPOReport = props => {
    const { data, totalNew, totalCompleted, dataPieChart, COLORS, renderCustomizedLabel, searchValue } = props;

    return (
        <div role="tabpanel" className="tab-pane active" id="report-customer">
            <div className="wrap-doughnut-charts w-full inline-block pd-10 ">
                <div className="bg-white box-shadow inline-block w-full">
                    <div className="wrap-filter-search pd-15">
                        <div className="wrap-search from-to">
                            <div className="date-pick-search">
                                <ul>
                                    <li>
                                        <div className="title-date">From:</div>
                                        <div className="wrap-input-date">
                                            <DateTimeField timeFormat={false} closeOnSelect dateFormat="MMM DD, YYYY"
                                                value={moment(searchValue.from || {})}
                                                onChange={date => props.changeSearchText('from', date.format('YYYY-MM-DD'))} />
                                        </div>
                                    </li>
                                    <li>
                                        <div className="title-date">To:</div>
                                        <div className="wrap-input-date">
                                            <DateTimeField timeFormat={false} closeOnSelect dateFormat="MMM DD, YYYY"
                                                value={moment(searchValue.to || {})}
                                                onChange={date => props.changeSearchText('to', date.format('YYYY-MM-DD'))} />
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <button type="submit" onClick={() => props.onSearch(searchValue)} className="transform bt-orange">Search</button>
                        </div>
                    </div>

                    <div className="doughnut-charts inline-block w-full">
                        <div className="question">
                            <div className="question-container">
                                <ResponsiveContainer minHeight={500} minWidth={500}>
                                    <BarChart data={data} width={1500}
                                        margin={{ top: 5, right: 30, left: 20, bottom: 5 }} className="mg-auto">
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="name" />
                                        <YAxis />
                                        <Tooltip />
                                        <Bar barSize={20} dataKey="new" fill="#5780e0" />
                                        <Bar barSize={20} dataKey="completed" fill="#ddd" />
                                    </BarChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                    </div>
                    <div className="wrap-info w-full mg-top-5 inline-block">
                        <div className="box">
                            <div className="info-box">
                                <span className="info-box-icon bg-aqua">
                                    <img
                                        src={icDashboardNewIcon}
                                        alt="" />
                                </span>
                                <div className="info-box-content"><span className="info-box-text size-25">Total New</span><br /><span
                                    className="info-box-number">{totalNew}</span></div>
                            </div>
                        </div>

                        <div className="box">
                            <div className="info-box">
                                <span
                                    className="info-box-icon bg-aqua">
                                    <img
                                        src={icCompletedIcon}
                                        alt="" />
                                </span>
                                <div className="info-box-content"><span className="info-box-text size-25">Total Complete</span><br /><span
                                    className="info-box-number">{totalCompleted}</span></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="wrap-content-table inline-block w-full pd-10" style={{overflow: 'hidden'}}>
                <div className="row">

                    <div className="col-md-8 col-xs-12 left">
                        <CustomerPOList />
                    </div>

                    <div className="col-md-4 col-xs-12 right doughnut-wrap pd-left-0">
                        <div className="box-shadow bg-white">
                            <ul>
                                <li>
                                    <span className="bg-green box" />
                                    <span className="text">On Track</span>
                                </li>
                                <li>
                                    <span className="bg-yellow box" />
                                    <span className="text">Warning</span>
                                </li>
                                <li>
                                    <span className="bg-red box" />
                                    <span className="text">Behind Schedule</span>
                                </li>
                            </ul>
                            <div className="doughnut-charts inline-block w-full" style={{padding: "20px"}}>
                                <PieChart width={320} height={320} onMouseEnter={props.onPieEnter}>
                                    <Pie
                                        data={dataPieChart}
                                        cx={160}
                                        cy={160}
                                        // dataKey = "name"
                                        // valueKey = {'value'}
                                        innerRadius={100}
                                        outerRadius={120}
                                        fill="#8884d8"
                                        label={renderCustomizedLabel}
                                        isAnimationActive={false}
                                        labelLine={false}

                                    >
                                        {
                                            dataPieChart.map((entry, index) => <Cell fill={COLORS[index % COLORS.length]} />)
                                        }
                                    </Pie>
                                    <Tooltip />
                                </PieChart>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default customerPOReport;