// @flow

import I18n from 'i18n-js';

// Enable fallbacks if you want `en-US` and `en-GB` to fallback to `en`
I18n.fallbacks = true;

// English language is the main language for fall back:

let languageCode = I18n.locale.substr(0, 2);
// let languageCode = "vi"

// console.log({languageCode})

I18n.translations[languageCode] = require(`./i18ns/${languageCode}.json`)

