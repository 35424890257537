import React from "react";
import PropTypes from "prop-types";
import TableList from "../../../../components/common/datatables/TableList";
import moment from "moment";
import formatCurrency from 'format-currency';
import _ from "lodash";
import * as utils from "../../../../functions/utils";
import ReactExport from "react-data-export";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const PreviewSupplierOversea = props => {
    const { supplier, columns, creditDebitAmount, previewInvoice } = props;
    const creditDebitType = _.get(props, 'previewInvoice.creditDebit.type', '');
    let nm = '';
    if (supplier && supplier.raw) {
        nm = supplier.raw.name;
    } else if (supplier) {
        nm = supplier;
    }
    let labelName = 'Name :';
    let name = {value: nm, style: {font: {patternType: "solid", color: {rgb: "FFF86B00"}}}};
    if (columns && columns.length > 0) {
        _.forEach(columns, (c) => {
            if (c.name == 'Customner PO') {
                c.value = 'customerPO';
            }
            if (c.name == 'Style') {
                c.value = 'style';
            }
        })
    }
    let labelINVNo = {value: 'INV No : '};
    let totalINVNo = {
        value: props.invoiceNo || '',
        style: {font: {patternType: "solid", color: {rgb: "FFFF0000"}}}
    };
    let labelTotalAdvance = 'Credit/ Debit : ';
    let totalAdvance = {
        value: `${creditDebitType === "Debit" ? "-" : ''}${creditDebitAmount? creditDebitAmount.value : ''}`,
        style: {font: {patternType: "solid", color: {rgb: "FFFF0000"}}}
    };

    let labelIssuedDate = 'Issued Date : ';
    console.log(typeof props.issuedDate, props.issuedDate, moment(props.issuedDate))
    let totalIssuedDate = {
        value: moment(typeof props.issuedDate === 'number' ? new Date(props.issuedDate).toISOString() : props.issuedDate).format("MMM DD, YYYY"),
        style: {font: {patternType: "solid", color: {rgb: "FFFF0000"}}}
    };

    let labelAmount = 'Amount : ';
    let totalAmount = {
        value: formatCurrency(props.amount) + ' VND',
        style: {font: {patternType: "solid", color: {rgb: "FFFF0000"}}}
    };

    let labelTotalRemaining = 'Total Amount INV : ';
    let totalRemaining = {
        value: formatCurrency(props.totalAmountINV) + ' VND',
        style: {font: {patternType: "solid", color: {rgb: "FFFF0000"}}}
    };

    let header1 = [labelName, name, labelINVNo, totalINVNo, labelTotalAdvance, totalAdvance]
    let header2 = [labelIssuedDate, totalIssuedDate, labelTotalRemaining, totalRemaining, labelAmount, totalAmount];
    let putData = [];

    if (previewInvoice && previewInvoice.items && previewInvoice.items.length > 0) {
        _.map(previewInvoice.items, (el, index) => {
            let keys = [
                {key: 'index', value: index + 1, color: '00000000'},
                {key: 'string', value: 'purchaseNo', color: '00000000'},
                {key: 'string', value: 'material', color: '00000000'},
                {key: 'string', value: 'content', color: '00000000'},
                {key: 'string', value: 'color', color: '00000000'},
                {key: 'string', value: 'size', color: '00000000'},
                {key: 'string', value: 'payer', color: '00000000'},
                {key: 'date', value: 'eta', color: '00000000'},
                {key: 'number', value: 'orderQuantity', color: '00000000'},
                {key: 'number', value: 'receivedQuantity', color: '00000000'},
                {key: 'string', value: 'invNo', color: '00000000'},
                {key: 'number', value: 'priceText', color: '00000000'},
                {key: 'number', value: 'amount', color: '00000000'}
            ];

            putData.push(utils.mapDataForCell(el, keys));
        });
    }
    let headerTable = ['Number', 'Customner PO', 'Material', 'Content', 'Color', 'Size', 'Payer', 'ETA', 'Order Quantity',
        'Received Quantity', 'INV No', 'Price', 'Amount'];
    let dataHeaderTable = [];
    for(let i = 0; i < headerTable.length ; i++){
        dataHeaderTable.push({
            value: headerTable[i],
            style: utils.genStyleHeaderForCell('00000000')
        });
    }
    putData.unshift(dataHeaderTable);
    const multiDataSet = [
        {
            columns: [
                {title: '', width: {wch: 20}},//pixels width
                {title: '', width: {wch: 30}},//char width
                {title: '', width: {wch: 20}},
                {title: '', width: {wch: 30}},
                {title: '', width: {wch: 20}},//pixels width
                {title: '', width: {wch: 30}},//char width
            ],
            data: [header1]
        },
        {
            columns: [
                {title: '', width: {wch: 20}},//pixels width
                {title: '', width: {wch: 30}},//char width
                {title: '', width: {wch: 20}},
                {title: '', width: {wch: 30}},
                {title: '', width: {wch: 20}},//pixels width
                {title: '', width: {wch: 30}},//char width
            ],
            data: [header2]
        },
        {
            xSteps: 0, // Will start putting cell with 1 empty cell on left most
            ySteps: 0, //will put space of 5 rows,
            columns: [],
            data: putData
        }
    ];
    let nameFile = '';
    let d = new Date();
    if(props.invoiceNo){
        nameFile = 'INV_' + props.invoiceNo + '_' + (Number(d.getMonth()) + 1 < 10 ? "0" + (Number(d.getMonth()) + 1) : Number(d.getMonth()) + 1)  + '_' + d.getFullYear();
    }else{
        nameFile = 'INV_' +  (Number(d.getMonth()) + 1 < 10 ? "0" + (Number(d.getMonth()) + 1) : Number(d.getMonth()) + 1)  + '_' + d.getFullYear();
    }

    return (
        <div>
            <div className="mg-left-15 mg-right-15">
                <div className="row ">
                    <ul className="content-header-title float-left pd-0-5">
                        <li style={{float: 'left', width: '33.333%'}}>
                            <p>Name
                                <span className="color-orange medium mg-left-25 size-16">{nm}</span>
                            </p>
                        </li>
                        <li style={{float: 'left', width: '33.333%'}}>
                            <p>INV No
                                <span className="color-number medium mg-left-25 size-16">{(props.invoiceNo)}</span>
                            </p>
                        </li>
                        <li style={{float: 'left', width: '33.333%'}}>
                            <p>Credit/ Debit
                                <span className="color-number medium mg-left-25 size-16">{`${creditDebitType === "Credit" ? "-" : ''}${creditDebitAmount? creditDebitAmount.value: ''}`}</span>
                            </p>
                        </li>
                        
                    </ul>
                </div>
                <div className="row">
                    <ul className="content-header-title float-left pd-0-5" style={{height: 'auto'}}>
                        <li style={{float: 'left', width: '33.333%'}}>
                            <p>Issued Date
                                <span className="color-number medium mg-left-25 size-16">{moment(props.issuedDate).format("MMM DD, YYYY")}</span>
                            </p>
                        </li>
                        <li style={{float: 'left', width: '33.333%'}}>
                            <p>Amount
                                <span className="color-number medium mg-left-25 size-16">{props.amount}</span>
                            </p>
                        </li>
                        <li style={{float: 'left', width: '23.333%'}}>
                            <p>Total Amount INV
                                <span className="color-number medium mg-left-25 size-16">{(props.totalAmountINV)}</span>
                            </p>
                        </li>
                        <li className="add-filter-top">
                            <ExcelFile filename={nameFile}
                                       element={
                                           <span className="cursor-pointer">
                                <i className="fa fa-floppy-o" aria-hidden="true"></i>{"Export"}
                            </span>
                                       }>
                                <ExcelSheet dataSet={multiDataSet}  name={nameFile}/>
                            </ExcelFile>

                        </li>
                    </ul>
                </div>
                
            </div>
            <div className="pd-5">
                <TableList items={(previewInvoice && previewInvoice.items) ?  previewInvoice.items : []} columns={columns || []} options={{ clsTbl: 'global-table' }} />
            </div>
        </div>
    )
}
PreviewSupplierOversea.propTypes = {
    items: PropTypes.array,
    columns: PropTypes.array,
    listDeclaration: PropTypes.array,
    name: PropTypes.string,
    totalAmount: PropTypes.number,
    totalAdvance: PropTypes.number,
    totalRemaining: PropTypes.number
}

PreviewSupplierOversea.defaultProps = {

}
export default PreviewSupplierOversea