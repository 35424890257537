import { createPayInfo, uploadFile, getListPayInfo, getPayInfo, updatePayInfo } from './../api/payInfoApi';
import _ from 'lodash';

class payInfoServices {
    
    createPayInfo(data, files) {
        if(files && files.length) {
            let promises = [];
            promises = _.map(files, i => {
                return i.file
                  ? uploadFile(i.file).then(res => _.get(res || {}, "data.filePath", ""))
                  : Promise.resolve(i);
            });
            return Promise.all(promises)
            .then(documents => {
                let docs  = (documents || []).filter(i => !!i);
                data.documents = data.documents ? data.documents.concat(docs) : docs;
                return createPayInfo(data);
            })
            .catch(error => {
                throw error;
            });
        } else {
            return createPayInfo(data);
        }
        
    }

    getListPayInfo(options) {
        return getListPayInfo(options).then(res => {
            return res;
        })
    }

    getPayInfo(id) {
        return getPayInfo(id).then(res => {
            return res;
        })
    }

    updatePayInfo(data, files) {
        if(data._id) {
            if(files && files.length) {
                let promises = [];
                promises = _.map(files, i => {
                    return i.file
                      ? uploadFile(i.file).then(res => _.get(res || {}, "data.filePath", ""))
                      : Promise.resolve(i);
                });
                return Promise.all(promises)
                .then(documents => {
                    let docs  = (documents || []).filter(i => !!i);
                    data.documents = data.documents ? data.documents.concat(docs) : docs;
                    return updatePayInfo(data._id, data);
                })
                .catch(error => {
                    throw error;
                });
            } else {
                return updatePayInfo(data._id, data);
            }
        }
    }
}

export default new payInfoServices;