import { combineReducers } from "redux";
import C from "../../../constants/accounting/payable/createInvoiceSupplier";
import _ from "lodash";
import moment from 'moment';
/**
 * Store helper information to check whether selectable suppliers list is fetching...
 */
const fetchingSelectableSuppliers = (state = false, action) => {
  const { payload, type } = action;
  switch (type) {
    // TODO. handle related actions
    default:
      return state;
  }
};

/**
 * INV No of OVERSEA
 * payload = {purchaseOrderId,materalId,value}
 */
const invNoOfPurchaseOrder = (state = false, action) => {
  const { payload, type } = action;
  switch (type) {
    case C.INV_NO:
      return payload;
    default:
      return state;
  }
};

/**
 * Store a list of selectable suppliers
 */
const selectableSuppliers = (state = [], action) => {
  const { payload, type } = action;
  switch (type) {
    case C.CHANGE_LIST_SUPPLIERS:
      return payload;
    default:
      return state;
  }
};

/**
 * Store a selected supplier
 */
const selectedSupplier = (state = null, action) => {
  const { payload, type } = action;
  switch (type) {
    case C.CHANGE_SELECTED_SUPPLIER:
      return payload;
    default:
      return state;
  }
};

/**
 * Store a selected type supplier
 */
const selectedTypeSupplier = (state = null, action) => {
  const { payload, type } = action;
  switch (type) {
    case C.CHANGE_TYPE_SUPPLIERS:
      return payload;
    default:
      return state;
  }
};

/**
 * Store input information for invoice no field
 */
const invoiceNo = (state = null, action) => {
  const { payload, type } = action;
  switch (type) {
    case C.CHANGE_INVOICE:
      return payload;
    default:
      return state;
  }
};

/**
 * Store input information for issued date field
 */
const issuedDate = (state = moment().format(), action) => {
  const { payload, type } = action;
  switch (type) {
    case C.CHANGE_ISSUED_DATE:
      return payload;
    default:
      return state;
  }
};

/**
 * Store input information for due date field
 */
const dueDate = (state = moment().format(), action) => {
  const { payload, type } = action;
  switch (type) {
    case C.CHANGE_DUE_DATE:
      return payload;
    default:
      return state;
  }
};

/**
 * Store a list of selectable credit debit notes
 */
const selectableCreditDebits = (state = [], action) => {
  const { payload, type } = action;
  switch (type) {
    case C.CHANGE_LIST_CARD_CREDIT:
      return payload;
    default:
      return state;
  }
};

/**
 * Store a helper information to check whether credit debit notes data is fetching...
 */
const fetchingSelectableCreditDebitNotes = (state = false, action) => {
  const { payload, type } = action;
  switch (type) {
    // TODO. handle related actions
    default:
      return state;
  }
};

/**
 * Store a selected credit debit note field
 */
const selectedCreditDebit = (state = null, action) => {
  const { payload, type } = action;
  switch (type) {
    case C.CHANGE_SELECT_CARD_CREDIT:
      return payload;
    default:
      return state;
  }
};

/**
 * Store input information for factor field
 */
const factor = (state = null, action) => {
  const { payload, type } = action;
  switch (type) {
    case C.CHANGE_FACTOR:
      return payload;
    default:
      return state;
  }
};

/**
 * Store helper information to check whether list of purchase orders is fetching from api
 */
const fetchingPurchaseOrders = (state = false, action) => {
  const { payload, type } = action;
  switch (type) {
    // TODO. handle related actions
    default:
      return state;
  }
};

/**
 * Store a list of purchase orders that will be used to check in side menu
 */
const purchaseOrders = (state = [], action) => {
  const { payload, type } = action;
  switch (type) {
    case C.CHANGE_PURCHASE_ORDERS:
      payload.map(v => {
        v.materials.map(imaterial => {
          imaterial.amount = imaterial.quantity * imaterial.price;
        });
      });
      return payload;
    case C.CHANGE_SELECTED_SUPPLIER:
      return [];
    case C.CHANGE_PURCHASE_INVOICE_NO:
      const {purchaseId, materialId, index, value} = payload;
      let dic = _.cloneDeep(state);
      dic.map(item => item._id === purchaseId && item.materials.map((it, idx) => (it._id === materialId && idx === index) ? it.invNo = value : null));
      return dic;
    case C.CHANGE_SEARCH_TEXT: {
        let copyState = _.cloneDeep(state);
        if (!payload) {
            copyState = _.map(copyState, (el) => { el.hide = false; return el });
            return copyState;
        }
        const rex = new RegExp(`.*${payload}.*`)
        copyState = _.map(copyState, (el) => {
            let rs = rex.test(_.lowerCase(_.get(el, 'purchaseNo', '')));
            el.hide = !rs;
            return el;
        })
        return copyState;
    }
    default:
      return state;
  }
};

/**
 * Store a list of checked purchase orders
 */
const checkedPurchaseOrders = (state = [], action) => {
  const { payload, type } = action;
  switch (type) {
    case C.TOGLE_PURCHASE_ORDERS: {
      const copyState = _.clone(state);
      let find = _.find(copyState, it => it === payload);
      !find ? copyState.push(payload) : _.remove(copyState, n => {
            return n == payload;
          });
      return copyState;
    }
    case C.CHANGE_SELECTED_SUPPLIER:
      return [];
    default:
      return state;
  }
};

/**
 * Store a list of active purchase orders
 */
const activePurchaseOrders = (state = [], action) => {
  const { payload, type } = action;
  switch (type) {
    case C.TOGLE_PURCHASE_ORDERS: {
        const copyState = _.clone(state);
        let find = _.find(copyState, it => it === payload);
        !find ? copyState.push(payload) : _.remove(copyState, n => {
            return n == payload;
        });
        return copyState;
    }
    case C.TOGLE_ACTIVE_PURCHASE_ORDERS: {
      const copyState = _.clone(state);
      let find = _.find(copyState, it => it === payload);
      !find ? copyState.push(payload) : _.remove(copyState, n => {
          return n == payload;
      });
      return copyState;
  }
    case C.CHANGE_SELECTED_SUPPLIER:
      return [];
    default:
      return state;
  }
};

/**
 * Store isVATPerMonth for check box that only usable for domestic type
 */
const isVATPerMonth = (state = true, action) => {
  const { payload, type } = action;
  switch (type) {
    case C.TOGLE_VAT_PER_MOUNTH:
      return payload;
    default:
      return state;
  }
};

/**
 * Store a list of convertable currencies that used for select convert currency
 */
const selectableConvertCurrencies = (state = ["USD", "VND"], action) => {
  const { payload, type } = action;
  switch (type) {
    case C.CHANGE_CONVERT_CURRENCY:
      return payload;
    default:
      return state;
  }
};

/**
 * Store selected convert currency
 */
const selectedConvertCurrency = (state = "", action) => {
  const { payload, type } = action;
  switch (type) {
    case C.CHANGE_SELECTED_CURRENCY:
      return payload;
    default:
      return state;
  }
};

/**
 * Store input information for convert rate field
 */
const convertRate = (state = "1.0", action) => {
  const { payload, type } = action;
  switch (type) {
    case C.CHANGE_CONVERT_RATE:
      return payload;
    default:
      return state;
  }
};

/**
 * Store input information for VAT number field at the main VAT card
 */
const mainVATNo = (state = null, action) => {
  const { payload, type } = action;
  switch (type) {
    case C.CHANGE_MAIN_VAT_NO:
      return payload;
    default:
      return state;
  }
};

/**
 * Store input information for VAT date field at the main VAT card
 */
const mainVATDate = (state = moment().format(), action) => {
  const { payload, type } = action;
  switch (type) {
    case C.CHANGE_MAIN_VAT_DATE:
      return payload;
    default:
      return state;
  }
};

/**
 * Store input information for TAX rate field at the main VAT card
 */

const mainTaxRate = (state = null, action) => {
  const { payload, type } = action;
  switch (type) {
    case C.CHANGE_MAIN_TAX_RATE:
      try{
          return Number(payload)
      }catch (e) {
          return state;
      }
    default:
      return state;
  }
};

/**
 * Store input information for Total amount field at the main VAT card
 */
const mainTotalAmount = (state = null, action) => {
  const { payload, type } = action;
  switch (type) {
    case C.CHANGE_MAIN_TOTAL_AMOUNT:
      return payload;
    default:
      return state;
  }
};

/**
 * Store input information for Sub VAT cards in each purchase orders
 * only use if isVATPerMonth is false
 * @param state {[key]: {vatNo: Any, vatDate: Date, taxRate: Number, note: Any}}
 * key will be indexing which is purchase order id as well
 */
const subVATNo = (state = {}, action) => {
  const { payload, type } = action;
  switch (type) {
    case C.CHANGE_SUB_VAT_NO:{
      let copyState = _.clone(state);
      copyState[payload._id] = payload.value;
      return copyState;
    }
    default:
      return state;
  }
};

const subVATDate = (state = {}, action) => {
  const { payload, type } = action;
  switch (type) {
    case C.CHANGE_SUB_VAT_DATE: {
      const copyState = _.clone(state);
      copyState[payload._id] = payload.value;
      return copyState;
    }
    default:
      return state;
  }
};

const subNote = (state = {}, action) => {
  const { payload, type } = action;
  switch (type) {
    case C.CHANGE_SUB_NOTE:{
      let copyState = _.clone(state);
      copyState[payload._id] = payload.value;
      return copyState;
    }
    default:
      return state;
  }
};

const subTaxRate = (state = {}, action) => {
  const { payload, type } = action;
  switch (type) {
    case C.CHANGE_SUB_TAX_RATE: {
      let copyState = _.clone(state);
      copyState[payload._id] = payload.value;
      return copyState;
    }
    default:
      return state;
  }
};

/**
 * Store a helper information to check whether informations is post to api for saving...
 */
const success = (state = false, action) => {
  const { payload, type } = action;
  switch (type) {
    case C.SUCCESS:
      return payload;
    case C.RESET_DATA:
      return false;
    default:
      return state;
  }
};

/**
 * Store input information for error messages when input date
 */
const errorsMessage = (state = {}, action) => {
  const { payload, type } = action;
  switch (type) {
    case C.CHANGE_ERROR_MESSAGE:
      return payload;
    case C.RESET_DATA:
      return {};
    default:
      return state;
  }
};

const activeScreenPreview = (state = false, action) => {
  const {type, payload} = action;
  switch(type) {
    case C.ACTIVE_SCREEN_PREVIEW_STATUS:
      return payload;
    default:
      return state;
  }
}

const createPayableInvoiceSupplier = combineReducers({
  fetchingSelectableSuppliers,
  selectableSuppliers,
  selectedSupplier,
  selectedTypeSupplier,

  invoiceNo,
  issuedDate,
  dueDate,

  fetchingSelectableCreditDebitNotes,
  selectableCreditDebits,
  selectedCreditDebit,
  factor, // Only use for oversea type
  isVATPerMonth, // Only use for domestic type
  invNoOfPurchaseOrder,

  fetchingPurchaseOrders,
  purchaseOrders,
  checkedPurchaseOrders,
  activePurchaseOrders,

  // Converate card, only use for domestic type
  selectableConvertCurrencies,
  selectedConvertCurrency,
  convertRate,

  // Main VAT card, only use for domestic type
  mainVATNo,
  mainVATDate,
  mainTaxRate,
  mainTotalAmount,

  // VAT input informations for each checked purchase orders,
  // use only for domestic type and isVATPerMonth is false
  subVATNo,
  subVATDate,
  subNote,
  subTaxRate,

  success,
  errorsMessage,
  activeScreenPreview
});


export default (state, action) => {
  const { type } = action;
  if (type === C.RESET_DATA) {
    return {};
  }
  return createPayableInvoiceSupplier(state, action);
};

