import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import moment from 'moment';
import FormGroup from '../../forms/FormGroup';
import Input from '../../forms/Input';
import SidebarItemList from '../SidebarItemList';
import SearchSelect from '../../forms/SearchSelect';
import ConvertCurrencyCard from '../ConvertAmountCard';
import MainVATCard from '../MainVATCard';
import SubVATCard from '../payable/SubVATCard';
import _ from 'lodash';
const formatCurrency = require('format-currency');
const DateTimeField = require('react-datetime');

const EditInvoiceSupplierDomestic = props => {
  const {
    amountConvert,
    totalAmountConvert,
    disableChangeValue,
    vatValue,
    dataApi,
    constSupplierName = '',
    issuedDate,
    dueDate,
    totalAmount,
    paid,
    remaining,
    paymentType = 'cash',
    currencyTypeUSD,
    currencyTypeVND,
    emptyReceipt,
    receiptFromInvoice,
  } = props;
  // console.log(props.editablePurchaseOrders)
  return (
    <div>
      <ul className="content-header-title float-none pd-0-5 justify-content-space-between display-flex">
        <li>
          <p>
            Supplier
            <span className="color-orange medium mg-left-25 size-16">{constSupplierName}</span>
          </p>
        </li>
        <li className="add-filter-top">
          <span className="cursor-pointer" onClick={() => props.save(paymentType, dataApi)}>
            <i className="fa fa-floppy-o" aria-hidden="true"></i>
            {'SAVE'}
          </span>
        </li>
      </ul>
      <div className="wrap-input-global pd-15-0 margin-small bg-white page-edit-invoice-list box-shadow">
        <div className="pd-0 col-xs-9 left">
          <div className="col-md-6 col-xs-12 input">
            <FormGroup
              label="Supplier"
              type="input"
              inputComponent={
                <Input
                  type="text"
                  value={props.supplier}
                  onChange={e => props.changeSupplier(e.target.value)}
                  placeholder=""
                />
              }
            />
          </div>
          <div className="col-md-6 col-xs-12 input">
            <FormGroup
              type="input"
              label="Issued Date"
              inputComponent={
                <DateTimeField
                  timeFormat={false}
                  closeOnSelect
                  dateFormat="MMM DD, YYYY"
                  value={moment(issuedDate)}
                  onChange={date => props.changeIssuedDate(date.format())}
                />
              }
            />
          </div>
          <div className="col-md-6 col-xs-12 input">
            <FormGroup
              type="input"
              label="Invoice No."
              inputComponent={
                <Input
                  type="text"
                  value={props.invoiceNo}
                  onChange={e => props.changeInvoiceNo(e.target.value)}
                  placeholder=""
                />
              }
            />
          </div>
          <div className="col-md-6 col-xs-12 input">
            <FormGroup
              type="input"
              label="Due Date"
              inputComponent={
                <DateTimeField
                  timeFormat={false}
                  closeOnSelect
                  dateFormat="MMM DD, YYYY"
                  value={moment(dueDate)}
                  onChange={date => props.changeDueDate(date.format())}
                />
              }
            />
          </div>
          <div className="col-md-6 col-xs-12 input">
            <FormGroup
              type="input"
              label="Credit/Debit"
              inputComponent={
                <SearchSelect
                  options={props.selectableCredits}
                  value={props.selectedCredit || ''}
                  onChange={o => props.changeCreditDebit(o)}
                  // isLoading={props.fetchingSelectableCredits}
                  clearable
                />
              }
            />
          </div>
          <div className="col-md-6 col-xs-12 input">
            <FormGroup
              type="input"
              label="Payment Amount"
              inputComponent={
                <Input
                  type="number"
                  min="0"
                  value={props.payAmount}
                  onChange={e => props.changePayAmount(e.target.value, remaining, paid)}
                  placeholder=""
                />
              }
            />
          </div>
        </div>
        <div className="pd-0 col-xs-3 right">
          <ul className="type-supplier">
            <li
              onClick={() => props.changePaymentType('cash')}
              className={paymentType === 'cash' ? 'active' : 'cursor-pointer'}>
              <span className="cursor-poniter">
                <span className="icon">
                  <span />
                </span>
                <span className="text">Cash</span>
              </span>
            </li>

            <li
              onClick={() => props.changePaymentType('transfer')}
              className={paymentType === 'transfer' ? 'active' : 'cursor-pointer'}>
              <span className="cursor-poniter">
                <span className="icon">
                  <span />
                </span>
                <span className="text">Transfer</span>
              </span>
            </li>
          </ul>
          {paymentType === 'transfer' && (
            <span>
              <div className="mg-right-15">
                <span className="color-link text-decoration cursor-pointer" onClick={() => props.openTransferModal()}>
                  Upload File
                </span>
              </div>
              <p className="text-align-left mg-bottom-15">Files: {(dataApi.files || []).length}</p>
            </span>
          )}
          {paymentType === 'cash' && (
            <span>
              {emptyReceipt && (
                <div className="mg-right-15">
                  <span className="color-link text-decoration cursor-pointer" onClick={() => props.openReceiptModal()}>
                    Add Receipt
                  </span>
                </div>
              )}
              {!emptyReceipt && (
                <p className="text-align-left mg-bottom-15">
                  <span
                    href=""
                    className="color-link text-decoration size-12 cursor-pointer"
                    onClick={() => props.openReceiptModal()}>
                    {receiptFromInvoice.no}
                  </span>
                </p>
              )}
            </span>
          )}
          <div className="text-align-left mg-bottom-5">
            <p>
              <span className="mg-right-15 size-12">Total Amount INV</span>
              <span className="bold color-number size-14">
                {currencyTypeUSD}
                {formatCurrency(totalAmount)} {currencyTypeVND}
              </span>
            </p>
          </div>

          <div className="text-align-left mg-bottom-5">
            <p>
              <span className="mg-right-15 size-12">Paid</span>
              <span className="bold size-14">
                {currencyTypeUSD}
                {formatCurrency(paid)} {currencyTypeVND}
              </span>
            </p>
          </div>

          <div className="text-align-left mg-bottom-5">
            <p>
              <span className="mg-right-15 size-12">Remaining</span>
              <span className="bold size-14 color-green">
                {currencyTypeUSD}
                {formatCurrency(remaining)} {currencyTypeVND}
              </span>
            </p>
          </div>
        </div>
      </div>
      <div className="content-main-accounting">
        <SidebarItemList
          searchText={''}
          disableChangeValue={disableChangeValue}
          changeSearchText={text => props.changeFilterPOText(text)}
          items={props.listCheckablePurchaseOrder}
          changeItemChecked={(item, checked) => props.changeCheckedPurchaseOrder(item, checked)}
        />
        <div id="header-plan-accounts-payable">
          <ConvertCurrencyCard
            disableChangeValue={disableChangeValue}
            selectableCurrencies={props.selectableConvertCurrencies}
            selectedCurrency={props.selectedConvertCurrency}
            rate={props.convertRate}
            amount={amountConvert}
            totalAmount={totalAmountConvert}
            changeSelectedCurrency={o => props.changeSelectedConvertCurrency(o)}
            changeRate={value => props.changeConvertRate(value)}
          />
        </div>
        {!_.isEmpty(vatValue) && (
          <MainVATCard
            disableChangeValue={disableChangeValue}
            vatNo={vatValue.vatNo}
            vatDate={vatValue.vatDate}
            totalAmount={vatValue.totalAmount}
            vatAmount={currencyTypeUSD + vatValue.vatAmountText + ' ' + currencyTypeVND}
            grandTotal={currencyTypeUSD + vatValue.grandTotalVATText + ' ' + currencyTypeVND}
            taxRate={vatValue.taxRate}
            changeVATNo={value => props.changeMainVATNo(value)}
            changeTotalAmount={value => props.changeMainVATTotalAmount(value)}
            changeVATDate={value => props.changeMainVATDate(value)}
            changeTaxRate={value => props.changeMainVATTaxRate(value)}
          />
        )}
        <div className="panel-group mg-0" id="panel-heading-main" role="tablist" aria-multiselectable="true">
          {_.map(props.editablePurchaseOrders, (item, index) => (
            <div className="panel panel-default bd-none radius-0" key={index}>
              {/* <!-- Component --> */}

              <div className="panel-heading component" role="tab" id="">
                <div className="panel-title">
                  <a role="button" onClick={() => props.toggleActivePurchaseOrder(item._id, !item.active)}>
                    <i className="fa fa-caret-down" aria-hidden="true" />
                  </a>
                  <ul>
                    <li>
                      <div className="text">
                        <span className="left">Purchase Order</span>
                        <span className="right size-15">
                          <span className="medium">{R.path(['purchaseNo'], item.purchaseOrder)}</span>
                        </span>
                      </div>
                    </li>
                    <li>
                      <div className="text mg-0">
                        <span className="left">Total Purchase</span>
                        <span className="right">
                          <span className="medium">
                            {currencyTypeUSD}
                            {formatCurrency(R.path(['totalPurchase'], item))} {currencyTypeVND}
                          </span>
                        </span>
                      </div>
                    </li>
                    <li>
                      <div className="text mg-0">
                        <span className="left">IH Date</span>
                        <span className="right">
                          <span className="medium">{R.path(['ihDate'], item.purchaseOrder)}</span>
                        </span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

              {R.path(['active'], item) && (
                <div
                  id={R.path(['_id'], item)}
                  className="panel-collapse collapse in"
                  role="tabpanel"
                  aria-labelledby={R.path(['_id'], item)}>
                  <div className="panel-body pd-0 bg-gray">
                    {_.isEmpty(vatValue) && (
                      <SubVATCard
                        vatNo={R.path(['vatNo'], item.vat)}
                        totalAmount={`${
                          currencyTypeUSD + formatCurrency(R.path(['totalAmount'], item.vat)) + ' ' + currencyTypeVND
                        }`}
                        vatDate={R.path(['vatDate'], item.vat)}
                        vatAmount={`${
                          currencyTypeUSD + formatCurrency(R.path(['vatAmount'], item.vat)) + ' ' + currencyTypeVND
                        }`}
                        taxRate={R.path(['taxRate'], item.vat)}
                        grandTotal={`${
                          currencyTypeUSD + formatCurrency(R.path(['grandTotalVAT'], item.vat)) + ' ' + currencyTypeVND
                        }`}
                        note={R.path(['note'], item.vat)}
                        changeVATNo={value => props.changeSubVATNo(item._id, value)}
                        changeVATDate={value => props.changeSubVATDate(item_.id, value)}
                        changeTaxRate={value => props.changeSubVATTaxRate(item._id, value)}
                        changeNote={value => props.changeSubVATNote(item._id, value)}
                      />
                    )}

                    <div className="wrap-global-table pd-0 inline-block w-full float-left mg-bottom-0">
                      <table className="global-table box-shadow-none w-full">
                        <tbody>
                          <tr className="bg-white bd-solid-bottom">
                            <th>Material</th>
                            <th className="center">Order Quantity</th>
                            <th className="center">Received Quantity</th>
                            <th className="center">Received Date</th>
                            <th className="center">Unit</th>
                            <th className="center">Price</th>
                            <th className="center">Amount</th>
                          </tr>

                          {_.map(R.path(['materials'], item), (m, idx) => (
                            <tr key={idx}>
                              <td>{R.path(['name'], m.material)}</td>
                              <td className="center">{R.path(['orderQuantity'], m)}</td>
                              <td className="center">{R.path(['receivedQuantity'], m)}</td>
                              <td className="center">{moment(R.path(['exReceivedDate'], m)).format('MMM DD, YYYY')}</td>
                              <td className="center">{R.path(['unit'], m)}</td>
                              <td className="center">
                                {currencyTypeUSD}
                                {formatCurrency(R.path(['price'], m))} {currencyTypeVND}
                              </td>
                              <td className="center color-number">
                                {currencyTypeUSD}
                                {formatCurrency(R.pathOr(0, ['amount'], m))} {currencyTypeVND}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

EditInvoiceSupplierDomestic.propTypes = {
  constSupplierName: PropTypes.string,

  supplier: PropTypes.string,
  issuedDate: PropTypes.string,
  dueDate: PropTypes.string,
  invoiceNo: PropTypes.string,
  paymentAmount: PropTypes.number,
  creditDebit: PropTypes.string,
  listCreditDebit: PropTypes.array,
  listCurrencies: PropTypes.array,
  currency: PropTypes.string,
  amount: PropTypes.Number,
  paid: PropTypes.Number,
  remaining: PropTypes.Number,
  paymentType: PropTypes.string,

  currencyTypeUSD: PropTypes.string,
  currencyTypeVND: PropTypes.string,
  selectableConvertCurrencies: PropTypes.array,
  selectedConvertCurrency: PropTypes.string,
  convertRate: PropTypes.string,
  convertAmount: PropTypes.string,
  convertTotalAmount: PropTypes.string,

  listCheckablePurchaseOrder: PropTypes.array,
  editablePurchaseOrders: PropTypes.array,
  save: PropTypes.func,
  changeSupplier: PropTypes.func,
  changeIssuedDate: PropTypes.func,
  changeFactor: PropTypes.func,
  changeInvoiceNo: PropTypes.func,
  changeDueDate: PropTypes.func,
  changeCreditDebit: PropTypes.func,
  changeTransactionNo: PropTypes.func,
  changePaymentMethod: PropTypes.func,
  changePaymentType: PropTypes.func,
  changePaymentAmount: PropTypes.func,
  addFile: PropTypes.func,
  openReceiptModal: PropTypes.func,
  changeFilterPOText: PropTypes.func,
  changeCheckedPurchaseOrder: PropTypes.func,
  toggleActivePurchaseOrder: PropTypes.func,
};

EditInvoiceSupplierDomestic.defaultProps = {
  constSupplierName: '',
  supplier: '',
  issuedDate: '',
  dueDate: '',
  amount: 0,
  paid: 0,
  remaining: 0,
  paymentAmount: 0,
  listCheckablePurchaseOrder: [],
  editablePurchaseOrders: [],
  listCurrencies: [],
  currency: '',
  changeSupplier: f => f,
  changeIssuedDate: f => f,
  changeInvoiceNo: f => f,
  changeDueDate: f => f,
  changeCreditDebit: f => f,
  changePaymentAmount: f => f,
  changePaymentType: f => f,
  addFile: f => f,
  openReceiptModal: f => f,
  changeFilterPOText: f => f,
  changeCheckedPurchaseOrder: f => f,
  toggleActivePurchaseOrder: f => f,
};

export default EditInvoiceSupplierDomestic;
