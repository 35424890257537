import Base from './base';

class Shipping extends Base {
  constructor () {
    super();

    this.getListFollowUp = this.getListFollowUp.bind(this)
    this.createShipment = this.createShipment.bind(this)
    this.getPurchaseOrderList = this.getPurchaseOrderList.bind(this)
    this.getShipInList = this.getShipInList.bind(this)
    this.getHistoryList = this.getHistoryList.bind(this)
  }

  getListFollowUp(options)
  {
    return super.execute(this.Urls.shipping.followUp, this.ApiMethod.get, options)
  }

  createShipment(body)
  {
    return super.execute(this.Urls.shipping.default, this.ApiMethod.post, body);
  }

  editShipment = (id, body) => {
    return super.execute(this.Urls.shipping.default + '/' + id, this.ApiMethod.put, body);
  }

  deleteShipment = id => {
    return super.execute(this.Urls.shipping.default + '/' + id, this.ApiMethod.delete, null);
  }

  getPurchaseOrderList(body)
  {
    return super.execute(this.Urls.shipping.listPurchaseOrder, this.ApiMethod.post, body)
  }

  getShipInList(body)
  {
    return super.execute(this.Urls.shipping.default, this.ApiMethod.get, body)
  }

  getHistoryList()
  {
    return super.execute(this.Urls.shipping.listHistory, this.ApiMethod.get, null)
  }

  searchInto = (options, func,  name, field, bool = true) => {
    return new Promise(resolve => {
      if(bool) {
        options.query = JSON.stringify(options.query || {})
        return resolve(options)
      } 
      func.then(rs => {
        const qr = {[name]: _.get(rs, 'data.items', []).reduce((ls, vl) => _.isArray(vl[field]) ? [...ls, ...vl[field]] : [...ls, vl[field]], [])}
        options.query = JSON.stringify({...options.query, ...qr})
        return resolve(options)
      })
    })
  }

  search = (search, options, searchKey) =>{
    let func,  name, field, bool = true
    if(search.value != '') {
      switch(search.key) {
        case searchKey.CUSTOMER_PO: {
          func = orderServices.getListOrder({projection: 'styleOrders', query: JSON.stringify({customerPO: {$regex: '.*' + search.value + '.*', $options: 'i'}})})
          name = 'styleId'
          field = 'styleOrders'
          bool = false
          break;
        }
        case searchKey.ORDER_DATE: {
          var gte = search.value.split(',');
          let db = new Date(gte[0])
          db.setHours(0,0,0,0)
          //db.setDate(db.getDate() - 1);
          let de = new Date(gte[1])
          de.setHours(23,59,59,999)
          func = orderServices.getListOrder({projection: 'styleOrders orderDate', query: JSON.stringify({orderDate: {$gte: db, $lt: de}})})
          name = 'styleId'
          field = 'styleOrders'
          bool = false
          break;
        }
      }
    }

    let op = {}
    return this.searchInto(options, func,  name, field, bool).then(data => {
      op = data
      return super.execute(this.Urls.shipping.listShipIn, this.ApiMethod.get, op);
    })
  }

  getListForwarder = () => {
    return super.execute(this.Urls.shipping.listForwarder, this.ApiMethod.get);
  }

  getExportShipin = (filter) => {
    return `${this.Urls.shipping.exportShipin}${filter ? filter : ''}`;
  }
}

export default new Shipping();
