import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as types from '../../constants/actionTypes';
import { formatNumber } from '../../functions/helpers';
import BaseComponent from '../base/BaseComponent';
import YieldFabricDetail from './partial/YieldFabricDetail';
import YieldTrimDetail from './partial/YieldTrimDetail';
import YieldLog from './modal/YieldLog';
import cutTicketActions from '../../actions/cutTicketActions';
import yieldActions from '../../actions/yieldActions';
import _ from 'lodash';
import I18n from 'i18n-js';
import IC_NOTIFY from "../../stylesheets/images/ic-notify.png";

import icEditIcon from '../../stylesheets/images/ic-edit.svg';
import icLockIcon from '../../stylesheets/images/ic-lock.svg';
import faEyeWIcon from '../../stylesheets/images/fa-eye-w.svg';

class PanelCutTicket extends BaseComponent {
  constructor(props, context) {
    super(props, context);

    this.state = {
      style: {},
      items: [],
      collapse: [],
      active: true,
      idTab: [],
      clickActive: "notClick",
      checkActive: "display"
    };
  }

  componentWillReceiveProps(newProps) {
    let { style, items } = this.state;

    if (!_.isEqual(style._id, _.get(newProps, 'style._id', ''))) {
      this.setState({
        style: _.cloneDeep(newProps.style),
        items: []
      });
    }

    if (_.get(newProps, 'style._id', '') && !_.isEqual(items, newProps.cutTicketState.items)) {
      this.setState({ items: _.cloneDeep(newProps.cutTicketState.items) });
      this.props.getCutTickets(_.cloneDeep(newProps.cutTicketState.items));
    }
  }

  componentWillUpdate(nextProps, nextState) {
    let { style } = this.state;
    if (!_.isEqual(style, nextState.style) || this.props.cutTicketState.reload != nextProps.cutTicketState.reload) {
      let populate = 'colors,yield|self.info.material,yield|self.estimate.color';
      populate += ',yield|contrast.info.material,yield|contrast.estimate.color';
      populate += ',yield|_70D.info.material,yield|_70D.estimate.color';
      populate += ',yield|specialTrims.info.material,yield|specialTrims.estimate.color';
      populate += ',yield|optionalTrims.info.material,yield|optionalTrims.estimate.color';
      populate += ',yield|auditLog.material,yield|auditLog.user';

      _.get(nextState, 'style._id', '') && this.props.cutTicketPropsActions.getListCutTicket(_.get(nextState, 'style._id', ''), { populate: populate });
    }
  }

  showTab(cutTicket, index, act, click, check) {
    let items = this.state;
    let { collapse } = this.state;
    let { idTab, active, clickActive, checkActive } = this.state;
    let arr = [];
    {
      (items.items || []).map((value, key) => {
        arr.push(value._id)
      })
    }
    collapse = _.nth(arr, index);
    if (act == active && check == checkActive && click == "notClick") {
      this.setState({ idTab: index, clickActive: "trueClick" })
    } else {
      this.setState({ idTab: index, clickActive: "notClick" })
    }
  }

  editItem(cutTicket, index, act, click, check) {
    let items = this.state;
    let { collapse } = this.state;
    let { idTab, active, clickActive, checkActive } = this.state;
    let arr = [];
    {
      (items.items || []).map((value, key) => {
        arr.push(value._id)
      })
    }
    collapse = _.nth(arr, index);
    if (act == active && check == checkActive && click == "notClick") {
      this.setState({ idTab: index, clickActive: "trueClick" })
    } else {
      this.setState({ idTab: index, clickActive: "notClick" })
    }
    this.props.dispatch({
      type: types.CUT_TICKET.SET_ITEM_YIELD_DETAIL,
      data: cutTicket
    });

    this.props.dispatch({
      type: types.APP.SET_PAGE_CURRENT,
      data: _.assign(_.cloneDeep(this.props.appState.page), { isEdit: true })
    });
  }

  sendNotify(cutTicket) {
    let y = _.get(cutTicket, 'yield', {});
    y.sendNotify = true;
    this.props.yieldPropsActions.sendNotify(y._id, y);
  }

  unBlockYield(item) {
    item && item._id && this.props.yieldPropsActions.editYield(item._id, { isBlocked: false });
  }

  openYieldLog(item) {
    const { openModal, closeModal } = this.props.modal;
    let modal = (<YieldLog closeModal={closeModal} item={item} />);
    openModal(modal);
  }

  render() {
    let { items, collapse, idTab, active, clickActive, checkActive } = this.state;
    const { showCreate, showEdit, showDelete, isSuperAdmin } = this.props;
    return (
      <div className="md content-main-info">
        <div className="panel-group" id="tab_cut_ticket" role="tablist">
          {items.map((i, idx) => {
            let y = i.yield || {};

            return (
              <div key={idx} className="panel panel-default">
                <div className="panel-heading" role="tab" id={`tab_cut_ticket_${idx}`}>
                  <div className="panel-title">
                    <a className={`wrap-icon-tab ${(idTab == idx && active == true) ? active : "not-display"} ${idTab == idx ? clickActive : "notClick"}`} role="button"
                      onClick={() => this.showTab(i, idx, active, clickActive, `${idTab == idx ? checkActive : "not-display"}`)}>
                      <i className="fa fa-caret-right" aria-hidden="true" />
                    </a>
                    <ul>
                      <li>
                        <div className="text">
                          <span className="left">{I18n.t('PageYield.CutTicket')}</span>
                          <span className="right size-15"><span className="medium">{i.cutTicket}</span></span>
                        </div>
                        <div className="text">
                          <span className="left">{I18n.t('PageYield.G-Total')}</span>
                          <span className="right size-15"><span
                            className="medium">{formatNumber(i.gTotal)}</span></span>
                        </div>
                      </li>
                      <li>

                        {(!y.isBlocked || isSuperAdmin) ? (
                          <div>
                            <span className="log-history"
                              onClick={() => this.openYieldLog(y)}>{I18n.t('PageYield.LogHistory')}</span>
                            {showEdit && (
                              <span className="cursor-pointer" onClick={() => this.editItem(i, idx)}>
                              <img src={icEditIcon} alt="" />
                              {I18n.t('Btn.EDIT')}
                            </span>
                            )}
                            
                            {i.yield && !i.yield.sendNotify &&
                            <span style={{cursor: "pointer"}} onClick={() => this.sendNotify(i)}>
                              <img src={IC_NOTIFY} alt="" style={{width:"17px", height:"17px"}} data-toggle="tooltip" data-placement="bottom" title="Send Notify" />
                              Send Notify
                            </span>
                            }
                          </div>)
                          : (<div>
                            <span><img src={icLockIcon} alt="" /></span>
                            <span className="log-history"
                              onClick={() => this.openYieldLog(y)}>{I18n.t('PageYield.LogHistory')}</span>
                            <span className="cursor-pointer" onClick={() => this.editItem(i, idx, active, clickActive, `${idTab == idx ? checkActive : "not-display"}`)}>
                              <img src={faEyeWIcon} alt="" /> {I18n.t('Btn.VIEW')}
                            </span>
                            {i.yield && !i.yield.sendNotify &&
                            <span style={{cursor: "pointer"}} onClick={() => this.sendNotify(i)}>
                              <img src={IC_NOTIFY} alt="" style={{width:"17px", height:"17px"}} data-toggle="tooltip" data-placement="bottom" title="Send Notify" />
                              Send Notify
                            </span>
                            }
                          </div>
                          )}
                      </li>
                    </ul>
                  </div>
                </div>
                <div id={`cut_ticket_${idx}`} className={`wrap-cut-ticket ${(idTab == idx && active == true) ? active : "not-display"} ${idTab == idx ? clickActive : "notClick"}`}
                  role="tabpanel" aria-labelledby={`tab_cut_ticket_${idx}`}>
                  {!_.isEmpty(y) && (
                    <div className="panel-body">
                      <YieldFabricDetail {...this.props} data={[y.self || {}] || []} name={"SELF"} thName={"Self color"}
                        fieldName={"selfColor"} clsName={"colum-2"} style={{color: 'blue'}}/>
                      <div className="colum-2">
                        <div className="container-colum">
                          <YieldFabricDetail {...this.props} data={y.contrast || []} name={"CONTRAST"}
                            thName={"Contrast color"} fieldName={"contrastColor"}
                            clsName={"container-colum-2"} style={{color: 'blue'}}/>
                          <YieldFabricDetail {...this.props} data={y._70D || []} name={"LINING"} thName={"70D color"}
                            fieldName={"_70DColor"} clsName={"container-colum-2"} style={{color: 'blue'}}/>
                          <div className="bg-line-colum" />
                        </div>
                      </div>

                      <div className="colum-2">
                        <div className="container-colum">
                          <YieldTrimDetail {...this.props} data={y.specialTrims || []} name={"Special Trims"} style={{color: 'blue'}}/>
                          <YieldTrimDetail {...this.props} data={y.optionalTrims || []} name={"Optional Trims"} style={{color: 'blue'}}/>
                          <div className="bg-line-colum" />
                        </div>
                      </div>
                    </div>
                  )}

                  {_.isEmpty(y) && (<div className="panel-body" />)}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    appState: state.appState,
    authState: state.authState,
    cutTicketState: state.cutTicketState
  };
}

function mapDispatchToProps(dispatch) {
  return {
    cutTicketPropsActions: bindActionCreators(cutTicketActions, dispatch),
    yieldPropsActions: bindActionCreators(yieldActions, dispatch),
    dispatch
  };
}

PanelCutTicket.propTypes = {
  authState: PropTypes.object.isRequired,
  showCreate: PropTypes.bool,
  showEdit: PropTypes.bool,
  showDelete: PropTypes.bool,
  isSuperAdmin: PropTypes.bool
};

export default connect(mapStateToProps, mapDispatchToProps)(PanelCutTicket);
