import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Switch, Route } from 'react-router-dom';
import * as R from 'ramda';
import { toastr } from 'react-redux-toastr';
import I18n from 'i18n-js';
import * as PATH from '../../../../constants/routeConstants';
import CreatePlanSupplierOversea from '../../../components/accounting/payable/CreatePlanSupplierOversea';
import * as actions from '../../../../actions/accounting/payable/createInvoiceSupplier';
import * as selector from '../../../../selectors/accounting/payable/createInvoiceSupplier';
import moment from 'moment';
import PreivewSupplierOversea from './PreviewSupplierOversea';
import CreatePlanContractorView from '../../../components/accounting/payable/CreatePlanContractor';

class AccountsPayablePlanSupplier extends Component {
  componentWillMount() {
    this.props.loadData();
    this.props.setSupplierType('overseaInvoice');
  }

  componentWillReceiveProps(nextProps) {
    const { success } = nextProps;
    if (success) {
      nextProps.resetData();
      nextProps.router.history.push(PATH.ACCOUNTING_PAYABLE_INVOICE_URL);
      toastr.success(I18n.t('Notify.Success'), 'Create INV successfully');
    }
  }

  changeSelectedCustomerPOs = (id, checked) => {
    const { checkablePurchaseOrders } = this.props;
    const item = checkablePurchaseOrders.find(item => item._id == id);
    if (item) this.props.changeCheckedPurchaseOrder(item.raw, checked);
  };

  save = data => {
    const { invoiceNo } = this.props;
    if (_.isEmpty(invoiceNo)) {
      toastr.error(I18n.t('Notify.Error'), 'Missing Invoice No');
    } else {
      this.props.createSupplierInvoice(data);
    }
  };

  render() {
    // const {issuedDate, dueDate, paymentTermNumber} = this.props;
    // console.log(this.props);
    // const dateTemp = moment(issuedDate).add(paymentTermNumber || 0, 'day').format()
    // if(_.isNumber(dueDate) && dueDate <= paymentTermNumber) {
    //   this.props.changeDueDate(dateTemp, issuedDate)
    // }
    return (
      <Switch>
        <Route
          path={PATH.ACCOUNTING_PAYABLE_PLAN_SUPPLIER_OVERSEA_PREVIEW_URL}
          render={() => <PreivewSupplierOversea />}
        />
        <Route
          path={PATH.ACCOUNTING_PAYABLE_PLAN_SUPPLIER_OVERSEA_URL}
          exact
          render={() => (
            <CreatePlanSupplierOversea
              {...this.props}
              save={data => this.save(data)}
              changeSelectedCustomerPOs={(id, checked) => this.changeSelectedCustomerPOs(id, checked)}
            />
          )}
        />
      </Switch>
      // <CreatePlanSupplierOversea
      //   {...this.props}
      //   save={(data) => this.save(data)}
      //   changeSelectedCustomerPOs={(id, checked) => this.changeSelectedCustomerPOs(id, checked)}
      // />
    );
  }
}

AccountsPayablePlanSupplier.propTypes = {};

AccountsPayablePlanSupplier.defaultProps = {};

const mapStateToProps = state => ({
  isVATPerMonthChecked: selector.isVATPerMonthChecked(state),

  selectableSuppliers: selector.getSelectableSuppliers(state),
  selectedSupplier: R.pathOr('', ['value'], selector.getSelectedSupplier(state)),

  issuedDate: selector.getIssuedDate(state),
  invoiceNo: selector.getInvoiceNo(state),
  factor: selector.getFactor(state),
  paymentTerm: selector.getPaymentTerm(state),
  paymentTermNumber: selector.getPaymentTermNumber(state),
  dueDate: selector.getDueDate(state),

  selectableCredits: selector.getSelectableCreditDebits(state),
  selectedCredit: R.pathOr('', ['value'], selector.getSelectedCreditDebit(state)),

  invAmount: selector.getAmountINV(state),
  invTotalAmount: selector.getTotalAmountINV(state),
  invTotalAmountSay: selector.getTotalAmountSay(state),

  checkablePurchaseOrders: selector.getSelectablePurchaseOrders(state),
  editablePurchaseOrders: selector.getEditablePurchaseOrders(state),

  selectableConvertCurrencies: selector.getSelectableConvertCurrencies(state),
  selectedConvertCurrency: R.pathOr('', ['value'], selector.getSelectedConvertCurrency(state)),
  convertRate: selector.getConvertRate(state),
  convertAmount: selector.getAmountConvert(state),
  convertTotalAmount: selector.getTotalAmountConvert(state),

  mainVATNo: selector.getMainVATNo(state),
  mainVATDate: selector.getMainVATDate(state),
  mainVATTotalAmount: selector.getMainTotalAmount(state),
  mainVATAmount: selector.getMainVATAmount(state),
  mainVATGrandTotal: selector.getMainGrandTotalVAT(state),
  mainTaxRate: selector.getMainTaxRate(state),
  dataApi: selector.fnGenerateRequestBody(state),
  success: selector.getSuccess(state),
  canPreview: selector.getCanPreview(state),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  loadData() {
    dispatch(actions.listSuppliers('OVERSEA'));
  },
  changeFilterPOText(value) {
    dispatch(actions.changeSearchText(value));
  },
  changeVATPerMonthChecked(checked) {
    dispatch(actions.changeVATPerMonth(checked));
  },

  changeSelectedSupplier(item) {
    dispatch(actions.changeSelectedSupplier(item ? item.raw : null));
  },
  setSupplierType(value) {
    dispatch(actions.changeSupplierType(value));
  },

  changeIssuedDate(value) {
    dispatch(actions.changeIssuedDate(value));
  },

  changeInvoiceNo(value) {
    dispatch(actions.changeInvoiceNo(value));
  },

  changeDueDate(value, issuedDate) {
    if (moment(issuedDate).valueOf() <= moment(value).valueOf()) {
      dispatch(actions.changeDueDate(value));
    }
  },

  changeSelectedCredit(item) {
    dispatch(actions.changeSelectedCredit(item ? item.raw : null));
  },

  changeSelectedConvertCurrency(item) {
    dispatch(actions.changeSelectedConvertCurrency(item ? item.raw : null));
  },

  changeConvertRate(value) {
    dispatch(actions.changeConvertRate(value));
  },

  changeMainVATNo(value) {
    dispatch(actions.changeMainVATNo(value));
  },

  changeMainVATTotalAmount(value) {
    dispatch(actions.changeMainVATTotalAmount(value));
  },

  changeMainVATDate(value) {
    dispatch(actions.changeMainVATDate(value));
  },

  changeMainVATTaxRate(value) {
    dispatch(actions.changeMainVATTaxRate(value));
  },

  changeCheckedPurchaseOrder(item, value) {
    if (item) {
      dispatch(actions.changeCheckedPurchaseOrder(R.path(['_id'], item)), value);
    }
  },

  toggleActivePurchaseOrder(item) {
    if (item) {
      dispatch(actions.toggleActivePurchaseOrder(R.path(['_id'], item)));
    }
  },

  changeSubVATNo(item, value) {
    if (item) {
      dispatch(actions.changeSubVATNo(item._id, value));
    }
  },

  changeSubVATDate(item, value) {
    if (item) {
      dispatch(actions.changeSubVATDate(item._id, value));
    }
  },

  changeSubVATTaxRate(item, value) {
    if (item) {
      dispatch(actions.changeSubTaxRate(item._id, value));
    }
  },

  changeSubVATNote(item, value) {
    if (item) {
      dispatch(actions.changeSubVATNote(item._id, value));
    }
  },

  changeFactor(value) {
    dispatch(actions.changeFactor(value));
  },

  onDomesticClick() {
    ownProps.router.history.push(PATH.ACCOUNTING_PAYABLE_PLAN_SUPPLIER_DOMESTIC_URL);
  },

  createSupplierInvoice(data) {
    dispatch(actions.createSupplierInvoice(data));
  },

  changePurchaseInvoiceNo(purchaseId, materialId, index, value) {
    dispatch(actions.changePurchaseInvoiceNo(purchaseId, materialId, index, value));
  },
  onPreviewScreen() {
    dispatch(actions.activeScreenPreview());
    ownProps.router.history.push(PATH.ACCOUNTING_PAYABLE_PLAN_SUPPLIER_OVERSEA_PREVIEW_URL);
  },

  resetData() {
    dispatch(actions.resetData());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AccountsPayablePlanSupplier));
