import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {sourceTypes} from '../../../constants/EnumTypes';
import {formatNumber, getQuatity} from '../../../functions/helpers';
import _ from 'lodash';
import I18n from 'i18n-js';

class YieldTrimDetail extends Component {
  constructor (props, context) {
    super(props, context);

    this.state = {};
  }

  // groupColor(colors) {
  //   let data = _.groupBy(colors,"color.name");
  //   return Object.keys(data).map(vl => {
  //     let quatity = 0;
  //     let sizes = data[vl].reduce((acc, item) => {
  //       _.get(item, 'color.sizes', []).forEach(e => {
  //         quatity += e.quantity || 0;
  //         acc[e.name] = (e.quantity || 0) +  (acc[e.name] || 0);
  //       });
  //       return acc;
  //     }, {});
  //     const idx = _.findIndex(data[vl], i => i.trimColor == '')
  //     return {
  //       name: vl,
  //       sizes,
  //       quatity,
  //       selectColor: idx >= 0 ? '': data[vl][0].trimColor
  //     };
  //   });
  // }

  render () {
    let {data, name, style = {}} = this.props;
    let itemNominated = data.find(i => _.get(i, 'info.type', '') === sourceTypes.NOMINATED) || null;

    return (
      <div className="container-colum-2">
        <div className="header-ticket">
          <ul>
            <li>
              <h3 style={style}>{name}</h3>
            </li>
            <li>
              <span>{itemNominated ? 'Nominated' : 'Sourced'}</span>
              {itemNominated ? <span>{_.get(itemNominated, 'info.replacementPO', '')}</span> : null }
            </li>
          </ul>
        </div>

        {data.map((item, idx) => {
          let estimates = _.get(item, 'estimate', []);
          let trimSize = _.get(item, 'trimSize', []);
          const actualYield = _.get(item, 'info.actualYield', undefined);
          const customerYield = _.get(item, 'info.yield', undefined);
          return (
            <div key={idx} className="content-ticket content-ticket-colum-2">
              <div className="info">
                <ul>
                  <li>
                    <h4 style={style}>{_.get(item, 'info.material.name', '')}</h4><br/>
                    <p>{_.get(item, 'info.material.data.content', '')}</p>
                    <div style={{position: 'absolute', top: 0, right: 0, width: 150}}>
                        {customerYield && <span className="float-right text">{I18n.t('PageYield.CustomerYield')} <span className="number">{`${customerYield}  %${_.get(item, 'info.percent', '')}`}</span></span>}
                        {actualYield && <span className="float-right text">{I18n.t('PageYield.ActualYield')} <span className="number">{`${actualYield}  %${_.get(item, 'info.actualPercent', '')}`}</span></span>}
                    </div>
                  </li>
                </ul>
              </div>

              <table style={{width: '100%'}}>
                <thead>
                  <tr>
                    <th>{I18n.t('PageYield.TrimColor')}</th>
                    <th className="w-100">{I18n.t('PageYield.Color')}</th>
                    <th className="center">{I18n.t('PageYield.Size')}</th>
                    <th />
                    <th className="center">{I18n.t('PageYield.TrimSize')}</th>
                    <th className="right">{I18n.t('PageYield.Quantity')} ({_.get(item, 'info.material.data.unit', '')})</th>
                  </tr>
                </thead>
                <tbody>
                  {estimates.map((i, idx) => {
                    let quantity = (_.get(i, 'color.sizes')|| []).reduce((s, item) => s + item.quantity || 0, 0);
                    return (
                      <tr key={idx}>
                        <td className="bold">{_.get(i, 'trimColor', '')}</td>
                        <td className="bold">
                          <span className="title block">{_.get(i, 'color.name', '')}</span>
                          <span className="number">{formatNumber(quantity)}</span>
                        </td>
                        <td className="list">
                          <ul>
                            {_.get(i, 'color.sizes', []).map((vl, idx) => (<li key={idx}>{vl.name}</li>))}
                          </ul>
                        </td>
                        <td className="list">
                          <ul>
                            {_.get(i, 'color.sizes', []).map((vl, idx) => (<li key={idx}>{formatNumber(vl.quantity)}</li>))}
                          </ul>
                        </td>
                        <td className="list">
                          <ul>
                            {_.get(i, 'color.sizes', []).map((vl, idx) => {
                              let found = trimSize.find(ts => ts.size === vl.name);
                              let value = _.get(found, 'value', '-');
                              value = value ? value : '-';
                              return (<li key={idx}>{value}</li>);
                            })}
                          </ul>
                        </td>
                        <td className="list last">
                          <ul>
                            {_.get(i, 'color.sizes', []).map((vl, idx) => (<li key={idx}>{getQuatity(vl.quantity, _.get(item, 'info.yield', 0), _.get(item, 'info.percent', 0))}</li>))}
                          </ul>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          );
        })}
      </div>
    );
  }
}

function mapStateToProps (state, ownProps) {
  return {
    authState: state.authState
  };
}

function mapDispatchToProps (dispatch) {
  return {};
}

YieldTrimDetail.propTypes = {
  authState: PropTypes.object.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(YieldTrimDetail);
