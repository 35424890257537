import {USERS} from '../constants/actionTypes';
import initial from './initialState';

export default function users(state = initial.userState, action) {
  switch(action.type) {
    case USERS.GET_LIST_SUCCESS:
    return {...state, items: action.data.items || [], count: action.data.count || 0};
    case USERS.FETCH_SUCCESS:
        return {...state, items: action.payload.items, count: action.payload.count};
    case USERS.LOGOUT_SUCCESS:
        return {username: ''};
    case USERS.FETCH_DETAIL_SUCCESS:
      return {...state, detail: action.payload};
    case USERS.RELOAD:
      return {...state, reload: !state.reload};
    default:
        return state;
  }
}
