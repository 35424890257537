import * as R from 'ramda';
import _ from 'lodash';
import {toastr} from 'react-redux-toastr'
import I18n from 'i18n-js';
import C from '../../../constants/customer-po/current/showOrderDetail';
import styleServices from '../../../services/styleServices';
import orderServices from '../../../services/orderServices';
import cutTicketServices from '../../../services/cutTicketServices';

export const changeOrderDetail = order => dispatch => {
  dispatch({ type: C.CHANGE_ORDER_DETAIL, payload: order });

  const id = R.path(['_id'], order);
  if (id) dispatch(listStyles(id));
};

export const listStyles = orderId => dispatch => {
  dispatch({ type: C.FETCH_STYLES });
  styleServices.getListStyle(orderId, {}).then(res => {
    dispatch({ type: C.CANCEL_FETCH_STYLES });
    const payload = R.path(['data', 'items'], res);
    dispatch({ type: C.CHANGE_STYLES, payload });
    dispatch(changeSelectedStyle(_.head(payload)));
  }).catch(error => {
    dispatch({ type: C.CANCEL_FETCH_STYLES });
    console.error(error);
  })
};

export const changeSelectedStyle = style => dispatch => {
  dispatch({ type: C.CHANGE_SELECTED_STYLE, payload: style });

  const id = R.path(['_id'], style);
  if (id) dispatch(listCutTickets(id));
};

export const listCutTickets = styleId => dispatch => {
  dispatch({ type: C.FETCH_CUT_TICKETS });
  cutTicketServices.getListCutTicket(styleId, { populate:'colors,store|packingStandard' }).then(res => {
    dispatch({ type: C.CANCEL_FETCH_CUT_TICKETS });
    const payload = R.path(['data', 'items'], res);
    dispatch({ type: C.CHANGE_CUT_TICKETS, payload });
  }).catch(error => {
    dispatch({ type: C.CANCEL_FETCH_CUT_TICKETS });
    console.error(error);
  });
};

export const changeCutTicket = cutTicket => dispatch => {
  dispatch({ type: C.CHANGE_CUT_TICKET, payload: cutTicket });
};

export const sendNotify = id => dispatch => {
  dispatch({ type: C.SEND_NOTIFY });
  orderServices.sendNotify(id).then((res) => {
    dispatch({ type: C.CANCEL_SEND_NOTIFY });
    const { success, error } = res;
    if(success) toastr.success(I18n.t('Notify.Success'), I18n.t('SendNotifySuccessful'))
    else toastr.error(I18n.t('Notify.Error'), error);
  }).catch(error => {
    dispatch({ type: C.CANCEL_SEND_NOTIFY });
    toastr.error(I18n.t('Notify.Error'), error);
    console.error(error);
  });
};
