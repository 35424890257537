const C = 'CREATE_PAYABLE_INVOICE_SUPPLIER';

export default {
    CHANGE_LIST_SUPPLIERS: `${C}_CHANGE_LIST_SUPPLIERS`,
    CHANGE_TYPE_SUPPLIERS: `${C}_CHANGE_TYPE_SUPPLIERS`,
    CHANGE_SELECTED_SUPPLIER: `${C}_CHANGE_SELECTED_SUPPLIER`,
    CHANGE_SELECTED_CURRENCY: `${C}_CHANGE_SELECTED_CURRENCY`,
    CHANGE_CURRENCY_RATE: `${C}_CHANGE_CURRENCY_RATE`,
    CHANGE_CURRENCY_TOTAL_AMOUNT: `${C}_CHANGE_CURRENCY_TOTAL_AMOUNT`,
    CHANGE_ISSUED_DATE: `${C}_CHANGE_ISSUED_DATE`,
    CHANGE_DUE_DATE: `${C}_CHANGE_DUE_DATE`,
    CHANGE_LIST_CARD_CREDIT: `${C}_CHANGE_LIST_CARD_CREDIT`,
    CHANGE_SELECT_CARD_CREDIT: `${C}_CHANGE_SELECT_CARD_CREDIT`,
    CHANGE_INVOICE: `${C}_CHANGE_INVOICE`,
    CHANGE_FACTOR: `${C}_CHANGE_FACTOR`,
    CHANGE_MAIN_VAT_NO: `${C}_CHANGE_MAIN_VAT_NO`,
    CHANGE_MAIN_VAT_DATE: `${C}_CHANGE_MAIN_VAT_DATE`,
    CHANGE_CONVERT_CURRENCY: `${C}_CHANGE_CONVERT_CURRENCY`,
    CHANGE_CONVERT_RATE: `${C}_CHANGE_CONVERT_RATE`,
    CHANGE_MAIN_TAX_RATE: `${C}_CHANGE_MAIN_TAX_RATE`,
    CHANGE_MAIN_TOTAL_AMOUNT: `${C}_CHANGE_MAIN_TOTAL_AMOUNT`,
    CHANGE_SUB_VAT_NO: `${C}_CHANGE_SUB_VAT_NO`,
    CHANGE_SUB_VAT_DATE: `${C}_CHANGE_SUB_VAT_DATE`,
    CHANGE_SUB_NOTE: `${C}_CHANGE_SUB_NOTE`,
    CHANGE_SUB_LIST_DATA: `${C}_CHANGE_SUB_LIST_DATA`,
    CHANGE_SUB_TAX_RATE: `${C}_CHANGE_SUB_TAX_RATE`,
    CHANGE_PURCHASE_ORDERS: `${C}_CHANGE_PURCHASE_ORDERS`,
    TOGLE_ACTIVE_PURCHASE_ORDERS: `${C}TOGLE_ACTIVE_PURCHASE_ORDERS`,
    TOGLE_PURCHASE_ORDERS: `${C}_CHECKED_PURCHASE_ORDERS`,
    TOGLE_VAT_PER_MOUNTH: `${C}_TOGLE_VAT_PER_MOUNTH`,
    SUCCESS: `${C}_SUCCESS`,
    INV_NO: `${C}_INV_NO`,
    CHANGE_PURCHASE_INVOICE_NO: `${C}_CHANGE_PURCHASE_INVOICE_NO`,
    RESET_DATA: `${C}_RESET_DATA`,
    CHANGE_ERROR_MESSAGE: `${C}_CHANGE_ERROR_MESSAGE`,
    ACTIVE_SCREEN_PREVIEW_STATUS: `${C}_ACTIVE_SCREEN_PREVIEW_STATUS`,
    CHANGE_SEARCH_TEXT: `${C}_CHANGE_SEARCH_TEXT`,
}