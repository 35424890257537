import {createSelector} from 'reselect';
import _ from 'lodash';
import moment from "moment";
import formatCurrency from "format-currency";


/**
 * Get selectable filters
 */
export const getSelectableFilters = () => {
    let arrData = [
        {field: 'purchaseorder', name: 'PageAccounting.PurchaseOrder', type: 'text'},
        {field: 'invRequest', name: 'PageAccounting.INVRequest', type: 'text'},
        {field: 'invRef', name: 'PageAccounting.INVRef', type: 'text'},
        {field: 'invNo', name: 'PageAccounting.INVNo', type: 'text'},
        {field: 'customerPO', name: 'PageAccounting.CustomerPO', type: 'text'},
        {field: 'dueDate', name: 'PageAccounting.DueDate', type: 'dateTo'},
        {field: 'payee', name: 'PageAccounting.Payee', type: 'text'},
        {field: 'type', name: 'PageAccounting.Type', type: 'text'},
    ]
    return arrData;
}

export const getCheckedList = ({showPayableInvoiceList}) => showPayableInvoiceList.checkedList;

/**
 * Get selected filter
 */
export const getSelectedFilter = createSelector(
    [getSelectableFilters, ({showPayableInvoiceList}) => showPayableInvoiceList.selectedFilter],
    (filters, selectedFilter) => {
        if (!selectedFilter) return filters[0].field;
        return selectedFilter;
    }
)


/**
 * Get search text field value from reducer
 */
export const getSearchValue = ({showPayableInvoiceList}) => showPayableInvoiceList.searchValue;

/**
 * Get status of whether invoices data is fetching from api
 */
export const isFetchingInvoices = ({showPayableInvoiceList}) => showPayableInvoiceList.fetchingPayableInvoices;

/**
 * Get a list of invoices
 * The results will contains these information
 * [{
 *    _id, invRequest, invRef, invNo, customerPO, dueDate, payee, 
 *    type, amount, paid, remaining, checked, checkable, deletable,
 * }]
 */

export const getPayableInvoices = createSelector (
    [
        ({showPayableInvoiceList}) => showPayableInvoiceList,
    ], (data) => {
    let arrData = [];
    data.payableInvoices.map(it => {
        let obj = {};
        obj.type = it.type;
        obj.typeInvoice = it.typeInvoice;
        obj._id = it._id;
        obj.invRequest = it.invRequest;
        obj.invRef = it.invRef;
        obj.invNo = it.invNo;
        obj.customerPOs = it.customerPOs;
        obj.customerPO = (it.customerPOs && it.customerPOs[0]) ? it.customerPOs[0].customerPO : '-';
        obj.purchaseOrders = it.purchaseOrders;
        obj.dueDate = converData('dueDate', it);
        obj.finishedDate = converData('finishedDate', it);
        obj.payee = it.payee ? it.payee : '-';
        obj.amount = converData('amount', it);
        obj.totalAmount = converData('totalAmount', it);
        obj.paid = converData('paid', it);
        obj.remaining = converData('remaining', it);
        let findCheck = data.checkedInvoices.findIndex(item => item == it._id);
        obj.checked = findCheck > -1 ? true : false;
        obj.checkable = obj.checked ? false : true;
        obj.deletable = obj.paid == '0.00' ? true : false;
        obj.invoiceDetail = it.invoiceDetail || {};
        arrData.push(obj);
    });
    return arrData;
});



function converData(key, obj) {
    switch (key) {
        case 'amount':
            return formatCurrency(obj['amount']);
        case 'paid':
            return formatCurrency(obj['paid']);
        case 'remaining':
            return formatCurrency(obj['amount'] - (obj['paid'] || 0));  // Convert to number
        case 'dueDate':
            return obj['dueDate'] ? moment(obj['dueDate']).format('MMM DD, YYYY') : '-';
        case 'finishedDate':
            return obj['finishedDate'] ? moment(obj['finishedDate']).format('MMM DD, YYYY') : '-';
        default:
            return obj[key];
    }
}

/**
 * Get total amount
 * Format: $ ###,###.##
 */
export const getTotalAmount = ({showPayableInvoiceList}) => formatCurrency(showPayableInvoiceList.totalAmount);


/**
 * change currency
 */
export const getCurrency = ({showPayableInvoiceList}) => showPayableInvoiceList.selectedCurrency;


export const getCurrencyTypeUSD = createSelector(
    [
        getCurrency
    ], (currency) => {
        return currency && currency === "USD" ? '$' : '';
    }
)

export const getCurrencyTypeVND = createSelector(
    [
        getCurrency
    ], (currency) => {
        return currency && currency === "VND" ? ' VND' : '';
    }
)

/**
 * Get total paid
 * Format: $ ###,###.##
 */
export const getTotalPaid = ({showPayableInvoiceList}) => formatCurrency(showPayableInvoiceList.totalPaid);

/**
 * Get total remaining
 * Format: $ ###,###.##
 */
export const getTotalRemaining = ({showPayableInvoiceList}) => formatCurrency(showPayableInvoiceList.totalRemaining);


/**
 * Get paid detail
 * Format: $ ###,###.##
 */
export const getPaidDetail = ({showPayableInvoiceList}) => {
    let arr = [];
    showPayableInvoiceList.paidDetail.map(it =>{
        let obj = {};
        obj.date = moment(it.date).format("MMM DD, YYYY");
        obj.paid = formatCurrency(it.paid);
        obj.name = _.isEmpty(it.attachs) ? it.receipt ? it.receipt.no : '' : it.attachs[0].split('-')[1];
        obj.type = _.isEmpty(it.attachs) ? 'Receipt' : 'Transfer';
        obj.raw = it;
        arr.push(obj);
    });
    return arr;
};

/**
 * Get total pages
 */
export const getTotalPages = ({showPayableInvoiceList}) => {
    return showPayableInvoiceList.totalPages;
}

/**
 * Get current page
 */
export const getCurrentPage = ({showPayableInvoiceList}) => {
    return showPayableInvoiceList.currentPage;
}

/**
 * Return a function that will be use to generate request body which is use to
 * send create invoice request from checked invoices
 */
export const fnGenerateRequestBody = createSelector([
    getPayableInvoices,
], (payableInvoices) => {
    let arrData = [];
    payableInvoices.map(it => {
        if (it.checked) arrData.push(it._id);
    });
    return arrData;
});

export const getInvoiceRequest = ({showPayableInvoiceList}) => showPayableInvoiceList.invoiceRequest;

export const getCheckedInvoice = ({showPayableInvoiceList}) => {
    if(showPayableInvoiceList.checkedInvoices){
        return showPayableInvoiceList.checkedInvoices
    }
};

export const getCreateInvoiceRequestStatus = ({showPayableInvoiceList}) => showPayableInvoiceList.createInvoiceRequestSuccess;

export const getDeleteSuccess = ({showPayableInvoiceList}) => showPayableInvoiceList.deleteSuccess;

export const getParamsRequest = createSelector([
    getSelectedFilter,
    getSearchValue,
    getCurrency,
    getCurrentPage
], (type,
    value,
    currency,
    page
) => {
    let params = {};
    params.type = type;
    // params.value = JSON.stringify(value);
    if(params.type == 'dueDate') {
        params.from = _.get(value, 'from', '');
        params.to = _.get(value, 'to', '');
    } else {
        params.value = _.get(value, 'value', '')
    }
    params.currency = currency;
    params.pageNumber = page ||  1;
    params.pageSize = 10;
    params.sort = JSON.stringify({createdAt: -1});
    return params;
});

export const getLoading = ({showPayableInvoiceList}) => showPayableInvoiceList.loading;

export const getReceipt = ({showPayableInvoiceList}) => showPayableInvoiceList.receipt;