import React, { Component } from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import _ from 'lodash';
import commonUseActions from '../../../actions/commonUseActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as packingActions from '../../../actions/production/packing';
import * as selector from '../../../selectors/production/addPacking';

class ModalEditCarton extends Component {
  constructor(props, context) {
    super(props, context);
    const { data, listCartons } = this.props;
    const key = `${data.styleId}:${data.cutId}:${data.colorId}`;
    this.state = {
      carton: listCartons[key].find(i => i.ctnNo == data.no),
    };
  }

  componentWillMount = () => {};

  componentWillReceiveProps(nextProps) {}

  ok = () => {
    const { carton } = this.state;
    const { data } = this.props;
    carton.items.map(c => {
      if (c.actualQuantity >= 0) {
        // console.log('--------c.actualQuantity', c.actualQuantity)
        this.props.packingActions.changeActualPackingCartonNumber(
          c.actualQuantity,
          data.styleId,
          data.cutId,
          data.colorId,
          c.name,
          data.no,
          c.planQuantity
        );
        this.props.packingActions.changeActualPackingCartonNumberForEdit(
          data.styleId,
          data.cutId,
          data.colorId,
          c.name,
          data.no,
          c.actualQuantity,
          c.planQuantity
        );
      }
      if (c.value > 0) {
        this.props.packingActions.changeGwKgsNwKgsPackingCartonNumber(
          c.value,
          data.styleId,
          data.cutId,
          data.colorId,
          c.name,
          data.no,
          c.maxValue
        );
        this.props.packingActions.changeGwKgsNwKgsPackingCartonNumberForEdit(
          data.styleId,
          data.cutId,
          data.colorId,
          c.name,
          data.no,
          c.value,
          c.maxValue
        );
      }
    });
    this.props.ok();
    this.props.closeModal();
  };

  onChangeGwKgsNwKgsPacking = (value, styleId, cutId, colorId, sizeName, no, total) => {
    let { carton } = _.cloneDeep(this.state);
    if (Number(value) <= total) {
      carton.items.map(c => (c.name == sizeName ? (c.value = Number(value)) : null));
    }
    this.setState({ carton });
  };

  onChangeActualPacking = (value, styleId, cutId, colorId, sizeName, no, total) => {
    let { carton } = _.cloneDeep(this.state);
    if (Number(value) <= total) {
      carton.items.map(c => (c.name == sizeName ? (c.actualQuantity = Number(value)) : null));
    }
    this.setState({ carton });
  };

  render = () => {
    const { data, getCarton, packedStyles, getActualPacking, packing, listCartons } = this.props;
    const item = _.filter(packedStyles, packedStyle => packedStyle._id === data.styleId)[0];
    const key = `${data.styleId}:${data.cutId}:${data.colorId}`;
    const { carton } = this.state;
    // console.log(carton)
    return (
      <div className="modal-dialog" role="document" id="modal-add-packing" style={{ maxWidth: 'unset', width: 750 }}>
        <div className="modal-content">
          <form>
            <div className="modal-header">
              <a className="close" data-dismiss="modal" aria-label="Close" onClick={this.props.closeModal}>
                <span aria-hidden="true">&times;</span>
              </a>
              <h4 className="modal-title" id="myModalLabel"></h4>
            </div>
            <div className="modal-body">
              <table className="global-table tr-height-30 box-shadow-none">
                <tbody>
                  <tr style={{ borderBottom: 'unset' }} className="bg-none">
                    <td className="fw-normal">{I18n.t('PageProduction.Plan')}</td>
                    {carton.items.map((item, idx) => {
                      if (item.name == 'gwKgs' || item.name == 'nwKgs') {
                        return (
                          <td className="center" key={idx}>
                            <span className="medium">{item.name}</span> : {item.maxValue || 0}
                          </td>
                        );
                      } else {
                        return (
                          <td className="center" key={idx}>
                            <span className="medium">{item.name}</span> : {item.planQuantity || 0}
                          </td>
                        );
                      }
                    })}
                  </tr>
                  <tr className="bg-none">
                    <td className="fw-normal">{I18n.t('PageProduction.Actual')}</td>
                    {carton.items.map((item, idx) => {
                      if (!item.actualQuantity && item.actualQuantity != 0) {
                        item.actualQuantity = item.planQuantity;
                      }
                      if (item.name == 'gwKgs' || item.name == 'nwKgs') {
                        // let actualPacking = getActualPacking(data.styleId, data.cutId, data.colorId, item.name);
                        return (
                          <td className="center color-green" key={idx}>
                            <input
                              min="0"
                              value={item.value || 0}
                              onChange={evt => {
                                this.onChangeGwKgsNwKgsPacking(
                                  evt.target.value,
                                  data.styleId,
                                  data.cutId,
                                  data.colorId,
                                  item.name,
                                  data.no,
                                  item.maxValue
                                );
                              }}
                              type="number"
                              className="form-control center pd-right-0 mr-10 height-30 w-50"
                            />
                          </td>
                        );
                      } else {
                        // let actualPacking = getActualPacking(data.styleId, data.cutId, data.colorId, item.name);
                        return (
                          <td className="center color-green" key={idx}>
                            <input
                              min="0"
                              value={item.actualQuantity || 0}
                              onChange={evt => {
                                this.onChangeActualPacking(
                                  evt.target.value,
                                  data.styleId,
                                  data.cutId,
                                  data.colorId,
                                  item.name,
                                  data.no,
                                  item.planQuantity
                                );
                              }}
                              type="number"
                              className="form-control center pd-right-0 mr-10 height-30 w-50"
                            />
                          </td>
                        );
                      }
                    })}
                  </tr>
                  <tr style={{ borderBottom: 'unset' }} className="bg-none">
                    <td className="fw-normal">{I18n.t('PageProduction.Remaining')}</td>
                    {carton.items.map((item, idx) => {
                      if (item.name != 'gwKgs' || item.name != 'nwKgs') {
                        // let actualPacking = getActualPacking(data.styleId, data.cutId, data.colorId, item.name);
                        return (
                          <td className="center color-green" key={idx}>
                            {(item.planQuantity || 0) - (item.actualQuantity || 0)}
                          </td>
                        );
                      }
                    })}
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="modal-footer">
              <button type="button" className="bt-black" onClick={this.props.closeModal}>
                {I18n.t('Btn.CANCEL')}
              </button>
              <button type="button" className="bt-orange" onClick={() => this.ok()}>
                {I18n.t('Btn.OK')}
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  };
}

function mapStateToProps(state, ownProps) {
  return {
    appState: state.appState,
    authState: state.authState,
    getCarton: selector.getCarton(state),
    packedStyles: selector.getPackingByOrder(state),
    packing: selector.getPacking(state),
    getActualPacking: selector.getActualPacking(state),
    packingInfo: selector.getPackingInfo(state),
    listCartons: selector.getListCartonsForEdit(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    commonUseActions: bindActionCreators(commonUseActions, dispatch),
    packingActions: bindActionCreators(packingActions, dispatch),
    dispatch,
  };
}

ModalEditCarton.propTypes = {
  ok: PropTypes.func,
  commonUseActions: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalEditCarton);
