import _ from 'lodash';
import * as R from 'ramda';

export const isOpen = ({ editCutTicket }) => !!editCutTicket.rawData;

export const getId = ({ editCutTicket }) => R.path(['_id'], editCutTicket.rawData);

export const getCutTicketName = ({ editCutTicket }) => editCutTicket.cutTicketName;

export const getSelectableStores = ({ editCutTicket }) => {
  const { selectableStores } = editCutTicket;
  return _.map(selectableStores, item => ({
    value: R.path(['_id'], item),
    label: R.path(['name'], item),
    raw: item,
  }));
}

export const getSelectedStore = ({ editCutTicket }) => {
  const { selectedStore } = editCutTicket;
  return R.pathOr('', ['_id'], selectedStore);
}

export const getSelectableWarehouses = ({ showOrderDetail }) => {
  const { order } = showOrderDetail;
  const warehouses = R.pathOr([], ['customer', 'warehouses'], order);
  return _.map(warehouses, item => ({
    value: R.path(['name'], item),
    label: R.path(['name'], item),
    raw: item,
  }));
}

export const getExFactory = ({ showOrderDetail }) => {
  const { order } = showOrderDetail;
  return R.pathOr([], ['exFactory'], order);
}
export const getSelectedWarehouse = ({ editCutTicket }) => {
  const { selectedWarehouse } = editCutTicket;
  return R.pathOr('', ['name'], selectedWarehouse);
}

export const getSelectableShipModes = ({ editCutTicket }) => {
  const { selectableShipModes } = editCutTicket;
  return _.map(selectableShipModes, item => ({
    value: item,
    label: item,
    raw: item,
  }));
};

export const getSelectedShipMode = ({ editCutTicket }) => {
  const { selectedShipMode } = editCutTicket;
  return selectedShipMode;
}

export const getMainLabel = ({ editCutTicket }) => editCutTicket.mainLabel;
export const getIhDate = ({ editCutTicket }) => editCutTicket.ihDate;
export const getNote = ({ editCutTicket }) => editCutTicket.note;

export const getErrors = ({ editCutTicket }) => editCutTicket.errors;

export const canSave = ({ editCutTicket }) => {
  const {
    errors, submiting, cutTicketName, ihDate, 
    selectedWarehouse, selectedShipMode,
  } = editCutTicket;

  if (submiting) return false;

  return !!_.trimEnd(cutTicketName) 
    && !!_.trimEnd(ihDate)
    && !!selectedWarehouse
    && !!selectedShipMode
    && !R.path(['cutTicket'], errors)
    && !R.path(['ihDate'], errors)
    && !R.path(['selectedWarehouse'], errors)
    && !R.path(['selectedShipMode'], errors);
};

export const isSubmiting = ({ editCutTicket }) => editCutTicket.submiting;

export const fnGenerateBody = ({ editCutTicket }) => () => {
  const { 
    cutTicketName, selectedWarehouse, selectedStore, 
    selectedShipMode, mainLabel, ihDate, note, rawData,
  } = editCutTicket;

  const body = {
    ...rawData,
    cutTicket: cutTicketName,
    warehouse: selectedWarehouse,
    shipMode: selectedShipMode,
    store: selectedStore,
    mainLabel,
    ihDate,
    note,
  };

  return body;
}
