import {PURCHASE_ORDER} from '../constants/actionTypes';
import initial from './initialState';

export default function (state = initial.purchaseOrderState, action) {
  switch (action.type) {
    case PURCHASE_ORDER.GET_LIST_SUCCESS:
      return {...state, items: action.data.items || [], count: action.data.total || 0};
    case PURCHASE_ORDER.RELOAD:
      return {...state, reload: !state.reload};
    case PURCHASE_ORDER.GET_DETAIL_SUCCESS:
      return {...state, detail: action.data};
    case PURCHASE_ORDER.RESET_DETAIL:
      return {...state, detail: {}};
    default:
      return state;
  }
}
