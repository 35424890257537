import {STORE} from '../constants/actionTypes';
import initial from './initialState';


export default function (state = initial.storeState, action) {
  switch (action.type) {
    case STORE.GET_LIST_SUCCESS:
      return {...state, items: action.data.items || [], count: action.data.count || 0};
    case STORE.RELOAD:
      return {...state, reload: !state.reload};
    case STORE.GET_DETAIL_SUCCESS:
      return {...state, detail: action.data};
    case STORE.RESET_DETAIL:
      return {...state, detail: {}};
    default:
      return state;
  }
}
