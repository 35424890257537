import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { bindActionCreators } from 'redux';
import BaseComponent from '../../base/BaseComponent';
import I18n from 'i18n-js';
import TableList from '../../common/datatables/TableList';
import ModalConfirm from '../../common/modals/ModalConfirm';
import SearchBar from '../../common/layout/SearchBar';
import { ACTION_TYPE, PRODUCTION_SCREEN } from '../../../constants/constants';
import { PACKING_LIST_SEARCH_KEY, SEARCH_LIST, SEARCH_BAR_EVENT } from '../../../constants/constants';

import ModalAddPacking from '../../common/modals/ModalAddPacking';
import ModalNotifyPacking from '../../common/modals/ModalNotifyPacking';
import * as types from '../../../constants/actionTypes';
import packingApi from '../../../api/packingApi';
import * as packingActions from '../../../actions/production/packing';
import Missing from './missing';
import icAddIcon from '../../../stylesheets/images/ic-add.svg';
import _ from 'lodash';
import CustomPagination from '../../common/datatables/CustomPagination';

class ScreenPacking extends BaseComponent {
  constructor(props, context) {
    super(props, context);

    this.state = {
      notifies: [],
      items: [],
      meta: {},
      pageSize: 10,
      search: { key: PACKING_LIST_SEARCH_KEY.PACKING_TYPE, value: '', beginDate: '', endDate: '' },
      pageNumber: 1,
      columns: [
        { name: '', type: 'checkbox', value: 'check', checkable: 'checkable', options: { event: this.onCheck } },
        { name: I18n.t('PageProduction.PackingType'), value: 'packingType' },
        { name: I18n.t('PageProduction.Customer'), value: 'customer' },
        { name: I18n.t('PageProduction.CustomerPO'), value: 'customerPO' },
        {
          name: I18n.t('PageProduction.PlanTotal'),
          getValue: i => {
            let planTotal = i.planTotal == 0 ? '-' : i.planTotal;
            return planTotal;
          },
        },
        {
          name: I18n.t('PageProduction.ActualTotal'),
          getValue: i => {
            let actualTotal = i.planTotal - i.missing;
            return actualTotal;
          },
        },
        {
          name: I18n.t('PageProduction.Missing'),
          value: 'missing',
          options: { style: { cursor: 'pointer' }, onClick: this.showDialog, clsTh: 'center', clsTd: 'center' },
        },
        { name: I18n.t('PageProduction.DateTime'), type: 'date', formatType: 'MMM DD, YYYY', value: 'date' },
      ],
      actions: [],
    };
  }

  stockPacking = packing => {
    this.props.dispatch({ type: types.APP.SHOW_LOADING });
    // const options = {}
    packingApi.stockPackingAPI(packing._id.toString()).then(
      rs => {
        this.props.dispatch({ type: types.APP.HIDE_LOADING });

        if (rs.success) {
          toastr.success(I18n.t('Notify.Success'), I18n.t('Notify.StockPackingSuccess'));
          this.getList(this.getBaseOption());
        } else toastr.error(I18n.t('Notify.Error'), rs.error);
      },
      err => {
        this.props.dispatch({ type: types.APP.HIDE_LOADING });
        toastr.error(I18n.t('Notify.Error'), err.error);
      }
    );
  };

  showDialog = packing => {
    if (packing.missing > 0 && !packing.missingStatus) {
      let { openModal, closeModal } = this.props.modal;
      let modal = <Missing closeModal={closeModal} packing={packing} stockPacking={this.stockPacking} />;
      openModal(modal);
    }
  };

  onCheck = (item, name, value) => {
    const { items, notifies } = this.state;
    let bool = false;
    for (const vl of items) {
      if (vl._id == item._id) {
        item[name] = value;
        const idx = _.findIndex(notifies, n => n._id == vl._id);
        if (value) {
          idx < 0 && notifies.push(vl);
        } else {
          idx >= 0 && notifies.splice(idx, 1);
        }
        bool = true;
        break;
      }
    }
    bool && this.setState({ items, notifies });
  };

  notify = date => {
    const { notifies } = this.state;
    this.props.dispatch({ type: types.APP.SHOW_LOADING });
    packingApi.notify({ notifies, date }).then(
      rs => {
        this.props.dispatch({ type: types.APP.HIDE_LOADING });

        if (rs.success) {
          toastr.success(I18n.t('Notify.Success'), I18n.t('Notify.NotifyShippingSuccess'));
          this.getList(this.getBaseOption());
        } else {
          toastr.error(I18n.t('Notify.Error'), rs.error);
          this.getList(this.getBaseOption());
        }
      },
      err => {
        this.props.dispatch({ type: types.APP.HIDE_LOADING });
        toastr.error(I18n.t('Notify.Error'), err.error);
      }
    );
    this.setState({ notifies: [] });
  };

  modalNotify = () => {
    let { openModal, closeModal } = this.props.modal;
    let modal = <ModalNotifyPacking closeModal={closeModal} notify={this.notify} />;
    openModal(modal);
  };

  addPacking = () => {
    let { openModal, closeModal } = this.props.modal;
    let modal = <ModalAddPacking closeModal={closeModal} ok={this.ok} />;
    openModal(modal);
  };

  editPacking = packing => {
    this.props.packingActions.getInfoPacking(packing);
    let pageList = [...this.props.appState.pageList];
    pageList.push({
      screen: packing.carton == 'Pre-pack' ? PRODUCTION_SCREEN.ADD_PACKING_PRE_PACK : PRODUCTION_SCREEN.ADD_PACKING,
      data: { isEdit: true, packing },
    });
    this.props.appActions.addPage(pageList);
  };

  showDeleteConfirm = item => {
    let { openModal, closeModal } = this.props.modal;
    let modal = (
      <ModalConfirm
        title={I18n.t('PageProduction.DELETEPACKING')}
        confirmMessage={I18n.t('PageProduction.DeleteConfirm')}
        messageOk={I18n.t('Btn.YES')}
        messageCancel={I18n.t('Btn.CANCEL')}
        handleClose={closeModal}
        handleOk={() => this.deletePacking(item._id)}
      />
    );
    openModal(modal);
  };

  deletePacking = id => {
    this.props.dispatch({ type: types.APP.SHOW_LOADING });
    // const options = {}
    packingApi.deletePackingDAL(id).then(
      rs => {
        this.props.dispatch({ type: types.APP.HIDE_LOADING });

        if (rs.success) {
          toastr.success(I18n.t('Notify.Success'), I18n.t('Notify.DeletePackingSuccess'));
          this.getList(this.getBaseOption());
        } else toastr.error(I18n.t('Notify.Error'), rs.error);
      },
      err => {
        this.props.dispatch({ type: types.APP.HIDE_LOADING });
        toastr.error(I18n.t('Notify.Error'), err.error);
      }
    );
  };

  ok = (cartonType, order, packingType) => {
    let pageList = [...this.props.appState.pageList];
    pageList.push({
      screen: cartonType == 'Pre-pack' ? PRODUCTION_SCREEN.ADD_PACKING_PRE_PACK : PRODUCTION_SCREEN.ADD_PACKING,
      data: { order, packingType, isAdd: true },
    });
    this.props.appActions.addPage(pageList);
  };

  resetItems = items => {
    const { notifies } = this.state;
    return _.map(items, item => {
      const idx = _.findIndex(notifies, n => n._id == item._id);
      return {
        ...item,
        planTotal: item.planTotal || item.planPpTotal,
        actualTotal: item.actualTotal || item.actualPpTotal,
        check: idx >= 0 ? true : false,
        // missing: (item.planTotal - item.actualTotal),
        checkable: !((item.planTotal > 0 || item.planPpTotal > 0) && _.isEmpty(item.notifyDate)),
      };
    });
  };

  getBaseOption = () => {
    const { pageSize, pageNumber } = this.state;

    let options = {
      sort: JSON.stringify({ date: -1 }),
      // populate: JSON.stringify([
      //   { path: 'order', select: 'customerPO' },
      //   { path: 'customer', select: 'name' },
      //   { path: 'styles.style', select: 'style'},
      //   { path: 'styles.cutTickets.cutTicket', select: 'cutTicket store shipMode mainLabel', populate: { path: 'store', select: 'name packingStandard', populate: { path: 'packingStandard', select: 'name' }}},
      //   { path: 'styles.cutTickets.colors.color', select: 'name sizes QAProgress'}
      // ]),
      pageSize,
      pageNumber,
    };
    return options;
  };

  onChangeSearch = (property, value, pos) => {
    const { search } = this.state;
    switch (property) {
      case SEARCH_BAR_EVENT.FILTER: {
        this.setState({ search: { key: value, value: '', beginDate: '', endDate: '' } });
        break;
      }
      case SEARCH_BAR_EVENT.SEARCH: {
        if (this.state.search.key === 'createdAt') {
          if (pos === 'from') {
            search.beginDate = value;
            this.setState({ search });
          }
          if (pos === 'to') {
            search.endDate = value;
            this.setState({ search });
          }
        } else {
          this.setState({ search: { ...this.state.search, value: value } });
        }
        break;
      }
    }
  };

  onSearch = () => {
    const { search } = this.state;
    const options = this.getBaseOption();
    // options.pageNumber = 1;
    this.props.dispatch({ type: types.APP.SHOW_LOADING });
    // const options = {}
    packingApi.search(options, search).then(
      rs => {
        this.props.dispatch({ type: types.APP.HIDE_LOADING });

        if (rs.success) {
          this.setState({
            items: this.resetItems(_.get(rs, 'data', [])),
            meta: _.get(rs, 'meta', {}),
          });
        } else toastr.error(I18n.t('Notify.Error'), rs.error);
      },
      err => {
        this.props.dispatch({ type: types.APP.HIDE_LOADING });
        toastr.error(I18n.t('Notify.Error'), err.error);
      }
    );
  };

  getList = options => {
    const { search } = this.state;
    this.props.dispatch({ type: types.APP.SHOW_LOADING });
    if (search && search.key && search.value) {
      packingApi.search(options, search).then(
        rs => {
          this.props.dispatch({ type: types.APP.HIDE_LOADING });

          if (rs.success) {
            this.setState({
              items: this.resetItems(_.get(rs, 'data', [])),
              meta: _.get(rs, 'meta', {}),
            });
          } else toastr.error(I18n.t('Notify.Error'), rs.error);
        },
        err => {
          this.props.dispatch({ type: types.APP.HIDE_LOADING });
          toastr.error(I18n.t('Notify.Error'), err.error);
        }
      );
    } else {
      packingApi.getListDAL(options).then(
        rs => {
          this.props.dispatch({ type: types.APP.HIDE_LOADING });
          if (rs.success) {
            this.setState({
              items: this.resetItems(_.get(rs, 'data', [])),
              meta: _.get(rs, 'meta', {}),
            });
          } else toastr.error(I18n.t('Notify.Error'), rs.error);
        },
        err => {
          this.props.dispatch({ type: types.APP.HIDE_LOADING });
          toastr.error(I18n.t('Notify.Error'), err.error);
        }
      );
    }
  };

  selectPage = page => {
    this.setState({ pageNumber: page });
    const options = {
      ...this.getBaseOption(),
      pageNumber: page,
    };
    this.getList(options);
  };

  componentWillMount = () => {
    this.getList(this.getBaseOption());
  };

  render = () => {
    let {
      tabCurrent,
      roles: { showCreate, showEdit, showDelete, showView },
    } = this.props;
    let { items, columns, actions, meta } = this.state;
    const fields = SEARCH_LIST.PACKING_LIST_SEARCH_LIST;
    let check = [];
    check = items.filter(it => it.check);
    let disableNotify = true;
    if (check.length > 0) {
      disableNotify = false;
    }
    actions = [];
    if (showView || showEdit) {
      actions.push(() => {
        return { type: ACTION_TYPE.EDIT, click: item => this.editPacking(item) };
      });
    }
    if (showDelete) {
      actions.push(row => {
        if (row.checkable) return {};
        return { type: ACTION_TYPE.DELETE, click: item => this.showDeleteConfirm(item) };
      });
    }
    return (
      <div className={tabCurrent === 'packing' ? 'show' : 'hide'}>
        <div>
          <div>
            {showCreate && (
              <div className="wrap-stock-in">
                <span className="cursor-pointer" onClick={() => this.addPacking()}>
                  <img src={icAddIcon} alt="" />
                  {I18n.t('PageProduction.ADD_PACKING')}
                </span>
              </div>
            )}
            <SearchBar fields={fields} onChangeSearch={this.onChangeSearch} onSearch={this.onSearch} />
          </div>

          <div className="wrap-global-table">
            <TableList
              {...this.props}
              items={items || []}
              columns={columns || []}
              actions={actions || []}
              options={{ clsTbl: 'global-table' }}
            />
            <CustomPagination items={meta.pages} activePage={meta.page} onSelect={this.selectPage} />

            <div className="center clearBoth mg-top-15">
              {disableNotify ? (
                <button className="btn bt-orange disabled text-uppercase">
                  {I18n.t('PageProduction.NotifyShipping')}
                </button>
              ) : (
                <button onClick={this.modalNotify} className="bt-orange text-uppercase">
                  {I18n.t('PageProduction.NotifyShipping')}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };
}

function mapStateToProps(state) {
  return {
    appState: state.appState,
    authState: state.authState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    packingActions: bindActionCreators(packingActions, dispatch),
    dispatch,
  };
}

ScreenPacking.propTypes = {
  authState: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ScreenPacking);
