import * as types from '../constants/actionTypes';
import {showLoading, hideLoading} from './commonUseActions';
import cutTicketServices from '../services/cutTicketServices';

import _ from 'lodash';

function getListCutTicket (styleId, options = {}) {
  return (dispatch) => {
    showLoading(types, dispatch);

    cutTicketServices.getListCutTicket(styleId, options).then((res) => {
      res && res.success && dispatch({
        type: types.CUT_TICKET.GET_LIST_SUCCESS,
        data: _.get(res, 'data', {})
      });

      hideLoading(types, dispatch);
    }, (error) => {
      hideLoading(types, dispatch);
    });
  };
}

function getDetail (id, reset = true) {
  return (dispatch) => {
    showLoading(types, dispatch);

    reset && dispatch({
      type: types.CUT_TICKET.RESET_DETAIL,
      data: {}
    });

    cutTicketServices.getDetail(id, {}).then((res) => {
      hideLoading(types, dispatch);
      if (res) {
        dispatch({
          type: types.CUT_TICKET.GET_DETAIL_SUCCESS,
          data: res.data || {}
        });
      }
    }, (error) => {
      hideLoading(types, dispatch);
    });
  };
}

function deleteCutTicket (id) {
  return (dispatch) => {
    showLoading(types, dispatch);

    cutTicketServices.deleteCutTicket(id).then((res) => {
      hideLoading(types, dispatch);
      if (res) {
        dispatch({
          type: types.CUT_TICKET.RELOAD,
          data: true
        });
        dispatch({
          type: types.ORDER.RELOAD_DETAIL,
          data: true
        });
      }
    }, (error) => {
      hideLoading(types, dispatch, error);
    });
  };
}

function editCutTicket (id, data) {

  return (dispatch) => {
    showLoading(types, dispatch);

    return cutTicketServices.editCutTicket(id, data).then((res) => {
      hideLoading(types, dispatch, (!res.success && res.error) ? res.error : undefined);
      if (res && res.success) {
        dispatch({
          type: types.CUT_TICKET.RELOAD,
          data: true
        });
        dispatch({
          type: types.ORDER.RELOAD_DETAIL,
          data: true
        });
        return res;
      }
    }, (error) => {
      hideLoading(types, dispatch, error);
    });
  };
}

function editColorCutTicket (id, data) {
  return (dispatch) => {
    showLoading(types, dispatch);

    return cutTicketServices.editColorCutTicket(id, data).then((res) => {
      hideLoading(types, dispatch, (!res.success && res.error) ? res.error : undefined);
      if (res && res.success) {
        dispatch({
          type: types.CUT_TICKET.RELOAD,
          data: true
        });
        dispatch({
          type: types.ORDER.RELOAD_DETAIL,
          data: true
        });
      }
      return res
    }, (error) => {
      hideLoading(types, dispatch, error);
      return error
    });
  };
}

function addCutTicket (orderId, data) {
  return (dispatch) => {
    showLoading(types, dispatch);

    cutTicketServices.addCutTicket(orderId, data).then((res) => {
      hideLoading(types, dispatch);
      if (res) {
        dispatch({
          type: types.CUT_TICKET.RELOAD,
          data: true
        });
      }
    }, (error) => {
      hideLoading(types, dispatch, error);
    });
  };
}

function updateCutTicketColorProgress (cutTicketId, data) {
  return (dispatch) => {
    showLoading(types, dispatch);

    return cutTicketServices.updateCutTicketColorProgress(cutTicketId, data).then((res) => {
      hideLoading(types, dispatch);
      if (res) {
        dispatch({
          type: types.CUT_TICKET.RELOAD,
          data: true
        });
        return res;
      }
    }, (error) => {
      hideLoading(types, dispatch, error);
    });
  };
}

function deleteCutTicketColorProgress (cutTicketId, data) {
  return (dispatch) => {
    showLoading(types, dispatch);

    return cutTicketServices.deleteCutTicketColorProgress(cutTicketId, data).then((res) => {
      hideLoading(types, dispatch);
      if (res) {
        dispatch({
          type: types.CUT_TICKET.RELOAD,
          data: true
        });
        return res;
      }
    }, (error) => {
      hideLoading(types, dispatch, error);
    });
  };
}

function deleteCutTicketColorQAProgress (cutTicketId, data) {
  return (dispatch) => {
    showLoading(types, dispatch);

    return cutTicketServices.deleteCutTicketColorQAProgress(cutTicketId, data).then((res) => {
      hideLoading(types, dispatch);
      if (res) {
        dispatch({
          type: types.CUT_TICKET.RELOAD,
          data: true
        });
        return res;
      }
    }, (error) => {
      hideLoading(types, dispatch, error);
    });
  };
}

function updateCutTicketColorQAProgress (cutTicketId, data) {
  return (dispatch) => {
    showLoading(types, dispatch);

    return cutTicketServices.updateCutTicketColorQAProgress(cutTicketId, data).then((res) => {
      hideLoading(types, dispatch);
      if (res) {
        dispatch({
          type: types.CUT_TICKET.RELOAD,
          data: true
        });
        return res;
      }
    }, (error) => {
      hideLoading(types, dispatch, error);
    });
  };
}

export default {
  getListCutTicket,
  deleteCutTicket,
  editCutTicket,
  editColorCutTicket,
  addCutTicket,
  getDetail,
  updateCutTicketColorProgress,
  deleteCutTicketColorProgress,
  deleteCutTicketColorQAProgress,
  updateCutTicketColorQAProgress,
};
