const C = "CREATE_INVOICE_IE_DECLARATION";

export default {
    //INVOICE INFO
    CHANGE_NAME_FIELD: `${C}_CHANGE_NAME_FIELD`,
    CHANGE_INVOICE_NO: `${C}_CHANGE_INVOICE_NO`,
    CHANGE_INVOICE_DATE: `${C}_CHANGE_ISSUED_DATE`,
    CHANGE_ORDER_FEES: `${C}_CHANGE_ORDER_FEES`,
    CHANGE_NOTE: `${C}_CHANGE_NOTE`,

    //LIST DECLARATION
    CHANGE_LIST_DECLARATION: `${C}CHANGE_LIST_DECLARATION`,
    CHANGE_CHECKED_LIST: `${C}_CHANGE_CHECKED_LIST`,
    CHANGE_SEARCH_DECLARATION_VALUE: `${C}_CHANGE_SEARCH_DECLARATION_VALUE`,
    
    CREATE_INVOICE_SUCCESS: `${C}_CREATE_INVOICE_SUCCESS`,
    RESET_DATA: `${C}_RESET_DATA`,
    ACTIVE_SCREEN_STATUS: `${C}_ACTIVE_SCREEN_STATUS`
}