import 'babel-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import router from './router.js';
import configureStore from './store/configureStore';
import ReduxToastr from 'react-redux-toastr'

import './stylesheets/font-awesome/css/font-awesome.css';
import '../node_modules/react-datetime/css/react-datetime.css';
import './stylesheets/css/react-datetime.css';
import './stylesheets/sass/main.scss';
import './stylesheets/css/style.css';
import './stylesheets/css/override.css';
import './I18n/I18n'; // keep before root container

const store = configureStore();
window.store = store;
ReactDOM.render(
  <Provider store={store}>
    <div>
      {router}
      <ReduxToastr
        timeOut={10000}
        newestOnTop={false}
        preventDuplicates
        position="top-right"
        transitionIn="fadeIn"
        transitionOut="fadeOut"
        progressBar={false}/>
      </div>
  </Provider>,
  document.getElementById('app-erp')
);