import {
  getListProgress,
  getListProductionOrders,
  getListActualYield,
  updateActualYield,
  getListQAOrders,
  getListPackingOrders
} from '../api/productionApi';
import _ from 'lodash';

class productionServices {
  getListProductionOrders (options = {}) {
    options.query && _.set(options, 'query', JSON.stringify(options['query']));
    options.populate = (options.populate ? `${options.populate}` : '');
    options.sort = options.sort || {createdAt: -1};
    _.set(options, 'sort', JSON.stringify(options.sort));
    return getListProductionOrders(options);
  }

  getListQAOrders (options = {}) {
    options.query && _.set(options, 'query', JSON.stringify(options['query']));
    options.populate = (options.populate ? `${options.populate}` : '');
    options.sort = options.sort || {createdAt: -1};
    _.set(options, 'sort', JSON.stringify(options.sort));
    return getListQAOrders(options);
  }

  getListPackingOrders (options = {}) {
    options.query && _.set(options, 'query', JSON.stringify(options['query']));
    options.populate = (options.populate ? `${options.populate}` : '');
    options.sort = options.sort || {createdAt: -1};
    _.set(options, 'sort', JSON.stringify(options.sort));
    return getListPackingOrders(options);
  }

  getListProgress (id, options = {}) {
    options.query && _.set(options, 'query', JSON.stringify(options['query']));
    options.populate = (options.populate ? `${options.populate}` : '');
    options.sort = options.sort || {createdAt: -1};
    _.set(options, 'sort', JSON.stringify(options.sort));
    return getListProgress(id, options);
  }

  getListActualYield (id, options = {}) {
    options.query && _.set(options, 'query', JSON.stringify(options['query']));
    options.populate = (options.populate ? `${options.populate}` : '');
    options.sort = options.sort || {createdAt: -1};
    _.set(options, 'sort', JSON.stringify(options.sort));
    return getListActualYield(id, options);
  }

  updateActualYield (id, data) {
    return updateActualYield(id, data);
  }
}

export default new productionServices();
