const C = 'RECEIVABLE_INVOICE_LIST_HISTORY';

export default {
    CHANGE_LIST_INV: `${C}_CHANGE_LIST_INV`,
    CHANGE_PAGING_TOTAL: `${C}_CHANGE_PAGING_TOTAL`,
    CHANGE_LOADING: `${C}_CHANGE_LOADING`,
    CHANGE_LIST_FILTER: `${C}CHANGE_LIST_FILTER`,
    CHANGE_SELECT_FILTER: `${C}CHANGE_SELECT_FILTER`,
    CHANGE_SEARCH_VALUE: `${C}CHANGE_SEARCH_VALUE`,
    CHANGE_PAGING_CURRENT: `${C}_CHANGE_PAGING_CURRENT`,
    CHANGE_CHECKED_INVOICE: `${C}_CHANGE_CHECKED_INVOICE`,
    CHANGE_TOTAL_PAID: `${C}CHANGE_TOTAL_PAID`,
    CHANGE_TOTAL_AMOUNT: `${C}CHANGE_TOTAL_AMOUNT`,
    CHANGE_TOTAL_REMAINING: `${C}CHANGE_TOTAL_REMAINING`,
    CHANGE_INVOICE_REQUEST: `${C}_CHANGE_INVOICE_REQUEST`,
    CREATE_INV_REQUEST_SUCCESS: `${C}_CREATE_INV_REQUEST_SUCCESS`,
    RESET_INVOICE_REQUEST: `${C}_RESET_INVOICE_REQUEST`,
    DELETE_SUCCESS: `${C}_DELETE_SUCCESS`,
    RESET_DELETE_STATUS: `${C}_RESET_DELETE_STATUS`,
    CHANGE_INVOICE_WILL_BE_DELETED: `${C}_CHANGE_INVOICE_WILL_BE_DELETED`,
    CHANGE_INVOICE_DETAIL: `${C}_CHANGE_INVOICE_DETAIL`
}