import C from '../../../constants/accounting/payable/editInvoiceIEContainer';
import { combineReducers } from "redux";
import _ from 'lodash';

const invoiceDetail = (state = null, action) => {
    const { type, payload } = action;
    let dic = _.cloneDeep(state);
    switch (type) {
        case C.CHANGE_INVOICE_DETAIL:
            return { ...payload, receipt: { isEdit: false } };
        case C.CHANGE_NAME_VALUE:
            dic.invoiceDetail.name = payload;
            return dic;
        case C.CHANGE_INVOICE_NO_VALUE:
            dic.invNo = payload;
            return dic;
        case C.CHANGE_ISSUED_DATE_VALUE:
            dic.issuedDate = payload;
            return dic;
        case C.CHANGE_DUE_DATE_VALUE:
            dic.dueDate = payload;
            return dic;
        case C.SAVE_CHANGE_CONTAINER_DETAIL:
            const { _id } = payload || {};
            dic = _.cloneDeep(state);
            dic.invoiceDetail.containers.map(item => item._id === _id && _.assign(item, payload));
            return dic;
        case C.SUBMIT_FILES:
            dic = _.cloneDeep(state);
            dic.files = payload;
            return dic;
        case C.SUBMIT_RECEIPT:
            dic = _.cloneDeep(state);
            dic.receipt = { ...payload, isEdit: true };
            return dic;
        case C.RESET_DATA_ALL:
            return null;
        default:
            return state;
    }
}

const company = (state = '', action) => {
    const { type, payload } = action;
    switch (type) {
        case C.CHANGE_COMPANY_VALUE:
            return payload;
        case C.RESET_DATA_ALL:
            return '';
        default:
            return state;
    }
}

const rawData = (state = '', action) => {
    const { type, payload } = action;
    switch (type) {
        case C.COPY_CONTAINER_DETAIL_TO_RAW_DATA:
            return payload;
        case C.CHANGE_RAW_DATA_VALUE:
            const { key, value } = payload;
            let dic = _.cloneDeep(state);
            dic[key] = value;
            return dic;
        case C.RESET_DATA_ALL:
            return '';
        default:
            return state;
    }
}

const paymentType = (state = 'cash', action) => {
    const { type, payload } = action;
    switch (type) {
        case C.CHANGE_PAYMENT_TYPE:
            return payload;
        case C.RESET_DATA_ALL:
            return 'cash';
        default:
            return state;
    }
}

const transferFiles = (state = [], action) => {
    const { type, payload } = action;
    switch (type) {
        case C.COPY_FILES_TO_RAW_DATA:
            return payload;
        case C.ADD_TRANSFER_FILE: {
            const stateClone = _.cloneDeep(state);
            stateClone.push(payload);
            return stateClone;
        }
        case C.REMOVE_TRANSFER_FILE:
            return state.filter(file => file.id !== payload);
        case C.EMPTY_FILES_IN_RAW_DATA:
            return [];
        case C.RESET_DATA_ALL:
            return [];
        default:
            return state;
    }
}

const receipt = (state = {}, actions) => {
    const { type, payload } = actions;
    switch (type) {
        case C.CREATE_RECEIPT:
            return payload;
        case C.CHANGE_RECEIPT_VALUE: {
            const { key, value } = payload;
            let dic = _.cloneDeep(state);
            dic[key] = value;
            return dic;
        }
        case C.EMPTY_RECEIPT:
            return {}
        case C.RESET_DATA_ALL:
            return {};
        default:
            return state;
    }
}

export const payAmount = (state = 0, action) => {
    const { type, payload } = action;
    switch (type) {
        case C.CHANGE_PAY_AMOUNT_VALUE:
            return payload;
        case C.RESET_DATA_ALL:
            return 0;
        default:
            return state;
    }
}

export const success = (state = false, action) => {
    const { type, payload } = action;
    switch (type) {
        case C.SAVE_SUCCESS:
            return payload;
        case C.RESET_DATA_ALL:
            return false;
        default:
            return state;
    }
}

const activeScreen = (state = false, action) => {
    const { type, payload } = action;
    switch (type) {
        case C.ACTIVE_SCREEN_STATUS:
            return payload;
        default:
            return state;
    }
}

export const editInvoiceImpExpContainer = combineReducers({
    invoiceDetail,
    company,
    rawData,
    paymentType,
    transferFiles,
    receipt,
    payAmount,
    success,
    activeScreen,
})