import * as types from '../constants/actionTypes';
import { showLoading, hideLoading } from './commonUseActions';
import purchaseOrderServices from '../services/purchaseOrderServices';

function getListItem(options = {}) {
  return dispatch => {
    showLoading(types, dispatch);

    purchaseOrderServices.getListItem(options).then(
      res => {
        hideLoading(types, dispatch);
        dispatch({
          type: types.PURCHASE_ORDER.GET_LIST_SUCCESS,
          data: res.data || [],
        });
      },
      error => {
        hideLoading(types, dispatch);
      }
    );
  };
}

function getListPurchasesInbound(options = {}) {
  return dispatch => {
    showLoading(types, dispatch);

    purchaseOrderServices.getListPurchasesInbound(options).then(
      res => {
        hideLoading(types, dispatch);
        dispatch({
          type: types.PURCHASE_ORDER.GET_LIST_SUCCESS,
          data: res.data || [],
        });
      },
      error => {
        hideLoading(types, dispatch);
      }
    );
  };
}

function getDetail(id, reset = true) {
  return dispatch => {
    showLoading(types, dispatch);

    reset &&
      dispatch({
        type: types.PURCHASE_ORDER.RESET_DETAIL,
        data: {},
      });

    purchaseOrderServices.getDetail(id, {}).then(
      res => {
        hideLoading(types, dispatch);

        if (res) {
          dispatch({
            type: types.PURCHASE_ORDER.GET_DETAIL_SUCCESS,
            data: res.data || {},
          });
        }
      },
      error => {
        hideLoading(types, dispatch);
      }
    );
  };
}

function getDetailByKey(key) {
  return dispatch => {
    showLoading(types, dispatch);

    purchaseOrderServices.getDetailByKey(key, {}).then(
      res => {
        hideLoading(types, dispatch);

        res &&
          res.success &&
          dispatch({
            type: types.PURCHASE_ORDER.GET_KEY_SUCCESS,
            data: { key: key, values: _.get(res, 'data.values', []) },
          });
      },
      error => {
        hideLoading(types, dispatch);
      }
    );
  };
}

function deleteItem(id) {
  return dispatch => {
    showLoading(types, dispatch);

    purchaseOrderServices.deleteItem(id).then(
      res => {
        hideLoading(types, dispatch);

        if (res) {
          dispatch({
            type: types.PURCHASE_ORDER.RELOAD,
            data: true,
          });
        }
      },
      error => {
        hideLoading(types, dispatch, error);
      }
    );
  };
}

function editItem(id, data) {
  return dispatch => {
    // showLoading(types, dispatch);

    return purchaseOrderServices.editItem(id, data).then(
      res => {
        // hideLoading(types, dispatch);

        if (res) {
          dispatch({
            type: types.APP.SET_DATA_PAGE_CURRENT,
            data: { isEdit: false },
          });
          dispatch({
            type: types.PURCHASE_ORDER.RELOAD,
            data: true,
          });
          dispatch({
            type: types.APP.ADD_PAGE_CURRENT,
            data: [],
          });
        }
        return res;
      },
      error => {
        // hideLoading(types, dispatch, error);
      }
    );
  };
}

function editAndSendRevised(id, data) {
  return dispatch => {
    // showLoading(types, dispatch);

    return purchaseOrderServices.editAndSendRevised(id, data).then(
      res => {
        // hideLoading(types, dispatch);

        if (res) {
          dispatch({
            type: types.APP.SET_DATA_PAGE_CURRENT,
            data: { isEdit: false },
          });
          dispatch({
            type: types.PURCHASE_ORDER.RELOAD,
            data: true,
          });
          dispatch({
            type: types.APP.ADD_PAGE_CURRENT,
            data: [],
          });
        }
        return res;
      },
      error => {
        // hideLoading(types, dispatch, error);
      }
    );
  };
}

function addItem(data) {
  return dispatch => {
    // showLoading(types, dispatch);

    purchaseOrderServices.addItem(data).then(
      res => {
        // hideLoading(types, dispatch);
        if (res) {
          dispatch({
            type: types.APP.SET_DATA_PAGE_CURRENT,
            data: { isEdit: false },
          });
          dispatch({
            type: types.APP.ADD_PAGE_CURRENT,
            data: [],
          });
          dispatch({
            type: types.PURCHASE_ORDER.RELOAD,
          });
        }
      },
      error => {
        // hideLoading(types, dispatch, error);
      }
    );
  };
}

export default {
  getListItem,
  deleteItem,
  editItem,
  addItem,
  getDetail,
  getDetailByKey,
  getListPurchasesInbound,
  editAndSendRevised,
};
