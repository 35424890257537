import { combineReducers } from 'redux';
import C from '../../../constants/customer-po/current/showOrderList';
const initialState = [];
const fetching = (state = false, action) => {
  const { type } = action;
  switch (type) {
    case C.FETCH_ORDER_LIST:
      return true;
    case C.CANCEL_FETCH_ORDER_LIST:
      return false;
    default:
      return state;
  }
};

const loadMore = (state = true, action) => {
  const { type } = action;
  switch (type) {
    case C.LOAD_MORE:
      return true;
    case C.CANCEL_LOAD_MORE:
      return false;
    default:
      return state;
  }
};

const isSearch = (state = false, action) => {
  const { type } = action;
  switch (type) {
    case C.IS_SEARCH:
      return true;
    case C.CANCEL_IS_SEARCH:
      return false;
    default:
      return state;
  }
};

const numberPage = (state = 1, action) => {
  const { type, payload } = action;
  switch (type) {
    case C.CHANGE_PAGE_NUMBER:
      return payload;
    case C.RESET_CHANGE_PAGE_NUMBER:
      return 1;  
    default:
      return state;
  }
}

const orders = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case C.CHANGE_ORDER_LIST:
      return state.concat(...payload);
    case C.CHANGE_FIRST_ORDER_LIST: 
      return payload;
    case C.REMOVE_ORDER_LIST: 
      return state.filter(function(item) { 
        return item._id !== payload; 
      });
    case C.UPDATE_ORDER_LIST: 
    debugger
    // items = items.map(x => (x.id === item.id) ? item : x)
      return state.map(x => (x._id === payload._id) ? payload : x)
    default:
      return state;
  }
};

const selectedOrder = (state = null, action) => {
  const { type, payload } = action;
  switch (type) {
    case C.CHANGE_SELECTED_ORDER:
      return payload;
    default:
      return state;
  }
};

const filterText = (state ='', action) => {
  const { type, payload } = action;
  switch (type) {
    case C.CHANGE_FILTER_TEXT:
      return payload;
    default:
      return state;
  }
}

const loading = (state = true, action) => {
  const { type } = action;
  switch (type) {
    case C.LOADING:
      return true;
    case C.CANCEL_LOADING:
      return false;
    default:
      return state;
  }
}

export default combineReducers({
  fetching,
  orders,
  selectedOrder,
  filterText,
  numberPage,
  loadMore,
  isSearch,
  loading
});
