import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { formatNumber, textTrim } from '../../../functions/helpers';
import I18n from 'i18n-js';
import moment from 'moment';
import './style.scss';

import icNotifyIcon from '../../../stylesheets/images/ic-notify.png';
import icAddIcon from '../../../stylesheets/images/ic-add.svg';

const BarOrderInfo = ({ShippedAndSendnotify, order, addCutTicket, sendNotify, onStartProduction, isStartDate, styleSelected, canStartProduction}) => {
  
  const age = function(a, b) {
    a.setHours(0,0,0,0)
    b.setHours(0,0,0,0)
    const daysLeft = moment(b).diff(moment(a), 'days') + 1
    return daysLeft > 0 ? daysLeft : 0
  }
  return (
    <div className="md content-main-header">
      <ul className="content-main-header-top">
        <li className="content-main-date">
          <span className="top">{moment(order.orderDate).format('MMM DD')}</span>
          <span className="line"><span /></span>
          <span className="bottom">{moment(order.orderDate).format('YYYY')}</span>
        </li>
        <li className="content-main-title bold">
          {_.get(order, 'customer.name', '')}
        </li>
        <li className="content-main-po">
          <div className="content-title-po">
            <span className="title mg-top-0 mg-bottom-0">{I18n.t('PageOrder.CustomerPO')}</span>
            <span className="divide mg-top-0 mg-bottom-0"></span>
            <span className="name bold mg-top-0 mg-bottom-0 mg-right-5">
              <span className="ellipsisContent">{textTrim(order.customerPO, 14)}</span>
            </span>
          </div>
          <div className="content-title-po">
            <span className="title mg-top-0 mg-bottom-0">{I18n.t('PageOrder.SaleOrder')}</span>
            <span className="number mg-top-0 mg-bottom-0 mg-right-5"> <span className="bold">{order.internalPO}</span></span>
          </div>
        </li>
        <li className="content-main-total">
          <span className="text">{I18n.t('PageOrder.G-Total')}</span>
          <span className="number">{formatNumber(order.gTotal)}</span>
        </li>
        { ShippedAndSendnotify == true &&
          <li className="content-main-shipped">
            <span className="text">{I18n.t('PageOrder.Shipped')}</span>
            <span className="number color-green">0</span>
          </li>
        }
        <li className="content-main-factory">
          <span className="text">{I18n.t('PageOrder.Ex-Factory')}</span>
          <span className="date">{order.exFactory ? moment(order.exFactory).format('MMMM DD, YYYY') : ''}</span>
        </li>
        <li className="content-main-factory">
          <span className="text">{I18n.t('PageOrder.IHDate')}</span>
          <span className="date">{order.ihDate ? moment(order.ihDate).format('MMMM DD, YYYY') : ''}</span>
        </li>
        {
          isStartDate && (!order.startDate ?
            canStartProduction && (<li className="content-main-factory">
            <span className="text mg-0">{I18n.t('PageProduction.StartProduction')}</span>
            <span className="icon mg-0 size-40 color-orange" onClick={onStartProduction}><i
              className="fa fa-youtube-play" aria-hidden="true" /></span>
          </li>) :
            <li className="content-main-factory">
              <span className="text mg-0">{I18n.t('PageProduction.DaysLeft')}</span>
              <span className="icon mg-0 days-left"><span
                className="bold size-40 color-orange">{order.exFactory ? age(new Date(), new Date(order.exFactory)) : 0}</span></span>
            </li>)
        }
        { ShippedAndSendnotify == true && (
          <li className="bd-solid-left position-relative w-100 z-index-10">
            <div className="content-main-add-ticket cursor-pointer" onClick={sendNotify}>
              <span className="icon"><img src={icNotifyIcon} alt="" /></span>
              <span className="text">{I18n.t('PageOrder.SendNotify')}</span>
            </div>
            <div className="wrap-button-add-cut-ticket cursor-pointer text-align-right" style={styleSelected._id ? {dislay: ''} : {display : 'none'}} onClick={addCutTicket}>
              <span className="icon inline-block w-auto" onClick={onStartProduction}><img src={icAddIcon} alt="" /></span>
              <span className="text inline-block w-auto">{I18n.t('PageOrder.ADDCUTTICKET')}</span>
            </div>
          </li>
        )}
      </ul>
    </div>
  );
};

BarOrderInfo.propTypes = {
  order: PropTypes.object.isRequired,
  styleSelected: PropTypes.object,
  addCutTicket: PropTypes.func,
  onStartProduction: PropTypes.func,
  showCreate: PropTypes.bool,
  showEdit: PropTypes.bool,
  showDelete: PropTypes.bool,
  isSuperAdmin: PropTypes.bool,
  isStartDate: PropTypes.bool,
  sendNotify: PropTypes.func,
  canStartProduction: PropTypes.bool
};

export default BarOrderInfo;
