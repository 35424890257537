import React from 'react';
import PropTypes from 'prop-types';

import FormGroup from '../forms/FormGroup';
import Input from '../forms/Input';
import QuickSearchSelect from '../../components/forms/QuickSearchSelect';

const AccountsReceivablePlan = props => {
  return (
    <div id="accounting-receivable-plan">
      <div
        id="header-accounting"
        className="box-shadow-1 inline-block w-full position-relative page-accounting-supplier">
        <div className="left w-200" />
        <div className="right top-0 w-full pd-left-300">
          <div className="border-title-left" />
          <div className="title-left left  wrap-input-global">
            <div className="input pd-0 position-relative">
              <FormGroup
                label="Supplier"
                type="select pd-right-0"
                inputComponent={
                  <QuickSearchSelect
                  // value={R.path(['id'], props.accountsPayable)}
                  // onChangeItem={item =>
                  //     props.handleChange('accountsPayable', item)
                  // }
                  />
                }
              />
            </div>

            <div className="style-checkbox mg-5-0 text-right">
              <input type="checkbox" id="vat_per_customer_1" className="display-none" value="on" />
              <label htmlFor="vat_per_customer_1">
                <span className="mg-right-10">
                  <span />
                </span>
                VAT per customer
              </label>
            </div>

            <div className="input pd-0 position-relative">
              <FormGroup
                label="Invoice No."
                type="select pd-right-0"
                inputComponent={
                  <Input
                  // value={R.path(['id'], props.accountsPayable)}
                  // onChangeItem={item =>
                  //     props.handleChange('accountsPayable', item)
                  // }
                  />
                }
              />
            </div>

            <div className="input pd-0 position-relative">
              <FormGroup
                label="Credit For Sea Freight"
                type="select pd-right-0"
                inputComponent={
                  <Input
                  // value={R.path(['id'], props.accountsPayable)}
                  // onChangeItem={item =>
                  //     props.handleChange('accountsPayable', item)
                  // }
                  />
                }
              />
            </div>

            <div className="input pd-0 position-relative">
              <FormGroup
                label="Price Type"
                type="select pd-right-0"
                inputComponent={
                  <QuickSearchSelect
                  // value={R.path(['id'], props.accountsPayable)}
                  // onChangeItem={item =>
                  //     props.handleChange('accountsPayable', item)
                  // }
                  />
                }
              />
            </div>
          </div>

          <div className="bd-dashed-bottom wrap-input-global pd-15-0 margin-small">
            <div className="col-md-6 col-xs-12 input">
              <FormGroup
                label="Issued Date"
                type="select"
                inputComponent={
                  <QuickSearchSelect
                  // value={R.path(['id'], props.accountsPayable)}
                  // onChangeItem={item =>
                  //     props.handleChange('accountsPayable', item)
                  // }
                  />
                }
              />
            </div>
            <div className="col-md-6 col-xs-12 input">
              <FormGroup type="position-relative top-7 pd-left-160" label="Amount INV" inputComponent={'$1,000'} />
            </div>
            <div className="col-md-6 col-xs-12 input">
              <FormGroup type="position-relative top-7 bold" label="Payment Term" inputComponent={'60 days'} />
            </div>
            <div className="col-md-6 col-xs-12 input">
              <FormGroup type="position-relative top-7 pd-left-160" label="Total Air Cost" inputComponent={'$1,000'} />
            </div>
            <div className="col-md-6 col-xs-12 input">
              <FormGroup
                type="input"
                label="Due Date"
                inputComponent={
                  <Input
                  // type="text"
                  // value={}
                  // onChange={e =>
                  //     props.handleChange('', e.target.value)
                  // }
                  // placeholder=""
                  />
                }
              />
            </div>
            <div className="col-md-6 col-xs-12 input">
              <FormGroup
                type="position-relative top-7 pd-left-160"
                label="Total Credit for Sea Freight"
                inputComponent={'$1,000'}
              />
            </div>
            <div className="col-md-6 col-xs-12 input">
              <FormGroup
                label="Ship Mode"
                type="select"
                inputComponent={
                  <QuickSearchSelect
                  // value={R.path(['id'], props.accountsPayable)}
                  // onChangeItem={item =>
                  //     props.handleChange('accountsPayable', item)
                  // }
                  />
                }
              />
            </div>
            <div className="col-md-6 col-xs-12 input">
              <FormGroup
                type="position-relative top-7 color-number bold pd-left-160"
                label="Total Amount INV"
                inputComponent={'$1,500'}
              />
            </div>
          </div>

          <div className="bottom text-left size-12 pd-15-0 inline-block w-full">
            <div className="col-xs-12">
              <p>
                <span className="mg-right-15">Say</span>{' '}
                <span className="bold">Forty-three thousand five hundred twenty-two dollars and twenty-two cents</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="content-main-accounting">
        <div className="content-sidebar top-initial left-0">
          <div className="md content-sidebar-search">
            <input type="text" placeholder="Search" />
            <span>
              <i className="fa fa-search" aria-hidden="true" />
            </span>
          </div>
          <div className="md content-sidebar-menu">
            <ul className="overflow-y-scroll h-full">
              <li className="style-checkbox">
                <input type="checkbox" id="content-sidebar-menu-1" className="display-none" value="on" />
                <label htmlFor="content-sidebar-menu-1">
                  <span className="mg-right-10">
                    <span />
                  </span>
                  VAT per month
                </label>
              </li>
              <li className="style-checkbox">
                <input type="checkbox" id="content-sidebar-menu-2" className="display-none" value="on" />
                <label htmlFor="content-sidebar-menu-2">
                  <span className="mg-right-10">
                    <span />
                  </span>
                  VAT per month
                </label>
              </li>
              <li className="style-checkbox">
                <input type="checkbox" id="content-sidebar-menu-3" className="display-none" value="on" />
                <label htmlFor="content-sidebar-menu-3">
                  <span className="mg-right-10">
                    <span />
                  </span>
                  VAT per month
                </label>
              </li>
            </ul>
          </div>
        </div>

        {/* <!-- Component Header Convert --> */}
        <div id="header-plan-accounts-payable">
          <div className="wrap-input-global box-shadow bg-white float-left overflow-hidden">
            <div className="col-md-4 col-xs-12 input">
              <FormGroup
                label="Convert to"
                type="select"
                inputComponent={
                  <QuickSearchSelect
                  // value={R.path(['id'], props.accountsPayable)}
                  // onChangeItem={item =>
                  //     props.handleChange('accountsPayable', item)
                  // }
                  />
                }
              />
            </div>

            <div className="col-md-4 col-xs-12 input">
              <FormGroup
                type="input"
                label="Rate"
                inputComponent={
                  <Input
                  // type="text"
                  // value={}
                  // onChange={e =>
                  //     props.handleChange('', e.target.value)
                  // }
                  // placeholder=""
                  />
                }
              />
            </div>

            <div className="col-md-4 col-xs-12 text col-md-4 col-xs-12 text bd-dashed-left">
              <p>
                Amount Convert <span className="mg-left-15 color-number bold size-13">9,0000 VND</span>
              </p>
              <p>
                Total Amount Convert 4 <span className="mg-left-15 color-number bold size-13">9,0000 VND</span>
              </p>
            </div>
          </div>
        </div>

        {/* <!-- End Component --> */}

        {/* <!-- Component --> */}
        <div className="bg-white box-shadow wrap-input-global pd-15-0 margin-small">
          <div className="col-md-6 col-xs-12 input">
            <ul>
              <li>
                <span>VAT No</span>
              </li>
              <li>
                <input type="text" className="form-control" />
              </li>
            </ul>
          </div>
          <div className="col-md-6 col-xs-12 input">
            <ul>
              <li>
                <span>Total Amount</span>
              </li>
              <li>
                <span className="color-number bold position-relative top-7">$400</span>
              </li>
            </ul>
          </div>
          <div className="col-md-6 col-xs-12 input">
            <ul>
              <li>
                <span>VAT Date</span>
              </li>
              <li>
                <input type="text" className="form-control" />
              </li>
            </ul>
          </div>
          <div className="col-md-6 col-xs-12 input">
            <ul>
              <li>
                <span>VAT Amount</span>
              </li>
              <li>
                <span className="color-number bold position-relative top-7">$400</span>
              </li>
            </ul>
          </div>
          <div className="col-md-6 col-xs-12 input">
            <ul>
              <li>
                <span>Tax Rate</span>
              </li>
              <li>
                <input type="text" className="form-control" />
              </li>
            </ul>
          </div>
          <div className="col-md-6 col-xs-12 input">
            <ul>
              <li>
                <span>Grand Total VAT</span>
              </li>
              <li>
                <span className="color-number bold position-relative top-7">$400</span>
              </li>
            </ul>
          </div>

          <div className="col-xs-12 input h-auto">
            <ul>
              <li>
                <span>Note</span>
              </li>
              <li className="textarea">
                <textarea rows="4" cols="50" />
              </li>
            </ul>
          </div>
        </div>
        {/* <!-- End Component --> */}

        <div className="panel-group mg-0" id="panel-heading-main" role="tablist" aria-multiselectable="true">
          <div className="panel panel-default bd-none radius-0">
            {/* <!-- Component --> */}
            <div className="panel-heading component" role="tab" id="">
              <div className="panel-title">
                <a
                  role="button"
                  data-toggle="collapse"
                  data-parent="#panel-heading-main"
                  href="#panel-heading-1"
                  aria-expanded="true"
                  aria-controls="collapseOne">
                  <i className="fa fa-caret-down" aria-hidden="true" />
                </a>
                <ul>
                  <li className="float-left">
                    <div className="text">
                      <span className="left">Purchase Order</span>
                      <span className="right size-15">
                        <span className="medium">1709-00001</span>
                      </span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            {/* <!-- End Component --> */}
            <div
              id="panel-heading-1"
              className="panel-collapse collapse in"
              role="tabpanel"
              aria-labelledby="panel-heading-1">
              <div className="panel-body pd-0 bg-gray">
                <ul className="bg-white content-header-title pd-left-15 bd-solid-bottom-3">
                  <li>
                    <p>
                      Customer PO <span className="bold mg-left-25 size-14 color-green">12312</span>
                    </p>
                  </li>
                  <li className="float-right">
                    <div className="inline-block mg-right-30">
                      <span>LADIES KNIT DRESS - 90</span>
                    </div>
                  </li>
                </ul>
                {/* <!-- Component --> */}
                <div className="bg-white wrap-input-global pd-15-0-0-0 margin-small">
                  <div className="col-md-4 col-xs-12 input">
                    <ul>
                      <li>
                        <span>Air Cost</span>
                      </li>
                      <li className="pd-left-60">
                        <input type="text" className="form-control" />
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-4 col-xs-12 input">
                    <ul className="position-relative">
                      <li className="top-0">
                        <span>Total Quantity</span>
                      </li>
                      <li>
                        <span className="color-number bold">$400</span>
                      </li>
                    </ul>
                    <ul className="position-relative">
                      <li className="top-0">
                        <span>Total Quantity</span>
                      </li>
                      <li>
                        <span className="color-number bold">$400</span>
                      </li>
                    </ul>
                  </div>

                  <div className="col-md-4 col-xs-12 input h-auto">
                    <ul>
                      <li>
                        <span>Note</span>
                      </li>
                      <li className="textarea pd-left-40">
                        <textarea rows="4" cols="50" />
                      </li>
                    </ul>
                  </div>
                </div>
                {/* <!-- End Component --> */}

                <div className="wrap-global-table pd-0 inline-block w-full float-left mg-bottom-0">
                  <table className="global-table box-shadow-none w-full">
                    <tbody>
                      <tr className="bg-white bd-solid-bottom">
                        <th>CTNS</th>
                        <th className="center">Style</th>
                        <th className="center">PG</th>
                        <th className="center">Cut Ticket</th>
                        <th className="center">Contractor</th>
                        <th className="center">Store</th>
                        <th className="center">Lable</th>
                        <th className="center">Color</th>
                        <th className="center">QTY</th>
                        <th className="center w-100">Unit Price (LDP)</th>
                        <th className="center">Total</th>
                      </tr>
                      <tr>
                        <td>1 -> 10</td>
                        <td className="center">19</td>
                        <td className="center">19</td>
                        <td className="center">Aug 13, 2017</td>
                        <td className="center">yard</td>
                        <td className="center">$5</td>
                        <td className="center">yard</td>
                        <td className="center">$5</td>
                        <td className="center">$5</td>
                        <td className="center input">
                          <input type="text" className="form-control center" />
                        </td>
                        <td className="center color-number">$234.20</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

AccountsReceivablePlan.propTypes = {
  typeSupplier: PropTypes.string,
  handleTypeSupplier: PropTypes.func,
};

AccountsReceivablePlan.defaultProps = {
  typeSupplier: null,
  handleTypeSupplier: f => f,
};

export default AccountsReceivablePlan;
