import React from 'react';
import I18n from 'i18n-js';
import DateTimeField from 'react-datetime';
import moment from 'moment';
import SearchSelect from '../forms/SearchSelect';

const flexStyle = {
  display: 'flex',
  alignItem: 'center',
  justifyContent: 'space-between',
};

const PurchasePlanPlaceOrder = props => {
  const { orderDetail, selectedStyle, materials, supplier, listPayer, sumGTotal, remark, dataApi } = props;
  // console.log(materials)
  return (
    <div id="content">
      {/* <BarOrderInfo order={orderDetail}/> */}
      <div className="md content-main-process" style={flexStyle}>
        <div className="style">
          <ul className="content-main-header-filter-style">
            <li className="title">
              <p>
                {' '}
                {I18n.t('PagePurchase.CustomerPO')}
                <span className="color-orange medium size-16 mg-left-15"> {(orderDetail || {}).customerPO}</span>
              </p>
            </li>
            <li className="title mg-left-15">
              <p>
                {' '}
                {I18n.t('PagePurchase.Style')}
                <span className="color-orange medium size-16 mg-left-15">
                  {' '}
                  {((selectedStyle || {}).raw || {}).style}
                </span>
              </p>
            </li>
            <li className="title mg-left-15">
              <p>
                {' '}
                {I18n.t('PagePurchase.Supplier')}
                <span className="color-orange medium size-16 mg-left-15"> {supplier}</span>
              </p>
            </li>
          </ul>
        </div>
      </div>

      <div className="wrap-global-table">
        <table style={{ width: '100%' }} className="global-table data-time-table">
          <tbody className="body-data-time edit-purchase">
            <tr>
              <th className="center">{I18n.t('PagePurchase.Material')}</th>
              <th className="center w-100">
                {I18n.t('PagePurchase.Replacement')}
                <br />
                P.0 #
              </th>
              <th className="center">{I18n.t('PagePurchase.XMIL')}</th>
              <th className="center">{I18n.t('PagePurchase.ETD')}</th>
              <th className="center">{I18n.t('PagePurchase.ETA')}</th>
              <th className="center">
                {I18n.t('PagePurchase.Expected')}
                <br />
                {I18n.t('PagePurchase.ReceivedDate')}
              </th>
              <th className="center">{I18n.t('PagePurchase.Payer')}</th>
              <th className="center w-80">{I18n.t('PagePurchase.Quantity')}</th>
              <th className="center">{I18n.t('PagePurchase.Convert_Quantity')}</th>
              <th className="center">{I18n.t('PagePurchase.Price')}</th>
              <th className="center w-80">{I18n.t('PagePurchase.Surcharge')}</th>
              <th className="center w-80">{I18n.t('PagePurchase.Discount')}</th>
              <th className="center">
                {I18n.t('PagePurchase.Total')}
                <br />
                {I18n.t('PagePurchase.Price')}
              </th>
            </tr>
            {materials.map((item, index) => {
              return (
                <tr key={index}>
                  <td>
                    {item.material} <br /> <span className="title-size">({item.color})</span>
                  </td>
                  <td className="center w-150">{item.replacementPO}</td>
                  <td className="center select date-time-td w-150">
                    <DateTimeField
                      timeFormat={false}
                      dateFormat="MMM DD, YYYY"
                      value={moment(item.XMIL)}
                      onChange={date => props.changeMaterialValueInPlaceOrder(index, 'XMIL', date.format())}
                    />
                  </td>
                  <td className="center select date-time-td w-150">
                    <DateTimeField
                      timeFormat={false}
                      dateFormat="MMM DD, YYYY"
                      value={moment(item.ETD)}
                      onChange={date => props.changeMaterialValueInPlaceOrder(index, 'ETD', date.format())}
                    />
                  </td>
                  <td className="center select date-time-td w-150">
                    <DateTimeField
                      timeFormat={false}
                      dateFormat="MMM DD, YYYY"
                      value={moment(item.ETA)}
                      onChange={date => props.changeMaterialValueInPlaceOrder(index, 'ETA', date.format())}
                    />
                  </td>
                  <td className="center select date-time-td w-150">
                    <DateTimeField
                      timeFormat={false}
                      dateFormat="MMM DD, YYYY"
                      value={moment(item.exReceivedDate)}
                      onChange={date => props.changeMaterialValueInPlaceOrder(index, 'exReceivedDate', date.format())}
                    />
                  </td>
                  <td className="center select date-time-td w-100">
                    <SearchSelect
                      options={listPayer || []}
                      value={item.payer || ''}
                      onChange={o => props.changeMaterialValueInPlaceOrder(index, 'payer', (o || {}).value || '')}
                      // isLoading={props.fetchingSelectableSuppliers}
                      clearable={false}
                    />
                  </td>
                  <td className="center">{item.quantityText}</td>
                  <td className="center">{item.convertQuantity}</td>
                  <td className="color-number center">{(item.supplier || {}).price}</td>
                  <td className="color-number center">
                    <input
                      className="form-control center"
                      type="number"
                      min="0"
                      value={item.surcharge}
                      onChange={e => props.changeMaterialValueInPlaceOrder(index, 'surcharge', Number(e.target.value))}
                    />
                  </td>
                  <td className="color-number center">
                    <input
                      className="form-control center"
                      type="number"
                      min="0"
                      value={item.discount}
                      onChange={e => props.changeMaterialValueInPlaceOrder(index, 'discount', Number(e.target.value))}
                    />
                  </td>
                  <td className="color-number center">{item.amount}</td>
                </tr>
              );
            })}

            <tr className="g-total">
              {/* <td colSpan="1">{I18n.t('PagePurchase.Status')}</td>
                            <td colSpan="2" className="pd-0-15">
                                <p className="text-left">{'currentStatus'}</p>
                            </td> */}
              <td colSpan={'13'}>
                <ul>
                  <li>{I18n.t('PagePurchase.G-Total')}</li>
                  <li className="color-number pd-0-5">{sumGTotal}</li>
                </ul>
              </td>
            </tr>

            <tr className="bg-white wrap-textarea">
              <td colSpan="1" className="center vertical-align-inherit">
                {I18n.t('PagePurchase.Remark')}
              </td>
              <td colSpan="13">
                <textarea
                  rows="6"
                  cols="20"
                  className="mg-bottom-50"
                  value={remark}
                  onChange={e => props.changeRemark(e.target.value)}
                />
              </td>
            </tr>
          </tbody>
        </table>
        <div className="text-center">
          <ul className="list-button">
            <li>
              <a className="bg-black" onClick={() => props.previewOrder()}>
                {I18n.t('Btn.PreviewOrder')}
              </a>
            </li>

            <li>
              <a className="bg-orange" onClick={() => props.save({ ...dataApi, status: 'Draft' })}>
                {I18n.t('Btn.Save')}
              </a>
            </li>

            <li>
              <a className="bg-green" onClick={() => props.send({ ...dataApi, status: 'New' })}>
                {I18n.t('Btn.Send')}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default PurchasePlanPlaceOrder;
