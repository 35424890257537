import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import I18n from 'i18n-js';

import EditOrderModal from '../../../components/customer-po/EditOrderModal';

import * as selectors from '../../../../selectors/customer-po/current/editOrder';
import * as permissions from '../../../../selectors/permissions';
import * as actions from '../../../../actions/customer-po/current/editOrder';


class UpdateOrderModal extends Component {
  componentWillMount() {
    this.props.loadData();
  }

  render() {
    return (
      <EditOrderModal
        title={I18n.t('PageOrder.EDITORDER')}
        {...this.props}
        showAdvance
        onSubmitClick={() => this.props.submitOrder(this.props.fnBody())}
      />
    )
  }
}

const mapStateToProps = state => ({
  isOpen: selectors.isOpen(state),
  customerPO: selectors.getCustomerPO(state),
  orderDate: selectors.getOrderDate(state),
  exFactory: selectors.getExFactory(state),
  ihDate: selectors.getIhDate(state),
  description: selectors.getDescription(state),
  selectableCustomers: selectors.getSelectableCustomers(state),
  selectedCustomer: selectors.getSelectedCustomer(state),
  isCompleted: selectors.isCompleted(state),
  note: selectors.getNote(state),

  errors: selectors.getErrors(state),
  canSave: selectors.canSave(state),
  canComplete: permissions.isSpecial({moduleKey: "customerPO", ...state}),
  submiting: selectors.isSubmiting(state),
  fnBody: selectors.fnGenerateRequestBody(state),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  loadData() {
    dispatch(actions.listSelectableCustomers());
    dispatch(actions.listValidateOrderNames());
  },

  onCloseClick() {
    dispatch(actions.hideModal());
  },

  submitOrder(order) {
    dispatch(actions.submitOrder(order));
  },

  onChangeSelectedCustomer(item) {
    dispatch(actions.changeSelectedCustomer(item ? item.raw : null));
  },

  onChangeCustomerPO(value) {
    dispatch(actions.changeCustomerPO(value));
  },

  onChangeOrderDate(value) {
    dispatch(actions.changeOrderDate(value));
  },

  onChangeExFactory(value) {
    dispatch(actions.changeExFactory(value));
  },

  onChangeIhDate(value) {
    dispatch(actions.changeIhDate(value));
  },

  onChangeDescription(value) {
    dispatch(actions.changeDescription(value));
  },

  onChangeCompleteted(value) {
    dispatch(actions.changeCompleted(value));
  },

  onChangeNote(value) {
    dispatch(actions.changeNote(value));
  },

});

export default connect(mapStateToProps, mapDispatchToProps) (withRouter(UpdateOrderModal));
