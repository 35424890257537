import * as R from 'ramda';
import _ from 'lodash';
import { createSelector } from "reselect";
import formatCurrency from 'format-currency';
export const getListOrder = ({ purchasePlans }) => purchasePlans.purchaseOrders;

export const getPageNumber = ({ purchasePlans }) => purchasePlans.pageNumber;
export const getFilterText = ({ purchasePlans }) => purchasePlans.filterText;
export const isOpenLoadMore = ({ purchasePlans }) => purchasePlans.loadMore;
export const isSearch = ({ purchasePlans }) => purchasePlans.isSearch;
export const getOrders = ({ purchasePlans }) => purchasePlans.purchaseOrders;
export const isLoading = ({ purchasePlans }) => purchasePlans.loading;

export const getSelectedOrder = ({ purchasePlans }) => purchasePlans.selectedOrder;

export const getListStyle = ({ purchasePlans }) => {
    const { listStyles } = purchasePlans;
    return _.map(listStyles, s => ({
        value: s._id,
        label: s.style,
        raw: s,
    }));
};

export const getSelectedStyle = ({ purchasePlans }) => {
    const { selectedStyle } = purchasePlans;
    return {
        value: R.pathOr('', ['_id'], selectedStyle),
        raw: selectedStyle
    }
};

export const getCheckedFabric = ({ purchasePlans }) => purchasePlans.checkedFabric;

export const getCheckedTrim = ({ purchasePlans }) => purchasePlans.checkedTrim;

const genNumberWithCurrency = (amount, currency) => {
    if (currency == "USD") {
        return '$' + formatCurrency(_.round(amount, 4));
    }
    if (currency == 'VND') {
        return formatCurrency(_.round(amount, 4)) + ' VND';
    }
    return formatCurrency(_.round(amount, 4))
}

export const getListMaterial = createSelector(
    [
        ({ purchasePlans }) => purchasePlans.materials,
        getCheckedFabric,
        getCheckedTrim,
    ], (data, fabricBool, trimBool) => {
        if(_.isEmpty(data)) return [];
        const fabric = fabricBool ? 'FABRIC' : null;
        const trim = trimBool ? 'TRIM' : null;
        let materials = _.cloneDeep(data).filter(item => item.material.type === fabric || item.material.type === trim)
        let results = [];
        materials.map(item => results.push({
            ...item,
            content: ((item.material || {}).data || {}).content,
            suppliers: item.availableSuppliers.map(a => ({
                label: a.supplierName,
                value: a.supplierId,
                currency: a.currency,
                price: a.price,
                paymentTerm: a.paymentTerm || '',
                name: a.supplierName,
                company: a.company || {},
                phoneNumber: a.phoneNumber,
                fax: a.fax,
                email: a.email,
            })),
            _id: (item.material || {})._id,
            price: genNumberWithCurrency(((item.supplier || {}).price || 0), (item.supplier || {}).currency),
            amount: genNumberWithCurrency((((item.supplier || {}).price || 0) * (item.needToBuy || 0)), (item.supplier || {}).currency),
            amountNumber: (((item.supplier || {}).price || 0) * (item.needToBuy || 0)),
            supplier: item.supplier || {},
            material: item.material.name,
            inStock: item.replacementPO ? 0 : item.inStock,
            quantityText: item.needToBuy + ' ' + ((item.material || {}).data || {}).unit,
            convertQuantity: _.round((item.needToBuy || 0) * Number((item.material || {}).convertTo || 0), 2) + ' ' + (item.material || {}).convertUnit
        }));
        return results;
    }
)

export const getListItemsForPreview = createSelector(
    [
        getListMaterial
    ], data => {
        let results = data.filter(item => !_.isEmpty(item.supplier));
        let grouppedArray = _.groupBy(results, 'supplier.label')
        let useStock = data.filter(item => item.inStock > 0 && item.needToBuy == 0)
        results = _.toArray(grouppedArray);
        useStock.length > 0 && results.push(useStock);
        return results;
    }
)

export const getListGTotal = createSelector(
    [
        getListItemsForPreview
    ], data => {
        let results = [];
        data.map(item => results.push(genNumberWithCurrency(_.sum(item.map(it => it.amountNumber)), ((item[0] || {}).supplier || {}).currency)))
        return results;
    }
)

export const getListCanUseStock = createSelector(
    [
        getListItemsForPreview
    ], data => {
        let results = [];
        data.map(item => results.push(((item[0] || {}).inStock)))
        return results;
    }
)

export const getCheckedEmpty = createSelector(
    [
        getListMaterial,
        getListCanUseStock
    ], (data, listInStock) => {
        const sumNeedToBuy = _.sum(data.map(item => item.needToBuy || 0));
        const suppliers = data.filter(item => !_.isEmpty(item.supplier));
        const sumInStock = _.sum(listInStock.map(item => item))
        if (sumInStock > 0) {
            return '';
        }
        if (sumNeedToBuy == 0) {
            return 'emptyNeedToBuy';
        }
        if (_.isEmpty(suppliers)) {
            return 'emptySupplier';
        }
        return '';
    }
)

export const fnGenRequestDataForUseStock = ({ purchasePlans }) => (materials) => {
    if (purchasePlans.selectedStyle) {
        const status = materials.filter(item => item.inStock < item.quantity).length === 0 ? 'New' : 'Waiting for approval';
        const results = {
            status : "New", // will change flow
            styleId: purchasePlans.selectedStyle._id,
            type: 'Use stock', // will change flow
            materials: materials.map(m => ({
                ...m,
                material: m._id,
            }))
        }
        return results
    }
    return {};
}

export const getCreateSuccessStatus = ({ purchasePlans }) => purchasePlans.createSuccessStatus;

export const getSelectedMaterials = ({ purchasePlans }) => {
    return purchasePlans.selectedMaterials.map(item => ({...item, quantity: item.needToBuy, amount: genNumberWithCurrency(item.totalPrice, item.supplier.currency)}))
};

export const getSupplier = createSelector(
    [
        getSelectedMaterials
    ], data => {
        return data && data[0] && (data[0].supplier || {}).label
    }
)

export const getCurrencyOfSupplier = createSelector(
    [
        getSelectedMaterials
    ], data => {
        return data && data[0] && (data[0].supplier || {}).currency
    }
)

export const getListPayer = () => {
    return ['LM', 'FI', 'Customer', 'Subcontractor'].map(item => ({
        label: item,
        value: item,
    }))
}

export const getSumGTotalText = createSelector(
    [
        getSelectedMaterials,
        getCurrencyOfSupplier
    ], (data, currency) => {
        return genNumberWithCurrency(_.sum(data.map(item => _.get(item, 'totalPrice', 0))), currency)
    }
)

export const getSumGTotal = createSelector(
    [
        getSelectedMaterials,

    ], (data) => {
        return _.sum(data.map(item => item.amountNumber || 0))
    }
)

export const getRemark = ({ purchasePlans }) => purchasePlans.remark;

export const genRequestBody = createSelector(
    [
        ({ purchasePlans }) => purchasePlans,

    ], (purchasePlans) => {
        const { selectedOrder, selectedStyle, selectedMaterials, remark } = purchasePlans;
        const results = {
            styleId: selectedStyle._id,
            supplierId: ((selectedMaterials[0] || {}).supplier || {}).value,
            type: 'Reserved',
            remark,
            materials: selectedMaterials.map(item => ({
                ...item,
                material: item._id,
                price: item.supplier.price,
                totalPrice: item.totalPrice,
                discount: item.discount,
                surcharge: item.surcharge,
            }))
        }
        return results
    }
)

export const getFirstLoad = ({purchasePlans}) => purchasePlans.firstLoad;