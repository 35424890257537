import React, {Component}           from 'react';
import PropTypes                    from 'prop-types';
import { connect }                  from 'react-redux';
import _                            from 'lodash';

import {sourceTypes} from '../../../constants/EnumTypes';
import FabricDetail                 from './FabricDetail';


class YieldFabricDetail extends Component {
  constructor (props, context) {
    super(props, context);

    this.state = {};
  }
  // groupColor(estimates, fieldName) {
  //   let data = _.groupBy(estimates.filter(vl => vl.color != null),"color.name");
  //   return Object.keys(data).map(vl => {
  //     let quantity = data[vl].reduce((acc, item) => {
  //       _.get(item, 'color.sizes', []).forEach(e => {
  //         acc += (e.quantity || 0);
  //       });
  //       return acc;
  //     }, 0);
  //     const idx = _.findIndex(data[vl], i => i[fieldName] == '')
  //     return {
  //       color: vl,
  //       quantity,
  //       selectColor: idx >= 0 ? '' : data[vl][0][fieldName]
  //     };
  //   });
  // }

  render () {
    let {data, name, thName, fieldName, clsName, style = {}} = this.props;
    let itemNominated = data.find(i => _.get(i, 'info.type', '') === sourceTypes.NOMINATED) || null;
    return (
      <div className={clsName}>
        <div className="header-ticket">
          <ul>
            <li>
              <h3 style={style}>{name}</h3>
            </li>
            <li>
              <span>{itemNominated ? 'Nominated' : 'Sourced'}</span>
              {itemNominated ? <span>{_.get(itemNominated, 'info.replacementPO', '')}</span> : null }
            </li>
          </ul>
        </div>

        {data.map((item, idx) => {
          let est = _.get(item,  'estimate', []);
          let props = {item, est, thName, fieldName};
          return (<FabricDetail key={idx} {...props} style={style}/>); 
        })}
      </div>
    );
  }
}

function mapStateToProps (state, ownProps) {
  return {
    authState: state.authState
  };
}

function mapDispatchToProps (dispatch) {
  return {};
}

YieldFabricDetail.propTypes = {
  authState: PropTypes.object.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(YieldFabricDetail);
