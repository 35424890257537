import { combineReducers } from 'redux';

import appState from './appReducer';
import authState from './authReducer';
import userState from './userReducer';
import orderState from './orderReducer';

import showListOrders from './customer-po/current/showListOrders';
import showOrderDetail from './customer-po/current/showOrderDetail';
import createOrder from './customer-po/current/createOrder';
import editOrder from './customer-po/current/editOrder';
import cancelOrder from './customer-po/current/cancelOrder';
import createStyle from './customer-po/current/createStyle';
import editStyle from './customer-po/current/editStyle';
import deleteStyle from './customer-po/current/deleteStyle';
import createCutTicket from './customer-po/current/createCutTicket';
import editCutTicket from './customer-po/current/editCutTicket';
import editCutTicketImages from './customer-po/current/editCutTicketImages';
import deleteCutTicket from './customer-po/current/deleteCutTicket';
import editCutTicketColors from './customer-po/current/editCutTicketColors';
import showImagesGallery from './customer-po/current/showImagesGallery';

import purchaseState from './purchaseReducer';
import purchasePlanState from './purchasePlanReducer';
import supplierState from './supplierReducer';
import { addSupplier } from './modals/addSupplier';
import styleState from './styleReducer';
import cutTicketState from './cutTicketReducer';
import customerState from './customerReducer';
import storeState from './storeReducer';
import propertyState from './propertyReducer';
import fabricState from './fabricReducer';
import trimState from './trimReducer';
import materialState from './materialReducer';
import sampleApprovalState from './sampleApprovalReducer';
import warehouseState from './warehouseReducer';
import stockInState from './stockInReducer';
import stockOutState from './stockOutReducer';

import purchaseOrderState from './purchaseOrderReducer';
import purchase from './purchase';

import damagedGarmentState from './damagedGarmentReducer';
import damagedMaterialState from './damagedMaterialReducer';
import contractorState from './contractorReducer';
import assignmentState from './assignmentReducer';
import packingStandardState from './packingStandardReducer';
import systemSettingState from './systemSettingsReducer';
import departmentState from './departmentReducer';
import organizationState from './organizationReducer';
import roleState from './roleReducer';
import standardState from './standardReducer';
import productionOrderState from './productionOrdersReducer';
import qaOrderState from './qaOrdersReducer';
import productionProgressState from './productionProgressReducer';
import productionActualYieldState from './productionActualYieldReducer';
import { reducer as toastrReducer } from 'react-redux-toastr';
import shippingState from './shippingReducer';
import payInfoState from './payInfoReducer';
import shipOutState from './shipOutReducer';
import { addPacking } from './production/packingReducer';
import { editPacking } from './production/editPackingReducer';
import { showPklProdList } from './ship-out/showPklProdListReducer';
import { previewSelectedPklProdList } from './ship-out/previewSelectedPklProdList';
import { createPklShip } from './ship-out/createPklShip';
import { editPklShip } from './ship-out/editPklShip';
import { viewPklOfProdDetail } from './ship-out/viewPklOfProdDetailReducer';
import { showPklShipList } from './ship-out/showPklShipListReducer';
import { createINV } from './ship-out/createINVReducer';
import { createShipment } from './ship-out/createShipmentReducer';
import createPayableInvoiceSupplier from './accounting/payable/createInvoiceSupplier';
import createPayableInvoiceContractor from './accounting/payable/createInvoiceContractor';
import { createPayableInvoiceImpExpContainer } from './accounting/payable/createInvoiceImpExpContainers';
import { createPayableInvoiceImpExpDeclarations } from './accounting/payable/createInvoiceImpExpDeclarations';
import { createPayableInvoiceImpExpUSDelivery } from './accounting/payable/createInvoiceImpExpUSDelivery';
import { showPayableInvoiceList } from './accounting/payable/showInvoiceList';
import { showPayableHistoryInvoiceList } from './accounting/payable/showHistoryInvoiceList';
import { createReceivableInvoiceNormal } from './accounting/receivable/createInvoiceNormal';
import { createReceivableInvoiceChargeback } from './accounting/receivable/createInvoiceChargeback';
import { showReceivableInvoiceList } from './accounting/receivable/showInvoiceList';
import { showReceivableInvoiceListHistory } from './accounting/receivable/showInvoiceListHistory';
import dashboardState from './dashboardReducer';
import { showShipOutList } from './ship-out/showShipOutListReducer';
import { editShipment } from './ship-out/editShipmentReducer';
import { showHistoryList } from './ship-out/showHistoryListReducer';
import { editInvoiceImpExpContainer } from './accounting/payable/editInvoiceIEContainer';
import { editInvoiceImpExpDeclaration } from './accounting/payable/editInvoiceIEDeclaration';
import { editInvoiceImpExpUSDelivery } from './accounting/payable/editInvoiceIEUSDelivery';
import { editInvoiceSupplierOversea } from './accounting/payable/editInvoiceSupplierOversea';
import { editInvoiceSupplierDomestic } from './accounting/payable/editInvoiceSupplierDomestic';
import editInvoiceContractor from './accounting/payable/editInvoiceContractor';
import { historyPreviewContainer } from './accounting/payable/historyPreviewContainer';
import { historyPreviewDeclaration } from './accounting/payable/historyPreviewDeclaration';
import { historyPreviewUSDelivery } from './accounting/payable/historyPreviewUSDelivery';
import { historyPreviewContractor } from './accounting/payable/historyPreviewContractor';
import { historyPreviewSupplierOversea } from './accounting/payable/historyPreviewSupplierOversea';
import { historyPreviewSupplierDomestic } from './accounting/payable/historyPreviewSupplierDomestic';
import editPackingStandard from './standard/packing/editPackingStandard';

import customerPOReport from './reportAnalytic/customerPOReport';

import { previewReceivableInvoice } from './accounting/receivable/previewInvoice';
import { editReceivableInvoiceNormal } from './accounting/receivable/editInvoiceNormal';
import purchasePlans from './purchase/purchasePlans';

const rootReducer = combineReducers({
  appState,
  authState,
  userState,
  orderState,
  showListOrders,
  showOrderDetail,
  createOrder,
  editOrder,
  cancelOrder,
  createStyle,
  editStyle,
  deleteStyle,
  createCutTicket,
  editCutTicket,
  editCutTicketImages,
  deleteCutTicket,
  editCutTicketColors,
  showImagesGallery,
  purchaseState,
  supplierState,
  addSupplier,
  styleState,
  cutTicketState,
  customerState,
  storeState,
  propertyState,
  fabricState,
  trimState,
  materialState,
  sampleApprovalState,
  warehouseState,
  stockInState,
  stockOutState,
  purchaseOrderState,
  purchase,
  damagedGarmentState,
  damagedMaterialState,
  purchasePlanState,
  contractorState,
  assignmentState,
  packingStandardState,
  systemSettingState,
  departmentState,
  organizationState,
  roleState,
  standardState,
  productionOrderState,
  qaOrderState,
  productionProgressState,
  productionActualYieldState,
  toastr: toastrReducer,
  shippingState,
  payInfoState,
  shipOutState,
  addPacking,
  editPacking,
  showPklProdList,
  previewSelectedPklProdList,
  createPklShip,
  editPklShip,
  viewPklOfProdDetail,
  showPklShipList,
  createINV,
  createShipment,
  createPayableInvoiceSupplier,
  createPayableInvoiceContractor,
  createPayableInvoiceImpExpContainer,
  createPayableInvoiceImpExpDeclarations,
  createPayableInvoiceImpExpUSDelivery,
  showPayableInvoiceList,
  showPayableHistoryInvoiceList,
  createReceivableInvoiceNormal,
  createReceivableInvoiceChargeback,
  showReceivableInvoiceList,
  showReceivableInvoiceListHistory,
  dashboardState,
  showShipOutList,
  editShipment,
  showHistoryList,
  editInvoiceImpExpContainer,
  editInvoiceImpExpDeclaration,
  editInvoiceImpExpUSDelivery,
  editInvoiceSupplierOversea,
  editInvoiceSupplierDomestic,
  editInvoiceContractor,
  editPackingStandard,
  historyPreviewContainer,
  historyPreviewDeclaration,
  historyPreviewUSDelivery,
  historyPreviewContractor,
  historyPreviewSupplierOversea,
  historyPreviewSupplierDomestic,
  customerPOReport,
  previewReceivableInvoice,
  editReceivableInvoiceNormal,
  purchasePlans,
});

export default rootReducer;
