
import C from '../../../constants/customer-po/current/editStyle';
import {toastr} from 'react-redux-toastr';
import * as R from 'ramda';
import I18n from 'i18n-js';
import { listStyles } from './showOrderDetail';
import styleServices from '../../../services/styleServices';

export const showModal = style => dispatch => {
  dispatch({ type: C.SHOW_MODAL, payload: style });
}

export const hideModal = () => dispatch => {
  dispatch({ type: C.RESET_DATA });
}

export const changeStyle = value => dispatch => {
  dispatch({ type: C.CHANGE_STYLE, payload: value });
}

export const changePage = value => dispatch => {
  dispatch({ type: C.CHANGE_PAGE, payload: value });
}

export const addImage = file => dispatch => {
  if(file.type.indexOf('image') < 0){
      toastr.error('Error', I18n.t('ErrorMessages.FileNotImage'));
      return;
  }
  var FileSize = file.size / 1024 / 1024; // in MB
  if (FileSize > 2) {
    toastr.error('Error', I18n.t('ErrorMessages.FileSizeExceeds2MB'));
    return;
  } 
  const readerBase64 = new FileReader();
  readerBase64.onload = (eventBase64) => {
    const url = eventBase64.target.result;
    const filename = file.name;

    const payload = { id: filename, file, filename, url, }
    dispatch({ type: C.ADD_IMAGE, payload });
  };
  readerBase64.readAsDataURL(file);
}

export const removeImage = image => dispatch => {
  dispatch({ type: C.REMOVE_IMAGE, payload: image });
}

export const save = (id, style) => dispatch => {
  dispatch({ type: C.SUBMIT });
  styleServices.editStyle(id, style).then(res => {
    dispatch({ type: C.CANCEL_SUBMIT });
    dispatch(listStyles(R.path(['data', 'orderId'], res)));
    dispatch({ type: C.RESET_DATA });
  }).catch(error => {
    toastr.error('Error', error.error);
    dispatch({ type: C.CANCEL_SUBMIT });
    console.error(error);
  })
}
