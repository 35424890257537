import C from "../../constants/reportAnalytic/customerPOReport";
import reportAnalyticApi from '../../api/reportAnalyticApi';

export const getDataForChart = () => dispatch =>{
    reportAnalyticApi.getDataForChart().then(res => {
        if(res.success) {
            dispatch({type: C.CHANGE_DATA_CHART, payload: res.data})
        }
    })
}

export const changeSearchText = (key, date) => dispatch => {
    dispatch({type: C.CHANGE_SEARCH_VALUE, payload: {key, date}})
}

export const search = (searchValue) => dispatch => {
    const query = searchValue
    reportAnalyticApi.getDataForChart(query).then(res => {
        if(res.success) {
            dispatch({type: C.CHANGE_DATA_CHART, payload: res.data})
        }
    })
}