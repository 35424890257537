import React, { Component } from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import { shipMode } from '../../../constants/EnumTypes';
import customerServices from '../../../services/customerServices';
import _ from 'lodash';
import orderServices from '../../../services/orderServices';
import { PACKING_TYPE } from '../../../constants/constants';
// import {buildCustomerPOToCheckList} from "../../../functions/utils";
import productionServices from '../../../services/productionServices';
import * as types from '../../../constants/actionTypes';
import commonUseActions from '../../../actions/commonUseActions';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { bindActionCreators } from 'redux';
import * as packingActions from '../../../actions/production/packing';

import * as selector from '../../../selectors/production/addPacking';

class ModalAddPacking extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  componentWillMount = () => {
    // this.props.dispatch({type: types.APP.SHOW_LOADING});
    this.getListCustomer();
    this.selectType(PACKING_TYPE.GOHH);
  };

  componentWillReceiveProps(nextProps) {}

  getListCustomer = () => {
    this.props.packingActions.getListCustomer();
  };

  selectCustomer = data => {
    this.props.packingActions.changeSelectedCustomer(data);
  };

  selectCustomerPO = data => {
    this.props.packingActions.changeSelectedCustomerPO(data);
  };

  selectContractor = data => {
    this.props.packingActions.changeSelectedContractor(data);
  };

  selectCartonType = data => {
    this.props.packingActions.changeSelectCartonType(data);
  };

  selectType = data => {
    this.props.packingActions.changePackingType(data);
  };

  ok = () => {
    const { customerPO, contractor, cartonType } = this.props;
    if (_.isEmpty(customerPO)) {
      toastr.info(I18n.t('Notify.Info'), I18n.t('ErrorMessages.PleaseSelectOrder'));
      return;
    }
    if (_.isEmpty(contractor)) {
      toastr.info(I18n.t('Notify.Info'), I18n.t('ErrorMessages.PleaseSelectContractor'));
      return;
    }
    this.props.packingActions.emptyPacking();
    this.props.ok(cartonType);
    this.props.closeModal();
  };

  render = () => {
    // const {customers, customer ,orders, order, packingType, contractors, contractor} = this.state;
    const {
      customers,
      customer,
      customerPOs,
      customerPO,
      contractors,
      contractor,
      packingType,
      cartonType,
    } = this.props;
    return (
      <div className="modal-dialog" role="document" id="modal-add-packing">
        <div className="modal-content">
          <form>
            <div className="modal-header">
              <a className="close" data-dismiss="modal" aria-label="Close" onClick={this.props.closeModal}>
                <span aria-hidden="true">&times;</span>
              </a>
              <h4 className="modal-title" id="myModalLabel">
                {I18n.t('PageProduction.ADDPACKING')}
              </h4>
            </div>
            <div className="modal-body">
              <div className="form-group">
                <div className="col-md-4 col-xs-12 text">
                  <p>{I18n.t('PageProduction.Customer')}</p>
                </div>
                <div className="col-md-8 col-xs-12 value">
                  <select
                    className="form-control"
                    value={_.get(customer, '_id', '')}
                    onChange={e => this.selectCustomer(customers.filter(item => item._id === e.target.value)[0])}>
                    {customers &&
                      customers.map((customer, index) => (
                        <option key={index} value={customer._id}>
                          {customer.name}
                        </option>
                      ))}
                  </select>
                </div>
              </div>

              <div className="form-group pd-top-10 pd-bottom-10">
                <div className="col-md-4 col-xs-12 text">
                  <p>{I18n.t('PageProduction.CustomerPO')}</p>
                </div>
                <div className="col-md-8 col-xs-12 value">
                  <select
                    className="form-control"
                    value={_.get(customerPO, '_id', '')}
                    onChange={e => this.selectCustomerPO(customerPOs.filter(item => item._id === e.target.value)[0])}>
                    {customerPOs &&
                      customerPOs.map((customerPO, index) => (
                        <option key={index} value={customerPO._id}>
                          {customerPO.customerPO}
                        </option>
                      ))}
                  </select>
                </div>
              </div>

              <div className="form-group pd-top-10 pd-bottom-10">
                <div className="col-md-4 col-xs-12 text">
                  <p>{I18n.t('PageProduction.Contractor')}</p>
                </div>
                <div className="col-md-8 col-xs-12 value">
                  <select
                    className="form-control"
                    value={_.get(contractor, '_id', '')}
                    onChange={e =>
                      this.selectContractor(_.head(contractors.filter(item => item._id === e.target.value)))
                    }>
                    {contractors &&
                      contractors.map((contractor, index) => (
                        <option key={index} value={contractor._id}>
                          {contractor.name}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
              {packingType === PACKING_TYPE.CARTON && (
                <div className="form-group pd-top-10 pd-bottom-10">
                  <div className="col-md-4 col-xs-12 text">
                    <p>Carton Type</p>
                  </div>
                  <div className="col-md-8 col-xs-12 value">
                    <select
                      className="form-control"
                      value={cartonType || 'Solid'}
                      onChange={e => this.selectCartonType(e.target.value)}>
                      <option value="Solid">Solid</option>
                      <option value="Pre-pack">Pre-pack</option>
                    </select>
                  </div>
                </div>
              )}

              <ul
                className="nav nav-tabs col-md-8 col-md-offset-4 col-xs-12 wrap-list-radio pd-10-0-15-0"
                role="tablist">
                <li role="presentation" className={packingType === PACKING_TYPE.GOHH ? 'active' : ''}>
                  <a role="tab">
                    <input
                      type="radio"
                      id={PACKING_TYPE.GOHH}
                      checked={packingType === PACKING_TYPE.GOHH}
                      name={PACKING_TYPE.GOHH}
                      onChange={() => this.selectType(PACKING_TYPE.GOHH)}
                    />
                    <label>{I18n.t('PageProduction.GOHH')}</label>
                    <div className="check">
                      <div className="inside" />
                    </div>
                  </a>
                </li>
                <li role="presentation" className={packingType === PACKING_TYPE.CARTON ? 'active' : ''}>
                  <a role="tab">
                    <input
                      checked={packingType === PACKING_TYPE.CARTON}
                      type="radio"
                      id={PACKING_TYPE.CARTON}
                      name={PACKING_TYPE.CARTON}
                      onChange={() => this.selectType(PACKING_TYPE.CARTON)}
                    />
                    <label>{I18n.t('PageProduction.Carton')}</label>
                    <div className="check">
                      <div className="inside" />
                    </div>
                  </a>
                </li>
              </ul>
            </div>
            <div className="modal-footer">
              <button type="button" className="bt-black" onClick={this.props.closeModal}>
                {I18n.t('Btn.CANCEL')}
              </button>
              <button type="button" className="bt-orange" onClick={() => this.ok()}>
                {I18n.t('Btn.OK')}
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  };
}

function mapStateToProps(state, ownProps) {
  return {
    appState: state.appState,
    authState: state.authState,
    customers: selector.getListCustomer(state),
    customer: selector.getCustomer(state),
    cartonType: selector.getCartonType(state),
    customerPOs: selector.getListCustomerPO(state),
    customerPO: selector.getCustomerPO(state),
    contractors: selector.getListContractor(state),
    contractor: selector.getContractor(state),
    packingType: selector.getPackingType(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    commonUseActions: bindActionCreators(commonUseActions, dispatch),
    packingActions: bindActionCreators(packingActions, dispatch),
    dispatch,
  };
}

ModalAddPacking.propTypes = {
  ok: PropTypes.func,
  commonUseActions: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalAddPacking);
