import React from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';

const PreviewInvoiceModal =  props => {
    const style = {
        overlay: {
          position: 'fixed',
          backgroundColor: 'rgba(0, 0, 0, 0.75)',
          zIndex: '3'
        },
        content: {
          position: 'auto',
          top: '50%',
          left: '50%',
          backgroundColor: 'rgba(0,0,0,0)',
          border: '0px',
          borderRadius: '0px',
          outline: 'none',
        },
      };
      const {openModal, invoiceRequest, listCheckedInvoice} = props
    //   console.log(props)
        return (
            <Modal style = {style} isOpen = {openModal}>
                <div className="modal-dialog" role="document" id="accounts-payable-invoice-receipt">
                    <div className="modal-content">
                            <form>
                            <div className="modal-header">
                                <h4 className="modal-title" id="myModalLabel">PREVIEW</h4>
                            </div>
                            <div className="modal-body pd-0">
                                <div className="col-xs-12">
                                    <div className="form-group">
                                        <div className="col-md-4 col-xs-12 text no-padding-left">
                                            <p>INV Request</p>
                                        </div>
                                        <div className="col-md-8 col-xs-12 value no-padding-right">
                                            <input type="text" className="form-control" placeholder="Input here"
                                                value = {invoiceRequest}
                                                onChange = {(e) => props.changeInvoiceRequest(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="bt-black" onClick = {() => props.close()}>CANCEL</button>
                                <button type="button" className="bt-orange" onClick = {() => props.createINVRequest(listCheckedInvoice, invoiceRequest)}>EXPORT</button>
                            </div>
                            </form>
                        </div>
                    </div>
            </Modal>
    );
}

PreviewInvoiceModal.propTypes = {
    invoiceRequest: PropTypes.string,
    listCheckedInvoice: PropTypes.array,
    changeInvoiceRequest: PropTypes.func,
    exportInvoice: PropTypes.func
}
PreviewInvoiceModal.defaultProps = {
    changeInvoiceRequest: f => f,
    exportInvoice: f => f
}

export default PreviewInvoiceModal;