import {
  ACCOUNTING_URL,
  CRM_URL,
  CUSTOMER_PO_URL,
  DASHBOARD_URL,
  ORGANIZATION_URL,
  PRODUCTION_URL,
  PURCHASE_URL,
  REPORT_ANALYTIC_URL,
  SAMPLE_APPROVAL_URL,
  SHIPPING_URL,
  STANDARD_URL,
  SYSTEM_SETTING_URL,
  WAREHOUSE_URL,
  YIELD_URL,
} from './routeConstants';
import I18n from 'i18n-js';

import icDashboardIcon from '../stylesheets/images/ic-dashboard.svg';
import customerIcon from '../stylesheets/images/ic-customer.svg';
import yieldIcon from '../stylesheets/images/ic-yield.svg';
import sampleApprovalIcon from '../stylesheets/images/ic-sample-approval.svg';
import purchaseIcon from '../stylesheets/images/ic-purchase.svg';
import warehouseIcon from '../stylesheets/images/ic-warehouse-w.svg';
import productionIcon from '../stylesheets/images/ic-production-s.svg';
import shippingIcon from '../stylesheets/images/ic-shipping.svg';
import accountingIcon from '../stylesheets/images/ic-accounting-s.svg';
import reportIcon from '../stylesheets/images/ic-report.svg';
import standardIcon from '../stylesheets/images/ic-standard-s.svg';
import organizationIcon from '../stylesheets/images/ic-organization.svg';
import crmIcon from '../stylesheets/images/ic-crm-s.svg';
import systemIcon from '../stylesheets/images/ic-system.svg';

export const ApiMethod = {
  get: 'GET',
  post: 'POST',
  put: 'PUT',
  delete: 'DELETE',
};

export const PropertyKeys = {
  COLOR: 'color',
  UNIT: 'unit',
  ORIGIN: 'origin',
  CONVERT_UNIT: 'convertunit',
  SIZE: 'size',
};

export const PaymentTerms = [
  { value: 'NET10', label: 'NET10' },
  { value: 'NET30', label: 'NET30' },
  { value: 'NET45', label: 'NET45' },
  { value: 'NET60', label: 'NET60' },
  { value: 'T/T Before Delivery', label: 'T/T Before Delivery' },
  { value: 'T/T Before Prodution', label: 'T/T Before Prodution' },
  { value: 'T/T In Advance', label: 'T/T In Advance' },
];

export const PaymentTermsOfCustomer = [
  { value: 'NEED_70_PAYMENT_TO_SHIP', label: 'Need 70% payment to ship' },
  { value: 'NEED_50_PAYMENT_TO_SHIP', label: 'Need 50% payment to ship' },
  { value: 'NEED_50_PAYMENT_TO_DELIVERY', label: 'Need 50% payment to delivery' },
  { value: 'NEED_70_PAYMENT_TO_DELIVERY', label: 'Need 70% payment to delivery' },
  { value: 'ROG7', label: 'ROG 7' },
  { value: 'NET30_ROG', label: 'NET 30 ROG' },
  { value: 'T_T_IN_ADVANCE', label: 'T/T In Advance' },
];

export const Currencies = [
  { value: 'VND', label: 'VND' },
  { value: 'USD', label: 'USD' },
];

export const MaterialTypes = {
  FABRIC: 'FABRIC',
  TRIM: 'TRIM',
};

export const Payers = [
  { value: 'LM', label: 'LM' },
  { value: 'FI', label: 'FI' },
  { value: 'Customer', label: 'Customer' },
  { value: 'Subcontractor', label: 'Subcontractor' },
];

export const PURCHASE_STATUS_KEY = {
  NEW: 'NEW',
  DRAFT: 'DRAFT',
  USE_STOCK: 'USE_STOCK',
  WAITING_FOR_APPROVAL: 'WAITING_FOR_APPROVAL',
  APPROVED: 'APPROVED',
  IN_PROGRESS: 'IN_PROGRESS',
  IN_TRANSIT: 'IN_TRANSIT',
  IN_PORT: 'IN_PORT',
  IN_WAREHOUSE: 'IN_WAREHOUSE',
  COMPLETED: 'COMPLETED',
};

export const PURCHASE_STATUS = {
  NEW: 'New',
  DRAFT: 'Draft',
  USE_STOCK: 'Use stock',
  WAITING_FOR_APPROVAL: 'Waiting for approval',
  APPROVED: 'Approved',
  IN_PROGRESS: 'In progress',
  IN_TRANSIT: 'In transit',
  IN_PORT: 'In port',
  WAITING_FOR_SHIPPING: 'Waiting for shipping',
  IN_WAREHOUSE: 'In warehouse',
  COMPLETED: 'Completed',
};

export const PURCHASES_STATUS_LIST = [
  { value: 'NEW', label: 'New' },
  { value: 'IN_PROGRESS', label: 'In progress' },
  { value: 'IN_TRANSIT', label: 'In transit' },
  { value: 'IN_PORT', label: 'In port' },
  { value: 'WAITING_FOR_SHIPPING', label: 'Waiting for shipping' },
  { value: 'IN_WAREHOUSE', label: 'In warehouse' },
  { value: 'COMPLETED', label: 'Completed' },
];

export const PERMISSION_ROLE = {
  VIEW: 'view_',
  CREATE: 'create_',
  REVISE: 'revise_',
  DELETE: 'delete_',
  SPECIAL: 'special_',
};

export const ORGANIZATION_TAB = {
  ORGANIZATION: 'organization_organization',
  DEPARTMENT: 'organization_department',
  PERMISSION_ROLE: 'organization_permission_role',
  USERS: 'organization_users',
};

export const MENU_ITEM = {
  dashboard: {
    key: 'dashboard',
    url: DASHBOARD_URL,
    img: icDashboardIcon,
  },
  customerPO: {
    key: 'customerPO',
    url: CUSTOMER_PO_URL,
    img: customerIcon,
  },
  yield: {
    key: 'yield',
    url: YIELD_URL,
    img: yieldIcon,
  },
  sampleApproval: {
    key: 'sampleApproval',
    url: SAMPLE_APPROVAL_URL,
    img: sampleApprovalIcon,
  },
  purchase: {
    key: 'purchase',
    url: PURCHASE_URL,
    img: purchaseIcon,
  },
  warehouse: {
    key: 'warehouse',
    url: WAREHOUSE_URL,
    img: warehouseIcon,
  },
  production: {
    key: 'production',
    url: PRODUCTION_URL,
    img: productionIcon,
  },
  shipping: {
    key: 'shipping',
    url: SHIPPING_URL,
    img: shippingIcon,
  },
  accounting: {
    key: 'accounting',
    url: ACCOUNTING_URL,
    img: accountingIcon,
  },
  reportAnalytic: {
    key: 'reportAnalytic',
    url: REPORT_ANALYTIC_URL,
    img: reportIcon,
  },
  standard: {
    key: 'standard',
    url: STANDARD_URL,
    img: standardIcon,
  },
  organization: {
    key: 'organization',
    url: ORGANIZATION_URL,
    img: organizationIcon,
  },
  crm: {
    key: 'crm',
    url: CRM_URL,
    img: crmIcon,
  },
  systemSettings: {
    key: 'systemSettings',
    url: SYSTEM_SETTING_URL,
    img: systemIcon,
  },
};

export const PURCHASE_TAB = {
  PURCHASE_PLAN: 'purchase.plan',
  PURCHASE_LIST: 'purchase.list',
  CANCEL_PURCHASE_LIST: 'purchase.cancelList',
  PURCHASE_FOLLOW_UP: 'purchase.followUp',
  PURCHASE_HISTORY: 'purchase.history',
};

export const PURCHASE_SCREEN = {
  PREVIEW_ORDER: 'previewOrder',
  ADDITIONAL_ORDER: 'additionalOrder',
  PLACE_ORDER: 'placeOrder',
  VIEW_HISTORY: 'viewHistory',
  STOCK_ORDER: 'stockOrder',
  EDIT_ORDER: 'editOrder',
  PREVIEW_PURCHASE_ORDER: 'previewPurchaseOrder',
  FOLLOW_UP_PREVIEW_PURCHASE_ORDER: 'followUpPreviewPurchaseOrder',
};

export const CUSTOMERPO_TAB = {
  CUSTOMER_CANCELED: 'customer.canceled',
  CUSTOMER_HISTORY: 'customer.history',
  CUSTOMER_CURRENT: 'customer.current',
};

export const SAMPLE_APPROVAL_SCREEN = {};

export const SAMPLE_APPROVAL_TAB = {
  SAMPLE_APPROVAL_HISTORY: 'sample_approval.history',
};

export const YIELD_ITEM = {
  SELF: 'self',
  CONTRAST: 'contrast',
  LINING: 'lining',
};

export const SEARCH_BAR_EVENT = {
  FILTER: 'FILTER',
  SEARCH: 'SEARCH',
};

export const PURCHASE_TYPE = {
  RESERVED: 'Reserved',
  UNRESERVED: 'Unreserved',
  ADDITIONAL: 'Additional',
  USE_STOCK: 'Use stock',
};

export const WAREHOUSE_TAB = {
  STOCK_MANAGEMENT: {
    STOCK: 'stock',
    DAMAGED_GARMENTS: 'damaged_garments',
    DAMAGED_MATERIALS: 'damaged_materials',
  },
};

export const PURCHASE_LIST_SEARCH_KEY = {
  CUSTOMER_PO: 'style.order.customerPO',
  STYLE: 'style.style',
  PURCHASE_ORDER_NO: 'purchaseNo',
  SUPPLIER: 'supplier.name',
  TYPE: 'type',
  STATUS: 'status',
};

export const PACKING_LIST_SEARCH_KEY = {
  CUSTOMER_PO: 'customerPO',
  CUSTOMER: 'customer',
  PACKING_TYPE: 'packingType',
  DATE: 'createdAt',
};

export const FOLLOWUP_SEARCH_KEY = {
  CUSTOMER_PO: 'customerPO',
  ORDER_DATE: 'orderDate',
  PURCHASE_ORDER_NO: 'purchaseNo',
  STYLE: 'style',
  SUPPLIER: 'supplier.name',
  TYPE: 'type',
};

export const FABRIC_SEARCH_KEY = {
  FABRIC_NAME: 'name',
  CONTENT: 'content',
  UNIT: 'unit',
  ORIGIN: 'origin',
};

export const SHIPPING_SEARCH_KEY = {
  DECLARATION_NO: 'declarationNo',
  DECLARATION_DATE: 'declarationDate',
  SHIP_MODE: 'shipMode',
  BILL_NO: ' billNo',
  CONT: 'cont',
  ETD: 'etd',
  ETA: 'eta',
  ETA_FACTORY: 'ETAFactory',
  ADVANCE: 'Advance',
};
export const CUSTOMER_PO_KEY = {
  CUSTOMER_PO: 'customerPO',
  CUSTOMER: 'customer.name',
  SALE_ORDER: 'internalPO',
};

export const SHIPOUT_SEARCH_KEY = {
  PKL_OF_PRODUCTION: {
    CUSTOMER_PO: 'customerPO',
    CUSTOMER: 'customer',
    PACKING_TYPE: 'packingType',
    EX_FACTORY: 'exFactory',
  },
};

export const SEARCH_KEY = {
  AGE: 'age',
  CUSTOMER_PO: 'customerPO',
  IHDATE: 'IhDate',
  CREATEAT: 'createdAt',
  STYLE: 'style',
  PURCHASE_ORDER_NO: 'purchaseNo',
  SUPPLIER: 'supplier',
  TYPE: 'type',
  STATUS: 'status',
  MATERIAL: 'material',
  CUT_TICKET: 'cut_ticket',
  CONTRACTOR: 'contractor',
  CONTENT: 'content',
  COLOR: 'color',
  SIZE: 'size',
  SOURCE: 'source',
  NAME: 'name',
  PHONE: 'phone',
  EMAIL: 'email',
  ORGANIZATION: 'organization',
  DEPARTMENT: 'department',
  ROLE: 'role',
  COMPANY: 'company',
  ADDRESS: 'address',
  SHORT_NAME: 'short_name',
  COUNTRY: 'country',
  DIVISION: 'division',
  PAYMENT_TERM: 'payment_term',
  PERMISSION: 'permission',
  DESCRIPTION: 'description',
  DATE: 'date',
  DATELINE: 'deadline',
  CURRENCY: 'currency',
  CUSTOMER: 'customer',
  COMPLETEDATE: 'completeDate',
};

export const CREDIT_DEBIT_SEARCH_KEY = {
  ID: 'code',
  ISSUED_DATE: 'issuedDate',
  CHARGE_TO: 'chargeTo',
  TYPE: 'type',
};

export const SEARCH_LIST = {
  ASIGNMENTS: [
    { name: 'PageProduction.CustomerPO', field: SEARCH_KEY.CUSTOMER_PO, type: 'text' },
    { name: 'PageProduction.Style', field: SEARCH_KEY.STYLE, type: 'text' },
    { name: 'PageProduction.Contractor', field: SEARCH_KEY.CONTRACTOR, type: 'text' },
    { name: 'PageProduction.AssignmentDate', field: SEARCH_KEY.DATE, type: 'date' },
    { name: 'PageProduction.DueDate', field: SEARCH_KEY.DATELINE, type: 'date' },
    {
      name: 'PageProduction.Status',
      field: SEARCH_KEY.STATUS,
      type: 'enum',
      values: [
        { key: '', value: '' },
        { key: 'NEW', value: 'New' },
        { key: 'IN_PROGRESS', value: 'In Progress' },
        { key: 'DONE', value: 'Done' },
      ],
    },
  ],
  PURCHASE_LIST: [
    { value: SEARCH_KEY.CUSTOMER_PO, label: 'PagePurchase.CustomerPO' },
    { value: SEARCH_KEY.STYLE, label: 'PagePurchase.Style' },
    { value: SEARCH_KEY.PURCHASE_ORDER_NO, label: 'PagePurchase.PurchaseOrderNo' },
    { value: SEARCH_KEY.SUPPLIER, label: 'PagePurchase.Supplier' },
    { value: SEARCH_KEY.TYPE, label: 'PagePurchase.Type' },
    { value: SEARCH_KEY.STATUS, label: 'PagePurchase.Status' },
  ],
  PURCHASE_LIST_HISTORY: [
    { name: 'PagePurchase.CustomerPO', field: SEARCH_KEY.CUSTOMER_PO, type: 'text' },
    { name: 'PagePurchase.Style', field: SEARCH_KEY.STYLE, type: 'text' },
    { name: 'PagePurchase.PurchaseOrderNo', field: SEARCH_KEY.PURCHASE_ORDER_NO, type: 'text' },
    { name: 'PagePurchase.Supplier', field: SEARCH_KEY.SUPPLIER, type: 'text' },
    { name: 'PagePurchase.Type', field: SEARCH_KEY.TYPE, type: 'text' },
  ],
  SAMPLE_APPROVAL_HISTORY: [
    { name: 'PageSampleApproval.CustomerPO', field: SEARCH_KEY.CUSTOMER_PO, type: 'text' },
    { name: 'PageSampleApproval.CompleteDate', field: SEARCH_KEY.COMPLETEDATE, type: 'dateTo' },
    { name: 'PageSampleApproval.Style', field: SEARCH_KEY.STYLE, type: 'text' },
    { name: 'PageSampleApproval.Customer', field: SEARCH_KEY.CUSTOMER, type: 'text' },
  ],
  WAREHOUSE_STOCK: [
    { name: 'PageWareHouse.Material', field: SEARCH_KEY.MATERIAL, type: 'text' },
    { name: 'PageWareHouse.Content', field: SEARCH_KEY.CONTENT, type: 'text' },
    { name: 'PageWareHouse.Color', field: SEARCH_KEY.COLOR, type: 'text' },
    { name: 'PageWareHouse.Size', field: SEARCH_KEY.SIZE, type: 'text' },
    { name: 'PageWareHouse.CustomerPO', field: SEARCH_KEY.CUSTOMER_PO, type: 'text' },
  ],
  WAREHOUSE_DAMAGED_GARMENTS: [
    { name: 'PageWareHouse.CustomerPO', field: SEARCH_KEY.CUSTOMER_PO, type: 'text' },
    { name: 'PageWareHouse.Style', field: SEARCH_KEY.STYLE, type: 'text' },
    { name: 'PageWareHouse.CutTicket', field: SEARCH_KEY.CUT_TICKET, type: 'text' },
    { name: 'PageWareHouse.Contractor', field: SEARCH_KEY.CONTRACTOR, type: 'text' },
    { name: 'PageWareHouse.Type', field: SEARCH_KEY.TYPE, type: 'text' },
  ],
  WAREHOUSE_DAMAGED_MATERIALS: [
    { name: 'PageWareHouse.Material', field: SEARCH_KEY.MATERIAL, type: 'text' },
    { name: 'PageWareHouse.Content', field: SEARCH_KEY.CONTENT, type: 'text' },
    { name: 'PageWareHouse.CustomerPO', field: SEARCH_KEY.CUSTOMER_PO, type: 'text' },
    { name: 'PageWareHouse.Style', field: SEARCH_KEY.STYLE, type: 'text' },
    { name: 'PageWareHouse.PurchaseOrderNo', field: SEARCH_KEY.PURCHASE_ORDER_NO, type: 'text' },
    { name: 'PageWareHouse.Source', field: SEARCH_KEY.SOURCE, type: 'text' },
    { name: 'PageWareHouse.Color', field: SEARCH_KEY.COLOR, type: 'text' },
    { name: 'PageWareHouse.Size', field: SEARCH_KEY.SIZE, type: 'text' },
  ],
  WAREHOUSE_INBOUND_SUPPLIER: [
    { name: 'PageWareHouse.CustomerPO', field: SEARCH_KEY.CUSTOMER_PO, type: 'text' },
    { name: 'PageWareHouse.Style', field: SEARCH_KEY.STYLE, type: 'text' },
    { name: 'PageWareHouse.PurchaseOrderNo', field: SEARCH_KEY.PURCHASE_ORDER_NO, type: 'text' },
    { name: 'PageWareHouse.Supplier', field: SEARCH_KEY.SUPPLIER, type: 'text' },
  ],
  WAREHOUSE_INBOUND_CONTRACTOR: [
    { name: 'PageWareHouse.CustomerPO', field: SEARCH_KEY.CUSTOMER_PO, type: 'text' },
    { name: 'PageWareHouse.Style', field: SEARCH_KEY.STYLE, type: 'text' },
    { name: 'PageWareHouse.Contractor', field: SEARCH_KEY.CONTRACTOR, type: 'text' },
  ],
  WAREHOUSE_OUTBOUND: [
    { name: 'PageWareHouse.CustomerPO', field: SEARCH_KEY.CUSTOMER_PO, type: 'text' },
    { name: 'PageWareHouse.Style', field: SEARCH_KEY.STYLE, type: 'text' },
    { name: 'PageWareHouse.Contractor', field: SEARCH_KEY.CONTRACTOR, type: 'text' },
    { name: 'PageWareHouse.Date', field: SEARCH_KEY.DATE, type: 'date' },
  ],
  ORGANIZATION_USER: [
    { name: 'PageOrganization.Name', field: SEARCH_KEY.NAME, type: 'text' },
    { name: 'PageOrganization.Phone', field: SEARCH_KEY.PHONE, type: 'text' },
    { name: 'PageOrganization.Email', field: SEARCH_KEY.EMAIL, type: 'text' },
    { name: 'PageOrganization.Organization', field: SEARCH_KEY.ORGANIZATION, type: 'text' },
    { name: 'PageOrganization.Department', field: SEARCH_KEY.DEPARTMENT, type: 'text' },
    { name: 'PageOrganization.Role', field: SEARCH_KEY.ROLE, type: 'text' },
  ],
  CRM_CUSTOMER: [
    { name: 'PageCrm.Name', field: SEARCH_KEY.NAME, type: 'text' },
    { name: 'PageCrm.ShortName', field: SEARCH_KEY.SHORT_NAME, type: 'text' },
    { name: 'PageCrm.Company', field: SEARCH_KEY.COMPANY, type: 'text' },
    { name: 'PageCrm.Address', field: SEARCH_KEY.ADDRESS, type: 'text' },
    { name: 'PageCrm.Email', field: SEARCH_KEY.EMAIL, type: 'text' },
    { name: 'PageCrm.Country', field: SEARCH_KEY.COUNTRY, type: 'text' },
  ],
  CRM_STORE: [
    { name: 'PageCrm.Name', field: SEARCH_KEY.NAME, type: 'text' },
    { name: 'PageCrm.Address', field: SEARCH_KEY.ADDRESS, type: 'text' },
  ],
  CRM_SUPPLIER: [
    { name: 'PageCrm.Name', field: SEARCH_KEY.NAME, type: 'text' },
    { name: 'PageCrm.Company', field: SEARCH_KEY.COMPANY, type: 'text' },
    { name: 'PageCrm.Address', field: SEARCH_KEY.ADDRESS, type: 'text' },
    { name: 'PageCrm.Email', field: SEARCH_KEY.EMAIL, type: 'text' },
    { name: 'PageCrm.Division', field: SEARCH_KEY.DIVISION, type: 'text' },
    { name: 'PageCrm.PaymentTerm', field: SEARCH_KEY.PAYMENT_TERM, type: 'text' },
  ],
  CRM_CONTRACTOR: [
    { name: 'PageCrm.Name', field: SEARCH_KEY.NAME, type: 'text' },
    { name: 'PageCrm.Address', field: SEARCH_KEY.ADDRESS, type: 'text' },
    { name: 'PageCrm.Currency', field: SEARCH_KEY.CURRENCY, type: 'text' },
  ],
  CRM_ANALYTIC: [
    { name: 'PageOrder.CustomerPO', field: SEARCH_KEY.CUSTOMER_PO, type: 'text' },
    { name: 'PageOrder.Customer', field: SEARCH_KEY.CUSTOMER, type: 'text' },
    { name: 'PageOrder.Style', field: SEARCH_KEY.STYLE, type: 'text' },
    { name: 'PageOrder.IhDate', field: SEARCH_KEY.IHDATE, type: 'date' },
  ],
  ORGANIZATION_ORGANIZATION: [
    { name: 'PageOrganization.Name', field: SEARCH_KEY.NAME, type: 'text' },
    { name: 'PageOrganization.Address', field: SEARCH_KEY.ADDRESS, type: 'text' },
    { name: 'PageOrganization.Phone', field: SEARCH_KEY.PHONE, type: 'text' },
  ],
  ORGANIZATION_DEPARTMENT: [
    { name: 'PageDepartment.Name', field: SEARCH_KEY.NAME, type: 'text' },
    { name: 'PageDepartment.Organization', field: SEARCH_KEY.ORGANIZATION, type: 'text' },
    { name: 'PageDepartment.Description', field: SEARCH_KEY.DESCRIPTION, type: 'text' },
  ],
  ORGANIZATION_PERMISSION: [{ name: 'PagePermission.Role', field: SEARCH_KEY.ROLE, type: 'text' }],
  PURCHASE_LIST_SEARCH_LIST: [
    { field: PURCHASE_LIST_SEARCH_KEY.CUSTOMER_PO, name: 'PagePurchase.CustomerPO', type: 'text' },
    { field: PURCHASE_LIST_SEARCH_KEY.STYLE, name: 'PagePurchase.Style', type: 'text' },
    { field: PURCHASE_LIST_SEARCH_KEY.PURCHASE_ORDER_NO, name: 'PagePurchase.PurchaseOrderNo', type: 'text' },
    { field: PURCHASE_LIST_SEARCH_KEY.SUPPLIER, name: 'PagePurchase.Supplier', type: 'text' },
    { field: PURCHASE_LIST_SEARCH_KEY.TYPE, name: 'PagePurchase.Type', type: 'text' },
    {
      field: PURCHASE_LIST_SEARCH_KEY.STATUS,
      name: 'PagePurchase.Status',
      type: 'enum',
      values: [
        // 'New', 'Use stock', 'Draft', 'In progress', 'Waiting for shipping', 'In transit', 'In port', 'In warehouse', 'Waiting for approval', 'Approved', 'Completed'
        { key: '', value: 'All' },
        { key: 'New', value: 'New' },
        // { key: 'Use stock', value: 'Use stock' },
        { key: 'Draft', value: 'Draft' },
        { key: 'Waiting for shipping', value: 'Waiting for shipping' },
        { key: 'In transit', value: 'In transit' },
        { key: 'In port', value: 'In port' },
        { key: 'In warehouse', value: 'In warehouse' },
        { key: 'Waiting for approval', value: 'Waiting for approval' },
        // { key: 'Approved', value: 'Approved' },
        // { key: 'Completed', value: 'Completed' }
      ],
    },
  ],
  CUSTOMER_PO_SEARCH_LIST: [
    { field: CUSTOMER_PO_KEY.CUSTOMER_PO, name: 'PageOrder.CustomerPO', type: 'text' },
    { field: CUSTOMER_PO_KEY.CUSTOMER, name: 'PageOrder.Customer', type: 'text' },
    { field: CUSTOMER_PO_KEY.SALE_ORDER, name: 'PageOrder.SaleOrder', type: 'text' },
  ],
  SHIPPING: [
    { field: SEARCH_KEY.CUSTOMER_PO, name: 'PageShiping.CustomerPO', type: 'text' },
    { field: SEARCH_KEY.STYLE, name: 'PageShiping.Style', type: 'text' },
  ],
  PACKING_LIST_SEARCH_LIST: [
    { field: PACKING_LIST_SEARCH_KEY.PACKING_TYPE, name: 'PageProduction.PackingType', type: 'text' },
    { field: PACKING_LIST_SEARCH_KEY.CUSTOMER, name: 'PageProduction.Customer', type: 'text' },
    { field: PACKING_LIST_SEARCH_KEY.CUSTOMER_PO, name: 'PageProduction.CustomerPO', type: 'text' },
    { field: PACKING_LIST_SEARCH_KEY.DATE, name: 'PageProduction.Date', type: 'dateTo' },
  ],
  FOLLOWUP_SEARCH_LIST: [
    { field: FOLLOWUP_SEARCH_KEY.CUSTOMER_PO, name: 'PageShiping.CustomerPO', type: 'text' },
    { field: FOLLOWUP_SEARCH_KEY.ORDER_DATE, name: 'PageShiping.OrderDate', type: 'dateTo' },
    { field: FOLLOWUP_SEARCH_KEY.PURCHASE_ORDER_NO, name: 'PageShiping.PurchaseOrder', type: 'text' },
    { field: FOLLOWUP_SEARCH_KEY.STYLE, name: 'PageShiping.Style', type: 'text' },
    { field: FOLLOWUP_SEARCH_KEY.SUPPLIER, name: 'PageShiping.Supplier', type: 'text' },
    { field: FOLLOWUP_SEARCH_KEY.TYPE, name: 'PageShiping.Type', type: 'text' },
  ],
  FABRIC_SEARCH_LIST: [
    { field: FABRIC_SEARCH_KEY.FABRIC_NAME, name: 'PageStandard.Name', type: 'text' },
    { field: FABRIC_SEARCH_KEY.CONTENT, name: 'PageStandard.Content', type: 'text' },
    { field: FABRIC_SEARCH_KEY.UNIT, name: 'PageStandard.Unit', type: 'text' },
    { field: FABRIC_SEARCH_KEY.ORIGIN, name: 'PageStandard.Origin', type: 'text' },
  ],
  SHIPIN_SEARCH_LIST: [
    { field: SHIPPING_SEARCH_KEY.DECLARATION_NO, name: 'PageShiping.DeclarationNo', type: 'text' },
    { field: SHIPPING_SEARCH_KEY.DECLARATION_DATE, name: 'PageShiping.DeclarationDate', type: 'dateTo' },
    { field: SHIPPING_SEARCH_KEY.SHIP_MODE, name: 'PageShiping.ShipMode', type: 'text' },
    { field: SHIPPING_SEARCH_KEY.BILL_NO, name: 'PageShiping.BillNo', type: 'text' },
    { field: SHIPPING_SEARCH_KEY.CONT, name: 'PageShiping.Cont', type: 'text' },
    { field: SHIPPING_SEARCH_KEY.ETD, name: 'PageShiping.ETD', type: 'dateTo' },
    { field: SHIPPING_SEARCH_KEY.ETA, name: 'PageShiping.ETA', type: 'dateTo' },
    { field: SHIPPING_SEARCH_KEY.ETA_FACTORY, name: 'PageShiping.ETAFactory', type: 'date' },
    { field: SHIPPING_SEARCH_KEY.ADVANCE, name: 'PageShiping.Advance', type: 'text' },
  ],
  SHIPOUT_SEARCH_LIST: {
    PKL_OF_PRODUCTION_SEARCH_LIST: [
      { field: SHIPOUT_SEARCH_KEY.PKL_OF_PRODUCTION.CUSTOMER_PO, name: 'PageShiping.CustomerPO', type: 'text' },
      { field: SHIPOUT_SEARCH_KEY.PKL_OF_PRODUCTION.CUSTOMER, name: 'PageShiping.Customer', type: 'text' },
      { field: SHIPOUT_SEARCH_KEY.PKL_OF_PRODUCTION.PACKING_TYPE, name: 'PageShiping.PackingType', type: 'text' },
      { field: SHIPOUT_SEARCH_KEY.PKL_OF_PRODUCTION.EX_FACTORY, name: 'PageShiping.ExFactory', type: 'dateTo' },
    ],
  },
  CREDIT_DEBIT_SEARCH_LIST: [
    { field: CREDIT_DEBIT_SEARCH_KEY.ID, name: 'PageAccounting.CreditDebitID', type: 'text' },
    { field: CREDIT_DEBIT_SEARCH_KEY.ISSUED_DATE, name: 'PageAccounting.IssuedDate', type: 'dateTo' },
    { field: CREDIT_DEBIT_SEARCH_KEY.CHARGE_TO, name: 'PageAccounting.ChargeTo', type: 'text' },
    {
      field: CREDIT_DEBIT_SEARCH_KEY.TYPE,
      name: 'PageAccounting.Type',
      type: 'enum',
      values: [
        { key: '', value: 'All' },
        { key: 'Credit', value: 'Credit' },
        { key: 'Debit', value: 'Debit' },
      ],
    },
  ],
};

export const PURCHASE_LIST_SEARCH_LIST = [
  { value: PURCHASE_LIST_SEARCH_KEY.CUSTOMER_PO, label: 'PagePurchase.CustomerPO' },
  { value: PURCHASE_LIST_SEARCH_KEY.STYLE, label: 'PagePurchase.Style' },
  { value: PURCHASE_LIST_SEARCH_KEY.PURCHASE_ORDER_NO, label: 'PagePurchase.PurchaseOrderNo' },
  { value: PURCHASE_LIST_SEARCH_KEY.SUPPLIER, label: 'PagePurchase.Supplier' },
  { value: PURCHASE_LIST_SEARCH_KEY.TYPE, label: 'PagePurchase.Type' },
  { value: PURCHASE_LIST_SEARCH_KEY.STATUS, label: 'PagePurchase.Status' },
];

export const MATERIAL_KEY = {
  NEED_TO_BUY: 'needToBuy',
  SURCHARGE: 'surcharge',
  DISCOUNT: 'discount',
};

export const PURCHASE_EVENT = {
  SAVE: 'SAVE',
  SEND: 'SEND',
  PREVIEW_ORDER: 'PREVIEW_ORDER',
};

export const PRODUCTION_TAB = {
  ASSIGNMENT: 'assignment',
  PRODUCTION: 'production',
  QA: 'qa',
  PACKING: 'packing',
  PROGRESS: 'progress',
  VIEW_PROGRESS: 'view_progress',
  ACTUAL_YIELD: 'actual_yield',
  UPDATE_PROGRESS: 'update_progress',
};

export const PRODUCTION_SCREEN = {
  VIEW_PROGRESS: 'view_progress',
  UPDATE_PROGRESS: 'update_progress',
  UPDATE_QA_PROGRESS: 'update_qa_progress',
  EDIT_PROGRESS: 'edit_progress',
  EDIT_ACTUAL_YIELD: 'edit_actual_yield',
  VIEW_QA_PROGRESS: 'view_qa_progress',
  EDIT_QA_PROGRESS: 'edit_qa_progress',
  ADD_PACKING: 'add_packing',
  ADD_PACKING_PRE_PACK: 'add_packing_pre_pack',
};

export const COMPONENT_TYPE = {
  PROGRESS_BAR: 'PROGRESS_BAR',
  INPUT: 'INPUT',
  LIST: 'LIST',
  INPUT_LIST: 'INPUT_LIST',
  TEXT_AREA: 'TEXT_AREA',
  CHECKBOX: 'checkbox',
  LINK: 'link',
};

export const ACTION_TYPE = {
  RESTORE: 'restore',
  VIEW: 'view',
  EDIT: 'edit',
  DELETE: 'delete',
  CREATEINV: 'create_inv',
  IMPORT: 'import',
  EXPORT: 'export',
};

export const NAME_TYPE = {
  GARMENT: `garment`,
  MATERIAL: `material`,
  TECHPACK: `tech-pack`,
};

export const PANEL_TABLE_ID = {
  TAB_PRODUCTION_VIEW: 'tab_production_view',
  PRODUCTION_VIEW: 'production_view_',
};

export const PACKING_TYPE = {
  GOHH: 'GOHH',
  CARTON: 'Carton',
};

export const CREDIT_DEBIT_TYPE = {
  CREDIT: 'Credit',
  DEBIT: 'Debit',
  OF: 'OF',
  CS: 'CS',
  CONTRACTOR: 'Contractor',
  SUPPLIER: 'Supplier',
  CUSTOMER: 'Customer',
};

export const SHIPPING_SCREEN = {
  CREATE_SHIPMENT: 'createShipment',
  EDIT_SHIPIN: 'editShipin',
  VIEW_HISTORY: 'viewHistory',
};

export const PAGINATION = {
  PAGE_SIZE: 20,
  PAGE_NUMBER: 1,
};
