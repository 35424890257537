import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import _ from 'lodash';
import * as PATH from '../../../constants/routeConstants';
import PreviewPurchaseOrder from '../../components/purchase/PreviewPurchaseOrder';
import * as selectors from '../../../selectors/purchase/purchasePlans';

class PreviewPurchaseOrderForPurchasePlans extends Component {

    componentWillReceiveProps(nextProps) {
        const {items} = nextProps;
        if(_.isEmpty(items)) {
            nextProps.router.history.push(PATH.PURCHASE_PLANS_PREVIEW_PLACE_ORDER_URL);
        }
    }
    render() {
        return (
            <div>
                <PreviewPurchaseOrder {...this.props} />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    // appState: state.appState,
    customerPO: (selectors.getSelectedOrder(state) || {}).customerPO,
    style: ((selectors.getSelectedStyle(state) || {}).raw || {}).style,
    // purchaseNo: selectors.getPurchaseNo(state),
    gTotal: selectors.getSumGTotalText(state),
    items: selectors.getSelectedMaterials(state),
    supplierData: ((selectors.getSelectedMaterials(state) || [])[0] || {}).supplier,
    remark: selectors.getRemark(state),
    order: selectors.getSelectedOrder(state),
  });

  const mapDispatchToProps = dispatch => ({

  })

export default connect(mapStateToProps, mapDispatchToProps) (withRouter(PreviewPurchaseOrderForPurchasePlans));