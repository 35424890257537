import C from '../../constants/ship-out/showHistory';
import { combineReducers } from "redux";

const displayList = (state = null, action) => {
    const {type, payload } = action;
    switch(type) {
        case C.CHANGE_DISPLAY_LIST:
            return payload;
        default:
            return state;
    }
}

const filter = (state = null, action) => {
    const {type, payload} = action;
    switch(type) {
        case C.CHANGE_SELECTED_FILTER:
            return payload;
        default:
            return state
    }
}

const searchValue = (state = {}, action) => {
    const { type, payload } = action;
    switch (type) {
        case C.CHANGE_SEARCH_VALUE:
            return {...state, [payload.pos]: payload.value};
        default:
            return state;
    }
}

const currentPage = (state = 1, action) => {
    const { type, payload } = action;
    switch (type) {
        case C.CHANGE_PAGING_CURRENT:
            return payload;
        default:
            return state;
    }
}

const totalPages = (state = null, action) => {
    const { type, payload } = action;
    switch (type) {
        case C.CHANGE_PAGING_TOTAL:
            return payload;
        default:
            return state;
    }
}

const searched = (state = false, action) => {
    const {type, payload} = action;
    switch(type) {
        case C.CHANGE_SEARCH_STATUS:
            return payload;
        default:
            return state;
    }
}

export const showHistoryList = combineReducers({
    displayList,
    filter,
    searchValue,
    currentPage,
    totalPages,
    searched,
})