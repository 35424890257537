const C = "EDIT_INVOICE_IE_US_DELIVERY";

export default {
    CHANGE_INVOICE_DETAIL: `${C}_CHANGE_INVOICE_DETAIL`,
    CHANGE_COMPANY_VALUE: `${C}_CHANGE_COMPANY_VALUE`,
    CHANGE_NAME_VALUE: `${C}_CHANGE_NAME_VALUE`,
    CHANGE_INVOICE_NO_VALUE: `${C}_CHANGE_INVOICE_NO_VALUE`,
    CHANGE_PAY_AMOUNT_VALUE: `${C}_CHANGE_PAY_AMOUNT_VALUE`,
    CHANGE_PAYMENT_TYPE: `${C}_CHANGE_PAYMENT_TYPE`,
    CHANGE_NOTE_VALUE: `${C}_CHANGE_NOTE_VALUE`,
    CHANGE_BILL_DETAIL_VALUE: `${C}_CHANGE_BILL_DETAIL_VALUE`,
    COPY_DATA_TO_TEMP: `${C}_COPY_DATA_TO_TEMP`,
    CHANGE_CONTAINER_DETAIL_VALUE: `${C}_CHANGE_CONTAINER_DETAIL_VALUE`,
    SUBMIT_EDIT_DATA: `${C}_SUBMIT_EDIT_DATA`,
    RESET_TEMP_DATA: `${C}_RESET_TEMP_DATA`,
    ADD_TRANSFER_FILE: `${C}_ADD_TRANSFER_FILE`,
    REMOVE_TRANSFER_FILE: `${C}_REMOVE_TRANSFER_FILE`,
    EMPTY_FILES_IN_RAW_DATA: `${C}_EMPTY_FILES_IN_RAW_DATA`,
    COPY_FILES_TO_RAW_DATA: `${C}_COPY_FILES_TO_RAW_DATA`,
    SUBMIT_FILES: `${C}_SUBMIT_FILES`,
    CREATE_RECEIPT: `${C}_CREATE_RECEIPT`,
    CHANGE_RECEIPT_VALUE: `${C}_CHANGE_RECEIPT_VALUE`,
    SUBMIT_RECEIPT: `${C}_SUBMIT_RECEIPT`,
    EMPTY_RECEIPT: `${C}_EMPTY_RECEIPT`,
    SAVE_SUCCESS: `${C}_SAVE_SUCCESS`,
    RESET_DATA_ALL: `${C}_RESET_DATA_ALL`,
    CHANGE_INVOICE_DATE_VALUE: `${C}_CHANGE_INVOICE_DATE_VALUE`,
    ACTIVE_SCREEN_STATUS: `${C}_ACTIVE_SCREEN_STATUS`,
    CHANGE_TOGGLE_ACTIVE: `${C}_CHANGE_TOGGLE_ACTIVE`,
    CHANGE_DUE_DATE_VALUE: `${C}_CHANGE_DUE_DATE_VALUE`,
}