import React from 'react';
import PropTypes from 'prop-types';
import PanelTableList from "../../../common/layout/panel/PanelTableList";
import _ from 'lodash';
import {ACTION_TYPE, PRODUCTION_SCREEN} from "../../../../constants/constants";
import appActions from "../../../../actions/appActions";
import {bindActionCreators} from "redux";
import cutTicketActions from "../../../../actions/cutTicketActions";
import {setCutTicketProgressItems} from '../../../../functions/utils';
import {connect} from "react-redux";
import I18n from "i18n-js";
import ModalConfirm from "../../../common/modals/ModalConfirm";

import icEditIcon from '../../../../stylesheets/images/ic-edit.svg';

class ViewProgressStyle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      actions: [],
      styleContractor: ""
    };
  }

  componentWillMount() {
    const {data, cutTicketState} = this.props;
    this.getData(data);
    const {items, styleContractor} = setCutTicketProgressItems(_.get(data, "order", []), _.get(cutTicketState, "items", []), _.get(data, "style._id", ""));
    this.setState({items, styleContractor});
  }

  componentWillReceiveProps(nextProps) {
    if (!_.isEqual(this.props.cutTicketState, nextProps.cutTicketState)) {
      const {items, styleContractor} = setCutTicketProgressItems(_.get(nextProps.data, "order", []), _.get(nextProps.cutTicketState, "items", []), _.get(nextProps.data, "style._id", ""));
      this.setState({items, styleContractor});
    }
  }

  getData(data) {
    const styleId = _.get(data, "style._id", "");
    const styleAndCutTicket = (_.get(data, "order.styleOrders", []).find(styleOrder => _.get(styleOrder, "style._id", "") === _.get(data, "style._id", "")));
    const cutTickets = _.get(styleAndCutTicket, "cutTickets", []).map(ct => ({_id: ct._id, contractorId: ct.contractorId}));
    const query = JSON.stringify({_id: cutTickets});
    const populate = "colors";
    styleId && this.props.cutTicketActions.getListCutTicket(styleId, {populate, query});
  }

  handleUpdateProgress(item) {
    let pageList = [...this.props.appState.pageList];
    pageList.push({screen: PRODUCTION_SCREEN.UPDATE_PROGRESS, data: item});
    this.props.appActions.addPage(pageList);
  }

  editProgressByDate(item, data) {
    let pageList = _.cloneDeep(this.props.appState.pageList);
    this.props.appActions.addPage([...pageList, {
      screen: PRODUCTION_SCREEN.EDIT_PROGRESS,
      data: {...data, selectedDate: item}
    }]);
  }

  deleteProgressByDate(item, data) {

    let {openModal, closeModal} = this.props.modal;
    let title = I18n.t('PageProduction.DELETE_PROGRESS');
    let confirmMessage = I18n.t('PageProduction.DeleteConfirm');
    let okFunction = () => this.deleteProgress(item, data);
    let modal = (<ModalConfirm
      title={title}
      confirmMessage={confirmMessage}
      messageOk={I18n.t('Btn.YES')}
      messageCancel={I18n.t('Btn.CANCEL')}
      handleClose={closeModal}
      handleOk={okFunction}/>);
    openModal(modal);
  }

  deleteProgress(item, data) {
    _.get(data, "_id", "") && _.get(item, "date", "") &&
    this.props.cutTicketActions.deleteCutTicketColorProgress(_.get(data, "_id", ""), {date: _.get(item, "date", "")}).then(res => {
      res && res.success && this.getData(this.props.data);
    });
  }

  handleSaveIHDate({_id, ihDate, ihDates}) {
    _id && ihDates && this.props.cutTicketActions.editCutTicket(_id, {ihDate, ihDates}).then(res => {
      res && res.success && this.getData(this.props.data);
    });
  }

  renderPanelActions(item) {
    return (
      <li>
        <span onClick={()=>this.handleUpdateProgress(item)}>
          <img src={icEditIcon} alt=""/>
          {I18n.t("PageProduction.UPDATE_PROGRESS")}
        </span>
      </li>
    );
  }

  render() {
    let {data, roles: {showCreate, showEdit, showDelete, showView}} = this.props;
    let {styleContractor, actions} = this.state;
    actions = []
    if(showEdit){
      actions.push(() => {
        return {type: ACTION_TYPE.EDIT, click: (item, data) => this.editProgressByDate(item, data)};
      })
    }
    if(showDelete){
      actions.push(() => {
        return {type: ACTION_TYPE.DELETE, click: (item, data) => this.deleteProgressByDate(item, data)};
      })
    }
    return (
      <div id="content">

        <div className="pd-5 production_scroll">
          <ul className="content-header-title">
            <li>
              <p>{I18n.t("PageProduction.Style")} <span
                className="color-orange medium mg-left-50 size-16">{_.get(data, "style.style", "-")}</span></p>
            </li>
            {styleContractor &&
            <li className="mg-left-50">
              <p>{I18n.t("PageProduction.Contractor")} <span
                className="medium mg-left-50 size-16">{styleContractor}</span></p>
            </li>
            }
          </ul>
          <PanelTableList
            items={this.state.items || []}
            actions={actions}
            //onUpdateProgress={(item) => this.handleUpdateProgress(item)}
            onSaveIHDate={((item) => this.handleSaveIHDate(item))}
            renderPanelActions={((item) => this.renderPanelActions(item))}
            isRenderIHDate
            showEdit={showEdit}
          />
        </div>
      </div>
    );
  }
}

ViewProgressStyle.propTypes = {
  appActions: PropTypes.object,
  cutTicketActions: PropTypes.object,
  appState: PropTypes.object,
  data: PropTypes.object,
  cutTicketState: PropTypes.object,
};

function mapStateToProps(state, ownProps) {
  return {
    appState: state.appState,
    authState: state.authState,
    cutTicketState: state.cutTicketState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    appActions: bindActionCreators(appActions, dispatch),
    cutTicketActions: bindActionCreators(cutTicketActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewProgressStyle);
