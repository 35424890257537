import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import I18n from 'i18n-js';

import ModalConfirm from '../../../components/forms/ModalConfirm';

import * as selectors from '../../../../selectors/customer-po/current/deleteCutTicket';
import * as actions from '../../../../actions/customer-po/current/deleteCutTicket';

const DeleteCutTicketModal = props => (
  <ModalConfirm
    {...props}
    onConfirmClick={() => props.deleteCutTicket(props.cutTicket)}
  />
);

const mapStateToProps = state => ({
  isOpen: selectors.isOpen(state),
  title: I18n.t('PageOrder.DELETECUTTICKET'),
  confirmMessage: I18n.t('PageOrder.ConfirmDeleteCutTicket'),
  cancelLabel: I18n.t('Btn.CANCEL'),
  okLabel: I18n.t('Btn.YES'),
  submitingLabel: 'Deleting...',
  cutTicket: selectors.getCutTicket(state),
  canSave: selectors.canSave(state),
  isSubmiting: selectors.isSubmiting(state),
});

const mapDispatchToProps = (dispatch) => ({
  onCloseClick() {
    dispatch(actions.hideModal())
  },

  deleteCutTicket(style) {
    dispatch(actions.deleteCutTicket(style));
  },
});

export default connect(mapStateToProps, mapDispatchToProps) (withRouter(DeleteCutTicketModal));
