import React, { Component } from 'react';
import { connect } from 'react-redux';
import I18n from "i18n-js";
import moment from 'moment';

import DateTimeField from 'react-datetime';
import { toastr } from 'react-redux-toastr'
import { bindActionCreators } from "redux";

import { CREDIT_DEBIT_TYPE } from "../../../constants/constants";
import * as types from "../../../constants/actionTypes";

import payInfoServices from './../../../services/payInfoServices';
import customerServices from "../../../services/customerServices";
import commonUseActions from '../../../actions/commonUseActions';
import payInfoActions from './../../../actions/payInfoActions';
import supplierServices from '../../../services/supplierServices';
import contractorServices from '../../../services/contractorServices';

import IMG_DEFAULT from "../../../stylesheets/images/image.png";
import XLXS_DEFAULT from "../../../stylesheets/images/excel.png";
import DOC_DEFAULT from "../../../stylesheets/images/doc.png";
import PDF_DEFAULT from "../../../stylesheets/images/pdf.png";
import UNKNOWN_DEFAULT from "../../../stylesheets/images/unknown-file.png";

import SearchSelect from "../../../ui/components/forms/SearchSelect";
import { pathImage } from '../../../functions/helpers';
var FileSaver = require('file-saver');

import icXHoverIcon from '../../../stylesheets/images/ic-x-hover.svg';
import downloadIcon from '../../../stylesheets/images/download.png';

class ModalAddCreditDebit extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            payInfo: {
                typeUser: CREDIT_DEBIT_TYPE.CONTRACTOR,
                type: CREDIT_DEBIT_TYPE.CREDIT,
                reason: CREDIT_DEBIT_TYPE.OF,
                currency: 'VND',
                documents: [],
                issuedDate: new Date()
            },
            contractors: [],
            suppliers: [],
            customers: [],
            fetchingData: false,
            files: []
        }
    }

    componentWillMount = () => {
        let { payInfo } = this.state;
        if(this.props.payInfo && this.props.payInfo._id) {
            payInfoServices.getPayInfo(this.props.payInfo._id).then(res => {
                if (res.success) {
                    payInfo = res.data;
                    this.setState({payInfo});
                    this.getDataSelectAble(payInfo.typeUser);
                }
                if(res.error) {
                    toastr.error(I18n.t('Notify.Error'), res.error);
                    this.props.closeModal();
                }
            }, (err) => {
                toastr.error(I18n.t('Notify.Error'), err);
                this.props.closeModal();
            })
        } else {
            this.getDataSelectAble(payInfo.typeUser);
        }
    }

    componentWillReceiveProps = () => {
        
    }

    getDataSelectAble(type) {
        if (type) {
            switch (type) {
                case 'Supplier':
                    this.getListSuppliersAndHandle();
                    break;
                case 'Contractor':
                    this.getListContractorsAndHandle();
                    break;
                case 'Customer':
                    this.getListCustomersAndHandle();
                    break;
            }
        }
    }

    getListSuppliersAndHandle() {
        this.setState({ fetchingData: true });
        supplierServices.getListSupplier().then(res => {
            if (res) {
                let data = _.get(res, "data.items", [])
                data = _.map(data, (el) => {
                    return {
                        label: el.name || "",
                        value: el._id
                    }
                })
                this.setState({
                    suppliers: data
                })
            }
            this.setState({ fetchingData: false });
        }, (err) => {
            console.log(err);
        })
    }

    getListContractorsAndHandle() {
        this.setState({ fetchingData: true });
        contractorServices.getListItem().then(res => {
            if (res) {
                let data = _.get(res, "data.items", [])
                data = _.map(data, (el) => {
                    return {
                        label: el.name || "",
                        value: el._id
                    }
                })
                this.setState({
                    contractors: data
                })
            }
            this.setState({ fetchingData: false });
        }, (err) => {
            console.log(err);
        })
    }

    getListCustomersAndHandle() {
        this.setState({ fetchingData: true });
        customerServices.getListCustomerByQa().then(res => {
            if (res) {
                let data = _.get(res, "data.items", [])
                data = _.map(data, (el) => {
                    return {
                        label: el.name || "",
                        value: el._id
                    }
                })
                this.setState({
                    customers: data
                })
            }
            this.setState({ fetchingData: false });
        }, (err) => {
            console.log(err);
        })
    }

    savePayInfo = (data, files = []) => {
        if(data._id) {
            payInfoServices.updatePayInfo(data, files).then(res => {
                if (res.success) {
                    toastr.success(I18n.t('Notify.Success'), 'Update Success')
                    this.props.ok(data)
                } else {
                    toastr.error(I18n.t('Notify.Error'), rs.error)
                }
            })
        } else {
            payInfoServices.createPayInfo(data, files).then(res => {
                if (res.success) {
                    toastr.success(I18n.t('Notify.Success'), 'Add Success')
                    this.props.ok(data)
                } else {
                    toastr.error(I18n.t('Notify.Error'), rs.error)
                }
            })
        }
    }

    ok = () => {
        const { payInfo, files } = this.state;
        if (this.validateInfo(payInfo)) {
            let data = this.handleDataForSave(_.cloneDeep(payInfo));
            this.savePayInfo(data, files);
            this.props.closeModal();
        }
    }

    handleDataForSave(data) {
        const { files } = this.state;
        switch (data.typeUser) {
            case 'Supplier':
                data.supplier = _.get(data, 'supplier.value');
                break;
            case 'Contractor':
                data.contractor = _.get(data, 'contractor.value');
                break;
            case 'Customer':
                data.customer = _.get(data, 'customer.value');
                break;
        }
        return data;
    }

    validateInfo(data) {
        let errors = {};
        const { CONTRACTOR, SUPPLIER, CUSTOMER } = CREDIT_DEBIT_TYPE;
        if (!data.typeUser) {
            errors.obj = I18n.t('ErrorMessages.PleaseChooseTypeUser');
        }
        if (!data.amount || parseInt(data.amount) === NaN || parseInt(data.amount) < 0) {
            errors.amount = I18n.t('ErrorMessages.AmountIsWrong');
        }
        if (data.typeUser === CUSTOMER && !data.customer) {
            errors.obj = I18n.t('ErrorMessages.PleaseChooseCustomer');
        }
        if (data.typeUser === SUPPLIER && !data.supplier) {
            errors.obj = I18n.t('ErrorMessages.PleaseChooseSupplier');
        }
        if (data.typeUser === CONTRACTOR && !data.contractor) {
            errors.obj = I18n.t('ErrorMessages.PleaseChooseContractor');
        }
        if (!data.issuedDate) {
            errors.issuedDate = I18n.t('ErrorMessages.PleaseInputIssuedDate');
        }
        this.setState({ errors });
        return _.isEmpty(errors);
    }

    close() {
        this.props.closeModal();
    }

    setpayInfo = (name, value) => {
        let { payInfo } = this.state;
        payInfo[name] = value;
        if (name === 'typeUser') {
            this.getDataSelectAble(value);
            switch (value) {
                case 'Supplier':
                    delete payInfo.contractor;
                    delete payInfo.customer;
                    break;
                case 'Contractor':
                    delete payInfo.supplier;
                    delete payInfo.customer;
                    break;
                case 'Customer':
                    delete payInfo.contractor;
                    delete payInfo.supplier;
                    break;
                default:
                    break;
            }
        }
        this.setState({ payInfo });
    }

    onChange = (type, data) => {
        this.setState({
            [type]: data
        })
    }

    addFile = (e) => {
        e.preventDefault();
        const file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
        if (!file) return;
        var FileSize = file.size / 1024 / 1024; // in MB
        if (FileSize > 2) {
            toastr.error('Error', I18n.t('ErrorMessages.FileSizeExceeds2MB'))
            return;
        }

        let { files } = this.state;
        const readerBase64 = new FileReader();
        readerBase64.onload = eventBase64 => {
            files.push({ file: file, type: file.name.split('.')[file.name.split('.').length - 1], url: eventBase64.target.result, filename: file.name});
            this.setState({ files });
        };
        readerBase64.readAsDataURL(file);
    }

    onRemoveFile(idx) {
        let { files } = this.state;
        _.remove(files, (file, i) => i === idx);
        this.setState({ files })
    }

    getImage(file) {
        switch(file.type) {
            case 'jpg':
            case 'jpeg':
            case 'png':
                return file.url || IMG_DEFAULT;
            case 'csv':
            case 'xlsx':
                return XLXS_DEFAULT;
            case 'pdf':
                return PDF_DEFAULT;
            case 'doc':
            case 'docx':
                return DOC_DEFAULT;
            default:
                return UNKNOWN_DEFAULT;
        }
    }

    handleImage(imageUrl) {
        if(imageUrl) {
            let firstIndex = _.lastIndexOf(imageUrl, "/");
            let lastIndex = _.lastIndexOf(imageUrl, ".");
            if(lastIndex < 0 || firstIndex < 0) return;
            let fileType = imageUrl.slice(lastIndex + 1, imageUrl.length);
            let fileName = imageUrl.slice(firstIndex + 1, lastIndex);
            return this.getImage({ type: fileType, url: pathImage(imageUrl), name: fileName })
        }
        return;
    }

    onDownloadFileDocuments(i) {
        let { payInfo } = this.state;
        const file = _.head(payInfo.documents.filter(doc => doc === i));
        if (file) {
            const fileName = _.last(file.split('/'));
            FileSaver.saveAs(pathImage(file), fileName);
        }
    }

    onRemoveFileDocuments(i) {
        let { payInfo } = this.state;
        _.remove(payInfo.documents, (doc, k) => k === i)
        this.setState({payInfo})
    }

    render() {
        const { payInfo, contractors, customers, suppliers, fetchingData, errors, files } = this.state;
        const { CONTRACTOR, SUPPLIER, CUSTOMER } = CREDIT_DEBIT_TYPE;
        console.log(files);
        return (
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <a className="close" data-dismiss="modal" aria-label="Close" onClick={this.props.closeModal}><span aria-hidden="true">&times;</span></a>
                        <h4 className="modal-title" id="myModalLabel">{payInfo._id ? I18n.t('ScreenCreditDebitNote.EDITCREDITDEBIT') : I18n.t('ScreenCreditDebitNote.ADDCREDITDEBIT')}</h4>
                    </div>
                    <div className="modal-body">
                        <div className="form-group">
                            <div className="col-md-4 col-xs-12 text">
                                <p>{I18n.t('ScreenCreditDebitNote.Type')} </p>
                            </div>
                            <div className="col-md-8 col-xs-12 value">
                                <ul className="nav nav-tabs col-md-8 col-xs-12 wrap-list-radio pd-10-0-15-0" role="tablist">
                                    <li role="presentation" className={payInfo.type === CREDIT_DEBIT_TYPE.CREDIT ? 'active' : ''}>
                                        <a role="tab">
                                            <input type="radio" id={CREDIT_DEBIT_TYPE.CREDIT} name={CREDIT_DEBIT_TYPE.CREDIT}
                                                checked={payInfo.type === CREDIT_DEBIT_TYPE.CREDIT} value={CREDIT_DEBIT_TYPE.CREDIT}
                                                onChange={(evt) => this.setpayInfo('type', evt.target.value)} />
                                            <label>{I18n.t('ScreenCreditDebitNote.Credit')}</label>
                                            <div className="check">
                                                <div className="inside" />
                                            </div>
                                        </a>
                                    </li>
                                    <li role="presentation" className={payInfo.type === CREDIT_DEBIT_TYPE.DEBIT ? 'active' : ''}>
                                        <a role="tab">
                                            <input type="radio" id={CREDIT_DEBIT_TYPE.DEBIT} name={CREDIT_DEBIT_TYPE.DEBIT}
                                                checked={payInfo.type === CREDIT_DEBIT_TYPE.DEBIT} value={CREDIT_DEBIT_TYPE.DEBIT}
                                                onChange={(evt) => this.setpayInfo('type', evt.target.value)} />
                                            <label>{I18n.t('ScreenCreditDebitNote.Debit')}</label>
                                            <div className="check">
                                                <div className="inside" />
                                            </div>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="col-md-4 col-xs-12 text">
                                <p>{I18n.t('ScreenCreditDebitNote.IssuedDate')}</p>
                            </div>
                            <div className="col-md-8 col-xs-12">
                                <DateTimeField
                                    timeFormat={false}
                                    closeOnSelect dateFormat="MMM DD, YYYY"
                                    value={moment(payInfo.issuedDate)}
                                    // defaultText={payInfo.issuedDate ? moment(payInfo.issuedDate).format("MMM DD, YYYY") : ''}
                                    onChange={date => this.setpayInfo('issuedDate', date.format('YYYY-MM-DD'))}
                                />
                                {errors && errors.issuedDate && <div className="text-danger size-12">{errors.issuedDate}</div>}
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="col-md-4 col-xs-12">
                                <style dangerouslySetInnerHTML={{ __html: "\ninput[type=\"file\"] {\n    display: none;\n}\n.custom-file-upload {\n    display: inline-block;\n}\n" }} />
                                <label className="custom-file-upload">
                                    <p style={{ fontWeight: 'normal'}}>
                                        {I18n.t('ScreenCreditDebitNote.Documents')}
                                    </p>
                                </label>
                            </div>
                            <div className="col-md-8 col-xs-12">
                                <ul className="images" style={{width: '100%'}}>
                                    {_.map(files, (item, idx) => (
                                        <li key={idx} style={{ marginBottom: 20, width: '100%', float: 'left' }}>
                                            <img
                                                src={this.getImage(item)}
                                                alt="Image"
                                                className="dnd-item"
                                                style={{ maxWidth: "64px", float: 'left' }}
                                            />
                                            <div style={{ maxWidth: 230, float: 'left', paddingLeft: 5 }}>
                                                <p style={{ fontSize: 12, width: '100%', height: '20px', }} className="text-ellipsis">{item.filename}</p>
                                                <span className="close-photo">
                                                    <img
                                                        style={{ cursor: "pointer" }}
                                                        src={icXHoverIcon}
                                                        onClick={e => {
                                                            e.stopPropagation();
                                                            this.onRemoveFile(idx);
                                                        }}
                                                    />

                                                </span>
                                            </div>
                                        </li>
                                    ))}
                                    {_.map(payInfo.documents, (item, i) => (
                                        <li key={i} style={{ marginBottom: 20, width: '100%', float: 'left' }}>
                                            <img
                                                src={this.handleImage(item)}
                                                alt="Image"
                                                className="dnd-item"
                                                style={{ maxWidth: "64px", float: 'left' }}
                                            />
                                            <div style={{ maxWidth: 230, float: 'left', paddingLeft: 5 }}>
                                                <p style={{ fontSize: 12, width: '100%', height: '20px', }} className="text-ellipsis">{_.last(item.split('/'))}</p>
                                                <span style={{ margin: '0px 10px' }}>
                                                    <img
                                                        style={{ cursor: "pointer" }}
                                                        src={downloadIcon}
                                                        onClick={e => {
                                                            e.stopPropagation();
                                                            this.onDownloadFileDocuments(item);
                                                        }}
                                                        style={{ width: 25, cursor:'pointer' }}
                                                    />
                                                </span>
                                                <span className="close-photo">
                                                    <img
                                                        style={{ cursor: "pointer" }}
                                                        src={icXHoverIcon}
                                                        onClick={e => {
                                                            e.stopPropagation();
                                                            this.onRemoveFileDocuments(i);
                                                        }}
                                                    />
                                                </span>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                                <div style={{ width: '100%', float: 'left'}}>
                                    <label htmlFor="file-upload" style={{border: '2px solid gray', borderRadius: 4, padding: 5, color: 'gray', fontWeight: 'bold', cursor: 'pointer'}} >Upload
                                        <input 
                                            id="file-upload"
                                            type="file"
                                            onChange={evt => this.addFile(evt)}
                                            // accept=".csv,.doc,.docx,image/*,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel" 
                                        />
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="form-group bd-dashed-bottom">
                            <div className="col-md-4 col-xs-12 text" />
                            <div className="col-md-8 col-xs-12 value row">
                                <ul className="nav nav-tabs col-md-8 col-xs-12 wrap-list-radio pd-10-0-15-0" role="tablist">
                                    <li role="presentation" className={payInfo.reason === CREDIT_DEBIT_TYPE.OF ? 'active' : ''}>
                                        <a role="tab">
                                            <input type="radio" id={CREDIT_DEBIT_TYPE.OF} name={CREDIT_DEBIT_TYPE.OF}
                                                checked={payInfo.reason === CREDIT_DEBIT_TYPE.OF} value={CREDIT_DEBIT_TYPE.OF}
                                                onChange={(evt) => this.setpayInfo('reason', evt.target.value)} />
                                            <label>{I18n.t('ScreenCreditDebitNote.OF')}</label>
                                            <div className="check">
                                                <div className="inside" />
                                            </div>
                                        </a>
                                    </li>
                                    <li role="presentation" className={payInfo.reason === CREDIT_DEBIT_TYPE.CS ? 'active' : ''}>
                                        <a role="tab">
                                            <input type="radio" id={CREDIT_DEBIT_TYPE.CS} name={CREDIT_DEBIT_TYPE.CS}
                                                checked={payInfo.reason === CREDIT_DEBIT_TYPE.CS} value={CREDIT_DEBIT_TYPE.CS}
                                                onChange={(evt) => this.setpayInfo('reason', evt.target.value)} />
                                            <label>{I18n.t('ScreenCreditDebitNote.CS')}</label>
                                            <div className="check">
                                                <div className="inside" />
                                            </div>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="form-group">
                            <ul className="nav nav-tabs col-md-12 col-xs-12 wrap-list-radio pd-10-0-15-0" role="tablist">
                                <li role="presentation" className={payInfo.typeUser === CREDIT_DEBIT_TYPE.CONTRACTOR ? 'active' : ''}>
                                    <a role="tab">
                                        <input type="radio" id={CREDIT_DEBIT_TYPE.CONTRACTOR} name={CREDIT_DEBIT_TYPE.CONTRACTOR}
                                            checked={payInfo.typeUser === CREDIT_DEBIT_TYPE.CONTRACTOR} value={CREDIT_DEBIT_TYPE.CONTRACTOR}
                                            onChange={(evt) => this.setpayInfo('typeUser', evt.target.value)} />
                                        <label>{I18n.t('ScreenCreditDebitNote.Contractor')}</label>
                                        <div className="check">
                                            <div className="inside" />
                                        </div>
                                    </a>
                                </li>
                                <li role="presentation" className={payInfo.typeUser === CREDIT_DEBIT_TYPE.SUPPLIER ? 'active' : ''}>
                                    <a role="tab">
                                        <input type="radio" id={CREDIT_DEBIT_TYPE.SUPPLIER} name={CREDIT_DEBIT_TYPE.SUPPLIER}
                                            checked={payInfo.typeUser === CREDIT_DEBIT_TYPE.SUPPLIER} value={CREDIT_DEBIT_TYPE.SUPPLIER}
                                            onChange={(evt) => this.setpayInfo('typeUser', evt.target.value)} />
                                        <label>{I18n.t('ScreenCreditDebitNote.Supplier')}</label>
                                        <div className="check">
                                            <div className="inside" />
                                        </div>
                                    </a>
                                </li>
                                <li role="presentation" className={payInfo.typeUser === CREDIT_DEBIT_TYPE.CUSTOMER ? 'active' : ''}>
                                    <a role="tab">
                                        <input type="radio" id={CREDIT_DEBIT_TYPE.CUSTOMER} name={CREDIT_DEBIT_TYPE.CUSTOMER}
                                            checked={payInfo.typeUser === CREDIT_DEBIT_TYPE.CUSTOMER} value={CREDIT_DEBIT_TYPE.CUSTOMER}
                                            onChange={(evt) => this.setpayInfo('typeUser', evt.target.value)} />
                                        <label>{I18n.t('ScreenCreditDebitNote.Customer')}</label>
                                        <div className="check">
                                            <div className="inside" />
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="form-group">
                            <div className="col-md-4 col-xs-12 text">
                                <p>
                                    {payInfo.typeUser === SUPPLIER ? I18n.t('ScreenCreditDebitNote.Supplier')
                                        : payInfo.typeUser === CUSTOMER ? I18n.t('ScreenCreditDebitNote.Customer')
                                        : I18n.t('ScreenCreditDebitNote.Contractor')
                                    }
                                </p>
                            </div>
                            <div className="col-md-8 col-xs-12 size-12">
                                {payInfo.typeUser &&
                                    <SearchSelect
                                        options={payInfo.typeUser === CUSTOMER ? customers : payInfo.typeUser === SUPPLIER ? suppliers : contractors}
                                        value={payInfo.typeUser === CUSTOMER ? payInfo.customer : payInfo.typeUser === SUPPLIER ? payInfo.supplier : payInfo.contractor}
                                        onChange={o => this.setpayInfo(payInfo.typeUser.toLocaleLowerCase(), o)}
                                        isLoading={fetchingData}
                                        clearable
                                    />
                                }
                                {errors && errors.obj && <div className="text-danger">{errors.obj}</div>}
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="col-md-4 col-xs-12 text">
                                <p>
                                    {I18n.t('ScreenCreditDebitNote.Amount')}
                                </p>
                            </div>
                            <div className="col-md-8 col-xs-12">
                                <input 
                                    type="number" 
                                    min="1" 
                                    placeholder="0.00"  
                                    value={payInfo.amount || ""} 
                                    className="form-control" 
                                    onChange={(evt => this.setpayInfo("amount", evt.target.value))} 
                                />
                                {errors && errors.amount && <div className="text-danger size-12">{errors.amount}</div>}
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="col-md-4 col-xs-12 text">
                                <p>
                                    {I18n.t('ScreenCreditDebitNote.Currency')}
                                </p>
                            </div>

                            <div className="col-md-8 col-xs-12 value">
                                <select className="form-control" value={payInfo.currency || ""} onChange={evt => this.setpayInfo("currency", evt.target.value)}>
                                    <option value="VND">VND</option>
                                    <option value="USD">USD</option>
                                </select>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="col-md-4 col-xs-12 text">
                                <p>
                                    {I18n.t('ScreenCreditDebitNote.Note')}
                                </p>
                            </div>
                            <div className="col-md-8 col-xs-12 value">
                                <textarea type="text" placeholder="Note" className="form-control" value={payInfo.note || ""} onChange={evt => this.setpayInfo('note', evt.target.value)} />
                            </div>
                        </div>
                        <div className="modal-footer pd-right-15">
                            <button type="button" className="bt-black" onClick={() => this.close()}>{I18n.t('Btn.CANCEL')}</button>
                            <button type="button" className="bt-orange" onClick={() => this.ok()}>{I18n.t('Btn.OK')}</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        appState: state.appState,
        authState: state.authState
    };
}

function mapDispatchToProps(dispatch) {
    return {
        commonUseActions: bindActionCreators(commonUseActions, dispatch),
        payInfoActions: bindActionCreators(payInfoActions, dispatch),
        dispatch
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalAddCreditDebit);