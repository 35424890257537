import {getListDepartmentDAL, editDepartmentDAL, addDepartmentDAL, deleteDepartmentDAL} from '../api/departmentApi';

class departmentServices {

  constructor() {
  }

  getListDepartment (options = {}) {
    options.query && _.set(options, 'query', JSON.stringify(options['query']));
    options.sort && _.set(options, 'sort', JSON.stringify(options['sort']));
    return getListDepartmentDAL(options).then((res) => {
      return res;
    });
  }

  editDepartment (id, data) {
    return editDepartmentDAL(id, data);
  }

  addDepartment (data) {
    return addDepartmentDAL(data);
  }

  deleteDepartment (id) {
   return deleteDepartmentDAL(id);
  }
}

export default new departmentServices();
