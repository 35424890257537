import C from '../../constants/ship-out/showPklShipList';
import shipOutApi from '../../api/shipOutApi';

export const getListPklShip = (options) => dispatch => {
    shipOutApi.getListPklShip(options).then(res => {
        if(res.success) {
            dispatch({type: C.CHANGE_PKL_SHIP_LIST, payload: res.data})
            dispatch({type: C.CHANGE_PAGING_TOTAL, payload: res.meta.pages})
            dispatch({type: C.CHANGE_PAGING_CURRENT, payload: res.meta.page})
        }
    })
}

export const changeSelectedFilter = (value) => dispatch => {
    if(value) {
        dispatch({type: C.CHANGE_SELECTED_FILTER, payload: value})
    }
}

export const changeSearchValue = (data, pos) => dispatch => {
    dispatch({type: C.CHANGE_SEARCH_TEXT, payload: {data, pos}})
}

export const changeCheckedList = (item, checked) => dispatch => {
    if(item) {
        if(checked)
            dispatch({type: C.ADD_CHECKED_PKL_SHIP, payload : item})
        else 
            dispatch({type: C.REMOVE_CHECKED_PKL_SHIP, payload: item})
    }
}

// export const search = (selectedFilter, searchValue) => dispatch => {
//     if(selectedFilter && searchValue) {
//         let op = {};
//         let key = selectedFilter;
//         if(selectedFilter == 'exFactory' || selectedFilter == 'invDate') {
//             let begin = moment(searchValue.from).format('YYYY-MM-DD');
//             let end = moment(searchValue.to).format('YYYY-MM-DD');
//             let date = `${begin}:${end}`;
//             op[key] = date
//             shipOutApi.searchPklShip(op).then(res => {
//                 if(res.success)
//                     dispatch({type: C.CHANGE_PKL_SHIP_LIST, payload: res.data})
//                     dispatch({type: C.CHANGE_PAGING_TOTAL, payload: res.meta.pages})
//                     dispatch({type: C.CHANGE_PAGING_CURRENT, payload: res.meta.page})
//             })
//         } else {
//             op[key] = searchValue.value
//             shipOutApi.searchPklShip(op).then(res => {
//                 if(res.success)
//                     dispatch({type: C.CHANGE_PKL_SHIP_LIST, payload: res.data})
//                     dispatch({type: C.CHANGE_PAGING_TOTAL, payload: res.meta.pages})
//                     dispatch({type: C.CHANGE_PAGING_CURRENT, payload: res.meta.page})
//             })
//         }
//     }
// }

export const changeSearchStatus = (value) => dispatch => {
    dispatch({type: C.CHANGE_SEARCH_STATUS, payload: value})
}

export const deletePklShip = (id, options) => dispatch => {
    shipOutApi.deletePklShipOut(id).then(res => {
        if(res.success) {
            shipOutApi.getListPklShip(options).then(resList => {
                if(res.success) {
                    dispatch({type: C.CHANGE_PKL_SHIP_LIST, payload: resList.data})
                    dispatch({type: C.CHANGE_PAGING_TOTAL, payload: resList.meta.pages})
                    dispatch({type: C.CHANGE_PAGING_CURRENT, payload: resList.meta.page})
                }
            })
        }
    })
}

export const resetCheckListAtPklShip = () => dispatch => {
    dispatch({type: C.CLEAR_ALL_CHECKED_PKL_SHIPS})
}