/* eslint-disable import/no-unresolved */
import React, { Component } from 'react';
import * as PATH from '../../../constants/routeConstants';
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom';
import _ from 'lodash';
import { toastr } from 'react-redux-toastr'
import I18n from 'i18n-js';
import PurchasePlanPlaceOrderView from '../../components/purchase/PurchasePlanPlaceOrder';
import * as selector from '../../../selectors/purchase/purchasePlans';
import * as actions from '../../../actions/purchase/purchasePlans';

class PurchasePlanPlaceOrder extends Component {

    componentWillReceiveProps(nextProps) {
        const {createSuccessStatus, materials} = nextProps;
        if(createSuccessStatus) {
            toastr.success(I18n.t('Notify.Success'), 'Create Purchase Order Successfully');
            nextProps.resetListOrder();
            nextProps.resetSuccessStatus();
            nextProps.history.push(PATH.PURCHASE_PLANS_URL);
        }
        if(_.isEmpty(materials)) {
            nextProps.history.push(PATH.PURCHASE_PLANS_PREVIEW_URL);
        }
    }

    previewOrder = () => {
        this.props.history.push(PATH.PURCHASE_PLANS_PREVIEW_ORDER_URL)
    }

    render() {
        return (
            <div>
                <PurchasePlanPlaceOrderView
                    {...this.props}
                    previewOrder = {() => this.previewOrder()}
                />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    orderDetail: selector.getSelectedOrder(state),
    selectedStyle: selector.getSelectedStyle(state),
    materials: selector.getSelectedMaterials(state),
    supplier: selector.getSupplier(state),
    listPayer: selector.getListPayer(state),
    sumGTotal: selector.getSumGTotalText(state),
    remark: selector.getRemark(state),
    dataApi: selector.genRequestBody(state),
    createSuccessStatus: selector.getCreateSuccessStatus(state),
})

const mapDispatchToProps = dispatch => ({
    createUseStock(data) {
        dispatch(actions.createUseStock(data))
    },
    resetData() {
        dispatch(actions.resetData())
    },
    selectedListMaterials(materials) {
        dispatch(actions.selectedListMaterials(materials))
    },
    changeMaterialValueInPlaceOrder(index, key, value) {
        dispatch(actions.changeMaterialValueInPlaceOrder(index, key, value))
    },
    changeRemark(value) {
        dispatch(actions.changeRemark(value))
    },
    save(data) {
        dispatch(actions.createPurchase(data))
    },
    send(data) {
        dispatch(actions.createPurchase(data))
    },
    resetListOrder() {
        dispatch(actions.resetListOrder())
    },
    resetSuccessStatus() {
        dispatch(actions.resetSuccessStatus())
    }
})


export default connect(mapStateToProps, mapDispatchToProps) (withRouter(PurchasePlanPlaceOrder));