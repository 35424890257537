import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import departmentActions from '../../actions/departmentActions';
import organizationActions from '../../actions/organizationActions';
import DepartmentTabEdit from './modal/DepartmentTabEdit';
import DepartmentTabAdd from './modal/DepartmentTabAdd';
import ModalConfirm from '../common/modals/ModalConfirm';
import SearchBar from '../common/layout/SearchBar';
import BaseComponent from '../base/BaseComponent';
import TableList from '../common/datatables/TableList';
import _ from 'lodash';
import I18n from 'i18n-js';
import organizationServices from "../../services/organizationServices";
import { SEARCH_BAR_EVENT, SEARCH_KEY, SEARCH_LIST } from "../../constants/constants";
import CustomPagination from '../common/datatables/CustomPagination';

class TabDepartment extends BaseComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {
      pageSize: 10,
      pageNumber: 1,
      columns: [
        { name: I18n.t('PageOrganization.Name'), value: 'name' },
        { name: I18n.t('PageOrganization.Organization'), value: 'organizationId.name' },
        { name: I18n.t('PageOrganization.Description'), value: 'description' },
      ],
      actions: [
        (item) => {
          return { type: 'edit', click: (item) => this.editDepartmentTab(item) };
        },
        (item) => {
          return { type: 'delete', click: (item) => this.showDeleteConfirm(item) };
        }
      ],
      items: [],
      organization: [],
      total: 0,
      searchValue: {},
      search: { key: SEARCH_KEY.NAME, value: "" }
    };

    this.onDeleteDepartment = this.onDeleteDepartment.bind(this);
  }

  componentWillMount() {
    this.getListItems();
    this.getListOrganization();
  }

  componentWillReceiveProps(newProps) {
    let { items, organization } = this.state;
    const newItems = _.cloneDeep(_.get(newProps.departmentState, 'items') || []);
    !_.isEqual(items, newItems) && this.setState({ items: newItems, total: newProps.departmentState.count || 0 });

    const newOrganization = _.cloneDeep(_.get(newProps.organizationState, 'items') || []);
    !_.isEqual(organization, newOrganization) && this.setState({ organization: newOrganization });
  }

  componentWillUpdate(nextProps, nextState) {
    if (_.get(this.props.departmentState, 'reload') != _.get(nextProps.departmentState, 'reload')) {
      this.search(nextState.search, nextState.pageNumber, nextState.pageSize)
    }
    _.get(this.props.departmentState, 'reload') != _.get(nextProps.departmentState, 'reload') && this.props.modal.closeModal();
  }

  getListOrganization() {
    organizationServices.getListOrganization({}).then(res => {
      const newOrganization = res.data.items;
      this.setState({ organization: newOrganization });
    });
  }

  getListItems(query = {}) {
    let { pageSize, pageNumber } = this.state;
    let _query = _.assign({ pageSize: pageSize, pageNumber: pageNumber, populate: 'organizationId' }, query)
    this.props.departmentActions.getListDepartment(_.set(_query, 'sort', { createdAt: -1 }));
  }

  addDepartmentTab() {
    let { openModal, closeModal } = this.props.modal;
    let modal = (<DepartmentTabAdd closeModal={closeModal} />);
    openModal(modal);
  }

  editDepartmentTab(item) {
    let { openModal, closeModal } = this.props.modal;
    let modal = (<DepartmentTabEdit closeModal={closeModal} options={{ data: item }} />);
    openModal(modal);
  }

  showDeleteConfirm(item) {
    let { openModal, closeModal } = this.props.modal;
    let modal = (<ModalConfirm
      title={I18n.t('PageOrganization.DELETEDEPARTMETNT')}
      confirmMessage={I18n.t('PageOrganization.ConfirmDeleteDepartment')}
      messageOk={I18n.t('Btn.YES')}
      messageCancel={I18n.t('Btn.CANCEL')}
      handleClose={closeModal}
      handleOk={this.onDeleteDepartment.bind(null, item)} />);
    openModal(modal);
  }

  onDeleteDepartment(item) {
    item._id && this.props.departmentActions.deleteDepartment(item._id);
  }

  selectPage = (page) => {
    this.setState({ pageNumber: page });
    this.search(this.state.search, page, this.state.pageSize);
  }

  onChangeSearch(property, value) {
    switch (property) {
      case SEARCH_BAR_EVENT.FILTER: {
        this.setState({ search: { key: value, value: "" } });
        break;
      }
      case SEARCH_BAR_EVENT.SEARCH: {
        this.setState({ search: { ...this.state.search, value: value } });
        break;
      }
    }
  }

  onSearch() {
    const { search, pageNumber, pageSize } = this.state;
    this.search(search, pageNumber, pageSize);
  }

  search(search, pageNumber, pageSize) {
    let options = {};
    const populate = 'organizationId';
    if (search && search.key && search.value) {

      switch (search.key) {
        case SEARCH_KEY.NAME: {
          options = {
            pageNumber,
            pageSize,
            sort: { updatedAt: -1 },
            populate: populate,
            query: { name: { $regex: '.*' + search.value + '.*', $options: 'i' } }
          };
          this.props.departmentActions.getListDepartment(options).then(res => {
            if (res && res.success && res.data && res.data.items && !res.data.items.length && pageNumber > 1) {
              options = {
                pageNumber: 1,
                pageSize,
                sort: { updatedAt: -1 },
                populate: populate,
                query: { name: { $regex: '.*' + search.value + '.*', $options: 'i' } }
              };
              this.setState({ pageNumber: 1 });
              this.props.departmentActions.getListDepartment(options)
            }
          });
          break;
        }
        case SEARCH_KEY.ORGANIZATION: {
          options = {
            projection: "_id",
            query: JSON.stringify({name: {$regex: '.*' + search.value + '.*', $options: 'i'}})
          };
          this.props.departmentActions.searchByContent(options, pageNumber, pageSize).then(res => {
            if(res && res.pageNumber && res.pageNumber == 1) {
              this.setState({pageNumber: 1});
            }
          });
          break;
        }
        case SEARCH_KEY.DESCRIPTION: {
          options = {
            pageNumber,
            pageSize,
            sort: { updatedAt: -1 },
            populate: populate,
            query: { description: { $regex: '.*' + search.value + '.*', $options: 'i' } }
          };
          this.props.departmentActions.getListDepartment(options).then(res => {
            if (res && res.success && res.data && res.data.items && !res.data.items.length && pageNumber > 1) {
              options = {
                pageNumber: 1,
                pageSize,
                sort: { updatedAt: -1 },
                populate: populate,
                query: { description: { $regex: '.*' + search.value + '.*', $options: 'i' } }
              };
              this.setState({ pageNumber: 1 });
              this.props.departmentActions.getListDepartment(options)
            }
          });
          break;
        }
      }
    }
    if (!search.value) {
      let options = {
        pageNumber,
        pageSize,
        sort: { updatedAt: -1 },
        populate: populate,
      };
      this.props.departmentActions.getListDepartment(options).then(res => {
        if (res && res.success && res.data && res.data.items && !res.data.items.length && pageNumber > 1) {
          options = {
            pageNumber: 1,
            pageSize,
            sort: { updatedAt: -1 },
            populate: populate,
          };
          this.setState({ pageNumber: 1 });
          this.props.departmentActions.getListDepartment(options);
        }
      });
    }
  }

  render() {
    let { items, total, columns, actions, pageNumber, pageSize } = this.state;
    let totalPage = _.ceil(total / pageSize);
    let tabPanelClass = this.props.active ? "tab-pane active" : "tab-pane";
    return (
      <div role="tabpanel" className={tabPanelClass} id="organization-department">
        <div className="wrap-search-position">
          <SearchBar
            name={I18n.t('PageOrganization.AddNew')}
            addItem={() => this.addDepartmentTab()}
            fields={SEARCH_LIST.ORGANIZATION_DEPARTMENT}
            onChangeSearch={(type, data) => this.onChangeSearch(type, data)}
            onSearch={() => this.onSearch()}
          />
        </div>

        <TableList {...this.props} items={items || []} columns={columns || []} actions={actions || []}
          options={{ clsTbl: 'global-table box-shadow-none' }} widthAction="w-70" />
        <CustomPagination items={totalPage} activePage={pageNumber} onSelect={this.selectPage} />
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    appState: state.appState,
    authState: state.authState,
    departmentState: state.departmentState,
    organizationState: state.organizationState
  };
}

function mapDispatchToProps(dispatch) {
  return {
    departmentActions: bindActionCreators(departmentActions, dispatch),
    organizationPropsActions: bindActionCreators(organizationActions, dispatch)
  };
}

TabDepartment.propTypes = {
  authState: PropTypes.object.isRequired,
  active: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(TabDepartment);
