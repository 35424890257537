import { SHIPPING } from '../constants/actionTypes';
import initial from './initialState';

export default function (state = initial.ShipOutState, action) {
    switch (action.type) {
        case SHIPPING.SHIP_OUT.GET_ITEMS_FROM_SEARCH:
            return {
                ...state, 
                items: action.data
            }
        default:
            return state;
    }
}
