import {getListItemDAL, addItemDAL, editItemDAL, getItemDAL, deleteItemDAL} from '../api/contractorApi';
import _ from 'lodash';

class contractorServices {

  constructor() {
  }

  getListItem (options = {}) {
    options.query && _.set(options, 'query', JSON.stringify(options['query']));
    options.sort && _.set(options, 'sort', JSON.stringify(options['sort']));
    return getListItemDAL(options).then((res) => {
      return res;
    });
  }

  getDetail (id, reset = true) {
    return getItemDAL(id, {});
  }

  deleteItem (id) {
    return deleteItemDAL(id);
  }

  editItem (id, data) {
   return editItemDAL(id, data);
  }

  addItem (data) {
   return addItemDAL(data);
  }
}

export default new contractorServices();
