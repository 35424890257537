import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import BaseComponent from '../../base/BaseComponent';
import { bindActionCreators } from 'redux';
import I18n from 'i18n-js';
import CheckList from '../../common/layout/list/CheckList';
import _ from 'lodash';
import Panel from '../../common/layout/panel/Panel';
import * as packingActions from '../../../actions/production/packing';
import { toastr } from 'react-redux-toastr';
import * as selector from '../../../selectors/production/addPacking';
import ModalEditCarton from '../../common/modals/ModalEditCarton';

import icEditXIcon from '../../../stylesheets/images/ic-edit-x.svg';
import icXIcon from '../../../stylesheets/images/ic-x.svg';
import addNewIcon from '../../../stylesheets/images/add-new.svg';

class AddPacking extends BaseComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {
      isEdit: false,
      packedStyles: {},
      packed: {},
      props: {},
    };
  }

  componentWillMount = () => {
    const isEdit = _.get(this.props, 'appState.pageList[0].data.isEdit', '');
    const notifyDate = _.get(this.props, 'appState.pageList[0].data.packing.notifyDate', '');
    if (isEdit) {
      this.getPacking();
      if (notifyDate) {
        this.setState({ isEdit });
      }
    } else this.getPackingByOrderAndContractor();
  };

  componentWillReceiveProps(nextProps) {
    const {
      packedStyles,
      customer,
      customerPO,
      contractor,
      type,
      packing,
      success,
      canResetPackedQuantity,
    } = nextProps;
    const isEdit = _.get(nextProps, 'appState.pageList[0].data.isEdit', '');
    packing === null && this.createPacking(packedStyles, customer, customerPO, contractor, type);

    if (success) {
      toastr.success(I18n.t('Notify.Success'), I18n.t('Notify.SaveSuccess'));
      this.props.packingActions.changeEmptyData();
    }

    if (canResetPackedQuantity && packing && isEdit) {
      this.props.packingActions.canResetPackedQuantity();
      let { getPackingTotal } = nextProps;
      _.map(packedStyles, style =>
        _.map(style.cutTickets, cut =>
          _.map(cut.colors, color =>
            _.map(color.sizes, size => {
              const total = getPackingTotal(style._id, cut._id, color._id, size.name);
              nextProps.packingActions.resetPackedQuantity(style._id, cut._id, color._id, size.name, total);
            })
          )
        )
      );
    }
  }

  editCarton = (styleId, cutId, colorId, no) => {
    let { openModal, closeModal } = this.props.modal;
    // const {listCartonsForEdit} = this.props
    let modal = <ModalEditCarton closeModal={closeModal} data={{ styleId, cutId, colorId, no }} ok={this.ok} />;
    openModal(modal);
  };

  ok = () => {};

  getPackingByOrderAndContractor = () => {
    const id = this.props.customerPO._id;
    const contractorId = _.get(this.props, 'contractor._id', undefined);
    let options = {};
    if (contractorId) {
      options = { contractorId };
    }
    this.props.packingActions.getPackingByOrder(id, options);
    // this.props.packingActions.getPackingByOrderAndContractor(id, contractorId);
  };

  getPacking = () => {
    const id = this.props.packingInfo._id;
    this.props.packingActions.getPacking(id);
  };

  createPacking = (packedStyles, customer, customerPO, contractor, type) => {
    this.props.packingActions.createPacking(packedStyles, customer, customerPO, contractor, type);
  };

  setRemainingTempCarton = packedStyles => {
    let { getRemaining } = this.props;
    _.map(packedStyles, style =>
      _.map(style.cutTickets, cut =>
        _.map(cut.colors, color =>
          _.map(color.sizes, size => {
            const total = getRemaining(style._id, cut._id, color._id, size.name);
            this.props.packingActions.setRemainingTempCarton(style._id, cut._id, color._id, size.name, total);
          })
        )
      )
    );
  };

  onChangeStyle = (_id, checked) => {
    this.props.packingActions.changeCheckedStyle(_id, checked);
  };

  onChangePackingGoHH = (value, styleId, cutId, colorId, sizeName) => {
    const total = this.props.getTotal(styleId, cutId, colorId, sizeName);
    this.props.packingActions.changePackingNumber(value, styleId, cutId, colorId, sizeName, total);
  };

  onChangeActualPacking = (value, styleId, cutId, colorId, sizeName, total) => {
    this.props.packingActions.changeActualPackingNumber(value, styleId, cutId, colorId, sizeName, total);
  };

  onChangePackingNote = (value, styleId, cutId, colorId) => {
    this.props.packingActions.changePackingNote(value, styleId, cutId, colorId);
  };

  onChangeCarton = (name, value, styleId, cutId, colorId, isBlur = false) => {
    this.props.packingActions.changePropsCarton(name, value, styleId, cutId, colorId);
  };
  onChangeCartonFloat = (name, value, styleId, cutId, colorId, isBlur = false) => {
    this.props.packingActions.changePropsCartonFloat(name, value, styleId, cutId, colorId);
  };

  addCarton = (listCartonsForEdit, styleId, cutTicketId, colorId, itemId, propsCarton, sizes) => {
    let arrSubpropsCarton = propsCarton.group.split('-');
    let err = false;
    _.map(listCartonsForEdit || [], item => {
      if (arrSubpropsCarton.length == 2 && item.noEnd != '') {
        if (
          (Number(item.noBegin) <= Number(arrSubpropsCarton[0]) &&
            Number(arrSubpropsCarton[0]) <= Number(item.noEnd)) ||
          (Number(item.noBegin) <= Number(arrSubpropsCarton[1]) && Number(arrSubpropsCarton[1]) <= Number(item.noEnd))
        ) {
          err = true;
        } else if (arrSubpropsCarton.length == 2 && item.noEnd == '') {
          if (item.noBegin == Number(arrSubpropsCarton[0]) || Number(item.noBegin) == Number(arrSubpropsCarton[1])) {
            err = true;
          } else if (
            Number(item.noBegin) >= Number(arrSubpropsCarton[0]) &&
            Number(item.noBegin) <= Number(arrSubpropsCarton[1])
          ) {
            err = true;
          }
        }
      } else if (arrSubpropsCarton.length == 1 && item.noEnd != '') {
        if (
          Number(item.noBegin) == Number(arrSubpropsCarton[0]) ||
          Number(item.noEnd) == Number(arrSubpropsCarton[0])
        ) {
          err = true;
        } else if (
          Number(item.noBegin) >= Number(arrSubpropsCarton[0]) &&
          Number(item.noEnd) <= Number(arrSubpropsCarton[0])
        ) {
          err = true;
        }
      } else if (arrSubpropsCarton.length == 1 && item.noEnd == '') {
        if (Number(item.noBegin) == Number(arrSubpropsCarton[0])) {
          err = true;
        }
      }
    });
    if (err) {
      toastr.error(I18n.t('Notify.Error'), 'Duplicate CTN No');
      return;
    }
    const { getPackingTotalCarton } = this.props;
    if (
      propsCarton.gwKgs == 0 ||
      propsCarton.nwKgs == 0 ||
      propsCarton.l == 0 ||
      propsCarton.h == 0 ||
      propsCarton.w == 0
    ) {
      toastr.error(I18n.t('Notify.Error'), 'Please change the information of the Carton');
      return;
    }
    const sumPacking = _.sum(sizes.map(size => getPackingTotalCarton(itemId, cutTicketId, colorId, size.name)));
    if (sumPacking == 0) {
      toastr.error(I18n.t('Notify.Error'), 'Please change packing number');
      return;
    }
    let { packedStyles } = this.props;
    this.setRemainingTempCarton(packedStyles);
    this.props.packingActions.createCarton(styleId, cutTicketId, colorId);
  };

  deleteCarton = (styleId, cutId, colorId, no) => {
    // console.log(group)
    this.props.packingActions.deleteCarton(styleId, cutId, colorId, no);
  };

  onChangeTotal = (value, name) => {
    this.props.packingActions.changeTotal(value, name);
  };

  changeShowCartonDetail = (styleId, cutTicketId, colorId, group) => {
    this.props.packingActions.changeShowCartonDetail(styleId, cutTicketId, colorId, group);
  };

  changeCheckedPacking = (styleId, cutTicketId, colorId, group, cartonNo, value) => {
    // this.props.packingActions.changeCheckedPacking(styleId, cutTicketId, colorId, group, cartonNo, value)
  };

  getCartonPlan = (cartons, sizeName) => {
    let result = 0;
    cartons.forEach(carton => {
      if (carton.items && carton.canExcute) {
        carton.items.forEach(item => {
          if (item.name == sizeName) {
            result += item.planQuantity || 0;
          }
        });
      }
    });
    return result;
  };

  save = () => {
    const { packing, getPackingTotal } = this.props;
    const { totalNW, totalGW } = this.props.packing || 0;
    if (packing.missingStatus) {
      toastr.warning('This packing is resolved missing, you cannot edit');
      return;
    }
    let param = '';
    if (totalNW === 0) {
      param = 'emptyTotalNW';
    }
    if (totalGW === 0) {
      param = 'emptyTotalGW';
    }
    if (getPackingTotal() === 0) {
      param = 'emptyPacking';
    }
    switch (param) {
      case 'emptyPacking':
        toastr.error(I18n.t('Notify.Error'), 'Please change packing number');
        break;
      case 'emptyTotalNW':
        toastr.error(I18n.t('Notify.Error'), "Total N.W mustn't equal 0");
        break;
      case 'emptyTotalGW':
        toastr.error(I18n.t('Notify.Error'), "Total G.W mustn't equal 0");
        break;
      default: {
        if (packing._id) {
          this.props.packingActions.editPacking(packing._id, packing);
        } else {
          this.props.packingActions.savePacking(packing);
        }
        break;
      }
    }
  };

  renderCustomer = (customer, customerPO, totalNW, totalGW) => {
    const {
      roles: { showCreate, showEdit },
    } = this.props;
    const { isEdit } = this.state;
    const packingType = this.props.packing && this.props.packing.type;
    const missingStatus =
      this.props.packing && this.props.packing.missingStatus ? this.props.packing.missingStatus : null;
    const packingTotal = this.props.getPackingTotal();
    var notifyDate = this.props.packing ? this.props.packing.notifyDate : null;
    var remaining = 0;
    var actualTotal = 0;

    // console.log(this.props.packing, packingTotal);
    if (packingType == 'Carton') {
      remaining = this.props.getRemainingCustomer || 0;
      actualTotal = this.props.getActualPacking() || 0;
    } else {
      if (_.isEmpty(notifyDate)) {
        remaining = this.props.getRemaining();
      } else {
        actualTotal = this.props.getActualPacking();
        remaining = packingTotal - actualTotal;
      }
    }

    return (
      <ul className="content-header-title nav-package">
        <li className="mg-right-40 order-1 order-sm-1 width-200">
          <p>
            {I18n.t('PageProduction.Customer')}
            <span
              className="color-orange medium mg-left-25 size-16 text-truncate float-right"
              style={{ display: 'inline-block', maxWidth: 100 }}>
              {customer || ''}
            </span>
          </p>
        </li>
        <li className="mg-right-40 order-2 order-sm-4 width-200">
          <p>
            {I18n.t('PageProduction.CustomerPO')}
            <span
              className="color-orange medium mg-left-25 size-16 text-truncate float-right"
              style={{ display: 'inline-block', maxWidth: 100 }}>
              {customerPO || ''}
            </span>
          </p>
        </li>
        <li className="mg-right-40 order-3 order-sm-2">
          <p style={{ display: 'flex' }}>
            {I18n.t('PageProduction.TotalNW')}
            {packingType == 'Carton' ? (
              <strong className="medium mg-left-25 size-16">{totalNW || 0}</strong>
            ) : (
              <input
                type="number"
                min="0"
                value={totalNW || 0}
                onChange={evt => {
                  this.onChangeTotal(evt.target.value, 'totalNW');
                }}
                className="mg-left-25 form-control center w-70"
              />
            )}
          </p>
        </li>
        <li className="mg-right-40 order-4 order-sm-5">
          <p style={{ display: 'flex' }}>
            {I18n.t('PageProduction.TotalGW')}
            {packingType == 'Carton' ? (
              <strong className="medium mg-left-25 size-16">{totalGW || 0}</strong>
            ) : (
              <input
                type="number"
                min="0"
                value={totalGW || 0}
                onChange={evt => {
                  this.onChangeTotal(evt.target.value, 'totalGW');
                }}
                className="mg-left-25 form-control center w-70"
              />
            )}
          </p>
        </li>
        <li className="mg-right-40 order-5 order-sm-3">
          {/*  GOHH  PackingType */}
          {notifyDate ? (
            <p>
              {I18n.t('PageProduction.Missing')}
              <strong className="color-green medium mg-left-25 size-16">{remaining}</strong>
            </p>
          ) : (
            <p>
              {I18n.t('PageProduction.Remaining')}
              <strong className="color-green medium mg-left-25 size-16">{remaining}</strong>
            </p>
          )}
        </li>
        <li className="mg-right-40 order-6 order-sm-6">
          {_.isEmpty(notifyDate) && (
            <p>
              {I18n.t('PageProduction.PackingQTY')}
              <strong className="color-red medium mg-left-25 size-16">{packingTotal}</strong>
            </p>
          )}
          {!_.isEmpty(notifyDate) && (
            <p>
              {I18n.t('PageProduction.TotalQTY')}
              <strong className="color-red medium mg-left-25 size-16">{actualTotal}</strong>
            </p>
          )}
        </li>
        {!missingStatus
          ? ((isEdit && showEdit) || showCreate) && (
              <li className="float-right add-filter-top order-7 order-sm-7" style={{ flex: '1 1 auto', width: 'auto' }}>
                <span
                  className="cursor-pointer"
                  onClick={this.save}
                  data-toggle="modal"
                  data-target="#modal-add-order"
                  style={{ float: 'right', width: 80 }}>
                  <i className="fa fa-floppy-o" aria-hidden="true"></i>
                  {I18n.t('PageProduction.SAVE')}
                </span>
              </li>
            )
          : ''}
      </ul>
    );
  };

  renderPanelContent = style => {
    const {
      packedStyles,
      getPackingTotal,
      getActualPacking,
      getRemaining,
      getPackingTotalCarton,
      getCarton,
      packing,
      listCartonsForEdit,
      getMissingPacking,
    } = this.props;
    const packingType = packing.type;
    // let item = _.filter(packedStyles, packedStyle => packedStyle._id === style.style)[0];
    const { isEdit } = this.state;
    // console.log(this.props)
    // console.log(this.state)
    let item = packedStyles
      .map(pkstyle => {
        if (pkstyle._id === style.style) {
          const cutTickets = _.map(pkstyle.cutTickets, cutTicket => {
            let newCutTicket = null;
            _.forEach(style.cutTickets, c => {
              if (c.cutTicket === cutTicket._id) {
                let colors = [];
                cutTicket.colors.forEach(c1 => {
                  if (_.find(c.colors, c2 => c1._id === c2.color, 0)) {
                    colors.push(c1);
                  }
                });
                newCutTicket = Object.assign({}, cutTicket);
                newCutTicket.colors = colors;
                return false;
              }
            });
            return newCutTicket;
          }).filter(i => i !== null);
          let newStyle = Object.assign({}, pkstyle);
          newStyle.cutTickets = cutTickets;
          return newStyle;
        }
        return null;
      })
      .filter(i => i !== null)[0];
    return (
      <div className="packing-customerPO-content">
        {item &&
          (item.cutTickets || []).map((cut, idx) => {
            return (
              <div key={idx}>
                <ul className="content-header-title">
                  <li>
                    <div className="text">
                      <span className="left size-12 mg-right-5">{I18n.t('PageProduction.CutTicket')}</span>
                      <span className="color-orange bold mg-left-50 size-16">{_.get(cut, 'cutTicket', '')}</span>
                    </div>
                  </li>
                  <li className="float-right">
                    <div className="text">
                      <a className="text-decoration color-link mg-left-50">
                        {_.get(cut, 'store.packingStandard.name', '')}
                      </a>
                    </div>
                  </li>
                  <li className="float-right mg-right-50">
                    <div className="text">
                      <span className="left size-12 mg-right-15">{I18n.t('PageOrder.Store')}</span>
                      <span className="bold">{_.get(cut, 'store.name', '')}</span>
                    </div>
                  </li>
                  <li className="float-right mg-right-50">
                    <div className="text">
                      <span className="left size-12 mg-right-15">{I18n.t('PageOrder.MainLabel')}</span>
                      <span className="bold">{_.get(cut, 'mainLabel', '')}</span>
                    </div>
                  </li>
                </ul>
                {(cut.colors || []).map((color, cidx) => {
                  const sizes = _.get(color, 'sizes', []);
                  let cartons = getCarton(item._id, cut._id, color._id);
                  let note = style.cutTickets[idx].colors[cidx] ? style.cutTickets[idx].colors[cidx].note || '' : '';
                  const propsCarton = style.cutTickets[idx].colors[cidx]
                    ? _.get(style.cutTickets[idx].colors[cidx].packings[0], 'props', {})
                    : {};
                  const colorPacking = getPackingTotal(item._id, cut._id, color._id);
                  const styleId = _.get(item, '_id', '');
                  const cutTicketId = _.get(cut, '_id', '');
                  const colorId = _.get(color, '_id', '');
                  const key = `${styleId}:${cutTicketId}:${colorId}`;
                  let totalTam = 0;

                  if (listCartonsForEdit && listCartonsForEdit[key]) {
                    listCartonsForEdit[key].map(carton => {
                      let qtyCtn = 0;
                      carton.items.map(item => {
                        let tam = item.actualQuantity == null ? item.planQuantity : item.actualQuantity;
                        qtyCtn += tam;
                      });
                      if (carton.canExcute == true) {
                        carton.totalQtyCtn = qtyCtn;
                        totalTam += carton.totalQtyCtn;
                      }
                    });

                    listCartonsForEdit[key].map(carton => {
                      if (carton.canExcute == false) {
                        carton.totalQtyCtn = totalTam;
                      }
                    });
                  }
                  return (
                    <div key={cidx} className="clear-both mg-top-5">
                      <div className="display-flex bg-white">
                        <div className="pd-10 display-flex align-items-center pd-20 w-180">
                          <h3 className="center mg-auto size-12">
                            <span className="fw-normal">{I18n.t('PageProduction.Color')}</span>
                            <br />
                            <br />
                            <strong className="size-13">{color.name}</strong>
                            <br />
                            <br />
                            {_.isEmpty(packing.notifyDate) && <span className="bold color-red">{colorPacking}</span>}
                          </h3>
                        </div>
                        <div className="pd-10 bd-solid-left">
                          <table className="global-table tr-height-30 box-shadow-none">
                            {_.isEmpty(packing.notifyDate) && (
                              <tbody>
                                <tr style={{ borderBottom: 'unset' }} className="bg-none">
                                  <td className="fw-normal">{I18n.t('PageProduction.TotalOrder')}</td>
                                  {sizes.map((size, sidx) => {
                                    return (
                                      <td key={sidx}>
                                        <span className="medium">{size.name}</span> : {size.quantity}
                                      </td>
                                    );
                                  })}
                                </tr>
                                <tr className="bg-none">
                                  <td className="fw-normal">{I18n.t('PageProduction.TotalPassQA')}</td>
                                  {sizes.map((size, sidx) => {
                                    return (
                                      <td key={sidx}>
                                        <span className="medium">{size.name}</span> : {size.passQuantity}
                                      </td>
                                    );
                                  })}
                                </tr>

                                <tr className="bg-none">
                                  <td className="fw-normal">{I18n.t('PageProduction.Remaining')}</td>
                                  {sizes.map((size, sidx) => {
                                    const remaining = getRemaining(item._id, cut._id, color._id, size.name);
                                    return (
                                      <td className="center color-green" key={sidx}>
                                        {remaining || 0}
                                      </td>
                                    );
                                  })}
                                </tr>

                                <tr className="bg-none">
                                  <td className="fw-normal">{I18n.t('PageProduction.Packing')}</td>
                                  {sizes.map((size, sidx) => {
                                    const packingGOHH = getPackingTotal(item._id, cut._id, color._id, size.name);
                                    const packingCarton = getPackingTotalCarton(
                                      item._id,
                                      cut._id,
                                      color._id,
                                      size.name
                                    );
                                    return (
                                      <td key={sidx} className="center w-100">
                                        <input
                                          type="number"
                                          min="0"
                                          value={packingType === 'GOHH' ? packingGOHH : packingCarton}
                                          onChange={evt => {
                                            this.onChangePackingGoHH(
                                              evt.target.value,
                                              styleId,
                                              cutTicketId,
                                              colorId,
                                              size.name
                                            );
                                          }}
                                          className="form-control center pd-right-0"
                                        />
                                      </td>
                                    );
                                  })}
                                </tr>
                              </tbody>
                            )}

                            {!_.isEmpty(packing.notifyDate) && (
                              <tbody>
                                {packingType === 'GOHH' && (
                                  <tr style={{ borderBottom: 'unset' }} className="bg-none">
                                    <td className="fw-normal">{I18n.t('PageProduction.Plan')}</td>
                                    {sizes.map((size, sidx) => {
                                      size.packingGOHH = getPackingTotal(item._id, cut._id, color._id, size.name);
                                      return (
                                        <td key={sidx}>
                                          <span className="medium">{size.name}</span> : {size.packingGOHH}
                                        </td>
                                      );
                                    })}
                                  </tr>
                                )}

                                <tr className="bg-none" style={{ borderBottom: 'unset' }}>
                                  <td className="fw-normal">{I18n.t('PageProduction.Actual')}</td>
                                  {sizes.map((size, sidx) => {
                                    size.actualQuantity = getActualPacking(item._id, cut._id, color._id, size.name);
                                    size.remaining = getMissingPacking(item._id, cut._id, color._id, size.name);
                                    // if (size.actualQuantity == 0) {
                                    //   size.actualQuantity = size.packingGOHH;
                                    // }
                                    if (packingType === 'GOHH') {
                                      return (
                                        <td key={sidx} className="center w-100">
                                          <input
                                            type="number"
                                            min="0"
                                            value={size.actualQuantity}
                                            onChange={evt => {
                                              this.onChangeActualPacking(
                                                evt.target.value,
                                                styleId,
                                                cutTicketId,
                                                colorId,
                                                size.name,
                                                size.packingGOHH
                                              );
                                            }}
                                            className="form-control center pd-right-0 max-width-70"
                                          />
                                        </td>
                                      );
                                    } else {
                                      return (
                                        <td key={sidx}>
                                          <span className="medium">{size.name}</span> : {size.actualQuantity || 0}
                                        </td>
                                      );
                                    }
                                    // return <td key={sidx}><span className="medium">{size.name}</span> : {size.packedQuantity}</td>
                                  })}
                                </tr>
                                <tr className="bg-none">
                                  <td className="fw-normal">{I18n.t('PageProduction.Missing')}</td>
                                  {sizes.map((size, sidx) => {
                                    let remaining = 0;
                                    if (packingType === 'GOHH') {
                                      remaining = size.packingGOHH - size.actualQuantity;
                                    } else {
                                      // remaining = this.getCartonPlan(listCartonsForEdit[key], size.name) - (size.actualPacking || 0);
                                      remaining = size.remaining;
                                    }

                                    return (
                                      <td className="color-green" key={sidx}>
                                        <span className="medium">{size.name}</span> : {remaining || 0}
                                      </td>
                                    );
                                  })}
                                </tr>
                                {packingType === 'GOHH' && (
                                  <tr className="bg-none">
                                    <td className="fw-normal">{I18n.t('PageProduction.Note')}</td>
                                    <td colSpan={sizes.length}>
                                      <input
                                        value={note}
                                        onChange={evt => {
                                          this.onChangePackingNote(evt.target.value, styleId, cutTicketId, colorId);
                                        }}
                                        className="form-control pd-right-0 min-width-250"
                                      />
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            )}
                          </table>
                        </div>
                      </div>
                      {packingType == 'Carton' && (
                        <div className="bg-white mg-top-2 pd-10">
                          {_.isEmpty(packing.notifyDate) && (
                            <ul className="display-table w-full">
                              <li className="display-table-cell">
                                <div className="text">
                                  <span className="left size-12 mg-right-5">{I18n.t('PageProduction.CTNNO')}</span>
                                  <input
                                    type="number"
                                    min="1"
                                    value={propsCarton && propsCarton.noBegin}
                                    onChange={evt => {
                                      this.onChangeCarton('noBegin', evt.target.value, styleId, cutTicketId, colorId);
                                    }}
                                    className="form-control inline-block center pd-right-0 w-50"
                                  />
                                  &nbsp;&nbsp;&rArr;&nbsp;&nbsp;
                                  <input
                                    type="number"
                                    min="1"
                                    value={propsCarton && propsCarton.noEnd}
                                    onChange={evt => {
                                      this.onChangeCarton('noEnd', evt.target.value, styleId, cutTicketId, colorId);
                                    }}
                                    onBlur={evt => {
                                      this.onChangeCarton(
                                        'noEnd',
                                        evt.target.value,
                                        styleId,
                                        cutTicketId,
                                        colorId,
                                        true
                                      );
                                    }}
                                    className="form-control inline-block center pd-right-0 w-50"
                                  />
                                </div>
                              </li>
                              <li className="display-table-cell">
                                <div className="text">
                                  <span className="left size-12 mg-right-5">{I18n.t('PageProduction.NWKGS')}</span>
                                  <input
                                    type="number"
                                    min="0"
                                    value={(propsCarton && propsCarton.nwKgs) || 0}
                                    onChange={evt => {
                                      this.onChangeCartonFloat(
                                        'nwKgs',
                                        evt.target.value,
                                        styleId,
                                        cutTicketId,
                                        colorId
                                      );
                                    }}
                                    className="form-control inline-block center pd-right-0 w-50"
                                  />
                                </div>
                              </li>
                              <li className="display-table-cell">
                                <div className="text">
                                  <span className="left size-12 mg-right-5">{I18n.t('PageProduction.GWKGS')}</span>
                                  <input
                                    type="number"
                                    min="0"
                                    value={(propsCarton && propsCarton.gwKgs) || 0}
                                    onChange={evt => {
                                      this.onChangeCartonFloat(
                                        'gwKgs',
                                        evt.target.value,
                                        styleId,
                                        cutTicketId,
                                        colorId
                                      );
                                    }}
                                    className="form-control inline-block center pd-right-0 w-50"
                                  />
                                </div>
                              </li>
                              <li className="display-table-cell">
                                <div className="text">
                                  <span className="left size-12 mg-right-5">{I18n.t('PageProduction.MEANS')}</span>
                                  <input
                                    type="number"
                                    min="0"
                                    value={(propsCarton && propsCarton.l) || 0}
                                    onChange={evt => {
                                      this.onChangeCartonFloat('l', evt.target.value, styleId, cutTicketId, colorId);
                                    }}
                                    className="form-control inline-block center pd-right-0 w-50"
                                  />
                                  &nbsp;&nbsp;&minus;&nbsp;&nbsp;
                                  <input
                                    type="number"
                                    min="0"
                                    value={(propsCarton && propsCarton.w) || 0}
                                    onChange={evt => {
                                      this.onChangeCartonFloat('w', evt.target.value, styleId, cutTicketId, colorId);
                                    }}
                                    className="form-control inline-block center pd-right-0 w-50"
                                  />
                                  &nbsp;&nbsp;&minus;&nbsp;&nbsp;
                                  <input
                                    type="number"
                                    min="0"
                                    value={(propsCarton && propsCarton.h) || 0}
                                    onChange={evt => {
                                      this.onChangeCartonFloat('h', evt.target.value, styleId, cutTicketId, colorId);
                                    }}
                                    className="form-control inline-block center pd-right-0 w-50"
                                  />
                                  <button
                                    onClick={() =>
                                      this.addCarton(
                                        cartons,
                                        styleId,
                                        cutTicketId,
                                        colorId,
                                        item._id,
                                        propsCarton,
                                        sizes
                                      )
                                    }
                                    className="cursor-pointer icon-add button vertical-align-middle">
                                    <img src={addNewIcon} />
                                  </button>
                                </div>
                              </li>
                            </ul>
                          )}
                        </div>
                      )}
                      {isEdit && packingType == 'Carton' && listCartonsForEdit[key].length > 0 && (
                        <div className="bg-white mg-top-2">
                          <table className="global-table w-full">
                            <tbody>
                              <tr>
                                <th className="center"></th>
                                <th className="center">{I18n.t('PageShiping.CTNNo')}</th>
                                <th className="center">{I18n.t('PageShiping.NW')}</th>
                                <th className="center">{I18n.t('PageShiping.GW')}</th>
                                <th className="center">{I18n.t('PageShiping.MEANS')}</th>
                                <th className="center">{I18n.t('PageShiping.M3')}</th>
                                {sizes.map((size, idx) => (
                                  <th key={idx} className="center">
                                    {size.name}
                                  </th>
                                ))}
                                <th className="center">{I18n.t('PageShiping.QTY')}</th>
                                <th className="center">{I18n.t('PageShiping.QTY/CTN')}</th>
                                <th className="center">{I18n.t('PageShiping.Total')}</th>
                                <th className="center"></th>
                              </tr>
                              {listCartonsForEdit[key].map((carton, idx) => {
                                if (!_.isEmpty(carton) && (carton.active == true || carton.active == null)) {
                                  const qty = carton.quantity || 0;
                                  // const qtyCtn = carton.qtyCtn || 0
                                  let qtyCtn = 0;
                                  carton.items.map((item, idx) => {
                                    let tam = item.actualQuantity == null ? item.planQuantity : item.actualQuantity;
                                    qtyCtn += tam;
                                  });
                                  const m3 = _.round(0.00001648 * carton.props.l * carton.props.w * carton.props.h, 5);
                                  const total = carton.totalQtyCtn || qty * qtyCtn || 0;
                                  return (
                                    <tr key={idx} className={carton.active != null ? 'bg-gray' : ''}>
                                      {carton.active == null && carton.canExcute == false ? (
                                        <td className="center">
                                          {carton.canExcute == false && (
                                            <span
                                              className="fa fa-caret-down cursor-pointer"
                                              style={{ marginLeft: 10 }}
                                              onClick={() =>
                                                this.changeShowCartonDetail(styleId, cutTicketId, colorId, carton.group)
                                              }></span>
                                          )}
                                        </td>
                                      ) : (
                                        <td className=" center style-checkbox">
                                          {/*<input checked={carton.checked}*/}
                                          {/*type="checkbox"*/}
                                          {/*id={`${styleId}:${cutTicketId}:${colorId}:${carton.group}:${carton.ctnNo}`}*/}
                                          {/*onChange={() => this.changeCheckedPacking(styleId, cutTicketId, colorId, carton.group, carton.ctnNo, !carton.checked)}*/}
                                          {/*/>*/}
                                          <label
                                            htmlFor={`${styleId}:${cutTicketId}:${colorId}:${carton.group}:${carton.ctnNo}`}>
                                            <span>
                                              <span />
                                            </span>
                                          </label>
                                        </td>
                                      )}
                                      <td className="center">{carton.ctnNo}</td>
                                      {/*{carton.active == null &&*/}
                                      {/*<td className="center">{ carton.props.nwKgs}</td>*/}
                                      {/*}*/}
                                      {/*{carton.active == null &&*/}
                                      {/*<td className="center">{carton.props.gwKgs}</td>*/}
                                      {/*}*/}
                                      {/*<td className="center">{carton.active == null ? carton.props.nwKgs || '-' : ''}</td>*/}
                                      {/*<td className="center">{carton.active == null ? carton.props.gwKgs || '-' : ''}</td>*/}
                                      {carton.active == null &&
                                        carton.canExcute == true &&
                                        carton.items.map((item, idx) => {
                                          if (item.name == 'gwKgs' || item.name == 'nwKgs') {
                                            return (
                                              <td className="center" key={idx}>
                                                {item.value}
                                              </td>
                                            );
                                          }
                                        })}

                                      {carton.active == null &&
                                        carton.canExcute == false &&
                                        [1, 2].map(i => {
                                          return <td className="center">-</td>;
                                        })}

                                      {carton.active == true &&
                                        carton.canExcute == true &&
                                        carton.items.map((item, idx) => {
                                          if (item.name == 'gwKgs' || item.name == 'nwKgs') {
                                            return (
                                              <td className="center" key={idx}>
                                                {item.value}
                                              </td>
                                            );
                                          }
                                        })}

                                      <td className="center">{`${carton.props.l || '-'}" x ${
                                        carton.props.w || '-'
                                      }" x ${carton.props.h || '-'}"`}</td>

                                      {carton.active == null && <td className="center">{m3}</td>}
                                      {carton.active !== null && carton.canExcute == true && (
                                        <td className="center"> </td>
                                      )}
                                      {/*<td className="center">{carton.active == null ? m3 : ''}</td>*/}

                                      {carton.items.map((item, idx) => {
                                        if (item.name != 'gwKgs' && item.name != 'nwKgs') {
                                          return (
                                            <td className="color-orange center" key={idx}>
                                              {item.actualQuantity == null ? item.planQuantity : item.actualQuantity}
                                            </td>
                                          );
                                        }
                                      })}
                                      <td className="color-green center">{qty}</td>
                                      <td className="color-green center">{qtyCtn}</td>
                                      <td className="color-red center">{total}</td>
                                      <td className="center">
                                        {!_.isEmpty(packing.notifyDate) && carton.checked && (
                                          <span
                                            onClick={() =>
                                              this.editCarton(styleId, cutTicketId, colorId, carton.props.no)
                                            }
                                            className="cursor-pointer">
                                            <img src={icEditXIcon} alt="" />
                                          </span>
                                        )}
                                      </td>
                                    </tr>
                                  );
                                }
                              })}
                            </tbody>
                          </table>
                        </div>
                      )}
                      {!isEdit && packingType == 'Carton' && cartons.length > 0 && (
                        <div className="bg-white mg-top-2">
                          <table className="global-table w-full">
                            <tbody>
                              <tr>
                                <th className="center">{I18n.t('PageProduction.CTNNO')}</th>
                                <th className="center">{I18n.t('PageProduction.NWKGS')}</th>
                                <th className="center">{I18n.t('PageProduction.GWKGS')}</th>
                                <th className="center">{I18n.t('PageProduction.MEANSInch')}</th>
                                <th className="center">{I18n.t('PageProduction.M3')}</th>
                                {sizes.map((size, idx) => (
                                  <th key={idx} className="center">
                                    {size.name}
                                  </th>
                                ))}
                                <th className="center">{I18n.t('PageProduction.QTY')}</th>
                                <th className="center">{I18n.t('PageProduction.QTYCTN')}</th>
                                <th className="center">{I18n.t('PageProduction.Total')}</th>
                                <th className="center"></th>
                              </tr>
                              {cartons.map((carton, idx) => {
                                let setActualQuantity = carton.items.find(
                                  item => typeof item.actualQuantity != 'undefined'
                                );
                                const qty = carton.quantity;
                                const qtyCTN = carton.quantityCTN;
                                const m3 = _.round(0.00001648 * carton.props.l * carton.props.w * carton.props.h, 5);
                                const total = carton.total;
                                const be = _.isEmpty(carton.noEnd) ? (
                                  <td className="center">{carton.noBegin}</td>
                                ) : (
                                  <td className="center">
                                    {carton.noBegin}&nbsp;&nbsp;&rArr;&nbsp;&nbsp;{carton.noEnd}
                                  </td>
                                );
                                return (
                                  <tr key={idx}>
                                    {be}
                                    <td className="center">{carton.props.nwKgs || '-'}</td>
                                    <td className="center">{carton.props.gwKgs || '-'}</td>
                                    <td className="center">{`${carton.props.l || '-'}" x ${carton.props.w || '-'}" x ${
                                      carton.props.h || '-'
                                    }"`}</td>
                                    <td className="center">{m3}</td>
                                    {carton.items.map((item, idx) => {
                                      let planQuantity = item.planQuantity;
                                      if (!_.isEmpty(packing.notifyDate) && setActualQuantity) {
                                        planQuantity = item.actualQuantity || 0;
                                      }
                                      return (
                                        <td className="color-orange center" key={idx}>
                                          {planQuantity}
                                        </td>
                                      );
                                    })}
                                    <td className="color-green center">{qty}</td>
                                    <td className="color-green center">{qtyCTN}</td>
                                    <td className="color-red center">{total}</td>
                                    <td className="center">
                                      {_.isEmpty(packing.notifyDate) && (
                                        <span
                                          onClick={() =>
                                            this.deleteCarton(styleId, cutTicketId, colorId, carton.props.group)
                                          }
                                          className="cursor-pointer">
                                          <img src={icXIcon} alt="" />
                                        </span>
                                      )}
                                      {!_.isEmpty(packing.notifyDate) && (
                                        <span
                                          onClick={() =>
                                            this.editCarton(styleId, cutTicketId, colorId, carton.props.no)
                                          }
                                          className="cursor-pointer">
                                          <img src={icEditXIcon} alt="" />
                                        </span>
                                      )}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            );
          })}
      </div>
    );
  };

  renderPanelDetails = style => {
    const { getPackingTotal, getRemaining, packedStyles, packing } = this.props;
    const remaining = getRemaining(style.style);
    const packingTotal = getPackingTotal(style.style);
    const tmp = _.find(packedStyles || [], packedStyle => packedStyle._id == style.style);
    return (
      <ul>
        <li>
          <div className="text">
            <span className="left">{I18n.t('PageProduction.Style')}</span>
            <span className="right size-15">
              <span className="medium">{(tmp && tmp.style) || ''}</span>
            </span>
          </div>
        </li>
        <li className="float-right ">
          <div className="text">
            {_.isEmpty(packing.notifyDate) && (
              <span className="left size-12 mg-right-5">{I18n.t('PageProduction.PackingQTY')}</span>
            )}
            {_.isEmpty(packing.notifyDate) && <span className="right size-15">{packingTotal}</span>}
          </div>
        </li>
        <li className="float-right mg-right-50">
          <div className="text">
            {_.isEmpty(packing.notifyDate) && (
              <span className="left size-12 mg-right-5">{I18n.t('PageProduction.Remaining')}</span>
            )}
            {_.isEmpty(packing.notifyDate) && <span className="right size-15 mg-left-15">{remaining}</span>}
          </div>
        </li>
      </ul>
    );
  };

  render = () => {
    const {
      packing,
      packedStyles,
      roles: { showCreate, showEdit, showDelete, showView },
    } = this.props;
    const { isEdit } = this.state;
    const name = _.get(this.props, 'customer.name', null);
    // const {name} = this.props.customer || {};
    const { customer } = this.props.packingInfo || {};
    const getCustomerName = name || customer; // view || add

    const { customerPO } = this.props.customerPO || this.props.packingInfo || '';
    const totalNW = _.get(this.props, 'packing.totalNW', 0);
    const totalGW = _.get(this.props, 'packing.totalGW', 0);
    const packingTotal = this.props.getPackingTotal();
    const styles =
      packing && packing.styles
        ? (packing.styles || []).map((item, idx) => ({
            name: packedStyles ? _.get(packedStyles[idx], 'style', '') : '',
            _id: _.get(item, 'style', ''),
            active: _.get(item, 'active', false),
          }))
        : [];

    return (
      <div id="content">
        <div id="view-packing">
          {this.renderCustomer(getCustomerName, customerPO, totalNW, totalGW, packingTotal)}
          <CheckList
            items={styles}
            title={I18n.t('PageProduction.Style')}
            onChange={(_id, checked) => this.onChangeStyle(_id, checked)}
          />
          <div className="panel-group wrap-scrollbar" id="panel-list">
            {packing && packing.styles
              ? (packing.styles || []).map((style, index) => {
                  if (!_.get(style, 'active', false)) return undefined;
                  return (
                    <Panel
                      key={_.get(style, 'style', index)}
                      id={_.get(style, 'style', index)}
                      renderContent={() => this.renderPanelContent(style)}
                      renderPanelDetails={() => this.renderPanelDetails(style)}
                    />
                  );
                })
              : null}
          </div>
        </div>
      </div>
    );
  };
}

function mapStateToProps(state, ownProps) {
  return {
    customer: selector.getCustomer(state),
    customerPO: selector.getCustomerPO(state),
    contractor: selector.getContractor(state),
    type: selector.getPackingType(state),
    packedStyles: selector.getPackingByOrder(state),
    packing: selector.getPacking(state),
    getRemaining: selector.getRemaining(state),
    getRemainingCustomer: selector.getRemainingCustomer(state),
    getPackingTotal: selector.getPackingTotal(state),
    getActualPacking: selector.getActualPacking(state),
    getMissingPacking: selector.getMissingPacking(state),
    getPackingTotalCarton: selector.getPackingTotalCarton(state),
    getTotal: selector.getTotal(state),
    getCarton: selector.getCarton(state),
    success: selector.getSuccess(state),
    packingInfo: selector.getPackingInfo(state),
    canResetPackedQuantity: selector.resetPackedQuantity(state),
    listCartonsForEdit: selector.getListCartonsForEdit(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    packingActions: bindActionCreators(packingActions, dispatch),
  };
}

AddPacking.propTypes = {
  data: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddPacking);
