import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Switch, Route } from "react-router-dom";
import { toastr } from 'react-redux-toastr';
import I18n from 'i18n-js';
import * as PATH from '../../../../constants/routeConstants';
import * as R from 'ramda';
import * as actions from "../../../../actions/accounting/payable/createInvoiceSupplier";
import * as selector from "../../../../selectors/accounting/payable/createInvoiceSupplier";
import CreatePlanSupplierDomesticView from "../../../components/accounting/payable/CreatePlanSupplierDomestic";
import PreivewSupplierDomestic from './PreviewSupplierDomestic';
import CreatePlanSupplierOversea from "../../../components/accounting/payable/CreatePlanSupplierOversea";

class AccountsPayablePlanSupplier extends Component {

  componentWillMount() {
    this.props.loadData();
    this.props.setSupplierType("domesticInvoice")
  }

  componentWillUnmount() {
    // this.props.resetData();
  }

  componentWillReceiveProps(nextProps) {
    const { success } = nextProps;
    if (success) {
      nextProps.resetData()
      nextProps.router.history.push(PATH.ACCOUNTING_PAYABLE_INVOICE_URL);
      toastr.success(I18n.t('Notify.Success'), 'Create INV successfully')
    }
  }

  changeSelectedCustomerPOs = (id, checked) => {
      const { checkablePurchaseOrders } = this.props;
      const item = checkablePurchaseOrders.find(item => item._id == id);
      if(item) this.props.changeCheckedPurchaseOrder(item.raw, checked)
  }

  save = (data) => {
    let errors = {};
    if(!data.invNo) {
      errors.invNo = I18n.t('Accounting.ErrorMessages.InvoiceNoIsRequired');
    }
    if(!_.get(data, 'invoiceDetail.supplier')) {
      errors.supplier = I18n.t('Accounting.ErrorMessages.SupplierIsRequired');
    }
    if(!data.issuedDate) {
      errors.issuedDate = I18n.t('Accounting.ErrorMessages.IssuedDateIsRequired');
    }
    if(!data.dueDate) {
      errors.dueDate = I18n.t('Accounting.ErrorMessages.DueDateIsRequired');
    }
    if(_.isEmpty(errors)) {
      this.props.createSupplierInvoice(data);
    } else {
      this.props.changeErrorMessage(errors);
    }
  }

  render() {
    return (
      <Switch>
        <Route path={PATH.ACCOUNTING_PAYABLE_PLAN_SUPPLIER_DOMESTIC_PREVIEW_URL}
          render={() => (
            <PreivewSupplierDomestic />
          )}
        />
        <Route path={PATH.ACCOUNTING_PAYABLE_PLAN_SUPPLIER_DOMESTIC_URL} exact
          render={() =>
            <CreatePlanSupplierDomesticView
              {...this.props}
              save={(data) => this.save(data)}
              changeSelectedCustomerPOs={(id, checked) => this.changeSelectedCustomerPOs(id, checked)}
            />
          }
        />
      </Switch>
    );
  }
}

AccountsPayablePlanSupplier.propTypes = {};

AccountsPayablePlanSupplier.defaultProps = {};

const mapStateToProps = state => ({
  isVATPerMonthChecked: selector.isVATPerMonthChecked(state),

  selectableSuppliers: selector.getSelectableSuppliers(state),
  selectedSupplier: R.pathOr('', ['value'], selector.getSelectedSupplier(state)),
  selectedSupplierDeliveryDeviation: R.pathOr(1, ['deliveryDeviation'], selector.getSelectedSupplier(state)),

  issuedDate: selector.getIssuedDate(state),
  invoiceNo: selector.getInvoiceNo(state),
  dueDate: selector.getDueDate(state),

  selectableCredits: selector.getSelectableCreditDebits(state),
  selectedCredit: R.pathOr('', ['value'], selector.getSelectedCreditDebit(state)),

  invAmount: selector.getAmountINV(state),
  invTotalAmount: selector.getTotalAmountINV(state),
  invTotalAmountSay: selector.getTotalAmountSay(state),

  checkablePurchaseOrders: selector.getSelectablePurchaseOrders(state),
  editablePurchaseOrders: selector.getEditablePurchaseOrders(state),

  selectableConvertCurrencies: selector.getSelectableConvertCurrencies(state),
  selectedConvertCurrency: selector.getConvertCurrency(state),
  convertRate: selector.getConvertRate(state),
  convertAmount: selector.getAmountConvert(state),
  convertTotalAmount: selector.getTotalAmountConvert(state),

  mainVATNo: selector.getMainVATNo(state),
  mainVATDate: selector.getMainVATDate(state),
  mainVATTotalAmount: selector.getMainTotalAmount(state),
  mainVATAmount: selector.getMainVATAmount(state),
  mainVATGrandTotal: selector.getMainGrandTotalVAT(state),
  mainTaxRate: selector.getMainTaxRate(state),
  dataApi: selector.fnGenerateRequestBody(state),
  canPreview: selector.getCanPreview(state),
  success: selector.getSuccess(state),
  errorMessages: selector.getErrorMessages(state)
});

const mapDispatchToProps = (dispatch, ownProps) => ({

  loadData() {
    dispatch(actions.listSuppliers('DOMESTIC'));
  },
  changeFilterPOText(value) {
      dispatch(actions.changeSearchText(value));
  },
  setSupplierType(value) {
    dispatch(actions.changeSupplierType(value))
  },

  resetData() {
    dispatch(actions.resetData());
  },

  changeVATPerMonthChecked(checked) {
    dispatch(actions.changeVATPerMonth(checked));
  },

  changeSelectedSupplier(item) {
    dispatch(actions.changeSelectedSupplier(item ? item.raw : null));
  },

  changeIssuedDate(value) {
    dispatch(actions.changeIssuedDate(value));
  },

  changeInvoiceNo(value) {
    dispatch(actions.changeInvoiceNo(value));
  },

  changeDueDate(value) {
    dispatch(actions.changeDueDate(value));
  },

  changeSelectedCredit(item) {
    dispatch(actions.changeSelectedCredit(item ? item.raw : null));
  },

  changeSelectedConvertCurrency(item) {
    dispatch(actions.changeSelectedConvertCurrency(item ? item.raw : null));
  },

  changeConvertRate(value) {
    dispatch(actions.changeConvertRate(value));
  },

  changeMainVATNo(value) {
    dispatch(actions.changeMainVATNo(value));
  },

  changeMainVATTotalAmount(value) {
    dispatch(actions.changeMainVATTotalAmount(value));
  },

  changeMainVATDate(value) {
    dispatch(actions.changeMainVATDate(value));
  },

  changeMainVATTaxRate(value) {
    dispatch(actions.changeMainVATTaxRate(value));
  },

  changeCheckedPurchaseOrder(item, value) {
    if (item) {
      dispatch(actions.changeCheckedPurchaseOrder(R.path(['_id'], item)), value);
    }
  },

  toggleActivePurchaseOrder(item) {
    if (item) {
      dispatch(actions.toggleActivePurchaseOrder(R.path(['_id'], item)));
    }
  },

  changeSubVATNo(item, value) {
    if (item) {
      dispatch(actions.changeSubVATNo(item._id, value));
    }
  },

  changeSubVATDate(item, value) {
    if (item) {
      dispatch(actions.changeSubVATDate(item._id, value));
    }
  },

  changeSubVATTaxRate(item, value) {
    if (item) {
      dispatch(actions.changeSubTaxRate(item._id, value));
    }
  },

  changeSubVATNote(item, value) {
    if (item) {
      dispatch(actions.changeSubVATNote(item._id, value));
    }
  },

  onOverseaClick() {
    ownProps.router.history.push(PATH.ACCOUNTING_PAYABLE_PLAN_SUPPLIER_OVERSEA_URL)
  },

  onPreviewScreen() {
    dispatch(actions.activeScreenPreview())
    ownProps.router.history.push(PATH.ACCOUNTING_PAYABLE_PLAN_SUPPLIER_DOMESTIC_PREVIEW_URL)
  },

  createSupplierInvoice(data) {
    dispatch(actions.createSupplierInvoice(data))
  },

  changeErrorMessage(errors) {
    dispatch(actions.changeErrorMessage(errors))
  }

});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AccountsPayablePlanSupplier));
