import C from '../../constants/ship-out/createINV';
import shipOutApi from '../../api/shipOutApi'

export const getItemDetailToCreateINV = (id, isEdit) => dispatch => {
    if(id) {
        let array = [];
        array.push(id);
        let query = {data: array}
        shipOutApi.getPklShipDetail(query).then(res => {
            if(res.success) {
                dispatch({type: C.CHANGE_PKL_SHIP_DETAIL, payload: res.data})
                if(!isEdit) {
                    dispatch({type: C.CHANGE_DATASET_DETAIL_OF_CREATE, payload: res.data})
                } else {
                    dispatch({type: C.CHANGE_DATASET_DETAIL, payload: res.data})
                }
            }
        })
    }
}

export const changeCustomerINV = (value) => dispatch => {
    dispatch({type: C.CHANGE_CUSTOMER_INV, payload: value})
}

export const changeInvDate = (value) => dispatch => {
    dispatch({type: C.CHANGE_INV_DATE, payload: value})
}

export const changePrice = (orderId, styleId, cutTicketId, colorId, value) => dispatch => {
    dispatch({type: C.CHANGE_PRICE, payload: {orderId, styleId, cutTicketId, colorId, value}})
}

export const changeHSCode = (orderId, value) => dispatch => {
    dispatch({type: C.CHANGE_HS_CODE, payload: {orderId, value}})
}

export const changeCat = (orderId, value) => dispatch => {
    dispatch({type: C.CHANGE_CAT, payload: {orderId, value}})
}

export const save = (data) => dispatch => {
    if(data) {
        shipOutApi.createINV(data).then(res => {
            if(res.success) {
                dispatch({type: C.CREATE_SUCCES, payload: true})
            }
        })
    }  
}

export const update = (data) => dispatch => {
    if(data) {
        let id = _.cloneDeep(data)._id;
        data = _.omit(data, ['_id'])
        shipOutApi.updateINV(id, data).then(res => {
            if(res.success) {
                dispatch({type: C.CREATE_SUCCES, payload: true})
            }
        })
    }
}

export const resetData = () => dispatch => {
    dispatch({type: C.RESET_DATA})
}

export const activeEditInvScreen = () => dispatch => {
    dispatch({type: C.ACTIVE_SCREEN_EDIT_INV, payload: true})
}

export const activeScreenCreateInv = () => dispatch => {
    dispatch({type: C.ACTIVE_SCREEN_CREATE_INV, payload: true})
}

export const getShipInvDetail = (id) => dispatch => {
    shipOutApi.getShipInvDetail(id).then(res => {
        if(res.success) {
            dispatch({type: C.CHANGE_DATASET_ALL_VALUE, payload: res.data})
        }
    })
}