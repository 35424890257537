const C = 'EDIT_NORMAL_RECEIVABLE_INVOICE';

export default {
    CHANGE_LIST_CUSTOMER: `${C}_CHANGE_LIST_CUSTOMER`,
    CHANGE_SELECTED_CUSTOMER: `${C}_CHANGE_SELECTED_CUSTOMER`,
    CHANGE_LIST_CREDIT_DEBIT_NOTE: `${C}CHANGE_LIST_CREDIT_DEBIT_NOTE`,
    CHANGE_SELECTED_CREDIT_DEBIT: `${C}_CHANGE_SELECTED_CREDIT_DEBIT`,
    CHANGE_VAT_PER_CUSTOMER: `${C}_CHANGE_VAT_PER_CUSTOMER`,
    CHANGE_INVOICE_NO: `${C}_CHANGE_INVOICE_NO`,
    CHANGE_CREADIT_FOR_SEA_FREIGHT: `${C}_CHANGE_CREADIT_FOR_SEA_FREIGHT`,
    CHANGE_PRICE_TYPE_LIST: `${C}_CHANGE_PRICE_TYPE_LIST`,
    CHANGE_SELECTED_PRICE: `${C}_CHANGE_SELECTED_PRICE`,
    CHANGE_ISSUED_DATE: `${C}_CHANGE_ISSUED_DATE`,
    CHANGE_DUE_DATE: `${C}_CHANGE_DUE_DATE`,
    CHANGE_SHIP_MODE: `${C}_CHANGE_SHIP_MODE`,
    CHANGE_TYPE_INVOICE: `${C}_CHANGE_TYPE_INVOICE`,
    CHANGE_LIST_BILL: `${C}_CHANGE_LIST_BILL`,
    CHANGE_SELECTED_BILL: `${C}_CHANGE_SELECTED_BILL`,
    CHANGE_CONVERT_TYPE: `${C}_CHANGE_CONVERT_TYPE`,
    CHANGE_CONVERT_RATE: `${C}_CHANGE_CONVERT_RATE`,
    CHANGE_CONVERT_AMOUNT: `${C}_CHANGE_CONVERT_AMOUNT`,
    CHANGE_MAIN_VAT_NO: `${C}_CHANGE_MAIN_VAT_NO`,
    CHANGE_MAIN_VAT_DATE: `${C}_CHANGE_MAIN_VAT_DATE`,
    CHANGE_MAIN_VAT_TAX_RATE: `${C}_CHANGE_MAIN_VAT_TAX_RATE`,
    CHANGE_MAIN_VAT_NOTE: `${C}_CHANGE_MAIN_VAT_NOTE`,
    CHANGE_MAIN_VAT_TOTAL_AMOUNT: `${C}_CHANGE_MAIN_VAT_TOTAL_AMOUNT`,
    CHANGE_SUB_VAT_NO: `${C}_CHANGE_SUB_VAT_NO`,
    CHANGE_SUB_VAT_DATE: `${C}_CHANGE_SUB_VAT_DATE`,
    CHANGE_SUB_VAT_TAX_RATE: `${C}_CHANGE_SUB_VAT_TAX_RATE`,
    CHANGE_SUB_VAT_TOTAL_AMOUNT: `${C}_CHANGE_SUB_VAT_TOTAL_AMOUNT`,
    CHANGE_SUB_VAT_NOTE: `${C}_CHANGE_SUB_VAT_NOTE`,
    CHANGE_AIR_COST: `${C}_CHANGE_AIR_COST`,
    CHANGE_NOTE_BILL: `${C}_CHANGE_NOTE_BILL`,
    CHANGE_SEARCH_TEXT: `${C}_CHANGE_SEARCH_TEXT`,
    RESET_DATA: `${C}_RESET_DATA`,
    MAP_DATA_ON_BILL: `${C}_MAP_DATA_ON_BILL`,
    CHANGE_UNIT_PRICE: `${C}_CHANGE_UNIT_PRICE`,
    CHANGE_AIR_COST: `${C}_CHANGE_AIR_COST`,
    CHANGE_NOTE_BILL: `${C}_CHANGE_NOTE_BILL`,
    SAVE_SUCCESS: `${C}_SAVE_SUCCESS`,
    RESET_DATA_ALL: `${C}_RESET_DATA_ALL`,
    CHANGE_INVOICE_DETAIL: `${C}_CHANGE_INVOICE_DETAIL`,
    CHANGE_LIST_SELECTED_BILL: `${C}_CHANGE_LIST_SELECTED_BILL`,
    CHANGE_RECEIVE_TYPE: `${C}_CHANGE_RECEIVE_TYPE`,
    CHANGE_RECEIVE_NOTE: `${C}_CHANGE_RECEIVE_NOTE`,
    CHANGE_RECEIVE_AMOUNT: `${C}_CHANGE_RECEIVE_AMOUNT`,
    CHANGE_RECEIVE_FILE: `${C}_CHANGE_RECEIVE_FILE`,
}