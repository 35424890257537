import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import I18n from 'i18n-js';

class YieldLog extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      items: []
    };
  }

  componentWillMount() {
    const {item} = this.props;
    let items = _.get(item, 'auditLog') || [];
    items = this.handleLogs(items);
    this.setState({items: _.reverse(items)});
  }

  close() {
    this.props.closeModal();
  }

  handleLogs(items) {
    let keys = ['yield', 'percent', 'actualYield', 'actualPercent'];
    let logs = [];
    _.forEach(items, (item, i) => {
      let tmp = _.cloneDeep(item);
      if(!i) {
        _.forEach(keys, key => {
          tmp[key] = tmp[key] || 0;
        })
        logs.push(tmp);
        return;
      }
      if(item.percent == items[i-1].percent && item.yield == items[i-1].yield) {
        tmp.percent = null;
        tmp.yield = null;
      } else {
        tmp.percent = tmp.percent || 0;
        tmp.yield = tmp.yield || 0;
      }
      if(item.actualPercent == items[i-1].actualPercent && item.actualYield == items[i-1].actualYield) {
        tmp.actualPercent = null;
        tmp.actualYield = null;
      } else {
        tmp.actualPercent = tmp.actualPercent || 0;
        tmp.actualYield = tmp.actualYield || 0;
      }
      if(item.note && item.note == items[i-1].note) {
        tmp.note = null;
      }
      logs.push(tmp);
    })
    return logs;
  }

  render() {
    let {items} = this.state;
    return (
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <a className="close" onClick={() => this.close()}><span aria-hidden="true">&times;</span></a>
            <h4 className="modal-title" id="myModalLabel">{I18n.t('PageYield.LOGHISTORY')}</h4>
          </div>
          <div className="modal-body pd-0">
            <ul className="list-log-history ">
              <li>

                <div className="bg-white">
                  <table style={{width: '100%'}} className="global-table">
                    <tbody>
                    <tr className="bg-gray">
                      <th className="medium w-100">{I18n.t('PageYield.Date')}</th>
                      <th className="medium w-100">{I18n.t('PageYield.User')}</th>
                      <th className="medium">{I18n.t('PageYield.Material')}</th>
                      <th className="medium center">{I18n.t('PageYield.CustomerYield')}</th>
                      <th className="medium center">%</th>
                      <th className="medium center">{I18n.t('PageYield.ActualYield')}</th>
                      <th className="medium center">%</th>
                    </tr>
                    {items.map((i, idx) => [(
                      <tr key={idx} className="bg-white">
                        <td className="color-gray">{i.date ? moment(i.date).format('MMM DD, YYYY') : ''}</td>
                        <td className="medium"><span>{_.get(i, 'user.fullName', '')}</span></td>
                        <td>{_.get(i, 'material.name', '')}</td>
                        <td className="center">{i.yield || i.yield === 0 ? i.yield : "-"}</td>
                        <td className="center">{i.percent || i.percent === 0 ? i.percent : "-"}</td>
                        <td className="center">{i.actualYield || i.actualYield === 0 ? i.actualYield : "-"}</td>
                        <td className="center">{i.actualPercent || i.actualPercent === 0 ? i.actualPercent : "-"}</td>
                      </tr>
                    ),
                      (i.note && <tr className={i.note == "" ? "display-none" : "bg-white"}>
                        <td colSpan="5"><span className="label label-primary mg-right-5">Note</span>{i.note || ''}</td>
                      </tr>)
                    ])}
                    </tbody>
                  </table>
                </div>

              </li>

            </ul>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    authState: state.authState
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

YieldLog.propTypes = {
  authState: PropTypes.object.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(YieldLog);
