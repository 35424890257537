import { signInDAL, 
  getCurrentUserDAL, 
  signOutDAL, 
  profileEditDAL, 
  uploadFile, 
  changePasswordDAL, 
  forgotPasswordDAL, 
  forgotPasswordConfirmDAL } from '../api/userApi';
import { getAccessToken, setCookie } from '../functions/helpers';

class authServices {

  constructor() {
  }

  signIn({ email, password, rememberMe }) {
    return signInDAL({ email, password, rememberMe }).then(res => {
      if (res && !res.error) {
        if(rememberMe) {
          setCookie('x-access-token', res.data.accessToken, 7);
        } else {
          setCookie('x-access-token', res.data.accessToken, 1);
        }
      }
      return res;
    });
  }
  // Get current user
  getUser() {
    return getCurrentUserDAL().then((res) => {
      return res;
    });
  }

  profileEdit(id, data) {
    var token = getAccessToken()
    if (data.file) {
      let promises = ([data.file]).map(i => {
        return i.file ? uploadFile(i.file).then(res => _.get(res || {}, 'data.filePath', '')) : Promise.resolve(i);
      });
      return Promise.all(promises)
        .then(images => {
          data.avatar = images.length > 0 ? images[0] : '';

          return profileEditDAL(id, _.omit(data, ['file']), token);
        })
        .catch(error => {
          throw error;
        });
    } else {
      return profileEditDAL(id, data, token);
    }
  }


  signOut() {
    // setCookie('userId-ad', '', -1);
    setCookie('x-access-token', '', -1);
  }

  changePassword(body) {
    return changePasswordDAL(body);
  }

  forgotPassword(body) {
    return forgotPasswordDAL(body);
  }

  forgotPasswordConfirm(body) {
    return forgotPasswordConfirmDAL(body);
  }
}

export default new authServices();
