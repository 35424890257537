import Base from './base';

class Common extends Base {

	constructor() {
		super();
		this.getDAL = this.getDAL.bind(this);
	}

	getDAL(url) {
		return super.execute(url, this.ApiMethod.get, {});
	}
}

export default new Common();
