import C from '../../constants/crm/addSupplier';
import * as types from '../../constants/actionTypes';
import {showLoading, hideLoading} from '../commonUseActions';
import supplierServices from '../../services/supplierServices';
import materialServices from '../../services/materialServices';


export const getListMaterial = (options) => dispatch => {
    // showLoading(types, dispatch);
    materialServices.getListMaterial(options).then((res) => {
        // hideLoading(types, dispatch);
        if(res.success) {
            dispatch({
                type: C.GET_LIST_MATERIAL,
                payload: res.data.items || []
            });
            const [item] = res.data.items;
        }
    });
}

export const changeMaterial = (data) => dispatch => {
    dispatch({ type: C.CHANGE_MATERIAL, payload: data });
}

export const changeSelectedMaterials = (data) => dispatch => {
    dispatch({ type: C.CHANGE_PROPERTY_MATERIAL, payload: data });
    // if(data.property == 'material') {
    //     dispatch({ type: C.CHANGE_SIZE, payload: data });
    //     dispatch({ type: C.CHANGE_COLOR, payload: data });
    // }
}

export const addSelectedMaterial = (data) => dispatch => {
    dispatch({ type: C.ADD_MATERIAL, payload: data });
}

export const removeSelectedMaterial = (index) => dispatch => {
    dispatch({ type: C.REMOVE_MATERIAL, payload: index });
}

export const changeSupplier = (data) => dispatch => {
    dispatch({type: C.CHANGE_SUPPLIER, payload: data})
}

export const changeSize = (data) => dispatch => {
    dispatch({ type: C.CHANGE_SIZE, payload: data });
}

export const changeColor = (data) => dispatch => {
    dispatch({ type: C.CHANGE_COLOR, payload: data });
}

export const changePrice = (data) => dispatch => {
    dispatch({ type: C.CHANGE_PRICE, payload: data });
}

export const changeName = (data) => dispatch => {
    dispatch({type: C.CHANGE_NAME, payload: data})
}

export const changeShortName = (data) => dispatch => {
    dispatch({type: C.CHANGE_SHORT_NAME, payload: data})
}

export const changeBankAccountNo = (data) => dispatch => {
    dispatch({type: C.CHANGE_BANK_ACCOUNT_NO, payload: data})
}

export const changeBankBranch = (data) => dispatch => {
    dispatch({type: C.CHANGE_BANK_BRANCH, payload: data})
}

export const changeCompanyName = (data) => dispatch => {
    dispatch({type: C.CHANGE_COMPANY_NAME, payload: data})
}

export const changeCompanyAddress = (data) => dispatch => {
    dispatch({type: C.CHANGE_COMPANY_ADDRESS, payload: data})
}

export const changeType = (data) => dispatch => {
    dispatch({type: C.CHANGE_TYPE, payload: data})
}

export const changePhoneNumber = (data) => dispatch => {
    dispatch({type: C.CHANGE_PHONE_NUMBER, payload: data})
}

export const changePaymentTerm = (data) => dispatch => {
    dispatch({type: C.CHANGE_PAYMENT_TERM, payload: data})
}

export const changeDeliveryDeviation = (data) => dispatch => {
    dispatch({type: C.CHANGE_DELIVERY_DEVIATION, payload: data})
}

export const changeFax = (data) => dispatch => {
    dispatch({type: C.CHANGE_FAX, payload: data})
}

export const changeCurrency = (data) => dispatch => {
    dispatch({type: C.CHANGE_CURRENCY, payload: data})
}

export const changeEmail = (data) => dispatch => {
    dispatch({type: C.CHANGE_EMAIL, payload: data})
}

export const changeEmptyData = (data) => dispatch => {
    dispatch({type: C.CHANGE_EMPTY_DATA, payload: data})
}

export const save = (data) => dispatch => {
    if(data) {
        // showLoading(types, dispatch);
        return supplierServices.addSupplier(data).then(res => {
            // hideLoading(types, dispatch);
            if(res.success) {
                dispatch({type: C.SAVE, payload: res.success});
                // dispatch({type: types.APP.ADD_PAGE_CURRENT, data: null})
            }
            return res;
        }, (err) => {
            return err;
            // hideLoading(types, dispatch);
        })
    }
}

export const edit = (id, data) => dispatch => {
    if(data) {
        return supplierServices.editSupplier(id, data).then(res => {
            // hideLoading(types, dispatch);
            if(res.success) {
                dispatch({type: C.SAVE, payload: res.success});
            }
            return res;
        }, (err) => {
            return err;
            // hideLoading(types, dispatch);
        })
    }
}