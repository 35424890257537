import React from 'react';
import PropTypes from 'prop-types';
import TableList from "../../datatables/TableList";
import I18n from "i18n-js";
import _ from 'lodash';
import moment from "moment";
let DateTimeField = require('react-datetime');
import {toastr} from 'react-redux-toastr'

export default class PanelTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      headers: [
        {}
      ],
      ihDate: "",
      isClick: false,
      activeTab: true,
    };
  }

  onChange(property, value) {
    this.setState({ihDate: value});
  }

  save() {
    if (this.props.onSaveIHDate) {
      const {cutTicketData} = this.props;
      if (!this.state.ihDate || (moment(this.state.ihDate).format("DD MM YY") === moment(_.get(cutTicketData, "ihDate", new Date())).format("DD MM YY"))) {
        toastr.info("Note!", "Please change IH Date");
        return;
      }
      const ihDate = _.get(cutTicketData, "ihDate", "");
      this.props.onSaveIHDate({
        _id: _.get(cutTicketData, "_id", ""),
        ihDate: this.state.ihDate,
        ihDates: ihDate ? [ihDate, ..._.get(cutTicketData, "ihDates", [])] : []
      });
    }
  }

  onRotatePanelArrow() {
    this.setState({activeTab: !this.state.activeTab})
  }

  renderIHDate(cutTicketData) {
    const {showEdit} = this.props;
    if (!cutTicketData || !cutTicketData.ihDate || !cutTicketData.ihDates) {
      return null;
    }
    return (
      <div className="pd-5 bg-white box-shadow">
        <div className="style inline-block">
          <ul className="content-main-header-filter-style">
            <li className="title">
              {I18n.t("PageProduction.IHDate")}
            </li>
            <li className="ihDate-progress w-200 position-relative">
              <DateTimeField
                timeFormat={false} closeOnSelect dateFormat="MMM DD, YYYY"
                value={moment(_.get(cutTicketData, "ihDate", ""))}
                onChange={date => this.onChange('ihDate', date.format())}/>
            </li>
          </ul>
          <div className="audit_log">
            <div className="dropdown dropdown-style">
              <button className="btn btn-secondary dropdown-toggle color-link text-decoration" type="button"
                      id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <span>{I18n.t("PageProduction.History")}</span>
              </button>
              <div className="dropdown-menu box-shadow">
                <ul className="pd-0">
                  {_.get(cutTicketData, "ihDates", []).map((ihDate, index) => (
                    <li key={index}>{moment(ihDate).format("MMMM DD, YYYY")}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>

        {(showEdit != false) && (<div className="float-right add-filter-top">
            <span className="cursor-pointer" onClick={() => this.save()}
              data-toggle="modal"
              data-target="#modal-add-order">
              <i className="fa fa-floppy-o" aria-hidden="true"></i>{I18n.t("PageProduction.SAVE")}
            </span>
        </div>)}
      </div>
    );
  }

  renderPanelActions(){
    if(this.props.renderPanelActions) return this.props.renderPanelActions();
    return null;
  }

  render() {
    const {tabId, contentId, onUpdateProgress, headers, data, actions, cutTicketData, contractor, isRenderIHDate, showEdit} = this.props;
    const {activeTab} = this.state;
    return (
      <div className="panel panel-default">
        <div className="panel-heading" id={tabId}>
          <div className="panel-title">
            <a role="button"
               data-toggle="collapse"
               data-parent="#tab_production_view"
               //href={"#" + contentId}
               aria-expanded="true"
               aria-controls="collapseOne"
               onClick={() => this.onRotatePanelArrow("caret" + (_.get(cutTicketData,"_id","")))}
            >
              <i id={"caret" + (_.get(cutTicketData,"_id",""))} className={"fa " + (activeTab ? "fa-caret-down" : "fa-caret-right")}
                 aria-hidden="true"></i>
            </a>
            <ul>
              <li>
                <div className="text">
                  <span className="left">{I18n.t("PageProduction.CutTicket")}</span>
                  <span className="right size-15"><span
                    className="medium">{_.get(cutTicketData, "cutTicket", "")}</span></span>
                </div>
                {contractor &&
                <div className="text">
                  <span className="left">{I18n.t("PageProduction.Contractor")}</span>
                  <span className="right size-15"><span className="medium">{contractor}</span></span>
                </div>
                }

              </li>

              {(showEdit != false) && this.renderPanelActions()}

            </ul>
          </div>
        </div>
        <div className={(activeTab ? " show" : " hide")}>
          <div className="panel-body pd-0">
            {isRenderIHDate && this.renderIHDate(cutTicketData)}

            <TableList
              items={data || []}
              columns={headers || []}
              actions={actions || []}
              options={{clsTbl: 'global-table box-shadow-none'}}
              parentData={cutTicketData}
              widthAction="w-90"
            />
          </div>
        </div>
      </div>
    );
  }
}

PanelTable.propTypes = {
  parentData: PropTypes.object,
  tabId: PropTypes.string,
  contentId: PropTypes.string,
  onUpdateProgress: PropTypes.func,
  onSaveIHDate: PropTypes.func,
  setActivePanel: PropTypes.func,
  renderPanelActions: PropTypes.func,
  cutTicketData: PropTypes.object,
  contractor: PropTypes.string,
  data: PropTypes.array,
  actions: PropTypes.array,
  headers: PropTypes.array,
  active: PropTypes.bool,
  isRenderIHDate: PropTypes.bool,
};

