
import styleServices from '../../../services/styleServices';
import * as R from 'ramda';
import C from '../../../constants/customer-po/current/deleteStyle';
import { listStyles } from './showOrderDetail';

export const showModal = style => dispatch => {
  dispatch({ type: C.SHOW_MODAL, payload: style });
}

export const hideModal = () => dispatch => {
  dispatch({ type: C.RESET_DATA });
}

export const deleteStyle = style => dispatch => {
  dispatch({ type: C.SUBMIT });
  const id = R.path(['_id'], style);
  styleServices.deleteStyle(id).then((res) => {
    dispatch({ type: C.CANCEL_SUBMIT });
    dispatch({ type: C.RESET_DATA });
    dispatch(listStyles(R.path(['orderId'], style)));
  }).catch(error => {
    dispatch({ type: C.CANCEL_SUBMIT });
    console.error(error);
  });
};
