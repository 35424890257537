import React, { Component } from 'react';
import PreviewDeclarationView from '../../../components/accounting/payable/PreviewDeclaration';
import I18n from 'i18n-js';
import { connect } from 'react-redux';
import * as selector from '../../../../selectors/accounting/payable/createInvoiceImpExpDeclarations';
import * as PATH from '../../../../constants/routeConstants'; 
import { withRouter } from 'react-router';

class PreviewDeclaration extends Component {

    constructor (props) {
        super(props)
        this.state = {
            listIds: this.props.listIds,
            items: [],
            pageNumber: 1,
            pageSize: 10,
            columns: [
                { name: I18n.t('PageAccounting.DeclarationNo'), value: 'declarationNo', type: 'text' },
                { name: I18n.t('PageAccounting.DeclarationDate'), value: 'declarationDate', type: 'date', formatType: "MMM DD, YYYY" },
                { name: I18n.t('PageAccounting.BorderGate'), value: 'borderGate', type: 'text' },
                { name: I18n.t('PageAccounting.QTYPKG'), value: 'qtyPKG', type: 'text' },
                { name: I18n.t('PageAccounting.ImportExportFees'), value: 'crsPaperWork', type: 'number' },
                { name: I18n.t('PageAccounting.TransportationFees'), value: 'truckingIn', type: 'number' },
                { name: I18n.t('PageAccounting.Expense'), value: 'expense', type: 'number' },
                { name: I18n.t('PageAccounting.LocalCharge'), value: 'localCharge', type: 'number' },
                { name: I18n.t('PageAccounting.Advance'), value: 'advance', options: { clsTd: 'color-red' }, type: 'number' },
                { name: I18n.t('PageAccounting.Remaining'), value: 'remaining', options: { clsTd: 'color-green' }, type: 'number' },
               
            ],
            actions: []
        }
    }

    componentWillReceiveProps(newProps) {
        const {activeScreen} = newProps;
        if(!activeScreen) {
            newProps.router.history.push(PATH.ACCOUNTING_PAYABLE_PLAN_IMPORTEXPORT_DECLARATION_URL);
        }
    }    

    render() {
        const { columns } = this.state
        return (
            <PreviewDeclarationView columns = {columns} {...this.props}/>
        );
    }
}

const mapStateToProps = state => ({
    listDeclaration: selector.getDetailedDeclarations(state),
    name: selector.getName(state),
    invoiceNo: selector.getInvoiceNo(state),
    totalAmountPreview: selector.getTotalAmountPreview(state),
    totalAdvance: selector.getTotalAdvance(state),
    totalRemaining: selector.getTotalRemaining(state),
    activeScreen: selector.getActiveScreenStatus(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({

})

export default connect(mapStateToProps, mapDispatchToProps) (withRouter(PreviewDeclaration));