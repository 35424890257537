import { createSelector } from 'reselect';
import formatCurrency from "format-currency";
import moment from "moment";
import _ from 'lodash';

/**
 * Get selectable filters
 */
export const getSelectableFilters = () => {
    let arrData = [
        {field: 'invRequest', name: 'PageAccounting.INVRequest', type: 'text'},
        {field: 'invRef', name: 'PageAccounting.INVRef', type: 'text'},
        {field: 'invNo', name: 'PageAccounting.INVNo', type: 'text'},
        {field: 'customerPO', name: 'PageAccounting.CustomerPO', type: 'text'},
        {field: 'dueDate', name: 'PageAccounting.DueDate', type: 'dateTo'},
        {field: 'payee', name: 'PageAccounting.Payee', type: 'text'},
        {field: 'type', name: 'PageAccounting.Type', type: 'text'},
    ]
    return arrData;
}

/**
 * Get status of whether invoices data is fetching from api
 */
export const isFetchingInvoices = ({ showPayableHistoryInvoiceList }) => showPayableHistoryInvoiceList.fetchingInvoiceList;

/**
 * Get list of history invoice
 * The results will contains these information
 * [{
 *    _id, invRequest, invoiceRef, invoiceNo, issuedDate, customerPO, dueDate, finishedDate, payee, type, amount,
 * }]
 */

export const getInvoicesHistory = createSelector([
    ({showPayableHistoryInvoiceList}) => showPayableHistoryInvoiceList.historyInvoices,
], (payableInvoices) => {
    let arrData = [];
    payableInvoices.map(it => {
        let obj = {};
        const typeInvoice = _.get(it, 'invoiceDetail.typeInvoice', '');
        switch(typeInvoice) {
            case 'overseaInvoice':
                obj.type = 'Supplier'
                break;
            case 'domesticInvoice':
                obj.type = 'Supplier'
                break;
            case 'contractorInvoice':
                obj.type = 'Contractor'
                break;
            case 'containerInvoice':
                obj.type = 'Container Transporter'
                break;
            case 'declarationInvoice':
                obj.type = 'Declaration'
                break;
            case 'usDeliveryInvoice':
                obj.type = 'US Delivery'
                break;
            default:
                break;
        }
        obj._id = it._id;
        obj.invRequest = it.invRequest;
        obj.invRef = it.invRef;
        obj.invNo = it.invNo;
        obj.customerPOs = it.customerPOs ? it.customerPOs : [];
        obj.customerPO = (it.customerPOs &&  it.customerPOs.length > 0) ? it.customerPOs[0] : '-';
        obj.issuedDate = converData('issuedDate', it);
        obj.finishedDate = converData('finishedDate', it);
        obj.dueDate = converData('dueDate', it);
        obj.payee = it.payee;
        obj.amount = converData('amount', it);
        obj.typeInvoice = it.typeInvoice;
        obj.type = it.type;
        arrData.push(obj);
    });
    return arrData;
});



function converData(key, obj) {
    switch (key) {
        case 'amount':
            return formatCurrency(obj['amount']);
        case 'paid':
            return formatCurrency(obj['paid']);
        case 'remaining':
            return formatCurrency(obj['amount'] - obj['paid']);  // Convert to number
        case 'dueDate':
            return obj['dueDate'] ? moment(obj['dueDate']).format('MMM DD, YYYY') : '-';
        case 'issuedDate':
            return obj['issuedDate'] ? moment(obj['issuedDate']).format('MMM DD, YYYY') : '-';
        case 'finishedDate':
            return obj['finishedDate'] ? moment(obj['finishedDate']).format('MMM DD, YYYY') : '-';
        default:
            return obj[key];
    }
}


export const getSelectedFilter = createSelector(
    [getSelectableFilters, ({showPayableHistoryInvoiceList}) => showPayableHistoryInvoiceList.selectedFilter],
    (filters, selectedFilter) => {
        if (!selectedFilter) return filters[0].field;
        return selectedFilter;
    }
)

/**
 * Get from date field value
 */
export const getFromDate = ({showPayableHistoryInvoiceList}) => {
    return showPayableHistoryInvoiceList.fromDate;
}

/**
 * Get to date field value
 */
export const getToDate = ({showPayableHistoryInvoiceList}) => {
    return showPayableHistoryInvoiceList.toDate;
}

/**
 * Get paid detail
 * Format: $ ###,###.##
 */
export const getPaidDetail = ({showPayableHistoryInvoiceList}) => {
    let arr = [];
    showPayableHistoryInvoiceList.paidDetail.map(it =>{
        let obj = {};
        obj.date = it.date;
        obj.received = `$${it.paid}`;
        obj.attachment = it.attachs;
        arr.push(obj);
    });
    return arr;
};

/**
 * change currency
 */
export const getCurrency = ({showPayableHistoryInvoiceList}) => showPayableHistoryInvoiceList.changeCurrency;


export const getCurrencyTypeUSD = createSelector(
    [
        getCurrency
    ], (currency) => {
        return currency && currency === "USD" ? '$' : '';
    }
)

export const getCurrencyTypeVND = createSelector(
    [
        getCurrency
    ], (currency) => {
        return currency && currency === "VND" ? ' VND' : '';
    }
)

export const getTotalPages = ({ showPayableHistoryInvoiceList }) => {
  return showPayableHistoryInvoiceList.totalPages;
}

/**
 * Get total amount
 * Format: $ ###,###.##
 */
export const getTotalAmount = ({showPayableHistoryInvoiceList}) => showPayableHistoryInvoiceList.totalAmount;

/**
 * Get search text field value from reducer
 */
export const getSearchValue = ({showPayableHistoryInvoiceList}) => showPayableHistoryInvoiceList.searchValue;

export const getCurrentPage = ({ showPayableHistoryInvoiceList }) => {
  return showPayableHistoryInvoiceList.currentPage;
}

export const getParamsRequest = createSelector([
    getSelectedFilter,
    getSearchValue,
    getCurrency,
    getCurrentPage
], (type,
    value,
    currency,
    page
) => {
    let params = {};
    params.type = type;
    // params.value = JSON.stringify(value);
    if(params.type == 'dueDate') {
        params.from = _.get(value, 'from', '');
        params.to = _.get(value, 'to', '');
    } else {
        params.value = _.get(value, 'value', '')
    }
    params.isHistory = true;
    params.currency = currency;
    params.pageNumber = page ||  1;
    params.pageSize = 10;
    params.sort = JSON.stringify({updatedAt: -1});
    return params;
});

export const getLoading = ({showPayableHistoryInvoiceList}) => showPayableHistoryInvoiceList.loading;

export const getPageTotal = ({showPayableHistoryInvoiceList}) => showPayableHistoryInvoiceList.pageTotal || 1;

export const getPageCurrent = ({showPayableHistoryInvoiceList}) => showPayableHistoryInvoiceList.pageCurrent || 1;