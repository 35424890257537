import C from '../../constants/purchase/purchasePlans';
import purchasePlanApi from '../../api/purchasePlanApi';
import purchaseOrderApi from '../../api/purchaseOrderApi';
import orderApi from '../../api/orderApi';
import { PAGINATION } from '../../constants/constants';

export const getListOrder = (params) => dispatch => {
    dispatch({type: C.LOAD_MORE });
    dispatch({type: C.LOADING });
    orderApi.getListOrderOnPurchaseDAL(params).then(res => {
        if(res.success && res.data.items.length > 0) {
            dispatch({type: C.CHANGE_LIST_ORDER, payload: res.data.items})
            dispatch(changeSeletedOrder(res.data.items[0]))
            if (res.data.items.length < PAGINATION.PAGE_SIZE || res.data.items.length == 0) { 
                dispatch({type: C.CANCEL_LOAD_MORE });
            }
        } else {
            dispatch({type: C.CANCEL_LOAD_MORE });
            dispatch({type: C.CHANGE_LIST_ORDER, payload: []})
        }
        dispatch({type: C.CANCEL_LOADING });
    })
}

export const getListOrderMore = (params) => dispatch => {
    dispatch({type: C.LOAD_MORE });
    dispatch({type: C.LOADING });
    orderApi.getListOrderOnPurchaseDAL(params).then(res => {
        if(res.success && res.data.items.length > 0) {
            dispatch({type: C.CHANGE_LIST_ORDER_MORE, payload: res.data.items})
            dispatch(changeSeletedOrder(res.data.items[0]))
            if (res.data.items.length < PAGINATION.PAGE_SIZE || res.data.items.length == 0) { 
                dispatch({type: C.CANCEL_LOAD_MORE });
            }
        } else {
            dispatch({type: C.CANCEL_LOAD_MORE });
        }
        dispatch({type: C.CANCEL_LOADING });
    })
}

export const changeSeletedOrder = (order) => dispatch => {
    dispatch({type: C.CHANGE_SELECTED_ORDER, payload: order})
    dispatch(changeListStyle(order.styleOrders))
}

export const changeListStyle = styles => dispatch => {
    dispatch({type: C.CHANGE_LIST_STYLE, payload: styles})
    dispatch(changeSelectedStyle(styles[0]))
}

export const changeSelectedStyle = (style) => dispatch => {
    dispatch({type: C.CHANGE_SELECTED_STYLE, payload: style})
    if(style) {
        dispatch(getListMaterial(style._id))
    } else {
        dispatch({type: C.CHANGE_LIST_MATERIAL, payload: []})
    }
}

export const getListMaterial = (id) => dispatch => {
    const op = {
        type: 'ORIGINAL',
        populate: 'materials.material',
        additional: false
    }
    purchasePlanApi.getPurchasePlanDAL(id, op).then(res => {
        if(res.success) {
            dispatch({type: C.CHANGE_LIST_MATERIAL, payload: res.data.materials})
        }
    })
}

export const changeCheckedTrim = (checked) => dispatch => {
    dispatch({type: C.CHANGE_CHECKED_TRIM, payload: checked})
}

export const changeCheckedFabric = (checked) => dispatch => {
    dispatch({type: C.CHANGE_CHECKED_FABRIC, payload: checked})
}

export const changeMaterialValue = (checkedFabric, checkedTrim, id, idx, key, value) => dispatch => {
    dispatch({type: C.CHANGE_MATERIAL_VALUE, payload: {checkedFabric, checkedTrim, id, idx, key, value}})
}

export const createUseStock = (data) => {
    return purchaseOrderApi.addItemDAL(data);
}

export const resetData = () => dispatch => {
    dispatch({type: C.RESET_DATA})
}

export const selectedListMaterials = (materials) => dispatch => {
    dispatch({type: C.CHANGE_SELECTED_LIST_MATERIALS, payload: materials})
}

export const changeMaterialValueInPlaceOrder = (index, key, value) => dispatch => {
    dispatch({type: C.CHANGE_MATERIAL_IN_PLACE_ORDER_VALUE, payload: {index, key, value}})
}

export const changeRemark = (value) => dispatch => {
    dispatch({type: C.CHANGE_REMARK, payload: value});
}

export const createPurchase = (data) => dispatch => {
    purchaseOrderApi.addItemDAL(data).then(res => {
        if(res.success) {
            dispatch({type: C.CHANGE_REMARK, payload: ''});
            dispatch({type: C.CREATE_PURCHASE_ORDER_SUCCESS, payload: true})
        }
    })
}

export const resetListOrder = () => dispatch => {
    dispatch({type: C.RESET_LIST_ORDER})
}

export const resetSuccessStatus = () => dispatch => {
    dispatch({type: C.RESET_SUCCESS_STATUS})
}

export const changePageNumber = (number) => dispatch => {
    dispatch({ type: C.CHANGE_PAGE_NUMBER, payload: number });
}

export const cancelChangePageNumber = () => dispatch => {
    dispatch({ type: C.RESET_CHANGE_PAGE_NUMBER });
}

export const changeFilterText = (text) => dispatch => {
    dispatch({ type: C.CHANGE_FILTER_TEXT, payload: text });
}

export const changeSearch = () => dispatch => {
    dispatch({ type: C.IS_SEARCH});
}
  
export const cancelChangeSearch = () => dispatch => {
    dispatch({ type: C.CANCEL_IS_SEARCH });
}