import React, {Component}           from 'react';
import PropTypes                    from 'prop-types';
import { connect }                  from 'react-redux';
import _                            from 'lodash';

import EstimateForm                 from './EstimateForm';
import FabricForm                   from './FabricForm';
import {sourceTypes, materialTypes} from '../../../constants/EnumTypes';

import icAddIcon from '../../../stylesheets/images/ic-add.svg';

class YieldFabric extends Component {
  constructor (props, context) {
    super(props, context);

    this.state = {
      wrap: this.props.wrap,
      est: this.setEst(this.props.colors || [])
    };
    this.onChange = this.onChange.bind(this);
  }

  addItem () {
    let {wrap} = this.state;
    let data = _.get(wrap, 'data', []);
    data.unshift({info: {type: sourceTypes.SOURCED}})
    this.setState({wrap: {data: data}});
  }

  onDelete (idx) {
    let {wrap} = this.state;
    let newData = wrap ? [...wrap.data] : [];
    newData.splice(idx, 1);


    this.setState({wrap: {data: newData, estimate: wrap.estimate}});
    if(this.props.onDelete) {
      this.props.onDelete(idx);
    }

  }

  onChange (idx1, idx2, est, value) {
    let {wrap} = this.state;
    let {fieldName} = this.props;

    est[idx2].selectColor = value;
    let estimate = [];
    est.forEach(c => {
      estimate.push({[fieldName]: c.selectColor, color: c.color._id});
    });

    wrap.data[idx1].estimate = estimate;
    this.setState({wrap});

    this.updateToParent(_.cloneDeep(_.get(wrap, 'data', [{}])));
  }

  updateInfo (info, idx) {
    let {wrap} = this.state;
    wrap = _.set(wrap || {}, `data[${idx}].info`, info);
    this.setState({wrap});

    this.updateToParent(_.cloneDeep(_.get(wrap, 'data', [{}])));
  }

  updateToParent (data) {
    const {setProperty, name} = this.props;
    setProperty && setProperty((name !== 'SELF') ? data : data[0]);
  }

  setEst(colors) {
    return colors.map(vl => ({
      color: vl,
      selectColor: ""
    }))
  }

  renderAddFabric() {

    const {isBlocked, showCreate, showEdit, showDelete, isSuperAdmin} = this.props;
    if(!isSuperAdmin) {
      if(isBlocked) {
        if(!showDelete) {
          return null;
        }
      } else if(!showCreate && !showEdit && !showDelete) {
        return null;
      }
    }

    return (
      <span className="cursor-pointer" onClick={() => this.addItem()}>
        <img src={icAddIcon} /> Add Fabric</span>
    )
  }

  render () {
    let {materials, colorsGlobal, name, thName, fieldName, isRequired, errors} = this.props;
    let {wrap, est} = this.state;
    return (
      <div className="md content-main-info-edit-yield">
        <div className="md content-main-info-edit-yield-header">
          <h2>{name}</h2>
          {name !== "SELF" && this.renderAddFabric() }
        </div>

        {(wrap.data || []).map((data, idx) => {
          let selectedColors = []
          if(data.info && data.info.material) {
            let mat = materials.find(vl => vl._id.toString() == data.info.material)
            if(mat)
            selectedColors = mat.data.color
          }
          const itemError = _.isArray(errors) ? errors[idx] : errors;
          return (
            <div key={idx} className="md content-main-info-edit-yield-wrap">
              <EstimateForm
                onDelete={()=>this.onDelete(idx)}
                data={data.info || {}}
                errors={itemError}
                idx={idx}
                updateInfo={(info) => this.updateInfo(info, idx)}
                materials={(materials || []).filter(m => m.type === materialTypes.FABRIC)}
                type={materialTypes.FABRIC}
                name={name}
                isRequired={isRequired || false}/> 
              <FabricForm {...{data, idx, est, colorsGlobal, onChange: this.onChange, thName, fieldName, selectedColors}} errors={itemError} />
            </div>
          );
        })}
      </div>
    );
  }
}

function mapStateToProps (state, ownProps) {
  return {
    authState: state.authState
  };
}

function mapDispatchToProps (dispatch) {
  return {};
}

YieldFabric.propTypes = {
  authState: PropTypes.object.isRequired,
  isBlocked : PropTypes.bool,
  showCreate : PropTypes.bool,
  showEdit : PropTypes.bool,
  showDelete : PropTypes.bool,
  isSuperAdmin: PropTypes.bool,
  onDelete: PropTypes.func,
  type: PropTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps)(YieldFabric);
