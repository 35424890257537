import React, { Component } from 'react';
import TableList from '../../../../components/common/datatables/TableList';
import { Store } from '../../../CssHandleModule/functions'
import I18n from 'i18n-js';
import { connect } from 'react-redux';
import { changePackingNumber, changeBarNumber, addBar, removeBar, changePackedNumber, changeCheckedPacking } from '../../../../actions/ship-out/createPklShipActions';
import * as selector from '../../../../selectors/ship-out/createPklShip';

import icXIcon from '../../../../stylesheets/images/ic-x.svg';
import addNewIcon from '../../../../stylesheets/images/add-new.svg';

const store = new Store();
store.setStyles({
    orderNumbers: [1, 2],
    fonts: [
        { order: 1, size: '15px' }
    ],
    aligns: [
        { order: 1, margin: { right: '10px' } },
        { order: 2, margin: { left: '10px' } }
    ]
});

class ColorTableAddView extends Component {

    constructor(props) {
        super(props)
    }

    changePackingNumber = (orderId, styleId, cutId, colorId, sizeName, value) => {
        this.props.changePackingNumber(orderId, styleId, cutId, colorId, sizeName, value)
    }

    changeBarNumber = (orderId, styleId, cutId, colorId, value) => {
        this.props.changeBarNumber(orderId, styleId, cutId, colorId, value)
    }

    addBar = (orderId, styleId, cutId, colorId) => {
        this.props.addbar(orderId, styleId, cutId, colorId)
    }

    removeBar = (orderId, styleId, cutId, colorId, value) => {
        this.props.removeBar(orderId, styleId, cutId, colorId, value)
    }

    //Carton
    changePackedNumber = (orderId, styleId, cutId, colorId, group, value) => {
        this.props.changePackedNumber(orderId, styleId, cutId, colorId, group, value)
    }

    changeCheckedPacking = (orderId, styleId, cutId, colorId, group, value) => {
        this.props.changeCheckedPacking(orderId, styleId, cutId, colorId, group, value)
    }

    render() {
        const { data, packingType, getSizes, orderId, styleId, cutId, getPacking, getBarNumber, getSizeQtyGOHH, getListBarDetail, getBarTotal, getRemaining, getQtyColorConst, getCarton } = this.props;
        const sizes = getSizes(orderId, styleId, cutId, data.color._id)
        // GOHH
        const barNumber = getBarNumber(orderId, styleId, cutId, data.color._id)
        const listBarDetail = getListBarDetail(orderId, styleId, cutId, data.color._id)
        //Carton
        const cartons = packingType == 'Carton' && getCarton(orderId, styleId, cutId, data.color._id);
        const colorPacking = getQtyColorConst(orderId, styleId, cutId, data.color._id)
        return (
            <div>
                {packingType == 'GOHH' && sizes &&
                    <div>
                        <div className="clear-both mg-top-5">
                            <div className="display-flex bg-white">
                                <div className="pd-10 display-flex align-items-center pd-20 w-180">
                                    <h3 className="center mg-auto size-12">
                                        <span className="fw-normal">{I18n.t("PageShiping.Color")}</span><br /><br />
                                        <strong className="size-13">{data.color.name}</strong><br /><br />
                                    </h3>
                                </div>
                                <div className="pd-10 border-left-dashed">
                                    <table className="global-table tr-height-30 box-shadow-none">
                                        <tbody>
                                            <tr style={{ borderBottom: 'unset' }} className="bg-none">
                                                <td className="fw-normal">{I18n.t("PageShiping.QTY")}</td>
                                                {sizes.map((size, sId) => {
                                                    let qty = getSizeQtyGOHH(orderId, styleId, cutId, data.color._id, size)
                                                    return <td key={sId} className='center'> {size} : {qty}</td>
                                                })}
                                            </tr>
                                            <tr className="bg-none">
                                                <td className="fw-normal">{I18n.t("PageShiping.Remaining")}</td>
                                                {sizes.map((size, sId) => {
                                                    let remaining = getRemaining(orderId, styleId, cutId, data.color._id, size)
                                                    return <td key={sId} className='center color-green'> {remaining || 0}</td>
                                                })}
                                            </tr>

                                            <tr className="bg-none">
                                                <td className="fw-normal">{I18n.t("PageShiping.Packing")}</td>
                                                {sizes.map((size, sId) => {
                                                    const packing = getPacking(orderId, styleId, cutId, data.color._id, size)
                                                    return (
                                                        <td key={sId} className="center w-100">
                                                            <input type="number" min="0" className="form-control center pd-right-0 mr-10"
                                                                value={packing || 0}
                                                                onChange={(e) => this.changePackingNumber(orderId, styleId, cutId, data.color._id, size, e.target.value)}
                                                            />
                                                        </td>
                                                    )
                                                })}

                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div className="bg-white border-top-dashed display-flex">
                            <div className="pd-10 display-flex align-items-center w-180">
                                <h3 className="center mg-auto size-12">
                                    <span className="fw-normal"><h3>{I18n.t("PageShiping.Bar")}</h3></span><br /><br />
                                </h3>
                            </div>
                            <div className="pd-10 w-180">
                                <input type="number" min="0" className="form-control center pd-right-0 mr-10"
                                    value={barNumber || 0}
                                    onChange={(e) => this.changeBarNumber(orderId, styleId, cutId, data.color._id, e.target.value)}
                                />
                            </div>
                            <div className="button-add mg-10">
                                <span className="cursor-pointer icon-add" onClick={() => this.addBar(orderId, styleId, cutId, data.color._id)}>
                                    <img className="bd-none" src={addNewIcon} />
                                </span>
                            </div>
                        </div>

                        {!_.isEmpty(listBarDetail) && <div className="bg-white mg-top-2">
                            <table className="global-table w-full">
                                <tbody>
                                    <tr>
                                        <th className="left">{I18n.t('PageShiping.Bar')}</th>
                                        {sizes.map((size, idx) => <th key={idx} className='left'>{size}</th>)}
                                        <th className="center">{I18n.t('PageShiping.Total')}</th>
                                        <th className="center"></th>
                                    </tr>
                                    {listBarDetail.map((bar, bId) => {
                                        const barTotal = getBarTotal(orderId, styleId, cutId, data.color._id, bar.bar)
                                        return (
                                            <tr key={bId}>
                                                <td className="left">{bar.bar}</td>
                                                {sizes.map((size, sId) => {
                                                    let temp = bar.packings ? bar.packings.find(item => size == item.name) : {};
                                                    return <td key={sId} className="left" style={{ color: '#F36F21' }}>{temp ? temp.value || 0 : 0}</td>
                                                })}
                                                <td className="center" style={{ color: 'red' }}>{barTotal || 0}</td>
                                                <td>
                                                    <a onClick={() => this.removeBar(orderId, styleId, cutId, data.color._id, bar.bar)}><img src={icXIcon} /></a>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                        }
                    </div>
                }
                {packingType == 'Carton' && colorPacking > 0 &&
                    <div>
                        <div className="clear-both mg-top-5">
                            <div className="display-flex bg-white">
                                <div className="pd-10 display-flex align-items-center pd-20 w-180">
                                    <h3 className="center mg-auto size-12">
                                        <span className="fw-normal">{I18n.t("PageShiping.Color")}</span><br /><br />
                                        <strong className="size-13">{data.color.name}</strong><br /><br />
                                    </h3>
                                </div>
                                <div className="pd-10 border-left-dashed">
                                    <table className="global-table mg-auto tr-height-30 box-shadow-none">
                                        <tbody>
                                            <tr style={{ borderBottom: 'unset' }} className="bg-none">
                                                <td className="fw-normal"><h3>{I18n.t("PageShiping.TotalQTY")} : <strong className="color-red">{colorPacking}</strong></h3></td>
                                                {sizes.map((size, sId) => {
                                                    let qty = getQtyColorConst(orderId, styleId, cutId, data.color._id, size)
                                                    return <td key={sId} className='center'> {size} : {qty}</td>
                                                })}
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="bg-white mg-top-2">
                            <table className="global-table w-full">
                                <tbody>
                                    <tr>
                                        <th className='center'></th>
                                        <th className='center'>{I18n.t('PageShiping.CTNNo')}</th>
                                        <th className='center'>{I18n.t('PageShiping.Packed')}</th>
                                        <th className='center'>{I18n.t('PageShiping.NW')}</th>
                                        <th className='center'>{I18n.t('PageShiping.GW')}</th>
                                        <th className='center'>{I18n.t('PageShiping.MEANS')}</th>
                                        <th className='center'>{I18n.t('PageShiping.M3')}</th>
                                        {sizes.map((size, idx) => <th key={idx} className='center'>{size}</th>)}
                                        <th className='center'>{I18n.t('PageShiping.QTY')}</th>
                                        <th className='center'>{I18n.t('PageShiping.QTY/CTN')}</th>
                                        <th className='center'>{I18n.t('PageShiping.Total')}</th>
                                    </tr>
                                    {cartons.sort((a, b) => a.group > b.group).map((carton, idx) => {
                                        if (!_.isEmpty(carton)) {
                                            const qty = carton.quantity || 0
                                            const qtyCtn = carton.qtyCtn || 0
                                            const m3 = _.round(0.00001648 * carton.raw[0].props.l * carton.raw[0].props.w * carton.raw[0].props.h, 5)
                                            const total = (qty * qtyCtn) || 0
                                            const be = carton.raw.length < 2 ?
                                                (<td className="center">{carton.raw[0].props.no}</td>)
                                                :
                                                (<td className="center">{carton.raw[0].props.no}&nbsp;&nbsp;&rArr;&nbsp;&nbsp;{carton.raw[carton.raw.length - 1].props.no}</td>)
                                            return (
                                                <tr key={idx}>
                                                    <td className="style-checkbox">
                                                        <input checked={carton.checked}
                                                            type="checkbox" id={`${orderId}:${styleId}:${cutId}:${data.color._id}:${carton.group}`}
                                                            onChange={() => this.changeCheckedPacking(orderId, styleId, cutId, data.color._id, carton.group, !carton.checked)}
                                                        />
                                                        <label htmlFor={`${orderId}:${styleId}:${cutId}:${data.color._id}:${carton.group}`}><span><span /></span></label>
                                                    </td>
                                                    {be}
                                                    <td className="center w-100">
                                                        <input type="number" min="0" className="form-control center pd-right-0 mr-10"
                                                            value={qty}
                                                            onChange={(e) => this.changePackedNumber(orderId, styleId, cutId, data.color._id, carton.group, e.target.value)}
                                                        />
                                                    </td>
                                                    <td className="center">{carton.raw[0].props.nwKgs || '-'}</td>
                                                    <td className="center">{carton.raw[0].props.gwKgs || '-'}</td>
                                                    <td className="center">{`${carton.raw[0].props.l || '-'}" x ${carton.raw[0].props.w || '-'}" x ${carton.raw[0].props.h || '-'}"`}</td>
                                                    <td className="center">{m3}</td>
                                                    {carton.raw[0].items.map((item, idx) => {
                                                        if(item.name != 'gwKgs' && item.name != 'nwKgs'){
                                                            return (<td className="color-orange center" key={idx}>{item.planQuantity}</td>)
                                                        }

                                                    })}
                                                    <td className="color-green center">{qty}</td>
                                                    <td className="color-green center">{qtyCtn}</td>
                                                    <td className="color-red center">{total}</td>
                                                </tr>
                                            )
                                        }
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                }
            </div>
        );
    }
}


const mapStateToProps = state => ({
    getSizes: selector.getSizes(state),
    getPacking: selector.getPacking(state),
    //GOHH
    getSizeQtyGOHH: selector.getSizeQtyGOHH(state),
    getBarNumber: selector.getBarNumber(state),
    getListBarDetail: selector.getListBarDetail(state),
    getBarTotal: selector.getBarTotal(state),
    getRemaining: selector.getRemaining(state),
    //Carton
    getQtyColorConst: selector.getQtyColorConst(state),
    getCarton: selector.getCarton(state),
})

const mapDispatchToProps = dispatch => ({
    changePackingNumber(orderId, styleId, cutId, colorId, sizeName, value) {
        dispatch(changePackingNumber(orderId, styleId, cutId, colorId, sizeName, value))
    },
    changeBarNumber(orderId, styleId, cutId, colorId, value) {
        dispatch(changeBarNumber(orderId, styleId, cutId, colorId, value))
    },
    addbar(orderId, styleId, cutId, colorId) {
        dispatch(addBar(orderId, styleId, cutId, colorId))
    },
    removeBar(orderId, styleId, cutId, colorId, value) {
        dispatch(removeBar(orderId, styleId, cutId, colorId, value))
    },
    // carton
    changePackedNumber(orderId, styleId, cutId, colorId, group, value) {
        dispatch(changePackedNumber(orderId, styleId, cutId, colorId, group, value))
    },
    changeCheckedPacking(orderId, styleId, cutId, colorId, group, value) {
        dispatch(changeCheckedPacking(orderId, styleId, cutId, colorId, group, value))
    }
})
export default connect(mapStateToProps, mapDispatchToProps)(ColorTableAddView);