
const C = 'CUSTOMER_PO.CURRENT.SHOW_ORDER_DETAIL';

export default {
  CHANGE_ORDER_DETAIL: `${C}.CHANGE_ORDER_DETAIL`,

  FETCH_STYLES: `${C}.FETCH_STYLES`,
  CANCEL_FETCH_STYLES: `${C}.CANCEL_FETCH_STYLES`,
  CHANGE_STYLES: `${C}.CHANGE_STYLES`,

  CHANGE_SELECTED_STYLE: `${C}.CHANGE_SELECTED_STYLE`,

  FETCH_CUT_TICKETS: `${C}.FETCH_CUT_TICKETS`,
  CANCEL_FETCH_CUT_TICKETS: `${C}.CANCEL_FETCH_CUT_TICKETS`,
  CHANGE_CUT_TICKETS: `${C}.CHANGE_CUT_TICKETS`,
  CHANGE_CUT_TICKET: `${C}.CHANGE_CUT_TICKET`,

  SEND_NOTIFY: `${C}.SEND_NOTIFY`,
  CANCEL_SEND_NOTIFY: `${C}.CANCEL_SEND_NOTIFY`,

  RESET_DATA: `${C}.RESET_DATA`,
};
