import React, { Component } from 'react';
import {connect} from 'react-redux';
import ViewInvoiceNoView from '../../../components/accounting/receivable/PreviewHistoryReceivableInvoice';
import * as selector from '../../../../selectors/accounting/receivable/previewInvoice';
class ViewInvoiceNo extends Component {

    constructor(props) {
        super(props);
        this.state = {
         
        }
    }

    componentWillMount = () => {
    }
    
    render() {
        return (
            <ViewInvoiceNoView {...this.props}/>
        );
    }
}

const mapStateToProps = state => ({
    customer: selector.getCustomer(state),
    amount: selector.getAmount(state),
    amountCredit: selector.getAmountCredit(state),
    convertType: selector.getConvertType(state),
    convertRate: selector.getConvertRate(state),
    customerPOs: selector.getCustomerPOs(state)
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps) (ViewInvoiceNo);