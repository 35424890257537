import React from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import _ from 'lodash';

const AssignmentCutTickets = ({styles, orderStyles, handleSelectAllCutTicket, handleSelectCutTicket}) => {
  if(!styles || !styles.length)
    return null;
  return (
    <div>
      {
        styles.map((style, styleIndex) => {
          let orderStyle = (orderStyles || []).find(orderStyle => style.style && orderStyle._id === style.style._id);
          if (!orderStyle) {
            return '';
          }
          let cutTickets = orderStyle.cutTickets || [];
          let total = cutTickets.reduce((acc, c) => acc + (parseInt(c.gTotal) || 0), 0);
          return (
            <div key={_.get(style, "style._id", "999")} className="add-assignment-list-ticket bd-dashed-bottom pd-5-15">
              <ul>
                <li>
                  <div className="float-left">
                    <span className="mg-right-15">{I18n.t('PageProduction.Style')}</span>
                    <span className="medium color-orange">{_.get(style, "style.style", "")}</span>
                  </div>
                  <div className="float-right">
                    <span className="">{I18n.t('PageProduction.Total')}</span>
                    <span className="mg-left-15 bold color-number">{total}</span>
                  </div>
                </li>

                <li>
                  <div className="float-left">
                    <div className="style-checkbox">
                      <input type="checkbox" id={`check_style_all_${_.get(style, "style._id", "999")}`}
                             checked={style.cutTickets && style.cutTickets.length === cutTickets.length}
                             onChange={evt => handleSelectAllCutTicket(style.style._id, cutTickets.filter(el=>!el.disable), evt.target.checked)}
                      />
                      <label htmlFor={`check_style_all_${_.get(style, "style._id", "999")}`}>
                        <span className="mg-right-15"><span/></span>
                        {I18n.t('PageProduction.AllCutTicket')}
                      </label>
                    </div>
                  </div>
                  <div className="float-right">
                    <span className="">{I18n.t('PageProduction.Quantity')}</span>
                  </div>
                </li>
                {cutTickets.map((cutTicket) => {
                  const st = cutTicket.disable ? {background: '#928075'} : {}
                  const title = cutTicket.disable ? 'Assigned' : ''
                  return (
                    <li key={cutTicket._id}>
                      <div className="float-left">
                        <div className="style-checkbox" title={title}>
                          <input disabled={cutTicket.disable} type="checkbox" id={`check_cutTicket_${cutTicket._id}`}
                                 checked={cutTicket.disable || !!(style.cutTickets || []).find(styleCutTicket => styleCutTicket._id === cutTicket._id)}
                                 onChange={evt => handleSelectCutTicket(style.style._id, cutTicket, evt.target.checked)}/>
                          <label className={cutTicket.disable ? "chxDisable":""} htmlFor={`check_cutTicket_${cutTicket._id}`}>
                            <span className="mg-right-15"></span>{cutTicket.cutTicket}
                          </label>
                        </div>
                      </div>
                      <div className="float-right">
                        <span className="color-number">{cutTicket.gTotal}</span>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          );
        })
      }
    </div>
  );
}

AssignmentCutTickets.propTypes = {
  styles: PropTypes.array.isRequired,
  orderStyles: PropTypes.array.isRequired,
  handleSelectAllCutTicket: PropTypes.func.isRequired,
  handleSelectCutTicket: PropTypes.func.isRequired,
};

export default AssignmentCutTickets;
