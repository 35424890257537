import React, { Component } from 'react';
import PropTypes from 'prop-types';

const AccountsPayablePlanAmountTable = (props) => {
    const { AmountINV, TotalAmountINV, Say } = props
    return (
    <div className="bottom text-left size-12 pd-15-0 inline-block w-full">
        <div className="col-md-4 col-sm-6 col-xs-12">
            <p className = "size-17"><span className="mg-right-15">Amount INV</span> <span className="bold color-number">{ AmountINV }</span></p>
        </div>
        <div className="col-md-4 col-sm-6 col-xs-12">
            <p className = "size-17"><span className="mg-right-15">Total Amount INV</span> <span className="bold color-number">{ TotalAmountINV }</span></p>
        </div>
        <div className="col-md-4 col-sm-6 col-xs-12">
            <p className = "size-15"><span className="mg-right-15">Say</span> <span className="bold">{ Say }</span></p>
        </div>
    </div>
    );
};

AccountsPayablePlanAmountTable.propTypes = {
    AmountINV: PropTypes.string,
    TotalAmountINV: PropTypes.string,
    Say: PropTypes.string,
};  

AccountsPayablePlanAmountTable.defaultProps = {
    AmountINV: null,
    TotalAmountINV: null,
    Say: null,
};

export default AccountsPayablePlanAmountTable;
