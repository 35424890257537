function showLoading(types, dispatch){
  dispatch({
    type: types.APP.SHOW_LOADING
  });
}

function showLoadingAuth(types, dispatch){
  dispatch({
    type: types.AUTH.SHOW_LOADING
  });
}

function hideLoading(types, dispatch, error){
  dispatch({
    type: types.APP.HIDE_LOADING
  });
  // Handle error if got error
  if ((error || null) !== null){
    dispatch({
      type: types.APP.SHOW_ERROR_ALERT,
      data: (error && ((typeof error === 'string') ? error : error.error)) || 'Has error'
    });
  }
}

function hideLoadingAuth(types, dispatch, error){
  dispatch({
    type: types.AUTH.HIDE_LOADING
  });
  //Handle error if got error
  if ((error || null) !== null){
    dispatch({
      type: types.AUTH.LOGIN_FAIL,
      data: (error && ((typeof error === 'string') ? error : error.error)) || 'Has error'
    });
  }
}

export default {
  showLoading,
  showLoadingAuth,
  hideLoading,
  hideLoadingAuth
};
