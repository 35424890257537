import React from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import './style.scss';
import { SYSTEM_SETTING_URL } from '../../../constants/routeConstants';

class DropDownMultiSelect extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedValues: [],
      isSelectAll: false,
      displayLabel: '',
      isOpenMenu: false,
      clickOk: false,
      search: '',
    };
  }

  componentWillMount() {
    let { selectedValues } = this.props;
    this.setState({ selectedValues: (this.props.selectedValues || []), displayLabel: this.getDisplayLabel(this.props.options, selectedValues) });
  }

  componentWillReceiveProps(newProps) {
    let { selectedValues } = newProps;
    if (selectedValues != this.props.selectedValues) {
      this.setState({ selectedValues, displayLabel: this.getDisplayLabel(newProps.options, selectedValues) });
    }
    if (this.props.options !== newProps.options) {
      this.setState({ displayLabel: this.getDisplayLabel(newProps.options, selectedValues) });
    }
  }

  handlePreventCloseMenu = (event) => {
    event.stopPropagation();
  }

  handleSelect = (isSelect, value) => {
    let { selectedValues } = this.state;
    selectedValues = (isSelect
      ? Object.assign([], [...selectedValues, value])
      : selectedValues.filter(selectedValue => selectedValue !== value));
    this.setState({
      selectedValues: selectedValues
      , isSelectAll: this.props.options.length === selectedValues.length
    });
  }

  handleSelectAll = (isSelect) => {
    this.cleanSearch();
    this.setState({ 
      selectedValues: isSelect ? (this.props.options || []).map(option => option.value) : [], 
      isSelectAll: isSelect
    });
  }

  handleChange = () => {
    let { options } = this.props;
    let { selectedValues, clickOk } = this.state;
    clickOk = true;
    this.cleanSearch();
    let displayLabel = this.getDisplayLabel(options, selectedValues);
    this.setState({ displayLabel: displayLabel, clickOk: clickOk }); 
    this.props.onChange(this.props.property, this.state.selectedValues);
  }

  handleClickOk = () => {
    let { clickOk } = this.state;
    clickOk = false;
    this.setState({ clickOk: clickOk });
  }

  handleSearch= (searchText) => {
    this.setState({ search: searchText });
  }

  cleanSearch= () => {
    this.refs.search.value = '';
    this.handleSearch('');
  }

  getDisplayLabel = (options, selectedValues) => {
    return options.reduce((label, option) => {
      return label += selectedValues.findIndex(value => value === option.value) >= 0 ? ((label.length ? ', ' : '') + option.label) : '';
    }, '');
  }

  render() {
    let { options, disabled } = this.props;
    let { selectedValues, isSelectAll, displayLabel, clickOk, search } = this.state;
    let wrapClass = 'dropDownMultiSelect';
    let allValue = (this.props.options) || [].map(option => option.value);
    let arrSelectAll = [];
    let arrSelect = [];
    {(options || []).map((option, index) => {
      arrSelectAll.push(selectedValues.findIndex(arrSelectAll => arrSelectAll === option.value))
    })}
    let optionShows = options.map(o => ({...o, display: o.label.toLowerCase().indexOf(search.toLowerCase()) != -1? true : false }))
    arrSelect = _.pullAllWith(arrSelectAll, [-1], _.isEqual);
    let checkAll = _.isEqual(arrSelect.length, allValue.length);
    return (
      <div className={wrapClass}>
        <div className={clickOk == true ? "dropdown remove-dropdown" : "dropdown"}>
          <input onClick={evt => this.handleClickOk()} type="text" className="form-control" value={displayLabel} data-toggle="dropdown" disabled={disabled} />
          <div className="dropdown-menu" onClick={evt => this.handlePreventCloseMenu(evt)}>
            <ul onClick={event => event.stopPropagation()}>
              <li className="style-checkbox">
                <input type="checkbox" className="cursor-pointer" checked={checkAll ? true : isSelectAll}
                  onChange={evt => this.handleSelectAll(!!evt.target.checked)} disabled={disabled} />
                <label>
                  <span><span><b>{I18n.t('ALL')}</b></span></span>
                </label>
                <div className="lable-search">
                  <input type="text" className="form-control search" onKeyUp={evt => this.handleSearch(evt.target.value)} ref="search"></input>
                  <input type="button" className="clean-search" value="x" onClick={() => this.cleanSearch('')}></input>
                </div>
              </li>
              {(optionShows || []).map((option, index) => {
                return (
                  <li key={index} className="style-checkbox" 
                    style={{display: option.display ? 'block' : 'none'}}
                    checked={selectedValues.findIndex(value => value === option.value) >= 0}
                  >
                    <input type="checkbox"
                      className = "cursor-pointer"
                      checked={selectedValues.findIndex(value => value === option.value) >= 0}
                      value={option.value}
                      onChange={evt => this.handleSelect(!!evt.target.checked, option.value)} />
                    <label>
                      <span><span>{option.label}</span></span>
                    </label>
                  </li>
                );
              })}
            </ul>
            <input type="button" className='btnOk' onClick={evt => this.handleChange()} value={I18n.t('OK')} />
          </div>
        </div>
      </div>
    );
  }
}

DropDownMultiSelect.propTypes = {
  options: PropTypes.array.isRequired,
  selectedValues: PropTypes.array.isRequired,
  property: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

DropDownMultiSelect.defaultProps = {
  disabled: false,
};

export default DropDownMultiSelect;
