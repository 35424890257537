import {getListPurchaseDAL, getListFollowUpDAL, addPurchaseDAL, editPurchaseDAL, getPurchaseDAL, deletePurchaseDAL, restorePurchaseDAL, getMaterialsDAL, search} from '../api/purchaseApi';
import _ from 'lodash';

class purchaseServices {

  constructor() {
  }

  getListPurchase (options = {}) {
    options.populate = (options.populate ? `${options.populate},` : '');
    options.sort = options.sort || {createdAt: -1};
    _.set(options, 'sort', JSON.stringify(options.sort));

   return getListPurchaseDAL(options);
  }

  getListPurchaseShipping (options = {}) {
      options.populate = (options.populate ? `${options.populate},` : '');
      options.sort = options.sort || {createdAt: -1};
      _.set(options, 'sort', JSON.stringify(options.sort));

      return search(null, options, null);
  }

  getListFollowUp (options = {}) {
    options.populate = (options.populate ? `${options.populate},` : '');
    options.sort = options.sort || {createdAt: -1};
    _.set(options, 'sort', JSON.stringify(options.sort));
    return getListFollowUpDAL(options);
  }

  getDetail (id) {
    return getPurchaseDAL(id, {});
  }
  deletePurchase (id) {
    return deletePurchaseDAL(id);
  }

  editPurchase (id, data) {
    return editPurchaseDAL(id, data);
  }

  restorePurchase (id) {
      return restorePurchaseDAL(id);
  }

  addPurchase (data) {
    return addPurchaseDAL(data);
  }
}

export default new purchaseServices();
