import React from 'react';
import PropTypes from 'prop-types';
import I18n from "i18n-js";
import _ from 'lodash';
import TableList from "../../../common/datatables/TableList";
import cutTicketActions from "../../../../actions/cutTicketActions";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import appActions from "../../../../actions/appActions";

class UpdateActualYield extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      headers: [],
      tableData: []
    };
  }

  render() {
    const {data} = this.props;
    return (
      <div id="content">

        <ul className="content-header-title">
          <li>
            <p>{I18n.t("PageProduction.CutTicket")}
              <span className="color-orange medium mg-left-50 size-16">{_.get(data,"cutTicket","")}</span>
            </p>
          </li>

          <li className="float-right add-filter-top" onClick={this.props.onUpdateActualYield}>
            <span className="cursor-pointer global-add" data-toggle="modal" data-target="#modal-add-save">
              <i className="fa fa-floppy-o" aria-hidden="true"></i>{I18n.t("PageProduction.Save")}</span>
          </li>
        </ul>

        <div className="wrap-global-table">
          <TableList items={_.get(data, "materials", [])} columns={_.get(data, "editHeaders", [])}
                     options={{clsTbl: 'global-table box-shadow-none'}}/>
        </div>
      </div>
    );
  }
}

UpdateActualYield.propTypes = {
  appActions: PropTypes.object,
  cutTicketActions: PropTypes.object,
  data: PropTypes.object,
  styleId: PropTypes.string,
  activeScreen: PropTypes.string,
  onChangeActual: PropTypes.func,
  onUpdateActualYield: PropTypes.func,
};

function mapStateToProps (state, ownProps) {
  return {
    appState: state.appState,
    authState: state.authState,
    cutTicketState: state.cutTicketState,
  };
}

function mapDispatchToProps (dispatch) {
  return {
    appActions: bindActionCreators(appActions, dispatch),
    cutTicketActions: bindActionCreators(cutTicketActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateActualYield);

