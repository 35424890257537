import C from '../../constants/ship-out/createPklShip';
import shipOutApi from '../../api/shipOutApi';

// all
export const getListOrders = (listIds) => dispatch => {
    if(listIds) {
        const query = {data: listIds};
        shipOutApi.getPackingDetail(query).then(res => {
            if(res.success) {
                dispatch({type: C.CHANGE_PKL_PROD_LIST_DETAIL, payload: res.data})
            }
        })
    }
}

export const changeOrderActive = (id, value) => dispatch => {
    if(id) {
        dispatch({type: C.CHANGE_ACTIVE_ORDER, payload: {orderId: id, value: value}})
    }
}

export const resetData = () => dispatch => {
    dispatch({type: C.RESET_DATA})
}

export const changeStyleActive = (orderId, styleId) => dispatch => {
    dispatch({type: C.CHANGE_ACTIVE_STYLE, payload: {orderId: orderId, styleId: styleId}})
}

export const removeStyle = (orderId, styleId) => dispatch => {
    if(orderId && styleId) {
        dispatch({type: C.REMOVE_STYLE, payload: {orderId: orderId, styleId: styleId}})
    }
}

export const changeCommondity = (orderId, value) => dispatch => {
        dispatch({type: C.CHANGE_COMMODITY, payload: {orderId, value}})
    
}

export const save = (data) => dispatch => {
    if(data) {
        shipOutApi.createPklShip(data).then(res => {
            if(res.success) {
                dispatch({type: C.CREATE_SUCCES, payload: true})
            }
        })
    }
}

// Gohh
export const changePackingNumber = (orderId, styleId, cutTicketId, colorId, sizeName, value) => dispatch => {
    if(value >= 0) {
        dispatch({type: C.CHANGE_SIZE_PACKING, payload: {orderId, styleId, cutTicketId, colorId, sizeName, value}})
    }
}

export const changeBarNumber = (orderId, styleId, cutTicketId, colorId, value) => dispatch => {
    if(value >=1 ) {
        dispatch({type: C.CHANGE_BAR_NUMBER, payload: {orderId, styleId, cutTicketId, colorId, value}})
    }
}

export const addBar = (orderId, styleId, cutTicketId, colorId) => dispatch => {
    dispatch({type: C.ADD_BAR, payload: {orderId, styleId, cutTicketId, colorId}})
}

export const removeBar = (orderId, styleId, cutTicketId, colorId, value) => dispatch => {
    dispatch({type: C.REMOVE_BAR, payload: {orderId, styleId, cutTicketId, colorId, value}})
}

//carton
export const changePackedNumber = (orderId, styleId, cutTicketId, colorId, group, value) => dispatch => {
    dispatch({type: C.CHANGE_PACKED_QUANTITY, payload: {orderId, styleId, cutTicketId, colorId, group, value}})
}

export const changeCheckedPacking = (orderId, styleId, cutTicketId, colorId, group, value) => dispatch => {
    dispatch({type: C.CHANGE_PACKED_CHECKED, payload: {orderId, styleId, cutTicketId, colorId, group, value}})
}