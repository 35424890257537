import React, { Component } from 'react';
import { toastr } from 'react-redux-toastr';
import I18n from 'i18n-js'
import EditInvoiceContractorView from '../../../components/accounting/PayableInvoiceList/EditInvoiceContractor';
import { connect } from 'react-redux';
import * as actions from '../../../../actions/accounting/payable/editInvoiceContractor';
import * as selector from '../../../../selectors/accounting/payable/editInvoiceContractor';
import ReceiptModal from '../../../components/accounting/PayableInvoiceList/ReceiptModal';
import UploadTransferFileModal from '../../../components/accounting/PayableInvoiceList/UploadTransferFileModal';
import {withRouter} from 'react-router';
import * as PATH from '../../../../constants/routeConstants';
class EditInvoiceContractor extends Component {
    constructor(props) {
        super(props);
        this.state = ({
            openModal: false,
            openReceiptModal: false,
            openTransferModal: false,
        })
    }

    componentWillUnmount() {
        this.props.resetData()
    }

    componentWillReceiveProps(nextProps) {
        const {success, activeScreen} = nextProps;
        if(success) {
            nextProps.resetSuccessStatus();
            nextProps.router.history.push(PATH.ACCOUNTING_PAYABLE_INVOICE_URL)
            toastr.success(I18n.t('Notify.Success'), 'Update Invoice Success')
        }
        if(!activeScreen) {
            nextProps.router.history.goBack()
        }
    }

    openReceiptModal = () => {
        const { receiptFromInvoice } = this.props;
        this.props.createReceipt(receiptFromInvoice)
        this.setState({ openReceiptModal: true })

    }

    closeReceiptModal = () => {
        this.props.changeEmptyReceipt()
        this.setState({ openReceiptModal: false })
    }

    openTransferModal = () => {
        const { filesSaved } = this.props;
        this.props.copyFileToRawData(filesSaved);
        this.setState({ openTransferModal: true })
    }

    closeTransferModal = () => {
        this.props.emptyFilesInRawData();
        this.setState({ openTransferModal: false })
    }

    save = (paymentType, data) => {
        const {payAmount} = this.props;
        if(payAmount == 0) {
            delete data.payment;
            this.props.save(paymentType, data);
        } else {
            this.props.save(paymentType, data);
        }
    }

    render() {
        const { openReceiptModal, openTransferModal } = this.state;
        const { transferFiles, receiptData } = this.props
        return (
            <div>
                <EditInvoiceContractorView
                    {...this.props}
                    save = {(paymentType, data) => this.save(paymentType, data)}
                    openReceiptModal={() => this.openReceiptModal()}
                    openTransferModal={() => this.openTransferModal()}
                />
                <ReceiptModal
                    openModal={openReceiptModal}
                    close={this.closeReceiptModal}
                    changeReceiptValue={(key, value) => this.props.changeReceiptValue(key, value)}
                    receiptData={receiptData}
                    onSubmitReceipt={data => this.props.onSubmitReceipt(data)}
                />
                <UploadTransferFileModal
                    onRemoveFile={id => this.props.onRemoveFile(id)}
                    transferFiles={transferFiles}
                    onAddFile={file => this.props.onAddFile(file)}
                    isOpen={openTransferModal}
                    onClose={this.closeTransferModal}
                    onSubmit={(files) => this.props.onSubmitFiles(files)}
                />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    constContractorName: selector.getConstContractor(state),
    contractor: selector.getContractor(state),
    invoiceNo: selector.getInvoiceNo(state),
    issuedDate: selector.getIssuedDate(state),
    dueDate: selector.getDueDate(state),
    payAmount: selector.getPayAmount(state),
    paymentType: selector.getPaymentType(state),
    totalAmount: selector.getTotalAmount(state),
    paid: selector.getPaid(state),
    remaining: selector.getRemaining(state),
    listCheckableCustomerPOs: selector.getCheckableCustomerPOs(state),
    editableCustomerPOsDetail: selector.getListCustomerPOsDetail(state),
    transferFiles: selector.getTransferFiles(state),
    dataApi: selector.fnGenerateRequestBody(state),
    filesSaved: selector.getFilesSaved(state),
    receiptData: selector.getReceiptDataFromRawData(state),
    receiptFromInvoice: selector.getReceiptFromInvoice(state),
    emptyReceipt: selector.getEmptyReceipt(state),
    success: selector.getSuccess(state),
    selectableConvertCurrencies: selector.getSelectableConvertCurrencies(state),
    selectedConvertCurrency: selector.getSelectedConvertCurrency(state),
    convertRate: selector.getConvertRate(state),
    disableChangeValue: selector.getDisableChangeValue(state),
    mainVatValue: selector.getMainVATValue(state),
    amountConvert: selector.getAmountConvert(state),
    totalAmountConvert: selector.getTotalAmountConvert(state),
    currencyTypeUSD: selector.getCurrencyTypeUSDOfSupplier(state),
    currencyTypeVND: selector.getCurrencyTypeVNDOfSupplier(state),
    activeScreen: selector.getActiveScreenStatus(state),
    selectableCredits: selector.getSelectableCreditDebits(state),
    selectedCredit: selector.getSelectedCreditDebit(state)    
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    changePaymentType(value) {
        dispatch(actions.changePaymentType(value))
    },
    changeContractor(value) {
        dispatch(actions.changeContractor(value))
    },
    changeCreditDebit(value, totalAmount) {
        dispatch(actions.changeCreditedDebit(value, totalAmount));
    },
    changeIssuedDate(value) {
        dispatch(actions.changeIssuedDate(value))
    },
    changePayAmount(value, remaining, paid) {
        dispatch(actions.changePayAmount(value, remaining, paid))
    },
    changeDueDate(value) {
        dispatch(actions.changeDueDate(value))
    },
    changeInvoiceNo(value) {
        dispatch(actions.changeInvoiceNo(value))
    },
    onAddFile(file) {
        dispatch(actions.addFileTransfer(file))
    },
    onRemoveFile(id) {
        dispatch(actions.onRemoveFile(id))
    },
    save(paymentType, data) {
        // console.log(data)
        dispatch(actions.save(paymentType, data))
    },
    copyFileToRawData(files) {
        dispatch(actions.copyFileToRawData(files))
    },
    emptyFilesInRawData() {
        dispatch(actions.emptyFilesInRawData())
    },
    onSubmitFiles(files) {
        dispatch(actions.onSubmitFiles(files))
    },
    createReceipt(value) {
        dispatch(actions.createReceipt(value))
    },
    changeReceiptValue(key, value) {
        dispatch(actions.changeReceiptValue(key, value))
    },
    onSubmitReceipt(data) {
        dispatch(actions.onSubmitReceipt(data))
    },
    changeEmptyReceipt() {
        dispatch(actions.changeEmptyReceipt())
    },
    resetSuccessStatus() {
        dispatch(actions.resetSuccessStatus())
    },
    changeSelectedCustomerPOs(id, checked) {
        dispatch(actions.changeSelectedCustomerPOs(id, checked))
    },
    toggleActiveCustomerPO(id, active) {
        dispatch(actions.toggleActiveCustomerPO(id, active))
    },
    changeCustomerPOPrice(id, value) {
        dispatch(actions.changeCustomerPOPrice(id, value))
    },
    changeStylePrice(cusId, styleId, value) {
        dispatch(actions.changeStylePrice(cusId, styleId, value))
    },
    changeColorPrice(cusId, styleId, cutId, colorId, value) {
        dispatch(actions.changeColorPrice(cusId, styleId, cutId, colorId, value))
    },
    changeSelectedConvertCurrency(item) {
        dispatch(actions.changeSelectedConvertCurrency(item.value))
    },
    changeConvertRate(value) {
        dispatch(actions.changeConvertRate(value))
    },
    resetData() {
        dispatch(actions.resetData())
    },
    changeMainVATNo(value) {
        dispatch(actions.changeMainVATNo(value))
    },
    changeMainVATTotalAmount(value) {
        dispatch(actions.changeMainVATTotalAmount(value))
    },
    changeMainVATDate(value) {
        dispatch(actions.changeMainVATDate(value))
    },
    changeMainVATTaxRate(value) {
        dispatch(actions.changeMainVATTaxRate(value))
    },
    changeSubVATDate(id, value) {
        if (id) {
            dispatch(actions.changeSubVATDate(id, value));
        }
    },

    changeSubVATTaxRate(id, value) {
        if (id) {
            dispatch(actions.changeSubTaxRate(id, value));
        }
    },

    changeSubVATNote(id, value) {
        if (id) {
            dispatch(actions.changeSubVATNote(id, value));
        }
    },

    changeSubVATTotalAmount(id, value) {
        if (id) {
            dispatch(actions.changeSubVATTotalAmount(id, value));
        }
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EditInvoiceContractor));
