import C from '../../constants/ship-out/editShipment';
import { combineReducers } from "redux";
import _ from 'lodash';

const shipmentDetail = (state = null, action) => {
    const {type, payload} = action;
    let key, value, rawCopy;
    switch(type) {
        case C.CHANGE_SHIPMENT_DETAIL:
            return payload;
        case C.CHANGE_SHIPMENT_INFO:
            key = payload.key;
            value = payload.value;
            rawCopy = _.cloneDeep(state);
            rawCopy[key] = value;
            return rawCopy;
        case C.RESET_DATA:
            return null;
        default:
            return state;
    }
}

const standard = (state = null, action) => {
    const {type, payload} = action;
    switch(type) {
        case C.CHANGE_STANDARD:
            return payload;
        case C.RESET_DATA:
            return null;
        default:
            return state;
    }
}

const listOrders = (state = null, action) => {
    const {type, payload} = action
    switch(type) {
        case C.CHANGE_SHIPMENT_DETAIL: {
            let dic = []
            payload.pklShips.map(item => item.shipOutInvoices.map(it => {
                const order = item.pklShip.invoice.items.find(temp => temp._id == it._id);
                const result = {
                    pklShipId: item._id,
                    _id: it._id,
                    customerPO: order.customerPO,
                    commodity: order.commodity,
                    styles: it.styles.map(style => {
                        return {
                            _id: style.style._id,
                            style: style.style.style,
                            quantity: _.sum(style.cutTickets.map(cut => _.sum(cut.colors.map(color => color.quantity)))),
                        }
                    })
                }
                dic.push(result);
            }))
            return dic;
        }
        case C.RESET_DATA:
            return null;
        default:
            return state;
    }
}

const activeOrders = (state = [], action) => {
    const { type, payload } = action;
    switch (type) {
        case C.CHANGE_SHIPMENT_DETAIL: {
            // Active first order after reload data
            const [item] = payload && payload.pklShips[0].pklShip.pklProds;
            return item ? [item._id] : [];
        }
        case C.CHANGE_ACTIVE_ORDER: {
            const { orderId, value } = payload;
            let filtered = _.cloneDeep(state).filter(it => it !== orderId);
            if (value) filtered = [...filtered, orderId];
            return filtered;
        }
        case C.RESET_DATA:
            return [];
        default:
            return state;
    }
}

const activeStyles = (state = {}, action) => {
    const { type, payload } = action;
    switch(type) {
        case C.CHANGE_SHIPMENT_DETAIL: {
            const dic = {};
            payload && payload.pklShips.map(item => item.pklShip.pklProds.map(it => {
                // Active one style of each order if found one
                const [style] = it.styles;
                dic[it._id] = style ? style.style._id : null;
            }))
            return dic;
        }
        case C.CHANGE_ACTIVE_STYLE: {
            const { orderId, styleId } = payload;
            const dic = _.cloneDeep(state);
            dic[orderId] = styleId;
            return dic;
        }
        case C.REMOVE_STYLE: {
            const { orderId, styleId } = payload;
            const dic = _.cloneDeep(state);
            if (dic[orderId] === styleId) dic[orderId] = null;
            return dic;
        }
        case C.RESET_DATA:
            return {};
        default:
            return state;
    }
}

const dataInputs = (state = null, action) => {
    const {type, payload} = action;
    let dic = {};
    let key;
    switch(type) {
        case C.CHANGE_SHIPMENT_DETAIL:
            payload.pklShips.map(pklShip => pklShip.shipOutInvoices.map(item => item.styles.map(style => {
                key = `${item._id}:${style.style._id}`
                dic[key] = [];
                style.cutTickets.map(cut => cut.colors.map(color => {
                    // const key = `${item._id}:${style.style._id}:${cut.cutTicket._id}:${color.color._id}`
                    dic[key].push({
                        cutTicket: cut.cutTicket.cutTicket,
                        cutTicketId: cut.cutTicket._id,
                        store: cut.cutTicket.store && cut.cutTicket.store.name,
                        color: color.color.name,
                        colorId: color.color._id,
                        quantity: color.quantity,
                        price: color.price,
                        amount: color.price * color.quantity,
                        unitPrice: color.unitPrice,
                        unitAmount: color.quantity * color.unitPrice
                    })
            }))})))
            return dic;
        case C.CHANGE_UNIT_PRICE:
            const {orderId, styleId, cutTicketId, colorId, value} = payload;
            dic = _.cloneDeep(state);
            key = `${orderId}:${styleId}`;
            dic[key].map(item => {
                if(item.cutTicketId == cutTicketId && item.colorId == colorId) {
                    item.unitPrice = Number(value);
                    item.unitAmount =  _.round((item.quantity * Number(value)) * 1, 3);;
                }
            })
            return dic
        case C.RESET_DATA:
            return null;
        default:
            return state;
    }
}

const success = (state = false, action) => {
    const {type, payload} = action;
    switch(type) {
        case C.RESET_DATA:
            return null;
        case C.UPDATE_SUCCESS:
            return payload;
        default:
            return state;
    }
}

const listForwarder = (state = [], action) => {
    const { type, payload } = action;
    switch(type) {
        case C.CHANGE_LIST_FORWARDER:
            return payload;
        case C.ADD_FORWARDER_INTO_LIST:
            let dic = _.cloneDeep(state);
            dic = dic.filter(item => item.forwarder != payload);
            dic.unshift({_id: payload, forwarder: payload});
            return dic;
        case C.RESET_DATA:
            return [];
        default:
            return state;
    }
}

const activeScreenEditShipment = (state = false, action) => {
    const {type, payload} = action;
    switch(type) {
      case C.ACTIVE_SCREEN_EDIT_SHIPMENT:
        return payload;
      default:
        return state;
    }
  }

export const editShipment = combineReducers({
    success,
    shipmentDetail,
    standard,
    listOrders,
    activeOrders,
    activeStyles,
    dataInputs,
    listForwarder,
    activeScreenEditShipment
})