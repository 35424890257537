import { createSelector } from 'reselect';
import _ from 'lodash';
import moment from 'moment'
import {genDollarToText} from '../../functions/helpers'

export const getTotal = ({createINV}) => {
    return createINV.dataSet ?  _.sum(createINV.dataSet.items.map(item =>
        _.sum(item.styles.map(style =>
            _.sum(style.cutTickets.map(cut =>
                _.sum(cut.colors.map(color => color.quantity || 0)))))))) : 0;
}

export const getGTotal = ({ createINV }) => {
    return createINV.dataSet ?  _.sum(createINV.dataSet.items.map(item =>
        _.sum(item.styles.map(style =>
            _.sum(style.cutTickets.map(cut =>
                _.sum(cut.colors.map(color => _.round((color.quantity || 0) * (color.price || 0), 5))))))))) : 0;
}

export const getcustomerInv = ({createINV}) => createINV.dataSet && createINV.dataSet.customerInv

export const getInvDate = ({createINV}) => createINV.dataSet && moment(createINV.dataSet.invDate).format("MMM DD, YYYY")  || moment().format("MMM DD, YYYY")

export const getListCommodity = ({createINV}) => {
    const data = createINV.dataSet ? createINV.dataSet.items.map(item => {
        return {
            _id: item._id,
            commodity: item.commodity,
            hsCode: item.hsCode,
            catNo: item.catNo
        }
    }) : []
    return data;
}

export const getDataOfCommodity = ({ createINV }) => id => {
    const data = [];
    createINV.dataSet && createINV.dataSet.items.map(item => item._id === id && item.styles.map(style => style.cutTickets.map(cut => cut.colors.map(color => {
        color.quantity && data.push({
            _id: item._id,
            customerPO: item.customerPO,
            styleId: style.style,
            style: style.styleName,
            cutTicketId: cut.cutTicket,
            cutTicket: cut.cutTicketName,
            store: cut.store,
            colorId: color.color,
            color: color.colorName,
            quantity: color.quantity,
            price: color.price,
            amount: _.round(color.quantity * color.price, 5),
        }) 
    }))))
    return data;
}

export const genSayText = (value) => {
    return genDollarToText(value);
}

export const genDataApi = ({createINV}) => createINV.dataSet && {pklShipId: ((createINV.pklShipDetail || {})._id || ''), ...createINV.dataSet}

export const getSuccess = ({ createINV }) => createINV.success;

export const getEmptyPrice = createSelector (
    [
        ({createINV}) => createINV.dataSet,
        getDataOfCommodity
    ], (dataSet, getDataOfCommodity) => {
        const price = dataSet ? _.sum(dataSet.items.map(item => _.sum(getDataOfCommodity(item._id).map(it => it.price)))) : 0;
        return price > 0 ? false : true;
    }
)

export const getEmptyHSCode = createSelector (
    [
        getListCommodity
    ], data => {
        const temp = data.filter(item => item.hsCode);
        return temp.length < data.length ? true : false;
    }
)

export const getEmptyCatNo = createSelector (
    [
        getListCommodity
    ], data => {
        const temp = data.filter(item => item.catNo);
        return temp.length < data.length ? true : false;
    }
)

export const getActiveScreenEditStatus = ({createINV}) => createINV.activeScreenEditStatus;

export const getActiveScreenCreateStatus = ({createINV}) => createINV.activeScreenCreateStatus;