import C  from '../../constants/ship-out/editPklShip';
import C2  from '../../constants/ship-out/showPklProdList';
import shipOutApi from '../../api/shipOutApi';

export const getPklShipDetail = (id) => dispatch => {
    if(id) {
        let array = [];
        array.push(id);
        let query = {data: array}
        dispatch({type: C2.VIEW_CREATER, payload: true});
        shipOutApi.getPklShipDetail(query).then(res => {
            if(res.success) {
                dispatch({type: C.CHANGE_PKL_SHIP_DETAIL, payload: res.data[0]});
            }
        })
    }
}

export const getPklShipDetailView = (id) => dispatch => {
    if(id) {
        let array = [];
        array.push(id);
        let query = {data: array}
        shipOutApi.getPklShipDetail(query).then(res => {
            if(res.success) {
                dispatch({type: C.CHANGE_PKL_SHIP_DETAIL, payload: res.data[0]})
                dispatch({type: C.CHANGE_VIEW, payload: true})
            }
        })
    }
}

export const changeCommondity = (pklProds, orderId, value) => dispatch => {
    dispatch({type: C.CHANGE_COMMODITY, payload: {pklProds, orderId, value}})

}

export const resetData = () => dispatch => {
    dispatch({type: C.RESET_DATA})
}

export const changeOrderActive = (value, orderId) => dispatch => {
    dispatch({type: C.CHANGE_ACTIVE_ORDER, payload: {value, orderId}})
}

export const changeStyleActive = (orderId, styleId) => dispatch => {
    dispatch({type: C.CHANGE_ACTIVE_STYLE, payload: {orderId, styleId}})
}

export const changeBarActual = (orderId, styleId, cutTicketId, colorId, barNo, sizeName, value) => dispatch => {
    dispatch({type: C.CHANGE_BAR_ACTUAL_QUANTITY_INPUT_VALUE, payload: {orderId, styleId, cutTicketId, colorId, barNo, sizeName, value}})
}

export const resetBarNumber = (orderId, styleId, cutTicketId, colorId, barNo, bar) => dispatch => {
    dispatch({type: C.RESET_BAR_NUMBER, payload: {orderId, styleId, cutTicketId, colorId, barNo, bar}})
}

export const submitBarChange = (orderId, styleId, cutTicketId, colorId, barNo, bar) => dispatch => {
    dispatch({type: C.PERFORM_CHANGE_BAR_ACTUAL_QUANTITIES, payload: {orderId, styleId, cutTicketId, colorId, barNo, bar}})
}

export const save = (id, data) => dispatch => {
    if(id) {
        shipOutApi.updatePklShip(id, data).then(res => {
            if(res.success) {
                dispatch({type: C.UPDATE_SUCCESS, payload: true})
            }
        })
    }
}

export const changeShowCartonDetail = (orderId, styleId, cutTicketId, colorId, group) => dispatch => {
    dispatch({type: C.SHOW_CARTON_DETAIL, payload: {orderId, styleId, cutTicketId, colorId, group}})
}

export const changeCheckedPacking = (orderId, styleId, cutTicketId, colorId, group, cartonNo, value) => dispatch => {
    dispatch({type: C.CHANGE_CHECKED_PACKING, payload: {orderId, styleId, cutTicketId, colorId, group, cartonNo, value}})
}

export const changePackedNwKgs = (orderId, styleId, cutTicketId, colorId, group, cartonNo, value) => dispatch => {
    dispatch({type: C.CHANGE_PACKED_NWKGS, payload: {orderId, styleId, cutTicketId, colorId, group, cartonNo, value}})
}
export const changePackedGwKgs = (orderId, styleId, cutTicketId, colorId, group, cartonNo, value) => dispatch => {
    dispatch({type: C.CHANGE_PACKED_GWKGS, payload: {orderId, styleId, cutTicketId, colorId, group, cartonNo, value}})
}
export const changePackedNumber = (orderId, styleId, cutTicketId, colorId, group, cartonNo, value) => dispatch => {
    dispatch({type: C.CHANGE_PACKING_NUMBER, payload: {orderId, styleId, cutTicketId, colorId, group, cartonNo, value}})
}

export const activeScreenEditPklShip = () => dispatch => {
    dispatch({type: C.ACTIVE_SCREEN_EDIT_PKL_SHIP, payload: true})
}