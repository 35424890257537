import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { sourceTypes, materialTypes } from '../../../constants/EnumTypes';
import _ from 'lodash';
import I18n from 'i18n-js';
import SearchSelect from '../../../ui/components/forms/SearchSelect';
import TextNumberInput from "../../common/fields/TextNumberInput";

import icXIcon from '../../../stylesheets/images/ic-x.svg';

class EstimateForm extends Component {
  constructor(props, context) {
    super(props, context);
  }

  onDelete() {
    if(this.props.onDelete) {
      this.props.onDelete();
    }
  }

  onChange(property, value) {
    let { data, materials } = this.props;
    let mat = materials.find(vl => vl._id.toString() == value);
    if (mat) {
      data.content = mat.data.content;
      data.unit = mat.data.unit;
    }
    data = _.set(data, property, value);
    this.setState({ data });

    // update to parent
    let { updateInfo } = this.props;
    updateInfo && updateInfo(_.cloneDeep(data));
  }

  render() {
    const { type,name } = this.props;
    let errors = _.isArray(this.props.errors) ? this.props.errors[0] : (this.props.errors || {});
    let { data, materials } = this.props;
    let findMateral = _.find(materials , o => o._id === data.material);
    let unit = _.get(findMateral, 'data.unit', '');
    const fabrics = (materials || []).map(m => ({
      value: m._id,
      label: m.name + ((m.data && m.data.content) ? (' --- ' + m.data.content) : ''),
      raw: m
    }));
    const content = _.get(findMateral, 'data.content', '');
    return (
      <div className="content-left">
        <div className="col-md-2">
          {name !== "SELF" &&
          <img className="ic-delete" src={icXIcon} alt="" onClick={()=>this.onDelete()}/>
          }
        </div>
        <div className="col-md-10">
          <ul>
            <li><input type="radio" id="self-radio-nominated" checked={data.type === sourceTypes.NOMINATED} onChange={evt => this.onChange('type', evt.currentTarget.value)} value={sourceTypes.NOMINATED} /><label htmlFor="self-radio-nominated"><span>{I18n.t('PageYield.Nominated')}</span></label></li>
            <li><input type="radio" id="self-radio-sourced" checked={data.type === sourceTypes.SOURCED} onChange={evt => this.onChange('type', evt.currentTarget.value)} value={sourceTypes.SOURCED} /><label htmlFor="self-radio-sourced"><span>{I18n.t('PageYield.Sourced')}</span></label></li>
          </ul>
        </div>
        {data.type === sourceTypes.NOMINATED && (
          <div className="form-group replacement">
            <div className="col-md-2 col-sm-6 col-xs-12 text">
              <p className="w-102" style={{ position: 'relative' }}>{I18n.t('PageYield.ReplacementPO')} #<span className="color-red" style={{ position: 'absolute', right: '-5px'}}>*</span></p>
            </div>
            <div className="col-md-10 col-sm-6 col-xs-12 value">
              <input type="text" className="form-control" value={data.replacementPO || ''} onChange={evt => this.onChange('replacementPO', evt.target.value)} />
              <div className="text-danger size-12">{errors && errors.replacementPO && I18n.t(errors.replacementPO)}</div>
            </div>
          </div>
        )}

        <div className="form-group">
          <div className="col-md-2 col-sm-6 col-xs-12 text">
            {type === materialTypes.TRIM && (
              <p>{I18n.t('PageYield.Trim')}</p>
            )}
            {type === materialTypes.FABRIC && (
              <p>{I18n.t('PageYield.Fabric')}<span className="color-red" >*</span></p>
            )}
          </div>
          <div className="col-md-10 col-sm-6 col-xs-12 value">
            <SearchSelect
                className="fabric-combobox"
                options={fabrics}
                value={data.material || ""}
                onChange={evt => this.onChange('material', (evt && evt.value) || '')}
            />
            {/* <select className="form-control" value={data.material || ''} onChange={evt => this.onChange('material', evt.target.value)} >
              <option value="" />
              {materials.map((i, idx) => (<option key={idx} value={i._id}>{i.name + ((i.data && i.data.content) ? (' --- ' + i.data.content) : '')}</option>))}
            </select> */}
            <div className="text-danger size-12">{errors && errors.material && I18n.t(errors.material)}</div>
          </div>
        </div>

        <div className="form-group">
          <div className="col-md-2 col-sm-6 col-xs-12 text">
            <p>{I18n.t('PageYield.Content')}<span className="color-red">*</span></p>
          </div>
          <div className="col-md-10 col-sm-6 col-xs-12 value">
            {/* <select disabled className="form-control" value={data.material || ''} onChange={evt => this.onChange('material', evt.target.value)} >
              <option value="" />
              {materials.map((i, idx) => (<option key={idx} value={i._id}>{((i.data && i.data.content) ? i.data.content : '')}</option>))}
            </select> */}
            
            <input type="text" className="form-control" value={content} disabled />
          </div>
        </div>

        <div className="form-group">

          <div className="col-md-2 col-sm-6 col-xs-12 text">
            <p className="w-100">{I18n.t('PageYield.CustomerYield')}<span className="color-red">*</span></p>
          </div>
          <div className="col-md-4 col-sm-6 col-xs-12 value">
              <input type="number" className="form-control center" value={data.yield == null ? '' : data.yield} onChange={evt => this.onChange('yield', evt.currentTarget.value)} />
            {/*<TextNumberInput className="form-control center" value={data.yield || ''} onChange={val => this.onChange('yield', val)} />*/}
            <div className="text-danger size-12">{errors && errors.yield && I18n.t(errors.yield)}</div>
          </div>
          <div className="col-md-2 col-sm-6 col-xs-12 text right">
            <p>%</p>
          </div>
          <div className="col-md-4 col-sm-6 col-xs-12 value">
              <input type="number" className="form-control center" value={data.percent == null ? '' : data.percent} onChange={evt => this.onChange('percent', evt.currentTarget.value)} />
            {/*<TextNumberInput className="form-control center" value={data.percent || ''} onChange={val => this.onChange('percent', val)} />*/}
            <div className="text-danger size-12">{errors && errors.percent && I18n.t(errors.percent)}</div>
          </div>
        </div>

        <div className="form-group">
          {type === materialTypes.FABRIC && (
            <div>
              <div className="col-md-2 col-sm-6 col-xs-12 text">
                <p>{I18n.t('PageYield.Width')}<span className="color-red">*</span></p>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12 value">
                <TextNumberInput className="form-control center" value={data.width == null ? '' : data.width} onChange={val => this.onChange('width', val)} />
                <span className="menium inch">{I18n.t('PageYield.inch')}</span>
                <div className="text-danger size-12">{errors && errors.width && I18n.t(errors.width)}</div>
              </div>
            </div>
          )}
          <div className="col-md-2 col-sm-6 col-xs-12 text right">
            <p>{I18n.t('PageYield.Unit')}</p>
          </div>
          <div className="col-md-4 col-sm-6 col-xs-12 value">
            <input type="text" className="form-control center" value={unit} onChange={evt => this.onChange('unit', evt.target.value)} placeholder="" disabled />
          </div>
        </div>

        {/* actual yield */}
        <div className="form-group">
          <div className="col-md-2 col-sm-6 col-xs-12 text">
            <p className="w-100">{I18n.t('PageYield.ActualYield')}<span className="color-red">*</span></p>
          </div>
          <div className="col-md-4 col-sm-6 col-xs-12 value">
              <input type="number" className="form-control center" value={data.actualYield == null ? '' : data.actualYield} onChange={evt => this.onChange('actualYield', evt.currentTarget.value)} />
            {/*<TextNumberInput className="form-control center" value={data.actualYield || ''} onChange={val => this.onChange('actualYield', val)} />*/}
            <div className="text-danger size-12">{errors && errors.actualYield && I18n.t(errors.actualYield)}</div>
          </div>
          <div className="col-md-2 col-sm-6 col-xs-12 text right">
            <p>%</p>
          </div>
          <div className="col-md-4 col-sm-6 col-xs-12 value">
              <input type="number" className="form-control center" value={data.actualPercent == null ? '' : data.actualPercent} onChange={evt => this.onChange('actualPercent', evt.currentTarget.value)} />
            {/*<TextNumberInput className="form-control center" value={data.actualPercent || ''} onChange={val => this.onChange('actualPercent', val)} />*/}
            <div className="text-danger size-12">{errors && errors.actualPercent && I18n.t(errors.actualPercent)}</div>
          </div>
        </div>

        <div className="form-group">
          {type === materialTypes.FABRIC && (
            <div>
              <div className="col-md-2 col-sm-6 col-xs-12 text">
                <p>{I18n.t('PageYield.Width')}<span className="color-red">*</span></p>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12 value">
                <TextNumberInput className="form-control center" value={data.actualWidth == null ? '' : data.actualWidth} onChange={val => this.onChange('actualWidth', val)} />
                <span className="menium inch">{I18n.t('PageYield.inch')}</span>
                <div className="text-danger size-12">{errors && errors.actualWidth && I18n.t(errors.actualWidth)}</div>
              </div>
            </div>
          )}
          <div className="col-md-2 col-sm-6 col-xs-12 text right">
            <p>{I18n.t('PageYield.Unit')}</p>
          </div>
          <div className="col-md-4 col-sm-6 col-xs-12 value">
            <input type="text" className="form-control center" value={unit} placeholder="" disabled />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    authState: state.authState
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

EstimateForm.propTypes = {
  authState: PropTypes.object.isRequired,
  onDelete: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(EstimateForm);
