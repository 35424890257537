const C = "EDIT_INVOICE_SUPPLIER_OVERSEA";

export default {
    CHANGE_INVOICE_DETAIL: `${C}_CHANGE_INVOICE_DETAIL`,
    CHANGE_INVOICE_TYPE: `${C}_CHANGE_INVOICE_TYPE`,
    CHANGE_LIST_SUPPLIER: `${C}_CHANGE_LIST_SUPPLIER`,
    CHANGE_SUPPLIER_VALUE: `${C}_CHANGE_SUPPLIER_VALUE`,
    CHANGE_FACTOR_VALUE: `${C}_CHANGE_FACTOR_VALUE`,
    CHANGE_INVOICE_NO_VALUE: `${C}_CHANGE_INVOICE_NO_VALUE`,
    CHANGE_ISSUED_DATE_VALUE: `${C}_CHANGE_ISSUED_DATE_VALUE`,
    CHANGE_LIST_CREDIT_DEBIT: `${C}_CHANGE_LIST_CREDIT_DEBIT`,
    CHANGE_CREDIT_DEBIT_VALUE: `${C}_CHANGE_CREDIT_DEBIT_VALUE`,
    CHANGE_LIST_PURCHASE_DETAIL: `${C}_CHANGE_LIST_PURCHASE_DETAIL`,
    CHANGE_CHECKED_PURCHASE_ORDER: `${C}_CHANGE_CHECKED_PURCHASE_ORDER`,
    CHANGE_DUE_DATE_VALUE: `${C}_CHANGE_DUE_DATE_VALUE`,
    CHANGE_PAY_AMOUNT_VALUE: `${C}_CHANGE_PAY_AMOUNT_VALUE`,
    CHANGE_TRANSACTION_NO_VALUE: `${C}_CHANGE_TRANSACTION_NO_VALUE`,
    CHANGE_PAYMENT_METHOD_VALUE: `${C}_CHANGE_PAYMENT_METHOD_VALUE`,
    CHANGE_PAYMENT_TYPE: `${C}_CHANGE_PAYMENT_TYPE`,
    CHANGE_TOGGLE_ACTIVE: `${C}_CHANGE_TOGGLE_ACTIVE`,
    CHANGE_PURCHASE_INVOICE_NO: `${C}_CHANGE_PURCHASE_INVOICE_NO`,
    ADD_TRANSFER_FILE: `${C}_ADD_TRANSFER_FILE`,
    REMOVE_TRANSFER_FILE: `${C}_REMOVE_TRANSFER_FILE`,
    EMPTY_FILES_IN_RAW_DATA: `${C}_EMPTY_FILES_IN_RAW_DATA`,
    COPY_FILES_TO_RAW_DATA: `${C}_COPY_FILES_TO_RAW_DATA`,
    SUBMIT_FILES: `${C}_SUBMIT_FILES`,
    CREATE_RECEIPT: `${C}_CREATE_RECEIPT`,
    CHANGE_RECEIPT_VALUE: `${C}_CHANGE_RECEIPT_VALUE`,
    SUBMIT_RECEIPT: `${C}_SUBMIT_RECEIPT`,
    EMPTY_RECEIPT: `${C}_EMPTY_RECEIPT`,
    SAVE_SUCCESS: `${C}_SAVE_SUCCESS`,
    RESET_DATA_ALL: `${C}_RESET_DATA_ALL`,
    ACTIVE_SCREEN_STATUS: `${C}_ACTIVE_SCREEN_STATUS`,
}