
import Base from '../api/base';
import Urls from '../constants/apiUrls';
import * as R from 'ramda';
import _ from 'lodash';

const BASE_URL = `${Urls.baseUrl}/erp/api/accounting/receivable-invoice`;

class Service {
  constructor() {
    this.api = new Base();
  }

  executeApi(url, method, body) {
    return this.api.execute(url, method, body).then(res => {
      if (R.path(['error'], res)) throw R.path(['error'], res);
      return res;
    });
  }

  /**
   * List customers that sorted by the longest that the INV haven't created
   * @returns 
      [{
        "_id": "5a5d6a3a8932a5a24225b79c",
        "name": "Customer 1"
      }]
   */
  listCustomers() {
    const url = `${BASE_URL}/list-customers`;
    return this.executeApi(url, this.api.ApiMethod.get);
  }

  /**
   * List bills (shipMode=Air) or conts (shipMode=Sea) by specify customer
   * @param {string} shipMode Ship mode name must be in (Air|Sea) @requires
   * @param {string} customer Customer name @requires
   * @param {bool} chargeBack charge back @optional
   * @returns [{
   *   "_id": "5bb33f28cd25660016a46f85",
   *   "billNo": "32523"
   * }]
   * 
   * OR
   * 
   * [{
   *   "_id": "",
   *   "cont": ""
   * }]
   */
  listBillsOrContsByCustomer(shipMode, customer, chargeBack = false) {
    const url = `${BASE_URL}/list-billsconts`;
    return this.executeApi(url, this.api.ApiMethod.post, { shipMode, customer, chargeBack });
  }

  /**
   * List receivable invoices, if history provided, then list history invoices only
   * @param {pageSize: string, pageNumber: string, history: bool} query 
   * 
   */
  listInvoices(query = { pageSize: 15, pageNumber: 1, history: false }) {
    const url = `${BASE_URL}/list-invoices`;
    return this.executeApi(url, this.api.ApiMethod.get, query || {});
  }

  /**
   * Create receivable invoice
   * @param {*} body @requires
   */
  createInvoice(body) {
    const url = `${BASE_URL}/create-invoice`;
    return this.executeApi(url, this.api.ApiMethod.post, body);
  }

  /**
   * Create receivable invoice
   * @param {*} body @requires
   */
  getListShipOutByCustomer(body) {
    const url = `${BASE_URL}/list-shipouts`;
    return this.executeApi(url, this.api.ApiMethod.post, body);
  }

  /**
   * Create receivable invoice
   * @param {*} body @requires
   */
  createINVRequest(body) {
    const url = `${BASE_URL}/create-invoice-request`;
    return this.executeApi(url, this.api.ApiMethod.post, body);
  }

  /**
   * Get amount/paid invoice list
   * @param {*} body @requires
   */
  getAmountInvoice(params = {}) {
    const url = `${BASE_URL}/amount-invoice`;
    return this.executeApi(url, this.api.ApiMethod.get, params);
  }

  /**
   * Get amount/paid invoice list
   * @param {*} body @requires
   */
  deleteInvoice(id) {
    const url = `${BASE_URL}/${id}`;
    return this.executeApi(url, this.api.ApiMethod.delete);
  }

  /**
   * Get detail invoice
   * @param {*} body @requires
   */
  getDetailInvoice(id) {
    const url = `${BASE_URL}/detail`;
    return this.executeApi(url, this.api.ApiMethod.post, { id });
  }
    /**
   * Update invoice
   * @param {*} body @requires
   */
  updateInvoice(id, body) {
    if (body.receiveType == 'Transfer' && body.receiveFiles && body.receiveFiles.length > 0) {
      let promises = (body.receiveFiles || []).map(i => {
      return i.file
        ? this.api.uploadFile(i.file).then(res => _.get(res || {}, "data.filePath", ""))
        : Promise.resolve(i);
      });
      return Promise.all(promises)
        .then(files => {
          body.receiveFiles = (files || []).filter(i => !!i);
          const url = `${BASE_URL}/${id}`;
          return this.executeApi(url, this.api.ApiMethod.put, body);
        })
        .catch(error => {
          throw error;
        });
    } else {
      const url = `${BASE_URL}/${id}`;
      return this.executeApi(url, this.api.ApiMethod.put, body);
    }
  }
  
}

export default new Service();
