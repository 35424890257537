import { combineReducers } from 'redux';
import moment from 'moment';
import C from '../../../constants/accounting/receivable/createInvoiceChargeback';
/**
 * Store status of feching selectable customers from api
 */
const fetchingSelectableCustomers = (state = false, action) => {
  const { payload, type } = action;
  switch (type) {
    // TODO. handle related actions
    default:
      return state;
  }
}

/**
 * Store a list of selectable customers
 */
const selectableCustomers = (state = [], action) => {
  const { payload, type } = action;
  switch (type) {
    case C.CHANGE_LIST_CUSTOMER:
      return payload;
    case C.RESET_DATA:
      return [];
    // TODO. handle related actions
    default:
      return state;
  }
}

/**
 * Store selected customer
 */
const selectedCustomer = (state = null, action) => {
  const { payload, type } = action;
  switch (type) {
    case C.CHANGE_SELECTED_CUSTOMER:
      return payload;
    case C.RESET_DATA:
      return [];
    // TODO. handle related actions
    default:
      return state;
  }
}

/**
 * Store issued date, default is now
 */
const issuedDate = (state = moment(), action) => {
  const { payload, type } = action;
  switch (type) {
		case C.CHANGE_ISSUED_DATE:
			return payload;
		case C.RESET_DATA:
			return moment();
    // TODO. handle related actions
    default:
      return state;
  }
}

/**
 * Store a list of checkable bill
 */
const checkableBillList = (state = [], action) => {
  const { payload, type } = action;
  const copyState = _.cloneDeep(state);
  switch (type) {
    case C.CHANGE_LIST_BILL:
      return payload;
    case C.CHANGE_SELECTED_BILL:
      let i = _.findIndex(copyState, (el) => el._id == payload.id);
      if(i >= 0) {
        copyState[i].checked = payload.checked;
      }
      return copyState;
    case C.RESET_DATA:
      return [];
    // TODO. handle related actions
    default:
      return state;
  }
}

/**
 * Store a list of checked bill 
 */
const checkedBillList = (state = [], action) => {
  const { payload, type } = action;
  let copyState = _.cloneDeep(state);
  let index;
  switch (type) {
    case C.CHANGE_SELECTED_BILL:
      if(payload.checked) {
        copyState.push(payload);
      } else {
        _.remove(copyState, (el) => el.id == payload.id);
      }
      return copyState;
    case C.MAP_DATA_ON_BILL:
      _.map(copyState, el => {
        let billProp = el.billNo;
        if(payload[billProp]) {
          el.customerPOs = [];
          let keys = Object.keys(payload[billProp])
          // if(keys & keys.length) {
            _.forEach(keys, (property) => {
              el.customerPOs.push({
                customerPO: property,
                data: payload[billProp][property] || []
              })
            })
          // }
        }
      })
      return copyState;
    case C.CHANGE_RATE_ITEM:
      index = _.findIndex(copyState, (el) => el.billNo == payload.billNo);
      if(index >= 0) {
        let i = _.findIndex(copyState[index].customerPOs, (item) => item.customerPO == payload.customerPO)
        let k = payload.itemKey;
        if(i >= 0) copyState[index].customerPOs[i].data[k].rate = payload.value;
      }
      return copyState;
    case C.CHANGE_FEE_I_LOGICX_IN_USA_ITEM:
      index = _.findIndex(copyState, (el) => el.billNo == payload.billNo);
      if(index >= 0) {
        let i = _.findIndex(copyState[index].customerPOs, (item) => item.customerPO == payload.customerPO)
        let k = payload.itemKey;
        if(i >= 0) copyState[index].customerPOs[i].data[k].feeInUsa = payload.value;
      }
      return copyState;
    case C.CHANGE_DEDUCT_ITEM:
      index = _.findIndex(copyState, (el) => el.billNo == payload.billNo);
      if(index >= 0) {
        let i = _.findIndex(copyState[index].customerPOs, (item) => item.customerPO == payload.customerPO)
        let k = payload.itemKey;
        if(i >= 0) copyState[index].customerPOs[i].data[k].deduct = payload.value;
      }
      return copyState;
    case C.CHANGE_PAYER_ITEM:
      index = _.findIndex(copyState, (el) => el.billNo == payload.billNo);
      if(index >= 0) {
        let i = _.findIndex(copyState[index].customerPOs, (item) => item.customerPO == payload.customerPO)
        let k = payload.itemKey;
        if(i >= 0) copyState[index].customerPOs[i].data[k].payer = payload.value;
      }
      return copyState;
    case C.CHANGE_RATE_LM_ITEM:
      index = _.findIndex(copyState, (el) => el.billNo == payload.billNo);
      if(index >= 0) {
        let i = _.findIndex(copyState[index].customerPOs, (item) => item.customerPO == payload.customerPO)
        let k = payload.itemKey;
        if(i >= 0) copyState[index].customerPOs[i].data[k].rateLM = payload.value;
      }
      return copyState;
    case C.CREATE_NO_ITEM:
      index = _.findIndex(copyState, (el) => el.billNo == payload.billNo);
      if(index >= 0) {
        let i = _.findIndex(copyState[index].customerPOs, (item) => item.customerPO == payload.customerPO)
        let k = payload.itemKey;
        if(i >= 0) copyState[index].customerPOs[i].data[k].no = payload.value;
      }
      return copyState;
    case C.CHANGE_REASON_ORDER:
      index = _.findIndex(copyState, (el) => el.billNo == payload.billNo);
      if(index >= 0) {
        let i = _.findIndex(copyState[index].customerPOs, (item) => item.customerPO == payload.customerPO)
        if(i >= 0) copyState[index].customerPOs[i].reason = payload.value;
      }
      return copyState;
    case C.CHANGE_NOTE_ORDER:
      index = _.findIndex(copyState, (el) => el.billNo == payload.billNo);
      if(index >= 0) {
        let i = _.findIndex(copyState[index].customerPOs, (item) => item.customerPO == payload.customerPO)
        if(i >= 0) copyState[index].customerPOs[i].note = payload.value;
      }
      return copyState;
    case C.RESET_SELECTED_BILL:
      return [];
    case C.RESET_DATA:
      return [];
    // TODO. handle related actions
    default:
      return state;
  }
}


/**
 * Store status of search text
 */
const searchText = (state = null, action) => {
  const { payload, type } = action;
  switch (type) {
    case C.CHANGE_SEARCH_TEXT:
			return payload;
		case C.RESET_DATA:
      return null;
    // TODO. handle related actions
    default:
      return state;
  }
}

const success = (state = false, action) => {
  const {type, payload} = action;
  switch(type) {
        case C.SAVE_SUCCESS:
						return payload;
				case C.RESET_DATA:
						return false;
        case C.RESET_DATA_ALL:
            return false;
        default:
            return state;
  }
}

// const invoiceNo = (state = null, action) => {
//   const {type, payload} = action;
//   switch(type) {
//         case C.CHANGE_INVOICE_NO:
//             return payload;
//         case C.RESET_DATA:
// 						return null;
// 				case C.RESET_DATA:
// 						return null;
//         default:
//             return state;
//   }
// }

const countNo = (state = null, action) => {
  const {type, payload} = action;
  switch(type) {
    case C.CHANGE_COUNT_NO_ITEM:
        return payload;
    case C.RESET_DATA_ALL:
        return null;
		case C.RESET_DATA:
        return null;
    default:
        return state;
  }
}

const availableNoList = (state = [], action) => {
  const {type, payload} = action;
  switch(type) {
    case C.CHANGE_AVAILABLE_NO_LIST:
        return payload;
    case C.RESET_DATA_ALL:
        return [];
		case C.RESET_DATA:
        return [];
    default:
        return state;
  }
}

export const createReceivableInvoiceChargeback = combineReducers({
  fetchingSelectableCustomers,
  selectableCustomers,
  selectedCustomer,

  issuedDate,

  checkableBillList,
  checkedBillList,

  searchText,
  success,
  // invoiceNo,
  countNo,
  availableNoList
});

