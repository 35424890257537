import Base from './base';

class Contractor extends Base {
  constructor () {
    super();

    this.getListItemDAL = this.getListItemDAL.bind(this);
    this.addItemDAL = this.addItemDAL.bind(this);
    this.editItemDAL = this.editItemDAL.bind(this);
    this.getItemDAL = this.getItemDAL.bind(this);
    this.deleteItemDAL = this.deleteItemDAL.bind(this);
  }

  getListItemDAL (query) {
    return super.execute(this.Urls.contractor.list, this.ApiMethod.get, query);
  }

  addItemDAL (body) {
    return super.execute(this.Urls.contractor.create, this.ApiMethod.post, body);
  }

  editItemDAL (id, body) {
    return super.execute(this.Urls.contractor.update + '/' + id, this.ApiMethod.put, body);
  }

  getItemDAL (id, options) {
    return super.execute(this.Urls.contractor.detail + '/' + id, this.ApiMethod.get, options);
  }

  deleteItemDAL (id) {
    return super.execute(this.Urls.contractor.delete + '/' + id, this.ApiMethod.delete, null);
  }
}

export default new Contractor();
