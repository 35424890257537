import C from '../../../constants/customer-po/current/editCutTicketColors';
import ORDER_DETAIL from '../../../constants/customer-po/current/showOrderDetail';
import commonApi from '../../../api/commonApi';
import Urls from '../../../constants/apiUrls';
import { toastr } from 'react-redux-toastr';
import * as R from 'ramda';
import _ from 'lodash';
import I18n from 'i18n-js';
import cutTicketServices from '../../../services/cutTicketServices';
import orderServices from '../../../services/orderServices';
import standardServices from '../../../services/standardServices';
import { listCutTickets } from './showOrderDetail';

export const showModal = cutTicket => dispatch => {
  dispatch({ type: C.SHOW_MODAL, payload: cutTicket });
}

export const hideModal = () => dispatch => {
  dispatch({ type: C.RESET_DATA });
}

export const addColor = () => dispatch => {
  dispatch({ type: C.ADD_COLOR });
}

export const changeColorName = (id, value) => dispatch => {
  dispatch({ type: C.CHANGE_COLOR_NAME, payload: {id, value}});
}

export const removeColor = (id) => dispatch => {
  dispatch({ type: C.REMOVE_COLOR, payload: id });
}

export const addSize = (colorId, value) => dispatch => {
  dispatch({ type: C.ADD_SIZE, payload: {colorId, value: {name: value, quantity: 0 }}});
}

export const removeSize = (colorId, sizeName) => dispatch => {
  dispatch({ type: C.REMOVE_SIZE, payload: {colorId, sizeName}});
}

export const changeSizeQuantity = (colorId, sizeName, value) => dispatch => {
  dispatch({ type: C.CHANGE_SIZE_QUANTITY, payload: {colorId, sizeName, value}});
}

export const listSuggestions = () => dispatch => {
  dispatch({ type: C.FETCH_SUGGESTIONS });
  commonApi.getDAL(Urls.cutTicket.sugestColors).then(res => {
    dispatch({ type: C.CANCEL_FETCH_SUGGESTIONS });
    dispatch({ type: C.CHANGE_SUGGESTIONS, payload: R.path(['data'], res)});
  }).catch(errors => {
    dispatch({ type: C.CANCEL_FETCH_SUGGESTIONS });
    console.log(errors);
  })
}

export const listSelectableSizes = () => dispatch => {
  dispatch({ type: C.FETCH_SELECTABLE_SIZES });
  standardServices.getStandard().then((res) => {
    dispatch({ type: C.CANCEL_FETCH_SELECTABLE_SIZES });
    const config = _.head(R.pathOr([], ['data', 'items'], res));
    const payload = ['Default', ...R.pathOr([], ['garmentSize'], config)];
    dispatch({ type: C.CHANGE_SELECTABLE_SIZES, payload });
  }).catch(error => {
    dispatch({ type: C.CANCEL_FETCH_SELECTABLE_SIZES });
    console.error(error);
  });
};

export const save = (id, body, orderId) => dispatch => {
  dispatch({ type: C.SUBMIT });
  // Validate dupplicate colors name
  const colors = _.get(body, 'colors', []);
  let isDuplicate = false;
  for(let i = 0; i < colors.length - 1; i++) {
    for(let j = i + 1; j < colors.length; j++) {
      if (colors[i].name === colors[j].name) {
        isDuplicate = true;
      }
    }
  }
  if(isDuplicate) {
    toastr.error(I18n.t('Error'), I18n.t('ErrorMessages.CustomerPO.ColorsNameWereDuplicated'));
    dispatch({ type: C.CANCEL_SUBMIT });
    return;
  }

  cutTicketServices.editColorCutTicket(id, body).then((res) => {
    dispatch({ type: C.RESET_DATA });
    dispatch(listCutTickets(R.path(['data', 'cutTicket', 'styleId'], res)));

    if (orderId) {
      orderServices.getDetail(orderId, {}).then(res => {
        const order = R.path(['data'], res);
        dispatch({ type: ORDER_DETAIL.CHANGE_ORDER_DETAIL, payload: order });
      })
    }

    if(R.path(['data', 'show'], res)) {
      const ms = I18n.t('GoToYield').replace('<name>', _.get(res, 'data.cutTicket.cutTicket', ''))
      toastr.info('Info', ms);
    }
  }).catch(error => {
    dispatch({ type: C.CANCEL_SUBMIT });
    console.log(error);
  });
}
