import React, { Component } from 'react';

export default class Status extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const { value } = this.props;
		let { statusList } = this.props.options;
		statusList = statusList ? statusList : [];
		let status = statusList.find((item) => { return item.data == value; });
		status = status ? status : {};

		return (
			<td className={status.class}>{status.text}</td>
		);
	}
}