
import { combineReducers } from 'redux';
import moment from 'moment';
import C from '../../../constants/customer-po/current/createCutTicket';

const style = (state = null, action) => {
  const { type, payload } = action;
  switch (type) {
    case C.SHOW_MODAL:
      return payload;
    default:
      return state;
  }
};

const cutTicketName = (state = '', action) => {
  const { type, payload } = action;
  switch (type) {
    case C.CHANGE_CUT_TICKET_NAME:
      return payload;
    default:
      return state;
  }
};

const selectableStores = (state = [], action) => {
  const { type, payload } = action;
  switch (type) {
    case C.CHANGE_SELECTABLE_STORES:
      return payload;
    default:
      return state;
  }
};

const selectedStore = (state = null, action) => {
  const { type, payload } = action;
  switch (type) {
    case C.CHANGE_SELECTED_STORE:
      return payload;
    default:
      return state;
  }
};

const selectedWarehouse = (state = null, action) => {
  const { type, payload } = action;
  switch (type) {
    case C.CHANGE_SELECTED_WAREHOUSE:
      return payload;
    default:
      return state;
  }
};

const selectableShipModes = (state = [], action) => {
  const { type, payload } = action;
  switch (type) {
    case C.CHANGE_SELECTABLE_SHIP_MODES:
      return payload;
    default:
      return state;
  }
}

const selectedShipMode = (state = null, action) => {
  const { type, payload } = action;
  switch (type) {
    case C.CHANGE_SELECTED_SHIP_MODE:
      return payload;
    default:
      return state;
  }
}

const mainLabel = (state = '', action) => {
  const { type, payload } = action;
  switch (type) {
    case C.CHANGE_MAIN_LABEL:
      return payload;
    default:
      return state;
  }
}

const ihDate = (state = moment().format(), action) => {
  const { type, payload } = action;
  switch (type) {
    case C.CHANGE_IH_DATE:
      return payload;
    default:
      return state;
  }
}

const note = (state = '', action) => {
  const { type, payload } = action;
  switch (type) {
    case C.CHANGE_NOTE:
      return payload;
    default:
      return state;
  }
}

const errors = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case C.CHANGE_CUT_TICKET_NAME:
      return {...state, 'cutTicket': !payload ? 'Please input cut ticket name' : null };
    case C.CHANGE_IH_DATE:
      return { ...state, 'ihDate': !payload ? 'Please input ih date': null};
    case C.CHANGE_SELECTED_SHIP_MODE:
      return { ...state, 'selectedShipMode': !payload ? 'Please select ship mode': null};
    case C.CHANGE_SELECTED_WAREHOUSE:
      return {...state, 'selectedWarehouse': !payload ? 'Please select warehouse': null};
    default:
      return state;
  }
}

const submiting = (state = false, action) => {
  const { type } = action;
  switch (type) {
    case C.SUBMIT:
      return true;
    case C.CANCEL_SUBMIT:
      return false;
    default:
      return state;
  }
}

const combined = combineReducers({
  style,
  cutTicketName,
  selectableStores,
  selectedStore,

  selectedWarehouse,

  selectableShipModes,
  selectedShipMode,

  mainLabel,
  ihDate,
  note,

  errors,
  submiting,
});


export default (state, action) => {
  const { type } = action;
  if (type === C.RESET_DATA) {
    return {};
  }
  return combined(state, action);
};

