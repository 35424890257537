import {STOCK_OUT} from '../constants/actionTypes';
import initial from './initialState';

export default function (state = initial.stockOutState, action) {
  switch (action.type) {
    case STOCK_OUT.GET_LIST_SUCCESS:
      return {...state, items: action.data.items || [], count: action.data.count || 0};
    case STOCK_OUT.RELOAD:
      return {...state, reload: !state.reload};
    case STOCK_OUT.GET_DETAIL_SUCCESS:
      return {...state, detail: action.data};
    case STOCK_OUT.RESET_DETAIL:
      return {...state, detail: {}};
    case STOCK_OUT.SET_MATERIALS:
      return {...state, materials: action.data.items || [], count: action.data.count || 0};
    default:
      return state;
  }
}
