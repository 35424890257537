import * as types from '../constants/actionTypes';
import {showLoading, hideLoading} from './commonUseActions';
import storeServices from '../services/storeServices';

function getListStore (options = {}) {
  return (dispatch) => {
    showLoading(types, dispatch);

    return storeServices.getListStore(options).then((res) => {
      hideLoading(types, dispatch);

      dispatch({
        type: types.STORE.GET_LIST_SUCCESS,
        data: res.data || []
      });
      return res;
    }, (error) => {
      hideLoading(types, dispatch);
      return error;
    });
  };
}

function getDetail (id) {
  return (dispatch) => {
    showLoading(types, dispatch);

    dispatch({
      type: types.STORE.RESET_DETAIL,
      data: {}
    });

    storeServices.getDetail(id, {}).then((res) => {
      hideLoading(types, dispatch);

      if (res) {
        dispatch({
          type: types.STORE.GET_DETAIL_SUCCESS,
          data: res.data || {}
        });
      }
    }, (error) => {
      hideLoading(types, dispatch);
    });
  };
}

function deleteStore (id) {
  return (dispatch) => {
    showLoading(types, dispatch);

    storeServices.deleteStore(id).then((res) => {
      hideLoading(types, dispatch);

      if (res) {
        dispatch({
          type: types.STORE.RELOAD,
          data: true
        });
      }
    }, (error) => {
      hideLoading(types, dispatch, error);
    });
  };
}

function editStore (id, data) {
  return (dispatch) => {
    showLoading(types, dispatch);

    return storeServices.editStore(id, data).then((res) => {
      hideLoading(types, dispatch);

      if (res) {
        dispatch({
          type: types.STORE.RELOAD,
          data: true
        });
      }
      return {success: true};
    }, (error) => {
      hideLoading(types, dispatch);
      return error;
    });
  };
}

function addStore (data) {
  return (dispatch) => {
    showLoading(types, dispatch);

    return storeServices.addStore(data).then((res) => {
      hideLoading(types, dispatch);

      if (res) {
        dispatch({
          type: types.STORE.RELOAD,
          data: true
        });
      }
      return {success: true};
    }, (error) => {
      hideLoading(types, dispatch);
      return error;
    });
  };
}

export default {
  getListStore,
  deleteStore,
  editStore,
  addStore,
  getDetail
};
