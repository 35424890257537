import C from '../../../constants/accounting/payable/createInvoiceImpExpUSDelivery';
import accountingApi from '../../../api/accountingApi';

export const getListCompany = () => dispatch => {
    accountingApi.getListCompany().then(res => {
        if(res.success) {
            dispatch({type: C.CHANGE_LIST_COMPANY, payload: res.data.items})
            // dispatch({type: C.CHANGE_SELECTED_COMPANY, payload: res.data.items[0].company})
            // dispatch(getListBill(res.data.items[0].company))
        }
    })
}


export const changeSelectedCompany = (value) => dispatch => {
    dispatch({ type: C.CHANGE_SELECTED_COMPANY, payload: value})
    dispatch(getListBill(value))
    dispatch({type: C.RESET_CHECKED_LIST, payload: []})
}

export const getListBill = (value) => dispatch => {
    const query = {company: value};
    accountingApi.getListBill(query).then(res => {
        if(res.success) {
            dispatch({ type: C.CHANGE_LIST_BILL, payload: res.data.items})
        }
    })
}

export const changeInvoiceNo = (value) => dispatch => {
    dispatch({ type: C.CHANGE_INVOICE_NO, payload: value})
}

export const changeInvoiceDate = (value) => dispatch => {
    dispatch({ type: C.CHANGE_INVOICE_DATE, payload: value})
}
export const changeSearchText = value => dispatch => {
    dispatch({ type: C.CHANGE_SEARCH_TEXT, payload: value });
};
export const changeNote = (value) => dispatch => {
    dispatch({type: C.CHANGE_NOTE, payload: value})
}

export const changeCheckedDeliveryBill = (_id, checked) => dispatch => {
    dispatch({ type: C.CHANGE_CHECKED_LIST, payload: {_id, checked}})
}

export const changeBillDetailValue = (_id, key, value) => dispatch => {
    dispatch({type: C.CHANGE_BILL_DETAIL_VALUE, payload: {_id, key, value}})
}

export const copyDataToTemp = (data) => dispatch => {
    dispatch({type: C.COPY_DATA_TO_TEMP, payload: data})
}

export const changeContainerDetailValue = (key, value) => dispatch => {
    dispatch({ type: C.CHANGE_CONTAINER_DETAIL_VALUE, payload: {key, value}})
}

export const submit = (data) => dispatch => {
    dispatch({type: C.SUBMIT_EDIT_DATA, payload: {data}})
    dispatch({type: C.RESET_TEMP_DATA})
}

export const resetTempData = () => dispatch => {
    dispatch({type: C.RESET_TEMP_DATA})
}

export const createInvoice = (data) => dispatch => {
    accountingApi.createInvoice(data).then(res => {
        if(res.success) {
            dispatch({type: C.CREATE_INVOICE_SUCCESS, payload: true})
        }
    })
}

export const resetData = () => dispatch => {
    dispatch({type: C.RESET_DATA})
}

export const activeScreenPreview = () => dispatch => {
    dispatch({type: C.ACTIVE_SCREEN_STATUS, payload: true})
}

export const toggleActivePurchaseOrder = (id, value) => dispatch => {
    dispatch({type: C.CHANGE_TOGGLE_ACTIVE, payload: {id, value}})
}

export const changeDueDate = (value) => dispatch => {
    dispatch({type: C.CHANGE_DUE_DATE_VALUE, payload: value})
}