import {CUSTOMER} from '../constants/actionTypes';
import initial from './initialState';

export default function (state = initial.customerState, action) {
  switch (action.type) {
    case CUSTOMER.GET_LIST_SUCCESS:
      return {...state, items: action.data.items || [], count: action.data.count || 0};
    case CUSTOMER.RELOAD:
      return {...state, reload: !state.reload};
    case CUSTOMER.GET_DETAIL_SUCCESS:
      return {...state, detail: action.data};
    case CUSTOMER.RESET_DETAIL:
      return {...state, detail: {}};
    case CUSTOMER.DELETE_SUCCESS:
      return {...state, deleteSuccessStatus: action.data}
    default:
      return state;
  }
}
