import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import I18n from 'i18n-js';
import Avatar from '../fields/Avatar';
import './style.scss';
import { ACTION_TYPE } from '../../../constants/constants';
import { getComponentByType, pathImage } from '../../../functions/helpers';
import numeral from 'numeral';

import restoreIcon from '../../../stylesheets/images/restore.png';
import icEyeIcon from '../../../stylesheets/images/ic-eye.svg';
import icEditXIcon from '../../../stylesheets/images/ic-edit-x.svg';
import icXIcon from '../../../stylesheets/images/ic-x.svg';
import xlxsIcon from '../../../stylesheets/images/xlxs.svg';
import icCreateINVIcon from '../../../stylesheets/images/ic-createINV.svg';

class TableList extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {};
  }

  convertValue(value, type, format = '', defaultValue, func = null) {
    let convert = value;
    switch (type) {
      case 'date':
        // convert = value ? moment(value).format(format) : '';
        var offset = new Date().getTimezoneOffset();
        var date = new moment(value).utcOffset(-1 * offset);
        date = date.format(format || 'MM/DD/YYYY');
        convert = value ? date : '';

        break;
      case 'amount':
        convert = numeral(value).format('0,0.00');
        break;
      case 'price':
        convert = { text: '$' + new Intl.NumberFormat('en-US').format(parseInt(value) || 0), cls: 'color-number' };
        break;
      case 'join':
        convert = _.join(value, ', ');
        break;
      case 'avatar':
        convert = <Avatar avatarUrl={value} defaultText={defaultValue} />;
        break;
      case 'checkbox':
        convert = value || false;
        break;
      case 'image':
        convert = (
          <div className="img">
            <img src={pathImage(value)}></img>
          </div>
        );
        break;
      case 'gallery': {
        if (func) {
          convert = (
            <div className="img" style={{ cursor: 'pointer' }} onClick={func}>
              <img src={pathImage(value)}></img>
            </div>
          );
        } else {
          convert = (
            <div className="img">
              <img src={pathImage(value)}></img>
            </div>
          );
        }
        break;
      }
      case 'dropdownstyle': {
        const filteredStyles = (value || []).reduce((elements, style) => {
          const cutTickets = _.get(style, 'cutTickets', []);
          return !cutTickets.length ? elements : [...elements, style];
        }, []);
        convert = (
          <div className="dropdown">
            <span
              className="dropdown-toggle button-text color-link"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false">
              {filteredStyles.length}
            </span>
            <div className="dropdown-menu">
              <table className="dropdown-table">
                <tbody>
                  {filteredStyles.map((i, index) => (
                    <tr key={`dropstyle_${_.get(i, '_id', index)}`}>
                      <td>{_.get(i, 'style.style', '-')}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <span className="button-ok center color-orange bold cursor-pointer">OK</span>
            </div>
          </div>
        );
        break;
      }
      case 'dropdowncutticket': {
        const numbCutTickets = (value || []).reduce((acc, s) => acc + (s.cutTickets || []).length, 0);
        convert = (
          <div className="dropdown">
            <span
              className="dropdown-toggle button-text color-link"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false">
              {numbCutTickets}
            </span>
            <div className="dropdown-menu">
              {(value || []).map((item, index) => {
                const cutTickets = _.get(item, 'cutTickets', []);

                return (
                  <table
                    className={`dropdown-table-ticket ${value && value.length - 1 === index && 'last-table'}`}
                    key={`dropticket_${_.get(item, '_id', index)}`}>
                    <thead>
                      <tr>
                        <th>Style</th>
                        <th>{_.get(item, 'style.style', '-')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {cutTickets.map((ticket, index) => (
                        <tr key={`chilticket_${_.get(ticket, '_id', index)}`}>
                          <td colSpan={2}>{_.get(ticket, 'cutTicket', '-')}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                );
              })}
              <span className="button-ok center color-orange bold cursor-pointer">OK</span>
            </div>
          </div>
        );
        break;
      }
    }
    return convert;
  }

  getIcon(action = {}) {
    let icon = null;
    switch (action.type) {
      case ACTION_TYPE.RESTORE:
        icon = restoreIcon;
        break;
      case ACTION_TYPE.VIEW:
        icon = icEyeIcon;
        break;
      case ACTION_TYPE.EDIT:
        icon = icEditXIcon;
        break;
      case ACTION_TYPE.DELETE:
        icon = icXIcon;
        break;
      case ACTION_TYPE.EXPORT:
        icon = xlxsIcon;
        break;
      case ACTION_TYPE.CREATEINV:
        icon = icCreateINVIcon;
        break;
      default:
        break;
    }
    return icon;
  }

  render() {
    const { columns, actions, items, options, widthAction, parentData, tId } = this.props;
    return (
      <table style={{ width: '100%' }} className={`${_.get(options || {}, 'clsTbl') || ''} global-table`} id={tId}>
        <tbody>
          <tr>
            {(columns || []).map((c, idx) => (
              <th key={idx} className={_.get(c, 'options.clsTh', '')}>
                {c.name === 'ER Date'
                  ? I18n.t('PageShiping.Expected') + ' ' + I18n.t('PageShiping.ReceivedDate')
                  : c.name}
              </th>
            ))}
            {(actions || []).length > 0 && (
              <th className={widthAction ? widthAction : 'actions'}>{I18n.t('Actions')}</th>
            )}
          </tr>
          {items.map((i, idx) => {
            let currency = '';
            if (i.supplier) {
              i.supplier.currency == 'USD' ? (currency = 'USD') : (currency = 'VND');
            }

            return (
              <tr key={idx}>
                {(columns || []).map((c, idc) => {
                  let value = _.isFunction(c.getValue) ? c.getValue(i) : _.get(i, c.value, '');
                  let defaultValue = _.get(i, c.default, '');
                  value = this.convertValue(
                    value,
                    c.type || '',
                    c.formatType || '',
                    defaultValue,
                    _.isFunction(c.onClick) && (() => c.onClick(i))
                  );
                  if (i.supplier && c.type == 'amount') {
                    value = value + ' ' + currency;
                  }
                  // disabled == true when checkable == true
                  let disabled = _.get(i, c.checkable, false);

                  let element = getComponentByType(i, c, c.type, value, defaultValue, idc, disabled);
                  // if(_.isObject(value)) {
                  //   element = (<td key={idx} className={`${_.get(c, 'options.clsTd', '')} ${value.cls || ''}`}>{value}</td>);
                  // }
                  return element;
                })}

                {(actions || []).length > 0 && (
                  <td className="actions center">
                    {(actions || []).map((f, idx) => {
                      let action = _.isFunction(f) ? f(i) : null;
                      if (action.type == 'delete' && action.check && i[action.check] == action.value) {
                        return;
                      }
                      if (action.type == 'edit' && action.check && i[action.check] == action.value) {
                        action.type = ACTION_TYPE.VIEW;
                      }
                      let icon = action ? this.getIcon(action) : null;

                      return (
                        <span
                          key={idx}
                          className="cursor-pointer"
                          data-toggle="tooltip"
                          data-placement="bottom"
                          title={action.title || action.type}
                          onClick={() => {
                            _.isFunction(action.click) && action.click(i, parentData);
                          }}>
                          <a>{!!icon && <img src={icon} alt="" width="14px" />}</a>
                        </span>
                      );
                    })}
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    authState: state.authState,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

TableList.propTypes = {
  authState: PropTypes.object.isRequired,
};

TableList.defaultProps = {
  tId: 'my-table',
};

export default connect(mapStateToProps, mapDispatchToProps)(TableList);
