import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import BaseComponent from '../../base/BaseComponent';
import I18n from 'i18n-js';
import shippingActions from '../../../actions/shippingActions';
import moment from 'moment';
import appActions from '../../../actions/appActions';
import {SHIPPING_SCREEN} from "../../../constants/constants";
import _ from 'lodash'
import {Pagination} from "react-bootstrap";
import TableList from '../../common/datatables/TableList';
import SearchBar from '../../common/layout/SearchBar';
import {SHIPPING_SEARCH_KEY, SEARCH_LIST, SEARCH_BAR_EVENT, ACTION_TYPE} from "../../../constants/constants";
import shippingApi from '../../../api/shippingApi';
import * as types from '../../../constants/actionTypes'
import { toastr } from 'react-redux-toastr';
import CustomPagination from '../../common/datatables/CustomPagination';

class TabHistory extends BaseComponent {
  constructor(props, context) {
    super(props, context);
    this.state = ({
      //- using for ship-in list
      items: [],
      notifies: [],
      pageNumber: 1,
      pageSize: 10,
      total: 0,
      columns: [
        {name: I18n.t('PageShiping.Date'), type: 'date', formatType: 'MMM DD, YYYY', value: 'advanceDate'},
        {name: I18n.t('PageShiping.DeclarationNo'), value: 'declarationNo'},
        {name: I18n.t('PageShiping.DeclarationDate'), type: 'date', formatType: 'MMM DD, YYYY', value: 'declarationDate'},
        {name: I18n.t('PageShiping.ShipMode'), value: 'shipMode'},
        {name: I18n.t('PageShiping.BillNo'), value: 'billNo'},
        {name: I18n.t('PageShiping.Cont'), value: 'cont'},
        {name: I18n.t('PageShiping.ETD'), type: 'date', formatType: 'MMM DD, YYYY', value: 'etd'},
        {name: I18n.t('PageShiping.ETA'), type: 'date', formatType: 'MMM DD, YYYY', value: 'eta'},
        {name: I18n.t("PageShiping.TotalFee"), value: 'totalFee', options: {clsTd: 'color-red'}},
        // {name: I18n.t('PageSystemSettings'), type: '', 'dataType': '', value: ''},
      ],
      actions: [],
      //- search
      search: {key: SHIPPING_SEARCH_KEY.DECLARATION_NO, value: '', beginDate: '', endDate: ''}
    });

  }

  componentWillMount()
  {
    //- fire to an action
    this.getList(this.getBaseOption()) 
    return true;
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.appState.page.loadMore){
      this.props.dispatch({
        type: types.APP.SET_PAGE_CURRENT,
        data: {
          isEdit: false,
          tab: '',
          tabChild: 'tabHistory',
          loadMore: false,
        }
      })
      this.getList(this.getBaseOption())
    }
    //this.getList(this.getBaseOption());
    return true;
  }

  getBaseOption = () => {
    const {pageSize, pageNumber} = this.state;

    let options = {
      query: JSON.stringify({isCancel: {$ne: true}, isCompleted : true}),
      sort: JSON.stringify({createdAt:-1}), 
      populate:JSON.stringify([
        {
          path: 'purchaseOrders.purchaseOrder', 
          populate:[
            {
              path: 'styleId',
              select: 'orderId style gTotal',
              populate: {
                path: 'orderId',
                select: 'customerPO gTotal'
              }
            },
            {
              path: 'materials.material',
              select: 'name data.content'
            }
          ] 
        }
      ]),
      pageNumber,
      pageSize
    };
    return options
  }

  resetItems = items => {
    const {pageSize, pageNumber, notifies} = this.state;
    
    return items.map((item, idx) => {
      const idxN = _.findIndex(notifies, n => n._id == item._id)
      let totalFee = '$' + (item.crsPaperWork + item.localCharge + item.truckingIn);
      return {...item, totalFee, no: (pageSize * (pageNumber - 1)) + idx + 1}
    })
  }
  
  getList = options => {
    this.props.dispatch({type: types.APP.SHOW_LOADING});
    shippingApi.getShipInList(options).then(rs => {
      this.props.dispatch({type: types.APP.HIDE_LOADING})
      if(rs.success) {
        this.setState({
          items: this.resetItems(_.get(rs, 'data.items', [])),
          total: _.get(rs,'data.count',0)
        })
      }
      else toastr.error(I18n.t('Notify.Error'), rs.error)
    },
    err => {
      this.props.dispatch({type: types.APP.HIDE_LOADING})
      toastr.error(I18n.t('Notify.Error'), err.error)
    })
  }
  //- history
  selectPage = (page) => {
    this.setState({pageNumber: page});
    this.onSearch(page);
  };

  //- searching
  onChangeSearch = (property, value , pos) => {
    const { search } = this.state;
    // console.log('-----', search);
    switch(property) {
      case SEARCH_BAR_EVENT.FILTER: {
        this.setState({search: {key: value, value: ""}});
        break;
      }
      case SEARCH_BAR_EVENT.SEARCH: {
        if( this.state.search.key ==='date' || this.state.search.key ==='declarationDate' ||
            this.state.search.key ==='etd' || this.state.search.key ==='eta'){
              if( pos === 'from'){
                search.beginDate = value
                this.setState({ search })      
              }
              if( pos === 'to'){
                search.endDate = value
                this.setState({ search })
              }
        }else{
          this.setState({search: {...this.state.search,value : value }});
        }
        break;
      }
    }
  }

  getQueryWithDate = (search) => {
    let qr = {}
    let db = new Date(search.beginDate)
    db.setHours(0,0,0,0)
    let de = new Date(search.endDate)
    de.setHours(23,59,59,999)
    qr = {$gte: db, $lt: de}
    return qr
  }

  onSearch = (page) =>
  {
    const { search } = this.state;
    const options = this.getBaseOption()
    options.query = JSON.parse(options.query)
    options.pageNumber = page != null ? page : options.pageNumber
    if(search && search.key && (search.value || (search.beginDate && search.endDate))) {
      let qr = {}
      let vl = {}
      switch(search.key) {
        case SHIPPING_SEARCH_KEY.DECLARATION_NO: {
          qr = {declarationNo: {$regex: '.*' + search.value + '.*', $options: 'i'}}
          options.query = qr
          break;
        }
        case SHIPPING_SEARCH_KEY.SHIP_MODE: {
          qr = {shipMode: {$regex: '.*' + search.value + '.*', $options: 'i'}}
          options.query = qr
          break;
        }
        case SHIPPING_SEARCH_KEY.BILL_NO: {
          qr = {billNo: {$regex: '.*' + search.value + '.*', $options: 'i'}}
          options.query = qr
          break;
        }
        case SHIPPING_SEARCH_KEY.CONT: {
          qr = {cont: {$regex: '.*' + search.value + '.*', $options: 'i'}}
          options.query = qr
          break;
        }
        case SHIPPING_SEARCH_KEY.DECLARATION_DATE: {
          vl = this.getQueryWithDate(this.state.search)
          qr = {declarationDate: vl}
          options.query = qr
          break;
        }
        case SHIPPING_SEARCH_KEY.ETD: {
          vl = this.getQueryWithDate(this.state.search)
          qr = {etd: vl}
          options.query = qr
          break;
        }
        case SHIPPING_SEARCH_KEY.ETA: {
          vl = this.getQueryWithDate(this.state.search)
          qr = {eta: vl}
          options.query = qr
          break;
        }
        default:
          break;
      }
    }

    this.props.dispatch({type: types.APP.SHOW_LOADING})

    shippingApi.search(search, options, SHIPPING_SEARCH_KEY).then(rs => {
      this.props.dispatch({type: types.APP.HIDE_LOADING})
      let items = [];
      if(rs.success) {
        rs.data.items.map(item => {
          if(item.isCompleted) items.push(item);
        })
        this.setState({
          items: this.resetItems(items),
          total: _.get(rs,'data.count',0)
        })
      }
      else toastr.error(I18n.t('Notify.Error'), rs.error)
    },
    err => {
      this.props.dispatch({type: types.APP.HIDE_LOADING})
      toastr.error(I18n.t('Notify.Error'), err.error)
    })
  }

  viewShipin = (data) => {
    const screen = SHIPPING_SCREEN.VIEW_HISTORY;
    const clonePage = {...this.props.appState.page};
    const pageData = _.assign(_.cloneDeep(clonePage),
      {isEdit : true},
      {
        screen, 
        data
      }
    );
    this.props.appPropsActions.setDataPageCurrent(pageData);
  }
  
  render() {
    let { items, total, columns, pageNumber , pageSize , actions } = this.state;
    let { roles: {showView} } = this.props;
    actions = []
    if(showView){
      actions.push(() => {
        return { type: ACTION_TYPE.VIEW, click: (item) => this.viewShipin(item) };
      })
    };
    const fields = SEARCH_LIST.SHIPIN_SEARCH_LIST;
    let totaPage = _.ceil(total / pageSize);
    let tabPanelClass = this.props.active ? "tab-pane active" : "tab-pane";
    return (
        <div className={tabPanelClass} id="shipping-history">
        <div className="wrap-search-position">
          <SearchBar
                name="Shipping"
                fields={fields}
                onChangeSearch={this.onChangeSearch}
                onSearch={this.onSearch}
            />
        </div>
        <TableList 
          {...this.props} 
          items={items} 
          columns={columns || []} 
          actions={actions || []}
          options={{clsTbl: 'global-table box-shadow-none'}} 
          widthAction="w-70"
        />
        <CustomPagination items={totaPage} activePage={pageNumber} onSelect={this.selectPage} />
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    appState: state.appState,
    authState: state.authState,
    shippingState: state.shippingState
  };
}

function mapDispatchToProps(dispatch) {
  return {
    appPropsActions: bindActionCreators(appActions, dispatch),
    shippingActions: bindActionCreators(shippingActions, dispatch),
    dispatch
  };
}

TabHistory.propTypes = {
  authState: PropTypes.object.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(TabHistory);

