import Base from './base';
import moment from "moment";

class Order extends Base {
  constructor() {
    super()

    this.getListOrderDAL = this.getListOrderDAL.bind(this)
    this.addOrderDAL = this.addOrderDAL.bind(this)
    this.editOrderDAL = this.editOrderDAL.bind(this)
    this.getOrderDAL = this.getOrderDAL.bind(this)
    this.deleteOrderDAL = this.deleteOrderDAL.bind(this)
    this.sendNotifyDAL = this.sendNotifyDAL.bind(this)
    this.getListOrderOnPurchaseDAL = this.getListOrderOnPurchaseDAL.bind(this)
  }

  getListOrderDAL(query) {
    return super.execute(this.Urls.order.list, this.ApiMethod.get, query)
  }

  getListOrderOnPurchaseDAL(query) {
    return super.execute(this.Urls.order.listOrderPurchase, this.ApiMethod.get, query)
  }

  addOrderDAL(body) {
    return super.execute(this.Urls.order.create, this.ApiMethod.post, body)
  }

  editOrderDAL(id, body) {
    return super.execute(
      this.Urls.order.update + '/' + id,
      this.ApiMethod.put,
      body
    )
  }

  getOrderDAL(id, options) {
    return super.execute(
      this.Urls.order.detail + '/' + id,
      this.ApiMethod.get,
      options
    )
  }

  sendNotifyDAL(id) {
    return super.execute(this.Urls.order.sendNotify, this.ApiMethod.post, {
      id: id,
      timeZone: moment().utcOffset()
    })
  }
  deleteOrderDAL(id) {
    return super.execute(
      this.Urls.order.delete + '/' + id,
      this.ApiMethod.delete,
      null
    )
  }

  getListCustomerDAL = () => {
    return super.execute(
      this.Urls.order.getListCustomer,
      this.ApiMethod.get,
      null
    )
  }

  getListContractorDAL = id => {
    return super.execute(
      this.Urls.order.getListContractor + '/' + id + '/contractors',
      this.ApiMethod.get,
      null
    )
  }

  getListOrderForReportDAL = query => {
    return super.execute(
      this.Urls.order.listOrderForReport,
      this.ApiMethod.get,
      query
    )
  }

  updateCompletedStatusOrders(body) {
    return super.execute(
      this.Urls.order.updateCompletedStatusOrders,
      this.ApiMethod.put,
      body
    )
  }
}

export default new Order();
