const C = 'CUSTOMER_PO.CURRENT.CREATE_CUT_TICKET';

export default {
  SHOW_MODAL: `${C}.SHOW_MODAL`,
  HIDE_MODAL: `${C}.HIDE_MODAL`,

  CHANGE_CUT_TICKET_NAME: `${C}.CHANGE_CUT_TICKET_NAME`,

  FETCH_SELECTABLE_STORES: `${C}.FETCH_SELECTABLE_STORES`,
  CANCEL_FETCH_SELECTABLE_STORES: `${C}.CANCEL_FETCH_SELECTABLE_STORES`,
  CHANGE_SELECTABLE_STORES: `${C}.CHANGE_SELECTABLE_STORES`,
  CHANGE_SELECTED_STORE: `${C}.CHANGE_SELECTED_STORE`,

  CHANGE_SELECTED_WAREHOUSE: `${C}.CHANGE_SELECTED_WAREHOUSE`,

  FETCH_SELECTABLE_SHIP_MODES: `${C}.FETCH_SELECTABLE_SHIP_MODES`,
  CANCEL_FETCH_SELECTABLE_SHIP_MODES: `${C}.CANCEL_FETCH_SELECTABLE_SHIP_MODES`,
  CHANGE_SELECTABLE_SHIP_MODES: `${C}.CHANGE_SELECTABLE_SHIP_MODES`,
  CHANGE_SELECTED_SHIP_MODE: `${C}.CHANGE_SELECTED_SHIP_MODE`,

  CHANGE_MAIN_LABEL: `${C}.CHANGE_MAIN_LABEL`,
  CHANGE_IH_DATE: `${C}.CHANGE_IH_DATE`,
  CHANGE_NOTE: `${C}.CHANGE_NOTE`,

  SUBMIT: `${C}.SUBMIT`,
  CANCEL_SUBMIT: `${C}.CANCEL_SUBMIT`,

  RESET_DATA: `${C}.RESET_DATA`,
};
