import {DEPARTMENT} from '../constants/actionTypes';
import initial from './initialState';

export default function (state = initial.departmentState, action) {
  switch (action.type) {
    case DEPARTMENT.GET_LIST_SUCCESS:
      return {...state, items: action.data.items || [], count: action.data.count || 0};
    case DEPARTMENT.RELOAD:
      return {...state, reload: !state.reload};
    default:
      return state;
  }
}
