import {getListItemDAL, addItemDAL, editItemDAL, getItemDAL, deleteItemDAL} from '../api/standardApi';
import _ from 'lodash';

class standardServices {

  constructor() {
  }

  getStandard (options = {}) {
    options.query && _.set(options, 'query', JSON.stringify(options['query']));
    options.sort && _.set(options, 'sort', JSON.stringify(options['sort']));

    return getListItemDAL(options);
  }

  getDetail (id, options = {}) {
    return getItemDAL(id, options);
  }

  deleteItem (id) {
    return deleteItemDAL(id);
  }

  editItem (id, data) {
    return editItemDAL(id, data);
  }

  addItem (data) {
    return addItemDAL(data);
  }
}

export default new standardServices();
