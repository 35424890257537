/**
 * @author hienhc <hienhc@nexlesoft.com>
 * @create date 2018-08-31 11:48:02
*/

const C = "CREATE_PKL_SHIP_GOHH";

export default {
    /* LOAD RAW DATA TO DISPLAY IN THE EDITOR ACTIONS */
    FETCH_PKL_PROD_LIST_DETAIL: `${C}_FETCH_PKL_PROD_LIST_DETAIL`,
    CANCEL_FETCH_PKL_PROD_LIST_DETAIL: `${C}_CANCEL_FETCH_PKL_PROD_LIST_DETAIL`,
    CHANGE_PKL_PROD_LIST_DETAIL: `${C}_CHANGE_PKL_PROD_LIST_DETAIL`,

    /**
     * Actions that will affect change in data
     */
    CHANGE_COMMODITY: `${C}_CHANGE_COMMODITY`,
    CHANGE_ACTIVE_ORDER: `${C}_CHANGE_ACTIVE_ORDER`,
    CHANGE_ACTIVE_STYLE: `${C}_CHANGE_ACTIVE_STYLE`,
    REMOVE_STYLE: `${C}_REMOVE_STYLE`,
    //GOHH
    CHANGE_SIZE_PACKING: `${C}_CHANGE_SIZE_PACKING`,
    CHANGE_BAR_NUMBER: `${C}_CHANGE_BAR_NUMBER`,
    ADD_BAR: `${C}.ADD_BAR`,
    REMOVE_BAR: `${C}.REMOVE_BAR`,
    //Carton
    CHANGE_PACKED_QUANTITY: `${C}_CHANGE_PACKED_QUANTITY`,
    CHANGE_PACKED_CHECKED: `${C}_CHANGE_PACKED_CHECKED`,
    /**
     * Actions to clear all data in specific store
     */
    RESET_DATA: `${C}_RESET_DATA`,

    /**
     * Actions to create successful
     */
    CREATE_SUCCES: `${C}_CREATE_SUCCES`,
}