import {createSelector} from 'reselect';
import moment from 'moment';
import _ from 'lodash';
import * as R from 'ramda';
import {genDollarToText} from '../../../functions/helpers';

export const getInvoiceDetail = ({editInvoiceImpExpUSDelivery}) => editInvoiceImpExpUSDelivery.invoiceDetail || {};

export const getCompany = ({editInvoiceImpExpUSDelivery}) => editInvoiceImpExpUSDelivery.company;

export const getPaymentType = ({editInvoiceImpExpUSDelivery}) => editInvoiceImpExpUSDelivery.paymentType;

export const getName = createSelector (
    [
        getInvoiceDetail
    ], (data) => {
        return data.invoiceDetail && data.invoiceDetail.company
    }
)


export const getAmount = createSelector (
    [
        getInvoiceDetail
    ], (data) => {
        return data.amount || 0
    }
)

export const getPaid = createSelector (
    [
        getInvoiceDetail
    ], (data) => {
        return data.paid || 0
    }
)

export const getRemaining = createSelector(
    [
        getAmount,
        getPaid
    ], (amount, paid) => {
        return amount - paid
    }
)

export const getInvoiceNo = createSelector (
    [
        getInvoiceDetail
    ], (data) => {
        return data.invNo
    }
)

export const getInvoiceDate = createSelector (
    [
        getInvoiceDetail
    ], (data) => {
        return data.invoiceDate //moment(data.issuedDate).format("MMM DD, YYYY");
    }
)


export const getNote = createSelector(
    [
        getInvoiceDetail
    ], data => {
        return data.note;
    }
)

export const getDueDate = createSelector (
    [
        getInvoiceDetail
    ], (data) => {
        return data.dueDate;
    }
)

export const getListBillDetail = createSelector(
    [
        ({editInvoiceImpExpUSDelivery}) => editInvoiceImpExpUSDelivery.activeListBills,
        getInvoiceDetail,
    ], (activeListBills, data) => {
        return data.invoiceDetail && data.invoiceDetail.bills.map(item => ({...item, active: activeListBills.findIndex(bill => bill == item._id) > -1}))
    }
)

export const getDataOfTemp = ({editInvoiceImpExpUSDelivery}) => editInvoiceImpExpUSDelivery.dataOfTemp;

export const getPayAmount = ({editInvoiceImpExpUSDelivery}) => editInvoiceImpExpUSDelivery.payAmount;

export const getFilesSaved = createSelector(
    [
        getInvoiceDetail
    ], data => {
        return data.files || [];
    }
)

export const getTransferFiles = ({ editInvoiceImpExpUSDelivery }) => {
    const { transferFiles } = editInvoiceImpExpUSDelivery;
    return _.map(transferFiles, file => ({
      id: R.path(['id'], file),
      url: R.path(['url'], file),
      raw: file,
      type: _.split(R.path(['id'], file), '.')[1],
    }));
}

export const fnGenerateRequestData = ({editInvoiceImpExpUSDelivery}) => {
    const {invoiceDetail = {}, paymentType, payAmount} = _.cloneDeep(editInvoiceImpExpUSDelivery);
    let data = _.omit(invoiceDetail, ['createdAt', 'createdBy', 'updatedAt', 'updatedBy', 'paymentHistory']);
    let detailOfInvoice = _.omit(data.invoiceDetail, ['createdAt', 'createdBy', 'updatedAt', 'updatedBy'])
    let receipt;
    if(paymentType === 'transfer') {
        data = _.omit(data, ['receipt']);
    } else {
        receipt = data.receipt
        data = _.omit(data, ['files', 'receipt']);
    }
    data = {...data, payment: {receipt, paymentType: _.startCase(paymentType), paid: payAmount}}
    return ({...data, invoiceDetail: detailOfInvoice, currency: 'USD'})
}

export const getReceiptFromInvoice = createSelector (
    [
        getInvoiceDetail,
        getPayAmount,
        getName
    ], (data, payAmount, address) => {
        return ({...data.receipt, total: payAmount, date: moment().format(), address})
    }
)

export const getReceiptDataFromRawData = ({editInvoiceImpExpUSDelivery}) => ({...editInvoiceImpExpUSDelivery.receipt, say: genDollarToText(editInvoiceImpExpUSDelivery.receipt.total)})

export const getEmptyReceipt = createSelector(
    [
        getReceiptFromInvoice
    ], data => {
        return !data.isEdit;
    }
)

export const getSuccess = ({editInvoiceImpExpUSDelivery}) => editInvoiceImpExpUSDelivery.success;

export const getDisableChangeValue = createSelector (
    [
      getPaid
    ], paid => {
      if(paid > 0) return true;
      return false;
    }
  )

  export const getActiveScreenStatus = ({editInvoiceImpExpUSDelivery}) => editInvoiceImpExpUSDelivery.activeScreen;