import React, {Component} from 'react';

export default class Text extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {value, className} = this.props;
    return (
      <td className={className}>
        {value}
      </td>
    );
  }
}
