import React, {Component} from 'react';
import * as R from 'ramda';
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import appActions from '../../../actions/appActions';
import imgFile from '../../../stylesheets/images/logo-white.svg';
import * as utils from "../../../functions/utils";

class Sidebar extends Component {
  constructor (props) {
    super(props);
    this.state = {
      menus:[],
      active: {
        color: '#ffffff',
        backgroundColor: '#2d363f'
      }
    };
  }

  componentWillMount() {
    const {authState} = this.props;
    if(authState) {
      this.getMenuData(authState);
    }
  }

  componentWillReceiveProps(nextProps) {
    if(this.props.authState !== nextProps.authState) {
      this.getMenuData(nextProps.authState);
    }
  }
  
  getMenuData(authState){
    if(authState && authState.auth && authState.auth.role) {
      const role = authState.auth.role;
      let menus = utils.getMenuByRole(role, authState.auth.isSupperAdmin);
      this.setState({menus});
    }
  }

  handleMenu (menu) {
    this.setPageCurrent(menu);
    this.props.router.history.push(menu.url);
  }

  setPageCurrent (menu) {
    this.props.appActions.setPageCurrent({
      name: menu.name,
      url: menu.url,
      tab: menu.defaultTab || '',
      data: {}
    });
    this.props.appActions.addPage([]);
  }

  render () {
    let {menus} = this.state;
    let currentUrl = R.path(['router', 'route', 'location', 'pathname'], this.props);
    return (
      <aside className="sidebar scrollbar-sidebar">
        <div className="sidebar-container">
          <div className="sidebar-header">
            <div className="brand">
              <div className="logo">
                <Link to="/"><img src={imgFile} alt="fashion" className="img-responsive" /></Link>
              </div>
            </div>
          </div>
          <nav className="menu">
            <ul className="nav metismenu" id="sidebar-menu">
              {menus.map((item, idx) => (
                <li key={idx} className={currentUrl.startsWith(item.url) ? 'active' : ''} style={{width: '100%'}}>
                  <Link to={item.url} onClick={() => { this.setPageCurrent(item) }} ><img src={item.img} alt="dashboard" className="img-responsive" />{item.text}</Link>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </aside>
    );
  }
}

Sidebar.propTypes = {
  appActions: PropTypes.object
};

function mapStateToProps (state) {
  return {
    authState: state.authState
  };
}

function mapDispatchToProps (dispatch) {
  return {
    appActions: bindActionCreators(appActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
