import React, { Component } from 'react';
import PreviewSupplierOverseaView from '../../../components/accounting/payable/PreviewSupplierOversea';
import { connect } from 'react-redux';
import * as selector from '../../../../selectors/accounting/payable/createInvoiceSupplier';
import * as actions from '../../../../actions/accounting/payable/createInvoiceSupplier';
import { withRouter } from 'react-router';
import * as PATH from '../../../../constants/routeConstants'; 

class PreviewSupplierOversea extends Component {
    constructor (props) {
        super(props)
        this.state = {
            listIds: this.props.listIds,
            items: [],
            pageNumber: 1,
            pageSize: 10,
            columns: [
                { name: 'Purchase Order No.', value: 'purchaseNo', type: 'text' },
                { name: 'Material', value: 'material', type: 'text' },
                { name: 'Content', value: 'content', type: 'text' },
                { name: 'Color', value: 'color', type: 'text' },
                { name: 'Size', value: 'size', type: 'text' },
                { name: 'Payer', value: 'payer', type: 'text' },
                { name: 'ETD', value: 'eta', type: 'date', formatType: 'MMM DD, YYYY'  },
                { name: 'Order Quantity', value: 'orderQuantity', type: 'number' },
                { name: 'Received Quantity', value: 'receivedQuantity', type: 'number' },
                { name: 'INV No', value: 'invNo', type: 'text' },
                { name: 'Price', value: 'priceText', type: 'text' },
                { name: 'Amount', value: 'amount', type: 'text' },
            ],
            actions: []
        }
    }
    
    componentWillReceiveProps(newProps) {
        const {activeScreen} = newProps;
        if(!activeScreen) {
            newProps.router.history.push(PATH.ACCOUNTING_PAYABLE_PLAN_SUPPLIER_OVERSEA_URL);
        }
    }

    render() {
        const { columns } = this.state;
        // console.log(this.props.previewInvoice)
        return (
            <div>
                <PreviewSupplierOverseaView columns = {columns} {...this.props} />
            </div>
        );
    }
}
const mapStateToProps = state => ({
    // invoiceDetail: selector.getInvoiceDetail(state),
    previewInvoice: selector.getPreviewInvoice(state),
    amount: selector.getAmountINV(state),
    invoiceNo: selector.getInvoiceNo(state),
    issuedDate: selector.getIssuedDate(state),
    supplier: selector.getSelectedSupplier(state),
    // amountINV: selector.getAmount(state),
    totalAmountINV: selector.getTotalAmountINV(state),
    activeScreen: selector.getActiveScreenPreviewStatus(state),
    creditDebitAmount: selector.getSelectedCreditDebitAmount(state),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    resetData() {
        dispatch(actions.resetData())
    }
})

export default connect(mapStateToProps, mapDispatchToProps) (withRouter(PreviewSupplierOversea));
