import React from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import I18n from "i18n-js";
import Modal from '../forms/Modal';

import IMG_DEFAULT from "../../../stylesheets/images/grag-photo.png";

import icXHoverIcon from '../../../stylesheets/images/ic-x-hover.svg';

export const EditCutTicketImagesModal = props => (
  <Modal isOpen={props.isOpen}>
  <div className="modal-dialog lg" role="document">
    <div className="modal-content">
      <form>
        <div className="modal-header">
          <h4 className="modal-title">{I18n.t("PageOrder.EDITPICTURE")}</h4>
        </div>
        <div className="modal-body">
          <div className="clsCtkImgs popup-picture">
            {_.map(props.images, img => {
              return (
                <div key={img.url} className="list position-relative">
                  <img
                    src={img.url}
                    alt="Image"
                    className="dnd-item"
                  />
                  <span
                    className="close-photo position-absolute bottom-0 right-0"
                    onClick={() => props.removeImage(img)}
                  >
                    <img
                      style={{ cursor: "pointer" }}
                      src={icXHoverIcon}
                    />
                  </span>
                </div>
              );
            })}
            <div>
              <img
                onDragOver={evt => evt.preventDefault()}
                onDrop={e => {
                  e.preventDefault();
                  const file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
                  if (!file)
                    return;
                  
                  props.addImage(file);
                }}
                onDrag={evt => evt.preventDefault()}
                onClick={() => {
                  $("#imgStyle").click();
                }}
                style={{ cursor: "pointer" }}
                src={IMG_DEFAULT}
              />
            </div>
            <input
              onChange={e => {
                e.preventDefault();
                const file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
                if (!file)
                  return;
                
                props.addImage(file);
              }}
              style={{ display: "none" }}
              id="imgStyle"
              type="file"
              name="imgStyle"
            />
          </div>
          <div style={{ clear: "both" }} />
        </div>
        <div className="modal-footer">
          <button type="button" 
            className="bt-black" 
            onClick={() => props.onClose()}
          >
            {I18n.t("Btn.CANCEL")}
          </button>
          <button
            type="button"
            className={`${!props.canSave ? 'btn btn-default': ''} bt-orange mg-l-5`}
            disabled={!props.canSave}
            onClick={() => props.onSubmit()}
          >
            {props.isSubmiting ? I18n.t("Btn.SAVING") : I18n.t("Btn.SAVE")}
          </button>
        </div>
      </form>
    </div>
  </div>
  </Modal>
);

EditCutTicketImagesModal.propTypes = {
  images: PropTypes.arrayOf(PropTypes.any).isRequired,
  isSubmiting: PropTypes.bool,
  canSave: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  removeImage: PropTypes.func,
  addImage: PropTypes.func,
}

EditCutTicketImagesModal.defaultProps = {
  canSave: true,
  isSubmiting: false,
  onClose: f => f,
  onSubmit: f => f,
  removeImage: f => f,
  addImage: f => f,
}

export default EditCutTicketImagesModal;
