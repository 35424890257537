import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import DropDownMultiSelect from '../fields/DropDownMultiSelect';
import propertyActions from '../../../actions/propertyActions';
import fabricActions from '../../../actions/fabricActions';
import { PropertyKeys } from '../../../constants/constants';
import I18n from 'i18n-js';
import Material from '../../../models/material';
import { toastr } from 'react-redux-toastr';
import { isStringEmpty } from '../../../functions/helpers';

class ModalFabricForm extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      fabricDetail: new Material(),
      colors: [],
      units: [],
      origins: [],
      errors: {}
    };
    this.initProperties = this.initProperties.bind(this);
  }

  componentWillMount() {
    let { fabricDetail } = this.state;
    let { propFabricDetail } = this.props;

    this.props.propertyPropsActions.getDetailByKey(PropertyKeys.COLOR);
    this.props.propertyPropsActions.getDetailByKey(PropertyKeys.CONVERT_UNIT);
    this.props.propertyPropsActions.getDetailByKey(PropertyKeys.UNIT);
    this.props.propertyPropsActions.getDetailByKey(PropertyKeys.ORIGIN);

    if (propFabricDetail && fabricDetail._id !== propFabricDetail._id) {
      fabricDetail.init(propFabricDetail);
      this.setState({ fabricDetail: fabricDetail });
    }
  }

  componentWillReceiveProps(newProps) {
    let { propertyState } = newProps;
    if (propertyState && Object.keys(propertyState.properties).length > 0) {
      this.initProperties(propertyState);
    }
  }

  initProperties(propertyState) {
    let { colors, units, origins } = this.state;
    if (!colors.length || colors !== propertyState.properties[PropertyKeys.COLOR]) {
      let arrayColor = propertyState.properties[PropertyKeys.COLOR] || [];
      this.setState({ colors: arrayColor.map(color => { return { value: color, label: color }; }) });
    }
    if (!units.length || units !== propertyState.properties[PropertyKeys.UNIT]) {
      this.setState({ units: propertyState.properties[PropertyKeys.UNIT] || [] });
    }
    if (!origins.length || origins !== propertyState.properties[PropertyKeys.ORIGIN]) {
      this.setState({ origins: propertyState.properties[PropertyKeys.ORIGIN] || [] });
    }
  }

  onChange = (property, value) => {
    let { fabricDetail } = this.state;
    this.setState({ fabricDetail: _.set(fabricDetail, property, value) });
    if (property === 'isConvert' && (value === false)) {
      fabricDetail.convertTo = '';
      this.setState({ fabricDetail: fabricDetail })
    }
    this.validateAfterChange(property, value);
  }

  close() {
    this.props.closeModal();
  }

  validateAfterChange = (property, value) => {
    let { fabricDetail, errors } = this.state;
    switch (property) {
      case 'name': {
        delete errors['name'];
        if (isStringEmpty(value)) {
          errors['name'] = I18n.t('ErrorMessages.FabricNameIsRequired');
        }
        this.setState({ errors });
        break;
      }
      case 'data.color': {
        delete errors['dataColor'];
        if (!value || value.length <  1) {
          errors['dataColor'] = I18n.t('ErrorMessages.FabricColorIsRequired');
          this.setState({ errors });
          return false;
        }
        break;
      }
      case 'data.unit': {
        delete errors['dataUnit'];
        if (isStringEmpty(value)) {
          errors['dataUnit'] = I18n.t('ErrorMessages.FabricUnitIsRequired');
        }
        this.setState({ errors });
        break;
      }
      case 'convertTo': {
        delete errors['convertTo'];
        if (value > 0 && isStringEmpty(fabricDetail.convertUnit)) {
          errors['convertTo'] = I18n.t('ErrorMessages.FabricConvertUnitIsRequired');
        }
        this.setState({ errors });
        break;
      }
      case 'convertUnit': {
        delete errors['dataUnit'];
        if (value == fabricDetail.data.unit) {
          errors['dataUnit'] = I18n.t('ErrorMessages.FabricConvertUnitEqualsUnit');
        }
        this.setState({ errors });
        break;
      }
      default:
    }
  }

  validateBeforeSave = () => {
    let { fabricDetail, errors } = this.state;
    errors = {};
    if (!fabricDetail) {
      errors['name'] = I18n.t('ErrorMessages.FabricInfoIsRequired');
      this.setState({ errors });
      return false;
    }

    if (isStringEmpty(fabricDetail.name)) {
      errors['name'] = I18n.t('ErrorMessages.FabricNameIsRequired');
      this.setState({ errors });
      return false;
    }

    if (!fabricDetail.data.color || !fabricDetail.data.color.length) {
      errors['dataColor'] = I18n.t('ErrorMessages.FabricColorIsRequired');
      this.setState({ errors });
      return false;
    }

    if (isStringEmpty(fabricDetail.data.unit)) {
      errors['dataUnit'] = I18n.t('ErrorMessages.FabricUnitIsRequired');
      this.setState({ errors });
      return false;
    }

    /*if (isStringEmpty(fabricDetail.data.origin)) {
      errors['dataOrigin'] =  I18n.t('ErrorMessages.FabricOriginIsRequired');
      this.setState({errors});
      return false;
    }*/

    if (fabricDetail.convertTo > 0 && isStringEmpty(fabricDetail.convertUnit)) {
      errors['convertTo'] = I18n.t('ErrorMessages.FabricConvertUnitIsRequired');
      this.setState({ errors });
      return false;
    }

    if (fabricDetail.convertUnit == fabricDetail.data.unit) {
      errors['dataUnit'] = I18n.t('ErrorMessages.FabricConvertUnitEqualsUnit');
      this.setState({ errors });
      return false;
    }

    this.setState({ errors });
    return true;
  }

  save() {
    if (!this.validateBeforeSave())
      return;

    let { fabricDetail } = this.state;
    if (fabricDetail._id) {
      this.props.fabricPropsActions.editFabric(fabricDetail._id, fabricDetail);
    } else {
      delete fabricDetail._id;
      this.props.fabricPropsActions.addFabric(fabricDetail);
    }
  }

  render() {
    let { fabricDetail, colors, units, origins, errors } = this.state;
    return (
      <div className="modal-dialog modal-add-fabric" role="document">
        <div className="modal-content">
          <form>
            <div className="modal-header">
              <a className="close" onClick={() => this.close()}><span aria-hidden="true">&times;</span></a>
              <h4 className="modal-title" id="myModalLabel">{fabricDetail && fabricDetail._id ? I18n.t('PageStandard.EDITFABRIC') : I18n.t('PageStandard.ADDFABRIC')}</h4>
            </div>

            <div className="modal-body">
              <div className="form-group">
                <div className="col-md-4 col-xs-12 text">
                  <p>{I18n.t('PageStandard.Name')}</p> <span className="color-red"> *</span>
                </div>
                <div className="col-md-8 col-xs-12 value">
                  <input type="text" value={fabricDetail.name} onChange={evt => this.onChange('name', evt.target.value)} className="form-control" />
                  {errors['name'] && <div className="alert-warning">{errors['name']}</div>}
                </div>
              </div>

              <div className="form-group">
                <div className="col-md-4 col-xs-12 text">
                  <p>{I18n.t('PageStandard.Content')}</p>
                </div>
                <div className="col-md-8 col-xs-12 value relative">
                  <input type="text" value={fabricDetail.data && fabricDetail.data.content} ref="property" onChange={evt => this.onChange('data.content', evt.target.value)} className="form-control" />
                </div>
              </div>

              <div className="form-group">
                <div className="col-md-4 col-xs-12 text">
                  <p>{I18n.t('PageStandard.Color')}</p> <span className="color-red"> *</span>
                </div>
                <div className="col-md-8 col-xs-12 value relative">
                  <DropDownMultiSelect options={colors || []} selectedValues={fabricDetail.data.color || []} property={'data.color'} onChange={this.onChange}></DropDownMultiSelect>
                  {errors['dataColor'] && <div className="alert-warning">{errors['dataColor']}</div>}
                </div>
              </div>

              <div className="form-group">
                <div className="col-md-4 col-xs-12 text">
                  <p>{I18n.t('PageStandard.Unit')}</p> <span className="color-red"> *</span>
                </div>
                <div className="col-md-8 col-xs-12 value relative">
                  <select className="form-control" value={fabricDetail.data.unit} onChange={evt => this.onChange('data.unit', evt.target.value)}>
                    <option value="" />
                    {units.map((unit, idx1) => (<option key={idx1} value={unit}>{unit}</option>))}
                  </select>
                  {errors['dataUnit'] && <div className="alert-warning">{errors['dataUnit']}</div>}
                </div>
              </div>

              <div className="form-group">
                <div className="col-md-4 col-xs-12 text">
                  <p>{I18n.t('PageStandard.Origin')}</p>
                </div>
                <div className="col-md-8 col-xs-12 value relative">
                  <select className="form-control" value={fabricDetail.data.origin} onChange={evt => this.onChange('data.origin', evt.target.value)}>
                    <option value="" />
                    {origins.map((origin, idx1) => (<option key={idx1} value={origin}>{origin}</option>))}
                  </select>
                  {errors['dataOrigin'] && <div className="alert-warning">{errors['dataOrigin']}</div>}
                </div>
              </div>

              <div className="form-group">
                <div className="col-md-4 col-xs-12 text">
                  &nbsp;
                </div>
                <div className="col-md-8 col-xs-12 value relative">
                  <div className="style-checkbox">
                    <input type="checkbox" checked={fabricDetail.isConvert} value={fabricDetail.isConvert} onChange={evt => this.onChange('isConvert', !!evt.target.checked)} />
                    <label>
                      <span><span>{I18n.t('PageStandard.ConvertUnit')}</span></span>
                    </label>
                  </div>
                </div>
              </div>

              <div className="form-group convertToGrayBg">
                <div className="col-md-4 col-xs-12 text">
                  <p>{I18n.t('PageStandard.ConvertTo')}</p>
                </div>
                <div className="col-md-8 col-xs-12 value relative">
                  <div className="row">
                    <div className="col-md-6">
                      <input type="number" className="form-control"
                        disabled={!fabricDetail.isConvert}
                        value={fabricDetail.convertTo}
                        onChange={evt => this.onChange('convertTo', evt.target.value)} />
                      {errors['convertTo'] && <div className="alert-warning">{errors['convertTo']}</div>}
                    </div>
                    <div className="col-md-6 no-padding-left">
                      <select className="form-control"
                        disabled={!fabricDetail.isConvert}
                        value={fabricDetail.convertUnit}
                        onChange={evt => this.onChange('convertUnit', evt.target.value)}>
                        <option value="" />
                        {units.map((unit, idx1) => (<option key={idx1} value={unit}>{unit}</option>))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-group">
                <div className="col-md-4 col-xs-12 text">
                  <p>{I18n.t('PageStandard.Note')}</p>
                </div>
                <div className="col-md-8 col-xs-12 value relative">
                  <textarea type="text" className="form-control" rows={3}
                    value={fabricDetail.note} onChange={evt => this.onChange('note', evt.target.value)}></textarea>
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <button type="button" className="bt-black" onClick={() => this.close()}>{I18n.t('Btn.CANCEL')}</button>
              <button type="button" className="bt-orange mg-l-5" onClick={() => this.save()}>{I18n.t('Btn.SAVE')}</button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    appState: state.appState,
    authState: state.authState,
    propertyState: state.propertyState,
    fabricState: state.fabricState
  };
}

function mapDispatchToProps(dispatch) {
  return {
    propertyPropsActions: bindActionCreators(propertyActions, dispatch),
    fabricPropsActions: bindActionCreators(fabricActions, dispatch),
  };
}

ModalFabricForm.propTypes = {
  authState: PropTypes.object.isRequired,
  fabricDetail: PropTypes.object
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalFabricForm);
