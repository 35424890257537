import Base from './base';

class Customer extends Base {
  constructor () {
    super();

    this.getListCustomerDAL = this.getListCustomerDAL.bind(this);
    this.getListCustomerByQaDAL = this.getListCustomerByQaDAL.bind(this);
    this.addCustomerDAL = this.addCustomerDAL.bind(this);
    this.editCustomerDAL = this.editCustomerDAL.bind(this);
    this.getCustomerDAL = this.getCustomerDAL.bind(this);
    this.deleteCustomerDAL = this.deleteCustomerDAL.bind(this);
  }

  getListCustomerDAL (query) {
    return super.execute(this.Urls.customer.list, this.ApiMethod.get, query);
  }
  
  getListCustomerByQaDAL (query) {
    return super.execute(this.Urls.customer.listByQa, this.ApiMethod.get, query);
  }

  addCustomerDAL (body) {
    return super.execute(this.Urls.customer.create, this.ApiMethod.post, body);
  }

  editCustomerDAL (id, body) {
    return super.execute(this.Urls.customer.update + '/' + id, this.ApiMethod.put, body);
  }

  getCustomerDAL (id, options) {
    return super.execute(this.Urls.customer.detail + '/' + id, this.ApiMethod.get, options);
  }

  deleteCustomerDAL (id) {
    return super.execute(this.Urls.customer.delete + '/' + id, this.ApiMethod.delete, null);
  }
}

export default new Customer();
