import Base from './base';
class PayInfo extends Base {
    constructor () {
      super();
      
    }
    
    createPayInfo = (query) => {
        return super.execute(this.Urls.accounting.payInfo.create, this.ApiMethod.post, query);
    }

    getListPayInfo = (options) => {
        return super.execute(this.Urls.accounting.payInfo.listPayInfo, this.ApiMethod.get, options);
    }

    deletePayInfo = (id) => {
        return super.execute(this.Urls.accounting.payInfo.delete + '/' + id, this.ApiMethod.delete, null);
    }

    getPayInfo = (id) => {
        return super.execute(this.Urls.accounting.payInfo.default + '/' + id, this.ApiMethod.get);
    }

    updatePayInfo = (id, data) => {
        return super.execute(this.Urls.accounting.payInfo.default + '/' + id, this.ApiMethod.put, data);
    }
    getExportCreditDebitUrl = () => {
        return this.Urls.accounting.payInfo.export 
    }
  }
  
  export default new PayInfo();
  