const C = "PURCHASE_PLANS";

export default {
    CHANGE_LIST_ORDER: `${C}_CHANGE_LIST_ORDER`,
    CHANGE_LIST_ORDER_MORE: `${C}CHANGE_LIST_ORDER_MORE`,
    CHANGE_SELECTED_ORDER: `${C}_CHANGE_SELECTED_ORDER`,
    CHANGE_LIST_STYLE: `${C}_CHANGE_LIST_STYLE`,
    CHANGE_SELECTED_STYLE: `${C}_CHANGE_SELECTED_STYLE`,
    RESET_DATA: `${C}_RESET_DATA`,
    CHANGE_LIST_MATERIAL: `${C}_CHANGE_LIST_MATERIAL`,
    CHANGE_CHECKED_FABRIC: `${C}_CHANGE_CHECKED_FABRIC`,
    CHANGE_CHECKED_TRIM: `${C}_CHANGE_CHECKED_TRIM`,
    CHANGE_MATERIAL_VALUE: `${C}_CHANGE_MATERIAL_VALUE`,
    CREATE_USE_STOCK_SUCCESS: `${C}_CREATE_USE_STOCK_SUCCESS`,
    CHANGE_SELECTED_LIST_MATERIALS: `${C}_CHANGE_SELECTED_LIST_MATERIALS`,
    CHANGE_MATERIAL_IN_PLACE_ORDER_VALUE: `${C}_CHANGE_MATERIAL_IN_PLACE_ORDER_VALUE`,
    CHANGE_REMARK: `${C}_CHANGE_REMARK`,
    CREATE_PURCHASE_ORDER_SUCCESS: `${C}_CREATE_PURCHASE_ORDER_SUCCESS`,
    RESET_LIST_ORDER: `${C}_RESET_LIST_ORDER`,
    RESET_SUCCESS_STATUS: `${C}_RESET_SUCCESS_STATUS`,

    CHANGE_PAGE_NUMBER: 'CHANGE_PAGE_NUMBER',
    RESET_CHANGE_PAGE_NUMBER: 'RESET_CHANGE_PAGE_NUMBER',
    CHANGE_FILTER_TEXT: 'CHANGE_FILTER_TEXT',
    CANCEL_IS_SEARCH: 'CANCEL_IS_SEARCH',
    IS_SEARCH: 'IS_SEARCH',
    LOAD_MORE: 'LOAD_MORE',
    CANCEL_LOAD_MORE: 'CANCEL_LOAD_MORE',

    LOADING: 'LOADING',
    CANCEL_LOADING: 'CANCEL_LOADING'
}