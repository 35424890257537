import React    from 'react';
import _        from 'lodash';
import I18n from 'i18n-js';
import SearchSelect from '../../../ui/components/forms/SearchSelect';

export default ({data, idx, sizes, sizesTrim, est, colorsGlobal, sizesGlobal, onChangeTrimSize, onChange, selectedColors, errors}) => {

    const getRate = item => {
        let _cost = parseFloat(_.get(item, 'info.yield', 0)) || 0;
        let _percent = parseFloat(_.get(item, 'info.percent', 0)) || 0;

        return _cost * (1 + _percent / 100);
    }
    const dataSelectSizes = sizesGlobal.map((i, idx3) => ({key: idx3, value: i, label: i}));
    const dataSelectColors = colorsGlobal.map((i, idx3) => ({key: idx3, value: i, label: i}))
    
    const getColor = (estimates, est) => {
        // let estimate = estimates.find(e => e.color === groupColor.data[0]._id);
        // const ids = groupColor.data.map(g => g._id)
        // const idx =  _.findIndex(estimates, es => es.trimColor == '' && _.findIndex(ids, id => id == es.color) >= 0)
        // groupColor.trimColor = idx >= 0 ? '' : _.get(estimate, 'trimColor', '')
        // return groupColor.trimColor;

        let estimate = estimates.find(e => e.color === est.color._id);
         est.trimColor = _.get(estimate, 'trimColor', '')
         return est.trimColor;
    }

    const error = errors && _.isArray(errors) ? _.nth(errors, idx) : errors;

    return (
        <div className="content-right">
            <div className="md content-main-title-edit-yield-color bg-gray">
                <table style={{width: '100%'}}>
                    <tbody>
                        <tr className="background">
                            <th className="title first">{I18n.t('PageYield.TrimSize')}<span className="color-red" style={{display: 'inline'}}>*</span></th>

                            {Object.keys(sizes).map((k, idx2) => {
                                let trimSize = _.get(data, 'trimSize', []);
                                return (
                                <th key={idx2} id={idx2+JSON.stringify(k)}>
                                    <span>{k}</span>
                                    {/* <select value={_.get(data, `trimSize[${idx2}].size`, '') === k ? _.get(data, `trimSize[${idx2}].value`, '') : ''} 
                                        onChange={evt => onChangeTrimSize(idx, idx2, k, evt.target.value)} >
                                        <option value="" disabled selected>Select</option>
                                        {sizesGlobal.map((i, idx3) => (<option key={idx3} value={i}>{i}</option>))}
                                    </select> */}
                                    <SearchSelect
                                        className="fabric-combobox m-w-75 w-100-percent"
                                        options={dataSelectSizes}
                                        value={_.get(data, `trimSize[${idx2}].size`, '') === k ? _.get(data, `trimSize[${idx2}].value`, '') : ''}
                                        onChange={evt => onChangeTrimSize(idx, idx2, k, evt.value)}
                                    />
                                    {error && _.get(error, `size.${idx2}`, undefined) &&
                                        <div className="text-danger" style={{position: 'absolute'}}>
                                            {I18n.t(_.get(error, `size.${idx2}`, ''))}
                                        </div>
                                    }
                                </th>
                                );
                            })}
                        </tr>

                        <tr className="border-dashed bg-white">
                            <td className="title first">{I18n.t('PageYield.GarmentColor')}</td>
                            {Object.keys(sizes).map((k, idx) => (<td key={idx}>{k}</td>))}
                            <td className="trim-color">{I18n.t('PageYield.TrimColor')}<span className="color-red" style={{display: 'inline'}}>*</span></td>
                        </tr>
                        {(est || []).map((c, idx2) => (
                            <tr key={idx2} className="bg-white">
                                <td className="title first medium">{_.get(c, 'color.name')}</td>
                                {Object.keys(sizes).map((k, idx) => {
                                    let quantity = _.get(sizes, `${k}.${_.get(c, 'color.name')}`, 0);
                                    return (<td key={idx} className="number">{_.ceil((quantity * getRate(data)), 3) || '-'}</td>);
                                })}
                                <td className="trim-color">
                                    {/* <select value={getColor(data.estimate||[], c)} onChange={evt => onChange(idx, idx2, est , evt.target.value)} >
                                        <option value="" disabled selected>Select color</option>
                                        {colorsGlobal.map((cg, idx3) => (<option key={idx3} value={cg}>{cg}</option>))}
                                    </select> */}
                                    <SearchSelect
                                        className="fabric-combobox m-w-75 w-100-percent"
                                        options={dataSelectColors}
                                        value={getColor(data.estimate||[], c)} onChange={evt => onChange(idx, idx2, est , evt.target.value)}
                                        onChange={evt => onChange(idx, idx2, est , evt.value)}
                                    />
                                    {error && _.get(error, `color.${idx2}`, undefined) &&
                                        <div className="text-danger">
                                            {I18n.t(_.get(error, `color.${idx2}`, ''))}
                                        </div>
                                    }
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}