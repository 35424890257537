import React, { Component } from 'react';
import { Store } from '../../../../CssHandleModule/functions';
import BarTitle from '../../../../components/shipping/subViews/barTitle';
import CutTicketContainer from './CutTicketContainer';
import I18n from 'i18n-js';
import { connect } from 'react-redux';
import * as selector from '../../../../../selectors/ship-out/viewPklOfProdDetail';
import { changeStyleActive } from '../../../../../actions/ship-out/viewPklOfProdDetailActions';
const store = new Store();
store.setStyles({
  orderNumbers: [1, 2],
  fonts: [{ order: 1, size: '15px' }],
  aligns: [
    { order: 1, margin: { right: '10px' } },
    { order: 2, margin: { left: '10px' } },
  ],
});

class ViewStyleContainerPrepack extends Component {
  constructor(props) {
    super(props);
  }

  //Get new items from props
  componentWillReceiveProps(nextProps) {
    const { cutTickets } = { ...nextProps };
    if (cutTickets !== []) {
      this.setState({ cutTickets });
    }
  }

  changeStyleActive = id => {
    this.props.changeStyleActive(id);
  };

  getActualAndRemainingPacking = (cutId, colorId) => {
    const { style } = this.props;
    let actualQuantity = 0;
    let remaining = 0;
    style.packings.forEach(pk => {
      pk.items.forEach(item => {
        if (item.color == colorId && item.cutTicket == cutId) {
          actualQuantity += Number(item.actualQuantity || 0);
          remaining += Number(item.planQuantity || 0) - Number(item.actualQuantity || 0);
        }
      });
    });
    return { actualQuantity, remaining };
  };

  defineDataShow = style => {
    const styleClone = _.cloneDeep(style);
    const colors = _.flattenDeep(styleClone.cutTickets.map(ct => ct.colors));
    const sizeNames = _.uniq(
      _.flattenDeep(
        styleClone.cutTickets.map(
          ct => ct.colors && ct.colors.map(c => c.color.sizes && c.color.sizes.map(s => s.name))
        )
      )
    );

    let packings = _.cloneDeep(
      styleClone.packings.map(pk => {
        const groupColor = _.groupBy(pk.items, item => item.color);
        pk.items = Object.values(groupColor)
          .map(cs => {
            const item = {};
            cs.forEach(c => {
              if (['gwKgs', 'nwKgs'].includes(c.name)) {
                pk.props[c.name] = c.value;
              }
              item[c.name] = c.actualQuantity || c.planQuantity;
              item.color = (colors.find(c2 => c2.color._id == c.color) || {}).color;
            });
            return item;
          })
          .filter(item => item.color);
        return pk;
      })
    );
    packings = _.sortBy(
      Object.values(_.groupBy(packings, pk => pk.props.group)).map(pks => {
        const [noBegin, noEnd] = pks[0].props.group.split('-');
        return pks.length == 1
          ? pks[0]
          : {
              items: [...pks[0].items],
              props: {
                ...pks[0].props,
                noBegin,
                noEnd,
              },
              childs: pks,
            };
      }),
      pk => pk.props.group
    );
    return { packings, sizeNames };
  };

  render() {
    const { style, getActiveStyle } = this.props;
    const active = getActiveStyle(style._id);
    const { packings, sizeNames } = this.defineDataShow(style);
    return (
      <div>
        <div className="mg-bottom-10">
          <BarTitle
            classActive={active ? 'rotate' : ''}
            type="style"
            name={style.style.style}
            id={style._id}
            changeStyleActive={this.changeStyleActive}
          />
          {/* {active == true ? cutTicketContainers : null} */}
          {active &&
            style.cutTickets.map((cutTicket, index) => {
              return (
                <div>
                  <div className="d-flex justify-content-between pd-10">
                    <div className="d-flex">
                      <p className="size-12">{I18n.t('PageShiping.CutTicket')}</p>
                      <span className="color-orange bold mg-left-50 size-14">
                        {cutTicket.cutTicket.cutTicket || ''}
                      </span>
                    </div>

                    <div className="d-flex justify-content-end">
                      <div className="d-flex mg-right-30">
                        <p className="size-12 mg-right-15">{I18n.t('PageShiping.Lable')}</p>
                        <span className="size-12 bold">{cutTicket.cutTicket.mainLabel || ''}</span>
                      </div>
                      <div className="d-flex mg-right-30">
                        <p className="size-12 mg-right-15">{I18n.t('PageShiping.Store')}</p>
                        <span className="size-12 bold">
                          {(cutTicket.cutTicket.store && cutTicket.cutTicket.store.name) || ''}
                        </span>
                      </div>
                      <div className="size-12 color-link">
                        <a>
                          {(cutTicket.cutTicket.store &&
                            cutTicket.cutTicket.store.packingStandard &&
                            cutTicket.cutTicket.store.packingStandard.name) ||
                            ''}
                        </a>
                      </div>
                    </div>
                  </div>
                  {cutTicket.colors &&
                    cutTicket.colors.map((color, index) => {
                      const { actualQuantity, remaining } = this.getActualAndRemainingPacking(
                        cutTicket.cutTicket._id,
                        color.color._id
                      );
                      return (
                        <div className="bg-white mg-top-2">
                          <div
                            style={{
                              height: 50,
                              backgroundColor: 'white',
                              paddingTop: 15,
                              paddingLeft: '1%',
                              paddingRight: '1%',
                            }}>
                            <div className="d-flex justify-content-between">
                              <div>
                                <span style={store.setStyleType('font_1')}>{I18n.t('PageShiping.Color')}</span>
                                <span style={{ marginLeft: 10 }}>{color.color.name}</span>
                              </div>
                              <div className="d-flex justify-content-between" style={{ width: '25%' }}>
                                <div>
                                  <span style={store.setStyleType('font_1', 'align_1')}>
                                    {I18n.t('PageShiping.Total')}
                                  </span>
                                  <span style={{ color: '#D20020' }}>{actualQuantity}</span>
                                </div>
                                <div>
                                  <span style={store.setStyleType('font_1', 'align_1')}>
                                    {I18n.t('PageShiping.Remaining')}
                                  </span>
                                  <span className="mr-10" style={{ color: '#52B244 ' }}>
                                    {remaining}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              );
            })}
          {active && (
            <div className="bg-white mg-top-2">
              <table className="global-table w-full">
                <tbody>
                  <tr>
                    <th className="left">{I18n.t('PageShiping.CTNNo')}</th>
                    <th className="left">{I18n.t('PageShiping.NW')}</th>
                    <th className="left">{I18n.t('PageShiping.GW')}</th>
                    <th className="left">{I18n.t('PageShiping.MEANS')}</th>
                    <th className="left">{I18n.t('PageShiping.M3')}</th>
                    <th className="left">Color</th>
                    {sizeNames.map((sizeName, idx) => (
                      <th key={idx} className="center">
                        {sizeName}
                      </th>
                    ))}
                    <th className="center">{I18n.t('PageShiping.QTY')}</th>
                    <th className="center">{I18n.t('PageShiping.QTY/CTN')}</th>
                    <th className="center">{I18n.t('PageShiping.Total')}</th>
                    <th className="center"></th>
                  </tr>
                  {packings.map((pk, idx) => {
                    const qty_ctn = pk.items.reduce(
                      (x, y) => x + Object.values(y).reduce((a, b) => a + (Number(b) || 0), 0),
                      0
                    );
                    return pk.items.map((item, itemIdx) => {
                      return (
                        <tr style={{ borderBottom: '1px solid #ccc' }}>
                          {itemIdx == 0 &&
                            (pk.childs ? (
                              <td className="center" rowspan={pk.items.length}>
                                {pk.props.noBegin}&nbsp;&nbsp;&rArr;&nbsp;&nbsp;{pk.props.noEnd}
                              </td>
                            ) : (
                              <td className="center" rowspan={pk.items.length}>
                                {pk.props.no}
                              </td>
                            ))}
                          {itemIdx == 0 && (
                            <td className="center" rowspan={pk.items.length}>
                              {pk.props.nwKgs}
                            </td>
                          )}
                          {itemIdx == 0 && (
                            <td className="center" rowspan={pk.items.length}>
                              {pk.props.gwKgs}
                            </td>
                          )}
                          {itemIdx == 0 && (
                            <td className="center" rowspan={pk.items.length}>
                              {pk.props.l}" x {pk.props.w}" x {pk.props.h}"
                            </td>
                          )}
                          {itemIdx == 0 && (
                            <td className="center" rowspan={pk.items.length}>
                              {pk.props.m3}
                            </td>
                          )}
                          <td className="center">{item.color.name}</td>
                          {sizeNames.map((sizeName, idx) => (
                            <td className="center color-orange">{item[sizeName] || 0}</td>
                          ))}
                          {itemIdx == 0 && (
                            <td className="center color-green" rowspan={pk.items.length}>
                              {pk.childs ? pk.childs.length : 1}
                            </td>
                          )}
                          {itemIdx == 0 && (
                            <td className="center color-green" rowspan={pk.items.length}>
                              {qty_ctn}
                            </td>
                          )}
                          {itemIdx == 0 && (
                            <td className="center color-red" rowspan={pk.items.length}>
                              {qty_ctn * (pk.childs ? pk.childs.length : 1)}
                            </td>
                          )}
                          {itemIdx == 0 && <td className="center" rowspan={pk.items.length}></td>}
                        </tr>
                      );
                    });
                  })}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  getActiveStyle: selector.getActiveStyle(state),
});

const mapDispatchToProps = dispatch => ({
  changeStyleActive(id) {
    dispatch(changeStyleActive(id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewStyleContainerPrepack);
