import React, { Component } from 'react';
import { Store } from '../../../CssHandleModule/functions';
import I18n from 'i18n-js';
import { changeStyleActive } from '../../../../actions/ship-out/viewPklOfProdDetailActions';
import { changeOrderActive } from '../../../../actions/ship-out/createPklShipActions';
import { connect } from 'react-redux';

const store = new Store();
store.setStyles({
    orderNumbers: [1],
    aligns: [
        { order: 1, margin: { right: '3%' } }
    ]
});



class HeaderforType extends Component {

    constructor(props) {
        super(props);
        this.state = {
            caret: "left"
          };
    }

    changeActive = (type, id) => {
        if (type === 'style') {
            this.props.changeStyleActive(id);
        } else {
            const { active } = this.props;
            this.props.changeOrderActive(!active, id);
        }
        let {caret} = this.state;
        if(caret == "down") {
            this.setState({ caret: "left"});
        } else {
            this.setState({ caret: "down"});
        }

    }

    render() {
        const { id, type, name, commodity, classActive } = this.props;
        const {caret} = this.state;
        return (
            <div className="panel-pkl-production-view inline-block w-full">
                <div className="d-flex panel-title cursor-pointer" onClick={() => this.changeActive(type, id)} style={{
                    height: 40,
                    backgroundColor: `#4a4a4a`,
                    color: 'white',
                    paddingTop: 10
                }}>
                    <span className={`${classActive} icon fa fa-caret-${caret} cursor-pointer`}></span>
                    {type === 'style' &&
                        <div>
                            <span className="size-12 mg-right-30">{I18n.t('PageShiping.Style')}</span>
                            <span className="size-15">{name}</span>
                        </div>
                    }
                    {type === 'customer_po' &&
                        <div className="d-flex" style={{ width: '100%' }}>
                            <div className="mg-right-30">
                                <span className="size-12 mg-right-30">{I18n.t('PageShiping.CustomerPO')}</span>
                                <span className="size-15">{name}</span>
                            </div>
                            {commodity &&
                                <div>
                                    <span className="size-12 mg-right-30">{I18n.t('PageShiping.Commodity')}</span>
                                    <span className="size-15">{commodity}</span>
                                </div>}
                        </div>
                    }
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({
})

export default connect(null, mapDispatchToProps)(HeaderforType);