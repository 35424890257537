import { createSelector } from 'reselect';
import _ from 'lodash';
import {genDollarToText} from '../../../functions/helpers'
export const isFetchingSelectableCustomer = ({ createReceivableInvoiceChargeback }) => {
  return createReceivableInvoiceChargeback.fetchingSelectableCustomers;
}

export const getSelectableCustomers = ({ createReceivableInvoiceChargeback }) => {
  return _.map(createReceivableInvoiceChargeback.selectableCustomers, s => ({
    value: s.customerId,
    label: s.name
  }));
}

export const getSelectedCustomer = ({ createReceivableInvoiceChargeback }) => {
  return createReceivableInvoiceChargeback.selectedCustomer;
}

// export const getInvoiceNo = ({ createReceivableInvoiceChargeback }) => {
//   return createReceivableInvoiceChargeback.invoiceNo;
// }

export const getIssuedDate = ({ createReceivableInvoiceChargeback }) => {
  return createReceivableInvoiceChargeback.issuedDate;
}

export const getCheckableBills = ({ createReceivableInvoiceChargeback }) => {
  return createReceivableInvoiceChargeback.checkableBillList;
}

export const getCheckedBills = ({ createReceivableInvoiceChargeback }) => {
  return createReceivableInvoiceChargeback.checkedBillList;
}

export const getListNoAvailable = ({ createReceivableInvoiceChargeback }) => {
  let arr = [];
  
  return arr;
}

export const getDataRequestShipOutByCustomer = ({ createReceivableInvoiceChargeback }) => {
  let obj = {};
  obj.chargeBack = true;
  obj.billNo = _.map(createReceivableInvoiceChargeback.checkedBillList, el => el.billNo);
  obj.billIds = _.map(createReceivableInvoiceChargeback.checkedBillList, el => el.id);
  obj.shipMode ='Air'
  obj.customer = _.get(createReceivableInvoiceChargeback, 'selectedCustomer.value', null);
  return obj;
}

/**
 * Return a function to generate request body which will be used to send to api
 */
export const fnGenerateRequestBody = ({ createReceivableInvoiceChargeback }) => () => {
  throw "NotImplementedException";
}



export const getDataApi = ({ createReceivableInvoiceChargeback }) => {
  let data = {};
  data.chargeBack = true;
  data.customer = _.get(createReceivableInvoiceChargeback, 'selectedCustomer.value', null);
  data.issuedDate = createReceivableInvoiceChargeback.issuedDate;
  data.debitAmount = _.reduce(createReceivableInvoiceChargeback.checkedBillList, (total1, bill) => {
    return total1 + _.reduce(bill.customerPOs, (total2, item) => {
      return total2 + _.reduce(item.data, (total3, el) => {
        let rate = parseFloat(el.rate) || 0;
        let us = parseFloat(el.feeInUsa) || 0;
        let de = parseFloat(el.deduct) || 0;
        return parseFloat(total3 + rate * el.cbm.cw + us - de).toFixed(2);
      }, 0);
    }, 0);
  }, 0);
  data.bills = [];
  _.each(createReceivableInvoiceChargeback.checkedBillList, (item) => {
    let bill = {}
    bill.billNo = item.billNo;
    bill.id = item.id;
    let orders = [];
    _.each(item.customerPOs, (elem) => {
      let tmp = {};
      tmp.order = _.get(elem, 'data[0].orderId');
      tmp.reason = elem.reason;
      tmp.note = elem.note;
      tmp.items = [];
      _.each(elem.data, (o) => {
        tmp.items.push({
          cbm: o.cbm || null,
          style: o.style,
          rate: o.rate || null,
          airFee: o.airFee || null,
          feeInUsa: o.feeInUsa || null,
          deduct: o.deduct || null,
          debitAmount: o.debitAmount || null,
          payer: o.payer || null,
          no: o.no || null,
          rateLM: o.rateLM || null,
          cutTicket: o.cutTicket,
          cutTicketId: o.cutTicketId
        })
      })

      orders.push(tmp);
    })
    bill.orders = orders;
    data.bills.push(bill);
  });
  return data;
}

export const getTotalAirFee = ({ createReceivableInvoiceChargeback }) => {
  return _.reduce(createReceivableInvoiceChargeback.checkedBillList, (total1, bill) => {
    return total1 + _.reduce(bill.customerPOs, (total2, item) => {
      return total2 + _.reduce(item.data, (total3, el) => {
        return total3 + (el.rate || 0) * el.cbm.cw;
      }, 0);
    }, 0);
  }, 0);
}

export const getTotalDebitAmount = ({ createReceivableInvoiceChargeback }) => {
  return _.reduce(createReceivableInvoiceChargeback.checkedBillList, (total1, bill) => {
    return total1 + _.reduce(bill.customerPOs, (total2, item) => {
      return total2 + _.reduce(item.data, (total3, el) => {
        return total3 + (el.rate || 0) * el.cbm.cw + (el.feeInUsa || 0) - (el.deduct || 0);
      }, 0);
    }, 0);
  }, 0);
}

export const getCountNo = ({ createReceivableInvoiceChargeback }) => {
  return createReceivableInvoiceChargeback.countNo;
}

export const getAvailableNoList = ({ createReceivableInvoiceChargeback }) => {
  return createReceivableInvoiceChargeback.availableNoList;
}

export const getSayMoney = createSelector(
  [getTotalDebitAmount],
  (amount) => {
    if(!amount) return "";
    return genDollarToText(amount);
  }
)

export const getSuccess = ({ createReceivableInvoiceChargeback }) => {
  return createReceivableInvoiceChargeback.success;
}