import React from 'react';
import {sampleApprovalStatus} from '../../../constants/EnumTypes';
import _ from 'lodash';
import moment from 'moment';
import I18n from 'i18n-js';

export default (props)=>{
  const data = _.groupBy(props.auditLog.materials.sort((a,b) => new Date(b.date) - new Date(a.date)) || [], i => moment(i.date).format('MMM DD, YYYY'));

  return (
    <div className="modal-dialog" role="document">
            <div className="modal-content" style={{width: `650px`}}>
              <div className="modal-header">
                <a className="close" onClick={() => {props.closeModal()}}><span aria-hidden="true">&times;</span></a>
                <h4 className="modal-title" id="myModalLabel">{I18n.t('PageSampleApproval.AUDITLOG')}</h4>
              </div>
              <div className="modal-body pd-0">
                <ul className="list-log-history material-log-history">
                  {Object.keys(data).map((k, idx) => (
                    <li key={idx}>
                      <h3>{k}</h3>
                      <div className="pd-15 bg-white">
                        <table style={{width: '100%'}} className="global-table">
                          <tbody>
                            {
                              data[k].reduce((acc, i) => {
                                return _.get(i, 'data.remark', '') != '' ? _.concat(acc, [i, _.set(_.cloneDeep(i), 'showRemark', true)]) : _.concat(acc, i);
                              }, [])
                              .map((i, idx) => {
                                let status = _.get(i, 'data.status', '');
                                let className = sampleApprovalStatus.APPROVED === status ? 'color-green'
                                  : sampleApprovalStatus.REJECTED === status ? 'color-red'
                                  : sampleApprovalStatus.RESUBMITTED === status ? 'color-yellow' : '';

                                return !i.showRemark ? (
                                  <tr key={idx} className="bg-white">
                                    <td className="color-gray">{moment(i.date).format('HH:mm A')}</td>
                                    <td><b>{_.get(i, 'type', '').toLowerCase().split(`-`)[0].charAt(0).toUpperCase() + _.get(i, 'type', '').toLowerCase().split(`-`)[0].slice(1) + ` ` + _.get(i, 'type', '').toLowerCase().split(`-`)[1].charAt(0).toUpperCase() + _.get(i, 'type', '').toLowerCase().split(`-`)[1].slice(1)}</b></td>
                                    <td>{_.get(i, 'material.name', '')}</td>
                                    <td className={className}>{_.capitalize(status)}</td>
                                    <td>{moment(_.get(i, 'data.date') || {}).format('MMM DD, YYYY')}</td>
                                  </tr>
                                  ) : (
                                  <tr key={idx} className="bg-white text">
                                    <td />
                                    <td colSpan="3">
                                      <div className="bg-gray radius-5 pd-10">{_.truncate(_.get(i, 'data.remark', ''), {length: 120, separator: ' '})}</div>
                                      <br/>
                                    </td>
                                  </tr>
                                  );
                              })
                            }
                          </tbody>
                        </table>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
  );
}