import React, { Component } from 'react'
import { connect } from 'react-redux'
import authActions from '../../actions/authActions'
import { bindActionCreators } from 'redux'
import I18n from 'i18n-js';
import { toastr } from 'react-redux-toastr'
import _ from 'lodash';
import PropTypes from 'prop-types';

import faEyeIcon from '../../stylesheets/images/fa-eye.svg';
import faEyeSlashIcon from '../../stylesheets/images/fa-eye-slash.svg';

class ChangePassword extends Component {

  constructor(props) {
    super(props)
    this.state = {
      typeNewPass: true,
      typeConfirmPass: true,
      error: {},
      data: {
        password: '',
        newpassword: '',
        passwordConfirm: ''
      }
    }
  }

  validation = () => {
    const params = _.clone(this.state.data)
    const error = {}
    let bool = true
    if (params.password.length < 6) {
      error.password = I18n.t('ResetPassword.EmptyOrLength')
      bool = false
    }
    if (params.newpassword.length < 6) {
      error.newpassword = I18n.t('ResetPassword.EmptyOrLength')
      bool = false
    }
    if (params.passwordConfirm.length < 6) {
      error.passwordConfirm = I18n.t('ResetPassword.EmptyOrLength')
      bool = false
    }
    if (params.newpassword != params.passwordConfirm) {
      error.passwordConfirm = I18n.t('ResetPassword.ConfirmPasswordIncorrect')
      bool = false
    }
    !bool && this.setState({ error })
    return bool
  }

  onChangePassword = () => {
    const bool = this.validation()
    const { closeModal } = this.props
    const params = _.clone(this.state.data)

    bool && this.props.authPropsActions.changePassword(params).then(data => {
      data.success && toastr.success('Success', I18n.t('ResetPassword.ChangeSuccess'))
      !data.success && data.error.statusCode != 601 && toastr.error('Error', data.error.error)
      !data.success && data.error.statusCode == 601 && this.setState({ error: { password: I18n.t('ResetPassword.RecentPassword') } })
      data.success && closeModal()
    })
  }

  onChange = (event) => {
    this.setState({ data: { ...this.state.data, [event.target.name]: event.target.value } })
  }

  render = () => {
    const { data, typeNewPass, typeConfirmPass, error } = this.state
    const { closeModal } = this.props
    return (
      <div className="change-password">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <form>
              <div className="modal-header">
                <h4 className="modal-title" id="myModalLabel">{I18n.t('Profile.ChangePassword')}</h4>
              </div>
              <div className="modal-body">
                <div className="form-group">
                  <div className="col-md-4 col-xs-12 text">
                    <p>{I18n.t('Profile.RecentPassword')}</p>
                  </div>
                  <div className="col-md-8 col-xs-12 value">
                    <input type="password" className="form-control" name="password" value={data.password} onChange={this.onChange} />
                    {error.password && <div className="alert-warning">{error.password}</div>}
                  </div>
                </div>
                <div className="form-group">
                  <div className="col-md-4 col-xs-12 text">
                    <p>{I18n.t('Profile.NewPassword')}</p>
                  </div>
                  <div className="col-md-8 col-xs-12 value">
                    <input type={this.state.typeNewPass ? "password" : "text"} className="form-control" name="newpassword" value={data.newpassword} onChange={this.onChange} />
                    <span onClick={() => { this.setState({ typeNewPass: !this.state.typeNewPass }) }}>
                      <img src={this.state.typeNewPass ? faEyeIcon : faEyeSlashIcon} alt="" />
                    </span>
                    {error.newpassword && <div className="alert-warning">{error.newpassword}</div>}
                  </div>
                </div>
                <div className="form-group">
                  <div className="col-md-4 col-xs-12 text">
                    <p>{I18n.t('Profile.ConfirmPassword')}</p>
                  </div>
                  <div className="col-md-8 col-xs-12 value">
                    <input type={this.state.typeConfirmPass ? "password" : "text"} className="form-control" name="passwordConfirm" value={data.passwordConfirm} onChange={this.onChange} />
                    <span onClick={() => { this.setState({ typeConfirmPass: !this.state.typeConfirmPass }) }}>
                      <img src={this.state.typeConfirmPass ? faEyeIcon : faEyeSlashIcon} alt="" />
                    </span>
                    {error.passwordConfirm && <div className="alert-warning">{error.passwordConfirm}</div>}
                  </div>
                </div>
              </div>
              <div className="modal-footer inline-block w-full">
                <button onClick={closeModal} type="button" className="bt-black" data-dismiss="modal">{I18n.t('Btn.CANCEL')}</button>
                <button onClick={this.onChangePassword} type="button" className="bt-orange mg-l-5" data-dismiss="modal">{I18n.t('Btn.OK')}</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}


const mapStateToProps = (state) => {
  return {
    authState: state.authState
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    authPropsActions: bindActionCreators(authActions, dispatch)
  }
}

ChangePassword.contextTypes = {
  router: PropTypes.object.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword)

