import C from '../../constants/ship-out/showPklShipList';
import {combineReducers} from 'redux';

const filter = (state = null, action) => {
    const {type, payload} = action;
    switch(type) {
        case C.CHANGE_SELECTED_FILTER:
            return payload;
        default:
            return state
    }
}

const searchValue = (state = {}, action) => {
    const { type, payload } = action;
    switch (type) {
        case C.CHANGE_SEARCH_TEXT:
            return {...state, [payload.pos]: payload.data};
        default:
            return state;
    }
}

const displayList = (state = [], action) => {
    const { type, payload } = action;
    switch (type) {
        case C.CHANGE_PKL_SHIP_LIST:
            return payload;
        default:
            return state;
    }
}

const currentPage = (state = 1, action) => {
    const { type, payload } = action;
    switch (type) {
        case C.CHANGE_PAGING_CURRENT:
            return payload;
        default:
            return state;
    }
}

const totalPages = (state = null, action) => {
    const { type, payload } = action;
    switch (type) {
        case C.CHANGE_PAGING_TOTAL:
            return payload;
        default:
            return state;
    }
}

const checkedList = (state = [], action) => {
    const {type, payload} = action;
    switch (type) {
        case C.ADD_CHECKED_PKL_SHIP: 
            return [...state, payload];
        case C.REMOVE_CHECKED_PKL_SHIP:
            let temp = state.filter(it => it._id !== payload._id);
            return temp;
        case C.CLEAR_ALL_CHECKED_PKL_SHIPS:
            return [];
        default:
            return state;
    }
}

const searched = (state = false, action) => {
    const {type, payload} = action;
    switch(type) {
        case C.CHANGE_SEARCH_STATUS:
            return payload;
        default:
            return state;
    }
}

const deletePklShip = (state = false, action) => {
    const {type, payload} = action;
    switch(type) {
        case C.CHANGE_PKL_SHIP_LIST:
            return false;
        case C.DELETE_SUCCESS:
            return true;
        default:
            return state;
    }
}

export const showPklShipList = combineReducers ({
    deletePklShip,
    filter,
    searchValue,
    displayList,
    currentPage,
    totalPages,
    checkedList,
    searched,
})