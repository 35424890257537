import React, { Component } from 'react';
import I18n from 'i18n-js'
import { Store } from '../../../CssHandleModule/functions';
import { connect } from 'react-redux';
import { changeCommondity } from '../../../../actions/ship-out/createPklShipActions';
import * as selector from '../../../../selectors/ship-out/createPklShip';
const store = new Store();

store.setStyles({
    orderNumbers: [1, 2, 3],
    aligns: [
        { order: 1, margin: { top: '10px' } },
        { order: 2, padding: { left: '2%', right: '2%' } }
    ],
    sizes: [
        { order: 1, height: '30px' }
    ],
    borders: [
        { order: 1, bottom: '1px dashed gray' },
        { order: 2, right: '1px dashed gray' }
    ]
});

class CustomerPOCreateShip extends Component {
    constructor(props) {
        super(props)
    }

    changeCommondity = (orderId, value) => {
        this.props.changeCommondity(orderId, value)
    }
    render() {
        const { orderDetail, orderId, getCommondity } = this.props;
        const commodity = getCommondity(orderId)
        return (
            <div className="mg-bottom-5 box-shadow">
                <div className='row m-0 size-12 bg-white'>
                    <div className='col bd-dashed-right-1 pd-bottom-15'>
                        <p className="bd-dashed-bottom pd-5-0">{I18n.t('PageShiping.Self')}</p>
                        {orderDetail.self.map((item, index) => {
                            return (
                                <p key={index} style={store.setStyleType('align_1')}>{item}</p>
                            )
                        })}
                    </div>
                    <div className='col bd-dashed-right-1 pd-bottom-15'>
                        <p className="bd-dashed-bottom pd-5-0">{I18n.t('PageShiping.Description')}</p>
                        <p style={store.setStyleType('align_1')}>{orderDetail.description}</p>
                    </div>
                    <div className='col textarea pd-bottom-15'>
                        <p className="bd-dashed-bottom pd-5-0">{I18n.t('PageShiping.Commodity')} <span className="color-red"> *</span></p>
                        <textarea style={store.setStyleType('align_1')} value={commodity || ''} className='form-control'
                            onChange={(e) => this.changeCommondity(orderId, e.target.value)}
                        ></textarea>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    getCommondity: selector.getCommondity(state)
})

const mapDispatchToProps = dispatch => ({
    changeCommondity(orderId, value) {
        dispatch(changeCommondity(orderId, value))
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(CustomerPOCreateShip);
