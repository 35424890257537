import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import { push } from 'react-router-redux';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import authActions from '../../../actions/authActions';
import appActions from '../../../actions/appActions';
import * as types from '../../../constants/actionTypes';
import * as utils from '../../../functions/utils';
import Profile from '../../profile';
import _ from 'lodash';
import I18n from 'i18n-js';
import * as R from 'ramda';

import * as PATH from '../../../constants/routeConstants';

import {
  ACCOUNTING_URL,
  ACCOUNTING_PAYABLE_URL,
  ACCOUNTING_RECEIVABLE_URL,
  ACCOUNTING_CREDIT_DEBIT_URL,
  CRM_URL,
  CUSTOMER_PO_URL,
  ORGANIZATION_URL,
  PRODUCTION_URL,
  PURCHASE_URL,
  REPORT_ANALYTIC_URL,
  SAMPLE_APPROVAL_URL,
  SHIPPING_URL,
  STANDARD_URL,
  SHIPPING_SHIP_IN_URL,
  SHIPPING_SHIP_OUT_URL,
  SYSTEM_SETTING_URL,
  ACCOUNTING_PAYABLE_PLAN_SUPPLIER_DOMESTIC_URL,
  ACCOUNTING_PAYABLE_PLAN_SUPPLIER_OVERSEA_URL,
  WAREHOUSE_URL,
  YIELD_URL,
  SHIP_OUT,
  ACCOUNTING_PAYABLE_PLAN_URL,
  ACCOUNTING_PAYABLE_PLAN_IMPORTEXPORT_DECLARATION_PREVIEW_URL,
  ACCOUNTING_PAYABLE_PLAN_IMPORTEXPORT_US_DELIVERY_PREVIEW_URL,
  ACCOUNTING_PAYABLE_PLAN_IMPORTEXPORT_CONTAINER_PREVIEW_URL,
  ACCOUNTING_PAYABLE_INVOICE_CONTAINER_EDIT_URL,
  ACCOUNTING_PAYABLE_INVOICE_VIEW_URL,
  ACCOUNTING_PAYABLE_INVOICE_DECLARATION_EDIT_URL,
  ACCOUNTING_PAYABLE_INVOICE_US_DELIVERY_EDIT_URL,
  ACCOUNTING_PAYABLE_INVOICE_CONTRACTOR_EDIT_URL,
  ACCOUNTING_PAYABLE_INVOICE_DOMESTIC_EDIT_URL,
  ACCOUNTING_PAYABLE_INVOICE_OVERSEA_EDIT_URL,
  ACCOUNTING_PAYABLE_HISTORY_CONTAINER_VIEW_URL,
  ACCOUNTING_PAYABLE_HISTORY_DECLARATION_VIEW_URL,
  ACCOUNTING_PAYABLE_HISTORY_CONTRACTOR_VIEW_URL,
  ACCOUNTING_PAYABLE_HISTORY_OVERSEA_VIEW_URL,
  ACCOUNTING_PAYABLE_HISTORY_DOMESTIC_VIEW_URL,
  ACCOUNTING_PAYABLE_PLAN_SUPPLIER_DOMESTIC_PREVIEW_URL,
  ACCOUNTING_PAYABLE_PLAN_SUPPLIER_OVERSEA_PREVIEW_URL,
  ACCOUNTING_PAYABLE_PLAN_CONTRACTOR_PREVIEW_URL,
  ACCOUNTING_RECEIVABLE_PLAN_VIEW_CHARGE_BACK_URL,
  ACCOUNTING_RECEIVABLE_INVOICE_EDIT_NORMAL_URL,
  ACCOUNTING_RECEIVABLE_INVOICE_EDIT_CHARGEBACK_URL,
  ACCOUNTING_RECEIVABLE_PLAN_EDIT_CHARGE_BACK_URL,
} from '../../../constants/routeConstants';

import { PRODUCTION_SCREEN, PRODUCTION_TAB, PURCHASE_SCREEN, PURCHASE_TAB } from '../../../constants/constants';

import icDashboardIcon from '../../../stylesheets/images/ic-dashboard.svg';
import icCustomerIcon from '../../../stylesheets/images/ic-customer.svg';
import icYieldCIcon from '../../../stylesheets/images/ic-yield-c.svg';
import icSampleApprovalIcon from '../../../stylesheets/images/ic-sample-approval.svg';
import icPurchaseIcon from '../../../stylesheets/images/ic-purchase.svg';
import icWarehouseCIcon from '../../../stylesheets/images/ic-warehouse-c.svg';
import icProductionIcon from '../../../stylesheets/images/ic-production.svg';
import icShippingIcon from '../../../stylesheets/images/ic-shipping.svg';
import icAccountingIcon from '../../../stylesheets/images/ic-accounting.svg';
import icReportIcon from '../../../stylesheets/images/ic-report.svg';
import icUserIcon from '../../../stylesheets/images/ic-user.svg';
import icSystemIcon from '../../../stylesheets/images/ic-system.svg';
import icOrganizationCIcon from '../../../stylesheets/images/ic-organization-c.svg';
import icEditYieldIcon from '../../../stylesheets/images/ic-edit-yield.svg';
import icPayableCIcon from '../../../stylesheets/images/ic-payable-c.svg';
import icPayableDIcon from '../../../stylesheets/images/ic-payable-d.svg';
import icReceivableCIcon from '../../../stylesheets/images/ic-receivable-c.svg';
import icReceivableDIcon from '../../../stylesheets/images/ic-receivable-d.svg';
import icCreditCIcon from '../../../stylesheets/images/ic-credit-c.svg';
import icCreditDIcon from '../../../stylesheets/images/ic-credit-d.svg';
import icShipInCIcon from '../../../stylesheets/images/ic-ship-in-c.svg';
import icShipInDIcon from '../../../stylesheets/images/ic-ship-in-d.svg';
import icShipOutCIcon from '../../../stylesheets/images/ic-ship-out-c.svg';
import icShipOutDIcon from '../../../stylesheets/images/ic-ship-out-d.svg';
import icAssignmentCIcon from '../../../stylesheets/images/ic-assignment-c.svg';
import icAssignmentIcon from '../../../stylesheets/images/ic-assignment.svg';
import icProductionCIcon from '../../../stylesheets/images/ic-production-c.svg';
import icQACIcon from '../../../stylesheets/images/ic-qa-c.svg';
import icQAIcon from '../../../stylesheets/images/ic-qa.svg';
import icPackingCIcon from '../../../stylesheets/images/ic-packing-c.svg';
import icPackingIcon from '../../../stylesheets/images/ic-packing.svg';
import icWarehouseIcon from '../../../stylesheets/images/ic-warehouse.svg';
import icStockInCIcon from '../../../stylesheets/images/ic-stock-in-c.svg';
import icStockInIcon from '../../../stylesheets/images/ic-stock-in.svg';
import icStockOutHvIcon from '../../../stylesheets/images/ic-stock-out-hv.svg';
import icStockOutIcon from '../../../stylesheets/images/ic-stock-out.svg';
import icSampleApprovalCIcon from '../../../stylesheets/images/ic-sample-approval-c.svg';
import icSampleApprovalDIcon from '../../../stylesheets/images/ic-sample-approval-d.svg';
import icHistoryCIcon from '../../../stylesheets/images/ic-history-c.svg';
import icHistoryDIcon from '../../../stylesheets/images/ic-history-d.svg';
import icPlansCIcon from '../../../stylesheets/images/ic-plans-c.svg';
import icPlansDIcon from '../../../stylesheets/images/ic-plans-d.svg';
import icFollowUpCIcon from '../../../stylesheets/images/ic-follow-up-c.svg';
import icFollowUpDIcon from '../../../stylesheets/images/ic-follow-up-d.svg';
import icPurchaseListCIcon from '../../../stylesheets/images/ic-purchase-list-c.svg';
import icPurchaseListDIcon from '../../../stylesheets/images/ic-purchase-list-d.svg';
import icCancelHighlightIcon from '../../../stylesheets/images/ic-cancel-highlight.svg';
import icCancelIcon from '../../../stylesheets/images/ic-cancel.svg';
import icEditCIcon from '../../../stylesheets/images/ic-edit-c.svg';
import icEditDIcon from '../../../stylesheets/images/ic-edit-d.svg';
import icCrmCIcon from '../../../stylesheets/images/ic-crm-c.svg';
import icSystemCIcon from '../../../stylesheets/images/ic-system-c.svg';
import icReportCIcon from '../../../stylesheets/images/ic-report-c.svg';
import icStandardCIcon from '../../../stylesheets/images/ic-standard-c.svg';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menus: [
        {
          prefix: 'dashboard',
          name: I18n.t('Menu.Dashboard'),
          url: '/',
          text: 'Dashboard',
          img: icDashboardIcon,
          role: [],
        },
        {
          prefix: 'dashboard',
          name: I18n.t('Menu.CustomerPO'),
          url: '/customer-po',
          text: 'Customer PO',
          img: icCustomerIcon,
          role: [],
        },
        {
          prefix: 'dashboard',
          name: I18n.t('Menu.Yield'),
          url: '/yield',
          text: 'Yield',
          img: icYieldCIcon,
          role: [],
        },
        {
          prefix: 'dashboard',
          name: I18n.t('Menu.SampleApproval'),
          url: '/sample-approval',
          text: 'Sample Approval',
          img: icSampleApprovalIcon,
          role: [],
        },
        {
          prefix: 'dashboard',
          name: I18n.t('Menu.Purchase'),
          url: '/purchase',
          text: 'Purchase',
          img: icPurchaseIcon,
          role: [],
        },
        {
          prefix: 'dashboard',
          name: I18n.t('Menu.Warehouse'),
          url: '/warehouse',
          text: 'Warehouse',
          img: icWarehouseCIcon,
          role: [],
        },
        {
          prefix: 'dashboard',
          name: I18n.t('Menu.Production'),
          url: '/production',
          text: 'Production',
          img: icProductionIcon,
          role: [],
        },
        {
          prefix: 'dashboard',
          name: I18n.t('Menu.Shipping'),
          url: '/shipping',
          text: 'Shipping',
          img: icShippingIcon,
          role: [],
        },
        {
          prefix: 'dashboard',
          name: I18n.t('Menu.Accounting'),
          url: '/accounting',
          text: 'Accounting',
          img: icAccountingIcon,
          role: [],
        },
        {
          prefix: 'report',
          name: I18n.t('Menu.ReportAnalytic'),
          url: '/report-analytic',
          text: 'Report & Analytic',
          img: icReportIcon,
          role: [],
        },
        {
          prefix: 'dashboard',
          name: I18n.t('Menu.Usermanagement'),
          url: '/user-manament',
          text: 'User management',
          img: icUserIcon,
          role: [],
        },
        {
          prefix: 'dashboard',
          name: I18n.t('Menu.SystemSettings'),
          url: '/system-settings',
          text: 'System Settings',
          img: icSystemIcon,
          role: [],
        },
        {
          prefix: 'organization',
          name: I18n.t('Menu.Organization'),
          url: '/organization',
          text: 'Organization',
          img: icOrganizationCIcon,
          role: [],
        },
        {
          prefix: 'crm',
          name: I18n.t('Menu.CRM'),
          url: '/crm',
          text: 'CRM',
          img: icSystemIcon,
          role: [],
        },
      ],
      menuCurrent: {},
    };
  }

  componentWillMount() {
    this.setState({ menuCurrent: this.getMenuCurrent() || {} });
  }

  componentDidMount() {
    window.onpopstate = () => {
      // this.props.appActions.setPageCurrent({});
      this.props.appActions.addPage([]);
    };
  }

  componentWillReceiveProps(newProps) {
    let { menuCurrent } = this.state;
    !_.isEqual(menuCurrent, newProps.appState.page) && this.setState({ menuCurrent: newProps.appState.page });
  }

  getMenuCurrent() {
    let { menus } = this.state;
    let pathname = R.path(['router', 'route', 'location', 'pathname'], this.props);
    let menuCurrent = menus.find(m => m.url === pathname);

    // set state
    menuCurrent &&
      this.props.dispatch({
        type: types.APP.SET_PAGE_CURRENT,
        data: {
          name: menuCurrent.name,
          url: menuCurrent.url,
          isEdit: false,
        },
      });

    return menus.find(m => m.url === pathname);
  }

  returnPage = () => {
    const clonePage = { ...this.props.appState.page };
    let pageList = _.cloneDeep(this.props.appState.pageList);
    if (pageList.length > 1) {
      pageList.pop();
      this.props.appActions.addPage(pageList);
    } else {
      const pageData = _.assign(_.cloneDeep(clonePage), { isEdit: false, isAdd: false });
      if (pageData.tab == 'packing') {
        this.props.dispatch({ type: 'ADD_PACKING_CHANGE_EMPTY_DATA', payload: null });
      }
      this.props.appActions.setPageCurrent(pageData);
      pageList.pop();
      this.props.appActions.addPage(pageList);
    }
  };

  setTabCurrent(tab = '') {
    this.props.dispatch({
      type: types.APP.SET_PAGE_CURRENT,
      data: _.assign(_.cloneDeep(this.props.appState.page), { tab: tab }),
    });
  }

  changeLanguage(language) {
    if (language != this.props.authState.language) {
      this.props.authPropsActions.setLanguage(language);
    }
  }

  signOut() {
    this.props.authPropsActions.signOut();
  }

  goProfile() {
    this.props.router.history.push('/profile');
  }

  getNavigations() {
    let { menuCurrent } = this.state;
    let addView = _.get(this.props, 'appState.pageList[0].data.isEdit', false);
    const { activeTab, activeScreen } = utils.getActiveScreen(this.props.appState);
    let pathname = R.path(['router', 'route', 'location', 'pathname'], this.props);
    let wrap = <ul />;
    let tabCurrent = _.get(menuCurrent, 'tab', '');
    let tabSub = _.get(menuCurrent, 'context.tabSub', '');
    let screen = this.props.appState.page.screen;
    wrap = (
      <Switch>
        <Route
          path={PATH.WAREHOUSE_INBOUND_EDIT_CONTRACTOR_URL}
          render={() => (
            <ul className="header-menu">
              <li className="cursor-pointer" onClick={() => this.props.router.history.goBack()}>
                <img src={icEditYieldIcon} />
                {I18n.t('Menu.Edit')}
              </li>
            </ul>
          )}
        />
        <Route
          path={PATH.WAREHOUSE_INBOUND_EDIT_SUPPLIER_URL}
          render={() => (
            <ul className="header-menu">
              <li className="cursor-pointer" onClick={() => this.props.router.history.goBack()}>
                <img src={icEditYieldIcon} />
                {I18n.t('Menu.Edit')}
              </li>
            </ul>
          )}
        />
        <Route
          path={PATH.WAREHOUSE_INBOUND_ADD_SUPPLIER_URL}
          render={() => (
            <ul className="header-menu">
              <li className="cursor-pointer" onClick={() => this.props.router.history.goBack()}>
                <img src={icEditYieldIcon} />
                {I18n.t('Menu.Add')}
              </li>
            </ul>
          )}
        />
        <Route
          path={PATH.WAREHOUSE_INBOUND_ADD_CONTRACTOR_URL}
          render={() => (
            <ul className="header-menu">
              <li className="cursor-pointer" onClick={() => this.props.router.history.goBack()}>
                <img src={icEditYieldIcon} />
                {I18n.t('Menu.Add')}
              </li>
            </ul>
          )}
        />
        <Route
          path={PATH.PURCHASE_PLANS_PREVIEW_ORDER_URL}
          render={() => (
            <ul className="header-menu">
              <li className="cursor-pointer" onClick={() => this.props.router.history.goBack()}>
                <img src={icEditYieldIcon} />
                {I18n.t('Menu.PreviewPurchaseOrder')}
              </li>
            </ul>
          )}
        />
        <Route
          path={PATH.PURCHASE_PLANS_PREVIEW_PLACE_ORDER_URL}
          render={() => (
            <ul className="header-menu">
              <li className="cursor-pointer" onClick={() => this.props.router.history.goBack()}>
                <img src={icEditYieldIcon} />
                {I18n.t('Menu.PlaceOrder')}
              </li>
            </ul>
          )}
        />

        <Route
          path={PATH.PURCHASE_PLANS_PREVIEW_URL}
          render={() => (
            <ul className="header-menu">
              <li className="cursor-pointer" onClick={() => this.props.router.history.goBack()}>
                <img src={icEditYieldIcon} />
                {I18n.t('Menu.PreviewPurchaseBySupplier')}
              </li>
            </ul>
          )}
        />

        <Route
          path={ACCOUNTING_PAYABLE_HISTORY_DOMESTIC_VIEW_URL}
          render={() => (
            <ul className="header-menu">
              <li className="cursor-pointer" onClick={() => this.props.router.history.goBack()}>
                <img src={icEditYieldIcon} />
                {I18n.t('Menu.View')}
              </li>
            </ul>
          )}
        />

        <Route
          path={ACCOUNTING_PAYABLE_HISTORY_CONTRACTOR_VIEW_URL}
          render={() => (
            <ul className="header-menu">
              <li className="cursor-pointer" onClick={() => this.props.router.history.goBack()}>
                <img src={icEditYieldIcon} />
                {I18n.t('Menu.View')}
              </li>
            </ul>
          )}
        />

        <Route
          path={ACCOUNTING_PAYABLE_HISTORY_OVERSEA_VIEW_URL}
          render={() => (
            <ul className="header-menu">
              <li className="cursor-pointer" onClick={() => this.props.router.history.goBack()}>
                <img src={icEditYieldIcon} />
                {I18n.t('Menu.View')}
              </li>
            </ul>
          )}
        />

        <Route
          path={ACCOUNTING_RECEIVABLE_PLAN_VIEW_CHARGE_BACK_URL}
          render={() => (
            <ul className="header-menu">
              <li className="cursor-pointer" onClick={() => this.props.router.history.goBack()}>
                <img src={icEditYieldIcon} />
                {I18n.t('Menu.View')}
              </li>
            </ul>
          )}
        />

        <Route
          path={ACCOUNTING_PAYABLE_HISTORY_CONTAINER_VIEW_URL}
          render={() => (
            <ul className="header-menu">
              <li className="cursor-pointer" onClick={() => this.props.router.history.goBack()}>
                <img src={icEditYieldIcon} />
                {I18n.t('Menu.View')}
              </li>
            </ul>
          )}
        />

        <Route
          path={PATH.ACCOUNTING_PAYABLE_HISTORY_US_DELIVERY_VIEW_URL}
          render={() => (
            <ul className="header-menu">
              <li className="cursor-pointer" onClick={() => this.props.router.history.goBack()}>
                <img src={icEditYieldIcon} />
                {I18n.t('Menu.View')}
              </li>
            </ul>
          )}
        />

        <Route
          path={ACCOUNTING_PAYABLE_HISTORY_DECLARATION_VIEW_URL}
          render={() => (
            <ul className="header-menu">
              <li className="cursor-pointer" onClick={() => this.props.router.history.goBack()}>
                <img src={icEditYieldIcon} />
                {I18n.t('Menu.View')}
              </li>
            </ul>
          )}
        />

        <Route
          path={ACCOUNTING_PAYABLE_PLAN_SUPPLIER_DOMESTIC_PREVIEW_URL}
          render={() => (
            <ul className="header-menu">
              <li className="cursor-pointer" onClick={() => this.props.router.history.goBack()}>
                <img src={icEditYieldIcon} />
                {I18n.t('Menu.Preview')}
              </li>
            </ul>
          )}
        />

        <Route
          path={ACCOUNTING_PAYABLE_PLAN_SUPPLIER_OVERSEA_PREVIEW_URL}
          render={() => (
            <ul className="header-menu">
              <li className="cursor-pointer" onClick={() => this.props.router.history.goBack()}>
                <img src={icEditYieldIcon} />
                {I18n.t('Menu.Preview')}
              </li>
            </ul>
          )}
        />
        <Route
          path={ACCOUNTING_PAYABLE_PLAN_CONTRACTOR_PREVIEW_URL}
          render={() => (
            <ul className="header-menu">
              <li className="cursor-pointer" onClick={() => this.props.router.history.goBack()}>
                <img src={icEditYieldIcon} />
                {I18n.t('Menu.Preview')}
              </li>
            </ul>
          )}
        />

        <Route
          path={ACCOUNTING_PAYABLE_PLAN_IMPORTEXPORT_DECLARATION_PREVIEW_URL}
          render={() => (
            <ul className="header-menu">
              <li className="cursor-pointer" onClick={() => this.props.router.history.goBack()}>
                <img src={icEditYieldIcon} />
                {I18n.t('Menu.Preview')}
              </li>
            </ul>
          )}
        />

        <Route
          path={ACCOUNTING_PAYABLE_PLAN_IMPORTEXPORT_US_DELIVERY_PREVIEW_URL}
          render={() => (
            <ul className="header-menu">
              <li className="cursor-pointer" onClick={() => this.props.router.history.goBack()}>
                <img src={icEditYieldIcon} />
                {I18n.t('Menu.Preview')}
              </li>
            </ul>
          )}
        />

        <Route
          path={ACCOUNTING_PAYABLE_PLAN_IMPORTEXPORT_CONTAINER_PREVIEW_URL}
          render={() => (
            <ul className="header-menu">
              <li className="cursor-pointer" onClick={() => this.props.router.history.goBack()}>
                <img src={icEditYieldIcon} />
                {I18n.t('Menu.Preview')}
              </li>
            </ul>
          )}
        />

        <Route
          path={ACCOUNTING_PAYABLE_INVOICE_CONTAINER_EDIT_URL}
          render={() => (
            <ul className="header-menu">
              <li className="cursor-pointer" onClick={() => this.props.router.history.goBack()}>
                <img src={icEditYieldIcon} />
                {I18n.t('Menu.Edit')}
              </li>
            </ul>
          )}
        />
        <Route
          path={ACCOUNTING_PAYABLE_INVOICE_DECLARATION_EDIT_URL}
          render={() => (
            <ul className="header-menu">
              <li className="cursor-pointer" onClick={() => this.props.router.history.goBack()}>
                <img src={icEditYieldIcon} />
                {I18n.t('Menu.Edit')}
              </li>
            </ul>
          )}
        />
        <Route
          path={ACCOUNTING_PAYABLE_INVOICE_US_DELIVERY_EDIT_URL}
          render={() => (
            <ul className="header-menu">
              <li className="cursor-pointer" onClick={() => this.props.router.history.goBack()}>
                <img src={icEditYieldIcon} />
                {I18n.t('Menu.Edit')}
              </li>
            </ul>
          )}
        />
        <Route
          path={ACCOUNTING_PAYABLE_INVOICE_CONTRACTOR_EDIT_URL}
          render={() => (
            <ul className="header-menu">
              <li className="cursor-pointer" onClick={() => this.props.router.history.goBack()}>
                <img src={icEditYieldIcon} />
                {I18n.t('Menu.Edit')}
              </li>
            </ul>
          )}
        />
        <Route
          path={ACCOUNTING_PAYABLE_INVOICE_DOMESTIC_EDIT_URL}
          render={() => (
            <ul className="header-menu">
              <li className="cursor-pointer" onClick={() => this.props.router.history.goBack()}>
                <img src={icEditYieldIcon} />
                {I18n.t('Menu.Edit')}
              </li>
            </ul>
          )}
        />
        <Route
          path={ACCOUNTING_PAYABLE_INVOICE_OVERSEA_EDIT_URL}
          render={() => (
            <ul className="header-menu">
              <li className="cursor-pointer" onClick={() => this.props.router.history.goBack()}>
                <img src={icEditYieldIcon} />
                {I18n.t('Menu.Edit')}
              </li>
            </ul>
          )}
        />
        <Route
          path={ACCOUNTING_RECEIVABLE_INVOICE_EDIT_NORMAL_URL}
          render={() => (
            <ul className="header-menu">
              <li className="cursor-pointer" onClick={() => this.props.router.history.goBack()}>
                <img src={icEditYieldIcon} />
                {I18n.t('Menu.Edit')}
              </li>
            </ul>
          )}
        />
        <Route
          path={ACCOUNTING_RECEIVABLE_INVOICE_EDIT_CHARGEBACK_URL}
          render={() => (
            <ul className="header-menu">
              <li className="cursor-pointer" onClick={() => this.props.router.history.goBack()}>
                <img src={icEditYieldIcon} />
                {I18n.t('Menu.Edit')}
              </li>
            </ul>
          )}
        />

        <Route
          path={ACCOUNTING_PAYABLE_INVOICE_VIEW_URL}
          render={() => (
            <ul className="header-menu">
              <li className="cursor-pointer" onClick={() => this.props.router.history.goBack()}>
                <img src={icEditYieldIcon} />
                {I18n.t('Menu.ViewInvoiceNo')}
              </li>
            </ul>
          )}
        />

        <Route
          path={ACCOUNTING_RECEIVABLE_PLAN_EDIT_CHARGE_BACK_URL}
          render={() => (
            <ul className="header-menu">
              <li className="cursor-pointer" onClick={() => this.props.router.history.goBack()}>
                <img src={icEditYieldIcon} />
                {I18n.t('Menu.Edit')}
              </li>
            </ul>
          )}
        />

        <Route
          path={ACCOUNTING_URL}
          render={props =>
            !menuCurrent.isEdit && (
              <ul className="header-menu">
                <li
                  onClick={() => this.props.router.history.push(ACCOUNTING_PAYABLE_URL)}
                  className={`cursor-pointer  ${pathname.startsWith(ACCOUNTING_PAYABLE_URL) ? 'active' : ''}`}>
                  <img src={pathname.startsWith(ACCOUNTING_PAYABLE_URL) ? icPayableCIcon : icPayableDIcon} />
                  {I18n.t('Menu.AccountsPayable')}
                </li>
                <li
                  onClick={() => this.props.router.history.push(ACCOUNTING_RECEIVABLE_URL)}
                  className={`cursor-pointer  ${pathname.startsWith(ACCOUNTING_RECEIVABLE_URL) ? 'active' : ''}`}>
                  <img src={pathname.startsWith(ACCOUNTING_RECEIVABLE_URL) ? icReceivableCIcon : icReceivableDIcon} />
                  {I18n.t('Menu.AccountsReceivable')}
                </li>
                <li
                  onClick={() => this.props.router.history.push(ACCOUNTING_CREDIT_DEBIT_URL)}
                  className={`cursor-pointer  ${pathname.startsWith(ACCOUNTING_CREDIT_DEBIT_URL) ? 'active' : ''}`}>
                  <img src={pathname.startsWith(ACCOUNTING_CREDIT_DEBIT_URL) ? icCreditCIcon : icCreditDIcon} />
                  {I18n.t('Menu.CreditDebitNote')}
                </li>
              </ul>
            )
          }
        />

        <Route
          path={SHIP_OUT.HISTORY.VIEW}
          render={() => (
            <ul className="header-menu">
              <li className="cursor-pointer" onClick={() => this.props.router.history.goBack()}>
                <img src={icEditYieldIcon} />
                {I18n.t('Menu.View')}
              </li>
            </ul>
          )}
        />

        <Route
          path={SHIP_OUT.PKL_PRODUCTION.VIEW}
          render={() => (
            <ul className="header-menu">
              <li className="cursor-pointer" onClick={() => this.props.router.history.goBack()}>
                <img src={icEditYieldIcon} />
                {I18n.t('Menu.View')}
              </li>
            </ul>
          )}
        />

        <Route
          path={SHIP_OUT.PKL_PRODUCTION.PREVIEW}
          render={() => (
            <ul className="header-menu">
              <li className="cursor-pointer" onClick={() => this.props.router.history.goBack()}>
                <img src={icEditYieldIcon} />
                {I18n.t('Menu.View')}
              </li>
            </ul>
          )}
        />

        <Route
          path={SHIP_OUT.PKL_PRODUCTION.CREATE_CARTON}
          render={() => (
            <ul className="header-menu">
              <li className="cursor-pointer" onClick={() => this.props.router.history.goBack()}>
                <img src={icEditYieldIcon} />
                {I18n.t('Menu.Create_Ship_Carton')}
              </li>
            </ul>
          )}
        />

        <Route
          path={SHIP_OUT.PKL_PRODUCTION.CREATE_GOHH}
          render={() => (
            <ul className="header-menu">
              <li className="cursor-pointer" onClick={() => this.props.router.history.goBack()}>
                <img src={icEditYieldIcon} />
                {I18n.t('Menu.Create_Ship_GOHH')}
              </li>
            </ul>
          )}
        />

        <Route
          path={SHIP_OUT.SHIP.VIEW_DETAIL}
          render={() => (
            <ul className="header-menu">
              <li className="cursor-pointer" onClick={() => this.props.router.history.goBack()}>
                <img src={icEditYieldIcon} />
                {I18n.t('Menu.View')}
              </li>
            </ul>
          )}
        />

        <Route
          path={SHIP_OUT.SHIP.EDIT}
          render={() => (
            <ul className="header-menu">
              <li className="cursor-pointer" onClick={() => this.props.router.history.goBack()}>
                <img src={icEditYieldIcon} />
                {I18n.t('Menu.Edit')}
              </li>
            </ul>
          )}
        />

        <Route
          path={SHIP_OUT.PKL_SHIP.EDIT_INV}
          render={() => (
            <ul className="header-menu">
              <li className="cursor-pointer" onClick={() => this.props.router.history.goBack()}>
                <img src={icEditYieldIcon} />
                {I18n.t('Menu.EditINV')}
              </li>
            </ul>
          )}
        />
        <Route
          path={SHIP_OUT.PKL_SHIP.CREATE_INV}
          render={() => (
            <ul className="header-menu">
              <li className="cursor-pointer" onClick={() => this.props.router.history.goBack()}>
                <img src={icEditYieldIcon} />
                {I18n.t('Menu.CreateINV')}
              </li>
            </ul>
          )}
        />

        <Route
          path={SHIP_OUT.PKL_SHIP.EDIT}
          render={() => (
            <ul className="header-menu">
              <li className="cursor-pointer" onClick={() => this.props.router.history.goBack()}>
                <img src={icEditYieldIcon} />
                {I18n.t('Menu.Edit')}
              </li>
            </ul>
          )}
        />
        <Route
          path={SHIP_OUT.PKL_SHIP.EDIT_REVIEW}
          render={() => (
            <ul className="header-menu">
              <li className="cursor-pointer" onClick={() => this.props.router.history.goBack()}>
                <img src={icEditYieldIcon} />
                {I18n.t('Menu.Edit')}
              </li>
            </ul>
          )}
        />

        <Route
          path={SHIP_OUT.PKL_SHIP.CREATE_DETAIL}
          render={() => (
            <ul className="header-menu">
              <li className="cursor-pointer" onClick={() => this.props.router.history.goBack()}>
                <img src={icEditYieldIcon} />
                {I18n.t('Menu.View')}
              </li>
            </ul>
          )}
        />

        <Route
          path={SHIP_OUT.PKL_SHIP.CREATE}
          render={() => (
            <ul className="header-menu">
              <li className="cursor-pointer" onClick={() => this.props.router.history.goBack()}>
                <img src={icEditYieldIcon} />
                {I18n.t('Menu.CreateShipment')}
              </li>
            </ul>
          )}
        />

        <Route
          path={SHIPPING_URL}
          render={() =>
            menuCurrent.isAdd ? (
              <ul className="header-menu">
                <li className="cursor-pointer" onClick={() => this.returnPage()}>
                  <img src={icEditYieldIcon} alt="Add" title="Add" />
                  {I18n.t('Menu.Add')}
                </li>
              </ul>
            ) : !menuCurrent.isEdit ? (
              <ul className="header-menu">
                <li
                  onClick={() => this.props.router.history.push(SHIPPING_SHIP_IN_URL)}
                  className={`cursor-pointer ${
                    pathname === SHIPPING_URL || pathname === SHIPPING_SHIP_IN_URL ? 'active' : ''
                  }`}>
                  <img src={pathname === SHIPPING_SHIP_IN_URL ? icShipInCIcon : icShipInDIcon} />
                  {I18n.t('Menu.ShipIn')}
                </li>

                <li
                  onClick={() => this.props.router.history.push(SHIPPING_SHIP_OUT_URL)}
                  className={`cursor-pointer  ${pathname.startsWith(SHIPPING_SHIP_OUT_URL) ? 'active' : ''}`}>
                  <img src={pathname.startsWith(SHIPPING_SHIP_OUT_URL) ? icShipOutCIcon : icShipOutDIcon} />
                  {I18n.t('Menu.ShipOut')}
                </li>
              </ul>
            ) : (
              <ul className="header-menu">
                <li className="cursor-pointer" onClick={() => this.returnPage()}>
                  <img src={icEditYieldIcon} alt="Edit Yield" title="Edit Yield" />
                  {screen == 'viewHistory' ? I18n.t('Menu.View') : I18n.t('Menu.Edit')}
                </li>
              </ul>
            )
          }
        />

        <Route
          path={PRODUCTION_URL}
          render={() =>
            !activeScreen ? (
              <ul className="header-menu">
                {/* <li className={`cursor-pointer ${(!tabCurrent || tabCurrent === PRODUCTION_TAB.ASSIGNMENT) ? 'active' : ''}`} */}
                <li
                  className={`cursor-pointer ${tabCurrent === '' ? 'active' : ''}`}
                  onClick={() => this.setTabCurrent('')}>
                  <img src={tabCurrent === '' ? icAssignmentCIcon : icAssignmentIcon} alt="Assignment" title="" />{' '}
                  {I18n.t('Menu.Assignment')}
                </li>
                <li
                  className={`cursor-pointer ${tabCurrent === 'production' ? 'active' : ''}`}
                  onClick={() => this.setTabCurrent(PRODUCTION_TAB.PRODUCTION)}>
                  <img
                    src={tabCurrent === 'production' ? icProductionCIcon : icProductionIcon}
                    alt="Production"
                    title="Production"
                  />{' '}
                  {I18n.t('Menu.Production')}
                </li>
                <li
                  className={`cursor-pointer ${tabCurrent === 'qa' ? 'active' : ''}`}
                  onClick={() => this.setTabCurrent(PRODUCTION_TAB.QA)}>
                  <img src={tabCurrent === 'qa' ? icQACIcon : icQAIcon} alt="QA" title="QA" /> {I18n.t('Menu.QA')}
                </li>
                <li
                  className={`cursor-pointer ${tabCurrent === 'packing' ? 'active' : ''}`}
                  onClick={() => this.setTabCurrent(PRODUCTION_TAB.PACKING)}>
                  <img src={tabCurrent === 'packing' ? icPackingCIcon : icPackingIcon} alt="Packing" title="Packing" />{' '}
                  {I18n.t('Menu.Packing')}
                </li>
              </ul>
            ) : (
              <ul className="header-menu">
                <li className="cursor-pointer" onClick={() => this.returnPage()}>
                  <img src={icEditYieldIcon} />
                  {addView ? utils.getHeaderMenuNameByActiveScreen(activeScreen) : I18n.t('Menu.Add')}
                </li>
              </ul>
            )
          }
        />

        <Route
          path={WAREHOUSE_URL}
          render={() =>
            !menuCurrent.isEdit && !menuCurrent.isAdd ? (
              <ul className="header-menu">
                <li
                  className={`cursor-pointer ${
                    pathname === PATH.WAREHOUSE_URL || pathname === PATH.WAREHOUSE_STOCK_URL ? 'active' : ''
                  }`}
                  onClick={() => {
                    this.setTabCurrent(''), this.props.history.push(PATH.WAREHOUSE_STOCK_URL);
                  }}>
                  <img
                    src={
                      pathname === PATH.WAREHOUSE_URL || pathname === PATH.WAREHOUSE_STOCK_URL
                        ? icWarehouseCIcon
                        : icWarehouseIcon
                    }
                    alt="Warehouse"
                    title={I18n.t('Menu.StockManagement')}
                  />{' '}
                  {I18n.t('Menu.StockManagement')}
                </li>
                <li
                  className={`cursor-pointer ${
                    pathname === PATH.WAREHOUSE_INBOUND_SUPPLIER_URL ||
                    pathname === PATH.WAREHOUSE_INBOUND_CONTRACTOR_URL
                      ? 'active'
                      : ''
                  }`}
                  onClick={() => {
                    this.setTabCurrent('stock-in');
                    this.props.history.push(PATH.WAREHOUSE_INBOUND_URL);
                  }}>
                  <img
                    src={
                      pathname === PATH.WAREHOUSE_INBOUND_SUPPLIER_URL ||
                      pathname === PATH.WAREHOUSE_INBOUND_CONTRACTOR_URL
                        ? icStockInCIcon
                        : icStockInIcon
                    }
                    alt="Stock In"
                    title={I18n.t('Menu.Inbound')}
                  />{' '}
                  {I18n.t('Menu.Inbound')}
                </li>
                <li
                  className={`cursor-pointer ${pathname === PATH.WAREHOUSE_OUTBOUND_URL ? 'active' : ''}`}
                  onClick={() => {
                    this.setTabCurrent('stock-out');
                    this.props.history.push(PATH.WAREHOUSE_OUTBOUND_URL);
                  }}>
                  <img
                    src={pathname === PATH.WAREHOUSE_OUTBOUND_URL ? icStockOutHvIcon : icStockOutIcon}
                    alt="Stock Out"
                    title={I18n.t('Menu.Outbound')}
                    className="h-15"
                  />{' '}
                  {I18n.t('Menu.Outbound')}
                </li>
              </ul>
            ) : (
              <ul className="header-menu">
                <li className="cursor-pointer" onClick={() => this.returnPage()}>
                  <img src={icEditYieldIcon} alt="Edit Yield" title="Edit Yield" />
                  {menuCurrent.isEdit ? I18n.t('Menu.Edit') : I18n.t('Menu.Add')}
                </li>
              </ul>
            )
          }
        />

        <Route
          path={SAMPLE_APPROVAL_URL}
          render={() =>
            !menuCurrent.isEdit ? (
              <ul className="header-menu">
                <li
                  className="cursor-pointer active"
                  onClick={() => {
                    this.setTabCurrent('');
                  }}
                  className={`cursor-pointer  ${tabCurrent === '' ? 'active' : ''}`}>
                  <img
                    src={tabCurrent === '' ? icSampleApprovalCIcon : icSampleApprovalDIcon}
                    alt="Current Sample Approval"
                    title="Current Sample Approval"
                  />{' '}
                  {I18n.t('Menu.CurrentSampleApproval')}
                </li>
                <li
                  className="cursor-pointer"
                  onClick={() => {
                    this.setTabCurrent('sample_approval.history');
                  }}
                  className={`cursor-pointer  ${tabCurrent === 'sample_approval.history' ? 'active' : ''}`}>
                  <img
                    src={tabCurrent === 'sample_approval.history' ? icHistoryCIcon : icHistoryDIcon}
                    alt="History"
                    title="History"
                  />{' '}
                  {I18n.t('Menu.History')}
                </li>
              </ul>
            ) : (
              <span></span>
            )
          }
        />

        <Route
          path={YIELD_URL}
          render={() =>
            !menuCurrent.isEdit ? (
              <ul className="header-menu">
                <li className="cursor-pointer active">
                  <img src={icYieldCIcon} alt="Yield" title="Yield" />
                  {I18n.t('Menu.Yield')}
                </li>
              </ul>
            ) : (
              <ul className="header-menu">
                <li className="cursor-pointer" onClick={() => this.returnPage()}>
                  <img src={icEditYieldIcon} alt="Edit Yield" title="Edit Yield" />
                  {I18n.t('Menu.EditYield')}
                </li>
              </ul>
            )
          }
        />

        <Route
          path={PATH.PURCHASE_SHOW_URL}
          render={() => (
            <ul className="header-menu">
              <li className="cursor-pointer" onClick={() => this.props.router.history.goBack()}>
                <img src={icEditYieldIcon} />
                {I18n.t('Menu.View')}
              </li>
            </ul>
          )}
        />
        <Route
          path={PURCHASE_URL}
          render={() =>
            !activeScreen ? (
              <ul className="header-menu">
                <li
                  onClick={() => this.props.router.history.push(PATH.PURCHASE_PLANS_URL)}
                  className={`cursor-pointer ${pathname.startsWith(PATH.PURCHASE_PLANS_URL) ? 'active' : ''}`}>
                  <img
                    src={pathname.startsWith(PATH.PURCHASE_PLANS_URL) ? icPlansCIcon : icPlansDIcon}
                    alt="Plans"
                    title="Plans"
                  />{' '}
                  {I18n.t('Menu.Plans')}
                </li>
                <li
                  onClick={() => this.props.router.history.push(PATH.PURCHASE_FOLLOW_UP_URL)}
                  className={`cursor-pointer ${pathname.startsWith(PATH.PURCHASE_FOLLOW_UP_URL) ? 'active' : ''}`}>
                  <img
                    src={pathname.startsWith(PATH.PURCHASE_FOLLOW_UP_URL) ? icFollowUpCIcon : icFollowUpDIcon}
                    alt="Follow-Up"
                    title="Follow-Up"
                  />{' '}
                  {I18n.t('Menu.Follow-Up')}
                </li>
                <li
                  onClick={() => this.props.router.history.push(PATH.PURCHASE_LIST_URL)}
                  className={`cursor-pointer ${pathname.startsWith(PATH.PURCHASE_LIST_URL) ? 'active' : ''}`}>
                  <img
                    src={pathname.startsWith(PATH.PURCHASE_LIST_URL) ? icPurchaseListCIcon : icPurchaseListDIcon}
                    alt="Purchase List"
                    title="Purchase List"
                  />{' '}
                  {I18n.t('Menu.PurchaseList')}
                </li>
                <li
                  onClick={() => this.props.router.history.push(PATH.PURCHASE_CANCEL_LIST_URL)}
                  className={`cursor-pointer ${pathname.startsWith(PATH.PURCHASE_CANCEL_LIST_URL) ? 'active' : ''}`}>
                  <img
                    width="14px"
                    src={pathname.startsWith(PATH.PURCHASE_CANCEL_LIST_URL) ? icCancelHighlightIcon : icCancelIcon}
                    alt="Canecel Purchase List"
                    title="Cancel Purchase List"
                  />{' '}
                  {I18n.t('Menu.CancelPurchaseList')}
                </li>
                <li
                  onClick={() => this.props.router.history.push(PATH.PURCHASE_HISTORY_LIST_URL)}
                  className={`cursor-pointer ${pathname.startsWith(PATH.PURCHASE_HISTORY_LIST_URL) ? 'active' : ''}`}>
                  <img
                    src={pathname.startsWith(PATH.PURCHASE_HISTORY_LIST_URL) ? icHistoryCIcon : icHistoryDIcon}
                    alt="History"
                    title="History"
                  />{' '}
                  {I18n.t('Menu.History')}
                </li>
              </ul>
            ) : (
              <ul className="header-menu">
                <li className="cursor-pointer" onClick={() => this.returnPage()}>
                  <img src={icEditYieldIcon} />
                  {menuCurrent.isEdit
                    ? I18n.t('Menu.EditPurchase')
                    : utils.getHeaderMenuNameByActiveScreen(activeScreen)}
                </li>
              </ul>
            )
          }
        />

        <Route
          path={CUSTOMER_PO_URL}
          render={() =>
            !menuCurrent.isEdit ? (
              <ul className="header-menu">
                <li
                  className={`cursor-pointer ${pathname.startsWith(PATH.CUSTOMER_PO_CURRENT_URL) ? 'active' : ''}`}
                  onClick={() => this.props.router.history.push(PATH.CUSTOMER_PO_CURRENT_URL)}>
                  <img
                    src={pathname.startsWith(PATH.CUSTOMER_PO_CURRENT_URL) ? icEditCIcon : icEditDIcon}
                    alt="Current Orders"
                    title="Current Orders"
                  />{' '}
                  {I18n.t('Menu.CurrentOrders')}
                </li>

                <li
                  className={`cursor-pointer ${pathname.startsWith(PATH.CUSTOMER_PO_CANCELLED_URL) ? 'active' : ''}`}
                  onClick={() => this.props.router.history.push(PATH.CUSTOMER_PO_CANCELLED_URL)}>
                  <img
                    width="14px"
                    src={pathname.startsWith(PATH.CUSTOMER_PO_CANCELLED_URL) ? icCancelHighlightIcon : icCancelIcon}
                    alt="Canceled Orders"
                    title="Canceled Orders"
                  />{' '}
                  {I18n.t('Menu.CanceledOrders')}
                </li>

                <li
                  className={`cursor-pointer ${pathname.startsWith(PATH.CUSTOMER_PO_HISTORY_URL) ? 'active' : ''}`}
                  onClick={() => this.props.router.history.push(PATH.CUSTOMER_PO_HISTORY_URL)}>
                  <img
                    src={pathname.startsWith(PATH.CUSTOMER_PO_HISTORY_URL) ? icHistoryCIcon : icHistoryDIcon}
                    alt="History"
                    title="History"
                  />{' '}
                  {I18n.t('Menu.History')}
                </li>
              </ul>
            ) : (
              <ul className="header-menu">
                <li className="cursor-pointer" onClick={() => this.returnPage()}>
                  <img src={icEditYieldIcon} alt="Add/Edit Role" title="Add/Edit Role" />
                  {I18n.t('Menu.View')}
                </li>
              </ul>
            )
          }
        />

        <Route
          path={CRM_URL}
          render={() => (
            <ul className="header-menu">
              <li className="cursor-pointer active">
                <img src={icCrmCIcon} alt="CRM" title="CRM" /> {I18n.t('Menu.CRM')}
              </li>
            </ul>
          )}
        />

        <Route
          path={SYSTEM_SETTING_URL}
          render={() => (
            <ul className="header-menu">
              <li className="cursor-pointer active">
                <img src={icSystemCIcon} alt="System Settings" title="System Settings" />{' '}
                {I18n.t('Menu.SystemSettings')}
              </li>
            </ul>
          )}
        />

        <Route
          path={REPORT_ANALYTIC_URL}
          render={() => (
            <ul className="header-menu">
              <li className="cursor-pointer active">
                <img src={icReportCIcon} alt="Report & Analytic" title="Report & Analytic" />{' '}
                {I18n.t('Menu.ReportAnalytic')}
              </li>
            </ul>
          )}
        />

        <Route
          path={ORGANIZATION_URL}
          render={() =>
            !menuCurrent.isEdit ? (
              <ul className="header-menu">
                <li className="cursor-pointer active">
                  <img src={icOrganizationCIcon} alt="Organization" title="Organization" />{' '}
                  {I18n.t('Menu.Organization')}
                </li>
              </ul>
            ) : (
              <ul className="header-menu">
                <li className="cursor-pointer" onClick={() => this.returnPage()}>
                  <img src={icEditYieldIcon} alt="Add/Edit Role" title="Add/Edit Role" />
                  {I18n.t('Menu.Edit')}
                </li>
              </ul>
            )
          }
        />

        <Route
          path={PATH.STANDARD_PACKINGS_EDIT_URL}
          render={() => (
            <ul className="header-menu">
              <li className="cursor-pointer" onClick={() => this.props.router.history.goBack()}>
                <img src={icEditYieldIcon} alt="Add/Edit Role" title="Add/Edit Role" />
                {I18n.t('Menu.Edit')}
              </li>
            </ul>
          )}
        />

        <Route
          path={STANDARD_URL}
          render={() =>
            menuCurrent.isAdd ? (
              <ul className="header-menu">
                <li className="cursor-pointer" onClick={() => this.returnPage()}>
                  <img src={icEditYieldIcon} alt="Add/Edit Role" title="Add/Edit Role" />
                  {I18n.t('Menu.Add')}
                </li>
              </ul>
            ) : !menuCurrent.isEdit ? (
              <ul className="header-menu">
                <li className="cursor-pointer active">
                  <img src={icStandardCIcon} alt="Standard" title="Standard" />
                  {I18n.t('Menu.Standard')}
                </li>
              </ul>
            ) : (
              <ul className="header-menu">
                <li className="cursor-pointer" onClick={() => this.returnPage()}>
                  <img src={icEditYieldIcon} alt="Add/Edit Role" title="Add/Edit Role" />
                  {I18n.t('Menu.Edit')}
                </li>
              </ul>
            )
          }
        />

        <Route
          path={'/'}
          render={() => (
            <ul className="header-menu">
              <li className="cursor-pointer active">
                <img src={icDashboardIcon} alt="Dashboard" title="Dashboard" />
                {I18n.t('Menu.Dashboard')}
              </li>
            </ul>
          )}
        />
      </Switch>
    );

    return wrap;
  }

  render() {
    let { menuCurrent } = this.state;

    return (
      <header className="header">
        <div className="header-block header-block-collapse hidden-lg-up">
          <button className="collapse-btn" id="sidebar-collapse-btn">
            <i className="fa fa-bars" />
          </button>
        </div>
        <div className="header-block header-block-search hidden-sm-down">{this.getNavigations()}</div>
        <Profile {...this.props} />
      </header>
    );
  }
}

function mapStateToProps(state) {
  return {
    appState: state.appState,
    authState: state.authState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    authPropsActions: bindActionCreators(authActions, dispatch),
    appActions: bindActionCreators(appActions, dispatch),
    routeTo: function (url) {
      dispatch(push(url));
    },
    dispatch,
  };
}

Header.contextTypes = {
  router: PropTypes.object.isRequired,
  appActions: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
