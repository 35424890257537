import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import DropDownMultiSelect from '../fields/DropDownMultiSelect';
import propertyActions from '../../../actions/propertyActions';
import trimActions from '../../../actions/trimActions';
import {PropertyKeys} from '../../../constants/constants';
import I18n from 'i18n-js';
import Material from '../../../models/material';
import { toastr } from 'react-redux-toastr';
import {isStringEmpty} from '../../../functions/helpers';

class ModalTrimForm extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      trimDetail: new Material(),
      colors: [],
      sizes: [],
      units: [],
      origins: [],
      errors: {}
    };
    this.initProperties = this.initProperties.bind(this);

  }

  componentWillMount() {
    let {trimDetail} = this.state;
    let {propTrimDetail} = this.props;
    this.props.propertyPropsActions.getDetailByKey(PropertyKeys.COLOR);
    this.props.propertyPropsActions.getDetailByKey(PropertyKeys.SIZE);
    this.props.propertyPropsActions.getDetailByKey(PropertyKeys.UNIT);
    this.props.propertyPropsActions.getDetailByKey(PropertyKeys.ORIGIN);

    if (propTrimDetail && trimDetail._id !== propTrimDetail._id){
      trimDetail.init(propTrimDetail);
      this.setState({trimDetail: trimDetail});
    }
  }

  componentWillReceiveProps(newProps) {
    let {propertyState} = newProps;
    if (propertyState && Object.keys(propertyState.properties).length > 0){
      this.initProperties(propertyState);
    }
  }

  initProperties(propertyState){
    let {colors, sizes, units, origins} = this.state;
    if (!colors.length || colors !== propertyState.properties[PropertyKeys.COLOR]){
      let arrayColor = propertyState.properties[PropertyKeys.COLOR] || [];
      this.setState({colors: arrayColor.map(color => {return {value: color, label: color};})});
    }
    if (!sizes.length || sizes !== propertyState.properties[PropertyKeys.SIZE]){
      let arraySize = propertyState.properties[PropertyKeys.SIZE] || [];
      this.setState({sizes: arraySize.map(size => {return {value: size, label: size};})});
    }
    if (!units.length || units !== propertyState.properties[PropertyKeys.UNIT]){
      this.setState({units: propertyState.properties[PropertyKeys.UNIT] || []});
    }
    if (!origins.length || origins !== propertyState.properties[PropertyKeys.ORIGIN]){
      this.setState({origins: propertyState.properties[PropertyKeys.ORIGIN] || []});
    }
  }

  onChange = (property, value) => {
    let { trimDetail } = this.state;
    
    if (property ===  'data.unit' && trimDetail.convertUnit === value) {
      trimDetail.convertUnit = '';
    }

    this.setState({ trimDetail: _.set(trimDetail, property, value) });
    this.validateAfterChange(property, value);
  }

  close() {
    this.props.closeModal();
  }

  validateAfterChange = (property, value) => {
    let { trimDetail, errors } = this.state;

    switch (property) {
      case 'name': {
        delete errors['name'];
        if (isStringEmpty(value)) {
          errors['name'] =  I18n.t('ErrorMessages.TrimNameIsRequired');
        }
        this.setState({errors});
        break;
      }
      case 'data.unit': {
        delete errors['dataUnit'];
        if (isStringEmpty(value)) {
          errors['dataUnit'] =  I18n.t('ErrorMessages.TrimUnitIsRequired');
        }
        this.setState({errors});
        break;
      }
      case 'convertUnit':
      case 'convertTo': {
        delete errors['convertUnit'];
        if (value > 0 && isStringEmpty(trimDetail.convertUnit)){
          errors['convertUnit'] =  I18n.t('ErrorMessages.TrimConvertUnitIsRequired');
        }
        this.setState({errors});
        break;
      }
      default:
    }
  }

  validateBeforeSave = () => {
    let { trimDetail, errors } = this.state;
    errors = {};
    if (!trimDetail){
      errors['name'] = I18n.t('ErrorMessages.TrimInfoIsRequired');
      this.setState({errors});
      return false;
    }

    if (isStringEmpty(trimDetail.name)) {
      errors['name'] =  I18n.t('ErrorMessages.TrimNameIsRequired');
      this.setState({errors});
      return false;
    }

    /*if (!trimDetail.data.color || !trimDetail.data.color.length) {
      errors['dataColor'] =  I18n.t('ErrorMessages.TrimColorIsRequired');
      this.setState({errors});
      return false;
    }*/

    /*if (!trimDetail.data.size || !trimDetail.data.size.length) {
      errors['dataSize'] =  I18n.t('ErrorMessages.TrimSizeIsRequired');
      this.setState({errors});
      return false;
    }*/

    if (isStringEmpty(trimDetail.data.unit)) {
      errors['dataUnit'] =  I18n.t('ErrorMessages.TrimUnitIsRequired');
      this.setState({errors});
      return false;
    }

    /*if (isStringEmpty(trimDetail.data.origin)) {
      errors['dataOrigin'] =  I18n.t('ErrorMessages.TrimOriginIsRequired');
      this.setState({errors});
      return false;
    }*/

    if (trimDetail.convertTo > 0 && isStringEmpty(trimDetail.convertUnit)){
      errors['convertUnit'] =  I18n.t('ErrorMessages.TrimConvertUnitIsRequired');
      this.setState({errors});
      return false;
    }

    this.setState({errors});
    return true;
  }

  save() {
    if (!this.validateBeforeSave())
      return;

    let { trimDetail } = this.state;
    if (trimDetail._id){
      this.props.trimPropsActions.editTrim(trimDetail._id, trimDetail);
    } else {
      delete trimDetail._id;
      this.props.trimPropsActions.addTrim(trimDetail);
    }
  }

  render() {
    let { trimDetail, colors, units, origins, sizes, errors} = this.state;
    // console.log(trimDetail.isConvert)
    const isOptionDisabled = (unit, unitConvertTo) => {
      if (unit === unitConvertTo) 
        return true;
      return false;
    }

    return (
      <div className="modal-dialog modal-add-trim" role="document">
        <div className="modal-content">
          <form>
            <div className="modal-header">
              <a className="close" onClick={() => this.close()}><span aria-hidden="true">&times;</span></a>
              <h4 className="modal-title" id="myModalLabel">{trimDetail && trimDetail._id ? I18n.t('PageStandard.EDITTRIM') : I18n.t('PageStandard.ADDTRIM')}</h4>
            </div>

            <div className="modal-body">
              <div className="form-group">
                <div className="col-md-4 col-xs-12 text">
                  <p>{I18n.t('PageStandard.Name')}</p> <span className="color-red"> *</span>
                </div>
                <div className="col-md-8 col-xs-12 value">
                  <input type="text" value={trimDetail.name} onChange={evt => this.onChange('name', evt.target.value)} className="form-control" />
                  {errors['name'] && <div className="alert-warning">{errors['name']}</div>}
                </div>
              </div>

              <div className="form-group">
                <div className="col-md-4 col-xs-12 text">
                  <p>{I18n.t('PageStandard.Content')}</p>
                </div>
                <div className="col-md-8 col-xs-12 value relative">
                  <input type="text" value={trimDetail.data && trimDetail.data.content} ref="property" onChange={evt => this.onChange('data.content', evt.target.value)} className="form-control" />
                </div>
              </div>

              <div className="form-group">
                <div className="col-md-4 col-xs-12 text">
                  <p>{I18n.t('PageStandard.Color')}</p>
                </div>
                <div className="col-md-8 col-xs-12 value relative">
                  <DropDownMultiSelect options={colors || []} selectedValues={trimDetail.data.color || []} property={'data.color'} onChange={this.onChange}></DropDownMultiSelect>
                  {errors['dataColor'] && <div className="alert-warning">{errors['dataColor']}</div>}
                </div>
              </div>
              <div className="form-group">
                <div className="col-md-4 col-xs-12 text">
                  <p>{I18n.t('PageStandard.Size')}</p>
                </div>
                <div className="col-md-8 col-xs-12 value relative">
                  <DropDownMultiSelect options={sizes || []} selectedValues={trimDetail.data.size || []} property={'data.size'} onChange={this.onChange}></DropDownMultiSelect>
                  {errors['dataSize'] && <div className="alert-warning">{errors['dataSize']}</div>}
                </div>
              </div>

              {/* UNIT FIELD */}
              <div className="form-group">
                <div className="col-md-4 col-xs-12 text">
                  <p>{I18n.t('PageStandard.Unit')}</p> <span className="color-red"> *</span>
                </div>
                <div className="col-md-8 col-xs-12 value relative">
                  <select className="form-control cursor-pointer" value={trimDetail.data.unit} onChange={evt => this.onChange('data.unit', evt.target.value)}>
                    <option value="" />
                    {units.map((unit, idx1) => (<option key={idx1} value={unit}>{unit}</option>))}
                  </select>
                  {errors['dataUnit'] && <div className="alert-warning">{errors['dataUnit']}</div>}
                </div>
              </div>
              {/* END UNIT FIELD */}

              <div className="form-group">
                <div className="col-md-4 col-xs-12 text">
                  <p>{I18n.t('PageStandard.Origin')}</p>
                </div>
                <div className="col-md-8 col-xs-12 value relative">
                  <select className="form-control cursor-pointer" value={trimDetail.data.origin} onChange={evt => this.onChange('data.origin', evt.target.value)}>
                    <option value="" />
                    {origins.map((origin, idx1) => (<option key={idx1} value={origin}>{origin}</option>))}
                  </select>
                  {errors['dataOrigin'] && <div className="alert-warning">{errors['dataOrigin']}</div>}
                </div>
              </div>

              <div className="form-group">
                <div className="col-md-4 col-xs-12 text">
                  &nbsp;
                </div>
                <div className="col-md-8 col-xs-12 value relative size-13">
                <div className="style-checkbox">
                    <input type="checkbox" className = "cursor-pointer" checked={trimDetail.isConvert} value={trimDetail.isConvert} onChange={evt => this.onChange('isConvert', !trimDetail.isConvert)} />
                    <label>
                      <span><span className = "cursor-pointer" onClick = {evt => this.onChange('isConvert', !trimDetail.isConvert)}>{I18n.t('PageStandard.ConvertUnit')}</span></span>
                    </label>
                  </div>
                </div>
              </div>

              {/* CONVERT TO */}
              <div className="form-group convertToGrayBg">
                <div className="col-md-4 col-xs-12 text">
                  <p>{I18n.t('PageStandard.ConvertTo')}</p>
                </div>
                <div className="col-md-8 col-xs-12 value relative">
                  <div className="row">
                    <div className="col-md-6">
                      <input type="number" className="form-control"
                             disabled={!trimDetail.isConvert}
                             value={trimDetail.convertTo}
                             onChange={evt => this.onChange('convertTo', evt.target.value)} />
                    </div>
                    <div className="col-md-6 no-padding-left">
                      <select className="form-control"
                              disabled={!trimDetail.isConvert}
                              value={trimDetail.convertUnit}
                              onChange={evt => this.onChange('convertUnit', evt.target.value)}>
                        <option value="" />
                        {units.map((unit, idx1) => (<option key={idx1} value={unit} disabled={isOptionDisabled(trimDetail.data.unit, unit)}>{unit}</option>))}
                      </select>
                      {errors['convertUnit'] && <div className="alert-warning">{errors['convertUnit']}</div>}
                    </div>
                  </div>
                </div>
              </div>
              {/* END CONVERT TO */}

              <div className="form-group">
                <div className="col-md-4 col-xs-12 text">
                  <p>{I18n.t('PageStandard.Note')}</p>
                </div>
                <div className="col-md-8 col-xs-12 value relative">
                  <textarea type="text" className="form-control" rows={3}
                            value={trimDetail.note} onChange={evt => this.onChange('note', evt.target.value)}></textarea>
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <button type="button" className="bt-black" onClick={() => this.close()}>{I18n.t('Btn.CANCEL')}</button>
              <button type="button" className="bt-orange mg-l-5" onClick={() => this.save()}>{I18n.t('Btn.SAVE')}</button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    appState: state.appState,
    authState: state.authState,
    propertyState: state.propertyState,
    trimState: state.trimState
  };
}

function mapDispatchToProps(dispatch) {
  return {
    propertyPropsActions: bindActionCreators(propertyActions, dispatch),
    trimPropsActions: bindActionCreators(trimActions, dispatch),
  };
}

ModalTrimForm.propTypes = {
  authState: PropTypes.object.isRequired,
  trimDetail: PropTypes.object
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalTrimForm);
