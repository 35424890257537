import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import I18n from 'i18n-js';
import moment from 'moment';
import ReactExport from "react-data-export";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const ReceiptModal = (props) => {
    const style = {
        overlay: {
            position: 'fixed',
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
            zIndex: '3'
        },
        content: {
            position: 'auto',
            top: '50%',
            left: '50%',
            backgroundColor: 'rgba(0,0,0,0)',
            border: '0px',
            borderRadius: '0px',
            outline: 'none',
        },
    };
    const { receiptData, openModal, type, close } = props;
    const currencyUSD = receiptData.say && receiptData.say.search("dollar") > -1 && '$';
    const currencyVND = receiptData.say && receiptData.say.search("đồng") > -1 && 'VND';
    const multiDataSet = [
        {
            columns: [
                {title: '', width: {wch: 40}},//pixels width
                {title: '', width: {wch: 40}},//char width
                {title: '', width: {wch: 40}},//char width
                {title: '', width: {wch: 40}},//char width
            ],
            data: [
                ["No", `${receiptData.no || ''}`, "PC No", `${receiptData.pcNo || ''}` ],
                ["Reason", `${receiptData.reason || ''}`, "Date pay", `${moment(receiptData.date).format("MMM DD, YYYY")}`],
                ["Recipient", `${receiptData.recipient || ''}`, "Address", `${receiptData.address || ''}`],
                ["Attach", `${receiptData.attach || ''}`, "Total", `${currencyUSD + ' ' + receiptData.total || 0 + ' ' + currencyVND}`],
                ["Treasurer", `${receiptData.treasurer || ''}`, "Say", `${receiptData.say}`],
                ["", "", "Recipient", `${receiptData.recipient || ''}`]
            ]
        },
    ]
    // console.log(receiptData);
    return (
        <Modal style={style} isOpen={openModal} onRequestClose={close}>
            <div className="modal-dialog lg" role="document" id="accounts-payable-invoice-receipt">
                <div className="modal-content">
                    <form>
                        <div className="modal-header">
                            <h4 className="modal-title" id="myModalLabel">RECEIPT</h4>
                            <button onClick={close} style={{ position: 'absolute', top: 5, right: 5, background: 'transparent' }} ref={ele => {
                                // css hack
                                if (ele) {
                                    ele.style.setProperty("font-size", "16px", "important");
                                }
                            }}>X</button>
                        </div>
                        <div className="modal-body pd-0">

                            <div className="wrap-top inline-block w-full mg-bottom-5">
                                <div className="col-md-6 left">
                                    <div className="form-group">
                                        <div className="col-md-4 col-xs-12 text no-padding-left">
                                            <p>No</p>
                                        </div>
                                        <div className="col-md-8 col-xs-12 value no-padding-right">
                                            <input type="text" className="form-control" placeholder=""
                                                value={receiptData.no}
                                                onChange={(e) => props.changeReceiptValue('no', e.target.value)}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <div className="col-md-4 col-xs-12 text no-padding-left">
                                            <p>Reason</p>
                                        </div>
                                        <div className="col-md-8 col-xs-12 value no-padding-right">
                                            <input type="text" className="form-control" placeholder=""
                                                value={receiptData.reason}
                                                onChange={(e) => props.changeReceiptValue('reason', e.target.value)}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <div className="col-md-4 col-xs-12 text no-padding-left">
                                            <p>Recipient</p>
                                        </div>
                                        <div className="col-md-8 col-xs-12 value no-padding-right">
                                            <input type="text" className="form-control" placeholder=""
                                                value={receiptData.recipient}
                                                onChange={(e) => props.changeReceiptValue('recipient', e.target.value)}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <div className="col-md-4 col-xs-12 text no-padding-left">
                                            <p>Attach</p>
                                        </div>
                                        <div className="col-md-8 col-xs-12 value no-padding-right">
                                            <input type="text" className="form-control" placeholder=""
                                                value={receiptData.attach}
                                                onChange={(e) => props.changeReceiptValue('attach', e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6 right">
                                    <div className="top">
                                        <div className="form-group">
                                            <div className="col-md-4 col-xs-12 text no-padding-left">
                                                <p>PC No</p>
                                            </div>
                                            <div className="col-md-8 col-xs-12 h-40 value display-flex no-padding-right">
                                                <p className="bold display-flex align-items">{receiptData.pcNo || ''}</p>
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <div className="col-md-4 col-xs-12 text no-padding-left">
                                                <p>Date pay</p>
                                            </div>
                                            <div className="col-md-8 col-xs-12 h-40 display-flex value no-padding-right">
                                                <p className="bold display-flex align-items">{moment(receiptData.date).format("MMM DD, YYYY")}</p>
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <div className="col-md-4 col-xs-12 text no-padding-left">
                                                <p>Address</p>
                                            </div>
                                            <div className="col-md-8 col-xs-12 h-40 display-flex value no-padding-right">
                                                <p className="bold display-flex align-items">{receiptData.address || ''}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bottom">
                                        <div className="form-group">
                                            <div className="col-md-4 col-xs-12 text no-padding-left">
                                                <p>Total</p>
                                            </div>
                                            <div className="col-md-8 col-xs-12 h-40 display-flex value no-padding-right">
                                                <p className="bold color-number display-flex align-items">{currencyUSD} {receiptData.total} {currencyVND}</p>
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <div className="col-md-4 col-xs-12 text no-padding-left">
                                                <p>Say</p>
                                            </div>
                                            <div className="col-md-8 col-xs-12 h-40 value no-padding-right">
                                                <p className="bold text-align-left">{receiptData.say}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="wrap-bottom bd-dashed-bottom">
                                <div className="col-md-6 col-xs-12">
                                    <div className="form-group">
                                        <div className="col-md-4 col-xs-12 text no-padding-left">
                                            <p>Treasurer</p>
                                        </div>
                                        <div className="col-md-8 col-xs-12 value no-padding-right">
                                            <input type="text" className="form-control" placeholder=""
                                                value={receiptData.treasurer}
                                                onChange={(e) => props.changeReceiptValue('treasurer', e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-xs-12">
                                    <div className="form-group">
                                        <div className="col-md-4 col-xs-12 text no-padding-left">
                                            <p>Recipient</p>
                                        </div>
                                        <div className="col-md-8 col-xs-12 value no-padding-right">
                                            <input type="text" className="form-control" placeholder=""
                                                   value={receiptData.recipient}
                                                   onChange={(e) => props.changeReceiptValue('recipient', e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-xs-12">
                                    <div className="form-group">
                                        <div className="col-md-4 col-xs-12 text no-padding-left"></div>
                                        <div className="col-md-8 col-xs-12 value no-padding-right">
                                            <div className="style-checkbox mg-5-0 text-right">
                                                <input type="checkbox" id="check_received_enough_money" className="display-none" value="on"
                                                    checked={receiptData.checked}
                                                    onChange={() => props.changeReceiptValue('checked', !receiptData.checked)}
                                                />
                                                <label htmlFor="check_received_enough_money">
                                                    <span className="mg-right-10"><span /></span>Received enough money
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            {type === 'export' ?
                                // <button type="button" className="bt-orange" onClick={() => { props.export(receiptData), props.close() }}>EXPORT</button>
                                <ExcelFile filename={receiptData.no}
                                    element={
                                        <ul className="list-button cursor-pointer" style={{margin: 0}}>
                                            <li>
                                                <a className="bt-orange" onClick={() => { props.export(receiptData), props.close() }}>EXPORT</a>
                                            </li>
                                        </ul>
                                    }>
                                    <ExcelSheet dataSet={multiDataSet} name={"RECEIPT"} />
                                </ExcelFile>
                            :
                                <span>
                                    <button type="button" className="bt-black" onClick={() => { props.close() }}>CANCEL</button>
                                    <button type="button" className="bt-orange" onClick={() => { props.onSubmitReceipt(receiptData), props.close() }}>SAVE</button>
                                </span>
                            }
                        </div>
                    </form>
                </div>
            </div>
        </Modal>
    );
}


ReceiptModal.propTypes = {
    receiptData: PropTypes.object,
    openModal: PropTypes.bool,
    close: PropTypes.func,
    export: PropTypes.func,
}
ReceiptModal.defaultProps = {
    receiptData: {},
    openModal: false,
    close: f => f,
    export: f => f
}

export default ReceiptModal;