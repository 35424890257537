import { createSelector } from 'reselect';
import moment from 'moment';
import _ from 'lodash';
import * as R from 'ramda';
import { genDollarToText } from '../../../functions/helpers';
import formatCurrency from 'format-currency';

export const getPaymentType = ({ editInvoiceSupplierDomestic }) => editInvoiceSupplierDomestic.paymentType;

export const getInvoiceDetail = ({ editInvoiceSupplierDomestic }) => editInvoiceSupplierDomestic.invoiceDetail || {};

export const getInvoice = ({ editInvoiceSupplierDomestic }) => editInvoiceSupplierDomestic || {};

export const getConstSupplierName = ({ editInvoiceSupplierDomestic }) => editInvoiceSupplierDomestic.supplier;

export const getSupplier = createSelector([getInvoiceDetail], data => {
  return data.invoiceDetail && data.invoiceDetail.supplier.name;
});

export const getSupplierId = createSelector([getInvoiceDetail], data => {
  return data.invoiceDetail && data.invoiceDetail.supplier._id;
});
export const getIssuedDate = createSelector([getInvoiceDetail], data => {
  return data.issuedDate;
});

export const getInvoiceNo = createSelector([getInvoiceDetail], data => {
  return data.invNo;
});

export const getDueDate = createSelector([getInvoiceDetail], data => {
  return data.dueDate;
});

export const getCreditDebit = createSelector([getInvoiceDetail], data => {
  if (data.invoiceDetail && data.invoiceDetail.creditDebit) {
    let creditDebit = data.invoiceDetail.creditDebit;
    return {
      label: R.pathOr('', ['code'], creditDebit),
      value: R.pathOr('', ['_id'], creditDebit),
      raw: creditDebit,
    };
  }
  return null;
});

export const getSelectCurrency = createSelector([getInvoiceDetail], data => {
  return data.invoiceDetail && data.invoiceDetail.convertType;
});

export const getConvertRate = createSelector([getInvoiceDetail], data => {
  return data.invoiceDetail && data.invoiceDetail.convertRate;
});
export const getListCreditDebit = ({ editInvoiceSupplierDomestic }) => {
  const { selectableCreditDebits } = editInvoiceSupplierDomestic;
  return _.map(selectableCreditDebits, item => ({
    label: R.pathOr('', ['code'], item),
    value: R.pathOr('', ['_id'], item),
    raw: item,
  }));
};

export const getSelectableConvertCurrencies = ({ editInvoiceSupplierDomestic }) => {
  const { selectableConvertCurrencies } = editInvoiceSupplierDomestic;
  return _.map(selectableConvertCurrencies, item => ({
    label: item,
    value: item,
    raw: item,
  }));
};

export const getListCheckablePurchaseOrder = ({ editInvoiceSupplierDomestic }) => {
  if (editInvoiceSupplierDomestic.listPurchaseOrderDetail) {
    const purchaseOrders = editInvoiceSupplierDomestic.listPurchaseOrderDetail;
    return purchaseOrders.map(item => ({ _id: item._id, name: item.purchaseOrder.purchaseNo, checked: item.checked }));
  }
};

export const getListPurchaseOrderDetail = ({ editInvoiceSupplierDomestic }) => {
  if (editInvoiceSupplierDomestic.listPurchaseOrderDetail) {
    const { listPurchaseOrderDetail, invoiceDetail } = editInvoiceSupplierDomestic;
    const purchaseOrders = listPurchaseOrderDetail.filter(item => item.checked === true);
    let temp = [];
    (purchaseOrders || []).map(it => {
      if (it.vat) {
        it.vat.vatAmount = _.round(((it.vat.totalAmount || 0) * (it.vat.taxRate || 0)) / 100, 2);
        it.vat.grandTotalVAT = _.round(it.vat.totalAmount + it.vat.vatAmount, 2);
      }
      it.purchaseOrder.materials.map(m => (m.amount = _.round((m.price || 0) * (m.quantity || m.needToBuy || 0), 2)));
      it.materials.map(material => {
        let find = it.purchaseOrder.materials.find(m => m.material == material.material);
        if (find) {
          material.price = it.purchaseOrder.materials.find(m => m.material == material.material).price;
        } else {
          material.price = 0;
        }
      });
      it.totalPurchase = _.round(
        _.sum(
          _.cloneDeep(it.purchaseOrder.materials).map(m =>
            _.round((m.price || 0) * (m.quantity || m.needToBuy || 0), 2)
          )
        ),
        2
      );
      temp.push(it);
    });
    return temp;
  }
};

export const getVATValue = createSelector([getInvoiceDetail], data => {
  const vat = (data.invoiceDetail && data.invoiceDetail.vat) || {};
  if (_.isEmpty(vat)) return {};
  return {
    ...vat,
    vatAmount: Number(vat.totalAmount) * (Number(vat.taxRate) / 100),
    vatAmountText: formatCurrency(Number(vat.totalAmount) * (Number(vat.taxRate) / 100)),
    grandTotalVAT: Number(vat.totalAmount) + Number(vat.totalAmount) * (Number(vat.taxRate) / 100),
    grandTotalVATText: formatCurrency(Number(vat.totalAmount) + Number(vat.totalAmount) * (Number(vat.taxRate) / 100)),
  };
});

export const getCurrencyTypeUSDOfSupplier = createSelector([getInvoiceDetail], data => {
  return data.invoiceDetail && data.invoiceDetail.supplier.currency === 'USD' ? '$' : '';
});

export const getCurrencyTypeVNDOfSupplier = createSelector([getInvoiceDetail], data => {
  return data.invoiceDetail && data.invoiceDetail.supplier.currency === 'VND'
    ? data.invoiceDetail.supplier.currency
    : '';
});

export const getPayAmount = ({ editInvoiceSupplierDomestic }) => editInvoiceSupplierDomestic.payAmount;

export const getFilesSaved = createSelector([getInvoiceDetail], data => {
  return data.files || [];
});

export const getTransferFiles = ({ editInvoiceSupplierDomestic }) => {
  const { transferFiles } = editInvoiceSupplierDomestic;
  return _.map(transferFiles, file => ({
    id: R.path(['id'], file),
    url: R.path(['url'], file),
    raw: file,
    type: _.split(R.path(['id'], file), '.')[1],
  }));
};

export const getReceiptFromInvoice = createSelector(
  [getInvoiceDetail, getPayAmount, getSupplier],
  (data, payAmount, address) => {
    return { ...data.receipt, total: payAmount, date: moment().format(), address };
  }
);

export const getReceiptDataFromRawData = ({ editInvoiceSupplierDomestic }) => ({
  ...editInvoiceSupplierDomestic.receipt,
  say: genDollarToText(editInvoiceSupplierDomestic.receipt.total),
});

export const getEmptyReceipt = createSelector([getReceiptFromInvoice], data => {
  return data ? !data.isEdit : false;
});

export const getSuccess = ({ editInvoiceSupplierDomestic }) => editInvoiceSupplierDomestic.success;

export const getMainVATValue = createSelector([getInvoiceDetail], data => {
  if (data.invoiceDetail && data.invoiceDetail.vat) {
    let { vat } = data.invoiceDetail;
    return {
      ...vat,
      vatAmount: (vat.totalAmount || 0) * ((vat.taxRate || 0) / 100),
      grandTotalAmount: (vat.totalAmount || 0) + (vat.totalAmount || 0) * ((vat.taxRate || 0) / 100),
    };
  }
  return null;
});

export const getMainVATAmount = createSelector([getInvoiceDetail], data => {
  if (data.invoiceDetail) {
    const totalAmount = (data.invoiceDetail.vat || {}).totalAmount || 0;
    const taxRate = (data.invoiceDetail.vat || {}).taxRate || 0;
    return totalAmount * (taxRate / 100);
  }
});

export const getSumVatAmountOfPurchase = ({ editInvoiceSupplierDomestic }) => {
  const { listPurchaseOrderDetail } = editInvoiceSupplierDomestic;
  if (listPurchaseOrderDetail) {
    return _.sum(
      listPurchaseOrderDetail.map(s => s.checked && s.vat && (s.vat.totalAmount || 0) * ((s.vat.taxRate || 0) / 100))
    );
  }
  return 0;
};

export const getAmount = createSelector([getInvoiceDetail], data => {
  return data.amount || 0;
});

export const getTotalAmount = createSelector(
  [getAmount, getMainVATAmount, getSumVatAmountOfPurchase, getCreditDebit],
  (amount, maiVATAmount, sumVatAmountOfCustomerPO, creditDebit) => {
    if (creditDebit) {
      let cb = _.get(creditDebit, 'raw.amount', 0);
      let ty = _.get(creditDebit, 'raw.type', 'Debit');
      if (ty == 'Debit') {
        return (amount || 0) + (maiVATAmount || 0) + (sumVatAmountOfCustomerPO || 0) + cb;
      } else {
        return (amount || 0) + (maiVATAmount || 0) + (sumVatAmountOfCustomerPO || 0) - cb;
      }
    } else return (amount || 0) + (maiVATAmount || 0) + (sumVatAmountOfCustomerPO || 0);
  }
);
export const getConvertCurrency = ({ editInvoiceSupplierDomestic }) => {
  const convertType =
    editInvoiceSupplierDomestic.invoiceDetail &&
    editInvoiceSupplierDomestic.invoiceDetail.invoiceDetail &&
    editInvoiceSupplierDomestic.invoiceDetail.invoiceDetail.convertType;
  return convertType || 'VND';
};

export const getAmountConvert = createSelector(
  [state => state, getAmount, getConvertRate],
  (state, amount, convertRate) => {
    const currency = getConvertCurrency(state);
    if (currency == 'USD') {
      return '$ ' + formatCurrency(_.round(amount / convertRate, 2));
    } else {
      return formatCurrency(_.round(amount * convertRate, 2)) + ` ${currency}`;
    }
  }
);

export const getTotalAmountConvert = createSelector(
  [state => state, getTotalAmount, getConvertRate],
  (state, totalAmount, convertRate) => {
    const currency = getConvertCurrency(state);
    if (currency == 'USD') {
      return '$ ' + formatCurrency(_.round(totalAmount / convertRate, 2));
    } else {
      return formatCurrency(_.round(totalAmount * convertRate, 2)) + ` ${currency}`;
    }
  }
);

export const fnGenerateRequestData = createSelector(
  [getInvoice, getTotalAmount],
  (editInvoiceSupplierDomestic, totalAmount) => {
    const { paymentType, payAmount, listPurchaseOrderDetail = [] } = _.cloneDeep(editInvoiceSupplierDomestic);
    let { invoiceDetail } = _.cloneDeep(editInvoiceSupplierDomestic);
    invoiceDetail.totalAmount = totalAmount;
    if (_.isEmpty(invoiceDetail)) return {};
    let data = _.omit(invoiceDetail, ['createdAt', 'createdBy', 'updatedAt', 'updatedBy', 'paymentHistory']);
    let receipt;
    if (paymentType === 'transfer') {
      data = _.omit(data, ['receipt']);
    } else {
      receipt = data.receipt;
      data = _.omit(data, ['files', 'receipt']);
    }

    const purchaseOrders = listPurchaseOrderDetail.map(item =>
      item.checked
        ? {
            purchaseOrder: (item.purchaseOrder || {})._id,
            materials: item.materials.map(m => ({
              ...m,
              receiveQuantity: m.orderQuantity,
            })),
            vat: item.vat,
          }
        : {}
    );
    // (data ||{}).invoiceDetail.purchaseOrders = purchaseOrders;
    _.assign((data.invoiceDetail || {}).purchaseOrders, purchaseOrders);
    let detailOfInvoice = _.omit(data.invoiceDetail, ['createdAt', 'createdBy', 'updatedAt', 'updatedBy']);
    return {
      ...data,
      invoiceDetail: detailOfInvoice,
      payment: { receipt, paymentType: _.startCase(paymentType), paid: payAmount },
    };
  }
);

export const getPaid = createSelector([getInvoiceDetail], data => {
  return data.paid || 0;
});

export const getRemaining = createSelector([getTotalAmount, getPaid], (amount, paid) => {
  return amount - paid;
});

export const getDisableChangeValue = createSelector([getPaid], paid => {
  if (paid > 0) return true;
  return false;
});

export const getActiveScreenStatus = ({ editInvoiceSupplierDomestic }) => editInvoiceSupplierDomestic.activeScreen;
