import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

const MultiSelectField = ({options, value, label, placeholder, property, error, onChange, wrapClass, stayOpen, removeSelected}) => {
    wrapClass = wrapClass ? wrapClass : 'col-md-6 col-xs-12'
    return (
      <div className={wrapClass}>
        <div className="form-group">
          <div className="col-md-4 col-xs-12 text no-padding-left">
            <p>{label}</p>
          </div>
          <div className="col-md-8 col-xs-12 value no-padding-right">
						<Select
							closeOnSelect={!stayOpen}
							// disabled={disabled}
							multi
							onChange={onChange}
							options={options}
							placeholder={placeholder}
							removeSelected={removeSelected}
							// rtl={this.state.rtl}
							simpleValue
							value={value}
						/>
          </div>
        </div>
        {error && <div className="alert-warning">{error}</div>}
      </div>
    );
  }
  
  MultiSelectField.propTypes = {
    options: PropTypes.array.isRequired,
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    property: PropTypes.string.isRequired,
    error: PropTypes.string,
    onChange: PropTypes.func.isRequired,
		wrapClass: PropTypes.string,
		stayOpen: PropTypes.bool,
		removeSelected: PropTypes.bool
  };
  
  export default MultiSelectField;