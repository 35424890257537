import { combineReducers } from 'redux';
import C from '../../../constants/accounting/payable/createInvoiceImpExpContainer';
import _ from 'lodash';
import moment from 'moment';
/**
 * Store helper information to check whether selectable import export forwarder list is fetching...
 */
const fetchingSelecTableImpExpForwarder = (state = false, action) => {
  const { payload, type } = action;
  switch (type) {
    // TODO. handle related actions
    default:
      return state;
  }
}

/**
 * Store a list of selectable import export Forwarder
 */
const selectableImpExpForwarders = (state = [], action) => {
  const { payload, type } = action;
  switch (type) {
    case C.CHANGE_LIST_FORWARDER:
      return payload;
    case C.RESET_DATA:
      return [];
    default:
      return state;
  }
}

/**
 * Store a selected import export company
 */
const selectedImpExpInfo = (state = null, action) => {
  const { payload, type } = action;
  switch (type) {
    case C.CHANGE_LIST_FORWARDER:
      const result = {
        name: '',
        invoiceNo: '',
        issuedDate: moment().format(),
        dueDate: moment().format()
      }
      return result;
    case C.CHANGE_INVOICE_INFO:
      const {key, value} = payload;
      let temp = _.cloneDeep(state);
      temp[key] = value;
      return temp;
    case C.RESET_DATA:
      return '';
    default:
      return state;
  }
}


/**
 * Store helper information to check whether list of purchase orders is fetching from api
 */
const fetchingContainers = (state = false, action) => {
  const { payload, type } = action;
  switch (type) {
    // TODO. handle related actions
    default:
      return state;
  }
}

/**
 * Store a list of purchase orders that will be used to check in side menu
 */
const containers = (state = [], action) => {
  const { payload, type } = action;
  switch (type) {
    case C.CHANGE_LIST_CONTAINER:
      return payload;
    // case C.CHANGE_CHECKED_LIST:
    //   let dic = _.cloneDeep(state);
    //   const {_id, checked} = payload;
    //   dic.map(item => item._id == _id ? item.checked = checked : null);
    //   return dic;
    case C.RESET_DATA:
      return [];
    default:
      return state;
  }
}

const containersDetail = (state = [], action) => {
  const {payload, type} = action;
  let dic;
  if(type === C.CHANGE_CHECKED_LIST) {
      const { _id, checked, qty, cont, contType, dropOffCont, pickupCont, pickUpCont, route, warehouseAddress, truckNo, qtyPkg} = payload;
      let filtered = _.cloneDeep(state).filter(it => it._id !== _id);
      if (checked) {
        filtered.push({
          _id: _id,
          cont,
          contType: contType || '',
          date: moment().format(),
          qty: qty || qtyPkg || 0,
          type: contType,
          route: route || '',
          warehouseAddress: warehouseAddress || '',
          pickupCont: pickupCont || '',
          pickUpCont: pickUpCont || '',
          dropOffCont: dropOffCont || '',
          truckNo: truckNo || 0,
          cost: 0,
          chargesIncurred: 0,
          liftOnInv: '',
          liftOnFee: 0,
          liftOffInv: '',
          liftOffFee: 0,
          note: ''
        })
      }
      return filtered;
  }
  if(type === C.SAVE_CHANGE_DETAIL_CONTAINER_VALUES) {
    const {_id} = payload || {};
    dic = _.cloneDeep(state);
    dic.map(item => item._id === _id && _.assign(item, payload));
    return dic
  }
  if(type === C.RESET_CHECKED_LIST) {
    return [];
  }
  if(type === C.RESET_DATA) {
    return []
  }
  return state;
}
/**
 * Store a list of checked purchase orders
 */
const checkedContainers = (state = [], action) => {
  const { payload, type } = action;
  switch (type) {
    case C.CHANGE_CHECKED_LIST:
      const { _id, checked } = payload;
      let filtered = _.cloneDeep(state).filter(it => it !== _id);
      if (checked) filtered = [...filtered, _id];
      return filtered;
    case C.RESET_CHECKED_LIST:
      return []
    case C.RESET_DATA:
      return [];
    default:
      return state;
  }
}

/**
 * Store input information for VAT number field at the main VAT card
 */
const mainVAT = (state = {}, action) => {
  const { payload, type } = action;
  switch (type) {
    case C.CHANGE_EMPTY_VAT_VALUE:
      const result = {
        vatNo: '',
        vatDate: moment().format(),
        taxRate: 0,
        note: ''
      }
      return result
    case C.CHANGE_VAT_VALUE:
      const {key, value} = payload;
      let dic = _.cloneDeep(state);
      dic[key] =value;
      return dic;
    case C.RESET_DATA:
      return {};
    default:
      return state;
  }
}

/**
 * Store a helper information to check whether informations is post to api for saving...
 */
const saving = (state = false, action) => {
  const { payload, type } = action;
  switch (type) {
    // TODO. handle related actions
    default:
      return state;
  }
}

/**
 * Store an editing container.
 */
const editingContainer = (state = null, action) => {
  const { payload, type } = action;
  switch (type) {
    // TODO. handle related actions
    default:
      return state;
  }
}

const searchText = (state = '', action) => {
  const {type, payload} = action;
  switch(type) {
    case C.CHANGE_SEARCH_CONTAINER_VALUE:
      return payload;
    case C.RESET_DATA:
      return '';
    default: 
      return state;
  }
}

const tempData = (state = {}, action) => {
  const { type, payload } = action;
  let key, value;
  switch(type) {
    case C.CHANGE_TEMP_DATA:
      return payload;
    case C.CHANGE_DETAIL_CONTAINER_VALUE:
      key = payload.key;
      value = payload.value
      let dic = _.cloneDeep(state);
      dic[key] = value;
      return dic;
    case C.RESET_DATA:
      return {};
    default:
      return state;
  }
}

const success = (state = false, action) => {
  const {type, payload } = action;
  switch(type) {
    case C.CREATE_INVOICE_SUCCESS:
      return payload;
    case C.RESET_DATA:
      return false;
    default:
      return state;
  }
}

const activeScreenPreview = (state = false, action) => {
  const {type, payload} = action;
  switch(type) {
    case C.ACTIVE_SCREEN_STATUS:
      return payload;
    default:
      return state;
  }
}

export const createPayableInvoiceImpExpContainer = combineReducers({
  fetchingSelecTableImpExpForwarder,
  selectableImpExpForwarders,
  selectedImpExpInfo,

  fetchingContainers,
  containers,
  checkedContainers,
  containersDetail,
  searchText,
  // Main VAT card, only use for domestic type

  mainVAT,
  tempData,
  saving,

  editingContainer,

  success,
  activeScreenPreview
});
