import { combineReducers } from 'redux';
import C from '../../constants/purchase/purchasePlans';
import _ from 'lodash';
import moment from 'moment';
const purchaseOrders = (state = null, action) => {
    const { type, payload } = action;
    switch (type) {
        case C.CHANGE_LIST_ORDER:
            return payload;
        case C.CHANGE_LIST_ORDER_MORE:
            return state.concat(...payload);
        case C.RESET_LIST_ORDER:
            return [];
        default:
            return state;
    }
}

const selectedOrder = (state = {}, action) => {
    const { type, payload } = action;
    switch (type) {
        case C.CHANGE_SELECTED_ORDER:
            return payload;
        default:
            return state;
    }
}

const listStyles = (state = [], action) => {
    const { type, payload } = action;
    switch (type) {
        case C.CHANGE_LIST_STYLE:
            return payload;
        default:
            return state;
    }
}

const selectedStyle = (state = {}, action) => {
    const { type, payload } = action;
    switch (type) {
        case C.CHANGE_SELECTED_STYLE:
            return payload;
        default:
            return state;
    }
}

const materials = (state = [], action) => {
    const { type, payload } = action;
    switch (type) {
        case C.CHANGE_LIST_MATERIAL:
            return payload;
        case C.CHANGE_MATERIAL_VALUE: {
            const { checkedFabric, checkedTrim, id, idx, key, value } = payload;
            let dataDic = _.cloneDeep(state);
            let dic = dataDic.filter(function (d) {
                if (checkedFabric && checkedTrim) {
                    return true;
                }
                else if (checkedFabric) {
                    return d.material.type == "FABRIC";
                }
                else if (checkedTrim) {
                    return d.material.type == "TRIM";
                }
                else {
                    return false;
                }
            });
            dic.map((item, index) => (item.material._id === id && index === idx) ? item[key] = value : null)
            return dic;
        }
        default:
            return state;
    }
}

const checkedFabric = (state = true, action) => {
    const { type, payload } = action;
    switch (type) {
        case C.CHANGE_CHECKED_FABRIC:
            return payload;
        default:
            return state;
    }
}

const checkedTrim = (state = true, action) => {
    const { type, payload } = action;
    switch (type) {
        case C.CHANGE_CHECKED_TRIM:
            return payload;
        default:
            return state;
    }
}

const createSuccessStatus = (state = false, action) => {
    const { type, payload } = action;
    switch (type) {
        case C.CREATE_PURCHASE_ORDER_SUCCESS:
            return payload;
        case C.RESET_SUCCESS_STATUS:
            return false;
        default:
            return state;
    }
}

const selectedMaterials = (state = [], action) => {
    const { type, payload } = action;
    switch (type) {
        case C.CHANGE_SELECTED_LIST_MATERIALS: {
            let result = _.cloneDeep(payload).map(item => ({
                ...item,
                XMIL: moment().format(),
                ETD: moment().format(),
                ETA: moment().format(),
                exReceivedDate: moment().format(),
                payer: 'LM',
                surcharge: 0,
                discount: 0,
                totalPrice: item.amountNumber,
            }));
            return result;   
        }
        case C.CHANGE_MATERIAL_IN_PLACE_ORDER_VALUE: {
            let dic = _.cloneDeep(state);
            let amount;
            const {index, key, value} = payload;
            dic.map((item, idx) => {
                idx == index ? item[key] = value : null;
                if(key == 'surcharge') {
                    amount = item.amountNumber + value - item.discount;
                    item.totalPrice = amount;
                }
                if(key == 'discount') {
                    amount = item.amountNumber - value + item.surcharge;
                    item.totalPrice = amount;
                }
            })

            return dic
        }
        default:
            return state;
    }
}

const remark = (state = '', action) => {
    const { type, payload } = action;
    switch (type) {
        case C.CHANGE_REMARK:
            return payload;
        default:
            return state;
    }
}

const firstLoad = (state = true, action) => {
    const {type, payload} = action;
    switch(type) {
        case C.CHANGE_LIST_ORDER:
            if(payload) {
                return false
            }
            return true;
        default:
            return state;
    }
}


const loadMore = (state = true, action) => {
    const { type } = action;
    switch (type) {
      case C.LOAD_MORE:
        return true;
      case C.CANCEL_LOAD_MORE:
        return false;
      default:
        return state;
    }
  };
  
  const isSearch = (state = false, action) => {
    const { type } = action;
    switch (type) {
      case C.IS_SEARCH:
        return true;
      case C.CANCEL_IS_SEARCH:
        return false;
      default:
        return state;
    }
  };
  
  const pageNumber = (state = 1, action) => {
    const { type, payload } = action;
    switch (type) {
      case C.CHANGE_PAGE_NUMBER:
        return payload ? payload : state;
      case C.RESET_CHANGE_PAGE_NUMBER:
        return 1;  
      default:
        return state;
    }
  }

  const filterText = (state ='', action) => {
    const { type, payload } = action;
    switch (type) {
      case C.CHANGE_FILTER_TEXT:
        return payload ? payload : '';
      default:
        return state;
    }
  }

  const loading = (state = true, action) => {
    const { type } = action;
    switch (type) {
      case C.LOADING:
        return true;
      case C.CANCEL_LOADING:
        return false;
      default:
        return state;
    }
  }

const combined = combineReducers({
    purchaseOrders,
    selectedOrder,
    listStyles,
    selectedStyle,
    materials,
    checkedFabric,
    checkedTrim,
    createSuccessStatus,
    selectedMaterials,
    remark,
    firstLoad,
    loadMore,
    isSearch,
    pageNumber,
    filterText,
    loading
})

export default (state, action) => {
    const { type } = action;
    if (type === C.RESET_DATA) {
        return {};
    }
    return combined(state, action);
};