import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { browserHistory } from 'react-router';

const App = lazy(() => import('./components/App'));

const ResetPassword = lazy(() => import('./components/auth/ResetPassword'));

const ForgotPassword = lazy(() => import('./components/auth/ForgotPassword'));

const LoginPage = lazy(() => import('./components/auth/LoginPage'));

const Loading = () => <div>loading...</div>;

export default (
  <Router history={browserHistory}>
      <Suspense fallback={<Loading />}>
        <Switch>
          <Route path="/forgotPassword" component={ForgotPassword} />
          <Route path="/resetPassword" component={ResetPassword} />
          <Route path="/login" component={LoginPage} />
          <Route path="/" component={App} />
        </Switch>
      </Suspense>
  </Router>
);
