const C = "SHOW_PKL_SHIP_LIST";

export default {
    /* Actions that will affect displaying list */
    FETCH_PKL_SHIP_LIST: `${C}_FETCH_PKL_SHIP_LIST`,
    CANCEL_FETCH_PKL_SHIP_LIST: `${C}_CANCEL_FETCH_PKL_SHIP_LIST`,
    CHANGE_PKL_SHIP_LIST: `${C}_CHANGE_PKL_SHIP_LIST`,

    /* Actions that will affect checked list */
    ADD_CHECKED_PKL_SHIP: `${C}_ADD_CHECKED_PKL_SHIP`,
    REMOVE_CHECKED_PKL_SHIP: `${C}_REMOVE_CHECKED_PKL_SHIP`,
    CLEAR_ALL_CHECKED_PKL_SHIPS: `${C}_CLEAR_ALL_CHECKED_PKL_SHIPS`,

    /* Actions that will affect pagination view */
    CHANGE_PAGING_CURRENT: `${C}_CHANGE_PAGING_CURRENT`,
    CHANGE_PAGING_TOTAL: `${C}_CHANGE_PAGING_TOTAL`,

    /* Actions that will affect filter */
    CHANGE_SELECTED_FILTER: `${C}_CHANGE_SELECTED_FILTER`,
    CHANGE_SEARCH_TEXT: `${C}_CHANGE_SEARCH_TEXT`,
    SEARCH_PKL_SHIP_LIST: `${C}_SEARCH_PKL_SHIP_LIST`,
    CANCEL_SEARCH_PKL_SHIP_LIST: `${C}_SEARCH_PKL_SHIP_LIST`,
    CHANGE_SEARCH_STATUS: `${C}_CHANGE_SEARCH_STATUS`,
}