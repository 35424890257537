import React from 'react';
import { Link, Switch, Route, Redirect } from 'react-router-dom';
import {connect} from 'react-redux';
import BaseComponent from '../base/BaseComponent';
import I18n from 'i18n-js';
import * as PATH from '../../constants/routeConstants';
import * as R from 'ramda';

import AccountsReceivablePlan from '../../ui/containers/accounting/AccountsReceivablePlan';
// import ChargeBack from '../../ui/containers/accounting/receivable/plan/ChargeBack';
import CreatePlanNormalInvoiceView from '../../ui/containers/accounting/receivable/CreatePlanNormalInvoice';
import CreatePlanChargeBackInvoiceView from '../../ui/containers/accounting/receivable/CreatePlanChargeBackInvoice';
import EditPlanChargeBackInvoice from '../../ui/containers/accounting/receivable/EditPlanChargeBackInvoice';
import ShowInvoiceListView from '../../ui/containers/accounting/receivable/AccountsListReceivableInvoice';
import ShowInvoiceListHistoryView from '../../ui/containers/accounting/receivable/AccountsListHistoryReceivableInvoice';
class ScreenAccountsReceivable extends BaseComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  render() {
    const pathname = R.path(['location', 'pathname'], this.props);

    return (
      <div className='show'>
        <div className="global-table-tab">
          <ul className={`nav nav-tabs wrap-tab ${!pathname.startsWith(PATH.ACCOUNTING_RECEIVABLE_PLAN_URL) && 'accounting-tabs'}`}>
            <li className={pathname.startsWith(PATH.ACCOUNTING_RECEIVABLE_PLAN_URL) ? "active" : ''}>
              <Link to={PATH.ACCOUNTING_RECEIVABLE_PLAN_URL}
                aria-controls="tab-plan" role="tab"
              >
                {I18n.t('ScreenAccountsPayable.Plan')}
              </Link>
            </li>

            <li className={pathname === PATH.ACCOUNTING_RECEIVABLE_INVOICE_URL ? "active" : ''}>
              <Link to={PATH.ACCOUNTING_RECEIVABLE_INVOICE_URL}
                aria-controls="tab-invoice-list" role="tab"
              >
                {I18n.t('ScreenAccountsPayable.InvoiceList')}
              </Link>
            </li>

            <li className={pathname === PATH.ACCOUNTING_RECEIVABLE_HISTORY_URL ? "active" : ""}>
              <Link to={PATH.ACCOUNTING_RECEIVABLE_HISTORY_URL}
                aria-controls="History" role="tab"
              >
                {I18n.t('ScreenAccountsPayable.History')}
              </Link>
            </li>
           
          </ul>
          
          <div className="tab-content bg-none">
            <Switch>
              <Route path={PATH.ACCOUNTING_RECEIVABLE_URL} exact render={() => <Redirect to={PATH.ACCOUNTING_RECEIVABLE_PLAN_URL} />} />
              <Route path={PATH.ACCOUNTING_RECEIVABLE_PLAN_URL} exact render={() => <Redirect to={PATH.ACCOUNTING_RECEIVABLE_PLAN_NORMAL_URL} />} />
              <Route path={PATH.ACCOUNTING_RECEIVABLE_PLAN_NORMAL_URL} exact render={() => <CreatePlanNormalInvoiceView {...this.props}/>} />
              <Route path={PATH.ACCOUNTING_RECEIVABLE_PLAN_CHARGE_BACK_URL} exact render={() => <CreatePlanChargeBackInvoiceView {...this.props}/>} />

              <Route path={PATH.ACCOUNTING_RECEIVABLE_PLAN_EDIT_CHARGE_BACK_URL} exact render={() => <EditPlanChargeBackInvoice {...this.props}/>} />

              <Route path={PATH.ACCOUNTING_RECEIVABLE_PLAN_URL} render = {() => (
                <AccountsReceivablePlan />
              )}/>
              <Route path={PATH.ACCOUNTING_RECEIVABLE_INVOICE_URL} render = { () => <ShowInvoiceListView {...this.props}/> }/>
              <Route path={PATH.ACCOUNTING_RECEIVABLE_HISTORY_URL} render = { () => <ShowInvoiceListHistoryView {...this.props}/> }/>
            </Switch>
          </div>
        </div>

        <div className="modal fade" id="modal-add-accounts-receivable-invoice" tabIndex="-1" role="dialog">
          <div className="modal-dialog" role="document">
            <div className="modal-content">

              <div className="modal-header">
                <a className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></a>
                <h4 className="modal-title" id="myModalLabel">{I18n.t('ScreenAccountsReceivable.ADDINVOICE')}</h4>
              </div>
              <div className="modal-body">

                <div className="form-group">
                  <div className="col-md-4 col-xs-12 text">
                    <p>
                      {I18n.t('ScreenAccountsReceivable.InvoiceNo')}
                    </p>
                  </div>
                  <div className="col-md-8 col-xs-12 value">
                    <input type="text" placeholder="Invoice No" className="form-control"/>
                  </div>
                </div>

                <div className="form-group">
                  <div className="col-md-4 col-xs-12 text">
                    <p>
                      {I18n.t('ScreenAccountsReceivable.IssuedDate')}
                    </p>
                  </div>
                  <div className="col-md-8 col-xs-12 value">
                    <input type="text" placeholder="Issued Date" className="form-control"/>
                  </div>
                </div>

                <div className="form-group">
                  <div className="col-md-4 col-xs-12 text">
                    <p>
                      {I18n.t('ScreenAccountsReceivable.DueDate')}
                    </p>
                  </div>
                  <div className="col-md-8 col-xs-12 value">
                    <input type="text" placeholder="Due Date" className="form-control"/>
                  </div>
                </div>

                <div className="form-group">
                  <div className="col-md-4 col-xs-12 text">
                    <p>
                      {I18n.t('ScreenAccountsReceivable.Customer')}
                    </p>
                  </div>
                  <div className="col-md-8 col-xs-12 value">
                    <select className="form-control">
                      <option value="">JEMMA</option>
                      <option value="">JEMMA</option>
                      <option value="">JEMMA</option>
                    </select>
                  </div>
                </div>


              </div>
              <div className="modal-footer">
                <button type="button" className="bt-black mg-right-15" data-dismiss="modal">{I18n.t('Btn.CANCEL')}</button>
                <button type="button" className="bt-orange">{I18n.t('Btn.SAVE')}</button>
              </div>

            </div>
          </div>
        </div>

      </div>

    );
  }
}

const mapStateToProps = (state, ownProps) => ({
});

const mapDispatchToProps = dispatch => ({
  save() {

  }
});

ScreenAccountsReceivable.propTypes = {
  
};

export default connect(mapStateToProps, mapDispatchToProps)(ScreenAccountsReceivable);

