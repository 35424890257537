import * as types from '../constants/actionTypes';
import {showLoading, hideLoading} from './commonUseActions';
import supplierServices from '../services/supplierServices';


function getListSupplier (options = {}) {
  return (dispatch) => {
    showLoading(types, dispatch);
    return supplierServices.getListSupplier(options).then((res) => {
      hideLoading(types, dispatch);
      if (res) {
        dispatch({
          type: types.SUPPLIER.GET_LIST_SUCCESS,
          data: res.data || []
        });
      }
      return res;
    }, (error) => {
      hideLoading(types, dispatch);
    });
  };
}

function getDetail (id, reset = true) {
  return (dispatch) => {
    showLoading(types, dispatch);

    reset && dispatch({
      type: types.ORDER.RESET_DETAIL,
      data: {}
    });

    supplierServices.getDetail(id, {}).then((res) => {
      hideLoading(types, dispatch);

      if (res) {
        dispatch({
          type: types.SUPPLIER.GET_DETAIL_SUCCESS,
          data: res.data || {}
        });
      }
    }, (error) => {
      hideLoading(types, dispatch);
    });
  };
}

function deleteSupplier (id) {
  return (dispatch) => {
    showLoading(types, dispatch);

    supplierServices.deleteSupplier(id).then((res) => {
      hideLoading(types, dispatch);

      if (res) {
        dispatch({
          type: types.SUPPLIER.RELOAD,
          data: true
        });
      }
    }, (error) => {
      hideLoading(types, dispatch, error);
    });
  };
}

function editSupplier (id, data) {
  return (dispatch) => {
    showLoading(types, dispatch);

    return supplierServices.editSupplier(id, data).then((res) => {
      hideLoading(types, dispatch);

      if (res) {
        dispatch({
          type: types.SUPPLIER.RELOAD,
          data: true
        });
      }
      return res;
    }, (error) => {

      hideLoading(types, dispatch);
      return error;
    });
  };
}

function addSupplier (data) {
  return (dispatch) => {
    showLoading(types, dispatch);

    return supplierServices.addSupplier(data).then((res) => {
      hideLoading(types, dispatch, (res && !res.success) ? res.error : undefined);

      if (res && res.success) {
        dispatch({
          type: types.SUPPLIER.RELOAD,
          data: true
        });
      }
      return res;
    }, (error) => {
      hideLoading(types, dispatch);
      return error;
    });
  };
}

export default {
  getListSupplier,
  deleteSupplier,
  editSupplier,
  addSupplier,
  getDetail
};
