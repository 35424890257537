import RestClient from './restClient';
import {ApiMethod} from '../constants/constants';
import Urls from '../constants/apiUrls';
import uploadFile from './uploadFileApi';

export default class Base {
  constructor () {
    this.Urls = Urls;
    this.ApiMethod = ApiMethod;
  }

  execute (url, method, data, language) {
    let restClient = new RestClient();
    if (language != null) {
      restClient.setLanguage(language);
    }
    return restClient.execute(url, method, data);
  }

  uploadFile (data, language) {
    let restClient = new RestClient();
    if (language != null) {
      restClient.setLanguage(language);
    }
    return uploadFile(data);
  }
}
