import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import BaseComponent from '../base/BaseComponent';
import TabOrganization from './TabOrganization';
import TabDepartment from './TabDepartment';
import TabPermissionRole from './TabRolePermission';
import RoleFormScreen from './TabPermissionRole/RoleFormScreen';
import TabUsers from './TabUsers';
import roleActions from '../../actions/roleActions';
import I18n from 'i18n-js';
import {ORGANIZATION_TAB} from "../../constants/constants";
import appActions from '../../actions/appActions';
import { bindActionCreators } from 'redux';
class PageOrganization extends BaseComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {
      isEditRole: false,
      idAddRole: false,
      roleDetail: {},
      roleId: "",
      tabActive: ORGANIZATION_TAB.ORGANIZATION,
      pageSize: 10,
      pageNumberPermission: 1
    };
    this.handleClickTab = this.handleClickTab.bind(this);
    this.handleAddEditRole = this.handleAddEditRole.bind(this);
    this.handleSelectPagePermission = this.handleSelectPagePermission.bind(this);
  }

  handleAddEditRole({roleId, isEditRole}) {
    this.setState({roleId, isEditRole});
  }

  handleClickTab(tabActive) {
    this.setState({tabActive});
  }

  handleSelectPagePermission(page) {
    this.setState({pageNumberPermission: page})
  }

  componentWillUnmount() {
    this.props.appPropsActions.setDataPageCurrent({isEdit: false, isAdd: false});
  }

  render() {
    let {isEditRole, roleId} = this.state;
    const pageCurrent = this.props.appState.page || {};
    let showEditRole = isEditRole && !!pageCurrent.isEdit;
    return (
      <div>
        <div id="content">
          {showEditRole &&
            <RoleFormScreen
              handleAddEditRole={this.handleAddEditRole}
              roleId={roleId}
              isEditRole={isEditRole}
            />
          }
          {!showEditRole &&
          <div className="global-table-tab wrap-position-filter">
            <ul className="nav nav-tabs wrap-tab" role="tablist">
              <li role="presentation" className={this.state.tabActive === ORGANIZATION_TAB.ORGANIZATION ? "active" : ""}>
                <a onClick={()=>this.handleClickTab(ORGANIZATION_TAB.ORGANIZATION)}
                   aria-controls="organization-organization" role="tab"
                   data-toggle="tab">{I18n.t('PageOrganization.Organization')}</a>
                <span className="line"/>
              </li>
              <li role="presentation" className={this.state.tabActive === ORGANIZATION_TAB.DEPARTMENT ? "active" : ""}>
                <a onClick={()=>this.handleClickTab(ORGANIZATION_TAB.DEPARTMENT)}
                   aria-controls="organization-department"
                   role="tab" data-toggle="tab">{I18n.t('PageOrganization.Department')}</a>
                <span className="line"/>
              </li>
              <li role="presentation" className={this.state.tabActive === ORGANIZATION_TAB.PERMISSION_ROLE ? "active" : ""}>
                <a onClick={()=>this.handleClickTab(ORGANIZATION_TAB.PERMISSION_ROLE)}
                   aria-controls="organization-permission-role" role="tab"
                   data-toggle="tab">{I18n.t('PageOrganization.PermissionRole')}</a>
                <span className="line"/></li>
              <li role="presentation" className={this.state.tabActive === ORGANIZATION_TAB.USERS ? "active" : ""}>
                <a onClick={()=>this.handleClickTab(ORGANIZATION_TAB.USERS)}
                   aria-controls="organization-users" role="tab"
                   data-toggle="tab">{I18n.t('PageOrganization.Users')}</a>
              </li>
            </ul>

            <div className="tab-content">
              <TabOrganization
                {...this.props}
                active={this.state.tabActive === ORGANIZATION_TAB.ORGANIZATION}/>
              <TabDepartment
                {...this.props}
                active={this.state.tabActive === ORGANIZATION_TAB.DEPARTMENT}/>
              <TabPermissionRole
                {...this.props}
                onSelectPage={this.handleSelectPagePermission}
                handleAddEditRole={this.handleAddEditRole}
                pageSize={this.state.pageSize}
                pageNumber={this.state.pageNumberPermission}
                active={this.state.tabActive === ORGANIZATION_TAB.PERMISSION_ROLE}/>
              <TabUsers
                {...this.props}
                active={this.state.tabActive === ORGANIZATION_TAB.USERS}/>
            </div>
          </div>
          }
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    appState: state.appState,
    authState: state.authState,
    roleState: state.roleState
  };
}

function mapDispatchToProps(dispatch) {
  return {
    appPropsActions: bindActionCreators(appActions, dispatch)
  };
}

PageOrganization.propTypes = {
  authState: PropTypes.object.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(PageOrganization);
