/**
 * Selectors for import/export invoice (declaration)
 * ===================================================
 * @description connect to import/export invoice (declaration) reducer
 * to create selectors
 * 
 */

import { createSelector } from 'reselect';
import _ from 'lodash';
import { genVietNamDong } from '../../../functions/helpers';
/**
 * Selectors for overview information of invoice (first component)
 *
 */

export const getName = ({ createPayableInvoiceImpExpDeclarations }) => {
    return createPayableInvoiceImpExpDeclarations.name;
}

export const getOtherFees = ({ createPayableInvoiceImpExpDeclarations }) => {
    return createPayableInvoiceImpExpDeclarations.otherFees;
}

export const getNote = ({ createPayableInvoiceImpExpDeclarations }) => {
    return createPayableInvoiceImpExpDeclarations.note;
}

export const getInvoiceNo = ({ createPayableInvoiceImpExpDeclarations }) => {
    return createPayableInvoiceImpExpDeclarations.invoiceNo;
}

export const getInvoiceDate = ({ createPayableInvoiceImpExpDeclarations }) => {
    return createPayableInvoiceImpExpDeclarations.invoiceDate;
}

export const getSelectableCreditDebits = ({ createPayableInvoiceImpExpDeclarations }) => {
    /**
     * Get selectable credit debit notes (filtered by import/export company) from reducer
     */
    return createPayableInvoiceImpExpDeclarations.selectableCreditDebits;
}

export const getSelectedCreditDebit = ({ createPayableInvoiceImpExpDeclarations }) => {
    return createPayableInvoiceImpExpDeclarations.selectableCreditDebits;
}








/**
 * Get values in VAT section
 *
 */
export const getMainVATNo = ({ createPayableInvoiceImpExpDeclarations }) => {
    return createPayableInvoiceImpExpDeclarations.mainVATNo;
}

export const getMainVATDate = ({ createPayableInvoiceImpExpDeclarations }) => {
    return createPayableInvoiceImpExpDeclarations.mainVATDate;
}

export const getMainTaxRate = ({ createPayableInvoiceImpExpDeclarations }) => {
    return createPayableInvoiceImpExpDeclarations.mainTaxRate;
}

export const getMainTotalAmount = ({ createPayableInvoiceImpExpDeclarations }) => {
    return createPayableInvoiceImpExpDeclarations.mainTotalAmount;
}

export const getMainVATAmount = ({ createPayableInvoiceImpExpDeclarations }) => {
    // TODO.
    throw "NotImplementedException";
}

export const getGrandTotalVAT = ({ createPayableInvoiceImpExpDeclarations }) => {
    // TODO.
    throw "NotImplementedException";
}

const genDisplayDeclarationModel = (model, checked) => {
    if (!model) return {};
    return _.assign(new Proxy(model, {
        get(obj, prop) {
            const expense = (((obj['crsPaperWork'] || 0) * 1) + ((obj['truckingIn'] || 0) * 1));
            const remaining = (expense + ((obj['localCharge'] || 0) * 1) - ((obj['advance'] || 0) * 1));
            switch (prop) {
                case 'crsPaperWork':
                    return (obj['crsPaperWork'] || 0) * 1
                case 'truckingIn':
                    return (obj['truckingIn'] || 0) * 1
                case 'localCharge':
                    return (obj['localCharge'] || 0) * 1
                case 'advance':
                    return (obj['advance'] || 0) * 1
                // case 'declarationDate':
                //   return obj['declarationDate'] ? moment(obj['declarationDate']).format("MMM DD, YYYY") : '';
                case 'expense':
                    return expense;
                case 'remaining':
                    return remaining;
                case 'raw':
                    return obj;
                default:
                    return obj[prop];
            }
        },
    }), { checked: checked });
};

export const getCheckedList = ({ createPayableInvoiceImpExpDeclarations }) => createPayableInvoiceImpExpDeclarations.checkedDeclarations;

export const getSelectableDeclarations = createSelector(
    [
        ({ createPayableInvoiceImpExpDeclarations }) => createPayableInvoiceImpExpDeclarations.declarations,
        ({ createPayableInvoiceImpExpDeclarations }) => createPayableInvoiceImpExpDeclarations.searchText,
        getCheckedList,
    ],
    (declarations, query, checkedList) => {
        const data = _.cloneDeep(declarations).map(it => {
            const checked = checkedList.findIndex(item => item === it._id) >= 0
            return genDisplayDeclarationModel(it, checked)
        }
        ).filter(item => !item.declarationNo || item.declarationNo.toLowerCase().indexOf(query.toLowerCase()) > -1)
        return data.map(item => ({ _id: item._id, checked: item.checked, name: item.declarationNo }))
    }
)

export const getDetailedDeclarations = createSelector(
    [
        ({ createPayableInvoiceImpExpDeclarations }) => createPayableInvoiceImpExpDeclarations.declarations,
        getCheckedList,
    ],
    (declarations, checkedList) => {
        let data = []
        _.cloneDeep(declarations).map(it => {
            if (checkedList.findIndex(item => item === it._id) >= 0)
                data.push(genDisplayDeclarationModel(it))
        }
        )
        return data
    }
)

export const getAmount = createSelector(
    [
        getDetailedDeclarations
    ], (listDeclaration) => {
        return (_.sum(listDeclaration.map(item => (item.crsPaperWork || 0) + (item.truckingIn || 0) + (item.localCharge || 0) - (item.advance || 0))))
    }
)

export const getAmountPreview = createSelector(
    [
        getDetailedDeclarations
    ], (listDeclaration) => {
        return (_.sum(listDeclaration.map(item => (item.crsPaperWork || 0) + (item.truckingIn || 0) + (item.localCharge || 0))))
    }
)

export const getTotalAmountPreview = createSelector(
    [
        ({ createPayableInvoiceImpExpDeclarations }) => createPayableInvoiceImpExpDeclarations.otherFees,
        getAmountPreview
    ], (orderFees, amount) => {
        return (amount + orderFees)
    }
)

export const getTotalAmount = createSelector(
    [
        ({ createPayableInvoiceImpExpDeclarations }) => createPayableInvoiceImpExpDeclarations.otherFees,
        getAmount
    ], (orderFees, amount) => {
        return (amount + orderFees)
    }
)

export const getTotalAmountSay = createSelector(
    [getTotalAmount],
    (totalAmount) => {
        return genVietNamDong(_.round(totalAmount, 2))
    }
);


export const getSearchText = ({ createPayableInvoiceImpExpDeclarations }) => createPayableInvoiceImpExpDeclarations.searchText;

export const getCanPreview = ({ createPayableInvoiceImpExpDeclarations }) => !_.isEmpty(createPayableInvoiceImpExpDeclarations.checkedDeclarations);

export const getTotalAdvance = createSelector(
    [
        getDetailedDeclarations
    ], (listDeclaration) => {
        return _.sum(listDeclaration.map(item => item.advance))
    }
)
export const getTotalRemaining = createSelector(
    [
        getDetailedDeclarations
    ], (listDeclaration) => {
        return _.sum(listDeclaration.map(item => genDisplayDeclarationModel(item).remaining))
    }
)

export const fnGenerateRequestBody = createSelector(
    [
        ({ createPayableInvoiceImpExpDeclarations }) => createPayableInvoiceImpExpDeclarations,
        getDetailedDeclarations,
        getTotalAmount
    ], (data, listDeclaration, amount) => {
        const result = {
            typeInvoice: "declarationInvoice",
            invNo: data.invoiceNo,
            invoiceDate: data.invoiceDate,
            note: data.note,
            amount: amount,
            currency: 'VND',
            invoiceDetail: {
                name: data.name,
                otherFees: data.otherFees,
                declarations: listDeclaration.map(item => ({
                    ...item,
                    importExportFees: item.crsPaperWork,
                    transportationFees: item.truckingIn,
                    expense: ((item.crsPaperWork || 0) * 1) + ((item.truckingIn || 0) * 1),
                    remaining: (((item.crsPaperWork || 0) * 1) + ((item.truckingIn || 0) * 1) + ((item.localCharge || 0) * 1) - ((item.advance|| 0) * 1)),
                }))
            }
        }
        return result
    }
)

export const getSuccess = ({ createPayableInvoiceImpExpDeclarations }) => createPayableInvoiceImpExpDeclarations.success

export const getActiveScreenStatus = ({createPayableInvoiceImpExpDeclarations}) => createPayableInvoiceImpExpDeclarations.activeScreenPreview;