import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import storeActions from '../../actions/storeActions';
import StoreEdit from './modal/StoreEdit';
import ModalConfirm from '../common/modals/ModalConfirm';
import StoreAdd from './modal/StoreAdd';
import BaseComponent from '../base/BaseComponent';
import TableList from '../common/datatables/TableList';
import SearchBar from '../common/layout/SearchBar';
import _ from 'lodash';
import I18n from 'i18n-js';
import { SEARCH_BAR_EVENT, SEARCH_KEY, SEARCH_LIST } from "../../constants/constants";
import './style.scss';
import CustomPagination from '../common/datatables/CustomPagination';

class TabStores extends BaseComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {
      tabActive: '',
      pageSize: 10,
      pageNumber: 1,
      columns: [
        { name: I18n.t('PageCrm.Name'), value: 'name' },
        { name: I18n.t('PageCrm.Address'), value: 'address' },
        { name: I18n.t('PageCrm.PackingStandard'), value: 'packingStandard.name' },
      ],
      items: [],
      total: 0,
      search: { key: SEARCH_KEY.NAME, value: "" }
    };

    this.onDeleteStore = this.onDeleteStore.bind(this);
  }
  componentWillMount() {
    this.getListItems();
  }

  componentWillReceiveProps(newProps) {
    let { items } = this.state;
    !_.isEqual(items, newProps.storeState.items) && this.setState({
      items: _.cloneDeep(newProps.storeState.items) || [],
      total: newProps.storeState.count || 0
    });
  }

  componentWillUpdate(nextProps, nextState) {
    if (_.get(this.props.storeState, 'reload') != _.get(nextProps.storeState, 'reload')) {
      this.search(nextState.search, nextState.pageNumber, nextState.pageSize);
    }
    _.get(this.props.storeState, 'reload') != _.get(nextProps.storeState, 'reload') && this.props.modal.closeModal();
  }

  getListItems(query = {}) {
    let { pageSize, pageNumber } = this.state;
    let _query = _.assign({ pageSize: pageSize, pageNumber: pageNumber, populate: 'packingStandard' }, query)
    this.props.storeActions.getListStore(_.set(_query, 'sort', { createdAt: -1 }));
  }

  addItem(item) {
    let { openModal, closeModal } = this.props.modal;
    let modal = (<StoreAdd closeModal={closeModal} />);
    openModal(modal);
  }

  editStore(item) {
    let { openModal, closeModal } = this.props.modal;
    let modal = (<StoreEdit closeModal={closeModal} options={{ data: item }} />);
    openModal(modal);
  }

  showDeleteConfirm(item) {
    let { openModal, closeModal } = this.props.modal;
    let modal = (<ModalConfirm
      title={I18n.t('PageCrm.DELETESTORE')}
      confirmMessage={I18n.t('PageCrm.ConfirmDeleteSupplier')}
      messageOk={I18n.t('Btn.YES')}
      messageCancel={I18n.t('Btn.CANCEL')}
      handleClose={closeModal}
      handleOk={this.onDeleteStore.bind(null, item)} />);
    openModal(modal);
  }

  onDeleteStore(item) {
    item._id && this.props.storeActions.deleteStore(item._id);
  }

  selectPage = (page) => {
    this.setState({ pageNumber: page });
    this.search(this.state.search, page, this.state.pageSize);
  }

  onChangeSearch(property, value) {
    switch (property) {
      case SEARCH_BAR_EVENT.FILTER: {
        this.setState({ search: { key: value, value: "" } });
        break;
      }
      case SEARCH_BAR_EVENT.SEARCH: {
        this.setState({ search: { ...this.state.search, value: value } });
        break;
      }
    }
  }

  onSearch() {
    const { search, pageNumber, pageSize } = this.state;
    this.search(search, pageNumber, pageSize);
  }

  search(search, pageNumber, pageSize) {
    let options = {};
    const populate = 'packingStandard';
    if (search && search.key && search.value) {
      switch (search.key) {
        case SEARCH_KEY.NAME: {
          options = {
            pageNumber,
            pageSize,
            sort: { updatedAt: -1 },
            populate: populate,
            query: { name: { $regex: '.*' + search.value + '.*', $options: 'i' } }
          };
          this.props.storeActions.getListStore(options).then(res => {
            if (res && res.success && res.data && res.data.items && !res.data.items.length && pageNumber > 1) {
              options = {
                pageNumber: 1,
                pageSize,
                sort: { updatedAt: -1 },
                populate: populate,
                query: { name: { $regex: '.*' + search.value + '.*', $options: 'i' } }
              };
              this.setState({ pageNumber: 1 });
              this.props.storeActions.getListStore(options)
            }
          });
          break;
        }
        case SEARCH_KEY.ADDRESS: {
          options = {
            pageNumber,
            pageSize,
            sort: { updatedAt: -1 },
            populate: populate,
            query: { address: { $regex: '.*' + search.value + '.*', $options: 'i' } }
          };
          this.props.storeActions.getListStore(options).then(res => {
            if (res && res.success && res.data && res.data.items && !res.data.items.length && pageNumber > 1) {
              options = {
                pageNumber: 1,
                pageSize,
                sort: { updatedAt: -1 },
                populate: populate,
                query: { address: { $regex: '.*' + search.value + '.*', $options: 'i' } }
              };
              this.setState({ pageNumber: 1 });
              this.props.storeActions.getListStore(options)
            }
          });
          break;
        }

      }
    }
    if (!search.value) {
      let options = {
        pageNumber,
        pageSize,
        sort: { updatedAt: -1 },
        populate: populate,
      };
      this.props.storeActions.getListStore(options).then(res => {
        if (res && res.success && res.data && res.data.items && !res.data.items.length && pageNumber > 1) {
          options = {
            pageNumber: 1,
            pageSize,
            sort: { updatedAt: -1 },
            populate: populate,
          };
          this.setState({ pageNumber: 1 });
          this.props.storeActions.getListStore(options);
        }
      });
    }
  }

  render() {
    let { items, total, columns, pageNumber, pageSize } = this.state;
    let { roles: {showCreate, showEdit, showDelete} } = this.props;
    let actions = [
      (item) => {
        return showEdit ? { type: 'edit', click: (item) => this.editStore(item) } : {};
      },
      (item) => {
        return showDelete ? { type: 'delete', click: (item) => this.showDeleteConfirm(item) } : {};
      }
    ];
    let totalPage = _.ceil(total / pageSize);
    return (
      <div role="tabpanel" className="tab-pane" id="crm-stores">
        <div className="wrap-search-position">
          <SearchBar
            name={I18n.t('PageCrm.AddNew')}
            addItem={showCreate ? () => this.addItem() : null}
            fields={SEARCH_LIST.CRM_STORE}
            onChangeSearch={(type, data) => this.onChangeSearch(type, data)}
            onSearch={() => this.onSearch()}
          />
        </div>
        <TableList {...this.props} items={items || []} columns={columns || []} actions={actions || []} options={{ clsTbl: 'global-table box-shadow-none' }} widthAction="w-70" />
        <CustomPagination items={totalPage} activePage={pageNumber} onSelect={this.selectPage} />
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    appState: state.appState,
    authState: state.authState,
    storeState: state.storeState
  };
}

function mapDispatchToProps(dispatch) {
  return {
    storeActions: bindActionCreators(storeActions, dispatch)
  };
}

TabStores.propTypes = {
  authState: PropTypes.object.isRequired,
  storeState: PropTypes.object
};

export default connect(mapStateToProps, mapDispatchToProps)(TabStores);

