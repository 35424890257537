import React from 'react';
import PropTypes from 'prop-types';
import BaseComponent from '../../base/BaseComponent';
import I18n from 'i18n-js';
import moment from 'moment';
import _ from 'lodash';
import {textTrim, pathImage, getMetaImage} from "../../../functions/helpers";

import gragPhotoIcon from '../../../stylesheets/images/grag-photo.svg';

export default class CutTicket extends BaseComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {
      styleImageUrl: gragPhotoIcon
    };
  }

  componentWillMount() {
    const {cutTicket} = this.props;
    const url = pathImage(_.get(cutTicket, "images", [])[0]);
    const _this = this;
    getMetaImage(url, function (url, success, w, h) {
      if(success) {
        _this.setState({styleImageUrl: url});
      }
    })
  }

  render() {
    const {cutTicket} = this.props;
    return (
      <div className="md content-main-info-number pd-0 overflow-hidden h-full-none mg-bottom-5">

        <ul className="top bd-dashed-bottom">
          <li>
            <div className="text">
              <span className="left">{I18n.t('PageOrder.CutTicket')}</span>
              <span className="right size-15">: <span className="medium">{_.get(cutTicket, "cutTicket", "")}</span></span>
            </div>
          </li>
          <li>
            <div className="text">
              <span className="left">{I18n.t('PageOrder.G-Total')}</span>
              <span className="right size-15 color-number">: <span className="medium">{_.get(cutTicket, "gTotal", "")}</span></span>
            </div>

          </li>

        </ul>

        <ul className="center">
          <li className="img">
            <div className="images">
              <img src={this.state.styleImageUrl} alt=""/>
              <span className="cursor-pointer" data-toggle="modal" data-target="#modal-add-picture">
                <img src={_.get(cutTicket, "images", [])[0]} alt=""/>
              </span>
            </div>
          </li>
          <li className="padding-img size-12 list-info-style-order">
            <div className="col-md-6 col-xs-12">
              <div className="text">
                <span className="left">{I18n.t('PageOrder.Warehouse')}</span>
                <span className="right">{_.get(cutTicket, "warehouse.name", "")}</span>
              </div>
              <div className="text">
                <span className="left">{I18n.t('PageOrder.ShipMode')}</span>
                <span className="right">{_.get(cutTicket, "shipMode", "")}</span>
              </div>
              <div className="text">
                <span className="left">{I18n.t('PageOrder.MainLabel')}</span>
                <span className="right">{_.get(cutTicket, "mainLabel", "")}</span>
              </div>
            </div>
            <div className="col-md-6 col-xs-12">
              <div className="text">
                <span className="left">{I18n.t('PageOrder.IHDate')}</span>
                <span className="right">
                  {moment(_.get(cutTicket, "ihDate", new Date())).format("MMMM DD, YYYY")}
                  </span>
              </div>

              <div className="text">
                <span className="left">{I18n.t('PageOrder.Note')}</span>
                <span className="right">{_.get(cutTicket, "note", "")}</span>
              </div>
            </div>
          </li>

        </ul>

        <ul className="center bd-solid-top">
          <li className="not-padding-img padding-img">
            <div className="wrap-table">
              <table>
                <tbody>
                <tr>
                  <th className="w-70">{I18n.t('PageOrder.Store')}</th>
                  <th className="w-220">{textTrim(_.get(cutTicket, 'store.name', ''), 20)}</th>
                  <th><a className="text-decoration color-link">{_.get(cutTicket, 'store.packingStandard.name', '')}</a></th>
                </tr>
                {_.get(cutTicket, 'colors', []).map((c, idx) => (
                  <tr key={idx}>
                    <td></td>
                    <td><span
                      className="color-number w-40">{c.sizes.reduce((acc, s) => acc + (parseInt(s.quantity) || 0), 0)}</span><span
                      className="medium">{textTrim(c.name, 15)}</span></td>
                    <td>
                      {c.sizes.map((s, sidx) => (
                        <div key={sidx} className="itemSize float-left w-70"><span
                          className="bold">{s.name}</span> : <span className="regular">{s.quantity}</span></div>
                      ))}
                    </td>
                  </tr>
                ))}
                </tbody>
              </table>
            </div>
          </li>

        </ul>

      </div>  
    );
  }
}

CutTicket.propTypes = {
  cutTicket: PropTypes.object
};



