import {
  getListOrderDAL,
  addOrderDAL,
  editOrderDAL,
  getOrderDAL,
  deleteOrderDAL,
  sendNotifyDAL,
  getListCustomerDAL,
  getListContractorDAL,
  getListOrderForReportDAL,
} from "../api/orderApi";
import _ from "lodash";

class orderServices {
  constructor() {}

  getListOrder(options = {}) {
    options.populate =
      (options.populate ? `${options.populate},` : "") + "customer";
    options.sort = options.sort || { createdAt: -1 };
    _.set(options, "sort", JSON.stringify(options.sort));

    return getListOrderDAL(options);
  }

  getListOrderForReport(options = {}) {
    options.populate =
      (options.populate ? `${options.populate},` : "") + "customer";
    options.sort = options.sort || { createdAt: -1 };
    _.set(options, "sort", JSON.stringify(options.sort));

    return getListOrderForReportDAL(options);
  }

  getDetail(id, options = {}) {
    options.populate =
      (options.populate ? `${options.populate},` : "") + "customer";
    return getOrderDAL(id, options);
  }

  sendNotify(id) {
    return sendNotifyDAL(id);
  }

  deleteOrder(id) {
    return deleteOrderDAL(id);
  }

  editOrder(id, data) {
    return editOrderDAL(id, data);
  }

  addOrder(data) {
    return addOrderDAL(data);
  }

  getListCustomer() {
    return getListCustomerDAL();
  }

  getListContractor(id) {
    return getListContractorDAL(id);
  }
}

export default new orderServices();
