const C = "CREATE_INVOICE_IE_US_DELIVERY";

export default {

    // DATA
    CHANGE_LIST_COMPANY: `${C}_CHANGE_LIST_COMPANY`,
    CHANGE_LIST_BILL: `${C}_CHANGE_LIST_BILL`,
    //INVOICE INFO
    CHANGE_SELECTED_COMPANY: `${C}_CHANGE_SELECTED_COMPANY`,
    CHANGE_INVOICE_NO: `${C}_CHANGE_INVOICE_NO`,
    CHANGE_INVOICE_DATE: `${C}_CHANGE_ISSUED_DATE`,
    CHANGE_NOTE: `${C}_CHANGE_NOTE`,
    CHANGE_DUE_DATE_VALUE: `${C}_CHANGE_DUE_DATE_VALUE`,

    //LIST BILL
    CHANGE_CHECKED_LIST: `${C}_CHANGE_CHECKED_LIST`,
    RESET_CHECKED_LIST: `${C}_RESET_CHECKED_LIST`,
    //Each Bill
    CHANGE_BILL_DETAIL_VALUE: `${C}_CHANGE_BILL_DETAIL_VALUE`,
    COPY_DATA_TO_TEMP: `${C}_COPY_DATA_TO_TEMP`,
    CHANGE_CONTAINER_DETAIL_VALUE: `${C}_CHANGE_CONTAINER_DETAIL_VALUE`,
    SUBMIT_EDIT_DATA: `${C}_SUBMIT_EDIT_DATA`,
    RESET_TEMP_DATA: `${C}_RESET_TEMP_DATA`,
    //AFTER ALL EDIT
    CREATE_INVOICE_SUCCESS: `${C}_CREATE_INVOICE_SUCCESS`,
    RESET_DATA: `${C}_RESET_DATA`,
    ACTIVE_SCREEN_STATUS: `${C}_ACTIVE_SCREEN_STATUS`,
    CHANGE_TOGGLE_ACTIVE: `${C}_CHANGE_TOGGLE_ACTIVE`,
    CHANGE_SEARCH_TEXT: `${C}_CHANGE_SEARCH_TEXT`,

}