import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BaseComponent from '../../base/BaseComponent';
import stockInActions from '../../../actions/stockInActions';
import contractorActions from '../../../actions/contractorActions';
import appActions from '../../../actions/appActions';
const DateTimeField = require('react-datetime');
import AddMaterial from '../modal/AddMaterial';
import _ from 'lodash';
import moment from 'moment';
import I18n from 'i18n-js';
import TextNumberInput from "../../common/fields/TextNumberInput";
import {validateQuantityAndUsableOfMaterial} from "../../../functions/helpers";
import {toastr} from "react-redux-toastr";
import { withRouter } from 'react-router-dom';
import * as PATH from '../../../constants/routeConstants';

import icXIcon from '../../../stylesheets/images/ic-x.svg';
import icLineDbIcon from '../../../stylesheets/images/ic-line-db.svg';
import icLineNewRightIcon from '../../../stylesheets/images/ic-line-new-right.png';

class StockInContractorEdit extends BaseComponent {
  constructor (props, context) {
    super(props, context);

    this.state = {
      item: {},
      saved: true,
      materials: []
    };
  }


  componentWillMount () {
    let stockIn = _.get(this.props.stockInState, 'contractor.detail', {});
    this.setState({
      item: _.cloneDeep(stockIn),
      saved: stockIn.status != 'Approved',
      materials: _.cloneDeep(stockIn.materials || [])
    });
    stockIn._id && this.props.stockInPropsActions.getDetail('contractor', stockIn._id, {populate: 'order,style,contractor,materials.material'});
  }

  componentWillReceiveProps (newProps) {
    let {item} = this.state;
    let stockIn = _.get(newProps.stockInState, 'contractor.detail', {});
    !_.isEqual(item, stockIn) && this.setState({
      item: _.cloneDeep(stockIn),
      saved: stockIn.status != 'Approved',
      materials: _.cloneDeep(stockIn.materials || [])
    });
    if(_.isEmpty(stockIn)) {
      this.props.history.push(PATH.WAREHOUSE_INBOUND_CONTRACTOR_URL)
    }
  }

  componentWillUpdate (nextProps, nextState) {
    if (_.get(this.props.stockInState, 'contractor.reload') != _.get(nextProps.stockInState, 'contractor.reload')) {
     this.props.appPropsActions.setDataPageCurrent({context: {}, isEdit: false});
    }
  }

  onChangeMaterials (id, property, value) {
    let {materials} = this.state;
    materials.forEach(m => {
      if (m._id === id) {
        m[property] = value;
      }
    });

    this.setState({materials});
  }

  onChangeStatus (check) {
    let {item} = this.state;
    item.status = check ? 'Approved' :'Unreserved'
    this.setState({item});
  }
  
  onAddMaterial(materialData) {
    var d = new Date();
    materialData._id = d.getTime();
    materialData.receivedDate = d;
    this.setState({ materials: [...this.state.materials, materialData] });
  }

  deleteMaterial (id) {
    let {materials} = this.state;
    materials = materials.filter(i => i._id != id);
    this.setState({materials});
  }

  save () {
    let {materials, item} = this.state;
    materials = materials.map(i => {
        if(!isNaN(i._id)) i._id = undefined;
        return i;
    })

    const error = validateQuantityAndUsableOfMaterial(materials || []);
    if(error) {
      toastr.info(I18n.t('Notify.Info'), error.msg);
      return;
    }

    const forMail = { 
      contractor: _.get(item, 'contractor.name', ''), 
      customerPO: _.get(item, 'order.customerPO', ''), 
      style: _.get(item, 'style.style', ''),
    }
    item._id && this.props.stockInPropsActions.editItem('contractor', item._id, {...item, ...forMail, materials});
    this.props.history.push(PATH.WAREHOUSE_INBOUND_CONTRACTOR_URL);
  }

  addMaterial () {
    const {openModal, closeModal} = this.props.modal;
    openModal((<AddMaterial closeModal={closeModal} onSave={(dataForm) => this.onAddMaterial(dataForm)} />))
  }

  render () {
    let {item, materials, saved} = this.state;
    let w100 = {
      width: '100%'
    };
    return (
      <div id="content">
        <div className="wrap-filter-title w-full pd-5 inline-block ">
          <div className="bg-white w-full inline-block pd-10">

            <div className="float-left h-40 line-h-40">
              <p className="size-12 normal">{I18n.t('PageWareHouse.Contractor')} <span className="color-orange medium mg-left-50 size-16">{item.contractor ? item.contractor.name || '' : ''}</span></p>
            </div>

            {saved && (
              <div className="float-right add-filter-top ">
                  <span className="cursor-pointer global-add" onClick={() => this.save()}><i className="fa fa-floppy-o" aria-hidden="true" /> Save</span>
              </div>
            )}

            <div className="style-checkbox float-right mg-10">
              <input checked={item.status == 'Approved'} disabled={!saved} type="checkbox" id="approvedChxId" onChange={evt => this.onChangeStatus(evt.target.checked)} />
              <label htmlFor="approvedChxId"> <span className="mg-right-10"><span></span></span>{I18n.t('PageWareHouse.Approve')}</label>
            </div>

          </div>
        </div>
        {(materials == null ||  materials.length == 0) && (
          <div className="there_is_no_data">
            <div className="center">
              <span className="line-click"><img src={icLineDbIcon} alt=""/></span>
              <span className="text">There is no data!</span>
            </div>
            <div className="right">
              <span className="line-click"><img src={icLineNewRightIcon} alt=""/></span>
            </div>
        </div>
        )}
        {(materials &&  materials.length > 0) && (
        <div className="wrap-global-table">
          <table style={w100} className="global-table data-time-table warehouse">
            <tbody className="body-data-time">
              <tr>
                {/* <th /> */}
                <th className="center w-225">{I18n.t('PageWareHouse.ReceivedDate')}</th>
                <th className="center">{I18n.t('PageWareHouse.Material')}</th>
                <th className="center">{I18n.t('PageWareHouse.Content')}</th>
                <th className="center">{I18n.t('PageWareHouse.Color')}</th>
                <th className="center">{I18n.t('PageWareHouse.Size')}</th>
                <th className="center">{I18n.t('PageWareHouse.Quantity')}</th>
                <th className="center">{I18n.t('PageWareHouse.Usable')}</th>
                <th className="center">{I18n.t('PageWareHouse.Damaged')}</th>
                <th className="center">{I18n.t('PageWareHouse.Unit')}</th>
                <th className="center">{I18n.t('PageWareHouse.Roll')}</th>
                {saved && <th className="center">{I18n.t('PageWareHouse.Actions')}</th>}
              </tr>

              {(materials || []).map((i, idx) => (
                <tr key={idx}>
                  {/* <td className="center style-checkbox">
                    <input type="checkbox" id={`check` + idx} checked={!!i.checked} onChange={evt => this.onChangeMaterials(i._id, 'checked', !!evt.target.checked)} value /><label htmlFor={`check` + idx}><span><span /></span></label>
                  </td> */}
                  <td className="center date-pick date-time-td">
                    {saved ? (i.receivedDate ? <DateTimeField timeFormat={false} closeOnSelect dateFormat="MMM DD, YYYY"
                      value={moment(i.receivedDate || {})}
                      onChange={date => this.onChangeMaterials(i._id, 'receivedDate', date.format())} />
                    : <DateTimeField timeFormat={false} closeOnSelect dateFormat="MMM DD, YYYY"
                      onChange={date => this.onChangeMaterials(i._id, 'receivedDate', date.format())} />) : moment(i.receivedDate || {}).format('MMM DD, YYYY') 
                  }
                  </td>
                  <td className="center">{_.get(i, 'material.name', '')}</td>
                  <td className="center">{_.get(i, 'material.data.content', '')}</td>
                  <td className="center">{i.color || ''}</td>
                  <td className="center">{i.size || ''}</td>
                  <td className="center w-100">
                    {saved ? <TextNumberInput value={i.quantity || 0} onChange={val => this.onChangeMaterials(i._id, 'quantity', val)} className="form-control center" /> : i.quantity || 0}
                  </td>
                  <td className="center w-100">
                    {saved ? <TextNumberInput value={i.usable || 0} onChange={val => this.onChangeMaterials(i._id, 'usable', val)} className="form-control center" /> : i.usable || 0}
                  </td>
                  <td className="center">{(i.quantity - i.usable) ? (i.quantity - i.usable).toFixed(2) : 0}</td>
                  <td className="center">{_.get(i, 'material.data.unit', '')}</td>
                  <td className="center w-100">
                    {saved ? <input type="text" value={i.roll || ''} onChange={evt => this.onChangeMaterials(i._id, 'roll', evt.target.value)} className="form-control center" /> : i.roll}
                  </td>
                  {saved && <td className="actions center">
                    <span key={idx} className="cursor-pointer" onClick={() => this.deleteMaterial(i._id)}><img src={icXIcon} alt="" /></span>
                  </td>}
                </tr>
              ))}

            </tbody>
          </table>
        </div>
        )}

      </div>
    );
  }
}

function mapStateToProps (state, ownProps) {
  return {
    authState: state.authState,
    stockInState: state.stockInState,
    contractorState: state.contractorState
  };
}

function mapDispatchToProps (dispatch) {
  return {
    appPropsActions: bindActionCreators(appActions, dispatch),
    stockInPropsActions: bindActionCreators(stockInActions, dispatch),
    contractorActions: bindActionCreators(contractorActions, dispatch)
  };
}

StockInContractorEdit.propTypes = {
  authState: PropTypes.object.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(StockInContractorEdit));
