import * as types from '../constants/actionTypes';
import { showLoadingAuth, hideLoadingAuth, showLoading, hideLoading } from './commonUseActions';
import authServices from '../services/authServices';

function signIn({ email, password, rememberMe }) {
  return (dispatch) => {
    showLoadingAuth(types, dispatch);

    authServices.signIn({ email, password, rememberMe }).then(res => {
      hideLoadingAuth(types, dispatch, res.error);
      if (res && !res.error) {
        dispatch({
          type: types.AUTH.SET_USER,
          data: res.data.user
        });
      }
    }, (error) => {
      hideLoadingAuth(types, dispatch, error.error);
    });
  };
}

// Get current user
function getUser() {
  return (dispatch) => {
    showLoadingAuth(types, dispatch);

    authServices.getUser().then((res) => {
      hideLoadingAuth(types, dispatch, res.error);
      if (res && !res.error) {
        dispatch({
          type: types.AUTH.SET_USER,
          data: res.data
        });
      }
    }, (error) => {
      hideLoadingAuth(types, dispatch, error);
    });
  };
}

// Edit User
function profileEdit(id, data) {
  return (dispatch) => {
    showLoading(types, dispatch);
      authServices.profileEdit(id, data).then((res) => {
        hideLoading(types, dispatch, res.error);
        if (res && !res.error) {
          dispatch({
            type: types.AUTH.EDIT_USER,
            data: res.data
          });
        }
      }, (error) => {
        hideLoading(types, dispatch, error);
      });
  };
}

function setLanguage(language) {
  return (dispatch) => {
    dispatch({
      type: types.AUTH.SET_LANGUAGE,
      data: language
    });
  };
}

const changePassword = (body) => (dispatch) => {
  showLoading(types, dispatch);
  return authServices.changePassword(body).then(res => {
    hideLoading(types, dispatch);
    return {success: true};
  }, (error) => {
    hideLoading(types, dispatch);
    return {success: false, error};
  });
}

const forgotPassword = (body) => (dispatch) => {
  showLoading(types, dispatch);
  return authServices.forgotPassword(body).then(res => {
    hideLoading(types, dispatch);
    return {success: true};
  }, (error) => {
    hideLoading(types, dispatch);
    return {success: false, error};
  });
}

const forgotPasswordConfirm = (body) => (dispatch) => {
  showLoading(types, dispatch);
  return authServices.forgotPasswordConfirm(body).then(res => {
    hideLoading(types, dispatch);
    return {success: true};
  }, (error) => {
    hideLoading(types, dispatch);
    return {success: false, error};
  });
}


function signOut() {
  return (dispatch) => {
    dispatch({
      type: types.AUTH.SET_USER,
      data: null
    });

    authServices.signOut();
  };
}

export default {
  signIn,
  getUser,
  profileEdit,
  setLanguage,
  signOut,
  changePassword,
  forgotPassword,
  forgotPasswordConfirm
};
