import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import propertyActions from '../../../actions/propertyActions';
import I18n from 'i18n-js';

import icAddIcon from '../../../stylesheets/images/ic-add.svg';
import icXGrayIcon from '../../../stylesheets/images/ic-x-gray.svg';

class PropertyAdd extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      item: {},
      color: [],
      value: null,
      errors: {},
    };
  }

  onChange(property, value) {
    let { item } = this.state;
    this.setState({ item: _.set(item, property, value) });
  }

  addColor() {
    let valuenull = this.state.value;
    let color = _.compact(_.concat(this.state.color));
    let value = _.isUndefined(this.state.item.value) ? "" : this.state.item.value;
    color.push(value);
    this.setState({ color, value: null });
    this.refs.property.value = "";
  }

  deleteColor(id) {
    let color = _.uniq(this.state.color);
    let data = _.pullAt(color, id);
    this.setState({ color });
  }

  close() {
    this.props.closeModal();
  }

  validate () {
    let {item} = this.state;
    let fields = ['name'];
    let errors = {};
    fields.forEach(f => {
      _.set(errors, f, !item[f] ? `Please enter ${f}` : '');
    });
    if (!errors.name) {
      _.set(errors, 'name', (this.props.validateExistPropertyName && this.props.validateExistPropertyName(item)) ? I18n.t('ErrorMessages.PropertyNameAlreadyExist') : '');
    }
    this.setState({errors});
    let flag = Object.keys(errors).every(k => !errors[k]);
    return flag;
  }


  save() {
    let { item } = this.state;
    let color = _.uniq(_.compact(this.state.color));
    let data = {
      "name": this.state.item.name,
      "values": _.isEmpty(color) ? [this.state.item.value] : color,
    }
    if (this.validate()) {
    this.props.propertyPropsActions.addProperty(data);
    }
  }

  render() {
    let data = this.props.data || {};
    let { color, item, value, errors } = this.state;
    return (
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <form>
            <div className="modal-header">
              <a className="close" onClick={() => this.close()}><span aria-hidden="true">&times;</span></a>
              <h4 className="modal-title" id="myModalLabel">{I18n.t('PageStandard.ADDPROPERTY')}</h4>
            </div>

            <div className="modal-body">

              <div className="form-group">
                <div className="col-md-4 col-xs-12 text">
                  <p>{I18n.t('PageStandard.Name')}</p>
                </div>
                <div className="col-md-8 col-xs-12 value">
                  <input type="text" onChange={evt => this.onChange('name', evt.target.value)} className="form-control" />
                  {errors['name'] && <div className="alert-warning">{errors['name']}</div>}
                </div>
              </div>

              <div className="form-group">
                <div className="col-md-4 col-xs-12 text">
                  <p>{I18n.t('PageStandard.PropertyValues')}</p>
                </div>
                <div className="col-md-8 col-xs-12 value relative">
                  <input type="text" value={this.state.value} ref="property" onChange={evt => this.onChange('value', evt.target.value)} className="form-control" onKeyPress={event => {
                    if (event.key === 'Enter') {
                      this.addColor();
                    }
                  }} />
                  <img src={icAddIcon} className="icon-add-property-value" onClick={() => { this.addColor(); }} />
                </div>
              </div>
              <ul className="item-property-value">
                {(_.uniq(color) || []).map((item, idx) => (
                  <li key={idx}><span>{item} <img onClick={() => { this.deleteColor(idx); }} className="cursor-pointer" src={icXGrayIcon} /></span> </li>
                ))}
              </ul>
            </div>

            <div className="modal-footer">
              <button type="button" className="bt-black" onClick={() => this.close()}>{I18n.t('Btn.CANCEL')}</button>
              <button type="button" className="bt-orange mg-l-5" onClick={() => this.save()}>{I18n.t('Btn.SAVE')}</button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    appState: state.appState,
    authState: state.authState,
    propertyState: state.propertyState
  };
}

function mapDispatchToProps(dispatch) {
  return {
    propertyPropsActions: bindActionCreators(propertyActions, dispatch)
  };
}

PropertyAdd.propTypes = {
  authState: PropTypes.object.isRequired,
  validateExistPropertyName: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(PropertyAdd);
