import {getListItemDAL, addItemDAL, editItemDAL, getItemDAL, deleteItemDAL, getListOrderDAL, getOrdersByContractorDAL} from '../api/assignmentApi';
import _ from 'lodash';

class assignmentServices {

  constructor() {
  }

  getOrdersByContractor(id, options = {}) {
    options.query && _.set(options, 'query', JSON.stringify(options['query']));
    options.sort && _.set(options, 'sort', JSON.stringify(options['sort']));

    return getOrdersByContractorDAL(id, options).then((res) => {
      return res;
    });
  }

  getListOrder(options = {}) {
    options.query && _.set(options, 'query', JSON.stringify(options['query']));
    options.sort && _.set(options, 'sort', JSON.stringify(options['sort']));

    return getListOrderDAL(options).then((res) => {
      return res;
    });
  }

  getListItem (options = {}) {
    options.query && _.set(options, 'query', JSON.stringify(options['query']));
    options.sort && _.set(options, 'sort', JSON.stringify(options['sort']));

    return getListItemDAL(options).then((res) => {
      return res;
    });
  }

  getDetail (id, reset = true) {
    return getItemDAL(id, {}).then((res) => {
      return res;
    });
  }

  deleteItem (id) {

    return deleteItemDAL(id).then((res) => {
      return res;
    });
  }

  editItem (id, data) {
    return editItemDAL(id, data).then((res) => {
      return res;
    });
  }

  addItem (data) {
    if (data && data.styles) {
      data.styles = data.styles.map(style => {
        return {
          style: style.style._id,
          cutTickets: style.cutTickets.map(cutTicket => cutTicket._id),
          total: style.cutTickets.reduce((total, cutTicket) => {return total += parseInt(cutTicket.gTotal || 0)}, 0),
          styleName: style.styleName
        }
      })
    }
    return addItemDAL(data).then((res) => {
      return res;
    });
  }
}

export default new assignmentServices();
