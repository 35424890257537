import C from '../../../constants/accounting/payable/editInvoiceIEUSDelivery';
import {combineReducers} from 'redux';
import _ from 'lodash';
const invoiceDetail = (state = {}, action) => {
    const {type, payload} = action;
    let dic = _.cloneDeep(state);
    switch(type) {
        case C.CHANGE_INVOICE_DETAIL:
            return {...payload, receipt: {isEdit: false}};
        case C.CHANGE_NAME_VALUE:
            dic.invoiceDetail.company = payload;
            return dic;
        case C.CHANGE_INVOICE_NO_VALUE:
            dic.invNo = payload;
            return dic;
        case C.CHANGE_INVOICE_DATE_VALUE:
            dic.invoiceDate = payload;
            return dic;
        case C.CHANGE_NOTE_VALUE:
            dic.note = payload;
            return dic;
        case C.CHANGE_PAY_AMOUNT_VALUE:
            dic['payAmount'] = payload;
            return dic;
        case C.CHANGE_BILL_DETAIL_VALUE: {
            const {_id, key, value} = payload;
            dic = _.cloneDeep(state);
            _.map(dic.invoiceDetail.bills, item => item._id === _id ? item[key] = value : null)
            return dic;
        }
        case C.SUBMIT_EDIT_DATA: {
            const {data} = payload;
            dic = _.cloneDeep(state);
            _.map(dic.invoiceDetail.bills, item => item._id === data.billId && _.map(item.containers, (it, index) => index === data.contIndex && _.assign(it, data)))
            return dic;
        }
        case C.SUBMIT_FILES:
            dic = _.cloneDeep(state);
            dic.files = payload;
            return dic;
        case C.SUBMIT_RECEIPT:
            dic = _.cloneDeep(state);
            dic.receipt = {...payload, isEdit: true};
            return dic;
        case C.RESET_ALL_DATA:
            return []
        case C.CHANGE_DUE_DATE_VALUE: {
            dic.dueDate = payload;
            return dic;
        }
        default:
            return state;
    }
}

const activeListBills = (state = [], action) => {
    const {type, payload} = action;
    switch(type) {
        case C.CHANGE_INVOICE_DETAIL:
            // Active first bill after reload data
            const {bills} = payload.invoiceDetail;
            return bills ? [bills[0]._id] : [];
            // return []
        case C.CHANGE_TOGGLE_ACTIVE: {
            const { id, value } = payload;
            let filtered = _.cloneDeep(state).filter(it => it !== id);
            if (value) filtered = [...filtered, id];
            return filtered;
        }
        case C.RESET_DATA_ALL:
            return [];
        default:
            return state;
    }
}

const company = (state = '', action) => {
    const {type, payload} = action;
    switch(type) {
        case C.CHANGE_COMPANY_VALUE:
            return payload;
        default:
            return state;
    }
}

const paymentType = (state = 'cash', action) => {
    const {type, payload} = action;
    switch(type) {
        case C.CHANGE_PAYMENT_TYPE:
            return payload;
        default:
            return state;
    }
}

const dataOfTemp = (state = {}, action) => {
    const {type, payload} = action;
    switch(type) {
        case C.COPY_DATA_TO_TEMP:
            return payload;
        case C.CHANGE_CONTAINER_DETAIL_VALUE:
            const {key, value} = payload;
            let dic = _.cloneDeep(state);
            dic[key] = value;
            return dic
          case C.RESET_TEMP_DATA:
            return {}
          case C.RESET_ALL_DATA:
            return {}
        default:
            return state;
    }
}

const transferFiles = (state = [], action) => {
    const { type, payload } = action;
    switch (type) {
        case C.COPY_FILES_TO_RAW_DATA:
            return payload;
        case C.ADD_TRANSFER_FILE: {
            const stateClone = _.cloneDeep(state);
            stateClone.push(payload);
            return stateClone;
        }
        case C.REMOVE_TRANSFER_FILE:
            return state.filter(file => file.id !== payload);
        case C.EMPTY_FILES_IN_RAW_DATA:
            return [];
        case C.RESET_DATA_ALL:
            return [];
        default:
            return state;
    }
  }

const receipt = (state = {}, actions) => {
      const {type, payload} = actions;
      switch(type) {
            case C.CREATE_RECEIPT:
                return payload;
            case C.CHANGE_RECEIPT_VALUE:
                const {key, value} = payload;
                let dic = _.cloneDeep(state);
                dic[key] = value;
                return dic;
            case C.EMPTY_RECEIPT:
                return {}
            default:
                return state;
      }
  }

const payAmount = (state = 0, action) => {
      const {type, payload} = action;
      switch(type) {
        case C.CHANGE_PAY_AMOUNT_VALUE:
            return payload;
        case C.RESET_DATA_ALL:
            return 0;
        default:
            return state;
      }
  }

const success = (state = false, action) => {
    const {type, payload} = action;
    switch(type) {
          case C.SAVE_SUCCESS:
              return payload;
          case C.RESET_DATA_ALL:
              return false;
          default:
              return state;
    }
}

const activeScreen = (state = false, action) => {
    const {type, payload} = action;
    switch(type) {
        case C.ACTIVE_SCREEN_STATUS:
            return payload;
        default:
            return state;
    }
}

export const editInvoiceImpExpUSDelivery = combineReducers({
    invoiceDetail,
    company,
    paymentType,
    dataOfTemp,
    transferFiles,
    payAmount,
    receipt,
    success,
    activeScreen,
    activeListBills
})