
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import * as R from 'ramda';
import I18n from 'i18n-js';

import EditPackingStandard from '../../../components/standard/packing/EditPackingStandard';

import * as selectors from '../../../../selectors/standard/packing/editPackingStandard';
import * as actions from '../../../../actions/standard/packing/editPackingStandard';

class View extends Component {
  componentWillMount() {
    const id = R.path(['match', 'params', 'id'], this.props);
    this.props.loadData(id);
  }

  componentWillUnmount() {
    this.props.resetData();
  }

  render() {
    return (
      <EditPackingStandard
        {...this.props}
        onSubmit={() => this.props.submit(this.props.id, this.props.fnBody())}
      />
    )
  }
}

const mapStateToProps = state => ({
  id: selectors.getId(state),
  name: selectors.getName(state),
  description: selectors.getDescription(state),
  checkablePackingTypes: selectors.getCheckableTypes(state),
  trims: selectors.getTrims(state),

  foldingWays: selectors.getFoldingWays(state),
  hangTags: selectors.getHangTags(state),
  shippingMarks: selectors.getShippingMarks(state),

  fnBody: selectors.fnGenerateBody(state),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  loadData(id) {
    if (id) {
      dispatch(actions.fetchPackingStandard(id));
      dispatch(actions.fetchSelectableMaterials());
    }
  },

  resetData() {
    dispatch(actions.resetData());
  },

  onChangeName(value) {
    dispatch(actions.changeName(value));
  },

  onChangePackingType(item) {
    dispatch(actions.changeType(R.path(['value'], item)));
  },

  onChangeDescription(value) {
    dispatch(actions.changeDescription(value));
  },

  onChangeTrimMaterial(trim, material) {
    dispatch(actions.changeTrimMaterial(R.path(['key'], trim), R.path(['value'], material)));
  },

  onChangeTrimColor(trim, color) {
    dispatch(actions.changeTrimColor(R.path(['key'], trim), R.path(['value'], color)));
  },

  onChangeTrimSize(trim, size) {
    dispatch(actions.changeTrimSize(R.path(['key'], trim), R.path(['value'], size)));
  },

  onAddTrim() {
    dispatch(actions.addTrim());
  },
  
  onRemoveTrim(trim) {
    dispatch(actions.removeTrim(R.path(['key'], trim)));
  },

  onChangeFoldingWayDescription(item, value) {
    dispatch(actions.changeFoldingWayDescription(R.path(['key'], item), value));
  },

  onAddFoldingWayImage(item, file) {
    dispatch(actions.changeFoldingWayPhoto(R.path(['key'], item), file));
  },

  onRemoveFoldingWayImage(item) {
    dispatch(actions.removeFoldingWayPhoto(R.path(['key'], item)));
  },

  onAddFoldingWay() {
    dispatch(actions.addFoldingWay());
  },

  onRemoveFoldingWay(item) {
    dispatch(actions.removeFoldingWay(R.path(['key'], item)));
  },

  onChangeHangTagDescription(item, value) {
    dispatch(actions.changeHangTagDescription(R.path(['key'], item), value));
  },

  onAddHangTagImage(item, file) {
    dispatch(actions.changeHangTagPhoto(R.path(['key'], item), file));
  },

  onRemoveHangTagImage(item) {
    dispatch(actions.removeHangTagPhoto(R.path(['key'], item)));
  },

  onAddHangTag() {
    dispatch(actions.addHangTag());
  },

  onRemoveHangTag(item) {
    dispatch(actions.removeHangTag(R.path(['key'], item)));
  },

  onChangeShippingMarkDescription(item, value) {
    dispatch(actions.changeShippingMarkDescription(R.path(['key'], item), value));
  },

  onAddShippingMarkImage(item, file) {
    dispatch(actions.changeShippingMarkPhoto(R.path(['key'], item), file));
  },

  onRemoveShippingMarkImage(item) {
    dispatch(actions.removeShippingMarkPhoto(R.path(['key'], item)));
  },

  onAddShippingMark() {
    dispatch(actions.addShippingMark());
  },

  onRemoveShippingMark(item) {
    dispatch(actions.removeShippingMark(R.path(['key'], item)));
  },

  submit(id, body) {
    dispatch(actions.submit(id, body));
  },

});

export default connect(mapStateToProps, mapDispatchToProps) (withRouter(View));
