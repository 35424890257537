import { combineReducers } from 'redux';
import C from '../../../constants/accounting/payable/createInvoiceImpExpUSDelivery';
import _ from 'lodash';
import moment from 'moment';
/**
 * Store helper information to check whether selectable import export companies list is fetching...
 */
const fetchingSelectableImpExpCompanies = (state = false, action) => {
  const { payload, type } = action;
  switch (type) {
    // TODO. handle related actions
    default:
      return state;
  }
}

/**
 * Store a list of selectable import export companies
 */
const selectableImpExpCompanies = (state = [], action) => {
  const { payload, type } = action;
  switch (type) {
    case C.CHANGE_LIST_COMPANY:
      return payload;
    case C.RESET_DATA:
      return []
    default:
      return state;
  }
}

/**
 * Store a selected import export company
 */
const selectedImpExpCompany = (state = '', action) => {
  const { payload, type } = action;
  switch (type) {
    case C.CHANGE_SELECTED_COMPANY:
      return payload;
    case C.RESET_DATA:
      return ''
    default:
      return state;
  }
}

/**
 * Store input information for invoice no field
 */
const invoiceNo = (state = '', action) => {
  const { payload, type } = action;
  switch (type) {
    case C.CHANGE_INVOICE_NO:
      return payload;
    case C.RESET_DATA:
      return ''
    default:
      return state;
  }
}

/**
 * Store input information for invoice date field
 */
const invoiceDate = (state = moment().format(), action) => {
  const { payload, type } = action;
  switch (type) {
    case C.CHANGE_INVOICE_DATE:
      return payload;
    case C.RESET_DATA:
      return ''
    default:
      return state;
  }
}

/**
 * Store input information for due date field
 */
const dueDate = (state = moment().format(), action) => {
  const { payload, type } = action;
  switch (type) {
    case C.CHANGE_DUE_DATE_VALUE:
      return payload;
    case C.RESET_DATA:
      return ''
    default:
      return state;
  }
}

/**
 * Store input information for note field
 */
const note = (state = '', action) => {
  const { payload, type } = action;
  switch (type) {
    case C.CHANGE_NOTE:
      return payload;
      case C.RESET_DATA:
      return ''
    default:
      return state;
  }
}

/**
 * Store helper information to check whether list of purchase orders is fetching from api
 */
const fetchingUSDeliveries = (state = false, action) => {
  const { payload, type } = action;
  switch (type) {
    // TODO. handle related actions
    default:
      return state;
  }
}

/**
 * Store a list of purchase orders that will be used to check in side menu
 */
const USDeliveries = (state = [], action) => {
  const { payload, type } = action;
  let dic = _.cloneDeep(state);
  switch (type) {
    case C.CHANGE_LIST_BILL:
    return payload.map(item => ({ ...item, active: true }));
    case C.CHANGE_SEARCH_TEXT: {
        let copyState = _.cloneDeep(state);
        if (!payload) {
            copyState = _.map(copyState, (el) => { el.hide = false; return el });
            return copyState;
        }
        const rex = new RegExp(`.*${payload}.*`)
        copyState = _.map(copyState, (el) => {
            let rs = rex.test(_.lowerCase(_.get(el, 'billNo', '')));
            el.hide = !rs;
            return el;
        })
        return copyState;
    }
    case C.CHANGE_BILL_DETAIL_VALUE:
      const {_id, key, value} = payload;
      _.map(dic, item => item._id === _id ? item[key] = value : null)
      return dic;
    case C.SUBMIT_EDIT_DATA:
      const {data} = payload;
      _.map(dic, item => item._id === data.billId && _.map(item.containers, (it, index) => index === data.contIndex && _.assign(it, data)))
      return dic;
    case C.CHANGE_TOGGLE_ACTIVE:
      const active = payload.value;
      let id = payload.id;
      dic.map(item => item._id === id ? item.active = active : null);
      return dic;
    case C.RESET_DATA:
      return []
    default:
      return state;
  }
}

/**
 * Store a list of checked purchase orders
 */
const checkedUSDeliveries = (state = [], action) => {
  const { payload, type } = action;
  switch (type) {
    case C.CHANGE_CHECKED_LIST:
      const { _id, checked } = payload;
      let filtered = _.cloneDeep(state).filter(it => it !== _id);
      if (checked) filtered = [...filtered, _id];
      return filtered;
    case C.RESET_CHECKED_LIST:
      return payload;
      case C.RESET_DATA:
      return []
    default:
      return state;
  }
}

/**
 * Store a list of active purchase orders
 */
const activeUSDeliveries = (state = [], action) => {
  const { payload, type } = action;
  switch (type) {
    // TODO. handle related actions
    default:
      return state;
  }
}

/**
 * Store a helper information to check whether informations is post to api for saving...
 */
const saving = (state = false, action) => {
  const { payload, type } = action;
  switch (type) {
    // TODO. handle related actions
    default:
      return state;
  }
}

/**
 * Store an editing container.
 */
const editingContainer = (state = null, action) => {
  const { payload, type } = action;
  switch (type) {
    // TODO. handle related actions
    default:
      return state;
  }
}

const dataOfTemp = (state = {}, action) => {
  const {type, payload} = action;
  switch(type) {
    case C.COPY_DATA_TO_TEMP:
      return payload;
    case C.CHANGE_CONTAINER_DETAIL_VALUE:
      const {key, value} = payload;
      let dic = _.cloneDeep(state);
      dic[key] = value;
      return dic
    case C.RESET_TEMP_DATA:
      return {}
    case C.RESET_DATA:
      return {}
    default:
      return state;
  }
}

const success = (state = false, action) => {
  const {type, payload} = action;
  switch(type) {
    case C.CREATE_INVOICE_SUCCESS:
      return payload;
    case C.RESET_DATA:
      return false
    default:
      return state
  }
}

const activeScreenPreview = (state = false, action) => {
  const {type, payload} = action;
  switch(type) {
    case C.ACTIVE_SCREEN_STATUS:
      return payload;
    default:
      return state;
  }
}

export const createPayableInvoiceImpExpUSDelivery = combineReducers({
  fetchingSelectableImpExpCompanies,
  selectableImpExpCompanies,
  selectedImpExpCompany,

  invoiceNo,
  invoiceDate,
  dueDate,
  note,

  fetchingUSDeliveries,
  USDeliveries,
  checkedUSDeliveries,
  activeUSDeliveries,

  saving,
  editingContainer,
  dataOfTemp,
  success,
  activeScreenPreview,
});
