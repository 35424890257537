import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {toastr} from 'react-redux-toastr'
import BaseComponent from '../../base/BaseComponent';
import appActions from '../../../actions/appActions';
import stockOutActions from '../../../actions/stockOutActions';
import { PURCHASE_STATUS } from "../../../constants/constants";
import * as permissions from "../../../selectors/permissions";
const DateTimeField = require('react-datetime');
import _ from 'lodash';
import moment from 'moment';
import I18n from 'i18n-js';

import icXIcon from '../../../stylesheets/images/ic-x.svg';

class StockOutEdit extends BaseComponent {
  constructor (props, context) {
    super(props, context);

    this.state = {
      stockOut: {},
      contractors: [], 
      status: ""
    };
  }

  componentWillMount = () => {
    let item = this.props.data;
    item._id && this.props.stockOutPropsActions.getDetail(item._id, {populate: 'contractorId,styleId,orderId,materials.material'});
  }

  componentWillReceiveProps = newProps => {
    let {contractors, stockOut} = this.state;
    let stockOutNew = _.cloneDeep(_.get(newProps.stockOutState, 'detail', {}));

    !_.isEqual(stockOutNew, stockOut) && this.setState({stockOut: stockOutNew, status: stockOutNew.status});
  }

  componentWillUpdate = (nextProps, nextState) => {
    if (_.get(this.props.stockOutState, 'reload') != _.get(nextProps.stockOutState, 'reload')) {
      _.isFunction(this.props.closeFormAdd) && this.props.closeFormAdd();
    }
  }

  onChangeMaterials = (id, property, value) => {
    let {stockOut} = this.state;
    
    _.set(stockOut.materials[idx] || {}, property, value);
    this.setState({stockOut});
  }

  deleteMaterial = id => {
    let {stockOut} = this.state;

    stockOut.materials = stockOut.materials.filter(i => i._id != id);
    this.setState({stockOut});
  }

  onChangeMaterials = (idx, property, value) => {
    let {stockOut} = this.state;
  
    _.set(stockOut.materials[idx] || {}, property, value);
    this.setState({stockOut});
  }

  save = () => {
    let {stockOut, status} = this.state;
    if(status == PURCHASE_STATUS.WAITING_FOR_APPROVAL && stockOut.status == PURCHASE_STATUS.NEW) {
      if(_.findIndex(stockOut.materials, i => i.actual > i.reserve) >= 0){
        toastr.error(I18n.t('Notify.Error'), I18n.t('ErrorMessages.ReserveActual'))
        return
      }
    }
    if((stockOut.materials || []).length == 0) {
      stockOut._id && this.props.stockOutPropsActions.deleteItem(stockOut._id);
    } else {
      stockOut.customerPO = _.get(stockOut, 'order.customerPO', '')
      stockOut.style = _.get(stockOut, 'style.style', '')
      stockOut.contractor = _.get(stockOut, 'contractor.name', '')
      for (const mt of stockOut.materials) {
        mt.deliveryDateFomat = new moment(mt.deliveryDate).format("DD/MM/YYYY")
      }
      stockOut._id && this.props.stockOutPropsActions.editItem(stockOut._id, stockOut).then(res => {
        if(res.statusCode == 555)
          toastr.error(I18n.t('Notify.Error'), I18n.t('ErrorMessages.ReserveActualServer'))
      })
    }
  }

  renderDate = (idx, fieldName, item) => {
    const onChange = date => {
      const {stockOut} = this.state
      stockOut.materials[idx][fieldName] = date.format()
      
      this.setState({stockOut})
    }
    return (
      <div>
        <DateTimeField onChange={onChange} timeFormat={false} dateFormat='MMM DD, YYYY' value={moment(item[fieldName])} />
      </div>
    )
  }

  onClickStatus = status => {
    let {stockOut} = this.state;
    stockOut.status = status
    this.setState({stockOut});
  }

  render = () => {
    let {stockOut, status} = this.state;
    let w100 = {
      width: '100%'
    };
    return (
      <div id="content">
        <div className="wrap-filter-title w-full pd-5 inline-block ">
          <div className="bg-white w-full inline-block pd-10">
            <div className="float-left list-filter">
              <div className="filter select">
                <p>{I18n.t('PageWareHouse.Contractor')}</p>
                <p className="color-orange">{_.get(stockOut, 'contractor.name', '')}</p>
              </div>
              <div className="filter select">
                <p>{I18n.t('PageWareHouse.CustomerPO')}</p>
                <p className="color-orange">{_.get(stockOut, 'order.customerPO', '')}</p>
              </div>
              <div className="filter select">
                <p>{I18n.t('PageWareHouse.Style')}</p>
                <p className="color-orange">{_.get(stockOut, 'style.style', '')}</p>
              </div>
            </div>
            <div className="float-right add-filter-top ">
              <span className="cursor-pointer global-add" onClick={() => this.save()}><i className="fa fa-floppy-o" aria-hidden="true" /> {I18n.t('Btn.Save')}</span>
            </div>
            {status == PURCHASE_STATUS.WAITING_FOR_APPROVAL && this.props.canApproveReject && (
              <div className="float-right add-filter-top pd-12">
                <ul className="waiting-for-approval-radio">
                  <li>
                    <input className="left-negative-3" type="radio" name="status" value="New" onClick={(e) => this.onClickStatus("New")} />
                    <label forHtml="s-option">{I18n.t("PageWareHouse.Approve")} </label>
                    <div className="check"><div className="inside"></div></div>
                  </li>

                  <li>
                    <input className="mg-left-10" type="radio" name="status" value="Rejected" onClick={(e) => this.onClickStatus("Rejected")} />
                    <label forHtml="s-option">{I18n.t("PageWareHouse.Reject")} </label>
                    <div className="check"><div className="inside"></div></div>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>

        <div className="wrap-global-table">
          <table style={w100} className="global-table data-time-table warehouse">
            <tbody>
              <tr>
                <th className="center">{I18n.t('PageWareHouse.DeliveryDate')}</th>
                <th className="center">{I18n.t('PageWareHouse.Material')}</th>
                <th className="center">{I18n.t('PageWareHouse.Content')}</th>
                <th className="center">{I18n.t('PageWareHouse.Color')}</th>
                <th className="center">{I18n.t('PageWareHouse.Size')}</th>
                <th className="center">{I18n.t('PageWareHouse.Needed')}</th>
                <th className="center">{I18n.t('PageWareHouse.Reserve')}</th>
                <th className="center">{I18n.t('PageWareHouse.Outbound')}</th>
                <th className="center">{I18n.t('PageWareHouse.Actual')}</th>
                <th className="center">{I18n.t('PageWareHouse.Unit')}</th>
                <th className="center">{I18n.t('PageWareHouse.Roll')}</th>
                <th className="center">{I18n.t('PageWareHouse.Actions')}</th>
              </tr>

              {(stockOut.materials || []).map((i, idx) => (
                <tr key={idx}>
                  <td className='center w-240 select date-time-td'>
                    {this.renderDate(idx, "deliveryDate", i)}
                  </td>
                  <td className="center">{_.get(i, 'material.name', '')}</td>
                  <td className="center">{_.get(i, 'material.data.content', '')}</td>
                  <td className="center">{i.color || ''}</td>
                  <td className="center">{i.size || ''}</td>
                  <td className="center">{i.quantity || 0}</td>
                  <td className="center">{i.reserve || 0}</td>
                  <td className="center">{i.outbound || 0}</td>
                  <td className="center w-90">{i.actual || 0}</td>
                  <td className="center">{_.get(i, 'material.data.unit', '')}</td>
                  <td className="center w-90">
                    <input type="number" value={i.roll || ''} min="0" onChange={evt => this.onChangeMaterials(idx, 'roll', evt.target.value)} className="form-control center" />
                  </td>
                  <td className="center">
                    {(stockOut.status != 'New') &&
                      <span key={idx} className="cursor-pointer" onClick={() => this.deleteMaterial(i._id)}><img src={icXIcon} alt="" /></span>
                    }
                  </td>
                </tr>
              ))}

            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

function mapStateToProps (state, ownProps) {
  return {
    authState: state.authState,
    stockOutState: state.stockOutState,
    canApproveReject: permissions.isSpecial({moduleKey: "warehouse", ...state})
  };
}

function mapDispatchToProps (dispatch) {
  return {
    appPropsActions: bindActionCreators(appActions, dispatch),
    stockOutPropsActions: bindActionCreators(stockOutActions, dispatch),
  };
}

StockOutEdit.propTypes = {
  authState: PropTypes.object.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(StockOutEdit);
