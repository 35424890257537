import Base from './base';

class SampleApproval extends Base {
  constructor () {
    super();

    this.getSampleApprovalDAL = this.getSampleApprovalDAL.bind(this);
    this.editSampleApprovalDAL = this.editSampleApprovalDAL.bind(this);
    this.updateSampleApprovalDAL = this.updateSampleApprovalDAL.bind(this);
    this.getListSampleApprovalDAL = this.getListSampleApprovalDAL.bind(this);
    this.getListCustomerPODAL = this.getListCustomerPODAL.bind(this);
    this.getExportCurrent = this.getExportCurrent.bind(this);
  }

  getSampleApprovalDAL (styleId, query) {
    return super.execute(this.Urls.sampleApproval.get.replace('{id}', styleId), this.ApiMethod.get, query);
  }

  editSampleApprovalDAL (styleId, body) {
    return super.execute(this.Urls.sampleApproval.update.replace('{id}', styleId), this.ApiMethod.put, body);
  }

  updateSampleApprovalDAL (styleId, status){
    return super.execute(this.Urls.sampleApproval.updateStatus.replace('{id}', styleId), this.ApiMethod.put, status);
  }

  getListSampleApprovalDAL (options){
    return super.execute(this.Urls.sampleApproval.getList, this.ApiMethod.get, options);
  }

  getListCustomerPODAL (options){
    return super.execute(this.Urls.sampleApproval.getListCustomer, this.ApiMethod.get, options);
  }

  getExportCurrent (orderId){
    const offset = - (new Date().getTimezoneOffset() / 60);
    return this.Urls.sampleApproval.exportCurrent +`/${orderId}?offset=${offset}`
  }
}

export default new SampleApproval();
