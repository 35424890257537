const C = 'CREATE_PAYABLE_INVOICE_CONTRACTOR';

export default {
    CHANGE_LIST_CONTRACTORS: `${C}_CHANGE_LIST_CONTRACTORS`,
    CHANGE_SELECTED_CONTRACTOR: `${C}_CHANGE_SELECTED_CONTRACTOR`,
    CHANGE_LIST_CREDIT_DEBITS: `${C}_CHANGE_LIST_CREDIT_DEBITS`,
    CHANGE_SELECTED_CREDIT_DEBIT: `${C}_CHANGE_SELECTED_CREDIT_DEBIT`,
    CHANGE_INVOICE_NO: `${C}_CHANGE_INVOICE_NO`,
    CHANGE_ISSUED_DATE: `${C}_CHANGE_ISSUED_DATE`,
    CHANGE_VAT_PER_MOUNTH: `${C}_CHANGE_VAT_PER_MOUNTH`,
    CHANGE_LIST_CUSTOMER_PO: `${C}_CHANGE_LIST_CUSTOMER_PO`,
    CHANGE_SELECTED_CUSTOMER_PO: `${C}_CHANGE_SELECTED_CUSTOMER_PO`,
    CHANGE_DUE_DATE: `${C}_CHANGE_DUE_DATE`,
    CHANGE_SELECTED_CURRENCY: `${C}_CHANGE_SELECTED_CURRENCY`,
    CHANGE_CONVERT_RATE: `${C}_CHANGE_CONVERT_RATE`,
    CHANGE_CONVERT_CURRENCY: `${C}_CHANGE_CONVERT_CURRENCY`,
    CHANGE_SEARCH_TEXT: `${C}_CHANGE_SEARCH_TEXT`,
    CHANGE_CUSTOMER_PO_PRICE: `${C}_CHANGE_CUSTOMER_PO_PRICE`,
    CHANGE_STYLE_PRICE: `${C}_CHANGE_STYLE_PRICE`,
    CHANGE_COLOR_PRICE: `${C}_CHANGE_COLOR_PRICE`,
    //--------- sub VAT ---------//
    CHANGE_SUB_VAT_NOTE: `${C}_CHANGE_SUB_VAT_NOTE`,
    CHANGE_SUB_VAT_TAX_RATE: `${C}_CHANGE_SUB_VAT_TAX_RATE`,
    CHANGE_SUB_VAT_DATE: `${C}_CHANGE_SUB_VAT_DATE`,
    CHANGE_SUB_VAT_NO: `${C}_CHANGE_SUB_VAT_NO`,
    CHANGE_SUB_VAT_TOTAL_AMOUNT: `${C}_CHANGE_SUB_VAT_TOTAL_AMOUNT`,
    //--------- main VAT ---------//
    CHANGE_MAIN_VAT_NOTE: `${C}_CHANGE_MAIN_VAT_NOTE`,
    CHANGE_MAIN_VAT_TAX_RATE: `${C}_CHANGE_MAIN_VAT_TAX_RATE`,
    CHANGE_MAIN_VAT_DATE: `${C}_CHANGE_MAIN_VAT_DATE`,
    CHANGE_MAIN_VAT_NO: `${C}_CHANGE_MAIN_VAT_NO`,
    CHANGE_MAIN_VAT_TOTAL_AMOUNT: `${C}_CHANGE_MAIN_TOTAL_AMOUNT`,
    SUCCESS: `CREATE_PAYABLE_INVOICE_CONTRACTOR_SUCCESS`,
    RESET_DATA: `${C}_RESET_DATA`,
    ACTIVE_SCREEN_STATUS: `${C}_ACTIVE_SCREEN_STATUS`
}