import React from 'react';
import PropTypes from "prop-types";
import './style.scss';
import {randomColorAvatar, pathImage} from '../../../functions/helpers';
import * as R from 'ramda';

export default class Avatar extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      randomBackground: randomColorAvatar(this.props.defaultText || "G")
    };
  }

  handleLoadImageError = () => {
    this.setState({isValidImage: false});
  }

  handleLoadImage = () => {
    this.setState({isValidImage: true, randomBackground: ''});
  }

  render() {
    let {avatarUrl} = this.props;
    const isValidImage = !!avatarUrl;
    
    let wrapClass = 'wrapAvatar ' + this.props.type;
    return (
      <div className={wrapClass} >
        {isValidImage &&
          <img src={pathImage(avatarUrl)} style={{width:'100%', height: '100%'}}
              onError={this.handleLoadImageError}
              onLoad={this.handleLoadImage}/>
        }
        {!isValidImage &&
          <span className='avatarText' style={{background: this.state.randomBackground || '#ccc'}}>
            {this.props.defaultText || ''}
          </span>
        }
      </div>
    );
  }
}

Avatar.defaultProps = {
  avatarUrl: '',
  type: 'square',
  defaultText: 'G'
};

Avatar.propTypes = {
  // avatarUrl: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['square', 'circle']),
  defaultText: PropTypes.string.isRequired
};

