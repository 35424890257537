import { SHIPPING } from '../constants/actionTypes';
import initial from './initialState';

export default function (state = initial.shipping, action) {
    switch (action.type) {
        // case STANDARD.RELOAD:
        //     return {...state, reload: !state.reload};
        case SHIPPING.GET_FOLLOW_UP_SUCCESS:
            // alert('here at reducer: ' + JSON.stringify(action.data))
            return {
                ...state, 
                detail: action.data,
                count: action.count,
            }
        case SHIPPING.CREATE_SHIPMENT:
            return {
                ...state,
                detail: action.data
            }
        case SHIPPING.PURCHASE_ORDER_LIST:
            return {
                ...state,
                items: action.data
            }
        case SHIPPING.SHIPIN_LIST: 
            // alert('here at reducer: ' + action.data)
            return {
                ...state,
                shipinItems: action.data,
                shipinCount: action.count,
            }
        case SHIPPING.HISTORY_LIST:
            return {
                ...state,
                historyItems: action.data,
                historyCount: action.count,
            }
        // case STANDARD.RESET_DETAIL:
        //     return {...state, detail: {}};
        default:
            return state;
    }
}
