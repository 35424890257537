const C = "CREATE_SHIPMENT";

export default {
    CHANGE_SHIPMENT_INFOMATION: `${C}_CHANGE_SHIPMENT_INFOMATION`,
    CHANGE_SHIPMENT_INFO_VALUE: `${C}_CHANGE_SHIPMENT_INFO_VALUE`,
    CHANGE_TRANSPORT_FEE_VALUE: `${C}_CHANGE_TRANSPORT_FEE_VALUE`,
    CHANGE_PKL_SHIP_LIST: `${C}_CHANGE_PKL_SHIP_LIST`,
    CHANGE_PKL_SHIP_DETAIL: `${C}_CHANGE_PKL_SHIP_DETAIL`,
    CHANGE_ACTIVE_ORDER: `${C}_CHANGE_ACTIVE_ORDER`,
    CHANGE_ACTIVE_STYLE: `${C}_CHANGE_ACTIVE_STYLE`,
    CHANGE_UNIT_PRICE: `${C}_CHANGE_UNIT_PRICE`,
    CREATE_SUCCESS: `${C}_CREATE_SUCCESS`,
    RESET_DATA: `${C}_RESET_DATA`,
    CHANGE_LIST_FORWARDER: `${C}_CHANGE_LIST_FORWARDER`,
    ADD_FORWARDER_INTO_LIST: `${C}_ADD_FORWARDER_INTO_LIST`,
    ACTIVE_SCREEN_CREATE_SHIPMENT: `${C}_ACTIVE_SCREEN_CREATE_SHIPMENT`
}