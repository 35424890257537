import * as types from '../constants/actionTypes';
import {showLoading, hideLoading} from './commonUseActions';
import fabricServices from '../services/fabricServices';

function getListFabric (options = {}) {
  return (dispatch) => {
    showLoading(types, dispatch);

    fabricServices.getListFabric(options).then((res) => {
      hideLoading(types, dispatch, (!res.success && res.error ?  res.error : undefined));
      if(res.success){
        dispatch({
          type: types.FABRIC.GET_LIST_SUCCESS,
          data: res.data || []
        });
      }
    }, (error) => {
      hideLoading(types, dispatch);
    });
  };
}

function getDetail (id, reset = true) {
  return (dispatch) => {
    showLoading(types, dispatch);
    reset && dispatch({
      type: types.FABRIC.RESET_DETAIL,
      data: {}
    });

    fabricServices.getDetail(id, {}).then((res) => {
      hideLoading(types, dispatch);
      if (res) {
        dispatch({
          type: types.FABRIC.GET_DETAIL_SUCCESS,
          data: res.data || {}
        });
      }
    }, (error) => {
      hideLoading(types, dispatch);
    });
  };
}

function getDetailByKey (key) {
  return (dispatch) => {
    showLoading(types, dispatch);

    fabricServices.getDetailByKey(key, {}).then((res) => {
      hideLoading(types, dispatch);
      res && res.success && dispatch({
        type: types.FABRIC.GET_KEY_SUCCESS,
        data: {key: key, values: _.get(res, 'data.values', [])}
      });
    }, (error) => {
      hideLoading(types, dispatch);
    });
  };
}

function deleteFabric (id) {
  return (dispatch) => {
    showLoading(types, dispatch);

    fabricServices.deleteFabric(id).then((res) => {
      hideLoading(types, dispatch);
      if (res) {
        dispatch({
          type: types.FABRIC.RELOAD,
          data: true
        });
      }
    }, (error) => {
      hideLoading(types, dispatch, error);
    });
  };
}

function editFabric (id, data) {
  return (dispatch) => {
    showLoading(types, dispatch);

    fabricServices.editFabric(id, data).then((res) => {
      hideLoading(types, dispatch);
      if (res) {
        dispatch({
          type: types.FABRIC.RELOAD,
          data: true
        });
      }
    }, (error) => {
      hideLoading(types, dispatch, error);
    });
  };
}

function addFabric (data) {

  return (dispatch) => {
    showLoading(types, dispatch);

    fabricServices.addFabric(data).then((res) => {
      hideLoading(types, dispatch);
      if (res) {
        dispatch({
          type: types.FABRIC.RELOAD,
          data: true
        });
      }
    }, (error) => {
      hideLoading(types, dispatch, error);
    });
  };
}

export default {
  getListFabric,
  deleteFabric,
  editFabric,
  addFabric,
  getDetail,
  getDetailByKey
};
