import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { toastr } from 'react-redux-toastr'
import I18n from 'i18n-js';
import { Switch, Route, withRouter } from 'react-router-dom'
import * as PATH from '../../../constants/routeConstants';
import PurchasePlansView from '../../components/purchase/PurchasePlans';
import PurchasePlansPreviewView from './PreviewPurchasePlan';
import PurchasePlanPlaceOrder from './PurchasePlanPlaceOrder';
import PreviewPurchaseOrder from './PreviewPurchaseOrderForPurchasePlans';
import * as selector from '../../../selectors/purchase/purchasePlans';
import * as actions from '../../../actions/purchase/purchasePlans';
import { PAGINATION } from '../../../constants/constants';
class PurchasePlans extends Component {

    constructor(props) {
        super(props);
        this.state = {
            pageSize: PAGINATION.PAGE_SIZE,
            pageNumber: PAGINATION.PAGE_NUMBER,
        };
        this.onSubmitSearch = this.onSubmitSearch.bind(this)
    }

    componentWillMount() {
        const size = this.state.pageSize;
        const number = this.state.pageNumber;
        this.getListFirstOrder(size, number);
        this.props.onChangePageNumber(PAGINATION.PAGE_NUMBER);
        this.props.onChangeSearch();
    }

    componentWillReceiveProps(nextProps) {
        // const { orders, firstLoad } = nextProps;
        // if (_.isEmpty(orders) && firstLoad == false) {
        //     this.props.getListOrder({isCompleted: 'false'})
        // }
        // if(firstLoad) {
        //     this.props.getListOrder({isCompleted: 'false'})
        // }
    }

    componentWillUnmount() {
        this.props.resetData()
    }

    handleLoadMore(number) {
        const { isSearch, searchText } = this.props
        const {pageSize, pageNumber } = this.state;
        if (isSearch && searchText == '') { // Search not value 
          this.props.cancelChangeSearch();
          this.props.cancelChangePageNumber();
          this.getListFirstOrder(pageSize, pageNumber);
        } else if (isSearch && searchText) { // Search value
          this.props.onChangePageNumber(number)
          this.getListOrderSearch(pageSize, number)
        } else { // Not search
          this.props.onChangePageNumber(number)
          this.props.cancelChangeSearch()
          this.getListOrderMore(pageSize, number)
        }
    }

    onSubmitSearch() {      
        const { searchText } = this.props
        const { pageSize, pageNumber } = this.state;
        const querySearch = { pageNumber: pageNumber, pageSize: pageSize, populate: 'styleOrders', isCompleted:false, isCancel:false, searchKey: searchText, filter: true};
        const querySearchNull = { pageNumber: pageNumber, pageSize: pageSize, populate: 'styleOrders', isCompleted:false, isCancel:false};
        this.props.cancelChangePageNumber();
        if (searchText) {
          this.props.changeSearch()
          this.props.loadFirstData(_.assign(querySearch));
        } else {
          this.props.cancelChangeSearch()
          this.props.loadFirstData(_.assign(querySearchNull));
        } 
      }
    getListOrderSearch(size, number) {
        const { searchText } = this.props
        const query = { pageNumber: number, pageSize: size, isCompleted: false, isCancel: false, searchKey: searchText, filter: true };
        if (searchText) {
            this.props.getListOrderMore(_.assign(query));
        }
    }

    getListFirstOrder(size, number) {
        const query = { pageSize: size, pageNumber: number, isCompleted: false, isCancel: false };
        this.props.loadFirstData(_.assign(query));
    }

    getListOrderMore(size, number) {
        const query = { pageSize: size, pageNumber: number, isCompleted: false, isCancel: false };
        this.props.getListOrderMore(_.assign(query));
      }

    handleSelectOrder(order) {
        this.props.changeSeletedOrder(order);
    }

    previewPlan() {
        const { checkedEmpty } = this.props;
        switch (checkedEmpty) {
            case 'emptyNeedToBuy': {
                toastr.info(I18n.t('Notify.Info'), I18n.t('ErrorMessages.PleaseInputNeedToBuy'));
                break;
            }
            case 'emptySupplier': {
                toastr.info(I18n.t('Notify.Info'), I18n.t('ErrorMessages.PleaseChooseSupplier'));
                break;
            }
            default:
                this.props.history.push(PATH.PURCHASE_PLANS_PREVIEW_URL)
                break;
        }
    }
 
    render() {
        return (
            <Switch>
                <Route path={PATH.PURCHASE_PLANS_URL} exact render={() =>
                    (<PurchasePlansView
                        {...this.props}
                        handleSelectOrder={(order) => this.handleSelectOrder(order)}
                        previewPlan={() => this.previewPlan()}
                        onSubmitSearch={() => this.onSubmitSearch()}
                        handleLoadMore={(e) => this.handleLoadMore(e)}
                    />)}
                />
                <Route path={PATH.PURCHASE_PLANS_PREVIEW_URL} exact render={() =>
                    (<PurchasePlansPreviewView />)} />
                <Route path={PATH.PURCHASE_PLANS_PREVIEW_PLACE_ORDER_URL} exact render={() => (
                    <PurchasePlanPlaceOrder />
                )} />
                <Route path={PATH.PURCHASE_PLANS_PREVIEW_ORDER_URL} exact render= {() => 
                (
                    <PreviewPurchaseOrder />
                )}
                />
            </Switch>
        );
    }
}

const mapStateToProps = state => ({
    orders: selector.getListOrder(state),
    selectedOrder: selector.getSelectedOrder(state),
    listStyles: selector.getListStyle(state),
    selectedStyle: selector.getSelectedStyle(state),
    materials: selector.getListMaterial(state),
    checkedFabric: selector.getCheckedFabric(state),
    checkedTrim: selector.getCheckedTrim(state),
    checkedEmpty: selector.getCheckedEmpty(state),
    firstLoad: selector.getFirstLoad(state),

    pageNumber: selector.getPageNumber(state),
    searchText: selector.getFilterText(state),
    isLoadMore: selector.isOpenLoadMore(state),
    isSearch: selector.isSearch(state),
    items: selector.getOrders(state),
    isLoading: selector.isLoading(state),
})

const mapDispatchToProps = dispatch => ({
    getListOrder(params) {
        dispatch(actions.getListOrder(params))
    },
    changeSeletedOrder(order) {
        dispatch(actions.changeSeletedOrder(order))
    },
    changeSelectedStyle(style) {
        dispatch(actions.changeSelectedStyle((style || {}).raw || null))
    },
    changeCheckedFabric(checked) {
        dispatch(actions.changeCheckedFabric(checked))
    },
    changeCheckedTrim(checked) {
        dispatch(actions.changeCheckedTrim(checked))
    },
    changeMaterialValue(checkedFabric, checkedTrim, id, idx, key, value) {
        dispatch(actions.changeMaterialValue(checkedFabric, checkedTrim, id, idx, key, value))
    },
    resetData() {
        dispatch(actions.resetData())
    },

    loadFirstData(options) {
        dispatch(actions.getListOrder(options));
    },
  
    getListOrderMore(options) {
        dispatch(actions.getListOrderMore(options));
    },
  
    cancelChangePageNumber() {
        dispatch(actions.cancelChangePageNumber());
    },

    onChangePageNumber(number) {
        dispatch(actions.changePageNumber(number));
    },

    onChangeSearch(text) {
        dispatch(actions.changeFilterText(text ? text : ''));
    },

    cancelChangeSearch() {
        dispatch(actions.cancelChangeSearch());
    },

    changeSearch() {
        dispatch(actions.changeSearch());
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PurchasePlans));