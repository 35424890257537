import {createSelector} from 'reselect';
import _ from 'lodash';
import formatCurrency from "format-currency";
import moment from 'moment';

export const getInvoiceDetail = ({historyPreviewUSDelivery}) => historyPreviewUSDelivery.invoiceDetail || {};

export const genDataForPreview = createSelector (
    [
        getInvoiceDetail
    ], data => {
        // let temp = [];
        // data.invoiceDetail && data.invoiceDetail.bills.map(item => item.containers.map(c => {
        //     temp.push({...c, 
        //         billNo: item.billNo, 
        //         amount: '$'+ formatCurrency(((c.cost || 0) + (c.trucking || 0) + (c.pierPass || 0) + (c.waitingTime || 0) + (c.chassisFee || 0) + (c.prepull || 0) + (c.yardStorage || 0) + (c.otherFees || 0))),
        //         amountNumber: (c.cost || 0) + (c.trucking || 0) + (c.pierPass || 0) + (c.waitingTime || 0) + (c.chassisFee || 0) + (c.prepull || 0) + (c.yardStorage || 0) + (c.otherFees || 0),
        //     })
        // }))
        let bills = data.invoiceDetail && _.cloneDeep(data.invoiceDetail.bills)
        if(_.isEmpty(bills)) return [];
        bills.map(item => {
            const containersAmount =  _.sum(item.containers.map(c => (c.cost || 0) + (c.trucking || 0) + (c.pierPass || 0) + (c.waitingTime || 0) + (c.chassisFee || 0) + (c.prepull || 0) + (c.yardStorage || 0) + (c.otherFees || 0)))
            const billAmount = (item.ifsFilling || 0) + (item.customerEntry || 0) + (item.otherFees || 0);
            item.amount = formatCurrency(containersAmount + billAmount) + ' VND';
            item.amountNumber = (containersAmount + billAmount);
            
            let maxLengthEtd = 0;
            let etdValue;
            item.containers.map(c => {
                const count = item.containers.filter(it => it.etd === c.etd);
                if(count.length > maxLengthEtd) {
                    maxLengthEtd = count.length;
                    etdValue = c.etd;
                }
            })
            item.etd = moment(etdValue).format("MMM DD, YYYY");

            let maxLengthEta = 0;
            let etaValue;
            item.containers.map(c => {
                const count = item.containers.filter(it => it.eta === c.eta);
                if(count.length > maxLengthEta) {
                    maxLengthEta = count.length;
                    etaValue = c.eta;
                }
            })
            item.eta = moment(etaValue).format("MMM DD, YYYY");

            let maxLengthDueDate = 0;
            let dueDateValue;
            item.containers.map(c => {
                const count = item.containers.filter(it => it.dueDate === c.dueDate);
                if(count.length > maxLengthDueDate) {
                    maxLengthDueDate = count.length;
                    dueDateValue = c.dueDate;
                }
            })
            item.dueDate = moment(dueDateValue).format("MMM DD, YYYY");
            _.omit(item, ['containers']);
        })
        return bills;
    }
)

export const getTotalAmount = createSelector (
    [
        genDataForPreview
    ], (data) => {
        const count = _.sum(data.map(item => item.amountNumber));
        return formatCurrency(count|| 0) + ' VND'
    }
)

export const getName = createSelector (
    [getInvoiceDetail], data => {
        if(data.invoiceDetail) {
            return data.invoiceDetail.company;
        }
        return '';
    }
)

// export const getTotalAmount = createSelector (
//     [genDataForPreview], data => {
//         return _.sum(data.map(item => (item.crsPaperWork || 0) + (item.truckingIn || 0) + (item.localCharge || 0) - (item.advance || 0)))
//     }
// )

// export const getTotalAdvance = createSelector (
//     [genDataForPreview], data => {
//         return _.sum(data.map(item => (item.advance || 0)))
//     }
// )

export const getActiveScreenStatus = ({historyPreviewUSDelivery}) => historyPreviewUSDelivery.activeScreen;