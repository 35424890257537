import React, {Component} from 'react';
import _ from 'lodash';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import PropTypes from 'prop-types';
import organizationActions from '../../../actions/organizationActions';
import I18n from 'i18n-js';
import {toastr} from 'react-redux-toastr'
import ReactTelephoneInput from 'react-telephone-input/lib/withStyles';

import flagsIcon from '../../../stylesheets/images/flags.png';

class OrganizationTabEdit extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      item: {},
      errors: {}
    };
  }

  componentWillMount() {
    const data = _.get(this.props, 'options.data', {});
    this.setState({item: _.cloneDeep(data)});
    //this.props.organizationPropsActions.getListOrganization();
  }

  onChange(property, value) {
    let {item} = this.state;
    this.setState({item: _.set(item, property, value)});
    this.validateChange(property, value);
  }

  close() {
    this.props.closeModal();
  }

  validateChange(property, value) {
    let errors = {};
    if (_.isEmpty(value)) {
      _.set(errors, property, `Please enter ${property}`);
    }
    this.setState({ errors });
  }

  validate(item) {
    let fields = ['name', 'address'];
    let errors = {};

    fields.forEach(f => {
      _.set(errors, f, !item[f] ? `Please enter ${f}` : '');
    });
    
    this.setState({ errors });

    let flag = Object.keys(errors).every(k => !errors[k]);

    return flag;
  }

  save() {
    let {item} = this.state;
    if(!this.validate(item)) return
    
    item._id && this.props.organizationPropsActions.editOrganization(item._id, item).then(rs => {
      if(rs.statusCode == 500) {
        toastr.error(I18n.t('Notify.Error'), I18n.t('ErrorMessages.OrganizationExist'))
      } else if(!rs.success) {
        toastr.error(I18n.t('Notify.Error'), rs.message)
      }
    });
  }

  render() {
    let {item, errors} = this.state;
    return (
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <form>
            <div className="modal-header">
              <a className="close" onClick={() => this.close()}><span aria-hidden="true">&times;</span></a>
              <h4 className="modal-title" id="myModalLabel">{I18n.t('PageOrganization.EDITORGANIZATION')}</h4>
            </div>

            <div className="modal-body">
              <div className="form-group">
                <div className="col-md-4 col-xs-12 text">
                  <p>{I18n.t('PageOrganization.Name')}</p> <span className="color-red"> *</span>
                </div>
                <div className="col-md-8 col-xs-12 value">
                  <input type="text" value={item.name || ''} onChange={evt => this.onChange('name', evt.target.value)}
                         className="form-control"/>
                  {errors['name'] && <div className="alert-warning">{errors['name']}</div>}
                </div>
              </div>

              <div className="form-group">
                <div className="col-md-4 col-xs-12 text">
                  <p>{I18n.t('PageOrganization.Address')}</p> <span className="color-red"> *</span>
                </div>
                <div className="col-md-8 col-xs-12 value">
                  <input type="text" value={item.address || ''}
                         onChange={evt => this.onChange('address', evt.target.value)} className="form-control"/>
                  {errors['address'] && <div className="alert-warning">{errors['address']}</div>}
                </div>
              </div>

              <div className="form-group">
                <div className="col-md-4 col-xs-12 text">
                  <p>{I18n.t('PageOrganization.Phone')}</p>
                </div>
                <div className="col-md-8 col-xs-12 value">
                <ReactTelephoneInput className='w-full'
                    defaultCountry='us'
                    autoFormat={false}
                    flagsImagePath={flagsIcon}
                    initialValue={(item.phone ? item.phone : '')}
                    onChange={(phone) => this.onChange('phone', phone)}
                  />
                </div>
              </div>

            </div>

            <div className="modal-footer">
              <button type="button" className="bt-black" onClick={() => this.close()}>{I18n.t('Btn.CANCEL')}</button>
              <button type="button" className="bt-orange mg-l-5" onClick={() => this.save()}>{I18n.t('Btn.SAVE')}</button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    appState: state.appState,
    authState: state.authState,
    organizationState: state.organizationState
  };
}

function mapDispatchToProps(dispatch) {
  return {
    organizationPropsActions: bindActionCreators(organizationActions, dispatch)
  };
}

OrganizationTabEdit.propTypes = {
  authState: PropTypes.object.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationTabEdit);
