import React, { Component } from 'react';
import moment from 'moment';
import I18n from 'i18n-js';

import placeOrderIcon from '../../../stylesheets/images/place_order.svg';

const PreviewPurchasePlan = props => {
  const { orderDetail = {}, total = '1', selectedStyle, items, listGTotal, listCanUseStock } = props;
  // console.log(props)
  return (
    <div id="content">
      <div className="md content-main-header pd-5">
        <ul className="content-main-header-top">
          <li className="content-main-date">
            <span>{moment(orderDetail.createdAt).format('MMM DD')}</span>
            <span className="line">
              <span />
            </span>
            <span>{moment(orderDetail.createdAt).format('YYYY')}</span>
          </li>
          <li className="content-main-title bold">{orderDetail.customer ? orderDetail.customer.name : ''}</li>
          <li className="content-main-po">
            <div className="content-title-po">
              <span className="title">{I18n.t('PagePurchase.CustomerPO')}</span>
              <span className="bold color-black-darken">{orderDetail.customerPO}</span>
            </div>
            <div className="content-title-po">
              <span className="title">{I18n.t('PagePurchase.SaleOrderPO')}</span>
              <span className="bold color-black-darken">{orderDetail.internalPO}</span>
            </div>
          </li>
          <li className="content-main-total">
            <span className="text">{I18n.t('PagePurchase.G-Total')}</span>
            <span className="color-number size-25">{orderDetail.gTotal}</span>
          </li>
          <li className="content-main-factory">
            <span className="text">{I18n.t('PagePurchase.Ex-Factory')}</span>
            <span className="date">{moment(orderDetail.exFactory).format('MMM DD, YYYY')}</span>
          </li>
          <li className="content-main-factory">
            <span className="text">{I18n.t('PagePurchase.IHDate')}</span>
            <span className="date">{moment(orderDetail.ihDate).format('MMM DD, YYYY')}</span>
          </li>
        </ul>
      </div>
      <ul className="content-header-title">
        <li>
          <p>
            {I18n.t('PagePurchase.Style')}{' '}
            <span className="color-orange medium mg-left-50 size-16">
              {selectedStyle && (selectedStyle.raw || {}).style}
            </span>
          </p>
        </li>
      </ul>

      <ul className="wrap-list-table grid">
        {/* //   let total = supplier.materials.reduce((a,b) => a + (b.price * b.needToBuy || 0), 0)
            //   total = total > 0 ? (supplier.supplier.currency == 'USD' ? `$ ${total}` : `${total} ${supplier.supplier.currency}`) : '0'
            //   return ( */}
        {items.map((item, index) => {
          return (
            <li key={index} className="grid-item mg-bottom-5" style={{ float: 'left !important' }}>
              <div className="wrap-item">
                <div className="box-shadow">
                  <div className="header-place-order">
                    <ul style={{ minWidth: 593 }}>
                      <li>
                        <h3>{((item[0] || {}).supplier || {}).label}</h3>
                      </li>
                      <li>
                        {listCanUseStock[index] > 0 && item[0].needToBuy == 0 ? (
                          <span style={{ cursor: 'pointer' }} onClick={() => props.useStock(item)}>
                            <img src={placeOrderIcon} /> {I18n.t('PagePurchase.UseStock')}
                          </span>
                        ) : (
                          <span style={{ cursor: 'pointer' }} onClick={() => props.placeOrder(item)}>
                            <img src={placeOrderIcon} /> {I18n.t('PagePurchase.PLACEORDER')}
                          </span>
                        )}
                      </li>
                    </ul>
                  </div>

                  <div className="wrap-global-table pd-0">
                    <table style={{ width: '100%' }} className="global-table">
                      <tbody>
                        <tr className="bg-white bd-dashed-bottom">
                          <th>{I18n.t('PagePurchase.Material')}</th>
                          <th>{I18n.t('PagePurchase.Content')}</th>
                          <th className="center">{I18n.t('PagePurchase.Replacement')}</th>
                          <th className="center">{I18n.t('PagePurchase.Color')}</th>
                          <th className="center">{I18n.t('PagePurchase.Size')}</th>
                          <th className="center">{I18n.t('PagePurchase.Quantity')}</th>
                          <th className="center">{I18n.t('PagePurchase.Convert_Quantity')}</th>
                          <th className="center">{I18n.t('PagePurchase.Amount')}</th>
                        </tr>

                        {item.map((m, idx) => {
                          return (
                            <tr key={idx}>
                              <td>{m.material}</td>
                              <td>{m.content}</td>
                              <td className="center">{m.replacementPO}</td>
                              <td className="center">{m.color}</td>
                              <td className="center">{m.size}</td>
                              <td className="center">{m.quantityText}</td>
                              <td className="center">{m.convertQuantity}</td>
                              <td className="number center color-number">{m.amount}</td>
                            </tr>
                          );
                        })}
                        <tr className="bg-white bd-dashed-top">
                          <td colSpan="2" className="medium">
                            {I18n.t('PagePurchase.G-Total')}
                          </td>
                          <td colSpan="6" className="color-number right size-16">
                            {listGTotal[index]}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default PreviewPurchasePlan;
