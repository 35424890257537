import React, { Component } from 'react';
import I18n from 'i18n-js';
import {connect} from 'react-redux';
import * as selector from '../../../selectors/ship-out/editPklShip';
import {changeBarActual, resetBarNumber, submitBarChange} from '../../../actions/ship-out/editPklShipActions';

class ModalEditbar extends Component {

    constructor(props) {
        super(props);
        
    }
    
    ok = (data, bar) => {
        this.props.closeModal();
        this.props.submitBarChange(data.orderId, data.styleId, data.cutTicketId, data.colorId, data.barNo, bar)
    }

    close = (data) => {
        const {getBarOfPklShip} = this.props;
        this.props.closeModal();
        const bar = getBarOfPklShip(data.orderId, data.styleId, data.cutTicketId, data.colorId, data.barNo)
        this.props.resetBarNumber(data.orderId, data.styleId, data.cutTicketId, data.colorId, data.barNo, bar)
    }

    changeBarActual = (data, sizeName, value) => {
        this.props.changeBarActual(data.orderId, data.styleId, data.cutTicketId, data.colorId, data.barNo, sizeName, value);
    }

    render() {
        const {data, getBarDetail} = this.props;
        const bar = getBarDetail(data)
        // console.log('change')
        return (
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <form>
                    <div className="modal-header">
                        <h4 className="modal-title" id="myModalLabel">{I18n.t('PageShiping.BarNo') + ' ' +bar.bar || ''}</h4>
                    </div>
                    <div className="modal-body size-12">
                        <div className="size-12 pd-0-20">
                            <div className="d-flex justify-content-between mg-10">
                                <div>
                                    <span className="color-red" >{data.barTotal}</span>
                                    
                                </div>
                                <div className="d-flex justify-content-between" style={{width: '80%'}}>
                                    {bar.addableQuantities.map((item, index) => {
                                        return (
                                            <div key = {index}>
                                                <span>{item.name + ' : ' + item.value}</span>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                            <div className="d-flex justify-content-between mg-10">
                                <div>
                                    <span className="color-gray" >{'Actual'}</span>
                                    
                                </div>
                                <div className="d-flex justify-content-between" style={{width: '80%'}}>
                                    {bar.sizeInputs.map((item, index) => {
                                        return (
                                            <div key = {index}>
                                            <span >
                                                <input min = "0" type = "number" className="form-control center pd-right-0 mr-10 height-30 w-50" 
                                                        value ={item.value || 0}
                                                        onChange = {(e) => this.changeBarActual(data, item.name, e.target.value)}
                                                    />
                                            </span>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                            <div className="d-flex justify-content-between mg-10">
                                <div>
                                    <span className="color-gray" >{"Remaining"}</span>
                                    
                                </div>
                                <div className="d-flex justify-content-between" style={{width: '80%'}}>
                                    {bar.addableQuantities.map((item, index) => {
                                        return (
                                            <div key = {index}>
                                                <span className="color-green">{item.value - bar.sizeInputs[index].value}</span>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                            <button type="button" className="bt-black" onClick={() => this.close(data)}>{I18n.t('Btn.CANCEL')}</button>
                            <button type="button" className="bt-orange" onClick={() => this.ok(data, bar)}>{I18n.t('Btn.SUBMIT')}</button>
                    </div>
                    </form>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    getBarDetail: selector.getBarDetail(state),
    getBarOfPklShip: selector.getBarOfPklShip(state),
})

const mapDispatchToProps = dispatch => ({
    changeBarActual(orderId, styleId, cutTicketId, colorId, barNo, sizeName, value) {
        dispatch(changeBarActual(orderId, styleId, cutTicketId, colorId, barNo, sizeName, value))
    },
    resetBarNumber(orderId, styleId, cutTicketId, colorId, barNo, bar) {
        dispatch(resetBarNumber(orderId, styleId, cutTicketId, colorId, barNo, bar))
    },
    submitBarChange(orderId, styleId, cutTicketId, colorId, barNo, bar) {
        dispatch(submitBarChange(orderId, styleId, cutTicketId, colorId, barNo, bar))
    }
})

export default connect(mapStateToProps, mapDispatchToProps) (ModalEditbar);