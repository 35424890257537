import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import moment from 'moment';
import _ from 'lodash';
import I18n from 'i18n-js';

import TableList from '../../common/datatables/TableList';
import BaseComponent from '../../base/BaseComponent';
import warehouseActions from '../../../actions/warehouseActions';

class Outbound extends BaseComponent {
  constructor (props, context) {
    super(props, context);

    this.state = {
      columns: [
        {name: I18n.t('PageWareHouse.Date'), value: 'date'},
        {name: I18n.t('PageWareHouse.CustomerPO'), value: 'customerPO'},
        {name: I18n.t('PageWareHouse.Style'), value: 'style'},
        {name: I18n.t('PageWareHouse.Contractor'), value: 'contractor'},
        {name: I18n.t('PageWareHouse.Quantity'), value: 'qty', options: {clsTh: 'center', clsTd: 'center'}},
      ]
    }
  }

  render = () => {
    let {closeModal, stock } = this.props;
    let { columns } = this.state;
    const outbounds = _.get(this.props, 'stock.outbounds', []);
    return (
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <form>
              <div className="modal-header">
                <a className="close" onClick={closeModal}><span aria-hidden="true">&times;</span></a>
                <h4 className="modal-title" id="myModalLabel">{I18n.t('PageWareHouse.Outbound')}</h4>
              </div>

              <div className="modal-body">
                <h3 style={{margin:'10px', textAlign: 'center'}}>{`${_.get(stock, 'material.name', '')}: ${stock.outbounded}`}</h3>
                <TableList items={outbounds} columns={columns || []} actions={[]} options={{clsTbl: 'global-table box-shadow-none'}}/>
                <hr/>
              </div>

              <div className="modal-footer">
                <button type="button" className="bt-black" onClick={closeModal}>{I18n.t('Btn.CLOSE')}</button>
              </div>
            </form>
          </div>
        </div>
      )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    authState: state.authState,
    warehouseState: state.warehouseState
  };
}

const mapDispatchToProps = dispatch => {
  return {
    warehouseActions: bindActionCreators(warehouseActions, dispatch)
  };
}

Outbound.propTypes = {
  authState: PropTypes.object.isRequired,
  warehouseActions: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(Outbound);
