
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import * as R from 'ramda';
import I18n from 'i18n-js';

import EditCutTicketImagesModal from '../../../components/customer-po/EditCutTicketImagesModal';

import * as selectors from '../../../../selectors/customer-po/current/editCutTicketImages';
import * as actions from '../../../../actions/customer-po/current/editCutTicketImages';


class EditModal extends Component {
  render() {
    return (
      <EditCutTicketImagesModal
        title={I18n.t('PageOrder.EDITSTYLE')}
        {...this.props}
        onSubmit={() => {
          const { id, fnReqBody } = this.props;
          this.props.save(id, fnReqBody());
        }}
      />
    )
  }
}

const mapStateToProps = state => ({
  id: selectors.getId(state),
  isOpen: selectors.isShowing(state),
  images: selectors.getImages(state),
  isSubmiting: selectors.isSubmiting(state),
  canSave: selectors.canSave(state),
  fnReqBody: selectors.fnGenerateBody(state),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onClose() {
    dispatch(actions.hideModal());
  },

  addImage(file) {
    dispatch(actions.addImage(file));
  },

  removeImage(item) {
    dispatch(actions.removeImage(R.path(['raw', 'id'], item)));
  },

  save(id, style) {
    dispatch(actions.save(id, style));
  },
});

export default connect(mapStateToProps, mapDispatchToProps) (withRouter(EditModal));
