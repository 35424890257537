import {getListPropertyDAL, addPropertyDAL, editPropertyDAL, getPropertyDAL, getPropertyByKeyDAL, deletePropertyDAL} from '../api/propertyApi';
import _ from 'lodash';

class propertyServices {

  constructor() {
  }

  getListProperty (options = {}) {
    options.populate = (options.populate ? `${options.populate}` : '');
    options.sort = options.sort || {createdAt: -1};
    _.set(options, 'sort', JSON.stringify(options.sort));
    return getListPropertyDAL(options);
  }

  getDetail (id) {
    return getPropertyDAL(id, {});
  }

  getDetailByKey (key) {
    return getPropertyByKeyDAL(key, {});
  }

  deleteProperty (id) {
    return deletePropertyDAL(id);
  }

  editProperty (id, data) {
    return editPropertyDAL(id, data);
  }

  addProperty (data) {
    return addPropertyDAL(data);
  }
}

export default new propertyServices();
