import C from "../../../constants/accounting/payable/createInvoiceSupplier";
import accountingServices from "../../../services/accountingServices";
import I18n from 'i18n-js';
import moment from "moment";

export const changeVATPerMonth = checked => dispatch => {
  dispatch({ type: C.TOGLE_VAT_PER_MOUNTH, payload: checked });
};
export const changeSearchText = value => dispatch => {
    dispatch({ type: C.CHANGE_SEARCH_TEXT, payload: value });
};
export const changeIssuedDate = value => dispatch => {
  dispatch({ type: C.CHANGE_ISSUED_DATE, payload: value });
};

export const changeInvoiceNo = value => dispatch => {
  dispatch({ type: C.CHANGE_INVOICE, payload: value });
}

export const changeDueDate = value => dispatch => {
  dispatch({ type: C.CHANGE_DUE_DATE, payload: value });
};

/**
 *
 * @param {string} type DOMESTIC" || "OVERSEA"
 */
export const listSuppliers = type => dispatch => {
  accountingServices.getListSupplier({ division: type }).then(
    res => {
      dispatch({ type: C.CHANGE_LIST_SUPPLIERS, payload: res.data.items });
      const [firstItem] = res.data.items;
      if (firstItem) {
        dispatch(changeSelectedSupplier(firstItem));
      }
    },
    error => {
      console.log(JSON.stringify(error));
    }
  );
};

export const getListPurchaseOrder = query => dispatch => {
  accountingServices.getListPurchaseOrder(query).then(
    res => {
      dispatch({ type: C.CHANGE_PURCHASE_ORDERS, payload: res.data.items });
    },
    error => {
      console.log(JSON.stringify(error));
    }
  );
};

export const getListCreditDebit = params => dispatch => {
  // debugger
  accountingServices.getListCreditDebit(params).then(
    res => {
      dispatch({ type: C.CHANGE_LIST_CARD_CREDIT, payload: res.data || [] });
    },
    error => {
      console.log(JSON.stringify(error));
    }
  );
};

export const changeSelectedSupplier = data => dispatch => {
  dispatch({ type: C.CHANGE_SELECTED_SUPPLIER, payload: data });
  // dispatch({ type: C.CHANGE_SELECTED_CURRENCY, payload: data.currency });
  if (data) {
    dispatch(getListPurchaseOrder({ supplier: data._id }))
    dispatch(getListCreditDebit({ supplier: data._id }))
    // dispatch(changeDueDate(moment().format('YYYY-MM-DD')))
    dispatch(changeSelectedCredit(null))
  }
};

export const changeSelectedCredit = item => dispatch => {
  dispatch({ type: C.CHANGE_SELECT_CARD_CREDIT, payload: item });
};

export const changeSelectedConvertCurrency = data => dispatch => {
  dispatch({ type: C.CHANGE_SELECTED_CURRENCY, payload: data });
};

export const changeConvertRate = value => dispatch => {
  dispatch({ type: C.CHANGE_CONVERT_RATE, payload: value });
};

export const changeMainVATNo = value => dispatch => {
  dispatch({ type: C.CHANGE_MAIN_VAT_NO, payload: value });
};

export const changeMainVATTotalAmount = value => dispatch => {
  dispatch({ type: C.CHANGE_MAIN_TOTAL_AMOUNT, payload: value });
};

export const changeMainVATDate = value => dispatch => {
  dispatch({ type: C.CHANGE_MAIN_VAT_DATE, payload: value });
};

export const changeMainVATTaxRate = value => dispatch => {
  dispatch({ type: C.CHANGE_MAIN_TAX_RATE, payload: value });
};

export const changeCheckedPurchaseOrder = (id, checked) => dispatch => {
  dispatch({ type: C.TOGLE_PURCHASE_ORDERS, payload: id });
};

export const toggleActivePurchaseOrder = id => dispatch => {
  dispatch({ type: C.TOGLE_ACTIVE_PURCHASE_ORDERS, payload: id });
};

export const changeSubVATNo = (_id, value) => dispatch => {
  dispatch({ type: C.CHANGE_SUB_VAT_NO, payload: {_id, value }});
};

export const changeSubVATDate = (_id, value) => dispatch => {
  dispatch({ type: C.CHANGE_SUB_VAT_DATE, payload: {_id, value}});
};

export const changeSubTaxRate = (_id, value) => dispatch => {
  dispatch({ type: C.CHANGE_SUB_TAX_RATE, payload: {_id, value}});
};

export const changeSubVATNote = (_id, value) => dispatch => {
  dispatch({ type: C.CHANGE_SUB_NOTE, payload: {_id, value}});
};

export const resetData = () => dispatch => {
  dispatch({ type: C.RESET_DATA });
};

export const changeFactor = (value) => dispatch => {
  dispatch({ type: C.CHANGE_FACTOR, payload: value})
}

export const createSupplierInvoice = (data) => dispatch => {
  accountingServices.createInvoice(data).then(res => {
    if(res.success) {
      dispatch({ type: C.SUCCESS, payload: true})
    }
  }).catch(error => {
    console.error(error)
  })
}

export const changeSupplierType = (value) => dispatch => {
  dispatch({ type: C.CHANGE_TYPE_SUPPLIERS, payload: value})
}

export const changePurchaseInvoiceNo = (purchaseId, materialId, index, value) => dispatch => {
  dispatch({type: C.CHANGE_PURCHASE_INVOICE_NO, payload: {purchaseId, materialId, index, value}})
}

export const changeErrorMessage = (data) => dispatch => {
  dispatch({type: C.CHANGE_ERROR_MESSAGE, payload: data})
}

export const activeScreenPreview = () => dispatch => {
  dispatch({type: C.ACTIVE_SCREEN_PREVIEW_STATUS, payload: true})
}