import { SUPPLIER } from '../constants/actionTypes';
import initial from './initialState';

export default function (state = initial.supplierState, action) {
  switch (action.type) {
    case SUPPLIER.GET_LIST_SUCCESS:
      return { ...state, items: action.data.items || [], count: action.data.count || 0 };
    case SUPPLIER.RELOAD:
      return { ...state, reload: !state.reload };
    default:
      return state;
  }
}
