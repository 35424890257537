import C from '../../constants/ship-out/showPklProdList';
import shipOutApi from '../../api/shipOutApi';

export const getPklProdList = (options) => dispatch => {
    shipOutApi.getListPklOfProduction(options).then(res => {
        if(res.success) {
            dispatch({type: C.CHANGE_PKL_PROD_LIST, payload: res.data})
            dispatch({type: C.CHANGE_PAGING_TOTAL, payload: res.meta.pages})
            dispatch({type: C.CHANGE_PAGING_CURRENT, payload: res.meta.page})
        }
    })
}

export const changeSelectedFilter = (data) => dispatch => {
    if(data) {
        dispatch({type: C.CHANGE_SELECTED_FILTER, payload: data})
    }
}

export const changeSearchValue = (data, pos) => dispatch => {
    if(pos) {
        dispatch({type: C.CHANGE_SEARCH_TEXT, payload: {data, pos}})
    }
}

// export const search = (selectedFilter, searchValue) => dispatch => {
//     if(selectedFilter && searchValue) {
//         let op = {};
//         let key = selectedFilter;
//         if(selectedFilter == 'exFactory') {
//             let begin = moment(searchValue.from).format('YYYY-MM-DD');
//             let end = moment(searchValue.to).format('YYYY-MM-DD');
//             let date = `${begin}:${end}`;
//             op[key] = date
//             shipOutApi.search(op).then(res => {
//                 if(res.success)
//                     dispatch({type: C.CHANGE_PKL_PROD_LIST, payload: res.data})
//                     dispatch({type: C.CHANGE_PAGING_TOTAL, payload: res.meta.pages})
//                     dispatch({type: C.CHANGE_PAGING_CURRENT, payload: res.meta.page})
//             })
//         } else {
//             op[key] = searchValue.value
//             shipOutApi.search(op).then(res => {
//                 if(res.success)
//                     dispatch({type: C.CHANGE_PKL_PROD_LIST, payload: res.data})
//                     dispatch({type: C.CHANGE_PAGING_TOTAL, payload: res.meta.pages})
//                     dispatch({type: C.CHANGE_PAGING_CURRENT, payload: res.meta.page})
//             })
//         }
//     }
// }

export const changeCheckedList = (item, checked) => dispatch => {
    if(item) {
        if(checked)
            dispatch({type: C.ADD_CHECKED_PKL_PROD, payload : item})
        else 
            dispatch({type: C.REMOVE_CHECKED_PKL_PROD, payload: item})
    }
}

export const changeSearchStatus = (value) => dispatch => {
    dispatch({type: C.CHANGE_SEARCH_STATUS, payload: value})
}
export const viewCreate = (value) => dispatch => {
    dispatch({type: C.VIEW_CREATER, payload: false})
}

