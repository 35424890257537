import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, Switch, Route, Redirect } from 'react-router-dom';
import * as PATH from '../../../constants/routeConstants';

const AccountsPayablePlanImportExportRadioLists = (props) => {
    const { pathname } = props;
    // console.log('pathnamepathname', pathname)
    // console.log('PATH.ACCOUNTING_PAYABLE_PLAN_IMPORTEXPORT_CONTAINER_URL', PATH.ACCOUNTING_PAYABLE_PLAN_IMPORTEXPORT_CONTAINER_URL)
    return (
        <ul className="wrap-list-radio plan-import-export">
            <li className={pathname === PATH.ACCOUNTING_PAYABLE_PLAN_IMPORTEXPORT_CONTAINER_URL ? 'active' : ''}>
                <Link to={PATH.ACCOUNTING_PAYABLE_PLAN_IMPORTEXPORT_CONTAINER_URL}>
                    <span className="icon"><span></span></span>
                    <span className="text">Container</span>
                </Link>
            </li>
            <li className={pathname === PATH.ACCOUNTING_PAYABLE_PLAN_IMPORTEXPORT_DECLARATION_URL ? 'active' : ''}>
                <Link to={PATH.ACCOUNTING_PAYABLE_PLAN_IMPORTEXPORT_DECLARATION_URL}>
                    <span className="icon"><span></span></span>
                    <span className="text">Declaration</span>
                </Link>
            </li>

            <li className={pathname === PATH.ACCOUNTING_PAYABLE_PLAN_IMPORTEXPORT_US_DELIVERY_URL ? 'active' : ''}>
                <Link to={PATH.ACCOUNTING_PAYABLE_PLAN_IMPORTEXPORT_US_DELIVERY_URL}>
                    <span className="icon"><span></span></span>
                    <span className="text">Us Delivery</span>
                </Link>
            </li>
        </ul>
    );
};

AccountsPayablePlanImportExportRadioLists.propTypes = {
    pathname: PropTypes.string,
};

AccountsPayablePlanImportExportRadioLists.defaultProps = {
    pathname: null,
};

export default AccountsPayablePlanImportExportRadioLists;
