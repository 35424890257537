import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BaseComponent from '../../base/BaseComponent';
import { shipMode } from '../../../constants/EnumTypes';
import I18n from 'i18n-js';
import { createShipment, editShipment, getListForwarder } from '../../../api/shippingApi';
import standardActions from '../../../actions/standardActions';
import moment from 'moment';
import _ from 'lodash';
import { toastr } from 'react-redux-toastr'
import Panel from "../../common/layout/panel/Panel";
import * as types from '../../../constants/actionTypes'
import { checkedQuantity } from '../../../selectors/shipping';
import ComfirmModal from './ConfirmModal';
import * as permissions from "../../../selectors/permissions";
const DateTimeField = require('react-datetime');
import Creatable from 'react-select/lib/Creatable';
import ModalConfirm from "../../common/modals/ModalConfirm";
import PurchaseAdd from "./PurchaseAdd";


class ScreenShipIn extends BaseComponent {
  constructor(props, context) {
    super(props, context);

    this.state = {
      shipping: {},
      standards: {},
      contType: [],
      listForwarder: [],
      openModal: false,
      errors: {},
      notifies: [],
      orders:[],
      gTotal: 0,
      pos: []
    }
      this.checkedQuantityBeforeSave = this.checkedQuantityBeforeSave.bind(this);
  }

  componentWillMount() {
    console.log('========');
    const id = _.get(this.props, 'appState.page.data._id', '');
    const shipping = id ? _.get(this.props, 'appState.page.data', {
      crsPaperWork: 0,
      localCharge: 0,
      truckingIn: 0
    }) : {
        crsPaperWork: 0,
        localCharge: 0,
        truckingIn: 0
      }
    // shipping.purchaseOrders ? shipping.purchaseOrders = {} : shipping.purchaseOrders = {};
    const getOrders = this.getOrders();
    this.setState({
      shipping: _.cloneDeep(shipping),
      ...getOrders
    })
  }

  componentDidMount() {
    this.props.standardPropsActions.getStandard();
    this.getListForwarder();
  }

  componentWillReceiveProps(newProps) {
    const standards = _.cloneDeep(_.get(newProps.standardState, 'detail') || []);
    if (standards.shipping && standards.shipping.other) {
      let tmp = {
        ...shipMode
      }
      let contType = standards.containerType;
      _.forEach(standards.shipping.other, (el) => {
        if (el.shipMod && !tmp[el.shipMod]) {
          tmp[el.shipMod] = el.shipMod;
        }
      })
      this.setState({ standards: tmp, contType });
    }
  }

  notify = (notifies) => {
    const getOrders = this.getOrders(notifies);
    this.setState({...getOrders})
  }

  getListForwarder() {
    getListForwarder().then(res => {
      if (res.success) {
        this.setState({ listForwarder: res.data.items.map(item => ({ value: item._id, label: item.forwarder })) })
      }
    })
  }

  setShipment = (name, value) => {
    const { shipping } = this.state;
    if (name === "isCompleted") {
      value = value === "false" ? true : false;
    }
    shipping[name] = value
    this.setState({ shipping })
  }

  renderConfirmSaveStatusCompleted () {
      let { openModal, closeModal } = this.props.modal;
      let modal = (<ModalConfirm
          title={I18n.t('PageShiping.ConfirmShiping')}
          confirmMessage={I18n.t('PageShiping.ConfirmComplete')}
          messageOk={I18n.t('Btn.YES')}
          messageCancel={I18n.t('Btn.CANCEL')}
          handleClose={closeModal}
          handleOk={this.checkedQuantityBeforeSave.bind(null)} />);
      openModal(modal);
  }

  checkSave = () => {
      const { shipping } = this.state;
      if(shipping['isCompleted']){
          return this.renderConfirmSaveStatusCompleted();
      }
      return this.checkedQuantityBeforeSave();
  }

  checkedQuantityBeforeSave = () => {
    const { shipping, pos } = this.state;
    const data = {
      ...shipping,
      purchaseOrders: pos.map(po => ({
        purchaseOrder: po._id,
        materials: (po.materials || []).filter(mt => mt.checked).map(mt => ({
          id: mt._id,
          ..._.pick(mt, ['sample', 'actual', 'xmil', 'etd', 'eta', 'checked','replacementPO', 'size', 'color'])
        }))
      }))
    }
    const emptyQuantity = this.props.checkedQuantity(data);
    if (emptyQuantity && shipping.isCompleted) {
      this.setState({ openModal: true })
    } else {
      this.saveShipment(shipping, data)
    }
  }
  
  getEmptyField(data) {
      if(data) {
          if(!data.billNo || data.billNo == '') return 'billNo';
          if(!data.forwarder || data.forwarder == '') return 'forwarder';
          if(!data.declarationNo || data.declarationNo == '') return 'declarationNo';
          if(!data.cont || data.cont == '') return 'cont';
      }
      return '';
  }

  validateShipment(shipping) {
    const emptyField = this.getEmptyField(shipping);
    switch (emptyField) {
      case 'cont':
        if(shipping.shipMode == 'SEA'){
            toastr.error(I18n.t('Notify.Error'), "Cont field mustn't empty");
            break;
        }
          return true;
      case 'declarationNo':
          toastr.error(I18n.t('Notify.Error'), "Declaration No field mustn't empty");
          break;
      case 'forwarder':
          toastr.error(I18n.t('Notify.Error'), "Forwarder field mustn't empty");
          break;
      case 'billNo':
          toastr.error(I18n.t('Notify.Error'), "Bill No mustn't empty");
          break;
      default:
          return true;
    }
    return false;
  }

  saveShipment = (shipping, data) => {
    if (this.validateShipment(shipping)) {
      if (shipping._id) {
        this.props.dispatch({ type: types.APP.SHOW_LOADING })
        editShipment(shipping._id, data).then(rs => {
          this.props.dispatch({ type: types.APP.HIDE_LOADING })
          if (rs.success) {
            toastr.success(I18n.t('Notify.Success'), 'Success')
            this.props.dispatch({
              type: types.APP.SET_PAGE_CURRENT,
              data: {
                isEdit: false,
                tab: '',
                tabChild: 'tabShipIn',
                loadMore: true
              }
            })
          } else {
            toastr.error(I18n.t('Notify.Error'), rs.error)
          }
        },
          err => {
            this.props.dispatch({ type: types.APP.HIDE_LOADING })
            toastr.error(I18n.t('Notify.Error'), err.error)
          })
      } else {
        this.props.dispatch({ type: types.APP.SHOW_LOADING })
        createShipment(data).then(rs => {
          this.props.dispatch({ type: types.APP.HIDE_LOADING })
          if (rs.success) {
            toastr.success(I18n.t('Notify.Success'), I18n.t('Notify.CreateShipmentSuccess'));
            this.props.dispatch({
              type: types.APP.SET_PAGE_CURRENT,
              data: {
                isEdit: false,
                tab: '',
                tabChild: 'tabShipIn',
                loadMore: true
              }
            })
          } else {
            toastr.error(I18n.t('Notify.Error'), rs.error)
          }
        },
          err => {
            this.props.dispatch({ type: types.APP.HIDE_LOADING })
            toastr.error(I18n.t('Notify.Error'), err.error)
          })
      }
    }
  }

  ok = () => {
    const { shipping, pos } = this.state;
    const data = {
      ...shipping,
      purchaseOrders: pos.map(po => ({
        purchaseOrder: po._id,
        materials: (po.materials || []).filter(mt => mt.checked).map(mt => ({
          id: mt._id,
          ..._.pick(mt, ['sample', 'actual', 'xmil', 'etd', 'eta', 'checked'])
        }))
      }))
    }
    this.saveShipment(shipping, data)
  }

  closeModal = () => {
    this.setState({ openModal: false })
  }

  removePurchase = (order) => {
    let { pos } = this.state; 
    _.remove(pos, (po)=> { return _.get(po, 'style.order._id', '') == order._id});
    this.setState({...this.getOrders(null, pos)});
  }

  getOrders = (notifies, pos = this.state.pos) => {
    if(pos.length == 0) {
      const id = _.get(this.props, 'appState.page.data._id', '');
      pos = id ? _.cloneDeep(_.get(this.props, 'appState.page.data.purchaseOrders')).filter(po => {
        return po.materials.length;
      }).map(po => {
        for (const mt of po.materials) {
          for (const material of po.purchaseOrder.materials) {
            if (mt.id == material._id && mt.color == material.color && mt.replacementPO == material.replacementPO && mt.size == material.size) {
              material.checked = mt.checked || false;
              material.xmil = mt.xmil;
              material.eta = mt.eta;
              material.etd = mt.etd;
              material.actual = mt.actual;
              material.sample = mt.sample;
            }
          }
        }
        return po.purchaseOrder
      }) : _.get(this.props, 'appState.page.data', [])
    }
    if(notifies) {
      _.remove(notifies, (nf)=> {return pos.map(po => po._id).includes(nf._id)});
      pos.push(...notifies);
    }
    const orders = pos.reduce((orders, po) => {
      const idx = _.findIndex(orders, o => o._id == _.get(po, 'style.order._id'))
      if (idx >= 0) {
        const idxS = _.findIndex(orders[idx].styles, st => st._id == _.get(po, 'style._id', ''))
        if (idxS < 0) {
          orders[idx].styles.push({
            _id: _.get(po, 'style._id', ''),
            style: _.get(po, 'style.style', ''),
            gTotal: _.get(po, 'style.gTotal', ''),
            active: false
          });
          // orders[idx].purchaseOrders.push(po._id)
        }
      } else {
        orders.push({
          _id: _.get(po, 'style.order._id', ''),
          customerPO: _.get(po, 'style.order.customerPO', ''),
          gTotal: _.get(po, 'style.order.gTotal', 0),
          styles: [
            {
              _id: _.get(po, 'style._id', ''),
              style: _.get(po, 'style.style', ''),
              gTotal: _.get(po, 'style.order.gTotal', ''),
              active: true
            }
          ]
        })
      }
      return _.uniqBy(orders, '_id')
    }, [])

    const gTotal = orders.reduce((s, o) => s + o.gTotal, 0)
    return { orders, gTotal, pos: _.cloneDeep(pos) }
  }

  setTabStyle = (orderId, styleId) => {
    const { orders } = this.state

    for (const order of orders) {
      if (order._id == orderId) {
        for (const style of order.styles || []) {
          style.active = false
          if (style._id == styleId) {
            style.active = true
          }
        }
        break
      }
    }

    this.setState({ orders })
  }

  setPo = (name, poId, mtId, size, color, replacementPO, value) => {
    const { pos } = this.state
    for (const po of pos) {
      if (po._id == poId) {
        for (const mt of po.materials || []) {
          if (mtId == mt._id && size == mt.size && color == mt.color && replacementPO == mt.replacementPO) {
            mt[name] = value
            this.setState({ pos })
            break
          }
        }
        break
      }
    }
  }

  checkMaterial = (poId, check, allId, size, color, replacementPO) => {
    const { pos } = this.state

    for (const po of pos) {
      if (po._id == poId) {
        for (const mt of po.materials || []) {
          if (allId == 'all') {
            mt.checked = check
          } else if (mt._id == allId && mt.size == size && mt.color == color && mt.replacementPO == replacementPO) {
            mt.checked = check
            break
          }
        }
        break
      }
    }
    this.setState({ pos })
  }

  renderTitle = () => {
    const { shipping, standards, contType, listForwarder, errors } = this.state;
    const { isEdit, roles: {showCreate, showEdit, showDelete, showView} } = this.props;
    // console.log(errors)
    return (
      <div>
        <div className="wrap-title bd-dashed-bottom bg-white">
          <div className="col-md-4 col-sm-6 col-xs-12 title">
            <ul>
              <li><span>{I18n.t('PageShiping.BillNo')}</span> <span className="color-red"> *</span></li>
              <li className="input">
                <input type="text" className="form-control" onChange={(evt) => { this.setShipment('billNo', evt.target.value) }} value={shipping.billNo} />
              </li>
            </ul>
          </div>
          {/* <div className="col-md-4 col-sm-6 col-xs-12 bg-white">
            <div>
                       
              </div>
            </div> */}
          {((isEdit && showEdit) || showCreate) && <div className="col-md-8 col-sm-6 col-xs-12 wrap-button">
            <div className="float-right">
              {this.props.canComplete && (
                <div className="inline-block size-12 mg-right-15">
                  <input type="checkbox"
                    className="icon-completed"
                    value={shipping.isCompleted || false}
                    checked={shipping.isCompleted || false}
                    onChange={(evt) => this.setShipment('isCompleted', evt.target.value)} />
                  <div className="style-checkbox">
                    <input className="display-none" type="checkbox" checked={shipping.isCompleted || false} onChange={(evt) => this.setShipment('isCompleted', evt.target.value)} />
                    <label><span className="mg-right-10"><span></span></span>{I18n.t('PageShiping.Complete')}</label>
                  </div>
                </div>)
              }
              <span onClick={this.checkSave} className="cursor-pointer bt-save"><i className="fa fa-floppy-o" aria-hidden="true" /> {I18n.t('Btn.SAVE')}</span>
            </div>
          </div>}
        </div>

        <div className="wrap-table inline-block w-full box-shadow pd-10-0 bg-white wrap-input">
          <div className="col-md-4 col-sm-6 col-xs-12 input">
            <ul>
              <li>
                <span>{I18n.t('PageSystemSettings.ETD')}</span>
              </li>
              <li>
                <DateTimeField value={moment(shipping.etd).format('LL')} timeFormat={false} closeOnSelect dateFormat="MMM DD, YYYY" size="md" onChange={date => this.setShipment('etd', date.format())} />
              </li>
            </ul>
          </div>

          <div className="col-md-4 col-sm-6 col-xs-12 input">
            <ul>
              <li>
                <span>{I18n.t('PageSystemSettings.Shipper')}</span>
              </li>
              <li>
                <input type="text" className="form-control" onChange={(evt) => { this.setShipment('shipper', evt.target.value) }} value={shipping.shipper} />
              </li>
            </ul>
          </div>

          <div className="col-md-4 col-sm-6 col-xs-12 input">
            <ul>
              <li>
                <span>{I18n.t('PageSystemSettings.FlightNoVoyageNo')}</span>
              </li>
              <li>
                <input type="text" className="form-control" onChange={(evt) => { this.setShipment('flightNo', evt.target.value) }} value={shipping.flightNo} />
              </li>
            </ul>
          </div>
          {/* {-----------------} */}

          <div className="col-md-4 col-sm-6 col-xs-12 input">
            <ul>
              <li>
                <span>{I18n.t('PageSystemSettings.ETA')}</span>
              </li>
              <li>
                <DateTimeField value={moment(shipping.eta)} timeFormat={false} closeOnSelect dateFormat="MMM DD, YYYY" size="md" onChange={date => this.setShipment('eta', date.format())} />
              </li>
            </ul>
          </div>

          <div className="col-md-4 col-sm-6 col-xs-12 input">
            <ul>
              <li>
                <span>{I18n.t('PageSystemSettings.ShipMode')}</span>
              </li>
              <li>
                <div className="select w-full h-34">
                  <select className="w-full" value={shipping.shipMode} onChange={(evt) => { this.setShipment('shipMode', evt.target.value) }}>
                    <option value="" />
                    {Object.keys(standards).map((i, idx) => (<option key={idx} value={i}>{standards[i]}</option>))}
                  </select>
                </div>
              </li>
            </ul>
          </div>

          <div className="col-md-4 col-sm-6 col-xs-12 input">
            <ul>
              <li>
                <span>{I18n.t('PageSystemSettings.CBM')}</span>
              </li>
              <li>
                <input type="text" className="form-control" onChange={(evt) => { this.setShipment('cbm', evt.target.value) }} value={shipping.cbm} />
              </li>
            </ul>
          </div>
          {/* {-----------------} */}

          <div className="col-md-4 col-sm-6 col-xs-12 input">
            <ul>
              <li>
                <span>{I18n.t('PageSystemSettings.DocsReceived')}</span>
              </li>
              <li>
                <DateTimeField value={moment(shipping.docsReceived)} timeFormat={false} closeOnSelect dateFormat="MMM DD, YYYY" size="md" onChange={date => this.setShipment('docsReceived', date.format())} />
              </li>
            </ul>
          </div>

          <div className="col-md-4 col-sm-6 col-xs-12 input">
            <ul>
              <li>
                <span>{I18n.t('PageSystemSettings.QTYPKG')}</span>
              </li>
              <li>
                <input type="text" className="form-control" onChange={(evt) => { this.setShipment('qtyPKG', evt.target.value) }} value={shipping.qtyPKG} />
              </li>
            </ul>
          </div>

          <div className="col-md-4 col-sm-6 col-xs-12 input">
            <ul>
              <li>
                <span>{I18n.t('PageSystemSettings.GW')}</span>
              </li>
              <li>
                <input type="number" min="0" className="form-control" onChange={(evt) => { this.setShipment('gw', evt.target.value) }} value={shipping.gw} />
              </li>
            </ul>
          </div>
          {/* {-----------------} */}

          <div className="col-md-4 col-sm-6 col-xs-12 input">
            <ul>
              <li>
                <span>{I18n.t('PageSystemSettings.ETAFactory')}</span>
              </li>
              <li>
                <DateTimeField value={moment(shipping.etaFactory)} timeFormat={false} closeOnSelect dateFormat="MMM DD, YYYY" size="md" onChange={date => this.setShipment('etaFactory', date.format())} />
              </li>
            </ul>
          </div>

          <div className="col-md-4 col-sm-6 col-xs-12 input">
            <ul>
              <li>
                <span>{I18n.t('PageSystemSettings.Qty')}</span>
              </li>
              <li>
                <input type="number" min="0" className="form-control" onChange={(evt) => { this.setShipment('qty', evt.target.value) }} value={shipping.qty} />
              </li>
            </ul>
          </div>

          <div className="col-md-4 col-sm-6 col-xs-12 input">
            <ul>
              <li>
                <span>{I18n.t('PageSystemSettings.Forwarder')}</span> <span className="color-red"> *</span>
              </li>
              <li>
                <Creatable 
                  options = {listForwarder}
                  value = {shipping.forwarder}
                  onChange={(data) => { this.setShipment('forwarder', data.value)}}
                  isClearable = {false}
                  className="z-index-10"
                />
              {/* <input type="text" className="form-control" onChange={(evt) => { this.setShipment('forwarder', evt.target.value) }} value={shipping.forwarder} /> */}
              </li>
            </ul>
          </div>
          {/* {-----------------} */}

          <div className="col-md-4 col-sm-6 col-xs-12 input">
            <ul>
              <li>
                <span>{I18n.t('PageSystemSettings.DeclarationNo')}</span> <span className="color-red"> *</span>
              </li>
              <li>
                <input type="text" className="form-control" onChange={(evt) => { this.setShipment('declarationNo', evt.target.value) }} value={shipping.declarationNo} />
              </li>
            </ul>
          </div>

          <div className="col-md-4 col-sm-6 col-xs-12 input">
            <ul>
              <li>
                <span>{I18n.t('PageSystemSettings.Advance')}</span>
              </li>
              <li className="inline-flex">
                <input type="number" min="0" className="form-control col-md-10" onChange={(evt) => { this.setShipment('advance', evt.target.value) }} value={shipping.advance}/><span className="margin-auto-10">VND</span>
              </li>
            </ul>
          </div>

          <div className="col-md-4 col-sm-6 col-xs-12 input">
            <ul>
              <li>
                <span>{I18n.t('PageSystemSettings.BorderGate')}</span>
              </li>
              <li>
                <input type="text" className="form-control" onChange={(evt) => { this.setShipment('borderGate', evt.target.value) }} value={shipping.borderGate} />
              </li>
            </ul>
          </div>
          {/* {-----------------} */}

          <div className="col-md-4 col-sm-6 col-xs-12 input">
            <ul>
              <li>
                <span>{I18n.t('PageSystemSettings.DeclarationDate')}</span>
              </li>
              <li>
                <DateTimeField value={moment(shipping.declarationDate)} timeFormat={false} closeOnSelect dateFormat="MMM DD, YYYY" size="md" onChange={date => this.setShipment('declarationDate', date.format())} />
              </li>
            </ul>
          </div>

          <div className="col-md-4 col-sm-6 col-xs-12 input">
            <ul>
              <li>
                <span>{I18n.t('PageSystemSettings.AdvanceDate')}</span>
              </li>
              <li>
                <DateTimeField value={moment(shipping.advanceDate)} timeFormat={false} closeOnSelect dateFormat="MMM DD, YYYY" size="md" onChange={date => this.setShipment('advanceDate', date.format())} />
              </li>
            </ul>
          </div>

          <div className="col-md-4 col-sm-6 col-xs-12 input">
            <ul>
              <li>
                <span>{I18n.t('PageSystemSettings.DropOffCont')}</span>
              </li>
              <li>
                <input type="text" className="form-control" onChange={(evt) => { this.setShipment('dropOffCont', evt.target.value) }} value={shipping.dropOffCont} />
              </li>
            </ul>
          </div>
          {/* {-----------------} */}

          <div className="col-md-4 col-sm-6 col-xs-12 input">
            <ul>
              <li>
                <span>{I18n.t('PageSystemSettings.Cont')}</span> <span className="color-red"> *</span>
              </li>
              <li>
                <input type="text" className="form-control" onChange={(evt) => { this.setShipment('cont', evt.target.value) }} value={shipping.cont} />
              </li>
            </ul>
          </div>

          <div className="col-md-4 col-sm-6 col-xs-12 input">
            <ul>
              <li>
                <span>{I18n.t('PageSystemSettings.No')}</span>
              </li>
              <li>
                <input type="text" className="form-control" onChange={(evt) => { this.setShipment('no', evt.target.value) }} value={shipping.no} />
              </li>
            </ul>
          </div>

          <div className="col-md-4 col-sm-6 col-xs-12 input textarea note">
            <ul>
              <li>
                <span>{I18n.t('PageSystemSettings.Note')}</span>
              </li>
              <li>
                <textarea type="text" className="form-control" onChange={(evt) => { this.setShipment('note', evt.target.value) }} value={shipping.note} />

              </li>
            </ul>
          </div>
          {/* {-----------------} */}

          <div className="wrap-bottom">
            <div className="col-md-4 col-sm-6 col-xs-12 input">
              <ul>
                <li>
                  <span>{I18n.t('PageSystemSettings.ContType')}</span>
                </li>
                <li>
                  <div className="select w-full h-34">
                    <select className="w-full" value={shipping.contType} onChange={(evt) => { this.setShipment('contType', evt.target.value) }} >
                      <option value="">{''}</option>
                      {contType.map((item, index) => {
                        return (
                          <option key={index} value={item}>{item}</option>
                        )
                      })}
                    </select>
                  </div>
                </li>
              </ul>
            </div>

            <div className="col-md-4 col-sm-6 col-xs-12 input">
              <ul>
                <li>
                  <span>{I18n.t('PageSystemSettings.PickupCont')}</span>
                </li>
                <li>
                  <input type="text" className="form-control" onChange={(evt) => { this.setShipment('pickUpCont', evt.target.value) }} value={shipping.pickUpCont} />
                </li>
              </ul>
            </div>
          </div>
          {/* {-----------------} */}


        </div>

        <div className="header-transport mg-5-0 bd-none box-shadow">
          <div className="wrap-border-right-transport"><span className="border-right-transport"></span></div>
          <div className="wrap-title">
            <h3 style={{ margin: 10 }}><span className="fw-normal">{I18n.t('PageShiping.TransportFees')}</span></h3>
          </div>
          <div className="wrap-value">
            <ul>
              <li>
                <span className="wrap-text">{I18n.t('PageShiping.CRS')}</span>
                <span className="wrap-input inline-flex"  >
                  <input type="number" min="0" value={shipping.crsPaperWork} className="form-control center pd-right-0" onChange={(evt) => { this.setShipment('crsPaperWork', evt.target.value) }} /><span className="mg-auto-10">VND</span>
                </span>
              </li>
              <li>
                <span className="wrap-text">{I18n.t('PageShiping.LocalCharge')}</span>
                <span className="wrap-input inline-flex">
                  <input type="number" min="0" value={shipping.localCharge} className="form-control center pd-right-0" onChange={(evt) => { this.setShipment('localCharge', evt.target.value) }} /><span className="mg-auto-10">VND</span>
                </span>
              </li>
              <li>
                <span className="wrap-text">{I18n.t('PageShiping.TruckingIn')}</span>
                <span className="wrap-input inline-flex">
                  <input type="number" min="0" value={shipping.truckingIn} className="form-control center pd-right-0" onChange={(evt) => { this.setShipment('truckingIn', evt.target.value) }} /><span className="mg-auto-10">VND</span>
                </span>
              </li>
                <li className="note-li">
                    <span className="wrap-text">{I18n.t('PageShiping.Note')}</span>
                    <span className="wrap-input note">
                  <input type="text" value={shipping.transNote || ''} className="form-control pd-right-0 w-200"
                         onChange={(evt) => {
                             this.setShipment('transNote', evt.target.value)
                         }}/>
                </span>
                </li>
              </ul>
          </div>

        </div>

      </div>
    )
  }

  renderPanelDetails = order => {
    return (
      <div className="text mg-left-30">
        {(order.customerPO) ? (<span className="left size-12">{I18n.t("PageShiping.CustomerPO")}</span>) : (<span className="left size-12">Buy for Stock</span>)}
        <span className="right size-15 medium mg-left-10 text-transform">{_.get(order, 'customerPO', '')}</span>
      </div>
    )
  }

  addPurchase = () => {
    const { onSearch, onChangeSearch } = this.props.appState.page;
    let {openModal, closeModal} = this.props.modal;
    let modal = (<PurchaseAdd closeModal={closeModal} notify={this.notify} onSearch={onSearch} onChangeSearch={onChangeSearch}/>);
    openModal(modal);
  }

  renderPanelContent = (order, orderIndex, totalMaterials) => {
    const { shipping, pos } = this.state;
    let mt_total = 0;
    let CRS = 0, LocalCharge = 0, TruckingIn = 0;
    pos.filter((p, index) => (p.styleId === _.get(_.find(order.styles, s => s.active, 0), '_id', undefined)) || (order._id == '' && p.styleId == undefined)).map(po => {
      if (po.materials) {
        (po.materials || []).map((mt, idx) => {
          if (mt.checked) {
            mt_total = (mt.sample || 0) + (mt.actual || 0);
            CRS += ((_.toInteger(shipping.crsPaperWork * mt_total) / totalMaterials) || 0);
            LocalCharge += ((_.toInteger(shipping.localCharge * mt_total) / totalMaterials) || 0);
            TruckingIn += ((_.toInteger(shipping.truckingIn * mt_total) / totalMaterials) || 0);
          }
        })
      }
    });

    let styleActive = '';
    order.styles.map(s => s.active ? styleActive = s._id : null);
    return (
      <div>
        {!!order._id &&
        (
          <div className="header-title pd-0 box-shadow">
            <div className="list-style pd-0-15 bg-white">
              <span className="mg-right-10 size-13">{I18n.t("PageShiping.Style")}</span>
              {(order.styles || []).map((style, idx) => (
                <button key={idx} onClick={() => this.setTabStyle(order._id, style._id)} className={style.active ? 'bt-orange mg-10' : 'bt-white mg-10'}>{style.style || ''}</button>
              ))}
            </div>
          </div>
        )}

        <div className="header-transport pd-15 bd-none box-shadow">
          <div className="wrap-border-right-transport"> <span className="border-right-transport"></span></div>
          <div className="wrap-title">
            <h3 style={{ margin: 10 }}><span className="fw-normal">{I18n.t('PageShiping.TransportFees')}</span></h3>
          </div>
          <div className="wrap-value">
            <ul>
              <li>
                <span className="wrap-text">{I18n.t('PageShiping.CRS')}</span>
                <span className="wrap-text" style={{ marginLeft: 10, color: 'red' }}>{_.round(CRS, 2) || 0} VND</span>
              </li>
              <li>
                <span className="wrap-text">{I18n.t('PageShiping.LocalCharge')}</span>
                <span className="wrap-text" style={{ marginLeft: 10, color: 'red' }}>{_.round(LocalCharge, 2) || 0} VND</span>
              </li>
              <li>
                <span className="wrap-text">{I18n.t('PageShiping.TruckingIn')}</span>
                <span className="wrap-text" style={{ marginLeft: 10, color: 'red' }}>{_.round(TruckingIn, 2) || 0} VND</span>
              </li>
            </ul>
          </div>
        </div>

        { //filter(p => p.styleId == style._id) index == orderIndex
          pos.filter((p, index) => ((((p.style || {}).order || {})._id == order._id)) || (!_.get(p, 'style._id', undefined) && order._id == '')).filter(s => s.styleId == styleActive || !s.styleId).map((po, idx) => {
            const checkedAll = _.findIndex(po.materials || [], m => !(m.checked || false)) < 0
            let isStock = (typeof po.styleId === 'undefined') ? false : true;
            return (
              <div key={idx}>
                <div className="pd-10 size-13">
                  <span className="wrap-text mg-right-10">{I18n.t('PageShiping.PurchaseOrder')}</span>
                  <span className="wrap-text color-orange ">{po.purchaseNo}</span>
                </div>
                <div className="tab-content">
                  <table className="global-table w-full bg-white data-time-table table-ship-in-edit">
                    <tbody>
                      <tr>
                        <th className="center style-checkbox">
                          <input checked={checkedAll} type="checkbox" id={po._id} onChange={(evt) => this.checkMaterial(po._id, evt.target.checked, 'all')} />
                          <label htmlFor={po._id} style={{ top: '-12px' }}><span><span /></span></label>
                        </th>
                        <th className='center'>{I18n.t('PageShiping.Material')}</th>
                        <th className='center'>{I18n.t('PageShiping.Content')}</th>
                        <th className='center'>{I18n.t('PageShiping.ReplacementPO')}</th>
                        <th className='center'>{I18n.t('PageShiping.Color')}</th>
                        <th className='center'>{I18n.t('PageShiping.Size')}</th>
                        <th className='center'>{I18n.t('PageShiping.Needed')}</th>
                        <th className='center'>{I18n.t('PageShiping.Sample')}</th>
                        <th className='center'>{I18n.t('PageShiping.Actual')}</th>
                        <th className='center'>{I18n.t('PageShiping.XMIL')}</th>
                        <th className='center'>{I18n.t('PageShiping.ETD')}</th>
                        <th className='center'>{I18n.t('PageShiping.ETA')}</th>
                      </tr>
                      {(po.materials || []).map((mt, idx) => {
                        return (
                          <tr key={idx}>
                            <td className="center style-checkbox">
                              <input checked={mt.checked || false} type="checkbox" id={`${mt._id}_${mt.color}_${idx}`} onChange={(evt) => this.checkMaterial(po._id, evt.target.checked, mt._id, mt.size, mt.color, mt.replacementPO)} />
                              <label htmlFor={`${mt._id}_${mt.color}_${idx}`} style={{ top: '-12px' }}><span><span /></span></label>
                            </td>
                            <td>{_.get(mt, 'material.name', '')}</td>
                            <td className="center">{_.get(mt, 'material.data.content', '')}</td>
                            <td className="center">{_.get(mt, 'replacementPO', '')}</td>
                            <td className="center">{_.get(mt, 'color', '')}</td>
                            <td className="center">{_.get(mt, 'size', '')}</td>
                            <td className="center">{_.get(mt, 'needToBuy', '')}</td>
                            <td className="center">
                              <input type="number" min="0" value={mt.sample || 0} className="form-control center pd-right-0" onChange={(evt) => { this.setPo('sample', po._id, mt._id, mt.size, mt.color, mt.replacementPO, _.toInteger(evt.target.value)) }} />
                            </td>
                            <td className="center">
                              <input type="number" min="0" value={mt.actual || 0} className="form-control center pd-right-0" onChange={(evt) => { this.setPo('actual', po._id, mt._id, mt.size, mt.color, mt.replacementPO, _.toInteger(evt.target.value)) }} />
                            </td>
                            <td className="center select date-time-td">
                              <DateTimeField 
                                dateFormat='MMM DD, YYYY'
                                timeFormat={false}
                                value={moment(_.get(mt, 'XMIL', new Date()))}
                                onChange={date => this.setPo('xmil', po._id, mt._id, mt.size, mt.color, mt.replacementPO, date.format())}
                              />
                            </td>
                            <td className="center select date-time-td">
                              <DateTimeField 
                                dateFormat='MMM DD, YYYY'
                                timeFormat={false}
                                value={moment(_.get(mt, 'ETD', new Date()))}
                                onChange={date => this.setPo('etd', po._id, mt._id, mt.size, mt.color, mt.replacementPO, date.format())}
                              />
                            </td>
                            <td className="center select date-time-td">
                              <DateTimeField 
                                dateFormat='MMM DD, YYYY'
                                timeFormat={false}
                                value={moment(_.get(mt, 'ETA', new Date()))}
                                onChange={date => this.setPo('eta', po._id, mt._id, mt.size, mt.color, mt.replacementPO, date.format())}
                              />
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </div>)
          })
        }
        
      </div>
    )

  }

  render = () => {
    const { orders, openModal, pos, shipping } = this.state;
    const { isEdit, roles: {showCreate, showEdit, showDelete, showView} } = this.props;
    const totalMaterials = pos.reduce((mt_total, po) => {
      let total = 0;
      (po.materials || []).forEach((mt) => {
        if (mt.checked) {
          total += (mt.sample || 0) + (mt.actual || 0);
        }
      });
      return mt_total + total;
    }, 0);

    return (
      <div id="screen-create-shipment" className="pd-10">
        <div>
          {this.renderTitle()}
          <div className="panel-group" id="panel-list">
            {orders.map((order, idx) => (
              <Panel key={`key_${idx}`} id={`id_${idx}`} 
                renderContent={() => this.renderPanelContent(order, idx, totalMaterials)} 
                renderPanelDetails={() => this.renderPanelDetails(order)} 
                removePurchase={() => this.removePurchase(order)}
                />
            ))}
          </div>
          {((isEdit && showEdit) || showCreate) && <div className="center mg-top-15 clearBoth">
            <button className="bt-orange text-uppercase" onClick={() => this.addPurchase()}>{I18n.t('PagePurchase.ADDPURCHASEORDER')}</button>
          </div>}
        </div>
        <ComfirmModal openModal={openModal} close={this.closeModal} ok={this.ok} />
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
    appState: state.appState,
    shippingState: state.shippingState,
    standardState: state.standardState,
    checkedQuantity: checkedQuantity,
    canComplete: permissions.isSpecial({ moduleKey: "shipping", ...state })
  };
}

function mapDispatchToProps(dispatch) {
  return {
    createShipment: bindActionCreators(createShipment, dispatch),
    standardPropsActions: bindActionCreators(standardActions, dispatch),
    dispatch
  };
}

ScreenShipIn.propTypes = {
  authState: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ScreenShipIn);

