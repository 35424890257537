import { createSelector } from 'reselect';
import _ from 'lodash';
import {genDollarToText} from '../../../functions/helpers';
import moment from 'moment';
const formatCurrency = require('format-currency');
/**
 * Selectors for overview information of invoice (first component)
 *
 */
export const getSelectableImpExpCompanies = ({ createPayableInvoiceImpExpUSDelivery }) => {
  const {selectableImpExpCompanies} = createPayableInvoiceImpExpUSDelivery;
  return _.map(selectableImpExpCompanies, s => ({
    value: s._id,
    label: s.company,
    raw: s,
  }));
}

export const getSelectedImpExpCompany = ({ createPayableInvoiceImpExpUSDelivery }) => {
  return createPayableInvoiceImpExpUSDelivery.selectedImpExpCompany;
}

export const getInvoiceNo = ({ createPayableInvoiceImpExpUSDelivery }) => {
  return createPayableInvoiceImpExpUSDelivery.invoiceNo;
}

export const getInvoiceDate = ({ createPayableInvoiceImpExpUSDelivery }) => {
  return createPayableInvoiceImpExpUSDelivery.invoiceDate;
}

export const getNote = ({ createPayableInvoiceImpExpUSDelivery }) => {
  return createPayableInvoiceImpExpUSDelivery.note;
}

export const getSelectableCreditDebits = ({ createPayableInvoiceImpExpUSDelivery }) => {
  /**
   * Get selectable credit debit notes (filtered by import/export company) from reducer
   */
  return createPayableInvoiceImpExpUSDelivery.selectableCreditDebits;
}

export const getSelectedCreditDebit = ({ createPayableInvoiceImpExpUSDelivery }) => {
  return createPayableInvoiceImpExpUSDelivery.selectableCreditDebits;
}

const genDisplayDeliveryBillsModel = (model, checked) => {
  if (!model) return {};
  return  _.assign( new Proxy(model, {
      get(obj, prop) {
          switch (prop) {
            case 'etd':
              return moment(obj['etd']).format("MMM DD, YYYY");
            case 'eta':
              return moment(obj['eta']).format("MMM DD, YYYY");
            case 'dueDate':
              return moment(obj['eta']).add((obj['paymentTerm'] || 0), 'day').format('MMM DD, YYYY')
            case 'raw':
              return obj;
            default:
              return obj[prop];
          }
      },
  }), {checked: checked});
};

export const getCheckedList = ({ createPayableInvoiceImpExpUSDelivery }) => createPayableInvoiceImpExpUSDelivery.checkedUSDeliveries;

export const getSelectableUSDeliveryBills = createSelector (
  [
    ({createPayableInvoiceImpExpUSDelivery}) => createPayableInvoiceImpExpUSDelivery.USDeliveries,
    getCheckedList
  ], (listBill, listChecked) => {
    let arrData = [];
    const data =  _.cloneDeep(listBill).map(it => 
      {
        const checked = listChecked.findIndex(item => item === it._id) >= 0
        return genDisplayDeliveryBillsModel(it, checked)
      }
    )
     data.map(item => {
        if(!item.hide){
            arrData.push({ _id: item._id, checked: item.checked, name: item.billNo });
        }
    })
    return arrData;
  }
)

export const getDetailedUSDeliveryBills = createSelector (
  [
    ({createPayableInvoiceImpExpUSDelivery}) => createPayableInvoiceImpExpUSDelivery.USDeliveries,
    getCheckedList
  ], (listBill, listChecked) => {
    let data = [] 
    _.cloneDeep(listBill).map(it => 
      {
        if(listChecked.findIndex(item => item === it._id) >= 0){
          it.containers.map(c => _.assign(c, {
            name: c.name + (c.type ? ` - ${c.type}` : ''),
            eta: moment(c.eta).format('MMM DD, YYYY'),
            etd: moment(c.etd).format('MMM DD, YYYY'),
            dueDate: moment(c.eta).add((it.paymentTerm || 0), 'day').format('MMM DD, YYYY'),
            amount: '$'+ formatCurrency(((c.cost || 0) + (c.trucking || 0) + (c.pierPass || 0) + (c.waitingTime || 0) + (c.chassisFee || 0) + (c.prepull || 0) + (c.yardStorage || 0) + (c.otherFees || 0))),
            quantity: c.qty,
          }))
          data.push(it)
        }
      }
    )
      return data
  }

)

export const getAmount = createSelector (
  [
    getDetailedUSDeliveryBills
  ], (listBill) => {
    const countFeeOfAllBills = _.sum(listBill.map(item => ((item.ifsFilling || 0) + (item.customerEntry || 0) + (item.otherFees || 0))))
    const countFeeOfAllContainers = _.sum(listBill.map(item => _.sum(item.containers.map(it => ((it.cost || 0) + (it.trucking || 0) + (it.pierPass || 0) + (it.waitingTime || 0) + (it.chassisFee || 0) + (it.prepull || 0) + (it.yardStorage || 0) + (it.otherFees || 0))))))
    return countFeeOfAllBills + countFeeOfAllContainers;
  }
)

export const getTotalAmount = createSelector (
  [getAmount],
    (amount) => {
      return amount
    }
)

export const getTotalAmountSay = createSelector(
  [getTotalAmount],
  (totalAmount) => {
    return genDollarToText(totalAmount)
  }
);

export const fnGetEditingDeliveryBill = createSelector (
  [
    getDetailedUSDeliveryBills,
  ], (listBill) => id => {
    return _.find(listBill, item => item._id === id)
  }
)

export const fnGetDetailContainerOfBill = ({ createPayableInvoiceImpExpUSDelivery }) => (billId, containerIndex) => {
  const temp = createPayableInvoiceImpExpUSDelivery.USDeliveries.find(item => item._id === billId)
  const raw = temp.containers.find((item, index) => index === containerIndex && item);
  return {...raw, billId: billId, contIndex: containerIndex};
}

export const getTempData = ({ createPayableInvoiceImpExpUSDelivery }) => createPayableInvoiceImpExpUSDelivery.dataOfTemp;

export const getPreviewInvoice = createSelector(
  [getDetailedUSDeliveryBills], 
  (bills) => {
    // TODO.
    throw "NotImplementedException";
  }
);

export const fnGenerateRequestBody = createSelector (
  [
    ({createPayableInvoiceImpExpUSDelivery}) => createPayableInvoiceImpExpUSDelivery,
    getDetailedUSDeliveryBills,
    getAmount
  ], (data, listBill, amount) => {
    const result = {
        typeInvoice: "usDeliveryInvoice",
        invNo: data.invoiceNo,
        invoiceDate: data.invoiceDate,
        dueDate: data.dueDate,
        note: data.note,
        currency: 'USD',
        amount: amount,
        invoiceDetail: {
          company: data.selectedImpExpCompany,
          bills : listBill.map(item => _.omit(item, ['_id']))
        }
    }
    return result
  }
)

export const getCanPreview = ({createPayableInvoiceImpExpUSDelivery}) => !_.isEmpty(createPayableInvoiceImpExpUSDelivery.checkedUSDeliveries);

export const genDataForPreview = createSelector (
  [
    getDetailedUSDeliveryBills
  ], (data) => {
    let bills = _.cloneDeep(data);
    bills.map(item => {
      const containersAmount =  _.sum(item.containers.map(c => (c.cost || 0) + (c.trucking || 0) + (c.pierPass || 0) + (c.waitingTime || 0) + (c.chassisFee || 0) + (c.prepull || 0) + (c.yardStorage || 0) + (c.otherFees || 0)))
      const billAmount = (item.ifsFilling || 0) + (item.customerEntry || 0) + (item.otherFees || 0);
      item.amount = formatCurrency(containersAmount + billAmount) + ' USD';
      item.amountNumber = (containersAmount + billAmount);
      
      let maxLengthEtd = 0;
      let etdValue;
      item.containers.map(c => {
          const count = item.containers.filter(it => it.etd === c.etd);
          if(count.length > maxLengthEtd) {
              maxLengthEtd = count.length;
              etdValue = c.etd;
          }
      })
      item.etd = moment(etdValue).format("MMM DD, YYYY");

      let maxLengthEta = 0;
      let etaValue;
      item.containers.map(c => {
          const count = item.containers.filter(it => it.eta === c.eta);
          if(count.length > maxLengthEta) {
              maxLengthEta = count.length;
              etaValue = c.eta;
          }
      })
      item.eta = moment(etaValue).format("MMM DD, YYYY");

      let maxLengthDueDate = 0;
      let dueDateValue;
      item.containers.map(c => {
          const count = item.containers.filter(it => it.dueDate === c.dueDate);
          if(count.length > maxLengthDueDate) {
              maxLengthDueDate = count.length;
              dueDateValue = c.dueDate;
          }
      })
      item.dueDate = moment(dueDateValue).format("MMM DD, YYYY");
      _.omit(item, ['containers']);
  })
  return bills;
  }
)

export const getSuccess = ({ createPayableInvoiceImpExpUSDelivery }) => createPayableInvoiceImpExpUSDelivery.success;

export const getActiveScreenStatus = ({createPayableInvoiceImpExpUSDelivery}) => createPayableInvoiceImpExpUSDelivery.activeScreenPreview;

export const getDueDate = ({ createPayableInvoiceImpExpUSDelivery }) => createPayableInvoiceImpExpUSDelivery.dueDate;