import C from '../../../constants/accounting/payable/createInvoiceImpExpContainer';
import accountingApi from '../../../api/accountingApi';
import { toastr } from 'react-redux-toastr';
export const getListForwarder = () => dispatch => {
    accountingApi.getListForwarder().then(res => {
        if(res.success) {
            dispatch({type: C.CHANGE_LIST_FORWARDER, payload: res.data.items});
            dispatch({type: C.CHANGE_INVOICE_INFO, payload: {key: 'name', value: res.data.items[0].forwarder}})
            dispatch({type: C.CHANGE_EMPTY_VAT_VALUE})
            const query = {forwarder: res.data.items[0].forwarder}
            accountingApi.getListContainer(query).then(r => {
                if(r.success) {
                    dispatch({type: C.CHANGE_LIST_CONTAINER, payload: r.data.items})
                }
            })
        }
    })
}

export const changeInvoiceInfo = (key, value) => dispatch => {
    dispatch({type: C.CHANGE_INVOICE_INFO, payload: {key, value}})
    if(key == 'name') {
        const query = {forwarder: value}
            accountingApi.getListContainer(query).then(r => {
                if(r.success) {
                    dispatch({type: C.CHANGE_LIST_CONTAINER, payload: r.data.items})
                    dispatch({type: C.RESET_CHECKED_LIST})
                    dispatch({type: C.CHANGE_DETAIL_CONTAINER_VALUE, payload: {}})
                }
            })
    }
}

export const changeChecked = (item) => dispatch => {
    dispatch({type: C.CHANGE_CHECKED_LIST, payload: item})
}

export const changeVATValues = (key, value) => dispatch => {
    dispatch({type: C.CHANGE_VAT_VALUE, payload: {key, value}})
}

export const changeSearchText = (value) => dispatch => {
    dispatch({type: C.CHANGE_SEARCH_CONTAINER_VALUE, payload: value})
}

export const changeContainerDetailValue = (key, value) => dispatch => {
    dispatch({type: C.CHANGE_DETAIL_CONTAINER_VALUE, payload: {key, value}})
}

export const copyDataToTemp = (data) => dispatch => {
    dispatch({type: C.CHANGE_TEMP_DATA, payload: data})
}

export const saveChangeContainerDetailValue = (data) => dispatch => {
    dispatch({type: C.SAVE_CHANGE_DETAIL_CONTAINER_VALUES, payload: data})
}

export const createInvoice = (data) => dispatch => {
    accountingApi.createInvoice(data).then(res => {
        if(res.success) {
            dispatch({type: C.CREATE_INVOICE_SUCCESS, payload: true})
        }
    }).catch(error => {
        if(error.error.search('duplicate key error collection: erp_staging.payableInvoice') > -1) {
            toastr.error(I18n.t('Notify.Error'), "Dupplicate Invoice with data of Database")
        }
    });
}

 export const resetData = () => dispatch => {
     dispatch({type: C.RESET_DATA})
 }

 export const activeScreenPreview = () => dispatch => {
    dispatch({type: C.ACTIVE_SCREEN_STATUS, payload: true})
  }