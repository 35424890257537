
import C from '../../../constants/customer-po/current/showImagesGallery';
import * as R from 'ramda';

export const showModal = images => dispatch => {
  dispatch({ type: C.SHOW_MODAL, payload: images });
}

export const hideModal = () => dispatch => {
  dispatch({ type: C.RESET_DATA });
}
