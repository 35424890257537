import * as types from '../constants/actionTypes';
import {showLoading, hideLoading} from './commonUseActions';
import customerServices from '../services/customerServices';
import I18n from "i18n-js";

function getListCustomer (options = {}) {
  return (dispatch) => {
    showLoading(types, dispatch);

    return customerServices.getListCustomer(options).then((res) => {
      dispatch({
        type: types.CUSTOMER.GET_LIST_SUCCESS,
        data: res.data || []
      });
      hideLoading(types, dispatch);
      return res;
    }, (error) => {
      hideLoading(types, dispatch);
    });
  };
}

function getDetail (id) {
  return (dispatch) => {
    showLoading(types, dispatch);

    dispatch({
      type: types.CUSTOMER.RESET_DETAIL,
      data: {}
    });

    customerServices.getDetail(id).then((res) => {
      hideLoading(types, dispatch);
      if (res) {
        dispatch({
          type: types.CUSTOMER.GET_DETAIL_SUCCESS,
          data: res.data || {}
        });
      }
    }, (error) => {
      hideLoading(types, dispatch);
    });
  };
}

function deleteCustomer (id) {
  return (dispatch) => {
    showLoading(types, dispatch);

    customerServices.deleteCustomer(id).then((res) => {
      hideLoading(types, dispatch);
      if (res) {
        dispatch({
          type: types.CUSTOMER.DELETE_SUCCESS,
          data: true
        });
      }
    }, (error) => {
      hideLoading(types, dispatch, error);
    });
  };
}

function editCustomer (id, data) {
  return (dispatch) => {
    showLoading(types, dispatch);

    customerServices.editCustomer(id, data).then((res) => {
      hideLoading(types, dispatch);
      if (res) {
        dispatch({
          type: types.CUSTOMER.RELOAD,
          data: true
        });
      }
    }, (error) => {
      hideLoading(types, dispatch, error);
    });
  };
}

function addCustomer (data) {
  return (dispatch) => {
    showLoading(types, dispatch);

    customerServices.addCustomer(data).then((res) => {
      if (res) {
        dispatch({
          type: types.CUSTOMER.RELOAD,
          data: true
        });
      }
    }, (error) => {
      if(error.error && error.error.indexOf('erp_staging.customer index: shortName_1 ') != 0){
          error.error = I18n.t('PageCrm.CustomerNameExist');
      }
      hideLoading(types, dispatch, error);
    });
  };
}

const resetDeleteStatus = () => dispatch => {
  dispatch({type: types.CUSTOMER.DELETE_SUCCESS, data: false});
}

export default {
  getListCustomer,
  deleteCustomer,
  editCustomer,
  addCustomer,
  getDetail,
  resetDeleteStatus
};
