import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import stockInActions from '../../../actions/stockInActions';
import orderActions from '../../../actions/orderActions';
import contractorActions from '../../../actions/contractorActions';
import styleServices from '../../../services/styleServices';
import _ from 'lodash';
import I18n from 'i18n-js';

import Select from 'react-select';
import 'react-select/dist/react-select.css';

class StockInContractor extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      item: {},
      orders: [],
      contractors: [],
      styles: [],
      errors: {},
    };
  }

  componentWillMount() {
    this.getListContractors();
  }

  componentWillReceiveProps(newProps) {
    let { orders, contractors } = this.state;
    !_.isEqual(orders, newProps.orderState.items) &&
      this.setState({ orders: _.cloneDeep(newProps.orderState.items) || [] });
    !_.isEqual(contractors, newProps.contractorState.items) &&
      this.setState({ contractors: _.cloneDeep(newProps.contractorState.items) || [] });
  }

  getListOrder(options = {}) {
    let query = {};
    options.populate = 'styleOrders';

    this.props.orderPropsActions.getListOrder(_.assign(query, options));
  }

  getListContractors() {
    this.props.contractorActions.getListItem({ sort: { createdAt: -1 }, isAssigned: true });
  }

  onChange(property, value) {
    let { item, styles } = this.state;
    item[property] = value;

    switch (property) {
      case 'contractorId': {
        let query = {
          populate: 'styleOrders',
          isGetAll: true,
          contractorId: value,
        };
        this.props.orderPropsActions.getListOrder(query);
        // this.props.orderPropsActions.getListOrder(query).then(res => {
        //   orders = _.get(res, 'data.items', []);
        //   item = { contractorId: value };
        //   this.setState({ orders, item });
        // });
        break;
      }
      case 'orderId': {
        styleServices.getAllStylesAssignment({ orderId: item.orderId, contractorId: item.contractorId }).then(res => {
          styles = _.get(res, 'data.items', []);
          item = _.set(_.pick(item, ['orderId', 'contractorId']), property, value);
          this.setState({ styles, item });
        });
        break;
      }
      default:
        this.setState({ item });
        break;
    }
    this.validateChange(property, value);
  }

  close() {
    this.props.closeModal();
  }

  validateChange(property, value) {
    let { errors } = this.state;
    let fields = { orderId: 'Customer PO', styleId: 'Style', contractorId: 'Contractor' };
    delete errors[property];
    if (!value) {
      errors[property] = `Please select ${fields[property]}`;
    }
    this.setState({ errors });
  }

  validate() {
    let { item, errors } = this.state;
    let fields = { orderId: 'Customer PO', styleId: 'Style', contractorId: 'Contractor' };

    Object.keys(fields).forEach(f => {
      _.set(errors, f, !item[f] ? `Please select ${fields[f]}` : '');
    });
    this.setState({ errors });

    let flag = Object.keys(errors).every(k => !errors[k]);

    return flag;
  }

  save() {
    let { item, contractors, orders } = this.state;
    const { openFormAdd } = this.props;
    let contractor = contractors.find(i => i._id === item.contractorId);
    let order = orders.find(i => i._id === item.orderId) || {};
    let styles = order.styleOrders || [];

    let data = _.cloneDeep(item);

    data.style = styles.find(i => i._id === item.styleId) || {};

    data.order = order;
    data.contractor = contractor;
    data.contractors = contractors || [];

    if (this.validate()) {
      // open form add
      _.isFunction(openFormAdd) && openFormAdd('contractor', data);

      // close modal add
      this.props.closeModal();
    }
  }

  render() {
    let { orders, contractors, item, errors, styles } = this.state;
    // let orderSelected = (orders || []).find(i => i._id === item.orderId) || {};
    // let styles = orderSelected.styleOrders || [];

    return (
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <form>
            <div className="modal-header">
              <a className="close" onClick={() => this.close()}>
                <span aria-hidden="true">&times;</span>
              </a>
              <h4 className="modal-title" id="myModalLabel">
                {I18n.t('PageWareHouse.ADDINBOUND')}
              </h4>
            </div>

            <div className="modal-body">
              <div className="form-group">
                <div className="col-md-4 col-xs-12 text">
                  <p>{I18n.t('PageWareHouse.Contractor')}</p> <span className="color-red"> *</span>
                </div>
                <div className="col-md-8 col-xs-12 value">
                  <Select
                    value={item.contractorId}
                    options={contractors.map(elm => ({ value: elm._id, label: elm.name }))}
                    onChange={evt => this.onChange('contractorId', evt.value)}
                  />
                  {errors['contractorId'] && <div className="alert-warning">{errors['contractorId']}</div>}
                </div>
              </div>

              <div className="form-group">
                <div className="col-md-4 col-xs-12 text">
                  <p>{I18n.t('PageWareHouse.CustomerPO')}</p> <span className="color-red"> *</span>
                </div>
                <div className="col-md-8 col-xs-12 value">
                  <Select
                    value={item.orderId}
                    options={orders.map(elm => ({ value: elm._id, label: elm.customerPO }))}
                    onChange={evt => this.onChange('orderId', evt.value)}
                  />
                  {errors['orderId'] && <div className="alert-warning">{errors['orderId']}</div>}
                </div>
              </div>

              <div className="form-group">
                <div className="col-md-4 col-xs-12 text">
                  <p>{I18n.t('PageWareHouse.Style')}</p> <span className="color-red"> *</span>
                </div>
                <div className="col-md-8 col-xs-12 value">
                  <Select
                    value={item.styleId}
                    options={styles.map(elm => ({ value: elm._id, label: elm.style }))}
                    onChange={evt => this.onChange('styleId', evt.value)}
                  />
                  {errors['styleId'] && <div className="alert-warning">{errors['styleId']}</div>}
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <button type="button" className="bt-black" onClick={() => this.close()}>
                {I18n.t('Btn.CANCEL')}
              </button>
              <button type="button" className="bt-orange mg-l-5" onClick={() => this.save()}>
                {I18n.t('Btn.SAVE')}
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    authState: state.authState,
    stockInState: state.stockInState,
    orderState: state.orderState,
    contractorState: state.contractorState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    stockInPropsActions: bindActionCreators(stockInActions, dispatch),
    orderPropsActions: bindActionCreators(orderActions, dispatch),
    contractorActions: bindActionCreators(contractorActions, dispatch),
  };
}

StockInContractor.propTypes = {
  authState: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(StockInContractor);
