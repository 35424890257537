import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import FormGroup from '../../forms/FormGroup';
import _ from 'lodash';

// import SideBar from './SideBar';
const EditPlanChargeBackInvoice = (props) => {
    const { typeSupplier, availableNoList, countNo, payInfo } = props;
    return (
        <div id="accounting-receivable-plan">
            <div
                className="wrap-filter-search"
                style={{
                    top: '-50px',
                    right: '0px',
                    position: 'absolute'
                }}
            >
                <ul className="inline-block">
                    <li className="float-left mg-right-15">
                        {/* <div className="style-checkbox position-relative">
                            <input id="accounts-receivable-chargeback-123123" checked={true} type="checkbox" onChange={() => props.changeTypeInvoice()} />
                            <label htmlFor="accounts-receivable-chargeback-123123"><span className="mg-right-10"><span></span></span>Chargeback</label>
                        </div> */}
                    </li>
                    {props.roles.showCreate && <li className="float-left style-icon-save" onClick={() => props.onSave(payInfo)}>
                        <span className="cursor-pointer">
                            <i className="fa fa-floppy-o" aria-hidden="true"></i>
                            SAVE
                        </span>
                    </li>}
                </ul>
            </div>
            <div id="header-accounting" className="box-shadow-1 inline-block w-full position-relative page-accounting-supplier">
                <div className="left w-200" />
                <div className="right top-0 w-full pd-left-300">
                    <div className="border-title-left" />
                    <div className="title-left left  wrap-input-global">

                        <div className="input pd-0 position-relative">
                        <FormGroup
                                type="pd-right-0"
                                label="Customer"
                                inputComponent={(payInfo && payInfo.customer )? payInfo.customer.name: ''}
                            />
                        </div>
                        <div className="input pd-0 position-relative">
                            <FormGroup
                                type="pd-right-0"
                                label="Issued Date"
                                inputComponent={(payInfo && payInfo.issuedDate )? moment(payInfo.issuedDate).format('MMM DD, YYYY'): ''}
                            />
                        </div>
                    </div>

                    <div className="bd-dashed-bottom wrap-input-global pd-15-0 margin-small">
                        <div className="col-md-6 col-xs-12 input">
                            <FormGroup
                                type="position-relative top-7 pd-left-160"
                                label="Total Air Fee"
                                inputComponent={props.totalAirFee}
                            />
                        </div>
                        <div className="col-md-6 col-xs-12 input">
                            <FormGroup
                                type="position-relative top-7 pd-left-160"
                                label="Total Debit Amount"
                                inputComponent={props.totalDebitAmount}
                            />
                        </div>
                    </div>

                    <div className="bottom text-left size-12 pd-15-0 inline-block w-full">
                        <div className="col-xs-12">
                                {props.sayMoney
                                    ? <p><span className="mg-right-15">Say</span> <span className="bold">{props.sayMoney}</span></p>
                                    : ""
                                }
                        </div>
                    </div>
                </div>
            </div>
            <div className="content-main-accounting">
                
                <div className="content-sidebar top-initial left-0">
                    <div className="md content-sidebar-search">
                        <input type="text" placeholder="Search" />
                        <span><i className="fa fa-search" aria-hidden="true" /></span>
                    </div>
                    <div className="md content-sidebar-menu">
                        <ul className="overflow-y-scroll h-full">
                            {_.map(props.checkableBills, (bill, index) => (
                                <li className="style-checkbox" key={index}>
                                    <input
                                        type="checkbox"
                                        // id="content-sidebar-menu-1" 
                                        className="display-none"
                                        value={bill.checked} 
                                        // {bill.checked ? 'checked' : ''} 
                                        checked={bill.checked}
                                        disabled
                                        id={bill._id}
                                    />
                                    <label htmlFor={bill._id}>
                                        <span className="mg-right-10"><span /></span>{bill.billNo}
                                    </label>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>

                <div className="panel-group mg-0" id="panel-heading-main" role="tablist" aria-multiselectable="true">
                    {_.map(props.checkedBills, (bill, billKey) => (
                        <div className="panel panel-default bd-none radius-0" key={billKey}>
                        {/* <!-- Component --> */}
                        <div className="panel-heading component" role="tab" id="">
                            <div className="panel-title">
                                <a role="button" data-toggle="collapse" data-parent="#panel-heading-main" href="#panel-heading-1" aria-expanded="true" aria-controls="collapseOne">
                                    <i className="fa fa-caret-down" aria-hidden="true" />
                                </a>
                                <ul>
                                    <li className="float-left">
                                        <div className="text">
                                            <span className="left">Bill No</span>
                                            <span className="right size-15"><span className="medium">{bill.billNo}</span></span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        {/* <!-- End Component --> */}
                        <div id="panel-heading-1" className="panel-collapse collapse in" role="tabpanel" aria-labelledby="panel-heading-1">
                            {_.map(bill.customerPOs, (item, cusKey) => (
                                <div className="panel-body pd-0 bg-gray" key={cusKey}>
                                    <ul className="bg-white content-header-title pd-left-15 bd-solid-bottom-3">
                                        <li>
                                            <p>Customer PO <span className="bold mg-left-25 size-14 color-green">{item.customerPO}</span></p>
                                        </li>
                                    </ul>
                                    {/* <!-- Component --> */}
                                    <div className="bg-white wrap-input-global pd-15-0-0-0 margin-small">
                                        <div className="col-md-4 col-xs-12 input">
                                            <ul className="position-relative">
                                                <li className="top-0"><span>Air Fee</span></li>
                                                <li><span className="color-number bold">${props.getAirFeeCustomerPO(item.data)}</span></li>
                                            </ul>
                                            <ul className="position-relative">
                                                <li className="top-0"><span>Debit Amount</span></li>
                                                <li><span className="color-number bold">${props.getDebitAmountCustomerPO(item.data)}</span></li>
                                            </ul>
                                        </div>
                                        <div className="col-md-6 col-xs-12 input h-auto">
                                            <ul>
                                                <li><span>Note</span></li>
                                                <li className="textarea pd-left-40">
                                                    <textarea 
                                                        rows="4" 
                                                        cols="120" 
                                                        value={item.note}
                                                        disabled
                                                        // onChange={evt => props.changeNoteOrder(bill.billNo, item.customerPO, evt.target.value)}
                                                    />
                                                </li>
                                            </ul>
                                        </div>

                                    </div>
                                    {/* <!-- End Component --> */}

                                    <div className="wrap-global-table pd-0 inline-block w-full float-left mg-bottom-0">
                                        <table className="global-table box-shadow-none w-full">
                                            <tbody>
                                                <tr className="bg-white bd-solid-bottom">
                                                    <th className="center">Style</th>
                                                    <th className="center">Cut Ticket</th>
                                                    <th className="center">Rate</th>
                                                    <th className="center">CBM</th>
                                                    <th className="center">Air Fee</th>
                                                    <th className="center">Fee i loginx in USA</th>
                                                    <th className="center">Deduct</th>
                                                    <th className="center w-100">Debit Amount</th>
                                                    <th className="center">Payer</th>
                                                    <th className="center">No</th>
                                                    <th className="center">Rate LM Pay</th>
                                                    <th className="center">Amount Pay</th>
                                                </tr>
                                                {_.map(item.data, (elem, elemKey) => (
                                                    <tr key={elemKey}>
                                                        <td className="center">{elem.style}</td>
                                                        <td className="center">{elem.cutTicket}</td>
                                                        <td className="center">
                                                            <input 
                                                                type="text" 
                                                                className="form-control center"
                                                                value={elem.chargeBackInvoice.rate || ""}
                                                                onChange={evt => props.changeChargeBackInvs('rate', elem.cutTicketId, evt.target.value)}
                                                            />
                                                        </td>
                                                        <td className="center color-link dropdown-table-style">
                                                            <div className="dropdown">
                                                                <button
                                                                    className="btn btn-secondary dropdown-toggle color-link text-decoration" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                                                >
                                                                    <span>{parseFloat(elem.cbm.cbm).toFixed(2)}</span>
                                                                </button>
                                                                <div className="dropdown-menu box-shadow w-300">
                                                                    <table>
                                                                        <tbody>
                                                                            <tr>
                                                                                <th>CBM</th>
                                                                                <th>CTNS</th>
                                                                                <th>QTY</th>
                                                                                <th>Chargeable weight</th>
                                                                            </tr>
                                                                            <tr className="text-center">
                                                                                <td>{parseFloat(elem.cbm.cbm).toFixed(2)}</td>
                                                                                <td>{elem.cbm.cnts}</td>
                                                                                <td>{elem.cbm.qty}</td>
                                                                                <td>{elem.cbm.cw}</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="center">${props.getAirFee(elem)}</td>
                                                        <td className="center">
                                                            <input 
                                                                type="text" 
                                                                className="form-control center"
                                                                value={elem.chargeBackInvoice.feeInUSA || null}
                                                                onChange={evt => props.changeChargeBackInvs('feeInUSA', elem.cutTicketId, parseFloat(evt.target.value))}
                                                            />
                                                        </td>
                                                        <td className="center">
                                                            <input 
                                                                type="text" 
                                                                className="form-control center"
                                                                value={elem.chargeBackInvoice.deduct || null}
                                                                onChange={evt => props.changeChargeBackInvs('deduct', elem.cutTicketId, evt.target.value)}
                                                            />
                                                        </td>
                                                        <td className="center">${props.getDebitAmount(elem)}</td>
                                                        <td className="center">
                                                            <input 
                                                                type="text" 
                                                                className="form-control center"
                                                                value={elem.chargeBackInvoice.payer || null}
                                                                onChange={evt => props.changeChargeBackInvs('payer', elem.cutTicketId, evt.target.value)}
                                                            />
                                                        </td>
                                                        <td className="center color-link dropdown-table-style w-150 pd-0">
                                                            {payInfo.code}
                                                        </td>
                                                        <td className="center color-number">
                                                            <input 
                                                                type="text" 
                                                                className="form-control center"
                                                                value={elem.chargeBackInvoice.rateLM || null}
                                                                onChange={evt => props.changeChargeBackInvs('rateLM', elem.cutTicketId, evt.target.value)}
                                                            />
                                                        </td>
                                                        <th className="center">${props.getAmountPay(elem)}</th>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

EditPlanChargeBackInvoice.propTypes = {
    changeTypeInvoice: PropTypes.func,
    typeSupplier: PropTypes.string,
    handleTypeSupplier: PropTypes.func,
    totalAirFee: PropTypes.string,
    totalDebitAmount: PropTypes.string,
};

EditPlanChargeBackInvoice.defaultProps = {
    typeSupplier: null,
    handleTypeSupplier: f => f,
    totalAirFee: 0,
    totalDebitAmount: 0,
    changeTypeInvoice: f => f
};

export default EditPlanChargeBackInvoice;